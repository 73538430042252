import { createSlice } from "@reduxjs/toolkit";
import infoData from "../components/infoData";
const initialState = {
  value: false,
  data: "",
};

const infoBoxGeneral = createSlice({
  name: "infoBoxVisibility",
  initialState,
  reducers: {
    toggleInfoBpx: (state, action) => {
      state.value = !state.value;
      state.data = infoData[action.payload[0]];
    },
  },
});

export const { toggleInfoBpx } = infoBoxGeneral.actions;

export default infoBoxGeneral.reducer;
