const project52ShaindgProgression = [
  {
    ID: -1,
    polygon: "ANotExistingPolygon",
    "04-15 00:00": 0,
    "04-15 00:15": 0,
    "04-15 00:30": 0,
    "04-15 00:45": 0,
    "04-15 01:00": 0,
    "04-15 01:15": 0,
    "04-15 01:30": 0,
    "04-15 01:45": 0,
    "04-15 02:00": 0,
    "04-15 02:15": 0,
    "04-15 02:30": 0,
    "04-15 02:45": 0,
    "04-15 03:00": 0,
    "04-15 03:15": 0,
    "04-15 03:30": 0,
    "04-15 03:45": 0,
    "04-15 04:00": 0,
    "04-15 04:15": 0,
    "04-15 04:30": 0,
    "04-15 04:45": 0,
    "04-15 05:00": 0,
    "04-15 05:15": 0,
    "04-15 05:30": 0,
    "04-15 05:45": 0,
    "04-15 06:00": 0,
    "04-15 06:15": 0,
    "04-15 06:30": 0,
    "04-15 06:45": 0,
    "04-15 07:00": 0,
    "04-15 07:15": 0,
    "04-15 07:30": 0,
    "04-15 07:45": 0,
    "04-15 08:00": 0,
    "04-15 08:15": 0,
    "04-15 08:30": 0,
    "04-15 08:45": 0,
    "04-15 09:00": 0,
    "04-15 09:15": 0,
    "04-15 09:30": 0,
    "04-15 09:45": 0,
    "04-15 10:00": 0,
    "04-15 10:15": 0,
    "04-15 10:30": 0,
    "04-15 10:45": 0,
    "04-15 11:00": 0,
    "04-15 11:15": 0,
    "04-15 11:30": 0,
    "04-15 11:45": 0,
    "04-15 12:00": 0,
    "04-15 12:15": 0,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 0,
    "04-15 15:45": 0,
    "04-15 16:00": 0,
    "04-15 16:15": 0,
    "04-15 16:30": 0,
    "04-15 16:45": 0,
    "04-15 17:00": 0,
    "04-15 17:15": 0,
    "04-15 17:30": 0,
    "04-15 17:45": 0,
    "04-15 18:00": 0,
    "04-15 18:15": 0,
    "04-15 18:30": 0,
    "04-15 18:45": 0,
    "04-15 19:00": 0,
    "04-15 19:15": 0,
    "04-15 19:30": 0,
    "04-15 19:45": 0,
    "04-15 20:00": 0,
    "04-15 20:15": 0,
    "04-15 20:30": 0,
    "04-15 20:45": 0,
    "04-15 21:00": 0,
    "04-15 21:15": 0,
    "04-15 21:30": 0,
    "04-15 21:45": 0,
    "04-15 22:00": 0,
    "04-15 22:15": 0,
    "04-15 22:30": 0,
    "04-15 22:45": 0,
    "04-15 23:00": 0,
    "04-15 23:15": 0,
    "04-15 23:30": 0,
    "04-15 23:45": 0,
  },
  {
    ID: "1.OG_An_8-9_1",
    polygon: [
      -57.8749,
      58.0801,
      9.15,
      -57.8749,
      58.0801,
      11.41,
      -57.6995,
      62.9819,
      11.41,
      -57.6995,
      62.9819,
      11.41,
      -57.6995,
      62.9819,
      9.15,
      -57.8749,
      58.0801,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2.OG_An_6-7_1",
    polygon: [
      -57.1337,
      71.7165,
      12.79,
      -57.1337,
      71.7165,
      14.86,
      -56.9901,
      73.6712,
      14.86,
      -56.9901,
      73.6712,
      14.86,
      -56.9901,
      73.6712,
      12.79,
      -57.1337,
      71.7165,
      12.79,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_7-8_2",
    polygon: [
      -57.6785,
      63.5683,
      12.6,
      -57.6785,
      63.5683,
      14.86,
      -57.6433,
      64.5527,
      14.86,
      -57.6433,
      64.5527,
      14.86,
      -57.6433,
      64.5527,
      12.6,
      -57.6785,
      63.5683,
      12.6,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2.OG_An-Bn_1-2n_1",
    polygon: [
      -49.6739,
      99.1076,
      12.79,
      -49.6739,
      99.1076,
      14.86,
      -47.7146,
      99.3934,
      14.86,
      -47.7146,
      99.3934,
      14.86,
      -47.7146,
      99.3934,
      12.79,
      -49.6739,
      99.1076,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_Dn-En_15-16_1",
    polygon: [
      -40.8196,
      23.4099,
      9.15,
      -40.8196,
      23.4099,
      11.41,
      -42.8046,
      23.4168,
      11.41,
      -42.8046,
      23.4168,
      11.41,
      -42.8046,
      23.4168,
      9.15,
      -40.8196,
      23.4099,
      9.15,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "EG_Gn-H_1_1",
    polygon: [
      -27.3193,
      102.3685,
      3.89,
      -27.3193,
      102.3685,
      7.135,
      -22.8862,
      103.0152,
      7.135,
      -22.8862,
      103.0152,
      7.135,
      -22.8862,
      103.0152,
      3.89,
      -27.3193,
      102.3685,
      3.89,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1.OG_En-Fn_1-2n_1",
    polygon: [
      -38.3132,
      100.7648,
      9.16,
      -38.3132,
      100.7648,
      11.865,
      -33.8801,
      101.4115,
      11.865,
      -33.8801,
      101.4115,
      11.865,
      -33.8801,
      101.4115,
      9.16,
      -38.3132,
      100.7648,
      9.16,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_Fn-Gn_1_2",
    polygon: [
      -27.6718,
      102.3171,
      12.79,
      -27.6718,
      102.3171,
      14.86,
      -25.7125,
      102.6029,
      14.86,
      -25.7125,
      102.6029,
      14.86,
      -25.7125,
      102.6029,
      12.79,
      -27.6718,
      102.3171,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_An_15-16_1",
    polygon: [
      -59.2321,
      20.156,
      3.89,
      -59.2321,
      20.156,
      7.135,
      -59.0867,
      24.2184,
      7.135,
      -59.0867,
      24.2184,
      7.135,
      -59.0867,
      24.2184,
      3.89,
      -59.2321,
      20.156,
      3.89,
    ],
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "EG_An_5-6_1",
    polygon: [
      -57.1012,
      72.062,
      3.89,
      -57.1012,
      72.062,
      7.135,
      -56.7762,
      76.4851,
      7.135,
      -56.7762,
      76.4851,
      7.135,
      -56.7762,
      76.4851,
      3.89,
      -57.1012,
      72.062,
      3.89,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_En-Fn_15-16_2",
    polygon: [
      -35.3046,
      23.3906,
      3.89,
      -35.3046,
      23.3906,
      7.135,
      -37.4546,
      23.3981,
      7.135,
      -37.4546,
      23.3981,
      7.135,
      -37.4546,
      23.3981,
      3.89,
      -35.3046,
      23.3906,
      3.89,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "EG_Dn-En_9-10_1",
    polygon: [
      -37.9401,
      53.4,
      3.89,
      -37.9401,
      53.4,
      7.135,
      -40.0901,
      53.4075,
      7.135,
      -40.0901,
      53.4075,
      7.135,
      -40.0901,
      53.4075,
      3.89,
      -37.9401,
      53.4,
      3.89,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "2.OG_En-Fn_9-10_1",
    polygon: [
      -35.2101,
      53.3905,
      12.6,
      -35.2101,
      53.3905,
      14.86,
      -37.3601,
      53.398,
      14.86,
      -37.3601,
      53.398,
      14.86,
      -37.3601,
      53.398,
      12.6,
      -35.2101,
      53.3905,
      12.6,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "2.OG_M-N_2n-3_2",
    polygon: [
      10.27,
      90.6723,
      12.6,
      10.27,
      90.6723,
      14.86,
      8.32,
      90.6791,
      14.86,
      8.32,
      90.6791,
      14.86,
      8.32,
      90.6791,
      12.6,
      10.27,
      90.6723,
      12.6,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1.OG_En-Fn_3-4n_2",
    polygon: [
      -31.2596,
      86.5369,
      9.34,
      -31.2596,
      86.5369,
      11.555,
      -33.2096,
      86.5437,
      11.555,
      -33.2096,
      86.5437,
      11.555,
      -33.2096,
      86.5437,
      9.34,
      -31.2596,
      86.5369,
      9.34,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2.OG_Gn-H_4-5_1",
    polygon: [
      -21.2879,
      79.849,
      12.6,
      -21.2879,
      79.849,
      14.86,
      -21.2811,
      81.799,
      14.86,
      -21.2811,
      81.799,
      14.86,
      -21.2811,
      81.799,
      12.6,
      -21.2879,
      79.849,
      12.6,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1.OG_En-Fn_9-10_1",
    polygon: [
      -35.2101,
      53.3905,
      9.15,
      -35.2101,
      53.3905,
      11.41,
      -37.3601,
      53.398,
      11.41,
      -37.3601,
      53.398,
      11.41,
      -37.3601,
      53.398,
      9.15,
      -35.2101,
      53.3905,
      9.15,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "2.OG_On-O_4n-4_1",
    polygon: [
      16.0211,
      85.2239,
      12.6,
      16.0211,
      85.2239,
      14.86,
      16.0279,
      87.1739,
      14.86,
      16.0279,
      87.1739,
      14.86,
      16.0279,
      87.1739,
      12.6,
      16.0211,
      85.2239,
      12.6,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2.OG_Fn-Gn_3-4n_1",
    polygon: [
      -25.7697,
      86.5178,
      12.79,
      -25.7697,
      86.5178,
      14.86,
      -27.7196,
      86.5246,
      14.86,
      -27.7196,
      86.5246,
      14.86,
      -27.7196,
      86.5246,
      12.79,
      -25.7697,
      86.5178,
      12.79,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2.OG_An_6-7_2",
    polygon: [
      -57.3359,
      68.9639,
      12.79,
      -57.3359,
      68.9639,
      14.86,
      -57.1923,
      70.9186,
      14.86,
      -57.1923,
      70.9186,
      14.86,
      -57.1923,
      70.9186,
      12.79,
      -57.3359,
      68.9639,
      12.79,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_Bn-Cn_1-2n_1",
    polygon: [
      -46.9181,
      99.5096,
      12.79,
      -46.9181,
      99.5096,
      14.86,
      -44.9588,
      99.7954,
      14.86,
      -44.9588,
      99.7954,
      14.86,
      -44.9588,
      99.7954,
      12.79,
      -46.9181,
      99.5096,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_An_9-10_1",
    polygon: [
      -58.0718,
      52.5786,
      9.15,
      -58.0718,
      52.5786,
      11.41,
      -57.8964,
      57.4804,
      11.41,
      -57.8964,
      57.4804,
      11.41,
      -57.8964,
      57.4804,
      9.15,
      -58.0718,
      52.5786,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "1.OG_Dn-En_1-2n_1",
    polygon: [
      -43.8439,
      99.9618,
      9.16,
      -43.8439,
      99.9618,
      11.865,
      -39.4108,
      100.6085,
      11.865,
      -39.4108,
      100.6085,
      11.865,
      -39.4108,
      100.6085,
      9.16,
      -43.8439,
      99.9618,
      9.16,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "EG_Fn-Gn_1_1",
    polygon: [
      -32.7814,
      101.5718,
      3.89,
      -32.7814,
      101.5718,
      7.135,
      -28.3484,
      102.2184,
      7.135,
      -28.3484,
      102.2184,
      7.135,
      -28.3484,
      102.2184,
      3.89,
      -32.7814,
      101.5718,
      3.89,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "EG_An_7-8_1",
    polygon: [
      -57.5066,
      66.5419,
      3.89,
      -57.5066,
      66.5419,
      7.135,
      -57.1817,
      70.965,
      7.135,
      -57.1817,
      70.965,
      7.135,
      -57.1817,
      70.965,
      3.89,
      -57.5066,
      66.5419,
      3.89,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_Gn-H_1_1",
    polygon: [
      -24.911,
      102.7198,
      12.79,
      -24.911,
      102.7198,
      14.86,
      -22.9517,
      103.0056,
      14.86,
      -22.9517,
      103.0056,
      14.86,
      -22.9517,
      103.0056,
      12.79,
      -24.911,
      102.7198,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_Dn-En_15-16_2",
    polygon: [
      -38.0496,
      23.4002,
      9.15,
      -38.0496,
      23.4002,
      11.41,
      -40.1996,
      23.4077,
      11.41,
      -40.1996,
      23.4077,
      11.41,
      -40.1996,
      23.4077,
      9.15,
      -38.0496,
      23.4002,
      9.15,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "1.OG_M-N_1_1",
    polygon: [
      6.9703,
      105.9621,
      9.15,
      6.9703,
      105.9621,
      11.555,
      11.3996,
      106.3904,
      11.555,
      11.3996,
      106.3904,
      11.555,
      11.3996,
      106.3904,
      9.15,
      6.9703,
      105.9621,
      9.15,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "EG_Dn-En_15-16_1",
    polygon: [
      -38.0546,
      23.4002,
      3.89,
      -38.0546,
      23.4002,
      7.135,
      -40.2046,
      23.4077,
      7.135,
      -40.2046,
      23.4077,
      7.135,
      -40.2046,
      23.4077,
      3.89,
      -38.0546,
      23.4002,
      3.89,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "2.OG_Bn-Cn_5-6_2",
    polygon: [
      -43.0533,
      74.8339,
      12.79,
      -43.0533,
      74.8339,
      14.86,
      -43.0465,
      76.7839,
      14.86,
      -43.0465,
      76.7839,
      14.86,
      -43.0465,
      76.7839,
      12.79,
      -43.0533,
      74.8339,
      12.79,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "1.OG_Kn-L_2n-3_1",
    polygon: [
      -3.1518,
      91.6141,
      9.15,
      -3.1518,
      91.6141,
      11.555,
      -5.2868,
      91.6215,
      11.555,
      -5.2868,
      91.6215,
      11.555,
      -5.2868,
      91.6215,
      9.15,
      -3.1518,
      91.6141,
      9.15,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "1.OG_Jn-Kn_4-5_2",
    polygon: [
      -5.6636,
      83.9128,
      9.15,
      -5.6636,
      83.9128,
      11.555,
      -5.6568,
      85.8628,
      11.555,
      -5.6568,
      85.8628,
      11.555,
      -5.6568,
      85.8628,
      9.15,
      -5.6636,
      83.9128,
      9.15,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2.OG_En-Fn_9-10_2",
    polygon: [
      -32.4601,
      53.3809,
      12.6,
      -32.4601,
      53.3809,
      14.86,
      -34.6101,
      53.3884,
      14.86,
      -34.6101,
      53.3884,
      14.86,
      -34.6101,
      53.3884,
      12.6,
      -32.4601,
      53.3809,
      12.6,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2.OG_Gn-H_4-5_2",
    polygon: [
      -21.2782,
      82.6171,
      12.6,
      -21.2782,
      82.6171,
      14.86,
      -21.2714,
      84.5671,
      14.86,
      -21.2714,
      84.5671,
      14.86,
      -21.2714,
      84.5671,
      12.6,
      -21.2782,
      82.6171,
      12.6,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "1.OG_En-Fn_9-10_2",
    polygon: [
      -32.4601,
      53.3809,
      9.15,
      -32.4601,
      53.3809,
      11.41,
      -34.6101,
      53.3884,
      11.41,
      -34.6101,
      53.3884,
      11.41,
      -34.6101,
      53.3884,
      9.15,
      -32.4601,
      53.3809,
      9.15,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2.OG_N-On_2n-3_1",
    polygon: [
      13.02,
      90.6627,
      12.6,
      13.02,
      90.6627,
      14.86,
      11.07,
      90.6695,
      14.86,
      11.07,
      90.6695,
      14.86,
      11.07,
      90.6695,
      12.6,
      13.02,
      90.6627,
      12.6,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2.OG_On-O_3-4n_1",
    polygon: [
      16.0307,
      87.9739,
      12.6,
      16.0307,
      87.9739,
      14.86,
      16.0375,
      89.9238,
      14.86,
      16.0375,
      89.9238,
      14.86,
      16.0375,
      89.9238,
      12.6,
      16.0307,
      87.9739,
      12.6,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "1.OG_Fn-Gn_3-4n_1",
    polygon: [
      -28.5146,
      86.5274,
      9.34,
      -28.5146,
      86.5274,
      11.555,
      -30.4646,
      86.5342,
      11.555,
      -30.4646,
      86.5342,
      11.555,
      -30.4646,
      86.5342,
      9.34,
      -28.5146,
      86.5274,
      9.34,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2.OG_Fn-Gn_3-4n_2",
    polygon: [
      -28.5196,
      86.5274,
      12.79,
      -28.5196,
      86.5274,
      14.86,
      -30.4696,
      86.5342,
      14.86,
      -30.4696,
      86.5342,
      14.86,
      -30.4696,
      86.5342,
      12.79,
      -28.5196,
      86.5274,
      12.79,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "2.OG_An_7-8_1",
    polygon: [
      -57.5394,
      66.1938,
      12.79,
      -57.5394,
      66.1938,
      14.86,
      -57.3932,
      68.1835,
      14.86,
      -57.3932,
      68.1835,
      14.86,
      -57.3932,
      68.1835,
      12.79,
      -57.5394,
      66.1938,
      12.79,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2.OG_Bn-Cn_1-2n_2",
    polygon: [
      -44.1672,
      99.9109,
      12.79,
      -44.1672,
      99.9109,
      14.86,
      -42.2079,
      100.1967,
      14.86,
      -42.2079,
      100.1967,
      14.86,
      -42.2079,
      100.1967,
      12.79,
      -44.1672,
      99.9109,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_An_10-11_1",
    polygon: [
      -58.2687,
      47.0771,
      9.15,
      -58.2687,
      47.0771,
      11.41,
      -58.0933,
      51.979,
      11.41,
      -58.0933,
      51.979,
      11.41,
      -58.0933,
      51.979,
      9.15,
      -58.2687,
      47.0771,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "EG_En-Fn_1-2n_1",
    polygon: [
      -38.303,
      100.7663,
      3.89,
      -38.303,
      100.7663,
      7.135,
      -33.8699,
      101.413,
      7.135,
      -33.8699,
      101.413,
      7.135,
      -33.8699,
      101.413,
      3.89,
      -38.303,
      100.7663,
      3.89,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "EG_Bn-Cn_15-16_1",
    polygon: [
      -43.2472,
      19.7833,
      3.89,
      -43.2472,
      19.7833,
      7.135,
      -43.2402,
      21.8033,
      7.135,
      -43.2402,
      21.8033,
      7.135,
      -43.2402,
      21.8033,
      3.89,
      -43.2472,
      19.7833,
      3.89,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "1.OG_Bn-Cn_1-2n_1",
    polygon: [
      -49.3258,
      99.162,
      9.16,
      -49.3258,
      99.162,
      11.865,
      -44.8928,
      99.8087,
      11.865,
      -44.8928,
      99.8087,
      11.865,
      -44.8928,
      99.8087,
      9.16,
      -49.3258,
      99.162,
      9.16,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2.OG_An_1-2n_1",
    polygon: [
      -54.5473,
      96.353,
      12.79,
      -54.5473,
      96.353,
      14.86,
      -53.049,
      97.9159,
      14.86,
      -53.049,
      97.9159,
      14.86,
      -53.049,
      97.9159,
      12.79,
      -54.5473,
      96.353,
      12.79,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_An_8-9_1",
    polygon: [
      -57.8666,
      58.3141,
      3.89,
      -57.8666,
      58.3141,
      7.135,
      -57.709,
      62.7163,
      7.135,
      -57.709,
      62.7163,
      7.135,
      -57.709,
      62.7163,
      3.89,
      -57.8666,
      58.3141,
      3.89,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "1.OG_En-Fn_15-16_1",
    polygon: [
      -35.2996,
      23.3906,
      9.15,
      -35.2996,
      23.3906,
      11.41,
      -37.4496,
      23.3981,
      11.41,
      -37.4496,
      23.3981,
      11.41,
      -37.4496,
      23.3981,
      9.15,
      -35.2996,
      23.3906,
      9.15,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "1.OG_Kn-L_1_1",
    polygon: [
      -4.0284,
      104.8984,
      9.15,
      -4.0284,
      104.8984,
      11.555,
      0.4009,
      105.3268,
      11.555,
      0.4009,
      105.3268,
      11.555,
      0.4009,
      105.3268,
      9.15,
      -4.0284,
      104.8984,
      9.15,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "EG_Dn-En_10-11_1",
    polygon: [
      -40.5726,
      49.8092,
      3.89,
      -40.5726,
      49.8092,
      7.135,
      -40.5651,
      51.9591,
      7.135,
      -40.5651,
      51.9591,
      7.135,
      -40.5651,
      51.9591,
      3.89,
      -40.5726,
      49.8092,
      3.89,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2.OG_Fn-Gn_9-10_1",
    polygon: [
      -29.7101,
      53.3713,
      12.6,
      -29.7101,
      53.3713,
      14.86,
      -31.8601,
      53.3788,
      14.86,
      -31.8601,
      53.3788,
      14.86,
      -31.8601,
      53.3788,
      12.6,
      -29.7101,
      53.3713,
      12.6,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "1.OG_Fn-Gn_9-10_1",
    polygon: [
      -29.7101,
      53.3713,
      9.15,
      -29.7101,
      53.3713,
      11.41,
      -31.8601,
      53.3788,
      11.41,
      -31.8601,
      53.3788,
      11.41,
      -31.8601,
      53.3788,
      9.15,
      -29.7101,
      53.3713,
      9.15,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "2.OG_L-M_2n-3_1",
    polygon: [
      4.7701,
      90.6915,
      12.6,
      4.7701,
      90.6915,
      14.86,
      2.8201,
      90.6983,
      14.86,
      2.8201,
      90.6983,
      14.86,
      2.8201,
      90.6983,
      12.6,
      4.7701,
      90.6915,
      12.6,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2.OG_En-Fn_3-4n_1",
    polygon: [
      -31.2646,
      86.537,
      12.79,
      -31.2646,
      86.537,
      14.86,
      -33.2146,
      86.5438,
      14.86,
      -33.2146,
      86.5438,
      14.86,
      -33.2146,
      86.5438,
      12.79,
      -31.2646,
      86.537,
      12.79,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "1.OG_Fn-Gn_3-4n_2",
    polygon: [
      -25.7647,
      86.5178,
      9.34,
      -25.7647,
      86.5178,
      11.555,
      -27.7146,
      86.5246,
      11.555,
      -27.7146,
      86.5246,
      11.555,
      -27.7146,
      86.5246,
      9.34,
      -25.7647,
      86.5178,
      9.34,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "2.OG_On-O_4-5_2",
    polygon: [
      16.0067,
      81.0973,
      12.6,
      16.0067,
      81.0973,
      14.86,
      16.0093,
      81.8223,
      14.86,
      16.0093,
      81.8223,
      14.86,
      16.0093,
      81.8223,
      12.6,
      16.0067,
      81.0973,
      12.6,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2.OG_An_11-12_1",
    polygon: [
      -57.0565,
      41.5638,
      12.02,
      -57.0565,
      41.5638,
      14.84,
      -56.9802,
      43.7124,
      14.84,
      -56.9802,
      43.7124,
      14.84,
      -56.9802,
      43.7124,
      12.02,
      -57.0565,
      41.5638,
      12.02,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2.OG_An_8-9_1",
    polygon: [
      -57.7769,
      60.8201,
      12.6,
      -57.7769,
      60.8201,
      14.86,
      -57.7,
      62.9687,
      14.86,
      -57.7,
      62.9687,
      14.86,
      -57.7,
      62.9687,
      12.6,
      -57.7769,
      60.8201,
      12.6,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2.OG_Dn-En_1-2n_1",
    polygon: [
      -41.4212,
      100.3114,
      12.79,
      -41.4212,
      100.3114,
      14.86,
      -39.462,
      100.5972,
      14.86,
      -39.462,
      100.5972,
      14.86,
      -39.462,
      100.5972,
      12.79,
      -41.4212,
      100.3114,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_An_12-13_1",
    polygon: [
      -58.6626,
      36.0672,
      9.15,
      -58.6626,
      36.0672,
      11.41,
      -58.4871,
      40.969,
      11.41,
      -58.4871,
      40.969,
      11.41,
      -58.4871,
      40.969,
      9.15,
      -58.6626,
      36.0672,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2.OG_Dn-En_15-16_1",
    polygon: [
      -38.0496,
      23.4002,
      12.6,
      -38.0496,
      23.4002,
      14.86,
      -40.1996,
      23.4077,
      14.86,
      -40.1996,
      23.4077,
      14.86,
      -40.1996,
      23.4077,
      12.6,
      -38.0496,
      23.4002,
      12.6,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2.OG_An-Bn_1-2n_2",
    polygon: [
      -52.4886,
      98.2774,
      12.79,
      -52.4886,
      98.2774,
      14.86,
      -50.4471,
      98.9979,
      14.86,
      -50.4471,
      98.9979,
      14.86,
      -50.4471,
      98.9979,
      12.79,
      -52.4886,
      98.2774,
      12.79,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_An_3-4n_1",
    polygon: [
      -55.8959,
      88.5349,
      9.16,
      -55.8959,
      88.5349,
      11.555,
      -55.5736,
      92.9582,
      11.555,
      -55.5736,
      92.9582,
      11.555,
      -55.5736,
      92.9582,
      9.16,
      -55.8959,
      88.5349,
      9.16,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_Dn-En_1-2n_1",
    polygon: [
      -43.8522,
      99.9605,
      3.89,
      -43.8522,
      99.9605,
      7.135,
      -39.4192,
      100.6073,
      7.135,
      -39.4192,
      100.6073,
      7.135,
      -39.4192,
      100.6073,
      3.89,
      -43.8522,
      99.9605,
      3.89,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "EG_An_9-10_1",
    polygon: [
      -58.0627,
      52.8326,
      3.89,
      -58.0627,
      52.8326,
      7.135,
      -57.9052,
      57.2348,
      7.135,
      -57.9052,
      57.2348,
      7.135,
      -57.9052,
      57.2348,
      3.89,
      -58.0627,
      52.8326,
      3.89,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "EG_H-In_1_1",
    polygon: [
      -21.0761,
      103.2498,
      3.89,
      -21.0761,
      103.2498,
      7.135,
      -16.6467,
      103.6781,
      7.135,
      -16.6467,
      103.6781,
      7.135,
      -16.6467,
      103.6781,
      3.89,
      -21.0761,
      103.2498,
      3.89,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1.OG_En-Fn_15-16_2",
    polygon: [
      -32.5896,
      23.3812,
      9.15,
      -32.5896,
      23.3812,
      11.41,
      -34.7396,
      23.3887,
      11.41,
      -34.7396,
      23.3887,
      11.41,
      -34.7396,
      23.3887,
      9.15,
      -32.5896,
      23.3812,
      9.15,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1.OG_Jn-Kn_1_1",
    polygon: [
      -9.5527,
      104.3642,
      9.15,
      -9.5527,
      104.3642,
      11.555,
      -5.1233,
      104.7925,
      11.555,
      -5.1233,
      104.7925,
      11.555,
      -5.1233,
      104.7925,
      9.15,
      -9.5527,
      104.3642,
      9.15,
    ],
    "04-15 17:00": 1,
  },
  {
    ID: "2.OG_Kn-L_1_2",
    polygon: [
      -1.2386,
      103.7763,
      12.02,
      -1.2386,
      103.7763,
      14.84,
      0.7172,
      103.9655,
      14.84,
      0.7172,
      103.9655,
      14.84,
      0.7172,
      103.9655,
      12.02,
      -1.2386,
      103.7763,
      12.02,
    ],
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_N-On_2n-3_2",
    polygon: [
      14.405,
      90.6579,
      12.6,
      14.405,
      90.6579,
      14.86,
      13.68,
      90.6604,
      14.86,
      13.68,
      90.6604,
      14.86,
      13.68,
      90.6604,
      12.6,
      14.405,
      90.6579,
      12.6,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2.OG_Bn-Cn_6-7_1",
    polygon: [
      -43.0629,
      72.084,
      12.79,
      -43.0629,
      72.084,
      14.86,
      -43.0561,
      74.0339,
      14.86,
      -43.0561,
      74.0339,
      14.86,
      -43.0561,
      74.0339,
      12.79,
      -43.0629,
      72.084,
      12.79,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "1.OG_Jn-Kn_3-4n_1",
    polygon: [
      -5.654,
      86.6627,
      9.15,
      -5.654,
      86.6627,
      11.555,
      -5.6472,
      88.6127,
      11.555,
      -5.6472,
      88.6127,
      11.555,
      -5.6472,
      88.6127,
      9.15,
      -5.654,
      86.6627,
      9.15,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2.OG_Fn-Gn_9-10_2",
    polygon: [
      -26.9577,
      53.3618,
      12.6,
      -26.9577,
      53.3618,
      14.86,
      -29.1076,
      53.3692,
      14.86,
      -29.1076,
      53.3692,
      14.86,
      -29.1076,
      53.3692,
      12.6,
      -26.9577,
      53.3618,
      12.6,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "3.OG_Gn-H_1_1",
    polygon: [
      -24.504,
      102.7792,
      16.05,
      -24.504,
      102.7792,
      18.12,
      -22.5448,
      103.065,
      18.12,
      -22.5448,
      103.065,
      18.12,
      -22.5448,
      103.065,
      16.05,
      -24.504,
      102.7792,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_10-11_1",
    polygon: [
      -56.8605,
      47.0803,
      12.02,
      -56.8605,
      47.0803,
      14.84,
      -56.7842,
      49.229,
      14.84,
      -56.7842,
      49.229,
      14.84,
      -56.7842,
      49.229,
      12.02,
      -56.8605,
      47.0803,
      12.02,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1.OG_Fn-Gn_9-10_2",
    polygon: [
      -26.9577,
      53.3618,
      9.15,
      -26.9577,
      53.3618,
      11.41,
      -29.1076,
      53.3692,
      11.41,
      -29.1076,
      53.3692,
      11.41,
      -29.1076,
      53.3692,
      9.15,
      -26.9577,
      53.3618,
      9.15,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "2.OG_Jn-Kn_5-6_1",
    polygon: [
      -5.6923,
      75.6678,
      12.6,
      -5.6923,
      75.6678,
      14.86,
      -5.6898,
      76.3928,
      14.86,
      -5.6898,
      76.3928,
      14.86,
      -5.6898,
      76.3928,
      12.6,
      -5.6923,
      75.6678,
      12.6,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "2.OG_En-Fn_3-4n_2",
    polygon: [
      -33.9996,
      86.5465,
      12.79,
      -33.9996,
      86.5465,
      14.86,
      -35.1996,
      86.5507,
      14.86,
      -35.1996,
      86.5507,
      14.86,
      -35.1996,
      86.5507,
      12.79,
      -33.9996,
      86.5465,
      12.79,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1.OG_Gn-H_3-4n_1",
    polygon: [
      -23.0147,
      86.5082,
      9.34,
      -23.0147,
      86.5082,
      11.555,
      -24.9647,
      86.515,
      11.555,
      -24.9647,
      86.515,
      11.555,
      -24.9647,
      86.515,
      9.34,
      -23.0147,
      86.5082,
      9.34,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2.OG_Dn-En_1-2n_2",
    polygon: [
      -38.6654,
      100.7134,
      12.79,
      -38.6654,
      100.7134,
      14.86,
      -36.7061,
      100.9992,
      14.86,
      -36.7061,
      100.9992,
      14.86,
      -36.7061,
      100.9992,
      12.79,
      -38.6654,
      100.7134,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_An_13-14_1",
    polygon: [
      -58.8595,
      30.5657,
      9.15,
      -58.8595,
      30.5657,
      11.41,
      -58.6841,
      35.4675,
      11.41,
      -58.6841,
      35.4675,
      11.41,
      -58.6841,
      35.4675,
      9.15,
      -58.8595,
      30.5657,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2.OG_An_2n-3_2",
    polygon: [
      -55.5236,
      93.6637,
      12.79,
      -55.5236,
      93.6637,
      14.86,
      -54.9049,
      95.7384,
      14.86,
      -54.9049,
      95.7384,
      14.86,
      -54.9049,
      95.7384,
      12.79,
      -55.5236,
      93.6637,
      12.79,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_En-Fn_15-16_1",
    polygon: [
      -35.2996,
      23.3906,
      12.6,
      -35.2996,
      23.3906,
      14.86,
      -37.4496,
      23.3981,
      14.86,
      -37.4496,
      23.3981,
      14.86,
      -37.4496,
      23.3981,
      12.6,
      -35.2996,
      23.3906,
      12.6,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1.OG_An_4-5_1",
    polygon: [
      -56.2967,
      83.0345,
      9.16,
      -56.2967,
      83.0345,
      11.555,
      -55.9744,
      87.4578,
      11.555,
      -55.9744,
      87.4578,
      11.555,
      -55.9744,
      87.4578,
      9.16,
      -56.2967,
      83.0345,
      9.16,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "EG_An_10-11_1",
    polygon: [
      -58.2596,
      47.3311,
      3.89,
      -58.2596,
      47.3311,
      7.135,
      -58.1021,
      51.7333,
      7.135,
      -58.1021,
      51.7333,
      7.135,
      -58.1021,
      51.7333,
      3.89,
      -58.2596,
      47.3311,
      3.89,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "EG_Bn-Cn_1-2n_1",
    polygon: [
      -49.3518,
      99.1582,
      3.89,
      -49.3518,
      99.1582,
      7.135,
      -44.9187,
      99.8049,
      7.135,
      -44.9187,
      99.8049,
      7.135,
      -44.9187,
      99.8049,
      3.89,
      -49.3518,
      99.1582,
      3.89,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "EG_In-Jn_1_1",
    polygon: [
      -15.5916,
      103.7802,
      3.89,
      -15.5916,
      103.7802,
      7.135,
      -11.1623,
      104.2085,
      7.135,
      -11.1623,
      104.2085,
      7.135,
      -11.1623,
      104.2085,
      3.89,
      -15.5916,
      103.7802,
      3.89,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1.OG_In-Jn_1_1",
    polygon: [
      -15.057,
      103.8319,
      9.15,
      -15.057,
      103.8319,
      11.555,
      -10.6276,
      104.2602,
      11.555,
      -10.6276,
      104.2602,
      11.555,
      -10.6276,
      104.2602,
      9.15,
      -15.057,
      103.8319,
      9.15,
    ],
    "04-15 17:00": 1,
  },
  {
    ID: "1.OG_Fn-Gn_15-16_1",
    polygon: [
      -29.8196,
      23.3715,
      9.15,
      -29.8196,
      23.3715,
      11.41,
      -31.9696,
      23.379,
      11.41,
      -31.9696,
      23.379,
      11.41,
      -31.9696,
      23.379,
      9.15,
      -29.8196,
      23.3715,
      9.15,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2.OG_L-M_1_1",
    polygon: [
      1.5135,
      104.0425,
      12.02,
      1.5135,
      104.0425,
      14.84,
      3.4694,
      104.2316,
      14.84,
      3.4694,
      104.2316,
      14.84,
      3.4694,
      104.2316,
      12.02,
      1.5135,
      104.0425,
      12.02,
    ],
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_11-12_2",
    polygon: [
      -56.9582,
      44.3321,
      12.04,
      -56.9582,
      44.3321,
      14.86,
      -56.8818,
      46.4807,
      14.86,
      -56.8818,
      46.4807,
      14.86,
      -56.8818,
      46.4807,
      12.04,
      -56.9582,
      44.3321,
      12.04,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "EG_Dn-En_10-11_2",
    polygon: [
      -40.5823,
      47.0242,
      3.89,
      -40.5823,
      47.0242,
      7.135,
      -40.5748,
      49.1742,
      7.135,
      -40.5748,
      49.1742,
      7.135,
      -40.5748,
      49.1742,
      3.89,
      -40.5823,
      47.0242,
      3.89,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "3.OG_Fn-Gn_1_1",
    polygon: [
      -27.2747,
      102.375,
      16.05,
      -27.2747,
      102.375,
      18.12,
      -25.3155,
      102.6608,
      18.12,
      -25.3155,
      102.6608,
      18.12,
      -25.3155,
      102.6608,
      16.05,
      -27.2747,
      102.375,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_L-M_2n-3_1",
    polygon: [
      6.1501,
      90.6867,
      9.15,
      6.1501,
      90.6867,
      11.555,
      4.2001,
      90.6934,
      11.555,
      4.2001,
      90.6934,
      11.555,
      4.2001,
      90.6934,
      9.15,
      6.1501,
      90.6867,
      9.15,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "1.OG_Gn-H_5-6_1",
    polygon: [
      -21.3023,
      75.7172,
      9.15,
      -21.3023,
      75.7172,
      11.555,
      -21.2955,
      77.6672,
      11.555,
      -21.2955,
      77.6672,
      11.555,
      -21.2955,
      77.6672,
      9.15,
      -21.3023,
      75.7172,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "2.OG_An_10-11_2",
    polygon: [
      -56.7636,
      49.8086,
      12.02,
      -56.7636,
      49.8086,
      14.84,
      -56.6899,
      51.8823,
      14.84,
      -56.6899,
      51.8823,
      14.84,
      -56.6899,
      51.8823,
      12.02,
      -56.7636,
      49.8086,
      12.02,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2.OG_Jn-Kn_3-4n_1",
    polygon: [
      -5.6492,
      88.0477,
      12.6,
      -5.6492,
      88.0477,
      14.86,
      -5.6424,
      90.0127,
      14.86,
      -5.6424,
      90.0127,
      14.86,
      -5.6424,
      90.0127,
      12.6,
      -5.6492,
      88.0477,
      12.6,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "1.OG_An_14-15_1",
    polygon: [
      -59.0564,
      25.0642,
      9.15,
      -59.0564,
      25.0642,
      11.41,
      -58.881,
      29.9661,
      11.41,
      -58.881,
      29.9661,
      11.41,
      -58.881,
      29.9661,
      9.15,
      -59.0564,
      25.0642,
      9.15,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "2.OG_En-Fn_1-2n_1",
    polygon: [
      -35.9145,
      101.1147,
      12.79,
      -35.9145,
      101.1147,
      14.86,
      -33.9553,
      101.4005,
      14.86,
      -33.9553,
      101.4005,
      14.86,
      -33.9553,
      101.4005,
      12.79,
      -35.9145,
      101.1147,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_4-5_2",
    polygon: [
      -56.5273,
      79.9742,
      12.79,
      -56.5273,
      79.9742,
      14.86,
      -56.3837,
      81.929,
      14.86,
      -56.3837,
      81.929,
      14.86,
      -56.3837,
      81.929,
      12.79,
      -56.5273,
      79.9742,
      12.79,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "2.OG_En-Fn_15-16_2",
    polygon: [
      -32.5896,
      23.3812,
      12.6,
      -32.5896,
      23.3812,
      14.86,
      -34.7396,
      23.3887,
      14.86,
      -34.7396,
      23.3887,
      14.86,
      -34.7396,
      23.3887,
      12.6,
      -32.5896,
      23.3812,
      12.6,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1.OG_An_5-6_1",
    polygon: [
      -56.6988,
      77.5392,
      9.16,
      -56.6988,
      77.5392,
      11.555,
      -56.3739,
      81.9622,
      11.555,
      -56.3739,
      81.9622,
      11.555,
      -56.3739,
      81.9622,
      9.16,
      -56.6988,
      77.5392,
      9.16,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "EG_An_12-13_1",
    polygon: [
      -58.6535,
      36.3207,
      3.89,
      -58.6535,
      36.3207,
      7.135,
      -58.496,
      40.7229,
      7.135,
      -58.496,
      40.7229,
      7.135,
      -58.496,
      40.7229,
      3.89,
      -58.6535,
      36.3207,
      3.89,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "EG_An_4-5_1",
    polygon: [
      -56.2965,
      83.037,
      3.89,
      -56.2965,
      83.037,
      7.135,
      -55.9742,
      87.4603,
      7.135,
      -55.9742,
      87.4603,
      7.135,
      -55.9742,
      87.4603,
      3.89,
      -56.2965,
      83.037,
      3.89,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "EG_Jn-Kn_1_1",
    polygon: [
      -10.0873,
      104.3125,
      3.89,
      -10.0873,
      104.3125,
      7.135,
      -5.658,
      104.7408,
      7.135,
      -5.658,
      104.7408,
      7.135,
      -5.658,
      104.7408,
      3.89,
      -10.0873,
      104.3125,
      3.89,
    ],
    "04-15 17:00": 1,
  },
  {
    ID: "1.OG_H-In_1_1",
    polygon: [
      -20.5613,
      103.2996,
      9.15,
      -20.5613,
      103.2996,
      11.555,
      -16.132,
      103.7279,
      11.555,
      -16.132,
      103.7279,
      11.555,
      -16.132,
      103.7279,
      9.15,
      -20.5613,
      103.2996,
      9.15,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1.OG_Fn-Gn_15-16_2",
    polygon: [
      -27.0597,
      23.3619,
      9.15,
      -27.0597,
      23.3619,
      11.41,
      -29.2096,
      23.3694,
      11.41,
      -29.2096,
      23.3694,
      11.41,
      -29.2096,
      23.3694,
      9.15,
      -27.0597,
      23.3619,
      9.15,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "2.OG_Jn-Kn_1_2",
    polygon: [
      -6.7484,
      104.6354,
      12.6,
      -6.7484,
      104.6354,
      14.86,
      -4.4939,
      104.8534,
      14.86,
      -4.4939,
      104.8534,
      14.86,
      -4.4939,
      104.8534,
      12.6,
      -6.7484,
      104.6354,
      12.6,
    ],
    "04-15 05:00": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_12-13_1",
    polygon: [
      -57.1542,
      38.8155,
      12.04,
      -57.1542,
      38.8155,
      14.86,
      -57.0778,
      40.9642,
      14.86,
      -57.0778,
      40.9642,
      14.86,
      -57.0778,
      40.9642,
      12.04,
      -57.1542,
      38.8155,
      12.04,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2.OG_L-M_1_2",
    polygon: [
      3.8675,
      104.2701,
      12.02,
      3.8675,
      104.2701,
      14.84,
      5.8234,
      104.4593,
      14.84,
      5.8234,
      104.4593,
      14.84,
      5.8234,
      104.4593,
      12.02,
      3.8675,
      104.2701,
      12.02,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_Fn-Gn_1_2",
    polygon: [
      -30.0058,
      101.9766,
      16.05,
      -30.0058,
      101.9766,
      18.12,
      -28.0466,
      102.2624,
      18.12,
      -28.0466,
      102.2624,
      18.12,
      -28.0466,
      102.2624,
      16.05,
      -30.0058,
      101.9766,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_Bn-Cn_6-7_1",
    polygon: [
      -43.0725,
      69.3331,
      9.34,
      -43.0725,
      69.3331,
      11.555,
      -43.0657,
      71.283,
      11.555,
      -43.0657,
      71.283,
      11.555,
      -43.0657,
      71.283,
      9.34,
      -43.0725,
      69.3331,
      9.34,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "2.OG_Bn-Cn_6-7_2",
    polygon: [
      -43.0725,
      69.329,
      12.79,
      -43.0725,
      69.329,
      14.86,
      -43.0657,
      71.279,
      14.86,
      -43.0657,
      71.279,
      14.86,
      -43.0657,
      71.279,
      12.79,
      -43.0725,
      69.329,
      12.79,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "2.OG_En-Fn_1-2n_2",
    polygon: [
      -33.1636,
      101.516,
      12.79,
      -33.1636,
      101.516,
      14.86,
      -31.2044,
      101.8018,
      14.86,
      -31.2044,
      101.8018,
      14.86,
      -31.2044,
      101.8018,
      12.79,
      -33.1636,
      101.516,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_9-10_2",
    polygon: [
      -58.0868,
      52.1606,
      12.6,
      -58.0868,
      52.1606,
      14.86,
      -57.9943,
      54.744,
      14.86,
      -57.9943,
      54.744,
      14.86,
      -57.9943,
      54.744,
      12.6,
      -58.0868,
      52.1606,
      12.6,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2.OG_Bn-Cn_15-16_1",
    polygon: [
      -43.2472,
      19.7833,
      12.6,
      -43.2472,
      19.7833,
      14.86,
      -43.2402,
      21.8033,
      14.86,
      -43.2402,
      21.8033,
      14.86,
      -43.2402,
      21.8033,
      12.6,
      -43.2472,
      19.7833,
      12.6,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "2.OG_An_8-9_2",
    polygon: [
      -57.8752,
      58.0718,
      12.6,
      -57.8752,
      58.0718,
      14.86,
      -57.7983,
      60.2205,
      14.86,
      -57.7983,
      60.2205,
      14.86,
      -57.7983,
      60.2205,
      12.6,
      -57.8752,
      58.0718,
      12.6,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2.OG_An_5-6_1",
    polygon: [
      -56.7293,
      77.2216,
      12.79,
      -56.7293,
      77.2216,
      14.86,
      -56.5857,
      79.1764,
      14.86,
      -56.5857,
      79.1764,
      14.86,
      -56.5857,
      79.1764,
      12.79,
      -56.7293,
      77.2216,
      12.79,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1.OG_An_6-7_1",
    polygon: [
      -57.1028,
      72.039,
      9.16,
      -57.1028,
      72.039,
      11.555,
      -56.7779,
      76.4621,
      11.555,
      -56.7779,
      76.4621,
      11.555,
      -56.7779,
      76.4621,
      9.16,
      -57.1028,
      72.039,
      9.16,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_M-N_2n-3_1",
    polygon: [
      8.9,
      90.6771,
      9.15,
      8.9,
      90.6771,
      11.555,
      6.95,
      90.6839,
      11.555,
      6.95,
      90.6839,
      11.555,
      6.95,
      90.6839,
      9.15,
      8.9,
      90.6771,
      9.15,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1.OG_Gn-H_5-6_2",
    polygon: [
      -21.2926,
      78.4872,
      9.15,
      -21.2926,
      78.4872,
      11.555,
      -21.2858,
      80.4372,
      11.555,
      -21.2858,
      80.4372,
      11.555,
      -21.2858,
      80.4372,
      9.15,
      -21.2926,
      78.4872,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "2.OG_Kn-L_2n-3_1",
    polygon: [
      -3.1518,
      91.6141,
      12.6,
      -3.1518,
      91.6141,
      14.86,
      -5.2868,
      91.6215,
      14.86,
      -5.2868,
      91.6215,
      14.86,
      -5.2868,
      91.6215,
      12.6,
      -3.1518,
      91.6141,
      12.6,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "2.OG_Bn-Cn_5-6_1",
    polygon: [
      -43.0437,
      77.5839,
      12.79,
      -43.0437,
      77.5839,
      14.86,
      -43.0396,
      78.7839,
      14.86,
      -43.0396,
      78.7839,
      14.86,
      -43.0396,
      78.7839,
      12.79,
      -43.0437,
      77.5839,
      12.79,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "EG_An_13-14_1",
    polygon: [
      -58.8504,
      30.8192,
      3.89,
      -58.8504,
      30.8192,
      7.135,
      -58.6929,
      35.2214,
      7.135,
      -58.6929,
      35.2214,
      7.135,
      -58.6929,
      35.2214,
      3.89,
      -58.8504,
      30.8192,
      3.89,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "1.OG_Cn-Dn_4n-4_1",
    polygon: [
      -43.0729,
      84.7916,
      9.34,
      -43.0729,
      84.7916,
      11.815,
      -41.2611,
      86.6003,
      11.815,
      -41.2611,
      86.6003,
      11.815,
      -41.2611,
      86.6003,
      9.34,
      -43.0729,
      84.7916,
      9.34,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "2.OG_Fn-Gn_15-16_1",
    polygon: [
      -29.8196,
      23.3715,
      12.6,
      -29.8196,
      23.3715,
      14.86,
      -31.9696,
      23.379,
      14.86,
      -31.9696,
      23.379,
      14.86,
      -31.9696,
      23.379,
      12.6,
      -29.8196,
      23.3715,
      12.6,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "EG_An_2n-3_1",
    polygon: [
      -55.8945,
      88.5549,
      3.89,
      -55.8945,
      88.5549,
      7.135,
      -55.5722,
      92.9781,
      7.135,
      -55.5722,
      92.9781,
      7.135,
      -55.5722,
      92.9781,
      3.89,
      -55.8945,
      88.5549,
      3.89,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2.OG_Jn-Kn_5-6_2",
    polygon: [
      -5.6876,
      77.0303,
      12.6,
      -5.6876,
      77.0303,
      14.86,
      -5.6807,
      78.9953,
      14.86,
      -5.6807,
      78.9953,
      14.86,
      -5.6807,
      78.9953,
      12.6,
      -5.6876,
      77.0303,
      12.6,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2.OG_N-On_1_2",
    polygon: [
      11.7928,
      106.4284,
      12.6,
      11.7928,
      106.4284,
      14.86,
      14.0721,
      106.6489,
      14.86,
      14.0721,
      106.6489,
      14.86,
      14.0721,
      106.6489,
      12.6,
      11.7928,
      106.4284,
      12.6,
    ],
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_12-13_2",
    polygon: [
      -57.2492,
      36.1422,
      12.04,
      -57.2492,
      36.1422,
      14.84,
      -57.1755,
      38.2159,
      14.86,
      -57.1755,
      38.2159,
      14.86,
      -57.1755,
      38.2159,
      12.04,
      -57.2492,
      36.1422,
      12.04,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2.OG_M-N_1_1",
    polygon: [
      6.6197,
      104.5363,
      12.02,
      6.6197,
      104.5363,
      14.84,
      8.5756,
      104.7254,
      14.84,
      8.5756,
      104.7254,
      14.84,
      8.5756,
      104.7254,
      12.02,
      6.6197,
      104.5363,
      12.02,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_L-M_2n-3_2",
    polygon: [
      3.5601,
      90.6957,
      9.15,
      3.5601,
      90.6957,
      11.555,
      2.8351,
      90.6982,
      11.555,
      2.8351,
      90.6982,
      11.555,
      2.8351,
      90.6982,
      9.15,
      3.5601,
      90.6957,
      9.15,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "3.OG_En-Fn_1_1",
    polygon: [
      -32.7567,
      101.5754,
      16.05,
      -32.7567,
      101.5754,
      18.12,
      -30.7974,
      101.8612,
      18.12,
      -30.7974,
      101.8612,
      18.12,
      -30.7974,
      101.8612,
      16.05,
      -32.7567,
      101.5754,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_Fn-Gn_1_1",
    polygon: [
      -30.4128,
      101.9173,
      12.79,
      -30.4128,
      101.9173,
      14.86,
      -28.4535,
      102.2031,
      14.86,
      -28.4535,
      102.2031,
      14.86,
      -28.4535,
      102.2031,
      12.79,
      -30.4128,
      101.9173,
      12.79,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_5-6_2",
    polygon: [
      -56.9315,
      74.469,
      12.79,
      -56.9315,
      74.469,
      14.86,
      -56.7879,
      76.4238,
      14.86,
      -56.7879,
      76.4238,
      14.86,
      -56.7879,
      76.4238,
      12.79,
      -56.9315,
      74.469,
      12.79,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_9-10_3",
    polygon: [
      -57.0073,
      52.4014,
      12.6,
      -57.0073,
      52.4014,
      14.86,
      -58.1673,
      52.4055,
      14.86,
      -58.1673,
      52.4055,
      14.86,
      -58.1673,
      52.4055,
      12.6,
      -57.0073,
      52.4014,
      12.6,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1.OG_An_7-8_1",
    polygon: [
      -57.5069,
      66.5388,
      9.16,
      -57.5069,
      66.5388,
      11.555,
      -57.182,
      70.9619,
      11.555,
      -57.182,
      70.9619,
      11.555,
      -57.182,
      70.9619,
      9.16,
      -57.5069,
      66.5388,
      9.16,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_Dn-En_15-16_2",
    polygon: [
      -40.8196,
      23.4099,
      12.6,
      -40.8196,
      23.4099,
      14.86,
      -42.8046,
      23.4168,
      14.86,
      -42.8046,
      23.4168,
      14.86,
      -42.8046,
      23.4168,
      12.6,
      -40.8196,
      23.4099,
      12.6,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "EG_An_14-15_1",
    polygon: [
      -59.0474,
      25.3177,
      3.89,
      -59.0474,
      25.3177,
      7.135,
      -58.8898,
      29.7199,
      7.135,
      -58.8898,
      29.7199,
      7.135,
      -58.8898,
      29.7199,
      3.89,
      -59.0474,
      25.3177,
      3.89,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "EG_An_4-5_2",
    polygon: [
      -56.6971,
      77.5622,
      3.89,
      -56.6971,
      77.5622,
      7.135,
      -56.3722,
      81.9853,
      7.135,
      -56.3722,
      81.9853,
      7.135,
      -56.3722,
      81.9853,
      3.89,
      -56.6971,
      77.5622,
      3.89,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2.OG_Fn-Gn_15-16_2",
    polygon: [
      -27.0597,
      23.3619,
      12.6,
      -27.0597,
      23.3619,
      14.86,
      -29.2096,
      23.3694,
      14.86,
      -29.2096,
      23.3694,
      14.86,
      -29.2096,
      23.3694,
      12.6,
      -27.0597,
      23.3619,
      12.6,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "1.OG_Gn-H_4-5_1",
    polygon: [
      -21.283,
      81.2372,
      9.15,
      -21.283,
      81.2372,
      11.555,
      -21.2762,
      83.1871,
      11.555,
      -21.2762,
      83.1871,
      11.555,
      -21.2762,
      83.1871,
      9.15,
      -21.283,
      81.2372,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1.OG_M-N_2n-3_2",
    polygon: [
      11.65,
      90.6675,
      9.15,
      11.65,
      90.6675,
      11.555,
      9.7,
      90.6743,
      11.555,
      9.7,
      90.6743,
      11.555,
      9.7,
      90.6743,
      9.15,
      11.65,
      90.6675,
      9.15,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "1.OG_Dn-En_3-4n_1",
    polygon: [
      -36.6747,
      87.7558,
      9.34,
      -36.6747,
      87.7558,
      11.41,
      -37.4247,
      87.7584,
      11.41,
      -37.4247,
      87.7584,
      11.41,
      -37.4247,
      87.7584,
      9.34,
      -36.6747,
      87.7558,
      9.34,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "2.OG_N-On_1_3",
    polygon: [
      12.0271,
      105.6152,
      12.6,
      12.0271,
      105.6152,
      14.86,
      12.0308,
      106.6751,
      14.86,
      12.0308,
      106.6751,
      14.86,
      12.0308,
      106.6751,
      12.6,
      12.0271,
      105.6152,
      12.6,
    ],
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_M-N_1_1",
    polygon: [
      7.5039,
      106.0137,
      3.89,
      7.5039,
      106.0137,
      7.135,
      11.9333,
      106.442,
      7.135,
      11.9333,
      106.442,
      7.135,
      11.9333,
      106.442,
      3.89,
      7.5039,
      106.0137,
      3.89,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2.OG_M-N_1_2",
    polygon: [
      9.352,
      104.8005,
      12.02,
      9.352,
      104.8005,
      14.84,
      11.3078,
      104.9897,
      14.84,
      11.3078,
      104.9897,
      14.84,
      11.3078,
      104.9897,
      12.02,
      9.352,
      104.8005,
      12.02,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_En-Fn_1-2n_1",
    polygon: [
      -35.5274,
      101.1712,
      16.05,
      -35.5274,
      101.1712,
      18.12,
      -33.5681,
      101.457,
      18.12,
      -33.5681,
      101.457,
      18.12,
      -33.5681,
      101.457,
      16.05,
      -35.5274,
      101.1712,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_Dn-En_1-2n_1",
    polygon: [
      -38.2894,
      100.7683,
      16.05,
      -38.2894,
      100.7683,
      18.12,
      -36.3301,
      101.0541,
      18.12,
      -36.3301,
      101.0541,
      18.12,
      -36.3301,
      101.0541,
      16.05,
      -38.2894,
      100.7683,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_An_4-5_1",
    polygon: [
      -56.2972,
      83.1079,
      16.05,
      -56.2972,
      83.1079,
      18.12,
      -56.151,
      85.0976,
      18.12,
      -56.151,
      85.0976,
      18.12,
      -56.151,
      85.0976,
      16.05,
      -56.2972,
      83.1079,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_Bn-Cn_15-16_1",
    polygon: [
      -43.2472,
      19.7833,
      9.15,
      -43.2472,
      19.7833,
      11.41,
      -43.2402,
      21.8033,
      11.41,
      -43.2402,
      21.8033,
      11.41,
      -43.2402,
      21.8033,
      9.15,
      -43.2472,
      19.7833,
      9.15,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "2.OG_Bn-Cn_7-8_1",
    polygon: [
      -43.0821,
      66.579,
      12.79,
      -43.0821,
      66.579,
      14.86,
      -43.0753,
      68.529,
      14.86,
      -43.0753,
      68.529,
      14.86,
      -43.0753,
      68.529,
      12.79,
      -43.0821,
      66.579,
      12.79,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "2.OG_Dn-En_12-13_1",
    polygon: [
      -40.6207,
      36.0192,
      12.6,
      -40.6207,
      36.0192,
      14.86,
      -40.6132,
      38.1692,
      14.86,
      -40.6132,
      38.1692,
      14.86,
      -40.6132,
      38.1692,
      12.6,
      -40.6207,
      36.0192,
      12.6,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_Dn-En_3-4n_1",
    polygon: [
      -36.6747,
      87.7558,
      16.24,
      -36.6747,
      87.7558,
      18.31,
      -37.4247,
      87.7584,
      18.31,
      -37.4247,
      87.7584,
      18.31,
      -37.4247,
      87.7584,
      16.24,
      -36.6747,
      87.7558,
      16.24,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "3.OG_An_2n-3_2",
    polygon: [
      -55.4683,
      94.0483,
      16.05,
      -55.4683,
      94.0483,
      18.12,
      -54.7185,
      96.0793,
      18.12,
      -54.7185,
      96.0793,
      18.12,
      -54.7185,
      96.0793,
      16.05,
      -55.4683,
      94.0483,
      16.05,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_Gn-H_1_1",
    polygon: [
      -27.2997,
      102.3714,
      9.16,
      -27.2997,
      102.3714,
      11.865,
      -22.8666,
      103.018,
      11.865,
      -22.8666,
      103.018,
      11.865,
      -22.8666,
      103.018,
      9.16,
      -27.2997,
      102.3714,
      9.16,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "1.OG_Bn-Cn_6-7_2",
    polygon: [
      -43.0629,
      72.073,
      9.34,
      -43.0629,
      72.073,
      11.555,
      -43.0561,
      74.023,
      11.555,
      -43.0561,
      74.023,
      11.555,
      -43.0561,
      74.023,
      9.34,
      -43.0629,
      72.073,
      9.34,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "3.OG_An_10-11_2",
    polygon: [
      -56.7636,
      49.8086,
      15.47,
      -56.7636,
      49.8086,
      18.29,
      -56.6899,
      51.8823,
      18.29,
      -56.6899,
      51.8823,
      18.29,
      -56.6899,
      51.8823,
      15.47,
      -56.7636,
      49.8086,
      15.47,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1.OG_N-On_2n-3_1",
    polygon: [
      14.4,
      90.6579,
      9.15,
      14.4,
      90.6579,
      11.555,
      12.45,
      90.6647,
      11.555,
      12.45,
      90.6647,
      11.555,
      12.45,
      90.6647,
      9.15,
      14.4,
      90.6579,
      9.15,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "EG_N-On_1_1",
    polygon: [
      12.9983,
      106.545,
      3.89,
      12.9983,
      106.545,
      7.135,
      17.4276,
      106.9734,
      7.135,
      17.4276,
      106.9734,
      7.135,
      17.4276,
      106.9734,
      3.89,
      12.9983,
      106.545,
      3.89,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "3.OG_Dn-En_13-14_1",
    polygon: [
      -40.6428,
      33.1093,
      15.502,
      -40.6428,
      33.1093,
      18.258,
      -42.7088,
      33.1165,
      18.258,
      -42.7088,
      33.1165,
      18.258,
      -42.7088,
      33.1165,
      15.502,
      -40.6428,
      33.1093,
      15.502,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "2.OG_Jn-Kn_4-5_1",
    polygon: [
      -5.678,
      79.7803,
      12.6,
      -5.678,
      79.7803,
      14.86,
      -5.6712,
      81.7453,
      14.86,
      -5.6712,
      81.7453,
      14.86,
      -5.6712,
      81.7453,
      12.6,
      -5.678,
      79.7803,
      12.6,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "3.OG_Gn-H_5-6_1",
    polygon: [
      -21.2974,
      77.099,
      16.05,
      -21.2974,
      77.099,
      18.31,
      -21.2906,
      79.049,
      18.31,
      -21.2906,
      79.049,
      18.31,
      -21.2906,
      79.049,
      16.05,
      -21.2974,
      77.099,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1.OG_Jn-Kn_3-4n_2",
    polygon: [
      -5.6449,
      89.2727,
      9.15,
      -5.6449,
      89.2727,
      11.555,
      -5.6424,
      89.9977,
      11.555,
      -5.6424,
      89.9977,
      11.555,
      -5.6424,
      89.9977,
      9.15,
      -5.6449,
      89.2727,
      9.15,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "2.OG_An_9-10_1",
    polygon: [
      -57.9736,
      55.3236,
      12.6,
      -57.9736,
      55.3236,
      14.86,
      -57.8967,
      57.4722,
      14.86,
      -57.8967,
      57.4722,
      14.86,
      -57.8967,
      57.4722,
      12.6,
      -57.9736,
      55.3236,
      12.6,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "3.OG_Dn-En_1-2n_2",
    polygon: [
      -41.0205,
      100.3699,
      16.05,
      -41.0205,
      100.3699,
      18.12,
      -39.0612,
      100.6557,
      18.12,
      -39.0612,
      100.6557,
      18.12,
      -39.0612,
      100.6557,
      16.05,
      -41.0205,
      100.3699,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_4-5_1",
    polygon: [
      -56.3251,
      82.7268,
      12.79,
      -56.3251,
      82.7268,
      14.86,
      -56.1816,
      84.6815,
      14.86,
      -56.1816,
      84.6815,
      14.86,
      -56.1816,
      84.6815,
      12.79,
      -56.3251,
      82.7268,
      12.79,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "3.OG_An_4-5_2",
    polygon: [
      -56.4989,
      80.3603,
      16.05,
      -56.4989,
      80.3603,
      18.12,
      -56.3528,
      82.35,
      18.12,
      -56.3528,
      82.35,
      18.12,
      -56.3528,
      82.35,
      16.05,
      -56.4989,
      80.3603,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_M-N_2n-3_1",
    polygon: [
      8.89,
      90.6771,
      16.05,
      8.89,
      90.6771,
      18.31,
      6.94,
      90.6839,
      18.31,
      6.94,
      90.6839,
      18.31,
      6.94,
      90.6839,
      16.05,
      8.89,
      90.6771,
      16.05,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "3.OG_En-Fn_3-4n_1",
    polygon: [
      -33.9996,
      86.5465,
      16.24,
      -33.9996,
      86.5465,
      18.31,
      -35.1996,
      86.5507,
      18.31,
      -35.1996,
      86.5507,
      18.31,
      -35.1996,
      86.5507,
      16.24,
      -33.9996,
      86.5465,
      16.24,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "1.OG_Fn-Gn_1_1",
    polygon: [
      -32.8114,
      101.5674,
      9.16,
      -32.8114,
      101.5674,
      11.865,
      -28.3783,
      102.214,
      11.865,
      -28.3783,
      102.214,
      11.865,
      -28.3783,
      102.214,
      9.16,
      -32.8114,
      101.5674,
      9.16,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "2.OG_Dn-En_11-12_1",
    polygon: [
      -40.6015,
      41.5242,
      12.6,
      -40.6015,
      41.5242,
      14.86,
      -40.594,
      43.6742,
      14.86,
      -40.594,
      43.6742,
      14.86,
      -40.594,
      43.6742,
      12.6,
      -40.6015,
      41.5242,
      12.6,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_Dn-En_12-13_2",
    polygon: [
      -40.6111,
      38.7542,
      16.05,
      -40.6111,
      38.7542,
      18.31,
      -40.6036,
      40.9042,
      18.31,
      -40.6036,
      40.9042,
      18.31,
      -40.6036,
      40.9042,
      16.05,
      -40.6111,
      38.7542,
      16.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "2.OG_An_13-14_3",
    polygon: [
      -58.7648,
      33.3134,
      12.6,
      -58.7648,
      33.3134,
      14.86,
      -58.6815,
      35.6572,
      14.86,
      -58.6815,
      35.6572,
      14.86,
      -58.6815,
      35.6572,
      12.6,
      -58.7648,
      33.3134,
      12.6,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "1.OG_P_1_1",
    polygon: [
      23.4633,
      107.557,
      9.15,
      23.4633,
      107.557,
      11.555,
      27.8926,
      107.9854,
      11.555,
      27.8926,
      107.9854,
      11.555,
      27.8926,
      107.9854,
      9.15,
      23.4633,
      107.557,
      9.15,
    ],
    "04-15 17:00": 1,
  },
  {
    ID: "2.OG_Dn-En_3-4n_1",
    polygon: [
      -36.6947,
      87.7559,
      12.79,
      -36.6947,
      87.7559,
      14.86,
      -37.4447,
      87.7585,
      14.86,
      -37.4447,
      87.7585,
      14.86,
      -37.4447,
      87.7585,
      12.79,
      -36.6947,
      87.7559,
      12.79,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "3.OG_An_9-10_2",
    polygon: [
      -58.0868,
      52.1606,
      16.05,
      -58.0868,
      52.1606,
      18.31,
      -57.9943,
      54.744,
      18.31,
      -57.9943,
      54.744,
      18.31,
      -57.9943,
      54.744,
      16.05,
      -58.0868,
      52.1606,
      16.05,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "1.OG_Jn-Kn_5-6_1",
    polygon: [
      -5.6924,
      75.6628,
      9.15,
      -5.6924,
      75.6628,
      11.555,
      -5.6856,
      77.6128,
      11.555,
      -5.6856,
      77.6128,
      11.555,
      -5.6856,
      77.6128,
      9.15,
      -5.6924,
      75.6628,
      9.15,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "EG_Fn-Gn_9-10_1",
    polygon: [
      -26.9252,
      53.3616,
      3.89,
      -26.9252,
      53.3616,
      7.135,
      -29.0751,
      53.3691,
      7.135,
      -29.0751,
      53.3691,
      7.135,
      -29.0751,
      53.3691,
      3.89,
      -26.9252,
      53.3616,
      3.89,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "3.OG_Cn-Dn_1-2n_1",
    polygon: [
      -43.7714,
      99.9686,
      16.05,
      -43.7714,
      99.9686,
      18.12,
      -41.8121,
      100.2544,
      18.12,
      -41.8121,
      100.2544,
      18.12,
      -41.8121,
      100.2544,
      16.05,
      -43.7714,
      99.9686,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_An_5-6_1",
    polygon: [
      -56.7021,
      77.5928,
      16.05,
      -56.7021,
      77.5928,
      18.12,
      -56.5559,
      79.5824,
      18.12,
      -56.5559,
      79.5824,
      18.12,
      -56.5559,
      79.5824,
      16.05,
      -56.7021,
      77.5928,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_Gn-H_4-5_1",
    polygon: [
      -21.2879,
      79.849,
      16.05,
      -21.2879,
      79.849,
      18.31,
      -21.2811,
      81.799,
      18.31,
      -21.2811,
      81.799,
      18.31,
      -21.2811,
      81.799,
      16.05,
      -21.2879,
      79.849,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "2.OG_An_4n-4_1",
    polygon: [
      -56.123,
      85.4794,
      12.79,
      -56.123,
      85.4794,
      14.86,
      -55.9794,
      87.4341,
      14.86,
      -55.9794,
      87.4341,
      14.86,
      -55.9794,
      87.4341,
      12.79,
      -56.123,
      85.4794,
      12.79,
    ],
    "04-15 13:45": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "2.OG_Gn-H_5-6_1",
    polygon: [
      -21.2974,
      77.099,
      12.6,
      -21.2974,
      77.099,
      14.86,
      -21.2906,
      79.049,
      14.86,
      -21.2906,
      79.049,
      14.86,
      -21.2906,
      79.049,
      12.6,
      -21.2974,
      77.099,
      12.6,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "3.OG_An_8-9_2",
    polygon: [
      -57.8752,
      58.0718,
      16.05,
      -57.8752,
      58.0718,
      18.31,
      -57.7983,
      60.2205,
      18.31,
      -57.7983,
      60.2205,
      18.31,
      -57.7983,
      60.2205,
      16.05,
      -57.8752,
      58.0718,
      16.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "3.OG_Bn-Cn_7-8_1",
    polygon: [
      -43.0821,
      66.5831,
      16.24,
      -43.0821,
      66.5831,
      18.5,
      -43.0753,
      68.5331,
      18.5,
      -43.0753,
      68.5331,
      18.5,
      -43.0753,
      68.5331,
      16.24,
      -43.0821,
      66.5831,
      16.24,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "3.OG_N-On_2n-3_1",
    polygon: [
      14.41,
      90.6579,
      16.05,
      14.41,
      90.6579,
      18.31,
      12.46,
      90.6647,
      18.31,
      12.46,
      90.6647,
      18.31,
      12.46,
      90.6647,
      16.05,
      14.41,
      90.6579,
      16.05,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "2.OG_Dn-En_12-13_2",
    polygon: [
      -40.6111,
      38.7542,
      12.6,
      -40.6111,
      38.7542,
      14.86,
      -40.6036,
      40.9042,
      14.86,
      -40.6036,
      40.9042,
      14.86,
      -40.6036,
      40.9042,
      12.6,
      -40.6111,
      38.7542,
      12.6,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_An_12-13_1",
    polygon: [
      -57.2492,
      36.1422,
      15.49,
      -57.2492,
      36.1422,
      18.29,
      -57.1755,
      38.2159,
      18.29,
      -57.1755,
      38.2159,
      18.29,
      -57.1755,
      38.2159,
      15.49,
      -57.2492,
      36.1422,
      15.49,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3.OG_M-N_2n-3_2",
    polygon: [
      11.64,
      90.6675,
      16.05,
      11.64,
      90.6675,
      18.31,
      9.69,
      90.6743,
      18.31,
      9.69,
      90.6743,
      18.31,
      9.69,
      90.6743,
      16.05,
      11.64,
      90.6675,
      16.05,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "1.OG_Dn-En_12-13_2",
    polygon: [
      -40.6111,
      38.7542,
      9.15,
      -40.6111,
      38.7542,
      11.41,
      -40.6036,
      40.9042,
      11.41,
      -40.6036,
      40.9042,
      11.41,
      -40.6036,
      40.9042,
      9.15,
      -40.6111,
      38.7542,
      9.15,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_Gn-H_3-4n_1",
    polygon: [
      -23.0197,
      86.5082,
      16.24,
      -23.0197,
      86.5082,
      18.31,
      -24.9697,
      86.515,
      18.31,
      -24.9697,
      86.515,
      18.31,
      -24.9697,
      86.515,
      16.24,
      -23.0197,
      86.5082,
      16.24,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "3.OG_An_9-10_3",
    polygon: [
      -57.0073,
      52.4014,
      16.05,
      -57.0073,
      52.4014,
      18.31,
      -58.1673,
      52.4055,
      18.31,
      -58.1673,
      52.4055,
      18.31,
      -58.1673,
      52.4055,
      16.05,
      -57.0073,
      52.4014,
      16.05,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "1.OG_O-P_1_1",
    polygon: [
      17.959,
      107.0247,
      9.15,
      17.959,
      107.0247,
      11.555,
      22.3883,
      107.4531,
      11.555,
      22.3883,
      107.4531,
      11.555,
      22.3883,
      107.4531,
      9.15,
      17.959,
      107.0247,
      9.15,
    ],
    "04-15 17:00": 1,
  },
  {
    ID: "1.OG_Bn-Cn_5-6_1",
    polygon: [
      -43.0533,
      74.833,
      9.34,
      -43.0533,
      74.833,
      11.555,
      -43.0465,
      76.783,
      11.555,
      -43.0465,
      76.783,
      11.555,
      -43.0465,
      76.783,
      9.34,
      -43.0533,
      74.833,
      9.34,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "2.OG_On-O_4-5_1",
    polygon: [
      16.0115,
      82.4739,
      12.6,
      16.0115,
      82.4739,
      14.86,
      16.0183,
      84.4239,
      14.86,
      16.0183,
      84.4239,
      14.86,
      16.0183,
      84.4239,
      12.6,
      16.0115,
      82.4739,
      12.6,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "3.OG_Dn-En_13-14_2",
    polygon: [
      -40.6308,
      33.1093,
      16.05,
      -40.6308,
      33.1093,
      18.31,
      -40.6227,
      35.4192,
      18.31,
      -40.6227,
      35.4192,
      18.31,
      -40.6227,
      35.4192,
      16.05,
      -40.6308,
      33.1093,
      16.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "3.OG_Bn-Cn_1-2n_1",
    polygon: [
      -46.5223,
      99.5674,
      16.05,
      -46.5223,
      99.5674,
      18.12,
      -44.563,
      99.8532,
      18.12,
      -44.563,
      99.8532,
      18.12,
      -44.563,
      99.8532,
      16.05,
      -46.5223,
      99.5674,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_An_5-6_2",
    polygon: [
      -56.9039,
      74.8452,
      16.05,
      -56.9039,
      74.8452,
      18.12,
      -56.7577,
      76.8348,
      18.12,
      -56.7577,
      76.8348,
      18.12,
      -56.7577,
      76.8348,
      16.05,
      -56.9039,
      74.8452,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "EG_Fn-Gn_9-10_2",
    polygon: [
      -29.6951,
      53.3713,
      3.89,
      -29.6951,
      53.3713,
      7.135,
      -31.8451,
      53.3788,
      7.135,
      -31.8451,
      53.3788,
      7.135,
      -31.8451,
      53.3788,
      3.89,
      -29.6951,
      53.3713,
      3.89,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "2.OG_An_3-4n_1",
    polygon: [
      -55.9208,
      88.232,
      12.79,
      -55.9208,
      88.232,
      14.86,
      -55.7773,
      90.1867,
      14.86,
      -55.7773,
      90.1867,
      14.86,
      -55.7773,
      90.1867,
      12.79,
      -55.9208,
      88.232,
      12.79,
    ],
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_Jn-Kn_4-5_2",
    polygon: [
      -5.6684,
      82.5303,
      12.6,
      -5.6684,
      82.5303,
      14.86,
      -5.6616,
      84.4953,
      14.86,
      -5.6616,
      84.4953,
      14.86,
      -5.6616,
      84.4953,
      12.6,
      -5.6684,
      82.5303,
      12.6,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4.OG_Fn-Gn_3-4n_1",
    polygon: [
      -25.7897,
      86.5179,
      19.5,
      -25.7897,
      86.5179,
      21.57,
      -27.7396,
      86.5247,
      21.57,
      -27.7396,
      86.5247,
      21.57,
      -27.7396,
      86.5247,
      19.5,
      -25.7897,
      86.5179,
      19.5,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "3.OG_Gn-H_4-5_2",
    polygon: [
      -21.2782,
      82.6171,
      16.05,
      -21.2782,
      82.6171,
      18.31,
      -21.2714,
      84.5671,
      18.31,
      -21.2714,
      84.5671,
      18.31,
      -21.2714,
      84.5671,
      16.05,
      -21.2782,
      82.6171,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "4.OG_Gn-H_1_1",
    polygon: [
      -24.9196,
      102.7186,
      19.5,
      -24.9196,
      102.7186,
      21.57,
      -22.9604,
      103.0044,
      21.57,
      -22.9604,
      103.0044,
      21.57,
      -22.9604,
      103.0044,
      19.5,
      -24.9196,
      102.7186,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2.OG_Dn-En_11-12_2",
    polygon: [
      -40.5919,
      44.2742,
      12.6,
      -40.5919,
      44.2742,
      14.86,
      -40.5844,
      46.4242,
      14.86,
      -40.5844,
      46.4242,
      14.86,
      -40.5844,
      46.4242,
      12.6,
      -40.5919,
      44.2742,
      12.6,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_L-M_2n-3_1",
    polygon: [
      6.1401,
      90.6867,
      16.05,
      6.1401,
      90.6867,
      18.31,
      4.1901,
      90.6935,
      18.31,
      4.1901,
      90.6935,
      18.31,
      4.1901,
      90.6935,
      16.05,
      6.1401,
      90.6867,
      16.05,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "3.OG_Dn-En_10-11_1",
    polygon: [
      -40.5823,
      47.0242,
      16.05,
      -40.5823,
      47.0242,
      18.31,
      -40.5748,
      49.1742,
      18.31,
      -40.5748,
      49.1742,
      18.31,
      -40.5748,
      49.1742,
      16.05,
      -40.5823,
      47.0242,
      16.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_Fn-Gn_3-4n_1",
    polygon: [
      -25.7597,
      86.5178,
      16.24,
      -25.7597,
      86.5178,
      18.31,
      -27.7096,
      86.5246,
      18.31,
      -27.7096,
      86.5246,
      18.31,
      -27.7096,
      86.5246,
      16.24,
      -25.7597,
      86.5178,
      16.24,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "2.OG_Cn-Dn_4n-4_1",
    polygon: [
      -43.0769,
      84.7858,
      12.02,
      -43.0769,
      84.7858,
      14.9,
      -41.267,
      86.5963,
      14.9,
      -41.267,
      86.5963,
      14.9,
      -41.267,
      86.5963,
      12.02,
      -43.0769,
      84.7858,
      12.02,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "4.OG_Bn-Cn_1-2n_2",
    polygon: [
      -46.9181,
      99.5096,
      19.5,
      -46.9181,
      99.5096,
      21.57,
      -44.9588,
      99.7954,
      21.57,
      -44.9588,
      99.7954,
      21.57,
      -44.9588,
      99.7954,
      19.5,
      -46.9181,
      99.5096,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_An-Bn_1-2n_1",
    polygon: [
      -49.288,
      99.1639,
      16.05,
      -49.288,
      99.1639,
      18.12,
      -47.3287,
      99.4497,
      18.12,
      -47.3287,
      99.4497,
      18.12,
      -47.3287,
      99.4497,
      16.05,
      -49.288,
      99.1639,
      16.05,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_An_13-14_1",
    polygon: [
      -58.8617,
      30.5852,
      12.6,
      -58.8617,
      30.5852,
      14.86,
      -58.7854,
      32.7338,
      14.86,
      -58.7854,
      32.7338,
      14.86,
      -58.7854,
      32.7338,
      12.6,
      -58.8617,
      30.5852,
      12.6,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2.OG_An_2n-3_1",
    polygon: [
      -55.7187,
      90.9842,
      12.79,
      -55.7187,
      90.9842,
      14.86,
      -55.5751,
      92.939,
      14.86,
      -55.5751,
      92.939,
      14.86,
      -55.5751,
      92.939,
      12.79,
      -55.7187,
      90.9842,
      12.79,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_An_6-7_1",
    polygon: [
      -57.1057,
      72.0976,
      16.05,
      -57.1057,
      72.0976,
      18.12,
      -56.9596,
      74.0872,
      18.12,
      -56.9596,
      74.0872,
      18.12,
      -56.9596,
      74.0872,
      16.05,
      -57.1057,
      72.0976,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "1.OG_Jn-Kn_5-6_2",
    polygon: [
      -5.6827,
      78.4328,
      9.15,
      -5.6827,
      78.4328,
      11.555,
      -5.6759,
      80.3828,
      11.555,
      -5.6759,
      80.3828,
      11.555,
      -5.6759,
      80.3828,
      9.15,
      -5.6827,
      78.4328,
      9.15,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "EG_En-Fn_9-10_1",
    polygon: [
      -32.4451,
      53.3809,
      3.89,
      -32.4451,
      53.3809,
      7.135,
      -34.5951,
      53.3884,
      7.135,
      -34.5951,
      53.3884,
      7.135,
      -34.5951,
      53.3884,
      3.89,
      -32.4451,
      53.3809,
      3.89,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "4.OG_Bn-Cn_5-6_2",
    polygon: [
      -43.0533,
      74.8289,
      19.5,
      -43.0533,
      74.8289,
      21.57,
      -43.0465,
      76.7789,
      21.57,
      -43.0465,
      76.7789,
      21.57,
      -43.0465,
      76.7789,
      19.5,
      -43.0533,
      74.8289,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "4.OG_Fn-Gn_3-4n_2",
    polygon: [
      -28.5196,
      86.5274,
      19.5,
      -28.5196,
      86.5274,
      21.57,
      -30.4696,
      86.5342,
      21.57,
      -30.4696,
      86.5342,
      21.57,
      -30.4696,
      86.5342,
      19.5,
      -28.5196,
      86.5274,
      19.5,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "4.OG_Fn-Gn_1_1",
    polygon: [
      -27.6507,
      102.3202,
      19.5,
      -27.6507,
      102.3202,
      21.57,
      -25.6915,
      102.606,
      21.57,
      -25.6915,
      102.606,
      21.57,
      -25.6915,
      102.606,
      19.5,
      -27.6507,
      102.3202,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_Bn-Cn_6-7_1",
    polygon: [
      -43.0725,
      69.3231,
      16.24,
      -43.0725,
      69.3231,
      18.5,
      -43.0657,
      71.273,
      18.5,
      -43.0657,
      71.273,
      18.5,
      -43.0657,
      71.273,
      16.24,
      -43.0725,
      69.3231,
      16.24,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "3.OG_An_13-14_2",
    polygon: [
      -57.6124,
      35.6534,
      16.05,
      -57.6124,
      35.6534,
      18.31,
      -58.8823,
      35.6579,
      18.31,
      -58.8823,
      35.6579,
      18.31,
      -58.8823,
      35.6579,
      16.05,
      -57.6124,
      35.6534,
      16.05,
    ],
    "04-15 16:45": 1,
  },
  {
    ID: "4.OG_An_6-7_1",
    polygon: [
      -57.1336,
      71.7186,
      19.5,
      -57.1336,
      71.7186,
      21.57,
      -56.9874,
      73.7082,
      21.57,
      -56.9874,
      73.7082,
      21.57,
      -56.9874,
      73.7082,
      19.5,
      -57.1336,
      71.7186,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2.OG_Dn-En_10-11_1",
    polygon: [
      -40.5823,
      47.0242,
      12.6,
      -40.5823,
      47.0242,
      14.86,
      -40.5748,
      49.1742,
      14.86,
      -40.5748,
      49.1742,
      14.86,
      -40.5748,
      49.1742,
      12.6,
      -40.5823,
      47.0242,
      12.6,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "1.OG_Dn-En_10-11_1",
    polygon: [
      -40.5823,
      47.0242,
      9.15,
      -40.5823,
      47.0242,
      11.41,
      -40.5748,
      49.1742,
      11.41,
      -40.5748,
      49.1742,
      11.41,
      -40.5748,
      49.1742,
      9.15,
      -40.5823,
      47.0242,
      9.15,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_An-Bn_1-2n_1",
    polygon: [
      -49.6887,
      99.1054,
      19.5,
      -49.6887,
      99.1054,
      21.57,
      -47.7295,
      99.3913,
      21.57,
      -47.7295,
      99.3913,
      21.57,
      -47.7295,
      99.3913,
      19.5,
      -49.6887,
      99.1054,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_An_2n-3_1",
    polygon: [
      -55.6921,
      91.3457,
      16.05,
      -55.6921,
      91.3457,
      18.12,
      -55.546,
      93.3354,
      18.12,
      -55.546,
      93.3354,
      18.12,
      -55.546,
      93.3354,
      16.05,
      -55.6921,
      91.3457,
      16.05,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "1.OG_Bn-Cn_5-6_2",
    polygon: [
      -43.0439,
      77.5439,
      9.34,
      -43.0439,
      77.5439,
      11.555,
      -43.0397,
      78.7439,
      11.555,
      -43.0397,
      78.7439,
      11.555,
      -43.0397,
      78.7439,
      9.34,
      -43.0439,
      77.5439,
      9.34,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "3.OG_Fn-Gn_3-4n_2",
    polygon: [
      -28.5196,
      86.5274,
      16.24,
      -28.5196,
      86.5274,
      18.31,
      -30.4696,
      86.5342,
      18.31,
      -30.4696,
      86.5342,
      18.31,
      -30.4696,
      86.5342,
      16.24,
      -28.5196,
      86.5274,
      16.24,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "3.OG_An_6-7_2",
    polygon: [
      -57.3075,
      69.35,
      16.05,
      -57.3075,
      69.35,
      18.12,
      -57.1614,
      71.3396,
      18.12,
      -57.1614,
      71.3396,
      18.12,
      -57.1614,
      71.3396,
      16.05,
      -57.3075,
      69.35,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_Jn-Kn_1_3",
    polygon: [
      -6.7484,
      104.6354,
      16.05,
      -6.7484,
      104.6354,
      18.31,
      -4.4939,
      104.8534,
      18.31,
      -4.4939,
      104.8534,
      18.31,
      -4.4939,
      104.8534,
      16.05,
      -6.7484,
      104.6354,
      16.05,
    ],
    "04-15 05:00": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_Dn-En_9-10_1",
    polygon: [
      -40.5632,
      52.5191,
      16.05,
      -40.5632,
      52.5191,
      18.31,
      -40.5551,
      54.8291,
      18.31,
      -40.5551,
      54.8291,
      18.31,
      -40.5551,
      54.8291,
      16.05,
      -40.5632,
      52.5191,
      16.05,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "3.OG_An_9-10_1",
    polygon: [
      -57.9736,
      55.3236,
      16.05,
      -57.9736,
      55.3236,
      18.31,
      -57.8967,
      57.4722,
      18.31,
      -57.8967,
      57.4722,
      18.31,
      -57.8967,
      57.4722,
      16.05,
      -57.9736,
      55.3236,
      16.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "EG_En-Fn_9-10_2",
    polygon: [
      -35.1951,
      53.3904,
      3.89,
      -35.1951,
      53.3904,
      7.135,
      -37.3451,
      53.3979,
      7.135,
      -37.3451,
      53.3979,
      7.135,
      -37.3451,
      53.3979,
      3.89,
      -35.1951,
      53.3904,
      3.89,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "3.OG_An_13-14_3",
    polygon: [
      -58.7648,
      33.3134,
      16.05,
      -58.7648,
      33.3134,
      18.31,
      -58.6815,
      35.6572,
      18.31,
      -58.6815,
      35.6572,
      18.31,
      -58.6815,
      35.6572,
      16.05,
      -58.7648,
      33.3134,
      16.05,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4.OG_Fn-Gn_1_2",
    polygon: [
      -30.4016,
      101.9189,
      19.5,
      -30.4016,
      101.9189,
      21.57,
      -28.4424,
      102.2047,
      21.57,
      -28.4424,
      102.2047,
      21.57,
      -28.4424,
      102.2047,
      19.5,
      -30.4016,
      101.9189,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2.OG_Jn-Kn_4n-4_1",
    polygon: [
      -5.6588,
      85.2953,
      12.6,
      -5.6588,
      85.2953,
      14.86,
      -5.652,
      87.2602,
      14.86,
      -5.652,
      87.2602,
      14.86,
      -5.652,
      87.2602,
      12.6,
      -5.6588,
      85.2953,
      12.6,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4.OG_En-Fn_3-4n_1",
    polygon: [
      -31.2596,
      86.5369,
      19.5,
      -31.2596,
      86.5369,
      21.57,
      -33.2096,
      86.5437,
      21.57,
      -33.2096,
      86.5437,
      21.57,
      -33.2096,
      86.5437,
      19.5,
      -31.2596,
      86.5369,
      19.5,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "4.OG_An_6-7_2",
    polygon: [
      -57.3369,
      68.9511,
      19.5,
      -57.3369,
      68.9511,
      21.57,
      -57.1907,
      70.9407,
      21.57,
      -57.1907,
      70.9407,
      21.57,
      -57.1907,
      70.9407,
      19.5,
      -57.3369,
      68.9511,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "1.OG_On-O_3-4n_1",
    polygon: [
      16.0259,
      86.5922,
      9.15,
      16.0259,
      86.5922,
      11.41,
      16.0327,
      88.5422,
      11.41,
      16.0327,
      88.5422,
      11.41,
      16.0327,
      88.5422,
      9.15,
      16.0259,
      86.5922,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "3.OG_Jn-Kn_5-6_1",
    polygon: [
      -5.6923,
      75.6678,
      16.05,
      -5.6923,
      75.6678,
      18.31,
      -5.6898,
      76.3928,
      18.31,
      -5.6898,
      76.3928,
      18.31,
      -5.6898,
      76.3928,
      16.05,
      -5.6923,
      75.6678,
      16.05,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_An_1-2n_1",
    polygon: [
      -54.5397,
      96.3643,
      19.5,
      -54.5397,
      96.3643,
      21.57,
      -53.038,
      97.9238,
      21.57,
      -53.038,
      97.9238,
      21.57,
      -53.038,
      97.9238,
      19.5,
      -54.5397,
      96.3643,
      19.5,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_An_3-4n_1",
    polygon: [
      -55.8923,
      88.6206,
      15.47,
      -55.8923,
      88.6206,
      17.73,
      -55.7495,
      90.5654,
      17.73,
      -55.7495,
      90.5654,
      17.73,
      -55.7495,
      90.5654,
      15.47,
      -55.8923,
      88.6206,
      15.47,
    ],
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4.OG_Dn-En_11-12_1",
    polygon: [
      -40.6015,
      41.5242,
      19.5,
      -40.6015,
      41.5242,
      21.76,
      -40.594,
      43.6742,
      21.76,
      -40.594,
      43.6742,
      21.76,
      -40.594,
      43.6742,
      19.5,
      -40.6015,
      41.5242,
      19.5,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_L-M_1_2",
    polygon: [
      5.2411,
      104.403,
      16.05,
      5.2411,
      104.403,
      18.31,
      7.197,
      104.5921,
      18.31,
      7.197,
      104.5921,
      18.31,
      7.197,
      104.5921,
      16.05,
      5.2411,
      104.403,
      16.05,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_Jn-Kn_3-4n_1",
    polygon: [
      -5.6492,
      88.0477,
      16.05,
      -5.6492,
      88.0477,
      18.31,
      -5.6424,
      90.0127,
      18.31,
      -5.6424,
      90.0127,
      18.31,
      -5.6424,
      90.0127,
      16.05,
      -5.6492,
      88.0477,
      16.05,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "3.OG_Dn-En_10-11_2",
    polygon: [
      -40.5727,
      49.7742,
      16.05,
      -40.5727,
      49.7742,
      18.31,
      -40.5653,
      51.9241,
      18.31,
      -40.5653,
      51.9241,
      18.31,
      -40.5653,
      51.9241,
      16.05,
      -40.5727,
      49.7742,
      16.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "1.OG_En-Fn_3-4n_1",
    polygon: [
      -33.9996,
      86.5465,
      9.34,
      -33.9996,
      86.5465,
      11.555,
      -35.1996,
      86.5507,
      11.555,
      -35.1996,
      86.5507,
      11.555,
      -35.1996,
      86.5507,
      9.34,
      -33.9996,
      86.5465,
      9.34,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "3.OG_An_7-8_1",
    polygon: [
      -57.5094,
      66.6024,
      16.05,
      -57.5094,
      66.6024,
      18.12,
      -57.3632,
      68.592,
      18.12,
      -57.3632,
      68.592,
      18.12,
      -57.3632,
      68.592,
      16.05,
      -57.5094,
      66.6024,
      16.05,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_En-Fn_3-4n_2",
    polygon: [
      -31.2721,
      86.537,
      16.24,
      -31.2721,
      86.537,
      18.31,
      -33.2221,
      86.5438,
      18.31,
      -33.2221,
      86.5438,
      18.31,
      -33.2221,
      86.5438,
      16.24,
      -31.2721,
      86.537,
      16.24,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "3.OG_N-On_1_1",
    polygon: [
      11.7928,
      106.4284,
      16.05,
      11.7928,
      106.4284,
      18.31,
      14.0721,
      106.6489,
      18.31,
      14.0721,
      106.6489,
      18.31,
      14.0721,
      106.6489,
      16.05,
      11.7928,
      106.4284,
      16.05,
    ],
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4.OG_En-Fn_1-2n_1",
    polygon: [
      -33.1723,
      101.5147,
      19.5,
      -33.1723,
      101.5147,
      21.57,
      -31.213,
      101.8005,
      21.57,
      -31.213,
      101.8005,
      21.57,
      -31.213,
      101.8005,
      19.5,
      -33.1723,
      101.5147,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_9-10_4",
    polygon: [
      -57.0073,
      52.4014,
      19.5,
      -57.0073,
      52.4014,
      21.76,
      -58.1673,
      52.4055,
      21.76,
      -58.1673,
      52.4055,
      21.76,
      -58.1673,
      52.4055,
      19.5,
      -57.0073,
      52.4014,
      19.5,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "4.OG_Bn-Cn_6-7_1",
    polygon: [
      -43.063,
      72.069,
      19.5,
      -43.063,
      72.069,
      21.57,
      -43.0562,
      74.0189,
      21.57,
      -43.0562,
      74.0189,
      21.57,
      -43.0562,
      74.0189,
      19.5,
      -43.063,
      72.069,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "4.OG_An_7-8_1",
    polygon: [
      -57.5387,
      66.2035,
      19.5,
      -57.5387,
      66.2035,
      21.57,
      -57.3925,
      68.1931,
      21.57,
      -57.3925,
      68.1931,
      21.57,
      -57.3925,
      68.1931,
      19.5,
      -57.5387,
      66.2035,
      19.5,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "1.OG_Jn-Kn_4-5_1",
    polygon: [
      -5.6732,
      81.1628,
      9.15,
      -5.6732,
      81.1628,
      11.555,
      -5.6664,
      83.1128,
      11.555,
      -5.6664,
      83.1128,
      11.555,
      -5.6664,
      83.1128,
      9.15,
      -5.6732,
      81.1628,
      9.15,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "EG_En-Fn_15-16_1",
    polygon: [
      -32.5546,
      23.3811,
      3.89,
      -32.5546,
      23.3811,
      7.135,
      -34.7046,
      23.3886,
      7.135,
      -34.7046,
      23.3886,
      7.135,
      -34.7046,
      23.3886,
      3.89,
      -32.5546,
      23.3811,
      3.89,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "3.OG_On-O_3-4n_1",
    polygon: [
      16.0259,
      86.6039,
      16.05,
      16.0259,
      86.6039,
      18.31,
      16.0327,
      88.5539,
      18.31,
      16.0327,
      88.5539,
      18.31,
      16.0327,
      88.5539,
      16.05,
      16.0259,
      86.6039,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "4.OG_An-Bn_1-2n_2",
    polygon: [
      -52.4604,
      98.2933,
      19.5,
      -52.4604,
      98.2933,
      21.57,
      -50.415,
      99.003,
      21.57,
      -50.415,
      99.003,
      21.57,
      -50.415,
      99.003,
      19.5,
      -52.4604,
      98.2933,
      19.5,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "2.OG_M-N_2n-3_1",
    polygon: [
      7.52,
      90.6819,
      12.6,
      7.52,
      90.6819,
      14.86,
      5.5701,
      90.6887,
      14.86,
      5.5701,
      90.6887,
      14.86,
      5.5701,
      90.6887,
      12.6,
      7.52,
      90.6819,
      12.6,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "3.OG_An_3-4n_2",
    polygon: [
      -56.095,
      85.8605,
      16.05,
      -56.095,
      85.8605,
      18.12,
      -55.9489,
      87.8502,
      18.12,
      -55.9489,
      87.8502,
      18.12,
      -55.9489,
      87.8502,
      16.05,
      -56.095,
      85.8605,
      16.05,
    ],
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_Bn-Cn_15-16_2",
    polygon: [
      -43.2382,
      22.3833,
      16.05,
      -43.2382,
      22.3833,
      18.31,
      -43.2311,
      24.4033,
      18.31,
      -43.2311,
      24.4033,
      18.31,
      -43.2311,
      24.4033,
      16.05,
      -43.2382,
      22.3833,
      16.05,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_En-Fn_3-4n_2",
    polygon: [
      -34.0516,
      86.5467,
      19.532,
      -34.0516,
      86.5467,
      21.538,
      -35.1876,
      86.5506,
      21.538,
      -35.1876,
      86.5506,
      21.538,
      -35.1876,
      86.5506,
      19.532,
      -34.0516,
      86.5467,
      19.532,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "2.OG_An_14-15_1",
    polygon: [
      -58.9594,
      27.8369,
      12.6,
      -58.9594,
      27.8369,
      14.86,
      -58.883,
      29.9855,
      14.86,
      -58.883,
      29.9855,
      14.86,
      -58.883,
      29.9855,
      12.6,
      -58.9594,
      27.8369,
      12.6,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "3.OG_Bn-Cn_6-7_2",
    polygon: [
      -43.0629,
      72.083,
      16.54,
      -43.0629,
      72.083,
      18.8,
      -43.0561,
      74.033,
      18.8,
      -43.0561,
      74.033,
      18.8,
      -43.0561,
      74.033,
      16.54,
      -43.0629,
      72.083,
      16.54,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "2.OG_Dn-En_10-11_2",
    polygon: [
      -40.5727,
      49.7742,
      12.6,
      -40.5727,
      49.7742,
      14.86,
      -40.5653,
      51.9241,
      14.86,
      -40.5653,
      51.9241,
      14.86,
      -40.5653,
      51.9241,
      12.6,
      -40.5727,
      49.7742,
      12.6,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "1.OG_On-O_4-5_1",
    polygon: [
      16.0163,
      83.8422,
      9.15,
      16.0163,
      83.8422,
      11.41,
      16.0231,
      85.7922,
      11.41,
      16.0231,
      85.7922,
      11.41,
      16.0231,
      85.7922,
      9.15,
      16.0163,
      83.8422,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "1.OG_Dn-En_10-11_2",
    polygon: [
      -40.5727,
      49.7742,
      9.15,
      -40.5727,
      49.7742,
      11.41,
      -40.5653,
      51.9241,
      11.41,
      -40.5653,
      51.9241,
      11.41,
      -40.5653,
      51.9241,
      9.15,
      -40.5727,
      49.7742,
      9.15,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_An_14-15_2",
    polygon: [
      -59.057,
      25.0886,
      19.5,
      -59.057,
      25.0886,
      21.76,
      -58.9807,
      27.2373,
      21.76,
      -58.9807,
      27.2373,
      21.76,
      -58.9807,
      27.2373,
      19.5,
      -59.057,
      25.0886,
      19.5,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "3.OG_M-N_1_1",
    polygon: [
      7.9933,
      104.6691,
      16.05,
      7.9933,
      104.6691,
      18.31,
      9.9492,
      104.8583,
      18.31,
      9.9492,
      104.8583,
      18.31,
      9.9492,
      104.8583,
      16.05,
      7.9933,
      104.6691,
      16.05,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_An_1-2n_1",
    polygon: [
      -54.3263,
      96.6645,
      16.05,
      -54.3263,
      96.6645,
      18.12,
      -52.733,
      98.1304,
      18.12,
      -52.733,
      98.1304,
      18.12,
      -52.733,
      98.1304,
      16.05,
      -54.3263,
      96.6645,
      16.05,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_Kn-L_2n-3_1",
    polygon: [
      -3.1518,
      91.6141,
      16.05,
      -3.1518,
      91.6141,
      18.31,
      -5.2868,
      91.6215,
      18.31,
      -5.2868,
      91.6215,
      18.31,
      -5.2868,
      91.6215,
      16.05,
      -3.1518,
      91.6141,
      16.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "3.OG_N-On_1_2",
    polygon: [
      12.0271,
      105.6152,
      16.05,
      12.0271,
      105.6152,
      18.31,
      12.0308,
      106.6751,
      18.31,
      12.0308,
      106.6751,
      18.31,
      12.0308,
      106.6751,
      16.05,
      12.0271,
      105.6152,
      16.05,
    ],
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4.OG_En-Fn_1-2n_2",
    polygon: [
      -35.9232,
      101.1135,
      19.5,
      -35.9232,
      101.1135,
      21.57,
      -33.9639,
      101.3993,
      21.57,
      -33.9639,
      101.3993,
      21.57,
      -33.9639,
      101.3993,
      19.5,
      -35.9232,
      101.1135,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_2n-3_2",
    polygon: [
      -55.5243,
      93.6577,
      19.5,
      -55.5243,
      93.6577,
      21.57,
      -54.9077,
      95.733,
      21.57,
      -54.9077,
      95.733,
      21.57,
      -54.9077,
      95.733,
      19.5,
      -55.5243,
      93.6577,
      19.5,
    ],
    "04-15 12:45": 1,
    "04-15 13:15": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Kn-L_1_2",
    polygon: [
      -1.2386,
      103.7763,
      19.5,
      -1.2386,
      103.7763,
      21.76,
      0.7172,
      103.9655,
      21.76,
      0.7172,
      103.9655,
      21.76,
      0.7172,
      103.9655,
      19.5,
      -1.2386,
      103.7763,
      19.5,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Jn-Kn_1_2",
    polygon: [
      -6.7484,
      104.6354,
      19.5,
      -6.7484,
      104.6354,
      21.76,
      -4.4939,
      104.8534,
      21.76,
      -4.4939,
      104.8534,
      21.76,
      -4.4939,
      104.8534,
      19.5,
      -6.7484,
      104.6354,
      19.5,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "2.OG_Dn-En_9-10_1",
    polygon: [
      -37.9801,
      53.4001,
      12.6,
      -37.9801,
      53.4001,
      14.86,
      -40.1301,
      53.4076,
      14.86,
      -40.1301,
      53.4076,
      14.86,
      -40.1301,
      53.4076,
      12.6,
      -37.9801,
      53.4001,
      12.6,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "4.OG_Dn-En_3-4n_1",
    polygon: [
      -36.6847,
      87.7558,
      19.5,
      -36.6847,
      87.7558,
      21.57,
      -37.4347,
      87.7585,
      21.57,
      -37.4347,
      87.7585,
      21.57,
      -37.4347,
      87.7585,
      19.5,
      -36.6847,
      87.7558,
      19.5,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "4.OG_L-M_2n-3_1",
    polygon: [
      4.7701,
      90.6915,
      19.5,
      4.7701,
      90.6915,
      21.76,
      2.8201,
      90.6983,
      21.76,
      2.8201,
      90.6983,
      21.76,
      2.8201,
      90.6983,
      19.5,
      4.7701,
      90.6915,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "3.OG_On-O_4-5_1",
    polygon: [
      16.0163,
      83.8539,
      16.05,
      16.0163,
      83.8539,
      18.31,
      16.0231,
      85.8039,
      18.31,
      16.0231,
      85.8039,
      18.31,
      16.0231,
      85.8039,
      16.05,
      16.0163,
      83.8539,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "3.OG_An-Bn_1-2n_2",
    polygon: [
      -52.1041,
      98.4788,
      16.05,
      -52.1041,
      98.4788,
      18.12,
      -50.0163,
      99.052,
      18.12,
      -50.0163,
      99.052,
      18.12,
      -50.0163,
      99.052,
      16.05,
      -52.1041,
      98.4788,
      16.05,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_Jn-Kn_5-6_2",
    polygon: [
      -5.6876,
      77.0303,
      16.05,
      -5.6876,
      77.0303,
      18.31,
      -5.6807,
      78.9953,
      18.31,
      -5.6807,
      78.9953,
      18.31,
      -5.6807,
      78.9953,
      16.05,
      -5.6876,
      77.0303,
      16.05,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "3.OG_Bn-Cn_15-16_1",
    polygon: [
      -43.2472,
      19.7833,
      16.05,
      -43.2472,
      19.7833,
      18.31,
      -43.2402,
      21.8033,
      18.31,
      -43.2402,
      21.8033,
      18.31,
      -43.2402,
      21.8033,
      16.05,
      -43.2472,
      19.7833,
      16.05,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "1.OG_Dn-En_9-10_1",
    polygon: [
      -37.9801,
      53.4001,
      9.15,
      -37.9801,
      53.4001,
      11.41,
      -40.1301,
      53.4076,
      11.41,
      -40.1301,
      53.4076,
      11.41,
      -40.1301,
      53.4076,
      9.15,
      -37.9801,
      53.4001,
      9.15,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "4.OG_Dn-En_11-12_2",
    polygon: [
      -40.5919,
      44.2742,
      19.5,
      -40.5919,
      44.2742,
      21.76,
      -40.5844,
      46.4242,
      21.76,
      -40.5844,
      46.4242,
      21.76,
      -40.5844,
      46.4242,
      19.5,
      -40.5919,
      44.2742,
      19.5,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_Dn-En_12-13_1",
    polygon: [
      -40.6207,
      36.0192,
      16.05,
      -40.6207,
      36.0192,
      18.31,
      -40.6132,
      38.1692,
      18.31,
      -40.6132,
      38.1692,
      18.31,
      -40.6132,
      38.1692,
      16.05,
      -40.6207,
      36.0192,
      16.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "1.OG_On-O_4-5_2",
    polygon: [
      16.0067,
      81.0923,
      9.15,
      16.0067,
      81.0923,
      11.41,
      16.0135,
      83.0423,
      11.41,
      16.0135,
      83.0423,
      11.41,
      16.0135,
      83.0423,
      9.15,
      16.0067,
      81.0923,
      9.15,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
  },
  {
    ID: "4.OG_Jn-Kn_5-6_1",
    polygon: [
      -5.6923,
      75.6678,
      19.5,
      -5.6923,
      75.6678,
      21.76,
      -5.6898,
      76.3928,
      21.76,
      -5.6898,
      76.3928,
      21.76,
      -5.6898,
      76.3928,
      19.5,
      -5.6923,
      75.6678,
      19.5,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_Dn-En_1-2n_1",
    polygon: [
      -38.6852,
      100.7106,
      19.5,
      -38.6852,
      100.7106,
      21.57,
      -36.7259,
      100.9964,
      21.57,
      -36.7259,
      100.9964,
      21.57,
      -36.7259,
      100.9964,
      19.5,
      -38.6852,
      100.7106,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_13-14_2",
    polygon: [
      -57.6124,
      35.6534,
      19.5,
      -57.6124,
      35.6534,
      21.76,
      -58.8823,
      35.6579,
      21.76,
      -58.8823,
      35.6579,
      21.76,
      -58.8823,
      35.6579,
      19.5,
      -57.6124,
      35.6534,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "3.OG_An_13-14_1",
    polygon: [
      -58.8617,
      30.5852,
      16.05,
      -58.8617,
      30.5852,
      18.31,
      -58.7854,
      32.7338,
      18.31,
      -58.7854,
      32.7338,
      18.31,
      -58.7854,
      32.7338,
      16.05,
      -58.8617,
      30.5852,
      16.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4.OG_An_4-5_2",
    polygon: [
      -56.5282,
      79.9614,
      19.5,
      -56.5282,
      79.9614,
      21.57,
      -56.3821,
      81.9511,
      21.57,
      -56.3821,
      81.9511,
      21.57,
      -56.3821,
      81.9511,
      19.5,
      -56.5282,
      79.9614,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Bn-Cn_6-7_2",
    polygon: [
      -43.0725,
      69.329,
      19.5,
      -43.0725,
      69.329,
      21.57,
      -43.0657,
      71.279,
      21.57,
      -43.0657,
      71.279,
      21.57,
      -43.0657,
      71.279,
      19.5,
      -43.0725,
      69.329,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "4.OG_L-M_1_1",
    polygon: [
      3.8675,
      104.2701,
      19.5,
      3.8675,
      104.2701,
      21.76,
      5.8234,
      104.4593,
      21.76,
      5.8234,
      104.4593,
      21.76,
      5.8234,
      104.4593,
      19.5,
      3.8675,
      104.2701,
      19.5,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Dn-En_12-13_1",
    polygon: [
      -40.6207,
      36.0192,
      19.5,
      -40.6207,
      36.0192,
      21.76,
      -40.6132,
      38.1692,
      21.76,
      -40.6132,
      38.1692,
      21.76,
      -40.6132,
      38.1692,
      19.5,
      -40.6207,
      36.0192,
      19.5,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_N-On_1_2",
    polygon: [
      11.7928,
      106.4284,
      19.5,
      11.7928,
      106.4284,
      21.76,
      14.0721,
      106.6489,
      21.76,
      14.0721,
      106.6489,
      21.76,
      14.0721,
      106.6489,
      19.5,
      11.7928,
      106.4284,
      19.5,
    ],
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_L-M_2n-3_2",
    polygon: [
      3.5501,
      90.6957,
      16.05,
      3.5501,
      90.6957,
      18.31,
      2.8251,
      90.6982,
      18.31,
      2.8251,
      90.6982,
      18.31,
      2.8251,
      90.6982,
      16.05,
      3.5501,
      90.6957,
      16.05,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "3.OG_Bn-Cn_14-15_1",
    polygon: [
      -43.229,
      25.0233,
      16.05,
      -43.229,
      25.0233,
      18.31,
      -43.2215,
      27.1732,
      18.31,
      -43.2215,
      27.1732,
      18.31,
      -43.2215,
      27.1732,
      16.05,
      -43.229,
      25.0233,
      16.05,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_An_12-13_1",
    polygon: [
      -57.1542,
      38.8155,
      19.5,
      -57.1542,
      38.8155,
      21.76,
      -57.0773,
      40.9642,
      21.76,
      -57.0773,
      40.9642,
      21.76,
      -57.0773,
      40.9642,
      19.5,
      -57.1542,
      38.8155,
      19.5,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "4.OG_Jn-Kn_3-4n_1",
    polygon: [
      -5.6492,
      88.0477,
      19.5,
      -5.6492,
      88.0477,
      21.76,
      -5.6424,
      90.0127,
      21.76,
      -5.6424,
      90.0127,
      21.76,
      -5.6424,
      90.0127,
      19.5,
      -5.6492,
      88.0477,
      19.5,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5.OG_Cn-Dn_1-2n_1",
    polygon: [
      -43.7725,
      99.9685,
      22.95,
      -43.7725,
      99.9685,
      25.02,
      -41.8132,
      100.2543,
      25.02,
      -41.8132,
      100.2543,
      25.02,
      -41.8132,
      100.2543,
      22.95,
      -43.7725,
      99.9685,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_Bn-Cn_5-6_1",
    polygon: [
      -43.0534,
      74.823,
      16.24,
      -43.0534,
      74.823,
      18.5,
      -43.0466,
      76.773,
      18.5,
      -43.0466,
      76.773,
      18.5,
      -43.0466,
      76.773,
      16.24,
      -43.0534,
      74.823,
      16.24,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4.OG_An_7-8_2",
    polygon: [
      -57.6785,
      63.5683,
      19.5,
      -57.6785,
      63.5683,
      21.76,
      -57.6433,
      64.5527,
      21.76,
      -57.6433,
      64.5527,
      21.76,
      -57.6433,
      64.5527,
      19.5,
      -57.6785,
      63.5683,
      19.5,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_On-O_4-5_2",
    polygon: [
      16.0067,
      81.0889,
      19.5,
      16.0067,
      81.0889,
      21.76,
      16.0092,
      81.8139,
      21.76,
      16.0092,
      81.8139,
      21.76,
      16.0092,
      81.8139,
      19.5,
      16.0067,
      81.0889,
      19.5,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "3.OG_On-O_4-5_2",
    polygon: [
      16.0067,
      81.0973,
      16.05,
      16.0067,
      81.0973,
      18.31,
      16.0135,
      83.0473,
      18.31,
      16.0135,
      83.0473,
      18.31,
      16.0135,
      83.0473,
      16.05,
      16.0067,
      81.0973,
      16.05,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "4.OG_Dn-En_1-2n_2",
    polygon: [
      -41.4361,
      100.3093,
      19.5,
      -41.4361,
      100.3093,
      21.57,
      -39.4768,
      100.5951,
      21.57,
      -39.4768,
      100.5951,
      21.57,
      -39.4768,
      100.5951,
      19.5,
      -41.4361,
      100.3093,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Fn-Gn_3-4n_1",
    polygon: [
      -25.7497,
      86.5177,
      22.95,
      -25.7497,
      86.5177,
      25.02,
      -27.6996,
      86.5245,
      25.02,
      -27.6996,
      86.5245,
      25.02,
      -27.6996,
      86.5245,
      22.95,
      -25.7497,
      86.5177,
      22.95,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "1.OG_On-O_3-4n_2",
    polygon: [
      16.0347,
      89.1172,
      9.15,
      16.0347,
      89.1172,
      11.555,
      16.0378,
      90.0022,
      11.555,
      16.0378,
      90.0022,
      11.555,
      16.0378,
      90.0022,
      9.15,
      16.0347,
      89.1172,
      9.15,
    ],
    "04-15 13:45": 1,
  },
  {
    ID: "4.OG_An_5-6_1",
    polygon: [
      -56.7299,
      77.2138,
      19.5,
      -56.7299,
      77.2138,
      21.57,
      -56.5838,
      79.2034,
      21.57,
      -56.5838,
      79.2034,
      21.57,
      -56.5838,
      79.2034,
      19.5,
      -56.7299,
      77.2138,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "2.OG_An_14-15_2",
    polygon: [
      -59.057,
      25.0886,
      12.6,
      -59.057,
      25.0886,
      14.86,
      -58.9807,
      27.2373,
      14.86,
      -58.9807,
      27.2373,
      14.86,
      -58.9807,
      27.2373,
      12.6,
      -59.057,
      25.0886,
      12.6,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "4.OG_M-N_1_1",
    polygon: [
      6.6197,
      104.5363,
      19.5,
      6.6197,
      104.5363,
      21.76,
      8.5756,
      104.7254,
      21.76,
      8.5756,
      104.7254,
      21.76,
      8.5756,
      104.7254,
      19.5,
      6.6197,
      104.5363,
      19.5,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_An_11-12_1",
    polygon: [
      -56.9587,
      44.3321,
      15.49,
      -56.9582,
      44.3321,
      18.29,
      -56.8818,
      46.4807,
      18.29,
      -56.8818,
      46.4807,
      18.29,
      -56.8818,
      46.4807,
      15.49,
      -56.9587,
      44.3321,
      15.49,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4.OG_Cn-Dn_4n-4_1",
    polygon: [
      -43.0665,
      84.8011,
      18.92,
      -43.0665,
      84.8011,
      21.8,
      -41.2517,
      86.6066,
      21.8,
      -41.2517,
      86.6066,
      21.8,
      -41.2517,
      86.6066,
      18.92,
      -43.0665,
      84.8011,
      18.92,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "4.OG_An_11-12_2",
    polygon: [
      -56.9587,
      44.3321,
      19.5,
      -56.9587,
      44.3321,
      21.76,
      -56.8818,
      46.4807,
      21.76,
      -56.8818,
      46.4807,
      21.76,
      -56.8818,
      46.4807,
      19.5,
      -56.9587,
      44.3321,
      19.5,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "4.OG_N-On_1_3",
    polygon: [
      12.0271,
      105.6152,
      19.5,
      12.0271,
      105.6152,
      21.76,
      12.0308,
      106.6751,
      21.76,
      12.0308,
      106.6751,
      21.76,
      12.0308,
      106.6751,
      19.5,
      12.0271,
      105.6152,
      19.5,
    ],
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_15-16_1",
    polygon: [
      -59.149,
      22.5003,
      19.5,
      -59.149,
      22.5003,
      21.76,
      -59.0783,
      24.489,
      21.76,
      -59.0783,
      24.489,
      21.76,
      -59.0783,
      24.489,
      19.5,
      -59.149,
      22.5003,
      19.5,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5.OG_Bn-Cn_1-2n_1",
    polygon: [
      -46.5234,
      99.5672,
      22.95,
      -46.5234,
      99.5672,
      25.02,
      -44.5641,
      99.853,
      25.02,
      -44.5641,
      99.853,
      25.02,
      -44.5641,
      99.853,
      22.95,
      -46.5234,
      99.5672,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Kn-L_2n-3_1",
    polygon: [
      -3.1518,
      91.6141,
      19.5,
      -3.1518,
      91.6141,
      21.76,
      -5.2868,
      91.6215,
      21.76,
      -5.2868,
      91.6215,
      21.76,
      -5.2868,
      91.6215,
      19.5,
      -3.1518,
      91.6141,
      19.5,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "5.OG_Gn-H_1_1",
    polygon: [
      -24.5238,
      102.7763,
      22.95,
      -24.5238,
      102.7763,
      25.02,
      -22.5646,
      103.0621,
      25.02,
      -22.5646,
      103.0621,
      25.02,
      -22.5646,
      103.0621,
      22.95,
      -24.5238,
      102.7763,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_5-6_1",
    polygon: [
      -56.7005,
      77.6127,
      22.95,
      -56.7005,
      77.6127,
      25.02,
      -56.5544,
      79.6024,
      25.02,
      -56.5544,
      79.6024,
      25.02,
      -56.5544,
      79.6024,
      22.95,
      -56.7005,
      77.6127,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "3.OG_An_11-12_2",
    polygon: [
      -57.0565,
      41.5638,
      15.49,
      -57.0565,
      41.5638,
      18.29,
      -56.9802,
      43.7124,
      18.29,
      -56.9802,
      43.7124,
      18.29,
      -56.9802,
      43.7124,
      15.49,
      -57.0565,
      41.5638,
      15.49,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4.OG_Bn-Cn_1-2n_1",
    polygon: [
      -44.1672,
      99.9109,
      19.5,
      -44.1672,
      99.9109,
      21.57,
      -42.2079,
      100.1967,
      21.57,
      -42.2079,
      100.1967,
      21.57,
      -42.2079,
      100.1967,
      19.5,
      -44.1672,
      99.9109,
      19.5,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_6-7_1",
    polygon: [
      -43.0629,
      72.089,
      22.95,
      -43.0629,
      72.089,
      25.02,
      -43.0561,
      74.0389,
      25.02,
      -43.0561,
      74.0389,
      25.02,
      -43.0561,
      74.0389,
      22.95,
      -43.0629,
      72.089,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "3.OG_Dn-En_11-12_1",
    polygon: [
      -40.6015,
      41.5242,
      16.05,
      -40.6015,
      41.5242,
      18.31,
      -40.594,
      43.6742,
      18.31,
      -40.594,
      43.6742,
      18.31,
      -40.594,
      43.6742,
      16.05,
      -40.6015,
      41.5242,
      16.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_Jn-Kn_4-5_1",
    polygon: [
      -5.678,
      79.7803,
      16.05,
      -5.678,
      79.7803,
      18.31,
      -5.6712,
      81.7453,
      18.31,
      -5.6712,
      81.7453,
      18.31,
      -5.6712,
      81.7453,
      16.05,
      -5.678,
      79.7803,
      16.05,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4.OG_An_5-6_2",
    polygon: [
      -56.9332,
      74.4463,
      19.5,
      -56.9332,
      74.4463,
      21.57,
      -56.787,
      76.4359,
      21.57,
      -56.787,
      76.4359,
      21.57,
      -56.787,
      76.4359,
      19.5,
      -56.9332,
      74.4463,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5.OG_Fn-Gn_3-4n_2",
    polygon: [
      -28.5246,
      86.5274,
      22.95,
      -28.5246,
      86.5274,
      25.02,
      -30.4746,
      86.5342,
      25.02,
      -30.4746,
      86.5342,
      25.02,
      -30.4746,
      86.5342,
      22.95,
      -28.5246,
      86.5274,
      22.95,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "4.OG_Bn-Cn_15-16_2",
    polygon: [
      -43.2382,
      22.3833,
      19.5,
      -43.2382,
      22.3833,
      21.76,
      -43.2311,
      24.4033,
      21.76,
      -43.2311,
      24.4033,
      21.76,
      -43.2311,
      24.4033,
      19.5,
      -43.2382,
      22.3833,
      19.5,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "2.OG_Gn-H_3-4n_1",
    polygon: [
      -23.0197,
      86.5082,
      12.79,
      -23.0197,
      86.5082,
      14.86,
      -24.9697,
      86.515,
      14.86,
      -24.9697,
      86.515,
      14.86,
      -24.9697,
      86.515,
      12.79,
      -23.0197,
      86.5082,
      12.79,
    ],
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "4.OG_Jn-Kn_5-6_2",
    polygon: [
      -5.6876,
      77.0303,
      19.5,
      -5.6876,
      77.0303,
      21.76,
      -5.6807,
      78.9953,
      21.76,
      -5.6807,
      78.9953,
      21.76,
      -5.6807,
      78.9953,
      19.5,
      -5.6876,
      77.0303,
      19.5,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4.OG_N-On_1_1",
    polygon: [
      14.8684,
      106.7259,
      19.5,
      14.8684,
      106.7259,
      21.76,
      16.8243,
      106.915,
      21.76,
      16.8243,
      106.915,
      21.76,
      16.8243,
      106.915,
      19.5,
      14.8684,
      106.7259,
      19.5,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_10-11_1",
    polygon: [
      -56.8605,
      47.0803,
      22.95,
      -56.8605,
      47.0803,
      25.21,
      -56.7842,
      49.229,
      25.21,
      -56.7842,
      49.229,
      25.21,
      -56.7842,
      49.229,
      22.95,
      -56.8605,
      47.0803,
      22.95,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "4.OG_Bn-Cn_7-8_1",
    polygon: [
      -43.082,
      66.589,
      19.5,
      -43.082,
      66.589,
      21.57,
      -43.0752,
      68.539,
      21.57,
      -43.0752,
      68.539,
      21.57,
      -43.0752,
      68.539,
      19.5,
      -43.082,
      66.589,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5.OG_An-Bn_1-2n_1",
    polygon: [
      -49.2743,
      99.1659,
      22.95,
      -49.2743,
      99.1659,
      25.02,
      -47.315,
      99.4517,
      25.02,
      -47.315,
      99.4517,
      25.02,
      -47.315,
      99.4517,
      22.95,
      -49.2743,
      99.1659,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_12-13_2",
    polygon: [
      -57.2497,
      36.1422,
      19.5,
      -57.2497,
      36.1422,
      21.76,
      -57.1755,
      38.2159,
      21.76,
      -57.1755,
      38.2159,
      21.76,
      -57.1755,
      38.2159,
      19.5,
      -57.2497,
      36.1422,
      19.5,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "4.OG_Dn-En_12-13_2",
    polygon: [
      -40.6111,
      38.7542,
      19.5,
      -40.6111,
      38.7542,
      21.76,
      -40.6036,
      40.9042,
      21.76,
      -40.6036,
      40.9042,
      21.76,
      -40.6036,
      40.9042,
      19.5,
      -40.6111,
      38.7542,
      19.5,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_H-In_1_1",
    polygon: [
      -20.4893,
      103.3065,
      19.5,
      -20.4893,
      103.3065,
      21.76,
      -18.5334,
      103.4957,
      21.76,
      -18.5334,
      103.4957,
      21.76,
      -18.5334,
      103.4957,
      19.5,
      -20.4893,
      103.3065,
      19.5,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Fn-Gn_1_1",
    polygon: [
      -27.2747,
      102.375,
      22.95,
      -27.2747,
      102.375,
      25.02,
      -25.3155,
      102.6608,
      25.02,
      -25.3155,
      102.6608,
      25.02,
      -25.3155,
      102.6608,
      22.95,
      -27.2747,
      102.375,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_5-6_2",
    polygon: [
      -56.9037,
      74.8452,
      22.95,
      -56.9037,
      74.8452,
      25.02,
      -56.7576,
      76.8348,
      25.02,
      -56.7576,
      76.8348,
      25.02,
      -56.7576,
      76.8348,
      22.95,
      -56.9037,
      74.8452,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "3.OG_Bn-Cn_14-15_2",
    polygon: [
      -43.2194,
      27.7732,
      16.05,
      -43.2194,
      27.7732,
      18.31,
      -43.2119,
      29.9232,
      18.31,
      -43.2119,
      29.9232,
      18.31,
      -43.2119,
      29.9232,
      16.05,
      -43.2194,
      27.7732,
      16.05,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_Bn-Cn_5-6_2",
    polygon: [
      -43.0437,
      77.5839,
      16.24,
      -43.0437,
      77.5839,
      18.5,
      -43.0396,
      78.7839,
      18.5,
      -43.0396,
      78.7839,
      18.5,
      -43.0396,
      78.7839,
      16.24,
      -43.0437,
      77.5839,
      16.24,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "3.OG_An_12-13_2",
    polygon: [
      -57.1542,
      38.8155,
      15.49,
      -57.1542,
      38.8155,
      18.29,
      -57.0778,
      40.9642,
      18.29,
      -57.0778,
      40.9642,
      18.29,
      -57.0778,
      40.9642,
      15.49,
      -57.1542,
      38.8155,
      15.49,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "3.OG_An_14-15_1",
    polygon: [
      -58.9594,
      27.8369,
      16.05,
      -58.9594,
      27.8369,
      18.31,
      -58.883,
      29.9855,
      18.31,
      -58.883,
      29.9855,
      18.31,
      -58.883,
      29.9855,
      16.05,
      -58.9594,
      27.8369,
      16.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5.OG_Gn-H_4-5_1",
    polygon: [
      -21.2831,
      81.229,
      22.95,
      -21.2831,
      81.229,
      25.21,
      -21.2763,
      83.179,
      25.21,
      -21.2763,
      83.179,
      25.21,
      -21.2763,
      83.179,
      22.95,
      -21.2831,
      81.229,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "5.OG_En-Fn_3-4n_1",
    polygon: [
      -31.2696,
      86.537,
      22.95,
      -31.2696,
      86.537,
      25.02,
      -33.2196,
      86.5438,
      25.02,
      -33.2196,
      86.5438,
      25.02,
      -33.2196,
      86.5438,
      22.95,
      -31.2696,
      86.537,
      22.95,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5.OG_On-O_4-5_1",
    polygon: [
      16.0067,
      81.0973,
      22.95,
      16.0067,
      81.0973,
      25.21,
      16.0135,
      83.0473,
      25.21,
      16.0135,
      83.0473,
      25.21,
      16.0135,
      83.0473,
      22.95,
      16.0067,
      81.0973,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5.OG_O-P_1_1",
    polygon: [
      21.7463,
      107.391,
      22.95,
      21.7463,
      107.391,
      25.21,
      23.7022,
      107.5801,
      25.21,
      23.7022,
      107.5801,
      25.21,
      23.7022,
      107.5801,
      22.95,
      21.7463,
      107.391,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_2n-3_1",
    polygon: [
      -55.7214,
      90.9468,
      19.5,
      -55.7214,
      90.9468,
      21.57,
      -55.5753,
      92.9365,
      21.57,
      -55.5753,
      92.9365,
      21.57,
      -55.5753,
      92.9365,
      19.5,
      -55.7214,
      90.9468,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_Bn-Cn_8-9_1",
    polygon: [
      -43.1118,
      58.0381,
      16.05,
      -43.1118,
      58.0381,
      18.31,
      -43.1043,
      60.188,
      18.31,
      -43.1043,
      60.188,
      18.31,
      -43.1043,
      60.188,
      16.05,
      -43.1118,
      58.0381,
      16.05,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "5.OG_An_10-11_2",
    polygon: [
      -56.7636,
      49.8086,
      22.95,
      -56.7636,
      49.8086,
      25.21,
      -56.6899,
      51.8823,
      25.21,
      -56.6899,
      51.8823,
      25.21,
      -56.6899,
      51.8823,
      22.95,
      -56.7636,
      49.8086,
      22.95,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4.OG_P_1_1",
    polygon: [
      25.8771,
      107.7905,
      19.5,
      25.8771,
      107.7905,
      21.76,
      27.8329,
      107.9796,
      21.76,
      27.8329,
      107.9796,
      21.76,
      27.8329,
      107.9796,
      19.5,
      25.8771,
      107.7905,
      19.5,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_2n-3_1",
    polygon: [
      -55.6921,
      91.3457,
      22.95,
      -55.6921,
      91.3457,
      25.02,
      -55.546,
      93.3354,
      25.02,
      -55.546,
      93.3354,
      25.02,
      -55.546,
      93.3354,
      22.95,
      -55.6921,
      91.3457,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Fn-Gn_1_2",
    polygon: [
      -30.0256,
      101.9738,
      22.95,
      -30.0256,
      101.9738,
      25.02,
      -28.0663,
      102.2596,
      25.02,
      -28.0663,
      102.2596,
      25.02,
      -28.0663,
      102.2596,
      22.95,
      -30.0256,
      101.9738,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Bn-Cn_4-5_1",
    polygon: [
      -44.2366,
      80.265,
      19.5,
      -44.2366,
      80.265,
      21.57,
      -44.2339,
      81.015,
      21.57,
      -44.2339,
      81.015,
      21.57,
      -44.2339,
      81.015,
      19.5,
      -44.2366,
      80.265,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "5.OG_An_6-7_1",
    polygon: [
      -57.1055,
      72.0976,
      22.95,
      -57.1055,
      72.0976,
      25.02,
      -56.9594,
      74.0872,
      25.02,
      -56.9594,
      74.0872,
      25.02,
      -56.9594,
      74.0872,
      22.95,
      -57.1055,
      72.0976,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4.OG_Jn-Kn_1_3",
    polygon: [
      -4.7535,
      104.0327,
      19.5,
      -4.7535,
      104.0327,
      21.76,
      -4.7498,
      105.1077,
      21.76,
      -4.7498,
      105.1077,
      21.76,
      -4.7498,
      105.1077,
      19.5,
      -4.7535,
      104.0327,
      19.5,
    ],
    "04-15 04:45": 1,
  },
  {
    ID: "4.OG_Bn-Cn_8-9_1",
    polygon: [
      -43.1118,
      58.0381,
      19.5,
      -43.1118,
      58.0381,
      21.76,
      -43.1043,
      60.188,
      21.76,
      -43.1043,
      60.188,
      21.76,
      -43.1043,
      60.188,
      19.5,
      -43.1118,
      58.0381,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_An_8-9_1",
    polygon: [
      -57.7769,
      60.8201,
      19.5,
      -57.7769,
      60.8201,
      21.76,
      -57.7,
      62.9687,
      21.76,
      -57.7,
      62.9687,
      21.76,
      -57.7,
      62.9687,
      19.5,
      -57.7769,
      60.8201,
      19.5,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5.OG_Bn-Cn_13-14_1",
    polygon: [
      -43.2098,
      30.5382,
      22.95,
      -43.2098,
      30.5382,
      25.21,
      -43.2023,
      32.6882,
      25.21,
      -43.2023,
      32.6882,
      25.21,
      -43.2023,
      32.6882,
      22.95,
      -43.2098,
      30.5382,
      22.95,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_Gn-H_3-4n_1",
    polygon: [
      -23.0097,
      86.5082,
      19.5,
      -23.0097,
      86.5082,
      21.57,
      -24.9597,
      86.515,
      21.57,
      -24.9597,
      86.515,
      21.57,
      -24.9597,
      86.515,
      19.5,
      -23.0097,
      86.5082,
      19.5,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5.OG_Bn-Cn_6-7_2",
    polygon: [
      -43.0725,
      69.329,
      22.95,
      -43.0725,
      69.329,
      25.02,
      -43.0657,
      71.279,
      25.02,
      -43.0657,
      71.279,
      25.02,
      -43.0657,
      71.279,
      22.95,
      -43.0725,
      69.329,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "3.OG_Dn-En_11-12_2",
    polygon: [
      -40.5919,
      44.2742,
      16.05,
      -40.5919,
      44.2742,
      18.31,
      -40.5844,
      46.4242,
      18.31,
      -40.5844,
      46.4242,
      18.31,
      -40.5844,
      46.4242,
      16.05,
      -40.5919,
      44.2742,
      16.05,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "2.OG_An_15-16_1",
    polygon: [
      -59.149,
      22.5003,
      12.6,
      -59.149,
      22.5003,
      14.86,
      -59.0783,
      24.489,
      14.86,
      -59.0783,
      24.489,
      14.86,
      -59.0783,
      24.489,
      12.6,
      -59.149,
      22.5003,
      12.6,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "5.OG_Gn-H_4-5_2",
    polygon: [
      -21.274,
      83.819,
      22.95,
      -21.274,
      83.819,
      25.21,
      -21.2715,
      84.544,
      25.21,
      -21.2715,
      84.544,
      25.21,
      -21.2715,
      84.544,
      22.95,
      -21.274,
      83.819,
      22.95,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "5.OG_Kn-L_1_1",
    polygon: [
      -2.5973,
      103.6449,
      22.95,
      -2.5973,
      103.6449,
      25.21,
      -0.6414,
      103.8341,
      25.21,
      -0.6414,
      103.8341,
      25.21,
      -0.6414,
      103.8341,
      22.95,
      -2.5973,
      103.6449,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_8-9_2",
    polygon: [
      -57.8752,
      58.0718,
      22.95,
      -57.8752,
      58.0718,
      25.21,
      -57.7983,
      60.2205,
      25.21,
      -57.7983,
      60.2205,
      25.21,
      -57.7983,
      60.2205,
      22.95,
      -57.8752,
      58.0718,
      22.95,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "4.OG_Bn-Cn_14-15_1",
    polygon: [
      -43.229,
      25.0233,
      19.5,
      -43.229,
      25.0233,
      21.76,
      -43.2215,
      27.1732,
      21.76,
      -43.2215,
      27.1732,
      21.76,
      -43.2215,
      27.1732,
      19.5,
      -43.229,
      25.0233,
      19.5,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_An_3-4n_1",
    polygon: [
      -55.9221,
      88.2142,
      19.5,
      -55.9221,
      88.2142,
      21.57,
      -55.776,
      90.2038,
      21.57,
      -55.776,
      90.2038,
      21.57,
      -55.776,
      90.2038,
      19.5,
      -55.9221,
      88.2142,
      19.5,
    ],
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_Jn-Kn_4-5_2",
    polygon: [
      -5.6684,
      82.5303,
      16.05,
      -5.6684,
      82.5303,
      18.31,
      -5.6616,
      84.4953,
      18.31,
      -5.6616,
      84.4953,
      18.31,
      -5.6616,
      84.4953,
      16.05,
      -5.6684,
      82.5303,
      16.05,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5.OG_En-Fn_3-4n_2",
    polygon: [
      -34.0196,
      86.5466,
      22.95,
      -34.0196,
      86.5466,
      25.02,
      -35.2196,
      86.5507,
      25.02,
      -35.2196,
      86.5507,
      25.02,
      -35.2196,
      86.5507,
      22.95,
      -34.0196,
      86.5466,
      22.95,
    ],
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "5.OG_O-P_1_2",
    polygon: [
      18.9942,
      107.1248,
      22.95,
      18.9942,
      107.1248,
      25.21,
      20.95,
      107.314,
      25.21,
      20.95,
      107.314,
      25.21,
      20.95,
      107.314,
      22.95,
      18.9942,
      107.1248,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_L-M_2n-3_1",
    polygon: [
      6.1401,
      90.6867,
      22.95,
      6.1401,
      90.6867,
      25.21,
      4.1901,
      90.6935,
      25.21,
      4.1901,
      90.6935,
      25.21,
      4.1901,
      90.6935,
      22.95,
      6.1401,
      90.6867,
      22.95,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "5.OG_An_9-10_2",
    polygon: [
      -58.0868,
      52.1606,
      22.95,
      -58.0868,
      52.1606,
      25.21,
      -57.9943,
      54.744,
      25.21,
      -57.9943,
      54.744,
      25.21,
      -57.9943,
      54.744,
      22.95,
      -58.0868,
      52.1606,
      22.95,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "5.OG_An_3-4n_1",
    polygon: [
      -55.8943,
      88.5932,
      22.95,
      -55.8943,
      88.5932,
      25.02,
      -55.7482,
      90.5828,
      25.02,
      -55.7482,
      90.5828,
      25.02,
      -55.7482,
      90.5828,
      22.95,
      -55.8943,
      88.5932,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_P_1_2",
    polygon: [
      23.1448,
      107.5262,
      19.5,
      23.1448,
      107.5262,
      21.76,
      25.1007,
      107.7154,
      21.76,
      25.1007,
      107.7154,
      21.76,
      25.1007,
      107.7154,
      19.5,
      23.1448,
      107.5262,
      19.5,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_En-Fn_1_1",
    polygon: [
      -32.7765,
      101.5725,
      22.95,
      -32.7765,
      101.5725,
      25.02,
      -30.8172,
      101.8583,
      25.02,
      -30.8172,
      101.8583,
      25.02,
      -30.8172,
      101.8583,
      22.95,
      -32.7765,
      101.5725,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_15-16_2",
    polygon: [
      -59.241,
      19.9119,
      19.5,
      -59.241,
      19.9119,
      21.76,
      -59.1703,
      21.9006,
      21.76,
      -59.1703,
      21.9006,
      21.76,
      -59.1703,
      21.9006,
      19.5,
      -59.241,
      19.9119,
      19.5,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5.OG_An_6-7_2",
    polygon: [
      -57.3072,
      69.35,
      22.95,
      -57.3072,
      69.35,
      25.02,
      -57.1611,
      71.3396,
      25.02,
      -57.1611,
      71.3396,
      25.02,
      -57.1611,
      71.3396,
      22.95,
      -57.3072,
      69.35,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Jn-Kn_4-5_1",
    polygon: [
      -5.678,
      79.7803,
      19.5,
      -5.678,
      79.7803,
      21.76,
      -5.6712,
      81.7453,
      21.76,
      -5.6712,
      81.7453,
      21.76,
      -5.6712,
      81.7453,
      19.5,
      -5.678,
      79.7803,
      19.5,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "3.OG_Bn-Cn_13-14_1",
    polygon: [
      -43.2098,
      30.5382,
      16.05,
      -43.2098,
      30.5382,
      18.31,
      -43.2023,
      32.6882,
      18.31,
      -43.2023,
      32.6882,
      18.31,
      -43.2023,
      32.6882,
      16.05,
      -43.2098,
      30.5382,
      16.05,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_Gn-H_5-6_2",
    polygon: [
      -21.3022,
      75.734,
      19.5,
      -21.3022,
      75.734,
      21.76,
      -21.2997,
      76.459,
      21.76,
      -21.2997,
      76.459,
      21.76,
      -21.2997,
      76.459,
      19.5,
      -21.3022,
      75.734,
      19.5,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "4.OG_Dn-En_10-11_1",
    polygon: [
      -40.5823,
      47.0242,
      19.5,
      -40.5823,
      47.0242,
      21.76,
      -40.5748,
      49.1742,
      21.76,
      -40.5748,
      49.1742,
      21.76,
      -40.5748,
      49.1742,
      19.5,
      -40.5823,
      47.0242,
      19.5,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_An_7-8_2",
    polygon: [
      -57.6785,
      63.5683,
      16.05,
      -57.6785,
      63.5683,
      18.31,
      -57.6433,
      64.5527,
      18.31,
      -57.6433,
      64.5527,
      18.31,
      -57.6433,
      64.5527,
      16.05,
      -57.6785,
      63.5683,
      16.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_4n-4_1",
    polygon: [
      -56.1243,
      85.4616,
      19.5,
      -56.1243,
      85.4616,
      21.57,
      -55.9782,
      87.4512,
      21.57,
      -55.9782,
      87.4512,
      21.57,
      -55.9782,
      87.4512,
      19.5,
      -56.1243,
      85.4616,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_L-M_1_1",
    polygon: [
      0.1549,
      103.9111,
      22.95,
      0.1549,
      103.9111,
      25.21,
      2.1107,
      104.1002,
      25.21,
      2.1107,
      104.1002,
      25.21,
      2.1107,
      104.1002,
      22.95,
      0.1549,
      103.9111,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_1-2n_1",
    polygon: [
      -54.293,
      96.7081,
      22.95,
      -54.293,
      96.7081,
      25.02,
      -52.6866,
      98.1595,
      25.02,
      -52.6866,
      98.1595,
      25.02,
      -52.6866,
      98.1595,
      22.95,
      -54.293,
      96.7081,
      22.95,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_3-4n_2",
    polygon: [
      -56.0965,
      85.8406,
      22.95,
      -56.0965,
      85.8406,
      25.02,
      -55.9503,
      87.8302,
      25.02,
      -55.9503,
      87.8302,
      25.02,
      -55.9503,
      87.8302,
      22.95,
      -56.0965,
      85.8406,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_9-10_3",
    polygon: [
      -57.0073,
      52.4014,
      22.95,
      -57.0073,
      52.4014,
      25.21,
      -58.1673,
      52.4055,
      25.21,
      -58.1673,
      52.4055,
      25.21,
      -58.1673,
      52.4055,
      22.95,
      -57.0073,
      52.4014,
      22.95,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "5.OG_An_7-8_1",
    polygon: [
      -57.5105,
      66.5824,
      22.95,
      -57.5105,
      66.5824,
      25.02,
      -57.3644,
      68.5721,
      25.02,
      -57.3644,
      68.5721,
      25.02,
      -57.3644,
      68.5721,
      22.95,
      -57.5105,
      66.5824,
      22.95,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Jn-Kn_1_3",
    polygon: [
      -6.7484,
      104.6354,
      22.95,
      -6.7484,
      104.6354,
      25.21,
      -4.4939,
      104.8534,
      25.21,
      -4.4939,
      104.8534,
      25.21,
      -4.4939,
      104.8534,
      22.95,
      -6.7484,
      104.6354,
      22.95,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_O-P_1_1",
    polygon: [
      20.3727,
      107.2582,
      19.5,
      20.3727,
      107.2582,
      21.76,
      22.3286,
      107.4473,
      21.76,
      22.3286,
      107.4473,
      21.76,
      22.3286,
      107.4473,
      19.5,
      20.3727,
      107.2582,
      19.5,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_En-Fn_1-2n_1",
    polygon: [
      -35.5274,
      101.1712,
      22.95,
      -35.5274,
      101.1712,
      25.02,
      -33.5681,
      101.457,
      25.02,
      -33.5681,
      101.457,
      25.02,
      -33.5681,
      101.457,
      22.95,
      -35.5274,
      101.1712,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_13-14_3",
    polygon: [
      -58.7648,
      33.3134,
      19.5,
      -58.7648,
      33.3134,
      21.76,
      -58.6815,
      35.6572,
      21.76,
      -58.6815,
      35.6572,
      21.76,
      -58.6815,
      35.6572,
      19.5,
      -58.7648,
      33.3134,
      19.5,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "4.OG_An_11-12_1",
    polygon: [
      -57.0565,
      41.5638,
      19.5,
      -57.0565,
      41.5638,
      21.76,
      -56.9802,
      43.7124,
      21.76,
      -56.9802,
      43.7124,
      21.76,
      -56.9802,
      43.7124,
      19.5,
      -57.0565,
      41.5638,
      19.5,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "3.OG_Bn-Cn_8-9_2",
    polygon: [
      -43.1023,
      60.768,
      16.05,
      -43.1023,
      60.768,
      18.31,
      -43.0948,
      62.918,
      18.31,
      -43.0948,
      62.918,
      18.31,
      -43.0948,
      62.918,
      16.05,
      -43.1023,
      60.768,
      16.05,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "4.OG_Bn-Cn_5-6_1",
    polygon: [
      -43.0437,
      77.5839,
      19.5,
      -43.0437,
      77.5839,
      21.57,
      -43.0396,
      78.7839,
      21.57,
      -43.0396,
      78.7839,
      21.57,
      -43.0396,
      78.7839,
      19.5,
      -43.0437,
      77.5839,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5.OG_On-O_3-4n_2",
    polygon: [
      16.0349,
      89.1972,
      22.95,
      16.0349,
      89.1972,
      25.21,
      16.0375,
      89.9222,
      25.21,
      16.0375,
      89.9222,
      25.21,
      16.0375,
      89.9222,
      22.95,
      16.0349,
      89.1972,
      22.95,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "4.OG_N-On_2n-3_2",
    polygon: [
      14.405,
      90.6579,
      19.5,
      14.405,
      90.6579,
      21.76,
      13.68,
      90.6604,
      21.76,
      13.68,
      90.6604,
      21.76,
      13.68,
      90.6604,
      19.5,
      14.405,
      90.6579,
      19.5,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "4.OG_Bn-Cn_8-9_2",
    polygon: [
      -43.1023,
      60.768,
      19.5,
      -43.1023,
      60.768,
      21.76,
      -43.0948,
      62.918,
      21.76,
      -43.0948,
      62.918,
      21.76,
      -43.0948,
      62.918,
      19.5,
      -43.1023,
      60.768,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_An_14-15_2",
    polygon: [
      -59.057,
      25.0886,
      16.05,
      -59.057,
      25.0886,
      18.31,
      -58.9807,
      27.2373,
      18.31,
      -58.9807,
      27.2373,
      18.31,
      -58.9807,
      27.2373,
      16.05,
      -59.057,
      25.0886,
      16.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5.OG_L-M_1_3",
    polygon: [
      2.8921,
      104.1758,
      22.95,
      2.8921,
      104.1758,
      25.21,
      4.4449,
      104.326,
      25.21,
      4.4449,
      104.326,
      25.21,
      4.4449,
      104.326,
      22.95,
      2.8921,
      104.1758,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_9-10_1",
    polygon: [
      -43.1215,
      55.2681,
      22.95,
      -43.1215,
      55.2681,
      25.21,
      -43.114,
      57.4181,
      25.21,
      -43.114,
      57.4181,
      25.21,
      -43.114,
      57.4181,
      22.95,
      -43.1215,
      55.2681,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5.OG_Bn-Cn_7-8_1",
    polygon: [
      -43.082,
      66.589,
      22.95,
      -43.082,
      66.589,
      25.02,
      -43.0752,
      68.539,
      25.02,
      -43.0752,
      68.539,
      25.02,
      -43.0752,
      68.539,
      22.95,
      -43.082,
      66.589,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5.OG_L-M_1_2",
    polygon: [
      5.2411,
      104.403,
      22.95,
      5.2411,
      104.403,
      25.21,
      7.197,
      104.5921,
      25.21,
      7.197,
      104.5921,
      25.21,
      7.197,
      104.5921,
      22.95,
      5.2411,
      104.403,
      22.95,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_4-5_1",
    polygon: [
      -56.2972,
      83.1079,
      22.95,
      -56.2972,
      83.1079,
      25.02,
      -56.151,
      85.0976,
      25.02,
      -56.151,
      85.0976,
      25.02,
      -56.151,
      85.0976,
      22.95,
      -56.2972,
      83.1079,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An-Bn_1-2n_2",
    polygon: [
      -52.1062,
      98.4777,
      22.95,
      -52.1062,
      98.4777,
      25.02,
      -50.0187,
      99.0518,
      25.02,
      -50.0187,
      99.0518,
      25.02,
      -50.0187,
      99.0518,
      22.95,
      -52.1062,
      98.4777,
      22.95,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_11-12_1",
    polygon: [
      -44.3687,
      42.3223,
      22.39,
      -44.3687,
      42.3223,
      25.19,
      -44.3653,
      43.2973,
      25.19,
      -44.3653,
      43.2973,
      25.19,
      -44.3653,
      43.2973,
      22.39,
      -44.3687,
      42.3223,
      22.39,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "4.OG_Bn-Cn_14-15_2",
    polygon: [
      -43.2194,
      27.7732,
      19.5,
      -43.2194,
      27.7732,
      21.76,
      -43.2119,
      29.9232,
      21.76,
      -43.2119,
      29.9232,
      21.76,
      -43.2119,
      29.9232,
      19.5,
      -43.2194,
      27.7732,
      19.5,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5.OG_Dn-En_1-2n_1",
    polygon: [
      -38.2707,
      100.771,
      22.95,
      -38.2707,
      100.771,
      25.02,
      -36.3115,
      101.0568,
      25.02,
      -36.3115,
      101.0568,
      25.02,
      -36.3115,
      101.0568,
      22.95,
      -38.2707,
      100.771,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_N-On_1_1",
    polygon: [
      11.7928,
      106.4284,
      22.95,
      11.7928,
      106.4284,
      25.21,
      14.0721,
      106.6489,
      25.21,
      14.0721,
      106.6489,
      25.21,
      14.0721,
      106.6489,
      22.95,
      11.7928,
      106.4284,
      22.95,
    ],
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_O-P_1_2",
    polygon: [
      17.6405,
      106.9939,
      19.5,
      17.6405,
      106.9939,
      21.76,
      19.5964,
      107.1831,
      21.76,
      19.5964,
      107.1831,
      21.76,
      19.5964,
      107.1831,
      19.5,
      17.6405,
      106.9939,
      19.5,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_4-5_1",
    polygon: [
      -56.3265,
      82.709,
      19.5,
      -56.3265,
      82.709,
      21.57,
      -56.1803,
      84.6987,
      21.57,
      -56.1803,
      84.6987,
      21.57,
      -56.1803,
      84.6987,
      19.5,
      -56.3265,
      82.709,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_Jn-Kn_4n-4_1",
    polygon: [
      -5.6588,
      85.2953,
      16.05,
      -5.6588,
      85.2953,
      18.31,
      -5.652,
      87.2602,
      18.31,
      -5.652,
      87.2602,
      18.31,
      -5.652,
      87.2602,
      16.05,
      -5.6588,
      85.2953,
      16.05,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4.OG_An_10-11_1",
    polygon: [
      -56.8605,
      47.0803,
      19.5,
      -56.8605,
      47.0803,
      21.76,
      -56.7842,
      49.229,
      21.76,
      -56.7842,
      49.229,
      21.76,
      -56.7842,
      49.229,
      19.5,
      -56.8605,
      47.0803,
      19.5,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "6.OG_Bn-Cn_1-2n_1",
    polygon: [
      -44.1881,
      99.9078,
      26.4,
      -44.1881,
      99.9078,
      28.47,
      -42.2288,
      100.1936,
      28.47,
      -42.2288,
      100.1936,
      28.47,
      -42.2288,
      100.1936,
      26.4,
      -44.1881,
      99.9078,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Jn-Kn_5-6_1",
    polygon: [
      -5.6923,
      75.6753,
      22.95,
      -5.6923,
      75.6753,
      25.21,
      -5.6855,
      77.6403,
      25.21,
      -5.6855,
      77.6403,
      25.21,
      -5.6855,
      77.6403,
      22.95,
      -5.6923,
      75.6753,
      22.95,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4.OG_An_8-9_2",
    polygon: [
      -57.8752,
      58.0718,
      19.5,
      -57.8752,
      58.0718,
      21.76,
      -57.7983,
      60.2205,
      21.76,
      -57.7983,
      60.2205,
      21.76,
      -57.7983,
      60.2205,
      19.5,
      -57.8752,
      58.0718,
      19.5,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5.OG_Jn-Kn_3-4n_1",
    polygon: [
      -5.654,
      86.6677,
      22.95,
      -5.654,
      86.6677,
      25.21,
      -5.6472,
      88.6327,
      25.21,
      -5.6472,
      88.6327,
      25.21,
      -5.6472,
      88.6327,
      22.95,
      -5.654,
      86.6677,
      22.95,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "6.OG_Gn-H_1_1",
    polygon: [
      -24.9201,
      102.7215,
      26.4,
      -24.9201,
      102.7215,
      28.47,
      -22.9608,
      103.0073,
      28.47,
      -22.9608,
      103.0073,
      28.47,
      -22.9608,
      103.0073,
      26.4,
      -24.9201,
      102.7215,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_On-O_4-5_1",
    polygon: [
      16.0115,
      82.4739,
      19.5,
      16.0115,
      82.4739,
      21.76,
      16.0183,
      84.4239,
      21.76,
      16.0183,
      84.4239,
      21.76,
      16.0183,
      84.4239,
      19.5,
      16.0115,
      82.4739,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "4.OG_Jn-Kn_4-5_2",
    polygon: [
      -5.6684,
      82.5303,
      19.5,
      -5.6684,
      82.5303,
      21.76,
      -5.6616,
      84.4953,
      21.76,
      -5.6616,
      84.4953,
      21.76,
      -5.6616,
      84.4953,
      19.5,
      -5.6684,
      82.5303,
      19.5,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4.OG_M-N_1_2",
    polygon: [
      9.3719,
      104.8024,
      19.5,
      9.3719,
      104.8024,
      21.76,
      11.3277,
      104.9916,
      21.76,
      11.3277,
      104.9916,
      21.76,
      11.3277,
      104.9916,
      19.5,
      9.3719,
      104.8024,
      19.5,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Dn-En_10-11_2",
    polygon: [
      -40.5727,
      49.7742,
      19.5,
      -40.5727,
      49.7742,
      21.76,
      -40.5653,
      51.9241,
      21.76,
      -40.5653,
      51.9241,
      21.76,
      -40.5653,
      51.9241,
      19.5,
      -40.5727,
      49.7742,
      19.5,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "3.OG_Cn-Dn_4n-4_1",
    polygon: [
      -43.0719,
      84.7931,
      15.47,
      -43.0719,
      84.7931,
      18.35,
      -41.2596,
      86.6013,
      18.35,
      -41.2596,
      86.6013,
      18.35,
      -41.2596,
      86.6013,
      15.47,
      -43.0719,
      84.7931,
      15.47,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
  },
  {
    ID: "5.OG_L-M_2n-3_2",
    polygon: [
      3.5501,
      90.6957,
      22.95,
      3.5501,
      90.6957,
      25.21,
      2.8251,
      90.6982,
      25.21,
      2.8251,
      90.6982,
      25.21,
      2.8251,
      90.6982,
      22.95,
      3.5501,
      90.6957,
      22.95,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "5.OG_An_2n-3_2",
    polygon: [
      -55.4652,
      94.0657,
      22.95,
      -55.4652,
      94.0657,
      25.02,
      -54.7095,
      96.0945,
      25.02,
      -54.7095,
      96.0945,
      25.02,
      -54.7095,
      96.0945,
      22.95,
      -55.4652,
      94.0657,
      22.95,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_9-10_1",
    polygon: [
      -57.9736,
      55.3236,
      22.95,
      -57.9736,
      55.3236,
      25.21,
      -57.8967,
      57.4722,
      25.21,
      -57.8967,
      57.4722,
      25.21,
      -57.8967,
      57.4722,
      22.95,
      -57.9736,
      55.3236,
      22.95,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "5.OG_M-N_1_1",
    polygon: [
      7.9933,
      104.6691,
      22.95,
      7.9933,
      104.6691,
      25.21,
      9.9492,
      104.8583,
      25.21,
      9.9492,
      104.8583,
      25.21,
      9.9492,
      104.8583,
      22.95,
      7.9933,
      104.6691,
      22.95,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Dn-En_1-2n_2",
    polygon: [
      -41.0216,
      100.3697,
      22.95,
      -41.0216,
      100.3697,
      25.02,
      -39.0623,
      100.6555,
      25.02,
      -39.0623,
      100.6555,
      25.02,
      -39.0623,
      100.6555,
      22.95,
      -41.0216,
      100.3697,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_13-14_2",
    polygon: [
      -57.6124,
      35.6534,
      22.95,
      -57.6124,
      35.6534,
      25.21,
      -58.8823,
      35.6579,
      25.21,
      -58.8823,
      35.6579,
      25.21,
      -58.8823,
      35.6579,
      22.95,
      -57.6124,
      35.6534,
      22.95,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "6.OG_An-Bn_1-2n_2",
    polygon: [
      -52.4603,
      98.2934,
      26.4,
      -52.4603,
      98.2934,
      28.47,
      -50.4149,
      99.0031,
      28.47,
      -50.4149,
      99.0031,
      28.47,
      -50.4149,
      99.0031,
      26.4,
      -52.4603,
      98.2934,
      26.4,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_5-6_2",
    polygon: [
      -56.9264,
      74.4396,
      26.4,
      -56.9264,
      74.4396,
      28.47,
      -56.7803,
      76.4293,
      28.47,
      -56.7803,
      76.4293,
      28.47,
      -56.7803,
      76.4293,
      26.4,
      -56.9264,
      74.4396,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "5.OG_Bn-Cn_15-16_1",
    polygon: [
      -43.2472,
      19.7833,
      22.95,
      -43.2472,
      19.7833,
      25.21,
      -43.2402,
      21.8033,
      25.21,
      -43.2402,
      21.8033,
      25.21,
      -43.2402,
      21.8033,
      22.95,
      -43.2472,
      19.7833,
      22.95,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_Bn-Cn_1-2n_2",
    polygon: [
      -46.9192,
      99.5095,
      26.4,
      -46.9192,
      99.5095,
      28.47,
      -44.9599,
      99.7953,
      28.47,
      -44.9599,
      99.7953,
      28.47,
      -44.9599,
      99.7953,
      26.4,
      -46.9192,
      99.5095,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_N-On_1_2",
    polygon: [
      12.0271,
      105.6152,
      22.95,
      12.0271,
      105.6152,
      25.21,
      12.0308,
      106.6751,
      25.21,
      12.0308,
      106.6751,
      25.21,
      12.0308,
      106.6751,
      22.95,
      12.0271,
      105.6152,
      22.95,
    ],
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_10-11_2",
    polygon: [
      -56.7636,
      49.8086,
      19.5,
      -56.7636,
      49.8086,
      21.76,
      -56.6899,
      51.8823,
      21.76,
      -56.6899,
      51.8823,
      21.76,
      -56.6899,
      51.8823,
      19.5,
      -56.7636,
      49.8086,
      19.5,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "6.OG_Dn-En_3-4n_1",
    polygon: [
      -36.6904,
      87.7559,
      26.4,
      -36.6904,
      87.7559,
      28.47,
      -37.4404,
      87.7585,
      28.47,
      -37.4404,
      87.7585,
      28.47,
      -37.4404,
      87.7585,
      26.4,
      -36.6904,
      87.7559,
      26.4,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "6.OG_Fn-Gn_1_1",
    polygon: [
      -27.671,
      102.3202,
      26.4,
      -27.671,
      102.3202,
      28.47,
      -25.7117,
      102.606,
      28.47,
      -25.7117,
      102.606,
      28.47,
      -25.7117,
      102.606,
      26.4,
      -27.671,
      102.3202,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_Bn-Cn_7-8_2",
    polygon: [
      -43.0927,
      63.523,
      16.05,
      -43.0927,
      63.523,
      18.31,
      -43.0893,
      64.508,
      18.31,
      -43.0893,
      64.508,
      18.31,
      -43.0893,
      64.508,
      16.05,
      -43.0927,
      63.523,
      16.05,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5.OG_Kn-L_2n-3_1",
    polygon: [
      -3.1518,
      91.6141,
      22.95,
      -3.1518,
      91.6141,
      25.21,
      -5.2868,
      91.6215,
      25.21,
      -5.2868,
      91.6215,
      25.21,
      -5.2868,
      91.6215,
      22.95,
      -3.1518,
      91.6141,
      22.95,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "4.OG_L-M_1_2",
    polygon: [
      1.5135,
      104.0425,
      19.5,
      1.5135,
      104.0425,
      21.76,
      3.4694,
      104.2316,
      21.76,
      3.4694,
      104.2316,
      21.76,
      3.4694,
      104.2316,
      19.5,
      1.5135,
      104.0425,
      19.5,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_An_8-9_1",
    polygon: [
      -57.7769,
      60.8201,
      16.05,
      -57.7769,
      60.8201,
      18.31,
      -57.7,
      62.9687,
      18.31,
      -57.7,
      62.9687,
      18.31,
      -57.7,
      62.9687,
      16.05,
      -57.7769,
      60.8201,
      16.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "4.OG_On-O_4n-4_1",
    polygon: [
      16.0211,
      85.2239,
      19.5,
      16.0211,
      85.2239,
      21.76,
      16.0279,
      87.1739,
      21.76,
      16.0279,
      87.1739,
      21.76,
      16.0279,
      87.1739,
      19.5,
      16.0211,
      85.2239,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5.OG_An_4-5_2",
    polygon: [
      -56.5004,
      80.3404,
      22.95,
      -56.5004,
      80.3404,
      25.02,
      -56.3543,
      82.33,
      25.02,
      -56.3543,
      82.33,
      25.02,
      -56.3543,
      82.33,
      22.95,
      -56.5004,
      80.3404,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 16:45": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Bn-Cn_7-8_2",
    polygon: [
      -43.0927,
      63.523,
      19.5,
      -43.0927,
      63.523,
      21.76,
      -43.0893,
      64.508,
      21.76,
      -43.0893,
      64.508,
      21.76,
      -43.0893,
      64.508,
      19.5,
      -43.0927,
      63.523,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5.OG_On-O_1_1",
    polygon: [
      16.242,
      106.8587,
      22.95,
      16.242,
      106.8587,
      25.21,
      18.1979,
      107.0478,
      25.21,
      18.1979,
      107.0478,
      25.21,
      18.1979,
      107.0478,
      22.95,
      16.242,
      106.8587,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_5-6_2",
    polygon: [
      -43.0437,
      77.5839,
      22.95,
      -43.0437,
      77.5839,
      25.02,
      -43.0396,
      78.7839,
      25.02,
      -43.0396,
      78.7839,
      25.02,
      -43.0396,
      78.7839,
      22.95,
      -43.0437,
      77.5839,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "6.OG_An_1-2n_1",
    polygon: [
      -54.5615,
      96.3315,
      26.4,
      -54.5615,
      96.3315,
      28.47,
      -53.0699,
      97.9007,
      28.47,
      -53.0699,
      97.9007,
      28.47,
      -53.0699,
      97.9007,
      26.4,
      -54.5615,
      96.3315,
      26.4,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_6-7_1",
    polygon: [
      -57.127,
      71.707,
      26.4,
      -57.127,
      71.707,
      28.47,
      -56.981,
      73.6966,
      28.47,
      -56.981,
      73.6966,
      28.47,
      -56.981,
      73.6966,
      26.4,
      -57.127,
      71.707,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "5.OG_Bn-Cn_5-6_1",
    polygon: [
      -43.0533,
      74.8289,
      22.95,
      -43.0533,
      74.8289,
      25.02,
      -43.0465,
      76.7789,
      25.02,
      -43.0465,
      76.7789,
      25.02,
      -43.0465,
      76.7789,
      22.95,
      -43.0533,
      74.8289,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "4.OG_Bn-Cn_13-14_1",
    polygon: [
      -43.2098,
      30.5382,
      19.5,
      -43.2098,
      30.5382,
      21.76,
      -43.2023,
      32.6882,
      21.76,
      -43.2023,
      32.6882,
      21.76,
      -43.2023,
      32.6882,
      19.5,
      -43.2098,
      30.5382,
      19.5,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5.OG_An_7-8_2",
    polygon: [
      -57.6785,
      63.5683,
      22.95,
      -57.6785,
      63.5683,
      25.21,
      -57.6433,
      64.5527,
      25.21,
      -57.6433,
      64.5527,
      25.21,
      -57.6433,
      64.5527,
      22.95,
      -57.6785,
      63.5683,
      22.95,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An-Bn_1-2n_1",
    polygon: [
      -49.6899,
      99.1053,
      26.4,
      -49.6899,
      99.1053,
      28.47,
      -47.7306,
      99.3911,
      28.47,
      -47.7306,
      99.3911,
      28.47,
      -47.7306,
      99.3911,
      26.4,
      -49.6899,
      99.1053,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_8-9_1",
    polygon: [
      -43.1118,
      58.0381,
      22.95,
      -43.1118,
      58.0381,
      25.21,
      -43.1043,
      60.188,
      25.21,
      -43.1043,
      60.188,
      25.21,
      -43.1043,
      60.188,
      22.95,
      -43.1118,
      58.0381,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_Fn-Gn_1_2",
    polygon: [
      -30.4218,
      101.9189,
      26.4,
      -30.4218,
      101.9189,
      28.47,
      -28.4626,
      102.2047,
      28.47,
      -28.4626,
      102.2047,
      28.47,
      -28.4626,
      102.2047,
      26.4,
      -30.4218,
      101.9189,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_11-12_2",
    polygon: [
      -44.3646,
      43.4948,
      22.39,
      -44.3646,
      43.4948,
      25.19,
      -44.3612,
      44.4698,
      25.19,
      -44.3612,
      44.4698,
      25.19,
      -44.3612,
      44.4698,
      22.39,
      -44.3646,
      43.4948,
      22.39,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "4.OG_An_9-10_2",
    polygon: [
      -58.0868,
      52.1606,
      19.5,
      -58.0868,
      52.1606,
      21.76,
      -57.9943,
      54.744,
      21.76,
      -57.9943,
      54.744,
      21.76,
      -57.9943,
      54.744,
      19.5,
      -58.0868,
      52.1606,
      19.5,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5.OG_H-In_1_1",
    polygon: [
      -19.1157,
      103.4394,
      22.95,
      -19.1157,
      103.4394,
      25.21,
      -17.1598,
      103.6285,
      25.21,
      -17.1598,
      103.6285,
      25.21,
      -17.1598,
      103.6285,
      22.95,
      -19.1157,
      103.4394,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "3.OG_An_15-16_1",
    polygon: [
      -59.149,
      22.5003,
      16.05,
      -59.149,
      22.5003,
      18.31,
      -59.0783,
      24.489,
      18.31,
      -59.0783,
      24.489,
      18.31,
      -59.0783,
      24.489,
      16.05,
      -59.149,
      22.5003,
      16.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:30": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5.OG_Jn-Kn_5-6_2",
    polygon: [
      -5.6827,
      78.4253,
      22.95,
      -5.6827,
      78.4253,
      25.21,
      -5.6759,
      80.3903,
      25.21,
      -5.6759,
      80.3903,
      25.21,
      -5.6759,
      80.3903,
      22.95,
      -5.6827,
      78.4253,
      22.95,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "6.OG_H-In_1_2",
    polygon: [
      -17.7371,
      103.5727,
      26.4,
      -17.7371,
      103.5727,
      28.66,
      -15.7812,
      103.7618,
      28.66,
      -15.7812,
      103.7618,
      28.66,
      -15.7812,
      103.7618,
      26.4,
      -17.7371,
      103.5727,
      26.4,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Jn-Kn_1_2",
    polygon: [
      -6.7484,
      104.6354,
      26.4,
      -6.7484,
      104.6354,
      28.66,
      -4.4939,
      104.8534,
      28.66,
      -4.4939,
      104.8534,
      28.66,
      -4.4939,
      104.8534,
      26.4,
      -6.7484,
      104.6354,
      26.4,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Jn-Kn_4n-4_1",
    polygon: [
      -5.6588,
      85.2953,
      19.5,
      -5.6588,
      85.2953,
      21.76,
      -5.652,
      87.2602,
      21.76,
      -5.652,
      87.2602,
      21.76,
      -5.652,
      87.2602,
      19.5,
      -5.6588,
      85.2953,
      19.5,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "4.OG_On-O_3-4n_1",
    polygon: [
      16.0307,
      87.9722,
      19.5,
      16.0307,
      87.9722,
      21.76,
      16.0375,
      89.9222,
      21.76,
      16.0375,
      89.9222,
      21.76,
      16.0375,
      89.9222,
      19.5,
      16.0307,
      87.9722,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "4.OG_Bn-Cn_15-16_1",
    polygon: [
      -43.2472,
      19.7833,
      19.5,
      -43.2472,
      19.7833,
      21.76,
      -43.2402,
      21.8033,
      21.76,
      -43.2402,
      21.8033,
      21.76,
      -43.2402,
      21.8033,
      19.5,
      -43.2472,
      19.7833,
      19.5,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_An_2n-3_2",
    polygon: [
      -55.5209,
      93.6881,
      26.4,
      -55.5209,
      93.6881,
      28.47,
      -54.8938,
      95.7603,
      28.47,
      -54.8938,
      95.7603,
      28.47,
      -54.8938,
      95.7603,
      26.4,
      -55.5209,
      93.6881,
      26.4,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_6-7_2",
    polygon: [
      -57.3291,
      68.9544,
      26.4,
      -57.3291,
      68.9544,
      28.47,
      -57.183,
      70.944,
      28.47,
      -57.183,
      70.944,
      28.47,
      -57.183,
      70.944,
      26.4,
      -57.3291,
      68.9544,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Gn-H_5-6_3",
    polygon: [
      -21.3022,
      75.7272,
      26.4,
      -21.3022,
      75.7272,
      28.66,
      -21.2997,
      76.4522,
      28.66,
      -21.2997,
      76.4522,
      28.66,
      -21.2997,
      76.4522,
      26.4,
      -21.3022,
      75.7272,
      26.4,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5.OG_Jn-Kn_3-4n_2",
    polygon: [
      -5.6449,
      89.2727,
      22.95,
      -5.6449,
      89.2727,
      25.21,
      -5.6424,
      89.9977,
      25.21,
      -5.6424,
      89.9977,
      25.21,
      -5.6424,
      89.9977,
      22.95,
      -5.6449,
      89.2727,
      22.95,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "4.OG_Gn-H_5-6_1",
    polygon: [
      -21.2974,
      77.099,
      19.5,
      -21.2974,
      77.099,
      21.76,
      -21.2906,
      79.049,
      21.76,
      -21.2906,
      79.049,
      21.76,
      -21.2906,
      79.049,
      19.5,
      -21.2974,
      77.099,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "6.OG_P_1_1",
    polygon: [
      23.1128,
      106.1313,
      26.4,
      23.1128,
      106.1313,
      28.66,
      25.0686,
      106.3204,
      28.66,
      25.0686,
      106.3204,
      28.66,
      25.0686,
      106.3204,
      26.4,
      23.1128,
      106.1313,
      26.4,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_3-4n_1",
    polygon: [
      -55.9232,
      88.1996,
      26.4,
      -55.9232,
      88.1996,
      28.47,
      -55.7771,
      90.1892,
      28.47,
      -55.7771,
      90.1892,
      28.47,
      -55.7771,
      90.1892,
      26.4,
      -55.9232,
      88.1996,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_13-14_2",
    polygon: [
      -57.6451,
      35.6535,
      26.4,
      -57.6451,
      35.6535,
      28.66,
      -58.9201,
      35.658,
      28.66,
      -58.9201,
      35.658,
      28.66,
      -58.9201,
      35.658,
      26.4,
      -57.6451,
      35.6535,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Dn-En_13-14_1",
    polygon: [
      -40.6428,
      33.1093,
      19.532,
      -40.6428,
      33.1093,
      21.728,
      -42.7088,
      33.1165,
      21.728,
      -42.7088,
      33.1165,
      21.728,
      -42.7088,
      33.1165,
      19.532,
      -40.6428,
      33.1093,
      19.532,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "6.OG_En-Fn_1-2n_1",
    polygon: [
      -33.1727,
      101.5176,
      26.4,
      -33.1727,
      101.5176,
      28.47,
      -31.2135,
      101.8034,
      28.47,
      -31.2135,
      101.8034,
      28.47,
      -31.2135,
      101.8034,
      26.4,
      -33.1727,
      101.5176,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_15-16_2",
    polygon: [
      -43.2382,
      22.3833,
      22.95,
      -43.2382,
      22.3833,
      25.21,
      -43.2311,
      24.4033,
      25.21,
      -43.2311,
      24.4033,
      25.21,
      -43.2311,
      24.4033,
      22.95,
      -43.2382,
      22.3833,
      22.95,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "QVB_4.OG_57",
    polygon: [
      -57.0393,
      52.4015,
      19.532,
      -57.0393,
      52.4015,
      21.728,
      -58.1353,
      52.4053,
      21.728,
      -58.1353,
      52.4053,
      21.728,
      -58.1353,
      52.4053,
      19.532,
      -57.0393,
      52.4015,
      19.532,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "4.OG_An_9-10_1",
    polygon: [
      -57.9736,
      55.3236,
      19.5,
      -57.9736,
      55.3236,
      21.76,
      -57.8967,
      57.4722,
      21.76,
      -57.8967,
      57.4722,
      21.76,
      -57.8967,
      57.4722,
      19.5,
      -57.9736,
      55.3236,
      19.5,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "5.OG_Jn-Kn_1_4",
    polygon: [
      -4.7535,
      104.0327,
      22.95,
      -4.7535,
      104.0327,
      25.21,
      -4.7498,
      105.1077,
      25.21,
      -4.7498,
      105.1077,
      25.21,
      -4.7498,
      105.1077,
      22.95,
      -4.7535,
      104.0327,
      22.95,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "6.OG_Cn-Dn_4n-4_1",
    polygon: [
      -43.0776,
      84.7849,
      25.82,
      -43.0776,
      84.7849,
      28.7,
      -41.2702,
      86.5978,
      28.7,
      -41.2702,
      86.5978,
      28.7,
      -41.2702,
      86.5978,
      25.82,
      -43.0776,
      84.7849,
      25.82,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "5.OG_In-Jn_1_1",
    polygon: [
      -16.3635,
      103.7055,
      22.95,
      -16.3635,
      103.7055,
      25.21,
      -14.4076,
      103.8947,
      25.21,
      -14.4076,
      103.8947,
      25.21,
      -14.4076,
      103.8947,
      22.95,
      -16.3635,
      103.7055,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Bn-Cn_8-9_2",
    polygon: [
      -44.2977,
      60.7722,
      25.82,
      -44.2977,
      60.7722,
      28.64,
      -44.2902,
      62.9222,
      28.64,
      -44.2902,
      62.9222,
      28.64,
      -44.2902,
      62.9222,
      25.82,
      -44.2977,
      60.7722,
      25.82,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_An_14-15_1",
    polygon: [
      -58.9594,
      27.8369,
      26.4,
      -58.9594,
      27.8369,
      28.66,
      -58.883,
      29.9855,
      28.66,
      -58.883,
      29.9855,
      28.66,
      -58.883,
      29.9855,
      26.4,
      -58.9594,
      27.8369,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_In-Jn_1_1",
    polygon: [
      -14.9849,
      103.8388,
      26.4,
      -14.9849,
      103.8388,
      28.66,
      -13.0291,
      104.028,
      28.66,
      -13.0291,
      104.028,
      28.66,
      -13.0291,
      104.028,
      26.4,
      -14.9849,
      103.8388,
      26.4,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Jn-Kn_4-5_1",
    polygon: [
      -5.678,
      79.7903,
      26.4,
      -5.678,
      79.7903,
      28.66,
      -5.6711,
      81.7553,
      28.66,
      -5.6711,
      81.7553,
      28.66,
      -5.6711,
      81.7553,
      26.4,
      -5.678,
      79.7903,
      26.4,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "6.OG_An_7-8_1",
    polygon: [
      -57.5312,
      66.2018,
      26.4,
      -57.5312,
      66.2018,
      28.47,
      -57.3851,
      68.1915,
      28.47,
      -57.3851,
      68.1915,
      28.47,
      -57.3851,
      68.1915,
      26.4,
      -57.5312,
      66.2018,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_12-13_2",
    polygon: [
      -57.2497,
      36.1422,
      26.4,
      -57.2497,
      36.1422,
      28.66,
      -57.1755,
      38.2159,
      28.66,
      -57.1755,
      38.2159,
      28.66,
      -57.1755,
      38.2159,
      26.4,
      -57.2497,
      36.1422,
      26.4,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_7-8_2",
    polygon: [
      -57.6785,
      63.5683,
      26.4,
      -57.6785,
      63.5683,
      28.66,
      -57.6433,
      64.5527,
      28.66,
      -57.6433,
      64.5527,
      28.66,
      -57.6433,
      64.5527,
      26.4,
      -57.6785,
      63.5683,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_P_1_2",
    polygon: [
      24.4985,
      107.6572,
      22.95,
      24.4985,
      107.6572,
      25.21,
      26.4544,
      107.8463,
      25.21,
      26.4544,
      107.8463,
      25.21,
      26.4544,
      107.8463,
      22.95,
      24.4985,
      107.6572,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_13-14_1",
    polygon: [
      -58.8617,
      30.5852,
      22.95,
      -58.8617,
      30.5852,
      25.21,
      -58.7854,
      32.7338,
      25.21,
      -58.7854,
      32.7338,
      25.21,
      -58.7854,
      32.7338,
      22.95,
      -58.8617,
      30.5852,
      22.95,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "6.OG_An_4n-4_1",
    polygon: [
      -56.1254,
      85.447,
      26.4,
      -56.1254,
      85.447,
      28.47,
      -55.9792,
      87.4366,
      28.47,
      -55.9792,
      87.4366,
      28.47,
      -55.9792,
      87.4366,
      26.4,
      -56.1254,
      85.447,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_M-N_2n-3_1",
    polygon: [
      10.27,
      90.6723,
      19.5,
      10.27,
      90.6723,
      21.76,
      8.32,
      90.6791,
      21.76,
      8.32,
      90.6791,
      21.76,
      8.32,
      90.6791,
      19.5,
      10.27,
      90.6723,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "6.OG_N-On_2n-3_2",
    polygon: [
      14.395,
      90.6579,
      26.4,
      14.395,
      90.6579,
      28.66,
      13.67,
      90.6605,
      28.66,
      13.67,
      90.6605,
      28.66,
      13.67,
      90.6605,
      26.4,
      14.395,
      90.6579,
      26.4,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "6.OG_P_1_2",
    polygon: [
      25.8848,
      106.3994,
      26.4,
      25.8848,
      106.3994,
      28.66,
      27.8407,
      106.5885,
      28.66,
      27.8407,
      106.5885,
      28.66,
      27.8407,
      106.5885,
      26.4,
      25.8848,
      106.3994,
      26.4,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_En-Fn_1-2n_2",
    polygon: [
      -35.9236,
      101.1163,
      26.4,
      -35.9236,
      101.1163,
      28.47,
      -33.9644,
      101.4021,
      28.47,
      -33.9644,
      101.4021,
      28.47,
      -33.9644,
      101.4021,
      26.4,
      -35.9236,
      101.1163,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_4-5_1",
    polygon: [
      -44.2366,
      80.265,
      22.95,
      -44.2366,
      80.265,
      25.02,
      -44.2339,
      81.015,
      25.02,
      -44.2339,
      81.015,
      25.02,
      -44.2339,
      81.015,
      22.95,
      -44.2366,
      80.265,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
  },
  {
    ID: "7.OG_An_3-4n_1",
    polygon: [
      -56.0959,
      85.8484,
      29.85,
      -56.0959,
      85.8484,
      31.92,
      -55.9498,
      87.8381,
      31.92,
      -55.9498,
      87.8381,
      31.92,
      -55.9498,
      87.8381,
      29.85,
      -56.0959,
      85.8484,
      29.85,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_Gn-H_4-5_1",
    polygon: [
      -21.2879,
      79.849,
      19.5,
      -21.2879,
      79.849,
      21.76,
      -21.2811,
      81.799,
      21.76,
      -21.2811,
      81.799,
      21.76,
      -21.2811,
      81.799,
      19.5,
      -21.2879,
      79.849,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "5.OG_Bn-Cn_11-12_3",
    polygon: [
      -44.3605,
      44.6698,
      22.39,
      -44.3605,
      44.6698,
      25.19,
      -44.3571,
      45.6448,
      25.19,
      -44.3571,
      45.6448,
      25.19,
      -44.3571,
      45.6448,
      22.39,
      -44.3605,
      44.6698,
      22.39,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5.OG_Bn-Cn_8-9_2",
    polygon: [
      -43.1023,
      60.768,
      22.95,
      -43.1023,
      60.768,
      25.21,
      -43.0948,
      62.918,
      25.21,
      -43.0948,
      62.918,
      25.21,
      -43.0948,
      62.918,
      22.95,
      -43.1023,
      60.768,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "5.OG_Gn-H_5-6_2",
    polygon: [
      -21.3023,
      75.7172,
      22.95,
      -21.3023,
      75.7172,
      25.21,
      -21.2955,
      77.6672,
      25.21,
      -21.2955,
      77.6672,
      25.21,
      -21.2955,
      77.6672,
      22.95,
      -21.3023,
      75.7172,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "5.OG_In-Jn_1_2",
    polygon: [
      -13.6113,
      103.9717,
      22.95,
      -13.6113,
      103.9717,
      25.21,
      -11.6555,
      104.1608,
      25.21,
      -11.6555,
      104.1608,
      25.21,
      -11.6555,
      104.1608,
      22.95,
      -13.6113,
      103.9717,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_In-Jn_1_2",
    polygon: [
      -12.2328,
      104.105,
      26.4,
      -12.2328,
      104.105,
      28.66,
      -10.2769,
      104.2941,
      28.66,
      -10.2769,
      104.2941,
      28.66,
      -10.2769,
      104.2941,
      26.4,
      -12.2328,
      104.105,
      26.4,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Jn-Kn_4-5_1",
    polygon: [
      -5.6732,
      81.1703,
      22.95,
      -5.6732,
      81.1703,
      25.21,
      -5.6663,
      83.1353,
      25.21,
      -5.6663,
      83.1353,
      25.21,
      -5.6663,
      83.1353,
      22.95,
      -5.6732,
      81.1703,
      22.95,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5.OG_An_8-9_1",
    polygon: [
      -57.7769,
      60.8201,
      22.95,
      -57.7769,
      60.8201,
      25.21,
      -57.7,
      62.9687,
      25.21,
      -57.7,
      62.9687,
      25.21,
      -57.7,
      62.9687,
      22.95,
      -57.7769,
      60.8201,
      22.95,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "7.OG_Fn-Gn_1-2n_2",
    polygon: [
      -30.0146,
      100.4617,
      29.27,
      -30.0146,
      100.4617,
      32.09,
      -28.0553,
      100.747,
      32.09,
      -28.0553,
      100.747,
      32.09,
      -28.0553,
      100.747,
      29.27,
      -30.0146,
      100.4617,
      29.27,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Jn-Kn_1_3",
    polygon: [
      -4.7535,
      104.0327,
      26.4,
      -4.7535,
      104.0327,
      28.66,
      -4.7498,
      105.1077,
      28.66,
      -4.7498,
      105.1077,
      28.66,
      -4.7498,
      105.1077,
      26.4,
      -4.7535,
      104.0327,
      26.4,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
  },
  {
    ID: "6.OG_An_4-5_1",
    polygon: [
      -56.3275,
      82.6944,
      26.4,
      -56.3275,
      82.6944,
      28.47,
      -56.1814,
      84.6841,
      28.47,
      -56.1814,
      84.6841,
      28.47,
      -56.1814,
      84.6841,
      26.4,
      -56.3275,
      82.6944,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_6-7_1",
    polygon: [
      -45.465,
      72.0938,
      29.27,
      -45.465,
      72.0938,
      32.09,
      -45.4582,
      74.0488,
      32.09,
      -45.4582,
      74.0488,
      32.09,
      -45.4582,
      74.0488,
      29.27,
      -45.465,
      72.0938,
      29.27,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "6.OG_Dn-En_1-2n_1",
    polygon: [
      -38.6863,
      100.7104,
      26.4,
      -38.6863,
      100.7104,
      28.47,
      -36.7271,
      100.9962,
      28.47,
      -36.7271,
      100.9962,
      28.47,
      -36.7271,
      100.9962,
      26.4,
      -38.6863,
      100.7104,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Gn-H_3-4n_1",
    polygon: [
      -23.0055,
      87.7082,
      26.4,
      -23.0055,
      87.7082,
      28.47,
      -24.9555,
      87.715,
      28.47,
      -24.9555,
      87.715,
      28.47,
      -24.9555,
      87.715,
      26.4,
      -23.0055,
      87.7082,
      26.4,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "6.OG_An_12-13_1",
    polygon: [
      -57.1542,
      38.8155,
      26.4,
      -57.1542,
      38.8155,
      28.66,
      -57.0773,
      40.9642,
      28.66,
      -57.0773,
      40.9642,
      28.66,
      -57.0773,
      40.9642,
      26.4,
      -57.1542,
      38.8155,
      26.4,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "QVB_6.OG_90",
    polygon: [
      25.4449,
      90.6194,
      26.4,
      25.4449,
      90.6194,
      28.66,
      23.4949,
      90.6262,
      28.66,
      23.4949,
      90.6262,
      28.66,
      23.4949,
      90.6262,
      26.4,
      25.4449,
      90.6194,
      26.4,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "7.OG_An_3-4n_2",
    polygon: [
      -55.8937,
      88.601,
      29.85,
      -55.8937,
      88.601,
      31.92,
      -55.7476,
      90.5907,
      31.92,
      -55.7476,
      90.5907,
      31.92,
      -55.7476,
      90.5907,
      29.85,
      -55.8937,
      88.601,
      29.85,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_N-On_2n-3_1",
    polygon: [
      13.02,
      90.6627,
      19.5,
      13.02,
      90.6627,
      21.76,
      11.07,
      90.6695,
      21.76,
      11.07,
      90.6695,
      21.76,
      11.07,
      90.6695,
      19.5,
      13.02,
      90.6627,
      19.5,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "5.OG_Bn-Cn_14-15_1",
    polygon: [
      -43.229,
      25.0233,
      22.95,
      -43.229,
      25.0233,
      25.21,
      -43.2215,
      27.1732,
      25.21,
      -43.2215,
      27.1732,
      25.21,
      -43.2215,
      27.1732,
      22.95,
      -43.229,
      25.0233,
      22.95,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_Bn-Cn_13-14_1",
    polygon: [
      -44.403,
      30.5324,
      25.82,
      -44.403,
      30.5324,
      28.64,
      -44.3955,
      32.6824,
      28.64,
      -44.3955,
      32.6824,
      28.64,
      -44.3955,
      32.6824,
      25.82,
      -44.403,
      30.5324,
      25.82,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "4.OG_Dn-En_13-14_2",
    polygon: [
      -40.6308,
      33.1093,
      19.5,
      -40.6308,
      33.1093,
      21.76,
      -40.6227,
      35.4192,
      21.76,
      -40.6227,
      35.4192,
      21.76,
      -40.6227,
      35.4192,
      19.5,
      -40.6308,
      33.1093,
      19.5,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "5.OG_An_11-12_2",
    polygon: [
      -56.9587,
      44.3321,
      22.95,
      -56.9587,
      44.3321,
      25.21,
      -56.8818,
      46.4807,
      25.21,
      -56.8818,
      46.4807,
      25.21,
      -56.8818,
      46.4807,
      22.95,
      -56.9587,
      44.3321,
      22.95,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "3.OG_An_15-16_2",
    polygon: [
      -59.241,
      19.9119,
      16.05,
      -59.241,
      19.9119,
      18.31,
      -59.1703,
      21.9006,
      18.31,
      -59.1703,
      21.9006,
      18.31,
      -59.1703,
      21.9006,
      16.05,
      -59.241,
      19.9119,
      16.05,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "4.OG_Gn-H_4-5_2",
    polygon: [
      -21.2782,
      82.6171,
      19.5,
      -21.2782,
      82.6171,
      21.76,
      -21.2714,
      84.5671,
      21.76,
      -21.2714,
      84.5671,
      21.76,
      -21.2714,
      84.5671,
      19.5,
      -21.2782,
      82.6171,
      19.5,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "6.OG_Bn-Cn_4-5_1",
    polygon: [
      -44.2299,
      80.2423,
      26.4,
      -44.2299,
      80.2423,
      28.47,
      -44.2272,
      80.9923,
      28.47,
      -44.2272,
      80.9923,
      28.47,
      -44.2272,
      80.9923,
      26.4,
      -44.2299,
      80.2423,
      26.4,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5.OG_Jn-Kn_1_1",
    polygon: [
      -10.8592,
      104.2378,
      22.95,
      -10.8592,
      104.2378,
      25.21,
      -8.9033,
      104.427,
      25.21,
      -8.9033,
      104.427,
      25.21,
      -8.9033,
      104.427,
      22.95,
      -10.8592,
      104.2378,
      22.95,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_N-On_2n-3_1",
    polygon: [
      14.41,
      90.6579,
      22.95,
      14.41,
      90.6579,
      25.21,
      12.46,
      90.6647,
      25.21,
      12.46,
      90.6647,
      25.21,
      12.46,
      90.6647,
      22.95,
      14.41,
      90.6579,
      22.95,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "6.OG_Bn-Cn_7-8_2",
    polygon: [
      -44.2881,
      63.5272,
      25.82,
      -44.2881,
      63.5272,
      28.425,
      -44.2847,
      64.5122,
      28.425,
      -44.2847,
      64.5122,
      28.425,
      -44.2847,
      64.5122,
      25.82,
      -44.2881,
      63.5272,
      25.82,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "7.OG_En-Fn_1-2n_1",
    polygon: [
      -32.7854,
      100.0583,
      29.27,
      -32.7854,
      100.0583,
      32.09,
      -30.8261,
      100.3436,
      32.09,
      -30.8261,
      100.3436,
      32.09,
      -30.8261,
      100.3436,
      29.27,
      -32.7854,
      100.0583,
      29.27,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Jn-Kn_1_1",
    polygon: [
      -9.4806,
      104.3711,
      26.4,
      -9.4806,
      104.3711,
      28.66,
      -7.5397,
      104.5588,
      28.66,
      -7.5397,
      104.5588,
      28.66,
      -7.5397,
      104.5588,
      26.4,
      -9.4806,
      104.3711,
      26.4,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_4-5_2",
    polygon: [
      -56.5297,
      79.9418,
      26.4,
      -56.5297,
      79.9418,
      28.47,
      -56.3836,
      81.9315,
      28.47,
      -56.3836,
      81.9315,
      28.47,
      -56.3836,
      81.9315,
      26.4,
      -56.5297,
      79.9418,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "6.OG_Dn-En_1-2n_2",
    polygon: [
      -41.4372,
      100.3091,
      26.4,
      -41.4372,
      100.3091,
      28.47,
      -39.4779,
      100.5949,
      28.47,
      -39.4779,
      100.5949,
      28.47,
      -39.4779,
      100.5949,
      26.4,
      -41.4372,
      100.3091,
      26.4,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Jn-Kn_4-5_2",
    polygon: [
      -5.6684,
      82.5353,
      26.4,
      -5.6684,
      82.5353,
      28.66,
      -5.6616,
      84.5003,
      28.66,
      -5.6616,
      84.5003,
      28.66,
      -5.6616,
      84.5003,
      26.4,
      -5.6684,
      82.5353,
      26.4,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "6.OG_Gn-H_5-6_1",
    polygon: [
      -21.2974,
      77.0972,
      26.4,
      -21.2974,
      77.0972,
      28.66,
      -21.2907,
      79.0472,
      28.66,
      -21.2907,
      79.0472,
      28.66,
      -21.2907,
      79.0472,
      26.4,
      -21.2974,
      77.0972,
      26.4,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "7.OG_An-Bn_1-2n_1",
    polygon: [
      -49.2941,
      99.163,
      29.85,
      -49.2941,
      99.163,
      31.92,
      -47.3348,
      99.4488,
      31.92,
      -47.3348,
      99.4488,
      31.92,
      -47.3348,
      99.4488,
      29.85,
      -49.2941,
      99.163,
      29.85,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_O-P_2n-3_1",
    polygon: [
      22.695,
      90.629,
      26.4,
      22.695,
      90.629,
      28.66,
      20.325,
      90.6373,
      28.66,
      20.325,
      90.6373,
      28.66,
      20.325,
      90.6373,
      26.4,
      22.695,
      90.629,
      26.4,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5.OG_Cn-Dn_4n-4_1",
    polygon: [
      -43.0676,
      84.7995,
      22.37,
      -43.0676,
      84.7995,
      25.25,
      -41.2533,
      86.6056,
      25.25,
      -41.2533,
      86.6056,
      25.25,
      -41.2533,
      86.6056,
      22.37,
      -43.0676,
      84.7995,
      22.37,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "6.OG_Fn-Gn_3-4n_1",
    polygon: [
      -25.7455,
      87.7177,
      26.4,
      -25.7455,
      87.7177,
      28.47,
      -27.6955,
      87.7245,
      28.47,
      -27.6955,
      87.7245,
      28.47,
      -27.6955,
      87.7245,
      26.4,
      -25.7455,
      87.7177,
      26.4,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "4.OG_An_13-14_1",
    polygon: [
      -58.8617,
      30.5852,
      19.5,
      -58.8617,
      30.5852,
      21.76,
      -58.7854,
      32.7338,
      21.76,
      -58.7854,
      32.7338,
      21.76,
      -58.7854,
      32.7338,
      19.5,
      -58.8617,
      30.5852,
      19.5,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "7.OG_An_15-16_2",
    polygon: [
      -57.8304,
      19.8926,
      29.27,
      -57.8304,
      19.8926,
      32.09,
      -57.7593,
      21.8813,
      32.09,
      -57.7593,
      21.8813,
      32.09,
      -57.7593,
      21.8813,
      29.27,
      -57.8304,
      19.8926,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_M-N_2n-3_2",
    polygon: [
      7.52,
      90.6819,
      19.5,
      7.52,
      90.6819,
      21.76,
      5.5701,
      90.6887,
      21.76,
      5.5701,
      90.6887,
      21.76,
      5.5701,
      90.6887,
      19.5,
      7.52,
      90.6819,
      19.5,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "5.OG_An_12-13_2",
    polygon: [
      -57.2497,
      36.1422,
      22.95,
      -57.2497,
      36.1422,
      25.21,
      -57.1755,
      38.2159,
      25.21,
      -57.1755,
      38.2159,
      25.21,
      -57.1755,
      38.2159,
      22.95,
      -57.2497,
      36.1422,
      22.95,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "3.OG_An_10-11_1",
    polygon: [
      -56.8605,
      47.0803,
      15.47,
      -56.8605,
      47.0803,
      18.29,
      -56.7842,
      49.229,
      18.29,
      -56.7842,
      49.229,
      18.29,
      -56.7842,
      49.229,
      15.47,
      -56.8605,
      47.0803,
      15.47,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_7-8_2",
    polygon: [
      -43.0927,
      63.523,
      22.95,
      -43.0927,
      63.523,
      25.21,
      -43.0893,
      64.508,
      25.21,
      -43.0893,
      64.508,
      25.21,
      -43.0893,
      64.508,
      22.95,
      -43.0927,
      63.523,
      22.95,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "7.OG_An_9-10_2",
    polygon: [
      -56.6609,
      52.5817,
      29.27,
      -56.6609,
      52.5817,
      32.09,
      -56.584,
      54.7303,
      32.09,
      -56.584,
      54.7303,
      32.09,
      -56.584,
      54.7303,
      29.27,
      -56.6609,
      52.5817,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_14-15_2",
    polygon: [
      -59.057,
      25.0886,
      26.4,
      -59.057,
      25.0886,
      28.66,
      -58.9807,
      27.2373,
      28.66,
      -58.9807,
      27.2373,
      28.66,
      -58.9807,
      27.2373,
      26.4,
      -59.057,
      25.0886,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_An_2n-3_1",
    polygon: [
      -55.6921,
      91.3457,
      29.85,
      -55.6921,
      91.3457,
      31.92,
      -55.546,
      93.3354,
      31.92,
      -55.546,
      93.3354,
      31.92,
      -55.546,
      93.3354,
      29.85,
      -55.6921,
      91.3457,
      29.85,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_5-6_1",
    polygon: [
      -56.7243,
      77.1922,
      26.4,
      -56.7243,
      77.1922,
      28.47,
      -56.5782,
      79.1819,
      28.47,
      -56.5782,
      79.1819,
      28.47,
      -56.5782,
      79.1819,
      26.4,
      -56.7243,
      77.1922,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "7.OG_Jn-Kn_1_1",
    polygon: [
      -10.864,
      102.8455,
      29.27,
      -10.864,
      102.8455,
      32.09,
      -8.9082,
      103.0346,
      32.09,
      -8.9082,
      103.0346,
      32.09,
      -8.9082,
      103.0346,
      29.27,
      -10.864,
      102.8455,
      29.27,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_8-9_1",
    polygon: [
      -57.7769,
      60.8201,
      26.4,
      -57.7769,
      60.8201,
      28.66,
      -57.7,
      62.9687,
      28.66,
      -57.7,
      62.9687,
      28.66,
      -57.7,
      62.9687,
      26.4,
      -57.7769,
      60.8201,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_14-15_1",
    polygon: [
      -58.9594,
      27.8369,
      22.95,
      -58.9594,
      27.8369,
      25.21,
      -58.883,
      29.9855,
      25.21,
      -58.883,
      29.9855,
      25.21,
      -58.883,
      29.9855,
      22.95,
      -58.9594,
      27.8369,
      22.95,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "6.OG_An_13-14_3",
    polygon: [
      -58.7648,
      33.3134,
      26.4,
      -58.7648,
      33.3134,
      28.66,
      -58.6815,
      35.6572,
      28.66,
      -58.6815,
      35.6572,
      28.66,
      -58.6815,
      35.6572,
      26.4,
      -58.7648,
      33.3134,
      26.4,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Cn-Dn_4n-4_1",
    polygon: [
      -43.0709,
      84.8035,
      29.27,
      -43.0709,
      84.8035,
      32.15,
      -41.2572,
      86.6102,
      32.15,
      -41.2572,
      86.6102,
      32.15,
      -41.2572,
      86.6102,
      29.27,
      -43.0709,
      84.8035,
      29.27,
    ],
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
  },
  {
    ID: "7.OG_Bn-Cn_6-7_2",
    polygon: [
      -45.4746,
      69.3339,
      29.27,
      -45.4746,
      69.3339,
      32.09,
      -45.4678,
      71.2888,
      32.09,
      -45.4678,
      71.2888,
      32.09,
      -45.4678,
      71.2888,
      29.27,
      -45.4746,
      69.3339,
      29.27,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "7.OG_L-M_1_3",
    polygon: [
      2.907,
      104.1773,
      29.85,
      2.907,
      104.1773,
      32.11,
      4.4598,
      104.3274,
      32.11,
      4.4598,
      104.3274,
      32.11,
      4.4598,
      104.3274,
      29.85,
      2.907,
      104.1773,
      29.85,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Jn-Kn_4-5_2",
    polygon: [
      -5.6636,
      83.9153,
      22.95,
      -5.6636,
      83.9153,
      25.21,
      -5.6568,
      85.8803,
      25.21,
      -5.6568,
      85.8803,
      25.21,
      -5.6568,
      85.8803,
      22.95,
      -5.6636,
      83.9153,
      22.95,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_1-2n_1",
    polygon: [
      -46.5234,
      99.5672,
      29.85,
      -46.5234,
      99.5672,
      31.92,
      -44.5641,
      99.853,
      31.92,
      -44.5641,
      99.853,
      31.92,
      -44.5641,
      99.853,
      29.85,
      -46.5234,
      99.5672,
      29.85,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_M-N_2n-3_1",
    polygon: [
      7.53,
      90.6818,
      26.4,
      7.53,
      90.6818,
      28.66,
      5.5801,
      90.6886,
      28.66,
      5.5801,
      90.6886,
      28.66,
      5.5801,
      90.6886,
      26.4,
      7.53,
      90.6818,
      26.4,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "6.OG_Bn-Cn_11-12_1",
    polygon: [
      -44.362,
      42.3173,
      26.4,
      -44.362,
      42.3173,
      28.66,
      -44.3586,
      43.2923,
      28.66,
      -44.3586,
      43.2923,
      28.66,
      -44.3586,
      43.2923,
      26.4,
      -44.362,
      42.3173,
      26.4,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_N-On_1_1",
    polygon: [
      12.124,
      105.0686,
      26.4,
      12.124,
      105.0686,
      28.66,
      14.0799,
      105.2577,
      28.66,
      14.0799,
      105.2577,
      28.66,
      14.0799,
      105.2577,
      26.4,
      12.124,
      105.0686,
      26.4,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_12-13_1",
    polygon: [
      -57.1542,
      38.8155,
      22.95,
      -57.1542,
      38.8155,
      25.21,
      -57.0773,
      40.9642,
      25.21,
      -57.0773,
      40.9642,
      25.21,
      -57.0773,
      40.9642,
      22.95,
      -57.1542,
      38.8155,
      22.95,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "6.OG_N-On_2n-3_1",
    polygon: [
      13.03,
      90.6627,
      26.4,
      13.03,
      90.6627,
      28.66,
      11.08,
      90.6695,
      28.66,
      11.08,
      90.6695,
      28.66,
      11.08,
      90.6695,
      26.4,
      13.03,
      90.6627,
      26.4,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5.OG_Dn-En_3-4n_1",
    polygon: [
      -36.6947,
      87.7559,
      22.95,
      -36.6947,
      87.7559,
      25.02,
      -37.4447,
      87.7585,
      25.02,
      -37.4447,
      87.7585,
      25.02,
      -37.4447,
      87.7585,
      22.95,
      -36.6947,
      87.7559,
      22.95,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "6.OG_Bn-Cn_14-15_1",
    polygon: [
      -44.4126,
      27.7824,
      25.82,
      -44.4126,
      27.7824,
      28.64,
      -44.4051,
      29.9324,
      28.64,
      -44.4051,
      29.9324,
      28.64,
      -44.4051,
      29.9324,
      25.82,
      -44.4126,
      27.7824,
      25.82,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_Bn-Cn_5-6_1",
    polygon: [
      -44.2487,
      74.8273,
      25.82,
      -44.2487,
      74.8273,
      28.64,
      -44.2419,
      76.7773,
      28.64,
      -44.2419,
      76.7773,
      28.64,
      -44.2419,
      76.7773,
      25.82,
      -44.2487,
      74.8273,
      25.82,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5.OG_Bn-Cn_14-15_2",
    polygon: [
      -43.2194,
      27.7732,
      22.95,
      -43.2194,
      27.7732,
      25.21,
      -43.2119,
      29.9232,
      25.21,
      -43.2119,
      29.9232,
      25.21,
      -43.2119,
      29.9232,
      22.95,
      -43.2194,
      27.7732,
      22.95,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_Fn-Gn_3-4n_2",
    polygon: [
      -28.5055,
      87.7274,
      26.4,
      -28.5055,
      87.7274,
      28.47,
      -30.4555,
      87.7342,
      28.47,
      -30.4555,
      87.7342,
      28.47,
      -30.4555,
      87.7342,
      26.4,
      -28.5055,
      87.7274,
      26.4,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_9-10_2",
    polygon: [
      -45.5236,
      55.2864,
      29.27,
      -45.5236,
      55.2864,
      32.09,
      -45.5161,
      57.4364,
      32.09,
      -45.5161,
      57.4364,
      32.09,
      -45.5161,
      57.4364,
      29.27,
      -45.5236,
      55.2864,
      29.27,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "7.OG_An_1-2n_1",
    polygon: [
      -54.3273,
      96.6633,
      29.85,
      -54.3273,
      96.6633,
      31.92,
      -52.7344,
      98.1295,
      31.92,
      -52.7344,
      98.1295,
      31.92,
      -52.7344,
      98.1295,
      29.85,
      -54.3273,
      96.6633,
      29.85,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_L-M_2n-3_1",
    polygon: [
      6.1532,
      91.5817,
      29.27,
      6.1532,
      91.5817,
      32.09,
      4.2032,
      91.5884,
      32.09,
      4.2032,
      91.5884,
      32.09,
      4.2032,
      91.5884,
      29.27,
      6.1532,
      91.5817,
      29.27,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "6.OG_Bn-Cn_15-16_1",
    polygon: [
      -44.4313,
      22.4124,
      25.82,
      -44.4313,
      22.4124,
      28.64,
      -44.4243,
      24.4324,
      28.64,
      -44.4243,
      24.4324,
      28.64,
      -44.4243,
      24.4324,
      25.82,
      -44.4313,
      22.4124,
      25.82,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_An_9-10_3",
    polygon: [
      -57.0473,
      52.4016,
      26.4,
      -57.0473,
      52.4016,
      28.66,
      -58.2073,
      52.4056,
      28.66,
      -58.2073,
      52.4056,
      28.66,
      -58.2073,
      52.4056,
      26.4,
      -57.0473,
      52.4016,
      26.4,
    ],
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "7.OG_An_11-12_1",
    polygon: [
      -57.0551,
      41.5637,
      29.85,
      -57.0551,
      41.5637,
      32.11,
      -56.9782,
      43.7123,
      32.11,
      -56.9782,
      43.7123,
      32.11,
      -56.9782,
      43.7123,
      29.85,
      -57.0551,
      41.5637,
      29.85,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Kn-L_1_2",
    polygon: [
      -1.2386,
      103.7763,
      26.4,
      -1.2386,
      103.7763,
      28.66,
      0.7172,
      103.9655,
      28.66,
      0.7172,
      103.9655,
      28.66,
      0.7172,
      103.9655,
      26.4,
      -1.2386,
      103.7763,
      26.4,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Cn-Dn_1-2n_1",
    polygon: [
      -43.7725,
      99.9685,
      29.85,
      -43.7725,
      99.9685,
      31.92,
      -41.8132,
      100.2543,
      31.92,
      -41.8132,
      100.2543,
      31.92,
      -41.8132,
      100.2543,
      29.85,
      -43.7725,
      99.9685,
      29.85,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_N-On_2n-3_1",
    polygon: [
      15.4281,
      91.5493,
      29.27,
      15.4281,
      91.5493,
      32.09,
      13.8431,
      91.5549,
      32.09,
      13.8431,
      91.5549,
      32.09,
      13.8431,
      91.5549,
      29.27,
      15.4281,
      91.5493,
      29.27,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "5.OG_M-N_2n-3_1",
    polygon: [
      8.91,
      90.677,
      22.95,
      8.91,
      90.677,
      25.21,
      6.96,
      90.6838,
      25.21,
      6.96,
      90.6838,
      25.21,
      6.96,
      90.6838,
      22.95,
      8.91,
      90.677,
      22.95,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "6.OG_M-N_2n-3_2",
    polygon: [
      10.28,
      90.6723,
      26.4,
      10.28,
      90.6723,
      28.66,
      8.33,
      90.6791,
      28.66,
      8.33,
      90.6791,
      28.66,
      8.33,
      90.6791,
      26.4,
      10.28,
      90.6723,
      26.4,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "7.OG_An_13-14_2",
    polygon: [
      -57.3495,
      33.334,
      29.29,
      -57.3495,
      33.334,
      32.11,
      -57.2726,
      35.4826,
      32.11,
      -57.2726,
      35.4826,
      32.11,
      -57.2726,
      35.4826,
      29.29,
      -57.3495,
      33.334,
      29.29,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_15-16_1",
    polygon: [
      -45.6477,
      19.7917,
      29.27,
      -45.6477,
      19.7917,
      32.09,
      -45.6407,
      21.8117,
      32.09,
      -45.6407,
      21.8117,
      32.09,
      -45.6407,
      21.8117,
      29.27,
      -45.6477,
      19.7917,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_N-On_1_2",
    polygon: [
      14.8762,
      105.3348,
      26.4,
      14.8762,
      105.3348,
      28.66,
      16.8321,
      105.5239,
      28.66,
      16.8321,
      105.5239,
      28.66,
      16.8321,
      105.5239,
      26.4,
      14.8762,
      105.3348,
      26.4,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_M-N_1_2",
    polygon: [
      9.3719,
      104.8024,
      26.4,
      9.3719,
      104.8024,
      28.66,
      11.3277,
      104.9916,
      28.66,
      11.3277,
      104.9916,
      28.66,
      11.3277,
      104.9916,
      26.4,
      9.3719,
      104.8024,
      26.4,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An-Bn_1-2n_1",
    polygon: [
      -49.6789,
      99.1069,
      33.49,
      -49.6789,
      99.1069,
      35.56,
      -47.7196,
      99.3927,
      35.56,
      -47.7196,
      99.3927,
      35.56,
      -47.7196,
      99.3927,
      33.49,
      -49.6789,
      99.1069,
      33.49,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Gn-H_3-4n_1",
    polygon: [
      -23.0097,
      86.5082,
      22.95,
      -23.0097,
      86.5082,
      25.02,
      -24.9597,
      86.515,
      25.02,
      -24.9597,
      86.515,
      25.02,
      -24.9597,
      86.515,
      22.95,
      -23.0097,
      86.5082,
      22.95,
    ],
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "7.OG_O-P_1-2n_1",
    polygon: [
      19.9933,
      95.8835,
      29.27,
      19.9933,
      95.8835,
      32.09,
      20.0001,
      97.8485,
      32.09,
      20.0001,
      97.8485,
      32.09,
      20.0001,
      97.8485,
      29.27,
      19.9933,
      95.8835,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "6.OG_En-Fn_3-4n_1",
    polygon: [
      -31.2454,
      87.7369,
      26.4,
      -31.2454,
      87.7369,
      28.47,
      -33.1954,
      87.7437,
      28.47,
      -33.1954,
      87.7437,
      28.47,
      -33.1954,
      87.7437,
      26.4,
      -31.2454,
      87.7369,
      26.4,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "7.OG_An-Bn_1-2n_2",
    polygon: [
      -52.1358,
      98.4635,
      29.85,
      -52.1358,
      98.4635,
      31.92,
      -50.0514,
      99.0488,
      31.92,
      -50.0514,
      99.0488,
      31.92,
      -50.0514,
      99.0488,
      29.85,
      -52.1358,
      98.4635,
      29.85,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_9-10_2",
    polygon: [
      -43.131,
      52.5281,
      22.37,
      -43.131,
      52.5281,
      25.19,
      -43.1235,
      54.6781,
      25.19,
      -43.1235,
      54.6781,
      25.19,
      -43.1235,
      54.6781,
      22.37,
      -43.131,
      52.5281,
      22.37,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "7.OG_Jn-Kn_5-6_2",
    polygon: [
      -7.4327,
      78.4389,
      29.27,
      -7.4327,
      78.4389,
      32.09,
      -7.4259,
      80.3889,
      32.09,
      -7.4259,
      80.3889,
      32.09,
      -7.4259,
      80.3889,
      29.27,
      -7.4327,
      78.4389,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "7.OG_M-N_2n-3_1",
    polygon: [
      8.9032,
      91.5721,
      29.27,
      8.9032,
      91.5721,
      32.09,
      6.9532,
      91.5789,
      32.09,
      6.9532,
      91.5789,
      32.09,
      6.9532,
      91.5789,
      29.27,
      8.9032,
      91.5721,
      29.27,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "7.OG_N-On_1_2",
    polygon: [
      14.6332,
      104.4936,
      29.85,
      14.6332,
      104.4936,
      32.11,
      14.637,
      105.5686,
      32.11,
      14.637,
      105.5686,
      32.11,
      14.637,
      105.5686,
      29.85,
      14.6332,
      104.4936,
      29.85,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "7.OG_An_10-11_1",
    polygon: [
      -56.8577,
      47.0802,
      29.85,
      -56.8577,
      47.0802,
      32.11,
      -56.7808,
      49.2288,
      32.11,
      -56.7808,
      49.2288,
      32.11,
      -56.7808,
      49.2288,
      29.85,
      -56.8577,
      47.0802,
      29.85,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_L-M_1_1",
    polygon: [
      3.8675,
      104.2701,
      26.4,
      3.8675,
      104.2701,
      28.66,
      5.8234,
      104.4593,
      28.66,
      5.8234,
      104.4593,
      28.66,
      5.8234,
      104.4593,
      26.4,
      3.8675,
      104.2701,
      26.4,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Kn-L_1_1",
    polygon: [
      -2.5973,
      103.6449,
      29.85,
      -2.5973,
      103.6449,
      32.11,
      -0.6414,
      103.8341,
      32.11,
      -0.6414,
      103.8341,
      32.11,
      -0.6414,
      103.8341,
      29.85,
      -2.5973,
      103.6449,
      29.85,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Dn-En_1-2n_1",
    polygon: [
      -41.0216,
      100.3697,
      29.85,
      -41.0216,
      100.3697,
      31.92,
      -39.0623,
      100.6555,
      31.92,
      -39.0623,
      100.6555,
      31.92,
      -39.0623,
      100.6555,
      29.85,
      -41.0216,
      100.3697,
      29.85,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Fn-Gn_3-4n_1",
    polygon: [
      -28.5056,
      88.9274,
      29.27,
      -28.5056,
      88.9274,
      32.09,
      -30.4606,
      88.9342,
      32.09,
      -30.4606,
      88.9342,
      32.09,
      -30.4606,
      88.9342,
      29.27,
      -28.5056,
      88.9274,
      29.27,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "7.OG_Bn-Cn_7-8_1",
    polygon: [
      -45.4842,
      66.5939,
      29.27,
      -45.4842,
      66.5939,
      32.09,
      -45.4774,
      68.5489,
      32.09,
      -45.4774,
      68.5489,
      32.09,
      -45.4774,
      68.5489,
      29.27,
      -45.4842,
      66.5939,
      29.27,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "7.OG_An_6-7_2",
    polygon: [
      -55.8026,
      69.3728,
      29.29,
      -55.8026,
      69.3728,
      32.11,
      -55.6602,
      71.3226,
      32.11,
      -55.6602,
      71.3226,
      32.11,
      -55.6602,
      71.3226,
      29.29,
      -55.8026,
      69.3728,
      29.29,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "7.OG_N-On_1_1",
    polygon: [
      12.1141,
      105.0676,
      29.85,
      12.1141,
      105.0676,
      32.11,
      14.7916,
      105.3266,
      32.11,
      14.7916,
      105.3266,
      32.11,
      14.7916,
      105.3266,
      29.85,
      12.1141,
      105.0676,
      29.85,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_L-M_1_2",
    polygon: [
      1.5135,
      104.0425,
      26.4,
      1.5135,
      104.0425,
      28.66,
      3.4694,
      104.2316,
      28.66,
      3.4694,
      104.2316,
      28.66,
      3.4694,
      104.2316,
      26.4,
      1.5135,
      104.0425,
      26.4,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "4.OG_An_14-15_1",
    polygon: [
      -58.9594,
      27.8369,
      19.5,
      -58.9594,
      27.8369,
      21.76,
      -58.883,
      29.9855,
      21.76,
      -58.883,
      29.9855,
      21.76,
      -58.883,
      29.9855,
      19.5,
      -58.9594,
      27.8369,
      19.5,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
  },
  {
    ID: "6.OG_O-P_1_1",
    polygon: [
      17.6084,
      105.599,
      26.4,
      17.6084,
      105.599,
      28.66,
      19.5643,
      105.7881,
      28.66,
      19.5643,
      105.7881,
      28.66,
      19.5643,
      105.7881,
      26.4,
      17.6084,
      105.599,
      26.4,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_M-N_2n-3_2",
    polygon: [
      11.64,
      90.6675,
      22.95,
      11.64,
      90.6675,
      25.21,
      9.69,
      90.6743,
      25.21,
      9.69,
      90.6743,
      25.21,
      9.69,
      90.6743,
      22.95,
      11.64,
      90.6675,
      22.95,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "5.OG_An_13-14_3",
    polygon: [
      -58.7648,
      33.3134,
      22.95,
      -58.7648,
      33.3134,
      25.21,
      -58.6815,
      35.6572,
      25.21,
      -58.6815,
      35.6572,
      25.21,
      -58.6815,
      35.6572,
      22.95,
      -58.7648,
      33.3134,
      22.95,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "6.OG_Bn-Cn_11-12_2",
    polygon: [
      -44.3579,
      43.4923,
      26.4,
      -44.3579,
      43.4923,
      28.66,
      -44.3545,
      44.4673,
      28.66,
      -44.3545,
      44.4673,
      28.66,
      -44.3545,
      44.4673,
      26.4,
      -44.3579,
      43.4923,
      26.4,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_L-M_2n-3_1",
    polygon: [
      4.7801,
      90.6914,
      26.4,
      4.7801,
      90.6914,
      28.66,
      2.8301,
      90.6982,
      28.66,
      2.8301,
      90.6982,
      28.66,
      2.8301,
      90.6982,
      26.4,
      4.7801,
      90.6914,
      26.4,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "8.OG_Gn-H_3-4n_1",
    polygon: [
      -23.0071,
      90.1082,
      33.49,
      -23.0071,
      90.1082,
      35.56,
      -24.9571,
      90.115,
      35.56,
      -24.9571,
      90.115,
      35.56,
      -24.9571,
      90.115,
      33.49,
      -23.0071,
      90.1082,
      33.49,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "7.OG_An_2n-3_2",
    polygon: [
      -55.4666,
      94.0576,
      29.85,
      -55.4666,
      94.0576,
      31.92,
      -54.7137,
      96.0874,
      31.92,
      -54.7137,
      96.0874,
      31.92,
      -54.7137,
      96.0874,
      29.85,
      -55.4666,
      94.0576,
      29.85,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_2n-3_1",
    polygon: [
      -55.7214,
      90.9468,
      33.49,
      -55.7214,
      90.9468,
      35.56,
      -55.5753,
      92.9365,
      35.56,
      -55.5753,
      92.9365,
      35.56,
      -55.5753,
      92.9365,
      33.49,
      -55.7214,
      90.9468,
      33.49,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Bn-Cn_6-7_1",
    polygon: [
      -44.2583,
      72.0873,
      25.82,
      -44.2583,
      72.0873,
      28.64,
      -44.2515,
      74.0373,
      28.64,
      -44.2515,
      74.0373,
      28.64,
      -44.2515,
      74.0373,
      25.82,
      -44.2583,
      72.0873,
      25.82,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "6.OG_Bn-Cn_14-15_2",
    polygon: [
      -44.4222,
      25.0324,
      25.82,
      -44.4222,
      25.0324,
      28.64,
      -44.4147,
      27.1824,
      28.64,
      -44.4147,
      27.1824,
      28.64,
      -44.4147,
      27.1824,
      25.82,
      -44.4222,
      25.0324,
      25.82,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_An_15-16_1",
    polygon: [
      -59.149,
      22.5003,
      26.4,
      -59.149,
      22.5003,
      28.66,
      -59.0783,
      24.489,
      28.66,
      -59.0783,
      24.489,
      28.66,
      -59.0783,
      24.489,
      26.4,
      -59.149,
      22.5003,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_8-9_1",
    polygon: [
      -45.514,
      58.0364,
      29.27,
      -45.514,
      58.0364,
      32.09,
      -45.5065,
      60.1864,
      32.09,
      -45.5065,
      60.1864,
      32.09,
      -45.5065,
      60.1864,
      29.27,
      -45.514,
      58.0364,
      29.27,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "5.OG_An_14-15_2",
    polygon: [
      -59.057,
      25.0886,
      22.95,
      -59.057,
      25.0886,
      25.21,
      -58.9807,
      27.2373,
      25.21,
      -58.9807,
      27.2373,
      25.21,
      -58.9807,
      27.2373,
      22.95,
      -59.057,
      25.0886,
      22.95,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "6.OG_An_8-9_2",
    polygon: [
      -57.8752,
      58.0718,
      26.4,
      -57.8752,
      58.0718,
      28.66,
      -57.7983,
      60.2205,
      28.66,
      -57.7983,
      60.2205,
      28.66,
      -57.7983,
      60.2205,
      26.4,
      -57.8752,
      58.0718,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_L-M_2n-3_2",
    polygon: [
      3.5632,
      91.5907,
      29.27,
      3.5632,
      91.5907,
      32.09,
      2.8382,
      91.5932,
      32.09,
      2.8382,
      91.5932,
      32.09,
      2.8382,
      91.5932,
      29.27,
      3.5632,
      91.5907,
      29.27,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "7.OG_An_10-11_2",
    polygon: [
      -56.7594,
      49.8284,
      29.85,
      -56.7594,
      49.8284,
      32.11,
      -56.6825,
      51.9771,
      32.11,
      -56.6825,
      51.9771,
      32.11,
      -56.6825,
      51.9771,
      29.85,
      -56.7594,
      49.8284,
      29.85,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Bn-Cn_15-16_2",
    polygon: [
      -44.4404,
      19.7924,
      25.82,
      -44.4404,
      19.7924,
      28.64,
      -44.4334,
      21.8124,
      28.64,
      -44.4334,
      21.8124,
      28.64,
      -44.4334,
      21.8124,
      25.82,
      -44.4404,
      19.7924,
      25.82,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "7.OG_Gn-H_1-2n_1",
    polygon: [
      -24.5324,
      101.26,
      29.27,
      -24.5324,
      101.26,
      32.09,
      -22.5731,
      101.5453,
      32.09,
      -22.5731,
      101.5453,
      32.09,
      -22.5731,
      101.5453,
      29.27,
      -24.5324,
      101.26,
      29.27,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_M-N_1_1",
    polygon: [
      6.6197,
      104.5363,
      26.4,
      6.6197,
      104.5363,
      28.66,
      8.5756,
      104.7254,
      28.66,
      8.5756,
      104.7254,
      28.66,
      8.5756,
      104.7254,
      26.4,
      6.6197,
      104.5363,
      26.4,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Gn-H_1-2n_1",
    polygon: [
      -25.0019,
      99.6748,
      32.72,
      -25.0019,
      99.6748,
      35.54,
      -22.8843,
      99.9837,
      35.54,
      -22.8843,
      99.9837,
      35.54,
      -22.8843,
      99.9837,
      32.72,
      -25.0019,
      99.6748,
      32.72,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_L-M_1_1",
    polygon: [
      0.1549,
      103.9111,
      29.85,
      0.1549,
      103.9111,
      32.11,
      2.1107,
      104.1002,
      32.11,
      2.1107,
      104.1002,
      32.11,
      2.1107,
      104.1002,
      29.85,
      0.1549,
      103.9111,
      29.85,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Gn-H_3-4n_1",
    polygon: [
      -23.0213,
      88.9083,
      30.04,
      -23.0213,
      88.9083,
      32.11,
      -24.9713,
      88.9151,
      32.11,
      -24.9713,
      88.9151,
      32.11,
      -24.9713,
      88.9151,
      30.04,
      -23.0213,
      88.9083,
      30.04,
    ],
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "8.OG_An_8-9_1",
    polygon: [
      -54.8653,
      60.8195,
      32.72,
      -54.8653,
      60.8195,
      35.54,
      -54.7884,
      62.9681,
      35.54,
      -54.7884,
      62.9681,
      35.54,
      -54.7884,
      62.9681,
      32.72,
      -54.8653,
      60.8195,
      32.72,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_15-16_2",
    polygon: [
      -45.6386,
      22.4117,
      29.27,
      -45.6386,
      22.4117,
      32.09,
      -45.6315,
      24.4316,
      32.09,
      -45.6315,
      24.4316,
      32.09,
      -45.6315,
      24.4316,
      29.27,
      -45.6386,
      22.4117,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_O-P_1_2",
    polygon: [
      20.3805,
      105.8671,
      26.4,
      20.3805,
      105.8671,
      28.66,
      22.3364,
      106.0562,
      28.66,
      22.3364,
      106.0562,
      28.66,
      22.3364,
      106.0562,
      26.4,
      20.3805,
      105.8671,
      26.4,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_12-13_2",
    polygon: [
      -57.2417,
      36.0669,
      33.3,
      -57.2417,
      36.0669,
      35.56,
      -57.1648,
      38.2155,
      35.56,
      -57.1648,
      38.2155,
      35.56,
      -57.1648,
      38.2155,
      33.3,
      -57.2417,
      36.0669,
      33.3,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_H-In_4-5_1",
    polygon: [
      -19.5335,
      83.9761,
      29.27,
      -19.5335,
      83.9761,
      32.09,
      -19.5267,
      85.9261,
      32.09,
      -19.5267,
      85.9261,
      32.09,
      -19.5267,
      85.9261,
      29.27,
      -19.5335,
      83.9761,
      29.27,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "8.OG_Bn-Cn_6-7_2",
    polygon: [
      -46.6746,
      69.3438,
      32.72,
      -46.6746,
      69.3438,
      35.54,
      -46.6678,
      71.2938,
      35.54,
      -46.6678,
      71.2938,
      35.54,
      -46.6678,
      71.2938,
      32.72,
      -46.6746,
      69.3438,
      32.72,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "8.OG_Fn-Gn_3-4n_1",
    polygon: [
      -25.7471,
      90.1178,
      33.49,
      -25.7471,
      90.1178,
      35.56,
      -27.6971,
      90.1246,
      35.56,
      -27.6971,
      90.1246,
      35.56,
      -27.6971,
      90.1246,
      33.49,
      -25.7471,
      90.1178,
      33.49,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "7.OG_O-P_1-2n_2",
    polygon: [
      20.0037,
      98.8885,
      29.27,
      20.0037,
      98.8885,
      32.09,
      20.0106,
      100.8534,
      32.09,
      20.0106,
      100.8534,
      32.09,
      20.0106,
      100.8534,
      29.27,
      20.0037,
      98.8885,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "5.OG_On-O_4-5_2",
    polygon: [
      16.0163,
      83.8339,
      22.95,
      16.0163,
      83.8339,
      25.21,
      16.0231,
      85.7839,
      25.21,
      16.0231,
      85.7839,
      25.21,
      16.0231,
      85.7839,
      22.95,
      16.0163,
      83.8339,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "7.OG_An_12-13_1",
    polygon: [
      -57.1534,
      38.8155,
      29.85,
      -57.1534,
      38.8155,
      32.11,
      -57.0765,
      40.9641,
      32.11,
      -57.0765,
      40.9641,
      32.11,
      -57.0765,
      40.9641,
      29.85,
      -57.1534,
      38.8155,
      29.85,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Bn-Cn_13-14_2",
    polygon: [
      -43.2002,
      33.2732,
      22.37,
      -43.2002,
      33.2732,
      25.19,
      -43.1927,
      35.4232,
      25.19,
      -43.1927,
      35.4232,
      25.19,
      -43.1927,
      35.4232,
      22.37,
      -43.2002,
      33.2732,
      22.37,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "7.OG_Jn-Kn_4-5_1",
    polygon: [
      -7.4231,
      81.1889,
      29.27,
      -7.4231,
      81.1889,
      32.09,
      -7.4163,
      83.1389,
      32.09,
      -7.4163,
      83.1389,
      32.09,
      -7.4163,
      83.1389,
      29.27,
      -7.4231,
      81.1889,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "7.OG_An_13-14_1",
    polygon: [
      -57.4478,
      30.5857,
      29.27,
      -57.4478,
      30.5857,
      32.09,
      -57.371,
      32.7344,
      32.09,
      -57.371,
      32.7344,
      32.09,
      -57.371,
      32.7344,
      29.27,
      -57.4478,
      30.5857,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Fn-Gn_1-2n_1",
    polygon: [
      -27.3032,
      100.8565,
      29.27,
      -27.3032,
      100.8565,
      32.09,
      -25.3439,
      101.1418,
      32.09,
      -25.3439,
      101.1418,
      32.09,
      -25.3439,
      101.1418,
      29.27,
      -27.3032,
      100.8565,
      29.27,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Kn-L_2n-3_1",
    polygon: [
      -3.1518,
      91.6141,
      29.85,
      -3.1518,
      91.6141,
      32.11,
      -5.2868,
      91.6215,
      32.11,
      -5.2868,
      91.6215,
      32.11,
      -5.2868,
      91.6215,
      29.85,
      -3.1518,
      91.6141,
      29.85,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
  },
  {
    ID: "8.OG_Fn-Gn_1-2n_1",
    polygon: [
      -27.7527,
      99.2736,
      32.72,
      -27.7527,
      99.2736,
      35.54,
      -25.6352,
      99.5825,
      35.54,
      -25.6352,
      99.5825,
      35.54,
      -25.6352,
      99.5825,
      32.72,
      -27.7527,
      99.2736,
      32.72,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_13-14_3",
    polygon: [
      -56.4236,
      33.0243,
      33.3,
      -56.4236,
      33.0243,
      35.56,
      -57.3736,
      33.0276,
      35.56,
      -57.3736,
      33.0276,
      35.56,
      -57.3736,
      33.0276,
      33.3,
      -56.4236,
      33.0243,
      33.3,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "7.OG_L-M_1_2",
    polygon: [
      5.2411,
      104.403,
      29.85,
      5.2411,
      104.403,
      32.11,
      7.197,
      104.5921,
      32.11,
      7.197,
      104.5921,
      32.11,
      7.197,
      104.5921,
      29.85,
      5.2411,
      104.403,
      29.85,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_5-6_1",
    polygon: [
      -45.4555,
      74.8413,
      29.27,
      -45.4555,
      74.8413,
      32.09,
      -45.4528,
      75.5913,
      32.09,
      -45.4528,
      75.5913,
      32.09,
      -45.4528,
      75.5913,
      29.27,
      -45.4555,
      74.8413,
      29.27,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_Gn-H_4-5_1",
    polygon: [
      -21.2878,
      79.859,
      26.4,
      -21.2878,
      79.859,
      28.66,
      -21.281,
      81.809,
      28.66,
      -21.281,
      81.809,
      28.66,
      -21.281,
      81.809,
      26.4,
      -21.2878,
      79.859,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "7.OG_Fn-Gn_3-4n_2",
    polygon: [
      -25.7422,
      88.9177,
      30.04,
      -25.7422,
      88.9177,
      32.11,
      -27.7372,
      88.9247,
      32.11,
      -27.7372,
      88.9247,
      32.11,
      -27.7372,
      88.9247,
      30.04,
      -25.7422,
      88.9177,
      30.04,
    ],
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
  },
  {
    ID: "8.OG_An_8-9_2",
    polygon: [
      -54.9636,
      58.0713,
      32.72,
      -54.9636,
      58.0713,
      35.54,
      -54.8867,
      60.2199,
      35.54,
      -54.8867,
      60.2199,
      35.54,
      -54.8867,
      60.2199,
      32.72,
      -54.9636,
      58.0713,
      32.72,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_14-15_1",
    polygon: [
      -56.0452,
      27.8132,
      32.72,
      -56.0452,
      27.8132,
      35.54,
      -55.9686,
      29.9618,
      35.54,
      -55.9686,
      29.9618,
      35.54,
      -55.9686,
      29.9618,
      32.72,
      -56.0452,
      27.8132,
      32.72,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Jn-Kn_3-4n_1",
    polygon: [
      -5.6492,
      88.0477,
      26.4,
      -5.6492,
      88.0477,
      28.66,
      -5.6424,
      89.9977,
      28.66,
      -5.6424,
      89.9977,
      28.66,
      -5.6424,
      89.9977,
      26.4,
      -5.6492,
      88.0477,
      26.4,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "7.OG_H-In_4-5_2",
    polygon: [
      -19.543,
      81.2261,
      29.27,
      -19.543,
      81.2261,
      32.09,
      -19.5362,
      83.1761,
      32.09,
      -19.5362,
      83.1761,
      32.09,
      -19.5362,
      83.1761,
      29.27,
      -19.543,
      81.2261,
      29.27,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "7.OG_Bn-Cn_5-6_2",
    polygon: [
      -44.2429,
      78.4471,
      30.205,
      -44.2429,
      78.4471,
      32.275,
      -44.2403,
      79.1971,
      32.275,
      -44.2403,
      79.1971,
      32.275,
      -44.2403,
      79.1971,
      30.205,
      -44.2429,
      78.4471,
      30.205,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "6.OG_Bn-Cn_11-12_3",
    polygon: [
      -44.3538,
      44.6673,
      26.4,
      -44.3538,
      44.6673,
      28.66,
      -44.3504,
      45.6423,
      28.66,
      -44.3504,
      45.6423,
      28.66,
      -44.3504,
      45.6423,
      26.4,
      -44.3538,
      44.6673,
      26.4,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_Bn-Cn_6-7_2",
    polygon: [
      -44.2678,
      69.3424,
      25.82,
      -44.2678,
      69.3424,
      28.64,
      -44.261,
      71.2924,
      28.64,
      -44.261,
      71.2924,
      28.64,
      -44.261,
      71.2924,
      25.82,
      -44.2678,
      69.3424,
      25.82,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "8.OG_Fn-Gn_3-4n_2",
    polygon: [
      -28.5071,
      90.1274,
      33.49,
      -28.5071,
      90.1274,
      35.56,
      -30.4571,
      90.1342,
      35.56,
      -30.4571,
      90.1342,
      35.56,
      -30.4571,
      90.1342,
      33.49,
      -28.5071,
      90.1274,
      33.49,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "6.OG_Bn-Cn_13-14_2",
    polygon: [
      -44.3935,
      33.2624,
      25.82,
      -44.3935,
      33.2624,
      28.64,
      -44.386,
      35.4124,
      28.64,
      -44.386,
      35.4124,
      28.64,
      -44.386,
      35.4124,
      25.82,
      -44.3935,
      33.2624,
      25.82,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_Jn-Kn_4n-4_1",
    polygon: [
      -5.6588,
      85.2978,
      26.4,
      -5.6588,
      85.2978,
      28.66,
      -5.6519,
      87.2627,
      28.66,
      -5.6519,
      87.2627,
      28.66,
      -5.6519,
      87.2627,
      26.4,
      -5.6588,
      85.2978,
      26.4,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_5-6_2",
    polygon: [
      -44.2429,
      78.4471,
      33.655,
      -44.2429,
      78.4471,
      35.725,
      -44.2403,
      79.1971,
      35.725,
      -44.2403,
      79.1971,
      35.725,
      -44.2403,
      79.1971,
      33.655,
      -44.2429,
      78.4471,
      33.655,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "5.OG_On-O_3-4n_1",
    polygon: [
      16.0258,
      86.5839,
      22.95,
      16.0258,
      86.5839,
      25.21,
      16.0326,
      88.5339,
      25.21,
      16.0326,
      88.5339,
      25.21,
      16.0326,
      88.5339,
      22.95,
      16.0258,
      86.5839,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "7.OG_An_14-15_1",
    polygon: [
      -57.5469,
      27.8175,
      29.27,
      -57.5469,
      27.8175,
      32.09,
      -57.47,
      29.9661,
      32.09,
      -57.47,
      29.9661,
      32.09,
      -57.47,
      29.9661,
      29.27,
      -57.5469,
      27.8175,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_Gn-H_5-6_1",
    polygon: [
      -21.2926,
      78.479,
      22.95,
      -21.2926,
      78.479,
      25.21,
      -21.2858,
      80.429,
      25.21,
      -21.2858,
      80.429,
      25.21,
      -21.2858,
      80.429,
      22.95,
      -21.2926,
      78.479,
      22.95,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "8.OG_L-M_1_1",
    polygon: [
      1.5086,
      104.042,
      33.3,
      1.5086,
      104.042,
      35.56,
      3.4644,
      104.2312,
      35.56,
      3.4644,
      104.2312,
      35.56,
      3.4644,
      104.2312,
      33.3,
      1.5086,
      104.042,
      33.3,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_8-9_2",
    polygon: [
      -45.5044,
      60.7864,
      29.27,
      -45.5044,
      60.7864,
      32.09,
      -45.4969,
      62.9364,
      32.09,
      -45.4969,
      62.9364,
      32.09,
      -45.4969,
      62.9364,
      29.27,
      -45.5044,
      60.7864,
      29.27,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "7.OG_H-In_1_1",
    polygon: [
      -19.1205,
      102.047,
      29.27,
      -19.1205,
      102.047,
      32.09,
      -17.1646,
      102.2362,
      32.09,
      -17.1646,
      102.2362,
      32.09,
      -17.1646,
      102.2362,
      29.27,
      -19.1205,
      102.047,
      29.27,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_An_7-8_1",
    polygon: [
      -56.0037,
      66.6202,
      29.27,
      -56.0037,
      66.6202,
      32.09,
      -55.8612,
      68.57,
      32.09,
      -55.8612,
      68.57,
      32.09,
      -55.8612,
      68.57,
      29.27,
      -56.0037,
      66.6202,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_M-N_1_1",
    polygon: [
      7.9933,
      104.6691,
      29.85,
      7.9933,
      104.6691,
      32.11,
      9.9492,
      104.8583,
      32.11,
      9.9492,
      104.8583,
      32.11,
      9.9492,
      104.8583,
      29.85,
      7.9933,
      104.6691,
      29.85,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Gn-H_4-5_2",
    polygon: [
      -21.2782,
      82.609,
      26.4,
      -21.2782,
      82.609,
      28.66,
      -21.2715,
      84.559,
      28.66,
      -21.2715,
      84.559,
      28.66,
      -21.2715,
      84.559,
      26.4,
      -21.2782,
      82.609,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "8.OG_An_9-10_1",
    polygon: [
      -55.0602,
      55.373,
      32.72,
      -55.0602,
      55.373,
      35.54,
      -54.9851,
      57.4717,
      35.54,
      -54.9851,
      57.4717,
      35.54,
      -54.9851,
      57.4717,
      32.72,
      -55.0602,
      55.373,
      32.72,
    ],
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_14-15_1",
    polygon: [
      -45.6295,
      25.0316,
      29.27,
      -45.6295,
      25.0316,
      32.09,
      -45.622,
      27.1816,
      32.09,
      -45.622,
      27.1816,
      32.09,
      -45.622,
      27.1816,
      29.27,
      -45.6295,
      25.0316,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "8.OG_An_14-15_2",
    polygon: [
      -56.143,
      25.0649,
      32.72,
      -56.143,
      25.0649,
      35.54,
      -56.0665,
      27.2135,
      35.54,
      -56.0665,
      27.2135,
      35.54,
      -56.0665,
      27.2135,
      32.72,
      -56.143,
      25.0649,
      32.72,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_En-Fn_3-4n_1",
    polygon: [
      -31.2631,
      88.937,
      29.27,
      -31.2631,
      88.937,
      32.09,
      -32.0131,
      88.9396,
      32.09,
      -32.0131,
      88.9396,
      32.09,
      -32.0131,
      88.9396,
      29.27,
      -31.2631,
      88.937,
      29.27,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "8.OG_Bn-Cn_7-8_1",
    polygon: [
      -46.6842,
      66.5838,
      32.72,
      -46.6842,
      66.5838,
      35.54,
      -46.6774,
      68.5338,
      35.54,
      -46.6774,
      68.5338,
      35.54,
      -46.6774,
      68.5338,
      32.72,
      -46.6842,
      66.5838,
      32.72,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_9-10_2",
    polygon: [
      -46.7236,
      55.2905,
      32.72,
      -46.7236,
      55.2905,
      35.54,
      -46.7161,
      57.4405,
      35.54,
      -46.7161,
      57.4405,
      35.54,
      -46.7161,
      57.4405,
      32.72,
      -46.7236,
      55.2905,
      32.72,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_An_15-16_2",
    polygon: [
      -59.241,
      19.9119,
      26.4,
      -59.241,
      19.9119,
      28.66,
      -59.1703,
      21.9006,
      28.66,
      -59.1703,
      21.9006,
      28.66,
      -59.1703,
      21.9006,
      26.4,
      -59.241,
      19.9119,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_O-P_1_1",
    polygon: [
      20.0137,
      101.7534,
      29.27,
      20.0137,
      101.7534,
      32.09,
      20.0162,
      102.4784,
      32.09,
      20.0162,
      102.4784,
      32.09,
      20.0162,
      102.4784,
      29.27,
      20.0137,
      101.7534,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "QVB_6.OG_87",
    polygon: [
      -21.3021,
      75.7592,
      26.432,
      -21.3021,
      75.7592,
      28.628,
      -21.2998,
      76.4202,
      28.628,
      -21.2998,
      76.4202,
      28.628,
      -21.2998,
      76.4202,
      26.432,
      -21.3021,
      75.7592,
      26.432,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "7.OG_H-In_5-6_1",
    polygon: [
      -19.5526,
      78.4761,
      29.27,
      -19.5526,
      78.4761,
      32.09,
      -19.5458,
      80.4261,
      32.09,
      -19.5458,
      80.4261,
      32.09,
      -19.5458,
      80.4261,
      29.27,
      -19.5526,
      78.4761,
      29.27,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "5.OG_An_15-16_1",
    polygon: [
      -59.149,
      22.5003,
      22.95,
      -59.149,
      22.5003,
      25.21,
      -59.0783,
      24.489,
      25.21,
      -59.0783,
      24.489,
      25.21,
      -59.0783,
      24.489,
      22.95,
      -59.149,
      22.5003,
      22.95,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "8.OG_En-Fn_3-4n_1",
    polygon: [
      -31.2471,
      90.1369,
      32.72,
      -31.2471,
      90.1369,
      35.54,
      -31.9971,
      90.1395,
      35.54,
      -31.9971,
      90.1395,
      35.54,
      -31.9971,
      90.1395,
      32.72,
      -31.2471,
      90.1369,
      32.72,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "6.OG_An_9-10_1",
    polygon: [
      -57.9736,
      55.3236,
      26.4,
      -57.9736,
      55.3236,
      28.66,
      -57.8967,
      57.4722,
      28.66,
      -57.8967,
      57.4722,
      28.66,
      -57.8967,
      57.4722,
      26.4,
      -57.9736,
      55.3236,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Kn-L_2n-3_1",
    polygon: [
      -3.1518,
      91.6141,
      26.4,
      -3.1518,
      91.6141,
      28.66,
      -5.2868,
      91.6215,
      28.66,
      -5.2868,
      91.6215,
      28.66,
      -5.2868,
      91.6215,
      26.4,
      -3.1518,
      91.6141,
      26.4,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "7.OG_Bn-Cn_11-12_1",
    polygon: [
      -44.3687,
      42.3173,
      29.85,
      -44.3687,
      42.3173,
      32.11,
      -44.3653,
      43.2923,
      32.11,
      -44.3653,
      43.2923,
      32.11,
      -44.3653,
      43.2923,
      29.85,
      -44.3687,
      42.3173,
      29.85,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "8.OG_Dn-En_1-2n_1",
    polygon: [
      -41.4151,
      100.3123,
      33.49,
      -41.4151,
      100.3123,
      35.56,
      -39.4558,
      100.5981,
      35.56,
      -39.4558,
      100.5981,
      35.56,
      -39.4558,
      100.5981,
      33.49,
      -41.4151,
      100.3123,
      33.49,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_An_14-15_2",
    polygon: [
      -57.6445,
      25.0893,
      29.27,
      -57.6445,
      25.0893,
      32.09,
      -57.5676,
      27.2379,
      32.09,
      -57.5676,
      27.2379,
      32.09,
      -57.5676,
      27.2379,
      29.27,
      -57.6445,
      25.0893,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_H-In_4-5_1",
    polygon: [
      -19.5383,
      82.5861,
      33.3,
      -19.5383,
      82.5861,
      35.56,
      -19.5315,
      84.5361,
      35.56,
      -19.5315,
      84.5361,
      35.56,
      -19.5315,
      84.5361,
      33.3,
      -19.5383,
      82.5861,
      33.3,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "7.OG_In-Jn_1_1",
    polygon: [
      -16.3683,
      102.3132,
      29.27,
      -16.3683,
      102.3132,
      32.09,
      -14.4125,
      102.5023,
      32.09,
      -14.4125,
      102.5023,
      32.09,
      -14.4125,
      102.5023,
      29.27,
      -16.3683,
      102.3132,
      29.27,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_An_6-7_1",
    polygon: [
      -55.6015,
      72.1255,
      29.27,
      -55.6015,
      72.1255,
      32.09,
      -55.4591,
      74.0753,
      32.09,
      -55.4591,
      74.0753,
      32.09,
      -55.4591,
      74.0753,
      29.27,
      -55.6015,
      72.1255,
      29.27,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
  },
  {
    ID: "8.OG_M-N_1_1",
    polygon: [
      6.6297,
      104.5373,
      33.3,
      6.6297,
      104.5373,
      35.56,
      8.5855,
      104.7264,
      35.56,
      8.5855,
      104.7264,
      35.56,
      8.5855,
      104.7264,
      33.3,
      6.6297,
      104.5373,
      33.3,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_15-16_1",
    polygon: [
      -46.8472,
      19.7958,
      32.72,
      -46.8472,
      19.7958,
      35.54,
      -46.8402,
      21.8158,
      35.54,
      -46.8402,
      21.8158,
      35.54,
      -46.8402,
      21.8158,
      32.72,
      -46.8472,
      19.7958,
      32.72,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "7.OG_Jn-Kn_4-5_2",
    polygon: [
      -7.4135,
      83.9389,
      29.27,
      -7.4135,
      83.9389,
      32.09,
      -7.4067,
      85.8889,
      32.09,
      -7.4067,
      85.8889,
      32.09,
      -7.4067,
      85.8889,
      29.27,
      -7.4135,
      83.9389,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "7.OG_M-N_2n-3_2",
    polygon: [
      11.6531,
      91.5625,
      29.27,
      11.6531,
      91.5625,
      32.09,
      9.7032,
      91.5693,
      32.09,
      9.7032,
      91.5693,
      32.09,
      9.7032,
      91.5693,
      29.27,
      11.6531,
      91.5625,
      29.27,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "8.OG_An_10-11_1",
    polygon: [
      -56.7499,
      49.8081,
      33.3,
      -56.7499,
      49.8081,
      35.56,
      -56.673,
      51.9568,
      35.56,
      -56.673,
      51.9568,
      35.56,
      -56.673,
      51.9568,
      33.3,
      -56.7499,
      49.8081,
      33.3,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_15-16_1",
    polygon: [
      -56.2345,
      22.4965,
      32.72,
      -56.2345,
      22.4965,
      35.54,
      -56.1637,
      24.4853,
      35.54,
      -56.1637,
      24.4853,
      35.54,
      -56.1637,
      24.4853,
      32.72,
      -56.2345,
      22.4965,
      32.72,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Dn-En_3-4n_1",
    polygon: [
      -36.6947,
      87.7559,
      29.85,
      -36.6947,
      87.7559,
      31.92,
      -37.4447,
      87.7585,
      31.92,
      -37.4447,
      87.7585,
      31.92,
      -37.4447,
      87.7585,
      29.85,
      -36.6947,
      87.7559,
      29.85,
    ],
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
  },
  {
    ID: "8.OG_An_7-8_1",
    polygon: [
      -54.5303,
      66.2239,
      32.72,
      -54.5303,
      66.2239,
      35.54,
      -54.3872,
      68.1737,
      35.54,
      -54.3872,
      68.1737,
      35.54,
      -54.3872,
      68.1737,
      32.72,
      -54.5303,
      66.2239,
      32.72,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_4-5_1",
    polygon: [
      -44.2367,
      80.2321,
      30.205,
      -44.2367,
      80.2321,
      32.275,
      -44.2341,
      80.9821,
      32.275,
      -44.2341,
      80.9821,
      32.275,
      -44.2341,
      80.9821,
      30.205,
      -44.2367,
      80.2321,
      30.205,
    ],
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "6.OG_An_11-12_1",
    polygon: [
      -57.0565,
      41.5638,
      26.4,
      -57.0565,
      41.5638,
      28.66,
      -56.9802,
      43.7124,
      28.66,
      -56.9802,
      43.7124,
      28.66,
      -56.9802,
      43.7124,
      26.4,
      -57.0565,
      41.5638,
      26.4,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "6.OG_Bn-Cn_5-6_2",
    polygon: [
      -44.2361,
      78.4623,
      26.4,
      -44.2361,
      78.4623,
      28.47,
      -44.2334,
      79.2123,
      28.47,
      -44.2334,
      79.2123,
      28.47,
      -44.2334,
      79.2123,
      26.4,
      -44.2361,
      78.4623,
      26.4,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "8.OG_O-P_1_3",
    polygon: [
      17.603,
      104.0868,
      33.3,
      17.603,
      104.0868,
      35.56,
      20.2805,
      104.3458,
      35.56,
      20.2805,
      104.3458,
      35.56,
      20.2805,
      104.3458,
      33.3,
      17.603,
      104.0868,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_4-5_1",
    polygon: [
      -44.2366,
      80.2521,
      33.655,
      -44.2366,
      80.2521,
      35.725,
      -44.234,
      81.0021,
      35.725,
      -44.234,
      81.0021,
      35.725,
      -44.234,
      81.0021,
      33.655,
      -44.2366,
      80.2521,
      33.655,
    ],
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "6.OG_Bn-Cn_7-8_1",
    polygon: [
      -44.2775,
      66.5774,
      25.82,
      -44.2775,
      66.5774,
      28.64,
      -44.2707,
      68.5274,
      28.64,
      -44.2707,
      68.5274,
      28.64,
      -44.2707,
      68.5274,
      25.82,
      -44.2775,
      66.5774,
      25.82,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "7.OG_H-In_5-6_2",
    polygon: [
      -19.5623,
      75.7061,
      29.27,
      -19.5623,
      75.7061,
      32.09,
      -19.5555,
      77.6561,
      32.09,
      -19.5555,
      77.6561,
      32.09,
      -19.5555,
      77.6561,
      29.27,
      -19.5623,
      75.7061,
      29.27,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "8.OG_Bn-Cn_1-2n_1",
    polygon: [
      -44.1858,
      99.9082,
      33.49,
      -44.1858,
      99.9082,
      35.56,
      -42.2265,
      100.194,
      35.56,
      -42.2265,
      100.194,
      35.56,
      -42.2265,
      100.194,
      33.49,
      -44.1858,
      99.9082,
      33.49,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Dn-En_3-4n_1",
    polygon: [
      -36.6947,
      87.7559,
      33.655,
      -36.6947,
      87.7559,
      35.725,
      -37.4447,
      87.7585,
      35.725,
      -37.4447,
      87.7585,
      35.725,
      -37.4447,
      87.7585,
      33.655,
      -36.6947,
      87.7559,
      33.655,
    ],
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "6.OG_H-In_1_1",
    polygon: [
      -20.4893,
      103.3065,
      26.4,
      -20.4893,
      103.3065,
      28.66,
      -18.5334,
      103.4957,
      28.66,
      -18.5334,
      103.4957,
      28.66,
      -18.5334,
      103.4957,
      26.4,
      -20.4893,
      103.3065,
      26.4,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Bn-Cn_9-10_1",
    polygon: [
      -44.3263,
      52.5423,
      25.82,
      -44.3263,
      52.5423,
      28.64,
      -44.3189,
      54.6922,
      28.64,
      -44.3189,
      54.6922,
      28.64,
      -44.3189,
      54.6922,
      25.82,
      -44.3263,
      52.5423,
      25.82,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "7.OG_An_15-16_1",
    polygon: [
      -57.7378,
      22.4809,
      29.27,
      -57.7378,
      22.4809,
      32.09,
      -57.6667,
      24.4697,
      32.09,
      -57.6667,
      24.4697,
      32.09,
      -57.6667,
      24.4697,
      29.27,
      -57.7378,
      22.4809,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_An_5-6_1",
    polygon: [
      -55.4019,
      74.8582,
      29.27,
      -55.4019,
      74.8582,
      32.09,
      -55.2594,
      76.808,
      32.09,
      -55.2594,
      76.808,
      32.09,
      -55.2594,
      76.808,
      29.27,
      -55.4019,
      74.8582,
      29.27,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
  },
  {
    ID: "7.OG_In-Jn_1_2",
    polygon: [
      -13.6162,
      102.5793,
      29.27,
      -13.6162,
      102.5793,
      32.09,
      -11.6603,
      102.7685,
      32.09,
      -11.6603,
      102.7685,
      32.09,
      -11.6603,
      102.7685,
      29.27,
      -13.6162,
      102.5793,
      29.27,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_H-In_4-5_2",
    polygon: [
      -19.5479,
      79.8361,
      33.3,
      -19.5479,
      79.8361,
      35.56,
      -19.5411,
      81.7861,
      35.56,
      -19.5411,
      81.7861,
      35.56,
      -19.5411,
      81.7861,
      33.3,
      -19.5479,
      79.8361,
      33.3,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "8.OG_Jn-Kn_3-4n_1",
    polygon: [
      -7.3992,
      88.0588,
      33.3,
      -7.3992,
      88.0588,
      35.56,
      -7.3924,
      90.0088,
      35.56,
      -7.3924,
      90.0088,
      35.56,
      -7.3924,
      90.0088,
      33.3,
      -7.3992,
      88.0588,
      33.3,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "8.OG_M-N_1_2",
    polygon: [
      9.3868,
      104.8039,
      33.3,
      9.3868,
      104.8039,
      35.56,
      11.3427,
      104.993,
      35.56,
      11.3427,
      104.993,
      35.56,
      11.3427,
      104.993,
      33.3,
      9.3868,
      104.8039,
      33.3,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_7-8_2",
    polygon: [
      -45.4949,
      63.5314,
      29.27,
      -45.4949,
      63.5314,
      32.09,
      -45.4914,
      64.5164,
      32.09,
      -45.4914,
      64.5164,
      32.09,
      -45.4914,
      64.5164,
      29.27,
      -45.4949,
      63.5314,
      29.27,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "8.OG_An_15-16_2",
    polygon: [
      -56.3274,
      19.8882,
      32.72,
      -56.3274,
      19.8882,
      35.54,
      -56.2566,
      21.8769,
      35.54,
      -56.2566,
      21.8769,
      35.54,
      -56.2566,
      21.8769,
      32.72,
      -56.3274,
      19.8882,
      32.72,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_10-11_2",
    polygon: [
      -56.8475,
      47.0799,
      33.3,
      -56.8475,
      47.0799,
      35.56,
      -56.7706,
      49.2285,
      35.56,
      -56.7706,
      49.2285,
      35.56,
      -56.7706,
      49.2285,
      33.3,
      -56.8475,
      47.0799,
      33.3,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_6-7_1",
    polygon: [
      -54.3282,
      68.9765,
      32.72,
      -54.3282,
      68.9765,
      35.54,
      -54.1851,
      70.9263,
      35.54,
      -54.1851,
      70.9263,
      35.54,
      -54.1851,
      70.9263,
      32.72,
      -54.3282,
      68.9765,
      32.72,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_14-15_2",
    polygon: [
      -45.6199,
      27.7816,
      29.27,
      -45.6199,
      27.7816,
      32.09,
      -45.6124,
      29.9316,
      32.09,
      -45.6124,
      29.9316,
      32.09,
      -45.6124,
      29.9316,
      29.27,
      -45.6199,
      27.7816,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_Jn-Kn_5-6_1",
    polygon: [
      -5.6923,
      75.6803,
      26.4,
      -5.6923,
      75.6803,
      28.66,
      -5.6898,
      76.4053,
      28.66,
      -5.6898,
      76.4053,
      28.66,
      -5.6898,
      76.4053,
      26.4,
      -5.6923,
      75.6803,
      26.4,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "6.OG_An_11-12_2",
    polygon: [
      -56.9587,
      44.3321,
      26.4,
      -56.9587,
      44.3321,
      28.66,
      -56.8818,
      46.4807,
      28.66,
      -56.8818,
      46.4807,
      28.66,
      -56.8818,
      46.4807,
      26.4,
      -56.9587,
      44.3321,
      26.4,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_O-P_1_2",
    polygon: [
      20.0198,
      103.5134,
      29.27,
      20.0198,
      103.5134,
      32.09,
      20.0236,
      104.5884,
      32.09,
      20.0236,
      104.5884,
      32.09,
      20.0236,
      104.5884,
      29.27,
      20.0198,
      103.5134,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
  },
  {
    ID: "6.OG_An_10-11_1",
    polygon: [
      -56.8605,
      47.0803,
      26.4,
      -56.8605,
      47.0803,
      28.66,
      -56.7842,
      49.229,
      28.66,
      -56.7842,
      49.229,
      28.66,
      -56.7842,
      49.229,
      26.4,
      -56.8605,
      47.0803,
      26.4,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_Dn-En_3-4n_1",
    polygon: [
      -36.6847,
      87.7558,
      36.94,
      -36.6847,
      87.7558,
      39.01,
      -37.4347,
      87.7585,
      39.01,
      -37.4347,
      87.7585,
      39.01,
      -37.4347,
      87.7585,
      36.94,
      -36.6847,
      87.7558,
      36.94,
    ],
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
  },
  {
    ID: "6.OG_An_2n-3_1",
    polygon: [
      -55.7214,
      90.9468,
      26.4,
      -55.7214,
      90.9468,
      28.47,
      -55.5753,
      92.9365,
      28.47,
      -55.5753,
      92.9365,
      28.47,
      -55.5753,
      92.9365,
      26.4,
      -55.7214,
      90.9468,
      26.4,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_8-9_1",
    polygon: [
      -46.714,
      58.0405,
      32.72,
      -46.714,
      58.0405,
      35.54,
      -46.7065,
      60.1905,
      35.54,
      -46.7065,
      60.1905,
      35.54,
      -46.7065,
      60.1905,
      32.72,
      -46.714,
      58.0405,
      32.72,
    ],
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "8.OG_Bn-Cn_1-2n_2",
    polygon: [
      -46.9267,
      99.5083,
      33.49,
      -46.9267,
      99.5083,
      35.56,
      -44.9675,
      99.7942,
      35.56,
      -44.9675,
      99.7942,
      35.56,
      -44.9675,
      99.7942,
      33.49,
      -46.9267,
      99.5083,
      33.49,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An-Bn_1-2n_1",
    polygon: [
      -48.4307,
      97.7736,
      36.17,
      -48.4307,
      97.7736,
      39.29,
      -47.2633,
      97.9455,
      39.29,
      -47.2633,
      97.9455,
      39.29,
      -47.2633,
      97.9455,
      36.17,
      -48.4307,
      97.7736,
      36.17,
    ],
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_11-12_2",
    polygon: [
      -44.3646,
      43.4923,
      29.85,
      -44.3646,
      43.4923,
      32.11,
      -44.3612,
      44.4673,
      32.11,
      -44.3612,
      44.4673,
      32.11,
      -44.3612,
      44.4673,
      29.85,
      -44.3646,
      43.4923,
      29.85,
    ],
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "7.OG_An_4-5_1",
    polygon: [
      -56.2968,
      83.1125,
      29.85,
      -56.2968,
      83.1125,
      31.92,
      -56.1507,
      85.1021,
      31.92,
      -56.1507,
      85.1021,
      31.92,
      -56.1507,
      85.1021,
      29.85,
      -56.2968,
      83.1125,
      29.85,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_In-Jn_1-2n_1",
    polygon: [
      -14.1826,
      97.4125,
      36.17,
      -14.1826,
      97.4125,
      39.29,
      -14.1758,
      99.3775,
      39.29,
      -14.1758,
      99.3775,
      39.29,
      -14.1758,
      99.3775,
      36.17,
      -14.1826,
      97.4125,
      36.17,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_15-16_2",
    polygon: [
      -46.8381,
      22.4158,
      32.72,
      -46.8381,
      22.4158,
      35.54,
      -46.831,
      24.4358,
      35.54,
      -46.831,
      24.4358,
      35.54,
      -46.831,
      24.4358,
      32.72,
      -46.8381,
      22.4158,
      32.72,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "7.OG_An_7-8_2",
    polygon: [
      -56.2676,
      63.5746,
      29.27,
      -56.2676,
      63.5746,
      32.09,
      -56.2324,
      64.559,
      32.09,
      -56.2324,
      64.559,
      32.09,
      -56.2324,
      64.559,
      29.27,
      -56.2676,
      63.5746,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_N-On_2n-3_2",
    polygon: [
      13.0231,
      91.5577,
      33.3,
      13.0231,
      91.5577,
      35.56,
      11.0731,
      91.5645,
      35.56,
      11.0731,
      91.5645,
      35.56,
      11.0731,
      91.5645,
      33.3,
      13.0231,
      91.5577,
      33.3,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "8.OG_H-In_5-6_1",
    polygon: [
      -19.5574,
      77.0911,
      33.3,
      -19.5574,
      77.0911,
      35.56,
      -19.5507,
      79.0411,
      35.56,
      -19.5507,
      79.0411,
      35.56,
      -19.5507,
      79.0411,
      33.3,
      -19.5574,
      77.0911,
      33.3,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
  },
  {
    ID: "8.OG_M-N_2n-3_2",
    polygon: [
      10.2731,
      91.5673,
      33.3,
      10.2731,
      91.5673,
      35.56,
      8.3232,
      91.5741,
      35.56,
      8.3232,
      91.5741,
      35.56,
      8.3232,
      91.5741,
      33.3,
      10.2731,
      91.5673,
      33.3,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "8.OG_An_11-12_1",
    polygon: [
      -56.9459,
      44.3316,
      33.3,
      -56.9459,
      44.3316,
      35.56,
      -56.869,
      46.4803,
      35.56,
      -56.869,
      46.4803,
      35.56,
      -56.869,
      46.4803,
      33.3,
      -56.9459,
      44.3316,
      33.3,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_6-7_2",
    polygon: [
      -54.1276,
      71.7092,
      32.72,
      -54.1276,
      71.7092,
      35.54,
      -53.9844,
      73.6589,
      35.54,
      -53.9844,
      73.6589,
      35.54,
      -53.9844,
      73.6589,
      32.72,
      -54.1276,
      71.7092,
      32.72,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "8.OG_An_13-14_1",
    polygon: [
      -55.9473,
      30.5614,
      32.72,
      -55.9473,
      30.5614,
      35.54,
      -55.8732,
      32.6401,
      35.54,
      -55.8732,
      32.6401,
      35.54,
      -55.8732,
      32.6401,
      32.72,
      -55.9473,
      30.5614,
      32.72,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_M-N_2n-3_1",
    polygon: [
      11.663,
      91.5125,
      36.75,
      11.663,
      91.5125,
      39.31,
      9.713,
      91.5193,
      39.31,
      9.713,
      91.5193,
      39.31,
      9.713,
      91.5193,
      36.75,
      11.663,
      91.5125,
      36.75,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "7.OG_Jn-Kn_3-4n_1",
    polygon: [
      -7.4039,
      86.6889,
      29.27,
      -7.4039,
      86.6889,
      32.09,
      -7.3971,
      88.6388,
      32.09,
      -7.3971,
      88.6388,
      32.09,
      -7.3971,
      88.6388,
      29.27,
      -7.4039,
      86.6889,
      29.27,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "9.OG_An_4-5_1",
    polygon: [
      -54.804,
      83.0205,
      36.17,
      -54.804,
      83.0205,
      39.29,
      -54.662,
      84.9704,
      39.29,
      -54.662,
      84.9704,
      39.29,
      -54.662,
      84.9704,
      36.17,
      -54.804,
      83.0205,
      36.17,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_15-16_2",
    polygon: [
      -59.241,
      19.9119,
      22.95,
      -59.241,
      19.9119,
      25.21,
      -59.1703,
      21.9006,
      25.21,
      -59.1703,
      21.9006,
      25.21,
      -59.1703,
      21.9006,
      22.95,
      -59.241,
      19.9119,
      22.95,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
  },
  {
    ID: "9.OG_L-M_1_1",
    polygon: [
      0.146,
      103.9508,
      36.75,
      0.146,
      103.9508,
      39.31,
      2.1019,
      104.14,
      39.31,
      2.1019,
      104.14,
      39.31,
      2.1019,
      104.14,
      36.75,
      0.146,
      103.9508,
      36.75,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_O-P_1_3",
    polygon: [
      17.603,
      104.0868,
      29.27,
      17.603,
      104.0868,
      32.09,
      20.2805,
      104.3458,
      32.09,
      20.2805,
      104.3458,
      32.09,
      20.2805,
      104.3458,
      29.27,
      17.603,
      104.0868,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_13-14_1",
    polygon: [
      -58.8617,
      30.5852,
      26.4,
      -58.8617,
      30.5852,
      28.66,
      -58.7854,
      32.7338,
      28.66,
      -58.7854,
      32.7338,
      28.66,
      -58.7854,
      32.7338,
      26.4,
      -58.8617,
      30.5852,
      26.4,
    ],
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_An_10-11_2",
    polygon: [
      -56.7636,
      49.8086,
      26.4,
      -56.7636,
      49.8086,
      28.66,
      -56.6899,
      51.8823,
      28.66,
      -56.6899,
      51.8823,
      28.66,
      -56.6899,
      51.8823,
      26.4,
      -56.7636,
      49.8086,
      26.4,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An_10-11_1",
    polygon: [
      -56.811,
      49.8103,
      36.75,
      -56.811,
      49.8103,
      39.59,
      -56.7341,
      51.9589,
      39.59,
      -56.7341,
      51.9589,
      39.59,
      -56.7341,
      51.9589,
      36.75,
      -56.811,
      49.8103,
      36.75,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_5-6_1",
    polygon: [
      -46.6554,
      74.8488,
      32.72,
      -46.6554,
      74.8488,
      35.54,
      -46.6528,
      75.5988,
      35.54,
      -46.6528,
      75.5988,
      35.54,
      -46.6528,
      75.5988,
      32.72,
      -46.6554,
      74.8488,
      32.72,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "8.OG_Kn-L_1_2",
    polygon: [
      -3.9958,
      103.5097,
      33.3,
      -3.9958,
      103.5097,
      35.56,
      -2.0399,
      103.6989,
      35.56,
      -2.0399,
      103.6989,
      35.56,
      -2.0399,
      103.6989,
      33.3,
      -3.9958,
      103.5097,
      33.3,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Cn-Dn_4n-4_1",
    polygon: [
      -43.0665,
      84.8015,
      32.72,
      -43.0665,
      84.8015,
      35.6,
      -41.2523,
      86.6077,
      35.6,
      -41.2523,
      86.6077,
      35.6,
      -41.2523,
      86.6077,
      32.72,
      -43.0665,
      84.8015,
      32.72,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
  },
  {
    ID: "9.OG_In-Jn_1-2n_2",
    polygon: [
      -14.1731,
      100.1575,
      36.75,
      -14.1731,
      100.1575,
      39.31,
      -14.1693,
      101.2325,
      39.31,
      -14.1693,
      101.2325,
      39.31,
      -14.1693,
      101.2325,
      36.75,
      -14.1731,
      100.1575,
      36.75,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An_13-14_3",
    polygon: [
      -54.8905,
      30.3439,
      36.75,
      -54.8905,
      30.3439,
      39.59,
      -56.0055,
      30.3478,
      39.59,
      -56.0055,
      30.3478,
      39.59,
      -56.0055,
      30.3478,
      36.75,
      -54.8905,
      30.3439,
      36.75,
    ],
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "7.OG_Jn-Kn_5-6_1",
    polygon: [
      -7.4423,
      75.6689,
      29.27,
      -7.4423,
      75.6689,
      32.09,
      -7.4355,
      77.6189,
      32.09,
      -7.4355,
      77.6189,
      32.09,
      -7.4355,
      77.6189,
      29.27,
      -7.4423,
      75.6689,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "7.OG_An_8-9_1",
    polygon: [
      -56.3659,
      60.8264,
      29.27,
      -56.3659,
      60.8264,
      32.09,
      -56.289,
      62.975,
      32.09,
      -56.289,
      62.975,
      32.09,
      -56.289,
      62.975,
      29.27,
      -56.3659,
      60.8264,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Bn-Cn_9-10_2",
    polygon: [
      -44.3168,
      55.2722,
      25.82,
      -44.3168,
      55.2722,
      28.64,
      -44.3093,
      57.4222,
      28.64,
      -44.3093,
      57.4222,
      28.64,
      -44.3093,
      57.4222,
      25.82,
      -44.3168,
      55.2722,
      25.82,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "9.OG_An-Bn_9-10_2",
    polygon: [
      -48.3831,
      52.5463,
      36.19,
      -48.3831,
      52.5463,
      39.57,
      -48.3756,
      54.6963,
      39.57,
      -48.3756,
      54.6963,
      39.57,
      -48.3756,
      54.6963,
      36.19,
      -48.3831,
      52.5463,
      36.19,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
  },
  {
    ID: "8.OG_An_13-14_2",
    polygon: [
      -57.36,
      32.759,
      33.3,
      -57.36,
      32.759,
      35.56,
      -57.2631,
      35.4673,
      35.56,
      -57.2631,
      35.4673,
      35.56,
      -57.2631,
      35.4673,
      33.3,
      -57.36,
      32.759,
      33.3,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_4-5_1",
    polygon: [
      -56.3268,
      82.7039,
      33.49,
      -56.3268,
      82.7039,
      35.56,
      -56.1807,
      84.6936,
      35.56,
      -56.1807,
      84.6936,
      35.56,
      -56.1807,
      84.6936,
      33.49,
      -56.3268,
      82.7039,
      33.49,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_11-12_2",
    polygon: [
      -57.0442,
      41.5834,
      33.3,
      -57.0442,
      41.5834,
      35.56,
      -56.9673,
      43.732,
      35.56,
      -56.9673,
      43.732,
      35.56,
      -56.9673,
      43.732,
      33.3,
      -57.0442,
      41.5834,
      33.3,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_L-M_1_2",
    polygon: [
      3.8775,
      104.2711,
      33.3,
      3.8775,
      104.2711,
      35.56,
      5.8334,
      104.4603,
      35.56,
      5.8334,
      104.4603,
      35.56,
      5.8334,
      104.4603,
      33.3,
      3.8775,
      104.2711,
      33.3,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_M-N_2n-3_2",
    polygon: [
      8.893,
      91.5221,
      36.75,
      8.893,
      91.5221,
      39.31,
      6.943,
      91.5289,
      39.31,
      6.943,
      91.5289,
      39.31,
      6.943,
      91.5289,
      36.75,
      8.893,
      91.5221,
      36.75,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "7.OG_Bn-Cn_13-14_1",
    polygon: [
      -45.6103,
      30.5316,
      29.27,
      -45.6103,
      30.5316,
      32.09,
      -45.6028,
      32.6816,
      32.09,
      -45.6028,
      32.6816,
      32.09,
      -45.6028,
      32.6816,
      29.27,
      -45.6103,
      30.5316,
      29.27,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "8.OG_H-In_1-2n_1",
    polygon: [
      -17.749,
      100.6657,
      32.72,
      -17.749,
      100.6657,
      35.54,
      -15.7932,
      100.8553,
      35.54,
      -15.7932,
      100.8553,
      35.54,
      -15.7932,
      100.8553,
      32.72,
      -17.749,
      100.6657,
      32.72,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An_3-4n_1",
    polygon: [
      -54.6049,
      85.7533,
      36.17,
      -54.6049,
      85.7533,
      39.29,
      -54.4628,
      87.7031,
      39.29,
      -54.4628,
      87.7031,
      39.29,
      -54.4628,
      87.7031,
      36.17,
      -54.6049,
      85.7533,
      36.17,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "5.OG_An_11-12_1",
    polygon: [
      -57.0565,
      41.5638,
      22.95,
      -57.0565,
      41.5638,
      25.21,
      -56.9802,
      43.7124,
      25.21,
      -56.9802,
      43.7124,
      25.21,
      -56.9802,
      43.7124,
      22.95,
      -57.0565,
      41.5638,
      22.95,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
  },
  {
    ID: "6.OG_An_9-10_2",
    polygon: [
      -58.0868,
      52.1606,
      26.4,
      -58.0868,
      52.1606,
      28.66,
      -57.9943,
      54.744,
      28.66,
      -57.9943,
      54.744,
      28.66,
      -57.9943,
      54.744,
      26.4,
      -58.0868,
      52.1606,
      26.4,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_L-M_1_2",
    polygon: [
      2.8832,
      104.2155,
      36.75,
      2.8832,
      104.2155,
      39.31,
      4.436,
      104.3657,
      39.31,
      4.436,
      104.3657,
      39.31,
      4.436,
      104.3657,
      36.75,
      2.8832,
      104.2155,
      36.75,
    ],
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_1-2n_1",
    polygon: [
      -54.5548,
      96.3415,
      33.49,
      -54.5548,
      96.3415,
      35.56,
      -53.0602,
      97.9078,
      35.56,
      -53.0602,
      97.9078,
      35.56,
      -53.0602,
      97.9078,
      33.49,
      -54.5548,
      96.3415,
      33.49,
    ],
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_O-P_1-2n_1",
    polygon: [
      20.0537,
      98.8883,
      36.75,
      20.0537,
      98.8883,
      39.31,
      20.0606,
      100.8533,
      39.31,
      20.0606,
      100.8533,
      39.31,
      20.0606,
      100.8533,
      36.75,
      20.0537,
      98.8883,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_8-9_2",
    polygon: [
      -46.7045,
      60.7705,
      32.72,
      -46.7045,
      60.7705,
      35.54,
      -46.697,
      62.9205,
      35.54,
      -46.697,
      62.9205,
      35.54,
      -46.697,
      62.9205,
      32.72,
      -46.7045,
      60.7705,
      32.72,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "9.OG_Cn-Dn_4n-4_1",
    polygon: [
      -43.0688,
      84.7981,
      36.17,
      -43.0688,
      84.7981,
      39.19,
      -41.2557,
      86.6054,
      39.19,
      -41.2557,
      86.6054,
      39.19,
      -41.2557,
      86.6054,
      36.17,
      -43.0688,
      84.7981,
      36.17,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
  },
  {
    ID: "9.OG_An_10-11_2",
    polygon: [
      -56.9087,
      47.0821,
      36.75,
      -56.9087,
      47.0821,
      39.59,
      -56.8318,
      49.2307,
      39.59,
      -56.8318,
      49.2307,
      39.59,
      -56.8318,
      49.2307,
      36.75,
      -56.9087,
      47.0821,
      36.75,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Jn-Kn_1_1",
    polygon: [
      -7.3501,
      103.1853,
      33.3,
      -7.3501,
      103.1853,
      35.56,
      -4.7921,
      103.4327,
      35.56,
      -4.7921,
      103.4327,
      35.56,
      -4.7921,
      103.4327,
      33.3,
      -7.3501,
      103.1853,
      33.3,
    ],
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "6.OG_Jn-Kn_5-6_2",
    polygon: [
      -5.6875,
      77.0453,
      26.4,
      -5.6875,
      77.0453,
      28.66,
      -5.6807,
      79.0103,
      28.66,
      -5.6807,
      79.0103,
      28.66,
      -5.6807,
      79.0103,
      26.4,
      -5.6875,
      77.0453,
      26.4,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "7.OG_Bn-Cn_11-12_3",
    polygon: [
      -44.3606,
      44.6673,
      29.85,
      -44.3606,
      44.6673,
      32.11,
      -44.3572,
      45.6423,
      32.11,
      -44.3572,
      45.6423,
      32.11,
      -44.3572,
      45.6423,
      29.85,
      -44.3606,
      44.6673,
      29.85,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "9.OG_An_13-14_4",
    polygon: [
      -56.0117,
      30.1758,
      36.75,
      -56.0117,
      30.1758,
      39.59,
      -55.9224,
      32.6698,
      39.59,
      -55.9224,
      32.6698,
      39.59,
      -55.9224,
      32.6698,
      36.75,
      -56.0117,
      30.1758,
      36.75,
    ],
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_An_8-9_2",
    polygon: [
      -56.4642,
      58.0782,
      29.27,
      -56.4642,
      58.0782,
      32.09,
      -56.3874,
      60.2268,
      32.09,
      -56.3874,
      60.2268,
      32.09,
      -56.3874,
      60.2268,
      29.27,
      -56.4642,
      58.0782,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_14-15_1",
    polygon: [
      -46.829,
      25.0358,
      32.72,
      -46.829,
      25.0358,
      35.54,
      -46.8215,
      27.1858,
      35.54,
      -46.8215,
      27.1858,
      35.54,
      -46.8215,
      27.1858,
      32.72,
      -46.829,
      25.0358,
      32.72,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "8.OG_An_4n-4_1",
    polygon: [
      -56.1252,
      85.4497,
      33.49,
      -56.1252,
      85.4497,
      35.56,
      -55.979,
      87.4393,
      35.56,
      -55.979,
      87.4393,
      35.56,
      -55.979,
      87.4393,
      33.49,
      -56.1252,
      85.4497,
      33.49,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_12-13_1",
    polygon: [
      -57.1426,
      38.8352,
      33.3,
      -57.1426,
      38.8352,
      35.56,
      -57.0657,
      40.9838,
      35.56,
      -57.0657,
      40.9838,
      35.56,
      -57.0657,
      40.9838,
      33.3,
      -57.1426,
      38.8352,
      33.3,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Jn-Kn_5-6_1",
    polygon: [
      -7.4423,
      75.6739,
      33.3,
      -7.4423,
      75.6739,
      35.56,
      -7.4398,
      76.3989,
      35.56,
      -7.4398,
      76.3989,
      35.56,
      -7.4398,
      76.3989,
      33.3,
      -7.4423,
      75.6739,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "8.OG_N-On_2n-3_3",
    polygon: [
      15.1624,
      91.3303,
      33.3,
      15.1624,
      91.3303,
      35.56,
      15.1679,
      92.9153,
      35.56,
      15.1679,
      92.9153,
      35.56,
      15.1679,
      92.9153,
      33.3,
      15.1624,
      91.3303,
      33.3,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "8.OG_N-On_2n-3_1",
    polygon: [
      15.4281,
      91.5493,
      33.3,
      15.4281,
      91.5493,
      35.56,
      13.8431,
      91.5549,
      35.56,
      13.8431,
      91.5549,
      35.56,
      13.8431,
      91.5549,
      33.3,
      15.4281,
      91.5493,
      33.3,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
  },
  {
    ID: "8.OG_H-In_1-2n_2",
    polygon: [
      -20.5011,
      100.399,
      32.72,
      -20.5011,
      100.399,
      35.54,
      -18.5453,
      100.5885,
      35.54,
      -18.5453,
      100.5885,
      35.54,
      -18.5453,
      100.5885,
      32.72,
      -20.5011,
      100.399,
      32.72,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An_3-4n_2",
    polygon: [
      -54.3965,
      88.5254,
      36.17,
      -54.3965,
      88.5254,
      39.29,
      -54.2534,
      90.4752,
      39.29,
      -54.2534,
      90.4752,
      39.29,
      -54.2534,
      90.4752,
      36.17,
      -54.3965,
      88.5254,
      36.17,
    ],
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_L-M_2n-3_1",
    polygon: [
      6.153,
      91.5317,
      36.75,
      6.153,
      91.5317,
      39.31,
      4.203,
      91.5384,
      39.31,
      4.203,
      91.5384,
      39.31,
      4.203,
      91.5384,
      36.75,
      6.153,
      91.5317,
      36.75,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "7.OG_Jn-Kn_3-4n_2",
    polygon: [
      -7.395,
      89.2588,
      29.27,
      -7.395,
      89.2588,
      32.09,
      -7.3925,
      89.9838,
      32.09,
      -7.3925,
      89.9838,
      32.09,
      -7.3925,
      89.9838,
      29.27,
      -7.395,
      89.2588,
      29.27,
    ],
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "9.OG_L-M_1_3",
    polygon: [
      5.2522,
      104.4446,
      36.75,
      5.2522,
      104.4446,
      39.31,
      7.2081,
      104.6338,
      39.31,
      7.2081,
      104.6338,
      39.31,
      7.2081,
      104.6338,
      36.75,
      5.2522,
      104.4446,
      36.75,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An-Bn_1-2n_2",
    polygon: [
      -52.4621,
      98.2924,
      33.49,
      -52.4621,
      98.2924,
      35.56,
      -50.4169,
      99.0027,
      35.56,
      -50.4169,
      99.0027,
      35.56,
      -50.4169,
      99.0027,
      33.49,
      -52.4621,
      98.2924,
      33.49,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An_2n-3_2",
    polygon: [
      -54.0145,
      93.1048,
      36.17,
      -54.0145,
      93.1048,
      39.29,
      -53.337,
      94.8798,
      39.29,
      -53.337,
      94.8798,
      39.29,
      -53.337,
      94.8798,
      36.17,
      -54.0145,
      93.1048,
      36.17,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An_11-12_1",
    polygon: [
      -57.007,
      44.3338,
      36.75,
      -57.007,
      44.3338,
      39.59,
      -56.9301,
      46.4824,
      39.59,
      -56.9301,
      46.4824,
      39.59,
      -56.9301,
      46.4824,
      36.75,
      -57.007,
      44.3338,
      36.75,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_6-7_1",
    polygon: [
      -46.6651,
      72.0838,
      32.72,
      -46.6651,
      72.0838,
      35.54,
      -46.6583,
      74.0338,
      35.54,
      -46.6583,
      74.0338,
      35.54,
      -46.6583,
      74.0338,
      32.72,
      -46.6651,
      72.0838,
      32.72,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
  },
  {
    ID: "7.OG_An_11-12_2",
    polygon: [
      -56.9561,
      44.3319,
      29.85,
      -56.9561,
      44.3319,
      32.11,
      -56.8792,
      46.4806,
      32.11,
      -56.8792,
      46.4806,
      32.11,
      -56.8792,
      46.4806,
      29.85,
      -56.9561,
      44.3319,
      29.85,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_An_3-4n_1",
    polygon: [
      -55.9229,
      88.2034,
      33.49,
      -55.9229,
      88.2034,
      35.56,
      -55.7768,
      90.193,
      35.56,
      -55.7768,
      90.193,
      35.56,
      -55.7768,
      90.193,
      33.49,
      -55.9229,
      88.2034,
      33.49,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_Bn-Cn_11-12_1",
    polygon: [
      -44.3687,
      42.3173,
      36.75,
      -44.3687,
      42.3173,
      39.59,
      -44.3653,
      43.2923,
      39.59,
      -44.3653,
      43.2923,
      39.59,
      -44.3653,
      43.2923,
      36.75,
      -44.3687,
      42.3173,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "7.OG_An_9-10_1",
    polygon: [
      -56.5626,
      55.3299,
      29.27,
      -56.5626,
      55.3299,
      32.09,
      -56.4857,
      57.4785,
      32.09,
      -56.4857,
      57.4785,
      32.09,
      -56.4857,
      57.4785,
      29.27,
      -56.5626,
      55.3299,
      29.27,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An-Bn_13-14_2",
    polygon: [
      -48.4501,
      33.2965,
      36.19,
      -48.4501,
      33.2965,
      39.57,
      -48.4427,
      35.4465,
      39.57,
      -48.4427,
      35.4465,
      39.57,
      -48.4427,
      35.4465,
      36.19,
      -48.4501,
      33.2965,
      36.19,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "6.OG_Bn-Cn_8-9_1",
    polygon: [
      -44.3073,
      58.0222,
      25.82,
      -44.3073,
      58.0222,
      28.64,
      -44.2998,
      60.1722,
      28.64,
      -44.2998,
      60.1722,
      28.64,
      -44.2998,
      60.1722,
      25.82,
      -44.3073,
      58.0222,
      25.82,
    ],
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "8.OG_An_9-10_2",
    polygon: [
      -55.6268,
      55.022,
      33.3,
      -55.6268,
      55.022,
      35.56,
      -56.5768,
      55.0253,
      35.56,
      -56.5768,
      55.0253,
      35.56,
      -56.5768,
      55.0253,
      33.3,
      -55.6268,
      55.022,
      33.3,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_On-O_2n-3_1",
    polygon: [
      16.8847,
      93.4343,
      33.3,
      16.8847,
      93.4343,
      35.56,
      16.1597,
      93.4368,
      35.56,
      16.1597,
      93.4368,
      35.56,
      16.1597,
      93.4368,
      33.3,
      16.8847,
      93.4343,
      33.3,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "9.OG_Dn-En_1-2n_1",
    polygon: [
      -41.0387,
      98.8569,
      36.17,
      -41.0387,
      98.8569,
      39.29,
      -39.0792,
      99.1412,
      39.29,
      -39.0792,
      99.1412,
      39.29,
      -39.0792,
      99.1412,
      36.17,
      -41.0387,
      98.8569,
      36.17,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_N-On_1_1",
    polygon: [
      12.1141,
      105.0676,
      33.3,
      12.1141,
      105.0676,
      35.56,
      14.7916,
      105.3266,
      35.56,
      14.7916,
      105.3266,
      35.56,
      14.7916,
      105.3266,
      33.3,
      12.1141,
      105.0676,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_L-M_2n-3_2",
    polygon: [
      3.753,
      91.54,
      36.75,
      3.753,
      91.54,
      39.31,
      2.868,
      91.5431,
      39.31,
      2.868,
      91.5431,
      39.31,
      2.868,
      91.5431,
      36.75,
      3.753,
      91.54,
      36.75,
    ],
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "8.OG_An_2n-3_2",
    polygon: [
      -55.5205,
      93.6916,
      33.49,
      -55.5205,
      93.6916,
      35.56,
      -54.8922,
      95.7634,
      35.56,
      -54.8922,
      95.7634,
      35.56,
      -54.8922,
      95.7634,
      33.49,
      -55.5205,
      93.6916,
      33.49,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An-Bn_2n-3_1",
    polygon: [
      -52.961,
      95.442,
      36.17,
      -52.961,
      95.442,
      39.29,
      -51.5793,
      96.7461,
      39.29,
      -51.5793,
      96.7461,
      39.29,
      -51.5793,
      96.7461,
      36.17,
      -52.961,
      95.442,
      36.17,
    ],
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Jn-Kn_1_2",
    polygon: [
      -7.3445,
      102.3168,
      33.3,
      -7.3445,
      102.3168,
      35.56,
      -7.3408,
      103.3918,
      35.56,
      -7.3408,
      103.3918,
      35.56,
      -7.3408,
      103.3918,
      33.3,
      -7.3445,
      102.3168,
      33.3,
    ],
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An_11-12_2",
    polygon: [
      -57.1054,
      41.5856,
      36.75,
      -57.1054,
      41.5856,
      39.59,
      -57.0285,
      43.7342,
      39.59,
      -57.0285,
      43.7342,
      39.59,
      -57.0285,
      43.7342,
      36.75,
      -57.1054,
      41.5856,
      36.75,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_7-8_2",
    polygon: [
      -46.6948,
      63.5356,
      32.72,
      -46.6948,
      63.5356,
      35.54,
      -46.6914,
      64.5206,
      35.54,
      -46.6914,
      64.5206,
      35.54,
      -46.6914,
      64.5206,
      32.72,
      -46.6948,
      63.5356,
      32.72,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "7.OG_N-On_2n-3_2",
    polygon: [
      13.0256,
      91.5577,
      29.27,
      13.0256,
      91.5577,
      32.09,
      12.3006,
      91.5602,
      32.09,
      12.3006,
      91.5602,
      32.09,
      12.3006,
      91.5602,
      29.27,
      13.0256,
      91.5577,
      29.27,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
  },
  {
    ID: "9.OG_O-P_2n-3_1",
    polygon: [
      20.0433,
      95.9033,
      36.75,
      20.0433,
      95.9033,
      39.31,
      20.0458,
      96.6283,
      39.31,
      20.0458,
      96.6283,
      39.31,
      20.0458,
      96.6283,
      36.75,
      20.0433,
      95.9033,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "7.OG_Jn-Kn_1_3",
    polygon: [
      -6.7531,
      103.2431,
      29.27,
      -6.7531,
      103.2431,
      32.09,
      -4.7973,
      103.4322,
      32.09,
      -4.7973,
      103.4322,
      32.09,
      -4.7973,
      103.4322,
      29.27,
      -6.7531,
      103.2431,
      29.27,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_An_12-13_2",
    polygon: [
      -57.249,
      36.1422,
      29.85,
      -57.249,
      36.1422,
      32.11,
      -57.1749,
      38.2159,
      32.11,
      -57.1749,
      38.2159,
      32.11,
      -57.1749,
      38.2159,
      29.85,
      -57.249,
      36.1422,
      29.85,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_N-On_1_2",
    polygon: [
      14.6332,
      104.4936,
      33.3,
      14.6332,
      104.4936,
      35.56,
      14.637,
      105.5686,
      35.56,
      14.637,
      105.5686,
      35.56,
      14.637,
      105.5686,
      33.3,
      14.6332,
      104.4936,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "9.OG_An-Bn_9-10_3",
    polygon: [
      -50.2453,
      57.6779,
      36.19,
      -50.2453,
      57.6779,
      39.57,
      -52.3953,
      57.6854,
      39.57,
      -52.3953,
      57.6854,
      39.57,
      -52.3953,
      57.6854,
      36.19,
      -50.2453,
      57.6779,
      36.19,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_14-15_2",
    polygon: [
      -46.8194,
      27.7858,
      32.72,
      -46.8194,
      27.7858,
      35.54,
      -46.8119,
      29.9358,
      35.54,
      -46.8119,
      29.9358,
      35.54,
      -46.8119,
      29.9358,
      32.72,
      -46.8194,
      27.7858,
      32.72,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "9.OG_Bn-Cn_1-2n_1",
    polygon: [
      -46.5312,
      98.0533,
      36.17,
      -46.5312,
      98.0533,
      39.29,
      -44.5723,
      98.3417,
      39.29,
      -44.5723,
      98.3417,
      39.29,
      -44.5723,
      98.3417,
      36.17,
      -46.5312,
      98.0533,
      36.17,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Jn-Kn_5-6_2",
    polygon: [
      -7.4375,
      77.0589,
      33.3,
      -7.4375,
      77.0589,
      35.56,
      -7.4307,
      79.0089,
      35.56,
      -7.4307,
      79.0089,
      35.56,
      -7.4307,
      79.0089,
      33.3,
      -7.4375,
      77.0589,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "9.OG_An-Bn_5-6_1",
    polygon: [
      -49.4041,
      74.9751,
      36.209,
      -49.4041,
      74.9751,
      39.29,
      -52.1061,
      74.9845,
      39.29,
      -52.1061,
      74.9845,
      39.29,
      -52.1061,
      74.9845,
      36.19,
      -49.4041,
      74.9751,
      36.209,
    ],
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
  },
  {
    ID: "8.OG_An_7-8_2",
    polygon: [
      -54.7662,
      63.5877,
      32.72,
      -54.7662,
      63.5877,
      35.54,
      -54.731,
      64.5721,
      35.54,
      -54.731,
      64.5721,
      35.54,
      -54.731,
      64.5721,
      32.72,
      -54.7662,
      63.5877,
      32.72,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An-Bn_1-2n_2",
    polygon: [
      -50.9766,
      97.0991,
      36.17,
      -50.9766,
      97.0991,
      39.29,
      -49.1631,
      97.6661,
      39.29,
      -49.1631,
      97.6661,
      39.29,
      -49.1631,
      97.6661,
      36.17,
      -50.9766,
      97.0991,
      36.17,
    ],
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_H-In_4n-4_1",
    polygon: [
      -19.5287,
      85.3361,
      33.3,
      -19.5287,
      85.3361,
      35.56,
      -19.5219,
      87.2861,
      35.56,
      -19.5219,
      87.2861,
      35.56,
      -19.5219,
      87.2861,
      33.3,
      -19.5287,
      85.3361,
      33.3,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "9.OG_An_12-13_1",
    polygon: [
      -57.2037,
      38.8373,
      36.75,
      -57.2037,
      38.8373,
      39.59,
      -57.1268,
      40.986,
      39.59,
      -57.1268,
      40.986,
      39.59,
      -57.1268,
      40.986,
      36.75,
      -57.2037,
      38.8373,
      36.75,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_N-On_1_1",
    polygon: [
      12.1351,
      105.1103,
      36.75,
      12.1351,
      105.1103,
      39.31,
      14.6862,
      105.357,
      39.31,
      14.6862,
      105.357,
      39.31,
      14.6862,
      105.357,
      36.75,
      12.1351,
      105.1103,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_In-Jn_1-2n_3",
    polygon: [
      -14.3609,
      101.0404,
      36.75,
      -14.3609,
      101.0404,
      39.31,
      -11.6735,
      101.3009,
      39.31,
      -11.6735,
      101.3009,
      39.31,
      -11.6735,
      101.3009,
      36.75,
      -14.3609,
      101.0404,
      36.75,
    ],
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_L-M_2n-3_1",
    polygon: [
      4.7932,
      91.5864,
      33.3,
      4.7932,
      91.5864,
      35.56,
      2.8432,
      91.5932,
      35.56,
      2.8432,
      91.5932,
      35.56,
      2.8432,
      91.5932,
      33.3,
      4.7932,
      91.5864,
      33.3,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "9.OG_Bn-Cn_11-12_2",
    polygon: [
      -44.3646,
      43.4923,
      36.75,
      -44.3646,
      43.4923,
      39.59,
      -44.3612,
      44.4673,
      39.59,
      -44.3612,
      44.4673,
      39.59,
      -44.3612,
      44.4673,
      36.75,
      -44.3646,
      43.4923,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "9.OG_An-Bn_13-14_3",
    polygon: [
      -48.4597,
      30.5415,
      36.19,
      -48.4597,
      30.5415,
      39.57,
      -48.4523,
      32.6915,
      39.57,
      -48.4523,
      32.6915,
      39.57,
      -48.4523,
      32.6915,
      36.19,
      -48.4597,
      30.5415,
      36.19,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "9.OG_Cn-Dn_1-2n_1",
    polygon: [
      -43.7848,
      98.4576,
      36.17,
      -43.7848,
      98.4576,
      39.29,
      -41.8259,
      98.746,
      39.29,
      -41.8259,
      98.746,
      39.29,
      -41.8259,
      98.746,
      36.17,
      -43.7848,
      98.4576,
      36.17,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_O-P_2n-3_1",
    polygon: [
      19.9933,
      95.9035,
      33.3,
      19.9933,
      95.9035,
      35.56,
      19.9958,
      96.6285,
      35.56,
      19.9958,
      96.6285,
      35.56,
      19.9958,
      96.6285,
      33.3,
      19.9933,
      95.9035,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "9.OG_An-Bn_13-14_1",
    polygon: [
      -50.3005,
      30.3279,
      36.19,
      -50.3005,
      30.3279,
      39.57,
      -52.4505,
      30.3354,
      39.57,
      -52.4505,
      30.3354,
      39.57,
      -52.4505,
      30.3354,
      36.19,
      -50.3005,
      30.3279,
      36.19,
    ],
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
  },
  {
    ID: "9.OG_Jn-Kn_1_2",
    polygon: [
      -7.3413,
      103.2268,
      36.75,
      -7.3413,
      103.2268,
      39.31,
      -4.791,
      103.4734,
      39.31,
      -4.791,
      103.4734,
      39.31,
      -4.791,
      103.4734,
      36.75,
      -7.3413,
      103.2268,
      36.75,
    ],
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_11-12_1",
    polygon: [
      -44.3687,
      42.3173,
      33.3,
      -44.3687,
      42.3173,
      35.56,
      -44.3653,
      43.2923,
      35.56,
      -44.3653,
      43.2923,
      35.56,
      -44.3653,
      43.2923,
      33.3,
      -44.3687,
      42.3173,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "9.OG_An_12-13_2",
    polygon: [
      -57.3028,
      36.0691,
      36.75,
      -57.3028,
      36.0691,
      39.59,
      -57.2259,
      38.2177,
      39.59,
      -57.2259,
      38.2177,
      39.59,
      -57.2259,
      38.2177,
      36.75,
      -57.3028,
      36.0691,
      36.75,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "7.OG_N-On_2n-3_3",
    polygon: [
      15.1624,
      91.3303,
      29.27,
      15.1624,
      91.3303,
      32.09,
      15.1679,
      92.9153,
      32.09,
      15.1679,
      92.9153,
      32.09,
      15.1679,
      92.9153,
      29.27,
      15.1624,
      91.3303,
      29.27,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "9.OG_In-Jn_2n-3_1",
    polygon: [
      -14.2035,
      92.5726,
      36.75,
      -14.2035,
      92.5726,
      39.31,
      -14.1995,
      93.7226,
      39.31,
      -14.1995,
      93.7226,
      39.31,
      -14.1995,
      93.7226,
      36.75,
      -14.2035,
      92.5726,
      36.75,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
  },
  {
    ID: "8.OG_M-N_2n-3_1",
    polygon: [
      7.5232,
      91.5769,
      33.3,
      7.5232,
      91.5769,
      35.56,
      5.5732,
      91.5837,
      35.56,
      5.5732,
      91.5837,
      35.56,
      5.5732,
      91.5837,
      33.3,
      7.5232,
      91.5769,
      33.3,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "9.OG_En-Fn_2n-3_1",
    polygon: [
      -31.3676,
      92.8524,
      36.19,
      -31.3676,
      92.8524,
      39.29,
      -31.3581,
      95.5792,
      39.29,
      -31.3581,
      95.5792,
      39.29,
      -31.3581,
      95.5792,
      36.19,
      -31.3676,
      92.8524,
      36.19,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
  },
  {
    ID: "8.OG_H-In_5-6_2",
    polygon: [
      -19.5622,
      75.7261,
      33.3,
      -19.5622,
      75.7261,
      35.56,
      -19.5597,
      76.4511,
      35.56,
      -19.5597,
      76.4511,
      35.56,
      -19.5597,
      76.4511,
      33.3,
      -19.5622,
      75.7261,
      33.3,
    ],
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "9.OG_An_2n-3_1",
    polygon: [
      -54.199,
      91.2154,
      36.17,
      -54.199,
      91.2154,
      39.29,
      -54.1122,
      92.3973,
      39.29,
      -54.1122,
      92.3973,
      39.29,
      -54.1122,
      92.3973,
      36.17,
      -54.199,
      91.2154,
      36.17,
    ],
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_13-14_1",
    polygon: [
      -46.8098,
      30.5358,
      32.72,
      -46.8098,
      30.5358,
      35.54,
      -46.8023,
      32.6858,
      35.54,
      -46.8023,
      32.6858,
      35.54,
      -46.8023,
      32.6858,
      32.72,
      -46.8098,
      30.5358,
      32.72,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "9.OG_An_13-14_1",
    polygon: [
      -57.4135,
      32.9755,
      36.75,
      -57.4135,
      32.9755,
      39.59,
      -57.3242,
      35.4695,
      39.59,
      -57.3242,
      35.4695,
      39.59,
      -57.3242,
      35.4695,
      36.75,
      -57.4135,
      32.9755,
      36.75,
    ],
    "04-15 11:45": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_Kn-L_2n-3_1",
    polygon: [
      -3.1518,
      91.6141,
      36.75,
      -3.1518,
      91.6141,
      39.31,
      -5.2868,
      91.6215,
      39.31,
      -5.2868,
      91.6215,
      39.31,
      -5.2868,
      91.6215,
      36.75,
      -3.1518,
      91.6141,
      36.75,
    ],
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
  },
  {
    ID: "8.OG_Jn-Kn_4-5_1",
    polygon: [
      -7.4279,
      79.8089,
      33.3,
      -7.4279,
      79.8089,
      35.56,
      -7.4211,
      81.7589,
      35.56,
      -7.4211,
      81.7589,
      35.56,
      -7.4211,
      81.7589,
      33.3,
      -7.4279,
      79.8089,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "9.OG_N-On_1_2",
    polygon: [
      14.6832,
      104.4934,
      36.75,
      14.6832,
      104.4934,
      39.31,
      14.6862,
      105.357,
      39.31,
      14.6862,
      105.357,
      39.31,
      14.6862,
      105.357,
      36.75,
      14.6832,
      104.4934,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
  },
  {
    ID: "7.OG_On-O_2n-3_1",
    polygon: [
      16.8847,
      93.4343,
      29.27,
      16.8847,
      93.4343,
      32.09,
      16.1597,
      93.4368,
      32.09,
      16.1597,
      93.4368,
      32.09,
      16.1597,
      93.4368,
      29.27,
      16.8847,
      93.4343,
      29.27,
    ],
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "9.OG_An_9-10_1",
    polygon: [
      -54.0353,
      57.6871,
      39.59,
      -54.0352,
      57.6911,
      36.75,
      -55.0268,
      57.6946,
      39.59,
      -55.0268,
      57.6946,
      39.59,
      -55.0268,
      57.6946,
      36.75,
      -54.0353,
      57.6871,
      39.59,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Kn-L_2n-3_1",
    polygon: [
      -3.1518,
      91.6141,
      33.3,
      -3.1518,
      91.6141,
      35.56,
      -5.2868,
      91.6215,
      35.56,
      -5.2868,
      91.6215,
      35.56,
      -5.2868,
      91.6215,
      33.3,
      -3.1518,
      91.6141,
      33.3,
    ],
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
  },
  {
    ID: "9.OG_Jn-Kn_1-2n_1",
    polygon: [
      -10.8776,
      101.3821,
      36.75,
      -10.8776,
      101.3821,
      39.31,
      -8.9217,
      101.5717,
      39.31,
      -8.9217,
      101.5717,
      39.31,
      -8.9217,
      101.5717,
      36.75,
      -10.8776,
      101.3821,
      36.75,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_O-P_1_1",
    polygon: [
      20.0698,
      103.5108,
      36.75,
      20.0698,
      103.5108,
      39.31,
      20.0728,
      104.3759,
      39.31,
      20.0728,
      104.3759,
      39.31,
      20.0728,
      104.3759,
      36.75,
      20.0698,
      103.5108,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "9.OG_Bn-Cn_11-12_3",
    polygon: [
      -44.3606,
      44.6673,
      36.75,
      -44.3606,
      44.6673,
      39.59,
      -44.3572,
      45.6423,
      39.59,
      -44.3572,
      45.6423,
      39.59,
      -44.3572,
      45.6423,
      36.75,
      -44.3606,
      44.6673,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "8.OG_Jn-Kn_1-2n_1",
    polygon: [
      -9.4927,
      101.466,
      32.74,
      -9.4927,
      101.466,
      35.54,
      -7.94,
      101.6165,
      35.54,
      -7.94,
      101.6165,
      35.54,
      -7.94,
      101.6165,
      32.74,
      -9.4927,
      101.466,
      32.74,
    ],
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_O-P_1-2n_1",
    polygon: [
      20.0037,
      98.8885,
      33.3,
      20.0037,
      98.8885,
      35.56,
      20.0106,
      100.8534,
      35.56,
      20.0106,
      100.8534,
      35.56,
      20.0106,
      100.8534,
      33.3,
      20.0037,
      98.8885,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "9.OG_An_13-14_2",
    polygon: [
      -56.4136,
      32.972,
      36.75,
      -56.4136,
      32.972,
      39.59,
      -57.4135,
      32.9755,
      39.59,
      -57.4135,
      32.9755,
      39.59,
      -57.4135,
      32.9755,
      36.75,
      -56.4136,
      32.972,
      36.75,
    ],
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
  },
  {
    ID: "9.OG_Kn-L_1_2",
    polygon: [
      -2.6062,
      103.6847,
      36.75,
      -2.6062,
      103.6847,
      39.31,
      -0.6503,
      103.8738,
      39.31,
      -0.6503,
      103.8738,
      39.31,
      -0.6503,
      103.8738,
      36.75,
      -2.6062,
      103.6847,
      36.75,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_11-12_2",
    polygon: [
      -44.3646,
      43.4923,
      33.3,
      -44.3646,
      43.4923,
      35.56,
      -44.3612,
      44.4673,
      35.56,
      -44.3612,
      44.4673,
      35.56,
      -44.3612,
      44.4673,
      33.3,
      -44.3646,
      43.4923,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "9.OG_Jn-Kn_1_1",
    polygon: [
      -7.3445,
      102.3168,
      36.75,
      -7.3445,
      102.3168,
      39.31,
      -7.3408,
      103.3918,
      39.31,
      -7.3408,
      103.3918,
      39.31,
      -7.3408,
      103.3918,
      36.75,
      -7.3445,
      102.3168,
      36.75,
    ],
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_An_9-10_2",
    polygon: [
      -55.1109,
      55.3451,
      36.75,
      -55.1109,
      55.3451,
      39.59,
      -55.0268,
      57.6946,
      39.59,
      -55.0268,
      57.6946,
      39.59,
      -55.0268,
      57.6946,
      36.75,
      -55.1109,
      55.3451,
      36.75,
    ],
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Kn-L_1_1",
    polygon: [
      -1.2436,
      103.7759,
      33.3,
      -1.2436,
      103.7759,
      35.56,
      0.7123,
      103.965,
      35.56,
      0.7123,
      103.965,
      35.56,
      0.7123,
      103.965,
      33.3,
      -1.2436,
      103.7759,
      33.3,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_M-N_1_2",
    polygon: [
      7.9994,
      104.7103,
      36.75,
      7.9994,
      104.7103,
      39.31,
      9.9552,
      104.8995,
      39.31,
      9.9552,
      104.8995,
      39.31,
      9.9552,
      104.8995,
      36.75,
      7.9994,
      104.7103,
      36.75,
    ],
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_O-P_1_2",
    polygon: [
      17.5982,
      104.1366,
      36.75,
      17.5982,
      104.1366,
      39.31,
      20.0728,
      104.3759,
      39.31,
      20.0728,
      104.3759,
      39.31,
      20.0728,
      104.3759,
      36.75,
      17.5982,
      104.1366,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "8.OG_Bn-Cn_13-14_2",
    polygon: [
      -46.8002,
      33.2858,
      32.72,
      -46.8002,
      33.2858,
      35.54,
      -46.7927,
      35.4358,
      35.54,
      -46.7927,
      35.4358,
      35.54,
      -46.7927,
      35.4358,
      32.72,
      -46.8002,
      33.2858,
      32.72,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "8.OG_Jn-Kn_4-5_2",
    polygon: [
      -7.4183,
      82.5589,
      33.3,
      -7.4183,
      82.5589,
      35.56,
      -7.4115,
      84.5089,
      35.56,
      -7.4115,
      84.5089,
      35.56,
      -7.4115,
      84.5089,
      33.3,
      -7.4183,
      82.5589,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "9.OG_An_9-10_3",
    polygon: [
      -55.6108,
      55.0654,
      39.59,
      -55.6108,
      55.0694,
      36.75,
      -56.6227,
      55.0729,
      39.59,
      -56.6227,
      55.0729,
      39.59,
      -56.6227,
      55.0729,
      36.75,
      -55.6108,
      55.0654,
      39.59,
    ],
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_On-O_2n-3_1",
    polygon: [
      16.9645,
      93.384,
      36.75,
      16.9645,
      93.384,
      39.31,
      16.0795,
      93.3871,
      39.31,
      16.0795,
      93.3871,
      39.31,
      16.0795,
      93.3871,
      36.75,
      16.9645,
      93.384,
      36.75,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
  },
  {
    ID: "9.OG_Bn-Cn_5-6_1",
    polygon: [
      -44.1941,
      78.4469,
      36.94,
      -44.1941,
      78.4469,
      39.01,
      -44.1915,
      79.1969,
      39.01,
      -44.1915,
      79.1969,
      39.01,
      -44.1915,
      79.1969,
      36.94,
      -44.1941,
      78.4469,
      36.94,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "8.OG_O-P_1_1",
    polygon: [
      20.0137,
      101.7534,
      33.3,
      20.0137,
      101.7534,
      35.56,
      20.0162,
      102.4784,
      35.56,
      20.0162,
      102.4784,
      35.56,
      20.0162,
      102.4784,
      33.3,
      20.0137,
      101.7534,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "8.OG_Bn-Cn_11-12_3",
    polygon: [
      -44.3606,
      44.6673,
      33.3,
      -44.3606,
      44.6673,
      35.56,
      -44.3572,
      45.6423,
      35.56,
      -44.3572,
      45.6423,
      35.56,
      -44.3572,
      45.6423,
      33.3,
      -44.3606,
      44.6673,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
  },
  {
    ID: "9.OG_O-P_1_3",
    polygon: [
      20.0637,
      101.7508,
      36.75,
      20.0637,
      101.7508,
      39.31,
      20.0662,
      102.4808,
      39.31,
      20.0662,
      102.4808,
      39.31,
      20.0662,
      102.4808,
      36.75,
      20.0637,
      101.7508,
      36.75,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
  {
    ID: "9.OG_An_9-10_4",
    polygon: [
      -56.712,
      52.5785,
      36.75,
      -56.712,
      52.5785,
      39.59,
      -56.6227,
      55.0729,
      39.59,
      -56.6227,
      55.0729,
      39.59,
      -56.6227,
      55.0729,
      36.75,
      -56.712,
      52.5785,
      36.75,
    ],
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
    "04-15 15:00": 1,
    "04-15 15:15": 1,
    "04-15 15:30": 1,
    "04-15 15:45": 1,
    "04-15 16:00": 1,
    "04-15 16:15": 1,
    "04-15 16:30": 1,
    "04-15 16:45": 1,
    "04-15 17:00": 1,
    "04-15 17:15": 1,
    "04-15 17:30": 1,
    "04-15 17:45": 1,
  },
  {
    ID: "9.OG_N-On_2n-3_1",
    polygon: [
      15.213,
      91.5001,
      36.75,
      15.213,
      91.5001,
      39.31,
      15.2179,
      92.9151,
      39.31,
      15.2179,
      92.9151,
      39.31,
      15.2179,
      92.9151,
      36.75,
      15.213,
      91.5001,
      36.75,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
  },
  {
    ID: "8.OG_Bn-Cn_9-10_1",
    polygon: [
      -46.7331,
      52.5406,
      32.72,
      -46.7331,
      52.5406,
      35.54,
      -46.7257,
      54.6906,
      35.54,
      -46.7257,
      54.6906,
      35.54,
      -46.7257,
      54.6906,
      32.72,
      -46.7331,
      52.5406,
      32.72,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
  },
  {
    ID: "8.OG_Jn-Kn_4n-4_1",
    polygon: [
      -7.4087,
      85.3089,
      33.3,
      -7.4087,
      85.3089,
      35.56,
      -7.402,
      87.2588,
      35.56,
      -7.402,
      87.2588,
      35.56,
      -7.402,
      87.2588,
      33.3,
      -7.4087,
      85.3089,
      33.3,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
  },
  {
    ID: "9.OG_Bn-Cn_4-5_1",
    polygon: [
      -44.1879,
      80.2362,
      36.94,
      -44.1879,
      80.2362,
      39.01,
      -44.1853,
      80.9862,
      39.01,
      -44.1853,
      80.9862,
      39.01,
      -44.1853,
      80.9862,
      36.94,
      -44.1879,
      80.2362,
      36.94,
    ],
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "9.OG_N-On_2n-3_2",
    polygon: [
      15.213,
      91.5001,
      36.75,
      15.213,
      91.5001,
      39.31,
      13.843,
      91.5049,
      39.31,
      13.843,
      91.5049,
      39.31,
      13.843,
      91.5049,
      36.75,
      15.213,
      91.5001,
      36.75,
    ],
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
    "04-15 14:00": 1,
    "04-15 14:15": 1,
    "04-15 14:30": 1,
    "04-15 14:45": 1,
  },
  {
    ID: "8.OG_O-P_1_2",
    polygon: [
      20.0198,
      103.5134,
      33.3,
      20.0198,
      103.5134,
      35.56,
      20.0236,
      104.5884,
      35.56,
      20.0236,
      104.5884,
      35.56,
      20.0236,
      104.5884,
      33.3,
      20.0198,
      103.5134,
      33.3,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
  },
  {
    ID: "9.OG_N-On_2n-3_3",
    polygon: [
      13.028,
      91.5077,
      36.75,
      13.028,
      91.5077,
      39.31,
      12.303,
      91.5102,
      39.31,
      12.303,
      91.5102,
      39.31,
      12.303,
      91.5102,
      36.75,
      13.028,
      91.5077,
      36.75,
    ],
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
    "04-15 09:45": 1,
    "04-15 10:00": 1,
    "04-15 10:15": 1,
    "04-15 10:30": 1,
    "04-15 10:45": 1,
    "04-15 11:00": 1,
    "04-15 11:15": 1,
    "04-15 11:30": 1,
    "04-15 11:45": 1,
    "04-15 12:00": 1,
    "04-15 12:15": 1,
    "04-15 12:30": 1,
    "04-15 12:45": 1,
    "04-15 13:00": 1,
    "04-15 13:15": 1,
    "04-15 13:30": 1,
    "04-15 13:45": 1,
  },
  {
    ID: "9.OG_An-Bn_9-10_1",
    polygon: [
      -48.3735,
      55.3014,
      36.19,
      -48.3735,
      55.3014,
      39.57,
      -48.366,
      57.4514,
      39.57,
      -48.366,
      57.4514,
      39.57,
      -48.366,
      57.4514,
      36.19,
      -48.3735,
      55.3014,
      36.19,
    ],
    "04-15 04:30": 1,
    "04-15 04:45": 1,
    "04-15 05:00": 1,
    "04-15 05:15": 1,
    "04-15 05:30": 1,
    "04-15 05:45": 1,
    "04-15 06:00": 1,
    "04-15 06:15": 1,
    "04-15 06:30": 1,
    "04-15 06:45": 1,
    "04-15 07:00": 1,
    "04-15 07:15": 1,
    "04-15 07:30": 1,
    "04-15 07:45": 1,
    "04-15 08:00": 1,
    "04-15 08:15": 1,
    "04-15 08:30": 1,
    "04-15 08:45": 1,
    "04-15 09:00": 1,
    "04-15 09:15": 1,
    "04-15 09:30": 1,
  },
];

export default project52ShaindgProgression;
