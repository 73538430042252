import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  defaultValue: { checkStatus: [false, false, false, false, false] },
  value: { checkStatus: [false, false, false, false, false] },
  min: 0,
  minOne: 0,
  avg: 0,
  avgOne: 0,
  max: 0,
  rangeList: [0, 0, 0, 0, 0, 0, 0, 0],
  isLoading: false,
  sensorsData: {
    sensorName: null,
    sunshineHours: null,
    directEnergyValue: null,
    diffuseEnergyValue: null,
    globalEnergyValue: null,
    coordinateX: null,
    coordinateY: null,
    coordinateZ: null,
  },
  clickedSensor: null,
  showSensorInfoBox: false,
  coordinate: { x: null, y: null },
};

const sensorSolarsSlice = createSlice({
  name: "sensorSolars",
  initialState,
  reducers: {
    // toggle: (state, action) => {
    //   state.value.checkStatus[action.payload] = !state.value.checkStatus[
    //     action.payload
    //   ];
    // },
    toggle: (state, action) => {
      const selected = !state.value.checkStatus[action.payload];
      if (selected)
        state.value.checkStatus = [false, false, false, false, false];
      state.value.checkStatus[action.payload] = selected;
    },
    hide: (state) => {
      state.value = false;
    },
    updateRangeSensorSolar: (state, action) => {
      state.rangeList[0] = action.payload[0];
      state.rangeList[1] = action.payload[1];
      state.rangeList[2] = action.payload[2];
      state.rangeList[3] = action.payload[3];
      state.rangeList[4] = action.payload[4];
      state.rangeList[5] = action.payload[5];
      state.rangeList[6] = action.payload[6];
      state.rangeList[7] = action.payload[7];
    },
    updateClickedSolarSensor: (state, action) => {
      state.clickedSensor = action.payload
    },
    updateVirtualSensorInfo: (state, action) => {
      state.sensorsData.sensorName = action.payload[0];
      state.sensorsData.sunshineHours = action.payload[1];
      state.sensorsData.directEnergyValue = action.payload[2];
      state.sensorsData.diffuseEnergyValue = action.payload[3];
      state.sensorsData.globalEnergyValue = action.payload[4];
      state.sensorsData.coordinateX = action.payload[5];
      state.sensorsData.coordinateY = action.payload[6];
      state.sensorsData.coordinateZ = action.payload[7];
    },
    setCoordinate: (state, action) => {
      state.coordinate.x = action.payload[0];
      state.coordinate.y = action.payload[1];
    },
    toggleSensorsVirtualBox: (state) => {
      state.showSensorInfoBox = true;
    },

    disappearSensorsVirtualBox: (state) => {
      state.showSensorInfoBox = false;
    },
    setDefaultVirtualSensors: (state) => {
      state.value = state.defaultValue;
      state.showSensorInfoBox = false;
    },
    resetAllVirtualSensorsValues: (state) => {
      state.value.checkStatus = state.value.checkStatus.map(() => false);
    },
    setSensorsIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  toggle,
  updateRangeSensorSolar,
  updateVirtualSensorInfo,
  toggleSensorsVirtualBox,
  setCoordinate,
  disappearSensorsVirtualBox,
  setDefaultVirtualSensors,
  resetAllVirtualSensorsValues,
  setSensorsIsLoading,
  updateClickedSolarSensor
} = sensorSolarsSlice.actions;

export default sensorSolarsSlice.reducer;
