import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateAxisValues, generateNumbers } from "../utils";
import ColorBarComponent, { CustomColorBar } from "./ColorBarComponent";
import "./colorBar.css";
import { setColorbar } from "../../redux/colorbars";
import ColorbarSetRange from "./CustomizeColorbar";
import { useEnergyDataColorBar } from "../CustomHooks";
const calculateStep = (min, max, percentage) => {
  const range = max - min;
  let step = (range * percentage) / 100;
  step = parseFloat(step.toFixed(2));
  return Math.max(step, 0.0001);
};

function extractTextInsideBrackets(str) {
  const matches = str?.match(/\[(.*?)\]/);
  const extractedText = matches ? matches[1] : null;
  if (extractedText === "h") {
    return "hours";
  }
  return extractedText;
}

function roundToNearest50(n) {
  if (n === undefined) {
    return undefined;
  }

  if (n < 1) {
    return n;
  }

  return Math.ceil(n / 50) * 50;
}

const ColorBarEnergyData = () => {
  const { dataMax, dataMin, isVisible, subcategory } = useEnergyDataColorBar();
  return (
    <CustomColorBar
      colorbarClassName="color-bar-energy"
      category="Energy Data"
      subcategory={subcategory}
      unit={extractTextInsideBrackets(subcategory)}
      dataMin={dataMin}
      dataMax={dataMax}
      isVisible={isVisible}
    />
  );
};

export default ColorBarEnergyData;
