import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  LineChart,
  Line,
  RadarChart,
  Radar,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import "./InfoBoxDashboard.css";
import { hideFirstSensor } from "../../redux/infoWindRisk";
import { PieChart, Pie, Cell } from "recharts";
import { generateColor, sensorPlacementScale } from "../utils";
import InfoBoxDashboardContainer from "./InfoBoxDashboard";

// Constants
const CHART_WIDTH = 400;
const CHART_HEIGHT = 200;
const RADAR_CHART_DIMENSIONS = { height: 200, width: 220, outerRadius: "80%" };

const SensorPlacementInfoBox = () => {
  // Redux Selectors
  const sensorLevel = useSelector((state) => state.infoWindRisk.sensorLevel);
  const sensorPlacementSelector = useSelector(
    (state) => state.infoWindRisk.firstSensorPlacementIsShowing
  );

  const windRiskSelector = useSelector((state) => state.shadingSystem.value);
  const wholeSensorData = useSelector(
    (state) => state.infoWindRisk.sensorPlacementData
  );

  const sensorPlacementIsAvailable = useSelector(
    (state) => state.sceneVar.sensorPlacement
  );
  // Data processing logic
  const o1 = [],
    radarChartList = [],
    uzFactorList = [];
  const listKey = Array.from({ length: 24 }, (_, i) => String(i * 15));
  const listKey225 = [
    "0",
    "22.5",
    "45.0",
    "67.5",
    "90.0",
    "112.5",
    "135.0",
    "157.5",
    "180.0",
    "202.5",
    "225.0",
    "247.5",
    "270.0",
    "292.5",
    "315.0",
    "337.5",
  ];

  for (const [key, value] of Object.entries(wholeSensorData)) {
    if ([...listKey, ...listKey225].includes(key)) {
      o1.push({
        direction: key,
        Simulated: parseFloat(key) + value.direction[1],
        Ideal: key,
      });
      radarChartList.push({ direction: key, velocity: value.velocity[1] });
      uzFactorList.push({
        direction: key,
        uzValue: 1 - parseFloat(value["Uz factor"]),
      });
    }
  }
  o1?.sort((a, b) => Number(a?.direction) - Number(b?.direction));
  uzFactorList?.sort((a, b) => Number(a?.direction) - Number(b?.direction));
  radarChartList?.sort((a, b) => Number(a?.direction) - Number(b?.direction));

  const dispatch = useDispatch();
  const captureFrameRef = useRef()
  return (
    (windRiskSelector.checkStatus[3] || windRiskSelector.checkStatus[4]) &&
    sensorPlacementSelector &&
    sensorPlacementIsAvailable && (
      <InfoBoxDashboardContainer handleClickOutside={() => dispatch(hideFirstSensor())}
        needsCapture
        captureProps={{
          targetElement: () => captureFrameRef?.current,
        }}
        style={{ display: 'block' }}
      >
        <div style={{
          position: 'absolute',
          padding: '20px',
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr 1fr',
          gap: '20px',
        }}
          ref={captureFrameRef}
        >

          <DonoughtSection
            title={"Sensor ID: " + wholeSensorData.id.substring(4)}
            color={generateColor(
              wholeSensorData["overall score"],
              0,
              1,
              sensorPlacementScale
            )}
            value={wholeSensorData["overall score"]}
          />
          <ChartSection title="Wind direction distribution" data={o1} />
          <UZFactorSection
            title="UZ factor deviation in different direction"
            data={uzFactorList}
          />
          <RadarSection title="Average wind speed" data={radarChartList} />
        </div>
      </InfoBoxDashboardContainer>
    )
  );
};

const ChartSection = ({ title, data }) => (
  <div className="chart-section">
    <p className="chart-title">{title}</p>
    <LineChart width={CHART_WIDTH} height={CHART_HEIGHT} data={data}>
      <XAxis dataKey="direction" />
      <YAxis />
      <Tooltip />
      <Line
        type="monotone"
        dataKey="Simulated"
        stroke="#e60000"
        fill="#990000"
        activeDot={{ r: 8 }}
      />
      <Line type="monotone" dataKey="Ideal" stroke="#0000ff" fill="#005ce6" />
      <CartesianGrid strokeDasharray="3 3" />
      <Legend />
    </LineChart>
  </div>
);

const RadarSection = ({ title, data }) => (
  <div className="radar-section">
    <p className="chart-title">{title}</p>
    <RadarChart {...RADAR_CHART_DIMENSIONS} data={data}>
      <PolarGrid />
      <PolarAngleAxis dataKey="direction" tick={{ fontSize: 11 }} />
      <PolarRadiusAxis domain={[0, 1.4]} />
      <Radar
        dataKey="velocity"
        stroke="#0b5e04"
        fill="#39b32e"
        fillOpacity={0.7}
      />
    </RadarChart>
  </div>
);

const UZFactorSection = ({ title, data }) => (
  <div className="uz-factor-section">
    <p className="chart-title">{title}</p>
    <LineChart width={CHART_WIDTH} height={CHART_HEIGHT} data={data}>
      <XAxis dataKey="direction" />
      <YAxis type="number" domain={[0, 0.99]} />
      <Tooltip />
      <Line
        type="monotone"
        dataKey="uzValue"
        stroke="#0000ff"
        fill="#005ce6"
        activeDot={{ r: 10 }}
      />
      <CartesianGrid strokeDasharray="3 3" />
      <Legend />
    </LineChart>
  </div>
);

const renderCustomizedLabel = ({ cx, cy, color, value }) => {
  return (
    <text x={cx} y={cy} fill={color} textAnchor="middle">
      {(100 * value).toFixed(1)}%
    </text>
  );
};

const DonoughtSection = ({ title, color, value }) => {
  const data = [
    { name: "Quality", value, color },
    { name: "Rest", value: 1 - value, color: "#fff" },
  ];
  const cx = 200;
  const cy = 100;
  const iR = 60;
  const oR = 90;

  return (
    <div style={{ textAlign: "center" }}>
      <p className="">{title}</p>
      <PieChart width={400} height={200}>
        <Pie
          dataKey="value"
          label={(props) => renderCustomizedLabel({ ...props, color, value })}
          labelLine={false}
          startAngle={360}
          endAngle={0}
          data={data}
          cx={cx}
          cy={cy}
          innerRadius={iR}
          outerRadius={oR}
          stroke="none"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} stroke="#777" />
          ))}
        </Pie>
      </PieChart>
    </div>
  );
};
export default SensorPlacementInfoBox;
