import React from "react";
import "../NavBar.css";
import { Dropdown, Checkbox } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { toggleInfoBpx } from "../../redux/infoBoxGeneral";
import {
  toggleFloor,
  showRoom,
  updateSelectedFloor,
} from "../../redux/floorVisibility";
import { useSelector, useDispatch } from "react-redux";
import { selectAllFloor, deselectAllFloor } from "../../redux/floorVisibility";
import { Button } from "@mui/material";

const DropDownFloors = (props) => {
  const dispatch = useDispatch();

  const visibilityFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );

  var inputData = props.data;
  const callToActionSelectAll = () => {
    for (let i = 0; i < inputData.length; i++) {
      dispatch(selectAllFloor([i]));
    }
  };

  const callToActionDeSelectAll = () => {
    for (let i = 0; i < inputData.length; i++) {
      dispatch(deselectAllFloor([i]));
    }
  };

  return (
    <Dropdown item simple text="floors selection" className="drop-down">
      <Dropdown.Menu className="kkk">
        <Dropdown.Item onClick={() => callToActionSelectAll()}>
          <Button variant="outlined">Select All</Button>
        </Dropdown.Item>

        <Dropdown.Item onClick={() => callToActionDeSelectAll()}>
          <Button variant="outlined" color="primary">
            Deselect All
          </Button>
        </Dropdown.Item>
        {inputData.map((singleFloor, id) => {
          return (
            <Dropdown.Item
              onClick={() => {
                dispatch(updateSelectedFloor([id]));
                // dispatch(toggleFloor([singleFloor.id, 0]));
                // dispatch(showRoom([eachFloorsComponent, props.parentIndex]));
              }}
            >
              <Checkbox checked={visibilityFloor[id]} label={singleFloor} />
              <button
                style={{ background: "none", border: "none" }}
                // onClick={() => {
                //   dispatch(toggleInfoBpx([eachFloorsComponent]));
                // }}
              >
                <i
                  class="info circle icon"
                  style={{ marginLeft: "5px", color: "blue" }}
                ></i>
              </button>
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownFloors;
