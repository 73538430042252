import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: [true, true, false, true, true, true, true, true, true, true, false],
  default: [true, true, false, true, true, true, true, true, true, true, false],
  // value: Array(11).fill(true),
  surroundingValue: Array(4).fill(true),
  loading: [
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ],
  roomsFilter: {
    isByDetails: false,
    selectedRoomNames: [],
    selectedRoomDetails: [],
  },
  isMenuOpen: true,
  pvFilter: {}
};

const sceneSlice = createSlice({
  name: "sceneVisibility",
  initialState,
  reducers: {
    setloading: (state, action) => {
      state.loading[action.payload.index] = action.payload.value;
    },
    show: (state, action) => {
      state.value[action.payload] = !state.value[action.payload];
    },
    hide: (state) => {
      state.value = Array(state.value.length).fill(false);
    },
    setDefault: (state) => {
      state.value = initialState.value;
      state.surroundingValue = initialState.surroundingValue;
    },
    setDefaultTarget: (state) => {
      state.value = initialState.value;
    },
    showSurroungding: (state, action) => {
      state.surroundingValue[action.payload] = !state.surroundingValue[
        action.payload
      ];
    },
    setTargetElementShow: (state, { payload }) => {
      const { index, value } = payload;
      state.value[index] = value;
    },
    selectAllTarget: (state) => {
      state.value = Array(state.value.length).fill(true);
      for (const [scenario, _isShown] of Object.entries(state.pvFilter))
        state.pvFilter[scenario] = true
    },
    deselectAllTarget: (state) => {
      state.value = Array(state.value.length).fill(false);
      for (const [scenario, _isShown] of Object.entries(state.pvFilter))
        state.pvFilter[scenario] = false
    },
    selectAllSurrounding: (state) => {
      state.surroundingValue = Array(state.surroundingValue.length).fill(true);
    },
    deselectAllSurrounding: (state) => {
      state.surroundingValue = Array(state.surroundingValue.length).fill(false);
    },
    setIsRoomFilterByDetails: (state, action) => {
      state.roomsFilter = { ...state.roomsFilter, isByDetails: action.payload };
    },
    setSelectedRoomsNames: (state, action) => {
      state.roomsFilter = {
        ...state.roomsFilter,
        selectedRoomNames: action.payload,
      };
    },
    setSelectedRoomsDetails: (state, action) => {
      state.roomsFilter = {
        ...state.roomsFilter,
        selectedRoomDetails: action.payload,
      };
    },

    setIsMenuOpen: (state, action) => {
      state.isMenuOpen = action.payload;
    },
    updatePVPanelFilter: (state, action) => {
      state.pvFilter = { ...state.pvFilter, ...action.payload }
    },
    selectAllPVs: (state, action) => {
      for (const [scenario, _isShown] of Object.entries(state.pvFilter))
        state.pvFilter[scenario] = true
    },
    deselectAllPVs: (state, action) => {
      for (const [scenario, _isShown] of Object.entries(state.pvFilter))
        state.pvFilter[scenario] = false
    },

  },
});

export const {
  // toggleVisibility,
  show,
  hide,
  setDefault,
  showSurroungding,
  // toggleSurroundingVisibility,
  selectAllTarget,
  deselectAllTarget,
  selectAllSurrounding,
  deselectAllSurrounding,
  setloading,
  setTargetElementShow,
  setDefaultTarget,
  setIsRoomFilterByDetails,
  setSelectedRoomsNames,
  setIsMenuOpen,
  setSelectedRoomsDetails,
  updatePVPanelFilter,
  selectAllPVs,
  deselectAllPVs
} = sceneSlice.actions;

export default sceneSlice.reducer;
