const zoneOne = [
  {
    id: "IDN:5821.0",
    zone: 1,
    height: 3,
    position: [-99.2087783169, 26.0524200652, 22.2000007629],
    "overall score": 0.6104572309,
    0: {
      velocity: [1.2076085779, 1.2187605685, 1.2339674241],
      direction: [-7.2134126177, -6.4619764982, -5.7520494335],
      "speed factor": 1.2908013864,
      "Uz factor": 0.9997119715,
      "Directional variance factor": 0.425602089,
      "Velocity stability factor": 0.9784954255,
      "Direction stability factor": 0.9959406578,
      score: 0.8489947076,
    },
    15: {
      velocity: [0.0808508095, 0.5027797597, 0.9110764821],
      direction: [-154.5784617733, -22.4454076084, 166.0974420074],
      "speed factor": 0.4995270238,
      "Uz factor": 0.9999995107,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3133027473,
      "Direction stability factor": 0.1092336006,
      score: 0.0,
    },
    30: {
      velocity: [0.5220165984, 0.9603663114, 1.1768313844],
      direction: [-21.1100595114, -1.2237775938, 12.7719855492],
      "speed factor": 1.1102290915,
      "Uz factor": 0.9994644664,
      "Directional variance factor": 0.8912197694,
      "Velocity stability factor": 0.5177243786,
      "Direction stability factor": 0.9058832082,
      score: 0.8051776411,
    },
    45: {
      velocity: [0.5104134955, 0.8542475841, 1.1186247897],
      direction: [-20.3102931174, 3.9720371601, 29.177323651],
      "speed factor": 0.9301277131,
      "Uz factor": 0.9981593049,
      "Directional variance factor": 0.6469300302,
      "Velocity stability factor": 0.5293446737,
      "Direction stability factor": 0.8625343979,
      score: 0.0,
    },
    60: {
      velocity: [0.2435398584, 0.7329392247, 1.1035743035],
      direction: [-29.6368798001, 0.2623569626, 33.9090054273],
      "speed factor": 0.7798007836,
      "Uz factor": 0.9989237136,
      "Directional variance factor": 0.9766793811,
      "Velocity stability factor": 0.3037627086,
      "Direction stability factor": 0.8234836521,
      score: 0.0,
    },
    75: {
      velocity: [0.3106641952, 0.6999873162, 1.1248701221],
      direction: [-39.4890168123, -3.9340847005, 44.5851076213],
      "speed factor": 0.8745490861,
      "Uz factor": 0.9980929888,
      "Directional variance factor": 0.6503035822,
      "Velocity stability factor": 0.3872336993,
      "Direction stability factor": 0.7664607655,
      score: 0.0,
    },
    90: {
      velocity: [0.1352348843, 0.355147751, 0.7812841639],
      direction: [-70.1178771925, -8.3429056296, 83.7899090423],
      "speed factor": 0.4040095874,
      "Uz factor": 0.9911872673,
      "Directional variance factor": 0.2584083885,
      "Velocity stability factor": 0.452671446,
      "Direction stability factor": 0.5724783716,
      score: 0.0,
    },
    105: {
      velocity: [0.3675956585, 0.8327298256, 1.1116063049],
      direction: [-28.9222051507, -1.0659483241, 24.9637376737],
      "speed factor": 1.3372322287,
      "Uz factor": 0.999339203,
      "Directional variance factor": 0.9052490379,
      "Velocity stability factor": 0.4164074301,
      "Direction stability factor": 0.8503168255,
      score: 0.7555725297,
    },
    120: {
      velocity: [0.3071870656, 1.0348982978, 1.1766980719],
      direction: [-33.4381505657, 3.1403921147, 16.2487472033],
      "speed factor": 1.2625329268,
      "Uz factor": 0.9947614233,
      "Directional variance factor": 0.7208540342,
      "Velocity stability factor": 0.3337977551,
      "Direction stability factor": 0.8619808395,
      score: 0.6946533671,
    },
    135: {
      velocity: [0.777920258, 0.9997246738, 1.1613170334],
      direction: [-13.6439584484, 2.554640303, 17.4109479925],
      "speed factor": 1.2290020507,
      "Uz factor": 0.9898518999,
      "Directional variance factor": 0.772920862,
      "Velocity stability factor": 0.7379120602,
      "Direction stability factor": 0.913736371,
      score: 0.834576416,
    },
    150: {
      velocity: [0.5660171988, 0.9798157647, 1.2071457784],
      direction: [-21.7801940323, 0.6726608208, 18.0044153174],
      "speed factor": 1.3890296556,
      "Uz factor": 0.984270476,
      "Directional variance factor": 0.940207927,
      "Velocity stability factor": 0.5564739255,
      "Direction stability factor": 0.8894871963,
      score: 0.8189140613,
    },
    165: {
      velocity: [0.6611518283, 0.8671791152, 1.0981871124],
      direction: [-11.7641466077, -2.3185702696, 5.2850071593],
      "speed factor": 1.0980558939,
      "Uz factor": 0.9843450287,
      "Directional variance factor": 0.7939048649,
      "Velocity stability factor": 0.6413455164,
      "Direction stability factor": 0.9526412395,
      score: 0.8351332151,
    },
    180: {
      velocity: [0.7714179294, 1.0484223051, 1.2315590649],
      direction: [-14.2169079723, -4.6974078256, 1.8890762741],
      "speed factor": 1.5650032995,
      "Uz factor": 0.9757951213,
      "Directional variance factor": 0.5824526377,
      "Velocity stability factor": 0.6567752021,
      "Direction stability factor": 0.9552611549,
      score: 0.7874375374,
    },
    195: {
      velocity: [1.0893780464, 1.2480977164, 1.2890930793],
      direction: [-12.8912998785, -8.5475240092, -7.2943868619],
      "speed factor": 1.6762168478,
      "Uz factor": 0.9610884601,
      "Directional variance factor": 0.2402200881,
      "Velocity stability factor": 0.8409619201,
      "Direction stability factor": 0.9844530194,
      score: 0.7625220117,
    },
    210: {
      velocity: [0.6380358882, 1.1267189761, 1.2030982646],
      direction: [-21.7210583785, -6.6390248717, -1.8722233842],
      "speed factor": 1.0545813674,
      "Uz factor": 0.9743656286,
      "Directional variance factor": 0.4098644559,
      "Velocity stability factor": 0.5720375656,
      "Direction stability factor": 0.9448643472,
      score: 0.717907679,
    },
    225: {
      velocity: [0.361709865, 0.966730052, 1.1245165338],
      direction: [-27.2018720986, -5.3111529241, 0.0469903334],
      "speed factor": 1.1106032805,
      "Uz factor": 0.9880456389,
      "Directional variance factor": 0.5278975179,
      "Velocity stability factor": 0.3325475847,
      "Direction stability factor": 0.9243087155,
      score: 0.6772656334,
    },
    240: {
      velocity: [1.0768243335, 1.0982357788, 1.1147096856],
      direction: [-8.2089977424, -7.4417880118, -6.6444386151],
      "speed factor": 1.3386505272,
      "Uz factor": 0.9822560675,
      "Directional variance factor": 0.3385077323,
      "Velocity stability factor": 0.9690241329,
      "Direction stability factor": 0.9956540024,
      score: 0.8247099675,
    },
    225: {
      velocity: [1.0975620515, 1.1079415795, 1.1176969089],
      direction: [-2.51700676, -2.0818730049, -1.709260939],
      "speed factor": 1.434684203,
      "Uz factor": 0.9950097857,
      "Directional variance factor": 0.8149446218,
      "Velocity stability factor": 0.9825102289,
      "Direction stability factor": 0.9977562616,
      score: 0.9482418435,
    },
    270: {
      velocity: [1.0660008422, 1.0889778376, 1.1149823481],
      direction: [2.474975079, 5.2979309513, 7.545063333],
      "speed factor": 1.5912713998,
      "Uz factor": 0.9980108359,
      "Directional variance factor": 0.5290728043,
      "Velocity stability factor": 0.9592739985,
      "Direction stability factor": 0.9859164215,
      score: 0.8650449115,
    },
    285: {
      velocity: [1.1291978949, 1.1542706229, 1.1783768241],
      direction: [13.3817455101, 13.915238016, 14.5403780237],
      "speed factor": 1.2595909612,
      "Uz factor": 0.9945319663,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9600610267,
      "Direction stability factor": 0.9967815764,
      score: 0.0,
    },
    300: {
      velocity: [0.7820531662, 0.8795506797, 0.9360351407],
      direction: [6.7386299568, 7.808063843, 9.1595024951],
      "speed factor": 1.1272409584,
      "Uz factor": 0.9729265786,
      "Directional variance factor": 0.3059498806,
      "Velocity stability factor": 0.86496361,
      "Direction stability factor": 0.9932753541,
      score: 0.7893660497,
    },
    315: {
      velocity: [1.0945675406, 1.139538261, 1.1968382517],
      direction: [7.4940441404, 8.1302142442, 8.739766239],
      "speed factor": 1.2861033162,
      "Uz factor": 0.997076868,
      "Directional variance factor": 0.2773142894,
      "Velocity stability factor": 0.9077829163,
      "Direction stability factor": 0.9965396608,
      score: 0.7945441318,
    },
    330: {
      velocity: [0.9483908895, 1.0209996813, 1.0840086184],
      direction: [-5.8434483842, 1.937183154, 9.124395591],
      "speed factor": 1.1671341652,
      "Uz factor": 0.9899117126,
      "Directional variance factor": 0.8278059419,
      "Velocity stability factor": 0.9019074663,
      "Direction stability factor": 0.9584226556,
      score: 0.9116396798,
    },
    345: {
      velocity: [0.9358477419, 0.977566288, 1.016986339],
      direction: [-1.2909705404, -0.1510591786, 0.7444897686],
      "speed factor": 1.0179916648,
      "Uz factor": 0.9939213426,
      "Directional variance factor": 0.9865725175,
      "Velocity stability factor": 0.9418242244,
      "Direction stability factor": 0.9943459436,
      score: 0.9792721573,
    },
  },
  {
    id: "IDN:5719.0",
    zone: 1,
    height: 3,
    position: [-102.2888764994, 27.3161746569, 22.2000007629],
    "overall score": 0.6023170535,
    0: {
      velocity: [1.2076648284, 1.221809195, 1.2360758726],
      direction: [-7.723775851, -7.11172743, -6.4891014063],
      "speed factor": 1.2940302169,
      "Uz factor": 0.999897159,
      "Directional variance factor": 0.3678464507,
      "Velocity stability factor": 0.9768211624,
      "Direction stability factor": 0.9965703488,
      score: 0.8344520777,
    },
    15: {
      velocity: [0.1723392435, 0.6927177092, 1.1248456156],
      direction: [-78.0706704876, -10.1224246831, 39.7936272462],
      "speed factor": 0.6882361689,
      "Uz factor": 0.9953287525,
      "Directional variance factor": 0.1002289171,
      "Velocity stability factor": 0.2121617887,
      "Direction stability factor": 0.672599173,
      score: 0.0,
    },
    30: {
      velocity: [0.4757932532, 0.9244282459, 1.1692906278],
      direction: [-47.9459288034, -0.8371742834, 21.2682524063],
      "speed factor": 1.0686829801,
      "Uz factor": 0.9995828405,
      "Directional variance factor": 0.9255845081,
      "Velocity stability factor": 0.4892343845,
      "Direction stability factor": 0.8077383855,
      score: 0.7575739159,
    },
    45: {
      velocity: [0.5766742007, 0.849419397, 1.1247144488],
      direction: [-23.274777292, 6.4211188049, 42.8965764699],
      "speed factor": 0.9248706533,
      "Uz factor": 0.9992473287,
      "Directional variance factor": 0.429233884,
      "Velocity stability factor": 0.575907149,
      "Direction stability factor": 0.816190684,
      score: 0.0,
    },
    60: {
      velocity: [0.4204657129, 0.7500569321, 1.0826296505],
      direction: [-43.1281510787, 1.9801291764, 36.0742244435],
      "speed factor": 0.798012937,
      "Uz factor": 0.9990849902,
      "Directional variance factor": 0.8239885177,
      "Velocity stability factor": 0.4639479512,
      "Direction stability factor": 0.7799934013,
      score: 0.0,
    },
    75: {
      velocity: [0.27993693, 0.6878468417, 1.1626737745],
      direction: [-37.7208690703, -4.3704602593, 27.7244257299],
      "speed factor": 0.8593810386,
      "Uz factor": 0.9978733818,
      "Directional variance factor": 0.6115146436,
      "Velocity stability factor": 0.3356577583,
      "Direction stability factor": 0.8182075144,
      score: 0.0,
    },
    90: {
      velocity: [0.140261103, 0.3946588183, 0.7618026229],
      direction: [-63.9577035279, -11.4555579179, 53.6921212296],
      "speed factor": 0.4489566551,
      "Uz factor": 0.9950627695,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4734342534,
      "Direction stability factor": 0.6731949312,
      score: 0.0,
    },
    105: {
      velocity: [0.4645395157, 0.8269572683, 1.1194602085],
      direction: [-25.2616507257, -1.6166102315, 28.7049567073],
      "speed factor": 1.3279624159,
      "Uz factor": 0.9989749099,
      "Directional variance factor": 0.8563013128,
      "Velocity stability factor": 0.4862884664,
      "Direction stability factor": 0.8500927571,
      score: 0.7606938233,
    },
    120: {
      velocity: [0.3384429747, 1.0220086689, 1.1691744714],
      direction: [-32.1104136641, 2.3868525381, 16.6267585768],
      "speed factor": 1.2468081151,
      "Uz factor": 0.9937184473,
      "Directional variance factor": 0.7878353299,
      "Velocity stability factor": 0.3635098533,
      "Direction stability factor": 0.864618966,
      score: 0.7201457788,
    },
    135: {
      velocity: [0.7592509354, 0.9881345813, 1.1699470495],
      direction: [-13.7662066048, 2.1221405341, 17.9335241017],
      "speed factor": 1.2147538804,
      "Uz factor": 0.9896605726,
      "Directional variance factor": 0.8113652859,
      "Velocity stability factor": 0.7192503816,
      "Direction stability factor": 0.9119451925,
      score: 0.8386265131,
    },
    150: {
      velocity: [0.6086265143, 0.98816907, 1.222053291],
      direction: [-22.2862809459, -0.1696294581, 15.2783715853],
      "speed factor": 1.4008716663,
      "Uz factor": 0.9863345146,
      "Directional variance factor": 0.9849218259,
      "Velocity stability factor": 0.5756377443,
      "Direction stability factor": 0.895653743,
      score: 0.837966764,
    },
    165: {
      velocity: [0.692917928, 0.9040819013, 1.1187380529],
      direction: [-14.002687154, -3.2922086933, 4.8732068252],
      "speed factor": 1.1447836357,
      "Uz factor": 0.9864539513,
      "Directional variance factor": 0.7073592273,
      "Velocity stability factor": 0.6505492747,
      "Direction stability factor": 0.9475669612,
      score: 0.8132606061,
    },
    180: {
      velocity: [0.8380306594, 1.086879089, 1.2536125008],
      direction: [-12.4708249291, -5.8192468546, -0.9235623933],
      "speed factor": 1.6224085965,
      "Uz factor": 0.9799399073,
      "Directional variance factor": 0.4827336129,
      "Velocity stability factor": 0.6900125146,
      "Direction stability factor": 0.9679242707,
      score: 0.7771486672,
    },
    195: {
      velocity: [1.0942902228, 1.2691517327, 1.3075658544],
      direction: [-14.5402613665, -10.6237199872, -9.1212671506],
      "speed factor": 1.7044927563,
      "Uz factor": 0.9714073031,
      "Directional variance factor": 0.0556693345,
      "Velocity stability factor": 0.830163276,
      "Direction stability factor": 0.9849472383,
      score: 0.7139317718,
    },
    210: {
      velocity: [0.7874009153, 1.1683727382, 1.2234015362],
      direction: [-16.6453477824, -8.5725460017, -5.1021777878],
      "speed factor": 1.0935682686,
      "Uz factor": 0.9766945404,
      "Directional variance factor": 0.237995911,
      "Velocity stability factor": 0.6697853283,
      "Direction stability factor": 0.9679356389,
      score: 0.7109131293,
    },
    225: {
      velocity: [0.4149935114, 1.0264326087, 1.1649116565],
      direction: [-31.0569247393, -6.0896290834, 5.6310688568],
      "speed factor": 1.1791910473,
      "Uz factor": 0.9900774709,
      "Directional variance factor": 0.458699637,
      "Velocity stability factor": 0.3438249851,
      "Direction stability factor": 0.8980889067,
      score: 0.6496756089,
    },
    240: {
      velocity: [1.0877556252, 1.1008758325, 1.1143757623],
      direction: [-8.4925238023, -7.8372589076, -7.1775309581],
      "speed factor": 1.3418685149,
      "Uz factor": 0.9889339931,
      "Directional variance factor": 0.3033547638,
      "Velocity stability factor": 0.9782348116,
      "Direction stability factor": 0.9963472421,
      score: 0.8185710149,
    },
    225: {
      velocity: [1.0949249746, 1.1045287227, 1.1156444204],
      direction: [-1.6409741162, -1.1870681578, -0.7602699079],
      "speed factor": 1.4302648619,
      "Uz factor": 0.9960735784,
      "Directional variance factor": 0.8944828304,
      "Velocity stability factor": 0.982002437,
      "Direction stability factor": 0.9975535994,
      score: 0.9678981166,
    },
    270: {
      velocity: [1.0605860918, 1.0836145222, 1.108801888],
      direction: [3.4893980902, 6.6377286303, 9.1657552432],
      "speed factor": 1.5834342427,
      "Uz factor": 0.9957456775,
      "Directional variance factor": 0.4099796773,
      "Velocity stability factor": 0.9599106529,
      "Direction stability factor": 0.9842323412,
      score: 0.8345887532,
    },
    285: {
      velocity: [1.1985940918, 1.2171439731, 1.2333564043],
      direction: [14.2162772071, 14.6459776136, 15.0721009429],
      "speed factor": 1.3282011312,
      "Uz factor": 0.9893128268,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9717689854,
      "Direction stability factor": 0.9976227118,
      score: 0.0,
    },
    300: {
      velocity: [1.1542123006, 1.1716274018, 1.1853816267],
      direction: [10.103172361, 10.8108469879, 11.6978027024],
      "speed factor": 1.5015694101,
      "Uz factor": 0.9700810031,
      "Directional variance factor": 0.0390358233,
      "Velocity stability factor": 0.9726656754,
      "Direction stability factor": 0.9955704713,
      score: 0.7507106103,
    },
    315: {
      velocity: [1.0818814445, 1.1301085609, 1.1809602372],
      direction: [8.2719156767, 8.6342636216, 9.0993518913],
      "speed factor": 1.2754607876,
      "Uz factor": 0.9957357562,
      "Directional variance factor": 0.2325099003,
      "Velocity stability factor": 0.9106610561,
      "Direction stability factor": 0.9977015661,
      score: 0.7846435221,
    },
    330: {
      velocity: [1.1362792804, 1.1635669716, 1.1886776218],
      direction: [2.2568276382, 3.3623196626, 4.3056902824],
      "speed factor": 1.3301069441,
      "Uz factor": 0.9798946567,
      "Directional variance factor": 0.7011271411,
      "Velocity stability factor": 0.9621001907,
      "Direction stability factor": 0.9943087149,
      score: 0.9129611904,
    },
    345: {
      velocity: [1.1052718842, 1.1235343811, 1.1402650313],
      direction: [0.6391936006, 0.950026281, 1.1924608031],
      "speed factor": 1.1699959882,
      "Uz factor": 0.9878860814,
      "Directional variance factor": 0.9155532195,
      "Velocity stability factor": 0.9749101716,
      "Direction stability factor": 0.9984631467,
      score: 0.9718474211,
    },
  },
];
export default zoneOne;
