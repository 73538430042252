import { useSelector, useDispatch } from "react-redux";
import "./configuration.css";
import React, { useRef, useEffect, useState } from "react";
// import "./UploadModal.css";
import axios from "axios";
import {
  roomConfigurationStateChanger,
  setCurrentValue,
  setCurrentValueIndex,
  setLastChosenScenario,
} from "../../redux/roomData";
import { useIsAnyColorBarVisible } from "../CustomHooks";
import { Accordion } from "react-bootstrap";
import { updateFilter } from "../../redux/sceneGlobal";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CustomTooltip } from "./EnergyDataInfoBox";
import { generateColor, roomEnergyDataInfoBoxScale } from "../utils";
import { Dropdown } from "semantic-ui-react";
import ConfigurationLayout from "./ConfigurationLayout";
import { withErrorBoundary } from "../utility/ErrorBoundary";


const RoomConfiguration = () => {
  const roomConfigurationState = useSelector(
    (store) => store.roomData.roomConfigurationState
  );
  const dispatch = useDispatch();
  const visibilityBox = useSelector(
    (state) => state.shadingConfigurationWindow.roomShowConfig
  );
  const onClickOutside = (e) => {
    dispatch(roomConfigurationStateChanger(false));
  };
  var room = useSelector((state) => state.shadingConfigurationWindow.room);

  var infoBoxValGeneral = useSelector(
    (state) => state.shadingConfigurationWindow.roomIsShowing
  );
  var infoBoxValFirstIsShowing = useSelector(
    (state) => state.shadingConfigurationWindow.roomFirstIsShowing
  );

  return (
    visibilityBox &&
    roomConfigurationState &&
    infoBoxValFirstIsShowing &&
    infoBoxValGeneral && (
      <ConfigurationLayout title={`Room: ${room?.text}`} onClickOutside={onClickOutside}>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Information</Accordion.Header>
            <Accordion.Body>
              <RoomConfigurationGeneralInfo
                name={room?.text}
                id={room?.id}
                floor={room?.floor}
                rentalUnit={room?.rentalUnit}
                rentalStatus={room?.rentalStatus}
                roomFunction={room?.room_function_value}
                area={room?.room_area}
                maxPeople={room?.maxPeople}
              />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Energy Data</Accordion.Header>
            <Accordion.Body>
              <RoomConfigurationEnergyData />
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Control Logic</Accordion.Header>
            <Accordion.Body>
              <RoomConfigurationControlLogic />
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
      </ConfigurationLayout>
    )
  );
};

const RoomConfigurationGeneralInfo = withErrorBoundary((props) => {
  const {
    name,
    id,
    floor,
    rentalUnit,
    rentalStatus,
    roomFunction,
    area,
    maxPeople,
  } = props;
  const items = [
    { label: "Name", value: name },
    { label: "Unique ID", value: id },
    { label: "Floor Level", value: floor },
    { label: "Rental Unit", value: rentalStatus },
    { label: "Rental Status", value: rentalUnit },
    { label: "Function", value: roomFunction },
    { label: "Area", value: area },
    { label: "Max. People", value: maxPeople },
  ];
  return (
    <ul className="room_configuration__general_info__container">
      {items.map((item) => (
        <li>
          <span className="room_configuration__general_info__item__label">
            {item.label}
          </span>
          <span>{item.value ?? "----"}</span>
        </li>
      ))}
    </ul>
  );
});

const RoomConfigurationControlLogic = withErrorBoundary(() => {
  const project_id = localStorage.getItem("project_id");
  const [nameUpdate, setNameUpdate] = useState(false);
  const [upload, setUpload] = useState(false);
  const [secuitytime, setSecurityTime] = useState(null);
  const [threshold, setThreshold] = useState(null);
  const [editedname, setEditName] = useState(null);
  var windowName = useSelector(
    (state) => state.shadingConfigurationWindow.name
  );
  var windowID = useSelector(
    (state) => state.shadingConfigurationWindow.roomId
  );

  const updateName = () => {
    let url = `${process.env.REACT_APP_API_URL}api/room_configuration/${windowID}/`;

    axios
      .patch(url, {
        project: project_id,
        name: editedname,
      })
      .then((response) => {
        if (response.status == 200) {
          setNameUpdate(true);
        }
      });
  };

  const insertShadingConfiguration = () => {
    let url = `${process.env.REACT_APP_API_URL}api/room_configuration/`;

    axios
      .post(url, {
        room_name: windowName,
        project: project_id,
        security_time: secuitytime,
        threshold: threshold,
      })
      .then((response) => {
        if (response.status == 201) {
          setUpload(true);
        }
      });
  };
  const [show, setShow] = useState(false);
  const [name, setName] = useState(false);

  var windowName = useSelector(
    (state) => state.shadingConfigurationWindow.roomName
  );
  return (
    <>
      <p style={{ padding: "2px" }}>
        here is a part for editing shading configuration
      </p>
      {!upload && (
        <div
          style={{
            border: "1.9px dashed #2459EA",
            borderRadius: "5px",
            padding: "5px",
            // display: "flex",
            // flexDirection: "column",
          }}
        >
          <button
            style={{
              width: "170px",
              background: "#2459ea",
              borderRadius: "7px",
              padding: "0px 8px",
              justifyContent: "center",
              height: "40px",
            }}
            onClick={() => {
              setShow(!show);
            }}
          >
            <lable
              style={{
                width: "170px",
                color: "#ffffff",
                justifyContent: "center",
                height: "40px",
              }}
            >
              Edit Shading Config
            </lable>
          </button>
          {show && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ marginTop: "10px" }}>
                <div class="ui labeled input">
                  <div class="ui label">security time in minutes:</div>
                  <input
                    type="text"
                    placeholder="20"
                    style={{ width: "150px" }}
                    onChange={(event) => setSecurityTime(event.target.value)}
                  />
                </div>
              </div>

              <div style={{ marginTop: "10px" }}>
                <div class="ui labeled input">
                  <div class="ui label">sthreshold:</div>
                  <input
                    type="text"
                    placeholder="20"
                    onChange={(event) => setThreshold(event.target.value)}
                  />
                </div>
              </div>
              <div style={{ marginTop: "10px", width: "200px" }}>
                <div>
                  <select
                    style={{
                      borderRadius: "3px",
                      padding: "3px",
                      width: "120px",
                      height: "40px",
                    }}
                  >
                    <option value="room">room</option>
                    <option value="window">window</option>
                    <option value="room facade">room facade</option>
                  </select>
                </div>
              </div>
              <button
                style={{
                  width: "130px",
                  marginTop: "10px",
                  background: "#2459ea",
                  borderRadius: "7px",
                  padding: "0px 8px",
                  justifyContent: "center",
                  height: "40px",
                }}
                onClick={() => {
                  insertShadingConfiguration();
                }}
              >
                <lable style={{ color: "white" }}>Submit Config</lable>
              </button>
            </div>
          )}
        </div>
      )}
      {upload && (
        <div>
          your file uploaded successfully
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="130"
            fill="green"
            class="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
        </div>
      )}
      {!nameUpdate && (
        <div
          style={{
            border: "1.9px dashed #2459EA",
            borderRadius: "5px",
            padding: "5px",
            marginTop: "10px",
            // display: "flex",
            // flexDirection: "column",
          }}
        >
          <button
            style={{
              width: "170px",
              background: "#2459ea",
              borderRadius: "7px",
              padding: "0px 8px",
              justifyContent: "center",
              height: "40px",
            }}
            onClick={() => setName(!name)}
          >
            <lable
              style={{
                width: "170px",
                color: "#ffffff",
                justifyContent: "center",
                height: "40px",
              }}
            >
              Edit Name
            </lable>
          </button>
          {name && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div class="ui  input" style={{ paddingTop: "10px" }}>
                enter your required name:
                <input
                  type="text"
                  placeholder="window 10G"
                  style={{ marginLeft: "10px" }}
                  onChange={(event) => setEditName(event.target.value)}
                />
              </div>
              <button
                style={{
                  width: "130px",
                  marginTop: "10px",
                  background: "#2459ea",
                  borderRadius: "7px",
                  padding: "0px 8px",
                  justifyContent: "center",
                  height: "40px",
                }}
                onClick={() => {
                  updateName();
                }}
              // onClick={() => dispatch(toggleInfoBpx([""]))}
              >
                <lable style={{ color: "white" }}>Update Name</lable>
              </button>
            </div>
          )}
        </div>
      )}
      {nameUpdate && (
        <div>
          the name successfully updated
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="70"
            height="130"
            fill="green"
            class="bi bi-check-circle-fill"
            viewBox="0 0 16 16"
          >
            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
          </svg>
        </div>
      )}
    </>
  );
});

const CustomizedTicks = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={6}
        textAnchor="end"
        fill="#666"
        transform="rotate(-30)"
      >
        {payload.value}
      </text>
    </g>
  );
};

const RoomConfigurationEnergyData = withErrorBoundary(() => {
  const dispatch = useDispatch();
  const minMaxDict = useSelector((state) => state.roomData.rangeValues);

  var allProejctParameters = useSelector((state) => state.sceneVar);
  var energyValueData = allProejctParameters.energyValueList;

  const dropdownValue = useSelector((state) => state.roomData.currentValue);
  const currentValue = dropdownValue || energyValueData[0];
  var unit = [];
  if (currentValue) {
    unit = currentValue.split("[");
  }

  const scenarioNameListString = localStorage.getItem("scenario_list");
  var scenarioNamesList = scenarioNameListString.split(",");

  const room = useSelector((state) => state.shadingConfigurationWindow.room);
  const valueIndex = useSelector((state) => state.roomData.currentValueIndex);

  const scenarioList = JSON.parse(room.values)[0][valueIndex][currentValue];

  var output = [];
  if (scenarioList) {
    var maxScenario = Math.max(...scenarioList);
    output = scenarioList.slice(0, 6).map((scenario, index) => ({
      name: scenarioNamesList[index],
      scenarioNumber: scenario,
      fill: generateColor(scenario, 0, maxScenario, roomEnergyDataInfoBoxScale),
    }));
  }

  const handleChange = (e, { value }) => {
    const index = options.findIndex((option) => option.value === value);
    dispatch(setCurrentValue([value]));
    dispatch(setCurrentValueIndex(index));
  };

  const options = energyValueData?.map((option, index) => ({
    key: index,
    text: option,
    value: option,
  }));

  return (
    <div
      style={{
        // width: "510px",
        height: "310px",
        borderRadius: "10px",
        background: "#ffffff",
        // left: coordinates.pagex,
        // top: coordinates.pagey,
        // position: "absolute",
        // zIndex: 1000,
        padding: "10px",
      }}
    >
      <Dropdown
        onChange={handleChange}
        options={options}
        placeholder="Choose a value"
        selection
        value={currentValue}
        style={{ margin: "4px auto", display: "block" }}
      />
      <BarChart
        width={360}
        height={250}
        data={output}
        margin={{
          top: 5,
          right: 10,
          left: 10,
          bottom: 55,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="name"
          // angle={-45}
          // tickMargin={0}
          interval={0}
          tick={<CustomizedTicks />}
        // padding={120}
        />

        <YAxis
          domain={[
            // minMaxDict[currentValue]?.min,
            Math.max(0, minMaxDict[currentValue]?.min),
            // 0,

            // minMaxDict[currentValue].max,
            maxScenario > minMaxDict[currentValue]?.min
              ? maxScenario || minMaxDict[currentValue]?.max
              : minMaxDict[currentValue]?.max,
          ]}
          tickFormatter={(x) => x.toFixed(2)}
        >
          <Label
            angle={-90}
            style={{
              textAnchor: "middle",
              fontWeight: "bold",
              color: "blue",
            }}
            dy="100"
            dx="100"
            position="left"
          >
            {"[" + unit[1]}
          </Label>
        </YAxis>
        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}
        <Bar
          dataKey="scenarioNumber"
          fill="#8884d8"
          onClick={(e) => {
            // dispatch(
            //   showEnergyData([
            //     `${currentFloorNameValue}${roomDataSelector.currentValue}${roomDataSelector.lastChosenScenario}`,
            //   ])
            // );
            // dispatch(setLastChosenScenario([e.name]));
            // dispatch(
            //   showEnergyData([
            //     `${currentFloorNameValue}${roomDataSelector.currentValue}${e.name}`,
            //   ])
            // );
            // dispatch(
            //   updateFilter([
            //     `Energy Data >> ${roomDataSelector.currentValue} >> ${e.name}`,
            //   ])
            // );
            // dispatch(setLastChosenScenario([e.name]));
          }}
        />
      </BarChart>
    </div>
  );
});

export default RoomConfiguration;
