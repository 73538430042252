import React from "react";
import { useWindTurbineColorBar } from "../CustomHooks";
import "./colorBar.css";
import { CustomColorBar } from "./ColorBarComponent";


const WindTurbineColorBar = () => {
  const { minValue, maxValue, isVisible } = useWindTurbineColorBar();
  return (
    <CustomColorBar
      colorbarClassName="wind-turbine-color-bar"
      category="Wind Turbine"
      subcategory="Zone"
      unit="AEP(kWh)"
      dataMin={minValue}
      dataMax={maxValue}
      isVisible={isVisible}
    />
  );
};

export default WindTurbineColorBar;
