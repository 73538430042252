import React from "react";
import { useSelector } from "react-redux";
import { generateAxisValues, generateNumbers } from "../utils";
import ColorBarComponent, { CustomColorBar } from "./ColorBarComponent";
import "./colorBar.css";
import ColorbarSetRange from "./CustomizeColorbar";
import { sunsensors } from "../navBar/NavBar";
import { useSolarSensorsColorBar } from "../CustomHooks";

const ColorBarOld = () => {
  var solarSensorSelector = useSelector((state) => state.sensorSolars.value);
  var minValue = useSelector((state) => state.sensorSolars.rangeList[0]);
  var maxValue = useSelector((state) => state.sensorSolars.rangeList[1]);

  const colorbars = useSelector((state) => state.colorbars["Solar Sensors"]);
  const { min, max, step } = colorbars["0"] || {
    min: undefined,
    max: maxValue,
    step: undefined,
  };
  const generatedNumbers = generateAxisValues(min || minValue, max, step);
  // const generatedNumbers = generateNumbers(minValue, maxValue, 250);

  return (
    solarSensorSelector.checkStatus[0] &&
    generatedNumbers && (
      <>
        <ColorbarSetRange
          category="Solar Sensors"
          subcategory={"0"}
          defaultMax={maxValue}
        />
        <ColorBarComponent
          generatedNumbers={generatedNumbers}
          min={minValue}
          max={generatedNumbers[generatedNumbers.length - 1]}
          shiftUp={2}
          label="Hours"
          className="hoursColor"
        />
      </>
    )
  );
};

const ColorBarSolarSensors = () => {
  const { min, max, isVisible, visibleIndex } = useSolarSensorsColorBar();

  return (
    <CustomColorBar
      colorbarClassName={visibleIndex == 0 ? "hoursColor" : "otherColor"}
      category="Solar Sensors"
      subcategory={sunsensors[visibleIndex]}
      unit={visibleIndex === 0 ? "Hours" : "kWh/m²a"}
      dataMin={min}
      dataMax={max}
      isVisible={isVisible}
    />
  );
};

export default ColorBarSolarSensors;
