import axios from "axios";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import "./InfoBoxDashboard.css";

const WindGif = () => {
  const project_id = localStorage.getItem("project_id");
  const token = localStorage.getItem("token");
  var windSelector = useSelector((state) => state.windDirection.value)
    .checkStatus;
  var allProejctParameters = useSelector((state) => state.sceneVar);
  var winddirection = allProejctParameters.windDirectionList;
  const [gifs, setGifs] = useState([]);

  useEffect(() => {
    const endpoint = `${process.env.REACT_APP_API_URL}api/wind_gif_data/?project_id=${project_id}`;
    axios
      .get(endpoint, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setGifs(response.data);
      })
      .catch(() => { });
  }, []);

  return (
    <>
      {gifs?.length > 0 &&
        gifs?.map((gif, index) => {
          const { image_url_value, direction } = gif;
          const directionIndex = winddirection?.findIndex(
            (dir) => dir == direction
          );
          const isDirectionSelected = windSelector[directionIndex];
          if (isDirectionSelected && image_url_value)
            return (
              <div
                style={{
                  backgroundColor: "white",
                  position: "fixed",
                  bottom: "5%",
                  right: "10%",
                  zIndex: 10 + 2 * index,
                  width: "250px",
                  height: "250px",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    zIndex: 10 + 2 * index,
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <span style={{}}>
                    <Spinner />
                    <p>Loading Gif...</p>
                  </span>
                </div>
                <img
                  src={image_url_value}
                  style={{
                    width: "250px",
                    height: "250px",
                    position: "absolute",
                    zIndex: 11 + 2 * index,
                  }}
                />
              </div>
            );
        })}
    </>
  );
};

export default WindGif;
