const zoneTwo = [
  //   {
  //     id: "IDN:5826.0",
  //     zone: 1,
  //     height: 3,
  //     position: [-89.7265662771, 22.8763131979, 22.2000007629],
  //     "overall score": 0.4191863754,
  //     0: {
  //       velocity: [0.8127947181, 0.9943103185, 1.1065972493],
  //       direction: [-12.2589437113, -8.0636134759, -5.6859456498],
  //       "speed factor": 1.0530839041,
  //       "Uz factor": 0.992844095,
  //       "Directional variance factor": 0.2832343577,
  //       "Velocity stability factor": 0.76030444,
  //       "Direction stability factor": 0.9817416721,
  //       score: 0.7517555354,
  //     },
  //     15: {
  //       velocity: [0.0641800702, 0.2924504363, 0.7439546781],
  //       direction: [-178.5084475757, -0.2953743858, 158.9562274285],
  //       "speed factor": 0.2905584269,
  //       "Uz factor": 0.8966591329,
  //       "Directional variance factor": 0.973744499,
  //       "Velocity stability factor": 0.4377440122,
  //       "Direction stability factor": 0.062598125,
  //       score: 0.0,
  //     },
  //     "30.0": {
  //       velocity: [0.4256637742, 0.8203604397, 1.2569473298],
  //       direction: [-23.4142308894, -4.5443141184, 32.0860629691],
  //       "speed factor": 0.9483756509,
  //       "Uz factor": 0.9999603898,
  //       "Directional variance factor": 0.5960609673,
  //       "Velocity stability factor": 0.3877539087,
  //       "Direction stability factor": 0.8458325171,
  //       score: 0.0,
  //     },
  //     45: {
  //       velocity: [0.2845691339, 0.7819080139, 1.1331033011],
  //       direction: [-36.2493129989, -1.1819102084, 46.0911490548],
  //       "speed factor": 0.8513624461,
  //       "Uz factor": 0.9980036493,
  //       "Directional variance factor": 0.8949413148,
  //       "Velocity stability factor": 0.3433743683,
  //       "Direction stability factor": 0.7712764943,
  //       score: 0.0,
  //     },
  //     60: {
  //       velocity: [0.2898675268, 0.7120297231, 1.0655162814],
  //       direction: [-55.6889727786, -2.39184087, 27.9792010393],
  //       "speed factor": 0.7575544018,
  //       "Uz factor": 0.999999803,
  //       "Directional variance factor": 0.7873919227,
  //       "Velocity stability factor": 0.3720767918,
  //       "Direction stability factor": 0.7675884061,
  //       score: 0.0,
  //     },
  //     75: {
  //       velocity: [0.1760540587, 0.7230102899, 1.1148481456],
  //       direction: [-38.6887679632, -6.6457660757, 29.2218922645],
  //       "speed factor": 0.9033134939,
  //       "Uz factor": 0.9998060971,
  //       "Directional variance factor": 0.4092652377,
  //       "Velocity stability factor": 0.2934694274,
  //       "Direction stability factor": 0.8113592771,
  //       score: 0.0,
  //     },
  //     90: {
  //       velocity: [0.0565986904, 0.3131449869, 0.6769135466],
  //       direction: [-87.3719804945, 2.7500357225, 136.3541726568],
  //       "speed factor": 0.3562280111,
  //       "Uz factor": 0.984403485,
  //       "Directional variance factor": 0.7555523802,
  //       "Velocity stability factor": 0.4744734744,
  //       "Direction stability factor": 0.3785384635,
  //       score: 0.0,
  //     },
  //     105: {
  //       velocity: [0.4026758656, 0.8534162327, 1.1187356515],
  //       direction: [-17.1897084699, 1.891059698, 31.0898745126],
  //       "speed factor": 1.3704513227,
  //       "Uz factor": 0.9998927119,
  //       "Directional variance factor": 0.8319058046,
  //       "Velocity stability factor": 0.4383317326,
  //       "Direction stability factor": 0.8658900473,
  //       score: 0.7505044079,
  //     },
  //     120: {
  //       velocity: [0.5336976524, 1.0798850848, 1.2013684814],
  //       direction: [-5.8397952217, 4.7567894654, 15.0387657791],
  //       "speed factor": 1.3174149379,
  //       "Uz factor": 0.9968684395,
  //       "Directional variance factor": 0.5771742697,
  //       "Velocity stability factor": 0.4884437322,
  //       "Direction stability factor": 0.9420039972,
  //       score: 0.7374064991,
  //     },
  //     135: {
  //       velocity: [0.7821827478, 1.0316315425, 1.1641974493],
  //       direction: [-9.2687046973, 3.7165698603, 17.631783197],
  //       "speed factor": 1.2682264572,
  //       "Uz factor": 0.9897319774,
  //       "Directional variance factor": 0.6696382346,
  //       "Velocity stability factor": 0.7388568384,
  //       "Direction stability factor": 0.9252764225,
  //       score: 0.8147619795,
  //     },
  //     150: {
  //       velocity: [0.5750222619, 0.9830515666, 1.2467512596],
  //       direction: [-17.6654872096, 2.8270149738, 23.9266143161],
  //       "speed factor": 1.3936168699,
  //       "Uz factor": 0.9814974665,
  //       "Directional variance factor": 0.7487097801,
  //       "Velocity stability factor": 0.5353048749,
  //       "Direction stability factor": 0.8844663847,
  //       score: 0.7632368561,
  //     },
  //     165: {
  //       velocity: [0.5179905326, 0.7593273622, 1.0563009136],
  //       direction: [-13.8315411952, 0.54853979, 10.8522567005],
  //       "speed factor": 0.9614898132,
  //       "Uz factor": 0.9780733405,
  //       "Directional variance factor": 0.9512409076,
  //       "Velocity stability factor": 0.5582337657,
  //       "Direction stability factor": 0.9314338947,
  //       score: 0.0,
  //     },
  //     180: {
  //       velocity: [0.7911587568, 1.0113970002, 1.1700416307],
  //       direction: [-10.5759534935, -1.0747211509, 7.4525340842],
  //       "speed factor": 1.5097348032,
  //       "Uz factor": 0.9771538685,
  //       "Directional variance factor": 0.904469231,
  //       "Velocity stability factor": 0.7173867151,
  //       "Direction stability factor": 0.9499208678,
  //       score: 0.8804244204,
  //     },
  //     195: {
  //       velocity: [1.0758004252, 1.2289518869, 1.2758991507],
  //       direction: [-7.7933149538, -3.7542921733, -0.9141157433],
  //       "speed factor": 1.6505036671,
  //       "Uz factor": 0.9549306647,
  //       "Directional variance factor": 0.6662851402,
  //       "Velocity stability factor": 0.8406563761,
  //       "Direction stability factor": 0.9808911133,
  //       score: 0.8671809357,
  //     },
  //     210: {
  //       velocity: [0.6846070102, 0.9759515277, 1.1544851039],
  //       direction: [-33.7945062975, -4.1299424838, 6.4065350118],
  //       "speed factor": 0.9134667281,
  //       "Uz factor": 0.9777763208,
  //       "Directional variance factor": 0.6328940014,
  //       "Velocity stability factor": 0.6441274781,
  //       "Direction stability factor": 0.8883304408,
  //       score: 0.0,
  //     },
  //     225: {
  //       velocity: [0.3457292012, 0.7721898711, 1.0694145959],
  //       direction: [-35.980867606, -6.3064367387, 20.9399513899],
  //       "speed factor": 0.887110732,
  //       "Uz factor": 0.9799025538,
  //       "Directional variance factor": 0.4394278454,
  //       "Velocity stability factor": 0.3667785241,
  //       "Direction stability factor": 0.8418866139,
  //       score: 0.0,
  //     },
  //     240: {
  //       velocity: [1.0951181945, 1.1060028274, 1.1139516055],
  //       direction: [-5.2951921434, -4.1672486422, -2.5662550736],
  //       "speed factor": 1.3481178601,
  //       "Uz factor": 0.9750679773,
  //       "Directional variance factor": 0.6295778985,
  //       "Velocity stability factor": 0.9846014039,
  //       "Direction stability factor": 0.9924196193,
  //       score: 0.8997546352,
  //     },
  //     255: {
  //       velocity: [1.1028019522, 1.1121948081, 1.1187308456],
  //       direction: [-3.2497390388, -3.0233948024, -2.8046876824],
  //       "speed factor": 1.4401917495,
  //       "Uz factor": 0.9915139602,
  //       "Directional variance factor": 0.7312537953,
  //       "Velocity stability factor": 0.9861636616,
  //       "Direction stability factor": 0.9987637462,
  //       score: 0.9287362374,
  //     },
  //     270: {
  //       velocity: [1.053637505, 1.0856226197, 1.1117772394],
  //       direction: [-0.2665833522, 1.095620553, 3.054599625],
  //       "speed factor": 1.5863685799,
  //       "Uz factor": 0.9992125499,
  //       "Directional variance factor": 0.9026115064,
  //       "Velocity stability factor": 0.951659328,
  //       "Direction stability factor": 0.9907744917,
  //       score: 0.9589549545,
  //     },
  //     285: {
  //       velocity: [0.8257619748, 0.9232955599, 1.0395806194],
  //       direction: [9.3578055884, 11.8197544957, 13.3468131001],
  //       "speed factor": 1.0075407956,
  //       "Uz factor": 0.9996618436,
  //       "Directional variance factor": 0.0,
  //       "Velocity stability factor": 0.8263545534,
  //       "Direction stability factor": 0.9889194236,
  //       score: 0.0,
  //     },
  //     300: {
  //       velocity: [0.045692693, 0.1091574274, 0.1711414704],
  //       direction: [-145.798163742, -65.5502728565, -21.1384562745],
  //       "speed factor": 0.139897252,
  //       "Uz factor": 0.9534673416,
  //       "Directional variance factor": 0.0,
  //       "Velocity stability factor": 0.8899861488,
  //       "Direction stability factor": 0.6537230348,
  //       score: 0.0,
  //     },
  //     315: {
  //       velocity: [0.8145448492, 0.9649814134, 1.0963443599],
  //       direction: [6.037896708, 8.0967800313, 10.3739682697],
  //       "speed factor": 1.0890953278,
  //       "Uz factor": 0.9993607218,
  //       "Directional variance factor": 0.2802862194,
  //       "Velocity stability factor": 0.7459025289,
  //       "Direction stability factor": 0.9879553568,
  //       score: 0.7505248655,
  //     },
  //     330: {
  //       velocity: [0.5020552797, 0.7780146122, 0.9122259213],
  //       direction: [-20.733099079, 1.6066784661, 22.2405819194],
  //       "speed factor": 0.8893709288,
  //       "Uz factor": 0.9999917633,
  //       "Directional variance factor": 0.8571841363,
  //       "Velocity stability factor": 0.703322878,
  //       "Direction stability factor": 0.8806286639,
  //       score: 0.0,
  //     },
  //     345: {
  //       velocity: [1.1597608803, 1.1749791569, 1.1882406325],
  //       direction: [0.2491472604, 1.5610549553, 2.389897316],
  //       "speed factor": 1.2235681638,
  //       "Uz factor": 0.9997848491,
  //       "Directional variance factor": 0.8612395595,
  //       "Velocity stability factor": 0.9795802278,
  //       "Direction stability factor": 0.9940534721,
  //       score: 0.9572316829,
  //     },
  //   },
];
export default zoneTwo;
