import React, { useRef, useState } from "react";
import "../NavBar.css";
import { Dropdown, Checkbox } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { useSelector, useDispatch } from "react-redux";
import {
  show,
  showSurroungding,
  selectAllTarget,
  selectAllSurrounding,
  deselectAllSurrounding,
  deselectAllTarget,
  setIsRoomFilterByDetails,
  setSelectedRoomsNames,
  deselectAllPVs,
  selectAllPVs,
  updatePVPanelFilter,
} from "../../redux/sceneSlice";
import { toggleInfoBpx } from "../../redux/infoBoxGeneral";
import { Button } from "@mui/material";

const DropDownCustomized = (props) => {
  var inputData = props.inputData;
  const dispatch = useDispatch();
  var { roomsFilter, pvFilter } = useSelector((state) => state.sceneVisibility);

  const callToActionSelectAll = (value, surrounding) => {
    if (surrounding == "false") {
      dispatch(selectAllTarget());
    }
    if (surrounding == "true") {
      dispatch(selectAllSurrounding());
    }
  };

  const callToActionDeSelectAll = (value, surrounding) => {
    if (surrounding == "false") {
      dispatch(deselectAllTarget());
    }
    if (surrounding == "true") {
      dispatch(deselectAllSurrounding());
    }
  };


  const isSomePVSelected = Object.values(pvFilter)?.some(e => e)
  return (
    <Dropdown item simple text={props.title} className="drop-down">
      <Dropdown.Menu className="kkk">
        <Dropdown.Item>
          <Button
            variant="outlined"
            onClick={(event) =>
              callToActionSelectAll(event.target.checked, props.surroundings)
            }
          >
            Select All
          </Button>
        </Dropdown.Item>

        <Dropdown.Item>
          <Button
            variant="outlined"
            color="primary"
            onClick={(event) =>
              callToActionDeSelectAll(event.target.checked, props.surroundings)
            }
          >
            Deselect All
          </Button>
        </Dropdown.Item>
        {inputData.map(({ id, title }) => {
          if (id !== 5 && id !== 9)
            return (
              <Dropdown.Item
                onClick={() => {
                  props.surroundings != "true"
                    ? dispatch(show(id))
                    : dispatch(showSurroungding(id));
                }}
              >
                <>
                  <Checkbox
                    label={title}
                    key={id}
                    checked={props.status[id]}
                  // defaultChecked={props.status[id]}
                  />
                  <button
                    style={{ background: "none", border: "none" }}
                    onClick={() => {
                      dispatch(toggleInfoBpx([title]));
                    }}
                  >
                    <i
                      class="info circle icon"
                      style={{ marginLeft: "5px", color: "blue" }}
                    ></i>
                  </button>
                </>
              </Dropdown.Item>
            );
          if (id === 5)
            return (
              <Dropdown.Item
                id="rooms-filter"
                className="drop-down-item-no-padding"
                onClick={() => {
                  props.surroundings != "true"
                    ? dispatch(show(id))
                    : dispatch(showSurroungding(id));
                }}
                style={{
                  display: "flex",
                  gap: "0px",
                  height: 40,
                  alignItems: "center",
                }}
              >
                <Checkbox key={id} checked={props.status[id]} />
                <Dropdown
                  item
                  simple
                  text={title}
                  onClick={() => {
                    props.surroundings != "true"
                      ? dispatch(show(id))
                      : dispatch(showSurroungding(id));
                  }}
                  style={{
                    flexGrow: 1,
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: 8,
                    paddingLeft: 10,
                  }}
                >
                  <Dropdown.Menu
                    style={{
                      transform: "translateY(-32px)",
                    }}
                  >
                    <Dropdown.Item
                      onClick={(e) => {
                        dispatch(
                          setIsRoomFilterByDetails(!roomsFilter.isByDetails)
                        );
                        e.stopPropagation()
                      }}
                    >
                      <Checkbox
                        label={"Filter by room details"}
                        key={id + "details"}
                        checked={roomsFilter.isByDetails}
                      />
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Item>
            );
          if (id === 9)
            return (
              <Dropdown.Item
                id="rooms-filter"
                className="drop-down-item-no-padding"
                onClick={() => {
                  isSomePVSelected ?
                    dispatch(deselectAllPVs()) :
                    dispatch(selectAllPVs())
                }}
                style={{
                  display: "flex",
                  gap: "0px",
                  height: 40,
                  alignItems: "center",
                }}
              >
                <Checkbox key={id} checked={isSomePVSelected} />
                <Dropdown
                  item
                  simple
                  text={title}
                  onClick={() => {
                    isSomePVSelected ?
                      dispatch(deselectAllPVs()) :
                      dispatch(selectAllPVs())
                  }}
                  style={{
                    flexGrow: 1,
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingRight: 8,
                    paddingLeft: 10,
                  }}
                >
                  <Dropdown.Menu
                    style={{
                      transform: "translateY(-32px)",
                    }}
                  >
                    {Object.entries(pvFilter)?.map(([scenario, isShown]) => {
                      return (
                        <Dropdown.Item
                          onClick={(e) => {
                            dispatch(updatePVPanelFilter({ [scenario]: !isShown }));
                            e.stopPropagation()
                          }}
                        >
                          <Checkbox
                            label={scenario}
                            key={id + "details"}
                            checked={isShown}
                          />
                        </Dropdown.Item>

                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </Dropdown.Item>
            );
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownCustomized;
