const project3ShadingProgression = [
  {
    ID: -1,
    polygon: "ANotExistingPolygon",
    "05-22 00:00": 0,
    "05-22 00:15": 0,
    "05-22 00:30": 0,
    "05-22 00:45": 0,
    "05-22 01:00": 0,
    "05-22 01:15": 0,
    "05-22 01:30": 0,
    "05-22 01:45": 0,
    "05-22 02:00": 0,
    "05-22 02:15": 0,
    "05-22 02:30": 0,
    "05-22 02:45": 0,
    "05-22 03:00": 0,
    "05-22 03:15": 0,
    "05-22 03:30": 0,
    "05-22 03:45": 0,
    "05-22 04:00": 0,
    "05-22 04:15": 0,
    "05-22 04:30": 0,
    "05-22 04:45": 0,
    "05-22 05:00": 0,
    "05-22 05:15": 0,
    "05-22 05:30": 0,
    "05-22 05:45": 0,
    "05-22 06:00": 0,
    "05-22 06:15": 0,
    "05-22 06:30": 0,
    "05-22 06:45": 0,
    "05-22 07:00": 0,
    "05-22 07:15": 0,
    "05-22 07:30": 0,
    "05-22 07:45": 0,
    "05-22 08:00": 0,
    "05-22 08:15": 0,
    "05-22 08:30": 0,
    "05-22 08:45": 0,
    "05-22 09:00": 0,
    "05-22 09:15": 0,
    "05-22 09:30": 0,
    "05-22 09:45": 0,
    "05-22 10:00": 0,
    "05-22 10:15": 0,
    "05-22 10:30": 0,
    "05-22 10:45": 0,
    "05-22 11:00": 0,
    "05-22 11:15": 0,
    "05-22 11:30": 0,
    "05-22 11:45": 0,
    "05-22 12:00": 0,
    "05-22 12:15": 0,
    "05-22 12:30": 0,
    "05-22 12:45": 0,
    "05-22 13:00": 0,
    "05-22 13:15": 0,
    "05-22 13:30": 0,
    "05-22 13:45": 0,
    "05-22 14:00": 0,
    "05-22 14:15": 0,
    "05-22 14:30": 0,
    "05-22 14:45": 0,
    "05-22 15:00": 0,
    "05-22 15:15": 0,
    "05-22 15:30": 0,
    "05-22 15:45": 0,
    "05-22 16:00": 0,
    "05-22 16:15": 0,
    "05-22 16:30": 0,
    "05-22 16:45": 0,
    "05-22 17:00": 0,
    "05-22 17:15": 0,
    "05-22 17:30": 0,
    "05-22 17:45": 0,
    "05-22 18:00": 0,
    "05-22 18:15": 0,
    "05-22 18:30": 0,
    "05-22 18:45": 0,
    "05-22 19:00": 0,
    "05-22 19:15": 0,
    "05-22 19:30": 0,
    "05-22 19:45": 0,
    "05-22 20:00": 0,
    "05-22 20:15": 0,
    "05-22 20:30": 0,
    "05-22 20:45": 0,
    "05-22 21:00": 0,
    "05-22 21:15": 0,
    "05-22 21:30": 0,
    "05-22 21:45": 0,
    "05-22 22:00": 0,
    "05-22 22:15": 0,
    "05-22 22:30": 0,
    "05-22 22:45": 0,
    "05-22 23:00": 0,
    "05-22 23:15": 0,
    "05-22 23:30": 0,
    "05-22 23:45": 0,
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12777",
    "polygon": [
      -20.75,
      47.647,
      0.0,
      -20.75,
      47.647,
      4.55,
      -20.353,
      48.833,
      4.55,
      -20.353,
      48.833,
      4.55,
      -20.353,
      48.833,
      0.0,
      -20.75,
      47.647,
      0.0
    ]
  },
  {
    "ID": "Block-A-Outer_EG_9999",
    "polygon": [
      3.267,
      77.415,
      0.745,
      3.267,
      77.415,
      3.455,
      2.755,
      75.888,
      3.455,
      2.755,
      75.888,
      3.455,
      2.755,
      75.888,
      0.745,
      3.267,
      77.415,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10000",
    "polygon": [
      2.557,
      75.296,
      0.745,
      2.557,
      75.296,
      3.455,
      2.319,
      74.585,
      3.455,
      2.319,
      74.585,
      3.455,
      2.319,
      74.585,
      0.745,
      2.557,
      75.296,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_10001",
    "polygon": [
      2.755,
      75.888,
      0.745,
      2.755,
      75.888,
      3.455,
      2.557,
      75.296,
      3.455,
      2.557,
      75.296,
      3.455,
      2.557,
      75.296,
      0.745,
      2.755,
      75.888,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10003",
    "polygon": [
      3.505,
      78.126,
      0.745,
      3.505,
      78.126,
      3.455,
      3.267,
      77.415,
      3.455,
      3.267,
      77.415,
      3.455,
      3.267,
      77.415,
      0.745,
      3.505,
      78.126,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_10004",
    "polygon": [
      5.233,
      83.284,
      0.745,
      5.233,
      83.284,
      3.455,
      4.721,
      81.757,
      3.455,
      4.721,
      81.757,
      3.455,
      4.721,
      81.757,
      0.745,
      5.233,
      83.284,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10005",
    "polygon": [
      4.523,
      81.164,
      0.745,
      4.523,
      81.164,
      3.455,
      4.284,
      80.453,
      3.455,
      4.284,
      80.453,
      3.455,
      4.284,
      80.453,
      0.745,
      4.523,
      81.164,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_10006",
    "polygon": [
      4.721,
      81.757,
      0.745,
      4.721,
      81.757,
      3.455,
      4.523,
      81.164,
      3.455,
      4.523,
      81.164,
      3.455,
      4.523,
      81.164,
      0.745,
      4.721,
      81.757,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10008",
    "polygon": [
      5.471,
      83.995,
      0.745,
      5.471,
      83.995,
      3.455,
      5.233,
      83.284,
      3.455,
      5.233,
      83.284,
      3.455,
      5.233,
      83.284,
      0.745,
      5.471,
      83.995,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_10009",
    "polygon": [
      7.248,
      89.3,
      0.745,
      7.248,
      89.3,
      3.455,
      6.737,
      87.773,
      3.455,
      6.737,
      87.773,
      3.455,
      6.737,
      87.773,
      0.745,
      7.248,
      89.3,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10010",
    "polygon": [
      6.538,
      87.181,
      0.745,
      6.538,
      87.181,
      3.455,
      6.3,
      86.47,
      3.455,
      6.3,
      86.47,
      3.455,
      6.3,
      86.47,
      0.745,
      6.538,
      87.181,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_10011",
    "polygon": [
      6.737,
      87.773,
      0.745,
      6.737,
      87.773,
      3.455,
      6.538,
      87.181,
      3.455,
      6.538,
      87.181,
      3.455,
      6.538,
      87.181,
      0.745,
      6.737,
      87.773,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10013",
    "polygon": [
      7.486,
      90.011,
      0.745,
      7.486,
      90.011,
      3.455,
      7.248,
      89.3,
      3.455,
      7.248,
      89.3,
      3.455,
      7.248,
      89.3,
      0.745,
      7.486,
      90.011,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_10014",
    "polygon": [
      9.263,
      95.316,
      0.745,
      9.263,
      95.316,
      3.455,
      8.752,
      93.79,
      3.455,
      8.752,
      93.79,
      3.455,
      8.752,
      93.79,
      0.745,
      9.263,
      95.316,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10015",
    "polygon": [
      8.554,
      93.197,
      0.745,
      8.554,
      93.197,
      3.455,
      8.315,
      92.486,
      3.455,
      8.315,
      92.486,
      3.455,
      8.315,
      92.486,
      0.745,
      8.554,
      93.197,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_10016",
    "polygon": [
      8.752,
      93.79,
      0.745,
      8.752,
      93.79,
      3.455,
      8.554,
      93.197,
      3.455,
      8.554,
      93.197,
      3.455,
      8.554,
      93.197,
      0.745,
      8.752,
      93.79,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10018",
    "polygon": [
      9.502,
      96.027,
      0.745,
      9.502,
      96.027,
      3.455,
      9.263,
      95.316,
      3.455,
      9.263,
      95.316,
      3.455,
      9.263,
      95.316,
      0.745,
      9.502,
      96.027,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_10019",
    "polygon": [
      13.304,
      107.377,
      0.745,
      13.304,
      107.377,
      3.455,
      12.792,
      105.851,
      3.455,
      12.792,
      105.851,
      3.455,
      12.792,
      105.851,
      0.745,
      13.304,
      107.377,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10020",
    "polygon": [
      12.594,
      105.258,
      0.745,
      12.594,
      105.258,
      3.455,
      12.356,
      104.547,
      3.455,
      12.356,
      104.547,
      3.455,
      12.356,
      104.547,
      0.745,
      12.594,
      105.258,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_10021",
    "polygon": [
      12.792,
      105.851,
      0.745,
      12.792,
      105.851,
      3.455,
      12.594,
      105.258,
      3.455,
      12.594,
      105.258,
      3.455,
      12.594,
      105.258,
      0.745,
      12.792,
      105.851,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_10023",
    "polygon": [
      13.542,
      108.088,
      0.745,
      13.542,
      108.088,
      3.455,
      13.304,
      107.377,
      3.455,
      13.304,
      107.377,
      3.455,
      13.304,
      107.377,
      0.745,
      13.542,
      108.088,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_10024",
    "polygon": [
      2.354,
      74.573,
      13.073,
      2.354,
      74.573,
      15.523,
      1.843,
      73.046,
      15.523,
      1.843,
      73.046,
      15.523,
      1.843,
      73.046,
      13.073,
      2.354,
      74.573,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_10025",
    "polygon": [
      4.318,
      80.442,
      13.073,
      4.318,
      80.442,
      15.523,
      3.807,
      78.915,
      15.523,
      3.807,
      78.915,
      15.523,
      3.807,
      78.915,
      13.073,
      4.318,
      80.442,
      13.073
    ],
    "05-22 04:00": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_10026",
    "polygon": [
      6.333,
      86.458,
      13.073,
      6.333,
      86.458,
      15.523,
      5.822,
      84.932,
      15.523,
      5.822,
      84.932,
      15.523,
      5.822,
      84.932,
      13.073,
      6.333,
      86.458,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_10027",
    "polygon": [
      8.347,
      92.475,
      13.073,
      8.347,
      92.475,
      15.523,
      7.836,
      90.949,
      15.523,
      7.836,
      90.949,
      15.523,
      7.836,
      90.949,
      13.073,
      8.347,
      92.475,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_10028",
    "polygon": [
      12.385,
      104.537,
      13.073,
      12.385,
      104.537,
      15.523,
      11.874,
      103.01,
      15.523,
      11.874,
      103.01,
      15.523,
      11.874,
      103.01,
      13.073,
      12.385,
      104.537,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_10029",
    "polygon": [
      10.371,
      98.52,
      9.323,
      10.371,
      98.52,
      11.773,
      9.859,
      96.993,
      11.773,
      9.859,
      96.993,
      11.773,
      9.859,
      96.993,
      9.323,
      10.371,
      98.52,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_10030",
    "polygon": [
      2.354,
      74.573,
      9.323,
      2.354,
      74.573,
      11.773,
      1.843,
      73.046,
      11.773,
      1.843,
      73.046,
      11.773,
      1.843,
      73.046,
      9.323,
      2.354,
      74.573,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_10031",
    "polygon": [
      4.318,
      80.442,
      9.323,
      4.318,
      80.442,
      11.773,
      3.807,
      78.915,
      11.773,
      3.807,
      78.915,
      11.773,
      3.807,
      78.915,
      9.323,
      4.318,
      80.442,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_10032",
    "polygon": [
      6.333,
      86.458,
      9.323,
      6.333,
      86.458,
      11.773,
      5.822,
      84.932,
      11.773,
      5.822,
      84.932,
      11.773,
      5.822,
      84.932,
      9.323,
      6.333,
      86.458,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_10033",
    "polygon": [
      8.347,
      92.475,
      9.323,
      8.347,
      92.475,
      11.773,
      7.836,
      90.948,
      11.773,
      7.836,
      90.948,
      11.773,
      7.836,
      90.948,
      9.323,
      8.347,
      92.475,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_10034",
    "polygon": [
      12.385,
      104.537,
      9.323,
      12.385,
      104.537,
      11.773,
      11.874,
      103.01,
      11.773,
      11.874,
      103.01,
      11.773,
      11.874,
      103.01,
      9.323,
      12.385,
      104.537,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_10035",
    "polygon": [
      10.366,
      98.522,
      5.573,
      10.366,
      98.522,
      8.023,
      9.855,
      96.995,
      8.023,
      9.855,
      96.995,
      8.023,
      9.855,
      96.995,
      5.573,
      10.366,
      98.522,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_10036",
    "polygon": [
      2.345,
      74.576,
      5.573,
      2.345,
      74.576,
      8.023,
      1.833,
      73.049,
      8.023,
      1.833,
      73.049,
      8.023,
      1.833,
      73.049,
      5.573,
      2.345,
      74.576,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_10037",
    "polygon": [
      4.31,
      80.444,
      5.573,
      4.31,
      80.444,
      8.023,
      3.799,
      78.918,
      8.023,
      3.799,
      78.918,
      8.023,
      3.799,
      78.918,
      5.573,
      4.31,
      80.444,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_10038",
    "polygon": [
      6.326,
      86.461,
      5.573,
      6.326,
      86.461,
      8.023,
      5.814,
      84.934,
      8.023,
      5.814,
      84.934,
      8.023,
      5.814,
      84.934,
      5.573,
      6.326,
      86.461,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_10039",
    "polygon": [
      8.341,
      92.477,
      5.573,
      8.341,
      92.477,
      8.023,
      7.83,
      90.951,
      8.023,
      7.83,
      90.951,
      8.023,
      7.83,
      90.951,
      5.573,
      8.341,
      92.477,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_10040",
    "polygon": [
      12.381,
      104.538,
      5.573,
      12.381,
      104.538,
      8.023,
      11.87,
      103.011,
      8.023,
      11.87,
      103.011,
      8.023,
      11.87,
      103.011,
      5.573,
      12.381,
      104.538,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_10041",
    "polygon": [
      10.34,
      98.53,
      0.745,
      10.34,
      98.53,
      3.455,
      9.829,
      97.004,
      3.455,
      9.829,
      97.004,
      3.455,
      9.829,
      97.004,
      0.745,
      10.34,
      98.53,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_10042",
    "polygon": [
      2.319,
      74.585,
      0.745,
      2.319,
      74.585,
      3.455,
      1.807,
      73.058,
      3.455,
      1.807,
      73.058,
      3.455,
      1.807,
      73.058,
      0.745,
      2.319,
      74.585,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_10043",
    "polygon": [
      4.284,
      80.453,
      0.745,
      4.284,
      80.453,
      3.455,
      3.773,
      78.927,
      3.455,
      3.773,
      78.927,
      3.455,
      3.773,
      78.927,
      0.745,
      4.284,
      80.453,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_10044",
    "polygon": [
      6.3,
      86.47,
      0.745,
      6.3,
      86.47,
      3.455,
      5.789,
      84.943,
      3.455,
      5.789,
      84.943,
      3.455,
      5.789,
      84.943,
      0.745,
      6.3,
      86.47,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_10045",
    "polygon": [
      8.315,
      92.486,
      0.745,
      8.315,
      92.486,
      3.455,
      7.804,
      90.959,
      3.455,
      7.804,
      90.959,
      3.455,
      7.804,
      90.959,
      0.745,
      8.315,
      92.486,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_10046",
    "polygon": [
      12.356,
      104.547,
      0.745,
      12.356,
      104.547,
      3.455,
      11.844,
      103.02,
      3.455,
      11.844,
      103.02,
      3.455,
      11.844,
      103.02,
      0.745,
      12.356,
      104.547,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10047",
    "polygon": [
      -7.723,
      87.584,
      13.073,
      -7.723,
      87.584,
      15.523,
      -7.485,
      88.295,
      15.523,
      -7.485,
      88.295,
      15.523,
      -7.485,
      88.295,
      13.073,
      -7.723,
      87.584,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10048",
    "polygon": [
      -8.234,
      86.057,
      13.073,
      -8.234,
      86.057,
      15.523,
      -7.723,
      87.584,
      15.523,
      -7.723,
      87.584,
      15.523,
      -7.723,
      87.584,
      13.073,
      -8.234,
      86.057,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10049",
    "polygon": [
      -8.433,
      85.465,
      13.073,
      -8.433,
      85.465,
      15.523,
      -8.234,
      86.057,
      15.523,
      -8.234,
      86.057,
      15.523,
      -8.234,
      86.057,
      13.073,
      -8.433,
      85.465,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10050",
    "polygon": [
      -8.671,
      84.754,
      13.073,
      -8.671,
      84.754,
      15.523,
      -8.433,
      85.465,
      15.523,
      -8.433,
      85.465,
      15.523,
      -8.433,
      85.465,
      13.073,
      -8.671,
      84.754,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10051",
    "polygon": [
      -9.183,
      83.227,
      13.073,
      -9.183,
      83.227,
      15.523,
      -8.671,
      84.754,
      15.523,
      -8.671,
      84.754,
      15.523,
      -8.671,
      84.754,
      13.073,
      -9.183,
      83.227,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10052",
    "polygon": [
      15.433,
      -8.26,
      13.104,
      15.433,
      -8.26,
      15.554,
      15.195,
      -8.971,
      15.554,
      15.195,
      -8.971,
      15.554,
      15.195,
      -8.971,
      13.104,
      15.433,
      -8.26,
      13.104
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10053",
    "polygon": [
      15.195,
      -8.971,
      13.104,
      15.195,
      -8.971,
      15.554,
      14.684,
      -10.497,
      15.554,
      14.684,
      -10.497,
      15.554,
      14.684,
      -10.497,
      13.104,
      15.195,
      -8.971,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10054",
    "polygon": [
      16.582,
      -4.83,
      13.104,
      16.582,
      -4.83,
      15.554,
      16.384,
      -5.423,
      15.554,
      16.384,
      -5.423,
      15.554,
      16.384,
      -5.423,
      13.104,
      16.582,
      -4.83,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10055",
    "polygon": [
      16.384,
      -5.423,
      13.104,
      16.384,
      -5.423,
      15.554,
      16.145,
      -6.134,
      15.554,
      16.145,
      -6.134,
      15.554,
      16.145,
      -6.134,
      13.104,
      16.384,
      -5.423,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10056",
    "polygon": [
      16.145,
      -6.134,
      13.104,
      16.145,
      -6.134,
      15.554,
      15.634,
      -7.66,
      15.554,
      15.634,
      -7.66,
      15.554,
      15.634,
      -7.66,
      13.104,
      16.145,
      -6.134,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10057",
    "polygon": [
      17.332,
      -2.592,
      13.104,
      17.332,
      -2.592,
      15.554,
      17.094,
      -3.303,
      15.554,
      17.094,
      -3.303,
      15.554,
      17.094,
      -3.303,
      13.104,
      17.332,
      -2.592,
      13.104
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10058",
    "polygon": [
      17.094,
      -3.303,
      13.104,
      17.094,
      -3.303,
      15.554,
      16.582,
      -4.83,
      15.554,
      16.582,
      -4.83,
      15.554,
      16.582,
      -4.83,
      13.104,
      17.094,
      -3.303,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10059",
    "polygon": [
      18.527,
      0.974,
      13.104,
      18.527,
      0.974,
      15.554,
      18.328,
      0.381,
      15.554,
      18.328,
      0.381,
      15.554,
      18.328,
      0.381,
      13.104,
      18.527,
      0.974,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10060",
    "polygon": [
      18.328,
      0.381,
      13.104,
      18.328,
      0.381,
      15.554,
      18.09,
      -0.33,
      15.554,
      18.09,
      -0.33,
      15.554,
      18.09,
      -0.33,
      13.104,
      18.328,
      0.381,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10061",
    "polygon": [
      18.09,
      -0.33,
      13.104,
      18.09,
      -0.33,
      15.554,
      17.578,
      -1.856,
      15.554,
      17.578,
      -1.856,
      15.554,
      17.578,
      -1.856,
      13.104,
      18.09,
      -0.33,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10062",
    "polygon": [
      19.276,
      3.212,
      13.104,
      19.276,
      3.212,
      15.554,
      19.038,
      2.501,
      15.554,
      19.038,
      2.501,
      15.554,
      19.038,
      2.501,
      13.104,
      19.276,
      3.212,
      13.104
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10063",
    "polygon": [
      19.038,
      2.501,
      13.104,
      19.038,
      2.501,
      15.554,
      18.527,
      0.974,
      15.554,
      18.527,
      0.974,
      15.554,
      18.527,
      0.974,
      13.104,
      19.038,
      2.501,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10064",
    "polygon": [
      20.471,
      6.778,
      13.104,
      20.471,
      6.778,
      15.554,
      20.272,
      6.185,
      15.554,
      20.272,
      6.185,
      15.554,
      20.272,
      6.185,
      13.104,
      20.471,
      6.778,
      13.104
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10065",
    "polygon": [
      20.272,
      6.185,
      13.104,
      20.272,
      6.185,
      15.554,
      20.034,
      5.474,
      15.554,
      20.034,
      5.474,
      15.554,
      20.034,
      5.474,
      13.104,
      20.272,
      6.185,
      13.104
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10066",
    "polygon": [
      20.034,
      5.474,
      13.104,
      20.034,
      5.474,
      15.554,
      19.523,
      3.948,
      15.554,
      19.523,
      3.948,
      15.554,
      19.523,
      3.948,
      13.104,
      20.034,
      5.474,
      13.104
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10067",
    "polygon": [
      21.22,
      9.016,
      13.104,
      21.22,
      9.016,
      15.554,
      20.982,
      8.305,
      15.554,
      20.982,
      8.305,
      15.554,
      20.982,
      8.305,
      13.104,
      21.22,
      9.016,
      13.104
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10068",
    "polygon": [
      20.982,
      8.305,
      13.104,
      20.982,
      8.305,
      15.554,
      20.471,
      6.778,
      15.554,
      20.471,
      6.778,
      15.554,
      20.471,
      6.778,
      13.104,
      20.982,
      8.305,
      13.104
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10069",
    "polygon": [
      22.415,
      12.582,
      13.104,
      22.415,
      12.582,
      15.554,
      22.217,
      11.989,
      15.554,
      22.217,
      11.989,
      15.554,
      22.217,
      11.989,
      13.104,
      22.415,
      12.582,
      13.104
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10070",
    "polygon": [
      22.217,
      11.989,
      13.104,
      22.217,
      11.989,
      15.554,
      21.978,
      11.278,
      15.554,
      21.978,
      11.278,
      15.554,
      21.978,
      11.278,
      13.104,
      22.217,
      11.989,
      13.104
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10071",
    "polygon": [
      21.978,
      11.278,
      13.104,
      21.978,
      11.278,
      15.554,
      21.467,
      9.752,
      15.554,
      21.467,
      9.752,
      15.554,
      21.467,
      9.752,
      13.104,
      21.978,
      11.278,
      13.104
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10072",
    "polygon": [
      23.165,
      14.82,
      13.104,
      23.165,
      14.82,
      15.554,
      22.926,
      14.109,
      15.554,
      22.926,
      14.109,
      15.554,
      22.926,
      14.109,
      13.104,
      23.165,
      14.82,
      13.104
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10073",
    "polygon": [
      22.926,
      14.109,
      13.104,
      22.926,
      14.109,
      15.554,
      22.415,
      12.582,
      15.554,
      22.415,
      12.582,
      15.554,
      22.415,
      12.582,
      13.104,
      22.926,
      14.109,
      13.104
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10074",
    "polygon": [
      24.359,
      18.386,
      13.104,
      24.359,
      18.386,
      15.554,
      24.161,
      17.793,
      15.554,
      24.161,
      17.793,
      15.554,
      24.161,
      17.793,
      13.104,
      24.359,
      18.386,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10075",
    "polygon": [
      24.161,
      17.793,
      13.104,
      24.161,
      17.793,
      15.554,
      23.923,
      17.082,
      15.554,
      23.923,
      17.082,
      15.554,
      23.923,
      17.082,
      13.104,
      24.161,
      17.793,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10076",
    "polygon": [
      23.923,
      17.082,
      13.104,
      23.923,
      17.082,
      15.554,
      23.411,
      15.556,
      15.554,
      23.411,
      15.556,
      15.554,
      23.411,
      15.556,
      13.104,
      23.923,
      17.082,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10077",
    "polygon": [
      25.109,
      20.624,
      13.104,
      25.109,
      20.624,
      15.554,
      24.871,
      19.913,
      15.554,
      24.871,
      19.913,
      15.554,
      24.871,
      19.913,
      13.104,
      25.109,
      20.624,
      13.104
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10078",
    "polygon": [
      24.871,
      19.913,
      13.104,
      24.871,
      19.913,
      15.554,
      24.359,
      18.386,
      15.554,
      24.359,
      18.386,
      15.554,
      24.359,
      18.386,
      13.104,
      24.871,
      19.913,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10079",
    "polygon": [
      26.304,
      24.19,
      13.104,
      26.304,
      24.19,
      15.554,
      26.105,
      23.597,
      15.554,
      26.105,
      23.597,
      15.554,
      26.105,
      23.597,
      13.104,
      26.304,
      24.19,
      13.104
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10080",
    "polygon": [
      26.105,
      23.597,
      13.104,
      26.105,
      23.597,
      15.554,
      25.867,
      22.886,
      15.554,
      25.867,
      22.886,
      15.554,
      25.867,
      22.886,
      13.104,
      26.105,
      23.597,
      13.104
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10081",
    "polygon": [
      25.867,
      22.886,
      13.104,
      25.867,
      22.886,
      15.554,
      25.355,
      21.36,
      15.554,
      25.355,
      21.36,
      15.554,
      25.355,
      21.36,
      13.104,
      25.867,
      22.886,
      13.104
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10082",
    "polygon": [
      27.053,
      26.428,
      13.104,
      27.053,
      26.428,
      15.554,
      26.815,
      25.717,
      15.554,
      26.815,
      25.717,
      15.554,
      26.815,
      25.717,
      13.104,
      27.053,
      26.428,
      13.104
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_10083",
    "polygon": [
      26.815,
      25.717,
      13.104,
      26.815,
      25.717,
      15.554,
      26.304,
      24.19,
      15.554,
      26.304,
      24.19,
      15.554,
      26.304,
      24.19,
      13.104,
      26.815,
      25.717,
      13.104
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10084",
    "polygon": [
      14.684,
      -10.497,
      9.354,
      14.684,
      -10.497,
      11.804,
      14.485,
      -11.09,
      11.804,
      14.485,
      -11.09,
      11.804,
      14.485,
      -11.09,
      9.354,
      14.684,
      -10.497,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10085",
    "polygon": [
      14.485,
      -11.09,
      9.354,
      14.485,
      -11.09,
      11.804,
      14.247,
      -11.801,
      11.804,
      14.247,
      -11.801,
      11.804,
      14.247,
      -11.801,
      9.354,
      14.485,
      -11.09,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10086",
    "polygon": [
      14.247,
      -11.801,
      9.354,
      14.247,
      -11.801,
      11.804,
      13.736,
      -13.328,
      11.804,
      13.736,
      -13.328,
      11.804,
      13.736,
      -13.328,
      9.354,
      14.247,
      -11.801,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10087",
    "polygon": [
      15.433,
      -8.26,
      9.354,
      15.433,
      -8.26,
      11.804,
      15.195,
      -8.971,
      11.804,
      15.195,
      -8.971,
      11.804,
      15.195,
      -8.971,
      9.354,
      15.433,
      -8.26,
      9.354
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10088",
    "polygon": [
      15.195,
      -8.971,
      9.354,
      15.195,
      -8.971,
      11.804,
      14.684,
      -10.497,
      11.804,
      14.684,
      -10.497,
      11.804,
      14.684,
      -10.497,
      9.354,
      15.195,
      -8.971,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10089",
    "polygon": [
      16.582,
      -4.83,
      9.354,
      16.582,
      -4.83,
      11.804,
      16.384,
      -5.423,
      11.804,
      16.384,
      -5.423,
      11.804,
      16.384,
      -5.423,
      9.354,
      16.582,
      -4.83,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10090",
    "polygon": [
      16.384,
      -5.423,
      9.354,
      16.384,
      -5.423,
      11.804,
      16.145,
      -6.134,
      11.804,
      16.145,
      -6.134,
      11.804,
      16.145,
      -6.134,
      9.354,
      16.384,
      -5.423,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10091",
    "polygon": [
      16.145,
      -6.134,
      9.354,
      16.145,
      -6.134,
      11.804,
      15.634,
      -7.66,
      11.804,
      15.634,
      -7.66,
      11.804,
      15.634,
      -7.66,
      9.354,
      16.145,
      -6.134,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10092",
    "polygon": [
      17.332,
      -2.592,
      9.354,
      17.332,
      -2.592,
      11.804,
      17.094,
      -3.303,
      11.804,
      17.094,
      -3.303,
      11.804,
      17.094,
      -3.303,
      9.354,
      17.332,
      -2.592,
      9.354
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10093",
    "polygon": [
      17.094,
      -3.303,
      9.354,
      17.094,
      -3.303,
      11.804,
      16.582,
      -4.83,
      11.804,
      16.582,
      -4.83,
      11.804,
      16.582,
      -4.83,
      9.354,
      17.094,
      -3.303,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10094",
    "polygon": [
      18.527,
      0.974,
      9.354,
      18.527,
      0.974,
      11.804,
      18.328,
      0.381,
      11.804,
      18.328,
      0.381,
      11.804,
      18.328,
      0.381,
      9.354,
      18.527,
      0.974,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10095",
    "polygon": [
      18.328,
      0.381,
      9.354,
      18.328,
      0.381,
      11.804,
      18.09,
      -0.33,
      11.804,
      18.09,
      -0.33,
      11.804,
      18.09,
      -0.33,
      9.354,
      18.328,
      0.381,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10096",
    "polygon": [
      18.09,
      -0.33,
      9.354,
      18.09,
      -0.33,
      11.804,
      17.578,
      -1.856,
      11.804,
      17.578,
      -1.856,
      11.804,
      17.578,
      -1.856,
      9.354,
      18.09,
      -0.33,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10097",
    "polygon": [
      19.276,
      3.212,
      9.354,
      19.276,
      3.212,
      11.804,
      19.038,
      2.501,
      11.804,
      19.038,
      2.501,
      11.804,
      19.038,
      2.501,
      9.354,
      19.276,
      3.212,
      9.354
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10098",
    "polygon": [
      19.038,
      2.501,
      9.354,
      19.038,
      2.501,
      11.804,
      18.527,
      0.974,
      11.804,
      18.527,
      0.974,
      11.804,
      18.527,
      0.974,
      9.354,
      19.038,
      2.501,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10099",
    "polygon": [
      20.471,
      6.778,
      9.354,
      20.471,
      6.778,
      11.804,
      20.272,
      6.185,
      11.804,
      20.272,
      6.185,
      11.804,
      20.272,
      6.185,
      9.354,
      20.471,
      6.778,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10100",
    "polygon": [
      20.272,
      6.185,
      9.354,
      20.272,
      6.185,
      11.804,
      20.034,
      5.474,
      11.804,
      20.034,
      5.474,
      11.804,
      20.034,
      5.474,
      9.354,
      20.272,
      6.185,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10101",
    "polygon": [
      20.034,
      5.474,
      9.354,
      20.034,
      5.474,
      11.804,
      19.523,
      3.948,
      11.804,
      19.523,
      3.948,
      11.804,
      19.523,
      3.948,
      9.354,
      20.034,
      5.474,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10102",
    "polygon": [
      21.22,
      9.016,
      9.354,
      21.22,
      9.016,
      11.804,
      20.982,
      8.305,
      11.804,
      20.982,
      8.305,
      11.804,
      20.982,
      8.305,
      9.354,
      21.22,
      9.016,
      9.354
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10103",
    "polygon": [
      20.982,
      8.305,
      9.354,
      20.982,
      8.305,
      11.804,
      20.471,
      6.778,
      11.804,
      20.471,
      6.778,
      11.804,
      20.471,
      6.778,
      9.354,
      20.982,
      8.305,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10104",
    "polygon": [
      22.415,
      12.582,
      9.354,
      22.415,
      12.582,
      11.804,
      22.217,
      11.989,
      11.804,
      22.217,
      11.989,
      11.804,
      22.217,
      11.989,
      9.354,
      22.415,
      12.582,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10105",
    "polygon": [
      22.217,
      11.989,
      9.354,
      22.217,
      11.989,
      11.804,
      21.978,
      11.278,
      11.804,
      21.978,
      11.278,
      11.804,
      21.978,
      11.278,
      9.354,
      22.217,
      11.989,
      9.354
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10106",
    "polygon": [
      21.978,
      11.278,
      9.354,
      21.978,
      11.278,
      11.804,
      21.467,
      9.752,
      11.804,
      21.467,
      9.752,
      11.804,
      21.467,
      9.752,
      9.354,
      21.978,
      11.278,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10107",
    "polygon": [
      23.165,
      14.82,
      9.354,
      23.165,
      14.82,
      11.804,
      22.926,
      14.109,
      11.804,
      22.926,
      14.109,
      11.804,
      22.926,
      14.109,
      9.354,
      23.165,
      14.82,
      9.354
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10108",
    "polygon": [
      22.926,
      14.109,
      9.354,
      22.926,
      14.109,
      11.804,
      22.415,
      12.582,
      11.804,
      22.415,
      12.582,
      11.804,
      22.415,
      12.582,
      9.354,
      22.926,
      14.109,
      9.354
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10109",
    "polygon": [
      24.359,
      18.386,
      9.354,
      24.359,
      18.386,
      11.804,
      24.161,
      17.793,
      11.804,
      24.161,
      17.793,
      11.804,
      24.161,
      17.793,
      9.354,
      24.359,
      18.386,
      9.354
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10110",
    "polygon": [
      24.161,
      17.793,
      9.354,
      24.161,
      17.793,
      11.804,
      23.923,
      17.082,
      11.804,
      23.923,
      17.082,
      11.804,
      23.923,
      17.082,
      9.354,
      24.161,
      17.793,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10111",
    "polygon": [
      23.923,
      17.082,
      9.354,
      23.923,
      17.082,
      11.804,
      23.411,
      15.556,
      11.804,
      23.411,
      15.556,
      11.804,
      23.411,
      15.556,
      9.354,
      23.923,
      17.082,
      9.354
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10112",
    "polygon": [
      25.109,
      20.624,
      9.354,
      25.109,
      20.624,
      11.804,
      24.871,
      19.913,
      11.804,
      24.871,
      19.913,
      11.804,
      24.871,
      19.913,
      9.354,
      25.109,
      20.624,
      9.354
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10113",
    "polygon": [
      24.871,
      19.913,
      9.354,
      24.871,
      19.913,
      11.804,
      24.359,
      18.386,
      11.804,
      24.359,
      18.386,
      11.804,
      24.359,
      18.386,
      9.354,
      24.871,
      19.913,
      9.354
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10114",
    "polygon": [
      26.304,
      24.19,
      9.354,
      26.304,
      24.19,
      11.804,
      26.105,
      23.597,
      11.804,
      26.105,
      23.597,
      11.804,
      26.105,
      23.597,
      9.354,
      26.304,
      24.19,
      9.354
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10115",
    "polygon": [
      26.105,
      23.597,
      9.354,
      26.105,
      23.597,
      11.804,
      25.867,
      22.886,
      11.804,
      25.867,
      22.886,
      11.804,
      25.867,
      22.886,
      9.354,
      26.105,
      23.597,
      9.354
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10116",
    "polygon": [
      25.867,
      22.886,
      9.354,
      25.867,
      22.886,
      11.804,
      25.355,
      21.36,
      11.804,
      25.355,
      21.36,
      11.804,
      25.355,
      21.36,
      9.354,
      25.867,
      22.886,
      9.354
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10117",
    "polygon": [
      27.053,
      26.428,
      9.354,
      27.053,
      26.428,
      11.804,
      26.815,
      25.717,
      11.804,
      26.815,
      25.717,
      11.804,
      26.815,
      25.717,
      9.354,
      27.053,
      26.428,
      9.354
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_10118",
    "polygon": [
      26.815,
      25.717,
      9.354,
      26.815,
      25.717,
      11.804,
      26.304,
      24.19,
      11.804,
      26.304,
      24.19,
      11.804,
      26.304,
      24.19,
      9.354,
      26.815,
      25.717,
      9.354
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10119",
    "polygon": [
      14.684,
      -10.497,
      5.604,
      14.684,
      -10.497,
      8.054,
      14.485,
      -11.09,
      8.054,
      14.485,
      -11.09,
      8.054,
      14.485,
      -11.09,
      5.604,
      14.684,
      -10.497,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10120",
    "polygon": [
      14.485,
      -11.09,
      5.604,
      14.485,
      -11.09,
      8.054,
      14.247,
      -11.801,
      8.054,
      14.247,
      -11.801,
      8.054,
      14.247,
      -11.801,
      5.604,
      14.485,
      -11.09,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10121",
    "polygon": [
      14.247,
      -11.801,
      5.604,
      14.247,
      -11.801,
      8.054,
      13.736,
      -13.328,
      8.054,
      13.736,
      -13.328,
      8.054,
      13.736,
      -13.328,
      5.604,
      14.247,
      -11.801,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10122",
    "polygon": [
      15.433,
      -8.26,
      5.604,
      15.433,
      -8.26,
      8.054,
      15.195,
      -8.971,
      8.054,
      15.195,
      -8.971,
      8.054,
      15.195,
      -8.971,
      5.604,
      15.433,
      -8.26,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10123",
    "polygon": [
      15.195,
      -8.971,
      5.604,
      15.195,
      -8.971,
      8.054,
      14.684,
      -10.497,
      8.054,
      14.684,
      -10.497,
      8.054,
      14.684,
      -10.497,
      5.604,
      15.195,
      -8.971,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10124",
    "polygon": [
      16.582,
      -4.83,
      5.604,
      16.582,
      -4.83,
      8.054,
      16.384,
      -5.423,
      8.054,
      16.384,
      -5.423,
      8.054,
      16.384,
      -5.423,
      5.604,
      16.582,
      -4.83,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10125",
    "polygon": [
      16.384,
      -5.423,
      5.604,
      16.384,
      -5.423,
      8.054,
      16.145,
      -6.134,
      8.054,
      16.145,
      -6.134,
      8.054,
      16.145,
      -6.134,
      5.604,
      16.384,
      -5.423,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10126",
    "polygon": [
      16.145,
      -6.134,
      5.604,
      16.145,
      -6.134,
      8.054,
      15.634,
      -7.66,
      8.054,
      15.634,
      -7.66,
      8.054,
      15.634,
      -7.66,
      5.604,
      16.145,
      -6.134,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10127",
    "polygon": [
      17.332,
      -2.592,
      5.604,
      17.332,
      -2.592,
      8.054,
      17.094,
      -3.303,
      8.054,
      17.094,
      -3.303,
      8.054,
      17.094,
      -3.303,
      5.604,
      17.332,
      -2.592,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10128",
    "polygon": [
      17.094,
      -3.303,
      5.604,
      17.094,
      -3.303,
      8.054,
      16.582,
      -4.83,
      8.054,
      16.582,
      -4.83,
      8.054,
      16.582,
      -4.83,
      5.604,
      17.094,
      -3.303,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10129",
    "polygon": [
      18.527,
      0.974,
      5.604,
      18.527,
      0.974,
      8.054,
      18.328,
      0.381,
      8.054,
      18.328,
      0.381,
      8.054,
      18.328,
      0.381,
      5.604,
      18.527,
      0.974,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10130",
    "polygon": [
      18.328,
      0.381,
      5.604,
      18.328,
      0.381,
      8.054,
      18.09,
      -0.33,
      8.054,
      18.09,
      -0.33,
      8.054,
      18.09,
      -0.33,
      5.604,
      18.328,
      0.381,
      5.604
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10131",
    "polygon": [
      18.09,
      -0.33,
      5.604,
      18.09,
      -0.33,
      8.054,
      17.578,
      -1.856,
      8.054,
      17.578,
      -1.856,
      8.054,
      17.578,
      -1.856,
      5.604,
      18.09,
      -0.33,
      5.604
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10132",
    "polygon": [
      19.276,
      3.212,
      5.604,
      19.276,
      3.212,
      8.054,
      19.038,
      2.501,
      8.054,
      19.038,
      2.501,
      8.054,
      19.038,
      2.501,
      5.604,
      19.276,
      3.212,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10133",
    "polygon": [
      19.038,
      2.501,
      5.604,
      19.038,
      2.501,
      8.054,
      18.527,
      0.974,
      8.054,
      18.527,
      0.974,
      8.054,
      18.527,
      0.974,
      5.604,
      19.038,
      2.501,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10134",
    "polygon": [
      20.471,
      6.778,
      5.604,
      20.471,
      6.778,
      8.054,
      20.272,
      6.185,
      8.054,
      20.272,
      6.185,
      8.054,
      20.272,
      6.185,
      5.604,
      20.471,
      6.778,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10135",
    "polygon": [
      20.272,
      6.185,
      5.604,
      20.272,
      6.185,
      8.054,
      20.034,
      5.474,
      8.054,
      20.034,
      5.474,
      8.054,
      20.034,
      5.474,
      5.604,
      20.272,
      6.185,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10136",
    "polygon": [
      20.034,
      5.474,
      5.604,
      20.034,
      5.474,
      8.054,
      19.523,
      3.948,
      8.054,
      19.523,
      3.948,
      8.054,
      19.523,
      3.948,
      5.604,
      20.034,
      5.474,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10137",
    "polygon": [
      21.22,
      9.016,
      5.604,
      21.22,
      9.016,
      8.054,
      20.982,
      8.305,
      8.054,
      20.982,
      8.305,
      8.054,
      20.982,
      8.305,
      5.604,
      21.22,
      9.016,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10138",
    "polygon": [
      20.982,
      8.305,
      5.604,
      20.982,
      8.305,
      8.054,
      20.471,
      6.778,
      8.054,
      20.471,
      6.778,
      8.054,
      20.471,
      6.778,
      5.604,
      20.982,
      8.305,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10139",
    "polygon": [
      22.415,
      12.582,
      5.604,
      22.415,
      12.582,
      8.054,
      22.217,
      11.989,
      8.054,
      22.217,
      11.989,
      8.054,
      22.217,
      11.989,
      5.604,
      22.415,
      12.582,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10140",
    "polygon": [
      22.217,
      11.989,
      5.604,
      22.217,
      11.989,
      8.054,
      21.978,
      11.278,
      8.054,
      21.978,
      11.278,
      8.054,
      21.978,
      11.278,
      5.604,
      22.217,
      11.989,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10141",
    "polygon": [
      21.978,
      11.278,
      5.604,
      21.978,
      11.278,
      8.054,
      21.467,
      9.752,
      8.054,
      21.467,
      9.752,
      8.054,
      21.467,
      9.752,
      5.604,
      21.978,
      11.278,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10142",
    "polygon": [
      23.165,
      14.82,
      5.604,
      23.165,
      14.82,
      8.054,
      22.926,
      14.109,
      8.054,
      22.926,
      14.109,
      8.054,
      22.926,
      14.109,
      5.604,
      23.165,
      14.82,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10143",
    "polygon": [
      22.926,
      14.109,
      5.604,
      22.926,
      14.109,
      8.054,
      22.415,
      12.582,
      8.054,
      22.415,
      12.582,
      8.054,
      22.415,
      12.582,
      5.604,
      22.926,
      14.109,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10144",
    "polygon": [
      24.359,
      18.386,
      5.604,
      24.359,
      18.386,
      8.054,
      24.161,
      17.793,
      8.054,
      24.161,
      17.793,
      8.054,
      24.161,
      17.793,
      5.604,
      24.359,
      18.386,
      5.604
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10145",
    "polygon": [
      24.161,
      17.793,
      5.604,
      24.161,
      17.793,
      8.054,
      23.923,
      17.082,
      8.054,
      23.923,
      17.082,
      8.054,
      23.923,
      17.082,
      5.604,
      24.161,
      17.793,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10146",
    "polygon": [
      23.923,
      17.082,
      5.604,
      23.923,
      17.082,
      8.054,
      23.411,
      15.556,
      8.054,
      23.411,
      15.556,
      8.054,
      23.411,
      15.556,
      5.604,
      23.923,
      17.082,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10147",
    "polygon": [
      25.109,
      20.624,
      5.604,
      25.109,
      20.624,
      8.054,
      24.871,
      19.913,
      8.054,
      24.871,
      19.913,
      8.054,
      24.871,
      19.913,
      5.604,
      25.109,
      20.624,
      5.604
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10148",
    "polygon": [
      24.871,
      19.913,
      5.604,
      24.871,
      19.913,
      8.054,
      24.359,
      18.386,
      8.054,
      24.359,
      18.386,
      8.054,
      24.359,
      18.386,
      5.604,
      24.871,
      19.913,
      5.604
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10149",
    "polygon": [
      26.304,
      24.19,
      5.604,
      26.304,
      24.19,
      8.054,
      26.105,
      23.597,
      8.054,
      26.105,
      23.597,
      8.054,
      26.105,
      23.597,
      5.604,
      26.304,
      24.19,
      5.604
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10150",
    "polygon": [
      26.105,
      23.597,
      5.604,
      26.105,
      23.597,
      8.054,
      25.867,
      22.886,
      8.054,
      25.867,
      22.886,
      8.054,
      25.867,
      22.886,
      5.604,
      26.105,
      23.597,
      5.604
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10151",
    "polygon": [
      25.867,
      22.886,
      5.604,
      25.867,
      22.886,
      8.054,
      25.355,
      21.36,
      8.054,
      25.355,
      21.36,
      8.054,
      25.355,
      21.36,
      5.604,
      25.867,
      22.886,
      5.604
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10152",
    "polygon": [
      27.053,
      26.428,
      5.604,
      27.053,
      26.428,
      8.054,
      26.815,
      25.717,
      8.054,
      26.815,
      25.717,
      8.054,
      26.815,
      25.717,
      5.604,
      27.053,
      26.428,
      5.604
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_10153",
    "polygon": [
      26.815,
      25.717,
      5.604,
      26.815,
      25.717,
      8.054,
      26.304,
      24.19,
      8.054,
      26.304,
      24.19,
      8.054,
      26.304,
      24.19,
      5.604,
      26.815,
      25.717,
      5.604
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_10154",
    "polygon": [
      16.582,
      -4.83,
      0.605,
      16.582,
      -4.83,
      3.365,
      16.384,
      -5.423,
      3.365,
      16.384,
      -5.423,
      3.365,
      16.384,
      -5.423,
      0.605,
      16.582,
      -4.83,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10155",
    "polygon": [
      16.384,
      -5.423,
      0.605,
      16.384,
      -5.423,
      3.365,
      16.145,
      -6.134,
      3.365,
      16.145,
      -6.134,
      3.365,
      16.145,
      -6.134,
      0.605,
      16.384,
      -5.423,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10156",
    "polygon": [
      16.145,
      -6.134,
      0.605,
      16.145,
      -6.134,
      3.365,
      15.634,
      -7.66,
      3.365,
      15.634,
      -7.66,
      3.365,
      15.634,
      -7.66,
      0.605,
      16.145,
      -6.134,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_10157",
    "polygon": [
      17.332,
      -2.592,
      0.605,
      17.332,
      -2.592,
      3.365,
      17.094,
      -3.303,
      3.365,
      17.094,
      -3.303,
      3.365,
      17.094,
      -3.303,
      0.605,
      17.332,
      -2.592,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10158",
    "polygon": [
      17.094,
      -3.303,
      0.605,
      17.094,
      -3.303,
      3.365,
      16.582,
      -4.83,
      3.365,
      16.582,
      -4.83,
      3.365,
      16.582,
      -4.83,
      0.605,
      17.094,
      -3.303,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10159",
    "polygon": [
      18.527,
      0.974,
      0.605,
      18.527,
      0.974,
      3.365,
      18.328,
      0.381,
      3.365,
      18.328,
      0.381,
      3.365,
      18.328,
      0.381,
      0.605,
      18.527,
      0.974,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10160",
    "polygon": [
      18.328,
      0.381,
      0.605,
      18.328,
      0.381,
      3.365,
      18.09,
      -0.33,
      3.365,
      18.09,
      -0.33,
      3.365,
      18.09,
      -0.33,
      0.605,
      18.328,
      0.381,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10161",
    "polygon": [
      18.09,
      -0.33,
      0.605,
      18.09,
      -0.33,
      3.365,
      17.578,
      -1.856,
      3.365,
      17.578,
      -1.856,
      3.365,
      17.578,
      -1.856,
      0.605,
      18.09,
      -0.33,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_10162",
    "polygon": [
      19.276,
      3.212,
      0.605,
      19.276,
      3.212,
      3.365,
      19.038,
      2.501,
      3.365,
      19.038,
      2.501,
      3.365,
      19.038,
      2.501,
      0.605,
      19.276,
      3.212,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10163",
    "polygon": [
      19.038,
      2.501,
      0.605,
      19.038,
      2.501,
      3.365,
      18.527,
      0.974,
      3.365,
      18.527,
      0.974,
      3.365,
      18.527,
      0.974,
      0.605,
      19.038,
      2.501,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10164",
    "polygon": [
      20.471,
      6.778,
      0.605,
      20.471,
      6.778,
      3.365,
      20.272,
      6.185,
      3.365,
      20.272,
      6.185,
      3.365,
      20.272,
      6.185,
      0.605,
      20.471,
      6.778,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10165",
    "polygon": [
      20.272,
      6.185,
      0.605,
      20.272,
      6.185,
      3.365,
      20.034,
      5.474,
      3.365,
      20.034,
      5.474,
      3.365,
      20.034,
      5.474,
      0.605,
      20.272,
      6.185,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10166",
    "polygon": [
      20.034,
      5.474,
      0.605,
      20.034,
      5.474,
      3.365,
      19.523,
      3.948,
      3.365,
      19.523,
      3.948,
      3.365,
      19.523,
      3.948,
      0.605,
      20.034,
      5.474,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_10167",
    "polygon": [
      21.22,
      9.016,
      0.605,
      21.22,
      9.016,
      3.365,
      20.982,
      8.305,
      3.365,
      20.982,
      8.305,
      3.365,
      20.982,
      8.305,
      0.605,
      21.22,
      9.016,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10168",
    "polygon": [
      20.982,
      8.305,
      0.605,
      20.982,
      8.305,
      3.365,
      20.471,
      6.778,
      3.365,
      20.471,
      6.778,
      3.365,
      20.471,
      6.778,
      0.605,
      20.982,
      8.305,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10169",
    "polygon": [
      22.415,
      12.582,
      0.605,
      22.415,
      12.582,
      3.365,
      22.217,
      11.989,
      3.365,
      22.217,
      11.989,
      3.365,
      22.217,
      11.989,
      0.605,
      22.415,
      12.582,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10170",
    "polygon": [
      22.217,
      11.989,
      0.605,
      22.217,
      11.989,
      3.365,
      21.978,
      11.278,
      3.365,
      21.978,
      11.278,
      3.365,
      21.978,
      11.278,
      0.605,
      22.217,
      11.989,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10171",
    "polygon": [
      21.978,
      11.278,
      0.605,
      21.978,
      11.278,
      3.365,
      21.467,
      9.752,
      3.365,
      21.467,
      9.752,
      3.365,
      21.467,
      9.752,
      0.605,
      21.978,
      11.278,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_10172",
    "polygon": [
      23.165,
      14.82,
      0.605,
      23.165,
      14.82,
      3.365,
      22.926,
      14.109,
      3.365,
      22.926,
      14.109,
      3.365,
      22.926,
      14.109,
      0.605,
      23.165,
      14.82,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10173",
    "polygon": [
      22.926,
      14.109,
      0.605,
      22.926,
      14.109,
      3.365,
      22.415,
      12.582,
      3.365,
      22.415,
      12.582,
      3.365,
      22.415,
      12.582,
      0.605,
      22.926,
      14.109,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10174",
    "polygon": [
      24.359,
      18.386,
      0.605,
      24.359,
      18.386,
      3.365,
      24.161,
      17.793,
      3.365,
      24.161,
      17.793,
      3.365,
      24.161,
      17.793,
      0.605,
      24.359,
      18.386,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10175",
    "polygon": [
      24.161,
      17.793,
      0.605,
      24.161,
      17.793,
      3.365,
      23.923,
      17.082,
      3.365,
      23.923,
      17.082,
      3.365,
      23.923,
      17.082,
      0.605,
      24.161,
      17.793,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10176",
    "polygon": [
      23.923,
      17.082,
      0.605,
      23.923,
      17.082,
      3.365,
      23.411,
      15.556,
      3.365,
      23.411,
      15.556,
      3.365,
      23.411,
      15.556,
      0.605,
      23.923,
      17.082,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_10177",
    "polygon": [
      25.109,
      20.624,
      0.605,
      25.109,
      20.624,
      3.365,
      24.871,
      19.913,
      3.365,
      24.871,
      19.913,
      3.365,
      24.871,
      19.913,
      0.605,
      25.109,
      20.624,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10178",
    "polygon": [
      24.871,
      19.913,
      0.605,
      24.871,
      19.913,
      3.365,
      24.359,
      18.386,
      3.365,
      24.359,
      18.386,
      3.365,
      24.359,
      18.386,
      0.605,
      24.871,
      19.913,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10179",
    "polygon": [
      26.304,
      24.19,
      0.605,
      26.304,
      24.19,
      3.365,
      26.105,
      23.597,
      3.365,
      26.105,
      23.597,
      3.365,
      26.105,
      23.597,
      0.605,
      26.304,
      24.19,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_10180",
    "polygon": [
      26.105,
      23.597,
      0.605,
      26.105,
      23.597,
      3.365,
      25.867,
      22.886,
      3.365,
      25.867,
      22.886,
      3.365,
      25.867,
      22.886,
      0.605,
      26.105,
      23.597,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10181",
    "polygon": [
      25.867,
      22.886,
      0.605,
      25.867,
      22.886,
      3.365,
      25.355,
      21.36,
      3.365,
      25.355,
      21.36,
      3.365,
      25.355,
      21.36,
      0.605,
      25.867,
      22.886,
      0.605
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_10182",
    "polygon": [
      27.053,
      26.428,
      0.605,
      27.053,
      26.428,
      3.365,
      26.815,
      25.717,
      3.365,
      26.815,
      25.717,
      3.365,
      26.815,
      25.717,
      0.605,
      27.053,
      26.428,
      0.605
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_10183",
    "polygon": [
      26.815,
      25.717,
      0.605,
      26.815,
      25.717,
      3.365,
      26.304,
      24.19,
      3.365,
      26.304,
      24.19,
      3.365,
      26.304,
      24.19,
      0.605,
      26.815,
      25.717,
      0.605
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10184",
    "polygon": [
      -16.347,
      78.372,
      13.073,
      -16.347,
      78.372,
      15.523,
      -14.82,
      77.861,
      15.523,
      -14.82,
      77.861,
      15.523,
      -14.82,
      77.861,
      13.073,
      -16.347,
      78.372,
      13.073
    ]
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10185",
    "polygon": [
      -17.058,
      78.61,
      13.073,
      -17.058,
      78.61,
      15.523,
      -16.347,
      78.372,
      15.523,
      -16.347,
      78.372,
      15.523,
      -16.347,
      78.372,
      13.073,
      -17.058,
      78.61,
      13.073
    ]
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10186",
    "polygon": [
      -19.321,
      79.367,
      13.073,
      -19.321,
      79.367,
      15.523,
      -17.794,
      78.856,
      15.523,
      -17.794,
      78.856,
      15.523,
      -17.794,
      78.856,
      13.073,
      -19.321,
      79.367,
      13.073
    ]
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10187",
    "polygon": [
      -25.842,
      81.55,
      13.073,
      -25.842,
      81.55,
      15.523,
      -25.131,
      81.312,
      15.523,
      -25.131,
      81.312,
      15.523,
      -25.131,
      81.312,
      13.073,
      -25.842,
      81.55,
      13.073
    ]
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10188",
    "polygon": [
      -27.369,
      82.061,
      13.073,
      -27.369,
      82.061,
      15.523,
      -25.842,
      81.55,
      15.523,
      -25.842,
      81.55,
      15.523,
      -25.842,
      81.55,
      13.073,
      -27.369,
      82.061,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10189",
    "polygon": [
      -13.517,
      77.423,
      9.323,
      -13.517,
      77.423,
      11.773,
      -11.99,
      76.912,
      11.773,
      -11.99,
      76.912,
      11.773,
      -11.99,
      76.912,
      9.323,
      -13.517,
      77.423,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10190",
    "polygon": [
      -14.228,
      77.661,
      9.323,
      -14.228,
      77.661,
      11.773,
      -13.517,
      77.423,
      11.773,
      -13.517,
      77.423,
      11.773,
      -13.517,
      77.423,
      9.323,
      -14.228,
      77.661,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10191",
    "polygon": [
      -14.821,
      77.86,
      9.323,
      -14.821,
      77.86,
      11.773,
      -14.228,
      77.661,
      11.773,
      -14.228,
      77.661,
      11.773,
      -14.228,
      77.661,
      9.323,
      -14.821,
      77.86,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10192",
    "polygon": [
      -16.347,
      78.371,
      9.323,
      -16.347,
      78.371,
      11.773,
      -14.821,
      77.86,
      11.773,
      -14.821,
      77.86,
      11.773,
      -14.821,
      77.86,
      9.323,
      -16.347,
      78.371,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10193",
    "polygon": [
      -17.058,
      78.609,
      9.323,
      -17.058,
      78.609,
      11.773,
      -16.347,
      78.371,
      11.773,
      -16.347,
      78.371,
      11.773,
      -16.347,
      78.371,
      9.323,
      -17.058,
      78.609,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10194",
    "polygon": [
      -19.321,
      79.367,
      9.323,
      -19.321,
      79.367,
      11.773,
      -17.794,
      78.856,
      11.773,
      -17.794,
      78.856,
      11.773,
      -17.794,
      78.856,
      9.323,
      -19.321,
      79.367,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10195",
    "polygon": [
      -25.842,
      81.552,
      9.323,
      -25.842,
      81.552,
      11.773,
      -25.131,
      81.313,
      11.773,
      -25.131,
      81.313,
      11.773,
      -25.131,
      81.313,
      9.323,
      -25.842,
      81.552,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10196",
    "polygon": [
      -27.368,
      82.063,
      9.323,
      -27.368,
      82.063,
      11.773,
      -25.842,
      81.552,
      11.773,
      -25.842,
      81.552,
      11.773,
      -25.842,
      81.552,
      9.323,
      -27.368,
      82.063,
      9.323
    ],
    "05-22 05:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10197",
    "polygon": [
      -13.517,
      77.424,
      5.573,
      -13.517,
      77.424,
      8.023,
      -11.99,
      76.913,
      8.023,
      -11.99,
      76.913,
      8.023,
      -11.99,
      76.913,
      5.573,
      -13.517,
      77.424,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10198",
    "polygon": [
      -14.228,
      77.662,
      5.573,
      -14.228,
      77.662,
      8.023,
      -13.517,
      77.424,
      8.023,
      -13.517,
      77.424,
      8.023,
      -13.517,
      77.424,
      5.573,
      -14.228,
      77.662,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10199",
    "polygon": [
      -14.82,
      77.861,
      5.573,
      -14.82,
      77.861,
      8.023,
      -14.228,
      77.662,
      8.023,
      -14.228,
      77.662,
      8.023,
      -14.228,
      77.662,
      5.573,
      -14.82,
      77.861,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10200",
    "polygon": [
      -16.347,
      78.372,
      5.573,
      -16.347,
      78.372,
      8.023,
      -14.82,
      77.861,
      8.023,
      -14.82,
      77.861,
      8.023,
      -14.82,
      77.861,
      5.573,
      -16.347,
      78.372,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10201",
    "polygon": [
      -17.058,
      78.61,
      5.573,
      -17.058,
      78.61,
      8.023,
      -16.347,
      78.372,
      8.023,
      -16.347,
      78.372,
      8.023,
      -16.347,
      78.372,
      5.573,
      -17.058,
      78.61,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10202",
    "polygon": [
      -19.321,
      79.368,
      5.573,
      -19.321,
      79.368,
      8.023,
      -17.794,
      78.857,
      8.023,
      -17.794,
      78.857,
      8.023,
      -17.794,
      78.857,
      5.573,
      -19.321,
      79.368,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10203",
    "polygon": [
      -25.841,
      81.553,
      5.573,
      -25.841,
      81.553,
      8.023,
      -25.13,
      81.314,
      8.023,
      -25.13,
      81.314,
      8.023,
      -25.13,
      81.314,
      5.573,
      -25.841,
      81.553,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10204",
    "polygon": [
      -27.368,
      82.064,
      5.573,
      -27.368,
      82.064,
      8.023,
      -25.841,
      81.553,
      8.023,
      -25.841,
      81.553,
      8.023,
      -25.841,
      81.553,
      5.573,
      -27.368,
      82.064,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_10205",
    "polygon": [
      -13.517,
      77.422,
      0.523,
      -13.517,
      77.422,
      3.523,
      -11.991,
      76.911,
      3.523,
      -11.991,
      76.911,
      3.523,
      -11.991,
      76.911,
      0.523,
      -13.517,
      77.422,
      0.523
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_10206",
    "polygon": [
      -14.228,
      77.66,
      0.523,
      -14.228,
      77.66,
      3.523,
      -13.517,
      77.422,
      3.523,
      -13.517,
      77.422,
      3.523,
      -13.517,
      77.422,
      0.523,
      -14.228,
      77.66,
      0.523
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_10207",
    "polygon": [
      -14.821,
      77.859,
      0.523,
      -14.821,
      77.859,
      3.523,
      -14.228,
      77.66,
      3.523,
      -14.228,
      77.66,
      3.523,
      -14.228,
      77.66,
      0.523,
      -14.821,
      77.859,
      0.523
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_10208",
    "polygon": [
      -16.348,
      78.37,
      0.523,
      -16.348,
      78.37,
      3.523,
      -14.821,
      77.859,
      3.523,
      -14.821,
      77.859,
      3.523,
      -14.821,
      77.859,
      0.523,
      -16.348,
      78.37,
      0.523
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_10209",
    "polygon": [
      -17.059,
      78.609,
      0.523,
      -17.059,
      78.609,
      3.523,
      -16.348,
      78.37,
      3.523,
      -16.348,
      78.37,
      3.523,
      -16.348,
      78.37,
      0.523,
      -17.059,
      78.609,
      0.523
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_10210",
    "polygon": [
      -19.321,
      79.366,
      0.523,
      -19.321,
      79.366,
      3.523,
      -17.795,
      78.855,
      3.523,
      -17.795,
      78.855,
      3.523,
      -17.795,
      78.855,
      0.523,
      -19.321,
      79.366,
      0.523
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_10211",
    "polygon": [
      -26.067,
      81.626,
      0.523,
      -26.067,
      81.626,
      2.948,
      -24.991,
      81.266,
      2.948,
      -24.991,
      81.266,
      2.948,
      -24.991,
      81.266,
      0.523,
      -26.067,
      81.626,
      0.523
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_10212",
    "polygon": [
      -27.143,
      81.987,
      0.523,
      -27.143,
      81.987,
      2.948,
      -26.067,
      81.626,
      2.948,
      -26.067,
      81.626,
      2.948,
      -26.067,
      81.626,
      0.523,
      -27.143,
      81.987,
      0.523
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_10213",
    "polygon": [
      -27.143,
      81.987,
      2.948,
      -27.143,
      81.987,
      3.645,
      -24.991,
      81.266,
      3.645,
      -24.991,
      81.266,
      3.645,
      -24.991,
      81.266,
      2.948,
      -27.143,
      81.987,
      2.948
    ]
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10214",
    "polygon": [
      -30.642,
      86.38,
      13.073,
      -30.642,
      86.38,
      15.523,
      -30.88,
      85.669,
      15.523,
      -30.88,
      85.669,
      15.523,
      -30.88,
      85.669,
      13.073,
      -30.642,
      86.38,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10215",
    "polygon": [
      -30.443,
      86.973,
      13.073,
      -30.443,
      86.973,
      15.523,
      -30.642,
      86.38,
      15.523,
      -30.642,
      86.38,
      15.523,
      -30.642,
      86.38,
      13.073,
      -30.443,
      86.973,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10216",
    "polygon": [
      -29.932,
      88.499,
      13.073,
      -29.932,
      88.499,
      15.523,
      -30.443,
      86.973,
      15.523,
      -30.443,
      86.973,
      15.523,
      -30.443,
      86.973,
      13.073,
      -29.932,
      88.499,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10217",
    "polygon": [
      -29.693,
      89.211,
      13.073,
      -29.693,
      89.211,
      15.523,
      -29.932,
      88.499,
      15.523,
      -29.932,
      88.499,
      15.523,
      -29.932,
      88.499,
      13.073,
      -29.693,
      89.211,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10218",
    "polygon": [
      -28.936,
      91.473,
      13.073,
      -28.936,
      91.473,
      15.523,
      -29.447,
      89.946,
      15.523,
      -29.447,
      89.946,
      15.523,
      -29.447,
      89.946,
      13.073,
      -28.936,
      91.473,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10219",
    "polygon": [
      -28.697,
      92.184,
      13.073,
      -28.697,
      92.184,
      15.523,
      -28.936,
      91.473,
      15.523,
      -28.936,
      91.473,
      15.523,
      -28.936,
      91.473,
      13.073,
      -28.697,
      92.184,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10220",
    "polygon": [
      -28.499,
      92.777,
      13.073,
      -28.499,
      92.777,
      15.523,
      -28.697,
      92.184,
      15.523,
      -28.697,
      92.184,
      15.523,
      -28.697,
      92.184,
      13.073,
      -28.499,
      92.777,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10221",
    "polygon": [
      -27.987,
      94.303,
      13.073,
      -27.987,
      94.303,
      15.523,
      -28.499,
      92.777,
      15.523,
      -28.499,
      92.777,
      15.523,
      -28.499,
      92.777,
      13.073,
      -27.987,
      94.303,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10222",
    "polygon": [
      -27.749,
      95.015,
      13.073,
      -27.749,
      95.015,
      15.523,
      -27.987,
      94.303,
      15.523,
      -27.987,
      94.303,
      15.523,
      -27.987,
      94.303,
      13.073,
      -27.749,
      95.015,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10223",
    "polygon": [
      -26.991,
      97.277,
      13.073,
      -26.991,
      97.277,
      15.523,
      -27.503,
      95.75,
      15.523,
      -27.503,
      95.75,
      15.523,
      -27.503,
      95.75,
      13.073,
      -26.991,
      97.277,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10224",
    "polygon": [
      -26.753,
      97.988,
      13.073,
      -26.753,
      97.988,
      15.523,
      -26.991,
      97.277,
      15.523,
      -26.991,
      97.277,
      15.523,
      -26.991,
      97.277,
      13.073,
      -26.753,
      97.988,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10225",
    "polygon": [
      -26.555,
      98.581,
      13.073,
      -26.555,
      98.581,
      15.523,
      -26.753,
      97.988,
      15.523,
      -26.753,
      97.988,
      15.523,
      -26.753,
      97.988,
      13.073,
      -26.555,
      98.581,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10226",
    "polygon": [
      -26.043,
      100.107,
      13.073,
      -26.043,
      100.107,
      15.523,
      -26.555,
      98.581,
      15.523,
      -26.555,
      98.581,
      15.523,
      -26.555,
      98.581,
      13.073,
      -26.043,
      100.107,
      13.073
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10227",
    "polygon": [
      -25.805,
      100.819,
      13.073,
      -25.805,
      100.819,
      15.523,
      -26.043,
      100.107,
      15.523,
      -26.043,
      100.107,
      15.523,
      -26.043,
      100.107,
      13.073,
      -25.805,
      100.819,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10228",
    "polygon": [
      -25.053,
      103.062,
      13.073,
      -25.053,
      103.062,
      15.523,
      -25.565,
      101.535,
      15.523,
      -25.565,
      101.535,
      15.523,
      -25.565,
      101.535,
      13.073,
      -25.053,
      103.062,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10229",
    "polygon": [
      -24.815,
      103.773,
      13.073,
      -24.815,
      103.773,
      15.523,
      -25.053,
      103.062,
      15.523,
      -25.053,
      103.062,
      15.523,
      -25.053,
      103.062,
      13.073,
      -24.815,
      103.773,
      13.073
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10230",
    "polygon": [
      -24.617,
      104.366,
      13.073,
      -24.617,
      104.366,
      15.523,
      -24.815,
      103.773,
      15.523,
      -24.815,
      103.773,
      15.523,
      -24.815,
      103.773,
      13.073,
      -24.617,
      104.366,
      13.073
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10231",
    "polygon": [
      -24.105,
      105.892,
      13.073,
      -24.105,
      105.892,
      15.523,
      -24.617,
      104.366,
      15.523,
      -24.617,
      104.366,
      15.523,
      -24.617,
      104.366,
      13.073,
      -24.105,
      105.892,
      13.073
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_10232",
    "polygon": [
      -23.867,
      106.604,
      13.073,
      -23.867,
      106.604,
      15.523,
      -24.105,
      105.892,
      15.523,
      -24.105,
      105.892,
      15.523,
      -24.105,
      105.892,
      13.073,
      -23.867,
      106.604,
      13.073
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10233",
    "polygon": [
      -30.88,
      85.669,
      9.323,
      -30.88,
      85.669,
      11.773,
      -31.391,
      84.142,
      11.773,
      -31.391,
      84.142,
      11.773,
      -31.391,
      84.142,
      9.323,
      -30.88,
      85.669,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10234",
    "polygon": [
      -30.642,
      86.38,
      9.323,
      -30.642,
      86.38,
      11.773,
      -30.88,
      85.669,
      11.773,
      -30.88,
      85.669,
      11.773,
      -30.88,
      85.669,
      9.323,
      -30.642,
      86.38,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10235",
    "polygon": [
      -30.443,
      86.973,
      9.323,
      -30.443,
      86.973,
      11.773,
      -30.642,
      86.38,
      11.773,
      -30.642,
      86.38,
      11.773,
      -30.642,
      86.38,
      9.323,
      -30.443,
      86.973,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10236",
    "polygon": [
      -29.932,
      88.499,
      9.323,
      -29.932,
      88.499,
      11.773,
      -30.443,
      86.973,
      11.773,
      -30.443,
      86.973,
      11.773,
      -30.443,
      86.973,
      9.323,
      -29.932,
      88.499,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10237",
    "polygon": [
      -29.693,
      89.211,
      9.323,
      -29.693,
      89.211,
      11.773,
      -29.932,
      88.499,
      11.773,
      -29.932,
      88.499,
      11.773,
      -29.932,
      88.499,
      9.323,
      -29.693,
      89.211,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10238",
    "polygon": [
      -28.936,
      91.473,
      9.323,
      -28.936,
      91.473,
      11.773,
      -29.447,
      89.946,
      11.773,
      -29.447,
      89.946,
      11.773,
      -29.447,
      89.946,
      9.323,
      -28.936,
      91.473,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10239",
    "polygon": [
      -28.697,
      92.184,
      9.323,
      -28.697,
      92.184,
      11.773,
      -28.936,
      91.473,
      11.773,
      -28.936,
      91.473,
      11.773,
      -28.936,
      91.473,
      9.323,
      -28.697,
      92.184,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10240",
    "polygon": [
      -28.499,
      92.777,
      9.323,
      -28.499,
      92.777,
      11.773,
      -28.697,
      92.184,
      11.773,
      -28.697,
      92.184,
      11.773,
      -28.697,
      92.184,
      9.323,
      -28.499,
      92.777,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10241",
    "polygon": [
      -27.987,
      94.303,
      9.323,
      -27.987,
      94.303,
      11.773,
      -28.499,
      92.777,
      11.773,
      -28.499,
      92.777,
      11.773,
      -28.499,
      92.777,
      9.323,
      -27.987,
      94.303,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10242",
    "polygon": [
      -27.749,
      95.015,
      9.323,
      -27.749,
      95.015,
      11.773,
      -27.987,
      94.303,
      11.773,
      -27.987,
      94.303,
      11.773,
      -27.987,
      94.303,
      9.323,
      -27.749,
      95.015,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10243",
    "polygon": [
      -26.991,
      97.277,
      9.323,
      -26.991,
      97.277,
      11.773,
      -27.503,
      95.75,
      11.773,
      -27.503,
      95.75,
      11.773,
      -27.503,
      95.75,
      9.323,
      -26.991,
      97.277,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10244",
    "polygon": [
      -26.753,
      97.988,
      9.323,
      -26.753,
      97.988,
      11.773,
      -26.991,
      97.277,
      11.773,
      -26.991,
      97.277,
      11.773,
      -26.991,
      97.277,
      9.323,
      -26.753,
      97.988,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10245",
    "polygon": [
      -26.555,
      98.581,
      9.323,
      -26.555,
      98.581,
      11.773,
      -26.753,
      97.988,
      11.773,
      -26.753,
      97.988,
      11.773,
      -26.753,
      97.988,
      9.323,
      -26.555,
      98.581,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10246",
    "polygon": [
      -26.043,
      100.107,
      9.323,
      -26.043,
      100.107,
      11.773,
      -26.555,
      98.581,
      11.773,
      -26.555,
      98.581,
      11.773,
      -26.555,
      98.581,
      9.323,
      -26.043,
      100.107,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10247",
    "polygon": [
      -25.805,
      100.819,
      9.323,
      -25.805,
      100.819,
      11.773,
      -26.043,
      100.107,
      11.773,
      -26.043,
      100.107,
      11.773,
      -26.043,
      100.107,
      9.323,
      -25.805,
      100.819,
      9.323
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10248",
    "polygon": [
      -25.053,
      103.062,
      9.323,
      -25.053,
      103.062,
      11.773,
      -25.565,
      101.535,
      11.773,
      -25.565,
      101.535,
      11.773,
      -25.565,
      101.535,
      9.323,
      -25.053,
      103.062,
      9.323
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10249",
    "polygon": [
      -24.815,
      103.773,
      9.323,
      -24.815,
      103.773,
      11.773,
      -25.053,
      103.062,
      11.773,
      -25.053,
      103.062,
      11.773,
      -25.053,
      103.062,
      9.323,
      -24.815,
      103.773,
      9.323
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10250",
    "polygon": [
      -24.617,
      104.366,
      9.323,
      -24.617,
      104.366,
      11.773,
      -24.815,
      103.773,
      11.773,
      -24.815,
      103.773,
      11.773,
      -24.815,
      103.773,
      9.323,
      -24.617,
      104.366,
      9.323
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10251",
    "polygon": [
      -24.105,
      105.892,
      9.323,
      -24.105,
      105.892,
      11.773,
      -24.617,
      104.366,
      11.773,
      -24.617,
      104.366,
      11.773,
      -24.617,
      104.366,
      9.323,
      -24.105,
      105.892,
      9.323
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_10252",
    "polygon": [
      -23.867,
      106.604,
      9.323,
      -23.867,
      106.604,
      11.773,
      -24.105,
      105.892,
      11.773,
      -24.105,
      105.892,
      11.773,
      -24.105,
      105.892,
      9.323,
      -23.867,
      106.604,
      9.323
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10253",
    "polygon": [
      -30.874,
      85.667,
      5.573,
      -30.874,
      85.667,
      8.023,
      -31.385,
      84.14,
      8.023,
      -31.385,
      84.14,
      8.023,
      -31.385,
      84.14,
      5.573,
      -30.874,
      85.667,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10254",
    "polygon": [
      -30.636,
      86.378,
      5.573,
      -30.636,
      86.378,
      8.023,
      -30.874,
      85.667,
      8.023,
      -30.874,
      85.667,
      8.023,
      -30.874,
      85.667,
      5.573,
      -30.636,
      86.378,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10255",
    "polygon": [
      -30.437,
      86.971,
      5.573,
      -30.437,
      86.971,
      8.023,
      -30.636,
      86.378,
      8.023,
      -30.636,
      86.378,
      8.023,
      -30.636,
      86.378,
      5.573,
      -30.437,
      86.971,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10256",
    "polygon": [
      -29.926,
      88.497,
      5.573,
      -29.926,
      88.497,
      8.023,
      -30.437,
      86.971,
      8.023,
      -30.437,
      86.971,
      8.023,
      -30.437,
      86.971,
      5.573,
      -29.926,
      88.497,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10257",
    "polygon": [
      -29.688,
      89.209,
      5.573,
      -29.688,
      89.209,
      8.023,
      -29.926,
      88.497,
      8.023,
      -29.926,
      88.497,
      8.023,
      -29.926,
      88.497,
      5.573,
      -29.688,
      89.209,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10258",
    "polygon": [
      -28.93,
      91.471,
      5.573,
      -28.93,
      91.471,
      8.023,
      -29.441,
      89.944,
      8.023,
      -29.441,
      89.944,
      8.023,
      -29.441,
      89.944,
      5.573,
      -28.93,
      91.471,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10259",
    "polygon": [
      -28.692,
      92.182,
      5.573,
      -28.692,
      92.182,
      8.023,
      -28.93,
      91.471,
      8.023,
      -28.93,
      91.471,
      8.023,
      -28.93,
      91.471,
      5.573,
      -28.692,
      92.182,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10260",
    "polygon": [
      -28.493,
      92.775,
      5.573,
      -28.493,
      92.775,
      8.023,
      -28.692,
      92.182,
      8.023,
      -28.692,
      92.182,
      8.023,
      -28.692,
      92.182,
      5.573,
      -28.493,
      92.775,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10261",
    "polygon": [
      -27.982,
      94.301,
      5.573,
      -27.982,
      94.301,
      8.023,
      -28.493,
      92.775,
      8.023,
      -28.493,
      92.775,
      8.023,
      -28.493,
      92.775,
      5.573,
      -27.982,
      94.301,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10262",
    "polygon": [
      -27.743,
      95.013,
      5.573,
      -27.743,
      95.013,
      8.023,
      -27.982,
      94.301,
      8.023,
      -27.982,
      94.301,
      8.023,
      -27.982,
      94.301,
      5.573,
      -27.743,
      95.013,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10263",
    "polygon": [
      -26.986,
      97.275,
      5.573,
      -26.986,
      97.275,
      8.023,
      -27.497,
      95.748,
      8.023,
      -27.497,
      95.748,
      8.023,
      -27.497,
      95.748,
      5.573,
      -26.986,
      97.275,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10264",
    "polygon": [
      -26.747,
      97.986,
      5.573,
      -26.747,
      97.986,
      8.023,
      -26.986,
      97.275,
      8.023,
      -26.986,
      97.275,
      8.023,
      -26.986,
      97.275,
      5.573,
      -26.747,
      97.986,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10265",
    "polygon": [
      -26.549,
      98.579,
      5.573,
      -26.549,
      98.579,
      8.023,
      -26.747,
      97.986,
      8.023,
      -26.747,
      97.986,
      8.023,
      -26.747,
      97.986,
      5.573,
      -26.549,
      98.579,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10266",
    "polygon": [
      -26.037,
      100.106,
      5.573,
      -26.037,
      100.106,
      8.023,
      -26.549,
      98.579,
      8.023,
      -26.549,
      98.579,
      8.023,
      -26.549,
      98.579,
      5.573,
      -26.037,
      100.106,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10267",
    "polygon": [
      -25.799,
      100.817,
      5.573,
      -25.799,
      100.817,
      8.023,
      -26.037,
      100.106,
      8.023,
      -26.037,
      100.106,
      8.023,
      -26.037,
      100.106,
      5.573,
      -25.799,
      100.817,
      5.573
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10268",
    "polygon": [
      -25.048,
      103.06,
      5.573,
      -25.048,
      103.06,
      8.023,
      -25.559,
      101.534,
      8.023,
      -25.559,
      101.534,
      8.023,
      -25.559,
      101.534,
      5.573,
      -25.048,
      103.06,
      5.573
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10269",
    "polygon": [
      -24.809,
      103.771,
      5.573,
      -24.809,
      103.771,
      8.023,
      -25.048,
      103.06,
      8.023,
      -25.048,
      103.06,
      8.023,
      -25.048,
      103.06,
      5.573,
      -24.809,
      103.771,
      5.573
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10270",
    "polygon": [
      -24.611,
      104.364,
      5.573,
      -24.611,
      104.364,
      8.023,
      -24.809,
      103.771,
      8.023,
      -24.809,
      103.771,
      8.023,
      -24.809,
      103.771,
      5.573,
      -24.611,
      104.364,
      5.573
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10271",
    "polygon": [
      -24.099,
      105.891,
      5.573,
      -24.099,
      105.891,
      8.023,
      -24.611,
      104.364,
      8.023,
      -24.611,
      104.364,
      8.023,
      -24.611,
      104.364,
      5.573,
      -24.099,
      105.891,
      5.573
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_10272",
    "polygon": [
      -23.861,
      106.602,
      5.573,
      -23.861,
      106.602,
      8.023,
      -24.099,
      105.891,
      8.023,
      -24.099,
      105.891,
      8.023,
      -24.099,
      105.891,
      5.573,
      -23.861,
      106.602,
      5.573
    ],
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10273",
    "polygon": [
      -30.88,
      85.669,
      0.523,
      -30.88,
      85.669,
      3.523,
      -31.391,
      84.142,
      3.523,
      -31.391,
      84.142,
      3.523,
      -31.391,
      84.142,
      0.523,
      -30.88,
      85.669,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10274",
    "polygon": [
      -30.642,
      86.38,
      0.523,
      -30.642,
      86.38,
      3.523,
      -30.88,
      85.669,
      3.523,
      -30.88,
      85.669,
      3.523,
      -30.88,
      85.669,
      0.523,
      -30.642,
      86.38,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10275",
    "polygon": [
      -30.443,
      86.973,
      0.523,
      -30.443,
      86.973,
      3.523,
      -30.642,
      86.38,
      3.523,
      -30.642,
      86.38,
      3.523,
      -30.642,
      86.38,
      0.523,
      -30.443,
      86.973,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10276",
    "polygon": [
      -29.932,
      88.499,
      0.523,
      -29.932,
      88.499,
      3.523,
      -30.443,
      86.973,
      3.523,
      -30.443,
      86.973,
      3.523,
      -30.443,
      86.973,
      0.523,
      -29.932,
      88.499,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10277",
    "polygon": [
      -29.693,
      89.211,
      0.523,
      -29.693,
      89.211,
      3.523,
      -29.932,
      88.499,
      3.523,
      -29.932,
      88.499,
      3.523,
      -29.932,
      88.499,
      0.523,
      -29.693,
      89.211,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10278",
    "polygon": [
      -28.936,
      91.473,
      0.523,
      -28.936,
      91.473,
      3.523,
      -29.447,
      89.946,
      3.523,
      -29.447,
      89.946,
      3.523,
      -29.447,
      89.946,
      0.523,
      -28.936,
      91.473,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10279",
    "polygon": [
      -28.697,
      92.184,
      0.523,
      -28.697,
      92.184,
      3.523,
      -28.936,
      91.473,
      3.523,
      -28.936,
      91.473,
      3.523,
      -28.936,
      91.473,
      0.523,
      -28.697,
      92.184,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10280",
    "polygon": [
      -28.499,
      92.777,
      0.523,
      -28.499,
      92.777,
      3.523,
      -28.697,
      92.184,
      3.523,
      -28.697,
      92.184,
      3.523,
      -28.697,
      92.184,
      0.523,
      -28.499,
      92.777,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10281",
    "polygon": [
      -27.987,
      94.303,
      0.523,
      -27.987,
      94.303,
      3.523,
      -28.499,
      92.777,
      3.523,
      -28.499,
      92.777,
      3.523,
      -28.499,
      92.777,
      0.523,
      -27.987,
      94.303,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10282",
    "polygon": [
      -27.749,
      95.015,
      0.523,
      -27.749,
      95.015,
      3.523,
      -27.987,
      94.303,
      3.523,
      -27.987,
      94.303,
      3.523,
      -27.987,
      94.303,
      0.523,
      -27.749,
      95.015,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10283",
    "polygon": [
      -26.991,
      97.277,
      0.523,
      -26.991,
      97.277,
      3.523,
      -27.503,
      95.75,
      3.523,
      -27.503,
      95.75,
      3.523,
      -27.503,
      95.75,
      0.523,
      -26.991,
      97.277,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10284",
    "polygon": [
      -26.753,
      97.988,
      0.523,
      -26.753,
      97.988,
      3.523,
      -26.991,
      97.277,
      3.523,
      -26.991,
      97.277,
      3.523,
      -26.991,
      97.277,
      0.523,
      -26.753,
      97.988,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10285",
    "polygon": [
      -26.555,
      98.581,
      0.523,
      -26.555,
      98.581,
      3.523,
      -26.753,
      97.988,
      3.523,
      -26.753,
      97.988,
      3.523,
      -26.753,
      97.988,
      0.523,
      -26.555,
      98.581,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10286",
    "polygon": [
      -26.043,
      100.107,
      0.523,
      -26.043,
      100.107,
      3.523,
      -26.555,
      98.581,
      3.523,
      -26.555,
      98.581,
      3.523,
      -26.555,
      98.581,
      0.523,
      -26.043,
      100.107,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10287",
    "polygon": [
      -25.805,
      100.819,
      0.523,
      -25.805,
      100.819,
      3.523,
      -26.043,
      100.107,
      3.523,
      -26.043,
      100.107,
      3.523,
      -26.043,
      100.107,
      0.523,
      -25.805,
      100.819,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10288",
    "polygon": [
      -25.053,
      103.062,
      0.523,
      -25.053,
      103.062,
      3.523,
      -25.565,
      101.535,
      3.523,
      -25.565,
      101.535,
      3.523,
      -25.565,
      101.535,
      0.523,
      -25.053,
      103.062,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10289",
    "polygon": [
      -24.815,
      103.773,
      0.523,
      -24.815,
      103.773,
      3.523,
      -25.053,
      103.062,
      3.523,
      -25.053,
      103.062,
      3.523,
      -25.053,
      103.062,
      0.523,
      -24.815,
      103.773,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10290",
    "polygon": [
      -24.617,
      104.366,
      0.523,
      -24.617,
      104.366,
      3.523,
      -24.815,
      103.773,
      3.523,
      -24.815,
      103.773,
      3.523,
      -24.815,
      103.773,
      0.523,
      -24.617,
      104.366,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10291",
    "polygon": [
      -24.105,
      105.892,
      0.523,
      -24.105,
      105.892,
      3.523,
      -24.617,
      104.366,
      3.523,
      -24.617,
      104.366,
      3.523,
      -24.617,
      104.366,
      0.523,
      -24.105,
      105.892,
      0.523
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_10292",
    "polygon": [
      -23.867,
      106.604,
      0.523,
      -23.867,
      106.604,
      3.523,
      -24.105,
      105.892,
      3.523,
      -24.105,
      105.892,
      3.523,
      -24.105,
      105.892,
      0.523,
      -23.867,
      106.604,
      0.523
    ],
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10293",
    "polygon": [
      56.423,
      53.941,
      13.073,
      56.423,
      53.941,
      15.523,
      57.134,
      53.702,
      15.523,
      57.134,
      53.702,
      15.523,
      57.134,
      53.702,
      13.073,
      56.423,
      53.941,
      13.073
    ]
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10294",
    "polygon": [
      48.524,
      56.586,
      13.073,
      48.524,
      56.586,
      15.523,
      50.051,
      56.075,
      15.523,
      50.051,
      56.075,
      15.523,
      50.051,
      56.075,
      13.073,
      48.524,
      56.586,
      13.073
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10295",
    "polygon": [
      47.813,
      56.825,
      13.073,
      47.813,
      56.825,
      15.523,
      48.524,
      56.586,
      15.523,
      48.524,
      56.586,
      15.523,
      48.524,
      56.586,
      13.073,
      47.813,
      56.825,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10296",
    "polygon": [
      47.22,
      57.023,
      13.073,
      47.22,
      57.023,
      15.523,
      47.813,
      56.825,
      15.523,
      47.813,
      56.825,
      15.523,
      47.813,
      56.825,
      13.073,
      47.22,
      57.023,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10297",
    "polygon": [
      45.694,
      57.535,
      13.073,
      45.694,
      57.535,
      15.523,
      47.22,
      57.023,
      15.523,
      47.22,
      57.023,
      15.523,
      47.22,
      57.023,
      13.073,
      45.694,
      57.535,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10298",
    "polygon": [
      44.983,
      57.773,
      13.073,
      44.983,
      57.773,
      15.523,
      45.694,
      57.535,
      15.523,
      45.694,
      57.535,
      15.523,
      45.694,
      57.535,
      13.073,
      44.983,
      57.773,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10299",
    "polygon": [
      42.448,
      58.622,
      13.073,
      42.448,
      58.622,
      15.523,
      43.975,
      58.11,
      15.523,
      43.975,
      58.11,
      15.523,
      43.975,
      58.11,
      13.073,
      42.448,
      58.622,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10300",
    "polygon": [
      41.737,
      58.86,
      13.073,
      41.737,
      58.86,
      15.523,
      42.448,
      58.622,
      15.523,
      42.448,
      58.622,
      15.523,
      42.448,
      58.622,
      13.073,
      41.737,
      58.86,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10301",
    "polygon": [
      41.145,
      59.059,
      13.073,
      41.145,
      59.059,
      15.523,
      41.737,
      58.86,
      15.523,
      41.737,
      58.86,
      15.523,
      41.737,
      58.86,
      13.073,
      41.145,
      59.059,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10302",
    "polygon": [
      39.618,
      59.57,
      13.073,
      39.618,
      59.57,
      15.523,
      41.145,
      59.059,
      15.523,
      41.145,
      59.059,
      15.523,
      41.145,
      59.059,
      13.073,
      39.618,
      59.57,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10303",
    "polygon": [
      38.907,
      59.808,
      13.073,
      38.907,
      59.808,
      15.523,
      39.618,
      59.57,
      15.523,
      39.618,
      59.57,
      15.523,
      39.618,
      59.57,
      13.073,
      38.907,
      59.808,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10304",
    "polygon": [
      57.134,
      53.702,
      9.323,
      57.134,
      53.702,
      11.773,
      58.661,
      53.191,
      11.773,
      58.661,
      53.191,
      11.773,
      58.661,
      53.191,
      9.323,
      57.134,
      53.702,
      9.323
    ]
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10305",
    "polygon": [
      56.423,
      53.941,
      9.323,
      56.423,
      53.941,
      11.773,
      57.134,
      53.702,
      11.773,
      57.134,
      53.702,
      11.773,
      57.134,
      53.702,
      9.323,
      56.423,
      53.941,
      9.323
    ]
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10306",
    "polygon": [
      48.524,
      56.586,
      9.323,
      48.524,
      56.586,
      11.773,
      50.051,
      56.075,
      11.773,
      50.051,
      56.075,
      11.773,
      50.051,
      56.075,
      9.323,
      48.524,
      56.586,
      9.323
    ]
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10307",
    "polygon": [
      47.813,
      56.825,
      9.323,
      47.813,
      56.825,
      11.773,
      48.524,
      56.586,
      11.773,
      48.524,
      56.586,
      11.773,
      48.524,
      56.586,
      9.323,
      47.813,
      56.825,
      9.323
    ],
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10308",
    "polygon": [
      47.22,
      57.023,
      9.323,
      47.22,
      57.023,
      11.773,
      47.813,
      56.825,
      11.773,
      47.813,
      56.825,
      11.773,
      47.813,
      56.825,
      9.323,
      47.22,
      57.023,
      9.323
    ],
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10309",
    "polygon": [
      45.694,
      57.535,
      9.323,
      45.694,
      57.535,
      11.773,
      47.22,
      57.023,
      11.773,
      47.22,
      57.023,
      11.773,
      47.22,
      57.023,
      9.323,
      45.694,
      57.535,
      9.323
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10310",
    "polygon": [
      44.983,
      57.773,
      9.323,
      44.983,
      57.773,
      11.773,
      45.694,
      57.535,
      11.773,
      45.694,
      57.535,
      11.773,
      45.694,
      57.535,
      9.323,
      44.983,
      57.773,
      9.323
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10311",
    "polygon": [
      42.448,
      58.622,
      9.323,
      42.448,
      58.622,
      11.773,
      43.975,
      58.11,
      11.773,
      43.975,
      58.11,
      11.773,
      43.975,
      58.11,
      9.323,
      42.448,
      58.622,
      9.323
    ]
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10312",
    "polygon": [
      41.737,
      58.86,
      9.323,
      41.737,
      58.86,
      11.773,
      42.448,
      58.622,
      11.773,
      42.448,
      58.622,
      11.773,
      42.448,
      58.622,
      9.323,
      41.737,
      58.86,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10313",
    "polygon": [
      41.145,
      59.059,
      9.323,
      41.145,
      59.059,
      11.773,
      41.737,
      58.86,
      11.773,
      41.737,
      58.86,
      11.773,
      41.737,
      58.86,
      9.323,
      41.145,
      59.059,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10314",
    "polygon": [
      39.618,
      59.57,
      9.323,
      39.618,
      59.57,
      11.773,
      41.145,
      59.059,
      11.773,
      41.145,
      59.059,
      11.773,
      41.145,
      59.059,
      9.323,
      39.618,
      59.57,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10315",
    "polygon": [
      38.907,
      59.808,
      9.323,
      38.907,
      59.808,
      11.773,
      39.618,
      59.57,
      11.773,
      39.618,
      59.57,
      11.773,
      39.618,
      59.57,
      9.323,
      38.907,
      59.808,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10316",
    "polygon": [
      57.134,
      53.702,
      5.573,
      57.134,
      53.702,
      8.023,
      58.661,
      53.191,
      8.023,
      58.661,
      53.191,
      8.023,
      58.661,
      53.191,
      5.573,
      57.134,
      53.702,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10317",
    "polygon": [
      56.423,
      53.941,
      5.573,
      56.423,
      53.941,
      8.023,
      57.134,
      53.702,
      8.023,
      57.134,
      53.702,
      8.023,
      57.134,
      53.702,
      5.573,
      56.423,
      53.941,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10318",
    "polygon": [
      48.524,
      56.586,
      5.573,
      48.524,
      56.586,
      8.023,
      50.051,
      56.075,
      8.023,
      50.051,
      56.075,
      8.023,
      50.051,
      56.075,
      5.573,
      48.524,
      56.586,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10319",
    "polygon": [
      47.813,
      56.825,
      5.573,
      47.813,
      56.825,
      8.023,
      48.524,
      56.586,
      8.023,
      48.524,
      56.586,
      8.023,
      48.524,
      56.586,
      5.573,
      47.813,
      56.825,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10320",
    "polygon": [
      47.22,
      57.023,
      5.573,
      47.22,
      57.023,
      8.023,
      47.813,
      56.825,
      8.023,
      47.813,
      56.825,
      8.023,
      47.813,
      56.825,
      5.573,
      47.22,
      57.023,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10321",
    "polygon": [
      45.694,
      57.535,
      5.573,
      45.694,
      57.535,
      8.023,
      47.22,
      57.023,
      8.023,
      47.22,
      57.023,
      8.023,
      47.22,
      57.023,
      5.573,
      45.694,
      57.535,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10322",
    "polygon": [
      44.983,
      57.773,
      5.573,
      44.983,
      57.773,
      8.023,
      45.694,
      57.535,
      8.023,
      45.694,
      57.535,
      8.023,
      45.694,
      57.535,
      5.573,
      44.983,
      57.773,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10323",
    "polygon": [
      42.448,
      58.622,
      5.573,
      42.448,
      58.622,
      8.023,
      43.975,
      58.11,
      8.023,
      43.975,
      58.11,
      8.023,
      43.975,
      58.11,
      5.573,
      42.448,
      58.622,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10324",
    "polygon": [
      41.737,
      58.86,
      5.573,
      41.737,
      58.86,
      8.023,
      42.448,
      58.622,
      8.023,
      42.448,
      58.622,
      8.023,
      42.448,
      58.622,
      5.573,
      41.737,
      58.86,
      5.573
    ],
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10325",
    "polygon": [
      41.145,
      59.059,
      5.573,
      41.145,
      59.059,
      8.023,
      41.737,
      58.86,
      8.023,
      41.737,
      58.86,
      8.023,
      41.737,
      58.86,
      5.573,
      41.145,
      59.059,
      5.573
    ],
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10326",
    "polygon": [
      39.618,
      59.57,
      5.573,
      39.618,
      59.57,
      8.023,
      41.145,
      59.059,
      8.023,
      41.145,
      59.059,
      8.023,
      41.145,
      59.059,
      5.573,
      39.618,
      59.57,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10327",
    "polygon": [
      38.907,
      59.808,
      5.573,
      38.907,
      59.808,
      8.023,
      39.618,
      59.57,
      8.023,
      39.618,
      59.57,
      8.023,
      39.618,
      59.57,
      5.573,
      38.907,
      59.808,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10328",
    "polygon": [
      48.524,
      56.586,
      0.523,
      48.524,
      56.586,
      3.523,
      50.051,
      56.075,
      3.523,
      50.051,
      56.075,
      3.523,
      50.051,
      56.075,
      0.523,
      48.524,
      56.586,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10329",
    "polygon": [
      47.813,
      56.825,
      0.523,
      47.813,
      56.825,
      3.523,
      48.524,
      56.586,
      3.523,
      48.524,
      56.586,
      3.523,
      48.524,
      56.586,
      0.523,
      47.813,
      56.825,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10330",
    "polygon": [
      47.22,
      57.023,
      0.523,
      47.22,
      57.023,
      3.523,
      47.813,
      56.825,
      3.523,
      47.813,
      56.825,
      3.523,
      47.813,
      56.825,
      0.523,
      47.22,
      57.023,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10331",
    "polygon": [
      45.694,
      57.535,
      0.523,
      45.694,
      57.535,
      3.523,
      47.22,
      57.023,
      3.523,
      47.22,
      57.023,
      3.523,
      47.22,
      57.023,
      0.523,
      45.694,
      57.535,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10332",
    "polygon": [
      44.983,
      57.773,
      0.523,
      44.983,
      57.773,
      3.523,
      45.694,
      57.535,
      3.523,
      45.694,
      57.535,
      3.523,
      45.694,
      57.535,
      0.523,
      44.983,
      57.773,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10333",
    "polygon": [
      42.448,
      58.622,
      0.523,
      42.448,
      58.622,
      3.523,
      43.975,
      58.11,
      3.523,
      43.975,
      58.11,
      3.523,
      43.975,
      58.11,
      0.523,
      42.448,
      58.622,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10334",
    "polygon": [
      41.737,
      58.86,
      0.523,
      41.737,
      58.86,
      3.523,
      42.448,
      58.622,
      3.523,
      42.448,
      58.622,
      3.523,
      42.448,
      58.622,
      0.523,
      41.737,
      58.86,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10335",
    "polygon": [
      41.145,
      59.059,
      0.523,
      41.145,
      59.059,
      3.523,
      41.737,
      58.86,
      3.523,
      41.737,
      58.86,
      3.523,
      41.737,
      58.86,
      0.523,
      41.145,
      59.059,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10336",
    "polygon": [
      39.618,
      59.57,
      0.523,
      39.618,
      59.57,
      3.523,
      41.145,
      59.059,
      3.523,
      41.145,
      59.059,
      3.523,
      41.145,
      59.059,
      0.523,
      39.618,
      59.57,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10337",
    "polygon": [
      38.907,
      59.808,
      0.523,
      38.907,
      59.808,
      3.523,
      39.618,
      59.57,
      3.523,
      39.618,
      59.57,
      3.523,
      39.618,
      59.57,
      0.523,
      38.907,
      59.808,
      0.523
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10338",
    "polygon": [
      56.423,
      53.941,
      2.826,
      56.423,
      53.941,
      3.523,
      58.575,
      53.22,
      3.523,
      58.575,
      53.22,
      3.523,
      58.575,
      53.22,
      2.826,
      56.423,
      53.941,
      2.826
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10339",
    "polygon": [
      57.499,
      53.58,
      0.401,
      57.499,
      53.58,
      2.826,
      58.575,
      53.22,
      2.826,
      58.575,
      53.22,
      2.826,
      58.575,
      53.22,
      0.401,
      57.499,
      53.58,
      0.401
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10340",
    "polygon": [
      56.423,
      53.941,
      0.401,
      56.423,
      53.941,
      2.826,
      57.499,
      53.58,
      2.826,
      57.499,
      53.58,
      2.826,
      57.499,
      53.58,
      0.401,
      56.423,
      53.941,
      0.401
    ]
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10341",
    "polygon": [
      40.221,
      65.798,
      13.073,
      40.221,
      65.798,
      15.523,
      39.982,
      65.087,
      15.523,
      39.982,
      65.087,
      15.523,
      39.982,
      65.087,
      13.073,
      40.221,
      65.798,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10342",
    "polygon": [
      39.982,
      65.087,
      13.073,
      39.982,
      65.087,
      15.523,
      39.452,
      63.503,
      15.523,
      39.452,
      63.503,
      15.523,
      39.452,
      63.503,
      13.073,
      39.982,
      65.087,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10343",
    "polygon": [
      41.201,
      68.725,
      13.073,
      41.201,
      68.725,
      15.523,
      40.963,
      68.013,
      15.523,
      40.963,
      68.013,
      15.523,
      40.963,
      68.013,
      13.073,
      41.201,
      68.725,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10344",
    "polygon": [
      41.4,
      69.317,
      13.073,
      41.4,
      69.317,
      15.523,
      41.201,
      68.725,
      15.523,
      41.201,
      68.725,
      15.523,
      41.201,
      68.725,
      13.073,
      41.4,
      69.317,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10345",
    "polygon": [
      40.963,
      68.013,
      13.073,
      40.963,
      68.013,
      15.523,
      40.432,
      66.43,
      15.523,
      40.432,
      66.43,
      15.523,
      40.432,
      66.43,
      13.073,
      40.963,
      68.013,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10346",
    "polygon": [
      42.165,
      71.603,
      13.073,
      42.165,
      71.603,
      15.523,
      41.927,
      70.892,
      15.523,
      41.927,
      70.892,
      15.523,
      41.927,
      70.892,
      13.073,
      42.165,
      71.603,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10347",
    "polygon": [
      41.927,
      70.892,
      13.073,
      41.927,
      70.892,
      15.523,
      41.397,
      69.309,
      15.523,
      41.397,
      69.309,
      15.523,
      41.397,
      69.309,
      13.073,
      41.927,
      70.892,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10348",
    "polygon": [
      43.179,
      74.628,
      13.073,
      43.179,
      74.628,
      15.523,
      42.941,
      73.917,
      15.523,
      42.941,
      73.917,
      15.523,
      42.941,
      73.917,
      13.073,
      43.179,
      74.628,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10349",
    "polygon": [
      43.377,
      75.221,
      13.073,
      43.377,
      75.221,
      15.523,
      43.179,
      74.628,
      15.523,
      43.179,
      74.628,
      15.523,
      43.179,
      74.628,
      13.073,
      43.377,
      75.221,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10350",
    "polygon": [
      42.941,
      73.917,
      13.073,
      42.941,
      73.917,
      15.523,
      42.41,
      72.334,
      15.523,
      42.41,
      72.334,
      15.523,
      42.41,
      72.334,
      13.073,
      42.941,
      73.917,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10351",
    "polygon": [
      44.143,
      77.507,
      13.073,
      44.143,
      77.507,
      15.523,
      43.905,
      76.796,
      15.523,
      43.905,
      76.796,
      15.523,
      43.905,
      76.796,
      13.073,
      44.143,
      77.507,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10352",
    "polygon": [
      43.905,
      76.796,
      13.073,
      43.905,
      76.796,
      15.523,
      43.374,
      75.212,
      15.523,
      43.374,
      75.212,
      15.523,
      43.374,
      75.212,
      13.073,
      43.905,
      76.796,
      13.073
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10353",
    "polygon": [
      45.107,
      80.384,
      13.073,
      45.107,
      80.384,
      15.523,
      44.869,
      79.673,
      15.523,
      44.869,
      79.673,
      15.523,
      44.869,
      79.673,
      13.073,
      45.107,
      80.384,
      13.073
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10354",
    "polygon": [
      45.306,
      80.977,
      13.073,
      45.306,
      80.977,
      15.523,
      45.107,
      80.384,
      15.523,
      45.107,
      80.384,
      15.523,
      45.107,
      80.384,
      13.073,
      45.306,
      80.977,
      13.073
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10355",
    "polygon": [
      44.869,
      79.673,
      13.073,
      44.869,
      79.673,
      15.523,
      44.338,
      78.09,
      15.523,
      44.338,
      78.09,
      15.523,
      44.338,
      78.09,
      13.073,
      44.869,
      79.673,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10357",
    "polygon": [
      45.833,
      82.552,
      13.073,
      45.833,
      82.552,
      15.523,
      45.303,
      80.968,
      15.523,
      45.303,
      80.968,
      15.523,
      45.303,
      80.968,
      13.073,
      45.833,
      82.552,
      13.073
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_10358",
    "polygon": [
      46.032,
      83.144,
      13.073,
      46.032,
      83.144,
      15.523,
      45.833,
      82.552,
      15.523,
      45.833,
      82.552,
      15.523,
      45.833,
      82.552,
      13.073,
      46.032,
      83.144,
      13.073
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10359",
    "polygon": [
      39.256,
      62.919,
      9.323,
      39.256,
      62.919,
      11.773,
      39.018,
      62.208,
      11.773,
      39.018,
      62.208,
      11.773,
      39.018,
      62.208,
      9.323,
      39.256,
      62.919,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10360",
    "polygon": [
      39.455,
      63.512,
      9.323,
      39.455,
      63.512,
      11.773,
      39.256,
      62.919,
      11.773,
      39.256,
      62.919,
      11.773,
      39.256,
      62.919,
      9.323,
      39.455,
      63.512,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10361",
    "polygon": [
      39.018,
      62.208,
      9.323,
      39.018,
      62.208,
      11.773,
      38.488,
      60.624,
      11.773,
      38.488,
      60.624,
      11.773,
      38.488,
      60.624,
      9.323,
      39.018,
      62.208,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10362",
    "polygon": [
      40.221,
      65.798,
      9.323,
      40.221,
      65.798,
      11.773,
      39.982,
      65.087,
      11.773,
      39.982,
      65.087,
      11.773,
      39.982,
      65.087,
      9.323,
      40.221,
      65.798,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10363",
    "polygon": [
      39.982,
      65.087,
      9.323,
      39.982,
      65.087,
      11.773,
      39.452,
      63.503,
      11.773,
      39.452,
      63.503,
      11.773,
      39.452,
      63.503,
      9.323,
      39.982,
      65.087,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10364",
    "polygon": [
      41.201,
      68.725,
      9.323,
      41.201,
      68.725,
      11.773,
      40.963,
      68.013,
      11.773,
      40.963,
      68.013,
      11.773,
      40.963,
      68.013,
      9.323,
      41.201,
      68.725,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10365",
    "polygon": [
      41.4,
      69.317,
      9.323,
      41.4,
      69.317,
      11.773,
      41.201,
      68.725,
      11.773,
      41.201,
      68.725,
      11.773,
      41.201,
      68.725,
      9.323,
      41.4,
      69.317,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10366",
    "polygon": [
      40.963,
      68.013,
      9.323,
      40.963,
      68.013,
      11.773,
      40.432,
      66.43,
      11.773,
      40.432,
      66.43,
      11.773,
      40.432,
      66.43,
      9.323,
      40.963,
      68.013,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10367",
    "polygon": [
      42.165,
      71.603,
      9.323,
      42.165,
      71.603,
      11.773,
      41.927,
      70.892,
      11.773,
      41.927,
      70.892,
      11.773,
      41.927,
      70.892,
      9.323,
      42.165,
      71.603,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10368",
    "polygon": [
      41.927,
      70.892,
      9.323,
      41.927,
      70.892,
      11.773,
      41.397,
      69.309,
      11.773,
      41.397,
      69.309,
      11.773,
      41.397,
      69.309,
      9.323,
      41.927,
      70.892,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10369",
    "polygon": [
      43.179,
      74.628,
      9.323,
      43.179,
      74.628,
      11.773,
      42.941,
      73.917,
      11.773,
      42.941,
      73.917,
      11.773,
      42.941,
      73.917,
      9.323,
      43.179,
      74.628,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10370",
    "polygon": [
      43.377,
      75.221,
      9.323,
      43.377,
      75.221,
      11.773,
      43.179,
      74.628,
      11.773,
      43.179,
      74.628,
      11.773,
      43.179,
      74.628,
      9.323,
      43.377,
      75.221,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10371",
    "polygon": [
      42.941,
      73.917,
      9.323,
      42.941,
      73.917,
      11.773,
      42.41,
      72.334,
      11.773,
      42.41,
      72.334,
      11.773,
      42.41,
      72.334,
      9.323,
      42.941,
      73.917,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10372",
    "polygon": [
      44.143,
      77.507,
      9.323,
      44.143,
      77.507,
      11.773,
      43.905,
      76.796,
      11.773,
      43.905,
      76.796,
      11.773,
      43.905,
      76.796,
      9.323,
      44.143,
      77.507,
      9.323
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10373",
    "polygon": [
      43.905,
      76.796,
      9.323,
      43.905,
      76.796,
      11.773,
      43.374,
      75.212,
      11.773,
      43.374,
      75.212,
      11.773,
      43.374,
      75.212,
      9.323,
      43.905,
      76.796,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10374",
    "polygon": [
      45.107,
      80.384,
      9.323,
      45.107,
      80.384,
      11.773,
      44.869,
      79.673,
      11.773,
      44.869,
      79.673,
      11.773,
      44.869,
      79.673,
      9.323,
      45.107,
      80.384,
      9.323
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10375",
    "polygon": [
      45.306,
      80.977,
      9.323,
      45.306,
      80.977,
      11.773,
      45.107,
      80.384,
      11.773,
      45.107,
      80.384,
      11.773,
      45.107,
      80.384,
      9.323,
      45.306,
      80.977,
      9.323
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10376",
    "polygon": [
      44.869,
      79.673,
      9.323,
      44.869,
      79.673,
      11.773,
      44.338,
      78.09,
      11.773,
      44.338,
      78.09,
      11.773,
      44.338,
      78.09,
      9.323,
      44.869,
      79.673,
      9.323
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10377",
    "polygon": [
      45.833,
      82.552,
      9.323,
      45.833,
      82.552,
      11.773,
      45.303,
      80.968,
      11.773,
      45.303,
      80.968,
      11.773,
      45.303,
      80.968,
      9.323,
      45.833,
      82.552,
      9.323
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10378",
    "polygon": [
      46.838,
      83.543,
      9.323,
      46.838,
      83.543,
      11.773,
      46.245,
      83.742,
      11.773,
      46.245,
      83.742,
      11.773,
      46.245,
      83.742,
      9.323,
      46.838,
      83.543,
      9.323
    ]
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_10379",
    "polygon": [
      46.032,
      83.144,
      9.323,
      46.032,
      83.144,
      11.773,
      45.833,
      82.552,
      11.773,
      45.833,
      82.552,
      11.773,
      45.833,
      82.552,
      9.323,
      46.032,
      83.144,
      9.323
    ],
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10380",
    "polygon": [
      39.256,
      62.919,
      5.573,
      39.256,
      62.919,
      8.023,
      39.018,
      62.208,
      8.023,
      39.018,
      62.208,
      8.023,
      39.018,
      62.208,
      5.573,
      39.256,
      62.919,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10381",
    "polygon": [
      39.455,
      63.512,
      5.573,
      39.455,
      63.512,
      8.023,
      39.256,
      62.919,
      8.023,
      39.256,
      62.919,
      8.023,
      39.256,
      62.919,
      5.573,
      39.455,
      63.512,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10382",
    "polygon": [
      39.018,
      62.208,
      5.573,
      39.018,
      62.208,
      8.023,
      38.488,
      60.624,
      8.023,
      38.488,
      60.624,
      8.023,
      38.488,
      60.624,
      5.573,
      39.018,
      62.208,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10383",
    "polygon": [
      40.221,
      65.798,
      5.573,
      40.221,
      65.798,
      8.023,
      39.982,
      65.087,
      8.023,
      39.982,
      65.087,
      8.023,
      39.982,
      65.087,
      5.573,
      40.221,
      65.798,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10384",
    "polygon": [
      39.982,
      65.087,
      5.573,
      39.982,
      65.087,
      8.023,
      39.452,
      63.503,
      8.023,
      39.452,
      63.503,
      8.023,
      39.452,
      63.503,
      5.573,
      39.982,
      65.087,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10385",
    "polygon": [
      41.201,
      68.725,
      5.573,
      41.201,
      68.725,
      8.023,
      40.963,
      68.013,
      8.023,
      40.963,
      68.013,
      8.023,
      40.963,
      68.013,
      5.573,
      41.201,
      68.725,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10386",
    "polygon": [
      41.4,
      69.317,
      5.573,
      41.4,
      69.317,
      8.023,
      41.201,
      68.725,
      8.023,
      41.201,
      68.725,
      8.023,
      41.201,
      68.725,
      5.573,
      41.4,
      69.317,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10387",
    "polygon": [
      40.963,
      68.013,
      5.573,
      40.963,
      68.013,
      8.023,
      40.432,
      66.43,
      8.023,
      40.432,
      66.43,
      8.023,
      40.432,
      66.43,
      5.573,
      40.963,
      68.013,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10388",
    "polygon": [
      42.165,
      71.603,
      5.573,
      42.165,
      71.603,
      8.023,
      41.927,
      70.892,
      8.023,
      41.927,
      70.892,
      8.023,
      41.927,
      70.892,
      5.573,
      42.165,
      71.603,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10389",
    "polygon": [
      41.927,
      70.892,
      5.573,
      41.927,
      70.892,
      8.023,
      41.397,
      69.309,
      8.023,
      41.397,
      69.309,
      8.023,
      41.397,
      69.309,
      5.573,
      41.927,
      70.892,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10390",
    "polygon": [
      43.179,
      74.628,
      5.573,
      43.179,
      74.628,
      8.023,
      42.941,
      73.917,
      8.023,
      42.941,
      73.917,
      8.023,
      42.941,
      73.917,
      5.573,
      43.179,
      74.628,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10391",
    "polygon": [
      43.377,
      75.221,
      5.573,
      43.377,
      75.221,
      8.023,
      43.179,
      74.628,
      8.023,
      43.179,
      74.628,
      8.023,
      43.179,
      74.628,
      5.573,
      43.377,
      75.221,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10392",
    "polygon": [
      42.941,
      73.917,
      5.573,
      42.941,
      73.917,
      8.023,
      42.41,
      72.334,
      8.023,
      42.41,
      72.334,
      8.023,
      42.41,
      72.334,
      5.573,
      42.941,
      73.917,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10393",
    "polygon": [
      44.143,
      77.507,
      5.573,
      44.143,
      77.507,
      8.023,
      43.905,
      76.796,
      8.023,
      43.905,
      76.796,
      8.023,
      43.905,
      76.796,
      5.573,
      44.143,
      77.507,
      5.573
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10394",
    "polygon": [
      43.905,
      76.796,
      5.573,
      43.905,
      76.796,
      8.023,
      43.374,
      75.212,
      8.023,
      43.374,
      75.212,
      8.023,
      43.374,
      75.212,
      5.573,
      43.905,
      76.796,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10395",
    "polygon": [
      45.107,
      80.384,
      5.573,
      45.107,
      80.384,
      8.023,
      44.869,
      79.673,
      8.023,
      44.869,
      79.673,
      8.023,
      44.869,
      79.673,
      5.573,
      45.107,
      80.384,
      5.573
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10396",
    "polygon": [
      45.306,
      80.977,
      5.573,
      45.306,
      80.977,
      8.023,
      45.107,
      80.384,
      8.023,
      45.107,
      80.384,
      8.023,
      45.107,
      80.384,
      5.573,
      45.306,
      80.977,
      5.573
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10397",
    "polygon": [
      44.869,
      79.673,
      5.573,
      44.869,
      79.673,
      8.023,
      44.338,
      78.09,
      8.023,
      44.338,
      78.09,
      8.023,
      44.338,
      78.09,
      5.573,
      44.869,
      79.673,
      5.573
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10398",
    "polygon": [
      45.833,
      82.552,
      5.573,
      45.833,
      82.552,
      8.023,
      45.303,
      80.968,
      8.023,
      45.303,
      80.968,
      8.023,
      45.303,
      80.968,
      5.573,
      45.833,
      82.552,
      5.573
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10399",
    "polygon": [
      46.838,
      83.543,
      5.573,
      46.838,
      83.543,
      8.023,
      46.245,
      83.742,
      8.023,
      46.245,
      83.742,
      8.023,
      46.245,
      83.742,
      5.573,
      46.838,
      83.543,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_10400",
    "polygon": [
      46.032,
      83.144,
      5.573,
      46.032,
      83.144,
      8.023,
      45.833,
      82.552,
      8.023,
      45.833,
      82.552,
      8.023,
      45.833,
      82.552,
      5.573,
      46.032,
      83.144,
      5.573
    ],
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10401",
    "polygon": [
      39.275,
      62.913,
      0.523,
      39.275,
      62.913,
      3.523,
      39.037,
      62.202,
      3.523,
      39.037,
      62.202,
      3.523,
      39.037,
      62.202,
      0.523,
      39.275,
      62.913,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10402",
    "polygon": [
      39.474,
      63.505,
      0.523,
      39.474,
      63.505,
      3.523,
      39.275,
      62.913,
      3.523,
      39.275,
      62.913,
      3.523,
      39.275,
      62.913,
      0.523,
      39.474,
      63.505,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10403",
    "polygon": [
      39.027,
      62.173,
      0.523,
      39.027,
      62.173,
      3.523,
      38.516,
      60.647,
      3.523,
      38.516,
      60.647,
      3.523,
      38.516,
      60.647,
      0.523,
      39.027,
      62.173,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10404",
    "polygon": [
      40.239,
      65.791,
      0.523,
      40.239,
      65.791,
      3.523,
      40.001,
      65.08,
      3.523,
      40.001,
      65.08,
      3.523,
      40.001,
      65.08,
      0.523,
      40.239,
      65.791,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10405",
    "polygon": [
      39.992,
      65.052,
      0.523,
      39.992,
      65.052,
      3.523,
      39.48,
      63.525,
      3.523,
      39.48,
      63.525,
      3.523,
      39.48,
      63.525,
      0.523,
      39.992,
      65.052,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10406",
    "polygon": [
      41.22,
      68.718,
      0.523,
      41.22,
      68.718,
      3.523,
      40.982,
      68.007,
      3.523,
      40.982,
      68.007,
      3.523,
      40.982,
      68.007,
      0.523,
      41.22,
      68.718,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10407",
    "polygon": [
      41.418,
      69.311,
      0.523,
      41.418,
      69.311,
      3.523,
      41.22,
      68.718,
      3.523,
      41.22,
      68.718,
      3.523,
      41.22,
      68.718,
      0.523,
      41.418,
      69.311,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10408",
    "polygon": [
      40.972,
      67.979,
      0.523,
      40.972,
      67.979,
      3.523,
      40.461,
      66.452,
      3.523,
      40.461,
      66.452,
      3.523,
      40.461,
      66.452,
      0.523,
      40.972,
      67.979,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10409",
    "polygon": [
      42.184,
      71.597,
      0.523,
      42.184,
      71.597,
      3.523,
      41.946,
      70.886,
      3.523,
      41.946,
      70.886,
      3.523,
      41.946,
      70.886,
      0.523,
      42.184,
      71.597,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10410",
    "polygon": [
      41.936,
      70.857,
      0.523,
      41.936,
      70.857,
      3.523,
      41.425,
      69.331,
      3.523,
      41.425,
      69.331,
      3.523,
      41.425,
      69.331,
      0.523,
      41.936,
      70.857,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10411",
    "polygon": [
      43.198,
      74.622,
      0.523,
      43.198,
      74.622,
      3.523,
      42.959,
      73.911,
      3.523,
      42.959,
      73.911,
      3.523,
      42.959,
      73.911,
      0.523,
      43.198,
      74.622,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10412",
    "polygon": [
      43.396,
      75.215,
      0.523,
      43.396,
      75.215,
      3.523,
      43.198,
      74.622,
      3.523,
      43.198,
      74.622,
      3.523,
      43.198,
      74.622,
      0.523,
      43.396,
      75.215,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10413",
    "polygon": [
      42.95,
      73.882,
      0.523,
      42.95,
      73.882,
      3.523,
      42.438,
      72.356,
      3.523,
      42.438,
      72.356,
      3.523,
      42.438,
      72.356,
      0.523,
      42.95,
      73.882,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10414",
    "polygon": [
      44.162,
      77.501,
      0.523,
      44.162,
      77.501,
      3.523,
      43.924,
      76.789,
      3.523,
      43.924,
      76.789,
      3.523,
      43.924,
      76.789,
      0.523,
      44.162,
      77.501,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10415",
    "polygon": [
      43.914,
      76.761,
      0.523,
      43.914,
      76.761,
      3.523,
      43.403,
      75.234,
      3.523,
      43.403,
      75.234,
      3.523,
      43.403,
      75.234,
      0.523,
      43.914,
      76.761,
      0.523
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10416",
    "polygon": [
      45.126,
      80.378,
      0.523,
      45.126,
      80.378,
      3.523,
      44.888,
      79.667,
      3.523,
      44.888,
      79.667,
      3.523,
      44.888,
      79.667,
      0.523,
      45.126,
      80.378,
      0.523
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10417",
    "polygon": [
      45.324,
      80.971,
      0.523,
      45.324,
      80.971,
      3.523,
      45.126,
      80.378,
      3.523,
      45.126,
      80.378,
      3.523,
      45.126,
      80.378,
      0.523,
      45.324,
      80.971,
      0.523
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10418",
    "polygon": [
      44.878,
      79.638,
      0.523,
      44.878,
      79.638,
      3.523,
      44.367,
      78.112,
      3.523,
      44.367,
      78.112,
      3.523,
      44.367,
      78.112,
      0.523,
      44.878,
      79.638,
      0.523
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10419",
    "polygon": [
      45.842,
      82.517,
      0.523,
      45.842,
      82.517,
      3.523,
      45.331,
      80.99,
      3.523,
      45.331,
      80.99,
      3.523,
      45.331,
      80.99,
      0.523,
      45.842,
      82.517,
      0.523
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_10420",
    "polygon": [
      46.838,
      83.543,
      1.573,
      46.838,
      83.543,
      4.023,
      46.245,
      83.742,
      4.023,
      46.245,
      83.742,
      4.023,
      46.245,
      83.742,
      1.573,
      46.838,
      83.543,
      1.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_10421",
    "polygon": [
      46.05,
      83.138,
      0.523,
      46.05,
      83.138,
      3.523,
      45.852,
      82.546,
      3.523,
      45.852,
      82.546,
      3.523,
      45.852,
      82.546,
      0.523,
      46.05,
      83.138,
      0.523
    ],
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10422",
    "polygon": [
      -74.24,
      29.211,
      13.063,
      -74.24,
      29.211,
      15.513,
      -72.714,
      28.699,
      15.513,
      -72.714,
      28.699,
      15.513,
      -72.714,
      28.699,
      13.063,
      -74.24,
      29.211,
      13.063
    ]
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10423",
    "polygon": [
      -74.952,
      29.449,
      13.063,
      -74.952,
      29.449,
      15.513,
      -74.24,
      29.211,
      15.513,
      -74.24,
      29.211,
      15.513,
      -74.24,
      29.211,
      13.063,
      -74.952,
      29.449,
      13.063
    ]
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10424",
    "polygon": [
      -77.214,
      30.207,
      13.063,
      -77.214,
      30.207,
      15.513,
      -75.687,
      29.695,
      15.513,
      -75.687,
      29.695,
      15.513,
      -75.687,
      29.695,
      13.063,
      -77.214,
      30.207,
      13.063
    ]
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10425",
    "polygon": [
      -78.542,
      30.652,
      13.063,
      -78.542,
      30.652,
      15.513,
      -77.95,
      30.453,
      15.513,
      -77.95,
      30.453,
      15.513,
      -77.95,
      30.453,
      13.063,
      -78.542,
      30.652,
      13.063
    ]
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10426",
    "polygon": [
      -80.069,
      31.163,
      13.063,
      -80.069,
      31.163,
      15.513,
      -78.542,
      30.652,
      15.513,
      -78.542,
      30.652,
      15.513,
      -78.542,
      30.652,
      13.063,
      -80.069,
      31.163,
      13.063
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10427",
    "polygon": [
      -80.78,
      31.401,
      13.063,
      -80.78,
      31.401,
      15.513,
      -80.069,
      31.163,
      15.513,
      -80.069,
      31.163,
      15.513,
      -80.069,
      31.163,
      13.063,
      -80.78,
      31.401,
      13.063
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10428",
    "polygon": [
      -83.043,
      32.159,
      13.063,
      -83.043,
      32.159,
      15.513,
      -81.516,
      31.648,
      15.513,
      -81.516,
      31.648,
      15.513,
      -81.516,
      31.648,
      13.063,
      -83.043,
      32.159,
      13.063
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10429",
    "polygon": [
      -83.754,
      32.397,
      13.063,
      -83.754,
      32.397,
      15.513,
      -83.043,
      32.159,
      15.513,
      -83.043,
      32.159,
      15.513,
      -83.043,
      32.159,
      13.063,
      -83.754,
      32.397,
      13.063
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10430",
    "polygon": [
      -86.016,
      33.155,
      13.063,
      -86.016,
      33.155,
      15.513,
      -84.49,
      32.644,
      15.513,
      -84.49,
      32.644,
      15.513,
      -84.49,
      32.644,
      13.063,
      -86.016,
      33.155,
      13.063
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10431",
    "polygon": [
      -71.41,
      28.262,
      9.313,
      -71.41,
      28.262,
      11.763,
      -69.883,
      27.751,
      11.763,
      -69.883,
      27.751,
      11.763,
      -69.883,
      27.751,
      9.313,
      -71.41,
      28.262,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10432",
    "polygon": [
      -72.121,
      28.501,
      9.313,
      -72.121,
      28.501,
      11.763,
      -71.41,
      28.262,
      11.763,
      -71.41,
      28.262,
      11.763,
      -71.41,
      28.262,
      9.313,
      -72.121,
      28.501,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10433",
    "polygon": [
      -72.714,
      28.699,
      9.313,
      -72.714,
      28.699,
      11.763,
      -72.121,
      28.501,
      11.763,
      -72.121,
      28.501,
      11.763,
      -72.121,
      28.501,
      9.313,
      -72.714,
      28.699,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10434",
    "polygon": [
      -74.24,
      29.211,
      9.313,
      -74.24,
      29.211,
      11.763,
      -72.714,
      28.699,
      11.763,
      -72.714,
      28.699,
      11.763,
      -72.714,
      28.699,
      9.313,
      -74.24,
      29.211,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10435",
    "polygon": [
      -74.952,
      29.449,
      9.313,
      -74.952,
      29.449,
      11.763,
      -74.24,
      29.211,
      11.763,
      -74.24,
      29.211,
      11.763,
      -74.24,
      29.211,
      9.313,
      -74.952,
      29.449,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10436",
    "polygon": [
      -77.214,
      30.207,
      9.313,
      -77.214,
      30.207,
      11.763,
      -75.687,
      29.695,
      11.763,
      -75.687,
      29.695,
      11.763,
      -75.687,
      29.695,
      9.313,
      -77.214,
      30.207,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10437",
    "polygon": [
      -78.542,
      30.652,
      9.313,
      -78.542,
      30.652,
      11.763,
      -77.95,
      30.453,
      11.763,
      -77.95,
      30.453,
      11.763,
      -77.95,
      30.453,
      9.313,
      -78.542,
      30.652,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10438",
    "polygon": [
      -80.069,
      31.163,
      9.313,
      -80.069,
      31.163,
      11.763,
      -78.542,
      30.652,
      11.763,
      -78.542,
      30.652,
      11.763,
      -78.542,
      30.652,
      9.313,
      -80.069,
      31.163,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10439",
    "polygon": [
      -80.78,
      31.401,
      9.313,
      -80.78,
      31.401,
      11.763,
      -80.069,
      31.163,
      11.763,
      -80.069,
      31.163,
      11.763,
      -80.069,
      31.163,
      9.313,
      -80.78,
      31.401,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10440",
    "polygon": [
      -83.043,
      32.159,
      9.313,
      -83.043,
      32.159,
      11.763,
      -81.516,
      31.648,
      11.763,
      -81.516,
      31.648,
      11.763,
      -81.516,
      31.648,
      9.313,
      -83.043,
      32.159,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10441",
    "polygon": [
      -83.754,
      32.397,
      9.313,
      -83.754,
      32.397,
      11.763,
      -83.043,
      32.159,
      11.763,
      -83.043,
      32.159,
      11.763,
      -83.043,
      32.159,
      9.313,
      -83.754,
      32.397,
      9.313
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10442",
    "polygon": [
      -86.016,
      33.155,
      9.313,
      -86.016,
      33.155,
      11.763,
      -84.49,
      32.644,
      11.763,
      -84.49,
      32.644,
      11.763,
      -84.49,
      32.644,
      9.313,
      -86.016,
      33.155,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10443",
    "polygon": [
      -71.41,
      28.262,
      5.563,
      -71.41,
      28.262,
      8.013,
      -69.883,
      27.751,
      8.013,
      -69.883,
      27.751,
      8.013,
      -69.883,
      27.751,
      5.563,
      -71.41,
      28.262,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10444",
    "polygon": [
      -72.121,
      28.501,
      5.563,
      -72.121,
      28.501,
      8.013,
      -71.41,
      28.262,
      8.013,
      -71.41,
      28.262,
      8.013,
      -71.41,
      28.262,
      5.563,
      -72.121,
      28.501,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10445",
    "polygon": [
      -72.714,
      28.699,
      5.563,
      -72.714,
      28.699,
      8.013,
      -72.121,
      28.501,
      8.013,
      -72.121,
      28.501,
      8.013,
      -72.121,
      28.501,
      5.563,
      -72.714,
      28.699,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10446",
    "polygon": [
      -74.24,
      29.211,
      5.563,
      -74.24,
      29.211,
      8.013,
      -72.714,
      28.699,
      8.013,
      -72.714,
      28.699,
      8.013,
      -72.714,
      28.699,
      5.563,
      -74.24,
      29.211,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10447",
    "polygon": [
      -74.952,
      29.449,
      5.563,
      -74.952,
      29.449,
      8.013,
      -74.24,
      29.211,
      8.013,
      -74.24,
      29.211,
      8.013,
      -74.24,
      29.211,
      5.563,
      -74.952,
      29.449,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10448",
    "polygon": [
      -77.214,
      30.207,
      5.563,
      -77.214,
      30.207,
      8.013,
      -75.687,
      29.695,
      8.013,
      -75.687,
      29.695,
      8.013,
      -75.687,
      29.695,
      5.563,
      -77.214,
      30.207,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10449",
    "polygon": [
      -78.542,
      30.652,
      5.563,
      -78.542,
      30.652,
      8.013,
      -77.95,
      30.453,
      8.013,
      -77.95,
      30.453,
      8.013,
      -77.95,
      30.453,
      5.563,
      -78.542,
      30.652,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10450",
    "polygon": [
      -80.069,
      31.163,
      5.563,
      -80.069,
      31.163,
      8.013,
      -78.542,
      30.652,
      8.013,
      -78.542,
      30.652,
      8.013,
      -78.542,
      30.652,
      5.563,
      -80.069,
      31.163,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10451",
    "polygon": [
      -80.78,
      31.401,
      5.563,
      -80.78,
      31.401,
      8.013,
      -80.069,
      31.163,
      8.013,
      -80.069,
      31.163,
      8.013,
      -80.069,
      31.163,
      5.563,
      -80.78,
      31.401,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10454",
    "polygon": [
      -86.016,
      33.155,
      5.563,
      -86.016,
      33.155,
      8.013,
      -84.49,
      32.644,
      8.013,
      -84.49,
      32.644,
      8.013,
      -84.49,
      32.644,
      5.563,
      -86.016,
      33.155,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10455",
    "polygon": [
      -83.669,
      32.369,
      7.316,
      -83.669,
      32.369,
      8.013,
      -81.516,
      31.648,
      8.013,
      -81.516,
      31.648,
      8.013,
      -81.516,
      31.648,
      7.316,
      -83.669,
      32.369,
      7.316
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10457",
    "polygon": [
      -83.669,
      32.369,
      5.0,
      -83.669,
      32.369,
      7.316,
      -82.592,
      32.008,
      7.316,
      -82.592,
      32.008,
      7.316,
      -82.592,
      32.008,
      5.0,
      -83.669,
      32.369,
      5.0
    ]
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10458",
    "polygon": [
      -87.72,
      39.309,
      13.063,
      -87.72,
      39.309,
      15.513,
      -88.231,
      37.782,
      15.513,
      -88.231,
      37.782,
      15.513,
      -88.231,
      37.782,
      13.063,
      -87.72,
      39.309,
      13.063
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10459",
    "polygon": [
      -87.482,
      40.02,
      13.063,
      -87.482,
      40.02,
      15.513,
      -87.72,
      39.309,
      15.513,
      -87.72,
      39.309,
      15.513,
      -87.72,
      39.309,
      13.063,
      -87.482,
      40.02,
      13.063
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10460",
    "polygon": [
      -86.733,
      42.258,
      13.063,
      -86.733,
      42.258,
      15.513,
      -87.244,
      40.731,
      15.513,
      -87.244,
      40.731,
      15.513,
      -87.244,
      40.731,
      13.063,
      -86.733,
      42.258,
      13.063
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10461",
    "polygon": [
      -86.495,
      42.969,
      13.063,
      -86.495,
      42.969,
      15.513,
      -86.733,
      42.258,
      15.513,
      -86.733,
      42.258,
      15.513,
      -86.733,
      42.258,
      13.063,
      -86.495,
      42.969,
      13.063
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10462",
    "polygon": [
      -86.296,
      43.562,
      13.063,
      -86.296,
      43.562,
      15.513,
      -86.495,
      42.969,
      15.513,
      -86.495,
      42.969,
      15.513,
      -86.495,
      42.969,
      13.063,
      -86.296,
      43.562,
      13.063
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10463",
    "polygon": [
      -85.785,
      45.089,
      13.063,
      -85.785,
      45.089,
      15.513,
      -86.296,
      43.562,
      15.513,
      -86.296,
      43.562,
      15.513,
      -86.296,
      43.562,
      13.063,
      -85.785,
      45.089,
      13.063
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10464",
    "polygon": [
      -85.547,
      45.8,
      13.063,
      -85.547,
      45.8,
      15.513,
      -85.785,
      45.089,
      15.513,
      -85.785,
      45.089,
      15.513,
      -85.785,
      45.089,
      13.063,
      -85.547,
      45.8,
      13.063
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10465",
    "polygon": [
      -84.798,
      48.038,
      13.063,
      -84.798,
      48.038,
      15.513,
      -85.309,
      46.511,
      15.513,
      -85.309,
      46.511,
      15.513,
      -85.309,
      46.511,
      13.063,
      -84.798,
      48.038,
      13.063
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10466",
    "polygon": [
      -84.56,
      48.749,
      13.063,
      -84.56,
      48.749,
      15.513,
      -84.798,
      48.038,
      15.513,
      -84.798,
      48.038,
      15.513,
      -84.798,
      48.038,
      13.063,
      -84.56,
      48.749,
      13.063
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10467",
    "polygon": [
      -84.361,
      49.342,
      13.063,
      -84.361,
      49.342,
      15.513,
      -84.56,
      48.749,
      15.513,
      -84.56,
      48.749,
      15.513,
      -84.56,
      48.749,
      13.063,
      -84.361,
      49.342,
      13.063
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10468",
    "polygon": [
      -83.85,
      50.868,
      13.063,
      -83.85,
      50.868,
      15.513,
      -84.361,
      49.342,
      15.513,
      -84.361,
      49.342,
      15.513,
      -84.361,
      49.342,
      13.063,
      -83.85,
      50.868,
      13.063
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10469",
    "polygon": [
      -83.612,
      51.579,
      13.063,
      -83.612,
      51.579,
      15.513,
      -83.85,
      50.868,
      15.513,
      -83.85,
      50.868,
      15.513,
      -83.85,
      50.868,
      13.063,
      -83.612,
      51.579,
      13.063
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10470",
    "polygon": [
      -82.863,
      53.817,
      13.063,
      -82.863,
      53.817,
      15.513,
      -83.374,
      52.291,
      15.513,
      -83.374,
      52.291,
      15.513,
      -83.374,
      52.291,
      13.063,
      -82.863,
      53.817,
      13.063
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10471",
    "polygon": [
      -82.625,
      54.529,
      13.063,
      -82.625,
      54.529,
      15.513,
      -82.863,
      53.817,
      15.513,
      -82.863,
      53.817,
      15.513,
      -82.863,
      53.817,
      13.063,
      -82.625,
      54.529,
      13.063
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10472",
    "polygon": [
      -82.426,
      55.121,
      13.063,
      -82.426,
      55.121,
      15.513,
      -82.625,
      54.529,
      15.513,
      -82.625,
      54.529,
      15.513,
      -82.625,
      54.529,
      13.063,
      -82.426,
      55.121,
      13.063
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10473",
    "polygon": [
      -81.915,
      56.648,
      13.063,
      -81.915,
      56.648,
      15.513,
      -82.426,
      55.121,
      15.513,
      -82.426,
      55.121,
      15.513,
      -82.426,
      55.121,
      13.063,
      -81.915,
      56.648,
      13.063
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_10474",
    "polygon": [
      -81.677,
      57.359,
      13.063,
      -81.677,
      57.359,
      15.513,
      -81.915,
      56.648,
      15.513,
      -81.915,
      56.648,
      15.513,
      -81.915,
      56.648,
      13.063,
      -81.677,
      57.359,
      13.063
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10475",
    "polygon": [
      -88.668,
      36.478,
      9.313,
      -88.668,
      36.478,
      11.763,
      -89.179,
      34.952,
      11.763,
      -89.179,
      34.952,
      11.763,
      -89.179,
      34.952,
      9.313,
      -88.668,
      36.478,
      9.313
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10476",
    "polygon": [
      -88.429,
      37.189,
      9.313,
      -88.429,
      37.189,
      11.763,
      -88.668,
      36.478,
      11.763,
      -88.668,
      36.478,
      11.763,
      -88.668,
      36.478,
      9.313,
      -88.429,
      37.189,
      9.313
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10477",
    "polygon": [
      -88.231,
      37.782,
      9.313,
      -88.231,
      37.782,
      11.763,
      -88.429,
      37.189,
      11.763,
      -88.429,
      37.189,
      11.763,
      -88.429,
      37.189,
      9.313,
      -88.231,
      37.782,
      9.313
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10478",
    "polygon": [
      -87.72,
      39.309,
      9.313,
      -87.72,
      39.309,
      11.763,
      -88.231,
      37.782,
      11.763,
      -88.231,
      37.782,
      11.763,
      -88.231,
      37.782,
      9.313,
      -87.72,
      39.309,
      9.313
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10479",
    "polygon": [
      -87.482,
      40.02,
      9.313,
      -87.482,
      40.02,
      11.763,
      -87.72,
      39.309,
      11.763,
      -87.72,
      39.309,
      11.763,
      -87.72,
      39.309,
      9.313,
      -87.482,
      40.02,
      9.313
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10480",
    "polygon": [
      -86.733,
      42.258,
      9.313,
      -86.733,
      42.258,
      11.763,
      -87.244,
      40.731,
      11.763,
      -87.244,
      40.731,
      11.763,
      -87.244,
      40.731,
      9.313,
      -86.733,
      42.258,
      9.313
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10481",
    "polygon": [
      -86.495,
      42.969,
      9.313,
      -86.495,
      42.969,
      11.763,
      -86.733,
      42.258,
      11.763,
      -86.733,
      42.258,
      11.763,
      -86.733,
      42.258,
      9.313,
      -86.495,
      42.969,
      9.313
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10482",
    "polygon": [
      -86.296,
      43.562,
      9.313,
      -86.296,
      43.562,
      11.763,
      -86.495,
      42.969,
      11.763,
      -86.495,
      42.969,
      11.763,
      -86.495,
      42.969,
      9.313,
      -86.296,
      43.562,
      9.313
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10483",
    "polygon": [
      -85.785,
      45.089,
      9.313,
      -85.785,
      45.089,
      11.763,
      -86.296,
      43.562,
      11.763,
      -86.296,
      43.562,
      11.763,
      -86.296,
      43.562,
      9.313,
      -85.785,
      45.089,
      9.313
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10484",
    "polygon": [
      -85.547,
      45.8,
      9.313,
      -85.547,
      45.8,
      11.763,
      -85.785,
      45.089,
      11.763,
      -85.785,
      45.089,
      11.763,
      -85.785,
      45.089,
      9.313,
      -85.547,
      45.8,
      9.313
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10485",
    "polygon": [
      -84.798,
      48.038,
      9.313,
      -84.798,
      48.038,
      11.763,
      -85.309,
      46.511,
      11.763,
      -85.309,
      46.511,
      11.763,
      -85.309,
      46.511,
      9.313,
      -84.798,
      48.038,
      9.313
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10486",
    "polygon": [
      -84.56,
      48.749,
      9.313,
      -84.56,
      48.749,
      11.763,
      -84.798,
      48.038,
      11.763,
      -84.798,
      48.038,
      11.763,
      -84.798,
      48.038,
      9.313,
      -84.56,
      48.749,
      9.313
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10487",
    "polygon": [
      -84.361,
      49.342,
      9.313,
      -84.361,
      49.342,
      11.763,
      -84.56,
      48.749,
      11.763,
      -84.56,
      48.749,
      11.763,
      -84.56,
      48.749,
      9.313,
      -84.361,
      49.342,
      9.313
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10488",
    "polygon": [
      -83.85,
      50.868,
      9.313,
      -83.85,
      50.868,
      11.763,
      -84.361,
      49.342,
      11.763,
      -84.361,
      49.342,
      11.763,
      -84.361,
      49.342,
      9.313,
      -83.85,
      50.868,
      9.313
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10489",
    "polygon": [
      -83.612,
      51.579,
      9.313,
      -83.612,
      51.579,
      11.763,
      -83.85,
      50.868,
      11.763,
      -83.85,
      50.868,
      11.763,
      -83.85,
      50.868,
      9.313,
      -83.612,
      51.579,
      9.313
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10490",
    "polygon": [
      -82.863,
      53.817,
      9.313,
      -82.863,
      53.817,
      11.763,
      -83.374,
      52.291,
      11.763,
      -83.374,
      52.291,
      11.763,
      -83.374,
      52.291,
      9.313,
      -82.863,
      53.817,
      9.313
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10491",
    "polygon": [
      -82.625,
      54.529,
      9.313,
      -82.625,
      54.529,
      11.763,
      -82.863,
      53.817,
      11.763,
      -82.863,
      53.817,
      11.763,
      -82.863,
      53.817,
      9.313,
      -82.625,
      54.529,
      9.313
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10492",
    "polygon": [
      -82.426,
      55.121,
      9.313,
      -82.426,
      55.121,
      11.763,
      -82.625,
      54.529,
      11.763,
      -82.625,
      54.529,
      11.763,
      -82.625,
      54.529,
      9.313,
      -82.426,
      55.121,
      9.313
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10493",
    "polygon": [
      -81.915,
      56.648,
      9.313,
      -81.915,
      56.648,
      11.763,
      -82.426,
      55.121,
      11.763,
      -82.426,
      55.121,
      11.763,
      -82.426,
      55.121,
      9.313,
      -81.915,
      56.648,
      9.313
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_10494",
    "polygon": [
      -81.677,
      57.359,
      9.313,
      -81.677,
      57.359,
      11.763,
      -81.915,
      56.648,
      11.763,
      -81.915,
      56.648,
      11.763,
      -81.915,
      56.648,
      9.313,
      -81.677,
      57.359,
      9.313
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10495",
    "polygon": [
      -88.668,
      36.478,
      5.563,
      -88.668,
      36.478,
      8.013,
      -89.179,
      34.952,
      8.013,
      -89.179,
      34.952,
      8.013,
      -89.179,
      34.952,
      5.563,
      -88.668,
      36.478,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10496",
    "polygon": [
      -88.429,
      37.189,
      5.563,
      -88.429,
      37.189,
      8.013,
      -88.668,
      36.478,
      8.013,
      -88.668,
      36.478,
      8.013,
      -88.668,
      36.478,
      5.563,
      -88.429,
      37.189,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10497",
    "polygon": [
      -88.231,
      37.782,
      5.563,
      -88.231,
      37.782,
      8.013,
      -88.429,
      37.189,
      8.013,
      -88.429,
      37.189,
      8.013,
      -88.429,
      37.189,
      5.563,
      -88.231,
      37.782,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10498",
    "polygon": [
      -87.72,
      39.309,
      5.563,
      -87.72,
      39.309,
      8.013,
      -88.231,
      37.782,
      8.013,
      -88.231,
      37.782,
      8.013,
      -88.231,
      37.782,
      5.563,
      -87.72,
      39.309,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10499",
    "polygon": [
      -87.482,
      40.02,
      5.563,
      -87.482,
      40.02,
      8.013,
      -87.72,
      39.309,
      8.013,
      -87.72,
      39.309,
      8.013,
      -87.72,
      39.309,
      5.563,
      -87.482,
      40.02,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10500",
    "polygon": [
      -86.733,
      42.258,
      5.563,
      -86.733,
      42.258,
      8.013,
      -87.244,
      40.731,
      8.013,
      -87.244,
      40.731,
      8.013,
      -87.244,
      40.731,
      5.563,
      -86.733,
      42.258,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10501",
    "polygon": [
      -86.495,
      42.969,
      5.563,
      -86.495,
      42.969,
      8.013,
      -86.733,
      42.258,
      8.013,
      -86.733,
      42.258,
      8.013,
      -86.733,
      42.258,
      5.563,
      -86.495,
      42.969,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10502",
    "polygon": [
      -86.296,
      43.562,
      5.563,
      -86.296,
      43.562,
      8.013,
      -86.495,
      42.969,
      8.013,
      -86.495,
      42.969,
      8.013,
      -86.495,
      42.969,
      5.563,
      -86.296,
      43.562,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10503",
    "polygon": [
      -85.785,
      45.089,
      5.563,
      -85.785,
      45.089,
      8.013,
      -86.296,
      43.562,
      8.013,
      -86.296,
      43.562,
      8.013,
      -86.296,
      43.562,
      5.563,
      -85.785,
      45.089,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10504",
    "polygon": [
      -85.547,
      45.8,
      5.563,
      -85.547,
      45.8,
      8.013,
      -85.785,
      45.089,
      8.013,
      -85.785,
      45.089,
      8.013,
      -85.785,
      45.089,
      5.563,
      -85.547,
      45.8,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10505",
    "polygon": [
      -84.798,
      48.038,
      5.563,
      -84.798,
      48.038,
      8.013,
      -85.309,
      46.511,
      8.013,
      -85.309,
      46.511,
      8.013,
      -85.309,
      46.511,
      5.563,
      -84.798,
      48.038,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10506",
    "polygon": [
      -84.56,
      48.749,
      5.563,
      -84.56,
      48.749,
      8.013,
      -84.798,
      48.038,
      8.013,
      -84.798,
      48.038,
      8.013,
      -84.798,
      48.038,
      5.563,
      -84.56,
      48.749,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10507",
    "polygon": [
      -84.361,
      49.342,
      5.563,
      -84.361,
      49.342,
      8.013,
      -84.56,
      48.749,
      8.013,
      -84.56,
      48.749,
      8.013,
      -84.56,
      48.749,
      5.563,
      -84.361,
      49.342,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10508",
    "polygon": [
      -83.85,
      50.868,
      5.563,
      -83.85,
      50.868,
      8.013,
      -84.361,
      49.342,
      8.013,
      -84.361,
      49.342,
      8.013,
      -84.361,
      49.342,
      5.563,
      -83.85,
      50.868,
      5.563
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10509",
    "polygon": [
      -83.612,
      51.579,
      5.563,
      -83.612,
      51.579,
      8.013,
      -83.85,
      50.868,
      8.013,
      -83.85,
      50.868,
      8.013,
      -83.85,
      50.868,
      5.563,
      -83.612,
      51.579,
      5.563
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10510",
    "polygon": [
      -82.863,
      53.817,
      5.563,
      -82.863,
      53.817,
      8.013,
      -83.374,
      52.291,
      8.013,
      -83.374,
      52.291,
      8.013,
      -83.374,
      52.291,
      5.563,
      -82.863,
      53.817,
      5.563
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10511",
    "polygon": [
      -82.625,
      54.529,
      5.563,
      -82.625,
      54.529,
      8.013,
      -82.863,
      53.817,
      8.013,
      -82.863,
      53.817,
      8.013,
      -82.863,
      53.817,
      5.563,
      -82.625,
      54.529,
      5.563
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10512",
    "polygon": [
      -82.426,
      55.121,
      5.563,
      -82.426,
      55.121,
      8.013,
      -82.625,
      54.529,
      8.013,
      -82.625,
      54.529,
      8.013,
      -82.625,
      54.529,
      5.563,
      -82.426,
      55.121,
      5.563
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10513",
    "polygon": [
      -81.915,
      56.648,
      5.563,
      -81.915,
      56.648,
      8.013,
      -82.426,
      55.121,
      8.013,
      -82.426,
      55.121,
      8.013,
      -82.426,
      55.121,
      5.563,
      -81.915,
      56.648,
      5.563
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_10514",
    "polygon": [
      -81.677,
      57.359,
      5.563,
      -81.677,
      57.359,
      8.013,
      -81.915,
      56.648,
      8.013,
      -81.915,
      56.648,
      8.013,
      -81.915,
      56.648,
      5.563,
      -81.677,
      57.359,
      5.563
    ],
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10515",
    "polygon": [
      -0.732,
      4.654,
      13.073,
      -0.732,
      4.654,
      15.523,
      0.795,
      4.143,
      15.523,
      0.795,
      4.143,
      15.523,
      0.795,
      4.143,
      13.073,
      -0.732,
      4.654,
      13.073
    ]
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10516",
    "polygon": [
      -1.443,
      4.893,
      13.073,
      -1.443,
      4.893,
      15.523,
      -0.732,
      4.654,
      15.523,
      -0.732,
      4.654,
      15.523,
      -0.732,
      4.654,
      13.073,
      -1.443,
      4.893,
      13.073
    ]
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10517",
    "polygon": [
      -3.706,
      5.65,
      13.073,
      -3.706,
      5.65,
      15.523,
      -2.179,
      5.139,
      15.523,
      -2.179,
      5.139,
      15.523,
      -2.179,
      5.139,
      13.073,
      -3.706,
      5.65,
      13.073
    ]
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10518",
    "polygon": [
      -5.009,
      6.087,
      13.073,
      -5.009,
      6.087,
      15.523,
      -4.417,
      5.889,
      15.523,
      -4.417,
      5.889,
      15.523,
      -4.417,
      5.889,
      13.073,
      -5.009,
      6.087,
      13.073
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10519",
    "polygon": [
      -4.417,
      5.889,
      13.073,
      -4.417,
      5.889,
      15.523,
      -3.706,
      5.65,
      15.523,
      -3.706,
      5.65,
      15.523,
      -3.706,
      5.65,
      13.073,
      -4.417,
      5.889,
      13.073
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10520",
    "polygon": [
      -6.536,
      6.599,
      13.073,
      -6.536,
      6.599,
      15.523,
      -5.009,
      6.087,
      15.523,
      -5.009,
      6.087,
      15.523,
      -5.009,
      6.087,
      13.073,
      -6.536,
      6.599,
      13.073
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10521",
    "polygon": [
      -7.247,
      6.837,
      13.073,
      -7.247,
      6.837,
      15.523,
      -6.536,
      6.599,
      15.523,
      -6.536,
      6.599,
      15.523,
      -6.536,
      6.599,
      13.073,
      -7.247,
      6.837,
      13.073
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10522",
    "polygon": [
      -15.251,
      9.518,
      13.073,
      -15.251,
      9.518,
      15.523,
      -13.725,
      9.007,
      15.523,
      -13.725,
      9.007,
      15.523,
      -13.725,
      9.007,
      13.073,
      -15.251,
      9.518,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10523",
    "polygon": [
      -16.555,
      9.955,
      13.073,
      -16.555,
      9.955,
      15.523,
      -15.963,
      9.756,
      15.523,
      -15.963,
      9.756,
      15.523,
      -15.963,
      9.756,
      13.073,
      -16.555,
      9.955,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10524",
    "polygon": [
      -15.963,
      9.756,
      13.073,
      -15.963,
      9.756,
      15.523,
      -15.251,
      9.518,
      15.523,
      -15.251,
      9.518,
      15.523,
      -15.251,
      9.518,
      13.073,
      -15.963,
      9.756,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10525",
    "polygon": [
      -18.082,
      10.466,
      13.073,
      -18.082,
      10.466,
      15.523,
      -16.555,
      9.955,
      15.523,
      -16.555,
      9.955,
      15.523,
      -16.555,
      9.955,
      13.073,
      -18.082,
      10.466,
      13.073
    ],
    "05-22 04:00": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10526",
    "polygon": [
      -18.793,
      10.705,
      13.073,
      -18.793,
      10.705,
      15.523,
      -18.082,
      10.466,
      15.523,
      -18.082,
      10.466,
      15.523,
      -18.082,
      10.466,
      13.073,
      -18.793,
      10.705,
      13.073
    ],
    "05-22 04:00": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10527",
    "polygon": [
      2.098,
      3.706,
      9.323,
      2.098,
      3.706,
      11.773,
      3.625,
      3.195,
      11.773,
      3.625,
      3.195,
      11.773,
      3.625,
      3.195,
      9.323,
      2.098,
      3.706,
      9.323
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10528",
    "polygon": [
      0.795,
      4.143,
      9.323,
      0.795,
      4.143,
      11.773,
      1.387,
      3.944,
      11.773,
      1.387,
      3.944,
      11.773,
      1.387,
      3.944,
      9.323,
      0.795,
      4.143,
      9.323
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10529",
    "polygon": [
      1.387,
      3.944,
      9.323,
      1.387,
      3.944,
      11.773,
      2.098,
      3.706,
      11.773,
      2.098,
      3.706,
      11.773,
      2.098,
      3.706,
      9.323,
      1.387,
      3.944,
      9.323
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10530",
    "polygon": [
      -0.732,
      4.654,
      9.323,
      -0.732,
      4.654,
      11.773,
      0.795,
      4.143,
      11.773,
      0.795,
      4.143,
      11.773,
      0.795,
      4.143,
      9.323,
      -0.732,
      4.654,
      9.323
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10531",
    "polygon": [
      -1.443,
      4.893,
      9.323,
      -1.443,
      4.893,
      11.773,
      -0.732,
      4.654,
      11.773,
      -0.732,
      4.654,
      11.773,
      -0.732,
      4.654,
      9.323,
      -1.443,
      4.893,
      9.323
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10532",
    "polygon": [
      -3.706,
      5.65,
      9.323,
      -3.706,
      5.65,
      11.773,
      -2.179,
      5.139,
      11.773,
      -2.179,
      5.139,
      11.773,
      -2.179,
      5.139,
      9.323,
      -3.706,
      5.65,
      9.323
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10534",
    "polygon": [
      -4.417,
      5.889,
      9.323,
      -4.417,
      5.889,
      11.773,
      -3.706,
      5.65,
      11.773,
      -3.706,
      5.65,
      11.773,
      -3.706,
      5.65,
      9.323,
      -4.417,
      5.889,
      9.323
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10535",
    "polygon": [
      -6.849,
      6.703,
      11.076,
      -6.849,
      6.703,
      11.773,
      -4.697,
      5.982,
      11.773,
      -4.697,
      5.982,
      11.773,
      -4.697,
      5.982,
      11.076,
      -6.849,
      6.703,
      11.076
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10537",
    "polygon": [
      -15.251,
      9.518,
      9.323,
      -15.251,
      9.518,
      11.773,
      -13.725,
      9.007,
      11.773,
      -13.725,
      9.007,
      11.773,
      -13.725,
      9.007,
      9.323,
      -15.251,
      9.518,
      9.323
    ],
    "05-22 05:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10538",
    "polygon": [
      -16.555,
      9.955,
      9.323,
      -16.555,
      9.955,
      11.773,
      -15.963,
      9.756,
      11.773,
      -15.963,
      9.756,
      11.773,
      -15.963,
      9.756,
      9.323,
      -16.555,
      9.955,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10539",
    "polygon": [
      -15.963,
      9.756,
      9.323,
      -15.963,
      9.756,
      11.773,
      -15.251,
      9.518,
      11.773,
      -15.251,
      9.518,
      11.773,
      -15.251,
      9.518,
      9.323,
      -15.963,
      9.756,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10540",
    "polygon": [
      -18.082,
      10.466,
      9.323,
      -18.082,
      10.466,
      11.773,
      -16.555,
      9.955,
      11.773,
      -16.555,
      9.955,
      11.773,
      -16.555,
      9.955,
      9.323,
      -18.082,
      10.466,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10541",
    "polygon": [
      -18.793,
      10.705,
      9.323,
      -18.793,
      10.705,
      11.773,
      -18.082,
      10.466,
      11.773,
      -18.082,
      10.466,
      11.773,
      -18.082,
      10.466,
      9.323,
      -18.793,
      10.705,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10542",
    "polygon": [
      -5.773,
      6.343,
      8.76,
      -5.773,
      6.343,
      11.076,
      -4.697,
      5.982,
      11.076,
      -4.697,
      5.982,
      11.076,
      -4.697,
      5.982,
      8.76,
      -5.773,
      6.343,
      8.76
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10543",
    "polygon": [
      -6.849,
      6.703,
      8.76,
      -6.849,
      6.703,
      11.076,
      -5.773,
      6.343,
      11.076,
      -5.773,
      6.343,
      11.076,
      -5.773,
      6.343,
      8.76,
      -6.849,
      6.703,
      8.76
    ]
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10544",
    "polygon": [
      -17.831,
      15.964,
      13.073,
      -17.831,
      15.964,
      15.523,
      -18.342,
      14.438,
      15.523,
      -18.342,
      14.438,
      15.523,
      -18.342,
      14.438,
      13.073,
      -17.831,
      15.964,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10545",
    "polygon": [
      -17.593,
      16.676,
      13.073,
      -17.593,
      16.676,
      15.523,
      -17.831,
      15.964,
      15.523,
      -17.831,
      15.964,
      15.523,
      -17.831,
      15.964,
      13.073,
      -17.593,
      16.676,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10546",
    "polygon": [
      -16.844,
      18.913,
      13.073,
      -16.844,
      18.913,
      15.523,
      -17.355,
      17.387,
      15.523,
      -17.355,
      17.387,
      15.523,
      -17.355,
      17.387,
      13.073,
      -16.844,
      18.913,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10547",
    "polygon": [
      -16.408,
      20.217,
      13.073,
      -16.408,
      20.217,
      15.523,
      -16.606,
      19.625,
      15.523,
      -16.606,
      19.625,
      15.523,
      -16.606,
      19.625,
      13.073,
      -16.408,
      20.217,
      13.073
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10548",
    "polygon": [
      -16.606,
      19.625,
      13.073,
      -16.606,
      19.625,
      15.523,
      -16.844,
      18.913,
      15.523,
      -16.844,
      18.913,
      15.523,
      -16.844,
      18.913,
      13.073,
      -16.606,
      19.625,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10549",
    "polygon": [
      -15.896,
      21.744,
      13.073,
      -15.896,
      21.744,
      15.523,
      -16.408,
      20.217,
      15.523,
      -16.408,
      20.217,
      15.523,
      -16.408,
      20.217,
      13.073,
      -15.896,
      21.744,
      13.073
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10550",
    "polygon": [
      -15.658,
      22.455,
      13.073,
      -15.658,
      22.455,
      15.523,
      -15.896,
      21.744,
      15.523,
      -15.896,
      21.744,
      15.523,
      -15.896,
      21.744,
      13.073,
      -15.658,
      22.455,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10551",
    "polygon": [
      -14.909,
      24.693,
      13.073,
      -14.909,
      24.693,
      15.523,
      -15.42,
      23.166,
      15.523,
      -15.42,
      23.166,
      15.523,
      -15.42,
      23.166,
      13.073,
      -14.909,
      24.693,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10552",
    "polygon": [
      -14.473,
      25.997,
      13.073,
      -14.473,
      25.997,
      15.523,
      -14.671,
      25.404,
      15.523,
      -14.671,
      25.404,
      15.523,
      -14.671,
      25.404,
      13.073,
      -14.473,
      25.997,
      13.073
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10553",
    "polygon": [
      -14.671,
      25.404,
      13.073,
      -14.671,
      25.404,
      15.523,
      -14.909,
      24.693,
      15.523,
      -14.909,
      24.693,
      15.523,
      -14.909,
      24.693,
      13.073,
      -14.671,
      25.404,
      13.073
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10554",
    "polygon": [
      -13.962,
      27.524,
      13.073,
      -13.962,
      27.524,
      15.523,
      -14.473,
      25.997,
      15.523,
      -14.473,
      25.997,
      15.523,
      -14.473,
      25.997,
      13.073,
      -13.962,
      27.524,
      13.073
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10555",
    "polygon": [
      -13.723,
      28.235,
      13.073,
      -13.723,
      28.235,
      15.523,
      -13.962,
      27.524,
      15.523,
      -13.962,
      27.524,
      15.523,
      -13.962,
      27.524,
      13.073,
      -13.723,
      28.235,
      13.073
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10556",
    "polygon": [
      -12.981,
      30.454,
      13.073,
      -12.981,
      30.454,
      15.523,
      -13.492,
      28.927,
      15.523,
      -13.492,
      28.927,
      15.523,
      -13.492,
      28.927,
      13.073,
      -12.981,
      30.454,
      13.073
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10557",
    "polygon": [
      -12.544,
      31.758,
      13.073,
      -12.544,
      31.758,
      15.523,
      -12.742,
      31.165,
      15.523,
      -12.742,
      31.165,
      15.523,
      -12.742,
      31.165,
      13.073,
      -12.544,
      31.758,
      13.073
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10558",
    "polygon": [
      -12.742,
      31.165,
      13.073,
      -12.742,
      31.165,
      15.523,
      -12.981,
      30.454,
      15.523,
      -12.981,
      30.454,
      15.523,
      -12.981,
      30.454,
      13.073,
      -12.742,
      31.165,
      13.073
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10559",
    "polygon": [
      -12.033,
      33.285,
      13.073,
      -12.033,
      33.285,
      15.523,
      -12.544,
      31.758,
      15.523,
      -12.544,
      31.758,
      15.523,
      -12.544,
      31.758,
      13.073,
      -12.033,
      33.285,
      13.073
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_10560",
    "polygon": [
      -11.795,
      33.996,
      13.073,
      -11.795,
      33.996,
      15.523,
      -12.033,
      33.285,
      15.523,
      -12.033,
      33.285,
      15.523,
      -12.033,
      33.285,
      13.073,
      -11.795,
      33.996,
      13.073
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10561",
    "polygon": [
      -18.779,
      13.134,
      9.323,
      -18.779,
      13.134,
      11.773,
      -19.29,
      11.607,
      11.773,
      -19.29,
      11.607,
      11.773,
      -19.29,
      11.607,
      9.323,
      -18.779,
      13.134,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10562",
    "polygon": [
      -18.342,
      14.438,
      9.323,
      -18.342,
      14.438,
      11.773,
      -18.541,
      13.845,
      11.773,
      -18.541,
      13.845,
      11.773,
      -18.541,
      13.845,
      9.323,
      -18.342,
      14.438,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10563",
    "polygon": [
      -18.541,
      13.845,
      9.323,
      -18.541,
      13.845,
      11.773,
      -18.779,
      13.134,
      11.773,
      -18.779,
      13.134,
      11.773,
      -18.779,
      13.134,
      9.323,
      -18.541,
      13.845,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10564",
    "polygon": [
      -17.831,
      15.964,
      9.323,
      -17.831,
      15.964,
      11.773,
      -18.342,
      14.438,
      11.773,
      -18.342,
      14.438,
      11.773,
      -18.342,
      14.438,
      9.323,
      -17.831,
      15.964,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10565",
    "polygon": [
      -17.593,
      16.676,
      9.323,
      -17.593,
      16.676,
      11.773,
      -17.831,
      15.964,
      11.773,
      -17.831,
      15.964,
      11.773,
      -17.831,
      15.964,
      9.323,
      -17.593,
      16.676,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10566",
    "polygon": [
      -16.844,
      18.913,
      9.323,
      -16.844,
      18.913,
      11.773,
      -17.355,
      17.387,
      11.773,
      -17.355,
      17.387,
      11.773,
      -17.355,
      17.387,
      9.323,
      -16.844,
      18.913,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10567",
    "polygon": [
      -16.408,
      20.217,
      9.323,
      -16.408,
      20.217,
      11.773,
      -16.606,
      19.625,
      11.773,
      -16.606,
      19.625,
      11.773,
      -16.606,
      19.625,
      9.323,
      -16.408,
      20.217,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10568",
    "polygon": [
      -16.606,
      19.625,
      9.323,
      -16.606,
      19.625,
      11.773,
      -16.844,
      18.913,
      11.773,
      -16.844,
      18.913,
      11.773,
      -16.844,
      18.913,
      9.323,
      -16.606,
      19.625,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10569",
    "polygon": [
      -15.896,
      21.744,
      9.323,
      -15.896,
      21.744,
      11.773,
      -16.408,
      20.217,
      11.773,
      -16.408,
      20.217,
      11.773,
      -16.408,
      20.217,
      9.323,
      -15.896,
      21.744,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10570",
    "polygon": [
      -15.658,
      22.455,
      9.323,
      -15.658,
      22.455,
      11.773,
      -15.896,
      21.744,
      11.773,
      -15.896,
      21.744,
      11.773,
      -15.896,
      21.744,
      9.323,
      -15.658,
      22.455,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10571",
    "polygon": [
      -14.909,
      24.693,
      9.323,
      -14.909,
      24.693,
      11.773,
      -15.42,
      23.166,
      11.773,
      -15.42,
      23.166,
      11.773,
      -15.42,
      23.166,
      9.323,
      -14.909,
      24.693,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10572",
    "polygon": [
      -14.473,
      25.997,
      9.323,
      -14.473,
      25.997,
      11.773,
      -14.671,
      25.404,
      11.773,
      -14.671,
      25.404,
      11.773,
      -14.671,
      25.404,
      9.323,
      -14.473,
      25.997,
      9.323
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10573",
    "polygon": [
      -14.671,
      25.404,
      9.323,
      -14.671,
      25.404,
      11.773,
      -14.909,
      24.693,
      11.773,
      -14.909,
      24.693,
      11.773,
      -14.909,
      24.693,
      9.323,
      -14.671,
      25.404,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10574",
    "polygon": [
      -13.962,
      27.524,
      9.323,
      -13.962,
      27.524,
      11.773,
      -14.473,
      25.997,
      11.773,
      -14.473,
      25.997,
      11.773,
      -14.473,
      25.997,
      9.323,
      -13.962,
      27.524,
      9.323
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10575",
    "polygon": [
      -13.723,
      28.235,
      9.323,
      -13.723,
      28.235,
      11.773,
      -13.962,
      27.524,
      11.773,
      -13.962,
      27.524,
      11.773,
      -13.962,
      27.524,
      9.323,
      -13.723,
      28.235,
      9.323
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10576",
    "polygon": [
      -12.981,
      30.454,
      9.323,
      -12.981,
      30.454,
      11.773,
      -13.492,
      28.927,
      11.773,
      -13.492,
      28.927,
      11.773,
      -13.492,
      28.927,
      9.323,
      -12.981,
      30.454,
      9.323
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10577",
    "polygon": [
      -12.544,
      31.758,
      9.323,
      -12.544,
      31.758,
      11.773,
      -12.742,
      31.165,
      11.773,
      -12.742,
      31.165,
      11.773,
      -12.742,
      31.165,
      9.323,
      -12.544,
      31.758,
      9.323
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10578",
    "polygon": [
      -12.742,
      31.165,
      9.323,
      -12.742,
      31.165,
      11.773,
      -12.981,
      30.454,
      11.773,
      -12.981,
      30.454,
      11.773,
      -12.981,
      30.454,
      9.323,
      -12.742,
      31.165,
      9.323
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10579",
    "polygon": [
      -12.033,
      33.285,
      9.323,
      -12.033,
      33.285,
      11.773,
      -12.544,
      31.758,
      11.773,
      -12.544,
      31.758,
      11.773,
      -12.544,
      31.758,
      9.323,
      -12.033,
      33.285,
      9.323
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_10580",
    "polygon": [
      -11.795,
      33.996,
      9.323,
      -11.795,
      33.996,
      11.773,
      -12.033,
      33.285,
      11.773,
      -12.033,
      33.285,
      11.773,
      -12.033,
      33.285,
      9.323,
      -11.795,
      33.996,
      9.323
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10581",
    "polygon": [
      83.779,
      -3.76,
      13.073,
      83.779,
      -3.76,
      15.523,
      84.291,
      -2.233,
      15.523,
      84.291,
      -2.233,
      15.523,
      84.291,
      -2.233,
      13.073,
      83.779,
      -3.76,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10582",
    "polygon": [
      84.291,
      -2.233,
      13.073,
      84.291,
      -2.233,
      15.523,
      84.529,
      -1.522,
      15.523,
      84.529,
      -1.522,
      15.523,
      84.529,
      -1.522,
      13.073,
      84.291,
      -2.233,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10583",
    "polygon": [
      82.595,
      -7.295,
      13.073,
      82.595,
      -7.295,
      15.523,
      82.794,
      -6.702,
      15.523,
      82.794,
      -6.702,
      15.523,
      82.794,
      -6.702,
      13.073,
      82.595,
      -7.295,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10584",
    "polygon": [
      82.794,
      -6.702,
      13.073,
      82.794,
      -6.702,
      15.523,
      83.305,
      -5.176,
      15.523,
      83.305,
      -5.176,
      15.523,
      83.305,
      -5.176,
      13.073,
      82.794,
      -6.702,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10585",
    "polygon": [
      83.305,
      -5.176,
      13.073,
      83.305,
      -5.176,
      15.523,
      83.543,
      -4.464,
      15.523,
      83.543,
      -4.464,
      15.523,
      83.543,
      -4.464,
      13.073,
      83.305,
      -5.176,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10586",
    "polygon": [
      81.846,
      -9.533,
      13.073,
      81.846,
      -9.533,
      15.523,
      82.357,
      -8.006,
      15.523,
      82.357,
      -8.006,
      15.523,
      82.357,
      -8.006,
      13.073,
      81.846,
      -9.533,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10587",
    "polygon": [
      82.357,
      -8.006,
      13.073,
      82.357,
      -8.006,
      15.523,
      82.595,
      -7.295,
      15.523,
      82.595,
      -7.295,
      15.523,
      82.595,
      -7.295,
      13.073,
      82.357,
      -8.006,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10588",
    "polygon": [
      80.659,
      -13.074,
      13.073,
      80.659,
      -13.074,
      15.523,
      80.858,
      -12.482,
      15.523,
      80.858,
      -12.482,
      15.523,
      80.858,
      -12.482,
      13.073,
      80.659,
      -13.074,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10589",
    "polygon": [
      80.858,
      -12.482,
      13.073,
      80.858,
      -12.482,
      15.523,
      81.369,
      -10.955,
      15.523,
      81.369,
      -10.955,
      15.523,
      81.369,
      -10.955,
      13.073,
      80.858,
      -12.482,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10590",
    "polygon": [
      81.369,
      -10.955,
      13.073,
      81.369,
      -10.955,
      15.523,
      81.607,
      -10.244,
      15.523,
      81.607,
      -10.244,
      15.523,
      81.607,
      -10.244,
      13.073,
      81.369,
      -10.955,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10591",
    "polygon": [
      79.91,
      -15.312,
      13.073,
      79.91,
      -15.312,
      15.523,
      80.421,
      -13.785,
      15.523,
      80.421,
      -13.785,
      15.523,
      80.421,
      -13.785,
      13.073,
      79.91,
      -15.312,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10592",
    "polygon": [
      80.421,
      -13.785,
      13.073,
      80.421,
      -13.785,
      15.523,
      80.659,
      -13.074,
      15.523,
      80.659,
      -13.074,
      15.523,
      80.659,
      -13.074,
      13.073,
      80.421,
      -13.785,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10593",
    "polygon": [
      78.755,
      -18.759,
      13.073,
      78.755,
      -18.759,
      15.523,
      78.953,
      -18.166,
      15.523,
      78.953,
      -18.166,
      15.523,
      78.953,
      -18.166,
      13.073,
      78.755,
      -18.759,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10594",
    "polygon": [
      78.953,
      -18.166,
      13.073,
      78.953,
      -18.166,
      15.523,
      79.465,
      -16.639,
      15.523,
      79.465,
      -16.639,
      15.523,
      79.465,
      -16.639,
      13.073,
      78.953,
      -18.166,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10595",
    "polygon": [
      79.465,
      -16.639,
      13.073,
      79.465,
      -16.639,
      15.523,
      79.703,
      -15.928,
      15.523,
      79.703,
      -15.928,
      15.523,
      79.703,
      -15.928,
      13.073,
      79.465,
      -16.639,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10596",
    "polygon": [
      78.005,
      -20.996,
      13.073,
      78.005,
      -20.996,
      15.523,
      78.517,
      -19.47,
      15.523,
      78.517,
      -19.47,
      15.523,
      78.517,
      -19.47,
      13.073,
      78.005,
      -20.996,
      13.073
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10597",
    "polygon": [
      78.517,
      -19.47,
      13.073,
      78.517,
      -19.47,
      15.523,
      78.755,
      -18.759,
      15.523,
      78.755,
      -18.759,
      15.523,
      78.755,
      -18.759,
      13.073,
      78.517,
      -19.47,
      13.073
    ],
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10598",
    "polygon": [
      84.529,
      -1.522,
      9.323,
      84.529,
      -1.522,
      11.773,
      84.728,
      -0.929,
      11.773,
      84.728,
      -0.929,
      11.773,
      84.728,
      -0.929,
      9.323,
      84.529,
      -1.522,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10599",
    "polygon": [
      84.728,
      -0.929,
      9.323,
      84.728,
      -0.929,
      11.773,
      85.239,
      0.597,
      11.773,
      85.239,
      0.597,
      11.773,
      85.239,
      0.597,
      9.323,
      84.728,
      -0.929,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10600",
    "polygon": [
      85.239,
      0.597,
      9.323,
      85.239,
      0.597,
      11.773,
      85.477,
      1.309,
      11.773,
      85.477,
      1.309,
      11.773,
      85.477,
      1.309,
      9.323,
      85.239,
      0.597,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10601",
    "polygon": [
      83.779,
      -3.76,
      9.323,
      83.779,
      -3.76,
      11.773,
      84.291,
      -2.233,
      11.773,
      84.291,
      -2.233,
      11.773,
      84.291,
      -2.233,
      9.323,
      83.779,
      -3.76,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10602",
    "polygon": [
      84.291,
      -2.233,
      9.323,
      84.291,
      -2.233,
      11.773,
      84.529,
      -1.522,
      11.773,
      84.529,
      -1.522,
      11.773,
      84.529,
      -1.522,
      9.323,
      84.291,
      -2.233,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10603",
    "polygon": [
      82.595,
      -7.295,
      9.323,
      82.595,
      -7.295,
      11.773,
      82.794,
      -6.702,
      11.773,
      82.794,
      -6.702,
      11.773,
      82.794,
      -6.702,
      9.323,
      82.595,
      -7.295,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10604",
    "polygon": [
      82.794,
      -6.702,
      9.323,
      82.794,
      -6.702,
      11.773,
      83.305,
      -5.176,
      11.773,
      83.305,
      -5.176,
      11.773,
      83.305,
      -5.176,
      9.323,
      82.794,
      -6.702,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10605",
    "polygon": [
      83.305,
      -5.176,
      9.323,
      83.305,
      -5.176,
      11.773,
      83.543,
      -4.464,
      11.773,
      83.543,
      -4.464,
      11.773,
      83.543,
      -4.464,
      9.323,
      83.305,
      -5.176,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10606",
    "polygon": [
      81.846,
      -9.533,
      9.323,
      81.846,
      -9.533,
      11.773,
      82.357,
      -8.006,
      11.773,
      82.357,
      -8.006,
      11.773,
      82.357,
      -8.006,
      9.323,
      81.846,
      -9.533,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10607",
    "polygon": [
      82.357,
      -8.006,
      9.323,
      82.357,
      -8.006,
      11.773,
      82.595,
      -7.295,
      11.773,
      82.595,
      -7.295,
      11.773,
      82.595,
      -7.295,
      9.323,
      82.357,
      -8.006,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10608",
    "polygon": [
      80.659,
      -13.074,
      9.323,
      80.659,
      -13.074,
      11.773,
      80.858,
      -12.482,
      11.773,
      80.858,
      -12.482,
      11.773,
      80.858,
      -12.482,
      9.323,
      80.659,
      -13.074,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10609",
    "polygon": [
      80.858,
      -12.482,
      9.323,
      80.858,
      -12.482,
      11.773,
      81.369,
      -10.955,
      11.773,
      81.369,
      -10.955,
      11.773,
      81.369,
      -10.955,
      9.323,
      80.858,
      -12.482,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10610",
    "polygon": [
      81.369,
      -10.955,
      9.323,
      81.369,
      -10.955,
      11.773,
      81.607,
      -10.244,
      11.773,
      81.607,
      -10.244,
      11.773,
      81.607,
      -10.244,
      9.323,
      81.369,
      -10.955,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10611",
    "polygon": [
      79.91,
      -15.312,
      9.323,
      79.91,
      -15.312,
      11.773,
      80.421,
      -13.785,
      11.773,
      80.421,
      -13.785,
      11.773,
      80.421,
      -13.785,
      9.323,
      79.91,
      -15.312,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10612",
    "polygon": [
      80.421,
      -13.785,
      9.323,
      80.421,
      -13.785,
      11.773,
      80.659,
      -13.074,
      11.773,
      80.659,
      -13.074,
      11.773,
      80.659,
      -13.074,
      9.323,
      80.421,
      -13.785,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10613",
    "polygon": [
      78.755,
      -18.759,
      9.323,
      78.755,
      -18.759,
      11.773,
      78.953,
      -18.166,
      11.773,
      78.953,
      -18.166,
      11.773,
      78.953,
      -18.166,
      9.323,
      78.755,
      -18.759,
      9.323
    ],
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10614",
    "polygon": [
      78.953,
      -18.166,
      9.323,
      78.953,
      -18.166,
      11.773,
      79.465,
      -16.639,
      11.773,
      79.465,
      -16.639,
      11.773,
      79.465,
      -16.639,
      9.323,
      78.953,
      -18.166,
      9.323
    ],
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10615",
    "polygon": [
      79.465,
      -16.639,
      9.323,
      79.465,
      -16.639,
      11.773,
      79.703,
      -15.928,
      11.773,
      79.703,
      -15.928,
      11.773,
      79.703,
      -15.928,
      9.323,
      79.465,
      -16.639,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10616",
    "polygon": [
      78.005,
      -20.996,
      9.323,
      78.005,
      -20.996,
      11.773,
      78.517,
      -19.47,
      11.773,
      78.517,
      -19.47,
      11.773,
      78.517,
      -19.47,
      9.323,
      78.005,
      -20.996,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10617",
    "polygon": [
      78.517,
      -19.47,
      9.323,
      78.517,
      -19.47,
      11.773,
      78.755,
      -18.759,
      11.773,
      78.755,
      -18.759,
      11.773,
      78.755,
      -18.759,
      9.323,
      78.517,
      -19.47,
      9.323
    ],
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10618",
    "polygon": [
      84.529,
      -1.522,
      5.573,
      84.529,
      -1.522,
      8.023,
      84.728,
      -0.929,
      8.023,
      84.728,
      -0.929,
      8.023,
      84.728,
      -0.929,
      5.573,
      84.529,
      -1.522,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10619",
    "polygon": [
      84.728,
      -0.929,
      5.573,
      84.728,
      -0.929,
      8.023,
      85.239,
      0.597,
      8.023,
      85.239,
      0.597,
      8.023,
      85.239,
      0.597,
      5.573,
      84.728,
      -0.929,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10620",
    "polygon": [
      85.239,
      0.597,
      5.573,
      85.239,
      0.597,
      8.023,
      85.477,
      1.309,
      8.023,
      85.477,
      1.309,
      8.023,
      85.477,
      1.309,
      5.573,
      85.239,
      0.597,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10621",
    "polygon": [
      83.779,
      -3.76,
      5.573,
      83.779,
      -3.76,
      8.023,
      84.291,
      -2.233,
      8.023,
      84.291,
      -2.233,
      8.023,
      84.291,
      -2.233,
      5.573,
      83.779,
      -3.76,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10622",
    "polygon": [
      84.291,
      -2.233,
      5.573,
      84.291,
      -2.233,
      8.023,
      84.529,
      -1.522,
      8.023,
      84.529,
      -1.522,
      8.023,
      84.529,
      -1.522,
      5.573,
      84.291,
      -2.233,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10623",
    "polygon": [
      82.595,
      -7.295,
      5.573,
      82.595,
      -7.295,
      8.023,
      82.794,
      -6.702,
      8.023,
      82.794,
      -6.702,
      8.023,
      82.794,
      -6.702,
      5.573,
      82.595,
      -7.295,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10624",
    "polygon": [
      82.794,
      -6.702,
      5.573,
      82.794,
      -6.702,
      8.023,
      83.305,
      -5.176,
      8.023,
      83.305,
      -5.176,
      8.023,
      83.305,
      -5.176,
      5.573,
      82.794,
      -6.702,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10625",
    "polygon": [
      83.305,
      -5.176,
      5.573,
      83.305,
      -5.176,
      8.023,
      83.543,
      -4.464,
      8.023,
      83.543,
      -4.464,
      8.023,
      83.543,
      -4.464,
      5.573,
      83.305,
      -5.176,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10626",
    "polygon": [
      81.846,
      -9.533,
      5.573,
      81.846,
      -9.533,
      8.023,
      82.357,
      -8.006,
      8.023,
      82.357,
      -8.006,
      8.023,
      82.357,
      -8.006,
      5.573,
      81.846,
      -9.533,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10627",
    "polygon": [
      82.357,
      -8.006,
      5.573,
      82.357,
      -8.006,
      8.023,
      82.595,
      -7.295,
      8.023,
      82.595,
      -7.295,
      8.023,
      82.595,
      -7.295,
      5.573,
      82.357,
      -8.006,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10628",
    "polygon": [
      80.659,
      -13.074,
      5.573,
      80.659,
      -13.074,
      8.023,
      80.858,
      -12.482,
      8.023,
      80.858,
      -12.482,
      8.023,
      80.858,
      -12.482,
      5.573,
      80.659,
      -13.074,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10629",
    "polygon": [
      80.858,
      -12.482,
      5.573,
      80.858,
      -12.482,
      8.023,
      81.369,
      -10.955,
      8.023,
      81.369,
      -10.955,
      8.023,
      81.369,
      -10.955,
      5.573,
      80.858,
      -12.482,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10630",
    "polygon": [
      81.369,
      -10.955,
      5.573,
      81.369,
      -10.955,
      8.023,
      81.607,
      -10.244,
      8.023,
      81.607,
      -10.244,
      8.023,
      81.607,
      -10.244,
      5.573,
      81.369,
      -10.955,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10631",
    "polygon": [
      79.91,
      -15.312,
      5.573,
      79.91,
      -15.312,
      8.023,
      80.421,
      -13.785,
      8.023,
      80.421,
      -13.785,
      8.023,
      80.421,
      -13.785,
      5.573,
      79.91,
      -15.312,
      5.573
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10632",
    "polygon": [
      80.421,
      -13.785,
      5.573,
      80.421,
      -13.785,
      8.023,
      80.659,
      -13.074,
      8.023,
      80.659,
      -13.074,
      8.023,
      80.659,
      -13.074,
      5.573,
      80.421,
      -13.785,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10633",
    "polygon": [
      78.755,
      -18.759,
      5.573,
      78.755,
      -18.759,
      8.023,
      78.953,
      -18.166,
      8.023,
      78.953,
      -18.166,
      8.023,
      78.953,
      -18.166,
      5.573,
      78.755,
      -18.759,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10634",
    "polygon": [
      78.953,
      -18.166,
      5.573,
      78.953,
      -18.166,
      8.023,
      79.465,
      -16.639,
      8.023,
      79.465,
      -16.639,
      8.023,
      79.465,
      -16.639,
      5.573,
      78.953,
      -18.166,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10635",
    "polygon": [
      79.465,
      -16.639,
      5.573,
      79.465,
      -16.639,
      8.023,
      79.703,
      -15.928,
      8.023,
      79.703,
      -15.928,
      8.023,
      79.703,
      -15.928,
      5.573,
      79.465,
      -16.639,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10636",
    "polygon": [
      78.005,
      -20.996,
      5.573,
      78.005,
      -20.996,
      8.023,
      78.517,
      -19.47,
      8.023,
      78.517,
      -19.47,
      8.023,
      78.517,
      -19.47,
      5.573,
      78.005,
      -20.996,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10637",
    "polygon": [
      78.517,
      -19.47,
      5.573,
      78.517,
      -19.47,
      8.023,
      78.755,
      -18.759,
      8.023,
      78.755,
      -18.759,
      8.023,
      78.755,
      -18.759,
      5.573,
      78.517,
      -19.47,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10638",
    "polygon": [
      72.745,
      -20.002,
      13.073,
      72.745,
      -20.002,
      15.523,
      74.272,
      -20.513,
      15.523,
      74.272,
      -20.513,
      15.523,
      74.272,
      -20.513,
      13.073,
      72.745,
      -20.002,
      13.073
    ]
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10639",
    "polygon": [
      72.034,
      -19.764,
      13.073,
      72.034,
      -19.764,
      15.523,
      72.745,
      -20.002,
      15.523,
      72.745,
      -20.002,
      15.523,
      72.745,
      -20.002,
      13.073,
      72.034,
      -19.764,
      13.073
    ]
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10640",
    "polygon": [
      69.796,
      -19.014,
      13.073,
      69.796,
      -19.014,
      15.523,
      71.323,
      -19.525,
      15.523,
      71.323,
      -19.525,
      15.523,
      71.323,
      -19.525,
      13.073,
      69.796,
      -19.014,
      13.073
    ]
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10641",
    "polygon": [
      69.085,
      -18.776,
      13.073,
      69.085,
      -18.776,
      15.523,
      69.796,
      -19.014,
      15.523,
      69.796,
      -19.014,
      15.523,
      69.796,
      -19.014,
      13.073,
      69.085,
      -18.776,
      13.073
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10642",
    "polygon": [
      68.492,
      -18.577,
      13.073,
      68.492,
      -18.577,
      15.523,
      69.085,
      -18.776,
      15.523,
      69.085,
      -18.776,
      15.523,
      69.085,
      -18.776,
      13.073,
      68.492,
      -18.577,
      13.073
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10643",
    "polygon": [
      66.966,
      -18.066,
      13.073,
      66.966,
      -18.066,
      15.523,
      68.492,
      -18.577,
      15.523,
      68.492,
      -18.577,
      15.523,
      68.492,
      -18.577,
      13.073,
      66.966,
      -18.066,
      13.073
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10644",
    "polygon": [
      66.254,
      -17.828,
      13.073,
      66.254,
      -17.828,
      15.523,
      66.966,
      -18.066,
      15.523,
      66.966,
      -18.066,
      15.523,
      66.966,
      -18.066,
      13.073,
      66.254,
      -17.828,
      13.073
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10645",
    "polygon": [
      64.017,
      -17.078,
      13.073,
      64.017,
      -17.078,
      15.523,
      65.543,
      -17.589,
      15.523,
      65.543,
      -17.589,
      15.523,
      65.543,
      -17.589,
      13.073,
      64.017,
      -17.078,
      13.073
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10646",
    "polygon": [
      63.305,
      -16.84,
      13.073,
      63.305,
      -16.84,
      15.523,
      64.017,
      -17.078,
      15.523,
      64.017,
      -17.078,
      15.523,
      64.017,
      -17.078,
      13.073,
      63.305,
      -16.84,
      13.073
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10647",
    "polygon": [
      62.713,
      -16.641,
      13.073,
      62.713,
      -16.641,
      15.523,
      63.305,
      -16.84,
      15.523,
      63.305,
      -16.84,
      15.523,
      63.305,
      -16.84,
      13.073,
      62.713,
      -16.641,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10648",
    "polygon": [
      61.186,
      -16.13,
      13.073,
      61.186,
      -16.13,
      15.523,
      62.713,
      -16.641,
      15.523,
      62.713,
      -16.641,
      15.523,
      62.713,
      -16.641,
      13.073,
      61.186,
      -16.13,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10649",
    "polygon": [
      60.475,
      -15.892,
      13.073,
      60.475,
      -15.892,
      15.523,
      61.186,
      -16.13,
      15.523,
      61.186,
      -16.13,
      15.523,
      61.186,
      -16.13,
      13.073,
      60.475,
      -15.892,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10650",
    "polygon": [
      58.237,
      -15.142,
      13.073,
      58.237,
      -15.142,
      15.523,
      59.764,
      -15.653,
      15.523,
      59.764,
      -15.653,
      15.523,
      59.764,
      -15.653,
      13.073,
      58.237,
      -15.142,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10651",
    "polygon": [
      57.526,
      -14.904,
      13.073,
      57.526,
      -14.904,
      15.523,
      58.237,
      -15.142,
      15.523,
      58.237,
      -15.142,
      15.523,
      58.237,
      -15.142,
      13.073,
      57.526,
      -14.904,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10652",
    "polygon": [
      56.933,
      -14.705,
      13.073,
      56.933,
      -14.705,
      15.523,
      57.526,
      -14.904,
      15.523,
      57.526,
      -14.904,
      15.523,
      57.526,
      -14.904,
      13.073,
      56.933,
      -14.705,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10653",
    "polygon": [
      55.407,
      -14.194,
      13.073,
      55.407,
      -14.194,
      15.523,
      56.933,
      -14.705,
      15.523,
      56.933,
      -14.705,
      15.523,
      56.933,
      -14.705,
      13.073,
      55.407,
      -14.194,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_10654",
    "polygon": [
      54.696,
      -13.956,
      13.073,
      54.696,
      -13.956,
      15.523,
      55.407,
      -14.194,
      15.523,
      55.407,
      -14.194,
      15.523,
      55.407,
      -14.194,
      13.073,
      54.696,
      -13.956,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10655",
    "polygon": [
      75.576,
      -20.948,
      9.323,
      75.576,
      -20.948,
      11.773,
      77.102,
      -21.46,
      11.773,
      77.102,
      -21.46,
      11.773,
      77.102,
      -21.46,
      9.323,
      75.576,
      -20.948,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10656",
    "polygon": [
      74.865,
      -20.71,
      9.323,
      74.865,
      -20.71,
      11.773,
      75.576,
      -20.948,
      11.773,
      75.576,
      -20.948,
      11.773,
      75.576,
      -20.948,
      9.323,
      74.865,
      -20.71,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10657",
    "polygon": [
      74.272,
      -20.512,
      9.323,
      74.272,
      -20.512,
      11.773,
      74.865,
      -20.71,
      11.773,
      74.865,
      -20.71,
      11.773,
      74.865,
      -20.71,
      9.323,
      74.272,
      -20.512,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10658",
    "polygon": [
      72.745,
      -20.0,
      9.323,
      72.745,
      -20.0,
      11.773,
      74.272,
      -20.512,
      11.773,
      74.272,
      -20.512,
      11.773,
      74.272,
      -20.512,
      9.323,
      72.745,
      -20.0,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10659",
    "polygon": [
      72.034,
      -19.762,
      9.323,
      72.034,
      -19.762,
      11.773,
      72.745,
      -20.0,
      11.773,
      72.745,
      -20.0,
      11.773,
      72.745,
      -20.0,
      9.323,
      72.034,
      -19.762,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10660",
    "polygon": [
      69.796,
      -19.012,
      9.323,
      69.796,
      -19.012,
      11.773,
      71.323,
      -19.524,
      11.773,
      71.323,
      -19.524,
      11.773,
      71.323,
      -19.524,
      9.323,
      69.796,
      -19.012,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10661",
    "polygon": [
      69.085,
      -18.774,
      9.323,
      69.085,
      -18.774,
      11.773,
      69.796,
      -19.012,
      11.773,
      69.796,
      -19.012,
      11.773,
      69.796,
      -19.012,
      9.323,
      69.085,
      -18.774,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10662",
    "polygon": [
      68.493,
      -18.576,
      9.323,
      68.493,
      -18.576,
      11.773,
      69.085,
      -18.774,
      11.773,
      69.085,
      -18.774,
      11.773,
      69.085,
      -18.774,
      9.323,
      68.493,
      -18.576,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10663",
    "polygon": [
      66.966,
      -18.064,
      9.323,
      66.966,
      -18.064,
      11.773,
      68.493,
      -18.576,
      11.773,
      68.493,
      -18.576,
      11.773,
      68.493,
      -18.576,
      9.323,
      66.966,
      -18.064,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10664",
    "polygon": [
      66.255,
      -17.826,
      9.323,
      66.255,
      -17.826,
      11.773,
      66.966,
      -18.064,
      11.773,
      66.966,
      -18.064,
      11.773,
      66.966,
      -18.064,
      9.323,
      66.255,
      -17.826,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10665",
    "polygon": [
      64.017,
      -17.076,
      9.323,
      64.017,
      -17.076,
      11.773,
      65.544,
      -17.588,
      11.773,
      65.544,
      -17.588,
      11.773,
      65.544,
      -17.588,
      9.323,
      64.017,
      -17.076,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10666",
    "polygon": [
      63.306,
      -16.838,
      9.323,
      63.306,
      -16.838,
      11.773,
      64.017,
      -17.076,
      11.773,
      64.017,
      -17.076,
      11.773,
      64.017,
      -17.076,
      9.323,
      63.306,
      -16.838,
      9.323
    ],
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10667",
    "polygon": [
      62.713,
      -16.64,
      9.323,
      62.713,
      -16.64,
      11.773,
      63.306,
      -16.838,
      11.773,
      63.306,
      -16.838,
      11.773,
      63.306,
      -16.838,
      9.323,
      62.713,
      -16.64,
      9.323
    ],
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10668",
    "polygon": [
      61.187,
      -16.128,
      9.323,
      61.187,
      -16.128,
      11.773,
      62.713,
      -16.64,
      11.773,
      62.713,
      -16.64,
      11.773,
      62.713,
      -16.64,
      9.323,
      61.187,
      -16.128,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10669",
    "polygon": [
      60.476,
      -15.89,
      9.323,
      60.476,
      -15.89,
      11.773,
      61.187,
      -16.128,
      11.773,
      61.187,
      -16.128,
      11.773,
      61.187,
      -16.128,
      9.323,
      60.476,
      -15.89,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10670",
    "polygon": [
      58.238,
      -15.14,
      9.323,
      58.238,
      -15.14,
      11.773,
      59.764,
      -15.652,
      11.773,
      59.764,
      -15.652,
      11.773,
      59.764,
      -15.652,
      9.323,
      58.238,
      -15.14,
      9.323
    ]
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10671",
    "polygon": [
      57.527,
      -14.902,
      9.323,
      57.527,
      -14.902,
      11.773,
      58.238,
      -15.14,
      11.773,
      58.238,
      -15.14,
      11.773,
      58.238,
      -15.14,
      9.323,
      57.527,
      -14.902,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10672",
    "polygon": [
      56.934,
      -14.704,
      9.323,
      56.934,
      -14.704,
      11.773,
      57.527,
      -14.902,
      11.773,
      57.527,
      -14.902,
      11.773,
      57.527,
      -14.902,
      9.323,
      56.934,
      -14.704,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10673",
    "polygon": [
      55.407,
      -14.192,
      9.323,
      55.407,
      -14.192,
      11.773,
      56.934,
      -14.704,
      11.773,
      56.934,
      -14.704,
      11.773,
      56.934,
      -14.704,
      9.323,
      55.407,
      -14.192,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_10674",
    "polygon": [
      54.696,
      -13.954,
      9.323,
      54.696,
      -13.954,
      11.773,
      55.407,
      -14.192,
      11.773,
      55.407,
      -14.192,
      11.773,
      55.407,
      -14.192,
      9.323,
      54.696,
      -13.954,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10675",
    "polygon": [
      75.576,
      -20.948,
      5.573,
      75.576,
      -20.948,
      8.023,
      77.102,
      -21.46,
      8.023,
      77.102,
      -21.46,
      8.023,
      77.102,
      -21.46,
      5.573,
      75.576,
      -20.948,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10676",
    "polygon": [
      74.865,
      -20.71,
      5.573,
      74.865,
      -20.71,
      8.023,
      75.576,
      -20.948,
      8.023,
      75.576,
      -20.948,
      8.023,
      75.576,
      -20.948,
      5.573,
      74.865,
      -20.71,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10677",
    "polygon": [
      74.272,
      -20.512,
      5.573,
      74.272,
      -20.512,
      8.023,
      74.865,
      -20.71,
      8.023,
      74.865,
      -20.71,
      8.023,
      74.865,
      -20.71,
      5.573,
      74.272,
      -20.512,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10678",
    "polygon": [
      72.745,
      -20.0,
      5.573,
      72.745,
      -20.0,
      8.023,
      74.272,
      -20.512,
      8.023,
      74.272,
      -20.512,
      8.023,
      74.272,
      -20.512,
      5.573,
      72.745,
      -20.0,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10679",
    "polygon": [
      72.034,
      -19.762,
      5.573,
      72.034,
      -19.762,
      8.023,
      72.745,
      -20.0,
      8.023,
      72.745,
      -20.0,
      8.023,
      72.745,
      -20.0,
      5.573,
      72.034,
      -19.762,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10680",
    "polygon": [
      69.171,
      -18.803,
      7.326,
      69.171,
      -18.803,
      8.023,
      71.323,
      -19.524,
      8.023,
      71.323,
      -19.524,
      8.023,
      71.323,
      -19.524,
      7.326,
      69.171,
      -18.803,
      7.326
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10683",
    "polygon": [
      66.966,
      -18.064,
      5.573,
      66.966,
      -18.064,
      8.023,
      68.493,
      -18.576,
      8.023,
      68.493,
      -18.576,
      8.023,
      68.493,
      -18.576,
      5.573,
      66.966,
      -18.064,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10684",
    "polygon": [
      66.255,
      -17.826,
      5.573,
      66.255,
      -17.826,
      8.023,
      66.966,
      -18.064,
      8.023,
      66.966,
      -18.064,
      8.023,
      66.966,
      -18.064,
      5.573,
      66.255,
      -17.826,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10685",
    "polygon": [
      64.017,
      -17.076,
      5.573,
      64.017,
      -17.076,
      8.023,
      65.544,
      -17.588,
      8.023,
      65.544,
      -17.588,
      8.023,
      65.544,
      -17.588,
      5.573,
      64.017,
      -17.076,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10686",
    "polygon": [
      63.306,
      -16.838,
      5.573,
      63.306,
      -16.838,
      8.023,
      64.017,
      -17.076,
      8.023,
      64.017,
      -17.076,
      8.023,
      64.017,
      -17.076,
      5.573,
      63.306,
      -16.838,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10687",
    "polygon": [
      62.713,
      -16.64,
      5.573,
      62.713,
      -16.64,
      8.023,
      63.306,
      -16.838,
      8.023,
      63.306,
      -16.838,
      8.023,
      63.306,
      -16.838,
      5.573,
      62.713,
      -16.64,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10688",
    "polygon": [
      61.187,
      -16.128,
      5.573,
      61.187,
      -16.128,
      8.023,
      62.713,
      -16.64,
      8.023,
      62.713,
      -16.64,
      8.023,
      62.713,
      -16.64,
      5.573,
      61.187,
      -16.128,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10689",
    "polygon": [
      60.476,
      -15.89,
      5.573,
      60.476,
      -15.89,
      8.023,
      61.187,
      -16.128,
      8.023,
      61.187,
      -16.128,
      8.023,
      61.187,
      -16.128,
      5.573,
      60.476,
      -15.89,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10690",
    "polygon": [
      58.238,
      -15.14,
      5.573,
      58.238,
      -15.14,
      8.023,
      59.764,
      -15.652,
      8.023,
      59.764,
      -15.652,
      8.023,
      59.764,
      -15.652,
      5.573,
      58.238,
      -15.14,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10691",
    "polygon": [
      57.527,
      -14.902,
      5.573,
      57.527,
      -14.902,
      8.023,
      58.238,
      -15.14,
      8.023,
      58.238,
      -15.14,
      8.023,
      58.238,
      -15.14,
      5.573,
      57.527,
      -14.902,
      5.573
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10692",
    "polygon": [
      56.934,
      -14.704,
      5.573,
      56.934,
      -14.704,
      8.023,
      57.527,
      -14.902,
      8.023,
      57.527,
      -14.902,
      8.023,
      57.527,
      -14.902,
      5.573,
      56.934,
      -14.704,
      5.573
    ],
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10693",
    "polygon": [
      55.407,
      -14.192,
      5.573,
      55.407,
      -14.192,
      8.023,
      56.934,
      -14.704,
      8.023,
      56.934,
      -14.704,
      8.023,
      56.934,
      -14.704,
      5.573,
      55.407,
      -14.192,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10694",
    "polygon": [
      54.696,
      -13.954,
      5.573,
      54.696,
      -13.954,
      8.023,
      55.407,
      -14.192,
      8.023,
      55.407,
      -14.192,
      8.023,
      55.407,
      -14.192,
      5.573,
      54.696,
      -13.954,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10695",
    "polygon": [
      70.247,
      -19.163,
      5.01,
      70.247,
      -19.163,
      7.326,
      71.323,
      -19.524,
      7.326,
      71.323,
      -19.524,
      7.326,
      71.323,
      -19.524,
      5.01,
      70.247,
      -19.163,
      5.01
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_10696",
    "polygon": [
      69.171,
      -18.803,
      5.01,
      69.171,
      -18.803,
      7.326,
      70.247,
      -19.163,
      7.326,
      70.247,
      -19.163,
      7.326,
      70.247,
      -19.163,
      5.01,
      69.171,
      -18.803,
      5.01
    ]
  },
  {
    "ID": "Magistrale_3.OG_10793",
    "polygon": [
      -42.683,
      53.313,
      12.55,
      -42.683,
      53.313,
      14.65,
      -44.537,
      53.934,
      14.65,
      -44.537,
      53.934,
      14.65,
      -44.537,
      53.934,
      12.55,
      -42.683,
      53.313,
      12.55
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10795",
    "polygon": [
      -38.975,
      52.071,
      12.55,
      -38.975,
      52.071,
      14.65,
      -40.829,
      52.692,
      14.65,
      -40.829,
      52.692,
      14.65,
      -40.829,
      52.692,
      12.55,
      -38.975,
      52.071,
      12.55
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_3.OG_10796",
    "polygon": [
      -37.121,
      51.45,
      12.55,
      -37.121,
      51.45,
      14.65,
      -38.975,
      52.071,
      14.65,
      -38.975,
      52.071,
      14.65,
      -38.975,
      52.071,
      12.55,
      -37.121,
      51.45,
      12.55
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Magistrale_3.OG_10798",
    "polygon": [
      -33.414,
      50.208,
      12.55,
      -33.414,
      50.208,
      14.65,
      -35.268,
      50.829,
      14.65,
      -35.268,
      50.829,
      14.65,
      -35.268,
      50.829,
      12.55,
      -33.414,
      50.208,
      12.55
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10799",
    "polygon": [
      -33.414,
      50.208,
      8.8,
      -33.414,
      50.208,
      10.9,
      -35.268,
      50.829,
      10.9,
      -35.268,
      50.829,
      10.9,
      -35.268,
      50.829,
      8.8,
      -33.414,
      50.208,
      8.8
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10801",
    "polygon": [
      -33.414,
      50.208,
      10.9,
      -33.414,
      50.208,
      12.55,
      -35.268,
      50.829,
      12.55,
      -35.268,
      50.829,
      12.55,
      -35.268,
      50.829,
      10.9,
      -33.414,
      50.208,
      10.9
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10804",
    "polygon": [
      -35.268,
      50.829,
      8.8,
      -35.268,
      50.829,
      10.9,
      -37.121,
      51.45,
      10.9,
      -37.121,
      51.45,
      10.9,
      -37.121,
      51.45,
      8.8,
      -35.268,
      50.829,
      8.8
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10805",
    "polygon": [
      -35.268,
      50.829,
      10.9,
      -35.268,
      50.829,
      12.55,
      -37.121,
      51.45,
      12.55,
      -37.121,
      51.45,
      12.55,
      -37.121,
      51.45,
      10.9,
      -35.268,
      50.829,
      10.9
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Magistrale_2.OG_10806",
    "polygon": [
      -37.121,
      51.45,
      8.8,
      -37.121,
      51.45,
      10.9,
      -38.975,
      52.071,
      10.9,
      -38.975,
      52.071,
      10.9,
      -38.975,
      52.071,
      8.8,
      -37.121,
      51.45,
      8.8
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10807",
    "polygon": [
      -37.121,
      51.45,
      10.9,
      -37.121,
      51.45,
      12.55,
      -38.975,
      52.071,
      12.55,
      -38.975,
      52.071,
      12.55,
      -38.975,
      52.071,
      10.9,
      -37.121,
      51.45,
      10.9
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10808",
    "polygon": [
      -38.975,
      52.071,
      8.8,
      -38.975,
      52.071,
      10.9,
      -40.829,
      52.692,
      10.9,
      -40.829,
      52.692,
      10.9,
      -40.829,
      52.692,
      8.8,
      -38.975,
      52.071,
      8.8
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10809",
    "polygon": [
      -38.975,
      52.071,
      10.9,
      -38.975,
      52.071,
      12.55,
      -40.829,
      52.692,
      12.55,
      -40.829,
      52.692,
      12.55,
      -40.829,
      52.692,
      10.9,
      -38.975,
      52.071,
      10.9
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10810",
    "polygon": [
      -40.829,
      52.692,
      8.8,
      -40.829,
      52.692,
      10.9,
      -42.683,
      53.313,
      10.9,
      -42.683,
      53.313,
      10.9,
      -42.683,
      53.313,
      8.8,
      -40.829,
      52.692,
      8.8
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10811",
    "polygon": [
      -40.829,
      52.692,
      10.9,
      -40.829,
      52.692,
      12.55,
      -42.683,
      53.313,
      12.55,
      -42.683,
      53.313,
      12.55,
      -42.683,
      53.313,
      10.9,
      -40.829,
      52.692,
      10.9
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10812",
    "polygon": [
      -42.683,
      53.313,
      8.8,
      -42.683,
      53.313,
      10.9,
      -44.536,
      53.934,
      10.9,
      -44.536,
      53.934,
      10.9,
      -44.536,
      53.934,
      8.8,
      -42.683,
      53.313,
      8.8
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10813",
    "polygon": [
      -42.683,
      53.313,
      10.9,
      -42.683,
      53.313,
      12.55,
      -44.536,
      53.934,
      12.55,
      -44.536,
      53.934,
      12.55,
      -44.536,
      53.934,
      10.9,
      -42.683,
      53.313,
      10.9
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Magistrale_1.OG_10814",
    "polygon": [
      -33.414,
      50.207,
      5.206,
      -33.414,
      50.207,
      7.306,
      -35.268,
      50.828,
      7.306,
      -35.268,
      50.828,
      7.306,
      -35.268,
      50.828,
      5.206,
      -33.414,
      50.207,
      5.206
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10824",
    "polygon": [
      -35.268,
      50.829,
      12.55,
      -35.268,
      50.829,
      14.65,
      -37.121,
      51.45,
      14.65,
      -37.121,
      51.45,
      14.65,
      -37.121,
      51.45,
      12.55,
      -35.268,
      50.829,
      12.55
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Magistrale_3.OG_10825",
    "polygon": [
      -40.829,
      52.692,
      12.55,
      -40.829,
      52.692,
      14.65,
      -42.683,
      53.313,
      14.65,
      -42.683,
      53.313,
      14.65,
      -42.683,
      53.313,
      12.55,
      -40.829,
      52.692,
      12.55
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Magistrale_1.OG_10828",
    "polygon": [
      -35.268,
      50.828,
      5.206,
      -35.268,
      50.828,
      7.306,
      -37.122,
      51.449,
      7.306,
      -37.122,
      51.449,
      7.306,
      -37.122,
      51.449,
      5.206,
      -35.268,
      50.828,
      5.206
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Magistrale_1.OG_10829",
    "polygon": [
      -35.268,
      50.828,
      7.306,
      -35.268,
      50.828,
      8.8,
      -37.122,
      51.449,
      8.8,
      -37.122,
      51.449,
      8.8,
      -37.122,
      51.449,
      7.306,
      -35.268,
      50.828,
      7.306
    ],
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Magistrale_1.OG_10830",
    "polygon": [
      -37.122,
      51.449,
      5.206,
      -37.122,
      51.449,
      7.306,
      -38.975,
      52.07,
      7.306,
      -38.975,
      52.07,
      7.306,
      -38.975,
      52.07,
      5.206,
      -37.122,
      51.449,
      5.206
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Magistrale_1.OG_10832",
    "polygon": [
      -38.975,
      52.07,
      5.206,
      -38.975,
      52.07,
      7.306,
      -40.829,
      52.691,
      7.306,
      -40.829,
      52.691,
      7.306,
      -40.829,
      52.691,
      5.206,
      -38.975,
      52.07,
      5.206
    ],
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Magistrale_1.OG_10834",
    "polygon": [
      -40.829,
      52.691,
      5.206,
      -40.829,
      52.691,
      7.306,
      -42.683,
      53.312,
      7.306,
      -42.683,
      53.312,
      7.306,
      -42.683,
      53.312,
      5.206,
      -40.829,
      52.691,
      5.206
    ],
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1
  },
  {
    "ID": "Magistrale_1.OG_10836",
    "polygon": [
      -42.683,
      53.312,
      5.206,
      -42.683,
      53.312,
      7.306,
      -44.537,
      53.933,
      7.306,
      -44.537,
      53.933,
      7.306,
      -44.537,
      53.933,
      5.206,
      -42.683,
      53.312,
      5.206
    ],
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "Magistrale_1.OG_10839",
    "polygon": [
      -33.414,
      50.207,
      7.306,
      -33.414,
      50.207,
      8.8,
      -35.268,
      50.828,
      8.8,
      -35.268,
      50.828,
      8.8,
      -35.268,
      50.828,
      7.306,
      -33.414,
      50.207,
      7.306
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_1.OG_10840",
    "polygon": [
      -37.122,
      51.449,
      7.306,
      -37.122,
      51.449,
      8.8,
      -38.975,
      52.07,
      8.8,
      -38.975,
      52.07,
      8.8,
      -38.975,
      52.07,
      7.306,
      -37.122,
      51.449,
      7.306
    ],
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Magistrale_1.OG_10841",
    "polygon": [
      -38.975,
      52.07,
      7.306,
      -38.975,
      52.07,
      8.8,
      -40.829,
      52.691,
      8.8,
      -40.829,
      52.691,
      8.8,
      -40.829,
      52.691,
      7.306,
      -38.975,
      52.07,
      7.306
    ],
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Magistrale_1.OG_10843",
    "polygon": [
      -42.683,
      53.312,
      7.306,
      -42.683,
      53.312,
      8.8,
      -44.537,
      53.933,
      8.8,
      -44.537,
      53.933,
      8.8,
      -44.537,
      53.933,
      7.306,
      -42.683,
      53.312,
      7.306
    ],
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Magistrale_EG_10844",
    "polygon": [
      -33.486,
      49.994,
      1.0,
      -33.486,
      49.994,
      3.1,
      -35.339,
      50.614,
      3.1,
      -35.339,
      50.614,
      3.1,
      -35.339,
      50.614,
      1.0,
      -33.486,
      49.994,
      1.0
    ],
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Magistrale_EG_10849",
    "polygon": [
      -33.486,
      49.994,
      3.1,
      -33.486,
      49.994,
      5.2,
      -35.339,
      50.614,
      5.2,
      -35.339,
      50.614,
      5.2,
      -35.339,
      50.614,
      3.1,
      -33.486,
      49.994,
      3.1
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Magistrale_EG_10850",
    "polygon": [
      -33.486,
      49.994,
      0.3,
      -33.486,
      49.994,
      1.0,
      -35.339,
      50.614,
      1.0,
      -35.339,
      50.614,
      1.0,
      -35.339,
      50.614,
      0.3,
      -33.486,
      49.994,
      0.3
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Magistrale_EG_10851",
    "polygon": [
      -35.339,
      50.614,
      1.0,
      -35.339,
      50.614,
      3.1,
      -37.193,
      51.235,
      3.1,
      -37.193,
      51.235,
      3.1,
      -37.193,
      51.235,
      1.0,
      -35.339,
      50.614,
      1.0
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Magistrale_EG_10852",
    "polygon": [
      -35.339,
      50.614,
      3.1,
      -35.339,
      50.614,
      5.2,
      -37.193,
      51.235,
      5.2,
      -37.193,
      51.235,
      5.2,
      -37.193,
      51.235,
      3.1,
      -35.339,
      50.614,
      3.1
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Magistrale_EG_10853",
    "polygon": [
      -37.193,
      51.235,
      1.0,
      -37.193,
      51.235,
      3.1,
      -39.047,
      51.856,
      3.1,
      -39.047,
      51.856,
      3.1,
      -39.047,
      51.856,
      1.0,
      -37.193,
      51.235,
      1.0
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Magistrale_EG_10854",
    "polygon": [
      -37.193,
      51.235,
      3.1,
      -37.193,
      51.235,
      5.2,
      -39.047,
      51.856,
      5.2,
      -39.047,
      51.856,
      5.2,
      -39.047,
      51.856,
      3.1,
      -37.193,
      51.235,
      3.1
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Magistrale_EG_10855",
    "polygon": [
      -39.047,
      51.856,
      1.0,
      -39.047,
      51.856,
      3.1,
      -40.901,
      52.477,
      3.1,
      -40.901,
      52.477,
      3.1,
      -40.901,
      52.477,
      1.0,
      -39.047,
      51.856,
      1.0
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1
  },
  {
    "ID": "Magistrale_EG_10856",
    "polygon": [
      -39.047,
      51.856,
      3.1,
      -39.047,
      51.856,
      5.2,
      -40.901,
      52.477,
      5.2,
      -40.901,
      52.477,
      5.2,
      -40.901,
      52.477,
      3.1,
      -39.047,
      51.856,
      3.1
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Magistrale_EG_10857",
    "polygon": [
      -40.901,
      52.477,
      1.0,
      -40.901,
      52.477,
      3.1,
      -42.755,
      53.098,
      3.1,
      -42.755,
      53.098,
      3.1,
      -42.755,
      53.098,
      1.0,
      -40.901,
      52.477,
      1.0
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Magistrale_EG_10858",
    "polygon": [
      -40.901,
      52.477,
      3.1,
      -40.901,
      52.477,
      5.2,
      -42.755,
      53.098,
      5.2,
      -42.755,
      53.098,
      5.2,
      -42.755,
      53.098,
      3.1,
      -40.901,
      52.477,
      3.1
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Magistrale_EG_10859",
    "polygon": [
      -42.755,
      53.098,
      1.0,
      -42.755,
      53.098,
      3.1,
      -44.608,
      53.719,
      3.1,
      -44.608,
      53.719,
      3.1,
      -44.608,
      53.719,
      1.0,
      -42.755,
      53.098,
      1.0
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Magistrale_EG_10860",
    "polygon": [
      -42.755,
      53.098,
      3.1,
      -42.755,
      53.098,
      5.2,
      -44.608,
      53.719,
      5.2,
      -44.608,
      53.719,
      5.2,
      -44.608,
      53.719,
      3.1,
      -42.755,
      53.098,
      3.1
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Magistrale_1.OG_10866",
    "polygon": [
      -40.829,
      52.691,
      7.306,
      -40.829,
      52.691,
      8.8,
      -42.683,
      53.312,
      8.8,
      -42.683,
      53.312,
      8.8,
      -42.683,
      53.312,
      7.306,
      -40.829,
      52.691,
      7.306
    ],
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Magistrale_EG_10867",
    "polygon": [
      -26.119,
      47.526,
      1.0,
      -26.119,
      47.526,
      3.1,
      -27.973,
      48.147,
      3.1,
      -27.973,
      48.147,
      3.1,
      -27.973,
      48.147,
      1.0,
      -26.119,
      47.526,
      1.0
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_EG_10868",
    "polygon": [
      -26.119,
      47.526,
      3.1,
      -26.119,
      47.526,
      5.2,
      -27.973,
      48.147,
      5.2,
      -27.973,
      48.147,
      5.2,
      -27.973,
      48.147,
      3.1,
      -26.119,
      47.526,
      3.1
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_EG_10869",
    "polygon": [
      -26.119,
      47.526,
      0.3,
      -26.119,
      47.526,
      1.0,
      -27.973,
      48.147,
      1.0,
      -27.973,
      48.147,
      1.0,
      -27.973,
      48.147,
      0.3,
      -26.119,
      47.526,
      0.3
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Magistrale_EG_10870",
    "polygon": [
      -24.265,
      46.905,
      1.0,
      -24.265,
      46.905,
      3.1,
      -26.119,
      47.526,
      3.1,
      -26.119,
      47.526,
      3.1,
      -26.119,
      47.526,
      1.0,
      -24.265,
      46.905,
      1.0
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_EG_10871",
    "polygon": [
      -24.265,
      46.905,
      3.1,
      -24.265,
      46.905,
      5.2,
      -26.119,
      47.526,
      5.2,
      -26.119,
      47.526,
      5.2,
      -26.119,
      47.526,
      3.1,
      -24.265,
      46.905,
      3.1
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Magistrale_EG_10872",
    "polygon": [
      -24.265,
      46.905,
      0.3,
      -24.265,
      46.905,
      1.0,
      -26.119,
      47.526,
      1.0,
      -26.119,
      47.526,
      1.0,
      -26.119,
      47.526,
      0.3,
      -24.265,
      46.905,
      0.3
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_EG_10873",
    "polygon": [
      -22.412,
      46.284,
      1.0,
      -22.412,
      46.284,
      3.1,
      -24.265,
      46.905,
      3.1,
      -24.265,
      46.905,
      3.1,
      -24.265,
      46.905,
      1.0,
      -22.412,
      46.284,
      1.0
    ],
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Magistrale_EG_10874",
    "polygon": [
      -22.412,
      46.284,
      3.1,
      -22.412,
      46.284,
      5.2,
      -24.265,
      46.905,
      5.2,
      -24.265,
      46.905,
      5.2,
      -24.265,
      46.905,
      3.1,
      -22.412,
      46.284,
      3.1
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Magistrale_EG_10875",
    "polygon": [
      -22.412,
      46.284,
      0.3,
      -22.412,
      46.284,
      1.0,
      -24.265,
      46.905,
      1.0,
      -24.265,
      46.905,
      1.0,
      -24.265,
      46.905,
      0.3,
      -22.412,
      46.284,
      0.3
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_3.OG_10876",
    "polygon": [
      -26.047,
      47.74,
      12.55,
      -26.047,
      47.74,
      14.65,
      -27.901,
      48.361,
      14.65,
      -27.901,
      48.361,
      14.65,
      -27.901,
      48.361,
      12.55,
      -26.047,
      47.74,
      12.55
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10877",
    "polygon": [
      -26.047,
      47.74,
      8.8,
      -26.047,
      47.74,
      10.9,
      -27.901,
      48.361,
      10.9,
      -27.901,
      48.361,
      10.9,
      -27.901,
      48.361,
      8.8,
      -26.047,
      47.74,
      8.8
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10878",
    "polygon": [
      -26.047,
      47.74,
      10.9,
      -26.047,
      47.74,
      12.55,
      -27.901,
      48.361,
      12.55,
      -27.901,
      48.361,
      12.55,
      -27.901,
      48.361,
      10.9,
      -26.047,
      47.74,
      10.9
    ],
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_1.OG_10879",
    "polygon": [
      -26.048,
      47.739,
      5.206,
      -26.048,
      47.739,
      7.306,
      -27.901,
      48.36,
      7.306,
      -27.901,
      48.36,
      7.306,
      -27.901,
      48.36,
      5.206,
      -26.048,
      47.739,
      5.206
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_1.OG_10880",
    "polygon": [
      -26.048,
      47.739,
      7.306,
      -26.048,
      47.739,
      8.8,
      -27.901,
      48.36,
      8.8,
      -27.901,
      48.36,
      8.8,
      -27.901,
      48.36,
      7.306,
      -26.048,
      47.739,
      7.306
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_3.OG_10881",
    "polygon": [
      -24.194,
      47.119,
      12.55,
      -24.194,
      47.119,
      14.65,
      -26.047,
      47.74,
      14.65,
      -26.047,
      47.74,
      14.65,
      -26.047,
      47.74,
      12.55,
      -24.194,
      47.119,
      12.55
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10882",
    "polygon": [
      -24.194,
      47.119,
      8.8,
      -24.194,
      47.119,
      10.9,
      -26.047,
      47.74,
      10.9,
      -26.047,
      47.74,
      10.9,
      -26.047,
      47.74,
      8.8,
      -24.194,
      47.119,
      8.8
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10883",
    "polygon": [
      -24.194,
      47.119,
      10.9,
      -24.194,
      47.119,
      12.55,
      -26.047,
      47.74,
      12.55,
      -26.047,
      47.74,
      12.55,
      -26.047,
      47.74,
      10.9,
      -24.194,
      47.119,
      10.9
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Magistrale_1.OG_10884",
    "polygon": [
      -24.194,
      47.118,
      5.206,
      -24.194,
      47.118,
      7.306,
      -26.048,
      47.739,
      7.306,
      -26.048,
      47.739,
      7.306,
      -26.048,
      47.739,
      5.206,
      -24.194,
      47.118,
      5.206
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Magistrale_1.OG_10885",
    "polygon": [
      -24.194,
      47.118,
      7.306,
      -24.194,
      47.118,
      8.8,
      -26.048,
      47.739,
      8.8,
      -26.048,
      47.739,
      8.8,
      -26.048,
      47.739,
      7.306,
      -24.194,
      47.118,
      7.306
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Magistrale_3.OG_10886",
    "polygon": [
      -22.34,
      46.498,
      12.55,
      -22.34,
      46.498,
      14.65,
      -24.194,
      47.119,
      14.65,
      -24.194,
      47.119,
      14.65,
      -24.194,
      47.119,
      12.55,
      -22.34,
      46.498,
      12.55
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10887",
    "polygon": [
      -22.34,
      46.498,
      8.8,
      -22.34,
      46.498,
      10.9,
      -24.194,
      47.119,
      10.9,
      -24.194,
      47.119,
      10.9,
      -24.194,
      47.119,
      8.8,
      -22.34,
      46.498,
      8.8
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Magistrale_2.OG_10888",
    "polygon": [
      -22.34,
      46.498,
      10.9,
      -22.34,
      46.498,
      12.55,
      -24.194,
      47.119,
      12.55,
      -24.194,
      47.119,
      12.55,
      -24.194,
      47.119,
      10.9,
      -22.34,
      46.498,
      10.9
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Magistrale_1.OG_10889",
    "polygon": [
      -22.34,
      46.497,
      5.206,
      -22.34,
      46.497,
      7.306,
      -24.194,
      47.118,
      7.306,
      -24.194,
      47.118,
      7.306,
      -24.194,
      47.118,
      5.206,
      -22.34,
      46.497,
      5.206
    ],
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Magistrale_1.OG_10890",
    "polygon": [
      -22.34,
      46.497,
      7.306,
      -22.34,
      46.497,
      8.8,
      -24.194,
      47.118,
      8.8,
      -24.194,
      47.118,
      8.8,
      -24.194,
      47.118,
      7.306,
      -22.34,
      46.497,
      7.306
    ],
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Magistrale_3.OG_10893",
    "polygon": [
      30.527,
      28.612,
      12.7,
      30.527,
      28.612,
      14.8,
      28.915,
      29.152,
      14.8,
      28.915,
      29.152,
      14.8,
      28.915,
      29.152,
      12.7,
      30.527,
      28.612,
      12.7
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Magistrale_3.OG_10895",
    "polygon": [
      30.527,
      28.612,
      14.8,
      30.527,
      28.612,
      15.5,
      28.915,
      29.152,
      15.5,
      28.915,
      29.152,
      15.5,
      28.915,
      29.152,
      14.8,
      30.527,
      28.612,
      14.8
    ]
  },
  {
    "ID": "Magistrale_3.OG_10897",
    "polygon": [
      32.139,
      28.072,
      14.8,
      32.139,
      28.072,
      15.5,
      30.527,
      28.612,
      15.5,
      30.527,
      28.612,
      15.5,
      30.527,
      28.612,
      14.8,
      32.139,
      28.072,
      14.8
    ]
  },
  {
    "ID": "Magistrale_3.OG_10899",
    "polygon": [
      32.133,
      28.074,
      12.7,
      32.133,
      28.074,
      14.8,
      30.521,
      28.614,
      14.8,
      30.521,
      28.614,
      14.8,
      30.521,
      28.614,
      12.7,
      32.133,
      28.074,
      12.7
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10900",
    "polygon": [
      33.751,
      27.533,
      14.8,
      33.751,
      27.533,
      15.5,
      32.139,
      28.072,
      15.5,
      32.139,
      28.072,
      15.5,
      32.139,
      28.072,
      14.8,
      33.751,
      27.533,
      14.8
    ]
  },
  {
    "ID": "Magistrale_3.OG_10901",
    "polygon": [
      33.745,
      27.535,
      12.7,
      33.745,
      27.535,
      14.8,
      32.133,
      28.074,
      14.8,
      32.133,
      28.074,
      14.8,
      32.133,
      28.074,
      12.7,
      33.745,
      27.535,
      12.7
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10904",
    "polygon": [
      33.745,
      27.535,
      8.851,
      33.745,
      27.535,
      10.951,
      32.133,
      28.074,
      10.951,
      32.133,
      28.074,
      10.951,
      32.133,
      28.074,
      8.851,
      33.745,
      27.535,
      8.851
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Magistrale_2.OG_10906",
    "polygon": [
      33.745,
      27.535,
      10.951,
      33.745,
      27.535,
      12.7,
      32.133,
      28.074,
      12.7,
      32.133,
      28.074,
      12.7,
      32.133,
      28.074,
      10.951,
      33.745,
      27.535,
      10.951
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10907",
    "polygon": [
      32.133,
      28.074,
      8.851,
      32.133,
      28.074,
      10.951,
      30.521,
      28.614,
      10.951,
      30.521,
      28.614,
      10.951,
      30.521,
      28.614,
      8.851,
      32.133,
      28.074,
      8.851
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10908",
    "polygon": [
      32.133,
      28.074,
      10.951,
      32.133,
      28.074,
      12.7,
      30.521,
      28.614,
      12.7,
      30.521,
      28.614,
      12.7,
      30.521,
      28.614,
      10.951,
      32.133,
      28.074,
      10.951
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10909",
    "polygon": [
      30.521,
      28.614,
      8.851,
      30.521,
      28.614,
      10.951,
      28.909,
      29.154,
      10.951,
      28.909,
      29.154,
      10.951,
      28.909,
      29.154,
      8.851,
      30.521,
      28.614,
      8.851
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10910",
    "polygon": [
      30.521,
      28.614,
      10.951,
      30.521,
      28.614,
      12.7,
      28.909,
      29.154,
      12.7,
      28.909,
      29.154,
      12.7,
      28.909,
      29.154,
      10.951,
      30.521,
      28.614,
      10.951
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Magistrale_1.OG_10918",
    "polygon": [
      33.82,
      27.735,
      5.0,
      33.82,
      27.735,
      7.1,
      32.21,
      28.281,
      7.1,
      32.21,
      28.281,
      7.1,
      32.21,
      28.281,
      5.0,
      33.82,
      27.735,
      5.0
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Magistrale_1.OG_10922",
    "polygon": [
      33.816,
      27.736,
      7.1,
      33.816,
      27.736,
      8.851,
      32.206,
      28.282,
      8.851,
      32.206,
      28.282,
      8.851,
      32.206,
      28.282,
      7.1,
      33.816,
      27.736,
      7.1
    ]
  },
  {
    "ID": "Magistrale_1.OG_10923",
    "polygon": [
      32.21,
      28.281,
      5.0,
      32.21,
      28.281,
      7.1,
      30.6,
      28.827,
      7.1,
      30.6,
      28.827,
      7.1,
      30.6,
      28.827,
      5.0,
      32.21,
      28.281,
      5.0
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Magistrale_1.OG_10924",
    "polygon": [
      32.206,
      28.282,
      7.1,
      32.206,
      28.282,
      8.851,
      30.596,
      28.828,
      8.851,
      30.596,
      28.828,
      8.851,
      30.596,
      28.828,
      7.1,
      32.206,
      28.282,
      7.1
    ]
  },
  {
    "ID": "Magistrale_1.OG_10925",
    "polygon": [
      30.6,
      28.827,
      5.0,
      30.6,
      28.827,
      7.1,
      28.99,
      29.373,
      7.1,
      28.99,
      29.373,
      7.1,
      28.99,
      29.373,
      5.0,
      30.6,
      28.827,
      5.0
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Magistrale_1.OG_10926",
    "polygon": [
      30.596,
      28.828,
      7.1,
      30.596,
      28.828,
      8.851,
      28.986,
      29.374,
      8.851,
      28.986,
      29.374,
      8.851,
      28.986,
      29.374,
      7.1,
      30.596,
      28.828,
      7.1
    ]
  },
  {
    "ID": "Magistrale_EG_10929",
    "polygon": [
      32.21,
      28.281,
      0.412,
      32.21,
      28.281,
      2.512,
      30.6,
      28.827,
      2.512,
      30.6,
      28.827,
      2.512,
      30.6,
      28.827,
      0.412,
      32.21,
      28.281,
      0.412
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "Magistrale_EG_10930",
    "polygon": [
      32.223,
      28.276,
      2.512,
      32.223,
      28.276,
      4.263,
      30.613,
      28.822,
      4.263,
      30.613,
      28.822,
      4.263,
      30.613,
      28.822,
      2.512,
      32.223,
      28.276,
      2.512
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Magistrale_EG_10931",
    "polygon": [
      32.223,
      28.276,
      4.262,
      32.223,
      28.276,
      4.962,
      30.613,
      28.822,
      4.962,
      30.613,
      28.822,
      4.962,
      30.613,
      28.822,
      4.262,
      32.223,
      28.276,
      4.262
    ]
  },
  {
    "ID": "Magistrale_EG_10932",
    "polygon": [
      33.826,
      27.732,
      3.556,
      33.826,
      27.732,
      4.956,
      32.216,
      28.278,
      4.956,
      32.216,
      28.278,
      4.956,
      32.216,
      28.278,
      3.556,
      33.826,
      27.732,
      3.556
    ]
  },
  {
    "ID": "Magistrale_EG_10933",
    "polygon": [
      30.627,
      28.817,
      0.412,
      30.627,
      28.817,
      2.512,
      29.017,
      29.364,
      2.512,
      29.017,
      29.364,
      2.512,
      29.017,
      29.364,
      0.412,
      30.627,
      28.817,
      0.412
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Magistrale_EG_10934",
    "polygon": [
      30.627,
      28.817,
      2.512,
      30.627,
      28.817,
      4.263,
      29.017,
      29.364,
      4.263,
      29.017,
      29.364,
      4.263,
      29.017,
      29.364,
      2.512,
      30.627,
      28.817,
      2.512
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Magistrale_3.OG_10935",
    "polygon": [
      41.86,
      24.818,
      14.8,
      41.86,
      24.818,
      15.5,
      40.248,
      25.358,
      15.5,
      40.248,
      25.358,
      15.5,
      40.248,
      25.358,
      14.8,
      41.86,
      24.818,
      14.8
    ]
  },
  {
    "ID": "Magistrale_3.OG_10936",
    "polygon": [
      41.86,
      24.818,
      12.7,
      41.86,
      24.818,
      14.8,
      40.248,
      25.358,
      14.8,
      40.248,
      25.358,
      14.8,
      40.248,
      25.358,
      12.7,
      41.86,
      24.818,
      12.7
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_3.OG_10937",
    "polygon": [
      43.472,
      24.278,
      14.8,
      43.472,
      24.278,
      15.5,
      41.86,
      24.818,
      15.5,
      41.86,
      24.818,
      15.5,
      41.86,
      24.818,
      14.8,
      43.472,
      24.278,
      14.8
    ]
  },
  {
    "ID": "Magistrale_3.OG_10938",
    "polygon": [
      43.472,
      24.278,
      12.7,
      43.472,
      24.278,
      14.8,
      41.86,
      24.818,
      14.8,
      41.86,
      24.818,
      14.8,
      41.86,
      24.818,
      12.7,
      43.472,
      24.278,
      12.7
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10939",
    "polygon": [
      45.084,
      23.739,
      14.8,
      45.084,
      23.739,
      15.5,
      43.472,
      24.278,
      15.5,
      43.472,
      24.278,
      15.5,
      43.472,
      24.278,
      14.8,
      45.084,
      23.739,
      14.8
    ]
  },
  {
    "ID": "Magistrale_3.OG_10940",
    "polygon": [
      45.084,
      23.739,
      12.7,
      45.084,
      23.739,
      14.8,
      43.472,
      24.278,
      14.8,
      43.472,
      24.278,
      14.8,
      43.472,
      24.278,
      12.7,
      45.084,
      23.739,
      12.7
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Magistrale_3.OG_10941",
    "polygon": [
      46.696,
      23.199,
      14.8,
      46.696,
      23.199,
      15.5,
      45.084,
      23.739,
      15.5,
      45.084,
      23.739,
      15.5,
      45.084,
      23.739,
      14.8,
      46.696,
      23.199,
      14.8
    ]
  },
  {
    "ID": "Magistrale_3.OG_10942",
    "polygon": [
      46.696,
      23.199,
      12.7,
      46.696,
      23.199,
      14.8,
      45.084,
      23.739,
      14.8,
      45.084,
      23.739,
      14.8,
      45.084,
      23.739,
      12.7,
      46.696,
      23.199,
      12.7
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Magistrale_3.OG_10943",
    "polygon": [
      48.308,
      22.659,
      14.8,
      48.308,
      22.659,
      15.5,
      46.696,
      23.199,
      15.5,
      46.696,
      23.199,
      15.5,
      46.696,
      23.199,
      14.8,
      48.308,
      22.659,
      14.8
    ]
  },
  {
    "ID": "Magistrale_3.OG_10944",
    "polygon": [
      48.306,
      22.66,
      12.7,
      48.306,
      22.66,
      14.8,
      46.694,
      23.2,
      14.8,
      46.694,
      23.2,
      14.8,
      46.694,
      23.2,
      12.7,
      48.306,
      22.66,
      12.7
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Magistrale_3.OG_10945",
    "polygon": [
      49.917,
      22.121,
      14.8,
      49.917,
      22.121,
      15.5,
      48.305,
      22.66,
      15.5,
      48.305,
      22.66,
      15.5,
      48.305,
      22.66,
      14.8,
      49.917,
      22.121,
      14.8
    ]
  },
  {
    "ID": "Magistrale_3.OG_10946",
    "polygon": [
      49.917,
      22.121,
      12.7,
      49.917,
      22.121,
      14.8,
      48.305,
      22.66,
      14.8,
      48.305,
      22.66,
      14.8,
      48.305,
      22.66,
      12.7,
      49.917,
      22.121,
      12.7
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10947",
    "polygon": [
      41.862,
      24.817,
      8.851,
      41.862,
      24.817,
      10.951,
      40.25,
      25.357,
      10.951,
      40.25,
      25.357,
      10.951,
      40.25,
      25.357,
      8.851,
      41.862,
      24.817,
      8.851
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10948",
    "polygon": [
      41.862,
      24.817,
      10.951,
      41.862,
      24.817,
      12.7,
      40.25,
      25.357,
      12.7,
      40.25,
      25.357,
      12.7,
      40.25,
      25.357,
      10.951,
      41.862,
      24.817,
      10.951
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10949",
    "polygon": [
      43.473,
      24.278,
      8.851,
      43.473,
      24.278,
      10.951,
      41.861,
      24.818,
      10.951,
      41.861,
      24.818,
      10.951,
      41.861,
      24.818,
      8.851,
      43.473,
      24.278,
      8.851
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10951",
    "polygon": [
      45.085,
      23.738,
      8.851,
      45.085,
      23.738,
      10.951,
      43.473,
      24.278,
      10.951,
      43.473,
      24.278,
      10.951,
      43.473,
      24.278,
      8.851,
      45.085,
      23.738,
      8.851
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10952",
    "polygon": [
      45.085,
      23.738,
      10.951,
      45.085,
      23.738,
      12.7,
      43.473,
      24.278,
      12.7,
      43.473,
      24.278,
      12.7,
      43.473,
      24.278,
      10.951,
      45.085,
      23.738,
      10.951
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Magistrale_2.OG_10954",
    "polygon": [
      46.696,
      23.199,
      8.851,
      46.696,
      23.199,
      10.951,
      45.084,
      23.739,
      10.951,
      45.084,
      23.739,
      10.951,
      45.084,
      23.739,
      8.851,
      46.696,
      23.199,
      8.851
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10955",
    "polygon": [
      46.697,
      23.199,
      10.951,
      46.697,
      23.199,
      12.7,
      45.085,
      23.738,
      12.7,
      45.085,
      23.738,
      12.7,
      45.085,
      23.738,
      10.951,
      46.697,
      23.199,
      10.951
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Magistrale_2.OG_10956",
    "polygon": [
      48.308,
      22.659,
      8.851,
      48.308,
      22.659,
      10.951,
      46.696,
      23.199,
      10.951,
      46.696,
      23.199,
      10.951,
      46.696,
      23.199,
      8.851,
      48.308,
      22.659,
      8.851
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10957",
    "polygon": [
      48.308,
      22.659,
      10.951,
      48.308,
      22.659,
      12.7,
      46.696,
      23.199,
      12.7,
      46.696,
      23.199,
      12.7,
      46.696,
      23.199,
      10.951,
      48.308,
      22.659,
      10.951
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10958",
    "polygon": [
      49.917,
      22.121,
      8.851,
      49.917,
      22.121,
      10.951,
      48.305,
      22.66,
      10.951,
      48.305,
      22.66,
      10.951,
      48.305,
      22.66,
      8.851,
      49.917,
      22.121,
      8.851
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Magistrale_2.OG_10959",
    "polygon": [
      49.917,
      22.121,
      10.951,
      49.917,
      22.121,
      12.7,
      48.305,
      22.66,
      12.7,
      48.305,
      22.66,
      12.7,
      48.305,
      22.66,
      10.951,
      49.917,
      22.121,
      10.951
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Magistrale_2.OG_10960",
    "polygon": [
      43.473,
      24.278,
      10.951,
      43.473,
      24.278,
      12.7,
      41.861,
      24.818,
      12.7,
      41.861,
      24.818,
      12.7,
      41.861,
      24.818,
      10.951,
      43.473,
      24.278,
      10.951
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Magistrale_1.OG_10961",
    "polygon": [
      41.928,
      24.984,
      5.0,
      41.928,
      24.984,
      7.1,
      40.318,
      25.53,
      7.1,
      40.318,
      25.53,
      7.1,
      40.318,
      25.53,
      5.0,
      41.928,
      24.984,
      5.0
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Magistrale_1.OG_10962",
    "polygon": [
      41.923,
      24.986,
      7.1,
      41.923,
      24.986,
      8.851,
      40.313,
      25.532,
      8.851,
      40.313,
      25.532,
      8.851,
      40.313,
      25.532,
      7.1,
      41.923,
      24.986,
      7.1
    ]
  },
  {
    "ID": "Magistrale_1.OG_10963",
    "polygon": [
      43.538,
      24.438,
      5.0,
      43.538,
      24.438,
      7.1,
      41.928,
      24.984,
      7.1,
      41.928,
      24.984,
      7.1,
      41.928,
      24.984,
      5.0,
      43.538,
      24.438,
      5.0
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_1.OG_10964",
    "polygon": [
      43.529,
      24.441,
      7.1,
      43.529,
      24.441,
      8.851,
      41.92,
      24.987,
      8.851,
      41.92,
      24.987,
      8.851,
      41.92,
      24.987,
      7.1,
      43.529,
      24.441,
      7.1
    ]
  },
  {
    "ID": "Magistrale_1.OG_10965",
    "polygon": [
      45.148,
      23.892,
      5.0,
      45.148,
      23.892,
      7.1,
      43.538,
      24.438,
      7.1,
      43.538,
      24.438,
      7.1,
      43.538,
      24.438,
      5.0,
      45.148,
      23.892,
      5.0
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Magistrale_1.OG_10966",
    "polygon": [
      45.138,
      23.895,
      7.1,
      45.138,
      23.895,
      8.851,
      43.528,
      24.441,
      8.851,
      43.528,
      24.441,
      8.851,
      43.528,
      24.441,
      7.1,
      45.138,
      23.895,
      7.1
    ]
  },
  {
    "ID": "Magistrale_1.OG_10967",
    "polygon": [
      46.749,
      23.349,
      5.007,
      46.749,
      23.349,
      7.107,
      45.139,
      23.895,
      7.107,
      45.139,
      23.895,
      7.107,
      45.139,
      23.895,
      5.007,
      46.749,
      23.349,
      5.007
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Magistrale_1.OG_10968",
    "polygon": [
      46.747,
      23.35,
      7.107,
      46.747,
      23.35,
      8.858,
      45.137,
      23.896,
      8.858,
      45.137,
      23.896,
      8.858,
      45.137,
      23.896,
      7.107,
      46.747,
      23.35,
      7.107
    ]
  },
  {
    "ID": "Magistrale_1.OG_10969",
    "polygon": [
      48.359,
      22.803,
      5.007,
      48.359,
      22.803,
      7.107,
      46.749,
      23.349,
      7.107,
      46.749,
      23.349,
      7.107,
      46.749,
      23.349,
      5.007,
      48.359,
      22.803,
      5.007
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Magistrale_1.OG_10970",
    "polygon": [
      48.355,
      22.804,
      7.107,
      48.355,
      22.804,
      8.858,
      46.745,
      23.35,
      8.858,
      46.745,
      23.35,
      8.858,
      46.745,
      23.35,
      7.107,
      48.355,
      22.804,
      7.107
    ]
  },
  {
    "ID": "Magistrale_1.OG_10971",
    "polygon": [
      49.967,
      22.257,
      5.007,
      49.967,
      22.257,
      7.107,
      48.357,
      22.803,
      7.107,
      48.357,
      22.803,
      7.107,
      48.357,
      22.803,
      5.007,
      49.967,
      22.257,
      5.007
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Magistrale_1.OG_10972",
    "polygon": [
      49.963,
      22.259,
      7.107,
      49.963,
      22.259,
      8.858,
      48.354,
      22.805,
      8.858,
      48.354,
      22.805,
      8.858,
      48.354,
      22.805,
      7.107,
      49.963,
      22.259,
      7.107
    ]
  },
  {
    "ID": "Magistrale_EG_10973",
    "polygon": [
      41.836,
      24.786,
      0.412,
      41.836,
      24.786,
      2.512,
      40.224,
      25.326,
      2.512,
      40.224,
      25.326,
      2.512,
      40.224,
      25.326,
      0.412,
      41.836,
      24.786,
      0.412
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Magistrale_EG_10974",
    "polygon": [
      41.861,
      24.777,
      2.512,
      41.861,
      24.777,
      4.263,
      40.249,
      25.317,
      4.263,
      40.249,
      25.317,
      4.263,
      40.249,
      25.317,
      2.512,
      41.861,
      24.777,
      2.512
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Magistrale_EG_10975",
    "polygon": [
      43.447,
      24.246,
      0.412,
      43.447,
      24.246,
      2.512,
      41.835,
      24.786,
      2.512,
      41.835,
      24.786,
      2.512,
      41.835,
      24.786,
      0.412,
      43.447,
      24.246,
      0.412
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Magistrale_EG_10977",
    "polygon": [
      45.059,
      23.706,
      0.412,
      45.059,
      23.706,
      2.512,
      43.447,
      24.246,
      2.512,
      43.447,
      24.246,
      2.512,
      43.447,
      24.246,
      0.412,
      45.059,
      23.706,
      0.412
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Magistrale_EG_10978",
    "polygon": [
      45.084,
      23.698,
      2.512,
      45.084,
      23.698,
      4.263,
      43.472,
      24.238,
      4.263,
      43.472,
      24.238,
      4.263,
      43.472,
      24.238,
      2.512,
      45.084,
      23.698,
      2.512
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_EG_10979",
    "polygon": [
      46.67,
      23.166,
      0.412,
      46.67,
      23.166,
      2.512,
      45.058,
      23.706,
      2.512,
      45.058,
      23.706,
      2.512,
      45.058,
      23.706,
      0.412,
      46.67,
      23.166,
      0.412
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_EG_10980",
    "polygon": [
      46.692,
      23.159,
      2.512,
      46.692,
      23.159,
      4.263,
      45.08,
      23.699,
      4.263,
      45.08,
      23.699,
      4.263,
      45.08,
      23.699,
      2.512,
      46.692,
      23.159,
      2.512
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_EG_10981",
    "polygon": [
      48.282,
      22.626,
      0.412,
      48.282,
      22.626,
      2.512,
      46.671,
      23.166,
      2.512,
      46.671,
      23.166,
      2.512,
      46.671,
      23.166,
      0.412,
      48.282,
      22.626,
      0.412
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Magistrale_EG_10982",
    "polygon": [
      48.302,
      22.62,
      2.512,
      48.302,
      22.62,
      4.263,
      46.69,
      23.16,
      4.263,
      46.69,
      23.16,
      4.263,
      46.69,
      23.16,
      2.512,
      48.302,
      22.62,
      2.512
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_EG_10983",
    "polygon": [
      49.894,
      22.086,
      0.412,
      49.894,
      22.086,
      2.512,
      48.282,
      22.626,
      2.512,
      48.282,
      22.626,
      2.512,
      48.282,
      22.626,
      0.412,
      49.894,
      22.086,
      0.412
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Magistrale_EG_10984",
    "polygon": [
      49.912,
      22.08,
      2.512,
      49.912,
      22.08,
      4.263,
      48.3,
      22.62,
      4.263,
      48.3,
      22.62,
      4.263,
      48.3,
      22.62,
      2.512,
      49.912,
      22.08,
      2.512
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Magistrale_EG_10985",
    "polygon": [
      43.473,
      24.237,
      2.512,
      43.473,
      24.237,
      4.263,
      41.861,
      24.777,
      4.263,
      41.861,
      24.777,
      4.263,
      41.861,
      24.777,
      2.512,
      43.473,
      24.237,
      2.512
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Magistrale_EG_10986",
    "polygon": [
      45.071,
      23.702,
      4.262,
      45.071,
      23.702,
      4.962,
      43.459,
      24.242,
      4.962,
      43.459,
      24.242,
      4.962,
      43.459,
      24.242,
      4.262,
      45.071,
      23.702,
      4.262
    ]
  },
  {
    "ID": "Magistrale_3.OG_10988",
    "polygon": [
      60.194,
      29.872,
      12.7,
      60.194,
      29.872,
      14.8,
      59.574,
      28.045,
      14.8,
      59.574,
      28.045,
      14.8,
      59.574,
      28.045,
      12.7,
      60.194,
      29.872,
      12.7
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10989",
    "polygon": [
      60.199,
      29.887,
      14.8,
      60.199,
      29.887,
      15.5,
      59.579,
      28.06,
      15.5,
      59.579,
      28.06,
      15.5,
      59.579,
      28.06,
      14.8,
      60.199,
      29.887,
      14.8
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10990",
    "polygon": [
      60.826,
      31.736,
      14.799,
      60.826,
      31.736,
      15.499,
      60.198,
      29.884,
      15.499,
      60.198,
      29.884,
      15.499,
      60.198,
      29.884,
      14.799,
      60.826,
      31.736,
      14.799
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10991",
    "polygon": [
      61.454,
      33.587,
      14.805,
      61.454,
      33.587,
      15.505,
      60.826,
      31.736,
      15.505,
      60.826,
      31.736,
      15.505,
      60.826,
      31.736,
      14.805,
      61.454,
      33.587,
      14.805
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10992",
    "polygon": [
      62.091,
      35.463,
      14.803,
      62.091,
      35.463,
      15.503,
      61.454,
      33.587,
      15.503,
      61.454,
      33.587,
      15.503,
      61.454,
      33.587,
      14.803,
      62.091,
      35.463,
      14.803
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10993",
    "polygon": [
      60.821,
      31.721,
      12.699,
      60.821,
      31.721,
      14.799,
      60.193,
      29.869,
      14.799,
      60.193,
      29.869,
      14.799,
      60.193,
      29.869,
      12.699,
      60.821,
      31.721,
      12.699
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10994",
    "polygon": [
      61.449,
      33.573,
      12.706,
      61.449,
      33.573,
      14.806,
      60.821,
      31.721,
      14.806,
      60.821,
      31.721,
      14.806,
      60.821,
      31.721,
      12.706,
      61.449,
      33.573,
      12.706
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Magistrale_3.OG_10995",
    "polygon": [
      62.086,
      35.449,
      12.703,
      62.086,
      35.449,
      14.803,
      61.449,
      33.573,
      14.803,
      61.449,
      33.573,
      14.803,
      61.449,
      33.573,
      12.703,
      62.086,
      35.449,
      12.703
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10996",
    "polygon": [
      60.186,
      29.857,
      8.854,
      60.186,
      29.857,
      10.954,
      59.566,
      28.03,
      10.954,
      59.566,
      28.03,
      10.954,
      59.566,
      28.03,
      8.854,
      60.186,
      29.857,
      8.854
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1
  },
  {
    "ID": "Magistrale_2.OG_10997",
    "polygon": [
      60.187,
      29.86,
      10.954,
      60.187,
      29.86,
      12.704,
      59.567,
      28.033,
      12.704,
      59.567,
      28.033,
      12.704,
      59.567,
      28.033,
      10.954,
      60.187,
      29.86,
      10.954
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1
  },
  {
    "ID": "Magistrale_2.OG_10998",
    "polygon": [
      60.812,
      31.705,
      8.85,
      60.812,
      31.705,
      10.95,
      60.184,
      29.852,
      10.95,
      60.184,
      29.852,
      10.95,
      60.184,
      29.852,
      8.85,
      60.812,
      31.705,
      8.85
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1
  },
  {
    "ID": "Magistrale_2.OG_10999",
    "polygon": [
      61.44,
      33.554,
      8.873,
      61.44,
      33.554,
      10.973,
      60.812,
      31.703,
      10.973,
      60.812,
      31.703,
      10.973,
      60.812,
      31.703,
      8.873,
      61.44,
      33.554,
      8.873
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1
  },
  {
    "ID": "Magistrale_2.OG_11000",
    "polygon": [
      62.073,
      35.421,
      8.858,
      62.073,
      35.421,
      10.958,
      61.436,
      33.545,
      10.958,
      61.436,
      33.545,
      10.958,
      61.436,
      33.545,
      8.858,
      62.073,
      35.421,
      8.858
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1
  },
  {
    "ID": "Magistrale_2.OG_11002",
    "polygon": [
      61.441,
      33.559,
      10.958,
      61.441,
      33.559,
      12.708,
      60.813,
      31.707,
      12.708,
      60.813,
      31.707,
      12.708,
      60.813,
      31.707,
      10.958,
      61.441,
      33.559,
      10.958
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1
  },
  {
    "ID": "Magistrale_2.OG_11003",
    "polygon": [
      62.077,
      35.432,
      10.957,
      62.077,
      35.432,
      12.707,
      61.44,
      33.556,
      12.707,
      61.44,
      33.556,
      12.707,
      61.44,
      33.556,
      10.957,
      62.077,
      35.432,
      10.957
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1
  },
  {
    "ID": "Magistrale_1.OG_11004",
    "polygon": [
      60.19,
      29.87,
      5.706,
      60.19,
      29.87,
      7.806,
      59.571,
      28.043,
      7.806,
      59.571,
      28.043,
      7.806,
      59.571,
      28.043,
      5.706,
      60.19,
      29.87,
      5.706
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1
  },
  {
    "ID": "Magistrale_1.OG_11005",
    "polygon": [
      60.813,
      31.706,
      5.71,
      60.813,
      31.706,
      7.81,
      60.184,
      29.854,
      7.81,
      60.184,
      29.854,
      7.81,
      60.184,
      29.854,
      5.71,
      60.813,
      31.706,
      5.71
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Magistrale_1.OG_11006",
    "polygon": [
      61.441,
      33.557,
      5.729,
      61.441,
      33.557,
      7.829,
      60.813,
      31.706,
      7.829,
      60.813,
      31.706,
      7.829,
      60.813,
      31.706,
      5.729,
      61.441,
      33.557,
      5.729
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1
  },
  {
    "ID": "Magistrale_1.OG_11007",
    "polygon": [
      62.076,
      35.431,
      5.732,
      62.076,
      35.431,
      7.832,
      61.44,
      33.555,
      7.832,
      61.44,
      33.555,
      7.832,
      61.44,
      33.555,
      5.732,
      62.076,
      35.431,
      5.732
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1
  },
  {
    "ID": "Magistrale_1.OG_11008",
    "polygon": [
      60.191,
      29.873,
      7.805,
      60.191,
      29.873,
      8.855,
      59.571,
      28.046,
      8.855,
      59.571,
      28.046,
      8.855,
      59.571,
      28.046,
      7.805,
      60.191,
      29.873,
      7.805
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1
  },
  {
    "ID": "Magistrale_1.OG_11009",
    "polygon": [
      60.819,
      31.724,
      7.81,
      60.819,
      31.724,
      8.86,
      60.191,
      29.872,
      8.86,
      60.191,
      29.872,
      8.86,
      60.191,
      29.872,
      7.81,
      60.819,
      31.724,
      7.81
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "Magistrale_1.OG_11010",
    "polygon": [
      61.441,
      33.556,
      7.826,
      61.441,
      33.556,
      8.876,
      60.813,
      31.705,
      8.876,
      60.813,
      31.705,
      8.876,
      60.813,
      31.705,
      7.826,
      61.441,
      33.556,
      7.826
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1
  },
  {
    "ID": "Magistrale_1.OG_11011",
    "polygon": [
      62.077,
      35.431,
      7.828,
      62.077,
      35.431,
      8.878,
      61.44,
      33.555,
      8.878,
      61.44,
      33.555,
      8.878,
      61.44,
      33.555,
      7.828,
      62.077,
      35.431,
      7.828
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1
  },
  {
    "ID": "Magistrale_2.OG_11012",
    "polygon": [
      60.814,
      31.708,
      10.95,
      60.814,
      31.708,
      12.7,
      60.186,
      29.857,
      12.7,
      60.186,
      29.857,
      12.7,
      60.186,
      29.857,
      10.95,
      60.814,
      31.708,
      10.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1
  },
  {
    "ID": "Magistrale_3.OG_11014",
    "polygon": [
      -45.751,
      69.352,
      12.698,
      -45.751,
      69.352,
      14.798,
      -45.122,
      71.207,
      14.798,
      -45.122,
      71.207,
      14.798,
      -45.122,
      71.207,
      12.698,
      -45.751,
      69.352,
      12.698
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_3.OG_11015",
    "polygon": [
      -45.751,
      69.353,
      14.795,
      -45.751,
      69.353,
      15.495,
      -45.122,
      71.208,
      15.495,
      -45.122,
      71.208,
      15.495,
      -45.122,
      71.208,
      14.795,
      -45.751,
      69.353,
      14.795
    ],
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_3.OG_11016",
    "polygon": [
      -47.01,
      65.642,
      12.689,
      -47.01,
      65.642,
      14.789,
      -46.381,
      67.497,
      14.789,
      -46.381,
      67.497,
      14.789,
      -46.381,
      67.497,
      12.689,
      -47.01,
      65.642,
      12.689
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_3.OG_11017",
    "polygon": [
      -47.009,
      65.646,
      14.788,
      -47.009,
      65.646,
      15.488,
      -46.379,
      67.501,
      15.488,
      -46.379,
      67.501,
      15.488,
      -46.379,
      67.501,
      14.788,
      -47.009,
      65.646,
      14.788
    ],
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_3.OG_11018",
    "polygon": [
      -46.38,
      67.499,
      14.792,
      -46.38,
      67.499,
      15.492,
      -45.75,
      69.356,
      15.492,
      -45.75,
      69.356,
      15.492,
      -45.75,
      69.356,
      14.792,
      -46.38,
      67.499,
      14.792
    ],
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_3.OG_11020",
    "polygon": [
      -46.381,
      67.496,
      12.692,
      -46.381,
      67.496,
      14.792,
      -45.751,
      69.352,
      14.792,
      -45.751,
      69.352,
      14.792,
      -45.751,
      69.352,
      12.692,
      -46.381,
      67.496,
      12.692
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_3.OG_11021",
    "polygon": [
      -47.636,
      63.795,
      12.693,
      -47.636,
      63.795,
      14.793,
      -47.01,
      65.643,
      14.793,
      -47.01,
      65.643,
      14.793,
      -47.01,
      65.643,
      12.693,
      -47.636,
      63.795,
      12.693
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_3.OG_11022",
    "polygon": [
      -47.635,
      63.799,
      14.791,
      -47.635,
      63.799,
      15.491,
      -47.008,
      65.647,
      15.491,
      -47.008,
      65.647,
      15.491,
      -47.008,
      65.647,
      14.791,
      -47.635,
      63.799,
      14.791
    ],
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_11023",
    "polygon": [
      -45.754,
      69.346,
      8.864,
      -45.754,
      69.346,
      10.964,
      -45.124,
      71.201,
      10.964,
      -45.124,
      71.201,
      10.964,
      -45.124,
      71.201,
      8.864,
      -45.754,
      69.346,
      8.864
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_11024",
    "polygon": [
      -47.012,
      65.637,
      8.85,
      -47.012,
      65.637,
      10.95,
      -46.382,
      67.492,
      10.95,
      -46.382,
      67.492,
      10.95,
      -46.382,
      67.492,
      8.85,
      -47.012,
      65.637,
      8.85
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_11025",
    "polygon": [
      -46.382,
      67.492,
      8.845,
      -46.382,
      67.492,
      10.945,
      -45.753,
      69.348,
      10.945,
      -45.753,
      69.348,
      10.945,
      -45.753,
      69.348,
      8.845,
      -46.382,
      67.492,
      8.845
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_11026",
    "polygon": [
      -47.637,
      63.792,
      8.86,
      -47.637,
      63.792,
      10.96,
      -47.011,
      65.64,
      10.96,
      -47.011,
      65.64,
      10.96,
      -47.011,
      65.64,
      8.86,
      -47.637,
      63.792,
      8.86
    ],
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_11027",
    "polygon": [
      -45.753,
      69.347,
      10.959,
      -45.753,
      69.347,
      12.709,
      -45.124,
      71.202,
      12.709,
      -45.124,
      71.202,
      12.709,
      -45.124,
      71.202,
      10.959,
      -45.753,
      69.347,
      10.959
    ],
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_11028",
    "polygon": [
      -46.381,
      67.496,
      10.943,
      -46.381,
      67.496,
      12.693,
      -45.751,
      69.352,
      12.693,
      -45.751,
      69.352,
      12.693,
      -45.751,
      69.352,
      10.943,
      -46.381,
      67.496,
      10.943
    ],
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_11029",
    "polygon": [
      -47.012,
      65.637,
      10.944,
      -47.012,
      65.637,
      12.694,
      -46.381,
      67.498,
      12.694,
      -46.381,
      67.498,
      12.694,
      -46.381,
      67.498,
      10.944,
      -47.012,
      65.637,
      10.944
    ],
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_11030",
    "polygon": [
      -47.638,
      63.79,
      10.958,
      -47.638,
      63.79,
      12.708,
      -47.011,
      65.637,
      12.708,
      -47.011,
      65.637,
      12.708,
      -47.011,
      65.637,
      10.958,
      -47.638,
      63.79,
      10.958
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_11031",
    "polygon": [
      -45.749,
      69.359,
      5.018,
      -45.749,
      69.359,
      7.118,
      -45.12,
      71.214,
      7.118,
      -45.12,
      71.214,
      7.118,
      -45.12,
      71.214,
      5.018,
      -45.749,
      69.359,
      5.018
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_11032",
    "polygon": [
      -47.008,
      65.649,
      5.0,
      -47.008,
      65.649,
      7.1,
      -46.378,
      67.504,
      7.1,
      -46.378,
      67.504,
      7.1,
      -46.378,
      67.504,
      5.0,
      -47.008,
      65.649,
      5.0
    ],
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_11033",
    "polygon": [
      -46.379,
      67.504,
      4.995,
      -46.379,
      67.504,
      7.095,
      -45.749,
      69.36,
      7.095,
      -45.749,
      69.36,
      7.095,
      -45.749,
      69.36,
      4.995,
      -46.379,
      67.504,
      4.995
    ],
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_11034",
    "polygon": [
      -47.633,
      63.807,
      5.013,
      -47.633,
      63.807,
      7.113,
      -47.006,
      65.654,
      7.113,
      -47.006,
      65.654,
      7.113,
      -47.006,
      65.654,
      5.013,
      -47.633,
      63.807,
      5.013
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_11035",
    "polygon": [
      -45.751,
      69.352,
      7.116,
      -45.751,
      69.352,
      8.866,
      -45.122,
      71.207,
      8.866,
      -45.122,
      71.207,
      8.866,
      -45.122,
      71.207,
      7.116,
      -45.751,
      69.352,
      7.116
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_11036",
    "polygon": [
      -46.381,
      67.496,
      7.095,
      -46.381,
      67.496,
      8.845,
      -45.751,
      69.352,
      8.845,
      -45.751,
      69.352,
      8.845,
      -45.751,
      69.352,
      7.095,
      -46.381,
      67.496,
      7.095
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_11037",
    "polygon": [
      -47.012,
      65.635,
      7.1,
      -47.012,
      65.635,
      8.85,
      -46.381,
      67.496,
      8.85,
      -46.381,
      67.496,
      8.85,
      -46.381,
      67.496,
      7.1,
      -47.012,
      65.635,
      7.1
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_11038",
    "polygon": [
      -47.638,
      63.79,
      7.112,
      -47.638,
      63.79,
      8.862,
      -47.012,
      65.637,
      8.862,
      -47.012,
      65.637,
      8.862,
      -47.012,
      65.637,
      7.112,
      -47.638,
      63.79,
      7.112
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_11039",
    "polygon": [
      11.441,
      60.862,
      0.598,
      11.441,
      60.862,
      3.598,
      13.234,
      60.261,
      3.598,
      13.234,
      60.261,
      3.598,
      13.234,
      60.261,
      0.598,
      11.441,
      60.862,
      0.598
    ]
  },
  {
    "ID": "Magistrale_EG_11040",
    "polygon": [
      11.441,
      60.862,
      3.598,
      11.441,
      60.862,
      4.723,
      13.234,
      60.261,
      4.723,
      13.234,
      60.261,
      4.723,
      13.234,
      60.261,
      3.598,
      11.441,
      60.862,
      3.598
    ]
  },
  {
    "ID": "Magistrale_EG_11041",
    "polygon": [
      13.234,
      60.261,
      0.598,
      13.234,
      60.261,
      3.598,
      15.026,
      59.661,
      3.598,
      15.026,
      59.661,
      3.598,
      15.026,
      59.661,
      0.598,
      13.234,
      60.261,
      0.598
    ]
  },
  {
    "ID": "Magistrale_EG_11042",
    "polygon": [
      13.234,
      60.261,
      3.598,
      13.234,
      60.261,
      4.723,
      15.026,
      59.661,
      4.723,
      15.026,
      59.661,
      4.723,
      15.026,
      59.661,
      3.598,
      13.234,
      60.261,
      3.598
    ]
  },
  {
    "ID": "Magistrale_EG_11043",
    "polygon": [
      15.026,
      59.661,
      0.598,
      15.026,
      59.661,
      3.598,
      16.818,
      59.061,
      3.598,
      16.818,
      59.061,
      3.598,
      16.818,
      59.061,
      0.598,
      15.026,
      59.661,
      0.598
    ]
  },
  {
    "ID": "Magistrale_EG_11044",
    "polygon": [
      15.026,
      59.661,
      3.598,
      15.026,
      59.661,
      4.723,
      16.818,
      59.061,
      4.723,
      16.818,
      59.061,
      4.723,
      16.818,
      59.061,
      3.598,
      15.026,
      59.661,
      3.598
    ]
  },
  {
    "ID": "Magistrale_EG_11045",
    "polygon": [
      16.818,
      59.061,
      0.598,
      16.818,
      59.061,
      3.598,
      18.61,
      58.46,
      3.598,
      18.61,
      58.46,
      3.598,
      18.61,
      58.46,
      0.598,
      16.818,
      59.061,
      0.598
    ]
  },
  {
    "ID": "Magistrale_EG_11046",
    "polygon": [
      16.818,
      59.061,
      3.598,
      16.818,
      59.061,
      4.723,
      18.61,
      58.46,
      4.723,
      18.61,
      58.46,
      4.723,
      18.61,
      58.46,
      3.598,
      16.818,
      59.061,
      3.598
    ]
  },
  {
    "ID": "Magistrale_EG_11047",
    "polygon": [
      18.61,
      58.46,
      0.598,
      18.61,
      58.46,
      3.598,
      20.402,
      57.86,
      3.598,
      20.402,
      57.86,
      3.598,
      20.402,
      57.86,
      0.598,
      18.61,
      58.46,
      0.598
    ]
  },
  {
    "ID": "Magistrale_EG_11048",
    "polygon": [
      18.61,
      58.46,
      3.598,
      18.61,
      58.46,
      4.723,
      20.402,
      57.86,
      4.723,
      20.402,
      57.86,
      4.723,
      20.402,
      57.86,
      3.598,
      18.61,
      58.46,
      3.598
    ]
  },
  {
    "ID": "Magistrale_EG_11049",
    "polygon": [
      20.402,
      57.86,
      0.598,
      20.402,
      57.86,
      3.598,
      22.194,
      57.26,
      3.598,
      22.194,
      57.26,
      3.598,
      22.194,
      57.26,
      0.598,
      20.402,
      57.86,
      0.598
    ]
  },
  {
    "ID": "Magistrale_EG_11050",
    "polygon": [
      20.402,
      57.86,
      3.598,
      20.402,
      57.86,
      4.723,
      22.194,
      57.26,
      4.723,
      22.194,
      57.26,
      4.723,
      22.194,
      57.26,
      3.598,
      20.402,
      57.86,
      3.598
    ]
  },
  {
    "ID": "Magistrale_EG_11051",
    "polygon": [
      3.3,
      63.589,
      0.598,
      3.3,
      63.589,
      3.598,
      5.092,
      62.989,
      3.598,
      5.092,
      62.989,
      3.598,
      5.092,
      62.989,
      0.598,
      3.3,
      63.589,
      0.598
    ]
  },
  {
    "ID": "Magistrale_EG_11052",
    "polygon": [
      3.3,
      63.589,
      3.598,
      3.3,
      63.589,
      4.723,
      5.092,
      62.989,
      4.723,
      5.092,
      62.989,
      4.723,
      5.092,
      62.989,
      3.598,
      3.3,
      63.589,
      3.598
    ]
  },
  {
    "ID": "Magistrale_EG_11053",
    "polygon": [
      1.508,
      64.189,
      0.598,
      1.508,
      64.189,
      3.598,
      3.3,
      63.589,
      3.598,
      3.3,
      63.589,
      3.598,
      3.3,
      63.589,
      0.598,
      1.508,
      64.189,
      0.598
    ]
  },
  {
    "ID": "Magistrale_EG_11054",
    "polygon": [
      1.508,
      64.189,
      3.598,
      1.508,
      64.189,
      4.723,
      3.3,
      63.589,
      4.723,
      3.3,
      63.589,
      4.723,
      3.3,
      63.589,
      3.598,
      1.508,
      64.189,
      3.598
    ]
  },
  {
    "ID": "Magistrale_EG_11055",
    "polygon": [
      -0.284,
      64.79,
      0.598,
      -0.284,
      64.79,
      3.598,
      1.508,
      64.189,
      3.598,
      1.508,
      64.189,
      3.598,
      1.508,
      64.189,
      0.598,
      -0.284,
      64.79,
      0.598
    ]
  },
  {
    "ID": "Magistrale_EG_11056",
    "polygon": [
      -0.284,
      64.79,
      3.598,
      -0.284,
      64.79,
      4.723,
      1.508,
      64.189,
      4.723,
      1.508,
      64.189,
      4.723,
      1.508,
      64.189,
      3.598,
      -0.284,
      64.79,
      3.598
    ]
  },
  {
    "ID": "Magistrale_DA_11058",
    "polygon": [
      -4.34,
      59.174,
      15.95,
      -5.162,
      56.749,
      15.95,
      -4.073,
      56.38,
      15.95,
      -4.073,
      56.38,
      15.95,
      -3.251,
      58.804,
      15.95,
      -4.34,
      59.174,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11061",
    "polygon": [
      -5.534,
      59.579,
      15.95,
      -6.356,
      57.154,
      15.95,
      -5.267,
      56.785,
      15.95,
      -5.267,
      56.785,
      15.95,
      -4.445,
      59.209,
      15.95,
      -5.534,
      59.579,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11062",
    "polygon": [
      -7.899,
      60.381,
      15.95,
      -8.722,
      57.957,
      15.95,
      -7.632,
      57.587,
      15.95,
      -7.632,
      57.587,
      15.95,
      -6.81,
      60.012,
      15.95,
      -7.899,
      60.381,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11063",
    "polygon": [
      -9.093,
      60.786,
      15.95,
      -9.916,
      58.361,
      15.95,
      -8.826,
      57.992,
      15.95,
      -8.826,
      57.992,
      15.95,
      -8.004,
      60.416,
      15.95,
      -9.093,
      60.786,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11064",
    "polygon": [
      -11.45,
      61.586,
      15.95,
      -12.273,
      59.161,
      15.95,
      -11.184,
      58.792,
      15.95,
      -11.184,
      58.792,
      15.95,
      -10.361,
      61.216,
      15.95,
      -11.45,
      61.586,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11065",
    "polygon": [
      -12.644,
      61.99,
      15.95,
      -13.467,
      59.566,
      15.95,
      -12.378,
      59.197,
      15.95,
      -12.378,
      59.197,
      15.95,
      -11.555,
      61.621,
      15.95,
      -12.644,
      61.99,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11066",
    "polygon": [
      -13.818,
      62.389,
      15.95,
      -14.64,
      59.964,
      15.95,
      -13.551,
      59.595,
      15.95,
      -13.551,
      59.595,
      15.95,
      -12.729,
      62.019,
      15.95,
      -13.818,
      62.389,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11067",
    "polygon": [
      -15.012,
      62.793,
      15.95,
      -15.834,
      60.369,
      15.95,
      -14.745,
      60.0,
      15.95,
      -14.745,
      60.0,
      15.95,
      -13.923,
      62.424,
      15.95,
      -15.012,
      62.793,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11068",
    "polygon": [
      -16.186,
      63.192,
      15.95,
      -17.008,
      60.767,
      15.95,
      -15.919,
      60.398,
      15.95,
      -15.919,
      60.398,
      15.95,
      -15.096,
      62.822,
      15.95,
      -16.186,
      63.192,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11069",
    "polygon": [
      -17.379,
      63.596,
      15.95,
      -18.202,
      61.172,
      15.95,
      -17.113,
      60.803,
      15.95,
      -17.113,
      60.803,
      15.95,
      -16.29,
      63.227,
      15.95,
      -17.379,
      63.596,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11070",
    "polygon": [
      -20.921,
      64.798,
      15.95,
      -21.743,
      62.373,
      15.95,
      -20.654,
      62.004,
      15.95,
      -20.654,
      62.004,
      15.95,
      -19.831,
      64.428,
      15.95,
      -20.921,
      64.798,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11071",
    "polygon": [
      -22.114,
      65.203,
      15.95,
      -22.937,
      62.778,
      15.95,
      -21.848,
      62.409,
      15.95,
      -21.848,
      62.409,
      15.95,
      -21.025,
      64.833,
      15.95,
      -22.114,
      65.203,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11072",
    "polygon": [
      -26.839,
      66.805,
      15.95,
      -27.662,
      64.381,
      15.95,
      -26.573,
      64.012,
      15.95,
      -26.573,
      64.012,
      15.95,
      -25.75,
      66.436,
      15.95,
      -26.839,
      66.805,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11073",
    "polygon": [
      -28.033,
      67.21,
      15.95,
      -28.856,
      64.786,
      15.95,
      -27.767,
      64.416,
      15.95,
      -27.767,
      64.416,
      15.95,
      -26.944,
      66.841,
      15.95,
      -28.033,
      67.21,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11074",
    "polygon": [
      -32.758,
      68.813,
      15.95,
      -33.58,
      66.389,
      15.95,
      -32.491,
      66.019,
      15.95,
      -32.491,
      66.019,
      15.95,
      -31.669,
      68.444,
      15.95,
      -32.758,
      68.813,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11075",
    "polygon": [
      -33.952,
      69.218,
      15.95,
      -34.774,
      66.793,
      15.95,
      -33.685,
      66.424,
      15.95,
      -33.685,
      66.424,
      15.95,
      -32.863,
      68.848,
      15.95,
      -33.952,
      69.218,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11080",
    "polygon": [
      -35.136,
      69.619,
      15.95,
      -35.958,
      67.195,
      15.95,
      -34.869,
      66.826,
      15.95,
      -34.869,
      66.826,
      15.95,
      -34.047,
      69.25,
      15.95,
      -35.136,
      69.619,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11081",
    "polygon": [
      -36.32,
      70.021,
      15.95,
      -37.142,
      67.597,
      15.95,
      -36.053,
      67.227,
      15.95,
      -36.053,
      67.227,
      15.95,
      -35.231,
      69.651,
      15.95,
      -36.32,
      70.021,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11082",
    "polygon": [
      -37.503,
      70.422,
      15.95,
      -38.326,
      67.998,
      15.95,
      -37.237,
      67.629,
      15.95,
      -37.237,
      67.629,
      15.95,
      -36.414,
      70.053,
      15.95,
      -37.503,
      70.422,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11083",
    "polygon": [
      -38.687,
      70.824,
      15.95,
      -39.509,
      68.4,
      15.95,
      -38.42,
      68.03,
      15.95,
      -38.42,
      68.03,
      15.95,
      -37.598,
      70.455,
      15.95,
      -38.687,
      70.824,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11084",
    "polygon": [
      -39.871,
      71.225,
      15.95,
      -40.693,
      68.801,
      15.95,
      -39.604,
      68.432,
      15.95,
      -39.604,
      68.432,
      15.95,
      -38.782,
      70.856,
      15.95,
      -39.871,
      71.225,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11085",
    "polygon": [
      -41.055,
      71.627,
      15.95,
      -41.877,
      69.203,
      15.95,
      -40.788,
      68.833,
      15.95,
      -40.788,
      68.833,
      15.95,
      -39.966,
      71.258,
      15.95,
      -41.055,
      71.627,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11087",
    "polygon": [
      -31.574,
      68.411,
      15.95,
      -32.397,
      65.987,
      15.95,
      -31.308,
      65.618,
      15.95,
      -31.308,
      65.618,
      15.95,
      -30.485,
      68.042,
      15.95,
      -31.574,
      68.411,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11088",
    "polygon": [
      -30.391,
      68.01,
      15.95,
      -31.213,
      65.586,
      15.95,
      -30.124,
      65.216,
      15.95,
      -30.124,
      65.216,
      15.95,
      -29.302,
      67.641,
      15.95,
      -30.391,
      68.01,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11089",
    "polygon": [
      -29.207,
      67.608,
      15.95,
      -30.029,
      65.184,
      15.95,
      -28.94,
      64.815,
      15.95,
      -28.94,
      64.815,
      15.95,
      -28.118,
      67.239,
      15.95,
      -29.207,
      67.608,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11090",
    "polygon": [
      -25.656,
      66.404,
      15.95,
      -26.478,
      63.98,
      15.95,
      -25.389,
      63.61,
      15.95,
      -25.389,
      63.61,
      15.95,
      -24.567,
      66.034,
      15.95,
      -25.656,
      66.404,
      15.95
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11091",
    "polygon": [
      -24.472,
      66.002,
      15.95,
      -25.294,
      63.578,
      15.95,
      -24.205,
      63.209,
      15.95,
      -24.205,
      63.209,
      15.95,
      -23.383,
      65.633,
      15.95,
      -24.472,
      66.002,
      15.95
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11092",
    "polygon": [
      -23.288,
      65.601,
      15.95,
      -24.11,
      63.176,
      15.95,
      -23.021,
      62.807,
      15.95,
      -23.021,
      62.807,
      15.95,
      -22.199,
      65.231,
      15.95,
      -23.288,
      65.601,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11093",
    "polygon": [
      -19.737,
      64.396,
      15.95,
      -20.559,
      61.972,
      15.95,
      -19.47,
      61.602,
      15.95,
      -19.47,
      61.602,
      15.95,
      -18.648,
      64.027,
      15.95,
      -19.737,
      64.396,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11094",
    "polygon": [
      -18.553,
      63.995,
      15.95,
      -19.375,
      61.57,
      15.95,
      -18.286,
      61.201,
      15.95,
      -18.286,
      61.201,
      15.95,
      -17.464,
      63.625,
      15.95,
      -18.553,
      63.995,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11095",
    "polygon": [
      -10.267,
      61.184,
      15.95,
      -11.089,
      58.76,
      15.95,
      -10.0,
      58.39,
      15.95,
      -10.0,
      58.39,
      15.95,
      -9.178,
      60.815,
      15.95,
      -10.267,
      61.184,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11096",
    "polygon": [
      -6.715,
      59.979,
      15.95,
      -7.538,
      57.555,
      15.95,
      -6.449,
      57.186,
      15.95,
      -6.449,
      57.186,
      15.95,
      -5.626,
      59.61,
      15.95,
      -6.715,
      59.979,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11097",
    "polygon": [
      28.805,
      47.931,
      15.95,
      27.983,
      45.507,
      15.95,
      29.072,
      45.137,
      15.95,
      29.072,
      45.137,
      15.95,
      29.894,
      47.562,
      15.95,
      28.805,
      47.931,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11098",
    "polygon": [
      25.246,
      49.138,
      15.95,
      24.424,
      46.714,
      15.95,
      25.513,
      46.344,
      15.95,
      25.513,
      46.344,
      15.95,
      26.335,
      48.769,
      15.95,
      25.246,
      49.138,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11099",
    "polygon": [
      24.052,
      49.543,
      15.95,
      23.23,
      47.119,
      15.95,
      24.319,
      46.749,
      15.95,
      24.319,
      46.749,
      15.95,
      25.141,
      49.174,
      15.95,
      24.052,
      49.543,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11100",
    "polygon": [
      21.695,
      50.343,
      15.95,
      20.872,
      47.918,
      15.95,
      21.961,
      47.549,
      15.95,
      21.961,
      47.549,
      15.95,
      22.784,
      49.973,
      15.95,
      21.695,
      50.343,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11101",
    "polygon": [
      22.878,
      49.941,
      15.95,
      22.056,
      47.517,
      15.95,
      23.145,
      47.148,
      15.95,
      23.145,
      47.148,
      15.95,
      23.967,
      49.572,
      15.95,
      22.878,
      49.941,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11102",
    "polygon": [
      26.43,
      48.737,
      15.95,
      25.607,
      46.312,
      15.95,
      26.696,
      45.943,
      15.95,
      26.696,
      45.943,
      15.95,
      27.519,
      48.367,
      15.95,
      26.43,
      48.737,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11103",
    "polygon": [
      27.613,
      48.335,
      15.95,
      26.791,
      45.911,
      15.95,
      27.88,
      45.541,
      15.95,
      27.88,
      45.541,
      15.95,
      28.702,
      47.966,
      15.95,
      27.613,
      48.335,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11104",
    "polygon": [
      20.506,
      50.753,
      15.95,
      19.683,
      48.328,
      15.95,
      20.773,
      47.959,
      15.95,
      20.773,
      47.959,
      15.95,
      21.595,
      50.383,
      15.95,
      20.506,
      50.753,
      15.95
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11105",
    "polygon": [
      29.989,
      47.529,
      15.95,
      29.167,
      45.105,
      15.95,
      30.256,
      44.736,
      15.95,
      30.256,
      44.736,
      15.95,
      31.078,
      47.16,
      15.95,
      29.989,
      47.529,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11106",
    "polygon": [
      31.173,
      47.128,
      15.95,
      30.35,
      44.704,
      15.95,
      31.439,
      44.334,
      15.95,
      31.439,
      44.334,
      15.95,
      32.262,
      46.759,
      15.95,
      31.173,
      47.128,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11107",
    "polygon": [
      32.356,
      46.726,
      15.95,
      31.534,
      44.302,
      15.95,
      32.623,
      43.933,
      15.95,
      32.623,
      43.933,
      15.95,
      33.445,
      46.357,
      15.95,
      32.356,
      46.726,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11108",
    "polygon": [
      33.54,
      46.325,
      15.95,
      32.718,
      43.901,
      15.95,
      33.807,
      43.531,
      15.95,
      33.807,
      43.531,
      15.95,
      34.629,
      45.955,
      15.95,
      33.54,
      46.325,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11109",
    "polygon": [
      34.724,
      45.923,
      15.95,
      33.902,
      43.499,
      15.95,
      34.991,
      43.13,
      15.95,
      34.991,
      43.13,
      15.95,
      35.813,
      45.554,
      15.95,
      34.724,
      45.923,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11110",
    "polygon": [
      35.908,
      45.522,
      15.95,
      35.085,
      43.097,
      15.95,
      36.174,
      42.728,
      15.95,
      36.174,
      42.728,
      15.95,
      36.997,
      45.152,
      15.95,
      35.908,
      45.522,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11111",
    "polygon": [
      37.091,
      45.12,
      15.95,
      36.269,
      42.696,
      15.95,
      37.358,
      42.327,
      15.95,
      37.358,
      42.327,
      15.95,
      38.18,
      44.751,
      15.95,
      37.091,
      45.12,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11112",
    "polygon": [
      38.275,
      44.719,
      15.95,
      37.453,
      42.294,
      15.95,
      38.542,
      41.925,
      15.95,
      38.542,
      41.925,
      15.95,
      39.364,
      44.349,
      15.95,
      38.275,
      44.719,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11113",
    "polygon": [
      39.459,
      44.317,
      15.95,
      38.637,
      41.893,
      15.95,
      39.726,
      41.524,
      15.95,
      39.726,
      41.524,
      15.95,
      40.548,
      43.948,
      15.95,
      39.459,
      44.317,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11114",
    "polygon": [
      40.643,
      43.916,
      15.95,
      39.82,
      41.491,
      15.95,
      40.909,
      41.122,
      15.95,
      40.909,
      41.122,
      15.95,
      41.732,
      43.546,
      15.95,
      40.643,
      43.916,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11115",
    "polygon": [
      41.826,
      43.514,
      15.95,
      41.004,
      41.09,
      15.95,
      42.093,
      40.72,
      15.95,
      42.093,
      40.72,
      15.95,
      42.915,
      43.145,
      15.95,
      41.826,
      43.514,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11116",
    "polygon": [
      43.01,
      43.113,
      15.95,
      42.188,
      40.688,
      15.95,
      43.277,
      40.319,
      15.95,
      43.277,
      40.319,
      15.95,
      44.099,
      42.743,
      15.95,
      43.01,
      43.113,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11117",
    "polygon": [
      44.194,
      42.711,
      15.95,
      43.372,
      40.287,
      15.95,
      44.461,
      39.917,
      15.95,
      44.461,
      39.917,
      15.95,
      45.283,
      42.342,
      15.95,
      44.194,
      42.711,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11118",
    "polygon": [
      45.378,
      42.31,
      15.95,
      44.555,
      39.885,
      15.95,
      45.644,
      39.516,
      15.95,
      45.644,
      39.516,
      15.95,
      46.467,
      41.94,
      15.95,
      45.378,
      42.31,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11119",
    "polygon": [
      46.561,
      41.908,
      15.95,
      45.739,
      39.484,
      15.95,
      46.828,
      39.114,
      15.95,
      46.828,
      39.114,
      15.95,
      47.65,
      41.539,
      15.95,
      46.561,
      41.908,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11120",
    "polygon": [
      47.745,
      41.507,
      15.95,
      46.923,
      39.082,
      15.95,
      48.012,
      38.713,
      15.95,
      48.012,
      38.713,
      15.95,
      48.834,
      41.137,
      15.95,
      47.745,
      41.507,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11121",
    "polygon": [
      48.929,
      41.105,
      15.95,
      48.107,
      38.681,
      15.95,
      49.196,
      38.311,
      15.95,
      49.196,
      38.311,
      15.95,
      50.018,
      40.736,
      15.95,
      48.929,
      41.105,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11122",
    "polygon": [
      50.113,
      40.704,
      15.95,
      49.29,
      38.279,
      15.95,
      50.379,
      37.91,
      15.95,
      50.379,
      37.91,
      15.95,
      51.202,
      40.334,
      15.95,
      50.113,
      40.704,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11123",
    "polygon": [
      51.296,
      40.302,
      15.95,
      50.474,
      37.878,
      15.95,
      51.563,
      37.508,
      15.95,
      51.563,
      37.508,
      15.95,
      52.385,
      39.933,
      15.95,
      51.296,
      40.302,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11125",
    "polygon": [
      53.664,
      39.499,
      15.95,
      52.842,
      37.075,
      15.95,
      53.931,
      36.705,
      15.95,
      53.931,
      36.705,
      15.95,
      54.753,
      39.13,
      15.95,
      53.664,
      39.499,
      15.95
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11126",
    "polygon": [
      54.848,
      39.097,
      15.95,
      54.025,
      36.673,
      15.95,
      55.114,
      36.304,
      15.95,
      55.114,
      36.304,
      15.95,
      55.937,
      38.728,
      15.95,
      54.848,
      39.097,
      15.95
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11127",
    "polygon": [
      56.031,
      38.696,
      15.95,
      55.209,
      36.272,
      15.95,
      56.298,
      35.902,
      15.95,
      56.298,
      35.902,
      15.95,
      57.121,
      38.326,
      15.95,
      56.031,
      38.696,
      15.95
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11128",
    "polygon": [
      57.215,
      38.294,
      15.95,
      56.393,
      35.87,
      15.95,
      57.482,
      35.501,
      15.95,
      57.482,
      35.501,
      15.95,
      58.304,
      37.925,
      15.95,
      57.215,
      38.294,
      15.95
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11131",
    "polygon": [
      29.279,
      37.631,
      15.95,
      28.456,
      35.206,
      15.95,
      29.545,
      34.837,
      15.95,
      29.545,
      34.837,
      15.95,
      30.368,
      37.261,
      15.95,
      29.279,
      37.631,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11132",
    "polygon": [
      28.092,
      38.024,
      15.95,
      27.27,
      35.599,
      15.95,
      28.359,
      35.23,
      15.95,
      28.359,
      35.23,
      15.95,
      29.181,
      37.654,
      15.95,
      28.092,
      38.024,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11133",
    "polygon": [
      26.91,
      38.43,
      15.95,
      26.088,
      36.006,
      15.95,
      27.177,
      35.636,
      15.95,
      27.177,
      35.636,
      15.95,
      27.999,
      38.061,
      15.95,
      26.91,
      38.43,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11134",
    "polygon": [
      25.726,
      38.832,
      15.95,
      24.904,
      36.407,
      15.95,
      25.993,
      36.038,
      15.95,
      25.993,
      36.038,
      15.95,
      26.815,
      38.462,
      15.95,
      25.726,
      38.832,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11135",
    "polygon": [
      24.542,
      39.233,
      15.95,
      23.72,
      36.809,
      15.95,
      24.809,
      36.439,
      15.95,
      24.809,
      36.439,
      15.95,
      25.631,
      38.864,
      15.95,
      24.542,
      39.233,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11136",
    "polygon": [
      23.359,
      39.635,
      15.95,
      22.536,
      37.21,
      15.95,
      23.625,
      36.841,
      15.95,
      23.625,
      36.841,
      15.95,
      24.448,
      39.265,
      15.95,
      23.359,
      39.635,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11137",
    "polygon": [
      22.175,
      40.036,
      15.95,
      21.353,
      37.612,
      15.95,
      22.442,
      37.242,
      15.95,
      22.442,
      37.242,
      15.95,
      23.264,
      39.667,
      15.95,
      22.175,
      40.036,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11138",
    "polygon": [
      20.991,
      40.438,
      15.95,
      20.169,
      38.013,
      15.95,
      21.258,
      37.644,
      15.95,
      21.258,
      37.644,
      15.95,
      22.08,
      40.068,
      15.95,
      20.991,
      40.438,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11139",
    "polygon": [
      19.807,
      40.839,
      15.95,
      18.985,
      38.415,
      15.95,
      20.074,
      38.045,
      15.95,
      20.074,
      38.045,
      15.95,
      20.896,
      40.47,
      15.95,
      19.807,
      40.839,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11140",
    "polygon": [
      18.624,
      41.241,
      15.95,
      17.801,
      38.816,
      15.95,
      18.89,
      38.447,
      15.95,
      18.89,
      38.447,
      15.95,
      19.713,
      40.871,
      15.95,
      18.624,
      41.241,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11141",
    "polygon": [
      17.44,
      41.642,
      15.95,
      16.618,
      39.218,
      15.95,
      17.707,
      38.848,
      15.95,
      17.707,
      38.848,
      15.95,
      18.529,
      41.273,
      15.95,
      17.44,
      41.642,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11142",
    "polygon": [
      16.256,
      42.044,
      15.95,
      15.434,
      39.619,
      15.95,
      16.523,
      39.25,
      15.95,
      16.523,
      39.25,
      15.95,
      17.345,
      41.674,
      15.95,
      16.256,
      42.044,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11143",
    "polygon": [
      15.072,
      42.445,
      15.95,
      14.25,
      40.021,
      15.95,
      15.339,
      39.652,
      15.95,
      15.339,
      39.652,
      15.95,
      16.161,
      42.076,
      15.95,
      15.072,
      42.445,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11144",
    "polygon": [
      13.889,
      42.847,
      15.95,
      13.066,
      40.422,
      15.95,
      14.155,
      40.053,
      15.95,
      14.155,
      40.053,
      15.95,
      14.978,
      42.477,
      15.95,
      13.889,
      42.847,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11145",
    "polygon": [
      12.705,
      43.248,
      15.95,
      11.882,
      40.824,
      15.95,
      12.972,
      40.455,
      15.95,
      12.972,
      40.455,
      15.95,
      13.794,
      42.879,
      15.95,
      12.705,
      43.248,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11146",
    "polygon": [
      11.521,
      43.65,
      15.95,
      10.699,
      41.225,
      15.95,
      11.788,
      40.856,
      15.95,
      11.788,
      40.856,
      15.95,
      12.61,
      43.28,
      15.95,
      11.521,
      43.65,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11147",
    "polygon": [
      10.337,
      44.051,
      15.95,
      9.515,
      41.627,
      15.95,
      10.604,
      41.258,
      15.95,
      10.604,
      41.258,
      15.95,
      11.426,
      43.682,
      15.95,
      10.337,
      44.051,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11148",
    "polygon": [
      9.154,
      44.453,
      15.95,
      8.331,
      42.029,
      15.95,
      9.42,
      41.659,
      15.95,
      9.42,
      41.659,
      15.95,
      10.243,
      44.083,
      15.95,
      9.154,
      44.453,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11149",
    "polygon": [
      7.97,
      44.854,
      15.95,
      7.147,
      42.43,
      15.95,
      8.237,
      42.061,
      15.95,
      8.237,
      42.061,
      15.95,
      9.059,
      44.485,
      15.95,
      7.97,
      44.854,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11150",
    "polygon": [
      6.786,
      45.256,
      15.95,
      5.964,
      42.832,
      15.95,
      7.053,
      42.462,
      15.95,
      7.053,
      42.462,
      15.95,
      7.875,
      44.887,
      15.95,
      6.786,
      45.256,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11151",
    "polygon": [
      5.602,
      45.657,
      15.95,
      4.78,
      43.233,
      15.95,
      5.869,
      42.864,
      15.95,
      5.869,
      42.864,
      15.95,
      6.691,
      45.288,
      15.95,
      5.602,
      45.657,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11152",
    "polygon": [
      4.419,
      46.059,
      15.95,
      3.596,
      43.635,
      15.95,
      4.685,
      43.265,
      15.95,
      4.685,
      43.265,
      15.95,
      5.508,
      45.69,
      15.95,
      4.419,
      46.059,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11153",
    "polygon": [
      3.235,
      46.46,
      15.95,
      2.412,
      44.036,
      15.95,
      3.501,
      43.667,
      15.95,
      3.501,
      43.667,
      15.95,
      4.324,
      46.091,
      15.95,
      3.235,
      46.46,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11154",
    "polygon": [
      2.051,
      46.862,
      15.95,
      1.229,
      44.438,
      15.95,
      2.318,
      44.068,
      15.95,
      2.318,
      44.068,
      15.95,
      3.14,
      46.493,
      15.95,
      2.051,
      46.862,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11155",
    "polygon": [
      0.867,
      47.264,
      15.95,
      0.045,
      44.839,
      15.95,
      1.134,
      44.47,
      15.95,
      1.134,
      44.47,
      15.95,
      1.956,
      46.894,
      15.95,
      0.867,
      47.264,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11157",
    "polygon": [
      -0.317,
      47.665,
      15.95,
      -1.139,
      45.241,
      15.95,
      -0.05,
      44.871,
      15.95,
      -0.05,
      44.871,
      15.95,
      0.773,
      47.296,
      15.95,
      -0.317,
      47.665,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11158",
    "polygon": [
      -1.5,
      48.067,
      15.95,
      -2.323,
      45.642,
      15.95,
      -1.234,
      45.273,
      15.95,
      -1.234,
      45.273,
      15.95,
      -0.411,
      47.697,
      15.95,
      -1.5,
      48.067,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11159",
    "polygon": [
      -2.684,
      48.468,
      15.95,
      -3.506,
      46.044,
      15.95,
      -2.417,
      45.674,
      15.95,
      -2.417,
      45.674,
      15.95,
      -1.595,
      48.099,
      15.95,
      -2.684,
      48.468,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11160",
    "polygon": [
      -3.868,
      48.87,
      15.95,
      -4.69,
      46.445,
      15.95,
      -3.601,
      46.076,
      15.95,
      -3.601,
      46.076,
      15.95,
      -2.779,
      48.5,
      15.95,
      -3.868,
      48.87,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11161",
    "polygon": [
      -5.052,
      49.271,
      15.95,
      -5.874,
      46.847,
      15.95,
      -4.785,
      46.477,
      15.95,
      -4.785,
      46.477,
      15.95,
      -3.962,
      48.902,
      15.95,
      -5.052,
      49.271,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11162",
    "polygon": [
      -6.235,
      49.673,
      15.95,
      -7.058,
      47.248,
      15.95,
      -5.969,
      46.879,
      15.95,
      -5.969,
      46.879,
      15.95,
      -5.146,
      49.303,
      15.95,
      -6.235,
      49.673,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11163",
    "polygon": [
      -7.419,
      50.074,
      15.95,
      -8.241,
      47.65,
      15.95,
      -7.152,
      47.28,
      15.95,
      -7.152,
      47.28,
      15.95,
      -6.33,
      49.705,
      15.95,
      -7.419,
      50.074,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11164",
    "polygon": [
      -8.603,
      50.476,
      15.95,
      -9.425,
      48.051,
      15.95,
      -8.336,
      47.682,
      15.95,
      -8.336,
      47.682,
      15.95,
      -7.514,
      50.106,
      15.95,
      -8.603,
      50.476,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11165",
    "polygon": [
      -9.787,
      50.877,
      15.95,
      -10.609,
      48.453,
      15.95,
      -9.52,
      48.084,
      15.95,
      -9.52,
      48.084,
      15.95,
      -8.697,
      50.508,
      15.95,
      -9.787,
      50.877,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11166",
    "polygon": [
      -10.97,
      51.279,
      15.95,
      -11.793,
      48.854,
      15.95,
      -10.704,
      48.485,
      15.95,
      -10.704,
      48.485,
      15.95,
      -9.881,
      50.909,
      15.95,
      -10.97,
      51.279,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11167",
    "polygon": [
      -12.154,
      51.68,
      15.95,
      -12.976,
      49.256,
      15.95,
      -11.887,
      48.887,
      15.95,
      -11.887,
      48.887,
      15.95,
      -11.065,
      51.311,
      15.95,
      -12.154,
      51.68,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11168",
    "polygon": [
      -13.338,
      52.082,
      15.95,
      -14.16,
      49.658,
      15.95,
      -13.071,
      49.288,
      15.95,
      -13.071,
      49.288,
      15.95,
      -12.249,
      51.712,
      15.95,
      -13.338,
      52.082,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11169",
    "polygon": [
      -14.522,
      52.483,
      15.95,
      -15.344,
      50.059,
      15.95,
      -14.255,
      49.69,
      15.95,
      -14.255,
      49.69,
      15.95,
      -13.433,
      52.114,
      15.95,
      -14.522,
      52.483,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11170",
    "polygon": [
      -15.705,
      52.885,
      15.95,
      -16.528,
      50.461,
      15.95,
      -15.439,
      50.091,
      15.95,
      -15.439,
      50.091,
      15.95,
      -14.616,
      52.515,
      15.95,
      -15.705,
      52.885,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11171",
    "polygon": [
      -16.889,
      53.286,
      15.95,
      -17.711,
      50.862,
      15.95,
      -16.622,
      50.493,
      15.95,
      -16.622,
      50.493,
      15.95,
      -15.8,
      52.917,
      15.95,
      -16.889,
      53.286,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11172",
    "polygon": [
      -18.073,
      53.688,
      15.95,
      -18.895,
      51.264,
      15.95,
      -17.806,
      50.894,
      15.95,
      -17.806,
      50.894,
      15.95,
      -16.984,
      53.319,
      15.95,
      -18.073,
      53.688,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11173",
    "polygon": [
      -19.28,
      54.097,
      15.95,
      -20.102,
      51.673,
      15.95,
      -19.013,
      51.304,
      15.95,
      -19.013,
      51.304,
      15.95,
      -18.191,
      53.728,
      15.95,
      -19.28,
      54.097,
      15.95
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11174",
    "polygon": [
      58.38,
      37.899,
      15.95,
      57.558,
      35.475,
      15.95,
      58.154,
      35.273,
      15.95,
      58.154,
      35.273,
      15.95,
      58.977,
      37.697,
      15.95,
      58.38,
      37.899,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11175",
    "polygon": [
      52.48,
      39.9,
      15.95,
      51.658,
      37.476,
      15.95,
      52.747,
      37.107,
      15.95,
      52.747,
      37.107,
      15.95,
      53.569,
      39.531,
      15.95,
      52.48,
      39.9,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_DA_11176",
    "polygon": [
      -41.761,
      71.863,
      15.95,
      -42.583,
      69.439,
      15.95,
      -41.987,
      69.236,
      15.95,
      -41.987,
      69.236,
      15.95,
      -41.164,
      71.661,
      15.95,
      -41.761,
      71.863,
      15.95
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_EG_8395",
    "polygon": [
      41.848,
      24.782,
      4.262,
      41.848,
      24.782,
      4.962,
      40.236,
      25.321,
      4.962,
      40.236,
      25.321,
      4.962,
      40.236,
      25.321,
      4.262,
      41.848,
      24.782,
      4.262
    ]
  },
  {
    "ID": "Magistrale_EG_8437",
    "polygon": [
      43.46,
      24.241,
      4.262,
      43.46,
      24.241,
      4.962,
      41.848,
      24.781,
      4.962,
      41.848,
      24.781,
      4.962,
      41.848,
      24.781,
      4.262,
      43.46,
      24.241,
      4.262
    ]
  },
  {
    "ID": "Magistrale_EG_8519",
    "polygon": [
      46.681,
      23.162,
      4.262,
      46.681,
      23.162,
      4.962,
      45.069,
      23.702,
      4.962,
      45.069,
      23.702,
      4.962,
      45.069,
      23.702,
      4.262,
      46.681,
      23.162,
      4.262
    ]
  },
  {
    "ID": "Magistrale_EG_8560",
    "polygon": [
      48.292,
      22.623,
      4.262,
      48.292,
      22.623,
      4.962,
      46.68,
      23.163,
      4.962,
      46.68,
      23.163,
      4.962,
      46.68,
      23.163,
      4.262,
      48.292,
      22.623,
      4.262
    ]
  },
  {
    "ID": "Magistrale_EG_8601",
    "polygon": [
      49.904,
      22.083,
      4.262,
      49.904,
      22.083,
      4.962,
      48.292,
      22.623,
      4.962,
      48.292,
      22.623,
      4.962,
      48.292,
      22.623,
      4.262,
      49.904,
      22.083,
      4.262
    ]
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8632",
    "polygon": [
      63.26,
      9.589,
      13.073,
      63.26,
      9.589,
      15.523,
      62.549,
      9.827,
      15.523,
      62.549,
      9.827,
      15.523,
      62.549,
      9.827,
      13.073,
      63.26,
      9.589,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8633",
    "polygon": [
      64.787,
      9.077,
      13.073,
      64.787,
      9.077,
      15.523,
      63.26,
      9.589,
      15.523,
      63.26,
      9.589,
      15.523,
      63.26,
      9.589,
      13.073,
      64.787,
      9.077,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8634",
    "polygon": [
      65.379,
      8.879,
      13.073,
      65.379,
      8.879,
      15.523,
      64.787,
      9.077,
      15.523,
      64.787,
      9.077,
      15.523,
      64.787,
      9.077,
      13.073,
      65.379,
      8.879,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8635",
    "polygon": [
      66.091,
      8.641,
      13.073,
      66.091,
      8.641,
      15.523,
      65.379,
      8.879,
      15.523,
      65.379,
      8.879,
      15.523,
      65.379,
      8.879,
      13.073,
      66.091,
      8.641,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8636",
    "polygon": [
      67.617,
      8.129,
      13.073,
      67.617,
      8.129,
      15.523,
      66.091,
      8.641,
      15.523,
      66.091,
      8.641,
      15.523,
      66.091,
      8.641,
      13.073,
      67.617,
      8.129,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8637",
    "polygon": [
      69.186,
      7.603,
      13.073,
      69.186,
      7.603,
      15.523,
      68.475,
      7.842,
      15.523,
      68.475,
      7.842,
      15.523,
      68.475,
      7.842,
      13.073,
      69.186,
      7.603,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8638",
    "polygon": [
      70.713,
      7.092,
      13.073,
      70.713,
      7.092,
      15.523,
      69.186,
      7.603,
      15.523,
      69.186,
      7.603,
      15.523,
      69.186,
      7.603,
      13.073,
      70.713,
      7.092,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8639",
    "polygon": [
      71.306,
      6.893,
      13.073,
      71.306,
      6.893,
      15.523,
      70.713,
      7.092,
      15.523,
      70.713,
      7.092,
      15.523,
      70.713,
      7.092,
      13.073,
      71.306,
      6.893,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8640",
    "polygon": [
      72.017,
      6.655,
      13.073,
      72.017,
      6.655,
      15.523,
      71.306,
      6.893,
      15.523,
      71.306,
      6.893,
      15.523,
      71.306,
      6.893,
      13.073,
      72.017,
      6.655,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8641",
    "polygon": [
      73.543,
      6.144,
      13.073,
      73.543,
      6.144,
      15.523,
      72.017,
      6.655,
      15.523,
      72.017,
      6.655,
      15.523,
      72.017,
      6.655,
      13.073,
      73.543,
      6.144,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8642",
    "polygon": [
      75.113,
      5.618,
      13.073,
      75.113,
      5.618,
      15.523,
      74.402,
      5.856,
      15.523,
      74.402,
      5.856,
      15.523,
      74.402,
      5.856,
      13.073,
      75.113,
      5.618,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8643",
    "polygon": [
      76.639,
      5.107,
      13.073,
      76.639,
      5.107,
      15.523,
      75.113,
      5.618,
      15.523,
      75.113,
      5.618,
      15.523,
      75.113,
      5.618,
      13.073,
      76.639,
      5.107,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8644",
    "polygon": [
      77.232,
      4.908,
      13.073,
      77.232,
      4.908,
      15.523,
      76.639,
      5.107,
      15.523,
      76.639,
      5.107,
      15.523,
      76.639,
      5.107,
      13.073,
      77.232,
      4.908,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8645",
    "polygon": [
      77.943,
      4.67,
      13.073,
      77.943,
      4.67,
      15.523,
      77.232,
      4.908,
      15.523,
      77.232,
      4.908,
      15.523,
      77.232,
      4.908,
      13.073,
      77.943,
      4.67,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8646",
    "polygon": [
      79.47,
      4.159,
      13.073,
      79.47,
      4.159,
      15.523,
      77.943,
      4.67,
      15.523,
      77.943,
      4.67,
      15.523,
      77.943,
      4.67,
      13.073,
      79.47,
      4.159,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8647",
    "polygon": [
      81.039,
      3.633,
      13.073,
      81.039,
      3.633,
      15.523,
      80.328,
      3.871,
      15.523,
      80.328,
      3.871,
      15.523,
      80.328,
      3.871,
      13.073,
      81.039,
      3.633,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8648",
    "polygon": [
      82.566,
      3.122,
      13.073,
      82.566,
      3.122,
      15.523,
      81.039,
      3.633,
      15.523,
      81.039,
      3.633,
      15.523,
      81.039,
      3.633,
      13.073,
      82.566,
      3.122,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8649",
    "polygon": [
      83.158,
      2.923,
      13.073,
      83.158,
      2.923,
      15.523,
      82.566,
      3.122,
      15.523,
      82.566,
      3.122,
      15.523,
      82.566,
      3.122,
      13.073,
      83.158,
      2.923,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8650",
    "polygon": [
      83.87,
      2.685,
      13.073,
      83.87,
      2.685,
      15.523,
      83.158,
      2.923,
      15.523,
      83.158,
      2.923,
      15.523,
      83.158,
      2.923,
      13.073,
      83.87,
      2.685,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8652",
    "polygon": [
      85.114,
      2.268,
      13.073,
      85.114,
      2.268,
      15.523,
      83.87,
      2.685,
      15.523,
      83.87,
      2.685,
      15.523,
      83.87,
      2.685,
      13.073,
      85.114,
      2.268,
      13.073
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8653",
    "polygon": [
      63.261,
      9.59,
      9.323,
      63.261,
      9.59,
      11.773,
      62.549,
      9.828,
      11.773,
      62.549,
      9.828,
      11.773,
      62.549,
      9.828,
      9.323,
      63.261,
      9.59,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8654",
    "polygon": [
      64.787,
      9.079,
      9.323,
      64.787,
      9.079,
      11.773,
      63.261,
      9.59,
      11.773,
      63.261,
      9.59,
      11.773,
      63.261,
      9.59,
      9.323,
      64.787,
      9.079,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8655",
    "polygon": [
      65.38,
      8.88,
      9.323,
      65.38,
      8.88,
      11.773,
      64.787,
      9.079,
      11.773,
      64.787,
      9.079,
      11.773,
      64.787,
      9.079,
      9.323,
      65.38,
      8.88,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8656",
    "polygon": [
      66.091,
      8.642,
      9.323,
      66.091,
      8.642,
      11.773,
      65.38,
      8.88,
      11.773,
      65.38,
      8.88,
      11.773,
      65.38,
      8.88,
      9.323,
      66.091,
      8.642,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8657",
    "polygon": [
      67.618,
      8.131,
      9.323,
      67.618,
      8.131,
      11.773,
      66.091,
      8.642,
      11.773,
      66.091,
      8.642,
      11.773,
      66.091,
      8.642,
      9.323,
      67.618,
      8.131,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8658",
    "polygon": [
      69.187,
      7.605,
      9.323,
      69.187,
      7.605,
      11.773,
      68.476,
      7.843,
      11.773,
      68.476,
      7.843,
      11.773,
      68.476,
      7.843,
      9.323,
      69.187,
      7.605,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8659",
    "polygon": [
      70.714,
      7.094,
      9.323,
      70.714,
      7.094,
      11.773,
      69.187,
      7.605,
      11.773,
      69.187,
      7.605,
      11.773,
      69.187,
      7.605,
      9.323,
      70.714,
      7.094,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8660",
    "polygon": [
      71.306,
      6.895,
      9.323,
      71.306,
      6.895,
      11.773,
      70.714,
      7.094,
      11.773,
      70.714,
      7.094,
      11.773,
      70.714,
      7.094,
      9.323,
      71.306,
      6.895,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8661",
    "polygon": [
      72.017,
      6.657,
      9.323,
      72.017,
      6.657,
      11.773,
      71.306,
      6.895,
      11.773,
      71.306,
      6.895,
      11.773,
      71.306,
      6.895,
      9.323,
      72.017,
      6.657,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8662",
    "polygon": [
      73.544,
      6.145,
      9.323,
      73.544,
      6.145,
      11.773,
      72.017,
      6.657,
      11.773,
      72.017,
      6.657,
      11.773,
      72.017,
      6.657,
      9.323,
      73.544,
      6.145,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8663",
    "polygon": [
      75.113,
      5.62,
      9.323,
      75.113,
      5.62,
      11.773,
      74.402,
      5.858,
      11.773,
      74.402,
      5.858,
      11.773,
      74.402,
      5.858,
      9.323,
      75.113,
      5.62,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8664",
    "polygon": [
      76.64,
      5.108,
      9.323,
      76.64,
      5.108,
      11.773,
      75.113,
      5.62,
      11.773,
      75.113,
      5.62,
      11.773,
      75.113,
      5.62,
      9.323,
      76.64,
      5.108,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8665",
    "polygon": [
      77.233,
      4.91,
      9.323,
      77.233,
      4.91,
      11.773,
      76.64,
      5.108,
      11.773,
      76.64,
      5.108,
      11.773,
      76.64,
      5.108,
      9.323,
      77.233,
      4.91,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8666",
    "polygon": [
      77.944,
      4.672,
      9.323,
      77.944,
      4.672,
      11.773,
      77.233,
      4.91,
      11.773,
      77.233,
      4.91,
      11.773,
      77.233,
      4.91,
      9.323,
      77.944,
      4.672,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8667",
    "polygon": [
      79.47,
      4.16,
      9.323,
      79.47,
      4.16,
      11.773,
      77.944,
      4.672,
      11.773,
      77.944,
      4.672,
      11.773,
      77.944,
      4.672,
      9.323,
      79.47,
      4.16,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8668",
    "polygon": [
      81.04,
      3.634,
      9.323,
      81.04,
      3.634,
      11.773,
      80.328,
      3.873,
      11.773,
      80.328,
      3.873,
      11.773,
      80.328,
      3.873,
      9.323,
      81.04,
      3.634,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8669",
    "polygon": [
      82.566,
      3.123,
      9.323,
      82.566,
      3.123,
      11.773,
      81.04,
      3.634,
      11.773,
      81.04,
      3.634,
      11.773,
      81.04,
      3.634,
      9.323,
      82.566,
      3.123,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8670",
    "polygon": [
      83.159,
      2.925,
      9.323,
      83.159,
      2.925,
      11.773,
      82.566,
      3.123,
      11.773,
      82.566,
      3.123,
      11.773,
      82.566,
      3.123,
      9.323,
      83.159,
      2.925,
      9.323
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8671",
    "polygon": [
      83.87,
      2.686,
      9.323,
      83.87,
      2.686,
      11.773,
      83.159,
      2.925,
      11.773,
      83.159,
      2.925,
      11.773,
      83.159,
      2.925,
      9.323,
      83.87,
      2.686,
      9.323
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8672",
    "polygon": [
      85.114,
      2.27,
      9.323,
      85.114,
      2.27,
      11.773,
      83.87,
      2.686,
      11.773,
      83.87,
      2.686,
      11.773,
      83.87,
      2.686,
      9.323,
      85.114,
      2.27,
      9.323
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8673",
    "polygon": [
      63.261,
      9.59,
      5.573,
      63.261,
      9.59,
      8.023,
      62.549,
      9.828,
      8.023,
      62.549,
      9.828,
      8.023,
      62.549,
      9.828,
      5.573,
      63.261,
      9.59,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8674",
    "polygon": [
      64.787,
      9.079,
      5.573,
      64.787,
      9.079,
      8.023,
      63.261,
      9.59,
      8.023,
      63.261,
      9.59,
      8.023,
      63.261,
      9.59,
      5.573,
      64.787,
      9.079,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8675",
    "polygon": [
      65.38,
      8.88,
      5.573,
      65.38,
      8.88,
      8.023,
      64.787,
      9.079,
      8.023,
      64.787,
      9.079,
      8.023,
      64.787,
      9.079,
      5.573,
      65.38,
      8.88,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8676",
    "polygon": [
      66.091,
      8.642,
      5.573,
      66.091,
      8.642,
      8.023,
      65.38,
      8.88,
      8.023,
      65.38,
      8.88,
      8.023,
      65.38,
      8.88,
      5.573,
      66.091,
      8.642,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8677",
    "polygon": [
      67.618,
      8.131,
      5.573,
      67.618,
      8.131,
      8.023,
      66.091,
      8.642,
      8.023,
      66.091,
      8.642,
      8.023,
      66.091,
      8.642,
      5.573,
      67.618,
      8.131,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8678",
    "polygon": [
      72.017,
      6.657,
      5.573,
      72.017,
      6.657,
      8.023,
      71.306,
      6.895,
      8.023,
      71.306,
      6.895,
      8.023,
      71.306,
      6.895,
      5.573,
      72.017,
      6.657,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8679",
    "polygon": [
      73.544,
      6.145,
      5.573,
      73.544,
      6.145,
      8.023,
      72.017,
      6.657,
      8.023,
      72.017,
      6.657,
      8.023,
      72.017,
      6.657,
      5.573,
      73.544,
      6.145,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8680",
    "polygon": [
      75.113,
      5.62,
      5.573,
      75.113,
      5.62,
      8.023,
      74.402,
      5.858,
      8.023,
      74.402,
      5.858,
      8.023,
      74.402,
      5.858,
      5.573,
      75.113,
      5.62,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8681",
    "polygon": [
      76.64,
      5.108,
      5.573,
      76.64,
      5.108,
      8.023,
      75.113,
      5.62,
      8.023,
      75.113,
      5.62,
      8.023,
      75.113,
      5.62,
      5.573,
      76.64,
      5.108,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8682",
    "polygon": [
      77.233,
      4.91,
      5.573,
      77.233,
      4.91,
      8.023,
      76.64,
      5.108,
      8.023,
      76.64,
      5.108,
      8.023,
      76.64,
      5.108,
      5.573,
      77.233,
      4.91,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8683",
    "polygon": [
      77.944,
      4.672,
      5.573,
      77.944,
      4.672,
      8.023,
      77.233,
      4.91,
      8.023,
      77.233,
      4.91,
      8.023,
      77.233,
      4.91,
      5.573,
      77.944,
      4.672,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8684",
    "polygon": [
      79.47,
      4.16,
      5.573,
      79.47,
      4.16,
      8.023,
      77.944,
      4.672,
      8.023,
      77.944,
      4.672,
      8.023,
      77.944,
      4.672,
      5.573,
      79.47,
      4.16,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8685",
    "polygon": [
      81.04,
      3.634,
      5.573,
      81.04,
      3.634,
      8.023,
      80.328,
      3.873,
      8.023,
      80.328,
      3.873,
      8.023,
      80.328,
      3.873,
      5.573,
      81.04,
      3.634,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8686",
    "polygon": [
      82.566,
      3.123,
      5.573,
      82.566,
      3.123,
      8.023,
      81.04,
      3.634,
      8.023,
      81.04,
      3.634,
      8.023,
      81.04,
      3.634,
      5.573,
      82.566,
      3.123,
      5.573
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8687",
    "polygon": [
      83.159,
      2.925,
      5.573,
      83.159,
      2.925,
      8.023,
      82.566,
      3.123,
      8.023,
      82.566,
      3.123,
      8.023,
      82.566,
      3.123,
      5.573,
      83.159,
      2.925,
      5.573
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8688",
    "polygon": [
      83.87,
      2.686,
      5.573,
      83.87,
      2.686,
      8.023,
      83.159,
      2.925,
      8.023,
      83.159,
      2.925,
      8.023,
      83.159,
      2.925,
      5.573,
      83.87,
      2.686,
      5.573
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8689",
    "polygon": [
      85.114,
      2.27,
      5.573,
      85.114,
      2.27,
      8.023,
      83.87,
      2.686,
      8.023,
      83.87,
      2.686,
      8.023,
      83.87,
      2.686,
      5.573,
      85.114,
      2.27,
      5.573
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8690",
    "polygon": [
      70.648,
      7.116,
      7.326,
      70.648,
      7.116,
      8.023,
      68.496,
      7.837,
      8.023,
      68.496,
      7.837,
      8.023,
      68.496,
      7.837,
      7.326,
      70.648,
      7.116,
      7.326
    ]
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8691",
    "polygon": [
      69.572,
      7.476,
      5.0,
      69.572,
      7.476,
      7.326,
      68.496,
      7.836,
      7.326,
      68.496,
      7.836,
      7.326,
      68.496,
      7.836,
      5.0,
      69.572,
      7.476,
      5.0
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8692",
    "polygon": [
      70.649,
      7.115,
      5.0,
      70.649,
      7.115,
      7.326,
      69.572,
      7.476,
      7.326,
      69.572,
      7.476,
      7.326,
      69.572,
      7.476,
      5.0,
      70.649,
      7.115,
      5.0
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_8693",
    "polygon": [
      -50.331,
      75.411,
      9.323,
      -50.331,
      75.411,
      11.773,
      -51.042,
      75.65,
      11.773,
      -51.042,
      75.65,
      11.773,
      -51.042,
      75.65,
      9.323,
      -50.331,
      75.411,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_8694",
    "polygon": [
      -48.805,
      74.9,
      9.323,
      -48.805,
      74.9,
      11.773,
      -50.331,
      75.411,
      11.773,
      -50.331,
      75.411,
      11.773,
      -50.331,
      75.411,
      9.323,
      -48.805,
      74.9,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_8695",
    "polygon": [
      -48.212,
      74.702,
      9.323,
      -48.212,
      74.702,
      11.773,
      -48.805,
      74.9,
      11.773,
      -48.805,
      74.9,
      11.773,
      -48.805,
      74.9,
      9.323,
      -48.212,
      74.702,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_8696",
    "polygon": [
      -47.501,
      74.463,
      9.323,
      -47.501,
      74.463,
      11.773,
      -48.212,
      74.702,
      11.773,
      -48.212,
      74.702,
      11.773,
      -48.212,
      74.702,
      9.323,
      -47.501,
      74.463,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_8697",
    "polygon": [
      -46.382,
      74.088,
      9.323,
      -46.382,
      74.088,
      11.773,
      -47.501,
      74.463,
      11.773,
      -47.501,
      74.463,
      11.773,
      -47.501,
      74.463,
      9.323,
      -46.382,
      74.088,
      9.323
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_8698",
    "polygon": [
      -50.33,
      75.415,
      13.073,
      -50.33,
      75.415,
      15.523,
      -51.041,
      75.653,
      15.523,
      -51.041,
      75.653,
      15.523,
      -51.041,
      75.653,
      13.073,
      -50.33,
      75.415,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_8699",
    "polygon": [
      -48.803,
      74.904,
      13.073,
      -48.803,
      74.904,
      15.523,
      -50.33,
      75.415,
      15.523,
      -50.33,
      75.415,
      15.523,
      -50.33,
      75.415,
      13.073,
      -48.803,
      74.904,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_8700",
    "polygon": [
      -48.211,
      74.705,
      13.073,
      -48.211,
      74.705,
      15.523,
      -48.803,
      74.904,
      15.523,
      -48.803,
      74.904,
      15.523,
      -48.803,
      74.904,
      13.073,
      -48.211,
      74.705,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_8701",
    "polygon": [
      -47.5,
      74.467,
      13.073,
      -47.5,
      74.467,
      15.523,
      -48.211,
      74.705,
      15.523,
      -48.211,
      74.705,
      15.523,
      -48.211,
      74.705,
      13.073,
      -47.5,
      74.467,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_8702",
    "polygon": [
      -46.381,
      74.092,
      13.073,
      -46.381,
      74.092,
      15.523,
      -47.5,
      74.467,
      15.523,
      -47.5,
      74.467,
      15.523,
      -47.5,
      74.467,
      13.073,
      -46.381,
      74.092,
      13.073
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_8703",
    "polygon": [
      -50.33,
      75.415,
      5.573,
      -50.33,
      75.415,
      8.023,
      -51.041,
      75.653,
      8.023,
      -51.041,
      75.653,
      8.023,
      -51.041,
      75.653,
      5.573,
      -50.33,
      75.415,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_8704",
    "polygon": [
      -48.803,
      74.903,
      5.573,
      -48.803,
      74.903,
      8.023,
      -50.33,
      75.415,
      8.023,
      -50.33,
      75.415,
      8.023,
      -50.33,
      75.415,
      5.573,
      -48.803,
      74.903,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_8705",
    "polygon": [
      -48.211,
      74.705,
      5.573,
      -48.211,
      74.705,
      8.023,
      -48.803,
      74.903,
      8.023,
      -48.803,
      74.903,
      8.023,
      -48.803,
      74.903,
      5.573,
      -48.211,
      74.705,
      5.573
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_8706",
    "polygon": [
      -47.5,
      74.467,
      5.573,
      -47.5,
      74.467,
      8.023,
      -48.211,
      74.705,
      8.023,
      -48.211,
      74.705,
      8.023,
      -48.211,
      74.705,
      5.573,
      -47.5,
      74.467,
      5.573
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_8707",
    "polygon": [
      -46.381,
      74.092,
      5.573,
      -46.381,
      74.092,
      8.023,
      -47.5,
      74.467,
      8.023,
      -47.5,
      74.467,
      8.023,
      -47.5,
      74.467,
      5.573,
      -46.381,
      74.092,
      5.573
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_8709",
    "polygon": [
      -50.33,
      75.415,
      0.745,
      -50.33,
      75.415,
      3.455,
      -51.041,
      75.653,
      3.455,
      -51.041,
      75.653,
      3.455,
      -51.041,
      75.653,
      0.745,
      -50.33,
      75.415,
      0.745
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_8710",
    "polygon": [
      -48.803,
      74.903,
      0.745,
      -48.803,
      74.903,
      3.455,
      -50.33,
      75.415,
      3.455,
      -50.33,
      75.415,
      3.455,
      -50.33,
      75.415,
      0.745,
      -48.803,
      74.903,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_8711",
    "polygon": [
      -48.211,
      74.705,
      0.745,
      -48.211,
      74.705,
      3.455,
      -48.803,
      74.903,
      3.455,
      -48.803,
      74.903,
      3.455,
      -48.803,
      74.903,
      0.745,
      -48.211,
      74.705,
      0.745
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_8712",
    "polygon": [
      -47.5,
      74.467,
      0.745,
      -47.5,
      74.467,
      3.455,
      -48.211,
      74.705,
      3.455,
      -48.211,
      74.705,
      3.455,
      -48.211,
      74.705,
      0.745,
      -47.5,
      74.467,
      0.745
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_8713",
    "polygon": [
      -46.381,
      74.092,
      0.745,
      -46.381,
      74.092,
      3.455,
      -47.5,
      74.467,
      3.455,
      -47.5,
      74.467,
      3.455,
      -47.5,
      74.467,
      0.745,
      -46.381,
      74.092,
      0.745
    ],
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_8714",
    "polygon": [
      66.291,
      36.385,
      9.323,
      66.291,
      36.385,
      11.773,
      64.764,
      36.896,
      11.773,
      64.764,
      36.896,
      11.773,
      64.764,
      36.896,
      9.323,
      66.291,
      36.385,
      9.323
    ]
  },
  {
    "ID": "Block-B-Outer_2.OG_8715",
    "polygon": [
      66.883,
      36.186,
      9.323,
      66.883,
      36.186,
      11.773,
      66.291,
      36.385,
      11.773,
      66.291,
      36.385,
      11.773,
      66.291,
      36.385,
      9.323,
      66.883,
      36.186,
      9.323
    ]
  },
  {
    "ID": "Block-B-Outer_2.OG_8716",
    "polygon": [
      67.594,
      35.948,
      9.323,
      67.594,
      35.948,
      11.773,
      66.883,
      36.186,
      11.773,
      66.883,
      36.186,
      11.773,
      66.883,
      36.186,
      9.323,
      67.594,
      35.948,
      9.323
    ]
  },
  {
    "ID": "Block-B-Outer_3.OG_8717",
    "polygon": [
      66.291,
      36.385,
      13.073,
      66.291,
      36.385,
      15.523,
      64.764,
      36.896,
      15.523,
      64.764,
      36.896,
      15.523,
      64.764,
      36.896,
      13.073,
      66.291,
      36.385,
      13.073
    ]
  },
  {
    "ID": "Block-B-Outer_3.OG_8718",
    "polygon": [
      66.883,
      36.186,
      13.073,
      66.883,
      36.186,
      15.523,
      66.291,
      36.385,
      15.523,
      66.291,
      36.385,
      15.523,
      66.291,
      36.385,
      13.073,
      66.883,
      36.186,
      13.073
    ]
  },
  {
    "ID": "Block-B-Outer_3.OG_8719",
    "polygon": [
      67.594,
      35.948,
      13.073,
      67.594,
      35.948,
      15.523,
      66.883,
      36.186,
      15.523,
      66.883,
      36.186,
      15.523,
      66.883,
      36.186,
      13.073,
      67.594,
      35.948,
      13.073
    ]
  },
  {
    "ID": "Block-B-Outer_1.OG_8720",
    "polygon": [
      66.292,
      36.389,
      5.573,
      66.292,
      36.389,
      8.023,
      64.765,
      36.9,
      8.023,
      64.765,
      36.9,
      8.023,
      64.765,
      36.9,
      5.573,
      66.292,
      36.389,
      5.573
    ]
  },
  {
    "ID": "Block-B-Outer_1.OG_8721",
    "polygon": [
      66.884,
      36.19,
      5.573,
      66.884,
      36.19,
      8.023,
      66.292,
      36.389,
      8.023,
      66.292,
      36.389,
      8.023,
      66.292,
      36.389,
      5.573,
      66.884,
      36.19,
      5.573
    ]
  },
  {
    "ID": "Block-B-Outer_1.OG_8722",
    "polygon": [
      67.596,
      35.952,
      5.573,
      67.596,
      35.952,
      8.023,
      66.884,
      36.19,
      8.023,
      66.884,
      36.19,
      8.023,
      66.884,
      36.19,
      5.573,
      67.596,
      35.952,
      5.573
    ]
  },
  {
    "ID": "Block-B-Outer_2.OG_8723",
    "polygon": [
      69.121,
      35.437,
      9.323,
      69.121,
      35.437,
      11.773,
      67.594,
      35.948,
      11.773,
      67.594,
      35.948,
      11.773,
      67.594,
      35.948,
      9.323,
      69.121,
      35.437,
      9.323
    ]
  },
  {
    "ID": "Block-B-Outer_1.OG_8724",
    "polygon": [
      69.122,
      35.44,
      5.573,
      69.122,
      35.44,
      8.023,
      67.596,
      35.952,
      8.023,
      67.596,
      35.952,
      8.023,
      67.596,
      35.952,
      5.573,
      69.122,
      35.44,
      5.573
    ]
  },
  {
    "ID": "Block-B-Outer_3.OG_8725",
    "polygon": [
      69.121,
      35.437,
      13.073,
      69.121,
      35.437,
      15.523,
      67.594,
      35.948,
      15.523,
      67.594,
      35.948,
      15.523,
      67.594,
      35.948,
      13.073,
      69.121,
      35.437,
      13.073
    ]
  },
  {
    "ID": "Block-B-Outer_EG_8727",
    "polygon": [
      66.291,
      36.385,
      0.745,
      66.291,
      36.385,
      3.455,
      64.764,
      36.896,
      3.455,
      64.764,
      36.896,
      3.455,
      64.764,
      36.896,
      0.745,
      66.291,
      36.385,
      0.745
    ]
  },
  {
    "ID": "Block-B-Outer_EG_8728",
    "polygon": [
      66.883,
      36.186,
      0.745,
      66.883,
      36.186,
      3.455,
      66.291,
      36.385,
      3.455,
      66.291,
      36.385,
      3.455,
      66.291,
      36.385,
      0.745,
      66.883,
      36.186,
      0.745
    ]
  },
  {
    "ID": "Block-B-Outer_EG_8729",
    "polygon": [
      67.594,
      35.948,
      0.745,
      67.594,
      35.948,
      3.455,
      66.883,
      36.186,
      3.455,
      66.883,
      36.186,
      3.455,
      66.883,
      36.186,
      0.745,
      67.594,
      35.948,
      0.745
    ]
  },
  {
    "ID": "Block-B-Outer_EG_8730",
    "polygon": [
      69.121,
      35.437,
      0.745,
      69.121,
      35.437,
      3.455,
      67.594,
      35.948,
      3.455,
      67.594,
      35.948,
      3.455,
      67.594,
      35.948,
      0.745,
      69.121,
      35.437,
      0.745
    ]
  },
  {
    "ID": "Magistrale_3.OG_8731",
    "polygon": [
      18.621,
      58.452,
      13.073,
      18.621,
      58.452,
      16.073,
      20.414,
      57.852,
      16.073,
      20.414,
      57.852,
      16.073,
      20.414,
      57.852,
      13.073,
      18.621,
      58.452,
      13.073
    ]
  },
  {
    "ID": "Magistrale_3.OG_8732",
    "polygon": [
      16.829,
      59.053,
      13.073,
      16.829,
      59.053,
      16.073,
      18.621,
      58.452,
      16.073,
      18.621,
      58.452,
      16.073,
      18.621,
      58.452,
      13.073,
      16.829,
      59.053,
      13.073
    ]
  },
  {
    "ID": "Magistrale_3.OG_8733",
    "polygon": [
      15.037,
      59.653,
      13.073,
      15.037,
      59.653,
      16.073,
      16.829,
      59.053,
      16.073,
      16.829,
      59.053,
      16.073,
      16.829,
      59.053,
      13.073,
      15.037,
      59.653,
      13.073
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_3.OG_8734",
    "polygon": [
      13.245,
      60.253,
      13.073,
      13.245,
      60.253,
      16.073,
      15.037,
      59.653,
      16.073,
      15.037,
      59.653,
      16.073,
      15.037,
      59.653,
      13.073,
      13.245,
      60.253,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_3.OG_8735",
    "polygon": [
      11.453,
      60.854,
      13.073,
      11.453,
      60.854,
      16.073,
      13.245,
      60.253,
      16.073,
      13.245,
      60.253,
      16.073,
      13.245,
      60.253,
      13.073,
      11.453,
      60.854,
      13.073
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_2.OG_8736",
    "polygon": [
      20.413,
      57.851,
      9.323,
      20.413,
      57.851,
      12.323,
      22.205,
      57.25,
      12.323,
      22.205,
      57.25,
      12.323,
      22.205,
      57.25,
      9.323,
      20.413,
      57.851,
      9.323
    ]
  },
  {
    "ID": "Magistrale_2.OG_8737",
    "polygon": [
      18.621,
      58.451,
      9.323,
      18.621,
      58.451,
      12.323,
      20.413,
      57.851,
      12.323,
      20.413,
      57.851,
      12.323,
      20.413,
      57.851,
      9.323,
      18.621,
      58.451,
      9.323
    ]
  },
  {
    "ID": "Magistrale_2.OG_8738",
    "polygon": [
      16.829,
      59.051,
      9.323,
      16.829,
      59.051,
      12.323,
      18.621,
      58.451,
      12.323,
      18.621,
      58.451,
      12.323,
      18.621,
      58.451,
      9.323,
      16.829,
      59.051,
      9.323
    ]
  },
  {
    "ID": "Magistrale_2.OG_8739",
    "polygon": [
      15.037,
      59.652,
      9.323,
      15.037,
      59.652,
      12.323,
      16.829,
      59.051,
      12.323,
      16.829,
      59.051,
      12.323,
      16.829,
      59.051,
      9.323,
      15.037,
      59.652,
      9.323
    ]
  },
  {
    "ID": "Magistrale_2.OG_8740",
    "polygon": [
      13.245,
      60.252,
      9.323,
      13.245,
      60.252,
      12.323,
      15.037,
      59.652,
      12.323,
      15.037,
      59.652,
      12.323,
      15.037,
      59.652,
      9.323,
      13.245,
      60.252,
      9.323
    ]
  },
  {
    "ID": "Magistrale_2.OG_8741",
    "polygon": [
      11.452,
      60.852,
      9.323,
      11.452,
      60.852,
      12.323,
      13.245,
      60.252,
      12.323,
      13.245,
      60.252,
      12.323,
      13.245,
      60.252,
      9.323,
      11.452,
      60.852,
      9.323
    ]
  },
  {
    "ID": "Magistrale_1.OG_8748",
    "polygon": [
      20.413,
      57.851,
      5.573,
      20.413,
      57.851,
      8.573,
      22.205,
      57.25,
      8.573,
      22.205,
      57.25,
      8.573,
      22.205,
      57.25,
      5.573,
      20.413,
      57.851,
      5.573
    ]
  },
  {
    "ID": "Magistrale_1.OG_8749",
    "polygon": [
      18.621,
      58.451,
      5.573,
      18.621,
      58.451,
      8.573,
      20.413,
      57.851,
      8.573,
      20.413,
      57.851,
      8.573,
      20.413,
      57.851,
      5.573,
      18.621,
      58.451,
      5.573
    ]
  },
  {
    "ID": "Magistrale_1.OG_8750",
    "polygon": [
      16.829,
      59.051,
      5.573,
      16.829,
      59.051,
      8.573,
      18.621,
      58.451,
      8.573,
      18.621,
      58.451,
      8.573,
      18.621,
      58.451,
      5.573,
      16.829,
      59.051,
      5.573
    ]
  },
  {
    "ID": "Magistrale_1.OG_8751",
    "polygon": [
      15.037,
      59.652,
      5.573,
      15.037,
      59.652,
      8.573,
      16.829,
      59.051,
      8.573,
      16.829,
      59.051,
      8.573,
      16.829,
      59.051,
      5.573,
      15.037,
      59.652,
      5.573
    ]
  },
  {
    "ID": "Magistrale_1.OG_8752",
    "polygon": [
      13.245,
      60.252,
      5.573,
      13.245,
      60.252,
      8.573,
      15.037,
      59.652,
      8.573,
      15.037,
      59.652,
      8.573,
      15.037,
      59.652,
      5.573,
      13.245,
      60.252,
      5.573
    ]
  },
  {
    "ID": "Magistrale_1.OG_8753",
    "polygon": [
      11.452,
      60.852,
      5.573,
      11.452,
      60.852,
      8.573,
      13.245,
      60.252,
      8.573,
      13.245,
      60.252,
      8.573,
      13.245,
      60.252,
      5.573,
      11.452,
      60.852,
      5.573
    ]
  },
  {
    "ID": "Magistrale_2.OG_8754",
    "polygon": [
      1.521,
      64.179,
      9.323,
      1.521,
      64.179,
      12.323,
      3.313,
      63.579,
      12.323,
      3.313,
      63.579,
      12.323,
      3.313,
      63.579,
      9.323,
      1.521,
      64.179,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_2.OG_8755",
    "polygon": [
      3.313,
      63.579,
      9.323,
      3.313,
      63.579,
      12.323,
      5.106,
      62.978,
      12.323,
      5.106,
      62.978,
      12.323,
      5.106,
      62.978,
      9.323,
      3.313,
      63.579,
      9.323
    ]
  },
  {
    "ID": "Magistrale_3.OG_8756",
    "polygon": [
      -0.27,
      64.781,
      13.073,
      -0.27,
      64.781,
      16.073,
      1.522,
      64.18,
      16.073,
      1.522,
      64.18,
      16.073,
      1.522,
      64.18,
      13.073,
      -0.27,
      64.781,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_3.OG_8757",
    "polygon": [
      1.522,
      64.18,
      13.073,
      1.522,
      64.18,
      16.073,
      3.314,
      63.58,
      16.073,
      3.314,
      63.58,
      16.073,
      3.314,
      63.58,
      13.073,
      1.522,
      64.18,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_3.OG_8758",
    "polygon": [
      3.314,
      63.58,
      13.073,
      3.314,
      63.58,
      16.073,
      5.106,
      62.98,
      16.073,
      5.106,
      62.98,
      16.073,
      5.106,
      62.98,
      13.073,
      3.314,
      63.58,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "Magistrale_1.OG_8759",
    "polygon": [
      -0.271,
      64.78,
      5.573,
      -0.271,
      64.78,
      8.573,
      1.521,
      64.179,
      8.573,
      1.521,
      64.179,
      8.573,
      1.521,
      64.179,
      5.573,
      -0.271,
      64.78,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1
  },
  {
    "ID": "Magistrale_1.OG_8760",
    "polygon": [
      1.521,
      64.179,
      5.573,
      1.521,
      64.179,
      8.573,
      3.313,
      63.579,
      8.573,
      3.313,
      63.579,
      8.573,
      3.313,
      63.579,
      5.573,
      1.521,
      64.179,
      5.573
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_1.OG_8761",
    "polygon": [
      3.313,
      63.579,
      5.573,
      3.313,
      63.579,
      8.573,
      5.106,
      62.978,
      8.573,
      5.106,
      62.978,
      8.573,
      5.106,
      62.978,
      5.573,
      3.313,
      63.579,
      5.573
    ]
  },
  {
    "ID": "Magistrale_2.OG_8762",
    "polygon": [
      11.452,
      60.852,
      8.573,
      11.452,
      60.852,
      9.323,
      13.245,
      60.252,
      9.323,
      13.245,
      60.252,
      9.323,
      13.245,
      60.252,
      8.573,
      11.452,
      60.852,
      8.573
    ]
  },
  {
    "ID": "Magistrale_1.OG_8763",
    "polygon": [
      13.245,
      60.252,
      8.573,
      13.245,
      60.252,
      9.323,
      15.037,
      59.652,
      9.323,
      15.037,
      59.652,
      9.323,
      15.037,
      59.652,
      8.573,
      13.245,
      60.252,
      8.573
    ]
  },
  {
    "ID": "Magistrale_1.OG_8764",
    "polygon": [
      15.037,
      59.652,
      8.573,
      15.037,
      59.652,
      9.323,
      16.829,
      59.051,
      9.323,
      16.829,
      59.051,
      9.323,
      16.829,
      59.051,
      8.573,
      15.037,
      59.652,
      8.573
    ]
  },
  {
    "ID": "Magistrale_EG_8849",
    "polygon": [
      7.364,
      62.227,
      2.848,
      7.364,
      62.227,
      3.973,
      9.261,
      61.592,
      3.973,
      9.261,
      61.592,
      3.973,
      9.261,
      61.592,
      2.848,
      7.364,
      62.227,
      2.848
    ]
  },
  {
    "ID": "Magistrale_EG_8850",
    "polygon": [
      8.313,
      61.91,
      0.0,
      8.313,
      61.91,
      2.848,
      9.261,
      61.592,
      2.848,
      9.261,
      61.592,
      2.848,
      9.261,
      61.592,
      0.0,
      8.313,
      61.91,
      0.0
    ]
  },
  {
    "ID": "Magistrale_EG_8851",
    "polygon": [
      7.364,
      62.227,
      0.0,
      7.364,
      62.227,
      2.848,
      8.313,
      61.91,
      2.848,
      8.313,
      61.91,
      2.848,
      8.313,
      61.91,
      0.0,
      7.364,
      62.227,
      0.0
    ]
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8852",
    "polygon": [
      54.681,
      -11.572,
      13.073,
      54.681,
      -11.572,
      15.523,
      54.17,
      -13.099,
      15.523,
      54.17,
      -13.099,
      15.523,
      54.17,
      -13.099,
      13.073,
      54.681,
      -11.572,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8853",
    "polygon": [
      54.919,
      -10.861,
      13.073,
      54.919,
      -10.861,
      15.523,
      54.681,
      -11.572,
      15.523,
      54.681,
      -11.572,
      15.523,
      54.681,
      -11.572,
      13.073,
      54.919,
      -10.861,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8854",
    "polygon": [
      55.118,
      -10.268,
      13.073,
      55.118,
      -10.268,
      15.523,
      54.919,
      -10.861,
      15.523,
      54.919,
      -10.861,
      15.523,
      54.919,
      -10.861,
      13.073,
      55.118,
      -10.268,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8855",
    "polygon": [
      55.627,
      -8.746,
      13.073,
      55.627,
      -8.746,
      15.523,
      55.116,
      -10.273,
      15.523,
      55.116,
      -10.273,
      15.523,
      55.116,
      -10.273,
      13.073,
      55.627,
      -8.746,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8856",
    "polygon": [
      55.866,
      -8.035,
      13.073,
      55.866,
      -8.035,
      15.523,
      55.627,
      -8.746,
      15.523,
      55.627,
      -8.746,
      15.523,
      55.627,
      -8.746,
      13.073,
      55.866,
      -8.035,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8857",
    "polygon": [
      56.645,
      -5.707,
      13.073,
      56.645,
      -5.707,
      15.523,
      56.134,
      -7.234,
      15.523,
      56.134,
      -7.234,
      15.523,
      56.134,
      -7.234,
      13.073,
      56.645,
      -5.707,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8858",
    "polygon": [
      56.883,
      -4.996,
      13.073,
      56.883,
      -4.996,
      15.523,
      56.645,
      -5.707,
      15.523,
      56.645,
      -5.707,
      15.523,
      56.645,
      -5.707,
      13.073,
      56.883,
      -4.996,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8859",
    "polygon": [
      57.081,
      -4.403,
      13.073,
      57.081,
      -4.403,
      15.523,
      56.883,
      -4.996,
      15.523,
      56.883,
      -4.996,
      15.523,
      56.883,
      -4.996,
      13.073,
      57.081,
      -4.403,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8860",
    "polygon": [
      57.591,
      -2.881,
      13.073,
      57.591,
      -2.881,
      15.523,
      57.08,
      -4.407,
      15.523,
      57.08,
      -4.407,
      15.523,
      57.08,
      -4.407,
      13.073,
      57.591,
      -2.881,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8861",
    "polygon": [
      57.829,
      -2.169,
      13.073,
      57.829,
      -2.169,
      15.523,
      57.591,
      -2.881,
      15.523,
      57.591,
      -2.881,
      15.523,
      57.591,
      -2.881,
      13.073,
      57.829,
      -2.169,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8862",
    "polygon": [
      58.613,
      0.173,
      13.073,
      58.613,
      0.173,
      15.523,
      58.102,
      -1.353,
      15.523,
      58.102,
      -1.353,
      15.523,
      58.102,
      -1.353,
      13.073,
      58.613,
      0.173,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8863",
    "polygon": [
      58.851,
      0.885,
      13.073,
      58.851,
      0.885,
      15.523,
      58.613,
      0.173,
      15.523,
      58.613,
      0.173,
      15.523,
      58.613,
      0.173,
      13.073,
      58.851,
      0.885,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8864",
    "polygon": [
      59.05,
      1.477,
      13.073,
      59.05,
      1.477,
      15.523,
      58.851,
      0.885,
      15.523,
      58.851,
      0.885,
      15.523,
      58.851,
      0.885,
      13.073,
      59.05,
      1.477,
      13.073
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8865",
    "polygon": [
      59.56,
      3.0,
      13.073,
      59.56,
      3.0,
      15.523,
      59.048,
      1.473,
      15.523,
      59.048,
      1.473,
      15.523,
      59.048,
      1.473,
      13.073,
      59.56,
      3.0,
      13.073
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8866",
    "polygon": [
      59.798,
      3.711,
      13.073,
      59.798,
      3.711,
      15.523,
      59.56,
      3.0,
      15.523,
      59.56,
      3.0,
      15.523,
      59.56,
      3.0,
      13.073,
      59.798,
      3.711,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8867",
    "polygon": [
      60.582,
      6.054,
      13.073,
      60.582,
      6.054,
      15.523,
      60.071,
      4.527,
      15.523,
      60.071,
      4.527,
      15.523,
      60.071,
      4.527,
      13.073,
      60.582,
      6.054,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8868",
    "polygon": [
      60.82,
      6.765,
      13.073,
      60.82,
      6.765,
      15.523,
      60.582,
      6.054,
      15.523,
      60.582,
      6.054,
      15.523,
      60.582,
      6.054,
      13.073,
      60.82,
      6.765,
      13.073
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8869",
    "polygon": [
      61.018,
      7.357,
      13.073,
      61.018,
      7.357,
      15.523,
      60.82,
      6.765,
      15.523,
      60.82,
      6.765,
      15.523,
      60.82,
      6.765,
      13.073,
      61.018,
      7.357,
      13.073
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8870",
    "polygon": [
      61.528,
      8.88,
      13.073,
      61.528,
      8.88,
      15.523,
      61.017,
      7.353,
      15.523,
      61.017,
      7.353,
      15.523,
      61.017,
      7.353,
      13.073,
      61.528,
      8.88,
      13.073
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_8871",
    "polygon": [
      61.703,
      9.401,
      13.073,
      61.703,
      9.401,
      15.523,
      61.528,
      8.88,
      15.523,
      61.528,
      8.88,
      15.523,
      61.528,
      8.88,
      13.073,
      61.703,
      9.401,
      13.073
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8872",
    "polygon": [
      54.58,
      -11.878,
      9.323,
      54.58,
      -11.878,
      11.773,
      54.069,
      -13.405,
      11.773,
      54.069,
      -13.405,
      11.773,
      54.069,
      -13.405,
      9.323,
      54.58,
      -11.878,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8873",
    "polygon": [
      54.818,
      -11.167,
      9.323,
      54.818,
      -11.167,
      11.773,
      54.58,
      -11.878,
      11.773,
      54.58,
      -11.878,
      11.773,
      54.58,
      -11.878,
      9.323,
      54.818,
      -11.167,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8874",
    "polygon": [
      55.017,
      -10.574,
      9.323,
      55.017,
      -10.574,
      11.773,
      54.818,
      -11.167,
      11.773,
      54.818,
      -11.167,
      11.773,
      54.818,
      -11.167,
      9.323,
      55.017,
      -10.574,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8875",
    "polygon": [
      55.526,
      -9.052,
      9.323,
      55.526,
      -9.052,
      11.773,
      55.015,
      -10.579,
      11.773,
      55.015,
      -10.579,
      11.773,
      55.015,
      -10.579,
      9.323,
      55.526,
      -9.052,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8876",
    "polygon": [
      55.764,
      -8.341,
      9.323,
      55.764,
      -8.341,
      11.773,
      55.526,
      -9.052,
      11.773,
      55.526,
      -9.052,
      11.773,
      55.526,
      -9.052,
      9.323,
      55.764,
      -8.341,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8877",
    "polygon": [
      56.544,
      -6.013,
      9.323,
      56.544,
      -6.013,
      11.773,
      56.032,
      -7.54,
      11.773,
      56.032,
      -7.54,
      11.773,
      56.032,
      -7.54,
      9.323,
      56.544,
      -6.013,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8878",
    "polygon": [
      56.782,
      -5.302,
      9.323,
      56.782,
      -5.302,
      11.773,
      56.544,
      -6.013,
      11.773,
      56.544,
      -6.013,
      11.773,
      56.544,
      -6.013,
      9.323,
      56.782,
      -5.302,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8879",
    "polygon": [
      56.98,
      -4.709,
      9.323,
      56.98,
      -4.709,
      11.773,
      56.782,
      -5.302,
      11.773,
      56.782,
      -5.302,
      11.773,
      56.782,
      -5.302,
      9.323,
      56.98,
      -4.709,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8880",
    "polygon": [
      57.49,
      -3.187,
      9.323,
      57.49,
      -3.187,
      11.773,
      56.979,
      -4.713,
      11.773,
      56.979,
      -4.713,
      11.773,
      56.979,
      -4.713,
      9.323,
      57.49,
      -3.187,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8881",
    "polygon": [
      57.728,
      -2.475,
      9.323,
      57.728,
      -2.475,
      11.773,
      57.49,
      -3.187,
      11.773,
      57.49,
      -3.187,
      11.773,
      57.49,
      -3.187,
      9.323,
      57.728,
      -2.475,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8882",
    "polygon": [
      58.512,
      -0.133,
      9.323,
      58.512,
      -0.133,
      11.773,
      58.001,
      -1.66,
      11.773,
      58.001,
      -1.66,
      11.773,
      58.001,
      -1.66,
      9.323,
      58.512,
      -0.133,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8883",
    "polygon": [
      58.75,
      0.578,
      9.323,
      58.75,
      0.578,
      11.773,
      58.512,
      -0.133,
      11.773,
      58.512,
      -0.133,
      11.773,
      58.512,
      -0.133,
      9.323,
      58.75,
      0.578,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8884",
    "polygon": [
      58.949,
      1.171,
      9.323,
      58.949,
      1.171,
      11.773,
      58.75,
      0.578,
      11.773,
      58.75,
      0.578,
      11.773,
      58.75,
      0.578,
      9.323,
      58.949,
      1.171,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8885",
    "polygon": [
      59.458,
      2.694,
      9.323,
      59.458,
      2.694,
      11.773,
      58.947,
      1.167,
      11.773,
      58.947,
      1.167,
      11.773,
      58.947,
      1.167,
      9.323,
      59.458,
      2.694,
      9.323
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8886",
    "polygon": [
      59.696,
      3.405,
      9.323,
      59.696,
      3.405,
      11.773,
      59.458,
      2.694,
      11.773,
      59.458,
      2.694,
      11.773,
      59.458,
      2.694,
      9.323,
      59.696,
      3.405,
      9.323
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8887",
    "polygon": [
      60.481,
      5.747,
      9.323,
      60.481,
      5.747,
      11.773,
      59.97,
      4.221,
      11.773,
      59.97,
      4.221,
      11.773,
      59.97,
      4.221,
      9.323,
      60.481,
      5.747,
      9.323
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8888",
    "polygon": [
      60.719,
      6.459,
      9.323,
      60.719,
      6.459,
      11.773,
      60.481,
      5.747,
      11.773,
      60.481,
      5.747,
      11.773,
      60.481,
      5.747,
      9.323,
      60.719,
      6.459,
      9.323
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8889",
    "polygon": [
      60.917,
      7.051,
      9.323,
      60.917,
      7.051,
      11.773,
      60.719,
      6.459,
      11.773,
      60.719,
      6.459,
      11.773,
      60.719,
      6.459,
      9.323,
      60.917,
      7.051,
      9.323
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8890",
    "polygon": [
      61.427,
      8.574,
      9.323,
      61.427,
      8.574,
      11.773,
      60.916,
      7.047,
      11.773,
      60.916,
      7.047,
      11.773,
      60.916,
      7.047,
      9.323,
      61.427,
      8.574,
      9.323
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_2.OG_8891",
    "polygon": [
      61.602,
      9.095,
      9.323,
      61.602,
      9.095,
      11.773,
      61.427,
      8.574,
      11.773,
      61.427,
      8.574,
      11.773,
      61.427,
      8.574,
      9.323,
      61.602,
      9.095,
      9.323
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8892",
    "polygon": [
      54.58,
      -11.878,
      5.573,
      54.58,
      -11.878,
      8.023,
      54.069,
      -13.405,
      8.023,
      54.069,
      -13.405,
      8.023,
      54.069,
      -13.405,
      5.573,
      54.58,
      -11.878,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8893",
    "polygon": [
      54.818,
      -11.167,
      5.573,
      54.818,
      -11.167,
      8.023,
      54.58,
      -11.878,
      8.023,
      54.58,
      -11.878,
      8.023,
      54.58,
      -11.878,
      5.573,
      54.818,
      -11.167,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8894",
    "polygon": [
      55.017,
      -10.574,
      5.573,
      55.017,
      -10.574,
      8.023,
      54.818,
      -11.167,
      8.023,
      54.818,
      -11.167,
      8.023,
      54.818,
      -11.167,
      5.573,
      55.017,
      -10.574,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8895",
    "polygon": [
      55.526,
      -9.052,
      5.573,
      55.526,
      -9.052,
      8.023,
      55.015,
      -10.579,
      8.023,
      55.015,
      -10.579,
      8.023,
      55.015,
      -10.579,
      5.573,
      55.526,
      -9.052,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8896",
    "polygon": [
      55.764,
      -8.341,
      5.573,
      55.764,
      -8.341,
      8.023,
      55.526,
      -9.052,
      8.023,
      55.526,
      -9.052,
      8.023,
      55.526,
      -9.052,
      5.573,
      55.764,
      -8.341,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8897",
    "polygon": [
      56.544,
      -6.013,
      5.573,
      56.544,
      -6.013,
      8.023,
      56.032,
      -7.54,
      8.023,
      56.032,
      -7.54,
      8.023,
      56.032,
      -7.54,
      5.573,
      56.544,
      -6.013,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8898",
    "polygon": [
      56.782,
      -5.302,
      5.573,
      56.782,
      -5.302,
      8.023,
      56.544,
      -6.013,
      8.023,
      56.544,
      -6.013,
      8.023,
      56.544,
      -6.013,
      5.573,
      56.782,
      -5.302,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8899",
    "polygon": [
      56.98,
      -4.709,
      5.573,
      56.98,
      -4.709,
      8.023,
      56.782,
      -5.302,
      8.023,
      56.782,
      -5.302,
      8.023,
      56.782,
      -5.302,
      5.573,
      56.98,
      -4.709,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8900",
    "polygon": [
      57.49,
      -3.187,
      5.573,
      57.49,
      -3.187,
      8.023,
      56.979,
      -4.713,
      8.023,
      56.979,
      -4.713,
      8.023,
      56.979,
      -4.713,
      5.573,
      57.49,
      -3.187,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8901",
    "polygon": [
      57.728,
      -2.475,
      5.573,
      57.728,
      -2.475,
      8.023,
      57.49,
      -3.187,
      8.023,
      57.49,
      -3.187,
      8.023,
      57.49,
      -3.187,
      5.573,
      57.728,
      -2.475,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8902",
    "polygon": [
      58.512,
      -0.133,
      5.573,
      58.512,
      -0.133,
      8.023,
      58.001,
      -1.66,
      8.023,
      58.001,
      -1.66,
      8.023,
      58.001,
      -1.66,
      5.573,
      58.512,
      -0.133,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8903",
    "polygon": [
      58.75,
      0.578,
      5.573,
      58.75,
      0.578,
      8.023,
      58.512,
      -0.133,
      8.023,
      58.512,
      -0.133,
      8.023,
      58.512,
      -0.133,
      5.573,
      58.75,
      0.578,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8904",
    "polygon": [
      58.949,
      1.171,
      5.573,
      58.949,
      1.171,
      8.023,
      58.75,
      0.578,
      8.023,
      58.75,
      0.578,
      8.023,
      58.75,
      0.578,
      5.573,
      58.949,
      1.171,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8905",
    "polygon": [
      59.458,
      2.694,
      5.573,
      59.458,
      2.694,
      8.023,
      58.947,
      1.167,
      8.023,
      58.947,
      1.167,
      8.023,
      58.947,
      1.167,
      5.573,
      59.458,
      2.694,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8906",
    "polygon": [
      59.696,
      3.405,
      5.573,
      59.696,
      3.405,
      8.023,
      59.458,
      2.694,
      8.023,
      59.458,
      2.694,
      8.023,
      59.458,
      2.694,
      5.573,
      59.696,
      3.405,
      5.573
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8907",
    "polygon": [
      60.481,
      5.747,
      5.573,
      60.481,
      5.747,
      8.023,
      59.97,
      4.221,
      8.023,
      59.97,
      4.221,
      8.023,
      59.97,
      4.221,
      5.573,
      60.481,
      5.747,
      5.573
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8908",
    "polygon": [
      60.719,
      6.459,
      5.573,
      60.719,
      6.459,
      8.023,
      60.481,
      5.747,
      8.023,
      60.481,
      5.747,
      8.023,
      60.481,
      5.747,
      5.573,
      60.719,
      6.459,
      5.573
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8909",
    "polygon": [
      60.917,
      7.051,
      5.573,
      60.917,
      7.051,
      8.023,
      60.719,
      6.459,
      8.023,
      60.719,
      6.459,
      8.023,
      60.719,
      6.459,
      5.573,
      60.917,
      7.051,
      5.573
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8910",
    "polygon": [
      61.427,
      8.574,
      5.573,
      61.427,
      8.574,
      8.023,
      60.916,
      7.047,
      8.023,
      60.916,
      7.047,
      8.023,
      60.916,
      7.047,
      5.573,
      61.427,
      8.574,
      5.573
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_1.OG_8911",
    "polygon": [
      61.602,
      9.095,
      5.573,
      61.602,
      9.095,
      8.023,
      61.427,
      8.574,
      8.023,
      61.427,
      8.574,
      8.023,
      61.427,
      8.574,
      5.573,
      61.602,
      9.095,
      5.573
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Magistrale_EG_8953",
    "polygon": [
      60.472,
      30.646,
      4.3,
      60.472,
      30.646,
      5.0,
      59.83,
      28.752,
      5.0,
      59.83,
      28.752,
      5.0,
      59.83,
      28.752,
      4.3,
      60.472,
      30.646,
      4.3
    ]
  },
  {
    "ID": "Magistrale_EG_8954",
    "polygon": [
      61.08,
      32.436,
      4.3,
      61.08,
      32.436,
      5.0,
      60.472,
      30.646,
      5.0,
      60.472,
      30.646,
      5.0,
      60.472,
      30.646,
      4.3,
      61.08,
      32.436,
      4.3
    ]
  },
  {
    "ID": "Magistrale_EG_8955",
    "polygon": [
      61.687,
      34.226,
      4.3,
      61.687,
      34.226,
      5.0,
      61.08,
      32.436,
      5.0,
      61.08,
      32.436,
      5.0,
      61.08,
      32.436,
      4.3,
      61.687,
      34.226,
      4.3
    ]
  },
  {
    "ID": "Magistrale_EG_8956",
    "polygon": [
      62.008,
      35.173,
      4.3,
      62.008,
      35.173,
      5.0,
      61.687,
      34.226,
      5.0,
      61.687,
      34.226,
      5.0,
      61.687,
      34.226,
      4.3,
      62.008,
      35.173,
      4.3
    ]
  },
  {
    "ID": "Magistrale_EG_8957",
    "polygon": [
      60.472,
      30.646,
      3.5,
      60.472,
      30.646,
      4.3,
      59.83,
      28.752,
      4.3,
      59.83,
      28.752,
      4.3,
      59.83,
      28.752,
      3.5,
      60.472,
      30.646,
      3.5
    ]
  },
  {
    "ID": "Magistrale_EG_8958",
    "polygon": [
      61.08,
      32.436,
      3.5,
      61.08,
      32.436,
      4.3,
      60.472,
      30.646,
      4.3,
      60.472,
      30.646,
      4.3,
      60.472,
      30.646,
      3.5,
      61.08,
      32.436,
      3.5
    ]
  },
  {
    "ID": "Magistrale_EG_8959",
    "polygon": [
      61.687,
      34.226,
      3.5,
      61.687,
      34.226,
      4.3,
      61.08,
      32.436,
      4.3,
      61.08,
      32.436,
      4.3,
      61.08,
      32.436,
      3.5,
      61.687,
      34.226,
      3.5
    ]
  },
  {
    "ID": "Magistrale_EG_8960",
    "polygon": [
      62.008,
      35.173,
      3.5,
      62.008,
      35.173,
      4.3,
      61.687,
      34.226,
      4.3,
      61.687,
      34.226,
      4.3,
      61.687,
      34.226,
      3.5,
      62.008,
      35.173,
      3.5
    ]
  },
  {
    "ID": "Magistrale_EG_8961",
    "polygon": [
      61.982,
      35.098,
      0.0,
      61.982,
      35.098,
      3.5,
      61.661,
      34.151,
      3.5,
      61.661,
      34.151,
      3.5,
      61.661,
      34.151,
      0.0,
      61.982,
      35.098,
      0.0
    ]
  },
  {
    "ID": "Magistrale_EG_8962",
    "polygon": [
      60.139,
      29.663,
      0.0,
      60.139,
      29.663,
      3.5,
      59.835,
      28.768,
      3.5,
      59.835,
      28.768,
      3.5,
      59.835,
      28.768,
      0.0,
      60.139,
      29.663,
      0.0
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_EG_8963",
    "polygon": [
      60.442,
      30.556,
      0.0,
      60.442,
      30.556,
      3.5,
      60.138,
      29.661,
      3.5,
      60.138,
      29.661,
      3.5,
      60.138,
      29.661,
      0.0,
      60.442,
      30.556,
      0.0
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_EG_8964",
    "polygon": [
      60.741,
      31.438,
      0.0,
      60.741,
      31.438,
      3.5,
      60.437,
      30.543,
      3.5,
      60.437,
      30.543,
      3.5,
      60.437,
      30.543,
      0.0,
      60.741,
      31.438,
      0.0
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_EG_8965",
    "polygon": [
      61.042,
      32.324,
      0.0,
      61.042,
      32.324,
      3.5,
      60.738,
      31.429,
      3.5,
      60.738,
      31.429,
      3.5,
      60.738,
      31.429,
      0.0,
      61.042,
      32.324,
      0.0
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_EG_8966",
    "polygon": [
      61.345,
      33.217,
      0.0,
      61.345,
      33.217,
      3.5,
      61.041,
      32.322,
      3.5,
      61.041,
      32.322,
      3.5,
      61.041,
      32.322,
      0.0,
      61.345,
      33.217,
      0.0
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Magistrale_EG_8969",
    "polygon": [
      61.666,
      34.164,
      0.0,
      61.666,
      34.164,
      3.5,
      61.345,
      33.217,
      3.5,
      61.345,
      33.217,
      3.5,
      61.345,
      33.217,
      0.0,
      61.666,
      34.164,
      0.0
    ]
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8970",
    "polygon": [
      -61.721,
      49.929,
      13.063,
      -61.721,
      49.929,
      15.513,
      -61.483,
      50.64,
      15.513,
      -61.483,
      50.64,
      15.513,
      -61.483,
      50.64,
      13.063,
      -61.721,
      49.929,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8971",
    "polygon": [
      -62.233,
      48.402,
      13.063,
      -62.233,
      48.402,
      15.513,
      -61.721,
      49.929,
      15.513,
      -61.721,
      49.929,
      15.513,
      -61.721,
      49.929,
      13.063,
      -62.233,
      48.402,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8972",
    "polygon": [
      -62.431,
      47.809,
      13.063,
      -62.431,
      47.809,
      15.513,
      -62.233,
      48.402,
      15.513,
      -62.233,
      48.402,
      15.513,
      -62.233,
      48.402,
      13.063,
      -62.431,
      47.809,
      13.063
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8973",
    "polygon": [
      -62.67,
      47.096,
      13.063,
      -62.67,
      47.096,
      15.513,
      -62.432,
      47.808,
      15.513,
      -62.432,
      47.808,
      15.513,
      -62.432,
      47.808,
      13.063,
      -62.67,
      47.096,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8974",
    "polygon": [
      -63.181,
      45.57,
      13.063,
      -63.181,
      45.57,
      15.513,
      -62.67,
      47.096,
      15.513,
      -62.67,
      47.096,
      15.513,
      -62.67,
      47.096,
      13.063,
      -63.181,
      45.57,
      13.063
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8975",
    "polygon": [
      -63.707,
      44.002,
      13.063,
      -63.707,
      44.002,
      15.513,
      -63.468,
      44.714,
      15.513,
      -63.468,
      44.714,
      15.513,
      -63.468,
      44.714,
      13.063,
      -63.707,
      44.002,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8976",
    "polygon": [
      -64.218,
      42.476,
      13.063,
      -64.218,
      42.476,
      15.513,
      -63.707,
      44.002,
      15.513,
      -63.707,
      44.002,
      15.513,
      -63.707,
      44.002,
      13.063,
      -64.218,
      42.476,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8977",
    "polygon": [
      -64.416,
      41.883,
      13.063,
      -64.416,
      41.883,
      15.513,
      -64.218,
      42.476,
      15.513,
      -64.218,
      42.476,
      15.513,
      -64.218,
      42.476,
      13.063,
      -64.416,
      41.883,
      13.063
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8978",
    "polygon": [
      -64.655,
      41.17,
      13.063,
      -64.655,
      41.17,
      15.513,
      -64.417,
      41.881,
      15.513,
      -64.417,
      41.881,
      15.513,
      -64.417,
      41.881,
      13.063,
      -64.655,
      41.17,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8979",
    "polygon": [
      -65.167,
      39.644,
      13.063,
      -65.167,
      39.644,
      15.513,
      -64.655,
      41.17,
      15.513,
      -64.655,
      41.17,
      15.513,
      -64.655,
      41.17,
      13.063,
      -65.167,
      39.644,
      13.063
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8980",
    "polygon": [
      -65.692,
      38.076,
      13.063,
      -65.692,
      38.076,
      15.513,
      -65.454,
      38.787,
      15.513,
      -65.454,
      38.787,
      15.513,
      -65.454,
      38.787,
      13.063,
      -65.692,
      38.076,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8981",
    "polygon": [
      -66.203,
      36.549,
      13.063,
      -66.203,
      36.549,
      15.513,
      -65.692,
      38.076,
      15.513,
      -65.692,
      38.076,
      15.513,
      -65.692,
      38.076,
      13.063,
      -66.203,
      36.549,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8982",
    "polygon": [
      -66.402,
      35.957,
      13.063,
      -66.402,
      35.957,
      15.513,
      -66.203,
      36.549,
      15.513,
      -66.203,
      36.549,
      15.513,
      -66.203,
      36.549,
      13.063,
      -66.402,
      35.957,
      13.063
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8983",
    "polygon": [
      -66.64,
      35.244,
      13.063,
      -66.64,
      35.244,
      15.513,
      -66.402,
      35.955,
      15.513,
      -66.402,
      35.955,
      15.513,
      -66.402,
      35.955,
      13.063,
      -66.64,
      35.244,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8984",
    "polygon": [
      -67.152,
      33.717,
      13.063,
      -67.152,
      33.717,
      15.513,
      -66.64,
      35.244,
      15.513,
      -66.64,
      35.244,
      15.513,
      -66.64,
      35.244,
      13.063,
      -67.152,
      33.717,
      13.063
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8985",
    "polygon": [
      -67.677,
      32.15,
      13.063,
      -67.677,
      32.15,
      15.513,
      -67.439,
      32.861,
      15.513,
      -67.439,
      32.861,
      15.513,
      -67.439,
      32.861,
      13.063,
      -67.677,
      32.15,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8986",
    "polygon": [
      -68.188,
      30.623,
      13.063,
      -68.188,
      30.623,
      15.513,
      -67.677,
      32.15,
      15.513,
      -67.677,
      32.15,
      15.513,
      -67.677,
      32.15,
      13.063,
      -68.188,
      30.623,
      13.063
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8987",
    "polygon": [
      -68.387,
      30.03,
      13.063,
      -68.387,
      30.03,
      15.513,
      -68.188,
      30.623,
      15.513,
      -68.188,
      30.623,
      15.513,
      -68.188,
      30.623,
      13.063,
      -68.387,
      30.03,
      13.063
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8988",
    "polygon": [
      -68.626,
      29.318,
      13.063,
      -68.626,
      29.318,
      15.513,
      -68.388,
      30.029,
      15.513,
      -68.388,
      30.029,
      15.513,
      -68.388,
      30.029,
      13.063,
      -68.626,
      29.318,
      13.063
    ],
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_8990",
    "polygon": [
      -69.018,
      28.147,
      13.063,
      -69.018,
      28.147,
      15.513,
      -68.627,
      29.315,
      15.513,
      -68.627,
      29.315,
      15.513,
      -68.627,
      29.315,
      13.063,
      -69.018,
      28.147,
      13.063
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_8991",
    "polygon": [
      -61.721,
      49.929,
      9.313,
      -61.721,
      49.929,
      11.763,
      -61.483,
      50.64,
      11.763,
      -61.483,
      50.64,
      11.763,
      -61.483,
      50.64,
      9.313,
      -61.721,
      49.929,
      9.313
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_8992",
    "polygon": [
      -62.233,
      48.402,
      9.313,
      -62.233,
      48.402,
      11.763,
      -61.721,
      49.929,
      11.763,
      -61.721,
      49.929,
      11.763,
      -61.721,
      49.929,
      9.313,
      -62.233,
      48.402,
      9.313
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_8993",
    "polygon": [
      -62.431,
      47.809,
      9.313,
      -62.431,
      47.809,
      11.763,
      -62.233,
      48.402,
      11.763,
      -62.233,
      48.402,
      11.763,
      -62.233,
      48.402,
      9.313,
      -62.431,
      47.809,
      9.313
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_8994",
    "polygon": [
      -62.67,
      47.096,
      9.313,
      -62.67,
      47.096,
      11.763,
      -62.432,
      47.808,
      11.763,
      -62.432,
      47.808,
      11.763,
      -62.432,
      47.808,
      9.313,
      -62.67,
      47.096,
      9.313
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_8995",
    "polygon": [
      -63.181,
      45.57,
      9.313,
      -63.181,
      45.57,
      11.763,
      -62.67,
      47.096,
      11.763,
      -62.67,
      47.096,
      11.763,
      -62.67,
      47.096,
      9.313,
      -63.181,
      45.57,
      9.313
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_8996",
    "polygon": [
      -63.707,
      44.002,
      9.313,
      -63.707,
      44.002,
      11.763,
      -63.468,
      44.714,
      11.763,
      -63.468,
      44.714,
      11.763,
      -63.468,
      44.714,
      9.313,
      -63.707,
      44.002,
      9.313
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_8997",
    "polygon": [
      -64.218,
      42.476,
      9.313,
      -64.218,
      42.476,
      11.763,
      -63.707,
      44.002,
      11.763,
      -63.707,
      44.002,
      11.763,
      -63.707,
      44.002,
      9.313,
      -64.218,
      42.476,
      9.313
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_8998",
    "polygon": [
      -64.416,
      41.883,
      9.313,
      -64.416,
      41.883,
      11.763,
      -64.218,
      42.476,
      11.763,
      -64.218,
      42.476,
      11.763,
      -64.218,
      42.476,
      9.313,
      -64.416,
      41.883,
      9.313
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_8999",
    "polygon": [
      -64.655,
      41.17,
      9.313,
      -64.655,
      41.17,
      11.763,
      -64.417,
      41.881,
      11.763,
      -64.417,
      41.881,
      11.763,
      -64.417,
      41.881,
      9.313,
      -64.655,
      41.17,
      9.313
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9000",
    "polygon": [
      -65.167,
      39.644,
      9.313,
      -65.167,
      39.644,
      11.763,
      -64.655,
      41.17,
      11.763,
      -64.655,
      41.17,
      11.763,
      -64.655,
      41.17,
      9.313,
      -65.167,
      39.644,
      9.313
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9001",
    "polygon": [
      -65.692,
      38.076,
      9.313,
      -65.692,
      38.076,
      11.763,
      -65.454,
      38.787,
      11.763,
      -65.454,
      38.787,
      11.763,
      -65.454,
      38.787,
      9.313,
      -65.692,
      38.076,
      9.313
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9002",
    "polygon": [
      -66.203,
      36.549,
      9.313,
      -66.203,
      36.549,
      11.763,
      -65.692,
      38.076,
      11.763,
      -65.692,
      38.076,
      11.763,
      -65.692,
      38.076,
      9.313,
      -66.203,
      36.549,
      9.313
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9003",
    "polygon": [
      -66.402,
      35.957,
      9.313,
      -66.402,
      35.957,
      11.763,
      -66.203,
      36.549,
      11.763,
      -66.203,
      36.549,
      11.763,
      -66.203,
      36.549,
      9.313,
      -66.402,
      35.957,
      9.313
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9004",
    "polygon": [
      -66.64,
      35.244,
      9.313,
      -66.64,
      35.244,
      11.763,
      -66.402,
      35.955,
      11.763,
      -66.402,
      35.955,
      11.763,
      -66.402,
      35.955,
      9.313,
      -66.64,
      35.244,
      9.313
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9005",
    "polygon": [
      -67.152,
      33.717,
      9.313,
      -67.152,
      33.717,
      11.763,
      -66.64,
      35.244,
      11.763,
      -66.64,
      35.244,
      11.763,
      -66.64,
      35.244,
      9.313,
      -67.152,
      33.717,
      9.313
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9006",
    "polygon": [
      -67.677,
      32.15,
      9.313,
      -67.677,
      32.15,
      11.763,
      -67.439,
      32.861,
      11.763,
      -67.439,
      32.861,
      11.763,
      -67.439,
      32.861,
      9.313,
      -67.677,
      32.15,
      9.313
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9007",
    "polygon": [
      -68.188,
      30.623,
      9.313,
      -68.188,
      30.623,
      11.763,
      -67.677,
      32.15,
      11.763,
      -67.677,
      32.15,
      11.763,
      -67.677,
      32.15,
      9.313,
      -68.188,
      30.623,
      9.313
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9008",
    "polygon": [
      -68.387,
      30.03,
      9.313,
      -68.387,
      30.03,
      11.763,
      -68.188,
      30.623,
      11.763,
      -68.188,
      30.623,
      11.763,
      -68.188,
      30.623,
      9.313,
      -68.387,
      30.03,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9009",
    "polygon": [
      -68.626,
      29.318,
      9.313,
      -68.626,
      29.318,
      11.763,
      -68.388,
      30.029,
      11.763,
      -68.388,
      30.029,
      11.763,
      -68.388,
      30.029,
      9.313,
      -68.626,
      29.318,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_9010",
    "polygon": [
      -69.018,
      28.147,
      9.313,
      -69.018,
      28.147,
      11.763,
      -68.627,
      29.315,
      11.763,
      -68.627,
      29.315,
      11.763,
      -68.627,
      29.315,
      9.313,
      -69.018,
      28.147,
      9.313
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9011",
    "polygon": [
      -61.721,
      49.929,
      5.563,
      -61.721,
      49.929,
      8.013,
      -61.483,
      50.64,
      8.013,
      -61.483,
      50.64,
      8.013,
      -61.483,
      50.64,
      5.563,
      -61.721,
      49.929,
      5.563
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9012",
    "polygon": [
      -62.233,
      48.402,
      5.563,
      -62.233,
      48.402,
      8.013,
      -61.721,
      49.929,
      8.013,
      -61.721,
      49.929,
      8.013,
      -61.721,
      49.929,
      5.563,
      -62.233,
      48.402,
      5.563
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9013",
    "polygon": [
      -62.431,
      47.809,
      5.563,
      -62.431,
      47.809,
      8.013,
      -62.233,
      48.402,
      8.013,
      -62.233,
      48.402,
      8.013,
      -62.233,
      48.402,
      5.563,
      -62.431,
      47.809,
      5.563
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9014",
    "polygon": [
      -62.67,
      47.096,
      5.563,
      -62.67,
      47.096,
      8.013,
      -62.432,
      47.808,
      8.013,
      -62.432,
      47.808,
      8.013,
      -62.432,
      47.808,
      5.563,
      -62.67,
      47.096,
      5.563
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9015",
    "polygon": [
      -63.181,
      45.57,
      5.563,
      -63.181,
      45.57,
      8.013,
      -62.67,
      47.096,
      8.013,
      -62.67,
      47.096,
      8.013,
      -62.67,
      47.096,
      5.563,
      -63.181,
      45.57,
      5.563
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9016",
    "polygon": [
      -63.707,
      44.002,
      5.563,
      -63.707,
      44.002,
      8.013,
      -63.468,
      44.714,
      8.013,
      -63.468,
      44.714,
      8.013,
      -63.468,
      44.714,
      5.563,
      -63.707,
      44.002,
      5.563
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9017",
    "polygon": [
      -64.218,
      42.476,
      5.563,
      -64.218,
      42.476,
      8.013,
      -63.707,
      44.002,
      8.013,
      -63.707,
      44.002,
      8.013,
      -63.707,
      44.002,
      5.563,
      -64.218,
      42.476,
      5.563
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9018",
    "polygon": [
      -64.416,
      41.883,
      5.563,
      -64.416,
      41.883,
      8.013,
      -64.218,
      42.476,
      8.013,
      -64.218,
      42.476,
      8.013,
      -64.218,
      42.476,
      5.563,
      -64.416,
      41.883,
      5.563
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9019",
    "polygon": [
      -64.655,
      41.17,
      5.563,
      -64.655,
      41.17,
      8.013,
      -64.417,
      41.881,
      8.013,
      -64.417,
      41.881,
      8.013,
      -64.417,
      41.881,
      5.563,
      -64.655,
      41.17,
      5.563
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9020",
    "polygon": [
      -65.167,
      39.644,
      5.563,
      -65.167,
      39.644,
      8.013,
      -64.655,
      41.17,
      8.013,
      -64.655,
      41.17,
      8.013,
      -64.655,
      41.17,
      5.563,
      -65.167,
      39.644,
      5.563
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9021",
    "polygon": [
      -65.692,
      38.076,
      5.563,
      -65.692,
      38.076,
      8.013,
      -65.454,
      38.787,
      8.013,
      -65.454,
      38.787,
      8.013,
      -65.454,
      38.787,
      5.563,
      -65.692,
      38.076,
      5.563
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9022",
    "polygon": [
      -66.203,
      36.549,
      5.563,
      -66.203,
      36.549,
      8.013,
      -65.692,
      38.076,
      8.013,
      -65.692,
      38.076,
      8.013,
      -65.692,
      38.076,
      5.563,
      -66.203,
      36.549,
      5.563
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9023",
    "polygon": [
      -66.402,
      35.957,
      5.563,
      -66.402,
      35.957,
      8.013,
      -66.203,
      36.549,
      8.013,
      -66.203,
      36.549,
      8.013,
      -66.203,
      36.549,
      5.563,
      -66.402,
      35.957,
      5.563
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9024",
    "polygon": [
      -66.64,
      35.244,
      5.563,
      -66.64,
      35.244,
      8.013,
      -66.402,
      35.955,
      8.013,
      -66.402,
      35.955,
      8.013,
      -66.402,
      35.955,
      5.563,
      -66.64,
      35.244,
      5.563
    ],
    "05-22 13:45": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9025",
    "polygon": [
      -67.152,
      33.717,
      5.563,
      -67.152,
      33.717,
      8.013,
      -66.64,
      35.244,
      8.013,
      -66.64,
      35.244,
      8.013,
      -66.64,
      35.244,
      5.563,
      -67.152,
      33.717,
      5.563
    ],
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9026",
    "polygon": [
      -67.677,
      32.15,
      5.563,
      -67.677,
      32.15,
      8.013,
      -67.439,
      32.861,
      8.013,
      -67.439,
      32.861,
      8.013,
      -67.439,
      32.861,
      5.563,
      -67.677,
      32.15,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9027",
    "polygon": [
      -68.188,
      30.623,
      5.563,
      -68.188,
      30.623,
      8.013,
      -67.677,
      32.15,
      8.013,
      -67.677,
      32.15,
      8.013,
      -67.677,
      32.15,
      5.563,
      -68.188,
      30.623,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9028",
    "polygon": [
      -68.387,
      30.03,
      5.563,
      -68.387,
      30.03,
      8.013,
      -68.188,
      30.623,
      8.013,
      -68.188,
      30.623,
      8.013,
      -68.188,
      30.623,
      5.563,
      -68.387,
      30.03,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9029",
    "polygon": [
      -68.626,
      29.318,
      5.563,
      -68.626,
      29.318,
      8.013,
      -68.388,
      30.029,
      8.013,
      -68.388,
      30.029,
      8.013,
      -68.388,
      30.029,
      5.563,
      -68.626,
      29.318,
      5.563
    ]
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_9030",
    "polygon": [
      -69.018,
      28.147,
      5.563,
      -69.018,
      28.147,
      8.013,
      -68.627,
      29.315,
      8.013,
      -68.627,
      29.315,
      8.013,
      -68.627,
      29.315,
      5.563,
      -69.018,
      28.147,
      5.563
    ]
  },
  {
    "ID": "Magistrale_3.OG_9117",
    "polygon": [
      59.58,
      28.062,
      12.7,
      59.58,
      28.062,
      15.5,
      59.066,
      26.547,
      15.5,
      59.066,
      26.547,
      15.5,
      59.066,
      26.547,
      12.7,
      59.58,
      28.062,
      12.7
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1
  },
  {
    "ID": "Magistrale_2.OG_9118",
    "polygon": [
      59.571,
      28.046,
      12.0,
      59.571,
      28.046,
      12.7,
      59.057,
      26.531,
      12.7,
      59.057,
      26.531,
      12.7,
      59.057,
      26.531,
      12.0,
      59.571,
      28.046,
      12.0
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Magistrale_EG_9124",
    "polygon": [
      59.587,
      28.035,
      3.5,
      59.587,
      28.035,
      4.3,
      59.073,
      26.52,
      4.3,
      59.073,
      26.52,
      4.3,
      59.073,
      26.52,
      3.5,
      59.587,
      28.035,
      3.5
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1
  },
  {
    "ID": "Magistrale_EG_9125",
    "polygon": [
      59.587,
      28.035,
      0.0,
      59.587,
      28.035,
      3.5,
      59.33,
      27.278,
      3.5,
      59.33,
      27.278,
      3.5,
      59.33,
      27.278,
      0.0,
      59.587,
      28.035,
      0.0
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1
  },
  {
    "ID": "Magistrale_2.OG_9128",
    "polygon": [
      59.571,
      28.044,
      9.2,
      59.571,
      28.044,
      12.0,
      59.057,
      26.528,
      12.0,
      59.057,
      26.528,
      12.0,
      59.057,
      26.528,
      9.2,
      59.571,
      28.044,
      9.2
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1
  },
  {
    "ID": "Magistrale_1.OG_9129",
    "polygon": [
      59.572,
      28.047,
      8.5,
      59.572,
      28.047,
      9.2,
      59.058,
      26.532,
      9.2,
      59.058,
      26.532,
      9.2,
      59.058,
      26.532,
      8.5,
      59.572,
      28.047,
      8.5
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Magistrale_1.OG_9130",
    "polygon": [
      59.571,
      28.046,
      5.7,
      59.571,
      28.046,
      8.5,
      59.058,
      26.531,
      8.5,
      59.058,
      26.531,
      8.5,
      59.058,
      26.531,
      5.7,
      59.571,
      28.046,
      5.7
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Magistrale_1.OG_9131",
    "polygon": [
      59.57,
      28.041,
      5.0,
      59.57,
      28.041,
      5.7,
      59.056,
      26.526,
      5.7,
      59.056,
      26.526,
      5.7,
      59.056,
      26.526,
      5.0,
      59.57,
      28.041,
      5.0
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1
  },
  {
    "ID": "Magistrale_EG_9132",
    "polygon": [
      59.587,
      28.035,
      4.3,
      59.587,
      28.035,
      5.0,
      59.073,
      26.52,
      5.0,
      59.073,
      26.52,
      5.0,
      59.073,
      26.52,
      4.3,
      59.587,
      28.035,
      4.3
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1
  },
  {
    "ID": "Magistrale_EG_9133",
    "polygon": [
      59.33,
      27.278,
      0.0,
      59.33,
      27.278,
      3.5,
      59.073,
      26.52,
      3.5,
      59.073,
      26.52,
      3.5,
      59.073,
      26.52,
      0.0,
      59.33,
      27.278,
      0.0
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1
  },
  {
    "ID": "Magistrale_3.OG_9134",
    "polygon": [
      62.599,
      36.964,
      12.7,
      62.599,
      36.964,
      15.5,
      62.086,
      35.449,
      15.5,
      62.086,
      35.449,
      15.5,
      62.086,
      35.449,
      12.7,
      62.599,
      36.964,
      12.7
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Magistrale_2.OG_9135",
    "polygon": [
      62.59,
      36.945,
      12.003,
      62.59,
      36.945,
      12.703,
      62.076,
      35.43,
      12.703,
      62.076,
      35.43,
      12.703,
      62.076,
      35.43,
      12.003,
      62.59,
      36.945,
      12.003
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1
  },
  {
    "ID": "Magistrale_EG_9136",
    "polygon": [
      62.613,
      36.957,
      3.5,
      62.613,
      36.957,
      4.3,
      62.099,
      35.442,
      4.3,
      62.099,
      35.442,
      4.3,
      62.099,
      35.442,
      3.5,
      62.613,
      36.957,
      3.5
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Magistrale_EG_9137",
    "polygon": [
      62.613,
      36.957,
      0.0,
      62.613,
      36.957,
      3.5,
      62.356,
      36.2,
      3.5,
      62.356,
      36.2,
      3.5,
      62.356,
      36.2,
      0.0,
      62.613,
      36.957,
      0.0
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1
  },
  {
    "ID": "Magistrale_2.OG_9138",
    "polygon": [
      62.586,
      36.934,
      9.203,
      62.586,
      36.934,
      12.003,
      62.072,
      35.419,
      12.003,
      62.072,
      35.419,
      12.003,
      62.072,
      35.419,
      9.203,
      62.586,
      36.934,
      9.203
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1
  },
  {
    "ID": "Magistrale_1.OG_9139",
    "polygon": [
      62.586,
      36.934,
      8.503,
      62.586,
      36.934,
      9.203,
      62.072,
      35.419,
      9.203,
      62.072,
      35.419,
      9.203,
      62.072,
      35.419,
      8.503,
      62.586,
      36.934,
      8.503
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1
  },
  {
    "ID": "Magistrale_1.OG_9140",
    "polygon": [
      62.588,
      36.941,
      5.706,
      62.588,
      36.941,
      8.506,
      62.074,
      35.425,
      8.506,
      62.074,
      35.425,
      8.506,
      62.074,
      35.425,
      5.706,
      62.588,
      36.941,
      5.706
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1
  },
  {
    "ID": "Magistrale_1.OG_9141",
    "polygon": [
      62.596,
      36.963,
      5.0,
      62.596,
      36.963,
      5.7,
      62.082,
      35.448,
      5.7,
      62.082,
      35.448,
      5.7,
      62.082,
      35.448,
      5.0,
      62.596,
      36.963,
      5.0
    ]
  },
  {
    "ID": "Magistrale_EG_9142",
    "polygon": [
      62.613,
      36.957,
      4.3,
      62.613,
      36.957,
      5.0,
      62.099,
      35.442,
      5.0,
      62.099,
      35.442,
      5.0,
      62.099,
      35.442,
      4.3,
      62.613,
      36.957,
      4.3
    ],
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Magistrale_EG_9143",
    "polygon": [
      62.356,
      36.2,
      0.0,
      62.356,
      36.2,
      3.5,
      62.099,
      35.442,
      3.5,
      62.099,
      35.442,
      3.5,
      62.099,
      35.442,
      0.0,
      62.356,
      36.2,
      0.0
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1
  },
  {
    "ID": "Magistrale_3.OG_9144",
    "polygon": [
      -45.122,
      71.207,
      12.7,
      -45.122,
      71.207,
      15.5,
      -44.608,
      72.723,
      15.5,
      -44.608,
      72.723,
      15.5,
      -44.608,
      72.723,
      12.7,
      -45.122,
      71.207,
      12.7
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_2.OG_9145",
    "polygon": [
      -45.125,
      71.199,
      12.0,
      -45.125,
      71.199,
      12.7,
      -44.611,
      72.714,
      12.7,
      -44.611,
      72.714,
      12.7,
      -44.611,
      72.714,
      12.0,
      -45.125,
      71.199,
      12.0
    ],
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_2.OG_9146",
    "polygon": [
      -45.125,
      71.198,
      9.213,
      -45.125,
      71.198,
      12.013,
      -44.611,
      72.713,
      12.013,
      -44.611,
      72.713,
      12.013,
      -44.611,
      72.713,
      9.213,
      -45.125,
      71.198,
      9.213
    ],
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_1.OG_9147",
    "polygon": [
      -45.126,
      71.197,
      8.513,
      -45.126,
      71.197,
      9.213,
      -44.612,
      72.712,
      9.213,
      -44.612,
      72.712,
      9.213,
      -44.612,
      72.712,
      8.513,
      -45.126,
      71.197,
      8.513
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_1.OG_9148",
    "polygon": [
      -45.122,
      71.207,
      5.712,
      -45.122,
      71.207,
      8.512,
      -44.608,
      72.723,
      8.512,
      -44.608,
      72.723,
      8.512,
      -44.608,
      72.723,
      5.712,
      -45.122,
      71.207,
      5.712
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_1.OG_9149",
    "polygon": [
      -45.122,
      71.207,
      5.012,
      -45.122,
      71.207,
      5.712,
      -44.608,
      72.723,
      5.712,
      -44.608,
      72.723,
      5.712,
      -44.608,
      72.723,
      5.012,
      -45.122,
      71.207,
      5.012
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_EG_9150",
    "polygon": [
      -45.336,
      71.28,
      4.3,
      -45.336,
      71.28,
      5.0,
      -44.822,
      72.795,
      5.0,
      -44.822,
      72.795,
      5.0,
      -44.822,
      72.795,
      4.3,
      -45.336,
      71.28,
      4.3
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_EG_9151",
    "polygon": [
      -45.336,
      71.28,
      3.5,
      -45.336,
      71.28,
      4.3,
      -44.822,
      72.795,
      4.3,
      -44.822,
      72.795,
      4.3,
      -44.822,
      72.795,
      3.5,
      -45.336,
      71.28,
      3.5
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_EG_9152",
    "polygon": [
      -45.336,
      71.28,
      0.0,
      -45.336,
      71.28,
      3.5,
      -45.079,
      72.038,
      3.5,
      -45.079,
      72.038,
      3.5,
      -45.079,
      72.038,
      0.0,
      -45.336,
      71.28,
      0.0
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Magistrale_EG_9153",
    "polygon": [
      -45.079,
      72.038,
      0.0,
      -45.079,
      72.038,
      3.5,
      -44.822,
      72.795,
      3.5,
      -44.822,
      72.795,
      3.5,
      -44.822,
      72.795,
      0.0,
      -45.079,
      72.038,
      0.0
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Magistrale_3.OG_9274",
    "polygon": [
      -48.149,
      62.285,
      12.7,
      -48.149,
      62.285,
      15.5,
      -47.635,
      63.8,
      15.5,
      -47.635,
      63.8,
      15.5,
      -47.635,
      63.8,
      12.7,
      -48.149,
      62.285,
      12.7
    ],
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_9275",
    "polygon": [
      -48.151,
      62.278,
      12.0,
      -48.151,
      62.278,
      12.7,
      -47.637,
      63.793,
      12.7,
      -47.637,
      63.793,
      12.7,
      -47.637,
      63.793,
      12.0,
      -48.151,
      62.278,
      12.0
    ],
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_2.OG_9276",
    "polygon": [
      -48.151,
      62.277,
      9.2,
      -48.151,
      62.277,
      12.0,
      -47.637,
      63.792,
      12.0,
      -47.637,
      63.792,
      12.0,
      -47.637,
      63.792,
      9.2,
      -48.151,
      62.277,
      9.2
    ],
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_9277",
    "polygon": [
      -48.151,
      62.277,
      8.5,
      -48.151,
      62.277,
      9.2,
      -47.637,
      63.792,
      9.2,
      -47.637,
      63.792,
      9.2,
      -47.637,
      63.792,
      8.5,
      -48.151,
      62.277,
      8.5
    ],
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_9278",
    "polygon": [
      -48.146,
      62.292,
      5.7,
      -48.146,
      62.292,
      8.5,
      -47.632,
      63.808,
      8.5,
      -47.632,
      63.808,
      8.5,
      -47.632,
      63.808,
      5.7,
      -48.146,
      62.292,
      5.7
    ],
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_1.OG_9279",
    "polygon": [
      -48.146,
      62.293,
      5.0,
      -48.146,
      62.293,
      5.7,
      -47.632,
      63.808,
      5.7,
      -47.632,
      63.808,
      5.7,
      -47.632,
      63.808,
      5.0,
      -48.146,
      62.293,
      5.0
    ],
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9280",
    "polygon": [
      -48.366,
      62.347,
      4.3,
      -48.366,
      62.347,
      5.0,
      -47.852,
      63.862,
      5.0,
      -47.852,
      63.862,
      5.0,
      -47.852,
      63.862,
      4.3,
      -48.366,
      62.347,
      4.3
    ],
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "Magistrale_EG_9281",
    "polygon": [
      -48.366,
      62.347,
      3.5,
      -48.366,
      62.347,
      4.3,
      -47.852,
      63.862,
      4.3,
      -47.852,
      63.862,
      4.3,
      -47.852,
      63.862,
      3.5,
      -48.366,
      62.347,
      3.5
    ],
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Magistrale_EG_9282",
    "polygon": [
      -48.366,
      62.347,
      0.0,
      -48.366,
      62.347,
      3.5,
      -48.109,
      63.105,
      3.5,
      -48.109,
      63.105,
      3.5,
      -48.109,
      63.105,
      0.0,
      -48.366,
      62.347,
      0.0
    ],
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9283",
    "polygon": [
      -48.109,
      63.105,
      0.0,
      -48.109,
      63.105,
      3.5,
      -47.852,
      63.862,
      3.5,
      -47.852,
      63.862,
      3.5,
      -47.852,
      63.862,
      0.0,
      -48.109,
      63.105,
      0.0
    ],
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_EG_9284",
    "polygon": [
      -46.222,
      68.669,
      3.6,
      -46.222,
      68.669,
      4.3,
      -45.579,
      70.563,
      4.3,
      -45.579,
      70.563,
      4.3,
      -45.579,
      70.563,
      3.6,
      -46.222,
      68.669,
      3.6
    ]
  },
  {
    "ID": "Magistrale_EG_9285",
    "polygon": [
      -46.829,
      66.879,
      3.6,
      -46.829,
      66.879,
      4.3,
      -46.222,
      68.669,
      4.3,
      -46.222,
      68.669,
      4.3,
      -46.222,
      68.669,
      3.6,
      -46.829,
      66.879,
      3.6
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9286",
    "polygon": [
      -47.436,
      65.089,
      3.6,
      -47.436,
      65.089,
      4.3,
      -46.829,
      66.879,
      4.3,
      -46.829,
      66.879,
      4.3,
      -46.829,
      66.879,
      3.6,
      -47.436,
      65.089,
      3.6
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9287",
    "polygon": [
      -47.757,
      64.142,
      3.6,
      -47.757,
      64.142,
      4.3,
      -47.436,
      65.089,
      4.3,
      -47.436,
      65.089,
      4.3,
      -47.436,
      65.089,
      3.6,
      -47.757,
      64.142,
      3.6
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9288",
    "polygon": [
      -46.222,
      68.669,
      2.9,
      -46.222,
      68.669,
      3.6,
      -45.579,
      70.563,
      3.6,
      -45.579,
      70.563,
      3.6,
      -45.579,
      70.563,
      2.9,
      -46.222,
      68.669,
      2.9
    ],
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "Magistrale_EG_9289",
    "polygon": [
      -46.829,
      66.879,
      2.9,
      -46.829,
      66.879,
      3.6,
      -46.222,
      68.669,
      3.6,
      -46.222,
      68.669,
      3.6,
      -46.222,
      68.669,
      2.9,
      -46.829,
      66.879,
      2.9
    ],
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9290",
    "polygon": [
      -47.436,
      65.089,
      2.9,
      -47.436,
      65.089,
      3.6,
      -46.829,
      66.879,
      3.6,
      -46.829,
      66.879,
      3.6,
      -46.829,
      66.879,
      2.9,
      -47.436,
      65.089,
      2.9
    ],
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9291",
    "polygon": [
      -47.757,
      64.142,
      2.9,
      -47.757,
      64.142,
      3.6,
      -47.436,
      65.089,
      3.6,
      -47.436,
      65.089,
      3.6,
      -47.436,
      65.089,
      2.9,
      -47.757,
      64.142,
      2.9
    ],
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9292",
    "polygon": [
      -45.883,
      69.668,
      0.0,
      -45.883,
      69.668,
      2.9,
      -45.579,
      70.563,
      2.9,
      -45.579,
      70.563,
      2.9,
      -45.579,
      70.563,
      0.0,
      -45.883,
      69.668,
      0.0
    ],
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Magistrale_EG_9293",
    "polygon": [
      -46.187,
      68.771,
      0.0,
      -46.187,
      68.771,
      2.9,
      -45.884,
      69.665,
      2.9,
      -45.884,
      69.665,
      2.9,
      -45.884,
      69.665,
      0.0,
      -46.187,
      68.771,
      0.0
    ],
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9294",
    "polygon": [
      -46.492,
      67.873,
      0.0,
      -46.492,
      67.873,
      2.9,
      -46.188,
      68.768,
      2.9,
      -46.188,
      68.768,
      2.9,
      -46.188,
      68.768,
      0.0,
      -46.492,
      67.873,
      0.0
    ],
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9295",
    "polygon": [
      -46.796,
      66.975,
      0.0,
      -46.796,
      66.975,
      2.9,
      -46.493,
      67.87,
      2.9,
      -46.493,
      67.87,
      2.9,
      -46.493,
      67.87,
      0.0,
      -46.796,
      66.975,
      0.0
    ],
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9296",
    "polygon": [
      -47.101,
      66.078,
      0.0,
      -47.101,
      66.078,
      2.9,
      -46.797,
      66.973,
      2.9,
      -46.797,
      66.973,
      2.9,
      -46.797,
      66.973,
      0.0,
      -47.101,
      66.078,
      0.0
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9297",
    "polygon": [
      -47.422,
      65.131,
      0.0,
      -47.422,
      65.131,
      2.9,
      -47.101,
      66.078,
      2.9,
      -47.101,
      66.078,
      2.9,
      -47.101,
      66.078,
      0.0,
      -47.422,
      65.131,
      0.0
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Magistrale_EG_9298",
    "polygon": [
      -47.743,
      64.184,
      0.0,
      -47.743,
      64.184,
      2.9,
      -47.422,
      65.131,
      2.9,
      -47.422,
      65.131,
      2.9,
      -47.422,
      65.131,
      0.0,
      -47.743,
      64.184,
      0.0
    ],
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9299",
    "polygon": [
      -52.75,
      62.893,
      13.072,
      -52.75,
      62.893,
      15.522,
      -51.223,
      62.382,
      15.522,
      -51.223,
      62.382,
      15.522,
      -51.223,
      62.382,
      13.072,
      -52.75,
      62.893,
      13.072
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9300",
    "polygon": [
      -53.461,
      63.131,
      13.072,
      -53.461,
      63.131,
      15.522,
      -52.75,
      62.893,
      15.522,
      -52.75,
      62.893,
      15.522,
      -52.75,
      62.893,
      13.072,
      -53.461,
      63.131,
      13.072
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9301",
    "polygon": [
      -54.053,
      63.33,
      13.072,
      -54.053,
      63.33,
      15.522,
      -53.461,
      63.131,
      15.522,
      -53.461,
      63.131,
      15.522,
      -53.461,
      63.131,
      13.072,
      -54.053,
      63.33,
      13.072
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9302",
    "polygon": [
      -55.58,
      63.841,
      13.072,
      -55.58,
      63.841,
      15.522,
      -54.053,
      63.33,
      15.522,
      -54.053,
      63.33,
      15.522,
      -54.053,
      63.33,
      13.072,
      -55.58,
      63.841,
      13.072
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9303",
    "polygon": [
      -56.291,
      64.079,
      13.072,
      -56.291,
      64.079,
      15.522,
      -55.58,
      63.841,
      15.522,
      -55.58,
      63.841,
      15.522,
      -55.58,
      63.841,
      13.072,
      -56.291,
      64.079,
      13.072
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9304",
    "polygon": [
      -64.19,
      66.723,
      13.072,
      -64.19,
      66.723,
      15.522,
      -62.664,
      66.212,
      15.522,
      -62.664,
      66.212,
      15.522,
      -62.664,
      66.212,
      13.072,
      -64.19,
      66.723,
      13.072
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9305",
    "polygon": [
      -64.902,
      66.962,
      13.072,
      -64.902,
      66.962,
      15.522,
      -64.19,
      66.723,
      15.522,
      -64.19,
      66.723,
      15.522,
      -64.19,
      66.723,
      13.072,
      -64.902,
      66.962,
      13.072
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9306",
    "polygon": [
      -70.494,
      68.834,
      13.072,
      -70.494,
      68.834,
      15.522,
      -68.967,
      68.322,
      15.522,
      -68.967,
      68.322,
      15.522,
      -68.967,
      68.322,
      13.072,
      -70.494,
      68.834,
      13.072
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9307",
    "polygon": [
      -71.205,
      69.072,
      13.072,
      -71.205,
      69.072,
      15.522,
      -70.494,
      68.834,
      15.522,
      -70.494,
      68.834,
      15.522,
      -70.494,
      68.834,
      13.072,
      -71.205,
      69.072,
      13.072
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9308",
    "polygon": [
      -71.797,
      69.27,
      13.072,
      -71.797,
      69.27,
      15.522,
      -71.205,
      69.072,
      15.522,
      -71.205,
      69.072,
      15.522,
      -71.205,
      69.072,
      13.072,
      -71.797,
      69.27,
      13.072
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9309",
    "polygon": [
      -73.324,
      69.781,
      13.072,
      -73.324,
      69.781,
      15.522,
      -71.797,
      69.27,
      15.522,
      -71.797,
      69.27,
      15.522,
      -71.797,
      69.27,
      13.072,
      -73.324,
      69.781,
      13.072
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9310",
    "polygon": [
      -74.035,
      70.019,
      13.072,
      -74.035,
      70.019,
      15.522,
      -73.324,
      69.781,
      15.522,
      -73.324,
      69.781,
      15.522,
      -73.324,
      69.781,
      13.072,
      -74.035,
      70.019,
      13.072
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9311",
    "polygon": [
      -76.575,
      70.869,
      13.072,
      -76.575,
      70.869,
      15.522,
      -75.048,
      70.358,
      15.522,
      -75.048,
      70.358,
      15.522,
      -75.048,
      70.358,
      13.072,
      -76.575,
      70.869,
      13.072
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9312",
    "polygon": [
      -77.286,
      71.108,
      13.072,
      -77.286,
      71.108,
      15.522,
      -76.575,
      70.869,
      15.522,
      -76.575,
      70.869,
      15.522,
      -76.575,
      70.869,
      13.072,
      -77.286,
      71.108,
      13.072
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9313",
    "polygon": [
      -77.879,
      71.306,
      13.072,
      -77.879,
      71.306,
      15.522,
      -77.286,
      71.108,
      15.522,
      -77.286,
      71.108,
      15.522,
      -77.286,
      71.108,
      13.072,
      -77.879,
      71.306,
      13.072
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9314",
    "polygon": [
      -79.405,
      71.817,
      13.072,
      -79.405,
      71.817,
      15.522,
      -77.879,
      71.306,
      15.522,
      -77.879,
      71.306,
      15.522,
      -77.879,
      71.306,
      13.072,
      -79.405,
      71.817,
      13.072
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9315",
    "polygon": [
      -80.117,
      72.055,
      13.072,
      -80.117,
      72.055,
      15.522,
      -79.405,
      71.817,
      15.522,
      -79.405,
      71.817,
      15.522,
      -79.405,
      71.817,
      13.072,
      -80.117,
      72.055,
      13.072
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9316",
    "polygon": [
      -82.449,
      72.836,
      13.072,
      -82.449,
      72.836,
      15.522,
      -80.923,
      72.325,
      15.522,
      -80.923,
      72.325,
      15.522,
      -80.923,
      72.325,
      13.072,
      -82.449,
      72.836,
      13.072
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9317",
    "polygon": [
      -83.161,
      73.074,
      13.072,
      -83.161,
      73.074,
      15.522,
      -82.449,
      72.836,
      15.522,
      -82.449,
      72.836,
      15.522,
      -82.449,
      72.836,
      13.072,
      -83.161,
      73.074,
      13.072
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9318",
    "polygon": [
      -83.753,
      73.273,
      13.072,
      -83.753,
      73.273,
      15.522,
      -83.161,
      73.074,
      15.522,
      -83.161,
      73.074,
      15.522,
      -83.161,
      73.074,
      13.072,
      -83.753,
      73.273,
      13.072
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9319",
    "polygon": [
      -85.28,
      73.784,
      13.072,
      -85.28,
      73.784,
      15.522,
      -83.753,
      73.273,
      15.522,
      -83.753,
      73.273,
      15.522,
      -83.753,
      73.273,
      13.072,
      -85.28,
      73.784,
      13.072
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9320",
    "polygon": [
      -85.991,
      74.022,
      13.072,
      -85.991,
      74.022,
      15.522,
      -85.28,
      73.784,
      15.522,
      -85.28,
      73.784,
      15.522,
      -85.28,
      73.784,
      13.072,
      -85.991,
      74.022,
      13.072
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9321",
    "polygon": [
      -88.419,
      74.835,
      13.072,
      -88.419,
      74.835,
      15.522,
      -86.892,
      74.323,
      15.522,
      -86.892,
      74.323,
      15.522,
      -86.892,
      74.323,
      13.072,
      -88.419,
      74.835,
      13.072
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9322",
    "polygon": [
      -89.13,
      75.073,
      13.072,
      -89.13,
      75.073,
      15.522,
      -88.419,
      74.835,
      15.522,
      -88.419,
      74.835,
      15.522,
      -88.419,
      74.835,
      13.072,
      -89.13,
      75.073,
      13.072
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9323",
    "polygon": [
      -89.723,
      75.271,
      13.072,
      -89.723,
      75.271,
      15.522,
      -89.13,
      75.073,
      15.522,
      -89.13,
      75.073,
      15.522,
      -89.13,
      75.073,
      13.072,
      -89.723,
      75.271,
      13.072
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9324",
    "polygon": [
      -91.249,
      75.782,
      13.072,
      -91.249,
      75.782,
      15.522,
      -89.723,
      75.271,
      15.522,
      -89.723,
      75.271,
      15.522,
      -89.723,
      75.271,
      13.072,
      -91.249,
      75.782,
      13.072
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9325",
    "polygon": [
      -91.961,
      76.02,
      13.072,
      -91.961,
      76.02,
      15.522,
      -91.249,
      75.782,
      15.522,
      -91.249,
      75.782,
      15.522,
      -91.249,
      75.782,
      13.072,
      -91.961,
      76.02,
      13.072
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9326",
    "polygon": [
      -52.753,
      62.882,
      9.322,
      -52.753,
      62.882,
      11.772,
      -51.227,
      62.371,
      11.772,
      -51.227,
      62.371,
      11.772,
      -51.227,
      62.371,
      9.322,
      -52.753,
      62.882,
      9.322
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9327",
    "polygon": [
      -53.465,
      63.12,
      9.322,
      -53.465,
      63.12,
      11.772,
      -52.753,
      62.882,
      11.772,
      -52.753,
      62.882,
      11.772,
      -52.753,
      62.882,
      9.322,
      -53.465,
      63.12,
      9.322
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9328",
    "polygon": [
      -54.057,
      63.319,
      9.322,
      -54.057,
      63.319,
      11.772,
      -53.465,
      63.12,
      11.772,
      -53.465,
      63.12,
      11.772,
      -53.465,
      63.12,
      9.322,
      -54.057,
      63.319,
      9.322
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9329",
    "polygon": [
      -55.584,
      63.83,
      9.322,
      -55.584,
      63.83,
      11.772,
      -54.057,
      63.319,
      11.772,
      -54.057,
      63.319,
      11.772,
      -54.057,
      63.319,
      9.322,
      -55.584,
      63.83,
      9.322
    ]
  },
  {
    "ID": "Block-C-Outer_2.OG_9330",
    "polygon": [
      -56.295,
      64.068,
      9.322,
      -56.295,
      64.068,
      11.772,
      -55.584,
      63.83,
      11.772,
      -55.584,
      63.83,
      11.772,
      -55.584,
      63.83,
      9.322,
      -56.295,
      64.068,
      9.322
    ]
  },
  {
    "ID": "Block-C-Outer_2.OG_9331",
    "polygon": [
      -64.194,
      66.714,
      9.322,
      -64.194,
      66.714,
      11.772,
      -62.667,
      66.203,
      11.772,
      -62.667,
      66.203,
      11.772,
      -62.667,
      66.203,
      9.322,
      -64.194,
      66.714,
      9.322
    ]
  },
  {
    "ID": "Block-C-Outer_2.OG_9332",
    "polygon": [
      -64.905,
      66.953,
      9.322,
      -64.905,
      66.953,
      11.772,
      -64.194,
      66.714,
      11.772,
      -64.194,
      66.714,
      11.772,
      -64.194,
      66.714,
      9.322,
      -64.905,
      66.953,
      9.322
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9333",
    "polygon": [
      -70.496,
      68.826,
      9.322,
      -70.496,
      68.826,
      11.772,
      -68.97,
      68.314,
      11.772,
      -68.97,
      68.314,
      11.772,
      -68.97,
      68.314,
      9.322,
      -70.496,
      68.826,
      9.322
    ],
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9334",
    "polygon": [
      -71.207,
      69.064,
      9.322,
      -71.207,
      69.064,
      11.772,
      -70.496,
      68.826,
      11.772,
      -70.496,
      68.826,
      11.772,
      -70.496,
      68.826,
      9.322,
      -71.207,
      69.064,
      9.322
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9335",
    "polygon": [
      -71.8,
      69.262,
      9.322,
      -71.8,
      69.262,
      11.772,
      -71.207,
      69.064,
      11.772,
      -71.207,
      69.064,
      11.772,
      -71.207,
      69.064,
      9.322,
      -71.8,
      69.262,
      9.322
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9336",
    "polygon": [
      -73.327,
      69.774,
      9.322,
      -73.327,
      69.774,
      11.772,
      -71.8,
      69.262,
      11.772,
      -71.8,
      69.262,
      11.772,
      -71.8,
      69.262,
      9.322,
      -73.327,
      69.774,
      9.322
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9337",
    "polygon": [
      -74.038,
      70.012,
      9.322,
      -74.038,
      70.012,
      11.772,
      -73.327,
      69.774,
      11.772,
      -73.327,
      69.774,
      11.772,
      -73.327,
      69.774,
      9.322,
      -74.038,
      70.012,
      9.322
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9338",
    "polygon": [
      -76.577,
      70.863,
      9.322,
      -76.577,
      70.863,
      11.772,
      -75.051,
      70.351,
      11.772,
      -75.051,
      70.351,
      11.772,
      -75.051,
      70.351,
      9.322,
      -76.577,
      70.863,
      9.322
    ],
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9339",
    "polygon": [
      -77.288,
      71.101,
      9.322,
      -77.288,
      71.101,
      11.772,
      -76.577,
      70.863,
      11.772,
      -76.577,
      70.863,
      11.772,
      -76.577,
      70.863,
      9.322,
      -77.288,
      71.101,
      9.322
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9340",
    "polygon": [
      -77.881,
      71.299,
      9.322,
      -77.881,
      71.299,
      11.772,
      -77.288,
      71.101,
      11.772,
      -77.288,
      71.101,
      11.772,
      -77.288,
      71.101,
      9.322,
      -77.881,
      71.299,
      9.322
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9341",
    "polygon": [
      -79.408,
      71.811,
      9.322,
      -79.408,
      71.811,
      11.772,
      -77.881,
      71.299,
      11.772,
      -77.881,
      71.299,
      11.772,
      -77.881,
      71.299,
      9.322,
      -79.408,
      71.811,
      9.322
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9342",
    "polygon": [
      -80.119,
      72.049,
      9.322,
      -80.119,
      72.049,
      11.772,
      -79.408,
      71.811,
      11.772,
      -79.408,
      71.811,
      11.772,
      -79.408,
      71.811,
      9.322,
      -80.119,
      72.049,
      9.322
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9343",
    "polygon": [
      -82.451,
      72.83,
      9.322,
      -82.451,
      72.83,
      11.772,
      -80.925,
      72.319,
      11.772,
      -80.925,
      72.319,
      11.772,
      -80.925,
      72.319,
      9.322,
      -82.451,
      72.83,
      9.322
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9344",
    "polygon": [
      -83.162,
      73.069,
      9.322,
      -83.162,
      73.069,
      11.772,
      -82.451,
      72.83,
      11.772,
      -82.451,
      72.83,
      11.772,
      -82.451,
      72.83,
      9.322,
      -83.162,
      73.069,
      9.322
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9345",
    "polygon": [
      -83.755,
      73.267,
      9.322,
      -83.755,
      73.267,
      11.772,
      -83.162,
      73.069,
      11.772,
      -83.162,
      73.069,
      11.772,
      -83.162,
      73.069,
      9.322,
      -83.755,
      73.267,
      9.322
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9346",
    "polygon": [
      -85.282,
      73.779,
      9.322,
      -85.282,
      73.779,
      11.772,
      -83.755,
      73.267,
      11.772,
      -83.755,
      73.267,
      11.772,
      -83.755,
      73.267,
      9.322,
      -85.282,
      73.779,
      9.322
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9347",
    "polygon": [
      -85.993,
      74.017,
      9.322,
      -85.993,
      74.017,
      11.772,
      -85.282,
      73.779,
      11.772,
      -85.282,
      73.779,
      11.772,
      -85.282,
      73.779,
      9.322,
      -85.993,
      74.017,
      9.322
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9348",
    "polygon": [
      -88.42,
      74.83,
      9.322,
      -88.42,
      74.83,
      11.772,
      -86.894,
      74.319,
      11.772,
      -86.894,
      74.319,
      11.772,
      -86.894,
      74.319,
      9.322,
      -88.42,
      74.83,
      9.322
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9349",
    "polygon": [
      -89.131,
      75.068,
      9.322,
      -89.131,
      75.068,
      11.772,
      -88.42,
      74.83,
      11.772,
      -88.42,
      74.83,
      11.772,
      -88.42,
      74.83,
      9.322,
      -89.131,
      75.068,
      9.322
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9350",
    "polygon": [
      -89.724,
      75.267,
      9.322,
      -89.724,
      75.267,
      11.772,
      -89.131,
      75.068,
      11.772,
      -89.131,
      75.068,
      11.772,
      -89.131,
      75.068,
      9.322,
      -89.724,
      75.267,
      9.322
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9351",
    "polygon": [
      -91.251,
      75.778,
      9.322,
      -91.251,
      75.778,
      11.772,
      -89.724,
      75.267,
      11.772,
      -89.724,
      75.267,
      11.772,
      -89.724,
      75.267,
      9.322,
      -91.251,
      75.778,
      9.322
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9352",
    "polygon": [
      -91.962,
      76.016,
      9.322,
      -91.962,
      76.016,
      11.772,
      -91.251,
      75.778,
      11.772,
      -91.251,
      75.778,
      11.772,
      -91.251,
      75.778,
      9.322,
      -91.962,
      76.016,
      9.322
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9353",
    "polygon": [
      -52.752,
      62.885,
      5.572,
      -52.752,
      62.885,
      8.022,
      -51.226,
      62.374,
      8.022,
      -51.226,
      62.374,
      8.022,
      -51.226,
      62.374,
      5.572,
      -52.752,
      62.885,
      5.572
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9354",
    "polygon": [
      -53.464,
      63.123,
      5.572,
      -53.464,
      63.123,
      8.022,
      -52.752,
      62.885,
      8.022,
      -52.752,
      62.885,
      8.022,
      -52.752,
      62.885,
      5.572,
      -53.464,
      63.123,
      5.572
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9355",
    "polygon": [
      -54.056,
      63.322,
      5.572,
      -54.056,
      63.322,
      8.022,
      -53.464,
      63.123,
      8.022,
      -53.464,
      63.123,
      8.022,
      -53.464,
      63.123,
      5.572,
      -54.056,
      63.322,
      5.572
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9356",
    "polygon": [
      -55.583,
      63.833,
      5.572,
      -55.583,
      63.833,
      8.022,
      -54.056,
      63.322,
      8.022,
      -54.056,
      63.322,
      8.022,
      -54.056,
      63.322,
      5.572,
      -55.583,
      63.833,
      5.572
    ]
  },
  {
    "ID": "Block-C-Outer_1.OG_9357",
    "polygon": [
      -56.294,
      64.071,
      5.572,
      -56.294,
      64.071,
      8.022,
      -55.583,
      63.833,
      8.022,
      -55.583,
      63.833,
      8.022,
      -55.583,
      63.833,
      5.572,
      -56.294,
      64.071,
      5.572
    ]
  },
  {
    "ID": "Block-C-Outer_1.OG_9358",
    "polygon": [
      -64.193,
      66.717,
      5.572,
      -64.193,
      66.717,
      8.022,
      -62.666,
      66.206,
      8.022,
      -62.666,
      66.206,
      8.022,
      -62.666,
      66.206,
      5.572,
      -64.193,
      66.717,
      5.572
    ]
  },
  {
    "ID": "Block-C-Outer_1.OG_9359",
    "polygon": [
      -64.904,
      66.956,
      5.572,
      -64.904,
      66.956,
      8.022,
      -64.193,
      66.717,
      8.022,
      -64.193,
      66.717,
      8.022,
      -64.193,
      66.717,
      5.572,
      -64.904,
      66.956,
      5.572
    ]
  },
  {
    "ID": "Block-C-Outer_1.OG_9360",
    "polygon": [
      -70.495,
      68.829,
      5.572,
      -70.495,
      68.829,
      8.022,
      -68.969,
      68.317,
      8.022,
      -68.969,
      68.317,
      8.022,
      -68.969,
      68.317,
      5.572,
      -70.495,
      68.829,
      5.572
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9361",
    "polygon": [
      -71.206,
      69.067,
      5.572,
      -71.206,
      69.067,
      8.022,
      -70.495,
      68.829,
      8.022,
      -70.495,
      68.829,
      8.022,
      -70.495,
      68.829,
      5.572,
      -71.206,
      69.067,
      5.572
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9362",
    "polygon": [
      -71.799,
      69.265,
      5.572,
      -71.799,
      69.265,
      8.022,
      -71.206,
      69.067,
      8.022,
      -71.206,
      69.067,
      8.022,
      -71.206,
      69.067,
      5.572,
      -71.799,
      69.265,
      5.572
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9363",
    "polygon": [
      -73.326,
      69.777,
      5.572,
      -73.326,
      69.777,
      8.022,
      -71.799,
      69.265,
      8.022,
      -71.799,
      69.265,
      8.022,
      -71.799,
      69.265,
      5.572,
      -73.326,
      69.777,
      5.572
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9364",
    "polygon": [
      -74.037,
      70.015,
      5.572,
      -74.037,
      70.015,
      8.022,
      -73.326,
      69.777,
      8.022,
      -73.326,
      69.777,
      8.022,
      -73.326,
      69.777,
      5.572,
      -74.037,
      70.015,
      5.572
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9365",
    "polygon": [
      -76.576,
      70.866,
      5.572,
      -76.576,
      70.866,
      8.022,
      -75.05,
      70.354,
      8.022,
      -75.05,
      70.354,
      8.022,
      -75.05,
      70.354,
      5.572,
      -76.576,
      70.866,
      5.572
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9366",
    "polygon": [
      -77.287,
      71.104,
      5.572,
      -77.287,
      71.104,
      8.022,
      -76.576,
      70.866,
      8.022,
      -76.576,
      70.866,
      8.022,
      -76.576,
      70.866,
      5.572,
      -77.287,
      71.104,
      5.572
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9367",
    "polygon": [
      -77.88,
      71.302,
      5.572,
      -77.88,
      71.302,
      8.022,
      -77.287,
      71.104,
      8.022,
      -77.287,
      71.104,
      8.022,
      -77.287,
      71.104,
      5.572,
      -77.88,
      71.302,
      5.572
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9368",
    "polygon": [
      -79.407,
      71.814,
      5.572,
      -79.407,
      71.814,
      8.022,
      -77.88,
      71.302,
      8.022,
      -77.88,
      71.302,
      8.022,
      -77.88,
      71.302,
      5.572,
      -79.407,
      71.814,
      5.572
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9369",
    "polygon": [
      -80.118,
      72.052,
      5.572,
      -80.118,
      72.052,
      8.022,
      -79.407,
      71.814,
      8.022,
      -79.407,
      71.814,
      8.022,
      -79.407,
      71.814,
      5.572,
      -80.118,
      72.052,
      5.572
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9370",
    "polygon": [
      -82.45,
      72.833,
      5.572,
      -82.45,
      72.833,
      8.022,
      -80.924,
      72.322,
      8.022,
      -80.924,
      72.322,
      8.022,
      -80.924,
      72.322,
      5.572,
      -82.45,
      72.833,
      5.572
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9371",
    "polygon": [
      -83.161,
      73.072,
      5.572,
      -83.161,
      73.072,
      8.022,
      -82.45,
      72.833,
      8.022,
      -82.45,
      72.833,
      8.022,
      -82.45,
      72.833,
      5.572,
      -83.161,
      73.072,
      5.572
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9372",
    "polygon": [
      -83.754,
      73.27,
      5.572,
      -83.754,
      73.27,
      8.022,
      -83.161,
      73.072,
      8.022,
      -83.161,
      73.072,
      8.022,
      -83.161,
      73.072,
      5.572,
      -83.754,
      73.27,
      5.572
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9373",
    "polygon": [
      -85.281,
      73.782,
      5.572,
      -85.281,
      73.782,
      8.022,
      -83.754,
      73.27,
      8.022,
      -83.754,
      73.27,
      8.022,
      -83.754,
      73.27,
      5.572,
      -85.281,
      73.782,
      5.572
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9374",
    "polygon": [
      -85.992,
      74.02,
      5.572,
      -85.992,
      74.02,
      8.022,
      -85.281,
      73.782,
      8.022,
      -85.281,
      73.782,
      8.022,
      -85.281,
      73.782,
      5.572,
      -85.992,
      74.02,
      5.572
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9375",
    "polygon": [
      -88.419,
      74.833,
      5.572,
      -88.419,
      74.833,
      8.022,
      -86.893,
      74.322,
      8.022,
      -86.893,
      74.322,
      8.022,
      -86.893,
      74.322,
      5.572,
      -88.419,
      74.833,
      5.572
    ],
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9376",
    "polygon": [
      -89.13,
      75.071,
      5.572,
      -89.13,
      75.071,
      8.022,
      -88.419,
      74.833,
      8.022,
      -88.419,
      74.833,
      8.022,
      -88.419,
      74.833,
      5.572,
      -89.13,
      75.071,
      5.572
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9377",
    "polygon": [
      -89.723,
      75.27,
      5.572,
      -89.723,
      75.27,
      8.022,
      -89.13,
      75.071,
      8.022,
      -89.13,
      75.071,
      8.022,
      -89.13,
      75.071,
      5.572,
      -89.723,
      75.27,
      5.572
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9378",
    "polygon": [
      -91.25,
      75.781,
      5.572,
      -91.25,
      75.781,
      8.022,
      -89.723,
      75.27,
      8.022,
      -89.723,
      75.27,
      8.022,
      -89.723,
      75.27,
      5.572,
      -91.25,
      75.781,
      5.572
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9379",
    "polygon": [
      -91.961,
      76.019,
      5.572,
      -91.961,
      76.019,
      8.022,
      -91.25,
      75.781,
      8.022,
      -91.25,
      75.781,
      8.022,
      -91.25,
      75.781,
      5.572,
      -91.961,
      76.019,
      5.572
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_9380",
    "polygon": [
      -52.753,
      62.882,
      0.572,
      -52.753,
      62.882,
      3.282,
      -51.227,
      62.371,
      3.282,
      -51.227,
      62.371,
      3.282,
      -51.227,
      62.371,
      0.572,
      -52.753,
      62.882,
      0.572
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9381",
    "polygon": [
      -53.465,
      63.12,
      0.572,
      -53.465,
      63.12,
      3.282,
      -52.753,
      62.882,
      3.282,
      -52.753,
      62.882,
      3.282,
      -52.753,
      62.882,
      0.572,
      -53.465,
      63.12,
      0.572
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9382",
    "polygon": [
      -54.057,
      63.319,
      0.572,
      -54.057,
      63.319,
      3.282,
      -53.465,
      63.12,
      3.282,
      -53.465,
      63.12,
      3.282,
      -53.465,
      63.12,
      0.572,
      -54.057,
      63.319,
      0.572
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9383",
    "polygon": [
      -55.584,
      63.83,
      0.572,
      -55.584,
      63.83,
      3.282,
      -54.057,
      63.319,
      3.282,
      -54.057,
      63.319,
      3.282,
      -54.057,
      63.319,
      0.572,
      -55.584,
      63.83,
      0.572
    ]
  },
  {
    "ID": "Block-C-Outer_EG_9384",
    "polygon": [
      -56.295,
      64.068,
      0.572,
      -56.295,
      64.068,
      3.282,
      -55.584,
      63.83,
      3.282,
      -55.584,
      63.83,
      3.282,
      -55.584,
      63.83,
      0.572,
      -56.295,
      64.068,
      0.572
    ]
  },
  {
    "ID": "Block-C-Outer_EG_9385",
    "polygon": [
      -64.966,
      66.973,
      2.282,
      -64.966,
      66.973,
      3.282,
      -62.729,
      66.224,
      3.282,
      -62.729,
      66.224,
      3.282,
      -62.729,
      66.224,
      2.282,
      -64.966,
      66.973,
      2.282
    ]
  },
  {
    "ID": "Block-C-Outer_EG_9386",
    "polygon": [
      -63.847,
      66.598,
      0.0,
      -63.847,
      66.598,
      2.282,
      -62.729,
      66.224,
      2.282,
      -62.729,
      66.224,
      2.282,
      -62.729,
      66.224,
      0.0,
      -63.847,
      66.598,
      0.0
    ]
  },
  {
    "ID": "Block-C-Outer_EG_9387",
    "polygon": [
      -64.966,
      66.973,
      0.0,
      -64.966,
      66.973,
      2.282,
      -63.847,
      66.598,
      2.282,
      -63.847,
      66.598,
      2.282,
      -63.847,
      66.598,
      0.0,
      -64.966,
      66.973,
      0.0
    ]
  },
  {
    "ID": "Block-C-Outer_EG_9388",
    "polygon": [
      -77.585,
      71.2,
      2.282,
      -77.585,
      71.2,
      3.282,
      -76.281,
      70.763,
      3.282,
      -76.281,
      70.763,
      3.282,
      -76.281,
      70.763,
      2.282,
      -77.585,
      71.2,
      2.282
    ]
  },
  {
    "ID": "Block-C-Outer_EG_9389",
    "polygon": [
      -77.585,
      71.2,
      0.0,
      -77.585,
      71.2,
      2.282,
      -76.281,
      70.763,
      2.282,
      -76.281,
      70.763,
      2.282,
      -76.281,
      70.763,
      0.0,
      -77.585,
      71.2,
      0.0
    ]
  },
  {
    "ID": "Block-C-Outer_EG_9390",
    "polygon": [
      -82.451,
      72.83,
      -0.006,
      -82.451,
      72.83,
      3.022,
      -80.925,
      72.319,
      3.022,
      -80.925,
      72.319,
      3.022,
      -80.925,
      72.319,
      -0.006,
      -82.451,
      72.83,
      -0.006
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9391",
    "polygon": [
      -83.162,
      73.069,
      -0.006,
      -83.162,
      73.069,
      3.022,
      -82.451,
      72.83,
      3.022,
      -82.451,
      72.83,
      3.022,
      -82.451,
      72.83,
      -0.006,
      -83.162,
      73.069,
      -0.006
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9392",
    "polygon": [
      -83.755,
      73.267,
      -0.006,
      -83.755,
      73.267,
      3.022,
      -83.162,
      73.069,
      3.022,
      -83.162,
      73.069,
      3.022,
      -83.162,
      73.069,
      -0.006,
      -83.755,
      73.267,
      -0.006
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9393",
    "polygon": [
      -85.282,
      73.779,
      -0.006,
      -85.282,
      73.779,
      3.022,
      -83.755,
      73.267,
      3.022,
      -83.755,
      73.267,
      3.022,
      -83.755,
      73.267,
      -0.006,
      -85.282,
      73.779,
      -0.006
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-C-Outer_EG_9394",
    "polygon": [
      -85.993,
      74.017,
      -0.006,
      -85.993,
      74.017,
      3.022,
      -85.282,
      73.779,
      3.022,
      -85.282,
      73.779,
      3.022,
      -85.282,
      73.779,
      -0.006,
      -85.993,
      74.017,
      -0.006
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9395",
    "polygon": [
      -88.42,
      74.83,
      -0.006,
      -88.42,
      74.83,
      3.022,
      -86.894,
      74.319,
      3.022,
      -86.894,
      74.319,
      3.022,
      -86.894,
      74.319,
      -0.006,
      -88.42,
      74.83,
      -0.006
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9396",
    "polygon": [
      -89.131,
      75.068,
      -0.006,
      -89.131,
      75.068,
      3.022,
      -88.42,
      74.83,
      3.022,
      -88.42,
      74.83,
      3.022,
      -88.42,
      74.83,
      -0.006,
      -89.131,
      75.068,
      -0.006
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9397",
    "polygon": [
      -89.724,
      75.267,
      -0.006,
      -89.724,
      75.267,
      3.022,
      -89.131,
      75.068,
      3.022,
      -89.131,
      75.068,
      3.022,
      -89.131,
      75.068,
      -0.006,
      -89.724,
      75.267,
      -0.006
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_9398",
    "polygon": [
      -91.251,
      75.778,
      -0.006,
      -91.251,
      75.778,
      3.022,
      -89.724,
      75.267,
      3.022,
      -89.724,
      75.267,
      3.022,
      -89.724,
      75.267,
      -0.006,
      -91.251,
      75.778,
      -0.006
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-C-Outer_EG_9399",
    "polygon": [
      -91.962,
      76.016,
      -0.006,
      -91.962,
      76.016,
      3.022,
      -91.251,
      75.778,
      3.022,
      -91.251,
      75.778,
      3.022,
      -91.251,
      75.778,
      -0.006,
      -91.962,
      76.016,
      -0.006
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9400",
    "polygon": [
      99.752,
      11.835,
      13.072,
      99.752,
      11.835,
      15.522,
      100.462,
      11.594,
      15.522,
      100.462,
      11.594,
      15.522,
      100.462,
      11.594,
      13.072,
      99.752,
      11.835,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9401",
    "polygon": [
      99.16,
      12.036,
      13.072,
      99.16,
      12.036,
      15.522,
      99.752,
      11.835,
      15.522,
      99.752,
      11.835,
      15.522,
      99.752,
      11.835,
      13.072,
      99.16,
      12.036,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9402",
    "polygon": [
      97.635,
      12.553,
      13.072,
      97.635,
      12.553,
      15.522,
      99.16,
      12.036,
      15.522,
      99.16,
      12.036,
      15.522,
      99.16,
      12.036,
      13.072,
      97.635,
      12.553,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9403",
    "polygon": [
      96.925,
      12.794,
      13.072,
      96.925,
      12.794,
      15.522,
      97.635,
      12.553,
      15.522,
      97.635,
      12.553,
      15.522,
      97.635,
      12.553,
      13.072,
      96.925,
      12.794,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9404",
    "polygon": [
      94.665,
      13.56,
      13.072,
      94.665,
      13.56,
      15.522,
      96.19,
      13.043,
      15.522,
      96.19,
      13.043,
      15.522,
      96.19,
      13.043,
      13.072,
      94.665,
      13.56,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9405",
    "polygon": [
      93.955,
      13.801,
      13.072,
      93.955,
      13.801,
      15.522,
      94.665,
      13.56,
      15.522,
      94.665,
      13.56,
      15.522,
      94.665,
      13.56,
      13.072,
      93.955,
      13.801,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9406",
    "polygon": [
      93.363,
      14.002,
      13.072,
      93.363,
      14.002,
      15.522,
      93.955,
      13.801,
      15.522,
      93.955,
      13.801,
      15.522,
      93.955,
      13.801,
      13.072,
      93.363,
      14.002,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9407",
    "polygon": [
      91.838,
      14.519,
      13.072,
      91.838,
      14.519,
      15.522,
      93.363,
      14.002,
      15.522,
      93.363,
      14.002,
      15.522,
      93.363,
      14.002,
      13.072,
      91.838,
      14.519,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9408",
    "polygon": [
      91.128,
      14.76,
      13.072,
      91.128,
      14.76,
      15.522,
      91.838,
      14.519,
      15.522,
      91.838,
      14.519,
      15.522,
      91.838,
      14.519,
      13.072,
      91.128,
      14.76,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9409",
    "polygon": [
      88.869,
      15.526,
      13.072,
      88.869,
      15.526,
      15.522,
      90.393,
      15.009,
      15.522,
      90.393,
      15.009,
      15.522,
      90.393,
      15.009,
      13.072,
      88.869,
      15.526,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9410",
    "polygon": [
      88.158,
      15.767,
      13.072,
      88.158,
      15.767,
      15.522,
      88.869,
      15.526,
      15.522,
      88.869,
      15.526,
      15.522,
      88.869,
      15.526,
      13.072,
      88.158,
      15.767,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9411",
    "polygon": [
      87.566,
      15.968,
      13.072,
      87.566,
      15.968,
      15.522,
      88.158,
      15.767,
      15.522,
      88.158,
      15.767,
      15.522,
      88.158,
      15.767,
      13.072,
      87.566,
      15.968,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9412",
    "polygon": [
      86.042,
      16.485,
      13.072,
      86.042,
      16.485,
      15.522,
      87.566,
      15.968,
      15.522,
      87.566,
      15.968,
      15.522,
      87.566,
      15.968,
      13.072,
      86.042,
      16.485,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9413",
    "polygon": [
      85.331,
      16.726,
      13.072,
      85.331,
      16.726,
      15.522,
      86.042,
      16.485,
      15.522,
      86.042,
      16.485,
      15.522,
      86.042,
      16.485,
      13.072,
      85.331,
      16.726,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9414",
    "polygon": [
      83.072,
      17.493,
      13.072,
      83.072,
      17.493,
      15.522,
      84.597,
      16.976,
      15.522,
      84.597,
      16.976,
      15.522,
      84.597,
      16.976,
      13.072,
      83.072,
      17.493,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9415",
    "polygon": [
      82.362,
      17.734,
      13.072,
      82.362,
      17.734,
      15.522,
      83.072,
      17.493,
      15.522,
      83.072,
      17.493,
      15.522,
      83.072,
      17.493,
      13.072,
      82.362,
      17.734,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9416",
    "polygon": [
      81.77,
      17.934,
      13.072,
      81.77,
      17.934,
      15.522,
      82.362,
      17.734,
      15.522,
      82.362,
      17.734,
      15.522,
      82.362,
      17.734,
      13.072,
      81.77,
      17.934,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9417",
    "polygon": [
      80.245,
      18.452,
      13.072,
      80.245,
      18.452,
      15.522,
      81.77,
      17.934,
      15.522,
      81.77,
      17.934,
      15.522,
      81.77,
      17.934,
      13.072,
      80.245,
      18.452,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9418",
    "polygon": [
      79.535,
      18.692,
      13.072,
      79.535,
      18.692,
      15.522,
      80.245,
      18.452,
      15.522,
      80.245,
      18.452,
      15.522,
      80.245,
      18.452,
      13.072,
      79.535,
      18.692,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9419",
    "polygon": [
      74.147,
      20.52,
      13.072,
      74.147,
      20.52,
      15.522,
      75.672,
      20.003,
      15.522,
      75.672,
      20.003,
      15.522,
      75.672,
      20.003,
      13.072,
      74.147,
      20.52,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9420",
    "polygon": [
      73.437,
      20.761,
      13.072,
      73.437,
      20.761,
      15.522,
      74.147,
      20.52,
      15.522,
      74.147,
      20.52,
      15.522,
      74.147,
      20.52,
      13.072,
      73.437,
      20.761,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9421",
    "polygon": [
      65.088,
      23.593,
      13.072,
      65.088,
      23.593,
      15.522,
      66.612,
      23.076,
      15.522,
      66.612,
      23.076,
      15.522,
      66.612,
      23.076,
      13.072,
      65.088,
      23.593,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9422",
    "polygon": [
      64.377,
      23.834,
      13.072,
      64.377,
      23.834,
      15.522,
      65.088,
      23.593,
      15.522,
      65.088,
      23.593,
      15.522,
      65.088,
      23.593,
      13.072,
      64.377,
      23.834,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9423",
    "polygon": [
      63.786,
      24.035,
      13.072,
      63.786,
      24.035,
      15.522,
      64.377,
      23.834,
      15.522,
      64.377,
      23.834,
      15.522,
      64.377,
      23.834,
      13.072,
      63.786,
      24.035,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9424",
    "polygon": [
      62.261,
      24.552,
      13.072,
      62.261,
      24.552,
      15.522,
      63.786,
      24.035,
      15.522,
      63.786,
      24.035,
      15.522,
      63.786,
      24.035,
      13.072,
      62.261,
      24.552,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_9425",
    "polygon": [
      61.551,
      24.793,
      13.072,
      61.551,
      24.793,
      15.522,
      62.261,
      24.552,
      15.522,
      62.261,
      24.552,
      15.522,
      62.261,
      24.552,
      13.072,
      61.551,
      24.793,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9426",
    "polygon": [
      100.462,
      11.594,
      9.322,
      100.462,
      11.594,
      11.772,
      101.987,
      11.077,
      11.772,
      101.987,
      11.077,
      11.772,
      101.987,
      11.077,
      9.322,
      100.462,
      11.594,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9427",
    "polygon": [
      99.752,
      11.835,
      9.322,
      99.752,
      11.835,
      11.772,
      100.462,
      11.594,
      11.772,
      100.462,
      11.594,
      11.772,
      100.462,
      11.594,
      9.322,
      99.752,
      11.835,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9428",
    "polygon": [
      99.16,
      12.036,
      9.322,
      99.16,
      12.036,
      11.772,
      99.752,
      11.835,
      11.772,
      99.752,
      11.835,
      11.772,
      99.752,
      11.835,
      9.322,
      99.16,
      12.036,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9429",
    "polygon": [
      97.635,
      12.553,
      9.322,
      97.635,
      12.553,
      11.772,
      99.16,
      12.036,
      11.772,
      99.16,
      12.036,
      11.772,
      99.16,
      12.036,
      9.322,
      97.635,
      12.553,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9430",
    "polygon": [
      96.925,
      12.794,
      9.322,
      96.925,
      12.794,
      11.772,
      97.635,
      12.553,
      11.772,
      97.635,
      12.553,
      11.772,
      97.635,
      12.553,
      9.322,
      96.925,
      12.794,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9431",
    "polygon": [
      94.665,
      13.56,
      9.322,
      94.665,
      13.56,
      11.772,
      96.19,
      13.043,
      11.772,
      96.19,
      13.043,
      11.772,
      96.19,
      13.043,
      9.322,
      94.665,
      13.56,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9432",
    "polygon": [
      93.955,
      13.801,
      9.322,
      93.955,
      13.801,
      11.772,
      94.665,
      13.56,
      11.772,
      94.665,
      13.56,
      11.772,
      94.665,
      13.56,
      9.322,
      93.955,
      13.801,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9433",
    "polygon": [
      93.363,
      14.002,
      9.322,
      93.363,
      14.002,
      11.772,
      93.955,
      13.801,
      11.772,
      93.955,
      13.801,
      11.772,
      93.955,
      13.801,
      9.322,
      93.363,
      14.002,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9434",
    "polygon": [
      91.838,
      14.519,
      9.322,
      91.838,
      14.519,
      11.772,
      93.363,
      14.002,
      11.772,
      93.363,
      14.002,
      11.772,
      93.363,
      14.002,
      9.322,
      91.838,
      14.519,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9435",
    "polygon": [
      91.128,
      14.76,
      9.322,
      91.128,
      14.76,
      11.772,
      91.838,
      14.519,
      11.772,
      91.838,
      14.519,
      11.772,
      91.838,
      14.519,
      9.322,
      91.128,
      14.76,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9436",
    "polygon": [
      88.869,
      15.526,
      9.322,
      88.869,
      15.526,
      11.772,
      90.393,
      15.009,
      11.772,
      90.393,
      15.009,
      11.772,
      90.393,
      15.009,
      9.322,
      88.869,
      15.526,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9437",
    "polygon": [
      88.158,
      15.767,
      9.322,
      88.158,
      15.767,
      11.772,
      88.869,
      15.526,
      11.772,
      88.869,
      15.526,
      11.772,
      88.869,
      15.526,
      9.322,
      88.158,
      15.767,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9438",
    "polygon": [
      87.566,
      15.968,
      9.322,
      87.566,
      15.968,
      11.772,
      88.158,
      15.767,
      11.772,
      88.158,
      15.767,
      11.772,
      88.158,
      15.767,
      9.322,
      87.566,
      15.968,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9439",
    "polygon": [
      86.042,
      16.485,
      9.322,
      86.042,
      16.485,
      11.772,
      87.566,
      15.968,
      11.772,
      87.566,
      15.968,
      11.772,
      87.566,
      15.968,
      9.322,
      86.042,
      16.485,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9440",
    "polygon": [
      85.331,
      16.726,
      9.322,
      85.331,
      16.726,
      11.772,
      86.042,
      16.485,
      11.772,
      86.042,
      16.485,
      11.772,
      86.042,
      16.485,
      9.322,
      85.331,
      16.726,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9441",
    "polygon": [
      83.072,
      17.493,
      9.322,
      83.072,
      17.493,
      11.772,
      84.597,
      16.976,
      11.772,
      84.597,
      16.976,
      11.772,
      84.597,
      16.976,
      9.322,
      83.072,
      17.493,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9442",
    "polygon": [
      82.362,
      17.734,
      9.322,
      82.362,
      17.734,
      11.772,
      83.072,
      17.493,
      11.772,
      83.072,
      17.493,
      11.772,
      83.072,
      17.493,
      9.322,
      82.362,
      17.734,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9443",
    "polygon": [
      81.77,
      17.934,
      9.322,
      81.77,
      17.934,
      11.772,
      82.362,
      17.734,
      11.772,
      82.362,
      17.734,
      11.772,
      82.362,
      17.734,
      9.322,
      81.77,
      17.934,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9444",
    "polygon": [
      80.245,
      18.452,
      9.322,
      80.245,
      18.452,
      11.772,
      81.77,
      17.934,
      11.772,
      81.77,
      17.934,
      11.772,
      81.77,
      17.934,
      9.322,
      80.245,
      18.452,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9445",
    "polygon": [
      79.535,
      18.692,
      9.322,
      79.535,
      18.692,
      11.772,
      80.245,
      18.452,
      11.772,
      80.245,
      18.452,
      11.772,
      80.245,
      18.452,
      9.322,
      79.535,
      18.692,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9446",
    "polygon": [
      74.147,
      20.52,
      9.322,
      74.147,
      20.52,
      11.772,
      75.672,
      20.003,
      11.772,
      75.672,
      20.003,
      11.772,
      75.672,
      20.003,
      9.322,
      74.147,
      20.52,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9447",
    "polygon": [
      73.437,
      20.761,
      9.322,
      73.437,
      20.761,
      11.772,
      74.147,
      20.52,
      11.772,
      74.147,
      20.52,
      11.772,
      74.147,
      20.52,
      9.322,
      73.437,
      20.761,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9448",
    "polygon": [
      65.088,
      23.593,
      9.322,
      65.088,
      23.593,
      11.772,
      66.612,
      23.076,
      11.772,
      66.612,
      23.076,
      11.772,
      66.612,
      23.076,
      9.322,
      65.088,
      23.593,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9449",
    "polygon": [
      64.377,
      23.834,
      9.322,
      64.377,
      23.834,
      11.772,
      65.088,
      23.593,
      11.772,
      65.088,
      23.593,
      11.772,
      65.088,
      23.593,
      9.322,
      64.377,
      23.834,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9450",
    "polygon": [
      63.786,
      24.035,
      9.322,
      63.786,
      24.035,
      11.772,
      64.377,
      23.834,
      11.772,
      64.377,
      23.834,
      11.772,
      64.377,
      23.834,
      9.322,
      63.786,
      24.035,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9451",
    "polygon": [
      62.261,
      24.552,
      9.322,
      62.261,
      24.552,
      11.772,
      63.786,
      24.035,
      11.772,
      63.786,
      24.035,
      11.772,
      63.786,
      24.035,
      9.322,
      62.261,
      24.552,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_9452",
    "polygon": [
      61.551,
      24.793,
      9.322,
      61.551,
      24.793,
      11.772,
      62.261,
      24.552,
      11.772,
      62.261,
      24.552,
      11.772,
      62.261,
      24.552,
      9.322,
      61.551,
      24.793,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9453",
    "polygon": [
      100.463,
      11.597,
      5.572,
      100.463,
      11.597,
      8.022,
      101.988,
      11.08,
      8.022,
      101.988,
      11.08,
      8.022,
      101.988,
      11.08,
      5.572,
      100.463,
      11.597,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9454",
    "polygon": [
      99.753,
      11.838,
      5.572,
      99.753,
      11.838,
      8.022,
      100.463,
      11.597,
      8.022,
      100.463,
      11.597,
      8.022,
      100.463,
      11.597,
      5.572,
      99.753,
      11.838,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9455",
    "polygon": [
      99.161,
      12.039,
      5.572,
      99.161,
      12.039,
      8.022,
      99.753,
      11.838,
      8.022,
      99.753,
      11.838,
      8.022,
      99.753,
      11.838,
      5.572,
      99.161,
      12.039,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9456",
    "polygon": [
      97.636,
      12.556,
      5.572,
      97.636,
      12.556,
      8.022,
      99.161,
      12.039,
      8.022,
      99.161,
      12.039,
      8.022,
      99.161,
      12.039,
      5.572,
      97.636,
      12.556,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9457",
    "polygon": [
      96.926,
      12.797,
      5.572,
      96.926,
      12.797,
      8.022,
      97.636,
      12.556,
      8.022,
      97.636,
      12.556,
      8.022,
      97.636,
      12.556,
      5.572,
      96.926,
      12.797,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9458",
    "polygon": [
      94.666,
      13.563,
      5.572,
      94.666,
      13.563,
      8.022,
      96.191,
      13.046,
      8.022,
      96.191,
      13.046,
      8.022,
      96.191,
      13.046,
      5.572,
      94.666,
      13.563,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9459",
    "polygon": [
      93.956,
      13.804,
      5.572,
      93.956,
      13.804,
      8.022,
      94.666,
      13.563,
      8.022,
      94.666,
      13.563,
      8.022,
      94.666,
      13.563,
      5.572,
      93.956,
      13.804,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9460",
    "polygon": [
      93.364,
      14.005,
      5.572,
      93.364,
      14.005,
      8.022,
      93.956,
      13.804,
      8.022,
      93.956,
      13.804,
      8.022,
      93.956,
      13.804,
      5.572,
      93.364,
      14.005,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9461",
    "polygon": [
      91.839,
      14.522,
      5.572,
      91.839,
      14.522,
      8.022,
      93.364,
      14.005,
      8.022,
      93.364,
      14.005,
      8.022,
      93.364,
      14.005,
      5.572,
      91.839,
      14.522,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9462",
    "polygon": [
      91.129,
      14.763,
      5.572,
      91.129,
      14.763,
      8.022,
      91.839,
      14.522,
      8.022,
      91.839,
      14.522,
      8.022,
      91.839,
      14.522,
      5.572,
      91.129,
      14.763,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9463",
    "polygon": [
      88.87,
      15.529,
      5.572,
      88.87,
      15.529,
      8.022,
      90.394,
      15.012,
      8.022,
      90.394,
      15.012,
      8.022,
      90.394,
      15.012,
      5.572,
      88.87,
      15.529,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9464",
    "polygon": [
      88.159,
      15.77,
      5.572,
      88.159,
      15.77,
      8.022,
      88.87,
      15.529,
      8.022,
      88.87,
      15.529,
      8.022,
      88.87,
      15.529,
      5.572,
      88.159,
      15.77,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9465",
    "polygon": [
      87.567,
      15.971,
      5.572,
      87.567,
      15.971,
      8.022,
      88.159,
      15.77,
      8.022,
      88.159,
      15.77,
      8.022,
      88.159,
      15.77,
      5.572,
      87.567,
      15.971,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9466",
    "polygon": [
      86.043,
      16.488,
      5.572,
      86.043,
      16.488,
      8.022,
      87.567,
      15.971,
      8.022,
      87.567,
      15.971,
      8.022,
      87.567,
      15.971,
      5.572,
      86.043,
      16.488,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9467",
    "polygon": [
      85.332,
      16.729,
      5.572,
      85.332,
      16.729,
      8.022,
      86.043,
      16.488,
      8.022,
      86.043,
      16.488,
      8.022,
      86.043,
      16.488,
      5.572,
      85.332,
      16.729,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9468",
    "polygon": [
      83.073,
      17.496,
      5.572,
      83.073,
      17.496,
      8.022,
      84.598,
      16.978,
      8.022,
      84.598,
      16.978,
      8.022,
      84.598,
      16.978,
      5.572,
      83.073,
      17.496,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9469",
    "polygon": [
      82.363,
      17.737,
      5.572,
      82.363,
      17.737,
      8.022,
      83.073,
      17.496,
      8.022,
      83.073,
      17.496,
      8.022,
      83.073,
      17.496,
      5.572,
      82.363,
      17.737,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9470",
    "polygon": [
      81.771,
      17.937,
      5.572,
      81.771,
      17.937,
      8.022,
      82.363,
      17.737,
      8.022,
      82.363,
      17.737,
      8.022,
      82.363,
      17.737,
      5.572,
      81.771,
      17.937,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9471",
    "polygon": [
      80.246,
      18.454,
      5.572,
      80.246,
      18.454,
      8.022,
      81.771,
      17.937,
      8.022,
      81.771,
      17.937,
      8.022,
      81.771,
      17.937,
      5.572,
      80.246,
      18.454,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9472",
    "polygon": [
      79.536,
      18.695,
      5.572,
      79.536,
      18.695,
      8.022,
      80.246,
      18.454,
      8.022,
      80.246,
      18.454,
      8.022,
      80.246,
      18.454,
      5.572,
      79.536,
      18.695,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9473",
    "polygon": [
      74.148,
      20.523,
      5.572,
      74.148,
      20.523,
      8.022,
      75.673,
      20.006,
      8.022,
      75.673,
      20.006,
      8.022,
      75.673,
      20.006,
      5.572,
      74.148,
      20.523,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9474",
    "polygon": [
      73.438,
      20.764,
      5.572,
      73.438,
      20.764,
      8.022,
      74.148,
      20.523,
      8.022,
      74.148,
      20.523,
      8.022,
      74.148,
      20.523,
      5.572,
      73.438,
      20.764,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9475",
    "polygon": [
      65.089,
      23.596,
      5.572,
      65.089,
      23.596,
      8.022,
      66.613,
      23.079,
      8.022,
      66.613,
      23.079,
      8.022,
      66.613,
      23.079,
      5.572,
      65.089,
      23.596,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9476",
    "polygon": [
      64.378,
      23.837,
      5.572,
      64.378,
      23.837,
      8.022,
      65.089,
      23.596,
      8.022,
      65.089,
      23.596,
      8.022,
      65.089,
      23.596,
      5.572,
      64.378,
      23.837,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9477",
    "polygon": [
      63.787,
      24.037,
      5.572,
      63.787,
      24.037,
      8.022,
      64.378,
      23.837,
      8.022,
      64.378,
      23.837,
      8.022,
      64.378,
      23.837,
      5.572,
      63.787,
      24.037,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9478",
    "polygon": [
      62.262,
      24.555,
      5.572,
      62.262,
      24.555,
      8.022,
      63.787,
      24.037,
      8.022,
      63.787,
      24.037,
      8.022,
      63.787,
      24.037,
      5.572,
      62.262,
      24.555,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_9479",
    "polygon": [
      61.552,
      24.796,
      5.572,
      61.552,
      24.796,
      8.022,
      62.262,
      24.555,
      8.022,
      62.262,
      24.555,
      8.022,
      62.262,
      24.555,
      5.572,
      61.552,
      24.796,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9480",
    "polygon": [
      100.463,
      11.597,
      0.572,
      100.463,
      11.597,
      3.332,
      101.988,
      11.08,
      3.332,
      101.988,
      11.08,
      3.332,
      101.988,
      11.08,
      0.572,
      100.463,
      11.597,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9481",
    "polygon": [
      99.753,
      11.838,
      0.572,
      99.753,
      11.838,
      3.332,
      100.463,
      11.597,
      3.332,
      100.463,
      11.597,
      3.332,
      100.463,
      11.597,
      0.572,
      99.753,
      11.838,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9482",
    "polygon": [
      99.161,
      12.039,
      0.572,
      99.161,
      12.039,
      3.332,
      99.753,
      11.838,
      3.332,
      99.753,
      11.838,
      3.332,
      99.753,
      11.838,
      0.572,
      99.161,
      12.039,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9483",
    "polygon": [
      97.636,
      12.556,
      0.572,
      97.636,
      12.556,
      3.332,
      99.161,
      12.039,
      3.332,
      99.161,
      12.039,
      3.332,
      99.161,
      12.039,
      0.572,
      97.636,
      12.556,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9484",
    "polygon": [
      96.926,
      12.797,
      0.572,
      96.926,
      12.797,
      3.332,
      97.636,
      12.556,
      3.332,
      97.636,
      12.556,
      3.332,
      97.636,
      12.556,
      0.572,
      96.926,
      12.797,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9485",
    "polygon": [
      94.666,
      13.564,
      0.572,
      94.666,
      13.564,
      3.332,
      96.191,
      13.046,
      3.332,
      96.191,
      13.046,
      3.332,
      96.191,
      13.046,
      0.572,
      94.666,
      13.564,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9486",
    "polygon": [
      93.956,
      13.805,
      0.572,
      93.956,
      13.805,
      3.332,
      94.666,
      13.564,
      3.332,
      94.666,
      13.564,
      3.332,
      94.666,
      13.564,
      0.572,
      93.956,
      13.805,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9487",
    "polygon": [
      93.364,
      14.005,
      0.572,
      93.364,
      14.005,
      3.332,
      93.956,
      13.805,
      3.332,
      93.956,
      13.805,
      3.332,
      93.956,
      13.805,
      0.572,
      93.364,
      14.005,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9488",
    "polygon": [
      91.839,
      14.522,
      0.572,
      91.839,
      14.522,
      3.332,
      93.364,
      14.005,
      3.332,
      93.364,
      14.005,
      3.332,
      93.364,
      14.005,
      0.572,
      91.839,
      14.522,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9489",
    "polygon": [
      91.129,
      14.763,
      0.572,
      91.129,
      14.763,
      3.332,
      91.839,
      14.522,
      3.332,
      91.839,
      14.522,
      3.332,
      91.839,
      14.522,
      0.572,
      91.129,
      14.763,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9490",
    "polygon": [
      88.87,
      15.53,
      0.572,
      88.87,
      15.53,
      3.332,
      90.394,
      15.013,
      3.332,
      90.394,
      15.013,
      3.332,
      90.394,
      15.013,
      0.572,
      88.87,
      15.53,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9491",
    "polygon": [
      88.159,
      15.771,
      0.572,
      88.159,
      15.771,
      3.332,
      88.87,
      15.53,
      3.332,
      88.87,
      15.53,
      3.332,
      88.87,
      15.53,
      0.572,
      88.159,
      15.771,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9492",
    "polygon": [
      87.568,
      15.972,
      0.572,
      87.568,
      15.972,
      3.332,
      88.159,
      15.771,
      3.332,
      88.159,
      15.771,
      3.332,
      88.159,
      15.771,
      0.572,
      87.568,
      15.972,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9493",
    "polygon": [
      86.043,
      16.489,
      0.572,
      86.043,
      16.489,
      3.332,
      87.568,
      15.972,
      3.332,
      87.568,
      15.972,
      3.332,
      87.568,
      15.972,
      0.572,
      86.043,
      16.489,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9494",
    "polygon": [
      85.333,
      16.73,
      0.572,
      85.333,
      16.73,
      3.332,
      86.043,
      16.489,
      3.332,
      86.043,
      16.489,
      3.332,
      86.043,
      16.489,
      0.572,
      85.333,
      16.73,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9495",
    "polygon": [
      83.073,
      17.496,
      0.572,
      83.073,
      17.496,
      3.332,
      84.598,
      16.979,
      3.332,
      84.598,
      16.979,
      3.332,
      84.598,
      16.979,
      0.572,
      83.073,
      17.496,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9496",
    "polygon": [
      82.363,
      17.737,
      0.572,
      82.363,
      17.737,
      3.332,
      83.073,
      17.496,
      3.332,
      83.073,
      17.496,
      3.332,
      83.073,
      17.496,
      0.572,
      82.363,
      17.737,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9497",
    "polygon": [
      81.771,
      17.938,
      0.572,
      81.771,
      17.938,
      3.332,
      82.363,
      17.737,
      3.332,
      82.363,
      17.737,
      3.332,
      82.363,
      17.737,
      0.572,
      81.771,
      17.938,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9498",
    "polygon": [
      80.246,
      18.455,
      0.572,
      80.246,
      18.455,
      3.332,
      81.771,
      17.938,
      3.332,
      81.771,
      17.938,
      3.332,
      81.771,
      17.938,
      0.572,
      80.246,
      18.455,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9499",
    "polygon": [
      79.536,
      18.696,
      0.572,
      79.536,
      18.696,
      3.332,
      80.246,
      18.455,
      3.332,
      80.246,
      18.455,
      3.332,
      80.246,
      18.455,
      0.572,
      79.536,
      18.696,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9502",
    "polygon": [
      65.089,
      23.596,
      0.572,
      65.089,
      23.596,
      3.332,
      66.613,
      23.079,
      3.332,
      66.613,
      23.079,
      3.332,
      66.613,
      23.079,
      0.572,
      65.089,
      23.596,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9503",
    "polygon": [
      64.379,
      23.837,
      0.572,
      64.379,
      23.837,
      3.332,
      65.089,
      23.596,
      3.332,
      65.089,
      23.596,
      3.332,
      65.089,
      23.596,
      0.572,
      64.379,
      23.837,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9504",
    "polygon": [
      63.787,
      24.038,
      0.572,
      63.787,
      24.038,
      3.332,
      64.379,
      23.837,
      3.332,
      64.379,
      23.837,
      3.332,
      64.379,
      23.837,
      0.572,
      63.787,
      24.038,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9505",
    "polygon": [
      62.262,
      24.555,
      0.572,
      62.262,
      24.555,
      3.332,
      63.787,
      24.038,
      3.332,
      63.787,
      24.038,
      3.332,
      63.787,
      24.038,
      0.572,
      62.262,
      24.555,
      0.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9506",
    "polygon": [
      61.552,
      24.796,
      0.572,
      61.552,
      24.796,
      3.332,
      62.262,
      24.555,
      3.332,
      62.262,
      24.555,
      3.332,
      62.262,
      24.555,
      0.572,
      61.552,
      24.796,
      0.572
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9507",
    "polygon": [
      73.451,
      20.76,
      2.332,
      73.451,
      20.76,
      3.332,
      75.685,
      20.002,
      3.332,
      75.685,
      20.002,
      3.332,
      75.685,
      20.002,
      2.332,
      73.451,
      20.76,
      2.332
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_9508",
    "polygon": [
      74.568,
      20.381,
      0.05,
      74.568,
      20.381,
      2.332,
      75.685,
      20.002,
      2.332,
      75.685,
      20.002,
      2.332,
      75.685,
      20.002,
      0.05,
      74.568,
      20.381,
      0.05
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_9509",
    "polygon": [
      73.451,
      20.76,
      0.05,
      73.451,
      20.76,
      2.332,
      74.568,
      20.381,
      2.332,
      74.568,
      20.381,
      2.332,
      74.568,
      20.381,
      0.05,
      73.451,
      20.76,
      0.05
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9511",
    "polygon": [
      36.996,
      99.198,
      13.073,
      36.996,
      99.198,
      15.523,
      37.234,
      99.909,
      15.523,
      37.234,
      99.909,
      15.523,
      37.234,
      99.909,
      13.073,
      36.996,
      99.198,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9512",
    "polygon": [
      36.485,
      97.671,
      13.073,
      36.485,
      97.671,
      15.523,
      36.996,
      99.198,
      15.523,
      36.996,
      99.198,
      15.523,
      36.996,
      99.198,
      13.073,
      36.485,
      97.671,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9513",
    "polygon": [
      36.286,
      97.079,
      13.073,
      36.286,
      97.079,
      15.523,
      36.485,
      97.671,
      15.523,
      36.485,
      97.671,
      15.523,
      36.485,
      97.671,
      13.073,
      36.286,
      97.079,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9514",
    "polygon": [
      36.048,
      96.367,
      13.073,
      36.048,
      96.367,
      15.523,
      36.286,
      97.079,
      15.523,
      36.286,
      97.079,
      15.523,
      36.286,
      97.079,
      13.073,
      36.048,
      96.367,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9515",
    "polygon": [
      35.537,
      94.841,
      13.073,
      35.537,
      94.841,
      15.523,
      36.048,
      96.367,
      15.523,
      36.048,
      96.367,
      15.523,
      36.048,
      96.367,
      13.073,
      35.537,
      94.841,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9516",
    "polygon": [
      35.0,
      93.24,
      13.073,
      35.0,
      93.24,
      15.523,
      35.239,
      93.951,
      15.523,
      35.239,
      93.951,
      15.523,
      35.239,
      93.951,
      13.073,
      35.0,
      93.24,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9517",
    "polygon": [
      34.489,
      91.714,
      13.073,
      34.489,
      91.714,
      15.523,
      35.0,
      93.24,
      15.523,
      35.0,
      93.24,
      15.523,
      35.0,
      93.24,
      13.073,
      34.489,
      91.714,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9518",
    "polygon": [
      34.29,
      91.121,
      13.073,
      34.29,
      91.121,
      15.523,
      34.489,
      91.714,
      15.523,
      34.489,
      91.714,
      15.523,
      34.489,
      91.714,
      13.073,
      34.29,
      91.121,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9519",
    "polygon": [
      34.052,
      90.41,
      13.073,
      34.052,
      90.41,
      15.523,
      34.29,
      91.121,
      15.523,
      34.29,
      91.121,
      15.523,
      34.29,
      91.121,
      13.073,
      34.052,
      90.41,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9520",
    "polygon": [
      33.541,
      88.883,
      13.073,
      33.541,
      88.883,
      15.523,
      34.052,
      90.41,
      15.523,
      34.052,
      90.41,
      15.523,
      34.052,
      90.41,
      13.073,
      33.541,
      88.883,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9521",
    "polygon": [
      33.056,
      87.436,
      13.073,
      33.056,
      87.436,
      15.523,
      33.294,
      88.147,
      15.523,
      33.294,
      88.147,
      15.523,
      33.294,
      88.147,
      13.073,
      33.056,
      87.436,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9522",
    "polygon": [
      32.545,
      85.91,
      13.073,
      32.545,
      85.91,
      15.523,
      33.056,
      87.436,
      15.523,
      33.056,
      87.436,
      15.523,
      33.056,
      87.436,
      13.073,
      32.545,
      85.91,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9523",
    "polygon": [
      32.346,
      85.317,
      13.073,
      32.346,
      85.317,
      15.523,
      32.545,
      85.91,
      15.523,
      32.545,
      85.91,
      15.523,
      32.545,
      85.91,
      13.073,
      32.346,
      85.317,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9524",
    "polygon": [
      32.108,
      84.606,
      13.073,
      32.108,
      84.606,
      15.523,
      32.346,
      85.317,
      15.523,
      32.346,
      85.317,
      15.523,
      32.346,
      85.317,
      13.073,
      32.108,
      84.606,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9525",
    "polygon": [
      31.597,
      83.079,
      13.073,
      31.597,
      83.079,
      15.523,
      32.108,
      84.606,
      15.523,
      32.108,
      84.606,
      15.523,
      32.108,
      84.606,
      13.073,
      31.597,
      83.079,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9526",
    "polygon": [
      31.112,
      81.632,
      13.073,
      31.112,
      81.632,
      15.523,
      31.35,
      82.343,
      15.523,
      31.35,
      82.343,
      15.523,
      31.35,
      82.343,
      13.073,
      31.112,
      81.632,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9527",
    "polygon": [
      30.6,
      80.105,
      13.073,
      30.6,
      80.105,
      15.523,
      31.112,
      81.632,
      15.523,
      31.112,
      81.632,
      15.523,
      31.112,
      81.632,
      13.073,
      30.6,
      80.105,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9528",
    "polygon": [
      30.402,
      79.513,
      13.073,
      30.402,
      79.513,
      15.523,
      30.6,
      80.105,
      15.523,
      30.6,
      80.105,
      15.523,
      30.6,
      80.105,
      13.073,
      30.402,
      79.513,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9529",
    "polygon": [
      30.164,
      78.802,
      13.073,
      30.164,
      78.802,
      15.523,
      30.402,
      79.513,
      15.523,
      30.402,
      79.513,
      15.523,
      30.402,
      79.513,
      13.073,
      30.164,
      78.802,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9530",
    "polygon": [
      29.652,
      77.275,
      13.073,
      29.652,
      77.275,
      15.523,
      30.164,
      78.802,
      15.523,
      30.164,
      78.802,
      15.523,
      30.164,
      78.802,
      13.073,
      29.652,
      77.275,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9531",
    "polygon": [
      29.168,
      75.828,
      13.073,
      29.168,
      75.828,
      15.523,
      29.406,
      76.539,
      15.523,
      29.406,
      76.539,
      15.523,
      29.406,
      76.539,
      13.073,
      29.168,
      75.828,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9532",
    "polygon": [
      28.656,
      74.301,
      13.073,
      28.656,
      74.301,
      15.523,
      29.168,
      75.828,
      15.523,
      29.168,
      75.828,
      15.523,
      29.168,
      75.828,
      13.073,
      28.656,
      74.301,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9533",
    "polygon": [
      28.458,
      73.709,
      13.073,
      28.458,
      73.709,
      15.523,
      28.656,
      74.301,
      15.523,
      28.656,
      74.301,
      15.523,
      28.656,
      74.301,
      13.073,
      28.458,
      73.709,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9534",
    "polygon": [
      28.219,
      72.998,
      13.073,
      28.219,
      72.998,
      15.523,
      28.458,
      73.709,
      15.523,
      28.458,
      73.709,
      15.523,
      28.458,
      73.709,
      13.073,
      28.219,
      72.998,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9535",
    "polygon": [
      27.708,
      71.471,
      13.073,
      27.708,
      71.471,
      15.523,
      28.219,
      72.998,
      15.523,
      28.219,
      72.998,
      15.523,
      28.219,
      72.998,
      13.073,
      27.708,
      71.471,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9536",
    "polygon": [
      27.223,
      70.024,
      13.073,
      27.223,
      70.024,
      15.523,
      27.462,
      70.735,
      15.523,
      27.462,
      70.735,
      15.523,
      27.462,
      70.735,
      13.073,
      27.223,
      70.024,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9537",
    "polygon": [
      26.712,
      68.497,
      13.073,
      26.712,
      68.497,
      15.523,
      27.223,
      70.024,
      15.523,
      27.223,
      70.024,
      15.523,
      27.223,
      70.024,
      13.073,
      26.712,
      68.497,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9538",
    "polygon": [
      26.513,
      67.905,
      13.073,
      26.513,
      67.905,
      15.523,
      26.712,
      68.497,
      15.523,
      26.712,
      68.497,
      15.523,
      26.712,
      68.497,
      13.073,
      26.513,
      67.905,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9539",
    "polygon": [
      26.275,
      67.194,
      13.073,
      26.275,
      67.194,
      15.523,
      26.513,
      67.905,
      15.523,
      26.513,
      67.905,
      15.523,
      26.513,
      67.905,
      13.073,
      26.275,
      67.194,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9540",
    "polygon": [
      25.764,
      65.667,
      13.073,
      25.764,
      65.667,
      15.523,
      26.275,
      67.194,
      15.523,
      26.275,
      67.194,
      15.523,
      26.275,
      67.194,
      13.073,
      25.764,
      65.667,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9541",
    "polygon": [
      25.279,
      64.22,
      13.073,
      25.279,
      64.22,
      15.523,
      25.517,
      64.931,
      15.523,
      25.517,
      64.931,
      15.523,
      25.517,
      64.931,
      13.073,
      25.279,
      64.22,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9542",
    "polygon": [
      24.768,
      62.693,
      13.073,
      24.768,
      62.693,
      15.523,
      25.279,
      64.22,
      15.523,
      25.279,
      64.22,
      15.523,
      25.279,
      64.22,
      13.073,
      24.768,
      62.693,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9543",
    "polygon": [
      24.569,
      62.101,
      13.073,
      24.569,
      62.101,
      15.523,
      24.768,
      62.693,
      15.523,
      24.768,
      62.693,
      15.523,
      24.768,
      62.693,
      13.073,
      24.569,
      62.101,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9544",
    "polygon": [
      24.331,
      61.39,
      13.073,
      24.331,
      61.39,
      15.523,
      24.569,
      62.101,
      15.523,
      24.569,
      62.101,
      15.523,
      24.569,
      62.101,
      13.073,
      24.331,
      61.39,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_9545",
    "polygon": [
      23.956,
      60.271,
      13.073,
      23.956,
      60.271,
      15.523,
      24.331,
      61.39,
      15.523,
      24.331,
      61.39,
      15.523,
      24.331,
      61.39,
      13.073,
      23.956,
      60.271,
      13.073
    ],
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9546",
    "polygon": [
      36.996,
      99.198,
      9.323,
      36.996,
      99.198,
      11.773,
      37.234,
      99.909,
      11.773,
      37.234,
      99.909,
      11.773,
      37.234,
      99.909,
      9.323,
      36.996,
      99.198,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9547",
    "polygon": [
      36.485,
      97.671,
      9.323,
      36.485,
      97.671,
      11.773,
      36.996,
      99.198,
      11.773,
      36.996,
      99.198,
      11.773,
      36.996,
      99.198,
      9.323,
      36.485,
      97.671,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9548",
    "polygon": [
      36.286,
      97.079,
      9.323,
      36.286,
      97.079,
      11.773,
      36.485,
      97.671,
      11.773,
      36.485,
      97.671,
      11.773,
      36.485,
      97.671,
      9.323,
      36.286,
      97.079,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9549",
    "polygon": [
      36.048,
      96.367,
      9.323,
      36.048,
      96.367,
      11.773,
      36.286,
      97.079,
      11.773,
      36.286,
      97.079,
      11.773,
      36.286,
      97.079,
      9.323,
      36.048,
      96.367,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9550",
    "polygon": [
      35.537,
      94.841,
      9.323,
      35.537,
      94.841,
      11.773,
      36.048,
      96.367,
      11.773,
      36.048,
      96.367,
      11.773,
      36.048,
      96.367,
      9.323,
      35.537,
      94.841,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9551",
    "polygon": [
      35.0,
      93.24,
      9.323,
      35.0,
      93.24,
      11.773,
      35.239,
      93.951,
      11.773,
      35.239,
      93.951,
      11.773,
      35.239,
      93.951,
      9.323,
      35.0,
      93.24,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9552",
    "polygon": [
      34.489,
      91.714,
      9.323,
      34.489,
      91.714,
      11.773,
      35.0,
      93.24,
      11.773,
      35.0,
      93.24,
      11.773,
      35.0,
      93.24,
      9.323,
      34.489,
      91.714,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9553",
    "polygon": [
      34.29,
      91.121,
      9.323,
      34.29,
      91.121,
      11.773,
      34.489,
      91.714,
      11.773,
      34.489,
      91.714,
      11.773,
      34.489,
      91.714,
      9.323,
      34.29,
      91.121,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9554",
    "polygon": [
      34.052,
      90.41,
      9.323,
      34.052,
      90.41,
      11.773,
      34.29,
      91.121,
      11.773,
      34.29,
      91.121,
      11.773,
      34.29,
      91.121,
      9.323,
      34.052,
      90.41,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9555",
    "polygon": [
      33.541,
      88.883,
      9.323,
      33.541,
      88.883,
      11.773,
      34.052,
      90.41,
      11.773,
      34.052,
      90.41,
      11.773,
      34.052,
      90.41,
      9.323,
      33.541,
      88.883,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9556",
    "polygon": [
      33.056,
      87.436,
      9.323,
      33.056,
      87.436,
      11.773,
      33.294,
      88.147,
      11.773,
      33.294,
      88.147,
      11.773,
      33.294,
      88.147,
      9.323,
      33.056,
      87.436,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9557",
    "polygon": [
      32.545,
      85.91,
      9.323,
      32.545,
      85.91,
      11.773,
      33.056,
      87.436,
      11.773,
      33.056,
      87.436,
      11.773,
      33.056,
      87.436,
      9.323,
      32.545,
      85.91,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9558",
    "polygon": [
      32.346,
      85.317,
      9.323,
      32.346,
      85.317,
      11.773,
      32.545,
      85.91,
      11.773,
      32.545,
      85.91,
      11.773,
      32.545,
      85.91,
      9.323,
      32.346,
      85.317,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9559",
    "polygon": [
      32.108,
      84.606,
      9.323,
      32.108,
      84.606,
      11.773,
      32.346,
      85.317,
      11.773,
      32.346,
      85.317,
      11.773,
      32.346,
      85.317,
      9.323,
      32.108,
      84.606,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9560",
    "polygon": [
      31.597,
      83.079,
      9.323,
      31.597,
      83.079,
      11.773,
      32.108,
      84.606,
      11.773,
      32.108,
      84.606,
      11.773,
      32.108,
      84.606,
      9.323,
      31.597,
      83.079,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9561",
    "polygon": [
      31.112,
      81.632,
      9.323,
      31.112,
      81.632,
      11.773,
      31.35,
      82.343,
      11.773,
      31.35,
      82.343,
      11.773,
      31.35,
      82.343,
      9.323,
      31.112,
      81.632,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9562",
    "polygon": [
      30.6,
      80.105,
      9.323,
      30.6,
      80.105,
      11.773,
      31.112,
      81.632,
      11.773,
      31.112,
      81.632,
      11.773,
      31.112,
      81.632,
      9.323,
      30.6,
      80.105,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9563",
    "polygon": [
      30.402,
      79.513,
      9.323,
      30.402,
      79.513,
      11.773,
      30.6,
      80.105,
      11.773,
      30.6,
      80.105,
      11.773,
      30.6,
      80.105,
      9.323,
      30.402,
      79.513,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9564",
    "polygon": [
      30.164,
      78.802,
      9.323,
      30.164,
      78.802,
      11.773,
      30.402,
      79.513,
      11.773,
      30.402,
      79.513,
      11.773,
      30.402,
      79.513,
      9.323,
      30.164,
      78.802,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9565",
    "polygon": [
      29.652,
      77.275,
      9.323,
      29.652,
      77.275,
      11.773,
      30.164,
      78.802,
      11.773,
      30.164,
      78.802,
      11.773,
      30.164,
      78.802,
      9.323,
      29.652,
      77.275,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9566",
    "polygon": [
      29.168,
      75.828,
      9.323,
      29.168,
      75.828,
      11.773,
      29.406,
      76.539,
      11.773,
      29.406,
      76.539,
      11.773,
      29.406,
      76.539,
      9.323,
      29.168,
      75.828,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9567",
    "polygon": [
      28.656,
      74.301,
      9.323,
      28.656,
      74.301,
      11.773,
      29.168,
      75.828,
      11.773,
      29.168,
      75.828,
      11.773,
      29.168,
      75.828,
      9.323,
      28.656,
      74.301,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9568",
    "polygon": [
      28.458,
      73.709,
      9.323,
      28.458,
      73.709,
      11.773,
      28.656,
      74.301,
      11.773,
      28.656,
      74.301,
      11.773,
      28.656,
      74.301,
      9.323,
      28.458,
      73.709,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9569",
    "polygon": [
      28.219,
      72.998,
      9.323,
      28.219,
      72.998,
      11.773,
      28.458,
      73.709,
      11.773,
      28.458,
      73.709,
      11.773,
      28.458,
      73.709,
      9.323,
      28.219,
      72.998,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9570",
    "polygon": [
      27.708,
      71.471,
      9.323,
      27.708,
      71.471,
      11.773,
      28.219,
      72.998,
      11.773,
      28.219,
      72.998,
      11.773,
      28.219,
      72.998,
      9.323,
      27.708,
      71.471,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9571",
    "polygon": [
      27.223,
      70.024,
      9.323,
      27.223,
      70.024,
      11.773,
      27.462,
      70.735,
      11.773,
      27.462,
      70.735,
      11.773,
      27.462,
      70.735,
      9.323,
      27.223,
      70.024,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9572",
    "polygon": [
      26.712,
      68.497,
      9.323,
      26.712,
      68.497,
      11.773,
      27.223,
      70.024,
      11.773,
      27.223,
      70.024,
      11.773,
      27.223,
      70.024,
      9.323,
      26.712,
      68.497,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9573",
    "polygon": [
      26.513,
      67.905,
      9.323,
      26.513,
      67.905,
      11.773,
      26.712,
      68.497,
      11.773,
      26.712,
      68.497,
      11.773,
      26.712,
      68.497,
      9.323,
      26.513,
      67.905,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9574",
    "polygon": [
      26.275,
      67.194,
      9.323,
      26.275,
      67.194,
      11.773,
      26.513,
      67.905,
      11.773,
      26.513,
      67.905,
      11.773,
      26.513,
      67.905,
      9.323,
      26.275,
      67.194,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9575",
    "polygon": [
      25.764,
      65.667,
      9.323,
      25.764,
      65.667,
      11.773,
      26.275,
      67.194,
      11.773,
      26.275,
      67.194,
      11.773,
      26.275,
      67.194,
      9.323,
      25.764,
      65.667,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9576",
    "polygon": [
      25.279,
      64.22,
      9.323,
      25.279,
      64.22,
      11.773,
      25.517,
      64.931,
      11.773,
      25.517,
      64.931,
      11.773,
      25.517,
      64.931,
      9.323,
      25.279,
      64.22,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9577",
    "polygon": [
      24.768,
      62.693,
      9.323,
      24.768,
      62.693,
      11.773,
      25.279,
      64.22,
      11.773,
      25.279,
      64.22,
      11.773,
      25.279,
      64.22,
      9.323,
      24.768,
      62.693,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9578",
    "polygon": [
      24.569,
      62.101,
      9.323,
      24.569,
      62.101,
      11.773,
      24.768,
      62.693,
      11.773,
      24.768,
      62.693,
      11.773,
      24.768,
      62.693,
      9.323,
      24.569,
      62.101,
      9.323
    ],
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9579",
    "polygon": [
      24.331,
      61.39,
      9.323,
      24.331,
      61.39,
      11.773,
      24.569,
      62.101,
      11.773,
      24.569,
      62.101,
      11.773,
      24.569,
      62.101,
      9.323,
      24.331,
      61.39,
      9.323
    ],
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_9580",
    "polygon": [
      23.956,
      60.271,
      9.323,
      23.956,
      60.271,
      11.773,
      24.331,
      61.39,
      11.773,
      24.331,
      61.39,
      11.773,
      24.331,
      61.39,
      9.323,
      23.956,
      60.271,
      9.323
    ],
    "05-22 17:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9581",
    "polygon": [
      36.994,
      99.199,
      5.573,
      36.994,
      99.199,
      8.023,
      37.232,
      99.91,
      8.023,
      37.232,
      99.91,
      8.023,
      37.232,
      99.91,
      5.573,
      36.994,
      99.199,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9582",
    "polygon": [
      36.482,
      97.672,
      5.573,
      36.482,
      97.672,
      8.023,
      36.994,
      99.199,
      8.023,
      36.994,
      99.199,
      8.023,
      36.994,
      99.199,
      5.573,
      36.482,
      97.672,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9583",
    "polygon": [
      36.284,
      97.079,
      5.573,
      36.284,
      97.079,
      8.023,
      36.482,
      97.672,
      8.023,
      36.482,
      97.672,
      8.023,
      36.482,
      97.672,
      5.573,
      36.284,
      97.079,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9584",
    "polygon": [
      36.045,
      96.368,
      5.573,
      36.045,
      96.368,
      8.023,
      36.284,
      97.079,
      8.023,
      36.284,
      97.079,
      8.023,
      36.284,
      97.079,
      5.573,
      36.045,
      96.368,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9585",
    "polygon": [
      35.534,
      94.842,
      5.573,
      35.534,
      94.842,
      8.023,
      36.045,
      96.368,
      8.023,
      36.045,
      96.368,
      8.023,
      36.045,
      96.368,
      5.573,
      35.534,
      94.842,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9586",
    "polygon": [
      34.998,
      93.241,
      5.573,
      34.998,
      93.241,
      8.023,
      35.236,
      93.952,
      8.023,
      35.236,
      93.952,
      8.023,
      35.236,
      93.952,
      5.573,
      34.998,
      93.241,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9587",
    "polygon": [
      34.486,
      91.714,
      5.573,
      34.486,
      91.714,
      8.023,
      34.998,
      93.241,
      8.023,
      34.998,
      93.241,
      8.023,
      34.998,
      93.241,
      5.573,
      34.486,
      91.714,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9588",
    "polygon": [
      34.288,
      91.122,
      5.573,
      34.288,
      91.122,
      8.023,
      34.486,
      91.714,
      8.023,
      34.486,
      91.714,
      8.023,
      34.486,
      91.714,
      5.573,
      34.288,
      91.122,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9589",
    "polygon": [
      34.05,
      90.411,
      5.573,
      34.05,
      90.411,
      8.023,
      34.288,
      91.122,
      8.023,
      34.288,
      91.122,
      8.023,
      34.288,
      91.122,
      5.573,
      34.05,
      90.411,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9590",
    "polygon": [
      33.538,
      88.884,
      5.573,
      33.538,
      88.884,
      8.023,
      34.05,
      90.411,
      8.023,
      34.05,
      90.411,
      8.023,
      34.05,
      90.411,
      5.573,
      33.538,
      88.884,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9591",
    "polygon": [
      33.054,
      87.437,
      5.573,
      33.054,
      87.437,
      8.023,
      33.292,
      88.148,
      8.023,
      33.292,
      88.148,
      8.023,
      33.292,
      88.148,
      5.573,
      33.054,
      87.437,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9592",
    "polygon": [
      32.542,
      85.91,
      5.573,
      32.542,
      85.91,
      8.023,
      33.054,
      87.437,
      8.023,
      33.054,
      87.437,
      8.023,
      33.054,
      87.437,
      5.573,
      32.542,
      85.91,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9593",
    "polygon": [
      32.344,
      85.318,
      5.573,
      32.344,
      85.318,
      8.023,
      32.542,
      85.91,
      8.023,
      32.542,
      85.91,
      8.023,
      32.542,
      85.91,
      5.573,
      32.344,
      85.318,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9594",
    "polygon": [
      32.105,
      84.607,
      5.573,
      32.105,
      84.607,
      8.023,
      32.344,
      85.318,
      8.023,
      32.344,
      85.318,
      8.023,
      32.344,
      85.318,
      5.573,
      32.105,
      84.607,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9595",
    "polygon": [
      31.594,
      83.08,
      5.573,
      31.594,
      83.08,
      8.023,
      32.105,
      84.607,
      8.023,
      32.105,
      84.607,
      8.023,
      32.105,
      84.607,
      5.573,
      31.594,
      83.08,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9596",
    "polygon": [
      31.109,
      81.633,
      5.573,
      31.109,
      81.633,
      8.023,
      31.347,
      82.344,
      8.023,
      31.347,
      82.344,
      8.023,
      31.347,
      82.344,
      5.573,
      31.109,
      81.633,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9597",
    "polygon": [
      30.598,
      80.106,
      5.573,
      30.598,
      80.106,
      8.023,
      31.109,
      81.633,
      8.023,
      31.109,
      81.633,
      8.023,
      31.109,
      81.633,
      5.573,
      30.598,
      80.106,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9598",
    "polygon": [
      30.399,
      79.514,
      5.573,
      30.399,
      79.514,
      8.023,
      30.598,
      80.106,
      8.023,
      30.598,
      80.106,
      8.023,
      30.598,
      80.106,
      5.573,
      30.399,
      79.514,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9599",
    "polygon": [
      30.161,
      78.803,
      5.573,
      30.161,
      78.803,
      8.023,
      30.399,
      79.514,
      8.023,
      30.399,
      79.514,
      8.023,
      30.399,
      79.514,
      5.573,
      30.161,
      78.803,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9600",
    "polygon": [
      29.65,
      77.276,
      5.573,
      29.65,
      77.276,
      8.023,
      30.161,
      78.803,
      8.023,
      30.161,
      78.803,
      8.023,
      30.161,
      78.803,
      5.573,
      29.65,
      77.276,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9601",
    "polygon": [
      29.165,
      75.829,
      5.573,
      29.165,
      75.829,
      8.023,
      29.403,
      76.54,
      8.023,
      29.403,
      76.54,
      8.023,
      29.403,
      76.54,
      5.573,
      29.165,
      75.829,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9602",
    "polygon": [
      28.654,
      74.302,
      5.573,
      28.654,
      74.302,
      8.023,
      29.165,
      75.829,
      8.023,
      29.165,
      75.829,
      8.023,
      29.165,
      75.829,
      5.573,
      28.654,
      74.302,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9603",
    "polygon": [
      28.455,
      73.71,
      5.573,
      28.455,
      73.71,
      8.023,
      28.654,
      74.302,
      8.023,
      28.654,
      74.302,
      8.023,
      28.654,
      74.302,
      5.573,
      28.455,
      73.71,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9604",
    "polygon": [
      28.217,
      72.999,
      5.573,
      28.217,
      72.999,
      8.023,
      28.455,
      73.71,
      8.023,
      28.455,
      73.71,
      8.023,
      28.455,
      73.71,
      5.573,
      28.217,
      72.999,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9605",
    "polygon": [
      27.705,
      71.472,
      5.573,
      27.705,
      71.472,
      8.023,
      28.217,
      72.999,
      8.023,
      28.217,
      72.999,
      8.023,
      28.217,
      72.999,
      5.573,
      27.705,
      71.472,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9606",
    "polygon": [
      27.221,
      70.025,
      5.573,
      27.221,
      70.025,
      8.023,
      27.459,
      70.736,
      8.023,
      27.459,
      70.736,
      8.023,
      27.459,
      70.736,
      5.573,
      27.221,
      70.025,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9607",
    "polygon": [
      26.709,
      68.498,
      5.573,
      26.709,
      68.498,
      8.023,
      27.221,
      70.025,
      8.023,
      27.221,
      70.025,
      8.023,
      27.221,
      70.025,
      5.573,
      26.709,
      68.498,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9608",
    "polygon": [
      26.511,
      67.906,
      5.573,
      26.511,
      67.906,
      8.023,
      26.709,
      68.498,
      8.023,
      26.709,
      68.498,
      8.023,
      26.709,
      68.498,
      5.573,
      26.511,
      67.906,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9609",
    "polygon": [
      26.273,
      67.195,
      5.573,
      26.273,
      67.195,
      8.023,
      26.511,
      67.906,
      8.023,
      26.511,
      67.906,
      8.023,
      26.511,
      67.906,
      5.573,
      26.273,
      67.195,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9610",
    "polygon": [
      25.761,
      65.668,
      5.573,
      25.761,
      65.668,
      8.023,
      26.273,
      67.195,
      8.023,
      26.273,
      67.195,
      8.023,
      26.273,
      67.195,
      5.573,
      25.761,
      65.668,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9611",
    "polygon": [
      25.276,
      64.221,
      5.573,
      25.276,
      64.221,
      8.023,
      25.515,
      64.932,
      8.023,
      25.515,
      64.932,
      8.023,
      25.515,
      64.932,
      5.573,
      25.276,
      64.221,
      5.573
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9612",
    "polygon": [
      24.765,
      62.694,
      5.573,
      24.765,
      62.694,
      8.023,
      25.276,
      64.221,
      8.023,
      25.276,
      64.221,
      8.023,
      25.276,
      64.221,
      5.573,
      24.765,
      62.694,
      5.573
    ],
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_9613",
    "polygon": [
      24.567,
      62.102,
      5.573,
      24.567,
      62.102,
      8.023,
      24.765,
      62.694,
      8.023,
      24.765,
      62.694,
      8.023,
      24.765,
      62.694,
      5.573,
      24.567,
      62.102,
      5.573
    ]
  },
  {
    "ID": "Block-B-Outer_1.OG_9614",
    "polygon": [
      24.328,
      61.391,
      5.573,
      24.328,
      61.391,
      8.023,
      24.567,
      62.102,
      8.023,
      24.567,
      62.102,
      8.023,
      24.567,
      62.102,
      5.573,
      24.328,
      61.391,
      5.573
    ]
  },
  {
    "ID": "Block-B-Outer_1.OG_9615",
    "polygon": [
      23.954,
      60.272,
      5.573,
      23.954,
      60.272,
      8.023,
      24.328,
      61.391,
      8.023,
      24.328,
      61.391,
      8.023,
      24.328,
      61.391,
      5.573,
      23.954,
      60.272,
      5.573
    ]
  },
  {
    "ID": "Block-B-Outer_EG_9616",
    "polygon": [
      36.993,
      99.199,
      0.745,
      36.993,
      99.199,
      3.455,
      37.231,
      99.91,
      3.455,
      37.231,
      99.91,
      3.455,
      37.231,
      99.91,
      0.745,
      36.993,
      99.199,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_9617",
    "polygon": [
      36.481,
      97.672,
      0.745,
      36.481,
      97.672,
      3.455,
      36.993,
      99.199,
      3.455,
      36.993,
      99.199,
      3.455,
      36.993,
      99.199,
      0.745,
      36.481,
      97.672,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_9618",
    "polygon": [
      36.283,
      97.08,
      0.745,
      36.283,
      97.08,
      3.455,
      36.481,
      97.672,
      3.455,
      36.481,
      97.672,
      3.455,
      36.481,
      97.672,
      0.745,
      36.283,
      97.08,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_9619",
    "polygon": [
      36.045,
      96.369,
      0.745,
      36.045,
      96.369,
      3.455,
      36.283,
      97.08,
      3.455,
      36.283,
      97.08,
      3.455,
      36.283,
      97.08,
      0.745,
      36.045,
      96.369,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_9620",
    "polygon": [
      35.533,
      94.842,
      0.745,
      35.533,
      94.842,
      3.455,
      36.045,
      96.369,
      3.455,
      36.045,
      96.369,
      3.455,
      36.045,
      96.369,
      0.745,
      35.533,
      94.842,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9621",
    "polygon": [
      34.997,
      93.241,
      0.745,
      34.997,
      93.241,
      3.455,
      35.235,
      93.952,
      3.455,
      35.235,
      93.952,
      3.455,
      35.235,
      93.952,
      0.745,
      34.997,
      93.241,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9622",
    "polygon": [
      34.486,
      91.715,
      0.745,
      34.486,
      91.715,
      3.455,
      34.997,
      93.241,
      3.455,
      34.997,
      93.241,
      3.455,
      34.997,
      93.241,
      0.745,
      34.486,
      91.715,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9623",
    "polygon": [
      34.287,
      91.122,
      0.745,
      34.287,
      91.122,
      3.455,
      34.486,
      91.715,
      3.455,
      34.486,
      91.715,
      3.455,
      34.486,
      91.715,
      0.745,
      34.287,
      91.122,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9624",
    "polygon": [
      34.049,
      90.411,
      0.745,
      34.049,
      90.411,
      3.455,
      34.287,
      91.122,
      3.455,
      34.287,
      91.122,
      3.455,
      34.287,
      91.122,
      0.745,
      34.049,
      90.411,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9625",
    "polygon": [
      33.537,
      88.884,
      0.745,
      33.537,
      88.884,
      3.455,
      34.049,
      90.411,
      3.455,
      34.049,
      90.411,
      3.455,
      34.049,
      90.411,
      0.745,
      33.537,
      88.884,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9626",
    "polygon": [
      33.053,
      87.437,
      0.745,
      33.053,
      87.437,
      3.455,
      33.291,
      88.148,
      3.455,
      33.291,
      88.148,
      3.455,
      33.291,
      88.148,
      0.745,
      33.053,
      87.437,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9627",
    "polygon": [
      32.541,
      85.911,
      0.745,
      32.541,
      85.911,
      3.455,
      33.053,
      87.437,
      3.455,
      33.053,
      87.437,
      3.455,
      33.053,
      87.437,
      0.745,
      32.541,
      85.911,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9628",
    "polygon": [
      32.343,
      85.318,
      0.745,
      32.343,
      85.318,
      3.455,
      32.541,
      85.911,
      3.455,
      32.541,
      85.911,
      3.455,
      32.541,
      85.911,
      0.745,
      32.343,
      85.318,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9629",
    "polygon": [
      32.105,
      84.607,
      0.745,
      32.105,
      84.607,
      3.455,
      32.343,
      85.318,
      3.455,
      32.343,
      85.318,
      3.455,
      32.343,
      85.318,
      0.745,
      32.105,
      84.607,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9630",
    "polygon": [
      31.593,
      83.08,
      0.745,
      31.593,
      83.08,
      3.455,
      32.105,
      84.607,
      3.455,
      32.105,
      84.607,
      3.455,
      32.105,
      84.607,
      0.745,
      31.593,
      83.08,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9631",
    "polygon": [
      31.109,
      81.633,
      0.745,
      31.109,
      81.633,
      3.455,
      31.347,
      82.344,
      3.455,
      31.347,
      82.344,
      3.455,
      31.347,
      82.344,
      0.745,
      31.109,
      81.633,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9632",
    "polygon": [
      30.597,
      80.107,
      0.745,
      30.597,
      80.107,
      3.455,
      31.109,
      81.633,
      3.455,
      31.109,
      81.633,
      3.455,
      31.109,
      81.633,
      0.745,
      30.597,
      80.107,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9633",
    "polygon": [
      30.399,
      79.514,
      0.745,
      30.399,
      79.514,
      3.455,
      30.597,
      80.107,
      3.455,
      30.597,
      80.107,
      3.455,
      30.597,
      80.107,
      0.745,
      30.399,
      79.514,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9634",
    "polygon": [
      30.16,
      78.803,
      0.745,
      30.16,
      78.803,
      3.455,
      30.399,
      79.514,
      3.455,
      30.399,
      79.514,
      3.455,
      30.399,
      79.514,
      0.745,
      30.16,
      78.803,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9635",
    "polygon": [
      29.649,
      77.276,
      0.745,
      29.649,
      77.276,
      3.455,
      30.16,
      78.803,
      3.455,
      30.16,
      78.803,
      3.455,
      30.16,
      78.803,
      0.745,
      29.649,
      77.276,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9636",
    "polygon": [
      29.164,
      75.829,
      0.745,
      29.164,
      75.829,
      3.455,
      29.402,
      76.54,
      3.455,
      29.402,
      76.54,
      3.455,
      29.402,
      76.54,
      0.745,
      29.164,
      75.829,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9637",
    "polygon": [
      28.653,
      74.303,
      0.745,
      28.653,
      74.303,
      3.455,
      29.164,
      75.829,
      3.455,
      29.164,
      75.829,
      3.455,
      29.164,
      75.829,
      0.745,
      28.653,
      74.303,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9638",
    "polygon": [
      28.454,
      73.71,
      0.745,
      28.454,
      73.71,
      3.455,
      28.653,
      74.303,
      3.455,
      28.653,
      74.303,
      3.455,
      28.653,
      74.303,
      0.745,
      28.454,
      73.71,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9639",
    "polygon": [
      28.216,
      72.999,
      0.745,
      28.216,
      72.999,
      3.455,
      28.454,
      73.71,
      3.455,
      28.454,
      73.71,
      3.455,
      28.454,
      73.71,
      0.745,
      28.216,
      72.999,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9640",
    "polygon": [
      27.705,
      71.472,
      0.745,
      27.705,
      71.472,
      3.455,
      28.216,
      72.999,
      3.455,
      28.216,
      72.999,
      3.455,
      28.216,
      72.999,
      0.745,
      27.705,
      71.472,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9641",
    "polygon": [
      27.22,
      70.025,
      0.745,
      27.22,
      70.025,
      3.455,
      27.458,
      70.736,
      3.455,
      27.458,
      70.736,
      3.455,
      27.458,
      70.736,
      0.745,
      27.22,
      70.025,
      0.745
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9642",
    "polygon": [
      26.709,
      68.499,
      0.745,
      26.709,
      68.499,
      3.455,
      27.22,
      70.025,
      3.455,
      27.22,
      70.025,
      3.455,
      27.22,
      70.025,
      0.745,
      26.709,
      68.499,
      0.745
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9643",
    "polygon": [
      26.51,
      67.906,
      0.745,
      26.51,
      67.906,
      3.455,
      26.709,
      68.499,
      3.455,
      26.709,
      68.499,
      3.455,
      26.709,
      68.499,
      0.745,
      26.51,
      67.906,
      0.745
    ],
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9644",
    "polygon": [
      26.272,
      67.195,
      0.745,
      26.272,
      67.195,
      3.455,
      26.51,
      67.906,
      3.455,
      26.51,
      67.906,
      3.455,
      26.51,
      67.906,
      0.745,
      26.272,
      67.195,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_9645",
    "polygon": [
      25.76,
      65.668,
      0.745,
      25.76,
      65.668,
      3.455,
      26.272,
      67.195,
      3.455,
      26.272,
      67.195,
      3.455,
      26.272,
      67.195,
      0.745,
      25.76,
      65.668,
      0.745
    ]
  },
  {
    "ID": "Block-B-Outer_EG_9646",
    "polygon": [
      25.276,
      64.221,
      0.745,
      25.276,
      64.221,
      3.455,
      25.514,
      64.932,
      3.455,
      25.514,
      64.932,
      3.455,
      25.514,
      64.932,
      0.745,
      25.276,
      64.221,
      0.745
    ]
  },
  {
    "ID": "Block-B-Outer_EG_9647",
    "polygon": [
      24.764,
      62.695,
      0.745,
      24.764,
      62.695,
      3.455,
      25.276,
      64.221,
      3.455,
      25.276,
      64.221,
      3.455,
      25.276,
      64.221,
      0.745,
      24.764,
      62.695,
      0.745
    ]
  },
  {
    "ID": "Block-B-Outer_EG_9648",
    "polygon": [
      24.566,
      62.102,
      0.745,
      24.566,
      62.102,
      3.455,
      24.764,
      62.695,
      3.455,
      24.764,
      62.695,
      3.455,
      24.764,
      62.695,
      0.745,
      24.566,
      62.102,
      0.745
    ]
  },
  {
    "ID": "Block-B-Outer_EG_9649",
    "polygon": [
      24.328,
      61.391,
      0.745,
      24.328,
      61.391,
      3.455,
      24.566,
      62.102,
      3.455,
      24.566,
      62.102,
      3.455,
      24.566,
      62.102,
      0.745,
      24.328,
      61.391,
      0.745
    ]
  },
  {
    "ID": "Block-B-Outer_EG_9650",
    "polygon": [
      23.953,
      60.272,
      0.745,
      23.953,
      60.272,
      3.455,
      24.328,
      61.391,
      3.455,
      24.328,
      61.391,
      3.455,
      24.328,
      61.391,
      0.745,
      23.953,
      60.272,
      0.745
    ]
  },
  {
    "ID": "Block-D-Outer_3.OG_9651",
    "polygon": [
      -25.041,
      36.01,
      13.104,
      -25.041,
      36.01,
      15.554,
      -24.53,
      37.537,
      15.554,
      -24.53,
      37.537,
      15.554,
      -24.53,
      37.537,
      13.104,
      -25.041,
      36.01,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9652",
    "polygon": [
      -24.53,
      37.537,
      13.104,
      -24.53,
      37.537,
      15.554,
      -24.291,
      38.248,
      15.554,
      -24.291,
      38.248,
      15.554,
      -24.291,
      38.248,
      13.104,
      -24.53,
      37.537,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9653",
    "polygon": [
      -25.989,
      33.18,
      13.104,
      -25.989,
      33.18,
      15.554,
      -25.478,
      34.706,
      15.554,
      -25.478,
      34.706,
      15.554,
      -25.478,
      34.706,
      13.104,
      -25.989,
      33.18,
      13.104
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9654",
    "polygon": [
      -25.478,
      34.706,
      13.104,
      -25.478,
      34.706,
      15.554,
      -25.239,
      35.418,
      15.554,
      -25.239,
      35.418,
      15.554,
      -25.239,
      35.418,
      13.104,
      -25.478,
      34.706,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9655",
    "polygon": [
      -25.239,
      35.418,
      13.104,
      -25.239,
      35.418,
      15.554,
      -25.041,
      36.01,
      15.554,
      -25.041,
      36.01,
      15.554,
      -25.041,
      36.01,
      13.104,
      -25.239,
      35.418,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9656",
    "polygon": [
      -26.985,
      30.206,
      13.104,
      -26.985,
      30.206,
      15.554,
      -26.474,
      31.733,
      15.554,
      -26.474,
      31.733,
      15.554,
      -26.474,
      31.733,
      13.104,
      -26.985,
      30.206,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9657",
    "polygon": [
      -26.474,
      31.733,
      13.104,
      -26.474,
      31.733,
      15.554,
      -26.236,
      32.444,
      15.554,
      -26.236,
      32.444,
      15.554,
      -26.236,
      32.444,
      13.104,
      -26.474,
      31.733,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9658",
    "polygon": [
      -27.933,
      27.376,
      13.104,
      -27.933,
      27.376,
      15.554,
      -27.422,
      28.902,
      15.554,
      -27.422,
      28.902,
      15.554,
      -27.422,
      28.902,
      13.104,
      -27.933,
      27.376,
      13.104
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9659",
    "polygon": [
      -27.422,
      28.902,
      13.104,
      -27.422,
      28.902,
      15.554,
      -27.184,
      29.614,
      15.554,
      -27.184,
      29.614,
      15.554,
      -27.184,
      29.614,
      13.104,
      -27.422,
      28.902,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9660",
    "polygon": [
      -27.184,
      29.614,
      13.104,
      -27.184,
      29.614,
      15.554,
      -26.985,
      30.206,
      15.554,
      -26.985,
      30.206,
      15.554,
      -26.985,
      30.206,
      13.104,
      -27.184,
      29.614,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9661",
    "polygon": [
      -29.009,
      24.165,
      13.104,
      -29.009,
      24.165,
      15.554,
      -28.497,
      25.692,
      15.554,
      -28.497,
      25.692,
      15.554,
      -28.497,
      25.692,
      13.104,
      -29.009,
      24.165,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9662",
    "polygon": [
      -28.497,
      25.692,
      13.104,
      -28.497,
      25.692,
      15.554,
      -28.259,
      26.403,
      15.554,
      -28.259,
      26.403,
      15.554,
      -28.259,
      26.403,
      13.104,
      -28.497,
      25.692,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9663",
    "polygon": [
      -29.957,
      21.335,
      13.104,
      -29.957,
      21.335,
      15.554,
      -29.446,
      22.861,
      15.554,
      -29.446,
      22.861,
      15.554,
      -29.446,
      22.861,
      13.104,
      -29.957,
      21.335,
      13.104
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9664",
    "polygon": [
      -29.446,
      22.861,
      13.104,
      -29.446,
      22.861,
      15.554,
      -29.207,
      23.573,
      15.554,
      -29.207,
      23.573,
      15.554,
      -29.207,
      23.573,
      13.104,
      -29.446,
      22.861,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9665",
    "polygon": [
      -29.207,
      23.573,
      13.104,
      -29.207,
      23.573,
      15.554,
      -29.009,
      24.165,
      15.554,
      -29.009,
      24.165,
      15.554,
      -29.009,
      24.165,
      13.104,
      -29.207,
      23.573,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9666",
    "polygon": [
      -30.994,
      18.238,
      13.104,
      -30.994,
      18.238,
      15.554,
      -30.483,
      19.765,
      15.554,
      -30.483,
      19.765,
      15.554,
      -30.483,
      19.765,
      13.104,
      -30.994,
      18.238,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9667",
    "polygon": [
      -30.483,
      19.765,
      13.104,
      -30.483,
      19.765,
      15.554,
      -30.245,
      20.476,
      15.554,
      -30.245,
      20.476,
      15.554,
      -30.245,
      20.476,
      13.104,
      -30.483,
      19.765,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9668",
    "polygon": [
      -31.943,
      15.408,
      13.104,
      -31.943,
      15.408,
      15.554,
      -31.431,
      16.934,
      15.554,
      -31.431,
      16.934,
      15.554,
      -31.431,
      16.934,
      13.104,
      -31.943,
      15.408,
      13.104
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9669",
    "polygon": [
      -31.431,
      16.934,
      13.104,
      -31.431,
      16.934,
      15.554,
      -31.193,
      17.645,
      15.554,
      -31.193,
      17.645,
      15.554,
      -31.193,
      17.645,
      13.104,
      -31.431,
      16.934,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9670",
    "polygon": [
      -31.193,
      17.645,
      13.104,
      -31.193,
      17.645,
      15.554,
      -30.994,
      18.238,
      15.554,
      -30.994,
      18.238,
      15.554,
      -30.994,
      18.238,
      13.104,
      -31.193,
      17.645,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9671",
    "polygon": [
      -33.024,
      12.178,
      13.104,
      -33.024,
      12.178,
      15.554,
      -32.513,
      13.705,
      15.554,
      -32.513,
      13.705,
      15.554,
      -32.513,
      13.705,
      13.104,
      -33.024,
      12.178,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9672",
    "polygon": [
      -32.513,
      13.705,
      13.104,
      -32.513,
      13.705,
      15.554,
      -32.275,
      14.416,
      15.554,
      -32.275,
      14.416,
      15.554,
      -32.275,
      14.416,
      13.104,
      -32.513,
      13.705,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9673",
    "polygon": [
      -33.973,
      9.347,
      13.104,
      -33.973,
      9.347,
      15.554,
      -33.461,
      10.874,
      15.554,
      -33.461,
      10.874,
      15.554,
      -33.461,
      10.874,
      13.104,
      -33.973,
      9.347,
      13.104
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9674",
    "polygon": [
      -33.461,
      10.874,
      13.104,
      -33.461,
      10.874,
      15.554,
      -33.223,
      11.585,
      15.554,
      -33.223,
      11.585,
      15.554,
      -33.223,
      11.585,
      13.104,
      -33.461,
      10.874,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9675",
    "polygon": [
      -33.223,
      11.585,
      13.104,
      -33.223,
      11.585,
      15.554,
      -33.024,
      12.178,
      15.554,
      -33.024,
      12.178,
      15.554,
      -33.024,
      12.178,
      13.104,
      -33.223,
      11.585,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9676",
    "polygon": [
      -35.118,
      5.929,
      13.104,
      -35.118,
      5.929,
      15.554,
      -34.606,
      7.456,
      15.554,
      -34.606,
      7.456,
      15.554,
      -34.606,
      7.456,
      13.104,
      -35.118,
      5.929,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9677",
    "polygon": [
      -34.606,
      7.456,
      13.104,
      -34.606,
      7.456,
      15.554,
      -34.368,
      8.167,
      15.554,
      -34.368,
      8.167,
      15.554,
      -34.368,
      8.167,
      13.104,
      -34.606,
      7.456,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9678",
    "polygon": [
      -36.066,
      3.099,
      13.104,
      -36.066,
      3.099,
      15.554,
      -35.554,
      4.626,
      15.554,
      -35.554,
      4.626,
      15.554,
      -35.554,
      4.626,
      13.104,
      -36.066,
      3.099,
      13.104
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9679",
    "polygon": [
      -35.554,
      4.626,
      13.104,
      -35.554,
      4.626,
      15.554,
      -35.316,
      5.337,
      15.554,
      -35.316,
      5.337,
      15.554,
      -35.316,
      5.337,
      13.104,
      -35.554,
      4.626,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_9680",
    "polygon": [
      -35.316,
      5.337,
      13.104,
      -35.316,
      5.337,
      15.554,
      -35.118,
      5.929,
      15.554,
      -35.118,
      5.929,
      15.554,
      -35.118,
      5.929,
      13.104,
      -35.316,
      5.337,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9681",
    "polygon": [
      -24.041,
      38.983,
      9.354,
      -24.041,
      38.983,
      11.804,
      -23.53,
      40.509,
      11.804,
      -23.53,
      40.509,
      11.804,
      -23.53,
      40.509,
      9.354,
      -24.041,
      38.983,
      9.354
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9682",
    "polygon": [
      -23.53,
      40.509,
      9.354,
      -23.53,
      40.509,
      11.804,
      -23.292,
      41.221,
      11.804,
      -23.292,
      41.221,
      11.804,
      -23.292,
      41.221,
      9.354,
      -23.53,
      40.509,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9683",
    "polygon": [
      -23.093,
      41.813,
      9.354,
      -23.093,
      41.813,
      11.804,
      -22.582,
      43.34,
      11.804,
      -22.582,
      43.34,
      11.804,
      -22.582,
      43.34,
      9.354,
      -23.093,
      41.813,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9684",
    "polygon": [
      -23.292,
      41.221,
      9.354,
      -23.292,
      41.221,
      11.804,
      -23.093,
      41.813,
      11.804,
      -23.093,
      41.813,
      11.804,
      -23.093,
      41.813,
      9.354,
      -23.292,
      41.221,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9685",
    "polygon": [
      -25.038,
      36.009,
      9.354,
      -25.038,
      36.009,
      11.804,
      -24.526,
      37.536,
      11.804,
      -24.526,
      37.536,
      11.804,
      -24.526,
      37.536,
      9.354,
      -25.038,
      36.009,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9686",
    "polygon": [
      -24.526,
      37.536,
      9.354,
      -24.526,
      37.536,
      11.804,
      -24.288,
      38.247,
      11.804,
      -24.288,
      38.247,
      11.804,
      -24.288,
      38.247,
      9.354,
      -24.526,
      37.536,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9687",
    "polygon": [
      -25.986,
      33.179,
      9.354,
      -25.986,
      33.179,
      11.804,
      -25.474,
      34.705,
      11.804,
      -25.474,
      34.705,
      11.804,
      -25.474,
      34.705,
      9.354,
      -25.986,
      33.179,
      9.354
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9688",
    "polygon": [
      -25.474,
      34.705,
      9.354,
      -25.474,
      34.705,
      11.804,
      -25.236,
      35.416,
      11.804,
      -25.236,
      35.416,
      11.804,
      -25.236,
      35.416,
      9.354,
      -25.474,
      34.705,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9689",
    "polygon": [
      -25.236,
      35.416,
      9.354,
      -25.236,
      35.416,
      11.804,
      -25.038,
      36.009,
      11.804,
      -25.038,
      36.009,
      11.804,
      -25.038,
      36.009,
      9.354,
      -25.236,
      35.416,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9690",
    "polygon": [
      -26.982,
      30.205,
      9.354,
      -26.982,
      30.205,
      11.804,
      -26.47,
      31.732,
      11.804,
      -26.47,
      31.732,
      11.804,
      -26.47,
      31.732,
      9.354,
      -26.982,
      30.205,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9691",
    "polygon": [
      -26.47,
      31.732,
      9.354,
      -26.47,
      31.732,
      11.804,
      -26.232,
      32.443,
      11.804,
      -26.232,
      32.443,
      11.804,
      -26.232,
      32.443,
      9.354,
      -26.47,
      31.732,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9692",
    "polygon": [
      -27.93,
      27.375,
      9.354,
      -27.93,
      27.375,
      11.804,
      -27.419,
      28.901,
      11.804,
      -27.419,
      28.901,
      11.804,
      -27.419,
      28.901,
      9.354,
      -27.93,
      27.375,
      9.354
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9693",
    "polygon": [
      -27.419,
      28.901,
      9.354,
      -27.419,
      28.901,
      11.804,
      -27.18,
      29.612,
      11.804,
      -27.18,
      29.612,
      11.804,
      -27.18,
      29.612,
      9.354,
      -27.419,
      28.901,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9694",
    "polygon": [
      -27.18,
      29.612,
      9.354,
      -27.18,
      29.612,
      11.804,
      -26.982,
      30.205,
      11.804,
      -26.982,
      30.205,
      11.804,
      -26.982,
      30.205,
      9.354,
      -27.18,
      29.612,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9695",
    "polygon": [
      -29.005,
      24.164,
      9.354,
      -29.005,
      24.164,
      11.804,
      -28.494,
      25.691,
      11.804,
      -28.494,
      25.691,
      11.804,
      -28.494,
      25.691,
      9.354,
      -29.005,
      24.164,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9696",
    "polygon": [
      -28.494,
      25.691,
      9.354,
      -28.494,
      25.691,
      11.804,
      -28.256,
      26.402,
      11.804,
      -28.256,
      26.402,
      11.804,
      -28.256,
      26.402,
      9.354,
      -28.494,
      25.691,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9697",
    "polygon": [
      -29.954,
      21.334,
      9.354,
      -29.954,
      21.334,
      11.804,
      -29.442,
      22.86,
      11.804,
      -29.442,
      22.86,
      11.804,
      -29.442,
      22.86,
      9.354,
      -29.954,
      21.334,
      9.354
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9698",
    "polygon": [
      -29.442,
      22.86,
      9.354,
      -29.442,
      22.86,
      11.804,
      -29.204,
      23.571,
      11.804,
      -29.204,
      23.571,
      11.804,
      -29.204,
      23.571,
      9.354,
      -29.442,
      22.86,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9699",
    "polygon": [
      -29.204,
      23.571,
      9.354,
      -29.204,
      23.571,
      11.804,
      -29.005,
      24.164,
      11.804,
      -29.005,
      24.164,
      11.804,
      -29.005,
      24.164,
      9.354,
      -29.204,
      23.571,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9700",
    "polygon": [
      -30.991,
      18.237,
      9.354,
      -30.991,
      18.237,
      11.804,
      -30.48,
      19.763,
      11.804,
      -30.48,
      19.763,
      11.804,
      -30.48,
      19.763,
      9.354,
      -30.991,
      18.237,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9701",
    "polygon": [
      -30.48,
      19.763,
      9.354,
      -30.48,
      19.763,
      11.804,
      -30.241,
      20.475,
      11.804,
      -30.241,
      20.475,
      11.804,
      -30.241,
      20.475,
      9.354,
      -30.48,
      19.763,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9702",
    "polygon": [
      -31.939,
      15.406,
      9.354,
      -31.939,
      15.406,
      11.804,
      -31.428,
      16.933,
      11.804,
      -31.428,
      16.933,
      11.804,
      -31.428,
      16.933,
      9.354,
      -31.939,
      15.406,
      9.354
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9703",
    "polygon": [
      -31.428,
      16.933,
      9.354,
      -31.428,
      16.933,
      11.804,
      -31.19,
      17.644,
      11.804,
      -31.19,
      17.644,
      11.804,
      -31.19,
      17.644,
      9.354,
      -31.428,
      16.933,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9704",
    "polygon": [
      -31.19,
      17.644,
      9.354,
      -31.19,
      17.644,
      11.804,
      -30.991,
      18.237,
      11.804,
      -30.991,
      18.237,
      11.804,
      -30.991,
      18.237,
      9.354,
      -31.19,
      17.644,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9705",
    "polygon": [
      -33.021,
      12.177,
      9.354,
      -33.021,
      12.177,
      11.804,
      -32.51,
      13.703,
      11.804,
      -32.51,
      13.703,
      11.804,
      -32.51,
      13.703,
      9.354,
      -33.021,
      12.177,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9706",
    "polygon": [
      -32.51,
      13.703,
      9.354,
      -32.51,
      13.703,
      11.804,
      -32.271,
      14.415,
      11.804,
      -32.271,
      14.415,
      11.804,
      -32.271,
      14.415,
      9.354,
      -32.51,
      13.703,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9707",
    "polygon": [
      -33.969,
      9.346,
      9.354,
      -33.969,
      9.346,
      11.804,
      -33.458,
      10.873,
      11.804,
      -33.458,
      10.873,
      11.804,
      -33.458,
      10.873,
      9.354,
      -33.969,
      9.346,
      9.354
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9708",
    "polygon": [
      -33.458,
      10.873,
      9.354,
      -33.458,
      10.873,
      11.804,
      -33.22,
      11.584,
      11.804,
      -33.22,
      11.584,
      11.804,
      -33.22,
      11.584,
      9.354,
      -33.458,
      10.873,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9709",
    "polygon": [
      -33.22,
      11.584,
      9.354,
      -33.22,
      11.584,
      11.804,
      -33.021,
      12.177,
      11.804,
      -33.021,
      12.177,
      11.804,
      -33.021,
      12.177,
      9.354,
      -33.22,
      11.584,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9710",
    "polygon": [
      -35.114,
      5.928,
      9.354,
      -35.114,
      5.928,
      11.804,
      -34.603,
      7.455,
      11.804,
      -34.603,
      7.455,
      11.804,
      -34.603,
      7.455,
      9.354,
      -35.114,
      5.928,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9711",
    "polygon": [
      -34.603,
      7.455,
      9.354,
      -34.603,
      7.455,
      11.804,
      -34.365,
      8.166,
      11.804,
      -34.365,
      8.166,
      11.804,
      -34.365,
      8.166,
      9.354,
      -34.603,
      7.455,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9712",
    "polygon": [
      -36.062,
      3.098,
      9.354,
      -36.062,
      3.098,
      11.804,
      -35.551,
      4.625,
      11.804,
      -35.551,
      4.625,
      11.804,
      -35.551,
      4.625,
      9.354,
      -36.062,
      3.098,
      9.354
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9713",
    "polygon": [
      -35.551,
      4.625,
      9.354,
      -35.551,
      4.625,
      11.804,
      -35.313,
      5.336,
      11.804,
      -35.313,
      5.336,
      11.804,
      -35.313,
      5.336,
      9.354,
      -35.551,
      4.625,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_9714",
    "polygon": [
      -35.313,
      5.336,
      9.354,
      -35.313,
      5.336,
      11.804,
      -35.114,
      5.928,
      11.804,
      -35.114,
      5.928,
      11.804,
      -35.114,
      5.928,
      9.354,
      -35.313,
      5.336,
      9.354
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9715",
    "polygon": [
      -24.044,
      38.984,
      5.604,
      -24.044,
      38.984,
      8.054,
      -23.533,
      40.51,
      8.054,
      -23.533,
      40.51,
      8.054,
      -23.533,
      40.51,
      5.604,
      -24.044,
      38.984,
      5.604
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9716",
    "polygon": [
      -23.533,
      40.51,
      5.604,
      -23.533,
      40.51,
      8.054,
      -23.294,
      41.221,
      8.054,
      -23.294,
      41.221,
      8.054,
      -23.294,
      41.221,
      5.604,
      -23.533,
      40.51,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9717",
    "polygon": [
      -23.096,
      41.814,
      5.604,
      -23.096,
      41.814,
      8.054,
      -22.585,
      43.341,
      8.054,
      -22.585,
      43.341,
      8.054,
      -22.585,
      43.341,
      5.604,
      -23.096,
      41.814,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9718",
    "polygon": [
      -23.294,
      41.221,
      5.604,
      -23.294,
      41.221,
      8.054,
      -23.096,
      41.814,
      8.054,
      -23.096,
      41.814,
      8.054,
      -23.096,
      41.814,
      5.604,
      -23.294,
      41.221,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9719",
    "polygon": [
      -25.04,
      36.01,
      5.604,
      -25.04,
      36.01,
      8.054,
      -24.529,
      37.537,
      8.054,
      -24.529,
      37.537,
      8.054,
      -24.529,
      37.537,
      5.604,
      -25.04,
      36.01,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9720",
    "polygon": [
      -24.529,
      37.537,
      5.604,
      -24.529,
      37.537,
      8.054,
      -24.291,
      38.248,
      8.054,
      -24.291,
      38.248,
      8.054,
      -24.291,
      38.248,
      5.604,
      -24.529,
      37.537,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9721",
    "polygon": [
      -25.988,
      33.18,
      5.604,
      -25.988,
      33.18,
      8.054,
      -25.477,
      34.706,
      8.054,
      -25.477,
      34.706,
      8.054,
      -25.477,
      34.706,
      5.604,
      -25.988,
      33.18,
      5.604
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9722",
    "polygon": [
      -25.477,
      34.706,
      5.604,
      -25.477,
      34.706,
      8.054,
      -25.239,
      35.417,
      8.054,
      -25.239,
      35.417,
      8.054,
      -25.239,
      35.417,
      5.604,
      -25.477,
      34.706,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9723",
    "polygon": [
      -25.239,
      35.417,
      5.604,
      -25.239,
      35.417,
      8.054,
      -25.04,
      36.01,
      8.054,
      -25.04,
      36.01,
      8.054,
      -25.04,
      36.01,
      5.604,
      -25.239,
      35.417,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9724",
    "polygon": [
      -26.984,
      30.206,
      5.604,
      -26.984,
      30.206,
      8.054,
      -26.473,
      31.733,
      8.054,
      -26.473,
      31.733,
      8.054,
      -26.473,
      31.733,
      5.604,
      -26.984,
      30.206,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9725",
    "polygon": [
      -26.473,
      31.733,
      5.604,
      -26.473,
      31.733,
      8.054,
      -26.235,
      32.444,
      8.054,
      -26.235,
      32.444,
      8.054,
      -26.235,
      32.444,
      5.604,
      -26.473,
      31.733,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9726",
    "polygon": [
      -27.933,
      27.376,
      5.604,
      -27.933,
      27.376,
      8.054,
      -27.421,
      28.902,
      8.054,
      -27.421,
      28.902,
      8.054,
      -27.421,
      28.902,
      5.604,
      -27.933,
      27.376,
      5.604
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9727",
    "polygon": [
      -27.421,
      28.902,
      5.604,
      -27.421,
      28.902,
      8.054,
      -27.183,
      29.613,
      8.054,
      -27.183,
      29.613,
      8.054,
      -27.183,
      29.613,
      5.604,
      -27.421,
      28.902,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9728",
    "polygon": [
      -27.183,
      29.613,
      5.604,
      -27.183,
      29.613,
      8.054,
      -26.984,
      30.206,
      8.054,
      -26.984,
      30.206,
      8.054,
      -26.984,
      30.206,
      5.604,
      -27.183,
      29.613,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9729",
    "polygon": [
      -29.008,
      24.165,
      5.604,
      -29.008,
      24.165,
      8.054,
      -28.497,
      25.692,
      8.054,
      -28.497,
      25.692,
      8.054,
      -28.497,
      25.692,
      5.604,
      -29.008,
      24.165,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9730",
    "polygon": [
      -28.497,
      25.692,
      5.604,
      -28.497,
      25.692,
      8.054,
      -28.259,
      26.403,
      8.054,
      -28.259,
      26.403,
      8.054,
      -28.259,
      26.403,
      5.604,
      -28.497,
      25.692,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9731",
    "polygon": [
      -29.956,
      21.335,
      5.604,
      -29.956,
      21.335,
      8.054,
      -29.445,
      22.861,
      8.054,
      -29.445,
      22.861,
      8.054,
      -29.445,
      22.861,
      5.604,
      -29.956,
      21.335,
      5.604
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9732",
    "polygon": [
      -29.445,
      22.861,
      5.604,
      -29.445,
      22.861,
      8.054,
      -29.207,
      23.572,
      8.054,
      -29.207,
      23.572,
      8.054,
      -29.207,
      23.572,
      5.604,
      -29.445,
      22.861,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9733",
    "polygon": [
      -29.207,
      23.572,
      5.604,
      -29.207,
      23.572,
      8.054,
      -29.008,
      24.165,
      8.054,
      -29.008,
      24.165,
      8.054,
      -29.008,
      24.165,
      5.604,
      -29.207,
      23.572,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9734",
    "polygon": [
      -30.994,
      18.238,
      5.604,
      -30.994,
      18.238,
      8.054,
      -30.482,
      19.764,
      8.054,
      -30.482,
      19.764,
      8.054,
      -30.482,
      19.764,
      5.604,
      -30.994,
      18.238,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9735",
    "polygon": [
      -30.482,
      19.764,
      5.604,
      -30.482,
      19.764,
      8.054,
      -30.244,
      20.475,
      8.054,
      -30.244,
      20.475,
      8.054,
      -30.244,
      20.475,
      5.604,
      -30.482,
      19.764,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9736",
    "polygon": [
      -31.942,
      15.407,
      5.604,
      -31.942,
      15.407,
      8.054,
      -31.43,
      16.934,
      8.054,
      -31.43,
      16.934,
      8.054,
      -31.43,
      16.934,
      5.604,
      -31.942,
      15.407,
      5.604
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9737",
    "polygon": [
      -31.43,
      16.934,
      5.604,
      -31.43,
      16.934,
      8.054,
      -31.192,
      17.645,
      8.054,
      -31.192,
      17.645,
      8.054,
      -31.192,
      17.645,
      5.604,
      -31.43,
      16.934,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9738",
    "polygon": [
      -31.192,
      17.645,
      5.604,
      -31.192,
      17.645,
      8.054,
      -30.994,
      18.238,
      8.054,
      -30.994,
      18.238,
      8.054,
      -30.994,
      18.238,
      5.604,
      -31.192,
      17.645,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9739",
    "polygon": [
      -33.024,
      12.178,
      5.604,
      -33.024,
      12.178,
      8.054,
      -32.512,
      13.704,
      8.054,
      -32.512,
      13.704,
      8.054,
      -32.512,
      13.704,
      5.604,
      -33.024,
      12.178,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9740",
    "polygon": [
      -32.512,
      13.704,
      5.604,
      -32.512,
      13.704,
      8.054,
      -32.274,
      14.415,
      8.054,
      -32.274,
      14.415,
      8.054,
      -32.274,
      14.415,
      5.604,
      -32.512,
      13.704,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9741",
    "polygon": [
      -33.972,
      9.347,
      5.604,
      -33.972,
      9.347,
      8.054,
      -33.46,
      10.874,
      8.054,
      -33.46,
      10.874,
      8.054,
      -33.46,
      10.874,
      5.604,
      -33.972,
      9.347,
      5.604
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9742",
    "polygon": [
      -33.46,
      10.874,
      5.604,
      -33.46,
      10.874,
      8.054,
      -33.222,
      11.585,
      8.054,
      -33.222,
      11.585,
      8.054,
      -33.222,
      11.585,
      5.604,
      -33.46,
      10.874,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9743",
    "polygon": [
      -33.222,
      11.585,
      5.604,
      -33.222,
      11.585,
      8.054,
      -33.024,
      12.178,
      8.054,
      -33.024,
      12.178,
      8.054,
      -33.024,
      12.178,
      5.604,
      -33.222,
      11.585,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9744",
    "polygon": [
      -35.117,
      5.929,
      5.604,
      -35.117,
      5.929,
      8.054,
      -34.605,
      7.456,
      8.054,
      -34.605,
      7.456,
      8.054,
      -34.605,
      7.456,
      5.604,
      -35.117,
      5.929,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9745",
    "polygon": [
      -34.605,
      7.456,
      5.604,
      -34.605,
      7.456,
      8.054,
      -34.367,
      8.167,
      8.054,
      -34.367,
      8.167,
      8.054,
      -34.367,
      8.167,
      5.604,
      -34.605,
      7.456,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9746",
    "polygon": [
      -36.065,
      3.099,
      5.604,
      -36.065,
      3.099,
      8.054,
      -35.554,
      4.625,
      8.054,
      -35.554,
      4.625,
      8.054,
      -35.554,
      4.625,
      5.604,
      -36.065,
      3.099,
      5.604
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9747",
    "polygon": [
      -35.554,
      4.625,
      5.604,
      -35.554,
      4.625,
      8.054,
      -35.315,
      5.337,
      8.054,
      -35.315,
      5.337,
      8.054,
      -35.315,
      5.337,
      5.604,
      -35.554,
      4.625,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_9748",
    "polygon": [
      -35.315,
      5.337,
      5.604,
      -35.315,
      5.337,
      8.054,
      -35.117,
      5.929,
      8.054,
      -35.117,
      5.929,
      8.054,
      -35.117,
      5.929,
      5.604,
      -35.315,
      5.337,
      5.604
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9749",
    "polygon": [
      -24.045,
      38.984,
      0.605,
      -24.045,
      38.984,
      3.365,
      -23.533,
      40.51,
      3.365,
      -23.533,
      40.51,
      3.365,
      -23.533,
      40.51,
      0.605,
      -24.045,
      38.984,
      0.605
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9750",
    "polygon": [
      -23.533,
      40.51,
      0.605,
      -23.533,
      40.51,
      3.365,
      -23.295,
      41.222,
      3.365,
      -23.295,
      41.222,
      3.365,
      -23.295,
      41.222,
      0.605,
      -23.533,
      40.51,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9751",
    "polygon": [
      -23.097,
      41.814,
      0.605,
      -23.097,
      41.814,
      3.365,
      -22.585,
      43.341,
      3.365,
      -22.585,
      43.341,
      3.365,
      -22.585,
      43.341,
      0.605,
      -23.097,
      41.814,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9752",
    "polygon": [
      -23.295,
      41.222,
      0.605,
      -23.295,
      41.222,
      3.365,
      -23.097,
      41.814,
      3.365,
      -23.097,
      41.814,
      3.365,
      -23.097,
      41.814,
      0.605,
      -23.295,
      41.222,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9753",
    "polygon": [
      -25.041,
      36.01,
      0.605,
      -25.041,
      36.01,
      3.365,
      -24.53,
      37.537,
      3.365,
      -24.53,
      37.537,
      3.365,
      -24.53,
      37.537,
      0.605,
      -25.041,
      36.01,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9754",
    "polygon": [
      -24.53,
      37.537,
      0.605,
      -24.53,
      37.537,
      3.365,
      -24.291,
      38.248,
      3.365,
      -24.291,
      38.248,
      3.365,
      -24.291,
      38.248,
      0.605,
      -24.53,
      37.537,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9755",
    "polygon": [
      -25.989,
      33.18,
      0.605,
      -25.989,
      33.18,
      3.365,
      -25.478,
      34.706,
      3.365,
      -25.478,
      34.706,
      3.365,
      -25.478,
      34.706,
      0.605,
      -25.989,
      33.18,
      0.605
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9756",
    "polygon": [
      -25.478,
      34.706,
      0.605,
      -25.478,
      34.706,
      3.365,
      -25.239,
      35.418,
      3.365,
      -25.239,
      35.418,
      3.365,
      -25.239,
      35.418,
      0.605,
      -25.478,
      34.706,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9757",
    "polygon": [
      -25.239,
      35.418,
      0.605,
      -25.239,
      35.418,
      3.365,
      -25.041,
      36.01,
      3.365,
      -25.041,
      36.01,
      3.365,
      -25.041,
      36.01,
      0.605,
      -25.239,
      35.418,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9758",
    "polygon": [
      -26.985,
      30.206,
      0.605,
      -26.985,
      30.206,
      3.365,
      -26.474,
      31.733,
      3.365,
      -26.474,
      31.733,
      3.365,
      -26.474,
      31.733,
      0.605,
      -26.985,
      30.206,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9759",
    "polygon": [
      -26.474,
      31.733,
      0.605,
      -26.474,
      31.733,
      3.365,
      -26.236,
      32.444,
      3.365,
      -26.236,
      32.444,
      3.365,
      -26.236,
      32.444,
      0.605,
      -26.474,
      31.733,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9760",
    "polygon": [
      -27.933,
      27.376,
      0.605,
      -27.933,
      27.376,
      3.365,
      -27.422,
      28.902,
      3.365,
      -27.422,
      28.902,
      3.365,
      -27.422,
      28.902,
      0.605,
      -27.933,
      27.376,
      0.605
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9761",
    "polygon": [
      -27.422,
      28.902,
      0.605,
      -27.422,
      28.902,
      3.365,
      -27.184,
      29.614,
      3.365,
      -27.184,
      29.614,
      3.365,
      -27.184,
      29.614,
      0.605,
      -27.422,
      28.902,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9762",
    "polygon": [
      -27.184,
      29.614,
      0.605,
      -27.184,
      29.614,
      3.365,
      -26.985,
      30.206,
      3.365,
      -26.985,
      30.206,
      3.365,
      -26.985,
      30.206,
      0.605,
      -27.184,
      29.614,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9763",
    "polygon": [
      -29.009,
      24.165,
      0.605,
      -29.009,
      24.165,
      3.365,
      -28.497,
      25.692,
      3.365,
      -28.497,
      25.692,
      3.365,
      -28.497,
      25.692,
      0.605,
      -29.009,
      24.165,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9764",
    "polygon": [
      -28.497,
      25.692,
      0.605,
      -28.497,
      25.692,
      3.365,
      -28.259,
      26.403,
      3.365,
      -28.259,
      26.403,
      3.365,
      -28.259,
      26.403,
      0.605,
      -28.497,
      25.692,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9765",
    "polygon": [
      -29.957,
      21.335,
      0.605,
      -29.957,
      21.335,
      3.365,
      -29.446,
      22.861,
      3.365,
      -29.446,
      22.861,
      3.365,
      -29.446,
      22.861,
      0.605,
      -29.957,
      21.335,
      0.605
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9766",
    "polygon": [
      -29.446,
      22.861,
      0.605,
      -29.446,
      22.861,
      3.365,
      -29.207,
      23.573,
      3.365,
      -29.207,
      23.573,
      3.365,
      -29.207,
      23.573,
      0.605,
      -29.446,
      22.861,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9767",
    "polygon": [
      -29.207,
      23.573,
      0.605,
      -29.207,
      23.573,
      3.365,
      -29.009,
      24.165,
      3.365,
      -29.009,
      24.165,
      3.365,
      -29.009,
      24.165,
      0.605,
      -29.207,
      23.573,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9768",
    "polygon": [
      -30.994,
      18.238,
      0.605,
      -30.994,
      18.238,
      3.365,
      -30.483,
      19.765,
      3.365,
      -30.483,
      19.765,
      3.365,
      -30.483,
      19.765,
      0.605,
      -30.994,
      18.238,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9769",
    "polygon": [
      -30.483,
      19.765,
      0.605,
      -30.483,
      19.765,
      3.365,
      -30.245,
      20.476,
      3.365,
      -30.245,
      20.476,
      3.365,
      -30.245,
      20.476,
      0.605,
      -30.483,
      19.765,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9770",
    "polygon": [
      -31.943,
      15.408,
      0.605,
      -31.943,
      15.408,
      3.365,
      -31.431,
      16.934,
      3.365,
      -31.431,
      16.934,
      3.365,
      -31.431,
      16.934,
      0.605,
      -31.943,
      15.408,
      0.605
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9771",
    "polygon": [
      -31.431,
      16.934,
      0.605,
      -31.431,
      16.934,
      3.365,
      -31.193,
      17.645,
      3.365,
      -31.193,
      17.645,
      3.365,
      -31.193,
      17.645,
      0.605,
      -31.431,
      16.934,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9772",
    "polygon": [
      -31.193,
      17.645,
      0.605,
      -31.193,
      17.645,
      3.365,
      -30.994,
      18.238,
      3.365,
      -30.994,
      18.238,
      3.365,
      -30.994,
      18.238,
      0.605,
      -31.193,
      17.645,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9773",
    "polygon": [
      -33.024,
      12.178,
      0.605,
      -33.024,
      12.178,
      3.365,
      -32.513,
      13.705,
      3.365,
      -32.513,
      13.705,
      3.365,
      -32.513,
      13.705,
      0.605,
      -33.024,
      12.178,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9774",
    "polygon": [
      -32.513,
      13.705,
      0.605,
      -32.513,
      13.705,
      3.365,
      -32.275,
      14.416,
      3.365,
      -32.275,
      14.416,
      3.365,
      -32.275,
      14.416,
      0.605,
      -32.513,
      13.705,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9775",
    "polygon": [
      -33.973,
      9.347,
      0.605,
      -33.973,
      9.347,
      3.365,
      -33.461,
      10.874,
      3.365,
      -33.461,
      10.874,
      3.365,
      -33.461,
      10.874,
      0.605,
      -33.973,
      9.347,
      0.605
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_9776",
    "polygon": [
      -33.461,
      10.874,
      0.605,
      -33.461,
      10.874,
      3.365,
      -33.223,
      11.585,
      3.365,
      -33.223,
      11.585,
      3.365,
      -33.223,
      11.585,
      0.605,
      -33.461,
      10.874,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9777",
    "polygon": [
      -33.223,
      11.585,
      0.605,
      -33.223,
      11.585,
      3.365,
      -33.024,
      12.178,
      3.365,
      -33.024,
      12.178,
      3.365,
      -33.024,
      12.178,
      0.605,
      -33.223,
      11.585,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9778",
    "polygon": [
      -35.118,
      5.929,
      0.605,
      -35.118,
      5.929,
      3.365,
      -34.606,
      7.456,
      3.365,
      -34.606,
      7.456,
      3.365,
      -34.606,
      7.456,
      0.605,
      -35.118,
      5.929,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_9779",
    "polygon": [
      -34.606,
      7.456,
      0.605,
      -34.606,
      7.456,
      3.365,
      -34.368,
      8.167,
      3.365,
      -34.368,
      8.167,
      3.365,
      -34.368,
      8.167,
      0.605,
      -34.606,
      7.456,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_9780",
    "polygon": [
      -36.066,
      3.099,
      0.605,
      -36.066,
      3.099,
      3.365,
      -35.554,
      4.626,
      3.365,
      -35.554,
      4.626,
      3.365,
      -35.554,
      4.626,
      0.605,
      -36.066,
      3.099,
      0.605
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-Outer_EG_9781",
    "polygon": [
      -35.554,
      4.626,
      0.605,
      -35.554,
      4.626,
      3.365,
      -35.316,
      5.337,
      3.365,
      -35.316,
      5.337,
      3.365,
      -35.316,
      5.337,
      0.605,
      -35.554,
      4.626,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_9782",
    "polygon": [
      -35.316,
      5.337,
      0.605,
      -35.316,
      5.337,
      3.365,
      -35.118,
      5.929,
      3.365,
      -35.118,
      5.929,
      3.365,
      -35.118,
      5.929,
      0.605,
      -35.316,
      5.337,
      0.605
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9783",
    "polygon": [
      -59.1,
      13.181,
      13.09,
      -59.1,
      13.181,
      15.54,
      -59.612,
      11.655,
      15.54,
      -59.612,
      11.655,
      15.54,
      -59.612,
      11.655,
      13.09,
      -59.1,
      13.181,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9784",
    "polygon": [
      -58.862,
      13.892,
      13.09,
      -58.862,
      13.892,
      15.54,
      -59.1,
      13.181,
      15.54,
      -59.1,
      13.181,
      15.54,
      -59.1,
      13.181,
      13.09,
      -58.862,
      13.892,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9785",
    "polygon": [
      -58.664,
      14.485,
      13.09,
      -58.664,
      14.485,
      15.54,
      -58.862,
      13.892,
      15.54,
      -58.862,
      13.892,
      15.54,
      -58.862,
      13.892,
      13.09,
      -58.664,
      14.485,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9786",
    "polygon": [
      -58.152,
      16.012,
      13.09,
      -58.152,
      16.012,
      15.54,
      -58.664,
      14.485,
      15.54,
      -58.664,
      14.485,
      15.54,
      -58.664,
      14.485,
      13.09,
      -58.152,
      16.012,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9787",
    "polygon": [
      -57.914,
      16.723,
      13.09,
      -57.914,
      16.723,
      15.54,
      -58.152,
      16.012,
      15.54,
      -58.152,
      16.012,
      15.54,
      -58.152,
      16.012,
      13.09,
      -57.914,
      16.723,
      13.09
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9788",
    "polygon": [
      -57.156,
      18.985,
      13.09,
      -57.156,
      18.985,
      15.54,
      -57.667,
      17.459,
      15.54,
      -57.667,
      17.459,
      15.54,
      -57.667,
      17.459,
      13.09,
      -57.156,
      18.985,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9789",
    "polygon": [
      -56.918,
      19.696,
      13.09,
      -56.918,
      19.696,
      15.54,
      -57.156,
      18.985,
      15.54,
      -57.156,
      18.985,
      15.54,
      -57.156,
      18.985,
      13.09,
      -56.918,
      19.696,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9790",
    "polygon": [
      -56.719,
      20.289,
      13.09,
      -56.719,
      20.289,
      15.54,
      -56.918,
      19.696,
      15.54,
      -56.918,
      19.696,
      15.54,
      -56.918,
      19.696,
      13.09,
      -56.719,
      20.289,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9791",
    "polygon": [
      -56.208,
      21.816,
      13.09,
      -56.208,
      21.816,
      15.54,
      -56.719,
      20.289,
      15.54,
      -56.719,
      20.289,
      15.54,
      -56.719,
      20.289,
      13.09,
      -56.208,
      21.816,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9792",
    "polygon": [
      -55.97,
      22.527,
      13.09,
      -55.97,
      22.527,
      15.54,
      -56.208,
      21.816,
      15.54,
      -56.208,
      21.816,
      15.54,
      -56.208,
      21.816,
      13.09,
      -55.97,
      22.527,
      13.09
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9793",
    "polygon": [
      -55.212,
      24.789,
      13.09,
      -55.212,
      24.789,
      15.54,
      -55.723,
      23.263,
      15.54,
      -55.723,
      23.263,
      15.54,
      -55.723,
      23.263,
      13.09,
      -55.212,
      24.789,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9794",
    "polygon": [
      -54.974,
      25.5,
      13.09,
      -54.974,
      25.5,
      15.54,
      -55.212,
      24.789,
      15.54,
      -55.212,
      24.789,
      15.54,
      -55.212,
      24.789,
      13.09,
      -54.974,
      25.5,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9795",
    "polygon": [
      -54.775,
      26.093,
      13.09,
      -54.775,
      26.093,
      15.54,
      -54.974,
      25.5,
      15.54,
      -54.974,
      25.5,
      15.54,
      -54.974,
      25.5,
      13.09,
      -54.775,
      26.093,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9796",
    "polygon": [
      -54.264,
      27.62,
      13.09,
      -54.264,
      27.62,
      15.54,
      -54.775,
      26.093,
      15.54,
      -54.775,
      26.093,
      15.54,
      -54.775,
      26.093,
      13.09,
      -54.264,
      27.62,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9797",
    "polygon": [
      -54.025,
      28.331,
      13.09,
      -54.025,
      28.331,
      15.54,
      -54.264,
      27.62,
      15.54,
      -54.264,
      27.62,
      15.54,
      -54.264,
      27.62,
      13.09,
      -54.025,
      28.331,
      13.09
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9798",
    "polygon": [
      -53.268,
      30.593,
      13.09,
      -53.268,
      30.593,
      15.54,
      -53.779,
      29.067,
      15.54,
      -53.779,
      29.067,
      15.54,
      -53.779,
      29.067,
      13.09,
      -53.268,
      30.593,
      13.09
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9799",
    "polygon": [
      -53.029,
      31.304,
      13.09,
      -53.029,
      31.304,
      15.54,
      -53.268,
      30.593,
      15.54,
      -53.268,
      30.593,
      15.54,
      -53.268,
      30.593,
      13.09,
      -53.029,
      31.304,
      13.09
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9800",
    "polygon": [
      -52.831,
      31.897,
      13.09,
      -52.831,
      31.897,
      15.54,
      -53.029,
      31.304,
      15.54,
      -53.029,
      31.304,
      15.54,
      -53.029,
      31.304,
      13.09,
      -52.831,
      31.897,
      13.09
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9801",
    "polygon": [
      -52.319,
      33.424,
      13.09,
      -52.319,
      33.424,
      15.54,
      -52.831,
      31.897,
      15.54,
      -52.831,
      31.897,
      15.54,
      -52.831,
      31.897,
      13.09,
      -52.319,
      33.424,
      13.09
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9802",
    "polygon": [
      -52.081,
      34.135,
      13.09,
      -52.081,
      34.135,
      15.54,
      -52.319,
      33.424,
      15.54,
      -52.319,
      33.424,
      15.54,
      -52.319,
      33.424,
      13.09,
      -52.081,
      34.135,
      13.09
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9803",
    "polygon": [
      -51.323,
      36.397,
      13.09,
      -51.323,
      36.397,
      15.54,
      -51.835,
      34.871,
      15.54,
      -51.835,
      34.871,
      15.54,
      -51.835,
      34.871,
      13.09,
      -51.323,
      36.397,
      13.09
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9804",
    "polygon": [
      -51.085,
      37.108,
      13.09,
      -51.085,
      37.108,
      15.54,
      -51.323,
      36.397,
      15.54,
      -51.323,
      36.397,
      15.54,
      -51.323,
      36.397,
      13.09,
      -51.085,
      37.108,
      13.09
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9805",
    "polygon": [
      -50.887,
      37.701,
      13.09,
      -50.887,
      37.701,
      15.54,
      -51.085,
      37.108,
      15.54,
      -51.085,
      37.108,
      15.54,
      -51.085,
      37.108,
      13.09,
      -50.887,
      37.701,
      13.09
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9806",
    "polygon": [
      -50.375,
      39.228,
      13.09,
      -50.375,
      39.228,
      15.54,
      -50.887,
      37.701,
      15.54,
      -50.887,
      37.701,
      15.54,
      -50.887,
      37.701,
      13.09,
      -50.375,
      39.228,
      13.09
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9807",
    "polygon": [
      -50.137,
      39.939,
      13.09,
      -50.137,
      39.939,
      15.54,
      -50.375,
      39.228,
      15.54,
      -50.375,
      39.228,
      15.54,
      -50.375,
      39.228,
      13.09,
      -50.137,
      39.939,
      13.09
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9808",
    "polygon": [
      -49.379,
      42.201,
      13.09,
      -49.379,
      42.201,
      15.54,
      -49.89,
      40.675,
      15.54,
      -49.89,
      40.675,
      15.54,
      -49.89,
      40.675,
      13.09,
      -49.379,
      42.201,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9809",
    "polygon": [
      -49.141,
      42.912,
      13.09,
      -49.141,
      42.912,
      15.54,
      -49.379,
      42.201,
      15.54,
      -49.379,
      42.201,
      15.54,
      -49.379,
      42.201,
      13.09,
      -49.141,
      42.912,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9810",
    "polygon": [
      -48.942,
      43.505,
      13.09,
      -48.942,
      43.505,
      15.54,
      -49.141,
      42.912,
      15.54,
      -49.141,
      42.912,
      15.54,
      -49.141,
      42.912,
      13.09,
      -48.942,
      43.505,
      13.09
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9811",
    "polygon": [
      -48.431,
      45.032,
      13.09,
      -48.431,
      45.032,
      15.54,
      -48.942,
      43.505,
      15.54,
      -48.942,
      43.505,
      15.54,
      -48.942,
      43.505,
      13.09,
      -48.431,
      45.032,
      13.09
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9812",
    "polygon": [
      -48.193,
      45.743,
      13.09,
      -48.193,
      45.743,
      15.54,
      -48.431,
      45.032,
      15.54,
      -48.431,
      45.032,
      15.54,
      -48.431,
      45.032,
      13.09,
      -48.193,
      45.743,
      13.09
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9813",
    "polygon": [
      -47.435,
      48.005,
      13.09,
      -47.435,
      48.005,
      15.54,
      -47.946,
      46.479,
      15.54,
      -47.946,
      46.479,
      15.54,
      -47.946,
      46.479,
      13.09,
      -47.435,
      48.005,
      13.09
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9814",
    "polygon": [
      -47.197,
      48.716,
      13.09,
      -47.197,
      48.716,
      15.54,
      -47.435,
      48.005,
      15.54,
      -47.435,
      48.005,
      15.54,
      -47.435,
      48.005,
      13.09,
      -47.197,
      48.716,
      13.09
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9815",
    "polygon": [
      -46.998,
      49.309,
      13.09,
      -46.998,
      49.309,
      15.54,
      -47.197,
      48.716,
      15.54,
      -47.197,
      48.716,
      15.54,
      -47.197,
      48.716,
      13.09,
      -46.998,
      49.309,
      13.09
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9816",
    "polygon": [
      -46.487,
      50.836,
      13.09,
      -46.487,
      50.836,
      15.54,
      -46.998,
      49.309,
      15.54,
      -46.998,
      49.309,
      15.54,
      -46.998,
      49.309,
      13.09,
      -46.487,
      50.836,
      13.09
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_9817",
    "polygon": [
      -46.248,
      51.547,
      13.09,
      -46.248,
      51.547,
      15.54,
      -46.487,
      50.836,
      15.54,
      -46.487,
      50.836,
      15.54,
      -46.487,
      50.836,
      13.09,
      -46.248,
      51.547,
      13.09
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9818",
    "polygon": [
      -59.097,
      13.18,
      9.34,
      -59.097,
      13.18,
      11.79,
      -59.608,
      11.653,
      11.79,
      -59.608,
      11.653,
      11.79,
      -59.608,
      11.653,
      9.34,
      -59.097,
      13.18,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9819",
    "polygon": [
      -58.859,
      13.891,
      9.34,
      -58.859,
      13.891,
      11.79,
      -59.097,
      13.18,
      11.79,
      -59.097,
      13.18,
      11.79,
      -59.097,
      13.18,
      9.34,
      -58.859,
      13.891,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9820",
    "polygon": [
      -58.661,
      14.484,
      9.34,
      -58.661,
      14.484,
      11.79,
      -58.859,
      13.891,
      11.79,
      -58.859,
      13.891,
      11.79,
      -58.859,
      13.891,
      9.34,
      -58.661,
      14.484,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9821",
    "polygon": [
      -58.149,
      16.011,
      9.34,
      -58.149,
      16.011,
      11.79,
      -58.661,
      14.484,
      11.79,
      -58.661,
      14.484,
      11.79,
      -58.661,
      14.484,
      9.34,
      -58.149,
      16.011,
      9.34
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9822",
    "polygon": [
      -57.911,
      16.722,
      9.34,
      -57.911,
      16.722,
      11.79,
      -58.149,
      16.011,
      11.79,
      -58.149,
      16.011,
      11.79,
      -58.149,
      16.011,
      9.34,
      -57.911,
      16.722,
      9.34
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9823",
    "polygon": [
      -57.154,
      18.984,
      9.34,
      -57.154,
      18.984,
      11.79,
      -57.665,
      17.458,
      11.79,
      -57.665,
      17.458,
      11.79,
      -57.665,
      17.458,
      9.34,
      -57.154,
      18.984,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9824",
    "polygon": [
      -56.916,
      19.696,
      9.34,
      -56.916,
      19.696,
      11.79,
      -57.154,
      18.984,
      11.79,
      -57.154,
      18.984,
      11.79,
      -57.154,
      18.984,
      9.34,
      -56.916,
      19.696,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9825",
    "polygon": [
      -56.717,
      20.288,
      9.34,
      -56.717,
      20.288,
      11.79,
      -56.916,
      19.696,
      11.79,
      -56.916,
      19.696,
      11.79,
      -56.916,
      19.696,
      9.34,
      -56.717,
      20.288,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9826",
    "polygon": [
      -56.206,
      21.815,
      9.34,
      -56.206,
      21.815,
      11.79,
      -56.717,
      20.288,
      11.79,
      -56.717,
      20.288,
      11.79,
      -56.717,
      20.288,
      9.34,
      -56.206,
      21.815,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9827",
    "polygon": [
      -55.968,
      22.526,
      9.34,
      -55.968,
      22.526,
      11.79,
      -56.206,
      21.815,
      11.79,
      -56.206,
      21.815,
      11.79,
      -56.206,
      21.815,
      9.34,
      -55.968,
      22.526,
      9.34
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9828",
    "polygon": [
      -55.211,
      24.789,
      9.34,
      -55.211,
      24.789,
      11.79,
      -55.722,
      23.262,
      11.79,
      -55.722,
      23.262,
      11.79,
      -55.722,
      23.262,
      9.34,
      -55.211,
      24.789,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9829",
    "polygon": [
      -54.973,
      25.5,
      9.34,
      -54.973,
      25.5,
      11.79,
      -55.211,
      24.789,
      11.79,
      -55.211,
      24.789,
      11.79,
      -55.211,
      24.789,
      9.34,
      -54.973,
      25.5,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9830",
    "polygon": [
      -54.774,
      26.093,
      9.34,
      -54.774,
      26.093,
      11.79,
      -54.973,
      25.5,
      11.79,
      -54.973,
      25.5,
      11.79,
      -54.973,
      25.5,
      9.34,
      -54.774,
      26.093,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9831",
    "polygon": [
      -54.263,
      27.619,
      9.34,
      -54.263,
      27.619,
      11.79,
      -54.774,
      26.093,
      11.79,
      -54.774,
      26.093,
      11.79,
      -54.774,
      26.093,
      9.34,
      -54.263,
      27.619,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9832",
    "polygon": [
      -54.025,
      28.331,
      9.34,
      -54.025,
      28.331,
      11.79,
      -54.263,
      27.619,
      11.79,
      -54.263,
      27.619,
      11.79,
      -54.263,
      27.619,
      9.34,
      -54.025,
      28.331,
      9.34
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9833",
    "polygon": [
      -53.268,
      30.593,
      9.34,
      -53.268,
      30.593,
      11.79,
      -53.779,
      29.066,
      11.79,
      -53.779,
      29.066,
      11.79,
      -53.779,
      29.066,
      9.34,
      -53.268,
      30.593,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9834",
    "polygon": [
      -53.029,
      31.304,
      9.34,
      -53.029,
      31.304,
      11.79,
      -53.268,
      30.593,
      11.79,
      -53.268,
      30.593,
      11.79,
      -53.268,
      30.593,
      9.34,
      -53.029,
      31.304,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9835",
    "polygon": [
      -52.831,
      31.897,
      9.34,
      -52.831,
      31.897,
      11.79,
      -53.029,
      31.304,
      11.79,
      -53.029,
      31.304,
      11.79,
      -53.029,
      31.304,
      9.34,
      -52.831,
      31.897,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9836",
    "polygon": [
      -52.32,
      33.424,
      9.34,
      -52.32,
      33.424,
      11.79,
      -52.831,
      31.897,
      11.79,
      -52.831,
      31.897,
      11.79,
      -52.831,
      31.897,
      9.34,
      -52.32,
      33.424,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9837",
    "polygon": [
      -52.082,
      34.135,
      9.34,
      -52.082,
      34.135,
      11.79,
      -52.32,
      33.424,
      11.79,
      -52.32,
      33.424,
      11.79,
      -52.32,
      33.424,
      9.34,
      -52.082,
      34.135,
      9.34
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9838",
    "polygon": [
      -51.324,
      36.397,
      9.34,
      -51.324,
      36.397,
      11.79,
      -51.835,
      34.871,
      11.79,
      -51.835,
      34.871,
      11.79,
      -51.835,
      34.871,
      9.34,
      -51.324,
      36.397,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9839",
    "polygon": [
      -51.086,
      37.109,
      9.34,
      -51.086,
      37.109,
      11.79,
      -51.324,
      36.397,
      11.79,
      -51.324,
      36.397,
      11.79,
      -51.324,
      36.397,
      9.34,
      -51.086,
      37.109,
      9.34
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9840",
    "polygon": [
      -50.888,
      37.701,
      9.34,
      -50.888,
      37.701,
      11.79,
      -51.086,
      37.109,
      11.79,
      -51.086,
      37.109,
      11.79,
      -51.086,
      37.109,
      9.34,
      -50.888,
      37.701,
      9.34
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9841",
    "polygon": [
      -50.377,
      39.228,
      9.34,
      -50.377,
      39.228,
      11.79,
      -50.888,
      37.701,
      11.79,
      -50.888,
      37.701,
      11.79,
      -50.888,
      37.701,
      9.34,
      -50.377,
      39.228,
      9.34
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9842",
    "polygon": [
      -50.139,
      39.939,
      9.34,
      -50.139,
      39.939,
      11.79,
      -50.377,
      39.228,
      11.79,
      -50.377,
      39.228,
      11.79,
      -50.377,
      39.228,
      9.34,
      -50.139,
      39.939,
      9.34
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9843",
    "polygon": [
      -49.381,
      42.202,
      9.34,
      -49.381,
      42.202,
      11.79,
      -49.892,
      40.675,
      11.79,
      -49.892,
      40.675,
      11.79,
      -49.892,
      40.675,
      9.34,
      -49.381,
      42.202,
      9.34
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9844",
    "polygon": [
      -49.143,
      42.913,
      9.34,
      -49.143,
      42.913,
      11.79,
      -49.381,
      42.202,
      11.79,
      -49.381,
      42.202,
      11.79,
      -49.381,
      42.202,
      9.34,
      -49.143,
      42.913,
      9.34
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9845",
    "polygon": [
      -48.945,
      43.506,
      9.34,
      -48.945,
      43.506,
      11.79,
      -49.143,
      42.913,
      11.79,
      -49.143,
      42.913,
      11.79,
      -49.143,
      42.913,
      9.34,
      -48.945,
      43.506,
      9.34
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9846",
    "polygon": [
      -48.434,
      45.032,
      9.34,
      -48.434,
      45.032,
      11.79,
      -48.945,
      43.506,
      11.79,
      -48.945,
      43.506,
      11.79,
      -48.945,
      43.506,
      9.34,
      -48.434,
      45.032,
      9.34
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9847",
    "polygon": [
      -48.195,
      45.744,
      9.34,
      -48.195,
      45.744,
      11.79,
      -48.434,
      45.032,
      11.79,
      -48.434,
      45.032,
      11.79,
      -48.434,
      45.032,
      9.34,
      -48.195,
      45.744,
      9.34
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9848",
    "polygon": [
      -47.438,
      48.006,
      9.34,
      -47.438,
      48.006,
      11.79,
      -47.949,
      46.479,
      11.79,
      -47.949,
      46.479,
      11.79,
      -47.949,
      46.479,
      9.34,
      -47.438,
      48.006,
      9.34
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9849",
    "polygon": [
      -47.2,
      48.717,
      9.34,
      -47.2,
      48.717,
      11.79,
      -47.438,
      48.006,
      11.79,
      -47.438,
      48.006,
      11.79,
      -47.438,
      48.006,
      9.34,
      -47.2,
      48.717,
      9.34
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9850",
    "polygon": [
      -47.002,
      49.31,
      9.34,
      -47.002,
      49.31,
      11.79,
      -47.2,
      48.717,
      11.79,
      -47.2,
      48.717,
      11.79,
      -47.2,
      48.717,
      9.34,
      -47.002,
      49.31,
      9.34
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9851",
    "polygon": [
      -46.49,
      50.837,
      9.34,
      -46.49,
      50.837,
      11.79,
      -47.002,
      49.31,
      11.79,
      -47.002,
      49.31,
      11.79,
      -47.002,
      49.31,
      9.34,
      -46.49,
      50.837,
      9.34
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_9852",
    "polygon": [
      -46.252,
      51.548,
      9.34,
      -46.252,
      51.548,
      11.79,
      -46.49,
      50.837,
      11.79,
      -46.49,
      50.837,
      11.79,
      -46.49,
      50.837,
      9.34,
      -46.252,
      51.548,
      9.34
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9853",
    "polygon": [
      -59.107,
      13.183,
      5.59,
      -59.107,
      13.183,
      8.04,
      -59.619,
      11.657,
      8.04,
      -59.619,
      11.657,
      8.04,
      -59.619,
      11.657,
      5.59,
      -59.107,
      13.183,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9854",
    "polygon": [
      -58.869,
      13.895,
      5.59,
      -58.869,
      13.895,
      8.04,
      -59.107,
      13.183,
      8.04,
      -59.107,
      13.183,
      8.04,
      -59.107,
      13.183,
      5.59,
      -58.869,
      13.895,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9855",
    "polygon": [
      -58.671,
      14.487,
      5.59,
      -58.671,
      14.487,
      8.04,
      -58.869,
      13.895,
      8.04,
      -58.869,
      13.895,
      8.04,
      -58.869,
      13.895,
      5.59,
      -58.671,
      14.487,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9856",
    "polygon": [
      -58.159,
      16.014,
      5.59,
      -58.159,
      16.014,
      8.04,
      -58.671,
      14.487,
      8.04,
      -58.671,
      14.487,
      8.04,
      -58.671,
      14.487,
      5.59,
      -58.159,
      16.014,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9857",
    "polygon": [
      -57.921,
      16.725,
      5.59,
      -57.921,
      16.725,
      8.04,
      -58.159,
      16.014,
      8.04,
      -58.159,
      16.014,
      8.04,
      -58.159,
      16.014,
      5.59,
      -57.921,
      16.725,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9858",
    "polygon": [
      -57.163,
      18.987,
      5.59,
      -57.163,
      18.987,
      8.04,
      -57.674,
      17.461,
      8.04,
      -57.674,
      17.461,
      8.04,
      -57.674,
      17.461,
      5.59,
      -57.163,
      18.987,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9859",
    "polygon": [
      -56.925,
      19.699,
      5.59,
      -56.925,
      19.699,
      8.04,
      -57.163,
      18.987,
      8.04,
      -57.163,
      18.987,
      8.04,
      -57.163,
      18.987,
      5.59,
      -56.925,
      19.699,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9860",
    "polygon": [
      -56.726,
      20.291,
      5.59,
      -56.726,
      20.291,
      8.04,
      -56.925,
      19.699,
      8.04,
      -56.925,
      19.699,
      8.04,
      -56.925,
      19.699,
      5.59,
      -56.726,
      20.291,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9861",
    "polygon": [
      -56.215,
      21.818,
      5.59,
      -56.215,
      21.818,
      8.04,
      -56.726,
      20.291,
      8.04,
      -56.726,
      20.291,
      8.04,
      -56.726,
      20.291,
      5.59,
      -56.215,
      21.818,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9862",
    "polygon": [
      -55.977,
      22.529,
      5.59,
      -55.977,
      22.529,
      8.04,
      -56.215,
      21.818,
      8.04,
      -56.215,
      21.818,
      8.04,
      -56.215,
      21.818,
      5.59,
      -55.977,
      22.529,
      5.59
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9863",
    "polygon": [
      -55.219,
      24.791,
      5.59,
      -55.219,
      24.791,
      8.04,
      -55.73,
      23.265,
      8.04,
      -55.73,
      23.265,
      8.04,
      -55.73,
      23.265,
      5.59,
      -55.219,
      24.791,
      5.59
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9864",
    "polygon": [
      -54.981,
      25.503,
      5.59,
      -54.981,
      25.503,
      8.04,
      -55.219,
      24.791,
      8.04,
      -55.219,
      24.791,
      8.04,
      -55.219,
      24.791,
      5.59,
      -54.981,
      25.503,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9865",
    "polygon": [
      -54.782,
      26.095,
      5.59,
      -54.782,
      26.095,
      8.04,
      -54.981,
      25.503,
      8.04,
      -54.981,
      25.503,
      8.04,
      -54.981,
      25.503,
      5.59,
      -54.782,
      26.095,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9866",
    "polygon": [
      -54.271,
      27.622,
      5.59,
      -54.271,
      27.622,
      8.04,
      -54.782,
      26.095,
      8.04,
      -54.782,
      26.095,
      8.04,
      -54.782,
      26.095,
      5.59,
      -54.271,
      27.622,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9867",
    "polygon": [
      -54.032,
      28.333,
      5.59,
      -54.032,
      28.333,
      8.04,
      -54.271,
      27.622,
      8.04,
      -54.271,
      27.622,
      8.04,
      -54.271,
      27.622,
      5.59,
      -54.032,
      28.333,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9868",
    "polygon": [
      -53.275,
      30.595,
      5.59,
      -53.275,
      30.595,
      8.04,
      -53.786,
      29.069,
      8.04,
      -53.786,
      29.069,
      8.04,
      -53.786,
      29.069,
      5.59,
      -53.275,
      30.595,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9869",
    "polygon": [
      -53.036,
      31.307,
      5.59,
      -53.036,
      31.307,
      8.04,
      -53.275,
      30.595,
      8.04,
      -53.275,
      30.595,
      8.04,
      -53.275,
      30.595,
      5.59,
      -53.036,
      31.307,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9870",
    "polygon": [
      -52.838,
      31.899,
      5.59,
      -52.838,
      31.899,
      8.04,
      -53.036,
      31.307,
      8.04,
      -53.036,
      31.307,
      8.04,
      -53.036,
      31.307,
      5.59,
      -52.838,
      31.899,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9871",
    "polygon": [
      -52.326,
      33.426,
      5.59,
      -52.326,
      33.426,
      8.04,
      -52.838,
      31.899,
      8.04,
      -52.838,
      31.899,
      8.04,
      -52.838,
      31.899,
      5.59,
      -52.326,
      33.426,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9872",
    "polygon": [
      -52.088,
      34.137,
      5.59,
      -52.088,
      34.137,
      8.04,
      -52.326,
      33.426,
      8.04,
      -52.326,
      33.426,
      8.04,
      -52.326,
      33.426,
      5.59,
      -52.088,
      34.137,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9873",
    "polygon": [
      -51.33,
      36.399,
      5.59,
      -51.33,
      36.399,
      8.04,
      -51.842,
      34.873,
      8.04,
      -51.842,
      34.873,
      8.04,
      -51.842,
      34.873,
      5.59,
      -51.33,
      36.399,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9874",
    "polygon": [
      -51.092,
      37.111,
      5.59,
      -51.092,
      37.111,
      8.04,
      -51.33,
      36.399,
      8.04,
      -51.33,
      36.399,
      8.04,
      -51.33,
      36.399,
      5.59,
      -51.092,
      37.111,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9875",
    "polygon": [
      -50.893,
      37.703,
      5.59,
      -50.893,
      37.703,
      8.04,
      -51.092,
      37.111,
      8.04,
      -51.092,
      37.111,
      8.04,
      -51.092,
      37.111,
      5.59,
      -50.893,
      37.703,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9876",
    "polygon": [
      -50.382,
      39.23,
      5.59,
      -50.382,
      39.23,
      8.04,
      -50.893,
      37.703,
      8.04,
      -50.893,
      37.703,
      8.04,
      -50.893,
      37.703,
      5.59,
      -50.382,
      39.23,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9877",
    "polygon": [
      -50.144,
      39.941,
      5.59,
      -50.144,
      39.941,
      8.04,
      -50.382,
      39.23,
      8.04,
      -50.382,
      39.23,
      8.04,
      -50.382,
      39.23,
      5.59,
      -50.144,
      39.941,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9878",
    "polygon": [
      -49.386,
      42.203,
      5.59,
      -49.386,
      42.203,
      8.04,
      -49.897,
      40.677,
      8.04,
      -49.897,
      40.677,
      8.04,
      -49.897,
      40.677,
      5.59,
      -49.386,
      42.203,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9879",
    "polygon": [
      -49.148,
      42.915,
      5.59,
      -49.148,
      42.915,
      8.04,
      -49.386,
      42.203,
      8.04,
      -49.386,
      42.203,
      8.04,
      -49.386,
      42.203,
      5.59,
      -49.148,
      42.915,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9880",
    "polygon": [
      -48.949,
      43.507,
      5.59,
      -48.949,
      43.507,
      8.04,
      -49.148,
      42.915,
      8.04,
      -49.148,
      42.915,
      8.04,
      -49.148,
      42.915,
      5.59,
      -48.949,
      43.507,
      5.59
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9881",
    "polygon": [
      -48.438,
      45.034,
      5.59,
      -48.438,
      45.034,
      8.04,
      -48.949,
      43.507,
      8.04,
      -48.949,
      43.507,
      8.04,
      -48.949,
      43.507,
      5.59,
      -48.438,
      45.034,
      5.59
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9882",
    "polygon": [
      -48.2,
      45.745,
      5.59,
      -48.2,
      45.745,
      8.04,
      -48.438,
      45.034,
      8.04,
      -48.438,
      45.034,
      8.04,
      -48.438,
      45.034,
      5.59,
      -48.2,
      45.745,
      5.59
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9883",
    "polygon": [
      -47.442,
      48.008,
      5.59,
      -47.442,
      48.008,
      8.04,
      -47.953,
      46.481,
      8.04,
      -47.953,
      46.481,
      8.04,
      -47.953,
      46.481,
      5.59,
      -47.442,
      48.008,
      5.59
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9884",
    "polygon": [
      -47.204,
      48.719,
      5.59,
      -47.204,
      48.719,
      8.04,
      -47.442,
      48.008,
      8.04,
      -47.442,
      48.008,
      8.04,
      -47.442,
      48.008,
      5.59,
      -47.204,
      48.719,
      5.59
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9885",
    "polygon": [
      -47.005,
      49.311,
      5.59,
      -47.005,
      49.311,
      8.04,
      -47.204,
      48.719,
      8.04,
      -47.204,
      48.719,
      8.04,
      -47.204,
      48.719,
      5.59,
      -47.005,
      49.311,
      5.59
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_9886",
    "polygon": [
      -46.494,
      50.838,
      5.59,
      -46.494,
      50.838,
      8.04,
      -47.005,
      49.311,
      8.04,
      -47.005,
      49.311,
      8.04,
      -47.005,
      49.311,
      5.59,
      -46.494,
      50.838,
      5.59
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9888",
    "polygon": [
      1.637,
      72.434,
      13.073,
      1.637,
      72.434,
      15.523,
      1.399,
      71.722,
      15.523,
      1.399,
      71.722,
      15.523,
      1.399,
      71.722,
      13.073,
      1.637,
      72.434,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9889",
    "polygon": [
      11.318,
      101.351,
      13.073,
      11.318,
      101.351,
      15.523,
      10.807,
      99.824,
      15.523,
      10.807,
      99.824,
      15.523,
      10.807,
      99.824,
      13.073,
      11.318,
      101.351,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9890",
    "polygon": [
      10.609,
      99.231,
      13.073,
      10.609,
      99.231,
      15.523,
      10.371,
      98.52,
      15.523,
      10.371,
      98.52,
      15.523,
      10.371,
      98.52,
      13.073,
      10.609,
      99.231,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9891",
    "polygon": [
      10.807,
      99.824,
      13.073,
      10.807,
      99.824,
      15.523,
      10.609,
      99.231,
      15.523,
      10.609,
      99.231,
      15.523,
      10.609,
      99.231,
      13.073,
      10.807,
      99.824,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9892",
    "polygon": [
      10.371,
      98.52,
      13.073,
      10.371,
      98.52,
      15.523,
      9.86,
      96.993,
      15.523,
      9.86,
      96.993,
      15.523,
      9.86,
      96.993,
      13.073,
      10.371,
      98.52,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9893",
    "polygon": [
      11.556,
      102.062,
      13.073,
      11.556,
      102.062,
      15.523,
      11.318,
      101.351,
      15.523,
      11.318,
      101.351,
      15.523,
      11.318,
      101.351,
      13.073,
      11.556,
      102.062,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9894",
    "polygon": [
      3.301,
      77.403,
      13.073,
      3.301,
      77.403,
      15.523,
      2.79,
      75.877,
      15.523,
      2.79,
      75.877,
      15.523,
      2.79,
      75.877,
      13.073,
      3.301,
      77.403,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9895",
    "polygon": [
      2.592,
      75.284,
      13.073,
      2.592,
      75.284,
      15.523,
      2.354,
      74.573,
      15.523,
      2.354,
      74.573,
      15.523,
      2.354,
      74.573,
      13.073,
      2.592,
      75.284,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9896",
    "polygon": [
      2.79,
      75.877,
      13.073,
      2.79,
      75.877,
      15.523,
      2.592,
      75.284,
      15.523,
      2.592,
      75.284,
      15.523,
      2.592,
      75.284,
      13.073,
      2.79,
      75.877,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9898",
    "polygon": [
      3.539,
      78.115,
      13.073,
      3.539,
      78.115,
      15.523,
      3.301,
      77.403,
      15.523,
      3.301,
      77.403,
      15.523,
      3.301,
      77.403,
      13.073,
      3.539,
      78.115,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9899",
    "polygon": [
      5.266,
      83.272,
      13.073,
      5.266,
      83.272,
      15.523,
      4.755,
      81.746,
      15.523,
      4.755,
      81.746,
      15.523,
      4.755,
      81.746,
      13.073,
      5.266,
      83.272,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9900",
    "polygon": [
      4.556,
      81.153,
      13.073,
      4.556,
      81.153,
      15.523,
      4.318,
      80.442,
      15.523,
      4.318,
      80.442,
      15.523,
      4.318,
      80.442,
      13.073,
      4.556,
      81.153,
      13.073
    ],
    "05-22 04:00": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9901",
    "polygon": [
      4.755,
      81.746,
      13.073,
      4.755,
      81.746,
      15.523,
      4.556,
      81.153,
      15.523,
      4.556,
      81.153,
      15.523,
      4.556,
      81.153,
      13.073,
      4.755,
      81.746,
      13.073
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9903",
    "polygon": [
      5.504,
      83.983,
      13.073,
      5.504,
      83.983,
      15.523,
      5.266,
      83.272,
      15.523,
      5.266,
      83.272,
      15.523,
      5.266,
      83.272,
      13.073,
      5.504,
      83.983,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9904",
    "polygon": [
      7.28,
      89.289,
      13.073,
      7.28,
      89.289,
      15.523,
      6.769,
      87.762,
      15.523,
      6.769,
      87.762,
      15.523,
      6.769,
      87.762,
      13.073,
      7.28,
      89.289,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9905",
    "polygon": [
      6.571,
      87.17,
      13.073,
      6.571,
      87.17,
      15.523,
      6.333,
      86.458,
      15.523,
      6.333,
      86.458,
      15.523,
      6.333,
      86.458,
      13.073,
      6.571,
      87.17,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9906",
    "polygon": [
      6.769,
      87.762,
      13.073,
      6.769,
      87.762,
      15.523,
      6.571,
      87.17,
      15.523,
      6.571,
      87.17,
      15.523,
      6.571,
      87.17,
      13.073,
      6.769,
      87.762,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9908",
    "polygon": [
      7.518,
      90.0,
      13.073,
      7.518,
      90.0,
      15.523,
      7.28,
      89.289,
      15.523,
      7.28,
      89.289,
      15.523,
      7.28,
      89.289,
      13.073,
      7.518,
      90.0,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9909",
    "polygon": [
      9.295,
      95.306,
      13.073,
      9.295,
      95.306,
      15.523,
      8.783,
      93.779,
      15.523,
      8.783,
      93.779,
      15.523,
      8.783,
      93.779,
      13.073,
      9.295,
      95.306,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9910",
    "polygon": [
      8.585,
      93.186,
      13.073,
      8.585,
      93.186,
      15.523,
      8.347,
      92.475,
      15.523,
      8.347,
      92.475,
      15.523,
      8.347,
      92.475,
      13.073,
      8.585,
      93.186,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9911",
    "polygon": [
      8.783,
      93.779,
      13.073,
      8.783,
      93.779,
      15.523,
      8.585,
      93.186,
      15.523,
      8.585,
      93.186,
      15.523,
      8.585,
      93.186,
      13.073,
      8.783,
      93.779,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9913",
    "polygon": [
      9.533,
      96.017,
      13.073,
      9.533,
      96.017,
      15.523,
      9.295,
      95.306,
      15.523,
      9.295,
      95.306,
      15.523,
      9.295,
      95.306,
      13.073,
      9.533,
      96.017,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9914",
    "polygon": [
      13.333,
      107.367,
      13.073,
      13.333,
      107.367,
      15.523,
      12.821,
      105.841,
      15.523,
      12.821,
      105.841,
      15.523,
      12.821,
      105.841,
      13.073,
      13.333,
      107.367,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9915",
    "polygon": [
      12.623,
      105.248,
      13.073,
      12.623,
      105.248,
      15.523,
      12.385,
      104.537,
      15.523,
      12.385,
      104.537,
      15.523,
      12.385,
      104.537,
      13.073,
      12.623,
      105.248,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9916",
    "polygon": [
      12.821,
      105.841,
      13.073,
      12.821,
      105.841,
      15.523,
      12.623,
      105.248,
      15.523,
      12.623,
      105.248,
      15.523,
      12.623,
      105.248,
      13.073,
      12.821,
      105.841,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_9918",
    "polygon": [
      13.571,
      108.079,
      13.073,
      13.571,
      108.079,
      15.523,
      13.333,
      107.367,
      15.523,
      13.333,
      107.367,
      15.523,
      13.333,
      107.367,
      13.073,
      13.571,
      108.079,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9919",
    "polygon": [
      1.399,
      71.722,
      9.323,
      1.399,
      71.722,
      11.773,
      0.888,
      70.196,
      11.773,
      0.888,
      70.196,
      11.773,
      0.888,
      70.196,
      9.323,
      1.399,
      71.722,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9920",
    "polygon": [
      0.69,
      69.603,
      9.323,
      0.69,
      69.603,
      11.773,
      0.452,
      68.892,
      11.773,
      0.452,
      68.892,
      11.773,
      0.452,
      68.892,
      9.323,
      0.69,
      69.603,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9921",
    "polygon": [
      0.888,
      70.196,
      9.323,
      0.888,
      70.196,
      11.773,
      0.69,
      69.603,
      11.773,
      0.69,
      69.603,
      11.773,
      0.69,
      69.603,
      9.323,
      0.888,
      70.196,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9922",
    "polygon": [
      0.452,
      68.892,
      9.323,
      0.452,
      68.892,
      11.773,
      0.077,
      67.773,
      11.773,
      0.077,
      67.773,
      11.773,
      0.077,
      67.773,
      9.323,
      0.452,
      68.892,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9923",
    "polygon": [
      1.637,
      72.433,
      9.323,
      1.637,
      72.433,
      11.773,
      1.399,
      71.722,
      11.773,
      1.399,
      71.722,
      11.773,
      1.399,
      71.722,
      9.323,
      1.637,
      72.433,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9924",
    "polygon": [
      11.318,
      101.351,
      9.323,
      11.318,
      101.351,
      11.773,
      10.807,
      99.824,
      11.773,
      10.807,
      99.824,
      11.773,
      10.807,
      99.824,
      9.323,
      11.318,
      101.351,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9925",
    "polygon": [
      10.609,
      99.231,
      9.323,
      10.609,
      99.231,
      11.773,
      10.371,
      98.52,
      11.773,
      10.371,
      98.52,
      11.773,
      10.371,
      98.52,
      9.323,
      10.609,
      99.231,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9926",
    "polygon": [
      10.807,
      99.824,
      9.323,
      10.807,
      99.824,
      11.773,
      10.609,
      99.231,
      11.773,
      10.609,
      99.231,
      11.773,
      10.609,
      99.231,
      9.323,
      10.807,
      99.824,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9928",
    "polygon": [
      11.556,
      102.062,
      9.323,
      11.556,
      102.062,
      11.773,
      11.318,
      101.351,
      11.773,
      11.318,
      101.351,
      11.773,
      11.318,
      101.351,
      9.323,
      11.556,
      102.062,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9929",
    "polygon": [
      3.301,
      77.403,
      9.323,
      3.301,
      77.403,
      11.773,
      2.79,
      75.877,
      11.773,
      2.79,
      75.877,
      11.773,
      2.79,
      75.877,
      9.323,
      3.301,
      77.403,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9930",
    "polygon": [
      2.592,
      75.284,
      9.323,
      2.592,
      75.284,
      11.773,
      2.354,
      74.573,
      11.773,
      2.354,
      74.573,
      11.773,
      2.354,
      74.573,
      9.323,
      2.592,
      75.284,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9931",
    "polygon": [
      2.79,
      75.877,
      9.323,
      2.79,
      75.877,
      11.773,
      2.592,
      75.284,
      11.773,
      2.592,
      75.284,
      11.773,
      2.592,
      75.284,
      9.323,
      2.79,
      75.877,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9933",
    "polygon": [
      3.539,
      78.115,
      9.323,
      3.539,
      78.115,
      11.773,
      3.301,
      77.403,
      11.773,
      3.301,
      77.403,
      11.773,
      3.301,
      77.403,
      9.323,
      3.539,
      78.115,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9934",
    "polygon": [
      5.266,
      83.272,
      9.323,
      5.266,
      83.272,
      11.773,
      4.755,
      81.746,
      11.773,
      4.755,
      81.746,
      11.773,
      4.755,
      81.746,
      9.323,
      5.266,
      83.272,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9935",
    "polygon": [
      4.556,
      81.153,
      9.323,
      4.556,
      81.153,
      11.773,
      4.318,
      80.442,
      11.773,
      4.318,
      80.442,
      11.773,
      4.318,
      80.442,
      9.323,
      4.556,
      81.153,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9936",
    "polygon": [
      4.755,
      81.746,
      9.323,
      4.755,
      81.746,
      11.773,
      4.556,
      81.153,
      11.773,
      4.556,
      81.153,
      11.773,
      4.556,
      81.153,
      9.323,
      4.755,
      81.746,
      9.323
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9938",
    "polygon": [
      5.504,
      83.983,
      9.323,
      5.504,
      83.983,
      11.773,
      5.266,
      83.272,
      11.773,
      5.266,
      83.272,
      11.773,
      5.266,
      83.272,
      9.323,
      5.504,
      83.983,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9939",
    "polygon": [
      7.28,
      89.289,
      9.323,
      7.28,
      89.289,
      11.773,
      6.769,
      87.762,
      11.773,
      6.769,
      87.762,
      11.773,
      6.769,
      87.762,
      9.323,
      7.28,
      89.289,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9940",
    "polygon": [
      6.571,
      87.17,
      9.323,
      6.571,
      87.17,
      11.773,
      6.333,
      86.458,
      11.773,
      6.333,
      86.458,
      11.773,
      6.333,
      86.458,
      9.323,
      6.571,
      87.17,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9941",
    "polygon": [
      6.769,
      87.762,
      9.323,
      6.769,
      87.762,
      11.773,
      6.571,
      87.17,
      11.773,
      6.571,
      87.17,
      11.773,
      6.571,
      87.17,
      9.323,
      6.769,
      87.762,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9943",
    "polygon": [
      7.518,
      90.0,
      9.323,
      7.518,
      90.0,
      11.773,
      7.28,
      89.289,
      11.773,
      7.28,
      89.289,
      11.773,
      7.28,
      89.289,
      9.323,
      7.518,
      90.0,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9944",
    "polygon": [
      9.295,
      95.306,
      9.323,
      9.295,
      95.306,
      11.773,
      8.783,
      93.779,
      11.773,
      8.783,
      93.779,
      11.773,
      8.783,
      93.779,
      9.323,
      9.295,
      95.306,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9945",
    "polygon": [
      8.585,
      93.186,
      9.323,
      8.585,
      93.186,
      11.773,
      8.347,
      92.475,
      11.773,
      8.347,
      92.475,
      11.773,
      8.347,
      92.475,
      9.323,
      8.585,
      93.186,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9946",
    "polygon": [
      8.783,
      93.779,
      9.323,
      8.783,
      93.779,
      11.773,
      8.585,
      93.186,
      11.773,
      8.585,
      93.186,
      11.773,
      8.585,
      93.186,
      9.323,
      8.783,
      93.779,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9948",
    "polygon": [
      9.533,
      96.017,
      9.323,
      9.533,
      96.017,
      11.773,
      9.295,
      95.306,
      11.773,
      9.295,
      95.306,
      11.773,
      9.295,
      95.306,
      9.323,
      9.533,
      96.017,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9949",
    "polygon": [
      13.333,
      107.367,
      9.323,
      13.333,
      107.367,
      11.773,
      12.821,
      105.841,
      11.773,
      12.821,
      105.841,
      11.773,
      12.821,
      105.841,
      9.323,
      13.333,
      107.367,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9950",
    "polygon": [
      12.623,
      105.248,
      9.323,
      12.623,
      105.248,
      11.773,
      12.385,
      104.537,
      11.773,
      12.385,
      104.537,
      11.773,
      12.385,
      104.537,
      9.323,
      12.623,
      105.248,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9951",
    "polygon": [
      12.821,
      105.841,
      9.323,
      12.821,
      105.841,
      11.773,
      12.623,
      105.248,
      11.773,
      12.623,
      105.248,
      11.773,
      12.623,
      105.248,
      9.323,
      12.821,
      105.841,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_9953",
    "polygon": [
      13.571,
      108.079,
      9.323,
      13.571,
      108.079,
      11.773,
      13.333,
      107.367,
      11.773,
      13.333,
      107.367,
      11.773,
      13.333,
      107.367,
      9.323,
      13.571,
      108.079,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9954",
    "polygon": [
      1.39,
      71.726,
      5.573,
      1.39,
      71.726,
      8.023,
      0.878,
      70.199,
      8.023,
      0.878,
      70.199,
      8.023,
      0.878,
      70.199,
      5.573,
      1.39,
      71.726,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9955",
    "polygon": [
      0.68,
      69.606,
      5.573,
      0.68,
      69.606,
      8.023,
      0.442,
      68.895,
      8.023,
      0.442,
      68.895,
      8.023,
      0.442,
      68.895,
      5.573,
      0.68,
      69.606,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9956",
    "polygon": [
      0.878,
      70.199,
      5.573,
      0.878,
      70.199,
      8.023,
      0.68,
      69.606,
      8.023,
      0.68,
      69.606,
      8.023,
      0.68,
      69.606,
      5.573,
      0.878,
      70.199,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9957",
    "polygon": [
      0.442,
      68.895,
      5.573,
      0.442,
      68.895,
      8.023,
      0.067,
      67.776,
      8.023,
      0.067,
      67.776,
      8.023,
      0.067,
      67.776,
      5.573,
      0.442,
      68.895,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9958",
    "polygon": [
      1.628,
      72.437,
      5.573,
      1.628,
      72.437,
      8.023,
      1.39,
      71.726,
      8.023,
      1.39,
      71.726,
      8.023,
      1.39,
      71.726,
      5.573,
      1.628,
      72.437,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9959",
    "polygon": [
      11.314,
      101.352,
      5.573,
      11.314,
      101.352,
      8.023,
      10.803,
      99.825,
      8.023,
      10.803,
      99.825,
      8.023,
      10.803,
      99.825,
      5.573,
      11.314,
      101.352,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9960",
    "polygon": [
      10.604,
      99.233,
      5.573,
      10.604,
      99.233,
      8.023,
      10.366,
      98.522,
      8.023,
      10.366,
      98.522,
      8.023,
      10.366,
      98.522,
      5.573,
      10.604,
      99.233,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9961",
    "polygon": [
      10.803,
      99.825,
      5.573,
      10.803,
      99.825,
      8.023,
      10.604,
      99.233,
      8.023,
      10.604,
      99.233,
      8.023,
      10.604,
      99.233,
      5.573,
      10.803,
      99.825,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9963",
    "polygon": [
      11.552,
      102.063,
      5.573,
      11.552,
      102.063,
      8.023,
      11.314,
      101.352,
      8.023,
      11.314,
      101.352,
      8.023,
      11.314,
      101.352,
      5.573,
      11.552,
      102.063,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9964",
    "polygon": [
      3.293,
      77.406,
      5.573,
      3.293,
      77.406,
      8.023,
      2.781,
      75.88,
      8.023,
      2.781,
      75.88,
      8.023,
      2.781,
      75.88,
      5.573,
      3.293,
      77.406,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9965",
    "polygon": [
      2.583,
      75.287,
      5.573,
      2.583,
      75.287,
      8.023,
      2.345,
      74.576,
      8.023,
      2.345,
      74.576,
      8.023,
      2.345,
      74.576,
      5.573,
      2.583,
      75.287,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9966",
    "polygon": [
      2.781,
      75.88,
      5.573,
      2.781,
      75.88,
      8.023,
      2.583,
      75.287,
      8.023,
      2.583,
      75.287,
      8.023,
      2.583,
      75.287,
      5.573,
      2.781,
      75.88,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9968",
    "polygon": [
      3.531,
      78.118,
      5.573,
      3.531,
      78.118,
      8.023,
      3.293,
      77.406,
      8.023,
      3.293,
      77.406,
      8.023,
      3.293,
      77.406,
      5.573,
      3.531,
      78.118,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9969",
    "polygon": [
      5.259,
      83.275,
      5.573,
      5.259,
      83.275,
      8.023,
      4.747,
      81.748,
      8.023,
      4.747,
      81.748,
      8.023,
      4.747,
      81.748,
      5.573,
      5.259,
      83.275,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9970",
    "polygon": [
      4.549,
      81.156,
      5.573,
      4.549,
      81.156,
      8.023,
      4.31,
      80.444,
      8.023,
      4.31,
      80.444,
      8.023,
      4.31,
      80.444,
      5.573,
      4.549,
      81.156,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9971",
    "polygon": [
      4.747,
      81.748,
      5.573,
      4.747,
      81.748,
      8.023,
      4.549,
      81.156,
      8.023,
      4.549,
      81.156,
      8.023,
      4.549,
      81.156,
      5.573,
      4.747,
      81.748,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9973",
    "polygon": [
      5.497,
      83.986,
      5.573,
      5.497,
      83.986,
      8.023,
      5.259,
      83.275,
      8.023,
      5.259,
      83.275,
      8.023,
      5.259,
      83.275,
      5.573,
      5.497,
      83.986,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9974",
    "polygon": [
      7.274,
      89.291,
      5.573,
      7.274,
      89.291,
      8.023,
      6.763,
      87.765,
      8.023,
      6.763,
      87.765,
      8.023,
      6.763,
      87.765,
      5.573,
      7.274,
      89.291,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9976",
    "polygon": [
      6.763,
      87.765,
      5.573,
      6.763,
      87.765,
      8.023,
      6.564,
      87.172,
      8.023,
      6.564,
      87.172,
      8.023,
      6.564,
      87.172,
      5.573,
      6.763,
      87.765,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9978",
    "polygon": [
      7.512,
      90.002,
      5.573,
      7.512,
      90.002,
      8.023,
      7.274,
      89.291,
      8.023,
      7.274,
      89.291,
      8.023,
      7.274,
      89.291,
      5.573,
      7.512,
      90.002,
      5.573
    ],
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9979",
    "polygon": [
      9.289,
      95.308,
      5.573,
      9.289,
      95.308,
      8.023,
      8.778,
      93.781,
      8.023,
      8.778,
      93.781,
      8.023,
      8.778,
      93.781,
      5.573,
      9.289,
      95.308,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9980",
    "polygon": [
      8.579,
      93.188,
      5.573,
      8.579,
      93.188,
      8.023,
      8.341,
      92.477,
      8.023,
      8.341,
      92.477,
      8.023,
      8.341,
      92.477,
      5.573,
      8.579,
      93.188,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9981",
    "polygon": [
      8.778,
      93.781,
      5.573,
      8.778,
      93.781,
      8.023,
      8.579,
      93.188,
      8.023,
      8.579,
      93.188,
      8.023,
      8.579,
      93.188,
      5.573,
      8.778,
      93.781,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9983",
    "polygon": [
      9.528,
      96.019,
      5.573,
      9.528,
      96.019,
      8.023,
      9.289,
      95.308,
      8.023,
      9.289,
      95.308,
      8.023,
      9.289,
      95.308,
      5.573,
      9.528,
      96.019,
      5.573
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9984",
    "polygon": [
      13.33,
      107.368,
      5.573,
      13.33,
      107.368,
      8.023,
      12.818,
      105.842,
      8.023,
      12.818,
      105.842,
      8.023,
      12.818,
      105.842,
      5.573,
      13.33,
      107.368,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9985",
    "polygon": [
      12.62,
      105.249,
      5.573,
      12.62,
      105.249,
      8.023,
      12.381,
      104.538,
      8.023,
      12.381,
      104.538,
      8.023,
      12.381,
      104.538,
      5.573,
      12.62,
      105.249,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9986",
    "polygon": [
      12.818,
      105.842,
      5.573,
      12.818,
      105.842,
      8.023,
      12.62,
      105.249,
      8.023,
      12.62,
      105.249,
      8.023,
      12.62,
      105.249,
      5.573,
      12.818,
      105.842,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9988",
    "polygon": [
      13.568,
      108.08,
      5.573,
      13.568,
      108.08,
      8.023,
      13.33,
      107.368,
      8.023,
      13.33,
      107.368,
      8.023,
      13.33,
      107.368,
      5.573,
      13.568,
      108.08,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_9989",
    "polygon": [
      1.364,
      71.734,
      0.745,
      1.364,
      71.734,
      3.455,
      0.852,
      70.208,
      3.455,
      0.852,
      70.208,
      3.455,
      0.852,
      70.208,
      0.745,
      1.364,
      71.734,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_9990",
    "polygon": [
      0.654,
      69.615,
      0.745,
      0.654,
      69.615,
      3.455,
      0.416,
      68.904,
      3.455,
      0.416,
      68.904,
      3.455,
      0.416,
      68.904,
      0.745,
      0.654,
      69.615,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_9991",
    "polygon": [
      0.852,
      70.208,
      0.745,
      0.852,
      70.208,
      3.455,
      0.654,
      69.615,
      3.455,
      0.654,
      69.615,
      3.455,
      0.654,
      69.615,
      0.745,
      0.852,
      70.208,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_9992",
    "polygon": [
      0.416,
      68.904,
      0.745,
      0.416,
      68.904,
      3.455,
      0.041,
      67.785,
      3.455,
      0.041,
      67.785,
      3.455,
      0.041,
      67.785,
      0.745,
      0.416,
      68.904,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_9993",
    "polygon": [
      1.602,
      72.445,
      0.745,
      1.602,
      72.445,
      3.455,
      1.364,
      71.734,
      3.455,
      1.364,
      71.734,
      3.455,
      1.364,
      71.734,
      0.745,
      1.602,
      72.445,
      0.745
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_9994",
    "polygon": [
      11.288,
      101.361,
      0.745,
      11.288,
      101.361,
      3.455,
      10.777,
      99.834,
      3.455,
      10.777,
      99.834,
      3.455,
      10.777,
      99.834,
      0.745,
      11.288,
      101.361,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_9995",
    "polygon": [
      10.578,
      99.241,
      0.745,
      10.578,
      99.241,
      3.455,
      10.34,
      98.53,
      3.455,
      10.34,
      98.53,
      3.455,
      10.34,
      98.53,
      0.745,
      10.578,
      99.241,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-A-Outer_EG_9996",
    "polygon": [
      10.777,
      99.834,
      0.745,
      10.777,
      99.834,
      3.455,
      10.578,
      99.241,
      3.455,
      10.578,
      99.241,
      3.455,
      10.578,
      99.241,
      0.745,
      10.777,
      99.834,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_9998",
    "polygon": [
      11.526,
      102.072,
      0.745,
      11.526,
      102.072,
      3.455,
      11.288,
      101.361,
      3.455,
      11.288,
      101.361,
      3.455,
      11.288,
      101.361,
      0.745,
      11.526,
      102.072,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6478",
    "polygon": [
      92.928,
      -20.912,
      0.708,
      92.928,
      -20.912,
      3.468,
      92.728,
      -21.51,
      3.468,
      92.728,
      -21.51,
      3.468,
      92.728,
      -21.51,
      0.708,
      92.928,
      -20.912,
      0.708
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6479",
    "polygon": [
      92.723,
      -21.522,
      0.702,
      92.723,
      -21.522,
      3.462,
      92.168,
      -23.181,
      3.462,
      92.168,
      -23.181,
      3.462,
      92.168,
      -23.181,
      0.702,
      92.723,
      -21.522,
      0.702
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6480",
    "polygon": [
      92.168,
      -23.181,
      0.694,
      92.168,
      -23.181,
      3.454,
      91.983,
      -23.731,
      3.454,
      91.983,
      -23.731,
      3.454,
      91.983,
      -23.731,
      0.694,
      92.168,
      -23.181,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6481",
    "polygon": [
      91.983,
      -23.731,
      0.694,
      91.983,
      -23.731,
      3.454,
      91.759,
      -24.4,
      3.454,
      91.759,
      -24.4,
      3.454,
      91.759,
      -24.4,
      0.694,
      91.983,
      -23.731,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6482",
    "polygon": [
      91.759,
      -24.4,
      0.697,
      91.759,
      -24.4,
      3.457,
      91.242,
      -25.945,
      3.457,
      91.242,
      -25.945,
      3.457,
      91.242,
      -25.945,
      0.697,
      91.759,
      -24.4,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_6483",
    "polygon": [
      90.936,
      -26.858,
      0.708,
      90.936,
      -26.858,
      3.468,
      90.736,
      -27.455,
      3.468,
      90.736,
      -27.455,
      3.468,
      90.736,
      -27.455,
      0.708,
      90.936,
      -26.858,
      0.708
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6484",
    "polygon": [
      90.732,
      -27.468,
      0.702,
      90.732,
      -27.468,
      3.462,
      90.176,
      -29.127,
      3.462,
      90.176,
      -29.127,
      3.462,
      90.176,
      -29.127,
      0.702,
      90.732,
      -27.468,
      0.702
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6485",
    "polygon": [
      90.176,
      -29.127,
      0.694,
      90.176,
      -29.127,
      3.454,
      89.992,
      -29.677,
      3.454,
      89.992,
      -29.677,
      3.454,
      89.992,
      -29.677,
      0.694,
      90.176,
      -29.127,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6486",
    "polygon": [
      89.992,
      -29.677,
      0.694,
      89.992,
      -29.677,
      3.454,
      89.768,
      -30.345,
      3.454,
      89.768,
      -30.345,
      3.454,
      89.768,
      -30.345,
      0.694,
      89.992,
      -29.677,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6487",
    "polygon": [
      89.768,
      -30.345,
      0.697,
      89.768,
      -30.345,
      3.457,
      89.25,
      -31.891,
      3.457,
      89.25,
      -31.891,
      3.457,
      89.25,
      -31.891,
      0.697,
      89.768,
      -30.345,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_6488",
    "polygon": [
      88.968,
      -32.733,
      0.708,
      88.968,
      -32.733,
      3.468,
      88.73,
      -33.444,
      3.468,
      88.73,
      -33.444,
      3.468,
      88.73,
      -33.444,
      0.708,
      88.968,
      -32.733,
      0.708
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6489",
    "polygon": [
      88.73,
      -33.444,
      0.702,
      88.73,
      -33.444,
      3.462,
      88.218,
      -34.971,
      3.462,
      88.218,
      -34.971,
      3.462,
      88.218,
      -34.971,
      0.702,
      88.73,
      -33.444,
      0.702
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6490",
    "polygon": [
      88.218,
      -34.971,
      0.697,
      88.218,
      -34.971,
      3.457,
      88.02,
      -35.563,
      3.457,
      88.02,
      -35.563,
      3.457,
      88.02,
      -35.563,
      0.697,
      88.218,
      -34.971,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6491",
    "polygon": [
      88.02,
      -35.563,
      0.697,
      88.02,
      -35.563,
      3.457,
      87.782,
      -36.274,
      3.457,
      87.782,
      -36.274,
      3.457,
      87.782,
      -36.274,
      0.697,
      88.02,
      -35.563,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6492",
    "polygon": [
      87.782,
      -36.274,
      0.697,
      87.782,
      -36.274,
      3.457,
      87.27,
      -37.801,
      3.457,
      87.27,
      -37.801,
      3.457,
      87.27,
      -37.801,
      0.697,
      87.782,
      -36.274,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6528",
    "polygon": [
      72.873,
      41.216,
      0.745,
      72.873,
      41.216,
      3.455,
      72.635,
      40.505,
      3.455,
      72.635,
      40.505,
      3.455,
      72.635,
      40.505,
      0.745,
      72.873,
      41.216,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6529",
    "polygon": [
      72.635,
      40.505,
      0.745,
      72.635,
      40.505,
      3.455,
      72.124,
      38.978,
      3.455,
      72.124,
      38.978,
      3.455,
      72.124,
      38.978,
      0.745,
      72.635,
      40.505,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6530",
    "polygon": [
      72.124,
      38.978,
      0.745,
      72.124,
      38.978,
      3.455,
      71.927,
      38.39,
      3.455,
      71.927,
      38.39,
      3.455,
      71.927,
      38.39,
      0.745,
      72.124,
      38.978,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6531",
    "polygon": [
      71.927,
      38.39,
      0.745,
      71.927,
      38.39,
      3.455,
      71.689,
      37.679,
      3.455,
      71.689,
      37.679,
      3.455,
      71.689,
      37.679,
      0.745,
      71.927,
      38.39,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6532",
    "polygon": [
      71.689,
      37.679,
      0.745,
      71.689,
      37.679,
      3.455,
      71.177,
      36.153,
      3.455,
      71.177,
      36.153,
      3.455,
      71.177,
      36.153,
      0.745,
      71.689,
      37.679,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6568",
    "polygon": [
      72.876,
      41.221,
      5.573,
      72.876,
      41.221,
      8.023,
      72.638,
      40.51,
      8.023,
      72.638,
      40.51,
      8.023,
      72.638,
      40.51,
      5.573,
      72.876,
      41.221,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6569",
    "polygon": [
      72.638,
      40.51,
      5.573,
      72.638,
      40.51,
      8.023,
      72.126,
      38.983,
      8.023,
      72.126,
      38.983,
      8.023,
      72.126,
      38.983,
      5.573,
      72.638,
      40.51,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6570",
    "polygon": [
      72.126,
      38.983,
      5.573,
      72.126,
      38.983,
      8.023,
      71.928,
      38.39,
      8.023,
      71.928,
      38.39,
      8.023,
      71.928,
      38.39,
      5.573,
      72.126,
      38.983,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6571",
    "polygon": [
      71.928,
      38.39,
      5.573,
      71.928,
      38.39,
      8.023,
      71.689,
      37.679,
      8.023,
      71.689,
      37.679,
      8.023,
      71.689,
      37.679,
      5.573,
      71.928,
      38.39,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6572",
    "polygon": [
      71.689,
      37.679,
      5.573,
      71.689,
      37.679,
      8.023,
      71.178,
      36.152,
      8.023,
      71.178,
      36.152,
      8.023,
      71.178,
      36.152,
      5.573,
      71.689,
      37.679,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6688",
    "polygon": [
      74.873,
      47.184,
      5.573,
      74.873,
      47.184,
      8.023,
      74.635,
      46.473,
      8.023,
      74.635,
      46.473,
      8.023,
      74.635,
      46.473,
      5.573,
      74.873,
      47.184,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6689",
    "polygon": [
      74.635,
      46.473,
      5.573,
      74.635,
      46.473,
      8.023,
      74.124,
      44.946,
      8.023,
      74.124,
      44.946,
      8.023,
      74.124,
      44.946,
      5.573,
      74.635,
      46.473,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6690",
    "polygon": [
      74.124,
      44.946,
      5.573,
      74.124,
      44.946,
      8.023,
      73.925,
      44.354,
      8.023,
      73.925,
      44.354,
      8.023,
      73.925,
      44.354,
      5.573,
      74.124,
      44.946,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6691",
    "polygon": [
      73.925,
      44.354,
      5.573,
      73.925,
      44.354,
      8.023,
      73.687,
      43.643,
      8.023,
      73.687,
      43.643,
      8.023,
      73.687,
      43.643,
      5.573,
      73.925,
      44.354,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6692",
    "polygon": [
      73.687,
      43.643,
      5.573,
      73.687,
      43.643,
      8.023,
      73.176,
      42.116,
      8.023,
      73.176,
      42.116,
      8.023,
      73.176,
      42.116,
      5.573,
      73.687,
      43.643,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6693",
    "polygon": [
      76.879,
      53.17,
      5.573,
      76.879,
      53.17,
      8.023,
      76.64,
      52.459,
      8.023,
      76.64,
      52.459,
      8.023,
      76.64,
      52.459,
      5.573,
      76.879,
      53.17,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6694",
    "polygon": [
      76.64,
      52.459,
      5.573,
      76.64,
      52.459,
      8.023,
      76.129,
      50.932,
      8.023,
      76.129,
      50.932,
      8.023,
      76.129,
      50.932,
      5.573,
      76.64,
      52.459,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6695",
    "polygon": [
      76.129,
      50.932,
      5.573,
      76.129,
      50.932,
      8.023,
      75.931,
      50.34,
      8.023,
      75.931,
      50.34,
      8.023,
      75.931,
      50.34,
      5.573,
      76.129,
      50.932,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6696",
    "polygon": [
      75.931,
      50.34,
      5.573,
      75.931,
      50.34,
      8.023,
      75.692,
      49.629,
      8.023,
      75.692,
      49.629,
      8.023,
      75.692,
      49.629,
      5.573,
      75.931,
      50.34,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6697",
    "polygon": [
      75.692,
      49.629,
      5.573,
      75.692,
      49.629,
      8.023,
      75.181,
      48.102,
      8.023,
      75.181,
      48.102,
      8.023,
      75.181,
      48.102,
      5.573,
      75.692,
      49.629,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6698",
    "polygon": [
      78.884,
      59.156,
      5.573,
      78.884,
      59.156,
      8.023,
      78.646,
      58.445,
      8.023,
      78.646,
      58.445,
      8.023,
      78.646,
      58.445,
      5.573,
      78.884,
      59.156,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6699",
    "polygon": [
      78.646,
      58.445,
      5.573,
      78.646,
      58.445,
      8.023,
      78.134,
      56.919,
      8.023,
      78.134,
      56.919,
      8.023,
      78.134,
      56.919,
      5.573,
      78.646,
      58.445,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6700",
    "polygon": [
      78.134,
      56.919,
      5.573,
      78.134,
      56.919,
      8.023,
      77.936,
      56.326,
      8.023,
      77.936,
      56.326,
      8.023,
      77.936,
      56.326,
      5.573,
      78.134,
      56.919,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6701",
    "polygon": [
      77.936,
      56.326,
      5.573,
      77.936,
      56.326,
      8.023,
      77.698,
      55.615,
      8.023,
      77.698,
      55.615,
      8.023,
      77.698,
      55.615,
      5.573,
      77.936,
      56.326,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6702",
    "polygon": [
      77.698,
      55.615,
      5.573,
      77.698,
      55.615,
      8.023,
      77.186,
      54.088,
      8.023,
      77.186,
      54.088,
      8.023,
      77.186,
      54.088,
      5.573,
      77.698,
      55.615,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6703",
    "polygon": [
      80.889,
      65.142,
      5.573,
      80.889,
      65.142,
      8.023,
      80.651,
      64.431,
      8.023,
      80.651,
      64.431,
      8.023,
      80.651,
      64.431,
      5.573,
      80.889,
      65.142,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6704",
    "polygon": [
      80.651,
      64.431,
      5.573,
      80.651,
      64.431,
      8.023,
      80.14,
      62.905,
      8.023,
      80.14,
      62.905,
      8.023,
      80.14,
      62.905,
      5.573,
      80.651,
      64.431,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6705",
    "polygon": [
      80.14,
      62.905,
      5.573,
      80.14,
      62.905,
      8.023,
      79.941,
      62.312,
      8.023,
      79.941,
      62.312,
      8.023,
      79.941,
      62.312,
      5.573,
      80.14,
      62.905,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6706",
    "polygon": [
      79.941,
      62.312,
      5.573,
      79.941,
      62.312,
      8.023,
      79.703,
      61.601,
      8.023,
      79.703,
      61.601,
      8.023,
      79.703,
      61.601,
      5.573,
      79.941,
      62.312,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6707",
    "polygon": [
      79.703,
      61.601,
      5.573,
      79.703,
      61.601,
      8.023,
      79.191,
      60.074,
      8.023,
      79.191,
      60.074,
      8.023,
      79.191,
      60.074,
      5.573,
      79.703,
      61.601,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6708",
    "polygon": [
      82.894,
      71.128,
      5.573,
      82.894,
      71.128,
      8.023,
      82.656,
      70.417,
      8.023,
      82.656,
      70.417,
      8.023,
      82.656,
      70.417,
      5.573,
      82.894,
      71.128,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6709",
    "polygon": [
      82.656,
      70.417,
      5.573,
      82.656,
      70.417,
      8.023,
      82.145,
      68.891,
      8.023,
      82.145,
      68.891,
      8.023,
      82.145,
      68.891,
      5.573,
      82.656,
      70.417,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6710",
    "polygon": [
      82.145,
      68.891,
      5.573,
      82.145,
      68.891,
      8.023,
      81.946,
      68.298,
      8.023,
      81.946,
      68.298,
      8.023,
      81.946,
      68.298,
      5.573,
      82.145,
      68.891,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6711",
    "polygon": [
      81.946,
      68.298,
      5.573,
      81.946,
      68.298,
      8.023,
      81.708,
      67.587,
      8.023,
      81.708,
      67.587,
      8.023,
      81.708,
      67.587,
      5.573,
      81.946,
      68.298,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6712",
    "polygon": [
      81.708,
      67.587,
      5.573,
      81.708,
      67.587,
      8.023,
      81.197,
      66.06,
      8.023,
      81.197,
      66.06,
      8.023,
      81.197,
      66.06,
      5.573,
      81.708,
      67.587,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6713",
    "polygon": [
      84.9,
      77.114,
      5.573,
      84.9,
      77.114,
      8.023,
      84.661,
      76.403,
      8.023,
      84.661,
      76.403,
      8.023,
      84.661,
      76.403,
      5.573,
      84.9,
      77.114,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6714",
    "polygon": [
      84.661,
      76.403,
      5.573,
      84.661,
      76.403,
      8.023,
      84.15,
      74.877,
      8.023,
      84.15,
      74.877,
      8.023,
      84.15,
      74.877,
      5.573,
      84.661,
      76.403,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6715",
    "polygon": [
      84.15,
      74.877,
      5.573,
      84.15,
      74.877,
      8.023,
      83.951,
      74.284,
      8.023,
      83.951,
      74.284,
      8.023,
      83.951,
      74.284,
      5.573,
      84.15,
      74.877,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6716",
    "polygon": [
      83.951,
      74.284,
      5.573,
      83.951,
      74.284,
      8.023,
      83.713,
      73.573,
      8.023,
      83.713,
      73.573,
      8.023,
      83.713,
      73.573,
      5.573,
      83.951,
      74.284,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6717",
    "polygon": [
      83.713,
      73.573,
      5.573,
      83.713,
      73.573,
      8.023,
      83.202,
      72.046,
      8.023,
      83.202,
      72.046,
      8.023,
      83.202,
      72.046,
      5.573,
      83.713,
      73.573,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6718",
    "polygon": [
      86.905,
      83.1,
      5.573,
      86.905,
      83.1,
      8.023,
      86.667,
      82.389,
      8.023,
      86.667,
      82.389,
      8.023,
      86.667,
      82.389,
      5.573,
      86.905,
      83.1,
      5.573
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6719",
    "polygon": [
      86.667,
      82.389,
      5.573,
      86.667,
      82.389,
      8.023,
      86.155,
      80.863,
      8.023,
      86.155,
      80.863,
      8.023,
      86.155,
      80.863,
      5.573,
      86.667,
      82.389,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6720",
    "polygon": [
      86.155,
      80.863,
      5.573,
      86.155,
      80.863,
      8.023,
      85.957,
      80.27,
      8.023,
      85.957,
      80.27,
      8.023,
      85.957,
      80.27,
      5.573,
      86.155,
      80.863,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6721",
    "polygon": [
      85.957,
      80.27,
      5.573,
      85.957,
      80.27,
      8.023,
      85.718,
      79.559,
      8.023,
      85.718,
      79.559,
      8.023,
      85.718,
      79.559,
      5.573,
      85.957,
      80.27,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6722",
    "polygon": [
      85.718,
      79.559,
      5.573,
      85.718,
      79.559,
      8.023,
      85.207,
      78.032,
      8.023,
      85.207,
      78.032,
      8.023,
      85.207,
      78.032,
      5.573,
      85.718,
      79.559,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6723",
    "polygon": [
      74.871,
      47.18,
      0.745,
      74.871,
      47.18,
      3.455,
      74.633,
      46.469,
      3.455,
      74.633,
      46.469,
      3.455,
      74.633,
      46.469,
      0.745,
      74.871,
      47.18,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6724",
    "polygon": [
      74.633,
      46.469,
      0.745,
      74.633,
      46.469,
      3.455,
      74.122,
      44.942,
      3.455,
      74.122,
      44.942,
      3.455,
      74.122,
      44.942,
      0.745,
      74.633,
      46.469,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6725",
    "polygon": [
      74.122,
      44.942,
      0.745,
      74.122,
      44.942,
      3.455,
      73.925,
      44.354,
      3.455,
      73.925,
      44.354,
      3.455,
      73.925,
      44.354,
      0.745,
      74.122,
      44.942,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6726",
    "polygon": [
      73.925,
      44.354,
      0.745,
      73.925,
      44.354,
      3.455,
      73.686,
      43.643,
      3.455,
      73.686,
      43.643,
      3.455,
      73.686,
      43.643,
      0.745,
      73.925,
      44.354,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6727",
    "polygon": [
      73.686,
      43.643,
      0.745,
      73.686,
      43.643,
      3.455,
      73.175,
      42.116,
      3.455,
      73.175,
      42.116,
      3.455,
      73.175,
      42.116,
      0.745,
      73.686,
      43.643,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6728",
    "polygon": [
      76.869,
      53.143,
      0.745,
      76.869,
      53.143,
      3.455,
      76.631,
      52.432,
      3.455,
      76.631,
      52.432,
      3.455,
      76.631,
      52.432,
      0.745,
      76.869,
      53.143,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6729",
    "polygon": [
      76.631,
      52.432,
      0.745,
      76.631,
      52.432,
      3.455,
      76.119,
      50.906,
      3.455,
      76.119,
      50.906,
      3.455,
      76.119,
      50.906,
      0.745,
      76.631,
      52.432,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6730",
    "polygon": [
      76.119,
      50.906,
      0.745,
      76.119,
      50.906,
      3.455,
      75.922,
      50.318,
      3.455,
      75.922,
      50.318,
      3.455,
      75.922,
      50.318,
      0.745,
      76.119,
      50.906,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6731",
    "polygon": [
      75.922,
      50.318,
      0.745,
      75.922,
      50.318,
      3.455,
      75.684,
      49.607,
      3.455,
      75.684,
      49.607,
      3.455,
      75.684,
      49.607,
      0.745,
      75.922,
      50.318,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6732",
    "polygon": [
      75.684,
      49.607,
      0.745,
      75.684,
      49.607,
      3.455,
      75.173,
      48.08,
      3.455,
      75.173,
      48.08,
      3.455,
      75.173,
      48.08,
      0.745,
      75.684,
      49.607,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6733",
    "polygon": [
      78.867,
      59.107,
      0.745,
      78.867,
      59.107,
      3.455,
      78.628,
      58.396,
      3.455,
      78.628,
      58.396,
      3.455,
      78.628,
      58.396,
      0.745,
      78.867,
      59.107,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6734",
    "polygon": [
      78.628,
      58.396,
      0.745,
      78.628,
      58.396,
      3.455,
      78.117,
      56.869,
      3.455,
      78.117,
      56.869,
      3.455,
      78.117,
      56.869,
      0.745,
      78.628,
      58.396,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6735",
    "polygon": [
      78.117,
      56.869,
      0.745,
      78.117,
      56.869,
      3.455,
      77.92,
      56.281,
      3.455,
      77.92,
      56.281,
      3.455,
      77.92,
      56.281,
      0.745,
      78.117,
      56.869,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6736",
    "polygon": [
      77.92,
      56.281,
      0.745,
      77.92,
      56.281,
      3.455,
      77.682,
      55.57,
      3.455,
      77.682,
      55.57,
      3.455,
      77.682,
      55.57,
      0.745,
      77.92,
      56.281,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6737",
    "polygon": [
      77.682,
      55.57,
      0.745,
      77.682,
      55.57,
      3.455,
      77.17,
      54.043,
      3.455,
      77.17,
      54.043,
      3.455,
      77.17,
      54.043,
      0.745,
      77.682,
      55.57,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6738",
    "polygon": [
      80.864,
      65.071,
      0.745,
      80.864,
      65.071,
      3.455,
      80.626,
      64.359,
      3.455,
      80.626,
      64.359,
      3.455,
      80.626,
      64.359,
      0.745,
      80.864,
      65.071,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6739",
    "polygon": [
      80.626,
      64.359,
      0.745,
      80.626,
      64.359,
      3.455,
      80.115,
      62.833,
      3.455,
      80.115,
      62.833,
      3.455,
      80.115,
      62.833,
      0.745,
      80.626,
      64.359,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6740",
    "polygon": [
      80.115,
      62.833,
      0.745,
      80.115,
      62.833,
      3.455,
      79.918,
      62.245,
      3.455,
      79.918,
      62.245,
      3.455,
      79.918,
      62.245,
      0.745,
      80.115,
      62.833,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6741",
    "polygon": [
      79.918,
      62.245,
      0.745,
      79.918,
      62.245,
      3.455,
      79.68,
      61.534,
      3.455,
      79.68,
      61.534,
      3.455,
      79.68,
      61.534,
      0.745,
      79.918,
      62.245,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6742",
    "polygon": [
      79.68,
      61.534,
      0.745,
      79.68,
      61.534,
      3.455,
      79.168,
      60.007,
      3.455,
      79.168,
      60.007,
      3.455,
      79.168,
      60.007,
      0.745,
      79.68,
      61.534,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6743",
    "polygon": [
      82.862,
      71.034,
      0.745,
      82.862,
      71.034,
      3.455,
      82.624,
      70.323,
      3.455,
      82.624,
      70.323,
      3.455,
      82.624,
      70.323,
      0.745,
      82.862,
      71.034,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6744",
    "polygon": [
      82.624,
      70.323,
      0.745,
      82.624,
      70.323,
      3.455,
      82.112,
      68.796,
      3.455,
      82.112,
      68.796,
      3.455,
      82.112,
      68.796,
      0.745,
      82.624,
      70.323,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6745",
    "polygon": [
      82.112,
      68.796,
      0.745,
      82.112,
      68.796,
      3.455,
      81.915,
      68.208,
      3.455,
      81.915,
      68.208,
      3.455,
      81.915,
      68.208,
      0.745,
      82.112,
      68.796,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6746",
    "polygon": [
      81.915,
      68.208,
      0.745,
      81.915,
      68.208,
      3.455,
      81.677,
      67.497,
      3.455,
      81.677,
      67.497,
      3.455,
      81.677,
      67.497,
      0.745,
      81.915,
      68.208,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6747",
    "polygon": [
      81.677,
      67.497,
      0.745,
      81.677,
      67.497,
      3.455,
      81.166,
      65.971,
      3.455,
      81.166,
      65.971,
      3.455,
      81.166,
      65.971,
      0.745,
      81.677,
      67.497,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6748",
    "polygon": [
      84.86,
      76.998,
      0.745,
      84.86,
      76.998,
      3.455,
      84.622,
      76.287,
      3.455,
      84.622,
      76.287,
      3.455,
      84.622,
      76.287,
      0.745,
      84.86,
      76.998,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6749",
    "polygon": [
      84.622,
      76.287,
      0.745,
      84.622,
      76.287,
      3.455,
      84.11,
      74.76,
      3.455,
      84.11,
      74.76,
      3.455,
      84.11,
      74.76,
      0.745,
      84.622,
      76.287,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6750",
    "polygon": [
      84.11,
      74.76,
      0.745,
      84.11,
      74.76,
      3.455,
      83.913,
      74.172,
      3.455,
      83.913,
      74.172,
      3.455,
      83.913,
      74.172,
      0.745,
      84.11,
      74.76,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6751",
    "polygon": [
      83.913,
      74.172,
      0.745,
      83.913,
      74.172,
      3.455,
      83.675,
      73.461,
      3.455,
      83.675,
      73.461,
      3.455,
      83.675,
      73.461,
      0.745,
      83.913,
      74.172,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6752",
    "polygon": [
      83.675,
      73.461,
      0.745,
      83.675,
      73.461,
      3.455,
      83.164,
      71.934,
      3.455,
      83.164,
      71.934,
      3.455,
      83.164,
      71.934,
      0.745,
      83.675,
      73.461,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6753",
    "polygon": [
      86.857,
      82.961,
      0.745,
      86.857,
      82.961,
      3.455,
      86.619,
      82.25,
      3.455,
      86.619,
      82.25,
      3.455,
      86.619,
      82.25,
      0.745,
      86.857,
      82.961,
      0.745
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6754",
    "polygon": [
      86.619,
      82.25,
      0.745,
      86.619,
      82.25,
      3.455,
      86.108,
      80.724,
      3.455,
      86.108,
      80.724,
      3.455,
      86.108,
      80.724,
      0.745,
      86.619,
      82.25,
      0.745
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6755",
    "polygon": [
      86.108,
      80.724,
      0.745,
      86.108,
      80.724,
      3.455,
      85.911,
      80.136,
      3.455,
      85.911,
      80.136,
      3.455,
      85.911,
      80.136,
      0.745,
      86.108,
      80.724,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_EG_6756",
    "polygon": [
      85.911,
      80.136,
      0.745,
      85.911,
      80.136,
      3.455,
      85.673,
      79.424,
      3.455,
      85.673,
      79.424,
      3.455,
      85.673,
      79.424,
      0.745,
      85.911,
      80.136,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "Block-B-Outer_EG_6757",
    "polygon": [
      85.673,
      79.424,
      0.745,
      85.673,
      79.424,
      3.455,
      85.161,
      77.898,
      3.455,
      85.161,
      77.898,
      3.455,
      85.161,
      77.898,
      0.745,
      85.673,
      79.424,
      0.745
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6758",
    "polygon": [
      72.878,
      41.22,
      9.323,
      72.878,
      41.22,
      11.773,
      72.64,
      40.509,
      11.773,
      72.64,
      40.509,
      11.773,
      72.64,
      40.509,
      9.323,
      72.878,
      41.22,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6759",
    "polygon": [
      72.64,
      40.509,
      9.323,
      72.64,
      40.509,
      11.773,
      72.129,
      38.982,
      11.773,
      72.129,
      38.982,
      11.773,
      72.129,
      38.982,
      9.323,
      72.64,
      40.509,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6760",
    "polygon": [
      72.129,
      38.982,
      9.323,
      72.129,
      38.982,
      11.773,
      71.93,
      38.389,
      11.773,
      71.93,
      38.389,
      11.773,
      71.93,
      38.389,
      9.323,
      72.129,
      38.982,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6761",
    "polygon": [
      71.93,
      38.389,
      9.323,
      71.93,
      38.389,
      11.773,
      71.692,
      37.678,
      11.773,
      71.692,
      37.678,
      11.773,
      71.692,
      37.678,
      9.323,
      71.93,
      38.389,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6762",
    "polygon": [
      71.692,
      37.678,
      9.323,
      71.692,
      37.678,
      11.773,
      71.181,
      36.152,
      11.773,
      71.181,
      36.152,
      11.773,
      71.181,
      36.152,
      9.323,
      71.692,
      37.678,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6763",
    "polygon": [
      74.876,
      47.183,
      9.323,
      74.876,
      47.183,
      11.773,
      74.638,
      46.472,
      11.773,
      74.638,
      46.472,
      11.773,
      74.638,
      46.472,
      9.323,
      74.876,
      47.183,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6764",
    "polygon": [
      74.638,
      46.472,
      9.323,
      74.638,
      46.472,
      11.773,
      74.126,
      44.946,
      11.773,
      74.126,
      44.946,
      11.773,
      74.126,
      44.946,
      9.323,
      74.638,
      46.472,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6765",
    "polygon": [
      74.126,
      44.946,
      9.323,
      74.126,
      44.946,
      11.773,
      73.928,
      44.353,
      11.773,
      73.928,
      44.353,
      11.773,
      73.928,
      44.353,
      9.323,
      74.126,
      44.946,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6766",
    "polygon": [
      73.928,
      44.353,
      9.323,
      73.928,
      44.353,
      11.773,
      73.69,
      43.642,
      11.773,
      73.69,
      43.642,
      11.773,
      73.69,
      43.642,
      9.323,
      73.928,
      44.353,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6767",
    "polygon": [
      73.69,
      43.642,
      9.323,
      73.69,
      43.642,
      11.773,
      73.178,
      42.115,
      11.773,
      73.178,
      42.115,
      11.773,
      73.178,
      42.115,
      9.323,
      73.69,
      43.642,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6768",
    "polygon": [
      76.881,
      53.169,
      9.323,
      76.881,
      53.169,
      11.773,
      76.643,
      52.458,
      11.773,
      76.643,
      52.458,
      11.773,
      76.643,
      52.458,
      9.323,
      76.881,
      53.169,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6769",
    "polygon": [
      76.643,
      52.458,
      9.323,
      76.643,
      52.458,
      11.773,
      76.132,
      50.932,
      11.773,
      76.132,
      50.932,
      11.773,
      76.132,
      50.932,
      9.323,
      76.643,
      52.458,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6770",
    "polygon": [
      76.132,
      50.932,
      9.323,
      76.132,
      50.932,
      11.773,
      75.933,
      50.339,
      11.773,
      75.933,
      50.339,
      11.773,
      75.933,
      50.339,
      9.323,
      76.132,
      50.932,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6771",
    "polygon": [
      75.933,
      50.339,
      9.323,
      75.933,
      50.339,
      11.773,
      75.695,
      49.628,
      11.773,
      75.695,
      49.628,
      11.773,
      75.695,
      49.628,
      9.323,
      75.933,
      50.339,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6772",
    "polygon": [
      75.695,
      49.628,
      9.323,
      75.695,
      49.628,
      11.773,
      75.184,
      48.101,
      11.773,
      75.184,
      48.101,
      11.773,
      75.184,
      48.101,
      9.323,
      75.695,
      49.628,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6773",
    "polygon": [
      78.887,
      59.155,
      9.323,
      78.887,
      59.155,
      11.773,
      78.648,
      58.444,
      11.773,
      78.648,
      58.444,
      11.773,
      78.648,
      58.444,
      9.323,
      78.887,
      59.155,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6774",
    "polygon": [
      78.648,
      58.444,
      9.323,
      78.648,
      58.444,
      11.773,
      78.137,
      56.918,
      11.773,
      78.137,
      56.918,
      11.773,
      78.137,
      56.918,
      9.323,
      78.648,
      58.444,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6775",
    "polygon": [
      78.137,
      56.918,
      9.323,
      78.137,
      56.918,
      11.773,
      77.938,
      56.325,
      11.773,
      77.938,
      56.325,
      11.773,
      77.938,
      56.325,
      9.323,
      78.137,
      56.918,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6776",
    "polygon": [
      77.938,
      56.325,
      9.323,
      77.938,
      56.325,
      11.773,
      77.7,
      55.614,
      11.773,
      77.7,
      55.614,
      11.773,
      77.7,
      55.614,
      9.323,
      77.938,
      56.325,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6777",
    "polygon": [
      77.7,
      55.614,
      9.323,
      77.7,
      55.614,
      11.773,
      77.189,
      54.087,
      11.773,
      77.189,
      54.087,
      11.773,
      77.189,
      54.087,
      9.323,
      77.7,
      55.614,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6778",
    "polygon": [
      80.892,
      65.141,
      9.323,
      80.892,
      65.141,
      11.773,
      80.654,
      64.43,
      11.773,
      80.654,
      64.43,
      11.773,
      80.654,
      64.43,
      9.323,
      80.892,
      65.141,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6779",
    "polygon": [
      80.654,
      64.43,
      9.323,
      80.654,
      64.43,
      11.773,
      80.142,
      62.904,
      11.773,
      80.142,
      62.904,
      11.773,
      80.142,
      62.904,
      9.323,
      80.654,
      64.43,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6780",
    "polygon": [
      80.142,
      62.904,
      9.323,
      80.142,
      62.904,
      11.773,
      79.944,
      62.311,
      11.773,
      79.944,
      62.311,
      11.773,
      79.944,
      62.311,
      9.323,
      80.142,
      62.904,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6781",
    "polygon": [
      79.944,
      62.311,
      9.323,
      79.944,
      62.311,
      11.773,
      79.705,
      61.6,
      11.773,
      79.705,
      61.6,
      11.773,
      79.705,
      61.6,
      9.323,
      79.944,
      62.311,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6782",
    "polygon": [
      79.705,
      61.6,
      9.323,
      79.705,
      61.6,
      11.773,
      79.194,
      60.073,
      11.773,
      79.194,
      60.073,
      11.773,
      79.194,
      60.073,
      9.323,
      79.705,
      61.6,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6783",
    "polygon": [
      82.897,
      71.127,
      9.323,
      82.897,
      71.127,
      11.773,
      82.659,
      70.416,
      11.773,
      82.659,
      70.416,
      11.773,
      82.659,
      70.416,
      9.323,
      82.897,
      71.127,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6784",
    "polygon": [
      82.659,
      70.416,
      9.323,
      82.659,
      70.416,
      11.773,
      82.147,
      68.89,
      11.773,
      82.147,
      68.89,
      11.773,
      82.147,
      68.89,
      9.323,
      82.659,
      70.416,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6785",
    "polygon": [
      82.147,
      68.89,
      9.323,
      82.147,
      68.89,
      11.773,
      81.949,
      68.297,
      11.773,
      81.949,
      68.297,
      11.773,
      81.949,
      68.297,
      9.323,
      82.147,
      68.89,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6786",
    "polygon": [
      81.949,
      68.297,
      9.323,
      81.949,
      68.297,
      11.773,
      81.711,
      67.586,
      11.773,
      81.711,
      67.586,
      11.773,
      81.711,
      67.586,
      9.323,
      81.949,
      68.297,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6787",
    "polygon": [
      81.711,
      67.586,
      9.323,
      81.711,
      67.586,
      11.773,
      81.199,
      66.059,
      11.773,
      81.199,
      66.059,
      11.773,
      81.199,
      66.059,
      9.323,
      81.711,
      67.586,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6788",
    "polygon": [
      84.902,
      77.113,
      9.323,
      84.902,
      77.113,
      11.773,
      84.664,
      76.402,
      11.773,
      84.664,
      76.402,
      11.773,
      84.664,
      76.402,
      9.323,
      84.902,
      77.113,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6789",
    "polygon": [
      84.664,
      76.402,
      9.323,
      84.664,
      76.402,
      11.773,
      84.153,
      74.876,
      11.773,
      84.153,
      74.876,
      11.773,
      84.153,
      74.876,
      9.323,
      84.664,
      76.402,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6790",
    "polygon": [
      84.153,
      74.876,
      9.323,
      84.153,
      74.876,
      11.773,
      83.954,
      74.283,
      11.773,
      83.954,
      74.283,
      11.773,
      83.954,
      74.283,
      9.323,
      84.153,
      74.876,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6791",
    "polygon": [
      83.954,
      74.283,
      9.323,
      83.954,
      74.283,
      11.773,
      83.716,
      73.572,
      11.773,
      83.716,
      73.572,
      11.773,
      83.716,
      73.572,
      9.323,
      83.954,
      74.283,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6792",
    "polygon": [
      83.716,
      73.572,
      9.323,
      83.716,
      73.572,
      11.773,
      83.205,
      72.045,
      11.773,
      83.205,
      72.045,
      11.773,
      83.205,
      72.045,
      9.323,
      83.716,
      73.572,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6793",
    "polygon": [
      86.908,
      83.099,
      9.323,
      86.908,
      83.099,
      11.773,
      86.669,
      82.388,
      11.773,
      86.669,
      82.388,
      11.773,
      86.669,
      82.388,
      9.323,
      86.908,
      83.099,
      9.323
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6794",
    "polygon": [
      86.669,
      82.388,
      9.323,
      86.669,
      82.388,
      11.773,
      86.158,
      80.862,
      11.773,
      86.158,
      80.862,
      11.773,
      86.158,
      80.862,
      9.323,
      86.669,
      82.388,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6795",
    "polygon": [
      86.158,
      80.862,
      9.323,
      86.158,
      80.862,
      11.773,
      85.959,
      80.269,
      11.773,
      85.959,
      80.269,
      11.773,
      85.959,
      80.269,
      9.323,
      86.158,
      80.862,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6796",
    "polygon": [
      85.959,
      80.269,
      9.323,
      85.959,
      80.269,
      11.773,
      85.721,
      79.558,
      11.773,
      85.721,
      79.558,
      11.773,
      85.721,
      79.558,
      9.323,
      85.959,
      80.269,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6797",
    "polygon": [
      85.721,
      79.558,
      9.323,
      85.721,
      79.558,
      11.773,
      85.21,
      78.031,
      11.773,
      85.21,
      78.031,
      11.773,
      85.21,
      78.031,
      9.323,
      85.721,
      79.558,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6798",
    "polygon": [
      72.878,
      41.22,
      13.073,
      72.878,
      41.22,
      15.523,
      72.64,
      40.509,
      15.523,
      72.64,
      40.509,
      15.523,
      72.64,
      40.509,
      13.073,
      72.878,
      41.22,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6799",
    "polygon": [
      72.64,
      40.509,
      13.073,
      72.64,
      40.509,
      15.523,
      72.129,
      38.982,
      15.523,
      72.129,
      38.982,
      15.523,
      72.129,
      38.982,
      13.073,
      72.64,
      40.509,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6800",
    "polygon": [
      72.129,
      38.982,
      13.073,
      72.129,
      38.982,
      15.523,
      71.93,
      38.389,
      15.523,
      71.93,
      38.389,
      15.523,
      71.93,
      38.389,
      13.073,
      72.129,
      38.982,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6801",
    "polygon": [
      71.93,
      38.389,
      13.073,
      71.93,
      38.389,
      15.523,
      71.692,
      37.678,
      15.523,
      71.692,
      37.678,
      15.523,
      71.692,
      37.678,
      13.073,
      71.93,
      38.389,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6802",
    "polygon": [
      71.692,
      37.678,
      13.073,
      71.692,
      37.678,
      15.523,
      71.181,
      36.152,
      15.523,
      71.181,
      36.152,
      15.523,
      71.181,
      36.152,
      13.073,
      71.692,
      37.678,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6803",
    "polygon": [
      74.876,
      47.183,
      13.073,
      74.876,
      47.183,
      15.523,
      74.638,
      46.472,
      15.523,
      74.638,
      46.472,
      15.523,
      74.638,
      46.472,
      13.073,
      74.876,
      47.183,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6804",
    "polygon": [
      74.638,
      46.472,
      13.073,
      74.638,
      46.472,
      15.523,
      74.126,
      44.946,
      15.523,
      74.126,
      44.946,
      15.523,
      74.126,
      44.946,
      13.073,
      74.638,
      46.472,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6805",
    "polygon": [
      74.126,
      44.946,
      13.073,
      74.126,
      44.946,
      15.523,
      73.928,
      44.353,
      15.523,
      73.928,
      44.353,
      15.523,
      73.928,
      44.353,
      13.073,
      74.126,
      44.946,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6806",
    "polygon": [
      73.928,
      44.353,
      13.073,
      73.928,
      44.353,
      15.523,
      73.69,
      43.642,
      15.523,
      73.69,
      43.642,
      15.523,
      73.69,
      43.642,
      13.073,
      73.928,
      44.353,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6807",
    "polygon": [
      73.69,
      43.642,
      13.073,
      73.69,
      43.642,
      15.523,
      73.178,
      42.115,
      15.523,
      73.178,
      42.115,
      15.523,
      73.178,
      42.115,
      13.073,
      73.69,
      43.642,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6808",
    "polygon": [
      76.881,
      53.169,
      13.073,
      76.881,
      53.169,
      15.523,
      76.643,
      52.458,
      15.523,
      76.643,
      52.458,
      15.523,
      76.643,
      52.458,
      13.073,
      76.881,
      53.169,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6809",
    "polygon": [
      76.643,
      52.458,
      13.073,
      76.643,
      52.458,
      15.523,
      76.132,
      50.932,
      15.523,
      76.132,
      50.932,
      15.523,
      76.132,
      50.932,
      13.073,
      76.643,
      52.458,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6810",
    "polygon": [
      76.132,
      50.932,
      13.073,
      76.132,
      50.932,
      15.523,
      75.933,
      50.339,
      15.523,
      75.933,
      50.339,
      15.523,
      75.933,
      50.339,
      13.073,
      76.132,
      50.932,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6811",
    "polygon": [
      75.933,
      50.339,
      13.073,
      75.933,
      50.339,
      15.523,
      75.695,
      49.628,
      15.523,
      75.695,
      49.628,
      15.523,
      75.695,
      49.628,
      13.073,
      75.933,
      50.339,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6812",
    "polygon": [
      75.695,
      49.628,
      13.073,
      75.695,
      49.628,
      15.523,
      75.184,
      48.101,
      15.523,
      75.184,
      48.101,
      15.523,
      75.184,
      48.101,
      13.073,
      75.695,
      49.628,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6813",
    "polygon": [
      78.887,
      59.155,
      13.073,
      78.887,
      59.155,
      15.523,
      78.648,
      58.444,
      15.523,
      78.648,
      58.444,
      15.523,
      78.648,
      58.444,
      13.073,
      78.887,
      59.155,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6814",
    "polygon": [
      78.648,
      58.444,
      13.073,
      78.648,
      58.444,
      15.523,
      78.137,
      56.918,
      15.523,
      78.137,
      56.918,
      15.523,
      78.137,
      56.918,
      13.073,
      78.648,
      58.444,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6815",
    "polygon": [
      78.137,
      56.918,
      13.073,
      78.137,
      56.918,
      15.523,
      77.938,
      56.325,
      15.523,
      77.938,
      56.325,
      15.523,
      77.938,
      56.325,
      13.073,
      78.137,
      56.918,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6816",
    "polygon": [
      77.938,
      56.325,
      13.073,
      77.938,
      56.325,
      15.523,
      77.7,
      55.614,
      15.523,
      77.7,
      55.614,
      15.523,
      77.7,
      55.614,
      13.073,
      77.938,
      56.325,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6817",
    "polygon": [
      77.7,
      55.614,
      13.073,
      77.7,
      55.614,
      15.523,
      77.189,
      54.087,
      15.523,
      77.189,
      54.087,
      15.523,
      77.189,
      54.087,
      13.073,
      77.7,
      55.614,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6818",
    "polygon": [
      80.892,
      65.141,
      13.073,
      80.892,
      65.141,
      15.523,
      80.654,
      64.43,
      15.523,
      80.654,
      64.43,
      15.523,
      80.654,
      64.43,
      13.073,
      80.892,
      65.141,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6819",
    "polygon": [
      80.654,
      64.43,
      13.073,
      80.654,
      64.43,
      15.523,
      80.142,
      62.904,
      15.523,
      80.142,
      62.904,
      15.523,
      80.142,
      62.904,
      13.073,
      80.654,
      64.43,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6820",
    "polygon": [
      80.142,
      62.904,
      13.073,
      80.142,
      62.904,
      15.523,
      79.944,
      62.311,
      15.523,
      79.944,
      62.311,
      15.523,
      79.944,
      62.311,
      13.073,
      80.142,
      62.904,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6821",
    "polygon": [
      79.944,
      62.311,
      13.073,
      79.944,
      62.311,
      15.523,
      79.705,
      61.6,
      15.523,
      79.705,
      61.6,
      15.523,
      79.705,
      61.6,
      13.073,
      79.944,
      62.311,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6822",
    "polygon": [
      79.705,
      61.6,
      13.073,
      79.705,
      61.6,
      15.523,
      79.194,
      60.073,
      15.523,
      79.194,
      60.073,
      15.523,
      79.194,
      60.073,
      13.073,
      79.705,
      61.6,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6823",
    "polygon": [
      82.897,
      71.127,
      13.073,
      82.897,
      71.127,
      15.523,
      82.659,
      70.416,
      15.523,
      82.659,
      70.416,
      15.523,
      82.659,
      70.416,
      13.073,
      82.897,
      71.127,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6824",
    "polygon": [
      82.659,
      70.416,
      13.073,
      82.659,
      70.416,
      15.523,
      82.147,
      68.89,
      15.523,
      82.147,
      68.89,
      15.523,
      82.147,
      68.89,
      13.073,
      82.659,
      70.416,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6825",
    "polygon": [
      82.147,
      68.89,
      13.073,
      82.147,
      68.89,
      15.523,
      81.949,
      68.297,
      15.523,
      81.949,
      68.297,
      15.523,
      81.949,
      68.297,
      13.073,
      82.147,
      68.89,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6826",
    "polygon": [
      81.949,
      68.297,
      13.073,
      81.949,
      68.297,
      15.523,
      81.711,
      67.586,
      15.523,
      81.711,
      67.586,
      15.523,
      81.711,
      67.586,
      13.073,
      81.949,
      68.297,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6827",
    "polygon": [
      81.711,
      67.586,
      13.073,
      81.711,
      67.586,
      15.523,
      81.199,
      66.059,
      15.523,
      81.199,
      66.059,
      15.523,
      81.199,
      66.059,
      13.073,
      81.711,
      67.586,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6828",
    "polygon": [
      84.902,
      77.113,
      13.073,
      84.902,
      77.113,
      15.523,
      84.664,
      76.402,
      15.523,
      84.664,
      76.402,
      15.523,
      84.664,
      76.402,
      13.073,
      84.902,
      77.113,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6829",
    "polygon": [
      84.664,
      76.402,
      13.073,
      84.664,
      76.402,
      15.523,
      84.153,
      74.876,
      15.523,
      84.153,
      74.876,
      15.523,
      84.153,
      74.876,
      13.073,
      84.664,
      76.402,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6830",
    "polygon": [
      84.153,
      74.876,
      13.073,
      84.153,
      74.876,
      15.523,
      83.954,
      74.283,
      15.523,
      83.954,
      74.283,
      15.523,
      83.954,
      74.283,
      13.073,
      84.153,
      74.876,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6831",
    "polygon": [
      83.954,
      74.283,
      13.073,
      83.954,
      74.283,
      15.523,
      83.716,
      73.572,
      15.523,
      83.716,
      73.572,
      15.523,
      83.716,
      73.572,
      13.073,
      83.954,
      74.283,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6832",
    "polygon": [
      83.716,
      73.572,
      13.073,
      83.716,
      73.572,
      15.523,
      83.205,
      72.045,
      15.523,
      83.205,
      72.045,
      15.523,
      83.205,
      72.045,
      13.073,
      83.716,
      73.572,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6833",
    "polygon": [
      86.908,
      83.099,
      13.073,
      86.908,
      83.099,
      15.523,
      86.669,
      82.388,
      15.523,
      86.669,
      82.388,
      15.523,
      86.669,
      82.388,
      13.073,
      86.908,
      83.099,
      13.073
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6834",
    "polygon": [
      86.669,
      82.388,
      13.073,
      86.669,
      82.388,
      15.523,
      86.158,
      80.862,
      15.523,
      86.158,
      80.862,
      15.523,
      86.158,
      80.862,
      13.073,
      86.669,
      82.388,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6835",
    "polygon": [
      86.158,
      80.862,
      13.073,
      86.158,
      80.862,
      15.523,
      85.959,
      80.269,
      15.523,
      85.959,
      80.269,
      15.523,
      85.959,
      80.269,
      13.073,
      86.158,
      80.862,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6836",
    "polygon": [
      85.959,
      80.269,
      13.073,
      85.959,
      80.269,
      15.523,
      85.721,
      79.558,
      15.523,
      85.721,
      79.558,
      15.523,
      85.721,
      79.558,
      13.073,
      85.959,
      80.269,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6837",
    "polygon": [
      85.721,
      79.558,
      13.073,
      85.721,
      79.558,
      15.523,
      85.21,
      78.031,
      15.523,
      85.21,
      78.031,
      15.523,
      85.21,
      78.031,
      13.073,
      85.721,
      79.558,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6838",
    "polygon": [
      80.959,
      86.64,
      0.695,
      80.959,
      86.64,
      3.405,
      81.67,
      86.402,
      3.405,
      81.67,
      86.402,
      3.405,
      81.67,
      86.402,
      0.695,
      80.959,
      86.64,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6839",
    "polygon": [
      81.67,
      86.402,
      0.695,
      81.67,
      86.402,
      3.405,
      83.197,
      85.891,
      3.405,
      83.197,
      85.891,
      3.405,
      83.197,
      85.891,
      0.695,
      81.67,
      86.402,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6840",
    "polygon": [
      83.197,
      85.891,
      0.695,
      83.197,
      85.891,
      3.405,
      83.785,
      85.694,
      3.405,
      83.785,
      85.694,
      3.405,
      83.785,
      85.694,
      0.695,
      83.197,
      85.891,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6841",
    "polygon": [
      83.785,
      85.694,
      0.695,
      83.785,
      85.694,
      3.405,
      84.496,
      85.456,
      3.405,
      84.496,
      85.456,
      3.405,
      84.496,
      85.456,
      0.695,
      83.785,
      85.694,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6842",
    "polygon": [
      84.496,
      85.456,
      0.695,
      84.496,
      85.456,
      3.405,
      86.023,
      84.944,
      3.405,
      86.023,
      84.944,
      3.405,
      86.023,
      84.944,
      0.695,
      84.496,
      85.456,
      0.695
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6843",
    "polygon": [
      75.009,
      88.634,
      0.695,
      75.009,
      88.634,
      3.405,
      75.72,
      88.395,
      3.405,
      75.72,
      88.395,
      3.405,
      75.72,
      88.395,
      0.695,
      75.009,
      88.634,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6844",
    "polygon": [
      75.72,
      88.395,
      0.695,
      75.72,
      88.395,
      3.405,
      77.247,
      87.884,
      3.405,
      77.247,
      87.884,
      3.405,
      77.247,
      87.884,
      0.695,
      75.72,
      88.395,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6845",
    "polygon": [
      77.247,
      87.884,
      0.695,
      77.247,
      87.884,
      3.405,
      77.835,
      87.687,
      3.405,
      77.835,
      87.687,
      3.405,
      77.835,
      87.687,
      0.695,
      77.247,
      87.884,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6846",
    "polygon": [
      77.835,
      87.687,
      0.695,
      77.835,
      87.687,
      3.405,
      78.546,
      87.449,
      3.405,
      78.546,
      87.449,
      3.405,
      78.546,
      87.449,
      0.695,
      77.835,
      87.687,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6847",
    "polygon": [
      78.546,
      87.449,
      0.695,
      78.546,
      87.449,
      3.405,
      80.073,
      86.937,
      3.405,
      80.073,
      86.937,
      3.405,
      80.073,
      86.937,
      0.695,
      78.546,
      87.449,
      0.695
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6848",
    "polygon": [
      69.059,
      90.627,
      0.695,
      69.059,
      90.627,
      3.405,
      69.77,
      90.388,
      3.405,
      69.77,
      90.388,
      3.405,
      69.77,
      90.388,
      0.695,
      69.059,
      90.627,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6849",
    "polygon": [
      69.77,
      90.388,
      0.695,
      69.77,
      90.388,
      3.405,
      71.297,
      89.877,
      3.405,
      71.297,
      89.877,
      3.405,
      71.297,
      89.877,
      0.695,
      69.77,
      90.388,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6850",
    "polygon": [
      71.297,
      89.877,
      0.695,
      71.297,
      89.877,
      3.405,
      71.885,
      89.68,
      3.405,
      71.885,
      89.68,
      3.405,
      71.885,
      89.68,
      0.695,
      71.297,
      89.877,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6851",
    "polygon": [
      71.885,
      89.68,
      0.695,
      71.885,
      89.68,
      3.405,
      72.596,
      89.442,
      3.405,
      72.596,
      89.442,
      3.405,
      72.596,
      89.442,
      0.695,
      71.885,
      89.68,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6852",
    "polygon": [
      72.596,
      89.442,
      0.695,
      72.596,
      89.442,
      3.405,
      74.123,
      88.931,
      3.405,
      74.123,
      88.931,
      3.405,
      74.123,
      88.931,
      0.695,
      72.596,
      89.442,
      0.695
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6853",
    "polygon": [
      63.109,
      92.62,
      0.695,
      63.109,
      92.62,
      3.405,
      63.82,
      92.382,
      3.405,
      63.82,
      92.382,
      3.405,
      63.82,
      92.382,
      0.695,
      63.109,
      92.62,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6854",
    "polygon": [
      63.82,
      92.382,
      0.695,
      63.82,
      92.382,
      3.405,
      65.347,
      91.87,
      3.405,
      65.347,
      91.87,
      3.405,
      65.347,
      91.87,
      0.695,
      63.82,
      92.382,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6855",
    "polygon": [
      65.347,
      91.87,
      0.695,
      65.347,
      91.87,
      3.405,
      65.935,
      91.673,
      3.405,
      65.935,
      91.673,
      3.405,
      65.935,
      91.673,
      0.695,
      65.347,
      91.87,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6856",
    "polygon": [
      65.935,
      91.673,
      0.695,
      65.935,
      91.673,
      3.405,
      66.646,
      91.435,
      3.405,
      66.646,
      91.435,
      3.405,
      66.646,
      91.435,
      0.695,
      65.935,
      91.673,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6857",
    "polygon": [
      66.646,
      91.435,
      0.695,
      66.646,
      91.435,
      3.405,
      68.173,
      90.924,
      3.405,
      68.173,
      90.924,
      3.405,
      68.173,
      90.924,
      0.695,
      66.646,
      91.435,
      0.695
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6858",
    "polygon": [
      45.335,
      98.574,
      0.695,
      45.335,
      98.574,
      3.405,
      46.046,
      98.336,
      3.405,
      46.046,
      98.336,
      3.405,
      46.046,
      98.336,
      0.695,
      45.335,
      98.574,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6859",
    "polygon": [
      46.046,
      98.336,
      0.695,
      46.046,
      98.336,
      3.405,
      47.573,
      97.824,
      3.405,
      47.573,
      97.824,
      3.405,
      47.573,
      97.824,
      0.695,
      46.046,
      98.336,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6860",
    "polygon": [
      47.573,
      97.824,
      0.695,
      47.573,
      97.824,
      3.405,
      48.161,
      97.627,
      3.405,
      48.161,
      97.627,
      3.405,
      48.161,
      97.627,
      0.695,
      47.573,
      97.824,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6861",
    "polygon": [
      48.161,
      97.627,
      0.695,
      48.161,
      97.627,
      3.405,
      48.872,
      97.389,
      3.405,
      48.872,
      97.389,
      3.405,
      48.872,
      97.389,
      0.695,
      48.161,
      97.627,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6862",
    "polygon": [
      48.872,
      97.389,
      0.695,
      48.872,
      97.389,
      3.405,
      50.398,
      96.878,
      3.405,
      50.398,
      96.878,
      3.405,
      50.398,
      96.878,
      0.695,
      48.872,
      97.389,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6863",
    "polygon": [
      39.431,
      100.552,
      0.695,
      39.431,
      100.552,
      3.405,
      40.142,
      100.313,
      3.405,
      40.142,
      100.313,
      3.405,
      40.142,
      100.313,
      0.695,
      39.431,
      100.552,
      0.695
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6864",
    "polygon": [
      40.142,
      100.313,
      0.695,
      40.142,
      100.313,
      3.405,
      41.669,
      99.802,
      3.405,
      41.669,
      99.802,
      3.405,
      41.669,
      99.802,
      0.695,
      40.142,
      100.313,
      0.695
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-B-Outer_EG_6865",
    "polygon": [
      41.669,
      99.802,
      0.695,
      41.669,
      99.802,
      3.405,
      42.257,
      99.605,
      3.405,
      42.257,
      99.605,
      3.405,
      42.257,
      99.605,
      0.695,
      41.669,
      99.802,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6866",
    "polygon": [
      42.257,
      99.605,
      0.695,
      42.257,
      99.605,
      3.405,
      42.968,
      99.367,
      3.405,
      42.968,
      99.367,
      3.405,
      42.968,
      99.367,
      0.695,
      42.257,
      99.605,
      0.695
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6867",
    "polygon": [
      42.968,
      99.367,
      0.695,
      42.968,
      99.367,
      3.405,
      44.495,
      98.855,
      3.405,
      44.495,
      98.855,
      3.405,
      44.495,
      98.855,
      0.695,
      42.968,
      99.367,
      0.695
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6873",
    "polygon": [
      58.276,
      94.239,
      0.013,
      58.276,
      94.239,
      2.313,
      59.267,
      93.907,
      2.313,
      59.267,
      93.907,
      2.313,
      59.267,
      93.907,
      0.013,
      58.276,
      94.239,
      0.013
    ]
  },
  {
    "ID": "Block-B-Outer_EG_6874",
    "polygon": [
      57.288,
      94.57,
      0.013,
      57.288,
      94.57,
      2.313,
      58.279,
      94.238,
      2.313,
      58.279,
      94.238,
      2.313,
      58.279,
      94.238,
      0.013,
      57.288,
      94.57,
      0.013
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "Block-B-Outer_EG_6875",
    "polygon": [
      57.287,
      94.57,
      2.42,
      57.287,
      94.57,
      3.41,
      59.269,
      93.906,
      3.41,
      59.269,
      93.906,
      3.41,
      59.269,
      93.906,
      2.42,
      57.287,
      94.57,
      2.42
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6881",
    "polygon": [
      80.954,
      86.642,
      5.573,
      80.954,
      86.642,
      8.023,
      81.665,
      86.403,
      8.023,
      81.665,
      86.403,
      8.023,
      81.665,
      86.403,
      5.573,
      80.954,
      86.642,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6882",
    "polygon": [
      81.665,
      86.403,
      5.573,
      81.665,
      86.403,
      8.023,
      83.192,
      85.892,
      8.023,
      83.192,
      85.892,
      8.023,
      83.192,
      85.892,
      5.573,
      81.665,
      86.403,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6883",
    "polygon": [
      83.192,
      85.892,
      5.573,
      83.192,
      85.892,
      8.023,
      83.785,
      85.693,
      8.023,
      83.785,
      85.693,
      8.023,
      83.785,
      85.693,
      5.573,
      83.192,
      85.892,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6884",
    "polygon": [
      83.785,
      85.693,
      5.573,
      83.785,
      85.693,
      8.023,
      84.496,
      85.455,
      8.023,
      84.496,
      85.455,
      8.023,
      84.496,
      85.455,
      5.573,
      83.785,
      85.693,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6885",
    "polygon": [
      84.496,
      85.455,
      5.573,
      84.496,
      85.455,
      8.023,
      86.022,
      84.944,
      8.023,
      86.022,
      84.944,
      8.023,
      86.022,
      84.944,
      5.573,
      84.496,
      85.455,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6886",
    "polygon": [
      75.004,
      88.635,
      5.573,
      75.004,
      88.635,
      8.023,
      75.715,
      88.396,
      8.023,
      75.715,
      88.396,
      8.023,
      75.715,
      88.396,
      5.573,
      75.004,
      88.635,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6887",
    "polygon": [
      75.715,
      88.396,
      5.573,
      75.715,
      88.396,
      8.023,
      77.242,
      87.885,
      8.023,
      77.242,
      87.885,
      8.023,
      77.242,
      87.885,
      5.573,
      75.715,
      88.396,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6888",
    "polygon": [
      77.242,
      87.885,
      5.573,
      77.242,
      87.885,
      8.023,
      77.835,
      87.687,
      8.023,
      77.835,
      87.687,
      8.023,
      77.835,
      87.687,
      5.573,
      77.242,
      87.885,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6889",
    "polygon": [
      77.835,
      87.687,
      5.573,
      77.835,
      87.687,
      8.023,
      78.546,
      87.448,
      8.023,
      78.546,
      87.448,
      8.023,
      78.546,
      87.448,
      5.573,
      77.835,
      87.687,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6890",
    "polygon": [
      78.546,
      87.448,
      5.573,
      78.546,
      87.448,
      8.023,
      80.072,
      86.937,
      8.023,
      80.072,
      86.937,
      8.023,
      80.072,
      86.937,
      5.573,
      78.546,
      87.448,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6891",
    "polygon": [
      69.054,
      90.628,
      5.573,
      69.054,
      90.628,
      8.023,
      69.766,
      90.39,
      8.023,
      69.766,
      90.39,
      8.023,
      69.766,
      90.39,
      5.573,
      69.054,
      90.628,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6892",
    "polygon": [
      69.766,
      90.39,
      5.573,
      69.766,
      90.39,
      8.023,
      71.292,
      89.878,
      8.023,
      71.292,
      89.878,
      8.023,
      71.292,
      89.878,
      5.573,
      69.766,
      90.39,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6893",
    "polygon": [
      71.292,
      89.878,
      5.573,
      71.292,
      89.878,
      8.023,
      71.885,
      89.68,
      8.023,
      71.885,
      89.68,
      8.023,
      71.885,
      89.68,
      5.573,
      71.292,
      89.878,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6894",
    "polygon": [
      71.885,
      89.68,
      5.573,
      71.885,
      89.68,
      8.023,
      72.596,
      89.441,
      8.023,
      72.596,
      89.441,
      8.023,
      72.596,
      89.441,
      5.573,
      71.885,
      89.68,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6895",
    "polygon": [
      72.596,
      89.441,
      5.573,
      72.596,
      89.441,
      8.023,
      74.123,
      88.93,
      8.023,
      74.123,
      88.93,
      8.023,
      74.123,
      88.93,
      5.573,
      72.596,
      89.441,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6896",
    "polygon": [
      63.104,
      92.621,
      5.573,
      63.104,
      92.621,
      8.023,
      63.816,
      92.383,
      8.023,
      63.816,
      92.383,
      8.023,
      63.816,
      92.383,
      5.573,
      63.104,
      92.621,
      5.573
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6897",
    "polygon": [
      63.816,
      92.383,
      5.573,
      63.816,
      92.383,
      8.023,
      65.342,
      91.871,
      8.023,
      65.342,
      91.871,
      8.023,
      65.342,
      91.871,
      5.573,
      63.816,
      92.383,
      5.573
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6898",
    "polygon": [
      65.342,
      91.871,
      5.573,
      65.342,
      91.871,
      8.023,
      65.935,
      91.673,
      8.023,
      65.935,
      91.673,
      8.023,
      65.935,
      91.673,
      5.573,
      65.342,
      91.871,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6899",
    "polygon": [
      65.935,
      91.673,
      5.573,
      65.935,
      91.673,
      8.023,
      66.646,
      91.435,
      8.023,
      66.646,
      91.435,
      8.023,
      66.646,
      91.435,
      5.573,
      65.935,
      91.673,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6900",
    "polygon": [
      66.646,
      91.435,
      5.573,
      66.646,
      91.435,
      8.023,
      68.173,
      90.923,
      8.023,
      68.173,
      90.923,
      8.023,
      68.173,
      90.923,
      5.573,
      66.646,
      91.435,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6901",
    "polygon": [
      57.155,
      94.614,
      5.573,
      57.155,
      94.614,
      8.023,
      57.866,
      94.376,
      8.023,
      57.866,
      94.376,
      8.023,
      57.866,
      94.376,
      5.573,
      57.155,
      94.614,
      5.573
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6902",
    "polygon": [
      57.866,
      94.376,
      5.573,
      57.866,
      94.376,
      8.023,
      59.392,
      93.864,
      8.023,
      59.392,
      93.864,
      8.023,
      59.392,
      93.864,
      5.573,
      57.866,
      94.376,
      5.573
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6903",
    "polygon": [
      59.392,
      93.864,
      5.573,
      59.392,
      93.864,
      8.023,
      59.985,
      93.666,
      8.023,
      59.985,
      93.666,
      8.023,
      59.985,
      93.666,
      5.573,
      59.392,
      93.864,
      5.573
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6904",
    "polygon": [
      59.985,
      93.666,
      5.573,
      59.985,
      93.666,
      8.023,
      60.696,
      93.428,
      8.023,
      60.696,
      93.428,
      8.023,
      60.696,
      93.428,
      5.573,
      59.985,
      93.666,
      5.573
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6905",
    "polygon": [
      60.696,
      93.428,
      5.573,
      60.696,
      93.428,
      8.023,
      62.223,
      92.916,
      8.023,
      62.223,
      92.916,
      8.023,
      62.223,
      92.916,
      5.573,
      60.696,
      93.428,
      5.573
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6906",
    "polygon": [
      45.33,
      98.575,
      5.573,
      45.33,
      98.575,
      8.023,
      46.042,
      98.337,
      8.023,
      46.042,
      98.337,
      8.023,
      46.042,
      98.337,
      5.573,
      45.33,
      98.575,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6907",
    "polygon": [
      46.042,
      98.337,
      5.573,
      46.042,
      98.337,
      8.023,
      47.568,
      97.825,
      8.023,
      47.568,
      97.825,
      8.023,
      47.568,
      97.825,
      5.573,
      46.042,
      98.337,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6908",
    "polygon": [
      47.568,
      97.825,
      5.573,
      47.568,
      97.825,
      8.023,
      48.161,
      97.627,
      8.023,
      48.161,
      97.627,
      8.023,
      48.161,
      97.627,
      5.573,
      47.568,
      97.825,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6909",
    "polygon": [
      48.161,
      97.627,
      5.573,
      48.161,
      97.627,
      8.023,
      48.872,
      97.389,
      8.023,
      48.872,
      97.389,
      8.023,
      48.872,
      97.389,
      5.573,
      48.161,
      97.627,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6910",
    "polygon": [
      48.872,
      97.389,
      5.573,
      48.872,
      97.389,
      8.023,
      50.399,
      96.877,
      8.023,
      50.399,
      96.877,
      8.023,
      50.399,
      96.877,
      5.573,
      48.872,
      97.389,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6911",
    "polygon": [
      39.427,
      100.553,
      5.573,
      39.427,
      100.553,
      8.023,
      40.138,
      100.315,
      8.023,
      40.138,
      100.315,
      8.023,
      40.138,
      100.315,
      5.573,
      39.427,
      100.553,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6912",
    "polygon": [
      40.138,
      100.315,
      5.573,
      40.138,
      100.315,
      8.023,
      41.664,
      99.803,
      8.023,
      41.664,
      99.803,
      8.023,
      41.664,
      99.803,
      5.573,
      40.138,
      100.315,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6913",
    "polygon": [
      41.664,
      99.803,
      5.573,
      41.664,
      99.803,
      8.023,
      42.257,
      99.605,
      8.023,
      42.257,
      99.605,
      8.023,
      42.257,
      99.605,
      5.573,
      41.664,
      99.803,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6914",
    "polygon": [
      42.257,
      99.605,
      5.573,
      42.257,
      99.605,
      8.023,
      42.968,
      99.366,
      8.023,
      42.968,
      99.366,
      8.023,
      42.968,
      99.366,
      5.573,
      42.257,
      99.605,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_1.OG_6915",
    "polygon": [
      42.968,
      99.366,
      5.573,
      42.968,
      99.366,
      8.023,
      44.495,
      98.855,
      8.023,
      44.495,
      98.855,
      8.023,
      44.495,
      98.855,
      5.573,
      42.968,
      99.366,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6916",
    "polygon": [
      80.953,
      86.639,
      9.323,
      80.953,
      86.639,
      11.773,
      81.664,
      86.4,
      11.773,
      81.664,
      86.4,
      11.773,
      81.664,
      86.4,
      9.323,
      80.953,
      86.639,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6917",
    "polygon": [
      81.664,
      86.4,
      9.323,
      81.664,
      86.4,
      11.773,
      83.191,
      85.889,
      11.773,
      83.191,
      85.889,
      11.773,
      83.191,
      85.889,
      9.323,
      81.664,
      86.4,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6918",
    "polygon": [
      83.191,
      85.889,
      9.323,
      83.191,
      85.889,
      11.773,
      83.784,
      85.69,
      11.773,
      83.784,
      85.69,
      11.773,
      83.784,
      85.69,
      9.323,
      83.191,
      85.889,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6919",
    "polygon": [
      83.784,
      85.69,
      9.323,
      83.784,
      85.69,
      11.773,
      84.495,
      85.452,
      11.773,
      84.495,
      85.452,
      11.773,
      84.495,
      85.452,
      9.323,
      83.784,
      85.69,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6920",
    "polygon": [
      84.495,
      85.452,
      9.323,
      84.495,
      85.452,
      11.773,
      86.021,
      84.941,
      11.773,
      86.021,
      84.941,
      11.773,
      86.021,
      84.941,
      9.323,
      84.495,
      85.452,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6921",
    "polygon": [
      75.003,
      88.632,
      9.323,
      75.003,
      88.632,
      11.773,
      75.714,
      88.394,
      11.773,
      75.714,
      88.394,
      11.773,
      75.714,
      88.394,
      9.323,
      75.003,
      88.632,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6922",
    "polygon": [
      75.714,
      88.394,
      9.323,
      75.714,
      88.394,
      11.773,
      77.241,
      87.882,
      11.773,
      77.241,
      87.882,
      11.773,
      77.241,
      87.882,
      9.323,
      75.714,
      88.394,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6923",
    "polygon": [
      77.241,
      87.882,
      9.323,
      77.241,
      87.882,
      11.773,
      77.834,
      87.684,
      11.773,
      77.834,
      87.684,
      11.773,
      77.834,
      87.684,
      9.323,
      77.241,
      87.882,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6924",
    "polygon": [
      77.834,
      87.684,
      9.323,
      77.834,
      87.684,
      11.773,
      78.545,
      87.445,
      11.773,
      78.545,
      87.445,
      11.773,
      78.545,
      87.445,
      9.323,
      77.834,
      87.684,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6925",
    "polygon": [
      78.545,
      87.445,
      9.323,
      78.545,
      87.445,
      11.773,
      80.071,
      86.934,
      11.773,
      80.071,
      86.934,
      11.773,
      80.071,
      86.934,
      9.323,
      78.545,
      87.445,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6926",
    "polygon": [
      69.053,
      90.625,
      9.323,
      69.053,
      90.625,
      11.773,
      69.765,
      90.387,
      11.773,
      69.765,
      90.387,
      11.773,
      69.765,
      90.387,
      9.323,
      69.053,
      90.625,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6927",
    "polygon": [
      69.765,
      90.387,
      9.323,
      69.765,
      90.387,
      11.773,
      71.291,
      89.875,
      11.773,
      71.291,
      89.875,
      11.773,
      71.291,
      89.875,
      9.323,
      69.765,
      90.387,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6928",
    "polygon": [
      71.291,
      89.875,
      9.323,
      71.291,
      89.875,
      11.773,
      71.884,
      89.677,
      11.773,
      71.884,
      89.677,
      11.773,
      71.884,
      89.677,
      9.323,
      71.291,
      89.875,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6929",
    "polygon": [
      71.884,
      89.677,
      9.323,
      71.884,
      89.677,
      11.773,
      72.595,
      89.439,
      11.773,
      72.595,
      89.439,
      11.773,
      72.595,
      89.439,
      9.323,
      71.884,
      89.677,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6930",
    "polygon": [
      72.595,
      89.439,
      9.323,
      72.595,
      89.439,
      11.773,
      74.122,
      88.927,
      11.773,
      74.122,
      88.927,
      11.773,
      74.122,
      88.927,
      9.323,
      72.595,
      89.439,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6931",
    "polygon": [
      63.103,
      92.618,
      9.323,
      63.103,
      92.618,
      11.773,
      63.815,
      92.38,
      11.773,
      63.815,
      92.38,
      11.773,
      63.815,
      92.38,
      9.323,
      63.103,
      92.618,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6932",
    "polygon": [
      63.815,
      92.38,
      9.323,
      63.815,
      92.38,
      11.773,
      65.341,
      91.868,
      11.773,
      65.341,
      91.868,
      11.773,
      65.341,
      91.868,
      9.323,
      63.815,
      92.38,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6933",
    "polygon": [
      65.341,
      91.868,
      9.323,
      65.341,
      91.868,
      11.773,
      65.934,
      91.67,
      11.773,
      65.934,
      91.67,
      11.773,
      65.934,
      91.67,
      9.323,
      65.341,
      91.868,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6934",
    "polygon": [
      65.934,
      91.67,
      9.323,
      65.934,
      91.67,
      11.773,
      66.645,
      91.432,
      11.773,
      66.645,
      91.432,
      11.773,
      66.645,
      91.432,
      9.323,
      65.934,
      91.67,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6935",
    "polygon": [
      66.645,
      91.432,
      9.323,
      66.645,
      91.432,
      11.773,
      68.172,
      90.92,
      11.773,
      68.172,
      90.92,
      11.773,
      68.172,
      90.92,
      9.323,
      66.645,
      91.432,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6936",
    "polygon": [
      57.154,
      94.611,
      9.323,
      57.154,
      94.611,
      11.773,
      57.865,
      94.373,
      11.773,
      57.865,
      94.373,
      11.773,
      57.865,
      94.373,
      9.323,
      57.154,
      94.611,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6937",
    "polygon": [
      57.865,
      94.373,
      9.323,
      57.865,
      94.373,
      11.773,
      59.391,
      93.862,
      11.773,
      59.391,
      93.862,
      11.773,
      59.391,
      93.862,
      9.323,
      57.865,
      94.373,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6938",
    "polygon": [
      59.391,
      93.862,
      9.323,
      59.391,
      93.862,
      11.773,
      59.984,
      93.663,
      11.773,
      59.984,
      93.663,
      11.773,
      59.984,
      93.663,
      9.323,
      59.391,
      93.862,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6939",
    "polygon": [
      59.984,
      93.663,
      9.323,
      59.984,
      93.663,
      11.773,
      60.695,
      93.425,
      11.773,
      60.695,
      93.425,
      11.773,
      60.695,
      93.425,
      9.323,
      59.984,
      93.663,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6940",
    "polygon": [
      60.695,
      93.425,
      9.323,
      60.695,
      93.425,
      11.773,
      62.222,
      92.913,
      11.773,
      62.222,
      92.913,
      11.773,
      62.222,
      92.913,
      9.323,
      60.695,
      93.425,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6941",
    "polygon": [
      45.329,
      98.572,
      9.323,
      45.329,
      98.572,
      11.773,
      46.041,
      98.334,
      11.773,
      46.041,
      98.334,
      11.773,
      46.041,
      98.334,
      9.323,
      45.329,
      98.572,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6942",
    "polygon": [
      46.041,
      98.334,
      9.323,
      46.041,
      98.334,
      11.773,
      47.567,
      97.822,
      11.773,
      47.567,
      97.822,
      11.773,
      47.567,
      97.822,
      9.323,
      46.041,
      98.334,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6943",
    "polygon": [
      47.567,
      97.822,
      9.323,
      47.567,
      97.822,
      11.773,
      48.16,
      97.624,
      11.773,
      48.16,
      97.624,
      11.773,
      48.16,
      97.624,
      9.323,
      47.567,
      97.822,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6944",
    "polygon": [
      48.16,
      97.624,
      9.323,
      48.16,
      97.624,
      11.773,
      48.871,
      97.386,
      11.773,
      48.871,
      97.386,
      11.773,
      48.871,
      97.386,
      9.323,
      48.16,
      97.624,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6945",
    "polygon": [
      48.871,
      97.386,
      9.323,
      48.871,
      97.386,
      11.773,
      50.398,
      96.874,
      11.773,
      50.398,
      96.874,
      11.773,
      50.398,
      96.874,
      9.323,
      48.871,
      97.386,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6946",
    "polygon": [
      39.426,
      100.55,
      9.323,
      39.426,
      100.55,
      11.773,
      40.137,
      100.312,
      11.773,
      40.137,
      100.312,
      11.773,
      40.137,
      100.312,
      9.323,
      39.426,
      100.55,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6947",
    "polygon": [
      40.137,
      100.312,
      9.323,
      40.137,
      100.312,
      11.773,
      41.663,
      99.8,
      11.773,
      41.663,
      99.8,
      11.773,
      41.663,
      99.8,
      9.323,
      40.137,
      100.312,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6948",
    "polygon": [
      41.663,
      99.8,
      9.323,
      41.663,
      99.8,
      11.773,
      42.256,
      99.602,
      11.773,
      42.256,
      99.602,
      11.773,
      42.256,
      99.602,
      9.323,
      41.663,
      99.8,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6949",
    "polygon": [
      42.256,
      99.602,
      9.323,
      42.256,
      99.602,
      11.773,
      42.967,
      99.363,
      11.773,
      42.967,
      99.363,
      11.773,
      42.967,
      99.363,
      9.323,
      42.256,
      99.602,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_2.OG_6950",
    "polygon": [
      42.967,
      99.363,
      9.323,
      42.967,
      99.363,
      11.773,
      44.494,
      98.852,
      11.773,
      44.494,
      98.852,
      11.773,
      44.494,
      98.852,
      9.323,
      42.967,
      99.363,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6951",
    "polygon": [
      80.953,
      86.639,
      13.073,
      80.953,
      86.639,
      15.523,
      81.664,
      86.4,
      15.523,
      81.664,
      86.4,
      15.523,
      81.664,
      86.4,
      13.073,
      80.953,
      86.639,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6952",
    "polygon": [
      81.664,
      86.4,
      13.073,
      81.664,
      86.4,
      15.523,
      83.191,
      85.889,
      15.523,
      83.191,
      85.889,
      15.523,
      83.191,
      85.889,
      13.073,
      81.664,
      86.4,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6953",
    "polygon": [
      83.191,
      85.889,
      13.073,
      83.191,
      85.889,
      15.523,
      83.784,
      85.69,
      15.523,
      83.784,
      85.69,
      15.523,
      83.784,
      85.69,
      13.073,
      83.191,
      85.889,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6954",
    "polygon": [
      83.784,
      85.69,
      13.073,
      83.784,
      85.69,
      15.523,
      84.495,
      85.452,
      15.523,
      84.495,
      85.452,
      15.523,
      84.495,
      85.452,
      13.073,
      83.784,
      85.69,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6955",
    "polygon": [
      84.495,
      85.452,
      13.073,
      84.495,
      85.452,
      15.523,
      86.021,
      84.941,
      15.523,
      86.021,
      84.941,
      15.523,
      86.021,
      84.941,
      13.073,
      84.495,
      85.452,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6956",
    "polygon": [
      75.003,
      88.632,
      13.073,
      75.003,
      88.632,
      15.523,
      75.714,
      88.394,
      15.523,
      75.714,
      88.394,
      15.523,
      75.714,
      88.394,
      13.073,
      75.003,
      88.632,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6957",
    "polygon": [
      75.714,
      88.394,
      13.073,
      75.714,
      88.394,
      15.523,
      77.241,
      87.882,
      15.523,
      77.241,
      87.882,
      15.523,
      77.241,
      87.882,
      13.073,
      75.714,
      88.394,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6958",
    "polygon": [
      77.241,
      87.882,
      13.073,
      77.241,
      87.882,
      15.523,
      77.834,
      87.684,
      15.523,
      77.834,
      87.684,
      15.523,
      77.834,
      87.684,
      13.073,
      77.241,
      87.882,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6959",
    "polygon": [
      77.834,
      87.684,
      13.073,
      77.834,
      87.684,
      15.523,
      78.545,
      87.445,
      15.523,
      78.545,
      87.445,
      15.523,
      78.545,
      87.445,
      13.073,
      77.834,
      87.684,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6960",
    "polygon": [
      78.545,
      87.445,
      13.073,
      78.545,
      87.445,
      15.523,
      80.071,
      86.934,
      15.523,
      80.071,
      86.934,
      15.523,
      80.071,
      86.934,
      13.073,
      78.545,
      87.445,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6961",
    "polygon": [
      69.053,
      90.625,
      13.073,
      69.053,
      90.625,
      15.523,
      69.765,
      90.387,
      15.523,
      69.765,
      90.387,
      15.523,
      69.765,
      90.387,
      13.073,
      69.053,
      90.625,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6962",
    "polygon": [
      69.765,
      90.387,
      13.073,
      69.765,
      90.387,
      15.523,
      71.291,
      89.875,
      15.523,
      71.291,
      89.875,
      15.523,
      71.291,
      89.875,
      13.073,
      69.765,
      90.387,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6963",
    "polygon": [
      71.291,
      89.875,
      13.073,
      71.291,
      89.875,
      15.523,
      71.884,
      89.677,
      15.523,
      71.884,
      89.677,
      15.523,
      71.884,
      89.677,
      13.073,
      71.291,
      89.875,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6964",
    "polygon": [
      71.884,
      89.677,
      13.073,
      71.884,
      89.677,
      15.523,
      72.595,
      89.439,
      15.523,
      72.595,
      89.439,
      15.523,
      72.595,
      89.439,
      13.073,
      71.884,
      89.677,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6965",
    "polygon": [
      72.595,
      89.439,
      13.073,
      72.595,
      89.439,
      15.523,
      74.122,
      88.927,
      15.523,
      74.122,
      88.927,
      15.523,
      74.122,
      88.927,
      13.073,
      72.595,
      89.439,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6966",
    "polygon": [
      63.103,
      92.618,
      13.073,
      63.103,
      92.618,
      15.523,
      63.815,
      92.38,
      15.523,
      63.815,
      92.38,
      15.523,
      63.815,
      92.38,
      13.073,
      63.103,
      92.618,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6967",
    "polygon": [
      63.815,
      92.38,
      13.073,
      63.815,
      92.38,
      15.523,
      65.341,
      91.868,
      15.523,
      65.341,
      91.868,
      15.523,
      65.341,
      91.868,
      13.073,
      63.815,
      92.38,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6968",
    "polygon": [
      65.341,
      91.868,
      13.073,
      65.341,
      91.868,
      15.523,
      65.934,
      91.67,
      15.523,
      65.934,
      91.67,
      15.523,
      65.934,
      91.67,
      13.073,
      65.341,
      91.868,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6969",
    "polygon": [
      65.934,
      91.67,
      13.073,
      65.934,
      91.67,
      15.523,
      66.645,
      91.432,
      15.523,
      66.645,
      91.432,
      15.523,
      66.645,
      91.432,
      13.073,
      65.934,
      91.67,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6970",
    "polygon": [
      66.645,
      91.432,
      13.073,
      66.645,
      91.432,
      15.523,
      68.172,
      90.92,
      15.523,
      68.172,
      90.92,
      15.523,
      68.172,
      90.92,
      13.073,
      66.645,
      91.432,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6971",
    "polygon": [
      57.154,
      94.611,
      13.073,
      57.154,
      94.611,
      15.523,
      57.865,
      94.373,
      15.523,
      57.865,
      94.373,
      15.523,
      57.865,
      94.373,
      13.073,
      57.154,
      94.611,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6972",
    "polygon": [
      57.865,
      94.373,
      13.073,
      57.865,
      94.373,
      15.523,
      59.391,
      93.862,
      15.523,
      59.391,
      93.862,
      15.523,
      59.391,
      93.862,
      13.073,
      57.865,
      94.373,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6973",
    "polygon": [
      59.391,
      93.862,
      13.073,
      59.391,
      93.862,
      15.523,
      59.984,
      93.663,
      15.523,
      59.984,
      93.663,
      15.523,
      59.984,
      93.663,
      13.073,
      59.391,
      93.862,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6974",
    "polygon": [
      59.984,
      93.663,
      13.073,
      59.984,
      93.663,
      15.523,
      60.695,
      93.425,
      15.523,
      60.695,
      93.425,
      15.523,
      60.695,
      93.425,
      13.073,
      59.984,
      93.663,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6975",
    "polygon": [
      60.695,
      93.425,
      13.073,
      60.695,
      93.425,
      15.523,
      62.222,
      92.913,
      15.523,
      62.222,
      92.913,
      15.523,
      62.222,
      92.913,
      13.073,
      60.695,
      93.425,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6976",
    "polygon": [
      45.329,
      98.572,
      13.073,
      45.329,
      98.572,
      15.523,
      46.041,
      98.334,
      15.523,
      46.041,
      98.334,
      15.523,
      46.041,
      98.334,
      13.073,
      45.329,
      98.572,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6977",
    "polygon": [
      46.041,
      98.334,
      13.073,
      46.041,
      98.334,
      15.523,
      47.567,
      97.822,
      15.523,
      47.567,
      97.822,
      15.523,
      47.567,
      97.822,
      13.073,
      46.041,
      98.334,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6978",
    "polygon": [
      47.567,
      97.822,
      13.073,
      47.567,
      97.822,
      15.523,
      48.16,
      97.624,
      15.523,
      48.16,
      97.624,
      15.523,
      48.16,
      97.624,
      13.073,
      47.567,
      97.822,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6979",
    "polygon": [
      48.16,
      97.624,
      13.073,
      48.16,
      97.624,
      15.523,
      48.871,
      97.386,
      15.523,
      48.871,
      97.386,
      15.523,
      48.871,
      97.386,
      13.073,
      48.16,
      97.624,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6980",
    "polygon": [
      48.871,
      97.386,
      13.073,
      48.871,
      97.386,
      15.523,
      50.398,
      96.874,
      15.523,
      50.398,
      96.874,
      15.523,
      50.398,
      96.874,
      13.073,
      48.871,
      97.386,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6981",
    "polygon": [
      39.426,
      100.55,
      13.073,
      39.426,
      100.55,
      15.523,
      40.137,
      100.312,
      15.523,
      40.137,
      100.312,
      15.523,
      40.137,
      100.312,
      13.073,
      39.426,
      100.55,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6982",
    "polygon": [
      40.137,
      100.312,
      13.073,
      40.137,
      100.312,
      15.523,
      41.663,
      99.8,
      15.523,
      41.663,
      99.8,
      15.523,
      41.663,
      99.8,
      13.073,
      40.137,
      100.312,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6983",
    "polygon": [
      41.663,
      99.8,
      13.073,
      41.663,
      99.8,
      15.523,
      42.256,
      99.602,
      15.523,
      42.256,
      99.602,
      15.523,
      42.256,
      99.602,
      13.073,
      41.663,
      99.8,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6984",
    "polygon": [
      42.256,
      99.602,
      13.073,
      42.256,
      99.602,
      15.523,
      42.967,
      99.363,
      15.523,
      42.967,
      99.363,
      15.523,
      42.967,
      99.363,
      13.073,
      42.256,
      99.602,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-B-Outer_3.OG_6985",
    "polygon": [
      42.967,
      99.363,
      13.073,
      42.967,
      99.363,
      15.523,
      44.494,
      98.852,
      15.523,
      44.494,
      98.852,
      15.523,
      44.494,
      98.852,
      13.073,
      42.967,
      99.363,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_6986",
    "polygon": [
      7.473,
      111.257,
      0.695,
      7.473,
      111.257,
      3.405,
      8.184,
      111.019,
      3.405,
      8.184,
      111.019,
      3.405,
      8.184,
      111.019,
      0.695,
      7.473,
      111.257,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_6987",
    "polygon": [
      8.184,
      111.019,
      0.695,
      8.184,
      111.019,
      3.405,
      9.71,
      110.508,
      3.405,
      9.71,
      110.508,
      3.405,
      9.71,
      110.508,
      0.695,
      8.184,
      111.019,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_6988",
    "polygon": [
      9.71,
      110.508,
      0.695,
      9.71,
      110.508,
      3.405,
      10.298,
      110.311,
      3.405,
      10.298,
      110.311,
      3.405,
      10.298,
      110.311,
      0.695,
      9.71,
      110.508,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_6989",
    "polygon": [
      10.298,
      110.311,
      0.695,
      10.298,
      110.311,
      3.405,
      11.009,
      110.073,
      3.405,
      11.009,
      110.073,
      3.405,
      11.009,
      110.073,
      0.695,
      10.298,
      110.311,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_6990",
    "polygon": [
      11.009,
      110.073,
      0.695,
      11.009,
      110.073,
      3.405,
      12.536,
      109.561,
      3.405,
      12.536,
      109.561,
      3.405,
      12.536,
      109.561,
      0.695,
      11.009,
      110.073,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_6991",
    "polygon": [
      7.468,
      111.258,
      5.573,
      7.468,
      111.258,
      8.023,
      8.179,
      111.02,
      8.023,
      8.179,
      111.02,
      8.023,
      8.179,
      111.02,
      5.573,
      7.468,
      111.258,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_6992",
    "polygon": [
      8.179,
      111.02,
      5.573,
      8.179,
      111.02,
      8.023,
      9.706,
      110.509,
      8.023,
      9.706,
      110.509,
      8.023,
      9.706,
      110.509,
      5.573,
      8.179,
      111.02,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_6993",
    "polygon": [
      9.706,
      110.509,
      5.573,
      9.706,
      110.509,
      8.023,
      10.298,
      110.31,
      8.023,
      10.298,
      110.31,
      8.023,
      10.298,
      110.31,
      5.573,
      9.706,
      110.509,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_6994",
    "polygon": [
      10.298,
      110.31,
      5.573,
      10.298,
      110.31,
      8.023,
      11.009,
      110.072,
      8.023,
      11.009,
      110.072,
      8.023,
      11.009,
      110.072,
      5.573,
      10.298,
      110.31,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_6995",
    "polygon": [
      11.009,
      110.072,
      5.573,
      11.009,
      110.072,
      8.023,
      12.536,
      109.561,
      8.023,
      12.536,
      109.561,
      8.023,
      12.536,
      109.561,
      5.573,
      11.009,
      110.072,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_6996",
    "polygon": [
      1.523,
      113.25,
      0.695,
      1.523,
      113.25,
      3.405,
      2.234,
      113.012,
      3.405,
      2.234,
      113.012,
      3.405,
      2.234,
      113.012,
      0.695,
      1.523,
      113.25,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_6997",
    "polygon": [
      2.234,
      113.012,
      0.695,
      2.234,
      113.012,
      3.405,
      3.76,
      112.501,
      3.405,
      3.76,
      112.501,
      3.405,
      3.76,
      112.501,
      0.695,
      2.234,
      113.012,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_6998",
    "polygon": [
      3.76,
      112.501,
      0.695,
      3.76,
      112.501,
      3.405,
      4.348,
      112.304,
      3.405,
      4.348,
      112.304,
      3.405,
      4.348,
      112.304,
      0.695,
      3.76,
      112.501,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_6999",
    "polygon": [
      4.348,
      112.304,
      0.695,
      4.348,
      112.304,
      3.405,
      5.059,
      112.066,
      3.405,
      5.059,
      112.066,
      3.405,
      5.059,
      112.066,
      0.695,
      4.348,
      112.304,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7000",
    "polygon": [
      5.059,
      112.066,
      0.695,
      5.059,
      112.066,
      3.405,
      6.586,
      111.554,
      3.405,
      6.586,
      111.554,
      3.405,
      6.586,
      111.554,
      0.695,
      5.059,
      112.066,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7001",
    "polygon": [
      1.518,
      113.252,
      5.573,
      1.518,
      113.252,
      8.023,
      2.229,
      113.013,
      8.023,
      2.229,
      113.013,
      8.023,
      2.229,
      113.013,
      5.573,
      1.518,
      113.252,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7002",
    "polygon": [
      2.229,
      113.013,
      5.573,
      2.229,
      113.013,
      8.023,
      3.756,
      112.502,
      8.023,
      3.756,
      112.502,
      8.023,
      3.756,
      112.502,
      5.573,
      2.229,
      113.013,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7003",
    "polygon": [
      3.756,
      112.502,
      5.573,
      3.756,
      112.502,
      8.023,
      4.348,
      112.303,
      8.023,
      4.348,
      112.303,
      8.023,
      4.348,
      112.303,
      5.573,
      3.756,
      112.502,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7004",
    "polygon": [
      4.348,
      112.303,
      5.573,
      4.348,
      112.303,
      8.023,
      5.059,
      112.065,
      8.023,
      5.059,
      112.065,
      8.023,
      5.059,
      112.065,
      5.573,
      4.348,
      112.303,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7005",
    "polygon": [
      5.059,
      112.065,
      5.573,
      5.059,
      112.065,
      8.023,
      6.586,
      111.554,
      8.023,
      6.586,
      111.554,
      8.023,
      6.586,
      111.554,
      5.573,
      5.059,
      112.065,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7006",
    "polygon": [
      -6.365,
      115.893,
      0.013,
      -6.365,
      115.893,
      2.313,
      -5.374,
      115.561,
      2.313,
      -5.374,
      115.561,
      2.313,
      -5.374,
      115.561,
      0.013,
      -6.365,
      115.893,
      0.013
    ],
    "05-22 03:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_7007",
    "polygon": [
      -7.354,
      116.224,
      0.013,
      -7.354,
      116.224,
      2.313,
      -6.363,
      115.892,
      2.313,
      -6.363,
      115.892,
      2.313,
      -6.363,
      115.892,
      0.013,
      -7.354,
      116.224,
      0.013
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7008",
    "polygon": [
      -7.354,
      116.224,
      2.42,
      -7.354,
      116.224,
      3.41,
      -5.373,
      115.56,
      3.41,
      -5.373,
      115.56,
      3.41,
      -5.373,
      115.56,
      2.42,
      -7.354,
      116.224,
      2.42
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1
  },
  {
    "ID": "Block-A-Outer_EG_7009",
    "polygon": [
      -16.298,
      119.22,
      0.695,
      -16.298,
      119.22,
      3.405,
      -15.587,
      118.982,
      3.405,
      -15.587,
      118.982,
      3.405,
      -15.587,
      118.982,
      0.695,
      -16.298,
      119.22,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_7010",
    "polygon": [
      -15.587,
      118.982,
      0.695,
      -15.587,
      118.982,
      3.405,
      -14.06,
      118.471,
      3.405,
      -14.06,
      118.471,
      3.405,
      -14.06,
      118.471,
      0.695,
      -15.587,
      118.982,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_7011",
    "polygon": [
      -14.06,
      118.471,
      0.695,
      -14.06,
      118.471,
      3.405,
      -13.473,
      118.274,
      3.405,
      -13.473,
      118.274,
      3.405,
      -13.473,
      118.274,
      0.695,
      -14.06,
      118.471,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7012",
    "polygon": [
      -13.473,
      118.274,
      0.695,
      -13.473,
      118.274,
      3.405,
      -12.761,
      118.035,
      3.405,
      -12.761,
      118.035,
      3.405,
      -12.761,
      118.035,
      0.695,
      -13.473,
      118.274,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7013",
    "polygon": [
      -12.761,
      118.035,
      0.695,
      -12.761,
      118.035,
      3.405,
      -11.235,
      117.524,
      3.405,
      -11.235,
      117.524,
      3.405,
      -11.235,
      117.524,
      0.695,
      -12.761,
      118.035,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7014",
    "polygon": [
      -22.248,
      121.213,
      0.695,
      -22.248,
      121.213,
      3.405,
      -21.537,
      120.975,
      3.405,
      -21.537,
      120.975,
      3.405,
      -21.537,
      120.975,
      0.695,
      -22.248,
      121.213,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_7015",
    "polygon": [
      -21.537,
      120.975,
      0.695,
      -21.537,
      120.975,
      3.405,
      -20.01,
      120.464,
      3.405,
      -20.01,
      120.464,
      3.405,
      -20.01,
      120.464,
      0.695,
      -21.537,
      120.975,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_7016",
    "polygon": [
      -20.01,
      120.464,
      0.695,
      -20.01,
      120.464,
      3.405,
      -19.423,
      120.267,
      3.405,
      -19.423,
      120.267,
      3.405,
      -19.423,
      120.267,
      0.695,
      -20.01,
      120.464,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7017",
    "polygon": [
      -19.423,
      120.267,
      0.695,
      -19.423,
      120.267,
      3.405,
      -18.711,
      120.029,
      3.405,
      -18.711,
      120.029,
      3.405,
      -18.711,
      120.029,
      0.695,
      -19.423,
      120.267,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7018",
    "polygon": [
      -18.711,
      120.029,
      0.695,
      -18.711,
      120.029,
      3.405,
      -17.185,
      119.517,
      3.405,
      -17.185,
      119.517,
      3.405,
      -17.185,
      119.517,
      0.695,
      -18.711,
      120.029,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7019",
    "polygon": [
      -28.198,
      123.206,
      0.695,
      -28.198,
      123.206,
      3.405,
      -27.487,
      122.968,
      3.405,
      -27.487,
      122.968,
      3.405,
      -27.487,
      122.968,
      0.695,
      -28.198,
      123.206,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_7020",
    "polygon": [
      -27.487,
      122.968,
      0.695,
      -27.487,
      122.968,
      3.405,
      -25.96,
      122.457,
      3.405,
      -25.96,
      122.457,
      3.405,
      -25.96,
      122.457,
      0.695,
      -27.487,
      122.968,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_7021",
    "polygon": [
      -25.96,
      122.457,
      0.695,
      -25.96,
      122.457,
      3.405,
      -25.372,
      122.26,
      3.405,
      -25.372,
      122.26,
      3.405,
      -25.372,
      122.26,
      0.695,
      -25.96,
      122.457,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7022",
    "polygon": [
      -25.372,
      122.26,
      0.695,
      -25.372,
      122.26,
      3.405,
      -24.661,
      122.022,
      3.405,
      -24.661,
      122.022,
      3.405,
      -24.661,
      122.022,
      0.695,
      -25.372,
      122.26,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7023",
    "polygon": [
      -24.661,
      122.022,
      0.695,
      -24.661,
      122.022,
      3.405,
      -23.135,
      121.51,
      3.405,
      -23.135,
      121.51,
      3.405,
      -23.135,
      121.51,
      0.695,
      -24.661,
      122.022,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7024",
    "polygon": [
      -34.148,
      125.2,
      0.695,
      -34.148,
      125.2,
      3.405,
      -33.437,
      124.961,
      3.405,
      -33.437,
      124.961,
      3.405,
      -33.437,
      124.961,
      0.695,
      -34.148,
      125.2,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_7025",
    "polygon": [
      -33.437,
      124.961,
      0.695,
      -33.437,
      124.961,
      3.405,
      -31.91,
      124.45,
      3.405,
      -31.91,
      124.45,
      3.405,
      -31.91,
      124.45,
      0.695,
      -33.437,
      124.961,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_7026",
    "polygon": [
      -31.91,
      124.45,
      0.695,
      -31.91,
      124.45,
      3.405,
      -31.322,
      124.253,
      3.405,
      -31.322,
      124.253,
      3.405,
      -31.322,
      124.253,
      0.695,
      -31.91,
      124.45,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7027",
    "polygon": [
      -31.322,
      124.253,
      0.695,
      -31.322,
      124.253,
      3.405,
      -30.611,
      124.015,
      3.405,
      -30.611,
      124.015,
      3.405,
      -30.611,
      124.015,
      0.695,
      -31.322,
      124.253,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_7028",
    "polygon": [
      -30.611,
      124.015,
      0.695,
      -30.611,
      124.015,
      3.405,
      -29.085,
      123.503,
      3.405,
      -29.085,
      123.503,
      3.405,
      -29.085,
      123.503,
      0.695,
      -30.611,
      124.015,
      0.695
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7029",
    "polygon": [
      -16.303,
      119.221,
      5.573,
      -16.303,
      119.221,
      8.023,
      -15.592,
      118.983,
      8.023,
      -15.592,
      118.983,
      8.023,
      -15.592,
      118.983,
      5.573,
      -16.303,
      119.221,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7030",
    "polygon": [
      -15.592,
      118.983,
      5.573,
      -15.592,
      118.983,
      8.023,
      -14.065,
      118.472,
      8.023,
      -14.065,
      118.472,
      8.023,
      -14.065,
      118.472,
      5.573,
      -15.592,
      118.983,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7031",
    "polygon": [
      -14.065,
      118.472,
      5.573,
      -14.065,
      118.472,
      8.023,
      -13.473,
      118.273,
      8.023,
      -13.473,
      118.273,
      8.023,
      -13.473,
      118.273,
      5.573,
      -14.065,
      118.472,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7032",
    "polygon": [
      -13.473,
      118.273,
      5.573,
      -13.473,
      118.273,
      8.023,
      -12.762,
      118.035,
      8.023,
      -12.762,
      118.035,
      8.023,
      -12.762,
      118.035,
      5.573,
      -13.473,
      118.273,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7033",
    "polygon": [
      -12.762,
      118.035,
      5.573,
      -12.762,
      118.035,
      8.023,
      -11.235,
      117.524,
      8.023,
      -11.235,
      117.524,
      8.023,
      -11.235,
      117.524,
      5.573,
      -12.762,
      118.035,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7034",
    "polygon": [
      -22.253,
      121.215,
      5.573,
      -22.253,
      121.215,
      8.023,
      -21.542,
      120.976,
      8.023,
      -21.542,
      120.976,
      8.023,
      -21.542,
      120.976,
      5.573,
      -22.253,
      121.215,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7035",
    "polygon": [
      -21.542,
      120.976,
      5.573,
      -21.542,
      120.976,
      8.023,
      -20.015,
      120.465,
      8.023,
      -20.015,
      120.465,
      8.023,
      -20.015,
      120.465,
      5.573,
      -21.542,
      120.976,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7036",
    "polygon": [
      -20.015,
      120.465,
      5.573,
      -20.015,
      120.465,
      8.023,
      -19.423,
      120.266,
      8.023,
      -19.423,
      120.266,
      8.023,
      -19.423,
      120.266,
      5.573,
      -20.015,
      120.465,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7037",
    "polygon": [
      -19.423,
      120.266,
      5.573,
      -19.423,
      120.266,
      8.023,
      -18.711,
      120.028,
      8.023,
      -18.711,
      120.028,
      8.023,
      -18.711,
      120.028,
      5.573,
      -19.423,
      120.266,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7038",
    "polygon": [
      -18.711,
      120.028,
      5.573,
      -18.711,
      120.028,
      8.023,
      -17.185,
      119.517,
      8.023,
      -17.185,
      119.517,
      8.023,
      -17.185,
      119.517,
      5.573,
      -18.711,
      120.028,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7039",
    "polygon": [
      -28.203,
      123.208,
      5.573,
      -28.203,
      123.208,
      8.023,
      -27.492,
      122.969,
      8.023,
      -27.492,
      122.969,
      8.023,
      -27.492,
      122.969,
      5.573,
      -28.203,
      123.208,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7040",
    "polygon": [
      -27.492,
      122.969,
      5.573,
      -27.492,
      122.969,
      8.023,
      -25.965,
      122.458,
      8.023,
      -25.965,
      122.458,
      8.023,
      -25.965,
      122.458,
      5.573,
      -27.492,
      122.969,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7041",
    "polygon": [
      -25.965,
      122.458,
      5.573,
      -25.965,
      122.458,
      8.023,
      -25.373,
      122.26,
      8.023,
      -25.373,
      122.26,
      8.023,
      -25.373,
      122.26,
      5.573,
      -25.965,
      122.458,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7042",
    "polygon": [
      -25.373,
      122.26,
      5.573,
      -25.373,
      122.26,
      8.023,
      -24.661,
      122.021,
      8.023,
      -24.661,
      122.021,
      8.023,
      -24.661,
      122.021,
      5.573,
      -25.373,
      122.26,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7043",
    "polygon": [
      -24.661,
      122.021,
      5.573,
      -24.661,
      122.021,
      8.023,
      -23.135,
      121.51,
      8.023,
      -23.135,
      121.51,
      8.023,
      -23.135,
      121.51,
      5.573,
      -24.661,
      122.021,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7044",
    "polygon": [
      -34.153,
      125.201,
      5.573,
      -34.153,
      125.201,
      8.023,
      -33.442,
      124.963,
      8.023,
      -33.442,
      124.963,
      8.023,
      -33.442,
      124.963,
      5.573,
      -34.153,
      125.201,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7045",
    "polygon": [
      -33.442,
      124.963,
      5.573,
      -33.442,
      124.963,
      8.023,
      -31.915,
      124.451,
      8.023,
      -31.915,
      124.451,
      8.023,
      -31.915,
      124.451,
      5.573,
      -33.442,
      124.963,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7046",
    "polygon": [
      -31.915,
      124.451,
      5.573,
      -31.915,
      124.451,
      8.023,
      -31.322,
      124.253,
      8.023,
      -31.322,
      124.253,
      8.023,
      -31.322,
      124.253,
      5.573,
      -31.915,
      124.451,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7047",
    "polygon": [
      -31.322,
      124.253,
      5.573,
      -31.322,
      124.253,
      8.023,
      -30.611,
      124.014,
      8.023,
      -30.611,
      124.014,
      8.023,
      -30.611,
      124.014,
      5.573,
      -31.322,
      124.253,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7048",
    "polygon": [
      -30.611,
      124.014,
      5.573,
      -30.611,
      124.014,
      8.023,
      -29.085,
      123.503,
      8.023,
      -29.085,
      123.503,
      8.023,
      -29.085,
      123.503,
      5.573,
      -30.611,
      124.014,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7049",
    "polygon": [
      7.467,
      111.256,
      9.323,
      7.467,
      111.256,
      11.773,
      8.178,
      111.017,
      11.773,
      8.178,
      111.017,
      11.773,
      8.178,
      111.017,
      9.323,
      7.467,
      111.256,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7050",
    "polygon": [
      8.178,
      111.017,
      9.323,
      8.178,
      111.017,
      11.773,
      9.705,
      110.506,
      11.773,
      9.705,
      110.506,
      11.773,
      9.705,
      110.506,
      9.323,
      8.178,
      111.017,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7051",
    "polygon": [
      9.705,
      110.506,
      9.323,
      9.705,
      110.506,
      11.773,
      10.297,
      110.307,
      11.773,
      10.297,
      110.307,
      11.773,
      10.297,
      110.307,
      9.323,
      9.705,
      110.506,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7052",
    "polygon": [
      10.297,
      110.307,
      9.323,
      10.297,
      110.307,
      11.773,
      11.008,
      110.069,
      11.773,
      11.008,
      110.069,
      11.773,
      11.008,
      110.069,
      9.323,
      10.297,
      110.307,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7053",
    "polygon": [
      11.008,
      110.069,
      9.323,
      11.008,
      110.069,
      11.773,
      12.535,
      109.558,
      11.773,
      12.535,
      109.558,
      11.773,
      12.535,
      109.558,
      9.323,
      11.008,
      110.069,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7054",
    "polygon": [
      1.517,
      113.249,
      9.323,
      1.517,
      113.249,
      11.773,
      2.228,
      113.01,
      11.773,
      2.228,
      113.01,
      11.773,
      2.228,
      113.01,
      9.323,
      1.517,
      113.249,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7055",
    "polygon": [
      2.228,
      113.01,
      9.323,
      2.228,
      113.01,
      11.773,
      3.755,
      112.499,
      11.773,
      3.755,
      112.499,
      11.773,
      3.755,
      112.499,
      9.323,
      2.228,
      113.01,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7056",
    "polygon": [
      3.755,
      112.499,
      9.323,
      3.755,
      112.499,
      11.773,
      4.347,
      112.301,
      11.773,
      4.347,
      112.301,
      11.773,
      4.347,
      112.301,
      9.323,
      3.755,
      112.499,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7057",
    "polygon": [
      4.347,
      112.301,
      9.323,
      4.347,
      112.301,
      11.773,
      5.058,
      112.062,
      11.773,
      5.058,
      112.062,
      11.773,
      5.058,
      112.062,
      9.323,
      4.347,
      112.301,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7058",
    "polygon": [
      5.058,
      112.062,
      9.323,
      5.058,
      112.062,
      11.773,
      6.585,
      111.551,
      11.773,
      6.585,
      111.551,
      11.773,
      6.585,
      111.551,
      9.323,
      5.058,
      112.062,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7059",
    "polygon": [
      -16.304,
      119.218,
      9.323,
      -16.304,
      119.218,
      11.773,
      -15.593,
      118.98,
      11.773,
      -15.593,
      118.98,
      11.773,
      -15.593,
      118.98,
      9.323,
      -16.304,
      119.218,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7060",
    "polygon": [
      -15.593,
      118.98,
      9.323,
      -15.593,
      118.98,
      11.773,
      -14.066,
      118.469,
      11.773,
      -14.066,
      118.469,
      11.773,
      -14.066,
      118.469,
      9.323,
      -15.593,
      118.98,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7061",
    "polygon": [
      -14.066,
      118.469,
      9.323,
      -14.066,
      118.469,
      11.773,
      -13.474,
      118.27,
      11.773,
      -13.474,
      118.27,
      11.773,
      -13.474,
      118.27,
      9.323,
      -14.066,
      118.469,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7062",
    "polygon": [
      -13.474,
      118.27,
      9.323,
      -13.474,
      118.27,
      11.773,
      -12.763,
      118.032,
      11.773,
      -12.763,
      118.032,
      11.773,
      -12.763,
      118.032,
      9.323,
      -13.474,
      118.27,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7063",
    "polygon": [
      -12.763,
      118.032,
      9.323,
      -12.763,
      118.032,
      11.773,
      -11.236,
      117.521,
      11.773,
      -11.236,
      117.521,
      11.773,
      -11.236,
      117.521,
      9.323,
      -12.763,
      118.032,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7064",
    "polygon": [
      -22.254,
      121.212,
      9.323,
      -22.254,
      121.212,
      11.773,
      -21.543,
      120.973,
      11.773,
      -21.543,
      120.973,
      11.773,
      -21.543,
      120.973,
      9.323,
      -22.254,
      121.212,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7065",
    "polygon": [
      -21.543,
      120.973,
      9.323,
      -21.543,
      120.973,
      11.773,
      -20.016,
      120.462,
      11.773,
      -20.016,
      120.462,
      11.773,
      -20.016,
      120.462,
      9.323,
      -21.543,
      120.973,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7066",
    "polygon": [
      -20.016,
      120.462,
      9.323,
      -20.016,
      120.462,
      11.773,
      -19.424,
      120.263,
      11.773,
      -19.424,
      120.263,
      11.773,
      -19.424,
      120.263,
      9.323,
      -20.016,
      120.462,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7067",
    "polygon": [
      -19.424,
      120.263,
      9.323,
      -19.424,
      120.263,
      11.773,
      -18.712,
      120.025,
      11.773,
      -18.712,
      120.025,
      11.773,
      -18.712,
      120.025,
      9.323,
      -19.424,
      120.263,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7068",
    "polygon": [
      -18.712,
      120.025,
      9.323,
      -18.712,
      120.025,
      11.773,
      -17.186,
      119.514,
      11.773,
      -17.186,
      119.514,
      11.773,
      -17.186,
      119.514,
      9.323,
      -18.712,
      120.025,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7069",
    "polygon": [
      -28.204,
      123.205,
      9.323,
      -28.204,
      123.205,
      11.773,
      -27.493,
      122.967,
      11.773,
      -27.493,
      122.967,
      11.773,
      -27.493,
      122.967,
      9.323,
      -28.204,
      123.205,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7070",
    "polygon": [
      -27.493,
      122.967,
      9.323,
      -27.493,
      122.967,
      11.773,
      -25.966,
      122.455,
      11.773,
      -25.966,
      122.455,
      11.773,
      -25.966,
      122.455,
      9.323,
      -27.493,
      122.967,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7071",
    "polygon": [
      -25.966,
      122.455,
      9.323,
      -25.966,
      122.455,
      11.773,
      -25.374,
      122.257,
      11.773,
      -25.374,
      122.257,
      11.773,
      -25.374,
      122.257,
      9.323,
      -25.966,
      122.455,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7072",
    "polygon": [
      -25.374,
      122.257,
      9.323,
      -25.374,
      122.257,
      11.773,
      -24.662,
      122.018,
      11.773,
      -24.662,
      122.018,
      11.773,
      -24.662,
      122.018,
      9.323,
      -25.374,
      122.257,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7073",
    "polygon": [
      -24.662,
      122.018,
      9.323,
      -24.662,
      122.018,
      11.773,
      -23.136,
      121.507,
      11.773,
      -23.136,
      121.507,
      11.773,
      -23.136,
      121.507,
      9.323,
      -24.662,
      122.018,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7074",
    "polygon": [
      -34.154,
      125.198,
      9.323,
      -34.154,
      125.198,
      11.773,
      -33.443,
      124.96,
      11.773,
      -33.443,
      124.96,
      11.773,
      -33.443,
      124.96,
      9.323,
      -34.154,
      125.198,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7075",
    "polygon": [
      -33.443,
      124.96,
      9.323,
      -33.443,
      124.96,
      11.773,
      -31.916,
      124.448,
      11.773,
      -31.916,
      124.448,
      11.773,
      -31.916,
      124.448,
      9.323,
      -33.443,
      124.96,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7076",
    "polygon": [
      -31.916,
      124.448,
      9.323,
      -31.916,
      124.448,
      11.773,
      -31.323,
      124.25,
      11.773,
      -31.323,
      124.25,
      11.773,
      -31.323,
      124.25,
      9.323,
      -31.916,
      124.448,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7077",
    "polygon": [
      -31.323,
      124.25,
      9.323,
      -31.323,
      124.25,
      11.773,
      -30.612,
      124.011,
      11.773,
      -30.612,
      124.011,
      11.773,
      -30.612,
      124.011,
      9.323,
      -31.323,
      124.25,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7078",
    "polygon": [
      -30.612,
      124.011,
      9.323,
      -30.612,
      124.011,
      11.773,
      -29.086,
      123.5,
      11.773,
      -29.086,
      123.5,
      11.773,
      -29.086,
      123.5,
      9.323,
      -30.612,
      124.011,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7079",
    "polygon": [
      7.467,
      111.256,
      13.073,
      7.467,
      111.256,
      15.523,
      8.178,
      111.017,
      15.523,
      8.178,
      111.017,
      15.523,
      8.178,
      111.017,
      13.073,
      7.467,
      111.256,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7080",
    "polygon": [
      8.178,
      111.017,
      13.073,
      8.178,
      111.017,
      15.523,
      9.705,
      110.506,
      15.523,
      9.705,
      110.506,
      15.523,
      9.705,
      110.506,
      13.073,
      8.178,
      111.017,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7081",
    "polygon": [
      9.705,
      110.506,
      13.073,
      9.705,
      110.506,
      15.523,
      10.297,
      110.307,
      15.523,
      10.297,
      110.307,
      15.523,
      10.297,
      110.307,
      13.073,
      9.705,
      110.506,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7082",
    "polygon": [
      10.297,
      110.307,
      13.073,
      10.297,
      110.307,
      15.523,
      11.008,
      110.069,
      15.523,
      11.008,
      110.069,
      15.523,
      11.008,
      110.069,
      13.073,
      10.297,
      110.307,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7083",
    "polygon": [
      11.008,
      110.069,
      13.073,
      11.008,
      110.069,
      15.523,
      12.535,
      109.558,
      15.523,
      12.535,
      109.558,
      15.523,
      12.535,
      109.558,
      13.073,
      11.008,
      110.069,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7084",
    "polygon": [
      1.517,
      113.249,
      13.073,
      1.517,
      113.249,
      15.523,
      2.228,
      113.01,
      15.523,
      2.228,
      113.01,
      15.523,
      2.228,
      113.01,
      13.073,
      1.517,
      113.249,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7085",
    "polygon": [
      2.228,
      113.01,
      13.073,
      2.228,
      113.01,
      15.523,
      3.755,
      112.499,
      15.523,
      3.755,
      112.499,
      15.523,
      3.755,
      112.499,
      13.073,
      2.228,
      113.01,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7086",
    "polygon": [
      3.755,
      112.499,
      13.073,
      3.755,
      112.499,
      15.523,
      4.347,
      112.301,
      15.523,
      4.347,
      112.301,
      15.523,
      4.347,
      112.301,
      13.073,
      3.755,
      112.499,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7087",
    "polygon": [
      4.347,
      112.301,
      13.073,
      4.347,
      112.301,
      15.523,
      5.058,
      112.062,
      15.523,
      5.058,
      112.062,
      15.523,
      5.058,
      112.062,
      13.073,
      4.347,
      112.301,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7088",
    "polygon": [
      5.058,
      112.062,
      13.073,
      5.058,
      112.062,
      15.523,
      6.585,
      111.551,
      15.523,
      6.585,
      111.551,
      15.523,
      6.585,
      111.551,
      13.073,
      5.058,
      112.062,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7089",
    "polygon": [
      -16.304,
      119.218,
      13.073,
      -16.304,
      119.218,
      15.523,
      -15.593,
      118.98,
      15.523,
      -15.593,
      118.98,
      15.523,
      -15.593,
      118.98,
      13.073,
      -16.304,
      119.218,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7090",
    "polygon": [
      -15.593,
      118.98,
      13.073,
      -15.593,
      118.98,
      15.523,
      -14.066,
      118.469,
      15.523,
      -14.066,
      118.469,
      15.523,
      -14.066,
      118.469,
      13.073,
      -15.593,
      118.98,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7091",
    "polygon": [
      -14.066,
      118.469,
      13.073,
      -14.066,
      118.469,
      15.523,
      -13.474,
      118.27,
      15.523,
      -13.474,
      118.27,
      15.523,
      -13.474,
      118.27,
      13.073,
      -14.066,
      118.469,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7092",
    "polygon": [
      -13.474,
      118.27,
      13.073,
      -13.474,
      118.27,
      15.523,
      -12.763,
      118.032,
      15.523,
      -12.763,
      118.032,
      15.523,
      -12.763,
      118.032,
      13.073,
      -13.474,
      118.27,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7093",
    "polygon": [
      -12.763,
      118.032,
      13.073,
      -12.763,
      118.032,
      15.523,
      -11.236,
      117.521,
      15.523,
      -11.236,
      117.521,
      15.523,
      -11.236,
      117.521,
      13.073,
      -12.763,
      118.032,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7094",
    "polygon": [
      -22.254,
      121.212,
      13.073,
      -22.254,
      121.212,
      15.523,
      -21.543,
      120.973,
      15.523,
      -21.543,
      120.973,
      15.523,
      -21.543,
      120.973,
      13.073,
      -22.254,
      121.212,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7095",
    "polygon": [
      -21.543,
      120.973,
      13.073,
      -21.543,
      120.973,
      15.523,
      -20.016,
      120.462,
      15.523,
      -20.016,
      120.462,
      15.523,
      -20.016,
      120.462,
      13.073,
      -21.543,
      120.973,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7096",
    "polygon": [
      -20.016,
      120.462,
      13.073,
      -20.016,
      120.462,
      15.523,
      -19.424,
      120.263,
      15.523,
      -19.424,
      120.263,
      15.523,
      -19.424,
      120.263,
      13.073,
      -20.016,
      120.462,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7097",
    "polygon": [
      -19.424,
      120.263,
      13.073,
      -19.424,
      120.263,
      15.523,
      -18.712,
      120.025,
      15.523,
      -18.712,
      120.025,
      15.523,
      -18.712,
      120.025,
      13.073,
      -19.424,
      120.263,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7098",
    "polygon": [
      -18.712,
      120.025,
      13.073,
      -18.712,
      120.025,
      15.523,
      -17.186,
      119.514,
      15.523,
      -17.186,
      119.514,
      15.523,
      -17.186,
      119.514,
      13.073,
      -18.712,
      120.025,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7099",
    "polygon": [
      -28.204,
      123.205,
      13.073,
      -28.204,
      123.205,
      15.523,
      -27.493,
      122.967,
      15.523,
      -27.493,
      122.967,
      15.523,
      -27.493,
      122.967,
      13.073,
      -28.204,
      123.205,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7100",
    "polygon": [
      -27.493,
      122.967,
      13.073,
      -27.493,
      122.967,
      15.523,
      -25.966,
      122.455,
      15.523,
      -25.966,
      122.455,
      15.523,
      -25.966,
      122.455,
      13.073,
      -27.493,
      122.967,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7101",
    "polygon": [
      -25.966,
      122.455,
      13.073,
      -25.966,
      122.455,
      15.523,
      -25.374,
      122.257,
      15.523,
      -25.374,
      122.257,
      15.523,
      -25.374,
      122.257,
      13.073,
      -25.966,
      122.455,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7102",
    "polygon": [
      -25.374,
      122.257,
      13.073,
      -25.374,
      122.257,
      15.523,
      -24.662,
      122.018,
      15.523,
      -24.662,
      122.018,
      15.523,
      -24.662,
      122.018,
      13.073,
      -25.374,
      122.257,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7103",
    "polygon": [
      -24.662,
      122.018,
      13.073,
      -24.662,
      122.018,
      15.523,
      -23.136,
      121.507,
      15.523,
      -23.136,
      121.507,
      15.523,
      -23.136,
      121.507,
      13.073,
      -24.662,
      122.018,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7104",
    "polygon": [
      -34.154,
      125.198,
      13.073,
      -34.154,
      125.198,
      15.523,
      -33.443,
      124.96,
      15.523,
      -33.443,
      124.96,
      15.523,
      -33.443,
      124.96,
      13.073,
      -34.154,
      125.198,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7105",
    "polygon": [
      -33.443,
      124.96,
      13.073,
      -33.443,
      124.96,
      15.523,
      -31.916,
      124.448,
      15.523,
      -31.916,
      124.448,
      15.523,
      -31.916,
      124.448,
      13.073,
      -33.443,
      124.96,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7106",
    "polygon": [
      -31.916,
      124.448,
      13.073,
      -31.916,
      124.448,
      15.523,
      -31.323,
      124.25,
      15.523,
      -31.323,
      124.25,
      15.523,
      -31.323,
      124.25,
      13.073,
      -31.916,
      124.448,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7107",
    "polygon": [
      -31.323,
      124.25,
      13.073,
      -31.323,
      124.25,
      15.523,
      -30.612,
      124.011,
      15.523,
      -30.612,
      124.011,
      15.523,
      -30.612,
      124.011,
      13.073,
      -31.323,
      124.25,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7108",
    "polygon": [
      -30.612,
      124.011,
      13.073,
      -30.612,
      124.011,
      15.523,
      -29.086,
      123.5,
      15.523,
      -29.086,
      123.5,
      15.523,
      -29.086,
      123.5,
      13.073,
      -30.612,
      124.011,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7109",
    "polygon": [
      -10.353,
      117.228,
      5.573,
      -10.353,
      117.228,
      8.023,
      -9.642,
      116.99,
      8.023,
      -9.642,
      116.99,
      8.023,
      -9.642,
      116.99,
      5.573,
      -10.353,
      117.228,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7110",
    "polygon": [
      -9.642,
      116.99,
      5.573,
      -9.642,
      116.99,
      8.023,
      -8.115,
      116.479,
      8.023,
      -8.115,
      116.479,
      8.023,
      -8.115,
      116.479,
      5.573,
      -9.642,
      116.99,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7111",
    "polygon": [
      -8.115,
      116.479,
      5.573,
      -8.115,
      116.479,
      8.023,
      -7.523,
      116.28,
      8.023,
      -7.523,
      116.28,
      8.023,
      -7.523,
      116.28,
      5.573,
      -8.115,
      116.479,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7112",
    "polygon": [
      -7.523,
      116.28,
      5.573,
      -7.523,
      116.28,
      8.023,
      -6.812,
      116.042,
      8.023,
      -6.812,
      116.042,
      8.023,
      -6.812,
      116.042,
      5.573,
      -7.523,
      116.28,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_7113",
    "polygon": [
      -6.812,
      116.042,
      5.573,
      -6.812,
      116.042,
      8.023,
      -5.285,
      115.53,
      8.023,
      -5.285,
      115.53,
      8.023,
      -5.285,
      115.53,
      5.573,
      -6.812,
      116.042,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7114",
    "polygon": [
      -10.354,
      117.225,
      9.323,
      -10.354,
      117.225,
      11.773,
      -9.643,
      116.987,
      11.773,
      -9.643,
      116.987,
      11.773,
      -9.643,
      116.987,
      9.323,
      -10.354,
      117.225,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7115",
    "polygon": [
      -9.643,
      116.987,
      9.323,
      -9.643,
      116.987,
      11.773,
      -8.116,
      116.476,
      11.773,
      -8.116,
      116.476,
      11.773,
      -8.116,
      116.476,
      9.323,
      -9.643,
      116.987,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7116",
    "polygon": [
      -8.116,
      116.476,
      9.323,
      -8.116,
      116.476,
      11.773,
      -7.524,
      116.277,
      11.773,
      -7.524,
      116.277,
      11.773,
      -7.524,
      116.277,
      9.323,
      -8.116,
      116.476,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7117",
    "polygon": [
      -7.524,
      116.277,
      9.323,
      -7.524,
      116.277,
      11.773,
      -6.813,
      116.039,
      11.773,
      -6.813,
      116.039,
      11.773,
      -6.813,
      116.039,
      9.323,
      -7.524,
      116.277,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_7118",
    "polygon": [
      -6.813,
      116.039,
      9.323,
      -6.813,
      116.039,
      11.773,
      -5.286,
      115.528,
      11.773,
      -5.286,
      115.528,
      11.773,
      -5.286,
      115.528,
      9.323,
      -6.813,
      116.039,
      9.323
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7119",
    "polygon": [
      -10.354,
      117.225,
      13.073,
      -10.354,
      117.225,
      15.523,
      -9.643,
      116.987,
      15.523,
      -9.643,
      116.987,
      15.523,
      -9.643,
      116.987,
      13.073,
      -10.354,
      117.225,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7120",
    "polygon": [
      -9.643,
      116.987,
      13.073,
      -9.643,
      116.987,
      15.523,
      -8.116,
      116.476,
      15.523,
      -8.116,
      116.476,
      15.523,
      -8.116,
      116.476,
      13.073,
      -9.643,
      116.987,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7121",
    "polygon": [
      -8.116,
      116.476,
      13.073,
      -8.116,
      116.476,
      15.523,
      -7.524,
      116.277,
      15.523,
      -7.524,
      116.277,
      15.523,
      -7.524,
      116.277,
      13.073,
      -8.116,
      116.476,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7122",
    "polygon": [
      -7.524,
      116.277,
      13.073,
      -7.524,
      116.277,
      15.523,
      -6.813,
      116.039,
      15.523,
      -6.813,
      116.039,
      15.523,
      -6.813,
      116.039,
      13.073,
      -7.524,
      116.277,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_7123",
    "polygon": [
      -6.813,
      116.039,
      13.073,
      -6.813,
      116.039,
      15.523,
      -5.286,
      115.528,
      15.523,
      -5.286,
      115.528,
      15.523,
      -5.286,
      115.528,
      13.073,
      -6.813,
      116.039,
      13.073
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7124",
    "polygon": [
      -3.857,
      99.124,
      13.073,
      -3.857,
      99.124,
      15.523,
      -3.619,
      99.835,
      15.523,
      -3.619,
      99.835,
      15.523,
      -3.619,
      99.835,
      13.073,
      -3.857,
      99.124,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7125",
    "polygon": [
      -4.369,
      97.598,
      13.073,
      -4.369,
      97.598,
      15.523,
      -3.857,
      99.124,
      15.523,
      -3.857,
      99.124,
      15.523,
      -3.857,
      99.124,
      13.073,
      -4.369,
      97.598,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7126",
    "polygon": [
      -4.567,
      97.005,
      13.073,
      -4.567,
      97.005,
      15.523,
      -4.369,
      97.598,
      15.523,
      -4.369,
      97.598,
      15.523,
      -4.369,
      97.598,
      13.073,
      -4.567,
      97.005,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7127",
    "polygon": [
      -4.805,
      96.294,
      13.073,
      -4.805,
      96.294,
      15.523,
      -4.567,
      97.005,
      15.523,
      -4.567,
      97.005,
      15.523,
      -4.567,
      97.005,
      13.073,
      -4.805,
      96.294,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7128",
    "polygon": [
      -5.317,
      94.767,
      13.073,
      -5.317,
      94.767,
      15.523,
      -4.805,
      96.294,
      15.523,
      -4.805,
      96.294,
      15.523,
      -4.805,
      96.294,
      13.073,
      -5.317,
      94.767,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7144",
    "polygon": [
      -3.855,
      99.124,
      5.573,
      -3.855,
      99.124,
      8.023,
      -3.617,
      99.835,
      8.023,
      -3.617,
      99.835,
      8.023,
      -3.617,
      99.835,
      5.573,
      -3.855,
      99.124,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7145",
    "polygon": [
      -4.367,
      97.597,
      5.573,
      -4.367,
      97.597,
      8.023,
      -3.855,
      99.124,
      8.023,
      -3.855,
      99.124,
      8.023,
      -3.855,
      99.124,
      5.573,
      -4.367,
      97.597,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7146",
    "polygon": [
      -4.565,
      97.004,
      5.573,
      -4.565,
      97.004,
      8.023,
      -4.367,
      97.597,
      8.023,
      -4.367,
      97.597,
      8.023,
      -4.367,
      97.597,
      5.573,
      -4.565,
      97.004,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7147",
    "polygon": [
      -4.803,
      96.293,
      5.573,
      -4.803,
      96.293,
      8.023,
      -4.565,
      97.004,
      8.023,
      -4.565,
      97.004,
      8.023,
      -4.565,
      97.004,
      5.573,
      -4.803,
      96.293,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7148",
    "polygon": [
      -5.315,
      94.767,
      5.573,
      -5.315,
      94.767,
      8.023,
      -4.803,
      96.293,
      8.023,
      -4.803,
      96.293,
      8.023,
      -4.803,
      96.293,
      5.573,
      -5.315,
      94.767,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7164",
    "polygon": [
      -3.861,
      99.126,
      9.323,
      -3.861,
      99.126,
      11.773,
      -3.623,
      99.837,
      11.773,
      -3.623,
      99.837,
      11.773,
      -3.623,
      99.837,
      9.323,
      -3.861,
      99.126,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7165",
    "polygon": [
      -4.372,
      97.599,
      9.323,
      -4.372,
      97.599,
      11.773,
      -3.861,
      99.126,
      11.773,
      -3.861,
      99.126,
      11.773,
      -3.861,
      99.126,
      9.323,
      -4.372,
      97.599,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7166",
    "polygon": [
      -4.571,
      97.006,
      9.323,
      -4.571,
      97.006,
      11.773,
      -4.372,
      97.599,
      11.773,
      -4.372,
      97.599,
      11.773,
      -4.372,
      97.599,
      9.323,
      -4.571,
      97.006,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7167",
    "polygon": [
      -4.809,
      96.295,
      9.323,
      -4.809,
      96.295,
      11.773,
      -4.571,
      97.006,
      11.773,
      -4.571,
      97.006,
      11.773,
      -4.571,
      97.006,
      9.323,
      -4.809,
      96.295,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7168",
    "polygon": [
      -5.32,
      94.768,
      9.323,
      -5.32,
      94.768,
      11.773,
      -4.809,
      96.295,
      11.773,
      -4.809,
      96.295,
      11.773,
      -4.809,
      96.295,
      9.323,
      -5.32,
      94.768,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7184",
    "polygon": [
      -3.857,
      99.124,
      0.8,
      -3.857,
      99.124,
      3.65,
      -3.618,
      99.835,
      3.65,
      -3.618,
      99.835,
      3.65,
      -3.618,
      99.835,
      0.8,
      -3.857,
      99.124,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7185",
    "polygon": [
      -4.368,
      97.597,
      0.8,
      -4.368,
      97.597,
      3.65,
      -3.857,
      99.124,
      3.65,
      -3.857,
      99.124,
      3.65,
      -3.857,
      99.124,
      0.8,
      -4.368,
      97.597,
      0.8
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7186",
    "polygon": [
      -4.566,
      97.005,
      0.8,
      -4.566,
      97.005,
      3.65,
      -4.368,
      97.597,
      3.65,
      -4.368,
      97.597,
      3.65,
      -4.368,
      97.597,
      0.8,
      -4.566,
      97.005,
      0.8
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7187",
    "polygon": [
      -4.805,
      96.294,
      0.8,
      -4.805,
      96.294,
      3.65,
      -4.566,
      97.005,
      3.65,
      -4.566,
      97.005,
      3.65,
      -4.566,
      97.005,
      0.8,
      -4.805,
      96.294,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7188",
    "polygon": [
      -5.316,
      94.767,
      0.8,
      -5.316,
      94.767,
      3.65,
      -4.805,
      96.294,
      3.65,
      -4.805,
      96.294,
      3.65,
      -4.805,
      96.294,
      0.8,
      -5.316,
      94.767,
      0.8
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7189",
    "polygon": [
      -5.789,
      93.354,
      0.8,
      -5.789,
      93.354,
      3.65,
      -5.551,
      94.065,
      3.65,
      -5.551,
      94.065,
      3.65,
      -5.551,
      94.065,
      0.8,
      -5.789,
      93.354,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7190",
    "polygon": [
      -6.301,
      91.827,
      0.8,
      -6.301,
      91.827,
      3.65,
      -5.789,
      93.354,
      3.65,
      -5.789,
      93.354,
      3.65,
      -5.789,
      93.354,
      0.8,
      -6.301,
      91.827,
      0.8
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7191",
    "polygon": [
      -6.499,
      91.235,
      0.8,
      -6.499,
      91.235,
      3.65,
      -6.301,
      91.827,
      3.65,
      -6.301,
      91.827,
      3.65,
      -6.301,
      91.827,
      0.8,
      -6.499,
      91.235,
      0.8
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7192",
    "polygon": [
      -6.738,
      90.523,
      0.8,
      -6.738,
      90.523,
      3.65,
      -6.499,
      91.235,
      3.65,
      -6.499,
      91.235,
      3.65,
      -6.499,
      91.235,
      0.8,
      -6.738,
      90.523,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7193",
    "polygon": [
      -7.249,
      88.997,
      0.8,
      -7.249,
      88.997,
      3.65,
      -6.738,
      90.523,
      3.65,
      -6.738,
      90.523,
      3.65,
      -6.738,
      90.523,
      0.8,
      -7.249,
      88.997,
      0.8
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7194",
    "polygon": [
      -7.722,
      87.584,
      0.8,
      -7.722,
      87.584,
      3.65,
      -7.484,
      88.295,
      3.65,
      -7.484,
      88.295,
      3.65,
      -7.484,
      88.295,
      0.8,
      -7.722,
      87.584,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7195",
    "polygon": [
      -8.234,
      86.057,
      0.8,
      -8.234,
      86.057,
      3.65,
      -7.722,
      87.584,
      3.65,
      -7.722,
      87.584,
      3.65,
      -7.722,
      87.584,
      0.8,
      -8.234,
      86.057,
      0.8
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_7196",
    "polygon": [
      -8.432,
      85.464,
      0.8,
      -8.432,
      85.464,
      3.65,
      -8.234,
      86.057,
      3.65,
      -8.234,
      86.057,
      3.65,
      -8.234,
      86.057,
      0.8,
      -8.432,
      85.464,
      0.8
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_7197",
    "polygon": [
      -8.671,
      84.753,
      0.8,
      -8.671,
      84.753,
      3.65,
      -8.432,
      85.464,
      3.65,
      -8.432,
      85.464,
      3.65,
      -8.432,
      85.464,
      0.8,
      -8.671,
      84.753,
      0.8
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_7198",
    "polygon": [
      -9.182,
      83.227,
      0.8,
      -9.182,
      83.227,
      3.65,
      -8.671,
      84.753,
      3.65,
      -8.671,
      84.753,
      3.65,
      -8.671,
      84.753,
      0.8,
      -9.182,
      83.227,
      0.8
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_7199",
    "polygon": [
      -9.655,
      81.814,
      0.8,
      -9.655,
      81.814,
      3.65,
      -9.417,
      82.525,
      3.65,
      -9.417,
      82.525,
      3.65,
      -9.417,
      82.525,
      0.8,
      -9.655,
      81.814,
      0.8
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_7200",
    "polygon": [
      -10.167,
      80.287,
      0.8,
      -10.167,
      80.287,
      3.65,
      -9.655,
      81.814,
      3.65,
      -9.655,
      81.814,
      3.65,
      -9.655,
      81.814,
      0.8,
      -10.167,
      80.287,
      0.8
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_7201",
    "polygon": [
      -10.365,
      79.694,
      0.8,
      -10.365,
      79.694,
      3.65,
      -10.167,
      80.287,
      3.65,
      -10.167,
      80.287,
      3.65,
      -10.167,
      80.287,
      0.8,
      -10.365,
      79.694,
      0.8
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_7202",
    "polygon": [
      -10.603,
      78.983,
      0.8,
      -10.603,
      78.983,
      3.65,
      -10.365,
      79.694,
      3.65,
      -10.365,
      79.694,
      3.65,
      -10.365,
      79.694,
      0.8,
      -10.603,
      78.983,
      0.8
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_7203",
    "polygon": [
      -11.115,
      77.457,
      0.8,
      -11.115,
      77.457,
      3.65,
      -10.603,
      78.983,
      3.65,
      -10.603,
      78.983,
      3.65,
      -10.603,
      78.983,
      0.8,
      -11.115,
      77.457,
      0.8
    ]
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7204",
    "polygon": [
      11.779,
      25.308,
      13.073,
      11.779,
      25.308,
      15.523,
      12.017,
      26.019,
      15.523,
      12.017,
      26.019,
      15.523,
      12.017,
      26.019,
      13.073,
      11.779,
      25.308,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7205",
    "polygon": [
      11.268,
      23.781,
      13.073,
      11.268,
      23.781,
      15.523,
      11.779,
      25.308,
      15.523,
      11.779,
      25.308,
      15.523,
      11.779,
      25.308,
      13.073,
      11.268,
      23.781,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7206",
    "polygon": [
      11.069,
      23.189,
      13.073,
      11.069,
      23.189,
      15.523,
      11.268,
      23.781,
      15.523,
      11.268,
      23.781,
      15.523,
      11.268,
      23.781,
      13.073,
      11.069,
      23.189,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7207",
    "polygon": [
      10.831,
      22.477,
      13.073,
      10.831,
      22.477,
      15.523,
      11.069,
      23.189,
      15.523,
      11.069,
      23.189,
      15.523,
      11.069,
      23.189,
      13.073,
      10.831,
      22.477,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7208",
    "polygon": [
      10.319,
      20.951,
      13.073,
      10.319,
      20.951,
      15.523,
      10.831,
      22.477,
      15.523,
      10.831,
      22.477,
      15.523,
      10.831,
      22.477,
      13.073,
      10.319,
      20.951,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7209",
    "polygon": [
      -5.79,
      93.354,
      13.073,
      -5.79,
      93.354,
      15.523,
      -5.552,
      94.065,
      15.523,
      -5.552,
      94.065,
      15.523,
      -5.552,
      94.065,
      13.073,
      -5.79,
      93.354,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7210",
    "polygon": [
      -6.301,
      91.827,
      13.073,
      -6.301,
      91.827,
      15.523,
      -5.79,
      93.354,
      15.523,
      -5.79,
      93.354,
      15.523,
      -5.79,
      93.354,
      13.073,
      -6.301,
      91.827,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7211",
    "polygon": [
      -6.5,
      91.235,
      13.073,
      -6.5,
      91.235,
      15.523,
      -6.301,
      91.827,
      15.523,
      -6.301,
      91.827,
      15.523,
      -6.301,
      91.827,
      13.073,
      -6.5,
      91.235,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7212",
    "polygon": [
      -6.738,
      90.524,
      13.073,
      -6.738,
      90.524,
      15.523,
      -6.5,
      91.235,
      15.523,
      -6.5,
      91.235,
      15.523,
      -6.5,
      91.235,
      13.073,
      -6.738,
      90.524,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7213",
    "polygon": [
      -7.25,
      88.997,
      13.073,
      -7.25,
      88.997,
      15.523,
      -6.738,
      90.524,
      15.523,
      -6.738,
      90.524,
      15.523,
      -6.738,
      90.524,
      13.073,
      -7.25,
      88.997,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7219",
    "polygon": [
      -9.656,
      81.814,
      13.073,
      -9.656,
      81.814,
      15.523,
      -9.418,
      82.525,
      15.523,
      -9.418,
      82.525,
      15.523,
      -9.418,
      82.525,
      13.073,
      -9.656,
      81.814,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7220",
    "polygon": [
      -10.167,
      80.287,
      13.073,
      -10.167,
      80.287,
      15.523,
      -9.656,
      81.814,
      15.523,
      -9.656,
      81.814,
      15.523,
      -9.656,
      81.814,
      13.073,
      -10.167,
      80.287,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7221",
    "polygon": [
      -10.366,
      79.695,
      13.073,
      -10.366,
      79.695,
      15.523,
      -10.167,
      80.287,
      15.523,
      -10.167,
      80.287,
      15.523,
      -10.167,
      80.287,
      13.073,
      -10.366,
      79.695,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7222",
    "polygon": [
      -10.604,
      78.983,
      13.073,
      -10.604,
      78.983,
      15.523,
      -10.366,
      79.695,
      15.523,
      -10.366,
      79.695,
      15.523,
      -10.366,
      79.695,
      13.073,
      -10.604,
      78.983,
      13.073
    ],
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7223",
    "polygon": [
      -11.115,
      77.457,
      13.073,
      -11.115,
      77.457,
      15.523,
      -10.604,
      78.983,
      15.523,
      -10.604,
      78.983,
      15.523,
      -10.604,
      78.983,
      13.073,
      -11.115,
      77.457,
      13.073
    ],
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7224",
    "polygon": [
      -5.794,
      93.355,
      9.323,
      -5.794,
      93.355,
      11.773,
      -5.556,
      94.067,
      11.773,
      -5.556,
      94.067,
      11.773,
      -5.556,
      94.067,
      9.323,
      -5.794,
      93.355,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7225",
    "polygon": [
      -6.305,
      91.829,
      9.323,
      -6.305,
      91.829,
      11.773,
      -5.794,
      93.355,
      11.773,
      -5.794,
      93.355,
      11.773,
      -5.794,
      93.355,
      9.323,
      -6.305,
      91.829,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7226",
    "polygon": [
      -6.504,
      91.236,
      9.323,
      -6.504,
      91.236,
      11.773,
      -6.305,
      91.829,
      11.773,
      -6.305,
      91.829,
      11.773,
      -6.305,
      91.829,
      9.323,
      -6.504,
      91.236,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7227",
    "polygon": [
      -6.742,
      90.525,
      9.323,
      -6.742,
      90.525,
      11.773,
      -6.504,
      91.236,
      11.773,
      -6.504,
      91.236,
      11.773,
      -6.504,
      91.236,
      9.323,
      -6.742,
      90.525,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7228",
    "polygon": [
      -7.253,
      88.998,
      9.323,
      -7.253,
      88.998,
      11.773,
      -6.742,
      90.525,
      11.773,
      -6.742,
      90.525,
      11.773,
      -6.742,
      90.525,
      9.323,
      -7.253,
      88.998,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7229",
    "polygon": [
      -7.727,
      87.585,
      9.323,
      -7.727,
      87.585,
      11.773,
      -7.489,
      88.296,
      11.773,
      -7.489,
      88.296,
      11.773,
      -7.489,
      88.296,
      9.323,
      -7.727,
      87.585,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7230",
    "polygon": [
      -8.238,
      86.059,
      9.323,
      -8.238,
      86.059,
      11.773,
      -7.727,
      87.585,
      11.773,
      -7.727,
      87.585,
      11.773,
      -7.727,
      87.585,
      9.323,
      -8.238,
      86.059,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7231",
    "polygon": [
      -8.437,
      85.466,
      9.323,
      -8.437,
      85.466,
      11.773,
      -8.238,
      86.059,
      11.773,
      -8.238,
      86.059,
      11.773,
      -8.238,
      86.059,
      9.323,
      -8.437,
      85.466,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7232",
    "polygon": [
      -8.675,
      84.755,
      9.323,
      -8.675,
      84.755,
      11.773,
      -8.437,
      85.466,
      11.773,
      -8.437,
      85.466,
      11.773,
      -8.437,
      85.466,
      9.323,
      -8.675,
      84.755,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7233",
    "polygon": [
      -9.186,
      83.228,
      9.323,
      -9.186,
      83.228,
      11.773,
      -8.675,
      84.755,
      11.773,
      -8.675,
      84.755,
      11.773,
      -8.675,
      84.755,
      9.323,
      -9.186,
      83.228,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7234",
    "polygon": [
      -9.66,
      81.815,
      9.323,
      -9.66,
      81.815,
      11.773,
      -9.421,
      82.526,
      11.773,
      -9.421,
      82.526,
      11.773,
      -9.421,
      82.526,
      9.323,
      -9.66,
      81.815,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7235",
    "polygon": [
      -10.171,
      80.288,
      9.323,
      -10.171,
      80.288,
      11.773,
      -9.66,
      81.815,
      11.773,
      -9.66,
      81.815,
      11.773,
      -9.66,
      81.815,
      9.323,
      -10.171,
      80.288,
      9.323
    ],
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7236",
    "polygon": [
      -10.37,
      79.696,
      9.323,
      -10.37,
      79.696,
      11.773,
      -10.171,
      80.288,
      11.773,
      -10.171,
      80.288,
      11.773,
      -10.171,
      80.288,
      9.323,
      -10.37,
      79.696,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7237",
    "polygon": [
      -10.608,
      78.985,
      9.323,
      -10.608,
      78.985,
      11.773,
      -10.37,
      79.696,
      11.773,
      -10.37,
      79.696,
      11.773,
      -10.37,
      79.696,
      9.323,
      -10.608,
      78.985,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7238",
    "polygon": [
      -11.119,
      77.458,
      9.323,
      -11.119,
      77.458,
      11.773,
      -10.608,
      78.985,
      11.773,
      -10.608,
      78.985,
      11.773,
      -10.608,
      78.985,
      9.323,
      -11.119,
      77.458,
      9.323
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7239",
    "polygon": [
      -5.788,
      93.353,
      5.573,
      -5.788,
      93.353,
      8.023,
      -5.55,
      94.065,
      8.023,
      -5.55,
      94.065,
      8.023,
      -5.55,
      94.065,
      5.573,
      -5.788,
      93.353,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7240",
    "polygon": [
      -6.299,
      91.827,
      5.573,
      -6.299,
      91.827,
      8.023,
      -5.788,
      93.353,
      8.023,
      -5.788,
      93.353,
      8.023,
      -5.788,
      93.353,
      5.573,
      -6.299,
      91.827,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7241",
    "polygon": [
      -6.498,
      91.234,
      5.573,
      -6.498,
      91.234,
      8.023,
      -6.299,
      91.827,
      8.023,
      -6.299,
      91.827,
      8.023,
      -6.299,
      91.827,
      5.573,
      -6.498,
      91.234,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7242",
    "polygon": [
      -6.736,
      90.523,
      5.573,
      -6.736,
      90.523,
      8.023,
      -6.498,
      91.234,
      8.023,
      -6.498,
      91.234,
      8.023,
      -6.498,
      91.234,
      5.573,
      -6.736,
      90.523,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7243",
    "polygon": [
      -7.248,
      88.996,
      5.573,
      -7.248,
      88.996,
      8.023,
      -6.736,
      90.523,
      8.023,
      -6.736,
      90.523,
      8.023,
      -6.736,
      90.523,
      5.573,
      -7.248,
      88.996,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7244",
    "polygon": [
      -7.721,
      87.583,
      5.573,
      -7.721,
      87.583,
      8.023,
      -7.483,
      88.294,
      8.023,
      -7.483,
      88.294,
      8.023,
      -7.483,
      88.294,
      5.573,
      -7.721,
      87.583,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7245",
    "polygon": [
      -8.232,
      86.057,
      5.573,
      -8.232,
      86.057,
      8.023,
      -7.721,
      87.583,
      8.023,
      -7.721,
      87.583,
      8.023,
      -7.721,
      87.583,
      5.573,
      -8.232,
      86.057,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7246",
    "polygon": [
      -8.431,
      85.464,
      5.573,
      -8.431,
      85.464,
      8.023,
      -8.232,
      86.057,
      8.023,
      -8.232,
      86.057,
      8.023,
      -8.232,
      86.057,
      5.573,
      -8.431,
      85.464,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7247",
    "polygon": [
      -8.669,
      84.753,
      5.573,
      -8.669,
      84.753,
      8.023,
      -8.431,
      85.464,
      8.023,
      -8.431,
      85.464,
      8.023,
      -8.431,
      85.464,
      5.573,
      -8.669,
      84.753,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7248",
    "polygon": [
      -9.181,
      83.226,
      5.573,
      -9.181,
      83.226,
      8.023,
      -8.669,
      84.753,
      8.023,
      -8.669,
      84.753,
      8.023,
      -8.669,
      84.753,
      5.573,
      -9.181,
      83.226,
      5.573
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7249",
    "polygon": [
      -9.654,
      81.813,
      5.573,
      -9.654,
      81.813,
      8.023,
      -9.416,
      82.524,
      8.023,
      -9.416,
      82.524,
      8.023,
      -9.416,
      82.524,
      5.573,
      -9.654,
      81.813,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7250",
    "polygon": [
      -10.165,
      80.286,
      5.573,
      -10.165,
      80.286,
      8.023,
      -9.654,
      81.813,
      8.023,
      -9.654,
      81.813,
      8.023,
      -9.654,
      81.813,
      5.573,
      -10.165,
      80.286,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7251",
    "polygon": [
      -10.364,
      79.694,
      5.573,
      -10.364,
      79.694,
      8.023,
      -10.165,
      80.286,
      8.023,
      -10.165,
      80.286,
      8.023,
      -10.165,
      80.286,
      5.573,
      -10.364,
      79.694,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7252",
    "polygon": [
      -10.602,
      78.983,
      5.573,
      -10.602,
      78.983,
      8.023,
      -10.364,
      79.694,
      8.023,
      -10.364,
      79.694,
      8.023,
      -10.364,
      79.694,
      5.573,
      -10.602,
      78.983,
      5.573
    ]
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7253",
    "polygon": [
      -11.113,
      77.456,
      5.573,
      -11.113,
      77.456,
      8.023,
      -10.602,
      78.983,
      8.023,
      -10.602,
      78.983,
      8.023,
      -10.602,
      78.983,
      5.573,
      -11.113,
      77.456,
      5.573
    ]
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7254",
    "polygon": [
      9.846,
      19.538,
      13.073,
      9.846,
      19.538,
      15.523,
      10.084,
      20.249,
      15.523,
      10.084,
      20.249,
      15.523,
      10.084,
      20.249,
      13.073,
      9.846,
      19.538,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7255",
    "polygon": [
      9.335,
      18.011,
      13.073,
      9.335,
      18.011,
      15.523,
      9.846,
      19.538,
      15.523,
      9.846,
      19.538,
      15.523,
      9.846,
      19.538,
      13.073,
      9.335,
      18.011,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7256",
    "polygon": [
      9.136,
      17.418,
      13.073,
      9.136,
      17.418,
      15.523,
      9.335,
      18.011,
      15.523,
      9.335,
      18.011,
      15.523,
      9.335,
      18.011,
      13.073,
      9.136,
      17.418,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7257",
    "polygon": [
      8.898,
      16.707,
      13.073,
      8.898,
      16.707,
      15.523,
      9.136,
      17.418,
      15.523,
      9.136,
      17.418,
      15.523,
      9.136,
      17.418,
      13.073,
      8.898,
      16.707,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7258",
    "polygon": [
      8.386,
      15.181,
      13.073,
      8.386,
      15.181,
      15.523,
      8.898,
      16.707,
      15.523,
      8.898,
      16.707,
      15.523,
      8.898,
      16.707,
      13.073,
      8.386,
      15.181,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7259",
    "polygon": [
      7.913,
      13.768,
      13.073,
      7.913,
      13.768,
      15.523,
      8.151,
      14.479,
      15.523,
      8.151,
      14.479,
      15.523,
      8.151,
      14.479,
      13.073,
      7.913,
      13.768,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7260",
    "polygon": [
      7.402,
      12.241,
      13.073,
      7.402,
      12.241,
      15.523,
      7.913,
      13.768,
      15.523,
      7.913,
      13.768,
      15.523,
      7.913,
      13.768,
      13.073,
      7.402,
      12.241,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7261",
    "polygon": [
      7.203,
      11.648,
      13.073,
      7.203,
      11.648,
      15.523,
      7.402,
      12.241,
      15.523,
      7.402,
      12.241,
      15.523,
      7.402,
      12.241,
      13.073,
      7.203,
      11.648,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7262",
    "polygon": [
      6.965,
      10.937,
      13.073,
      6.965,
      10.937,
      15.523,
      7.203,
      11.648,
      15.523,
      7.203,
      11.648,
      15.523,
      7.203,
      11.648,
      13.073,
      6.965,
      10.937,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7263",
    "polygon": [
      6.454,
      9.411,
      13.073,
      6.454,
      9.411,
      15.523,
      6.965,
      10.937,
      15.523,
      6.965,
      10.937,
      15.523,
      6.965,
      10.937,
      13.073,
      6.454,
      9.411,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7264",
    "polygon": [
      5.98,
      7.997,
      13.073,
      5.98,
      7.997,
      15.523,
      6.218,
      8.709,
      15.523,
      6.218,
      8.709,
      15.523,
      6.218,
      8.709,
      13.073,
      5.98,
      7.997,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7265",
    "polygon": [
      5.469,
      6.471,
      13.073,
      5.469,
      6.471,
      15.523,
      5.98,
      7.997,
      15.523,
      5.98,
      7.997,
      15.523,
      5.98,
      7.997,
      13.073,
      5.469,
      6.471,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7266",
    "polygon": [
      5.27,
      5.878,
      13.073,
      5.27,
      5.878,
      15.523,
      5.469,
      6.471,
      15.523,
      5.469,
      6.471,
      15.523,
      5.469,
      6.471,
      13.073,
      5.27,
      5.878,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7267",
    "polygon": [
      5.032,
      5.167,
      13.073,
      5.032,
      5.167,
      15.523,
      5.27,
      5.878,
      15.523,
      5.27,
      5.878,
      15.523,
      5.27,
      5.878,
      13.073,
      5.032,
      5.167,
      13.073
    ],
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_7268",
    "polygon": [
      4.521,
      3.64,
      13.073,
      4.521,
      3.64,
      15.523,
      5.032,
      5.167,
      15.523,
      5.032,
      5.167,
      15.523,
      5.032,
      5.167,
      13.073,
      4.521,
      3.64,
      13.073
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7269",
    "polygon": [
      11.779,
      25.308,
      9.323,
      11.779,
      25.308,
      11.773,
      12.017,
      26.019,
      11.773,
      12.017,
      26.019,
      11.773,
      12.017,
      26.019,
      9.323,
      11.779,
      25.308,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7270",
    "polygon": [
      11.268,
      23.781,
      9.323,
      11.268,
      23.781,
      11.773,
      11.779,
      25.308,
      11.773,
      11.779,
      25.308,
      11.773,
      11.779,
      25.308,
      9.323,
      11.268,
      23.781,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7271",
    "polygon": [
      11.069,
      23.189,
      9.323,
      11.069,
      23.189,
      11.773,
      11.268,
      23.781,
      11.773,
      11.268,
      23.781,
      11.773,
      11.268,
      23.781,
      9.323,
      11.069,
      23.189,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7272",
    "polygon": [
      10.831,
      22.477,
      9.323,
      10.831,
      22.477,
      11.773,
      11.069,
      23.189,
      11.773,
      11.069,
      23.189,
      11.773,
      11.069,
      23.189,
      9.323,
      10.831,
      22.477,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7273",
    "polygon": [
      10.319,
      20.951,
      9.323,
      10.319,
      20.951,
      11.773,
      10.831,
      22.477,
      11.773,
      10.831,
      22.477,
      11.773,
      10.831,
      22.477,
      9.323,
      10.319,
      20.951,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7274",
    "polygon": [
      9.846,
      19.538,
      9.323,
      9.846,
      19.538,
      11.773,
      10.084,
      20.249,
      11.773,
      10.084,
      20.249,
      11.773,
      10.084,
      20.249,
      9.323,
      9.846,
      19.538,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7275",
    "polygon": [
      9.335,
      18.011,
      9.323,
      9.335,
      18.011,
      11.773,
      9.846,
      19.538,
      11.773,
      9.846,
      19.538,
      11.773,
      9.846,
      19.538,
      9.323,
      9.335,
      18.011,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7276",
    "polygon": [
      9.136,
      17.418,
      9.323,
      9.136,
      17.418,
      11.773,
      9.335,
      18.011,
      11.773,
      9.335,
      18.011,
      11.773,
      9.335,
      18.011,
      9.323,
      9.136,
      17.418,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7277",
    "polygon": [
      8.898,
      16.707,
      9.323,
      8.898,
      16.707,
      11.773,
      9.136,
      17.418,
      11.773,
      9.136,
      17.418,
      11.773,
      9.136,
      17.418,
      9.323,
      8.898,
      16.707,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7278",
    "polygon": [
      8.386,
      15.181,
      9.323,
      8.386,
      15.181,
      11.773,
      8.898,
      16.707,
      11.773,
      8.898,
      16.707,
      11.773,
      8.898,
      16.707,
      9.323,
      8.386,
      15.181,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7279",
    "polygon": [
      7.913,
      13.768,
      9.323,
      7.913,
      13.768,
      11.773,
      8.151,
      14.479,
      11.773,
      8.151,
      14.479,
      11.773,
      8.151,
      14.479,
      9.323,
      7.913,
      13.768,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7280",
    "polygon": [
      7.402,
      12.241,
      9.323,
      7.402,
      12.241,
      11.773,
      7.913,
      13.768,
      11.773,
      7.913,
      13.768,
      11.773,
      7.913,
      13.768,
      9.323,
      7.402,
      12.241,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7281",
    "polygon": [
      7.203,
      11.648,
      9.323,
      7.203,
      11.648,
      11.773,
      7.402,
      12.241,
      11.773,
      7.402,
      12.241,
      11.773,
      7.402,
      12.241,
      9.323,
      7.203,
      11.648,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7282",
    "polygon": [
      6.965,
      10.937,
      9.323,
      6.965,
      10.937,
      11.773,
      7.203,
      11.648,
      11.773,
      7.203,
      11.648,
      11.773,
      7.203,
      11.648,
      9.323,
      6.965,
      10.937,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7283",
    "polygon": [
      6.454,
      9.411,
      9.323,
      6.454,
      9.411,
      11.773,
      6.965,
      10.937,
      11.773,
      6.965,
      10.937,
      11.773,
      6.965,
      10.937,
      9.323,
      6.454,
      9.411,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7284",
    "polygon": [
      5.98,
      7.997,
      9.323,
      5.98,
      7.997,
      11.773,
      6.218,
      8.709,
      11.773,
      6.218,
      8.709,
      11.773,
      6.218,
      8.709,
      9.323,
      5.98,
      7.997,
      9.323
    ],
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7285",
    "polygon": [
      5.469,
      6.471,
      9.323,
      5.469,
      6.471,
      11.773,
      5.98,
      7.997,
      11.773,
      5.98,
      7.997,
      11.773,
      5.98,
      7.997,
      9.323,
      5.469,
      6.471,
      9.323
    ],
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7286",
    "polygon": [
      5.27,
      5.878,
      9.323,
      5.27,
      5.878,
      11.773,
      5.469,
      6.471,
      11.773,
      5.469,
      6.471,
      11.773,
      5.469,
      6.471,
      9.323,
      5.27,
      5.878,
      9.323
    ],
    "05-22 17:15": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7287",
    "polygon": [
      5.032,
      5.167,
      9.323,
      5.032,
      5.167,
      11.773,
      5.27,
      5.878,
      11.773,
      5.27,
      5.878,
      11.773,
      5.27,
      5.878,
      9.323,
      5.032,
      5.167,
      9.323
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_7288",
    "polygon": [
      4.521,
      3.64,
      9.323,
      4.521,
      3.64,
      11.773,
      5.032,
      5.167,
      11.773,
      5.032,
      5.167,
      11.773,
      5.032,
      5.167,
      9.323,
      4.521,
      3.64,
      9.323
    ]
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7289",
    "polygon": [
      69.581,
      74.348,
      13.073,
      69.581,
      74.348,
      15.523,
      69.82,
      75.059,
      15.523,
      69.82,
      75.059,
      15.523,
      69.82,
      75.059,
      13.073,
      69.581,
      74.348,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7290",
    "polygon": [
      69.07,
      72.821,
      13.073,
      69.07,
      72.821,
      15.523,
      69.581,
      74.348,
      15.523,
      69.581,
      74.348,
      15.523,
      69.581,
      74.348,
      13.073,
      69.07,
      72.821,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7291",
    "polygon": [
      68.872,
      72.229,
      13.073,
      68.872,
      72.229,
      15.523,
      69.07,
      72.821,
      15.523,
      69.07,
      72.821,
      15.523,
      69.07,
      72.821,
      13.073,
      68.872,
      72.229,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7292",
    "polygon": [
      68.633,
      71.518,
      13.073,
      68.633,
      71.518,
      15.523,
      68.872,
      72.229,
      15.523,
      68.872,
      72.229,
      15.523,
      68.872,
      72.229,
      13.073,
      68.633,
      71.518,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7293",
    "polygon": [
      68.122,
      69.991,
      13.073,
      68.122,
      69.991,
      15.523,
      68.633,
      71.518,
      15.523,
      68.633,
      71.518,
      15.523,
      68.633,
      71.518,
      13.073,
      68.122,
      69.991,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7294",
    "polygon": [
      67.649,
      68.578,
      13.073,
      67.649,
      68.578,
      15.523,
      67.887,
      69.289,
      15.523,
      67.887,
      69.289,
      15.523,
      67.887,
      69.289,
      13.073,
      67.649,
      68.578,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7295",
    "polygon": [
      67.137,
      67.051,
      13.073,
      67.137,
      67.051,
      15.523,
      67.649,
      68.578,
      15.523,
      67.649,
      68.578,
      15.523,
      67.649,
      68.578,
      13.073,
      67.137,
      67.051,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7296",
    "polygon": [
      66.939,
      66.459,
      13.073,
      66.939,
      66.459,
      15.523,
      67.137,
      67.051,
      15.523,
      67.137,
      67.051,
      15.523,
      67.137,
      67.051,
      13.073,
      66.939,
      66.459,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7297",
    "polygon": [
      66.7,
      65.748,
      13.073,
      66.7,
      65.748,
      15.523,
      66.939,
      66.459,
      15.523,
      66.939,
      66.459,
      15.523,
      66.939,
      66.459,
      13.073,
      66.7,
      65.748,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7298",
    "polygon": [
      66.189,
      64.221,
      13.073,
      66.189,
      64.221,
      15.523,
      66.7,
      65.748,
      15.523,
      66.7,
      65.748,
      15.523,
      66.7,
      65.748,
      13.073,
      66.189,
      64.221,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7299",
    "polygon": [
      65.716,
      62.808,
      13.073,
      65.716,
      62.808,
      15.523,
      65.954,
      63.519,
      15.523,
      65.954,
      63.519,
      15.523,
      65.954,
      63.519,
      13.073,
      65.716,
      62.808,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7300",
    "polygon": [
      65.204,
      61.281,
      13.073,
      65.204,
      61.281,
      15.523,
      65.716,
      62.808,
      15.523,
      65.716,
      62.808,
      15.523,
      65.716,
      62.808,
      13.073,
      65.204,
      61.281,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7301",
    "polygon": [
      65.006,
      60.688,
      13.073,
      65.006,
      60.688,
      15.523,
      65.204,
      61.281,
      15.523,
      65.204,
      61.281,
      15.523,
      65.204,
      61.281,
      13.073,
      65.006,
      60.688,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7302",
    "polygon": [
      64.767,
      59.977,
      13.073,
      64.767,
      59.977,
      15.523,
      65.006,
      60.688,
      15.523,
      65.006,
      60.688,
      15.523,
      65.006,
      60.688,
      13.073,
      64.767,
      59.977,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7303",
    "polygon": [
      64.256,
      58.451,
      13.073,
      64.256,
      58.451,
      15.523,
      64.767,
      59.977,
      15.523,
      64.767,
      59.977,
      15.523,
      64.767,
      59.977,
      13.073,
      64.256,
      58.451,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7304",
    "polygon": [
      63.783,
      57.038,
      13.073,
      63.783,
      57.038,
      15.523,
      64.021,
      57.749,
      15.523,
      64.021,
      57.749,
      15.523,
      64.021,
      57.749,
      13.073,
      63.783,
      57.038,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7305",
    "polygon": [
      63.271,
      55.511,
      13.073,
      63.271,
      55.511,
      15.523,
      63.783,
      57.038,
      15.523,
      63.783,
      57.038,
      15.523,
      63.783,
      57.038,
      13.073,
      63.271,
      55.511,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7306",
    "polygon": [
      63.073,
      54.918,
      13.073,
      63.073,
      54.918,
      15.523,
      63.271,
      55.511,
      15.523,
      63.271,
      55.511,
      15.523,
      63.271,
      55.511,
      13.073,
      63.073,
      54.918,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7307",
    "polygon": [
      62.835,
      54.207,
      13.073,
      62.835,
      54.207,
      15.523,
      63.073,
      54.918,
      15.523,
      63.073,
      54.918,
      15.523,
      63.073,
      54.918,
      13.073,
      62.835,
      54.207,
      13.073
    ],
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7308",
    "polygon": [
      62.323,
      52.681,
      13.073,
      62.323,
      52.681,
      15.523,
      62.835,
      54.207,
      15.523,
      62.835,
      54.207,
      15.523,
      62.835,
      54.207,
      13.073,
      62.323,
      52.681,
      13.073
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7309",
    "polygon": [
      69.559,
      74.356,
      5.573,
      69.559,
      74.356,
      8.023,
      69.797,
      75.067,
      8.023,
      69.797,
      75.067,
      8.023,
      69.797,
      75.067,
      5.573,
      69.559,
      74.356,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7310",
    "polygon": [
      69.047,
      72.829,
      5.573,
      69.047,
      72.829,
      8.023,
      69.559,
      74.356,
      8.023,
      69.559,
      74.356,
      8.023,
      69.559,
      74.356,
      5.573,
      69.047,
      72.829,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7311",
    "polygon": [
      68.849,
      72.237,
      5.573,
      68.849,
      72.237,
      8.023,
      69.047,
      72.829,
      8.023,
      69.047,
      72.829,
      8.023,
      69.047,
      72.829,
      5.573,
      68.849,
      72.237,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7312",
    "polygon": [
      68.61,
      71.525,
      5.573,
      68.61,
      71.525,
      8.023,
      68.849,
      72.237,
      8.023,
      68.849,
      72.237,
      8.023,
      68.849,
      72.237,
      5.573,
      68.61,
      71.525,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7313",
    "polygon": [
      68.099,
      69.999,
      5.573,
      68.099,
      69.999,
      8.023,
      68.61,
      71.525,
      8.023,
      68.61,
      71.525,
      8.023,
      68.61,
      71.525,
      5.573,
      68.099,
      69.999,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7314",
    "polygon": [
      67.626,
      68.586,
      5.573,
      67.626,
      68.586,
      8.023,
      67.864,
      69.297,
      8.023,
      67.864,
      69.297,
      8.023,
      67.864,
      69.297,
      5.573,
      67.626,
      68.586,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7315",
    "polygon": [
      67.114,
      67.059,
      5.573,
      67.114,
      67.059,
      8.023,
      67.626,
      68.586,
      8.023,
      67.626,
      68.586,
      8.023,
      67.626,
      68.586,
      5.573,
      67.114,
      67.059,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7316",
    "polygon": [
      66.916,
      66.466,
      5.573,
      66.916,
      66.466,
      8.023,
      67.114,
      67.059,
      8.023,
      67.114,
      67.059,
      8.023,
      67.114,
      67.059,
      5.573,
      66.916,
      66.466,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7317",
    "polygon": [
      66.677,
      65.755,
      5.573,
      66.677,
      65.755,
      8.023,
      66.916,
      66.466,
      8.023,
      66.916,
      66.466,
      8.023,
      66.916,
      66.466,
      5.573,
      66.677,
      65.755,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7318",
    "polygon": [
      66.166,
      64.229,
      5.573,
      66.166,
      64.229,
      8.023,
      66.677,
      65.755,
      8.023,
      66.677,
      65.755,
      8.023,
      66.677,
      65.755,
      5.573,
      66.166,
      64.229,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7319",
    "polygon": [
      65.693,
      62.815,
      5.573,
      65.693,
      62.815,
      8.023,
      65.931,
      63.527,
      8.023,
      65.931,
      63.527,
      8.023,
      65.931,
      63.527,
      5.573,
      65.693,
      62.815,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7320",
    "polygon": [
      65.181,
      61.289,
      5.573,
      65.181,
      61.289,
      8.023,
      65.693,
      62.815,
      8.023,
      65.693,
      62.815,
      8.023,
      65.693,
      62.815,
      5.573,
      65.181,
      61.289,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7321",
    "polygon": [
      64.983,
      60.696,
      5.573,
      64.983,
      60.696,
      8.023,
      65.181,
      61.289,
      8.023,
      65.181,
      61.289,
      8.023,
      65.181,
      61.289,
      5.573,
      64.983,
      60.696,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7322",
    "polygon": [
      64.745,
      59.985,
      5.573,
      64.745,
      59.985,
      8.023,
      64.983,
      60.696,
      8.023,
      64.983,
      60.696,
      8.023,
      64.983,
      60.696,
      5.573,
      64.745,
      59.985,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7323",
    "polygon": [
      64.233,
      58.458,
      5.573,
      64.233,
      58.458,
      8.023,
      64.745,
      59.985,
      8.023,
      64.745,
      59.985,
      8.023,
      64.745,
      59.985,
      5.573,
      64.233,
      58.458,
      5.573
    ],
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7324",
    "polygon": [
      63.76,
      57.045,
      5.573,
      63.76,
      57.045,
      8.023,
      63.998,
      57.756,
      8.023,
      63.998,
      57.756,
      8.023,
      63.998,
      57.756,
      5.573,
      63.76,
      57.045,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7325",
    "polygon": [
      63.248,
      55.519,
      5.573,
      63.248,
      55.519,
      8.023,
      63.76,
      57.045,
      8.023,
      63.76,
      57.045,
      8.023,
      63.76,
      57.045,
      5.573,
      63.248,
      55.519,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7326",
    "polygon": [
      63.05,
      54.926,
      5.573,
      63.05,
      54.926,
      8.023,
      63.248,
      55.519,
      8.023,
      63.248,
      55.519,
      8.023,
      63.248,
      55.519,
      5.573,
      63.05,
      54.926,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7327",
    "polygon": [
      62.812,
      54.215,
      5.573,
      62.812,
      54.215,
      8.023,
      63.05,
      54.926,
      8.023,
      63.05,
      54.926,
      8.023,
      63.05,
      54.926,
      5.573,
      62.812,
      54.215,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7328",
    "polygon": [
      62.3,
      52.688,
      5.573,
      62.3,
      52.688,
      8.023,
      62.812,
      54.215,
      8.023,
      62.812,
      54.215,
      8.023,
      62.812,
      54.215,
      5.573,
      62.3,
      52.688,
      5.573
    ]
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7329",
    "polygon": [
      69.581,
      74.348,
      9.323,
      69.581,
      74.348,
      11.773,
      69.82,
      75.059,
      11.773,
      69.82,
      75.059,
      11.773,
      69.82,
      75.059,
      9.323,
      69.581,
      74.348,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7330",
    "polygon": [
      69.07,
      72.821,
      9.323,
      69.07,
      72.821,
      11.773,
      69.581,
      74.348,
      11.773,
      69.581,
      74.348,
      11.773,
      69.581,
      74.348,
      9.323,
      69.07,
      72.821,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7331",
    "polygon": [
      68.872,
      72.229,
      9.323,
      68.872,
      72.229,
      11.773,
      69.07,
      72.821,
      11.773,
      69.07,
      72.821,
      11.773,
      69.07,
      72.821,
      9.323,
      68.872,
      72.229,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7332",
    "polygon": [
      68.633,
      71.518,
      9.323,
      68.633,
      71.518,
      11.773,
      68.872,
      72.229,
      11.773,
      68.872,
      72.229,
      11.773,
      68.872,
      72.229,
      9.323,
      68.633,
      71.518,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7333",
    "polygon": [
      68.122,
      69.991,
      9.323,
      68.122,
      69.991,
      11.773,
      68.633,
      71.518,
      11.773,
      68.633,
      71.518,
      11.773,
      68.633,
      71.518,
      9.323,
      68.122,
      69.991,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7334",
    "polygon": [
      67.649,
      68.578,
      9.323,
      67.649,
      68.578,
      11.773,
      67.887,
      69.289,
      11.773,
      67.887,
      69.289,
      11.773,
      67.887,
      69.289,
      9.323,
      67.649,
      68.578,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7335",
    "polygon": [
      67.137,
      67.051,
      9.323,
      67.137,
      67.051,
      11.773,
      67.649,
      68.578,
      11.773,
      67.649,
      68.578,
      11.773,
      67.649,
      68.578,
      9.323,
      67.137,
      67.051,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7336",
    "polygon": [
      66.939,
      66.459,
      9.323,
      66.939,
      66.459,
      11.773,
      67.137,
      67.051,
      11.773,
      67.137,
      67.051,
      11.773,
      67.137,
      67.051,
      9.323,
      66.939,
      66.459,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7337",
    "polygon": [
      66.7,
      65.748,
      9.323,
      66.7,
      65.748,
      11.773,
      66.939,
      66.459,
      11.773,
      66.939,
      66.459,
      11.773,
      66.939,
      66.459,
      9.323,
      66.7,
      65.748,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7338",
    "polygon": [
      66.189,
      64.221,
      9.323,
      66.189,
      64.221,
      11.773,
      66.7,
      65.748,
      11.773,
      66.7,
      65.748,
      11.773,
      66.7,
      65.748,
      9.323,
      66.189,
      64.221,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7339",
    "polygon": [
      65.716,
      62.808,
      9.323,
      65.716,
      62.808,
      11.773,
      65.954,
      63.519,
      11.773,
      65.954,
      63.519,
      11.773,
      65.954,
      63.519,
      9.323,
      65.716,
      62.808,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7340",
    "polygon": [
      65.204,
      61.281,
      9.323,
      65.204,
      61.281,
      11.773,
      65.716,
      62.808,
      11.773,
      65.716,
      62.808,
      11.773,
      65.716,
      62.808,
      9.323,
      65.204,
      61.281,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7341",
    "polygon": [
      65.006,
      60.688,
      9.323,
      65.006,
      60.688,
      11.773,
      65.204,
      61.281,
      11.773,
      65.204,
      61.281,
      11.773,
      65.204,
      61.281,
      9.323,
      65.006,
      60.688,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7342",
    "polygon": [
      64.767,
      59.977,
      9.323,
      64.767,
      59.977,
      11.773,
      65.006,
      60.688,
      11.773,
      65.006,
      60.688,
      11.773,
      65.006,
      60.688,
      9.323,
      64.767,
      59.977,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7343",
    "polygon": [
      64.256,
      58.451,
      9.323,
      64.256,
      58.451,
      11.773,
      64.767,
      59.977,
      11.773,
      64.767,
      59.977,
      11.773,
      64.767,
      59.977,
      9.323,
      64.256,
      58.451,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7344",
    "polygon": [
      63.783,
      57.038,
      9.323,
      63.783,
      57.038,
      11.773,
      64.021,
      57.749,
      11.773,
      64.021,
      57.749,
      11.773,
      64.021,
      57.749,
      9.323,
      63.783,
      57.038,
      9.323
    ],
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7345",
    "polygon": [
      63.271,
      55.511,
      9.323,
      63.271,
      55.511,
      11.773,
      63.783,
      57.038,
      11.773,
      63.783,
      57.038,
      11.773,
      63.783,
      57.038,
      9.323,
      63.271,
      55.511,
      9.323
    ],
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7346",
    "polygon": [
      63.073,
      54.918,
      9.323,
      63.073,
      54.918,
      11.773,
      63.271,
      55.511,
      11.773,
      63.271,
      55.511,
      11.773,
      63.271,
      55.511,
      9.323,
      63.073,
      54.918,
      9.323
    ],
    "05-22 17:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7347",
    "polygon": [
      62.835,
      54.207,
      9.323,
      62.835,
      54.207,
      11.773,
      63.073,
      54.918,
      11.773,
      63.073,
      54.918,
      11.773,
      63.073,
      54.918,
      9.323,
      62.835,
      54.207,
      9.323
    ]
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7348",
    "polygon": [
      62.323,
      52.681,
      9.323,
      62.323,
      52.681,
      11.773,
      62.835,
      54.207,
      11.773,
      62.835,
      54.207,
      11.773,
      62.835,
      54.207,
      9.323,
      62.323,
      52.681,
      9.323
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7349",
    "polygon": [
      69.577,
      74.349,
      0.8,
      69.577,
      74.349,
      3.65,
      69.815,
      75.06,
      3.65,
      69.815,
      75.06,
      3.65,
      69.815,
      75.06,
      0.8,
      69.577,
      74.349,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7350",
    "polygon": [
      69.066,
      72.823,
      0.8,
      69.066,
      72.823,
      3.65,
      69.577,
      74.349,
      3.65,
      69.577,
      74.349,
      3.65,
      69.577,
      74.349,
      0.8,
      69.066,
      72.823,
      0.8
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7351",
    "polygon": [
      68.867,
      72.23,
      0.8,
      68.867,
      72.23,
      3.65,
      69.066,
      72.823,
      3.65,
      69.066,
      72.823,
      3.65,
      69.066,
      72.823,
      0.8,
      68.867,
      72.23,
      0.8
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7352",
    "polygon": [
      68.629,
      71.519,
      0.8,
      68.629,
      71.519,
      3.65,
      68.867,
      72.23,
      3.65,
      68.867,
      72.23,
      3.65,
      68.867,
      72.23,
      0.8,
      68.629,
      71.519,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7353",
    "polygon": [
      68.118,
      69.992,
      0.8,
      68.118,
      69.992,
      3.65,
      68.629,
      71.519,
      3.65,
      68.629,
      71.519,
      3.65,
      68.629,
      71.519,
      0.8,
      68.118,
      69.992,
      0.8
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7354",
    "polygon": [
      67.644,
      68.58,
      0.8,
      67.644,
      68.58,
      3.65,
      67.883,
      69.291,
      3.65,
      67.883,
      69.291,
      3.65,
      67.883,
      69.291,
      0.8,
      67.644,
      68.58,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7355",
    "polygon": [
      67.133,
      67.053,
      0.8,
      67.133,
      67.053,
      3.65,
      67.644,
      68.58,
      3.65,
      67.644,
      68.58,
      3.65,
      67.644,
      68.58,
      0.8,
      67.133,
      67.053,
      0.8
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7356",
    "polygon": [
      66.934,
      66.461,
      0.8,
      66.934,
      66.461,
      3.65,
      67.133,
      67.053,
      3.65,
      67.133,
      67.053,
      3.65,
      67.133,
      67.053,
      0.8,
      66.934,
      66.461,
      0.8
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7357",
    "polygon": [
      66.696,
      65.749,
      0.8,
      66.696,
      65.749,
      3.65,
      66.934,
      66.461,
      3.65,
      66.934,
      66.461,
      3.65,
      66.934,
      66.461,
      0.8,
      66.696,
      65.749,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7358",
    "polygon": [
      66.185,
      64.223,
      0.8,
      66.185,
      64.223,
      3.65,
      66.696,
      65.749,
      3.65,
      66.696,
      65.749,
      3.65,
      66.696,
      65.749,
      0.8,
      66.185,
      64.223,
      0.8
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7359",
    "polygon": [
      65.712,
      62.81,
      0.8,
      65.712,
      62.81,
      3.65,
      65.95,
      63.521,
      3.65,
      65.95,
      63.521,
      3.65,
      65.95,
      63.521,
      0.8,
      65.712,
      62.81,
      0.8
    ],
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7360",
    "polygon": [
      65.2,
      61.284,
      0.8,
      65.2,
      61.284,
      3.65,
      65.712,
      62.81,
      3.65,
      65.712,
      62.81,
      3.65,
      65.712,
      62.81,
      0.8,
      65.2,
      61.284,
      0.8
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7361",
    "polygon": [
      65.002,
      60.691,
      0.8,
      65.002,
      60.691,
      3.65,
      65.2,
      61.284,
      3.65,
      65.2,
      61.284,
      3.65,
      65.2,
      61.284,
      0.8,
      65.002,
      60.691,
      0.8
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7362",
    "polygon": [
      64.763,
      59.98,
      0.8,
      64.763,
      59.98,
      3.65,
      65.002,
      60.691,
      3.65,
      65.002,
      60.691,
      3.65,
      65.002,
      60.691,
      0.8,
      64.763,
      59.98,
      0.8
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7363",
    "polygon": [
      64.252,
      58.453,
      0.8,
      64.252,
      58.453,
      3.65,
      64.763,
      59.98,
      3.65,
      64.763,
      59.98,
      3.65,
      64.763,
      59.98,
      0.8,
      64.252,
      58.453,
      0.8
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7364",
    "polygon": [
      63.779,
      57.041,
      0.8,
      63.779,
      57.041,
      3.65,
      64.017,
      57.752,
      3.65,
      64.017,
      57.752,
      3.65,
      64.017,
      57.752,
      0.8,
      63.779,
      57.041,
      0.8
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7365",
    "polygon": [
      63.268,
      55.514,
      0.8,
      63.268,
      55.514,
      3.65,
      63.779,
      57.041,
      3.65,
      63.779,
      57.041,
      3.65,
      63.779,
      57.041,
      0.8,
      63.268,
      55.514,
      0.8
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7366",
    "polygon": [
      63.069,
      54.922,
      0.8,
      63.069,
      54.922,
      3.65,
      63.268,
      55.514,
      3.65,
      63.268,
      55.514,
      3.65,
      63.268,
      55.514,
      0.8,
      63.069,
      54.922,
      0.8
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7367",
    "polygon": [
      62.831,
      54.21,
      0.8,
      62.831,
      54.21,
      3.65,
      63.069,
      54.922,
      3.65,
      63.069,
      54.922,
      3.65,
      63.069,
      54.922,
      0.8,
      62.831,
      54.21,
      0.8
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7368",
    "polygon": [
      62.319,
      52.684,
      0.8,
      62.319,
      52.684,
      3.65,
      62.831,
      54.21,
      3.65,
      62.831,
      54.21,
      3.65,
      62.831,
      54.21,
      0.8,
      62.319,
      52.684,
      0.8
    ]
  },
  {
    "ID": "Block-E-Outer_3.OG_7370",
    "polygon": [
      49.487,
      14.491,
      13.073,
      49.487,
      14.491,
      15.523,
      50.017,
      16.075,
      15.523,
      50.017,
      16.075,
      15.523,
      50.017,
      16.075,
      13.073,
      49.487,
      14.491,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7371",
    "polygon": [
      50.018,
      16.076,
      13.073,
      50.018,
      16.076,
      15.523,
      50.256,
      16.787,
      15.523,
      50.256,
      16.787,
      15.523,
      50.256,
      16.787,
      13.073,
      50.018,
      16.076,
      13.073
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7372",
    "polygon": [
      50.256,
      16.787,
      13.073,
      50.256,
      16.787,
      15.523,
      50.455,
      17.38,
      15.523,
      50.455,
      17.38,
      15.523,
      50.455,
      17.38,
      13.073,
      50.256,
      16.787,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7373",
    "polygon": [
      50.455,
      17.38,
      13.073,
      50.455,
      17.38,
      15.523,
      51.042,
      19.134,
      15.523,
      51.042,
      19.134,
      15.523,
      51.042,
      19.134,
      13.073,
      50.455,
      17.38,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7369",
    "polygon": [
      47.468,
      8.465,
      13.073,
      47.468,
      8.465,
      15.523,
      47.999,
      10.049,
      15.523,
      47.999,
      10.049,
      15.523,
      47.999,
      10.049,
      13.073,
      47.468,
      8.465,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7374",
    "polygon": [
      47.999,
      10.05,
      13.073,
      47.999,
      10.05,
      15.523,
      48.237,
      10.761,
      15.523,
      48.237,
      10.761,
      15.523,
      48.237,
      10.761,
      13.073,
      47.999,
      10.05,
      13.073
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7375",
    "polygon": [
      48.237,
      10.761,
      13.073,
      48.237,
      10.761,
      15.523,
      48.436,
      11.354,
      15.523,
      48.436,
      11.354,
      15.523,
      48.436,
      11.354,
      13.073,
      48.237,
      10.761,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7376",
    "polygon": [
      48.436,
      11.354,
      13.073,
      48.436,
      11.354,
      15.523,
      49.024,
      13.108,
      15.523,
      49.024,
      13.108,
      15.523,
      49.024,
      13.108,
      13.073,
      48.436,
      11.354,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7377",
    "polygon": [
      47.014,
      7.109,
      13.073,
      47.014,
      7.109,
      15.523,
      47.252,
      7.82,
      15.523,
      47.252,
      7.82,
      15.523,
      47.252,
      7.82,
      13.073,
      47.014,
      7.109,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7378",
    "polygon": [
      46.503,
      5.582,
      13.073,
      46.503,
      5.582,
      15.523,
      47.014,
      7.109,
      15.523,
      47.014,
      7.109,
      15.523,
      47.014,
      7.109,
      13.073,
      46.503,
      5.582,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7379",
    "polygon": [
      46.304,
      4.99,
      13.073,
      46.304,
      4.99,
      15.523,
      46.503,
      5.582,
      15.523,
      46.503,
      5.582,
      15.523,
      46.503,
      5.582,
      13.073,
      46.304,
      4.99,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7380",
    "polygon": [
      46.066,
      4.278,
      13.073,
      46.066,
      4.278,
      15.523,
      46.304,
      4.99,
      15.523,
      46.304,
      4.99,
      15.523,
      46.304,
      4.99,
      13.073,
      46.066,
      4.278,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7381",
    "polygon": [
      45.554,
      2.752,
      13.073,
      45.554,
      2.752,
      15.523,
      46.066,
      4.278,
      15.523,
      46.066,
      4.278,
      15.523,
      46.066,
      4.278,
      13.073,
      45.554,
      2.752,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7382",
    "polygon": [
      45.029,
      1.182,
      13.073,
      45.029,
      1.182,
      15.523,
      45.267,
      1.893,
      15.523,
      45.267,
      1.893,
      15.523,
      45.267,
      1.893,
      13.073,
      45.029,
      1.182,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7383",
    "polygon": [
      44.517,
      -0.344,
      13.073,
      44.517,
      -0.344,
      15.523,
      45.029,
      1.182,
      15.523,
      45.029,
      1.182,
      15.523,
      45.029,
      1.182,
      13.073,
      44.517,
      -0.344,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7384",
    "polygon": [
      44.319,
      -0.937,
      13.073,
      44.319,
      -0.937,
      15.523,
      44.517,
      -0.344,
      15.523,
      44.517,
      -0.344,
      15.523,
      44.517,
      -0.344,
      13.073,
      44.319,
      -0.937,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7385",
    "polygon": [
      44.081,
      -1.648,
      13.073,
      44.081,
      -1.648,
      15.523,
      44.319,
      -0.937,
      15.523,
      44.319,
      -0.937,
      15.523,
      44.319,
      -0.937,
      13.073,
      44.081,
      -1.648,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7386",
    "polygon": [
      43.569,
      -3.175,
      13.073,
      43.569,
      -3.175,
      15.523,
      44.081,
      -1.648,
      15.523,
      44.081,
      -1.648,
      15.523,
      44.081,
      -1.648,
      13.073,
      43.569,
      -3.175,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7387",
    "polygon": [
      43.043,
      -4.744,
      13.073,
      43.043,
      -4.744,
      15.523,
      43.282,
      -4.033,
      15.523,
      43.282,
      -4.033,
      15.523,
      43.282,
      -4.033,
      13.073,
      43.043,
      -4.744,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7388",
    "polygon": [
      42.532,
      -6.271,
      13.073,
      42.532,
      -6.271,
      15.523,
      43.043,
      -4.744,
      15.523,
      43.043,
      -4.744,
      15.523,
      43.043,
      -4.744,
      13.073,
      42.532,
      -6.271,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7389",
    "polygon": [
      42.333,
      -6.864,
      13.073,
      42.333,
      -6.864,
      15.523,
      42.532,
      -6.271,
      15.523,
      42.532,
      -6.271,
      15.523,
      42.532,
      -6.271,
      13.073,
      42.333,
      -6.864,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7390",
    "polygon": [
      42.095,
      -7.575,
      13.073,
      42.095,
      -7.575,
      15.523,
      42.333,
      -6.864,
      15.523,
      42.333,
      -6.864,
      15.523,
      42.333,
      -6.864,
      13.073,
      42.095,
      -7.575,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7391",
    "polygon": [
      41.584,
      -9.101,
      13.073,
      41.584,
      -9.101,
      15.523,
      42.095,
      -7.575,
      15.523,
      42.095,
      -7.575,
      15.523,
      42.095,
      -7.575,
      13.073,
      41.584,
      -9.101,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7392",
    "polygon": [
      41.058,
      -10.671,
      13.073,
      41.058,
      -10.671,
      15.523,
      41.296,
      -9.96,
      15.523,
      41.296,
      -9.96,
      15.523,
      41.296,
      -9.96,
      13.073,
      41.058,
      -10.671,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7393",
    "polygon": [
      40.547,
      -12.198,
      13.073,
      40.547,
      -12.198,
      15.523,
      41.058,
      -10.671,
      15.523,
      41.058,
      -10.671,
      15.523,
      41.058,
      -10.671,
      13.073,
      40.547,
      -12.198,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7394",
    "polygon": [
      40.348,
      -12.79,
      13.073,
      40.348,
      -12.79,
      15.523,
      40.547,
      -12.198,
      15.523,
      40.547,
      -12.198,
      15.523,
      40.547,
      -12.198,
      13.073,
      40.348,
      -12.79,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7395",
    "polygon": [
      40.11,
      -13.501,
      13.073,
      40.11,
      -13.501,
      15.523,
      40.348,
      -12.79,
      15.523,
      40.348,
      -12.79,
      15.523,
      40.348,
      -12.79,
      13.073,
      40.11,
      -13.501,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7396",
    "polygon": [
      39.598,
      -15.028,
      13.073,
      39.598,
      -15.028,
      15.523,
      40.11,
      -13.501,
      15.523,
      40.11,
      -13.501,
      15.523,
      40.11,
      -13.501,
      13.073,
      39.598,
      -15.028,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7397",
    "polygon": [
      39.073,
      -16.598,
      13.073,
      39.073,
      -16.598,
      15.523,
      39.311,
      -15.886,
      15.523,
      39.311,
      -15.886,
      15.523,
      39.311,
      -15.886,
      13.073,
      39.073,
      -16.598,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7398",
    "polygon": [
      38.561,
      -18.124,
      13.073,
      38.561,
      -18.124,
      15.523,
      39.073,
      -16.598,
      15.523,
      39.073,
      -16.598,
      15.523,
      39.073,
      -16.598,
      13.073,
      38.561,
      -18.124,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7399",
    "polygon": [
      38.363,
      -18.717,
      13.073,
      38.363,
      -18.717,
      15.523,
      38.561,
      -18.124,
      15.523,
      38.561,
      -18.124,
      15.523,
      38.561,
      -18.124,
      13.073,
      38.363,
      -18.717,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7400",
    "polygon": [
      38.125,
      -19.428,
      13.073,
      38.125,
      -19.428,
      15.523,
      38.363,
      -18.717,
      15.523,
      38.363,
      -18.717,
      15.523,
      38.363,
      -18.717,
      13.073,
      38.125,
      -19.428,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7401",
    "polygon": [
      37.613,
      -20.955,
      13.073,
      37.613,
      -20.955,
      15.523,
      38.125,
      -19.428,
      15.523,
      38.125,
      -19.428,
      15.523,
      38.125,
      -19.428,
      13.073,
      37.613,
      -20.955,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7402",
    "polygon": [
      49.484,
      14.492,
      5.573,
      49.484,
      14.492,
      8.023,
      50.015,
      16.075,
      8.023,
      50.015,
      16.075,
      8.023,
      50.015,
      16.075,
      5.573,
      49.484,
      14.492,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7403",
    "polygon": [
      50.015,
      16.077,
      5.573,
      50.015,
      16.077,
      8.023,
      50.253,
      16.788,
      8.023,
      50.253,
      16.788,
      8.023,
      50.253,
      16.788,
      5.573,
      50.015,
      16.077,
      5.573
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7404",
    "polygon": [
      50.253,
      16.788,
      5.573,
      50.253,
      16.788,
      8.023,
      50.452,
      17.381,
      8.023,
      50.452,
      17.381,
      8.023,
      50.452,
      17.381,
      5.573,
      50.253,
      16.788,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7405",
    "polygon": [
      50.452,
      17.381,
      5.573,
      50.452,
      17.381,
      8.023,
      51.04,
      19.135,
      8.023,
      51.04,
      19.135,
      8.023,
      51.04,
      19.135,
      5.573,
      50.452,
      17.381,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7406",
    "polygon": [
      47.466,
      8.466,
      5.573,
      47.466,
      8.466,
      8.023,
      47.996,
      10.05,
      8.023,
      47.996,
      10.05,
      8.023,
      47.996,
      10.05,
      5.573,
      47.466,
      8.466,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7407",
    "polygon": [
      47.997,
      10.051,
      5.573,
      47.997,
      10.051,
      8.023,
      48.235,
      10.762,
      8.023,
      48.235,
      10.762,
      8.023,
      48.235,
      10.762,
      5.573,
      47.997,
      10.051,
      5.573
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7408",
    "polygon": [
      48.235,
      10.762,
      5.573,
      48.235,
      10.762,
      8.023,
      48.433,
      11.355,
      8.023,
      48.433,
      11.355,
      8.023,
      48.433,
      11.355,
      5.573,
      48.235,
      10.762,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7409",
    "polygon": [
      48.433,
      11.355,
      5.573,
      48.433,
      11.355,
      8.023,
      49.021,
      13.109,
      8.023,
      49.021,
      13.109,
      8.023,
      49.021,
      13.109,
      5.573,
      48.433,
      11.355,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7410",
    "polygon": [
      47.011,
      7.11,
      5.573,
      47.011,
      7.11,
      8.023,
      47.25,
      7.821,
      8.023,
      47.25,
      7.821,
      8.023,
      47.25,
      7.821,
      5.573,
      47.011,
      7.11,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7411",
    "polygon": [
      46.5,
      5.583,
      5.573,
      46.5,
      5.583,
      8.023,
      47.011,
      7.11,
      8.023,
      47.011,
      7.11,
      8.023,
      47.011,
      7.11,
      5.573,
      46.5,
      5.583,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7412",
    "polygon": [
      46.301,
      4.99,
      5.573,
      46.301,
      4.99,
      8.023,
      46.5,
      5.583,
      8.023,
      46.5,
      5.583,
      8.023,
      46.5,
      5.583,
      5.573,
      46.301,
      4.99,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7413",
    "polygon": [
      46.063,
      4.279,
      5.573,
      46.063,
      4.279,
      8.023,
      46.301,
      4.99,
      8.023,
      46.301,
      4.99,
      8.023,
      46.301,
      4.99,
      5.573,
      46.063,
      4.279,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7414",
    "polygon": [
      45.552,
      2.753,
      5.573,
      45.552,
      2.753,
      8.023,
      46.063,
      4.279,
      8.023,
      46.063,
      4.279,
      8.023,
      46.063,
      4.279,
      5.573,
      45.552,
      2.753,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7415",
    "polygon": [
      45.026,
      1.183,
      5.573,
      45.026,
      1.183,
      8.023,
      45.264,
      1.894,
      8.023,
      45.264,
      1.894,
      8.023,
      45.264,
      1.894,
      5.573,
      45.026,
      1.183,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7416",
    "polygon": [
      44.515,
      -0.344,
      5.573,
      44.515,
      -0.344,
      8.023,
      45.026,
      1.183,
      8.023,
      45.026,
      1.183,
      8.023,
      45.026,
      1.183,
      5.573,
      44.515,
      -0.344,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7417",
    "polygon": [
      44.316,
      -0.936,
      5.573,
      44.316,
      -0.936,
      8.023,
      44.515,
      -0.344,
      8.023,
      44.515,
      -0.344,
      8.023,
      44.515,
      -0.344,
      5.573,
      44.316,
      -0.936,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7418",
    "polygon": [
      44.078,
      -1.647,
      5.573,
      44.078,
      -1.647,
      8.023,
      44.316,
      -0.936,
      8.023,
      44.316,
      -0.936,
      8.023,
      44.316,
      -0.936,
      5.573,
      44.078,
      -1.647,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7419",
    "polygon": [
      43.566,
      -3.174,
      5.573,
      43.566,
      -3.174,
      8.023,
      44.078,
      -1.647,
      8.023,
      44.078,
      -1.647,
      8.023,
      44.078,
      -1.647,
      5.573,
      43.566,
      -3.174,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7420",
    "polygon": [
      43.041,
      -4.744,
      5.573,
      43.041,
      -4.744,
      8.023,
      43.279,
      -4.032,
      8.023,
      43.279,
      -4.032,
      8.023,
      43.279,
      -4.032,
      5.573,
      43.041,
      -4.744,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7421",
    "polygon": [
      42.529,
      -6.27,
      5.573,
      42.529,
      -6.27,
      8.023,
      43.041,
      -4.744,
      8.023,
      43.041,
      -4.744,
      8.023,
      43.041,
      -4.744,
      5.573,
      42.529,
      -6.27,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7422",
    "polygon": [
      42.331,
      -6.863,
      5.573,
      42.331,
      -6.863,
      8.023,
      42.529,
      -6.27,
      8.023,
      42.529,
      -6.27,
      8.023,
      42.529,
      -6.27,
      5.573,
      42.331,
      -6.863,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7423",
    "polygon": [
      42.093,
      -7.574,
      5.573,
      42.093,
      -7.574,
      8.023,
      42.331,
      -6.863,
      8.023,
      42.331,
      -6.863,
      8.023,
      42.331,
      -6.863,
      5.573,
      42.093,
      -7.574,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7424",
    "polygon": [
      41.581,
      -9.101,
      5.573,
      41.581,
      -9.101,
      8.023,
      42.093,
      -7.574,
      8.023,
      42.093,
      -7.574,
      8.023,
      42.093,
      -7.574,
      5.573,
      41.581,
      -9.101,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7425",
    "polygon": [
      41.055,
      -10.67,
      5.573,
      41.055,
      -10.67,
      8.023,
      41.294,
      -9.959,
      8.023,
      41.294,
      -9.959,
      8.023,
      41.294,
      -9.959,
      5.573,
      41.055,
      -10.67,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7426",
    "polygon": [
      40.544,
      -12.197,
      5.573,
      40.544,
      -12.197,
      8.023,
      41.055,
      -10.67,
      8.023,
      41.055,
      -10.67,
      8.023,
      41.055,
      -10.67,
      5.573,
      40.544,
      -12.197,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7427",
    "polygon": [
      40.345,
      -12.789,
      5.573,
      40.345,
      -12.789,
      8.023,
      40.544,
      -12.197,
      8.023,
      40.544,
      -12.197,
      8.023,
      40.544,
      -12.197,
      5.573,
      40.345,
      -12.789,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7428",
    "polygon": [
      40.107,
      -13.501,
      5.573,
      40.107,
      -13.501,
      8.023,
      40.345,
      -12.789,
      8.023,
      40.345,
      -12.789,
      8.023,
      40.345,
      -12.789,
      5.573,
      40.107,
      -13.501,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7429",
    "polygon": [
      39.596,
      -15.027,
      5.573,
      39.596,
      -15.027,
      8.023,
      40.107,
      -13.501,
      8.023,
      40.107,
      -13.501,
      8.023,
      40.107,
      -13.501,
      5.573,
      39.596,
      -15.027,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7430",
    "polygon": [
      39.07,
      -16.597,
      5.573,
      39.07,
      -16.597,
      8.023,
      39.308,
      -15.886,
      8.023,
      39.308,
      -15.886,
      8.023,
      39.308,
      -15.886,
      5.573,
      39.07,
      -16.597,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7431",
    "polygon": [
      38.559,
      -18.123,
      5.573,
      38.559,
      -18.123,
      8.023,
      39.07,
      -16.597,
      8.023,
      39.07,
      -16.597,
      8.023,
      39.07,
      -16.597,
      5.573,
      38.559,
      -18.123,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7432",
    "polygon": [
      38.36,
      -18.716,
      5.573,
      38.36,
      -18.716,
      8.023,
      38.559,
      -18.123,
      8.023,
      38.559,
      -18.123,
      8.023,
      38.559,
      -18.123,
      5.573,
      38.36,
      -18.716,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7433",
    "polygon": [
      38.122,
      -19.427,
      5.573,
      38.122,
      -19.427,
      8.023,
      38.36,
      -18.716,
      8.023,
      38.36,
      -18.716,
      8.023,
      38.36,
      -18.716,
      5.573,
      38.122,
      -19.427,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7434",
    "polygon": [
      37.61,
      -20.954,
      5.573,
      37.61,
      -20.954,
      8.023,
      38.122,
      -19.427,
      8.023,
      38.122,
      -19.427,
      8.023,
      38.122,
      -19.427,
      5.573,
      37.61,
      -20.954,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7435",
    "polygon": [
      49.487,
      14.491,
      9.323,
      49.487,
      14.491,
      11.773,
      50.017,
      16.075,
      11.773,
      50.017,
      16.075,
      11.773,
      50.017,
      16.075,
      9.323,
      49.487,
      14.491,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7436",
    "polygon": [
      50.018,
      16.076,
      9.323,
      50.018,
      16.076,
      11.773,
      50.256,
      16.787,
      11.773,
      50.256,
      16.787,
      11.773,
      50.256,
      16.787,
      9.323,
      50.018,
      16.076,
      9.323
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7437",
    "polygon": [
      50.256,
      16.787,
      9.323,
      50.256,
      16.787,
      11.773,
      50.455,
      17.38,
      11.773,
      50.455,
      17.38,
      11.773,
      50.455,
      17.38,
      9.323,
      50.256,
      16.787,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7438",
    "polygon": [
      50.455,
      17.38,
      9.323,
      50.455,
      17.38,
      11.773,
      51.042,
      19.134,
      11.773,
      51.042,
      19.134,
      11.773,
      51.042,
      19.134,
      9.323,
      50.455,
      17.38,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7439",
    "polygon": [
      47.468,
      8.465,
      9.323,
      47.468,
      8.465,
      11.773,
      47.999,
      10.049,
      11.773,
      47.999,
      10.049,
      11.773,
      47.999,
      10.049,
      9.323,
      47.468,
      8.465,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7440",
    "polygon": [
      47.999,
      10.05,
      9.323,
      47.999,
      10.05,
      11.773,
      48.237,
      10.761,
      11.773,
      48.237,
      10.761,
      11.773,
      48.237,
      10.761,
      9.323,
      47.999,
      10.05,
      9.323
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7441",
    "polygon": [
      48.237,
      10.761,
      9.323,
      48.237,
      10.761,
      11.773,
      48.436,
      11.354,
      11.773,
      48.436,
      11.354,
      11.773,
      48.436,
      11.354,
      9.323,
      48.237,
      10.761,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7442",
    "polygon": [
      48.436,
      11.354,
      9.323,
      48.436,
      11.354,
      11.773,
      49.024,
      13.108,
      11.773,
      49.024,
      13.108,
      11.773,
      49.024,
      13.108,
      9.323,
      48.436,
      11.354,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7443",
    "polygon": [
      47.014,
      7.109,
      9.323,
      47.014,
      7.109,
      11.773,
      47.252,
      7.82,
      11.773,
      47.252,
      7.82,
      11.773,
      47.252,
      7.82,
      9.323,
      47.014,
      7.109,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7444",
    "polygon": [
      46.503,
      5.582,
      9.323,
      46.503,
      5.582,
      11.773,
      47.014,
      7.109,
      11.773,
      47.014,
      7.109,
      11.773,
      47.014,
      7.109,
      9.323,
      46.503,
      5.582,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7445",
    "polygon": [
      46.304,
      4.99,
      9.323,
      46.304,
      4.99,
      11.773,
      46.503,
      5.582,
      11.773,
      46.503,
      5.582,
      11.773,
      46.503,
      5.582,
      9.323,
      46.304,
      4.99,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7446",
    "polygon": [
      46.066,
      4.278,
      9.323,
      46.066,
      4.278,
      11.773,
      46.304,
      4.99,
      11.773,
      46.304,
      4.99,
      11.773,
      46.304,
      4.99,
      9.323,
      46.066,
      4.278,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7447",
    "polygon": [
      45.554,
      2.752,
      9.323,
      45.554,
      2.752,
      11.773,
      46.066,
      4.278,
      11.773,
      46.066,
      4.278,
      11.773,
      46.066,
      4.278,
      9.323,
      45.554,
      2.752,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7448",
    "polygon": [
      45.029,
      1.182,
      9.323,
      45.029,
      1.182,
      11.773,
      45.267,
      1.893,
      11.773,
      45.267,
      1.893,
      11.773,
      45.267,
      1.893,
      9.323,
      45.029,
      1.182,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7449",
    "polygon": [
      44.517,
      -0.344,
      9.323,
      44.517,
      -0.344,
      11.773,
      45.029,
      1.182,
      11.773,
      45.029,
      1.182,
      11.773,
      45.029,
      1.182,
      9.323,
      44.517,
      -0.344,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7450",
    "polygon": [
      44.319,
      -0.937,
      9.323,
      44.319,
      -0.937,
      11.773,
      44.517,
      -0.344,
      11.773,
      44.517,
      -0.344,
      11.773,
      44.517,
      -0.344,
      9.323,
      44.319,
      -0.937,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7451",
    "polygon": [
      44.081,
      -1.648,
      9.323,
      44.081,
      -1.648,
      11.773,
      44.319,
      -0.937,
      11.773,
      44.319,
      -0.937,
      11.773,
      44.319,
      -0.937,
      9.323,
      44.081,
      -1.648,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7452",
    "polygon": [
      43.569,
      -3.175,
      9.323,
      43.569,
      -3.175,
      11.773,
      44.081,
      -1.648,
      11.773,
      44.081,
      -1.648,
      11.773,
      44.081,
      -1.648,
      9.323,
      43.569,
      -3.175,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7453",
    "polygon": [
      43.043,
      -4.744,
      9.323,
      43.043,
      -4.744,
      11.773,
      43.282,
      -4.033,
      11.773,
      43.282,
      -4.033,
      11.773,
      43.282,
      -4.033,
      9.323,
      43.043,
      -4.744,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7454",
    "polygon": [
      42.532,
      -6.271,
      9.323,
      42.532,
      -6.271,
      11.773,
      43.043,
      -4.744,
      11.773,
      43.043,
      -4.744,
      11.773,
      43.043,
      -4.744,
      9.323,
      42.532,
      -6.271,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7455",
    "polygon": [
      42.333,
      -6.864,
      9.323,
      42.333,
      -6.864,
      11.773,
      42.532,
      -6.271,
      11.773,
      42.532,
      -6.271,
      11.773,
      42.532,
      -6.271,
      9.323,
      42.333,
      -6.864,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7456",
    "polygon": [
      42.095,
      -7.575,
      9.323,
      42.095,
      -7.575,
      11.773,
      42.333,
      -6.864,
      11.773,
      42.333,
      -6.864,
      11.773,
      42.333,
      -6.864,
      9.323,
      42.095,
      -7.575,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7457",
    "polygon": [
      41.584,
      -9.101,
      9.323,
      41.584,
      -9.101,
      11.773,
      42.095,
      -7.575,
      11.773,
      42.095,
      -7.575,
      11.773,
      42.095,
      -7.575,
      9.323,
      41.584,
      -9.101,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7458",
    "polygon": [
      41.058,
      -10.671,
      9.323,
      41.058,
      -10.671,
      11.773,
      41.296,
      -9.96,
      11.773,
      41.296,
      -9.96,
      11.773,
      41.296,
      -9.96,
      9.323,
      41.058,
      -10.671,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7459",
    "polygon": [
      40.547,
      -12.198,
      9.323,
      40.547,
      -12.198,
      11.773,
      41.058,
      -10.671,
      11.773,
      41.058,
      -10.671,
      11.773,
      41.058,
      -10.671,
      9.323,
      40.547,
      -12.198,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7460",
    "polygon": [
      40.348,
      -12.79,
      9.323,
      40.348,
      -12.79,
      11.773,
      40.547,
      -12.198,
      11.773,
      40.547,
      -12.198,
      11.773,
      40.547,
      -12.198,
      9.323,
      40.348,
      -12.79,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7461",
    "polygon": [
      40.11,
      -13.501,
      9.323,
      40.11,
      -13.501,
      11.773,
      40.348,
      -12.79,
      11.773,
      40.348,
      -12.79,
      11.773,
      40.348,
      -12.79,
      9.323,
      40.11,
      -13.501,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7462",
    "polygon": [
      39.598,
      -15.028,
      9.323,
      39.598,
      -15.028,
      11.773,
      40.11,
      -13.501,
      11.773,
      40.11,
      -13.501,
      11.773,
      40.11,
      -13.501,
      9.323,
      39.598,
      -15.028,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7463",
    "polygon": [
      39.073,
      -16.598,
      9.323,
      39.073,
      -16.598,
      11.773,
      39.311,
      -15.886,
      11.773,
      39.311,
      -15.886,
      11.773,
      39.311,
      -15.886,
      9.323,
      39.073,
      -16.598,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7464",
    "polygon": [
      38.561,
      -18.124,
      9.323,
      38.561,
      -18.124,
      11.773,
      39.073,
      -16.598,
      11.773,
      39.073,
      -16.598,
      11.773,
      39.073,
      -16.598,
      9.323,
      38.561,
      -18.124,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7465",
    "polygon": [
      38.363,
      -18.717,
      9.323,
      38.363,
      -18.717,
      11.773,
      38.561,
      -18.124,
      11.773,
      38.561,
      -18.124,
      11.773,
      38.561,
      -18.124,
      9.323,
      38.363,
      -18.717,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7466",
    "polygon": [
      38.125,
      -19.428,
      9.323,
      38.125,
      -19.428,
      11.773,
      38.363,
      -18.717,
      11.773,
      38.363,
      -18.717,
      11.773,
      38.363,
      -18.717,
      9.323,
      38.125,
      -19.428,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7467",
    "polygon": [
      37.613,
      -20.955,
      9.323,
      37.613,
      -20.955,
      11.773,
      38.125,
      -19.428,
      11.773,
      38.125,
      -19.428,
      11.773,
      38.125,
      -19.428,
      9.323,
      37.613,
      -20.955,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_7468",
    "polygon": [
      49.024,
      13.108,
      13.073,
      49.024,
      13.108,
      15.523,
      49.262,
      13.819,
      15.523,
      49.262,
      13.819,
      15.523,
      49.262,
      13.819,
      13.073,
      49.024,
      13.108,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_7469",
    "polygon": [
      49.021,
      13.109,
      5.573,
      49.021,
      13.109,
      8.023,
      49.259,
      13.82,
      8.023,
      49.259,
      13.82,
      8.023,
      49.259,
      13.82,
      5.573,
      49.021,
      13.109,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_7470",
    "polygon": [
      49.024,
      13.108,
      9.323,
      49.024,
      13.108,
      11.773,
      49.262,
      13.819,
      11.773,
      49.262,
      13.819,
      11.773,
      49.262,
      13.819,
      9.323,
      49.024,
      13.108,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_7471",
    "polygon": [
      49.484,
      14.492,
      0.67,
      49.484,
      14.492,
      3.45,
      50.014,
      16.076,
      3.45,
      50.014,
      16.076,
      3.45,
      50.014,
      16.076,
      0.67,
      49.484,
      14.492,
      0.67
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7472",
    "polygon": [
      50.014,
      16.077,
      0.67,
      50.014,
      16.077,
      3.45,
      50.253,
      16.788,
      3.45,
      50.253,
      16.788,
      3.45,
      50.253,
      16.788,
      0.67,
      50.014,
      16.077,
      0.67
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7473",
    "polygon": [
      50.253,
      16.788,
      0.67,
      50.253,
      16.788,
      3.45,
      50.451,
      17.381,
      3.45,
      50.451,
      17.381,
      3.45,
      50.451,
      17.381,
      0.67,
      50.253,
      16.788,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7474",
    "polygon": [
      50.451,
      17.381,
      0.67,
      50.451,
      17.381,
      3.45,
      51.039,
      19.135,
      3.45,
      51.039,
      19.135,
      3.45,
      51.039,
      19.135,
      0.67,
      50.451,
      17.381,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7475",
    "polygon": [
      47.465,
      8.466,
      0.67,
      47.465,
      8.466,
      3.45,
      47.995,
      10.05,
      3.45,
      47.995,
      10.05,
      3.45,
      47.995,
      10.05,
      0.67,
      47.465,
      8.466,
      0.67
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7476",
    "polygon": [
      47.996,
      10.051,
      0.67,
      47.996,
      10.051,
      3.45,
      48.234,
      10.762,
      3.45,
      48.234,
      10.762,
      3.45,
      48.234,
      10.762,
      0.67,
      47.996,
      10.051,
      0.67
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7477",
    "polygon": [
      48.234,
      10.762,
      0.67,
      48.234,
      10.762,
      3.45,
      48.433,
      11.355,
      3.45,
      48.433,
      11.355,
      3.45,
      48.433,
      11.355,
      0.67,
      48.234,
      10.762,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7478",
    "polygon": [
      48.433,
      11.355,
      0.67,
      48.433,
      11.355,
      3.45,
      49.02,
      13.109,
      3.45,
      49.02,
      13.109,
      3.45,
      49.02,
      13.109,
      0.67,
      48.433,
      11.355,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7479",
    "polygon": [
      47.011,
      7.11,
      0.67,
      47.011,
      7.11,
      3.45,
      47.249,
      7.821,
      3.45,
      47.249,
      7.821,
      3.45,
      47.249,
      7.821,
      0.67,
      47.011,
      7.11,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7480",
    "polygon": [
      46.499,
      5.583,
      0.67,
      46.499,
      5.583,
      3.45,
      47.011,
      7.11,
      3.45,
      47.011,
      7.11,
      3.45,
      47.011,
      7.11,
      0.67,
      46.499,
      5.583,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7481",
    "polygon": [
      46.301,
      4.991,
      0.67,
      46.301,
      4.991,
      3.45,
      46.499,
      5.583,
      3.45,
      46.499,
      5.583,
      3.45,
      46.499,
      5.583,
      0.67,
      46.301,
      4.991,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7482",
    "polygon": [
      46.062,
      4.279,
      0.67,
      46.062,
      4.279,
      3.45,
      46.301,
      4.991,
      3.45,
      46.301,
      4.991,
      3.45,
      46.301,
      4.991,
      0.67,
      46.062,
      4.279,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7483",
    "polygon": [
      45.551,
      2.753,
      0.67,
      45.551,
      2.753,
      3.45,
      46.062,
      4.279,
      3.45,
      46.062,
      4.279,
      3.45,
      46.062,
      4.279,
      0.67,
      45.551,
      2.753,
      0.67
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7489",
    "polygon": [
      43.04,
      -4.743,
      0.67,
      43.04,
      -4.743,
      3.45,
      43.278,
      -4.032,
      3.45,
      43.278,
      -4.032,
      3.45,
      43.278,
      -4.032,
      0.67,
      43.04,
      -4.743,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7490",
    "polygon": [
      42.529,
      -6.27,
      0.67,
      42.529,
      -6.27,
      3.45,
      43.04,
      -4.743,
      3.45,
      43.04,
      -4.743,
      3.45,
      43.04,
      -4.743,
      0.67,
      42.529,
      -6.27,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7491",
    "polygon": [
      42.33,
      -6.863,
      0.67,
      42.33,
      -6.863,
      3.45,
      42.529,
      -6.27,
      3.45,
      42.529,
      -6.27,
      3.45,
      42.529,
      -6.27,
      0.67,
      42.33,
      -6.863,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7492",
    "polygon": [
      42.092,
      -7.574,
      0.67,
      42.092,
      -7.574,
      3.45,
      42.33,
      -6.863,
      3.45,
      42.33,
      -6.863,
      3.45,
      42.33,
      -6.863,
      0.67,
      42.092,
      -7.574,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7493",
    "polygon": [
      41.58,
      -9.1,
      0.67,
      41.58,
      -9.1,
      3.45,
      42.092,
      -7.574,
      3.45,
      42.092,
      -7.574,
      3.45,
      42.092,
      -7.574,
      0.67,
      41.58,
      -9.1,
      0.67
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7494",
    "polygon": [
      41.055,
      -10.67,
      0.67,
      41.055,
      -10.67,
      3.45,
      41.293,
      -9.959,
      3.45,
      41.293,
      -9.959,
      3.45,
      41.293,
      -9.959,
      0.67,
      41.055,
      -10.67,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7495",
    "polygon": [
      40.543,
      -12.197,
      0.67,
      40.543,
      -12.197,
      3.45,
      41.055,
      -10.67,
      3.45,
      41.055,
      -10.67,
      3.45,
      41.055,
      -10.67,
      0.67,
      40.543,
      -12.197,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7496",
    "polygon": [
      40.345,
      -12.789,
      0.67,
      40.345,
      -12.789,
      3.45,
      40.543,
      -12.197,
      3.45,
      40.543,
      -12.197,
      3.45,
      40.543,
      -12.197,
      0.67,
      40.345,
      -12.789,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7497",
    "polygon": [
      40.106,
      -13.5,
      0.67,
      40.106,
      -13.5,
      3.45,
      40.345,
      -12.789,
      3.45,
      40.345,
      -12.789,
      3.45,
      40.345,
      -12.789,
      0.67,
      40.106,
      -13.5,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7498",
    "polygon": [
      39.595,
      -15.027,
      0.67,
      39.595,
      -15.027,
      3.45,
      40.106,
      -13.5,
      3.45,
      40.106,
      -13.5,
      3.45,
      40.106,
      -13.5,
      0.67,
      39.595,
      -15.027,
      0.67
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_7499",
    "polygon": [
      39.069,
      -16.596,
      0.67,
      39.069,
      -16.596,
      3.45,
      39.308,
      -15.885,
      3.45,
      39.308,
      -15.885,
      3.45,
      39.308,
      -15.885,
      0.67,
      39.069,
      -16.596,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_7500",
    "polygon": [
      38.558,
      -18.123,
      0.67,
      38.558,
      -18.123,
      3.45,
      39.069,
      -16.596,
      3.45,
      39.069,
      -16.596,
      3.45,
      39.069,
      -16.596,
      0.67,
      38.558,
      -18.123,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_7501",
    "polygon": [
      38.359,
      -18.716,
      0.67,
      38.359,
      -18.716,
      3.45,
      38.558,
      -18.123,
      3.45,
      38.558,
      -18.123,
      3.45,
      38.558,
      -18.123,
      0.67,
      38.359,
      -18.716,
      0.67
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7502",
    "polygon": [
      38.121,
      -19.427,
      0.67,
      38.121,
      -19.427,
      3.45,
      38.359,
      -18.716,
      3.45,
      38.359,
      -18.716,
      3.45,
      38.359,
      -18.716,
      0.67,
      38.121,
      -19.427,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7503",
    "polygon": [
      37.61,
      -20.954,
      0.67,
      37.61,
      -20.954,
      3.45,
      38.121,
      -19.427,
      3.45,
      38.121,
      -19.427,
      3.45,
      38.121,
      -19.427,
      0.67,
      37.61,
      -20.954,
      0.67
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_7504",
    "polygon": [
      49.02,
      13.109,
      0.67,
      49.02,
      13.109,
      3.45,
      49.258,
      13.82,
      3.45,
      49.258,
      13.82,
      3.45,
      49.258,
      13.82,
      0.67,
      49.02,
      13.109,
      0.67
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_7505",
    "polygon": [
      44.524,
      -0.312,
      0.002,
      44.524,
      -0.312,
      2.202,
      45.223,
      1.774,
      2.202,
      45.223,
      1.774,
      2.202,
      45.223,
      1.774,
      0.002,
      44.524,
      -0.312,
      0.002
    ],
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7506",
    "polygon": [
      -21.515,
      106.513,
      13.073,
      -21.515,
      106.513,
      15.523,
      -23.042,
      107.024,
      15.523,
      -23.042,
      107.024,
      15.523,
      -23.042,
      107.024,
      13.073,
      -21.515,
      106.513,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7515",
    "polygon": [
      -20.146,
      106.055,
      13.073,
      -20.146,
      106.055,
      15.523,
      -20.857,
      106.293,
      15.523,
      -20.857,
      106.293,
      15.523,
      -20.857,
      106.293,
      13.073,
      -20.146,
      106.055,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7517",
    "polygon": [
      -18.619,
      105.544,
      13.073,
      -18.619,
      105.544,
      15.523,
      -20.146,
      106.055,
      15.523,
      -20.146,
      106.055,
      15.523,
      -20.146,
      106.055,
      13.073,
      -18.619,
      105.544,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7518",
    "polygon": [
      -18.027,
      105.345,
      13.073,
      -18.027,
      105.345,
      15.523,
      -18.619,
      105.544,
      15.523,
      -18.619,
      105.544,
      15.523,
      -18.619,
      105.544,
      13.073,
      -18.027,
      105.345,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7519",
    "polygon": [
      -17.315,
      105.107,
      13.073,
      -17.315,
      105.107,
      15.523,
      -18.027,
      105.345,
      15.523,
      -18.027,
      105.345,
      15.523,
      -18.027,
      105.345,
      13.073,
      -17.315,
      105.107,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7520",
    "polygon": [
      -15.789,
      104.596,
      13.073,
      -15.789,
      104.596,
      15.523,
      -17.315,
      105.107,
      15.523,
      -17.315,
      105.107,
      15.523,
      -17.315,
      105.107,
      13.073,
      -15.789,
      104.596,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7521",
    "polygon": [
      -14.219,
      104.071,
      13.073,
      -14.219,
      104.071,
      15.523,
      -14.93,
      104.309,
      15.523,
      -14.93,
      104.309,
      15.523,
      -14.93,
      104.309,
      13.073,
      -14.219,
      104.071,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7522",
    "polygon": [
      -12.693,
      103.559,
      13.073,
      -12.693,
      103.559,
      15.523,
      -14.219,
      104.071,
      15.523,
      -14.219,
      104.071,
      15.523,
      -14.219,
      104.071,
      13.073,
      -12.693,
      103.559,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7523",
    "polygon": [
      -12.1,
      103.361,
      13.073,
      -12.1,
      103.361,
      15.523,
      -12.693,
      103.559,
      15.523,
      -12.693,
      103.559,
      15.523,
      -12.693,
      103.559,
      13.073,
      -12.1,
      103.361,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7524",
    "polygon": [
      -11.389,
      103.123,
      13.073,
      -11.389,
      103.123,
      15.523,
      -12.1,
      103.361,
      15.523,
      -12.1,
      103.361,
      15.523,
      -12.1,
      103.361,
      13.073,
      -11.389,
      103.123,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7525",
    "polygon": [
      -9.862,
      102.612,
      13.073,
      -9.862,
      102.612,
      15.523,
      -11.389,
      103.123,
      15.523,
      -11.389,
      103.123,
      15.523,
      -11.389,
      103.123,
      13.073,
      -9.862,
      102.612,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7526",
    "polygon": [
      -8.388,
      102.118,
      13.073,
      -8.388,
      102.118,
      15.523,
      -9.099,
      102.356,
      15.523,
      -9.099,
      102.356,
      15.523,
      -9.099,
      102.356,
      13.073,
      -8.388,
      102.118,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7527",
    "polygon": [
      -6.861,
      101.607,
      13.073,
      -6.861,
      101.607,
      15.523,
      -8.388,
      102.118,
      15.523,
      -8.388,
      102.118,
      15.523,
      -8.388,
      102.118,
      13.073,
      -6.861,
      101.607,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7528",
    "polygon": [
      -6.268,
      101.409,
      13.073,
      -6.268,
      101.409,
      15.523,
      -6.861,
      101.607,
      15.523,
      -6.861,
      101.607,
      15.523,
      -6.861,
      101.607,
      13.073,
      -6.268,
      101.409,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7529",
    "polygon": [
      -5.557,
      101.171,
      13.073,
      -5.557,
      101.171,
      15.523,
      -6.268,
      101.409,
      15.523,
      -6.268,
      101.409,
      15.523,
      -6.268,
      101.409,
      13.073,
      -5.557,
      101.171,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_7530",
    "polygon": [
      -4.03,
      100.66,
      13.073,
      -4.03,
      100.66,
      15.523,
      -5.557,
      101.171,
      15.523,
      -5.557,
      101.171,
      15.523,
      -5.557,
      101.171,
      13.073,
      -4.03,
      100.66,
      13.073
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7531",
    "polygon": [
      -21.514,
      106.515,
      9.323,
      -21.514,
      106.515,
      11.773,
      -23.041,
      107.027,
      11.773,
      -23.041,
      107.027,
      11.773,
      -23.041,
      107.027,
      9.323,
      -21.514,
      106.515,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7532",
    "polygon": [
      -20.145,
      106.057,
      9.323,
      -20.145,
      106.057,
      11.773,
      -20.856,
      106.295,
      11.773,
      -20.856,
      106.295,
      11.773,
      -20.856,
      106.295,
      9.323,
      -20.145,
      106.057,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7533",
    "polygon": [
      -18.619,
      105.545,
      9.323,
      -18.619,
      105.545,
      11.773,
      -20.145,
      106.057,
      11.773,
      -20.145,
      106.057,
      11.773,
      -20.145,
      106.057,
      9.323,
      -18.619,
      105.545,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7534",
    "polygon": [
      -18.026,
      105.347,
      9.323,
      -18.026,
      105.347,
      11.773,
      -18.619,
      105.545,
      11.773,
      -18.619,
      105.545,
      11.773,
      -18.619,
      105.545,
      9.323,
      -18.026,
      105.347,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7535",
    "polygon": [
      -17.315,
      105.109,
      9.323,
      -17.315,
      105.109,
      11.773,
      -18.026,
      105.347,
      11.773,
      -18.026,
      105.347,
      11.773,
      -18.026,
      105.347,
      9.323,
      -17.315,
      105.109,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7536",
    "polygon": [
      -15.788,
      104.597,
      9.323,
      -15.788,
      104.597,
      11.773,
      -17.315,
      105.109,
      11.773,
      -17.315,
      105.109,
      11.773,
      -17.315,
      105.109,
      9.323,
      -15.788,
      104.597,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7537",
    "polygon": [
      -14.219,
      104.071,
      9.323,
      -14.219,
      104.071,
      11.773,
      -14.93,
      104.31,
      11.773,
      -14.93,
      104.31,
      11.773,
      -14.93,
      104.31,
      9.323,
      -14.219,
      104.071,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7538",
    "polygon": [
      -12.692,
      103.56,
      9.323,
      -12.692,
      103.56,
      11.773,
      -14.219,
      104.071,
      11.773,
      -14.219,
      104.071,
      11.773,
      -14.219,
      104.071,
      9.323,
      -12.692,
      103.56,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7539",
    "polygon": [
      -12.1,
      103.361,
      9.323,
      -12.1,
      103.361,
      11.773,
      -12.692,
      103.56,
      11.773,
      -12.692,
      103.56,
      11.773,
      -12.692,
      103.56,
      9.323,
      -12.1,
      103.361,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7540",
    "polygon": [
      -11.388,
      103.123,
      9.323,
      -11.388,
      103.123,
      11.773,
      -12.1,
      103.361,
      11.773,
      -12.1,
      103.361,
      11.773,
      -12.1,
      103.361,
      9.323,
      -11.388,
      103.123,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7541",
    "polygon": [
      -9.862,
      102.612,
      9.323,
      -9.862,
      102.612,
      11.773,
      -11.388,
      103.123,
      11.773,
      -11.388,
      103.123,
      11.773,
      -11.388,
      103.123,
      9.323,
      -9.862,
      102.612,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7542",
    "polygon": [
      -8.388,
      102.118,
      9.323,
      -8.388,
      102.118,
      11.773,
      -9.099,
      102.356,
      11.773,
      -9.099,
      102.356,
      11.773,
      -9.099,
      102.356,
      9.323,
      -8.388,
      102.118,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7543",
    "polygon": [
      -6.861,
      101.607,
      9.323,
      -6.861,
      101.607,
      11.773,
      -8.388,
      102.118,
      11.773,
      -8.388,
      102.118,
      11.773,
      -8.388,
      102.118,
      9.323,
      -6.861,
      101.607,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7544",
    "polygon": [
      -6.268,
      101.408,
      9.323,
      -6.268,
      101.408,
      11.773,
      -6.861,
      101.607,
      11.773,
      -6.861,
      101.607,
      11.773,
      -6.861,
      101.607,
      9.323,
      -6.268,
      101.408,
      9.323
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7545",
    "polygon": [
      -5.557,
      101.17,
      9.323,
      -5.557,
      101.17,
      11.773,
      -6.268,
      101.408,
      11.773,
      -6.268,
      101.408,
      11.773,
      -6.268,
      101.408,
      9.323,
      -5.557,
      101.17,
      9.323
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_2.OG_7546",
    "polygon": [
      -4.031,
      100.659,
      9.323,
      -4.031,
      100.659,
      11.773,
      -5.557,
      101.17,
      11.773,
      -5.557,
      101.17,
      11.773,
      -5.557,
      101.17,
      9.323,
      -4.031,
      100.659,
      9.323
    ],
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7547",
    "polygon": [
      -21.515,
      106.513,
      5.573,
      -21.515,
      106.513,
      8.023,
      -23.042,
      107.024,
      8.023,
      -23.042,
      107.024,
      8.023,
      -23.042,
      107.024,
      5.573,
      -21.515,
      106.513,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7548",
    "polygon": [
      -20.146,
      106.055,
      5.573,
      -20.146,
      106.055,
      8.023,
      -20.857,
      106.293,
      8.023,
      -20.857,
      106.293,
      8.023,
      -20.857,
      106.293,
      5.573,
      -20.146,
      106.055,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7549",
    "polygon": [
      -18.619,
      105.544,
      5.573,
      -18.619,
      105.544,
      8.023,
      -20.146,
      106.055,
      8.023,
      -20.146,
      106.055,
      8.023,
      -20.146,
      106.055,
      5.573,
      -18.619,
      105.544,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7550",
    "polygon": [
      -18.027,
      105.345,
      5.573,
      -18.027,
      105.345,
      8.023,
      -18.619,
      105.544,
      8.023,
      -18.619,
      105.544,
      8.023,
      -18.619,
      105.544,
      5.573,
      -18.027,
      105.345,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7551",
    "polygon": [
      -17.315,
      105.107,
      5.573,
      -17.315,
      105.107,
      8.023,
      -18.027,
      105.345,
      8.023,
      -18.027,
      105.345,
      8.023,
      -18.027,
      105.345,
      5.573,
      -17.315,
      105.107,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7552",
    "polygon": [
      -15.789,
      104.596,
      5.573,
      -15.789,
      104.596,
      8.023,
      -17.315,
      105.107,
      8.023,
      -17.315,
      105.107,
      8.023,
      -17.315,
      105.107,
      5.573,
      -15.789,
      104.596,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7553",
    "polygon": [
      -14.219,
      104.071,
      5.573,
      -14.219,
      104.071,
      8.023,
      -14.93,
      104.309,
      8.023,
      -14.93,
      104.309,
      8.023,
      -14.93,
      104.309,
      5.573,
      -14.219,
      104.071,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7554",
    "polygon": [
      -12.693,
      103.559,
      5.573,
      -12.693,
      103.559,
      8.023,
      -14.219,
      104.071,
      8.023,
      -14.219,
      104.071,
      8.023,
      -14.219,
      104.071,
      5.573,
      -12.693,
      103.559,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7555",
    "polygon": [
      -12.1,
      103.361,
      5.573,
      -12.1,
      103.361,
      8.023,
      -12.693,
      103.559,
      8.023,
      -12.693,
      103.559,
      8.023,
      -12.693,
      103.559,
      5.573,
      -12.1,
      103.361,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7556",
    "polygon": [
      -11.389,
      103.123,
      5.573,
      -11.389,
      103.123,
      8.023,
      -12.1,
      103.361,
      8.023,
      -12.1,
      103.361,
      8.023,
      -12.1,
      103.361,
      5.573,
      -11.389,
      103.123,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7557",
    "polygon": [
      -9.862,
      102.612,
      5.573,
      -9.862,
      102.612,
      8.023,
      -11.389,
      103.123,
      8.023,
      -11.389,
      103.123,
      8.023,
      -11.389,
      103.123,
      5.573,
      -9.862,
      102.612,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7558",
    "polygon": [
      -8.388,
      102.118,
      5.573,
      -8.388,
      102.118,
      8.023,
      -9.099,
      102.356,
      8.023,
      -9.099,
      102.356,
      8.023,
      -9.099,
      102.356,
      5.573,
      -8.388,
      102.118,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7559",
    "polygon": [
      -6.861,
      101.607,
      5.573,
      -6.861,
      101.607,
      8.023,
      -8.388,
      102.118,
      8.023,
      -8.388,
      102.118,
      8.023,
      -8.388,
      102.118,
      5.573,
      -6.861,
      101.607,
      5.573
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7560",
    "polygon": [
      -6.268,
      101.409,
      5.573,
      -6.268,
      101.409,
      8.023,
      -6.861,
      101.607,
      8.023,
      -6.861,
      101.607,
      8.023,
      -6.861,
      101.607,
      5.573,
      -6.268,
      101.409,
      5.573
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7561",
    "polygon": [
      -5.557,
      101.171,
      5.573,
      -5.557,
      101.171,
      8.023,
      -6.268,
      101.409,
      8.023,
      -6.268,
      101.409,
      8.023,
      -6.268,
      101.409,
      5.573,
      -5.557,
      101.171,
      5.573
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_1.OG_7562",
    "polygon": [
      -4.03,
      100.66,
      5.573,
      -4.03,
      100.66,
      8.023,
      -5.557,
      101.171,
      8.023,
      -5.557,
      101.171,
      8.023,
      -5.557,
      101.171,
      5.573,
      -4.03,
      100.66,
      5.573
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7563",
    "polygon": [
      -21.512,
      106.514,
      0.8,
      -21.512,
      106.514,
      3.65,
      -23.039,
      107.025,
      3.65,
      -23.039,
      107.025,
      3.65,
      -23.039,
      107.025,
      0.8,
      -21.512,
      106.514,
      0.8
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7564",
    "polygon": [
      -20.094,
      106.039,
      0.8,
      -20.094,
      106.039,
      3.65,
      -20.805,
      106.277,
      3.65,
      -20.805,
      106.277,
      3.65,
      -20.805,
      106.277,
      0.8,
      -20.094,
      106.039,
      0.8
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7565",
    "polygon": [
      -18.567,
      105.527,
      0.8,
      -18.567,
      105.527,
      3.65,
      -20.094,
      106.039,
      3.65,
      -20.094,
      106.039,
      3.65,
      -20.094,
      106.039,
      0.8,
      -18.567,
      105.527,
      0.8
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7566",
    "polygon": [
      -17.975,
      105.329,
      0.8,
      -17.975,
      105.329,
      3.65,
      -18.567,
      105.527,
      3.65,
      -18.567,
      105.527,
      3.65,
      -18.567,
      105.527,
      0.8,
      -17.975,
      105.329,
      0.8
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7567",
    "polygon": [
      -17.264,
      105.09,
      0.8,
      -17.264,
      105.09,
      3.65,
      -17.975,
      105.329,
      3.65,
      -17.975,
      105.329,
      3.65,
      -17.975,
      105.329,
      0.8,
      -17.264,
      105.09,
      0.8
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7568",
    "polygon": [
      -15.737,
      104.579,
      0.8,
      -15.737,
      104.579,
      3.65,
      -17.264,
      105.09,
      3.65,
      -17.264,
      105.09,
      3.65,
      -17.264,
      105.09,
      0.8,
      -15.737,
      104.579,
      0.8
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7569",
    "polygon": [
      -14.168,
      104.053,
      0.8,
      -14.168,
      104.053,
      3.65,
      -14.879,
      104.292,
      3.65,
      -14.879,
      104.292,
      3.65,
      -14.879,
      104.292,
      0.8,
      -14.168,
      104.053,
      0.8
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7570",
    "polygon": [
      -12.641,
      103.542,
      0.8,
      -12.641,
      103.542,
      3.65,
      -14.168,
      104.053,
      3.65,
      -14.168,
      104.053,
      3.65,
      -14.168,
      104.053,
      0.8,
      -12.641,
      103.542,
      0.8
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7571",
    "polygon": [
      -12.048,
      103.343,
      0.8,
      -12.048,
      103.343,
      3.65,
      -12.641,
      103.542,
      3.65,
      -12.641,
      103.542,
      3.65,
      -12.641,
      103.542,
      0.8,
      -12.048,
      103.343,
      0.8
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7572",
    "polygon": [
      -11.337,
      103.105,
      0.8,
      -11.337,
      103.105,
      3.65,
      -12.048,
      103.343,
      3.65,
      -12.048,
      103.343,
      3.65,
      -12.048,
      103.343,
      0.8,
      -11.337,
      103.105,
      0.8
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7573",
    "polygon": [
      -9.811,
      102.594,
      0.8,
      -9.811,
      102.594,
      3.65,
      -11.337,
      103.105,
      3.65,
      -11.337,
      103.105,
      3.65,
      -11.337,
      103.105,
      0.8,
      -9.811,
      102.594,
      0.8
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7576",
    "polygon": [
      -6.838,
      101.598,
      2.953,
      -6.838,
      101.598,
      3.65,
      -8.99,
      102.319,
      3.65,
      -8.99,
      102.319,
      3.65,
      -8.99,
      102.319,
      2.953,
      -6.838,
      101.598,
      2.953
    ]
  },
  {
    "ID": "Block-A-InnerCourt_EG_7577",
    "polygon": [
      -7.914,
      101.958,
      0.528,
      -7.914,
      101.958,
      2.953,
      -8.99,
      102.319,
      2.953,
      -8.99,
      102.319,
      2.953,
      -8.99,
      102.319,
      0.528,
      -7.914,
      101.958,
      0.528
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7578",
    "polygon": [
      -6.838,
      101.598,
      0.528,
      -6.838,
      101.598,
      2.953,
      -7.914,
      101.958,
      2.953,
      -7.914,
      101.958,
      2.953,
      -7.914,
      101.958,
      0.528,
      -6.838,
      101.598,
      0.528
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7579",
    "polygon": [
      -5.278,
      101.075,
      0.8,
      -5.278,
      101.075,
      3.65,
      -5.989,
      101.314,
      3.65,
      -5.989,
      101.314,
      3.65,
      -5.989,
      101.314,
      0.8,
      -5.278,
      101.075,
      0.8
    ],
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-A-InnerCourt_EG_7580",
    "polygon": [
      -4.029,
      100.657,
      0.8,
      -4.029,
      100.657,
      3.65,
      -5.28,
      101.076,
      3.65,
      -5.28,
      101.076,
      3.65,
      -5.28,
      101.076,
      0.8,
      -4.029,
      100.657,
      0.8
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7581",
    "polygon": [
      48.376,
      83.028,
      13.073,
      48.376,
      83.028,
      15.523,
      46.849,
      83.539,
      15.523,
      46.849,
      83.539,
      15.523,
      46.849,
      83.539,
      13.073,
      48.376,
      83.028,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7582",
    "polygon": [
      49.087,
      82.79,
      13.073,
      49.087,
      82.79,
      15.523,
      48.376,
      83.028,
      15.523,
      48.376,
      83.028,
      15.523,
      48.376,
      83.028,
      13.073,
      49.087,
      82.79,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7583",
    "polygon": [
      49.68,
      82.591,
      13.073,
      49.68,
      82.591,
      15.523,
      49.087,
      82.79,
      15.523,
      49.087,
      82.79,
      15.523,
      49.087,
      82.79,
      13.073,
      49.68,
      82.591,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7584",
    "polygon": [
      51.206,
      82.08,
      13.073,
      51.206,
      82.08,
      15.523,
      49.68,
      82.591,
      15.523,
      49.68,
      82.591,
      15.523,
      49.68,
      82.591,
      13.073,
      51.206,
      82.08,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7585",
    "polygon": [
      51.918,
      81.842,
      13.073,
      51.918,
      81.842,
      15.523,
      51.206,
      82.08,
      15.523,
      51.206,
      82.08,
      15.523,
      51.206,
      82.08,
      13.073,
      51.918,
      81.842,
      13.073
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7586",
    "polygon": [
      53.288,
      81.383,
      13.073,
      53.288,
      81.383,
      15.523,
      52.577,
      81.621,
      15.523,
      52.577,
      81.621,
      15.523,
      52.577,
      81.621,
      13.073,
      53.288,
      81.383,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7587",
    "polygon": [
      54.814,
      80.871,
      13.073,
      54.814,
      80.871,
      15.523,
      53.288,
      81.383,
      15.523,
      53.288,
      81.383,
      15.523,
      53.288,
      81.383,
      13.073,
      54.814,
      80.871,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7588",
    "polygon": [
      55.407,
      80.673,
      13.073,
      55.407,
      80.673,
      15.523,
      54.814,
      80.871,
      15.523,
      54.814,
      80.871,
      15.523,
      54.814,
      80.871,
      13.073,
      55.407,
      80.673,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7589",
    "polygon": [
      56.118,
      80.434,
      13.073,
      56.118,
      80.434,
      15.523,
      55.407,
      80.673,
      15.523,
      55.407,
      80.673,
      15.523,
      55.407,
      80.673,
      13.073,
      56.118,
      80.434,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7590",
    "polygon": [
      57.645,
      79.923,
      13.073,
      57.645,
      79.923,
      15.523,
      56.118,
      80.434,
      15.523,
      56.118,
      80.434,
      15.523,
      56.118,
      80.434,
      13.073,
      57.645,
      79.923,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7591",
    "polygon": [
      59.214,
      79.397,
      13.073,
      59.214,
      79.397,
      15.523,
      58.503,
      79.636,
      15.523,
      58.503,
      79.636,
      15.523,
      58.503,
      79.636,
      13.073,
      59.214,
      79.397,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7592",
    "polygon": [
      60.741,
      78.886,
      13.073,
      60.741,
      78.886,
      15.523,
      59.214,
      79.397,
      15.523,
      59.214,
      79.397,
      15.523,
      59.214,
      79.397,
      13.073,
      60.741,
      78.886,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7593",
    "polygon": [
      61.333,
      78.687,
      13.073,
      61.333,
      78.687,
      15.523,
      60.741,
      78.886,
      15.523,
      60.741,
      78.886,
      15.523,
      60.741,
      78.886,
      13.073,
      61.333,
      78.687,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7594",
    "polygon": [
      62.044,
      78.449,
      13.073,
      62.044,
      78.449,
      15.523,
      61.333,
      78.687,
      15.523,
      61.333,
      78.687,
      15.523,
      61.333,
      78.687,
      13.073,
      62.044,
      78.449,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7595",
    "polygon": [
      63.571,
      77.938,
      13.073,
      63.571,
      77.938,
      15.523,
      62.044,
      78.449,
      15.523,
      62.044,
      78.449,
      15.523,
      62.044,
      78.449,
      13.073,
      63.571,
      77.938,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7596",
    "polygon": [
      65.14,
      77.412,
      13.073,
      65.14,
      77.412,
      15.523,
      64.429,
      77.65,
      15.523,
      64.429,
      77.65,
      15.523,
      64.429,
      77.65,
      13.073,
      65.14,
      77.412,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7597",
    "polygon": [
      66.667,
      76.901,
      13.073,
      66.667,
      76.901,
      15.523,
      65.14,
      77.412,
      15.523,
      65.14,
      77.412,
      15.523,
      65.14,
      77.412,
      13.073,
      66.667,
      76.901,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7598",
    "polygon": [
      67.26,
      76.702,
      13.073,
      67.26,
      76.702,
      15.523,
      66.667,
      76.901,
      15.523,
      66.667,
      76.901,
      15.523,
      66.667,
      76.901,
      13.073,
      67.26,
      76.702,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7599",
    "polygon": [
      67.971,
      76.464,
      13.073,
      67.971,
      76.464,
      15.523,
      67.26,
      76.702,
      15.523,
      67.26,
      76.702,
      15.523,
      67.26,
      76.702,
      13.073,
      67.971,
      76.464,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_7601",
    "polygon": [
      69.44,
      75.972,
      13.073,
      69.44,
      75.972,
      15.523,
      67.971,
      76.464,
      15.523,
      67.971,
      76.464,
      15.523,
      67.971,
      76.464,
      13.073,
      69.44,
      75.972,
      13.073
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7602",
    "polygon": [
      48.376,
      83.028,
      9.323,
      48.376,
      83.028,
      11.773,
      46.849,
      83.539,
      11.773,
      46.849,
      83.539,
      11.773,
      46.849,
      83.539,
      9.323,
      48.376,
      83.028,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7603",
    "polygon": [
      49.087,
      82.79,
      9.323,
      49.087,
      82.79,
      11.773,
      48.376,
      83.028,
      11.773,
      48.376,
      83.028,
      11.773,
      48.376,
      83.028,
      9.323,
      49.087,
      82.79,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7604",
    "polygon": [
      49.68,
      82.591,
      9.323,
      49.68,
      82.591,
      11.773,
      49.087,
      82.79,
      11.773,
      49.087,
      82.79,
      11.773,
      49.087,
      82.79,
      9.323,
      49.68,
      82.591,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7605",
    "polygon": [
      51.206,
      82.08,
      9.323,
      51.206,
      82.08,
      11.773,
      49.68,
      82.591,
      11.773,
      49.68,
      82.591,
      11.773,
      49.68,
      82.591,
      9.323,
      51.206,
      82.08,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7606",
    "polygon": [
      51.918,
      81.842,
      9.323,
      51.918,
      81.842,
      11.773,
      51.206,
      82.08,
      11.773,
      51.206,
      82.08,
      11.773,
      51.206,
      82.08,
      9.323,
      51.918,
      81.842,
      9.323
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7607",
    "polygon": [
      53.288,
      81.383,
      9.323,
      53.288,
      81.383,
      11.773,
      52.577,
      81.621,
      11.773,
      52.577,
      81.621,
      11.773,
      52.577,
      81.621,
      9.323,
      53.288,
      81.383,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7608",
    "polygon": [
      54.814,
      80.871,
      9.323,
      54.814,
      80.871,
      11.773,
      53.288,
      81.383,
      11.773,
      53.288,
      81.383,
      11.773,
      53.288,
      81.383,
      9.323,
      54.814,
      80.871,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7609",
    "polygon": [
      55.407,
      80.673,
      9.323,
      55.407,
      80.673,
      11.773,
      54.814,
      80.871,
      11.773,
      54.814,
      80.871,
      11.773,
      54.814,
      80.871,
      9.323,
      55.407,
      80.673,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7610",
    "polygon": [
      56.118,
      80.434,
      9.323,
      56.118,
      80.434,
      11.773,
      55.407,
      80.673,
      11.773,
      55.407,
      80.673,
      11.773,
      55.407,
      80.673,
      9.323,
      56.118,
      80.434,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7611",
    "polygon": [
      57.645,
      79.923,
      9.323,
      57.645,
      79.923,
      11.773,
      56.118,
      80.434,
      11.773,
      56.118,
      80.434,
      11.773,
      56.118,
      80.434,
      9.323,
      57.645,
      79.923,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7612",
    "polygon": [
      59.214,
      79.397,
      9.323,
      59.214,
      79.397,
      11.773,
      58.503,
      79.636,
      11.773,
      58.503,
      79.636,
      11.773,
      58.503,
      79.636,
      9.323,
      59.214,
      79.397,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7613",
    "polygon": [
      60.741,
      78.886,
      9.323,
      60.741,
      78.886,
      11.773,
      59.214,
      79.397,
      11.773,
      59.214,
      79.397,
      11.773,
      59.214,
      79.397,
      9.323,
      60.741,
      78.886,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7614",
    "polygon": [
      61.333,
      78.687,
      9.323,
      61.333,
      78.687,
      11.773,
      60.741,
      78.886,
      11.773,
      60.741,
      78.886,
      11.773,
      60.741,
      78.886,
      9.323,
      61.333,
      78.687,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7615",
    "polygon": [
      62.044,
      78.449,
      9.323,
      62.044,
      78.449,
      11.773,
      61.333,
      78.687,
      11.773,
      61.333,
      78.687,
      11.773,
      61.333,
      78.687,
      9.323,
      62.044,
      78.449,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7616",
    "polygon": [
      63.571,
      77.938,
      9.323,
      63.571,
      77.938,
      11.773,
      62.044,
      78.449,
      11.773,
      62.044,
      78.449,
      11.773,
      62.044,
      78.449,
      9.323,
      63.571,
      77.938,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7617",
    "polygon": [
      65.14,
      77.412,
      9.323,
      65.14,
      77.412,
      11.773,
      64.429,
      77.65,
      11.773,
      64.429,
      77.65,
      11.773,
      64.429,
      77.65,
      9.323,
      65.14,
      77.412,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7618",
    "polygon": [
      66.667,
      76.901,
      9.323,
      66.667,
      76.901,
      11.773,
      65.14,
      77.412,
      11.773,
      65.14,
      77.412,
      11.773,
      65.14,
      77.412,
      9.323,
      66.667,
      76.901,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7619",
    "polygon": [
      67.26,
      76.702,
      9.323,
      67.26,
      76.702,
      11.773,
      66.667,
      76.901,
      11.773,
      66.667,
      76.901,
      11.773,
      66.667,
      76.901,
      9.323,
      67.26,
      76.702,
      9.323
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7620",
    "polygon": [
      67.971,
      76.464,
      9.323,
      67.971,
      76.464,
      11.773,
      67.26,
      76.702,
      11.773,
      67.26,
      76.702,
      11.773,
      67.26,
      76.702,
      9.323,
      67.971,
      76.464,
      9.323
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_2.OG_7621",
    "polygon": [
      69.44,
      75.972,
      9.323,
      69.44,
      75.972,
      11.773,
      67.971,
      76.464,
      11.773,
      67.971,
      76.464,
      11.773,
      67.971,
      76.464,
      9.323,
      69.44,
      75.972,
      9.323
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7622",
    "polygon": [
      48.376,
      83.028,
      5.573,
      48.376,
      83.028,
      8.023,
      46.849,
      83.539,
      8.023,
      46.849,
      83.539,
      8.023,
      46.849,
      83.539,
      5.573,
      48.376,
      83.028,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7623",
    "polygon": [
      49.087,
      82.79,
      5.573,
      49.087,
      82.79,
      8.023,
      48.376,
      83.028,
      8.023,
      48.376,
      83.028,
      8.023,
      48.376,
      83.028,
      5.573,
      49.087,
      82.79,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7624",
    "polygon": [
      49.68,
      82.591,
      5.573,
      49.68,
      82.591,
      8.023,
      49.087,
      82.79,
      8.023,
      49.087,
      82.79,
      8.023,
      49.087,
      82.79,
      5.573,
      49.68,
      82.591,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7625",
    "polygon": [
      51.206,
      82.08,
      5.573,
      51.206,
      82.08,
      8.023,
      49.68,
      82.591,
      8.023,
      49.68,
      82.591,
      8.023,
      49.68,
      82.591,
      5.573,
      51.206,
      82.08,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7626",
    "polygon": [
      51.918,
      81.842,
      5.573,
      51.918,
      81.842,
      8.023,
      51.206,
      82.08,
      8.023,
      51.206,
      82.08,
      8.023,
      51.206,
      82.08,
      5.573,
      51.918,
      81.842,
      5.573
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7627",
    "polygon": [
      53.288,
      81.383,
      5.573,
      53.288,
      81.383,
      8.023,
      52.577,
      81.621,
      8.023,
      52.577,
      81.621,
      8.023,
      52.577,
      81.621,
      5.573,
      53.288,
      81.383,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7628",
    "polygon": [
      54.814,
      80.871,
      5.573,
      54.814,
      80.871,
      8.023,
      53.288,
      81.383,
      8.023,
      53.288,
      81.383,
      8.023,
      53.288,
      81.383,
      5.573,
      54.814,
      80.871,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7629",
    "polygon": [
      55.407,
      80.673,
      5.573,
      55.407,
      80.673,
      8.023,
      54.814,
      80.871,
      8.023,
      54.814,
      80.871,
      8.023,
      54.814,
      80.871,
      5.573,
      55.407,
      80.673,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7630",
    "polygon": [
      56.118,
      80.434,
      5.573,
      56.118,
      80.434,
      8.023,
      55.407,
      80.673,
      8.023,
      55.407,
      80.673,
      8.023,
      55.407,
      80.673,
      5.573,
      56.118,
      80.434,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7631",
    "polygon": [
      57.645,
      79.923,
      5.573,
      57.645,
      79.923,
      8.023,
      56.118,
      80.434,
      8.023,
      56.118,
      80.434,
      8.023,
      56.118,
      80.434,
      5.573,
      57.645,
      79.923,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7632",
    "polygon": [
      59.214,
      79.397,
      5.573,
      59.214,
      79.397,
      8.023,
      58.503,
      79.636,
      8.023,
      58.503,
      79.636,
      8.023,
      58.503,
      79.636,
      5.573,
      59.214,
      79.397,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7633",
    "polygon": [
      60.741,
      78.886,
      5.573,
      60.741,
      78.886,
      8.023,
      59.214,
      79.397,
      8.023,
      59.214,
      79.397,
      8.023,
      59.214,
      79.397,
      5.573,
      60.741,
      78.886,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7634",
    "polygon": [
      61.333,
      78.687,
      5.573,
      61.333,
      78.687,
      8.023,
      60.741,
      78.886,
      8.023,
      60.741,
      78.886,
      8.023,
      60.741,
      78.886,
      5.573,
      61.333,
      78.687,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7635",
    "polygon": [
      62.044,
      78.449,
      5.573,
      62.044,
      78.449,
      8.023,
      61.333,
      78.687,
      8.023,
      61.333,
      78.687,
      8.023,
      61.333,
      78.687,
      5.573,
      62.044,
      78.449,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7636",
    "polygon": [
      63.571,
      77.938,
      5.573,
      63.571,
      77.938,
      8.023,
      62.044,
      78.449,
      8.023,
      62.044,
      78.449,
      8.023,
      62.044,
      78.449,
      5.573,
      63.571,
      77.938,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7637",
    "polygon": [
      65.14,
      77.412,
      5.573,
      65.14,
      77.412,
      8.023,
      64.429,
      77.65,
      8.023,
      64.429,
      77.65,
      8.023,
      64.429,
      77.65,
      5.573,
      65.14,
      77.412,
      5.573
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7638",
    "polygon": [
      66.667,
      76.901,
      5.573,
      66.667,
      76.901,
      8.023,
      65.14,
      77.412,
      8.023,
      65.14,
      77.412,
      8.023,
      65.14,
      77.412,
      5.573,
      66.667,
      76.901,
      5.573
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7639",
    "polygon": [
      67.26,
      76.702,
      5.573,
      67.26,
      76.702,
      8.023,
      66.667,
      76.901,
      8.023,
      66.667,
      76.901,
      8.023,
      66.667,
      76.901,
      5.573,
      67.26,
      76.702,
      5.573
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7640",
    "polygon": [
      67.971,
      76.464,
      5.573,
      67.971,
      76.464,
      8.023,
      67.26,
      76.702,
      8.023,
      67.26,
      76.702,
      8.023,
      67.26,
      76.702,
      5.573,
      67.971,
      76.464,
      5.573
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_1.OG_7641",
    "polygon": [
      69.44,
      75.972,
      5.573,
      69.44,
      75.972,
      8.023,
      67.971,
      76.464,
      8.023,
      67.971,
      76.464,
      8.023,
      67.971,
      76.464,
      5.573,
      69.44,
      75.972,
      5.573
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7644",
    "polygon": [
      51.82,
      81.874,
      2.953,
      51.82,
      81.874,
      3.65,
      49.667,
      82.595,
      3.65,
      49.667,
      82.595,
      3.65,
      49.667,
      82.595,
      2.953,
      51.82,
      81.874,
      2.953
    ]
  },
  {
    "ID": "Block-B-InnerCourt_EG_7646",
    "polygon": [
      47.579,
      83.295,
      0.8,
      47.579,
      83.295,
      3.65,
      46.868,
      83.533,
      3.65,
      46.868,
      83.533,
      3.65,
      46.868,
      83.533,
      0.8,
      47.579,
      83.295,
      0.8
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7647",
    "polygon": [
      49.106,
      82.783,
      0.8,
      49.106,
      82.783,
      3.65,
      47.579,
      83.295,
      3.65,
      47.579,
      83.295,
      3.65,
      47.579,
      83.295,
      0.8,
      49.106,
      82.783,
      0.8
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7648",
    "polygon": [
      50.744,
      82.235,
      0.528,
      50.744,
      82.235,
      2.953,
      49.667,
      82.595,
      2.953,
      49.667,
      82.595,
      2.953,
      49.667,
      82.595,
      0.528,
      50.744,
      82.235,
      0.528
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7649",
    "polygon": [
      51.82,
      81.874,
      0.528,
      51.82,
      81.874,
      2.953,
      50.744,
      82.235,
      2.953,
      50.744,
      82.235,
      2.953,
      50.744,
      82.235,
      0.528,
      51.82,
      81.874,
      0.528
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7650",
    "polygon": [
      53.396,
      81.346,
      0.8,
      53.396,
      81.346,
      3.65,
      52.684,
      81.585,
      3.65,
      52.684,
      81.585,
      3.65,
      52.684,
      81.585,
      0.8,
      53.396,
      81.346,
      0.8
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7651",
    "polygon": [
      54.922,
      80.835,
      0.8,
      54.922,
      80.835,
      3.65,
      53.396,
      81.346,
      3.65,
      53.396,
      81.346,
      3.65,
      53.396,
      81.346,
      0.8,
      54.922,
      80.835,
      0.8
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7652",
    "polygon": [
      55.515,
      80.637,
      0.8,
      55.515,
      80.637,
      3.65,
      54.922,
      80.835,
      3.65,
      54.922,
      80.835,
      3.65,
      54.922,
      80.835,
      0.8,
      55.515,
      80.637,
      0.8
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7653",
    "polygon": [
      56.226,
      80.398,
      0.8,
      56.226,
      80.398,
      3.65,
      55.515,
      80.637,
      3.65,
      55.515,
      80.637,
      3.65,
      55.515,
      80.637,
      0.8,
      56.226,
      80.398,
      0.8
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7654",
    "polygon": [
      57.753,
      79.887,
      0.8,
      57.753,
      79.887,
      3.65,
      56.226,
      80.398,
      3.65,
      56.226,
      80.398,
      3.65,
      56.226,
      80.398,
      0.8,
      57.753,
      79.887,
      0.8
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7655",
    "polygon": [
      59.322,
      79.361,
      0.8,
      59.322,
      79.361,
      3.65,
      58.611,
      79.599,
      3.65,
      58.611,
      79.599,
      3.65,
      58.611,
      79.599,
      0.8,
      59.322,
      79.361,
      0.8
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7656",
    "polygon": [
      60.848,
      78.85,
      0.8,
      60.848,
      78.85,
      3.65,
      59.322,
      79.361,
      3.65,
      59.322,
      79.361,
      3.65,
      59.322,
      79.361,
      0.8,
      60.848,
      78.85,
      0.8
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7657",
    "polygon": [
      61.441,
      78.651,
      0.8,
      61.441,
      78.651,
      3.65,
      60.848,
      78.85,
      3.65,
      60.848,
      78.85,
      3.65,
      60.848,
      78.85,
      0.8,
      61.441,
      78.651,
      0.8
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7658",
    "polygon": [
      62.152,
      78.413,
      0.8,
      62.152,
      78.413,
      3.65,
      61.441,
      78.651,
      3.65,
      61.441,
      78.651,
      3.65,
      61.441,
      78.651,
      0.8,
      62.152,
      78.413,
      0.8
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7659",
    "polygon": [
      63.679,
      77.902,
      0.8,
      63.679,
      77.902,
      3.65,
      62.152,
      78.413,
      3.65,
      62.152,
      78.413,
      3.65,
      62.152,
      78.413,
      0.8,
      63.679,
      77.902,
      0.8
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7660",
    "polygon": [
      65.248,
      77.376,
      0.8,
      65.248,
      77.376,
      3.65,
      64.537,
      77.614,
      3.65,
      64.537,
      77.614,
      3.65,
      64.537,
      77.614,
      0.8,
      65.248,
      77.376,
      0.8
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7661",
    "polygon": [
      66.775,
      76.865,
      0.8,
      66.775,
      76.865,
      3.65,
      65.248,
      77.376,
      3.65,
      65.248,
      77.376,
      3.65,
      65.248,
      77.376,
      0.8,
      66.775,
      76.865,
      0.8
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7662",
    "polygon": [
      67.367,
      76.666,
      0.8,
      67.367,
      76.666,
      3.65,
      66.775,
      76.865,
      3.65,
      66.775,
      76.865,
      3.65,
      66.775,
      76.865,
      0.8,
      67.367,
      76.666,
      0.8
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7663",
    "polygon": [
      68.079,
      76.428,
      0.8,
      68.079,
      76.428,
      3.65,
      67.367,
      76.666,
      3.65,
      67.367,
      76.666,
      3.65,
      67.367,
      76.666,
      0.8,
      68.079,
      76.428,
      0.8
    ],
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_EG_7665",
    "polygon": [
      69.453,
      75.968,
      0.8,
      69.453,
      75.968,
      3.65,
      68.079,
      76.428,
      3.65,
      68.079,
      76.428,
      3.65,
      68.079,
      76.428,
      0.8,
      69.453,
      75.968,
      0.8
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7666",
    "polygon": [
      -79.309,
      57.299,
      13.063,
      -79.309,
      57.299,
      15.513,
      -80.836,
      57.81,
      15.513,
      -80.836,
      57.81,
      15.513,
      -80.836,
      57.81,
      13.063,
      -79.309,
      57.299,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7667",
    "polygon": [
      -77.839,
      56.806,
      13.063,
      -77.839,
      56.806,
      15.513,
      -78.55,
      57.045,
      15.513,
      -78.55,
      57.045,
      15.513,
      -78.55,
      57.045,
      13.063,
      -77.839,
      56.806,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7668",
    "polygon": [
      -76.313,
      56.295,
      13.063,
      -76.313,
      56.295,
      15.513,
      -77.839,
      56.806,
      15.513,
      -77.839,
      56.806,
      15.513,
      -77.839,
      56.806,
      13.063,
      -76.313,
      56.295,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7669",
    "polygon": [
      -75.72,
      56.097,
      13.063,
      -75.72,
      56.097,
      15.513,
      -76.313,
      56.295,
      15.513,
      -76.313,
      56.295,
      15.513,
      -76.313,
      56.295,
      13.063,
      -75.72,
      56.097,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7670",
    "polygon": [
      -75.009,
      55.858,
      13.063,
      -75.009,
      55.858,
      15.513,
      -75.72,
      56.097,
      15.513,
      -75.72,
      56.097,
      15.513,
      -75.72,
      56.097,
      13.063,
      -75.009,
      55.858,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7671",
    "polygon": [
      -73.482,
      55.347,
      13.063,
      -73.482,
      55.347,
      15.513,
      -75.009,
      55.858,
      15.513,
      -75.009,
      55.858,
      15.513,
      -75.009,
      55.858,
      13.063,
      -73.482,
      55.347,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7672",
    "polygon": [
      -71.913,
      54.821,
      13.063,
      -71.913,
      54.821,
      15.513,
      -72.624,
      55.059,
      15.513,
      -72.624,
      55.059,
      15.513,
      -72.624,
      55.059,
      13.063,
      -71.913,
      54.821,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7673",
    "polygon": [
      -70.386,
      54.31,
      13.063,
      -70.386,
      54.31,
      15.513,
      -71.913,
      54.821,
      15.513,
      -71.913,
      54.821,
      15.513,
      -71.913,
      54.821,
      13.063,
      -70.386,
      54.31,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7674",
    "polygon": [
      -69.794,
      54.111,
      13.063,
      -69.794,
      54.111,
      15.513,
      -70.386,
      54.31,
      15.513,
      -70.386,
      54.31,
      15.513,
      -70.386,
      54.31,
      13.063,
      -69.794,
      54.111,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7675",
    "polygon": [
      -69.083,
      53.873,
      13.063,
      -69.083,
      53.873,
      15.513,
      -69.794,
      54.111,
      15.513,
      -69.794,
      54.111,
      15.513,
      -69.794,
      54.111,
      13.063,
      -69.083,
      53.873,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7676",
    "polygon": [
      -67.556,
      53.362,
      13.063,
      -67.556,
      53.362,
      15.513,
      -69.083,
      53.873,
      15.513,
      -69.083,
      53.873,
      15.513,
      -69.083,
      53.873,
      13.063,
      -67.556,
      53.362,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7677",
    "polygon": [
      -65.987,
      52.836,
      13.063,
      -65.987,
      52.836,
      15.513,
      -66.698,
      53.074,
      15.513,
      -66.698,
      53.074,
      15.513,
      -66.698,
      53.074,
      13.063,
      -65.987,
      52.836,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7678",
    "polygon": [
      -64.46,
      52.325,
      13.063,
      -64.46,
      52.325,
      15.513,
      -65.987,
      52.836,
      15.513,
      -65.987,
      52.836,
      15.513,
      -65.987,
      52.836,
      13.063,
      -64.46,
      52.325,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7679",
    "polygon": [
      -63.867,
      52.126,
      13.063,
      -63.867,
      52.126,
      15.513,
      -64.46,
      52.325,
      15.513,
      -64.46,
      52.325,
      15.513,
      -64.46,
      52.325,
      13.063,
      -63.867,
      52.126,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7680",
    "polygon": [
      -63.156,
      51.888,
      13.063,
      -63.156,
      51.888,
      15.513,
      -63.867,
      52.126,
      15.513,
      -63.867,
      52.126,
      15.513,
      -63.867,
      52.126,
      13.063,
      -63.156,
      51.888,
      13.063
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_7682",
    "polygon": [
      -61.897,
      51.466,
      13.063,
      -61.897,
      51.466,
      15.513,
      -63.161,
      51.89,
      15.513,
      -63.161,
      51.89,
      15.513,
      -63.161,
      51.89,
      13.063,
      -61.897,
      51.466,
      13.063
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7683",
    "polygon": [
      -79.302,
      57.319,
      9.313,
      -79.302,
      57.319,
      11.763,
      -80.829,
      57.831,
      11.763,
      -80.829,
      57.831,
      11.763,
      -80.829,
      57.831,
      9.313,
      -79.302,
      57.319,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7684",
    "polygon": [
      -77.832,
      56.827,
      9.313,
      -77.832,
      56.827,
      11.763,
      -78.544,
      57.065,
      11.763,
      -78.544,
      57.065,
      11.763,
      -78.544,
      57.065,
      9.313,
      -77.832,
      56.827,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7685",
    "polygon": [
      -76.306,
      56.316,
      9.313,
      -76.306,
      56.316,
      11.763,
      -77.832,
      56.827,
      11.763,
      -77.832,
      56.827,
      11.763,
      -77.832,
      56.827,
      9.313,
      -76.306,
      56.316,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7686",
    "polygon": [
      -75.713,
      56.117,
      9.313,
      -75.713,
      56.117,
      11.763,
      -76.306,
      56.316,
      11.763,
      -76.306,
      56.316,
      11.763,
      -76.306,
      56.316,
      9.313,
      -75.713,
      56.117,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7687",
    "polygon": [
      -75.002,
      55.879,
      9.313,
      -75.002,
      55.879,
      11.763,
      -75.713,
      56.117,
      11.763,
      -75.713,
      56.117,
      11.763,
      -75.713,
      56.117,
      9.313,
      -75.002,
      55.879,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7688",
    "polygon": [
      -73.475,
      55.368,
      9.313,
      -73.475,
      55.368,
      11.763,
      -75.002,
      55.879,
      11.763,
      -75.002,
      55.879,
      11.763,
      -75.002,
      55.879,
      9.313,
      -73.475,
      55.368,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7689",
    "polygon": [
      -71.906,
      54.842,
      9.313,
      -71.906,
      54.842,
      11.763,
      -72.617,
      55.08,
      11.763,
      -72.617,
      55.08,
      11.763,
      -72.617,
      55.08,
      9.313,
      -71.906,
      54.842,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7690",
    "polygon": [
      -70.379,
      54.33,
      9.313,
      -70.379,
      54.33,
      11.763,
      -71.906,
      54.842,
      11.763,
      -71.906,
      54.842,
      11.763,
      -71.906,
      54.842,
      9.313,
      -70.379,
      54.33,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7691",
    "polygon": [
      -69.787,
      54.132,
      9.313,
      -69.787,
      54.132,
      11.763,
      -70.379,
      54.33,
      11.763,
      -70.379,
      54.33,
      11.763,
      -70.379,
      54.33,
      9.313,
      -69.787,
      54.132,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7692",
    "polygon": [
      -69.076,
      53.894,
      9.313,
      -69.076,
      53.894,
      11.763,
      -69.787,
      54.132,
      11.763,
      -69.787,
      54.132,
      11.763,
      -69.787,
      54.132,
      9.313,
      -69.076,
      53.894,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7693",
    "polygon": [
      -67.549,
      53.382,
      9.313,
      -67.549,
      53.382,
      11.763,
      -69.076,
      53.894,
      11.763,
      -69.076,
      53.894,
      11.763,
      -69.076,
      53.894,
      9.313,
      -67.549,
      53.382,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7694",
    "polygon": [
      -65.98,
      52.857,
      9.313,
      -65.98,
      52.857,
      11.763,
      -66.691,
      53.095,
      11.763,
      -66.691,
      53.095,
      11.763,
      -66.691,
      53.095,
      9.313,
      -65.98,
      52.857,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7695",
    "polygon": [
      -64.453,
      52.345,
      9.313,
      -64.453,
      52.345,
      11.763,
      -65.98,
      52.857,
      11.763,
      -65.98,
      52.857,
      11.763,
      -65.98,
      52.857,
      9.313,
      -64.453,
      52.345,
      9.313
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7696",
    "polygon": [
      -63.86,
      52.147,
      9.313,
      -63.86,
      52.147,
      11.763,
      -64.453,
      52.345,
      11.763,
      -64.453,
      52.345,
      11.763,
      -64.453,
      52.345,
      9.313,
      -63.86,
      52.147,
      9.313
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7697",
    "polygon": [
      -63.149,
      51.908,
      9.313,
      -63.149,
      51.908,
      11.763,
      -63.86,
      52.147,
      11.763,
      -63.86,
      52.147,
      11.763,
      -63.86,
      52.147,
      9.313,
      -63.149,
      51.908,
      9.313
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_2.OG_7698",
    "polygon": [
      -61.89,
      51.487,
      9.313,
      -61.89,
      51.487,
      11.763,
      -63.154,
      51.91,
      11.763,
      -63.154,
      51.91,
      11.763,
      -63.154,
      51.91,
      9.313,
      -61.89,
      51.487,
      9.313
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7699",
    "polygon": [
      -79.302,
      57.319,
      5.563,
      -79.302,
      57.319,
      8.013,
      -80.829,
      57.831,
      8.013,
      -80.829,
      57.831,
      8.013,
      -80.829,
      57.831,
      5.563,
      -79.302,
      57.319,
      5.563
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7700",
    "polygon": [
      -77.832,
      56.827,
      5.563,
      -77.832,
      56.827,
      8.013,
      -78.544,
      57.065,
      8.013,
      -78.544,
      57.065,
      8.013,
      -78.544,
      57.065,
      5.563,
      -77.832,
      56.827,
      5.563
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7701",
    "polygon": [
      -76.306,
      56.316,
      5.563,
      -76.306,
      56.316,
      8.013,
      -77.832,
      56.827,
      8.013,
      -77.832,
      56.827,
      8.013,
      -77.832,
      56.827,
      5.563,
      -76.306,
      56.316,
      5.563
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7702",
    "polygon": [
      -75.713,
      56.117,
      5.563,
      -75.713,
      56.117,
      8.013,
      -76.306,
      56.316,
      8.013,
      -76.306,
      56.316,
      8.013,
      -76.306,
      56.316,
      5.563,
      -75.713,
      56.117,
      5.563
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7703",
    "polygon": [
      -75.002,
      55.879,
      5.563,
      -75.002,
      55.879,
      8.013,
      -75.713,
      56.117,
      8.013,
      -75.713,
      56.117,
      8.013,
      -75.713,
      56.117,
      5.563,
      -75.002,
      55.879,
      5.563
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7704",
    "polygon": [
      -73.475,
      55.368,
      5.563,
      -73.475,
      55.368,
      8.013,
      -75.002,
      55.879,
      8.013,
      -75.002,
      55.879,
      8.013,
      -75.002,
      55.879,
      5.563,
      -73.475,
      55.368,
      5.563
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7705",
    "polygon": [
      -71.906,
      54.842,
      5.563,
      -71.906,
      54.842,
      8.013,
      -72.617,
      55.08,
      8.013,
      -72.617,
      55.08,
      8.013,
      -72.617,
      55.08,
      5.563,
      -71.906,
      54.842,
      5.563
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7706",
    "polygon": [
      -70.379,
      54.33,
      5.563,
      -70.379,
      54.33,
      8.013,
      -71.906,
      54.842,
      8.013,
      -71.906,
      54.842,
      8.013,
      -71.906,
      54.842,
      5.563,
      -70.379,
      54.33,
      5.563
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7707",
    "polygon": [
      -67.448,
      53.348,
      7.316,
      -67.448,
      53.348,
      8.013,
      -69.6,
      54.069,
      8.013,
      -69.6,
      54.069,
      8.013,
      -69.6,
      54.069,
      7.316,
      -67.448,
      53.348,
      7.316
    ]
  },
  {
    "ID": "Block-C-InnerCourt_EG_7708",
    "polygon": [
      -68.524,
      53.709,
      5.0,
      -68.524,
      53.709,
      7.316,
      -69.6,
      54.069,
      7.316,
      -69.6,
      54.069,
      7.316,
      -69.6,
      54.069,
      5.0,
      -68.524,
      53.709,
      5.0
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7709",
    "polygon": [
      -67.448,
      53.348,
      5.0,
      -67.448,
      53.348,
      7.316,
      -68.524,
      53.709,
      7.316,
      -68.524,
      53.709,
      7.316,
      -68.524,
      53.709,
      5.0,
      -67.448,
      53.348,
      5.0
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "Block-C-InnerCourt_EG_7714",
    "polygon": [
      -61.118,
      50.182,
      4.425,
      -61.118,
      50.182,
      6.875,
      -60.694,
      51.446,
      6.875,
      -60.694,
      51.446,
      6.875,
      -60.694,
      51.446,
      4.425,
      -61.118,
      50.182,
      4.425
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7715",
    "polygon": [
      -65.98,
      52.857,
      5.563,
      -65.98,
      52.857,
      8.013,
      -66.691,
      53.095,
      8.013,
      -66.691,
      53.095,
      8.013,
      -66.691,
      53.095,
      5.563,
      -65.98,
      52.857,
      5.563
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7716",
    "polygon": [
      -64.453,
      52.345,
      5.563,
      -64.453,
      52.345,
      8.013,
      -65.98,
      52.857,
      8.013,
      -65.98,
      52.857,
      8.013,
      -65.98,
      52.857,
      5.563,
      -64.453,
      52.345,
      5.563
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7717",
    "polygon": [
      -63.86,
      52.147,
      5.563,
      -63.86,
      52.147,
      8.013,
      -64.453,
      52.345,
      8.013,
      -64.453,
      52.345,
      8.013,
      -64.453,
      52.345,
      5.563,
      -63.86,
      52.147,
      5.563
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7718",
    "polygon": [
      -63.149,
      51.908,
      5.563,
      -63.149,
      51.908,
      8.013,
      -63.86,
      52.147,
      8.013,
      -63.86,
      52.147,
      8.013,
      -63.86,
      52.147,
      5.563,
      -63.149,
      51.908,
      5.563
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_1.OG_7719",
    "polygon": [
      -61.89,
      51.487,
      5.563,
      -61.89,
      51.487,
      8.013,
      -63.154,
      51.91,
      8.013,
      -63.154,
      51.91,
      8.013,
      -63.154,
      51.91,
      5.563,
      -61.89,
      51.487,
      5.563
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Magistrale_EG_7842",
    "polygon": [
      -42.755,
      53.098,
      0.3,
      -42.755,
      53.098,
      1.0,
      -44.608,
      53.719,
      1.0,
      -44.608,
      53.719,
      1.0,
      -44.608,
      53.719,
      0.3,
      -42.755,
      53.098,
      0.3
    ],
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "Magistrale_EG_7994",
    "polygon": [
      -40.901,
      52.477,
      0.3,
      -40.901,
      52.477,
      1.0,
      -42.755,
      53.098,
      1.0,
      -42.755,
      53.098,
      1.0,
      -42.755,
      53.098,
      0.3,
      -40.901,
      52.477,
      0.3
    ],
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "Magistrale_EG_8000",
    "polygon": [
      -39.047,
      51.856,
      0.3,
      -39.047,
      51.856,
      1.0,
      -40.901,
      52.477,
      1.0,
      -40.901,
      52.477,
      1.0,
      -40.901,
      52.477,
      0.3,
      -39.047,
      51.856,
      0.3
    ],
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1
  },
  {
    "ID": "Magistrale_EG_8006",
    "polygon": [
      -37.193,
      51.235,
      0.3,
      -37.193,
      51.235,
      1.0,
      -39.047,
      51.856,
      1.0,
      -39.047,
      51.856,
      1.0,
      -39.047,
      51.856,
      0.3,
      -37.193,
      51.235,
      0.3
    ],
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Magistrale_EG_8012",
    "polygon": [
      -35.34,
      50.614,
      0.3,
      -35.34,
      50.614,
      1.0,
      -37.193,
      51.235,
      1.0,
      -37.193,
      51.235,
      1.0,
      -37.193,
      51.235,
      0.3,
      -35.34,
      50.614,
      0.3
    ],
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Magistrale_EG_8109",
    "polygon": [
      -29.686,
      48.721,
      2.65,
      -29.686,
      48.721,
      3.45,
      -31.583,
      49.356,
      3.45,
      -31.583,
      49.356,
      3.45,
      -31.583,
      49.356,
      2.65,
      -29.686,
      48.721,
      2.65
    ]
  },
  {
    "ID": "Magistrale_EG_8110",
    "polygon": [
      -30.635,
      49.038,
      0.0,
      -30.635,
      49.038,
      2.65,
      -31.583,
      49.356,
      2.65,
      -31.583,
      49.356,
      2.65,
      -31.583,
      49.356,
      0.0,
      -30.635,
      49.038,
      0.0
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Magistrale_EG_8111",
    "polygon": [
      -29.686,
      48.721,
      0.0,
      -29.686,
      48.721,
      2.65,
      -30.635,
      49.038,
      2.65,
      -30.635,
      49.038,
      2.65,
      -30.635,
      49.038,
      0.0,
      -29.686,
      48.721,
      0.0
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_8235",
    "polygon": [
      -1.399,
      31.2,
      13.073,
      -1.399,
      31.2,
      15.523,
      -2.11,
      31.438,
      15.523,
      -2.11,
      31.438,
      15.523,
      -2.11,
      31.438,
      13.073,
      -1.399,
      31.2,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_8236",
    "polygon": [
      0.128,
      30.688,
      13.073,
      0.128,
      30.688,
      15.523,
      -1.399,
      31.2,
      15.523,
      -1.399,
      31.2,
      15.523,
      -1.399,
      31.2,
      13.073,
      0.128,
      30.688,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_8237",
    "polygon": [
      7.251,
      28.302,
      13.073,
      7.251,
      28.302,
      15.523,
      6.539,
      28.541,
      15.523,
      6.539,
      28.541,
      15.523,
      6.539,
      28.541,
      13.073,
      7.251,
      28.302,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_8238",
    "polygon": [
      8.777,
      27.791,
      13.073,
      8.777,
      27.791,
      15.523,
      7.251,
      28.302,
      15.523,
      7.251,
      28.302,
      15.523,
      7.251,
      28.302,
      13.073,
      8.777,
      27.791,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_8239",
    "polygon": [
      9.37,
      27.593,
      13.073,
      9.37,
      27.593,
      15.523,
      8.777,
      27.791,
      15.523,
      8.777,
      27.791,
      15.523,
      8.777,
      27.791,
      13.073,
      9.37,
      27.593,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_8240",
    "polygon": [
      10.081,
      27.354,
      13.073,
      10.081,
      27.354,
      15.523,
      9.37,
      27.593,
      15.523,
      9.37,
      27.593,
      15.523,
      9.37,
      27.593,
      13.073,
      10.081,
      27.354,
      13.073
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_8241",
    "polygon": [
      11.608,
      26.843,
      13.073,
      11.608,
      26.843,
      15.523,
      10.081,
      27.354,
      15.523,
      10.081,
      27.354,
      15.523,
      10.081,
      27.354,
      13.073,
      11.608,
      26.843,
      13.073
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_8242",
    "polygon": [
      0.037,
      30.81,
      11.076,
      0.037,
      30.81,
      11.773,
      -2.115,
      31.531,
      11.773,
      -2.115,
      31.531,
      11.773,
      -2.115,
      31.531,
      11.076,
      0.037,
      30.81,
      11.076
    ]
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_8243",
    "polygon": [
      -1.037,
      31.17,
      8.75,
      -1.037,
      31.17,
      11.076,
      -2.113,
      31.53,
      11.076,
      -2.113,
      31.53,
      11.076,
      -2.113,
      31.53,
      8.75,
      -1.037,
      31.17,
      8.75
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_8244",
    "polygon": [
      0.041,
      30.809,
      8.75,
      0.041,
      30.809,
      11.076,
      -1.035,
      31.169,
      11.076,
      -1.035,
      31.169,
      11.076,
      -1.035,
      31.169,
      8.75,
      0.041,
      30.809,
      8.75
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_8245",
    "polygon": [
      7.278,
      28.384,
      9.323,
      7.278,
      28.384,
      11.773,
      6.567,
      28.623,
      11.773,
      6.567,
      28.623,
      11.773,
      6.567,
      28.623,
      9.323,
      7.278,
      28.384,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_8246",
    "polygon": [
      8.805,
      27.873,
      9.323,
      8.805,
      27.873,
      11.773,
      7.278,
      28.384,
      11.773,
      7.278,
      28.384,
      11.773,
      7.278,
      28.384,
      9.323,
      8.805,
      27.873,
      9.323
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_8247",
    "polygon": [
      9.397,
      27.674,
      9.323,
      9.397,
      27.674,
      11.773,
      8.805,
      27.873,
      11.773,
      8.805,
      27.873,
      11.773,
      8.805,
      27.873,
      9.323,
      9.397,
      27.674,
      9.323
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_8248",
    "polygon": [
      10.108,
      27.436,
      9.323,
      10.108,
      27.436,
      11.773,
      9.397,
      27.674,
      11.773,
      9.397,
      27.674,
      11.773,
      9.397,
      27.674,
      9.323,
      10.108,
      27.436,
      9.323
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-InnerCourt_2.OG_8249",
    "polygon": [
      11.635,
      26.925,
      9.323,
      11.635,
      26.925,
      11.773,
      10.108,
      27.436,
      11.773,
      10.108,
      27.436,
      11.773,
      10.108,
      27.436,
      9.323,
      11.635,
      26.925,
      9.323
    ],
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Magistrale_EG_8358",
    "polygon": [
      30.627,
      28.817,
      4.262,
      30.627,
      28.817,
      4.962,
      29.017,
      29.364,
      4.962,
      29.017,
      29.364,
      4.962,
      29.017,
      29.364,
      4.262,
      30.627,
      28.817,
      4.262
    ]
  },
  {
    "ID": "Magistrale_EG_8380",
    "polygon": [
      33.82,
      27.735,
      0.062,
      33.82,
      27.735,
      3.562,
      32.21,
      28.281,
      3.562,
      32.21,
      28.281,
      3.562,
      32.21,
      28.281,
      0.062,
      33.82,
      27.735,
      0.062
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1
  },
  {
    "ID": "Magistrale_EG_8381",
    "polygon": [
      35.778,
      23.201,
      3.562,
      35.778,
      23.201,
      3.912,
      34.166,
      23.741,
      3.912,
      34.166,
      23.741,
      3.912,
      34.166,
      23.741,
      3.562,
      35.778,
      23.201,
      3.562
    ]
  },
  {
    "ID": "Magistrale_EG_8382",
    "polygon": [
      37.39,
      22.661,
      3.562,
      37.39,
      22.661,
      3.912,
      35.778,
      23.201,
      3.912,
      35.778,
      23.201,
      3.912,
      35.778,
      23.201,
      3.562,
      37.39,
      22.661,
      3.562
    ]
  },
  {
    "ID": "Magistrale_EG_8383",
    "polygon": [
      35.778,
      23.201,
      0.062,
      35.778,
      23.201,
      3.562,
      34.166,
      23.741,
      3.562,
      34.166,
      23.741,
      3.562,
      34.166,
      23.741,
      0.062,
      35.778,
      23.201,
      0.062
    ],
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Magistrale_EG_8384",
    "polygon": [
      37.39,
      22.661,
      0.062,
      37.39,
      22.661,
      3.562,
      35.778,
      23.201,
      3.562,
      35.778,
      23.201,
      3.562,
      35.778,
      23.201,
      0.062,
      37.39,
      22.661,
      0.062
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_108",
    "polygon": [
      -36.516,
      123.624,
      0.745,
      -36.516,
      123.624,
      3.455,
      -36.277,
      124.336,
      3.455,
      -36.277,
      124.336,
      3.455,
      -36.277,
      124.336,
      0.745,
      -36.516,
      123.624,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_455",
    "polygon": [
      -87.713,
      19.331,
      0.0,
      -87.713,
      19.331,
      2.37,
      -89.982,
      20.091,
      2.37,
      -89.982,
      20.091,
      2.37,
      -89.982,
      20.091,
      0.0,
      -87.713,
      19.331,
      0.0
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_461",
    "polygon": [
      -47.999,
      46.37,
      0.0,
      -47.999,
      46.37,
      2.71,
      -48.342,
      45.348,
      2.71,
      -48.342,
      45.348,
      2.71,
      -48.342,
      45.348,
      0.0,
      -47.999,
      46.37,
      0.0
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_462",
    "polygon": [
      -48.349,
      45.327,
      0.0,
      -48.349,
      45.327,
      2.71,
      -48.691,
      44.305,
      2.71,
      -48.691,
      44.305,
      2.71,
      -48.691,
      44.305,
      0.0,
      -48.349,
      45.327,
      0.0
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_485",
    "polygon": [
      3.252,
      -11.3,
      0.0,
      3.252,
      -11.3,
      2.26,
      2.135,
      -10.921,
      2.26,
      2.135,
      -10.921,
      2.26,
      2.135,
      -10.921,
      0.0,
      3.252,
      -11.3,
      0.0
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_486",
    "polygon": [
      4.293,
      -11.653,
      0.0,
      4.293,
      -11.653,
      2.26,
      3.176,
      -11.275,
      2.26,
      3.176,
      -11.275,
      2.26,
      3.176,
      -11.275,
      0.0,
      4.293,
      -11.653,
      0.0
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_EG_820",
    "polygon": [
      39.408,
      -23.295,
      0.745,
      39.408,
      -23.295,
      3.455,
      38.697,
      -23.056,
      3.455,
      38.697,
      -23.056,
      3.455,
      38.697,
      -23.056,
      0.745,
      39.408,
      -23.295,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_821",
    "polygon": [
      42.269,
      -24.253,
      0.745,
      42.269,
      -24.253,
      3.455,
      41.558,
      -24.015,
      3.455,
      41.558,
      -24.015,
      3.455,
      41.558,
      -24.015,
      0.745,
      42.269,
      -24.253,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_822",
    "polygon": [
      40.952,
      -23.812,
      0.745,
      40.952,
      -23.812,
      3.455,
      39.426,
      -23.301,
      3.455,
      39.426,
      -23.301,
      3.455,
      39.426,
      -23.301,
      0.745,
      40.952,
      -23.812,
      0.745
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_1015",
    "polygon": [
      -107.749,
      26.043,
      5.59,
      -107.749,
      26.043,
      8.04,
      -108.346,
      26.243,
      8.04,
      -108.346,
      26.243,
      8.04,
      -108.346,
      26.243,
      5.59,
      -107.749,
      26.043,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_1823",
    "polygon": [
      1.399,
      71.722,
      13.073,
      1.399,
      71.722,
      15.523,
      0.888,
      70.196,
      15.523,
      0.888,
      70.196,
      15.523,
      0.888,
      70.196,
      13.073,
      1.399,
      71.722,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_1824",
    "polygon": [
      0.69,
      69.603,
      13.073,
      0.69,
      69.603,
      15.523,
      0.452,
      68.892,
      15.523,
      0.452,
      68.892,
      15.523,
      0.452,
      68.892,
      13.073,
      0.69,
      69.603,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_1837",
    "polygon": [
      0.888,
      70.196,
      13.073,
      0.888,
      70.196,
      15.523,
      0.69,
      69.603,
      15.523,
      0.69,
      69.603,
      15.523,
      0.69,
      69.603,
      13.073,
      0.888,
      70.196,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_1881",
    "polygon": [
      0.452,
      68.892,
      13.073,
      0.452,
      68.892,
      15.523,
      0.077,
      67.773,
      15.523,
      0.077,
      67.773,
      15.523,
      0.077,
      67.773,
      13.073,
      0.452,
      68.892,
      13.073
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_2022",
    "polygon": [
      14.684,
      -10.497,
      13.104,
      14.684,
      -10.497,
      15.554,
      14.485,
      -11.09,
      15.554,
      14.485,
      -11.09,
      15.554,
      14.485,
      -11.09,
      13.104,
      14.684,
      -10.497,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_2023",
    "polygon": [
      14.485,
      -11.09,
      13.104,
      14.485,
      -11.09,
      15.554,
      14.247,
      -11.801,
      15.554,
      14.247,
      -11.801,
      15.554,
      14.247,
      -11.801,
      13.104,
      14.485,
      -11.09,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_2024",
    "polygon": [
      14.247,
      -11.801,
      13.104,
      14.247,
      -11.801,
      15.554,
      13.736,
      -13.328,
      15.554,
      13.736,
      -13.328,
      15.554,
      13.736,
      -13.328,
      13.104,
      14.247,
      -11.801,
      13.104
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_2039",
    "polygon": [
      -24.045,
      38.984,
      13.104,
      -24.045,
      38.984,
      15.554,
      -23.533,
      40.51,
      15.554,
      -23.533,
      40.51,
      15.554,
      -23.533,
      40.51,
      13.104,
      -24.045,
      38.984,
      13.104
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_2062",
    "polygon": [
      -23.533,
      40.51,
      13.104,
      -23.533,
      40.51,
      15.554,
      -23.295,
      41.222,
      15.554,
      -23.295,
      41.222,
      15.554,
      -23.295,
      41.222,
      13.104,
      -23.533,
      40.51,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_2063",
    "polygon": [
      -23.097,
      41.814,
      13.104,
      -23.097,
      41.814,
      15.554,
      -22.585,
      43.341,
      15.554,
      -22.585,
      43.341,
      15.554,
      -22.585,
      43.341,
      13.104,
      -23.097,
      41.814,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_2065",
    "polygon": [
      -23.295,
      41.222,
      13.104,
      -23.295,
      41.222,
      15.554,
      -23.097,
      41.814,
      15.554,
      -23.097,
      41.814,
      15.554,
      -23.097,
      41.814,
      13.104,
      -23.295,
      41.222,
      13.104
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_2124",
    "polygon": [
      100.462,
      11.594,
      13.072,
      100.462,
      11.594,
      15.522,
      101.987,
      11.077,
      15.522,
      101.987,
      11.077,
      15.522,
      101.987,
      11.077,
      13.072,
      100.462,
      11.594,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_2501",
    "polygon": [
      39.256,
      62.919,
      13.073,
      39.256,
      62.919,
      15.523,
      39.018,
      62.208,
      15.523,
      39.018,
      62.208,
      15.523,
      39.018,
      62.208,
      13.073,
      39.256,
      62.919,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_2502",
    "polygon": [
      39.455,
      63.512,
      13.073,
      39.455,
      63.512,
      15.523,
      39.256,
      62.919,
      15.523,
      39.256,
      62.919,
      15.523,
      39.256,
      62.919,
      13.073,
      39.455,
      63.512,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_2506",
    "polygon": [
      39.018,
      62.208,
      13.073,
      39.018,
      62.208,
      15.523,
      38.488,
      60.624,
      15.523,
      38.488,
      60.624,
      15.523,
      38.488,
      60.624,
      13.073,
      39.018,
      62.208,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1
  },
  {
    "ID": "Block-B-InnerCourt_3.OG_2515",
    "polygon": [
      57.134,
      53.702,
      13.073,
      57.134,
      53.702,
      15.523,
      58.661,
      53.191,
      15.523,
      58.661,
      53.191,
      15.523,
      58.661,
      53.191,
      13.073,
      57.134,
      53.702,
      13.073
    ]
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_2616",
    "polygon": [
      -13.516,
      77.424,
      13.073,
      -13.516,
      77.424,
      15.523,
      -11.99,
      76.913,
      15.523,
      -11.99,
      76.913,
      15.523,
      -11.99,
      76.913,
      13.073,
      -13.516,
      77.424,
      13.073
    ]
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_2617",
    "polygon": [
      -14.228,
      77.662,
      13.073,
      -14.228,
      77.662,
      15.523,
      -13.516,
      77.424,
      15.523,
      -13.516,
      77.424,
      15.523,
      -13.516,
      77.424,
      13.073,
      -14.228,
      77.662,
      13.073
    ]
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_2619",
    "polygon": [
      -30.88,
      85.669,
      13.073,
      -30.88,
      85.669,
      15.523,
      -31.391,
      84.142,
      15.523,
      -31.391,
      84.142,
      15.523,
      -31.391,
      84.142,
      13.073,
      -30.88,
      85.669,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Block-A-InnerCourt_3.OG_2640",
    "polygon": [
      -14.82,
      77.861,
      13.073,
      -14.82,
      77.861,
      15.523,
      -14.228,
      77.662,
      15.523,
      -14.228,
      77.662,
      15.523,
      -14.228,
      77.662,
      13.073,
      -14.82,
      77.861,
      13.073
    ]
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_3015",
    "polygon": [
      -71.41,
      28.262,
      13.063,
      -71.41,
      28.262,
      15.513,
      -69.883,
      27.751,
      15.513,
      -69.883,
      27.751,
      15.513,
      -69.883,
      27.751,
      13.063,
      -71.41,
      28.262,
      13.063
    ]
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_3017",
    "polygon": [
      -72.121,
      28.501,
      13.063,
      -72.121,
      28.501,
      15.513,
      -71.41,
      28.262,
      15.513,
      -71.41,
      28.262,
      15.513,
      -71.41,
      28.262,
      13.063,
      -72.121,
      28.501,
      13.063
    ]
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_3018",
    "polygon": [
      -88.668,
      36.478,
      13.063,
      -88.668,
      36.478,
      15.513,
      -89.179,
      34.952,
      15.513,
      -89.179,
      34.952,
      15.513,
      -89.179,
      34.952,
      13.063,
      -88.668,
      36.478,
      13.063
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_3020",
    "polygon": [
      -88.429,
      37.189,
      13.063,
      -88.429,
      37.189,
      15.513,
      -88.668,
      36.478,
      15.513,
      -88.668,
      36.478,
      15.513,
      -88.668,
      36.478,
      13.063,
      -88.429,
      37.189,
      13.063
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_3041",
    "polygon": [
      -88.231,
      37.782,
      13.063,
      -88.231,
      37.782,
      15.513,
      -88.429,
      37.189,
      15.513,
      -88.429,
      37.189,
      15.513,
      -88.429,
      37.189,
      13.063,
      -88.231,
      37.782,
      13.063
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_3105",
    "polygon": [
      84.529,
      -1.522,
      13.073,
      84.529,
      -1.522,
      15.523,
      84.728,
      -0.929,
      15.523,
      84.728,
      -0.929,
      15.523,
      84.728,
      -0.929,
      13.073,
      84.529,
      -1.522,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_3111",
    "polygon": [
      75.575,
      -20.95,
      13.073,
      75.575,
      -20.95,
      15.523,
      77.102,
      -21.461,
      15.523,
      77.102,
      -21.461,
      15.523,
      77.102,
      -21.461,
      13.073,
      75.575,
      -20.95,
      13.073
    ]
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_3112",
    "polygon": [
      74.864,
      -20.712,
      13.073,
      74.864,
      -20.712,
      15.523,
      75.575,
      -20.95,
      15.523,
      75.575,
      -20.95,
      15.523,
      75.575,
      -20.95,
      13.073,
      74.864,
      -20.712,
      13.073
    ]
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_3122",
    "polygon": [
      84.728,
      -0.929,
      13.073,
      84.728,
      -0.929,
      15.523,
      85.239,
      0.597,
      15.523,
      85.239,
      0.597,
      15.523,
      85.239,
      0.597,
      13.073,
      84.728,
      -0.929,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_3129",
    "polygon": [
      74.272,
      -20.513,
      13.073,
      74.272,
      -20.513,
      15.523,
      74.864,
      -20.712,
      15.523,
      74.864,
      -20.712,
      15.523,
      74.864,
      -20.712,
      13.073,
      74.272,
      -20.513,
      13.073
    ]
  },
  {
    "ID": "Block-E-InnerCourt_3.OG_3138",
    "polygon": [
      85.239,
      0.597,
      13.073,
      85.239,
      0.597,
      15.523,
      85.477,
      1.309,
      15.523,
      85.477,
      1.309,
      15.523,
      85.477,
      1.309,
      13.073,
      85.239,
      0.597,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "Block-C-InnerCourt_3.OG_3168",
    "polygon": [
      -72.714,
      28.699,
      13.063,
      -72.714,
      28.699,
      15.513,
      -72.121,
      28.501,
      15.513,
      -72.121,
      28.501,
      15.513,
      -72.121,
      28.501,
      13.063,
      -72.714,
      28.699,
      13.063
    ]
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_3843",
    "polygon": [
      2.098,
      3.706,
      13.073,
      2.098,
      3.706,
      15.523,
      3.625,
      3.195,
      15.523,
      3.625,
      3.195,
      15.523,
      3.625,
      3.195,
      13.073,
      2.098,
      3.706,
      13.073
    ]
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_3845",
    "polygon": [
      -18.779,
      13.134,
      13.073,
      -18.779,
      13.134,
      15.523,
      -19.29,
      11.607,
      15.523,
      -19.29,
      11.607,
      15.523,
      -19.29,
      11.607,
      13.073,
      -18.779,
      13.134,
      13.073
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_3847",
    "polygon": [
      -18.342,
      14.438,
      13.073,
      -18.342,
      14.438,
      15.523,
      -18.541,
      13.845,
      15.523,
      -18.541,
      13.845,
      15.523,
      -18.541,
      13.845,
      13.073,
      -18.342,
      14.438,
      13.073
    ],
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_3863",
    "polygon": [
      -18.541,
      13.845,
      13.073,
      -18.541,
      13.845,
      15.523,
      -18.779,
      13.134,
      15.523,
      -18.779,
      13.134,
      15.523,
      -18.779,
      13.134,
      13.073,
      -18.541,
      13.845,
      13.073
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_3882",
    "polygon": [
      0.795,
      4.143,
      13.073,
      0.795,
      4.143,
      15.523,
      1.387,
      3.944,
      15.523,
      1.387,
      3.944,
      15.523,
      1.387,
      3.944,
      13.073,
      0.795,
      4.143,
      13.073
    ]
  },
  {
    "ID": "Block-D-InnerCourt_3.OG_3884",
    "polygon": [
      1.387,
      3.944,
      13.073,
      1.387,
      3.944,
      15.523,
      2.098,
      3.706,
      15.523,
      2.098,
      3.706,
      15.523,
      2.098,
      3.706,
      13.073,
      1.387,
      3.944,
      13.073
    ]
  },
  {
    "ID": "Magistrale_EG_2176",
    "polygon": [
      -21.224,
      46.671,
      0.745,
      -21.224,
      46.671,
      3.455,
      -21.462,
      45.96,
      3.455,
      -21.462,
      45.96,
      3.455,
      -21.462,
      45.96,
      0.745,
      -21.224,
      46.671,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2177",
    "polygon": [
      -20.18,
      49.788,
      0.745,
      -20.18,
      49.788,
      3.455,
      -20.418,
      49.077,
      3.455,
      -20.418,
      49.077,
      3.455,
      -20.418,
      49.077,
      0.745,
      -20.18,
      49.788,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2178",
    "polygon": [
      -20.831,
      47.845,
      0.745,
      -20.831,
      47.845,
      3.005,
      -21.205,
      46.726,
      3.005,
      -21.205,
      46.726,
      3.005,
      -21.205,
      46.726,
      0.745,
      -20.831,
      47.845,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2179",
    "polygon": [
      -20.44,
      49.011,
      0.745,
      -20.44,
      49.011,
      3.005,
      -20.815,
      47.893,
      3.005,
      -20.815,
      47.893,
      3.005,
      -20.815,
      47.893,
      0.745,
      -20.44,
      49.011,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2180",
    "polygon": [
      -17.95,
      49.192,
      0.745,
      -17.95,
      49.192,
      3.005,
      -19.069,
      49.567,
      3.005,
      -19.069,
      49.567,
      3.005,
      -19.069,
      49.567,
      0.745,
      -17.95,
      49.192,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2181",
    "polygon": [
      -16.832,
      48.817,
      0.745,
      -16.832,
      48.817,
      3.005,
      -17.95,
      49.192,
      3.005,
      -17.95,
      49.192,
      3.005,
      -17.95,
      49.192,
      0.745,
      -16.832,
      48.817,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2182",
    "polygon": [
      -15.619,
      48.411,
      0.745,
      -15.619,
      48.411,
      3.005,
      -16.737,
      48.786,
      3.005,
      -16.737,
      48.786,
      3.005,
      -16.737,
      48.786,
      0.745,
      -15.619,
      48.411,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2183",
    "polygon": [
      -14.406,
      48.005,
      0.745,
      -14.406,
      48.005,
      3.005,
      -15.524,
      48.379,
      3.005,
      -15.524,
      48.379,
      3.005,
      -15.524,
      48.379,
      0.745,
      -14.406,
      48.005,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2184",
    "polygon": [
      -19.09,
      49.574,
      0.745,
      -19.09,
      49.574,
      3.455,
      -20.112,
      49.916,
      3.455,
      -20.112,
      49.916,
      3.455,
      -20.112,
      49.916,
      0.745,
      -19.09,
      49.574,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2185",
    "polygon": [
      -13.384,
      47.662,
      0.745,
      -13.384,
      47.662,
      3.455,
      -14.406,
      48.005,
      3.455,
      -14.406,
      48.005,
      3.455,
      -14.406,
      48.005,
      0.745,
      -13.384,
      47.662,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2186",
    "polygon": [
      -13.51,
      46.615,
      0.745,
      -13.51,
      46.615,
      3.455,
      -13.362,
      47.682,
      3.455,
      -13.362,
      47.682,
      3.455,
      -13.362,
      47.682,
      0.745,
      -13.51,
      46.615,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2187",
    "polygon": [
      -13.974,
      43.247,
      0.745,
      -13.974,
      43.247,
      3.455,
      -13.827,
      44.314,
      3.455,
      -13.827,
      44.314,
      3.455,
      -13.827,
      44.314,
      0.745,
      -13.974,
      43.247,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2188",
    "polygon": [
      -13.827,
      44.314,
      0.745,
      -13.827,
      44.314,
      3.115,
      -13.5,
      46.685,
      3.115,
      -13.5,
      46.685,
      3.115,
      -13.5,
      46.685,
      0.745,
      -13.827,
      44.314,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2202",
    "polygon": [
      21.932,
      34.8,
      0.745,
      21.932,
      34.8,
      3.115,
      20.765,
      32.711,
      3.115,
      20.765,
      32.711,
      3.115,
      20.765,
      32.711,
      0.745,
      21.932,
      34.8,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2203",
    "polygon": [
      22.356,
      35.558,
      0.745,
      22.356,
      35.558,
      3.455,
      21.99,
      34.904,
      3.455,
      21.99,
      34.904,
      3.455,
      21.99,
      34.904,
      0.745,
      22.356,
      35.558,
      0.745
    ]
  },
  {
    "ID": "Magistrale_EG_2204",
    "polygon": [
      20.844,
      32.853,
      1.415,
      20.844,
      32.853,
      3.675,
      20.269,
      31.823,
      3.675,
      20.269,
      31.823,
      3.675,
      20.269,
      31.823,
      1.415,
      20.844,
      32.853,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2205",
    "polygon": [
      24.522,
      34.947,
      1.415,
      24.522,
      34.947,
      3.675,
      23.404,
      35.322,
      3.675,
      23.404,
      35.322,
      3.675,
      23.404,
      35.322,
      1.415,
      24.522,
      34.947,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2206",
    "polygon": [
      25.735,
      34.541,
      1.415,
      25.735,
      34.541,
      3.675,
      24.617,
      34.916,
      3.675,
      24.617,
      34.916,
      3.675,
      24.617,
      34.916,
      1.415,
      25.735,
      34.541,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2207",
    "polygon": [
      26.948,
      34.135,
      1.415,
      26.948,
      34.135,
      3.675,
      25.83,
      34.509,
      3.675,
      25.83,
      34.509,
      3.675,
      25.83,
      34.509,
      1.415,
      26.948,
      34.135,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2208",
    "polygon": [
      28.161,
      33.728,
      1.415,
      28.161,
      33.728,
      3.675,
      27.043,
      34.103,
      3.675,
      27.043,
      34.103,
      3.675,
      27.043,
      34.103,
      1.415,
      28.161,
      33.728,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2209",
    "polygon": [
      28.53,
      31.408,
      1.415,
      28.53,
      31.408,
      3.675,
      28.904,
      32.526,
      3.675,
      28.904,
      32.526,
      3.675,
      28.904,
      32.526,
      1.415,
      28.53,
      31.408,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2210",
    "polygon": [
      28.155,
      30.29,
      1.415,
      28.155,
      30.29,
      3.675,
      28.53,
      31.408,
      3.675,
      28.53,
      31.408,
      3.675,
      28.53,
      31.408,
      1.415,
      28.155,
      30.29,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2211",
    "polygon": [
      27.861,
      29.413,
      1.415,
      27.861,
      29.413,
      4.125,
      28.123,
      30.195,
      4.125,
      28.123,
      30.195,
      4.125,
      28.123,
      30.195,
      1.415,
      27.861,
      29.413,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2212",
    "polygon": [
      28.912,
      32.548,
      1.415,
      28.912,
      32.548,
      4.125,
      29.174,
      33.33,
      4.125,
      29.174,
      33.33,
      4.125,
      29.174,
      33.33,
      1.415,
      28.912,
      32.548,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2213",
    "polygon": [
      29.021,
      33.44,
      1.415,
      29.021,
      33.44,
      4.125,
      28.239,
      33.702,
      4.125,
      28.239,
      33.702,
      4.125,
      28.239,
      33.702,
      1.415,
      29.021,
      33.44,
      1.415
    ]
  },
  {
    "ID": "Magistrale_EG_2214",
    "polygon": [
      23.404,
      35.322,
      1.415,
      23.404,
      35.322,
      4.125,
      22.622,
      35.584,
      4.125,
      22.622,
      35.584,
      4.125,
      22.622,
      35.584,
      1.415,
      23.404,
      35.322,
      1.415
    ]
  },
  {
    "ID": "Block-D-InnerCourt_EG_2215",
    "polygon": [
      -19.412,
      13.812,
      0.745,
      -19.412,
      13.812,
      3.455,
      -19.598,
      11.688,
      3.455,
      -19.598,
      11.688,
      3.455,
      -19.598,
      11.688,
      0.745,
      -19.412,
      13.812,
      0.745
    ]
  },
  {
    "ID": "Block-D-InnerCourt_EG_2216",
    "polygon": [
      -18.833,
      10.684,
      0.745,
      -18.833,
      10.684,
      3.455,
      -16.813,
      10.003,
      3.455,
      -16.813,
      10.003,
      3.455,
      -16.813,
      10.003,
      0.745,
      -18.833,
      10.684,
      0.745
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4120",
    "polygon": [
      -94.742,
      68.478,
      1.415,
      -94.742,
      68.478,
      4.125,
      -95.767,
      68.811,
      4.125,
      -95.767,
      68.811,
      4.125,
      -95.767,
      68.811,
      1.415,
      -94.742,
      68.478,
      1.415
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4121",
    "polygon": [
      -93.981,
      68.231,
      1.415,
      -93.981,
      68.231,
      4.125,
      -95.006,
      68.564,
      4.125,
      -95.006,
      68.564,
      4.125,
      -95.006,
      68.564,
      1.415,
      -93.981,
      68.231,
      1.415
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4122",
    "polygon": [
      -93.059,
      67.931,
      1.415,
      -93.059,
      67.931,
      4.125,
      -94.084,
      68.264,
      4.125,
      -94.084,
      68.264,
      4.125,
      -94.084,
      68.264,
      1.415,
      -93.059,
      67.931,
      1.415
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4123",
    "polygon": [
      -97.43,
      63.938,
      0.724,
      -97.43,
      63.938,
      3.434,
      -96.405,
      63.605,
      3.434,
      -96.405,
      63.605,
      3.434,
      -96.405,
      63.605,
      0.724,
      -97.43,
      63.938,
      0.724
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4124",
    "polygon": [
      -96.546,
      63.65,
      0.724,
      -96.546,
      63.65,
      3.434,
      -95.521,
      63.317,
      3.434,
      -95.521,
      63.317,
      3.434,
      -95.521,
      63.317,
      0.724,
      -96.546,
      63.65,
      0.724
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4125",
    "polygon": [
      -95.566,
      63.332,
      0.724,
      -95.566,
      63.332,
      3.434,
      -94.541,
      62.999,
      3.434,
      -94.541,
      62.999,
      3.434,
      -94.541,
      62.999,
      0.724,
      -95.566,
      63.332,
      0.724
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4126",
    "polygon": [
      -93.441,
      66.308,
      0.745,
      -93.441,
      66.308,
      3.505,
      -92.915,
      67.925,
      3.505,
      -92.915,
      67.925,
      3.505,
      -92.915,
      67.925,
      0.745,
      -93.441,
      66.308,
      0.745
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4127",
    "polygon": [
      -94.466,
      63.152,
      0.724,
      -94.466,
      63.152,
      3.434,
      -94.027,
      64.504,
      3.434,
      -94.027,
      64.504,
      3.434,
      -94.027,
      64.504,
      0.724,
      -94.466,
      63.152,
      0.724
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4128",
    "polygon": [
      -104.681,
      38.852,
      1.415,
      -104.681,
      38.852,
      4.125,
      -105.703,
      39.194,
      4.125,
      -105.703,
      39.194,
      4.125,
      -105.703,
      39.194,
      1.415,
      -104.681,
      38.852,
      1.415
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4129",
    "polygon": [
      -103.922,
      38.598,
      1.415,
      -103.922,
      38.598,
      4.125,
      -104.944,
      38.94,
      4.125,
      -104.944,
      38.94,
      4.125,
      -104.944,
      38.94,
      1.415,
      -103.922,
      38.598,
      1.415
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4130",
    "polygon": [
      -103.003,
      38.29,
      1.415,
      -103.003,
      38.29,
      4.125,
      -104.026,
      38.632,
      4.125,
      -104.026,
      38.632,
      4.125,
      -104.026,
      38.632,
      1.415,
      -103.003,
      38.29,
      1.415
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4131",
    "polygon": [
      -107.316,
      34.304,
      0.724,
      -107.316,
      34.304,
      3.434,
      -106.294,
      33.962,
      3.434,
      -106.294,
      33.962,
      3.434,
      -106.294,
      33.962,
      0.724,
      -107.316,
      34.304,
      0.724
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4132",
    "polygon": [
      -106.434,
      34.009,
      0.724,
      -106.434,
      34.009,
      3.434,
      -105.412,
      33.666,
      3.434,
      -105.412,
      33.666,
      3.434,
      -105.412,
      33.666,
      0.724,
      -106.434,
      34.009,
      0.724
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4133",
    "polygon": [
      -105.533,
      33.707,
      0.724,
      -105.533,
      33.707,
      3.434,
      -104.511,
      33.364,
      3.434,
      -104.511,
      33.364,
      3.434,
      -104.511,
      33.364,
      0.724,
      -105.533,
      33.707,
      0.724
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4134",
    "polygon": [
      -103.296,
      36.664,
      0.745,
      -103.296,
      36.664,
      3.505,
      -102.771,
      38.281,
      3.505,
      -102.771,
      38.281,
      3.505,
      -102.771,
      38.281,
      0.745,
      -103.296,
      36.664,
      0.745
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4135",
    "polygon": [
      -104.322,
      33.508,
      0.724,
      -104.322,
      33.508,
      3.434,
      -103.882,
      34.861,
      3.434,
      -103.882,
      34.861,
      3.434,
      -103.882,
      34.861,
      0.724,
      -104.322,
      33.508,
      0.724
    ]
  },
  {
    "ID": "Magistrale_3.OG_4381",
    "polygon": [
      20.414,
      57.852,
      13.073,
      20.414,
      57.852,
      16.073,
      22.206,
      57.252,
      16.073,
      22.206,
      57.252,
      16.073,
      22.206,
      57.252,
      13.073,
      20.414,
      57.852,
      13.073
    ]
  },
  {
    "ID": "Magistrale_2.OG_4411",
    "polygon": [
      -0.271,
      64.78,
      9.323,
      -0.271,
      64.78,
      12.323,
      1.521,
      64.179,
      12.323,
      1.521,
      64.179,
      12.323,
      1.521,
      64.179,
      9.323,
      -0.271,
      64.78,
      9.323
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_4449",
    "polygon": [
      -107.604,
      25.994,
      0.0,
      -107.604,
      25.994,
      3.4,
      -108.315,
      26.233,
      3.4,
      -108.315,
      26.233,
      3.4,
      -108.315,
      26.233,
      0.0,
      -107.604,
      25.994,
      0.0
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-C-Outer_EG_4450",
    "polygon": [
      -106.214,
      25.529,
      0.0,
      -106.214,
      25.529,
      3.4,
      -107.618,
      25.999,
      3.4,
      -107.618,
      25.999,
      3.4,
      -107.618,
      25.999,
      0.0,
      -106.214,
      25.529,
      0.0
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4451",
    "polygon": [
      -105.516,
      25.295,
      0.0,
      -105.516,
      25.295,
      3.4,
      -106.227,
      25.533,
      3.4,
      -106.227,
      25.533,
      3.4,
      -106.227,
      25.533,
      0.0,
      -105.516,
      25.295,
      0.0
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4452",
    "polygon": [
      -104.793,
      25.053,
      0.0,
      -104.793,
      25.053,
      3.4,
      -105.504,
      25.291,
      3.4,
      -105.504,
      25.291,
      3.4,
      -105.504,
      25.291,
      0.0,
      -104.793,
      25.053,
      0.0
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-C-Outer_EG_4454",
    "polygon": [
      -103.266,
      24.541,
      0.0,
      -103.266,
      24.541,
      3.4,
      -104.793,
      25.053,
      3.4,
      -104.793,
      25.053,
      3.4,
      -104.793,
      25.053,
      0.0,
      -103.266,
      24.541,
      0.0
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4455",
    "polygon": [
      -101.633,
      23.994,
      0.0,
      -101.633,
      23.994,
      3.4,
      -102.345,
      24.233,
      3.4,
      -102.345,
      24.233,
      3.4,
      -102.345,
      24.233,
      0.0,
      -101.633,
      23.994,
      0.0
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-C-Outer_EG_4456",
    "polygon": [
      -100.243,
      23.529,
      0.0,
      -100.243,
      23.529,
      3.4,
      -101.647,
      23.999,
      3.4,
      -101.647,
      23.999,
      3.4,
      -101.647,
      23.999,
      0.0,
      -100.243,
      23.529,
      0.0
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4457",
    "polygon": [
      -99.545,
      23.295,
      0.0,
      -99.545,
      23.295,
      3.4,
      -100.257,
      23.533,
      3.4,
      -100.257,
      23.533,
      3.4,
      -100.257,
      23.533,
      0.0,
      -99.545,
      23.295,
      0.0
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4458",
    "polygon": [
      -98.822,
      23.052,
      0.0,
      -98.822,
      23.052,
      3.4,
      -99.533,
      23.291,
      3.4,
      -99.533,
      23.291,
      3.4,
      -99.533,
      23.291,
      0.0,
      -98.822,
      23.052,
      0.0
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-C-Outer_EG_4459",
    "polygon": [
      -97.295,
      22.541,
      0.0,
      -97.295,
      22.541,
      3.4,
      -98.822,
      23.052,
      3.4,
      -98.822,
      23.052,
      3.4,
      -98.822,
      23.052,
      0.0,
      -97.295,
      22.541,
      0.0
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4460",
    "polygon": [
      -95.792,
      22.038,
      0.0,
      -95.792,
      22.038,
      3.4,
      -96.504,
      22.276,
      3.4,
      -96.504,
      22.276,
      3.4,
      -96.504,
      22.276,
      0.0,
      -95.792,
      22.038,
      0.0
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-C-Outer_EG_4461",
    "polygon": [
      -94.402,
      21.572,
      0.0,
      -94.402,
      21.572,
      3.4,
      -95.806,
      22.042,
      3.4,
      -95.806,
      22.042,
      3.4,
      -95.806,
      22.042,
      0.0,
      -94.402,
      21.572,
      0.0
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4462",
    "polygon": [
      -93.704,
      21.338,
      0.0,
      -93.704,
      21.338,
      3.4,
      -94.416,
      21.576,
      3.4,
      -94.416,
      21.576,
      3.4,
      -94.416,
      21.576,
      0.0,
      -93.704,
      21.338,
      0.0
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4463",
    "polygon": [
      -87.713,
      19.331,
      2.37,
      -87.713,
      19.331,
      3.37,
      -89.982,
      20.091,
      3.37,
      -89.982,
      20.091,
      3.37,
      -89.982,
      20.091,
      2.37,
      -87.713,
      19.331,
      2.37
    ]
  },
  {
    "ID": "Block-C-Outer_EG_4465",
    "polygon": [
      -79.493,
      16.578,
      0.605,
      -79.493,
      16.578,
      3.365,
      -81.105,
      17.118,
      3.365,
      -81.105,
      17.118,
      3.365,
      -81.105,
      17.118,
      0.605,
      -79.493,
      16.578,
      0.605
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_4466",
    "polygon": [
      -77.899,
      16.044,
      0.605,
      -77.899,
      16.044,
      3.365,
      -78.61,
      16.282,
      3.365,
      -78.61,
      16.282,
      3.365,
      -78.61,
      16.282,
      0.605,
      -77.899,
      16.044,
      0.605
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4467",
    "polygon": [
      -76.496,
      15.574,
      0.605,
      -76.496,
      15.574,
      3.365,
      -77.899,
      16.044,
      3.365,
      -77.899,
      16.044,
      3.365,
      -77.899,
      16.044,
      0.605,
      -76.496,
      15.574,
      0.605
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4468",
    "polygon": [
      -75.785,
      15.335,
      0.605,
      -75.785,
      15.335,
      3.365,
      -76.496,
      15.574,
      3.365,
      -76.496,
      15.574,
      3.365,
      -76.496,
      15.574,
      0.605,
      -75.785,
      15.335,
      0.605
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4469",
    "polygon": [
      -75.053,
      15.09,
      0.605,
      -75.053,
      15.09,
      3.365,
      -75.764,
      15.328,
      3.365,
      -75.764,
      15.328,
      3.365,
      -75.764,
      15.328,
      0.605,
      -75.053,
      15.09,
      0.605
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-C-Outer_EG_4470",
    "polygon": [
      -73.526,
      14.579,
      0.605,
      -73.526,
      14.579,
      3.365,
      -75.053,
      15.09,
      3.365,
      -75.053,
      15.09,
      3.365,
      -75.053,
      15.09,
      0.605,
      -73.526,
      14.579,
      0.605
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_4471",
    "polygon": [
      -34.084,
      1.364,
      0.605,
      -34.084,
      1.364,
      3.365,
      -34.794,
      1.605,
      3.365,
      -34.794,
      1.605,
      3.365,
      -34.794,
      1.605,
      0.605,
      -34.084,
      1.364,
      0.605
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_4472",
    "polygon": [
      -32.559,
      0.847,
      0.605,
      -32.559,
      0.847,
      3.365,
      -34.084,
      1.364,
      3.365,
      -34.084,
      1.364,
      3.365,
      -34.084,
      1.364,
      0.605,
      -32.559,
      0.847,
      0.605
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_4473",
    "polygon": [
      -31.972,
      0.648,
      0.605,
      -31.972,
      0.648,
      3.365,
      -32.559,
      0.847,
      3.365,
      -32.559,
      0.847,
      3.365,
      -32.559,
      0.847,
      0.605,
      -31.972,
      0.648,
      0.605
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_4474",
    "polygon": [
      -31.262,
      0.407,
      0.605,
      -31.262,
      0.407,
      3.365,
      -31.972,
      0.648,
      3.365,
      -31.972,
      0.648,
      3.365,
      -31.972,
      0.648,
      0.605,
      -31.262,
      0.407,
      0.605
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_4475",
    "polygon": [
      -29.737,
      -0.111,
      0.605,
      -29.737,
      -0.111,
      3.365,
      -31.262,
      0.407,
      3.365,
      -31.262,
      0.407,
      3.365,
      -31.262,
      0.407,
      0.605,
      -29.737,
      -0.111,
      0.605
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_4476",
    "polygon": [
      -28.214,
      -0.627,
      0.605,
      -28.214,
      -0.627,
      3.365,
      -28.924,
      -0.386,
      3.365,
      -28.924,
      -0.386,
      3.365,
      -28.924,
      -0.386,
      0.605,
      -28.214,
      -0.627,
      0.605
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_4477",
    "polygon": [
      -26.689,
      -1.144,
      0.605,
      -26.689,
      -1.144,
      3.365,
      -28.214,
      -0.627,
      3.365,
      -28.214,
      -0.627,
      3.365,
      -28.214,
      -0.627,
      0.605,
      -26.689,
      -1.144,
      0.605
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_4478",
    "polygon": [
      -26.102,
      -1.343,
      0.605,
      -26.102,
      -1.343,
      3.365,
      -26.689,
      -1.144,
      3.365,
      -26.689,
      -1.144,
      3.365,
      -26.689,
      -1.144,
      0.605,
      -26.102,
      -1.343,
      0.605
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_4479",
    "polygon": [
      -25.392,
      -1.584,
      0.605,
      -25.392,
      -1.584,
      3.365,
      -26.102,
      -1.343,
      3.365,
      -26.102,
      -1.343,
      3.365,
      -26.102,
      -1.343,
      0.605,
      -25.392,
      -1.584,
      0.605
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_4480",
    "polygon": [
      -23.867,
      -2.101,
      0.605,
      -23.867,
      -2.101,
      3.365,
      -25.392,
      -1.584,
      3.365,
      -25.392,
      -1.584,
      3.365,
      -25.392,
      -1.584,
      0.605,
      -23.867,
      -2.101,
      0.605
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_4481",
    "polygon": [
      -22.328,
      -2.623,
      0.605,
      -22.328,
      -2.623,
      3.365,
      -23.039,
      -2.383,
      3.365,
      -23.039,
      -2.383,
      3.365,
      -23.039,
      -2.383,
      0.605,
      -22.328,
      -2.623,
      0.605
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_4482",
    "polygon": [
      -20.804,
      -3.141,
      0.605,
      -20.804,
      -3.141,
      3.365,
      -22.328,
      -2.623,
      3.365,
      -22.328,
      -2.623,
      3.365,
      -22.328,
      -2.623,
      0.605,
      -20.804,
      -3.141,
      0.605
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_EG_4483",
    "polygon": [
      -20.217,
      -3.34,
      0.605,
      -20.217,
      -3.34,
      3.365,
      -20.804,
      -3.141,
      3.365,
      -20.804,
      -3.141,
      3.365,
      -20.804,
      -3.141,
      0.605,
      -20.217,
      -3.34,
      0.605
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_4484",
    "polygon": [
      -19.506,
      -3.581,
      0.605,
      -19.506,
      -3.581,
      3.365,
      -20.217,
      -3.34,
      3.365,
      -20.217,
      -3.34,
      3.365,
      -20.217,
      -3.34,
      0.605,
      -19.506,
      -3.581,
      0.605
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_4486",
    "polygon": [
      -18.029,
      -4.082,
      0.605,
      -18.029,
      -4.082,
      3.365,
      -19.506,
      -3.581,
      3.365,
      -19.506,
      -3.581,
      3.365,
      -19.506,
      -3.581,
      0.605,
      -18.029,
      -4.082,
      0.605
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_4487",
    "polygon": [
      -11.76,
      -6.208,
      2.408,
      -11.76,
      -6.208,
      3.408,
      -14.222,
      -5.373,
      3.408,
      -14.222,
      -5.373,
      3.408,
      -14.222,
      -5.373,
      2.408,
      -11.76,
      -6.208,
      2.408
    ]
  },
  {
    "ID": "Block-D-Outer_EG_4488",
    "polygon": [
      -11.749,
      -6.212,
      0.0,
      -11.749,
      -6.212,
      2.408,
      -13.012,
      -5.784,
      2.408,
      -13.012,
      -5.784,
      2.408,
      -13.012,
      -5.784,
      0.0,
      -11.749,
      -6.212,
      0.0
    ],
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "Block-D-Outer_EG_4489",
    "polygon": [
      2.135,
      -10.921,
      0.0,
      2.135,
      -10.921,
      2.7,
      0.781,
      -10.462,
      2.7,
      0.781,
      -10.462,
      2.7,
      0.781,
      -10.462,
      0.0,
      2.135,
      -10.921,
      0.0
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "Block-D-Outer_EG_4490",
    "polygon": [
      5.647,
      -12.113,
      0.0,
      5.647,
      -12.113,
      2.7,
      4.293,
      -11.653,
      2.7,
      4.293,
      -11.653,
      2.7,
      4.293,
      -11.653,
      0.0,
      5.647,
      -12.113,
      0.0
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4491",
    "polygon": [
      41.54,
      -24.009,
      0.745,
      41.54,
      -24.009,
      3.455,
      40.952,
      -23.812,
      3.455,
      40.952,
      -23.812,
      3.455,
      40.952,
      -23.812,
      0.745,
      41.54,
      -24.009,
      0.745
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_4492",
    "polygon": [
      43.813,
      -24.77,
      0.745,
      43.813,
      -24.77,
      3.455,
      42.287,
      -24.259,
      3.455,
      42.287,
      -24.259,
      3.455,
      42.287,
      -24.259,
      0.745,
      43.813,
      -24.77,
      0.745
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4493",
    "polygon": [
      45.308,
      -25.271,
      0.745,
      45.308,
      -25.271,
      3.455,
      44.596,
      -25.033,
      3.455,
      44.596,
      -25.033,
      3.455,
      44.596,
      -25.033,
      0.745,
      45.308,
      -25.271,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4494",
    "polygon": [
      48.169,
      -26.229,
      0.745,
      48.169,
      -26.229,
      3.455,
      47.458,
      -25.991,
      3.455,
      47.458,
      -25.991,
      3.455,
      47.458,
      -25.991,
      0.745,
      48.169,
      -26.229,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4495",
    "polygon": [
      46.852,
      -25.788,
      0.745,
      46.852,
      -25.788,
      3.455,
      45.325,
      -25.277,
      3.455,
      45.325,
      -25.277,
      3.455,
      45.325,
      -25.277,
      0.745,
      46.852,
      -25.788,
      0.745
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4496",
    "polygon": [
      47.44,
      -25.985,
      0.745,
      47.44,
      -25.985,
      3.455,
      46.852,
      -25.788,
      3.455,
      46.852,
      -25.788,
      3.455,
      46.852,
      -25.788,
      0.745,
      47.44,
      -25.985,
      0.745
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_4497",
    "polygon": [
      49.713,
      -26.747,
      0.745,
      49.713,
      -26.747,
      3.455,
      48.186,
      -26.235,
      3.455,
      48.186,
      -26.235,
      3.455,
      48.186,
      -26.235,
      0.745,
      49.713,
      -26.747,
      0.745
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4498",
    "polygon": [
      51.255,
      -27.263,
      0.745,
      51.255,
      -27.263,
      3.455,
      50.544,
      -27.025,
      3.455,
      50.544,
      -27.025,
      3.455,
      50.544,
      -27.025,
      0.745,
      51.255,
      -27.263,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4499",
    "polygon": [
      54.122,
      -28.224,
      0.745,
      54.122,
      -28.224,
      3.455,
      53.41,
      -27.985,
      3.455,
      53.41,
      -27.985,
      3.455,
      53.41,
      -27.985,
      0.745,
      54.122,
      -28.224,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4500",
    "polygon": [
      52.805,
      -27.782,
      0.745,
      52.805,
      -27.782,
      3.455,
      51.278,
      -27.271,
      3.455,
      51.278,
      -27.271,
      3.455,
      51.278,
      -27.271,
      0.745,
      52.805,
      -27.782,
      0.745
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4501",
    "polygon": [
      53.393,
      -27.979,
      0.745,
      53.393,
      -27.979,
      3.455,
      52.805,
      -27.782,
      3.455,
      52.805,
      -27.782,
      3.455,
      52.805,
      -27.782,
      0.745,
      53.393,
      -27.979,
      0.745
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_4502",
    "polygon": [
      55.666,
      -28.741,
      0.745,
      55.666,
      -28.741,
      3.455,
      54.139,
      -28.23,
      3.455,
      54.139,
      -28.23,
      3.455,
      54.139,
      -28.23,
      0.745,
      55.666,
      -28.741,
      0.745
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4503",
    "polygon": [
      57.161,
      -29.242,
      0.745,
      57.161,
      -29.242,
      3.455,
      56.45,
      -29.004,
      3.455,
      56.45,
      -29.004,
      3.455,
      56.45,
      -29.004,
      0.745,
      57.161,
      -29.242,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4504",
    "polygon": [
      60.022,
      -30.2,
      0.745,
      60.022,
      -30.2,
      3.455,
      59.311,
      -29.962,
      3.455,
      59.311,
      -29.962,
      3.455,
      59.311,
      -29.962,
      0.745,
      60.022,
      -30.2,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4505",
    "polygon": [
      58.706,
      -29.759,
      0.745,
      58.706,
      -29.759,
      3.455,
      57.179,
      -29.248,
      3.455,
      57.179,
      -29.248,
      3.455,
      57.179,
      -29.248,
      0.745,
      58.706,
      -29.759,
      0.745
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4506",
    "polygon": [
      59.293,
      -29.956,
      0.745,
      59.293,
      -29.956,
      3.455,
      58.706,
      -29.759,
      3.455,
      58.706,
      -29.759,
      3.455,
      58.706,
      -29.759,
      0.745,
      59.293,
      -29.956,
      0.745
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_4516",
    "polygon": [
      70.453,
      -33.694,
      2.31,
      70.453,
      -33.694,
      3.31,
      68.366,
      -32.995,
      3.31,
      68.366,
      -32.995,
      3.31,
      68.366,
      -32.995,
      2.31,
      70.453,
      -33.694,
      2.31
    ]
  },
  {
    "ID": "Block-E-Outer_EG_4517",
    "polygon": [
      70.453,
      -33.694,
      0.0,
      70.453,
      -33.694,
      2.31,
      69.41,
      -33.345,
      2.31,
      69.41,
      -33.345,
      2.31,
      69.41,
      -33.345,
      0.0,
      70.453,
      -33.694,
      0.0
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_4518",
    "polygon": [
      69.41,
      -33.345,
      0.0,
      69.41,
      -33.345,
      2.31,
      68.367,
      -32.996,
      2.31,
      68.367,
      -32.996,
      2.31,
      68.367,
      -32.996,
      0.0,
      69.41,
      -33.345,
      0.0
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4519",
    "polygon": [
      61.519,
      -30.702,
      0.745,
      61.519,
      -30.702,
      3.455,
      60.04,
      -30.206,
      3.455,
      60.04,
      -30.206,
      3.455,
      60.04,
      -30.206,
      0.745,
      61.519,
      -30.702,
      0.745
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4520",
    "polygon": [
      75.005,
      -35.219,
      0.745,
      75.005,
      -35.219,
      3.455,
      74.294,
      -34.981,
      3.455,
      74.294,
      -34.981,
      3.455,
      74.294,
      -34.981,
      0.745,
      75.005,
      -35.219,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4521",
    "polygon": [
      77.818,
      -36.162,
      0.745,
      77.818,
      -36.162,
      3.455,
      77.107,
      -35.923,
      3.455,
      77.107,
      -35.923,
      3.455,
      77.107,
      -35.923,
      0.745,
      77.818,
      -36.162,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4522",
    "polygon": [
      76.502,
      -35.721,
      0.745,
      76.502,
      -35.721,
      3.455,
      75.022,
      -35.225,
      3.455,
      75.022,
      -35.225,
      3.455,
      75.022,
      -35.225,
      0.745,
      76.502,
      -35.721,
      0.745
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4523",
    "polygon": [
      77.089,
      -35.918,
      0.745,
      77.089,
      -35.918,
      3.455,
      76.502,
      -35.721,
      3.455,
      76.502,
      -35.721,
      3.455,
      76.502,
      -35.721,
      0.745,
      77.089,
      -35.918,
      0.745
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_4524",
    "polygon": [
      79.363,
      -36.679,
      0.745,
      79.363,
      -36.679,
      3.455,
      77.836,
      -36.168,
      3.455,
      77.836,
      -36.168,
      3.455,
      77.836,
      -36.168,
      0.745,
      79.363,
      -36.679,
      0.745
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4525",
    "polygon": [
      80.858,
      -37.18,
      0.745,
      80.858,
      -37.18,
      3.455,
      80.147,
      -36.942,
      3.455,
      80.147,
      -36.942,
      3.455,
      80.147,
      -36.942,
      0.745,
      80.858,
      -37.18,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4526",
    "polygon": [
      83.719,
      -38.138,
      0.745,
      83.719,
      -38.138,
      3.455,
      83.008,
      -37.9,
      3.455,
      83.008,
      -37.9,
      3.455,
      83.008,
      -37.9,
      0.745,
      83.719,
      -38.138,
      0.745
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_4527",
    "polygon": [
      82.402,
      -37.697,
      0.745,
      82.402,
      -37.697,
      3.455,
      80.876,
      -37.186,
      3.455,
      80.876,
      -37.186,
      3.455,
      80.876,
      -37.186,
      0.745,
      82.402,
      -37.697,
      0.745
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_4528",
    "polygon": [
      82.99,
      -37.894,
      0.745,
      82.99,
      -37.894,
      3.455,
      82.402,
      -37.697,
      3.455,
      82.402,
      -37.697,
      3.455,
      82.402,
      -37.697,
      0.745,
      82.99,
      -37.894,
      0.745
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_4529",
    "polygon": [
      85.263,
      -38.656,
      0.745,
      85.263,
      -38.656,
      3.455,
      83.737,
      -38.144,
      3.455,
      83.737,
      -38.144,
      3.455,
      83.737,
      -38.144,
      0.745,
      85.263,
      -38.656,
      0.745
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4530",
    "polygon": [
      -105.506,
      25.292,
      5.59,
      -105.506,
      25.292,
      8.04,
      -106.089,
      25.487,
      8.04,
      -106.089,
      25.487,
      8.04,
      -106.089,
      25.487,
      5.59,
      -105.506,
      25.292,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4531",
    "polygon": [
      -106.089,
      25.487,
      5.59,
      -106.089,
      25.487,
      8.04,
      -107.749,
      26.043,
      8.04,
      -107.749,
      26.043,
      8.04,
      -107.749,
      26.043,
      5.59,
      -106.089,
      25.487,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4532",
    "polygon": [
      -104.816,
      25.061,
      5.59,
      -104.816,
      25.061,
      8.04,
      -105.528,
      25.299,
      8.04,
      -105.528,
      25.299,
      8.04,
      -105.528,
      25.299,
      5.59,
      -104.816,
      25.061,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4533",
    "polygon": [
      -103.302,
      24.553,
      5.59,
      -103.302,
      24.553,
      8.04,
      -104.828,
      25.065,
      8.04,
      -104.828,
      25.065,
      8.04,
      -104.828,
      25.065,
      5.59,
      -103.302,
      24.553,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4534",
    "polygon": [
      -101.839,
      24.063,
      5.59,
      -101.839,
      24.063,
      8.04,
      -102.436,
      24.263,
      8.04,
      -102.436,
      24.263,
      8.04,
      -102.436,
      24.263,
      5.59,
      -101.839,
      24.063,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4535",
    "polygon": [
      -99.596,
      23.312,
      5.59,
      -99.596,
      23.312,
      8.04,
      -100.179,
      23.507,
      8.04,
      -100.179,
      23.507,
      8.04,
      -100.179,
      23.507,
      5.59,
      -99.596,
      23.312,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4536",
    "polygon": [
      -100.179,
      23.507,
      5.59,
      -100.179,
      23.507,
      8.04,
      -101.839,
      24.063,
      8.04,
      -101.839,
      24.063,
      8.04,
      -101.839,
      24.063,
      5.59,
      -100.179,
      23.507,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4537",
    "polygon": [
      -98.906,
      23.081,
      5.59,
      -98.906,
      23.081,
      8.04,
      -99.617,
      23.319,
      8.04,
      -99.617,
      23.319,
      8.04,
      -99.617,
      23.319,
      5.59,
      -98.906,
      23.081,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4538",
    "polygon": [
      -97.391,
      22.573,
      5.59,
      -97.391,
      22.573,
      8.04,
      -98.918,
      23.085,
      8.04,
      -98.918,
      23.085,
      8.04,
      -98.918,
      23.085,
      5.59,
      -97.391,
      22.573,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4539",
    "polygon": [
      -95.905,
      22.075,
      5.59,
      -95.905,
      22.075,
      8.04,
      -96.502,
      22.275,
      8.04,
      -96.502,
      22.275,
      8.04,
      -96.502,
      22.275,
      5.59,
      -95.905,
      22.075,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4540",
    "polygon": [
      -93.662,
      21.324,
      5.59,
      -93.662,
      21.324,
      8.04,
      -94.245,
      21.519,
      8.04,
      -94.245,
      21.519,
      8.04,
      -94.245,
      21.519,
      5.59,
      -93.662,
      21.324,
      5.59
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4541",
    "polygon": [
      -94.245,
      21.519,
      5.59,
      -94.245,
      21.519,
      8.04,
      -95.905,
      22.075,
      8.04,
      -95.905,
      22.075,
      8.04,
      -95.905,
      22.075,
      5.59,
      -94.245,
      21.519,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4542",
    "polygon": [
      -91.422,
      20.573,
      5.59,
      -91.422,
      20.573,
      8.04,
      -92.948,
      21.085,
      8.04,
      -92.948,
      21.085,
      8.04,
      -92.948,
      21.085,
      5.59,
      -91.422,
      20.573,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4543",
    "polygon": [
      -90.815,
      20.37,
      5.59,
      -90.815,
      20.37,
      8.04,
      -91.412,
      20.57,
      8.04,
      -91.412,
      20.57,
      8.04,
      -91.412,
      20.57,
      5.59,
      -90.815,
      20.37,
      5.59
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4544",
    "polygon": [
      -88.455,
      19.58,
      5.59,
      -88.455,
      19.58,
      8.04,
      -89.982,
      20.091,
      8.04,
      -89.982,
      20.091,
      8.04,
      -89.982,
      20.091,
      5.59,
      -88.455,
      19.58,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4545",
    "polygon": [
      -87.849,
      19.377,
      5.59,
      -87.849,
      19.377,
      8.04,
      -88.446,
      19.577,
      8.04,
      -88.446,
      19.577,
      8.04,
      -88.446,
      19.577,
      5.59,
      -87.849,
      19.377,
      5.59
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4546",
    "polygon": [
      -79.519,
      16.586,
      5.59,
      -79.519,
      16.586,
      8.04,
      -81.045,
      17.098,
      8.04,
      -81.045,
      17.098,
      8.04,
      -81.045,
      17.098,
      5.59,
      -79.519,
      16.586,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4550",
    "polygon": [
      -78.126,
      16.12,
      5.59,
      -78.126,
      16.12,
      8.04,
      -78.723,
      16.32,
      8.04,
      -78.723,
      16.32,
      8.04,
      -78.723,
      16.32,
      5.59,
      -78.126,
      16.12,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4551",
    "polygon": [
      -75.883,
      15.368,
      5.59,
      -75.883,
      15.368,
      8.04,
      -76.466,
      15.564,
      8.04,
      -76.466,
      15.564,
      8.04,
      -76.466,
      15.564,
      5.59,
      -75.883,
      15.368,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4552",
    "polygon": [
      -76.466,
      15.564,
      5.59,
      -76.466,
      15.564,
      8.04,
      -78.126,
      16.12,
      8.04,
      -78.126,
      16.12,
      8.04,
      -78.126,
      16.12,
      5.59,
      -76.466,
      15.564,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4553",
    "polygon": [
      -75.193,
      15.137,
      5.59,
      -75.193,
      15.137,
      8.04,
      -75.904,
      15.376,
      8.04,
      -75.904,
      15.376,
      8.04,
      -75.904,
      15.376,
      5.59,
      -75.193,
      15.137,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4554",
    "polygon": [
      -73.679,
      14.63,
      5.59,
      -73.679,
      14.63,
      8.04,
      -75.205,
      15.141,
      8.04,
      -75.205,
      15.141,
      8.04,
      -75.205,
      15.141,
      5.59,
      -73.679,
      14.63,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4555",
    "polygon": [
      -72.198,
      14.134,
      5.59,
      -72.198,
      14.134,
      8.04,
      -72.796,
      14.334,
      8.04,
      -72.796,
      14.334,
      8.04,
      -72.796,
      14.334,
      5.59,
      -72.198,
      14.134,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4556",
    "polygon": [
      -69.956,
      13.383,
      5.59,
      -69.956,
      13.383,
      8.04,
      -70.539,
      13.578,
      8.04,
      -70.539,
      13.578,
      8.04,
      -70.539,
      13.578,
      5.59,
      -69.956,
      13.383,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4557",
    "polygon": [
      -70.539,
      13.578,
      5.59,
      -70.539,
      13.578,
      8.04,
      -72.198,
      14.134,
      8.04,
      -72.198,
      14.134,
      8.04,
      -72.198,
      14.134,
      5.59,
      -70.539,
      13.578,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4558",
    "polygon": [
      -69.266,
      13.152,
      5.59,
      -69.266,
      13.152,
      8.04,
      -69.977,
      13.39,
      8.04,
      -69.977,
      13.39,
      8.04,
      -69.977,
      13.39,
      5.59,
      -69.266,
      13.152,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4559",
    "polygon": [
      -67.751,
      12.644,
      5.59,
      -67.751,
      12.644,
      8.04,
      -69.278,
      13.156,
      8.04,
      -69.278,
      13.156,
      8.04,
      -69.278,
      13.156,
      5.59,
      -67.751,
      12.644,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4560",
    "polygon": [
      -66.27,
      12.148,
      5.59,
      -66.27,
      12.148,
      8.04,
      -66.868,
      12.348,
      8.04,
      -66.868,
      12.348,
      8.04,
      -66.868,
      12.348,
      5.59,
      -66.27,
      12.148,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4561",
    "polygon": [
      -64.028,
      11.397,
      5.59,
      -64.028,
      11.397,
      8.04,
      -64.611,
      11.592,
      8.04,
      -64.611,
      11.592,
      8.04,
      -64.611,
      11.592,
      5.59,
      -64.028,
      11.397,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4562",
    "polygon": [
      -64.611,
      11.592,
      5.59,
      -64.611,
      11.592,
      8.04,
      -66.27,
      12.148,
      8.04,
      -66.27,
      12.148,
      8.04,
      -66.27,
      12.148,
      5.59,
      -64.611,
      11.592,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4563",
    "polygon": [
      -63.338,
      11.166,
      5.59,
      -63.338,
      11.166,
      8.04,
      -64.049,
      11.404,
      8.04,
      -64.049,
      11.404,
      8.04,
      -64.049,
      11.404,
      5.59,
      -63.338,
      11.166,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_4564",
    "polygon": [
      -61.823,
      10.658,
      5.59,
      -61.823,
      10.658,
      8.04,
      -63.35,
      11.17,
      8.04,
      -63.35,
      11.17,
      8.04,
      -63.35,
      11.17,
      5.59,
      -61.823,
      10.658,
      5.59
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4565",
    "polygon": [
      -28.257,
      -0.613,
      5.604,
      -28.257,
      -0.613,
      8.054,
      -28.853,
      -0.41,
      8.054,
      -28.853,
      -0.41,
      8.054,
      -28.853,
      -0.41,
      5.604,
      -28.257,
      -0.613,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4566",
    "polygon": [
      -26.017,
      -1.372,
      5.604,
      -26.017,
      -1.372,
      8.054,
      -26.599,
      -1.175,
      8.054,
      -26.599,
      -1.175,
      8.054,
      -26.599,
      -1.175,
      5.604,
      -26.017,
      -1.372,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4567",
    "polygon": [
      -26.599,
      -1.175,
      5.604,
      -26.599,
      -1.175,
      8.054,
      -28.257,
      -0.613,
      8.054,
      -28.257,
      -0.613,
      8.054,
      -28.257,
      -0.613,
      5.604,
      -26.599,
      -1.175,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4568",
    "polygon": [
      -25.328,
      -1.606,
      5.604,
      -25.328,
      -1.606,
      8.054,
      -26.038,
      -1.365,
      8.054,
      -26.038,
      -1.365,
      8.054,
      -26.038,
      -1.365,
      5.604,
      -25.328,
      -1.606,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4569",
    "polygon": [
      -23.815,
      -2.119,
      5.604,
      -23.815,
      -2.119,
      8.054,
      -25.34,
      -1.602,
      8.054,
      -25.34,
      -1.602,
      8.054,
      -25.34,
      -1.602,
      5.604,
      -23.815,
      -2.119,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4570",
    "polygon": [
      -22.337,
      -2.621,
      5.604,
      -22.337,
      -2.621,
      8.054,
      -22.934,
      -2.418,
      8.054,
      -22.934,
      -2.418,
      8.054,
      -22.934,
      -2.418,
      5.604,
      -22.337,
      -2.621,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4571",
    "polygon": [
      -20.097,
      -3.38,
      5.604,
      -20.097,
      -3.38,
      8.054,
      -20.68,
      -3.183,
      8.054,
      -20.68,
      -3.183,
      8.054,
      -20.68,
      -3.183,
      5.604,
      -20.097,
      -3.38,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4572",
    "polygon": [
      -20.68,
      -3.183,
      5.604,
      -20.68,
      -3.183,
      8.054,
      -22.337,
      -2.621,
      8.054,
      -22.337,
      -2.621,
      8.054,
      -22.337,
      -2.621,
      5.604,
      -20.68,
      -3.183,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4573",
    "polygon": [
      -19.408,
      -3.614,
      5.604,
      -19.408,
      -3.614,
      8.054,
      -20.119,
      -3.373,
      8.054,
      -20.119,
      -3.373,
      8.054,
      -20.119,
      -3.373,
      5.604,
      -19.408,
      -3.614,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4574",
    "polygon": [
      -17.896,
      -4.127,
      5.604,
      -17.896,
      -4.127,
      8.054,
      -19.42,
      -3.61,
      8.054,
      -19.42,
      -3.61,
      8.054,
      -19.42,
      -3.61,
      5.604,
      -17.896,
      -4.127,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4575",
    "polygon": [
      -34.175,
      1.395,
      5.604,
      -34.175,
      1.395,
      8.054,
      -34.772,
      1.597,
      8.054,
      -34.772,
      1.597,
      8.054,
      -34.772,
      1.597,
      5.604,
      -34.175,
      1.395,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4576",
    "polygon": [
      -31.936,
      0.635,
      5.604,
      -31.936,
      0.635,
      8.054,
      -32.518,
      0.833,
      8.054,
      -32.518,
      0.833,
      8.054,
      -32.518,
      0.833,
      5.604,
      -31.936,
      0.635,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4577",
    "polygon": [
      -32.518,
      0.833,
      5.604,
      -32.518,
      0.833,
      8.054,
      -34.175,
      1.395,
      8.054,
      -34.175,
      1.395,
      8.054,
      -34.175,
      1.395,
      5.604,
      -32.518,
      0.833,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4578",
    "polygon": [
      -31.247,
      0.402,
      5.604,
      -31.247,
      0.402,
      8.054,
      -31.957,
      0.643,
      8.054,
      -31.957,
      0.643,
      8.054,
      -31.957,
      0.643,
      5.604,
      -31.247,
      0.402,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4579",
    "polygon": [
      -29.734,
      -0.111,
      5.604,
      -29.734,
      -0.111,
      8.054,
      -31.259,
      0.406,
      8.054,
      -31.259,
      0.406,
      8.054,
      -31.259,
      0.406,
      5.604,
      -29.734,
      -0.111,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4580",
    "polygon": [
      -16.415,
      -4.629,
      5.604,
      -16.415,
      -4.629,
      8.054,
      -17.012,
      -4.427,
      8.054,
      -17.012,
      -4.427,
      8.054,
      -17.012,
      -4.427,
      5.604,
      -16.415,
      -4.629,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4581",
    "polygon": [
      -14.758,
      -5.191,
      5.604,
      -14.758,
      -5.191,
      8.054,
      -16.415,
      -4.629,
      8.054,
      -16.415,
      -4.629,
      8.054,
      -16.415,
      -4.629,
      5.604,
      -14.758,
      -5.191,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4582",
    "polygon": [
      -13.484,
      -5.623,
      5.604,
      -13.484,
      -5.623,
      8.054,
      -14.081,
      -5.421,
      8.054,
      -14.081,
      -5.421,
      8.054,
      -14.081,
      -5.421,
      5.604,
      -13.484,
      -5.623,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4583",
    "polygon": [
      -11.827,
      -6.185,
      5.604,
      -11.827,
      -6.185,
      8.054,
      -13.484,
      -5.623,
      8.054,
      -13.484,
      -5.623,
      8.054,
      -13.484,
      -5.623,
      5.604,
      -11.827,
      -6.185,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4584",
    "polygon": [
      -4.533,
      -8.659,
      5.604,
      -4.533,
      -8.659,
      8.054,
      -5.13,
      -8.457,
      8.054,
      -5.13,
      -8.457,
      8.054,
      -5.13,
      -8.457,
      5.604,
      -4.533,
      -8.659,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4585",
    "polygon": [
      -2.294,
      -9.419,
      5.604,
      -2.294,
      -9.419,
      8.054,
      -2.876,
      -9.222,
      8.054,
      -2.876,
      -9.222,
      8.054,
      -2.876,
      -9.222,
      5.604,
      -2.294,
      -9.419,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4586",
    "polygon": [
      -2.876,
      -9.222,
      5.604,
      -2.876,
      -9.222,
      8.054,
      -4.533,
      -8.659,
      8.054,
      -4.533,
      -8.659,
      8.054,
      -4.533,
      -8.659,
      5.604,
      -2.876,
      -9.222,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4587",
    "polygon": [
      -1.605,
      -9.653,
      5.604,
      -1.605,
      -9.653,
      8.054,
      -2.315,
      -9.412,
      8.054,
      -2.315,
      -9.412,
      8.054,
      -2.315,
      -9.412,
      5.604,
      -1.605,
      -9.653,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4588",
    "polygon": [
      -0.092,
      -10.166,
      5.604,
      -0.092,
      -10.166,
      8.054,
      -1.617,
      -9.649,
      8.054,
      -1.617,
      -9.649,
      8.054,
      -1.617,
      -9.649,
      5.604,
      -0.092,
      -10.166,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4589",
    "polygon": [
      1.335,
      -10.65,
      5.604,
      1.335,
      -10.65,
      8.054,
      0.739,
      -10.448,
      8.054,
      0.739,
      -10.448,
      8.054,
      0.739,
      -10.448,
      5.604,
      1.335,
      -10.65,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4590",
    "polygon": [
      3.575,
      -11.41,
      5.604,
      3.575,
      -11.41,
      8.054,
      2.993,
      -11.212,
      8.054,
      2.993,
      -11.212,
      8.054,
      2.993,
      -11.212,
      5.604,
      3.575,
      -11.41,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4591",
    "polygon": [
      2.993,
      -11.212,
      5.604,
      2.993,
      -11.212,
      8.054,
      1.335,
      -10.65,
      8.054,
      1.335,
      -10.65,
      8.054,
      1.335,
      -10.65,
      5.604,
      2.993,
      -11.212,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4592",
    "polygon": [
      4.264,
      -11.643,
      5.604,
      4.264,
      -11.643,
      8.054,
      3.554,
      -11.403,
      8.054,
      3.554,
      -11.403,
      8.054,
      3.554,
      -11.403,
      5.604,
      4.264,
      -11.643,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4593",
    "polygon": [
      5.777,
      -12.157,
      5.604,
      5.777,
      -12.157,
      8.054,
      4.252,
      -11.639,
      8.054,
      4.252,
      -11.639,
      8.054,
      4.252,
      -11.639,
      5.604,
      5.777,
      -12.157,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4594",
    "polygon": [
      7.256,
      -12.658,
      5.604,
      7.256,
      -12.658,
      8.054,
      6.659,
      -12.456,
      8.054,
      6.659,
      -12.456,
      8.054,
      6.659,
      -12.456,
      5.604,
      7.256,
      -12.658,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4595",
    "polygon": [
      9.496,
      -13.418,
      5.604,
      9.496,
      -13.418,
      8.054,
      8.913,
      -13.22,
      8.054,
      8.913,
      -13.22,
      8.054,
      8.913,
      -13.22,
      5.604,
      9.496,
      -13.418,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4596",
    "polygon": [
      8.913,
      -13.22,
      5.604,
      8.913,
      -13.22,
      8.054,
      7.256,
      -12.658,
      8.054,
      7.256,
      -12.658,
      8.054,
      7.256,
      -12.658,
      5.604,
      8.913,
      -13.22,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4597",
    "polygon": [
      10.185,
      -13.652,
      5.604,
      10.185,
      -13.652,
      8.054,
      9.474,
      -13.411,
      8.054,
      9.474,
      -13.411,
      8.054,
      9.474,
      -13.411,
      5.604,
      10.185,
      -13.652,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_1.OG_4598",
    "polygon": [
      11.697,
      -14.165,
      5.604,
      11.697,
      -14.165,
      8.054,
      10.173,
      -13.648,
      8.054,
      10.173,
      -13.648,
      8.054,
      10.173,
      -13.648,
      5.604,
      11.697,
      -14.165,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4599",
    "polygon": [
      39.228,
      -23.235,
      5.604,
      39.228,
      -23.235,
      8.054,
      38.63,
      -23.035,
      8.054,
      38.63,
      -23.035,
      8.054,
      38.63,
      -23.035,
      5.604,
      39.228,
      -23.235,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4600",
    "polygon": [
      41.47,
      -23.986,
      5.604,
      41.47,
      -23.986,
      8.054,
      40.887,
      -23.791,
      8.054,
      40.887,
      -23.791,
      8.054,
      40.887,
      -23.791,
      5.604,
      41.47,
      -23.986,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4601",
    "polygon": [
      40.887,
      -23.791,
      5.604,
      40.887,
      -23.791,
      8.054,
      39.228,
      -23.235,
      8.054,
      39.228,
      -23.235,
      8.054,
      39.228,
      -23.235,
      5.604,
      40.887,
      -23.791,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4602",
    "polygon": [
      42.16,
      -24.217,
      5.604,
      42.16,
      -24.217,
      8.054,
      41.449,
      -23.979,
      8.054,
      41.449,
      -23.979,
      8.054,
      41.449,
      -23.979,
      5.604,
      42.16,
      -24.217,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4603",
    "polygon": [
      43.675,
      -24.725,
      5.604,
      43.675,
      -24.725,
      8.054,
      42.148,
      -24.213,
      8.054,
      42.148,
      -24.213,
      8.054,
      42.148,
      -24.213,
      5.604,
      43.675,
      -24.725,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4604",
    "polygon": [
      45.153,
      -25.22,
      5.604,
      45.153,
      -25.22,
      8.054,
      44.556,
      -25.02,
      8.054,
      44.556,
      -25.02,
      8.054,
      44.556,
      -25.02,
      5.604,
      45.153,
      -25.22,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4605",
    "polygon": [
      47.396,
      -25.971,
      5.604,
      47.396,
      -25.971,
      8.054,
      46.813,
      -25.776,
      8.054,
      46.813,
      -25.776,
      8.054,
      46.813,
      -25.776,
      5.604,
      47.396,
      -25.971,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4606",
    "polygon": [
      46.813,
      -25.776,
      5.604,
      46.813,
      -25.776,
      8.054,
      45.153,
      -25.22,
      8.054,
      45.153,
      -25.22,
      8.054,
      45.153,
      -25.22,
      5.604,
      46.813,
      -25.776,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4607",
    "polygon": [
      48.086,
      -26.202,
      5.604,
      48.086,
      -26.202,
      8.054,
      47.375,
      -25.964,
      8.054,
      47.375,
      -25.964,
      8.054,
      47.375,
      -25.964,
      5.604,
      48.086,
      -26.202,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4608",
    "polygon": [
      49.601,
      -26.71,
      5.604,
      49.601,
      -26.71,
      8.054,
      48.074,
      -26.198,
      8.054,
      48.074,
      -26.198,
      8.054,
      48.074,
      -26.198,
      5.604,
      49.601,
      -26.71,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4609",
    "polygon": [
      51.081,
      -27.205,
      5.604,
      51.081,
      -27.205,
      8.054,
      50.483,
      -27.005,
      8.054,
      50.483,
      -27.005,
      8.054,
      50.483,
      -27.005,
      5.604,
      51.081,
      -27.205,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4610",
    "polygon": [
      53.323,
      -27.957,
      5.604,
      53.323,
      -27.957,
      8.054,
      52.74,
      -27.761,
      8.054,
      52.74,
      -27.761,
      8.054,
      52.74,
      -27.761,
      5.604,
      53.323,
      -27.957,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4611",
    "polygon": [
      52.74,
      -27.761,
      5.604,
      52.74,
      -27.761,
      8.054,
      51.081,
      -27.205,
      8.054,
      51.081,
      -27.205,
      8.054,
      51.081,
      -27.205,
      5.604,
      52.74,
      -27.761,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4612",
    "polygon": [
      54.013,
      -28.188,
      5.604,
      54.013,
      -28.188,
      8.054,
      53.302,
      -27.949,
      8.054,
      53.302,
      -27.949,
      8.054,
      53.302,
      -27.949,
      5.604,
      54.013,
      -28.188,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4613",
    "polygon": [
      55.528,
      -28.695,
      5.604,
      55.528,
      -28.695,
      8.054,
      54.001,
      -28.184,
      8.054,
      54.001,
      -28.184,
      8.054,
      54.001,
      -28.184,
      5.604,
      55.528,
      -28.695,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4614",
    "polygon": [
      56.95,
      -29.172,
      5.604,
      56.95,
      -29.172,
      8.054,
      56.353,
      -28.971,
      8.054,
      56.353,
      -28.971,
      8.054,
      56.353,
      -28.971,
      5.604,
      56.95,
      -29.172,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4615",
    "polygon": [
      59.193,
      -29.923,
      5.604,
      59.193,
      -29.923,
      8.054,
      58.61,
      -29.727,
      8.054,
      58.61,
      -29.727,
      8.054,
      58.61,
      -29.727,
      5.604,
      59.193,
      -29.923,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4616",
    "polygon": [
      58.61,
      -29.727,
      5.604,
      58.61,
      -29.727,
      8.054,
      56.95,
      -29.172,
      8.054,
      56.95,
      -29.172,
      8.054,
      56.95,
      -29.172,
      5.604,
      58.61,
      -29.727,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4617",
    "polygon": [
      59.882,
      -30.154,
      5.604,
      59.882,
      -30.154,
      8.054,
      59.171,
      -29.916,
      8.054,
      59.171,
      -29.916,
      8.054,
      59.171,
      -29.916,
      5.604,
      59.882,
      -30.154,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4618",
    "polygon": [
      61.397,
      -30.661,
      5.604,
      61.397,
      -30.661,
      8.054,
      59.871,
      -30.15,
      8.054,
      59.871,
      -30.15,
      8.054,
      59.871,
      -30.15,
      5.604,
      61.397,
      -30.661,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4619",
    "polygon": [
      68.859,
      -33.161,
      5.604,
      68.859,
      -33.161,
      8.054,
      68.262,
      -32.961,
      8.054,
      68.262,
      -32.961,
      8.054,
      68.262,
      -32.961,
      5.604,
      68.859,
      -33.161,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4620",
    "polygon": [
      70.518,
      -33.717,
      5.604,
      70.518,
      -33.717,
      8.054,
      68.859,
      -33.161,
      8.054,
      68.859,
      -33.161,
      8.054,
      68.859,
      -33.161,
      5.604,
      70.518,
      -33.717,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4623",
    "polygon": [
      71.821,
      -34.153,
      5.604,
      71.821,
      -34.153,
      8.054,
      71.224,
      -33.953,
      8.054,
      71.224,
      -33.953,
      8.054,
      71.224,
      -33.953,
      5.604,
      71.821,
      -34.153,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4624",
    "polygon": [
      73.481,
      -34.709,
      5.604,
      73.481,
      -34.709,
      8.054,
      71.821,
      -34.153,
      8.054,
      71.821,
      -34.153,
      8.054,
      71.821,
      -34.153,
      5.604,
      73.481,
      -34.709,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4625",
    "polygon": [
      74.821,
      -35.158,
      5.604,
      74.821,
      -35.158,
      8.054,
      74.224,
      -34.958,
      8.054,
      74.224,
      -34.958,
      8.054,
      74.224,
      -34.958,
      5.604,
      74.821,
      -35.158,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4626",
    "polygon": [
      77.064,
      -35.909,
      5.604,
      77.064,
      -35.909,
      8.054,
      76.48,
      -35.714,
      8.054,
      76.48,
      -35.714,
      8.054,
      76.48,
      -35.714,
      5.604,
      77.064,
      -35.909,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4627",
    "polygon": [
      76.48,
      -35.714,
      5.604,
      76.48,
      -35.714,
      8.054,
      74.821,
      -35.158,
      8.054,
      74.821,
      -35.158,
      8.054,
      74.821,
      -35.158,
      5.604,
      76.48,
      -35.714,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4628",
    "polygon": [
      77.753,
      -36.14,
      5.604,
      77.753,
      -36.14,
      8.054,
      77.042,
      -35.902,
      8.054,
      77.042,
      -35.902,
      8.054,
      77.042,
      -35.902,
      5.604,
      77.753,
      -36.14,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4629",
    "polygon": [
      79.268,
      -36.648,
      5.604,
      79.268,
      -36.648,
      8.054,
      77.742,
      -36.136,
      8.054,
      77.742,
      -36.136,
      8.054,
      77.742,
      -36.136,
      5.604,
      79.268,
      -36.648,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4630",
    "polygon": [
      80.711,
      -37.131,
      5.604,
      80.711,
      -37.131,
      8.054,
      80.114,
      -36.931,
      8.054,
      80.114,
      -36.931,
      8.054,
      80.114,
      -36.931,
      5.604,
      80.711,
      -37.131,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4631",
    "polygon": [
      82.954,
      -37.882,
      5.604,
      82.954,
      -37.882,
      8.054,
      82.371,
      -37.687,
      8.054,
      82.371,
      -37.687,
      8.054,
      82.371,
      -37.687,
      5.604,
      82.954,
      -37.882,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4632",
    "polygon": [
      82.371,
      -37.687,
      5.604,
      82.371,
      -37.687,
      8.054,
      80.711,
      -37.131,
      8.054,
      80.711,
      -37.131,
      8.054,
      80.711,
      -37.131,
      5.604,
      82.371,
      -37.687,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4633",
    "polygon": [
      83.644,
      -38.114,
      5.604,
      83.644,
      -38.114,
      8.054,
      82.933,
      -37.875,
      8.054,
      82.933,
      -37.875,
      8.054,
      82.933,
      -37.875,
      5.604,
      83.644,
      -38.114,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_4634",
    "polygon": [
      85.158,
      -38.621,
      5.604,
      85.158,
      -38.621,
      8.054,
      83.632,
      -38.11,
      8.054,
      83.632,
      -38.11,
      8.054,
      83.632,
      -38.11,
      5.604,
      85.158,
      -38.621,
      5.604
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4635",
    "polygon": [
      39.227,
      -23.238,
      9.354,
      39.227,
      -23.238,
      11.804,
      38.629,
      -23.038,
      11.804,
      38.629,
      -23.038,
      11.804,
      38.629,
      -23.038,
      9.354,
      39.227,
      -23.238,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4636",
    "polygon": [
      41.469,
      -23.989,
      9.354,
      41.469,
      -23.989,
      11.804,
      40.886,
      -23.794,
      11.804,
      40.886,
      -23.794,
      11.804,
      40.886,
      -23.794,
      9.354,
      41.469,
      -23.989,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4637",
    "polygon": [
      40.886,
      -23.794,
      9.354,
      40.886,
      -23.794,
      11.804,
      39.227,
      -23.238,
      11.804,
      39.227,
      -23.238,
      11.804,
      39.227,
      -23.238,
      9.354,
      40.886,
      -23.794,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4638",
    "polygon": [
      42.159,
      -24.22,
      9.354,
      42.159,
      -24.22,
      11.804,
      41.448,
      -23.982,
      11.804,
      41.448,
      -23.982,
      11.804,
      41.448,
      -23.982,
      9.354,
      42.159,
      -24.22,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4639",
    "polygon": [
      43.674,
      -24.727,
      9.354,
      43.674,
      -24.727,
      11.804,
      42.147,
      -24.216,
      11.804,
      42.147,
      -24.216,
      11.804,
      42.147,
      -24.216,
      9.354,
      43.674,
      -24.727,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4640",
    "polygon": [
      45.152,
      -25.223,
      9.354,
      45.152,
      -25.223,
      11.804,
      44.555,
      -25.023,
      11.804,
      44.555,
      -25.023,
      11.804,
      44.555,
      -25.023,
      9.354,
      45.152,
      -25.223,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4641",
    "polygon": [
      47.395,
      -25.974,
      9.354,
      47.395,
      -25.974,
      11.804,
      46.812,
      -25.779,
      11.804,
      46.812,
      -25.779,
      11.804,
      46.812,
      -25.779,
      9.354,
      47.395,
      -25.974,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4642",
    "polygon": [
      46.812,
      -25.779,
      9.354,
      46.812,
      -25.779,
      11.804,
      45.152,
      -25.223,
      11.804,
      45.152,
      -25.223,
      11.804,
      45.152,
      -25.223,
      9.354,
      46.812,
      -25.779,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4643",
    "polygon": [
      48.085,
      -26.205,
      9.354,
      48.085,
      -26.205,
      11.804,
      47.374,
      -25.967,
      11.804,
      47.374,
      -25.967,
      11.804,
      47.374,
      -25.967,
      9.354,
      48.085,
      -26.205,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4644",
    "polygon": [
      49.6,
      -26.713,
      9.354,
      49.6,
      -26.713,
      11.804,
      48.073,
      -26.201,
      11.804,
      48.073,
      -26.201,
      11.804,
      48.073,
      -26.201,
      9.354,
      49.6,
      -26.713,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4645",
    "polygon": [
      51.08,
      -27.208,
      9.354,
      51.08,
      -27.208,
      11.804,
      50.482,
      -27.008,
      11.804,
      50.482,
      -27.008,
      11.804,
      50.482,
      -27.008,
      9.354,
      51.08,
      -27.208,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4646",
    "polygon": [
      53.322,
      -27.96,
      9.354,
      53.322,
      -27.96,
      11.804,
      52.739,
      -27.764,
      11.804,
      52.739,
      -27.764,
      11.804,
      52.739,
      -27.764,
      9.354,
      53.322,
      -27.96,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4647",
    "polygon": [
      52.739,
      -27.764,
      9.354,
      52.739,
      -27.764,
      11.804,
      51.08,
      -27.208,
      11.804,
      51.08,
      -27.208,
      11.804,
      51.08,
      -27.208,
      9.354,
      52.739,
      -27.764,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4648",
    "polygon": [
      54.012,
      -28.191,
      9.354,
      54.012,
      -28.191,
      11.804,
      53.301,
      -27.952,
      11.804,
      53.301,
      -27.952,
      11.804,
      53.301,
      -27.952,
      9.354,
      54.012,
      -28.191,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4649",
    "polygon": [
      55.527,
      -28.698,
      9.354,
      55.527,
      -28.698,
      11.804,
      54.0,
      -28.187,
      11.804,
      54.0,
      -28.187,
      11.804,
      54.0,
      -28.187,
      9.354,
      55.527,
      -28.698,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4650",
    "polygon": [
      56.949,
      -29.175,
      9.354,
      56.949,
      -29.175,
      11.804,
      56.352,
      -28.974,
      11.804,
      56.352,
      -28.974,
      11.804,
      56.352,
      -28.974,
      9.354,
      56.949,
      -29.175,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4651",
    "polygon": [
      59.192,
      -29.926,
      9.354,
      59.192,
      -29.926,
      11.804,
      58.609,
      -29.73,
      11.804,
      58.609,
      -29.73,
      11.804,
      58.609,
      -29.73,
      9.354,
      59.192,
      -29.926,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4652",
    "polygon": [
      58.609,
      -29.73,
      9.354,
      58.609,
      -29.73,
      11.804,
      56.949,
      -29.175,
      11.804,
      56.949,
      -29.175,
      11.804,
      56.949,
      -29.175,
      9.354,
      58.609,
      -29.73,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4653",
    "polygon": [
      59.882,
      -30.157,
      9.354,
      59.882,
      -30.157,
      11.804,
      59.17,
      -29.919,
      11.804,
      59.17,
      -29.919,
      11.804,
      59.17,
      -29.919,
      9.354,
      59.882,
      -30.157,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4654",
    "polygon": [
      61.396,
      -30.664,
      9.354,
      61.396,
      -30.664,
      11.804,
      59.87,
      -30.153,
      11.804,
      59.87,
      -30.153,
      11.804,
      59.87,
      -30.153,
      9.354,
      61.396,
      -30.664,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4655",
    "polygon": [
      68.858,
      -33.164,
      9.354,
      68.858,
      -33.164,
      11.804,
      68.261,
      -32.964,
      11.804,
      68.261,
      -32.964,
      11.804,
      68.261,
      -32.964,
      9.354,
      68.858,
      -33.164,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4656",
    "polygon": [
      70.517,
      -33.72,
      9.354,
      70.517,
      -33.72,
      11.804,
      68.858,
      -33.164,
      11.804,
      68.858,
      -33.164,
      11.804,
      68.858,
      -33.164,
      9.354,
      70.517,
      -33.72,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4657",
    "polygon": [
      71.82,
      -34.156,
      9.354,
      71.82,
      -34.156,
      11.804,
      71.223,
      -33.956,
      11.804,
      71.223,
      -33.956,
      11.804,
      71.223,
      -33.956,
      9.354,
      71.82,
      -34.156,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4658",
    "polygon": [
      73.48,
      -34.712,
      9.354,
      73.48,
      -34.712,
      11.804,
      71.82,
      -34.156,
      11.804,
      71.82,
      -34.156,
      11.804,
      71.82,
      -34.156,
      9.354,
      73.48,
      -34.712,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4659",
    "polygon": [
      74.82,
      -35.161,
      9.354,
      74.82,
      -35.161,
      11.804,
      74.223,
      -34.961,
      11.804,
      74.223,
      -34.961,
      11.804,
      74.223,
      -34.961,
      9.354,
      74.82,
      -35.161,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4660",
    "polygon": [
      77.063,
      -35.912,
      9.354,
      77.063,
      -35.912,
      11.804,
      76.479,
      -35.717,
      11.804,
      76.479,
      -35.717,
      11.804,
      76.479,
      -35.717,
      9.354,
      77.063,
      -35.912,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4661",
    "polygon": [
      76.48,
      -35.717,
      9.354,
      76.48,
      -35.717,
      11.804,
      74.82,
      -35.161,
      11.804,
      74.82,
      -35.161,
      11.804,
      74.82,
      -35.161,
      9.354,
      76.48,
      -35.717,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4662",
    "polygon": [
      77.752,
      -36.143,
      9.354,
      77.752,
      -36.143,
      11.804,
      77.041,
      -35.905,
      11.804,
      77.041,
      -35.905,
      11.804,
      77.041,
      -35.905,
      9.354,
      77.752,
      -36.143,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4663",
    "polygon": [
      79.267,
      -36.651,
      9.354,
      79.267,
      -36.651,
      11.804,
      77.741,
      -36.139,
      11.804,
      77.741,
      -36.139,
      11.804,
      77.741,
      -36.139,
      9.354,
      79.267,
      -36.651,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4664",
    "polygon": [
      80.71,
      -37.134,
      9.354,
      80.71,
      -37.134,
      11.804,
      80.113,
      -36.934,
      11.804,
      80.113,
      -36.934,
      11.804,
      80.113,
      -36.934,
      9.354,
      80.71,
      -37.134,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4665",
    "polygon": [
      82.953,
      -37.885,
      9.354,
      82.953,
      -37.885,
      11.804,
      82.37,
      -37.69,
      11.804,
      82.37,
      -37.69,
      11.804,
      82.37,
      -37.69,
      9.354,
      82.953,
      -37.885,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4666",
    "polygon": [
      82.37,
      -37.69,
      9.354,
      82.37,
      -37.69,
      11.804,
      80.71,
      -37.134,
      11.804,
      80.71,
      -37.134,
      11.804,
      80.71,
      -37.134,
      9.354,
      82.37,
      -37.69,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4667",
    "polygon": [
      83.643,
      -38.116,
      9.354,
      83.643,
      -38.116,
      11.804,
      82.932,
      -37.878,
      11.804,
      82.932,
      -37.878,
      11.804,
      82.932,
      -37.878,
      9.354,
      83.643,
      -38.116,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_4668",
    "polygon": [
      85.158,
      -38.624,
      9.354,
      85.158,
      -38.624,
      11.804,
      83.631,
      -38.113,
      11.804,
      83.631,
      -38.113,
      11.804,
      83.631,
      -38.113,
      9.354,
      85.158,
      -38.624,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4669",
    "polygon": [
      39.227,
      -23.238,
      13.104,
      39.227,
      -23.238,
      15.554,
      38.629,
      -23.038,
      15.554,
      38.629,
      -23.038,
      15.554,
      38.629,
      -23.038,
      13.104,
      39.227,
      -23.238,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4670",
    "polygon": [
      41.469,
      -23.99,
      13.104,
      41.469,
      -23.99,
      15.554,
      40.886,
      -23.794,
      15.554,
      40.886,
      -23.794,
      15.554,
      40.886,
      -23.794,
      13.104,
      41.469,
      -23.99,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4671",
    "polygon": [
      40.886,
      -23.794,
      13.104,
      40.886,
      -23.794,
      15.554,
      39.227,
      -23.238,
      15.554,
      39.227,
      -23.238,
      15.554,
      39.227,
      -23.238,
      13.104,
      40.886,
      -23.794,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4672",
    "polygon": [
      42.159,
      -24.221,
      13.104,
      42.159,
      -24.221,
      15.554,
      41.448,
      -23.983,
      15.554,
      41.448,
      -23.983,
      15.554,
      41.448,
      -23.983,
      13.104,
      42.159,
      -24.221,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4673",
    "polygon": [
      43.673,
      -24.729,
      13.104,
      43.673,
      -24.729,
      15.554,
      42.147,
      -24.217,
      15.554,
      42.147,
      -24.217,
      15.554,
      42.147,
      -24.217,
      13.104,
      43.673,
      -24.729,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4674",
    "polygon": [
      45.152,
      -25.224,
      13.104,
      45.152,
      -25.224,
      15.554,
      44.555,
      -25.024,
      15.554,
      44.555,
      -25.024,
      15.554,
      44.555,
      -25.024,
      13.104,
      45.152,
      -25.224,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4675",
    "polygon": [
      47.394,
      -25.976,
      13.104,
      47.394,
      -25.976,
      15.554,
      46.811,
      -25.781,
      15.554,
      46.811,
      -25.781,
      15.554,
      46.811,
      -25.781,
      13.104,
      47.394,
      -25.976,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4676",
    "polygon": [
      46.811,
      -25.781,
      13.104,
      46.811,
      -25.781,
      15.554,
      45.152,
      -25.224,
      15.554,
      45.152,
      -25.224,
      15.554,
      45.152,
      -25.224,
      13.104,
      46.811,
      -25.781,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4677",
    "polygon": [
      48.084,
      -26.207,
      13.104,
      48.084,
      -26.207,
      15.554,
      47.373,
      -25.969,
      15.554,
      47.373,
      -25.969,
      15.554,
      47.373,
      -25.969,
      13.104,
      48.084,
      -26.207,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4678",
    "polygon": [
      49.599,
      -26.715,
      13.104,
      49.599,
      -26.715,
      15.554,
      48.072,
      -26.203,
      15.554,
      48.072,
      -26.203,
      15.554,
      48.072,
      -26.203,
      13.104,
      49.599,
      -26.715,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4679",
    "polygon": [
      51.079,
      -27.211,
      13.104,
      51.079,
      -27.211,
      15.554,
      50.482,
      -27.011,
      15.554,
      50.482,
      -27.011,
      15.554,
      50.482,
      -27.011,
      13.104,
      51.079,
      -27.211,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4680",
    "polygon": [
      53.321,
      -27.963,
      13.104,
      53.321,
      -27.963,
      15.554,
      52.738,
      -27.767,
      15.554,
      52.738,
      -27.767,
      15.554,
      52.738,
      -27.767,
      13.104,
      53.321,
      -27.963,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4681",
    "polygon": [
      52.738,
      -27.767,
      13.104,
      52.738,
      -27.767,
      15.554,
      51.079,
      -27.211,
      15.554,
      51.079,
      -27.211,
      15.554,
      51.079,
      -27.211,
      13.104,
      52.738,
      -27.767,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4682",
    "polygon": [
      54.011,
      -28.194,
      13.104,
      54.011,
      -28.194,
      15.554,
      53.3,
      -27.956,
      15.554,
      53.3,
      -27.956,
      15.554,
      53.3,
      -27.956,
      13.104,
      54.011,
      -28.194,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4683",
    "polygon": [
      55.526,
      -28.702,
      13.104,
      55.526,
      -28.702,
      15.554,
      53.999,
      -28.19,
      15.554,
      53.999,
      -28.19,
      15.554,
      53.999,
      -28.19,
      13.104,
      55.526,
      -28.702,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4684",
    "polygon": [
      56.948,
      -29.178,
      13.104,
      56.948,
      -29.178,
      15.554,
      56.351,
      -28.978,
      15.554,
      56.351,
      -28.978,
      15.554,
      56.351,
      -28.978,
      13.104,
      56.948,
      -29.178,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4685",
    "polygon": [
      59.19,
      -29.93,
      13.104,
      59.19,
      -29.93,
      15.554,
      58.607,
      -29.735,
      15.554,
      58.607,
      -29.735,
      15.554,
      58.607,
      -29.735,
      13.104,
      59.19,
      -29.93,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4686",
    "polygon": [
      58.607,
      -29.735,
      13.104,
      58.607,
      -29.735,
      15.554,
      56.948,
      -29.178,
      15.554,
      56.948,
      -29.178,
      15.554,
      56.948,
      -29.178,
      13.104,
      58.607,
      -29.735,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4687",
    "polygon": [
      59.88,
      -30.161,
      13.104,
      59.88,
      -30.161,
      15.554,
      59.169,
      -29.923,
      15.554,
      59.169,
      -29.923,
      15.554,
      59.169,
      -29.923,
      13.104,
      59.88,
      -30.161,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4688",
    "polygon": [
      61.395,
      -30.669,
      13.104,
      61.395,
      -30.669,
      15.554,
      59.868,
      -30.157,
      15.554,
      59.868,
      -30.157,
      15.554,
      59.868,
      -30.157,
      13.104,
      61.395,
      -30.669,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4689",
    "polygon": [
      68.856,
      -33.17,
      13.104,
      68.856,
      -33.17,
      15.554,
      68.259,
      -32.97,
      15.554,
      68.259,
      -32.97,
      15.554,
      68.259,
      -32.97,
      13.104,
      68.856,
      -33.17,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4690",
    "polygon": [
      70.515,
      -33.726,
      13.104,
      70.515,
      -33.726,
      15.554,
      68.856,
      -33.17,
      15.554,
      68.856,
      -33.17,
      15.554,
      68.856,
      -33.17,
      13.104,
      70.515,
      -33.726,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4691",
    "polygon": [
      71.818,
      -34.163,
      13.104,
      71.818,
      -34.163,
      15.554,
      71.221,
      -33.963,
      15.554,
      71.221,
      -33.963,
      15.554,
      71.221,
      -33.963,
      13.104,
      71.818,
      -34.163,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4692",
    "polygon": [
      73.477,
      -34.719,
      13.104,
      73.477,
      -34.719,
      15.554,
      71.818,
      -34.163,
      15.554,
      71.818,
      -34.163,
      15.554,
      71.818,
      -34.163,
      13.104,
      73.477,
      -34.719,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4693",
    "polygon": [
      74.818,
      -35.168,
      13.104,
      74.818,
      -35.168,
      15.554,
      74.22,
      -34.968,
      15.554,
      74.22,
      -34.968,
      15.554,
      74.22,
      -34.968,
      13.104,
      74.818,
      -35.168,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4694",
    "polygon": [
      77.06,
      -35.92,
      13.104,
      77.06,
      -35.92,
      15.554,
      76.477,
      -35.724,
      15.554,
      76.477,
      -35.724,
      15.554,
      76.477,
      -35.724,
      13.104,
      77.06,
      -35.92,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4695",
    "polygon": [
      76.477,
      -35.724,
      13.104,
      76.477,
      -35.724,
      15.554,
      74.818,
      -35.168,
      15.554,
      74.818,
      -35.168,
      15.554,
      74.818,
      -35.168,
      13.104,
      76.477,
      -35.724,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4696",
    "polygon": [
      77.75,
      -36.151,
      13.104,
      77.75,
      -36.151,
      15.554,
      77.039,
      -35.913,
      15.554,
      77.039,
      -35.913,
      15.554,
      77.039,
      -35.913,
      13.104,
      77.75,
      -36.151,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4697",
    "polygon": [
      79.265,
      -36.659,
      13.104,
      79.265,
      -36.659,
      15.554,
      77.738,
      -36.147,
      15.554,
      77.738,
      -36.147,
      15.554,
      77.738,
      -36.147,
      13.104,
      79.265,
      -36.659,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4698",
    "polygon": [
      80.708,
      -37.143,
      13.104,
      80.708,
      -37.143,
      15.554,
      80.11,
      -36.942,
      15.554,
      80.11,
      -36.942,
      15.554,
      80.11,
      -36.942,
      13.104,
      80.708,
      -37.143,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4699",
    "polygon": [
      82.95,
      -37.894,
      13.104,
      82.95,
      -37.894,
      15.554,
      82.367,
      -37.699,
      15.554,
      82.367,
      -37.699,
      15.554,
      82.367,
      -37.699,
      13.104,
      82.95,
      -37.894,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4700",
    "polygon": [
      82.367,
      -37.699,
      13.104,
      82.367,
      -37.699,
      15.554,
      80.708,
      -37.143,
      15.554,
      80.708,
      -37.143,
      15.554,
      80.708,
      -37.143,
      13.104,
      82.367,
      -37.699,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4701",
    "polygon": [
      83.64,
      -38.125,
      13.104,
      83.64,
      -38.125,
      15.554,
      82.929,
      -37.887,
      15.554,
      82.929,
      -37.887,
      15.554,
      82.929,
      -37.887,
      13.104,
      83.64,
      -38.125,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_4702",
    "polygon": [
      85.155,
      -38.633,
      13.104,
      85.155,
      -38.633,
      15.554,
      83.628,
      -38.121,
      15.554,
      83.628,
      -38.121,
      15.554,
      83.628,
      -38.121,
      13.104,
      85.155,
      -38.633,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4703",
    "polygon": [
      -107.75,
      26.04,
      9.34,
      -107.75,
      26.04,
      11.79,
      -108.347,
      26.24,
      11.79,
      -108.347,
      26.24,
      11.79,
      -108.347,
      26.24,
      9.34,
      -107.75,
      26.04,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4704",
    "polygon": [
      -105.507,
      25.289,
      9.34,
      -105.507,
      25.289,
      11.79,
      -106.09,
      25.484,
      11.79,
      -106.09,
      25.484,
      11.79,
      -106.09,
      25.484,
      9.34,
      -105.507,
      25.289,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4705",
    "polygon": [
      -106.09,
      25.484,
      9.34,
      -106.09,
      25.484,
      11.79,
      -107.75,
      26.04,
      11.79,
      -107.75,
      26.04,
      11.79,
      -107.75,
      26.04,
      9.34,
      -106.09,
      25.484,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4706",
    "polygon": [
      -104.817,
      25.058,
      9.34,
      -104.817,
      25.058,
      11.79,
      -105.529,
      25.296,
      11.79,
      -105.529,
      25.296,
      11.79,
      -105.529,
      25.296,
      9.34,
      -104.817,
      25.058,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4707",
    "polygon": [
      -103.303,
      24.55,
      9.34,
      -103.303,
      24.55,
      11.79,
      -104.829,
      25.062,
      11.79,
      -104.829,
      25.062,
      11.79,
      -104.829,
      25.062,
      9.34,
      -103.303,
      24.55,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4708",
    "polygon": [
      -101.84,
      24.06,
      9.34,
      -101.84,
      24.06,
      11.79,
      -102.437,
      24.26,
      11.79,
      -102.437,
      24.26,
      11.79,
      -102.437,
      24.26,
      9.34,
      -101.84,
      24.06,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4709",
    "polygon": [
      -99.597,
      23.309,
      9.34,
      -99.597,
      23.309,
      11.79,
      -100.18,
      23.504,
      11.79,
      -100.18,
      23.504,
      11.79,
      -100.18,
      23.504,
      9.34,
      -99.597,
      23.309,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4710",
    "polygon": [
      -100.18,
      23.504,
      9.34,
      -100.18,
      23.504,
      11.79,
      -101.84,
      24.06,
      11.79,
      -101.84,
      24.06,
      11.79,
      -101.84,
      24.06,
      9.34,
      -100.18,
      23.504,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4711",
    "polygon": [
      -98.907,
      23.078,
      9.34,
      -98.907,
      23.078,
      11.79,
      -99.618,
      23.316,
      11.79,
      -99.618,
      23.316,
      11.79,
      -99.618,
      23.316,
      9.34,
      -98.907,
      23.078,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4712",
    "polygon": [
      -97.392,
      22.57,
      9.34,
      -97.392,
      22.57,
      11.79,
      -98.919,
      23.082,
      11.79,
      -98.919,
      23.082,
      11.79,
      -98.919,
      23.082,
      9.34,
      -97.392,
      22.57,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4713",
    "polygon": [
      -95.906,
      22.072,
      9.34,
      -95.906,
      22.072,
      11.79,
      -96.503,
      22.272,
      11.79,
      -96.503,
      22.272,
      11.79,
      -96.503,
      22.272,
      9.34,
      -95.906,
      22.072,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4714",
    "polygon": [
      -93.663,
      21.321,
      9.34,
      -93.663,
      21.321,
      11.79,
      -94.246,
      21.516,
      11.79,
      -94.246,
      21.516,
      11.79,
      -94.246,
      21.516,
      9.34,
      -93.663,
      21.321,
      9.34
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4715",
    "polygon": [
      -94.246,
      21.516,
      9.34,
      -94.246,
      21.516,
      11.79,
      -95.906,
      22.072,
      11.79,
      -95.906,
      22.072,
      11.79,
      -95.906,
      22.072,
      9.34,
      -94.246,
      21.516,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4716",
    "polygon": [
      -91.422,
      20.571,
      9.34,
      -91.422,
      20.571,
      11.79,
      -92.949,
      21.082,
      11.79,
      -92.949,
      21.082,
      11.79,
      -92.949,
      21.082,
      9.34,
      -91.422,
      20.571,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4717",
    "polygon": [
      -90.816,
      20.367,
      9.34,
      -90.816,
      20.367,
      11.79,
      -91.413,
      20.567,
      11.79,
      -91.413,
      20.567,
      11.79,
      -91.413,
      20.567,
      9.34,
      -90.816,
      20.367,
      9.34
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4718",
    "polygon": [
      -88.456,
      19.577,
      9.34,
      -88.456,
      19.577,
      11.79,
      -89.983,
      20.088,
      11.79,
      -89.983,
      20.088,
      11.79,
      -89.983,
      20.088,
      9.34,
      -88.456,
      19.577,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4719",
    "polygon": [
      -87.85,
      19.374,
      9.34,
      -87.85,
      19.374,
      11.79,
      -88.447,
      19.574,
      11.79,
      -88.447,
      19.574,
      11.79,
      -88.447,
      19.574,
      9.34,
      -87.85,
      19.374,
      9.34
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4720",
    "polygon": [
      -79.52,
      16.583,
      9.34,
      -79.52,
      16.583,
      11.79,
      -81.046,
      17.095,
      11.79,
      -81.046,
      17.095,
      11.79,
      -81.046,
      17.095,
      9.34,
      -79.52,
      16.583,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4721",
    "polygon": [
      -78.127,
      16.117,
      9.34,
      -78.127,
      16.117,
      11.79,
      -78.724,
      16.317,
      11.79,
      -78.724,
      16.317,
      11.79,
      -78.724,
      16.317,
      9.34,
      -78.127,
      16.117,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4722",
    "polygon": [
      -75.884,
      15.365,
      9.34,
      -75.884,
      15.365,
      11.79,
      -76.467,
      15.561,
      11.79,
      -76.467,
      15.561,
      11.79,
      -76.467,
      15.561,
      9.34,
      -75.884,
      15.365,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4723",
    "polygon": [
      -76.467,
      15.561,
      9.34,
      -76.467,
      15.561,
      11.79,
      -78.127,
      16.117,
      11.79,
      -78.127,
      16.117,
      11.79,
      -78.127,
      16.117,
      9.34,
      -76.467,
      15.561,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4724",
    "polygon": [
      -75.194,
      15.134,
      9.34,
      -75.194,
      15.134,
      11.79,
      -75.905,
      15.373,
      11.79,
      -75.905,
      15.373,
      11.79,
      -75.905,
      15.373,
      9.34,
      -75.194,
      15.134,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4725",
    "polygon": [
      -73.68,
      14.627,
      9.34,
      -73.68,
      14.627,
      11.79,
      -75.206,
      15.138,
      11.79,
      -75.206,
      15.138,
      11.79,
      -75.206,
      15.138,
      9.34,
      -73.68,
      14.627,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4726",
    "polygon": [
      -72.199,
      14.131,
      9.34,
      -72.199,
      14.131,
      11.79,
      -72.797,
      14.331,
      11.79,
      -72.797,
      14.331,
      11.79,
      -72.797,
      14.331,
      9.34,
      -72.199,
      14.131,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4727",
    "polygon": [
      -69.957,
      13.38,
      9.34,
      -69.957,
      13.38,
      11.79,
      -70.54,
      13.575,
      11.79,
      -70.54,
      13.575,
      11.79,
      -70.54,
      13.575,
      9.34,
      -69.957,
      13.38,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4728",
    "polygon": [
      -70.54,
      13.575,
      9.34,
      -70.54,
      13.575,
      11.79,
      -72.199,
      14.131,
      11.79,
      -72.199,
      14.131,
      11.79,
      -72.199,
      14.131,
      9.34,
      -70.54,
      13.575,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4729",
    "polygon": [
      -69.267,
      13.149,
      9.34,
      -69.267,
      13.149,
      11.79,
      -69.978,
      13.387,
      11.79,
      -69.978,
      13.387,
      11.79,
      -69.978,
      13.387,
      9.34,
      -69.267,
      13.149,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4730",
    "polygon": [
      -67.752,
      12.641,
      9.34,
      -67.752,
      12.641,
      11.79,
      -69.279,
      13.153,
      11.79,
      -69.279,
      13.153,
      11.79,
      -69.279,
      13.153,
      9.34,
      -67.752,
      12.641,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4731",
    "polygon": [
      -66.271,
      12.145,
      9.34,
      -66.271,
      12.145,
      11.79,
      -66.869,
      12.345,
      11.79,
      -66.869,
      12.345,
      11.79,
      -66.869,
      12.345,
      9.34,
      -66.271,
      12.145,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4732",
    "polygon": [
      -64.029,
      11.394,
      9.34,
      -64.029,
      11.394,
      11.79,
      -64.612,
      11.589,
      11.79,
      -64.612,
      11.589,
      11.79,
      -64.612,
      11.589,
      9.34,
      -64.029,
      11.394,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4733",
    "polygon": [
      -64.612,
      11.589,
      9.34,
      -64.612,
      11.589,
      11.79,
      -66.271,
      12.145,
      11.79,
      -66.271,
      12.145,
      11.79,
      -66.271,
      12.145,
      9.34,
      -64.612,
      11.589,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4734",
    "polygon": [
      -63.339,
      11.163,
      9.34,
      -63.339,
      11.163,
      11.79,
      -64.05,
      11.401,
      11.79,
      -64.05,
      11.401,
      11.79,
      -64.05,
      11.401,
      9.34,
      -63.339,
      11.163,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_4735",
    "polygon": [
      -61.824,
      10.655,
      9.34,
      -61.824,
      10.655,
      11.79,
      -63.351,
      11.167,
      11.79,
      -63.351,
      11.167,
      11.79,
      -63.351,
      11.167,
      9.34,
      -61.824,
      10.655,
      9.34
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4736",
    "polygon": [
      -28.257,
      -0.613,
      9.354,
      -28.257,
      -0.613,
      11.804,
      -28.853,
      -0.41,
      11.804,
      -28.853,
      -0.41,
      11.804,
      -28.853,
      -0.41,
      9.354,
      -28.257,
      -0.613,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4737",
    "polygon": [
      -26.017,
      -1.372,
      9.354,
      -26.017,
      -1.372,
      11.804,
      -26.599,
      -1.175,
      11.804,
      -26.599,
      -1.175,
      11.804,
      -26.599,
      -1.175,
      9.354,
      -26.017,
      -1.372,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4738",
    "polygon": [
      -26.599,
      -1.175,
      9.354,
      -26.599,
      -1.175,
      11.804,
      -28.257,
      -0.613,
      11.804,
      -28.257,
      -0.613,
      11.804,
      -28.257,
      -0.613,
      9.354,
      -26.599,
      -1.175,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4739",
    "polygon": [
      -25.328,
      -1.606,
      9.354,
      -25.328,
      -1.606,
      11.804,
      -26.038,
      -1.365,
      11.804,
      -26.038,
      -1.365,
      11.804,
      -26.038,
      -1.365,
      9.354,
      -25.328,
      -1.606,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4740",
    "polygon": [
      -23.815,
      -2.119,
      9.354,
      -23.815,
      -2.119,
      11.804,
      -25.34,
      -1.602,
      11.804,
      -25.34,
      -1.602,
      11.804,
      -25.34,
      -1.602,
      9.354,
      -23.815,
      -2.119,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4741",
    "polygon": [
      -22.337,
      -2.621,
      9.354,
      -22.337,
      -2.621,
      11.804,
      -22.934,
      -2.418,
      11.804,
      -22.934,
      -2.418,
      11.804,
      -22.934,
      -2.418,
      9.354,
      -22.337,
      -2.621,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4742",
    "polygon": [
      -20.097,
      -3.38,
      9.354,
      -20.097,
      -3.38,
      11.804,
      -20.68,
      -3.183,
      11.804,
      -20.68,
      -3.183,
      11.804,
      -20.68,
      -3.183,
      9.354,
      -20.097,
      -3.38,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4743",
    "polygon": [
      -20.68,
      -3.183,
      9.354,
      -20.68,
      -3.183,
      11.804,
      -22.337,
      -2.621,
      11.804,
      -22.337,
      -2.621,
      11.804,
      -22.337,
      -2.621,
      9.354,
      -20.68,
      -3.183,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4744",
    "polygon": [
      -19.408,
      -3.614,
      9.354,
      -19.408,
      -3.614,
      11.804,
      -20.119,
      -3.373,
      11.804,
      -20.119,
      -3.373,
      11.804,
      -20.119,
      -3.373,
      9.354,
      -19.408,
      -3.614,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4745",
    "polygon": [
      -17.896,
      -4.127,
      9.354,
      -17.896,
      -4.127,
      11.804,
      -19.42,
      -3.61,
      11.804,
      -19.42,
      -3.61,
      11.804,
      -19.42,
      -3.61,
      9.354,
      -17.896,
      -4.127,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4746",
    "polygon": [
      -34.175,
      1.395,
      9.354,
      -34.175,
      1.395,
      11.804,
      -34.772,
      1.597,
      11.804,
      -34.772,
      1.597,
      11.804,
      -34.772,
      1.597,
      9.354,
      -34.175,
      1.395,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4747",
    "polygon": [
      -31.936,
      0.635,
      9.354,
      -31.936,
      0.635,
      11.804,
      -32.518,
      0.833,
      11.804,
      -32.518,
      0.833,
      11.804,
      -32.518,
      0.833,
      9.354,
      -31.936,
      0.635,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4748",
    "polygon": [
      -32.518,
      0.833,
      9.354,
      -32.518,
      0.833,
      11.804,
      -34.175,
      1.395,
      11.804,
      -34.175,
      1.395,
      11.804,
      -34.175,
      1.395,
      9.354,
      -32.518,
      0.833,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4749",
    "polygon": [
      -31.247,
      0.402,
      9.354,
      -31.247,
      0.402,
      11.804,
      -31.957,
      0.643,
      11.804,
      -31.957,
      0.643,
      11.804,
      -31.957,
      0.643,
      9.354,
      -31.247,
      0.402,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4750",
    "polygon": [
      -29.734,
      -0.111,
      9.354,
      -29.734,
      -0.111,
      11.804,
      -31.259,
      0.406,
      11.804,
      -31.259,
      0.406,
      11.804,
      -31.259,
      0.406,
      9.354,
      -29.734,
      -0.111,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4751",
    "polygon": [
      -16.415,
      -4.629,
      9.354,
      -16.415,
      -4.629,
      11.804,
      -17.012,
      -4.427,
      11.804,
      -17.012,
      -4.427,
      11.804,
      -17.012,
      -4.427,
      9.354,
      -16.415,
      -4.629,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4752",
    "polygon": [
      -14.758,
      -5.191,
      9.354,
      -14.758,
      -5.191,
      11.804,
      -16.415,
      -4.629,
      11.804,
      -16.415,
      -4.629,
      11.804,
      -16.415,
      -4.629,
      9.354,
      -14.758,
      -5.191,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4753",
    "polygon": [
      -13.484,
      -5.623,
      9.354,
      -13.484,
      -5.623,
      11.804,
      -14.081,
      -5.421,
      11.804,
      -14.081,
      -5.421,
      11.804,
      -14.081,
      -5.421,
      9.354,
      -13.484,
      -5.623,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4754",
    "polygon": [
      -11.827,
      -6.185,
      9.354,
      -11.827,
      -6.185,
      11.804,
      -13.484,
      -5.623,
      11.804,
      -13.484,
      -5.623,
      11.804,
      -13.484,
      -5.623,
      9.354,
      -11.827,
      -6.185,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4755",
    "polygon": [
      -4.533,
      -8.659,
      9.354,
      -4.533,
      -8.659,
      11.804,
      -5.13,
      -8.457,
      11.804,
      -5.13,
      -8.457,
      11.804,
      -5.13,
      -8.457,
      9.354,
      -4.533,
      -8.659,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4756",
    "polygon": [
      -2.294,
      -9.419,
      9.354,
      -2.294,
      -9.419,
      11.804,
      -2.876,
      -9.222,
      11.804,
      -2.876,
      -9.222,
      11.804,
      -2.876,
      -9.222,
      9.354,
      -2.294,
      -9.419,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4757",
    "polygon": [
      -2.876,
      -9.222,
      9.354,
      -2.876,
      -9.222,
      11.804,
      -4.533,
      -8.659,
      11.804,
      -4.533,
      -8.659,
      11.804,
      -4.533,
      -8.659,
      9.354,
      -2.876,
      -9.222,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4758",
    "polygon": [
      -1.605,
      -9.653,
      9.354,
      -1.605,
      -9.653,
      11.804,
      -2.315,
      -9.412,
      11.804,
      -2.315,
      -9.412,
      11.804,
      -2.315,
      -9.412,
      9.354,
      -1.605,
      -9.653,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4759",
    "polygon": [
      -0.092,
      -10.166,
      9.354,
      -0.092,
      -10.166,
      11.804,
      -1.617,
      -9.649,
      11.804,
      -1.617,
      -9.649,
      11.804,
      -1.617,
      -9.649,
      9.354,
      -0.092,
      -10.166,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4760",
    "polygon": [
      1.335,
      -10.65,
      9.354,
      1.335,
      -10.65,
      11.804,
      0.739,
      -10.448,
      11.804,
      0.739,
      -10.448,
      11.804,
      0.739,
      -10.448,
      9.354,
      1.335,
      -10.65,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4761",
    "polygon": [
      3.575,
      -11.41,
      9.354,
      3.575,
      -11.41,
      11.804,
      2.993,
      -11.212,
      11.804,
      2.993,
      -11.212,
      11.804,
      2.993,
      -11.212,
      9.354,
      3.575,
      -11.41,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4762",
    "polygon": [
      2.993,
      -11.212,
      9.354,
      2.993,
      -11.212,
      11.804,
      1.335,
      -10.65,
      11.804,
      1.335,
      -10.65,
      11.804,
      1.335,
      -10.65,
      9.354,
      2.993,
      -11.212,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4763",
    "polygon": [
      4.264,
      -11.643,
      9.354,
      4.264,
      -11.643,
      11.804,
      3.554,
      -11.403,
      11.804,
      3.554,
      -11.403,
      11.804,
      3.554,
      -11.403,
      9.354,
      4.264,
      -11.643,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4764",
    "polygon": [
      5.777,
      -12.157,
      9.354,
      5.777,
      -12.157,
      11.804,
      4.252,
      -11.639,
      11.804,
      4.252,
      -11.639,
      11.804,
      4.252,
      -11.639,
      9.354,
      5.777,
      -12.157,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4765",
    "polygon": [
      7.256,
      -12.658,
      9.354,
      7.256,
      -12.658,
      11.804,
      6.659,
      -12.456,
      11.804,
      6.659,
      -12.456,
      11.804,
      6.659,
      -12.456,
      9.354,
      7.256,
      -12.658,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4766",
    "polygon": [
      9.496,
      -13.418,
      9.354,
      9.496,
      -13.418,
      11.804,
      8.913,
      -13.22,
      11.804,
      8.913,
      -13.22,
      11.804,
      8.913,
      -13.22,
      9.354,
      9.496,
      -13.418,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4767",
    "polygon": [
      8.913,
      -13.22,
      9.354,
      8.913,
      -13.22,
      11.804,
      7.256,
      -12.658,
      11.804,
      7.256,
      -12.658,
      11.804,
      7.256,
      -12.658,
      9.354,
      8.913,
      -13.22,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4768",
    "polygon": [
      10.185,
      -13.652,
      9.354,
      10.185,
      -13.652,
      11.804,
      9.474,
      -13.411,
      11.804,
      9.474,
      -13.411,
      11.804,
      9.474,
      -13.411,
      9.354,
      10.185,
      -13.652,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_2.OG_4769",
    "polygon": [
      11.697,
      -14.165,
      9.354,
      11.697,
      -14.165,
      11.804,
      10.173,
      -13.648,
      11.804,
      10.173,
      -13.648,
      11.804,
      10.173,
      -13.648,
      9.354,
      11.697,
      -14.165,
      9.354
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4770",
    "polygon": [
      -107.75,
      26.04,
      13.09,
      -107.75,
      26.04,
      15.54,
      -108.347,
      26.24,
      15.54,
      -108.347,
      26.24,
      15.54,
      -108.347,
      26.24,
      13.09,
      -107.75,
      26.04,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4771",
    "polygon": [
      -105.507,
      25.289,
      13.09,
      -105.507,
      25.289,
      15.54,
      -106.09,
      25.484,
      15.54,
      -106.09,
      25.484,
      15.54,
      -106.09,
      25.484,
      13.09,
      -105.507,
      25.289,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4772",
    "polygon": [
      -106.09,
      25.484,
      13.09,
      -106.09,
      25.484,
      15.54,
      -107.75,
      26.04,
      15.54,
      -107.75,
      26.04,
      15.54,
      -107.75,
      26.04,
      13.09,
      -106.09,
      25.484,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4773",
    "polygon": [
      -104.817,
      25.058,
      13.09,
      -104.817,
      25.058,
      15.54,
      -105.529,
      25.296,
      15.54,
      -105.529,
      25.296,
      15.54,
      -105.529,
      25.296,
      13.09,
      -104.817,
      25.058,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4774",
    "polygon": [
      -103.303,
      24.55,
      13.09,
      -103.303,
      24.55,
      15.54,
      -104.829,
      25.062,
      15.54,
      -104.829,
      25.062,
      15.54,
      -104.829,
      25.062,
      13.09,
      -103.303,
      24.55,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4775",
    "polygon": [
      -101.84,
      24.06,
      13.09,
      -101.84,
      24.06,
      15.54,
      -102.437,
      24.26,
      15.54,
      -102.437,
      24.26,
      15.54,
      -102.437,
      24.26,
      13.09,
      -101.84,
      24.06,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4776",
    "polygon": [
      -99.597,
      23.309,
      13.09,
      -99.597,
      23.309,
      15.54,
      -100.18,
      23.504,
      15.54,
      -100.18,
      23.504,
      15.54,
      -100.18,
      23.504,
      13.09,
      -99.597,
      23.309,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4777",
    "polygon": [
      -100.18,
      23.504,
      13.09,
      -100.18,
      23.504,
      15.54,
      -101.84,
      24.06,
      15.54,
      -101.84,
      24.06,
      15.54,
      -101.84,
      24.06,
      13.09,
      -100.18,
      23.504,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4778",
    "polygon": [
      -98.907,
      23.078,
      13.09,
      -98.907,
      23.078,
      15.54,
      -99.618,
      23.316,
      15.54,
      -99.618,
      23.316,
      15.54,
      -99.618,
      23.316,
      13.09,
      -98.907,
      23.078,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4779",
    "polygon": [
      -97.392,
      22.57,
      13.09,
      -97.392,
      22.57,
      15.54,
      -98.919,
      23.082,
      15.54,
      -98.919,
      23.082,
      15.54,
      -98.919,
      23.082,
      13.09,
      -97.392,
      22.57,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4780",
    "polygon": [
      -95.906,
      22.072,
      13.09,
      -95.906,
      22.072,
      15.54,
      -96.503,
      22.272,
      15.54,
      -96.503,
      22.272,
      15.54,
      -96.503,
      22.272,
      13.09,
      -95.906,
      22.072,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4781",
    "polygon": [
      -93.663,
      21.321,
      13.09,
      -93.663,
      21.321,
      15.54,
      -94.246,
      21.516,
      15.54,
      -94.246,
      21.516,
      15.54,
      -94.246,
      21.516,
      13.09,
      -93.663,
      21.321,
      13.09
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4782",
    "polygon": [
      -94.246,
      21.516,
      13.09,
      -94.246,
      21.516,
      15.54,
      -95.906,
      22.072,
      15.54,
      -95.906,
      22.072,
      15.54,
      -95.906,
      22.072,
      13.09,
      -94.246,
      21.516,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4783",
    "polygon": [
      -91.422,
      20.571,
      13.09,
      -91.422,
      20.571,
      15.54,
      -92.949,
      21.082,
      15.54,
      -92.949,
      21.082,
      15.54,
      -92.949,
      21.082,
      13.09,
      -91.422,
      20.571,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4784",
    "polygon": [
      -90.816,
      20.367,
      13.09,
      -90.816,
      20.367,
      15.54,
      -91.413,
      20.567,
      15.54,
      -91.413,
      20.567,
      15.54,
      -91.413,
      20.567,
      13.09,
      -90.816,
      20.367,
      13.09
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4785",
    "polygon": [
      -88.456,
      19.577,
      13.09,
      -88.456,
      19.577,
      15.54,
      -89.983,
      20.088,
      15.54,
      -89.983,
      20.088,
      15.54,
      -89.983,
      20.088,
      13.09,
      -88.456,
      19.577,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4786",
    "polygon": [
      -87.85,
      19.374,
      13.09,
      -87.85,
      19.374,
      15.54,
      -88.447,
      19.574,
      15.54,
      -88.447,
      19.574,
      15.54,
      -88.447,
      19.574,
      13.09,
      -87.85,
      19.374,
      13.09
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4787",
    "polygon": [
      -79.52,
      16.583,
      13.09,
      -79.52,
      16.583,
      15.54,
      -81.046,
      17.095,
      15.54,
      -81.046,
      17.095,
      15.54,
      -81.046,
      17.095,
      13.09,
      -79.52,
      16.583,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4788",
    "polygon": [
      -78.127,
      16.117,
      13.09,
      -78.127,
      16.117,
      15.54,
      -78.724,
      16.317,
      15.54,
      -78.724,
      16.317,
      15.54,
      -78.724,
      16.317,
      13.09,
      -78.127,
      16.117,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4789",
    "polygon": [
      -75.884,
      15.365,
      13.09,
      -75.884,
      15.365,
      15.54,
      -76.467,
      15.561,
      15.54,
      -76.467,
      15.561,
      15.54,
      -76.467,
      15.561,
      13.09,
      -75.884,
      15.365,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4790",
    "polygon": [
      -76.467,
      15.561,
      13.09,
      -76.467,
      15.561,
      15.54,
      -78.127,
      16.117,
      15.54,
      -78.127,
      16.117,
      15.54,
      -78.127,
      16.117,
      13.09,
      -76.467,
      15.561,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4791",
    "polygon": [
      -75.194,
      15.134,
      13.09,
      -75.194,
      15.134,
      15.54,
      -75.905,
      15.373,
      15.54,
      -75.905,
      15.373,
      15.54,
      -75.905,
      15.373,
      13.09,
      -75.194,
      15.134,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4792",
    "polygon": [
      -73.68,
      14.627,
      13.09,
      -73.68,
      14.627,
      15.54,
      -75.206,
      15.138,
      15.54,
      -75.206,
      15.138,
      15.54,
      -75.206,
      15.138,
      13.09,
      -73.68,
      14.627,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4793",
    "polygon": [
      -72.199,
      14.131,
      13.09,
      -72.199,
      14.131,
      15.54,
      -72.797,
      14.331,
      15.54,
      -72.797,
      14.331,
      15.54,
      -72.797,
      14.331,
      13.09,
      -72.199,
      14.131,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4794",
    "polygon": [
      -69.957,
      13.38,
      13.09,
      -69.957,
      13.38,
      15.54,
      -70.54,
      13.575,
      15.54,
      -70.54,
      13.575,
      15.54,
      -70.54,
      13.575,
      13.09,
      -69.957,
      13.38,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4795",
    "polygon": [
      -70.54,
      13.575,
      13.09,
      -70.54,
      13.575,
      15.54,
      -72.199,
      14.131,
      15.54,
      -72.199,
      14.131,
      15.54,
      -72.199,
      14.131,
      13.09,
      -70.54,
      13.575,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4796",
    "polygon": [
      -69.267,
      13.149,
      13.09,
      -69.267,
      13.149,
      15.54,
      -69.978,
      13.387,
      15.54,
      -69.978,
      13.387,
      15.54,
      -69.978,
      13.387,
      13.09,
      -69.267,
      13.149,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4797",
    "polygon": [
      -67.752,
      12.641,
      13.09,
      -67.752,
      12.641,
      15.54,
      -69.279,
      13.153,
      15.54,
      -69.279,
      13.153,
      15.54,
      -69.279,
      13.153,
      13.09,
      -67.752,
      12.641,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4798",
    "polygon": [
      -66.271,
      12.145,
      13.09,
      -66.271,
      12.145,
      15.54,
      -66.869,
      12.345,
      15.54,
      -66.869,
      12.345,
      15.54,
      -66.869,
      12.345,
      13.09,
      -66.271,
      12.145,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4799",
    "polygon": [
      -64.029,
      11.394,
      13.09,
      -64.029,
      11.394,
      15.54,
      -64.612,
      11.589,
      15.54,
      -64.612,
      11.589,
      15.54,
      -64.612,
      11.589,
      13.09,
      -64.029,
      11.394,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4800",
    "polygon": [
      -64.612,
      11.589,
      13.09,
      -64.612,
      11.589,
      15.54,
      -66.271,
      12.145,
      15.54,
      -66.271,
      12.145,
      15.54,
      -66.271,
      12.145,
      13.09,
      -64.612,
      11.589,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4801",
    "polygon": [
      -63.339,
      11.163,
      13.09,
      -63.339,
      11.163,
      15.54,
      -64.05,
      11.401,
      15.54,
      -64.05,
      11.401,
      15.54,
      -64.05,
      11.401,
      13.09,
      -63.339,
      11.163,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_4802",
    "polygon": [
      -61.824,
      10.655,
      13.09,
      -61.824,
      10.655,
      15.54,
      -63.351,
      11.167,
      15.54,
      -63.351,
      11.167,
      15.54,
      -63.351,
      11.167,
      13.09,
      -61.824,
      10.655,
      13.09
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4803",
    "polygon": [
      -28.257,
      -0.613,
      13.104,
      -28.257,
      -0.613,
      15.554,
      -28.853,
      -0.41,
      15.554,
      -28.853,
      -0.41,
      15.554,
      -28.853,
      -0.41,
      13.104,
      -28.257,
      -0.613,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4804",
    "polygon": [
      -26.017,
      -1.372,
      13.104,
      -26.017,
      -1.372,
      15.554,
      -26.599,
      -1.175,
      15.554,
      -26.599,
      -1.175,
      15.554,
      -26.599,
      -1.175,
      13.104,
      -26.017,
      -1.372,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4805",
    "polygon": [
      -26.599,
      -1.175,
      13.104,
      -26.599,
      -1.175,
      15.554,
      -28.257,
      -0.613,
      15.554,
      -28.257,
      -0.613,
      15.554,
      -28.257,
      -0.613,
      13.104,
      -26.599,
      -1.175,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4806",
    "polygon": [
      -25.328,
      -1.606,
      13.104,
      -25.328,
      -1.606,
      15.554,
      -26.038,
      -1.365,
      15.554,
      -26.038,
      -1.365,
      15.554,
      -26.038,
      -1.365,
      13.104,
      -25.328,
      -1.606,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4807",
    "polygon": [
      -23.815,
      -2.119,
      13.104,
      -23.815,
      -2.119,
      15.554,
      -25.34,
      -1.602,
      15.554,
      -25.34,
      -1.602,
      15.554,
      -25.34,
      -1.602,
      13.104,
      -23.815,
      -2.119,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4808",
    "polygon": [
      -22.337,
      -2.621,
      13.104,
      -22.337,
      -2.621,
      15.554,
      -22.934,
      -2.418,
      15.554,
      -22.934,
      -2.418,
      15.554,
      -22.934,
      -2.418,
      13.104,
      -22.337,
      -2.621,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4809",
    "polygon": [
      -20.097,
      -3.38,
      13.104,
      -20.097,
      -3.38,
      15.554,
      -20.68,
      -3.183,
      15.554,
      -20.68,
      -3.183,
      15.554,
      -20.68,
      -3.183,
      13.104,
      -20.097,
      -3.38,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4810",
    "polygon": [
      -20.68,
      -3.183,
      13.104,
      -20.68,
      -3.183,
      15.554,
      -22.337,
      -2.621,
      15.554,
      -22.337,
      -2.621,
      15.554,
      -22.337,
      -2.621,
      13.104,
      -20.68,
      -3.183,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4811",
    "polygon": [
      -19.408,
      -3.614,
      13.104,
      -19.408,
      -3.614,
      15.554,
      -20.119,
      -3.373,
      15.554,
      -20.119,
      -3.373,
      15.554,
      -20.119,
      -3.373,
      13.104,
      -19.408,
      -3.614,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4812",
    "polygon": [
      -17.896,
      -4.127,
      13.104,
      -17.896,
      -4.127,
      15.554,
      -19.42,
      -3.61,
      15.554,
      -19.42,
      -3.61,
      15.554,
      -19.42,
      -3.61,
      13.104,
      -17.896,
      -4.127,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4813",
    "polygon": [
      -34.175,
      1.395,
      13.104,
      -34.175,
      1.395,
      15.554,
      -34.772,
      1.597,
      15.554,
      -34.772,
      1.597,
      15.554,
      -34.772,
      1.597,
      13.104,
      -34.175,
      1.395,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4814",
    "polygon": [
      -31.936,
      0.635,
      13.104,
      -31.936,
      0.635,
      15.554,
      -32.518,
      0.833,
      15.554,
      -32.518,
      0.833,
      15.554,
      -32.518,
      0.833,
      13.104,
      -31.936,
      0.635,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4815",
    "polygon": [
      -32.518,
      0.833,
      13.104,
      -32.518,
      0.833,
      15.554,
      -34.175,
      1.395,
      15.554,
      -34.175,
      1.395,
      15.554,
      -34.175,
      1.395,
      13.104,
      -32.518,
      0.833,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4816",
    "polygon": [
      -31.247,
      0.402,
      13.104,
      -31.247,
      0.402,
      15.554,
      -31.957,
      0.643,
      15.554,
      -31.957,
      0.643,
      15.554,
      -31.957,
      0.643,
      13.104,
      -31.247,
      0.402,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4817",
    "polygon": [
      -29.734,
      -0.111,
      13.104,
      -29.734,
      -0.111,
      15.554,
      -31.259,
      0.406,
      15.554,
      -31.259,
      0.406,
      15.554,
      -31.259,
      0.406,
      13.104,
      -29.734,
      -0.111,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4818",
    "polygon": [
      -16.415,
      -4.629,
      13.104,
      -16.415,
      -4.629,
      15.554,
      -17.012,
      -4.427,
      15.554,
      -17.012,
      -4.427,
      15.554,
      -17.012,
      -4.427,
      13.104,
      -16.415,
      -4.629,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4819",
    "polygon": [
      -14.758,
      -5.191,
      13.104,
      -14.758,
      -5.191,
      15.554,
      -16.415,
      -4.629,
      15.554,
      -16.415,
      -4.629,
      15.554,
      -16.415,
      -4.629,
      13.104,
      -14.758,
      -5.191,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4820",
    "polygon": [
      -13.484,
      -5.623,
      13.104,
      -13.484,
      -5.623,
      15.554,
      -14.081,
      -5.421,
      15.554,
      -14.081,
      -5.421,
      15.554,
      -14.081,
      -5.421,
      13.104,
      -13.484,
      -5.623,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4821",
    "polygon": [
      -11.827,
      -6.185,
      13.104,
      -11.827,
      -6.185,
      15.554,
      -13.484,
      -5.623,
      15.554,
      -13.484,
      -5.623,
      15.554,
      -13.484,
      -5.623,
      13.104,
      -11.827,
      -6.185,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4822",
    "polygon": [
      -4.533,
      -8.659,
      13.104,
      -4.533,
      -8.659,
      15.554,
      -5.13,
      -8.457,
      15.554,
      -5.13,
      -8.457,
      15.554,
      -5.13,
      -8.457,
      13.104,
      -4.533,
      -8.659,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4823",
    "polygon": [
      -2.294,
      -9.419,
      13.104,
      -2.294,
      -9.419,
      15.554,
      -2.876,
      -9.222,
      15.554,
      -2.876,
      -9.222,
      15.554,
      -2.876,
      -9.222,
      13.104,
      -2.294,
      -9.419,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4824",
    "polygon": [
      -2.876,
      -9.222,
      13.104,
      -2.876,
      -9.222,
      15.554,
      -4.533,
      -8.659,
      15.554,
      -4.533,
      -8.659,
      15.554,
      -4.533,
      -8.659,
      13.104,
      -2.876,
      -9.222,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4825",
    "polygon": [
      -1.605,
      -9.653,
      13.104,
      -1.605,
      -9.653,
      15.554,
      -2.315,
      -9.412,
      15.554,
      -2.315,
      -9.412,
      15.554,
      -2.315,
      -9.412,
      13.104,
      -1.605,
      -9.653,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4826",
    "polygon": [
      -0.092,
      -10.166,
      13.104,
      -0.092,
      -10.166,
      15.554,
      -1.617,
      -9.649,
      15.554,
      -1.617,
      -9.649,
      15.554,
      -1.617,
      -9.649,
      13.104,
      -0.092,
      -10.166,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4827",
    "polygon": [
      1.335,
      -10.65,
      13.104,
      1.335,
      -10.65,
      15.554,
      0.739,
      -10.448,
      15.554,
      0.739,
      -10.448,
      15.554,
      0.739,
      -10.448,
      13.104,
      1.335,
      -10.65,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4828",
    "polygon": [
      3.575,
      -11.41,
      13.104,
      3.575,
      -11.41,
      15.554,
      2.993,
      -11.212,
      15.554,
      2.993,
      -11.212,
      15.554,
      2.993,
      -11.212,
      13.104,
      3.575,
      -11.41,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4829",
    "polygon": [
      2.993,
      -11.212,
      13.104,
      2.993,
      -11.212,
      15.554,
      1.335,
      -10.65,
      15.554,
      1.335,
      -10.65,
      15.554,
      1.335,
      -10.65,
      13.104,
      2.993,
      -11.212,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4830",
    "polygon": [
      4.264,
      -11.643,
      13.104,
      4.264,
      -11.643,
      15.554,
      3.554,
      -11.403,
      15.554,
      3.554,
      -11.403,
      15.554,
      3.554,
      -11.403,
      13.104,
      4.264,
      -11.643,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4831",
    "polygon": [
      5.777,
      -12.157,
      13.104,
      5.777,
      -12.157,
      15.554,
      4.252,
      -11.639,
      15.554,
      4.252,
      -11.639,
      15.554,
      4.252,
      -11.639,
      13.104,
      5.777,
      -12.157,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4832",
    "polygon": [
      7.256,
      -12.658,
      13.104,
      7.256,
      -12.658,
      15.554,
      6.659,
      -12.456,
      15.554,
      6.659,
      -12.456,
      15.554,
      6.659,
      -12.456,
      13.104,
      7.256,
      -12.658,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4833",
    "polygon": [
      9.496,
      -13.418,
      13.104,
      9.496,
      -13.418,
      15.554,
      8.913,
      -13.22,
      15.554,
      8.913,
      -13.22,
      15.554,
      8.913,
      -13.22,
      13.104,
      9.496,
      -13.418,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4834",
    "polygon": [
      8.913,
      -13.22,
      13.104,
      8.913,
      -13.22,
      15.554,
      7.256,
      -12.658,
      15.554,
      7.256,
      -12.658,
      15.554,
      7.256,
      -12.658,
      13.104,
      8.913,
      -13.22,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4835",
    "polygon": [
      10.185,
      -13.652,
      13.104,
      10.185,
      -13.652,
      15.554,
      9.474,
      -13.411,
      15.554,
      9.474,
      -13.411,
      15.554,
      9.474,
      -13.411,
      13.104,
      10.185,
      -13.652,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-D-Outer_3.OG_4836",
    "polygon": [
      11.697,
      -14.165,
      13.104,
      11.697,
      -14.165,
      15.554,
      10.173,
      -13.648,
      15.554,
      10.173,
      -13.648,
      15.554,
      10.173,
      -13.648,
      13.104,
      11.697,
      -14.165,
      13.104
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "Block-A-Outer_EG_4969",
    "polygon": [
      -37.035,
      122.075,
      0.745,
      -37.035,
      122.075,
      3.455,
      -36.523,
      123.602,
      3.455,
      -36.523,
      123.602,
      3.455,
      -36.523,
      123.602,
      0.745,
      -37.035,
      122.075,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4970",
    "polygon": [
      -37.232,
      121.486,
      0.745,
      -37.232,
      121.486,
      3.455,
      -37.035,
      122.074,
      3.455,
      -37.035,
      122.074,
      3.455,
      -37.035,
      122.074,
      0.745,
      -37.232,
      121.486,
      0.745
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4971",
    "polygon": [
      -37.476,
      120.757,
      0.745,
      -37.476,
      120.757,
      3.455,
      -37.238,
      121.468,
      3.455,
      -37.238,
      121.468,
      3.455,
      -37.238,
      121.468,
      0.745,
      -37.476,
      120.757,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4972",
    "polygon": [
      -37.995,
      119.208,
      0.745,
      -37.995,
      119.208,
      3.455,
      -37.484,
      120.734,
      3.455,
      -37.484,
      120.734,
      3.455,
      -37.484,
      120.734,
      0.745,
      -37.995,
      119.208,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4973",
    "polygon": [
      -38.492,
      117.724,
      0.745,
      -38.492,
      117.724,
      3.455,
      -38.254,
      118.435,
      3.455,
      -38.254,
      118.435,
      3.455,
      -38.254,
      118.435,
      0.745,
      -38.492,
      117.724,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4974",
    "polygon": [
      -39.011,
      116.175,
      0.745,
      -39.011,
      116.175,
      3.455,
      -38.5,
      117.702,
      3.455,
      -38.5,
      117.702,
      3.455,
      -38.5,
      117.702,
      0.745,
      -39.011,
      116.175,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4975",
    "polygon": [
      -39.209,
      115.585,
      0.745,
      -39.209,
      115.585,
      3.455,
      -39.012,
      116.173,
      3.455,
      -39.012,
      116.173,
      3.455,
      -39.012,
      116.173,
      0.745,
      -39.209,
      115.585,
      0.745
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4976",
    "polygon": [
      -39.453,
      114.857,
      0.745,
      -39.453,
      114.857,
      3.455,
      -39.215,
      115.568,
      3.455,
      -39.215,
      115.568,
      3.455,
      -39.215,
      115.568,
      0.745,
      -39.453,
      114.857,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4977",
    "polygon": [
      -39.972,
      113.307,
      0.745,
      -39.972,
      113.307,
      3.455,
      -39.46,
      114.834,
      3.455,
      -39.46,
      114.834,
      3.455,
      -39.46,
      114.834,
      0.745,
      -39.972,
      113.307,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4978",
    "polygon": [
      -40.497,
      111.738,
      0.745,
      -40.497,
      111.738,
      3.455,
      -40.259,
      112.449,
      3.455,
      -40.259,
      112.449,
      3.455,
      -40.259,
      112.449,
      0.745,
      -40.497,
      111.738,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4979",
    "polygon": [
      -41.016,
      110.189,
      0.745,
      -41.016,
      110.189,
      3.455,
      -40.505,
      111.715,
      3.455,
      -40.505,
      111.715,
      3.455,
      -40.505,
      111.715,
      0.745,
      -41.016,
      110.189,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4980",
    "polygon": [
      -41.214,
      109.599,
      0.745,
      -41.214,
      109.599,
      3.455,
      -41.017,
      110.187,
      3.455,
      -41.017,
      110.187,
      3.455,
      -41.017,
      110.187,
      0.745,
      -41.214,
      109.599,
      0.745
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4981",
    "polygon": [
      -41.458,
      108.87,
      0.745,
      -41.458,
      108.87,
      3.455,
      -41.22,
      109.581,
      3.455,
      -41.22,
      109.581,
      3.455,
      -41.22,
      109.581,
      0.745,
      -41.458,
      108.87,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4982",
    "polygon": [
      -41.977,
      107.321,
      0.745,
      -41.977,
      107.321,
      3.455,
      -41.466,
      108.848,
      3.455,
      -41.466,
      108.848,
      3.455,
      -41.466,
      108.848,
      0.745,
      -41.977,
      107.321,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4983",
    "polygon": [
      -42.483,
      105.811,
      0.745,
      -42.483,
      105.811,
      3.455,
      -42.244,
      106.523,
      3.455,
      -42.244,
      106.523,
      3.455,
      -42.244,
      106.523,
      0.745,
      -42.483,
      105.811,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4984",
    "polygon": [
      -43.002,
      104.262,
      0.745,
      -43.002,
      104.262,
      3.455,
      -42.49,
      105.789,
      3.455,
      -42.49,
      105.789,
      3.455,
      -42.49,
      105.789,
      0.745,
      -43.002,
      104.262,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4985",
    "polygon": [
      -43.199,
      103.673,
      0.745,
      -43.199,
      103.673,
      3.455,
      -43.002,
      104.261,
      3.455,
      -43.002,
      104.261,
      3.455,
      -43.002,
      104.261,
      0.745,
      -43.199,
      103.673,
      0.745
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4986",
    "polygon": [
      -43.443,
      102.944,
      0.745,
      -43.443,
      102.944,
      3.455,
      -43.205,
      103.655,
      3.455,
      -43.205,
      103.655,
      3.455,
      -43.205,
      103.655,
      0.745,
      -43.443,
      102.944,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4987",
    "polygon": [
      -43.962,
      101.395,
      0.745,
      -43.962,
      101.395,
      3.455,
      -43.451,
      102.921,
      3.455,
      -43.451,
      102.921,
      3.455,
      -43.451,
      102.921,
      0.745,
      -43.962,
      101.395,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4988",
    "polygon": [
      -44.468,
      99.885,
      0.745,
      -44.468,
      99.885,
      3.455,
      -44.23,
      100.597,
      3.455,
      -44.23,
      100.597,
      3.455,
      -44.23,
      100.597,
      0.745,
      -44.468,
      99.885,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4989",
    "polygon": [
      -44.987,
      98.336,
      0.745,
      -44.987,
      98.336,
      3.455,
      -44.475,
      99.863,
      3.455,
      -44.475,
      99.863,
      3.455,
      -44.475,
      99.863,
      0.745,
      -44.987,
      98.336,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4990",
    "polygon": [
      -45.184,
      97.747,
      0.745,
      -45.184,
      97.747,
      3.455,
      -44.987,
      98.335,
      3.455,
      -44.987,
      98.335,
      3.455,
      -44.987,
      98.335,
      0.745,
      -45.184,
      97.747,
      0.745
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4991",
    "polygon": [
      -45.428,
      97.018,
      0.745,
      -45.428,
      97.018,
      3.455,
      -45.19,
      97.729,
      3.455,
      -45.19,
      97.729,
      3.455,
      -45.19,
      97.729,
      0.745,
      -45.428,
      97.018,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4992",
    "polygon": [
      -45.947,
      95.469,
      0.745,
      -45.947,
      95.469,
      3.455,
      -45.436,
      96.995,
      3.455,
      -45.436,
      96.995,
      3.455,
      -45.436,
      96.995,
      0.745,
      -45.947,
      95.469,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4993",
    "polygon": [
      -46.448,
      93.973,
      0.745,
      -46.448,
      93.973,
      3.455,
      -46.21,
      94.684,
      3.455,
      -46.21,
      94.684,
      3.455,
      -46.21,
      94.684,
      0.745,
      -46.448,
      93.973,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4994",
    "polygon": [
      -46.967,
      92.424,
      0.745,
      -46.967,
      92.424,
      3.455,
      -46.456,
      93.951,
      3.455,
      -46.456,
      93.951,
      3.455,
      -46.456,
      93.951,
      0.745,
      -46.967,
      92.424,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4995",
    "polygon": [
      -47.165,
      91.834,
      0.745,
      -47.165,
      91.834,
      3.455,
      -46.968,
      92.422,
      3.455,
      -46.968,
      92.422,
      3.455,
      -46.968,
      92.422,
      0.745,
      -47.165,
      91.834,
      0.745
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4996",
    "polygon": [
      -47.409,
      91.105,
      0.745,
      -47.409,
      91.105,
      3.455,
      -47.171,
      91.817,
      3.455,
      -47.171,
      91.817,
      3.455,
      -47.171,
      91.817,
      0.745,
      -47.409,
      91.105,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4997",
    "polygon": [
      -47.928,
      89.556,
      0.745,
      -47.928,
      89.556,
      3.455,
      -47.417,
      91.083,
      3.455,
      -47.417,
      91.083,
      3.455,
      -47.417,
      91.083,
      0.745,
      -47.928,
      89.556,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4998",
    "polygon": [
      -48.438,
      88.033,
      0.745,
      -48.438,
      88.033,
      3.455,
      -48.2,
      88.744,
      3.455,
      -48.2,
      88.744,
      3.455,
      -48.2,
      88.744,
      0.745,
      -48.438,
      88.033,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_4999",
    "polygon": [
      -48.957,
      86.484,
      0.745,
      -48.957,
      86.484,
      3.455,
      -48.446,
      88.01,
      3.455,
      -48.446,
      88.01,
      3.455,
      -48.446,
      88.01,
      0.745,
      -48.957,
      86.484,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_5000",
    "polygon": [
      -49.155,
      85.894,
      0.745,
      -49.155,
      85.894,
      3.455,
      -48.958,
      86.482,
      3.455,
      -48.958,
      86.482,
      3.455,
      -48.958,
      86.482,
      0.745,
      -49.155,
      85.894,
      0.745
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_5001",
    "polygon": [
      -49.399,
      85.165,
      0.745,
      -49.399,
      85.165,
      3.455,
      -49.161,
      85.876,
      3.455,
      -49.161,
      85.876,
      3.455,
      -49.161,
      85.876,
      0.745,
      -49.399,
      85.165,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_5002",
    "polygon": [
      -49.918,
      83.616,
      0.745,
      -49.918,
      83.616,
      3.455,
      -49.407,
      85.143,
      3.455,
      -49.407,
      85.143,
      3.455,
      -49.407,
      85.143,
      0.745,
      -49.918,
      83.616,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_5003",
    "polygon": [
      -50.424,
      82.106,
      0.745,
      -50.424,
      82.106,
      3.455,
      -50.185,
      82.818,
      3.455,
      -50.185,
      82.818,
      3.455,
      -50.185,
      82.818,
      0.745,
      -50.424,
      82.106,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_5004",
    "polygon": [
      -50.943,
      80.557,
      0.745,
      -50.943,
      80.557,
      3.455,
      -50.431,
      82.084,
      3.455,
      -50.431,
      82.084,
      3.455,
      -50.431,
      82.084,
      0.745,
      -50.943,
      80.557,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_5005",
    "polygon": [
      -51.14,
      79.968,
      0.745,
      -51.14,
      79.968,
      3.455,
      -50.943,
      80.555,
      3.455,
      -50.943,
      80.555,
      3.455,
      -50.943,
      80.555,
      0.745,
      -51.14,
      79.968,
      0.745
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_5006",
    "polygon": [
      -51.384,
      79.239,
      0.745,
      -51.384,
      79.239,
      3.455,
      -51.146,
      79.95,
      3.455,
      -51.146,
      79.95,
      3.455,
      -51.146,
      79.95,
      0.745,
      -51.384,
      79.239,
      0.745
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_EG_5007",
    "polygon": [
      -51.903,
      77.69,
      0.745,
      -51.903,
      77.69,
      3.455,
      -51.392,
      79.216,
      3.455,
      -51.392,
      79.216,
      3.455,
      -51.392,
      79.216,
      0.745,
      -51.903,
      77.69,
      0.745
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5008",
    "polygon": [
      -94.025,
      73.98,
      0.0,
      -94.025,
      73.98,
      3.41,
      -93.787,
      74.691,
      3.41,
      -93.787,
      74.691,
      3.41,
      -93.787,
      74.691,
      0.0,
      -94.025,
      73.98,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5009",
    "polygon": [
      -94.495,
      72.577,
      0.0,
      -94.495,
      72.577,
      3.41,
      -94.025,
      73.98,
      3.41,
      -94.025,
      73.98,
      3.41,
      -94.025,
      73.98,
      0.0,
      -94.495,
      72.577,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5010",
    "polygon": [
      -95.474,
      69.656,
      0.0,
      -95.474,
      69.656,
      3.41,
      -94.972,
      71.154,
      3.41,
      -94.972,
      71.154,
      3.41,
      -94.972,
      71.154,
      0.0,
      -95.474,
      69.656,
      0.0
    ],
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5011",
    "polygon": [
      -94.734,
      71.865,
      0.0,
      -94.734,
      71.865,
      3.41,
      -94.495,
      72.577,
      3.41,
      -94.495,
      72.577,
      3.41,
      -94.495,
      72.577,
      0.0,
      -94.734,
      71.865,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5012",
    "polygon": [
      -94.972,
      71.154,
      0.0,
      -94.972,
      71.154,
      3.41,
      -94.734,
      71.865,
      3.41,
      -94.734,
      71.865,
      3.41,
      -94.734,
      71.865,
      0.0,
      -94.972,
      71.154,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5013",
    "polygon": [
      -96.273,
      67.269,
      0.007,
      -96.273,
      67.269,
      2.637,
      -95.927,
      68.303,
      2.637,
      -95.927,
      68.303,
      2.637,
      -95.927,
      68.303,
      0.007,
      -96.273,
      67.269,
      0.007
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5014",
    "polygon": [
      -96.599,
      66.298,
      0.007,
      -96.599,
      66.298,
      2.637,
      -96.273,
      67.269,
      2.637,
      -96.273,
      67.269,
      2.637,
      -96.273,
      67.269,
      0.007,
      -96.599,
      66.298,
      0.007
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5015",
    "polygon": [
      -96.924,
      65.326,
      0.007,
      -96.924,
      65.326,
      2.637,
      -96.599,
      66.298,
      2.637,
      -96.599,
      66.298,
      2.637,
      -96.599,
      66.298,
      0.007,
      -96.924,
      65.326,
      0.007
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5016",
    "polygon": [
      -97.27,
      64.292,
      0.007,
      -97.27,
      64.292,
      2.637,
      -96.924,
      65.326,
      2.637,
      -96.924,
      65.326,
      2.637,
      -96.924,
      65.326,
      0.007,
      -97.27,
      64.292,
      0.007
    ],
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5017",
    "polygon": [
      -97.979,
      62.178,
      0.0,
      -97.979,
      62.178,
      3.41,
      -97.74,
      62.889,
      3.41,
      -97.74,
      62.889,
      3.41,
      -97.74,
      62.889,
      0.0,
      -97.979,
      62.178,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5020",
    "polygon": [
      -98.681,
      60.082,
      0.0,
      -98.681,
      60.082,
      3.41,
      -98.442,
      60.794,
      3.41,
      -98.442,
      60.794,
      3.41,
      -98.442,
      60.794,
      0.0,
      -98.681,
      60.082,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5021",
    "polygon": [
      -98.919,
      59.371,
      0.0,
      -98.919,
      59.371,
      3.41,
      -98.681,
      60.082,
      3.41,
      -98.681,
      60.082,
      3.41,
      -98.681,
      60.082,
      0.0,
      -98.919,
      59.371,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5022",
    "polygon": [
      -98.442,
      60.794,
      0.0,
      -98.442,
      60.794,
      3.41,
      -97.979,
      62.178,
      3.41,
      -97.979,
      62.178,
      3.41,
      -97.979,
      62.178,
      0.0,
      -98.442,
      60.794,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5023",
    "polygon": [
      -99.43,
      57.845,
      0.0,
      -99.43,
      57.845,
      3.41,
      -98.919,
      59.371,
      3.41,
      -98.919,
      59.371,
      3.41,
      -98.919,
      59.371,
      0.0,
      -99.43,
      57.845,
      0.0
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5024",
    "polygon": [
      -99.956,
      56.275,
      0.0,
      -99.956,
      56.275,
      3.41,
      -99.718,
      56.986,
      3.41,
      -99.718,
      56.986,
      3.41,
      -99.718,
      56.986,
      0.0,
      -99.956,
      56.275,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5025",
    "polygon": [
      -100.426,
      54.872,
      0.0,
      -100.426,
      54.872,
      3.41,
      -99.956,
      56.275,
      3.41,
      -99.956,
      56.275,
      3.41,
      -99.956,
      56.275,
      0.0,
      -100.426,
      54.872,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5027",
    "polygon": [
      -100.664,
      54.16,
      0.0,
      -100.664,
      54.16,
      3.41,
      -100.426,
      54.872,
      3.41,
      -100.426,
      54.872,
      3.41,
      -100.426,
      54.872,
      0.0,
      -100.664,
      54.16,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5028",
    "polygon": [
      -100.903,
      53.449,
      0.0,
      -100.903,
      53.449,
      3.41,
      -100.664,
      54.16,
      3.41,
      -100.664,
      54.16,
      3.41,
      -100.664,
      54.16,
      0.0,
      -100.903,
      53.449,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5029",
    "polygon": [
      -101.414,
      51.923,
      0.0,
      -101.414,
      51.923,
      3.41,
      -100.903,
      53.449,
      3.41,
      -100.903,
      53.449,
      3.41,
      -100.903,
      53.449,
      0.0,
      -101.414,
      51.923,
      0.0
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5030",
    "polygon": [
      -101.946,
      50.334,
      0.0,
      -101.946,
      50.334,
      3.41,
      -101.708,
      51.046,
      3.41,
      -101.708,
      51.046,
      3.41,
      -101.708,
      51.046,
      0.0,
      -101.946,
      50.334,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5031",
    "polygon": [
      -102.416,
      48.931,
      0.0,
      -102.416,
      48.931,
      3.41,
      -101.946,
      50.334,
      3.41,
      -101.946,
      50.334,
      3.41,
      -101.946,
      50.334,
      0.0,
      -102.416,
      48.931,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5032",
    "polygon": [
      -102.654,
      48.22,
      0.0,
      -102.654,
      48.22,
      3.41,
      -102.416,
      48.931,
      3.41,
      -102.416,
      48.931,
      3.41,
      -102.416,
      48.931,
      0.0,
      -102.654,
      48.22,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5033",
    "polygon": [
      -102.893,
      47.509,
      0.0,
      -102.893,
      47.509,
      3.41,
      -102.654,
      48.22,
      3.41,
      -102.654,
      48.22,
      3.41,
      -102.654,
      48.22,
      0.0,
      -102.893,
      47.509,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5034",
    "polygon": [
      -103.404,
      45.982,
      0.0,
      -103.404,
      45.982,
      3.41,
      -102.893,
      47.509,
      3.41,
      -102.893,
      47.509,
      3.41,
      -102.893,
      47.509,
      0.0,
      -103.404,
      45.982,
      0.0
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5035",
    "polygon": [
      -103.931,
      44.408,
      0.0,
      -103.931,
      44.408,
      3.41,
      -103.693,
      45.119,
      3.41,
      -103.693,
      45.119,
      3.41,
      -103.693,
      45.119,
      0.0,
      -103.931,
      44.408,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5036",
    "polygon": [
      -104.401,
      43.005,
      0.0,
      -104.401,
      43.005,
      3.41,
      -103.931,
      44.408,
      3.41,
      -103.931,
      44.408,
      3.41,
      -103.931,
      44.408,
      0.0,
      -104.401,
      43.005,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5037",
    "polygon": [
      -104.64,
      42.294,
      0.0,
      -104.64,
      42.294,
      3.41,
      -104.401,
      43.005,
      3.41,
      -104.401,
      43.005,
      3.41,
      -104.401,
      43.005,
      0.0,
      -104.64,
      42.294,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5038",
    "polygon": [
      -104.878,
      41.582,
      0.0,
      -104.878,
      41.582,
      3.41,
      -104.64,
      42.294,
      3.41,
      -104.64,
      42.294,
      3.41,
      -104.64,
      42.294,
      0.0,
      -104.878,
      41.582,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5039",
    "polygon": [
      -105.38,
      40.084,
      0.0,
      -105.38,
      40.084,
      3.41,
      -104.878,
      41.582,
      3.41,
      -104.878,
      41.582,
      3.41,
      -104.878,
      41.582,
      0.0,
      -105.38,
      40.084,
      0.0
    ],
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5040",
    "polygon": [
      -106.2,
      37.636,
      0.007,
      -106.2,
      37.636,
      2.637,
      -105.854,
      38.67,
      2.637,
      -105.854,
      38.67,
      2.637,
      -105.854,
      38.67,
      0.007,
      -106.2,
      37.636,
      0.007
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5041",
    "polygon": [
      -106.525,
      36.664,
      0.007,
      -106.525,
      36.664,
      2.637,
      -106.2,
      37.636,
      2.637,
      -106.2,
      37.636,
      2.637,
      -106.2,
      37.636,
      0.007,
      -106.525,
      36.664,
      0.007
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5042",
    "polygon": [
      -106.851,
      35.692,
      0.007,
      -106.851,
      35.692,
      2.637,
      -106.525,
      36.664,
      2.637,
      -106.525,
      36.664,
      2.637,
      -106.525,
      36.664,
      0.007,
      -106.851,
      35.692,
      0.007
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5043",
    "polygon": [
      -107.197,
      34.659,
      0.007,
      -107.197,
      34.659,
      2.637,
      -106.851,
      35.692,
      2.637,
      -106.851,
      35.692,
      2.637,
      -106.851,
      35.692,
      0.007,
      -107.197,
      34.659,
      0.007
    ],
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5044",
    "polygon": [
      -107.91,
      32.532,
      0.0,
      -107.91,
      32.532,
      3.41,
      -107.672,
      33.243,
      3.41,
      -107.672,
      33.243,
      3.41,
      -107.672,
      33.243,
      0.0,
      -107.91,
      32.532,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5046",
    "polygon": [
      -108.618,
      30.417,
      0.0,
      -108.618,
      30.417,
      3.41,
      -108.38,
      31.128,
      3.41,
      -108.38,
      31.128,
      3.41,
      -108.38,
      31.128,
      0.0,
      -108.618,
      30.417,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5047",
    "polygon": [
      -108.856,
      29.706,
      0.0,
      -108.856,
      29.706,
      3.41,
      -108.618,
      30.417,
      3.41,
      -108.618,
      30.417,
      3.41,
      -108.618,
      30.417,
      0.0,
      -108.856,
      29.706,
      0.0
    ],
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5048",
    "polygon": [
      -109.368,
      28.179,
      0.0,
      -109.368,
      28.179,
      3.41,
      -108.856,
      29.706,
      3.41,
      -108.856,
      29.706,
      3.41,
      -108.856,
      29.706,
      0.0,
      -109.368,
      28.179,
      0.0
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_EG_5049",
    "polygon": [
      -108.374,
      31.147,
      0.0,
      -108.374,
      31.147,
      3.41,
      -107.91,
      32.532,
      3.41,
      -107.91,
      32.532,
      3.41,
      -107.91,
      32.532,
      0.0,
      -108.374,
      31.147,
      0.0
    ],
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5050",
    "polygon": [
      -36.457,
      123.798,
      5.573,
      -36.457,
      123.798,
      8.023,
      -36.257,
      124.395,
      8.023,
      -36.257,
      124.395,
      8.023,
      -36.257,
      124.395,
      5.573,
      -36.457,
      123.798,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5051",
    "polygon": [
      -37.013,
      122.139,
      5.573,
      -37.013,
      122.139,
      8.023,
      -36.457,
      123.798,
      8.023,
      -36.457,
      123.798,
      8.023,
      -36.457,
      123.798,
      5.573,
      -37.013,
      122.139,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5052",
    "polygon": [
      -37.197,
      121.589,
      5.573,
      -37.197,
      121.589,
      8.023,
      -37.013,
      122.139,
      8.023,
      -37.013,
      122.139,
      8.023,
      -37.013,
      122.139,
      5.573,
      -37.197,
      121.589,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5053",
    "polygon": [
      -37.428,
      120.899,
      5.573,
      -37.428,
      120.899,
      8.023,
      -37.19,
      121.61,
      8.023,
      -37.19,
      121.61,
      8.023,
      -37.19,
      121.61,
      5.573,
      -37.428,
      120.899,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5054",
    "polygon": [
      -37.935,
      119.384,
      5.573,
      -37.935,
      119.384,
      8.023,
      -37.424,
      120.911,
      8.023,
      -37.424,
      120.911,
      8.023,
      -37.424,
      120.911,
      5.573,
      -37.935,
      119.384,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5055",
    "polygon": [
      -38.443,
      117.869,
      5.573,
      -38.443,
      117.869,
      8.023,
      -38.243,
      118.467,
      8.023,
      -38.243,
      118.467,
      8.023,
      -38.243,
      118.467,
      5.573,
      -38.443,
      117.869,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5056",
    "polygon": [
      -38.999,
      116.21,
      5.573,
      -38.999,
      116.21,
      8.023,
      -38.443,
      117.869,
      8.023,
      -38.443,
      117.869,
      8.023,
      -38.443,
      117.869,
      5.573,
      -38.999,
      116.21,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5057",
    "polygon": [
      -39.183,
      115.66,
      5.573,
      -39.183,
      115.66,
      8.023,
      -38.999,
      116.21,
      8.023,
      -38.999,
      116.21,
      8.023,
      -38.999,
      116.21,
      5.573,
      -39.183,
      115.66,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5058",
    "polygon": [
      -39.414,
      114.97,
      5.573,
      -39.414,
      114.97,
      8.023,
      -39.176,
      115.681,
      8.023,
      -39.176,
      115.681,
      8.023,
      -39.176,
      115.681,
      5.573,
      -39.414,
      114.97,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5059",
    "polygon": [
      -39.921,
      113.455,
      5.573,
      -39.921,
      113.455,
      8.023,
      -39.41,
      114.982,
      8.023,
      -39.41,
      114.982,
      8.023,
      -39.41,
      114.982,
      5.573,
      -39.921,
      113.455,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5060",
    "polygon": [
      -40.431,
      111.933,
      5.573,
      -40.431,
      111.933,
      8.023,
      -40.231,
      112.531,
      8.023,
      -40.231,
      112.531,
      8.023,
      -40.231,
      112.531,
      5.573,
      -40.431,
      111.933,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5061",
    "polygon": [
      -40.987,
      110.274,
      5.573,
      -40.987,
      110.274,
      8.023,
      -40.431,
      111.933,
      8.023,
      -40.431,
      111.933,
      8.023,
      -40.431,
      111.933,
      5.573,
      -40.987,
      110.274,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5062",
    "polygon": [
      -41.171,
      109.724,
      5.573,
      -41.171,
      109.724,
      8.023,
      -40.987,
      110.274,
      8.023,
      -40.987,
      110.274,
      8.023,
      -40.987,
      110.274,
      5.573,
      -41.171,
      109.724,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5063",
    "polygon": [
      -41.402,
      109.034,
      5.573,
      -41.402,
      109.034,
      8.023,
      -41.164,
      109.745,
      8.023,
      -41.164,
      109.745,
      8.023,
      -41.164,
      109.745,
      5.573,
      -41.402,
      109.034,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5064",
    "polygon": [
      -41.91,
      107.52,
      5.573,
      -41.91,
      107.52,
      8.023,
      -41.398,
      109.046,
      8.023,
      -41.398,
      109.046,
      8.023,
      -41.398,
      109.046,
      5.573,
      -41.91,
      107.52,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5065",
    "polygon": [
      -42.424,
      105.985,
      5.573,
      -42.424,
      105.985,
      8.023,
      -42.224,
      106.582,
      8.023,
      -42.224,
      106.582,
      8.023,
      -42.224,
      106.582,
      5.573,
      -42.424,
      105.985,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5066",
    "polygon": [
      -42.98,
      104.326,
      5.573,
      -42.98,
      104.326,
      8.023,
      -42.424,
      105.985,
      8.023,
      -42.424,
      105.985,
      8.023,
      -42.424,
      105.985,
      5.573,
      -42.98,
      104.326,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5067",
    "polygon": [
      -43.164,
      103.776,
      5.573,
      -43.164,
      103.776,
      8.023,
      -42.98,
      104.326,
      8.023,
      -42.98,
      104.326,
      8.023,
      -42.98,
      104.326,
      5.573,
      -43.164,
      103.776,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5068",
    "polygon": [
      -43.395,
      103.086,
      5.573,
      -43.395,
      103.086,
      8.023,
      -43.157,
      103.797,
      8.023,
      -43.157,
      103.797,
      8.023,
      -43.157,
      103.797,
      5.573,
      -43.395,
      103.086,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5069",
    "polygon": [
      -43.902,
      101.571,
      5.573,
      -43.902,
      101.571,
      8.023,
      -43.391,
      103.098,
      8.023,
      -43.391,
      103.098,
      8.023,
      -43.391,
      103.098,
      5.573,
      -43.902,
      101.571,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5070",
    "polygon": [
      -44.4,
      100.086,
      5.573,
      -44.4,
      100.086,
      8.023,
      -44.2,
      100.684,
      8.023,
      -44.2,
      100.684,
      8.023,
      -44.2,
      100.684,
      5.573,
      -44.4,
      100.086,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5071",
    "polygon": [
      -44.956,
      98.427,
      5.573,
      -44.956,
      98.427,
      8.023,
      -44.4,
      100.086,
      8.023,
      -44.4,
      100.086,
      8.023,
      -44.4,
      100.086,
      5.573,
      -44.956,
      98.427,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5072",
    "polygon": [
      -45.14,
      97.877,
      5.573,
      -45.14,
      97.877,
      8.023,
      -44.956,
      98.427,
      8.023,
      -44.956,
      98.427,
      8.023,
      -44.956,
      98.427,
      5.573,
      -45.14,
      97.877,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5073",
    "polygon": [
      -45.371,
      97.187,
      5.573,
      -45.371,
      97.187,
      8.023,
      -45.133,
      97.898,
      8.023,
      -45.133,
      97.898,
      8.023,
      -45.133,
      97.898,
      5.573,
      -45.371,
      97.187,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5074",
    "polygon": [
      -45.878,
      95.672,
      5.573,
      -45.878,
      95.672,
      8.023,
      -45.367,
      97.199,
      8.023,
      -45.367,
      97.199,
      8.023,
      -45.367,
      97.199,
      5.573,
      -45.878,
      95.672,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5075",
    "polygon": [
      -46.384,
      94.164,
      5.573,
      -46.384,
      94.164,
      8.023,
      -46.183,
      94.761,
      8.023,
      -46.183,
      94.761,
      8.023,
      -46.183,
      94.761,
      5.573,
      -46.384,
      94.164,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5076",
    "polygon": [
      -46.939,
      92.505,
      5.573,
      -46.939,
      92.505,
      8.023,
      -46.384,
      94.164,
      8.023,
      -46.384,
      94.164,
      8.023,
      -46.384,
      94.164,
      5.573,
      -46.939,
      92.505,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5077",
    "polygon": [
      -47.124,
      91.955,
      5.573,
      -47.124,
      91.955,
      8.023,
      -46.939,
      92.505,
      8.023,
      -46.939,
      92.505,
      8.023,
      -46.939,
      92.505,
      5.573,
      -47.124,
      91.955,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5078",
    "polygon": [
      -47.355,
      91.265,
      5.573,
      -47.355,
      91.265,
      8.023,
      -47.117,
      91.976,
      8.023,
      -47.117,
      91.976,
      8.023,
      -47.117,
      91.976,
      5.573,
      -47.355,
      91.265,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5079",
    "polygon": [
      -47.862,
      89.75,
      5.573,
      -47.862,
      89.75,
      8.023,
      -47.351,
      91.277,
      8.023,
      -47.351,
      91.277,
      8.023,
      -47.351,
      91.277,
      5.573,
      -47.862,
      89.75,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5080",
    "polygon": [
      -48.369,
      88.237,
      5.573,
      -48.369,
      88.237,
      8.023,
      -48.169,
      88.834,
      8.023,
      -48.169,
      88.834,
      8.023,
      -48.169,
      88.834,
      5.573,
      -48.369,
      88.237,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5081",
    "polygon": [
      -48.925,
      86.578,
      5.573,
      -48.925,
      86.578,
      8.023,
      -48.369,
      88.237,
      8.023,
      -48.369,
      88.237,
      8.023,
      -48.369,
      88.237,
      5.573,
      -48.925,
      86.578,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5082",
    "polygon": [
      -49.109,
      86.028,
      5.573,
      -49.109,
      86.028,
      8.023,
      -48.925,
      86.578,
      8.023,
      -48.925,
      86.578,
      8.023,
      -48.925,
      86.578,
      5.573,
      -49.109,
      86.028,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5083",
    "polygon": [
      -49.34,
      85.338,
      5.573,
      -49.34,
      85.338,
      8.023,
      -49.102,
      86.049,
      8.023,
      -49.102,
      86.049,
      8.023,
      -49.102,
      86.049,
      5.573,
      -49.34,
      85.338,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5084",
    "polygon": [
      -49.848,
      83.823,
      5.573,
      -49.848,
      83.823,
      8.023,
      -49.336,
      85.35,
      8.023,
      -49.336,
      85.35,
      8.023,
      -49.336,
      85.35,
      5.573,
      -49.848,
      83.823,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5085",
    "polygon": [
      -50.354,
      82.312,
      5.573,
      -50.354,
      82.312,
      8.023,
      -50.154,
      82.909,
      8.023,
      -50.154,
      82.909,
      8.023,
      -50.154,
      82.909,
      5.573,
      -50.354,
      82.312,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5086",
    "polygon": [
      -50.91,
      80.652,
      5.573,
      -50.91,
      80.652,
      8.023,
      -50.354,
      82.312,
      8.023,
      -50.354,
      82.312,
      8.023,
      -50.354,
      82.312,
      5.573,
      -50.91,
      80.652,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5087",
    "polygon": [
      -51.094,
      80.102,
      5.573,
      -51.094,
      80.102,
      8.023,
      -50.91,
      80.652,
      8.023,
      -50.91,
      80.652,
      8.023,
      -50.91,
      80.652,
      5.573,
      -51.094,
      80.102,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5088",
    "polygon": [
      -51.325,
      79.412,
      5.573,
      -51.325,
      79.412,
      8.023,
      -51.087,
      80.124,
      8.023,
      -51.087,
      80.124,
      8.023,
      -51.087,
      80.124,
      5.573,
      -51.325,
      79.412,
      5.573
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_5089",
    "polygon": [
      -51.833,
      77.898,
      5.573,
      -51.833,
      77.898,
      8.023,
      -51.321,
      79.424,
      8.023,
      -51.321,
      79.424,
      8.023,
      -51.321,
      79.424,
      5.573,
      -51.833,
      77.898,
      5.573
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5095",
    "polygon": [
      -93.962,
      74.167,
      5.583,
      -93.962,
      74.167,
      8.033,
      -93.761,
      74.765,
      8.033,
      -93.761,
      74.765,
      8.033,
      -93.761,
      74.765,
      5.583,
      -93.962,
      74.167,
      5.583
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5096",
    "polygon": [
      -94.522,
      72.496,
      5.577,
      -94.522,
      72.496,
      8.027,
      -93.966,
      74.155,
      8.027,
      -93.966,
      74.155,
      8.027,
      -93.966,
      74.155,
      5.577,
      -94.522,
      72.496,
      5.577
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5097",
    "polygon": [
      -94.706,
      71.946,
      5.569,
      -94.706,
      71.946,
      8.019,
      -94.522,
      72.496,
      8.019,
      -94.522,
      72.496,
      8.019,
      -94.522,
      72.496,
      5.569,
      -94.706,
      71.946,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5098",
    "polygon": [
      -94.937,
      71.256,
      5.569,
      -94.937,
      71.256,
      8.019,
      -94.699,
      71.967,
      8.019,
      -94.699,
      71.967,
      8.019,
      -94.699,
      71.967,
      5.569,
      -94.937,
      71.256,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5099",
    "polygon": [
      -95.444,
      69.741,
      5.572,
      -95.444,
      69.741,
      8.022,
      -94.933,
      71.268,
      8.022,
      -94.933,
      71.268,
      8.022,
      -94.933,
      71.268,
      5.572,
      -95.444,
      69.741,
      5.572
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5100",
    "polygon": [
      -95.945,
      68.245,
      5.583,
      -95.945,
      68.245,
      8.033,
      -95.745,
      68.843,
      8.033,
      -95.745,
      68.843,
      8.033,
      -95.745,
      68.843,
      5.583,
      -95.945,
      68.245,
      5.583
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5101",
    "polygon": [
      -96.505,
      66.574,
      5.577,
      -96.505,
      66.574,
      8.027,
      -95.95,
      68.233,
      8.027,
      -95.95,
      68.233,
      8.027,
      -95.95,
      68.233,
      5.577,
      -96.505,
      66.574,
      5.577
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5102",
    "polygon": [
      -96.69,
      66.024,
      5.569,
      -96.69,
      66.024,
      8.019,
      -96.505,
      66.574,
      8.019,
      -96.505,
      66.574,
      8.019,
      -96.505,
      66.574,
      5.569,
      -96.69,
      66.024,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5103",
    "polygon": [
      -96.921,
      65.334,
      5.569,
      -96.921,
      65.334,
      8.019,
      -96.682,
      66.045,
      8.019,
      -96.682,
      66.045,
      8.019,
      -96.682,
      66.045,
      5.569,
      -96.921,
      65.334,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5104",
    "polygon": [
      -97.428,
      63.819,
      5.572,
      -97.428,
      63.819,
      8.022,
      -96.917,
      65.346,
      8.022,
      -96.917,
      65.346,
      8.022,
      -96.917,
      65.346,
      5.572,
      -97.428,
      63.819,
      5.572
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5105",
    "polygon": [
      -97.935,
      62.305,
      5.583,
      -97.935,
      62.305,
      8.033,
      -97.735,
      62.902,
      8.033,
      -97.735,
      62.902,
      8.033,
      -97.735,
      62.902,
      5.583,
      -97.935,
      62.305,
      5.583
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5106",
    "polygon": [
      -98.495,
      60.633,
      5.577,
      -98.495,
      60.633,
      8.027,
      -97.939,
      62.293,
      8.027,
      -97.939,
      62.293,
      8.027,
      -97.939,
      62.293,
      5.577,
      -98.495,
      60.633,
      5.577
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5107",
    "polygon": [
      -98.68,
      60.083,
      5.569,
      -98.68,
      60.083,
      8.019,
      -98.495,
      60.633,
      8.019,
      -98.495,
      60.633,
      8.019,
      -98.495,
      60.633,
      5.569,
      -98.68,
      60.083,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5108",
    "polygon": [
      -98.911,
      59.394,
      5.569,
      -98.911,
      59.394,
      8.019,
      -98.672,
      60.105,
      8.019,
      -98.672,
      60.105,
      8.019,
      -98.672,
      60.105,
      5.569,
      -98.911,
      59.394,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5109",
    "polygon": [
      -99.418,
      57.879,
      5.572,
      -99.418,
      57.879,
      8.022,
      -98.907,
      59.405,
      8.022,
      -98.907,
      59.405,
      8.022,
      -98.907,
      59.405,
      5.572,
      -99.418,
      57.879,
      5.572
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5110",
    "polygon": [
      -99.918,
      56.386,
      5.583,
      -99.918,
      56.386,
      8.033,
      -99.718,
      56.983,
      8.033,
      -99.718,
      56.983,
      8.033,
      -99.718,
      56.983,
      5.583,
      -99.918,
      56.386,
      5.583
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5111",
    "polygon": [
      -100.478,
      54.714,
      5.577,
      -100.478,
      54.714,
      8.027,
      -99.922,
      56.374,
      8.027,
      -99.922,
      56.374,
      8.027,
      -99.922,
      56.374,
      5.577,
      -100.478,
      54.714,
      5.577
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5112",
    "polygon": [
      -100.662,
      54.164,
      5.569,
      -100.662,
      54.164,
      8.019,
      -100.478,
      54.714,
      8.019,
      -100.478,
      54.714,
      8.019,
      -100.478,
      54.714,
      5.569,
      -100.662,
      54.164,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5113",
    "polygon": [
      -100.893,
      53.474,
      5.569,
      -100.893,
      53.474,
      8.019,
      -100.655,
      54.186,
      8.019,
      -100.655,
      54.186,
      8.019,
      -100.655,
      54.186,
      5.569,
      -100.893,
      53.474,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5114",
    "polygon": [
      -101.401,
      51.96,
      5.572,
      -101.401,
      51.96,
      8.022,
      -100.889,
      53.486,
      8.022,
      -100.889,
      53.486,
      8.022,
      -100.889,
      53.486,
      5.572,
      -101.401,
      51.96,
      5.572
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5115",
    "polygon": [
      -101.902,
      50.462,
      5.583,
      -101.902,
      50.462,
      8.033,
      -101.702,
      51.06,
      8.033,
      -101.702,
      51.06,
      8.033,
      -101.702,
      51.06,
      5.583,
      -101.902,
      50.462,
      5.583
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5116",
    "polygon": [
      -102.462,
      48.79,
      5.577,
      -102.462,
      48.79,
      8.027,
      -101.907,
      50.45,
      8.027,
      -101.907,
      50.45,
      8.027,
      -101.907,
      50.45,
      5.577,
      -102.462,
      48.79,
      5.577
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5117",
    "polygon": [
      -102.647,
      48.241,
      5.569,
      -102.647,
      48.241,
      8.019,
      -102.462,
      48.79,
      8.019,
      -102.462,
      48.79,
      8.019,
      -102.462,
      48.79,
      5.569,
      -102.647,
      48.241,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5118",
    "polygon": [
      -102.878,
      47.551,
      5.569,
      -102.878,
      47.551,
      8.019,
      -102.64,
      48.262,
      8.019,
      -102.64,
      48.262,
      8.019,
      -102.64,
      48.262,
      5.569,
      -102.878,
      47.551,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5119",
    "polygon": [
      -103.385,
      46.036,
      5.572,
      -103.385,
      46.036,
      8.022,
      -102.874,
      47.563,
      8.022,
      -102.874,
      47.563,
      8.022,
      -102.874,
      47.563,
      5.572,
      -103.385,
      46.036,
      5.572
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5120",
    "polygon": [
      -103.885,
      44.543,
      5.583,
      -103.885,
      44.543,
      8.033,
      -103.685,
      45.141,
      8.033,
      -103.685,
      45.141,
      8.033,
      -103.685,
      45.141,
      5.583,
      -103.885,
      44.543,
      5.583
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5121",
    "polygon": [
      -104.445,
      42.871,
      5.577,
      -104.445,
      42.871,
      8.027,
      -103.889,
      44.531,
      8.027,
      -103.889,
      44.531,
      8.027,
      -103.889,
      44.531,
      5.577,
      -104.445,
      42.871,
      5.577
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5122",
    "polygon": [
      -104.63,
      42.322,
      5.569,
      -104.63,
      42.322,
      8.019,
      -104.445,
      42.871,
      8.019,
      -104.445,
      42.871,
      8.019,
      -104.445,
      42.871,
      5.569,
      -104.63,
      42.322,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5123",
    "polygon": [
      -104.861,
      41.632,
      5.569,
      -104.861,
      41.632,
      8.019,
      -104.622,
      42.343,
      8.019,
      -104.622,
      42.343,
      8.019,
      -104.622,
      42.343,
      5.569,
      -104.861,
      41.632,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5124",
    "polygon": [
      -105.368,
      40.117,
      5.572,
      -105.368,
      40.117,
      8.022,
      -104.857,
      41.644,
      8.022,
      -104.857,
      41.644,
      8.022,
      -104.857,
      41.644,
      5.572,
      -105.368,
      40.117,
      5.572
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5125",
    "polygon": [
      -105.877,
      38.598,
      5.583,
      -105.877,
      38.598,
      8.033,
      -105.677,
      39.195,
      8.033,
      -105.677,
      39.195,
      8.033,
      -105.677,
      39.195,
      5.583,
      -105.877,
      38.598,
      5.583
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5126",
    "polygon": [
      -106.437,
      36.926,
      5.577,
      -106.437,
      36.926,
      8.027,
      -105.881,
      38.585,
      8.027,
      -105.881,
      38.585,
      8.027,
      -105.881,
      38.585,
      5.577,
      -106.437,
      36.926,
      5.577
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5127",
    "polygon": [
      -106.621,
      36.376,
      5.569,
      -106.621,
      36.376,
      8.019,
      -106.437,
      36.926,
      8.019,
      -106.437,
      36.926,
      8.019,
      -106.437,
      36.926,
      5.569,
      -106.621,
      36.376,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5128",
    "polygon": [
      -106.852,
      35.686,
      5.569,
      -106.852,
      35.686,
      8.019,
      -106.614,
      36.397,
      8.019,
      -106.614,
      36.397,
      8.019,
      -106.614,
      36.397,
      5.569,
      -106.852,
      35.686,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5129",
    "polygon": [
      -107.36,
      34.171,
      5.572,
      -107.36,
      34.171,
      8.022,
      -106.848,
      35.698,
      8.022,
      -106.848,
      35.698,
      8.022,
      -106.848,
      35.698,
      5.572,
      -107.36,
      34.171,
      5.572
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5130",
    "polygon": [
      -107.857,
      32.688,
      5.583,
      -107.857,
      32.688,
      8.033,
      -107.657,
      33.285,
      8.033,
      -107.657,
      33.285,
      8.033,
      -107.657,
      33.285,
      5.583,
      -107.857,
      32.688,
      5.583
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5131",
    "polygon": [
      -108.417,
      31.016,
      5.577,
      -108.417,
      31.016,
      8.027,
      -107.861,
      32.675,
      8.027,
      -107.861,
      32.675,
      8.027,
      -107.861,
      32.675,
      5.577,
      -108.417,
      31.016,
      5.577
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5132",
    "polygon": [
      -108.601,
      30.466,
      5.569,
      -108.601,
      30.466,
      8.019,
      -108.417,
      31.016,
      8.019,
      -108.417,
      31.016,
      8.019,
      -108.417,
      31.016,
      5.569,
      -108.601,
      30.466,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5133",
    "polygon": [
      -108.832,
      29.776,
      5.569,
      -108.832,
      29.776,
      8.019,
      -108.594,
      30.487,
      8.019,
      -108.594,
      30.487,
      8.019,
      -108.594,
      30.487,
      5.569,
      -108.832,
      29.776,
      5.569
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_1.OG_5134",
    "polygon": [
      -109.339,
      28.261,
      5.572,
      -109.339,
      28.261,
      8.022,
      -108.828,
      29.788,
      8.022,
      -108.828,
      29.788,
      8.022,
      -108.828,
      29.788,
      5.572,
      -109.339,
      28.261,
      5.572
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5135",
    "polygon": [
      -36.454,
      123.797,
      9.323,
      -36.454,
      123.797,
      11.773,
      -36.254,
      124.394,
      11.773,
      -36.254,
      124.394,
      11.773,
      -36.254,
      124.394,
      9.323,
      -36.454,
      123.797,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5136",
    "polygon": [
      -37.01,
      122.138,
      9.323,
      -37.01,
      122.138,
      11.773,
      -36.454,
      123.797,
      11.773,
      -36.454,
      123.797,
      11.773,
      -36.454,
      123.797,
      9.323,
      -37.01,
      122.138,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5137",
    "polygon": [
      -37.194,
      121.588,
      9.323,
      -37.194,
      121.588,
      11.773,
      -37.01,
      122.138,
      11.773,
      -37.01,
      122.138,
      11.773,
      -37.01,
      122.138,
      9.323,
      -37.194,
      121.588,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5138",
    "polygon": [
      -37.425,
      120.898,
      9.323,
      -37.425,
      120.898,
      11.773,
      -37.187,
      121.609,
      11.773,
      -37.187,
      121.609,
      11.773,
      -37.187,
      121.609,
      9.323,
      -37.425,
      120.898,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5139",
    "polygon": [
      -37.933,
      119.383,
      9.323,
      -37.933,
      119.383,
      11.773,
      -37.421,
      120.91,
      11.773,
      -37.421,
      120.91,
      11.773,
      -37.421,
      120.91,
      9.323,
      -37.933,
      119.383,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5140",
    "polygon": [
      -38.44,
      117.868,
      9.323,
      -38.44,
      117.868,
      11.773,
      -38.24,
      118.466,
      11.773,
      -38.24,
      118.466,
      11.773,
      -38.24,
      118.466,
      9.323,
      -38.44,
      117.868,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5141",
    "polygon": [
      -38.996,
      116.209,
      9.323,
      -38.996,
      116.209,
      11.773,
      -38.44,
      117.868,
      11.773,
      -38.44,
      117.868,
      11.773,
      -38.44,
      117.868,
      9.323,
      -38.996,
      116.209,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5142",
    "polygon": [
      -39.18,
      115.659,
      9.323,
      -39.18,
      115.659,
      11.773,
      -38.996,
      116.209,
      11.773,
      -38.996,
      116.209,
      11.773,
      -38.996,
      116.209,
      9.323,
      -39.18,
      115.659,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5143",
    "polygon": [
      -39.411,
      114.969,
      9.323,
      -39.411,
      114.969,
      11.773,
      -39.173,
      115.68,
      11.773,
      -39.173,
      115.68,
      11.773,
      -39.173,
      115.68,
      9.323,
      -39.411,
      114.969,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5144",
    "polygon": [
      -39.919,
      113.454,
      9.323,
      -39.919,
      113.454,
      11.773,
      -39.407,
      114.981,
      11.773,
      -39.407,
      114.981,
      11.773,
      -39.407,
      114.981,
      9.323,
      -39.919,
      113.454,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5145",
    "polygon": [
      -40.428,
      111.933,
      9.323,
      -40.428,
      111.933,
      11.773,
      -40.228,
      112.53,
      11.773,
      -40.228,
      112.53,
      11.773,
      -40.228,
      112.53,
      9.323,
      -40.428,
      111.933,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5146",
    "polygon": [
      -40.984,
      110.273,
      9.323,
      -40.984,
      110.273,
      11.773,
      -40.428,
      111.933,
      11.773,
      -40.428,
      111.933,
      11.773,
      -40.428,
      111.933,
      9.323,
      -40.984,
      110.273,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5147",
    "polygon": [
      -41.169,
      109.723,
      9.323,
      -41.169,
      109.723,
      11.773,
      -40.984,
      110.273,
      11.773,
      -40.984,
      110.273,
      11.773,
      -40.984,
      110.273,
      9.323,
      -41.169,
      109.723,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5148",
    "polygon": [
      -41.4,
      109.033,
      9.323,
      -41.4,
      109.033,
      11.773,
      -41.161,
      109.745,
      11.773,
      -41.161,
      109.745,
      11.773,
      -41.161,
      109.745,
      9.323,
      -41.4,
      109.033,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5149",
    "polygon": [
      -41.907,
      107.519,
      9.323,
      -41.907,
      107.519,
      11.773,
      -41.396,
      109.045,
      11.773,
      -41.396,
      109.045,
      11.773,
      -41.396,
      109.045,
      9.323,
      -41.907,
      107.519,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5150",
    "polygon": [
      -42.421,
      105.984,
      9.323,
      -42.421,
      105.984,
      11.773,
      -42.221,
      106.581,
      11.773,
      -42.221,
      106.581,
      11.773,
      -42.221,
      106.581,
      9.323,
      -42.421,
      105.984,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5151",
    "polygon": [
      -42.977,
      104.325,
      9.323,
      -42.977,
      104.325,
      11.773,
      -42.421,
      105.984,
      11.773,
      -42.421,
      105.984,
      11.773,
      -42.421,
      105.984,
      9.323,
      -42.977,
      104.325,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5152",
    "polygon": [
      -43.161,
      103.775,
      9.323,
      -43.161,
      103.775,
      11.773,
      -42.977,
      104.325,
      11.773,
      -42.977,
      104.325,
      11.773,
      -42.977,
      104.325,
      9.323,
      -43.161,
      103.775,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5153",
    "polygon": [
      -43.392,
      103.085,
      9.323,
      -43.392,
      103.085,
      11.773,
      -43.154,
      103.796,
      11.773,
      -43.154,
      103.796,
      11.773,
      -43.154,
      103.796,
      9.323,
      -43.392,
      103.085,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5154",
    "polygon": [
      -43.9,
      101.57,
      9.323,
      -43.9,
      101.57,
      11.773,
      -43.388,
      103.097,
      11.773,
      -43.388,
      103.097,
      11.773,
      -43.388,
      103.097,
      9.323,
      -43.9,
      101.57,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5155",
    "polygon": [
      -44.397,
      100.085,
      9.323,
      -44.397,
      100.085,
      11.773,
      -44.197,
      100.683,
      11.773,
      -44.197,
      100.683,
      11.773,
      -44.197,
      100.683,
      9.323,
      -44.397,
      100.085,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5156",
    "polygon": [
      -44.953,
      98.426,
      9.323,
      -44.953,
      98.426,
      11.773,
      -44.397,
      100.085,
      11.773,
      -44.397,
      100.085,
      11.773,
      -44.397,
      100.085,
      9.323,
      -44.953,
      98.426,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5157",
    "polygon": [
      -45.137,
      97.876,
      9.323,
      -45.137,
      97.876,
      11.773,
      -44.953,
      98.426,
      11.773,
      -44.953,
      98.426,
      11.773,
      -44.953,
      98.426,
      9.323,
      -45.137,
      97.876,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5158",
    "polygon": [
      -45.368,
      97.186,
      9.323,
      -45.368,
      97.186,
      11.773,
      -45.13,
      97.897,
      11.773,
      -45.13,
      97.897,
      11.773,
      -45.13,
      97.897,
      9.323,
      -45.368,
      97.186,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5159",
    "polygon": [
      -45.876,
      95.671,
      9.323,
      -45.876,
      95.671,
      11.773,
      -45.364,
      97.198,
      11.773,
      -45.364,
      97.198,
      11.773,
      -45.364,
      97.198,
      9.323,
      -45.876,
      95.671,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5160",
    "polygon": [
      -46.381,
      94.163,
      9.323,
      -46.381,
      94.163,
      11.773,
      -46.181,
      94.761,
      11.773,
      -46.181,
      94.761,
      11.773,
      -46.181,
      94.761,
      9.323,
      -46.381,
      94.163,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5161",
    "polygon": [
      -46.937,
      92.504,
      9.323,
      -46.937,
      92.504,
      11.773,
      -46.381,
      94.163,
      11.773,
      -46.381,
      94.163,
      11.773,
      -46.381,
      94.163,
      9.323,
      -46.937,
      92.504,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5162",
    "polygon": [
      -47.121,
      91.954,
      9.323,
      -47.121,
      91.954,
      11.773,
      -46.937,
      92.504,
      11.773,
      -46.937,
      92.504,
      11.773,
      -46.937,
      92.504,
      9.323,
      -47.121,
      91.954,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5163",
    "polygon": [
      -47.352,
      91.264,
      9.323,
      -47.352,
      91.264,
      11.773,
      -47.114,
      91.975,
      11.773,
      -47.114,
      91.975,
      11.773,
      -47.114,
      91.975,
      9.323,
      -47.352,
      91.264,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5164",
    "polygon": [
      -47.86,
      89.749,
      9.323,
      -47.86,
      89.749,
      11.773,
      -47.348,
      91.276,
      11.773,
      -47.348,
      91.276,
      11.773,
      -47.348,
      91.276,
      9.323,
      -47.86,
      89.749,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5165",
    "polygon": [
      -48.366,
      88.236,
      9.323,
      -48.366,
      88.236,
      11.773,
      -48.166,
      88.833,
      11.773,
      -48.166,
      88.833,
      11.773,
      -48.166,
      88.833,
      9.323,
      -48.366,
      88.236,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5166",
    "polygon": [
      -48.922,
      86.577,
      9.323,
      -48.922,
      86.577,
      11.773,
      -48.366,
      88.236,
      11.773,
      -48.366,
      88.236,
      11.773,
      -48.366,
      88.236,
      9.323,
      -48.922,
      86.577,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5167",
    "polygon": [
      -49.107,
      86.027,
      9.323,
      -49.107,
      86.027,
      11.773,
      -48.922,
      86.577,
      11.773,
      -48.922,
      86.577,
      11.773,
      -48.922,
      86.577,
      9.323,
      -49.107,
      86.027,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5168",
    "polygon": [
      -49.338,
      85.337,
      9.323,
      -49.338,
      85.337,
      11.773,
      -49.099,
      86.048,
      11.773,
      -49.099,
      86.048,
      11.773,
      -49.099,
      86.048,
      9.323,
      -49.338,
      85.337,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5169",
    "polygon": [
      -49.845,
      83.822,
      9.323,
      -49.845,
      83.822,
      11.773,
      -49.334,
      85.349,
      11.773,
      -49.334,
      85.349,
      11.773,
      -49.334,
      85.349,
      9.323,
      -49.845,
      83.822,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5170",
    "polygon": [
      -50.351,
      82.311,
      9.323,
      -50.351,
      82.311,
      11.773,
      -50.151,
      82.908,
      11.773,
      -50.151,
      82.908,
      11.773,
      -50.151,
      82.908,
      9.323,
      -50.351,
      82.311,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5171",
    "polygon": [
      -50.907,
      80.651,
      9.323,
      -50.907,
      80.651,
      11.773,
      -50.351,
      82.311,
      11.773,
      -50.351,
      82.311,
      11.773,
      -50.351,
      82.311,
      9.323,
      -50.907,
      80.651,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5172",
    "polygon": [
      -51.091,
      80.101,
      9.323,
      -51.091,
      80.101,
      11.773,
      -50.907,
      80.651,
      11.773,
      -50.907,
      80.651,
      11.773,
      -50.907,
      80.651,
      9.323,
      -51.091,
      80.101,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5173",
    "polygon": [
      -51.323,
      79.411,
      9.323,
      -51.323,
      79.411,
      11.773,
      -51.084,
      80.123,
      11.773,
      -51.084,
      80.123,
      11.773,
      -51.084,
      80.123,
      9.323,
      -51.323,
      79.411,
      9.323
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_2.OG_5174",
    "polygon": [
      -51.83,
      77.897,
      9.323,
      -51.83,
      77.897,
      11.773,
      -51.319,
      79.423,
      11.773,
      -51.319,
      79.423,
      11.773,
      -51.319,
      79.423,
      9.323,
      -51.83,
      77.897,
      9.323
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5175",
    "polygon": [
      -36.442,
      123.793,
      13.073,
      -36.442,
      123.793,
      15.523,
      -36.242,
      124.391,
      15.523,
      -36.242,
      124.391,
      15.523,
      -36.242,
      124.391,
      13.073,
      -36.442,
      123.793,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5176",
    "polygon": [
      -36.998,
      122.134,
      13.073,
      -36.998,
      122.134,
      15.523,
      -36.442,
      123.793,
      15.523,
      -36.442,
      123.793,
      15.523,
      -36.442,
      123.793,
      13.073,
      -36.998,
      122.134,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5177",
    "polygon": [
      -37.182,
      121.584,
      13.073,
      -37.182,
      121.584,
      15.523,
      -36.998,
      122.134,
      15.523,
      -36.998,
      122.134,
      15.523,
      -36.998,
      122.134,
      13.073,
      -37.182,
      121.584,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5178",
    "polygon": [
      -37.414,
      120.894,
      13.073,
      -37.414,
      120.894,
      15.523,
      -37.175,
      121.605,
      15.523,
      -37.175,
      121.605,
      15.523,
      -37.175,
      121.605,
      13.073,
      -37.414,
      120.894,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5179",
    "polygon": [
      -37.921,
      119.379,
      13.073,
      -37.921,
      119.379,
      15.523,
      -37.41,
      120.906,
      15.523,
      -37.41,
      120.906,
      15.523,
      -37.41,
      120.906,
      13.073,
      -37.921,
      119.379,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5180",
    "polygon": [
      -38.428,
      117.864,
      13.073,
      -38.428,
      117.864,
      15.523,
      -38.228,
      118.462,
      15.523,
      -38.228,
      118.462,
      15.523,
      -38.228,
      118.462,
      13.073,
      -38.428,
      117.864,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5181",
    "polygon": [
      -38.984,
      116.205,
      13.073,
      -38.984,
      116.205,
      15.523,
      -38.428,
      117.864,
      15.523,
      -38.428,
      117.864,
      15.523,
      -38.428,
      117.864,
      13.073,
      -38.984,
      116.205,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5182",
    "polygon": [
      -39.169,
      115.655,
      13.073,
      -39.169,
      115.655,
      15.523,
      -38.984,
      116.205,
      15.523,
      -38.984,
      116.205,
      15.523,
      -38.984,
      116.205,
      13.073,
      -39.169,
      115.655,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5183",
    "polygon": [
      -39.4,
      114.965,
      13.073,
      -39.4,
      114.965,
      15.523,
      -39.161,
      115.676,
      15.523,
      -39.161,
      115.676,
      15.523,
      -39.161,
      115.676,
      13.073,
      -39.4,
      114.965,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5184",
    "polygon": [
      -39.907,
      113.451,
      13.073,
      -39.907,
      113.451,
      15.523,
      -39.396,
      114.977,
      15.523,
      -39.396,
      114.977,
      15.523,
      -39.396,
      114.977,
      13.073,
      -39.907,
      113.451,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5185",
    "polygon": [
      -40.417,
      111.929,
      13.073,
      -40.417,
      111.929,
      15.523,
      -40.217,
      112.526,
      15.523,
      -40.217,
      112.526,
      15.523,
      -40.217,
      112.526,
      13.073,
      -40.417,
      111.929,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5186",
    "polygon": [
      -40.973,
      110.269,
      13.073,
      -40.973,
      110.269,
      15.523,
      -40.417,
      111.929,
      15.523,
      -40.417,
      111.929,
      15.523,
      -40.417,
      111.929,
      13.073,
      -40.973,
      110.269,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5187",
    "polygon": [
      -41.157,
      109.719,
      13.073,
      -41.157,
      109.719,
      15.523,
      -40.973,
      110.269,
      15.523,
      -40.973,
      110.269,
      15.523,
      -40.973,
      110.269,
      13.073,
      -41.157,
      109.719,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5188",
    "polygon": [
      -41.388,
      109.03,
      13.073,
      -41.388,
      109.03,
      15.523,
      -41.15,
      109.741,
      15.523,
      -41.15,
      109.741,
      15.523,
      -41.15,
      109.741,
      13.073,
      -41.388,
      109.03,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5189",
    "polygon": [
      -41.895,
      107.515,
      13.073,
      -41.895,
      107.515,
      15.523,
      -41.384,
      109.041,
      15.523,
      -41.384,
      109.041,
      15.523,
      -41.384,
      109.041,
      13.073,
      -41.895,
      107.515,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5190",
    "polygon": [
      -42.409,
      105.98,
      13.073,
      -42.409,
      105.98,
      15.523,
      -42.209,
      106.578,
      15.523,
      -42.209,
      106.578,
      15.523,
      -42.209,
      106.578,
      13.073,
      -42.409,
      105.98,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5191",
    "polygon": [
      -42.965,
      104.321,
      13.073,
      -42.965,
      104.321,
      15.523,
      -42.409,
      105.98,
      15.523,
      -42.409,
      105.98,
      15.523,
      -42.409,
      105.98,
      13.073,
      -42.965,
      104.321,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5192",
    "polygon": [
      -43.15,
      103.771,
      13.073,
      -43.15,
      103.771,
      15.523,
      -42.965,
      104.321,
      15.523,
      -42.965,
      104.321,
      15.523,
      -42.965,
      104.321,
      13.073,
      -43.15,
      103.771,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5193",
    "polygon": [
      -43.381,
      103.081,
      13.073,
      -43.381,
      103.081,
      15.523,
      -43.142,
      103.792,
      15.523,
      -43.142,
      103.792,
      15.523,
      -43.142,
      103.792,
      13.073,
      -43.381,
      103.081,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5194",
    "polygon": [
      -43.888,
      101.566,
      13.073,
      -43.888,
      101.566,
      15.523,
      -43.377,
      103.093,
      15.523,
      -43.377,
      103.093,
      15.523,
      -43.377,
      103.093,
      13.073,
      -43.888,
      101.566,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5195",
    "polygon": [
      -44.385,
      100.081,
      13.073,
      -44.385,
      100.081,
      15.523,
      -44.185,
      100.679,
      15.523,
      -44.185,
      100.679,
      15.523,
      -44.185,
      100.679,
      13.073,
      -44.385,
      100.081,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5196",
    "polygon": [
      -44.941,
      98.422,
      13.073,
      -44.941,
      98.422,
      15.523,
      -44.385,
      100.081,
      15.523,
      -44.385,
      100.081,
      15.523,
      -44.385,
      100.081,
      13.073,
      -44.941,
      98.422,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5197",
    "polygon": [
      -45.126,
      97.872,
      13.073,
      -45.126,
      97.872,
      15.523,
      -44.941,
      98.422,
      15.523,
      -44.941,
      98.422,
      15.523,
      -44.941,
      98.422,
      13.073,
      -45.126,
      97.872,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5198",
    "polygon": [
      -45.357,
      97.182,
      13.073,
      -45.357,
      97.182,
      15.523,
      -45.118,
      97.893,
      15.523,
      -45.118,
      97.893,
      15.523,
      -45.118,
      97.893,
      13.073,
      -45.357,
      97.182,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5199",
    "polygon": [
      -45.864,
      95.667,
      13.073,
      -45.864,
      95.667,
      15.523,
      -45.353,
      97.194,
      15.523,
      -45.353,
      97.194,
      15.523,
      -45.353,
      97.194,
      13.073,
      -45.864,
      95.667,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5200",
    "polygon": [
      -46.369,
      94.159,
      13.073,
      -46.369,
      94.159,
      15.523,
      -46.169,
      94.757,
      15.523,
      -46.169,
      94.757,
      15.523,
      -46.169,
      94.757,
      13.073,
      -46.369,
      94.159,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5201",
    "polygon": [
      -46.925,
      92.5,
      13.073,
      -46.925,
      92.5,
      15.523,
      -46.369,
      94.159,
      15.523,
      -46.369,
      94.159,
      15.523,
      -46.369,
      94.159,
      13.073,
      -46.925,
      92.5,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5202",
    "polygon": [
      -47.109,
      91.95,
      13.073,
      -47.109,
      91.95,
      15.523,
      -46.925,
      92.5,
      15.523,
      -46.925,
      92.5,
      15.523,
      -46.925,
      92.5,
      13.073,
      -47.109,
      91.95,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5203",
    "polygon": [
      -47.34,
      91.26,
      13.073,
      -47.34,
      91.26,
      15.523,
      -47.102,
      91.971,
      15.523,
      -47.102,
      91.971,
      15.523,
      -47.102,
      91.971,
      13.073,
      -47.34,
      91.26,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5204",
    "polygon": [
      -47.848,
      89.745,
      13.073,
      -47.848,
      89.745,
      15.523,
      -47.337,
      91.272,
      15.523,
      -47.337,
      91.272,
      15.523,
      -47.337,
      91.272,
      13.073,
      -47.848,
      89.745,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5205",
    "polygon": [
      -48.355,
      88.232,
      13.073,
      -48.355,
      88.232,
      15.523,
      -48.155,
      88.829,
      15.523,
      -48.155,
      88.829,
      15.523,
      -48.155,
      88.829,
      13.073,
      -48.355,
      88.232,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5206",
    "polygon": [
      -48.911,
      86.573,
      13.073,
      -48.911,
      86.573,
      15.523,
      -48.355,
      88.232,
      15.523,
      -48.355,
      88.232,
      15.523,
      -48.355,
      88.232,
      13.073,
      -48.911,
      86.573,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5207",
    "polygon": [
      -49.095,
      86.023,
      13.073,
      -49.095,
      86.023,
      15.523,
      -48.911,
      86.573,
      15.523,
      -48.911,
      86.573,
      15.523,
      -48.911,
      86.573,
      13.073,
      -49.095,
      86.023,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5208",
    "polygon": [
      -49.326,
      85.333,
      13.073,
      -49.326,
      85.333,
      15.523,
      -49.088,
      86.044,
      15.523,
      -49.088,
      86.044,
      15.523,
      -49.088,
      86.044,
      13.073,
      -49.326,
      85.333,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5209",
    "polygon": [
      -49.833,
      83.818,
      13.073,
      -49.833,
      83.818,
      15.523,
      -49.322,
      85.345,
      15.523,
      -49.322,
      85.345,
      15.523,
      -49.322,
      85.345,
      13.073,
      -49.833,
      83.818,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5210",
    "polygon": [
      -50.34,
      82.307,
      13.073,
      -50.34,
      82.307,
      15.523,
      -50.14,
      82.904,
      15.523,
      -50.14,
      82.904,
      15.523,
      -50.14,
      82.904,
      13.073,
      -50.34,
      82.307,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5211",
    "polygon": [
      -50.896,
      80.647,
      13.073,
      -50.896,
      80.647,
      15.523,
      -50.34,
      82.307,
      15.523,
      -50.34,
      82.307,
      15.523,
      -50.34,
      82.307,
      13.073,
      -50.896,
      80.647,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5212",
    "polygon": [
      -51.08,
      80.097,
      13.073,
      -51.08,
      80.097,
      15.523,
      -50.896,
      80.647,
      15.523,
      -50.896,
      80.647,
      15.523,
      -50.896,
      80.647,
      13.073,
      -51.08,
      80.097,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5213",
    "polygon": [
      -51.311,
      79.408,
      13.073,
      -51.311,
      79.408,
      15.523,
      -51.073,
      80.119,
      15.523,
      -51.073,
      80.119,
      15.523,
      -51.073,
      80.119,
      13.073,
      -51.311,
      79.408,
      13.073
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_3.OG_5214",
    "polygon": [
      -51.818,
      77.893,
      13.073,
      -51.818,
      77.893,
      15.523,
      -51.307,
      79.419,
      15.523,
      -51.307,
      79.419,
      15.523,
      -51.307,
      79.419,
      13.073,
      -51.818,
      77.893,
      13.073
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5215",
    "polygon": [
      -93.959,
      74.167,
      9.333,
      -93.959,
      74.167,
      11.783,
      -93.759,
      74.764,
      11.783,
      -93.759,
      74.764,
      11.783,
      -93.759,
      74.764,
      9.333,
      -93.959,
      74.167,
      9.333
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5216",
    "polygon": [
      -94.519,
      72.495,
      9.327,
      -94.519,
      72.495,
      11.777,
      -93.963,
      74.154,
      11.777,
      -93.963,
      74.154,
      11.777,
      -93.963,
      74.154,
      9.327,
      -94.519,
      72.495,
      9.327
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5217",
    "polygon": [
      -94.703,
      71.945,
      9.319,
      -94.703,
      71.945,
      11.769,
      -94.519,
      72.495,
      11.769,
      -94.519,
      72.495,
      11.769,
      -94.519,
      72.495,
      9.319,
      -94.703,
      71.945,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5218",
    "polygon": [
      -94.934,
      71.255,
      9.319,
      -94.934,
      71.255,
      11.769,
      -94.696,
      71.966,
      11.769,
      -94.696,
      71.966,
      11.769,
      -94.696,
      71.966,
      9.319,
      -94.934,
      71.255,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5219",
    "polygon": [
      -95.442,
      69.74,
      9.322,
      -95.442,
      69.74,
      11.772,
      -94.93,
      71.267,
      11.772,
      -94.93,
      71.267,
      11.772,
      -94.93,
      71.267,
      9.322,
      -95.442,
      69.74,
      9.322
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5220",
    "polygon": [
      -95.943,
      68.244,
      9.333,
      -95.943,
      68.244,
      11.783,
      -95.743,
      68.842,
      11.783,
      -95.743,
      68.842,
      11.783,
      -95.743,
      68.842,
      9.333,
      -95.943,
      68.244,
      9.333
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5221",
    "polygon": [
      -96.503,
      66.573,
      9.327,
      -96.503,
      66.573,
      11.777,
      -95.947,
      68.232,
      11.777,
      -95.947,
      68.232,
      11.777,
      -95.947,
      68.232,
      9.327,
      -96.503,
      66.573,
      9.327
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5222",
    "polygon": [
      -96.687,
      66.023,
      9.319,
      -96.687,
      66.023,
      11.769,
      -96.503,
      66.573,
      11.769,
      -96.503,
      66.573,
      11.769,
      -96.503,
      66.573,
      9.319,
      -96.687,
      66.023,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5223",
    "polygon": [
      -96.918,
      65.333,
      9.319,
      -96.918,
      65.333,
      11.769,
      -96.68,
      66.044,
      11.769,
      -96.68,
      66.044,
      11.769,
      -96.68,
      66.044,
      9.319,
      -96.918,
      65.333,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5224",
    "polygon": [
      -97.425,
      63.818,
      9.322,
      -97.425,
      63.818,
      11.772,
      -96.914,
      65.345,
      11.772,
      -96.914,
      65.345,
      11.772,
      -96.914,
      65.345,
      9.322,
      -97.425,
      63.818,
      9.322
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5225",
    "polygon": [
      -97.933,
      62.304,
      9.333,
      -97.933,
      62.304,
      11.783,
      -97.733,
      62.902,
      11.783,
      -97.733,
      62.902,
      11.783,
      -97.733,
      62.902,
      9.333,
      -97.933,
      62.304,
      9.333
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5226",
    "polygon": [
      -98.493,
      60.633,
      9.327,
      -98.493,
      60.633,
      11.777,
      -97.937,
      62.292,
      11.777,
      -97.937,
      62.292,
      11.777,
      -97.937,
      62.292,
      9.327,
      -98.493,
      60.633,
      9.327
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5227",
    "polygon": [
      -98.677,
      60.083,
      9.319,
      -98.677,
      60.083,
      11.769,
      -98.493,
      60.633,
      11.769,
      -98.493,
      60.633,
      11.769,
      -98.493,
      60.633,
      9.319,
      -98.677,
      60.083,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5228",
    "polygon": [
      -98.908,
      59.393,
      9.319,
      -98.908,
      59.393,
      11.769,
      -98.67,
      60.104,
      11.769,
      -98.67,
      60.104,
      11.769,
      -98.67,
      60.104,
      9.319,
      -98.908,
      59.393,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5229",
    "polygon": [
      -99.415,
      57.878,
      9.322,
      -99.415,
      57.878,
      11.772,
      -98.904,
      59.405,
      11.772,
      -98.904,
      59.405,
      11.772,
      -98.904,
      59.405,
      9.322,
      -99.415,
      57.878,
      9.322
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5230",
    "polygon": [
      -99.915,
      56.385,
      9.333,
      -99.915,
      56.385,
      11.783,
      -99.715,
      56.982,
      11.783,
      -99.715,
      56.982,
      11.783,
      -99.715,
      56.982,
      9.333,
      -99.915,
      56.385,
      9.333
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5231",
    "polygon": [
      -100.475,
      54.713,
      9.327,
      -100.475,
      54.713,
      11.777,
      -99.92,
      56.373,
      11.777,
      -99.92,
      56.373,
      11.777,
      -99.92,
      56.373,
      9.327,
      -100.475,
      54.713,
      9.327
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5232",
    "polygon": [
      -100.66,
      54.163,
      9.319,
      -100.66,
      54.163,
      11.769,
      -100.475,
      54.713,
      11.769,
      -100.475,
      54.713,
      11.769,
      -100.475,
      54.713,
      9.319,
      -100.66,
      54.163,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5233",
    "polygon": [
      -100.891,
      53.474,
      9.319,
      -100.891,
      53.474,
      11.769,
      -100.653,
      54.185,
      11.769,
      -100.653,
      54.185,
      11.769,
      -100.653,
      54.185,
      9.319,
      -100.891,
      53.474,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5234",
    "polygon": [
      -101.398,
      51.959,
      9.322,
      -101.398,
      51.959,
      11.772,
      -100.887,
      53.485,
      11.772,
      -100.887,
      53.485,
      11.772,
      -100.887,
      53.485,
      9.322,
      -101.398,
      51.959,
      9.322
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5235",
    "polygon": [
      -101.9,
      50.461,
      9.333,
      -101.9,
      50.461,
      11.783,
      -101.7,
      51.059,
      11.783,
      -101.7,
      51.059,
      11.783,
      -101.7,
      51.059,
      9.333,
      -101.9,
      50.461,
      9.333
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5236",
    "polygon": [
      -102.46,
      48.79,
      9.327,
      -102.46,
      48.79,
      11.777,
      -101.904,
      50.449,
      11.777,
      -101.904,
      50.449,
      11.777,
      -101.904,
      50.449,
      9.327,
      -102.46,
      48.79,
      9.327
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5237",
    "polygon": [
      -102.644,
      48.24,
      9.319,
      -102.644,
      48.24,
      11.769,
      -102.46,
      48.79,
      11.769,
      -102.46,
      48.79,
      11.769,
      -102.46,
      48.79,
      9.319,
      -102.644,
      48.24,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5238",
    "polygon": [
      -102.875,
      47.55,
      9.319,
      -102.875,
      47.55,
      11.769,
      -102.637,
      48.261,
      11.769,
      -102.637,
      48.261,
      11.769,
      -102.637,
      48.261,
      9.319,
      -102.875,
      47.55,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5239",
    "polygon": [
      -103.383,
      46.035,
      9.322,
      -103.383,
      46.035,
      11.772,
      -102.871,
      47.562,
      11.772,
      -102.871,
      47.562,
      11.772,
      -102.871,
      47.562,
      9.322,
      -103.383,
      46.035,
      9.322
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5240",
    "polygon": [
      -103.883,
      44.542,
      9.333,
      -103.883,
      44.542,
      11.783,
      -103.683,
      45.14,
      11.783,
      -103.683,
      45.14,
      11.783,
      -103.683,
      45.14,
      9.333,
      -103.883,
      44.542,
      9.333
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5241",
    "polygon": [
      -104.443,
      42.871,
      9.327,
      -104.443,
      42.871,
      11.777,
      -103.887,
      44.53,
      11.777,
      -103.887,
      44.53,
      11.777,
      -103.887,
      44.53,
      9.327,
      -104.443,
      42.871,
      9.327
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5242",
    "polygon": [
      -104.627,
      42.321,
      9.319,
      -104.627,
      42.321,
      11.769,
      -104.443,
      42.871,
      11.769,
      -104.443,
      42.871,
      11.769,
      -104.443,
      42.871,
      9.319,
      -104.627,
      42.321,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5243",
    "polygon": [
      -104.858,
      41.631,
      9.319,
      -104.858,
      41.631,
      11.769,
      -104.62,
      42.342,
      11.769,
      -104.62,
      42.342,
      11.769,
      -104.62,
      42.342,
      9.319,
      -104.858,
      41.631,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5244",
    "polygon": [
      -105.365,
      40.116,
      9.322,
      -105.365,
      40.116,
      11.772,
      -104.854,
      41.643,
      11.772,
      -104.854,
      41.643,
      11.772,
      -104.854,
      41.643,
      9.322,
      -105.365,
      40.116,
      9.322
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5245",
    "polygon": [
      -105.874,
      38.597,
      9.333,
      -105.874,
      38.597,
      11.783,
      -105.674,
      39.194,
      11.783,
      -105.674,
      39.194,
      11.783,
      -105.674,
      39.194,
      9.333,
      -105.874,
      38.597,
      9.333
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5246",
    "polygon": [
      -106.434,
      36.925,
      9.327,
      -106.434,
      36.925,
      11.777,
      -105.878,
      38.584,
      11.777,
      -105.878,
      38.584,
      11.777,
      -105.878,
      38.584,
      9.327,
      -106.434,
      36.925,
      9.327
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5247",
    "polygon": [
      -106.619,
      36.375,
      9.319,
      -106.619,
      36.375,
      11.769,
      -106.434,
      36.925,
      11.769,
      -106.434,
      36.925,
      11.769,
      -106.434,
      36.925,
      9.319,
      -106.619,
      36.375,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5248",
    "polygon": [
      -106.85,
      35.685,
      9.319,
      -106.85,
      35.685,
      11.769,
      -106.611,
      36.396,
      11.769,
      -106.611,
      36.396,
      11.769,
      -106.611,
      36.396,
      9.319,
      -106.85,
      35.685,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5249",
    "polygon": [
      -107.357,
      34.17,
      9.322,
      -107.357,
      34.17,
      11.772,
      -106.846,
      35.697,
      11.772,
      -106.846,
      35.697,
      11.772,
      -106.846,
      35.697,
      9.322,
      -107.357,
      34.17,
      9.322
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5250",
    "polygon": [
      -107.854,
      32.687,
      9.333,
      -107.854,
      32.687,
      11.783,
      -107.654,
      33.284,
      11.783,
      -107.654,
      33.284,
      11.783,
      -107.654,
      33.284,
      9.333,
      -107.854,
      32.687,
      9.333
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5251",
    "polygon": [
      -108.414,
      31.015,
      9.327,
      -108.414,
      31.015,
      11.777,
      -107.858,
      32.674,
      11.777,
      -107.858,
      32.674,
      11.777,
      -107.858,
      32.674,
      9.327,
      -108.414,
      31.015,
      9.327
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5252",
    "polygon": [
      -108.598,
      30.465,
      9.319,
      -108.598,
      30.465,
      11.769,
      -108.414,
      31.015,
      11.769,
      -108.414,
      31.015,
      11.769,
      -108.414,
      31.015,
      9.319,
      -108.598,
      30.465,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5253",
    "polygon": [
      -108.829,
      29.775,
      9.319,
      -108.829,
      29.775,
      11.769,
      -108.591,
      30.486,
      11.769,
      -108.591,
      30.486,
      11.769,
      -108.591,
      30.486,
      9.319,
      -108.829,
      29.775,
      9.319
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_2.OG_5254",
    "polygon": [
      -109.337,
      28.26,
      9.322,
      -109.337,
      28.26,
      11.772,
      -108.825,
      29.787,
      11.772,
      -108.825,
      29.787,
      11.772,
      -108.825,
      29.787,
      9.322,
      -109.337,
      28.26,
      9.322
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5255",
    "polygon": [
      -93.959,
      74.167,
      13.083,
      -93.959,
      74.167,
      15.533,
      -93.759,
      74.764,
      15.533,
      -93.759,
      74.764,
      15.533,
      -93.759,
      74.764,
      13.083,
      -93.959,
      74.167,
      13.083
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5256",
    "polygon": [
      -94.519,
      72.495,
      13.077,
      -94.519,
      72.495,
      15.527,
      -93.963,
      74.154,
      15.527,
      -93.963,
      74.154,
      15.527,
      -93.963,
      74.154,
      13.077,
      -94.519,
      72.495,
      13.077
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5257",
    "polygon": [
      -94.703,
      71.945,
      13.069,
      -94.703,
      71.945,
      15.519,
      -94.519,
      72.495,
      15.519,
      -94.519,
      72.495,
      15.519,
      -94.519,
      72.495,
      13.069,
      -94.703,
      71.945,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5258",
    "polygon": [
      -94.934,
      71.255,
      13.069,
      -94.934,
      71.255,
      15.519,
      -94.696,
      71.966,
      15.519,
      -94.696,
      71.966,
      15.519,
      -94.696,
      71.966,
      13.069,
      -94.934,
      71.255,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5259",
    "polygon": [
      -95.442,
      69.74,
      13.072,
      -95.442,
      69.74,
      15.522,
      -94.93,
      71.267,
      15.522,
      -94.93,
      71.267,
      15.522,
      -94.93,
      71.267,
      13.072,
      -95.442,
      69.74,
      13.072
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5260",
    "polygon": [
      -95.943,
      68.244,
      13.083,
      -95.943,
      68.244,
      15.533,
      -95.743,
      68.842,
      15.533,
      -95.743,
      68.842,
      15.533,
      -95.743,
      68.842,
      13.083,
      -95.943,
      68.244,
      13.083
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5261",
    "polygon": [
      -96.503,
      66.573,
      13.077,
      -96.503,
      66.573,
      15.527,
      -95.947,
      68.232,
      15.527,
      -95.947,
      68.232,
      15.527,
      -95.947,
      68.232,
      13.077,
      -96.503,
      66.573,
      13.077
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5262",
    "polygon": [
      -96.687,
      66.023,
      13.069,
      -96.687,
      66.023,
      15.519,
      -96.503,
      66.573,
      15.519,
      -96.503,
      66.573,
      15.519,
      -96.503,
      66.573,
      13.069,
      -96.687,
      66.023,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5263",
    "polygon": [
      -96.918,
      65.333,
      13.069,
      -96.918,
      65.333,
      15.519,
      -96.68,
      66.044,
      15.519,
      -96.68,
      66.044,
      15.519,
      -96.68,
      66.044,
      13.069,
      -96.918,
      65.333,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5264",
    "polygon": [
      -97.425,
      63.818,
      13.072,
      -97.425,
      63.818,
      15.522,
      -96.914,
      65.345,
      15.522,
      -96.914,
      65.345,
      15.522,
      -96.914,
      65.345,
      13.072,
      -97.425,
      63.818,
      13.072
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5265",
    "polygon": [
      -97.933,
      62.304,
      13.083,
      -97.933,
      62.304,
      15.533,
      -97.733,
      62.902,
      15.533,
      -97.733,
      62.902,
      15.533,
      -97.733,
      62.902,
      13.083,
      -97.933,
      62.304,
      13.083
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5266",
    "polygon": [
      -98.493,
      60.633,
      13.077,
      -98.493,
      60.633,
      15.527,
      -97.937,
      62.292,
      15.527,
      -97.937,
      62.292,
      15.527,
      -97.937,
      62.292,
      13.077,
      -98.493,
      60.633,
      13.077
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5267",
    "polygon": [
      -98.677,
      60.083,
      13.069,
      -98.677,
      60.083,
      15.519,
      -98.493,
      60.633,
      15.519,
      -98.493,
      60.633,
      15.519,
      -98.493,
      60.633,
      13.069,
      -98.677,
      60.083,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5268",
    "polygon": [
      -98.908,
      59.393,
      13.069,
      -98.908,
      59.393,
      15.519,
      -98.67,
      60.104,
      15.519,
      -98.67,
      60.104,
      15.519,
      -98.67,
      60.104,
      13.069,
      -98.908,
      59.393,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5269",
    "polygon": [
      -99.415,
      57.878,
      13.072,
      -99.415,
      57.878,
      15.522,
      -98.904,
      59.405,
      15.522,
      -98.904,
      59.405,
      15.522,
      -98.904,
      59.405,
      13.072,
      -99.415,
      57.878,
      13.072
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5270",
    "polygon": [
      -99.915,
      56.385,
      13.083,
      -99.915,
      56.385,
      15.533,
      -99.715,
      56.982,
      15.533,
      -99.715,
      56.982,
      15.533,
      -99.715,
      56.982,
      13.083,
      -99.915,
      56.385,
      13.083
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5271",
    "polygon": [
      -100.475,
      54.713,
      13.077,
      -100.475,
      54.713,
      15.527,
      -99.92,
      56.373,
      15.527,
      -99.92,
      56.373,
      15.527,
      -99.92,
      56.373,
      13.077,
      -100.475,
      54.713,
      13.077
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5272",
    "polygon": [
      -100.66,
      54.163,
      13.069,
      -100.66,
      54.163,
      15.519,
      -100.475,
      54.713,
      15.519,
      -100.475,
      54.713,
      15.519,
      -100.475,
      54.713,
      13.069,
      -100.66,
      54.163,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5273",
    "polygon": [
      -100.891,
      53.474,
      13.069,
      -100.891,
      53.474,
      15.519,
      -100.653,
      54.185,
      15.519,
      -100.653,
      54.185,
      15.519,
      -100.653,
      54.185,
      13.069,
      -100.891,
      53.474,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5274",
    "polygon": [
      -101.398,
      51.959,
      13.072,
      -101.398,
      51.959,
      15.522,
      -100.887,
      53.485,
      15.522,
      -100.887,
      53.485,
      15.522,
      -100.887,
      53.485,
      13.072,
      -101.398,
      51.959,
      13.072
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5275",
    "polygon": [
      -101.9,
      50.461,
      13.083,
      -101.9,
      50.461,
      15.533,
      -101.7,
      51.059,
      15.533,
      -101.7,
      51.059,
      15.533,
      -101.7,
      51.059,
      13.083,
      -101.9,
      50.461,
      13.083
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5276",
    "polygon": [
      -102.46,
      48.79,
      13.077,
      -102.46,
      48.79,
      15.527,
      -101.904,
      50.449,
      15.527,
      -101.904,
      50.449,
      15.527,
      -101.904,
      50.449,
      13.077,
      -102.46,
      48.79,
      13.077
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5277",
    "polygon": [
      -102.644,
      48.24,
      13.069,
      -102.644,
      48.24,
      15.519,
      -102.46,
      48.79,
      15.519,
      -102.46,
      48.79,
      15.519,
      -102.46,
      48.79,
      13.069,
      -102.644,
      48.24,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5278",
    "polygon": [
      -102.875,
      47.55,
      13.069,
      -102.875,
      47.55,
      15.519,
      -102.637,
      48.261,
      15.519,
      -102.637,
      48.261,
      15.519,
      -102.637,
      48.261,
      13.069,
      -102.875,
      47.55,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5279",
    "polygon": [
      -103.383,
      46.035,
      13.072,
      -103.383,
      46.035,
      15.522,
      -102.871,
      47.562,
      15.522,
      -102.871,
      47.562,
      15.522,
      -102.871,
      47.562,
      13.072,
      -103.383,
      46.035,
      13.072
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5280",
    "polygon": [
      -103.883,
      44.542,
      13.083,
      -103.883,
      44.542,
      15.533,
      -103.683,
      45.14,
      15.533,
      -103.683,
      45.14,
      15.533,
      -103.683,
      45.14,
      13.083,
      -103.883,
      44.542,
      13.083
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5281",
    "polygon": [
      -104.443,
      42.871,
      13.077,
      -104.443,
      42.871,
      15.527,
      -103.887,
      44.53,
      15.527,
      -103.887,
      44.53,
      15.527,
      -103.887,
      44.53,
      13.077,
      -104.443,
      42.871,
      13.077
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5282",
    "polygon": [
      -104.627,
      42.321,
      13.069,
      -104.627,
      42.321,
      15.519,
      -104.443,
      42.871,
      15.519,
      -104.443,
      42.871,
      15.519,
      -104.443,
      42.871,
      13.069,
      -104.627,
      42.321,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5283",
    "polygon": [
      -104.858,
      41.631,
      13.069,
      -104.858,
      41.631,
      15.519,
      -104.62,
      42.342,
      15.519,
      -104.62,
      42.342,
      15.519,
      -104.62,
      42.342,
      13.069,
      -104.858,
      41.631,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5284",
    "polygon": [
      -105.365,
      40.116,
      13.072,
      -105.365,
      40.116,
      15.522,
      -104.854,
      41.643,
      15.522,
      -104.854,
      41.643,
      15.522,
      -104.854,
      41.643,
      13.072,
      -105.365,
      40.116,
      13.072
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5285",
    "polygon": [
      -105.874,
      38.597,
      13.083,
      -105.874,
      38.597,
      15.533,
      -105.674,
      39.194,
      15.533,
      -105.674,
      39.194,
      15.533,
      -105.674,
      39.194,
      13.083,
      -105.874,
      38.597,
      13.083
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5286",
    "polygon": [
      -106.434,
      36.925,
      13.077,
      -106.434,
      36.925,
      15.527,
      -105.878,
      38.584,
      15.527,
      -105.878,
      38.584,
      15.527,
      -105.878,
      38.584,
      13.077,
      -106.434,
      36.925,
      13.077
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5287",
    "polygon": [
      -106.619,
      36.375,
      13.069,
      -106.619,
      36.375,
      15.519,
      -106.434,
      36.925,
      15.519,
      -106.434,
      36.925,
      15.519,
      -106.434,
      36.925,
      13.069,
      -106.619,
      36.375,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5288",
    "polygon": [
      -106.85,
      35.685,
      13.069,
      -106.85,
      35.685,
      15.519,
      -106.611,
      36.396,
      15.519,
      -106.611,
      36.396,
      15.519,
      -106.611,
      36.396,
      13.069,
      -106.85,
      35.685,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5289",
    "polygon": [
      -107.357,
      34.17,
      13.072,
      -107.357,
      34.17,
      15.522,
      -106.846,
      35.697,
      15.522,
      -106.846,
      35.697,
      15.522,
      -106.846,
      35.697,
      13.072,
      -107.357,
      34.17,
      13.072
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5290",
    "polygon": [
      -107.854,
      32.687,
      13.083,
      -107.854,
      32.687,
      15.533,
      -107.654,
      33.284,
      15.533,
      -107.654,
      33.284,
      15.533,
      -107.654,
      33.284,
      13.083,
      -107.854,
      32.687,
      13.083
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5291",
    "polygon": [
      -108.414,
      31.015,
      13.077,
      -108.414,
      31.015,
      15.527,
      -107.858,
      32.674,
      15.527,
      -107.858,
      32.674,
      15.527,
      -107.858,
      32.674,
      13.077,
      -108.414,
      31.015,
      13.077
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5292",
    "polygon": [
      -108.598,
      30.465,
      13.069,
      -108.598,
      30.465,
      15.519,
      -108.414,
      31.015,
      15.519,
      -108.414,
      31.015,
      15.519,
      -108.414,
      31.015,
      13.069,
      -108.598,
      30.465,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5293",
    "polygon": [
      -108.829,
      29.775,
      13.069,
      -108.829,
      29.775,
      15.519,
      -108.591,
      30.486,
      15.519,
      -108.591,
      30.486,
      15.519,
      -108.591,
      30.486,
      13.069,
      -108.829,
      29.775,
      13.069
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-C-Outer_3.OG_5294",
    "polygon": [
      -109.337,
      28.26,
      13.072,
      -109.337,
      28.26,
      15.522,
      -108.825,
      29.787,
      15.522,
      -108.825,
      29.787,
      15.522,
      -108.825,
      29.787,
      13.072,
      -109.337,
      28.26,
      13.072
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6333",
    "polygon": [
      102.852,
      8.712,
      5.583,
      102.852,
      8.712,
      8.033,
      102.652,
      8.114,
      8.033,
      102.652,
      8.114,
      8.033,
      102.652,
      8.114,
      5.583,
      102.852,
      8.712,
      5.583
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6334",
    "polygon": [
      102.648,
      8.102,
      5.577,
      102.648,
      8.102,
      8.027,
      102.092,
      6.443,
      8.027,
      102.092,
      6.443,
      8.027,
      102.092,
      6.443,
      5.577,
      102.648,
      8.102,
      5.577
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6335",
    "polygon": [
      102.092,
      6.443,
      5.569,
      102.092,
      6.443,
      8.019,
      101.908,
      5.893,
      8.019,
      101.908,
      5.893,
      8.019,
      101.908,
      5.893,
      5.569,
      102.092,
      6.443,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6336",
    "polygon": [
      101.915,
      5.914,
      5.569,
      101.915,
      5.914,
      8.019,
      101.677,
      5.203,
      8.019,
      101.677,
      5.203,
      8.019,
      101.677,
      5.203,
      5.569,
      101.915,
      5.914,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6337",
    "polygon": [
      101.681,
      5.215,
      5.572,
      101.681,
      5.215,
      8.022,
      101.169,
      3.688,
      8.022,
      101.169,
      3.688,
      8.022,
      101.169,
      3.688,
      5.572,
      101.681,
      5.215,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6338",
    "polygon": [
      100.868,
      2.79,
      5.583,
      100.868,
      2.79,
      8.033,
      100.668,
      2.192,
      8.033,
      100.668,
      2.192,
      8.033,
      100.668,
      2.192,
      5.583,
      100.868,
      2.79,
      5.583
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6339",
    "polygon": [
      100.664,
      2.18,
      5.577,
      100.664,
      2.18,
      8.027,
      100.108,
      0.521,
      8.027,
      100.108,
      0.521,
      8.027,
      100.108,
      0.521,
      5.577,
      100.664,
      2.18,
      5.577
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6340",
    "polygon": [
      100.108,
      0.521,
      5.569,
      100.108,
      0.521,
      8.019,
      99.924,
      -0.029,
      8.019,
      99.924,
      -0.029,
      8.019,
      99.924,
      -0.029,
      5.569,
      100.108,
      0.521,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6341",
    "polygon": [
      99.931,
      -0.008,
      5.569,
      99.931,
      -0.008,
      8.019,
      99.693,
      -0.719,
      8.019,
      99.693,
      -0.719,
      8.019,
      99.693,
      -0.719,
      5.569,
      99.931,
      -0.008,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6342",
    "polygon": [
      99.697,
      -0.707,
      5.572,
      99.697,
      -0.707,
      8.022,
      99.185,
      -2.234,
      8.022,
      99.185,
      -2.234,
      8.022,
      99.185,
      -2.234,
      5.572,
      99.697,
      -0.707,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6343",
    "polygon": [
      98.878,
      -3.151,
      5.583,
      98.878,
      -3.151,
      8.033,
      98.678,
      -3.748,
      8.033,
      98.678,
      -3.748,
      8.033,
      98.678,
      -3.748,
      5.583,
      98.878,
      -3.151,
      5.583
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6344",
    "polygon": [
      98.674,
      -3.76,
      5.577,
      98.674,
      -3.76,
      8.027,
      98.118,
      -5.42,
      8.027,
      98.118,
      -5.42,
      8.027,
      98.118,
      -5.42,
      5.577,
      98.674,
      -3.76,
      5.577
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6345",
    "polygon": [
      98.118,
      -5.42,
      5.569,
      98.118,
      -5.42,
      8.019,
      97.934,
      -5.97,
      8.019,
      97.934,
      -5.97,
      8.019,
      97.934,
      -5.97,
      5.569,
      98.118,
      -5.42,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6346",
    "polygon": [
      97.941,
      -5.948,
      5.569,
      97.941,
      -5.948,
      8.019,
      97.703,
      -6.659,
      8.019,
      97.703,
      -6.659,
      8.019,
      97.703,
      -6.659,
      5.569,
      97.941,
      -5.948,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6347",
    "polygon": [
      97.707,
      -6.648,
      5.572,
      97.707,
      -6.648,
      8.022,
      97.196,
      -8.174,
      8.022,
      97.196,
      -8.174,
      8.022,
      97.196,
      -8.174,
      5.572,
      97.707,
      -6.648,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6348",
    "polygon": [
      96.896,
      -9.07,
      5.583,
      96.896,
      -9.07,
      8.033,
      96.695,
      -9.667,
      8.033,
      96.695,
      -9.667,
      8.033,
      96.695,
      -9.667,
      5.583,
      96.896,
      -9.07,
      5.583
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6349",
    "polygon": [
      96.691,
      -9.679,
      5.577,
      96.691,
      -9.679,
      8.027,
      96.135,
      -11.339,
      8.027,
      96.135,
      -11.339,
      8.027,
      96.135,
      -11.339,
      5.577,
      96.691,
      -9.679,
      5.577
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6350",
    "polygon": [
      96.135,
      -11.339,
      5.569,
      96.135,
      -11.339,
      8.019,
      95.951,
      -11.889,
      8.019,
      95.951,
      -11.889,
      8.019,
      95.951,
      -11.889,
      5.569,
      96.135,
      -11.339,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6351",
    "polygon": [
      95.958,
      -11.867,
      5.569,
      95.958,
      -11.867,
      8.019,
      95.72,
      -12.579,
      8.019,
      95.72,
      -12.579,
      8.019,
      95.72,
      -12.579,
      5.569,
      95.958,
      -11.867,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6352",
    "polygon": [
      95.724,
      -12.567,
      5.572,
      95.724,
      -12.567,
      8.022,
      95.213,
      -14.093,
      8.022,
      95.213,
      -14.093,
      8.022,
      95.213,
      -14.093,
      5.572,
      95.724,
      -12.567,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6353",
    "polygon": [
      94.911,
      -14.993,
      5.583,
      94.911,
      -14.993,
      8.033,
      94.711,
      -15.591,
      8.033,
      94.711,
      -15.591,
      8.033,
      94.711,
      -15.591,
      5.583,
      94.911,
      -14.993,
      5.583
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6354",
    "polygon": [
      94.707,
      -15.603,
      5.577,
      94.707,
      -15.603,
      8.027,
      94.151,
      -17.263,
      8.027,
      94.151,
      -17.263,
      8.027,
      94.151,
      -17.263,
      5.577,
      94.707,
      -15.603,
      5.577
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6355",
    "polygon": [
      94.151,
      -17.263,
      5.569,
      94.151,
      -17.263,
      8.019,
      93.967,
      -17.813,
      8.019,
      93.967,
      -17.813,
      8.019,
      93.967,
      -17.813,
      5.569,
      94.151,
      -17.263,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6356",
    "polygon": [
      93.974,
      -17.791,
      5.569,
      93.974,
      -17.791,
      8.019,
      93.736,
      -18.502,
      8.019,
      93.736,
      -18.502,
      8.019,
      93.736,
      -18.502,
      5.569,
      93.974,
      -17.791,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6357",
    "polygon": [
      93.74,
      -18.49,
      5.572,
      93.74,
      -18.49,
      8.022,
      93.228,
      -20.017,
      8.022,
      93.228,
      -20.017,
      8.022,
      93.228,
      -20.017,
      5.572,
      93.74,
      -18.49,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6358",
    "polygon": [
      92.928,
      -20.912,
      5.583,
      92.928,
      -20.912,
      8.033,
      92.728,
      -21.51,
      8.033,
      92.728,
      -21.51,
      8.033,
      92.728,
      -21.51,
      5.583,
      92.928,
      -20.912,
      5.583
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6359",
    "polygon": [
      92.724,
      -21.522,
      5.577,
      92.724,
      -21.522,
      8.027,
      92.168,
      -23.182,
      8.027,
      92.168,
      -23.182,
      8.027,
      92.168,
      -23.182,
      5.577,
      92.724,
      -21.522,
      5.577
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6360",
    "polygon": [
      92.168,
      -23.182,
      5.569,
      92.168,
      -23.182,
      8.019,
      91.984,
      -23.732,
      8.019,
      91.984,
      -23.732,
      8.019,
      91.984,
      -23.732,
      5.569,
      92.168,
      -23.182,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6361",
    "polygon": [
      91.991,
      -23.71,
      5.569,
      91.991,
      -23.71,
      8.019,
      91.753,
      -24.421,
      8.019,
      91.753,
      -24.421,
      8.019,
      91.753,
      -24.421,
      5.569,
      91.991,
      -23.71,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6362",
    "polygon": [
      91.757,
      -24.409,
      5.572,
      91.757,
      -24.409,
      8.022,
      91.246,
      -25.936,
      8.022,
      91.246,
      -25.936,
      8.022,
      91.246,
      -25.936,
      5.572,
      91.757,
      -24.409,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6363",
    "polygon": [
      90.937,
      -26.858,
      5.583,
      90.937,
      -26.858,
      8.033,
      90.737,
      -27.456,
      8.033,
      90.737,
      -27.456,
      8.033,
      90.737,
      -27.456,
      5.583,
      90.937,
      -26.858,
      5.583
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6364",
    "polygon": [
      90.732,
      -27.468,
      5.577,
      90.732,
      -27.468,
      8.027,
      90.177,
      -29.127,
      8.027,
      90.177,
      -29.127,
      8.027,
      90.177,
      -29.127,
      5.577,
      90.732,
      -27.468,
      5.577
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6365",
    "polygon": [
      90.177,
      -29.127,
      5.569,
      90.177,
      -29.127,
      8.019,
      89.992,
      -29.677,
      8.019,
      89.992,
      -29.677,
      8.019,
      89.992,
      -29.677,
      5.569,
      90.177,
      -29.127,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6366",
    "polygon": [
      90.0,
      -29.656,
      5.569,
      90.0,
      -29.656,
      8.019,
      89.761,
      -30.367,
      8.019,
      89.761,
      -30.367,
      8.019,
      89.761,
      -30.367,
      5.569,
      90.0,
      -29.656,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6367",
    "polygon": [
      89.765,
      -30.355,
      5.572,
      89.765,
      -30.355,
      8.022,
      89.254,
      -31.882,
      8.022,
      89.254,
      -31.882,
      8.022,
      89.254,
      -31.882,
      5.572,
      89.765,
      -30.355,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6368",
    "polygon": [
      88.957,
      -32.768,
      5.583,
      88.957,
      -32.768,
      8.033,
      88.757,
      -33.365,
      8.033,
      88.757,
      -33.365,
      8.033,
      88.757,
      -33.365,
      5.583,
      88.957,
      -32.768,
      5.583
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6369",
    "polygon": [
      88.753,
      -33.378,
      5.577,
      88.753,
      -33.378,
      8.027,
      88.197,
      -35.037,
      8.027,
      88.197,
      -35.037,
      8.027,
      88.197,
      -35.037,
      5.577,
      88.753,
      -33.378,
      5.577
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6370",
    "polygon": [
      88.197,
      -35.037,
      5.569,
      88.197,
      -35.037,
      8.019,
      88.013,
      -35.587,
      8.019,
      88.013,
      -35.587,
      8.019,
      88.013,
      -35.587,
      5.569,
      88.197,
      -35.037,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6371",
    "polygon": [
      88.02,
      -35.566,
      5.569,
      88.02,
      -35.566,
      8.019,
      87.782,
      -36.277,
      8.019,
      87.782,
      -36.277,
      8.019,
      87.782,
      -36.277,
      5.569,
      88.02,
      -35.566,
      5.569
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_1.OG_6372",
    "polygon": [
      87.786,
      -36.265,
      5.572,
      87.786,
      -36.265,
      8.022,
      87.274,
      -37.792,
      8.022,
      87.274,
      -37.792,
      8.022,
      87.274,
      -37.792,
      5.572,
      87.786,
      -36.265,
      5.572
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6373",
    "polygon": [
      102.855,
      8.711,
      9.333,
      102.855,
      8.711,
      11.783,
      102.655,
      8.114,
      11.783,
      102.655,
      8.114,
      11.783,
      102.655,
      8.114,
      9.333,
      102.855,
      8.711,
      9.333
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6374",
    "polygon": [
      102.651,
      8.101,
      9.327,
      102.651,
      8.101,
      11.777,
      102.095,
      6.442,
      11.777,
      102.095,
      6.442,
      11.777,
      102.095,
      6.442,
      9.327,
      102.651,
      8.101,
      9.327
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6375",
    "polygon": [
      102.095,
      6.442,
      9.319,
      102.095,
      6.442,
      11.769,
      101.91,
      5.892,
      11.769,
      101.91,
      5.892,
      11.769,
      101.91,
      5.892,
      9.319,
      102.095,
      6.442,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6376",
    "polygon": [
      101.918,
      5.913,
      9.319,
      101.918,
      5.913,
      11.769,
      101.679,
      5.202,
      11.769,
      101.679,
      5.202,
      11.769,
      101.679,
      5.202,
      9.319,
      101.918,
      5.913,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6377",
    "polygon": [
      101.683,
      5.214,
      9.322,
      101.683,
      5.214,
      11.772,
      101.172,
      3.687,
      11.772,
      101.172,
      3.687,
      11.772,
      101.172,
      3.687,
      9.322,
      101.683,
      5.214,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6378",
    "polygon": [
      100.871,
      2.789,
      9.333,
      100.871,
      2.789,
      11.783,
      100.671,
      2.191,
      11.783,
      100.671,
      2.191,
      11.783,
      100.671,
      2.191,
      9.333,
      100.871,
      2.789,
      9.333
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6379",
    "polygon": [
      100.667,
      2.179,
      9.327,
      100.667,
      2.179,
      11.777,
      100.111,
      0.52,
      11.777,
      100.111,
      0.52,
      11.777,
      100.111,
      0.52,
      9.327,
      100.667,
      2.179,
      9.327
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6380",
    "polygon": [
      100.111,
      0.52,
      9.319,
      100.111,
      0.52,
      11.769,
      99.927,
      -0.03,
      11.769,
      99.927,
      -0.03,
      11.769,
      99.927,
      -0.03,
      9.319,
      100.111,
      0.52,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6381",
    "polygon": [
      99.934,
      -0.009,
      9.319,
      99.934,
      -0.009,
      11.769,
      99.696,
      -0.72,
      11.769,
      99.696,
      -0.72,
      11.769,
      99.696,
      -0.72,
      9.319,
      99.934,
      -0.009,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6382",
    "polygon": [
      99.699,
      -0.708,
      9.322,
      99.699,
      -0.708,
      11.772,
      99.188,
      -2.235,
      11.772,
      99.188,
      -2.235,
      11.772,
      99.188,
      -2.235,
      9.322,
      99.699,
      -0.708,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6383",
    "polygon": [
      98.881,
      -3.151,
      9.333,
      98.881,
      -3.151,
      11.783,
      98.681,
      -3.749,
      11.783,
      98.681,
      -3.749,
      11.783,
      98.681,
      -3.749,
      9.333,
      98.881,
      -3.151,
      9.333
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6384",
    "polygon": [
      98.677,
      -3.761,
      9.327,
      98.677,
      -3.761,
      11.777,
      98.121,
      -5.421,
      11.777,
      98.121,
      -5.421,
      11.777,
      98.121,
      -5.421,
      9.327,
      98.677,
      -3.761,
      9.327
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6385",
    "polygon": [
      98.121,
      -5.421,
      9.319,
      98.121,
      -5.421,
      11.769,
      97.937,
      -5.97,
      11.769,
      97.937,
      -5.97,
      11.769,
      97.937,
      -5.97,
      9.319,
      98.121,
      -5.421,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6386",
    "polygon": [
      97.944,
      -5.949,
      9.319,
      97.944,
      -5.949,
      11.769,
      97.706,
      -6.66,
      11.769,
      97.706,
      -6.66,
      11.769,
      97.706,
      -6.66,
      9.319,
      97.944,
      -5.949,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6387",
    "polygon": [
      97.71,
      -6.648,
      9.322,
      97.71,
      -6.648,
      11.772,
      97.198,
      -8.175,
      11.772,
      97.198,
      -8.175,
      11.772,
      97.198,
      -8.175,
      9.322,
      97.71,
      -6.648,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6388",
    "polygon": [
      96.898,
      -9.071,
      9.333,
      96.898,
      -9.071,
      11.783,
      96.698,
      -9.668,
      11.783,
      96.698,
      -9.668,
      11.783,
      96.698,
      -9.668,
      9.333,
      96.898,
      -9.071,
      9.333
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6389",
    "polygon": [
      96.694,
      -9.68,
      9.327,
      96.694,
      -9.68,
      11.777,
      96.138,
      -11.34,
      11.777,
      96.138,
      -11.34,
      11.777,
      96.138,
      -11.34,
      9.327,
      96.694,
      -9.68,
      9.327
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6390",
    "polygon": [
      96.138,
      -11.34,
      9.319,
      96.138,
      -11.34,
      11.769,
      95.954,
      -11.89,
      11.769,
      95.954,
      -11.89,
      11.769,
      95.954,
      -11.89,
      9.319,
      96.138,
      -11.34,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6391",
    "polygon": [
      95.961,
      -11.868,
      9.319,
      95.961,
      -11.868,
      11.769,
      95.723,
      -12.579,
      11.769,
      95.723,
      -12.579,
      11.769,
      95.723,
      -12.579,
      9.319,
      95.961,
      -11.868,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6392",
    "polygon": [
      95.727,
      -12.568,
      9.322,
      95.727,
      -12.568,
      11.772,
      95.215,
      -14.094,
      11.772,
      95.215,
      -14.094,
      11.772,
      95.215,
      -14.094,
      9.322,
      95.727,
      -12.568,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6393",
    "polygon": [
      94.914,
      -14.994,
      9.333,
      94.914,
      -14.994,
      11.783,
      94.714,
      -15.592,
      11.783,
      94.714,
      -15.592,
      11.783,
      94.714,
      -15.592,
      9.333,
      94.914,
      -14.994,
      9.333
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6394",
    "polygon": [
      94.71,
      -15.604,
      9.327,
      94.71,
      -15.604,
      11.777,
      94.154,
      -17.263,
      11.777,
      94.154,
      -17.263,
      11.777,
      94.154,
      -17.263,
      9.327,
      94.71,
      -15.604,
      9.327
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6395",
    "polygon": [
      94.154,
      -17.263,
      9.319,
      94.154,
      -17.263,
      11.769,
      93.969,
      -17.813,
      11.769,
      93.969,
      -17.813,
      11.769,
      93.969,
      -17.813,
      9.319,
      94.154,
      -17.263,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6396",
    "polygon": [
      93.977,
      -17.792,
      9.319,
      93.977,
      -17.792,
      11.769,
      93.738,
      -18.503,
      11.769,
      93.738,
      -18.503,
      11.769,
      93.738,
      -18.503,
      9.319,
      93.977,
      -17.792,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6397",
    "polygon": [
      93.742,
      -18.491,
      9.322,
      93.742,
      -18.491,
      11.772,
      93.231,
      -20.018,
      11.772,
      93.231,
      -20.018,
      11.772,
      93.231,
      -20.018,
      9.322,
      93.742,
      -18.491,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6398",
    "polygon": [
      92.931,
      -20.913,
      9.333,
      92.931,
      -20.913,
      11.783,
      92.731,
      -21.511,
      11.783,
      92.731,
      -21.511,
      11.783,
      92.731,
      -21.511,
      9.333,
      92.931,
      -20.913,
      9.333
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6399",
    "polygon": [
      92.727,
      -21.523,
      9.327,
      92.727,
      -21.523,
      11.777,
      92.171,
      -23.182,
      11.777,
      92.171,
      -23.182,
      11.777,
      92.171,
      -23.182,
      9.327,
      92.727,
      -21.523,
      9.327
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6400",
    "polygon": [
      92.171,
      -23.182,
      9.319,
      92.171,
      -23.182,
      11.769,
      91.987,
      -23.732,
      11.769,
      91.987,
      -23.732,
      11.769,
      91.987,
      -23.732,
      9.319,
      92.171,
      -23.182,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6401",
    "polygon": [
      91.994,
      -23.711,
      9.319,
      91.994,
      -23.711,
      11.769,
      91.756,
      -24.422,
      11.769,
      91.756,
      -24.422,
      11.769,
      91.756,
      -24.422,
      9.319,
      91.994,
      -23.711,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6402",
    "polygon": [
      91.76,
      -24.41,
      9.322,
      91.76,
      -24.41,
      11.772,
      91.248,
      -25.937,
      11.772,
      91.248,
      -25.937,
      11.772,
      91.248,
      -25.937,
      9.322,
      91.76,
      -24.41,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6403",
    "polygon": [
      90.939,
      -26.859,
      9.333,
      90.939,
      -26.859,
      11.783,
      90.739,
      -27.456,
      11.783,
      90.739,
      -27.456,
      11.783,
      90.739,
      -27.456,
      9.333,
      90.939,
      -26.859,
      9.333
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6404",
    "polygon": [
      90.735,
      -27.469,
      9.327,
      90.735,
      -27.469,
      11.777,
      90.179,
      -29.128,
      11.777,
      90.179,
      -29.128,
      11.777,
      90.179,
      -29.128,
      9.327,
      90.735,
      -27.469,
      9.327
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6405",
    "polygon": [
      90.179,
      -29.128,
      9.319,
      90.179,
      -29.128,
      11.769,
      89.995,
      -29.678,
      11.769,
      89.995,
      -29.678,
      11.769,
      89.995,
      -29.678,
      9.319,
      90.179,
      -29.128,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6406",
    "polygon": [
      90.002,
      -29.657,
      9.319,
      90.002,
      -29.657,
      11.769,
      89.764,
      -30.368,
      11.769,
      89.764,
      -30.368,
      11.769,
      89.764,
      -30.368,
      9.319,
      90.002,
      -29.657,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6407",
    "polygon": [
      89.768,
      -30.356,
      9.322,
      89.768,
      -30.356,
      11.772,
      89.256,
      -31.883,
      11.772,
      89.256,
      -31.883,
      11.772,
      89.256,
      -31.883,
      9.322,
      89.768,
      -30.356,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6408",
    "polygon": [
      88.96,
      -32.769,
      9.333,
      88.96,
      -32.769,
      11.783,
      88.759,
      -33.366,
      11.783,
      88.759,
      -33.366,
      11.783,
      88.759,
      -33.366,
      9.333,
      88.96,
      -32.769,
      9.333
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6409",
    "polygon": [
      88.755,
      -33.379,
      9.327,
      88.755,
      -33.379,
      11.777,
      88.199,
      -35.038,
      11.777,
      88.199,
      -35.038,
      11.777,
      88.199,
      -35.038,
      9.327,
      88.755,
      -33.379,
      9.327
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6410",
    "polygon": [
      88.199,
      -35.038,
      9.319,
      88.199,
      -35.038,
      11.769,
      88.015,
      -35.588,
      11.769,
      88.015,
      -35.588,
      11.769,
      88.015,
      -35.588,
      9.319,
      88.199,
      -35.038,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6411",
    "polygon": [
      88.022,
      -35.567,
      9.319,
      88.022,
      -35.567,
      11.769,
      87.784,
      -36.278,
      11.769,
      87.784,
      -36.278,
      11.769,
      87.784,
      -36.278,
      9.319,
      88.022,
      -35.567,
      9.319
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_2.OG_6412",
    "polygon": [
      87.788,
      -36.266,
      9.322,
      87.788,
      -36.266,
      11.772,
      87.277,
      -37.793,
      11.772,
      87.277,
      -37.793,
      11.772,
      87.277,
      -37.793,
      9.322,
      87.788,
      -36.266,
      9.322
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6413",
    "polygon": [
      102.855,
      8.711,
      13.083,
      102.855,
      8.711,
      15.533,
      102.655,
      8.114,
      15.533,
      102.655,
      8.114,
      15.533,
      102.655,
      8.114,
      13.083,
      102.855,
      8.711,
      13.083
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6414",
    "polygon": [
      102.651,
      8.101,
      13.077,
      102.651,
      8.101,
      15.527,
      102.095,
      6.442,
      15.527,
      102.095,
      6.442,
      15.527,
      102.095,
      6.442,
      13.077,
      102.651,
      8.101,
      13.077
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6415",
    "polygon": [
      102.095,
      6.442,
      13.069,
      102.095,
      6.442,
      15.519,
      101.91,
      5.892,
      15.519,
      101.91,
      5.892,
      15.519,
      101.91,
      5.892,
      13.069,
      102.095,
      6.442,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6416",
    "polygon": [
      101.918,
      5.913,
      13.069,
      101.918,
      5.913,
      15.519,
      101.679,
      5.202,
      15.519,
      101.679,
      5.202,
      15.519,
      101.679,
      5.202,
      13.069,
      101.918,
      5.913,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6417",
    "polygon": [
      101.683,
      5.214,
      13.072,
      101.683,
      5.214,
      15.522,
      101.172,
      3.687,
      15.522,
      101.172,
      3.687,
      15.522,
      101.172,
      3.687,
      13.072,
      101.683,
      5.214,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6418",
    "polygon": [
      100.871,
      2.789,
      13.083,
      100.871,
      2.789,
      15.533,
      100.671,
      2.191,
      15.533,
      100.671,
      2.191,
      15.533,
      100.671,
      2.191,
      13.083,
      100.871,
      2.789,
      13.083
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6419",
    "polygon": [
      100.667,
      2.179,
      13.077,
      100.667,
      2.179,
      15.527,
      100.111,
      0.52,
      15.527,
      100.111,
      0.52,
      15.527,
      100.111,
      0.52,
      13.077,
      100.667,
      2.179,
      13.077
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6420",
    "polygon": [
      100.111,
      0.52,
      13.069,
      100.111,
      0.52,
      15.519,
      99.927,
      -0.03,
      15.519,
      99.927,
      -0.03,
      15.519,
      99.927,
      -0.03,
      13.069,
      100.111,
      0.52,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6421",
    "polygon": [
      99.934,
      -0.009,
      13.069,
      99.934,
      -0.009,
      15.519,
      99.696,
      -0.72,
      15.519,
      99.696,
      -0.72,
      15.519,
      99.696,
      -0.72,
      13.069,
      99.934,
      -0.009,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6422",
    "polygon": [
      99.699,
      -0.708,
      13.072,
      99.699,
      -0.708,
      15.522,
      99.188,
      -2.235,
      15.522,
      99.188,
      -2.235,
      15.522,
      99.188,
      -2.235,
      13.072,
      99.699,
      -0.708,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6423",
    "polygon": [
      98.881,
      -3.151,
      13.083,
      98.881,
      -3.151,
      15.533,
      98.681,
      -3.749,
      15.533,
      98.681,
      -3.749,
      15.533,
      98.681,
      -3.749,
      13.083,
      98.881,
      -3.151,
      13.083
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6424",
    "polygon": [
      98.677,
      -3.761,
      13.077,
      98.677,
      -3.761,
      15.527,
      98.121,
      -5.421,
      15.527,
      98.121,
      -5.421,
      15.527,
      98.121,
      -5.421,
      13.077,
      98.677,
      -3.761,
      13.077
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6425",
    "polygon": [
      98.121,
      -5.421,
      13.069,
      98.121,
      -5.421,
      15.519,
      97.937,
      -5.97,
      15.519,
      97.937,
      -5.97,
      15.519,
      97.937,
      -5.97,
      13.069,
      98.121,
      -5.421,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6426",
    "polygon": [
      97.944,
      -5.949,
      13.069,
      97.944,
      -5.949,
      15.519,
      97.706,
      -6.66,
      15.519,
      97.706,
      -6.66,
      15.519,
      97.706,
      -6.66,
      13.069,
      97.944,
      -5.949,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6427",
    "polygon": [
      97.71,
      -6.648,
      13.072,
      97.71,
      -6.648,
      15.522,
      97.198,
      -8.175,
      15.522,
      97.198,
      -8.175,
      15.522,
      97.198,
      -8.175,
      13.072,
      97.71,
      -6.648,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6428",
    "polygon": [
      96.898,
      -9.071,
      13.083,
      96.898,
      -9.071,
      15.533,
      96.698,
      -9.668,
      15.533,
      96.698,
      -9.668,
      15.533,
      96.698,
      -9.668,
      13.083,
      96.898,
      -9.071,
      13.083
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6429",
    "polygon": [
      96.694,
      -9.68,
      13.077,
      96.694,
      -9.68,
      15.527,
      96.138,
      -11.34,
      15.527,
      96.138,
      -11.34,
      15.527,
      96.138,
      -11.34,
      13.077,
      96.694,
      -9.68,
      13.077
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6430",
    "polygon": [
      96.138,
      -11.34,
      13.069,
      96.138,
      -11.34,
      15.519,
      95.954,
      -11.89,
      15.519,
      95.954,
      -11.89,
      15.519,
      95.954,
      -11.89,
      13.069,
      96.138,
      -11.34,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6431",
    "polygon": [
      95.961,
      -11.868,
      13.069,
      95.961,
      -11.868,
      15.519,
      95.723,
      -12.579,
      15.519,
      95.723,
      -12.579,
      15.519,
      95.723,
      -12.579,
      13.069,
      95.961,
      -11.868,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6432",
    "polygon": [
      95.727,
      -12.568,
      13.072,
      95.727,
      -12.568,
      15.522,
      95.215,
      -14.094,
      15.522,
      95.215,
      -14.094,
      15.522,
      95.215,
      -14.094,
      13.072,
      95.727,
      -12.568,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6433",
    "polygon": [
      94.914,
      -14.994,
      13.083,
      94.914,
      -14.994,
      15.533,
      94.714,
      -15.592,
      15.533,
      94.714,
      -15.592,
      15.533,
      94.714,
      -15.592,
      13.083,
      94.914,
      -14.994,
      13.083
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6434",
    "polygon": [
      94.71,
      -15.604,
      13.077,
      94.71,
      -15.604,
      15.527,
      94.154,
      -17.263,
      15.527,
      94.154,
      -17.263,
      15.527,
      94.154,
      -17.263,
      13.077,
      94.71,
      -15.604,
      13.077
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6435",
    "polygon": [
      94.154,
      -17.263,
      13.069,
      94.154,
      -17.263,
      15.519,
      93.969,
      -17.813,
      15.519,
      93.969,
      -17.813,
      15.519,
      93.969,
      -17.813,
      13.069,
      94.154,
      -17.263,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6436",
    "polygon": [
      93.977,
      -17.792,
      13.069,
      93.977,
      -17.792,
      15.519,
      93.738,
      -18.503,
      15.519,
      93.738,
      -18.503,
      15.519,
      93.738,
      -18.503,
      13.069,
      93.977,
      -17.792,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6437",
    "polygon": [
      93.742,
      -18.491,
      13.072,
      93.742,
      -18.491,
      15.522,
      93.231,
      -20.018,
      15.522,
      93.231,
      -20.018,
      15.522,
      93.231,
      -20.018,
      13.072,
      93.742,
      -18.491,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6438",
    "polygon": [
      92.931,
      -20.913,
      13.083,
      92.931,
      -20.913,
      15.533,
      92.731,
      -21.511,
      15.533,
      92.731,
      -21.511,
      15.533,
      92.731,
      -21.511,
      13.083,
      92.931,
      -20.913,
      13.083
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6439",
    "polygon": [
      92.727,
      -21.523,
      13.077,
      92.727,
      -21.523,
      15.527,
      92.171,
      -23.182,
      15.527,
      92.171,
      -23.182,
      15.527,
      92.171,
      -23.182,
      13.077,
      92.727,
      -21.523,
      13.077
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6440",
    "polygon": [
      92.171,
      -23.182,
      13.069,
      92.171,
      -23.182,
      15.519,
      91.987,
      -23.732,
      15.519,
      91.987,
      -23.732,
      15.519,
      91.987,
      -23.732,
      13.069,
      92.171,
      -23.182,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6441",
    "polygon": [
      91.994,
      -23.711,
      13.069,
      91.994,
      -23.711,
      15.519,
      91.756,
      -24.422,
      15.519,
      91.756,
      -24.422,
      15.519,
      91.756,
      -24.422,
      13.069,
      91.994,
      -23.711,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6442",
    "polygon": [
      91.76,
      -24.41,
      13.072,
      91.76,
      -24.41,
      15.522,
      91.248,
      -25.937,
      15.522,
      91.248,
      -25.937,
      15.522,
      91.248,
      -25.937,
      13.072,
      91.76,
      -24.41,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6443",
    "polygon": [
      90.939,
      -26.859,
      13.083,
      90.939,
      -26.859,
      15.533,
      90.739,
      -27.456,
      15.533,
      90.739,
      -27.456,
      15.533,
      90.739,
      -27.456,
      13.083,
      90.939,
      -26.859,
      13.083
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6444",
    "polygon": [
      90.735,
      -27.469,
      13.077,
      90.735,
      -27.469,
      15.527,
      90.179,
      -29.128,
      15.527,
      90.179,
      -29.128,
      15.527,
      90.179,
      -29.128,
      13.077,
      90.735,
      -27.469,
      13.077
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6445",
    "polygon": [
      90.179,
      -29.128,
      13.069,
      90.179,
      -29.128,
      15.519,
      89.995,
      -29.678,
      15.519,
      89.995,
      -29.678,
      15.519,
      89.995,
      -29.678,
      13.069,
      90.179,
      -29.128,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6446",
    "polygon": [
      90.002,
      -29.657,
      13.069,
      90.002,
      -29.657,
      15.519,
      89.764,
      -30.368,
      15.519,
      89.764,
      -30.368,
      15.519,
      89.764,
      -30.368,
      13.069,
      90.002,
      -29.657,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6447",
    "polygon": [
      89.768,
      -30.356,
      13.072,
      89.768,
      -30.356,
      15.522,
      89.256,
      -31.883,
      15.522,
      89.256,
      -31.883,
      15.522,
      89.256,
      -31.883,
      13.072,
      89.768,
      -30.356,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6448",
    "polygon": [
      88.96,
      -32.769,
      13.083,
      88.96,
      -32.769,
      15.533,
      88.759,
      -33.366,
      15.533,
      88.759,
      -33.366,
      15.533,
      88.759,
      -33.366,
      13.083,
      88.96,
      -32.769,
      13.083
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6449",
    "polygon": [
      88.755,
      -33.379,
      13.077,
      88.755,
      -33.379,
      15.527,
      88.199,
      -35.038,
      15.527,
      88.199,
      -35.038,
      15.527,
      88.199,
      -35.038,
      13.077,
      88.755,
      -33.379,
      13.077
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6450",
    "polygon": [
      88.199,
      -35.038,
      13.069,
      88.199,
      -35.038,
      15.519,
      88.015,
      -35.588,
      15.519,
      88.015,
      -35.588,
      15.519,
      88.015,
      -35.588,
      13.069,
      88.199,
      -35.038,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6451",
    "polygon": [
      88.022,
      -35.567,
      13.069,
      88.022,
      -35.567,
      15.519,
      87.784,
      -36.278,
      15.519,
      87.784,
      -36.278,
      15.519,
      87.784,
      -36.278,
      13.069,
      88.022,
      -35.567,
      13.069
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_3.OG_6452",
    "polygon": [
      87.788,
      -36.266,
      13.072,
      87.788,
      -36.266,
      15.522,
      87.277,
      -37.793,
      15.522,
      87.277,
      -37.793,
      15.522,
      87.277,
      -37.793,
      13.072,
      87.788,
      -36.266,
      13.072
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_6453",
    "polygon": [
      102.851,
      8.712,
      0.708,
      102.851,
      8.712,
      3.468,
      102.651,
      8.115,
      3.468,
      102.651,
      8.115,
      3.468,
      102.651,
      8.115,
      0.708,
      102.851,
      8.712,
      0.708
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6454",
    "polygon": [
      102.647,
      8.102,
      0.702,
      102.647,
      8.102,
      3.462,
      102.091,
      6.443,
      3.462,
      102.091,
      6.443,
      3.462,
      102.091,
      6.443,
      0.702,
      102.647,
      8.102,
      0.702
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6455",
    "polygon": [
      102.091,
      6.443,
      0.694,
      102.091,
      6.443,
      3.454,
      101.907,
      5.893,
      3.454,
      101.907,
      5.893,
      3.454,
      101.907,
      5.893,
      0.694,
      102.091,
      6.443,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6456",
    "polygon": [
      101.907,
      5.893,
      0.694,
      101.907,
      5.893,
      3.454,
      101.683,
      5.225,
      3.454,
      101.683,
      5.225,
      3.454,
      101.683,
      5.225,
      0.694,
      101.907,
      5.893,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6457",
    "polygon": [
      101.683,
      5.225,
      0.697,
      101.683,
      5.225,
      3.457,
      101.165,
      3.679,
      3.457,
      101.165,
      3.679,
      3.457,
      101.165,
      3.679,
      0.697,
      101.683,
      5.225,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_6458",
    "polygon": [
      100.868,
      2.79,
      0.708,
      100.868,
      2.79,
      3.468,
      100.667,
      2.192,
      3.468,
      100.667,
      2.192,
      3.468,
      100.667,
      2.192,
      0.708,
      100.868,
      2.79,
      0.708
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6459",
    "polygon": [
      100.663,
      2.18,
      0.702,
      100.663,
      2.18,
      3.462,
      100.107,
      0.521,
      3.462,
      100.107,
      0.521,
      3.462,
      100.107,
      0.521,
      0.702,
      100.663,
      2.18,
      0.702
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6460",
    "polygon": [
      100.107,
      0.521,
      0.694,
      100.107,
      0.521,
      3.454,
      99.923,
      -0.029,
      3.454,
      99.923,
      -0.029,
      3.454,
      99.923,
      -0.029,
      0.694,
      100.107,
      0.521,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6461",
    "polygon": [
      99.923,
      -0.029,
      0.694,
      99.923,
      -0.029,
      3.454,
      99.699,
      -0.698,
      3.454,
      99.699,
      -0.698,
      3.454,
      99.699,
      -0.698,
      0.694,
      99.923,
      -0.029,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6462",
    "polygon": [
      99.699,
      -0.698,
      0.697,
      99.699,
      -0.698,
      3.457,
      99.182,
      -2.243,
      3.457,
      99.182,
      -2.243,
      3.457,
      99.182,
      -2.243,
      0.697,
      99.699,
      -0.698,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_6463",
    "polygon": [
      98.878,
      -3.15,
      0.708,
      98.878,
      -3.15,
      3.468,
      98.678,
      -3.748,
      3.468,
      98.678,
      -3.748,
      3.468,
      98.678,
      -3.748,
      0.708,
      98.878,
      -3.15,
      0.708
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6464",
    "polygon": [
      98.673,
      -3.76,
      0.702,
      98.673,
      -3.76,
      3.462,
      98.118,
      -5.419,
      3.462,
      98.118,
      -5.419,
      3.462,
      98.118,
      -5.419,
      0.702,
      98.673,
      -3.76,
      0.702
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6465",
    "polygon": [
      98.118,
      -5.419,
      0.694,
      98.118,
      -5.419,
      3.454,
      97.933,
      -5.969,
      3.454,
      97.933,
      -5.969,
      3.454,
      97.933,
      -5.969,
      0.694,
      98.118,
      -5.419,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6466",
    "polygon": [
      97.933,
      -5.969,
      0.694,
      97.933,
      -5.969,
      3.454,
      97.709,
      -6.638,
      3.454,
      97.709,
      -6.638,
      3.454,
      97.709,
      -6.638,
      0.694,
      97.933,
      -5.969,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6467",
    "polygon": [
      97.709,
      -6.638,
      0.697,
      97.709,
      -6.638,
      3.457,
      97.192,
      -8.183,
      3.457,
      97.192,
      -8.183,
      3.457,
      97.192,
      -8.183,
      0.697,
      97.709,
      -6.638,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_6468",
    "polygon": [
      96.895,
      -9.069,
      0.708,
      96.895,
      -9.069,
      3.468,
      96.695,
      -9.667,
      3.468,
      96.695,
      -9.667,
      3.468,
      96.695,
      -9.667,
      0.708,
      96.895,
      -9.069,
      0.708
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6469",
    "polygon": [
      96.691,
      -9.679,
      0.702,
      96.691,
      -9.679,
      3.462,
      96.135,
      -11.339,
      3.462,
      96.135,
      -11.339,
      3.462,
      96.135,
      -11.339,
      0.702,
      96.691,
      -9.679,
      0.702
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6470",
    "polygon": [
      96.135,
      -11.339,
      0.694,
      96.135,
      -11.339,
      3.454,
      95.95,
      -11.888,
      3.454,
      95.95,
      -11.888,
      3.454,
      95.95,
      -11.888,
      0.694,
      96.135,
      -11.339,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6471",
    "polygon": [
      95.95,
      -11.888,
      0.694,
      95.95,
      -11.888,
      3.454,
      95.727,
      -12.557,
      3.454,
      95.727,
      -12.557,
      3.454,
      95.727,
      -12.557,
      0.694,
      95.95,
      -11.888,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6472",
    "polygon": [
      95.727,
      -12.557,
      0.697,
      95.727,
      -12.557,
      3.457,
      95.209,
      -14.103,
      3.457,
      95.209,
      -14.103,
      3.457,
      95.209,
      -14.103,
      0.697,
      95.727,
      -12.557,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Block-E-Outer_EG_6473",
    "polygon": [
      94.91,
      -14.993,
      0.708,
      94.91,
      -14.993,
      3.468,
      94.71,
      -15.591,
      3.468,
      94.71,
      -15.591,
      3.468,
      94.71,
      -15.591,
      0.708,
      94.91,
      -14.993,
      0.708
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "Block-E-Outer_EG_6474",
    "polygon": [
      94.706,
      -15.603,
      0.702,
      94.706,
      -15.603,
      3.462,
      94.15,
      -17.262,
      3.462,
      94.15,
      -17.262,
      3.462,
      94.15,
      -17.262,
      0.702,
      94.706,
      -15.603,
      0.702
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6475",
    "polygon": [
      94.15,
      -17.262,
      0.694,
      94.15,
      -17.262,
      3.454,
      93.966,
      -17.812,
      3.454,
      93.966,
      -17.812,
      3.454,
      93.966,
      -17.812,
      0.694,
      94.15,
      -17.262,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6476",
    "polygon": [
      93.966,
      -17.812,
      0.694,
      93.966,
      -17.812,
      3.454,
      93.742,
      -18.481,
      3.454,
      93.742,
      -18.481,
      3.454,
      93.742,
      -18.481,
      0.694,
      93.966,
      -17.812,
      0.694
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "Block-E-Outer_EG_6477",
    "polygon": [
      93.742,
      -18.481,
      0.697,
      93.742,
      -18.481,
      3.457,
      93.224,
      -20.026,
      3.457,
      93.224,
      -20.026,
      3.457,
      93.224,
      -20.026,
      0.697,
      93.742,
      -18.481,
      0.697
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  },
  {
    "ID": "Magistrale-Interior_EG(A&B)_12287",
    "polygon": [
      57.467,
      39.591,
      0.0,
      57.467,
      39.591,
      4.5,
      56.676,
      39.856,
      4.5,
      56.676,
      39.856,
      4.5,
      56.676,
      39.856,
      0.0,
      57.467,
      39.591,
      0.0
    ]
  },
  {
    "ID": "Magistrale-Interior_EG(A&B)_12288",
    "polygon": [
      55.746,
      40.167,
      0.0,
      55.746,
      40.167,
      4.5,
      54.533,
      40.574,
      4.5,
      54.533,
      40.574,
      4.5,
      54.533,
      40.574,
      0.0,
      55.746,
      40.167,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11922",
    "polygon": [
      -10.345,
      67.728,
      8.75,
      -10.345,
      67.728,
      12.21,
      -9.88,
      67.573,
      12.21,
      -9.88,
      67.573,
      12.21,
      -9.88,
      67.573,
      8.75,
      -10.345,
      67.728,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12006",
    "polygon": [
      -101.262,
      44.487,
      8.75,
      -101.262,
      44.487,
      12.21,
      -101.065,
      45.075,
      12.21,
      -101.065,
      45.075,
      12.21,
      -101.065,
      45.075,
      8.75,
      -101.262,
      44.487,
      8.75
    ],
    "05-22 16:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11849",
    "polygon": [
      -36.667,
      115.384,
      5.0,
      -36.667,
      115.384,
      8.46,
      -36.47,
      115.972,
      8.46,
      -36.47,
      115.972,
      8.46,
      -36.47,
      115.972,
      5.0,
      -36.667,
      115.384,
      5.0
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11826",
    "polygon": [
      -35.926,
      117.597,
      0.0,
      -35.926,
      117.597,
      4.5,
      -35.729,
      118.185,
      4.5,
      -35.729,
      118.185,
      4.5,
      -35.729,
      118.185,
      0.0,
      -35.926,
      117.597,
      0.0
    ],
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11943",
    "polygon": [
      -37.132,
      113.993,
      12.5,
      -37.132,
      113.993,
      15.96,
      -36.935,
      114.581,
      15.96,
      -36.935,
      114.581,
      15.96,
      -36.935,
      114.581,
      12.5,
      -37.132,
      113.993,
      12.5
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11936",
    "polygon": [
      -6.095,
      107.105,
      0.0,
      -6.095,
      107.105,
      4.5,
      -5.631,
      106.95,
      4.5,
      -5.631,
      106.95,
      4.5,
      -5.631,
      106.95,
      0.0,
      -6.095,
      107.105,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11880",
    "polygon": [
      -18.139,
      73.279,
      5.0,
      -18.139,
      73.279,
      8.46,
      -21.268,
      74.327,
      8.46,
      -21.268,
      74.327,
      8.46,
      -21.268,
      74.327,
      5.0,
      -18.139,
      73.279,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11915",
    "polygon": [
      0.107,
      85.191,
      8.75,
      0.107,
      85.191,
      12.21,
      0.466,
      86.262,
      12.21,
      0.466,
      86.262,
      12.21,
      0.466,
      86.262,
      8.75,
      0.107,
      85.191,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12056",
    "polygon": [
      -86.782,
      27.776,
      12.5,
      -86.782,
      27.776,
      15.96,
      -87.247,
      27.932,
      15.96,
      -87.247,
      27.932,
      15.96,
      -87.247,
      27.932,
      12.5,
      -86.782,
      27.776,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11894",
    "polygon": [
      -33.093,
      96.301,
      8.75,
      -33.093,
      96.301,
      12.21,
      -32.726,
      97.37,
      12.21,
      -32.726,
      97.37,
      12.21,
      -32.726,
      97.37,
      8.75,
      -33.093,
      96.301,
      8.75
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12027",
    "polygon": [
      -95.861,
      24.686,
      12.5,
      -95.861,
      24.686,
      15.96,
      -96.449,
      24.883,
      15.96,
      -96.449,
      24.883,
      15.96,
      -96.449,
      24.883,
      12.5,
      -95.861,
      24.686,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11957",
    "polygon": [
      0.296,
      85.755,
      12.5,
      0.296,
      85.755,
      15.96,
      0.951,
      87.708,
      15.96,
      0.951,
      87.708,
      15.96,
      0.951,
      87.708,
      12.5,
      0.296,
      85.755,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11929",
    "polygon": [
      -40.072,
      77.636,
      8.75,
      -40.072,
      77.636,
      12.5,
      -37.521,
      76.781,
      12.5,
      -37.521,
      76.781,
      12.5,
      -37.521,
      76.781,
      8.75,
      -40.072,
      77.636,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11834",
    "polygon": [
      -1.069,
      90.21,
      0.0,
      -1.069,
      90.21,
      4.5,
      -1.265,
      89.622,
      4.5,
      -1.265,
      89.622,
      4.5,
      -1.265,
      89.622,
      0.0,
      -1.069,
      90.21,
      0.0
    ],
    "05-22 04:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11887",
    "polygon": [
      -38.789,
      99.472,
      5.0,
      -38.789,
      99.472,
      8.46,
      -39.085,
      98.59,
      8.46,
      -39.085,
      98.59,
      8.46,
      -39.085,
      98.59,
      5.0,
      -38.789,
      99.472,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11978",
    "polygon": [
      -104.415,
      33.644,
      0.0,
      -104.415,
      33.644,
      5.0,
      -107.222,
      34.584,
      5.0,
      -107.222,
      34.584,
      5.0,
      -107.222,
      34.584,
      0.0,
      -104.415,
      33.644,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11985",
    "polygon": [
      -90.826,
      66.388,
      5.0,
      -90.826,
      66.388,
      8.46,
      -91.48,
      64.434,
      8.46,
      -91.48,
      64.434,
      8.46,
      -91.48,
      64.434,
      5.0,
      -90.826,
      66.388,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11971",
    "polygon": [
      -31.348,
      74.637,
      12.5,
      -31.348,
      74.637,
      15.96,
      -30.883,
      74.481,
      15.96,
      -30.883,
      74.481,
      15.96,
      -30.883,
      74.481,
      12.5,
      -31.348,
      74.637,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12041",
    "polygon": [
      -61.647,
      57.119,
      12.5,
      -61.647,
      57.119,
      15.96,
      -61.183,
      56.963,
      15.96,
      -61.183,
      56.963,
      15.96,
      -61.183,
      56.963,
      12.5,
      -61.647,
      57.119,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11999",
    "polygon": [
      -71.651,
      19.722,
      5.0,
      -71.651,
      19.722,
      8.46,
      -70.579,
      19.366,
      8.46,
      -70.579,
      19.366,
      8.46,
      -70.579,
      19.366,
      5.0,
      -71.651,
      19.722,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12034",
    "polygon": [
      -89.116,
      51.107,
      12.5,
      -89.116,
      51.107,
      15.96,
      -88.758,
      52.179,
      15.96,
      -88.758,
      52.179,
      15.96,
      -88.758,
      52.179,
      12.5,
      -89.116,
      51.107,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12049",
    "polygon": [
      -59.558,
      37.776,
      12.5,
      -59.558,
      37.776,
      15.96,
      -60.213,
      35.823,
      15.96,
      -60.213,
      35.823,
      15.96,
      -60.213,
      35.823,
      12.5,
      -59.558,
      37.776,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11863",
    "polygon": [
      6.131,
      93.635,
      5.0,
      6.131,
      93.635,
      8.46,
      5.935,
      93.047,
      8.46,
      5.935,
      93.047,
      8.46,
      5.935,
      93.047,
      5.0,
      6.131,
      93.635,
      5.0
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11842",
    "polygon": [
      -43.798,
      84.648,
      0.0,
      -43.798,
      84.648,
      4.5,
      -43.995,
      84.06,
      4.5,
      -43.995,
      84.06,
      4.5,
      -43.995,
      84.06,
      0.0,
      -43.798,
      84.648,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11992",
    "polygon": [
      -56.22,
      58.313,
      5.0,
      -56.22,
      58.313,
      8.46,
      -56.684,
      58.469,
      8.46,
      -56.684,
      58.469,
      8.46,
      -56.684,
      58.469,
      5.0,
      -56.22,
      58.313,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12020",
    "polygon": [
      -57.892,
      34.045,
      8.75,
      -57.892,
      34.045,
      12.21,
      -57.604,
      34.93,
      12.21,
      -57.604,
      34.93,
      12.21,
      -57.604,
      34.93,
      8.75,
      -57.892,
      34.045,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11950",
    "polygon": [
      5.698,
      109.242,
      12.5,
      5.698,
      109.242,
      15.96,
      6.286,
      109.045,
      15.96,
      6.286,
      109.045,
      15.96,
      6.286,
      109.045,
      12.5,
      5.698,
      109.242,
      12.5
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11964",
    "polygon": [
      -10.305,
      67.76,
      12.5,
      -10.305,
      67.76,
      15.96,
      -9.84,
      67.604,
      15.96,
      -9.84,
      67.604,
      15.96,
      -9.84,
      67.604,
      12.5,
      -10.305,
      67.76,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12013",
    "polygon": [
      -77.76,
      65.481,
      8.75,
      -77.76,
      65.481,
      12.21,
      -78.644,
      65.768,
      12.21,
      -78.644,
      65.768,
      12.21,
      -78.644,
      65.768,
      8.75,
      -77.76,
      65.481,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11901",
    "polygon": [
      -29.652,
      117.916,
      8.75,
      -29.652,
      117.916,
      12.21,
      -30.724,
      118.274,
      12.21,
      -30.724,
      118.274,
      12.21,
      -30.724,
      118.274,
      8.75,
      -29.652,
      117.916,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11871",
    "polygon": [
      -5.773,
      65.099,
      5.0,
      -5.773,
      65.099,
      8.46,
      -5.928,
      64.634,
      8.46,
      -5.928,
      64.634,
      8.46,
      -5.928,
      64.634,
      5.0,
      -5.773,
      65.099,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11908",
    "polygon": [
      5.315,
      106.206,
      8.75,
      5.315,
      106.206,
      12.21,
      4.243,
      106.563,
      12.21,
      4.243,
      106.563,
      12.21,
      4.243,
      106.563,
      8.75,
      5.315,
      106.206,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12007",
    "polygon": [
      -98.262,
      44.169,
      8.75,
      -98.262,
      44.169,
      12.21,
      -98.621,
      43.097,
      12.21,
      -98.621,
      43.097,
      12.21,
      -98.621,
      43.097,
      8.75,
      -98.262,
      44.169,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11916",
    "polygon": [
      3.306,
      85.294,
      8.75,
      3.306,
      85.294,
      12.21,
      3.109,
      84.706,
      12.21,
      3.109,
      84.706,
      12.21,
      3.109,
      84.706,
      8.75,
      3.306,
      85.294,
      8.75
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11855",
    "polygon": [
      -32.87,
      117.27,
      5.0,
      -32.87,
      117.27,
      8.46,
      -33.166,
      116.388,
      8.46,
      -33.166,
      116.388,
      8.46,
      -33.166,
      116.388,
      5.0,
      -32.87,
      117.27,
      5.0
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11827",
    "polygon": [
      -32.887,
      117.221,
      0.0,
      -32.887,
      117.221,
      4.5,
      -33.246,
      116.15,
      4.5,
      -33.246,
      116.15,
      4.5,
      -33.246,
      116.15,
      0.0,
      -32.887,
      117.221,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11937",
    "polygon": [
      -3.336,
      106.181,
      0.0,
      -3.336,
      106.181,
      4.5,
      -2.871,
      106.025,
      4.5,
      -2.871,
      106.025,
      4.5,
      -2.871,
      106.025,
      0.0,
      -3.336,
      106.181,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11958",
    "polygon": [
      1.255,
      88.615,
      12.5,
      1.255,
      88.615,
      15.96,
      1.452,
      89.203,
      15.96,
      1.452,
      89.203,
      15.96,
      1.452,
      89.203,
      12.5,
      1.255,
      88.615,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11895",
    "polygon": [
      -33.716,
      94.49,
      8.75,
      -33.716,
      94.49,
      12.21,
      -33.413,
      95.37,
      12.21,
      -33.413,
      95.37,
      12.21,
      -33.413,
      95.37,
      8.75,
      -33.716,
      94.49,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11944",
    "polygon": [
      -34.754,
      111.644,
      12.5,
      -34.754,
      111.644,
      15.96,
      -35.05,
      110.762,
      15.96,
      -35.05,
      110.762,
      15.96,
      -35.05,
      110.762,
      12.5,
      -34.754,
      111.644,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11881",
    "polygon": [
      -28.329,
      73.687,
      5.0,
      -28.329,
      73.687,
      8.46,
      -27.864,
      73.531,
      8.46,
      -27.864,
      73.531,
      8.46,
      -27.864,
      73.531,
      5.0,
      -28.329,
      73.687,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11923",
    "polygon": [
      -12.858,
      68.57,
      8.75,
      -12.858,
      68.57,
      12.21,
      -12.393,
      68.414,
      12.21,
      -12.393,
      68.414,
      12.21,
      -12.393,
      68.414,
      8.75,
      -12.858,
      68.57,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12057",
    "polygon": [
      -87.247,
      68.692,
      12.5,
      -87.247,
      68.692,
      15.96,
      -88.129,
      68.987,
      15.96,
      -88.129,
      68.987,
      15.96,
      -88.129,
      68.987,
      12.5,
      -87.247,
      68.692,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12028",
    "polygon": [
      -102.204,
      32.42,
      12.5,
      -102.204,
      32.42,
      15.96,
      -102.401,
      31.832,
      15.96,
      -102.401,
      31.832,
      15.96,
      -102.401,
      31.832,
      12.5,
      -102.204,
      32.42,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12050",
    "polygon": [
      -56.123,
      30.044,
      12.5,
      -56.123,
      30.044,
      15.96,
      -56.315,
      29.454,
      15.96,
      -56.315,
      29.454,
      15.96,
      -56.315,
      29.454,
      12.5,
      -56.123,
      30.044,
      12.5
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11930",
    "polygon": [
      -43.022,
      78.624,
      8.75,
      -43.022,
      78.624,
      12.5,
      -42.092,
      78.313,
      12.5,
      -42.092,
      78.313,
      12.5,
      -42.092,
      78.313,
      8.75,
      -43.022,
      78.624,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11835",
    "polygon": [
      4.101,
      87.693,
      0.0,
      4.101,
      87.693,
      4.5,
      3.904,
      87.106,
      4.5,
      3.904,
      87.106,
      4.5,
      3.904,
      87.106,
      0.0,
      4.101,
      87.693,
      0.0
    ],
    "05-22 04:00": 1,
    "05-22 04:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11986",
    "polygon": [
      -75.456,
      67.85,
      5.0,
      -75.456,
      67.85,
      8.46,
      -74.867,
      67.657,
      8.46,
      -74.867,
      67.657,
      8.46,
      -74.867,
      67.657,
      5.0,
      -75.456,
      67.85,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11979",
    "polygon": [
      -101.249,
      35.27,
      5.0,
      -101.249,
      35.27,
      8.46,
      -101.904,
      33.317,
      8.46,
      -101.904,
      33.317,
      8.46,
      -101.904,
      33.317,
      5.0,
      -101.249,
      35.27,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12035",
    "polygon": [
      -86.102,
      50.776,
      12.5,
      -86.102,
      50.776,
      15.96,
      -86.299,
      50.188,
      15.96,
      -86.299,
      50.188,
      15.96,
      -86.299,
      50.188,
      12.5,
      -86.102,
      50.776,
      12.5
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11965",
    "polygon": [
      -12.834,
      68.607,
      12.5,
      -12.834,
      68.607,
      15.96,
      -12.369,
      68.451,
      15.96,
      -12.369,
      68.451,
      15.96,
      -12.369,
      68.451,
      12.5,
      -12.834,
      68.607,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12021",
    "polygon": [
      -62.126,
      21.519,
      8.75,
      -62.126,
      21.519,
      12.21,
      -61.929,
      22.107,
      12.21,
      -61.929,
      22.107,
      12.21,
      -61.929,
      22.107,
      8.75,
      -62.126,
      21.519,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11872",
    "polygon": [
      -6.66,
      62.451,
      5.0,
      -6.66,
      62.451,
      8.46,
      -6.815,
      61.987,
      8.46,
      -6.815,
      61.987,
      8.46,
      -6.815,
      61.987,
      5.0,
      -6.66,
      62.451,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11909",
    "polygon": [
      6.206,
      103.426,
      8.75,
      6.206,
      103.426,
      12.21,
      6.403,
      104.014,
      12.21,
      6.403,
      104.014,
      12.21,
      6.403,
      104.014,
      8.75,
      6.206,
      103.426,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11993",
    "polygon": [
      -54.107,
      35.994,
      5.0,
      -54.107,
      35.994,
      8.46,
      -54.299,
      35.404,
      8.46,
      -54.299,
      35.404,
      8.46,
      -54.299,
      35.404,
      5.0,
      -54.107,
      35.994,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12042",
    "polygon": [
      -56.309,
      58.343,
      12.5,
      -56.309,
      58.343,
      15.96,
      -56.774,
      58.499,
      15.96,
      -56.774,
      58.499,
      15.96,
      -56.774,
      58.499,
      12.5,
      -56.309,
      58.343,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12000",
    "polygon": [
      -69.688,
      19.07,
      5.0,
      -69.688,
      19.07,
      8.46,
      -68.806,
      18.777,
      8.46,
      -68.806,
      18.777,
      8.46,
      -68.806,
      18.777,
      5.0,
      -69.688,
      19.07,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11843",
    "polygon": [
      -44.299,
      83.153,
      0.0,
      -44.299,
      83.153,
      4.5,
      -44.953,
      81.2,
      4.5,
      -44.953,
      81.2,
      4.5,
      -44.953,
      81.2,
      0.0,
      -44.299,
      83.153,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11888",
    "polygon": [
      -42.578,
      97.604,
      5.0,
      -42.578,
      97.604,
      8.46,
      -42.376,
      98.19,
      8.46,
      -42.376,
      98.19,
      8.46,
      -42.376,
      98.19,
      5.0,
      -42.578,
      97.604,
      5.0
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11972",
    "polygon": [
      -35.829,
      76.138,
      12.5,
      -35.829,
      76.138,
      15.96,
      -35.364,
      75.982,
      15.96,
      -35.364,
      75.982,
      15.96,
      -35.364,
      75.982,
      12.5,
      -35.829,
      76.138,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11951",
    "polygon": [
      3.355,
      106.859,
      12.5,
      3.355,
      106.859,
      15.96,
      2.472,
      107.153,
      15.96,
      2.472,
      107.153,
      15.96,
      2.472,
      107.153,
      12.5,
      3.355,
      106.859,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12014",
    "polygon": [
      -69.931,
      59.894,
      8.75,
      -69.931,
      59.894,
      12.21,
      -69.466,
      59.738,
      12.21,
      -69.466,
      59.738,
      12.21,
      -69.466,
      59.738,
      8.75,
      -69.931,
      59.894,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11902",
    "polygon": [
      -21.249,
      115.095,
      8.75,
      -21.249,
      115.095,
      12.21,
      -21.837,
      115.292,
      12.21,
      -21.837,
      115.292,
      12.21,
      -21.837,
      115.292,
      8.75,
      -21.249,
      115.095,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11864",
    "polygon": [
      -2.046,
      87.163,
      5.0,
      -2.046,
      87.163,
      8.46,
      -2.341,
      86.282,
      8.46,
      -2.341,
      86.282,
      8.46,
      -2.341,
      86.282,
      5.0,
      -2.046,
      87.163,
      5.0
    ],
    "05-22 04:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12008",
    "polygon": [
      -98.927,
      42.183,
      8.75,
      -98.927,
      42.183,
      12.21,
      -99.222,
      41.301,
      12.21,
      -99.222,
      41.301,
      12.21,
      -99.222,
      41.301,
      8.75,
      -98.927,
      42.183,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11917",
    "polygon": [
      -0.636,
      73.553,
      8.75,
      -0.636,
      73.553,
      12.21,
      -0.833,
      72.965,
      12.21,
      -0.833,
      72.965,
      12.21,
      -0.833,
      72.965,
      8.75,
      -0.636,
      73.553,
      8.75
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11828",
    "polygon": [
      -33.542,
      115.265,
      0.0,
      -33.542,
      115.265,
      4.5,
      -33.837,
      114.383,
      4.5,
      -33.837,
      114.383,
      4.5,
      -33.837,
      114.383,
      0.0,
      -33.542,
      115.265,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11856",
    "polygon": [
      -33.477,
      115.458,
      5.0,
      -33.477,
      115.458,
      8.46,
      -33.836,
      114.387,
      8.46,
      -33.836,
      114.387,
      8.46,
      -33.836,
      114.387,
      5.0,
      -33.477,
      115.458,
      5.0
    ],
    "05-22 04:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11938",
    "polygon": [
      -41.795,
      90.598,
      12.5,
      -41.795,
      90.598,
      15.96,
      -41.992,
      90.01,
      15.96,
      -41.992,
      90.01,
      15.96,
      -41.992,
      90.01,
      12.5,
      -41.795,
      90.598,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11945",
    "polygon": [
      -34.088,
      113.632,
      12.5,
      -34.088,
      113.632,
      15.96,
      -34.447,
      112.561,
      15.96,
      -34.447,
      112.561,
      15.96,
      -34.447,
      112.561,
      12.5,
      -34.088,
      113.632,
      12.5
    ],
    "05-22 03:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12058",
    "polygon": [
      -85.272,
      68.031,
      12.5,
      -85.272,
      68.031,
      15.96,
      -86.344,
      68.389,
      15.96,
      -86.344,
      68.389,
      15.96,
      -86.344,
      68.389,
      12.5,
      -85.272,
      68.031,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11959",
    "polygon": [
      -5.141,
      87.451,
      12.5,
      -5.141,
      87.451,
      15.96,
      -4.944,
      88.038,
      15.96,
      -4.944,
      88.038,
      15.96,
      -4.944,
      88.038,
      12.5,
      -5.141,
      87.451,
      12.5
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11924",
    "polygon": [
      -13.233,
      71.551,
      8.75,
      -13.233,
      71.551,
      12.21,
      -17.262,
      72.9,
      12.21,
      -17.262,
      72.9,
      12.21,
      -17.262,
      72.9,
      8.75,
      -13.233,
      71.551,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11896",
    "polygon": [
      -34.552,
      112.249,
      8.75,
      -34.552,
      112.249,
      12.21,
      -34.847,
      111.367,
      12.21,
      -34.847,
      111.367,
      12.21,
      -34.847,
      111.367,
      8.75,
      -34.552,
      112.249,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11882",
    "polygon": [
      -31.32,
      74.689,
      5.0,
      -31.32,
      74.689,
      8.46,
      -30.855,
      74.533,
      8.46,
      -30.855,
      74.533,
      8.46,
      -30.855,
      74.533,
      5.0,
      -31.32,
      74.689,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11931",
    "polygon": [
      -31.377,
      74.708,
      0.0,
      -31.377,
      74.708,
      4.5,
      -30.913,
      74.552,
      4.5,
      -30.913,
      74.552,
      4.5,
      -30.913,
      74.552,
      0.0,
      -31.377,
      74.708,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11836",
    "polygon": [
      0.298,
      85.791,
      0.0,
      0.298,
      85.791,
      4.5,
      0.594,
      86.672,
      4.5,
      0.594,
      86.672,
      4.5,
      0.594,
      86.672,
      0.0,
      0.298,
      85.791,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12029",
    "polygon": [
      -101.247,
      35.277,
      12.5,
      -101.247,
      35.277,
      15.96,
      -101.902,
      33.324,
      15.96,
      -101.902,
      33.324,
      15.96,
      -101.902,
      33.324,
      12.5,
      -101.247,
      35.277,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11980",
    "polygon": [
      -102.209,
      32.406,
      5.0,
      -102.209,
      32.406,
      8.46,
      -102.406,
      31.818,
      8.46,
      -102.406,
      31.818,
      8.46,
      -102.406,
      31.818,
      5.0,
      -102.209,
      32.406,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11987",
    "polygon": [
      -75.784,
      64.838,
      5.0,
      -75.784,
      64.838,
      8.46,
      -76.858,
      65.188,
      8.46,
      -76.858,
      65.188,
      8.46,
      -76.858,
      65.188,
      5.0,
      -75.784,
      64.838,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12051",
    "polygon": [
      -59.315,
      29.919,
      12.5,
      -59.315,
      29.919,
      15.96,
      -58.966,
      30.994,
      15.96,
      -58.966,
      30.994,
      15.96,
      -58.966,
      30.994,
      12.5,
      -59.315,
      29.919,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12036",
    "polygon": [
      -93.319,
      58.945,
      12.5,
      -93.319,
      58.945,
      15.96,
      -93.516,
      58.358,
      15.96,
      -93.516,
      58.358,
      15.96,
      -93.516,
      58.358,
      12.5,
      -93.319,
      58.945,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11966",
    "polygon": [
      -13.222,
      71.547,
      12.5,
      -13.222,
      71.547,
      15.96,
      -17.252,
      72.897,
      15.96,
      -17.252,
      72.897,
      15.96,
      -17.252,
      72.897,
      12.5,
      -13.222,
      71.547,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12022",
    "polygon": [
      -63.082,
      18.666,
      8.75,
      -63.082,
      18.666,
      12.21,
      -62.427,
      20.619,
      12.21,
      -62.427,
      20.619,
      12.21,
      -62.427,
      20.619,
      8.75,
      -63.082,
      18.666,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11873",
    "polygon": [
      -10.115,
      67.758,
      5.0,
      -10.115,
      67.758,
      8.46,
      -9.65,
      67.602,
      8.46,
      -9.65,
      67.602,
      8.46,
      -9.65,
      67.602,
      5.0,
      -10.115,
      67.758,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11910",
    "polygon": [
      5.246,
      100.561,
      8.75,
      5.246,
      100.561,
      12.21,
      5.901,
      102.514,
      12.21,
      5.901,
      102.514,
      12.21,
      5.901,
      102.514,
      8.75,
      5.246,
      100.561,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11973",
    "polygon": [
      -95.796,
      68.876,
      0.0,
      -95.796,
      68.876,
      5.0,
      -92.989,
      67.936,
      5.0,
      -92.989,
      67.936,
      5.0,
      -92.989,
      67.936,
      0.0,
      -95.796,
      68.876,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12001",
    "polygon": [
      -79.885,
      25.466,
      5.0,
      -79.885,
      25.466,
      8.46,
      -80.349,
      25.621,
      8.46,
      -80.349,
      25.621,
      8.46,
      -80.349,
      25.621,
      5.0,
      -79.885,
      25.466,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11844",
    "polygon": [
      -44.614,
      91.661,
      0.0,
      -44.614,
      91.661,
      4.5,
      -44.417,
      92.249,
      4.5,
      -44.417,
      92.249,
      4.5,
      -44.417,
      92.249,
      0.0,
      -44.614,
      91.661,
      0.0
    ],
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11994",
    "polygon": [
      -57.307,
      35.844,
      5.0,
      -57.307,
      35.844,
      8.46,
      -56.958,
      36.919,
      8.46,
      -56.958,
      36.919,
      8.46,
      -56.958,
      36.919,
      5.0,
      -57.307,
      35.844,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11889",
    "polygon": [
      -33.374,
      95.46,
      5.0,
      -33.374,
      95.46,
      8.46,
      -32.72,
      97.413,
      8.46,
      -32.72,
      97.413,
      8.46,
      -32.72,
      97.413,
      5.0,
      -33.374,
      95.46,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12043",
    "polygon": [
      -55.927,
      48.502,
      12.5,
      -55.927,
      48.502,
      15.96,
      -56.082,
      48.037,
      15.96,
      -56.082,
      48.037,
      15.96,
      -56.082,
      48.037,
      12.5,
      -55.927,
      48.502,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11952",
    "polygon": [
      5.33,
      106.201,
      12.5,
      5.33,
      106.201,
      15.96,
      4.258,
      106.558,
      15.96,
      4.258,
      106.558,
      15.96,
      4.258,
      106.558,
      12.5,
      5.33,
      106.201,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11903",
    "polygon": [
      -18.406,
      114.143,
      8.75,
      -18.406,
      114.143,
      12.21,
      -20.359,
      114.797,
      12.21,
      -20.359,
      114.797,
      12.21,
      -20.359,
      114.797,
      8.75,
      -18.406,
      114.143,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12015",
    "polygon": [
      -63.796,
      57.839,
      8.75,
      -63.796,
      57.839,
      12.21,
      -63.332,
      57.683,
      12.21,
      -63.332,
      57.683,
      12.21,
      -63.332,
      57.683,
      8.75,
      -63.796,
      57.839,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11865",
    "polygon": [
      -2.636,
      85.402,
      5.0,
      -2.636,
      85.402,
      8.46,
      -2.995,
      84.33,
      8.46,
      -2.995,
      84.33,
      8.46,
      -2.995,
      84.33,
      5.0,
      -2.636,
      85.402,
      5.0
    ],
    "05-22 03:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12009",
    "polygon": [
      -90.336,
      67.848,
      8.75,
      -90.336,
      67.848,
      12.21,
      -90.533,
      67.26,
      12.21,
      -90.533,
      67.26,
      12.21,
      -90.533,
      67.26,
      8.75,
      -90.336,
      67.848,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11918",
    "polygon": [
      -3.024,
      75.873,
      8.75,
      -3.024,
      75.873,
      12.21,
      -2.729,
      76.755,
      12.21,
      -2.729,
      76.755,
      12.21,
      -2.729,
      76.755,
      8.75,
      -3.024,
      75.873,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11830",
    "polygon": [
      -25.758,
      116.671,
      0.0,
      -25.758,
      116.671,
      4.5,
      -27.711,
      117.326,
      4.5,
      -27.711,
      117.326,
      4.5,
      -27.711,
      117.326,
      0.0,
      -25.758,
      116.671,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11859",
    "polygon": [
      6.226,
      103.485,
      5.0,
      6.226,
      103.485,
      8.46,
      6.423,
      104.073,
      8.46,
      6.423,
      104.073,
      8.46,
      6.423,
      104.073,
      5.0,
      6.226,
      103.485,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11939",
    "polygon": [
      -40.829,
      93.483,
      12.5,
      -40.829,
      93.483,
      15.96,
      -41.483,
      91.529,
      15.96,
      -41.483,
      91.529,
      15.96,
      -41.483,
      91.529,
      12.5,
      -40.829,
      93.483,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11946",
    "polygon": [
      -21.252,
      115.096,
      12.5,
      -21.252,
      115.096,
      15.96,
      -21.84,
      115.293,
      15.96,
      -21.84,
      115.293,
      15.96,
      -21.84,
      115.293,
      12.5,
      -21.252,
      115.096,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12059",
    "polygon": [
      -79.537,
      66.109,
      12.5,
      -79.537,
      66.109,
      15.96,
      -81.604,
      66.801,
      15.96,
      -81.604,
      66.801,
      15.96,
      -81.604,
      66.801,
      12.5,
      -79.537,
      66.109,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11960",
    "polygon": [
      -2.761,
      85.109,
      12.5,
      -2.761,
      85.109,
      15.96,
      -3.056,
      84.227,
      15.96,
      -3.056,
      84.227,
      15.96,
      -3.056,
      84.227,
      12.5,
      -2.761,
      85.109,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11897",
    "polygon": [
      -33.896,
      114.205,
      8.75,
      -33.896,
      114.205,
      12.21,
      -34.255,
      113.133,
      12.21,
      -34.255,
      113.133,
      12.21,
      -34.255,
      113.133,
      8.75,
      -33.896,
      114.205,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11925",
    "polygon": [
      -18.216,
      73.22,
      8.75,
      -18.216,
      73.22,
      12.21,
      -21.345,
      74.268,
      12.21,
      -21.345,
      74.268,
      12.21,
      -21.345,
      74.268,
      8.75,
      -18.216,
      73.22,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11883",
    "polygon": [
      -42.566,
      88.196,
      5.0,
      -42.566,
      88.196,
      8.46,
      -42.925,
      87.125,
      8.46,
      -42.925,
      87.125,
      8.46,
      -42.925,
      87.125,
      5.0,
      -42.566,
      88.196,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12052",
    "polygon": [
      -59.894,
      28.138,
      12.5,
      -59.894,
      28.138,
      15.96,
      -59.606,
      29.022,
      15.96,
      -59.606,
      29.022,
      15.96,
      -59.606,
      29.022,
      12.5,
      -59.894,
      28.138,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12030",
    "polygon": [
      -100.477,
      46.886,
      12.5,
      -100.477,
      46.886,
      15.96,
      -100.28,
      47.474,
      15.96,
      -100.28,
      47.474,
      15.96,
      -100.28,
      47.474,
      12.5,
      -100.477,
      46.886,
      12.5
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11837",
    "polygon": [
      0.907,
      87.608,
      0.0,
      0.907,
      87.608,
      4.5,
      1.266,
      88.68,
      4.5,
      1.266,
      88.68,
      4.5,
      1.266,
      88.68,
      0.0,
      0.907,
      87.608,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11981",
    "polygon": [
      -101.263,
      44.484,
      5.0,
      -101.263,
      44.484,
      8.46,
      -101.066,
      45.072,
      8.46,
      -101.066,
      45.072,
      8.46,
      -101.066,
      45.072,
      5.0,
      -101.263,
      44.484,
      5.0
    ],
    "05-22 16:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11932",
    "polygon": [
      -20.019,
      73.909,
      0.0,
      -20.019,
      73.909,
      4.5,
      -21.289,
      74.334,
      4.5,
      -21.289,
      74.334,
      4.5,
      -21.289,
      74.334,
      0.0,
      -20.019,
      73.909,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11988",
    "polygon": [
      -77.768,
      65.483,
      5.0,
      -77.768,
      65.483,
      8.46,
      -78.653,
      65.771,
      8.46,
      -78.653,
      65.771,
      8.46,
      -78.653,
      65.771,
      5.0,
      -77.768,
      65.483,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11967",
    "polygon": [
      -18.194,
      73.213,
      12.5,
      -18.194,
      73.213,
      15.96,
      -21.324,
      74.261,
      15.96,
      -21.324,
      74.261,
      15.96,
      -21.324,
      74.261,
      12.5,
      -18.194,
      73.213,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12023",
    "polygon": [
      -79.849,
      25.453,
      8.75,
      -79.849,
      25.453,
      12.21,
      -80.313,
      25.609,
      12.21,
      -80.313,
      25.609,
      12.21,
      -80.313,
      25.609,
      8.75,
      -79.849,
      25.453,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12037",
    "polygon": [
      -93.804,
      57.496,
      12.5,
      -93.804,
      57.496,
      15.96,
      -94.459,
      55.543,
      15.96,
      -94.459,
      55.543,
      15.96,
      -94.459,
      55.543,
      12.5,
      -93.804,
      57.496,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11911",
    "polygon": [
      -3.161,
      93.39,
      8.75,
      -3.161,
      93.39,
      12.21,
      -2.964,
      93.978,
      12.21,
      -2.964,
      93.978,
      12.21,
      -2.964,
      93.978,
      8.75,
      -3.161,
      93.39,
      8.75
    ],
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12002",
    "polygon": [
      -84.001,
      26.844,
      5.0,
      -84.001,
      26.844,
      8.46,
      -84.465,
      27.0,
      8.46,
      -84.465,
      27.0,
      8.46,
      -84.465,
      27.0,
      5.0,
      -84.001,
      26.844,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11974",
    "polygon": [
      -93.041,
      67.964,
      0.0,
      -93.041,
      67.964,
      5.0,
      -94.586,
      63.351,
      5.0,
      -94.586,
      63.351,
      5.0,
      -94.586,
      63.351,
      0.0,
      -93.041,
      67.964,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11874",
    "polygon": [
      -12.775,
      68.649,
      5.0,
      -12.775,
      68.649,
      8.46,
      -12.31,
      68.493,
      8.46,
      -12.31,
      68.493,
      8.46,
      -12.31,
      68.493,
      5.0,
      -12.775,
      68.649,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11845",
    "polygon": [
      -41.42,
      91.747,
      0.0,
      -41.42,
      91.747,
      4.5,
      -41.779,
      90.675,
      4.5,
      -41.779,
      90.675,
      4.5,
      -41.779,
      90.675,
      0.0,
      -41.42,
      91.747,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11995",
    "polygon": [
      -57.893,
      34.041,
      5.0,
      -57.893,
      34.041,
      8.46,
      -57.606,
      34.926,
      8.46,
      -57.606,
      34.926,
      8.46,
      -57.606,
      34.926,
      5.0,
      -57.893,
      34.041,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12045",
    "polygon": [
      -50.194,
      47.78,
      12.5,
      -50.194,
      47.78,
      15.96,
      -50.391,
      47.192,
      15.96,
      -50.391,
      47.192,
      15.96,
      -50.391,
      47.192,
      12.5,
      -50.194,
      47.78,
      12.5
    ],
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11890",
    "polygon": [
      -33.869,
      93.982,
      5.0,
      -33.869,
      93.982,
      8.46,
      -33.672,
      94.57,
      8.46,
      -33.672,
      94.57,
      8.46,
      -33.672,
      94.57,
      5.0,
      -33.869,
      93.982,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11953",
    "polygon": [
      3.782,
      96.158,
      12.5,
      3.782,
      96.158,
      15.96,
      4.436,
      98.112,
      15.96,
      4.436,
      98.112,
      15.96,
      4.436,
      98.112,
      12.5,
      3.782,
      96.158,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11904",
    "polygon": [
      -9.429,
      108.175,
      8.75,
      -9.429,
      108.175,
      12.21,
      -8.965,
      108.02,
      12.21,
      -8.965,
      108.02,
      12.21,
      -8.965,
      108.02,
      8.75,
      -9.429,
      108.175,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12016",
    "polygon": [
      -56.209,
      58.31,
      8.75,
      -56.209,
      58.31,
      12.21,
      -56.673,
      58.465,
      12.21,
      -56.673,
      58.465,
      12.21,
      -56.673,
      58.465,
      8.75,
      -56.209,
      58.31,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11866",
    "polygon": [
      -5.846,
      85.267,
      5.0,
      -5.846,
      85.267,
      8.46,
      -5.649,
      85.855,
      8.46,
      -5.649,
      85.855,
      8.46,
      -5.649,
      85.855,
      5.0,
      -5.846,
      85.267,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12010",
    "polygon": [
      -90.824,
      66.393,
      8.75,
      -90.824,
      66.393,
      12.21,
      -91.478,
      64.44,
      12.21,
      -91.478,
      64.44,
      12.21,
      -91.478,
      64.44,
      8.75,
      -90.824,
      66.393,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11919",
    "polygon": [
      -3.687,
      73.892,
      8.75,
      -3.687,
      73.892,
      12.21,
      -3.328,
      74.963,
      12.21,
      -3.328,
      74.963,
      12.21,
      -3.328,
      74.963,
      8.75,
      -3.687,
      73.892,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11831",
    "polygon": [
      5.267,
      100.623,
      0.0,
      5.267,
      100.623,
      4.5,
      5.464,
      101.21,
      4.5,
      5.464,
      101.21,
      4.5,
      5.464,
      101.21,
      0.0,
      5.267,
      100.623,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11860",
    "polygon": [
      5.281,
      100.664,
      5.0,
      5.281,
      100.664,
      8.46,
      5.935,
      102.618,
      8.46,
      5.935,
      102.618,
      8.46,
      5.935,
      102.618,
      5.0,
      5.281,
      100.664,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11940",
    "polygon": [
      -29.92,
      96.377,
      12.5,
      -29.92,
      96.377,
      15.96,
      -30.117,
      95.789,
      15.96,
      -30.117,
      95.789,
      15.96,
      -30.117,
      95.789,
      12.5,
      -29.92,
      96.377,
      12.5
    ],
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11947",
    "polygon": [
      -18.371,
      114.131,
      12.5,
      -18.371,
      114.131,
      15.96,
      -20.325,
      114.786,
      15.96,
      -20.325,
      114.786,
      15.96,
      -20.325,
      114.786,
      12.5,
      -18.371,
      114.131,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12060",
    "polygon": [
      -76.385,
      65.054,
      12.5,
      -76.385,
      65.054,
      15.96,
      -78.642,
      65.81,
      15.96,
      -78.642,
      65.81,
      15.96,
      -78.642,
      65.81,
      12.5,
      -76.385,
      65.054,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11898",
    "polygon": [
      -36.937,
      114.577,
      8.75,
      -36.937,
      114.577,
      12.21,
      -36.74,
      115.165,
      12.21,
      -36.74,
      115.165,
      12.21,
      -36.74,
      115.165,
      8.75,
      -36.937,
      114.577,
      8.75
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11961",
    "polygon": [
      -2.099,
      87.084,
      12.5,
      -2.099,
      87.084,
      15.96,
      -2.458,
      86.013,
      15.96,
      -2.458,
      86.013,
      15.96,
      -2.458,
      86.013,
      12.5,
      -2.099,
      87.084,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11926",
    "polygon": [
      -22.173,
      71.691,
      8.75,
      -22.173,
      71.691,
      12.21,
      -19.044,
      70.642,
      12.21,
      -19.044,
      70.642,
      12.21,
      -19.044,
      70.642,
      8.75,
      -22.173,
      71.691,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11884",
    "polygon": [
      -41.965,
      89.989,
      5.0,
      -41.965,
      89.989,
      8.46,
      -42.261,
      89.107,
      8.46,
      -42.261,
      89.107,
      8.46,
      -42.261,
      89.107,
      5.0,
      -41.965,
      89.989,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12053",
    "polygon": [
      -68.792,
      18.773,
      12.5,
      -68.792,
      18.773,
      15.96,
      -68.203,
      18.577,
      15.96,
      -68.203,
      18.577,
      15.96,
      -68.203,
      18.577,
      12.5,
      -68.792,
      18.773,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12031",
    "polygon": [
      -97.473,
      46.523,
      12.5,
      -97.473,
      46.523,
      15.96,
      -97.832,
      45.452,
      15.96,
      -97.832,
      45.452,
      15.96,
      -97.832,
      45.452,
      12.5,
      -97.473,
      46.523,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11838",
    "polygon": [
      -0.62,
      73.601,
      0.0,
      -0.62,
      73.601,
      4.5,
      -0.817,
      73.013,
      4.5,
      -0.817,
      73.013,
      4.5,
      -0.817,
      73.013,
      0.0,
      -0.62,
      73.601,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11933",
    "polygon": [
      -14.978,
      72.22,
      0.0,
      -14.978,
      72.22,
      4.5,
      -18.705,
      73.469,
      4.5,
      -18.705,
      73.469,
      4.5,
      -18.705,
      73.469,
      0.0,
      -14.978,
      72.22,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11982",
    "polygon": [
      -98.264,
      44.162,
      5.0,
      -98.264,
      44.162,
      8.46,
      -98.623,
      43.091,
      8.46,
      -98.623,
      43.091,
      8.46,
      -98.623,
      43.091,
      5.0,
      -98.264,
      44.162,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11989",
    "polygon": [
      -69.931,
      59.894,
      5.0,
      -69.931,
      59.894,
      8.46,
      -69.466,
      59.738,
      8.46,
      -69.466,
      59.738,
      8.46,
      -69.466,
      59.738,
      5.0,
      -69.931,
      59.894,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11912",
    "polygon": [
      -0.121,
      93.018,
      8.75,
      -0.121,
      93.018,
      12.21,
      -0.48,
      91.947,
      12.21,
      -0.48,
      91.947,
      12.21,
      -0.48,
      91.947,
      8.75,
      -0.121,
      93.018,
      8.75
    ],
    "05-22 04:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11968",
    "polygon": [
      -16.996,
      70.001,
      12.5,
      -16.996,
      70.001,
      15.96,
      -14.341,
      69.112,
      15.96,
      -14.341,
      69.112,
      15.96,
      -14.341,
      69.112,
      12.5,
      -16.996,
      70.001,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12024",
    "polygon": [
      -86.784,
      27.777,
      8.75,
      -86.784,
      27.777,
      12.21,
      -87.249,
      27.932,
      12.21,
      -87.249,
      27.932,
      12.21,
      -87.249,
      27.932,
      8.75,
      -86.784,
      27.777,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12038",
    "polygon": [
      -90.946,
      66.028,
      12.5,
      -90.946,
      66.028,
      15.96,
      -91.102,
      65.564,
      15.96,
      -91.102,
      65.564,
      15.96,
      -91.102,
      65.564,
      12.5,
      -90.946,
      66.028,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11846",
    "polygon": [
      -40.825,
      93.524,
      0.0,
      -40.825,
      93.524,
      4.5,
      -41.12,
      92.643,
      4.5,
      -41.12,
      92.643,
      4.5,
      -41.12,
      92.643,
      0.0,
      -40.825,
      93.524,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12003",
    "polygon": [
      -86.754,
      27.766,
      5.0,
      -86.754,
      27.766,
      8.46,
      -87.218,
      27.922,
      8.46,
      -87.218,
      27.922,
      8.46,
      -87.218,
      27.922,
      5.0,
      -86.754,
      27.766,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11975",
    "polygon": [
      -94.477,
      63.302,
      0.0,
      -94.477,
      63.302,
      5.0,
      -97.283,
      64.242,
      5.0,
      -97.283,
      64.242,
      5.0,
      -97.283,
      64.242,
      0.0,
      -94.477,
      63.302,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11876",
    "polygon": [
      -16.958,
      70.05,
      5.0,
      -16.958,
      70.05,
      8.46,
      -14.303,
      69.161,
      8.46,
      -14.303,
      69.161,
      8.46,
      -14.303,
      69.161,
      5.0,
      -16.958,
      70.05,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11891",
    "polygon": [
      -41.322,
      92.011,
      8.75,
      -41.322,
      92.011,
      12.21,
      -41.976,
      90.057,
      12.21,
      -41.976,
      90.057,
      12.21,
      -41.976,
      90.057,
      8.75,
      -41.322,
      92.011,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11996",
    "polygon": [
      -62.118,
      21.542,
      5.0,
      -62.118,
      21.542,
      8.46,
      -61.921,
      22.13,
      8.46,
      -61.921,
      22.13,
      8.46,
      -61.921,
      22.13,
      5.0,
      -62.118,
      21.542,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12046",
    "polygon": [
      -53.35,
      47.688,
      12.5,
      -53.35,
      47.688,
      15.96,
      -52.991,
      48.759,
      15.96,
      -52.991,
      48.759,
      15.96,
      -52.991,
      48.759,
      12.5,
      -53.35,
      47.688,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11954",
    "polygon": [
      3.27,
      94.632,
      12.5,
      3.27,
      94.632,
      15.96,
      3.467,
      95.22,
      15.96,
      3.467,
      95.22,
      15.96,
      3.467,
      95.22,
      12.5,
      3.27,
      94.632,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11905",
    "polygon": [
      -3.288,
      106.118,
      8.75,
      -3.288,
      106.118,
      12.21,
      -2.823,
      105.962,
      12.21,
      -2.823,
      105.962,
      12.21,
      -2.823,
      105.962,
      8.75,
      -3.288,
      106.118,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11867",
    "polygon": [
      -0.622,
      73.596,
      5.0,
      -0.622,
      73.596,
      8.46,
      -0.819,
      73.008,
      8.46,
      -0.819,
      73.008,
      8.46,
      -0.819,
      73.008,
      5.0,
      -0.622,
      73.596,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12017",
    "polygon": [
      -53.481,
      57.396,
      8.75,
      -53.481,
      57.396,
      12.21,
      -53.946,
      57.552,
      12.21,
      -53.946,
      57.552,
      12.21,
      -53.946,
      57.552,
      8.75,
      -53.481,
      57.396,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12011",
    "polygon": [
      -75.455,
      67.85,
      8.75,
      -75.455,
      67.85,
      12.21,
      -74.866,
      67.657,
      12.21,
      -74.866,
      67.657,
      12.21,
      -74.866,
      67.657,
      8.75,
      -75.455,
      67.85,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11920",
    "polygon": [
      -6.73,
      62.356,
      8.75,
      -6.73,
      62.356,
      12.21,
      -6.888,
      61.892,
      12.21,
      -6.888,
      61.892,
      12.21,
      -6.888,
      61.892,
      8.75,
      -6.73,
      62.356,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11832",
    "polygon": [
      5.765,
      102.108,
      0.0,
      5.765,
      102.108,
      4.5,
      6.419,
      104.062,
      4.5,
      6.419,
      104.062,
      4.5,
      6.419,
      104.062,
      0.0,
      5.765,
      102.108,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11861",
    "polygon": [
      2.933,
      93.536,
      5.0,
      2.933,
      93.536,
      8.46,
      3.292,
      94.607,
      8.46,
      3.292,
      94.607,
      8.46,
      3.292,
      94.607,
      5.0,
      2.933,
      93.536,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11941",
    "polygon": [
      -33.103,
      96.272,
      12.5,
      -33.103,
      96.272,
      15.96,
      -32.736,
      97.341,
      15.96,
      -32.736,
      97.341,
      15.96,
      -32.736,
      97.341,
      12.5,
      -33.103,
      96.272,
      12.5
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12061",
    "polygon": [
      -25.628,
      4.143,
      0.0,
      -25.628,
      4.143,
      5.0,
      -23.611,
      3.458,
      5.0,
      -23.611,
      3.458,
      5.0,
      -23.611,
      3.458,
      0.0,
      -25.628,
      4.143,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11948",
    "polygon": [
      -9.405,
      108.167,
      12.5,
      -9.405,
      108.167,
      15.96,
      -8.94,
      108.011,
      15.96,
      -8.94,
      108.011,
      15.96,
      -8.94,
      108.011,
      12.5,
      -9.405,
      108.167,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11899",
    "polygon": [
      -29.74,
      121.113,
      8.75,
      -29.74,
      121.113,
      12.21,
      -29.153,
      120.916,
      12.21,
      -29.153,
      120.916,
      12.21,
      -29.153,
      120.916,
      8.75,
      -29.74,
      121.113,
      8.75
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11927",
    "polygon": [
      -17.021,
      69.965,
      8.75,
      -17.021,
      69.965,
      12.21,
      -14.366,
      69.075,
      12.21,
      -14.366,
      69.075,
      12.21,
      -14.366,
      69.075,
      8.75,
      -17.021,
      69.965,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11885",
    "polygon": [
      -45.755,
      88.123,
      5.0,
      -45.755,
      88.123,
      8.46,
      -45.558,
      88.711,
      8.46,
      -45.558,
      88.711,
      8.46,
      -45.558,
      88.711,
      5.0,
      -45.755,
      88.123,
      5.0
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11962",
    "polygon": [
      -5.82,
      64.988,
      12.5,
      -5.82,
      64.988,
      15.96,
      -5.978,
      64.523,
      15.96,
      -5.978,
      64.523,
      15.96,
      -5.978,
      64.523,
      12.5,
      -5.82,
      64.988,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11840",
    "polygon": [
      -3.662,
      73.966,
      0.0,
      -3.662,
      73.966,
      4.5,
      -3.304,
      75.038,
      4.5,
      -3.304,
      75.038,
      4.5,
      -3.304,
      75.038,
      0.0,
      -3.662,
      73.966,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12032",
    "polygon": [
      -98.127,
      44.57,
      12.5,
      -98.127,
      44.57,
      15.96,
      -98.423,
      43.688,
      15.96,
      -98.423,
      43.688,
      15.96,
      -98.423,
      43.688,
      12.5,
      -98.127,
      44.57,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12054",
    "polygon": [
      -71.659,
      19.724,
      12.5,
      -71.659,
      19.724,
      15.96,
      -69.704,
      19.075,
      15.96,
      -69.704,
      19.075,
      15.96,
      -69.704,
      19.075,
      12.5,
      -71.659,
      19.724,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11934",
    "polygon": [
      -13.216,
      71.63,
      0.0,
      -13.216,
      71.63,
      4.5,
      -14.032,
      71.903,
      4.5,
      -14.032,
      71.903,
      4.5,
      -14.032,
      71.903,
      0.0,
      -13.216,
      71.63,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11990",
    "polygon": [
      -66.181,
      58.638,
      5.0,
      -66.181,
      58.638,
      8.46,
      -65.716,
      58.482,
      8.46,
      -65.716,
      58.482,
      8.46,
      -65.716,
      58.482,
      5.0,
      -66.181,
      58.638,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11983",
    "polygon": [
      -98.931,
      42.171,
      5.0,
      -98.931,
      42.171,
      8.46,
      -99.226,
      41.289,
      8.46,
      -99.226,
      41.289,
      8.46,
      -99.226,
      41.289,
      5.0,
      -98.931,
      42.171,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11913",
    "polygon": [
      -0.787,
      91.031,
      8.75,
      -0.787,
      91.031,
      12.21,
      -1.082,
      90.149,
      12.21,
      -1.082,
      90.149,
      12.21,
      -1.082,
      90.149,
      8.75,
      -0.787,
      91.031,
      8.75
    ],
    "05-22 03:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11969",
    "polygon": [
      -22.155,
      71.729,
      12.5,
      -22.155,
      71.729,
      15.96,
      -19.026,
      70.681,
      15.96,
      -19.026,
      70.681,
      15.96,
      -19.026,
      70.681,
      12.5,
      -22.155,
      71.729,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12025",
    "polygon": [
      -95.977,
      27.846,
      12.5,
      -95.977,
      27.846,
      15.96,
      -94.906,
      27.487,
      15.96,
      -94.906,
      27.487,
      15.96,
      -94.906,
      27.487,
      12.5,
      -95.977,
      27.846,
      12.5
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12039",
    "polygon": [
      -77.901,
      62.688,
      12.5,
      -77.901,
      62.688,
      15.96,
      -77.437,
      62.533,
      15.96,
      -77.437,
      62.533,
      15.96,
      -77.437,
      62.533,
      12.5,
      -77.901,
      62.688,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11847",
    "polygon": [
      -32.921,
      96.813,
      0.0,
      -32.921,
      96.813,
      4.5,
      -32.724,
      97.401,
      4.5,
      -32.724,
      97.401,
      4.5,
      -32.724,
      97.401,
      0.0,
      -32.921,
      96.813,
      0.0
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11976",
    "polygon": [
      -102.943,
      38.325,
      0.0,
      -102.943,
      38.325,
      5.0,
      -104.489,
      33.712,
      5.0,
      -104.489,
      33.712,
      5.0,
      -104.489,
      33.712,
      0.0,
      -102.943,
      38.325,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12004",
    "polygon": [
      -102.207,
      32.412,
      8.75,
      -102.207,
      32.412,
      12.21,
      -102.404,
      31.824,
      12.21,
      -102.404,
      31.824,
      12.21,
      -102.404,
      31.824,
      8.75,
      -102.207,
      32.412,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11878",
    "polygon": [
      -22.121,
      71.78,
      5.0,
      -22.121,
      71.78,
      8.46,
      -18.992,
      70.732,
      8.46,
      -18.992,
      70.732,
      8.46,
      -18.992,
      70.732,
      5.0,
      -22.121,
      71.78,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11955",
    "polygon": [
      0.881,
      95.981,
      12.5,
      0.881,
      95.981,
      15.96,
      0.684,
      95.393,
      15.96,
      0.684,
      95.393,
      15.96,
      0.684,
      95.393,
      12.5,
      0.881,
      95.981,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11997",
    "polygon": [
      -63.085,
      18.657,
      5.0,
      -63.085,
      18.657,
      8.46,
      -62.43,
      20.61,
      8.46,
      -62.43,
      20.61,
      8.46,
      -62.43,
      20.61,
      5.0,
      -63.085,
      18.657,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11892",
    "polygon": [
      -40.82,
      93.51,
      8.75,
      -40.82,
      93.51,
      12.21,
      -41.017,
      92.922,
      12.21,
      -41.017,
      92.922,
      12.21,
      -41.017,
      92.922,
      8.75,
      -40.82,
      93.51,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12047",
    "polygon": [
      -53.944,
      45.917,
      12.5,
      -53.944,
      45.917,
      15.96,
      -53.648,
      46.798,
      15.96,
      -53.648,
      46.798,
      15.96,
      -53.648,
      46.798,
      12.5,
      -53.944,
      45.917,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11868",
    "polygon": [
      -2.999,
      75.946,
      5.0,
      -2.999,
      75.946,
      8.46,
      -2.704,
      76.828,
      8.46,
      -2.704,
      76.828,
      8.46,
      -2.704,
      76.828,
      5.0,
      -2.999,
      75.946,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12018",
    "polygon": [
      -54.108,
      35.991,
      8.75,
      -54.108,
      35.991,
      12.21,
      -54.3,
      35.401,
      12.21,
      -54.3,
      35.401,
      12.21,
      -54.3,
      35.401,
      8.75,
      -54.108,
      35.991,
      8.75
    ],
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11906",
    "polygon": [
      5.677,
      109.249,
      8.75,
      5.677,
      109.249,
      12.21,
      6.265,
      109.052,
      12.21,
      6.265,
      109.052,
      12.21,
      6.265,
      109.052,
      8.75,
      5.677,
      109.249,
      8.75
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12012",
    "polygon": [
      -75.777,
      64.836,
      8.75,
      -75.777,
      64.836,
      12.21,
      -76.852,
      65.186,
      12.21,
      -76.852,
      65.186,
      12.21,
      -76.852,
      65.186,
      8.75,
      -75.777,
      64.836,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11921",
    "polygon": [
      -5.834,
      65.0,
      8.75,
      -5.834,
      65.0,
      12.21,
      -5.991,
      64.536,
      12.21,
      -5.991,
      64.536,
      12.21,
      -5.991,
      64.536,
      8.75,
      -5.834,
      65.0,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11862",
    "polygon": [
      2.337,
      91.756,
      5.0,
      2.337,
      91.756,
      8.46,
      2.633,
      92.638,
      8.46,
      2.633,
      92.638,
      8.46,
      2.633,
      92.638,
      5.0,
      2.337,
      91.756,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11942",
    "polygon": [
      -33.708,
      94.514,
      12.5,
      -33.708,
      94.514,
      15.96,
      -33.405,
      95.393,
      15.96,
      -33.405,
      95.393,
      15.96,
      -33.405,
      95.393,
      12.5,
      -33.708,
      94.514,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11833",
    "polygon": [
      -0.116,
      93.053,
      0.0,
      -0.116,
      93.053,
      4.5,
      -0.771,
      91.099,
      4.5,
      -0.771,
      91.099,
      4.5,
      -0.771,
      91.099,
      0.0,
      -0.116,
      93.053,
      0.0
    ],
    "05-22 04:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11949",
    "polygon": [
      -3.303,
      106.123,
      12.5,
      -3.303,
      106.123,
      15.96,
      -2.838,
      105.967,
      15.96,
      -2.838,
      105.967,
      15.96,
      -2.838,
      105.967,
      12.5,
      -3.303,
      106.123,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12062",
    "polygon": [
      -19.75,
      2.149,
      0.0,
      -19.75,
      2.149,
      5.0,
      -17.733,
      1.465,
      5.0,
      -17.733,
      1.465,
      5.0,
      -17.733,
      1.465,
      0.0,
      -19.75,
      2.149,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11900",
    "polygon": [
      -27.872,
      117.323,
      8.75,
      -27.872,
      117.323,
      12.21,
      -28.755,
      117.617,
      12.21,
      -28.755,
      117.617,
      12.21,
      -28.755,
      117.617,
      8.75,
      -27.872,
      117.323,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11963",
    "polygon": [
      -6.708,
      62.37,
      12.5,
      -6.708,
      62.37,
      15.96,
      -6.866,
      61.906,
      15.96,
      -6.866,
      61.906,
      15.96,
      -6.866,
      61.906,
      12.5,
      -6.708,
      62.37,
      12.5
    ],
    "05-22 04:30": 1,
    "05-22 04:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11886",
    "polygon": [
      -39.389,
      97.679,
      5.0,
      -39.389,
      97.679,
      8.46,
      -39.748,
      96.607,
      8.46,
      -39.748,
      96.607,
      8.46,
      -39.748,
      96.607,
      5.0,
      -39.389,
      97.679,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11928",
    "polygon": [
      -31.331,
      74.586,
      8.75,
      -31.331,
      74.586,
      12.21,
      -30.866,
      74.43,
      12.21,
      -30.866,
      74.43,
      12.21,
      -30.866,
      74.43,
      8.75,
      -31.331,
      74.586,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11841",
    "polygon": [
      -3.005,
      75.93,
      0.0,
      -3.005,
      75.93,
      4.5,
      -2.709,
      76.812,
      4.5,
      -2.709,
      76.812,
      4.5,
      -2.709,
      76.812,
      0.0,
      -3.005,
      75.93,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12033",
    "polygon": [
      -88.451,
      53.095,
      12.5,
      -88.451,
      53.095,
      15.96,
      -88.155,
      53.977,
      15.96,
      -88.155,
      53.977,
      15.96,
      -88.155,
      53.977,
      12.5,
      -88.451,
      53.095,
      12.5
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12055",
    "polygon": [
      -79.854,
      25.455,
      12.5,
      -79.854,
      25.455,
      15.96,
      -80.319,
      25.611,
      15.96,
      -80.319,
      25.611,
      15.96,
      -80.319,
      25.611,
      12.5,
      -79.854,
      25.455,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11935",
    "polygon": [
      -9.394,
      108.21,
      0.0,
      -9.394,
      108.21,
      4.5,
      -8.93,
      108.055,
      4.5,
      -8.93,
      108.055,
      4.5,
      -8.93,
      108.055,
      0.0,
      -9.394,
      108.21,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11991",
    "polygon": [
      -63.837,
      57.853,
      5.0,
      -63.837,
      57.853,
      8.46,
      -63.372,
      57.697,
      8.46,
      -63.372,
      57.697,
      8.46,
      -63.372,
      57.697,
      5.0,
      -63.837,
      57.853,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11970",
    "polygon": [
      -28.354,
      73.634,
      12.5,
      -28.354,
      73.634,
      15.96,
      -27.89,
      73.478,
      15.96,
      -27.89,
      73.478,
      15.96,
      -27.89,
      73.478,
      12.5,
      -28.354,
      73.634,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12026",
    "polygon": [
      -97.743,
      28.438,
      12.5,
      -97.743,
      28.438,
      15.96,
      -96.861,
      28.142,
      15.96,
      -96.861,
      28.142,
      15.96,
      -96.861,
      28.142,
      12.5,
      -97.743,
      28.438,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11914",
    "polygon": [
      -0.506,
      83.361,
      8.75,
      -0.506,
      83.361,
      12.21,
      -0.211,
      84.242,
      12.21,
      -0.211,
      84.242,
      12.21,
      -0.211,
      84.242,
      8.75,
      -0.506,
      83.361,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11984",
    "polygon": [
      -90.331,
      67.864,
      5.0,
      -90.331,
      67.864,
      8.46,
      -90.528,
      67.276,
      8.46,
      -90.528,
      67.276,
      8.46,
      -90.528,
      67.276,
      5.0,
      -90.331,
      67.864,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12040",
    "polygon": [
      -68.946,
      59.564,
      12.5,
      -68.946,
      59.564,
      15.96,
      -68.482,
      59.408,
      15.96,
      -68.482,
      59.408,
      15.96,
      -68.482,
      59.408,
      12.5,
      -68.946,
      59.564,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12005",
    "polygon": [
      -101.252,
      35.262,
      8.75,
      -101.252,
      35.262,
      12.21,
      -101.907,
      33.309,
      12.21,
      -101.907,
      33.309,
      12.21,
      -101.907,
      33.309,
      8.75,
      -101.252,
      35.262,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11879",
    "polygon": [
      -13.196,
      71.623,
      5.0,
      -13.196,
      71.623,
      8.46,
      -17.225,
      72.973,
      8.46,
      -17.225,
      72.973,
      8.46,
      -17.225,
      72.973,
      5.0,
      -13.196,
      71.623,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11977",
    "polygon": [
      -105.71,
      39.241,
      0.0,
      -105.71,
      39.241,
      5.0,
      -102.904,
      38.301,
      5.0,
      -102.904,
      38.301,
      5.0,
      -102.904,
      38.301,
      0.0,
      -105.71,
      39.241,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_11956",
    "polygon": [
      0.383,
      94.494,
      12.5,
      0.383,
      94.494,
      15.96,
      -0.271,
      92.541,
      15.96,
      -0.271,
      92.541,
      15.96,
      -0.271,
      92.541,
      12.5,
      0.383,
      94.494,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_11848",
    "polygon": [
      -33.863,
      94.0,
      0.0,
      -33.863,
      94.0,
      4.5,
      -33.209,
      95.953,
      4.5,
      -33.209,
      95.953,
      4.5,
      -33.209,
      95.953,
      0.0,
      -33.863,
      94.0,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11893",
    "polygon": [
      -29.91,
      96.408,
      8.75,
      -29.91,
      96.408,
      12.21,
      -30.107,
      95.82,
      12.21,
      -30.107,
      95.82,
      12.21,
      -30.107,
      95.82,
      8.75,
      -29.91,
      96.408,
      8.75
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12048",
    "polygon": [
      -60.513,
      34.927,
      12.5,
      -60.513,
      34.927,
      15.96,
      -60.71,
      34.339,
      15.96,
      -60.71,
      34.339,
      15.96,
      -60.71,
      34.339,
      12.5,
      -60.513,
      34.927,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11998",
    "polygon": [
      -71.954,
      16.647,
      5.0,
      -71.954,
      16.647,
      8.46,
      -72.542,
      16.844,
      8.46,
      -72.542,
      16.844,
      8.46,
      -72.542,
      16.844,
      5.0,
      -71.954,
      16.647,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_11869",
    "polygon": [
      -3.654,
      73.993,
      5.0,
      -3.654,
      73.993,
      8.46,
      -3.295,
      75.064,
      8.46,
      -3.295,
      75.064,
      8.46,
      -3.295,
      75.064,
      5.0,
      -3.654,
      73.993,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12019",
    "polygon": [
      -57.317,
      35.815,
      8.75,
      -57.317,
      35.815,
      12.21,
      -56.968,
      36.889,
      12.21,
      -56.968,
      36.889,
      12.21,
      -56.968,
      36.889,
      8.75,
      -57.317,
      35.815,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_11907",
    "polygon": [
      3.32,
      106.871,
      8.75,
      3.32,
      106.871,
      12.21,
      2.438,
      107.165,
      12.21,
      2.438,
      107.165,
      12.21,
      2.438,
      107.165,
      8.75,
      3.32,
      106.871,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12063",
    "polygon": [
      -25.574,
      5.094,
      0.0,
      -25.574,
      5.094,
      5.0,
      -25.729,
      4.629,
      5.0,
      -25.729,
      4.629,
      5.0,
      -25.729,
      4.629,
      0.0,
      -25.574,
      5.094,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12070",
    "polygon": [
      -14.031,
      44.268,
      0.0,
      -14.031,
      44.268,
      4.55,
      -14.205,
      43.03,
      4.55,
      -14.205,
      43.03,
      4.55,
      -14.205,
      43.03,
      0.0,
      -14.031,
      44.268,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12079",
    "polygon": [
      17.9,
      27.244,
      0.0,
      17.9,
      27.244,
      5.0,
      18.14,
      27.671,
      5.0,
      18.14,
      27.671,
      5.0,
      18.14,
      27.671,
      0.0,
      17.9,
      27.244,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12089",
    "polygon": [
      -23.765,
      6.329,
      5.0,
      -23.765,
      6.329,
      8.75,
      -24.091,
      5.357,
      8.75,
      -24.091,
      5.357,
      8.75,
      -24.091,
      5.357,
      5.0,
      -23.765,
      6.329,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12096",
    "polygon": [
      -13.928,
      43.898,
      5.0,
      -13.928,
      43.898,
      8.46,
      -14.392,
      44.054,
      8.46,
      -14.392,
      44.054,
      8.46,
      -14.392,
      44.054,
      5.0,
      -13.928,
      43.898,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12103",
    "polygon": [
      6.106,
      -6.524,
      5.0,
      6.106,
      -6.524,
      8.46,
      6.57,
      -6.68,
      8.46,
      6.57,
      -6.68,
      8.46,
      6.57,
      -6.68,
      5.0,
      6.106,
      -6.524,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12110",
    "polygon": [
      -29.04,
      8.215,
      8.75,
      -29.04,
      8.215,
      12.21,
      -29.398,
      7.143,
      12.21,
      -29.398,
      7.143,
      12.21,
      -29.398,
      7.143,
      8.75,
      -29.04,
      8.215,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12117",
    "polygon": [
      -22.807,
      26.916,
      8.75,
      -22.807,
      26.916,
      12.21,
      -23.461,
      24.962,
      12.21,
      -23.461,
      24.962,
      12.21,
      -23.461,
      24.962,
      8.75,
      -22.807,
      26.916,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12124",
    "polygon": [
      12.7,
      32.124,
      8.75,
      12.7,
      32.124,
      12.21,
      13.165,
      31.968,
      12.21,
      13.165,
      31.968,
      12.21,
      13.165,
      31.968,
      8.75,
      12.7,
      32.124,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12134",
    "polygon": [
      15.453,
      19.041,
      8.75,
      15.453,
      19.041,
      12.21,
      15.158,
      18.159,
      12.21,
      15.158,
      18.159,
      12.21,
      15.158,
      18.159,
      8.75,
      15.453,
      19.041,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12141",
    "polygon": [
      -14.743,
      44.152,
      12.5,
      -14.743,
      44.152,
      15.96,
      -15.207,
      44.308,
      15.96,
      -15.207,
      44.308,
      15.96,
      -15.207,
      44.308,
      12.5,
      -14.743,
      44.152,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12148",
    "polygon": [
      22.204,
      31.797,
      12.5,
      22.204,
      31.797,
      15.96,
      21.74,
      31.952,
      15.96,
      21.74,
      31.952,
      15.96,
      21.74,
      31.952,
      12.5,
      22.204,
      31.797,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12155",
    "polygon": [
      51.405,
      2.864,
      0.0,
      51.405,
      2.864,
      5.0,
      51.249,
      2.4,
      5.0,
      51.249,
      2.4,
      5.0,
      51.249,
      2.4,
      0.0,
      51.405,
      2.864,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12162",
    "polygon": [
      84.831,
      10.977,
      0.0,
      84.831,
      10.977,
      5.0,
      84.366,
      11.133,
      5.0,
      84.366,
      11.133,
      5.0,
      84.366,
      11.133,
      0.0,
      84.831,
      10.977,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12169",
    "polygon": [
      51.732,
      -21.801,
      5.0,
      51.732,
      -21.801,
      8.46,
      52.804,
      -22.16,
      8.46,
      52.804,
      -22.16,
      8.46,
      52.804,
      -22.16,
      5.0,
      51.732,
      -21.801,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12183",
    "polygon": [
      73.665,
      11.571,
      5.0,
      73.665,
      11.571,
      8.46,
      74.129,
      11.414,
      8.46,
      74.129,
      11.414,
      8.46,
      74.129,
      11.414,
      5.0,
      73.665,
      11.571,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12197",
    "polygon": [
      69.157,
      -24.674,
      5.0,
      69.157,
      -24.674,
      8.46,
      68.693,
      -24.518,
      8.46,
      68.693,
      -24.518,
      8.46,
      68.693,
      -24.518,
      5.0,
      69.157,
      -24.674,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12190",
    "polygon": [
      85.775,
      -17.027,
      5.0,
      85.775,
      -17.027,
      8.46,
      85.416,
      -18.099,
      8.46,
      85.416,
      -18.099,
      8.46,
      85.416,
      -18.099,
      5.0,
      85.775,
      -17.027,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12176",
    "polygon": [
      49.634,
      -2.942,
      5.0,
      49.634,
      -2.942,
      8.46,
      49.338,
      -3.824,
      8.46,
      49.338,
      -3.824,
      8.46,
      49.338,
      -3.824,
      5.0,
      49.634,
      -2.942,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12204",
    "polygon": [
      42.61,
      -14.447,
      8.75,
      42.61,
      -14.447,
      12.21,
      42.807,
      -13.86,
      12.21,
      42.807,
      -13.86,
      12.21,
      42.807,
      -13.86,
      8.75,
      42.61,
      -14.447,
      8.75
    ],
    "05-22 17:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12211",
    "polygon": [
      66.413,
      14.001,
      8.75,
      66.413,
      14.001,
      12.21,
      66.877,
      13.845,
      12.21,
      66.877,
      13.845,
      12.21,
      66.877,
      13.845,
      8.75,
      66.413,
      14.001,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12232",
    "polygon": [
      48.827,
      -5.329,
      12.5,
      48.827,
      -5.329,
      15.96,
      48.173,
      -7.282,
      15.96,
      48.173,
      -7.282,
      15.96,
      48.173,
      -7.282,
      12.5,
      48.827,
      -5.329,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12218",
    "polygon": [
      93.738,
      -1.757,
      8.75,
      93.738,
      -1.757,
      12.21,
      94.097,
      -0.685,
      12.21,
      94.097,
      -0.685,
      12.21,
      94.097,
      -0.685,
      8.75,
      93.738,
      -1.757,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12225",
    "polygon": [
      85.984,
      -24.903,
      8.75,
      85.984,
      -24.903,
      12.21,
      86.343,
      -23.831,
      12.21,
      86.343,
      -23.831,
      12.21,
      86.343,
      -23.831,
      8.75,
      85.984,
      -24.903,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12239",
    "polygon": [
      69.116,
      13.095,
      12.5,
      69.116,
      13.095,
      15.96,
      69.581,
      12.939,
      15.96,
      69.581,
      12.939,
      15.96,
      69.581,
      12.939,
      12.5,
      69.116,
      13.095,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12246",
    "polygon": [
      96.934,
      -1.66,
      12.5,
      96.934,
      -1.66,
      15.96,
      96.737,
      -2.248,
      15.96,
      96.737,
      -2.248,
      15.96,
      96.737,
      -2.248,
      12.5,
      96.934,
      -1.66,
      12.5
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12253",
    "polygon": [
      86.369,
      -15.253,
      12.5,
      86.369,
      -15.253,
      15.96,
      86.074,
      -16.135,
      15.96,
      86.074,
      -16.135,
      15.96,
      86.074,
      -16.135,
      12.5,
      86.369,
      -15.253,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12260",
    "polygon": [
      40.042,
      73.12,
      0.0,
      40.042,
      73.12,
      4.5,
      39.845,
      72.532,
      4.5,
      39.845,
      72.532,
      4.5,
      39.845,
      72.532,
      0.0,
      40.042,
      73.12,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12267",
    "polygon": [
      55.273,
      86.494,
      0.0,
      55.273,
      86.494,
      4.5,
      55.738,
      86.339,
      4.5,
      55.738,
      86.339,
      4.5,
      55.738,
      86.339,
      0.0,
      55.273,
      86.494,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12274",
    "polygon": [
      65.559,
      54.688,
      0.0,
      65.559,
      54.688,
      4.5,
      65.756,
      55.276,
      4.5,
      65.756,
      55.276,
      4.5,
      65.756,
      55.276,
      0.0,
      65.559,
      54.688,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12281",
    "polygon": [
      42.758,
      52.793,
      0.0,
      42.758,
      52.793,
      4.5,
      41.013,
      53.377,
      4.5,
      41.013,
      53.377,
      4.5,
      41.013,
      53.377,
      0.0,
      42.758,
      52.793,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12288",
    "polygon": [
      55.746,
      40.167,
      0.0,
      55.746,
      40.167,
      4.5,
      54.533,
      40.574,
      4.5,
      54.533,
      40.574,
      4.5,
      54.533,
      40.574,
      0.0,
      55.746,
      40.167,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12064",
    "polygon": [
      -24.428,
      8.513,
      0.0,
      -24.428,
      8.513,
      5.0,
      -24.584,
      8.048,
      5.0,
      -24.584,
      8.048,
      5.0,
      -24.584,
      8.048,
      0.0,
      -24.428,
      8.513,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12071",
    "polygon": [
      -13.602,
      47.327,
      0.0,
      -13.602,
      47.327,
      4.55,
      -13.731,
      46.406,
      4.55,
      -13.731,
      46.406,
      4.55,
      -13.731,
      46.406,
      0.0,
      -13.602,
      47.327,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12080",
    "polygon": [
      20.274,
      31.478,
      0.0,
      20.274,
      31.478,
      4.55,
      20.885,
      32.569,
      4.55,
      20.885,
      32.569,
      4.55,
      20.885,
      32.569,
      0.0,
      20.274,
      31.478,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12090",
    "polygon": [
      -22.139,
      11.183,
      5.0,
      -22.139,
      11.183,
      8.75,
      -22.465,
      10.211,
      8.75,
      -22.465,
      10.211,
      8.75,
      -22.465,
      10.211,
      5.0,
      -22.139,
      11.183,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12097",
    "polygon": [
      -10.412,
      42.718,
      5.0,
      -10.412,
      42.718,
      8.46,
      -10.876,
      42.874,
      8.46,
      -10.876,
      42.874,
      8.46,
      -10.876,
      42.874,
      5.0,
      -10.412,
      42.718,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12104",
    "polygon": [
      5.407,
      -1.819,
      5.0,
      5.407,
      -1.819,
      8.46,
      5.563,
      -1.354,
      8.46,
      5.563,
      -1.354,
      8.46,
      5.563,
      -1.354,
      5.0,
      5.407,
      -1.819,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12111",
    "polygon": [
      -28.431,
      10.032,
      8.75,
      -28.431,
      10.032,
      12.21,
      -28.726,
      9.151,
      12.21,
      -28.726,
      9.151,
      12.21,
      -28.726,
      9.151,
      8.75,
      -28.431,
      10.032,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12118",
    "polygon": [
      -18.457,
      44.591,
      8.75,
      -18.457,
      44.591,
      12.21,
      -18.613,
      44.126,
      12.21,
      -18.613,
      44.126,
      12.21,
      -18.613,
      44.126,
      8.75,
      -18.457,
      44.591,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12128",
    "polygon": [
      20.825,
      26.57,
      8.75,
      20.825,
      26.57,
      12.21,
      21.479,
      28.523,
      12.21,
      21.479,
      28.523,
      12.21,
      21.479,
      28.523,
      8.75,
      20.825,
      26.57,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12135",
    "polygon": [
      11.097,
      -2.443,
      8.75,
      11.097,
      -2.443,
      12.21,
      11.392,
      -1.561,
      12.21,
      11.392,
      -1.561,
      12.21,
      11.392,
      -1.561,
      8.75,
      11.097,
      -2.443,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12142",
    "polygon": [
      -11.02,
      42.903,
      12.5,
      -11.02,
      42.903,
      15.96,
      -11.485,
      43.059,
      15.96,
      -11.485,
      43.059,
      15.96,
      -11.485,
      43.059,
      12.5,
      -11.02,
      42.903,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12163",
    "polygon": [
      87.725,
      10.007,
      0.0,
      87.725,
      10.007,
      5.0,
      87.261,
      10.163,
      5.0,
      87.261,
      10.163,
      5.0,
      87.261,
      10.163,
      0.0,
      87.725,
      10.007,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12156",
    "polygon": [
      54.11,
      10.94,
      0.0,
      54.11,
      10.94,
      5.0,
      53.954,
      10.476,
      5.0,
      53.954,
      10.476,
      5.0,
      53.954,
      10.476,
      0.0,
      54.11,
      10.94,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12149",
    "polygon": [
      -4.528,
      0.017,
      12.5,
      -4.528,
      0.017,
      15.96,
      -4.993,
      0.173,
      15.96,
      -4.993,
      0.173,
      15.96,
      -4.993,
      0.173,
      12.5,
      -4.528,
      0.017,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12170",
    "polygon": [
      53.715,
      -22.466,
      5.0,
      53.715,
      -22.466,
      8.46,
      54.597,
      -22.761,
      8.46,
      54.597,
      -22.761,
      8.46,
      54.597,
      -22.761,
      5.0,
      53.715,
      -22.466,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12191",
    "polygon": [
      82.578,
      -17.125,
      5.0,
      82.578,
      -17.125,
      8.46,
      82.775,
      -16.537,
      8.46,
      82.775,
      -16.537,
      8.46,
      82.775,
      -16.537,
      5.0,
      82.578,
      -17.125,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12184",
    "polygon": [
      92.048,
      8.392,
      5.0,
      92.048,
      8.392,
      8.46,
      91.461,
      8.592,
      8.46,
      91.461,
      8.592,
      8.46,
      91.461,
      8.592,
      5.0,
      92.048,
      8.392,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12177",
    "polygon": [
      56.145,
      8.058,
      5.0,
      56.145,
      8.058,
      8.46,
      56.342,
      8.646,
      8.46,
      56.342,
      8.646,
      8.46,
      56.342,
      8.646,
      5.0,
      56.145,
      8.058,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12198",
    "polygon": [
      66.206,
      -23.685,
      5.0,
      66.206,
      -23.685,
      8.46,
      65.741,
      -23.53,
      8.46,
      65.741,
      -23.53,
      8.46,
      65.741,
      -23.53,
      5.0,
      66.206,
      -23.685,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12205",
    "polygon": [
      45.83,
      -4.831,
      8.75,
      45.83,
      -4.831,
      12.21,
      46.027,
      -4.243,
      12.21,
      46.027,
      -4.243,
      12.21,
      46.027,
      -4.243,
      8.75,
      45.83,
      -4.831,
      8.75
    ],
    "05-22 17:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12212",
    "polygon": [
      69.139,
      13.087,
      8.75,
      69.139,
      13.087,
      12.21,
      69.603,
      12.932,
      12.21,
      69.603,
      12.932,
      12.21,
      69.603,
      12.932,
      8.75,
      69.139,
      13.087,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12233",
    "polygon": [
      47.864,
      -8.204,
      12.5,
      47.864,
      -8.204,
      15.96,
      47.667,
      -8.792,
      15.96,
      47.667,
      -8.792,
      15.96,
      47.667,
      -8.792,
      12.5,
      47.864,
      -8.204,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12219",
    "polygon": [
      96.936,
      -1.652,
      8.75,
      96.936,
      -1.652,
      12.21,
      96.739,
      -2.24,
      12.21,
      96.739,
      -2.24,
      12.21,
      96.739,
      -2.24,
      8.75,
      96.936,
      -1.652,
      8.75
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12226",
    "polygon": [
      86.65,
      -22.914,
      8.75,
      86.65,
      -22.914,
      12.21,
      86.946,
      -22.032,
      12.21,
      86.946,
      -22.032,
      12.21,
      86.946,
      -22.032,
      8.75,
      86.65,
      -22.914,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12247",
    "polygon": [
      92.021,
      8.402,
      12.5,
      92.021,
      8.402,
      15.96,
      91.434,
      8.601,
      15.96,
      91.434,
      8.601,
      15.96,
      91.434,
      8.601,
      12.5,
      92.021,
      8.402,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12240",
    "polygon": [
      73.631,
      11.583,
      12.5,
      73.631,
      11.583,
      15.96,
      74.095,
      11.425,
      15.96,
      74.095,
      11.425,
      15.96,
      74.095,
      11.425,
      12.5,
      73.631,
      11.583,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12254",
    "polygon": [
      75.469,
      -26.787,
      12.5,
      75.469,
      -26.787,
      15.96,
      75.004,
      -26.631,
      15.96,
      75.004,
      -26.631,
      15.96,
      75.004,
      -26.631,
      12.5,
      75.469,
      -26.787,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12261",
    "polygon": [
      37.001,
      73.487,
      0.0,
      37.001,
      73.487,
      4.5,
      37.36,
      74.558,
      4.5,
      37.36,
      74.558,
      4.5,
      37.36,
      74.558,
      0.0,
      37.001,
      73.487,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12268",
    "polygon": [
      62.864,
      80.917,
      0.0,
      62.864,
      80.917,
      4.5,
      62.277,
      81.113,
      4.5,
      62.277,
      81.113,
      4.5,
      62.277,
      81.113,
      0.0,
      62.864,
      80.917,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12289",
    "polygon": [
      27.63,
      63.431,
      5.0,
      27.63,
      63.431,
      8.46,
      27.827,
      64.019,
      8.46,
      27.827,
      64.019,
      8.46,
      27.827,
      64.019,
      5.0,
      27.63,
      63.431,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12275",
    "polygon": [
      68.751,
      54.773,
      0.0,
      68.751,
      54.773,
      4.5,
      68.392,
      53.701,
      4.5,
      68.392,
      53.701,
      4.5,
      68.392,
      53.701,
      0.0,
      68.751,
      54.773,
      0.0
    ],
    "05-22 03:45": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12282",
    "polygon": [
      40.064,
      53.695,
      0.0,
      40.064,
      53.695,
      4.5,
      36.65,
      54.839,
      4.5,
      36.65,
      54.839,
      4.5,
      36.65,
      54.839,
      0.0,
      40.064,
      53.695,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12065",
    "polygon": [
      -22.258,
      14.992,
      0.0,
      -22.258,
      14.992,
      5.0,
      -22.414,
      14.528,
      5.0,
      -22.414,
      14.528,
      5.0,
      -22.414,
      14.528,
      0.0,
      -22.258,
      14.992,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12074",
    "polygon": [
      -10.609,
      2.054,
      0.0,
      -10.609,
      2.054,
      5.0,
      -11.073,
      2.21,
      5.0,
      -11.073,
      2.21,
      5.0,
      -11.073,
      2.21,
      0.0,
      -10.609,
      2.054,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12091",
    "polygon": [
      -21.762,
      12.31,
      5.0,
      -21.762,
      12.31,
      8.75,
      -22.088,
      11.338,
      8.75,
      -22.088,
      11.338,
      8.75,
      -22.088,
      11.338,
      5.0,
      -21.762,
      12.31,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12081",
    "polygon": [
      21.963,
      34.492,
      0.0,
      21.963,
      34.492,
      4.55,
      22.418,
      35.303,
      4.55,
      22.418,
      35.303,
      4.55,
      22.418,
      35.303,
      0.0,
      21.963,
      34.492,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12098",
    "polygon": [
      -6.932,
      41.551,
      5.0,
      -6.932,
      41.551,
      8.46,
      -7.397,
      41.707,
      8.46,
      -7.397,
      41.707,
      8.46,
      -7.397,
      41.707,
      5.0,
      -6.932,
      41.551,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12105",
    "polygon": [
      22.813,
      28.762,
      5.0,
      22.813,
      28.762,
      8.46,
      23.278,
      28.606,
      8.46,
      23.278,
      28.606,
      8.46,
      23.278,
      28.606,
      5.0,
      22.813,
      28.762,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12112",
    "polygon": [
      -32.239,
      8.13,
      8.75,
      -32.239,
      8.13,
      12.21,
      -32.042,
      8.718,
      12.21,
      -32.042,
      8.718,
      12.21,
      -32.042,
      8.718,
      8.75,
      -32.239,
      8.13,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12129",
    "polygon": [
      21.784,
      29.433,
      8.75,
      21.784,
      29.433,
      12.21,
      21.981,
      30.021,
      12.21,
      21.981,
      30.021,
      12.21,
      21.981,
      30.021,
      8.75,
      21.784,
      29.433,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12136",
    "polygon": [
      11.69,
      -0.674,
      8.75,
      11.69,
      -0.674,
      12.21,
      12.049,
      0.398,
      12.21,
      12.049,
      0.398,
      12.21,
      12.049,
      0.398,
      8.75,
      11.69,
      -0.674,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12119",
    "polygon": [
      -14.726,
      44.147,
      8.75,
      -14.726,
      44.147,
      12.21,
      -15.19,
      44.302,
      12.21,
      -15.19,
      44.302,
      12.21,
      -15.19,
      44.302,
      8.75,
      -14.726,
      44.147,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12143",
    "polygon": [
      -6.945,
      41.536,
      12.5,
      -6.945,
      41.536,
      15.96,
      -7.409,
      41.692,
      15.96,
      -7.409,
      41.692,
      15.96,
      -7.409,
      41.692,
      12.5,
      -6.945,
      41.536,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12164",
    "polygon": [
      90.023,
      9.238,
      0.0,
      90.023,
      9.238,
      5.0,
      89.558,
      9.393,
      5.0,
      89.558,
      9.393,
      5.0,
      89.558,
      9.393,
      0.0,
      90.023,
      9.238,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12157",
    "polygon": [
      55.689,
      15.656,
      0.0,
      55.689,
      15.656,
      5.0,
      55.534,
      15.191,
      5.0,
      55.534,
      15.191,
      5.0,
      55.534,
      15.191,
      0.0,
      55.689,
      15.656,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12150",
    "polygon": [
      -8.248,
      1.263,
      12.5,
      -8.248,
      1.263,
      15.96,
      -8.713,
      1.419,
      15.96,
      -8.713,
      1.419,
      15.96,
      -8.713,
      1.419,
      12.5,
      -8.248,
      1.263,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12171",
    "polygon": [
      44.996,
      -16.769,
      5.0,
      44.996,
      -16.769,
      8.46,
      44.701,
      -17.651,
      8.46,
      44.701,
      -17.651,
      8.46,
      44.701,
      -17.651,
      5.0,
      44.996,
      -16.769,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12178",
    "polygon": [
      55.192,
      5.212,
      5.0,
      55.192,
      5.212,
      8.46,
      55.846,
      7.165,
      8.46,
      55.846,
      7.165,
      8.46,
      55.846,
      7.165,
      5.0,
      55.192,
      5.212,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12199",
    "polygon": [
      51.387,
      -24.864,
      8.75,
      51.387,
      -24.864,
      12.21,
      50.799,
      -24.667,
      12.21,
      50.799,
      -24.667,
      12.21,
      50.799,
      -24.667,
      8.75,
      51.387,
      -24.864,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12192",
    "polygon": [
      89.033,
      -25.245,
      5.0,
      89.033,
      -25.245,
      8.46,
      88.836,
      -25.833,
      8.46,
      88.836,
      -25.833,
      8.46,
      88.836,
      -25.833,
      5.0,
      89.033,
      -25.245,
      5.0
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12185",
    "polygon": [
      90.546,
      8.902,
      5.0,
      90.546,
      8.902,
      8.46,
      88.595,
      9.564,
      8.46,
      88.595,
      9.564,
      8.46,
      88.595,
      9.564,
      5.0,
      90.546,
      8.902,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12213",
    "polygon": [
      73.624,
      11.585,
      8.75,
      73.624,
      11.585,
      12.21,
      74.088,
      11.428,
      12.21,
      74.088,
      11.428,
      12.21,
      74.088,
      11.428,
      8.75,
      73.624,
      11.585,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12206",
    "polygon": [
      49.034,
      -4.731,
      8.75,
      49.034,
      -4.731,
      12.21,
      48.675,
      -5.803,
      12.21,
      48.675,
      -5.803,
      12.21,
      48.675,
      -5.803,
      8.75,
      49.034,
      -4.731,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12234",
    "polygon": [
      58.998,
      7.087,
      12.5,
      58.998,
      7.087,
      15.96,
      58.801,
      6.499,
      15.96,
      58.801,
      6.499,
      15.96,
      58.801,
      6.499,
      12.5,
      58.998,
      7.087,
      12.5
    ],
    "05-22 06:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12220",
    "polygon": [
      92.024,
      8.401,
      8.75,
      92.024,
      8.401,
      12.21,
      91.437,
      8.6,
      12.21,
      91.437,
      8.6,
      12.21,
      91.437,
      8.6,
      8.75,
      92.024,
      8.401,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12248",
    "polygon": [
      90.533,
      8.906,
      12.5,
      90.533,
      8.906,
      15.96,
      88.582,
      9.568,
      15.96,
      88.582,
      9.568,
      15.96,
      88.582,
      9.568,
      12.5,
      90.533,
      8.906,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12241",
    "polygon": [
      81.314,
      5.978,
      12.5,
      81.314,
      5.978,
      15.96,
      80.726,
      6.175,
      15.96,
      80.726,
      6.175,
      15.96,
      80.726,
      6.175,
      12.5,
      81.314,
      5.978,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12227",
    "polygon": [
      86.366,
      -15.263,
      8.75,
      86.366,
      -15.263,
      12.21,
      86.07,
      -16.144,
      12.21,
      86.07,
      -16.144,
      12.21,
      86.07,
      -16.144,
      8.75,
      86.366,
      -15.263,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12255",
    "polygon": [
      69.114,
      -24.659,
      12.5,
      69.114,
      -24.659,
      15.96,
      68.649,
      -24.504,
      15.96,
      68.649,
      -24.504,
      15.96,
      68.649,
      -24.504,
      12.5,
      69.114,
      -24.659,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12262",
    "polygon": [
      37.657,
      75.444,
      0.0,
      37.657,
      75.444,
      4.5,
      37.952,
      76.326,
      4.5,
      37.952,
      76.326,
      4.5,
      37.952,
      76.326,
      0.0,
      37.657,
      75.444,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12269",
    "polygon": [
      63.212,
      83.964,
      0.0,
      63.212,
      83.964,
      4.5,
      64.284,
      83.605,
      4.5,
      64.284,
      83.605,
      4.5,
      64.284,
      83.605,
      0.0,
      63.212,
      83.964,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12276",
    "polygon": [
      69.353,
      56.569,
      0.0,
      69.353,
      56.569,
      4.5,
      69.057,
      55.687,
      4.5,
      69.057,
      55.687,
      4.5,
      69.057,
      55.687,
      0.0,
      69.353,
      56.569,
      0.0
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12290",
    "polygon": [
      30.829,
      63.537,
      5.0,
      30.829,
      63.537,
      8.46,
      30.47,
      62.465,
      8.46,
      30.47,
      62.465,
      8.46,
      30.47,
      62.465,
      5.0,
      30.829,
      63.537,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12283",
    "polygon": [
      63.042,
      37.723,
      0.0,
      63.042,
      37.723,
      4.5,
      62.817,
      37.799,
      4.5,
      62.817,
      37.799,
      4.5,
      62.817,
      37.799,
      0.0,
      63.042,
      37.723,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12066",
    "polygon": [
      -20.117,
      21.384,
      0.0,
      -20.117,
      21.384,
      5.0,
      -20.577,
      20.009,
      5.0,
      -20.577,
      20.009,
      5.0,
      -20.577,
      20.009,
      0.0,
      -20.117,
      21.384,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12084",
    "polygon": [
      -17.288,
      1.312,
      5.0,
      -17.288,
      1.312,
      8.75,
      -16.359,
      1.001,
      8.75,
      -16.359,
      1.001,
      8.75,
      -16.359,
      1.001,
      5.0,
      -17.288,
      1.312,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12092",
    "polygon": [
      -20.079,
      17.334,
      5.0,
      -20.079,
      17.334,
      8.75,
      -20.404,
      16.362,
      8.75,
      -20.404,
      16.362,
      8.75,
      -20.404,
      16.362,
      5.0,
      -20.079,
      17.334,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12075",
    "polygon": [
      -6.799,
      0.778,
      0.0,
      -6.799,
      0.778,
      5.0,
      -7.264,
      0.933,
      5.0,
      -7.264,
      0.933,
      5.0,
      -7.264,
      0.933,
      0.0,
      -6.799,
      0.778,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12099",
    "polygon": [
      -8.318,
      1.286,
      5.0,
      -8.318,
      1.286,
      8.46,
      -8.782,
      1.442,
      8.46,
      -8.782,
      1.442,
      8.46,
      -8.782,
      1.442,
      5.0,
      -8.318,
      1.286,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12106",
    "polygon": [
      19.941,
      32.555,
      5.0,
      19.941,
      32.555,
      8.46,
      19.477,
      32.71,
      8.46,
      19.477,
      32.71,
      8.46,
      19.477,
      32.71,
      5.0,
      19.941,
      32.555,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12113",
    "polygon": [
      -16.638,
      27.292,
      8.75,
      -16.638,
      27.292,
      12.21,
      -16.834,
      26.705,
      12.21,
      -16.834,
      26.705,
      12.21,
      -16.834,
      26.705,
      8.75,
      -16.638,
      27.292,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12137",
    "polygon": [
      14.896,
      -0.57,
      8.75,
      14.896,
      -0.57,
      12.21,
      14.699,
      -1.157,
      12.21,
      14.699,
      -1.157,
      12.21,
      14.699,
      -1.157,
      8.75,
      14.896,
      -0.57,
      8.75
    ],
    "05-22 05:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12120",
    "polygon": [
      -10.626,
      42.771,
      8.75,
      -10.626,
      42.771,
      12.21,
      -11.091,
      42.927,
      12.21,
      -11.091,
      42.927,
      12.21,
      -11.091,
      42.927,
      8.75,
      -10.626,
      42.771,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12130",
    "polygon": [
      16.864,
      14.751,
      8.75,
      16.864,
      14.751,
      12.21,
      17.061,
      15.339,
      12.21,
      17.061,
      15.339,
      12.21,
      17.061,
      15.339,
      8.75,
      16.864,
      14.751,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12144",
    "polygon": [
      9.894,
      33.064,
      12.5,
      9.894,
      33.064,
      15.96,
      10.358,
      32.908,
      15.96,
      10.358,
      32.908,
      15.96,
      10.358,
      32.908,
      12.5,
      9.894,
      33.064,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12165",
    "polygon": [
      81.879,
      -31.736,
      0.0,
      81.879,
      -31.736,
      5.0,
      83.256,
      -32.191,
      5.0,
      83.256,
      -32.191,
      5.0,
      83.256,
      -32.191,
      0.0,
      81.879,
      -31.736,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12151",
    "polygon": [
      55.829,
      -22.967,
      0.0,
      55.829,
      -22.967,
      5.0,
      56.294,
      -23.121,
      5.0,
      56.294,
      -23.121,
      5.0,
      56.294,
      -23.121,
      0.0,
      55.829,
      -22.967,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12158",
    "polygon": [
      63.569,
      18.1,
      0.0,
      63.569,
      18.1,
      5.0,
      63.104,
      18.255,
      5.0,
      63.104,
      18.255,
      5.0,
      63.104,
      18.255,
      0.0,
      63.569,
      18.1,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12172",
    "polygon": [
      45.653,
      -14.804,
      5.0,
      45.653,
      -14.804,
      8.46,
      45.295,
      -15.876,
      8.46,
      45.295,
      -15.876,
      8.46,
      45.295,
      -15.876,
      5.0,
      45.653,
      -14.804,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12200",
    "polygon": [
      51.686,
      -21.786,
      8.75,
      51.686,
      -21.786,
      12.21,
      52.757,
      -22.145,
      12.21,
      52.757,
      -22.145,
      12.21,
      52.757,
      -22.145,
      8.75,
      51.686,
      -21.786,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12179",
    "polygon": [
      63.298,
      18.023,
      5.0,
      63.298,
      18.023,
      8.46,
      62.833,
      18.179,
      8.46,
      62.833,
      18.179,
      8.46,
      62.833,
      18.179,
      5.0,
      63.298,
      18.023,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12193",
    "polygon": [
      85.984,
      -24.904,
      5.0,
      85.984,
      -24.904,
      8.46,
      86.343,
      -23.832,
      8.46,
      86.343,
      -23.832,
      8.46,
      86.343,
      -23.832,
      5.0,
      85.984,
      -24.904,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12186",
    "polygon": [
      96.927,
      -1.682,
      5.0,
      96.927,
      -1.682,
      8.46,
      96.73,
      -2.27,
      8.46,
      96.73,
      -2.27,
      8.46,
      96.73,
      -2.27,
      5.0,
      96.927,
      -1.682,
      5.0
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12207",
    "polygon": [
      49.629,
      -2.955,
      8.75,
      49.629,
      -2.955,
      12.21,
      49.334,
      -3.837,
      12.21,
      49.334,
      -3.837,
      12.21,
      49.334,
      -3.837,
      8.75,
      49.629,
      -2.955,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12214",
    "polygon": [
      81.309,
      5.98,
      8.75,
      81.309,
      5.98,
      12.21,
      80.721,
      6.176,
      12.21,
      80.721,
      6.176,
      12.21,
      80.721,
      6.176,
      8.75,
      81.309,
      5.98,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12221",
    "polygon": [
      90.519,
      8.911,
      8.75,
      90.519,
      8.911,
      12.21,
      88.569,
      9.573,
      12.21,
      88.569,
      9.573,
      12.21,
      88.569,
      9.573,
      8.75,
      90.519,
      8.911,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12249",
    "polygon": [
      85.976,
      -24.925,
      12.5,
      85.976,
      -24.925,
      15.96,
      86.631,
      -22.972,
      15.96,
      86.631,
      -22.972,
      15.96,
      86.631,
      -22.972,
      12.5,
      85.976,
      -24.925,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12235",
    "polygon": [
      55.794,
      6.968,
      12.5,
      55.794,
      6.968,
      15.96,
      56.153,
      8.04,
      15.96,
      56.153,
      8.04,
      15.96,
      56.153,
      8.04,
      12.5,
      55.794,
      6.968,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12242",
    "polygon": [
      81.197,
      9.181,
      12.5,
      81.197,
      9.181,
      15.96,
      82.269,
      8.822,
      15.96,
      82.269,
      8.822,
      15.96,
      82.269,
      8.822,
      12.5,
      81.197,
      9.181,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12256",
    "polygon": [
      66.188,
      -23.68,
      12.5,
      66.188,
      -23.68,
      15.96,
      65.724,
      -23.524,
      15.96,
      65.724,
      -23.524,
      15.96,
      65.724,
      -23.524,
      12.5,
      66.188,
      -23.68,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12228",
    "polygon": [
      75.451,
      -26.78,
      8.75,
      75.451,
      -26.78,
      12.21,
      74.986,
      -26.625,
      12.21,
      74.986,
      -26.625,
      12.21,
      74.986,
      -26.625,
      8.75,
      75.451,
      -26.78,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12263",
    "polygon": [
      40.545,
      92.573,
      0.0,
      40.545,
      92.573,
      4.5,
      40.348,
      91.985,
      4.5,
      40.348,
      91.985,
      4.5,
      40.348,
      91.985,
      0.0,
      40.545,
      92.573,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12270",
    "polygon": [
      65.191,
      83.301,
      0.0,
      65.191,
      83.301,
      4.5,
      66.073,
      83.006,
      4.5,
      66.073,
      83.006,
      4.5,
      66.073,
      83.006,
      0.0,
      65.191,
      83.301,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12284",
    "polygon": [
      61.911,
      38.102,
      0.0,
      61.911,
      38.102,
      4.5,
      60.517,
      38.569,
      4.5,
      60.517,
      38.569,
      4.5,
      60.517,
      38.569,
      0.0,
      61.911,
      38.102,
      0.0
    ],
    "05-22 09:30": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12277",
    "polygon": [
      68.743,
      46.231,
      0.0,
      68.743,
      46.231,
      4.5,
      68.94,
      46.819,
      4.5,
      68.94,
      46.819,
      4.5,
      68.94,
      46.819,
      0.0,
      68.743,
      46.231,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12291",
    "polygon": [
      31.426,
      65.32,
      5.0,
      31.426,
      65.32,
      8.46,
      31.131,
      64.438,
      8.46,
      31.131,
      64.438,
      8.46,
      31.131,
      64.438,
      5.0,
      31.426,
      65.32,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12067",
    "polygon": [
      -17.964,
      27.811,
      0.0,
      -17.964,
      27.811,
      5.0,
      -18.424,
      26.436,
      5.0,
      -18.424,
      26.436,
      5.0,
      -18.424,
      26.436,
      0.0,
      -17.964,
      27.811,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12085",
    "polygon": [
      -22.297,
      2.99,
      5.0,
      -22.297,
      2.99,
      8.46,
      -21.832,
      2.834,
      8.46,
      -21.832,
      2.834,
      8.46,
      -21.832,
      2.834,
      5.0,
      -22.297,
      2.99,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12076",
    "polygon": [
      11.504,
      9.249,
      0.0,
      11.504,
      9.249,
      5.0,
      11.965,
      10.624,
      5.0,
      11.965,
      10.624,
      5.0,
      11.965,
      10.624,
      0.0,
      11.504,
      9.249,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12093",
    "polygon": [
      -19.652,
      18.607,
      5.0,
      -19.652,
      18.607,
      8.75,
      -19.978,
      17.635,
      8.75,
      -19.978,
      17.635,
      8.75,
      -19.978,
      17.635,
      5.0,
      -19.652,
      18.607,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12100",
    "polygon": [
      -4.493,
      0.005,
      5.0,
      -4.493,
      0.005,
      8.46,
      -4.958,
      0.161,
      8.46,
      -4.958,
      0.161,
      8.46,
      -4.958,
      0.161,
      5.0,
      -4.493,
      0.005,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12107",
    "polygon": [
      15.593,
      34.011,
      5.0,
      15.593,
      34.011,
      8.46,
      15.128,
      34.167,
      8.46,
      15.128,
      34.167,
      8.46,
      15.128,
      34.167,
      5.0,
      15.593,
      34.011,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12138",
    "polygon": [
      -4.549,
      0.024,
      8.75,
      -4.549,
      0.024,
      12.21,
      -5.014,
      0.18,
      12.21,
      -5.014,
      0.18,
      12.21,
      -5.014,
      0.18,
      8.75,
      -4.549,
      0.024,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12114",
    "polygon": [
      -19.693,
      27.638,
      8.75,
      -19.693,
      27.638,
      12.21,
      -19.334,
      28.709,
      12.21,
      -19.334,
      28.709,
      12.21,
      -19.334,
      28.709,
      8.75,
      -19.693,
      27.638,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12121",
    "polygon": [
      -6.927,
      41.53,
      8.75,
      -6.927,
      41.53,
      12.21,
      -7.391,
      41.686,
      12.21,
      -7.391,
      41.686,
      12.21,
      -7.391,
      41.686,
      8.75,
      -6.927,
      41.53,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12131",
    "polygon": [
      11.672,
      17.218,
      8.75,
      11.672,
      17.218,
      12.21,
      11.869,
      17.806,
      12.21,
      11.869,
      17.806,
      12.21,
      11.869,
      17.806,
      8.75,
      11.672,
      17.218,
      8.75
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12145",
    "polygon": [
      11.365,
      32.571,
      12.5,
      11.365,
      32.571,
      15.96,
      11.83,
      32.415,
      15.96,
      11.83,
      32.415,
      15.96,
      11.83,
      32.415,
      12.5,
      11.365,
      32.571,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12166",
    "polygon": [
      75.351,
      -26.582,
      0.0,
      75.351,
      -26.582,
      5.0,
      74.886,
      -26.426,
      5.0,
      74.886,
      -26.426,
      5.0,
      74.886,
      -26.426,
      0.0,
      75.351,
      -26.582,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12173",
    "polygon": [
      42.608,
      -14.453,
      5.0,
      42.608,
      -14.453,
      8.46,
      42.805,
      -13.865,
      8.46,
      42.805,
      -13.865,
      8.46,
      42.805,
      -13.865,
      5.0,
      42.608,
      -14.453,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12194",
    "polygon": [
      86.641,
      -22.941,
      5.0,
      86.641,
      -22.941,
      8.46,
      86.937,
      -22.059,
      8.46,
      86.937,
      -22.059,
      8.46,
      86.937,
      -22.059,
      5.0,
      86.641,
      -22.941,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12152",
    "polygon": [
      52.872,
      -21.989,
      0.0,
      52.872,
      -21.989,
      5.0,
      53.337,
      -22.143,
      5.0,
      53.337,
      -22.143,
      5.0,
      53.337,
      -22.143,
      0.0,
      52.872,
      -21.989,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12159",
    "polygon": [
      64.566,
      14.786,
      0.0,
      64.566,
      14.786,
      5.0,
      65.031,
      14.631,
      5.0,
      65.031,
      14.631,
      5.0,
      65.031,
      14.631,
      0.0,
      64.566,
      14.786,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12180",
    "polygon": [
      66.352,
      14.021,
      5.0,
      66.352,
      14.021,
      8.46,
      66.817,
      13.865,
      8.46,
      66.817,
      13.865,
      8.46,
      66.817,
      13.865,
      5.0,
      66.352,
      14.021,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12201",
    "polygon": [
      53.711,
      -22.464,
      8.75,
      53.711,
      -22.464,
      12.21,
      54.593,
      -22.76,
      12.21,
      54.593,
      -22.76,
      12.21,
      54.593,
      -22.76,
      8.75,
      53.711,
      -22.464,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12187",
    "polygon": [
      93.734,
      -1.766,
      5.0,
      93.734,
      -1.766,
      8.46,
      94.093,
      -0.695,
      8.46,
      94.093,
      -0.695,
      8.46,
      94.093,
      -0.695,
      5.0,
      93.734,
      -1.766,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12208",
    "polygon": [
      56.146,
      8.059,
      8.75,
      56.146,
      8.059,
      12.21,
      56.343,
      8.647,
      12.21,
      56.343,
      8.647,
      12.21,
      56.343,
      8.647,
      8.75,
      56.146,
      8.059,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12215",
    "polygon": [
      81.241,
      9.166,
      8.75,
      81.241,
      9.166,
      12.21,
      82.312,
      8.807,
      12.21,
      82.312,
      8.807,
      12.21,
      82.312,
      8.807,
      8.75,
      81.241,
      9.166,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12236",
    "polygon": [
      55.19,
      5.164,
      12.5,
      55.19,
      5.164,
      15.96,
      55.485,
      6.046,
      15.96,
      55.485,
      6.046,
      15.96,
      55.485,
      6.046,
      12.5,
      55.19,
      5.164,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12250",
    "polygon": [
      86.938,
      -22.056,
      12.5,
      86.938,
      -22.056,
      15.96,
      87.135,
      -21.468,
      15.96,
      87.135,
      -21.468,
      15.96,
      87.135,
      -21.468,
      12.5,
      86.938,
      -22.056,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12222",
    "polygon": [
      82.579,
      -17.122,
      8.75,
      82.579,
      -17.122,
      12.21,
      82.776,
      -16.534,
      12.21,
      82.776,
      -16.534,
      12.21,
      82.776,
      -16.534,
      8.75,
      82.579,
      -17.122,
      8.75
    ],
    "05-22 17:15": 1,
    "05-22 17:30": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12243",
    "polygon": [
      79.418,
      9.777,
      12.5,
      79.418,
      9.777,
      15.96,
      80.299,
      9.482,
      15.96,
      80.299,
      9.482,
      15.96,
      80.299,
      9.482,
      12.5,
      79.418,
      9.777,
      12.5
    ],
    "05-22 03:45": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12257",
    "polygon": [
      30.628,
      62.963,
      0.0,
      30.628,
      62.963,
      4.5,
      30.333,
      62.081,
      4.5,
      30.333,
      62.081,
      4.5,
      30.333,
      62.081,
      0.0,
      30.628,
      62.963,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12229",
    "polygon": [
      71.525,
      -25.466,
      8.75,
      71.525,
      -25.466,
      12.21,
      71.061,
      -25.311,
      12.21,
      71.061,
      -25.311,
      12.21,
      71.061,
      -25.311,
      8.75,
      71.525,
      -25.466,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12264",
    "polygon": [
      40.05,
      91.094,
      0.0,
      40.05,
      91.094,
      4.5,
      39.395,
      89.141,
      4.5,
      39.395,
      89.141,
      4.5,
      39.395,
      89.141,
      0.0,
      40.05,
      91.094,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12271",
    "polygon": [
      79.563,
      78.548,
      0.0,
      79.563,
      78.548,
      4.5,
      79.858,
      79.43,
      4.5,
      79.858,
      79.43,
      4.5,
      79.858,
      79.43,
      0.0,
      79.563,
      78.548,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12292",
    "polygon": [
      36.99,
      73.455,
      5.0,
      36.99,
      73.455,
      8.46,
      37.286,
      74.336,
      8.46,
      37.286,
      74.336,
      8.46,
      37.286,
      74.336,
      5.0,
      36.99,
      73.455,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12278",
    "polygon": [
      67.785,
      43.373,
      0.0,
      67.785,
      43.373,
      4.5,
      68.439,
      45.326,
      4.5,
      68.439,
      45.326,
      4.5,
      68.439,
      45.326,
      0.0,
      67.785,
      43.373,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12285",
    "polygon": [
      60.426,
      38.6,
      0.0,
      60.426,
      38.6,
      4.5,
      59.032,
      39.067,
      4.5,
      59.032,
      39.067,
      4.5,
      59.032,
      39.067,
      0.0,
      60.426,
      38.6,
      0.0
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12068",
    "polygon": [
      -15.851,
      34.117,
      0.0,
      -15.851,
      34.117,
      5.0,
      -16.007,
      33.653,
      5.0,
      -16.007,
      33.653,
      5.0,
      -16.007,
      33.653,
      0.0,
      -15.851,
      34.117,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12086",
    "polygon": [
      -24.598,
      3.761,
      5.0,
      -24.598,
      3.761,
      8.75,
      -22.464,
      3.046,
      8.75,
      -22.464,
      3.046,
      8.75,
      -22.464,
      3.046,
      5.0,
      -24.598,
      3.761,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12094",
    "polygon": [
      -17.975,
      23.614,
      5.0,
      -17.975,
      23.614,
      8.75,
      -18.301,
      22.643,
      8.75,
      -18.301,
      22.643,
      8.75,
      -18.301,
      22.643,
      5.0,
      -17.975,
      23.614,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12077",
    "polygon": [
      13.658,
      15.677,
      0.0,
      13.658,
      15.677,
      5.0,
      14.118,
      17.052,
      5.0,
      14.118,
      17.052,
      5.0,
      14.118,
      17.052,
      0.0,
      13.658,
      15.677,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12101",
    "polygon": [
      -2.396,
      -3.677,
      5.0,
      -2.396,
      -3.677,
      8.46,
      -1.931,
      -3.832,
      8.46,
      -1.931,
      -3.832,
      8.46,
      -1.931,
      -3.832,
      5.0,
      -2.396,
      -3.677,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12108",
    "polygon": [
      12.263,
      35.127,
      5.0,
      12.263,
      35.127,
      8.46,
      11.798,
      35.283,
      8.46,
      11.798,
      35.283,
      8.46,
      11.798,
      35.283,
      5.0,
      12.263,
      35.127,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12115",
    "polygon": [
      -19.035,
      29.601,
      8.75,
      -19.035,
      29.601,
      12.21,
      -18.74,
      30.482,
      12.21,
      -18.74,
      30.482,
      12.21,
      -18.74,
      30.482,
      8.75,
      -19.035,
      29.601,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12139",
    "polygon": [
      -8.292,
      1.278,
      8.75,
      -8.292,
      1.278,
      12.21,
      -8.757,
      1.434,
      12.21,
      -8.757,
      1.434,
      12.21,
      -8.757,
      1.434,
      8.75,
      -8.292,
      1.278,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12122",
    "polygon": [
      12.831,
      34.937,
      8.75,
      12.831,
      34.937,
      12.21,
      12.366,
      35.092,
      12.21,
      12.366,
      35.092,
      12.21,
      12.366,
      35.092,
      8.75,
      12.831,
      34.937,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12132",
    "polygon": [
      17.365,
      16.247,
      8.75,
      17.365,
      16.247,
      12.21,
      18.019,
      18.2,
      12.21,
      18.019,
      18.2,
      12.21,
      18.019,
      18.2,
      8.75,
      17.365,
      16.247,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12146",
    "polygon": [
      12.72,
      34.974,
      12.5,
      12.72,
      34.974,
      15.96,
      12.255,
      35.129,
      15.96,
      12.255,
      35.129,
      15.96,
      12.255,
      35.129,
      12.5,
      12.72,
      34.974,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12202",
    "polygon": [
      44.989,
      -16.79,
      8.75,
      44.989,
      -16.79,
      12.21,
      44.693,
      -17.672,
      12.21,
      44.693,
      -17.672,
      12.21,
      44.693,
      -17.672,
      8.75,
      44.989,
      -16.79,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12195",
    "polygon": [
      75.439,
      -26.777,
      5.0,
      75.439,
      -26.777,
      8.46,
      74.974,
      -26.621,
      8.46,
      74.974,
      -26.621,
      8.46,
      74.974,
      -26.621,
      5.0,
      75.439,
      -26.777,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12209",
    "polygon": [
      55.186,
      5.193,
      8.75,
      55.186,
      5.193,
      12.21,
      55.84,
      7.147,
      12.21,
      55.84,
      7.147,
      12.21,
      55.84,
      7.147,
      8.75,
      55.186,
      5.193,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12174",
    "polygon": [
      45.836,
      -4.815,
      5.0,
      45.836,
      -4.815,
      8.46,
      46.033,
      -4.227,
      8.46,
      46.033,
      -4.227,
      8.46,
      46.033,
      -4.227,
      5.0,
      45.836,
      -4.815,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12181",
    "polygon": [
      69.176,
      13.075,
      5.0,
      69.176,
      13.075,
      8.46,
      69.64,
      12.919,
      8.46,
      69.64,
      12.919,
      8.46,
      69.64,
      12.919,
      5.0,
      69.176,
      13.075,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12167",
    "polygon": [
      66.123,
      -23.491,
      0.0,
      66.123,
      -23.491,
      5.0,
      65.658,
      -23.335,
      5.0,
      65.658,
      -23.335,
      5.0,
      65.658,
      -23.335,
      0.0,
      66.123,
      -23.491,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12216",
    "polygon": [
      79.437,
      9.77,
      8.75,
      79.437,
      9.77,
      12.21,
      80.319,
      9.475,
      12.21,
      80.319,
      9.475,
      12.21,
      80.319,
      9.475,
      8.75,
      79.437,
      9.77,
      8.75
    ],
    "05-22 03:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12188",
    "polygon": [
      93.132,
      -3.566,
      5.0,
      93.132,
      -3.566,
      8.46,
      93.427,
      -2.684,
      8.46,
      93.427,
      -2.684,
      8.46,
      93.427,
      -2.684,
      5.0,
      93.132,
      -3.566,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12153",
    "polygon": [
      49.918,
      -21.011,
      0.0,
      49.918,
      -21.011,
      5.0,
      50.383,
      -21.165,
      5.0,
      50.383,
      -21.165,
      5.0,
      50.383,
      -21.165,
      0.0,
      49.918,
      -21.011,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12160",
    "polygon": [
      79.106,
      12.895,
      0.0,
      79.106,
      12.895,
      5.0,
      78.642,
      13.05,
      5.0,
      78.642,
      13.05,
      5.0,
      78.642,
      13.05,
      0.0,
      79.106,
      12.895,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12237",
    "polygon": [
      63.335,
      18.011,
      12.5,
      63.335,
      18.011,
      15.96,
      62.87,
      18.167,
      15.96,
      62.87,
      18.167,
      15.96,
      62.87,
      18.167,
      12.5,
      63.335,
      18.011,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12223",
    "polygon": [
      89.029,
      -25.258,
      8.75,
      89.029,
      -25.258,
      12.21,
      88.832,
      -25.846,
      12.21,
      88.832,
      -25.846,
      12.21,
      88.832,
      -25.846,
      8.75,
      89.029,
      -25.258,
      8.75
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12251",
    "polygon": [
      82.574,
      -17.138,
      12.5,
      82.574,
      -17.138,
      15.96,
      82.771,
      -16.55,
      15.96,
      82.771,
      -16.55,
      15.96,
      82.771,
      -16.55,
      12.5,
      82.574,
      -17.138,
      12.5
    ],
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12244",
    "polygon": [
      93.126,
      -3.581,
      12.5,
      93.126,
      -3.581,
      15.96,
      93.422,
      -2.699,
      15.96,
      93.422,
      -2.699,
      15.96,
      93.422,
      -2.699,
      12.5,
      93.126,
      -3.581,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12258",
    "polygon": [
      30.023,
      61.157,
      0.0,
      30.023,
      61.157,
      4.5,
      29.664,
      60.085,
      4.5,
      29.664,
      60.085,
      4.5,
      29.664,
      60.085,
      0.0,
      30.023,
      61.157,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12230",
    "polygon": [
      54.599,
      -22.762,
      12.5,
      54.599,
      -22.762,
      15.96,
      55.187,
      -22.959,
      15.96,
      55.187,
      -22.959,
      15.96,
      55.187,
      -22.959,
      12.5,
      54.599,
      -22.762,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12272",
    "polygon": [
      78.89,
      76.541,
      0.0,
      78.89,
      76.541,
      4.5,
      79.249,
      77.612,
      4.5,
      79.249,
      77.612,
      4.5,
      79.249,
      77.612,
      0.0,
      78.89,
      76.541,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12286",
    "polygon": [
      58.139,
      39.366,
      0.0,
      58.139,
      39.366,
      4.5,
      57.551,
      39.563,
      4.5,
      57.551,
      39.563,
      4.5,
      57.551,
      39.563,
      0.0,
      58.139,
      39.366,
      0.0
    ],
    "05-22 09:00": 1,
    "05-22 09:15": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12265",
    "polygon": [
      49.121,
      88.555,
      0.0,
      49.121,
      88.555,
      4.5,
      49.586,
      88.399,
      4.5,
      49.586,
      88.399,
      4.5,
      49.586,
      88.399,
      0.0,
      49.121,
      88.555,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12293",
    "polygon": [
      37.591,
      75.247,
      5.0,
      37.591,
      75.247,
      8.46,
      37.95,
      76.318,
      8.46,
      37.95,
      76.318,
      8.46,
      37.95,
      76.318,
      5.0,
      37.591,
      75.247,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12279",
    "polygon": [
      48.114,
      50.999,
      0.0,
      48.114,
      50.999,
      4.5,
      46.947,
      51.389,
      4.5,
      46.947,
      51.389,
      4.5,
      46.947,
      51.389,
      0.0,
      48.114,
      50.999,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12069",
    "polygon": [
      -14.78,
      38.699,
      0.0,
      -14.78,
      38.699,
      5.0,
      -14.844,
      38.213,
      5.0,
      -14.844,
      38.213,
      5.0,
      -14.844,
      38.213,
      0.0,
      -14.78,
      38.699,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12087",
    "polygon": [
      -24.472,
      4.218,
      5.0,
      -24.472,
      4.218,
      8.46,
      -24.628,
      3.754,
      8.46,
      -24.628,
      3.754,
      8.46,
      -24.628,
      3.754,
      5.0,
      -24.472,
      4.218,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12078",
    "polygon": [
      16.08,
      22.908,
      0.0,
      16.08,
      22.908,
      5.0,
      16.236,
      23.373,
      5.0,
      16.236,
      23.373,
      5.0,
      16.236,
      23.373,
      0.0,
      16.08,
      22.908,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12095",
    "polygon": [
      -19.433,
      42.905,
      5.0,
      -19.433,
      42.905,
      8.46,
      -18.969,
      42.749,
      8.46,
      -18.969,
      42.749,
      8.46,
      -18.969,
      42.749,
      5.0,
      -19.433,
      42.905,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12102",
    "polygon": [
      0.774,
      -4.738,
      5.0,
      0.774,
      -4.738,
      8.46,
      1.239,
      -4.894,
      8.46,
      1.239,
      -4.894,
      8.46,
      1.239,
      -4.894,
      5.0,
      0.774,
      -4.738,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12109",
    "polygon": [
      12.934,
      29.151,
      5.0,
      12.934,
      29.151,
      8.46,
      12.779,
      28.687,
      8.46,
      12.779,
      28.687,
      8.46,
      12.779,
      28.687,
      5.0,
      12.934,
      29.151,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12116",
    "polygon": [
      -22.327,
      28.348,
      8.75,
      -22.327,
      28.348,
      12.21,
      -22.524,
      27.76,
      12.21,
      -22.524,
      27.76,
      12.21,
      -22.524,
      27.76,
      8.75,
      -22.327,
      28.348,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12133",
    "polygon": [
      14.848,
      17.234,
      8.75,
      14.848,
      17.234,
      12.21,
      14.489,
      16.162,
      12.21,
      14.489,
      16.162,
      12.21,
      14.489,
      16.162,
      8.75,
      14.848,
      17.234,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12140",
    "polygon": [
      -11.09,
      2.215,
      8.75,
      -11.09,
      2.215,
      12.21,
      -11.555,
      2.371,
      12.21,
      -11.555,
      2.371,
      12.21,
      -11.555,
      2.371,
      8.75,
      -11.09,
      2.215,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12123",
    "polygon": [
      9.934,
      33.05,
      8.75,
      9.934,
      33.05,
      12.21,
      10.398,
      32.895,
      12.21,
      10.398,
      32.895,
      12.21,
      10.398,
      32.895,
      8.75,
      9.934,
      33.05,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12147",
    "polygon": [
      17.472,
      33.382,
      12.5,
      17.472,
      33.382,
      15.96,
      17.007,
      33.538,
      15.96,
      17.007,
      33.538,
      15.96,
      17.007,
      33.538,
      12.5,
      17.472,
      33.382,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12203",
    "polygon": [
      45.663,
      -14.775,
      8.75,
      45.663,
      -14.775,
      12.21,
      45.304,
      -15.846,
      12.21,
      45.304,
      -15.846,
      12.21,
      45.304,
      -15.846,
      8.75,
      45.663,
      -14.775,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12196",
    "polygon": [
      72.54,
      -25.806,
      5.0,
      72.54,
      -25.806,
      8.46,
      72.075,
      -25.65,
      8.46,
      72.075,
      -25.65,
      8.46,
      72.075,
      -25.65,
      5.0,
      72.54,
      -25.806,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12175",
    "polygon": [
      49.04,
      -4.713,
      5.0,
      49.04,
      -4.713,
      8.46,
      48.681,
      -5.785,
      8.46,
      48.681,
      -5.785,
      8.46,
      48.681,
      -5.785,
      5.0,
      49.04,
      -4.713,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12210",
    "polygon": [
      63.324,
      18.015,
      8.75,
      63.324,
      18.015,
      12.21,
      62.86,
      18.17,
      12.21,
      62.86,
      18.17,
      12.21,
      62.86,
      18.17,
      8.75,
      63.324,
      18.015,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12182",
    "polygon": [
      70.899,
      12.498,
      5.0,
      70.899,
      12.498,
      8.46,
      71.363,
      12.342,
      8.46,
      71.363,
      12.342,
      8.46,
      71.363,
      12.342,
      5.0,
      70.899,
      12.498,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12168",
    "polygon": [
      51.395,
      -24.867,
      5.0,
      51.395,
      -24.867,
      8.46,
      50.807,
      -24.67,
      8.46,
      50.807,
      -24.67,
      8.46,
      50.807,
      -24.67,
      5.0,
      51.395,
      -24.867,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12217",
    "polygon": [
      93.128,
      -3.575,
      8.75,
      93.128,
      -3.575,
      12.21,
      93.424,
      -2.694,
      12.21,
      93.424,
      -2.694,
      12.21,
      93.424,
      -2.694,
      8.75,
      93.128,
      -3.575,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12161",
    "polygon": [
      81.953,
      11.941,
      0.0,
      81.953,
      11.941,
      5.0,
      81.488,
      12.097,
      5.0,
      81.488,
      12.097,
      5.0,
      81.488,
      12.097,
      0.0,
      81.953,
      11.941,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12154",
    "polygon": [
      44.401,
      -18.059,
      0.0,
      44.401,
      -18.059,
      5.0,
      44.247,
      -18.524,
      5.0,
      44.247,
      -18.524,
      5.0,
      44.247,
      -18.524,
      0.0,
      44.401,
      -18.059,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12189",
    "polygon": [
      86.365,
      -15.265,
      5.0,
      86.365,
      -15.265,
      8.46,
      86.07,
      -16.147,
      8.46,
      86.07,
      -16.147,
      8.46,
      86.07,
      -16.147,
      5.0,
      86.365,
      -15.265,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12238",
    "polygon": [
      66.361,
      14.018,
      12.5,
      66.361,
      14.018,
      15.96,
      66.826,
      13.862,
      15.96,
      66.826,
      13.862,
      15.96,
      66.826,
      13.862,
      12.5,
      66.361,
      14.018,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12224",
    "polygon": [
      85.771,
      -17.038,
      8.75,
      85.771,
      -17.038,
      12.21,
      85.412,
      -18.109,
      12.21,
      85.412,
      -18.109,
      12.21,
      85.412,
      -18.109,
      8.75,
      85.771,
      -17.038,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12252",
    "polygon": [
      85.77,
      -17.042,
      12.5,
      85.77,
      -17.042,
      15.96,
      85.411,
      -18.114,
      15.96,
      85.411,
      -18.114,
      15.96,
      85.411,
      -18.114,
      12.5,
      85.77,
      -17.042,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12245",
    "polygon": [
      93.74,
      -1.751,
      12.5,
      93.74,
      -1.751,
      15.96,
      94.098,
      -0.68,
      15.96,
      94.098,
      -0.68,
      15.96,
      94.098,
      -0.68,
      12.5,
      93.74,
      -1.751,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12259",
    "polygon": [
      26.831,
      61.072,
      0.0,
      26.831,
      61.072,
      4.5,
      27.028,
      61.66,
      4.5,
      27.028,
      61.66,
      4.5,
      27.028,
      61.66,
      0.0,
      26.831,
      61.072,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12231",
    "polygon": [
      51.722,
      -21.798,
      12.5,
      51.722,
      -21.798,
      15.96,
      53.676,
      -22.452,
      15.96,
      53.676,
      -22.452,
      15.96,
      53.676,
      -22.452,
      12.5,
      51.722,
      -21.798,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12287",
    "polygon": [
      57.467,
      39.591,
      0.0,
      57.467,
      39.591,
      4.5,
      56.676,
      39.856,
      4.5,
      56.676,
      39.856,
      4.5,
      56.676,
      39.856,
      0.0,
      57.467,
      39.591,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12266",
    "polygon": [
      52.689,
      87.36,
      0.0,
      52.689,
      87.36,
      4.5,
      53.154,
      87.204,
      4.5,
      53.154,
      87.204,
      4.5,
      53.154,
      87.204,
      0.0,
      52.689,
      87.36,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12273",
    "polygon": [
      81.949,
      76.227,
      0.0,
      81.949,
      76.227,
      4.5,
      81.752,
      75.639,
      4.5,
      81.752,
      75.639,
      4.5,
      81.752,
      75.639,
      0.0,
      81.949,
      76.227,
      0.0
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12280",
    "polygon": [
      46.007,
      51.704,
      0.0,
      46.007,
      51.704,
      4.5,
      42.878,
      52.753,
      4.5,
      42.878,
      52.753,
      4.5,
      42.878,
      52.753,
      0.0,
      46.007,
      51.704,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12294",
    "polygon": [
      40.789,
      75.351,
      5.0,
      40.789,
      75.351,
      8.46,
      40.593,
      74.763,
      8.46,
      40.593,
      74.763,
      8.46,
      40.593,
      74.763,
      5.0,
      40.789,
      75.351,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12295",
    "polygon": [
      37.568,
      83.685,
      5.0,
      37.568,
      83.685,
      8.46,
      37.371,
      83.097,
      8.46,
      37.371,
      83.097,
      8.46,
      37.371,
      83.097,
      5.0,
      37.568,
      83.685,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12302",
    "polygon": [
      62.865,
      80.916,
      5.0,
      62.865,
      80.916,
      8.46,
      62.278,
      81.113,
      8.46,
      62.278,
      81.113,
      8.46,
      62.278,
      81.113,
      5.0,
      62.865,
      80.916,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12309",
    "polygon": [
      70.375,
      59.618,
      5.0,
      70.375,
      59.618,
      8.46,
      70.178,
      59.03,
      8.46,
      70.178,
      59.03,
      8.46,
      70.178,
      59.03,
      5.0,
      70.375,
      59.618,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12316",
    "polygon": [
      70.826,
      43.022,
      5.0,
      70.826,
      43.022,
      8.46,
      70.629,
      42.434,
      8.46,
      70.629,
      42.434,
      8.46,
      70.629,
      42.434,
      5.0,
      70.826,
      43.022,
      5.0
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12323",
    "polygon": [
      42.749,
      52.796,
      5.0,
      42.749,
      52.796,
      8.75,
      40.283,
      53.622,
      8.75,
      40.283,
      53.622,
      8.75,
      40.283,
      53.622,
      5.0,
      42.749,
      52.796,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12330",
    "polygon": [
      37.587,
      75.234,
      8.75,
      37.587,
      75.234,
      12.21,
      37.945,
      76.306,
      12.21,
      37.945,
      76.306,
      12.21,
      37.945,
      76.306,
      8.75,
      37.587,
      75.234,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12337",
    "polygon": [
      49.125,
      88.554,
      8.75,
      49.125,
      88.554,
      12.21,
      49.589,
      88.398,
      12.21,
      49.589,
      88.398,
      12.21,
      49.589,
      88.398,
      8.75,
      49.125,
      88.554,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12344",
    "polygon": [
      67.555,
      60.635,
      8.75,
      67.555,
      60.635,
      12.21,
      67.752,
      61.223,
      12.21,
      67.752,
      61.223,
      12.21,
      67.752,
      61.223,
      8.75,
      67.555,
      60.635,
      8.75
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12351",
    "polygon": [
      59.807,
      44.234,
      8.75,
      59.807,
      44.234,
      12.5,
      61.23,
      43.758,
      12.5,
      61.23,
      43.758,
      12.5,
      61.23,
      43.758,
      8.75,
      59.807,
      44.234,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12358",
    "polygon": [
      40.582,
      50.675,
      8.75,
      40.582,
      50.675,
      12.5,
      43.427,
      49.725,
      12.5,
      43.427,
      49.725,
      12.5,
      43.427,
      49.725,
      8.75,
      40.582,
      50.675,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12365",
    "polygon": [
      35.388,
      77.167,
      12.5,
      35.388,
      77.167,
      15.96,
      35.092,
      76.285,
      15.96,
      35.092,
      76.285,
      15.96,
      35.092,
      76.285,
      12.5,
      35.388,
      77.167,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12372",
    "polygon": [
      49.119,
      88.556,
      12.5,
      49.119,
      88.556,
      15.96,
      49.584,
      88.4,
      15.96,
      49.584,
      88.4,
      15.96,
      49.584,
      88.4,
      12.5,
      49.119,
      88.556,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12379",
    "polygon": [
      66.93,
      85.562,
      12.5,
      66.93,
      85.562,
      15.96,
      65.859,
      85.921,
      15.96,
      65.859,
      85.921,
      15.96,
      65.859,
      85.921,
      12.5,
      66.93,
      85.562,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12386",
    "polygon": [
      70.758,
      52.247,
      12.5,
      70.758,
      52.247,
      15.96,
      70.955,
      52.835,
      15.96,
      70.955,
      52.835,
      15.96,
      70.955,
      52.835,
      12.5,
      70.758,
      52.247,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12403",
    "polygon": [
      -44.193,
      63.988,
      0.0,
      -44.193,
      63.988,
      5.0,
      -44.479,
      63.146,
      5.0,
      -44.479,
      63.146,
      5.0,
      -44.479,
      63.146,
      0.0,
      -44.193,
      63.988,
      0.0
    ],
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12412",
    "polygon": [
      58.461,
      35.307,
      0.0,
      58.461,
      35.307,
      5.0,
      58.747,
      36.149,
      5.0,
      58.747,
      36.149,
      5.0,
      58.747,
      36.149,
      0.0,
      58.461,
      35.307,
      0.0
    ],
    "05-22 16:30": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12393",
    "polygon": [
      34.119,
      52.843,
      12.5,
      34.119,
      52.843,
      15.96,
      34.584,
      52.687,
      15.96,
      34.584,
      52.687,
      15.96,
      34.584,
      52.687,
      12.5,
      34.119,
      52.843,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12511",
    "polygon": [
      23.193,
      36.913,
      5.0,
      23.193,
      36.913,
      8.35,
      24.378,
      36.516,
      8.35,
      24.378,
      36.516,
      8.35,
      24.378,
      36.516,
      5.0,
      23.193,
      36.913,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12518",
    "polygon": [
      16.142,
      39.275,
      5.0,
      16.142,
      39.275,
      8.35,
      16.663,
      39.1,
      8.35,
      16.663,
      39.1,
      8.35,
      16.663,
      39.1,
      5.0,
      16.142,
      39.275,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12532",
    "polygon": [
      -15.92,
      50.019,
      5.0,
      -15.92,
      50.019,
      8.35,
      -14.734,
      49.622,
      8.35,
      -14.734,
      49.622,
      8.35,
      -14.734,
      49.622,
      5.0,
      -15.92,
      50.019,
      5.0
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12525",
    "polygon": [
      -8.179,
      47.426,
      5.0,
      -8.179,
      47.426,
      8.35,
      -7.089,
      47.061,
      8.35,
      -7.089,
      47.061,
      8.35,
      -7.089,
      47.061,
      5.0,
      -8.179,
      47.426,
      5.0
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12539",
    "polygon": [
      56.903,
      39.749,
      5.0,
      56.903,
      39.749,
      8.35,
      55.718,
      40.146,
      8.35,
      55.718,
      40.146,
      8.35,
      55.718,
      40.146,
      5.0,
      56.903,
      39.749,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12546",
    "polygon": [
      49.146,
      42.347,
      5.0,
      49.146,
      42.347,
      8.35,
      48.624,
      42.522,
      8.35,
      48.624,
      42.522,
      8.35,
      48.624,
      42.522,
      5.0,
      49.146,
      42.347,
      5.0
    ],
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12553",
    "polygon": [
      34.332,
      47.315,
      5.0,
      34.332,
      47.315,
      8.35,
      33.241,
      47.681,
      8.35,
      33.241,
      47.681,
      8.35,
      33.241,
      47.681,
      5.0,
      34.332,
      47.315,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12560",
    "polygon": [
      27.274,
      49.679,
      5.0,
      27.274,
      49.679,
      8.35,
      26.088,
      50.075,
      8.35,
      26.088,
      50.075,
      8.35,
      26.088,
      50.075,
      5.0,
      27.274,
      49.679,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12567",
    "polygon": [
      -11.767,
      62.866,
      5.0,
      -11.767,
      62.866,
      8.35,
      -12.858,
      63.231,
      8.35,
      -12.858,
      63.231,
      8.35,
      -12.858,
      63.231,
      5.0,
      -11.767,
      62.866,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12574",
    "polygon": [
      -27.152,
      68.02,
      5.0,
      -27.152,
      68.02,
      8.35,
      -27.674,
      68.194,
      8.35,
      -27.674,
      68.194,
      8.35,
      -27.674,
      68.194,
      5.0,
      -27.152,
      68.02,
      5.0
    ],
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12581",
    "polygon": [
      -33.718,
      70.219,
      5.0,
      -33.718,
      70.219,
      8.35,
      -34.24,
      70.394,
      8.35,
      -34.24,
      70.394,
      8.35,
      -34.24,
      70.394,
      5.0,
      -33.718,
      70.219,
      5.0
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12588",
    "polygon": [
      -41.365,
      72.781,
      5.0,
      -41.365,
      72.781,
      8.35,
      -42.55,
      73.178,
      8.35,
      -42.55,
      73.178,
      8.35,
      -42.55,
      73.178,
      5.0,
      -41.365,
      72.781,
      5.0
    ],
    "05-22 15:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12609",
    "polygon": [
      54.531,
      40.541,
      12.5,
      54.531,
      40.541,
      15.85,
      53.345,
      40.938,
      15.85,
      53.345,
      40.938,
      15.85,
      53.345,
      40.938,
      12.5,
      54.531,
      40.541,
      12.5
    ],
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12595",
    "polygon": [
      53.312,
      40.952,
      8.75,
      53.312,
      40.952,
      12.1,
      52.222,
      41.317,
      12.1,
      52.222,
      41.317,
      12.1,
      52.222,
      41.317,
      8.75,
      53.312,
      40.952,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12602",
    "polygon": [
      46.235,
      43.321,
      8.75,
      46.235,
      43.321,
      12.1,
      45.05,
      43.718,
      12.1,
      45.05,
      43.718,
      12.1,
      45.05,
      43.718,
      8.75,
      46.235,
      43.321,
      8.75
    ],
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12296",
    "polygon": [
      37.071,
      82.203,
      5.0,
      37.071,
      82.203,
      8.46,
      36.417,
      80.25,
      8.46,
      36.417,
      80.25,
      8.46,
      36.417,
      80.25,
      5.0,
      37.071,
      82.203,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12310",
    "polygon": [
      71.343,
      62.507,
      5.0,
      71.343,
      62.507,
      8.46,
      70.689,
      60.554,
      8.46,
      70.689,
      60.554,
      8.46,
      70.689,
      60.554,
      5.0,
      71.343,
      62.507,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12303",
    "polygon": [
      63.2,
      83.968,
      5.0,
      63.2,
      83.968,
      8.46,
      64.272,
      83.609,
      8.46,
      64.272,
      83.609,
      8.46,
      64.272,
      83.609,
      5.0,
      63.2,
      83.968,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12317",
    "polygon": [
      52.559,
      46.535,
      5.0,
      52.559,
      46.535,
      8.46,
      53.023,
      46.379,
      8.46,
      53.023,
      46.379,
      8.46,
      53.023,
      46.379,
      5.0,
      52.559,
      46.535,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12324",
    "polygon": [
      39.35,
      53.934,
      5.0,
      39.35,
      53.934,
      8.75,
      36.648,
      54.84,
      8.75,
      36.648,
      54.84,
      8.75,
      36.648,
      54.84,
      5.0,
      39.35,
      53.934,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12352",
    "polygon": [
      52.563,
      46.533,
      8.75,
      52.563,
      46.533,
      12.21,
      53.028,
      46.377,
      12.21,
      53.028,
      46.377,
      12.21,
      53.028,
      46.377,
      8.75,
      52.563,
      46.533,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12338",
    "polygon": [
      55.254,
      86.501,
      8.75,
      55.254,
      86.501,
      12.21,
      55.719,
      86.345,
      12.21,
      55.719,
      86.345,
      12.21,
      55.719,
      86.345,
      8.75,
      55.254,
      86.501,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12345",
    "polygon": [
      74.789,
      54.852,
      8.75,
      74.789,
      54.852,
      12.21,
      74.592,
      54.264,
      12.21,
      74.592,
      54.264,
      12.21,
      74.592,
      54.264,
      8.75,
      74.789,
      54.852,
      8.75
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12331",
    "polygon": [
      36.988,
      73.448,
      8.75,
      36.988,
      73.448,
      12.21,
      37.284,
      74.33,
      12.21,
      37.284,
      74.33,
      12.21,
      37.284,
      74.33,
      8.75,
      36.988,
      73.448,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12359",
    "polygon": [
      36.087,
      52.175,
      8.75,
      36.087,
      52.175,
      12.5,
      38.554,
      51.352,
      12.5,
      38.554,
      51.352,
      12.5,
      38.554,
      51.352,
      8.75,
      36.087,
      52.175,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12366",
    "polygon": [
      34.789,
      75.382,
      12.5,
      34.789,
      75.382,
      15.96,
      34.43,
      74.31,
      15.96,
      34.43,
      74.31,
      15.96,
      34.43,
      74.31,
      12.5,
      34.789,
      75.382,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12373",
    "polygon": [
      55.244,
      86.504,
      12.5,
      55.244,
      86.504,
      15.96,
      55.708,
      86.348,
      15.96,
      55.708,
      86.348,
      15.96,
      55.708,
      86.348,
      12.5,
      55.244,
      86.504,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12380",
    "polygon": [
      73.434,
      83.384,
      12.5,
      73.434,
      83.384,
      15.96,
      72.363,
      83.742,
      15.96,
      72.363,
      83.742,
      15.96,
      72.363,
      83.742,
      12.5,
      73.434,
      83.384,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12387",
    "polygon": [
      70.744,
      60.712,
      12.5,
      70.744,
      60.712,
      15.96,
      70.385,
      59.64,
      15.96,
      70.385,
      59.64,
      15.96,
      70.385,
      59.64,
      12.5,
      70.744,
      60.712,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12394",
    "polygon": [
      26.667,
      53.576,
      12.5,
      26.667,
      53.576,
      15.96,
      26.822,
      54.04,
      15.96,
      26.822,
      54.04,
      15.96,
      26.822,
      54.04,
      12.5,
      26.667,
      53.576,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12404",
    "polygon": [
      -43.123,
      67.143,
      0.0,
      -43.123,
      67.143,
      5.0,
      -43.669,
      65.533,
      5.0,
      -43.669,
      65.533,
      5.0,
      -43.669,
      65.533,
      0.0,
      -43.123,
      67.143,
      0.0
    ],
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12413",
    "polygon": [
      57.41,
      32.221,
      0.0,
      57.41,
      32.221,
      5.0,
      57.956,
      33.831,
      5.0,
      57.956,
      33.831,
      5.0,
      57.956,
      33.831,
      0.0,
      57.41,
      32.221,
      0.0
    ],
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12512",
    "polygon": [
      22.008,
      37.31,
      5.0,
      22.008,
      37.31,
      8.35,
      23.193,
      36.913,
      8.35,
      23.193,
      36.913,
      8.35,
      23.193,
      36.913,
      5.0,
      22.008,
      37.31,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12547",
    "polygon": [
      48.606,
      42.528,
      5.0,
      48.606,
      42.528,
      8.35,
      47.421,
      42.925,
      8.35,
      47.421,
      42.925,
      8.35,
      47.421,
      42.925,
      5.0,
      48.606,
      42.528,
      5.0
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12533",
    "polygon": [
      -17.045,
      50.396,
      5.0,
      -17.045,
      50.396,
      8.35,
      -15.955,
      50.031,
      8.35,
      -15.955,
      50.031,
      8.35,
      -15.955,
      50.031,
      5.0,
      -17.045,
      50.396,
      5.0
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12519",
    "polygon": [
      14.933,
      39.68,
      5.0,
      14.933,
      39.68,
      8.35,
      16.023,
      39.314,
      8.35,
      16.023,
      39.314,
      8.35,
      16.023,
      39.314,
      5.0,
      14.933,
      39.68,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12540",
    "polygon": [
      55.717,
      40.146,
      5.0,
      55.717,
      40.146,
      8.35,
      54.531,
      40.543,
      8.35,
      54.531,
      40.543,
      8.35,
      54.531,
      40.543,
      5.0,
      55.717,
      40.146,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12561",
    "polygon": [
      26.076,
      50.08,
      5.0,
      26.076,
      50.08,
      8.35,
      25.555,
      50.254,
      8.35,
      25.555,
      50.254,
      8.35,
      25.555,
      50.254,
      5.0,
      26.076,
      50.08,
      5.0
    ],
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12526",
    "polygon": [
      -9.4,
      47.835,
      5.0,
      -9.4,
      47.835,
      8.35,
      -8.214,
      47.438,
      8.35,
      -8.214,
      47.438,
      8.35,
      -8.214,
      47.438,
      5.0,
      -9.4,
      47.835,
      5.0
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12554",
    "polygon": [
      33.2,
      47.694,
      5.0,
      33.2,
      47.694,
      8.35,
      32.015,
      48.091,
      8.35,
      32.015,
      48.091,
      8.35,
      32.015,
      48.091,
      5.0,
      33.2,
      47.694,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12568",
    "polygon": [
      -12.976,
      63.271,
      5.0,
      -12.976,
      63.271,
      8.35,
      -14.067,
      63.636,
      8.35,
      -14.067,
      63.636,
      8.35,
      -14.067,
      63.636,
      5.0,
      -12.976,
      63.271,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12575",
    "polygon": [
      -27.792,
      68.234,
      5.0,
      -27.792,
      68.234,
      8.35,
      -28.314,
      68.409,
      8.35,
      -28.314,
      68.409,
      8.35,
      -28.314,
      68.409,
      5.0,
      -27.792,
      68.234,
      5.0
    ],
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12596",
    "polygon": [
      52.103,
      41.357,
      8.75,
      52.103,
      41.357,
      12.1,
      51.013,
      41.722,
      12.1,
      51.013,
      41.722,
      12.1,
      51.013,
      41.722,
      8.75,
      52.103,
      41.357,
      8.75
    ],
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12603",
    "polygon": [
      45.051,
      43.718,
      8.75,
      45.051,
      43.718,
      12.1,
      43.866,
      44.114,
      12.1,
      43.866,
      44.114,
      12.1,
      43.866,
      44.114,
      8.75,
      45.051,
      43.718,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12589",
    "polygon": [
      -42.55,
      73.178,
      5.0,
      -42.55,
      73.178,
      8.35,
      -43.735,
      73.575,
      8.35,
      -43.735,
      73.575,
      8.35,
      -43.735,
      73.575,
      5.0,
      -42.55,
      73.178,
      5.0
    ],
    "05-22 13:30": 1,
    "05-22 14:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12610",
    "polygon": [
      53.312,
      40.95,
      12.5,
      53.312,
      40.95,
      15.85,
      52.221,
      41.315,
      15.85,
      52.221,
      41.315,
      15.85,
      52.221,
      41.315,
      12.5,
      53.312,
      40.95,
      12.5
    ],
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12582",
    "polygon": [
      -34.253,
      70.398,
      5.0,
      -34.253,
      70.398,
      8.35,
      -35.438,
      70.795,
      8.35,
      -35.438,
      70.795,
      8.35,
      -35.438,
      70.795,
      5.0,
      -34.253,
      70.398,
      5.0
    ],
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12297",
    "polygon": [
      36.753,
      90.689,
      5.0,
      36.753,
      90.689,
      8.46,
      36.95,
      91.277,
      8.46,
      36.95,
      91.277,
      8.46,
      36.95,
      91.277,
      5.0,
      36.753,
      90.689,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12304",
    "polygon": [
      65.202,
      83.297,
      5.0,
      65.202,
      83.297,
      8.46,
      66.084,
      83.002,
      8.46,
      66.084,
      83.002,
      8.46,
      66.084,
      83.002,
      5.0,
      65.202,
      83.297,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12311",
    "polygon": [
      74.756,
      54.753,
      5.0,
      74.756,
      54.753,
      8.46,
      74.559,
      54.166,
      8.46,
      74.559,
      54.166,
      8.46,
      74.559,
      54.166,
      5.0,
      74.756,
      54.753,
      5.0
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1,
    "05-22 05:15": 1,
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12325",
    "polygon": [
      26.673,
      53.594,
      5.0,
      26.673,
      53.594,
      8.46,
      26.828,
      54.058,
      8.46,
      26.828,
      54.058,
      8.46,
      26.828,
      54.058,
      5.0,
      26.673,
      53.594,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12353",
    "polygon": [
      45.504,
      49.03,
      8.75,
      45.504,
      49.03,
      12.21,
      45.968,
      48.874,
      12.21,
      45.968,
      48.874,
      12.21,
      45.968,
      48.874,
      8.75,
      45.504,
      49.03,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12346",
    "polygon": [
      71.345,
      62.505,
      8.75,
      71.345,
      62.505,
      12.21,
      71.05,
      61.623,
      12.21,
      71.05,
      61.623,
      12.21,
      71.05,
      61.623,
      8.75,
      71.345,
      62.505,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12339",
    "polygon": [
      73.335,
      86.58,
      8.75,
      73.335,
      86.58,
      12.21,
      73.923,
      86.383,
      12.21,
      73.923,
      86.383,
      12.21,
      73.923,
      86.383,
      8.75,
      73.335,
      86.58,
      8.75
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12318",
    "polygon": [
      45.538,
      49.018,
      5.0,
      45.538,
      49.018,
      8.46,
      46.003,
      48.863,
      8.46,
      46.003,
      48.863,
      8.46,
      46.003,
      48.863,
      5.0,
      45.538,
      49.018,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12360",
    "polygon": [
      48.326,
      50.928,
      8.75,
      48.326,
      50.928,
      12.5,
      46.078,
      51.681,
      12.5,
      46.078,
      51.681,
      12.5,
      46.078,
      51.681,
      8.75,
      48.326,
      50.928,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12332",
    "polygon": [
      37.567,
      83.684,
      8.75,
      37.567,
      83.684,
      12.21,
      37.37,
      83.096,
      12.21,
      37.37,
      83.096,
      12.21,
      37.37,
      83.096,
      8.75,
      37.567,
      83.684,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12367",
    "polygon": [
      38.789,
      78.814,
      12.5,
      38.789,
      78.814,
      15.96,
      39.148,
      79.886,
      15.96,
      39.148,
      79.886,
      15.96,
      39.148,
      79.886,
      12.5,
      38.789,
      78.814,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12381",
    "polygon": [
      64.934,
      86.231,
      12.5,
      64.934,
      86.231,
      15.96,
      64.052,
      86.526,
      15.96,
      64.052,
      86.526,
      15.96,
      64.052,
      86.526,
      12.5,
      64.934,
      86.231,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12388",
    "polygon": [
      71.341,
      62.493,
      12.5,
      71.341,
      62.493,
      15.96,
      71.046,
      61.612,
      15.96,
      71.046,
      61.612,
      15.96,
      71.046,
      61.612,
      12.5,
      71.341,
      62.493,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12374",
    "polygon": [
      65.106,
      80.162,
      12.5,
      65.106,
      80.162,
      15.96,
      64.519,
      80.359,
      15.96,
      64.519,
      80.359,
      15.96,
      64.519,
      80.359,
      12.5,
      65.106,
      80.162,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12395",
    "polygon": [
      39.123,
      51.162,
      12.5,
      39.123,
      51.162,
      15.96,
      41.779,
      50.276,
      15.96,
      41.779,
      50.276,
      15.96,
      41.779,
      50.276,
      12.5,
      39.123,
      51.162,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12407",
    "polygon": [
      -42.643,
      68.678,
      0.0,
      -42.643,
      68.678,
      2.045,
      -43.149,
      67.186,
      2.045,
      -43.149,
      67.186,
      2.045,
      -43.149,
      67.186,
      0.0,
      -42.643,
      68.678,
      0.0
    ],
    "05-22 07:15": 1,
    "05-22 07:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12513",
    "polygon": [
      21.42,
      37.507,
      5.0,
      21.42,
      37.507,
      8.35,
      22.008,
      37.31,
      8.35,
      22.008,
      37.31,
      8.35,
      22.008,
      37.31,
      5.0,
      21.42,
      37.507,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12534",
    "polygon": [
      59.236,
      38.967,
      5.0,
      59.236,
      38.967,
      8.35,
      58.146,
      39.332,
      8.35,
      58.146,
      39.332,
      8.35,
      58.146,
      39.332,
      5.0,
      59.236,
      38.967,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12415",
    "polygon": [
      38.213,
      26.244,
      0.0,
      38.213,
      26.244,
      2.045,
      36.002,
      26.994,
      2.045,
      36.002,
      26.994,
      2.045,
      36.002,
      26.994,
      0.0,
      38.213,
      26.244,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12541",
    "polygon": [
      54.531,
      40.543,
      5.0,
      54.531,
      40.543,
      8.35,
      53.346,
      40.94,
      8.35,
      53.346,
      40.94,
      8.35,
      53.346,
      40.94,
      5.0,
      54.531,
      40.543,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12527",
    "polygon": [
      -10.545,
      48.219,
      5.0,
      -10.545,
      48.219,
      8.35,
      -9.454,
      47.853,
      8.35,
      -9.454,
      47.853,
      8.35,
      -9.454,
      47.853,
      5.0,
      -10.545,
      48.219,
      5.0
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12520",
    "polygon": [
      13.712,
      40.089,
      5.0,
      13.712,
      40.089,
      8.35,
      14.897,
      39.692,
      8.35,
      14.897,
      39.692,
      8.35,
      14.897,
      39.692,
      5.0,
      13.712,
      40.089,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12548",
    "polygon": [
      47.421,
      42.925,
      5.0,
      47.421,
      42.925,
      8.35,
      46.236,
      43.322,
      8.35,
      46.236,
      43.322,
      8.35,
      46.236,
      43.322,
      5.0,
      47.421,
      42.925,
      5.0
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12562",
    "polygon": [
      -7.05,
      61.286,
      5.0,
      -7.05,
      61.286,
      8.35,
      -8.14,
      61.651,
      8.35,
      -8.14,
      61.651,
      8.35,
      -8.14,
      61.651,
      5.0,
      -7.05,
      61.286,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12555",
    "polygon": [
      31.98,
      48.103,
      5.0,
      31.98,
      48.103,
      8.35,
      30.889,
      48.468,
      8.35,
      30.889,
      48.468,
      8.35,
      30.889,
      48.468,
      5.0,
      31.98,
      48.103,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12569",
    "polygon": [
      -14.103,
      63.649,
      5.0,
      -14.103,
      63.649,
      8.35,
      -15.289,
      64.046,
      8.35,
      -15.289,
      64.046,
      8.35,
      -15.289,
      64.046,
      5.0,
      -14.103,
      63.649,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12604",
    "polygon": [
      43.865,
      44.115,
      8.75,
      43.865,
      44.115,
      12.1,
      42.679,
      44.512,
      12.1,
      42.679,
      44.512,
      12.1,
      42.679,
      44.512,
      8.75,
      43.865,
      44.115,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12583",
    "polygon": [
      -35.438,
      70.795,
      5.0,
      -35.438,
      70.795,
      8.35,
      -36.624,
      71.192,
      8.35,
      -36.624,
      71.192,
      8.35,
      -36.624,
      71.192,
      5.0,
      -35.438,
      70.795,
      5.0
    ],
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12597",
    "polygon": [
      50.976,
      41.734,
      8.75,
      50.976,
      41.734,
      12.1,
      49.791,
      42.131,
      12.1,
      49.791,
      42.131,
      12.1,
      49.791,
      42.131,
      8.75,
      50.976,
      41.734,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12576",
    "polygon": [
      -28.326,
      68.413,
      5.0,
      -28.326,
      68.413,
      8.35,
      -29.511,
      68.81,
      8.35,
      -29.511,
      68.81,
      8.35,
      -29.511,
      68.81,
      5.0,
      -28.326,
      68.413,
      5.0
    ],
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12590",
    "polygon": [
      -43.735,
      73.575,
      5.0,
      -43.735,
      73.575,
      8.35,
      -44.257,
      73.749,
      8.35,
      -44.257,
      73.749,
      8.35,
      -44.257,
      73.749,
      5.0,
      -43.735,
      73.575,
      5.0
    ],
    "05-22 12:30": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12611",
    "polygon": [
      52.103,
      41.354,
      12.5,
      52.103,
      41.354,
      15.85,
      51.012,
      41.719,
      15.85,
      51.012,
      41.719,
      15.85,
      51.012,
      41.719,
      12.5,
      52.103,
      41.354,
      12.5
    ],
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12298",
    "polygon": [
      39.952,
      90.794,
      5.0,
      39.952,
      90.794,
      8.46,
      39.593,
      89.723,
      8.46,
      39.593,
      89.723,
      8.46,
      39.593,
      89.723,
      5.0,
      39.952,
      90.794,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12305",
    "polygon": [
      69.965,
      84.544,
      5.0,
      69.965,
      84.544,
      8.46,
      69.377,
      84.741,
      8.46,
      69.377,
      84.741,
      8.46,
      69.377,
      84.741,
      5.0,
      69.965,
      84.544,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12312",
    "polygon": [
      71.559,
      54.654,
      5.0,
      71.559,
      54.654,
      8.46,
      71.918,
      55.725,
      8.46,
      71.918,
      55.725,
      8.46,
      71.918,
      55.725,
      5.0,
      71.559,
      54.654,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12326",
    "polygon": [
      25.832,
      51.083,
      5.0,
      25.832,
      51.083,
      8.46,
      25.988,
      51.548,
      8.46,
      25.988,
      51.548,
      8.46,
      25.988,
      51.548,
      5.0,
      25.832,
      51.083,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12340",
    "polygon": [
      73.42,
      83.388,
      8.75,
      73.42,
      83.388,
      12.21,
      72.348,
      83.747,
      12.21,
      72.348,
      83.747,
      12.21,
      72.348,
      83.747,
      8.75,
      73.42,
      83.388,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12347",
    "polygon": [
      72.422,
      57.23,
      8.75,
      72.422,
      57.23,
      12.21,
      72.717,
      58.112,
      12.21,
      72.717,
      58.112,
      12.21,
      72.717,
      58.112,
      8.75,
      72.422,
      57.23,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12361",
    "polygon": [
      45.149,
      51.992,
      8.75,
      45.149,
      51.992,
      12.5,
      42.901,
      52.745,
      12.5,
      42.901,
      52.745,
      12.5,
      42.901,
      52.745,
      8.75,
      45.149,
      51.992,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12319",
    "polygon": [
      31.234,
      53.809,
      5.0,
      31.234,
      53.809,
      8.46,
      31.699,
      53.654,
      8.46,
      31.699,
      53.654,
      8.46,
      31.699,
      53.654,
      5.0,
      31.234,
      53.809,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12368",
    "polygon": [
      38.182,
      77.0,
      12.5,
      38.182,
      77.0,
      15.96,
      38.477,
      77.882,
      15.96,
      38.477,
      77.882,
      15.96,
      38.477,
      77.882,
      12.5,
      38.182,
      77.0,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12333",
    "polygon": [
      37.062,
      82.175,
      8.75,
      37.062,
      82.175,
      12.21,
      36.408,
      80.222,
      12.21,
      36.408,
      80.222,
      12.21,
      36.408,
      80.222,
      8.75,
      37.062,
      82.175,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12354",
    "polygon": [
      33.861,
      52.929,
      8.75,
      33.861,
      52.929,
      12.21,
      34.325,
      52.774,
      12.21,
      34.325,
      52.774,
      12.21,
      34.325,
      52.774,
      8.75,
      33.861,
      52.929,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12382",
    "polygon": [
      63.205,
      83.963,
      12.5,
      63.205,
      83.963,
      15.96,
      64.087,
      83.667,
      15.96,
      64.087,
      83.667,
      15.96,
      64.087,
      83.667,
      12.5,
      63.205,
      83.963,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12389",
    "polygon": [
      71.271,
      53.778,
      12.5,
      71.271,
      53.778,
      15.96,
      71.925,
      55.732,
      15.96,
      71.925,
      55.732,
      15.96,
      71.925,
      55.732,
      12.5,
      71.271,
      53.778,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12375",
    "polygon": [
      67.278,
      88.609,
      12.5,
      67.278,
      88.609,
      15.96,
      67.866,
      88.413,
      15.96,
      67.866,
      88.413,
      15.96,
      67.866,
      88.413,
      12.5,
      67.278,
      88.609,
      12.5
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12408",
    "polygon": [
      -44.173,
      64.027,
      0.0,
      -44.173,
      64.027,
      2.045,
      -43.667,
      65.518,
      2.045,
      -43.667,
      65.518,
      2.045,
      -43.667,
      65.518,
      0.0,
      -44.173,
      64.027,
      0.0
    ],
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12396",
    "polygon": [
      36.099,
      52.171,
      12.5,
      36.099,
      52.171,
      15.96,
      37.133,
      51.826,
      15.96,
      37.133,
      51.826,
      15.96,
      37.133,
      51.826,
      12.5,
      36.099,
      52.171,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12514",
    "polygon": [
      20.231,
      37.905,
      5.0,
      20.231,
      37.905,
      8.35,
      21.416,
      37.508,
      8.35,
      21.416,
      37.508,
      8.35,
      21.416,
      37.508,
      5.0,
      20.231,
      37.905,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12535",
    "polygon": [
      60.459,
      38.558,
      5.0,
      60.459,
      38.558,
      8.35,
      59.273,
      38.955,
      8.35,
      59.273,
      38.955,
      8.35,
      59.273,
      38.955,
      5.0,
      60.459,
      38.558,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12542",
    "polygon": [
      53.312,
      40.952,
      5.0,
      53.312,
      40.952,
      8.35,
      52.222,
      41.317,
      8.35,
      52.222,
      41.317,
      8.35,
      52.222,
      41.317,
      5.0,
      53.312,
      40.952,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12504",
    "polygon": [
      -24.292,
      116.18,
      0.0,
      -24.292,
      116.18,
      4.5,
      -24.88,
      116.377,
      4.5,
      -24.88,
      116.377,
      4.5,
      -24.88,
      116.377,
      0.0,
      -24.292,
      116.18,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12528",
    "polygon": [
      -11.138,
      48.417,
      5.0,
      -11.138,
      48.417,
      8.35,
      -10.663,
      48.258,
      8.35,
      -10.663,
      48.258,
      8.35,
      -10.663,
      48.258,
      5.0,
      -11.138,
      48.417,
      5.0
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12521",
    "polygon": [
      12.585,
      40.466,
      5.0,
      12.585,
      40.466,
      8.35,
      13.676,
      40.101,
      8.35,
      13.676,
      40.101,
      8.35,
      13.676,
      40.101,
      5.0,
      12.585,
      40.466,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12556",
    "polygon": [
      30.771,
      48.508,
      5.0,
      30.771,
      48.508,
      8.35,
      29.68,
      48.873,
      8.35,
      29.68,
      48.873,
      8.35,
      29.68,
      48.873,
      5.0,
      30.771,
      48.508,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12549",
    "polygon": [
      46.236,
      43.322,
      5.0,
      46.236,
      43.322,
      8.35,
      45.05,
      43.719,
      8.35,
      45.05,
      43.719,
      8.35,
      45.05,
      43.719,
      5.0,
      46.236,
      43.322,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12570",
    "polygon": [
      -15.299,
      64.049,
      5.0,
      -15.299,
      64.049,
      8.35,
      -15.821,
      64.224,
      8.35,
      -15.821,
      64.224,
      8.35,
      -15.821,
      64.224,
      5.0,
      -15.299,
      64.049,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12563",
    "polygon": [
      -8.176,
      61.663,
      5.0,
      -8.176,
      61.663,
      8.35,
      -9.362,
      62.06,
      8.35,
      -9.362,
      62.06,
      8.35,
      -9.362,
      62.06,
      5.0,
      -8.176,
      61.663,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12584",
    "polygon": [
      -36.624,
      71.192,
      5.0,
      -36.624,
      71.192,
      8.35,
      -37.809,
      71.59,
      8.35,
      -37.809,
      71.59,
      8.35,
      -37.809,
      71.59,
      5.0,
      -36.624,
      71.192,
      5.0
    ],
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12598",
    "polygon": [
      49.786,
      42.133,
      8.75,
      49.786,
      42.133,
      12.1,
      49.264,
      42.307,
      12.1,
      49.264,
      42.307,
      12.1,
      49.264,
      42.307,
      8.75,
      49.786,
      42.133,
      8.75
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12591",
    "polygon": [
      58.028,
      39.373,
      8.75,
      58.028,
      39.373,
      12.1,
      56.937,
      39.738,
      12.1,
      56.937,
      39.738,
      12.1,
      56.937,
      39.738,
      8.75,
      58.028,
      39.373,
      8.75
    ],
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12605",
    "polygon": [
      42.668,
      44.515,
      8.75,
      42.668,
      44.515,
      12.1,
      42.146,
      44.69,
      12.1,
      42.146,
      44.69,
      12.1,
      42.146,
      44.69,
      8.75,
      42.668,
      44.515,
      8.75
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12577",
    "polygon": [
      -29.57,
      68.83,
      5.0,
      -29.57,
      68.83,
      8.35,
      -30.66,
      69.195,
      8.35,
      -30.66,
      69.195,
      8.35,
      -30.66,
      69.195,
      5.0,
      -29.57,
      68.83,
      5.0
    ],
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12612",
    "polygon": [
      50.976,
      41.732,
      12.5,
      50.976,
      41.732,
      15.85,
      49.79,
      42.128,
      15.85,
      49.79,
      42.128,
      15.85,
      49.79,
      42.128,
      12.5,
      50.976,
      41.732,
      12.5
    ],
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12299",
    "polygon": [
      40.553,
      92.587,
      5.0,
      40.553,
      92.587,
      8.46,
      40.257,
      91.705,
      8.46,
      40.257,
      91.705,
      8.46,
      40.257,
      91.705,
      5.0,
      40.553,
      92.587,
      5.0
    ],
    "05-22 04:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12313",
    "polygon": [
      70.955,
      52.85,
      5.0,
      70.955,
      52.85,
      8.46,
      71.25,
      53.732,
      8.46,
      71.25,
      53.732,
      8.46,
      71.25,
      53.732,
      5.0,
      70.955,
      52.85,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12306",
    "polygon": [
      72.841,
      83.581,
      5.0,
      72.841,
      83.581,
      8.46,
      70.888,
      84.235,
      8.46,
      70.888,
      84.235,
      8.46,
      70.888,
      84.235,
      5.0,
      72.841,
      83.581,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12327",
    "polygon": [
      40.581,
      50.676,
      5.0,
      40.581,
      50.676,
      8.75,
      43.426,
      49.726,
      8.75,
      43.426,
      49.726,
      8.75,
      43.426,
      49.726,
      5.0,
      40.581,
      50.676,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12341",
    "polygon": [
      75.224,
      82.784,
      8.75,
      75.224,
      82.784,
      12.21,
      74.342,
      83.079,
      12.21,
      74.342,
      83.079,
      12.21,
      74.342,
      83.079,
      8.75,
      75.224,
      82.784,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12348",
    "polygon": [
      70.741,
      60.703,
      8.75,
      70.741,
      60.703,
      12.21,
      70.383,
      59.632,
      12.21,
      70.383,
      59.632,
      12.21,
      70.383,
      59.632,
      8.75,
      70.741,
      60.703,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12320",
    "polygon": [
      33.849,
      52.933,
      5.0,
      33.849,
      52.933,
      8.46,
      34.314,
      52.778,
      8.46,
      34.314,
      52.778,
      8.46,
      34.314,
      52.778,
      5.0,
      33.849,
      52.933,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12362",
    "polygon": [
      42.746,
      52.797,
      8.75,
      42.746,
      52.797,
      12.5,
      40.28,
      53.623,
      12.5,
      40.28,
      53.623,
      12.5,
      40.28,
      53.623,
      8.75,
      42.746,
      52.797,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12334",
    "polygon": [
      36.753,
      90.688,
      8.75,
      36.753,
      90.688,
      12.21,
      36.95,
      91.276,
      12.21,
      36.95,
      91.276,
      12.21,
      36.95,
      91.276,
      8.75,
      36.753,
      90.688,
      8.75
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12369",
    "polygon": [
      41.982,
      78.899,
      12.5,
      41.982,
      78.899,
      15.96,
      41.785,
      78.311,
      15.96,
      41.785,
      78.311,
      15.96,
      41.785,
      78.311,
      12.5,
      41.982,
      78.899,
      12.5
    ],
    "05-22 05:30": 1,
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12355",
    "polygon": [
      31.585,
      53.692,
      8.75,
      31.585,
      53.692,
      12.21,
      32.049,
      53.536,
      12.21,
      32.049,
      53.536,
      12.21,
      32.049,
      53.536,
      8.75,
      31.585,
      53.692,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12383",
    "polygon": [
      75.223,
      82.784,
      12.5,
      75.223,
      82.784,
      15.96,
      74.341,
      83.08,
      15.96,
      74.341,
      83.08,
      15.96,
      74.341,
      83.08,
      12.5,
      75.223,
      82.784,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12390",
    "polygon": [
      52.557,
      46.535,
      12.5,
      52.557,
      46.535,
      15.96,
      53.021,
      46.38,
      15.96,
      53.021,
      46.38,
      15.96,
      53.021,
      46.38,
      12.5,
      52.557,
      46.535,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12376",
    "polygon": [
      73.326,
      86.584,
      12.5,
      73.326,
      86.584,
      15.96,
      73.914,
      86.387,
      15.96,
      73.914,
      86.387,
      15.96,
      73.914,
      86.387,
      12.5,
      73.326,
      86.584,
      12.5
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 04:30": 1,
    "05-22 04:45": 1,
    "05-22 05:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12409",
    "polygon": [
      56.607,
      29.882,
      0.0,
      56.607,
      29.882,
      5.0,
      56.893,
      30.725,
      5.0,
      56.893,
      30.725,
      5.0,
      56.893,
      30.725,
      0.0,
      56.607,
      29.882,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12397",
    "polygon": [
      37.928,
      54.411,
      12.5,
      37.928,
      54.411,
      15.96,
      36.658,
      54.836,
      15.96,
      36.658,
      54.836,
      15.96,
      36.658,
      54.836,
      12.5,
      37.928,
      54.411,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12515",
    "polygon": [
      19.14,
      38.27,
      5.0,
      19.14,
      38.27,
      8.35,
      20.231,
      37.905,
      8.35,
      20.231,
      37.905,
      8.35,
      20.231,
      37.905,
      5.0,
      19.14,
      38.27,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12536",
    "polygon": [
      61.644,
      38.161,
      5.0,
      61.644,
      38.161,
      8.35,
      60.459,
      38.558,
      8.35,
      60.459,
      38.558,
      8.35,
      60.459,
      38.558,
      5.0,
      61.644,
      38.161,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12543",
    "polygon": [
      52.103,
      41.357,
      5.0,
      52.103,
      41.357,
      8.35,
      51.013,
      41.722,
      8.35,
      51.013,
      41.722,
      8.35,
      51.013,
      41.722,
      5.0,
      52.103,
      41.357,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12529",
    "polygon": [
      -12.364,
      48.828,
      5.0,
      -12.364,
      48.828,
      8.35,
      -11.178,
      48.431,
      8.35,
      -11.178,
      48.431,
      8.35,
      -11.178,
      48.431,
      5.0,
      -12.364,
      48.828,
      5.0
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12505",
    "polygon": [
      -24.114,
      5.287,
      5.0,
      -24.114,
      5.287,
      8.75,
      -24.189,
      5.065,
      8.75,
      -24.189,
      5.065,
      8.75,
      -24.189,
      5.065,
      5.0,
      -24.114,
      5.287,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12522",
    "polygon": [
      -4.623,
      46.235,
      5.0,
      -4.623,
      46.235,
      8.35,
      -3.533,
      45.87,
      8.35,
      -3.533,
      45.87,
      8.35,
      -3.533,
      45.87,
      5.0,
      -4.623,
      46.235,
      5.0
    ],
    "05-22 18:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12550",
    "polygon": [
      45.051,
      43.719,
      5.0,
      45.051,
      43.719,
      8.35,
      43.866,
      44.116,
      8.35,
      43.866,
      44.116,
      8.35,
      43.866,
      44.116,
      5.0,
      45.051,
      43.719,
      5.0
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12557",
    "polygon": [
      29.644,
      48.885,
      5.0,
      29.644,
      48.885,
      8.35,
      28.459,
      49.282,
      8.35,
      28.459,
      49.282,
      8.35,
      28.459,
      49.282,
      5.0,
      29.644,
      48.885,
      5.0
    ],
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12564",
    "polygon": [
      -9.373,
      62.064,
      5.0,
      -9.373,
      62.064,
      8.35,
      -9.895,
      62.239,
      8.35,
      -9.895,
      62.239,
      8.35,
      -9.895,
      62.239,
      5.0,
      -9.373,
      62.064,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12571",
    "polygon": [
      -24.085,
      66.992,
      5.0,
      -24.085,
      66.992,
      8.35,
      -24.749,
      67.215,
      8.35,
      -24.749,
      67.215,
      8.35,
      -24.749,
      67.215,
      5.0,
      -24.085,
      66.992,
      5.0
    ],
    "05-22 17:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12585",
    "polygon": [
      -37.843,
      71.601,
      5.0,
      -37.843,
      71.601,
      8.35,
      -38.934,
      71.966,
      8.35,
      -38.934,
      71.966,
      8.35,
      -38.934,
      71.966,
      5.0,
      -37.843,
      71.601,
      5.0
    ],
    "05-22 14:30": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12606",
    "polygon": [
      58.027,
      39.371,
      12.5,
      58.027,
      39.371,
      15.85,
      56.936,
      39.736,
      15.85,
      56.936,
      39.736,
      15.85,
      56.936,
      39.736,
      12.5,
      58.027,
      39.371,
      12.5
    ],
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12592",
    "polygon": [
      56.903,
      39.75,
      8.75,
      56.903,
      39.75,
      12.1,
      55.718,
      40.147,
      12.1,
      55.718,
      40.147,
      12.1,
      55.718,
      40.147,
      8.75,
      56.903,
      39.75,
      8.75
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12578",
    "polygon": [
      -30.779,
      69.235,
      5.0,
      -30.779,
      69.235,
      8.35,
      -31.869,
      69.6,
      8.35,
      -31.869,
      69.6,
      8.35,
      -31.869,
      69.6,
      5.0,
      -30.779,
      69.235,
      5.0
    ],
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12599",
    "polygon": [
      49.146,
      42.347,
      8.75,
      49.146,
      42.347,
      12.1,
      48.624,
      42.521,
      12.1,
      48.624,
      42.521,
      12.1,
      48.624,
      42.521,
      8.75,
      49.146,
      42.347,
      8.75
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12613",
    "polygon": [
      49.785,
      42.13,
      12.5,
      49.785,
      42.13,
      15.85,
      49.263,
      42.305,
      15.85,
      49.263,
      42.305,
      15.85,
      49.263,
      42.305,
      12.5,
      49.785,
      42.13,
      12.5
    ],
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:45": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12300",
    "polygon": [
      49.131,
      88.552,
      5.0,
      49.131,
      88.552,
      8.46,
      49.596,
      88.396,
      8.46,
      49.596,
      88.396,
      8.46,
      49.596,
      88.396,
      5.0,
      49.131,
      88.552,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12314",
    "polygon": [
      68.446,
      45.361,
      5.0,
      68.446,
      45.361,
      8.46,
      68.741,
      46.243,
      8.46,
      68.741,
      46.243,
      8.46,
      68.741,
      46.243,
      5.0,
      68.446,
      45.361,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12307",
    "polygon": [
      78.705,
      75.97,
      5.0,
      78.705,
      75.97,
      8.46,
      79.359,
      77.924,
      8.46,
      79.359,
      77.924,
      8.46,
      79.359,
      77.924,
      5.0,
      78.705,
      75.97,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12328",
    "polygon": [
      36.079,
      52.178,
      5.0,
      36.079,
      52.178,
      8.75,
      38.545,
      51.355,
      8.75,
      38.545,
      51.355,
      8.75,
      38.545,
      51.355,
      5.0,
      36.079,
      52.178,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12342",
    "polygon": [
      79.668,
      78.846,
      8.75,
      79.668,
      78.846,
      12.21,
      79.865,
      79.434,
      12.21,
      79.865,
      79.434,
      12.21,
      79.865,
      79.434,
      8.75,
      79.668,
      78.846,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12349",
    "polygon": [
      71.748,
      55.218,
      8.75,
      71.748,
      55.218,
      12.21,
      72.107,
      56.29,
      12.21,
      72.107,
      56.29,
      12.21,
      72.107,
      56.29,
      8.75,
      71.748,
      55.218,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12321",
    "polygon": [
      48.306,
      50.934,
      5.0,
      48.306,
      50.934,
      8.75,
      46.059,
      51.687,
      8.75,
      46.059,
      51.687,
      8.75,
      46.059,
      51.687,
      5.0,
      48.306,
      50.934,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12335",
    "polygon": [
      39.952,
      90.794,
      8.75,
      39.952,
      90.794,
      12.21,
      39.593,
      89.723,
      12.21,
      39.593,
      89.723,
      12.21,
      39.593,
      89.723,
      8.75,
      39.952,
      90.794,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12363",
    "polygon": [
      39.356,
      53.932,
      8.75,
      39.356,
      53.932,
      12.5,
      36.673,
      54.831,
      12.5,
      36.673,
      54.831,
      12.5,
      36.673,
      54.831,
      8.75,
      39.356,
      53.932,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12356",
    "polygon": [
      25.834,
      51.079,
      8.75,
      25.834,
      51.079,
      12.21,
      25.991,
      51.543,
      12.21,
      25.991,
      51.543,
      12.21,
      25.991,
      51.543,
      8.75,
      25.834,
      51.079,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12370",
    "polygon": [
      40.544,
      92.57,
      12.5,
      40.544,
      92.57,
      15.96,
      40.347,
      91.982,
      15.96,
      40.347,
      91.982,
      15.96,
      40.347,
      91.982,
      12.5,
      40.544,
      92.57,
      12.5
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12384",
    "polygon": [
      78.697,
      75.947,
      12.5,
      78.697,
      75.947,
      15.96,
      79.351,
      77.9,
      15.96,
      79.351,
      77.9,
      15.96,
      79.351,
      77.9,
      12.5,
      78.697,
      75.947,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12391",
    "polygon": [
      45.56,
      49.011,
      12.5,
      45.56,
      49.011,
      15.96,
      46.024,
      48.855,
      15.96,
      46.024,
      48.855,
      15.96,
      46.024,
      48.855,
      12.5,
      45.56,
      49.011,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12410",
    "polygon": [
      57.416,
      32.224,
      0.0,
      57.416,
      32.224,
      2.045,
      56.91,
      30.732,
      2.045,
      56.91,
      30.732,
      2.045,
      56.91,
      30.732,
      0.0,
      57.416,
      32.224,
      0.0
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12377",
    "polygon": [
      79.66,
      78.821,
      12.5,
      79.66,
      78.821,
      15.96,
      79.856,
      79.408,
      15.96,
      79.856,
      79.408,
      15.96,
      79.856,
      79.408,
      12.5,
      79.66,
      78.821,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12398",
    "polygon": [
      42.651,
      52.829,
      12.5,
      42.651,
      52.829,
      15.96,
      38.858,
      54.099,
      15.96,
      38.858,
      54.099,
      15.96,
      38.858,
      54.099,
      12.5,
      42.651,
      52.829,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12516",
    "polygon": [
      17.896,
      38.687,
      5.0,
      17.896,
      38.687,
      8.35,
      18.986,
      38.322,
      8.35,
      18.986,
      38.322,
      8.35,
      18.986,
      38.322,
      5.0,
      17.896,
      38.687,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12537",
    "polygon": [
      62.828,
      37.764,
      5.0,
      62.828,
      37.764,
      8.35,
      61.643,
      38.161,
      8.35,
      61.643,
      38.161,
      8.35,
      61.643,
      38.161,
      5.0,
      62.828,
      37.764,
      5.0
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12509",
    "polygon": [
      25.576,
      36.114,
      5.0,
      25.576,
      36.114,
      8.35,
      26.097,
      35.94,
      8.35,
      26.097,
      35.94,
      8.35,
      26.097,
      35.94,
      5.0,
      25.576,
      36.114,
      5.0
    ],
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12530",
    "polygon": [
      -13.548,
      49.225,
      5.0,
      -13.548,
      49.225,
      8.35,
      -12.363,
      48.828,
      8.35,
      -12.363,
      48.828,
      8.35,
      -12.363,
      48.828,
      5.0,
      -13.548,
      49.225,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12544",
    "polygon": [
      50.977,
      41.734,
      5.0,
      50.977,
      41.734,
      8.35,
      49.791,
      42.131,
      8.35,
      49.791,
      42.131,
      8.35,
      49.791,
      42.131,
      5.0,
      50.977,
      41.734,
      5.0
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12523",
    "polygon": [
      -5.845,
      46.644,
      5.0,
      -5.845,
      46.644,
      8.35,
      -4.66,
      46.247,
      8.35,
      -4.66,
      46.247,
      8.35,
      -4.66,
      46.247,
      5.0,
      -5.845,
      46.644,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12551",
    "polygon": [
      43.865,
      44.116,
      5.0,
      43.865,
      44.116,
      8.35,
      42.68,
      44.513,
      8.35,
      42.68,
      44.513,
      8.35,
      42.68,
      44.513,
      5.0,
      43.865,
      44.116,
      5.0
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12565",
    "polygon": [
      -10.013,
      62.278,
      5.0,
      -10.013,
      62.278,
      8.35,
      -10.535,
      62.453,
      8.35,
      -10.535,
      62.453,
      8.35,
      -10.535,
      62.453,
      5.0,
      -10.013,
      62.278,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12572",
    "polygon": [
      -24.77,
      67.222,
      5.0,
      -24.77,
      67.222,
      8.35,
      -25.955,
      67.619,
      8.35,
      -25.955,
      67.619,
      8.35,
      -25.955,
      67.619,
      5.0,
      -24.77,
      67.222,
      5.0
    ],
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12558",
    "polygon": [
      28.448,
      49.286,
      5.0,
      28.448,
      49.286,
      8.35,
      27.926,
      49.46,
      8.35,
      27.926,
      49.46,
      8.35,
      27.926,
      49.46,
      5.0,
      28.448,
      49.286,
      5.0
    ],
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12586",
    "polygon": [
      -39.052,
      72.006,
      5.0,
      -39.052,
      72.006,
      8.35,
      -40.143,
      72.371,
      8.35,
      -40.143,
      72.371,
      8.35,
      -40.143,
      72.371,
      5.0,
      -39.052,
      72.006,
      5.0
    ],
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12614",
    "polygon": [
      49.145,
      42.345,
      12.5,
      49.145,
      42.345,
      15.85,
      48.623,
      42.519,
      15.85,
      48.623,
      42.519,
      15.85,
      48.623,
      42.519,
      12.5,
      49.145,
      42.345,
      12.5
    ],
    "05-22 12:45": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12607",
    "polygon": [
      56.902,
      39.747,
      12.5,
      56.902,
      39.747,
      15.85,
      55.717,
      40.144,
      15.85,
      55.717,
      40.144,
      15.85,
      55.717,
      40.144,
      12.5,
      56.902,
      39.747,
      12.5
    ],
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12600",
    "polygon": [
      48.606,
      42.528,
      8.75,
      48.606,
      42.528,
      12.1,
      47.42,
      42.924,
      12.1,
      47.42,
      42.924,
      12.1,
      47.42,
      42.924,
      8.75,
      48.606,
      42.528,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12593",
    "polygon": [
      55.717,
      40.147,
      8.75,
      55.717,
      40.147,
      12.1,
      54.531,
      40.544,
      12.1,
      54.531,
      40.544,
      12.1,
      54.531,
      40.544,
      8.75,
      55.717,
      40.147,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12579",
    "polygon": [
      -31.882,
      69.604,
      5.0,
      -31.882,
      69.604,
      8.35,
      -33.068,
      70.001,
      8.35,
      -33.068,
      70.001,
      8.35,
      -33.068,
      70.001,
      5.0,
      -31.882,
      69.604,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12301",
    "polygon": [
      55.236,
      86.507,
      5.0,
      55.236,
      86.507,
      8.46,
      55.701,
      86.351,
      8.46,
      55.701,
      86.351,
      8.46,
      55.701,
      86.351,
      5.0,
      55.236,
      86.507,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12315",
    "polygon": [
      67.779,
      43.371,
      5.0,
      67.779,
      43.371,
      8.46,
      68.138,
      44.443,
      8.46,
      68.138,
      44.443,
      8.46,
      68.138,
      44.443,
      5.0,
      67.779,
      43.371,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12308",
    "polygon": [
      79.667,
      78.842,
      5.0,
      79.667,
      78.842,
      8.46,
      79.864,
      79.43,
      8.46,
      79.864,
      79.43,
      8.46,
      79.864,
      79.43,
      5.0,
      79.667,
      78.842,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12329",
    "polygon": [
      40.797,
      75.373,
      8.75,
      40.797,
      75.373,
      12.21,
      40.6,
      74.785,
      12.21,
      40.6,
      74.785,
      12.21,
      40.6,
      74.785,
      8.75,
      40.797,
      75.373,
      8.75
    ],
    "05-22 05:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12350",
    "polygon": [
      63.217,
      43.092,
      8.75,
      63.217,
      43.092,
      12.5,
      64.677,
      42.603,
      12.5,
      64.677,
      42.603,
      12.5,
      64.677,
      42.603,
      8.75,
      63.217,
      43.092,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12343",
    "polygon": [
      78.708,
      75.98,
      8.75,
      78.708,
      75.98,
      12.21,
      79.362,
      77.933,
      12.21,
      79.362,
      77.933,
      12.21,
      79.362,
      77.933,
      8.75,
      78.708,
      75.98,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12322",
    "polygon": [
      45.161,
      51.988,
      5.0,
      45.161,
      51.988,
      8.75,
      42.885,
      52.75,
      8.75,
      42.885,
      52.75,
      8.75,
      42.885,
      52.75,
      5.0,
      45.161,
      51.988,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12364",
    "polygon": [
      31.587,
      75.268,
      12.5,
      31.587,
      75.268,
      15.96,
      31.784,
      75.856,
      15.96,
      31.784,
      75.856,
      15.96,
      31.784,
      75.856,
      12.5,
      31.587,
      75.268,
      12.5
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12336",
    "polygon": [
      40.554,
      92.592,
      8.75,
      40.554,
      92.592,
      12.21,
      40.259,
      91.71,
      12.21,
      40.259,
      91.71,
      12.21,
      40.259,
      91.71,
      8.75,
      40.554,
      92.592,
      8.75
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12357",
    "polygon": [
      26.674,
      53.555,
      8.75,
      26.674,
      53.555,
      12.21,
      26.831,
      54.019,
      12.21,
      26.831,
      54.019,
      12.21,
      26.831,
      54.019,
      8.75,
      26.674,
      53.555,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12371",
    "polygon": [
      40.048,
      91.089,
      12.5,
      40.048,
      91.089,
      15.96,
      39.394,
      89.135,
      15.96,
      39.394,
      89.135,
      15.96,
      39.394,
      89.135,
      12.5,
      40.048,
      91.089,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12385",
    "polygon": [
      67.542,
      60.597,
      12.5,
      67.542,
      60.597,
      15.96,
      67.739,
      61.185,
      15.96,
      67.739,
      61.185,
      15.96,
      67.739,
      61.185,
      12.5,
      67.542,
      60.597,
      12.5
    ],
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1,
    "05-22 17:30": 1,
    "05-22 17:45": 1,
    "05-22 18:00": 1,
    "05-22 18:15": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12411",
    "polygon": [
      58.46,
      35.302,
      0.0,
      58.46,
      35.302,
      2.045,
      57.954,
      33.81,
      2.045,
      57.954,
      33.81,
      2.045,
      57.954,
      33.81,
      0.0,
      58.46,
      35.302,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12378",
    "polygon": [
      64.997,
      83.362,
      12.5,
      64.997,
      83.362,
      15.96,
      66.069,
      83.003,
      15.96,
      66.069,
      83.003,
      15.96,
      66.069,
      83.003,
      12.5,
      64.997,
      83.362,
      12.5
    ],
    "05-22 04:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12402",
    "polygon": [
      -42.332,
      69.438,
      0.0,
      -42.332,
      69.438,
      5.0,
      -42.618,
      68.595,
      5.0,
      -42.618,
      68.595,
      5.0,
      -42.618,
      68.595,
      0.0,
      -42.332,
      69.438,
      0.0
    ],
    "05-22 07:30": 1,
    "05-22 07:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12392",
    "polygon": [
      46.869,
      51.416,
      12.5,
      46.869,
      51.416,
      15.96,
      46.405,
      51.571,
      15.96,
      46.405,
      51.571,
      15.96,
      46.405,
      51.571,
      12.5,
      46.869,
      51.416,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12517",
    "polygon": [
      16.675,
      39.096,
      5.0,
      16.675,
      39.096,
      8.35,
      17.86,
      38.699,
      8.35,
      17.86,
      38.699,
      8.35,
      17.86,
      38.699,
      5.0,
      16.675,
      39.096,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12538",
    "polygon": [
      58.027,
      39.372,
      5.0,
      58.027,
      39.372,
      8.35,
      56.937,
      39.737,
      8.35,
      56.937,
      39.737,
      8.35,
      56.937,
      39.737,
      5.0,
      58.027,
      39.372,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12510",
    "polygon": [
      24.378,
      36.516,
      5.0,
      24.378,
      36.516,
      8.35,
      25.563,
      36.118,
      8.35,
      25.563,
      36.118,
      8.35,
      25.563,
      36.118,
      5.0,
      24.378,
      36.516,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12531",
    "polygon": [
      -14.734,
      49.622,
      5.0,
      -14.734,
      49.622,
      8.35,
      -13.549,
      49.225,
      8.35,
      -13.549,
      49.225,
      8.35,
      -13.549,
      49.225,
      5.0,
      -14.734,
      49.622,
      5.0
    ],
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12545",
    "polygon": [
      49.786,
      42.133,
      5.0,
      49.786,
      42.133,
      8.35,
      49.264,
      42.308,
      8.35,
      49.264,
      42.308,
      8.35,
      49.264,
      42.308,
      5.0,
      49.786,
      42.133,
      5.0
    ],
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12524",
    "polygon": [
      -6.97,
      47.021,
      5.0,
      -6.97,
      47.021,
      8.35,
      -5.88,
      46.656,
      8.35,
      -5.88,
      46.656,
      8.35,
      -5.88,
      46.656,
      5.0,
      -6.97,
      47.021,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12566",
    "polygon": [
      -10.547,
      62.457,
      5.0,
      -10.547,
      62.457,
      8.35,
      -11.732,
      62.854,
      8.35,
      -11.732,
      62.854,
      8.35,
      -11.732,
      62.854,
      5.0,
      -10.547,
      62.457,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12552",
    "polygon": [
      42.669,
      44.517,
      5.0,
      42.669,
      44.517,
      8.35,
      42.147,
      44.692,
      8.35,
      42.147,
      44.692,
      8.35,
      42.147,
      44.692,
      5.0,
      42.669,
      44.517,
      5.0
    ],
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12559",
    "polygon": [
      27.807,
      49.5,
      5.0,
      27.807,
      49.5,
      8.35,
      27.286,
      49.674,
      8.35,
      27.286,
      49.674,
      8.35,
      27.286,
      49.674,
      5.0,
      27.807,
      49.5,
      5.0
    ],
    "05-22 09:30": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12573",
    "polygon": [
      -25.956,
      67.619,
      5.0,
      -25.956,
      67.619,
      8.35,
      -27.141,
      68.016,
      8.35,
      -27.141,
      68.016,
      8.35,
      -27.141,
      68.016,
      5.0,
      -25.956,
      67.619,
      5.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12587",
    "polygon": [
      -40.179,
      72.384,
      5.0,
      -40.179,
      72.384,
      8.35,
      -41.365,
      72.781,
      8.35,
      -41.365,
      72.781,
      8.35,
      -41.365,
      72.781,
      5.0,
      -40.179,
      72.384,
      5.0
    ],
    "05-22 16:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12615",
    "polygon": [
      48.605,
      42.525,
      12.5,
      48.605,
      42.525,
      15.85,
      47.42,
      42.922,
      15.85,
      47.42,
      42.922,
      15.85,
      47.42,
      42.922,
      12.5,
      48.605,
      42.525,
      12.5
    ],
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12608",
    "polygon": [
      55.716,
      40.145,
      12.5,
      55.716,
      40.145,
      15.85,
      54.531,
      40.541,
      15.85,
      54.531,
      40.541,
      15.85,
      54.531,
      40.541,
      12.5,
      55.716,
      40.145,
      12.5
    ],
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12594",
    "polygon": [
      54.531,
      40.544,
      8.75,
      54.531,
      40.544,
      12.1,
      53.346,
      40.941,
      12.1,
      53.346,
      40.941,
      12.1,
      53.346,
      40.941,
      8.75,
      54.531,
      40.544,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12601",
    "polygon": [
      47.42,
      42.924,
      8.75,
      47.42,
      42.924,
      12.1,
      46.235,
      43.321,
      12.1,
      46.235,
      43.321,
      12.1,
      46.235,
      43.321,
      8.75,
      47.42,
      42.924,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_1.OG_12580",
    "polygon": [
      -33.078,
      70.005,
      5.0,
      -33.078,
      70.005,
      8.35,
      -33.6,
      70.18,
      8.35,
      -33.6,
      70.18,
      8.35,
      -33.6,
      70.18,
      5.0,
      -33.078,
      70.005,
      5.0
    ],
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12616",
    "polygon": [
      47.42,
      42.922,
      12.5,
      47.42,
      42.922,
      15.85,
      46.234,
      43.319,
      15.85,
      46.234,
      43.319,
      15.85,
      46.234,
      43.319,
      12.5,
      47.42,
      42.922,
      12.5
    ],
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12623",
    "polygon": [
      61.644,
      38.16,
      12.5,
      61.644,
      38.16,
      15.85,
      60.458,
      38.557,
      15.85,
      60.458,
      38.557,
      15.85,
      60.458,
      38.557,
      12.5,
      61.644,
      38.16,
      12.5
    ],
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12630",
    "polygon": [
      28.446,
      49.28,
      8.75,
      28.446,
      49.28,
      12.1,
      27.924,
      49.455,
      12.1,
      27.924,
      49.455,
      12.1,
      27.924,
      49.455,
      8.75,
      28.446,
      49.28,
      8.75
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12637",
    "polygon": [
      30.771,
      48.507,
      12.5,
      30.771,
      48.507,
      15.85,
      29.68,
      48.872,
      15.85,
      29.68,
      48.872,
      15.85,
      29.68,
      48.872,
      12.5,
      30.771,
      48.507,
      12.5
    ],
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12644",
    "polygon": [
      -8.211,
      61.561,
      8.75,
      -8.211,
      61.561,
      12.1,
      -9.396,
      61.958,
      12.1,
      -9.396,
      61.958,
      12.1,
      -9.396,
      61.958,
      8.75,
      -8.211,
      61.561,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12651",
    "polygon": [
      -15.334,
      63.947,
      8.75,
      -15.334,
      63.947,
      12.1,
      -15.855,
      64.121,
      12.1,
      -15.855,
      64.121,
      12.1,
      -15.855,
      64.121,
      8.75,
      -15.334,
      63.947,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12658",
    "polygon": [
      -13.007,
      63.179,
      12.5,
      -13.007,
      63.179,
      15.85,
      -14.098,
      63.544,
      15.85,
      -14.098,
      63.544,
      15.85,
      -14.098,
      63.544,
      12.5,
      -13.007,
      63.179,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12665",
    "polygon": [
      -27.826,
      68.132,
      8.75,
      -27.826,
      68.132,
      12.1,
      -28.348,
      68.307,
      12.1,
      -28.348,
      68.307,
      12.1,
      -28.348,
      68.307,
      8.75,
      -27.826,
      68.132,
      8.75
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12672",
    "polygon": [
      -34.287,
      70.296,
      8.75,
      -34.287,
      70.296,
      12.1,
      -35.472,
      70.693,
      12.1,
      -35.472,
      70.693,
      12.1,
      -35.472,
      70.693,
      8.75,
      -34.287,
      70.296,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1,
    "05-22 17:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12679",
    "polygon": [
      -27.186,
      67.918,
      12.5,
      -27.186,
      67.918,
      15.85,
      -27.708,
      68.093,
      15.85,
      -27.708,
      68.093,
      15.85,
      -27.708,
      68.093,
      12.5,
      -27.186,
      67.918,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12686",
    "polygon": [
      -33.753,
      70.117,
      12.5,
      -33.753,
      70.117,
      15.85,
      -34.274,
      70.292,
      15.85,
      -34.274,
      70.292,
      15.85,
      -34.274,
      70.292,
      12.5,
      -33.753,
      70.117,
      12.5
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:30": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12700",
    "polygon": [
      -9.394,
      47.852,
      12.5,
      -9.394,
      47.852,
      15.85,
      -8.207,
      47.459,
      15.85,
      -8.207,
      47.459,
      15.85,
      -8.207,
      47.459,
      12.5,
      -9.394,
      47.852,
      12.5
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12715",
    "polygon": [
      -11.141,
      48.408,
      8.75,
      -11.141,
      48.408,
      12.1,
      -10.667,
      48.249,
      12.1,
      -10.667,
      48.249,
      12.1,
      -10.667,
      48.249,
      8.75,
      -11.141,
      48.408,
      8.75
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12722",
    "polygon": [
      -18.886,
      51.002,
      8.75,
      -18.886,
      51.002,
      12.1,
      -17.701,
      50.605,
      12.1,
      -17.701,
      50.605,
      12.1,
      -17.701,
      50.605,
      8.75,
      -18.886,
      51.002,
      8.75
    ],
    "05-22 07:00": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12708",
    "polygon": [
      -16.448,
      50.193,
      12.5,
      -16.448,
      50.193,
      15.85,
      -15.926,
      50.02,
      15.85,
      -15.926,
      50.02,
      15.85,
      -15.926,
      50.02,
      12.5,
      -16.448,
      50.193,
      12.5
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12693",
    "polygon": [
      -41.399,
      72.679,
      12.5,
      -41.399,
      72.679,
      15.85,
      -42.584,
      73.076,
      15.85,
      -42.584,
      73.076,
      15.85,
      -42.584,
      73.076,
      12.5,
      -41.399,
      72.679,
      12.5
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12729",
    "polygon": [
      23.192,
      36.911,
      8.75,
      23.192,
      36.911,
      12.1,
      24.378,
      36.514,
      12.1,
      24.378,
      36.514,
      12.1,
      24.378,
      36.514,
      8.75,
      23.192,
      36.911,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12736",
    "polygon": [
      16.141,
      39.273,
      8.75,
      16.141,
      39.273,
      12.1,
      16.663,
      39.098,
      12.1,
      16.663,
      39.098,
      12.1,
      16.663,
      39.098,
      8.75,
      16.141,
      39.273,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12750",
    "polygon": [
      13.715,
      40.097,
      12.5,
      13.715,
      40.097,
      15.85,
      14.9,
      39.7,
      15.85,
      14.9,
      39.7,
      15.85,
      14.9,
      39.7,
      12.5,
      13.715,
      40.097,
      12.5
    ],
    "05-22 06:15": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12743",
    "polygon": [
      21.423,
      37.516,
      12.5,
      21.423,
      37.516,
      15.85,
      22.011,
      37.32,
      15.85,
      22.011,
      37.32,
      15.85,
      22.011,
      37.32,
      12.5,
      21.423,
      37.516,
      12.5
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12757",
    "polygon": [
      28.3,
      32.598,
      8.75,
      28.3,
      32.598,
      12.1,
      27.903,
      31.413,
      12.1,
      27.903,
      31.413,
      12.1,
      27.903,
      31.413,
      8.75,
      28.3,
      32.598,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12764",
    "polygon": [
      26.884,
      33.815,
      0.0,
      26.884,
      33.815,
      4.55,
      28.07,
      33.418,
      4.55,
      28.07,
      33.418,
      4.55,
      28.07,
      33.418,
      0.0,
      26.884,
      33.815,
      0.0
    ],
    "05-22 18:30": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12778",
    "polygon": [
      -21.147,
      46.463,
      0.0,
      -21.147,
      46.463,
      4.55,
      -20.75,
      47.648,
      4.55,
      -20.75,
      47.648,
      4.55,
      -20.75,
      47.648,
      0.0,
      -21.147,
      46.463,
      0.0
    ],
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12771",
    "polygon": [
      -15.683,
      48.076,
      0.0,
      -15.683,
      48.076,
      4.55,
      -14.501,
      47.669,
      4.55,
      -14.501,
      47.669,
      4.55,
      -14.501,
      47.669,
      0.0,
      -15.683,
      48.076,
      0.0
    ],
    "05-22 18:30": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12617",
    "polygon": [
      46.234,
      43.319,
      12.5,
      46.234,
      43.319,
      15.85,
      45.049,
      43.716,
      15.85,
      45.049,
      43.716,
      15.85,
      45.049,
      43.716,
      12.5,
      46.234,
      43.319,
      12.5
    ],
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12624",
    "polygon": [
      62.828,
      37.764,
      12.5,
      62.828,
      37.764,
      15.85,
      61.643,
      38.16,
      15.85,
      61.643,
      38.16,
      15.85,
      61.643,
      38.16,
      12.5,
      62.828,
      37.764,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12631",
    "polygon": [
      27.806,
      49.495,
      8.75,
      27.806,
      49.495,
      12.1,
      27.284,
      49.669,
      12.1,
      27.284,
      49.669,
      12.1,
      27.284,
      49.669,
      8.75,
      27.806,
      49.495,
      8.75
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12638",
    "polygon": [
      29.644,
      48.883,
      12.5,
      29.644,
      48.883,
      15.85,
      28.458,
      49.279,
      15.85,
      28.458,
      49.279,
      15.85,
      28.458,
      49.279,
      12.5,
      29.644,
      48.883,
      12.5
    ],
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12645",
    "polygon": [
      -9.407,
      61.961,
      8.75,
      -9.407,
      61.961,
      12.1,
      -9.929,
      62.136,
      12.1,
      -9.929,
      62.136,
      12.1,
      -9.929,
      62.136,
      8.75,
      -9.407,
      61.961,
      8.75
    ],
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12652",
    "polygon": [
      -7.081,
      61.194,
      12.5,
      -7.081,
      61.194,
      15.85,
      -8.171,
      61.559,
      15.85,
      -8.171,
      61.559,
      15.85,
      -8.171,
      61.559,
      12.5,
      -7.081,
      61.194,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12659",
    "polygon": [
      -14.134,
      63.557,
      12.5,
      -14.134,
      63.557,
      15.85,
      -15.32,
      63.954,
      15.85,
      -15.32,
      63.954,
      15.85,
      -15.32,
      63.954,
      12.5,
      -14.134,
      63.557,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12666",
    "polygon": [
      -28.36,
      68.311,
      8.75,
      -28.36,
      68.311,
      12.1,
      -29.545,
      68.708,
      12.1,
      -29.545,
      68.708,
      12.1,
      -29.545,
      68.708,
      8.75,
      -28.36,
      68.311,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12673",
    "polygon": [
      -35.472,
      70.693,
      8.75,
      -35.472,
      70.693,
      12.1,
      -36.658,
      71.09,
      12.1,
      -36.658,
      71.09,
      12.1,
      -36.658,
      71.09,
      8.75,
      -35.472,
      70.693,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1,
    "05-22 17:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12687",
    "polygon": [
      -34.287,
      70.297,
      12.5,
      -34.287,
      70.297,
      15.85,
      -35.472,
      70.694,
      15.85,
      -35.472,
      70.694,
      15.85,
      -35.472,
      70.694,
      12.5,
      -34.287,
      70.297,
      12.5
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12680",
    "polygon": [
      -27.826,
      68.132,
      12.5,
      -27.826,
      68.132,
      15.85,
      -28.348,
      68.307,
      15.85,
      -28.348,
      68.307,
      15.85,
      -28.348,
      68.307,
      12.5,
      -27.826,
      68.132,
      12.5
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:30": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12723",
    "polygon": [
      -20.031,
      51.385,
      8.75,
      -20.031,
      51.385,
      12.1,
      -18.94,
      51.02,
      12.1,
      -18.94,
      51.02,
      12.1,
      -18.94,
      51.02,
      8.75,
      -20.031,
      51.385,
      8.75
    ],
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12701",
    "polygon": [
      -10.54,
      48.233,
      12.5,
      -10.54,
      48.233,
      15.85,
      -9.449,
      47.871,
      15.85,
      -9.449,
      47.871,
      15.85,
      -9.449,
      47.871,
      12.5,
      -10.54,
      48.233,
      12.5
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12716",
    "polygon": [
      -12.367,
      48.818,
      8.75,
      -12.367,
      48.818,
      12.1,
      -11.182,
      48.421,
      12.1,
      -11.182,
      48.421,
      12.1,
      -11.182,
      48.421,
      8.75,
      -12.367,
      48.818,
      8.75
    ],
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12694",
    "polygon": [
      -42.584,
      73.076,
      12.5,
      -42.584,
      73.076,
      15.85,
      -43.769,
      73.473,
      15.85,
      -43.769,
      73.473,
      15.85,
      -43.769,
      73.473,
      12.5,
      -42.584,
      73.076,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12709",
    "polygon": [
      -4.627,
      46.225,
      8.75,
      -4.627,
      46.225,
      12.1,
      -3.536,
      45.86,
      12.1,
      -3.536,
      45.86,
      12.1,
      -3.536,
      45.86,
      8.75,
      -4.627,
      46.225,
      8.75
    ],
    "05-22 07:00": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12737",
    "polygon": [
      14.932,
      39.678,
      8.75,
      14.932,
      39.678,
      12.1,
      16.023,
      39.313,
      12.1,
      16.023,
      39.313,
      12.1,
      16.023,
      39.313,
      8.75,
      14.932,
      39.678,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12730",
    "polygon": [
      22.007,
      37.308,
      8.75,
      22.007,
      37.308,
      12.1,
      23.192,
      36.911,
      12.1,
      23.192,
      36.911,
      12.1,
      23.192,
      36.911,
      8.75,
      22.007,
      37.308,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12744",
    "polygon": [
      20.234,
      37.915,
      12.5,
      20.234,
      37.915,
      15.85,
      21.419,
      37.518,
      15.85,
      21.419,
      37.518,
      15.85,
      21.419,
      37.518,
      12.5,
      20.234,
      37.915,
      12.5
    ],
    "05-22 05:45": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12751",
    "polygon": [
      12.588,
      40.474,
      12.5,
      12.588,
      40.474,
      15.85,
      13.679,
      40.109,
      15.85,
      13.679,
      40.109,
      15.85,
      13.679,
      40.109,
      12.5,
      12.588,
      40.474,
      12.5
    ],
    "05-22 06:15": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12758",
    "polygon": [
      27.903,
      31.413,
      8.75,
      27.903,
      31.413,
      12.1,
      27.506,
      30.228,
      12.1,
      27.506,
      30.228,
      12.1,
      27.506,
      30.228,
      8.75,
      27.903,
      31.413,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12765",
    "polygon": [
      28.085,
      33.412,
      0.0,
      28.085,
      33.412,
      4.55,
      28.967,
      33.117,
      4.55,
      28.967,
      33.117,
      4.55,
      28.967,
      33.117,
      0.0,
      28.085,
      33.412,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12772",
    "polygon": [
      -16.865,
      48.483,
      0.0,
      -16.865,
      48.483,
      4.55,
      -15.683,
      48.076,
      4.55,
      -15.683,
      48.076,
      4.55,
      -15.683,
      48.076,
      0.0,
      -16.865,
      48.483,
      0.0
    ],
    "05-22 18:30": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12779",
    "polygon": [
      -21.417,
      45.657,
      0.0,
      -21.417,
      45.657,
      4.55,
      -21.147,
      46.463,
      4.55,
      -21.147,
      46.463,
      4.55,
      -21.147,
      46.463,
      0.0,
      -21.417,
      45.657,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12618",
    "polygon": [
      45.05,
      43.715,
      12.5,
      45.05,
      43.715,
      15.85,
      43.865,
      44.112,
      15.85,
      43.865,
      44.112,
      15.85,
      43.865,
      44.112,
      12.5,
      45.05,
      43.715,
      12.5
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12632",
    "polygon": [
      27.272,
      49.673,
      8.75,
      27.272,
      49.673,
      12.1,
      26.087,
      50.07,
      12.1,
      26.087,
      50.07,
      12.1,
      26.087,
      50.07,
      8.75,
      27.272,
      49.673,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12625",
    "polygon": [
      34.33,
      47.311,
      8.75,
      34.33,
      47.311,
      12.1,
      33.24,
      47.676,
      12.1,
      33.24,
      47.676,
      12.1,
      33.24,
      47.676,
      8.75,
      34.33,
      47.311,
      8.75
    ],
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12639",
    "polygon": [
      28.447,
      49.283,
      12.5,
      28.447,
      49.283,
      15.85,
      27.925,
      49.457,
      15.85,
      27.925,
      49.457,
      15.85,
      27.925,
      49.457,
      12.5,
      28.447,
      49.283,
      12.5
    ],
    "05-22 12:15": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12653",
    "polygon": [
      -8.207,
      61.571,
      12.5,
      -8.207,
      61.571,
      15.85,
      -9.392,
      61.968,
      15.85,
      -9.392,
      61.968,
      15.85,
      -9.392,
      61.968,
      12.5,
      -8.207,
      61.571,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12646",
    "polygon": [
      -10.047,
      62.176,
      8.75,
      -10.047,
      62.176,
      12.1,
      -10.569,
      62.351,
      12.1,
      -10.569,
      62.351,
      12.1,
      -10.569,
      62.351,
      8.75,
      -10.047,
      62.176,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12667",
    "polygon": [
      -29.604,
      68.728,
      8.75,
      -29.604,
      68.728,
      12.1,
      -30.695,
      69.093,
      12.1,
      -30.695,
      69.093,
      12.1,
      -30.695,
      69.093,
      8.75,
      -29.604,
      68.728,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12674",
    "polygon": [
      -36.658,
      71.09,
      8.75,
      -36.658,
      71.09,
      12.1,
      -37.843,
      71.488,
      12.1,
      -37.843,
      71.488,
      12.1,
      -37.843,
      71.488,
      8.75,
      -36.658,
      71.09,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12660",
    "polygon": [
      -15.33,
      63.957,
      12.5,
      -15.33,
      63.957,
      15.85,
      -15.852,
      64.132,
      15.85,
      -15.852,
      64.132,
      15.85,
      -15.852,
      64.132,
      12.5,
      -15.33,
      63.957,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 14:45": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12681",
    "polygon": [
      -28.36,
      68.311,
      12.5,
      -28.36,
      68.311,
      15.85,
      -29.545,
      68.708,
      15.85,
      -29.545,
      68.708,
      15.85,
      -29.545,
      68.708,
      12.5,
      -28.36,
      68.311,
      12.5
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12688",
    "polygon": [
      -35.472,
      70.694,
      12.5,
      -35.472,
      70.694,
      15.85,
      -36.658,
      71.091,
      15.85,
      -36.658,
      71.091,
      15.85,
      -36.658,
      71.091,
      12.5,
      -35.472,
      70.694,
      12.5
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12702",
    "polygon": [
      -11.133,
      48.43,
      12.5,
      -11.133,
      48.43,
      15.85,
      -10.659,
      48.272,
      15.85,
      -10.659,
      48.272,
      15.85,
      -10.659,
      48.272,
      12.5,
      -11.133,
      48.43,
      12.5
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12717",
    "polygon": [
      -13.551,
      49.215,
      8.75,
      -13.551,
      49.215,
      12.1,
      -12.366,
      48.818,
      12.1,
      -12.366,
      48.818,
      12.1,
      -12.366,
      48.818,
      8.75,
      -13.551,
      49.215,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12724",
    "polygon": [
      -20.399,
      50.285,
      8.75,
      -20.399,
      50.285,
      12.1,
      -20.034,
      51.375,
      12.1,
      -20.034,
      51.375,
      12.1,
      -20.034,
      51.375,
      8.75,
      -20.399,
      50.285,
      8.75
    ],
    "05-22 18:15": 1,
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12710",
    "polygon": [
      -5.848,
      46.635,
      8.75,
      -5.848,
      46.635,
      12.1,
      -4.663,
      46.237,
      12.1,
      -4.663,
      46.237,
      12.1,
      -4.663,
      46.237,
      8.75,
      -5.848,
      46.635,
      8.75
    ],
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12695",
    "polygon": [
      -43.769,
      73.473,
      12.5,
      -43.769,
      73.473,
      15.85,
      -44.291,
      73.648,
      15.85,
      -44.291,
      73.648,
      15.85,
      -44.291,
      73.648,
      12.5,
      -43.769,
      73.473,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12738",
    "polygon": [
      13.711,
      40.087,
      8.75,
      13.711,
      40.087,
      12.1,
      14.896,
      39.69,
      12.1,
      14.896,
      39.69,
      12.1,
      14.896,
      39.69,
      8.75,
      13.711,
      40.087,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12731",
    "polygon": [
      21.419,
      37.505,
      8.75,
      21.419,
      37.505,
      12.1,
      22.007,
      37.308,
      12.1,
      22.007,
      37.308,
      12.1,
      22.007,
      37.308,
      8.75,
      21.419,
      37.505,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12745",
    "polygon": [
      19.143,
      38.28,
      12.5,
      19.143,
      38.28,
      15.85,
      20.234,
      37.915,
      15.85,
      20.234,
      37.915,
      15.85,
      20.234,
      37.915,
      12.5,
      19.143,
      38.28,
      12.5
    ],
    "05-22 05:45": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12752",
    "polygon": [
      25.564,
      36.117,
      8.75,
      25.564,
      36.117,
      12.1,
      26.749,
      35.72,
      12.1,
      26.749,
      35.72,
      12.1,
      26.749,
      35.72,
      8.75,
      25.564,
      36.117,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12759",
    "polygon": [
      27.486,
      30.167,
      8.75,
      27.486,
      30.167,
      12.1,
      27.216,
      29.361,
      12.1,
      27.216,
      29.361,
      12.1,
      27.216,
      29.361,
      8.75,
      27.486,
      30.167,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12766",
    "polygon": [
      28.959,
      33.094,
      0.0,
      28.959,
      33.094,
      4.55,
      28.721,
      32.383,
      4.55,
      28.721,
      32.383,
      4.55,
      28.721,
      32.383,
      0.0,
      28.959,
      33.094,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12773",
    "polygon": [
      -18.047,
      48.89,
      0.0,
      -18.047,
      48.89,
      4.55,
      -16.865,
      48.483,
      4.55,
      -16.865,
      48.483,
      4.55,
      -16.865,
      48.483,
      0.0,
      -18.047,
      48.89,
      0.0
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12619",
    "polygon": [
      43.864,
      44.112,
      12.5,
      43.864,
      44.112,
      15.85,
      42.679,
      44.509,
      15.85,
      42.679,
      44.509,
      15.85,
      42.679,
      44.509,
      12.5,
      43.864,
      44.112,
      12.5
    ],
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12626",
    "polygon": [
      33.199,
      47.69,
      8.75,
      33.199,
      47.69,
      12.1,
      32.013,
      48.086,
      12.1,
      32.013,
      48.086,
      12.1,
      32.013,
      48.086,
      8.75,
      33.199,
      47.69,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12633",
    "polygon": [
      26.074,
      50.074,
      8.75,
      26.074,
      50.074,
      12.1,
      25.553,
      50.249,
      12.1,
      25.553,
      50.249,
      12.1,
      25.553,
      50.249,
      8.75,
      26.074,
      50.074,
      8.75
    ],
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12640",
    "polygon": [
      27.806,
      49.497,
      12.5,
      27.806,
      49.497,
      15.85,
      27.285,
      49.671,
      15.85,
      27.285,
      49.671,
      15.85,
      27.285,
      49.671,
      12.5,
      27.806,
      49.497,
      12.5
    ],
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12654",
    "polygon": [
      -9.404,
      61.972,
      12.5,
      -9.404,
      61.972,
      15.85,
      -9.925,
      62.147,
      15.85,
      -9.925,
      62.147,
      15.85,
      -9.925,
      62.147,
      12.5,
      -9.404,
      61.972,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:45": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12647",
    "polygon": [
      -10.581,
      62.355,
      8.75,
      -10.581,
      62.355,
      12.1,
      -11.766,
      62.752,
      12.1,
      -11.766,
      62.752,
      12.1,
      -11.766,
      62.752,
      8.75,
      -10.581,
      62.355,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12661",
    "polygon": [
      -24.12,
      66.89,
      8.75,
      -24.12,
      66.89,
      12.1,
      -24.783,
      67.113,
      12.1,
      -24.783,
      67.113,
      12.1,
      -24.783,
      67.113,
      8.75,
      -24.12,
      66.89,
      8.75
    ],
    "05-22 10:00": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12668",
    "polygon": [
      -30.813,
      69.133,
      8.75,
      -30.813,
      69.133,
      12.1,
      -31.904,
      69.498,
      12.1,
      -31.904,
      69.498,
      12.1,
      -31.904,
      69.498,
      8.75,
      -30.813,
      69.133,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12675",
    "polygon": [
      -37.877,
      71.499,
      8.75,
      -37.877,
      71.499,
      12.1,
      -38.968,
      71.864,
      12.1,
      -38.968,
      71.864,
      12.1,
      -38.968,
      71.864,
      8.75,
      -37.877,
      71.499,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 15:30": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12682",
    "polygon": [
      -29.604,
      68.728,
      12.5,
      -29.604,
      68.728,
      15.85,
      -30.695,
      69.093,
      15.85,
      -30.695,
      69.093,
      15.85,
      -30.695,
      69.093,
      12.5,
      -29.604,
      68.728,
      12.5
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12689",
    "polygon": [
      -36.658,
      71.091,
      12.5,
      -36.658,
      71.091,
      15.85,
      -37.843,
      71.488,
      15.85,
      -37.843,
      71.488,
      15.85,
      -37.843,
      71.488,
      12.5,
      -36.658,
      71.091,
      12.5
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12725",
    "polygon": [
      -20.829,
      49.003,
      8.75,
      -20.829,
      49.003,
      12.1,
      -20.432,
      50.188,
      12.1,
      -20.432,
      50.188,
      12.1,
      -20.432,
      50.188,
      8.75,
      -20.829,
      49.003,
      8.75
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12718",
    "polygon": [
      -14.737,
      49.612,
      8.75,
      -14.737,
      49.612,
      12.1,
      -13.552,
      49.215,
      12.1,
      -13.552,
      49.215,
      12.1,
      -13.552,
      49.215,
      8.75,
      -14.737,
      49.612,
      8.75
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12703",
    "polygon": [
      -12.361,
      48.837,
      12.5,
      -12.361,
      48.837,
      15.85,
      -11.174,
      48.443,
      15.85,
      -11.174,
      48.443,
      15.85,
      -11.174,
      48.443,
      12.5,
      -12.361,
      48.837,
      12.5
    ],
    "05-22 06:15": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12711",
    "polygon": [
      -6.974,
      47.012,
      8.75,
      -6.974,
      47.012,
      12.1,
      -5.883,
      46.646,
      12.1,
      -5.883,
      46.646,
      12.1,
      -5.883,
      46.646,
      8.75,
      -6.974,
      47.012,
      8.75
    ],
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12739",
    "polygon": [
      12.585,
      40.464,
      8.75,
      12.585,
      40.464,
      12.1,
      13.675,
      40.099,
      12.1,
      13.675,
      40.099,
      12.1,
      13.675,
      40.099,
      8.75,
      12.585,
      40.464,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12696",
    "polygon": [
      -4.613,
      46.266,
      12.5,
      -4.613,
      46.266,
      15.85,
      -3.521,
      45.904,
      15.85,
      -3.521,
      45.904,
      15.85,
      -3.521,
      45.904,
      12.5,
      -4.613,
      46.266,
      12.5
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12732",
    "polygon": [
      20.23,
      37.903,
      8.75,
      20.23,
      37.903,
      12.1,
      21.415,
      37.506,
      12.1,
      21.415,
      37.506,
      12.1,
      21.415,
      37.506,
      8.75,
      20.23,
      37.903,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12746",
    "polygon": [
      17.899,
      38.696,
      12.5,
      17.899,
      38.696,
      15.85,
      18.989,
      38.331,
      15.85,
      18.989,
      38.331,
      15.85,
      18.989,
      38.331,
      12.5,
      17.899,
      38.696,
      12.5
    ],
    "05-22 05:45": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12760",
    "polygon": [
      22.421,
      35.31,
      0.0,
      22.421,
      35.31,
      4.55,
      23.303,
      35.014,
      4.55,
      23.303,
      35.014,
      4.55,
      23.303,
      35.014,
      0.0,
      22.421,
      35.31,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12753",
    "polygon": [
      26.749,
      35.72,
      8.75,
      26.749,
      35.72,
      12.1,
      27.934,
      35.323,
      12.1,
      27.934,
      35.323,
      12.1,
      27.934,
      35.323,
      8.75,
      26.749,
      35.72,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12774",
    "polygon": [
      -19.229,
      49.297,
      0.0,
      -19.229,
      49.297,
      4.55,
      -18.047,
      48.89,
      4.55,
      -18.047,
      48.89,
      4.55,
      -18.047,
      48.89,
      0.0,
      -19.229,
      49.297,
      0.0
    ],
    "05-22 18:30": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12767",
    "polygon": [
      28.713,
      32.359,
      0.0,
      28.713,
      32.359,
      4.55,
      28.316,
      31.173,
      4.55,
      28.316,
      31.173,
      4.55,
      28.316,
      31.173,
      0.0,
      28.713,
      32.359,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12620",
    "polygon": [
      42.667,
      44.513,
      12.5,
      42.667,
      44.513,
      15.85,
      42.146,
      44.688,
      15.85,
      42.146,
      44.688,
      15.85,
      42.146,
      44.688,
      12.5,
      42.667,
      44.513,
      12.5
    ],
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:45": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12627",
    "polygon": [
      31.978,
      48.098,
      8.75,
      31.978,
      48.098,
      12.1,
      30.888,
      48.463,
      12.1,
      30.888,
      48.463,
      12.1,
      30.888,
      48.463,
      8.75,
      31.978,
      48.098,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12634",
    "polygon": [
      34.333,
      47.318,
      12.5,
      34.333,
      47.318,
      15.85,
      33.242,
      47.682,
      15.85,
      33.242,
      47.682,
      15.85,
      33.242,
      47.682,
      12.5,
      34.333,
      47.318,
      12.5
    ],
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12641",
    "polygon": [
      27.273,
      49.675,
      12.5,
      27.273,
      49.675,
      15.85,
      26.087,
      50.071,
      15.85,
      26.087,
      50.071,
      15.85,
      26.087,
      50.071,
      12.5,
      27.273,
      49.675,
      12.5
    ],
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12655",
    "polygon": [
      -10.044,
      62.186,
      12.5,
      -10.044,
      62.186,
      15.85,
      -10.565,
      62.361,
      15.85,
      -10.565,
      62.361,
      15.85,
      -10.565,
      62.361,
      12.5,
      -10.044,
      62.186,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:30": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12648",
    "polygon": [
      -11.802,
      62.763,
      8.75,
      -11.802,
      62.763,
      12.1,
      -12.892,
      63.129,
      12.1,
      -12.892,
      63.129,
      12.1,
      -12.892,
      63.129,
      8.75,
      -11.802,
      62.763,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12662",
    "polygon": [
      -24.804,
      67.12,
      8.75,
      -24.804,
      67.12,
      12.1,
      -25.989,
      67.517,
      12.1,
      -25.989,
      67.517,
      12.1,
      -25.989,
      67.517,
      8.75,
      -24.804,
      67.12,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12669",
    "polygon": [
      -31.917,
      69.502,
      8.75,
      -31.917,
      69.502,
      12.1,
      -33.102,
      69.899,
      12.1,
      -33.102,
      69.899,
      12.1,
      -33.102,
      69.899,
      8.75,
      -31.917,
      69.502,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12676",
    "polygon": [
      -24.119,
      66.891,
      12.5,
      -24.119,
      66.891,
      15.85,
      -24.783,
      67.113,
      15.85,
      -24.783,
      67.113,
      15.85,
      -24.783,
      67.113,
      12.5,
      -24.119,
      66.891,
      12.5
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:30": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12683",
    "polygon": [
      -30.813,
      69.133,
      12.5,
      -30.813,
      69.133,
      15.85,
      -31.904,
      69.498,
      15.85,
      -31.904,
      69.498,
      15.85,
      -31.904,
      69.498,
      12.5,
      -30.813,
      69.133,
      12.5
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12690",
    "polygon": [
      -37.877,
      71.499,
      12.5,
      -37.877,
      71.499,
      15.85,
      -38.968,
      71.864,
      15.85,
      -38.968,
      71.864,
      15.85,
      -38.968,
      71.864,
      12.5,
      -37.877,
      71.499,
      12.5
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12726",
    "polygon": [
      -21.21,
      47.865,
      8.75,
      -21.21,
      47.865,
      12.1,
      -20.813,
      49.05,
      12.1,
      -20.813,
      49.05,
      12.1,
      -20.813,
      49.05,
      8.75,
      -21.21,
      47.865,
      8.75
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12719",
    "polygon": [
      -15.923,
      50.009,
      8.75,
      -15.923,
      50.009,
      12.1,
      -14.737,
      49.612,
      12.1,
      -14.737,
      49.612,
      12.1,
      -14.737,
      49.612,
      8.75,
      -15.923,
      50.009,
      8.75
    ],
    "05-22 18:30": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12704",
    "polygon": [
      -13.546,
      49.23,
      12.5,
      -13.546,
      49.23,
      15.85,
      -12.36,
      48.836,
      15.85,
      -12.36,
      48.836,
      15.85,
      -12.36,
      48.836,
      12.5,
      -13.546,
      49.23,
      12.5
    ],
    "05-22 06:15": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12733",
    "polygon": [
      19.045,
      38.3,
      8.75,
      19.045,
      38.3,
      12.1,
      20.23,
      37.903,
      12.1,
      20.23,
      37.903,
      12.1,
      20.23,
      37.903,
      8.75,
      19.045,
      38.3,
      8.75
    ],
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12697",
    "polygon": [
      -5.835,
      46.672,
      12.5,
      -5.835,
      46.672,
      15.85,
      -4.649,
      46.278,
      15.85,
      -4.649,
      46.278,
      15.85,
      -4.649,
      46.278,
      12.5,
      -5.835,
      46.672,
      12.5
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12712",
    "polygon": [
      -8.182,
      47.417,
      8.75,
      -8.182,
      47.417,
      12.1,
      -7.092,
      47.051,
      12.1,
      -7.092,
      47.051,
      12.1,
      -7.092,
      47.051,
      8.75,
      -8.182,
      47.417,
      8.75
    ],
    "05-22 07:00": 1,
    "05-22 18:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12740",
    "polygon": [
      24.382,
      36.526,
      12.5,
      24.382,
      36.526,
      15.85,
      25.567,
      36.129,
      15.85,
      25.567,
      36.129,
      15.85,
      25.567,
      36.129,
      12.5,
      24.382,
      36.526,
      12.5
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12754",
    "polygon": [
      27.993,
      35.303,
      8.75,
      27.993,
      35.303,
      12.1,
      29.084,
      34.938,
      12.1,
      29.084,
      34.938,
      12.1,
      29.084,
      34.938,
      8.75,
      27.993,
      35.303,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12747",
    "polygon": [
      16.678,
      39.105,
      12.5,
      16.678,
      39.105,
      15.85,
      17.863,
      38.708,
      15.85,
      17.863,
      38.708,
      15.85,
      17.863,
      38.708,
      12.5,
      16.678,
      39.105,
      12.5
    ],
    "05-22 05:45": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12761",
    "polygon": [
      23.329,
      35.006,
      0.0,
      23.329,
      35.006,
      4.55,
      24.514,
      34.609,
      4.55,
      24.514,
      34.609,
      4.55,
      24.514,
      34.609,
      0.0,
      23.329,
      35.006,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12775",
    "polygon": [
      -20.108,
      49.6,
      0.0,
      -20.108,
      49.6,
      4.55,
      -19.229,
      49.297,
      4.55,
      -19.229,
      49.297,
      4.55,
      -19.229,
      49.297,
      0.0,
      -20.108,
      49.6,
      0.0
    ],
    "05-22 18:45": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12768",
    "polygon": [
      28.316,
      31.173,
      0.0,
      28.316,
      31.173,
      4.55,
      27.919,
      29.988,
      4.55,
      27.919,
      29.988,
      4.55,
      27.919,
      29.988,
      0.0,
      28.316,
      31.173,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12621",
    "polygon": [
      59.236,
      38.966,
      12.5,
      59.236,
      38.966,
      15.85,
      58.145,
      39.331,
      15.85,
      58.145,
      39.331,
      15.85,
      58.145,
      39.331,
      12.5,
      59.236,
      38.966,
      12.5
    ],
    "05-22 13:00": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12628",
    "polygon": [
      30.769,
      48.503,
      8.75,
      30.769,
      48.503,
      12.1,
      29.679,
      48.868,
      12.1,
      29.679,
      48.868,
      12.1,
      29.679,
      48.868,
      8.75,
      30.769,
      48.503,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12635",
    "polygon": [
      33.201,
      47.696,
      12.5,
      33.201,
      47.696,
      15.85,
      32.015,
      48.092,
      15.85,
      32.015,
      48.092,
      15.85,
      32.015,
      48.092,
      12.5,
      33.201,
      47.696,
      12.5
    ],
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12642",
    "polygon": [
      26.075,
      50.075,
      12.5,
      26.075,
      50.075,
      15.85,
      25.553,
      50.249,
      15.85,
      25.553,
      50.249,
      15.85,
      25.553,
      50.249,
      12.5,
      26.075,
      50.075,
      12.5
    ],
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:45": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12656",
    "polygon": [
      -10.578,
      62.365,
      12.5,
      -10.578,
      62.365,
      15.85,
      -11.763,
      62.762,
      15.85,
      -11.763,
      62.762,
      15.85,
      -11.763,
      62.762,
      12.5,
      -10.578,
      62.365,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12649",
    "polygon": [
      -13.011,
      63.168,
      8.75,
      -13.011,
      63.168,
      12.1,
      -14.101,
      63.534,
      12.1,
      -14.101,
      63.534,
      12.1,
      -14.101,
      63.534,
      8.75,
      -13.011,
      63.168,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12677",
    "polygon": [
      -24.804,
      67.12,
      12.5,
      -24.804,
      67.12,
      15.85,
      -25.989,
      67.517,
      15.85,
      -25.989,
      67.517,
      15.85,
      -25.989,
      67.517,
      12.5,
      -24.804,
      67.12,
      12.5
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:30": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12670",
    "polygon": [
      -33.113,
      69.903,
      8.75,
      -33.113,
      69.903,
      12.1,
      -33.634,
      70.078,
      12.1,
      -33.634,
      70.078,
      12.1,
      -33.634,
      70.078,
      8.75,
      -33.113,
      69.903,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12663",
    "polygon": [
      -25.99,
      67.517,
      8.75,
      -25.99,
      67.517,
      12.1,
      -27.176,
      67.914,
      12.1,
      -27.176,
      67.914,
      12.1,
      -27.176,
      67.914,
      8.75,
      -25.99,
      67.517,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12684",
    "polygon": [
      -31.917,
      69.502,
      12.5,
      -31.917,
      69.502,
      15.85,
      -33.102,
      69.899,
      15.85,
      -33.102,
      69.899,
      15.85,
      -33.102,
      69.899,
      12.5,
      -31.917,
      69.502,
      12.5
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12691",
    "polygon": [
      -39.086,
      71.904,
      12.5,
      -39.086,
      71.904,
      15.85,
      -40.177,
      72.269,
      15.85,
      -40.177,
      72.269,
      15.85,
      -40.177,
      72.269,
      12.5,
      -39.086,
      71.904,
      12.5
    ],
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12705",
    "polygon": [
      -14.733,
      49.624,
      12.5,
      -14.733,
      49.624,
      15.85,
      -13.547,
      49.23,
      15.85,
      -13.547,
      49.23,
      15.85,
      -13.547,
      49.23,
      12.5,
      -14.733,
      49.624,
      12.5
    ],
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12727",
    "polygon": [
      -21.607,
      46.679,
      8.75,
      -21.607,
      46.679,
      12.1,
      -21.21,
      47.864,
      12.1,
      -21.21,
      47.864,
      12.1,
      -21.21,
      47.864,
      8.75,
      -21.607,
      46.679,
      8.75
    ],
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1,
    "05-22 16:30": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12720",
    "polygon": [
      -16.451,
      50.186,
      8.75,
      -16.451,
      50.186,
      12.1,
      -15.929,
      50.012,
      12.1,
      -15.929,
      50.012,
      12.1,
      -15.929,
      50.012,
      8.75,
      -16.451,
      50.186,
      8.75
    ],
    "05-22 07:00": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12698",
    "polygon": [
      -6.962,
      47.046,
      12.5,
      -6.962,
      47.046,
      15.85,
      -5.87,
      46.684,
      15.85,
      -5.87,
      46.684,
      15.85,
      -5.87,
      46.684,
      12.5,
      -6.962,
      47.046,
      12.5
    ],
    "05-22 05:45": 1,
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12741",
    "polygon": [
      23.196,
      36.923,
      12.5,
      23.196,
      36.923,
      15.85,
      24.382,
      36.526,
      15.85,
      24.382,
      36.526,
      15.85,
      24.382,
      36.526,
      12.5,
      23.196,
      36.923,
      12.5
    ],
    "05-22 06:15": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12734",
    "polygon": [
      17.86,
      38.697,
      8.75,
      17.86,
      38.697,
      12.1,
      19.045,
      38.3,
      12.1,
      19.045,
      38.3,
      12.1,
      19.045,
      38.3,
      8.75,
      17.86,
      38.697,
      8.75
    ],
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12713",
    "polygon": [
      -9.403,
      47.825,
      8.75,
      -9.403,
      47.825,
      12.1,
      -8.218,
      47.428,
      12.1,
      -8.218,
      47.428,
      12.1,
      -8.218,
      47.428,
      8.75,
      -9.403,
      47.825,
      8.75
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12748",
    "polygon": [
      16.145,
      39.284,
      12.5,
      16.145,
      39.284,
      15.85,
      16.666,
      39.109,
      15.85,
      16.666,
      39.109,
      15.85,
      16.666,
      39.109,
      12.5,
      16.145,
      39.284,
      12.5
    ],
    "05-22 06:00": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12762",
    "polygon": [
      24.514,
      34.609,
      0.0,
      24.514,
      34.609,
      4.55,
      25.699,
      34.212,
      4.55,
      25.699,
      34.212,
      4.55,
      25.699,
      34.212,
      0.0,
      24.514,
      34.609,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12755",
    "polygon": [
      29.079,
      34.923,
      8.75,
      29.079,
      34.923,
      12.1,
      28.713,
      33.833,
      12.1,
      28.713,
      33.833,
      12.1,
      28.713,
      33.833,
      8.75,
      29.079,
      34.923,
      8.75
    ],
    "05-22 05:15": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12776",
    "polygon": [
      -20.352,
      48.838,
      0.0,
      -20.352,
      48.838,
      4.55,
      -20.097,
      49.596,
      4.55,
      -20.097,
      49.596,
      4.55,
      -20.097,
      49.596,
      0.0,
      -20.352,
      48.838,
      0.0
    ],
    "05-22 18:30": 1
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12769",
    "polygon": [
      27.919,
      29.988,
      0.0,
      27.919,
      29.988,
      4.55,
      27.649,
      29.182,
      4.55,
      27.649,
      29.182,
      4.55,
      27.649,
      29.182,
      0.0,
      27.919,
      29.988,
      0.0
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12622",
    "polygon": [
      60.458,
      38.557,
      12.5,
      60.458,
      38.557,
      15.85,
      59.273,
      38.954,
      15.85,
      59.273,
      38.954,
      15.85,
      59.273,
      38.954,
      12.5,
      60.458,
      38.557,
      12.5
    ],
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 14:45": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12629",
    "polygon": [
      29.643,
      48.88,
      8.75,
      29.643,
      48.88,
      12.1,
      28.457,
      49.277,
      12.1,
      28.457,
      49.277,
      12.1,
      28.457,
      49.277,
      8.75,
      29.643,
      48.88,
      8.75
    ],
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 12:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12643",
    "polygon": [
      -7.084,
      61.183,
      8.75,
      -7.084,
      61.183,
      12.1,
      -8.175,
      61.549,
      12.1,
      -8.175,
      61.549,
      12.1,
      -8.175,
      61.549,
      8.75,
      -7.084,
      61.183,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12636",
    "polygon": [
      31.98,
      48.104,
      12.5,
      31.98,
      48.104,
      15.85,
      30.889,
      48.468,
      15.85,
      30.889,
      48.468,
      15.85,
      30.889,
      48.468,
      12.5,
      31.98,
      48.104,
      12.5
    ],
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:45": 1,
    "05-22 14:15": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12657",
    "polygon": [
      -11.798,
      62.774,
      12.5,
      -11.798,
      62.774,
      15.85,
      -12.889,
      63.139,
      15.85,
      -12.889,
      63.139,
      15.85,
      -12.889,
      63.139,
      12.5,
      -11.798,
      62.774,
      12.5
    ],
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:00": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1,
    "05-22 15:45": 1,
    "05-22 16:00": 1,
    "05-22 16:30": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12650",
    "polygon": [
      -14.138,
      63.546,
      8.75,
      -14.138,
      63.546,
      12.1,
      -15.323,
      63.943,
      12.1,
      -15.323,
      63.943,
      12.1,
      -15.323,
      63.943,
      8.75,
      -14.138,
      63.546,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12671",
    "polygon": [
      -33.753,
      70.117,
      8.75,
      -33.753,
      70.117,
      12.1,
      -34.274,
      70.292,
      12.1,
      -34.274,
      70.292,
      12.1,
      -34.274,
      70.292,
      8.75,
      -33.753,
      70.117,
      8.75
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 16:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12664",
    "polygon": [
      -27.186,
      67.918,
      8.75,
      -27.186,
      67.918,
      12.1,
      -27.708,
      68.092,
      12.1,
      -27.708,
      68.092,
      12.1,
      -27.708,
      68.092,
      8.75,
      -27.186,
      67.918,
      8.75
    ],
    "05-22 09:30": 1,
    "05-22 10:00": 1,
    "05-22 10:15": 1,
    "05-22 10:30": 1,
    "05-22 10:45": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12678",
    "polygon": [
      -25.99,
      67.517,
      12.5,
      -25.99,
      67.517,
      15.85,
      -27.176,
      67.914,
      15.85,
      -27.176,
      67.914,
      15.85,
      -27.176,
      67.914,
      12.5,
      -25.99,
      67.517,
      12.5
    ],
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1,
    "05-22 13:00": 1,
    "05-22 13:15": 1,
    "05-22 13:30": 1,
    "05-22 13:45": 1,
    "05-22 14:00": 1,
    "05-22 14:30": 1,
    "05-22 15:00": 1,
    "05-22 15:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12685",
    "polygon": [
      -33.113,
      69.903,
      12.5,
      -33.113,
      69.903,
      15.85,
      -33.634,
      70.078,
      15.85,
      -33.634,
      70.078,
      15.85,
      -33.634,
      70.078,
      12.5,
      -33.113,
      69.903,
      12.5
    ],
    "05-22 10:30": 1,
    "05-22 11:00": 1,
    "05-22 15:15": 1,
    "05-22 15:30": 1,
    "05-22 16:00": 1,
    "05-22 16:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12692",
    "polygon": [
      -40.213,
      72.282,
      12.5,
      -40.213,
      72.282,
      15.85,
      -41.399,
      72.679,
      15.85,
      -41.399,
      72.679,
      15.85,
      -41.399,
      72.679,
      12.5,
      -40.213,
      72.282,
      12.5
    ],
    "05-22 10:30": 1,
    "05-22 10:45": 1,
    "05-22 11:00": 1,
    "05-22 11:15": 1,
    "05-22 11:30": 1,
    "05-22 11:45": 1,
    "05-22 12:15": 1,
    "05-22 12:30": 1,
    "05-22 12:45": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12728",
    "polygon": [
      24.378,
      36.514,
      8.75,
      24.378,
      36.514,
      12.1,
      25.563,
      36.117,
      12.1,
      25.563,
      36.117,
      12.1,
      25.563,
      36.117,
      8.75,
      24.378,
      36.514,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12706",
    "polygon": [
      -15.92,
      50.018,
      12.5,
      -15.92,
      50.018,
      15.85,
      -14.733,
      49.624,
      15.85,
      -14.733,
      49.624,
      15.85,
      -14.733,
      49.624,
      12.5,
      -15.92,
      50.018,
      12.5
    ],
    "05-22 06:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12721",
    "polygon": [
      -17.66,
      50.591,
      8.75,
      -17.66,
      50.591,
      12.1,
      -16.569,
      50.226,
      12.1,
      -16.569,
      50.226,
      12.1,
      -16.569,
      50.226,
      8.75,
      -17.66,
      50.591,
      8.75
    ],
    "05-22 07:00": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12714",
    "polygon": [
      -10.548,
      48.209,
      8.75,
      -10.548,
      48.209,
      12.1,
      -9.458,
      47.844,
      12.1,
      -9.458,
      47.844,
      12.1,
      -9.458,
      47.844,
      8.75,
      -10.548,
      48.209,
      8.75
    ],
    "05-22 07:00": 1,
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12699",
    "polygon": [
      -8.172,
      47.447,
      12.5,
      -8.172,
      47.447,
      15.85,
      -7.081,
      47.085,
      15.85,
      -7.081,
      47.085,
      15.85,
      -7.081,
      47.085,
      12.5,
      -8.172,
      47.447,
      12.5
    ],
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12742",
    "polygon": [
      22.011,
      37.32,
      12.5,
      22.011,
      37.32,
      15.85,
      23.196,
      36.923,
      15.85,
      23.196,
      36.923,
      15.85,
      23.196,
      36.923,
      12.5,
      22.011,
      37.32,
      12.5
    ],
    "05-22 07:00": 1,
    "05-22 07:15": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12735",
    "polygon": [
      16.674,
      39.095,
      8.75,
      16.674,
      39.095,
      12.1,
      17.86,
      38.697,
      12.1,
      17.86,
      38.697,
      12.1,
      17.86,
      38.697,
      8.75,
      16.674,
      39.095,
      8.75
    ],
    "05-22 07:00": 1
  },
  {
    "ID": "EDEKA-Interiors_3.OG_12749",
    "polygon": [
      14.936,
      39.688,
      12.5,
      14.936,
      39.688,
      15.85,
      16.026,
      39.323,
      15.85,
      16.026,
      39.323,
      15.85,
      16.026,
      39.323,
      12.5,
      14.936,
      39.688,
      12.5
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12763",
    "polygon": [
      25.698,
      34.212,
      0.0,
      25.698,
      34.212,
      4.55,
      26.883,
      33.815,
      4.55,
      26.883,
      33.815,
      4.55,
      26.883,
      33.815,
      0.0,
      25.698,
      34.212,
      0.0
    ],
    "05-22 18:30": 1
  },
  {
    "ID": "EDEKA-Interiors_2.OG_12756",
    "polygon": [
      28.697,
      33.783,
      8.75,
      28.697,
      33.783,
      12.1,
      28.3,
      32.597,
      12.1,
      28.3,
      32.597,
      12.1,
      28.3,
      32.597,
      8.75,
      28.697,
      33.783,
      8.75
    ]
  },
  {
    "ID": "EDEKA-Interiors_EG(A&B)_12770",
    "polygon": [
      -14.476,
      47.661,
      0.0,
      -14.476,
      47.661,
      4.55,
      -13.597,
      47.358,
      4.55,
      -13.597,
      47.358,
      4.55,
      -13.597,
      47.358,
      0.0,
      -14.476,
      47.661,
      0.0
    ],
    "05-22 18:45": 1,
    "05-22 19:00": 1
  },
  {
    "ID": "Block-A-Outer_1.OG_9975",
    "polygon": [
      6.564,
      87.172,
      5.573,
      6.564,
      87.172,
      8.023,
      6.326,
      86.461,
      8.023,
      6.326,
      86.461,
      8.023,
      6.326,
      86.461,
      5.573,
      6.564,
      87.172,
      5.573
    ],
    "05-22 03:45": 1,
    "05-22 04:00": 1,
    "05-22 04:15": 1,
    "05-22 06:15": 1,
    "05-22 06:30": 1,
    "05-22 06:45": 1,
    "05-22 07:00": 1,
    "05-22 07:15": 1,
    "05-22 07:30": 1,
    "05-22 07:45": 1,
    "05-22 08:00": 1,
    "05-22 08:15": 1,
    "05-22 08:30": 1,
    "05-22 08:45": 1,
    "05-22 09:00": 1,
    "05-22 09:15": 1,
    "05-22 09:30": 1,
    "05-22 09:45": 1,
    "05-22 10:00": 1
  }
]
export default project3ShadingProgression;
