import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./VirtualSensorsInfoBox.css";
import { formatNumber } from "../utils";
import ConfigurationLayout from "./ConfigurationLayout";
import { disappearSensorsVirtualBox } from "../../redux/solarSensors";

const labelMapping = {
  sensorName: "Sensor Name",
  sunshineHours: "Sunshine Hours",
  directEnergyValue: "Direct Energy Value",
  diffuseEnergyValue: "Diffuse Energy Value",
  globalEnergyValue: "Global Energy Value",
  coordinateX: "X Coordinate",
  coordinateY: "Y Coordinate",
  coordinateZ: "Z Coordinate",
};

const unitMapping = {
  sunshineHours: "hours",
  directEnergyValue: "kWh/m²a",
  diffuseEnergyValue: "kWh/m²a",
  globalEnergyValue: "kWh/m²a",
};

const VirtualSensorsInfoBox = () => {
  const dispatch = useDispatch()
  const solarSensors = useSelector(
    (state) => state.sensorSolars.value.checkStatus
  );

  const visible = useSelector((state) => state.sensorSolars.showSensorInfoBox);
  const sensorInfo = useSelector((state) => state.sensorSolars.sensorsData);

  const shouldRenderSensorInfoBox = solarSensors
    .slice(0, 4)
    .some((isActive) => isActive);

  const onClickOutside = () => {
    dispatch(disappearSensorsVirtualBox());
  }

  return (
    <>
      {visible && shouldRenderSensorInfoBox && (
        <ConfigurationLayout title={`Sensor Information:`} onClickOutside={onClickOutside}
          style={{ height: '600px' }}>

          <div className="sensor-info-box" >
            {Object.entries(sensorInfo).map(([key, value]) => (
              <div key={key} className="sensor-info-item">
                <span className="sensor-info-label">{labelMapping[key]}:</span>{" "}
                <span className="sensor-info-value">
                  {key.includes("Energy") | key.includes("Hours")
                    ? formatNumber(value)
                    : value}{" "}
                  {unitMapping[key] ? (
                    <span className="unit">{unitMapping[key]}</span>
                  ) : (
                    ""
                  )}
                </span>
              </div>
            ))}
          </div>
        </ConfigurationLayout>
      )}
    </>
  );
};

export default VirtualSensorsInfoBox;
