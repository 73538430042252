import { OrbitControls, PerformanceMonitor } from "@react-three/drei";
import { Canvas, useThree } from "@react-three/fiber";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import "semantic-ui-css/semantic.min.css";
import "../App.css";
import Loader from "./Loader";
import Ceilings from "./threeDComponent/Ceilings";
import CompassValue from "./threeDComponent/CompassValue";
import DaylightFactor from "./threeDComponent/DaylightFactor";
import Doors from "./threeDComponent/Doors";
import ExternalWalls from "./threeDComponent/ExternalWalls";
import InternalWalls from "./threeDComponent/InternalWalls";
import PVData from "./threeDComponent/PVData";
import PVPanels from "./threeDComponent/PVPanels";
import Rooftop from "./threeDComponent/Rooftop";
import RoomEnergyData from "./threeDComponent/RoomEnergyData";
import Rooms from "./threeDComponent/Rooms";
import SceneGLTF from "./threeDComponent/SceneGLTF";
import SensorPlacement from "./threeDComponent/SensorPlacement";
import ShadingProgression from "./threeDComponent/ShadingProgression";
import SolarSensors from "./threeDComponent/SolarSensors";
import Stairs from "./threeDComponent/Stairs";
import Sunlight from "./threeDComponent/Sunlight";
import WindDirection from "./threeDComponent/WindDirection";
import Window from "./threeDComponent/Window";
import WindRiskData from "./threeDComponent/WindRiskData";
import WindTurbine from "./threeDComponent/WindTurbine";
import { saveAs } from "file-saver";
import { GiPhotoCamera } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { resetCameraPosition, updateCamera } from "../redux/sceneGlobal";
import { subscribe, unsubscribe, windowEvents } from "./events";


function Lights() {
  const params = {
    color: "#fff",
    sunColor: "#fff",
    bounceColor: "#fff",
    ambientColor: "#fff",
    shine: 10,
    cubeColor: 0,
  };

  const ambientRef = useRef();
  const directionalRef = useRef();
  const pointRef = useRef();
  const spotRef = useRef();

  return (
    <>
      <ambientLight ref={ambientRef} />
      <directionalLight
        // castShadow
        // shadow-camera-left={-20}
        // castShadow
        ref={directionalRef}
        color={params.sunColor}
        intensity={0.5}
        position={[-50, 30, 50]}
      />
    </>
  );
}

const Camera = ({ target }) => {
  const cameraPosition = useSelector(store => store.sceneVar.cameraPosition)
  return (
    <OrbitControls
      target={target || cameraPosition}
      enableDamping={true}
      dampingFactor={0.1}
    />
  )
}

const CameraController = () => {
  const dispatch = useDispatch()
  const { camera } = useThree();
  const orbitRef = useRef()
  const { cameraPosition, cameraTarget, defaultCameraPosition } = useSelector(store => store.sceneVar)

  useEffect(() => {
    const handleReset = () => {
      orbitRef.current.reset();
      camera.position.set(...defaultCameraPosition);
    };

    subscribe(windowEvents.RESET_CAMERA, handleReset)
    return () => {
      unsubscribe(windowEvents.RESET_CAMERA, handleReset)
    };
  }, []);

  useEffect(() => {
    if (cameraPosition) {
      camera.position.set(...cameraPosition);
    }
  }, [camera, cameraPosition]);

  return <OrbitControls
    target={cameraTarget}
    enableDamping={true}
    dampingFactor={0.1}
    ref={orbitRef}
  />
};

// const ScreenShotTakerTool = forwardRef((props, ref) => {
//   const { gl, scene, camera } = useThree();

//   const captureCanvas = () => {

//     try {
//       console.log(gl?.getPixelRatio())
//       gl.setClearColor('white');
//       gl.setPixelRatio(4);
//       gl.render(scene, camera);
//       const dataURL = gl?.domElement?.toDataURL('image/png');
//       saveAs(dataURL, 'canvas-screenshot.png');

//     } catch {
//     }
//     finally {
//       gl.setPixelRatio(1.5);
//       //Scroll the window "a little bit" so that scene doesn't freeze (and hence need for a click, scroll,...)
//       //It happens in case that image is huge due to high image quality
//       window.scrollTo(20 * Math.random(), 20 * Math.random())
//     }
//   };

//   useImperativeHandle(ref, () => ({
//     takeScreenShot: captureCanvas,
//   }));
// })

const Convas = () => {
  const [dpr, setDpr] = useState(1.5);
  const screenShotRef = useRef()
  return (
    <>
      <Loader />
      {/* <div style={{ width: '60px' }}>

        <button
          style={{
            top: "6px",
            right: '6px',
            position: 'fixed',
            border: "1px solid #ddd",
            backgroundColor: "#fff",
            borderRadius: "7px",
            cursor: "auto",
            opacity: 0
          }}
          onClick={() => { screenShotRef?.current?.takeScreenShot() }}
        >
          <GiPhotoCamera style={{
            width: "36px",
            height: "36px",
            padding: 4
          }} />
        </button>
      </div> */}
      <Canvas
        frameloop="demand"
        name="mainscene"
        camera={{ up: [0, 1, 0] }}
        shadows
        shadowMap
        dpr={dpr}
        style={{ position: "absolute", zIndex: 1 }}
        gl={{
          antialias: true,
          preserveDrawingBuffer: true,
        }}
      >
        {/* <ScreenShotTakerTool
          ref={screenShotRef}
        /> */}

        <CameraController />
        {/* <group
          rotation-x={Math.PI * -0.5}
        >       <mesh position={[10, 60, 70]} onClick={() => b([30, 20, 70])}>
            <sphereBufferGeometry args={[4]} />
            <meshStandardMaterial color="black" />
          </mesh>
        </group>
        <group
          rotation-x={Math.PI * -0.5}
        >
          <mesh position={[400, -460, 65]} onClick={() => b([545.45, 65.45, 600])}>
            <sphereBufferGeometry args={[4]} />
            <meshStandardMaterial color="black" />
          </mesh>
        </group> */}

        <PerformanceMonitor
          flipflops={3}
          onFallback={() => setDpr(1)}
          onIncline={() => setDpr(2)}
          onDecline={() => setDpr(1)}
        ></PerformanceMonitor>
        <ambientLight intensity={1} />
        <directionalLight position={[10, 10, 5]} intensity={3} />
        <directionalLight position={[-10, -10, -5]} intensity={3} />
        <Ceilings />
        <PVPanels />
        <InternalWalls />
        <ExternalWalls />
        <Window />
        <SceneGLTF />
        <Rooftop />
        <SolarSensors />
        <Rooms />
        <Doors />
        <Stairs />

        <WindDirection />
        <WindRiskData />
        <SensorPlacement />
        <CompassValue />
        <RoomEnergyData />

        <PVData />
        <ShadingProgression />
        <Sunlight />
        <DaylightFactor />

        <WindTurbine />
        {/* <Stats showPanel="{2}" /> */}
      </Canvas>
    </>
  );
}

export default React.memo(Convas);
