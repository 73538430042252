const ChartPaper = ({ children, style, className }) => {
    return (
        <div className={className} style={{
            border: '1px solid #ddd',
            padding: '8px',
            borderRadius: '7px',
            ...style
        }}>
            {children}
        </div>
    )
}

export default ChartPaper