import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./configuration.css";
// import "./UploadModal.css";
import {
  roomConfigurationStateChanger
} from "../../redux/roomData";
import { useIsAnyColorBarVisible } from "../CustomHooks";
import ClickOutsideHandler from "../utility/ClickOutsideHandler";


const ConfigurationLayout = ({ children, title, onClickOutside, style }) => {
  // const roomConfigurationState = useSelector(
  //   (store) => store.roomData.roomConfigurationState
  // );

  const isAnyColorBarVisible = useIsAnyColorBarVisible();

  return (
    <ClickOutsideHandler handleClickOutside={onClickOutside}>
      <div
        className="room_configuration__infobox"
        style={{
          // display: `${roomConfigurationState ? "block" : "none"}`,
          right: isAnyColorBarVisible ? "134px" : "8px",
          ...style
        }}
      >
        <h4
          style={{
            backgroundColor: "#2459ea",
            color: "white",
            padding: "8px",
            borderRadius: 5,
            margin: "8px auto",
            width: "80%",
            textAlign: "center",
          }}
        >
          {title}
        </h4>
        {children}
      </div>
    </ClickOutsideHandler>
  );
};



export default ConfigurationLayout;
