import { BrowserRouter, Route, Routes } from "react-router-dom";
import React from "react";
import Project from "./components/Project";
import FAQData from "./components/FAQData";
import { theme } from "../src/styles/theme";
import { ThemeProvider } from "@mui/material/styles";

const App = () => (
  <ThemeProvider theme={theme}>
    <BrowserRouter>
      <Routes>
        {/* <Route path="projects/:id" element={<Project />} /> */}
        <Route path="projects" element={<Project />} />
        <Route path="/faq" element={<FAQData />} />
      </Routes>
    </BrowserRouter>
  </ThemeProvider>
);

export default App;
