import { createSlice } from "@reduxjs/toolkit";
import infoData from "../components/infoData";
const initialState = {
  rotationValue: null,
};

const compass = createSlice({
  name: "compass",
  initialState,
  reducers: {
    setCompassRotationValue: (state, action) => {
      state.rotationValue = action.payload;
    },
  },
});

export const { setCompassRotationValue } = compass.actions;

export default compass.reducer;
