import { useSelector, useDispatch } from "react-redux";
import "./configuration.css";
import React, { useRef, useEffect, useState } from "react";
// import "./UploadModal.css";
import axios from "axios";
import { toggleWindowIsShowing, updateWindowPosition } from "../../redux/windowConfiguration";
import ConfigurationLayout from "./ConfigurationLayout";

const WindowConfiguration = () => {
  const project_id = localStorage.getItem("project_id");
  const [nameUpdate, setNameUpdate] = useState(false);
  const [upload, setUpload] = useState(false);
  const [secuitytime, setSecurityTime] = useState(null);
  const [threshold, setThreshold] = useState(null);
  const [editedname, setEditName] = useState(null);
  var windowName = useSelector(
    (state) => state.shadingConfigurationWindow.name
  );
  var windowID = useSelector((state) => state.shadingConfigurationWindow.id);
  var state = useSelector(
    (state) => state.shadingConfigurationWindow.isShowing
  );
  // setWindowName(windowName);

  const updateName = () => {
    let url = `${process.env.REACT_APP_API_URL}api/window_polygon/${windowID}/`;
    axios
      .patch(url, {
        project: project_id,
        name: editedname,
      })
      .then((response) => {
        if (response.status == 200) {
          setNameUpdate(true);
        }
      });
  };

  const insertShadingConfiguration = () => {
    let url = "https://apis.leaftech.eu/api/window_configuration/";

    axios
      .post(url, {
        window_name: windowName,
        project: project_id,
        security_time: secuitytime,
        threshold: threshold,
      })
      .then((response) => {
        if (response.status == 201) {
          setUpload(true);
        }
      });
  };
  const projectId = project_id;
  const [show, setShow] = useState(false);
  const [name, setName] = useState(false);
  const dispatch = useDispatch();
  const visibilityGLTF = useSelector((state) => state.sceneVisibility.value[4]);

  var shadingDemandSelector = useSelector((state) => state.shadingSystem.value);
  const shadingIsVisible = shadingDemandSelector.checkStatus[2]

  const onClickOutside = (e) => {
    if (state) {
      dispatch(
        updateWindowPosition([
          null,
          null,
          null,
          null,
        ])
      );
      dispatch(toggleWindowIsShowing())
    };
  };
  var windowName = useSelector(
    (state) => state.shadingConfigurationWindow.name
  );

  var infoBoxValGeneral = useSelector(
    (state) => state.shadingConfigurationWindow.isShowing
  );
  var infoBoxValFirstIsShowing = useSelector(
    (state) => state.shadingConfigurationWindow.firstIsShowing
  );
  return (
    (visibilityGLTF || shadingIsVisible) &&
    infoBoxValFirstIsShowing &&
    infoBoxValGeneral && (
      <ConfigurationLayout title={`window: ${windowName}`} onClickOutside={onClickOutside}>
        <p style={{ padding: "2px" }}>
          here is a part for editing shading configuration
        </p>
        {!upload && (
          <div
            style={{
              border: "1.9px dashed #2459EA",
              borderRadius: "5px",
              padding: "5px",
              // display: "flex",
              // flexDirection: "column",
            }}
          >
            <button
              style={{
                width: "170px",
                background: "#2459ea",
                borderRadius: "7px",
                padding: "0px 8px",
                justifyContent: "center",
                height: "40px",
              }}
              onClick={() => {
                setShow(!show);
              }}
            >
              <lable
                style={{
                  width: "170px",
                  color: "#ffffff",
                  justifyContent: "center",
                  height: "40px",
                }}
              >
                Edit Shading Config
              </lable>
            </button>
            {show && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginTop: "10px" }}>
                  <div class="ui labeled input">
                    <div class="ui label">security time in minutes:</div>
                    <input
                      type="text"
                      placeholder="20"
                      onChange={(event) => setSecurityTime(event.target.value)}
                    />
                  </div>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div class="ui labeled input">
                    <div class="ui label">threshold:</div>
                    <input
                      type="text"
                      placeholder="20"
                      onChange={(event) => setThreshold(event.target.value)}
                    />
                  </div>
                </div>
                <button
                  style={{
                    width: "130px",
                    marginTop: "10px",
                    background: "#2459ea",
                    borderRadius: "7px",
                    padding: "0px 8px",
                    justifyContent: "center",
                    height: "40px",
                  }}
                  onClick={() => {
                    insertShadingConfiguration();
                  }}
                >
                  <lable style={{ color: "white" }}>Submit Config</lable>
                </button>
              </div>
            )}
          </div>
        )}
        {upload && (
          <div>
            your file uploaded successfully
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="130"
              fill="green"
              class="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          </div>
        )}
        {!nameUpdate && (
          <div
            style={{
              border: "1.9px dashed #2459EA",
              borderRadius: "5px",
              padding: "5px",
              marginTop: "10px",
              // display: "flex",
              // flexDirection: "column",
            }}
          >
            <button
              style={{
                width: "170px",
                background: "#2459ea",
                borderRadius: "7px",
                padding: "0px 8px",
                justifyContent: "center",
                height: "40px",
              }}
              onClick={() => setName(!name)}
            >
              <lable
                style={{
                  width: "170px",
                  color: "#ffffff",
                  justifyContent: "center",
                  height: "40px",
                }}
              >
                Edit Name
              </lable>
            </button>
            {name && (
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div class="ui  input" style={{ paddingTop: "10px" }}>
                  enter your required name:
                  <input
                    type="text"
                    placeholder="window 10G"
                    style={{ marginLeft: "10px" }}
                    onChange={(event) => setEditName(event.target.value)}
                  />
                </div>
                <button
                  style={{
                    width: "130px",
                    marginTop: "10px",
                    background: "#2459ea",
                    borderRadius: "7px",
                    padding: "0px 8px",
                    justifyContent: "center",
                    height: "40px",
                  }}
                  onClick={() => {
                    updateName();
                  }}
                // onClick={() => dispatch(toggleInfoBpx([""]))}
                >
                  <lable style={{ color: "white" }}>Update Name</lable>
                </button>
              </div>
            )}
          </div>
        )}
        {nameUpdate && (
          <div>
            the name successfully updated
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="70"
              height="130"
              fill="green"
              class="bi bi-check-circle-fill"
              viewBox="0 0 16 16"
            >
              <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
          </div>
        )}
      </ConfigurationLayout>
    )
  );
};

export default WindowConfiguration;
