import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Color, MeshBasicMaterial, SphereGeometry } from "three";
import { generateColor, windTurbineScale } from "../utils";

import axios from "axios";
import { setTurbineInfoBoxShow, setWindTurbineIsLoading, setWindTurbineValuesRanges, updateTurbineData } from "../../redux/windTurbine";
import { useColorBar } from "../CustomHooks";
import { getOldProjectId } from "../utility/hardconding";
import { SphereInstancedMesh } from "./InstancedMesh";

function generateMinMax(valueList) {
  const minVal = Math.min(...valueList);
  const maxVal = Math.max(...valueList);
  return { minVal, maxVal };
}


function getValuesRanges(data) {
  if (!data.length)
    return

  const keys = ['monthly_generation', 'aep_kwh_frequency', 'avg_wind_frequency', 'avg_wind_frequency_velocity']
  const result = {};

  data?.forEach(item => {
    for (const key of keys) {
      if (!result[key]) {
        result[key] = { min: Infinity, max: -Infinity };
      }
      const values = key === 'avg_wind_frequency_velocity' ? Object.keys(item['avg_wind_frequency']) : Object.values(item[key]);
      const min = Math.min(...values);
      const max = Math.max(...values);
      result[key].min = Math.min(result[key].min, min);
      result[key].max = Math.max(result[key].max, max);
    }
  });

  const finalResult = {};
  for (const key in result) {
    finalResult[key] = [result[key].min, result[key].max];
  }

  return finalResult;
}

const ZoneOld = (props) => {
  var dispatch = useDispatch();
  var rangeList = generateMinMax(props.data?.map(row => row.aep_kwh));
  const valuesRanges = getValuesRanges(props.data)
  valuesRanges.aep_kwh = [
    rangeList.minVal,
    rangeList.maxVal,
  ]
  dispatch(setWindTurbineValuesRanges(valuesRanges));
  const { max, min } = useColorBar("Wind Turbine", "Zone", rangeList.minVal, rangeList.maxVal);
  var windTurbineSelector = useSelector((state) => state.windTurbine.value.checkStatus);
  var geometry4 = new SphereGeometry(0.3, 20, 10);

  const visible = !!windTurbineSelector?.[props.id]
  const onClickHandler = (config, sensor) => {
    if (visible) {
      dispatch(updateTurbineData(sensor));
      dispatch(setTurbineInfoBoxShow(true));
    }
    config.stopPropagation();
  }

  return (
    <group
      rotation-x={Math.PI * -0.5}
      name="Wind turbine sensors"
      visible={visible}
    >
      {props.data.map((sensor, i) => {
        return (
          <>
            <mesh
              visible={windTurbineSelector?.[props.id]}
              geometry={geometry4}
              position={[
                sensor.position[0],
                sensor.position[1],
                sensor.position[2],
              ]}
              onClick={(config) => { onClickHandler(config, sensor) }}
            >
              <meshBasicMaterial
                dispose={null}
                attach="material"
                color={generateColor(
                  sensor?.aep_kwh,
                  min,
                  max,
                  windTurbineScale
                )}
              />
            </mesh>
          </>
        );
      })}
    </group>
  );
};


const Zone = (props) => {
  var dispatch = useDispatch();
  var rangeList = generateMinMax(props.data?.map(row => row.aep_kwh));

  useEffect(() => {
    var valuesRanges = getValuesRanges(props.data)
    valuesRanges.aep_kwh = [
      rangeList.minVal,
      rangeList.maxVal,
    ]
    dispatch(setWindTurbineValuesRanges(valuesRanges));
  }, [props.data])

  const { max, min } = useColorBar("Wind Turbine", "Zone", rangeList.minVal, rangeList.maxVal);
  var windTurbineSelector = useSelector((state) => state.windTurbine.value.checkStatus);
  const visible = !!windTurbineSelector?.[props.id]

  const { infoBoxShow, TurbineData: selectedSensorData } = useSelector((state) => state.windTurbine);

  const onClick = (config) => {
    const sensor = props.data[config.instanceId];
    dispatch(updateTurbineData(sensor));
    dispatch(setTurbineInfoBoxShow(true));
    config.stopPropagation();
  }

  const setColor = (sensor) => {
    return new Color(generateColor(
      sensor?.aep_kwh,
      min,
      max,
      windTurbineScale
    ))
  }

  const getObjectPosition = (sensor) => {
    return sensor?.position
  }
  return (
    <SphereInstancedMesh
      objects={props.data}
      setColor={setColor}
      groupProps={{
        onClick,
        visible
      }}
      Material={new MeshBasicMaterial()}
      getObjectPosition={getObjectPosition}
      radius={0.4}
      highlightProps={{
        highlightedObject: [selectedSensorData],
        showHighlight: infoBoxShow,
        radius: 0.9,
        getObjectPosition
      }}
    />
  )
};

const WindTurbine = () => {
  const project_id = localStorage.getItem("project_id");
  const oldProjectId = getOldProjectId(project_id)
  const [resultArray, setResultArray] = useState([])
  const [nodes, setNodes] = useState(false)
  const dispatch = useDispatch()
  var windTurbineSelector = useSelector((state) => state.windTurbine.value.checkStatus);
  const visible = Object.values(windTurbineSelector)?.some(e => e)

  useEffect(() => {
    const fetchData = async () => {

      try {
        dispatch(setWindTurbineIsLoading(true))
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}api/turbin_data/?project_id=${project_id}`
        );
        if (response.status === 200) {
          setNodes(true)
          setResultArray(response.data);
        }
      } finally {
        dispatch(setWindTurbineIsLoading(false))
      }
    };

    if (visible && !nodes)
      fetchData();
  }, [visible]);

  if (oldProjectId == "86" && resultArray?.length)
    return (
      <>
        <Zone id={0} data={resultArray} />
        {/* <Zone id="4" data={zoneTwo} /> */}
      </>
    );

};

export default WindTurbine;
