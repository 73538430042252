import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { updateUser } from "../redux/user";

const ActivityTracker = () => {
  const dispatch = useDispatch();
  const inactivityTimeout = 3 * 60 * 60;

  let lastActive = new Date();
  function resetLastActiveTimeListener() {
    lastActive = new Date();
  }
  window.onmousemove = resetLastActiveTimeListener;
  window.onmousedown = resetLastActiveTimeListener;
  window.onclick = resetLastActiveTimeListener;
  window.onscroll = resetLastActiveTimeListener;
  window.onkeydown = resetLastActiveTimeListener;
  window.onkeyup = resetLastActiveTimeListener;

  useEffect(() => {
    const logoutIfInactive = setInterval(async () => {
      if (new Date() - lastActive > 1000 * inactivityTimeout) {
        dispatch(updateUser({ isInactive: true }));
      }
    }, 60 * 1000);
    return () => clearInterval(logoutIfInactive);
  }, [lastActive]);
  return;
};
export default ActivityTracker;
