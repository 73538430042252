import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateAxisValues, generateNumbers } from "../utils";
import ColorBarComponent from "./ColorBarComponent";
import "./colorBar.css";
import { setColorbar } from "../../redux/colorbars";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const ColorbarSetRange = ({
  category,
  subcategory,
  defaultMin,
  defaultMax,
  colorbarMin,
  colorbarMax,
}) => {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const initialRange = [colorbarMin ?? defaultMin, colorbarMax ?? defaultMax];
  const [value, setValue] = useState(initialRange);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  useEffect(() => {
    setValue([colorbarMin ?? defaultMin, colorbarMax ?? defaultMax]);
  }, [category, subcategory, colorbarMin, defaultMin, colorbarMax, defaultMax]);

  const sliderMin = Math.min(defaultMin, value[0], initialRange[0]);
  const sliderMax = Math.max(defaultMax, value[1], initialRange[1]);
  return (
    <>
      <p
        style={{
          color: "#00f",
          position: "absolute",
          right: "1.5%",
          top: "60px",
          cursor: "pointer",
          fontSize: "18px",
          zIndex: 103,
        }}
        onClick={() => setShow(true)}
      >
        Set Range
      </p>
      {show && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
            width: "800px",
            height: "350px",
            backgroundColor: "white",
            padding: "16px",
            border: "1px solid gray",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            textAlign: "center",
            zIndex: 99,
          }}
        >
          <div>
            <h2>{category}</h2>
            <h2>{subcategory}</h2>
          </div>
          <Box
            sx={{
              width: 460,
              display: "flex",
              gap: "16px",
              alignItems: "center",
              color: "#000",
              fontSize: "18px",
            }}
          >
            <span>{sliderMin}</span>
            <Slider
              getAriaLabel={() => "Temperature range"}
              value={value}
              onChange={handleChange}
              min={sliderMin}
              max={sliderMax}
              valueLabelDisplay="off"
              step={defaultMax < 1 ? 0.01 : defaultMax < 10 ? 0.1 : 1}
            />
            <span>{sliderMax}</span>
          </Box>

          <div
            style={{
              display: "flex",
              gap: "32px",
              alignItems: "center",
            }}
          >
            <input
              style={{
                width: "80px",
                height: "40px",
                borderRadius: "5px",
                border: "1px solid #888",
                padding: "8px",
              }}
              type="number"
              placeholder="Min"
              value={value[0]}
              onChange={(e) => setValue([e.target.value, value[1]])}
            />
            <span>{" - "}</span>
            <input
              style={{
                width: "80px",
                height: "40px",
                borderRadius: "5px",
                border: "1px solid #888",
                padding: "8px",
              }}
              placeholder="Max"
              type="number"
              value={value[1]}
              onChange={(e) => setValue([value[0], e.target.value])}
            />
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              width: "60%",
              marginTop: "32px",
            }}
          >
            <button
              style={{
                width: "170px",
                background: "#2459ea",
                borderRadius: "7px",
                padding: "0px 8px",
                justifyContent: "center",
                height: "40px",
                color: "white",
                border: "none",
              }}
              onClick={() => {
                dispatch(
                  setColorbar([
                    category,
                    subcategory,
                    value[0],
                    value[1],
                    // newMax !== "" ? Number(newMax) : undefined,
                    undefined,
                  ])
                );
                setShow(false);
              }}
            >
              OK
            </button>
            <button
              style={{
                width: "170px",
                background: "#2459ea",
                borderRadius: "7px",
                padding: "0px 8px",
                justifyContent: "center",
                height: "40px",
                color: "white",
                border: "none",
              }}
              onClick={() => {
                setShow(false);
                setValue(initialRange);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default ColorbarSetRange;
