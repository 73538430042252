import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Asset from "./Asset";
import { setloading } from "../../redux/sceneSlice";
export default function InternalWalls(props) {
  const dispatch = useDispatch();
  const project_id = localStorage.getItem("project_id");
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  const doorsList = useSelector((state) => state.sceneVar.doors);
  var targetBuildingScene = useSelector(
    (state) => state.sceneVisibility.value[6]
  );
  return (
    doorsList && (
      <group name="doors" visible={targetBuildingScene}>
        {doorsList.map((floor, index) => {
          if (!floor) return null;
          return (
            <>
              <Asset
                url={`${process.env.REACT_APP_API_URL}project/glb/?project_id=${project_id}&type=${floor}`}
                visible={selectedFloor[index]}
                onFulfilled={
                  index === doorsList.length - 1
                    ? () => dispatch(setloading({ index: 6, value: false }))
                    : null
                }
                onLoading={() =>
                  dispatch(setloading({ index: 6, value: true }))
                }
              />
            </>
          );
        })}
      </group>
    )
  );
}
