import React from "react";
import { useWindRiskColorBar } from "../CustomHooks";
import { CustomColorBar } from "./ColorBarComponent";
import "./colorBar.css";

const ColorBarWindRisk = () => {
  const { isVisible, maxTimeVelMoreThan12 } = useWindRiskColorBar();

  return (
    <CustomColorBar
      colorbarClassName="wind-risk-color"
      category="Shading System"
      subcategory="Wind Risk"
      unit="Hours"
      dataMin={0}
      dataMax={maxTimeVelMoreThan12}
      isVisible={isVisible}
    />
  );
};

export default ColorBarWindRisk;
