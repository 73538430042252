const Project56ShadingProgression = [
    {
        ID: -1,
        polygon: "ANotExistingPolygon",
        "05-24 00:00": 0,
        "05-24 00:15": 0,
        "05-24 00:30": 0,
        "05-24 00:45": 0,
        "05-24 01:00": 0,
        "05-24 01:15": 0,
        "05-24 01:30": 0,
        "05-24 01:45": 0,
        "05-24 02:00": 0,
        "05-24 02:15": 0,
        "05-24 02:30": 0,
        "05-24 02:45": 0,
        "05-24 03:00": 0,
        "05-24 03:15": 0,
        "05-24 03:30": 0,
        "05-24 03:45": 0,
        "05-24 04:00": 0,
        "05-24 04:15": 0,
        "05-24 04:30": 0,
        "05-24 04:45": 0,
        "05-24 05:00": 0,
        "05-24 05:15": 0,
        "05-24 05:30": 0,
        "05-24 05:45": 0,
        "05-24 06:00": 0,
        "05-24 06:15": 0,
        "05-24 06:30": 0,
        "05-24 06:45": 0,
        "05-24 07:00": 0,
        "05-24 07:15": 0,
        "05-24 07:30": 0,
        "05-24 07:45": 0,
        "05-24 08:00": 0,
        "05-24 08:15": 0,
        "05-24 08:30": 0,
        "05-24 08:45": 0,
        "05-24 09:00": 0,
        "05-24 09:15": 0,
        "05-24 09:30": 0,
        "05-24 09:45": 0,
        "05-24 10:00": 0,
        "05-24 10:15": 0,
        "05-24 10:30": 0,
        "05-24 10:45": 0,
        "05-24 11:00": 0,
        "05-24 11:15": 0,
        "05-24 11:30": 0,
        "05-24 11:45": 0,
        "05-24 12:00": 0,
        "05-24 12:15": 0,
        "05-24 12:30": 0,
        "05-24 12:45": 0,
        "05-24 13:00": 0,
        "05-24 13:15": 0,
        "05-24 13:30": 0,
        "05-24 13:45": 0,
        "05-24 14:00": 0,
        "05-24 14:15": 0,
        "05-24 14:30": 0,
        "05-24 14:45": 0,
        "05-24 15:00": 0,
        "05-24 15:15": 0,
        "05-24 15:30": 0,
        "05-24 15:45": 0,
        "05-24 16:00": 0,
        "05-24 16:15": 0,
        "05-24 16:30": 0,
        "05-24 16:45": 0,
        "05-24 17:00": 0,
        "05-24 17:15": 0,
        "05-24 17:30": 0,
        "05-24 17:45": 0,
        "05-24 18:00": 0,
        "05-24 18:15": 0,
        "05-24 18:30": 0,
        "05-24 18:45": 0,
        "05-24 19:00": 0,
        "05-24 19:15": 0,
        "05-24 19:30": 0,
        "05-24 19:45": 0,
        "05-24 20:00": 0,
        "05-24 20:15": 0,
        "05-24 20:30": 0,
        "05-24 20:45": 0,
        "05-24 21:00": 0,
        "05-24 21:15": 0,
        "05-24 21:30": 0,
        "05-24 21:45": 0,
        "05-24 22:00": 0,
        "05-24 22:15": 0,
        "05-24 22:30": 0,
        "05-24 22:45": 0,
        "05-24 23:00": 0,
        "05-24 23:15": 0,
        "05-24 23:30": 0,
        "05-24 23:45": 0,
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_42",
        "polygon": [
            -106.165,
            93.482,
            0.08,
            -105.786,
            93.339,
            0.08,
            -105.786,
            93.339,
            2.928,
            -105.786,
            93.339,
            2.928,
            -106.165,
            93.482,
            2.928,
            -106.165,
            93.482,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_43",
        "polygon": [
            -106.165,
            93.482,
            2.928,
            -105.786,
            93.339,
            2.928,
            -105.786,
            93.339,
            3.698,
            -105.786,
            93.339,
            3.698,
            -106.165,
            93.482,
            3.698,
            -106.165,
            93.482,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_46",
        "polygon": [
            -103.544,
            92.497,
            0.08,
            -103.165,
            92.354,
            0.08,
            -103.165,
            92.354,
            2.928,
            -103.165,
            92.354,
            2.928,
            -103.544,
            92.497,
            2.928,
            -103.544,
            92.497,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_47",
        "polygon": [
            -103.544,
            92.497,
            2.928,
            -103.165,
            92.354,
            2.928,
            -103.165,
            92.354,
            3.698,
            -103.165,
            92.354,
            3.698,
            -103.544,
            92.497,
            3.698,
            -103.544,
            92.497,
            2.928
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_50",
        "polygon": [
            -108.479,
            94.352,
            0.08,
            -107.096,
            93.832,
            0.08,
            -107.096,
            93.832,
            3.698,
            -107.096,
            93.832,
            3.698,
            -108.479,
            94.352,
            3.698,
            -108.479,
            94.352,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_51",
        "polygon": [
            -106.999,
            93.795,
            0.079,
            -106.204,
            93.497,
            0.079,
            -106.204,
            93.497,
            3.697,
            -106.204,
            93.497,
            3.697,
            -106.999,
            93.795,
            3.697,
            -106.999,
            93.795,
            0.079
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_52",
        "polygon": [
            -105.688,
            93.303,
            0.08,
            -104.475,
            92.847,
            0.08,
            -104.475,
            92.847,
            3.698,
            -104.475,
            92.847,
            3.698,
            -105.688,
            93.303,
            3.698,
            -105.688,
            93.303,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_55",
        "polygon": [
            -104.378,
            92.81,
            0.079,
            -103.583,
            92.511,
            0.079,
            -103.583,
            92.511,
            3.697,
            -103.583,
            92.511,
            3.697,
            -104.378,
            92.81,
            3.697,
            -104.378,
            92.81,
            0.079
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_56",
        "polygon": [
            -100.923,
            91.511,
            0.08,
            -100.544,
            91.369,
            0.08,
            -100.544,
            91.369,
            2.928,
            -100.544,
            91.369,
            2.928,
            -100.923,
            91.511,
            2.928,
            -100.923,
            91.511,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_57",
        "polygon": [
            -103.067,
            92.317,
            0.08,
            -101.854,
            91.861,
            0.08,
            -101.854,
            91.861,
            3.698,
            -101.854,
            91.861,
            3.698,
            -103.067,
            92.317,
            3.698,
            -103.067,
            92.317,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_58",
        "polygon": [
            -100.923,
            91.511,
            2.928,
            -100.544,
            91.369,
            2.928,
            -100.544,
            91.369,
            3.698,
            -100.544,
            91.369,
            3.698,
            -100.923,
            91.511,
            3.698,
            -100.923,
            91.511,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_59",
        "polygon": [
            -101.757,
            91.825,
            0.079,
            -100.962,
            91.526,
            0.079,
            -100.962,
            91.526,
            3.697,
            -100.962,
            91.526,
            3.697,
            -101.757,
            91.825,
            3.697,
            -101.757,
            91.825,
            0.079
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_60",
        "polygon": [
            -98.302,
            90.526,
            0.08,
            -97.923,
            90.383,
            0.08,
            -97.923,
            90.383,
            2.928,
            -97.923,
            90.383,
            2.928,
            -98.302,
            90.526,
            2.928,
            -98.302,
            90.526,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_61",
        "polygon": [
            -100.446,
            91.332,
            0.08,
            -99.233,
            90.876,
            0.08,
            -99.233,
            90.876,
            3.698,
            -99.233,
            90.876,
            3.698,
            -100.446,
            91.332,
            3.698,
            -100.446,
            91.332,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_62",
        "polygon": [
            -98.302,
            90.526,
            2.928,
            -97.923,
            90.383,
            2.928,
            -97.923,
            90.383,
            3.698,
            -97.923,
            90.383,
            3.698,
            -98.302,
            90.526,
            3.698,
            -98.302,
            90.526,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_63",
        "polygon": [
            -99.136,
            90.839,
            0.079,
            -98.341,
            90.541,
            0.079,
            -98.341,
            90.541,
            3.697,
            -98.341,
            90.541,
            3.697,
            -99.136,
            90.839,
            3.697,
            -99.136,
            90.839,
            0.079
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_64",
        "polygon": [
            -95.681,
            89.541,
            0.08,
            -95.302,
            89.398,
            0.08,
            -95.302,
            89.398,
            2.928,
            -95.302,
            89.398,
            2.928,
            -95.681,
            89.541,
            2.928,
            -95.681,
            89.541,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_65",
        "polygon": [
            -97.826,
            90.347,
            0.08,
            -96.612,
            89.891,
            0.08,
            -96.612,
            89.891,
            3.698,
            -96.612,
            89.891,
            3.698,
            -97.826,
            90.347,
            3.698,
            -97.826,
            90.347,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_66",
        "polygon": [
            -95.681,
            89.541,
            2.928,
            -95.302,
            89.398,
            2.928,
            -95.302,
            89.398,
            3.698,
            -95.302,
            89.398,
            3.698,
            -95.681,
            89.541,
            3.698,
            -95.681,
            89.541,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_67",
        "polygon": [
            -96.515,
            89.854,
            0.079,
            -95.72,
            89.555,
            0.079,
            -95.72,
            89.555,
            3.697,
            -95.72,
            89.555,
            3.697,
            -96.515,
            89.854,
            3.697,
            -96.515,
            89.854,
            0.079
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_68",
        "polygon": [
            -95.205,
            89.361,
            0.08,
            -93.821,
            88.841,
            0.08,
            -93.821,
            88.841,
            3.698,
            -93.821,
            88.841,
            3.698,
            -95.205,
            89.361,
            3.698,
            -95.205,
            89.361,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_69",
        "polygon": [
            -72.093,
            80.672,
            0.08,
            -71.714,
            80.53,
            0.08,
            -71.714,
            80.53,
            2.928,
            -71.714,
            80.53,
            2.928,
            -72.093,
            80.672,
            2.928,
            -72.093,
            80.672,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_70",
        "polygon": [
            -72.093,
            80.672,
            2.928,
            -71.714,
            80.53,
            2.928,
            -71.714,
            80.53,
            3.698,
            -71.714,
            80.53,
            3.698,
            -72.093,
            80.672,
            3.698,
            -72.093,
            80.672,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_71",
        "polygon": [
            -74.408,
            81.543,
            0.08,
            -73.024,
            81.023,
            0.08,
            -73.024,
            81.023,
            3.698,
            -73.024,
            81.023,
            3.698,
            -74.408,
            81.543,
            3.698,
            -74.408,
            81.543,
            0.08
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_72",
        "polygon": [
            -72.927,
            80.986,
            0.079,
            -72.132,
            80.687,
            0.079,
            -72.132,
            80.687,
            3.697,
            -72.132,
            80.687,
            3.697,
            -72.927,
            80.986,
            3.697,
            -72.927,
            80.986,
            0.079
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_73",
        "polygon": [
            -71.617,
            80.493,
            0.08,
            -70.403,
            80.037,
            0.08,
            -70.403,
            80.037,
            3.698,
            -70.403,
            80.037,
            3.698,
            -71.617,
            80.493,
            3.698,
            -71.617,
            80.493,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_74",
        "polygon": [
            -70.306,
            80.001,
            0.08,
            -69.445,
            79.677,
            0.08,
            -69.445,
            79.677,
            2.928,
            -69.445,
            79.677,
            2.928,
            -70.306,
            80.001,
            2.928,
            -70.306,
            80.001,
            0.08
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_75",
        "polygon": [
            -70.306,
            80.001,
            2.928,
            -69.445,
            79.677,
            2.928,
            -69.445,
            79.677,
            3.698,
            -69.445,
            79.677,
            3.698,
            -70.306,
            80.001,
            3.698,
            -70.306,
            80.001,
            2.928
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_76",
        "polygon": [
            -69.406,
            79.662,
            0.08,
            -69.093,
            79.545,
            0.08,
            -69.093,
            79.545,
            3.698,
            -69.093,
            79.545,
            3.698,
            -69.406,
            79.662,
            3.698,
            -69.406,
            79.662,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_77",
        "polygon": [
            -68.996,
            79.508,
            0.08,
            -67.783,
            79.052,
            0.08,
            -67.783,
            79.052,
            3.698,
            -67.783,
            79.052,
            3.698,
            -68.996,
            79.508,
            3.698,
            -68.996,
            79.508,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_78",
        "polygon": [
            -66.851,
            78.702,
            0.08,
            -66.472,
            78.559,
            0.08,
            -66.472,
            78.559,
            2.928,
            -66.472,
            78.559,
            2.928,
            -66.851,
            78.702,
            2.928,
            -66.851,
            78.702,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_79",
        "polygon": [
            -66.851,
            78.702,
            2.928,
            -66.472,
            78.559,
            2.928,
            -66.472,
            78.559,
            3.698,
            -66.472,
            78.559,
            3.698,
            -66.851,
            78.702,
            3.698,
            -66.851,
            78.702,
            2.928
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_80",
        "polygon": [
            -67.685,
            79.015,
            0.079,
            -66.891,
            78.716,
            0.079,
            -66.891,
            78.716,
            3.697,
            -66.891,
            78.716,
            3.697,
            -67.685,
            79.015,
            3.697,
            -67.685,
            79.015,
            0.079
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_81",
        "polygon": [
            -66.375,
            78.523,
            0.08,
            -65.162,
            78.066,
            0.08,
            -65.162,
            78.066,
            3.698,
            -65.162,
            78.066,
            3.698,
            -66.375,
            78.523,
            3.698,
            -66.375,
            78.523,
            0.08
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_82",
        "polygon": [
            -65.064,
            78.03,
            0.08,
            -64.203,
            77.706,
            0.08,
            -64.203,
            77.706,
            2.928,
            -64.203,
            77.706,
            2.928,
            -65.064,
            78.03,
            2.928,
            -65.064,
            78.03,
            0.08
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_83",
        "polygon": [
            -65.064,
            78.03,
            2.928,
            -64.203,
            77.706,
            2.928,
            -64.203,
            77.706,
            3.698,
            -64.203,
            77.706,
            3.698,
            -65.064,
            78.03,
            3.698,
            -65.064,
            78.03,
            2.928
        ],
        "05-24 05:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_84",
        "polygon": [
            -64.164,
            77.691,
            0.08,
            -63.851,
            77.574,
            0.08,
            -63.851,
            77.574,
            3.698,
            -63.851,
            77.574,
            3.698,
            -64.164,
            77.691,
            3.698,
            -64.164,
            77.691,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_85",
        "polygon": [
            -63.754,
            77.537,
            0.08,
            -62.541,
            77.081,
            0.08,
            -62.541,
            77.081,
            3.698,
            -62.541,
            77.081,
            3.698,
            -63.754,
            77.537,
            3.698,
            -63.754,
            77.537,
            0.08
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_86",
        "polygon": [
            -61.609,
            76.731,
            0.08,
            -61.23,
            76.588,
            0.08,
            -61.23,
            76.588,
            2.928,
            -61.23,
            76.588,
            2.928,
            -61.609,
            76.731,
            2.928,
            -61.609,
            76.731,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_87",
        "polygon": [
            -61.609,
            76.731,
            2.928,
            -61.23,
            76.588,
            2.928,
            -61.23,
            76.588,
            3.698,
            -61.23,
            76.588,
            3.698,
            -61.609,
            76.731,
            3.698,
            -61.609,
            76.731,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_88",
        "polygon": [
            -62.443,
            77.045,
            0.079,
            -61.649,
            76.746,
            0.079,
            -61.649,
            76.746,
            3.697,
            -61.649,
            76.746,
            3.697,
            -62.443,
            77.045,
            3.697,
            -62.443,
            77.045,
            0.079
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_89",
        "polygon": [
            -61.133,
            76.552,
            0.08,
            -59.92,
            76.096,
            0.08,
            -59.92,
            76.096,
            3.698,
            -59.92,
            76.096,
            3.698,
            -61.133,
            76.552,
            3.698,
            -61.133,
            76.552,
            0.08
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_90",
        "polygon": [
            -59.823,
            76.059,
            0.08,
            -58.961,
            75.735,
            0.08,
            -58.961,
            75.735,
            2.928,
            -58.961,
            75.735,
            2.928,
            -59.823,
            76.059,
            2.928,
            -59.823,
            76.059,
            0.08
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_91",
        "polygon": [
            -59.823,
            76.059,
            2.928,
            -58.961,
            75.735,
            2.928,
            -58.961,
            75.735,
            3.698,
            -58.961,
            75.735,
            3.698,
            -59.823,
            76.059,
            3.698,
            -59.823,
            76.059,
            2.928
        ],
        "05-24 05:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_92",
        "polygon": [
            -58.922,
            75.721,
            0.08,
            -58.609,
            75.603,
            0.08,
            -58.609,
            75.603,
            3.698,
            -58.609,
            75.603,
            3.698,
            -58.922,
            75.721,
            3.698,
            -58.922,
            75.721,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_93",
        "polygon": [
            -58.512,
            75.567,
            0.08,
            -57.299,
            75.11,
            0.08,
            -57.299,
            75.11,
            3.698,
            -57.299,
            75.11,
            3.698,
            -58.512,
            75.567,
            3.698,
            -58.512,
            75.567,
            0.08
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_94",
        "polygon": [
            -56.368,
            74.76,
            0.08,
            -55.989,
            74.618,
            0.08,
            -55.989,
            74.618,
            2.928,
            -55.989,
            74.618,
            2.928,
            -56.368,
            74.76,
            2.928,
            -56.368,
            74.76,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_95",
        "polygon": [
            -56.368,
            74.76,
            2.928,
            -55.989,
            74.618,
            2.928,
            -55.989,
            74.618,
            3.698,
            -55.989,
            74.618,
            3.698,
            -56.368,
            74.76,
            3.698,
            -56.368,
            74.76,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_96",
        "polygon": [
            -57.202,
            75.074,
            0.079,
            -56.407,
            74.775,
            0.079,
            -56.407,
            74.775,
            3.697,
            -56.407,
            74.775,
            3.697,
            -57.202,
            75.074,
            3.697,
            -57.202,
            75.074,
            0.079
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_97",
        "polygon": [
            -55.891,
            74.581,
            0.08,
            -54.678,
            74.125,
            0.08,
            -54.678,
            74.125,
            3.698,
            -54.678,
            74.125,
            3.698,
            -55.891,
            74.581,
            3.698,
            -55.891,
            74.581,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_98",
        "polygon": [
            -54.581,
            74.088,
            0.08,
            -53.72,
            73.765,
            0.08,
            -53.72,
            73.765,
            2.928,
            -53.72,
            73.765,
            2.928,
            -54.581,
            74.088,
            2.928,
            -54.581,
            74.088,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_99",
        "polygon": [
            -54.581,
            74.088,
            2.928,
            -53.72,
            73.765,
            2.928,
            -53.72,
            73.765,
            3.698,
            -53.72,
            73.765,
            3.698,
            -54.581,
            74.088,
            3.698,
            -54.581,
            74.088,
            2.928
        ],
        "05-24 05:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_100",
        "polygon": [
            -53.68,
            73.75,
            0.08,
            -53.368,
            73.632,
            0.08,
            -53.368,
            73.632,
            3.698,
            -53.368,
            73.632,
            3.698,
            -53.68,
            73.75,
            3.698,
            -53.68,
            73.75,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_101",
        "polygon": [
            -53.93,
            67.861,
            0.08,
            -52.717,
            67.405,
            0.08,
            -52.717,
            67.405,
            2.928,
            -52.717,
            67.405,
            2.928,
            -53.93,
            67.861,
            2.928,
            -53.93,
            67.861,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_102",
        "polygon": [
            -52.62,
            67.369,
            0.11,
            -47.476,
            65.435,
            0.11,
            -47.476,
            65.435,
            3.313,
            -47.476,
            65.435,
            3.313,
            -52.62,
            67.369,
            3.313,
            -52.62,
            67.369,
            0.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_103",
        "polygon": [
            -47.378,
            65.398,
            0.11,
            -44.855,
            64.449,
            0.11,
            -44.855,
            64.449,
            3.313,
            -44.855,
            64.449,
            3.313,
            -47.378,
            65.398,
            3.313,
            -47.378,
            65.398,
            0.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_104",
        "polygon": [
            -53.93,
            67.861,
            2.928,
            -52.717,
            67.405,
            2.928,
            -52.717,
            67.405,
            3.283,
            -52.717,
            67.405,
            3.283,
            -53.93,
            67.861,
            3.283,
            -53.93,
            67.861,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_105",
        "polygon": [
            -54.915,
            68.232,
            0.08,
            -53.979,
            67.88,
            0.08,
            -53.979,
            67.88,
            3.283,
            -53.979,
            67.88,
            3.283,
            -54.915,
            68.232,
            3.283,
            -54.915,
            68.232,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_106",
        "polygon": [
            -42.137,
            63.427,
            0.11,
            -39.613,
            62.479,
            0.11,
            -39.613,
            62.479,
            3.313,
            -39.613,
            62.479,
            3.313,
            -42.137,
            63.427,
            3.313,
            -42.137,
            63.427,
            0.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_107",
        "polygon": [
            -39.516,
            62.442,
            0.08,
            -38.303,
            61.986,
            0.08,
            -38.303,
            61.986,
            2.928,
            -38.303,
            61.986,
            2.928,
            -39.516,
            62.442,
            2.928,
            -39.516,
            62.442,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_108",
        "polygon": [
            -38.205,
            61.949,
            0.11,
            -33.061,
            60.015,
            0.11,
            -33.061,
            60.015,
            3.313,
            -33.061,
            60.015,
            3.313,
            -38.205,
            61.949,
            3.313,
            -38.205,
            61.949,
            0.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_109",
        "polygon": [
            -39.516,
            62.442,
            2.928,
            -38.303,
            61.986,
            2.928,
            -38.303,
            61.986,
            3.283,
            -38.303,
            61.986,
            3.283,
            -39.516,
            62.442,
            3.283,
            -39.516,
            62.442,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_110",
        "polygon": [
            -32.963,
            59.979,
            0.11,
            -27.649,
            57.98,
            0.11,
            -27.649,
            57.98,
            3.313,
            -27.649,
            57.98,
            3.313,
            -32.963,
            59.979,
            3.313,
            -32.963,
            59.979,
            0.11
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_130",
        "polygon": [
            36.014,
            34.046,
            2.928,
            36.393,
            33.903,
            2.928,
            36.393,
            33.903,
            3.698,
            36.393,
            33.903,
            3.698,
            36.014,
            34.046,
            3.698,
            36.014,
            34.046,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_131",
        "polygon": [
            36.014,
            34.046,
            0.08,
            36.393,
            33.903,
            0.08,
            36.393,
            33.903,
            2.928,
            36.393,
            33.903,
            2.928,
            36.014,
            34.046,
            2.928,
            36.014,
            34.046,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_132",
        "polygon": [
            35.18,
            34.359,
            0.08,
            35.975,
            34.061,
            0.08,
            35.975,
            34.061,
            3.698,
            35.975,
            34.061,
            3.698,
            35.18,
            34.359,
            3.698,
            35.18,
            34.359,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_133",
        "polygon": [
            33.869,
            34.852,
            0.08,
            35.082,
            34.396,
            0.08,
            35.082,
            34.396,
            3.698,
            35.082,
            34.396,
            3.698,
            33.869,
            34.852,
            3.698,
            33.869,
            34.852,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_134",
        "polygon": [
            36.49,
            33.867,
            0.08,
            37.874,
            33.347,
            0.08,
            37.874,
            33.347,
            3.698,
            37.874,
            33.347,
            3.698,
            36.49,
            33.867,
            3.698,
            36.49,
            33.867,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_135",
        "polygon": [
            33.393,
            35.031,
            2.928,
            33.772,
            34.889,
            2.928,
            33.772,
            34.889,
            3.698,
            33.772,
            34.889,
            3.698,
            33.393,
            35.031,
            3.698,
            33.393,
            35.031,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_136",
        "polygon": [
            33.393,
            35.031,
            0.08,
            33.772,
            34.889,
            0.08,
            33.772,
            34.889,
            2.928,
            33.772,
            34.889,
            2.928,
            33.393,
            35.031,
            2.928,
            33.393,
            35.031,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_137",
        "polygon": [
            32.559,
            35.345,
            0.08,
            33.354,
            35.046,
            0.08,
            33.354,
            35.046,
            3.698,
            33.354,
            35.046,
            3.698,
            32.559,
            35.345,
            3.698,
            32.559,
            35.345,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_138",
        "polygon": [
            31.248,
            35.838,
            0.08,
            32.462,
            35.381,
            0.08,
            32.462,
            35.381,
            3.698,
            32.462,
            35.381,
            3.698,
            31.248,
            35.838,
            3.698,
            31.248,
            35.838,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_139",
        "polygon": [
            30.772,
            36.017,
            2.928,
            31.151,
            35.874,
            2.928,
            31.151,
            35.874,
            3.698,
            31.151,
            35.874,
            3.698,
            30.772,
            36.017,
            3.698,
            30.772,
            36.017,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_140",
        "polygon": [
            30.772,
            36.017,
            0.08,
            31.151,
            35.874,
            0.08,
            31.151,
            35.874,
            2.928,
            31.151,
            35.874,
            2.928,
            30.772,
            36.017,
            2.928,
            30.772,
            36.017,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_141",
        "polygon": [
            29.938,
            36.33,
            0.08,
            30.733,
            36.031,
            0.08,
            30.733,
            36.031,
            3.698,
            30.733,
            36.031,
            3.698,
            29.938,
            36.33,
            3.698,
            29.938,
            36.33,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_142",
        "polygon": [
            28.628,
            36.823,
            0.08,
            29.841,
            36.367,
            0.08,
            29.841,
            36.367,
            3.698,
            29.841,
            36.367,
            3.698,
            28.628,
            36.823,
            3.698,
            28.628,
            36.823,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_143",
        "polygon": [
            28.151,
            37.002,
            2.928,
            28.53,
            36.859,
            2.928,
            28.53,
            36.859,
            3.698,
            28.53,
            36.859,
            3.698,
            28.151,
            37.002,
            3.698,
            28.151,
            37.002,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_144",
        "polygon": [
            28.151,
            37.002,
            0.08,
            28.53,
            36.859,
            0.08,
            28.53,
            36.859,
            2.928,
            28.53,
            36.859,
            2.928,
            28.151,
            37.002,
            2.928,
            28.151,
            37.002,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_145",
        "polygon": [
            27.317,
            37.316,
            0.08,
            28.112,
            37.017,
            0.08,
            28.112,
            37.017,
            3.698,
            28.112,
            37.017,
            3.698,
            27.317,
            37.316,
            3.698,
            27.317,
            37.316,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_146",
        "polygon": [
            26.007,
            37.808,
            0.08,
            27.22,
            37.352,
            0.08,
            27.22,
            37.352,
            3.698,
            27.22,
            37.352,
            3.698,
            26.007,
            37.808,
            3.698,
            26.007,
            37.808,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_147",
        "polygon": [
            25.53,
            37.987,
            2.928,
            25.909,
            37.845,
            2.928,
            25.909,
            37.845,
            3.698,
            25.909,
            37.845,
            3.698,
            25.53,
            37.987,
            3.698,
            25.53,
            37.987,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_148",
        "polygon": [
            25.53,
            37.987,
            0.08,
            25.909,
            37.845,
            0.08,
            25.909,
            37.845,
            2.928,
            25.909,
            37.845,
            2.928,
            25.53,
            37.987,
            2.928,
            25.53,
            37.987,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_149",
        "polygon": [
            24.696,
            38.301,
            0.08,
            25.491,
            38.002,
            0.08,
            25.491,
            38.002,
            3.698,
            25.491,
            38.002,
            3.698,
            24.696,
            38.301,
            3.698,
            24.696,
            38.301,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_150",
        "polygon": [
            23.386,
            38.794,
            0.08,
            24.599,
            38.337,
            0.08,
            24.599,
            38.337,
            3.698,
            24.599,
            38.337,
            3.698,
            23.386,
            38.794,
            3.698,
            23.386,
            38.794,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_151",
        "polygon": [
            22.909,
            38.973,
            2.928,
            23.288,
            38.83,
            2.928,
            23.288,
            38.83,
            3.698,
            23.288,
            38.83,
            3.698,
            22.909,
            38.973,
            3.698,
            22.909,
            38.973,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_152",
        "polygon": [
            22.909,
            38.973,
            0.08,
            23.288,
            38.83,
            0.08,
            23.288,
            38.83,
            2.928,
            23.288,
            38.83,
            2.928,
            22.909,
            38.973,
            2.928,
            22.909,
            38.973,
            0.08
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_153",
        "polygon": [
            22.075,
            39.286,
            0.08,
            22.87,
            38.987,
            0.08,
            22.87,
            38.987,
            3.698,
            22.87,
            38.987,
            3.698,
            22.075,
            39.286,
            3.698,
            22.075,
            39.286,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_154",
        "polygon": [
            20.765,
            39.779,
            0.08,
            21.978,
            39.323,
            0.08,
            21.978,
            39.323,
            3.698,
            21.978,
            39.323,
            3.698,
            20.765,
            39.779,
            3.698,
            20.765,
            39.779,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_155",
        "polygon": [
            20.262,
            39.968,
            2.928,
            20.641,
            39.825,
            2.928,
            20.641,
            39.825,
            3.698,
            20.641,
            39.825,
            3.698,
            20.262,
            39.968,
            3.698,
            20.262,
            39.968,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_156",
        "polygon": [
            20.262,
            39.968,
            0.08,
            20.641,
            39.825,
            0.08,
            20.641,
            39.825,
            2.928,
            20.641,
            39.825,
            2.928,
            20.262,
            39.968,
            2.928,
            20.262,
            39.968,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_157",
        "polygon": [
            19.428,
            40.282,
            0.08,
            20.222,
            39.983,
            0.08,
            20.222,
            39.983,
            3.698,
            20.222,
            39.983,
            3.698,
            19.428,
            40.282,
            3.698,
            19.428,
            40.282,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_158",
        "polygon": [
            18.117,
            40.774,
            0.08,
            19.33,
            40.318,
            0.08,
            19.33,
            40.318,
            3.698,
            19.33,
            40.318,
            3.698,
            18.117,
            40.774,
            3.698,
            18.117,
            40.774,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_159",
        "polygon": [
            17.668,
            40.943,
            2.928,
            18.047,
            40.801,
            2.928,
            18.047,
            40.801,
            3.698,
            18.047,
            40.801,
            3.698,
            17.668,
            40.943,
            3.698,
            17.668,
            40.943,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_160",
        "polygon": [
            17.668,
            40.943,
            0.08,
            18.047,
            40.801,
            0.08,
            18.047,
            40.801,
            2.928,
            18.047,
            40.801,
            2.928,
            17.668,
            40.943,
            2.928,
            17.668,
            40.943,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_161",
        "polygon": [
            16.833,
            41.257,
            0.08,
            17.628,
            40.958,
            0.08,
            17.628,
            40.958,
            3.698,
            17.628,
            40.958,
            3.698,
            16.833,
            41.257,
            3.698,
            16.833,
            41.257,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_162",
        "polygon": [
            15.523,
            41.75,
            0.08,
            16.736,
            41.294,
            0.08,
            16.736,
            41.294,
            3.698,
            16.736,
            41.294,
            3.698,
            15.523,
            41.75,
            3.698,
            15.523,
            41.75,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_163",
        "polygon": [
            15.047,
            41.929,
            2.928,
            15.426,
            41.786,
            2.928,
            15.426,
            41.786,
            3.698,
            15.426,
            41.786,
            3.698,
            15.047,
            41.929,
            3.698,
            15.047,
            41.929,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_164",
        "polygon": [
            15.047,
            41.929,
            0.08,
            15.426,
            41.786,
            0.08,
            15.426,
            41.786,
            2.928,
            15.426,
            41.786,
            2.928,
            15.047,
            41.929,
            2.928,
            15.047,
            41.929,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_165",
        "polygon": [
            14.213,
            42.242,
            0.08,
            15.007,
            41.944,
            0.08,
            15.007,
            41.944,
            3.698,
            15.007,
            41.944,
            3.698,
            14.213,
            42.242,
            3.698,
            14.213,
            42.242,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_166",
        "polygon": [
            12.902,
            42.735,
            0.08,
            14.115,
            42.279,
            0.08,
            14.115,
            42.279,
            3.698,
            14.115,
            42.279,
            3.698,
            12.902,
            42.735,
            3.698,
            12.902,
            42.735,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_167",
        "polygon": [
            12.426,
            42.914,
            2.928,
            12.805,
            42.772,
            2.928,
            12.805,
            42.772,
            3.698,
            12.805,
            42.772,
            3.698,
            12.426,
            42.914,
            3.698,
            12.426,
            42.914,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_168",
        "polygon": [
            12.426,
            42.914,
            0.08,
            12.805,
            42.772,
            0.08,
            12.805,
            42.772,
            2.928,
            12.805,
            42.772,
            2.928,
            12.426,
            42.914,
            2.928,
            12.426,
            42.914,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_169",
        "polygon": [
            11.592,
            43.228,
            0.08,
            12.386,
            42.929,
            0.08,
            12.386,
            42.929,
            3.698,
            12.386,
            42.929,
            3.698,
            11.592,
            43.228,
            3.698,
            11.592,
            43.228,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_170",
        "polygon": [
            10.281,
            43.72,
            0.08,
            11.494,
            43.264,
            0.08,
            11.494,
            43.264,
            3.698,
            11.494,
            43.264,
            3.698,
            10.281,
            43.72,
            3.698,
            10.281,
            43.72,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_171",
        "polygon": [
            9.805,
            43.899,
            2.928,
            10.184,
            43.757,
            2.928,
            10.184,
            43.757,
            3.698,
            10.184,
            43.757,
            3.698,
            9.805,
            43.899,
            3.698,
            9.805,
            43.899,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_172",
        "polygon": [
            9.805,
            43.899,
            0.08,
            10.184,
            43.757,
            0.08,
            10.184,
            43.757,
            2.928,
            10.184,
            43.757,
            2.928,
            9.805,
            43.899,
            2.928,
            9.805,
            43.899,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_173",
        "polygon": [
            8.971,
            44.213,
            0.08,
            9.765,
            43.914,
            0.08,
            9.765,
            43.914,
            3.698,
            9.765,
            43.914,
            3.698,
            8.971,
            44.213,
            3.698,
            8.971,
            44.213,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_174",
        "polygon": [
            7.66,
            44.706,
            0.08,
            8.873,
            44.25,
            0.08,
            8.873,
            44.25,
            3.698,
            8.873,
            44.25,
            3.698,
            7.66,
            44.706,
            3.698,
            7.66,
            44.706,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_175",
        "polygon": [
            7.184,
            44.885,
            2.928,
            7.563,
            44.742,
            2.928,
            7.563,
            44.742,
            3.698,
            7.563,
            44.742,
            3.698,
            7.184,
            44.885,
            3.698,
            7.184,
            44.885,
            2.928
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_176",
        "polygon": [
            7.184,
            44.885,
            0.08,
            7.563,
            44.742,
            0.08,
            7.563,
            44.742,
            2.928,
            7.563,
            44.742,
            2.928,
            7.184,
            44.885,
            2.928,
            7.184,
            44.885,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_177",
        "polygon": [
            6.35,
            45.198,
            0.08,
            7.145,
            44.9,
            0.08,
            7.145,
            44.9,
            3.698,
            7.145,
            44.9,
            3.698,
            6.35,
            45.198,
            3.698,
            6.35,
            45.198,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_178",
        "polygon": [
            5.039,
            45.691,
            0.08,
            6.253,
            45.235,
            0.08,
            6.253,
            45.235,
            3.698,
            6.253,
            45.235,
            3.698,
            5.039,
            45.691,
            3.698,
            5.039,
            45.691,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_179",
        "polygon": [
            4.563,
            45.87,
            2.928,
            4.942,
            45.728,
            2.928,
            4.942,
            45.728,
            3.698,
            4.942,
            45.728,
            3.698,
            4.563,
            45.87,
            3.698,
            4.563,
            45.87,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_180",
        "polygon": [
            4.563,
            45.87,
            0.08,
            4.942,
            45.728,
            0.08,
            4.942,
            45.728,
            2.928,
            4.942,
            45.728,
            2.928,
            4.563,
            45.87,
            2.928,
            4.563,
            45.87,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_181",
        "polygon": [
            3.729,
            46.184,
            0.08,
            4.524,
            45.885,
            0.08,
            4.524,
            45.885,
            3.698,
            4.524,
            45.885,
            3.698,
            3.729,
            46.184,
            3.698,
            3.729,
            46.184,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_182",
        "polygon": [
            2.419,
            46.676,
            0.08,
            3.632,
            46.22,
            0.08,
            3.632,
            46.22,
            3.698,
            3.632,
            46.22,
            3.698,
            2.419,
            46.676,
            3.698,
            2.419,
            46.676,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_183",
        "polygon": [
            1.942,
            46.855,
            2.928,
            2.321,
            46.713,
            2.928,
            2.321,
            46.713,
            3.698,
            2.321,
            46.713,
            3.698,
            1.942,
            46.855,
            3.698,
            1.942,
            46.855,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_184",
        "polygon": [
            1.942,
            46.855,
            0.08,
            2.321,
            46.713,
            0.08,
            2.321,
            46.713,
            2.928,
            2.321,
            46.713,
            2.928,
            1.942,
            46.855,
            2.928,
            1.942,
            46.855,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_185",
        "polygon": [
            1.108,
            47.169,
            0.08,
            1.903,
            46.87,
            0.08,
            1.903,
            46.87,
            3.698,
            1.903,
            46.87,
            3.698,
            1.108,
            47.169,
            3.698,
            1.108,
            47.169,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_186",
        "polygon": [
            -0.202,
            47.662,
            0.08,
            1.011,
            47.206,
            0.08,
            1.011,
            47.206,
            3.698,
            1.011,
            47.206,
            3.698,
            -0.202,
            47.662,
            3.698,
            -0.202,
            47.662,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_187",
        "polygon": [
            -0.679,
            47.841,
            2.928,
            -0.3,
            47.698,
            2.928,
            -0.3,
            47.698,
            3.698,
            -0.3,
            47.698,
            3.698,
            -0.679,
            47.841,
            3.698,
            -0.679,
            47.841,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_188",
        "polygon": [
            -0.679,
            47.841,
            0.08,
            -0.3,
            47.698,
            0.08,
            -0.3,
            47.698,
            2.928,
            -0.3,
            47.698,
            2.928,
            -0.679,
            47.841,
            2.928,
            -0.679,
            47.841,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_189",
        "polygon": [
            -1.513,
            48.154,
            0.08,
            -0.718,
            47.856,
            0.08,
            -0.718,
            47.856,
            3.698,
            -0.718,
            47.856,
            3.698,
            -1.513,
            48.154,
            3.698,
            -1.513,
            48.154,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_190",
        "polygon": [
            -2.823,
            48.647,
            0.08,
            -1.61,
            48.191,
            0.08,
            -1.61,
            48.191,
            3.698,
            -1.61,
            48.191,
            3.698,
            -2.823,
            48.647,
            3.698,
            -2.823,
            48.647,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_191",
        "polygon": [
            -3.3,
            48.826,
            2.928,
            -2.92,
            48.684,
            2.928,
            -2.92,
            48.684,
            3.698,
            -2.92,
            48.684,
            3.698,
            -3.3,
            48.826,
            3.698,
            -3.3,
            48.826,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_192",
        "polygon": [
            -3.3,
            48.826,
            0.08,
            -2.92,
            48.684,
            0.08,
            -2.92,
            48.684,
            2.928,
            -2.92,
            48.684,
            2.928,
            -3.3,
            48.826,
            2.928,
            -3.3,
            48.826,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_193",
        "polygon": [
            -4.134,
            49.14,
            0.08,
            -3.339,
            48.841,
            0.08,
            -3.339,
            48.841,
            3.698,
            -3.339,
            48.841,
            3.698,
            -4.134,
            49.14,
            3.698,
            -4.134,
            49.14,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_194",
        "polygon": [
            -5.444,
            49.632,
            0.08,
            -4.231,
            49.176,
            0.08,
            -4.231,
            49.176,
            3.698,
            -4.231,
            49.176,
            3.698,
            -5.444,
            49.632,
            3.698,
            -5.444,
            49.632,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_195",
        "polygon": [
            -5.92,
            49.812,
            2.928,
            -5.541,
            49.669,
            2.928,
            -5.541,
            49.669,
            3.698,
            -5.541,
            49.669,
            3.698,
            -5.92,
            49.812,
            3.698,
            -5.92,
            49.812,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_196",
        "polygon": [
            -5.92,
            49.812,
            0.08,
            -5.541,
            49.669,
            0.08,
            -5.541,
            49.669,
            2.928,
            -5.541,
            49.669,
            2.928,
            -5.92,
            49.812,
            2.928,
            -5.92,
            49.812,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_197",
        "polygon": [
            -6.755,
            50.125,
            0.08,
            -5.96,
            49.826,
            0.08,
            -5.96,
            49.826,
            3.698,
            -5.96,
            49.826,
            3.698,
            -6.755,
            50.125,
            3.698,
            -6.755,
            50.125,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_198",
        "polygon": [
            -8.235,
            50.682,
            0.08,
            -6.852,
            50.162,
            0.08,
            -6.852,
            50.162,
            3.698,
            -6.852,
            50.162,
            3.698,
            -8.235,
            50.682,
            3.698,
            -8.235,
            50.682,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_11",
        "polygon": [
            31.87,
            -16.894,
            0.142,
            30.589,
            -16.413,
            0.142,
            30.589,
            -16.413,
            3.698,
            30.589,
            -16.413,
            3.698,
            31.87,
            -16.894,
            3.698,
            31.87,
            -16.894,
            0.142
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_9",
        "polygon": [
            29.181,
            -15.883,
            0.142,
            27.968,
            -15.427,
            0.142,
            27.968,
            -15.427,
            3.698,
            27.968,
            -15.427,
            3.698,
            29.181,
            -15.883,
            3.698,
            29.181,
            -15.883,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_7",
        "polygon": [
            26.56,
            -14.898,
            0.142,
            25.347,
            -14.442,
            0.142,
            25.347,
            -14.442,
            3.698,
            25.347,
            -14.442,
            3.698,
            26.56,
            -14.898,
            3.698,
            26.56,
            -14.898,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_5",
        "polygon": [
            23.939,
            -13.913,
            0.142,
            22.726,
            -13.457,
            0.142,
            22.726,
            -13.457,
            3.698,
            22.726,
            -13.457,
            3.698,
            23.939,
            -13.913,
            3.698,
            23.939,
            -13.913,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_3",
        "polygon": [
            21.318,
            -12.927,
            0.142,
            20.105,
            -12.471,
            0.142,
            20.105,
            -12.471,
            3.698,
            20.105,
            -12.471,
            3.698,
            21.318,
            -12.927,
            3.698,
            21.318,
            -12.927,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_37",
        "polygon": [
            -10.832,
            -0.84,
            0.142,
            -12.216,
            -0.32,
            0.142,
            -12.216,
            -0.32,
            3.698,
            -12.216,
            -0.32,
            3.698,
            -10.832,
            -0.84,
            3.698,
            -10.832,
            -0.84,
            0.142
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_35",
        "polygon": [
            -13.624,
            0.209,
            0.142,
            -14.837,
            0.666,
            0.142,
            -14.837,
            0.666,
            3.698,
            -14.837,
            0.666,
            3.698,
            -13.624,
            0.209,
            3.698,
            -13.624,
            0.209,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_33",
        "polygon": [
            -16.245,
            1.195,
            0.142,
            -17.458,
            1.651,
            0.142,
            -17.458,
            1.651,
            3.698,
            -17.458,
            1.651,
            3.698,
            -16.245,
            1.195,
            3.698,
            -16.245,
            1.195,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_31",
        "polygon": [
            -18.865,
            2.18,
            0.142,
            -20.079,
            2.636,
            0.142,
            -20.079,
            2.636,
            3.698,
            -20.079,
            2.636,
            3.698,
            -18.865,
            2.18,
            3.698,
            -18.865,
            2.18,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_29",
        "polygon": [
            -21.486,
            3.166,
            0.142,
            -22.699,
            3.622,
            0.142,
            -22.699,
            3.622,
            3.698,
            -22.699,
            3.622,
            3.698,
            -21.486,
            3.166,
            3.698,
            -21.486,
            3.166,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_27",
        "polygon": [
            -24.107,
            4.151,
            0.142,
            -25.32,
            4.607,
            0.142,
            -25.32,
            4.607,
            3.698,
            -25.32,
            4.607,
            3.698,
            -24.107,
            4.151,
            3.698,
            -24.107,
            4.151,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_25",
        "polygon": [
            -26.728,
            5.136,
            0.142,
            -27.941,
            5.592,
            0.142,
            -27.941,
            5.592,
            3.698,
            -27.941,
            5.592,
            3.698,
            -26.728,
            5.136,
            3.698,
            -26.728,
            5.136,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_23",
        "polygon": [
            -29.349,
            6.122,
            0.142,
            -30.562,
            6.578,
            0.142,
            -30.562,
            6.578,
            3.698,
            -30.562,
            6.578,
            3.698,
            -29.349,
            6.122,
            3.698,
            -29.349,
            6.122,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_21",
        "polygon": [
            -31.97,
            7.107,
            0.142,
            -33.183,
            7.563,
            0.142,
            -33.183,
            7.563,
            3.698,
            -33.183,
            7.563,
            3.698,
            -31.97,
            7.107,
            3.698,
            -31.97,
            7.107,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_19",
        "polygon": [
            -34.591,
            8.092,
            0.142,
            -35.804,
            8.548,
            0.142,
            -35.804,
            8.548,
            3.698,
            -35.804,
            8.548,
            3.698,
            -34.591,
            8.092,
            3.698,
            -34.591,
            8.092,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_17",
        "polygon": [
            -37.212,
            9.078,
            0.142,
            -38.425,
            9.534,
            0.142,
            -38.425,
            9.534,
            3.698,
            -38.425,
            9.534,
            3.698,
            -37.212,
            9.078,
            3.698,
            -37.212,
            9.078,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_15",
        "polygon": [
            -39.833,
            10.063,
            0.142,
            -41.046,
            10.519,
            0.142,
            -41.046,
            10.519,
            3.698,
            -41.046,
            10.519,
            3.698,
            -39.833,
            10.063,
            3.698,
            -39.833,
            10.063,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_13",
        "polygon": [
            -42.454,
            11.048,
            0.142,
            -43.667,
            11.504,
            0.142,
            -43.667,
            11.504,
            3.698,
            -43.667,
            11.504,
            3.698,
            -42.454,
            11.048,
            3.698,
            -42.454,
            11.048,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_11",
        "polygon": [
            -45.074,
            12.034,
            0.142,
            -46.288,
            12.49,
            0.142,
            -46.288,
            12.49,
            3.698,
            -46.288,
            12.49,
            3.698,
            -45.074,
            12.034,
            3.698,
            -45.074,
            12.034,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_9",
        "polygon": [
            -47.695,
            13.019,
            0.142,
            -48.908,
            13.475,
            0.142,
            -48.908,
            13.475,
            3.698,
            -48.908,
            13.475,
            3.698,
            -47.695,
            13.019,
            3.698,
            -47.695,
            13.019,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_7",
        "polygon": [
            -50.316,
            14.004,
            0.142,
            -51.529,
            14.46,
            0.142,
            -51.529,
            14.46,
            3.698,
            -51.529,
            14.46,
            3.698,
            -50.316,
            14.004,
            3.698,
            -50.316,
            14.004,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_5",
        "polygon": [
            -52.937,
            14.99,
            0.142,
            -54.15,
            15.446,
            0.142,
            -54.15,
            15.446,
            3.698,
            -54.15,
            15.446,
            3.698,
            -52.937,
            14.99,
            3.698,
            -52.937,
            14.99,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_3",
        "polygon": [
            -55.558,
            15.975,
            0.142,
            -56.771,
            16.431,
            0.142,
            -56.771,
            16.431,
            3.698,
            -56.771,
            16.431,
            3.698,
            -55.558,
            15.975,
            3.698,
            -55.558,
            15.975,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_1",
        "polygon": [
            -58.179,
            16.96,
            0.142,
            -59.562,
            17.481,
            0.142,
            -59.562,
            17.481,
            3.698,
            -59.562,
            17.481,
            3.698,
            -58.179,
            16.96,
            3.698,
            -58.179,
            16.96,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_3",
        "polygon": [
            -123.701,
            41.594,
            0.142,
            -124.914,
            42.05,
            0.142,
            -124.914,
            42.05,
            3.698,
            -124.914,
            42.05,
            3.698,
            -123.701,
            41.594,
            3.698,
            -123.701,
            41.594,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_1",
        "polygon": [
            -126.322,
            42.58,
            0.142,
            -127.706,
            43.1,
            0.142,
            -127.706,
            43.1,
            3.698,
            -127.706,
            43.1,
            3.698,
            -126.322,
            42.58,
            3.698,
            -126.322,
            42.58,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_5",
        "polygon": [
            -121.08,
            40.609,
            0.142,
            -122.293,
            41.065,
            0.142,
            -122.293,
            41.065,
            3.698,
            -122.293,
            41.065,
            3.698,
            -121.08,
            40.609,
            3.698,
            -121.08,
            40.609,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_7",
        "polygon": [
            -118.459,
            39.623,
            0.142,
            -119.673,
            40.08,
            0.142,
            -119.673,
            40.08,
            3.698,
            -119.673,
            40.08,
            3.698,
            -118.459,
            39.623,
            3.698,
            -118.459,
            39.623,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_9",
        "polygon": [
            -115.839,
            38.638,
            0.142,
            -117.052,
            39.094,
            0.142,
            -117.052,
            39.094,
            3.698,
            -117.052,
            39.094,
            3.698,
            -115.839,
            38.638,
            3.698,
            -115.839,
            38.638,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_11",
        "polygon": [
            -113.218,
            37.653,
            0.142,
            -114.431,
            38.109,
            0.142,
            -114.431,
            38.109,
            3.698,
            -114.431,
            38.109,
            3.698,
            -113.218,
            37.653,
            3.698,
            -113.218,
            37.653,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_15",
        "polygon": [
            -107.976,
            35.682,
            0.142,
            -109.189,
            36.138,
            0.142,
            -109.189,
            36.138,
            3.698,
            -109.189,
            36.138,
            3.698,
            -107.976,
            35.682,
            3.698,
            -107.976,
            35.682,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_19",
        "polygon": [
            -102.734,
            33.711,
            0.142,
            -103.947,
            34.167,
            0.142,
            -103.947,
            34.167,
            3.698,
            -103.947,
            34.167,
            3.698,
            -102.734,
            33.711,
            3.698,
            -102.734,
            33.711,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_21",
        "polygon": [
            -100.113,
            32.726,
            0.142,
            -101.326,
            33.182,
            0.142,
            -101.326,
            33.182,
            3.698,
            -101.326,
            33.182,
            3.698,
            -100.113,
            32.726,
            3.698,
            -100.113,
            32.726,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_23",
        "polygon": [
            -97.492,
            31.741,
            0.142,
            -98.705,
            32.197,
            0.142,
            -98.705,
            32.197,
            3.698,
            -98.705,
            32.197,
            3.698,
            -97.492,
            31.741,
            3.698,
            -97.492,
            31.741,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_27",
        "polygon": [
            -92.251,
            29.77,
            0.142,
            -93.464,
            30.226,
            0.142,
            -93.464,
            30.226,
            3.698,
            -93.464,
            30.226,
            3.698,
            -92.251,
            29.77,
            3.698,
            -92.251,
            29.77,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_29",
        "polygon": [
            -89.63,
            28.785,
            0.142,
            -90.843,
            29.241,
            0.142,
            -90.843,
            29.241,
            3.698,
            -90.843,
            29.241,
            3.698,
            -89.63,
            28.785,
            3.698,
            -89.63,
            28.785,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_31",
        "polygon": [
            -87.009,
            27.799,
            0.142,
            -88.222,
            28.255,
            0.142,
            -88.222,
            28.255,
            3.698,
            -88.222,
            28.255,
            3.698,
            -87.009,
            27.799,
            3.698,
            -87.009,
            27.799,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_35",
        "polygon": [
            -81.767,
            25.829,
            0.142,
            -82.98,
            26.285,
            0.142,
            -82.98,
            26.285,
            3.698,
            -82.98,
            26.285,
            3.698,
            -81.767,
            25.829,
            3.698,
            -81.767,
            25.829,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_37",
        "polygon": [
            -78.976,
            24.779,
            0.142,
            -80.359,
            25.299,
            0.142,
            -80.359,
            25.299,
            3.698,
            -80.359,
            25.299,
            3.698,
            -78.976,
            24.779,
            3.698,
            -78.976,
            24.779,
            0.142
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_13",
        "polygon": [
            -110.597,
            36.667,
            0.142,
            -111.81,
            37.124,
            0.142,
            -111.81,
            37.124,
            3.698,
            -111.81,
            37.124,
            3.698,
            -110.597,
            36.667,
            3.698,
            -110.597,
            36.667,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_17",
        "polygon": [
            -105.355,
            34.697,
            0.142,
            -106.568,
            35.153,
            0.142,
            -106.568,
            35.153,
            3.698,
            -106.568,
            35.153,
            3.698,
            -105.355,
            34.697,
            3.698,
            -105.355,
            34.697,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_25",
        "polygon": [
            -94.871,
            30.755,
            0.142,
            -96.085,
            31.211,
            0.142,
            -96.085,
            31.211,
            3.698,
            -96.085,
            31.211,
            3.698,
            -94.871,
            30.755,
            3.698,
            -94.871,
            30.755,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_33",
        "polygon": [
            -84.388,
            26.814,
            0.142,
            -85.601,
            27.27,
            0.142,
            -85.601,
            27.27,
            3.698,
            -85.601,
            27.27,
            3.698,
            -84.388,
            26.814,
            3.698,
            -84.388,
            26.814,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_39",
        "polygon": [
            -127.722,
            42.999,
            0.142,
            -127.202,
            44.382,
            0.142,
            -127.202,
            44.382,
            3.698,
            -127.202,
            44.382,
            3.698,
            -127.722,
            42.999,
            3.698,
            -127.722,
            42.999,
            0.142
        ],
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_37",
        "polygon": [
            -126.673,
            45.79,
            0.142,
            -126.217,
            47.003,
            0.142,
            -126.217,
            47.003,
            3.698,
            -126.217,
            47.003,
            3.698,
            -126.673,
            45.79,
            3.698,
            -126.673,
            45.79,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_35",
        "polygon": [
            -125.687,
            48.411,
            0.142,
            -125.231,
            49.624,
            0.142,
            -125.231,
            49.624,
            3.698,
            -125.231,
            49.624,
            3.698,
            -125.687,
            48.411,
            3.698,
            -125.687,
            48.411,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_33",
        "polygon": [
            -124.702,
            51.032,
            0.142,
            -124.246,
            52.245,
            0.142,
            -124.246,
            52.245,
            3.698,
            -124.246,
            52.245,
            3.698,
            -124.702,
            51.032,
            3.698,
            -124.702,
            51.032,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_31",
        "polygon": [
            -123.717,
            53.653,
            0.142,
            -123.261,
            54.866,
            0.142,
            -123.261,
            54.866,
            3.698,
            -123.261,
            54.866,
            3.698,
            -123.717,
            53.653,
            3.698,
            -123.717,
            53.653,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_29",
        "polygon": [
            -122.731,
            56.274,
            0.142,
            -122.275,
            57.487,
            0.142,
            -122.275,
            57.487,
            3.698,
            -122.275,
            57.487,
            3.698,
            -122.731,
            56.274,
            3.698,
            -122.731,
            56.274,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_27",
        "polygon": [
            -121.746,
            58.895,
            0.142,
            -121.29,
            60.108,
            0.142,
            -121.29,
            60.108,
            3.698,
            -121.29,
            60.108,
            3.698,
            -121.746,
            58.895,
            3.698,
            -121.746,
            58.895,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_25",
        "polygon": [
            -120.761,
            61.516,
            0.142,
            -120.305,
            62.729,
            0.142,
            -120.305,
            62.729,
            3.698,
            -120.305,
            62.729,
            3.698,
            -120.761,
            61.516,
            3.698,
            -120.761,
            61.516,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_23",
        "polygon": [
            -119.775,
            64.137,
            0.142,
            -119.319,
            65.35,
            0.142,
            -119.319,
            65.35,
            3.698,
            -119.319,
            65.35,
            3.698,
            -119.775,
            64.137,
            3.698,
            -119.775,
            64.137,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_21",
        "polygon": [
            -118.79,
            66.757,
            0.142,
            -118.334,
            67.971,
            0.142,
            -118.334,
            67.971,
            3.698,
            -118.334,
            67.971,
            3.698,
            -118.79,
            66.757,
            3.698,
            -118.79,
            66.757,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_17",
        "polygon": [
            -116.819,
            71.999,
            0.142,
            -116.363,
            73.212,
            0.142,
            -116.363,
            73.212,
            3.698,
            -116.363,
            73.212,
            3.698,
            -116.819,
            71.999,
            3.698,
            -116.819,
            71.999,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_15",
        "polygon": [
            -115.834,
            74.62,
            0.142,
            -115.378,
            75.833,
            0.142,
            -115.378,
            75.833,
            3.698,
            -115.378,
            75.833,
            3.698,
            -115.834,
            74.62,
            3.698,
            -115.834,
            74.62,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_13",
        "polygon": [
            -114.848,
            77.241,
            0.142,
            -114.392,
            78.454,
            0.142,
            -114.392,
            78.454,
            3.698,
            -114.392,
            78.454,
            3.698,
            -114.848,
            77.241,
            3.698,
            -114.848,
            77.241,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_11",
        "polygon": [
            -113.863,
            79.862,
            0.142,
            -113.407,
            81.075,
            0.142,
            -113.407,
            81.075,
            3.698,
            -113.407,
            81.075,
            3.698,
            -113.863,
            79.862,
            3.698,
            -113.863,
            79.862,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_9",
        "polygon": [
            -112.878,
            82.483,
            0.142,
            -112.422,
            83.696,
            0.142,
            -112.422,
            83.696,
            3.698,
            -112.422,
            83.696,
            3.698,
            -112.878,
            82.483,
            3.698,
            -112.878,
            82.483,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_7",
        "polygon": [
            -111.892,
            85.104,
            0.142,
            -111.436,
            86.317,
            0.142,
            -111.436,
            86.317,
            3.698,
            -111.436,
            86.317,
            3.698,
            -111.892,
            85.104,
            3.698,
            -111.892,
            85.104,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_5",
        "polygon": [
            -110.907,
            87.725,
            0.142,
            -110.451,
            88.938,
            0.142,
            -110.451,
            88.938,
            3.698,
            -110.451,
            88.938,
            3.698,
            -110.907,
            87.725,
            3.698,
            -110.907,
            87.725,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_19",
        "polygon": [
            -117.805,
            69.378,
            0.142,
            -117.348,
            70.591,
            0.142,
            -117.348,
            70.591,
            3.698,
            -117.348,
            70.591,
            3.698,
            -117.805,
            69.378,
            3.698,
            -117.805,
            69.378,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_3",
        "polygon": [
            -109.922,
            90.345,
            0.142,
            -109.466,
            91.559,
            0.142,
            -109.466,
            91.559,
            3.698,
            -109.466,
            91.559,
            3.698,
            -109.922,
            90.345,
            3.698,
            -109.922,
            90.345,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_1",
        "polygon": [
            -108.936,
            92.966,
            0.142,
            -108.416,
            94.35,
            0.142,
            -108.416,
            94.35,
            3.698,
            -108.416,
            94.35,
            3.698,
            -108.936,
            92.966,
            3.698,
            -108.936,
            92.966,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_36",
        "polygon": [
            37.861,
            33.415,
            0.142,
            37.682,
            31.948,
            0.142,
            37.682,
            31.948,
            3.698,
            37.682,
            31.948,
            3.698,
            37.861,
            33.415,
            3.698,
            37.861,
            33.415,
            0.142
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_34",
        "polygon": [
            37.5,
            30.455,
            0.142,
            37.343,
            29.169,
            0.142,
            37.343,
            29.169,
            3.698,
            37.343,
            29.169,
            3.698,
            37.5,
            30.455,
            3.698,
            37.5,
            30.455,
            0.142
        ],
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_32",
        "polygon": [
            37.161,
            27.676,
            0.142,
            37.004,
            26.389,
            0.142,
            37.004,
            26.389,
            3.698,
            37.004,
            26.389,
            3.698,
            37.161,
            27.676,
            3.698,
            37.161,
            27.676,
            0.142
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_30",
        "polygon": [
            36.822,
            24.896,
            0.142,
            36.665,
            23.61,
            0.142,
            36.665,
            23.61,
            3.698,
            36.665,
            23.61,
            3.698,
            36.822,
            24.896,
            3.698,
            36.822,
            24.896,
            0.142
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_28",
        "polygon": [
            36.483,
            22.117,
            0.142,
            36.326,
            20.831,
            0.142,
            36.326,
            20.831,
            3.698,
            36.326,
            20.831,
            3.698,
            36.483,
            22.117,
            3.698,
            36.483,
            22.117,
            0.142
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_26",
        "polygon": [
            36.144,
            19.338,
            0.142,
            35.987,
            18.051,
            0.142,
            35.987,
            18.051,
            3.698,
            35.987,
            18.051,
            3.698,
            36.144,
            19.338,
            3.698,
            36.144,
            19.338,
            0.142
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_24",
        "polygon": [
            35.805,
            16.558,
            0.142,
            35.648,
            15.272,
            0.142,
            35.648,
            15.272,
            3.698,
            35.648,
            15.272,
            3.698,
            35.805,
            16.558,
            3.698,
            35.805,
            16.558,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_22",
        "polygon": [
            35.466,
            13.779,
            0.142,
            35.309,
            12.492,
            0.142,
            35.309,
            12.492,
            3.698,
            35.309,
            12.492,
            3.698,
            35.466,
            13.779,
            3.698,
            35.466,
            13.779,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_20",
        "polygon": [
            35.127,
            10.999,
            0.142,
            34.97,
            9.713,
            0.142,
            34.97,
            9.713,
            3.698,
            34.97,
            9.713,
            3.698,
            35.127,
            10.999,
            3.698,
            35.127,
            10.999,
            0.142
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_18",
        "polygon": [
            34.788,
            8.22,
            0.142,
            34.631,
            6.934,
            0.142,
            34.631,
            6.934,
            3.698,
            34.631,
            6.934,
            3.698,
            34.788,
            8.22,
            3.698,
            34.788,
            8.22,
            0.142
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_16",
        "polygon": [
            34.449,
            5.441,
            0.142,
            34.292,
            4.154,
            0.142,
            34.292,
            4.154,
            3.698,
            34.292,
            4.154,
            3.698,
            34.449,
            5.441,
            3.698,
            34.449,
            5.441,
            0.142
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_14",
        "polygon": [
            34.11,
            2.661,
            0.142,
            33.953,
            1.375,
            0.142,
            33.953,
            1.375,
            3.698,
            33.953,
            1.375,
            3.698,
            34.11,
            2.661,
            3.698,
            34.11,
            2.661,
            0.142
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_12",
        "polygon": [
            33.771,
            -0.118,
            0.142,
            33.614,
            -1.405,
            0.142,
            33.614,
            -1.405,
            3.698,
            33.614,
            -1.405,
            3.698,
            33.771,
            -0.118,
            3.698,
            33.771,
            -0.118,
            0.142
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_10",
        "polygon": [
            33.432,
            -2.898,
            0.142,
            33.275,
            -4.184,
            0.142,
            33.275,
            -4.184,
            3.698,
            33.275,
            -4.184,
            3.698,
            33.432,
            -2.898,
            3.698,
            33.432,
            -2.898,
            0.142
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_8",
        "polygon": [
            33.093,
            -5.677,
            0.142,
            32.936,
            -6.963,
            0.142,
            32.936,
            -6.963,
            3.698,
            32.936,
            -6.963,
            3.698,
            33.093,
            -5.677,
            3.698,
            33.093,
            -5.677,
            0.142
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_6",
        "polygon": [
            32.754,
            -8.456,
            0.142,
            32.597,
            -9.743,
            0.142,
            32.597,
            -9.743,
            3.698,
            32.597,
            -9.743,
            3.698,
            32.754,
            -8.456,
            3.698,
            32.754,
            -8.456,
            0.142
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_2",
        "polygon": [
            32.076,
            -14.015,
            0.142,
            31.919,
            -15.302,
            0.142,
            31.919,
            -15.302,
            3.698,
            31.919,
            -15.302,
            3.698,
            32.076,
            -14.015,
            3.698,
            32.076,
            -14.015,
            0.142
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_36",
        "polygon": [
            37.861,
            33.415,
            4.202,
            37.682,
            31.948,
            4.202,
            37.682,
            31.948,
            7.408,
            37.682,
            31.948,
            7.408,
            37.861,
            33.415,
            7.408,
            37.861,
            33.415,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_34",
        "polygon": [
            37.5,
            30.455,
            4.202,
            37.343,
            29.169,
            4.202,
            37.343,
            29.169,
            7.408,
            37.343,
            29.169,
            7.408,
            37.5,
            30.455,
            7.408,
            37.5,
            30.455,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_32",
        "polygon": [
            37.161,
            27.676,
            4.202,
            37.004,
            26.389,
            4.202,
            37.004,
            26.389,
            7.408,
            37.004,
            26.389,
            7.408,
            37.161,
            27.676,
            7.408,
            37.161,
            27.676,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_30",
        "polygon": [
            36.822,
            24.896,
            4.202,
            36.665,
            23.61,
            4.202,
            36.665,
            23.61,
            7.408,
            36.665,
            23.61,
            7.408,
            36.822,
            24.896,
            7.408,
            36.822,
            24.896,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_28",
        "polygon": [
            36.483,
            22.117,
            4.202,
            36.326,
            20.831,
            4.202,
            36.326,
            20.831,
            7.408,
            36.326,
            20.831,
            7.408,
            36.483,
            22.117,
            7.408,
            36.483,
            22.117,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_26",
        "polygon": [
            36.144,
            19.338,
            4.202,
            35.987,
            18.051,
            4.202,
            35.987,
            18.051,
            7.408,
            35.987,
            18.051,
            7.408,
            36.144,
            19.338,
            7.408,
            36.144,
            19.338,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_24",
        "polygon": [
            35.805,
            16.558,
            4.202,
            35.648,
            15.272,
            4.202,
            35.648,
            15.272,
            7.408,
            35.648,
            15.272,
            7.408,
            35.805,
            16.558,
            7.408,
            35.805,
            16.558,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_22",
        "polygon": [
            35.466,
            13.779,
            4.202,
            35.309,
            12.492,
            4.202,
            35.309,
            12.492,
            7.408,
            35.309,
            12.492,
            7.408,
            35.466,
            13.779,
            7.408,
            35.466,
            13.779,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_20",
        "polygon": [
            35.127,
            10.999,
            4.202,
            34.97,
            9.713,
            4.202,
            34.97,
            9.713,
            7.408,
            34.97,
            9.713,
            7.408,
            35.127,
            10.999,
            7.408,
            35.127,
            10.999,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_18",
        "polygon": [
            34.788,
            8.22,
            4.202,
            34.631,
            6.934,
            4.202,
            34.631,
            6.934,
            7.408,
            34.631,
            6.934,
            7.408,
            34.788,
            8.22,
            7.408,
            34.788,
            8.22,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_16",
        "polygon": [
            34.449,
            5.441,
            4.202,
            34.292,
            4.154,
            4.202,
            34.292,
            4.154,
            7.408,
            34.292,
            4.154,
            7.408,
            34.449,
            5.441,
            7.408,
            34.449,
            5.441,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_14",
        "polygon": [
            34.11,
            2.661,
            4.202,
            33.953,
            1.375,
            4.202,
            33.953,
            1.375,
            7.408,
            33.953,
            1.375,
            7.408,
            34.11,
            2.661,
            7.408,
            34.11,
            2.661,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_12",
        "polygon": [
            33.771,
            -0.118,
            4.202,
            33.614,
            -1.405,
            4.202,
            33.614,
            -1.405,
            7.408,
            33.614,
            -1.405,
            7.408,
            33.771,
            -0.118,
            7.408,
            33.771,
            -0.118,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_10",
        "polygon": [
            33.432,
            -2.898,
            4.202,
            33.275,
            -4.184,
            4.202,
            33.275,
            -4.184,
            7.408,
            33.275,
            -4.184,
            7.408,
            33.432,
            -2.898,
            7.408,
            33.432,
            -2.898,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_8",
        "polygon": [
            33.093,
            -5.677,
            4.202,
            32.936,
            -6.963,
            4.202,
            32.936,
            -6.963,
            7.408,
            32.936,
            -6.963,
            7.408,
            33.093,
            -5.677,
            7.408,
            33.093,
            -5.677,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_6",
        "polygon": [
            32.754,
            -8.456,
            4.202,
            32.597,
            -9.743,
            4.202,
            32.597,
            -9.743,
            7.408,
            32.597,
            -9.743,
            7.408,
            32.754,
            -8.456,
            7.408,
            32.754,
            -8.456,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_4",
        "polygon": [
            32.415,
            -11.236,
            4.202,
            32.258,
            -12.522,
            4.202,
            32.258,
            -12.522,
            7.408,
            32.258,
            -12.522,
            7.408,
            32.415,
            -11.236,
            7.408,
            32.415,
            -11.236,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_2",
        "polygon": [
            32.076,
            -14.015,
            4.202,
            31.919,
            -15.302,
            4.202,
            31.919,
            -15.302,
            7.408,
            31.919,
            -15.302,
            7.408,
            32.076,
            -14.015,
            7.408,
            32.076,
            -14.015,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_36",
        "polygon": [
            37.861,
            33.415,
            7.912,
            37.682,
            31.948,
            7.912,
            37.682,
            31.948,
            11.118,
            37.682,
            31.948,
            11.118,
            37.861,
            33.415,
            11.118,
            37.861,
            33.415,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_34",
        "polygon": [
            37.5,
            30.455,
            7.912,
            37.343,
            29.169,
            7.912,
            37.343,
            29.169,
            11.118,
            37.343,
            29.169,
            11.118,
            37.5,
            30.455,
            11.118,
            37.5,
            30.455,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_32",
        "polygon": [
            37.161,
            27.676,
            7.912,
            37.004,
            26.389,
            7.912,
            37.004,
            26.389,
            11.118,
            37.004,
            26.389,
            11.118,
            37.161,
            27.676,
            11.118,
            37.161,
            27.676,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_30",
        "polygon": [
            36.822,
            24.896,
            7.912,
            36.665,
            23.61,
            7.912,
            36.665,
            23.61,
            11.118,
            36.665,
            23.61,
            11.118,
            36.822,
            24.896,
            11.118,
            36.822,
            24.896,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_28",
        "polygon": [
            36.483,
            22.117,
            7.912,
            36.326,
            20.831,
            7.912,
            36.326,
            20.831,
            11.118,
            36.326,
            20.831,
            11.118,
            36.483,
            22.117,
            11.118,
            36.483,
            22.117,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_26",
        "polygon": [
            36.144,
            19.338,
            7.912,
            35.987,
            18.051,
            7.912,
            35.987,
            18.051,
            11.118,
            35.987,
            18.051,
            11.118,
            36.144,
            19.338,
            11.118,
            36.144,
            19.338,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_24",
        "polygon": [
            35.805,
            16.558,
            7.912,
            35.648,
            15.272,
            7.912,
            35.648,
            15.272,
            11.118,
            35.648,
            15.272,
            11.118,
            35.805,
            16.558,
            11.118,
            35.805,
            16.558,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_22",
        "polygon": [
            35.466,
            13.779,
            7.912,
            35.309,
            12.492,
            7.912,
            35.309,
            12.492,
            11.118,
            35.309,
            12.492,
            11.118,
            35.466,
            13.779,
            11.118,
            35.466,
            13.779,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_20",
        "polygon": [
            35.127,
            10.999,
            7.912,
            34.97,
            9.713,
            7.912,
            34.97,
            9.713,
            11.118,
            34.97,
            9.713,
            11.118,
            35.127,
            10.999,
            11.118,
            35.127,
            10.999,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_18",
        "polygon": [
            34.788,
            8.22,
            7.912,
            34.631,
            6.934,
            7.912,
            34.631,
            6.934,
            11.118,
            34.631,
            6.934,
            11.118,
            34.788,
            8.22,
            11.118,
            34.788,
            8.22,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_16",
        "polygon": [
            34.449,
            5.441,
            7.912,
            34.292,
            4.154,
            7.912,
            34.292,
            4.154,
            11.118,
            34.292,
            4.154,
            11.118,
            34.449,
            5.441,
            11.118,
            34.449,
            5.441,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_14",
        "polygon": [
            34.11,
            2.661,
            7.912,
            33.953,
            1.375,
            7.912,
            33.953,
            1.375,
            11.118,
            33.953,
            1.375,
            11.118,
            34.11,
            2.661,
            11.118,
            34.11,
            2.661,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_12",
        "polygon": [
            33.771,
            -0.118,
            7.912,
            33.614,
            -1.405,
            7.912,
            33.614,
            -1.405,
            11.118,
            33.614,
            -1.405,
            11.118,
            33.771,
            -0.118,
            11.118,
            33.771,
            -0.118,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_10",
        "polygon": [
            33.432,
            -2.898,
            7.912,
            33.275,
            -4.184,
            7.912,
            33.275,
            -4.184,
            11.118,
            33.275,
            -4.184,
            11.118,
            33.432,
            -2.898,
            11.118,
            33.432,
            -2.898,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_8",
        "polygon": [
            33.093,
            -5.677,
            7.912,
            32.936,
            -6.963,
            7.912,
            32.936,
            -6.963,
            11.118,
            32.936,
            -6.963,
            11.118,
            33.093,
            -5.677,
            11.118,
            33.093,
            -5.677,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_6",
        "polygon": [
            32.754,
            -8.456,
            7.912,
            32.597,
            -9.743,
            7.912,
            32.597,
            -9.743,
            11.118,
            32.597,
            -9.743,
            11.118,
            32.754,
            -8.456,
            11.118,
            32.754,
            -8.456,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_4",
        "polygon": [
            32.415,
            -11.236,
            7.912,
            32.258,
            -12.522,
            7.912,
            32.258,
            -12.522,
            11.118,
            32.258,
            -12.522,
            11.118,
            32.415,
            -11.236,
            11.118,
            32.415,
            -11.236,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_2",
        "polygon": [
            32.076,
            -14.015,
            7.912,
            31.919,
            -15.302,
            7.912,
            31.919,
            -15.302,
            11.118,
            31.919,
            -15.302,
            11.118,
            32.076,
            -14.015,
            11.118,
            32.076,
            -14.015,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_678",
        "polygon": [
            -106.999,
            93.795,
            4.14,
            -106.62,
            93.653,
            4.14,
            -106.62,
            93.653,
            6.988,
            -106.62,
            93.653,
            6.988,
            -106.999,
            93.795,
            6.988,
            -106.999,
            93.795,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_679",
        "polygon": [
            -106.999,
            93.795,
            6.988,
            -106.62,
            93.653,
            6.988,
            -106.62,
            93.653,
            7.408,
            -106.62,
            93.653,
            7.408,
            -106.999,
            93.795,
            7.408,
            -106.999,
            93.795,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_680",
        "polygon": [
            -108.479,
            94.352,
            4.202,
            -107.096,
            93.832,
            4.202,
            -107.096,
            93.832,
            7.408,
            -107.096,
            93.832,
            7.408,
            -108.479,
            94.352,
            7.408,
            -108.479,
            94.352,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_681",
        "polygon": [
            -106.58,
            93.638,
            4.201,
            -105.786,
            93.339,
            4.201,
            -105.786,
            93.339,
            7.407,
            -105.786,
            93.339,
            7.407,
            -106.58,
            93.638,
            7.407,
            -106.58,
            93.638,
            4.201
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_682",
        "polygon": [
            -105.688,
            93.303,
            4.202,
            -104.475,
            92.847,
            4.202,
            -104.475,
            92.847,
            7.408,
            -104.475,
            92.847,
            7.408,
            -105.688,
            93.303,
            7.408,
            -105.688,
            93.303,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_683",
        "polygon": [
            -104.378,
            92.81,
            4.14,
            -103.999,
            92.668,
            4.14,
            -103.999,
            92.668,
            6.988,
            -103.999,
            92.668,
            6.988,
            -104.378,
            92.81,
            6.988,
            -104.378,
            92.81,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_684",
        "polygon": [
            -104.378,
            92.81,
            6.988,
            -103.999,
            92.668,
            6.988,
            -103.999,
            92.668,
            7.408,
            -103.999,
            92.668,
            7.408,
            -104.378,
            92.81,
            7.408,
            -104.378,
            92.81,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_685",
        "polygon": [
            -103.959,
            92.653,
            4.201,
            -103.165,
            92.354,
            4.201,
            -103.165,
            92.354,
            7.407,
            -103.165,
            92.354,
            7.407,
            -103.959,
            92.653,
            7.407,
            -103.959,
            92.653,
            4.201
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_686",
        "polygon": [
            -103.067,
            92.317,
            4.202,
            -101.854,
            91.861,
            4.202,
            -101.854,
            91.861,
            7.408,
            -101.854,
            91.861,
            7.408,
            -103.067,
            92.317,
            7.408,
            -103.067,
            92.317,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_687",
        "polygon": [
            -101.757,
            91.825,
            4.14,
            -101.378,
            91.682,
            4.14,
            -101.378,
            91.682,
            6.988,
            -101.378,
            91.682,
            6.988,
            -101.757,
            91.825,
            6.988,
            -101.757,
            91.825,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_688",
        "polygon": [
            -101.757,
            91.825,
            6.988,
            -101.378,
            91.682,
            6.988,
            -101.378,
            91.682,
            7.408,
            -101.378,
            91.682,
            7.408,
            -101.757,
            91.825,
            7.408,
            -101.757,
            91.825,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_689",
        "polygon": [
            -101.338,
            91.667,
            4.201,
            -100.544,
            91.369,
            4.201,
            -100.544,
            91.369,
            7.407,
            -100.544,
            91.369,
            7.407,
            -101.338,
            91.667,
            7.407,
            -101.338,
            91.667,
            4.201
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_690",
        "polygon": [
            -100.446,
            91.332,
            4.202,
            -99.233,
            90.876,
            4.202,
            -99.233,
            90.876,
            7.408,
            -99.233,
            90.876,
            7.408,
            -100.446,
            91.332,
            7.408,
            -100.446,
            91.332,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_691",
        "polygon": [
            -99.136,
            90.839,
            4.14,
            -98.757,
            90.697,
            4.14,
            -98.757,
            90.697,
            6.988,
            -98.757,
            90.697,
            6.988,
            -99.136,
            90.839,
            6.988,
            -99.136,
            90.839,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_692",
        "polygon": [
            -99.136,
            90.839,
            6.988,
            -98.757,
            90.697,
            6.988,
            -98.757,
            90.697,
            7.408,
            -98.757,
            90.697,
            7.408,
            -99.136,
            90.839,
            7.408,
            -99.136,
            90.839,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_693",
        "polygon": [
            -98.718,
            90.682,
            4.201,
            -97.923,
            90.383,
            4.201,
            -97.923,
            90.383,
            7.407,
            -97.923,
            90.383,
            7.407,
            -98.718,
            90.682,
            7.407,
            -98.718,
            90.682,
            4.201
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_694",
        "polygon": [
            -97.826,
            90.347,
            4.202,
            -96.612,
            89.891,
            4.202,
            -96.612,
            89.891,
            7.408,
            -96.612,
            89.891,
            7.408,
            -97.826,
            90.347,
            7.408,
            -97.826,
            90.347,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_695",
        "polygon": [
            -96.515,
            89.854,
            4.14,
            -96.136,
            89.712,
            4.14,
            -96.136,
            89.712,
            6.988,
            -96.136,
            89.712,
            6.988,
            -96.515,
            89.854,
            6.988,
            -96.515,
            89.854,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_696",
        "polygon": [
            -96.515,
            89.854,
            6.988,
            -96.136,
            89.712,
            6.988,
            -96.136,
            89.712,
            7.408,
            -96.136,
            89.712,
            7.408,
            -96.515,
            89.854,
            7.408,
            -96.515,
            89.854,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_697",
        "polygon": [
            -96.097,
            89.697,
            4.201,
            -95.302,
            89.398,
            4.201,
            -95.302,
            89.398,
            7.407,
            -95.302,
            89.398,
            7.407,
            -96.097,
            89.697,
            7.407,
            -96.097,
            89.697,
            4.201
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_698",
        "polygon": [
            -95.205,
            89.361,
            4.202,
            -93.821,
            88.841,
            4.202,
            -93.821,
            88.841,
            7.408,
            -93.821,
            88.841,
            7.408,
            -95.205,
            89.361,
            7.408,
            -95.205,
            89.361,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_699",
        "polygon": [
            -106.165,
            93.482,
            7.85,
            -105.786,
            93.339,
            7.85,
            -105.786,
            93.339,
            10.698,
            -105.786,
            93.339,
            10.698,
            -106.165,
            93.482,
            10.698,
            -106.165,
            93.482,
            7.85
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_700",
        "polygon": [
            -106.165,
            93.482,
            10.698,
            -105.786,
            93.339,
            10.698,
            -105.786,
            93.339,
            11.118,
            -105.786,
            93.339,
            11.118,
            -106.165,
            93.482,
            11.118,
            -106.165,
            93.482,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_701",
        "polygon": [
            -108.479,
            94.352,
            7.912,
            -107.096,
            93.832,
            7.912,
            -107.096,
            93.832,
            11.118,
            -107.096,
            93.832,
            11.118,
            -108.479,
            94.352,
            11.118,
            -108.479,
            94.352,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_702",
        "polygon": [
            -106.999,
            93.795,
            7.911,
            -106.204,
            93.497,
            7.911,
            -106.204,
            93.497,
            11.117,
            -106.204,
            93.497,
            11.117,
            -106.999,
            93.795,
            11.117,
            -106.999,
            93.795,
            7.911
        ],
        "05-24 04:15": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_703",
        "polygon": [
            -105.688,
            93.303,
            7.912,
            -104.475,
            92.847,
            7.912,
            -104.475,
            92.847,
            11.118,
            -104.475,
            92.847,
            11.118,
            -105.688,
            93.303,
            11.118,
            -105.688,
            93.303,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_704",
        "polygon": [
            -95.205,
            89.361,
            7.912,
            -93.821,
            88.841,
            7.912,
            -93.821,
            88.841,
            11.118,
            -93.821,
            88.841,
            11.118,
            -95.205,
            89.361,
            11.118,
            -95.205,
            89.361,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_705",
        "polygon": [
            -103.544,
            92.497,
            7.85,
            -103.165,
            92.354,
            7.85,
            -103.165,
            92.354,
            10.698,
            -103.165,
            92.354,
            10.698,
            -103.544,
            92.497,
            10.698,
            -103.544,
            92.497,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_706",
        "polygon": [
            -103.544,
            92.497,
            10.698,
            -103.165,
            92.354,
            10.698,
            -103.165,
            92.354,
            11.118,
            -103.165,
            92.354,
            11.118,
            -103.544,
            92.497,
            11.118,
            -103.544,
            92.497,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_707",
        "polygon": [
            -104.378,
            92.81,
            7.911,
            -103.583,
            92.511,
            7.911,
            -103.583,
            92.511,
            11.117,
            -103.583,
            92.511,
            11.117,
            -104.378,
            92.81,
            11.117,
            -104.378,
            92.81,
            7.911
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_708",
        "polygon": [
            -103.067,
            92.317,
            7.912,
            -101.854,
            91.861,
            7.912,
            -101.854,
            91.861,
            11.118,
            -101.854,
            91.861,
            11.118,
            -103.067,
            92.317,
            11.118,
            -103.067,
            92.317,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_709",
        "polygon": [
            -100.923,
            91.511,
            7.85,
            -100.544,
            91.369,
            7.85,
            -100.544,
            91.369,
            10.698,
            -100.544,
            91.369,
            10.698,
            -100.923,
            91.511,
            10.698,
            -100.923,
            91.511,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_710",
        "polygon": [
            -100.923,
            91.511,
            10.698,
            -100.544,
            91.369,
            10.698,
            -100.544,
            91.369,
            11.118,
            -100.544,
            91.369,
            11.118,
            -100.923,
            91.511,
            11.118,
            -100.923,
            91.511,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_711",
        "polygon": [
            -101.757,
            91.825,
            7.911,
            -100.962,
            91.526,
            7.911,
            -100.962,
            91.526,
            11.117,
            -100.962,
            91.526,
            11.117,
            -101.757,
            91.825,
            11.117,
            -101.757,
            91.825,
            7.911
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_712",
        "polygon": [
            -100.446,
            91.332,
            7.912,
            -99.233,
            90.876,
            7.912,
            -99.233,
            90.876,
            11.118,
            -99.233,
            90.876,
            11.118,
            -100.446,
            91.332,
            11.118,
            -100.446,
            91.332,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_713",
        "polygon": [
            -98.302,
            90.526,
            7.85,
            -97.923,
            90.383,
            7.85,
            -97.923,
            90.383,
            10.698,
            -97.923,
            90.383,
            10.698,
            -98.302,
            90.526,
            10.698,
            -98.302,
            90.526,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_714",
        "polygon": [
            -98.302,
            90.526,
            10.698,
            -97.923,
            90.383,
            10.698,
            -97.923,
            90.383,
            11.118,
            -97.923,
            90.383,
            11.118,
            -98.302,
            90.526,
            11.118,
            -98.302,
            90.526,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_715",
        "polygon": [
            -99.136,
            90.839,
            7.911,
            -98.341,
            90.541,
            7.911,
            -98.341,
            90.541,
            11.117,
            -98.341,
            90.541,
            11.117,
            -99.136,
            90.839,
            11.117,
            -99.136,
            90.839,
            7.911
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_716",
        "polygon": [
            -97.826,
            90.347,
            7.912,
            -96.612,
            89.891,
            7.912,
            -96.612,
            89.891,
            11.118,
            -96.612,
            89.891,
            11.118,
            -97.826,
            90.347,
            11.118,
            -97.826,
            90.347,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_717",
        "polygon": [
            -95.681,
            89.541,
            7.85,
            -95.302,
            89.398,
            7.85,
            -95.302,
            89.398,
            10.698,
            -95.302,
            89.398,
            10.698,
            -95.681,
            89.541,
            10.698,
            -95.681,
            89.541,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_718",
        "polygon": [
            -95.681,
            89.541,
            10.698,
            -95.302,
            89.398,
            10.698,
            -95.302,
            89.398,
            11.118,
            -95.302,
            89.398,
            11.118,
            -95.681,
            89.541,
            11.118,
            -95.681,
            89.541,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_719",
        "polygon": [
            -96.515,
            89.854,
            7.911,
            -95.72,
            89.555,
            7.911,
            -95.72,
            89.555,
            11.117,
            -95.72,
            89.555,
            11.117,
            -96.515,
            89.854,
            11.117,
            -96.515,
            89.854,
            7.911
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_11",
        "polygon": [
            -112.914,
            82.558,
            11.622,
            -111.53,
            82.038,
            11.622,
            -111.53,
            82.038,
            14.828,
            -111.53,
            82.038,
            14.828,
            -112.914,
            82.558,
            14.828,
            -112.914,
            82.558,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_1",
        "polygon": [
            -99.639,
            77.567,
            11.622,
            -98.255,
            77.047,
            11.622,
            -98.255,
            77.047,
            14.828,
            -98.255,
            77.047,
            14.828,
            -99.639,
            77.567,
            14.828,
            -99.639,
            77.567,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_9",
        "polygon": [
            -110.122,
            81.509,
            11.622,
            -108.909,
            81.053,
            11.622,
            -108.909,
            81.053,
            14.828,
            -108.909,
            81.053,
            14.828,
            -110.122,
            81.509,
            14.828,
            -110.122,
            81.509,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_7",
        "polygon": [
            -107.501,
            80.523,
            11.622,
            -106.288,
            80.067,
            11.622,
            -106.288,
            80.067,
            14.828,
            -106.288,
            80.067,
            14.828,
            -107.501,
            80.523,
            14.828,
            -107.501,
            80.523,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_5",
        "polygon": [
            -104.88,
            79.538,
            11.622,
            -103.667,
            79.082,
            11.622,
            -103.667,
            79.082,
            14.828,
            -103.667,
            79.082,
            14.828,
            -104.88,
            79.538,
            14.828,
            -104.88,
            79.538,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_3",
        "polygon": [
            -102.26,
            78.553,
            11.622,
            -101.047,
            78.097,
            11.622,
            -101.047,
            78.097,
            14.828,
            -101.047,
            78.097,
            14.828,
            -102.26,
            78.553,
            14.828,
            -102.26,
            78.553,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_738",
        "polygon": [
            -72.927,
            80.986,
            4.14,
            -72.548,
            80.843,
            4.14,
            -72.548,
            80.843,
            6.988,
            -72.548,
            80.843,
            6.988,
            -72.927,
            80.986,
            6.988,
            -72.927,
            80.986,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_739",
        "polygon": [
            -72.927,
            80.986,
            6.988,
            -72.548,
            80.843,
            6.988,
            -72.548,
            80.843,
            7.408,
            -72.548,
            80.843,
            7.408,
            -72.927,
            80.986,
            7.408,
            -72.927,
            80.986,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_740",
        "polygon": [
            -74.408,
            81.543,
            4.202,
            -73.024,
            81.023,
            4.202,
            -73.024,
            81.023,
            7.408,
            -73.024,
            81.023,
            7.408,
            -74.408,
            81.543,
            7.408,
            -74.408,
            81.543,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_741",
        "polygon": [
            -72.509,
            80.829,
            4.202,
            -71.714,
            80.53,
            4.202,
            -71.714,
            80.53,
            7.408,
            -71.714,
            80.53,
            7.408,
            -72.509,
            80.829,
            7.408,
            -72.509,
            80.829,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_742",
        "polygon": [
            -71.617,
            80.493,
            4.202,
            -70.403,
            80.037,
            4.202,
            -70.403,
            80.037,
            7.408,
            -70.403,
            80.037,
            7.408,
            -71.617,
            80.493,
            7.408,
            -71.617,
            80.493,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_743",
        "polygon": [
            -70.306,
            80.001,
            4.14,
            -69.927,
            79.858,
            4.14,
            -69.927,
            79.858,
            6.988,
            -69.927,
            79.858,
            6.988,
            -70.306,
            80.001,
            6.988,
            -70.306,
            80.001,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_744",
        "polygon": [
            -70.306,
            80.001,
            6.988,
            -69.927,
            79.858,
            6.988,
            -69.927,
            79.858,
            7.408,
            -69.927,
            79.858,
            7.408,
            -70.306,
            80.001,
            7.408,
            -70.306,
            80.001,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_745",
        "polygon": [
            -69.888,
            79.843,
            4.202,
            -69.093,
            79.545,
            4.202,
            -69.093,
            79.545,
            7.408,
            -69.093,
            79.545,
            7.408,
            -69.888,
            79.843,
            7.408,
            -69.888,
            79.843,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_746",
        "polygon": [
            -68.996,
            79.508,
            4.202,
            -67.783,
            79.052,
            4.202,
            -67.783,
            79.052,
            7.408,
            -67.783,
            79.052,
            7.408,
            -68.996,
            79.508,
            7.408,
            -68.996,
            79.508,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_747",
        "polygon": [
            -67.685,
            79.015,
            4.14,
            -67.306,
            78.873,
            4.14,
            -67.306,
            78.873,
            6.988,
            -67.306,
            78.873,
            6.988,
            -67.685,
            79.015,
            6.988,
            -67.685,
            79.015,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_748",
        "polygon": [
            -67.685,
            79.015,
            6.988,
            -67.306,
            78.873,
            6.988,
            -67.306,
            78.873,
            7.408,
            -67.306,
            78.873,
            7.408,
            -67.685,
            79.015,
            7.408,
            -67.685,
            79.015,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_749",
        "polygon": [
            -67.267,
            78.858,
            4.202,
            -66.472,
            78.559,
            4.202,
            -66.472,
            78.559,
            7.408,
            -66.472,
            78.559,
            7.408,
            -67.267,
            78.858,
            7.408,
            -67.267,
            78.858,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_750",
        "polygon": [
            -66.375,
            78.523,
            4.202,
            -65.162,
            78.066,
            4.202,
            -65.162,
            78.066,
            7.408,
            -65.162,
            78.066,
            7.408,
            -66.375,
            78.523,
            7.408,
            -66.375,
            78.523,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_751",
        "polygon": [
            -65.064,
            78.03,
            4.14,
            -64.685,
            77.887,
            4.14,
            -64.685,
            77.887,
            6.988,
            -64.685,
            77.887,
            6.988,
            -65.064,
            78.03,
            6.988,
            -65.064,
            78.03,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_752",
        "polygon": [
            -65.064,
            78.03,
            6.988,
            -64.685,
            77.887,
            6.988,
            -64.685,
            77.887,
            7.408,
            -64.685,
            77.887,
            7.408,
            -65.064,
            78.03,
            7.408,
            -65.064,
            78.03,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_753",
        "polygon": [
            -64.646,
            77.873,
            4.202,
            -63.851,
            77.574,
            4.202,
            -63.851,
            77.574,
            7.408,
            -63.851,
            77.574,
            7.408,
            -64.646,
            77.873,
            7.408,
            -64.646,
            77.873,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_754",
        "polygon": [
            -63.754,
            77.537,
            4.202,
            -62.541,
            77.081,
            4.202,
            -62.541,
            77.081,
            7.408,
            -62.541,
            77.081,
            7.408,
            -63.754,
            77.537,
            7.408,
            -63.754,
            77.537,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_755",
        "polygon": [
            -62.443,
            77.045,
            4.14,
            -62.064,
            76.902,
            4.14,
            -62.064,
            76.902,
            6.988,
            -62.064,
            76.902,
            6.988,
            -62.443,
            77.045,
            6.988,
            -62.443,
            77.045,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_756",
        "polygon": [
            -62.443,
            77.045,
            6.988,
            -62.064,
            76.902,
            6.988,
            -62.064,
            76.902,
            7.408,
            -62.064,
            76.902,
            7.408,
            -62.443,
            77.045,
            7.408,
            -62.443,
            77.045,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_757",
        "polygon": [
            -62.025,
            76.887,
            4.202,
            -61.23,
            76.588,
            4.202,
            -61.23,
            76.588,
            7.408,
            -61.23,
            76.588,
            7.408,
            -62.025,
            76.887,
            7.408,
            -62.025,
            76.887,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_758",
        "polygon": [
            -61.133,
            76.552,
            4.202,
            -59.92,
            76.096,
            4.202,
            -59.92,
            76.096,
            7.408,
            -59.92,
            76.096,
            7.408,
            -61.133,
            76.552,
            7.408,
            -61.133,
            76.552,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_759",
        "polygon": [
            -59.823,
            76.059,
            4.14,
            -59.443,
            75.917,
            4.14,
            -59.443,
            75.917,
            6.988,
            -59.443,
            75.917,
            6.988,
            -59.823,
            76.059,
            6.988,
            -59.823,
            76.059,
            4.14
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_760",
        "polygon": [
            -59.823,
            76.059,
            6.988,
            -59.443,
            75.917,
            6.988,
            -59.443,
            75.917,
            7.408,
            -59.443,
            75.917,
            7.408,
            -59.823,
            76.059,
            7.408,
            -59.823,
            76.059,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_761",
        "polygon": [
            -59.404,
            75.902,
            4.202,
            -58.609,
            75.603,
            4.202,
            -58.609,
            75.603,
            7.408,
            -58.609,
            75.603,
            7.408,
            -59.404,
            75.902,
            7.408,
            -59.404,
            75.902,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_762",
        "polygon": [
            -58.512,
            75.567,
            4.202,
            -57.299,
            75.11,
            4.202,
            -57.299,
            75.11,
            7.408,
            -57.299,
            75.11,
            7.408,
            -58.512,
            75.567,
            7.408,
            -58.512,
            75.567,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_763",
        "polygon": [
            -57.202,
            75.074,
            4.14,
            -56.823,
            74.931,
            4.14,
            -56.823,
            74.931,
            6.988,
            -56.823,
            74.931,
            6.988,
            -57.202,
            75.074,
            6.988,
            -57.202,
            75.074,
            4.14
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_764",
        "polygon": [
            -57.202,
            75.074,
            6.988,
            -56.823,
            74.931,
            6.988,
            -56.823,
            74.931,
            7.408,
            -56.823,
            74.931,
            7.408,
            -57.202,
            75.074,
            7.408,
            -57.202,
            75.074,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_765",
        "polygon": [
            -56.783,
            74.917,
            4.202,
            -55.989,
            74.618,
            4.202,
            -55.989,
            74.618,
            7.408,
            -55.989,
            74.618,
            7.408,
            -56.783,
            74.917,
            7.408,
            -56.783,
            74.917,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_766",
        "polygon": [
            -55.891,
            74.581,
            4.202,
            -54.678,
            74.125,
            4.202,
            -54.678,
            74.125,
            7.408,
            -54.678,
            74.125,
            7.408,
            -55.891,
            74.581,
            7.408,
            -55.891,
            74.581,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_767",
        "polygon": [
            -54.581,
            74.088,
            4.14,
            -54.202,
            73.946,
            4.14,
            -54.202,
            73.946,
            6.988,
            -54.202,
            73.946,
            6.988,
            -54.581,
            74.088,
            6.988,
            -54.581,
            74.088,
            4.14
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_768",
        "polygon": [
            -54.581,
            74.088,
            6.988,
            -54.202,
            73.946,
            6.988,
            -54.202,
            73.946,
            7.408,
            -54.202,
            73.946,
            7.408,
            -54.581,
            74.088,
            7.408,
            -54.581,
            74.088,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_769",
        "polygon": [
            -54.162,
            73.931,
            4.202,
            -53.368,
            73.632,
            4.202,
            -53.368,
            73.632,
            7.408,
            -53.368,
            73.632,
            7.408,
            -54.162,
            73.931,
            7.408,
            -54.162,
            73.931,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_770",
        "polygon": [
            -53.27,
            73.596,
            4.202,
            -52.057,
            73.14,
            4.202,
            -52.057,
            73.14,
            7.408,
            -52.057,
            73.14,
            7.408,
            -53.27,
            73.596,
            7.408,
            -53.27,
            73.596,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_771",
        "polygon": [
            -51.96,
            73.103,
            4.14,
            -51.581,
            72.961,
            4.14,
            -51.581,
            72.961,
            6.988,
            -51.581,
            72.961,
            6.988,
            -51.96,
            73.103,
            6.988,
            -51.96,
            73.103,
            4.14
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_772",
        "polygon": [
            -51.96,
            73.103,
            6.988,
            -51.581,
            72.961,
            6.988,
            -51.581,
            72.961,
            7.408,
            -51.581,
            72.961,
            7.408,
            -51.96,
            73.103,
            7.408,
            -51.96,
            73.103,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_773",
        "polygon": [
            -51.541,
            72.946,
            4.202,
            -50.747,
            72.647,
            4.202,
            -50.747,
            72.647,
            7.408,
            -50.747,
            72.647,
            7.408,
            -51.541,
            72.946,
            7.408,
            -51.541,
            72.946,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_774",
        "polygon": [
            -50.649,
            72.61,
            4.202,
            -49.436,
            72.154,
            4.202,
            -49.436,
            72.154,
            7.408,
            -49.436,
            72.154,
            7.408,
            -50.649,
            72.61,
            7.408,
            -50.649,
            72.61,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_775",
        "polygon": [
            -49.339,
            72.118,
            4.14,
            -48.96,
            71.975,
            4.14,
            -48.96,
            71.975,
            6.988,
            -48.96,
            71.975,
            6.988,
            -49.339,
            72.118,
            6.988,
            -49.339,
            72.118,
            4.14
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_776",
        "polygon": [
            -49.339,
            72.118,
            6.988,
            -48.96,
            71.975,
            6.988,
            -48.96,
            71.975,
            7.408,
            -48.96,
            71.975,
            7.408,
            -49.339,
            72.118,
            7.408,
            -49.339,
            72.118,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_777",
        "polygon": [
            -48.921,
            71.96,
            4.202,
            -48.126,
            71.662,
            4.202,
            -48.126,
            71.662,
            7.408,
            -48.126,
            71.662,
            7.408,
            -48.921,
            71.96,
            7.408,
            -48.921,
            71.96,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_778",
        "polygon": [
            -48.029,
            71.625,
            4.202,
            -46.815,
            71.169,
            4.202,
            -46.815,
            71.169,
            7.408,
            -46.815,
            71.169,
            7.408,
            -48.029,
            71.625,
            7.408,
            -48.029,
            71.625,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_779",
        "polygon": [
            -46.718,
            71.132,
            4.14,
            -46.339,
            70.99,
            4.14,
            -46.339,
            70.99,
            6.988,
            -46.339,
            70.99,
            6.988,
            -46.718,
            71.132,
            6.988,
            -46.718,
            71.132,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_780",
        "polygon": [
            -46.718,
            71.132,
            6.988,
            -46.339,
            70.99,
            6.988,
            -46.339,
            70.99,
            7.408,
            -46.339,
            70.99,
            7.408,
            -46.718,
            71.132,
            7.408,
            -46.718,
            71.132,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_781",
        "polygon": [
            -46.3,
            70.975,
            4.202,
            -45.505,
            70.676,
            4.202,
            -45.505,
            70.676,
            7.408,
            -45.505,
            70.676,
            7.408,
            -46.3,
            70.975,
            7.408,
            -46.3,
            70.975,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_782",
        "polygon": [
            -45.408,
            70.64,
            4.202,
            -44.195,
            70.184,
            4.202,
            -44.195,
            70.184,
            7.408,
            -44.195,
            70.184,
            7.408,
            -45.408,
            70.64,
            7.408,
            -45.408,
            70.64,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_783",
        "polygon": [
            -44.097,
            70.147,
            4.14,
            -43.718,
            70.005,
            4.14,
            -43.718,
            70.005,
            6.988,
            -43.718,
            70.005,
            6.988,
            -44.097,
            70.147,
            6.988,
            -44.097,
            70.147,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_784",
        "polygon": [
            -44.097,
            70.147,
            6.988,
            -43.718,
            70.005,
            6.988,
            -43.718,
            70.005,
            7.408,
            -43.718,
            70.005,
            7.408,
            -44.097,
            70.147,
            7.408,
            -44.097,
            70.147,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_785",
        "polygon": [
            -43.679,
            69.99,
            4.202,
            -42.884,
            69.691,
            4.202,
            -42.884,
            69.691,
            7.408,
            -42.884,
            69.691,
            7.408,
            -43.679,
            69.99,
            7.408,
            -43.679,
            69.99,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_786",
        "polygon": [
            -42.787,
            69.654,
            4.202,
            -41.574,
            69.198,
            4.202,
            -41.574,
            69.198,
            7.408,
            -41.574,
            69.198,
            7.408,
            -42.787,
            69.654,
            7.408,
            -42.787,
            69.654,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_787",
        "polygon": [
            -41.476,
            69.162,
            4.14,
            -41.097,
            69.019,
            4.14,
            -41.097,
            69.019,
            6.988,
            -41.097,
            69.019,
            6.988,
            -41.476,
            69.162,
            6.988,
            -41.476,
            69.162,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_788",
        "polygon": [
            -41.476,
            69.162,
            6.988,
            -41.097,
            69.019,
            6.988,
            -41.097,
            69.019,
            7.408,
            -41.097,
            69.019,
            7.408,
            -41.476,
            69.162,
            7.408,
            -41.476,
            69.162,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_789",
        "polygon": [
            -41.058,
            69.004,
            4.202,
            -40.263,
            68.706,
            4.202,
            -40.263,
            68.706,
            7.408,
            -40.263,
            68.706,
            7.408,
            -41.058,
            69.004,
            7.408,
            -41.058,
            69.004,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_790",
        "polygon": [
            -40.166,
            68.669,
            4.202,
            -38.953,
            68.213,
            4.202,
            -38.953,
            68.213,
            7.408,
            -38.953,
            68.213,
            7.408,
            -40.166,
            68.669,
            7.408,
            -40.166,
            68.669,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_791",
        "polygon": [
            -38.855,
            68.176,
            4.14,
            -38.476,
            68.034,
            4.14,
            -38.476,
            68.034,
            6.988,
            -38.476,
            68.034,
            6.988,
            -38.855,
            68.176,
            6.988,
            -38.855,
            68.176,
            4.14
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_792",
        "polygon": [
            -38.855,
            68.176,
            6.988,
            -38.476,
            68.034,
            6.988,
            -38.476,
            68.034,
            7.408,
            -38.476,
            68.034,
            7.408,
            -38.855,
            68.176,
            7.408,
            -38.855,
            68.176,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_793",
        "polygon": [
            -38.437,
            68.019,
            4.202,
            -37.642,
            67.72,
            4.202,
            -37.642,
            67.72,
            7.408,
            -37.642,
            67.72,
            7.408,
            -38.437,
            68.019,
            7.408,
            -38.437,
            68.019,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_794",
        "polygon": [
            -37.545,
            67.684,
            4.202,
            -36.332,
            67.228,
            4.202,
            -36.332,
            67.228,
            7.408,
            -36.332,
            67.228,
            7.408,
            -37.545,
            67.684,
            7.408,
            -37.545,
            67.684,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_795",
        "polygon": [
            -36.235,
            67.191,
            4.14,
            -35.855,
            67.049,
            4.14,
            -35.855,
            67.049,
            6.988,
            -35.855,
            67.049,
            6.988,
            -36.235,
            67.191,
            6.988,
            -36.235,
            67.191,
            4.14
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_796",
        "polygon": [
            -36.235,
            67.191,
            6.988,
            -35.855,
            67.049,
            6.988,
            -35.855,
            67.049,
            7.408,
            -35.855,
            67.049,
            7.408,
            -36.235,
            67.191,
            7.408,
            -36.235,
            67.191,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_797",
        "polygon": [
            -35.816,
            67.034,
            4.202,
            -35.021,
            66.735,
            4.202,
            -35.021,
            66.735,
            7.408,
            -35.021,
            66.735,
            7.408,
            -35.816,
            67.034,
            7.408,
            -35.816,
            67.034,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_798",
        "polygon": [
            -34.924,
            66.698,
            4.202,
            -33.711,
            66.242,
            4.202,
            -33.711,
            66.242,
            7.408,
            -33.711,
            66.242,
            7.408,
            -34.924,
            66.698,
            7.408,
            -34.924,
            66.698,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_799",
        "polygon": [
            -33.614,
            66.206,
            4.14,
            -33.235,
            66.063,
            4.14,
            -33.235,
            66.063,
            6.988,
            -33.235,
            66.063,
            6.988,
            -33.614,
            66.206,
            6.988,
            -33.614,
            66.206,
            4.14
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_800",
        "polygon": [
            -33.614,
            66.206,
            6.988,
            -33.235,
            66.063,
            6.988,
            -33.235,
            66.063,
            7.408,
            -33.235,
            66.063,
            7.408,
            -33.614,
            66.206,
            7.408,
            -33.614,
            66.206,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_801",
        "polygon": [
            -33.195,
            66.048,
            4.202,
            -32.401,
            65.75,
            4.202,
            -32.401,
            65.75,
            7.408,
            -32.401,
            65.75,
            7.408,
            -33.195,
            66.048,
            7.408,
            -33.195,
            66.048,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_802",
        "polygon": [
            -32.303,
            65.713,
            4.202,
            -31.09,
            65.257,
            4.202,
            -31.09,
            65.257,
            7.408,
            -31.09,
            65.257,
            7.408,
            -32.303,
            65.713,
            7.408,
            -32.303,
            65.713,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_803",
        "polygon": [
            -30.993,
            65.22,
            4.14,
            -30.614,
            65.078,
            4.14,
            -30.614,
            65.078,
            6.988,
            -30.614,
            65.078,
            6.988,
            -30.993,
            65.22,
            6.988,
            -30.993,
            65.22,
            4.14
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_804",
        "polygon": [
            -30.993,
            65.22,
            6.988,
            -30.614,
            65.078,
            6.988,
            -30.614,
            65.078,
            7.408,
            -30.614,
            65.078,
            7.408,
            -30.993,
            65.22,
            7.408,
            -30.993,
            65.22,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_805",
        "polygon": [
            -30.574,
            65.063,
            4.202,
            -29.78,
            64.764,
            4.202,
            -29.78,
            64.764,
            7.408,
            -29.78,
            64.764,
            7.408,
            -30.574,
            65.063,
            7.408,
            -30.574,
            65.063,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_806",
        "polygon": [
            -29.682,
            64.728,
            4.202,
            -28.469,
            64.272,
            4.202,
            -28.469,
            64.272,
            7.408,
            -28.469,
            64.272,
            7.408,
            -29.682,
            64.728,
            7.408,
            -29.682,
            64.728,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_807",
        "polygon": [
            -28.372,
            64.235,
            4.14,
            -27.993,
            64.092,
            4.14,
            -27.993,
            64.092,
            6.988,
            -27.993,
            64.092,
            6.988,
            -28.372,
            64.235,
            6.988,
            -28.372,
            64.235,
            4.14
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_808",
        "polygon": [
            -28.372,
            64.235,
            6.988,
            -27.993,
            64.092,
            6.988,
            -27.993,
            64.092,
            7.408,
            -27.993,
            64.092,
            7.408,
            -28.372,
            64.235,
            7.408,
            -28.372,
            64.235,
            6.988
        ],
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_809",
        "polygon": [
            -27.953,
            64.078,
            4.202,
            -27.159,
            63.779,
            4.202,
            -27.159,
            63.779,
            7.408,
            -27.159,
            63.779,
            7.408,
            -27.953,
            64.078,
            7.408,
            -27.953,
            64.078,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_811",
        "polygon": [
            -27.061,
            63.742,
            4.202,
            -25.678,
            63.222,
            4.202,
            -25.678,
            63.222,
            7.408,
            -25.678,
            63.222,
            7.408,
            -27.061,
            63.742,
            7.408,
            -27.061,
            63.742,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_812",
        "polygon": [
            -72.927,
            80.986,
            11.56,
            -72.548,
            80.843,
            11.56,
            -72.548,
            80.843,
            14.408,
            -72.548,
            80.843,
            14.408,
            -72.927,
            80.986,
            14.408,
            -72.927,
            80.986,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_813",
        "polygon": [
            -72.927,
            80.986,
            14.408,
            -72.548,
            80.843,
            14.408,
            -72.548,
            80.843,
            14.828,
            -72.548,
            80.843,
            14.828,
            -72.927,
            80.986,
            14.828,
            -72.927,
            80.986,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_814",
        "polygon": [
            -74.408,
            81.543,
            11.622,
            -73.024,
            81.023,
            11.622,
            -73.024,
            81.023,
            14.828,
            -73.024,
            81.023,
            14.828,
            -74.408,
            81.543,
            14.828,
            -74.408,
            81.543,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_815",
        "polygon": [
            -72.509,
            80.829,
            11.622,
            -71.714,
            80.53,
            11.622,
            -71.714,
            80.53,
            14.828,
            -71.714,
            80.53,
            14.828,
            -72.509,
            80.829,
            14.828,
            -72.509,
            80.829,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_816",
        "polygon": [
            -71.617,
            80.493,
            11.622,
            -70.403,
            80.037,
            11.622,
            -70.403,
            80.037,
            14.828,
            -70.403,
            80.037,
            14.828,
            -71.617,
            80.493,
            14.828,
            -71.617,
            80.493,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_817",
        "polygon": [
            -70.306,
            80.001,
            11.56,
            -69.927,
            79.858,
            11.56,
            -69.927,
            79.858,
            14.408,
            -69.927,
            79.858,
            14.408,
            -70.306,
            80.001,
            14.408,
            -70.306,
            80.001,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_818",
        "polygon": [
            -70.306,
            80.001,
            14.408,
            -69.927,
            79.858,
            14.408,
            -69.927,
            79.858,
            14.828,
            -69.927,
            79.858,
            14.828,
            -70.306,
            80.001,
            14.828,
            -70.306,
            80.001,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_819",
        "polygon": [
            -69.888,
            79.843,
            11.622,
            -69.093,
            79.545,
            11.622,
            -69.093,
            79.545,
            14.828,
            -69.093,
            79.545,
            14.828,
            -69.888,
            79.843,
            14.828,
            -69.888,
            79.843,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_820",
        "polygon": [
            -68.996,
            79.508,
            11.622,
            -67.783,
            79.052,
            11.622,
            -67.783,
            79.052,
            14.828,
            -67.783,
            79.052,
            14.828,
            -68.996,
            79.508,
            14.828,
            -68.996,
            79.508,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_821",
        "polygon": [
            -67.685,
            79.015,
            11.56,
            -67.306,
            78.873,
            11.56,
            -67.306,
            78.873,
            14.408,
            -67.306,
            78.873,
            14.408,
            -67.685,
            79.015,
            14.408,
            -67.685,
            79.015,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_822",
        "polygon": [
            -67.685,
            79.015,
            14.408,
            -67.306,
            78.873,
            14.408,
            -67.306,
            78.873,
            14.828,
            -67.306,
            78.873,
            14.828,
            -67.685,
            79.015,
            14.828,
            -67.685,
            79.015,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_823",
        "polygon": [
            -67.267,
            78.858,
            11.622,
            -66.472,
            78.559,
            11.622,
            -66.472,
            78.559,
            14.828,
            -66.472,
            78.559,
            14.828,
            -67.267,
            78.858,
            14.828,
            -67.267,
            78.858,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_824",
        "polygon": [
            -66.375,
            78.523,
            11.622,
            -65.162,
            78.066,
            11.622,
            -65.162,
            78.066,
            14.828,
            -65.162,
            78.066,
            14.828,
            -66.375,
            78.523,
            14.828,
            -66.375,
            78.523,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_825",
        "polygon": [
            -65.064,
            78.03,
            11.56,
            -64.685,
            77.887,
            11.56,
            -64.685,
            77.887,
            14.408,
            -64.685,
            77.887,
            14.408,
            -65.064,
            78.03,
            14.408,
            -65.064,
            78.03,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_826",
        "polygon": [
            -65.064,
            78.03,
            14.408,
            -64.685,
            77.887,
            14.408,
            -64.685,
            77.887,
            14.828,
            -64.685,
            77.887,
            14.828,
            -65.064,
            78.03,
            14.828,
            -65.064,
            78.03,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_827",
        "polygon": [
            -64.646,
            77.873,
            11.622,
            -63.851,
            77.574,
            11.622,
            -63.851,
            77.574,
            14.828,
            -63.851,
            77.574,
            14.828,
            -64.646,
            77.873,
            14.828,
            -64.646,
            77.873,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_828",
        "polygon": [
            -63.754,
            77.537,
            11.622,
            -62.541,
            77.081,
            11.622,
            -62.541,
            77.081,
            14.828,
            -62.541,
            77.081,
            14.828,
            -63.754,
            77.537,
            14.828,
            -63.754,
            77.537,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_829",
        "polygon": [
            -62.443,
            77.045,
            11.56,
            -62.064,
            76.902,
            11.56,
            -62.064,
            76.902,
            14.408,
            -62.064,
            76.902,
            14.408,
            -62.443,
            77.045,
            14.408,
            -62.443,
            77.045,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_830",
        "polygon": [
            -62.443,
            77.045,
            14.408,
            -62.064,
            76.902,
            14.408,
            -62.064,
            76.902,
            14.828,
            -62.064,
            76.902,
            14.828,
            -62.443,
            77.045,
            14.828,
            -62.443,
            77.045,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_831",
        "polygon": [
            -62.025,
            76.887,
            11.622,
            -61.23,
            76.588,
            11.622,
            -61.23,
            76.588,
            14.828,
            -61.23,
            76.588,
            14.828,
            -62.025,
            76.887,
            14.828,
            -62.025,
            76.887,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_832",
        "polygon": [
            -61.133,
            76.552,
            11.622,
            -59.92,
            76.096,
            11.622,
            -59.92,
            76.096,
            14.828,
            -59.92,
            76.096,
            14.828,
            -61.133,
            76.552,
            14.828,
            -61.133,
            76.552,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_833",
        "polygon": [
            -59.823,
            76.059,
            11.56,
            -59.443,
            75.917,
            11.56,
            -59.443,
            75.917,
            14.408,
            -59.443,
            75.917,
            14.408,
            -59.823,
            76.059,
            14.408,
            -59.823,
            76.059,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_834",
        "polygon": [
            -59.823,
            76.059,
            14.408,
            -59.443,
            75.917,
            14.408,
            -59.443,
            75.917,
            14.828,
            -59.443,
            75.917,
            14.828,
            -59.823,
            76.059,
            14.828,
            -59.823,
            76.059,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_835",
        "polygon": [
            -59.404,
            75.902,
            11.622,
            -58.609,
            75.603,
            11.622,
            -58.609,
            75.603,
            14.828,
            -58.609,
            75.603,
            14.828,
            -59.404,
            75.902,
            14.828,
            -59.404,
            75.902,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_836",
        "polygon": [
            -58.512,
            75.567,
            11.622,
            -57.299,
            75.11,
            11.622,
            -57.299,
            75.11,
            14.828,
            -57.299,
            75.11,
            14.828,
            -58.512,
            75.567,
            14.828,
            -58.512,
            75.567,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_837",
        "polygon": [
            -57.202,
            75.074,
            11.56,
            -56.823,
            74.931,
            11.56,
            -56.823,
            74.931,
            14.408,
            -56.823,
            74.931,
            14.408,
            -57.202,
            75.074,
            14.408,
            -57.202,
            75.074,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_838",
        "polygon": [
            -57.202,
            75.074,
            14.408,
            -56.823,
            74.931,
            14.408,
            -56.823,
            74.931,
            14.828,
            -56.823,
            74.931,
            14.828,
            -57.202,
            75.074,
            14.828,
            -57.202,
            75.074,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_839",
        "polygon": [
            -56.783,
            74.917,
            11.622,
            -55.989,
            74.618,
            11.622,
            -55.989,
            74.618,
            14.828,
            -55.989,
            74.618,
            14.828,
            -56.783,
            74.917,
            14.828,
            -56.783,
            74.917,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_840",
        "polygon": [
            -55.891,
            74.581,
            11.622,
            -54.678,
            74.125,
            11.622,
            -54.678,
            74.125,
            14.828,
            -54.678,
            74.125,
            14.828,
            -55.891,
            74.581,
            14.828,
            -55.891,
            74.581,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_841",
        "polygon": [
            -54.581,
            74.088,
            11.56,
            -54.202,
            73.946,
            11.56,
            -54.202,
            73.946,
            14.408,
            -54.202,
            73.946,
            14.408,
            -54.581,
            74.088,
            14.408,
            -54.581,
            74.088,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_842",
        "polygon": [
            -54.581,
            74.088,
            14.408,
            -54.202,
            73.946,
            14.408,
            -54.202,
            73.946,
            14.828,
            -54.202,
            73.946,
            14.828,
            -54.581,
            74.088,
            14.828,
            -54.581,
            74.088,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_843",
        "polygon": [
            -54.162,
            73.931,
            11.622,
            -53.368,
            73.632,
            11.622,
            -53.368,
            73.632,
            14.828,
            -53.368,
            73.632,
            14.828,
            -54.162,
            73.931,
            14.828,
            -54.162,
            73.931,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_844",
        "polygon": [
            -53.27,
            73.596,
            11.622,
            -52.057,
            73.14,
            11.622,
            -52.057,
            73.14,
            14.828,
            -52.057,
            73.14,
            14.828,
            -53.27,
            73.596,
            14.828,
            -53.27,
            73.596,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_845",
        "polygon": [
            -51.96,
            73.103,
            11.56,
            -51.581,
            72.961,
            11.56,
            -51.581,
            72.961,
            14.408,
            -51.581,
            72.961,
            14.408,
            -51.96,
            73.103,
            14.408,
            -51.96,
            73.103,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_846",
        "polygon": [
            -51.96,
            73.103,
            14.408,
            -51.581,
            72.961,
            14.408,
            -51.581,
            72.961,
            14.828,
            -51.581,
            72.961,
            14.828,
            -51.96,
            73.103,
            14.828,
            -51.96,
            73.103,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_847",
        "polygon": [
            -51.541,
            72.946,
            11.622,
            -50.747,
            72.647,
            11.622,
            -50.747,
            72.647,
            14.828,
            -50.747,
            72.647,
            14.828,
            -51.541,
            72.946,
            14.828,
            -51.541,
            72.946,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_848",
        "polygon": [
            -50.649,
            72.61,
            11.622,
            -49.436,
            72.154,
            11.622,
            -49.436,
            72.154,
            14.828,
            -49.436,
            72.154,
            14.828,
            -50.649,
            72.61,
            14.828,
            -50.649,
            72.61,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_849",
        "polygon": [
            -49.339,
            72.118,
            11.56,
            -48.96,
            71.975,
            11.56,
            -48.96,
            71.975,
            14.408,
            -48.96,
            71.975,
            14.408,
            -49.339,
            72.118,
            14.408,
            -49.339,
            72.118,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_850",
        "polygon": [
            -49.339,
            72.118,
            14.408,
            -48.96,
            71.975,
            14.408,
            -48.96,
            71.975,
            14.828,
            -48.96,
            71.975,
            14.828,
            -49.339,
            72.118,
            14.828,
            -49.339,
            72.118,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_851",
        "polygon": [
            -48.921,
            71.96,
            11.622,
            -48.126,
            71.662,
            11.622,
            -48.126,
            71.662,
            14.828,
            -48.126,
            71.662,
            14.828,
            -48.921,
            71.96,
            14.828,
            -48.921,
            71.96,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_852",
        "polygon": [
            -48.029,
            71.625,
            11.622,
            -46.815,
            71.169,
            11.622,
            -46.815,
            71.169,
            14.828,
            -46.815,
            71.169,
            14.828,
            -48.029,
            71.625,
            14.828,
            -48.029,
            71.625,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_853",
        "polygon": [
            -46.718,
            71.132,
            11.56,
            -46.339,
            70.99,
            11.56,
            -46.339,
            70.99,
            14.408,
            -46.339,
            70.99,
            14.408,
            -46.718,
            71.132,
            14.408,
            -46.718,
            71.132,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_854",
        "polygon": [
            -46.718,
            71.132,
            14.408,
            -46.339,
            70.99,
            14.408,
            -46.339,
            70.99,
            14.828,
            -46.339,
            70.99,
            14.828,
            -46.718,
            71.132,
            14.828,
            -46.718,
            71.132,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_855",
        "polygon": [
            -46.3,
            70.975,
            11.622,
            -45.505,
            70.676,
            11.622,
            -45.505,
            70.676,
            14.828,
            -45.505,
            70.676,
            14.828,
            -46.3,
            70.975,
            14.828,
            -46.3,
            70.975,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_856",
        "polygon": [
            -45.408,
            70.64,
            11.622,
            -44.195,
            70.184,
            11.622,
            -44.195,
            70.184,
            14.828,
            -44.195,
            70.184,
            14.828,
            -45.408,
            70.64,
            14.828,
            -45.408,
            70.64,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_857",
        "polygon": [
            -44.097,
            70.147,
            11.56,
            -43.718,
            70.005,
            11.56,
            -43.718,
            70.005,
            14.408,
            -43.718,
            70.005,
            14.408,
            -44.097,
            70.147,
            14.408,
            -44.097,
            70.147,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_858",
        "polygon": [
            -44.097,
            70.147,
            14.408,
            -43.718,
            70.005,
            14.408,
            -43.718,
            70.005,
            14.828,
            -43.718,
            70.005,
            14.828,
            -44.097,
            70.147,
            14.828,
            -44.097,
            70.147,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_859",
        "polygon": [
            -43.679,
            69.99,
            11.622,
            -42.884,
            69.691,
            11.622,
            -42.884,
            69.691,
            14.828,
            -42.884,
            69.691,
            14.828,
            -43.679,
            69.99,
            14.828,
            -43.679,
            69.99,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_860",
        "polygon": [
            -42.787,
            69.654,
            11.622,
            -41.574,
            69.198,
            11.622,
            -41.574,
            69.198,
            14.828,
            -41.574,
            69.198,
            14.828,
            -42.787,
            69.654,
            14.828,
            -42.787,
            69.654,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_861",
        "polygon": [
            -41.476,
            69.162,
            11.56,
            -41.097,
            69.019,
            11.56,
            -41.097,
            69.019,
            14.408,
            -41.097,
            69.019,
            14.408,
            -41.476,
            69.162,
            14.408,
            -41.476,
            69.162,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_862",
        "polygon": [
            -41.476,
            69.162,
            14.408,
            -41.097,
            69.019,
            14.408,
            -41.097,
            69.019,
            14.828,
            -41.097,
            69.019,
            14.828,
            -41.476,
            69.162,
            14.828,
            -41.476,
            69.162,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_863",
        "polygon": [
            -41.058,
            69.004,
            11.622,
            -40.263,
            68.706,
            11.622,
            -40.263,
            68.706,
            14.828,
            -40.263,
            68.706,
            14.828,
            -41.058,
            69.004,
            14.828,
            -41.058,
            69.004,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_864",
        "polygon": [
            -40.166,
            68.669,
            11.622,
            -38.953,
            68.213,
            11.622,
            -38.953,
            68.213,
            14.828,
            -38.953,
            68.213,
            14.828,
            -40.166,
            68.669,
            14.828,
            -40.166,
            68.669,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_865",
        "polygon": [
            -38.855,
            68.176,
            11.56,
            -38.476,
            68.034,
            11.56,
            -38.476,
            68.034,
            14.408,
            -38.476,
            68.034,
            14.408,
            -38.855,
            68.176,
            14.408,
            -38.855,
            68.176,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_866",
        "polygon": [
            -38.855,
            68.176,
            14.408,
            -38.476,
            68.034,
            14.408,
            -38.476,
            68.034,
            14.828,
            -38.476,
            68.034,
            14.828,
            -38.855,
            68.176,
            14.828,
            -38.855,
            68.176,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_867",
        "polygon": [
            -38.437,
            68.019,
            11.622,
            -37.642,
            67.72,
            11.622,
            -37.642,
            67.72,
            14.828,
            -37.642,
            67.72,
            14.828,
            -38.437,
            68.019,
            14.828,
            -38.437,
            68.019,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_868",
        "polygon": [
            -37.545,
            67.684,
            11.622,
            -36.332,
            67.228,
            11.622,
            -36.332,
            67.228,
            14.828,
            -36.332,
            67.228,
            14.828,
            -37.545,
            67.684,
            14.828,
            -37.545,
            67.684,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_869",
        "polygon": [
            -36.235,
            67.191,
            11.56,
            -35.855,
            67.049,
            11.56,
            -35.855,
            67.049,
            14.408,
            -35.855,
            67.049,
            14.408,
            -36.235,
            67.191,
            14.408,
            -36.235,
            67.191,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_870",
        "polygon": [
            -36.235,
            67.191,
            14.408,
            -35.855,
            67.049,
            14.408,
            -35.855,
            67.049,
            14.828,
            -35.855,
            67.049,
            14.828,
            -36.235,
            67.191,
            14.828,
            -36.235,
            67.191,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_871",
        "polygon": [
            -35.816,
            67.034,
            11.622,
            -35.021,
            66.735,
            11.622,
            -35.021,
            66.735,
            14.828,
            -35.021,
            66.735,
            14.828,
            -35.816,
            67.034,
            14.828,
            -35.816,
            67.034,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_872",
        "polygon": [
            -34.924,
            66.698,
            11.622,
            -33.711,
            66.242,
            11.622,
            -33.711,
            66.242,
            14.828,
            -33.711,
            66.242,
            14.828,
            -34.924,
            66.698,
            14.828,
            -34.924,
            66.698,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_873",
        "polygon": [
            -33.614,
            66.206,
            11.56,
            -33.235,
            66.063,
            11.56,
            -33.235,
            66.063,
            14.408,
            -33.235,
            66.063,
            14.408,
            -33.614,
            66.206,
            14.408,
            -33.614,
            66.206,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_874",
        "polygon": [
            -33.614,
            66.206,
            14.408,
            -33.235,
            66.063,
            14.408,
            -33.235,
            66.063,
            14.828,
            -33.235,
            66.063,
            14.828,
            -33.614,
            66.206,
            14.828,
            -33.614,
            66.206,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_875",
        "polygon": [
            -33.195,
            66.048,
            11.622,
            -32.401,
            65.75,
            11.622,
            -32.401,
            65.75,
            14.828,
            -32.401,
            65.75,
            14.828,
            -33.195,
            66.048,
            14.828,
            -33.195,
            66.048,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_876",
        "polygon": [
            -32.303,
            65.713,
            11.622,
            -31.09,
            65.257,
            11.622,
            -31.09,
            65.257,
            14.828,
            -31.09,
            65.257,
            14.828,
            -32.303,
            65.713,
            14.828,
            -32.303,
            65.713,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_877",
        "polygon": [
            -30.993,
            65.22,
            11.56,
            -30.614,
            65.078,
            11.56,
            -30.614,
            65.078,
            14.408,
            -30.614,
            65.078,
            14.408,
            -30.993,
            65.22,
            14.408,
            -30.993,
            65.22,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_878",
        "polygon": [
            -30.993,
            65.22,
            14.408,
            -30.614,
            65.078,
            14.408,
            -30.614,
            65.078,
            14.828,
            -30.614,
            65.078,
            14.828,
            -30.993,
            65.22,
            14.828,
            -30.993,
            65.22,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_879",
        "polygon": [
            -30.574,
            65.063,
            11.622,
            -29.78,
            64.764,
            11.622,
            -29.78,
            64.764,
            14.828,
            -29.78,
            64.764,
            14.828,
            -30.574,
            65.063,
            14.828,
            -30.574,
            65.063,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_880",
        "polygon": [
            -29.682,
            64.728,
            11.622,
            -28.469,
            64.272,
            11.622,
            -28.469,
            64.272,
            14.828,
            -28.469,
            64.272,
            14.828,
            -29.682,
            64.728,
            14.828,
            -29.682,
            64.728,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_881",
        "polygon": [
            -28.372,
            64.235,
            11.56,
            -27.993,
            64.092,
            11.56,
            -27.993,
            64.092,
            14.408,
            -27.993,
            64.092,
            14.408,
            -28.372,
            64.235,
            14.408,
            -28.372,
            64.235,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_882",
        "polygon": [
            -28.372,
            64.235,
            14.408,
            -27.993,
            64.092,
            14.408,
            -27.993,
            64.092,
            14.828,
            -27.993,
            64.092,
            14.828,
            -28.372,
            64.235,
            14.828,
            -28.372,
            64.235,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_883",
        "polygon": [
            -27.953,
            64.078,
            11.622,
            -27.159,
            63.779,
            11.622,
            -27.159,
            63.779,
            14.828,
            -27.159,
            63.779,
            14.828,
            -27.953,
            64.078,
            14.828,
            -27.953,
            64.078,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_884",
        "polygon": [
            -27.061,
            63.742,
            11.622,
            -25.678,
            63.222,
            11.622,
            -25.678,
            63.222,
            14.828,
            -25.678,
            63.222,
            14.828,
            -27.061,
            63.742,
            14.828,
            -27.061,
            63.742,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_885",
        "polygon": [
            -72.093,
            80.672,
            7.85,
            -71.714,
            80.53,
            7.85,
            -71.714,
            80.53,
            10.698,
            -71.714,
            80.53,
            10.698,
            -72.093,
            80.672,
            10.698,
            -72.093,
            80.672,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_886",
        "polygon": [
            -72.093,
            80.672,
            10.698,
            -71.714,
            80.53,
            10.698,
            -71.714,
            80.53,
            11.118,
            -71.714,
            80.53,
            11.118,
            -72.093,
            80.672,
            11.118,
            -72.093,
            80.672,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_887",
        "polygon": [
            -74.408,
            81.543,
            7.912,
            -73.024,
            81.023,
            7.912,
            -73.024,
            81.023,
            11.118,
            -73.024,
            81.023,
            11.118,
            -74.408,
            81.543,
            11.118,
            -74.408,
            81.543,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_888",
        "polygon": [
            -72.927,
            80.986,
            7.912,
            -72.132,
            80.687,
            7.912,
            -72.132,
            80.687,
            11.118,
            -72.132,
            80.687,
            11.118,
            -72.927,
            80.986,
            11.118,
            -72.927,
            80.986,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_889",
        "polygon": [
            -71.617,
            80.493,
            7.912,
            -70.403,
            80.037,
            7.912,
            -70.403,
            80.037,
            11.118,
            -70.403,
            80.037,
            11.118,
            -71.617,
            80.493,
            11.118,
            -71.617,
            80.493,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_890",
        "polygon": [
            -69.472,
            79.687,
            7.85,
            -69.093,
            79.545,
            7.85,
            -69.093,
            79.545,
            10.698,
            -69.093,
            79.545,
            10.698,
            -69.472,
            79.687,
            10.698,
            -69.472,
            79.687,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_891",
        "polygon": [
            -69.472,
            79.687,
            10.698,
            -69.093,
            79.545,
            10.698,
            -69.093,
            79.545,
            11.118,
            -69.093,
            79.545,
            11.118,
            -69.472,
            79.687,
            11.118,
            -69.472,
            79.687,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_892",
        "polygon": [
            -70.306,
            80.001,
            7.912,
            -69.511,
            79.702,
            7.912,
            -69.511,
            79.702,
            11.118,
            -69.511,
            79.702,
            11.118,
            -70.306,
            80.001,
            11.118,
            -70.306,
            80.001,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_893",
        "polygon": [
            -68.996,
            79.508,
            7.912,
            -67.783,
            79.052,
            7.912,
            -67.783,
            79.052,
            11.118,
            -67.783,
            79.052,
            11.118,
            -68.996,
            79.508,
            11.118,
            -68.996,
            79.508,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_894",
        "polygon": [
            -66.851,
            78.702,
            7.85,
            -66.472,
            78.559,
            7.85,
            -66.472,
            78.559,
            10.698,
            -66.472,
            78.559,
            10.698,
            -66.851,
            78.702,
            10.698,
            -66.851,
            78.702,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_895",
        "polygon": [
            -66.851,
            78.702,
            10.698,
            -66.472,
            78.559,
            10.698,
            -66.472,
            78.559,
            11.118,
            -66.472,
            78.559,
            11.118,
            -66.851,
            78.702,
            11.118,
            -66.851,
            78.702,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_896",
        "polygon": [
            -67.685,
            79.015,
            7.912,
            -66.891,
            78.716,
            7.912,
            -66.891,
            78.716,
            11.118,
            -66.891,
            78.716,
            11.118,
            -67.685,
            79.015,
            11.118,
            -67.685,
            79.015,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_897",
        "polygon": [
            -66.375,
            78.523,
            7.912,
            -65.162,
            78.066,
            7.912,
            -65.162,
            78.066,
            11.118,
            -65.162,
            78.066,
            11.118,
            -66.375,
            78.523,
            11.118,
            -66.375,
            78.523,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_898",
        "polygon": [
            -64.23,
            77.716,
            7.85,
            -63.851,
            77.574,
            7.85,
            -63.851,
            77.574,
            10.698,
            -63.851,
            77.574,
            10.698,
            -64.23,
            77.716,
            10.698,
            -64.23,
            77.716,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_899",
        "polygon": [
            -64.23,
            77.716,
            10.698,
            -63.851,
            77.574,
            10.698,
            -63.851,
            77.574,
            11.118,
            -63.851,
            77.574,
            11.118,
            -64.23,
            77.716,
            11.118,
            -64.23,
            77.716,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_900",
        "polygon": [
            -65.064,
            78.03,
            7.912,
            -64.27,
            77.731,
            7.912,
            -64.27,
            77.731,
            11.118,
            -64.27,
            77.731,
            11.118,
            -65.064,
            78.03,
            11.118,
            -65.064,
            78.03,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_901",
        "polygon": [
            -63.754,
            77.537,
            7.912,
            -62.541,
            77.081,
            7.912,
            -62.541,
            77.081,
            11.118,
            -62.541,
            77.081,
            11.118,
            -63.754,
            77.537,
            11.118,
            -63.754,
            77.537,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_902",
        "polygon": [
            -61.609,
            76.731,
            7.85,
            -61.23,
            76.588,
            7.85,
            -61.23,
            76.588,
            10.698,
            -61.23,
            76.588,
            10.698,
            -61.609,
            76.731,
            10.698,
            -61.609,
            76.731,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_903",
        "polygon": [
            -61.609,
            76.731,
            10.698,
            -61.23,
            76.588,
            10.698,
            -61.23,
            76.588,
            11.118,
            -61.23,
            76.588,
            11.118,
            -61.609,
            76.731,
            11.118,
            -61.609,
            76.731,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_904",
        "polygon": [
            -62.443,
            77.045,
            7.912,
            -61.649,
            76.746,
            7.912,
            -61.649,
            76.746,
            11.118,
            -61.649,
            76.746,
            11.118,
            -62.443,
            77.045,
            11.118,
            -62.443,
            77.045,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_905",
        "polygon": [
            -61.133,
            76.552,
            7.912,
            -59.92,
            76.096,
            7.912,
            -59.92,
            76.096,
            11.118,
            -59.92,
            76.096,
            11.118,
            -61.133,
            76.552,
            11.118,
            -61.133,
            76.552,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_906",
        "polygon": [
            -58.989,
            75.746,
            7.85,
            -58.609,
            75.603,
            7.85,
            -58.609,
            75.603,
            10.698,
            -58.609,
            75.603,
            10.698,
            -58.989,
            75.746,
            10.698,
            -58.989,
            75.746,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_907",
        "polygon": [
            -58.989,
            75.746,
            10.698,
            -58.609,
            75.603,
            10.698,
            -58.609,
            75.603,
            11.118,
            -58.609,
            75.603,
            11.118,
            -58.989,
            75.746,
            11.118,
            -58.989,
            75.746,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_908",
        "polygon": [
            -59.823,
            76.059,
            7.912,
            -59.028,
            75.76,
            7.912,
            -59.028,
            75.76,
            11.118,
            -59.028,
            75.76,
            11.118,
            -59.823,
            76.059,
            11.118,
            -59.823,
            76.059,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_909",
        "polygon": [
            -58.512,
            75.567,
            7.912,
            -57.299,
            75.11,
            7.912,
            -57.299,
            75.11,
            11.118,
            -57.299,
            75.11,
            11.118,
            -58.512,
            75.567,
            11.118,
            -58.512,
            75.567,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_910",
        "polygon": [
            -56.368,
            74.76,
            7.85,
            -55.989,
            74.618,
            7.85,
            -55.989,
            74.618,
            10.698,
            -55.989,
            74.618,
            10.698,
            -56.368,
            74.76,
            10.698,
            -56.368,
            74.76,
            7.85
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_911",
        "polygon": [
            -56.368,
            74.76,
            10.698,
            -55.989,
            74.618,
            10.698,
            -55.989,
            74.618,
            11.118,
            -55.989,
            74.618,
            11.118,
            -56.368,
            74.76,
            11.118,
            -56.368,
            74.76,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_912",
        "polygon": [
            -57.202,
            75.074,
            7.912,
            -56.407,
            74.775,
            7.912,
            -56.407,
            74.775,
            11.118,
            -56.407,
            74.775,
            11.118,
            -57.202,
            75.074,
            11.118,
            -57.202,
            75.074,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_913",
        "polygon": [
            -55.891,
            74.581,
            7.912,
            -54.678,
            74.125,
            7.912,
            -54.678,
            74.125,
            11.118,
            -54.678,
            74.125,
            11.118,
            -55.891,
            74.581,
            11.118,
            -55.891,
            74.581,
            7.912
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_914",
        "polygon": [
            -53.747,
            73.775,
            7.85,
            -53.368,
            73.632,
            7.85,
            -53.368,
            73.632,
            10.698,
            -53.368,
            73.632,
            10.698,
            -53.747,
            73.775,
            10.698,
            -53.747,
            73.775,
            7.85
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_915",
        "polygon": [
            -53.747,
            73.775,
            10.698,
            -53.368,
            73.632,
            10.698,
            -53.368,
            73.632,
            11.118,
            -53.368,
            73.632,
            11.118,
            -53.747,
            73.775,
            11.118,
            -53.747,
            73.775,
            10.698
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_916",
        "polygon": [
            -54.581,
            74.088,
            7.912,
            -53.786,
            73.79,
            7.912,
            -53.786,
            73.79,
            11.118,
            -53.786,
            73.79,
            11.118,
            -54.581,
            74.088,
            11.118,
            -54.581,
            74.088,
            7.912
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_917",
        "polygon": [
            -53.27,
            73.596,
            7.912,
            -52.057,
            73.14,
            7.912,
            -52.057,
            73.14,
            11.118,
            -52.057,
            73.14,
            11.118,
            -53.27,
            73.596,
            11.118,
            -53.27,
            73.596,
            7.912
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_918",
        "polygon": [
            -51.126,
            72.79,
            7.85,
            -50.747,
            72.647,
            7.85,
            -50.747,
            72.647,
            10.698,
            -50.747,
            72.647,
            10.698,
            -51.126,
            72.79,
            10.698,
            -51.126,
            72.79,
            7.85
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_919",
        "polygon": [
            -51.126,
            72.79,
            10.698,
            -50.747,
            72.647,
            10.698,
            -50.747,
            72.647,
            11.118,
            -50.747,
            72.647,
            11.118,
            -51.126,
            72.79,
            11.118,
            -51.126,
            72.79,
            10.698
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_920",
        "polygon": [
            -51.96,
            73.103,
            7.912,
            -51.165,
            72.804,
            7.912,
            -51.165,
            72.804,
            11.118,
            -51.165,
            72.804,
            11.118,
            -51.96,
            73.103,
            11.118,
            -51.96,
            73.103,
            7.912
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_921",
        "polygon": [
            -50.649,
            72.61,
            7.912,
            -49.436,
            72.154,
            7.912,
            -49.436,
            72.154,
            11.118,
            -49.436,
            72.154,
            11.118,
            -50.649,
            72.61,
            11.118,
            -50.649,
            72.61,
            7.912
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_922",
        "polygon": [
            -48.505,
            71.804,
            7.85,
            -48.126,
            71.662,
            7.85,
            -48.126,
            71.662,
            10.698,
            -48.126,
            71.662,
            10.698,
            -48.505,
            71.804,
            10.698,
            -48.505,
            71.804,
            7.85
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_923",
        "polygon": [
            -48.505,
            71.804,
            10.698,
            -48.126,
            71.662,
            10.698,
            -48.126,
            71.662,
            11.118,
            -48.126,
            71.662,
            11.118,
            -48.505,
            71.804,
            11.118,
            -48.505,
            71.804,
            10.698
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_924",
        "polygon": [
            -49.339,
            72.118,
            7.912,
            -48.544,
            71.819,
            7.912,
            -48.544,
            71.819,
            11.118,
            -48.544,
            71.819,
            11.118,
            -49.339,
            72.118,
            11.118,
            -49.339,
            72.118,
            7.912
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_925",
        "polygon": [
            -48.029,
            71.625,
            7.912,
            -46.815,
            71.169,
            7.912,
            -46.815,
            71.169,
            11.118,
            -46.815,
            71.169,
            11.118,
            -48.029,
            71.625,
            11.118,
            -48.029,
            71.625,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_926",
        "polygon": [
            -45.884,
            70.819,
            7.85,
            -45.505,
            70.676,
            7.85,
            -45.505,
            70.676,
            10.698,
            -45.505,
            70.676,
            10.698,
            -45.884,
            70.819,
            10.698,
            -45.884,
            70.819,
            7.85
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_927",
        "polygon": [
            -45.884,
            70.819,
            10.698,
            -45.505,
            70.676,
            10.698,
            -45.505,
            70.676,
            11.118,
            -45.505,
            70.676,
            11.118,
            -45.884,
            70.819,
            11.118,
            -45.884,
            70.819,
            10.698
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_928",
        "polygon": [
            -46.718,
            71.132,
            7.912,
            -45.923,
            70.834,
            7.912,
            -45.923,
            70.834,
            11.118,
            -45.923,
            70.834,
            11.118,
            -46.718,
            71.132,
            11.118,
            -46.718,
            71.132,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_929",
        "polygon": [
            -45.408,
            70.64,
            7.912,
            -44.195,
            70.184,
            7.912,
            -44.195,
            70.184,
            11.118,
            -44.195,
            70.184,
            11.118,
            -45.408,
            70.64,
            11.118,
            -45.408,
            70.64,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_930",
        "polygon": [
            -43.263,
            69.834,
            7.85,
            -42.884,
            69.691,
            7.85,
            -42.884,
            69.691,
            10.698,
            -42.884,
            69.691,
            10.698,
            -43.263,
            69.834,
            10.698,
            -43.263,
            69.834,
            7.85
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_931",
        "polygon": [
            -43.263,
            69.834,
            10.698,
            -42.884,
            69.691,
            10.698,
            -42.884,
            69.691,
            11.118,
            -42.884,
            69.691,
            11.118,
            -43.263,
            69.834,
            11.118,
            -43.263,
            69.834,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_932",
        "polygon": [
            -44.097,
            70.147,
            7.912,
            -43.303,
            69.848,
            7.912,
            -43.303,
            69.848,
            11.118,
            -43.303,
            69.848,
            11.118,
            -44.097,
            70.147,
            11.118,
            -44.097,
            70.147,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_933",
        "polygon": [
            -42.787,
            69.654,
            7.912,
            -41.574,
            69.198,
            7.912,
            -41.574,
            69.198,
            11.118,
            -41.574,
            69.198,
            11.118,
            -42.787,
            69.654,
            11.118,
            -42.787,
            69.654,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_934",
        "polygon": [
            -40.642,
            68.848,
            7.85,
            -40.263,
            68.706,
            7.85,
            -40.263,
            68.706,
            10.698,
            -40.263,
            68.706,
            10.698,
            -40.642,
            68.848,
            10.698,
            -40.642,
            68.848,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_935",
        "polygon": [
            -40.642,
            68.848,
            10.698,
            -40.263,
            68.706,
            10.698,
            -40.263,
            68.706,
            11.118,
            -40.263,
            68.706,
            11.118,
            -40.642,
            68.848,
            11.118,
            -40.642,
            68.848,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_936",
        "polygon": [
            -41.476,
            69.162,
            7.912,
            -40.682,
            68.863,
            7.912,
            -40.682,
            68.863,
            11.118,
            -40.682,
            68.863,
            11.118,
            -41.476,
            69.162,
            11.118,
            -41.476,
            69.162,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_937",
        "polygon": [
            -40.166,
            68.669,
            7.912,
            -38.953,
            68.213,
            7.912,
            -38.953,
            68.213,
            11.118,
            -38.953,
            68.213,
            11.118,
            -40.166,
            68.669,
            11.118,
            -40.166,
            68.669,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_938",
        "polygon": [
            -38.021,
            67.863,
            7.85,
            -37.642,
            67.72,
            7.85,
            -37.642,
            67.72,
            10.698,
            -37.642,
            67.72,
            10.698,
            -38.021,
            67.863,
            10.698,
            -38.021,
            67.863,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_939",
        "polygon": [
            -38.021,
            67.863,
            10.698,
            -37.642,
            67.72,
            10.698,
            -37.642,
            67.72,
            11.118,
            -37.642,
            67.72,
            11.118,
            -38.021,
            67.863,
            11.118,
            -38.021,
            67.863,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_940",
        "polygon": [
            -38.855,
            68.176,
            7.912,
            -38.061,
            67.878,
            7.912,
            -38.061,
            67.878,
            11.118,
            -38.061,
            67.878,
            11.118,
            -38.855,
            68.176,
            11.118,
            -38.855,
            68.176,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_941",
        "polygon": [
            -37.545,
            67.684,
            7.912,
            -36.332,
            67.228,
            7.912,
            -36.332,
            67.228,
            11.118,
            -36.332,
            67.228,
            11.118,
            -37.545,
            67.684,
            11.118,
            -37.545,
            67.684,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_942",
        "polygon": [
            -35.401,
            66.877,
            7.85,
            -35.021,
            66.735,
            7.85,
            -35.021,
            66.735,
            10.698,
            -35.021,
            66.735,
            10.698,
            -35.401,
            66.877,
            10.698,
            -35.401,
            66.877,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_943",
        "polygon": [
            -35.401,
            66.877,
            10.698,
            -35.021,
            66.735,
            10.698,
            -35.021,
            66.735,
            11.118,
            -35.021,
            66.735,
            11.118,
            -35.401,
            66.877,
            11.118,
            -35.401,
            66.877,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_944",
        "polygon": [
            -36.235,
            67.191,
            7.912,
            -35.44,
            66.892,
            7.912,
            -35.44,
            66.892,
            11.118,
            -35.44,
            66.892,
            11.118,
            -36.235,
            67.191,
            11.118,
            -36.235,
            67.191,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_945",
        "polygon": [
            -34.924,
            66.698,
            7.912,
            -33.711,
            66.242,
            7.912,
            -33.711,
            66.242,
            11.118,
            -33.711,
            66.242,
            11.118,
            -34.924,
            66.698,
            11.118,
            -34.924,
            66.698,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_946",
        "polygon": [
            -32.78,
            65.892,
            7.85,
            -32.401,
            65.75,
            7.85,
            -32.401,
            65.75,
            10.698,
            -32.401,
            65.75,
            10.698,
            -32.78,
            65.892,
            10.698,
            -32.78,
            65.892,
            7.85
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_947",
        "polygon": [
            -32.78,
            65.892,
            10.698,
            -32.401,
            65.75,
            10.698,
            -32.401,
            65.75,
            11.118,
            -32.401,
            65.75,
            11.118,
            -32.78,
            65.892,
            11.118,
            -32.78,
            65.892,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_948",
        "polygon": [
            -33.614,
            66.206,
            7.912,
            -32.819,
            65.907,
            7.912,
            -32.819,
            65.907,
            11.118,
            -32.819,
            65.907,
            11.118,
            -33.614,
            66.206,
            11.118,
            -33.614,
            66.206,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_949",
        "polygon": [
            -32.303,
            65.713,
            7.912,
            -31.09,
            65.257,
            7.912,
            -31.09,
            65.257,
            11.118,
            -31.09,
            65.257,
            11.118,
            -32.303,
            65.713,
            11.118,
            -32.303,
            65.713,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_950",
        "polygon": [
            -30.159,
            64.907,
            7.85,
            -29.78,
            64.764,
            7.85,
            -29.78,
            64.764,
            10.698,
            -29.78,
            64.764,
            10.698,
            -30.159,
            64.907,
            10.698,
            -30.159,
            64.907,
            7.85
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_951",
        "polygon": [
            -30.159,
            64.907,
            10.698,
            -29.78,
            64.764,
            10.698,
            -29.78,
            64.764,
            11.118,
            -29.78,
            64.764,
            11.118,
            -30.159,
            64.907,
            11.118,
            -30.159,
            64.907,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_952",
        "polygon": [
            -30.993,
            65.22,
            7.912,
            -30.198,
            64.922,
            7.912,
            -30.198,
            64.922,
            11.118,
            -30.198,
            64.922,
            11.118,
            -30.993,
            65.22,
            11.118,
            -30.993,
            65.22,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_953",
        "polygon": [
            -29.682,
            64.728,
            7.912,
            -28.469,
            64.272,
            7.912,
            -28.469,
            64.272,
            11.118,
            -28.469,
            64.272,
            11.118,
            -29.682,
            64.728,
            11.118,
            -29.682,
            64.728,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_954",
        "polygon": [
            -27.538,
            63.921,
            7.85,
            -27.159,
            63.779,
            7.85,
            -27.159,
            63.779,
            10.698,
            -27.159,
            63.779,
            10.698,
            -27.538,
            63.921,
            10.698,
            -27.538,
            63.921,
            7.85
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_955",
        "polygon": [
            -27.538,
            63.921,
            10.698,
            -27.159,
            63.779,
            10.698,
            -27.159,
            63.779,
            11.118,
            -27.159,
            63.779,
            11.118,
            -27.538,
            63.921,
            11.118,
            -27.538,
            63.921,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_956",
        "polygon": [
            -28.372,
            64.235,
            7.912,
            -27.577,
            63.936,
            7.912,
            -27.577,
            63.936,
            11.118,
            -27.577,
            63.936,
            11.118,
            -28.372,
            64.235,
            11.118,
            -28.372,
            64.235,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_958",
        "polygon": [
            -27.061,
            63.742,
            7.912,
            -25.678,
            63.222,
            7.912,
            -25.678,
            63.222,
            11.118,
            -25.678,
            63.222,
            11.118,
            -27.061,
            63.742,
            11.118,
            -27.061,
            63.742,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_959",
        "polygon": [
            -5.444,
            49.632,
            4.202,
            -4.231,
            49.176,
            4.202,
            -4.231,
            49.176,
            7.408,
            -4.231,
            49.176,
            7.408,
            -5.444,
            49.632,
            7.408,
            -5.444,
            49.632,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_960",
        "polygon": [
            -6.754,
            50.125,
            6.988,
            -6.375,
            49.983,
            6.988,
            -6.375,
            49.983,
            7.408,
            -6.375,
            49.983,
            7.408,
            -6.754,
            50.125,
            7.408,
            -6.754,
            50.125,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_961",
        "polygon": [
            -6.754,
            50.125,
            4.14,
            -6.375,
            49.983,
            4.14,
            -6.375,
            49.983,
            6.988,
            -6.375,
            49.983,
            6.988,
            -6.754,
            50.125,
            6.988,
            -6.754,
            50.125,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_962",
        "polygon": [
            -6.336,
            49.968,
            4.202,
            -5.541,
            49.669,
            4.202,
            -5.541,
            49.669,
            7.408,
            -5.541,
            49.669,
            7.408,
            -6.336,
            49.968,
            7.408,
            -6.336,
            49.968,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_963",
        "polygon": [
            -8.235,
            50.682,
            4.202,
            -6.852,
            50.162,
            4.202,
            -6.852,
            50.162,
            7.408,
            -6.852,
            50.162,
            7.408,
            -8.235,
            50.682,
            7.408,
            -8.235,
            50.682,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_964",
        "polygon": [
            -2.823,
            48.647,
            4.202,
            -1.61,
            48.191,
            4.202,
            -1.61,
            48.191,
            7.408,
            -1.61,
            48.191,
            7.408,
            -2.823,
            48.647,
            7.408,
            -2.823,
            48.647,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_965",
        "polygon": [
            -4.134,
            49.14,
            6.988,
            -3.755,
            48.997,
            6.988,
            -3.755,
            48.997,
            7.408,
            -3.755,
            48.997,
            7.408,
            -4.134,
            49.14,
            7.408,
            -4.134,
            49.14,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_966",
        "polygon": [
            -4.134,
            49.14,
            4.14,
            -3.755,
            48.997,
            4.14,
            -3.755,
            48.997,
            6.988,
            -3.755,
            48.997,
            6.988,
            -4.134,
            49.14,
            6.988,
            -4.134,
            49.14,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_967",
        "polygon": [
            -3.715,
            48.982,
            4.202,
            -2.92,
            48.684,
            4.202,
            -2.92,
            48.684,
            7.408,
            -2.92,
            48.684,
            7.408,
            -3.715,
            48.982,
            7.408,
            -3.715,
            48.982,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_968",
        "polygon": [
            -0.202,
            47.662,
            4.202,
            1.011,
            47.206,
            4.202,
            1.011,
            47.206,
            7.408,
            1.011,
            47.206,
            7.408,
            -0.202,
            47.662,
            7.408,
            -0.202,
            47.662,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_969",
        "polygon": [
            -1.513,
            48.154,
            6.988,
            -1.134,
            48.012,
            6.988,
            -1.134,
            48.012,
            7.408,
            -1.134,
            48.012,
            7.408,
            -1.513,
            48.154,
            7.408,
            -1.513,
            48.154,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_970",
        "polygon": [
            -1.513,
            48.154,
            4.14,
            -1.134,
            48.012,
            4.14,
            -1.134,
            48.012,
            6.988,
            -1.134,
            48.012,
            6.988,
            -1.513,
            48.154,
            6.988,
            -1.513,
            48.154,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_971",
        "polygon": [
            -1.094,
            47.997,
            4.202,
            -0.3,
            47.698,
            4.202,
            -0.3,
            47.698,
            7.408,
            -0.3,
            47.698,
            7.408,
            -1.094,
            47.997,
            7.408,
            -1.094,
            47.997,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_972",
        "polygon": [
            2.419,
            46.676,
            4.202,
            3.632,
            46.22,
            4.202,
            3.632,
            46.22,
            7.408,
            3.632,
            46.22,
            7.408,
            2.419,
            46.676,
            7.408,
            2.419,
            46.676,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_973",
        "polygon": [
            1.108,
            47.169,
            6.988,
            1.487,
            47.026,
            6.988,
            1.487,
            47.026,
            7.408,
            1.487,
            47.026,
            7.408,
            1.108,
            47.169,
            7.408,
            1.108,
            47.169,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_974",
        "polygon": [
            1.108,
            47.169,
            4.14,
            1.487,
            47.026,
            4.14,
            1.487,
            47.026,
            6.988,
            1.487,
            47.026,
            6.988,
            1.108,
            47.169,
            6.988,
            1.108,
            47.169,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_975",
        "polygon": [
            1.527,
            47.012,
            4.202,
            2.321,
            46.713,
            4.202,
            2.321,
            46.713,
            7.408,
            2.321,
            46.713,
            7.408,
            1.527,
            47.012,
            7.408,
            1.527,
            47.012,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_976",
        "polygon": [
            5.039,
            45.691,
            4.202,
            6.253,
            45.235,
            4.202,
            6.253,
            45.235,
            7.408,
            6.253,
            45.235,
            7.408,
            5.039,
            45.691,
            7.408,
            5.039,
            45.691,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_977",
        "polygon": [
            3.729,
            46.184,
            6.988,
            4.108,
            46.041,
            6.988,
            4.108,
            46.041,
            7.408,
            4.108,
            46.041,
            7.408,
            3.729,
            46.184,
            7.408,
            3.729,
            46.184,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_978",
        "polygon": [
            3.729,
            46.184,
            4.14,
            4.108,
            46.041,
            4.14,
            4.108,
            46.041,
            6.988,
            4.108,
            46.041,
            6.988,
            3.729,
            46.184,
            6.988,
            3.729,
            46.184,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_979",
        "polygon": [
            4.147,
            46.026,
            4.202,
            4.942,
            45.728,
            4.202,
            4.942,
            45.728,
            7.408,
            4.942,
            45.728,
            7.408,
            4.147,
            46.026,
            7.408,
            4.147,
            46.026,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_980",
        "polygon": [
            7.66,
            44.706,
            4.202,
            8.873,
            44.25,
            4.202,
            8.873,
            44.25,
            7.408,
            8.873,
            44.25,
            7.408,
            7.66,
            44.706,
            7.408,
            7.66,
            44.706,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_981",
        "polygon": [
            6.35,
            45.198,
            6.988,
            6.729,
            45.056,
            6.988,
            6.729,
            45.056,
            7.408,
            6.729,
            45.056,
            7.408,
            6.35,
            45.198,
            7.408,
            6.35,
            45.198,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_982",
        "polygon": [
            6.35,
            45.198,
            4.14,
            6.729,
            45.056,
            4.14,
            6.729,
            45.056,
            6.988,
            6.729,
            45.056,
            6.988,
            6.35,
            45.198,
            6.988,
            6.35,
            45.198,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_983",
        "polygon": [
            6.768,
            45.041,
            4.202,
            7.563,
            44.742,
            4.202,
            7.563,
            44.742,
            7.408,
            7.563,
            44.742,
            7.408,
            6.768,
            45.041,
            7.408,
            6.768,
            45.041,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_984",
        "polygon": [
            10.281,
            43.72,
            4.202,
            11.494,
            43.264,
            4.202,
            11.494,
            43.264,
            7.408,
            11.494,
            43.264,
            7.408,
            10.281,
            43.72,
            7.408,
            10.281,
            43.72,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_985",
        "polygon": [
            8.971,
            44.213,
            6.988,
            9.35,
            44.07,
            6.988,
            9.35,
            44.07,
            7.408,
            9.35,
            44.07,
            7.408,
            8.971,
            44.213,
            7.408,
            8.971,
            44.213,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_986",
        "polygon": [
            8.971,
            44.213,
            4.14,
            9.35,
            44.07,
            4.14,
            9.35,
            44.07,
            6.988,
            9.35,
            44.07,
            6.988,
            8.971,
            44.213,
            6.988,
            8.971,
            44.213,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_987",
        "polygon": [
            9.389,
            44.056,
            4.202,
            10.184,
            43.757,
            4.202,
            10.184,
            43.757,
            7.408,
            10.184,
            43.757,
            7.408,
            9.389,
            44.056,
            7.408,
            9.389,
            44.056,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_988",
        "polygon": [
            12.902,
            42.735,
            4.202,
            14.115,
            42.279,
            4.202,
            14.115,
            42.279,
            7.408,
            14.115,
            42.279,
            7.408,
            12.902,
            42.735,
            7.408,
            12.902,
            42.735,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_989",
        "polygon": [
            11.592,
            43.228,
            6.988,
            11.971,
            43.085,
            6.988,
            11.971,
            43.085,
            7.408,
            11.971,
            43.085,
            7.408,
            11.592,
            43.228,
            7.408,
            11.592,
            43.228,
            6.988
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_990",
        "polygon": [
            11.592,
            43.228,
            4.14,
            11.971,
            43.085,
            4.14,
            11.971,
            43.085,
            6.988,
            11.971,
            43.085,
            6.988,
            11.592,
            43.228,
            6.988,
            11.592,
            43.228,
            4.14
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_991",
        "polygon": [
            12.01,
            43.07,
            4.202,
            12.805,
            42.772,
            4.202,
            12.805,
            42.772,
            7.408,
            12.805,
            42.772,
            7.408,
            12.01,
            43.07,
            7.408,
            12.01,
            43.07,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_992",
        "polygon": [
            15.523,
            41.75,
            4.202,
            16.736,
            41.294,
            4.202,
            16.736,
            41.294,
            7.408,
            16.736,
            41.294,
            7.408,
            15.523,
            41.75,
            7.408,
            15.523,
            41.75,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_993",
        "polygon": [
            14.213,
            42.242,
            6.988,
            14.592,
            42.1,
            6.988,
            14.592,
            42.1,
            7.408,
            14.592,
            42.1,
            7.408,
            14.213,
            42.242,
            7.408,
            14.213,
            42.242,
            6.988
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_994",
        "polygon": [
            14.213,
            42.242,
            4.14,
            14.592,
            42.1,
            4.14,
            14.592,
            42.1,
            6.988,
            14.592,
            42.1,
            6.988,
            14.213,
            42.242,
            6.988,
            14.213,
            42.242,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_995",
        "polygon": [
            14.631,
            42.085,
            4.202,
            15.426,
            41.786,
            4.202,
            15.426,
            41.786,
            7.408,
            15.426,
            41.786,
            7.408,
            14.631,
            42.085,
            7.408,
            14.631,
            42.085,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_996",
        "polygon": [
            18.144,
            40.764,
            4.202,
            19.357,
            40.308,
            4.202,
            19.357,
            40.308,
            7.408,
            19.357,
            40.308,
            7.408,
            18.144,
            40.764,
            7.408,
            18.144,
            40.764,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_997",
        "polygon": [
            16.834,
            41.257,
            6.988,
            17.213,
            41.114,
            6.988,
            17.213,
            41.114,
            7.408,
            17.213,
            41.114,
            7.408,
            16.834,
            41.257,
            7.408,
            16.834,
            41.257,
            6.988
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_998",
        "polygon": [
            16.834,
            41.257,
            4.14,
            17.213,
            41.114,
            4.14,
            17.213,
            41.114,
            6.988,
            17.213,
            41.114,
            6.988,
            16.834,
            41.257,
            6.988,
            16.834,
            41.257,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_999",
        "polygon": [
            17.252,
            41.1,
            4.202,
            18.047,
            40.801,
            4.202,
            18.047,
            40.801,
            7.408,
            18.047,
            40.801,
            7.408,
            17.252,
            41.1,
            7.408,
            17.252,
            41.1,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1000",
        "polygon": [
            20.765,
            39.779,
            4.202,
            21.978,
            39.323,
            4.202,
            21.978,
            39.323,
            7.408,
            21.978,
            39.323,
            7.408,
            20.765,
            39.779,
            7.408,
            20.765,
            39.779,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1001",
        "polygon": [
            19.454,
            40.272,
            6.988,
            19.834,
            40.129,
            6.988,
            19.834,
            40.129,
            7.408,
            19.834,
            40.129,
            7.408,
            19.454,
            40.272,
            7.408,
            19.454,
            40.272,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1002",
        "polygon": [
            19.454,
            40.272,
            4.14,
            19.834,
            40.129,
            4.14,
            19.834,
            40.129,
            6.988,
            19.834,
            40.129,
            6.988,
            19.454,
            40.272,
            6.988,
            19.454,
            40.272,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1003",
        "polygon": [
            19.873,
            40.114,
            4.202,
            20.668,
            39.815,
            4.202,
            20.668,
            39.815,
            7.408,
            20.668,
            39.815,
            7.408,
            19.873,
            40.114,
            7.408,
            19.873,
            40.114,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1004",
        "polygon": [
            23.386,
            38.794,
            4.202,
            24.599,
            38.337,
            4.202,
            24.599,
            38.337,
            7.408,
            24.599,
            38.337,
            7.408,
            23.386,
            38.794,
            7.408,
            23.386,
            38.794,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1005",
        "polygon": [
            22.075,
            39.286,
            6.988,
            22.454,
            39.144,
            6.988,
            22.454,
            39.144,
            7.408,
            22.454,
            39.144,
            7.408,
            22.075,
            39.286,
            7.408,
            22.075,
            39.286,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1006",
        "polygon": [
            22.075,
            39.286,
            4.14,
            22.454,
            39.144,
            4.14,
            22.454,
            39.144,
            6.988,
            22.454,
            39.144,
            6.988,
            22.075,
            39.286,
            6.988,
            22.075,
            39.286,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1007",
        "polygon": [
            22.494,
            39.129,
            4.202,
            23.288,
            38.83,
            4.202,
            23.288,
            38.83,
            7.408,
            23.288,
            38.83,
            7.408,
            22.494,
            39.129,
            7.408,
            22.494,
            39.129,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1008",
        "polygon": [
            26.007,
            37.808,
            4.202,
            27.22,
            37.352,
            4.202,
            27.22,
            37.352,
            7.408,
            27.22,
            37.352,
            7.408,
            26.007,
            37.808,
            7.408,
            26.007,
            37.808,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1009",
        "polygon": [
            24.696,
            38.301,
            6.988,
            25.075,
            38.158,
            6.988,
            25.075,
            38.158,
            7.408,
            25.075,
            38.158,
            7.408,
            24.696,
            38.301,
            7.408,
            24.696,
            38.301,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1010",
        "polygon": [
            24.696,
            38.301,
            4.14,
            25.075,
            38.158,
            4.14,
            25.075,
            38.158,
            6.988,
            25.075,
            38.158,
            6.988,
            24.696,
            38.301,
            6.988,
            24.696,
            38.301,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1011",
        "polygon": [
            25.115,
            38.144,
            4.202,
            25.909,
            37.845,
            4.202,
            25.909,
            37.845,
            7.408,
            25.909,
            37.845,
            7.408,
            25.115,
            38.144,
            7.408,
            25.115,
            38.144,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1012",
        "polygon": [
            28.628,
            36.823,
            4.202,
            29.841,
            36.367,
            4.202,
            29.841,
            36.367,
            7.408,
            29.841,
            36.367,
            7.408,
            28.628,
            36.823,
            7.408,
            28.628,
            36.823,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1013",
        "polygon": [
            27.317,
            37.316,
            6.988,
            27.696,
            37.173,
            6.988,
            27.696,
            37.173,
            7.408,
            27.696,
            37.173,
            7.408,
            27.317,
            37.316,
            7.408,
            27.317,
            37.316,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1014",
        "polygon": [
            27.317,
            37.316,
            4.14,
            27.696,
            37.173,
            4.14,
            27.696,
            37.173,
            6.988,
            27.696,
            37.173,
            6.988,
            27.317,
            37.316,
            6.988,
            27.317,
            37.316,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1015",
        "polygon": [
            27.736,
            37.158,
            4.202,
            28.53,
            36.859,
            4.202,
            28.53,
            36.859,
            7.408,
            28.53,
            36.859,
            7.408,
            27.736,
            37.158,
            7.408,
            27.736,
            37.158,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1016",
        "polygon": [
            31.248,
            35.838,
            4.202,
            32.462,
            35.381,
            4.202,
            32.462,
            35.381,
            7.408,
            32.462,
            35.381,
            7.408,
            31.248,
            35.838,
            7.408,
            31.248,
            35.838,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1017",
        "polygon": [
            29.938,
            36.33,
            6.988,
            30.317,
            36.188,
            6.988,
            30.317,
            36.188,
            7.408,
            30.317,
            36.188,
            7.408,
            29.938,
            36.33,
            7.408,
            29.938,
            36.33,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1018",
        "polygon": [
            29.938,
            36.33,
            4.14,
            30.317,
            36.188,
            4.14,
            30.317,
            36.188,
            6.988,
            30.317,
            36.188,
            6.988,
            29.938,
            36.33,
            6.988,
            29.938,
            36.33,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1019",
        "polygon": [
            30.356,
            36.173,
            4.202,
            31.151,
            35.874,
            4.202,
            31.151,
            35.874,
            7.408,
            31.151,
            35.874,
            7.408,
            30.356,
            36.173,
            7.408,
            30.356,
            36.173,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1020",
        "polygon": [
            33.869,
            34.852,
            4.202,
            35.082,
            34.396,
            4.202,
            35.082,
            34.396,
            7.408,
            35.082,
            34.396,
            7.408,
            33.869,
            34.852,
            7.408,
            33.869,
            34.852,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1021",
        "polygon": [
            32.559,
            35.345,
            6.988,
            32.938,
            35.202,
            6.988,
            32.938,
            35.202,
            7.408,
            32.938,
            35.202,
            7.408,
            32.559,
            35.345,
            7.408,
            32.559,
            35.345,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1022",
        "polygon": [
            32.559,
            35.345,
            4.14,
            32.938,
            35.202,
            4.14,
            32.938,
            35.202,
            6.988,
            32.938,
            35.202,
            6.988,
            32.559,
            35.345,
            6.988,
            32.559,
            35.345,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1023",
        "polygon": [
            32.977,
            35.188,
            4.202,
            33.772,
            34.889,
            4.202,
            33.772,
            34.889,
            7.408,
            33.772,
            34.889,
            7.408,
            32.977,
            35.188,
            7.408,
            32.977,
            35.188,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1024",
        "polygon": [
            36.49,
            33.867,
            4.202,
            37.874,
            33.347,
            4.202,
            37.874,
            33.347,
            7.408,
            37.874,
            33.347,
            7.408,
            36.49,
            33.867,
            7.408,
            36.49,
            33.867,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1025",
        "polygon": [
            35.18,
            34.359,
            6.988,
            35.559,
            34.217,
            6.988,
            35.559,
            34.217,
            7.408,
            35.559,
            34.217,
            7.408,
            35.18,
            34.359,
            7.408,
            35.18,
            34.359,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1026",
        "polygon": [
            35.18,
            34.359,
            4.14,
            35.559,
            34.217,
            4.14,
            35.559,
            34.217,
            6.988,
            35.559,
            34.217,
            6.988,
            35.18,
            34.359,
            6.988,
            35.18,
            34.359,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1027",
        "polygon": [
            35.598,
            34.202,
            4.202,
            36.393,
            33.903,
            4.202,
            36.393,
            33.903,
            7.408,
            36.393,
            33.903,
            7.408,
            35.598,
            34.202,
            7.408,
            35.598,
            34.202,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1028",
        "polygon": [
            -5.444,
            49.632,
            7.912,
            -4.231,
            49.176,
            7.912,
            -4.231,
            49.176,
            11.118,
            -4.231,
            49.176,
            11.118,
            -5.444,
            49.632,
            11.118,
            -5.444,
            49.632,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1029",
        "polygon": [
            -5.92,
            49.812,
            10.698,
            -5.541,
            49.669,
            10.698,
            -5.541,
            49.669,
            11.118,
            -5.541,
            49.669,
            11.118,
            -5.92,
            49.812,
            11.118,
            -5.92,
            49.812,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1030",
        "polygon": [
            -5.92,
            49.812,
            7.85,
            -5.541,
            49.669,
            7.85,
            -5.541,
            49.669,
            10.698,
            -5.541,
            49.669,
            10.698,
            -5.92,
            49.812,
            10.698,
            -5.92,
            49.812,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1031",
        "polygon": [
            -6.754,
            50.125,
            7.912,
            -5.96,
            49.826,
            7.912,
            -5.96,
            49.826,
            11.118,
            -5.96,
            49.826,
            11.118,
            -6.754,
            50.125,
            11.118,
            -6.754,
            50.125,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1032",
        "polygon": [
            -8.235,
            50.682,
            7.912,
            -6.852,
            50.162,
            7.912,
            -6.852,
            50.162,
            11.118,
            -6.852,
            50.162,
            11.118,
            -8.235,
            50.682,
            11.118,
            -8.235,
            50.682,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1033",
        "polygon": [
            -2.823,
            48.647,
            7.912,
            -1.61,
            48.191,
            7.912,
            -1.61,
            48.191,
            11.118,
            -1.61,
            48.191,
            11.118,
            -2.823,
            48.647,
            11.118,
            -2.823,
            48.647,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1034",
        "polygon": [
            -0.202,
            47.662,
            7.912,
            1.011,
            47.206,
            7.912,
            1.011,
            47.206,
            11.118,
            1.011,
            47.206,
            11.118,
            -0.202,
            47.662,
            11.118,
            -0.202,
            47.662,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1035",
        "polygon": [
            2.419,
            46.676,
            7.912,
            3.632,
            46.22,
            7.912,
            3.632,
            46.22,
            11.118,
            3.632,
            46.22,
            11.118,
            2.419,
            46.676,
            11.118,
            2.419,
            46.676,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1036",
        "polygon": [
            5.039,
            45.691,
            7.912,
            6.253,
            45.235,
            7.912,
            6.253,
            45.235,
            11.118,
            6.253,
            45.235,
            11.118,
            5.039,
            45.691,
            11.118,
            5.039,
            45.691,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1037",
        "polygon": [
            7.66,
            44.706,
            7.912,
            8.873,
            44.25,
            7.912,
            8.873,
            44.25,
            11.118,
            8.873,
            44.25,
            11.118,
            7.66,
            44.706,
            11.118,
            7.66,
            44.706,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1038",
        "polygon": [
            10.281,
            43.72,
            7.912,
            11.494,
            43.264,
            7.912,
            11.494,
            43.264,
            11.118,
            11.494,
            43.264,
            11.118,
            10.281,
            43.72,
            11.118,
            10.281,
            43.72,
            7.912
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1039",
        "polygon": [
            12.902,
            42.735,
            7.912,
            14.115,
            42.279,
            7.912,
            14.115,
            42.279,
            11.118,
            14.115,
            42.279,
            11.118,
            12.902,
            42.735,
            11.118,
            12.902,
            42.735,
            7.912
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1040",
        "polygon": [
            15.523,
            41.75,
            7.912,
            16.736,
            41.294,
            7.912,
            16.736,
            41.294,
            11.118,
            16.736,
            41.294,
            11.118,
            15.523,
            41.75,
            11.118,
            15.523,
            41.75,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1041",
        "polygon": [
            18.144,
            40.764,
            7.912,
            19.357,
            40.308,
            7.912,
            19.357,
            40.308,
            11.118,
            19.357,
            40.308,
            11.118,
            18.144,
            40.764,
            11.118,
            18.144,
            40.764,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1042",
        "polygon": [
            20.765,
            39.779,
            7.912,
            21.978,
            39.323,
            7.912,
            21.978,
            39.323,
            11.118,
            21.978,
            39.323,
            11.118,
            20.765,
            39.779,
            11.118,
            20.765,
            39.779,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1043",
        "polygon": [
            23.386,
            38.794,
            7.912,
            24.599,
            38.337,
            7.912,
            24.599,
            38.337,
            11.118,
            24.599,
            38.337,
            11.118,
            23.386,
            38.794,
            11.118,
            23.386,
            38.794,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1044",
        "polygon": [
            26.007,
            37.808,
            7.912,
            27.22,
            37.352,
            7.912,
            27.22,
            37.352,
            11.118,
            27.22,
            37.352,
            11.118,
            26.007,
            37.808,
            11.118,
            26.007,
            37.808,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1045",
        "polygon": [
            28.628,
            36.823,
            7.912,
            29.841,
            36.367,
            7.912,
            29.841,
            36.367,
            11.118,
            29.841,
            36.367,
            11.118,
            28.628,
            36.823,
            11.118,
            28.628,
            36.823,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1046",
        "polygon": [
            31.248,
            35.838,
            7.912,
            32.462,
            35.381,
            7.912,
            32.462,
            35.381,
            11.118,
            32.462,
            35.381,
            11.118,
            31.248,
            35.838,
            11.118,
            31.248,
            35.838,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1047",
        "polygon": [
            33.869,
            34.852,
            7.912,
            35.082,
            34.396,
            7.912,
            35.082,
            34.396,
            11.118,
            35.082,
            34.396,
            11.118,
            33.869,
            34.852,
            11.118,
            33.869,
            34.852,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1048",
        "polygon": [
            36.49,
            33.867,
            7.912,
            37.874,
            33.347,
            7.912,
            37.874,
            33.347,
            11.118,
            37.874,
            33.347,
            11.118,
            36.49,
            33.867,
            11.118,
            36.49,
            33.867,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1049",
        "polygon": [
            -3.3,
            48.826,
            10.698,
            -2.92,
            48.684,
            10.698,
            -2.92,
            48.684,
            11.118,
            -2.92,
            48.684,
            11.118,
            -3.3,
            48.826,
            11.118,
            -3.3,
            48.826,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1050",
        "polygon": [
            -3.3,
            48.826,
            7.85,
            -2.92,
            48.684,
            7.85,
            -2.92,
            48.684,
            10.698,
            -2.92,
            48.684,
            10.698,
            -3.3,
            48.826,
            10.698,
            -3.3,
            48.826,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1051",
        "polygon": [
            -4.134,
            49.14,
            7.912,
            -3.339,
            48.841,
            7.912,
            -3.339,
            48.841,
            11.118,
            -3.339,
            48.841,
            11.118,
            -4.134,
            49.14,
            11.118,
            -4.134,
            49.14,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1052",
        "polygon": [
            -0.679,
            47.841,
            10.698,
            -0.3,
            47.698,
            10.698,
            -0.3,
            47.698,
            11.118,
            -0.3,
            47.698,
            11.118,
            -0.679,
            47.841,
            11.118,
            -0.679,
            47.841,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1053",
        "polygon": [
            -0.679,
            47.841,
            7.85,
            -0.3,
            47.698,
            7.85,
            -0.3,
            47.698,
            10.698,
            -0.3,
            47.698,
            10.698,
            -0.679,
            47.841,
            10.698,
            -0.679,
            47.841,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1054",
        "polygon": [
            -1.513,
            48.154,
            7.912,
            -0.718,
            47.856,
            7.912,
            -0.718,
            47.856,
            11.118,
            -0.718,
            47.856,
            11.118,
            -1.513,
            48.154,
            11.118,
            -1.513,
            48.154,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1055",
        "polygon": [
            1.942,
            46.855,
            10.698,
            2.321,
            46.713,
            10.698,
            2.321,
            46.713,
            11.118,
            2.321,
            46.713,
            11.118,
            1.942,
            46.855,
            11.118,
            1.942,
            46.855,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1056",
        "polygon": [
            1.942,
            46.855,
            7.85,
            2.321,
            46.713,
            7.85,
            2.321,
            46.713,
            10.698,
            2.321,
            46.713,
            10.698,
            1.942,
            46.855,
            10.698,
            1.942,
            46.855,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1057",
        "polygon": [
            1.108,
            47.169,
            7.912,
            1.903,
            46.87,
            7.912,
            1.903,
            46.87,
            11.118,
            1.903,
            46.87,
            11.118,
            1.108,
            47.169,
            11.118,
            1.108,
            47.169,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1058",
        "polygon": [
            4.563,
            45.87,
            10.698,
            4.942,
            45.728,
            10.698,
            4.942,
            45.728,
            11.118,
            4.942,
            45.728,
            11.118,
            4.563,
            45.87,
            11.118,
            4.563,
            45.87,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1059",
        "polygon": [
            4.563,
            45.87,
            7.85,
            4.942,
            45.728,
            7.85,
            4.942,
            45.728,
            10.698,
            4.942,
            45.728,
            10.698,
            4.563,
            45.87,
            10.698,
            4.563,
            45.87,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1060",
        "polygon": [
            3.729,
            46.184,
            7.912,
            4.524,
            45.885,
            7.912,
            4.524,
            45.885,
            11.118,
            4.524,
            45.885,
            11.118,
            3.729,
            46.184,
            11.118,
            3.729,
            46.184,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1061",
        "polygon": [
            7.184,
            44.885,
            10.698,
            7.563,
            44.742,
            10.698,
            7.563,
            44.742,
            11.118,
            7.563,
            44.742,
            11.118,
            7.184,
            44.885,
            11.118,
            7.184,
            44.885,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1062",
        "polygon": [
            7.184,
            44.885,
            7.85,
            7.563,
            44.742,
            7.85,
            7.563,
            44.742,
            10.698,
            7.563,
            44.742,
            10.698,
            7.184,
            44.885,
            10.698,
            7.184,
            44.885,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1063",
        "polygon": [
            6.35,
            45.198,
            7.912,
            7.145,
            44.9,
            7.912,
            7.145,
            44.9,
            11.118,
            7.145,
            44.9,
            11.118,
            6.35,
            45.198,
            11.118,
            6.35,
            45.198,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1064",
        "polygon": [
            9.805,
            43.899,
            10.698,
            10.184,
            43.757,
            10.698,
            10.184,
            43.757,
            11.118,
            10.184,
            43.757,
            11.118,
            9.805,
            43.899,
            11.118,
            9.805,
            43.899,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1065",
        "polygon": [
            9.805,
            43.899,
            7.85,
            10.184,
            43.757,
            7.85,
            10.184,
            43.757,
            10.698,
            10.184,
            43.757,
            10.698,
            9.805,
            43.899,
            10.698,
            9.805,
            43.899,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1066",
        "polygon": [
            8.971,
            44.213,
            7.912,
            9.766,
            43.914,
            7.912,
            9.766,
            43.914,
            11.118,
            9.766,
            43.914,
            11.118,
            8.971,
            44.213,
            11.118,
            8.971,
            44.213,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1067",
        "polygon": [
            12.426,
            42.914,
            10.698,
            12.805,
            42.772,
            10.698,
            12.805,
            42.772,
            11.118,
            12.805,
            42.772,
            11.118,
            12.426,
            42.914,
            11.118,
            12.426,
            42.914,
            10.698
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1068",
        "polygon": [
            12.426,
            42.914,
            7.85,
            12.805,
            42.772,
            7.85,
            12.805,
            42.772,
            10.698,
            12.805,
            42.772,
            10.698,
            12.426,
            42.914,
            10.698,
            12.426,
            42.914,
            7.85
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1069",
        "polygon": [
            11.592,
            43.228,
            7.912,
            12.386,
            42.929,
            7.912,
            12.386,
            42.929,
            11.118,
            12.386,
            42.929,
            11.118,
            11.592,
            43.228,
            11.118,
            11.592,
            43.228,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1070",
        "polygon": [
            15.047,
            41.929,
            10.698,
            15.426,
            41.786,
            10.698,
            15.426,
            41.786,
            11.118,
            15.426,
            41.786,
            11.118,
            15.047,
            41.929,
            11.118,
            15.047,
            41.929,
            10.698
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1071",
        "polygon": [
            15.047,
            41.929,
            7.85,
            15.426,
            41.786,
            7.85,
            15.426,
            41.786,
            10.698,
            15.426,
            41.786,
            10.698,
            15.047,
            41.929,
            10.698,
            15.047,
            41.929,
            7.85
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1072",
        "polygon": [
            14.213,
            42.242,
            7.912,
            15.007,
            41.943,
            7.912,
            15.007,
            41.943,
            11.118,
            15.007,
            41.943,
            11.118,
            14.213,
            42.242,
            11.118,
            14.213,
            42.242,
            7.912
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1073",
        "polygon": [
            17.668,
            40.943,
            10.698,
            18.047,
            40.801,
            10.698,
            18.047,
            40.801,
            11.118,
            18.047,
            40.801,
            11.118,
            17.668,
            40.943,
            11.118,
            17.668,
            40.943,
            10.698
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1074",
        "polygon": [
            17.668,
            40.943,
            7.85,
            18.047,
            40.801,
            7.85,
            18.047,
            40.801,
            10.698,
            18.047,
            40.801,
            10.698,
            17.668,
            40.943,
            10.698,
            17.668,
            40.943,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1075",
        "polygon": [
            16.834,
            41.257,
            7.912,
            17.628,
            40.958,
            7.912,
            17.628,
            40.958,
            11.118,
            17.628,
            40.958,
            11.118,
            16.834,
            41.257,
            11.118,
            16.834,
            41.257,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1076",
        "polygon": [
            20.288,
            39.958,
            10.698,
            20.668,
            39.815,
            10.698,
            20.668,
            39.815,
            11.118,
            20.668,
            39.815,
            11.118,
            20.288,
            39.958,
            11.118,
            20.288,
            39.958,
            10.698
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1077",
        "polygon": [
            20.288,
            39.958,
            7.85,
            20.668,
            39.815,
            7.85,
            20.668,
            39.815,
            10.698,
            20.668,
            39.815,
            10.698,
            20.288,
            39.958,
            10.698,
            20.288,
            39.958,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1078",
        "polygon": [
            19.454,
            40.272,
            7.912,
            20.249,
            39.973,
            7.912,
            20.249,
            39.973,
            11.118,
            20.249,
            39.973,
            11.118,
            19.454,
            40.272,
            11.118,
            19.454,
            40.272,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1079",
        "polygon": [
            22.909,
            38.973,
            10.698,
            23.288,
            38.83,
            10.698,
            23.288,
            38.83,
            11.118,
            23.288,
            38.83,
            11.118,
            22.909,
            38.973,
            11.118,
            22.909,
            38.973,
            10.698
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1080",
        "polygon": [
            22.909,
            38.973,
            7.85,
            23.288,
            38.83,
            7.85,
            23.288,
            38.83,
            10.698,
            23.288,
            38.83,
            10.698,
            22.909,
            38.973,
            10.698,
            22.909,
            38.973,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1081",
        "polygon": [
            22.075,
            39.286,
            7.912,
            22.87,
            38.987,
            7.912,
            22.87,
            38.987,
            11.118,
            22.87,
            38.987,
            11.118,
            22.075,
            39.286,
            11.118,
            22.075,
            39.286,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1082",
        "polygon": [
            25.53,
            37.987,
            10.698,
            25.909,
            37.845,
            10.698,
            25.909,
            37.845,
            11.118,
            25.909,
            37.845,
            11.118,
            25.53,
            37.987,
            11.118,
            25.53,
            37.987,
            10.698
        ],
        "05-24 04:00": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1083",
        "polygon": [
            25.53,
            37.987,
            7.85,
            25.909,
            37.845,
            7.85,
            25.909,
            37.845,
            10.698,
            25.909,
            37.845,
            10.698,
            25.53,
            37.987,
            10.698,
            25.53,
            37.987,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1084",
        "polygon": [
            24.696,
            38.301,
            7.912,
            25.491,
            38.002,
            7.912,
            25.491,
            38.002,
            11.118,
            25.491,
            38.002,
            11.118,
            24.696,
            38.301,
            11.118,
            24.696,
            38.301,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1085",
        "polygon": [
            28.151,
            37.002,
            10.698,
            28.53,
            36.859,
            10.698,
            28.53,
            36.859,
            11.118,
            28.53,
            36.859,
            11.118,
            28.151,
            37.002,
            11.118,
            28.151,
            37.002,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1086",
        "polygon": [
            28.151,
            37.002,
            7.85,
            28.53,
            36.859,
            7.85,
            28.53,
            36.859,
            10.698,
            28.53,
            36.859,
            10.698,
            28.151,
            37.002,
            10.698,
            28.151,
            37.002,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1087",
        "polygon": [
            27.317,
            37.316,
            7.912,
            28.112,
            37.017,
            7.912,
            28.112,
            37.017,
            11.118,
            28.112,
            37.017,
            11.118,
            27.317,
            37.316,
            11.118,
            27.317,
            37.316,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1088",
        "polygon": [
            30.772,
            36.017,
            10.698,
            31.151,
            35.874,
            10.698,
            31.151,
            35.874,
            11.118,
            31.151,
            35.874,
            11.118,
            30.772,
            36.017,
            11.118,
            30.772,
            36.017,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1089",
        "polygon": [
            30.772,
            36.017,
            7.85,
            31.151,
            35.874,
            7.85,
            31.151,
            35.874,
            10.698,
            31.151,
            35.874,
            10.698,
            30.772,
            36.017,
            10.698,
            30.772,
            36.017,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1090",
        "polygon": [
            29.938,
            36.33,
            7.912,
            30.733,
            36.031,
            7.912,
            30.733,
            36.031,
            11.118,
            30.733,
            36.031,
            11.118,
            29.938,
            36.33,
            11.118,
            29.938,
            36.33,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1091",
        "polygon": [
            33.393,
            35.031,
            10.698,
            33.772,
            34.889,
            10.698,
            33.772,
            34.889,
            11.118,
            33.772,
            34.889,
            11.118,
            33.393,
            35.031,
            11.118,
            33.393,
            35.031,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1092",
        "polygon": [
            33.393,
            35.031,
            7.85,
            33.772,
            34.889,
            7.85,
            33.772,
            34.889,
            10.698,
            33.772,
            34.889,
            10.698,
            33.393,
            35.031,
            10.698,
            33.393,
            35.031,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1093",
        "polygon": [
            32.559,
            35.345,
            7.912,
            33.354,
            35.046,
            7.912,
            33.354,
            35.046,
            11.118,
            33.354,
            35.046,
            11.118,
            32.559,
            35.345,
            11.118,
            32.559,
            35.345,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1094",
        "polygon": [
            36.014,
            34.046,
            10.698,
            36.393,
            33.903,
            10.698,
            36.393,
            33.903,
            11.118,
            36.393,
            33.903,
            11.118,
            36.014,
            34.046,
            11.118,
            36.014,
            34.046,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1095",
        "polygon": [
            36.014,
            34.046,
            7.85,
            36.393,
            33.903,
            7.85,
            36.393,
            33.903,
            10.698,
            36.393,
            33.903,
            10.698,
            36.014,
            34.046,
            10.698,
            36.014,
            34.046,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1096",
        "polygon": [
            35.18,
            34.359,
            7.912,
            35.975,
            34.061,
            7.912,
            35.975,
            34.061,
            11.118,
            35.975,
            34.061,
            11.118,
            35.18,
            34.359,
            11.118,
            35.18,
            34.359,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1097",
        "polygon": [
            -10.206,
            45.44,
            11.622,
            -8.823,
            44.92,
            11.622,
            -8.823,
            44.92,
            14.828,
            -8.823,
            44.92,
            14.828,
            -10.206,
            45.44,
            14.828,
            -10.206,
            45.44,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1098",
        "polygon": [
            3.069,
            40.449,
            11.622,
            4.452,
            39.929,
            11.622,
            4.452,
            39.929,
            14.828,
            4.452,
            39.929,
            14.828,
            3.069,
            40.449,
            14.828,
            3.069,
            40.449,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1099",
        "polygon": [
            0.448,
            41.435,
            11.622,
            1.661,
            40.979,
            11.622,
            1.661,
            40.979,
            14.828,
            1.661,
            40.979,
            14.828,
            0.448,
            41.435,
            14.828,
            0.448,
            41.435,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1100",
        "polygon": [
            -2.173,
            42.42,
            11.622,
            -0.96,
            41.964,
            11.622,
            -0.96,
            41.964,
            14.828,
            -0.96,
            41.964,
            14.828,
            -2.173,
            42.42,
            14.828,
            -2.173,
            42.42,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1101",
        "polygon": [
            -4.794,
            43.405,
            11.622,
            -3.581,
            42.949,
            11.622,
            -3.581,
            42.949,
            14.828,
            -3.581,
            42.949,
            14.828,
            -4.794,
            43.405,
            14.828,
            -4.794,
            43.405,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1102",
        "polygon": [
            -7.415,
            44.391,
            11.622,
            -6.202,
            43.935,
            11.622,
            -6.202,
            43.935,
            14.828,
            -6.202,
            43.935,
            14.828,
            -7.415,
            44.391,
            14.828,
            -7.415,
            44.391,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1103",
        "polygon": [
            -8.307,
            44.726,
            11.622,
            -7.512,
            44.427,
            11.622,
            -7.512,
            44.427,
            14.828,
            -7.512,
            44.427,
            14.828,
            -8.307,
            44.726,
            14.828,
            -8.307,
            44.726,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1104",
        "polygon": [
            -8.725,
            44.883,
            14.408,
            -8.346,
            44.741,
            14.408,
            -8.346,
            44.741,
            14.828,
            -8.346,
            44.741,
            14.828,
            -8.725,
            44.883,
            14.828,
            -8.725,
            44.883,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1105",
        "polygon": [
            -8.725,
            44.883,
            11.56,
            -8.346,
            44.741,
            11.56,
            -8.346,
            44.741,
            14.408,
            -8.346,
            44.741,
            14.408,
            -8.725,
            44.883,
            14.408,
            -8.725,
            44.883,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1106",
        "polygon": [
            2.177,
            40.785,
            11.622,
            2.971,
            40.486,
            11.622,
            2.971,
            40.486,
            14.828,
            2.971,
            40.486,
            14.828,
            2.177,
            40.785,
            14.828,
            2.177,
            40.785,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1107",
        "polygon": [
            1.758,
            40.942,
            14.408,
            2.137,
            40.799,
            14.408,
            2.137,
            40.799,
            14.828,
            2.137,
            40.799,
            14.828,
            1.758,
            40.942,
            14.828,
            1.758,
            40.942,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1108",
        "polygon": [
            1.758,
            40.942,
            11.56,
            2.137,
            40.799,
            11.56,
            2.137,
            40.799,
            14.408,
            2.137,
            40.799,
            14.408,
            1.758,
            40.942,
            14.408,
            1.758,
            40.942,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1109",
        "polygon": [
            -6.104,
            43.898,
            11.622,
            -4.891,
            43.442,
            11.622,
            -4.891,
            43.442,
            14.349,
            -4.891,
            43.442,
            14.349,
            -6.104,
            43.898,
            14.349,
            -6.104,
            43.898,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1110",
        "polygon": [
            -6.104,
            43.898,
            14.408,
            -4.891,
            43.442,
            14.408,
            -4.891,
            43.442,
            14.828,
            -4.891,
            43.442,
            14.828,
            -6.104,
            43.898,
            14.828,
            -6.104,
            43.898,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1111",
        "polygon": [
            -3.483,
            42.913,
            11.622,
            -2.27,
            42.456,
            11.622,
            -2.27,
            42.456,
            14.349,
            -2.27,
            42.456,
            14.349,
            -3.483,
            42.913,
            14.349,
            -3.483,
            42.913,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1112",
        "polygon": [
            -3.483,
            42.913,
            14.408,
            -2.27,
            42.456,
            14.408,
            -2.27,
            42.456,
            14.828,
            -2.27,
            42.456,
            14.828,
            -3.483,
            42.913,
            14.828,
            -3.483,
            42.913,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1113",
        "polygon": [
            -0.863,
            41.927,
            11.622,
            0.351,
            41.471,
            11.622,
            0.351,
            41.471,
            14.349,
            0.351,
            41.471,
            14.349,
            -0.863,
            41.927,
            14.349,
            -0.863,
            41.927,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1114",
        "polygon": [
            -0.863,
            41.927,
            14.408,
            0.351,
            41.471,
            14.408,
            0.351,
            41.471,
            14.828,
            0.351,
            41.471,
            14.828,
            -0.863,
            41.927,
            14.828,
            -0.863,
            41.927,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_39",
        "polygon": [
            -127.722,
            42.999,
            4.202,
            -127.202,
            44.382,
            4.202,
            -127.202,
            44.382,
            7.408,
            -127.202,
            44.382,
            7.408,
            -127.722,
            42.999,
            7.408,
            -127.722,
            42.999,
            4.202
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_37",
        "polygon": [
            -126.673,
            45.79,
            4.202,
            -126.217,
            47.003,
            4.202,
            -126.217,
            47.003,
            7.408,
            -126.217,
            47.003,
            7.408,
            -126.673,
            45.79,
            7.408,
            -126.673,
            45.79,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_35",
        "polygon": [
            -125.687,
            48.411,
            4.202,
            -125.231,
            49.624,
            4.202,
            -125.231,
            49.624,
            7.408,
            -125.231,
            49.624,
            7.408,
            -125.687,
            48.411,
            7.408,
            -125.687,
            48.411,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_33",
        "polygon": [
            -124.702,
            51.032,
            4.202,
            -124.246,
            52.245,
            4.202,
            -124.246,
            52.245,
            7.408,
            -124.246,
            52.245,
            7.408,
            -124.702,
            51.032,
            7.408,
            -124.702,
            51.032,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_31",
        "polygon": [
            -123.717,
            53.653,
            4.202,
            -123.261,
            54.866,
            4.202,
            -123.261,
            54.866,
            7.408,
            -123.261,
            54.866,
            7.408,
            -123.717,
            53.653,
            7.408,
            -123.717,
            53.653,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_29",
        "polygon": [
            -122.731,
            56.274,
            4.202,
            -122.275,
            57.487,
            4.202,
            -122.275,
            57.487,
            7.408,
            -122.275,
            57.487,
            7.408,
            -122.731,
            56.274,
            7.408,
            -122.731,
            56.274,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_27",
        "polygon": [
            -121.746,
            58.895,
            4.202,
            -121.29,
            60.108,
            4.202,
            -121.29,
            60.108,
            7.408,
            -121.29,
            60.108,
            7.408,
            -121.746,
            58.895,
            7.408,
            -121.746,
            58.895,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_25",
        "polygon": [
            -120.761,
            61.516,
            4.202,
            -120.305,
            62.729,
            4.202,
            -120.305,
            62.729,
            7.408,
            -120.305,
            62.729,
            7.408,
            -120.761,
            61.516,
            7.408,
            -120.761,
            61.516,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_23",
        "polygon": [
            -119.775,
            64.137,
            4.202,
            -119.319,
            65.35,
            4.202,
            -119.319,
            65.35,
            7.408,
            -119.319,
            65.35,
            7.408,
            -119.775,
            64.137,
            7.408,
            -119.775,
            64.137,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_21",
        "polygon": [
            -118.79,
            66.757,
            4.202,
            -118.334,
            67.971,
            4.202,
            -118.334,
            67.971,
            7.408,
            -118.334,
            67.971,
            7.408,
            -118.79,
            66.757,
            7.408,
            -118.79,
            66.757,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_19",
        "polygon": [
            -117.805,
            69.378,
            4.202,
            -117.348,
            70.591,
            4.202,
            -117.348,
            70.591,
            7.408,
            -117.348,
            70.591,
            7.408,
            -117.805,
            69.378,
            7.408,
            -117.805,
            69.378,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_17",
        "polygon": [
            -116.819,
            71.999,
            4.202,
            -116.363,
            73.212,
            4.202,
            -116.363,
            73.212,
            7.408,
            -116.363,
            73.212,
            7.408,
            -116.819,
            71.999,
            7.408,
            -116.819,
            71.999,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_15",
        "polygon": [
            -115.834,
            74.62,
            4.202,
            -115.378,
            75.833,
            4.202,
            -115.378,
            75.833,
            7.408,
            -115.378,
            75.833,
            7.408,
            -115.834,
            74.62,
            7.408,
            -115.834,
            74.62,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_13",
        "polygon": [
            -114.848,
            77.241,
            4.202,
            -114.392,
            78.454,
            4.202,
            -114.392,
            78.454,
            7.408,
            -114.392,
            78.454,
            7.408,
            -114.848,
            77.241,
            7.408,
            -114.848,
            77.241,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_11",
        "polygon": [
            -113.863,
            79.862,
            4.202,
            -113.407,
            81.075,
            4.202,
            -113.407,
            81.075,
            7.408,
            -113.407,
            81.075,
            7.408,
            -113.863,
            79.862,
            7.408,
            -113.863,
            79.862,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_9",
        "polygon": [
            -112.878,
            82.483,
            4.202,
            -112.422,
            83.696,
            4.202,
            -112.422,
            83.696,
            7.408,
            -112.422,
            83.696,
            7.408,
            -112.878,
            82.483,
            7.408,
            -112.878,
            82.483,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_7",
        "polygon": [
            -111.892,
            85.104,
            4.202,
            -111.436,
            86.317,
            4.202,
            -111.436,
            86.317,
            7.408,
            -111.436,
            86.317,
            7.408,
            -111.892,
            85.104,
            7.408,
            -111.892,
            85.104,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_5",
        "polygon": [
            -110.907,
            87.725,
            4.202,
            -110.451,
            88.938,
            4.202,
            -110.451,
            88.938,
            7.408,
            -110.451,
            88.938,
            7.408,
            -110.907,
            87.725,
            7.408,
            -110.907,
            87.725,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_3",
        "polygon": [
            -109.922,
            90.345,
            4.202,
            -109.466,
            91.559,
            4.202,
            -109.466,
            91.559,
            7.408,
            -109.466,
            91.559,
            7.408,
            -109.922,
            90.345,
            7.408,
            -109.922,
            90.345,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_1",
        "polygon": [
            -108.936,
            92.966,
            4.202,
            -108.416,
            94.35,
            4.202,
            -108.416,
            94.35,
            7.408,
            -108.416,
            94.35,
            7.408,
            -108.936,
            92.966,
            7.408,
            -108.936,
            92.966,
            4.202
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_39",
        "polygon": [
            -127.722,
            42.999,
            7.912,
            -127.202,
            44.382,
            7.912,
            -127.202,
            44.382,
            11.118,
            -127.202,
            44.382,
            11.118,
            -127.722,
            42.999,
            11.118,
            -127.722,
            42.999,
            7.912
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_37",
        "polygon": [
            -126.673,
            45.79,
            7.912,
            -126.217,
            47.003,
            7.912,
            -126.217,
            47.003,
            11.118,
            -126.217,
            47.003,
            11.118,
            -126.673,
            45.79,
            11.118,
            -126.673,
            45.79,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_1",
        "polygon": [
            -108.936,
            92.966,
            7.912,
            -108.416,
            94.35,
            7.912,
            -108.416,
            94.35,
            11.118,
            -108.416,
            94.35,
            11.118,
            -108.936,
            92.966,
            11.118,
            -108.936,
            92.966,
            7.912
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_35",
        "polygon": [
            -125.687,
            48.411,
            7.912,
            -125.231,
            49.624,
            7.912,
            -125.231,
            49.624,
            11.118,
            -125.231,
            49.624,
            11.118,
            -125.687,
            48.411,
            11.118,
            -125.687,
            48.411,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_33",
        "polygon": [
            -124.702,
            51.032,
            7.912,
            -124.246,
            52.245,
            7.912,
            -124.246,
            52.245,
            11.118,
            -124.246,
            52.245,
            11.118,
            -124.702,
            51.032,
            11.118,
            -124.702,
            51.032,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_31",
        "polygon": [
            -123.717,
            53.653,
            7.912,
            -123.261,
            54.866,
            7.912,
            -123.261,
            54.866,
            11.118,
            -123.261,
            54.866,
            11.118,
            -123.717,
            53.653,
            11.118,
            -123.717,
            53.653,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_29",
        "polygon": [
            -122.731,
            56.274,
            7.912,
            -122.275,
            57.487,
            7.912,
            -122.275,
            57.487,
            11.118,
            -122.275,
            57.487,
            11.118,
            -122.731,
            56.274,
            11.118,
            -122.731,
            56.274,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_27",
        "polygon": [
            -121.746,
            58.895,
            7.912,
            -121.29,
            60.108,
            7.912,
            -121.29,
            60.108,
            11.118,
            -121.29,
            60.108,
            11.118,
            -121.746,
            58.895,
            11.118,
            -121.746,
            58.895,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_25",
        "polygon": [
            -120.761,
            61.516,
            7.912,
            -120.305,
            62.729,
            7.912,
            -120.305,
            62.729,
            11.118,
            -120.305,
            62.729,
            11.118,
            -120.761,
            61.516,
            11.118,
            -120.761,
            61.516,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_23",
        "polygon": [
            -119.775,
            64.137,
            7.912,
            -119.319,
            65.35,
            7.912,
            -119.319,
            65.35,
            11.118,
            -119.319,
            65.35,
            11.118,
            -119.775,
            64.137,
            11.118,
            -119.775,
            64.137,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_21",
        "polygon": [
            -118.79,
            66.757,
            7.912,
            -118.334,
            67.971,
            7.912,
            -118.334,
            67.971,
            11.118,
            -118.334,
            67.971,
            11.118,
            -118.79,
            66.757,
            11.118,
            -118.79,
            66.757,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_19",
        "polygon": [
            -117.805,
            69.378,
            7.912,
            -117.348,
            70.591,
            7.912,
            -117.348,
            70.591,
            11.118,
            -117.348,
            70.591,
            11.118,
            -117.805,
            69.378,
            11.118,
            -117.805,
            69.378,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_17",
        "polygon": [
            -116.819,
            71.999,
            7.912,
            -116.363,
            73.212,
            7.912,
            -116.363,
            73.212,
            11.118,
            -116.363,
            73.212,
            11.118,
            -116.819,
            71.999,
            11.118,
            -116.819,
            71.999,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_15",
        "polygon": [
            -115.834,
            74.62,
            7.912,
            -115.378,
            75.833,
            7.912,
            -115.378,
            75.833,
            11.118,
            -115.378,
            75.833,
            11.118,
            -115.834,
            74.62,
            11.118,
            -115.834,
            74.62,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_13",
        "polygon": [
            -114.848,
            77.241,
            7.912,
            -114.392,
            78.454,
            7.912,
            -114.392,
            78.454,
            11.118,
            -114.392,
            78.454,
            11.118,
            -114.848,
            77.241,
            11.118,
            -114.848,
            77.241,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_11",
        "polygon": [
            -113.863,
            79.862,
            7.912,
            -113.407,
            81.075,
            7.912,
            -113.407,
            81.075,
            11.118,
            -113.407,
            81.075,
            11.118,
            -113.863,
            79.862,
            11.118,
            -113.863,
            79.862,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_9",
        "polygon": [
            -112.878,
            82.483,
            7.912,
            -112.422,
            83.696,
            7.912,
            -112.422,
            83.696,
            11.118,
            -112.422,
            83.696,
            11.118,
            -112.878,
            82.483,
            11.118,
            -112.878,
            82.483,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_7",
        "polygon": [
            -111.892,
            85.104,
            7.912,
            -111.436,
            86.317,
            7.912,
            -111.436,
            86.317,
            11.118,
            -111.436,
            86.317,
            11.118,
            -111.892,
            85.104,
            11.118,
            -111.892,
            85.104,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_5",
        "polygon": [
            -110.907,
            87.725,
            7.912,
            -110.451,
            88.938,
            7.912,
            -110.451,
            88.938,
            11.118,
            -110.451,
            88.938,
            11.118,
            -110.907,
            87.725,
            11.118,
            -110.907,
            87.725,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_3",
        "polygon": [
            -109.922,
            90.345,
            7.912,
            -109.466,
            91.559,
            7.912,
            -109.466,
            91.559,
            11.118,
            -109.466,
            91.559,
            11.118,
            -109.922,
            90.345,
            11.118,
            -109.922,
            90.345,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_39",
        "polygon": [
            -127.722,
            42.999,
            11.622,
            -127.202,
            44.382,
            11.622,
            -127.202,
            44.382,
            14.828,
            -127.202,
            44.382,
            14.828,
            -127.722,
            42.999,
            14.828,
            -127.722,
            42.999,
            11.622
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_37",
        "polygon": [
            -126.673,
            45.79,
            11.622,
            -126.217,
            47.003,
            11.622,
            -126.217,
            47.003,
            14.828,
            -126.217,
            47.003,
            14.828,
            -126.673,
            45.79,
            14.828,
            -126.673,
            45.79,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_35",
        "polygon": [
            -125.687,
            48.411,
            11.622,
            -125.231,
            49.624,
            11.622,
            -125.231,
            49.624,
            14.828,
            -125.231,
            49.624,
            14.828,
            -125.687,
            48.411,
            14.828,
            -125.687,
            48.411,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_33",
        "polygon": [
            -124.702,
            51.032,
            11.622,
            -124.246,
            52.245,
            11.622,
            -124.246,
            52.245,
            14.828,
            -124.246,
            52.245,
            14.828,
            -124.702,
            51.032,
            14.828,
            -124.702,
            51.032,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_31",
        "polygon": [
            -123.717,
            53.653,
            11.622,
            -123.261,
            54.866,
            11.622,
            -123.261,
            54.866,
            14.828,
            -123.261,
            54.866,
            14.828,
            -123.717,
            53.653,
            14.828,
            -123.717,
            53.653,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_29",
        "polygon": [
            -122.731,
            56.274,
            11.622,
            -122.275,
            57.487,
            11.622,
            -122.275,
            57.487,
            14.828,
            -122.275,
            57.487,
            14.828,
            -122.731,
            56.274,
            14.828,
            -122.731,
            56.274,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_27",
        "polygon": [
            -121.746,
            58.895,
            11.622,
            -121.29,
            60.108,
            11.622,
            -121.29,
            60.108,
            14.828,
            -121.29,
            60.108,
            14.828,
            -121.746,
            58.895,
            14.828,
            -121.746,
            58.895,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_25",
        "polygon": [
            -120.761,
            61.516,
            11.622,
            -120.305,
            62.729,
            11.622,
            -120.305,
            62.729,
            14.828,
            -120.305,
            62.729,
            14.828,
            -120.761,
            61.516,
            14.828,
            -120.761,
            61.516,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_23",
        "polygon": [
            -119.775,
            64.137,
            11.622,
            -119.319,
            65.35,
            11.622,
            -119.319,
            65.35,
            14.828,
            -119.319,
            65.35,
            14.828,
            -119.775,
            64.137,
            14.828,
            -119.775,
            64.137,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_21",
        "polygon": [
            -118.79,
            66.757,
            11.622,
            -118.334,
            67.971,
            11.622,
            -118.334,
            67.971,
            14.828,
            -118.334,
            67.971,
            14.828,
            -118.79,
            66.757,
            14.828,
            -118.79,
            66.757,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_19",
        "polygon": [
            -117.805,
            69.378,
            11.622,
            -117.348,
            70.591,
            11.622,
            -117.348,
            70.591,
            14.828,
            -117.348,
            70.591,
            14.828,
            -117.805,
            69.378,
            14.828,
            -117.805,
            69.378,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_17",
        "polygon": [
            -116.819,
            71.999,
            11.622,
            -116.363,
            73.212,
            11.622,
            -116.363,
            73.212,
            14.828,
            -116.363,
            73.212,
            14.828,
            -116.819,
            71.999,
            14.828,
            -116.819,
            71.999,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_15",
        "polygon": [
            -115.834,
            74.62,
            11.622,
            -115.378,
            75.833,
            11.622,
            -115.378,
            75.833,
            14.828,
            -115.378,
            75.833,
            14.828,
            -115.834,
            74.62,
            14.828,
            -115.834,
            74.62,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_13",
        "polygon": [
            -114.848,
            77.241,
            11.622,
            -114.392,
            78.454,
            11.622,
            -114.392,
            78.454,
            14.828,
            -114.392,
            78.454,
            14.828,
            -114.848,
            77.241,
            14.828,
            -114.848,
            77.241,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_11",
        "polygon": [
            -113.863,
            79.862,
            11.622,
            -113.407,
            81.075,
            11.622,
            -113.407,
            81.075,
            14.828,
            -113.407,
            81.075,
            14.828,
            -113.863,
            79.862,
            14.828,
            -113.863,
            79.862,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_3",
        "polygon": [
            -123.701,
            41.594,
            4.202,
            -124.914,
            42.05,
            4.202,
            -124.914,
            42.05,
            7.408,
            -124.914,
            42.05,
            7.408,
            -123.701,
            41.594,
            7.408,
            -123.701,
            41.594,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_1",
        "polygon": [
            -126.322,
            42.58,
            4.202,
            -127.706,
            43.1,
            4.202,
            -127.706,
            43.1,
            7.408,
            -127.706,
            43.1,
            7.408,
            -126.322,
            42.58,
            7.408,
            -126.322,
            42.58,
            4.202
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_5",
        "polygon": [
            -121.08,
            40.609,
            4.202,
            -122.293,
            41.065,
            4.202,
            -122.293,
            41.065,
            7.408,
            -122.293,
            41.065,
            7.408,
            -121.08,
            40.609,
            7.408,
            -121.08,
            40.609,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_7",
        "polygon": [
            -118.459,
            39.623,
            4.202,
            -119.673,
            40.08,
            4.202,
            -119.673,
            40.08,
            7.408,
            -119.673,
            40.08,
            7.408,
            -118.459,
            39.623,
            7.408,
            -118.459,
            39.623,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_9",
        "polygon": [
            -115.839,
            38.638,
            4.202,
            -117.052,
            39.094,
            4.202,
            -117.052,
            39.094,
            7.408,
            -117.052,
            39.094,
            7.408,
            -115.839,
            38.638,
            7.408,
            -115.839,
            38.638,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_11",
        "polygon": [
            -113.218,
            37.653,
            4.202,
            -114.431,
            38.109,
            4.202,
            -114.431,
            38.109,
            7.408,
            -114.431,
            38.109,
            7.408,
            -113.218,
            37.653,
            7.408,
            -113.218,
            37.653,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_15",
        "polygon": [
            -107.976,
            35.682,
            4.202,
            -109.189,
            36.138,
            4.202,
            -109.189,
            36.138,
            7.408,
            -109.189,
            36.138,
            7.408,
            -107.976,
            35.682,
            7.408,
            -107.976,
            35.682,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_19",
        "polygon": [
            -102.734,
            33.711,
            4.202,
            -103.947,
            34.167,
            4.202,
            -103.947,
            34.167,
            7.408,
            -103.947,
            34.167,
            7.408,
            -102.734,
            33.711,
            7.408,
            -102.734,
            33.711,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_21",
        "polygon": [
            -100.113,
            32.726,
            4.202,
            -101.326,
            33.182,
            4.202,
            -101.326,
            33.182,
            7.408,
            -101.326,
            33.182,
            7.408,
            -100.113,
            32.726,
            7.408,
            -100.113,
            32.726,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_23",
        "polygon": [
            -97.492,
            31.741,
            4.202,
            -98.705,
            32.197,
            4.202,
            -98.705,
            32.197,
            7.408,
            -98.705,
            32.197,
            7.408,
            -97.492,
            31.741,
            7.408,
            -97.492,
            31.741,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_27",
        "polygon": [
            -92.251,
            29.77,
            4.202,
            -93.464,
            30.226,
            4.202,
            -93.464,
            30.226,
            7.408,
            -93.464,
            30.226,
            7.408,
            -92.251,
            29.77,
            7.408,
            -92.251,
            29.77,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_29",
        "polygon": [
            -89.63,
            28.785,
            4.202,
            -90.843,
            29.241,
            4.202,
            -90.843,
            29.241,
            7.408,
            -90.843,
            29.241,
            7.408,
            -89.63,
            28.785,
            7.408,
            -89.63,
            28.785,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_31",
        "polygon": [
            -87.009,
            27.799,
            4.202,
            -88.222,
            28.255,
            4.202,
            -88.222,
            28.255,
            7.408,
            -88.222,
            28.255,
            7.408,
            -87.009,
            27.799,
            7.408,
            -87.009,
            27.799,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_35",
        "polygon": [
            -81.767,
            25.829,
            4.202,
            -82.98,
            26.285,
            4.202,
            -82.98,
            26.285,
            7.408,
            -82.98,
            26.285,
            7.408,
            -81.767,
            25.829,
            7.408,
            -81.767,
            25.829,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_37",
        "polygon": [
            -78.976,
            24.779,
            4.202,
            -80.359,
            25.299,
            4.202,
            -80.359,
            25.299,
            7.408,
            -80.359,
            25.299,
            7.408,
            -78.976,
            24.779,
            7.408,
            -78.976,
            24.779,
            4.202
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_13",
        "polygon": [
            -110.597,
            36.667,
            4.202,
            -111.81,
            37.124,
            4.202,
            -111.81,
            37.124,
            7.408,
            -111.81,
            37.124,
            7.408,
            -110.597,
            36.667,
            7.408,
            -110.597,
            36.667,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_17",
        "polygon": [
            -105.355,
            34.697,
            4.202,
            -106.568,
            35.153,
            4.202,
            -106.568,
            35.153,
            7.408,
            -106.568,
            35.153,
            7.408,
            -105.355,
            34.697,
            7.408,
            -105.355,
            34.697,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_25",
        "polygon": [
            -94.871,
            30.755,
            4.202,
            -96.085,
            31.211,
            4.202,
            -96.085,
            31.211,
            7.408,
            -96.085,
            31.211,
            7.408,
            -94.871,
            30.755,
            7.408,
            -94.871,
            30.755,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_33",
        "polygon": [
            -84.388,
            26.814,
            4.202,
            -85.601,
            27.27,
            4.202,
            -85.601,
            27.27,
            7.408,
            -85.601,
            27.27,
            7.408,
            -84.388,
            26.814,
            7.408,
            -84.388,
            26.814,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_3",
        "polygon": [
            -123.701,
            41.594,
            11.622,
            -124.914,
            42.05,
            11.622,
            -124.914,
            42.05,
            14.828,
            -124.914,
            42.05,
            14.828,
            -123.701,
            41.594,
            14.828,
            -123.701,
            41.594,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_1",
        "polygon": [
            -126.322,
            42.58,
            11.622,
            -127.706,
            43.1,
            11.622,
            -127.706,
            43.1,
            14.828,
            -127.706,
            43.1,
            14.828,
            -126.322,
            42.58,
            14.828,
            -126.322,
            42.58,
            11.622
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_5",
        "polygon": [
            -121.08,
            40.609,
            11.622,
            -122.293,
            41.065,
            11.622,
            -122.293,
            41.065,
            14.828,
            -122.293,
            41.065,
            14.828,
            -121.08,
            40.609,
            14.828,
            -121.08,
            40.609,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_7",
        "polygon": [
            -118.459,
            39.623,
            11.622,
            -119.673,
            40.08,
            11.622,
            -119.673,
            40.08,
            14.828,
            -119.673,
            40.08,
            14.828,
            -118.459,
            39.623,
            14.828,
            -118.459,
            39.623,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_9",
        "polygon": [
            -115.839,
            38.638,
            11.622,
            -117.052,
            39.094,
            11.622,
            -117.052,
            39.094,
            14.828,
            -117.052,
            39.094,
            14.828,
            -115.839,
            38.638,
            14.828,
            -115.839,
            38.638,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_11",
        "polygon": [
            -113.218,
            37.653,
            11.622,
            -114.431,
            38.109,
            11.622,
            -114.431,
            38.109,
            14.828,
            -114.431,
            38.109,
            14.828,
            -113.218,
            37.653,
            14.828,
            -113.218,
            37.653,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_15",
        "polygon": [
            -107.976,
            35.682,
            11.622,
            -109.189,
            36.138,
            11.622,
            -109.189,
            36.138,
            14.828,
            -109.189,
            36.138,
            14.828,
            -107.976,
            35.682,
            14.828,
            -107.976,
            35.682,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_19",
        "polygon": [
            -102.734,
            33.711,
            11.622,
            -103.947,
            34.167,
            11.622,
            -103.947,
            34.167,
            14.828,
            -103.947,
            34.167,
            14.828,
            -102.734,
            33.711,
            14.828,
            -102.734,
            33.711,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_21",
        "polygon": [
            -100.113,
            32.726,
            11.622,
            -101.326,
            33.182,
            11.622,
            -101.326,
            33.182,
            14.828,
            -101.326,
            33.182,
            14.828,
            -100.113,
            32.726,
            14.828,
            -100.113,
            32.726,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_23",
        "polygon": [
            -97.492,
            31.741,
            11.622,
            -98.705,
            32.197,
            11.622,
            -98.705,
            32.197,
            14.828,
            -98.705,
            32.197,
            14.828,
            -97.492,
            31.741,
            14.828,
            -97.492,
            31.741,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_27",
        "polygon": [
            -92.251,
            29.77,
            11.622,
            -93.464,
            30.226,
            11.622,
            -93.464,
            30.226,
            14.828,
            -93.464,
            30.226,
            14.828,
            -92.251,
            29.77,
            14.828,
            -92.251,
            29.77,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_29",
        "polygon": [
            -89.63,
            28.785,
            11.622,
            -90.843,
            29.241,
            11.622,
            -90.843,
            29.241,
            14.828,
            -90.843,
            29.241,
            14.828,
            -89.63,
            28.785,
            14.828,
            -89.63,
            28.785,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_31",
        "polygon": [
            -87.009,
            27.799,
            11.622,
            -88.222,
            28.255,
            11.622,
            -88.222,
            28.255,
            14.828,
            -88.222,
            28.255,
            14.828,
            -87.009,
            27.799,
            14.828,
            -87.009,
            27.799,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_35",
        "polygon": [
            -81.767,
            25.829,
            11.622,
            -82.98,
            26.285,
            11.622,
            -82.98,
            26.285,
            14.828,
            -82.98,
            26.285,
            14.828,
            -81.767,
            25.829,
            14.828,
            -81.767,
            25.829,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_37",
        "polygon": [
            -78.976,
            24.779,
            11.622,
            -80.359,
            25.299,
            11.622,
            -80.359,
            25.299,
            14.828,
            -80.359,
            25.299,
            14.828,
            -78.976,
            24.779,
            14.828,
            -78.976,
            24.779,
            11.622
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_13",
        "polygon": [
            -110.597,
            36.667,
            11.622,
            -111.81,
            37.124,
            11.622,
            -111.81,
            37.124,
            14.828,
            -111.81,
            37.124,
            14.828,
            -110.597,
            36.667,
            14.828,
            -110.597,
            36.667,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_17",
        "polygon": [
            -105.355,
            34.697,
            11.622,
            -106.568,
            35.153,
            11.622,
            -106.568,
            35.153,
            14.828,
            -106.568,
            35.153,
            14.828,
            -105.355,
            34.697,
            14.828,
            -105.355,
            34.697,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_25",
        "polygon": [
            -94.871,
            30.755,
            11.622,
            -96.085,
            31.211,
            11.622,
            -96.085,
            31.211,
            14.828,
            -96.085,
            31.211,
            14.828,
            -94.871,
            30.755,
            14.828,
            -94.871,
            30.755,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_33",
        "polygon": [
            -84.388,
            26.814,
            11.622,
            -85.601,
            27.27,
            11.622,
            -85.601,
            27.27,
            14.828,
            -85.601,
            27.27,
            14.828,
            -84.388,
            26.814,
            14.828,
            -84.388,
            26.814,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_3",
        "polygon": [
            -123.701,
            41.594,
            7.912,
            -124.914,
            42.05,
            7.912,
            -124.914,
            42.05,
            11.118,
            -124.914,
            42.05,
            11.118,
            -123.701,
            41.594,
            11.118,
            -123.701,
            41.594,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_1",
        "polygon": [
            -126.322,
            42.58,
            7.912,
            -127.706,
            43.1,
            7.912,
            -127.706,
            43.1,
            11.118,
            -127.706,
            43.1,
            11.118,
            -126.322,
            42.58,
            11.118,
            -126.322,
            42.58,
            7.912
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_5",
        "polygon": [
            -121.08,
            40.609,
            7.912,
            -122.293,
            41.065,
            7.912,
            -122.293,
            41.065,
            11.118,
            -122.293,
            41.065,
            11.118,
            -121.08,
            40.609,
            11.118,
            -121.08,
            40.609,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_7",
        "polygon": [
            -118.459,
            39.623,
            7.912,
            -119.673,
            40.08,
            7.912,
            -119.673,
            40.08,
            11.118,
            -119.673,
            40.08,
            11.118,
            -118.459,
            39.623,
            11.118,
            -118.459,
            39.623,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_9",
        "polygon": [
            -115.839,
            38.638,
            7.912,
            -117.052,
            39.094,
            7.912,
            -117.052,
            39.094,
            11.118,
            -117.052,
            39.094,
            11.118,
            -115.839,
            38.638,
            11.118,
            -115.839,
            38.638,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_11",
        "polygon": [
            -113.218,
            37.653,
            7.912,
            -114.431,
            38.109,
            7.912,
            -114.431,
            38.109,
            11.118,
            -114.431,
            38.109,
            11.118,
            -113.218,
            37.653,
            11.118,
            -113.218,
            37.653,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_15",
        "polygon": [
            -107.976,
            35.682,
            7.912,
            -109.189,
            36.138,
            7.912,
            -109.189,
            36.138,
            11.118,
            -109.189,
            36.138,
            11.118,
            -107.976,
            35.682,
            11.118,
            -107.976,
            35.682,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_19",
        "polygon": [
            -102.734,
            33.711,
            7.912,
            -103.947,
            34.167,
            7.912,
            -103.947,
            34.167,
            11.118,
            -103.947,
            34.167,
            11.118,
            -102.734,
            33.711,
            11.118,
            -102.734,
            33.711,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_21",
        "polygon": [
            -100.113,
            32.726,
            7.912,
            -101.326,
            33.182,
            7.912,
            -101.326,
            33.182,
            11.118,
            -101.326,
            33.182,
            11.118,
            -100.113,
            32.726,
            11.118,
            -100.113,
            32.726,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_23",
        "polygon": [
            -97.492,
            31.741,
            7.912,
            -98.705,
            32.197,
            7.912,
            -98.705,
            32.197,
            11.118,
            -98.705,
            32.197,
            11.118,
            -97.492,
            31.741,
            11.118,
            -97.492,
            31.741,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_27",
        "polygon": [
            -92.251,
            29.77,
            7.912,
            -93.464,
            30.226,
            7.912,
            -93.464,
            30.226,
            11.118,
            -93.464,
            30.226,
            11.118,
            -92.251,
            29.77,
            11.118,
            -92.251,
            29.77,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_29",
        "polygon": [
            -89.63,
            28.785,
            7.912,
            -90.843,
            29.241,
            7.912,
            -90.843,
            29.241,
            11.118,
            -90.843,
            29.241,
            11.118,
            -89.63,
            28.785,
            11.118,
            -89.63,
            28.785,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_31",
        "polygon": [
            -87.009,
            27.799,
            7.912,
            -88.222,
            28.255,
            7.912,
            -88.222,
            28.255,
            11.118,
            -88.222,
            28.255,
            11.118,
            -87.009,
            27.799,
            11.118,
            -87.009,
            27.799,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_35",
        "polygon": [
            -81.767,
            25.829,
            7.912,
            -82.98,
            26.285,
            7.912,
            -82.98,
            26.285,
            11.118,
            -82.98,
            26.285,
            11.118,
            -81.767,
            25.829,
            11.118,
            -81.767,
            25.829,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_37",
        "polygon": [
            -78.976,
            24.779,
            7.912,
            -80.359,
            25.299,
            7.912,
            -80.359,
            25.299,
            11.118,
            -80.359,
            25.299,
            11.118,
            -78.976,
            24.779,
            11.118,
            -78.976,
            24.779,
            7.912
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_13",
        "polygon": [
            -110.597,
            36.667,
            7.912,
            -111.81,
            37.124,
            7.912,
            -111.81,
            37.124,
            11.118,
            -111.81,
            37.124,
            11.118,
            -110.597,
            36.667,
            11.118,
            -110.597,
            36.667,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_17",
        "polygon": [
            -105.355,
            34.697,
            7.912,
            -106.568,
            35.153,
            7.912,
            -106.568,
            35.153,
            11.118,
            -106.568,
            35.153,
            11.118,
            -105.355,
            34.697,
            11.118,
            -105.355,
            34.697,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_25",
        "polygon": [
            -94.871,
            30.755,
            7.912,
            -96.085,
            31.211,
            7.912,
            -96.085,
            31.211,
            11.118,
            -96.085,
            31.211,
            11.118,
            -94.871,
            30.755,
            11.118,
            -94.871,
            30.755,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_33",
        "polygon": [
            -84.388,
            26.814,
            7.912,
            -85.601,
            27.27,
            7.912,
            -85.601,
            27.27,
            11.118,
            -85.601,
            27.27,
            11.118,
            -84.388,
            26.814,
            11.118,
            -84.388,
            26.814,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_37",
        "polygon": [
            -10.832,
            -0.84,
            4.202,
            -12.216,
            -0.32,
            4.202,
            -12.216,
            -0.32,
            7.408,
            -12.216,
            -0.32,
            7.408,
            -10.832,
            -0.84,
            7.408,
            -10.832,
            -0.84,
            4.202
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_35",
        "polygon": [
            -13.624,
            0.209,
            4.202,
            -14.837,
            0.666,
            4.202,
            -14.837,
            0.666,
            7.408,
            -14.837,
            0.666,
            7.408,
            -13.624,
            0.209,
            7.408,
            -13.624,
            0.209,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_1",
        "polygon": [
            -58.179,
            16.96,
            4.202,
            -59.562,
            17.481,
            4.202,
            -59.562,
            17.481,
            7.408,
            -59.562,
            17.481,
            7.408,
            -58.179,
            16.96,
            7.408,
            -58.179,
            16.96,
            4.202
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_33",
        "polygon": [
            -16.245,
            1.195,
            4.202,
            -17.458,
            1.651,
            4.202,
            -17.458,
            1.651,
            7.408,
            -17.458,
            1.651,
            7.408,
            -16.245,
            1.195,
            7.408,
            -16.245,
            1.195,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_31",
        "polygon": [
            -18.865,
            2.18,
            4.202,
            -20.079,
            2.636,
            4.202,
            -20.079,
            2.636,
            7.408,
            -20.079,
            2.636,
            7.408,
            -18.865,
            2.18,
            7.408,
            -18.865,
            2.18,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_29",
        "polygon": [
            -21.486,
            3.166,
            4.202,
            -22.699,
            3.622,
            4.202,
            -22.699,
            3.622,
            7.408,
            -22.699,
            3.622,
            7.408,
            -21.486,
            3.166,
            7.408,
            -21.486,
            3.166,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_27",
        "polygon": [
            -24.107,
            4.151,
            4.202,
            -25.32,
            4.607,
            4.202,
            -25.32,
            4.607,
            7.408,
            -25.32,
            4.607,
            7.408,
            -24.107,
            4.151,
            7.408,
            -24.107,
            4.151,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_25",
        "polygon": [
            -26.728,
            5.136,
            4.202,
            -27.941,
            5.592,
            4.202,
            -27.941,
            5.592,
            7.408,
            -27.941,
            5.592,
            7.408,
            -26.728,
            5.136,
            7.408,
            -26.728,
            5.136,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_23",
        "polygon": [
            -29.349,
            6.122,
            4.202,
            -30.562,
            6.578,
            4.202,
            -30.562,
            6.578,
            7.408,
            -30.562,
            6.578,
            7.408,
            -29.349,
            6.122,
            7.408,
            -29.349,
            6.122,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_21",
        "polygon": [
            -31.97,
            7.107,
            4.202,
            -33.183,
            7.563,
            4.202,
            -33.183,
            7.563,
            7.408,
            -33.183,
            7.563,
            7.408,
            -31.97,
            7.107,
            7.408,
            -31.97,
            7.107,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_19",
        "polygon": [
            -34.591,
            8.092,
            4.202,
            -35.804,
            8.548,
            4.202,
            -35.804,
            8.548,
            7.408,
            -35.804,
            8.548,
            7.408,
            -34.591,
            8.092,
            7.408,
            -34.591,
            8.092,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_17",
        "polygon": [
            -37.212,
            9.078,
            4.202,
            -38.425,
            9.534,
            4.202,
            -38.425,
            9.534,
            7.408,
            -38.425,
            9.534,
            7.408,
            -37.212,
            9.078,
            7.408,
            -37.212,
            9.078,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_15",
        "polygon": [
            -39.833,
            10.063,
            4.202,
            -41.046,
            10.519,
            4.202,
            -41.046,
            10.519,
            7.408,
            -41.046,
            10.519,
            7.408,
            -39.833,
            10.063,
            7.408,
            -39.833,
            10.063,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_13",
        "polygon": [
            -42.454,
            11.048,
            4.202,
            -43.667,
            11.504,
            4.202,
            -43.667,
            11.504,
            7.408,
            -43.667,
            11.504,
            7.408,
            -42.454,
            11.048,
            7.408,
            -42.454,
            11.048,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_11",
        "polygon": [
            -45.074,
            12.034,
            4.202,
            -46.288,
            12.49,
            4.202,
            -46.288,
            12.49,
            7.408,
            -46.288,
            12.49,
            7.408,
            -45.074,
            12.034,
            7.408,
            -45.074,
            12.034,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_9",
        "polygon": [
            -47.695,
            13.019,
            4.202,
            -48.908,
            13.475,
            4.202,
            -48.908,
            13.475,
            7.408,
            -48.908,
            13.475,
            7.408,
            -47.695,
            13.019,
            7.408,
            -47.695,
            13.019,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_7",
        "polygon": [
            -50.316,
            14.004,
            4.202,
            -51.529,
            14.46,
            4.202,
            -51.529,
            14.46,
            7.408,
            -51.529,
            14.46,
            7.408,
            -50.316,
            14.004,
            7.408,
            -50.316,
            14.004,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_5",
        "polygon": [
            -52.937,
            14.99,
            4.202,
            -54.15,
            15.446,
            4.202,
            -54.15,
            15.446,
            7.408,
            -54.15,
            15.446,
            7.408,
            -52.937,
            14.99,
            7.408,
            -52.937,
            14.99,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_3",
        "polygon": [
            -55.558,
            15.975,
            4.202,
            -56.771,
            16.431,
            4.202,
            -56.771,
            16.431,
            7.408,
            -56.771,
            16.431,
            7.408,
            -55.558,
            15.975,
            7.408,
            -55.558,
            15.975,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_37",
        "polygon": [
            -10.832,
            -0.84,
            11.622,
            -12.216,
            -0.32,
            11.622,
            -12.216,
            -0.32,
            14.828,
            -12.216,
            -0.32,
            14.828,
            -10.832,
            -0.84,
            14.828,
            -10.832,
            -0.84,
            11.622
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_35",
        "polygon": [
            -13.624,
            0.209,
            11.622,
            -14.837,
            0.666,
            11.622,
            -14.837,
            0.666,
            14.828,
            -14.837,
            0.666,
            14.828,
            -13.624,
            0.209,
            14.828,
            -13.624,
            0.209,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_1",
        "polygon": [
            -58.179,
            16.96,
            11.622,
            -59.562,
            17.481,
            11.622,
            -59.562,
            17.481,
            14.828,
            -59.562,
            17.481,
            14.828,
            -58.179,
            16.96,
            14.828,
            -58.179,
            16.96,
            11.622
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_33",
        "polygon": [
            -16.245,
            1.195,
            11.622,
            -17.458,
            1.651,
            11.622,
            -17.458,
            1.651,
            14.828,
            -17.458,
            1.651,
            14.828,
            -16.245,
            1.195,
            14.828,
            -16.245,
            1.195,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_31",
        "polygon": [
            -18.865,
            2.18,
            11.622,
            -20.079,
            2.636,
            11.622,
            -20.079,
            2.636,
            14.828,
            -20.079,
            2.636,
            14.828,
            -18.865,
            2.18,
            14.828,
            -18.865,
            2.18,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_29",
        "polygon": [
            -21.486,
            3.166,
            11.622,
            -22.699,
            3.622,
            11.622,
            -22.699,
            3.622,
            14.828,
            -22.699,
            3.622,
            14.828,
            -21.486,
            3.166,
            14.828,
            -21.486,
            3.166,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_27",
        "polygon": [
            -24.107,
            4.151,
            11.622,
            -25.32,
            4.607,
            11.622,
            -25.32,
            4.607,
            14.828,
            -25.32,
            4.607,
            14.828,
            -24.107,
            4.151,
            14.828,
            -24.107,
            4.151,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_25",
        "polygon": [
            -26.728,
            5.136,
            11.622,
            -27.941,
            5.592,
            11.622,
            -27.941,
            5.592,
            14.828,
            -27.941,
            5.592,
            14.828,
            -26.728,
            5.136,
            14.828,
            -26.728,
            5.136,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_23",
        "polygon": [
            -29.349,
            6.122,
            11.622,
            -30.562,
            6.578,
            11.622,
            -30.562,
            6.578,
            14.828,
            -30.562,
            6.578,
            14.828,
            -29.349,
            6.122,
            14.828,
            -29.349,
            6.122,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_21",
        "polygon": [
            -31.97,
            7.107,
            11.622,
            -33.183,
            7.563,
            11.622,
            -33.183,
            7.563,
            14.828,
            -33.183,
            7.563,
            14.828,
            -31.97,
            7.107,
            14.828,
            -31.97,
            7.107,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_19",
        "polygon": [
            -34.591,
            8.092,
            11.622,
            -35.804,
            8.548,
            11.622,
            -35.804,
            8.548,
            14.828,
            -35.804,
            8.548,
            14.828,
            -34.591,
            8.092,
            14.828,
            -34.591,
            8.092,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_17",
        "polygon": [
            -37.212,
            9.078,
            11.622,
            -38.425,
            9.534,
            11.622,
            -38.425,
            9.534,
            14.828,
            -38.425,
            9.534,
            14.828,
            -37.212,
            9.078,
            14.828,
            -37.212,
            9.078,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_15",
        "polygon": [
            -39.833,
            10.063,
            11.622,
            -41.046,
            10.519,
            11.622,
            -41.046,
            10.519,
            14.828,
            -41.046,
            10.519,
            14.828,
            -39.833,
            10.063,
            14.828,
            -39.833,
            10.063,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_13",
        "polygon": [
            -42.454,
            11.048,
            11.622,
            -43.667,
            11.504,
            11.622,
            -43.667,
            11.504,
            14.828,
            -43.667,
            11.504,
            14.828,
            -42.454,
            11.048,
            14.828,
            -42.454,
            11.048,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_11",
        "polygon": [
            -45.074,
            12.034,
            11.622,
            -46.288,
            12.49,
            11.622,
            -46.288,
            12.49,
            14.828,
            -46.288,
            12.49,
            14.828,
            -45.074,
            12.034,
            14.828,
            -45.074,
            12.034,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_9",
        "polygon": [
            -47.695,
            13.019,
            11.622,
            -48.908,
            13.475,
            11.622,
            -48.908,
            13.475,
            14.828,
            -48.908,
            13.475,
            14.828,
            -47.695,
            13.019,
            14.828,
            -47.695,
            13.019,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_7",
        "polygon": [
            -50.316,
            14.004,
            11.622,
            -51.529,
            14.46,
            11.622,
            -51.529,
            14.46,
            14.828,
            -51.529,
            14.46,
            14.828,
            -50.316,
            14.004,
            14.828,
            -50.316,
            14.004,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_5",
        "polygon": [
            -52.937,
            14.99,
            11.622,
            -54.15,
            15.446,
            11.622,
            -54.15,
            15.446,
            14.828,
            -54.15,
            15.446,
            14.828,
            -52.937,
            14.99,
            14.828,
            -52.937,
            14.99,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_3",
        "polygon": [
            -55.558,
            15.975,
            11.622,
            -56.771,
            16.431,
            11.622,
            -56.771,
            16.431,
            14.828,
            -56.771,
            16.431,
            14.828,
            -55.558,
            15.975,
            14.828,
            -55.558,
            15.975,
            11.622
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_37",
        "polygon": [
            -10.832,
            -0.84,
            7.912,
            -12.216,
            -0.32,
            7.912,
            -12.216,
            -0.32,
            11.118,
            -12.216,
            -0.32,
            11.118,
            -10.832,
            -0.84,
            11.118,
            -10.832,
            -0.84,
            7.912
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_35",
        "polygon": [
            -13.624,
            0.209,
            7.912,
            -14.837,
            0.666,
            7.912,
            -14.837,
            0.666,
            11.118,
            -14.837,
            0.666,
            11.118,
            -13.624,
            0.209,
            11.118,
            -13.624,
            0.209,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_1",
        "polygon": [
            -58.179,
            16.96,
            7.912,
            -59.562,
            17.481,
            7.912,
            -59.562,
            17.481,
            11.118,
            -59.562,
            17.481,
            11.118,
            -58.179,
            16.96,
            11.118,
            -58.179,
            16.96,
            7.912
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_33",
        "polygon": [
            -16.245,
            1.195,
            7.912,
            -17.458,
            1.651,
            7.912,
            -17.458,
            1.651,
            11.118,
            -17.458,
            1.651,
            11.118,
            -16.245,
            1.195,
            11.118,
            -16.245,
            1.195,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_31",
        "polygon": [
            -18.865,
            2.18,
            7.912,
            -20.079,
            2.636,
            7.912,
            -20.079,
            2.636,
            11.118,
            -20.079,
            2.636,
            11.118,
            -18.865,
            2.18,
            11.118,
            -18.865,
            2.18,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_29",
        "polygon": [
            -21.486,
            3.166,
            7.912,
            -22.699,
            3.622,
            7.912,
            -22.699,
            3.622,
            11.118,
            -22.699,
            3.622,
            11.118,
            -21.486,
            3.166,
            11.118,
            -21.486,
            3.166,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_27",
        "polygon": [
            -24.107,
            4.151,
            7.912,
            -25.32,
            4.607,
            7.912,
            -25.32,
            4.607,
            11.118,
            -25.32,
            4.607,
            11.118,
            -24.107,
            4.151,
            11.118,
            -24.107,
            4.151,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_25",
        "polygon": [
            -26.728,
            5.136,
            7.912,
            -27.941,
            5.592,
            7.912,
            -27.941,
            5.592,
            11.118,
            -27.941,
            5.592,
            11.118,
            -26.728,
            5.136,
            11.118,
            -26.728,
            5.136,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_23",
        "polygon": [
            -29.349,
            6.122,
            7.912,
            -30.562,
            6.578,
            7.912,
            -30.562,
            6.578,
            11.118,
            -30.562,
            6.578,
            11.118,
            -29.349,
            6.122,
            11.118,
            -29.349,
            6.122,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_21",
        "polygon": [
            -31.97,
            7.107,
            7.912,
            -33.183,
            7.563,
            7.912,
            -33.183,
            7.563,
            11.118,
            -33.183,
            7.563,
            11.118,
            -31.97,
            7.107,
            11.118,
            -31.97,
            7.107,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_19",
        "polygon": [
            -34.591,
            8.092,
            7.912,
            -35.804,
            8.548,
            7.912,
            -35.804,
            8.548,
            11.118,
            -35.804,
            8.548,
            11.118,
            -34.591,
            8.092,
            11.118,
            -34.591,
            8.092,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_17",
        "polygon": [
            -37.212,
            9.078,
            7.912,
            -38.425,
            9.534,
            7.912,
            -38.425,
            9.534,
            11.118,
            -38.425,
            9.534,
            11.118,
            -37.212,
            9.078,
            11.118,
            -37.212,
            9.078,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_15",
        "polygon": [
            -39.833,
            10.063,
            7.912,
            -41.046,
            10.519,
            7.912,
            -41.046,
            10.519,
            11.118,
            -41.046,
            10.519,
            11.118,
            -39.833,
            10.063,
            11.118,
            -39.833,
            10.063,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_13",
        "polygon": [
            -42.454,
            11.048,
            7.912,
            -43.667,
            11.504,
            7.912,
            -43.667,
            11.504,
            11.118,
            -43.667,
            11.504,
            11.118,
            -42.454,
            11.048,
            11.118,
            -42.454,
            11.048,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_11",
        "polygon": [
            -45.074,
            12.034,
            7.912,
            -46.288,
            12.49,
            7.912,
            -46.288,
            12.49,
            11.118,
            -46.288,
            12.49,
            11.118,
            -45.074,
            12.034,
            11.118,
            -45.074,
            12.034,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_9",
        "polygon": [
            -47.695,
            13.019,
            7.912,
            -48.908,
            13.475,
            7.912,
            -48.908,
            13.475,
            11.118,
            -48.908,
            13.475,
            11.118,
            -47.695,
            13.019,
            11.118,
            -47.695,
            13.019,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_7",
        "polygon": [
            -50.316,
            14.004,
            7.912,
            -51.529,
            14.46,
            7.912,
            -51.529,
            14.46,
            11.118,
            -51.529,
            14.46,
            11.118,
            -50.316,
            14.004,
            11.118,
            -50.316,
            14.004,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_5",
        "polygon": [
            -52.937,
            14.99,
            7.912,
            -54.15,
            15.446,
            7.912,
            -54.15,
            15.446,
            11.118,
            -54.15,
            15.446,
            11.118,
            -52.937,
            14.99,
            11.118,
            -52.937,
            14.99,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_3",
        "polygon": [
            -55.558,
            15.975,
            7.912,
            -56.771,
            16.431,
            7.912,
            -56.771,
            16.431,
            11.118,
            -56.771,
            16.431,
            11.118,
            -55.558,
            15.975,
            11.118,
            -55.558,
            15.975,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_11",
        "polygon": [
            31.87,
            -16.894,
            4.202,
            30.589,
            -16.413,
            4.202,
            30.589,
            -16.413,
            7.408,
            30.589,
            -16.413,
            7.408,
            31.87,
            -16.894,
            7.408,
            31.87,
            -16.894,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_3",
        "polygon": [
            21.318,
            -12.927,
            4.202,
            20.105,
            -12.471,
            4.202,
            20.105,
            -12.471,
            7.408,
            20.105,
            -12.471,
            7.408,
            21.318,
            -12.927,
            7.408,
            21.318,
            -12.927,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_5",
        "polygon": [
            23.939,
            -13.913,
            4.202,
            22.726,
            -13.457,
            4.202,
            22.726,
            -13.457,
            7.408,
            22.726,
            -13.457,
            7.408,
            23.939,
            -13.913,
            7.408,
            23.939,
            -13.913,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_7",
        "polygon": [
            26.56,
            -14.898,
            4.202,
            25.347,
            -14.442,
            4.202,
            25.347,
            -14.442,
            7.408,
            25.347,
            -14.442,
            7.408,
            26.56,
            -14.898,
            7.408,
            26.56,
            -14.898,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_9",
        "polygon": [
            29.181,
            -15.883,
            4.202,
            27.968,
            -15.427,
            4.202,
            27.968,
            -15.427,
            7.408,
            27.968,
            -15.427,
            7.408,
            29.181,
            -15.883,
            7.408,
            29.181,
            -15.883,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_11",
        "polygon": [
            31.87,
            -16.894,
            7.912,
            30.589,
            -16.413,
            7.912,
            30.589,
            -16.413,
            11.118,
            30.589,
            -16.413,
            11.118,
            31.87,
            -16.894,
            11.118,
            31.87,
            -16.894,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_3",
        "polygon": [
            21.318,
            -12.927,
            7.912,
            20.105,
            -12.471,
            7.912,
            20.105,
            -12.471,
            11.118,
            20.105,
            -12.471,
            11.118,
            21.318,
            -12.927,
            11.118,
            21.318,
            -12.927,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_5",
        "polygon": [
            23.939,
            -13.913,
            7.912,
            22.726,
            -13.457,
            7.912,
            22.726,
            -13.457,
            11.118,
            22.726,
            -13.457,
            11.118,
            23.939,
            -13.913,
            11.118,
            23.939,
            -13.913,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_7",
        "polygon": [
            26.56,
            -14.898,
            7.912,
            25.347,
            -14.442,
            7.912,
            25.347,
            -14.442,
            11.118,
            25.347,
            -14.442,
            11.118,
            26.56,
            -14.898,
            11.118,
            26.56,
            -14.898,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_9",
        "polygon": [
            29.181,
            -15.883,
            7.912,
            27.968,
            -15.427,
            7.912,
            27.968,
            -15.427,
            11.118,
            27.968,
            -15.427,
            11.118,
            29.181,
            -15.883,
            11.118,
            29.181,
            -15.883,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1816",
        "polygon": [
            -22.411,
            36.391,
            4.089,
            -18.536,
            34.934,
            4.089,
            -18.536,
            34.934,
            5.884,
            -18.536,
            34.934,
            5.884,
            -22.411,
            36.391,
            5.884,
            -22.411,
            36.391,
            4.089
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1818",
        "polygon": [
            -30.274,
            39.347,
            4.089,
            -26.399,
            37.89,
            4.089,
            -26.399,
            37.89,
            5.884,
            -26.399,
            37.89,
            5.884,
            -30.274,
            39.347,
            5.884,
            -30.274,
            39.347,
            4.089
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1819",
        "polygon": [
            -34.018,
            40.755,
            4.089,
            -30.33,
            39.368,
            4.089,
            -30.33,
            39.368,
            5.884,
            -30.33,
            39.368,
            5.884,
            -34.018,
            40.755,
            5.884,
            -34.018,
            40.755,
            4.089
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1822",
        "polygon": [
            -26.342,
            37.869,
            4.089,
            -22.467,
            36.412,
            4.089,
            -22.467,
            36.412,
            5.884,
            -22.467,
            36.412,
            5.884,
            -26.342,
            37.869,
            5.884,
            -26.342,
            37.869,
            4.089
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1824",
        "polygon": [
            -18.48,
            34.913,
            4.089,
            -14.792,
            33.527,
            4.089,
            -14.792,
            33.527,
            5.884,
            -14.792,
            33.527,
            5.884,
            -18.48,
            34.913,
            5.884,
            -18.48,
            34.913,
            4.089
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1825",
        "polygon": [
            -22.411,
            36.391,
            5.944,
            -18.536,
            34.934,
            5.944,
            -18.536,
            34.934,
            7.739,
            -18.536,
            34.934,
            7.739,
            -22.411,
            36.391,
            7.739,
            -22.411,
            36.391,
            5.944
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1826",
        "polygon": [
            -30.274,
            39.347,
            5.944,
            -26.399,
            37.89,
            5.944,
            -26.399,
            37.89,
            7.739,
            -26.399,
            37.89,
            7.739,
            -30.274,
            39.347,
            7.739,
            -30.274,
            39.347,
            5.944
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1827",
        "polygon": [
            -34.018,
            40.755,
            5.944,
            -30.33,
            39.368,
            5.944,
            -30.33,
            39.368,
            7.739,
            -30.33,
            39.368,
            7.739,
            -34.018,
            40.755,
            7.739,
            -34.018,
            40.755,
            5.944
        ],
        "05-24 04:30": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1828",
        "polygon": [
            -26.342,
            37.869,
            5.944,
            -22.467,
            36.412,
            5.944,
            -22.467,
            36.412,
            7.739,
            -22.467,
            36.412,
            7.739,
            -26.342,
            37.869,
            7.739,
            -26.342,
            37.869,
            5.944
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_1829",
        "polygon": [
            -18.48,
            34.913,
            5.944,
            -14.792,
            33.527,
            5.944,
            -14.792,
            33.527,
            7.739,
            -14.792,
            33.527,
            7.739,
            -18.48,
            34.913,
            7.739,
            -18.48,
            34.913,
            5.944
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1830",
        "polygon": [
            -22.411,
            36.391,
            7.799,
            -18.536,
            34.934,
            7.799,
            -18.536,
            34.934,
            9.594,
            -18.536,
            34.934,
            9.594,
            -22.411,
            36.391,
            9.594,
            -22.411,
            36.391,
            7.799
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1831",
        "polygon": [
            -30.274,
            39.347,
            7.799,
            -26.399,
            37.89,
            7.799,
            -26.399,
            37.89,
            9.594,
            -26.399,
            37.89,
            9.594,
            -30.274,
            39.347,
            9.594,
            -30.274,
            39.347,
            7.799
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1832",
        "polygon": [
            -34.018,
            40.755,
            7.799,
            -30.33,
            39.368,
            7.799,
            -30.33,
            39.368,
            9.594,
            -30.33,
            39.368,
            9.594,
            -34.018,
            40.755,
            9.594,
            -34.018,
            40.755,
            7.799
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1833",
        "polygon": [
            -26.342,
            37.869,
            7.799,
            -22.467,
            36.412,
            7.799,
            -22.467,
            36.412,
            9.594,
            -22.467,
            36.412,
            9.594,
            -26.342,
            37.869,
            9.594,
            -26.342,
            37.869,
            7.799
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1834",
        "polygon": [
            -18.48,
            34.913,
            7.799,
            -14.792,
            33.527,
            7.799,
            -14.792,
            33.527,
            9.594,
            -14.792,
            33.527,
            9.594,
            -18.48,
            34.913,
            9.594,
            -18.48,
            34.913,
            7.799
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1835",
        "polygon": [
            -22.411,
            36.391,
            9.654,
            -18.536,
            34.934,
            9.654,
            -18.536,
            34.934,
            11.449,
            -18.536,
            34.934,
            11.449,
            -22.411,
            36.391,
            11.449,
            -22.411,
            36.391,
            9.654
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1836",
        "polygon": [
            -30.274,
            39.347,
            9.654,
            -26.399,
            37.89,
            9.654,
            -26.399,
            37.89,
            11.449,
            -26.399,
            37.89,
            11.449,
            -30.274,
            39.347,
            11.449,
            -30.274,
            39.347,
            9.654
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1837",
        "polygon": [
            -34.018,
            40.755,
            9.654,
            -30.33,
            39.368,
            9.654,
            -30.33,
            39.368,
            11.449,
            -30.33,
            39.368,
            11.449,
            -34.018,
            40.755,
            11.449,
            -34.018,
            40.755,
            9.654
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1838",
        "polygon": [
            -26.342,
            37.869,
            9.654,
            -22.467,
            36.412,
            9.654,
            -22.467,
            36.412,
            11.449,
            -22.467,
            36.412,
            11.449,
            -26.342,
            37.869,
            11.449,
            -26.342,
            37.869,
            9.654
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_1839",
        "polygon": [
            -18.48,
            34.913,
            9.654,
            -14.792,
            33.527,
            9.654,
            -14.792,
            33.527,
            11.449,
            -14.792,
            33.527,
            11.449,
            -18.48,
            34.913,
            11.449,
            -18.48,
            34.913,
            9.654
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1840",
        "polygon": [
            -22.411,
            36.391,
            11.509,
            -18.536,
            34.934,
            11.509,
            -18.536,
            34.934,
            13.304,
            -18.536,
            34.934,
            13.304,
            -22.411,
            36.391,
            13.304,
            -22.411,
            36.391,
            11.509
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1841",
        "polygon": [
            -30.274,
            39.347,
            11.509,
            -26.399,
            37.89,
            11.509,
            -26.399,
            37.89,
            13.304,
            -26.399,
            37.89,
            13.304,
            -30.274,
            39.347,
            13.304,
            -30.274,
            39.347,
            11.509
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1842",
        "polygon": [
            -34.018,
            40.755,
            11.509,
            -30.33,
            39.368,
            11.509,
            -30.33,
            39.368,
            13.304,
            -30.33,
            39.368,
            13.304,
            -34.018,
            40.755,
            13.304,
            -34.018,
            40.755,
            11.509
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1843",
        "polygon": [
            -26.342,
            37.869,
            11.509,
            -22.467,
            36.412,
            11.509,
            -22.467,
            36.412,
            13.304,
            -22.467,
            36.412,
            13.304,
            -26.342,
            37.869,
            13.304,
            -26.342,
            37.869,
            11.509
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1844",
        "polygon": [
            -18.48,
            34.913,
            11.509,
            -14.792,
            33.527,
            11.509,
            -14.792,
            33.527,
            13.304,
            -14.792,
            33.527,
            13.304,
            -18.48,
            34.913,
            13.304,
            -18.48,
            34.913,
            11.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1845",
        "polygon": [
            -22.411,
            36.391,
            13.364,
            -18.536,
            34.934,
            13.364,
            -18.536,
            34.934,
            15.159,
            -18.536,
            34.934,
            15.159,
            -22.411,
            36.391,
            15.159,
            -22.411,
            36.391,
            13.364
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1846",
        "polygon": [
            -30.274,
            39.347,
            13.364,
            -26.399,
            37.89,
            13.364,
            -26.399,
            37.89,
            15.159,
            -26.399,
            37.89,
            15.159,
            -30.274,
            39.347,
            15.159,
            -30.274,
            39.347,
            13.364
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1847",
        "polygon": [
            -34.018,
            40.755,
            13.364,
            -30.33,
            39.368,
            13.364,
            -30.33,
            39.368,
            15.159,
            -30.33,
            39.368,
            15.159,
            -34.018,
            40.755,
            15.159,
            -34.018,
            40.755,
            13.364
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1848",
        "polygon": [
            -26.342,
            37.869,
            13.364,
            -22.467,
            36.412,
            13.364,
            -22.467,
            36.412,
            15.159,
            -22.467,
            36.412,
            15.159,
            -26.342,
            37.869,
            15.159,
            -26.342,
            37.869,
            13.364
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_1849",
        "polygon": [
            -18.48,
            34.913,
            13.364,
            -14.792,
            33.527,
            13.364,
            -14.792,
            33.527,
            15.159,
            -14.792,
            33.527,
            15.159,
            -18.48,
            34.913,
            15.159,
            -18.48,
            34.913,
            13.364
        ],
        "05-24 18:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1852",
        "polygon": [
            -18.479,
            34.913,
            2.928,
            -14.791,
            33.527,
            2.928,
            -14.791,
            33.527,
            4.028,
            -14.791,
            33.527,
            4.028,
            -18.479,
            34.913,
            4.028,
            -18.479,
            34.913,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1853",
        "polygon": [
            -18.48,
            34.913,
            2.596,
            -17.188,
            34.428,
            2.596,
            -17.188,
            34.428,
            2.868,
            -17.188,
            34.428,
            2.868,
            -18.48,
            34.913,
            2.868,
            -18.48,
            34.913,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1854",
        "polygon": [
            -17.188,
            34.428,
            2.596,
            -15.896,
            33.942,
            2.596,
            -15.896,
            33.942,
            2.868,
            -15.896,
            33.942,
            2.868,
            -17.188,
            34.428,
            2.868,
            -17.188,
            34.428,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1855",
        "polygon": [
            -15.896,
            33.942,
            2.596,
            -14.791,
            33.526,
            2.596,
            -14.791,
            33.526,
            2.868,
            -14.791,
            33.526,
            2.868,
            -15.896,
            33.942,
            2.868,
            -15.896,
            33.942,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1856",
        "polygon": [
            -18.48,
            34.913,
            2.324,
            -17.188,
            34.428,
            2.324,
            -17.188,
            34.428,
            2.596,
            -17.188,
            34.428,
            2.596,
            -18.48,
            34.913,
            2.596,
            -18.48,
            34.913,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1858",
        "polygon": [
            -15.896,
            33.942,
            2.324,
            -14.791,
            33.526,
            2.324,
            -14.791,
            33.526,
            2.596,
            -14.791,
            33.526,
            2.596,
            -15.896,
            33.942,
            2.596,
            -15.896,
            33.942,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1859",
        "polygon": [
            -18.48,
            34.913,
            2.053,
            -17.188,
            34.428,
            2.053,
            -17.188,
            34.428,
            2.324,
            -17.188,
            34.428,
            2.324,
            -18.48,
            34.913,
            2.324,
            -18.48,
            34.913,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1860",
        "polygon": [
            -17.188,
            34.428,
            2.053,
            -15.896,
            33.942,
            2.053,
            -15.896,
            33.942,
            2.324,
            -15.896,
            33.942,
            2.324,
            -17.188,
            34.428,
            2.324,
            -17.188,
            34.428,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1861",
        "polygon": [
            -15.896,
            33.942,
            2.053,
            -14.791,
            33.526,
            2.053,
            -14.791,
            33.526,
            2.324,
            -14.791,
            33.526,
            2.324,
            -15.896,
            33.942,
            2.324,
            -15.896,
            33.942,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1862",
        "polygon": [
            -18.48,
            34.913,
            1.781,
            -17.188,
            34.428,
            1.781,
            -17.188,
            34.428,
            2.053,
            -17.188,
            34.428,
            2.053,
            -18.48,
            34.913,
            2.053,
            -18.48,
            34.913,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1863",
        "polygon": [
            -17.188,
            34.428,
            1.781,
            -15.896,
            33.942,
            1.781,
            -15.896,
            33.942,
            2.053,
            -15.896,
            33.942,
            2.053,
            -17.188,
            34.428,
            2.053,
            -17.188,
            34.428,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1864",
        "polygon": [
            -15.896,
            33.942,
            1.781,
            -14.791,
            33.526,
            1.781,
            -14.791,
            33.526,
            2.053,
            -14.791,
            33.526,
            2.053,
            -15.896,
            33.942,
            2.053,
            -15.896,
            33.942,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1865",
        "polygon": [
            -18.48,
            34.913,
            1.509,
            -17.188,
            34.428,
            1.509,
            -17.188,
            34.428,
            1.781,
            -17.188,
            34.428,
            1.781,
            -18.48,
            34.913,
            1.781,
            -18.48,
            34.913,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1866",
        "polygon": [
            -17.188,
            34.428,
            1.509,
            -15.896,
            33.942,
            1.509,
            -15.896,
            33.942,
            1.781,
            -15.896,
            33.942,
            1.781,
            -17.188,
            34.428,
            1.781,
            -17.188,
            34.428,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1867",
        "polygon": [
            -15.896,
            33.942,
            1.509,
            -14.791,
            33.526,
            1.509,
            -14.791,
            33.526,
            1.781,
            -14.791,
            33.526,
            1.781,
            -15.896,
            33.942,
            1.781,
            -15.896,
            33.942,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1868",
        "polygon": [
            -17.188,
            34.428,
            2.324,
            -15.896,
            33.942,
            2.324,
            -15.896,
            33.942,
            2.596,
            -15.896,
            33.942,
            2.596,
            -17.188,
            34.428,
            2.596,
            -17.188,
            34.428,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1869",
        "polygon": [
            -18.48,
            34.913,
            1.177,
            -17.188,
            34.428,
            1.177,
            -17.188,
            34.428,
            1.449,
            -17.188,
            34.428,
            1.449,
            -18.48,
            34.913,
            1.449,
            -18.48,
            34.913,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1870",
        "polygon": [
            -17.188,
            34.428,
            1.177,
            -15.896,
            33.942,
            1.177,
            -15.896,
            33.942,
            1.449,
            -15.896,
            33.942,
            1.449,
            -17.188,
            34.428,
            1.449,
            -17.188,
            34.428,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1871",
        "polygon": [
            -15.896,
            33.942,
            1.177,
            -14.791,
            33.526,
            1.177,
            -14.791,
            33.526,
            1.449,
            -14.791,
            33.526,
            1.449,
            -15.896,
            33.942,
            1.449,
            -15.896,
            33.942,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1872",
        "polygon": [
            -18.48,
            34.913,
            0.905,
            -17.188,
            34.428,
            0.905,
            -17.188,
            34.428,
            1.177,
            -17.188,
            34.428,
            1.177,
            -18.48,
            34.913,
            1.177,
            -18.48,
            34.913,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1873",
        "polygon": [
            -15.896,
            33.942,
            0.905,
            -14.791,
            33.526,
            0.905,
            -14.791,
            33.526,
            1.177,
            -14.791,
            33.526,
            1.177,
            -15.896,
            33.942,
            1.177,
            -15.896,
            33.942,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1874",
        "polygon": [
            -18.48,
            34.913,
            0.634,
            -17.188,
            34.428,
            0.634,
            -17.188,
            34.428,
            0.905,
            -17.188,
            34.428,
            0.905,
            -18.48,
            34.913,
            0.905,
            -18.48,
            34.913,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1875",
        "polygon": [
            -17.188,
            34.428,
            0.634,
            -15.896,
            33.942,
            0.634,
            -15.896,
            33.942,
            0.905,
            -15.896,
            33.942,
            0.905,
            -17.188,
            34.428,
            0.905,
            -17.188,
            34.428,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1876",
        "polygon": [
            -15.896,
            33.942,
            0.634,
            -14.791,
            33.526,
            0.634,
            -14.791,
            33.526,
            0.905,
            -14.791,
            33.526,
            0.905,
            -15.896,
            33.942,
            0.905,
            -15.896,
            33.942,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1877",
        "polygon": [
            -18.48,
            34.913,
            0.362,
            -17.188,
            34.428,
            0.362,
            -17.188,
            34.428,
            0.634,
            -17.188,
            34.428,
            0.634,
            -18.48,
            34.913,
            0.634,
            -18.48,
            34.913,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1878",
        "polygon": [
            -17.188,
            34.428,
            0.362,
            -15.896,
            33.942,
            0.362,
            -15.896,
            33.942,
            0.634,
            -15.896,
            33.942,
            0.634,
            -17.188,
            34.428,
            0.634,
            -17.188,
            34.428,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1879",
        "polygon": [
            -15.896,
            33.942,
            0.362,
            -14.791,
            33.526,
            0.362,
            -14.791,
            33.526,
            0.634,
            -14.791,
            33.526,
            0.634,
            -15.896,
            33.942,
            0.634,
            -15.896,
            33.942,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1880",
        "polygon": [
            -18.48,
            34.913,
            0.09,
            -17.188,
            34.428,
            0.09,
            -17.188,
            34.428,
            0.362,
            -17.188,
            34.428,
            0.362,
            -18.48,
            34.913,
            0.362,
            -18.48,
            34.913,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1881",
        "polygon": [
            -17.188,
            34.428,
            0.09,
            -15.896,
            33.942,
            0.09,
            -15.896,
            33.942,
            0.362,
            -15.896,
            33.942,
            0.362,
            -17.188,
            34.428,
            0.362,
            -17.188,
            34.428,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1882",
        "polygon": [
            -15.896,
            33.942,
            0.09,
            -14.791,
            33.526,
            0.09,
            -14.791,
            33.526,
            0.362,
            -14.791,
            33.526,
            0.362,
            -15.896,
            33.942,
            0.362,
            -15.896,
            33.942,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1883",
        "polygon": [
            -17.188,
            34.428,
            0.905,
            -15.896,
            33.942,
            0.905,
            -15.896,
            33.942,
            1.177,
            -15.896,
            33.942,
            1.177,
            -17.188,
            34.428,
            1.177,
            -17.188,
            34.428,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1884",
        "polygon": [
            -22.411,
            36.391,
            1.177,
            -21.119,
            35.906,
            1.177,
            -21.119,
            35.906,
            1.449,
            -21.119,
            35.906,
            1.449,
            -22.411,
            36.391,
            1.449,
            -22.411,
            36.391,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1885",
        "polygon": [
            -21.119,
            35.906,
            1.177,
            -19.828,
            35.42,
            1.177,
            -19.828,
            35.42,
            1.449,
            -19.828,
            35.42,
            1.449,
            -21.119,
            35.906,
            1.449,
            -21.119,
            35.906,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1886",
        "polygon": [
            -19.828,
            35.42,
            1.177,
            -18.536,
            34.934,
            1.177,
            -18.536,
            34.934,
            1.449,
            -18.536,
            34.934,
            1.449,
            -19.828,
            35.42,
            1.449,
            -19.828,
            35.42,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1887",
        "polygon": [
            -22.411,
            36.391,
            0.905,
            -21.119,
            35.906,
            0.905,
            -21.119,
            35.906,
            1.177,
            -21.119,
            35.906,
            1.177,
            -22.411,
            36.391,
            1.177,
            -22.411,
            36.391,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1888",
        "polygon": [
            -19.828,
            35.42,
            0.905,
            -18.536,
            34.934,
            0.905,
            -18.536,
            34.934,
            1.177,
            -18.536,
            34.934,
            1.177,
            -19.828,
            35.42,
            1.177,
            -19.828,
            35.42,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1889",
        "polygon": [
            -22.411,
            36.391,
            0.634,
            -21.119,
            35.906,
            0.634,
            -21.119,
            35.906,
            0.905,
            -21.119,
            35.906,
            0.905,
            -22.411,
            36.391,
            0.905,
            -22.411,
            36.391,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1890",
        "polygon": [
            -21.119,
            35.906,
            0.634,
            -19.828,
            35.42,
            0.634,
            -19.828,
            35.42,
            0.905,
            -19.828,
            35.42,
            0.905,
            -21.119,
            35.906,
            0.905,
            -21.119,
            35.906,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1891",
        "polygon": [
            -19.828,
            35.42,
            0.634,
            -18.536,
            34.934,
            0.634,
            -18.536,
            34.934,
            0.905,
            -18.536,
            34.934,
            0.905,
            -19.828,
            35.42,
            0.905,
            -19.828,
            35.42,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1892",
        "polygon": [
            -22.411,
            36.391,
            0.362,
            -21.119,
            35.906,
            0.362,
            -21.119,
            35.906,
            0.634,
            -21.119,
            35.906,
            0.634,
            -22.411,
            36.391,
            0.634,
            -22.411,
            36.391,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1893",
        "polygon": [
            -21.119,
            35.906,
            0.362,
            -19.828,
            35.42,
            0.362,
            -19.828,
            35.42,
            0.634,
            -19.828,
            35.42,
            0.634,
            -21.119,
            35.906,
            0.634,
            -21.119,
            35.906,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1894",
        "polygon": [
            -19.828,
            35.42,
            0.362,
            -18.536,
            34.934,
            0.362,
            -18.536,
            34.934,
            0.634,
            -18.536,
            34.934,
            0.634,
            -19.828,
            35.42,
            0.634,
            -19.828,
            35.42,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1895",
        "polygon": [
            -22.411,
            36.391,
            0.09,
            -21.119,
            35.906,
            0.09,
            -21.119,
            35.906,
            0.362,
            -21.119,
            35.906,
            0.362,
            -22.411,
            36.391,
            0.362,
            -22.411,
            36.391,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1896",
        "polygon": [
            -21.119,
            35.906,
            0.09,
            -19.828,
            35.42,
            0.09,
            -19.828,
            35.42,
            0.362,
            -19.828,
            35.42,
            0.362,
            -21.119,
            35.906,
            0.362,
            -21.119,
            35.906,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1897",
        "polygon": [
            -19.828,
            35.42,
            0.09,
            -18.536,
            34.934,
            0.09,
            -18.536,
            34.934,
            0.362,
            -18.536,
            34.934,
            0.362,
            -19.828,
            35.42,
            0.362,
            -19.828,
            35.42,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1898",
        "polygon": [
            -21.119,
            35.906,
            0.905,
            -19.828,
            35.42,
            0.905,
            -19.828,
            35.42,
            1.177,
            -19.828,
            35.42,
            1.177,
            -21.119,
            35.906,
            1.177,
            -21.119,
            35.906,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1899",
        "polygon": [
            -22.411,
            36.391,
            2.596,
            -21.119,
            35.906,
            2.596,
            -21.119,
            35.906,
            2.868,
            -21.119,
            35.906,
            2.868,
            -22.411,
            36.391,
            2.868,
            -22.411,
            36.391,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1900",
        "polygon": [
            -21.119,
            35.906,
            2.596,
            -19.828,
            35.42,
            2.596,
            -19.828,
            35.42,
            2.868,
            -19.828,
            35.42,
            2.868,
            -21.119,
            35.906,
            2.868,
            -21.119,
            35.906,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1901",
        "polygon": [
            -19.828,
            35.42,
            2.596,
            -18.536,
            34.934,
            2.596,
            -18.536,
            34.934,
            2.868,
            -18.536,
            34.934,
            2.868,
            -19.828,
            35.42,
            2.868,
            -19.828,
            35.42,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1902",
        "polygon": [
            -22.411,
            36.391,
            2.324,
            -21.119,
            35.906,
            2.324,
            -21.119,
            35.906,
            2.596,
            -21.119,
            35.906,
            2.596,
            -22.411,
            36.391,
            2.596,
            -22.411,
            36.391,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1903",
        "polygon": [
            -19.828,
            35.42,
            2.324,
            -18.536,
            34.934,
            2.324,
            -18.536,
            34.934,
            2.596,
            -18.536,
            34.934,
            2.596,
            -19.828,
            35.42,
            2.596,
            -19.828,
            35.42,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1904",
        "polygon": [
            -22.411,
            36.391,
            2.053,
            -21.119,
            35.906,
            2.053,
            -21.119,
            35.906,
            2.324,
            -21.119,
            35.906,
            2.324,
            -22.411,
            36.391,
            2.324,
            -22.411,
            36.391,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1905",
        "polygon": [
            -21.119,
            35.906,
            2.053,
            -19.828,
            35.42,
            2.053,
            -19.828,
            35.42,
            2.324,
            -19.828,
            35.42,
            2.324,
            -21.119,
            35.906,
            2.324,
            -21.119,
            35.906,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1906",
        "polygon": [
            -19.828,
            35.42,
            2.053,
            -18.536,
            34.934,
            2.053,
            -18.536,
            34.934,
            2.324,
            -18.536,
            34.934,
            2.324,
            -19.828,
            35.42,
            2.324,
            -19.828,
            35.42,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1907",
        "polygon": [
            -22.411,
            36.391,
            1.781,
            -21.119,
            35.906,
            1.781,
            -21.119,
            35.906,
            2.053,
            -21.119,
            35.906,
            2.053,
            -22.411,
            36.391,
            2.053,
            -22.411,
            36.391,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1908",
        "polygon": [
            -21.119,
            35.906,
            1.781,
            -19.828,
            35.42,
            1.781,
            -19.828,
            35.42,
            2.053,
            -19.828,
            35.42,
            2.053,
            -21.119,
            35.906,
            2.053,
            -21.119,
            35.906,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1909",
        "polygon": [
            -19.828,
            35.42,
            1.781,
            -18.536,
            34.934,
            1.781,
            -18.536,
            34.934,
            2.053,
            -18.536,
            34.934,
            2.053,
            -19.828,
            35.42,
            2.053,
            -19.828,
            35.42,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1910",
        "polygon": [
            -22.411,
            36.391,
            1.509,
            -21.119,
            35.906,
            1.509,
            -21.119,
            35.906,
            1.781,
            -21.119,
            35.906,
            1.781,
            -22.411,
            36.391,
            1.781,
            -22.411,
            36.391,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1911",
        "polygon": [
            -21.119,
            35.906,
            1.509,
            -19.828,
            35.42,
            1.509,
            -19.828,
            35.42,
            1.781,
            -19.828,
            35.42,
            1.781,
            -21.119,
            35.906,
            1.781,
            -21.119,
            35.906,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1912",
        "polygon": [
            -19.828,
            35.42,
            1.509,
            -18.536,
            34.934,
            1.509,
            -18.536,
            34.934,
            1.781,
            -18.536,
            34.934,
            1.781,
            -19.828,
            35.42,
            1.781,
            -19.828,
            35.42,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1913",
        "polygon": [
            -21.119,
            35.906,
            2.324,
            -19.828,
            35.42,
            2.324,
            -19.828,
            35.42,
            2.596,
            -19.828,
            35.42,
            2.596,
            -21.119,
            35.906,
            2.596,
            -21.119,
            35.906,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1914",
        "polygon": [
            -22.411,
            36.391,
            2.928,
            -18.536,
            34.934,
            2.928,
            -18.536,
            34.934,
            4.028,
            -18.536,
            34.934,
            4.028,
            -22.411,
            36.391,
            4.028,
            -22.411,
            36.391,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1915",
        "polygon": [
            -26.342,
            37.869,
            2.928,
            -22.467,
            36.412,
            2.928,
            -22.467,
            36.412,
            4.028,
            -22.467,
            36.412,
            4.028,
            -26.342,
            37.869,
            4.028,
            -26.342,
            37.869,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1916",
        "polygon": [
            -30.274,
            39.347,
            2.928,
            -26.399,
            37.89,
            2.928,
            -26.399,
            37.89,
            4.028,
            -26.399,
            37.89,
            4.028,
            -30.274,
            39.347,
            4.028,
            -30.274,
            39.347,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1917",
        "polygon": [
            -34.018,
            40.755,
            2.928,
            -30.33,
            39.368,
            2.928,
            -30.33,
            39.368,
            4.028,
            -30.33,
            39.368,
            4.028,
            -34.018,
            40.755,
            4.028,
            -34.018,
            40.755,
            2.928
        ],
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1918",
        "polygon": [
            -26.342,
            37.869,
            1.177,
            -25.051,
            37.384,
            1.177,
            -25.051,
            37.384,
            1.449,
            -25.051,
            37.384,
            1.449,
            -26.342,
            37.869,
            1.449,
            -26.342,
            37.869,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1919",
        "polygon": [
            -25.051,
            37.384,
            1.177,
            -23.759,
            36.898,
            1.177,
            -23.759,
            36.898,
            1.449,
            -23.759,
            36.898,
            1.449,
            -25.051,
            37.384,
            1.449,
            -25.051,
            37.384,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1920",
        "polygon": [
            -23.759,
            36.898,
            1.177,
            -22.467,
            36.412,
            1.177,
            -22.467,
            36.412,
            1.449,
            -22.467,
            36.412,
            1.449,
            -23.759,
            36.898,
            1.449,
            -23.759,
            36.898,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1921",
        "polygon": [
            -26.342,
            37.869,
            0.905,
            -25.051,
            37.384,
            0.905,
            -25.051,
            37.384,
            1.177,
            -25.051,
            37.384,
            1.177,
            -26.342,
            37.869,
            1.177,
            -26.342,
            37.869,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1922",
        "polygon": [
            -23.759,
            36.898,
            0.905,
            -22.467,
            36.412,
            0.905,
            -22.467,
            36.412,
            1.177,
            -22.467,
            36.412,
            1.177,
            -23.759,
            36.898,
            1.177,
            -23.759,
            36.898,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1923",
        "polygon": [
            -26.342,
            37.869,
            0.634,
            -25.051,
            37.384,
            0.634,
            -25.051,
            37.384,
            0.905,
            -25.051,
            37.384,
            0.905,
            -26.342,
            37.869,
            0.905,
            -26.342,
            37.869,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1924",
        "polygon": [
            -25.051,
            37.384,
            0.634,
            -23.759,
            36.898,
            0.634,
            -23.759,
            36.898,
            0.905,
            -23.759,
            36.898,
            0.905,
            -25.051,
            37.384,
            0.905,
            -25.051,
            37.384,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1925",
        "polygon": [
            -23.759,
            36.898,
            0.634,
            -22.467,
            36.412,
            0.634,
            -22.467,
            36.412,
            0.905,
            -22.467,
            36.412,
            0.905,
            -23.759,
            36.898,
            0.905,
            -23.759,
            36.898,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1926",
        "polygon": [
            -26.342,
            37.869,
            0.362,
            -25.051,
            37.384,
            0.362,
            -25.051,
            37.384,
            0.634,
            -25.051,
            37.384,
            0.634,
            -26.342,
            37.869,
            0.634,
            -26.342,
            37.869,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1927",
        "polygon": [
            -25.051,
            37.384,
            0.362,
            -23.759,
            36.898,
            0.362,
            -23.759,
            36.898,
            0.634,
            -23.759,
            36.898,
            0.634,
            -25.051,
            37.384,
            0.634,
            -25.051,
            37.384,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1928",
        "polygon": [
            -23.759,
            36.898,
            0.362,
            -22.467,
            36.412,
            0.362,
            -22.467,
            36.412,
            0.634,
            -22.467,
            36.412,
            0.634,
            -23.759,
            36.898,
            0.634,
            -23.759,
            36.898,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1929",
        "polygon": [
            -26.342,
            37.869,
            0.09,
            -25.051,
            37.384,
            0.09,
            -25.051,
            37.384,
            0.362,
            -25.051,
            37.384,
            0.362,
            -26.342,
            37.869,
            0.362,
            -26.342,
            37.869,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1930",
        "polygon": [
            -25.051,
            37.384,
            0.09,
            -23.759,
            36.898,
            0.09,
            -23.759,
            36.898,
            0.362,
            -23.759,
            36.898,
            0.362,
            -25.051,
            37.384,
            0.362,
            -25.051,
            37.384,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1931",
        "polygon": [
            -23.759,
            36.898,
            0.09,
            -22.467,
            36.412,
            0.09,
            -22.467,
            36.412,
            0.362,
            -22.467,
            36.412,
            0.362,
            -23.759,
            36.898,
            0.362,
            -23.759,
            36.898,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1932",
        "polygon": [
            -25.051,
            37.384,
            0.905,
            -23.759,
            36.898,
            0.905,
            -23.759,
            36.898,
            1.177,
            -23.759,
            36.898,
            1.177,
            -25.051,
            37.384,
            1.177,
            -25.051,
            37.384,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1933",
        "polygon": [
            -26.342,
            37.869,
            2.596,
            -25.051,
            37.384,
            2.596,
            -25.051,
            37.384,
            2.868,
            -25.051,
            37.384,
            2.868,
            -26.342,
            37.869,
            2.868,
            -26.342,
            37.869,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1934",
        "polygon": [
            -25.051,
            37.384,
            2.596,
            -23.759,
            36.898,
            2.596,
            -23.759,
            36.898,
            2.868,
            -23.759,
            36.898,
            2.868,
            -25.051,
            37.384,
            2.868,
            -25.051,
            37.384,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1935",
        "polygon": [
            -23.759,
            36.898,
            2.596,
            -22.467,
            36.412,
            2.596,
            -22.467,
            36.412,
            2.868,
            -22.467,
            36.412,
            2.868,
            -23.759,
            36.898,
            2.868,
            -23.759,
            36.898,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1936",
        "polygon": [
            -26.342,
            37.869,
            2.324,
            -25.051,
            37.384,
            2.324,
            -25.051,
            37.384,
            2.596,
            -25.051,
            37.384,
            2.596,
            -26.342,
            37.869,
            2.596,
            -26.342,
            37.869,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1937",
        "polygon": [
            -23.759,
            36.898,
            2.324,
            -22.467,
            36.412,
            2.324,
            -22.467,
            36.412,
            2.596,
            -22.467,
            36.412,
            2.596,
            -23.759,
            36.898,
            2.596,
            -23.759,
            36.898,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1938",
        "polygon": [
            -26.342,
            37.869,
            2.053,
            -25.051,
            37.384,
            2.053,
            -25.051,
            37.384,
            2.324,
            -25.051,
            37.384,
            2.324,
            -26.342,
            37.869,
            2.324,
            -26.342,
            37.869,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1939",
        "polygon": [
            -25.051,
            37.384,
            2.053,
            -23.759,
            36.898,
            2.053,
            -23.759,
            36.898,
            2.324,
            -23.759,
            36.898,
            2.324,
            -25.051,
            37.384,
            2.324,
            -25.051,
            37.384,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1940",
        "polygon": [
            -23.759,
            36.898,
            2.053,
            -22.467,
            36.412,
            2.053,
            -22.467,
            36.412,
            2.324,
            -22.467,
            36.412,
            2.324,
            -23.759,
            36.898,
            2.324,
            -23.759,
            36.898,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1941",
        "polygon": [
            -26.342,
            37.869,
            1.781,
            -25.051,
            37.384,
            1.781,
            -25.051,
            37.384,
            2.053,
            -25.051,
            37.384,
            2.053,
            -26.342,
            37.869,
            2.053,
            -26.342,
            37.869,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1942",
        "polygon": [
            -25.051,
            37.384,
            1.781,
            -23.759,
            36.898,
            1.781,
            -23.759,
            36.898,
            2.053,
            -23.759,
            36.898,
            2.053,
            -25.051,
            37.384,
            2.053,
            -25.051,
            37.384,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1943",
        "polygon": [
            -23.759,
            36.898,
            1.781,
            -22.467,
            36.412,
            1.781,
            -22.467,
            36.412,
            2.053,
            -22.467,
            36.412,
            2.053,
            -23.759,
            36.898,
            2.053,
            -23.759,
            36.898,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1944",
        "polygon": [
            -26.342,
            37.869,
            1.509,
            -25.051,
            37.384,
            1.509,
            -25.051,
            37.384,
            1.781,
            -25.051,
            37.384,
            1.781,
            -26.342,
            37.869,
            1.781,
            -26.342,
            37.869,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1945",
        "polygon": [
            -25.051,
            37.384,
            1.509,
            -23.759,
            36.898,
            1.509,
            -23.759,
            36.898,
            1.781,
            -23.759,
            36.898,
            1.781,
            -25.051,
            37.384,
            1.781,
            -25.051,
            37.384,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1946",
        "polygon": [
            -23.759,
            36.898,
            1.509,
            -22.467,
            36.412,
            1.509,
            -22.467,
            36.412,
            1.781,
            -22.467,
            36.412,
            1.781,
            -23.759,
            36.898,
            1.781,
            -23.759,
            36.898,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1947",
        "polygon": [
            -25.051,
            37.384,
            2.324,
            -23.759,
            36.898,
            2.324,
            -23.759,
            36.898,
            2.596,
            -23.759,
            36.898,
            2.596,
            -25.051,
            37.384,
            2.596,
            -25.051,
            37.384,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1948",
        "polygon": [
            -30.274,
            39.347,
            1.177,
            -28.982,
            38.862,
            1.177,
            -28.982,
            38.862,
            1.449,
            -28.982,
            38.862,
            1.449,
            -30.274,
            39.347,
            1.449,
            -30.274,
            39.347,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1949",
        "polygon": [
            -28.982,
            38.862,
            1.177,
            -27.69,
            38.376,
            1.177,
            -27.69,
            38.376,
            1.449,
            -27.69,
            38.376,
            1.449,
            -28.982,
            38.862,
            1.449,
            -28.982,
            38.862,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1950",
        "polygon": [
            -27.69,
            38.376,
            1.177,
            -26.399,
            37.89,
            1.177,
            -26.399,
            37.89,
            1.449,
            -26.399,
            37.89,
            1.449,
            -27.69,
            38.376,
            1.449,
            -27.69,
            38.376,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1951",
        "polygon": [
            -30.274,
            39.347,
            0.905,
            -28.982,
            38.862,
            0.905,
            -28.982,
            38.862,
            1.177,
            -28.982,
            38.862,
            1.177,
            -30.274,
            39.347,
            1.177,
            -30.274,
            39.347,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1952",
        "polygon": [
            -27.69,
            38.376,
            0.905,
            -26.399,
            37.89,
            0.905,
            -26.399,
            37.89,
            1.177,
            -26.399,
            37.89,
            1.177,
            -27.69,
            38.376,
            1.177,
            -27.69,
            38.376,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1953",
        "polygon": [
            -30.274,
            39.347,
            0.634,
            -28.982,
            38.862,
            0.634,
            -28.982,
            38.862,
            0.905,
            -28.982,
            38.862,
            0.905,
            -30.274,
            39.347,
            0.905,
            -30.274,
            39.347,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1954",
        "polygon": [
            -28.982,
            38.862,
            0.634,
            -27.69,
            38.376,
            0.634,
            -27.69,
            38.376,
            0.905,
            -27.69,
            38.376,
            0.905,
            -28.982,
            38.862,
            0.905,
            -28.982,
            38.862,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1955",
        "polygon": [
            -27.69,
            38.376,
            0.634,
            -26.399,
            37.89,
            0.634,
            -26.399,
            37.89,
            0.905,
            -26.399,
            37.89,
            0.905,
            -27.69,
            38.376,
            0.905,
            -27.69,
            38.376,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1956",
        "polygon": [
            -30.274,
            39.347,
            0.362,
            -28.982,
            38.862,
            0.362,
            -28.982,
            38.862,
            0.634,
            -28.982,
            38.862,
            0.634,
            -30.274,
            39.347,
            0.634,
            -30.274,
            39.347,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1957",
        "polygon": [
            -28.982,
            38.862,
            0.362,
            -27.69,
            38.376,
            0.362,
            -27.69,
            38.376,
            0.634,
            -27.69,
            38.376,
            0.634,
            -28.982,
            38.862,
            0.634,
            -28.982,
            38.862,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1958",
        "polygon": [
            -27.69,
            38.376,
            0.362,
            -26.399,
            37.89,
            0.362,
            -26.399,
            37.89,
            0.634,
            -26.399,
            37.89,
            0.634,
            -27.69,
            38.376,
            0.634,
            -27.69,
            38.376,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1959",
        "polygon": [
            -30.274,
            39.347,
            0.09,
            -28.982,
            38.862,
            0.09,
            -28.982,
            38.862,
            0.362,
            -28.982,
            38.862,
            0.362,
            -30.274,
            39.347,
            0.362,
            -30.274,
            39.347,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1960",
        "polygon": [
            -28.982,
            38.862,
            0.09,
            -27.69,
            38.376,
            0.09,
            -27.69,
            38.376,
            0.362,
            -27.69,
            38.376,
            0.362,
            -28.982,
            38.862,
            0.362,
            -28.982,
            38.862,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1961",
        "polygon": [
            -27.69,
            38.376,
            0.09,
            -26.399,
            37.89,
            0.09,
            -26.399,
            37.89,
            0.362,
            -26.399,
            37.89,
            0.362,
            -27.69,
            38.376,
            0.362,
            -27.69,
            38.376,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1962",
        "polygon": [
            -28.982,
            38.862,
            0.905,
            -27.69,
            38.376,
            0.905,
            -27.69,
            38.376,
            1.177,
            -27.69,
            38.376,
            1.177,
            -28.982,
            38.862,
            1.177,
            -28.982,
            38.862,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1963",
        "polygon": [
            -30.274,
            39.347,
            2.596,
            -28.982,
            38.862,
            2.596,
            -28.982,
            38.862,
            2.868,
            -28.982,
            38.862,
            2.868,
            -30.274,
            39.347,
            2.868,
            -30.274,
            39.347,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1964",
        "polygon": [
            -28.982,
            38.862,
            2.596,
            -27.69,
            38.376,
            2.596,
            -27.69,
            38.376,
            2.868,
            -27.69,
            38.376,
            2.868,
            -28.982,
            38.862,
            2.868,
            -28.982,
            38.862,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1965",
        "polygon": [
            -27.69,
            38.376,
            2.596,
            -26.399,
            37.89,
            2.596,
            -26.399,
            37.89,
            2.868,
            -26.399,
            37.89,
            2.868,
            -27.69,
            38.376,
            2.868,
            -27.69,
            38.376,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1966",
        "polygon": [
            -30.274,
            39.347,
            2.324,
            -28.982,
            38.862,
            2.324,
            -28.982,
            38.862,
            2.596,
            -28.982,
            38.862,
            2.596,
            -30.274,
            39.347,
            2.596,
            -30.274,
            39.347,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1967",
        "polygon": [
            -27.69,
            38.376,
            2.324,
            -26.399,
            37.89,
            2.324,
            -26.399,
            37.89,
            2.596,
            -26.399,
            37.89,
            2.596,
            -27.69,
            38.376,
            2.596,
            -27.69,
            38.376,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1968",
        "polygon": [
            -30.274,
            39.347,
            2.053,
            -28.982,
            38.862,
            2.053,
            -28.982,
            38.862,
            2.324,
            -28.982,
            38.862,
            2.324,
            -30.274,
            39.347,
            2.324,
            -30.274,
            39.347,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1969",
        "polygon": [
            -28.982,
            38.862,
            2.053,
            -27.69,
            38.376,
            2.053,
            -27.69,
            38.376,
            2.324,
            -27.69,
            38.376,
            2.324,
            -28.982,
            38.862,
            2.324,
            -28.982,
            38.862,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1970",
        "polygon": [
            -27.69,
            38.376,
            2.053,
            -26.399,
            37.89,
            2.053,
            -26.399,
            37.89,
            2.324,
            -26.399,
            37.89,
            2.324,
            -27.69,
            38.376,
            2.324,
            -27.69,
            38.376,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1971",
        "polygon": [
            -30.274,
            39.347,
            1.781,
            -28.982,
            38.862,
            1.781,
            -28.982,
            38.862,
            2.053,
            -28.982,
            38.862,
            2.053,
            -30.274,
            39.347,
            2.053,
            -30.274,
            39.347,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1972",
        "polygon": [
            -28.982,
            38.862,
            1.781,
            -27.69,
            38.376,
            1.781,
            -27.69,
            38.376,
            2.053,
            -27.69,
            38.376,
            2.053,
            -28.982,
            38.862,
            2.053,
            -28.982,
            38.862,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1973",
        "polygon": [
            -27.69,
            38.376,
            1.781,
            -26.399,
            37.89,
            1.781,
            -26.399,
            37.89,
            2.053,
            -26.399,
            37.89,
            2.053,
            -27.69,
            38.376,
            2.053,
            -27.69,
            38.376,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1974",
        "polygon": [
            -30.274,
            39.347,
            1.509,
            -28.982,
            38.862,
            1.509,
            -28.982,
            38.862,
            1.781,
            -28.982,
            38.862,
            1.781,
            -30.274,
            39.347,
            1.781,
            -30.274,
            39.347,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1975",
        "polygon": [
            -28.982,
            38.862,
            1.509,
            -27.69,
            38.376,
            1.509,
            -27.69,
            38.376,
            1.781,
            -27.69,
            38.376,
            1.781,
            -28.982,
            38.862,
            1.781,
            -28.982,
            38.862,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1976",
        "polygon": [
            -27.69,
            38.376,
            1.509,
            -26.399,
            37.89,
            1.509,
            -26.399,
            37.89,
            1.781,
            -26.399,
            37.89,
            1.781,
            -27.69,
            38.376,
            1.781,
            -27.69,
            38.376,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1977",
        "polygon": [
            -28.982,
            38.862,
            2.324,
            -27.69,
            38.376,
            2.324,
            -27.69,
            38.376,
            2.596,
            -27.69,
            38.376,
            2.596,
            -28.982,
            38.862,
            2.596,
            -28.982,
            38.862,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1978",
        "polygon": [
            -34.018,
            40.755,
            1.177,
            -32.913,
            40.34,
            1.177,
            -32.913,
            40.34,
            1.449,
            -32.913,
            40.34,
            1.449,
            -34.018,
            40.755,
            1.449,
            -34.018,
            40.755,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1979",
        "polygon": [
            -32.913,
            40.34,
            1.177,
            -31.622,
            39.854,
            1.177,
            -31.622,
            39.854,
            1.449,
            -31.622,
            39.854,
            1.449,
            -32.913,
            40.34,
            1.449,
            -32.913,
            40.34,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1980",
        "polygon": [
            -31.622,
            39.854,
            1.177,
            -30.33,
            39.368,
            1.177,
            -30.33,
            39.368,
            1.449,
            -30.33,
            39.368,
            1.449,
            -31.622,
            39.854,
            1.449,
            -31.622,
            39.854,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1981",
        "polygon": [
            -34.018,
            40.755,
            0.905,
            -32.913,
            40.34,
            0.905,
            -32.913,
            40.34,
            1.177,
            -32.913,
            40.34,
            1.177,
            -34.018,
            40.755,
            1.177,
            -34.018,
            40.755,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1982",
        "polygon": [
            -31.622,
            39.854,
            0.905,
            -30.33,
            39.368,
            0.905,
            -30.33,
            39.368,
            1.177,
            -30.33,
            39.368,
            1.177,
            -31.622,
            39.854,
            1.177,
            -31.622,
            39.854,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1983",
        "polygon": [
            -34.018,
            40.755,
            0.634,
            -32.913,
            40.34,
            0.634,
            -32.913,
            40.34,
            0.905,
            -32.913,
            40.34,
            0.905,
            -34.018,
            40.755,
            0.905,
            -34.018,
            40.755,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1984",
        "polygon": [
            -32.913,
            40.34,
            0.634,
            -31.622,
            39.854,
            0.634,
            -31.622,
            39.854,
            0.905,
            -31.622,
            39.854,
            0.905,
            -32.913,
            40.34,
            0.905,
            -32.913,
            40.34,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1985",
        "polygon": [
            -31.622,
            39.854,
            0.634,
            -30.33,
            39.368,
            0.634,
            -30.33,
            39.368,
            0.905,
            -30.33,
            39.368,
            0.905,
            -31.622,
            39.854,
            0.905,
            -31.622,
            39.854,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1986",
        "polygon": [
            -34.018,
            40.755,
            0.362,
            -32.913,
            40.34,
            0.362,
            -32.913,
            40.34,
            0.634,
            -32.913,
            40.34,
            0.634,
            -34.018,
            40.755,
            0.634,
            -34.018,
            40.755,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1987",
        "polygon": [
            -32.913,
            40.34,
            0.362,
            -31.622,
            39.854,
            0.362,
            -31.622,
            39.854,
            0.634,
            -31.622,
            39.854,
            0.634,
            -32.913,
            40.34,
            0.634,
            -32.913,
            40.34,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1988",
        "polygon": [
            -31.622,
            39.854,
            0.362,
            -30.33,
            39.368,
            0.362,
            -30.33,
            39.368,
            0.634,
            -30.33,
            39.368,
            0.634,
            -31.622,
            39.854,
            0.634,
            -31.622,
            39.854,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1989",
        "polygon": [
            -34.018,
            40.755,
            0.09,
            -32.913,
            40.34,
            0.09,
            -32.913,
            40.34,
            0.362,
            -32.913,
            40.34,
            0.362,
            -34.018,
            40.755,
            0.362,
            -34.018,
            40.755,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1990",
        "polygon": [
            -32.913,
            40.34,
            0.09,
            -31.622,
            39.854,
            0.09,
            -31.622,
            39.854,
            0.362,
            -31.622,
            39.854,
            0.362,
            -32.913,
            40.34,
            0.362,
            -32.913,
            40.34,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1991",
        "polygon": [
            -31.622,
            39.854,
            0.09,
            -30.33,
            39.368,
            0.09,
            -30.33,
            39.368,
            0.362,
            -30.33,
            39.368,
            0.362,
            -31.622,
            39.854,
            0.362,
            -31.622,
            39.854,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1992",
        "polygon": [
            -32.913,
            40.34,
            0.905,
            -31.622,
            39.854,
            0.905,
            -31.622,
            39.854,
            1.177,
            -31.622,
            39.854,
            1.177,
            -32.913,
            40.34,
            1.177,
            -32.913,
            40.34,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1993",
        "polygon": [
            -34.018,
            40.755,
            2.596,
            -32.913,
            40.34,
            2.596,
            -32.913,
            40.34,
            2.868,
            -32.913,
            40.34,
            2.868,
            -34.018,
            40.755,
            2.868,
            -34.018,
            40.755,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1994",
        "polygon": [
            -32.913,
            40.34,
            2.596,
            -31.622,
            39.854,
            2.596,
            -31.622,
            39.854,
            2.868,
            -31.622,
            39.854,
            2.868,
            -32.913,
            40.34,
            2.868,
            -32.913,
            40.34,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1995",
        "polygon": [
            -31.622,
            39.854,
            2.596,
            -30.33,
            39.368,
            2.596,
            -30.33,
            39.368,
            2.868,
            -30.33,
            39.368,
            2.868,
            -31.622,
            39.854,
            2.868,
            -31.622,
            39.854,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1996",
        "polygon": [
            -34.018,
            40.755,
            2.324,
            -32.913,
            40.34,
            2.324,
            -32.913,
            40.34,
            2.596,
            -32.913,
            40.34,
            2.596,
            -34.018,
            40.755,
            2.596,
            -34.018,
            40.755,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1997",
        "polygon": [
            -31.622,
            39.854,
            2.324,
            -30.33,
            39.368,
            2.324,
            -30.33,
            39.368,
            2.596,
            -30.33,
            39.368,
            2.596,
            -31.622,
            39.854,
            2.596,
            -31.622,
            39.854,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1998",
        "polygon": [
            -34.018,
            40.755,
            2.053,
            -32.913,
            40.34,
            2.053,
            -32.913,
            40.34,
            2.324,
            -32.913,
            40.34,
            2.324,
            -34.018,
            40.755,
            2.324,
            -34.018,
            40.755,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_1999",
        "polygon": [
            -32.913,
            40.34,
            2.053,
            -31.622,
            39.854,
            2.053,
            -31.622,
            39.854,
            2.324,
            -31.622,
            39.854,
            2.324,
            -32.913,
            40.34,
            2.324,
            -32.913,
            40.34,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2000",
        "polygon": [
            -31.622,
            39.854,
            2.053,
            -30.33,
            39.368,
            2.053,
            -30.33,
            39.368,
            2.324,
            -30.33,
            39.368,
            2.324,
            -31.622,
            39.854,
            2.324,
            -31.622,
            39.854,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2001",
        "polygon": [
            -34.018,
            40.755,
            1.781,
            -32.913,
            40.34,
            1.781,
            -32.913,
            40.34,
            2.053,
            -32.913,
            40.34,
            2.053,
            -34.018,
            40.755,
            2.053,
            -34.018,
            40.755,
            1.781
        ],
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2002",
        "polygon": [
            -32.913,
            40.34,
            1.781,
            -31.622,
            39.854,
            1.781,
            -31.622,
            39.854,
            2.053,
            -31.622,
            39.854,
            2.053,
            -32.913,
            40.34,
            2.053,
            -32.913,
            40.34,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2003",
        "polygon": [
            -31.622,
            39.854,
            1.781,
            -30.33,
            39.368,
            1.781,
            -30.33,
            39.368,
            2.053,
            -30.33,
            39.368,
            2.053,
            -31.622,
            39.854,
            2.053,
            -31.622,
            39.854,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2004",
        "polygon": [
            -34.018,
            40.755,
            1.509,
            -32.913,
            40.34,
            1.509,
            -32.913,
            40.34,
            1.781,
            -32.913,
            40.34,
            1.781,
            -34.018,
            40.755,
            1.781,
            -34.018,
            40.755,
            1.509
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2005",
        "polygon": [
            -32.913,
            40.34,
            1.509,
            -31.622,
            39.854,
            1.509,
            -31.622,
            39.854,
            1.781,
            -31.622,
            39.854,
            1.781,
            -32.913,
            40.34,
            1.781,
            -32.913,
            40.34,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2006",
        "polygon": [
            -31.622,
            39.854,
            1.509,
            -30.33,
            39.368,
            1.509,
            -30.33,
            39.368,
            1.781,
            -30.33,
            39.368,
            1.781,
            -31.622,
            39.854,
            1.781,
            -31.622,
            39.854,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2007",
        "polygon": [
            -32.913,
            40.34,
            2.324,
            -31.622,
            39.854,
            2.324,
            -31.622,
            39.854,
            2.596,
            -31.622,
            39.854,
            2.596,
            -32.913,
            40.34,
            2.596,
            -32.913,
            40.34,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2008",
        "polygon": [
            -22.411,
            36.391,
            15.216,
            -18.536,
            34.934,
            15.216,
            -18.536,
            34.934,
            17.166,
            -18.536,
            34.934,
            17.166,
            -22.411,
            36.391,
            17.166,
            -22.411,
            36.391,
            15.216
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2009",
        "polygon": [
            -26.342,
            37.869,
            15.216,
            -22.467,
            36.412,
            15.216,
            -22.467,
            36.412,
            17.344,
            -22.467,
            36.412,
            17.344,
            -26.342,
            37.869,
            17.344,
            -26.342,
            37.869,
            15.216
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2010",
        "polygon": [
            -18.48,
            34.913,
            15.216,
            -14.839,
            33.544,
            15.216,
            -14.839,
            33.544,
            17.016,
            -14.839,
            33.544,
            17.016,
            -18.48,
            34.913,
            17.016,
            -18.48,
            34.913,
            15.216
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2011",
        "polygon": [
            -30.274,
            39.347,
            15.216,
            -26.399,
            37.89,
            15.216,
            -26.399,
            37.89,
            17.166,
            -26.399,
            37.89,
            17.166,
            -30.274,
            39.347,
            17.166,
            -30.274,
            39.347,
            15.216
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2012",
        "polygon": [
            -33.971,
            40.737,
            15.216,
            -30.33,
            39.368,
            15.216,
            -30.33,
            39.368,
            17.016,
            -30.33,
            39.368,
            17.016,
            -33.971,
            40.737,
            17.016,
            -33.971,
            40.737,
            15.216
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2018",
        "polygon": [
            -90.551,
            62.02,
            5.944,
            -86.676,
            60.563,
            5.944,
            -86.676,
            60.563,
            7.739,
            -86.676,
            60.563,
            7.739,
            -90.551,
            62.02,
            7.739,
            -90.551,
            62.02,
            5.944
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2019",
        "polygon": [
            -98.413,
            64.976,
            5.944,
            -94.538,
            63.519,
            5.944,
            -94.538,
            63.519,
            7.739,
            -94.538,
            63.519,
            7.739,
            -98.413,
            64.976,
            7.739,
            -98.413,
            64.976,
            5.944
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2020",
        "polygon": [
            -102.158,
            66.383,
            5.944,
            -98.47,
            64.997,
            5.944,
            -98.47,
            64.997,
            7.739,
            -98.47,
            64.997,
            7.739,
            -102.158,
            66.383,
            7.739,
            -102.158,
            66.383,
            5.944
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2021",
        "polygon": [
            -94.482,
            63.498,
            5.944,
            -90.607,
            62.041,
            5.944,
            -90.607,
            62.041,
            7.739,
            -90.607,
            62.041,
            7.739,
            -94.482,
            63.498,
            7.739,
            -94.482,
            63.498,
            5.944
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2022",
        "polygon": [
            -86.619,
            60.542,
            5.944,
            -82.931,
            59.155,
            5.944,
            -82.931,
            59.155,
            7.739,
            -82.931,
            59.155,
            7.739,
            -86.619,
            60.542,
            7.739,
            -86.619,
            60.542,
            5.944
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2028",
        "polygon": [
            -90.551,
            62.02,
            9.654,
            -86.676,
            60.563,
            9.654,
            -86.676,
            60.563,
            11.449,
            -86.676,
            60.563,
            11.449,
            -90.551,
            62.02,
            11.449,
            -90.551,
            62.02,
            9.654
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2029",
        "polygon": [
            -98.413,
            64.976,
            9.654,
            -94.538,
            63.519,
            9.654,
            -94.538,
            63.519,
            11.449,
            -94.538,
            63.519,
            11.449,
            -98.413,
            64.976,
            11.449,
            -98.413,
            64.976,
            9.654
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2030",
        "polygon": [
            -102.158,
            66.383,
            9.654,
            -98.47,
            64.997,
            9.654,
            -98.47,
            64.997,
            11.449,
            -98.47,
            64.997,
            11.449,
            -102.158,
            66.383,
            11.449,
            -102.158,
            66.383,
            9.654
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2031",
        "polygon": [
            -94.482,
            63.498,
            9.654,
            -90.607,
            62.041,
            9.654,
            -90.607,
            62.041,
            11.449,
            -90.607,
            62.041,
            11.449,
            -94.482,
            63.498,
            11.449,
            -94.482,
            63.498,
            9.654
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2032",
        "polygon": [
            -86.619,
            60.542,
            9.654,
            -82.931,
            59.155,
            9.654,
            -82.931,
            59.155,
            11.449,
            -82.931,
            59.155,
            11.449,
            -86.619,
            60.542,
            11.449,
            -86.619,
            60.542,
            9.654
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2038",
        "polygon": [
            -90.551,
            62.02,
            13.364,
            -86.676,
            60.563,
            13.364,
            -86.676,
            60.563,
            15.159,
            -86.676,
            60.563,
            15.159,
            -90.551,
            62.02,
            15.159,
            -90.551,
            62.02,
            13.364
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2039",
        "polygon": [
            -98.413,
            64.976,
            13.364,
            -94.538,
            63.519,
            13.364,
            -94.538,
            63.519,
            15.159,
            -94.538,
            63.519,
            15.159,
            -98.413,
            64.976,
            15.159,
            -98.413,
            64.976,
            13.364
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2040",
        "polygon": [
            -102.158,
            66.383,
            13.364,
            -98.47,
            64.997,
            13.364,
            -98.47,
            64.997,
            15.159,
            -98.47,
            64.997,
            15.159,
            -102.158,
            66.383,
            15.159,
            -102.158,
            66.383,
            13.364
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2041",
        "polygon": [
            -94.482,
            63.498,
            13.364,
            -90.607,
            62.041,
            13.364,
            -90.607,
            62.041,
            15.159,
            -90.607,
            62.041,
            15.159,
            -94.482,
            63.498,
            15.159,
            -94.482,
            63.498,
            13.364
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2042",
        "polygon": [
            -86.619,
            60.542,
            13.364,
            -82.931,
            59.155,
            13.364,
            -82.931,
            59.155,
            15.159,
            -82.931,
            59.155,
            15.159,
            -86.619,
            60.542,
            15.159,
            -86.619,
            60.542,
            13.364
        ],
        "05-24 18:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2043",
        "polygon": [
            -86.619,
            60.542,
            2.928,
            -82.931,
            59.155,
            2.928,
            -82.931,
            59.155,
            4.028,
            -82.931,
            59.155,
            4.028,
            -86.619,
            60.542,
            4.028,
            -86.619,
            60.542,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2044",
        "polygon": [
            -90.551,
            62.02,
            2.928,
            -86.676,
            60.563,
            2.928,
            -86.676,
            60.563,
            4.028,
            -86.676,
            60.563,
            4.028,
            -90.551,
            62.02,
            4.028,
            -90.551,
            62.02,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2045",
        "polygon": [
            -94.482,
            63.498,
            2.928,
            -90.607,
            62.041,
            2.928,
            -90.607,
            62.041,
            4.028,
            -90.607,
            62.041,
            4.028,
            -94.482,
            63.498,
            4.028,
            -94.482,
            63.498,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2046",
        "polygon": [
            -98.413,
            64.976,
            2.928,
            -94.538,
            63.519,
            2.928,
            -94.538,
            63.519,
            4.028,
            -94.538,
            63.519,
            4.028,
            -98.413,
            64.976,
            4.028,
            -98.413,
            64.976,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2047",
        "polygon": [
            -102.158,
            66.383,
            2.928,
            -98.47,
            64.997,
            2.928,
            -98.47,
            64.997,
            4.028,
            -98.47,
            64.997,
            4.028,
            -102.158,
            66.383,
            4.028,
            -102.158,
            66.383,
            2.928
        ],
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2048",
        "polygon": [
            -90.551,
            62.02,
            15.216,
            -86.676,
            60.563,
            15.216,
            -86.676,
            60.563,
            17.166,
            -86.676,
            60.563,
            17.166,
            -90.551,
            62.02,
            17.166,
            -90.551,
            62.02,
            15.216
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2049",
        "polygon": [
            -94.482,
            63.498,
            15.216,
            -90.607,
            62.041,
            15.216,
            -90.607,
            62.041,
            17.344,
            -90.607,
            62.041,
            17.344,
            -94.482,
            63.498,
            17.344,
            -94.482,
            63.498,
            15.216
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2050",
        "polygon": [
            -86.619,
            60.542,
            15.216,
            -82.978,
            59.173,
            15.216,
            -82.978,
            59.173,
            17.016,
            -82.978,
            59.173,
            17.016,
            -86.619,
            60.542,
            17.016,
            -86.619,
            60.542,
            15.216
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2051",
        "polygon": [
            -98.413,
            64.976,
            15.216,
            -94.538,
            63.519,
            15.216,
            -94.538,
            63.519,
            17.166,
            -94.538,
            63.519,
            17.166,
            -98.413,
            64.976,
            17.166,
            -98.413,
            64.976,
            15.216
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2052",
        "polygon": [
            -102.111,
            66.366,
            15.216,
            -98.47,
            64.997,
            15.216,
            -98.47,
            64.997,
            17.016,
            -98.47,
            64.997,
            17.016,
            -102.111,
            66.366,
            17.016,
            -102.111,
            66.366,
            15.216
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2053",
        "polygon": [
            -90.551,
            62.02,
            11.509,
            -86.676,
            60.563,
            11.509,
            -86.676,
            60.563,
            13.304,
            -86.676,
            60.563,
            13.304,
            -90.551,
            62.02,
            13.304,
            -90.551,
            62.02,
            11.509
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2054",
        "polygon": [
            -98.413,
            64.976,
            11.509,
            -94.538,
            63.519,
            11.509,
            -94.538,
            63.519,
            13.304,
            -94.538,
            63.519,
            13.304,
            -98.413,
            64.976,
            13.304,
            -98.413,
            64.976,
            11.509
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2055",
        "polygon": [
            -102.158,
            66.383,
            11.509,
            -98.47,
            64.997,
            11.509,
            -98.47,
            64.997,
            13.304,
            -98.47,
            64.997,
            13.304,
            -102.158,
            66.383,
            13.304,
            -102.158,
            66.383,
            11.509
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2056",
        "polygon": [
            -94.482,
            63.498,
            11.509,
            -90.607,
            62.041,
            11.509,
            -90.607,
            62.041,
            13.304,
            -90.607,
            62.041,
            13.304,
            -94.482,
            63.498,
            13.304,
            -94.482,
            63.498,
            11.509
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2057",
        "polygon": [
            -86.619,
            60.542,
            11.509,
            -82.931,
            59.155,
            11.509,
            -82.931,
            59.155,
            13.304,
            -82.931,
            59.155,
            13.304,
            -86.619,
            60.542,
            13.304,
            -86.619,
            60.542,
            11.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2058",
        "polygon": [
            -90.551,
            62.02,
            7.798,
            -86.676,
            60.563,
            7.798,
            -86.676,
            60.563,
            9.593,
            -86.676,
            60.563,
            9.593,
            -90.551,
            62.02,
            9.593,
            -90.551,
            62.02,
            7.798
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2059",
        "polygon": [
            -98.413,
            64.976,
            7.798,
            -94.538,
            63.519,
            7.798,
            -94.538,
            63.519,
            9.593,
            -94.538,
            63.519,
            9.593,
            -98.413,
            64.976,
            9.593,
            -98.413,
            64.976,
            7.798
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2060",
        "polygon": [
            -102.158,
            66.383,
            7.798,
            -98.47,
            64.997,
            7.798,
            -98.47,
            64.997,
            9.593,
            -98.47,
            64.997,
            9.593,
            -102.158,
            66.383,
            9.593,
            -102.158,
            66.383,
            7.798
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2061",
        "polygon": [
            -94.482,
            63.498,
            7.798,
            -90.607,
            62.041,
            7.798,
            -90.607,
            62.041,
            9.593,
            -90.607,
            62.041,
            9.593,
            -94.482,
            63.498,
            9.593,
            -94.482,
            63.498,
            7.798
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2062",
        "polygon": [
            -86.619,
            60.542,
            7.798,
            -82.931,
            59.155,
            7.798,
            -82.931,
            59.155,
            9.593,
            -82.931,
            59.155,
            9.593,
            -86.619,
            60.542,
            9.593,
            -86.619,
            60.542,
            7.798
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2063",
        "polygon": [
            -90.551,
            62.02,
            4.089,
            -86.676,
            60.563,
            4.089,
            -86.676,
            60.563,
            5.884,
            -86.676,
            60.563,
            5.884,
            -90.551,
            62.02,
            5.884,
            -90.551,
            62.02,
            4.089
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2064",
        "polygon": [
            -94.482,
            63.498,
            4.089,
            -90.607,
            62.041,
            4.089,
            -90.607,
            62.041,
            5.884,
            -90.607,
            62.041,
            5.884,
            -94.482,
            63.498,
            5.884,
            -94.482,
            63.498,
            4.089
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2065",
        "polygon": [
            -98.413,
            64.976,
            4.089,
            -94.538,
            63.519,
            4.089,
            -94.538,
            63.519,
            5.884,
            -94.538,
            63.519,
            5.884,
            -98.413,
            64.976,
            5.884,
            -98.413,
            64.976,
            4.089
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2066",
        "polygon": [
            -102.158,
            66.383,
            4.088,
            -98.47,
            64.997,
            4.088,
            -98.47,
            64.997,
            5.883,
            -98.47,
            64.997,
            5.883,
            -102.158,
            66.383,
            5.883,
            -102.158,
            66.383,
            4.088
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2067",
        "polygon": [
            -86.619,
            60.542,
            4.088,
            -82.931,
            59.155,
            4.088,
            -82.931,
            59.155,
            5.883,
            -82.931,
            59.155,
            5.883,
            -86.619,
            60.542,
            5.883,
            -86.619,
            60.542,
            4.088
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2068",
        "polygon": [
            -86.619,
            60.542,
            2.596,
            -85.328,
            60.056,
            2.596,
            -85.328,
            60.056,
            2.868,
            -85.328,
            60.056,
            2.868,
            -86.619,
            60.542,
            2.868,
            -86.619,
            60.542,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2069",
        "polygon": [
            -85.328,
            60.056,
            2.596,
            -84.036,
            59.57,
            2.596,
            -84.036,
            59.57,
            2.868,
            -84.036,
            59.57,
            2.868,
            -85.328,
            60.056,
            2.868,
            -85.328,
            60.056,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2070",
        "polygon": [
            -84.035,
            59.57,
            2.596,
            -82.931,
            59.155,
            2.596,
            -82.931,
            59.155,
            2.868,
            -82.931,
            59.155,
            2.868,
            -84.035,
            59.57,
            2.868,
            -84.035,
            59.57,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2071",
        "polygon": [
            -86.619,
            60.542,
            2.324,
            -85.328,
            60.056,
            2.324,
            -85.328,
            60.056,
            2.596,
            -85.328,
            60.056,
            2.596,
            -86.619,
            60.542,
            2.596,
            -86.619,
            60.542,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2072",
        "polygon": [
            -84.035,
            59.57,
            2.324,
            -82.931,
            59.155,
            2.324,
            -82.931,
            59.155,
            2.596,
            -82.931,
            59.155,
            2.596,
            -84.035,
            59.57,
            2.596,
            -84.035,
            59.57,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2073",
        "polygon": [
            -86.619,
            60.542,
            2.053,
            -85.328,
            60.056,
            2.053,
            -85.328,
            60.056,
            2.324,
            -85.328,
            60.056,
            2.324,
            -86.619,
            60.542,
            2.324,
            -86.619,
            60.542,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2074",
        "polygon": [
            -85.328,
            60.056,
            2.053,
            -84.036,
            59.57,
            2.053,
            -84.036,
            59.57,
            2.324,
            -84.036,
            59.57,
            2.324,
            -85.328,
            60.056,
            2.324,
            -85.328,
            60.056,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2075",
        "polygon": [
            -84.035,
            59.57,
            2.053,
            -82.931,
            59.155,
            2.053,
            -82.931,
            59.155,
            2.324,
            -82.931,
            59.155,
            2.324,
            -84.035,
            59.57,
            2.324,
            -84.035,
            59.57,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2076",
        "polygon": [
            -86.619,
            60.542,
            1.781,
            -85.328,
            60.056,
            1.781,
            -85.328,
            60.056,
            2.053,
            -85.328,
            60.056,
            2.053,
            -86.619,
            60.542,
            2.053,
            -86.619,
            60.542,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2077",
        "polygon": [
            -85.328,
            60.056,
            1.781,
            -84.036,
            59.57,
            1.781,
            -84.036,
            59.57,
            2.053,
            -84.036,
            59.57,
            2.053,
            -85.328,
            60.056,
            2.053,
            -85.328,
            60.056,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2078",
        "polygon": [
            -84.035,
            59.57,
            1.781,
            -82.931,
            59.155,
            1.781,
            -82.931,
            59.155,
            2.053,
            -82.931,
            59.155,
            2.053,
            -84.035,
            59.57,
            2.053,
            -84.035,
            59.57,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2079",
        "polygon": [
            -86.619,
            60.542,
            1.509,
            -85.328,
            60.056,
            1.509,
            -85.328,
            60.056,
            1.781,
            -85.328,
            60.056,
            1.781,
            -86.619,
            60.542,
            1.781,
            -86.619,
            60.542,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2080",
        "polygon": [
            -85.328,
            60.056,
            1.509,
            -84.036,
            59.57,
            1.509,
            -84.036,
            59.57,
            1.781,
            -84.036,
            59.57,
            1.781,
            -85.328,
            60.056,
            1.781,
            -85.328,
            60.056,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2081",
        "polygon": [
            -84.035,
            59.57,
            1.509,
            -82.931,
            59.155,
            1.509,
            -82.931,
            59.155,
            1.781,
            -82.931,
            59.155,
            1.781,
            -84.035,
            59.57,
            1.781,
            -84.035,
            59.57,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2082",
        "polygon": [
            -85.328,
            60.056,
            2.324,
            -84.036,
            59.57,
            2.324,
            -84.036,
            59.57,
            2.596,
            -84.036,
            59.57,
            2.596,
            -85.328,
            60.056,
            2.596,
            -85.328,
            60.056,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2083",
        "polygon": [
            -86.619,
            60.542,
            1.177,
            -85.328,
            60.056,
            1.177,
            -85.328,
            60.056,
            1.449,
            -85.328,
            60.056,
            1.449,
            -86.619,
            60.542,
            1.449,
            -86.619,
            60.542,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2084",
        "polygon": [
            -85.328,
            60.056,
            1.177,
            -84.036,
            59.57,
            1.177,
            -84.036,
            59.57,
            1.449,
            -84.036,
            59.57,
            1.449,
            -85.328,
            60.056,
            1.449,
            -85.328,
            60.056,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2085",
        "polygon": [
            -84.035,
            59.57,
            1.177,
            -82.931,
            59.155,
            1.177,
            -82.931,
            59.155,
            1.449,
            -82.931,
            59.155,
            1.449,
            -84.035,
            59.57,
            1.449,
            -84.035,
            59.57,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2086",
        "polygon": [
            -86.619,
            60.542,
            0.905,
            -85.328,
            60.056,
            0.905,
            -85.328,
            60.056,
            1.177,
            -85.328,
            60.056,
            1.177,
            -86.619,
            60.542,
            1.177,
            -86.619,
            60.542,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2087",
        "polygon": [
            -84.035,
            59.57,
            0.905,
            -82.931,
            59.155,
            0.905,
            -82.931,
            59.155,
            1.177,
            -82.931,
            59.155,
            1.177,
            -84.035,
            59.57,
            1.177,
            -84.035,
            59.57,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2088",
        "polygon": [
            -86.619,
            60.542,
            0.634,
            -85.328,
            60.056,
            0.634,
            -85.328,
            60.056,
            0.905,
            -85.328,
            60.056,
            0.905,
            -86.619,
            60.542,
            0.905,
            -86.619,
            60.542,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2089",
        "polygon": [
            -85.328,
            60.056,
            0.634,
            -84.036,
            59.57,
            0.634,
            -84.036,
            59.57,
            0.905,
            -84.036,
            59.57,
            0.905,
            -85.328,
            60.056,
            0.905,
            -85.328,
            60.056,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2090",
        "polygon": [
            -84.035,
            59.57,
            0.634,
            -82.931,
            59.155,
            0.634,
            -82.931,
            59.155,
            0.905,
            -82.931,
            59.155,
            0.905,
            -84.035,
            59.57,
            0.905,
            -84.035,
            59.57,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2091",
        "polygon": [
            -86.619,
            60.542,
            0.362,
            -85.328,
            60.056,
            0.362,
            -85.328,
            60.056,
            0.634,
            -85.328,
            60.056,
            0.634,
            -86.619,
            60.542,
            0.634,
            -86.619,
            60.542,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2092",
        "polygon": [
            -85.328,
            60.056,
            0.362,
            -84.036,
            59.57,
            0.362,
            -84.036,
            59.57,
            0.634,
            -84.036,
            59.57,
            0.634,
            -85.328,
            60.056,
            0.634,
            -85.328,
            60.056,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2093",
        "polygon": [
            -84.035,
            59.57,
            0.362,
            -82.931,
            59.155,
            0.362,
            -82.931,
            59.155,
            0.634,
            -82.931,
            59.155,
            0.634,
            -84.035,
            59.57,
            0.634,
            -84.035,
            59.57,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2094",
        "polygon": [
            -86.619,
            60.542,
            0.09,
            -85.328,
            60.056,
            0.09,
            -85.328,
            60.056,
            0.362,
            -85.328,
            60.056,
            0.362,
            -86.619,
            60.542,
            0.362,
            -86.619,
            60.542,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2095",
        "polygon": [
            -85.328,
            60.056,
            0.09,
            -84.036,
            59.57,
            0.09,
            -84.036,
            59.57,
            0.362,
            -84.036,
            59.57,
            0.362,
            -85.328,
            60.056,
            0.362,
            -85.328,
            60.056,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2096",
        "polygon": [
            -84.035,
            59.57,
            0.09,
            -82.931,
            59.155,
            0.09,
            -82.931,
            59.155,
            0.362,
            -82.931,
            59.155,
            0.362,
            -84.035,
            59.57,
            0.362,
            -84.035,
            59.57,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2097",
        "polygon": [
            -85.328,
            60.056,
            0.905,
            -84.036,
            59.57,
            0.905,
            -84.036,
            59.57,
            1.177,
            -84.036,
            59.57,
            1.177,
            -85.328,
            60.056,
            1.177,
            -85.328,
            60.056,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2098",
        "polygon": [
            -94.482,
            63.498,
            1.177,
            -93.19,
            63.012,
            1.177,
            -93.19,
            63.012,
            1.449,
            -93.19,
            63.012,
            1.449,
            -94.482,
            63.498,
            1.449,
            -94.482,
            63.498,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2099",
        "polygon": [
            -93.19,
            63.012,
            1.177,
            -91.899,
            62.526,
            1.177,
            -91.899,
            62.526,
            1.449,
            -91.899,
            62.526,
            1.449,
            -93.19,
            63.012,
            1.449,
            -93.19,
            63.012,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2100",
        "polygon": [
            -91.899,
            62.526,
            1.177,
            -90.607,
            62.041,
            1.177,
            -90.607,
            62.041,
            1.449,
            -90.607,
            62.041,
            1.449,
            -91.899,
            62.526,
            1.449,
            -91.899,
            62.526,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2101",
        "polygon": [
            -94.482,
            63.498,
            0.905,
            -93.19,
            63.012,
            0.905,
            -93.19,
            63.012,
            1.177,
            -93.19,
            63.012,
            1.177,
            -94.482,
            63.498,
            1.177,
            -94.482,
            63.498,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2102",
        "polygon": [
            -91.899,
            62.526,
            0.905,
            -90.607,
            62.041,
            0.905,
            -90.607,
            62.041,
            1.177,
            -90.607,
            62.041,
            1.177,
            -91.899,
            62.526,
            1.177,
            -91.899,
            62.526,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2103",
        "polygon": [
            -94.482,
            63.498,
            0.634,
            -93.19,
            63.012,
            0.634,
            -93.19,
            63.012,
            0.905,
            -93.19,
            63.012,
            0.905,
            -94.482,
            63.498,
            0.905,
            -94.482,
            63.498,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2104",
        "polygon": [
            -93.19,
            63.012,
            0.634,
            -91.899,
            62.526,
            0.634,
            -91.899,
            62.526,
            0.905,
            -91.899,
            62.526,
            0.905,
            -93.19,
            63.012,
            0.905,
            -93.19,
            63.012,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2105",
        "polygon": [
            -91.899,
            62.526,
            0.634,
            -90.607,
            62.041,
            0.634,
            -90.607,
            62.041,
            0.905,
            -90.607,
            62.041,
            0.905,
            -91.899,
            62.526,
            0.905,
            -91.899,
            62.526,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2106",
        "polygon": [
            -94.482,
            63.498,
            0.362,
            -93.19,
            63.012,
            0.362,
            -93.19,
            63.012,
            0.634,
            -93.19,
            63.012,
            0.634,
            -94.482,
            63.498,
            0.634,
            -94.482,
            63.498,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2107",
        "polygon": [
            -93.19,
            63.012,
            0.362,
            -91.899,
            62.526,
            0.362,
            -91.899,
            62.526,
            0.634,
            -91.899,
            62.526,
            0.634,
            -93.19,
            63.012,
            0.634,
            -93.19,
            63.012,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2108",
        "polygon": [
            -91.899,
            62.526,
            0.362,
            -90.607,
            62.041,
            0.362,
            -90.607,
            62.041,
            0.634,
            -90.607,
            62.041,
            0.634,
            -91.899,
            62.526,
            0.634,
            -91.899,
            62.526,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2109",
        "polygon": [
            -94.482,
            63.498,
            0.09,
            -93.19,
            63.012,
            0.09,
            -93.19,
            63.012,
            0.362,
            -93.19,
            63.012,
            0.362,
            -94.482,
            63.498,
            0.362,
            -94.482,
            63.498,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2110",
        "polygon": [
            -93.19,
            63.012,
            0.09,
            -91.899,
            62.526,
            0.09,
            -91.899,
            62.526,
            0.362,
            -91.899,
            62.526,
            0.362,
            -93.19,
            63.012,
            0.362,
            -93.19,
            63.012,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2111",
        "polygon": [
            -91.899,
            62.526,
            0.09,
            -90.607,
            62.041,
            0.09,
            -90.607,
            62.041,
            0.362,
            -90.607,
            62.041,
            0.362,
            -91.899,
            62.526,
            0.362,
            -91.899,
            62.526,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2112",
        "polygon": [
            -93.19,
            63.012,
            0.905,
            -91.899,
            62.526,
            0.905,
            -91.899,
            62.526,
            1.177,
            -91.899,
            62.526,
            1.177,
            -93.19,
            63.012,
            1.177,
            -93.19,
            63.012,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2113",
        "polygon": [
            -94.482,
            63.498,
            2.596,
            -93.19,
            63.012,
            2.596,
            -93.19,
            63.012,
            2.868,
            -93.19,
            63.012,
            2.868,
            -94.482,
            63.498,
            2.868,
            -94.482,
            63.498,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2114",
        "polygon": [
            -93.19,
            63.012,
            2.596,
            -91.899,
            62.526,
            2.596,
            -91.899,
            62.526,
            2.868,
            -91.899,
            62.526,
            2.868,
            -93.19,
            63.012,
            2.868,
            -93.19,
            63.012,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2115",
        "polygon": [
            -91.899,
            62.526,
            2.596,
            -90.607,
            62.041,
            2.596,
            -90.607,
            62.041,
            2.868,
            -90.607,
            62.041,
            2.868,
            -91.899,
            62.526,
            2.868,
            -91.899,
            62.526,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2116",
        "polygon": [
            -94.482,
            63.498,
            2.324,
            -93.19,
            63.012,
            2.324,
            -93.19,
            63.012,
            2.596,
            -93.19,
            63.012,
            2.596,
            -94.482,
            63.498,
            2.596,
            -94.482,
            63.498,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2117",
        "polygon": [
            -91.899,
            62.526,
            2.324,
            -90.607,
            62.041,
            2.324,
            -90.607,
            62.041,
            2.596,
            -90.607,
            62.041,
            2.596,
            -91.899,
            62.526,
            2.596,
            -91.899,
            62.526,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2118",
        "polygon": [
            -94.482,
            63.498,
            2.053,
            -93.19,
            63.012,
            2.053,
            -93.19,
            63.012,
            2.324,
            -93.19,
            63.012,
            2.324,
            -94.482,
            63.498,
            2.324,
            -94.482,
            63.498,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2119",
        "polygon": [
            -93.19,
            63.012,
            2.053,
            -91.899,
            62.526,
            2.053,
            -91.899,
            62.526,
            2.324,
            -91.899,
            62.526,
            2.324,
            -93.19,
            63.012,
            2.324,
            -93.19,
            63.012,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2120",
        "polygon": [
            -91.899,
            62.526,
            2.053,
            -90.607,
            62.041,
            2.053,
            -90.607,
            62.041,
            2.324,
            -90.607,
            62.041,
            2.324,
            -91.899,
            62.526,
            2.324,
            -91.899,
            62.526,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2121",
        "polygon": [
            -94.482,
            63.498,
            1.781,
            -93.19,
            63.012,
            1.781,
            -93.19,
            63.012,
            2.053,
            -93.19,
            63.012,
            2.053,
            -94.482,
            63.498,
            2.053,
            -94.482,
            63.498,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2122",
        "polygon": [
            -93.19,
            63.012,
            1.781,
            -91.899,
            62.526,
            1.781,
            -91.899,
            62.526,
            2.053,
            -91.899,
            62.526,
            2.053,
            -93.19,
            63.012,
            2.053,
            -93.19,
            63.012,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2123",
        "polygon": [
            -91.899,
            62.526,
            1.781,
            -90.607,
            62.041,
            1.781,
            -90.607,
            62.041,
            2.053,
            -90.607,
            62.041,
            2.053,
            -91.899,
            62.526,
            2.053,
            -91.899,
            62.526,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2124",
        "polygon": [
            -94.482,
            63.498,
            1.509,
            -93.19,
            63.012,
            1.509,
            -93.19,
            63.012,
            1.781,
            -93.19,
            63.012,
            1.781,
            -94.482,
            63.498,
            1.781,
            -94.482,
            63.498,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2125",
        "polygon": [
            -93.19,
            63.012,
            1.509,
            -91.899,
            62.526,
            1.509,
            -91.899,
            62.526,
            1.781,
            -91.899,
            62.526,
            1.781,
            -93.19,
            63.012,
            1.781,
            -93.19,
            63.012,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2126",
        "polygon": [
            -91.899,
            62.526,
            1.509,
            -90.607,
            62.041,
            1.509,
            -90.607,
            62.041,
            1.781,
            -90.607,
            62.041,
            1.781,
            -91.899,
            62.526,
            1.781,
            -91.899,
            62.526,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2127",
        "polygon": [
            -93.19,
            63.012,
            2.324,
            -91.899,
            62.526,
            2.324,
            -91.899,
            62.526,
            2.596,
            -91.899,
            62.526,
            2.596,
            -93.19,
            63.012,
            2.596,
            -93.19,
            63.012,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2128",
        "polygon": [
            -102.158,
            66.383,
            1.177,
            -101.053,
            65.968,
            1.177,
            -101.053,
            65.968,
            1.449,
            -101.053,
            65.968,
            1.449,
            -102.158,
            66.383,
            1.449,
            -102.158,
            66.383,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2129",
        "polygon": [
            -101.053,
            65.968,
            1.177,
            -99.761,
            65.483,
            1.177,
            -99.761,
            65.483,
            1.449,
            -99.761,
            65.483,
            1.449,
            -101.053,
            65.968,
            1.449,
            -101.053,
            65.968,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2130",
        "polygon": [
            -99.761,
            65.483,
            1.177,
            -98.47,
            64.997,
            1.177,
            -98.47,
            64.997,
            1.449,
            -98.47,
            64.997,
            1.449,
            -99.761,
            65.483,
            1.449,
            -99.761,
            65.483,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2131",
        "polygon": [
            -102.158,
            66.383,
            0.905,
            -101.053,
            65.968,
            0.905,
            -101.053,
            65.968,
            1.177,
            -101.053,
            65.968,
            1.177,
            -102.158,
            66.383,
            1.177,
            -102.158,
            66.383,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2132",
        "polygon": [
            -99.761,
            65.483,
            0.905,
            -98.47,
            64.997,
            0.905,
            -98.47,
            64.997,
            1.177,
            -98.47,
            64.997,
            1.177,
            -99.761,
            65.483,
            1.177,
            -99.761,
            65.483,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2133",
        "polygon": [
            -102.158,
            66.383,
            0.634,
            -101.053,
            65.968,
            0.634,
            -101.053,
            65.968,
            0.905,
            -101.053,
            65.968,
            0.905,
            -102.158,
            66.383,
            0.905,
            -102.158,
            66.383,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2134",
        "polygon": [
            -101.053,
            65.968,
            0.634,
            -99.761,
            65.483,
            0.634,
            -99.761,
            65.483,
            0.905,
            -99.761,
            65.483,
            0.905,
            -101.053,
            65.968,
            0.905,
            -101.053,
            65.968,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2135",
        "polygon": [
            -99.761,
            65.483,
            0.634,
            -98.47,
            64.997,
            0.634,
            -98.47,
            64.997,
            0.905,
            -98.47,
            64.997,
            0.905,
            -99.761,
            65.483,
            0.905,
            -99.761,
            65.483,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2136",
        "polygon": [
            -102.158,
            66.383,
            0.362,
            -101.053,
            65.968,
            0.362,
            -101.053,
            65.968,
            0.634,
            -101.053,
            65.968,
            0.634,
            -102.158,
            66.383,
            0.634,
            -102.158,
            66.383,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2137",
        "polygon": [
            -101.053,
            65.968,
            0.362,
            -99.761,
            65.483,
            0.362,
            -99.761,
            65.483,
            0.634,
            -99.761,
            65.483,
            0.634,
            -101.053,
            65.968,
            0.634,
            -101.053,
            65.968,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2138",
        "polygon": [
            -99.761,
            65.483,
            0.362,
            -98.47,
            64.997,
            0.362,
            -98.47,
            64.997,
            0.634,
            -98.47,
            64.997,
            0.634,
            -99.761,
            65.483,
            0.634,
            -99.761,
            65.483,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2139",
        "polygon": [
            -102.158,
            66.383,
            0.09,
            -101.053,
            65.968,
            0.09,
            -101.053,
            65.968,
            0.362,
            -101.053,
            65.968,
            0.362,
            -102.158,
            66.383,
            0.362,
            -102.158,
            66.383,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2140",
        "polygon": [
            -101.053,
            65.968,
            0.09,
            -99.761,
            65.483,
            0.09,
            -99.761,
            65.483,
            0.362,
            -99.761,
            65.483,
            0.362,
            -101.053,
            65.968,
            0.362,
            -101.053,
            65.968,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2141",
        "polygon": [
            -99.761,
            65.483,
            0.09,
            -98.47,
            64.997,
            0.09,
            -98.47,
            64.997,
            0.362,
            -98.47,
            64.997,
            0.362,
            -99.761,
            65.483,
            0.362,
            -99.761,
            65.483,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2142",
        "polygon": [
            -101.053,
            65.968,
            0.905,
            -99.761,
            65.483,
            0.905,
            -99.761,
            65.483,
            1.177,
            -99.761,
            65.483,
            1.177,
            -101.053,
            65.968,
            1.177,
            -101.053,
            65.968,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2143",
        "polygon": [
            -102.158,
            66.383,
            2.596,
            -101.053,
            65.968,
            2.596,
            -101.053,
            65.968,
            2.868,
            -101.053,
            65.968,
            2.868,
            -102.158,
            66.383,
            2.868,
            -102.158,
            66.383,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2144",
        "polygon": [
            -101.053,
            65.968,
            2.596,
            -99.761,
            65.483,
            2.596,
            -99.761,
            65.483,
            2.868,
            -99.761,
            65.483,
            2.868,
            -101.053,
            65.968,
            2.868,
            -101.053,
            65.968,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2145",
        "polygon": [
            -99.761,
            65.483,
            2.596,
            -98.47,
            64.997,
            2.596,
            -98.47,
            64.997,
            2.868,
            -98.47,
            64.997,
            2.868,
            -99.761,
            65.483,
            2.868,
            -99.761,
            65.483,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2146",
        "polygon": [
            -102.158,
            66.383,
            2.324,
            -101.053,
            65.968,
            2.324,
            -101.053,
            65.968,
            2.596,
            -101.053,
            65.968,
            2.596,
            -102.158,
            66.383,
            2.596,
            -102.158,
            66.383,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2147",
        "polygon": [
            -99.761,
            65.483,
            2.324,
            -98.47,
            64.997,
            2.324,
            -98.47,
            64.997,
            2.596,
            -98.47,
            64.997,
            2.596,
            -99.761,
            65.483,
            2.596,
            -99.761,
            65.483,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2148",
        "polygon": [
            -102.158,
            66.383,
            2.053,
            -101.053,
            65.968,
            2.053,
            -101.053,
            65.968,
            2.324,
            -101.053,
            65.968,
            2.324,
            -102.158,
            66.383,
            2.324,
            -102.158,
            66.383,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2149",
        "polygon": [
            -101.053,
            65.968,
            2.053,
            -99.761,
            65.483,
            2.053,
            -99.761,
            65.483,
            2.324,
            -99.761,
            65.483,
            2.324,
            -101.053,
            65.968,
            2.324,
            -101.053,
            65.968,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2150",
        "polygon": [
            -99.761,
            65.483,
            2.053,
            -98.47,
            64.997,
            2.053,
            -98.47,
            64.997,
            2.324,
            -98.47,
            64.997,
            2.324,
            -99.761,
            65.483,
            2.324,
            -99.761,
            65.483,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2151",
        "polygon": [
            -102.158,
            66.383,
            1.781,
            -101.053,
            65.968,
            1.781,
            -101.053,
            65.968,
            2.053,
            -101.053,
            65.968,
            2.053,
            -102.158,
            66.383,
            2.053,
            -102.158,
            66.383,
            1.781
        ],
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2152",
        "polygon": [
            -101.053,
            65.968,
            1.781,
            -99.761,
            65.483,
            1.781,
            -99.761,
            65.483,
            2.053,
            -99.761,
            65.483,
            2.053,
            -101.053,
            65.968,
            2.053,
            -101.053,
            65.968,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2153",
        "polygon": [
            -99.761,
            65.483,
            1.781,
            -98.47,
            64.997,
            1.781,
            -98.47,
            64.997,
            2.053,
            -98.47,
            64.997,
            2.053,
            -99.761,
            65.483,
            2.053,
            -99.761,
            65.483,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2154",
        "polygon": [
            -102.158,
            66.383,
            1.509,
            -101.053,
            65.968,
            1.509,
            -101.053,
            65.968,
            1.781,
            -101.053,
            65.968,
            1.781,
            -102.158,
            66.383,
            1.781,
            -102.158,
            66.383,
            1.509
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2155",
        "polygon": [
            -101.053,
            65.968,
            1.509,
            -99.761,
            65.483,
            1.509,
            -99.761,
            65.483,
            1.781,
            -99.761,
            65.483,
            1.781,
            -101.053,
            65.968,
            1.781,
            -101.053,
            65.968,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2156",
        "polygon": [
            -99.761,
            65.483,
            1.509,
            -98.47,
            64.997,
            1.509,
            -98.47,
            64.997,
            1.781,
            -98.47,
            64.997,
            1.781,
            -99.761,
            65.483,
            1.781,
            -99.761,
            65.483,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2157",
        "polygon": [
            -101.053,
            65.968,
            2.324,
            -99.761,
            65.483,
            2.324,
            -99.761,
            65.483,
            2.596,
            -99.761,
            65.483,
            2.596,
            -101.053,
            65.968,
            2.596,
            -101.053,
            65.968,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2158",
        "polygon": [
            -90.551,
            62.02,
            2.596,
            -89.615,
            61.668,
            2.596,
            -89.615,
            61.668,
            2.868,
            -89.615,
            61.668,
            2.868,
            -90.551,
            62.02,
            2.868,
            -90.551,
            62.02,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2159",
        "polygon": [
            -89.615,
            61.668,
            2.596,
            -88.679,
            61.316,
            2.596,
            -88.679,
            61.316,
            2.868,
            -88.679,
            61.316,
            2.868,
            -89.615,
            61.668,
            2.868,
            -89.615,
            61.668,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2160",
        "polygon": [
            -96.41,
            64.223,
            2.596,
            -95.474,
            63.871,
            2.596,
            -95.474,
            63.871,
            2.868,
            -95.474,
            63.871,
            2.868,
            -96.41,
            64.223,
            2.868,
            -96.41,
            64.223,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2161",
        "polygon": [
            -95.474,
            63.871,
            2.596,
            -94.538,
            63.519,
            2.596,
            -94.538,
            63.519,
            2.868,
            -94.538,
            63.519,
            2.868,
            -95.474,
            63.871,
            2.868,
            -95.474,
            63.871,
            2.596
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2162",
        "polygon": [
            -90.551,
            62.02,
            2.324,
            -89.615,
            61.668,
            2.324,
            -89.615,
            61.668,
            2.596,
            -89.615,
            61.668,
            2.596,
            -90.551,
            62.02,
            2.596,
            -90.551,
            62.02,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2163",
        "polygon": [
            -89.615,
            61.668,
            2.324,
            -88.679,
            61.316,
            2.324,
            -88.679,
            61.316,
            2.596,
            -88.679,
            61.316,
            2.596,
            -89.615,
            61.668,
            2.596,
            -89.615,
            61.668,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2164",
        "polygon": [
            -96.41,
            64.223,
            2.324,
            -95.474,
            63.871,
            2.324,
            -95.474,
            63.871,
            2.596,
            -95.474,
            63.871,
            2.596,
            -96.41,
            64.223,
            2.596,
            -96.41,
            64.223,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2165",
        "polygon": [
            -95.474,
            63.871,
            2.324,
            -94.538,
            63.519,
            2.324,
            -94.538,
            63.519,
            2.596,
            -94.538,
            63.519,
            2.596,
            -95.474,
            63.871,
            2.596,
            -95.474,
            63.871,
            2.324
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2166",
        "polygon": [
            -90.551,
            62.02,
            2.053,
            -89.615,
            61.668,
            2.053,
            -89.615,
            61.668,
            2.324,
            -89.615,
            61.668,
            2.324,
            -90.551,
            62.02,
            2.324,
            -90.551,
            62.02,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2167",
        "polygon": [
            -89.615,
            61.668,
            2.053,
            -88.679,
            61.316,
            2.053,
            -88.679,
            61.316,
            2.324,
            -88.679,
            61.316,
            2.324,
            -89.615,
            61.668,
            2.324,
            -89.615,
            61.668,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2168",
        "polygon": [
            -96.41,
            64.223,
            2.053,
            -95.474,
            63.871,
            2.053,
            -95.474,
            63.871,
            2.324,
            -95.474,
            63.871,
            2.324,
            -96.41,
            64.223,
            2.324,
            -96.41,
            64.223,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2169",
        "polygon": [
            -95.474,
            63.871,
            2.053,
            -94.538,
            63.519,
            2.053,
            -94.538,
            63.519,
            2.324,
            -94.538,
            63.519,
            2.324,
            -95.474,
            63.871,
            2.324,
            -95.474,
            63.871,
            2.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2170",
        "polygon": [
            -90.551,
            62.02,
            1.781,
            -89.615,
            61.668,
            1.781,
            -89.615,
            61.668,
            2.053,
            -89.615,
            61.668,
            2.053,
            -90.551,
            62.02,
            2.053,
            -90.551,
            62.02,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2171",
        "polygon": [
            -89.615,
            61.668,
            1.781,
            -88.679,
            61.316,
            1.781,
            -88.679,
            61.316,
            2.053,
            -88.679,
            61.316,
            2.053,
            -89.615,
            61.668,
            2.053,
            -89.615,
            61.668,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2172",
        "polygon": [
            -96.41,
            64.223,
            1.781,
            -95.474,
            63.871,
            1.781,
            -95.474,
            63.871,
            2.053,
            -95.474,
            63.871,
            2.053,
            -96.41,
            64.223,
            2.053,
            -96.41,
            64.223,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2173",
        "polygon": [
            -95.474,
            63.871,
            1.781,
            -94.538,
            63.519,
            1.781,
            -94.538,
            63.519,
            2.053,
            -94.538,
            63.519,
            2.053,
            -95.474,
            63.871,
            2.053,
            -95.474,
            63.871,
            1.781
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2174",
        "polygon": [
            -90.551,
            62.02,
            1.509,
            -89.615,
            61.668,
            1.509,
            -89.615,
            61.668,
            1.781,
            -89.615,
            61.668,
            1.781,
            -90.551,
            62.02,
            1.781,
            -90.551,
            62.02,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2175",
        "polygon": [
            -89.615,
            61.668,
            1.509,
            -88.679,
            61.316,
            1.509,
            -88.679,
            61.316,
            1.781,
            -88.679,
            61.316,
            1.781,
            -89.615,
            61.668,
            1.781,
            -89.615,
            61.668,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2176",
        "polygon": [
            -96.41,
            64.223,
            1.509,
            -95.474,
            63.871,
            1.509,
            -95.474,
            63.871,
            1.781,
            -95.474,
            63.871,
            1.781,
            -96.41,
            64.223,
            1.781,
            -96.41,
            64.223,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2177",
        "polygon": [
            -95.474,
            63.871,
            1.509,
            -94.538,
            63.519,
            1.509,
            -94.538,
            63.519,
            1.781,
            -94.538,
            63.519,
            1.781,
            -95.474,
            63.871,
            1.781,
            -95.474,
            63.871,
            1.509
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2178",
        "polygon": [
            -90.551,
            62.02,
            1.177,
            -89.615,
            61.668,
            1.177,
            -89.615,
            61.668,
            1.449,
            -89.615,
            61.668,
            1.449,
            -90.551,
            62.02,
            1.449,
            -90.551,
            62.02,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2179",
        "polygon": [
            -89.615,
            61.668,
            1.177,
            -88.679,
            61.316,
            1.177,
            -88.679,
            61.316,
            1.449,
            -88.679,
            61.316,
            1.449,
            -89.615,
            61.668,
            1.449,
            -89.615,
            61.668,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2180",
        "polygon": [
            -96.41,
            64.223,
            1.177,
            -95.474,
            63.871,
            1.177,
            -95.474,
            63.871,
            1.449,
            -95.474,
            63.871,
            1.449,
            -96.41,
            64.223,
            1.449,
            -96.41,
            64.223,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2181",
        "polygon": [
            -95.474,
            63.871,
            1.177,
            -94.538,
            63.519,
            1.177,
            -94.538,
            63.519,
            1.449,
            -94.538,
            63.519,
            1.449,
            -95.474,
            63.871,
            1.449,
            -95.474,
            63.871,
            1.177
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2182",
        "polygon": [
            -90.551,
            62.02,
            0.905,
            -89.615,
            61.668,
            0.905,
            -89.615,
            61.668,
            1.177,
            -89.615,
            61.668,
            1.177,
            -90.551,
            62.02,
            1.177,
            -90.551,
            62.02,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2183",
        "polygon": [
            -89.615,
            61.668,
            0.905,
            -88.679,
            61.316,
            0.905,
            -88.679,
            61.316,
            1.177,
            -88.679,
            61.316,
            1.177,
            -89.615,
            61.668,
            1.177,
            -89.615,
            61.668,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2184",
        "polygon": [
            -96.41,
            64.223,
            0.905,
            -95.474,
            63.871,
            0.905,
            -95.474,
            63.871,
            1.177,
            -95.474,
            63.871,
            1.177,
            -96.41,
            64.223,
            1.177,
            -96.41,
            64.223,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2185",
        "polygon": [
            -95.474,
            63.871,
            0.905,
            -94.538,
            63.519,
            0.905,
            -94.538,
            63.519,
            1.177,
            -94.538,
            63.519,
            1.177,
            -95.474,
            63.871,
            1.177,
            -95.474,
            63.871,
            0.905
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2186",
        "polygon": [
            -90.551,
            62.02,
            0.634,
            -89.615,
            61.668,
            0.634,
            -89.615,
            61.668,
            0.905,
            -89.615,
            61.668,
            0.905,
            -90.551,
            62.02,
            0.905,
            -90.551,
            62.02,
            0.634
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2187",
        "polygon": [
            -89.615,
            61.668,
            0.634,
            -88.679,
            61.316,
            0.634,
            -88.679,
            61.316,
            0.905,
            -88.679,
            61.316,
            0.905,
            -89.615,
            61.668,
            0.905,
            -89.615,
            61.668,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2188",
        "polygon": [
            -96.41,
            64.223,
            0.634,
            -95.474,
            63.871,
            0.634,
            -95.474,
            63.871,
            0.905,
            -95.474,
            63.871,
            0.905,
            -96.41,
            64.223,
            0.905,
            -96.41,
            64.223,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2189",
        "polygon": [
            -95.474,
            63.871,
            0.634,
            -94.538,
            63.519,
            0.634,
            -94.538,
            63.519,
            0.905,
            -94.538,
            63.519,
            0.905,
            -95.474,
            63.871,
            0.905,
            -95.474,
            63.871,
            0.634
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2190",
        "polygon": [
            -90.551,
            62.02,
            0.362,
            -89.615,
            61.668,
            0.362,
            -89.615,
            61.668,
            0.634,
            -89.615,
            61.668,
            0.634,
            -90.551,
            62.02,
            0.634,
            -90.551,
            62.02,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2191",
        "polygon": [
            -89.615,
            61.668,
            0.362,
            -88.679,
            61.316,
            0.362,
            -88.679,
            61.316,
            0.634,
            -88.679,
            61.316,
            0.634,
            -89.615,
            61.668,
            0.634,
            -89.615,
            61.668,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2192",
        "polygon": [
            -96.41,
            64.223,
            0.362,
            -95.474,
            63.871,
            0.362,
            -95.474,
            63.871,
            0.634,
            -95.474,
            63.871,
            0.634,
            -96.41,
            64.223,
            0.634,
            -96.41,
            64.223,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2193",
        "polygon": [
            -95.474,
            63.871,
            0.362,
            -94.538,
            63.519,
            0.362,
            -94.538,
            63.519,
            0.634,
            -94.538,
            63.519,
            0.634,
            -95.474,
            63.871,
            0.634,
            -95.474,
            63.871,
            0.362
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2194",
        "polygon": [
            -90.551,
            62.02,
            0.09,
            -89.615,
            61.668,
            0.09,
            -89.615,
            61.668,
            0.362,
            -89.615,
            61.668,
            0.362,
            -90.551,
            62.02,
            0.362,
            -90.551,
            62.02,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2195",
        "polygon": [
            -89.615,
            61.668,
            0.09,
            -88.679,
            61.316,
            0.09,
            -88.679,
            61.316,
            0.362,
            -88.679,
            61.316,
            0.362,
            -89.615,
            61.668,
            0.362,
            -89.615,
            61.668,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2196",
        "polygon": [
            -96.41,
            64.223,
            0.09,
            -95.474,
            63.871,
            0.09,
            -95.474,
            63.871,
            0.362,
            -95.474,
            63.871,
            0.362,
            -96.41,
            64.223,
            0.362,
            -96.41,
            64.223,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2197",
        "polygon": [
            -95.474,
            63.871,
            0.09,
            -94.538,
            63.519,
            0.09,
            -94.538,
            63.519,
            0.362,
            -94.538,
            63.519,
            0.362,
            -95.474,
            63.871,
            0.362,
            -95.474,
            63.871,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2198",
        "polygon": [
            -98.413,
            64.976,
            0.09,
            -97.44,
            64.61,
            0.09,
            -97.44,
            64.61,
            2.868,
            -97.44,
            64.61,
            2.868,
            -98.413,
            64.976,
            2.868,
            -98.413,
            64.976,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2199",
        "polygon": [
            -97.44,
            64.61,
            0.09,
            -96.467,
            64.244,
            0.09,
            -96.467,
            64.244,
            2.868,
            -96.467,
            64.244,
            2.868,
            -97.44,
            64.61,
            2.868,
            -97.44,
            64.61,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2200",
        "polygon": [
            -88.623,
            61.295,
            0.09,
            -87.649,
            60.929,
            0.09,
            -87.649,
            60.929,
            2.868,
            -87.649,
            60.929,
            2.868,
            -88.623,
            61.295,
            2.868,
            -88.623,
            61.295,
            0.09
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2201",
        "polygon": [
            -87.649,
            60.929,
            0.09,
            -86.676,
            60.563,
            0.09,
            -86.676,
            60.563,
            2.868,
            -86.676,
            60.563,
            2.868,
            -87.649,
            60.929,
            2.868,
            -87.649,
            60.929,
            0.09
        ]
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_1",
        "polygon": [
            -78.57,
            26.142,
            0.142,
            -79.09,
            24.758,
            0.142,
            -79.09,
            24.758,
            3.698,
            -79.09,
            24.758,
            3.698,
            -78.57,
            26.142,
            3.698,
            -78.57,
            26.142,
            0.142
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_5",
        "polygon": [
            -76.6,
            31.383,
            0.142,
            -77.056,
            30.17,
            0.142,
            -77.056,
            30.17,
            3.698,
            -77.056,
            30.17,
            3.698,
            -76.6,
            31.383,
            3.698,
            -76.6,
            31.383,
            0.142
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_7",
        "polygon": [
            -75.614,
            34.004,
            0.142,
            -76.07,
            32.791,
            0.142,
            -76.07,
            32.791,
            3.698,
            -76.07,
            32.791,
            3.698,
            -75.614,
            34.004,
            3.698,
            -75.614,
            34.004,
            0.142
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_9",
        "polygon": [
            -74.629,
            36.625,
            0.142,
            -75.085,
            35.412,
            0.142,
            -75.085,
            35.412,
            3.698,
            -75.085,
            35.412,
            3.698,
            -74.629,
            36.625,
            3.698,
            -74.629,
            36.625,
            0.142
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_13",
        "polygon": [
            -72.658,
            41.867,
            0.142,
            -73.114,
            40.654,
            0.142,
            -73.114,
            40.654,
            3.698,
            -73.114,
            40.654,
            3.698,
            -72.658,
            41.867,
            3.698,
            -72.658,
            41.867,
            0.142
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_15",
        "polygon": [
            -71.673,
            44.488,
            0.142,
            -72.129,
            43.275,
            0.142,
            -72.129,
            43.275,
            3.698,
            -72.129,
            43.275,
            3.698,
            -71.673,
            44.488,
            3.698,
            -71.673,
            44.488,
            0.142
        ],
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_17",
        "polygon": [
            -70.723,
            47.015,
            0.142,
            -71.144,
            45.896,
            0.142,
            -71.144,
            45.896,
            3.698,
            -71.144,
            45.896,
            3.698,
            -70.723,
            47.015,
            3.698,
            -70.723,
            47.015,
            0.142
        ],
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_1",
        "polygon": [
            -78.57,
            26.142,
            4.202,
            -79.09,
            24.758,
            4.202,
            -79.09,
            24.758,
            7.408,
            -79.09,
            24.758,
            7.408,
            -78.57,
            26.142,
            7.408,
            -78.57,
            26.142,
            4.202
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_3",
        "polygon": [
            -77.585,
            28.763,
            4.202,
            -78.041,
            27.549,
            4.202,
            -78.041,
            27.549,
            7.408,
            -78.041,
            27.549,
            7.408,
            -77.585,
            28.763,
            7.408,
            -77.585,
            28.763,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_17",
        "polygon": [
            -70.723,
            47.015,
            4.202,
            -71.144,
            45.896,
            4.202,
            -71.144,
            45.896,
            7.408,
            -71.144,
            45.896,
            7.408,
            -70.723,
            47.015,
            7.408,
            -70.723,
            47.015,
            4.202
        ],
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_5",
        "polygon": [
            -76.6,
            31.383,
            4.202,
            -77.056,
            30.17,
            4.202,
            -77.056,
            30.17,
            7.408,
            -77.056,
            30.17,
            7.408,
            -76.6,
            31.383,
            7.408,
            -76.6,
            31.383,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_7",
        "polygon": [
            -75.614,
            34.004,
            4.202,
            -76.07,
            32.791,
            4.202,
            -76.07,
            32.791,
            7.408,
            -76.07,
            32.791,
            7.408,
            -75.614,
            34.004,
            7.408,
            -75.614,
            34.004,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_9",
        "polygon": [
            -74.629,
            36.625,
            4.202,
            -75.085,
            35.412,
            4.202,
            -75.085,
            35.412,
            7.408,
            -75.085,
            35.412,
            7.408,
            -74.629,
            36.625,
            7.408,
            -74.629,
            36.625,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_11",
        "polygon": [
            -73.644,
            39.246,
            4.202,
            -74.1,
            38.033,
            4.202,
            -74.1,
            38.033,
            7.408,
            -74.1,
            38.033,
            7.408,
            -73.644,
            39.246,
            7.408,
            -73.644,
            39.246,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_13",
        "polygon": [
            -72.658,
            41.867,
            4.202,
            -73.114,
            40.654,
            4.202,
            -73.114,
            40.654,
            7.408,
            -73.114,
            40.654,
            7.408,
            -72.658,
            41.867,
            7.408,
            -72.658,
            41.867,
            4.202
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_15",
        "polygon": [
            -71.673,
            44.488,
            4.202,
            -72.129,
            43.275,
            4.202,
            -72.129,
            43.275,
            7.408,
            -72.129,
            43.275,
            7.408,
            -71.673,
            44.488,
            7.408,
            -71.673,
            44.488,
            4.202
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_1",
        "polygon": [
            -78.57,
            26.142,
            11.622,
            -79.09,
            24.758,
            11.622,
            -79.09,
            24.758,
            14.828,
            -79.09,
            24.758,
            14.828,
            -78.57,
            26.142,
            14.828,
            -78.57,
            26.142,
            11.622
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_3",
        "polygon": [
            -77.585,
            28.763,
            11.622,
            -78.041,
            27.549,
            11.622,
            -78.041,
            27.549,
            14.828,
            -78.041,
            27.549,
            14.828,
            -77.585,
            28.763,
            14.828,
            -77.585,
            28.763,
            11.622
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_17",
        "polygon": [
            -70.723,
            47.015,
            11.622,
            -71.144,
            45.896,
            11.622,
            -71.144,
            45.896,
            14.828,
            -71.144,
            45.896,
            14.828,
            -70.723,
            47.015,
            14.828,
            -70.723,
            47.015,
            11.622
        ],
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_5",
        "polygon": [
            -76.6,
            31.383,
            11.622,
            -77.056,
            30.17,
            11.622,
            -77.056,
            30.17,
            14.828,
            -77.056,
            30.17,
            14.828,
            -76.6,
            31.383,
            14.828,
            -76.6,
            31.383,
            11.622
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_7",
        "polygon": [
            -75.614,
            34.004,
            11.622,
            -76.07,
            32.791,
            11.622,
            -76.07,
            32.791,
            14.828,
            -76.07,
            32.791,
            14.828,
            -75.614,
            34.004,
            14.828,
            -75.614,
            34.004,
            11.622
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_9",
        "polygon": [
            -74.629,
            36.625,
            11.622,
            -75.085,
            35.412,
            11.622,
            -75.085,
            35.412,
            14.828,
            -75.085,
            35.412,
            14.828,
            -74.629,
            36.625,
            14.828,
            -74.629,
            36.625,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_11",
        "polygon": [
            -73.644,
            39.246,
            11.622,
            -74.1,
            38.033,
            11.622,
            -74.1,
            38.033,
            14.828,
            -74.1,
            38.033,
            14.828,
            -73.644,
            39.246,
            14.828,
            -73.644,
            39.246,
            11.622
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_13",
        "polygon": [
            -72.658,
            41.867,
            11.622,
            -73.114,
            40.654,
            11.622,
            -73.114,
            40.654,
            14.828,
            -73.114,
            40.654,
            14.828,
            -72.658,
            41.867,
            14.828,
            -72.658,
            41.867,
            11.622
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_15",
        "polygon": [
            -71.673,
            44.488,
            11.622,
            -72.129,
            43.275,
            11.622,
            -72.129,
            43.275,
            14.828,
            -72.129,
            43.275,
            14.828,
            -71.673,
            44.488,
            14.828,
            -71.673,
            44.488,
            11.622
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_1",
        "polygon": [
            -78.57,
            26.142,
            7.912,
            -79.09,
            24.758,
            7.912,
            -79.09,
            24.758,
            11.118,
            -79.09,
            24.758,
            11.118,
            -78.57,
            26.142,
            11.118,
            -78.57,
            26.142,
            7.912
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_3",
        "polygon": [
            -77.585,
            28.763,
            7.912,
            -78.041,
            27.549,
            7.912,
            -78.041,
            27.549,
            11.118,
            -78.041,
            27.549,
            11.118,
            -77.585,
            28.763,
            11.118,
            -77.585,
            28.763,
            7.912
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_17",
        "polygon": [
            -70.723,
            47.015,
            7.912,
            -71.144,
            45.896,
            7.912,
            -71.144,
            45.896,
            11.118,
            -71.144,
            45.896,
            11.118,
            -70.723,
            47.015,
            11.118,
            -70.723,
            47.015,
            7.912
        ],
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_5",
        "polygon": [
            -76.6,
            31.383,
            7.912,
            -77.056,
            30.17,
            7.912,
            -77.056,
            30.17,
            11.118,
            -77.056,
            30.17,
            11.118,
            -76.6,
            31.383,
            11.118,
            -76.6,
            31.383,
            7.912
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_7",
        "polygon": [
            -75.614,
            34.004,
            7.912,
            -76.07,
            32.791,
            7.912,
            -76.07,
            32.791,
            11.118,
            -76.07,
            32.791,
            11.118,
            -75.614,
            34.004,
            11.118,
            -75.614,
            34.004,
            7.912
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_9",
        "polygon": [
            -74.629,
            36.625,
            7.912,
            -75.085,
            35.412,
            7.912,
            -75.085,
            35.412,
            11.118,
            -75.085,
            35.412,
            11.118,
            -74.629,
            36.625,
            11.118,
            -74.629,
            36.625,
            7.912
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_11",
        "polygon": [
            -73.644,
            39.246,
            7.912,
            -74.1,
            38.033,
            7.912,
            -74.1,
            38.033,
            11.118,
            -74.1,
            38.033,
            11.118,
            -73.644,
            39.246,
            11.118,
            -73.644,
            39.246,
            7.912
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_13",
        "polygon": [
            -72.658,
            41.867,
            7.912,
            -73.114,
            40.654,
            7.912,
            -73.114,
            40.654,
            11.118,
            -73.114,
            40.654,
            11.118,
            -72.658,
            41.867,
            11.118,
            -72.658,
            41.867,
            7.912
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_15",
        "polygon": [
            -71.673,
            44.488,
            7.912,
            -72.129,
            43.275,
            7.912,
            -72.129,
            43.275,
            11.118,
            -72.129,
            43.275,
            11.118,
            -71.673,
            44.488,
            11.118,
            -71.673,
            44.488,
            7.912
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2336",
        "polygon": [
            -62.833,
            44.302,
            13.364,
            -66.708,
            45.759,
            13.364,
            -66.708,
            45.759,
            15.159,
            -66.708,
            45.759,
            15.159,
            -62.833,
            44.302,
            15.159,
            -62.833,
            44.302,
            13.364
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2337",
        "polygon": [
            -62.833,
            44.302,
            11.509,
            -66.708,
            45.759,
            11.509,
            -66.708,
            45.759,
            13.304,
            -66.708,
            45.759,
            13.304,
            -62.833,
            44.302,
            13.304,
            -62.833,
            44.302,
            11.509
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2338",
        "polygon": [
            -62.833,
            44.302,
            9.653,
            -66.708,
            45.759,
            9.653,
            -66.708,
            45.759,
            11.448,
            -66.708,
            45.759,
            11.448,
            -62.833,
            44.302,
            11.448,
            -62.833,
            44.302,
            9.653
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2340",
        "polygon": [
            -62.833,
            44.302,
            5.944,
            -66.708,
            45.759,
            5.944,
            -66.708,
            45.759,
            7.739,
            -66.708,
            45.759,
            7.739,
            -62.833,
            44.302,
            7.739,
            -62.833,
            44.302,
            5.944
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2342",
        "polygon": [
            -62.833,
            44.302,
            7.799,
            -66.708,
            45.759,
            7.799,
            -66.708,
            45.759,
            9.594,
            -66.708,
            45.759,
            9.594,
            -62.833,
            44.302,
            9.594,
            -62.833,
            44.302,
            7.799
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2343",
        "polygon": [
            -62.833,
            44.302,
            4.089,
            -66.708,
            45.759,
            4.089,
            -66.708,
            45.759,
            5.884,
            -66.708,
            45.759,
            5.884,
            -62.833,
            44.302,
            5.884,
            -62.833,
            44.302,
            4.089
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2344",
        "polygon": [
            -58.901,
            42.824,
            13.364,
            -62.777,
            44.281,
            13.364,
            -62.777,
            44.281,
            15.159,
            -62.777,
            44.281,
            15.159,
            -58.901,
            42.824,
            15.159,
            -58.901,
            42.824,
            13.364
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2346",
        "polygon": [
            -58.901,
            42.824,
            9.653,
            -62.777,
            44.281,
            9.653,
            -62.777,
            44.281,
            11.448,
            -62.777,
            44.281,
            11.448,
            -58.901,
            42.824,
            11.448,
            -58.901,
            42.824,
            9.653
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2347",
        "polygon": [
            -58.901,
            42.824,
            5.944,
            -62.777,
            44.281,
            5.944,
            -62.777,
            44.281,
            7.739,
            -62.777,
            44.281,
            7.739,
            -58.901,
            42.824,
            7.739,
            -58.901,
            42.824,
            5.944
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2350",
        "polygon": [
            -54.97,
            41.346,
            13.364,
            -58.845,
            42.803,
            13.364,
            -58.845,
            42.803,
            15.159,
            -58.845,
            42.803,
            15.159,
            -54.97,
            41.346,
            15.159,
            -54.97,
            41.346,
            13.364
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2351",
        "polygon": [
            -54.97,
            41.346,
            11.509,
            -58.845,
            42.803,
            11.509,
            -58.845,
            42.803,
            13.304,
            -58.845,
            42.803,
            13.304,
            -54.97,
            41.346,
            13.304,
            -54.97,
            41.346,
            11.509
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2352",
        "polygon": [
            -54.97,
            41.346,
            9.653,
            -58.845,
            42.803,
            9.653,
            -58.845,
            42.803,
            11.448,
            -58.845,
            42.803,
            11.448,
            -54.97,
            41.346,
            11.448,
            -54.97,
            41.346,
            9.653
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2353",
        "polygon": [
            -54.97,
            41.346,
            5.944,
            -58.845,
            42.803,
            5.944,
            -58.845,
            42.803,
            7.739,
            -58.845,
            42.803,
            7.739,
            -54.97,
            41.346,
            7.739,
            -54.97,
            41.346,
            5.944
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2354",
        "polygon": [
            -54.97,
            41.346,
            7.799,
            -58.845,
            42.803,
            7.799,
            -58.845,
            42.803,
            9.594,
            -58.845,
            42.803,
            9.594,
            -54.97,
            41.346,
            9.594,
            -54.97,
            41.346,
            7.799
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2356",
        "polygon": [
            -58.901,
            42.824,
            7.799,
            -62.777,
            44.281,
            7.799,
            -62.777,
            44.281,
            9.594,
            -62.777,
            44.281,
            9.594,
            -58.901,
            42.824,
            9.594,
            -58.901,
            42.824,
            7.799
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2357",
        "polygon": [
            -58.901,
            42.824,
            11.509,
            -62.777,
            44.281,
            11.509,
            -62.777,
            44.281,
            13.304,
            -62.777,
            44.281,
            13.304,
            -58.901,
            42.824,
            13.304,
            -58.901,
            42.824,
            11.509
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2358",
        "polygon": [
            -58.901,
            42.824,
            4.089,
            -62.777,
            44.281,
            4.089,
            -62.777,
            44.281,
            5.884,
            -62.777,
            44.281,
            5.884,
            -58.901,
            42.824,
            5.884,
            -58.901,
            42.824,
            4.089
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2359",
        "polygon": [
            -54.97,
            41.346,
            4.089,
            -58.845,
            42.803,
            4.089,
            -58.845,
            42.803,
            5.884,
            -58.845,
            42.803,
            5.884,
            -54.97,
            41.346,
            5.884,
            -54.97,
            41.346,
            4.089
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2360",
        "polygon": [
            -51.226,
            39.938,
            11.509,
            -54.914,
            41.325,
            11.509,
            -54.914,
            41.325,
            13.304,
            -54.914,
            41.325,
            13.304,
            -51.226,
            39.938,
            13.304,
            -51.226,
            39.938,
            11.509
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2361",
        "polygon": [
            -51.226,
            39.938,
            13.364,
            -54.914,
            41.325,
            13.364,
            -54.914,
            41.325,
            15.159,
            -54.914,
            41.325,
            15.159,
            -51.226,
            39.938,
            15.159,
            -51.226,
            39.938,
            13.364
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2362",
        "polygon": [
            -51.226,
            39.938,
            7.799,
            -54.914,
            41.325,
            7.799,
            -54.914,
            41.325,
            9.594,
            -54.914,
            41.325,
            9.594,
            -51.226,
            39.938,
            9.594,
            -51.226,
            39.938,
            7.799
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2363",
        "polygon": [
            -51.226,
            39.938,
            9.654,
            -54.914,
            41.325,
            9.654,
            -54.914,
            41.325,
            11.449,
            -54.914,
            41.325,
            11.449,
            -51.226,
            39.938,
            11.449,
            -51.226,
            39.938,
            9.654
        ],
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2365",
        "polygon": [
            -51.226,
            39.938,
            5.944,
            -54.914,
            41.325,
            5.944,
            -54.914,
            41.325,
            7.739,
            -54.914,
            41.325,
            7.739,
            -51.226,
            39.938,
            7.739,
            -51.226,
            39.938,
            5.944
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2367",
        "polygon": [
            -66.764,
            45.78,
            13.364,
            -70.452,
            47.167,
            13.364,
            -70.452,
            47.167,
            15.159,
            -70.452,
            47.167,
            15.159,
            -66.764,
            45.78,
            15.159,
            -66.764,
            45.78,
            13.364
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2369",
        "polygon": [
            -66.764,
            45.78,
            9.654,
            -70.452,
            47.167,
            9.654,
            -70.452,
            47.167,
            11.449,
            -70.452,
            47.167,
            11.449,
            -66.764,
            45.78,
            11.449,
            -66.764,
            45.78,
            9.654
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2371",
        "polygon": [
            -66.764,
            45.78,
            5.944,
            -70.452,
            47.167,
            5.944,
            -70.452,
            47.167,
            7.739,
            -70.452,
            47.167,
            7.739,
            -66.764,
            45.78,
            7.739,
            -66.764,
            45.78,
            5.944
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2372",
        "polygon": [
            -66.764,
            45.78,
            11.509,
            -70.452,
            47.167,
            11.509,
            -70.452,
            47.167,
            13.304,
            -70.452,
            47.167,
            13.304,
            -66.764,
            45.78,
            13.304,
            -66.764,
            45.78,
            11.509
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2373",
        "polygon": [
            -66.764,
            45.78,
            7.799,
            -70.452,
            47.167,
            7.799,
            -70.452,
            47.167,
            9.594,
            -70.452,
            47.167,
            9.594,
            -66.764,
            45.78,
            9.594,
            -66.764,
            45.78,
            7.799
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2374",
        "polygon": [
            -66.764,
            45.78,
            4.089,
            -70.452,
            47.167,
            4.089,
            -70.452,
            47.167,
            5.884,
            -70.452,
            47.167,
            5.884,
            -66.764,
            45.78,
            5.884,
            -66.764,
            45.78,
            4.089
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2375",
        "polygon": [
            -66.764,
            45.78,
            15.217,
            -70.405,
            47.149,
            15.217,
            -70.405,
            47.149,
            17.017,
            -70.405,
            47.149,
            17.017,
            -66.764,
            45.78,
            17.017,
            -66.764,
            45.78,
            15.217
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2376",
        "polygon": [
            -62.833,
            44.302,
            15.217,
            -66.708,
            45.759,
            15.217,
            -66.708,
            45.759,
            17.167,
            -66.708,
            45.759,
            17.167,
            -62.833,
            44.302,
            17.167,
            -62.833,
            44.302,
            15.217
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2377",
        "polygon": [
            -58.901,
            42.824,
            15.217,
            -62.777,
            44.281,
            15.217,
            -62.777,
            44.281,
            17.345,
            -62.777,
            44.281,
            17.345,
            -58.901,
            42.824,
            17.345,
            -58.901,
            42.824,
            15.217
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2378",
        "polygon": [
            -54.97,
            41.346,
            15.217,
            -58.845,
            42.803,
            15.217,
            -58.845,
            42.803,
            17.167,
            -58.845,
            42.803,
            17.167,
            -54.97,
            41.346,
            17.167,
            -54.97,
            41.346,
            15.217
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2379",
        "polygon": [
            -51.273,
            39.956,
            15.217,
            -54.914,
            41.325,
            15.217,
            -54.914,
            41.325,
            17.017,
            -54.914,
            41.325,
            17.017,
            -51.273,
            39.956,
            17.017,
            -51.273,
            39.956,
            15.217
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2380",
        "polygon": [
            -62.833,
            44.302,
            2.928,
            -66.708,
            45.759,
            2.928,
            -66.708,
            45.759,
            4.029,
            -66.708,
            45.759,
            4.029,
            -62.833,
            44.302,
            4.029,
            -62.833,
            44.302,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2381",
        "polygon": [
            -58.901,
            42.824,
            2.928,
            -62.776,
            44.281,
            2.928,
            -62.776,
            44.281,
            4.029,
            -62.776,
            44.281,
            4.029,
            -58.901,
            42.824,
            4.029,
            -58.901,
            42.824,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2384",
        "polygon": [
            -54.97,
            41.346,
            2.928,
            -58.845,
            42.803,
            2.928,
            -58.845,
            42.803,
            4.029,
            -58.845,
            42.803,
            4.029,
            -54.97,
            41.346,
            4.029,
            -54.97,
            41.346,
            2.928
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2385",
        "polygon": [
            -66.764,
            45.78,
            2.928,
            -70.452,
            47.167,
            2.928,
            -70.452,
            47.167,
            4.029,
            -70.452,
            47.167,
            4.029,
            -66.764,
            45.78,
            4.029,
            -66.764,
            45.78,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2387",
        "polygon": [
            -51.226,
            39.938,
            2.928,
            -54.914,
            41.325,
            2.928,
            -54.914,
            41.325,
            4.029,
            -54.914,
            41.325,
            4.029,
            -51.226,
            39.938,
            4.029,
            -51.226,
            39.938,
            2.928
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2388",
        "polygon": [
            -51.226,
            39.938,
            4.089,
            -54.914,
            41.325,
            4.089,
            -54.914,
            41.325,
            5.884,
            -54.914,
            41.325,
            5.884,
            -51.226,
            39.938,
            5.884,
            -51.226,
            39.938,
            4.089
        ],
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2389",
        "polygon": [
            -66.764,
            45.78,
            2.528,
            -68.056,
            46.266,
            2.528,
            -68.056,
            46.266,
            2.868,
            -68.056,
            46.266,
            2.868,
            -66.764,
            45.78,
            2.868,
            -66.764,
            45.78,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2390",
        "polygon": [
            -68.056,
            46.266,
            2.528,
            -69.348,
            46.751,
            2.528,
            -69.348,
            46.751,
            2.868,
            -69.348,
            46.751,
            2.868,
            -68.056,
            46.266,
            2.868,
            -68.056,
            46.266,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2391",
        "polygon": [
            -69.348,
            46.751,
            2.528,
            -70.639,
            47.237,
            2.528,
            -70.639,
            47.237,
            2.868,
            -70.639,
            47.237,
            2.868,
            -69.348,
            46.751,
            2.868,
            -69.348,
            46.751,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2392",
        "polygon": [
            -66.764,
            45.78,
            2.189,
            -68.056,
            46.266,
            2.189,
            -68.056,
            46.266,
            2.528,
            -68.056,
            46.266,
            2.528,
            -66.764,
            45.78,
            2.528,
            -66.764,
            45.78,
            2.189
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2393",
        "polygon": [
            -68.056,
            46.266,
            2.189,
            -69.348,
            46.751,
            2.189,
            -69.348,
            46.751,
            2.528,
            -69.348,
            46.751,
            2.528,
            -68.056,
            46.266,
            2.528,
            -68.056,
            46.266,
            2.189
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2394",
        "polygon": [
            -69.348,
            46.751,
            2.189,
            -70.639,
            47.237,
            2.189,
            -70.639,
            47.237,
            2.528,
            -70.639,
            47.237,
            2.528,
            -69.348,
            46.751,
            2.528,
            -69.348,
            46.751,
            2.189
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2395",
        "polygon": [
            -66.764,
            45.78,
            1.849,
            -68.056,
            46.266,
            1.849,
            -68.056,
            46.266,
            2.189,
            -68.056,
            46.266,
            2.189,
            -66.764,
            45.78,
            2.189,
            -66.764,
            45.78,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2396",
        "polygon": [
            -68.056,
            46.266,
            1.849,
            -69.348,
            46.751,
            1.849,
            -69.348,
            46.751,
            2.189,
            -69.348,
            46.751,
            2.189,
            -68.056,
            46.266,
            2.189,
            -68.056,
            46.266,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2397",
        "polygon": [
            -69.348,
            46.751,
            1.849,
            -70.639,
            47.237,
            1.849,
            -70.639,
            47.237,
            2.189,
            -70.639,
            47.237,
            2.189,
            -69.348,
            46.751,
            2.189,
            -69.348,
            46.751,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2398",
        "polygon": [
            -66.764,
            45.78,
            1.509,
            -68.056,
            46.266,
            1.509,
            -68.056,
            46.266,
            1.849,
            -68.056,
            46.266,
            1.849,
            -66.764,
            45.78,
            1.849,
            -66.764,
            45.78,
            1.509
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2399",
        "polygon": [
            -68.056,
            46.266,
            1.509,
            -69.348,
            46.751,
            1.509,
            -69.348,
            46.751,
            1.849,
            -69.348,
            46.751,
            1.849,
            -68.056,
            46.266,
            1.849,
            -68.056,
            46.266,
            1.509
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2400",
        "polygon": [
            -69.348,
            46.751,
            1.509,
            -70.639,
            47.237,
            1.509,
            -70.639,
            47.237,
            1.849,
            -70.639,
            47.237,
            1.849,
            -69.348,
            46.751,
            1.849,
            -69.348,
            46.751,
            1.509
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2401",
        "polygon": [
            -66.764,
            45.78,
            1.109,
            -68.056,
            46.266,
            1.109,
            -68.056,
            46.266,
            1.449,
            -68.056,
            46.266,
            1.449,
            -66.764,
            45.78,
            1.449,
            -66.764,
            45.78,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2402",
        "polygon": [
            -68.056,
            46.266,
            1.109,
            -69.348,
            46.751,
            1.109,
            -69.348,
            46.751,
            1.449,
            -69.348,
            46.751,
            1.449,
            -68.056,
            46.266,
            1.449,
            -68.056,
            46.266,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2403",
        "polygon": [
            -69.348,
            46.751,
            1.109,
            -70.639,
            47.237,
            1.109,
            -70.639,
            47.237,
            1.449,
            -70.639,
            47.237,
            1.449,
            -69.348,
            46.751,
            1.449,
            -69.348,
            46.751,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2404",
        "polygon": [
            -66.764,
            45.78,
            0.77,
            -68.056,
            46.266,
            0.77,
            -68.056,
            46.266,
            1.109,
            -68.056,
            46.266,
            1.109,
            -66.764,
            45.78,
            1.109,
            -66.764,
            45.78,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2405",
        "polygon": [
            -68.056,
            46.266,
            0.77,
            -69.348,
            46.751,
            0.77,
            -69.348,
            46.751,
            1.109,
            -69.348,
            46.751,
            1.109,
            -68.056,
            46.266,
            1.109,
            -68.056,
            46.266,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2406",
        "polygon": [
            -69.348,
            46.751,
            0.77,
            -70.639,
            47.237,
            0.77,
            -70.639,
            47.237,
            1.109,
            -70.639,
            47.237,
            1.109,
            -69.348,
            46.751,
            1.109,
            -69.348,
            46.751,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2407",
        "polygon": [
            -66.764,
            45.78,
            0.43,
            -68.056,
            46.266,
            0.43,
            -68.056,
            46.266,
            0.77,
            -68.056,
            46.266,
            0.77,
            -66.764,
            45.78,
            0.77,
            -66.764,
            45.78,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2408",
        "polygon": [
            -68.056,
            46.266,
            0.43,
            -69.348,
            46.751,
            0.43,
            -69.348,
            46.751,
            0.77,
            -69.348,
            46.751,
            0.77,
            -68.056,
            46.266,
            0.77,
            -68.056,
            46.266,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2409",
        "polygon": [
            -69.348,
            46.751,
            0.43,
            -70.639,
            47.237,
            0.43,
            -70.639,
            47.237,
            0.77,
            -70.639,
            47.237,
            0.77,
            -69.348,
            46.751,
            0.77,
            -69.348,
            46.751,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2410",
        "polygon": [
            -66.764,
            45.78,
            0.09,
            -68.056,
            46.266,
            0.09,
            -68.056,
            46.266,
            0.43,
            -68.056,
            46.266,
            0.43,
            -66.764,
            45.78,
            0.43,
            -66.764,
            45.78,
            0.09
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2411",
        "polygon": [
            -68.056,
            46.266,
            0.09,
            -69.348,
            46.751,
            0.09,
            -69.348,
            46.751,
            0.43,
            -69.348,
            46.751,
            0.43,
            -68.056,
            46.266,
            0.43,
            -68.056,
            46.266,
            0.09
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2412",
        "polygon": [
            -69.348,
            46.751,
            0.09,
            -70.639,
            47.237,
            0.09,
            -70.639,
            47.237,
            0.43,
            -70.639,
            47.237,
            0.43,
            -69.348,
            46.751,
            0.43,
            -69.348,
            46.751,
            0.09
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2413",
        "polygon": [
            -58.901,
            42.824,
            2.528,
            -60.193,
            43.31,
            2.528,
            -60.193,
            43.31,
            2.868,
            -60.193,
            43.31,
            2.868,
            -58.901,
            42.824,
            2.868,
            -58.901,
            42.824,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2414",
        "polygon": [
            -60.193,
            43.31,
            2.528,
            -61.485,
            43.795,
            2.528,
            -61.485,
            43.795,
            2.868,
            -61.485,
            43.795,
            2.868,
            -60.193,
            43.31,
            2.868,
            -60.193,
            43.31,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2415",
        "polygon": [
            -61.485,
            43.795,
            2.528,
            -62.777,
            44.281,
            2.528,
            -62.777,
            44.281,
            2.868,
            -62.777,
            44.281,
            2.868,
            -61.485,
            43.795,
            2.868,
            -61.485,
            43.795,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2416",
        "polygon": [
            -58.901,
            42.824,
            2.189,
            -60.193,
            43.31,
            2.189,
            -60.193,
            43.31,
            2.528,
            -60.193,
            43.31,
            2.528,
            -58.901,
            42.824,
            2.528,
            -58.901,
            42.824,
            2.189
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2417",
        "polygon": [
            -60.193,
            43.31,
            2.189,
            -61.485,
            43.795,
            2.189,
            -61.485,
            43.795,
            2.528,
            -61.485,
            43.795,
            2.528,
            -60.193,
            43.31,
            2.528,
            -60.193,
            43.31,
            2.189
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2418",
        "polygon": [
            -61.485,
            43.795,
            2.189,
            -62.777,
            44.281,
            2.189,
            -62.777,
            44.281,
            2.528,
            -62.777,
            44.281,
            2.528,
            -61.485,
            43.795,
            2.528,
            -61.485,
            43.795,
            2.189
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2419",
        "polygon": [
            -58.901,
            42.824,
            1.849,
            -60.193,
            43.31,
            1.849,
            -60.193,
            43.31,
            2.189,
            -60.193,
            43.31,
            2.189,
            -58.901,
            42.824,
            2.189,
            -58.901,
            42.824,
            1.849
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2420",
        "polygon": [
            -60.193,
            43.31,
            1.849,
            -61.485,
            43.795,
            1.849,
            -61.485,
            43.795,
            2.189,
            -61.485,
            43.795,
            2.189,
            -60.193,
            43.31,
            2.189,
            -60.193,
            43.31,
            1.849
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2421",
        "polygon": [
            -61.485,
            43.795,
            1.849,
            -62.777,
            44.281,
            1.849,
            -62.777,
            44.281,
            2.189,
            -62.777,
            44.281,
            2.189,
            -61.485,
            43.795,
            2.189,
            -61.485,
            43.795,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2422",
        "polygon": [
            -58.901,
            42.824,
            1.509,
            -60.193,
            43.31,
            1.509,
            -60.193,
            43.31,
            1.849,
            -60.193,
            43.31,
            1.849,
            -58.901,
            42.824,
            1.849,
            -58.901,
            42.824,
            1.509
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2423",
        "polygon": [
            -60.193,
            43.31,
            1.509,
            -61.485,
            43.795,
            1.509,
            -61.485,
            43.795,
            1.849,
            -61.485,
            43.795,
            1.849,
            -60.193,
            43.31,
            1.849,
            -60.193,
            43.31,
            1.509
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2424",
        "polygon": [
            -61.485,
            43.795,
            1.509,
            -62.777,
            44.281,
            1.509,
            -62.777,
            44.281,
            1.849,
            -62.777,
            44.281,
            1.849,
            -61.485,
            43.795,
            1.849,
            -61.485,
            43.795,
            1.509
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2425",
        "polygon": [
            -58.901,
            42.824,
            1.109,
            -60.193,
            43.31,
            1.109,
            -60.193,
            43.31,
            1.449,
            -60.193,
            43.31,
            1.449,
            -58.901,
            42.824,
            1.449,
            -58.901,
            42.824,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2426",
        "polygon": [
            -60.193,
            43.31,
            1.109,
            -61.485,
            43.795,
            1.109,
            -61.485,
            43.795,
            1.449,
            -61.485,
            43.795,
            1.449,
            -60.193,
            43.31,
            1.449,
            -60.193,
            43.31,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2427",
        "polygon": [
            -61.485,
            43.795,
            1.109,
            -62.777,
            44.281,
            1.109,
            -62.777,
            44.281,
            1.449,
            -62.777,
            44.281,
            1.449,
            -61.485,
            43.795,
            1.449,
            -61.485,
            43.795,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2428",
        "polygon": [
            -58.901,
            42.824,
            0.77,
            -60.193,
            43.31,
            0.77,
            -60.193,
            43.31,
            1.109,
            -60.193,
            43.31,
            1.109,
            -58.901,
            42.824,
            1.109,
            -58.901,
            42.824,
            0.77
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2429",
        "polygon": [
            -60.193,
            43.31,
            0.77,
            -61.485,
            43.795,
            0.77,
            -61.485,
            43.795,
            1.109,
            -61.485,
            43.795,
            1.109,
            -60.193,
            43.31,
            1.109,
            -60.193,
            43.31,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2430",
        "polygon": [
            -61.485,
            43.795,
            0.77,
            -62.777,
            44.281,
            0.77,
            -62.777,
            44.281,
            1.109,
            -62.777,
            44.281,
            1.109,
            -61.485,
            43.795,
            1.109,
            -61.485,
            43.795,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2431",
        "polygon": [
            -58.901,
            42.824,
            0.43,
            -60.193,
            43.31,
            0.43,
            -60.193,
            43.31,
            0.77,
            -60.193,
            43.31,
            0.77,
            -58.901,
            42.824,
            0.77,
            -58.901,
            42.824,
            0.43
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2432",
        "polygon": [
            -60.193,
            43.31,
            0.43,
            -61.485,
            43.795,
            0.43,
            -61.485,
            43.795,
            0.77,
            -61.485,
            43.795,
            0.77,
            -60.193,
            43.31,
            0.77,
            -60.193,
            43.31,
            0.43
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2433",
        "polygon": [
            -61.485,
            43.795,
            0.43,
            -62.777,
            44.281,
            0.43,
            -62.777,
            44.281,
            0.77,
            -62.777,
            44.281,
            0.77,
            -61.485,
            43.795,
            0.77,
            -61.485,
            43.795,
            0.43
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2434",
        "polygon": [
            -58.901,
            42.824,
            0.09,
            -60.193,
            43.31,
            0.09,
            -60.193,
            43.31,
            0.43,
            -60.193,
            43.31,
            0.43,
            -58.901,
            42.824,
            0.43,
            -58.901,
            42.824,
            0.09
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2435",
        "polygon": [
            -60.193,
            43.31,
            0.09,
            -61.485,
            43.795,
            0.09,
            -61.485,
            43.795,
            0.43,
            -61.485,
            43.795,
            0.43,
            -60.193,
            43.31,
            0.43,
            -60.193,
            43.31,
            0.09
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2436",
        "polygon": [
            -61.485,
            43.795,
            0.09,
            -62.777,
            44.281,
            0.09,
            -62.777,
            44.281,
            0.43,
            -62.777,
            44.281,
            0.43,
            -61.485,
            43.795,
            0.43,
            -61.485,
            43.795,
            0.09
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2437",
        "polygon": [
            -51.039,
            39.868,
            2.528,
            -52.33,
            40.354,
            2.528,
            -52.33,
            40.354,
            2.868,
            -52.33,
            40.354,
            2.868,
            -51.039,
            39.868,
            2.868,
            -51.039,
            39.868,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2438",
        "polygon": [
            -52.33,
            40.354,
            2.528,
            -53.622,
            40.839,
            2.528,
            -53.622,
            40.839,
            2.868,
            -53.622,
            40.839,
            2.868,
            -52.33,
            40.354,
            2.868,
            -52.33,
            40.354,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2439",
        "polygon": [
            -53.622,
            40.839,
            2.528,
            -54.914,
            41.325,
            2.528,
            -54.914,
            41.325,
            2.868,
            -54.914,
            41.325,
            2.868,
            -53.622,
            40.839,
            2.868,
            -53.622,
            40.839,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2440",
        "polygon": [
            -51.039,
            39.868,
            2.189,
            -52.33,
            40.354,
            2.189,
            -52.33,
            40.354,
            2.528,
            -52.33,
            40.354,
            2.528,
            -51.039,
            39.868,
            2.528,
            -51.039,
            39.868,
            2.189
        ],
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2441",
        "polygon": [
            -52.33,
            40.354,
            2.189,
            -53.622,
            40.839,
            2.189,
            -53.622,
            40.839,
            2.528,
            -53.622,
            40.839,
            2.528,
            -52.33,
            40.354,
            2.528,
            -52.33,
            40.354,
            2.189
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2442",
        "polygon": [
            -53.622,
            40.839,
            2.189,
            -54.914,
            41.325,
            2.189,
            -54.914,
            41.325,
            2.528,
            -54.914,
            41.325,
            2.528,
            -53.622,
            40.839,
            2.528,
            -53.622,
            40.839,
            2.189
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2443",
        "polygon": [
            -51.039,
            39.868,
            1.849,
            -52.33,
            40.354,
            1.849,
            -52.33,
            40.354,
            2.189,
            -52.33,
            40.354,
            2.189,
            -51.039,
            39.868,
            2.189,
            -51.039,
            39.868,
            1.849
        ],
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2444",
        "polygon": [
            -52.33,
            40.354,
            1.849,
            -53.622,
            40.839,
            1.849,
            -53.622,
            40.839,
            2.189,
            -53.622,
            40.839,
            2.189,
            -52.33,
            40.354,
            2.189,
            -52.33,
            40.354,
            1.849
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2445",
        "polygon": [
            -53.622,
            40.839,
            1.849,
            -54.914,
            41.325,
            1.849,
            -54.914,
            41.325,
            2.189,
            -54.914,
            41.325,
            2.189,
            -53.622,
            40.839,
            2.189,
            -53.622,
            40.839,
            1.849
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2446",
        "polygon": [
            -51.039,
            39.868,
            1.509,
            -52.33,
            40.354,
            1.509,
            -52.33,
            40.354,
            1.849,
            -52.33,
            40.354,
            1.849,
            -51.039,
            39.868,
            1.849,
            -51.039,
            39.868,
            1.509
        ],
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2447",
        "polygon": [
            -52.33,
            40.354,
            1.509,
            -53.622,
            40.839,
            1.509,
            -53.622,
            40.839,
            1.849,
            -53.622,
            40.839,
            1.849,
            -52.33,
            40.354,
            1.849,
            -52.33,
            40.354,
            1.509
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2448",
        "polygon": [
            -53.622,
            40.839,
            1.509,
            -54.914,
            41.325,
            1.509,
            -54.914,
            41.325,
            1.849,
            -54.914,
            41.325,
            1.849,
            -53.622,
            40.839,
            1.849,
            -53.622,
            40.839,
            1.509
        ],
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2449",
        "polygon": [
            -51.039,
            39.868,
            1.109,
            -52.33,
            40.354,
            1.109,
            -52.33,
            40.354,
            1.449,
            -52.33,
            40.354,
            1.449,
            -51.039,
            39.868,
            1.449,
            -51.039,
            39.868,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2450",
        "polygon": [
            -52.33,
            40.354,
            1.109,
            -53.622,
            40.839,
            1.109,
            -53.622,
            40.839,
            1.449,
            -53.622,
            40.839,
            1.449,
            -52.33,
            40.354,
            1.449,
            -52.33,
            40.354,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2451",
        "polygon": [
            -53.622,
            40.839,
            1.109,
            -54.914,
            41.325,
            1.109,
            -54.914,
            41.325,
            1.449,
            -54.914,
            41.325,
            1.449,
            -53.622,
            40.839,
            1.449,
            -53.622,
            40.839,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2452",
        "polygon": [
            -51.039,
            39.868,
            0.77,
            -52.33,
            40.354,
            0.77,
            -52.33,
            40.354,
            1.109,
            -52.33,
            40.354,
            1.109,
            -51.039,
            39.868,
            1.109,
            -51.039,
            39.868,
            0.77
        ],
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2453",
        "polygon": [
            -52.33,
            40.354,
            0.77,
            -53.622,
            40.839,
            0.77,
            -53.622,
            40.839,
            1.109,
            -53.622,
            40.839,
            1.109,
            -52.33,
            40.354,
            1.109,
            -52.33,
            40.354,
            0.77
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2454",
        "polygon": [
            -53.622,
            40.839,
            0.77,
            -54.914,
            41.325,
            0.77,
            -54.914,
            41.325,
            1.109,
            -54.914,
            41.325,
            1.109,
            -53.622,
            40.839,
            1.109,
            -53.622,
            40.839,
            0.77
        ],
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2455",
        "polygon": [
            -51.039,
            39.868,
            0.43,
            -52.33,
            40.354,
            0.43,
            -52.33,
            40.354,
            0.77,
            -52.33,
            40.354,
            0.77,
            -51.039,
            39.868,
            0.77,
            -51.039,
            39.868,
            0.43
        ],
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2456",
        "polygon": [
            -52.33,
            40.354,
            0.43,
            -53.622,
            40.839,
            0.43,
            -53.622,
            40.839,
            0.77,
            -53.622,
            40.839,
            0.77,
            -52.33,
            40.354,
            0.77,
            -52.33,
            40.354,
            0.43
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2457",
        "polygon": [
            -53.622,
            40.839,
            0.43,
            -54.914,
            41.325,
            0.43,
            -54.914,
            41.325,
            0.77,
            -54.914,
            41.325,
            0.77,
            -53.622,
            40.839,
            0.77,
            -53.622,
            40.839,
            0.43
        ],
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2458",
        "polygon": [
            -51.039,
            39.868,
            0.09,
            -52.33,
            40.354,
            0.09,
            -52.33,
            40.354,
            0.43,
            -52.33,
            40.354,
            0.43,
            -51.039,
            39.868,
            0.43,
            -51.039,
            39.868,
            0.09
        ],
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2459",
        "polygon": [
            -52.33,
            40.354,
            0.09,
            -53.622,
            40.839,
            0.09,
            -53.622,
            40.839,
            0.43,
            -53.622,
            40.839,
            0.43,
            -52.33,
            40.354,
            0.43,
            -52.33,
            40.354,
            0.09
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2460",
        "polygon": [
            -53.622,
            40.839,
            0.09,
            -54.914,
            41.325,
            0.09,
            -54.914,
            41.325,
            0.43,
            -54.914,
            41.325,
            0.43,
            -53.622,
            40.839,
            0.43,
            -53.622,
            40.839,
            0.09
        ],
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2461",
        "polygon": [
            -63.769,
            44.654,
            2.528,
            -64.705,
            45.006,
            2.528,
            -64.705,
            45.006,
            2.868,
            -64.705,
            45.006,
            2.868,
            -63.769,
            44.654,
            2.868,
            -63.769,
            44.654,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2462",
        "polygon": [
            -62.833,
            44.302,
            2.528,
            -63.769,
            44.654,
            2.528,
            -63.769,
            44.654,
            2.868,
            -63.769,
            44.654,
            2.868,
            -62.833,
            44.302,
            2.868,
            -62.833,
            44.302,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2463",
        "polygon": [
            -63.769,
            44.654,
            2.188,
            -64.705,
            45.006,
            2.188,
            -64.705,
            45.006,
            2.528,
            -64.705,
            45.006,
            2.528,
            -63.769,
            44.654,
            2.528,
            -63.769,
            44.654,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2464",
        "polygon": [
            -62.833,
            44.302,
            2.188,
            -63.769,
            44.654,
            2.188,
            -63.769,
            44.654,
            2.528,
            -63.769,
            44.654,
            2.528,
            -62.833,
            44.302,
            2.528,
            -62.833,
            44.302,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2465",
        "polygon": [
            -63.769,
            44.654,
            1.849,
            -64.705,
            45.006,
            1.849,
            -64.705,
            45.006,
            2.188,
            -64.705,
            45.006,
            2.188,
            -63.769,
            44.654,
            2.188,
            -63.769,
            44.654,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2466",
        "polygon": [
            -62.833,
            44.302,
            1.849,
            -63.769,
            44.654,
            1.849,
            -63.769,
            44.654,
            2.188,
            -63.769,
            44.654,
            2.188,
            -62.833,
            44.302,
            2.188,
            -62.833,
            44.302,
            1.849
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2467",
        "polygon": [
            -63.769,
            44.654,
            1.509,
            -64.705,
            45.006,
            1.509,
            -64.705,
            45.006,
            1.849,
            -64.705,
            45.006,
            1.849,
            -63.769,
            44.654,
            1.849,
            -63.769,
            44.654,
            1.509
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2468",
        "polygon": [
            -62.833,
            44.302,
            1.509,
            -63.769,
            44.654,
            1.509,
            -63.769,
            44.654,
            1.849,
            -63.769,
            44.654,
            1.849,
            -62.833,
            44.302,
            1.849,
            -62.833,
            44.302,
            1.509
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2469",
        "polygon": [
            -63.769,
            44.654,
            1.109,
            -64.705,
            45.006,
            1.109,
            -64.705,
            45.006,
            1.449,
            -64.705,
            45.006,
            1.449,
            -63.769,
            44.654,
            1.449,
            -63.769,
            44.654,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2470",
        "polygon": [
            -62.833,
            44.302,
            1.109,
            -63.769,
            44.654,
            1.109,
            -63.769,
            44.654,
            1.449,
            -63.769,
            44.654,
            1.449,
            -62.833,
            44.302,
            1.449,
            -62.833,
            44.302,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2471",
        "polygon": [
            -63.769,
            44.654,
            0.77,
            -64.705,
            45.006,
            0.77,
            -64.705,
            45.006,
            1.109,
            -64.705,
            45.006,
            1.109,
            -63.769,
            44.654,
            1.109,
            -63.769,
            44.654,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2472",
        "polygon": [
            -62.833,
            44.302,
            0.77,
            -63.769,
            44.654,
            0.77,
            -63.769,
            44.654,
            1.109,
            -63.769,
            44.654,
            1.109,
            -62.833,
            44.302,
            1.109,
            -62.833,
            44.302,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2473",
        "polygon": [
            -63.769,
            44.654,
            0.43,
            -64.705,
            45.006,
            0.43,
            -64.705,
            45.006,
            0.77,
            -64.705,
            45.006,
            0.77,
            -63.769,
            44.654,
            0.77,
            -63.769,
            44.654,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2474",
        "polygon": [
            -62.833,
            44.302,
            0.43,
            -63.769,
            44.654,
            0.43,
            -63.769,
            44.654,
            0.77,
            -63.769,
            44.654,
            0.77,
            -62.833,
            44.302,
            0.77,
            -62.833,
            44.302,
            0.43
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2475",
        "polygon": [
            -63.769,
            44.654,
            0.09,
            -64.705,
            45.006,
            0.09,
            -64.705,
            45.006,
            0.43,
            -64.705,
            45.006,
            0.43,
            -63.769,
            44.654,
            0.43,
            -63.769,
            44.654,
            0.09
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2476",
        "polygon": [
            -62.833,
            44.302,
            0.09,
            -63.769,
            44.654,
            0.09,
            -63.769,
            44.654,
            0.43,
            -63.769,
            44.654,
            0.43,
            -62.833,
            44.302,
            0.43,
            -62.833,
            44.302,
            0.09
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2477",
        "polygon": [
            -54.97,
            41.346,
            0.102,
            -55.691,
            41.617,
            0.102,
            -55.691,
            41.617,
            2.868,
            -55.691,
            41.617,
            2.868,
            -54.97,
            41.346,
            2.868,
            -54.97,
            41.346,
            0.102
        ],
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2478",
        "polygon": [
            -58.125,
            42.532,
            0.102,
            -58.845,
            42.803,
            0.102,
            -58.845,
            42.803,
            2.868,
            -58.845,
            42.803,
            2.868,
            -58.125,
            42.532,
            2.868,
            -58.125,
            42.532,
            0.102
        ],
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2479",
        "polygon": [
            -64.761,
            45.027,
            0.09,
            -65.734,
            45.393,
            0.09,
            -65.734,
            45.393,
            2.868,
            -65.734,
            45.393,
            2.868,
            -64.761,
            45.027,
            2.868,
            -64.761,
            45.027,
            0.09
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2480",
        "polygon": [
            -65.734,
            45.393,
            0.09,
            -66.708,
            45.759,
            0.09,
            -66.708,
            45.759,
            2.868,
            -66.708,
            45.759,
            2.868,
            -65.734,
            45.393,
            2.868,
            -65.734,
            45.393,
            0.09
        ],
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2481",
        "polygon": [
            4.077,
            16.155,
            5.937,
            0.202,
            17.612,
            5.937,
            0.202,
            17.612,
            7.732,
            0.202,
            17.612,
            7.732,
            4.077,
            16.155,
            7.732,
            4.077,
            16.155,
            5.937
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2482",
        "polygon": [
            4.077,
            16.155,
            4.081,
            0.202,
            17.612,
            4.081,
            0.202,
            17.612,
            5.876,
            0.202,
            17.612,
            5.876,
            4.077,
            16.155,
            5.876,
            4.077,
            16.155,
            4.081
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2483",
        "polygon": [
            8.008,
            14.677,
            5.937,
            4.133,
            16.134,
            5.937,
            4.133,
            16.134,
            7.732,
            4.133,
            16.134,
            7.732,
            8.008,
            14.677,
            7.732,
            8.008,
            14.677,
            5.937
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2485",
        "polygon": [
            11.94,
            13.199,
            5.937,
            8.064,
            14.656,
            5.937,
            8.064,
            14.656,
            7.732,
            8.064,
            14.656,
            7.732,
            11.94,
            13.199,
            7.732,
            11.94,
            13.199,
            5.937
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2487",
        "polygon": [
            15.871,
            11.721,
            5.937,
            11.996,
            13.178,
            5.937,
            11.996,
            13.178,
            7.732,
            11.996,
            13.178,
            7.732,
            15.871,
            11.721,
            7.732,
            15.871,
            11.721,
            5.937
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2489",
        "polygon": [
            8.008,
            14.677,
            4.081,
            4.133,
            16.134,
            4.081,
            4.133,
            16.134,
            5.876,
            4.133,
            16.134,
            5.876,
            8.008,
            14.677,
            5.876,
            8.008,
            14.677,
            4.081
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2490",
        "polygon": [
            15.871,
            11.721,
            4.081,
            11.996,
            13.178,
            4.081,
            11.996,
            13.178,
            5.876,
            11.996,
            13.178,
            5.876,
            15.871,
            11.721,
            5.876,
            15.871,
            11.721,
            4.081
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2491",
        "polygon": [
            11.94,
            13.199,
            4.081,
            8.064,
            14.656,
            4.081,
            8.064,
            14.656,
            5.876,
            8.064,
            14.656,
            5.876,
            11.94,
            13.199,
            5.876,
            11.94,
            13.199,
            4.081
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2492",
        "polygon": [
            19.275,
            10.441,
            5.936,
            15.927,
            11.7,
            5.936,
            15.927,
            11.7,
            7.731,
            15.927,
            11.7,
            7.731,
            19.275,
            10.441,
            7.731,
            19.275,
            10.441,
            5.936
        ],
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2493",
        "polygon": [
            19.275,
            10.441,
            4.081,
            15.927,
            11.7,
            4.081,
            15.927,
            11.7,
            5.876,
            15.927,
            11.7,
            5.876,
            19.275,
            10.441,
            5.876,
            19.275,
            10.441,
            4.081
        ],
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2494",
        "polygon": [
            0.145,
            17.633,
            5.936,
            -3.203,
            18.892,
            5.936,
            -3.203,
            18.892,
            7.731,
            -3.203,
            18.892,
            7.731,
            0.145,
            17.633,
            7.731,
            0.145,
            17.633,
            5.936
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2496",
        "polygon": [
            0.145,
            17.633,
            4.081,
            -3.203,
            18.892,
            4.081,
            -3.203,
            18.892,
            5.876,
            -3.203,
            18.892,
            5.876,
            0.145,
            17.633,
            5.876,
            0.145,
            17.633,
            4.081
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2497",
        "polygon": [
            4.077,
            16.155,
            9.647,
            0.202,
            17.612,
            9.647,
            0.202,
            17.612,
            11.442,
            0.202,
            17.612,
            11.442,
            4.077,
            16.155,
            11.442,
            4.077,
            16.155,
            9.647
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2498",
        "polygon": [
            4.077,
            16.155,
            7.791,
            0.202,
            17.612,
            7.791,
            0.202,
            17.612,
            9.586,
            0.202,
            17.612,
            9.586,
            4.077,
            16.155,
            9.586,
            4.077,
            16.155,
            7.791
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2499",
        "polygon": [
            8.008,
            14.677,
            9.647,
            4.133,
            16.134,
            9.647,
            4.133,
            16.134,
            11.442,
            4.133,
            16.134,
            11.442,
            8.008,
            14.677,
            11.442,
            8.008,
            14.677,
            9.647
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2500",
        "polygon": [
            11.94,
            13.199,
            9.647,
            8.064,
            14.656,
            9.647,
            8.064,
            14.656,
            11.442,
            8.064,
            14.656,
            11.442,
            11.94,
            13.199,
            11.442,
            11.94,
            13.199,
            9.647
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2501",
        "polygon": [
            15.871,
            11.721,
            9.647,
            11.996,
            13.178,
            9.647,
            11.996,
            13.178,
            11.442,
            11.996,
            13.178,
            11.442,
            15.871,
            11.721,
            11.442,
            15.871,
            11.721,
            9.647
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2502",
        "polygon": [
            8.008,
            14.677,
            7.791,
            4.133,
            16.134,
            7.791,
            4.133,
            16.134,
            9.586,
            4.133,
            16.134,
            9.586,
            8.008,
            14.677,
            9.586,
            8.008,
            14.677,
            7.791
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2503",
        "polygon": [
            15.871,
            11.721,
            7.791,
            11.996,
            13.178,
            7.791,
            11.996,
            13.178,
            9.586,
            11.996,
            13.178,
            9.586,
            15.871,
            11.721,
            9.586,
            15.871,
            11.721,
            7.791
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2504",
        "polygon": [
            11.94,
            13.199,
            7.791,
            8.064,
            14.656,
            7.791,
            8.064,
            14.656,
            9.586,
            8.064,
            14.656,
            9.586,
            11.94,
            13.199,
            9.586,
            11.94,
            13.199,
            7.791
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2505",
        "polygon": [
            19.275,
            10.441,
            9.646,
            15.927,
            11.7,
            9.646,
            15.927,
            11.7,
            11.441,
            15.927,
            11.7,
            11.441,
            19.275,
            10.441,
            11.441,
            19.275,
            10.441,
            9.646
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2506",
        "polygon": [
            19.275,
            10.441,
            7.791,
            15.927,
            11.7,
            7.791,
            15.927,
            11.7,
            9.586,
            15.927,
            11.7,
            9.586,
            19.275,
            10.441,
            9.586,
            19.275,
            10.441,
            7.791
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2507",
        "polygon": [
            0.145,
            17.633,
            9.646,
            -3.203,
            18.892,
            9.646,
            -3.203,
            18.892,
            11.441,
            -3.203,
            18.892,
            11.441,
            0.145,
            17.633,
            11.441,
            0.145,
            17.633,
            9.646
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2508",
        "polygon": [
            0.145,
            17.633,
            7.791,
            -3.203,
            18.892,
            7.791,
            -3.203,
            18.892,
            9.586,
            -3.203,
            18.892,
            9.586,
            0.145,
            17.633,
            9.586,
            0.145,
            17.633,
            7.791
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2509",
        "polygon": [
            4.077,
            16.155,
            11.501,
            0.202,
            17.612,
            11.501,
            0.202,
            17.612,
            13.427,
            0.202,
            17.612,
            13.427,
            4.077,
            16.155,
            13.427,
            4.077,
            16.155,
            11.501
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2510",
        "polygon": [
            8.008,
            14.677,
            11.501,
            4.133,
            16.134,
            11.501,
            4.133,
            16.134,
            13.595,
            4.133,
            16.134,
            13.595,
            8.008,
            14.677,
            13.595,
            8.008,
            14.677,
            11.501
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2511",
        "polygon": [
            15.871,
            11.721,
            11.501,
            11.996,
            13.178,
            11.501,
            11.996,
            13.178,
            13.427,
            11.996,
            13.178,
            13.427,
            15.871,
            11.721,
            13.427,
            15.871,
            11.721,
            11.501
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2512",
        "polygon": [
            11.94,
            13.199,
            11.501,
            8.064,
            14.656,
            11.501,
            8.064,
            14.656,
            13.595,
            8.064,
            14.656,
            13.595,
            11.94,
            13.199,
            13.595,
            11.94,
            13.199,
            11.501
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2513",
        "polygon": [
            19.275,
            10.441,
            11.501,
            15.927,
            11.7,
            11.501,
            15.927,
            11.7,
            13.296,
            15.927,
            11.7,
            13.296,
            19.275,
            10.441,
            13.296,
            19.275,
            10.441,
            11.501
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2514",
        "polygon": [
            0.145,
            17.633,
            11.501,
            -3.203,
            18.892,
            11.501,
            -3.203,
            18.892,
            13.296,
            -3.203,
            18.892,
            13.296,
            0.145,
            17.633,
            13.296,
            0.145,
            17.633,
            11.501
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2515",
        "polygon": [
            4.077,
            16.155,
            2.928,
            0.202,
            17.612,
            2.928,
            0.202,
            17.612,
            4.029,
            0.202,
            17.612,
            4.029,
            4.077,
            16.155,
            4.029,
            4.077,
            16.155,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2516",
        "polygon": [
            8.008,
            14.677,
            2.928,
            4.133,
            16.134,
            2.928,
            4.133,
            16.134,
            4.029,
            4.133,
            16.134,
            4.029,
            8.008,
            14.677,
            4.029,
            8.008,
            14.677,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2517",
        "polygon": [
            11.94,
            13.199,
            2.928,
            8.064,
            14.656,
            2.928,
            8.064,
            14.656,
            4.029,
            8.064,
            14.656,
            4.029,
            11.94,
            13.199,
            4.029,
            11.94,
            13.199,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2518",
        "polygon": [
            15.871,
            11.721,
            2.928,
            11.996,
            13.178,
            2.928,
            11.996,
            13.178,
            4.029,
            11.996,
            13.178,
            4.029,
            15.871,
            11.721,
            4.029,
            15.871,
            11.721,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2519",
        "polygon": [
            19.275,
            10.441,
            2.928,
            15.927,
            11.7,
            2.928,
            15.927,
            11.7,
            4.029,
            15.927,
            11.7,
            4.029,
            19.275,
            10.441,
            4.029,
            19.275,
            10.441,
            2.928
        ],
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2520",
        "polygon": [
            0.146,
            17.633,
            2.928,
            -3.202,
            18.892,
            2.928,
            -3.202,
            18.892,
            4.029,
            -3.202,
            18.892,
            4.029,
            0.146,
            17.633,
            4.029,
            0.146,
            17.633,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2521",
        "polygon": [
            0.146,
            17.633,
            2.528,
            -1.146,
            18.119,
            2.528,
            -1.146,
            18.119,
            2.868,
            -1.146,
            18.119,
            2.868,
            0.146,
            17.633,
            2.868,
            0.146,
            17.633,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2522",
        "polygon": [
            -1.146,
            18.119,
            2.528,
            -2.438,
            18.604,
            2.528,
            -2.438,
            18.604,
            2.868,
            -2.438,
            18.604,
            2.868,
            -1.146,
            18.119,
            2.868,
            -1.146,
            18.119,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2523",
        "polygon": [
            -2.438,
            18.604,
            2.528,
            -3.191,
            18.888,
            2.528,
            -3.191,
            18.888,
            2.868,
            -3.191,
            18.888,
            2.868,
            -2.438,
            18.604,
            2.868,
            -2.438,
            18.604,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2524",
        "polygon": [
            0.146,
            17.633,
            2.188,
            -1.146,
            18.119,
            2.188,
            -1.146,
            18.119,
            2.528,
            -1.146,
            18.119,
            2.528,
            0.146,
            17.633,
            2.528,
            0.146,
            17.633,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2525",
        "polygon": [
            -1.146,
            18.119,
            2.188,
            -2.438,
            18.604,
            2.188,
            -2.438,
            18.604,
            2.528,
            -2.438,
            18.604,
            2.528,
            -1.146,
            18.119,
            2.528,
            -1.146,
            18.119,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2526",
        "polygon": [
            -2.438,
            18.604,
            2.189,
            -3.191,
            18.888,
            2.189,
            -3.191,
            18.888,
            2.528,
            -3.191,
            18.888,
            2.528,
            -2.438,
            18.604,
            2.528,
            -2.438,
            18.604,
            2.189
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2527",
        "polygon": [
            0.146,
            17.633,
            1.849,
            -1.146,
            18.119,
            1.849,
            -1.146,
            18.119,
            2.188,
            -1.146,
            18.119,
            2.188,
            0.146,
            17.633,
            2.188,
            0.146,
            17.633,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2529",
        "polygon": [
            -2.438,
            18.604,
            1.849,
            -3.191,
            18.888,
            1.849,
            -3.191,
            18.888,
            2.189,
            -3.191,
            18.888,
            2.189,
            -2.438,
            18.604,
            2.189,
            -2.438,
            18.604,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2530",
        "polygon": [
            0.146,
            17.633,
            1.509,
            -1.146,
            18.119,
            1.509,
            -1.146,
            18.119,
            1.849,
            -1.146,
            18.119,
            1.849,
            0.146,
            17.633,
            1.849,
            0.146,
            17.633,
            1.509
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2531",
        "polygon": [
            -1.146,
            18.119,
            1.509,
            -2.438,
            18.604,
            1.509,
            -2.438,
            18.604,
            1.849,
            -2.438,
            18.604,
            1.849,
            -1.146,
            18.119,
            1.849,
            -1.146,
            18.119,
            1.509
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2532",
        "polygon": [
            -2.438,
            18.604,
            1.509,
            -3.191,
            18.888,
            1.509,
            -3.191,
            18.888,
            1.849,
            -3.191,
            18.888,
            1.849,
            -2.438,
            18.604,
            1.849,
            -2.438,
            18.604,
            1.509
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2533",
        "polygon": [
            0.146,
            17.633,
            1.109,
            -1.146,
            18.119,
            1.109,
            -1.146,
            18.119,
            1.449,
            -1.146,
            18.119,
            1.449,
            0.146,
            17.633,
            1.449,
            0.146,
            17.633,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2534",
        "polygon": [
            -1.146,
            18.119,
            1.109,
            -2.438,
            18.604,
            1.109,
            -2.438,
            18.604,
            1.449,
            -2.438,
            18.604,
            1.449,
            -1.146,
            18.119,
            1.449,
            -1.146,
            18.119,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2535",
        "polygon": [
            -2.438,
            18.604,
            1.109,
            -3.191,
            18.888,
            1.109,
            -3.191,
            18.888,
            1.449,
            -3.191,
            18.888,
            1.449,
            -2.438,
            18.604,
            1.449,
            -2.438,
            18.604,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2536",
        "polygon": [
            0.146,
            17.633,
            0.769,
            -1.146,
            18.119,
            0.769,
            -1.146,
            18.119,
            1.109,
            -1.146,
            18.119,
            1.109,
            0.146,
            17.633,
            1.109,
            0.146,
            17.633,
            0.769
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2537",
        "polygon": [
            -1.146,
            18.119,
            0.769,
            -2.438,
            18.604,
            0.769,
            -2.438,
            18.604,
            1.109,
            -2.438,
            18.604,
            1.109,
            -1.146,
            18.119,
            1.109,
            -1.146,
            18.119,
            0.769
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2538",
        "polygon": [
            -2.438,
            18.604,
            0.77,
            -3.191,
            18.888,
            0.77,
            -3.191,
            18.888,
            1.109,
            -3.191,
            18.888,
            1.109,
            -2.438,
            18.604,
            1.109,
            -2.438,
            18.604,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2539",
        "polygon": [
            0.146,
            17.633,
            0.43,
            -1.146,
            18.119,
            0.43,
            -1.146,
            18.119,
            0.769,
            -1.146,
            18.119,
            0.769,
            0.146,
            17.633,
            0.769,
            0.146,
            17.633,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2541",
        "polygon": [
            -2.438,
            18.604,
            0.43,
            -3.191,
            18.888,
            0.43,
            -3.191,
            18.888,
            0.77,
            -3.191,
            18.888,
            0.77,
            -2.438,
            18.604,
            0.77,
            -2.438,
            18.604,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2542",
        "polygon": [
            0.146,
            17.633,
            0.09,
            -1.146,
            18.119,
            0.09,
            -1.146,
            18.119,
            0.43,
            -1.146,
            18.119,
            0.43,
            0.146,
            17.633,
            0.43,
            0.146,
            17.633,
            0.09
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2543",
        "polygon": [
            -1.146,
            18.119,
            0.09,
            -2.438,
            18.604,
            0.09,
            -2.438,
            18.604,
            0.43,
            -2.438,
            18.604,
            0.43,
            -1.146,
            18.119,
            0.43,
            -1.146,
            18.119,
            0.09
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2544",
        "polygon": [
            -2.438,
            18.604,
            0.09,
            -3.191,
            18.888,
            0.09,
            -3.191,
            18.888,
            0.43,
            -3.191,
            18.888,
            0.43,
            -2.438,
            18.604,
            0.43,
            -2.438,
            18.604,
            0.09
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2545",
        "polygon": [
            8.008,
            14.677,
            2.528,
            6.717,
            15.163,
            2.528,
            6.717,
            15.163,
            2.868,
            6.717,
            15.163,
            2.868,
            8.008,
            14.677,
            2.868,
            8.008,
            14.677,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2546",
        "polygon": [
            6.717,
            15.163,
            2.528,
            5.425,
            15.648,
            2.528,
            5.425,
            15.648,
            2.868,
            5.425,
            15.648,
            2.868,
            6.717,
            15.163,
            2.868,
            6.717,
            15.163,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2547",
        "polygon": [
            8.008,
            14.677,
            2.188,
            6.717,
            15.163,
            2.188,
            6.717,
            15.163,
            2.528,
            6.717,
            15.163,
            2.528,
            8.008,
            14.677,
            2.528,
            8.008,
            14.677,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2548",
        "polygon": [
            6.717,
            15.163,
            2.188,
            5.425,
            15.648,
            2.188,
            5.425,
            15.648,
            2.528,
            5.425,
            15.648,
            2.528,
            6.717,
            15.163,
            2.528,
            6.717,
            15.163,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2549",
        "polygon": [
            8.008,
            14.677,
            1.849,
            6.717,
            15.163,
            1.849,
            6.717,
            15.163,
            2.188,
            6.717,
            15.163,
            2.188,
            8.008,
            14.677,
            2.188,
            8.008,
            14.677,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2550",
        "polygon": [
            6.717,
            15.163,
            1.849,
            5.425,
            15.648,
            1.849,
            5.425,
            15.648,
            2.188,
            5.425,
            15.648,
            2.188,
            6.717,
            15.163,
            2.188,
            6.717,
            15.163,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2551",
        "polygon": [
            8.008,
            14.677,
            1.509,
            6.717,
            15.163,
            1.509,
            6.717,
            15.163,
            1.849,
            6.717,
            15.163,
            1.849,
            8.008,
            14.677,
            1.849,
            8.008,
            14.677,
            1.509
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2552",
        "polygon": [
            6.717,
            15.163,
            1.509,
            5.425,
            15.648,
            1.509,
            5.425,
            15.648,
            1.849,
            5.425,
            15.648,
            1.849,
            6.717,
            15.163,
            1.849,
            6.717,
            15.163,
            1.509
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2553",
        "polygon": [
            8.008,
            14.677,
            1.109,
            6.717,
            15.163,
            1.109,
            6.717,
            15.163,
            1.449,
            6.717,
            15.163,
            1.449,
            8.008,
            14.677,
            1.449,
            8.008,
            14.677,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2554",
        "polygon": [
            6.717,
            15.163,
            1.109,
            5.425,
            15.648,
            1.109,
            5.425,
            15.648,
            1.449,
            5.425,
            15.648,
            1.449,
            6.717,
            15.163,
            1.449,
            6.717,
            15.163,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2555",
        "polygon": [
            8.008,
            14.677,
            0.769,
            6.717,
            15.163,
            0.769,
            6.717,
            15.163,
            1.109,
            6.717,
            15.163,
            1.109,
            8.008,
            14.677,
            1.109,
            8.008,
            14.677,
            0.769
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2556",
        "polygon": [
            6.717,
            15.163,
            0.769,
            5.425,
            15.648,
            0.769,
            5.425,
            15.648,
            1.109,
            5.425,
            15.648,
            1.109,
            6.717,
            15.163,
            1.109,
            6.717,
            15.163,
            0.769
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2557",
        "polygon": [
            8.008,
            14.677,
            0.43,
            6.717,
            15.163,
            0.43,
            6.717,
            15.163,
            0.769,
            6.717,
            15.163,
            0.769,
            8.008,
            14.677,
            0.769,
            8.008,
            14.677,
            0.43
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2558",
        "polygon": [
            6.717,
            15.163,
            0.43,
            5.425,
            15.648,
            0.43,
            5.425,
            15.648,
            0.769,
            5.425,
            15.648,
            0.769,
            6.717,
            15.163,
            0.769,
            6.717,
            15.163,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2559",
        "polygon": [
            8.008,
            14.677,
            0.09,
            6.717,
            15.163,
            0.09,
            6.717,
            15.163,
            0.43,
            6.717,
            15.163,
            0.43,
            8.008,
            14.677,
            0.43,
            8.008,
            14.677,
            0.09
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2560",
        "polygon": [
            6.717,
            15.163,
            0.09,
            5.425,
            15.648,
            0.09,
            5.425,
            15.648,
            0.43,
            5.425,
            15.648,
            0.43,
            6.717,
            15.163,
            0.43,
            6.717,
            15.163,
            0.09
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2561",
        "polygon": [
            5.425,
            15.648,
            2.528,
            4.133,
            16.134,
            2.528,
            4.133,
            16.134,
            2.868,
            4.133,
            16.134,
            2.868,
            5.425,
            15.648,
            2.868,
            5.425,
            15.648,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2562",
        "polygon": [
            5.425,
            15.648,
            2.188,
            4.133,
            16.134,
            2.188,
            4.133,
            16.134,
            2.528,
            4.133,
            16.134,
            2.528,
            5.425,
            15.648,
            2.528,
            5.425,
            15.648,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2563",
        "polygon": [
            5.425,
            15.648,
            1.849,
            4.133,
            16.134,
            1.849,
            4.133,
            16.134,
            2.188,
            4.133,
            16.134,
            2.188,
            5.425,
            15.648,
            2.188,
            5.425,
            15.648,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2564",
        "polygon": [
            5.425,
            15.648,
            1.509,
            4.133,
            16.134,
            1.509,
            4.133,
            16.134,
            1.849,
            4.133,
            16.134,
            1.849,
            5.425,
            15.648,
            1.849,
            5.425,
            15.648,
            1.509
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2565",
        "polygon": [
            5.425,
            15.648,
            1.109,
            4.133,
            16.134,
            1.109,
            4.133,
            16.134,
            1.449,
            4.133,
            16.134,
            1.449,
            5.425,
            15.648,
            1.449,
            5.425,
            15.648,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2566",
        "polygon": [
            5.425,
            15.648,
            0.769,
            4.133,
            16.134,
            0.769,
            4.133,
            16.134,
            1.109,
            4.133,
            16.134,
            1.109,
            5.425,
            15.648,
            1.109,
            5.425,
            15.648,
            0.769
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2567",
        "polygon": [
            5.425,
            15.648,
            0.43,
            4.133,
            16.134,
            0.43,
            4.133,
            16.134,
            0.769,
            4.133,
            16.134,
            0.769,
            5.425,
            15.648,
            0.769,
            5.425,
            15.648,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2568",
        "polygon": [
            5.425,
            15.648,
            0.09,
            4.133,
            16.134,
            0.09,
            4.133,
            16.134,
            0.43,
            4.133,
            16.134,
            0.43,
            5.425,
            15.648,
            0.43,
            5.425,
            15.648,
            0.09
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2569",
        "polygon": [
            4.077,
            16.155,
            2.528,
            3.141,
            16.507,
            2.528,
            3.141,
            16.507,
            2.868,
            3.141,
            16.507,
            2.868,
            4.077,
            16.155,
            2.868,
            4.077,
            16.155,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2570",
        "polygon": [
            3.141,
            16.507,
            2.528,
            2.205,
            16.859,
            2.528,
            2.205,
            16.859,
            2.868,
            2.205,
            16.859,
            2.868,
            3.141,
            16.507,
            2.868,
            3.141,
            16.507,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2571",
        "polygon": [
            4.077,
            16.155,
            2.188,
            3.141,
            16.507,
            2.188,
            3.141,
            16.507,
            2.528,
            3.141,
            16.507,
            2.528,
            4.077,
            16.155,
            2.528,
            4.077,
            16.155,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2572",
        "polygon": [
            3.141,
            16.507,
            2.188,
            2.205,
            16.859,
            2.188,
            2.205,
            16.859,
            2.528,
            2.205,
            16.859,
            2.528,
            3.141,
            16.507,
            2.528,
            3.141,
            16.507,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2573",
        "polygon": [
            4.077,
            16.155,
            1.849,
            3.141,
            16.507,
            1.849,
            3.141,
            16.507,
            2.188,
            3.141,
            16.507,
            2.188,
            4.077,
            16.155,
            2.188,
            4.077,
            16.155,
            1.849
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2574",
        "polygon": [
            3.141,
            16.507,
            1.849,
            2.205,
            16.859,
            1.849,
            2.205,
            16.859,
            2.188,
            2.205,
            16.859,
            2.188,
            3.141,
            16.507,
            2.188,
            3.141,
            16.507,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2575",
        "polygon": [
            4.077,
            16.155,
            1.509,
            3.141,
            16.507,
            1.509,
            3.141,
            16.507,
            1.849,
            3.141,
            16.507,
            1.849,
            4.077,
            16.155,
            1.849,
            4.077,
            16.155,
            1.509
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2576",
        "polygon": [
            3.141,
            16.507,
            1.509,
            2.205,
            16.859,
            1.509,
            2.205,
            16.859,
            1.849,
            2.205,
            16.859,
            1.849,
            3.141,
            16.507,
            1.849,
            3.141,
            16.507,
            1.509
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2577",
        "polygon": [
            4.077,
            16.155,
            1.109,
            3.141,
            16.507,
            1.109,
            3.141,
            16.507,
            1.449,
            3.141,
            16.507,
            1.449,
            4.077,
            16.155,
            1.449,
            4.077,
            16.155,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2578",
        "polygon": [
            3.141,
            16.507,
            1.109,
            2.205,
            16.859,
            1.109,
            2.205,
            16.859,
            1.449,
            2.205,
            16.859,
            1.449,
            3.141,
            16.507,
            1.449,
            3.141,
            16.507,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2579",
        "polygon": [
            4.077,
            16.155,
            0.77,
            3.141,
            16.507,
            0.77,
            3.141,
            16.507,
            1.109,
            3.141,
            16.507,
            1.109,
            4.077,
            16.155,
            1.109,
            4.077,
            16.155,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2580",
        "polygon": [
            3.141,
            16.507,
            0.77,
            2.205,
            16.859,
            0.77,
            2.205,
            16.859,
            1.109,
            2.205,
            16.859,
            1.109,
            3.141,
            16.507,
            1.109,
            3.141,
            16.507,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2581",
        "polygon": [
            4.077,
            16.155,
            0.43,
            3.141,
            16.507,
            0.43,
            3.141,
            16.507,
            0.77,
            3.141,
            16.507,
            0.77,
            4.077,
            16.155,
            0.77,
            4.077,
            16.155,
            0.43
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2582",
        "polygon": [
            3.141,
            16.507,
            0.43,
            2.205,
            16.859,
            0.43,
            2.205,
            16.859,
            0.77,
            2.205,
            16.859,
            0.77,
            3.141,
            16.507,
            0.77,
            3.141,
            16.507,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2583",
        "polygon": [
            4.077,
            16.155,
            0.09,
            3.141,
            16.507,
            0.09,
            3.141,
            16.507,
            0.43,
            3.141,
            16.507,
            0.43,
            4.077,
            16.155,
            0.43,
            4.077,
            16.155,
            0.09
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2584",
        "polygon": [
            3.141,
            16.507,
            0.09,
            2.205,
            16.859,
            0.09,
            2.205,
            16.859,
            0.43,
            2.205,
            16.859,
            0.43,
            3.141,
            16.507,
            0.43,
            3.141,
            16.507,
            0.09
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2585",
        "polygon": [
            13.868,
            12.474,
            2.528,
            12.932,
            12.826,
            2.528,
            12.932,
            12.826,
            2.868,
            12.932,
            12.826,
            2.868,
            13.868,
            12.474,
            2.868,
            13.868,
            12.474,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2586",
        "polygon": [
            12.932,
            12.826,
            2.528,
            11.996,
            13.178,
            2.528,
            11.996,
            13.178,
            2.868,
            11.996,
            13.178,
            2.868,
            12.932,
            12.826,
            2.868,
            12.932,
            12.826,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2587",
        "polygon": [
            13.868,
            12.474,
            2.188,
            12.932,
            12.826,
            2.188,
            12.932,
            12.826,
            2.528,
            12.932,
            12.826,
            2.528,
            13.868,
            12.474,
            2.528,
            13.868,
            12.474,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2588",
        "polygon": [
            12.932,
            12.826,
            2.188,
            11.996,
            13.178,
            2.188,
            11.996,
            13.178,
            2.528,
            11.996,
            13.178,
            2.528,
            12.932,
            12.826,
            2.528,
            12.932,
            12.826,
            2.188
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2589",
        "polygon": [
            13.868,
            12.474,
            1.849,
            12.932,
            12.826,
            1.849,
            12.932,
            12.826,
            2.188,
            12.932,
            12.826,
            2.188,
            13.868,
            12.474,
            2.188,
            13.868,
            12.474,
            1.849
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2590",
        "polygon": [
            12.932,
            12.826,
            1.849,
            11.996,
            13.178,
            1.849,
            11.996,
            13.178,
            2.188,
            11.996,
            13.178,
            2.188,
            12.932,
            12.826,
            2.188,
            12.932,
            12.826,
            1.849
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2591",
        "polygon": [
            13.868,
            12.474,
            1.509,
            12.932,
            12.826,
            1.509,
            12.932,
            12.826,
            1.849,
            12.932,
            12.826,
            1.849,
            13.868,
            12.474,
            1.849,
            13.868,
            12.474,
            1.509
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2592",
        "polygon": [
            12.932,
            12.826,
            1.509,
            11.996,
            13.178,
            1.509,
            11.996,
            13.178,
            1.849,
            11.996,
            13.178,
            1.849,
            12.932,
            12.826,
            1.849,
            12.932,
            12.826,
            1.509
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2593",
        "polygon": [
            13.868,
            12.474,
            1.109,
            12.932,
            12.826,
            1.109,
            12.932,
            12.826,
            1.449,
            12.932,
            12.826,
            1.449,
            13.868,
            12.474,
            1.449,
            13.868,
            12.474,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2594",
        "polygon": [
            12.932,
            12.826,
            1.109,
            11.996,
            13.178,
            1.109,
            11.996,
            13.178,
            1.449,
            11.996,
            13.178,
            1.449,
            12.932,
            12.826,
            1.449,
            12.932,
            12.826,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2595",
        "polygon": [
            13.868,
            12.474,
            0.77,
            12.932,
            12.826,
            0.77,
            12.932,
            12.826,
            1.109,
            12.932,
            12.826,
            1.109,
            13.868,
            12.474,
            1.109,
            13.868,
            12.474,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2596",
        "polygon": [
            12.932,
            12.826,
            0.77,
            11.996,
            13.178,
            0.77,
            11.996,
            13.178,
            1.109,
            11.996,
            13.178,
            1.109,
            12.932,
            12.826,
            1.109,
            12.932,
            12.826,
            0.77
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2597",
        "polygon": [
            13.868,
            12.474,
            0.43,
            12.932,
            12.826,
            0.43,
            12.932,
            12.826,
            0.77,
            12.932,
            12.826,
            0.77,
            13.868,
            12.474,
            0.77,
            13.868,
            12.474,
            0.43
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2598",
        "polygon": [
            12.932,
            12.826,
            0.43,
            11.996,
            13.178,
            0.43,
            11.996,
            13.178,
            0.77,
            11.996,
            13.178,
            0.77,
            12.932,
            12.826,
            0.77,
            12.932,
            12.826,
            0.43
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2599",
        "polygon": [
            13.868,
            12.474,
            0.09,
            12.932,
            12.826,
            0.09,
            12.932,
            12.826,
            0.43,
            12.932,
            12.826,
            0.43,
            13.868,
            12.474,
            0.43,
            13.868,
            12.474,
            0.09
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2600",
        "polygon": [
            12.932,
            12.826,
            0.09,
            11.996,
            13.178,
            0.09,
            11.996,
            13.178,
            0.43,
            11.996,
            13.178,
            0.43,
            12.932,
            12.826,
            0.43,
            12.932,
            12.826,
            0.09
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2601",
        "polygon": [
            1.175,
            17.246,
            0.09,
            0.202,
            17.612,
            0.09,
            0.202,
            17.612,
            2.868,
            0.202,
            17.612,
            2.868,
            1.175,
            17.246,
            2.868,
            1.175,
            17.246,
            0.09
        ],
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2602",
        "polygon": [
            2.149,
            16.88,
            0.09,
            1.175,
            17.246,
            0.09,
            1.175,
            17.246,
            2.868,
            1.175,
            17.246,
            2.868,
            2.149,
            16.88,
            2.868,
            2.149,
            16.88,
            0.09
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2603",
        "polygon": [
            14.898,
            12.087,
            0.09,
            13.924,
            12.453,
            0.09,
            13.924,
            12.453,
            2.868,
            13.924,
            12.453,
            2.868,
            14.898,
            12.087,
            2.868,
            14.898,
            12.087,
            0.09
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2604",
        "polygon": [
            15.871,
            11.721,
            0.09,
            14.898,
            12.087,
            0.09,
            14.898,
            12.087,
            2.868,
            14.898,
            12.087,
            2.868,
            15.871,
            11.721,
            2.868,
            15.871,
            11.721,
            0.09
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2605",
        "polygon": [
            18.511,
            10.729,
            2.528,
            17.219,
            11.214,
            2.528,
            17.219,
            11.214,
            2.868,
            17.219,
            11.214,
            2.868,
            18.511,
            10.729,
            2.868,
            18.511,
            10.729,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2606",
        "polygon": [
            17.219,
            11.214,
            2.528,
            15.927,
            11.7,
            2.528,
            15.927,
            11.7,
            2.868,
            15.927,
            11.7,
            2.868,
            17.219,
            11.214,
            2.868,
            17.219,
            11.214,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2607",
        "polygon": [
            18.511,
            10.729,
            2.188,
            17.219,
            11.214,
            2.188,
            17.219,
            11.214,
            2.528,
            17.219,
            11.214,
            2.528,
            18.511,
            10.729,
            2.528,
            18.511,
            10.729,
            2.188
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2608",
        "polygon": [
            17.219,
            11.214,
            2.188,
            15.927,
            11.7,
            2.188,
            15.927,
            11.7,
            2.528,
            15.927,
            11.7,
            2.528,
            17.219,
            11.214,
            2.528,
            17.219,
            11.214,
            2.188
        ],
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2609",
        "polygon": [
            18.511,
            10.729,
            1.849,
            17.219,
            11.214,
            1.849,
            17.219,
            11.214,
            2.188,
            17.219,
            11.214,
            2.188,
            18.511,
            10.729,
            2.188,
            18.511,
            10.729,
            1.849
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2610",
        "polygon": [
            17.219,
            11.214,
            1.849,
            15.927,
            11.7,
            1.849,
            15.927,
            11.7,
            2.188,
            15.927,
            11.7,
            2.188,
            17.219,
            11.214,
            2.188,
            17.219,
            11.214,
            1.849
        ],
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2611",
        "polygon": [
            18.511,
            10.729,
            1.509,
            17.219,
            11.214,
            1.509,
            17.219,
            11.214,
            1.849,
            17.219,
            11.214,
            1.849,
            18.511,
            10.729,
            1.849,
            18.511,
            10.729,
            1.509
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2612",
        "polygon": [
            17.219,
            11.214,
            1.509,
            15.927,
            11.7,
            1.509,
            15.927,
            11.7,
            1.849,
            15.927,
            11.7,
            1.849,
            17.219,
            11.214,
            1.849,
            17.219,
            11.214,
            1.509
        ],
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2613",
        "polygon": [
            18.511,
            10.729,
            1.109,
            17.219,
            11.214,
            1.109,
            17.219,
            11.214,
            1.449,
            17.219,
            11.214,
            1.449,
            18.511,
            10.729,
            1.449,
            18.511,
            10.729,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2614",
        "polygon": [
            17.219,
            11.214,
            1.109,
            15.927,
            11.7,
            1.109,
            15.927,
            11.7,
            1.449,
            15.927,
            11.7,
            1.449,
            17.219,
            11.214,
            1.449,
            17.219,
            11.214,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2615",
        "polygon": [
            18.511,
            10.729,
            0.769,
            17.219,
            11.214,
            0.769,
            17.219,
            11.214,
            1.109,
            17.219,
            11.214,
            1.109,
            18.511,
            10.729,
            1.109,
            18.511,
            10.729,
            0.769
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2616",
        "polygon": [
            17.219,
            11.214,
            0.769,
            15.927,
            11.7,
            0.769,
            15.927,
            11.7,
            1.109,
            15.927,
            11.7,
            1.109,
            17.219,
            11.214,
            1.109,
            17.219,
            11.214,
            0.769
        ],
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2617",
        "polygon": [
            18.511,
            10.729,
            0.43,
            17.219,
            11.214,
            0.43,
            17.219,
            11.214,
            0.769,
            17.219,
            11.214,
            0.769,
            18.511,
            10.729,
            0.769,
            18.511,
            10.729,
            0.43
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2618",
        "polygon": [
            17.219,
            11.214,
            0.43,
            15.927,
            11.7,
            0.43,
            15.927,
            11.7,
            0.769,
            15.927,
            11.7,
            0.769,
            17.219,
            11.214,
            0.769,
            17.219,
            11.214,
            0.43
        ],
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2619",
        "polygon": [
            18.511,
            10.729,
            0.09,
            17.219,
            11.214,
            0.09,
            17.219,
            11.214,
            0.43,
            17.219,
            11.214,
            0.43,
            18.511,
            10.729,
            0.43,
            18.511,
            10.729,
            0.09
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2620",
        "polygon": [
            17.219,
            11.214,
            0.09,
            15.927,
            11.7,
            0.09,
            15.927,
            11.7,
            0.43,
            15.927,
            11.7,
            0.43,
            17.219,
            11.214,
            0.43,
            17.219,
            11.214,
            0.09
        ],
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2621",
        "polygon": [
            19.264,
            10.445,
            2.528,
            18.511,
            10.729,
            2.528,
            18.511,
            10.729,
            2.868,
            18.511,
            10.729,
            2.868,
            19.264,
            10.445,
            2.868,
            19.264,
            10.445,
            2.528
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2622",
        "polygon": [
            19.264,
            10.445,
            2.189,
            18.511,
            10.729,
            2.189,
            18.511,
            10.729,
            2.528,
            18.511,
            10.729,
            2.528,
            19.264,
            10.445,
            2.528,
            19.264,
            10.445,
            2.189
        ],
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2623",
        "polygon": [
            19.264,
            10.445,
            1.849,
            18.511,
            10.729,
            1.849,
            18.511,
            10.729,
            2.189,
            18.511,
            10.729,
            2.189,
            19.264,
            10.445,
            2.189,
            19.264,
            10.445,
            1.849
        ],
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2624",
        "polygon": [
            19.264,
            10.445,
            1.509,
            18.511,
            10.729,
            1.509,
            18.511,
            10.729,
            1.849,
            18.511,
            10.729,
            1.849,
            19.264,
            10.445,
            1.849,
            19.264,
            10.445,
            1.509
        ],
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2625",
        "polygon": [
            19.264,
            10.445,
            1.109,
            18.511,
            10.729,
            1.109,
            18.511,
            10.729,
            1.449,
            18.511,
            10.729,
            1.449,
            19.264,
            10.445,
            1.449,
            19.264,
            10.445,
            1.109
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2626",
        "polygon": [
            19.264,
            10.445,
            0.77,
            18.511,
            10.729,
            0.77,
            18.511,
            10.729,
            1.109,
            18.511,
            10.729,
            1.109,
            19.264,
            10.445,
            1.109,
            19.264,
            10.445,
            0.77
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2627",
        "polygon": [
            19.264,
            10.445,
            0.43,
            18.511,
            10.729,
            0.43,
            18.511,
            10.729,
            0.77,
            18.511,
            10.729,
            0.77,
            19.264,
            10.445,
            0.77,
            19.264,
            10.445,
            0.43
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2628",
        "polygon": [
            19.264,
            10.445,
            0.09,
            18.511,
            10.729,
            0.09,
            18.511,
            10.729,
            0.43,
            18.511,
            10.729,
            0.43,
            19.264,
            10.445,
            0.43,
            19.264,
            10.445,
            0.09
        ],
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2629",
        "polygon": [
            -1.146,
            18.119,
            1.849,
            -2.438,
            18.604,
            1.849,
            -2.438,
            18.604,
            2.188,
            -2.438,
            18.604,
            2.188,
            -1.146,
            18.119,
            2.188,
            -1.146,
            18.119,
            1.849
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_2630",
        "polygon": [
            -1.146,
            18.119,
            0.43,
            -2.438,
            18.604,
            0.43,
            -2.438,
            18.604,
            0.769,
            -2.438,
            18.604,
            0.769,
            -1.146,
            18.119,
            0.769,
            -1.146,
            18.119,
            0.43
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2631",
        "polygon": [
            -68.874,
            51.932,
            4.202,
            -69.173,
            51.138,
            4.202,
            -69.173,
            51.138,
            7.408,
            -69.173,
            51.138,
            7.408,
            -68.874,
            51.932,
            7.408,
            -68.874,
            51.932,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2632",
        "polygon": [
            -68.717,
            52.351,
            6.988,
            -68.859,
            51.972,
            6.988,
            -68.859,
            51.972,
            7.408,
            -68.859,
            51.972,
            7.408,
            -68.717,
            52.351,
            7.408,
            -68.717,
            52.351,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2633",
        "polygon": [
            -68.717,
            52.351,
            4.14,
            -68.859,
            51.972,
            4.14,
            -68.859,
            51.972,
            6.988,
            -68.859,
            51.972,
            6.988,
            -68.717,
            52.351,
            6.988,
            -68.717,
            52.351,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2634",
        "polygon": [
            -68.224,
            53.661,
            4.202,
            -68.68,
            52.448,
            4.202,
            -68.68,
            52.448,
            7.408,
            -68.68,
            52.448,
            7.408,
            -68.224,
            53.661,
            7.408,
            -68.224,
            53.661,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2635",
        "polygon": [
            -68.874,
            51.932,
            11.622,
            -69.173,
            51.138,
            11.622,
            -69.173,
            51.138,
            14.828,
            -69.173,
            51.138,
            14.828,
            -68.874,
            51.932,
            14.828,
            -68.874,
            51.932,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2636",
        "polygon": [
            -68.717,
            52.351,
            14.408,
            -68.859,
            51.972,
            14.408,
            -68.859,
            51.972,
            14.828,
            -68.859,
            51.972,
            14.828,
            -68.717,
            52.351,
            14.828,
            -68.717,
            52.351,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2637",
        "polygon": [
            -68.717,
            52.351,
            11.56,
            -68.859,
            51.972,
            11.56,
            -68.859,
            51.972,
            14.408,
            -68.859,
            51.972,
            14.408,
            -68.717,
            52.351,
            14.408,
            -68.717,
            52.351,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2638",
        "polygon": [
            -68.224,
            53.661,
            11.622,
            -68.68,
            52.448,
            11.622,
            -68.68,
            52.448,
            14.828,
            -68.68,
            52.448,
            14.828,
            -68.224,
            53.661,
            14.828,
            -68.224,
            53.661,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2639",
        "polygon": [
            -68.717,
            52.351,
            7.912,
            -69.016,
            51.556,
            7.912,
            -69.016,
            51.556,
            11.118,
            -69.016,
            51.556,
            11.118,
            -68.717,
            52.351,
            11.118,
            -68.717,
            52.351,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2640",
        "polygon": [
            -68.224,
            53.661,
            7.912,
            -68.68,
            52.448,
            7.912,
            -68.68,
            52.448,
            11.118,
            -68.68,
            52.448,
            11.118,
            -68.224,
            53.661,
            11.118,
            -68.224,
            53.661,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2641",
        "polygon": [
            -69.03,
            51.517,
            10.698,
            -69.173,
            51.138,
            10.698,
            -69.173,
            51.138,
            11.118,
            -69.173,
            51.138,
            11.118,
            -69.03,
            51.517,
            11.118,
            -69.03,
            51.517,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2642",
        "polygon": [
            -69.03,
            51.517,
            7.85,
            -69.173,
            51.138,
            7.85,
            -69.173,
            51.138,
            10.698,
            -69.173,
            51.138,
            10.698,
            -69.03,
            51.517,
            10.698,
            -69.03,
            51.517,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2643",
        "polygon": [
            -67.889,
            54.553,
            4.202,
            -68.188,
            53.758,
            4.202,
            -68.188,
            53.758,
            7.408,
            -68.188,
            53.758,
            7.408,
            -67.889,
            54.553,
            7.408,
            -67.889,
            54.553,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2644",
        "polygon": [
            -67.731,
            54.972,
            6.988,
            -67.874,
            54.592,
            6.988,
            -67.874,
            54.592,
            7.408,
            -67.874,
            54.592,
            7.408,
            -67.731,
            54.972,
            7.408,
            -67.731,
            54.972,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2645",
        "polygon": [
            -67.731,
            54.972,
            4.14,
            -67.874,
            54.592,
            4.14,
            -67.874,
            54.592,
            6.988,
            -67.874,
            54.592,
            6.988,
            -67.731,
            54.972,
            6.988,
            -67.731,
            54.972,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2646",
        "polygon": [
            -67.239,
            56.282,
            4.202,
            -67.695,
            55.069,
            4.202,
            -67.695,
            55.069,
            7.408,
            -67.695,
            55.069,
            7.408,
            -67.239,
            56.282,
            7.408,
            -67.239,
            56.282,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2647",
        "polygon": [
            -67.889,
            54.553,
            11.622,
            -68.188,
            53.758,
            11.622,
            -68.188,
            53.758,
            14.828,
            -68.188,
            53.758,
            14.828,
            -67.889,
            54.553,
            14.828,
            -67.889,
            54.553,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2648",
        "polygon": [
            -67.731,
            54.972,
            14.408,
            -67.874,
            54.592,
            14.408,
            -67.874,
            54.592,
            14.828,
            -67.874,
            54.592,
            14.828,
            -67.731,
            54.972,
            14.828,
            -67.731,
            54.972,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2649",
        "polygon": [
            -67.731,
            54.972,
            11.56,
            -67.874,
            54.592,
            11.56,
            -67.874,
            54.592,
            14.408,
            -67.874,
            54.592,
            14.408,
            -67.731,
            54.972,
            14.408,
            -67.731,
            54.972,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2650",
        "polygon": [
            -67.239,
            56.282,
            11.622,
            -67.695,
            55.069,
            11.622,
            -67.695,
            55.069,
            14.828,
            -67.695,
            55.069,
            14.828,
            -67.239,
            56.282,
            14.828,
            -67.239,
            56.282,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2651",
        "polygon": [
            -67.731,
            54.972,
            7.912,
            -68.03,
            54.177,
            7.912,
            -68.03,
            54.177,
            11.118,
            -68.03,
            54.177,
            11.118,
            -67.731,
            54.972,
            11.118,
            -67.731,
            54.972,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2652",
        "polygon": [
            -67.239,
            56.282,
            7.912,
            -67.695,
            55.069,
            7.912,
            -67.695,
            55.069,
            11.118,
            -67.695,
            55.069,
            11.118,
            -67.239,
            56.282,
            11.118,
            -67.239,
            56.282,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2653",
        "polygon": [
            -68.045,
            54.138,
            10.698,
            -68.188,
            53.758,
            10.698,
            -68.188,
            53.758,
            11.118,
            -68.188,
            53.758,
            11.118,
            -68.045,
            54.138,
            11.118,
            -68.045,
            54.138,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2654",
        "polygon": [
            -68.045,
            54.138,
            7.85,
            -68.188,
            53.758,
            7.85,
            -68.188,
            53.758,
            10.698,
            -68.188,
            53.758,
            10.698,
            -68.045,
            54.138,
            10.698,
            -68.045,
            54.138,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2655",
        "polygon": [
            -66.903,
            57.174,
            4.202,
            -67.202,
            56.379,
            4.202,
            -67.202,
            56.379,
            7.408,
            -67.202,
            56.379,
            7.408,
            -66.903,
            57.174,
            7.408,
            -66.903,
            57.174,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2656",
        "polygon": [
            -66.746,
            57.592,
            6.988,
            -66.889,
            57.213,
            6.988,
            -66.889,
            57.213,
            7.408,
            -66.889,
            57.213,
            7.408,
            -66.746,
            57.592,
            7.408,
            -66.746,
            57.592,
            6.988
        ],
        "05-24 07:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2657",
        "polygon": [
            -66.746,
            57.592,
            4.14,
            -66.889,
            57.213,
            4.14,
            -66.889,
            57.213,
            6.988,
            -66.889,
            57.213,
            6.988,
            -66.746,
            57.592,
            6.988,
            -66.746,
            57.592,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2658",
        "polygon": [
            -66.253,
            58.903,
            4.202,
            -66.709,
            57.69,
            4.202,
            -66.709,
            57.69,
            7.408,
            -66.709,
            57.69,
            7.408,
            -66.253,
            58.903,
            7.408,
            -66.253,
            58.903,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2659",
        "polygon": [
            -66.903,
            57.174,
            11.622,
            -67.202,
            56.379,
            11.622,
            -67.202,
            56.379,
            14.828,
            -67.202,
            56.379,
            14.828,
            -66.903,
            57.174,
            14.828,
            -66.903,
            57.174,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2660",
        "polygon": [
            -66.746,
            57.592,
            14.408,
            -66.889,
            57.213,
            14.408,
            -66.889,
            57.213,
            14.828,
            -66.889,
            57.213,
            14.828,
            -66.746,
            57.592,
            14.828,
            -66.746,
            57.592,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2661",
        "polygon": [
            -66.746,
            57.592,
            11.56,
            -66.889,
            57.213,
            11.56,
            -66.889,
            57.213,
            14.408,
            -66.889,
            57.213,
            14.408,
            -66.746,
            57.592,
            14.408,
            -66.746,
            57.592,
            11.56
        ],
        "05-24 06:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2662",
        "polygon": [
            -66.253,
            58.903,
            11.622,
            -66.709,
            57.69,
            11.622,
            -66.709,
            57.69,
            14.828,
            -66.709,
            57.69,
            14.828,
            -66.253,
            58.903,
            14.828,
            -66.253,
            58.903,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2663",
        "polygon": [
            -66.746,
            57.592,
            7.912,
            -67.045,
            56.798,
            7.912,
            -67.045,
            56.798,
            11.118,
            -67.045,
            56.798,
            11.118,
            -66.746,
            57.592,
            11.118,
            -66.746,
            57.592,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2664",
        "polygon": [
            -66.253,
            58.903,
            7.912,
            -66.709,
            57.69,
            7.912,
            -66.709,
            57.69,
            11.118,
            -66.709,
            57.69,
            11.118,
            -66.253,
            58.903,
            11.118,
            -66.253,
            58.903,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2665",
        "polygon": [
            -67.06,
            56.758,
            10.698,
            -67.202,
            56.379,
            10.698,
            -67.202,
            56.379,
            11.118,
            -67.202,
            56.379,
            11.118,
            -67.06,
            56.758,
            11.118,
            -67.06,
            56.758,
            10.698
        ],
        "05-24 06:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2666",
        "polygon": [
            -67.06,
            56.758,
            7.85,
            -67.202,
            56.379,
            7.85,
            -67.202,
            56.379,
            10.698,
            -67.202,
            56.379,
            10.698,
            -67.06,
            56.758,
            10.698,
            -67.06,
            56.758,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2667",
        "polygon": [
            -65.918,
            59.795,
            4.202,
            -66.217,
            59.0,
            4.202,
            -66.217,
            59.0,
            7.408,
            -66.217,
            59.0,
            7.408,
            -65.918,
            59.795,
            7.408,
            -65.918,
            59.795,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2668",
        "polygon": [
            -65.761,
            60.213,
            6.988,
            -65.903,
            59.834,
            6.988,
            -65.903,
            59.834,
            7.408,
            -65.903,
            59.834,
            7.408,
            -65.761,
            60.213,
            7.408,
            -65.761,
            60.213,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2669",
        "polygon": [
            -65.761,
            60.213,
            4.14,
            -65.903,
            59.834,
            4.14,
            -65.903,
            59.834,
            6.988,
            -65.903,
            59.834,
            6.988,
            -65.761,
            60.213,
            6.988,
            -65.761,
            60.213,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2670",
        "polygon": [
            -65.356,
            61.29,
            4.202,
            -65.724,
            60.311,
            4.202,
            -65.724,
            60.311,
            7.408,
            -65.724,
            60.311,
            7.408,
            -65.356,
            61.29,
            7.408,
            -65.356,
            61.29,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2671",
        "polygon": [
            -65.918,
            59.795,
            11.622,
            -66.217,
            59.0,
            11.622,
            -66.217,
            59.0,
            14.828,
            -66.217,
            59.0,
            14.828,
            -65.918,
            59.795,
            14.828,
            -65.918,
            59.795,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2672",
        "polygon": [
            -65.761,
            60.213,
            14.408,
            -65.903,
            59.834,
            14.408,
            -65.903,
            59.834,
            14.828,
            -65.903,
            59.834,
            14.828,
            -65.761,
            60.213,
            14.828,
            -65.761,
            60.213,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2673",
        "polygon": [
            -65.761,
            60.213,
            11.56,
            -65.903,
            59.834,
            11.56,
            -65.903,
            59.834,
            14.408,
            -65.903,
            59.834,
            14.408,
            -65.761,
            60.213,
            14.408,
            -65.761,
            60.213,
            11.56
        ],
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2675",
        "polygon": [
            -65.761,
            60.213,
            7.912,
            -66.06,
            59.419,
            7.912,
            -66.06,
            59.419,
            11.118,
            -66.06,
            59.419,
            11.118,
            -65.761,
            60.213,
            11.118,
            -65.761,
            60.213,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2677",
        "polygon": [
            -66.074,
            59.379,
            10.698,
            -66.217,
            59.0,
            10.698,
            -66.217,
            59.0,
            11.118,
            -66.217,
            59.0,
            11.118,
            -66.074,
            59.379,
            11.118,
            -66.074,
            59.379,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2678",
        "polygon": [
            -66.074,
            59.379,
            7.85,
            -66.217,
            59.0,
            7.85,
            -66.217,
            59.0,
            10.698,
            -66.217,
            59.0,
            10.698,
            -66.074,
            59.379,
            10.698,
            -66.074,
            59.379,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2679",
        "polygon": [
            -65.356,
            61.29,
            7.912,
            -65.724,
            60.311,
            7.912,
            -65.724,
            60.311,
            11.118,
            -65.724,
            60.311,
            11.118,
            -65.356,
            61.29,
            11.118,
            -65.356,
            61.29,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2680",
        "polygon": [
            -65.356,
            61.29,
            11.622,
            -65.724,
            60.311,
            11.622,
            -65.724,
            60.311,
            14.828,
            -65.724,
            60.311,
            14.828,
            -65.356,
            61.29,
            14.828,
            -65.356,
            61.29,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2681",
        "polygon": [
            -69.21,
            51.04,
            7.912,
            -69.791,
            49.495,
            7.912,
            -69.791,
            49.495,
            11.118,
            -69.791,
            49.495,
            11.118,
            -69.21,
            51.04,
            11.118,
            -69.21,
            51.04,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2682",
        "polygon": [
            -69.21,
            51.04,
            11.622,
            -69.791,
            49.495,
            11.622,
            -69.791,
            49.495,
            14.828,
            -69.791,
            49.495,
            14.828,
            -69.21,
            51.04,
            14.828,
            -69.21,
            51.04,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2683",
        "polygon": [
            -69.21,
            51.04,
            4.202,
            -69.791,
            49.495,
            4.202,
            -69.791,
            49.495,
            7.408,
            -69.791,
            49.495,
            7.408,
            -69.21,
            51.04,
            7.408,
            -69.21,
            51.04,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2684",
        "polygon": [
            -70.053,
            48.796,
            4.11,
            -70.462,
            47.71,
            4.11,
            -70.462,
            47.71,
            7.06,
            -70.462,
            47.71,
            7.06,
            -70.053,
            48.796,
            7.06,
            -70.053,
            48.796,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2685",
        "polygon": [
            -69.89,
            49.231,
            4.11,
            -70.053,
            48.796,
            4.11,
            -70.053,
            48.796,
            7.06,
            -70.053,
            48.796,
            7.06,
            -69.89,
            49.231,
            7.06,
            -69.89,
            49.231,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2686",
        "polygon": [
            -70.479,
            47.663,
            4.16,
            -70.608,
            47.321,
            4.16,
            -70.608,
            47.321,
            7.06,
            -70.608,
            47.321,
            7.06,
            -70.479,
            47.663,
            7.06,
            -70.479,
            47.663,
            4.16
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2687",
        "polygon": [
            -70.053,
            48.796,
            7.82,
            -70.462,
            47.71,
            7.82,
            -70.462,
            47.71,
            10.77,
            -70.462,
            47.71,
            10.77,
            -70.053,
            48.796,
            10.77,
            -70.053,
            48.796,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2688",
        "polygon": [
            -69.89,
            49.231,
            7.82,
            -70.053,
            48.796,
            7.82,
            -70.053,
            48.796,
            10.77,
            -70.053,
            48.796,
            10.77,
            -69.89,
            49.231,
            10.77,
            -69.89,
            49.231,
            7.82
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2689",
        "polygon": [
            -70.479,
            47.663,
            7.87,
            -70.608,
            47.321,
            7.87,
            -70.608,
            47.321,
            10.77,
            -70.608,
            47.321,
            10.77,
            -70.479,
            47.663,
            10.77,
            -70.479,
            47.663,
            7.87
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2690",
        "polygon": [
            -70.053,
            48.796,
            11.53,
            -70.462,
            47.71,
            11.53,
            -70.462,
            47.71,
            14.48,
            -70.462,
            47.71,
            14.48,
            -70.053,
            48.796,
            14.48,
            -70.053,
            48.796,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2691",
        "polygon": [
            -69.89,
            49.231,
            11.53,
            -70.053,
            48.796,
            11.53,
            -70.053,
            48.796,
            14.48,
            -70.053,
            48.796,
            14.48,
            -69.89,
            49.231,
            14.48,
            -69.89,
            49.231,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2692",
        "polygon": [
            -70.479,
            47.663,
            11.58,
            -70.608,
            47.321,
            11.58,
            -70.608,
            47.321,
            14.48,
            -70.608,
            47.321,
            14.48,
            -70.479,
            47.663,
            14.48,
            -70.479,
            47.663,
            11.58
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2714",
        "polygon": [
            -69.987,
            48.461,
            16.21,
            -69.526,
            49.687,
            16.21,
            -69.526,
            49.687,
            16.54,
            -69.526,
            49.687,
            16.54,
            -69.987,
            48.461,
            16.54,
            -69.987,
            48.461,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2715",
        "polygon": [
            -69.987,
            48.461,
            16.54,
            -69.526,
            49.687,
            16.54,
            -69.526,
            49.687,
            16.87,
            -69.526,
            49.687,
            16.87,
            -69.987,
            48.461,
            16.87,
            -69.987,
            48.461,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2716",
        "polygon": [
            -69.987,
            48.461,
            15.88,
            -69.526,
            49.687,
            15.88,
            -69.526,
            49.687,
            16.21,
            -69.526,
            49.687,
            16.21,
            -69.987,
            48.461,
            16.21,
            -69.987,
            48.461,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2717",
        "polygon": [
            -70.481,
            47.148,
            16.21,
            -70.02,
            48.374,
            16.21,
            -70.02,
            48.374,
            16.54,
            -70.02,
            48.374,
            16.54,
            -70.481,
            47.148,
            16.54,
            -70.481,
            47.148,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2718",
        "polygon": [
            -70.481,
            47.148,
            16.54,
            -70.02,
            48.374,
            16.54,
            -70.02,
            48.374,
            16.87,
            -70.02,
            48.374,
            16.87,
            -70.481,
            47.148,
            16.87,
            -70.481,
            47.148,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2719",
        "polygon": [
            -70.481,
            47.148,
            15.88,
            -70.02,
            48.374,
            15.88,
            -70.02,
            48.374,
            16.21,
            -70.02,
            48.374,
            16.21,
            -70.481,
            47.148,
            16.21,
            -70.481,
            47.148,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2720",
        "polygon": [
            -69.506,
            49.741,
            16.21,
            -69.045,
            50.967,
            16.21,
            -69.045,
            50.967,
            16.54,
            -69.045,
            50.967,
            16.54,
            -69.506,
            49.741,
            16.54,
            -69.506,
            49.741,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2721",
        "polygon": [
            -69.506,
            49.741,
            16.54,
            -69.045,
            50.967,
            16.54,
            -69.045,
            50.967,
            16.87,
            -69.045,
            50.967,
            16.87,
            -69.506,
            49.741,
            16.87,
            -69.506,
            49.741,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2722",
        "polygon": [
            -69.506,
            49.741,
            15.88,
            -69.045,
            50.967,
            15.88,
            -69.045,
            50.967,
            16.21,
            -69.045,
            50.967,
            16.21,
            -69.506,
            49.741,
            16.21,
            -69.506,
            49.741,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2723",
        "polygon": [
            -69.001,
            51.084,
            16.21,
            -68.54,
            52.31,
            16.21,
            -68.54,
            52.31,
            16.54,
            -68.54,
            52.31,
            16.54,
            -69.001,
            51.084,
            16.54,
            -69.001,
            51.084,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2724",
        "polygon": [
            -69.001,
            51.084,
            16.54,
            -68.54,
            52.31,
            16.54,
            -68.54,
            52.31,
            16.87,
            -68.54,
            52.31,
            16.87,
            -69.001,
            51.084,
            16.87,
            -69.001,
            51.084,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2725",
        "polygon": [
            -69.001,
            51.084,
            15.88,
            -68.54,
            52.31,
            15.88,
            -68.54,
            52.31,
            16.21,
            -68.54,
            52.31,
            16.21,
            -69.001,
            51.084,
            16.21,
            -69.001,
            51.084,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2726",
        "polygon": [
            -68.52,
            52.361,
            16.21,
            -68.059,
            53.588,
            16.21,
            -68.059,
            53.588,
            16.54,
            -68.059,
            53.588,
            16.54,
            -68.52,
            52.361,
            16.54,
            -68.52,
            52.361,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2727",
        "polygon": [
            -68.52,
            52.361,
            16.54,
            -68.059,
            53.588,
            16.54,
            -68.059,
            53.588,
            16.87,
            -68.059,
            53.588,
            16.87,
            -68.52,
            52.361,
            16.87,
            -68.52,
            52.361,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2728",
        "polygon": [
            -68.52,
            52.361,
            15.88,
            -68.059,
            53.588,
            15.88,
            -68.059,
            53.588,
            16.21,
            -68.059,
            53.588,
            16.21,
            -68.52,
            52.361,
            16.21,
            -68.52,
            52.361,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2729",
        "polygon": [
            -68.015,
            53.705,
            16.21,
            -67.554,
            54.931,
            16.21,
            -67.554,
            54.931,
            16.54,
            -67.554,
            54.931,
            16.54,
            -68.015,
            53.705,
            16.54,
            -68.015,
            53.705,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2730",
        "polygon": [
            -68.015,
            53.705,
            16.54,
            -67.554,
            54.931,
            16.54,
            -67.554,
            54.931,
            16.87,
            -67.554,
            54.931,
            16.87,
            -68.015,
            53.705,
            16.87,
            -68.015,
            53.705,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2731",
        "polygon": [
            -68.015,
            53.705,
            15.88,
            -67.554,
            54.931,
            15.88,
            -67.554,
            54.931,
            16.21,
            -67.554,
            54.931,
            16.21,
            -68.015,
            53.705,
            16.21,
            -68.015,
            53.705,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2732",
        "polygon": [
            -67.535,
            54.982,
            16.21,
            -67.074,
            56.209,
            16.21,
            -67.074,
            56.209,
            16.54,
            -67.074,
            56.209,
            16.54,
            -67.535,
            54.982,
            16.54,
            -67.535,
            54.982,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2733",
        "polygon": [
            -67.535,
            54.982,
            16.54,
            -67.074,
            56.209,
            16.54,
            -67.074,
            56.209,
            16.87,
            -67.074,
            56.209,
            16.87,
            -67.535,
            54.982,
            16.87,
            -67.535,
            54.982,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2734",
        "polygon": [
            -67.535,
            54.982,
            15.88,
            -67.074,
            56.209,
            15.88,
            -67.074,
            56.209,
            16.21,
            -67.074,
            56.209,
            16.21,
            -67.535,
            54.982,
            16.21,
            -67.535,
            54.982,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2735",
        "polygon": [
            -67.03,
            56.326,
            16.21,
            -66.569,
            57.552,
            16.21,
            -66.569,
            57.552,
            16.54,
            -66.569,
            57.552,
            16.54,
            -67.03,
            56.326,
            16.54,
            -67.03,
            56.326,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2736",
        "polygon": [
            -67.03,
            56.326,
            16.54,
            -66.569,
            57.552,
            16.54,
            -66.569,
            57.552,
            16.87,
            -66.569,
            57.552,
            16.87,
            -67.03,
            56.326,
            16.87,
            -67.03,
            56.326,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2737",
        "polygon": [
            -67.03,
            56.326,
            15.88,
            -66.569,
            57.552,
            15.88,
            -66.569,
            57.552,
            16.21,
            -66.569,
            57.552,
            16.21,
            -67.03,
            56.326,
            16.21,
            -67.03,
            56.326,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2738",
        "polygon": [
            -66.55,
            57.603,
            16.21,
            -66.089,
            58.829,
            16.21,
            -66.089,
            58.829,
            16.54,
            -66.089,
            58.829,
            16.54,
            -66.55,
            57.603,
            16.54,
            -66.55,
            57.603,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2739",
        "polygon": [
            -66.55,
            57.603,
            16.54,
            -66.089,
            58.829,
            16.54,
            -66.089,
            58.829,
            16.87,
            -66.089,
            58.829,
            16.87,
            -66.55,
            57.603,
            16.87,
            -66.55,
            57.603,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2740",
        "polygon": [
            -66.55,
            57.603,
            15.88,
            -66.089,
            58.829,
            15.88,
            -66.089,
            58.829,
            16.21,
            -66.089,
            58.829,
            16.21,
            -66.55,
            57.603,
            16.21,
            -66.55,
            57.603,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2741",
        "polygon": [
            -66.045,
            58.947,
            16.21,
            -65.584,
            60.173,
            16.21,
            -65.584,
            60.173,
            16.54,
            -65.584,
            60.173,
            16.54,
            -66.045,
            58.947,
            16.54,
            -66.045,
            58.947,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2742",
        "polygon": [
            -66.045,
            58.947,
            16.54,
            -65.584,
            60.173,
            16.54,
            -65.584,
            60.173,
            16.87,
            -65.584,
            60.173,
            16.87,
            -66.045,
            58.947,
            16.87,
            -66.045,
            58.947,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2743",
        "polygon": [
            -66.045,
            58.947,
            15.88,
            -65.584,
            60.173,
            15.88,
            -65.584,
            60.173,
            16.21,
            -65.584,
            60.173,
            16.21,
            -66.045,
            58.947,
            16.21,
            -66.045,
            58.947,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2744",
        "polygon": [
            -65.548,
            60.269,
            16.21,
            -65.087,
            61.495,
            16.21,
            -65.087,
            61.495,
            16.54,
            -65.087,
            61.495,
            16.54,
            -65.548,
            60.269,
            16.54,
            -65.548,
            60.269,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2745",
        "polygon": [
            -65.548,
            60.269,
            16.54,
            -65.087,
            61.495,
            16.54,
            -65.087,
            61.495,
            16.87,
            -65.087,
            61.495,
            16.87,
            -65.548,
            60.269,
            16.87,
            -65.548,
            60.269,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2746",
        "polygon": [
            -65.548,
            60.269,
            15.88,
            -65.087,
            61.495,
            15.88,
            -65.087,
            61.495,
            16.21,
            -65.087,
            61.495,
            16.21,
            -65.548,
            60.269,
            16.21,
            -65.548,
            60.269,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_17",
        "polygon": [
            -59.565,
            17.417,
            0.142,
            -59.045,
            18.801,
            0.142,
            -59.045,
            18.801,
            3.698,
            -59.045,
            18.801,
            3.698,
            -59.565,
            17.417,
            3.698,
            -59.565,
            17.417,
            0.142
        ],
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_15",
        "polygon": [
            -58.515,
            20.209,
            0.142,
            -58.059,
            21.422,
            0.142,
            -58.059,
            21.422,
            3.698,
            -58.059,
            21.422,
            3.698,
            -58.515,
            20.209,
            3.698,
            -58.515,
            20.209,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_13",
        "polygon": [
            -57.53,
            22.83,
            0.142,
            -57.074,
            24.043,
            0.142,
            -57.074,
            24.043,
            3.698,
            -57.074,
            24.043,
            3.698,
            -57.53,
            22.83,
            3.698,
            -57.53,
            22.83,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_11",
        "polygon": [
            -56.545,
            25.45,
            0.142,
            -56.089,
            26.664,
            0.142,
            -56.089,
            26.664,
            3.698,
            -56.089,
            26.664,
            3.698,
            -56.545,
            25.45,
            3.698,
            -56.545,
            25.45,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_9",
        "polygon": [
            -55.559,
            28.071,
            0.142,
            -55.103,
            29.284,
            0.142,
            -55.103,
            29.284,
            3.698,
            -55.103,
            29.284,
            3.698,
            -55.559,
            28.071,
            3.698,
            -55.559,
            28.071,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_7",
        "polygon": [
            -54.574,
            30.692,
            0.142,
            -54.118,
            31.905,
            0.142,
            -54.118,
            31.905,
            3.698,
            -54.118,
            31.905,
            3.698,
            -54.574,
            30.692,
            3.698,
            -54.574,
            30.692,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_5",
        "polygon": [
            -53.589,
            33.313,
            0.142,
            -53.133,
            34.526,
            0.142,
            -53.133,
            34.526,
            3.698,
            -53.133,
            34.526,
            3.698,
            -53.589,
            33.313,
            3.698,
            -53.589,
            33.313,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_3",
        "polygon": [
            -52.603,
            35.934,
            0.142,
            -52.147,
            37.147,
            0.142,
            -52.147,
            37.147,
            3.698,
            -52.147,
            37.147,
            3.698,
            -52.603,
            35.934,
            3.698,
            -52.603,
            35.934,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_1",
        "polygon": [
            -51.618,
            38.555,
            0.142,
            -51.197,
            39.674,
            0.142,
            -51.197,
            39.674,
            3.698,
            -51.197,
            39.674,
            3.698,
            -51.618,
            38.555,
            3.698,
            -51.618,
            38.555,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_17",
        "polygon": [
            -59.565,
            17.417,
            4.202,
            -59.045,
            18.801,
            4.202,
            -59.045,
            18.801,
            7.408,
            -59.045,
            18.801,
            7.408,
            -59.565,
            17.417,
            7.408,
            -59.565,
            17.417,
            4.202
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_15",
        "polygon": [
            -58.515,
            20.209,
            4.202,
            -58.059,
            21.422,
            4.202,
            -58.059,
            21.422,
            7.408,
            -58.059,
            21.422,
            7.408,
            -58.515,
            20.209,
            7.408,
            -58.515,
            20.209,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_1",
        "polygon": [
            -51.618,
            38.555,
            4.202,
            -51.197,
            39.674,
            4.202,
            -51.197,
            39.674,
            7.408,
            -51.197,
            39.674,
            7.408,
            -51.618,
            38.555,
            7.408,
            -51.618,
            38.555,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_13",
        "polygon": [
            -57.53,
            22.83,
            4.202,
            -57.074,
            24.043,
            4.202,
            -57.074,
            24.043,
            7.408,
            -57.074,
            24.043,
            7.408,
            -57.53,
            22.83,
            7.408,
            -57.53,
            22.83,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_11",
        "polygon": [
            -56.545,
            25.45,
            4.202,
            -56.089,
            26.664,
            4.202,
            -56.089,
            26.664,
            7.408,
            -56.089,
            26.664,
            7.408,
            -56.545,
            25.45,
            7.408,
            -56.545,
            25.45,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_9",
        "polygon": [
            -55.559,
            28.071,
            4.202,
            -55.103,
            29.284,
            4.202,
            -55.103,
            29.284,
            7.408,
            -55.103,
            29.284,
            7.408,
            -55.559,
            28.071,
            7.408,
            -55.559,
            28.071,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_7",
        "polygon": [
            -54.574,
            30.692,
            4.202,
            -54.118,
            31.905,
            4.202,
            -54.118,
            31.905,
            7.408,
            -54.118,
            31.905,
            7.408,
            -54.574,
            30.692,
            7.408,
            -54.574,
            30.692,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_5",
        "polygon": [
            -53.589,
            33.313,
            4.202,
            -53.133,
            34.526,
            4.202,
            -53.133,
            34.526,
            7.408,
            -53.133,
            34.526,
            7.408,
            -53.589,
            33.313,
            7.408,
            -53.589,
            33.313,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_3",
        "polygon": [
            -52.603,
            35.934,
            4.202,
            -52.147,
            37.147,
            4.202,
            -52.147,
            37.147,
            7.408,
            -52.147,
            37.147,
            7.408,
            -52.603,
            35.934,
            7.408,
            -52.603,
            35.934,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_17",
        "polygon": [
            -59.565,
            17.417,
            11.622,
            -59.045,
            18.801,
            11.622,
            -59.045,
            18.801,
            14.828,
            -59.045,
            18.801,
            14.828,
            -59.565,
            17.417,
            14.828,
            -59.565,
            17.417,
            11.622
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_15",
        "polygon": [
            -58.515,
            20.209,
            11.622,
            -58.059,
            21.422,
            11.622,
            -58.059,
            21.422,
            14.828,
            -58.059,
            21.422,
            14.828,
            -58.515,
            20.209,
            14.828,
            -58.515,
            20.209,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_1",
        "polygon": [
            -51.618,
            38.555,
            11.622,
            -51.197,
            39.674,
            11.622,
            -51.197,
            39.674,
            14.828,
            -51.197,
            39.674,
            14.828,
            -51.618,
            38.555,
            14.828,
            -51.618,
            38.555,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_13",
        "polygon": [
            -57.53,
            22.83,
            11.622,
            -57.074,
            24.043,
            11.622,
            -57.074,
            24.043,
            14.828,
            -57.074,
            24.043,
            14.828,
            -57.53,
            22.83,
            14.828,
            -57.53,
            22.83,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_11",
        "polygon": [
            -56.545,
            25.45,
            11.622,
            -56.089,
            26.664,
            11.622,
            -56.089,
            26.664,
            14.828,
            -56.089,
            26.664,
            14.828,
            -56.545,
            25.45,
            14.828,
            -56.545,
            25.45,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_9",
        "polygon": [
            -55.559,
            28.071,
            11.622,
            -55.103,
            29.284,
            11.622,
            -55.103,
            29.284,
            14.828,
            -55.103,
            29.284,
            14.828,
            -55.559,
            28.071,
            14.828,
            -55.559,
            28.071,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_7",
        "polygon": [
            -54.574,
            30.692,
            11.622,
            -54.118,
            31.905,
            11.622,
            -54.118,
            31.905,
            14.828,
            -54.118,
            31.905,
            14.828,
            -54.574,
            30.692,
            14.828,
            -54.574,
            30.692,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_5",
        "polygon": [
            -53.589,
            33.313,
            11.622,
            -53.133,
            34.526,
            11.622,
            -53.133,
            34.526,
            14.828,
            -53.133,
            34.526,
            14.828,
            -53.589,
            33.313,
            14.828,
            -53.589,
            33.313,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_3",
        "polygon": [
            -52.603,
            35.934,
            11.622,
            -52.147,
            37.147,
            11.622,
            -52.147,
            37.147,
            14.828,
            -52.147,
            37.147,
            14.828,
            -52.603,
            35.934,
            14.828,
            -52.603,
            35.934,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_17",
        "polygon": [
            -59.565,
            17.417,
            7.912,
            -59.045,
            18.801,
            7.912,
            -59.045,
            18.801,
            11.118,
            -59.045,
            18.801,
            11.118,
            -59.565,
            17.417,
            11.118,
            -59.565,
            17.417,
            7.912
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_15",
        "polygon": [
            -58.515,
            20.209,
            7.912,
            -58.059,
            21.422,
            7.912,
            -58.059,
            21.422,
            11.118,
            -58.059,
            21.422,
            11.118,
            -58.515,
            20.209,
            11.118,
            -58.515,
            20.209,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_1",
        "polygon": [
            -51.618,
            38.555,
            7.912,
            -51.197,
            39.674,
            7.912,
            -51.197,
            39.674,
            11.118,
            -51.197,
            39.674,
            11.118,
            -51.618,
            38.555,
            11.118,
            -51.618,
            38.555,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_13",
        "polygon": [
            -57.53,
            22.83,
            7.912,
            -57.074,
            24.043,
            7.912,
            -57.074,
            24.043,
            11.118,
            -57.074,
            24.043,
            11.118,
            -57.53,
            22.83,
            11.118,
            -57.53,
            22.83,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_11",
        "polygon": [
            -56.545,
            25.45,
            7.912,
            -56.089,
            26.664,
            7.912,
            -56.089,
            26.664,
            11.118,
            -56.089,
            26.664,
            11.118,
            -56.545,
            25.45,
            11.118,
            -56.545,
            25.45,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_9",
        "polygon": [
            -55.559,
            28.071,
            7.912,
            -55.103,
            29.284,
            7.912,
            -55.103,
            29.284,
            11.118,
            -55.103,
            29.284,
            11.118,
            -55.559,
            28.071,
            11.118,
            -55.559,
            28.071,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_7",
        "polygon": [
            -54.574,
            30.692,
            7.912,
            -54.118,
            31.905,
            7.912,
            -54.118,
            31.905,
            11.118,
            -54.118,
            31.905,
            11.118,
            -54.574,
            30.692,
            11.118,
            -54.574,
            30.692,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_5",
        "polygon": [
            -53.589,
            33.313,
            7.912,
            -53.133,
            34.526,
            7.912,
            -53.133,
            34.526,
            11.118,
            -53.133,
            34.526,
            11.118,
            -53.589,
            33.313,
            11.118,
            -53.589,
            33.313,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_3",
        "polygon": [
            -52.603,
            35.934,
            7.912,
            -52.147,
            37.147,
            7.912,
            -52.147,
            37.147,
            11.118,
            -52.147,
            37.147,
            11.118,
            -52.603,
            35.934,
            11.118,
            -52.603,
            35.934,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2879",
        "polygon": [
            -49.49,
            44.215,
            4.202,
            -49.191,
            45.01,
            4.202,
            -49.191,
            45.01,
            7.408,
            -49.191,
            45.01,
            7.408,
            -49.49,
            44.215,
            7.408,
            -49.49,
            44.215,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2880",
        "polygon": [
            -49.647,
            43.797,
            4.14,
            -49.505,
            44.176,
            4.14,
            -49.505,
            44.176,
            6.988,
            -49.505,
            44.176,
            6.988,
            -49.647,
            43.797,
            6.988,
            -49.647,
            43.797,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2881",
        "polygon": [
            -49.647,
            43.797,
            6.988,
            -49.505,
            44.176,
            6.988,
            -49.505,
            44.176,
            7.408,
            -49.505,
            44.176,
            7.408,
            -49.647,
            43.797,
            7.408,
            -49.647,
            43.797,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2882",
        "polygon": [
            -49.155,
            45.107,
            4.202,
            -48.698,
            46.32,
            4.202,
            -48.698,
            46.32,
            7.408,
            -48.698,
            46.32,
            7.408,
            -49.155,
            45.107,
            7.408,
            -49.155,
            45.107,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2883",
        "polygon": [
            -49.49,
            44.215,
            11.622,
            -49.191,
            45.01,
            11.622,
            -49.191,
            45.01,
            14.828,
            -49.191,
            45.01,
            14.828,
            -49.49,
            44.215,
            14.828,
            -49.49,
            44.215,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2884",
        "polygon": [
            -49.647,
            43.797,
            11.56,
            -49.505,
            44.176,
            11.56,
            -49.505,
            44.176,
            14.408,
            -49.505,
            44.176,
            14.408,
            -49.647,
            43.797,
            14.408,
            -49.647,
            43.797,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2885",
        "polygon": [
            -49.647,
            43.797,
            14.408,
            -49.505,
            44.176,
            14.408,
            -49.505,
            44.176,
            14.828,
            -49.505,
            44.176,
            14.828,
            -49.647,
            43.797,
            14.828,
            -49.647,
            43.797,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2886",
        "polygon": [
            -49.155,
            45.107,
            11.622,
            -48.698,
            46.32,
            11.622,
            -48.698,
            46.32,
            14.828,
            -48.698,
            46.32,
            14.828,
            -49.155,
            45.107,
            14.828,
            -49.155,
            45.107,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2887",
        "polygon": [
            -49.155,
            45.107,
            7.912,
            -48.698,
            46.32,
            7.912,
            -48.698,
            46.32,
            11.118,
            -48.698,
            46.32,
            11.118,
            -49.155,
            45.107,
            11.118,
            -49.155,
            45.107,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2888",
        "polygon": [
            -49.647,
            43.797,
            7.912,
            -49.348,
            44.591,
            7.912,
            -49.348,
            44.591,
            11.118,
            -49.348,
            44.591,
            11.118,
            -49.647,
            43.797,
            11.118,
            -49.647,
            43.797,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2889",
        "polygon": [
            -49.334,
            44.631,
            7.85,
            -49.191,
            45.01,
            7.85,
            -49.191,
            45.01,
            10.698,
            -49.191,
            45.01,
            10.698,
            -49.334,
            44.631,
            10.698,
            -49.334,
            44.631,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2890",
        "polygon": [
            -49.334,
            44.631,
            10.698,
            -49.191,
            45.01,
            10.698,
            -49.191,
            45.01,
            11.118,
            -49.191,
            45.01,
            11.118,
            -49.334,
            44.631,
            11.118,
            -49.334,
            44.631,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2891",
        "polygon": [
            -48.505,
            46.836,
            4.202,
            -48.206,
            47.631,
            4.202,
            -48.206,
            47.631,
            7.408,
            -48.206,
            47.631,
            7.408,
            -48.505,
            46.836,
            7.408,
            -48.505,
            46.836,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2892",
        "polygon": [
            -48.662,
            46.418,
            4.14,
            -48.519,
            46.797,
            4.14,
            -48.519,
            46.797,
            6.988,
            -48.519,
            46.797,
            6.988,
            -48.662,
            46.418,
            6.988,
            -48.662,
            46.418,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2893",
        "polygon": [
            -48.662,
            46.418,
            6.988,
            -48.519,
            46.797,
            6.988,
            -48.519,
            46.797,
            7.408,
            -48.519,
            46.797,
            7.408,
            -48.662,
            46.418,
            7.408,
            -48.662,
            46.418,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2894",
        "polygon": [
            -48.169,
            47.728,
            4.202,
            -47.713,
            48.941,
            4.202,
            -47.713,
            48.941,
            7.408,
            -47.713,
            48.941,
            7.408,
            -48.169,
            47.728,
            7.408,
            -48.169,
            47.728,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2895",
        "polygon": [
            -48.505,
            46.836,
            11.622,
            -48.206,
            47.631,
            11.622,
            -48.206,
            47.631,
            14.828,
            -48.206,
            47.631,
            14.828,
            -48.505,
            46.836,
            14.828,
            -48.505,
            46.836,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2896",
        "polygon": [
            -48.662,
            46.418,
            11.56,
            -48.519,
            46.797,
            11.56,
            -48.519,
            46.797,
            14.408,
            -48.519,
            46.797,
            14.408,
            -48.662,
            46.418,
            14.408,
            -48.662,
            46.418,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2897",
        "polygon": [
            -48.662,
            46.418,
            14.408,
            -48.519,
            46.797,
            14.408,
            -48.519,
            46.797,
            14.828,
            -48.519,
            46.797,
            14.828,
            -48.662,
            46.418,
            14.828,
            -48.662,
            46.418,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2898",
        "polygon": [
            -48.169,
            47.728,
            11.622,
            -47.713,
            48.941,
            11.622,
            -47.713,
            48.941,
            14.828,
            -47.713,
            48.941,
            14.828,
            -48.169,
            47.728,
            14.828,
            -48.169,
            47.728,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2899",
        "polygon": [
            -48.169,
            47.728,
            7.912,
            -47.713,
            48.941,
            7.912,
            -47.713,
            48.941,
            11.118,
            -47.713,
            48.941,
            11.118,
            -48.169,
            47.728,
            11.118,
            -48.169,
            47.728,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2900",
        "polygon": [
            -48.662,
            46.418,
            7.912,
            -48.363,
            47.212,
            7.912,
            -48.363,
            47.212,
            11.118,
            -48.363,
            47.212,
            11.118,
            -48.662,
            46.418,
            11.118,
            -48.662,
            46.418,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2901",
        "polygon": [
            -48.348,
            47.252,
            7.85,
            -48.206,
            47.631,
            7.85,
            -48.206,
            47.631,
            10.698,
            -48.206,
            47.631,
            10.698,
            -48.348,
            47.252,
            10.698,
            -48.348,
            47.252,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2902",
        "polygon": [
            -48.348,
            47.252,
            10.698,
            -48.206,
            47.631,
            10.698,
            -48.206,
            47.631,
            11.118,
            -48.206,
            47.631,
            11.118,
            -48.348,
            47.252,
            11.118,
            -48.348,
            47.252,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2903",
        "polygon": [
            -47.519,
            49.457,
            4.202,
            -47.22,
            50.252,
            4.202,
            -47.22,
            50.252,
            7.408,
            -47.22,
            50.252,
            7.408,
            -47.519,
            49.457,
            7.408,
            -47.519,
            49.457,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2904",
        "polygon": [
            -47.677,
            49.038,
            4.14,
            -47.534,
            49.418,
            4.14,
            -47.534,
            49.418,
            6.988,
            -47.534,
            49.418,
            6.988,
            -47.677,
            49.038,
            6.988,
            -47.677,
            49.038,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2905",
        "polygon": [
            -47.677,
            49.038,
            6.988,
            -47.534,
            49.418,
            6.988,
            -47.534,
            49.418,
            7.408,
            -47.534,
            49.418,
            7.408,
            -47.677,
            49.038,
            7.408,
            -47.677,
            49.038,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2906",
        "polygon": [
            -47.184,
            50.349,
            4.202,
            -46.728,
            51.562,
            4.202,
            -46.728,
            51.562,
            7.408,
            -46.728,
            51.562,
            7.408,
            -47.184,
            50.349,
            7.408,
            -47.184,
            50.349,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2907",
        "polygon": [
            -47.519,
            49.457,
            11.622,
            -47.22,
            50.252,
            11.622,
            -47.22,
            50.252,
            14.828,
            -47.22,
            50.252,
            14.828,
            -47.519,
            49.457,
            14.828,
            -47.519,
            49.457,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2908",
        "polygon": [
            -47.677,
            49.038,
            11.56,
            -47.534,
            49.418,
            11.56,
            -47.534,
            49.418,
            14.408,
            -47.534,
            49.418,
            14.408,
            -47.677,
            49.038,
            14.408,
            -47.677,
            49.038,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2909",
        "polygon": [
            -47.677,
            49.038,
            14.408,
            -47.534,
            49.418,
            14.408,
            -47.534,
            49.418,
            14.828,
            -47.534,
            49.418,
            14.828,
            -47.677,
            49.038,
            14.828,
            -47.677,
            49.038,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2910",
        "polygon": [
            -47.184,
            50.349,
            11.622,
            -46.728,
            51.562,
            11.622,
            -46.728,
            51.562,
            14.828,
            -46.728,
            51.562,
            14.828,
            -47.184,
            50.349,
            14.828,
            -47.184,
            50.349,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2911",
        "polygon": [
            -47.184,
            50.349,
            7.912,
            -46.728,
            51.562,
            7.912,
            -46.728,
            51.562,
            11.118,
            -46.728,
            51.562,
            11.118,
            -47.184,
            50.349,
            11.118,
            -47.184,
            50.349,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2912",
        "polygon": [
            -47.677,
            49.038,
            7.912,
            -47.378,
            49.833,
            7.912,
            -47.378,
            49.833,
            11.118,
            -47.378,
            49.833,
            11.118,
            -47.677,
            49.038,
            11.118,
            -47.677,
            49.038,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2913",
        "polygon": [
            -47.363,
            49.872,
            7.85,
            -47.22,
            50.252,
            7.85,
            -47.22,
            50.252,
            10.698,
            -47.22,
            50.252,
            10.698,
            -47.363,
            49.872,
            10.698,
            -47.363,
            49.872,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2914",
        "polygon": [
            -47.363,
            49.872,
            10.698,
            -47.22,
            50.252,
            10.698,
            -47.22,
            50.252,
            11.118,
            -47.22,
            50.252,
            11.118,
            -47.363,
            49.872,
            11.118,
            -47.363,
            49.872,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2915",
        "polygon": [
            -46.534,
            52.078,
            4.202,
            -46.235,
            52.872,
            4.202,
            -46.235,
            52.872,
            7.408,
            -46.235,
            52.872,
            7.408,
            -46.534,
            52.078,
            7.408,
            -46.534,
            52.078,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2916",
        "polygon": [
            -46.691,
            51.659,
            4.14,
            -46.549,
            52.038,
            4.14,
            -46.549,
            52.038,
            6.988,
            -46.549,
            52.038,
            6.988,
            -46.691,
            51.659,
            6.988,
            -46.691,
            51.659,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2917",
        "polygon": [
            -46.691,
            51.659,
            6.988,
            -46.549,
            52.038,
            6.988,
            -46.549,
            52.038,
            7.408,
            -46.549,
            52.038,
            7.408,
            -46.691,
            51.659,
            7.408,
            -46.691,
            51.659,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2918",
        "polygon": [
            -46.198,
            52.971,
            4.202,
            -45.83,
            53.95,
            4.202,
            -45.83,
            53.95,
            7.408,
            -45.83,
            53.95,
            7.408,
            -46.198,
            52.971,
            7.408,
            -46.198,
            52.971,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2919",
        "polygon": [
            -46.534,
            52.078,
            11.622,
            -46.235,
            52.872,
            11.622,
            -46.235,
            52.872,
            14.828,
            -46.235,
            52.872,
            14.828,
            -46.534,
            52.078,
            14.828,
            -46.534,
            52.078,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2920",
        "polygon": [
            -46.691,
            51.659,
            11.56,
            -46.549,
            52.038,
            11.56,
            -46.549,
            52.038,
            14.408,
            -46.549,
            52.038,
            14.408,
            -46.691,
            51.659,
            14.408,
            -46.691,
            51.659,
            11.56
        ],
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2921",
        "polygon": [
            -46.691,
            51.659,
            14.408,
            -46.549,
            52.038,
            14.408,
            -46.549,
            52.038,
            14.828,
            -46.549,
            52.038,
            14.828,
            -46.691,
            51.659,
            14.828,
            -46.691,
            51.659,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2922",
        "polygon": [
            -46.205,
            52.952,
            11.622,
            -45.837,
            53.931,
            11.622,
            -45.837,
            53.931,
            14.828,
            -45.837,
            53.931,
            14.828,
            -46.205,
            52.952,
            14.828,
            -46.205,
            52.952,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2923",
        "polygon": [
            -46.205,
            52.952,
            7.912,
            -45.837,
            53.931,
            7.912,
            -45.837,
            53.931,
            11.118,
            -45.837,
            53.931,
            11.118,
            -46.205,
            52.952,
            11.118,
            -46.205,
            52.952,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2924",
        "polygon": [
            -46.691,
            51.659,
            7.912,
            -46.392,
            52.454,
            7.912,
            -46.392,
            52.454,
            11.118,
            -46.392,
            52.454,
            11.118,
            -46.691,
            51.659,
            11.118,
            -46.691,
            51.659,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2925",
        "polygon": [
            -46.378,
            52.493,
            7.85,
            -46.235,
            52.872,
            7.85,
            -46.235,
            52.872,
            10.698,
            -46.235,
            52.872,
            10.698,
            -46.378,
            52.493,
            10.698,
            -46.378,
            52.493,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2926",
        "polygon": [
            -46.378,
            52.493,
            10.698,
            -46.235,
            52.872,
            10.698,
            -46.235,
            52.872,
            11.118,
            -46.235,
            52.872,
            11.118,
            -46.378,
            52.493,
            11.118,
            -46.378,
            52.493,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2928",
        "polygon": [
            -50.929,
            40.388,
            7.82,
            -50.521,
            41.473,
            7.82,
            -50.521,
            41.473,
            10.77,
            -50.521,
            41.473,
            10.77,
            -50.929,
            40.388,
            10.77,
            -50.929,
            40.388,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2929",
        "polygon": [
            -51.093,
            39.952,
            7.82,
            -50.929,
            40.388,
            7.82,
            -50.929,
            40.388,
            10.77,
            -50.929,
            40.388,
            10.77,
            -51.093,
            39.952,
            10.77,
            -51.093,
            39.952,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2930",
        "polygon": [
            -50.503,
            41.52,
            7.87,
            -50.375,
            41.862,
            7.87,
            -50.375,
            41.862,
            10.77,
            -50.375,
            41.862,
            10.77,
            -50.503,
            41.52,
            10.77,
            -50.503,
            41.52,
            7.87
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_2931",
        "polygon": [
            -50.265,
            42.154,
            7.912,
            -49.684,
            43.699,
            7.912,
            -49.684,
            43.699,
            11.118,
            -49.684,
            43.699,
            11.118,
            -50.265,
            42.154,
            11.118,
            -50.265,
            42.154,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2932",
        "polygon": [
            -50.929,
            40.388,
            4.11,
            -50.521,
            41.473,
            4.11,
            -50.521,
            41.473,
            7.06,
            -50.521,
            41.473,
            7.06,
            -50.929,
            40.388,
            7.06,
            -50.929,
            40.388,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2933",
        "polygon": [
            -51.093,
            39.952,
            4.11,
            -50.929,
            40.388,
            4.11,
            -50.929,
            40.388,
            7.06,
            -50.929,
            40.388,
            7.06,
            -51.093,
            39.952,
            7.06,
            -51.093,
            39.952,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2934",
        "polygon": [
            -50.503,
            41.52,
            4.16,
            -50.375,
            41.862,
            4.16,
            -50.375,
            41.862,
            7.06,
            -50.375,
            41.862,
            7.06,
            -50.503,
            41.52,
            7.06,
            -50.503,
            41.52,
            4.16
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2935",
        "polygon": [
            -50.265,
            42.154,
            4.202,
            -49.684,
            43.699,
            4.202,
            -49.684,
            43.699,
            7.408,
            -49.684,
            43.699,
            7.408,
            -50.265,
            42.154,
            7.408,
            -50.265,
            42.154,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2936",
        "polygon": [
            -50.929,
            40.388,
            11.53,
            -50.521,
            41.473,
            11.53,
            -50.521,
            41.473,
            14.48,
            -50.521,
            41.473,
            14.48,
            -50.929,
            40.388,
            14.48,
            -50.929,
            40.388,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2937",
        "polygon": [
            -51.093,
            39.952,
            11.53,
            -50.929,
            40.388,
            11.53,
            -50.929,
            40.388,
            14.48,
            -50.929,
            40.388,
            14.48,
            -51.093,
            39.952,
            14.48,
            -51.093,
            39.952,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2938",
        "polygon": [
            -50.503,
            41.52,
            11.58,
            -50.375,
            41.862,
            11.58,
            -50.375,
            41.862,
            14.48,
            -50.375,
            41.862,
            14.48,
            -50.503,
            41.52,
            14.48,
            -50.503,
            41.52,
            11.58
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2939",
        "polygon": [
            -50.265,
            42.154,
            11.622,
            -49.684,
            43.699,
            11.622,
            -49.684,
            43.699,
            14.828,
            -49.684,
            43.699,
            14.828,
            -50.265,
            42.154,
            14.828,
            -50.265,
            42.154,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2940",
        "polygon": [
            -50.831,
            41.16,
            16.54,
            -51.292,
            39.934,
            16.54,
            -51.292,
            39.934,
            16.87,
            -51.292,
            39.934,
            16.87,
            -50.831,
            41.16,
            16.87,
            -50.831,
            41.16,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2941",
        "polygon": [
            -50.337,
            42.473,
            16.21,
            -50.798,
            41.247,
            16.21,
            -50.798,
            41.247,
            16.54,
            -50.798,
            41.247,
            16.54,
            -50.337,
            42.473,
            16.54,
            -50.337,
            42.473,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2942",
        "polygon": [
            -50.337,
            42.473,
            16.54,
            -50.798,
            41.247,
            16.54,
            -50.798,
            41.247,
            16.87,
            -50.798,
            41.247,
            16.87,
            -50.337,
            42.473,
            16.87,
            -50.337,
            42.473,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2943",
        "polygon": [
            -50.337,
            42.473,
            15.88,
            -50.798,
            41.247,
            15.88,
            -50.798,
            41.247,
            16.21,
            -50.798,
            41.247,
            16.21,
            -50.337,
            42.473,
            16.21,
            -50.337,
            42.473,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2944",
        "polygon": [
            -50.831,
            41.16,
            16.21,
            -51.292,
            39.934,
            16.21,
            -51.292,
            39.934,
            16.54,
            -51.292,
            39.934,
            16.54,
            -50.831,
            41.16,
            16.54,
            -50.831,
            41.16,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2945",
        "polygon": [
            -50.831,
            41.16,
            15.88,
            -51.292,
            39.934,
            15.88,
            -51.292,
            39.934,
            16.21,
            -51.292,
            39.934,
            16.21,
            -50.831,
            41.16,
            16.21,
            -50.831,
            41.16,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2946",
        "polygon": [
            -49.856,
            43.753,
            16.21,
            -50.317,
            42.526,
            16.21,
            -50.317,
            42.526,
            16.54,
            -50.317,
            42.526,
            16.54,
            -49.856,
            43.753,
            16.54,
            -49.856,
            43.753,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2947",
        "polygon": [
            -49.856,
            43.753,
            16.54,
            -50.317,
            42.526,
            16.54,
            -50.317,
            42.526,
            16.87,
            -50.317,
            42.526,
            16.87,
            -49.856,
            43.753,
            16.87,
            -49.856,
            43.753,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2948",
        "polygon": [
            -49.856,
            43.753,
            15.88,
            -50.317,
            42.526,
            15.88,
            -50.317,
            42.526,
            16.21,
            -50.317,
            42.526,
            16.21,
            -49.856,
            43.753,
            16.21,
            -49.856,
            43.753,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2949",
        "polygon": [
            -49.351,
            45.096,
            16.21,
            -49.812,
            43.87,
            16.21,
            -49.812,
            43.87,
            16.54,
            -49.812,
            43.87,
            16.54,
            -49.351,
            45.096,
            16.54,
            -49.351,
            45.096,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2950",
        "polygon": [
            -49.351,
            45.096,
            16.54,
            -49.812,
            43.87,
            16.54,
            -49.812,
            43.87,
            16.87,
            -49.812,
            43.87,
            16.87,
            -49.351,
            45.096,
            16.87,
            -49.351,
            45.096,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2951",
        "polygon": [
            -49.351,
            45.096,
            15.88,
            -49.812,
            43.87,
            15.88,
            -49.812,
            43.87,
            16.21,
            -49.812,
            43.87,
            16.21,
            -49.351,
            45.096,
            16.21,
            -49.351,
            45.096,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2952",
        "polygon": [
            -48.871,
            46.373,
            16.21,
            -49.332,
            45.147,
            16.21,
            -49.332,
            45.147,
            16.54,
            -49.332,
            45.147,
            16.54,
            -48.871,
            46.373,
            16.54,
            -48.871,
            46.373,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2953",
        "polygon": [
            -48.871,
            46.373,
            16.54,
            -49.332,
            45.147,
            16.54,
            -49.332,
            45.147,
            16.87,
            -49.332,
            45.147,
            16.87,
            -48.871,
            46.373,
            16.87,
            -48.871,
            46.373,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2954",
        "polygon": [
            -48.871,
            46.373,
            15.88,
            -49.332,
            45.147,
            15.88,
            -49.332,
            45.147,
            16.21,
            -49.332,
            45.147,
            16.21,
            -48.871,
            46.373,
            16.21,
            -48.871,
            46.373,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2955",
        "polygon": [
            -48.366,
            47.717,
            16.21,
            -48.827,
            46.491,
            16.21,
            -48.827,
            46.491,
            16.54,
            -48.827,
            46.491,
            16.54,
            -48.366,
            47.717,
            16.54,
            -48.366,
            47.717,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2956",
        "polygon": [
            -48.366,
            47.717,
            16.54,
            -48.827,
            46.491,
            16.54,
            -48.827,
            46.491,
            16.87,
            -48.827,
            46.491,
            16.87,
            -48.366,
            47.717,
            16.87,
            -48.366,
            47.717,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2957",
        "polygon": [
            -48.366,
            47.717,
            15.88,
            -48.827,
            46.491,
            15.88,
            -48.827,
            46.491,
            16.21,
            -48.827,
            46.491,
            16.21,
            -48.366,
            47.717,
            16.21,
            -48.366,
            47.717,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2958",
        "polygon": [
            -47.885,
            48.994,
            16.21,
            -48.346,
            47.768,
            16.21,
            -48.346,
            47.768,
            16.54,
            -48.346,
            47.768,
            16.54,
            -47.885,
            48.994,
            16.54,
            -47.885,
            48.994,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2959",
        "polygon": [
            -47.885,
            48.994,
            16.54,
            -48.346,
            47.768,
            16.54,
            -48.346,
            47.768,
            16.87,
            -48.346,
            47.768,
            16.87,
            -47.885,
            48.994,
            16.87,
            -47.885,
            48.994,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2960",
        "polygon": [
            -47.885,
            48.994,
            15.88,
            -48.346,
            47.768,
            15.88,
            -48.346,
            47.768,
            16.21,
            -48.346,
            47.768,
            16.21,
            -47.885,
            48.994,
            16.21,
            -47.885,
            48.994,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2961",
        "polygon": [
            -47.38,
            50.338,
            16.21,
            -47.841,
            49.112,
            16.21,
            -47.841,
            49.112,
            16.54,
            -47.841,
            49.112,
            16.54,
            -47.38,
            50.338,
            16.54,
            -47.38,
            50.338,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2962",
        "polygon": [
            -47.38,
            50.338,
            16.54,
            -47.841,
            49.112,
            16.54,
            -47.841,
            49.112,
            16.87,
            -47.841,
            49.112,
            16.87,
            -47.38,
            50.338,
            16.87,
            -47.38,
            50.338,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2963",
        "polygon": [
            -47.38,
            50.338,
            15.88,
            -47.841,
            49.112,
            15.88,
            -47.841,
            49.112,
            16.21,
            -47.841,
            49.112,
            16.21,
            -47.38,
            50.338,
            16.21,
            -47.38,
            50.338,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2964",
        "polygon": [
            -46.9,
            51.615,
            16.21,
            -47.361,
            50.389,
            16.21,
            -47.361,
            50.389,
            16.54,
            -47.361,
            50.389,
            16.54,
            -46.9,
            51.615,
            16.54,
            -46.9,
            51.615,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2965",
        "polygon": [
            -46.9,
            51.615,
            16.54,
            -47.361,
            50.389,
            16.54,
            -47.361,
            50.389,
            16.87,
            -47.361,
            50.389,
            16.87,
            -46.9,
            51.615,
            16.87,
            -46.9,
            51.615,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2966",
        "polygon": [
            -46.9,
            51.615,
            15.88,
            -47.361,
            50.389,
            15.88,
            -47.361,
            50.389,
            16.21,
            -47.361,
            50.389,
            16.21,
            -46.9,
            51.615,
            16.21,
            -46.9,
            51.615,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2967",
        "polygon": [
            -46.395,
            52.959,
            16.21,
            -46.856,
            51.732,
            16.21,
            -46.856,
            51.732,
            16.54,
            -46.856,
            51.732,
            16.54,
            -46.395,
            52.959,
            16.54,
            -46.395,
            52.959,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2968",
        "polygon": [
            -46.395,
            52.959,
            16.54,
            -46.856,
            51.732,
            16.54,
            -46.856,
            51.732,
            16.87,
            -46.856,
            51.732,
            16.87,
            -46.395,
            52.959,
            16.87,
            -46.395,
            52.959,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2969",
        "polygon": [
            -46.395,
            52.959,
            15.88,
            -46.856,
            51.732,
            15.88,
            -46.856,
            51.732,
            16.21,
            -46.856,
            51.732,
            16.21,
            -46.395,
            52.959,
            16.21,
            -46.395,
            52.959,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2970",
        "polygon": [
            -45.898,
            54.281,
            16.21,
            -46.359,
            53.055,
            16.21,
            -46.359,
            53.055,
            16.54,
            -46.359,
            53.055,
            16.54,
            -45.898,
            54.281,
            16.54,
            -45.898,
            54.281,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2971",
        "polygon": [
            -45.898,
            54.281,
            16.54,
            -46.359,
            53.055,
            16.54,
            -46.359,
            53.055,
            16.87,
            -46.359,
            53.055,
            16.87,
            -45.898,
            54.281,
            16.87,
            -45.898,
            54.281,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_2972",
        "polygon": [
            -45.898,
            54.281,
            15.88,
            -46.359,
            53.055,
            15.88,
            -46.359,
            53.055,
            16.21,
            -46.359,
            53.055,
            16.21,
            -45.898,
            54.281,
            16.21,
            -45.898,
            54.281,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2973",
        "polygon": [
            -64.078,
            61.057,
            4.202,
            -65.057,
            61.425,
            4.202,
            -65.057,
            61.425,
            7.408,
            -65.057,
            61.425,
            7.408,
            -64.078,
            61.057,
            7.408,
            -64.078,
            61.057,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2974",
        "polygon": [
            -63.186,
            60.722,
            4.202,
            -63.981,
            61.021,
            4.202,
            -63.981,
            61.021,
            7.408,
            -63.981,
            61.021,
            7.408,
            -63.186,
            60.722,
            7.408,
            -63.186,
            60.722,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2975",
        "polygon": [
            -62.768,
            60.564,
            6.988,
            -63.147,
            60.707,
            6.988,
            -63.147,
            60.707,
            7.408,
            -63.147,
            60.707,
            7.408,
            -62.768,
            60.564,
            7.408,
            -62.768,
            60.564,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2976",
        "polygon": [
            -62.768,
            60.564,
            4.14,
            -63.147,
            60.707,
            4.14,
            -63.147,
            60.707,
            6.988,
            -63.147,
            60.707,
            6.988,
            -62.768,
            60.564,
            6.988,
            -62.768,
            60.564,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2977",
        "polygon": [
            -61.457,
            60.072,
            4.202,
            -62.67,
            60.528,
            4.202,
            -62.67,
            60.528,
            7.408,
            -62.67,
            60.528,
            7.408,
            -61.457,
            60.072,
            7.408,
            -61.457,
            60.072,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2978",
        "polygon": [
            -60.565,
            59.736,
            4.202,
            -61.36,
            60.035,
            4.202,
            -61.36,
            60.035,
            7.408,
            -61.36,
            60.035,
            7.408,
            -60.565,
            59.736,
            7.408,
            -60.565,
            59.736,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2979",
        "polygon": [
            -60.147,
            59.579,
            6.988,
            -60.526,
            59.722,
            6.988,
            -60.526,
            59.722,
            7.408,
            -60.526,
            59.722,
            7.408,
            -60.147,
            59.579,
            7.408,
            -60.147,
            59.579,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2980",
        "polygon": [
            -60.147,
            59.579,
            4.14,
            -60.526,
            59.722,
            4.14,
            -60.526,
            59.722,
            6.988,
            -60.526,
            59.722,
            6.988,
            -60.147,
            59.579,
            6.988,
            -60.147,
            59.579,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2981",
        "polygon": [
            -58.836,
            59.086,
            4.202,
            -60.049,
            59.543,
            4.202,
            -60.049,
            59.543,
            7.408,
            -60.049,
            59.543,
            7.408,
            -58.836,
            59.086,
            7.408,
            -58.836,
            59.086,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2982",
        "polygon": [
            -57.944,
            58.751,
            4.202,
            -58.739,
            59.05,
            4.202,
            -58.739,
            59.05,
            7.408,
            -58.739,
            59.05,
            7.408,
            -57.944,
            58.751,
            7.408,
            -57.944,
            58.751,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2983",
        "polygon": [
            -57.526,
            58.594,
            6.988,
            -57.905,
            58.736,
            6.988,
            -57.905,
            58.736,
            7.408,
            -57.905,
            58.736,
            7.408,
            -57.526,
            58.594,
            7.408,
            -57.526,
            58.594,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2984",
        "polygon": [
            -57.526,
            58.594,
            4.14,
            -57.905,
            58.736,
            4.14,
            -57.905,
            58.736,
            6.988,
            -57.905,
            58.736,
            6.988,
            -57.526,
            58.594,
            6.988,
            -57.526,
            58.594,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2985",
        "polygon": [
            -56.215,
            58.101,
            4.202,
            -57.429,
            58.557,
            4.202,
            -57.429,
            58.557,
            7.408,
            -57.429,
            58.557,
            7.408,
            -56.215,
            58.101,
            7.408,
            -56.215,
            58.101,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2986",
        "polygon": [
            -55.323,
            57.766,
            4.202,
            -56.118,
            58.065,
            4.202,
            -56.118,
            58.065,
            7.408,
            -56.118,
            58.065,
            7.408,
            -55.323,
            57.766,
            7.408,
            -55.323,
            57.766,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2987",
        "polygon": [
            -54.905,
            57.608,
            6.988,
            -55.284,
            57.751,
            6.988,
            -55.284,
            57.751,
            7.408,
            -55.284,
            57.751,
            7.408,
            -54.905,
            57.608,
            7.408,
            -54.905,
            57.608,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2988",
        "polygon": [
            -54.905,
            57.608,
            4.14,
            -55.284,
            57.751,
            4.14,
            -55.284,
            57.751,
            6.988,
            -55.284,
            57.751,
            6.988,
            -54.905,
            57.608,
            6.988,
            -54.905,
            57.608,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2989",
        "polygon": [
            -53.595,
            57.116,
            4.202,
            -54.808,
            57.572,
            4.202,
            -54.808,
            57.572,
            7.408,
            -54.808,
            57.572,
            7.408,
            -53.595,
            57.116,
            7.408,
            -53.595,
            57.116,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2990",
        "polygon": [
            -52.703,
            56.78,
            4.202,
            -53.497,
            57.079,
            4.202,
            -53.497,
            57.079,
            7.408,
            -53.497,
            57.079,
            7.408,
            -52.703,
            56.78,
            7.408,
            -52.703,
            56.78,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2991",
        "polygon": [
            -52.284,
            56.623,
            6.988,
            -52.663,
            56.766,
            6.988,
            -52.663,
            56.766,
            7.408,
            -52.663,
            56.766,
            7.408,
            -52.284,
            56.623,
            7.408,
            -52.284,
            56.623,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2992",
        "polygon": [
            -52.284,
            56.623,
            4.14,
            -52.663,
            56.766,
            4.14,
            -52.663,
            56.766,
            6.988,
            -52.663,
            56.766,
            6.988,
            -52.284,
            56.623,
            6.988,
            -52.284,
            56.623,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2993",
        "polygon": [
            -50.974,
            56.13,
            4.202,
            -52.187,
            56.586,
            4.202,
            -52.187,
            56.586,
            7.408,
            -52.187,
            56.586,
            7.408,
            -50.974,
            56.13,
            7.408,
            -50.974,
            56.13,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2994",
        "polygon": [
            -50.082,
            55.795,
            4.202,
            -50.876,
            56.094,
            4.202,
            -50.876,
            56.094,
            7.408,
            -50.876,
            56.094,
            7.408,
            -50.082,
            55.795,
            7.408,
            -50.082,
            55.795,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2995",
        "polygon": [
            -49.663,
            55.638,
            6.988,
            -50.042,
            55.78,
            6.988,
            -50.042,
            55.78,
            7.408,
            -50.042,
            55.78,
            7.408,
            -49.663,
            55.638,
            7.408,
            -49.663,
            55.638,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2996",
        "polygon": [
            -49.663,
            55.638,
            4.14,
            -50.042,
            55.78,
            4.14,
            -50.042,
            55.78,
            6.988,
            -50.042,
            55.78,
            6.988,
            -49.663,
            55.638,
            6.988,
            -49.663,
            55.638,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2997",
        "polygon": [
            -48.353,
            55.145,
            4.202,
            -49.566,
            55.601,
            4.202,
            -49.566,
            55.601,
            7.408,
            -49.566,
            55.601,
            7.408,
            -48.353,
            55.145,
            7.408,
            -48.353,
            55.145,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2998",
        "polygon": [
            -47.461,
            54.81,
            4.202,
            -48.255,
            55.108,
            4.202,
            -48.255,
            55.108,
            7.408,
            -48.255,
            55.108,
            7.408,
            -47.461,
            54.81,
            7.408,
            -47.461,
            54.81,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_2999",
        "polygon": [
            -47.042,
            54.652,
            6.988,
            -47.421,
            54.795,
            6.988,
            -47.421,
            54.795,
            7.408,
            -47.421,
            54.795,
            7.408,
            -47.042,
            54.652,
            7.408,
            -47.042,
            54.652,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3000",
        "polygon": [
            -47.042,
            54.652,
            4.14,
            -47.421,
            54.795,
            4.14,
            -47.421,
            54.795,
            6.988,
            -47.421,
            54.795,
            6.988,
            -47.042,
            54.652,
            6.988,
            -47.042,
            54.652,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3001",
        "polygon": [
            -45.966,
            54.248,
            4.202,
            -46.945,
            54.616,
            4.202,
            -46.945,
            54.616,
            7.408,
            -46.945,
            54.616,
            7.408,
            -45.966,
            54.248,
            7.408,
            -45.966,
            54.248,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3002",
        "polygon": [
            -64.078,
            61.057,
            11.622,
            -65.057,
            61.425,
            11.622,
            -65.057,
            61.425,
            14.828,
            -65.057,
            61.425,
            14.828,
            -64.078,
            61.057,
            14.828,
            -64.078,
            61.057,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3003",
        "polygon": [
            -63.186,
            60.722,
            11.622,
            -63.981,
            61.021,
            11.622,
            -63.981,
            61.021,
            14.828,
            -63.981,
            61.021,
            14.828,
            -63.186,
            60.722,
            14.828,
            -63.186,
            60.722,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3004",
        "polygon": [
            -62.768,
            60.564,
            14.408,
            -63.147,
            60.707,
            14.408,
            -63.147,
            60.707,
            14.828,
            -63.147,
            60.707,
            14.828,
            -62.768,
            60.564,
            14.828,
            -62.768,
            60.564,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3005",
        "polygon": [
            -62.768,
            60.564,
            11.56,
            -63.147,
            60.707,
            11.56,
            -63.147,
            60.707,
            14.408,
            -63.147,
            60.707,
            14.408,
            -62.768,
            60.564,
            14.408,
            -62.768,
            60.564,
            11.56
        ],
        "05-24 15:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3006",
        "polygon": [
            -61.457,
            60.072,
            11.622,
            -62.67,
            60.528,
            11.622,
            -62.67,
            60.528,
            14.828,
            -62.67,
            60.528,
            14.828,
            -61.457,
            60.072,
            14.828,
            -61.457,
            60.072,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3007",
        "polygon": [
            -60.565,
            59.736,
            11.622,
            -61.36,
            60.035,
            11.622,
            -61.36,
            60.035,
            14.828,
            -61.36,
            60.035,
            14.828,
            -60.565,
            59.736,
            14.828,
            -60.565,
            59.736,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3008",
        "polygon": [
            -60.147,
            59.579,
            14.408,
            -60.526,
            59.722,
            14.408,
            -60.526,
            59.722,
            14.828,
            -60.526,
            59.722,
            14.828,
            -60.147,
            59.579,
            14.828,
            -60.147,
            59.579,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3009",
        "polygon": [
            -60.147,
            59.579,
            11.56,
            -60.526,
            59.722,
            11.56,
            -60.526,
            59.722,
            14.408,
            -60.526,
            59.722,
            14.408,
            -60.147,
            59.579,
            14.408,
            -60.147,
            59.579,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3010",
        "polygon": [
            -58.836,
            59.086,
            11.622,
            -60.049,
            59.543,
            11.622,
            -60.049,
            59.543,
            14.828,
            -60.049,
            59.543,
            14.828,
            -58.836,
            59.086,
            14.828,
            -58.836,
            59.086,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3011",
        "polygon": [
            -57.944,
            58.751,
            11.622,
            -58.739,
            59.05,
            11.622,
            -58.739,
            59.05,
            14.828,
            -58.739,
            59.05,
            14.828,
            -57.944,
            58.751,
            14.828,
            -57.944,
            58.751,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3012",
        "polygon": [
            -57.526,
            58.594,
            14.408,
            -57.905,
            58.736,
            14.408,
            -57.905,
            58.736,
            14.828,
            -57.905,
            58.736,
            14.828,
            -57.526,
            58.594,
            14.828,
            -57.526,
            58.594,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3013",
        "polygon": [
            -57.526,
            58.594,
            11.56,
            -57.905,
            58.736,
            11.56,
            -57.905,
            58.736,
            14.408,
            -57.905,
            58.736,
            14.408,
            -57.526,
            58.594,
            14.408,
            -57.526,
            58.594,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3014",
        "polygon": [
            -56.215,
            58.101,
            11.622,
            -57.429,
            58.557,
            11.622,
            -57.429,
            58.557,
            14.828,
            -57.429,
            58.557,
            14.828,
            -56.215,
            58.101,
            14.828,
            -56.215,
            58.101,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3015",
        "polygon": [
            -55.323,
            57.766,
            11.622,
            -56.118,
            58.065,
            11.622,
            -56.118,
            58.065,
            14.828,
            -56.118,
            58.065,
            14.828,
            -55.323,
            57.766,
            14.828,
            -55.323,
            57.766,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3016",
        "polygon": [
            -54.905,
            57.608,
            14.408,
            -55.284,
            57.751,
            14.408,
            -55.284,
            57.751,
            14.828,
            -55.284,
            57.751,
            14.828,
            -54.905,
            57.608,
            14.828,
            -54.905,
            57.608,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3017",
        "polygon": [
            -54.905,
            57.608,
            11.56,
            -55.284,
            57.751,
            11.56,
            -55.284,
            57.751,
            14.408,
            -55.284,
            57.751,
            14.408,
            -54.905,
            57.608,
            14.408,
            -54.905,
            57.608,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3018",
        "polygon": [
            -53.595,
            57.116,
            11.622,
            -54.808,
            57.572,
            11.622,
            -54.808,
            57.572,
            14.828,
            -54.808,
            57.572,
            14.828,
            -53.595,
            57.116,
            14.828,
            -53.595,
            57.116,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3019",
        "polygon": [
            -52.703,
            56.78,
            11.622,
            -53.497,
            57.079,
            11.622,
            -53.497,
            57.079,
            14.828,
            -53.497,
            57.079,
            14.828,
            -52.703,
            56.78,
            14.828,
            -52.703,
            56.78,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3020",
        "polygon": [
            -52.284,
            56.623,
            14.408,
            -52.663,
            56.766,
            14.408,
            -52.663,
            56.766,
            14.828,
            -52.663,
            56.766,
            14.828,
            -52.284,
            56.623,
            14.828,
            -52.284,
            56.623,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3021",
        "polygon": [
            -52.284,
            56.623,
            11.56,
            -52.663,
            56.766,
            11.56,
            -52.663,
            56.766,
            14.408,
            -52.663,
            56.766,
            14.408,
            -52.284,
            56.623,
            14.408,
            -52.284,
            56.623,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3022",
        "polygon": [
            -50.974,
            56.13,
            11.622,
            -52.187,
            56.586,
            11.622,
            -52.187,
            56.586,
            14.828,
            -52.187,
            56.586,
            14.828,
            -50.974,
            56.13,
            14.828,
            -50.974,
            56.13,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3023",
        "polygon": [
            -50.082,
            55.795,
            11.622,
            -50.876,
            56.094,
            11.622,
            -50.876,
            56.094,
            14.828,
            -50.876,
            56.094,
            14.828,
            -50.082,
            55.795,
            14.828,
            -50.082,
            55.795,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3024",
        "polygon": [
            -49.663,
            55.638,
            14.408,
            -50.042,
            55.78,
            14.408,
            -50.042,
            55.78,
            14.828,
            -50.042,
            55.78,
            14.828,
            -49.663,
            55.638,
            14.828,
            -49.663,
            55.638,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3025",
        "polygon": [
            -49.663,
            55.638,
            11.56,
            -50.042,
            55.78,
            11.56,
            -50.042,
            55.78,
            14.408,
            -50.042,
            55.78,
            14.408,
            -49.663,
            55.638,
            14.408,
            -49.663,
            55.638,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3026",
        "polygon": [
            -48.353,
            55.145,
            11.622,
            -49.566,
            55.601,
            11.622,
            -49.566,
            55.601,
            14.828,
            -49.566,
            55.601,
            14.828,
            -48.353,
            55.145,
            14.828,
            -48.353,
            55.145,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3027",
        "polygon": [
            -47.461,
            54.81,
            11.622,
            -48.255,
            55.108,
            11.622,
            -48.255,
            55.108,
            14.828,
            -48.255,
            55.108,
            14.828,
            -47.461,
            54.81,
            14.828,
            -47.461,
            54.81,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3028",
        "polygon": [
            -47.042,
            54.652,
            14.408,
            -47.421,
            54.795,
            14.408,
            -47.421,
            54.795,
            14.828,
            -47.421,
            54.795,
            14.828,
            -47.042,
            54.652,
            14.828,
            -47.042,
            54.652,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3029",
        "polygon": [
            -47.042,
            54.652,
            11.56,
            -47.421,
            54.795,
            11.56,
            -47.421,
            54.795,
            14.408,
            -47.421,
            54.795,
            14.408,
            -47.042,
            54.652,
            14.408,
            -47.042,
            54.652,
            11.56
        ],
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3030",
        "polygon": [
            -45.966,
            54.248,
            11.622,
            -46.945,
            54.616,
            11.622,
            -46.945,
            54.616,
            14.828,
            -46.945,
            54.616,
            14.828,
            -45.966,
            54.248,
            14.828,
            -45.966,
            54.248,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3031",
        "polygon": [
            -64.078,
            61.057,
            7.912,
            -65.057,
            61.425,
            7.912,
            -65.057,
            61.425,
            11.118,
            -65.057,
            61.425,
            11.118,
            -64.078,
            61.057,
            11.118,
            -64.078,
            61.057,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3032",
        "polygon": [
            -61.457,
            60.072,
            7.912,
            -62.67,
            60.528,
            7.912,
            -62.67,
            60.528,
            11.118,
            -62.67,
            60.528,
            11.118,
            -61.457,
            60.072,
            11.118,
            -61.457,
            60.072,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3033",
        "polygon": [
            -58.836,
            59.086,
            7.912,
            -60.049,
            59.543,
            7.912,
            -60.049,
            59.543,
            11.118,
            -60.049,
            59.543,
            11.118,
            -58.836,
            59.086,
            11.118,
            -58.836,
            59.086,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3034",
        "polygon": [
            -56.215,
            58.101,
            7.912,
            -57.429,
            58.557,
            7.912,
            -57.429,
            58.557,
            11.118,
            -57.429,
            58.557,
            11.118,
            -56.215,
            58.101,
            11.118,
            -56.215,
            58.101,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3035",
        "polygon": [
            -53.595,
            57.116,
            7.912,
            -54.808,
            57.572,
            7.912,
            -54.808,
            57.572,
            11.118,
            -54.808,
            57.572,
            11.118,
            -53.595,
            57.116,
            11.118,
            -53.595,
            57.116,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3036",
        "polygon": [
            -50.974,
            56.13,
            7.912,
            -52.187,
            56.586,
            7.912,
            -52.187,
            56.586,
            11.118,
            -52.187,
            56.586,
            11.118,
            -50.974,
            56.13,
            11.118,
            -50.974,
            56.13,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3037",
        "polygon": [
            -48.353,
            55.145,
            7.912,
            -49.566,
            55.601,
            7.912,
            -49.566,
            55.601,
            11.118,
            -49.566,
            55.601,
            11.118,
            -48.353,
            55.145,
            11.118,
            -48.353,
            55.145,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3038",
        "polygon": [
            -47.042,
            54.652,
            7.912,
            -47.837,
            54.951,
            7.912,
            -47.837,
            54.951,
            11.118,
            -47.837,
            54.951,
            11.118,
            -47.042,
            54.652,
            11.118,
            -47.042,
            54.652,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3039",
        "polygon": [
            -47.876,
            54.966,
            10.698,
            -48.255,
            55.108,
            10.698,
            -48.255,
            55.108,
            11.118,
            -48.255,
            55.108,
            11.118,
            -47.876,
            54.966,
            11.118,
            -47.876,
            54.966,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3040",
        "polygon": [
            -47.876,
            54.966,
            7.85,
            -48.255,
            55.108,
            7.85,
            -48.255,
            55.108,
            10.698,
            -48.255,
            55.108,
            10.698,
            -47.876,
            54.966,
            10.698,
            -47.876,
            54.966,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3041",
        "polygon": [
            -45.966,
            54.248,
            7.912,
            -46.945,
            54.616,
            7.912,
            -46.945,
            54.616,
            11.118,
            -46.945,
            54.616,
            11.118,
            -45.966,
            54.248,
            11.118,
            -45.966,
            54.248,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3042",
        "polygon": [
            -49.663,
            55.638,
            7.912,
            -50.458,
            55.937,
            7.912,
            -50.458,
            55.937,
            11.118,
            -50.458,
            55.937,
            11.118,
            -49.663,
            55.638,
            11.118,
            -49.663,
            55.638,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3043",
        "polygon": [
            -50.497,
            55.951,
            10.698,
            -50.876,
            56.094,
            10.698,
            -50.876,
            56.094,
            11.118,
            -50.876,
            56.094,
            11.118,
            -50.497,
            55.951,
            11.118,
            -50.497,
            55.951,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3044",
        "polygon": [
            -50.497,
            55.951,
            7.85,
            -50.876,
            56.094,
            7.85,
            -50.876,
            56.094,
            10.698,
            -50.876,
            56.094,
            10.698,
            -50.497,
            55.951,
            10.698,
            -50.497,
            55.951,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3045",
        "polygon": [
            -52.284,
            56.623,
            7.912,
            -53.079,
            56.922,
            7.912,
            -53.079,
            56.922,
            11.118,
            -53.079,
            56.922,
            11.118,
            -52.284,
            56.623,
            11.118,
            -52.284,
            56.623,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3046",
        "polygon": [
            -53.118,
            56.937,
            10.698,
            -53.497,
            57.079,
            10.698,
            -53.497,
            57.079,
            11.118,
            -53.497,
            57.079,
            11.118,
            -53.118,
            56.937,
            11.118,
            -53.118,
            56.937,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3047",
        "polygon": [
            -53.118,
            56.937,
            7.85,
            -53.497,
            57.079,
            7.85,
            -53.497,
            57.079,
            10.698,
            -53.497,
            57.079,
            10.698,
            -53.118,
            56.937,
            10.698,
            -53.118,
            56.937,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3048",
        "polygon": [
            -54.905,
            57.608,
            7.912,
            -55.7,
            57.907,
            7.912,
            -55.7,
            57.907,
            11.118,
            -55.7,
            57.907,
            11.118,
            -54.905,
            57.608,
            11.118,
            -54.905,
            57.608,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3049",
        "polygon": [
            -55.739,
            57.922,
            10.698,
            -56.118,
            58.065,
            10.698,
            -56.118,
            58.065,
            11.118,
            -56.118,
            58.065,
            11.118,
            -55.739,
            57.922,
            11.118,
            -55.739,
            57.922,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3050",
        "polygon": [
            -55.739,
            57.922,
            7.85,
            -56.118,
            58.065,
            7.85,
            -56.118,
            58.065,
            10.698,
            -56.118,
            58.065,
            10.698,
            -55.739,
            57.922,
            10.698,
            -55.739,
            57.922,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3051",
        "polygon": [
            -57.526,
            58.594,
            7.912,
            -58.321,
            58.893,
            7.912,
            -58.321,
            58.893,
            11.118,
            -58.321,
            58.893,
            11.118,
            -57.526,
            58.594,
            11.118,
            -57.526,
            58.594,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3052",
        "polygon": [
            -58.36,
            58.907,
            10.698,
            -58.739,
            59.05,
            10.698,
            -58.739,
            59.05,
            11.118,
            -58.739,
            59.05,
            11.118,
            -58.36,
            58.907,
            11.118,
            -58.36,
            58.907,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3053",
        "polygon": [
            -58.36,
            58.907,
            7.85,
            -58.739,
            59.05,
            7.85,
            -58.739,
            59.05,
            10.698,
            -58.739,
            59.05,
            10.698,
            -58.36,
            58.907,
            10.698,
            -58.36,
            58.907,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3054",
        "polygon": [
            -60.147,
            59.579,
            7.912,
            -60.942,
            59.878,
            7.912,
            -60.942,
            59.878,
            11.118,
            -60.942,
            59.878,
            11.118,
            -60.147,
            59.579,
            11.118,
            -60.147,
            59.579,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3055",
        "polygon": [
            -60.981,
            59.893,
            10.698,
            -61.36,
            60.035,
            10.698,
            -61.36,
            60.035,
            11.118,
            -61.36,
            60.035,
            11.118,
            -60.981,
            59.893,
            11.118,
            -60.981,
            59.893,
            10.698
        ],
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3056",
        "polygon": [
            -60.981,
            59.893,
            7.85,
            -61.36,
            60.035,
            7.85,
            -61.36,
            60.035,
            10.698,
            -61.36,
            60.035,
            10.698,
            -60.981,
            59.893,
            10.698,
            -60.981,
            59.893,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3057",
        "polygon": [
            -62.768,
            60.564,
            7.912,
            -63.562,
            60.863,
            7.912,
            -63.562,
            60.863,
            11.118,
            -63.562,
            60.863,
            11.118,
            -62.768,
            60.564,
            11.118,
            -62.768,
            60.564,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3058",
        "polygon": [
            -63.602,
            60.878,
            10.698,
            -63.981,
            61.021,
            10.698,
            -63.981,
            61.021,
            11.118,
            -63.981,
            61.021,
            11.118,
            -63.602,
            60.878,
            11.118,
            -63.602,
            60.878,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3059",
        "polygon": [
            -63.602,
            60.878,
            7.85,
            -63.981,
            61.021,
            7.85,
            -63.981,
            61.021,
            10.698,
            -63.981,
            61.021,
            10.698,
            -63.602,
            60.878,
            10.698,
            -63.602,
            60.878,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3075",
        "polygon": [
            -49.65,
            55.44,
            16.555,
            -46.009,
            54.071,
            16.555,
            -46.009,
            54.071,
            16.91,
            -46.009,
            54.071,
            16.91,
            -49.65,
            55.44,
            16.91,
            -49.65,
            55.44,
            16.555
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3076",
        "polygon": [
            -49.65,
            55.44,
            16.2,
            -46.009,
            54.071,
            16.2,
            -46.009,
            54.071,
            16.555,
            -46.009,
            54.071,
            16.555,
            -49.65,
            55.44,
            16.555,
            -49.65,
            55.44,
            16.2
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3077",
        "polygon": [
            -49.65,
            55.44,
            15.845,
            -46.009,
            54.071,
            15.845,
            -46.009,
            54.071,
            16.2,
            -46.009,
            54.071,
            16.2,
            -49.65,
            55.44,
            16.2,
            -49.65,
            55.44,
            15.845
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3078",
        "polygon": [
            -53.581,
            56.918,
            16.555,
            -49.706,
            55.462,
            16.555,
            -49.706,
            55.462,
            16.91,
            -49.706,
            55.462,
            16.91,
            -53.581,
            56.918,
            16.91,
            -53.581,
            56.918,
            16.555
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3079",
        "polygon": [
            -53.581,
            56.918,
            16.2,
            -49.706,
            55.462,
            16.2,
            -49.706,
            55.462,
            16.555,
            -49.706,
            55.462,
            16.555,
            -53.581,
            56.918,
            16.555,
            -53.581,
            56.918,
            16.2
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3080",
        "polygon": [
            -53.581,
            56.918,
            15.845,
            -49.706,
            55.462,
            15.845,
            -49.706,
            55.462,
            16.2,
            -49.706,
            55.462,
            16.2,
            -53.581,
            56.918,
            16.2,
            -53.581,
            56.918,
            15.845
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3081",
        "polygon": [
            -57.513,
            58.396,
            16.555,
            -53.637,
            56.94,
            16.555,
            -53.637,
            56.94,
            16.91,
            -53.637,
            56.94,
            16.91,
            -57.513,
            58.396,
            16.91,
            -57.513,
            58.396,
            16.555
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3082",
        "polygon": [
            -57.513,
            58.396,
            16.2,
            -53.637,
            56.94,
            16.2,
            -53.637,
            56.94,
            16.555,
            -53.637,
            56.94,
            16.555,
            -57.513,
            58.396,
            16.555,
            -57.513,
            58.396,
            16.2
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3083",
        "polygon": [
            -57.513,
            58.396,
            15.845,
            -53.637,
            56.94,
            15.845,
            -53.637,
            56.94,
            16.2,
            -53.637,
            56.94,
            16.2,
            -57.513,
            58.396,
            16.2,
            -57.513,
            58.396,
            15.845
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3084",
        "polygon": [
            -61.444,
            59.874,
            16.555,
            -57.569,
            58.418,
            16.555,
            -57.569,
            58.418,
            16.91,
            -57.569,
            58.418,
            16.91,
            -61.444,
            59.874,
            16.91,
            -61.444,
            59.874,
            16.555
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3085",
        "polygon": [
            -61.444,
            59.874,
            16.2,
            -57.569,
            58.418,
            16.2,
            -57.569,
            58.418,
            16.555,
            -57.569,
            58.418,
            16.555,
            -61.444,
            59.874,
            16.555,
            -61.444,
            59.874,
            16.2
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3086",
        "polygon": [
            -61.444,
            59.874,
            15.845,
            -57.569,
            58.418,
            15.845,
            -57.569,
            58.418,
            16.2,
            -57.569,
            58.418,
            16.2,
            -61.444,
            59.874,
            16.2,
            -61.444,
            59.874,
            15.845
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3087",
        "polygon": [
            -65.141,
            61.265,
            16.555,
            -61.5,
            59.896,
            16.555,
            -61.5,
            59.896,
            16.91,
            -61.5,
            59.896,
            16.91,
            -65.141,
            61.265,
            16.91,
            -65.141,
            61.265,
            16.555
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3088",
        "polygon": [
            -65.141,
            61.265,
            16.2,
            -61.5,
            59.896,
            16.2,
            -61.5,
            59.896,
            16.555,
            -61.5,
            59.896,
            16.555,
            -65.141,
            61.265,
            16.555,
            -65.141,
            61.265,
            16.2
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3089",
        "polygon": [
            -65.141,
            61.265,
            15.845,
            -61.5,
            59.896,
            15.845,
            -61.5,
            59.896,
            16.2,
            -61.5,
            59.896,
            16.2,
            -65.141,
            61.265,
            16.2,
            -65.141,
            61.265,
            15.845
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3090",
        "polygon": [
            -53.581,
            56.918,
            16.91,
            -49.706,
            55.462,
            16.91,
            -49.706,
            55.462,
            17.176,
            -49.706,
            55.462,
            17.176,
            -53.581,
            56.918,
            17.176,
            -53.581,
            56.918,
            16.91
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3091",
        "polygon": [
            -57.513,
            58.396,
            16.91,
            -53.637,
            56.94,
            16.91,
            -53.637,
            56.94,
            17.344,
            -53.637,
            56.94,
            17.344,
            -57.513,
            58.396,
            17.344,
            -57.513,
            58.396,
            16.91
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3092",
        "polygon": [
            -61.444,
            59.874,
            16.91,
            -57.569,
            58.418,
            16.91,
            -57.569,
            58.418,
            17.176,
            -57.569,
            58.418,
            17.176,
            -61.444,
            59.874,
            17.176,
            -61.444,
            59.874,
            16.91
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_17",
        "polygon": [
            -93.857,
            88.918,
            0.142,
            -94.377,
            87.534,
            0.142,
            -94.377,
            87.534,
            3.698,
            -94.377,
            87.534,
            3.698,
            -93.857,
            88.918,
            3.698,
            -93.857,
            88.918,
            0.142
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_15",
        "polygon": [
            -94.906,
            86.126,
            0.142,
            -95.362,
            84.913,
            0.142,
            -95.362,
            84.913,
            3.698,
            -95.362,
            84.913,
            3.698,
            -94.906,
            86.126,
            3.698,
            -94.906,
            86.126,
            0.142
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_13",
        "polygon": [
            -95.891,
            83.506,
            0.142,
            -96.347,
            82.293,
            0.142,
            -96.347,
            82.293,
            3.698,
            -96.347,
            82.293,
            3.698,
            -95.891,
            83.506,
            3.698,
            -95.891,
            83.506,
            0.142
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_11",
        "polygon": [
            -96.876,
            80.886,
            0.142,
            -97.332,
            79.672,
            0.142,
            -97.332,
            79.672,
            3.698,
            -97.332,
            79.672,
            3.698,
            -96.876,
            80.886,
            3.698,
            -96.876,
            80.886,
            0.142
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_9",
        "polygon": [
            -97.862,
            78.265,
            0.142,
            -98.318,
            77.052,
            0.142,
            -98.318,
            77.052,
            3.698,
            -98.318,
            77.052,
            3.698,
            -97.862,
            78.265,
            3.698,
            -97.862,
            78.265,
            0.142
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_7",
        "polygon": [
            -98.847,
            75.644,
            0.142,
            -99.303,
            74.431,
            0.142,
            -99.303,
            74.431,
            3.698,
            -99.303,
            74.431,
            3.698,
            -98.847,
            75.644,
            3.698,
            -98.847,
            75.644,
            0.142
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_5",
        "polygon": [
            -99.832,
            73.023,
            0.142,
            -100.288,
            71.81,
            0.142,
            -100.288,
            71.81,
            3.698,
            -100.288,
            71.81,
            3.698,
            -99.832,
            73.023,
            3.698,
            -99.832,
            73.023,
            0.142
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_3",
        "polygon": [
            -100.818,
            70.402,
            0.142,
            -101.274,
            69.189,
            0.142,
            -101.274,
            69.189,
            3.698,
            -101.274,
            69.189,
            3.698,
            -100.818,
            70.402,
            3.698,
            -100.818,
            70.402,
            0.142
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_1",
        "polygon": [
            -101.803,
            67.78,
            0.142,
            -102.198,
            66.73,
            0.142,
            -102.198,
            66.73,
            3.698,
            -102.198,
            66.73,
            3.698,
            -101.803,
            67.78,
            3.698,
            -101.803,
            67.78,
            0.142
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_17",
        "polygon": [
            -93.857,
            88.918,
            7.912,
            -94.377,
            87.534,
            7.912,
            -94.377,
            87.534,
            11.118,
            -94.377,
            87.534,
            11.118,
            -93.857,
            88.918,
            11.118,
            -93.857,
            88.918,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_15",
        "polygon": [
            -94.906,
            86.126,
            7.912,
            -95.362,
            84.913,
            7.912,
            -95.362,
            84.913,
            11.118,
            -95.362,
            84.913,
            11.118,
            -94.906,
            86.126,
            11.118,
            -94.906,
            86.126,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_13",
        "polygon": [
            -95.891,
            83.506,
            7.912,
            -96.347,
            82.293,
            7.912,
            -96.347,
            82.293,
            11.118,
            -96.347,
            82.293,
            11.118,
            -95.891,
            83.506,
            11.118,
            -95.891,
            83.506,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_11",
        "polygon": [
            -96.876,
            80.886,
            7.912,
            -97.332,
            79.672,
            7.912,
            -97.332,
            79.672,
            11.118,
            -97.332,
            79.672,
            11.118,
            -96.876,
            80.886,
            11.118,
            -96.876,
            80.886,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_9",
        "polygon": [
            -97.862,
            78.265,
            7.912,
            -98.318,
            77.052,
            7.912,
            -98.318,
            77.052,
            11.118,
            -98.318,
            77.052,
            11.118,
            -97.862,
            78.265,
            11.118,
            -97.862,
            78.265,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_7",
        "polygon": [
            -98.847,
            75.644,
            7.912,
            -99.303,
            74.431,
            7.912,
            -99.303,
            74.431,
            11.118,
            -99.303,
            74.431,
            11.118,
            -98.847,
            75.644,
            11.118,
            -98.847,
            75.644,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_5",
        "polygon": [
            -99.832,
            73.023,
            7.912,
            -100.288,
            71.81,
            7.912,
            -100.288,
            71.81,
            11.118,
            -100.288,
            71.81,
            11.118,
            -99.832,
            73.023,
            11.118,
            -99.832,
            73.023,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_3",
        "polygon": [
            -100.818,
            70.402,
            7.912,
            -101.274,
            69.189,
            7.912,
            -101.274,
            69.189,
            11.118,
            -101.274,
            69.189,
            11.118,
            -100.818,
            70.402,
            11.118,
            -100.818,
            70.402,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_1",
        "polygon": [
            -101.803,
            67.781,
            7.912,
            -102.198,
            66.731,
            7.912,
            -102.198,
            66.731,
            11.118,
            -102.198,
            66.731,
            11.118,
            -101.803,
            67.781,
            11.118,
            -101.803,
            67.781,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_17",
        "polygon": [
            -93.857,
            88.918,
            4.202,
            -94.377,
            87.534,
            4.202,
            -94.377,
            87.534,
            7.408,
            -94.377,
            87.534,
            7.408,
            -93.857,
            88.918,
            7.408,
            -93.857,
            88.918,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_15",
        "polygon": [
            -94.906,
            86.126,
            4.202,
            -95.362,
            84.913,
            4.202,
            -95.362,
            84.913,
            7.408,
            -95.362,
            84.913,
            7.408,
            -94.906,
            86.126,
            7.408,
            -94.906,
            86.126,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_13",
        "polygon": [
            -95.891,
            83.506,
            4.202,
            -96.347,
            82.293,
            4.202,
            -96.347,
            82.293,
            7.408,
            -96.347,
            82.293,
            7.408,
            -95.891,
            83.506,
            7.408,
            -95.891,
            83.506,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_11",
        "polygon": [
            -96.876,
            80.886,
            4.202,
            -97.332,
            79.672,
            4.202,
            -97.332,
            79.672,
            7.408,
            -97.332,
            79.672,
            7.408,
            -96.876,
            80.886,
            7.408,
            -96.876,
            80.886,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_9",
        "polygon": [
            -97.862,
            78.265,
            4.202,
            -98.318,
            77.052,
            4.202,
            -98.318,
            77.052,
            7.408,
            -98.318,
            77.052,
            7.408,
            -97.862,
            78.265,
            7.408,
            -97.862,
            78.265,
            4.202
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_7",
        "polygon": [
            -98.847,
            75.644,
            4.202,
            -99.303,
            74.431,
            4.202,
            -99.303,
            74.431,
            7.408,
            -99.303,
            74.431,
            7.408,
            -98.847,
            75.644,
            7.408,
            -98.847,
            75.644,
            4.202
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_5",
        "polygon": [
            -99.832,
            73.023,
            4.202,
            -100.288,
            71.81,
            4.202,
            -100.288,
            71.81,
            7.408,
            -100.288,
            71.81,
            7.408,
            -99.832,
            73.023,
            7.408,
            -99.832,
            73.023,
            4.202
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_3",
        "polygon": [
            -100.818,
            70.402,
            4.202,
            -101.274,
            69.189,
            4.202,
            -101.274,
            69.189,
            7.408,
            -101.274,
            69.189,
            7.408,
            -100.818,
            70.402,
            7.408,
            -100.818,
            70.402,
            4.202
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_1",
        "polygon": [
            -101.803,
            67.781,
            4.202,
            -102.198,
            66.731,
            4.202,
            -102.198,
            66.731,
            7.408,
            -102.198,
            66.731,
            7.408,
            -101.803,
            67.781,
            7.408,
            -101.803,
            67.781,
            4.202
        ],
        "05-24 04:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_3",
        "polygon": [
            -100.818,
            70.402,
            11.622,
            -101.274,
            69.189,
            11.622,
            -101.274,
            69.189,
            14.828,
            -101.274,
            69.189,
            14.828,
            -100.818,
            70.402,
            14.828,
            -100.818,
            70.402,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_1",
        "polygon": [
            -101.803,
            67.781,
            11.622,
            -102.198,
            66.731,
            11.622,
            -102.198,
            66.731,
            14.828,
            -102.198,
            66.731,
            14.828,
            -101.803,
            67.781,
            14.828,
            -101.803,
            67.781,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_5",
        "polygon": [
            -99.832,
            73.023,
            11.622,
            -100.288,
            71.81,
            11.622,
            -100.288,
            71.81,
            14.828,
            -100.288,
            71.81,
            14.828,
            -99.832,
            73.023,
            14.828,
            -99.832,
            73.023,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_7",
        "polygon": [
            -98.847,
            75.644,
            11.622,
            -99.303,
            74.431,
            11.622,
            -99.303,
            74.431,
            14.828,
            -99.303,
            74.431,
            14.828,
            -98.847,
            75.644,
            14.828,
            -98.847,
            75.644,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_8",
        "polygon": [
            -98.291,
            77.124,
            11.622,
            -98.811,
            75.74,
            11.622,
            -98.811,
            75.74,
            14.828,
            -98.811,
            75.74,
            14.828,
            -98.291,
            77.124,
            14.828,
            -98.291,
            77.124,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3205",
        "polygon": [
            -103.931,
            62.121,
            4.202,
            -104.23,
            61.326,
            4.202,
            -104.23,
            61.326,
            7.408,
            -104.23,
            61.326,
            7.408,
            -103.931,
            62.121,
            7.408,
            -103.931,
            62.121,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3206",
        "polygon": [
            -103.774,
            62.539,
            4.14,
            -103.916,
            62.16,
            4.14,
            -103.916,
            62.16,
            6.988,
            -103.916,
            62.16,
            6.988,
            -103.774,
            62.539,
            6.988,
            -103.774,
            62.539,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3207",
        "polygon": [
            -103.774,
            62.539,
            6.988,
            -103.916,
            62.16,
            6.988,
            -103.916,
            62.16,
            7.408,
            -103.916,
            62.16,
            7.408,
            -103.774,
            62.539,
            7.408,
            -103.774,
            62.539,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3208",
        "polygon": [
            -104.267,
            61.228,
            4.202,
            -104.723,
            60.015,
            4.202,
            -104.723,
            60.015,
            7.408,
            -104.723,
            60.015,
            7.408,
            -104.267,
            61.228,
            7.408,
            -104.267,
            61.228,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3209",
        "polygon": [
            -105.252,
            58.608,
            4.202,
            -105.708,
            57.395,
            4.202,
            -105.708,
            57.395,
            7.408,
            -105.708,
            57.395,
            7.408,
            -105.252,
            58.608,
            7.408,
            -105.252,
            58.608,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3210",
        "polygon": [
            -104.916,
            59.5,
            4.202,
            -105.215,
            58.705,
            4.202,
            -105.215,
            58.705,
            7.408,
            -105.215,
            58.705,
            7.408,
            -104.916,
            59.5,
            7.408,
            -104.916,
            59.5,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3211",
        "polygon": [
            -104.759,
            59.918,
            4.14,
            -104.902,
            59.539,
            4.14,
            -104.902,
            59.539,
            6.988,
            -104.902,
            59.539,
            6.988,
            -104.759,
            59.918,
            6.988,
            -104.759,
            59.918,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3212",
        "polygon": [
            -104.759,
            59.918,
            6.988,
            -104.902,
            59.539,
            6.988,
            -104.902,
            59.539,
            7.408,
            -104.902,
            59.539,
            7.408,
            -104.759,
            59.918,
            7.408,
            -104.759,
            59.918,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3213",
        "polygon": [
            -105.902,
            56.879,
            4.202,
            -106.201,
            56.084,
            4.202,
            -106.201,
            56.084,
            7.408,
            -106.201,
            56.084,
            7.408,
            -105.902,
            56.879,
            7.408,
            -105.902,
            56.879,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3214",
        "polygon": [
            -105.744,
            57.297,
            4.14,
            -105.887,
            56.918,
            4.14,
            -105.887,
            56.918,
            6.988,
            -105.887,
            56.918,
            6.988,
            -105.744,
            57.297,
            6.988,
            -105.744,
            57.297,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3215",
        "polygon": [
            -105.744,
            57.297,
            6.988,
            -105.887,
            56.918,
            6.988,
            -105.887,
            56.918,
            7.408,
            -105.887,
            56.918,
            7.408,
            -105.744,
            57.297,
            7.408,
            -105.744,
            57.297,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3216",
        "polygon": [
            -106.238,
            55.986,
            4.202,
            -106.694,
            54.773,
            4.202,
            -106.694,
            54.773,
            7.408,
            -106.694,
            54.773,
            7.408,
            -106.238,
            55.986,
            7.408,
            -106.238,
            55.986,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3217",
        "polygon": [
            -107.222,
            53.366,
            4.202,
            -107.591,
            52.387,
            4.202,
            -107.591,
            52.387,
            7.408,
            -107.591,
            52.387,
            7.408,
            -107.222,
            53.366,
            7.408,
            -107.222,
            53.366,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3218",
        "polygon": [
            -106.887,
            54.258,
            4.202,
            -107.186,
            53.463,
            4.202,
            -107.186,
            53.463,
            7.408,
            -107.186,
            53.463,
            7.408,
            -106.887,
            54.258,
            7.408,
            -106.887,
            54.258,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3219",
        "polygon": [
            -106.73,
            54.677,
            4.14,
            -106.872,
            54.297,
            4.14,
            -106.872,
            54.297,
            6.988,
            -106.872,
            54.297,
            6.988,
            -106.73,
            54.677,
            6.988,
            -106.73,
            54.677,
            4.14
        ],
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3220",
        "polygon": [
            -106.73,
            54.677,
            6.988,
            -106.872,
            54.297,
            6.988,
            -106.872,
            54.297,
            7.408,
            -106.872,
            54.297,
            7.408,
            -106.73,
            54.677,
            7.408,
            -106.73,
            54.677,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3221",
        "polygon": [
            -103.156,
            64.182,
            4.202,
            -103.737,
            62.637,
            4.202,
            -103.737,
            62.637,
            7.408,
            -103.737,
            62.637,
            7.408,
            -103.156,
            64.182,
            7.408,
            -103.156,
            64.182,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3222",
        "polygon": [
            -102.482,
            65.975,
            4.11,
            -102.89,
            64.89,
            4.11,
            -102.89,
            64.89,
            7.06,
            -102.89,
            64.89,
            7.06,
            -102.482,
            65.975,
            7.06,
            -102.482,
            65.975,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3223",
        "polygon": [
            -102.348,
            66.332,
            4.11,
            -102.483,
            65.972,
            4.11,
            -102.483,
            65.972,
            7.06,
            -102.483,
            65.972,
            7.06,
            -102.348,
            66.332,
            7.06,
            -102.348,
            66.332,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3224",
        "polygon": [
            -102.913,
            64.83,
            4.16,
            -103.043,
            64.483,
            4.16,
            -103.043,
            64.483,
            7.06,
            -103.043,
            64.483,
            7.06,
            -102.913,
            64.83,
            7.06,
            -102.913,
            64.83,
            4.16
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3225",
        "polygon": [
            -103.931,
            62.121,
            11.622,
            -104.23,
            61.326,
            11.622,
            -104.23,
            61.326,
            14.828,
            -104.23,
            61.326,
            14.828,
            -103.931,
            62.121,
            14.828,
            -103.931,
            62.121,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3226",
        "polygon": [
            -103.774,
            62.539,
            11.56,
            -103.916,
            62.16,
            11.56,
            -103.916,
            62.16,
            14.408,
            -103.916,
            62.16,
            14.408,
            -103.774,
            62.539,
            14.408,
            -103.774,
            62.539,
            11.56
        ],
        "05-24 04:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3227",
        "polygon": [
            -103.774,
            62.539,
            14.408,
            -103.916,
            62.16,
            14.408,
            -103.916,
            62.16,
            14.828,
            -103.916,
            62.16,
            14.828,
            -103.774,
            62.539,
            14.828,
            -103.774,
            62.539,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3228",
        "polygon": [
            -104.267,
            61.228,
            11.622,
            -104.723,
            60.015,
            11.622,
            -104.723,
            60.015,
            14.828,
            -104.723,
            60.015,
            14.828,
            -104.267,
            61.228,
            14.828,
            -104.267,
            61.228,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3229",
        "polygon": [
            -105.252,
            58.608,
            11.622,
            -105.708,
            57.395,
            11.622,
            -105.708,
            57.395,
            14.828,
            -105.708,
            57.395,
            14.828,
            -105.252,
            58.608,
            14.828,
            -105.252,
            58.608,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3230",
        "polygon": [
            -104.916,
            59.5,
            11.622,
            -105.215,
            58.705,
            11.622,
            -105.215,
            58.705,
            14.828,
            -105.215,
            58.705,
            14.828,
            -104.916,
            59.5,
            14.828,
            -104.916,
            59.5,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3231",
        "polygon": [
            -104.759,
            59.918,
            11.56,
            -104.902,
            59.539,
            11.56,
            -104.902,
            59.539,
            14.408,
            -104.902,
            59.539,
            14.408,
            -104.759,
            59.918,
            14.408,
            -104.759,
            59.918,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3232",
        "polygon": [
            -104.759,
            59.918,
            14.408,
            -104.902,
            59.539,
            14.408,
            -104.902,
            59.539,
            14.828,
            -104.902,
            59.539,
            14.828,
            -104.759,
            59.918,
            14.828,
            -104.759,
            59.918,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3233",
        "polygon": [
            -105.902,
            56.879,
            11.622,
            -106.201,
            56.084,
            11.622,
            -106.201,
            56.084,
            14.828,
            -106.201,
            56.084,
            14.828,
            -105.902,
            56.879,
            14.828,
            -105.902,
            56.879,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3234",
        "polygon": [
            -105.744,
            57.297,
            11.56,
            -105.887,
            56.918,
            11.56,
            -105.887,
            56.918,
            14.408,
            -105.887,
            56.918,
            14.408,
            -105.744,
            57.297,
            14.408,
            -105.744,
            57.297,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3235",
        "polygon": [
            -105.744,
            57.297,
            14.408,
            -105.887,
            56.918,
            14.408,
            -105.887,
            56.918,
            14.828,
            -105.887,
            56.918,
            14.828,
            -105.744,
            57.297,
            14.828,
            -105.744,
            57.297,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3236",
        "polygon": [
            -106.238,
            55.986,
            11.622,
            -106.694,
            54.773,
            11.622,
            -106.694,
            54.773,
            14.828,
            -106.694,
            54.773,
            14.828,
            -106.238,
            55.986,
            14.828,
            -106.238,
            55.986,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3237",
        "polygon": [
            -107.222,
            53.366,
            11.622,
            -107.591,
            52.387,
            11.622,
            -107.591,
            52.387,
            14.828,
            -107.591,
            52.387,
            14.828,
            -107.222,
            53.366,
            14.828,
            -107.222,
            53.366,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3238",
        "polygon": [
            -106.887,
            54.258,
            11.622,
            -107.186,
            53.463,
            11.622,
            -107.186,
            53.463,
            14.828,
            -107.186,
            53.463,
            14.828,
            -106.887,
            54.258,
            14.828,
            -106.887,
            54.258,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3239",
        "polygon": [
            -106.73,
            54.677,
            11.56,
            -106.872,
            54.297,
            11.56,
            -106.872,
            54.297,
            14.408,
            -106.872,
            54.297,
            14.408,
            -106.73,
            54.677,
            14.408,
            -106.73,
            54.677,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3240",
        "polygon": [
            -106.73,
            54.677,
            14.408,
            -106.872,
            54.297,
            14.408,
            -106.872,
            54.297,
            14.828,
            -106.872,
            54.297,
            14.828,
            -106.73,
            54.677,
            14.828,
            -106.73,
            54.677,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3241",
        "polygon": [
            -103.156,
            64.182,
            11.622,
            -103.737,
            62.637,
            11.622,
            -103.737,
            62.637,
            14.828,
            -103.737,
            62.637,
            14.828,
            -103.156,
            64.182,
            14.828,
            -103.156,
            64.182,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3242",
        "polygon": [
            -102.482,
            65.975,
            11.53,
            -102.89,
            64.89,
            11.53,
            -102.89,
            64.89,
            14.48,
            -102.89,
            64.89,
            14.48,
            -102.482,
            65.975,
            14.48,
            -102.482,
            65.975,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3243",
        "polygon": [
            -102.348,
            66.332,
            11.53,
            -102.483,
            65.972,
            11.53,
            -102.483,
            65.972,
            14.48,
            -102.483,
            65.972,
            14.48,
            -102.348,
            66.332,
            14.48,
            -102.348,
            66.332,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3244",
        "polygon": [
            -102.913,
            64.83,
            11.58,
            -103.043,
            64.483,
            11.58,
            -103.043,
            64.483,
            14.48,
            -103.043,
            64.483,
            14.48,
            -102.913,
            64.83,
            14.48,
            -102.913,
            64.83,
            11.58
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3245",
        "polygon": [
            -103.774,
            62.538,
            7.912,
            -104.073,
            61.744,
            7.912,
            -104.073,
            61.744,
            11.118,
            -104.073,
            61.744,
            11.118,
            -103.774,
            62.538,
            11.118,
            -103.774,
            62.538,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3246",
        "polygon": [
            -104.087,
            61.705,
            7.85,
            -104.23,
            61.326,
            7.85,
            -104.23,
            61.326,
            10.698,
            -104.23,
            61.326,
            10.698,
            -104.087,
            61.705,
            10.698,
            -104.087,
            61.705,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3247",
        "polygon": [
            -104.087,
            61.705,
            10.698,
            -104.23,
            61.326,
            10.698,
            -104.23,
            61.326,
            11.118,
            -104.23,
            61.326,
            11.118,
            -104.087,
            61.705,
            11.118,
            -104.087,
            61.705,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3248",
        "polygon": [
            -104.267,
            61.228,
            7.912,
            -104.723,
            60.015,
            7.912,
            -104.723,
            60.015,
            11.118,
            -104.723,
            60.015,
            11.118,
            -104.267,
            61.228,
            11.118,
            -104.267,
            61.228,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3249",
        "polygon": [
            -105.252,
            58.608,
            7.912,
            -105.708,
            57.395,
            7.912,
            -105.708,
            57.395,
            11.118,
            -105.708,
            57.395,
            11.118,
            -105.252,
            58.608,
            11.118,
            -105.252,
            58.608,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3256",
        "polygon": [
            -106.238,
            55.986,
            7.912,
            -106.694,
            54.773,
            7.912,
            -106.694,
            54.773,
            11.118,
            -106.694,
            54.773,
            11.118,
            -106.238,
            55.986,
            11.118,
            -106.238,
            55.986,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3257",
        "polygon": [
            -107.222,
            53.366,
            7.912,
            -107.591,
            52.387,
            7.912,
            -107.591,
            52.387,
            11.118,
            -107.591,
            52.387,
            11.118,
            -107.222,
            53.366,
            11.118,
            -107.222,
            53.366,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3261",
        "polygon": [
            -103.156,
            64.182,
            7.912,
            -103.737,
            62.637,
            7.912,
            -103.737,
            62.637,
            11.118,
            -103.737,
            62.637,
            11.118,
            -103.156,
            64.182,
            11.118,
            -103.156,
            64.182,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3262",
        "polygon": [
            -102.482,
            65.975,
            7.82,
            -102.89,
            64.89,
            7.82,
            -102.89,
            64.89,
            10.77,
            -102.89,
            64.89,
            10.77,
            -102.482,
            65.975,
            10.77,
            -102.482,
            65.975,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3263",
        "polygon": [
            -102.348,
            66.332,
            7.82,
            -102.483,
            65.972,
            7.82,
            -102.483,
            65.972,
            10.77,
            -102.483,
            65.972,
            10.77,
            -102.348,
            66.332,
            10.77,
            -102.348,
            66.332,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3264",
        "polygon": [
            -102.913,
            64.83,
            7.87,
            -103.043,
            64.483,
            7.87,
            -103.043,
            64.483,
            10.77,
            -103.043,
            64.483,
            10.77,
            -102.913,
            64.83,
            10.77,
            -102.913,
            64.83,
            7.87
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3265",
        "polygon": [
            -104.76,
            59.917,
            7.912,
            -105.058,
            59.123,
            7.912,
            -105.058,
            59.123,
            11.118,
            -105.058,
            59.123,
            11.118,
            -104.76,
            59.917,
            11.118,
            -104.76,
            59.917,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3266",
        "polygon": [
            -105.073,
            59.084,
            7.85,
            -105.215,
            58.705,
            7.85,
            -105.215,
            58.705,
            10.698,
            -105.215,
            58.705,
            10.698,
            -105.073,
            59.084,
            10.698,
            -105.073,
            59.084,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3267",
        "polygon": [
            -105.073,
            59.084,
            10.698,
            -105.215,
            58.705,
            10.698,
            -105.215,
            58.705,
            11.118,
            -105.215,
            58.705,
            11.118,
            -105.073,
            59.084,
            11.118,
            -105.073,
            59.084,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3268",
        "polygon": [
            -105.745,
            57.296,
            7.912,
            -106.044,
            56.502,
            7.912,
            -106.044,
            56.502,
            11.118,
            -106.044,
            56.502,
            11.118,
            -105.745,
            57.296,
            11.118,
            -105.745,
            57.296,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3269",
        "polygon": [
            -106.058,
            56.463,
            7.85,
            -106.201,
            56.084,
            7.85,
            -106.201,
            56.084,
            10.698,
            -106.201,
            56.084,
            10.698,
            -106.058,
            56.463,
            10.698,
            -106.058,
            56.463,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3270",
        "polygon": [
            -106.058,
            56.463,
            10.698,
            -106.201,
            56.084,
            10.698,
            -106.201,
            56.084,
            11.118,
            -106.201,
            56.084,
            11.118,
            -106.058,
            56.463,
            11.118,
            -106.058,
            56.463,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3271",
        "polygon": [
            -106.73,
            54.676,
            7.912,
            -107.029,
            53.881,
            7.912,
            -107.029,
            53.881,
            11.118,
            -107.029,
            53.881,
            11.118,
            -106.73,
            54.676,
            11.118,
            -106.73,
            54.676,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3272",
        "polygon": [
            -107.043,
            53.843,
            7.85,
            -107.186,
            53.463,
            7.85,
            -107.186,
            53.463,
            10.698,
            -107.186,
            53.463,
            10.698,
            -107.043,
            53.843,
            10.698,
            -107.043,
            53.843,
            7.85
        ],
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3273",
        "polygon": [
            -107.043,
            53.843,
            10.698,
            -107.186,
            53.463,
            10.698,
            -107.186,
            53.463,
            11.118,
            -107.186,
            53.463,
            11.118,
            -107.043,
            53.843,
            11.118,
            -107.043,
            53.843,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3274",
        "polygon": [
            -106.925,
            53.646,
            16.21,
            -106.464,
            54.873,
            16.21,
            -106.464,
            54.873,
            16.54,
            -106.464,
            54.873,
            16.54,
            -106.925,
            53.646,
            16.54,
            -106.925,
            53.646,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3275",
        "polygon": [
            -106.925,
            53.646,
            16.54,
            -106.464,
            54.873,
            16.54,
            -106.464,
            54.873,
            16.87,
            -106.464,
            54.873,
            16.87,
            -106.925,
            53.646,
            16.87,
            -106.925,
            53.646,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3276",
        "polygon": [
            -106.925,
            53.646,
            15.88,
            -106.464,
            54.873,
            15.88,
            -106.464,
            54.873,
            16.21,
            -106.464,
            54.873,
            16.21,
            -106.925,
            53.646,
            16.21,
            -106.925,
            53.646,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3277",
        "polygon": [
            -107.418,
            52.333,
            16.21,
            -106.957,
            53.56,
            16.21,
            -106.957,
            53.56,
            16.54,
            -106.957,
            53.56,
            16.54,
            -107.418,
            52.333,
            16.54,
            -107.418,
            52.333,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3278",
        "polygon": [
            -107.418,
            52.333,
            16.54,
            -106.957,
            53.56,
            16.54,
            -106.957,
            53.56,
            16.87,
            -106.957,
            53.56,
            16.87,
            -107.418,
            52.333,
            16.87,
            -107.418,
            52.333,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3279",
        "polygon": [
            -107.418,
            52.333,
            15.88,
            -106.957,
            53.56,
            15.88,
            -106.957,
            53.56,
            16.21,
            -106.957,
            53.56,
            16.21,
            -107.418,
            52.333,
            16.21,
            -107.418,
            52.333,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3280",
        "polygon": [
            -106.444,
            54.926,
            16.21,
            -105.983,
            56.152,
            16.21,
            -105.983,
            56.152,
            16.54,
            -105.983,
            56.152,
            16.54,
            -106.444,
            54.926,
            16.54,
            -106.444,
            54.926,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3281",
        "polygon": [
            -106.444,
            54.926,
            16.54,
            -105.983,
            56.152,
            16.54,
            -105.983,
            56.152,
            16.87,
            -105.983,
            56.152,
            16.87,
            -106.444,
            54.926,
            16.87,
            -106.444,
            54.926,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3282",
        "polygon": [
            -106.444,
            54.926,
            15.88,
            -105.983,
            56.152,
            15.88,
            -105.983,
            56.152,
            16.21,
            -105.983,
            56.152,
            16.21,
            -106.444,
            54.926,
            16.21,
            -106.444,
            54.926,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3283",
        "polygon": [
            -105.939,
            56.27,
            16.21,
            -105.478,
            57.496,
            16.21,
            -105.478,
            57.496,
            16.54,
            -105.478,
            57.496,
            16.54,
            -105.939,
            56.27,
            16.54,
            -105.939,
            56.27,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3284",
        "polygon": [
            -105.939,
            56.27,
            16.54,
            -105.478,
            57.496,
            16.54,
            -105.478,
            57.496,
            16.87,
            -105.478,
            57.496,
            16.87,
            -105.939,
            56.27,
            16.87,
            -105.939,
            56.27,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3285",
        "polygon": [
            -105.939,
            56.27,
            15.88,
            -105.478,
            57.496,
            15.88,
            -105.478,
            57.496,
            16.21,
            -105.478,
            57.496,
            16.21,
            -105.939,
            56.27,
            16.21,
            -105.939,
            56.27,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3286",
        "polygon": [
            -105.458,
            57.547,
            16.21,
            -104.997,
            58.773,
            16.21,
            -104.997,
            58.773,
            16.54,
            -104.997,
            58.773,
            16.54,
            -105.458,
            57.547,
            16.54,
            -105.458,
            57.547,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3287",
        "polygon": [
            -105.458,
            57.547,
            16.54,
            -104.997,
            58.773,
            16.54,
            -104.997,
            58.773,
            16.87,
            -104.997,
            58.773,
            16.87,
            -105.458,
            57.547,
            16.87,
            -105.458,
            57.547,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3288",
        "polygon": [
            -105.458,
            57.547,
            15.88,
            -104.997,
            58.773,
            15.88,
            -104.997,
            58.773,
            16.21,
            -104.997,
            58.773,
            16.21,
            -105.458,
            57.547,
            16.21,
            -105.458,
            57.547,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3289",
        "polygon": [
            -104.953,
            58.89,
            16.21,
            -104.492,
            60.117,
            16.21,
            -104.492,
            60.117,
            16.54,
            -104.492,
            60.117,
            16.54,
            -104.953,
            58.89,
            16.54,
            -104.953,
            58.89,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3290",
        "polygon": [
            -104.953,
            58.89,
            16.54,
            -104.492,
            60.117,
            16.54,
            -104.492,
            60.117,
            16.87,
            -104.492,
            60.117,
            16.87,
            -104.953,
            58.89,
            16.87,
            -104.953,
            58.89,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3291",
        "polygon": [
            -104.953,
            58.89,
            15.88,
            -104.492,
            60.117,
            15.88,
            -104.492,
            60.117,
            16.21,
            -104.492,
            60.117,
            16.21,
            -104.953,
            58.89,
            16.21,
            -104.953,
            58.89,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3292",
        "polygon": [
            -104.473,
            60.168,
            16.21,
            -104.012,
            61.394,
            16.21,
            -104.012,
            61.394,
            16.54,
            -104.012,
            61.394,
            16.54,
            -104.473,
            60.168,
            16.54,
            -104.473,
            60.168,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3293",
        "polygon": [
            -104.473,
            60.168,
            16.54,
            -104.012,
            61.394,
            16.54,
            -104.012,
            61.394,
            16.87,
            -104.012,
            61.394,
            16.87,
            -104.473,
            60.168,
            16.87,
            -104.473,
            60.168,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3294",
        "polygon": [
            -104.473,
            60.168,
            15.88,
            -104.012,
            61.394,
            15.88,
            -104.012,
            61.394,
            16.21,
            -104.012,
            61.394,
            16.21,
            -104.473,
            60.168,
            16.21,
            -104.473,
            60.168,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3295",
        "polygon": [
            -103.968,
            61.511,
            16.21,
            -103.507,
            62.737,
            16.21,
            -103.507,
            62.737,
            16.54,
            -103.507,
            62.737,
            16.54,
            -103.968,
            61.511,
            16.54,
            -103.968,
            61.511,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3296",
        "polygon": [
            -103.968,
            61.511,
            16.54,
            -103.507,
            62.737,
            16.54,
            -103.507,
            62.737,
            16.87,
            -103.507,
            62.737,
            16.87,
            -103.968,
            61.511,
            16.87,
            -103.968,
            61.511,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3297",
        "polygon": [
            -103.968,
            61.511,
            15.88,
            -103.507,
            62.737,
            15.88,
            -103.507,
            62.737,
            16.21,
            -103.507,
            62.737,
            16.21,
            -103.968,
            61.511,
            16.21,
            -103.968,
            61.511,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3298",
        "polygon": [
            -103.488,
            62.789,
            16.21,
            -103.027,
            64.015,
            16.21,
            -103.027,
            64.015,
            16.54,
            -103.027,
            64.015,
            16.54,
            -103.488,
            62.789,
            16.54,
            -103.488,
            62.789,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3299",
        "polygon": [
            -103.488,
            62.789,
            16.54,
            -103.027,
            64.015,
            16.54,
            -103.027,
            64.015,
            16.87,
            -103.027,
            64.015,
            16.87,
            -103.488,
            62.789,
            16.87,
            -103.488,
            62.789,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3300",
        "polygon": [
            -103.488,
            62.789,
            15.88,
            -103.027,
            64.015,
            15.88,
            -103.027,
            64.015,
            16.21,
            -103.027,
            64.015,
            16.21,
            -103.488,
            62.789,
            16.21,
            -103.488,
            62.789,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3301",
        "polygon": [
            -102.983,
            64.132,
            16.21,
            -102.522,
            65.358,
            16.21,
            -102.522,
            65.358,
            16.54,
            -102.522,
            65.358,
            16.54,
            -102.983,
            64.132,
            16.54,
            -102.983,
            64.132,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3302",
        "polygon": [
            -102.983,
            64.132,
            16.54,
            -102.522,
            65.358,
            16.54,
            -102.522,
            65.358,
            16.87,
            -102.522,
            65.358,
            16.87,
            -102.983,
            64.132,
            16.87,
            -102.983,
            64.132,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3303",
        "polygon": [
            -102.983,
            64.132,
            15.88,
            -102.522,
            65.358,
            15.88,
            -102.522,
            65.358,
            16.21,
            -102.522,
            65.358,
            16.21,
            -102.983,
            64.132,
            16.21,
            -102.983,
            64.132,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3304",
        "polygon": [
            -102.486,
            65.454,
            16.21,
            -102.025,
            66.681,
            16.21,
            -102.025,
            66.681,
            16.54,
            -102.025,
            66.681,
            16.54,
            -102.486,
            65.454,
            16.54,
            -102.486,
            65.454,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3305",
        "polygon": [
            -102.486,
            65.454,
            16.54,
            -102.025,
            66.681,
            16.54,
            -102.025,
            66.681,
            16.87,
            -102.025,
            66.681,
            16.87,
            -102.486,
            65.454,
            16.87,
            -102.486,
            65.454,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3306",
        "polygon": [
            -102.486,
            65.454,
            15.88,
            -102.025,
            66.681,
            15.88,
            -102.025,
            66.681,
            16.21,
            -102.025,
            66.681,
            16.21,
            -102.486,
            65.454,
            16.21,
            -102.486,
            65.454,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3307",
        "polygon": [
            -87.769,
            46.345,
            16.54,
            -88.23,
            45.119,
            16.54,
            -88.23,
            45.119,
            16.87,
            -88.23,
            45.119,
            16.87,
            -87.769,
            46.345,
            16.87,
            -87.769,
            46.345,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3308",
        "polygon": [
            -87.275,
            47.658,
            16.21,
            -87.736,
            46.432,
            16.21,
            -87.736,
            46.432,
            16.54,
            -87.736,
            46.432,
            16.54,
            -87.275,
            47.658,
            16.54,
            -87.275,
            47.658,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3309",
        "polygon": [
            -87.275,
            47.658,
            16.54,
            -87.736,
            46.432,
            16.54,
            -87.736,
            46.432,
            16.87,
            -87.736,
            46.432,
            16.87,
            -87.275,
            47.658,
            16.87,
            -87.275,
            47.658,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3310",
        "polygon": [
            -87.275,
            47.658,
            15.88,
            -87.736,
            46.432,
            15.88,
            -87.736,
            46.432,
            16.21,
            -87.736,
            46.432,
            16.21,
            -87.275,
            47.658,
            16.21,
            -87.275,
            47.658,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3311",
        "polygon": [
            -87.769,
            46.345,
            16.21,
            -88.23,
            45.119,
            16.21,
            -88.23,
            45.119,
            16.54,
            -88.23,
            45.119,
            16.54,
            -87.769,
            46.345,
            16.54,
            -87.769,
            46.345,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3312",
        "polygon": [
            -87.769,
            46.345,
            15.88,
            -88.23,
            45.119,
            15.88,
            -88.23,
            45.119,
            16.21,
            -88.23,
            45.119,
            16.21,
            -87.769,
            46.345,
            16.21,
            -87.769,
            46.345,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3313",
        "polygon": [
            -86.794,
            48.938,
            16.21,
            -87.255,
            47.712,
            16.21,
            -87.255,
            47.712,
            16.54,
            -87.255,
            47.712,
            16.54,
            -86.794,
            48.938,
            16.54,
            -86.794,
            48.938,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3314",
        "polygon": [
            -86.794,
            48.938,
            16.54,
            -87.255,
            47.712,
            16.54,
            -87.255,
            47.712,
            16.87,
            -87.255,
            47.712,
            16.87,
            -86.794,
            48.938,
            16.87,
            -86.794,
            48.938,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3315",
        "polygon": [
            -86.794,
            48.938,
            15.88,
            -87.255,
            47.712,
            15.88,
            -87.255,
            47.712,
            16.21,
            -87.255,
            47.712,
            16.21,
            -86.794,
            48.938,
            16.21,
            -86.794,
            48.938,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3316",
        "polygon": [
            -86.289,
            50.282,
            16.21,
            -86.75,
            49.055,
            16.21,
            -86.75,
            49.055,
            16.54,
            -86.75,
            49.055,
            16.54,
            -86.289,
            50.282,
            16.54,
            -86.289,
            50.282,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3317",
        "polygon": [
            -86.289,
            50.282,
            16.54,
            -86.75,
            49.055,
            16.54,
            -86.75,
            49.055,
            16.87,
            -86.75,
            49.055,
            16.87,
            -86.289,
            50.282,
            16.87,
            -86.289,
            50.282,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3318",
        "polygon": [
            -86.289,
            50.282,
            15.88,
            -86.75,
            49.055,
            15.88,
            -86.75,
            49.055,
            16.21,
            -86.75,
            49.055,
            16.21,
            -86.289,
            50.282,
            16.21,
            -86.289,
            50.282,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3319",
        "polygon": [
            -85.809,
            51.559,
            16.21,
            -86.27,
            50.333,
            16.21,
            -86.27,
            50.333,
            16.54,
            -86.27,
            50.333,
            16.54,
            -85.809,
            51.559,
            16.54,
            -85.809,
            51.559,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3320",
        "polygon": [
            -85.809,
            51.559,
            16.54,
            -86.27,
            50.333,
            16.54,
            -86.27,
            50.333,
            16.87,
            -86.27,
            50.333,
            16.87,
            -85.809,
            51.559,
            16.87,
            -85.809,
            51.559,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3321",
        "polygon": [
            -85.809,
            51.559,
            15.88,
            -86.27,
            50.333,
            15.88,
            -86.27,
            50.333,
            16.21,
            -86.27,
            50.333,
            16.21,
            -85.809,
            51.559,
            16.21,
            -85.809,
            51.559,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3322",
        "polygon": [
            -85.304,
            52.902,
            16.21,
            -85.765,
            51.676,
            16.21,
            -85.765,
            51.676,
            16.54,
            -85.765,
            51.676,
            16.54,
            -85.304,
            52.902,
            16.54,
            -85.304,
            52.902,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3323",
        "polygon": [
            -85.304,
            52.902,
            16.54,
            -85.765,
            51.676,
            16.54,
            -85.765,
            51.676,
            16.87,
            -85.765,
            51.676,
            16.87,
            -85.304,
            52.902,
            16.87,
            -85.304,
            52.902,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3324",
        "polygon": [
            -85.304,
            52.902,
            15.88,
            -85.765,
            51.676,
            15.88,
            -85.765,
            51.676,
            16.21,
            -85.765,
            51.676,
            16.21,
            -85.304,
            52.902,
            16.21,
            -85.304,
            52.902,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3325",
        "polygon": [
            -84.823,
            54.18,
            16.21,
            -85.284,
            52.954,
            16.21,
            -85.284,
            52.954,
            16.54,
            -85.284,
            52.954,
            16.54,
            -84.823,
            54.18,
            16.54,
            -84.823,
            54.18,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3326",
        "polygon": [
            -84.823,
            54.18,
            16.54,
            -85.284,
            52.954,
            16.54,
            -85.284,
            52.954,
            16.87,
            -85.284,
            52.954,
            16.87,
            -84.823,
            54.18,
            16.87,
            -84.823,
            54.18,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3327",
        "polygon": [
            -84.823,
            54.18,
            15.88,
            -85.284,
            52.954,
            15.88,
            -85.284,
            52.954,
            16.21,
            -85.284,
            52.954,
            16.21,
            -84.823,
            54.18,
            16.21,
            -84.823,
            54.18,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3328",
        "polygon": [
            -84.318,
            55.523,
            16.21,
            -84.779,
            54.297,
            16.21,
            -84.779,
            54.297,
            16.54,
            -84.779,
            54.297,
            16.54,
            -84.318,
            55.523,
            16.54,
            -84.318,
            55.523,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3329",
        "polygon": [
            -84.318,
            55.523,
            16.54,
            -84.779,
            54.297,
            16.54,
            -84.779,
            54.297,
            16.87,
            -84.779,
            54.297,
            16.87,
            -84.318,
            55.523,
            16.87,
            -84.318,
            55.523,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3330",
        "polygon": [
            -84.318,
            55.523,
            15.88,
            -84.779,
            54.297,
            15.88,
            -84.779,
            54.297,
            16.21,
            -84.779,
            54.297,
            16.21,
            -84.318,
            55.523,
            16.21,
            -84.318,
            55.523,
            15.88
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3331",
        "polygon": [
            -83.838,
            56.801,
            16.21,
            -84.299,
            55.575,
            16.21,
            -84.299,
            55.575,
            16.54,
            -84.299,
            55.575,
            16.54,
            -83.838,
            56.801,
            16.54,
            -83.838,
            56.801,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3332",
        "polygon": [
            -83.838,
            56.801,
            16.54,
            -84.299,
            55.575,
            16.54,
            -84.299,
            55.575,
            16.87,
            -84.299,
            55.575,
            16.87,
            -83.838,
            56.801,
            16.87,
            -83.838,
            56.801,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3333",
        "polygon": [
            -83.838,
            56.801,
            15.88,
            -84.299,
            55.575,
            15.88,
            -84.299,
            55.575,
            16.21,
            -84.299,
            55.575,
            16.21,
            -83.838,
            56.801,
            16.21,
            -83.838,
            56.801,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3334",
        "polygon": [
            -83.333,
            58.144,
            16.21,
            -83.794,
            56.918,
            16.21,
            -83.794,
            56.918,
            16.54,
            -83.794,
            56.918,
            16.54,
            -83.333,
            58.144,
            16.54,
            -83.333,
            58.144,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3335",
        "polygon": [
            -83.333,
            58.144,
            16.54,
            -83.794,
            56.918,
            16.54,
            -83.794,
            56.918,
            16.87,
            -83.794,
            56.918,
            16.87,
            -83.333,
            58.144,
            16.87,
            -83.333,
            58.144,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3336",
        "polygon": [
            -83.333,
            58.144,
            15.88,
            -83.794,
            56.918,
            15.88,
            -83.794,
            56.918,
            16.21,
            -83.794,
            56.918,
            16.21,
            -83.333,
            58.144,
            16.21,
            -83.333,
            58.144,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3337",
        "polygon": [
            -82.836,
            59.466,
            16.21,
            -83.297,
            58.24,
            16.21,
            -83.297,
            58.24,
            16.54,
            -83.297,
            58.24,
            16.54,
            -82.836,
            59.466,
            16.54,
            -82.836,
            59.466,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3338",
        "polygon": [
            -82.836,
            59.466,
            16.54,
            -83.297,
            58.24,
            16.54,
            -83.297,
            58.24,
            16.87,
            -83.297,
            58.24,
            16.87,
            -82.836,
            59.466,
            16.87,
            -82.836,
            59.466,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3339",
        "polygon": [
            -82.836,
            59.466,
            15.88,
            -83.297,
            58.24,
            15.88,
            -83.297,
            58.24,
            16.21,
            -83.297,
            58.24,
            16.21,
            -82.836,
            59.466,
            16.21,
            -82.836,
            59.466,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3340",
        "polygon": [
            -88.28,
            45.072,
            16.555,
            -91.921,
            46.44,
            16.555,
            -91.921,
            46.44,
            16.91,
            -91.921,
            46.44,
            16.91,
            -88.28,
            45.072,
            16.91,
            -88.28,
            45.072,
            16.555
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3341",
        "polygon": [
            -88.28,
            45.072,
            16.2,
            -91.921,
            46.44,
            16.2,
            -91.921,
            46.44,
            16.555,
            -91.921,
            46.44,
            16.555,
            -88.28,
            45.072,
            16.555,
            -88.28,
            45.072,
            16.2
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3342",
        "polygon": [
            -88.28,
            45.072,
            15.845,
            -91.921,
            46.44,
            15.845,
            -91.921,
            46.44,
            16.2,
            -91.921,
            46.44,
            16.2,
            -88.28,
            45.072,
            16.2,
            -88.28,
            45.072,
            15.845
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3343",
        "polygon": [
            -91.977,
            46.462,
            16.555,
            -95.852,
            47.918,
            16.555,
            -95.852,
            47.918,
            16.91,
            -95.852,
            47.918,
            16.91,
            -91.977,
            46.462,
            16.91,
            -91.977,
            46.462,
            16.555
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3344",
        "polygon": [
            -91.977,
            46.462,
            16.2,
            -95.852,
            47.918,
            16.2,
            -95.852,
            47.918,
            16.555,
            -95.852,
            47.918,
            16.555,
            -91.977,
            46.462,
            16.555,
            -91.977,
            46.462,
            16.2
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3345",
        "polygon": [
            -91.977,
            46.462,
            15.845,
            -95.852,
            47.918,
            15.845,
            -95.852,
            47.918,
            16.2,
            -95.852,
            47.918,
            16.2,
            -91.977,
            46.462,
            16.2,
            -91.977,
            46.462,
            15.845
        ],
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3346",
        "polygon": [
            -95.908,
            47.94,
            16.555,
            -99.784,
            49.397,
            16.555,
            -99.784,
            49.397,
            16.91,
            -99.784,
            49.397,
            16.91,
            -95.908,
            47.94,
            16.91,
            -95.908,
            47.94,
            16.555
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3347",
        "polygon": [
            -95.908,
            47.94,
            16.2,
            -99.784,
            49.397,
            16.2,
            -99.784,
            49.397,
            16.555,
            -99.784,
            49.397,
            16.555,
            -95.908,
            47.94,
            16.555,
            -95.908,
            47.94,
            16.2
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3348",
        "polygon": [
            -95.908,
            47.94,
            15.845,
            -99.784,
            49.397,
            15.845,
            -99.784,
            49.397,
            16.2,
            -99.784,
            49.397,
            16.2,
            -95.908,
            47.94,
            16.2,
            -95.908,
            47.94,
            15.845
        ],
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3349",
        "polygon": [
            -99.84,
            49.418,
            16.555,
            -103.715,
            50.875,
            16.555,
            -103.715,
            50.875,
            16.91,
            -103.715,
            50.875,
            16.91,
            -99.84,
            49.418,
            16.91,
            -99.84,
            49.418,
            16.555
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3350",
        "polygon": [
            -99.84,
            49.418,
            16.2,
            -103.715,
            50.875,
            16.2,
            -103.715,
            50.875,
            16.555,
            -103.715,
            50.875,
            16.555,
            -99.84,
            49.418,
            16.555,
            -99.84,
            49.418,
            16.2
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3351",
        "polygon": [
            -99.84,
            49.418,
            15.845,
            -103.715,
            50.875,
            15.845,
            -103.715,
            50.875,
            16.2,
            -103.715,
            50.875,
            16.2,
            -99.84,
            49.418,
            16.2,
            -99.84,
            49.418,
            15.845
        ],
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3352",
        "polygon": [
            -103.771,
            50.896,
            16.555,
            -107.412,
            52.265,
            16.555,
            -107.412,
            52.265,
            16.91,
            -107.412,
            52.265,
            16.91,
            -103.771,
            50.896,
            16.91,
            -103.771,
            50.896,
            16.555
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3353",
        "polygon": [
            -103.771,
            50.896,
            16.2,
            -107.412,
            52.265,
            16.2,
            -107.412,
            52.265,
            16.555,
            -107.412,
            52.265,
            16.555,
            -103.771,
            50.896,
            16.555,
            -103.771,
            50.896,
            16.2
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3354",
        "polygon": [
            -103.771,
            50.896,
            15.845,
            -107.412,
            52.265,
            15.845,
            -107.412,
            52.265,
            16.2,
            -107.412,
            52.265,
            16.2,
            -103.771,
            50.896,
            16.2,
            -103.771,
            50.896,
            15.845
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3355",
        "polygon": [
            -91.977,
            46.462,
            16.91,
            -95.852,
            47.918,
            16.91,
            -95.852,
            47.918,
            17.176,
            -95.852,
            47.918,
            17.176,
            -91.977,
            46.462,
            17.176,
            -91.977,
            46.462,
            16.91
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3356",
        "polygon": [
            -95.908,
            47.94,
            16.91,
            -99.784,
            49.397,
            16.91,
            -99.784,
            49.397,
            17.344,
            -99.784,
            49.397,
            17.344,
            -95.908,
            47.94,
            17.344,
            -95.908,
            47.94,
            16.91
        ],
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3357",
        "polygon": [
            -99.84,
            49.418,
            16.91,
            -103.715,
            50.875,
            16.91,
            -103.715,
            50.875,
            17.176,
            -103.715,
            50.875,
            17.176,
            -99.84,
            49.418,
            17.176,
            -99.84,
            49.418,
            16.91
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3358",
        "polygon": [
            -84.547,
            54.404,
            4.202,
            -84.248,
            55.198,
            4.202,
            -84.248,
            55.198,
            7.408,
            -84.248,
            55.198,
            7.408,
            -84.547,
            54.404,
            7.408,
            -84.547,
            54.404,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3359",
        "polygon": [
            -84.704,
            53.985,
            6.988,
            -84.562,
            54.364,
            6.988,
            -84.562,
            54.364,
            7.408,
            -84.562,
            54.364,
            7.408,
            -84.704,
            53.985,
            7.408,
            -84.704,
            53.985,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3360",
        "polygon": [
            -84.704,
            53.985,
            4.14,
            -84.562,
            54.364,
            4.14,
            -84.562,
            54.364,
            6.988,
            -84.562,
            54.364,
            6.988,
            -84.704,
            53.985,
            6.988,
            -84.704,
            53.985,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3361",
        "polygon": [
            -85.197,
            52.675,
            4.202,
            -84.741,
            53.888,
            4.202,
            -84.741,
            53.888,
            7.408,
            -84.741,
            53.888,
            7.408,
            -85.197,
            52.675,
            7.408,
            -85.197,
            52.675,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3362",
        "polygon": [
            -85.532,
            51.783,
            4.202,
            -85.233,
            52.578,
            4.202,
            -85.233,
            52.578,
            7.408,
            -85.233,
            52.578,
            7.408,
            -85.532,
            51.783,
            7.408,
            -85.532,
            51.783,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3363",
        "polygon": [
            -85.69,
            51.364,
            6.988,
            -85.547,
            51.744,
            6.988,
            -85.547,
            51.744,
            7.408,
            -85.547,
            51.744,
            7.408,
            -85.69,
            51.364,
            7.408,
            -85.69,
            51.364,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3364",
        "polygon": [
            -85.69,
            51.364,
            4.14,
            -85.547,
            51.744,
            4.14,
            -85.547,
            51.744,
            6.988,
            -85.547,
            51.744,
            6.988,
            -85.69,
            51.364,
            6.988,
            -85.69,
            51.364,
            4.14
        ],
        "05-24 15:15": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3365",
        "polygon": [
            -86.182,
            50.054,
            4.202,
            -85.726,
            51.267,
            4.202,
            -85.726,
            51.267,
            7.408,
            -85.726,
            51.267,
            7.408,
            -86.182,
            50.054,
            7.408,
            -86.182,
            50.054,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3366",
        "polygon": [
            -86.518,
            49.162,
            4.202,
            -86.219,
            49.957,
            4.202,
            -86.219,
            49.957,
            7.408,
            -86.219,
            49.957,
            7.408,
            -86.518,
            49.162,
            7.408,
            -86.518,
            49.162,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3367",
        "polygon": [
            -86.675,
            48.744,
            6.988,
            -86.532,
            49.123,
            6.988,
            -86.532,
            49.123,
            7.408,
            -86.532,
            49.123,
            7.408,
            -86.675,
            48.744,
            7.408,
            -86.675,
            48.744,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3368",
        "polygon": [
            -86.675,
            48.744,
            4.14,
            -86.532,
            49.123,
            4.14,
            -86.532,
            49.123,
            6.988,
            -86.532,
            49.123,
            6.988,
            -86.675,
            48.744,
            6.988,
            -86.675,
            48.744,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3369",
        "polygon": [
            -87.168,
            47.433,
            4.202,
            -86.711,
            48.646,
            4.202,
            -86.711,
            48.646,
            7.408,
            -86.711,
            48.646,
            7.408,
            -87.168,
            47.433,
            7.408,
            -87.168,
            47.433,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3370",
        "polygon": [
            -87.503,
            46.541,
            4.202,
            -87.204,
            47.336,
            4.202,
            -87.204,
            47.336,
            7.408,
            -87.204,
            47.336,
            7.408,
            -87.503,
            46.541,
            7.408,
            -87.503,
            46.541,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3371",
        "polygon": [
            -87.66,
            46.123,
            6.988,
            -87.518,
            46.502,
            6.988,
            -87.518,
            46.502,
            7.408,
            -87.518,
            46.502,
            7.408,
            -87.66,
            46.123,
            7.408,
            -87.66,
            46.123,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3372",
        "polygon": [
            -87.66,
            46.123,
            4.14,
            -87.518,
            46.502,
            4.14,
            -87.518,
            46.502,
            6.988,
            -87.518,
            46.502,
            6.988,
            -87.66,
            46.123,
            6.988,
            -87.66,
            46.123,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3373",
        "polygon": [
            -88.065,
            45.046,
            4.202,
            -87.697,
            46.025,
            4.202,
            -87.697,
            46.025,
            7.408,
            -87.697,
            46.025,
            7.408,
            -88.065,
            45.046,
            7.408,
            -88.065,
            45.046,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3374",
        "polygon": [
            -84.211,
            55.296,
            4.202,
            -83.63,
            56.841,
            4.202,
            -83.63,
            56.841,
            7.408,
            -83.63,
            56.841,
            7.408,
            -84.211,
            55.296,
            7.408,
            -84.211,
            55.296,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3375",
        "polygon": [
            -83.383,
            57.499,
            4.11,
            -82.975,
            58.585,
            4.11,
            -82.975,
            58.585,
            7.06,
            -82.975,
            58.585,
            7.06,
            -83.383,
            57.499,
            7.06,
            -83.383,
            57.499,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3376",
        "polygon": [
            -83.517,
            57.143,
            4.11,
            -83.382,
            57.503,
            4.11,
            -83.382,
            57.503,
            7.06,
            -83.382,
            57.503,
            7.06,
            -83.517,
            57.143,
            7.06,
            -83.517,
            57.143,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3377",
        "polygon": [
            -82.952,
            58.645,
            4.16,
            -82.822,
            58.991,
            4.16,
            -82.822,
            58.991,
            7.06,
            -82.822,
            58.991,
            7.06,
            -82.952,
            58.645,
            7.06,
            -82.952,
            58.645,
            4.16
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3378",
        "polygon": [
            -84.704,
            53.985,
            7.912,
            -84.405,
            54.78,
            7.912,
            -84.405,
            54.78,
            11.118,
            -84.405,
            54.78,
            11.118,
            -84.704,
            53.985,
            11.118,
            -84.704,
            53.985,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3379",
        "polygon": [
            -85.197,
            52.675,
            7.912,
            -84.741,
            53.888,
            7.912,
            -84.741,
            53.888,
            11.118,
            -84.741,
            53.888,
            11.118,
            -85.197,
            52.675,
            11.118,
            -85.197,
            52.675,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3380",
        "polygon": [
            -84.391,
            54.819,
            10.698,
            -84.248,
            55.198,
            10.698,
            -84.248,
            55.198,
            11.118,
            -84.248,
            55.198,
            11.118,
            -84.391,
            54.819,
            11.118,
            -84.391,
            54.819,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3381",
        "polygon": [
            -84.391,
            54.819,
            7.85,
            -84.248,
            55.198,
            7.85,
            -84.248,
            55.198,
            10.698,
            -84.248,
            55.198,
            10.698,
            -84.391,
            54.819,
            10.698,
            -84.391,
            54.819,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3382",
        "polygon": [
            -85.69,
            51.364,
            7.912,
            -85.391,
            52.159,
            7.912,
            -85.391,
            52.159,
            11.118,
            -85.391,
            52.159,
            11.118,
            -85.69,
            51.364,
            11.118,
            -85.69,
            51.364,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3383",
        "polygon": [
            -86.182,
            50.054,
            7.912,
            -85.726,
            51.267,
            7.912,
            -85.726,
            51.267,
            11.118,
            -85.726,
            51.267,
            11.118,
            -86.182,
            50.054,
            11.118,
            -86.182,
            50.054,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3384",
        "polygon": [
            -85.376,
            52.198,
            10.698,
            -85.233,
            52.578,
            10.698,
            -85.233,
            52.578,
            11.118,
            -85.233,
            52.578,
            11.118,
            -85.376,
            52.198,
            11.118,
            -85.376,
            52.198,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3385",
        "polygon": [
            -85.376,
            52.198,
            7.85,
            -85.233,
            52.578,
            7.85,
            -85.233,
            52.578,
            10.698,
            -85.233,
            52.578,
            10.698,
            -85.376,
            52.198,
            10.698,
            -85.376,
            52.198,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3386",
        "polygon": [
            -86.675,
            48.744,
            7.912,
            -86.376,
            49.538,
            7.912,
            -86.376,
            49.538,
            11.118,
            -86.376,
            49.538,
            11.118,
            -86.675,
            48.744,
            11.118,
            -86.675,
            48.744,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3387",
        "polygon": [
            -87.168,
            47.433,
            7.912,
            -86.711,
            48.646,
            7.912,
            -86.711,
            48.646,
            11.118,
            -86.711,
            48.646,
            11.118,
            -87.168,
            47.433,
            11.118,
            -87.168,
            47.433,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3388",
        "polygon": [
            -86.361,
            49.578,
            10.698,
            -86.219,
            49.957,
            10.698,
            -86.219,
            49.957,
            11.118,
            -86.219,
            49.957,
            11.118,
            -86.361,
            49.578,
            11.118,
            -86.361,
            49.578,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3389",
        "polygon": [
            -86.361,
            49.578,
            7.85,
            -86.219,
            49.957,
            7.85,
            -86.219,
            49.957,
            10.698,
            -86.219,
            49.957,
            10.698,
            -86.361,
            49.578,
            10.698,
            -86.361,
            49.578,
            7.85
        ],
        "05-24 14:45": 1,
        "05-24 15:15": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3390",
        "polygon": [
            -87.66,
            46.123,
            7.912,
            -87.361,
            46.917,
            7.912,
            -87.361,
            46.917,
            11.118,
            -87.361,
            46.917,
            11.118,
            -87.66,
            46.123,
            11.118,
            -87.66,
            46.123,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3391",
        "polygon": [
            -87.347,
            46.957,
            10.698,
            -87.204,
            47.336,
            10.698,
            -87.204,
            47.336,
            11.118,
            -87.204,
            47.336,
            11.118,
            -87.347,
            46.957,
            11.118,
            -87.347,
            46.957,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3392",
        "polygon": [
            -87.347,
            46.957,
            7.85,
            -87.204,
            47.336,
            7.85,
            -87.204,
            47.336,
            10.698,
            -87.204,
            47.336,
            10.698,
            -87.347,
            46.957,
            10.698,
            -87.347,
            46.957,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3393",
        "polygon": [
            -88.065,
            45.046,
            7.912,
            -87.697,
            46.025,
            7.912,
            -87.697,
            46.025,
            11.118,
            -87.697,
            46.025,
            11.118,
            -88.065,
            45.046,
            11.118,
            -88.065,
            45.046,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3394",
        "polygon": [
            -84.211,
            55.296,
            7.912,
            -83.63,
            56.841,
            7.912,
            -83.63,
            56.841,
            11.118,
            -83.63,
            56.841,
            11.118,
            -84.211,
            55.296,
            11.118,
            -84.211,
            55.296,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3395",
        "polygon": [
            -83.368,
            57.54,
            7.82,
            -82.959,
            58.626,
            7.82,
            -82.959,
            58.626,
            10.77,
            -82.959,
            58.626,
            10.77,
            -83.368,
            57.54,
            10.77,
            -83.368,
            57.54,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3396",
        "polygon": [
            -83.531,
            57.105,
            7.82,
            -83.368,
            57.54,
            7.82,
            -83.368,
            57.54,
            10.77,
            -83.368,
            57.54,
            10.77,
            -83.531,
            57.105,
            10.77,
            -83.531,
            57.105,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3397",
        "polygon": [
            -82.942,
            58.673,
            7.87,
            -82.813,
            59.015,
            7.87,
            -82.813,
            59.015,
            10.77,
            -82.813,
            59.015,
            10.77,
            -82.942,
            58.673,
            10.77,
            -82.942,
            58.673,
            7.87
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3398",
        "polygon": [
            -84.547,
            54.404,
            11.622,
            -84.248,
            55.198,
            11.622,
            -84.248,
            55.198,
            14.828,
            -84.248,
            55.198,
            14.828,
            -84.547,
            54.404,
            14.828,
            -84.547,
            54.404,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3399",
        "polygon": [
            -84.704,
            53.985,
            14.408,
            -84.562,
            54.364,
            14.408,
            -84.562,
            54.364,
            14.828,
            -84.562,
            54.364,
            14.828,
            -84.704,
            53.985,
            14.828,
            -84.704,
            53.985,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3400",
        "polygon": [
            -84.704,
            53.985,
            11.56,
            -84.562,
            54.364,
            11.56,
            -84.562,
            54.364,
            14.408,
            -84.562,
            54.364,
            14.408,
            -84.704,
            53.985,
            14.408,
            -84.704,
            53.985,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3401",
        "polygon": [
            -85.197,
            52.675,
            11.622,
            -84.741,
            53.888,
            11.622,
            -84.741,
            53.888,
            14.828,
            -84.741,
            53.888,
            14.828,
            -85.197,
            52.675,
            14.828,
            -85.197,
            52.675,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3402",
        "polygon": [
            -85.532,
            51.783,
            11.622,
            -85.233,
            52.578,
            11.622,
            -85.233,
            52.578,
            14.828,
            -85.233,
            52.578,
            14.828,
            -85.532,
            51.783,
            14.828,
            -85.532,
            51.783,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3403",
        "polygon": [
            -85.69,
            51.364,
            14.408,
            -85.547,
            51.744,
            14.408,
            -85.547,
            51.744,
            14.828,
            -85.547,
            51.744,
            14.828,
            -85.69,
            51.364,
            14.828,
            -85.69,
            51.364,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3404",
        "polygon": [
            -85.69,
            51.364,
            11.56,
            -85.547,
            51.744,
            11.56,
            -85.547,
            51.744,
            14.408,
            -85.547,
            51.744,
            14.408,
            -85.69,
            51.364,
            14.408,
            -85.69,
            51.364,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3405",
        "polygon": [
            -86.182,
            50.054,
            11.622,
            -85.726,
            51.267,
            11.622,
            -85.726,
            51.267,
            14.828,
            -85.726,
            51.267,
            14.828,
            -86.182,
            50.054,
            14.828,
            -86.182,
            50.054,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3406",
        "polygon": [
            -86.518,
            49.162,
            11.622,
            -86.219,
            49.957,
            11.622,
            -86.219,
            49.957,
            14.828,
            -86.219,
            49.957,
            14.828,
            -86.518,
            49.162,
            14.828,
            -86.518,
            49.162,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3407",
        "polygon": [
            -86.675,
            48.744,
            14.408,
            -86.532,
            49.123,
            14.408,
            -86.532,
            49.123,
            14.828,
            -86.532,
            49.123,
            14.828,
            -86.675,
            48.744,
            14.828,
            -86.675,
            48.744,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3408",
        "polygon": [
            -86.675,
            48.744,
            11.56,
            -86.532,
            49.123,
            11.56,
            -86.532,
            49.123,
            14.408,
            -86.532,
            49.123,
            14.408,
            -86.675,
            48.744,
            14.408,
            -86.675,
            48.744,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3409",
        "polygon": [
            -87.168,
            47.433,
            11.622,
            -86.711,
            48.646,
            11.622,
            -86.711,
            48.646,
            14.828,
            -86.711,
            48.646,
            14.828,
            -87.168,
            47.433,
            14.828,
            -87.168,
            47.433,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3410",
        "polygon": [
            -87.503,
            46.541,
            11.622,
            -87.204,
            47.336,
            11.622,
            -87.204,
            47.336,
            14.828,
            -87.204,
            47.336,
            14.828,
            -87.503,
            46.541,
            14.828,
            -87.503,
            46.541,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3411",
        "polygon": [
            -87.66,
            46.123,
            14.408,
            -87.518,
            46.502,
            14.408,
            -87.518,
            46.502,
            14.828,
            -87.518,
            46.502,
            14.828,
            -87.66,
            46.123,
            14.828,
            -87.66,
            46.123,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3412",
        "polygon": [
            -87.66,
            46.123,
            11.56,
            -87.518,
            46.502,
            11.56,
            -87.518,
            46.502,
            14.408,
            -87.518,
            46.502,
            14.408,
            -87.66,
            46.123,
            14.408,
            -87.66,
            46.123,
            11.56
        ],
        "05-24 17:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3413",
        "polygon": [
            -88.065,
            45.046,
            11.622,
            -87.697,
            46.025,
            11.622,
            -87.697,
            46.025,
            14.828,
            -87.697,
            46.025,
            14.828,
            -88.065,
            45.046,
            14.828,
            -88.065,
            45.046,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3414",
        "polygon": [
            -84.211,
            55.296,
            11.622,
            -83.63,
            56.841,
            11.622,
            -83.63,
            56.841,
            14.828,
            -83.63,
            56.841,
            14.828,
            -84.211,
            55.296,
            14.828,
            -84.211,
            55.296,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3415",
        "polygon": [
            -83.383,
            57.499,
            11.53,
            -82.975,
            58.585,
            11.53,
            -82.975,
            58.585,
            14.48,
            -82.975,
            58.585,
            14.48,
            -83.383,
            57.499,
            14.48,
            -83.383,
            57.499,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3416",
        "polygon": [
            -83.517,
            57.143,
            11.53,
            -83.382,
            57.503,
            11.53,
            -83.382,
            57.503,
            14.48,
            -83.382,
            57.503,
            14.48,
            -83.517,
            57.143,
            14.48,
            -83.517,
            57.143,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3417",
        "polygon": [
            -82.952,
            58.645,
            11.58,
            -82.822,
            58.991,
            11.58,
            -82.822,
            58.991,
            14.48,
            -82.822,
            58.991,
            14.48,
            -82.952,
            58.645,
            14.48,
            -82.952,
            58.645,
            11.58
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_15",
        "polygon": [
            -81.748,
            61.848,
            4.202,
            -81.292,
            63.061,
            4.202,
            -81.292,
            63.061,
            7.408,
            -81.292,
            63.061,
            7.408,
            -81.748,
            61.848,
            7.408,
            -81.748,
            61.848,
            4.202
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_15",
        "polygon": [
            -81.748,
            61.848,
            7.912,
            -81.292,
            63.061,
            7.912,
            -81.292,
            63.061,
            11.118,
            -81.292,
            63.061,
            11.118,
            -81.748,
            61.848,
            11.118,
            -81.748,
            61.848,
            7.912
        ],
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_15",
        "polygon": [
            -81.748,
            61.848,
            11.622,
            -81.292,
            63.061,
            11.622,
            -81.292,
            63.061,
            14.828,
            -81.292,
            63.061,
            14.828,
            -81.748,
            61.848,
            14.828,
            -81.748,
            61.848,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_13",
        "polygon": [
            -80.763,
            64.469,
            4.202,
            -80.307,
            65.682,
            4.202,
            -80.307,
            65.682,
            7.408,
            -80.307,
            65.682,
            7.408,
            -80.763,
            64.469,
            7.408,
            -80.763,
            64.469,
            4.202
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_13",
        "polygon": [
            -80.763,
            64.469,
            7.912,
            -80.307,
            65.682,
            7.912,
            -80.307,
            65.682,
            11.118,
            -80.307,
            65.682,
            11.118,
            -80.763,
            64.469,
            11.118,
            -80.763,
            64.469,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_13",
        "polygon": [
            -80.763,
            64.469,
            11.622,
            -80.307,
            65.682,
            11.622,
            -80.307,
            65.682,
            14.828,
            -80.307,
            65.682,
            14.828,
            -80.763,
            64.469,
            14.828,
            -80.763,
            64.469,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_11",
        "polygon": [
            -79.777,
            67.09,
            4.202,
            -79.321,
            68.303,
            4.202,
            -79.321,
            68.303,
            7.408,
            -79.321,
            68.303,
            7.408,
            -79.777,
            67.09,
            7.408,
            -79.777,
            67.09,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_11",
        "polygon": [
            -79.777,
            67.09,
            7.912,
            -79.321,
            68.303,
            7.912,
            -79.321,
            68.303,
            11.118,
            -79.321,
            68.303,
            11.118,
            -79.777,
            67.09,
            11.118,
            -79.777,
            67.09,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_11",
        "polygon": [
            -79.777,
            67.09,
            11.622,
            -79.321,
            68.303,
            11.622,
            -79.321,
            68.303,
            14.828,
            -79.321,
            68.303,
            14.828,
            -79.777,
            67.09,
            14.828,
            -79.777,
            67.09,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_9",
        "polygon": [
            -78.792,
            69.711,
            4.202,
            -78.336,
            70.924,
            4.202,
            -78.336,
            70.924,
            7.408,
            -78.336,
            70.924,
            7.408,
            -78.792,
            69.711,
            7.408,
            -78.792,
            69.711,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_9",
        "polygon": [
            -78.792,
            69.711,
            7.912,
            -78.336,
            70.924,
            7.912,
            -78.336,
            70.924,
            11.118,
            -78.336,
            70.924,
            11.118,
            -78.792,
            69.711,
            11.118,
            -78.792,
            69.711,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_9",
        "polygon": [
            -78.792,
            69.711,
            11.622,
            -78.336,
            70.924,
            11.622,
            -78.336,
            70.924,
            14.828,
            -78.336,
            70.924,
            14.828,
            -78.792,
            69.711,
            14.828,
            -78.792,
            69.711,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_7",
        "polygon": [
            -77.807,
            72.332,
            4.202,
            -77.351,
            73.545,
            4.202,
            -77.351,
            73.545,
            7.408,
            -77.351,
            73.545,
            7.408,
            -77.807,
            72.332,
            7.408,
            -77.807,
            72.332,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_7",
        "polygon": [
            -77.807,
            72.332,
            7.912,
            -77.351,
            73.545,
            7.912,
            -77.351,
            73.545,
            11.118,
            -77.351,
            73.545,
            11.118,
            -77.807,
            72.332,
            11.118,
            -77.807,
            72.332,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_7",
        "polygon": [
            -77.807,
            72.332,
            11.622,
            -77.351,
            73.545,
            11.622,
            -77.351,
            73.545,
            14.828,
            -77.351,
            73.545,
            14.828,
            -77.807,
            72.332,
            14.828,
            -77.807,
            72.332,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_5",
        "polygon": [
            -76.821,
            74.952,
            4.202,
            -76.365,
            76.166,
            4.202,
            -76.365,
            76.166,
            7.408,
            -76.365,
            76.166,
            7.408,
            -76.821,
            74.952,
            7.408,
            -76.821,
            74.952,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_5",
        "polygon": [
            -76.821,
            74.952,
            7.912,
            -76.365,
            76.166,
            7.912,
            -76.365,
            76.166,
            11.118,
            -76.365,
            76.166,
            11.118,
            -76.821,
            74.952,
            11.118,
            -76.821,
            74.952,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_5",
        "polygon": [
            -76.821,
            74.952,
            11.622,
            -76.365,
            76.166,
            11.622,
            -76.365,
            76.166,
            14.828,
            -76.365,
            76.166,
            14.828,
            -76.821,
            74.952,
            14.828,
            -76.821,
            74.952,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_3",
        "polygon": [
            -75.836,
            77.573,
            4.202,
            -75.38,
            78.786,
            4.202,
            -75.38,
            78.786,
            7.408,
            -75.38,
            78.786,
            7.408,
            -75.836,
            77.573,
            7.408,
            -75.836,
            77.573,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_3",
        "polygon": [
            -75.836,
            77.573,
            7.912,
            -75.38,
            78.786,
            7.912,
            -75.38,
            78.786,
            11.118,
            -75.38,
            78.786,
            11.118,
            -75.836,
            77.573,
            11.118,
            -75.836,
            77.573,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_3",
        "polygon": [
            -75.836,
            77.573,
            11.622,
            -75.38,
            78.786,
            11.622,
            -75.38,
            78.786,
            14.828,
            -75.38,
            78.786,
            14.828,
            -75.836,
            77.573,
            14.828,
            -75.836,
            77.573,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_1",
        "polygon": [
            -74.851,
            80.194,
            4.202,
            -74.331,
            81.578,
            4.202,
            -74.331,
            81.578,
            7.408,
            -74.331,
            81.578,
            7.408,
            -74.851,
            80.194,
            7.408,
            -74.851,
            80.194,
            4.202
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:30": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_1",
        "polygon": [
            -74.851,
            80.194,
            7.912,
            -74.331,
            81.578,
            7.912,
            -74.331,
            81.578,
            11.118,
            -74.331,
            81.578,
            11.118,
            -74.851,
            80.194,
            11.118,
            -74.851,
            80.194,
            7.912
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_1",
        "polygon": [
            -74.851,
            80.194,
            11.622,
            -74.331,
            81.578,
            11.622,
            -74.331,
            81.578,
            14.828,
            -74.331,
            81.578,
            14.828,
            -74.851,
            80.194,
            14.828,
            -74.851,
            80.194,
            11.622
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_3",
        "polygon": [
            -75.836,
            77.573,
            0.142,
            -75.38,
            78.786,
            0.142,
            -75.38,
            78.786,
            3.698,
            -75.38,
            78.786,
            3.698,
            -75.836,
            77.573,
            3.698,
            -75.836,
            77.573,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_1",
        "polygon": [
            -74.851,
            80.194,
            0.142,
            -74.331,
            81.578,
            0.142,
            -74.331,
            81.578,
            3.698,
            -74.331,
            81.578,
            3.698,
            -74.851,
            80.194,
            3.698,
            -74.851,
            80.194,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_5",
        "polygon": [
            -76.821,
            74.952,
            0.142,
            -76.365,
            76.166,
            0.142,
            -76.365,
            76.166,
            3.698,
            -76.365,
            76.166,
            3.698,
            -76.821,
            74.952,
            3.698,
            -76.821,
            74.952,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_7",
        "polygon": [
            -77.807,
            72.332,
            0.142,
            -77.351,
            73.545,
            0.142,
            -77.351,
            73.545,
            3.698,
            -77.351,
            73.545,
            3.698,
            -77.807,
            72.332,
            3.698,
            -77.807,
            72.332,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_11",
        "polygon": [
            -79.777,
            67.09,
            0.142,
            -79.321,
            68.303,
            0.142,
            -79.321,
            68.303,
            3.698,
            -79.321,
            68.303,
            3.698,
            -79.777,
            67.09,
            3.698,
            -79.777,
            67.09,
            0.142
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_9",
        "polygon": [
            -78.792,
            69.711,
            0.142,
            -78.336,
            70.924,
            0.142,
            -78.336,
            70.924,
            3.698,
            -78.336,
            70.924,
            3.698,
            -78.792,
            69.711,
            3.698,
            -78.792,
            69.711,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_13",
        "polygon": [
            -80.763,
            64.469,
            0.142,
            -80.307,
            65.682,
            0.142,
            -80.307,
            65.682,
            3.698,
            -80.307,
            65.682,
            3.698,
            -80.763,
            64.469,
            3.698,
            -80.763,
            64.469,
            0.142
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_15",
        "polygon": [
            -81.748,
            61.848,
            0.142,
            -81.292,
            63.061,
            0.142,
            -81.292,
            63.061,
            3.698,
            -81.292,
            63.061,
            3.698,
            -81.748,
            61.848,
            3.698,
            -81.748,
            61.848,
            0.142
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_17",
        "polygon": [
            -82.669,
            59.399,
            0.142,
            -82.274,
            60.45,
            0.142,
            -82.274,
            60.45,
            3.698,
            -82.274,
            60.45,
            3.698,
            -82.669,
            59.399,
            3.698,
            -82.669,
            59.399,
            0.142
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_17",
        "polygon": [
            -82.669,
            59.399,
            4.202,
            -82.274,
            60.45,
            4.202,
            -82.274,
            60.45,
            7.408,
            -82.274,
            60.45,
            7.408,
            -82.669,
            59.399,
            7.408,
            -82.669,
            59.399,
            4.202
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_17",
        "polygon": [
            -82.669,
            59.399,
            7.912,
            -82.274,
            60.45,
            7.912,
            -82.274,
            60.45,
            11.118,
            -82.274,
            60.45,
            11.118,
            -82.669,
            59.399,
            11.118,
            -82.669,
            59.399,
            7.912
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_17",
        "polygon": [
            -82.669,
            59.399,
            11.622,
            -82.274,
            60.45,
            11.622,
            -82.274,
            60.45,
            14.828,
            -82.274,
            60.45,
            14.828,
            -82.669,
            59.399,
            14.828,
            -82.669,
            59.399,
            11.622
        ],
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3557",
        "polygon": [
            -89.343,
            45.279,
            4.202,
            -88.364,
            44.911,
            4.202,
            -88.364,
            44.911,
            7.408,
            -88.364,
            44.911,
            7.408,
            -89.343,
            45.279,
            7.408,
            -89.343,
            45.279,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3558",
        "polygon": [
            -90.235,
            45.614,
            4.202,
            -89.44,
            45.315,
            4.202,
            -89.44,
            45.315,
            7.408,
            -89.44,
            45.315,
            7.408,
            -90.235,
            45.614,
            7.408,
            -90.235,
            45.614,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3559",
        "polygon": [
            -90.653,
            45.772,
            6.988,
            -90.274,
            45.629,
            6.988,
            -90.274,
            45.629,
            7.408,
            -90.274,
            45.629,
            7.408,
            -90.653,
            45.772,
            7.408,
            -90.653,
            45.772,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3560",
        "polygon": [
            -90.653,
            45.772,
            4.14,
            -90.274,
            45.629,
            4.14,
            -90.274,
            45.629,
            6.988,
            -90.274,
            45.629,
            6.988,
            -90.653,
            45.772,
            6.988,
            -90.653,
            45.772,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3561",
        "polygon": [
            -91.964,
            46.264,
            4.202,
            -90.751,
            45.808,
            4.202,
            -90.751,
            45.808,
            7.408,
            -90.751,
            45.808,
            7.408,
            -91.964,
            46.264,
            7.408,
            -91.964,
            46.264,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3562",
        "polygon": [
            -92.856,
            46.6,
            4.202,
            -92.061,
            46.301,
            4.202,
            -92.061,
            46.301,
            7.408,
            -92.061,
            46.301,
            7.408,
            -92.856,
            46.6,
            7.408,
            -92.856,
            46.6,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3563",
        "polygon": [
            -93.274,
            46.757,
            6.988,
            -92.895,
            46.614,
            6.988,
            -92.895,
            46.614,
            7.408,
            -92.895,
            46.614,
            7.408,
            -93.274,
            46.757,
            7.408,
            -93.274,
            46.757,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3564",
        "polygon": [
            -93.274,
            46.757,
            4.14,
            -92.895,
            46.614,
            4.14,
            -92.895,
            46.614,
            6.988,
            -92.895,
            46.614,
            6.988,
            -93.274,
            46.757,
            6.988,
            -93.274,
            46.757,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3565",
        "polygon": [
            -94.585,
            47.25,
            4.202,
            -93.371,
            46.793,
            4.202,
            -93.371,
            46.793,
            7.408,
            -93.371,
            46.793,
            7.408,
            -94.585,
            47.25,
            7.408,
            -94.585,
            47.25,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3566",
        "polygon": [
            -95.477,
            47.585,
            4.202,
            -94.682,
            47.286,
            4.202,
            -94.682,
            47.286,
            7.408,
            -94.682,
            47.286,
            7.408,
            -95.477,
            47.585,
            7.408,
            -95.477,
            47.585,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3567",
        "polygon": [
            -95.895,
            47.742,
            6.988,
            -95.516,
            47.6,
            6.988,
            -95.516,
            47.6,
            7.408,
            -95.516,
            47.6,
            7.408,
            -95.895,
            47.742,
            7.408,
            -95.895,
            47.742,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3568",
        "polygon": [
            -95.895,
            47.742,
            4.14,
            -95.516,
            47.6,
            4.14,
            -95.516,
            47.6,
            6.988,
            -95.516,
            47.6,
            6.988,
            -95.895,
            47.742,
            6.988,
            -95.895,
            47.742,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3569",
        "polygon": [
            -97.205,
            48.235,
            4.202,
            -95.992,
            47.779,
            4.202,
            -95.992,
            47.779,
            7.408,
            -95.992,
            47.779,
            7.408,
            -97.205,
            48.235,
            7.408,
            -97.205,
            48.235,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3570",
        "polygon": [
            -98.098,
            48.57,
            4.202,
            -97.303,
            48.272,
            4.202,
            -97.303,
            48.272,
            7.408,
            -97.303,
            48.272,
            7.408,
            -98.098,
            48.57,
            7.408,
            -98.098,
            48.57,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3571",
        "polygon": [
            -98.516,
            48.728,
            6.988,
            -98.137,
            48.585,
            6.988,
            -98.137,
            48.585,
            7.408,
            -98.137,
            48.585,
            7.408,
            -98.516,
            48.728,
            7.408,
            -98.516,
            48.728,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3572",
        "polygon": [
            -98.516,
            48.728,
            4.14,
            -98.137,
            48.585,
            4.14,
            -98.137,
            48.585,
            6.988,
            -98.137,
            48.585,
            6.988,
            -98.516,
            48.728,
            6.988,
            -98.516,
            48.728,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3573",
        "polygon": [
            -99.826,
            49.22,
            4.202,
            -98.613,
            48.764,
            4.202,
            -98.613,
            48.764,
            7.408,
            -98.613,
            48.764,
            7.408,
            -99.826,
            49.22,
            7.408,
            -99.826,
            49.22,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3574",
        "polygon": [
            -100.718,
            49.556,
            4.202,
            -99.924,
            49.257,
            4.202,
            -99.924,
            49.257,
            7.408,
            -99.924,
            49.257,
            7.408,
            -100.718,
            49.556,
            7.408,
            -100.718,
            49.556,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3575",
        "polygon": [
            -101.137,
            49.713,
            6.988,
            -100.758,
            49.57,
            6.988,
            -100.758,
            49.57,
            7.408,
            -100.758,
            49.57,
            7.408,
            -101.137,
            49.713,
            7.408,
            -101.137,
            49.713,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3576",
        "polygon": [
            -101.137,
            49.713,
            4.14,
            -100.758,
            49.57,
            4.14,
            -100.758,
            49.57,
            6.988,
            -100.758,
            49.57,
            6.988,
            -101.137,
            49.713,
            6.988,
            -101.137,
            49.713,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3577",
        "polygon": [
            -102.447,
            50.206,
            4.202,
            -101.234,
            49.75,
            4.202,
            -101.234,
            49.75,
            7.408,
            -101.234,
            49.75,
            7.408,
            -102.447,
            50.206,
            7.408,
            -102.447,
            50.206,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3578",
        "polygon": [
            -103.339,
            50.541,
            4.202,
            -102.545,
            50.242,
            4.202,
            -102.545,
            50.242,
            7.408,
            -102.545,
            50.242,
            7.408,
            -103.339,
            50.541,
            7.408,
            -103.339,
            50.541,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3579",
        "polygon": [
            -103.758,
            50.698,
            6.988,
            -103.379,
            50.556,
            6.988,
            -103.379,
            50.556,
            7.408,
            -103.379,
            50.556,
            7.408,
            -103.758,
            50.698,
            7.408,
            -103.758,
            50.698,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3580",
        "polygon": [
            -103.758,
            50.698,
            4.14,
            -103.379,
            50.556,
            4.14,
            -103.379,
            50.556,
            6.988,
            -103.379,
            50.556,
            6.988,
            -103.758,
            50.698,
            6.988,
            -103.758,
            50.698,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3581",
        "polygon": [
            -105.068,
            51.191,
            4.202,
            -103.855,
            50.735,
            4.202,
            -103.855,
            50.735,
            7.408,
            -103.855,
            50.735,
            7.408,
            -105.068,
            51.191,
            7.408,
            -105.068,
            51.191,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3582",
        "polygon": [
            -105.96,
            51.526,
            4.202,
            -105.166,
            51.228,
            4.202,
            -105.166,
            51.228,
            7.408,
            -105.166,
            51.228,
            7.408,
            -105.96,
            51.526,
            7.408,
            -105.96,
            51.526,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3583",
        "polygon": [
            -106.379,
            51.684,
            6.988,
            -106.0,
            51.541,
            6.988,
            -106.0,
            51.541,
            7.408,
            -106.0,
            51.541,
            7.408,
            -106.379,
            51.684,
            7.408,
            -106.379,
            51.684,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3584",
        "polygon": [
            -106.379,
            51.684,
            4.14,
            -106.0,
            51.541,
            4.14,
            -106.0,
            51.541,
            6.988,
            -106.0,
            51.541,
            6.988,
            -106.379,
            51.684,
            6.988,
            -106.379,
            51.684,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3585",
        "polygon": [
            -107.455,
            52.088,
            4.202,
            -106.476,
            51.72,
            4.202,
            -106.476,
            51.72,
            7.408,
            -106.476,
            51.72,
            7.408,
            -107.455,
            52.088,
            7.408,
            -107.455,
            52.088,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3586",
        "polygon": [
            -89.343,
            45.279,
            11.622,
            -88.364,
            44.911,
            11.622,
            -88.364,
            44.911,
            14.828,
            -88.364,
            44.911,
            14.828,
            -89.343,
            45.279,
            14.828,
            -89.343,
            45.279,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3587",
        "polygon": [
            -90.235,
            45.614,
            11.622,
            -89.44,
            45.315,
            11.622,
            -89.44,
            45.315,
            14.828,
            -89.44,
            45.315,
            14.828,
            -90.235,
            45.614,
            14.828,
            -90.235,
            45.614,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3588",
        "polygon": [
            -90.653,
            45.772,
            14.408,
            -90.274,
            45.629,
            14.408,
            -90.274,
            45.629,
            14.828,
            -90.274,
            45.629,
            14.828,
            -90.653,
            45.772,
            14.828,
            -90.653,
            45.772,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3589",
        "polygon": [
            -90.653,
            45.772,
            11.56,
            -90.274,
            45.629,
            11.56,
            -90.274,
            45.629,
            14.408,
            -90.274,
            45.629,
            14.408,
            -90.653,
            45.772,
            14.408,
            -90.653,
            45.772,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3590",
        "polygon": [
            -91.964,
            46.264,
            11.622,
            -90.751,
            45.808,
            11.622,
            -90.751,
            45.808,
            14.828,
            -90.751,
            45.808,
            14.828,
            -91.964,
            46.264,
            14.828,
            -91.964,
            46.264,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3591",
        "polygon": [
            -92.856,
            46.6,
            11.622,
            -92.061,
            46.301,
            11.622,
            -92.061,
            46.301,
            14.828,
            -92.061,
            46.301,
            14.828,
            -92.856,
            46.6,
            14.828,
            -92.856,
            46.6,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3592",
        "polygon": [
            -93.274,
            46.757,
            14.408,
            -92.895,
            46.614,
            14.408,
            -92.895,
            46.614,
            14.828,
            -92.895,
            46.614,
            14.828,
            -93.274,
            46.757,
            14.828,
            -93.274,
            46.757,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3593",
        "polygon": [
            -93.274,
            46.757,
            11.56,
            -92.895,
            46.614,
            11.56,
            -92.895,
            46.614,
            14.408,
            -92.895,
            46.614,
            14.408,
            -93.274,
            46.757,
            14.408,
            -93.274,
            46.757,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3594",
        "polygon": [
            -94.585,
            47.25,
            11.622,
            -93.371,
            46.793,
            11.622,
            -93.371,
            46.793,
            14.828,
            -93.371,
            46.793,
            14.828,
            -94.585,
            47.25,
            14.828,
            -94.585,
            47.25,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3595",
        "polygon": [
            -95.477,
            47.585,
            11.622,
            -94.682,
            47.286,
            11.622,
            -94.682,
            47.286,
            14.828,
            -94.682,
            47.286,
            14.828,
            -95.477,
            47.585,
            14.828,
            -95.477,
            47.585,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3596",
        "polygon": [
            -95.895,
            47.742,
            14.408,
            -95.516,
            47.6,
            14.408,
            -95.516,
            47.6,
            14.828,
            -95.516,
            47.6,
            14.828,
            -95.895,
            47.742,
            14.828,
            -95.895,
            47.742,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3597",
        "polygon": [
            -95.895,
            47.742,
            11.56,
            -95.516,
            47.6,
            11.56,
            -95.516,
            47.6,
            14.408,
            -95.516,
            47.6,
            14.408,
            -95.895,
            47.742,
            14.408,
            -95.895,
            47.742,
            11.56
        ],
        "05-24 05:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3598",
        "polygon": [
            -97.205,
            48.235,
            11.622,
            -95.992,
            47.779,
            11.622,
            -95.992,
            47.779,
            14.828,
            -95.992,
            47.779,
            14.828,
            -97.205,
            48.235,
            14.828,
            -97.205,
            48.235,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3599",
        "polygon": [
            -98.098,
            48.57,
            11.622,
            -97.303,
            48.272,
            11.622,
            -97.303,
            48.272,
            14.828,
            -97.303,
            48.272,
            14.828,
            -98.098,
            48.57,
            14.828,
            -98.098,
            48.57,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3600",
        "polygon": [
            -98.516,
            48.728,
            14.408,
            -98.137,
            48.585,
            14.408,
            -98.137,
            48.585,
            14.828,
            -98.137,
            48.585,
            14.828,
            -98.516,
            48.728,
            14.828,
            -98.516,
            48.728,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3601",
        "polygon": [
            -98.516,
            48.728,
            11.56,
            -98.137,
            48.585,
            11.56,
            -98.137,
            48.585,
            14.408,
            -98.137,
            48.585,
            14.408,
            -98.516,
            48.728,
            14.408,
            -98.516,
            48.728,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3602",
        "polygon": [
            -99.826,
            49.22,
            11.622,
            -98.613,
            48.764,
            11.622,
            -98.613,
            48.764,
            14.828,
            -98.613,
            48.764,
            14.828,
            -99.826,
            49.22,
            14.828,
            -99.826,
            49.22,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3603",
        "polygon": [
            -100.718,
            49.556,
            11.622,
            -99.924,
            49.257,
            11.622,
            -99.924,
            49.257,
            14.828,
            -99.924,
            49.257,
            14.828,
            -100.718,
            49.556,
            14.828,
            -100.718,
            49.556,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3604",
        "polygon": [
            -101.137,
            49.713,
            14.408,
            -100.758,
            49.57,
            14.408,
            -100.758,
            49.57,
            14.828,
            -100.758,
            49.57,
            14.828,
            -101.137,
            49.713,
            14.828,
            -101.137,
            49.713,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3605",
        "polygon": [
            -101.137,
            49.713,
            11.56,
            -100.758,
            49.57,
            11.56,
            -100.758,
            49.57,
            14.408,
            -100.758,
            49.57,
            14.408,
            -101.137,
            49.713,
            14.408,
            -101.137,
            49.713,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3606",
        "polygon": [
            -102.447,
            50.206,
            11.622,
            -101.234,
            49.75,
            11.622,
            -101.234,
            49.75,
            14.828,
            -101.234,
            49.75,
            14.828,
            -102.447,
            50.206,
            14.828,
            -102.447,
            50.206,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3607",
        "polygon": [
            -103.339,
            50.541,
            11.622,
            -102.545,
            50.242,
            11.622,
            -102.545,
            50.242,
            14.828,
            -102.545,
            50.242,
            14.828,
            -103.339,
            50.541,
            14.828,
            -103.339,
            50.541,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3608",
        "polygon": [
            -103.758,
            50.698,
            14.408,
            -103.379,
            50.556,
            14.408,
            -103.379,
            50.556,
            14.828,
            -103.379,
            50.556,
            14.828,
            -103.758,
            50.698,
            14.828,
            -103.758,
            50.698,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3609",
        "polygon": [
            -103.758,
            50.698,
            11.56,
            -103.379,
            50.556,
            11.56,
            -103.379,
            50.556,
            14.408,
            -103.379,
            50.556,
            14.408,
            -103.758,
            50.698,
            14.408,
            -103.758,
            50.698,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3610",
        "polygon": [
            -105.068,
            51.191,
            11.622,
            -103.855,
            50.735,
            11.622,
            -103.855,
            50.735,
            14.828,
            -103.855,
            50.735,
            14.828,
            -105.068,
            51.191,
            14.828,
            -105.068,
            51.191,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3611",
        "polygon": [
            -105.96,
            51.526,
            11.622,
            -105.166,
            51.228,
            11.622,
            -105.166,
            51.228,
            14.828,
            -105.166,
            51.228,
            14.828,
            -105.96,
            51.526,
            14.828,
            -105.96,
            51.526,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3612",
        "polygon": [
            -106.379,
            51.684,
            14.408,
            -106.0,
            51.541,
            14.408,
            -106.0,
            51.541,
            14.828,
            -106.0,
            51.541,
            14.828,
            -106.379,
            51.684,
            14.828,
            -106.379,
            51.684,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3613",
        "polygon": [
            -106.379,
            51.684,
            11.56,
            -106.0,
            51.541,
            11.56,
            -106.0,
            51.541,
            14.408,
            -106.0,
            51.541,
            14.408,
            -106.379,
            51.684,
            14.408,
            -106.379,
            51.684,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3614",
        "polygon": [
            -107.455,
            52.088,
            11.622,
            -106.476,
            51.72,
            11.622,
            -106.476,
            51.72,
            14.828,
            -106.476,
            51.72,
            14.828,
            -107.455,
            52.088,
            14.828,
            -107.455,
            52.088,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3615",
        "polygon": [
            -89.343,
            45.279,
            7.912,
            -88.364,
            44.911,
            7.912,
            -88.364,
            44.911,
            11.118,
            -88.364,
            44.911,
            11.118,
            -89.343,
            45.279,
            11.118,
            -89.343,
            45.279,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3616",
        "polygon": [
            -91.964,
            46.264,
            7.912,
            -90.751,
            45.808,
            7.912,
            -90.751,
            45.808,
            11.118,
            -90.751,
            45.808,
            11.118,
            -91.964,
            46.264,
            11.118,
            -91.964,
            46.264,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3617",
        "polygon": [
            -94.585,
            47.25,
            7.912,
            -93.371,
            46.793,
            7.912,
            -93.371,
            46.793,
            11.118,
            -93.371,
            46.793,
            11.118,
            -94.585,
            47.25,
            11.118,
            -94.585,
            47.25,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3618",
        "polygon": [
            -97.205,
            48.235,
            7.912,
            -95.992,
            47.779,
            7.912,
            -95.992,
            47.779,
            11.118,
            -95.992,
            47.779,
            11.118,
            -97.205,
            48.235,
            11.118,
            -97.205,
            48.235,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3619",
        "polygon": [
            -99.826,
            49.22,
            7.912,
            -98.613,
            48.764,
            7.912,
            -98.613,
            48.764,
            11.118,
            -98.613,
            48.764,
            11.118,
            -99.826,
            49.22,
            11.118,
            -99.826,
            49.22,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3620",
        "polygon": [
            -102.447,
            50.206,
            7.912,
            -101.234,
            49.75,
            7.912,
            -101.234,
            49.75,
            11.118,
            -101.234,
            49.75,
            11.118,
            -102.447,
            50.206,
            11.118,
            -102.447,
            50.206,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3621",
        "polygon": [
            -105.068,
            51.191,
            7.912,
            -103.855,
            50.735,
            7.912,
            -103.855,
            50.735,
            11.118,
            -103.855,
            50.735,
            11.118,
            -105.068,
            51.191,
            11.118,
            -105.068,
            51.191,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3622",
        "polygon": [
            -106.379,
            51.684,
            7.912,
            -105.584,
            51.385,
            7.912,
            -105.584,
            51.385,
            11.118,
            -105.584,
            51.385,
            11.118,
            -106.379,
            51.684,
            11.118,
            -106.379,
            51.684,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3623",
        "polygon": [
            -105.545,
            51.37,
            10.698,
            -105.166,
            51.228,
            10.698,
            -105.166,
            51.228,
            11.118,
            -105.166,
            51.228,
            11.118,
            -105.545,
            51.37,
            11.118,
            -105.545,
            51.37,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3624",
        "polygon": [
            -105.545,
            51.37,
            7.85,
            -105.166,
            51.228,
            7.85,
            -105.166,
            51.228,
            10.698,
            -105.166,
            51.228,
            10.698,
            -105.545,
            51.37,
            10.698,
            -105.545,
            51.37,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3625",
        "polygon": [
            -107.455,
            52.088,
            7.912,
            -106.476,
            51.72,
            7.912,
            -106.476,
            51.72,
            11.118,
            -106.476,
            51.72,
            11.118,
            -107.455,
            52.088,
            11.118,
            -107.455,
            52.088,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3626",
        "polygon": [
            -103.758,
            50.698,
            7.912,
            -102.963,
            50.4,
            7.912,
            -102.963,
            50.4,
            11.118,
            -102.963,
            50.4,
            11.118,
            -103.758,
            50.698,
            11.118,
            -103.758,
            50.698,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3627",
        "polygon": [
            -102.924,
            50.385,
            10.698,
            -102.545,
            50.242,
            10.698,
            -102.545,
            50.242,
            11.118,
            -102.545,
            50.242,
            11.118,
            -102.924,
            50.385,
            11.118,
            -102.924,
            50.385,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3628",
        "polygon": [
            -102.924,
            50.385,
            7.85,
            -102.545,
            50.242,
            7.85,
            -102.545,
            50.242,
            10.698,
            -102.545,
            50.242,
            10.698,
            -102.924,
            50.385,
            10.698,
            -102.924,
            50.385,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3629",
        "polygon": [
            -101.137,
            49.713,
            7.912,
            -100.342,
            49.414,
            7.912,
            -100.342,
            49.414,
            11.118,
            -100.342,
            49.414,
            11.118,
            -101.137,
            49.713,
            11.118,
            -101.137,
            49.713,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3630",
        "polygon": [
            -100.303,
            49.399,
            10.698,
            -99.924,
            49.257,
            10.698,
            -99.924,
            49.257,
            11.118,
            -99.924,
            49.257,
            11.118,
            -100.303,
            49.399,
            11.118,
            -100.303,
            49.399,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3631",
        "polygon": [
            -100.303,
            49.399,
            7.85,
            -99.924,
            49.257,
            7.85,
            -99.924,
            49.257,
            10.698,
            -99.924,
            49.257,
            10.698,
            -100.303,
            49.399,
            10.698,
            -100.303,
            49.399,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3632",
        "polygon": [
            -98.516,
            48.728,
            7.912,
            -97.721,
            48.429,
            7.912,
            -97.721,
            48.429,
            11.118,
            -97.721,
            48.429,
            11.118,
            -98.516,
            48.728,
            11.118,
            -98.516,
            48.728,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3633",
        "polygon": [
            -97.682,
            48.414,
            10.698,
            -97.303,
            48.272,
            10.698,
            -97.303,
            48.272,
            11.118,
            -97.303,
            48.272,
            11.118,
            -97.682,
            48.414,
            11.118,
            -97.682,
            48.414,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3634",
        "polygon": [
            -97.682,
            48.414,
            7.85,
            -97.303,
            48.272,
            7.85,
            -97.303,
            48.272,
            10.698,
            -97.303,
            48.272,
            10.698,
            -97.682,
            48.414,
            10.698,
            -97.682,
            48.414,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3635",
        "polygon": [
            -95.895,
            47.742,
            7.912,
            -95.1,
            47.443,
            7.912,
            -95.1,
            47.443,
            11.118,
            -95.1,
            47.443,
            11.118,
            -95.895,
            47.742,
            11.118,
            -95.895,
            47.742,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3636",
        "polygon": [
            -95.061,
            47.429,
            10.698,
            -94.682,
            47.286,
            10.698,
            -94.682,
            47.286,
            11.118,
            -94.682,
            47.286,
            11.118,
            -95.061,
            47.429,
            11.118,
            -95.061,
            47.429,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3637",
        "polygon": [
            -95.061,
            47.429,
            7.85,
            -94.682,
            47.286,
            7.85,
            -94.682,
            47.286,
            10.698,
            -94.682,
            47.286,
            10.698,
            -95.061,
            47.429,
            10.698,
            -95.061,
            47.429,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3638",
        "polygon": [
            -93.274,
            46.757,
            7.912,
            -92.479,
            46.458,
            7.912,
            -92.479,
            46.458,
            11.118,
            -92.479,
            46.458,
            11.118,
            -93.274,
            46.757,
            11.118,
            -93.274,
            46.757,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3639",
        "polygon": [
            -92.44,
            46.443,
            10.698,
            -92.061,
            46.301,
            10.698,
            -92.061,
            46.301,
            11.118,
            -92.061,
            46.301,
            11.118,
            -92.44,
            46.443,
            11.118,
            -92.44,
            46.443,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3640",
        "polygon": [
            -92.44,
            46.443,
            7.85,
            -92.061,
            46.301,
            7.85,
            -92.061,
            46.301,
            10.698,
            -92.061,
            46.301,
            10.698,
            -92.44,
            46.443,
            10.698,
            -92.44,
            46.443,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3641",
        "polygon": [
            -90.653,
            45.772,
            7.912,
            -89.859,
            45.473,
            7.912,
            -89.859,
            45.473,
            11.118,
            -89.859,
            45.473,
            11.118,
            -90.653,
            45.772,
            11.118,
            -90.653,
            45.772,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3642",
        "polygon": [
            -89.819,
            45.458,
            10.698,
            -89.44,
            45.315,
            10.698,
            -89.44,
            45.315,
            11.118,
            -89.44,
            45.315,
            11.118,
            -89.819,
            45.458,
            11.118,
            -89.819,
            45.458,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3643",
        "polygon": [
            -89.819,
            45.458,
            7.85,
            -89.44,
            45.315,
            7.85,
            -89.44,
            45.315,
            10.698,
            -89.44,
            45.315,
            10.698,
            -89.819,
            45.458,
            10.698,
            -89.819,
            45.458,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3644",
        "polygon": [
            -103.151,
            64.196,
            0.142,
            -103.737,
            62.637,
            0.142,
            -103.737,
            62.637,
            3.698,
            -103.737,
            62.637,
            3.698,
            -103.151,
            64.196,
            3.698,
            -103.151,
            64.196,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3645",
        "polygon": [
            -103.774,
            62.539,
            0.142,
            -104.073,
            61.745,
            0.142,
            -104.073,
            61.745,
            3.698,
            -104.073,
            61.745,
            3.698,
            -103.774,
            62.539,
            3.698,
            -103.774,
            62.539,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3646",
        "polygon": [
            -104.087,
            61.705,
            0.142,
            -104.23,
            61.326,
            0.142,
            -104.23,
            61.326,
            3.698,
            -104.23,
            61.326,
            3.698,
            -104.087,
            61.705,
            3.698,
            -104.087,
            61.705,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3647",
        "polygon": [
            -104.266,
            61.229,
            0.142,
            -104.723,
            60.016,
            0.142,
            -104.723,
            60.016,
            3.698,
            -104.723,
            60.016,
            3.698,
            -104.266,
            61.229,
            3.698,
            -104.266,
            61.229,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3648",
        "polygon": [
            -104.759,
            59.918,
            0.142,
            -105.058,
            59.124,
            0.142,
            -105.058,
            59.124,
            3.698,
            -105.058,
            59.124,
            3.698,
            -104.759,
            59.918,
            3.698,
            -104.759,
            59.918,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3649",
        "polygon": [
            -105.073,
            59.084,
            0.142,
            -105.215,
            58.705,
            0.142,
            -105.215,
            58.705,
            3.698,
            -105.215,
            58.705,
            3.698,
            -105.073,
            59.084,
            3.698,
            -105.073,
            59.084,
            0.142
        ],
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3650",
        "polygon": [
            -105.252,
            58.608,
            0.142,
            -105.708,
            57.395,
            0.142,
            -105.708,
            57.395,
            3.698,
            -105.708,
            57.395,
            3.698,
            -105.252,
            58.608,
            3.698,
            -105.252,
            58.608,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3651",
        "polygon": [
            -105.744,
            57.297,
            0.142,
            -106.043,
            56.503,
            0.142,
            -106.043,
            56.503,
            3.698,
            -106.043,
            56.503,
            3.698,
            -105.744,
            57.297,
            3.698,
            -105.744,
            57.297,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3652",
        "polygon": [
            -106.058,
            56.463,
            0.142,
            -106.201,
            56.084,
            0.142,
            -106.201,
            56.084,
            3.698,
            -106.201,
            56.084,
            3.698,
            -106.058,
            56.463,
            3.698,
            -106.058,
            56.463,
            0.142
        ],
        "05-24 09:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3653",
        "polygon": [
            -106.237,
            55.987,
            0.142,
            -106.693,
            54.774,
            0.142,
            -106.693,
            54.774,
            3.698,
            -106.693,
            54.774,
            3.698,
            -106.237,
            55.987,
            3.698,
            -106.237,
            55.987,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3657",
        "polygon": [
            -83.111,
            58.734,
            0.05,
            -83.519,
            57.649,
            0.05,
            -83.519,
            57.649,
            3.0,
            -83.519,
            57.649,
            3.0,
            -83.111,
            58.734,
            3.0,
            -83.111,
            58.734,
            0.05
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3658",
        "polygon": [
            -82.977,
            59.091,
            0.05,
            -83.112,
            58.731,
            0.05,
            -83.112,
            58.731,
            3.0,
            -83.112,
            58.731,
            3.0,
            -82.977,
            59.091,
            3.0,
            -82.977,
            59.091,
            0.05
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3659",
        "polygon": [
            -83.542,
            57.589,
            0.1,
            -83.672,
            57.242,
            0.1,
            -83.672,
            57.242,
            3.0,
            -83.672,
            57.242,
            3.0,
            -83.542,
            57.589,
            3.0,
            -83.542,
            57.589,
            0.1
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3660",
        "polygon": [
            -87.345,
            47.444,
            0.142,
            -85.418,
            52.569,
            0.142,
            -85.418,
            52.569,
            3.698,
            -85.418,
            52.569,
            3.698,
            -87.345,
            47.444,
            3.698,
            -87.345,
            47.444,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3661",
        "polygon": [
            -38.782,
            28.027,
            16.21,
            -38.321,
            29.253,
            16.21,
            -38.321,
            29.253,
            16.54,
            -38.321,
            29.253,
            16.54,
            -38.782,
            28.027,
            16.54,
            -38.782,
            28.027,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3662",
        "polygon": [
            -38.782,
            28.027,
            16.54,
            -38.321,
            29.253,
            16.54,
            -38.321,
            29.253,
            16.87,
            -38.321,
            29.253,
            16.87,
            -38.782,
            28.027,
            16.87,
            -38.782,
            28.027,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3663",
        "polygon": [
            -38.782,
            28.027,
            15.88,
            -38.321,
            29.253,
            15.88,
            -38.321,
            29.253,
            16.21,
            -38.321,
            29.253,
            16.21,
            -38.782,
            28.027,
            16.21,
            -38.782,
            28.027,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3664",
        "polygon": [
            -39.275,
            26.714,
            16.21,
            -38.814,
            27.941,
            16.21,
            -38.814,
            27.941,
            16.54,
            -38.814,
            27.941,
            16.54,
            -39.275,
            26.714,
            16.54,
            -39.275,
            26.714,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3665",
        "polygon": [
            -39.275,
            26.714,
            16.54,
            -38.814,
            27.941,
            16.54,
            -38.814,
            27.941,
            16.87,
            -38.814,
            27.941,
            16.87,
            -39.275,
            26.714,
            16.87,
            -39.275,
            26.714,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3666",
        "polygon": [
            -39.275,
            26.714,
            15.88,
            -38.814,
            27.941,
            15.88,
            -38.814,
            27.941,
            16.21,
            -38.814,
            27.941,
            16.21,
            -39.275,
            26.714,
            16.21,
            -39.275,
            26.714,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3667",
        "polygon": [
            -38.3,
            29.307,
            16.21,
            -37.839,
            30.533,
            16.21,
            -37.839,
            30.533,
            16.54,
            -37.839,
            30.533,
            16.54,
            -38.3,
            29.307,
            16.54,
            -38.3,
            29.307,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3668",
        "polygon": [
            -38.3,
            29.307,
            16.54,
            -37.839,
            30.533,
            16.54,
            -37.839,
            30.533,
            16.87,
            -37.839,
            30.533,
            16.87,
            -38.3,
            29.307,
            16.87,
            -38.3,
            29.307,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3669",
        "polygon": [
            -38.3,
            29.307,
            15.88,
            -37.839,
            30.533,
            15.88,
            -37.839,
            30.533,
            16.21,
            -37.839,
            30.533,
            16.21,
            -38.3,
            29.307,
            16.21,
            -38.3,
            29.307,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3670",
        "polygon": [
            -37.795,
            30.65,
            16.21,
            -37.334,
            31.877,
            16.21,
            -37.334,
            31.877,
            16.54,
            -37.334,
            31.877,
            16.54,
            -37.795,
            30.65,
            16.54,
            -37.795,
            30.65,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3671",
        "polygon": [
            -37.795,
            30.65,
            16.54,
            -37.334,
            31.877,
            16.54,
            -37.334,
            31.877,
            16.87,
            -37.334,
            31.877,
            16.87,
            -37.795,
            30.65,
            16.87,
            -37.795,
            30.65,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3672",
        "polygon": [
            -37.795,
            30.65,
            15.88,
            -37.334,
            31.877,
            15.88,
            -37.334,
            31.877,
            16.21,
            -37.334,
            31.877,
            16.21,
            -37.795,
            30.65,
            16.21,
            -37.795,
            30.65,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3673",
        "polygon": [
            -37.315,
            31.928,
            16.21,
            -36.854,
            33.154,
            16.21,
            -36.854,
            33.154,
            16.54,
            -36.854,
            33.154,
            16.54,
            -37.315,
            31.928,
            16.54,
            -37.315,
            31.928,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3674",
        "polygon": [
            -37.315,
            31.928,
            16.54,
            -36.854,
            33.154,
            16.54,
            -36.854,
            33.154,
            16.87,
            -36.854,
            33.154,
            16.87,
            -37.315,
            31.928,
            16.87,
            -37.315,
            31.928,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3675",
        "polygon": [
            -37.315,
            31.928,
            15.88,
            -36.854,
            33.154,
            15.88,
            -36.854,
            33.154,
            16.21,
            -36.854,
            33.154,
            16.21,
            -37.315,
            31.928,
            16.21,
            -37.315,
            31.928,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3676",
        "polygon": [
            -36.81,
            33.271,
            16.21,
            -36.349,
            34.497,
            16.21,
            -36.349,
            34.497,
            16.54,
            -36.349,
            34.497,
            16.54,
            -36.81,
            33.271,
            16.54,
            -36.81,
            33.271,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3677",
        "polygon": [
            -36.81,
            33.271,
            16.54,
            -36.349,
            34.497,
            16.54,
            -36.349,
            34.497,
            16.87,
            -36.349,
            34.497,
            16.87,
            -36.81,
            33.271,
            16.87,
            -36.81,
            33.271,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3678",
        "polygon": [
            -36.81,
            33.271,
            15.88,
            -36.349,
            34.497,
            15.88,
            -36.349,
            34.497,
            16.21,
            -36.349,
            34.497,
            16.21,
            -36.81,
            33.271,
            16.21,
            -36.81,
            33.271,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3679",
        "polygon": [
            -36.33,
            34.549,
            16.21,
            -35.869,
            35.775,
            16.21,
            -35.869,
            35.775,
            16.54,
            -35.869,
            35.775,
            16.54,
            -36.33,
            34.549,
            16.54,
            -36.33,
            34.549,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3680",
        "polygon": [
            -36.33,
            34.549,
            16.54,
            -35.869,
            35.775,
            16.54,
            -35.869,
            35.775,
            16.87,
            -35.869,
            35.775,
            16.87,
            -36.33,
            34.549,
            16.87,
            -36.33,
            34.549,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3681",
        "polygon": [
            -36.33,
            34.549,
            15.88,
            -35.869,
            35.775,
            15.88,
            -35.869,
            35.775,
            16.21,
            -35.869,
            35.775,
            16.21,
            -36.33,
            34.549,
            16.21,
            -36.33,
            34.549,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3682",
        "polygon": [
            -35.825,
            35.892,
            16.21,
            -35.364,
            37.118,
            16.21,
            -35.364,
            37.118,
            16.54,
            -35.364,
            37.118,
            16.54,
            -35.825,
            35.892,
            16.54,
            -35.825,
            35.892,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3683",
        "polygon": [
            -35.825,
            35.892,
            16.54,
            -35.364,
            37.118,
            16.54,
            -35.364,
            37.118,
            16.87,
            -35.364,
            37.118,
            16.87,
            -35.825,
            35.892,
            16.87,
            -35.825,
            35.892,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3684",
        "polygon": [
            -35.825,
            35.892,
            15.88,
            -35.364,
            37.118,
            15.88,
            -35.364,
            37.118,
            16.21,
            -35.364,
            37.118,
            16.21,
            -35.825,
            35.892,
            16.21,
            -35.825,
            35.892,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3685",
        "polygon": [
            -35.344,
            37.17,
            16.21,
            -34.883,
            38.396,
            16.21,
            -34.883,
            38.396,
            16.54,
            -34.883,
            38.396,
            16.54,
            -35.344,
            37.17,
            16.54,
            -35.344,
            37.17,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3686",
        "polygon": [
            -35.344,
            37.17,
            16.54,
            -34.883,
            38.396,
            16.54,
            -34.883,
            38.396,
            16.87,
            -34.883,
            38.396,
            16.87,
            -35.344,
            37.17,
            16.87,
            -35.344,
            37.17,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3687",
        "polygon": [
            -35.344,
            37.17,
            15.88,
            -34.883,
            38.396,
            15.88,
            -34.883,
            38.396,
            16.21,
            -34.883,
            38.396,
            16.21,
            -35.344,
            37.17,
            16.21,
            -35.344,
            37.17,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3688",
        "polygon": [
            -34.839,
            38.513,
            16.21,
            -34.378,
            39.739,
            16.21,
            -34.378,
            39.739,
            16.54,
            -34.378,
            39.739,
            16.54,
            -34.839,
            38.513,
            16.54,
            -34.839,
            38.513,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3689",
        "polygon": [
            -34.839,
            38.513,
            16.54,
            -34.378,
            39.739,
            16.54,
            -34.378,
            39.739,
            16.87,
            -34.378,
            39.739,
            16.87,
            -34.839,
            38.513,
            16.87,
            -34.839,
            38.513,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3690",
        "polygon": [
            -34.839,
            38.513,
            15.88,
            -34.378,
            39.739,
            15.88,
            -34.378,
            39.739,
            16.21,
            -34.378,
            39.739,
            16.21,
            -34.839,
            38.513,
            16.21,
            -34.839,
            38.513,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3691",
        "polygon": [
            -34.342,
            39.835,
            16.21,
            -33.881,
            41.061,
            16.21,
            -33.881,
            41.061,
            16.54,
            -33.881,
            41.061,
            16.54,
            -34.342,
            39.835,
            16.54,
            -34.342,
            39.835,
            16.21
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3692",
        "polygon": [
            -34.342,
            39.835,
            16.54,
            -33.881,
            41.061,
            16.54,
            -33.881,
            41.061,
            16.87,
            -33.881,
            41.061,
            16.87,
            -34.342,
            39.835,
            16.87,
            -34.342,
            39.835,
            16.54
        ],
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3693",
        "polygon": [
            -34.342,
            39.835,
            15.88,
            -33.881,
            41.061,
            15.88,
            -33.881,
            41.061,
            16.21,
            -33.881,
            41.061,
            16.21,
            -34.342,
            39.835,
            16.21,
            -34.342,
            39.835,
            15.88
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3694",
        "polygon": [
            -19.626,
            20.726,
            16.54,
            -20.087,
            19.5,
            16.54,
            -20.087,
            19.5,
            16.87,
            -20.087,
            19.5,
            16.87,
            -19.626,
            20.726,
            16.87,
            -19.626,
            20.726,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3695",
        "polygon": [
            -19.132,
            22.039,
            16.21,
            -19.593,
            20.813,
            16.21,
            -19.593,
            20.813,
            16.54,
            -19.593,
            20.813,
            16.54,
            -19.132,
            22.039,
            16.54,
            -19.132,
            22.039,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3696",
        "polygon": [
            -19.132,
            22.039,
            16.54,
            -19.593,
            20.813,
            16.54,
            -19.593,
            20.813,
            16.87,
            -19.593,
            20.813,
            16.87,
            -19.132,
            22.039,
            16.87,
            -19.132,
            22.039,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3697",
        "polygon": [
            -19.132,
            22.039,
            15.88,
            -19.593,
            20.813,
            15.88,
            -19.593,
            20.813,
            16.21,
            -19.593,
            20.813,
            16.21,
            -19.132,
            22.039,
            16.21,
            -19.132,
            22.039,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3698",
        "polygon": [
            -19.626,
            20.726,
            16.21,
            -20.087,
            19.5,
            16.21,
            -20.087,
            19.5,
            16.54,
            -20.087,
            19.5,
            16.54,
            -19.626,
            20.726,
            16.54,
            -19.626,
            20.726,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3699",
        "polygon": [
            -19.626,
            20.726,
            15.88,
            -20.087,
            19.5,
            15.88,
            -20.087,
            19.5,
            16.21,
            -20.087,
            19.5,
            16.21,
            -19.626,
            20.726,
            16.21,
            -19.626,
            20.726,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3700",
        "polygon": [
            -18.651,
            23.319,
            16.21,
            -19.112,
            22.093,
            16.21,
            -19.112,
            22.093,
            16.54,
            -19.112,
            22.093,
            16.54,
            -18.651,
            23.319,
            16.54,
            -18.651,
            23.319,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3701",
        "polygon": [
            -18.651,
            23.319,
            16.54,
            -19.112,
            22.093,
            16.54,
            -19.112,
            22.093,
            16.87,
            -19.112,
            22.093,
            16.87,
            -18.651,
            23.319,
            16.87,
            -18.651,
            23.319,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3702",
        "polygon": [
            -18.651,
            23.319,
            15.88,
            -19.112,
            22.093,
            15.88,
            -19.112,
            22.093,
            16.21,
            -19.112,
            22.093,
            16.21,
            -18.651,
            23.319,
            16.21,
            -18.651,
            23.319,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3703",
        "polygon": [
            -18.146,
            24.662,
            16.21,
            -18.607,
            23.436,
            16.21,
            -18.607,
            23.436,
            16.54,
            -18.607,
            23.436,
            16.54,
            -18.146,
            24.662,
            16.54,
            -18.146,
            24.662,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3704",
        "polygon": [
            -18.146,
            24.662,
            16.54,
            -18.607,
            23.436,
            16.54,
            -18.607,
            23.436,
            16.87,
            -18.607,
            23.436,
            16.87,
            -18.146,
            24.662,
            16.87,
            -18.146,
            24.662,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3705",
        "polygon": [
            -18.146,
            24.662,
            15.88,
            -18.607,
            23.436,
            15.88,
            -18.607,
            23.436,
            16.21,
            -18.607,
            23.436,
            16.21,
            -18.146,
            24.662,
            16.21,
            -18.146,
            24.662,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3706",
        "polygon": [
            -17.665,
            25.94,
            16.21,
            -18.126,
            24.714,
            16.21,
            -18.126,
            24.714,
            16.54,
            -18.126,
            24.714,
            16.54,
            -17.665,
            25.94,
            16.54,
            -17.665,
            25.94,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3707",
        "polygon": [
            -17.665,
            25.94,
            16.54,
            -18.126,
            24.714,
            16.54,
            -18.126,
            24.714,
            16.87,
            -18.126,
            24.714,
            16.87,
            -17.665,
            25.94,
            16.87,
            -17.665,
            25.94,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3708",
        "polygon": [
            -17.665,
            25.94,
            15.88,
            -18.126,
            24.714,
            15.88,
            -18.126,
            24.714,
            16.21,
            -18.126,
            24.714,
            16.21,
            -17.665,
            25.94,
            16.21,
            -17.665,
            25.94,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3709",
        "polygon": [
            -17.16,
            27.283,
            16.21,
            -17.621,
            26.057,
            16.21,
            -17.621,
            26.057,
            16.54,
            -17.621,
            26.057,
            16.54,
            -17.16,
            27.283,
            16.54,
            -17.16,
            27.283,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3710",
        "polygon": [
            -17.16,
            27.283,
            16.54,
            -17.621,
            26.057,
            16.54,
            -17.621,
            26.057,
            16.87,
            -17.621,
            26.057,
            16.87,
            -17.16,
            27.283,
            16.87,
            -17.16,
            27.283,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3711",
        "polygon": [
            -17.16,
            27.283,
            15.88,
            -17.621,
            26.057,
            15.88,
            -17.621,
            26.057,
            16.21,
            -17.621,
            26.057,
            16.21,
            -17.16,
            27.283,
            16.21,
            -17.16,
            27.283,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3712",
        "polygon": [
            -16.68,
            28.561,
            16.21,
            -17.141,
            27.335,
            16.21,
            -17.141,
            27.335,
            16.54,
            -17.141,
            27.335,
            16.54,
            -16.68,
            28.561,
            16.54,
            -16.68,
            28.561,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3713",
        "polygon": [
            -16.68,
            28.561,
            16.54,
            -17.141,
            27.335,
            16.54,
            -17.141,
            27.335,
            16.87,
            -17.141,
            27.335,
            16.87,
            -16.68,
            28.561,
            16.87,
            -16.68,
            28.561,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3714",
        "polygon": [
            -16.68,
            28.561,
            15.88,
            -17.141,
            27.335,
            15.88,
            -17.141,
            27.335,
            16.21,
            -17.141,
            27.335,
            16.21,
            -16.68,
            28.561,
            16.21,
            -16.68,
            28.561,
            15.88
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3715",
        "polygon": [
            -16.175,
            29.904,
            16.21,
            -16.636,
            28.678,
            16.21,
            -16.636,
            28.678,
            16.54,
            -16.636,
            28.678,
            16.54,
            -16.175,
            29.904,
            16.54,
            -16.175,
            29.904,
            16.21
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3716",
        "polygon": [
            -16.175,
            29.904,
            16.54,
            -16.636,
            28.678,
            16.54,
            -16.636,
            28.678,
            16.87,
            -16.636,
            28.678,
            16.87,
            -16.175,
            29.904,
            16.87,
            -16.175,
            29.904,
            16.54
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3717",
        "polygon": [
            -16.175,
            29.904,
            15.88,
            -16.636,
            28.678,
            15.88,
            -16.636,
            28.678,
            16.21,
            -16.636,
            28.678,
            16.21,
            -16.175,
            29.904,
            16.21,
            -16.175,
            29.904,
            15.88
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3718",
        "polygon": [
            -15.695,
            31.182,
            16.21,
            -16.156,
            29.955,
            16.21,
            -16.156,
            29.955,
            16.54,
            -16.156,
            29.955,
            16.54,
            -15.695,
            31.182,
            16.54,
            -15.695,
            31.182,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3719",
        "polygon": [
            -15.695,
            31.182,
            16.54,
            -16.156,
            29.955,
            16.54,
            -16.156,
            29.955,
            16.87,
            -16.156,
            29.955,
            16.87,
            -15.695,
            31.182,
            16.87,
            -15.695,
            31.182,
            16.54
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3720",
        "polygon": [
            -15.695,
            31.182,
            15.88,
            -16.156,
            29.955,
            15.88,
            -16.156,
            29.955,
            16.21,
            -16.156,
            29.955,
            16.21,
            -15.695,
            31.182,
            16.21,
            -15.695,
            31.182,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3721",
        "polygon": [
            -15.19,
            32.525,
            16.21,
            -15.651,
            31.299,
            16.21,
            -15.651,
            31.299,
            16.54,
            -15.651,
            31.299,
            16.54,
            -15.19,
            32.525,
            16.54,
            -15.19,
            32.525,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3722",
        "polygon": [
            -15.19,
            32.525,
            16.54,
            -15.651,
            31.299,
            16.54,
            -15.651,
            31.299,
            16.87,
            -15.651,
            31.299,
            16.87,
            -15.19,
            32.525,
            16.87,
            -15.19,
            32.525,
            16.54
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3723",
        "polygon": [
            -15.19,
            32.525,
            15.88,
            -15.651,
            31.299,
            15.88,
            -15.651,
            31.299,
            16.21,
            -15.651,
            31.299,
            16.21,
            -15.19,
            32.525,
            16.21,
            -15.19,
            32.525,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3724",
        "polygon": [
            -14.693,
            33.847,
            16.21,
            -15.154,
            32.621,
            16.21,
            -15.154,
            32.621,
            16.54,
            -15.154,
            32.621,
            16.54,
            -14.693,
            33.847,
            16.54,
            -14.693,
            33.847,
            16.21
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3725",
        "polygon": [
            -14.693,
            33.847,
            16.54,
            -15.154,
            32.621,
            16.54,
            -15.154,
            32.621,
            16.87,
            -15.154,
            32.621,
            16.87,
            -14.693,
            33.847,
            16.87,
            -14.693,
            33.847,
            16.54
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3726",
        "polygon": [
            -14.693,
            33.847,
            15.88,
            -15.154,
            32.621,
            15.88,
            -15.154,
            32.621,
            16.21,
            -15.154,
            32.621,
            16.21,
            -14.693,
            33.847,
            16.21,
            -14.693,
            33.847,
            15.88
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3727",
        "polygon": [
            -20.137,
            19.452,
            16.555,
            -23.778,
            20.821,
            16.555,
            -23.778,
            20.821,
            16.91,
            -23.778,
            20.821,
            16.91,
            -20.137,
            19.452,
            16.91,
            -20.137,
            19.452,
            16.555
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3728",
        "polygon": [
            -20.137,
            19.452,
            16.2,
            -23.778,
            20.821,
            16.2,
            -23.778,
            20.821,
            16.555,
            -23.778,
            20.821,
            16.555,
            -20.137,
            19.452,
            16.555,
            -20.137,
            19.452,
            16.2
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3729",
        "polygon": [
            -20.137,
            19.452,
            15.845,
            -23.778,
            20.821,
            15.845,
            -23.778,
            20.821,
            16.2,
            -23.778,
            20.821,
            16.2,
            -20.137,
            19.452,
            16.2,
            -20.137,
            19.452,
            15.845
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3730",
        "polygon": [
            -23.834,
            20.842,
            16.555,
            -27.709,
            22.299,
            16.555,
            -27.709,
            22.299,
            16.91,
            -27.709,
            22.299,
            16.91,
            -23.834,
            20.842,
            16.91,
            -23.834,
            20.842,
            16.555
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3731",
        "polygon": [
            -23.834,
            20.842,
            16.2,
            -27.709,
            22.299,
            16.2,
            -27.709,
            22.299,
            16.555,
            -27.709,
            22.299,
            16.555,
            -23.834,
            20.842,
            16.555,
            -23.834,
            20.842,
            16.2
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3732",
        "polygon": [
            -23.834,
            20.842,
            15.845,
            -27.709,
            22.299,
            15.845,
            -27.709,
            22.299,
            16.2,
            -27.709,
            22.299,
            16.2,
            -23.834,
            20.842,
            16.2,
            -23.834,
            20.842,
            15.845
        ],
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3733",
        "polygon": [
            -27.765,
            22.32,
            16.555,
            -31.64,
            23.777,
            16.555,
            -31.64,
            23.777,
            16.91,
            -31.64,
            23.777,
            16.91,
            -27.765,
            22.32,
            16.91,
            -27.765,
            22.32,
            16.555
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3734",
        "polygon": [
            -27.765,
            22.32,
            16.2,
            -31.64,
            23.777,
            16.2,
            -31.64,
            23.777,
            16.555,
            -31.64,
            23.777,
            16.555,
            -27.765,
            22.32,
            16.555,
            -27.765,
            22.32,
            16.2
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3735",
        "polygon": [
            -27.765,
            22.32,
            15.845,
            -31.64,
            23.777,
            15.845,
            -31.64,
            23.777,
            16.2,
            -31.64,
            23.777,
            16.2,
            -27.765,
            22.32,
            16.2,
            -27.765,
            22.32,
            15.845
        ],
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3736",
        "polygon": [
            -31.697,
            23.799,
            16.555,
            -35.572,
            25.255,
            16.555,
            -35.572,
            25.255,
            16.91,
            -35.572,
            25.255,
            16.91,
            -31.697,
            23.799,
            16.91,
            -31.697,
            23.799,
            16.555
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3737",
        "polygon": [
            -31.697,
            23.799,
            16.2,
            -35.572,
            25.255,
            16.2,
            -35.572,
            25.255,
            16.555,
            -35.572,
            25.255,
            16.555,
            -31.697,
            23.799,
            16.555,
            -31.697,
            23.799,
            16.2
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3738",
        "polygon": [
            -31.697,
            23.799,
            15.845,
            -35.572,
            25.255,
            15.845,
            -35.572,
            25.255,
            16.2,
            -35.572,
            25.255,
            16.2,
            -31.697,
            23.799,
            16.2,
            -31.697,
            23.799,
            15.845
        ],
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3739",
        "polygon": [
            -35.628,
            25.277,
            16.555,
            -39.269,
            26.645,
            16.555,
            -39.269,
            26.645,
            16.91,
            -39.269,
            26.645,
            16.91,
            -35.628,
            25.277,
            16.91,
            -35.628,
            25.277,
            16.555
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3740",
        "polygon": [
            -35.628,
            25.277,
            16.2,
            -39.269,
            26.645,
            16.2,
            -39.269,
            26.645,
            16.555,
            -39.269,
            26.645,
            16.555,
            -35.628,
            25.277,
            16.555,
            -35.628,
            25.277,
            16.2
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3741",
        "polygon": [
            -35.628,
            25.277,
            15.845,
            -39.269,
            26.645,
            15.845,
            -39.269,
            26.645,
            16.2,
            -39.269,
            26.645,
            16.2,
            -35.628,
            25.277,
            16.2,
            -35.628,
            25.277,
            15.845
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3742",
        "polygon": [
            -23.834,
            20.842,
            16.91,
            -27.709,
            22.299,
            16.91,
            -27.709,
            22.299,
            17.176,
            -27.709,
            22.299,
            17.176,
            -23.834,
            20.842,
            17.176,
            -23.834,
            20.842,
            16.91
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3743",
        "polygon": [
            -27.765,
            22.32,
            16.91,
            -31.64,
            23.777,
            16.91,
            -31.64,
            23.777,
            17.344,
            -31.64,
            23.777,
            17.344,
            -27.765,
            22.32,
            17.344,
            -27.765,
            22.32,
            16.91
        ],
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3744",
        "polygon": [
            -31.697,
            23.799,
            16.91,
            -35.572,
            25.255,
            16.91,
            -35.572,
            25.255,
            17.176,
            -35.572,
            25.255,
            17.176,
            -31.697,
            23.799,
            17.176,
            -31.697,
            23.799,
            16.91
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_3",
        "polygon": [
            -9.663,
            46.712,
            0.142,
            -9.207,
            47.926,
            0.142,
            -9.207,
            47.926,
            3.698,
            -9.207,
            47.926,
            3.698,
            -9.663,
            46.712,
            3.698,
            -9.663,
            46.712,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_1",
        "polygon": [
            -8.678,
            49.333,
            0.142,
            -8.158,
            50.717,
            0.142,
            -8.158,
            50.717,
            3.698,
            -8.158,
            50.717,
            3.698,
            -8.678,
            49.333,
            3.698,
            -8.678,
            49.333,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_5",
        "polygon": [
            -10.649,
            44.092,
            0.142,
            -10.193,
            45.305,
            0.142,
            -10.193,
            45.305,
            3.698,
            -10.193,
            45.305,
            3.698,
            -10.649,
            44.092,
            3.698,
            -10.649,
            44.092,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_7",
        "polygon": [
            -11.634,
            41.471,
            0.142,
            -11.178,
            42.684,
            0.142,
            -11.178,
            42.684,
            3.698,
            -11.178,
            42.684,
            3.698,
            -11.634,
            41.471,
            3.698,
            -11.634,
            41.471,
            0.142
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_9",
        "polygon": [
            -12.62,
            38.85,
            0.142,
            -12.163,
            40.063,
            0.142,
            -12.163,
            40.063,
            3.698,
            -12.163,
            40.063,
            3.698,
            -12.62,
            38.85,
            3.698,
            -12.62,
            38.85,
            0.142
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_11",
        "polygon": [
            -13.605,
            36.229,
            0.142,
            -13.149,
            37.442,
            0.142,
            -13.149,
            37.442,
            3.698,
            -13.149,
            37.442,
            3.698,
            -13.605,
            36.229,
            3.698,
            -13.605,
            36.229,
            0.142
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_13",
        "polygon": [
            -14.525,
            33.78,
            0.142,
            -14.131,
            34.83,
            0.142,
            -14.131,
            34.83,
            3.698,
            -14.131,
            34.83,
            3.698,
            -14.525,
            33.78,
            3.698,
            -14.525,
            33.78,
            0.142
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_3",
        "polygon": [
            -9.663,
            46.714,
            4.202,
            -9.207,
            47.927,
            4.202,
            -9.207,
            47.927,
            7.408,
            -9.207,
            47.927,
            7.408,
            -9.663,
            46.714,
            7.408,
            -9.663,
            46.714,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_1",
        "polygon": [
            -8.678,
            49.334,
            4.202,
            -8.158,
            50.718,
            4.202,
            -8.158,
            50.718,
            7.408,
            -8.158,
            50.718,
            7.408,
            -8.678,
            49.334,
            7.408,
            -8.678,
            49.334,
            4.202
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_5",
        "polygon": [
            -10.649,
            44.092,
            4.202,
            -10.193,
            45.305,
            4.202,
            -10.193,
            45.305,
            7.408,
            -10.193,
            45.305,
            7.408,
            -10.649,
            44.092,
            7.408,
            -10.649,
            44.092,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_7",
        "polygon": [
            -11.634,
            41.471,
            4.202,
            -11.178,
            42.684,
            4.202,
            -11.178,
            42.684,
            7.408,
            -11.178,
            42.684,
            7.408,
            -11.634,
            41.471,
            7.408,
            -11.634,
            41.471,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_9",
        "polygon": [
            -12.62,
            38.85,
            4.202,
            -12.163,
            40.063,
            4.202,
            -12.163,
            40.063,
            7.408,
            -12.163,
            40.063,
            7.408,
            -12.62,
            38.85,
            7.408,
            -12.62,
            38.85,
            4.202
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_11",
        "polygon": [
            -13.605,
            36.229,
            4.202,
            -13.149,
            37.442,
            4.202,
            -13.149,
            37.442,
            7.408,
            -13.149,
            37.442,
            7.408,
            -13.605,
            36.229,
            7.408,
            -13.605,
            36.229,
            4.202
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_13",
        "polygon": [
            -14.525,
            33.78,
            4.202,
            -14.131,
            34.83,
            4.202,
            -14.131,
            34.83,
            7.408,
            -14.131,
            34.83,
            7.408,
            -14.525,
            33.78,
            7.408,
            -14.525,
            33.78,
            4.202
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_3",
        "polygon": [
            -9.663,
            46.714,
            7.912,
            -9.207,
            47.927,
            7.912,
            -9.207,
            47.927,
            11.118,
            -9.207,
            47.927,
            11.118,
            -9.663,
            46.714,
            11.118,
            -9.663,
            46.714,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_1",
        "polygon": [
            -8.678,
            49.334,
            7.912,
            -8.158,
            50.718,
            7.912,
            -8.158,
            50.718,
            11.118,
            -8.158,
            50.718,
            11.118,
            -8.678,
            49.334,
            11.118,
            -8.678,
            49.334,
            7.912
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_5",
        "polygon": [
            -10.649,
            44.092,
            7.912,
            -10.193,
            45.305,
            7.912,
            -10.193,
            45.305,
            11.118,
            -10.193,
            45.305,
            11.118,
            -10.649,
            44.092,
            11.118,
            -10.649,
            44.092,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_7",
        "polygon": [
            -11.634,
            41.471,
            7.912,
            -11.178,
            42.684,
            7.912,
            -11.178,
            42.684,
            11.118,
            -11.178,
            42.684,
            11.118,
            -11.634,
            41.471,
            11.118,
            -11.634,
            41.471,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_9",
        "polygon": [
            -12.62,
            38.85,
            7.912,
            -12.163,
            40.063,
            7.912,
            -12.163,
            40.063,
            11.118,
            -12.163,
            40.063,
            11.118,
            -12.62,
            38.85,
            11.118,
            -12.62,
            38.85,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_11",
        "polygon": [
            -13.605,
            36.229,
            7.912,
            -13.149,
            37.442,
            7.912,
            -13.149,
            37.442,
            11.118,
            -13.149,
            37.442,
            11.118,
            -13.605,
            36.229,
            11.118,
            -13.605,
            36.229,
            7.912
        ],
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_13",
        "polygon": [
            -14.525,
            33.78,
            7.912,
            -14.131,
            34.83,
            7.912,
            -14.131,
            34.83,
            11.118,
            -14.131,
            34.83,
            11.118,
            -14.525,
            33.78,
            11.118,
            -14.525,
            33.78,
            7.912
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_5",
        "polygon": [
            -10.649,
            44.092,
            11.622,
            -10.129,
            45.475,
            11.622,
            -10.129,
            45.475,
            14.828,
            -10.129,
            45.475,
            14.828,
            -10.649,
            44.092,
            14.828,
            -10.649,
            44.092,
            11.622
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_7",
        "polygon": [
            -11.634,
            41.471,
            11.622,
            -11.178,
            42.684,
            11.622,
            -11.178,
            42.684,
            14.828,
            -11.178,
            42.684,
            14.828,
            -11.634,
            41.471,
            14.828,
            -11.634,
            41.471,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_9",
        "polygon": [
            -12.62,
            38.85,
            11.622,
            -12.163,
            40.063,
            11.622,
            -12.163,
            40.063,
            14.828,
            -12.163,
            40.063,
            14.828,
            -12.62,
            38.85,
            14.828,
            -12.62,
            38.85,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_11",
        "polygon": [
            -13.605,
            36.229,
            11.622,
            -13.149,
            37.442,
            11.622,
            -13.149,
            37.442,
            14.828,
            -13.149,
            37.442,
            14.828,
            -13.605,
            36.229,
            14.828,
            -13.605,
            36.229,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_13",
        "polygon": [
            -14.525,
            33.78,
            11.622,
            -14.131,
            34.83,
            11.622,
            -14.131,
            34.83,
            14.828,
            -14.131,
            34.83,
            14.828,
            -14.525,
            33.78,
            14.828,
            -14.525,
            33.78,
            11.622
        ],
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3836",
        "polygon": [
            -16.404,
            28.785,
            4.202,
            -16.105,
            29.579,
            4.202,
            -16.105,
            29.579,
            7.408,
            -16.105,
            29.579,
            7.408,
            -16.404,
            28.785,
            7.408,
            -16.404,
            28.785,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3837",
        "polygon": [
            -16.561,
            28.366,
            6.988,
            -16.418,
            28.745,
            6.988,
            -16.418,
            28.745,
            7.408,
            -16.418,
            28.745,
            7.408,
            -16.561,
            28.366,
            7.408,
            -16.561,
            28.366,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3838",
        "polygon": [
            -16.561,
            28.366,
            4.14,
            -16.418,
            28.745,
            4.14,
            -16.418,
            28.745,
            6.988,
            -16.418,
            28.745,
            6.988,
            -16.561,
            28.366,
            6.988,
            -16.561,
            28.366,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3839",
        "polygon": [
            -17.054,
            27.056,
            4.202,
            -16.598,
            28.269,
            4.202,
            -16.598,
            28.269,
            7.408,
            -16.598,
            28.269,
            7.408,
            -17.054,
            27.056,
            7.408,
            -17.054,
            27.056,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3840",
        "polygon": [
            -17.389,
            26.164,
            4.202,
            -17.09,
            26.958,
            4.202,
            -17.09,
            26.958,
            7.408,
            -17.09,
            26.958,
            7.408,
            -17.389,
            26.164,
            7.408,
            -17.389,
            26.164,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3841",
        "polygon": [
            -17.546,
            25.745,
            6.988,
            -17.404,
            26.124,
            6.988,
            -17.404,
            26.124,
            7.408,
            -17.404,
            26.124,
            7.408,
            -17.546,
            25.745,
            7.408,
            -17.546,
            25.745,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3842",
        "polygon": [
            -17.546,
            25.745,
            4.14,
            -17.404,
            26.124,
            4.14,
            -17.404,
            26.124,
            6.988,
            -17.404,
            26.124,
            6.988,
            -17.546,
            25.745,
            6.988,
            -17.546,
            25.745,
            4.14
        ],
        "05-24 15:15": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3843",
        "polygon": [
            -18.039,
            24.435,
            4.202,
            -17.583,
            25.648,
            4.202,
            -17.583,
            25.648,
            7.408,
            -17.583,
            25.648,
            7.408,
            -18.039,
            24.435,
            7.408,
            -18.039,
            24.435,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3844",
        "polygon": [
            -18.374,
            23.543,
            4.202,
            -18.076,
            24.337,
            4.202,
            -18.076,
            24.337,
            7.408,
            -18.076,
            24.337,
            7.408,
            -18.374,
            23.543,
            7.408,
            -18.374,
            23.543,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3845",
        "polygon": [
            -18.532,
            23.124,
            6.988,
            -18.389,
            23.503,
            6.988,
            -18.389,
            23.503,
            7.408,
            -18.389,
            23.503,
            7.408,
            -18.532,
            23.124,
            7.408,
            -18.532,
            23.124,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3846",
        "polygon": [
            -18.532,
            23.124,
            4.14,
            -18.389,
            23.503,
            4.14,
            -18.389,
            23.503,
            6.988,
            -18.389,
            23.503,
            6.988,
            -18.532,
            23.124,
            6.988,
            -18.532,
            23.124,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3847",
        "polygon": [
            -19.024,
            21.814,
            4.202,
            -18.568,
            23.027,
            4.202,
            -18.568,
            23.027,
            7.408,
            -18.568,
            23.027,
            7.408,
            -19.024,
            21.814,
            7.408,
            -19.024,
            21.814,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3848",
        "polygon": [
            -19.36,
            20.922,
            4.202,
            -19.061,
            21.717,
            4.202,
            -19.061,
            21.717,
            7.408,
            -19.061,
            21.717,
            7.408,
            -19.36,
            20.922,
            7.408,
            -19.36,
            20.922,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3849",
        "polygon": [
            -19.517,
            20.503,
            6.988,
            -19.374,
            20.883,
            6.988,
            -19.374,
            20.883,
            7.408,
            -19.374,
            20.883,
            7.408,
            -19.517,
            20.503,
            7.408,
            -19.517,
            20.503,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3850",
        "polygon": [
            -19.517,
            20.503,
            4.14,
            -19.374,
            20.883,
            4.14,
            -19.374,
            20.883,
            6.988,
            -19.374,
            20.883,
            6.988,
            -19.517,
            20.503,
            6.988,
            -19.517,
            20.503,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3851",
        "polygon": [
            -19.922,
            19.427,
            4.202,
            -19.554,
            20.406,
            4.202,
            -19.554,
            20.406,
            7.408,
            -19.554,
            20.406,
            7.408,
            -19.922,
            19.427,
            7.408,
            -19.922,
            19.427,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3852",
        "polygon": [
            -16.068,
            29.677,
            4.202,
            -15.487,
            31.222,
            4.202,
            -15.487,
            31.222,
            7.408,
            -15.487,
            31.222,
            7.408,
            -16.068,
            29.677,
            7.408,
            -16.068,
            29.677,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3853",
        "polygon": [
            -15.24,
            31.88,
            4.11,
            -14.832,
            32.966,
            4.11,
            -14.832,
            32.966,
            7.06,
            -14.832,
            32.966,
            7.06,
            -15.24,
            31.88,
            7.06,
            -15.24,
            31.88,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3854",
        "polygon": [
            -15.374,
            31.523,
            4.11,
            -15.239,
            31.883,
            4.11,
            -15.239,
            31.883,
            7.06,
            -15.239,
            31.883,
            7.06,
            -15.374,
            31.523,
            7.06,
            -15.374,
            31.523,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3855",
        "polygon": [
            -14.809,
            33.026,
            4.16,
            -14.679,
            33.372,
            4.16,
            -14.679,
            33.372,
            7.06,
            -14.679,
            33.372,
            7.06,
            -14.809,
            33.026,
            7.06,
            -14.809,
            33.026,
            4.16
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3856",
        "polygon": [
            -16.404,
            28.785,
            11.622,
            -16.105,
            29.579,
            11.622,
            -16.105,
            29.579,
            14.828,
            -16.105,
            29.579,
            14.828,
            -16.404,
            28.785,
            14.828,
            -16.404,
            28.785,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3857",
        "polygon": [
            -16.561,
            28.366,
            14.408,
            -16.418,
            28.745,
            14.408,
            -16.418,
            28.745,
            14.828,
            -16.418,
            28.745,
            14.828,
            -16.561,
            28.366,
            14.828,
            -16.561,
            28.366,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3858",
        "polygon": [
            -16.561,
            28.366,
            11.56,
            -16.418,
            28.745,
            11.56,
            -16.418,
            28.745,
            14.408,
            -16.418,
            28.745,
            14.408,
            -16.561,
            28.366,
            14.408,
            -16.561,
            28.366,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3859",
        "polygon": [
            -17.054,
            27.056,
            11.622,
            -16.598,
            28.269,
            11.622,
            -16.598,
            28.269,
            14.828,
            -16.598,
            28.269,
            14.828,
            -17.054,
            27.056,
            14.828,
            -17.054,
            27.056,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3860",
        "polygon": [
            -17.389,
            26.164,
            11.622,
            -17.09,
            26.958,
            11.622,
            -17.09,
            26.958,
            14.828,
            -17.09,
            26.958,
            14.828,
            -17.389,
            26.164,
            14.828,
            -17.389,
            26.164,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3861",
        "polygon": [
            -17.546,
            25.745,
            14.408,
            -17.404,
            26.124,
            14.408,
            -17.404,
            26.124,
            14.828,
            -17.404,
            26.124,
            14.828,
            -17.546,
            25.745,
            14.828,
            -17.546,
            25.745,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3862",
        "polygon": [
            -17.546,
            25.745,
            11.56,
            -17.404,
            26.124,
            11.56,
            -17.404,
            26.124,
            14.408,
            -17.404,
            26.124,
            14.408,
            -17.546,
            25.745,
            14.408,
            -17.546,
            25.745,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3863",
        "polygon": [
            -18.039,
            24.435,
            11.622,
            -17.583,
            25.648,
            11.622,
            -17.583,
            25.648,
            14.828,
            -17.583,
            25.648,
            14.828,
            -18.039,
            24.435,
            14.828,
            -18.039,
            24.435,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3864",
        "polygon": [
            -18.374,
            23.543,
            11.622,
            -18.076,
            24.337,
            11.622,
            -18.076,
            24.337,
            14.828,
            -18.076,
            24.337,
            14.828,
            -18.374,
            23.543,
            14.828,
            -18.374,
            23.543,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3865",
        "polygon": [
            -18.532,
            23.124,
            14.408,
            -18.389,
            23.503,
            14.408,
            -18.389,
            23.503,
            14.828,
            -18.389,
            23.503,
            14.828,
            -18.532,
            23.124,
            14.828,
            -18.532,
            23.124,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3866",
        "polygon": [
            -18.532,
            23.124,
            11.56,
            -18.389,
            23.503,
            11.56,
            -18.389,
            23.503,
            14.408,
            -18.389,
            23.503,
            14.408,
            -18.532,
            23.124,
            14.408,
            -18.532,
            23.124,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3867",
        "polygon": [
            -19.024,
            21.814,
            11.622,
            -18.568,
            23.027,
            11.622,
            -18.568,
            23.027,
            14.828,
            -18.568,
            23.027,
            14.828,
            -19.024,
            21.814,
            14.828,
            -19.024,
            21.814,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3868",
        "polygon": [
            -19.36,
            20.922,
            11.622,
            -19.061,
            21.717,
            11.622,
            -19.061,
            21.717,
            14.828,
            -19.061,
            21.717,
            14.828,
            -19.36,
            20.922,
            14.828,
            -19.36,
            20.922,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3869",
        "polygon": [
            -19.517,
            20.503,
            14.408,
            -19.374,
            20.883,
            14.408,
            -19.374,
            20.883,
            14.828,
            -19.374,
            20.883,
            14.828,
            -19.517,
            20.503,
            14.828,
            -19.517,
            20.503,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3870",
        "polygon": [
            -19.517,
            20.503,
            11.56,
            -19.374,
            20.883,
            11.56,
            -19.374,
            20.883,
            14.408,
            -19.374,
            20.883,
            14.408,
            -19.517,
            20.503,
            14.408,
            -19.517,
            20.503,
            11.56
        ],
        "05-24 17:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3871",
        "polygon": [
            -19.922,
            19.427,
            11.622,
            -19.554,
            20.406,
            11.622,
            -19.554,
            20.406,
            14.828,
            -19.554,
            20.406,
            14.828,
            -19.922,
            19.427,
            14.828,
            -19.922,
            19.427,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3872",
        "polygon": [
            -16.068,
            29.677,
            11.622,
            -15.487,
            31.222,
            11.622,
            -15.487,
            31.222,
            14.828,
            -15.487,
            31.222,
            14.828,
            -16.068,
            29.677,
            14.828,
            -16.068,
            29.677,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3873",
        "polygon": [
            -15.24,
            31.88,
            11.53,
            -14.832,
            32.966,
            11.53,
            -14.832,
            32.966,
            14.48,
            -14.832,
            32.966,
            14.48,
            -15.24,
            31.88,
            14.48,
            -15.24,
            31.88,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3874",
        "polygon": [
            -15.374,
            31.523,
            11.53,
            -15.239,
            31.883,
            11.53,
            -15.239,
            31.883,
            14.48,
            -15.239,
            31.883,
            14.48,
            -15.374,
            31.523,
            14.48,
            -15.374,
            31.523,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3875",
        "polygon": [
            -14.809,
            33.026,
            11.58,
            -14.679,
            33.372,
            11.58,
            -14.679,
            33.372,
            14.48,
            -14.679,
            33.372,
            14.48,
            -14.809,
            33.026,
            14.48,
            -14.809,
            33.026,
            11.58
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3876",
        "polygon": [
            -17.054,
            27.056,
            7.912,
            -16.598,
            28.269,
            7.912,
            -16.598,
            28.269,
            11.118,
            -16.598,
            28.269,
            11.118,
            -17.054,
            27.056,
            11.118,
            -17.054,
            27.056,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3877",
        "polygon": [
            -18.039,
            24.435,
            7.912,
            -17.583,
            25.648,
            7.912,
            -17.583,
            25.648,
            11.118,
            -17.583,
            25.648,
            11.118,
            -18.039,
            24.435,
            11.118,
            -18.039,
            24.435,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3878",
        "polygon": [
            -19.024,
            21.814,
            7.912,
            -18.568,
            23.027,
            7.912,
            -18.568,
            23.027,
            11.118,
            -18.568,
            23.027,
            11.118,
            -19.024,
            21.814,
            11.118,
            -19.024,
            21.814,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3879",
        "polygon": [
            -19.517,
            20.504,
            7.912,
            -19.218,
            21.298,
            7.912,
            -19.218,
            21.298,
            11.118,
            -19.218,
            21.298,
            11.118,
            -19.517,
            20.504,
            11.118,
            -19.517,
            20.504,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3880",
        "polygon": [
            -19.203,
            21.338,
            10.698,
            -19.061,
            21.717,
            10.698,
            -19.061,
            21.717,
            11.118,
            -19.061,
            21.717,
            11.118,
            -19.203,
            21.338,
            11.118,
            -19.203,
            21.338,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3881",
        "polygon": [
            -19.203,
            21.338,
            7.85,
            -19.061,
            21.717,
            7.85,
            -19.061,
            21.717,
            10.698,
            -19.061,
            21.717,
            10.698,
            -19.203,
            21.338,
            10.698,
            -19.203,
            21.338,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3882",
        "polygon": [
            -19.922,
            19.427,
            7.912,
            -19.554,
            20.406,
            7.912,
            -19.554,
            20.406,
            11.118,
            -19.554,
            20.406,
            11.118,
            -19.922,
            19.427,
            11.118,
            -19.922,
            19.427,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3883",
        "polygon": [
            -16.068,
            29.677,
            7.912,
            -15.487,
            31.222,
            7.912,
            -15.487,
            31.222,
            11.118,
            -15.487,
            31.222,
            11.118,
            -16.068,
            29.677,
            11.118,
            -16.068,
            29.677,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3884",
        "polygon": [
            -15.24,
            31.88,
            7.82,
            -14.832,
            32.966,
            7.82,
            -14.832,
            32.966,
            10.77,
            -14.832,
            32.966,
            10.77,
            -15.24,
            31.88,
            10.77,
            -15.24,
            31.88,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3885",
        "polygon": [
            -15.374,
            31.523,
            7.82,
            -15.239,
            31.883,
            7.82,
            -15.239,
            31.883,
            10.77,
            -15.239,
            31.883,
            10.77,
            -15.374,
            31.523,
            10.77,
            -15.374,
            31.523,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3886",
        "polygon": [
            -14.809,
            33.026,
            7.87,
            -14.679,
            33.372,
            7.87,
            -14.679,
            33.372,
            10.77,
            -14.679,
            33.372,
            10.77,
            -14.809,
            33.026,
            10.77,
            -14.809,
            33.026,
            7.87
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3887",
        "polygon": [
            -18.532,
            23.124,
            7.912,
            -18.233,
            23.919,
            7.912,
            -18.233,
            23.919,
            11.118,
            -18.233,
            23.919,
            11.118,
            -18.532,
            23.124,
            11.118,
            -18.532,
            23.124,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3888",
        "polygon": [
            -18.218,
            23.958,
            10.698,
            -18.076,
            24.338,
            10.698,
            -18.076,
            24.338,
            11.118,
            -18.076,
            24.338,
            11.118,
            -18.218,
            23.958,
            11.118,
            -18.218,
            23.958,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3889",
        "polygon": [
            -18.218,
            23.958,
            7.85,
            -18.076,
            24.338,
            7.85,
            -18.076,
            24.338,
            10.698,
            -18.076,
            24.338,
            10.698,
            -18.218,
            23.958,
            10.698,
            -18.218,
            23.958,
            7.85
        ],
        "05-24 14:45": 1,
        "05-24 15:15": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3890",
        "polygon": [
            -17.546,
            25.745,
            7.912,
            -17.248,
            26.54,
            7.912,
            -17.248,
            26.54,
            11.118,
            -17.248,
            26.54,
            11.118,
            -17.546,
            25.745,
            11.118,
            -17.546,
            25.745,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3891",
        "polygon": [
            -17.233,
            26.579,
            10.698,
            -17.09,
            26.958,
            10.698,
            -17.09,
            26.958,
            11.118,
            -17.09,
            26.958,
            11.118,
            -17.233,
            26.579,
            11.118,
            -17.233,
            26.579,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3892",
        "polygon": [
            -17.233,
            26.579,
            7.85,
            -17.09,
            26.958,
            7.85,
            -17.09,
            26.958,
            10.698,
            -17.09,
            26.958,
            10.698,
            -17.233,
            26.579,
            10.698,
            -17.233,
            26.579,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3893",
        "polygon": [
            -16.561,
            28.366,
            7.912,
            -16.262,
            29.161,
            7.912,
            -16.262,
            29.161,
            11.118,
            -16.262,
            29.161,
            11.118,
            -16.561,
            28.366,
            11.118,
            -16.561,
            28.366,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3894",
        "polygon": [
            -16.247,
            29.2,
            10.698,
            -16.105,
            29.579,
            10.698,
            -16.105,
            29.579,
            11.118,
            -16.105,
            29.579,
            11.118,
            -16.247,
            29.2,
            11.118,
            -16.247,
            29.2,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_3895",
        "polygon": [
            -16.247,
            29.2,
            7.85,
            -16.105,
            29.579,
            7.85,
            -16.105,
            29.579,
            10.698,
            -16.105,
            29.579,
            10.698,
            -16.247,
            29.2,
            10.698,
            -16.247,
            29.2,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3896",
        "polygon": [
            -18.532,
            23.124,
            0.142,
            -18.233,
            23.919,
            0.142,
            -18.233,
            23.919,
            3.698,
            -18.233,
            23.919,
            3.698,
            -18.532,
            23.124,
            3.698,
            -18.532,
            23.124,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3897",
        "polygon": [
            -18.218,
            23.958,
            0.142,
            -18.076,
            24.338,
            0.142,
            -18.076,
            24.338,
            3.698,
            -18.076,
            24.338,
            3.698,
            -18.218,
            23.958,
            3.698,
            -18.218,
            23.958,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3898",
        "polygon": [
            -18.039,
            24.435,
            0.142,
            -17.583,
            25.648,
            0.142,
            -17.583,
            25.648,
            3.698,
            -17.583,
            25.648,
            3.698,
            -18.039,
            24.435,
            3.698,
            -18.039,
            24.435,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3899",
        "polygon": [
            -17.546,
            25.746,
            0.142,
            -17.247,
            26.541,
            0.142,
            -17.247,
            26.541,
            3.698,
            -17.247,
            26.541,
            3.698,
            -17.546,
            25.746,
            3.698,
            -17.546,
            25.746,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3900",
        "polygon": [
            -17.232,
            26.58,
            0.142,
            -17.09,
            26.959,
            0.142,
            -17.09,
            26.959,
            3.698,
            -17.09,
            26.959,
            3.698,
            -17.232,
            26.58,
            3.698,
            -17.232,
            26.58,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3901",
        "polygon": [
            -19.024,
            21.814,
            0.142,
            -18.568,
            23.027,
            0.142,
            -18.568,
            23.027,
            3.698,
            -18.568,
            23.027,
            3.698,
            -19.024,
            21.814,
            3.698,
            -19.024,
            21.814,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3902",
        "polygon": [
            -15.24,
            31.88,
            0.05,
            -14.832,
            32.966,
            0.05,
            -14.832,
            32.966,
            3.0,
            -14.832,
            32.966,
            3.0,
            -15.24,
            31.88,
            3.0,
            -15.24,
            31.88,
            0.05
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3903",
        "polygon": [
            -15.374,
            31.523,
            0.05,
            -15.239,
            31.883,
            0.05,
            -15.239,
            31.883,
            3.0,
            -15.239,
            31.883,
            3.0,
            -15.374,
            31.523,
            3.0,
            -15.374,
            31.523,
            0.05
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3904",
        "polygon": [
            -14.809,
            33.026,
            0.1,
            -14.679,
            33.372,
            0.1,
            -14.679,
            33.372,
            3.0,
            -14.679,
            33.372,
            3.0,
            -14.809,
            33.026,
            3.0,
            -14.809,
            33.026,
            0.1
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3905",
        "polygon": [
            -19.517,
            20.504,
            0.06,
            -19.061,
            21.717,
            0.06,
            -19.061,
            21.717,
            2.908,
            -19.061,
            21.717,
            2.908,
            -19.517,
            20.504,
            2.908,
            -19.517,
            20.504,
            0.06
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3906",
        "polygon": [
            -19.517,
            20.504,
            2.928,
            -19.061,
            21.717,
            2.928,
            -19.061,
            21.717,
            3.698,
            -19.061,
            21.717,
            3.698,
            -19.517,
            20.504,
            3.698,
            -19.517,
            20.504,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_3907",
        "polygon": [
            -19.913,
            19.451,
            0.142,
            -19.544,
            20.431,
            0.142,
            -19.544,
            20.431,
            3.698,
            -19.544,
            20.431,
            3.698,
            -19.913,
            19.451,
            3.698,
            -19.913,
            19.451,
            0.142
        ]
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_17",
        "polygon": [
            -25.713,
            63.298,
            4.202,
            -26.233,
            61.915,
            4.202,
            -26.233,
            61.915,
            7.408,
            -26.233,
            61.915,
            7.408,
            -25.713,
            63.298,
            7.408,
            -25.713,
            63.298,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_15",
        "polygon": [
            -26.763,
            60.507,
            4.202,
            -27.219,
            59.294,
            4.202,
            -27.219,
            59.294,
            7.408,
            -27.219,
            59.294,
            7.408,
            -26.763,
            60.507,
            7.408,
            -26.763,
            60.507,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_13",
        "polygon": [
            -27.748,
            57.887,
            4.202,
            -28.204,
            56.674,
            4.202,
            -28.204,
            56.674,
            7.408,
            -28.204,
            56.674,
            7.408,
            -27.748,
            57.887,
            7.408,
            -27.748,
            57.887,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_11",
        "polygon": [
            -28.733,
            55.266,
            4.202,
            -29.189,
            54.053,
            4.202,
            -29.189,
            54.053,
            7.408,
            -29.189,
            54.053,
            7.408,
            -28.733,
            55.266,
            7.408,
            -28.733,
            55.266,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_9",
        "polygon": [
            -29.718,
            52.646,
            4.202,
            -30.175,
            51.432,
            4.202,
            -30.175,
            51.432,
            7.408,
            -30.175,
            51.432,
            7.408,
            -29.718,
            52.646,
            7.408,
            -29.718,
            52.646,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_7",
        "polygon": [
            -30.704,
            50.025,
            4.202,
            -31.16,
            48.812,
            4.202,
            -31.16,
            48.812,
            7.408,
            -31.16,
            48.812,
            7.408,
            -30.704,
            50.025,
            7.408,
            -30.704,
            50.025,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_1",
        "polygon": [
            -33.66,
            42.162,
            4.202,
            -34.055,
            41.112,
            4.202,
            -34.055,
            41.112,
            7.408,
            -34.055,
            41.112,
            7.408,
            -33.66,
            42.162,
            7.408,
            -33.66,
            42.162,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_5",
        "polygon": [
            -31.689,
            47.404,
            4.202,
            -32.145,
            46.191,
            4.202,
            -32.145,
            46.191,
            7.408,
            -32.145,
            46.191,
            7.408,
            -31.689,
            47.404,
            7.408,
            -31.689,
            47.404,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_3",
        "polygon": [
            -32.674,
            44.783,
            4.202,
            -33.131,
            43.57,
            4.202,
            -33.131,
            43.57,
            7.408,
            -33.131,
            43.57,
            7.408,
            -32.674,
            44.783,
            7.408,
            -32.674,
            44.783,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3941",
        "polygon": [
            -35.788,
            36.502,
            4.202,
            -36.087,
            35.707,
            4.202,
            -36.087,
            35.707,
            7.408,
            -36.087,
            35.707,
            7.408,
            -35.788,
            36.502,
            7.408,
            -35.788,
            36.502,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3942",
        "polygon": [
            -35.631,
            36.92,
            6.988,
            -35.773,
            36.541,
            6.988,
            -35.773,
            36.541,
            7.408,
            -35.773,
            36.541,
            7.408,
            -35.631,
            36.92,
            7.408,
            -35.631,
            36.92,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3943",
        "polygon": [
            -35.631,
            36.92,
            4.14,
            -35.773,
            36.541,
            4.14,
            -35.773,
            36.541,
            6.988,
            -35.773,
            36.541,
            6.988,
            -35.631,
            36.92,
            6.988,
            -35.631,
            36.92,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3944",
        "polygon": [
            -36.123,
            35.61,
            4.202,
            -36.579,
            34.397,
            4.202,
            -36.579,
            34.397,
            7.408,
            -36.579,
            34.397,
            7.408,
            -36.123,
            35.61,
            7.408,
            -36.123,
            35.61,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3945",
        "polygon": [
            -35.013,
            38.563,
            4.202,
            -35.594,
            37.018,
            4.202,
            -35.594,
            37.018,
            7.408,
            -35.594,
            37.018,
            7.408,
            -35.013,
            38.563,
            7.408,
            -35.013,
            38.563,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3946",
        "polygon": [
            -36.773,
            33.881,
            4.202,
            -37.072,
            33.086,
            4.202,
            -37.072,
            33.086,
            7.408,
            -37.072,
            33.086,
            7.408,
            -36.773,
            33.881,
            7.408,
            -36.773,
            33.881,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3947",
        "polygon": [
            -36.616,
            34.299,
            6.988,
            -36.758,
            33.92,
            6.988,
            -36.758,
            33.92,
            7.408,
            -36.758,
            33.92,
            7.408,
            -36.616,
            34.299,
            7.408,
            -36.616,
            34.299,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3948",
        "polygon": [
            -36.616,
            34.299,
            4.14,
            -36.758,
            33.92,
            4.14,
            -36.758,
            33.92,
            6.988,
            -36.758,
            33.92,
            6.988,
            -36.616,
            34.299,
            6.988,
            -36.616,
            34.299,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3949",
        "polygon": [
            -37.109,
            32.989,
            4.202,
            -37.565,
            31.776,
            4.202,
            -37.565,
            31.776,
            7.408,
            -37.565,
            31.776,
            7.408,
            -37.109,
            32.989,
            7.408,
            -37.109,
            32.989,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3950",
        "polygon": [
            -37.759,
            31.26,
            4.202,
            -38.057,
            30.465,
            4.202,
            -38.057,
            30.465,
            7.408,
            -38.057,
            30.465,
            7.408,
            -37.759,
            31.26,
            7.408,
            -37.759,
            31.26,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3951",
        "polygon": [
            -37.601,
            31.678,
            6.988,
            -37.744,
            31.299,
            6.988,
            -37.744,
            31.299,
            7.408,
            -37.744,
            31.299,
            7.408,
            -37.601,
            31.678,
            7.408,
            -37.601,
            31.678,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3952",
        "polygon": [
            -37.601,
            31.678,
            4.14,
            -37.744,
            31.299,
            4.14,
            -37.744,
            31.299,
            6.988,
            -37.744,
            31.299,
            6.988,
            -37.601,
            31.678,
            6.988,
            -37.601,
            31.678,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3953",
        "polygon": [
            -38.094,
            30.368,
            4.202,
            -38.55,
            29.155,
            4.202,
            -38.55,
            29.155,
            7.408,
            -38.55,
            29.155,
            7.408,
            -38.094,
            30.368,
            7.408,
            -38.094,
            30.368,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3954",
        "polygon": [
            -38.744,
            28.639,
            4.202,
            -39.043,
            27.844,
            4.202,
            -39.043,
            27.844,
            7.408,
            -39.043,
            27.844,
            7.408,
            -38.744,
            28.639,
            7.408,
            -38.744,
            28.639,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3955",
        "polygon": [
            -38.587,
            29.057,
            6.988,
            -38.729,
            28.678,
            6.988,
            -38.729,
            28.678,
            7.408,
            -38.729,
            28.678,
            7.408,
            -38.587,
            29.057,
            7.408,
            -38.587,
            29.057,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3956",
        "polygon": [
            -38.587,
            29.057,
            4.14,
            -38.729,
            28.678,
            4.14,
            -38.729,
            28.678,
            6.988,
            -38.729,
            28.678,
            6.988,
            -38.587,
            29.057,
            6.988,
            -38.587,
            29.057,
            4.14
        ],
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3957",
        "polygon": [
            -39.079,
            27.747,
            4.202,
            -39.447,
            26.768,
            4.202,
            -39.447,
            26.768,
            7.408,
            -39.447,
            26.768,
            7.408,
            -39.079,
            27.747,
            7.408,
            -39.079,
            27.747,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3958",
        "polygon": [
            -34.337,
            40.36,
            4.11,
            -34.746,
            39.274,
            4.11,
            -34.746,
            39.274,
            7.06,
            -34.746,
            39.274,
            7.06,
            -34.337,
            40.36,
            7.06,
            -34.337,
            40.36,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3959",
        "polygon": [
            -34.203,
            40.716,
            4.11,
            -34.339,
            40.356,
            4.11,
            -34.339,
            40.356,
            7.06,
            -34.339,
            40.356,
            7.06,
            -34.203,
            40.716,
            7.06,
            -34.203,
            40.716,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_3960",
        "polygon": [
            -34.768,
            39.214,
            4.16,
            -34.898,
            38.868,
            4.16,
            -34.898,
            38.868,
            7.06,
            -34.898,
            38.868,
            7.06,
            -34.768,
            39.214,
            7.06,
            -34.768,
            39.214,
            4.16
        ]
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_17",
        "polygon": [
            -25.713,
            63.298,
            11.622,
            -26.233,
            61.915,
            11.622,
            -26.233,
            61.915,
            14.828,
            -26.233,
            61.915,
            14.828,
            -25.713,
            63.298,
            14.828,
            -25.713,
            63.298,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_15",
        "polygon": [
            -26.763,
            60.507,
            11.622,
            -27.219,
            59.294,
            11.622,
            -27.219,
            59.294,
            14.828,
            -27.219,
            59.294,
            14.828,
            -26.763,
            60.507,
            14.828,
            -26.763,
            60.507,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_13",
        "polygon": [
            -27.748,
            57.887,
            11.622,
            -28.204,
            56.674,
            11.622,
            -28.204,
            56.674,
            14.828,
            -28.204,
            56.674,
            14.828,
            -27.748,
            57.887,
            14.828,
            -27.748,
            57.887,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_11",
        "polygon": [
            -28.733,
            55.266,
            11.622,
            -29.189,
            54.053,
            11.622,
            -29.189,
            54.053,
            14.828,
            -29.189,
            54.053,
            14.828,
            -28.733,
            55.266,
            14.828,
            -28.733,
            55.266,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_9",
        "polygon": [
            -29.718,
            52.646,
            11.622,
            -30.175,
            51.432,
            11.622,
            -30.175,
            51.432,
            14.828,
            -30.175,
            51.432,
            14.828,
            -29.718,
            52.646,
            14.828,
            -29.718,
            52.646,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_7",
        "polygon": [
            -30.704,
            50.025,
            11.622,
            -31.16,
            48.812,
            11.622,
            -31.16,
            48.812,
            14.828,
            -31.16,
            48.812,
            14.828,
            -30.704,
            50.025,
            14.828,
            -30.704,
            50.025,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_1",
        "polygon": [
            -33.66,
            42.162,
            11.622,
            -34.055,
            41.112,
            11.622,
            -34.055,
            41.112,
            14.828,
            -34.055,
            41.112,
            14.828,
            -33.66,
            42.162,
            14.828,
            -33.66,
            42.162,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_5",
        "polygon": [
            -31.689,
            47.404,
            11.622,
            -32.145,
            46.191,
            11.622,
            -32.145,
            46.191,
            14.828,
            -32.145,
            46.191,
            14.828,
            -31.689,
            47.404,
            14.828,
            -31.689,
            47.404,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_3",
        "polygon": [
            -32.674,
            44.783,
            11.622,
            -33.131,
            43.57,
            11.622,
            -33.131,
            43.57,
            14.828,
            -33.131,
            43.57,
            14.828,
            -32.674,
            44.783,
            14.828,
            -32.674,
            44.783,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3994",
        "polygon": [
            -35.788,
            36.502,
            11.622,
            -36.087,
            35.707,
            11.622,
            -36.087,
            35.707,
            14.828,
            -36.087,
            35.707,
            14.828,
            -35.788,
            36.502,
            14.828,
            -35.788,
            36.502,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3995",
        "polygon": [
            -35.631,
            36.92,
            14.408,
            -35.773,
            36.541,
            14.408,
            -35.773,
            36.541,
            14.828,
            -35.773,
            36.541,
            14.828,
            -35.631,
            36.92,
            14.828,
            -35.631,
            36.92,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3996",
        "polygon": [
            -35.631,
            36.92,
            11.56,
            -35.773,
            36.541,
            11.56,
            -35.773,
            36.541,
            14.408,
            -35.773,
            36.541,
            14.408,
            -35.631,
            36.92,
            14.408,
            -35.631,
            36.92,
            11.56
        ],
        "05-24 04:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3997",
        "polygon": [
            -36.123,
            35.61,
            11.622,
            -36.579,
            34.397,
            11.622,
            -36.579,
            34.397,
            14.828,
            -36.579,
            34.397,
            14.828,
            -36.123,
            35.61,
            14.828,
            -36.123,
            35.61,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3998",
        "polygon": [
            -35.013,
            38.563,
            11.622,
            -35.594,
            37.018,
            11.622,
            -35.594,
            37.018,
            14.828,
            -35.594,
            37.018,
            14.828,
            -35.013,
            38.563,
            14.828,
            -35.013,
            38.563,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_3999",
        "polygon": [
            -36.773,
            33.881,
            11.622,
            -37.072,
            33.086,
            11.622,
            -37.072,
            33.086,
            14.828,
            -37.072,
            33.086,
            14.828,
            -36.773,
            33.881,
            14.828,
            -36.773,
            33.881,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4000",
        "polygon": [
            -36.616,
            34.299,
            14.408,
            -36.758,
            33.92,
            14.408,
            -36.758,
            33.92,
            14.828,
            -36.758,
            33.92,
            14.828,
            -36.616,
            34.299,
            14.828,
            -36.616,
            34.299,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4001",
        "polygon": [
            -36.616,
            34.299,
            11.56,
            -36.758,
            33.92,
            11.56,
            -36.758,
            33.92,
            14.408,
            -36.758,
            33.92,
            14.408,
            -36.616,
            34.299,
            14.408,
            -36.616,
            34.299,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4002",
        "polygon": [
            -37.109,
            32.989,
            11.622,
            -37.565,
            31.776,
            11.622,
            -37.565,
            31.776,
            14.828,
            -37.565,
            31.776,
            14.828,
            -37.109,
            32.989,
            14.828,
            -37.109,
            32.989,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4003",
        "polygon": [
            -37.759,
            31.26,
            11.622,
            -38.057,
            30.465,
            11.622,
            -38.057,
            30.465,
            14.828,
            -38.057,
            30.465,
            14.828,
            -37.759,
            31.26,
            14.828,
            -37.759,
            31.26,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4004",
        "polygon": [
            -37.601,
            31.678,
            14.408,
            -37.744,
            31.299,
            14.408,
            -37.744,
            31.299,
            14.828,
            -37.744,
            31.299,
            14.828,
            -37.601,
            31.678,
            14.828,
            -37.601,
            31.678,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4005",
        "polygon": [
            -37.601,
            31.678,
            11.56,
            -37.744,
            31.299,
            11.56,
            -37.744,
            31.299,
            14.408,
            -37.744,
            31.299,
            14.408,
            -37.601,
            31.678,
            14.408,
            -37.601,
            31.678,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4006",
        "polygon": [
            -38.094,
            30.368,
            11.622,
            -38.55,
            29.155,
            11.622,
            -38.55,
            29.155,
            14.828,
            -38.55,
            29.155,
            14.828,
            -38.094,
            30.368,
            14.828,
            -38.094,
            30.368,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4007",
        "polygon": [
            -38.744,
            28.639,
            11.622,
            -39.043,
            27.844,
            11.622,
            -39.043,
            27.844,
            14.828,
            -39.043,
            27.844,
            14.828,
            -38.744,
            28.639,
            14.828,
            -38.744,
            28.639,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4008",
        "polygon": [
            -38.587,
            29.057,
            14.408,
            -38.729,
            28.678,
            14.408,
            -38.729,
            28.678,
            14.828,
            -38.729,
            28.678,
            14.828,
            -38.587,
            29.057,
            14.828,
            -38.587,
            29.057,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4009",
        "polygon": [
            -38.587,
            29.057,
            11.56,
            -38.729,
            28.678,
            11.56,
            -38.729,
            28.678,
            14.408,
            -38.729,
            28.678,
            14.408,
            -38.587,
            29.057,
            14.408,
            -38.587,
            29.057,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4010",
        "polygon": [
            -39.079,
            27.747,
            11.622,
            -39.447,
            26.768,
            11.622,
            -39.447,
            26.768,
            14.828,
            -39.447,
            26.768,
            14.828,
            -39.079,
            27.747,
            14.828,
            -39.079,
            27.747,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4011",
        "polygon": [
            -34.337,
            40.36,
            11.53,
            -34.746,
            39.274,
            11.53,
            -34.746,
            39.274,
            14.48,
            -34.746,
            39.274,
            14.48,
            -34.337,
            40.36,
            14.48,
            -34.337,
            40.36,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4012",
        "polygon": [
            -34.203,
            40.716,
            11.53,
            -34.339,
            40.356,
            11.53,
            -34.339,
            40.356,
            14.48,
            -34.339,
            40.356,
            14.48,
            -34.203,
            40.716,
            14.48,
            -34.203,
            40.716,
            11.53
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4013",
        "polygon": [
            -34.768,
            39.214,
            11.58,
            -34.898,
            38.868,
            11.58,
            -34.898,
            38.868,
            14.48,
            -34.898,
            38.868,
            14.48,
            -34.768,
            39.214,
            14.48,
            -34.768,
            39.214,
            11.58
        ]
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_17",
        "polygon": [
            -25.713,
            63.298,
            7.912,
            -26.233,
            61.915,
            7.912,
            -26.233,
            61.915,
            11.118,
            -26.233,
            61.915,
            11.118,
            -25.713,
            63.298,
            11.118,
            -25.713,
            63.298,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_15",
        "polygon": [
            -26.763,
            60.507,
            7.912,
            -27.219,
            59.294,
            7.912,
            -27.219,
            59.294,
            11.118,
            -27.219,
            59.294,
            11.118,
            -26.763,
            60.507,
            11.118,
            -26.763,
            60.507,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_13",
        "polygon": [
            -27.748,
            57.887,
            7.912,
            -28.204,
            56.674,
            7.912,
            -28.204,
            56.674,
            11.118,
            -28.204,
            56.674,
            11.118,
            -27.748,
            57.887,
            11.118,
            -27.748,
            57.887,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_11",
        "polygon": [
            -28.733,
            55.266,
            7.912,
            -29.189,
            54.053,
            7.912,
            -29.189,
            54.053,
            11.118,
            -29.189,
            54.053,
            11.118,
            -28.733,
            55.266,
            11.118,
            -28.733,
            55.266,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_9",
        "polygon": [
            -29.718,
            52.646,
            7.912,
            -30.175,
            51.432,
            7.912,
            -30.175,
            51.432,
            11.118,
            -30.175,
            51.432,
            11.118,
            -29.718,
            52.646,
            11.118,
            -29.718,
            52.646,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_7",
        "polygon": [
            -30.704,
            50.025,
            7.912,
            -31.16,
            48.812,
            7.912,
            -31.16,
            48.812,
            11.118,
            -31.16,
            48.812,
            11.118,
            -30.704,
            50.025,
            11.118,
            -30.704,
            50.025,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_1",
        "polygon": [
            -33.66,
            42.162,
            7.912,
            -34.055,
            41.112,
            7.912,
            -34.055,
            41.112,
            11.118,
            -34.055,
            41.112,
            11.118,
            -33.66,
            42.162,
            11.118,
            -33.66,
            42.162,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_5",
        "polygon": [
            -31.689,
            47.404,
            7.912,
            -32.145,
            46.191,
            7.912,
            -32.145,
            46.191,
            11.118,
            -32.145,
            46.191,
            11.118,
            -31.689,
            47.404,
            11.118,
            -31.689,
            47.404,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_3",
        "polygon": [
            -32.674,
            44.783,
            7.912,
            -33.131,
            43.57,
            7.912,
            -33.131,
            43.57,
            11.118,
            -33.131,
            43.57,
            11.118,
            -32.674,
            44.783,
            11.118,
            -32.674,
            44.783,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4026",
        "polygon": [
            -36.123,
            35.61,
            7.912,
            -36.579,
            34.397,
            7.912,
            -36.579,
            34.397,
            11.118,
            -36.579,
            34.397,
            11.118,
            -36.123,
            35.61,
            11.118,
            -36.123,
            35.61,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4027",
        "polygon": [
            -35.013,
            38.563,
            7.912,
            -35.594,
            37.018,
            7.912,
            -35.594,
            37.018,
            11.118,
            -35.594,
            37.018,
            11.118,
            -35.013,
            38.563,
            11.118,
            -35.013,
            38.563,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4028",
        "polygon": [
            -37.109,
            32.989,
            7.912,
            -37.565,
            31.776,
            7.912,
            -37.565,
            31.776,
            11.118,
            -37.565,
            31.776,
            11.118,
            -37.109,
            32.989,
            11.118,
            -37.109,
            32.989,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4029",
        "polygon": [
            -38.094,
            30.368,
            7.912,
            -38.55,
            29.155,
            7.912,
            -38.55,
            29.155,
            11.118,
            -38.55,
            29.155,
            11.118,
            -38.094,
            30.368,
            11.118,
            -38.094,
            30.368,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4030",
        "polygon": [
            -39.079,
            27.747,
            7.912,
            -39.447,
            26.768,
            7.912,
            -39.447,
            26.768,
            11.118,
            -39.447,
            26.768,
            11.118,
            -39.079,
            27.747,
            11.118,
            -39.079,
            27.747,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4031",
        "polygon": [
            -34.337,
            40.36,
            7.82,
            -34.746,
            39.274,
            7.82,
            -34.746,
            39.274,
            10.77,
            -34.746,
            39.274,
            10.77,
            -34.337,
            40.36,
            10.77,
            -34.337,
            40.36,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4032",
        "polygon": [
            -34.203,
            40.716,
            7.82,
            -34.339,
            40.356,
            7.82,
            -34.339,
            40.356,
            10.77,
            -34.339,
            40.356,
            10.77,
            -34.203,
            40.716,
            10.77,
            -34.203,
            40.716,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4033",
        "polygon": [
            -34.768,
            39.214,
            7.87,
            -34.898,
            38.868,
            7.87,
            -34.898,
            38.868,
            10.77,
            -34.898,
            38.868,
            10.77,
            -34.768,
            39.214,
            10.77,
            -34.768,
            39.214,
            7.87
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4055",
        "polygon": [
            -35.631,
            36.92,
            7.912,
            -35.929,
            36.125,
            7.912,
            -35.929,
            36.125,
            11.118,
            -35.929,
            36.125,
            11.118,
            -35.631,
            36.92,
            11.118,
            -35.631,
            36.92,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4056",
        "polygon": [
            -35.944,
            36.086,
            7.85,
            -36.087,
            35.707,
            7.85,
            -36.087,
            35.707,
            10.698,
            -36.087,
            35.707,
            10.698,
            -35.944,
            36.086,
            10.698,
            -35.944,
            36.086,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4057",
        "polygon": [
            -35.944,
            36.086,
            10.698,
            -36.087,
            35.707,
            10.698,
            -36.087,
            35.707,
            11.118,
            -36.087,
            35.707,
            11.118,
            -35.944,
            36.086,
            11.118,
            -35.944,
            36.086,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4058",
        "polygon": [
            -36.616,
            34.299,
            7.912,
            -36.915,
            33.505,
            7.912,
            -36.915,
            33.505,
            11.118,
            -36.915,
            33.505,
            11.118,
            -36.616,
            34.299,
            11.118,
            -36.616,
            34.299,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4059",
        "polygon": [
            -36.929,
            33.465,
            7.85,
            -37.072,
            33.086,
            7.85,
            -37.072,
            33.086,
            10.698,
            -37.072,
            33.086,
            10.698,
            -36.929,
            33.465,
            10.698,
            -36.929,
            33.465,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4060",
        "polygon": [
            -36.929,
            33.465,
            10.698,
            -37.072,
            33.086,
            10.698,
            -37.072,
            33.086,
            11.118,
            -37.072,
            33.086,
            11.118,
            -36.929,
            33.465,
            11.118,
            -36.929,
            33.465,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4061",
        "polygon": [
            -37.601,
            31.678,
            7.912,
            -37.9,
            30.884,
            7.912,
            -37.9,
            30.884,
            11.118,
            -37.9,
            30.884,
            11.118,
            -37.601,
            31.678,
            11.118,
            -37.601,
            31.678,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4062",
        "polygon": [
            -37.915,
            30.844,
            7.85,
            -38.057,
            30.465,
            7.85,
            -38.057,
            30.465,
            10.698,
            -38.057,
            30.465,
            10.698,
            -37.915,
            30.844,
            10.698,
            -37.915,
            30.844,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4063",
        "polygon": [
            -37.915,
            30.844,
            10.698,
            -38.057,
            30.465,
            10.698,
            -38.057,
            30.465,
            11.118,
            -38.057,
            30.465,
            11.118,
            -37.915,
            30.844,
            11.118,
            -37.915,
            30.844,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4064",
        "polygon": [
            -38.587,
            29.057,
            7.912,
            -38.885,
            28.263,
            7.912,
            -38.885,
            28.263,
            11.118,
            -38.885,
            28.263,
            11.118,
            -38.587,
            29.057,
            11.118,
            -38.587,
            29.057,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4065",
        "polygon": [
            -38.9,
            28.223,
            7.85,
            -39.043,
            27.844,
            7.85,
            -39.043,
            27.844,
            10.698,
            -39.043,
            27.844,
            10.698,
            -38.9,
            28.223,
            10.698,
            -38.9,
            28.223,
            7.85
        ],
        "05-24 09:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4066",
        "polygon": [
            -38.9,
            28.223,
            10.698,
            -39.043,
            27.844,
            10.698,
            -39.043,
            27.844,
            11.118,
            -39.043,
            27.844,
            11.118,
            -38.9,
            28.223,
            11.118,
            -38.9,
            28.223,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4067",
        "polygon": [
            -27.691,
            58.037,
            0.142,
            -28.212,
            56.654,
            0.142,
            -28.212,
            56.654,
            3.698,
            -28.212,
            56.654,
            3.698,
            -27.691,
            58.037,
            3.698,
            -27.691,
            58.037,
            0.142
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4068",
        "polygon": [
            -28.233,
            56.597,
            0.142,
            -29.197,
            54.033,
            0.142,
            -29.197,
            54.033,
            3.698,
            -29.197,
            54.033,
            3.698,
            -28.233,
            56.597,
            3.698,
            -28.233,
            56.597,
            0.142
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4075",
        "polygon": [
            -29.218,
            53.977,
            0.142,
            -30.182,
            51.412,
            0.142,
            -30.182,
            51.412,
            3.698,
            -30.182,
            51.412,
            3.698,
            -29.218,
            53.977,
            3.698,
            -29.218,
            53.977,
            0.142
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_7",
        "polygon": [
            -30.704,
            50.025,
            0.142,
            -31.16,
            48.812,
            0.142,
            -31.16,
            48.812,
            3.698,
            -31.16,
            48.812,
            3.698,
            -30.704,
            50.025,
            3.698,
            -30.704,
            50.025,
            0.142
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_5",
        "polygon": [
            -31.689,
            47.404,
            0.142,
            -32.145,
            46.191,
            0.142,
            -32.145,
            46.191,
            3.698,
            -32.145,
            46.191,
            3.698,
            -31.689,
            47.404,
            3.698,
            -31.689,
            47.404,
            0.142
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_3",
        "polygon": [
            -32.674,
            44.783,
            0.142,
            -33.131,
            43.57,
            0.142,
            -33.131,
            43.57,
            3.698,
            -33.131,
            43.57,
            3.698,
            -32.674,
            44.783,
            3.698,
            -32.674,
            44.783,
            0.142
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_1",
        "polygon": [
            -33.66,
            42.162,
            0.142,
            -34.055,
            41.112,
            0.142,
            -34.055,
            41.112,
            3.698,
            -34.055,
            41.112,
            3.698,
            -33.66,
            42.162,
            3.698,
            -33.66,
            42.162,
            0.142
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4090",
        "polygon": [
            -34.337,
            40.36,
            0.05,
            -34.746,
            39.274,
            0.05,
            -34.746,
            39.274,
            3.0,
            -34.746,
            39.274,
            3.0,
            -34.337,
            40.36,
            3.0,
            -34.337,
            40.36,
            0.05
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4091",
        "polygon": [
            -34.203,
            40.716,
            0.05,
            -34.339,
            40.356,
            0.05,
            -34.339,
            40.356,
            3.0,
            -34.339,
            40.356,
            3.0,
            -34.203,
            40.716,
            3.0,
            -34.203,
            40.716,
            0.05
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4092",
        "polygon": [
            -34.768,
            39.214,
            0.1,
            -34.898,
            38.868,
            0.1,
            -34.898,
            38.868,
            3.0,
            -34.898,
            38.868,
            3.0,
            -34.768,
            39.214,
            3.0,
            -34.768,
            39.214,
            0.1
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4093",
        "polygon": [
            -36.616,
            34.298,
            0.142,
            -36.915,
            33.504,
            0.142,
            -36.915,
            33.504,
            3.698,
            -36.915,
            33.504,
            3.698,
            -36.616,
            34.298,
            3.698,
            -36.616,
            34.298,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4094",
        "polygon": [
            -36.93,
            33.464,
            0.08,
            -37.072,
            33.085,
            0.08,
            -37.072,
            33.085,
            2.928,
            -37.072,
            33.085,
            2.928,
            -36.93,
            33.464,
            2.928,
            -36.93,
            33.464,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4095",
        "polygon": [
            -36.93,
            33.464,
            2.928,
            -37.072,
            33.085,
            2.928,
            -37.072,
            33.085,
            3.698,
            -37.072,
            33.085,
            3.698,
            -36.93,
            33.464,
            3.698,
            -36.93,
            33.464,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4096",
        "polygon": [
            -37.109,
            32.988,
            0.142,
            -37.565,
            31.775,
            0.142,
            -37.565,
            31.775,
            3.698,
            -37.565,
            31.775,
            3.698,
            -37.109,
            32.988,
            3.698,
            -37.109,
            32.988,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4097",
        "polygon": [
            -37.602,
            31.677,
            0.142,
            -37.9,
            30.883,
            0.142,
            -37.9,
            30.883,
            3.698,
            -37.9,
            30.883,
            3.698,
            -37.602,
            31.677,
            3.698,
            -37.602,
            31.677,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4098",
        "polygon": [
            -37.915,
            30.843,
            0.08,
            -38.058,
            30.464,
            0.08,
            -38.058,
            30.464,
            2.928,
            -38.058,
            30.464,
            2.928,
            -37.915,
            30.843,
            2.928,
            -37.915,
            30.843,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4099",
        "polygon": [
            -37.915,
            30.843,
            2.928,
            -38.058,
            30.464,
            2.928,
            -38.058,
            30.464,
            3.698,
            -38.058,
            30.464,
            3.698,
            -37.915,
            30.843,
            3.698,
            -37.915,
            30.843,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4100",
        "polygon": [
            -38.094,
            30.367,
            0.142,
            -38.55,
            29.154,
            0.142,
            -38.55,
            29.154,
            3.698,
            -38.55,
            29.154,
            3.698,
            -38.094,
            30.367,
            3.698,
            -38.094,
            30.367,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4101",
        "polygon": [
            -38.587,
            29.056,
            0.142,
            -38.886,
            28.262,
            0.142,
            -38.886,
            28.262,
            3.698,
            -38.886,
            28.262,
            3.698,
            -38.587,
            29.056,
            3.698,
            -38.587,
            29.056,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4102",
        "polygon": [
            -38.901,
            28.222,
            0.08,
            -39.043,
            27.843,
            0.08,
            -39.043,
            27.843,
            2.928,
            -39.043,
            27.843,
            2.928,
            -38.901,
            28.222,
            2.928,
            -38.901,
            28.222,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4103",
        "polygon": [
            -38.901,
            28.222,
            2.928,
            -39.043,
            27.843,
            2.928,
            -39.043,
            27.843,
            3.698,
            -39.043,
            27.843,
            3.698,
            -38.901,
            28.222,
            3.698,
            -38.901,
            28.222,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4105",
        "polygon": [
            -39.079,
            27.747,
            0.142,
            -39.447,
            26.768,
            0.142,
            -39.447,
            26.768,
            3.698,
            -39.447,
            26.768,
            3.698,
            -39.079,
            27.747,
            3.698,
            -39.079,
            27.747,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4106",
        "polygon": [
            -21.2,
            19.66,
            4.202,
            -20.22,
            19.292,
            4.202,
            -20.22,
            19.292,
            7.408,
            -20.22,
            19.292,
            7.408,
            -21.2,
            19.66,
            7.408,
            -21.2,
            19.66,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4107",
        "polygon": [
            -22.092,
            19.995,
            4.202,
            -21.297,
            19.696,
            4.202,
            -21.297,
            19.696,
            7.408,
            -21.297,
            19.696,
            7.408,
            -22.092,
            19.995,
            7.408,
            -22.092,
            19.995,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4108",
        "polygon": [
            -22.51,
            20.152,
            6.988,
            -22.131,
            20.01,
            6.988,
            -22.131,
            20.01,
            7.408,
            -22.131,
            20.01,
            7.408,
            -22.51,
            20.152,
            7.408,
            -22.51,
            20.152,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4109",
        "polygon": [
            -22.51,
            20.152,
            4.14,
            -22.131,
            20.01,
            4.14,
            -22.131,
            20.01,
            6.988,
            -22.131,
            20.01,
            6.988,
            -22.51,
            20.152,
            6.988,
            -22.51,
            20.152,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4110",
        "polygon": [
            -23.82,
            20.645,
            4.202,
            -22.607,
            20.189,
            4.202,
            -22.607,
            20.189,
            7.408,
            -22.607,
            20.189,
            7.408,
            -23.82,
            20.645,
            7.408,
            -23.82,
            20.645,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4111",
        "polygon": [
            -24.713,
            20.98,
            4.202,
            -23.918,
            20.682,
            4.202,
            -23.918,
            20.682,
            7.408,
            -23.918,
            20.682,
            7.408,
            -24.713,
            20.98,
            7.408,
            -24.713,
            20.98,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4112",
        "polygon": [
            -25.131,
            21.138,
            6.988,
            -24.752,
            20.995,
            6.988,
            -24.752,
            20.995,
            7.408,
            -24.752,
            20.995,
            7.408,
            -25.131,
            21.138,
            7.408,
            -25.131,
            21.138,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4113",
        "polygon": [
            -25.131,
            21.138,
            4.14,
            -24.752,
            20.995,
            4.14,
            -24.752,
            20.995,
            6.988,
            -24.752,
            20.995,
            6.988,
            -25.131,
            21.138,
            6.988,
            -25.131,
            21.138,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4114",
        "polygon": [
            -26.441,
            21.63,
            4.202,
            -25.228,
            21.174,
            4.202,
            -25.228,
            21.174,
            7.408,
            -25.228,
            21.174,
            7.408,
            -26.441,
            21.63,
            7.408,
            -26.441,
            21.63,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4115",
        "polygon": [
            -27.333,
            21.966,
            4.202,
            -26.539,
            21.667,
            4.202,
            -26.539,
            21.667,
            7.408,
            -26.539,
            21.667,
            7.408,
            -27.333,
            21.966,
            7.408,
            -27.333,
            21.966,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4116",
        "polygon": [
            -27.752,
            22.123,
            6.988,
            -27.373,
            21.981,
            6.988,
            -27.373,
            21.981,
            7.408,
            -27.373,
            21.981,
            7.408,
            -27.752,
            22.123,
            7.408,
            -27.752,
            22.123,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4117",
        "polygon": [
            -27.752,
            22.123,
            4.14,
            -27.373,
            21.981,
            4.14,
            -27.373,
            21.981,
            6.988,
            -27.373,
            21.981,
            6.988,
            -27.752,
            22.123,
            6.988,
            -27.752,
            22.123,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4118",
        "polygon": [
            -29.062,
            22.616,
            4.202,
            -27.849,
            22.16,
            4.202,
            -27.849,
            22.16,
            7.408,
            -27.849,
            22.16,
            7.408,
            -29.062,
            22.616,
            7.408,
            -29.062,
            22.616,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4119",
        "polygon": [
            -29.954,
            22.951,
            4.202,
            -29.16,
            22.652,
            4.202,
            -29.16,
            22.652,
            7.408,
            -29.16,
            22.652,
            7.408,
            -29.954,
            22.951,
            7.408,
            -29.954,
            22.951,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4120",
        "polygon": [
            -30.373,
            23.108,
            6.988,
            -29.994,
            22.966,
            6.988,
            -29.994,
            22.966,
            7.408,
            -29.994,
            22.966,
            7.408,
            -30.373,
            23.108,
            7.408,
            -30.373,
            23.108,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4121",
        "polygon": [
            -30.373,
            23.108,
            4.14,
            -29.994,
            22.966,
            4.14,
            -29.994,
            22.966,
            6.988,
            -29.994,
            22.966,
            6.988,
            -30.373,
            23.108,
            6.988,
            -30.373,
            23.108,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4122",
        "polygon": [
            -31.683,
            23.601,
            4.202,
            -30.47,
            23.145,
            4.202,
            -30.47,
            23.145,
            7.408,
            -30.47,
            23.145,
            7.408,
            -31.683,
            23.601,
            7.408,
            -31.683,
            23.601,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4123",
        "polygon": [
            -32.575,
            23.936,
            4.202,
            -31.781,
            23.638,
            4.202,
            -31.781,
            23.638,
            7.408,
            -31.781,
            23.638,
            7.408,
            -32.575,
            23.936,
            7.408,
            -32.575,
            23.936,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4124",
        "polygon": [
            -32.994,
            24.094,
            6.988,
            -32.615,
            23.951,
            6.988,
            -32.615,
            23.951,
            7.408,
            -32.615,
            23.951,
            7.408,
            -32.994,
            24.094,
            7.408,
            -32.994,
            24.094,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4125",
        "polygon": [
            -32.994,
            24.094,
            4.14,
            -32.615,
            23.951,
            4.14,
            -32.615,
            23.951,
            6.988,
            -32.615,
            23.951,
            6.988,
            -32.994,
            24.094,
            6.988,
            -32.994,
            24.094,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4126",
        "polygon": [
            -34.304,
            24.586,
            4.202,
            -33.091,
            24.13,
            4.202,
            -33.091,
            24.13,
            7.408,
            -33.091,
            24.13,
            7.408,
            -34.304,
            24.586,
            7.408,
            -34.304,
            24.586,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4127",
        "polygon": [
            -35.196,
            24.922,
            4.202,
            -34.401,
            24.623,
            4.202,
            -34.401,
            24.623,
            7.408,
            -34.401,
            24.623,
            7.408,
            -35.196,
            24.922,
            7.408,
            -35.196,
            24.922,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4128",
        "polygon": [
            -35.615,
            25.079,
            6.988,
            -35.235,
            24.937,
            6.988,
            -35.235,
            24.937,
            7.408,
            -35.235,
            24.937,
            7.408,
            -35.615,
            25.079,
            7.408,
            -35.615,
            25.079,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4129",
        "polygon": [
            -35.615,
            25.079,
            4.14,
            -35.235,
            24.937,
            4.14,
            -35.235,
            24.937,
            6.988,
            -35.235,
            24.937,
            6.988,
            -35.615,
            25.079,
            6.988,
            -35.615,
            25.079,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4130",
        "polygon": [
            -36.925,
            25.572,
            4.202,
            -35.712,
            25.116,
            4.202,
            -35.712,
            25.116,
            7.408,
            -35.712,
            25.116,
            7.408,
            -36.925,
            25.572,
            7.408,
            -36.925,
            25.572,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4131",
        "polygon": [
            -37.817,
            25.907,
            4.202,
            -37.022,
            25.608,
            4.202,
            -37.022,
            25.608,
            7.408,
            -37.022,
            25.608,
            7.408,
            -37.817,
            25.907,
            7.408,
            -37.817,
            25.907,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4132",
        "polygon": [
            -38.235,
            26.064,
            6.988,
            -37.856,
            25.922,
            6.988,
            -37.856,
            25.922,
            7.408,
            -37.856,
            25.922,
            7.408,
            -38.235,
            26.064,
            7.408,
            -38.235,
            26.064,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4133",
        "polygon": [
            -38.235,
            26.064,
            4.14,
            -37.856,
            25.922,
            4.14,
            -37.856,
            25.922,
            6.988,
            -37.856,
            25.922,
            6.988,
            -38.235,
            26.064,
            6.988,
            -38.235,
            26.064,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4134",
        "polygon": [
            -39.312,
            26.469,
            4.202,
            -38.333,
            26.101,
            4.202,
            -38.333,
            26.101,
            7.408,
            -38.333,
            26.101,
            7.408,
            -39.312,
            26.469,
            7.408,
            -39.312,
            26.469,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4135",
        "polygon": [
            -21.2,
            19.66,
            11.622,
            -20.22,
            19.292,
            11.622,
            -20.22,
            19.292,
            14.828,
            -20.22,
            19.292,
            14.828,
            -21.2,
            19.66,
            14.828,
            -21.2,
            19.66,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4136",
        "polygon": [
            -22.092,
            19.995,
            11.622,
            -21.297,
            19.696,
            11.622,
            -21.297,
            19.696,
            14.828,
            -21.297,
            19.696,
            14.828,
            -22.092,
            19.995,
            14.828,
            -22.092,
            19.995,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4137",
        "polygon": [
            -22.51,
            20.152,
            14.408,
            -22.131,
            20.01,
            14.408,
            -22.131,
            20.01,
            14.828,
            -22.131,
            20.01,
            14.828,
            -22.51,
            20.152,
            14.828,
            -22.51,
            20.152,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4138",
        "polygon": [
            -22.51,
            20.152,
            11.56,
            -22.131,
            20.01,
            11.56,
            -22.131,
            20.01,
            14.408,
            -22.131,
            20.01,
            14.408,
            -22.51,
            20.152,
            14.408,
            -22.51,
            20.152,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4139",
        "polygon": [
            -23.82,
            20.645,
            11.622,
            -22.607,
            20.189,
            11.622,
            -22.607,
            20.189,
            14.828,
            -22.607,
            20.189,
            14.828,
            -23.82,
            20.645,
            14.828,
            -23.82,
            20.645,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4140",
        "polygon": [
            -24.713,
            20.98,
            11.622,
            -23.918,
            20.682,
            11.622,
            -23.918,
            20.682,
            14.828,
            -23.918,
            20.682,
            14.828,
            -24.713,
            20.98,
            14.828,
            -24.713,
            20.98,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4141",
        "polygon": [
            -25.131,
            21.138,
            14.408,
            -24.752,
            20.995,
            14.408,
            -24.752,
            20.995,
            14.828,
            -24.752,
            20.995,
            14.828,
            -25.131,
            21.138,
            14.828,
            -25.131,
            21.138,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4142",
        "polygon": [
            -25.131,
            21.138,
            11.56,
            -24.752,
            20.995,
            11.56,
            -24.752,
            20.995,
            14.408,
            -24.752,
            20.995,
            14.408,
            -25.131,
            21.138,
            14.408,
            -25.131,
            21.138,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4143",
        "polygon": [
            -26.441,
            21.63,
            11.622,
            -25.228,
            21.174,
            11.622,
            -25.228,
            21.174,
            14.828,
            -25.228,
            21.174,
            14.828,
            -26.441,
            21.63,
            14.828,
            -26.441,
            21.63,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4144",
        "polygon": [
            -27.333,
            21.966,
            11.622,
            -26.539,
            21.667,
            11.622,
            -26.539,
            21.667,
            14.828,
            -26.539,
            21.667,
            14.828,
            -27.333,
            21.966,
            14.828,
            -27.333,
            21.966,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4145",
        "polygon": [
            -27.752,
            22.123,
            14.408,
            -27.373,
            21.981,
            14.408,
            -27.373,
            21.981,
            14.828,
            -27.373,
            21.981,
            14.828,
            -27.752,
            22.123,
            14.828,
            -27.752,
            22.123,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4146",
        "polygon": [
            -27.752,
            22.123,
            11.56,
            -27.373,
            21.981,
            11.56,
            -27.373,
            21.981,
            14.408,
            -27.373,
            21.981,
            14.408,
            -27.752,
            22.123,
            14.408,
            -27.752,
            22.123,
            11.56
        ],
        "05-24 05:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4147",
        "polygon": [
            -29.062,
            22.616,
            11.622,
            -27.849,
            22.16,
            11.622,
            -27.849,
            22.16,
            14.828,
            -27.849,
            22.16,
            14.828,
            -29.062,
            22.616,
            14.828,
            -29.062,
            22.616,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4148",
        "polygon": [
            -29.954,
            22.951,
            11.622,
            -29.16,
            22.652,
            11.622,
            -29.16,
            22.652,
            14.828,
            -29.16,
            22.652,
            14.828,
            -29.954,
            22.951,
            14.828,
            -29.954,
            22.951,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4149",
        "polygon": [
            -30.373,
            23.108,
            14.408,
            -29.994,
            22.966,
            14.408,
            -29.994,
            22.966,
            14.828,
            -29.994,
            22.966,
            14.828,
            -30.373,
            23.108,
            14.828,
            -30.373,
            23.108,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4150",
        "polygon": [
            -30.373,
            23.108,
            11.56,
            -29.994,
            22.966,
            11.56,
            -29.994,
            22.966,
            14.408,
            -29.994,
            22.966,
            14.408,
            -30.373,
            23.108,
            14.408,
            -30.373,
            23.108,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4151",
        "polygon": [
            -31.683,
            23.601,
            11.622,
            -30.47,
            23.145,
            11.622,
            -30.47,
            23.145,
            14.828,
            -30.47,
            23.145,
            14.828,
            -31.683,
            23.601,
            14.828,
            -31.683,
            23.601,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4152",
        "polygon": [
            -32.575,
            23.936,
            11.622,
            -31.781,
            23.638,
            11.622,
            -31.781,
            23.638,
            14.828,
            -31.781,
            23.638,
            14.828,
            -32.575,
            23.936,
            14.828,
            -32.575,
            23.936,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4153",
        "polygon": [
            -32.994,
            24.094,
            14.408,
            -32.615,
            23.951,
            14.408,
            -32.615,
            23.951,
            14.828,
            -32.615,
            23.951,
            14.828,
            -32.994,
            24.094,
            14.828,
            -32.994,
            24.094,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4154",
        "polygon": [
            -32.994,
            24.094,
            11.56,
            -32.615,
            23.951,
            11.56,
            -32.615,
            23.951,
            14.408,
            -32.615,
            23.951,
            14.408,
            -32.994,
            24.094,
            14.408,
            -32.994,
            24.094,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4155",
        "polygon": [
            -34.304,
            24.586,
            11.622,
            -33.091,
            24.13,
            11.622,
            -33.091,
            24.13,
            14.828,
            -33.091,
            24.13,
            14.828,
            -34.304,
            24.586,
            14.828,
            -34.304,
            24.586,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4156",
        "polygon": [
            -35.196,
            24.922,
            11.622,
            -34.401,
            24.623,
            11.622,
            -34.401,
            24.623,
            14.828,
            -34.401,
            24.623,
            14.828,
            -35.196,
            24.922,
            14.828,
            -35.196,
            24.922,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4157",
        "polygon": [
            -35.615,
            25.079,
            14.408,
            -35.235,
            24.937,
            14.408,
            -35.235,
            24.937,
            14.828,
            -35.235,
            24.937,
            14.828,
            -35.615,
            25.079,
            14.828,
            -35.615,
            25.079,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4158",
        "polygon": [
            -35.615,
            25.079,
            11.56,
            -35.235,
            24.937,
            11.56,
            -35.235,
            24.937,
            14.408,
            -35.235,
            24.937,
            14.408,
            -35.615,
            25.079,
            14.408,
            -35.615,
            25.079,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4159",
        "polygon": [
            -36.925,
            25.572,
            11.622,
            -35.712,
            25.116,
            11.622,
            -35.712,
            25.116,
            14.828,
            -35.712,
            25.116,
            14.828,
            -36.925,
            25.572,
            14.828,
            -36.925,
            25.572,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4160",
        "polygon": [
            -37.817,
            25.907,
            11.622,
            -37.022,
            25.608,
            11.622,
            -37.022,
            25.608,
            14.828,
            -37.022,
            25.608,
            14.828,
            -37.817,
            25.907,
            14.828,
            -37.817,
            25.907,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4161",
        "polygon": [
            -38.235,
            26.064,
            14.408,
            -37.856,
            25.922,
            14.408,
            -37.856,
            25.922,
            14.828,
            -37.856,
            25.922,
            14.828,
            -38.235,
            26.064,
            14.828,
            -38.235,
            26.064,
            14.408
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4162",
        "polygon": [
            -38.235,
            26.064,
            11.56,
            -37.856,
            25.922,
            11.56,
            -37.856,
            25.922,
            14.408,
            -37.856,
            25.922,
            14.408,
            -38.235,
            26.064,
            14.408,
            -38.235,
            26.064,
            11.56
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4163",
        "polygon": [
            -39.312,
            26.469,
            11.622,
            -38.333,
            26.101,
            11.622,
            -38.333,
            26.101,
            14.828,
            -38.333,
            26.101,
            14.828,
            -39.312,
            26.469,
            14.828,
            -39.312,
            26.469,
            11.622
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4164",
        "polygon": [
            -21.2,
            19.66,
            7.912,
            -20.22,
            19.292,
            7.912,
            -20.22,
            19.292,
            11.118,
            -20.22,
            19.292,
            11.118,
            -21.2,
            19.66,
            11.118,
            -21.2,
            19.66,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4165",
        "polygon": [
            -23.82,
            20.645,
            7.912,
            -22.607,
            20.189,
            7.912,
            -22.607,
            20.189,
            11.118,
            -22.607,
            20.189,
            11.118,
            -23.82,
            20.645,
            11.118,
            -23.82,
            20.645,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4166",
        "polygon": [
            -26.441,
            21.63,
            7.912,
            -25.228,
            21.174,
            7.912,
            -25.228,
            21.174,
            11.118,
            -25.228,
            21.174,
            11.118,
            -26.441,
            21.63,
            11.118,
            -26.441,
            21.63,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4167",
        "polygon": [
            -29.062,
            22.616,
            7.912,
            -27.849,
            22.16,
            7.912,
            -27.849,
            22.16,
            11.118,
            -27.849,
            22.16,
            11.118,
            -29.062,
            22.616,
            11.118,
            -29.062,
            22.616,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4168",
        "polygon": [
            -31.683,
            23.601,
            7.912,
            -30.47,
            23.145,
            7.912,
            -30.47,
            23.145,
            11.118,
            -30.47,
            23.145,
            11.118,
            -31.683,
            23.601,
            11.118,
            -31.683,
            23.601,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4169",
        "polygon": [
            -34.304,
            24.586,
            7.912,
            -33.091,
            24.13,
            7.912,
            -33.091,
            24.13,
            11.118,
            -33.091,
            24.13,
            11.118,
            -34.304,
            24.586,
            11.118,
            -34.304,
            24.586,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4170",
        "polygon": [
            -36.925,
            25.572,
            7.912,
            -35.712,
            25.116,
            7.912,
            -35.712,
            25.116,
            11.118,
            -35.712,
            25.116,
            11.118,
            -36.925,
            25.572,
            11.118,
            -36.925,
            25.572,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4171",
        "polygon": [
            -38.235,
            26.064,
            7.912,
            -37.441,
            25.766,
            7.912,
            -37.441,
            25.766,
            11.118,
            -37.441,
            25.766,
            11.118,
            -38.235,
            26.064,
            11.118,
            -38.235,
            26.064,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4172",
        "polygon": [
            -37.4,
            25.751,
            10.698,
            -37.021,
            25.608,
            10.698,
            -37.021,
            25.608,
            11.118,
            -37.021,
            25.608,
            11.118,
            -37.4,
            25.751,
            11.118,
            -37.4,
            25.751,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4173",
        "polygon": [
            -37.4,
            25.751,
            7.85,
            -37.021,
            25.608,
            7.85,
            -37.021,
            25.608,
            10.698,
            -37.021,
            25.608,
            10.698,
            -37.4,
            25.751,
            10.698,
            -37.4,
            25.751,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4174",
        "polygon": [
            -39.312,
            26.469,
            7.912,
            -38.333,
            26.101,
            7.912,
            -38.333,
            26.101,
            11.118,
            -38.333,
            26.101,
            11.118,
            -39.312,
            26.469,
            11.118,
            -39.312,
            26.469,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4175",
        "polygon": [
            -35.615,
            25.079,
            7.912,
            -34.82,
            24.78,
            7.912,
            -34.82,
            24.78,
            11.118,
            -34.82,
            24.78,
            11.118,
            -35.615,
            25.079,
            11.118,
            -35.615,
            25.079,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4176",
        "polygon": [
            -34.779,
            24.765,
            10.698,
            -34.4,
            24.623,
            10.698,
            -34.4,
            24.623,
            11.118,
            -34.4,
            24.623,
            11.118,
            -34.779,
            24.765,
            11.118,
            -34.779,
            24.765,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4177",
        "polygon": [
            -34.779,
            24.765,
            7.85,
            -34.4,
            24.623,
            7.85,
            -34.4,
            24.623,
            10.698,
            -34.4,
            24.623,
            10.698,
            -34.779,
            24.765,
            10.698,
            -34.779,
            24.765,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4178",
        "polygon": [
            -32.994,
            24.094,
            7.912,
            -32.199,
            23.795,
            7.912,
            -32.199,
            23.795,
            11.118,
            -32.199,
            23.795,
            11.118,
            -32.994,
            24.094,
            11.118,
            -32.994,
            24.094,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4179",
        "polygon": [
            -32.159,
            23.78,
            10.698,
            -31.779,
            23.637,
            10.698,
            -31.779,
            23.637,
            11.118,
            -31.779,
            23.637,
            11.118,
            -32.159,
            23.78,
            11.118,
            -32.159,
            23.78,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4180",
        "polygon": [
            -32.159,
            23.78,
            7.85,
            -31.779,
            23.637,
            7.85,
            -31.779,
            23.637,
            10.698,
            -31.779,
            23.637,
            10.698,
            -32.159,
            23.78,
            10.698,
            -32.159,
            23.78,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4181",
        "polygon": [
            -30.373,
            23.108,
            7.912,
            -29.578,
            22.81,
            7.912,
            -29.578,
            22.81,
            11.118,
            -29.578,
            22.81,
            11.118,
            -30.373,
            23.108,
            11.118,
            -30.373,
            23.108,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4182",
        "polygon": [
            -29.538,
            22.794,
            10.698,
            -29.159,
            22.652,
            10.698,
            -29.159,
            22.652,
            11.118,
            -29.159,
            22.652,
            11.118,
            -29.538,
            22.794,
            11.118,
            -29.538,
            22.794,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4183",
        "polygon": [
            -29.538,
            22.794,
            7.85,
            -29.159,
            22.652,
            7.85,
            -29.159,
            22.652,
            10.698,
            -29.159,
            22.652,
            10.698,
            -29.538,
            22.794,
            10.698,
            -29.538,
            22.794,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4184",
        "polygon": [
            -27.752,
            22.123,
            7.912,
            -26.957,
            21.824,
            7.912,
            -26.957,
            21.824,
            11.118,
            -26.957,
            21.824,
            11.118,
            -27.752,
            22.123,
            11.118,
            -27.752,
            22.123,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4185",
        "polygon": [
            -26.917,
            21.809,
            10.698,
            -26.538,
            21.667,
            10.698,
            -26.538,
            21.667,
            11.118,
            -26.538,
            21.667,
            11.118,
            -26.917,
            21.809,
            11.118,
            -26.917,
            21.809,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4186",
        "polygon": [
            -26.917,
            21.809,
            7.85,
            -26.538,
            21.667,
            7.85,
            -26.538,
            21.667,
            10.698,
            -26.538,
            21.667,
            10.698,
            -26.917,
            21.809,
            10.698,
            -26.917,
            21.809,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4187",
        "polygon": [
            -25.131,
            21.138,
            7.912,
            -24.336,
            20.839,
            7.912,
            -24.336,
            20.839,
            11.118,
            -24.336,
            20.839,
            11.118,
            -25.131,
            21.138,
            11.118,
            -25.131,
            21.138,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4188",
        "polygon": [
            -24.296,
            20.824,
            10.698,
            -23.917,
            20.681,
            10.698,
            -23.917,
            20.681,
            11.118,
            -23.917,
            20.681,
            11.118,
            -24.296,
            20.824,
            11.118,
            -24.296,
            20.824,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4189",
        "polygon": [
            -24.296,
            20.824,
            7.85,
            -23.917,
            20.681,
            7.85,
            -23.917,
            20.681,
            10.698,
            -23.917,
            20.681,
            10.698,
            -24.296,
            20.824,
            10.698,
            -24.296,
            20.824,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4190",
        "polygon": [
            -22.51,
            20.152,
            7.912,
            -21.715,
            19.854,
            7.912,
            -21.715,
            19.854,
            11.118,
            -21.715,
            19.854,
            11.118,
            -22.51,
            20.152,
            11.118,
            -22.51,
            20.152,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4191",
        "polygon": [
            -21.675,
            19.838,
            10.698,
            -21.296,
            19.696,
            10.698,
            -21.296,
            19.696,
            11.118,
            -21.296,
            19.696,
            11.118,
            -21.675,
            19.838,
            11.118,
            -21.675,
            19.838,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4192",
        "polygon": [
            -21.675,
            19.838,
            7.85,
            -21.296,
            19.696,
            7.85,
            -21.296,
            19.696,
            10.698,
            -21.296,
            19.696,
            10.698,
            -21.675,
            19.838,
            10.698,
            -21.675,
            19.838,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4193",
        "polygon": [
            -21.2,
            19.66,
            0.142,
            -20.22,
            19.292,
            0.142,
            -20.22,
            19.292,
            3.698,
            -20.22,
            19.292,
            3.698,
            -21.2,
            19.66,
            3.698,
            -21.2,
            19.66,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4194",
        "polygon": [
            -36.925,
            25.572,
            0.142,
            -35.712,
            25.116,
            0.142,
            -35.712,
            25.116,
            3.698,
            -35.712,
            25.116,
            3.698,
            -36.925,
            25.572,
            3.698,
            -36.925,
            25.572,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4195",
        "polygon": [
            -38.235,
            26.064,
            0.142,
            -37.441,
            25.766,
            0.142,
            -37.441,
            25.766,
            3.698,
            -37.441,
            25.766,
            3.698,
            -38.235,
            26.064,
            3.698,
            -38.235,
            26.064,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4196",
        "polygon": [
            -37.4,
            25.751,
            2.928,
            -37.021,
            25.608,
            2.928,
            -37.021,
            25.608,
            3.698,
            -37.021,
            25.608,
            3.698,
            -37.4,
            25.751,
            3.698,
            -37.4,
            25.751,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4197",
        "polygon": [
            -37.4,
            25.751,
            0.08,
            -37.021,
            25.608,
            0.08,
            -37.021,
            25.608,
            2.928,
            -37.021,
            25.608,
            2.928,
            -37.4,
            25.751,
            2.928,
            -37.4,
            25.751,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4198",
        "polygon": [
            -39.312,
            26.469,
            0.142,
            -38.333,
            26.101,
            0.142,
            -38.333,
            26.101,
            3.698,
            -38.333,
            26.101,
            3.698,
            -39.312,
            26.469,
            3.698,
            -39.312,
            26.469,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4199",
        "polygon": [
            -34.305,
            24.587,
            0.142,
            -33.092,
            24.131,
            0.142,
            -33.092,
            24.131,
            3.698,
            -33.092,
            24.131,
            3.698,
            -34.305,
            24.587,
            3.698,
            -34.305,
            24.587,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4200",
        "polygon": [
            -35.616,
            25.079,
            0.142,
            -34.821,
            24.781,
            0.142,
            -34.821,
            24.781,
            3.698,
            -34.821,
            24.781,
            3.698,
            -35.616,
            25.079,
            3.698,
            -35.616,
            25.079,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4201",
        "polygon": [
            -34.78,
            24.766,
            2.928,
            -34.401,
            24.623,
            2.928,
            -34.401,
            24.623,
            3.698,
            -34.401,
            24.623,
            3.698,
            -34.78,
            24.766,
            3.698,
            -34.78,
            24.766,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4202",
        "polygon": [
            -34.78,
            24.766,
            0.08,
            -34.401,
            24.623,
            0.08,
            -34.401,
            24.623,
            2.928,
            -34.401,
            24.623,
            2.928,
            -34.78,
            24.766,
            2.928,
            -34.78,
            24.766,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4203",
        "polygon": [
            -31.684,
            23.601,
            0.142,
            -30.471,
            23.145,
            0.142,
            -30.471,
            23.145,
            3.698,
            -30.471,
            23.145,
            3.698,
            -31.684,
            23.601,
            3.698,
            -31.684,
            23.601,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4204",
        "polygon": [
            -32.995,
            24.094,
            0.142,
            -32.2,
            23.795,
            0.142,
            -32.2,
            23.795,
            3.698,
            -32.2,
            23.795,
            3.698,
            -32.995,
            24.094,
            3.698,
            -32.995,
            24.094,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4205",
        "polygon": [
            -32.16,
            23.78,
            2.928,
            -31.781,
            23.638,
            2.928,
            -31.781,
            23.638,
            3.698,
            -31.781,
            23.638,
            3.698,
            -32.16,
            23.78,
            3.698,
            -32.16,
            23.78,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4206",
        "polygon": [
            -32.16,
            23.78,
            0.08,
            -31.781,
            23.638,
            0.08,
            -31.781,
            23.638,
            2.928,
            -31.781,
            23.638,
            2.928,
            -32.16,
            23.78,
            2.928,
            -32.16,
            23.78,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4207",
        "polygon": [
            -29.063,
            22.616,
            0.142,
            -27.85,
            22.16,
            0.142,
            -27.85,
            22.16,
            3.698,
            -27.85,
            22.16,
            3.698,
            -29.063,
            22.616,
            3.698,
            -29.063,
            22.616,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4208",
        "polygon": [
            -30.374,
            23.109,
            0.142,
            -29.579,
            22.81,
            0.142,
            -29.579,
            22.81,
            3.698,
            -29.579,
            22.81,
            3.698,
            -30.374,
            23.109,
            3.698,
            -30.374,
            23.109,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4209",
        "polygon": [
            -29.539,
            22.795,
            2.928,
            -29.16,
            22.652,
            2.928,
            -29.16,
            22.652,
            3.698,
            -29.16,
            22.652,
            3.698,
            -29.539,
            22.795,
            3.698,
            -29.539,
            22.795,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4210",
        "polygon": [
            -29.539,
            22.795,
            0.08,
            -29.16,
            22.652,
            0.08,
            -29.16,
            22.652,
            2.928,
            -29.16,
            22.652,
            2.928,
            -29.539,
            22.795,
            2.928,
            -29.539,
            22.795,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4211",
        "polygon": [
            -26.442,
            21.631,
            0.142,
            -25.229,
            21.175,
            0.142,
            -25.229,
            21.175,
            3.698,
            -25.229,
            21.175,
            3.698,
            -26.442,
            21.631,
            3.698,
            -26.442,
            21.631,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4212",
        "polygon": [
            -27.753,
            22.123,
            0.142,
            -26.958,
            21.825,
            0.142,
            -26.958,
            21.825,
            3.698,
            -26.958,
            21.825,
            3.698,
            -27.753,
            22.123,
            3.698,
            -27.753,
            22.123,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4213",
        "polygon": [
            -26.918,
            21.81,
            2.928,
            -26.539,
            21.667,
            2.928,
            -26.539,
            21.667,
            3.698,
            -26.539,
            21.667,
            3.698,
            -26.918,
            21.81,
            3.698,
            -26.918,
            21.81,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4214",
        "polygon": [
            -26.918,
            21.81,
            0.08,
            -26.539,
            21.667,
            0.08,
            -26.539,
            21.667,
            2.928,
            -26.539,
            21.667,
            2.928,
            -26.918,
            21.81,
            2.928,
            -26.918,
            21.81,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4215",
        "polygon": [
            -23.822,
            20.645,
            0.142,
            -22.608,
            20.189,
            0.142,
            -22.608,
            20.189,
            3.698,
            -22.608,
            20.189,
            3.698,
            -23.822,
            20.645,
            3.698,
            -23.822,
            20.645,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4216",
        "polygon": [
            -25.132,
            21.138,
            0.142,
            -24.337,
            20.839,
            0.142,
            -24.337,
            20.839,
            3.698,
            -24.337,
            20.839,
            3.698,
            -25.132,
            21.138,
            3.698,
            -25.132,
            21.138,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4217",
        "polygon": [
            -24.297,
            20.824,
            2.928,
            -23.918,
            20.682,
            2.928,
            -23.918,
            20.682,
            3.698,
            -23.918,
            20.682,
            3.698,
            -24.297,
            20.824,
            3.698,
            -24.297,
            20.824,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4218",
        "polygon": [
            -24.297,
            20.824,
            0.08,
            -23.918,
            20.682,
            0.08,
            -23.918,
            20.682,
            2.928,
            -23.918,
            20.682,
            2.928,
            -24.297,
            20.824,
            2.928,
            -24.297,
            20.824,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4219",
        "polygon": [
            -22.51,
            20.152,
            0.142,
            -21.715,
            19.854,
            0.142,
            -21.715,
            19.854,
            3.698,
            -21.715,
            19.854,
            3.698,
            -22.51,
            20.152,
            3.698,
            -22.51,
            20.152,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4220",
        "polygon": [
            -21.675,
            19.838,
            2.928,
            -21.296,
            19.696,
            2.928,
            -21.296,
            19.696,
            3.698,
            -21.296,
            19.696,
            3.698,
            -21.675,
            19.838,
            3.698,
            -21.675,
            19.838,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4221",
        "polygon": [
            -21.675,
            19.838,
            0.08,
            -21.296,
            19.696,
            0.08,
            -21.296,
            19.696,
            2.928,
            -21.296,
            19.696,
            2.928,
            -21.675,
            19.838,
            2.928,
            -21.675,
            19.838,
            0.08
        ]
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_1",
        "polygon": [
            -10.427,
            0.522,
            0.142,
            -10.948,
            -0.862,
            0.142,
            -10.948,
            -0.862,
            3.698,
            -10.948,
            -0.862,
            3.698,
            -10.427,
            0.522,
            3.698,
            -10.427,
            0.522,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_5",
        "polygon": [
            -8.457,
            5.763,
            0.142,
            -8.913,
            4.55,
            0.142,
            -8.913,
            4.55,
            3.698,
            -8.913,
            4.55,
            3.698,
            -8.457,
            5.763,
            3.698,
            -8.457,
            5.763,
            0.142
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_3",
        "polygon": [
            -9.442,
            3.143,
            0.142,
            -9.898,
            1.93,
            0.142,
            -9.898,
            1.93,
            3.698,
            -9.898,
            1.93,
            3.698,
            -9.442,
            3.143,
            3.698,
            -9.442,
            3.143,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_9",
        "polygon": [
            -6.486,
            11.005,
            0.142,
            -6.942,
            9.792,
            0.142,
            -6.942,
            9.792,
            3.698,
            -6.942,
            9.792,
            3.698,
            -6.486,
            11.005,
            3.698,
            -6.486,
            11.005,
            0.142
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_7",
        "polygon": [
            -7.471,
            8.385,
            0.142,
            -7.927,
            7.172,
            0.142,
            -7.927,
            7.172,
            3.698,
            -7.927,
            7.172,
            3.698,
            -7.471,
            8.385,
            3.698,
            -7.471,
            8.385,
            0.142
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_13",
        "polygon": [
            -4.515,
            16.247,
            0.142,
            -4.971,
            15.034,
            0.142,
            -4.971,
            15.034,
            3.698,
            -4.971,
            15.034,
            3.698,
            -4.515,
            16.247,
            3.698,
            -4.515,
            16.247,
            0.142
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_11",
        "polygon": [
            -5.5,
            13.627,
            0.142,
            -5.956,
            12.414,
            0.142,
            -5.956,
            12.414,
            3.698,
            -5.956,
            12.414,
            3.698,
            -5.5,
            13.627,
            3.698,
            -5.5,
            13.627,
            0.142
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_15",
        "polygon": [
            -3.565,
            18.775,
            2.928,
            -3.986,
            17.656,
            2.928,
            -3.986,
            17.656,
            3.698,
            -3.986,
            17.656,
            3.698,
            -3.565,
            18.775,
            3.698,
            -3.565,
            18.775,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4252",
        "polygon": [
            -2.544,
            21.49,
            0.053,
            -3.0,
            20.277,
            0.053,
            -3.0,
            20.277,
            3.609,
            -3.0,
            20.277,
            3.609,
            -2.544,
            21.49,
            3.609,
            -2.544,
            21.49,
            0.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4253",
        "polygon": [
            -3.037,
            20.179,
            0.053,
            -3.449,
            19.083,
            0.053,
            -3.449,
            19.083,
            3.609,
            -3.449,
            19.083,
            3.609,
            -3.037,
            20.179,
            3.609,
            -3.037,
            20.179,
            0.053
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4254",
        "polygon": [
            -0.888,
            25.896,
            2.928,
            -1.03,
            25.517,
            2.928,
            -1.03,
            25.517,
            3.698,
            -1.03,
            25.517,
            3.698,
            -0.888,
            25.896,
            3.698,
            -0.888,
            25.896,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4255",
        "polygon": [
            -0.888,
            25.896,
            0.08,
            -1.03,
            25.517,
            0.08,
            -1.03,
            25.517,
            2.928,
            -1.03,
            25.517,
            2.928,
            -0.888,
            25.896,
            2.928,
            -0.888,
            25.896,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4256",
        "polygon": [
            -0.574,
            26.73,
            0.142,
            -0.873,
            25.936,
            0.142,
            -0.873,
            25.936,
            3.698,
            -0.873,
            25.936,
            3.698,
            -0.574,
            26.73,
            3.698,
            -0.574,
            26.73,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4257",
        "polygon": [
            -0.081,
            28.042,
            0.142,
            -0.537,
            26.829,
            0.142,
            -0.537,
            26.829,
            3.698,
            -0.537,
            26.829,
            3.698,
            -0.081,
            28.042,
            3.698,
            -0.081,
            28.042,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4258",
        "polygon": [
            0.098,
            28.517,
            2.928,
            -0.045,
            28.138,
            2.928,
            -0.045,
            28.138,
            3.698,
            -0.045,
            28.138,
            3.698,
            0.098,
            28.517,
            3.698,
            0.098,
            28.517,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4259",
        "polygon": [
            0.098,
            28.517,
            0.08,
            -0.045,
            28.138,
            0.08,
            -0.045,
            28.138,
            2.928,
            -0.045,
            28.138,
            2.928,
            0.098,
            28.517,
            2.928,
            0.098,
            28.517,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4260",
        "polygon": [
            0.411,
            29.351,
            0.142,
            0.113,
            28.557,
            0.142,
            0.113,
            28.557,
            3.698,
            0.113,
            28.557,
            3.698,
            0.411,
            29.351,
            3.698,
            0.411,
            29.351,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4261",
        "polygon": [
            0.816,
            30.429,
            0.142,
            0.448,
            29.45,
            0.142,
            0.448,
            29.45,
            3.698,
            0.448,
            29.45,
            3.698,
            0.816,
            30.429,
            3.698,
            0.816,
            30.429,
            0.142
        ]
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_1",
        "polygon": [
            -10.427,
            0.522,
            4.202,
            -10.948,
            -0.862,
            4.202,
            -10.948,
            -0.862,
            7.408,
            -10.948,
            -0.862,
            7.408,
            -10.427,
            0.522,
            7.408,
            -10.427,
            0.522,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_3",
        "polygon": [
            -9.442,
            3.143,
            4.202,
            -9.898,
            1.93,
            4.202,
            -9.898,
            1.93,
            7.408,
            -9.898,
            1.93,
            7.408,
            -9.442,
            3.143,
            7.408,
            -9.442,
            3.143,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4267",
        "polygon": [
            0.816,
            30.429,
            4.202,
            0.448,
            29.45,
            4.202,
            0.448,
            29.45,
            7.408,
            0.448,
            29.45,
            7.408,
            0.816,
            30.429,
            7.408,
            0.816,
            30.429,
            4.202
        ]
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_5",
        "polygon": [
            -8.456,
            5.764,
            4.202,
            -8.912,
            4.551,
            4.202,
            -8.912,
            4.551,
            7.408,
            -8.912,
            4.551,
            7.408,
            -8.456,
            5.764,
            7.408,
            -8.456,
            5.764,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_7",
        "polygon": [
            -7.471,
            8.385,
            4.202,
            -7.927,
            7.172,
            4.202,
            -7.927,
            7.172,
            7.408,
            -7.927,
            7.172,
            7.408,
            -7.471,
            8.385,
            7.408,
            -7.471,
            8.385,
            4.202
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_9",
        "polygon": [
            -6.486,
            11.006,
            4.202,
            -6.942,
            9.793,
            4.202,
            -6.942,
            9.793,
            7.408,
            -6.942,
            9.793,
            7.408,
            -6.486,
            11.006,
            7.408,
            -6.486,
            11.006,
            4.202
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_11",
        "polygon": [
            -5.5,
            13.627,
            4.202,
            -5.956,
            12.414,
            4.202,
            -5.956,
            12.414,
            7.408,
            -5.956,
            12.414,
            7.408,
            -5.5,
            13.627,
            7.408,
            -5.5,
            13.627,
            4.202
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_13",
        "polygon": [
            -4.515,
            16.248,
            4.202,
            -4.971,
            15.035,
            4.202,
            -4.971,
            15.035,
            7.408,
            -4.971,
            15.035,
            7.408,
            -4.515,
            16.248,
            7.408,
            -4.515,
            16.248,
            4.202
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_15",
        "polygon": [
            -3.565,
            18.775,
            4.202,
            -3.986,
            17.656,
            4.202,
            -3.986,
            17.656,
            7.408,
            -3.986,
            17.656,
            7.408,
            -3.565,
            18.775,
            7.408,
            -3.565,
            18.775,
            4.202
        ],
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4292",
        "polygon": [
            -2.052,
            22.799,
            4.202,
            -2.633,
            21.254,
            4.202,
            -2.633,
            21.254,
            7.408,
            -2.633,
            21.254,
            7.408,
            -2.052,
            22.799,
            7.408,
            -2.052,
            22.799,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4293",
        "polygon": [
            -1.559,
            24.111,
            6.988,
            -1.701,
            23.732,
            6.988,
            -1.701,
            23.732,
            7.408,
            -1.701,
            23.732,
            7.408,
            -1.559,
            24.111,
            7.408,
            -1.559,
            24.111,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4294",
        "polygon": [
            -1.559,
            24.111,
            4.14,
            -1.701,
            23.732,
            4.14,
            -1.701,
            23.732,
            6.988,
            -1.701,
            23.732,
            6.988,
            -1.559,
            24.111,
            6.988,
            -1.559,
            24.111,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4295",
        "polygon": [
            -1.717,
            23.691,
            4.202,
            -2.015,
            22.896,
            4.202,
            -2.015,
            22.896,
            7.408,
            -2.015,
            22.896,
            7.408,
            -1.717,
            23.691,
            7.408,
            -1.717,
            23.691,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4296",
        "polygon": [
            -1.066,
            25.421,
            4.202,
            -1.522,
            24.208,
            4.202,
            -1.522,
            24.208,
            7.408,
            -1.522,
            24.208,
            7.408,
            -1.066,
            25.421,
            7.408,
            -1.066,
            25.421,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4297",
        "polygon": [
            -0.574,
            26.732,
            6.988,
            -0.716,
            26.352,
            6.988,
            -0.716,
            26.352,
            7.408,
            -0.716,
            26.352,
            7.408,
            -0.574,
            26.732,
            7.408,
            -0.574,
            26.732,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4298",
        "polygon": [
            -0.574,
            26.732,
            4.14,
            -0.716,
            26.352,
            4.14,
            -0.716,
            26.352,
            6.988,
            -0.716,
            26.352,
            6.988,
            -0.574,
            26.732,
            6.988,
            -0.574,
            26.732,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4299",
        "polygon": [
            -0.731,
            26.312,
            4.202,
            -1.03,
            25.517,
            4.202,
            -1.03,
            25.517,
            7.408,
            -1.03,
            25.517,
            7.408,
            -0.731,
            26.312,
            7.408,
            -0.731,
            26.312,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4300",
        "polygon": [
            -0.081,
            28.042,
            4.202,
            -0.537,
            26.829,
            4.202,
            -0.537,
            26.829,
            7.408,
            -0.537,
            26.829,
            7.408,
            -0.081,
            28.042,
            7.408,
            -0.081,
            28.042,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4301",
        "polygon": [
            0.412,
            29.352,
            6.988,
            0.269,
            28.973,
            6.988,
            0.269,
            28.973,
            7.408,
            0.269,
            28.973,
            7.408,
            0.412,
            29.352,
            7.408,
            0.412,
            29.352,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4302",
        "polygon": [
            0.412,
            29.352,
            4.14,
            0.269,
            28.973,
            4.14,
            0.269,
            28.973,
            6.988,
            0.269,
            28.973,
            6.988,
            0.412,
            29.352,
            6.988,
            0.412,
            29.352,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4303",
        "polygon": [
            0.254,
            28.933,
            4.202,
            -0.045,
            28.138,
            4.202,
            -0.045,
            28.138,
            7.408,
            -0.045,
            28.138,
            7.408,
            0.254,
            28.933,
            7.408,
            0.254,
            28.933,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4307",
        "polygon": [
            -2.869,
            20.625,
            4.11,
            -3.278,
            19.539,
            4.11,
            -3.278,
            19.539,
            7.06,
            -3.278,
            19.539,
            7.06,
            -2.869,
            20.625,
            7.06,
            -2.869,
            20.625,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4308",
        "polygon": [
            -2.735,
            20.981,
            4.11,
            -2.871,
            20.621,
            4.11,
            -2.871,
            20.621,
            7.06,
            -2.871,
            20.621,
            7.06,
            -2.735,
            20.981,
            7.06,
            -2.735,
            20.981,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4309",
        "polygon": [
            -3.298,
            19.484,
            4.16,
            -3.46,
            19.053,
            4.16,
            -3.46,
            19.053,
            7.06,
            -3.46,
            19.053,
            7.06,
            -3.298,
            19.484,
            7.06,
            -3.298,
            19.484,
            4.16
        ]
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_1",
        "polygon": [
            -10.427,
            0.522,
            11.622,
            -10.948,
            -0.862,
            11.622,
            -10.948,
            -0.862,
            14.828,
            -10.948,
            -0.862,
            14.828,
            -10.427,
            0.522,
            14.828,
            -10.427,
            0.522,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_3",
        "polygon": [
            -9.442,
            3.143,
            11.622,
            -9.898,
            1.93,
            11.622,
            -9.898,
            1.93,
            14.828,
            -9.898,
            1.93,
            14.828,
            -9.442,
            3.143,
            14.828,
            -9.442,
            3.143,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_5",
        "polygon": [
            -8.456,
            5.764,
            11.622,
            -8.912,
            4.551,
            11.622,
            -8.912,
            4.551,
            14.828,
            -8.912,
            4.551,
            14.828,
            -8.456,
            5.764,
            14.828,
            -8.456,
            5.764,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_7",
        "polygon": [
            -7.471,
            8.385,
            11.622,
            -7.927,
            7.172,
            11.622,
            -7.927,
            7.172,
            14.828,
            -7.927,
            7.172,
            14.828,
            -7.471,
            8.385,
            14.828,
            -7.471,
            8.385,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_9",
        "polygon": [
            -6.486,
            11.006,
            11.622,
            -6.942,
            9.793,
            11.622,
            -6.942,
            9.793,
            14.828,
            -6.942,
            9.793,
            14.828,
            -6.486,
            11.006,
            14.828,
            -6.486,
            11.006,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_11",
        "polygon": [
            -5.5,
            13.627,
            11.622,
            -5.956,
            12.414,
            11.622,
            -5.956,
            12.414,
            14.828,
            -5.956,
            12.414,
            14.828,
            -5.5,
            13.627,
            14.828,
            -5.5,
            13.627,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_13",
        "polygon": [
            -4.515,
            16.248,
            11.622,
            -4.971,
            15.035,
            11.622,
            -4.971,
            15.035,
            14.828,
            -4.971,
            15.035,
            14.828,
            -4.515,
            16.248,
            14.828,
            -4.515,
            16.248,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_15",
        "polygon": [
            -3.565,
            18.775,
            11.622,
            -3.986,
            17.656,
            11.622,
            -3.986,
            17.656,
            14.828,
            -3.986,
            17.656,
            14.828,
            -3.565,
            18.775,
            14.828,
            -3.565,
            18.775,
            11.622
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_12",
        "polygon": [
            2.382,
            34.594,
            11.622,
            1.926,
            33.381,
            11.622,
            1.926,
            33.381,
            14.828,
            1.926,
            33.381,
            14.828,
            2.382,
            34.594,
            14.828,
            2.382,
            34.594,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_14",
        "polygon": [
            3.368,
            37.215,
            11.622,
            2.912,
            36.002,
            11.622,
            2.912,
            36.002,
            14.828,
            2.912,
            36.002,
            14.828,
            3.368,
            37.215,
            14.828,
            3.368,
            37.215,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_16",
        "polygon": [
            4.417,
            40.006,
            11.622,
            3.897,
            38.623,
            11.622,
            3.897,
            38.623,
            14.828,
            3.897,
            38.623,
            14.828,
            4.417,
            40.006,
            14.828,
            4.417,
            40.006,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_1",
        "polygon": [
            -10.427,
            0.522,
            7.912,
            -10.948,
            -0.862,
            7.912,
            -10.948,
            -0.862,
            11.118,
            -10.948,
            -0.862,
            11.118,
            -10.427,
            0.522,
            11.118,
            -10.427,
            0.522,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_3",
        "polygon": [
            -9.442,
            3.143,
            7.912,
            -9.898,
            1.93,
            7.912,
            -9.898,
            1.93,
            11.118,
            -9.898,
            1.93,
            11.118,
            -9.442,
            3.143,
            11.118,
            -9.442,
            3.143,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4375",
        "polygon": [
            0.816,
            30.429,
            7.912,
            0.448,
            29.45,
            7.912,
            0.448,
            29.45,
            11.118,
            0.448,
            29.45,
            11.118,
            0.816,
            30.429,
            11.118,
            0.816,
            30.429,
            7.912
        ]
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_5",
        "polygon": [
            -8.456,
            5.764,
            7.912,
            -8.912,
            4.551,
            7.912,
            -8.912,
            4.551,
            11.118,
            -8.912,
            4.551,
            11.118,
            -8.456,
            5.764,
            11.118,
            -8.456,
            5.764,
            7.912
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_7",
        "polygon": [
            -7.471,
            8.385,
            7.912,
            -7.927,
            7.172,
            7.912,
            -7.927,
            7.172,
            11.118,
            -7.927,
            7.172,
            11.118,
            -7.471,
            8.385,
            11.118,
            -7.471,
            8.385,
            7.912
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_9",
        "polygon": [
            -6.486,
            11.006,
            7.912,
            -6.942,
            9.793,
            7.912,
            -6.942,
            9.793,
            11.118,
            -6.942,
            9.793,
            11.118,
            -6.486,
            11.006,
            11.118,
            -6.486,
            11.006,
            7.912
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_11",
        "polygon": [
            -5.5,
            13.627,
            7.912,
            -5.956,
            12.414,
            7.912,
            -5.956,
            12.414,
            11.118,
            -5.956,
            12.414,
            11.118,
            -5.5,
            13.627,
            11.118,
            -5.5,
            13.627,
            7.912
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_13",
        "polygon": [
            -4.515,
            16.248,
            7.912,
            -4.971,
            15.035,
            7.912,
            -4.971,
            15.035,
            11.118,
            -4.971,
            15.035,
            11.118,
            -4.515,
            16.248,
            11.118,
            -4.515,
            16.248,
            7.912
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_15",
        "polygon": [
            -3.565,
            18.775,
            7.912,
            -3.986,
            17.656,
            7.912,
            -3.986,
            17.656,
            11.118,
            -3.986,
            17.656,
            11.118,
            -3.565,
            18.775,
            11.118,
            -3.565,
            18.775,
            7.912
        ],
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4382",
        "polygon": [
            -2.052,
            22.799,
            7.912,
            -2.633,
            21.254,
            7.912,
            -2.633,
            21.254,
            11.118,
            -2.633,
            21.254,
            11.118,
            -2.052,
            22.799,
            11.118,
            -2.052,
            22.799,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4383",
        "polygon": [
            -1.066,
            25.421,
            7.912,
            -1.522,
            24.208,
            7.912,
            -1.522,
            24.208,
            11.118,
            -1.522,
            24.208,
            11.118,
            -1.066,
            25.421,
            11.118,
            -1.066,
            25.421,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4384",
        "polygon": [
            -0.081,
            28.042,
            7.912,
            -0.537,
            26.829,
            7.912,
            -0.537,
            26.829,
            11.118,
            -0.537,
            26.829,
            11.118,
            -0.081,
            28.042,
            11.118,
            -0.081,
            28.042,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4385",
        "polygon": [
            -2.869,
            20.625,
            7.82,
            -3.278,
            19.539,
            7.82,
            -3.278,
            19.539,
            10.77,
            -3.278,
            19.539,
            10.77,
            -2.869,
            20.625,
            10.77,
            -2.869,
            20.625,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4386",
        "polygon": [
            -2.735,
            20.981,
            7.82,
            -2.871,
            20.621,
            7.82,
            -2.871,
            20.621,
            10.77,
            -2.871,
            20.621,
            10.77,
            -2.735,
            20.981,
            10.77,
            -2.735,
            20.981,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4387",
        "polygon": [
            -3.298,
            19.484,
            7.87,
            -3.46,
            19.053,
            7.87,
            -3.46,
            19.053,
            10.77,
            -3.46,
            19.053,
            10.77,
            -3.298,
            19.484,
            10.77,
            -3.298,
            19.484,
            7.87
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4406",
        "polygon": [
            -1.872,
            23.277,
            10.698,
            -2.015,
            22.898,
            10.698,
            -2.015,
            22.898,
            11.118,
            -2.015,
            22.898,
            11.118,
            -1.872,
            23.277,
            11.118,
            -1.872,
            23.277,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4407",
        "polygon": [
            -1.872,
            23.277,
            7.85,
            -2.015,
            22.898,
            7.85,
            -2.015,
            22.898,
            10.698,
            -2.015,
            22.898,
            10.698,
            -1.872,
            23.277,
            10.698,
            -1.872,
            23.277,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4408",
        "polygon": [
            -1.559,
            24.112,
            7.912,
            -1.857,
            23.317,
            7.912,
            -1.857,
            23.317,
            11.118,
            -1.857,
            23.317,
            11.118,
            -1.559,
            24.112,
            11.118,
            -1.559,
            24.112,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4409",
        "polygon": [
            -0.887,
            25.898,
            10.698,
            -1.03,
            25.518,
            10.698,
            -1.03,
            25.518,
            11.118,
            -1.03,
            25.518,
            11.118,
            -0.887,
            25.898,
            11.118,
            -0.887,
            25.898,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4410",
        "polygon": [
            -0.887,
            25.898,
            7.85,
            -1.03,
            25.518,
            7.85,
            -1.03,
            25.518,
            10.698,
            -1.03,
            25.518,
            10.698,
            -0.887,
            25.898,
            10.698,
            -0.887,
            25.898,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4411",
        "polygon": [
            -0.573,
            26.733,
            7.912,
            -0.872,
            25.938,
            7.912,
            -0.872,
            25.938,
            11.118,
            -0.872,
            25.938,
            11.118,
            -0.573,
            26.733,
            11.118,
            -0.573,
            26.733,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4412",
        "polygon": [
            0.098,
            28.518,
            10.698,
            -0.044,
            28.139,
            10.698,
            -0.044,
            28.139,
            11.118,
            -0.044,
            28.139,
            11.118,
            0.098,
            28.518,
            11.118,
            0.098,
            28.518,
            10.698
        ],
        "05-24 05:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4413",
        "polygon": [
            0.098,
            28.518,
            7.85,
            -0.044,
            28.139,
            7.85,
            -0.044,
            28.139,
            10.698,
            -0.044,
            28.139,
            10.698,
            0.098,
            28.518,
            10.698,
            0.098,
            28.518,
            7.85
        ],
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4414",
        "polygon": [
            0.412,
            29.353,
            7.912,
            0.113,
            28.559,
            7.912,
            0.113,
            28.559,
            11.118,
            0.113,
            28.559,
            11.118,
            0.412,
            29.353,
            11.118,
            0.412,
            29.353,
            7.912
        ]
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_1",
        "polygon": [
            -3.669,
            20.417,
            11.622,
            -4.125,
            19.204,
            11.622,
            -4.125,
            19.204,
            14.828,
            -4.125,
            19.204,
            14.828,
            -3.669,
            20.417,
            14.828,
            -3.669,
            20.417,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_2",
        "polygon": [
            -3.176,
            21.726,
            11.622,
            -3.633,
            20.513,
            11.622,
            -3.633,
            20.513,
            14.828,
            -3.633,
            20.513,
            14.828,
            -3.176,
            21.726,
            14.828,
            -3.176,
            21.726,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_3",
        "polygon": [
            -2.684,
            23.037,
            11.622,
            -3.14,
            21.824,
            11.622,
            -3.14,
            21.824,
            14.828,
            -3.14,
            21.824,
            14.828,
            -2.684,
            23.037,
            14.828,
            -2.684,
            23.037,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_4",
        "polygon": [
            -2.191,
            24.347,
            11.622,
            -2.647,
            23.134,
            11.622,
            -2.647,
            23.134,
            14.828,
            -2.647,
            23.134,
            14.828,
            -2.191,
            24.347,
            14.828,
            -2.191,
            24.347,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_5",
        "polygon": [
            -1.698,
            25.658,
            11.622,
            -2.155,
            24.444,
            11.622,
            -2.155,
            24.444,
            14.828,
            -2.155,
            24.444,
            14.828,
            -1.698,
            25.658,
            14.828,
            -1.698,
            25.658,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_6",
        "polygon": [
            -1.206,
            26.968,
            11.622,
            -1.662,
            25.755,
            11.622,
            -1.662,
            25.755,
            14.828,
            -1.662,
            25.755,
            14.828,
            -1.206,
            26.968,
            14.828,
            -1.206,
            26.968,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_7",
        "polygon": [
            -0.713,
            28.278,
            11.622,
            -1.169,
            27.065,
            11.622,
            -1.169,
            27.065,
            14.828,
            -1.169,
            27.065,
            14.828,
            -0.713,
            28.278,
            14.828,
            -0.713,
            28.278,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_8",
        "polygon": [
            -0.22,
            29.589,
            11.622,
            -0.676,
            28.376,
            11.622,
            -0.676,
            28.376,
            14.828,
            -0.676,
            28.376,
            14.828,
            -0.22,
            29.589,
            14.828,
            -0.22,
            29.589,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_9",
        "polygon": [
            0.272,
            30.899,
            11.622,
            -0.184,
            29.686,
            11.622,
            -0.184,
            29.686,
            14.828,
            -0.184,
            29.686,
            14.828,
            0.272,
            30.899,
            14.828,
            0.272,
            30.899,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4424",
        "polygon": [
            -3.325,
            18.903,
            12.714,
            -2.868,
            20.116,
            12.714,
            -2.868,
            20.116,
            13.044,
            -2.868,
            20.116,
            13.044,
            -3.325,
            18.903,
            13.044,
            -3.325,
            18.903,
            12.714
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4425",
        "polygon": [
            -3.325,
            18.903,
            12.384,
            -2.868,
            20.116,
            12.384,
            -2.868,
            20.116,
            12.714,
            -2.868,
            20.116,
            12.714,
            -3.325,
            18.903,
            12.714,
            -3.325,
            18.903,
            12.384
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4426",
        "polygon": [
            -3.325,
            18.903,
            12.054,
            -2.868,
            20.116,
            12.054,
            -2.868,
            20.116,
            12.384,
            -2.868,
            20.116,
            12.384,
            -3.325,
            18.903,
            12.384,
            -3.325,
            18.903,
            12.054
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4427",
        "polygon": [
            -2.832,
            20.213,
            12.714,
            -2.376,
            21.426,
            12.714,
            -2.376,
            21.426,
            13.044,
            -2.376,
            21.426,
            13.044,
            -2.832,
            20.213,
            13.044,
            -2.832,
            20.213,
            12.714
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4428",
        "polygon": [
            -2.832,
            20.213,
            12.384,
            -2.376,
            21.426,
            12.384,
            -2.376,
            21.426,
            12.714,
            -2.376,
            21.426,
            12.714,
            -2.832,
            20.213,
            12.714,
            -2.832,
            20.213,
            12.384
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4429",
        "polygon": [
            -2.832,
            20.213,
            12.054,
            -2.376,
            21.426,
            12.054,
            -2.376,
            21.426,
            12.384,
            -2.376,
            21.426,
            12.384,
            -2.832,
            20.213,
            12.384,
            -2.832,
            20.213,
            12.054
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4430",
        "polygon": [
            -2.339,
            21.524,
            12.714,
            -1.883,
            22.737,
            12.714,
            -1.883,
            22.737,
            13.044,
            -1.883,
            22.737,
            13.044,
            -2.339,
            21.524,
            13.044,
            -2.339,
            21.524,
            12.714
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4431",
        "polygon": [
            -2.339,
            21.524,
            12.384,
            -1.883,
            22.737,
            12.384,
            -1.883,
            22.737,
            12.714,
            -1.883,
            22.737,
            12.714,
            -2.339,
            21.524,
            12.714,
            -2.339,
            21.524,
            12.384
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4432",
        "polygon": [
            -2.339,
            21.524,
            12.054,
            -1.883,
            22.737,
            12.054,
            -1.883,
            22.737,
            12.384,
            -1.883,
            22.737,
            12.384,
            -2.339,
            21.524,
            12.384,
            -2.339,
            21.524,
            12.054
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4433",
        "polygon": [
            -1.846,
            22.834,
            12.714,
            -1.39,
            24.047,
            12.714,
            -1.39,
            24.047,
            13.044,
            -1.39,
            24.047,
            13.044,
            -1.846,
            22.834,
            13.044,
            -1.846,
            22.834,
            12.714
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4434",
        "polygon": [
            -1.846,
            22.834,
            12.384,
            -1.39,
            24.047,
            12.384,
            -1.39,
            24.047,
            12.714,
            -1.39,
            24.047,
            12.714,
            -1.846,
            22.834,
            12.714,
            -1.846,
            22.834,
            12.384
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4435",
        "polygon": [
            -1.846,
            22.834,
            12.054,
            -1.39,
            24.047,
            12.054,
            -1.39,
            24.047,
            12.384,
            -1.39,
            24.047,
            12.384,
            -1.846,
            22.834,
            12.384,
            -1.846,
            22.834,
            12.054
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4436",
        "polygon": [
            -1.354,
            24.145,
            12.714,
            -0.898,
            25.358,
            12.714,
            -0.898,
            25.358,
            13.044,
            -0.898,
            25.358,
            13.044,
            -1.354,
            24.145,
            13.044,
            -1.354,
            24.145,
            12.714
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4437",
        "polygon": [
            -1.354,
            24.145,
            12.384,
            -0.898,
            25.358,
            12.384,
            -0.898,
            25.358,
            12.714,
            -0.898,
            25.358,
            12.714,
            -1.354,
            24.145,
            12.714,
            -1.354,
            24.145,
            12.384
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4438",
        "polygon": [
            -1.354,
            24.145,
            12.054,
            -0.898,
            25.358,
            12.054,
            -0.898,
            25.358,
            12.384,
            -0.898,
            25.358,
            12.384,
            -1.354,
            24.145,
            12.384,
            -1.354,
            24.145,
            12.054
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4439",
        "polygon": [
            -0.861,
            25.455,
            12.714,
            -0.405,
            26.668,
            12.714,
            -0.405,
            26.668,
            13.044,
            -0.405,
            26.668,
            13.044,
            -0.861,
            25.455,
            13.044,
            -0.861,
            25.455,
            12.714
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4440",
        "polygon": [
            -0.861,
            25.455,
            12.384,
            -0.405,
            26.668,
            12.384,
            -0.405,
            26.668,
            12.714,
            -0.405,
            26.668,
            12.714,
            -0.861,
            25.455,
            12.714,
            -0.861,
            25.455,
            12.384
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4441",
        "polygon": [
            -0.861,
            25.455,
            12.054,
            -0.405,
            26.668,
            12.054,
            -0.405,
            26.668,
            12.384,
            -0.405,
            26.668,
            12.384,
            -0.861,
            25.455,
            12.384,
            -0.861,
            25.455,
            12.054
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4442",
        "polygon": [
            -0.368,
            26.766,
            12.714,
            0.088,
            27.979,
            12.714,
            0.088,
            27.979,
            13.044,
            0.088,
            27.979,
            13.044,
            -0.368,
            26.766,
            13.044,
            -0.368,
            26.766,
            12.714
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4443",
        "polygon": [
            -0.368,
            26.766,
            12.384,
            0.088,
            27.979,
            12.384,
            0.088,
            27.979,
            12.714,
            0.088,
            27.979,
            12.714,
            -0.368,
            26.766,
            12.714,
            -0.368,
            26.766,
            12.384
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4444",
        "polygon": [
            -0.368,
            26.766,
            12.054,
            0.088,
            27.979,
            12.054,
            0.088,
            27.979,
            12.384,
            0.088,
            27.979,
            12.384,
            -0.368,
            26.766,
            12.384,
            -0.368,
            26.766,
            12.054
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4445",
        "polygon": [
            0.124,
            28.076,
            12.714,
            0.58,
            29.289,
            12.714,
            0.58,
            29.289,
            13.044,
            0.58,
            29.289,
            13.044,
            0.124,
            28.076,
            13.044,
            0.124,
            28.076,
            12.714
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4446",
        "polygon": [
            0.124,
            28.076,
            12.384,
            0.58,
            29.289,
            12.384,
            0.58,
            29.289,
            12.714,
            0.58,
            29.289,
            12.714,
            0.124,
            28.076,
            12.714,
            0.124,
            28.076,
            12.384
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4447",
        "polygon": [
            0.124,
            28.076,
            12.054,
            0.58,
            29.289,
            12.054,
            0.58,
            29.289,
            12.384,
            0.58,
            29.289,
            12.384,
            0.124,
            28.076,
            12.384,
            0.124,
            28.076,
            12.054
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4448",
        "polygon": [
            0.617,
            29.386,
            12.714,
            1.073,
            30.6,
            12.714,
            1.073,
            30.6,
            13.044,
            1.073,
            30.6,
            13.044,
            0.617,
            29.386,
            13.044,
            0.617,
            29.386,
            12.714
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4449",
        "polygon": [
            0.617,
            29.386,
            12.384,
            1.073,
            30.6,
            12.384,
            1.073,
            30.6,
            12.714,
            1.073,
            30.6,
            12.714,
            0.617,
            29.386,
            12.714,
            0.617,
            29.386,
            12.384
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4450",
        "polygon": [
            0.617,
            29.386,
            12.054,
            1.073,
            30.6,
            12.054,
            1.073,
            30.6,
            12.384,
            1.073,
            30.6,
            12.384,
            0.617,
            29.386,
            12.384,
            0.617,
            29.386,
            12.054
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4451",
        "polygon": [
            2.094,
            30.196,
            0.142,
            1.115,
            30.564,
            0.142,
            1.115,
            30.564,
            3.698,
            1.115,
            30.564,
            3.698,
            2.094,
            30.196,
            3.698,
            2.094,
            30.196,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4452",
        "polygon": [
            3.404,
            29.704,
            0.142,
            2.609,
            30.003,
            0.142,
            2.609,
            30.003,
            3.698,
            2.609,
            30.003,
            3.698,
            3.404,
            29.704,
            3.698,
            3.404,
            29.704,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4453",
        "polygon": [
            2.571,
            30.017,
            2.928,
            2.192,
            30.16,
            2.928,
            2.192,
            30.16,
            3.698,
            2.192,
            30.16,
            3.698,
            2.571,
            30.017,
            3.698,
            2.571,
            30.017,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4454",
        "polygon": [
            2.571,
            30.017,
            0.08,
            2.192,
            30.16,
            0.08,
            2.192,
            30.16,
            2.928,
            2.192,
            30.16,
            2.928,
            2.571,
            30.017,
            2.928,
            2.571,
            30.017,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4455",
        "polygon": [
            4.715,
            29.211,
            0.142,
            3.502,
            29.667,
            0.142,
            3.502,
            29.667,
            3.698,
            3.502,
            29.667,
            3.698,
            4.715,
            29.211,
            3.698,
            4.715,
            29.211,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4456",
        "polygon": [
            6.024,
            28.719,
            0.142,
            5.229,
            29.018,
            0.142,
            5.229,
            29.018,
            3.698,
            5.229,
            29.018,
            3.698,
            6.024,
            28.719,
            3.698,
            6.024,
            28.719,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4457",
        "polygon": [
            5.191,
            29.032,
            2.928,
            4.812,
            29.175,
            2.928,
            4.812,
            29.175,
            3.698,
            4.812,
            29.175,
            3.698,
            5.191,
            29.032,
            3.698,
            5.191,
            29.032,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4458",
        "polygon": [
            5.191,
            29.032,
            0.08,
            4.812,
            29.175,
            0.08,
            4.812,
            29.175,
            2.928,
            4.812,
            29.175,
            2.928,
            5.191,
            29.032,
            2.928,
            5.191,
            29.032,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4459",
        "polygon": [
            7.335,
            28.226,
            0.142,
            6.122,
            28.682,
            0.142,
            6.122,
            28.682,
            3.698,
            6.122,
            28.682,
            3.698,
            7.335,
            28.226,
            3.698,
            7.335,
            28.226,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4460",
        "polygon": [
            8.645,
            27.734,
            0.142,
            7.85,
            28.032,
            0.142,
            7.85,
            28.032,
            3.698,
            7.85,
            28.032,
            3.698,
            8.645,
            27.734,
            3.698,
            8.645,
            27.734,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4461",
        "polygon": [
            7.812,
            28.047,
            2.928,
            7.432,
            28.189,
            2.928,
            7.432,
            28.189,
            3.698,
            7.432,
            28.189,
            3.698,
            7.812,
            28.047,
            3.698,
            7.812,
            28.047,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4462",
        "polygon": [
            7.812,
            28.047,
            0.08,
            7.432,
            28.189,
            0.08,
            7.432,
            28.189,
            2.928,
            7.432,
            28.189,
            2.928,
            7.812,
            28.047,
            2.928,
            7.812,
            28.047,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4463",
        "polygon": [
            9.956,
            27.241,
            0.142,
            8.743,
            27.697,
            0.142,
            8.743,
            27.697,
            3.698,
            8.743,
            27.697,
            3.698,
            9.956,
            27.241,
            3.698,
            9.956,
            27.241,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4464",
        "polygon": [
            11.265,
            26.748,
            0.142,
            10.471,
            27.047,
            0.142,
            10.471,
            27.047,
            3.698,
            10.471,
            27.047,
            3.698,
            11.265,
            26.748,
            3.698,
            11.265,
            26.748,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4465",
        "polygon": [
            10.432,
            27.061,
            2.928,
            10.053,
            27.204,
            2.928,
            10.053,
            27.204,
            3.698,
            10.053,
            27.204,
            3.698,
            10.432,
            27.061,
            3.698,
            10.432,
            27.061,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4466",
        "polygon": [
            10.432,
            27.061,
            0.08,
            10.053,
            27.204,
            0.08,
            10.053,
            27.204,
            2.928,
            10.053,
            27.204,
            2.928,
            10.432,
            27.061,
            2.928,
            10.432,
            27.061,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4467",
        "polygon": [
            12.577,
            26.255,
            0.142,
            11.364,
            26.711,
            0.142,
            11.364,
            26.711,
            3.698,
            11.364,
            26.711,
            3.698,
            12.577,
            26.255,
            3.698,
            12.577,
            26.255,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4468",
        "polygon": [
            13.886,
            25.763,
            0.142,
            13.092,
            26.062,
            0.142,
            13.092,
            26.062,
            3.698,
            13.092,
            26.062,
            3.698,
            13.886,
            25.763,
            3.698,
            13.886,
            25.763,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4469",
        "polygon": [
            13.053,
            26.076,
            2.928,
            12.674,
            26.219,
            2.928,
            12.674,
            26.219,
            3.698,
            12.674,
            26.219,
            3.698,
            13.053,
            26.076,
            3.698,
            13.053,
            26.076,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4470",
        "polygon": [
            13.053,
            26.076,
            0.08,
            12.674,
            26.219,
            0.08,
            12.674,
            26.219,
            2.928,
            12.674,
            26.219,
            2.928,
            13.053,
            26.076,
            2.928,
            13.053,
            26.076,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4471",
        "polygon": [
            15.198,
            25.27,
            0.142,
            13.985,
            25.726,
            0.142,
            13.985,
            25.726,
            3.698,
            13.985,
            25.726,
            3.698,
            15.198,
            25.27,
            3.698,
            15.198,
            25.27,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4472",
        "polygon": [
            16.507,
            24.778,
            0.142,
            15.713,
            25.076,
            0.142,
            15.713,
            25.076,
            3.698,
            15.713,
            25.076,
            3.698,
            16.507,
            24.778,
            3.698,
            16.507,
            24.778,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4473",
        "polygon": [
            15.674,
            25.091,
            2.928,
            15.295,
            25.233,
            2.928,
            15.295,
            25.233,
            3.698,
            15.295,
            25.233,
            3.698,
            15.674,
            25.091,
            3.698,
            15.674,
            25.091,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4474",
        "polygon": [
            15.674,
            25.091,
            0.08,
            15.295,
            25.233,
            0.08,
            15.295,
            25.233,
            2.928,
            15.295,
            25.233,
            2.928,
            15.674,
            25.091,
            2.928,
            15.674,
            25.091,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4475",
        "polygon": [
            17.819,
            24.285,
            0.142,
            16.606,
            24.741,
            0.142,
            16.606,
            24.741,
            3.698,
            16.606,
            24.741,
            3.698,
            17.819,
            24.285,
            3.698,
            17.819,
            24.285,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4476",
        "polygon": [
            19.128,
            23.792,
            0.142,
            18.333,
            24.091,
            0.142,
            18.333,
            24.091,
            3.698,
            18.333,
            24.091,
            3.698,
            19.128,
            23.792,
            3.698,
            19.128,
            23.792,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4477",
        "polygon": [
            18.295,
            24.105,
            2.928,
            17.916,
            24.248,
            2.928,
            17.916,
            24.248,
            3.698,
            17.916,
            24.248,
            3.698,
            18.295,
            24.105,
            3.698,
            18.295,
            24.105,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4478",
        "polygon": [
            18.295,
            24.105,
            0.08,
            17.916,
            24.248,
            0.08,
            17.916,
            24.248,
            2.928,
            17.916,
            24.248,
            2.928,
            18.295,
            24.105,
            2.928,
            18.295,
            24.105,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4479",
        "polygon": [
            20.44,
            23.299,
            0.142,
            19.226,
            23.755,
            0.142,
            19.226,
            23.755,
            3.698,
            19.226,
            23.755,
            3.698,
            20.44,
            23.299,
            3.698,
            20.44,
            23.299,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4480",
        "polygon": [
            20.815,
            23.158,
            0.142,
            20.537,
            23.263,
            0.142,
            20.537,
            23.263,
            3.698,
            20.537,
            23.263,
            3.698,
            20.815,
            23.158,
            3.698,
            20.815,
            23.158,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4481",
        "polygon": [
            2.094,
            30.196,
            7.912,
            1.115,
            30.564,
            7.912,
            1.115,
            30.564,
            11.118,
            1.115,
            30.564,
            11.118,
            2.094,
            30.196,
            11.118,
            2.094,
            30.196,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4482",
        "polygon": [
            3.404,
            29.704,
            7.912,
            2.609,
            30.003,
            7.912,
            2.609,
            30.003,
            11.118,
            2.609,
            30.003,
            11.118,
            3.404,
            29.704,
            11.118,
            3.404,
            29.704,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4483",
        "polygon": [
            2.571,
            30.017,
            10.698,
            2.192,
            30.16,
            10.698,
            2.192,
            30.16,
            11.118,
            2.192,
            30.16,
            11.118,
            2.571,
            30.017,
            11.118,
            2.571,
            30.017,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4484",
        "polygon": [
            2.571,
            30.017,
            7.85,
            2.192,
            30.16,
            7.85,
            2.192,
            30.16,
            10.698,
            2.192,
            30.16,
            10.698,
            2.571,
            30.017,
            10.698,
            2.571,
            30.017,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4485",
        "polygon": [
            4.715,
            29.211,
            7.912,
            3.502,
            29.667,
            7.912,
            3.502,
            29.667,
            11.118,
            3.502,
            29.667,
            11.118,
            4.715,
            29.211,
            11.118,
            4.715,
            29.211,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4486",
        "polygon": [
            20.815,
            23.158,
            7.912,
            20.537,
            23.263,
            7.912,
            20.537,
            23.263,
            11.118,
            20.537,
            23.263,
            11.118,
            20.815,
            23.158,
            11.118,
            20.815,
            23.158,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4487",
        "polygon": [
            6.024,
            28.719,
            7.912,
            5.229,
            29.018,
            7.912,
            5.229,
            29.018,
            11.118,
            5.229,
            29.018,
            11.118,
            6.024,
            28.719,
            11.118,
            6.024,
            28.719,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4488",
        "polygon": [
            5.191,
            29.032,
            7.85,
            4.812,
            29.175,
            7.85,
            4.812,
            29.175,
            10.698,
            4.812,
            29.175,
            10.698,
            5.191,
            29.032,
            10.698,
            5.191,
            29.032,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4489",
        "polygon": [
            7.335,
            28.226,
            7.912,
            6.122,
            28.682,
            7.912,
            6.122,
            28.682,
            11.118,
            6.122,
            28.682,
            11.118,
            7.335,
            28.226,
            11.118,
            7.335,
            28.226,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4490",
        "polygon": [
            8.645,
            27.734,
            7.912,
            7.85,
            28.032,
            7.912,
            7.85,
            28.032,
            11.118,
            7.85,
            28.032,
            11.118,
            8.645,
            27.734,
            11.118,
            8.645,
            27.734,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4491",
        "polygon": [
            7.812,
            28.047,
            7.85,
            7.432,
            28.189,
            7.85,
            7.432,
            28.189,
            10.698,
            7.432,
            28.189,
            10.698,
            7.812,
            28.047,
            10.698,
            7.812,
            28.047,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4492",
        "polygon": [
            9.956,
            27.241,
            7.912,
            8.743,
            27.697,
            7.912,
            8.743,
            27.697,
            11.118,
            8.743,
            27.697,
            11.118,
            9.956,
            27.241,
            11.118,
            9.956,
            27.241,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4493",
        "polygon": [
            11.265,
            26.748,
            7.912,
            10.471,
            27.047,
            7.912,
            10.471,
            27.047,
            11.118,
            10.471,
            27.047,
            11.118,
            11.265,
            26.748,
            11.118,
            11.265,
            26.748,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4494",
        "polygon": [
            10.432,
            27.061,
            7.85,
            10.053,
            27.204,
            7.85,
            10.053,
            27.204,
            10.698,
            10.053,
            27.204,
            10.698,
            10.432,
            27.061,
            10.698,
            10.432,
            27.061,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4495",
        "polygon": [
            12.577,
            26.255,
            7.912,
            11.364,
            26.711,
            7.912,
            11.364,
            26.711,
            11.118,
            11.364,
            26.711,
            11.118,
            12.577,
            26.255,
            11.118,
            12.577,
            26.255,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4496",
        "polygon": [
            13.886,
            25.763,
            7.912,
            13.092,
            26.062,
            7.912,
            13.092,
            26.062,
            11.118,
            13.092,
            26.062,
            11.118,
            13.886,
            25.763,
            11.118,
            13.886,
            25.763,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4497",
        "polygon": [
            13.053,
            26.076,
            7.85,
            12.674,
            26.219,
            7.85,
            12.674,
            26.219,
            10.698,
            12.674,
            26.219,
            10.698,
            13.053,
            26.076,
            10.698,
            13.053,
            26.076,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4498",
        "polygon": [
            15.198,
            25.27,
            7.912,
            13.985,
            25.726,
            7.912,
            13.985,
            25.726,
            11.118,
            13.985,
            25.726,
            11.118,
            15.198,
            25.27,
            11.118,
            15.198,
            25.27,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4499",
        "polygon": [
            16.507,
            24.778,
            7.912,
            15.713,
            25.076,
            7.912,
            15.713,
            25.076,
            11.118,
            15.713,
            25.076,
            11.118,
            16.507,
            24.778,
            11.118,
            16.507,
            24.778,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4500",
        "polygon": [
            15.674,
            25.091,
            7.85,
            15.295,
            25.233,
            7.85,
            15.295,
            25.233,
            10.698,
            15.295,
            25.233,
            10.698,
            15.674,
            25.091,
            10.698,
            15.674,
            25.091,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4501",
        "polygon": [
            17.819,
            24.285,
            7.912,
            16.606,
            24.741,
            7.912,
            16.606,
            24.741,
            11.118,
            16.606,
            24.741,
            11.118,
            17.819,
            24.285,
            11.118,
            17.819,
            24.285,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4502",
        "polygon": [
            19.128,
            23.792,
            7.912,
            18.333,
            24.091,
            7.912,
            18.333,
            24.091,
            11.118,
            18.333,
            24.091,
            11.118,
            19.128,
            23.792,
            11.118,
            19.128,
            23.792,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4503",
        "polygon": [
            18.295,
            24.105,
            7.85,
            17.916,
            24.248,
            7.85,
            17.916,
            24.248,
            10.698,
            17.916,
            24.248,
            10.698,
            18.295,
            24.105,
            10.698,
            18.295,
            24.105,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4504",
        "polygon": [
            20.44,
            23.299,
            7.912,
            19.226,
            23.755,
            7.912,
            19.226,
            23.755,
            11.118,
            19.226,
            23.755,
            11.118,
            20.44,
            23.299,
            11.118,
            20.44,
            23.299,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4505",
        "polygon": [
            2.094,
            30.196,
            4.202,
            1.115,
            30.564,
            4.202,
            1.115,
            30.564,
            7.408,
            1.115,
            30.564,
            7.408,
            2.094,
            30.196,
            7.408,
            2.094,
            30.196,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4506",
        "polygon": [
            2.986,
            29.861,
            4.202,
            2.192,
            30.16,
            4.202,
            2.192,
            30.16,
            7.408,
            2.192,
            30.16,
            7.408,
            2.986,
            29.861,
            7.408,
            2.986,
            29.861,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4507",
        "polygon": [
            3.407,
            29.703,
            6.988,
            3.028,
            29.845,
            6.988,
            3.028,
            29.845,
            7.408,
            3.028,
            29.845,
            7.408,
            3.407,
            29.703,
            7.408,
            3.407,
            29.703,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4508",
        "polygon": [
            3.407,
            29.703,
            4.14,
            3.028,
            29.845,
            4.14,
            3.028,
            29.845,
            6.988,
            3.028,
            29.845,
            6.988,
            3.407,
            29.703,
            6.988,
            3.407,
            29.703,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4509",
        "polygon": [
            4.715,
            29.211,
            4.202,
            3.502,
            29.667,
            4.202,
            3.502,
            29.667,
            7.408,
            3.502,
            29.667,
            7.408,
            4.715,
            29.211,
            7.408,
            4.715,
            29.211,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4510",
        "polygon": [
            20.815,
            23.158,
            4.202,
            20.537,
            23.263,
            4.202,
            20.537,
            23.263,
            7.408,
            20.537,
            23.263,
            7.408,
            20.815,
            23.158,
            7.408,
            20.815,
            23.158,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4511",
        "polygon": [
            7.335,
            28.226,
            4.202,
            6.122,
            28.682,
            4.202,
            6.122,
            28.682,
            7.408,
            6.122,
            28.682,
            7.408,
            7.335,
            28.226,
            7.408,
            7.335,
            28.226,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4512",
        "polygon": [
            9.956,
            27.241,
            4.202,
            8.743,
            27.697,
            4.202,
            8.743,
            27.697,
            7.408,
            8.743,
            27.697,
            7.408,
            9.956,
            27.241,
            7.408,
            9.956,
            27.241,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4513",
        "polygon": [
            12.577,
            26.255,
            4.202,
            11.364,
            26.711,
            4.202,
            11.364,
            26.711,
            7.408,
            11.364,
            26.711,
            7.408,
            12.577,
            26.255,
            7.408,
            12.577,
            26.255,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4514",
        "polygon": [
            15.198,
            25.27,
            4.202,
            13.985,
            25.726,
            4.202,
            13.985,
            25.726,
            7.408,
            13.985,
            25.726,
            7.408,
            15.198,
            25.27,
            7.408,
            15.198,
            25.27,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4515",
        "polygon": [
            17.819,
            24.285,
            4.202,
            16.606,
            24.741,
            4.202,
            16.606,
            24.741,
            7.408,
            16.606,
            24.741,
            7.408,
            17.819,
            24.285,
            7.408,
            17.819,
            24.285,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4516",
        "polygon": [
            20.44,
            23.299,
            4.202,
            19.226,
            23.755,
            4.202,
            19.226,
            23.755,
            7.408,
            19.226,
            23.755,
            7.408,
            20.44,
            23.299,
            7.408,
            20.44,
            23.299,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4517",
        "polygon": [
            5.191,
            29.032,
            10.698,
            4.812,
            29.175,
            10.698,
            4.812,
            29.175,
            11.118,
            4.812,
            29.175,
            11.118,
            5.191,
            29.032,
            11.118,
            5.191,
            29.032,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4518",
        "polygon": [
            7.812,
            28.047,
            10.698,
            7.432,
            28.189,
            10.698,
            7.432,
            28.189,
            11.118,
            7.432,
            28.189,
            11.118,
            7.812,
            28.047,
            11.118,
            7.812,
            28.047,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4519",
        "polygon": [
            10.432,
            27.061,
            10.698,
            10.053,
            27.204,
            10.698,
            10.053,
            27.204,
            11.118,
            10.053,
            27.204,
            11.118,
            10.432,
            27.061,
            11.118,
            10.432,
            27.061,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4520",
        "polygon": [
            13.053,
            26.076,
            10.698,
            12.674,
            26.219,
            10.698,
            12.674,
            26.219,
            11.118,
            12.674,
            26.219,
            11.118,
            13.053,
            26.076,
            11.118,
            13.053,
            26.076,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4521",
        "polygon": [
            15.674,
            25.091,
            10.698,
            15.295,
            25.233,
            10.698,
            15.295,
            25.233,
            11.118,
            15.295,
            25.233,
            11.118,
            15.674,
            25.091,
            11.118,
            15.674,
            25.091,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4522",
        "polygon": [
            18.295,
            24.105,
            10.698,
            17.916,
            24.248,
            10.698,
            17.916,
            24.248,
            11.118,
            17.916,
            24.248,
            11.118,
            18.295,
            24.105,
            11.118,
            18.295,
            24.105,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4523",
        "polygon": [
            5.607,
            28.876,
            4.202,
            4.813,
            29.174,
            4.202,
            4.813,
            29.174,
            7.408,
            4.813,
            29.174,
            7.408,
            5.607,
            28.876,
            7.408,
            5.607,
            28.876,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4524",
        "polygon": [
            6.028,
            28.717,
            6.988,
            5.649,
            28.86,
            6.988,
            5.649,
            28.86,
            7.408,
            5.649,
            28.86,
            7.408,
            6.028,
            28.717,
            7.408,
            6.028,
            28.717,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4525",
        "polygon": [
            6.028,
            28.717,
            4.14,
            5.649,
            28.86,
            4.14,
            5.649,
            28.86,
            6.988,
            5.649,
            28.86,
            6.988,
            6.028,
            28.717,
            6.988,
            6.028,
            28.717,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4526",
        "polygon": [
            8.228,
            27.89,
            4.202,
            7.434,
            28.189,
            4.202,
            7.434,
            28.189,
            7.408,
            7.434,
            28.189,
            7.408,
            8.228,
            27.89,
            7.408,
            8.228,
            27.89,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4527",
        "polygon": [
            8.649,
            27.732,
            6.988,
            8.27,
            27.875,
            6.988,
            8.27,
            27.875,
            7.408,
            8.27,
            27.875,
            7.408,
            8.649,
            27.732,
            7.408,
            8.649,
            27.732,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4528",
        "polygon": [
            8.649,
            27.732,
            4.14,
            8.27,
            27.875,
            4.14,
            8.27,
            27.875,
            6.988,
            8.27,
            27.875,
            6.988,
            8.649,
            27.732,
            6.988,
            8.649,
            27.732,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4529",
        "polygon": [
            10.849,
            26.905,
            4.202,
            10.054,
            27.204,
            4.202,
            10.054,
            27.204,
            7.408,
            10.054,
            27.204,
            7.408,
            10.849,
            26.905,
            7.408,
            10.849,
            26.905,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4530",
        "polygon": [
            11.27,
            26.747,
            6.988,
            10.891,
            26.889,
            6.988,
            10.891,
            26.889,
            7.408,
            10.891,
            26.889,
            7.408,
            11.27,
            26.747,
            7.408,
            11.27,
            26.747,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4531",
        "polygon": [
            11.27,
            26.747,
            4.14,
            10.891,
            26.889,
            4.14,
            10.891,
            26.889,
            6.988,
            10.891,
            26.889,
            6.988,
            11.27,
            26.747,
            6.988,
            11.27,
            26.747,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4532",
        "polygon": [
            13.47,
            25.919,
            4.202,
            12.675,
            26.218,
            4.202,
            12.675,
            26.218,
            7.408,
            12.675,
            26.218,
            7.408,
            13.47,
            25.919,
            7.408,
            13.47,
            25.919,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4533",
        "polygon": [
            13.891,
            25.761,
            6.988,
            13.512,
            25.904,
            6.988,
            13.512,
            25.904,
            7.408,
            13.512,
            25.904,
            7.408,
            13.891,
            25.761,
            7.408,
            13.891,
            25.761,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4534",
        "polygon": [
            13.891,
            25.761,
            4.14,
            13.512,
            25.904,
            4.14,
            13.512,
            25.904,
            6.988,
            13.512,
            25.904,
            6.988,
            13.891,
            25.761,
            6.988,
            13.891,
            25.761,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4535",
        "polygon": [
            16.091,
            24.934,
            4.202,
            15.296,
            25.233,
            4.202,
            15.296,
            25.233,
            7.408,
            15.296,
            25.233,
            7.408,
            16.091,
            24.934,
            7.408,
            16.091,
            24.934,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4536",
        "polygon": [
            16.512,
            24.776,
            6.988,
            16.133,
            24.918,
            6.988,
            16.133,
            24.918,
            7.408,
            16.133,
            24.918,
            7.408,
            16.512,
            24.776,
            7.408,
            16.512,
            24.776,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4537",
        "polygon": [
            16.512,
            24.776,
            4.14,
            16.133,
            24.918,
            4.14,
            16.133,
            24.918,
            6.988,
            16.133,
            24.918,
            6.988,
            16.512,
            24.776,
            6.988,
            16.512,
            24.776,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4538",
        "polygon": [
            18.715,
            23.947,
            4.202,
            17.921,
            24.246,
            4.202,
            17.921,
            24.246,
            7.408,
            17.921,
            24.246,
            7.408,
            18.715,
            23.947,
            7.408,
            18.715,
            23.947,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4539",
        "polygon": [
            19.136,
            23.789,
            6.988,
            18.757,
            23.932,
            6.988,
            18.757,
            23.932,
            7.408,
            18.757,
            23.932,
            7.408,
            19.136,
            23.789,
            7.408,
            19.136,
            23.789,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4540",
        "polygon": [
            19.136,
            23.789,
            4.14,
            18.757,
            23.932,
            4.14,
            18.757,
            23.932,
            6.988,
            18.757,
            23.932,
            6.988,
            19.136,
            23.789,
            6.988,
            19.136,
            23.789,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4571",
        "polygon": [
            16.523,
            24.58,
            12.49,
            20.398,
            23.123,
            12.49,
            20.398,
            23.123,
            12.845,
            20.398,
            23.123,
            12.845,
            16.523,
            24.58,
            12.845,
            16.523,
            24.58,
            12.49
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4572",
        "polygon": [
            16.523,
            24.58,
            12.135,
            20.398,
            23.123,
            12.135,
            20.398,
            23.123,
            12.49,
            20.398,
            23.123,
            12.49,
            16.523,
            24.58,
            12.49,
            16.523,
            24.58,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4573",
        "polygon": [
            16.523,
            24.58,
            12.845,
            20.398,
            23.123,
            12.845,
            20.398,
            23.123,
            13.2,
            20.398,
            23.123,
            13.2,
            16.523,
            24.58,
            13.2,
            16.523,
            24.58,
            12.845
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4574",
        "polygon": [
            12.591,
            26.058,
            12.49,
            16.467,
            24.601,
            12.49,
            16.467,
            24.601,
            12.845,
            16.467,
            24.601,
            12.845,
            12.591,
            26.058,
            12.845,
            12.591,
            26.058,
            12.49
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4575",
        "polygon": [
            12.591,
            26.058,
            12.135,
            16.467,
            24.601,
            12.135,
            16.467,
            24.601,
            12.49,
            16.467,
            24.601,
            12.49,
            12.591,
            26.058,
            12.49,
            12.591,
            26.058,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4576",
        "polygon": [
            12.591,
            26.058,
            12.845,
            16.467,
            24.601,
            12.845,
            16.467,
            24.601,
            13.2,
            16.467,
            24.601,
            13.2,
            12.591,
            26.058,
            13.2,
            12.591,
            26.058,
            12.845
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4577",
        "polygon": [
            8.66,
            27.536,
            12.49,
            12.535,
            26.079,
            12.49,
            12.535,
            26.079,
            12.845,
            12.535,
            26.079,
            12.845,
            8.66,
            27.536,
            12.845,
            8.66,
            27.536,
            12.49
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4578",
        "polygon": [
            8.66,
            27.536,
            12.135,
            12.535,
            26.079,
            12.135,
            12.535,
            26.079,
            12.49,
            12.535,
            26.079,
            12.49,
            8.66,
            27.536,
            12.49,
            8.66,
            27.536,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4579",
        "polygon": [
            8.66,
            27.536,
            12.845,
            12.535,
            26.079,
            12.845,
            12.535,
            26.079,
            13.2,
            12.535,
            26.079,
            13.2,
            8.66,
            27.536,
            13.2,
            8.66,
            27.536,
            12.845
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4580",
        "polygon": [
            4.729,
            29.014,
            12.49,
            8.604,
            27.557,
            12.49,
            8.604,
            27.557,
            12.845,
            8.604,
            27.557,
            12.845,
            4.729,
            29.014,
            12.845,
            4.729,
            29.014,
            12.49
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4581",
        "polygon": [
            4.729,
            29.014,
            12.135,
            8.604,
            27.557,
            12.135,
            8.604,
            27.557,
            12.49,
            8.604,
            27.557,
            12.49,
            4.729,
            29.014,
            12.49,
            4.729,
            29.014,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4582",
        "polygon": [
            4.729,
            29.014,
            12.845,
            8.604,
            27.557,
            12.845,
            8.604,
            27.557,
            13.2,
            8.604,
            27.557,
            13.2,
            4.729,
            29.014,
            13.2,
            4.729,
            29.014,
            12.845
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4583",
        "polygon": [
            1.378,
            30.273,
            12.49,
            4.673,
            29.035,
            12.49,
            4.673,
            29.035,
            12.845,
            4.673,
            29.035,
            12.845,
            1.378,
            30.273,
            12.845,
            1.378,
            30.273,
            12.49
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4584",
        "polygon": [
            1.378,
            30.273,
            12.135,
            4.673,
            29.035,
            12.135,
            4.673,
            29.035,
            12.49,
            4.673,
            29.035,
            12.49,
            1.378,
            30.273,
            12.49,
            1.378,
            30.273,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4586",
        "polygon": [
            1.378,
            30.273,
            12.845,
            4.673,
            29.035,
            12.845,
            4.673,
            29.035,
            13.2,
            4.673,
            29.035,
            13.2,
            1.378,
            30.273,
            13.2,
            1.378,
            30.273,
            12.845
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4588",
        "polygon": [
            12.591,
            26.058,
            13.2,
            16.467,
            24.601,
            13.2,
            16.467,
            24.601,
            13.555,
            16.467,
            24.601,
            13.555,
            12.591,
            26.058,
            13.555,
            12.591,
            26.058,
            13.2
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4589",
        "polygon": [
            8.66,
            27.536,
            13.2,
            12.535,
            26.079,
            13.2,
            12.535,
            26.079,
            13.596,
            12.535,
            26.079,
            13.596,
            8.66,
            27.536,
            13.596,
            8.66,
            27.536,
            13.2
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4590",
        "polygon": [
            4.729,
            29.014,
            13.2,
            8.604,
            27.557,
            13.2,
            8.604,
            27.557,
            13.428,
            8.604,
            27.557,
            13.428,
            4.729,
            29.014,
            13.428,
            4.729,
            29.014,
            13.2
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_14",
        "polygon": [
            16.997,
            -9.29,
            0.142,
            17.154,
            -8.003,
            0.142,
            17.154,
            -8.003,
            3.698,
            17.154,
            -8.003,
            3.698,
            16.997,
            -9.29,
            3.698,
            16.997,
            -9.29,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_12",
        "polygon": [
            17.336,
            -6.511,
            0.142,
            17.493,
            -5.225,
            0.142,
            17.493,
            -5.225,
            3.698,
            17.493,
            -5.225,
            3.698,
            17.336,
            -6.511,
            3.698,
            17.336,
            -6.511,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_10",
        "polygon": [
            17.675,
            -3.732,
            0.142,
            17.832,
            -2.445,
            0.142,
            17.832,
            -2.445,
            3.698,
            17.832,
            -2.445,
            3.698,
            17.675,
            -3.732,
            3.698,
            17.675,
            -3.732,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_8",
        "polygon": [
            18.014,
            -0.953,
            0.142,
            18.171,
            0.334,
            0.142,
            18.171,
            0.334,
            3.698,
            18.171,
            0.334,
            3.698,
            18.014,
            -0.953,
            3.698,
            18.014,
            -0.953,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_6",
        "polygon": [
            18.353,
            1.827,
            0.142,
            18.51,
            3.113,
            0.142,
            18.51,
            3.113,
            3.698,
            18.51,
            3.113,
            3.698,
            18.353,
            1.827,
            3.698,
            18.353,
            1.827,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_4",
        "polygon": [
            18.692,
            4.606,
            0.142,
            18.849,
            5.893,
            0.142,
            18.849,
            5.893,
            3.698,
            18.849,
            5.893,
            3.698,
            18.692,
            4.606,
            3.698,
            18.692,
            4.606,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_1",
        "polygon": [
            19.2,
            8.775,
            0.142,
            19.362,
            10.104,
            0.142,
            19.362,
            10.104,
            3.698,
            19.362,
            10.104,
            3.698,
            19.2,
            8.775,
            3.698,
            19.2,
            8.775,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4623",
        "polygon": [
            19.709,
            12.944,
            0.142,
            19.866,
            14.231,
            0.142,
            19.866,
            14.231,
            3.698,
            19.866,
            14.231,
            3.698,
            19.709,
            12.944,
            3.698,
            19.709,
            12.944,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4624",
        "polygon": [
            19.986,
            15.219,
            2.928,
            20.035,
            15.621,
            2.928,
            20.035,
            15.621,
            3.698,
            20.035,
            15.621,
            3.698,
            19.986,
            15.219,
            3.698,
            19.986,
            15.219,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4625",
        "polygon": [
            19.986,
            15.219,
            0.08,
            20.035,
            15.621,
            0.08,
            20.035,
            15.621,
            2.928,
            20.035,
            15.621,
            2.928,
            19.986,
            15.219,
            2.928,
            19.986,
            15.219,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4626",
        "polygon": [
            19.878,
            14.334,
            0.142,
            19.981,
            15.177,
            0.142,
            19.981,
            15.177,
            3.698,
            19.981,
            15.177,
            3.698,
            19.878,
            14.334,
            3.698,
            19.878,
            14.334,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4627",
        "polygon": [
            20.048,
            15.724,
            0.142,
            20.205,
            17.01,
            0.142,
            20.205,
            17.01,
            3.698,
            20.205,
            17.01,
            3.698,
            20.048,
            15.724,
            3.698,
            20.048,
            15.724,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4628",
        "polygon": [
            20.325,
            17.998,
            2.928,
            20.374,
            18.4,
            2.928,
            20.374,
            18.4,
            3.698,
            20.374,
            18.4,
            3.698,
            20.325,
            17.998,
            3.698,
            20.325,
            17.998,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4629",
        "polygon": [
            20.325,
            17.998,
            0.08,
            20.374,
            18.4,
            0.08,
            20.374,
            18.4,
            2.928,
            20.374,
            18.4,
            2.928,
            20.325,
            17.998,
            2.928,
            20.325,
            17.998,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4630",
        "polygon": [
            20.217,
            17.114,
            0.142,
            20.32,
            17.956,
            0.142,
            20.32,
            17.956,
            3.698,
            20.32,
            17.956,
            3.698,
            20.217,
            17.114,
            3.698,
            20.217,
            17.114,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4631",
        "polygon": [
            20.387,
            18.503,
            0.142,
            20.544,
            19.79,
            0.142,
            20.544,
            19.79,
            3.698,
            20.544,
            19.79,
            3.698,
            20.387,
            18.503,
            3.698,
            20.387,
            18.503,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4632",
        "polygon": [
            20.664,
            20.777,
            2.928,
            20.713,
            21.179,
            2.928,
            20.713,
            21.179,
            3.698,
            20.713,
            21.179,
            3.698,
            20.664,
            20.777,
            3.698,
            20.664,
            20.777,
            2.928
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4633",
        "polygon": [
            20.664,
            20.777,
            0.08,
            20.713,
            21.179,
            0.08,
            20.713,
            21.179,
            2.928,
            20.713,
            21.179,
            2.928,
            20.664,
            20.777,
            2.928,
            20.664,
            20.777,
            0.08
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4634",
        "polygon": [
            20.556,
            19.893,
            0.142,
            20.659,
            20.736,
            0.142,
            20.659,
            20.736,
            3.698,
            20.659,
            20.736,
            3.698,
            20.556,
            19.893,
            3.698,
            20.556,
            19.893,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4635",
        "polygon": [
            20.726,
            21.283,
            0.142,
            20.883,
            22.569,
            0.142,
            20.883,
            22.569,
            3.698,
            20.883,
            22.569,
            3.698,
            20.726,
            21.283,
            3.698,
            20.726,
            21.283,
            0.142
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4636",
        "polygon": [
            19.492,
            11.165,
            0.05,
            19.62,
            12.211,
            0.05,
            19.62,
            12.211,
            3.0,
            19.62,
            12.211,
            3.0,
            19.492,
            11.165,
            3.0,
            19.492,
            11.165,
            0.05
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4637",
        "polygon": [
            19.406,
            10.463,
            0.05,
            19.484,
            11.103,
            0.05,
            19.484,
            11.103,
            3.0,
            19.484,
            11.103,
            3.0,
            19.406,
            10.463,
            3.0,
            19.406,
            10.463,
            0.05
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4638",
        "polygon": [
            19.627,
            12.271,
            0.1,
            19.677,
            12.684,
            0.1,
            19.677,
            12.684,
            3.0,
            19.677,
            12.684,
            3.0,
            19.627,
            12.271,
            3.0,
            19.627,
            12.271,
            0.1
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_EG_4639",
        "polygon": [
            20.896,
            22.672,
            0.142,
            20.931,
            22.965,
            0.142,
            20.931,
            22.965,
            3.698,
            20.931,
            22.965,
            3.698,
            20.896,
            22.672,
            3.698,
            20.896,
            22.672,
            0.142
        ]
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_14",
        "polygon": [
            16.997,
            -9.29,
            7.912,
            17.154,
            -8.003,
            7.912,
            17.154,
            -8.003,
            11.118,
            17.154,
            -8.003,
            11.118,
            16.997,
            -9.29,
            11.118,
            16.997,
            -9.29,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4646",
        "polygon": [
            19.492,
            11.165,
            7.82,
            19.62,
            12.211,
            7.82,
            19.62,
            12.211,
            10.77,
            19.62,
            12.211,
            10.77,
            19.492,
            11.165,
            10.77,
            19.492,
            11.165,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4647",
        "polygon": [
            19.406,
            10.463,
            7.82,
            19.484,
            11.103,
            7.82,
            19.484,
            11.103,
            10.77,
            19.484,
            11.103,
            10.77,
            19.406,
            10.463,
            10.77,
            19.406,
            10.463,
            7.82
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4648",
        "polygon": [
            19.627,
            12.271,
            7.87,
            19.677,
            12.684,
            7.87,
            19.677,
            12.684,
            10.77,
            19.677,
            12.684,
            10.77,
            19.627,
            12.271,
            10.77,
            19.627,
            12.271,
            7.87
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4649",
        "polygon": [
            20.896,
            22.672,
            7.912,
            20.931,
            22.965,
            7.912,
            20.931,
            22.965,
            11.118,
            20.931,
            22.965,
            11.118,
            20.896,
            22.672,
            11.118,
            20.896,
            22.672,
            7.912
        ]
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_12",
        "polygon": [
            17.336,
            -6.511,
            7.912,
            17.493,
            -5.225,
            7.912,
            17.493,
            -5.225,
            11.118,
            17.493,
            -5.225,
            11.118,
            17.336,
            -6.511,
            11.118,
            17.336,
            -6.511,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_10",
        "polygon": [
            17.675,
            -3.732,
            7.912,
            17.832,
            -2.445,
            7.912,
            17.832,
            -2.445,
            11.118,
            17.832,
            -2.445,
            11.118,
            17.675,
            -3.732,
            11.118,
            17.675,
            -3.732,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_8",
        "polygon": [
            18.014,
            -0.953,
            7.912,
            18.171,
            0.334,
            7.912,
            18.171,
            0.334,
            11.118,
            18.171,
            0.334,
            11.118,
            18.014,
            -0.953,
            11.118,
            18.014,
            -0.953,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_6",
        "polygon": [
            18.353,
            1.827,
            7.912,
            18.51,
            3.113,
            7.912,
            18.51,
            3.113,
            11.118,
            18.51,
            3.113,
            11.118,
            18.353,
            1.827,
            11.118,
            18.353,
            1.827,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_4",
        "polygon": [
            18.692,
            4.606,
            7.912,
            18.849,
            5.893,
            7.912,
            18.849,
            5.893,
            11.118,
            18.849,
            5.893,
            11.118,
            18.692,
            4.606,
            11.118,
            18.692,
            4.606,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_2",
        "polygon": [
            19.031,
            7.386,
            7.912,
            19.188,
            8.672,
            7.912,
            19.188,
            8.672,
            11.118,
            19.188,
            8.672,
            11.118,
            19.031,
            7.386,
            11.118,
            19.031,
            7.386,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4674",
        "polygon": [
            20.048,
            15.724,
            7.912,
            20.205,
            17.01,
            7.912,
            20.205,
            17.01,
            11.118,
            20.205,
            17.01,
            11.118,
            20.048,
            15.724,
            11.118,
            20.048,
            15.724,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4675",
        "polygon": [
            19.986,
            15.219,
            10.698,
            20.035,
            15.621,
            10.698,
            20.035,
            15.621,
            11.118,
            20.035,
            15.621,
            11.118,
            19.986,
            15.219,
            11.118,
            19.986,
            15.219,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4676",
        "polygon": [
            19.986,
            15.219,
            7.85,
            20.035,
            15.621,
            7.85,
            20.035,
            15.621,
            10.698,
            20.035,
            15.621,
            10.698,
            19.986,
            15.219,
            10.698,
            19.986,
            15.219,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4677",
        "polygon": [
            19.878,
            14.334,
            7.912,
            19.981,
            15.177,
            7.912,
            19.981,
            15.177,
            11.118,
            19.981,
            15.177,
            11.118,
            19.878,
            14.334,
            11.118,
            19.878,
            14.334,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4678",
        "polygon": [
            20.387,
            18.503,
            7.912,
            20.544,
            19.79,
            7.912,
            20.544,
            19.79,
            11.118,
            20.544,
            19.79,
            11.118,
            20.387,
            18.503,
            11.118,
            20.387,
            18.503,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4679",
        "polygon": [
            20.325,
            17.998,
            10.698,
            20.374,
            18.4,
            10.698,
            20.374,
            18.4,
            11.118,
            20.374,
            18.4,
            11.118,
            20.325,
            17.998,
            11.118,
            20.325,
            17.998,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4680",
        "polygon": [
            20.325,
            17.998,
            7.85,
            20.374,
            18.4,
            7.85,
            20.374,
            18.4,
            10.698,
            20.374,
            18.4,
            10.698,
            20.325,
            17.998,
            10.698,
            20.325,
            17.998,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4681",
        "polygon": [
            20.217,
            17.114,
            7.912,
            20.32,
            17.956,
            7.912,
            20.32,
            17.956,
            11.118,
            20.32,
            17.956,
            11.118,
            20.217,
            17.114,
            11.118,
            20.217,
            17.114,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4682",
        "polygon": [
            20.726,
            21.283,
            7.912,
            20.883,
            22.569,
            7.912,
            20.883,
            22.569,
            11.118,
            20.883,
            22.569,
            11.118,
            20.726,
            21.283,
            11.118,
            20.726,
            21.283,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4683",
        "polygon": [
            20.664,
            20.777,
            10.698,
            20.713,
            21.179,
            10.698,
            20.713,
            21.179,
            11.118,
            20.713,
            21.179,
            11.118,
            20.664,
            20.777,
            11.118,
            20.664,
            20.777,
            10.698
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4684",
        "polygon": [
            20.664,
            20.777,
            7.85,
            20.713,
            21.179,
            7.85,
            20.713,
            21.179,
            10.698,
            20.713,
            21.179,
            10.698,
            20.664,
            20.777,
            10.698,
            20.664,
            20.777,
            7.85
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4685",
        "polygon": [
            20.556,
            19.893,
            7.912,
            20.659,
            20.736,
            7.912,
            20.659,
            20.736,
            11.118,
            20.659,
            20.736,
            11.118,
            20.556,
            19.893,
            11.118,
            20.556,
            19.893,
            7.912
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_2OG_4686",
        "polygon": [
            19.709,
            12.944,
            7.912,
            19.866,
            14.231,
            7.912,
            19.866,
            14.231,
            11.118,
            19.866,
            14.231,
            11.118,
            19.709,
            12.944,
            11.118,
            19.709,
            12.944,
            7.912
        ]
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_14",
        "polygon": [
            16.997,
            -9.29,
            4.202,
            17.154,
            -8.003,
            4.202,
            17.154,
            -8.003,
            7.408,
            17.154,
            -8.003,
            7.408,
            16.997,
            -9.29,
            7.408,
            16.997,
            -9.29,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4693",
        "polygon": [
            19.492,
            11.165,
            4.11,
            19.62,
            12.211,
            4.11,
            19.62,
            12.211,
            7.06,
            19.62,
            12.211,
            7.06,
            19.492,
            11.165,
            7.06,
            19.492,
            11.165,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4694",
        "polygon": [
            19.406,
            10.463,
            4.11,
            19.484,
            11.103,
            4.11,
            19.484,
            11.103,
            7.06,
            19.484,
            11.103,
            7.06,
            19.406,
            10.463,
            7.06,
            19.406,
            10.463,
            4.11
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4695",
        "polygon": [
            19.627,
            12.271,
            4.16,
            19.677,
            12.684,
            4.16,
            19.677,
            12.684,
            7.06,
            19.677,
            12.684,
            7.06,
            19.627,
            12.271,
            7.06,
            19.627,
            12.271,
            4.16
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4696",
        "polygon": [
            20.896,
            22.672,
            4.202,
            20.931,
            22.965,
            4.202,
            20.931,
            22.965,
            7.408,
            20.931,
            22.965,
            7.408,
            20.896,
            22.672,
            7.408,
            20.896,
            22.672,
            4.202
        ]
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_12",
        "polygon": [
            17.336,
            -6.511,
            4.202,
            17.493,
            -5.225,
            4.202,
            17.493,
            -5.225,
            7.408,
            17.493,
            -5.225,
            7.408,
            17.336,
            -6.511,
            7.408,
            17.336,
            -6.511,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_10",
        "polygon": [
            17.675,
            -3.732,
            4.202,
            17.832,
            -2.445,
            4.202,
            17.832,
            -2.445,
            7.408,
            17.832,
            -2.445,
            7.408,
            17.675,
            -3.732,
            7.408,
            17.675,
            -3.732,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_8",
        "polygon": [
            18.014,
            -0.953,
            4.202,
            18.171,
            0.334,
            4.202,
            18.171,
            0.334,
            7.408,
            18.171,
            0.334,
            7.408,
            18.014,
            -0.953,
            7.408,
            18.014,
            -0.953,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_6",
        "polygon": [
            18.353,
            1.827,
            4.202,
            18.51,
            3.113,
            4.202,
            18.51,
            3.113,
            7.408,
            18.51,
            3.113,
            7.408,
            18.353,
            1.827,
            7.408,
            18.353,
            1.827,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_4",
        "polygon": [
            18.692,
            4.606,
            4.202,
            18.849,
            5.893,
            4.202,
            18.849,
            5.893,
            7.408,
            18.849,
            5.893,
            7.408,
            18.692,
            4.606,
            7.408,
            18.692,
            4.606,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_2",
        "polygon": [
            19.031,
            7.386,
            4.202,
            19.188,
            8.672,
            4.202,
            19.188,
            8.672,
            7.408,
            19.188,
            8.672,
            7.408,
            19.031,
            7.386,
            7.408,
            19.031,
            7.386,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4703",
        "polygon": [
            20.048,
            15.724,
            4.202,
            20.205,
            17.01,
            4.202,
            20.205,
            17.01,
            7.408,
            20.205,
            17.01,
            7.408,
            20.048,
            15.724,
            7.408,
            20.048,
            15.724,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4704",
        "polygon": [
            20.387,
            18.503,
            4.202,
            20.544,
            19.79,
            4.202,
            20.544,
            19.79,
            7.408,
            20.544,
            19.79,
            7.408,
            20.387,
            18.503,
            7.408,
            20.387,
            18.503,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4706",
        "polygon": [
            19.709,
            12.944,
            4.202,
            19.866,
            14.231,
            4.202,
            19.866,
            14.231,
            7.408,
            19.866,
            14.231,
            7.408,
            19.709,
            12.944,
            7.408,
            19.709,
            12.944,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4728",
        "polygon": [
            19.986,
            15.219,
            6.988,
            20.035,
            15.621,
            6.988,
            20.035,
            15.621,
            7.408,
            20.035,
            15.621,
            7.408,
            19.986,
            15.219,
            7.408,
            19.986,
            15.219,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4729",
        "polygon": [
            19.986,
            15.219,
            4.14,
            20.035,
            15.621,
            4.14,
            20.035,
            15.621,
            6.988,
            20.035,
            15.621,
            6.988,
            19.986,
            15.219,
            6.988,
            19.986,
            15.219,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4731",
        "polygon": [
            20.217,
            17.114,
            6.988,
            20.267,
            17.516,
            6.988,
            20.267,
            17.516,
            7.408,
            20.267,
            17.516,
            7.408,
            20.217,
            17.114,
            7.408,
            20.217,
            17.114,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4732",
        "polygon": [
            20.217,
            17.114,
            4.14,
            20.267,
            17.516,
            4.14,
            20.267,
            17.516,
            6.988,
            20.267,
            17.516,
            6.988,
            20.217,
            17.114,
            6.988,
            20.217,
            17.114,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4733",
        "polygon": [
            20.272,
            17.557,
            4.202,
            20.374,
            18.4,
            4.202,
            20.374,
            18.4,
            7.408,
            20.374,
            18.4,
            7.408,
            20.272,
            17.557,
            7.408,
            20.272,
            17.557,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4734",
        "polygon": [
            20.554,
            19.875,
            6.988,
            20.603,
            20.277,
            6.988,
            20.603,
            20.277,
            7.408,
            20.603,
            20.277,
            7.408,
            20.554,
            19.875,
            7.408,
            20.554,
            19.875,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4735",
        "polygon": [
            20.554,
            19.875,
            4.14,
            20.603,
            20.277,
            4.14,
            20.603,
            20.277,
            6.988,
            20.603,
            20.277,
            6.988,
            20.554,
            19.875,
            6.988,
            20.554,
            19.875,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4736",
        "polygon": [
            20.608,
            20.319,
            4.202,
            20.711,
            21.162,
            4.202,
            20.711,
            21.162,
            7.408,
            20.711,
            21.162,
            7.408,
            20.608,
            20.319,
            7.408,
            20.608,
            20.319,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4740",
        "polygon": [
            19.878,
            14.334,
            4.202,
            19.981,
            15.177,
            4.202,
            19.981,
            15.177,
            7.408,
            19.981,
            15.177,
            7.408,
            19.878,
            14.334,
            7.408,
            19.878,
            14.334,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4741",
        "polygon": [
            20.836,
            22.184,
            6.988,
            20.885,
            22.586,
            6.988,
            20.885,
            22.586,
            7.408,
            20.885,
            22.586,
            7.408,
            20.836,
            22.184,
            7.408,
            20.836,
            22.184,
            6.988
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4742",
        "polygon": [
            20.836,
            22.184,
            4.14,
            20.885,
            22.586,
            4.14,
            20.885,
            22.586,
            6.988,
            20.885,
            22.586,
            6.988,
            20.836,
            22.184,
            6.988,
            20.836,
            22.184,
            4.14
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_1OG_4743",
        "polygon": [
            20.728,
            21.3,
            4.202,
            20.831,
            22.142,
            4.202,
            20.831,
            22.142,
            7.408,
            20.831,
            22.142,
            7.408,
            20.728,
            21.3,
            7.408,
            20.728,
            21.3,
            4.202
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4744",
        "polygon": [
            20.769,
            23.124,
            12.135,
            20.608,
            21.802,
            12.135,
            20.608,
            21.802,
            12.465,
            20.608,
            21.802,
            12.465,
            20.769,
            23.124,
            12.465,
            20.769,
            23.124,
            12.135
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4745",
        "polygon": [
            20.769,
            23.124,
            12.465,
            20.608,
            21.802,
            12.465,
            20.608,
            21.802,
            12.795,
            20.608,
            21.802,
            12.795,
            20.769,
            23.124,
            12.795,
            20.769,
            23.124,
            12.465
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4746",
        "polygon": [
            20.769,
            23.124,
            12.795,
            20.608,
            21.802,
            12.795,
            20.608,
            21.802,
            13.125,
            20.608,
            21.802,
            13.125,
            20.769,
            23.124,
            13.125,
            20.769,
            23.124,
            12.795
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4747",
        "polygon": [
            20.6,
            21.735,
            12.135,
            20.439,
            20.412,
            12.135,
            20.439,
            20.412,
            12.465,
            20.439,
            20.412,
            12.465,
            20.6,
            21.735,
            12.465,
            20.6,
            21.735,
            12.135
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4748",
        "polygon": [
            20.6,
            21.735,
            12.465,
            20.439,
            20.412,
            12.465,
            20.439,
            20.412,
            12.795,
            20.439,
            20.412,
            12.795,
            20.6,
            21.735,
            12.795,
            20.6,
            21.735,
            12.465
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4749",
        "polygon": [
            20.6,
            21.735,
            12.795,
            20.439,
            20.412,
            12.795,
            20.439,
            20.412,
            13.125,
            20.439,
            20.412,
            13.125,
            20.6,
            21.735,
            13.125,
            20.6,
            21.735,
            12.795
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4750",
        "polygon": [
            20.42,
            20.259,
            12.135,
            20.258,
            18.936,
            12.135,
            20.258,
            18.936,
            12.465,
            20.258,
            18.936,
            12.465,
            20.42,
            20.259,
            12.465,
            20.42,
            20.259,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4751",
        "polygon": [
            20.42,
            20.259,
            12.465,
            20.258,
            18.936,
            12.465,
            20.258,
            18.936,
            12.795,
            20.258,
            18.936,
            12.795,
            20.42,
            20.259,
            12.795,
            20.42,
            20.259,
            12.465
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4752",
        "polygon": [
            20.42,
            20.259,
            12.795,
            20.258,
            18.936,
            12.795,
            20.258,
            18.936,
            13.125,
            20.258,
            18.936,
            13.125,
            20.42,
            20.259,
            13.125,
            20.42,
            20.259,
            12.795
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4753",
        "polygon": [
            20.251,
            18.874,
            12.135,
            20.09,
            17.552,
            12.135,
            20.09,
            17.552,
            12.465,
            20.09,
            17.552,
            12.465,
            20.251,
            18.874,
            12.465,
            20.251,
            18.874,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4754",
        "polygon": [
            20.251,
            18.874,
            12.465,
            20.09,
            17.552,
            12.465,
            20.09,
            17.552,
            12.795,
            20.09,
            17.552,
            12.795,
            20.251,
            18.874,
            12.795,
            20.251,
            18.874,
            12.465
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4755",
        "polygon": [
            20.251,
            18.874,
            12.795,
            20.09,
            17.552,
            12.795,
            20.09,
            17.552,
            13.125,
            20.09,
            17.552,
            13.125,
            20.251,
            18.874,
            13.125,
            20.251,
            18.874,
            12.795
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4756",
        "polygon": [
            20.071,
            17.398,
            12.135,
            19.91,
            16.076,
            12.135,
            19.91,
            16.076,
            12.465,
            19.91,
            16.076,
            12.465,
            20.071,
            17.398,
            12.465,
            20.071,
            17.398,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4757",
        "polygon": [
            20.071,
            17.398,
            12.465,
            19.91,
            16.076,
            12.465,
            19.91,
            16.076,
            12.795,
            19.91,
            16.076,
            12.795,
            20.071,
            17.398,
            12.795,
            20.071,
            17.398,
            12.465
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4758",
        "polygon": [
            20.071,
            17.398,
            12.795,
            19.91,
            16.076,
            12.795,
            19.91,
            16.076,
            13.125,
            19.91,
            16.076,
            13.125,
            20.071,
            17.398,
            13.125,
            20.071,
            17.398,
            12.795
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4759",
        "polygon": [
            19.901,
            16.009,
            12.135,
            19.74,
            14.687,
            12.135,
            19.74,
            14.687,
            12.465,
            19.74,
            14.687,
            12.465,
            19.901,
            16.009,
            12.465,
            19.901,
            16.009,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4760",
        "polygon": [
            19.901,
            16.009,
            12.465,
            19.74,
            14.687,
            12.465,
            19.74,
            14.687,
            12.795,
            19.74,
            14.687,
            12.795,
            19.901,
            16.009,
            12.795,
            19.901,
            16.009,
            12.465
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4761",
        "polygon": [
            19.901,
            16.009,
            12.795,
            19.74,
            14.687,
            12.795,
            19.74,
            14.687,
            13.125,
            19.74,
            14.687,
            13.125,
            19.901,
            16.009,
            13.125,
            19.901,
            16.009,
            12.795
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4762",
        "polygon": [
            19.722,
            14.538,
            12.135,
            19.561,
            13.216,
            12.135,
            19.561,
            13.216,
            12.465,
            19.561,
            13.216,
            12.465,
            19.722,
            14.538,
            12.465,
            19.722,
            14.538,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4763",
        "polygon": [
            19.722,
            14.538,
            12.465,
            19.561,
            13.216,
            12.465,
            19.561,
            13.216,
            12.795,
            19.561,
            13.216,
            12.795,
            19.722,
            14.538,
            12.795,
            19.722,
            14.538,
            12.465
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4764",
        "polygon": [
            19.722,
            14.538,
            12.795,
            19.561,
            13.216,
            12.795,
            19.561,
            13.216,
            13.125,
            19.561,
            13.216,
            13.125,
            19.722,
            14.538,
            13.125,
            19.722,
            14.538,
            12.795
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4765",
        "polygon": [
            19.554,
            13.159,
            12.135,
            19.393,
            11.837,
            12.135,
            19.393,
            11.837,
            12.465,
            19.393,
            11.837,
            12.465,
            19.554,
            13.159,
            12.465,
            19.554,
            13.159,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4766",
        "polygon": [
            19.554,
            13.159,
            12.465,
            19.393,
            11.837,
            12.465,
            19.393,
            11.837,
            12.795,
            19.393,
            11.837,
            12.795,
            19.554,
            13.159,
            12.795,
            19.554,
            13.159,
            12.465
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4767",
        "polygon": [
            19.554,
            13.159,
            12.795,
            19.393,
            11.837,
            12.795,
            19.393,
            11.837,
            13.125,
            19.393,
            11.837,
            13.125,
            19.554,
            13.159,
            13.125,
            19.554,
            13.159,
            12.795
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4768",
        "polygon": [
            19.374,
            11.684,
            12.135,
            19.213,
            10.362,
            12.135,
            19.213,
            10.362,
            12.465,
            19.213,
            10.362,
            12.465,
            19.374,
            11.684,
            12.465,
            19.374,
            11.684,
            12.135
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4769",
        "polygon": [
            19.374,
            11.684,
            12.465,
            19.213,
            10.362,
            12.465,
            19.213,
            10.362,
            12.795,
            19.213,
            10.362,
            12.795,
            19.374,
            11.684,
            12.795,
            19.374,
            11.684,
            12.465
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4770",
        "polygon": [
            19.374,
            11.684,
            12.795,
            19.213,
            10.362,
            12.795,
            19.213,
            10.362,
            13.125,
            19.213,
            10.362,
            13.125,
            19.374,
            11.684,
            13.125,
            19.374,
            11.684,
            12.795
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4771",
        "polygon": [
            20.244,
            23.034,
            13.344,
            19.927,
            22.192,
            13.344,
            18.197,
            22.842,
            13.428,
            18.197,
            22.842,
            13.428,
            18.514,
            23.685,
            13.428,
            20.244,
            23.034,
            13.344
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4772",
        "polygon": [
            18.278,
            23.773,
            13.439,
            17.961,
            22.931,
            13.439,
            16.231,
            23.581,
            13.523,
            16.231,
            23.581,
            13.523,
            16.548,
            24.424,
            13.523,
            18.278,
            23.773,
            13.439
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4773",
        "polygon": [
            16.312,
            24.512,
            13.535,
            15.996,
            23.67,
            13.535,
            14.266,
            24.32,
            13.618,
            14.266,
            24.32,
            13.618,
            14.582,
            25.163,
            13.618,
            16.312,
            24.512,
            13.535
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4774",
        "polygon": [
            14.347,
            25.251,
            13.63,
            14.03,
            24.409,
            13.63,
            12.3,
            25.059,
            13.714,
            12.3,
            25.059,
            13.714,
            12.617,
            25.902,
            13.714,
            14.347,
            25.251,
            13.63
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4775",
        "polygon": [
            19.85,
            21.987,
            13.341,
            19.463,
            20.958,
            13.341,
            17.733,
            21.608,
            13.425,
            17.733,
            21.608,
            13.425,
            18.12,
            22.638,
            13.425,
            19.85,
            21.987,
            13.341
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4776",
        "polygon": [
            17.884,
            22.726,
            13.436,
            17.497,
            21.697,
            13.436,
            15.767,
            22.347,
            13.52,
            15.767,
            22.347,
            13.52,
            16.154,
            23.376,
            13.52,
            17.884,
            22.726,
            13.436
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4777",
        "polygon": [
            15.919,
            23.465,
            13.531,
            15.532,
            22.436,
            13.531,
            13.802,
            23.086,
            13.615,
            13.802,
            23.086,
            13.615,
            14.189,
            24.115,
            13.615,
            15.919,
            23.465,
            13.531
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4778",
        "polygon": [
            13.953,
            24.204,
            13.627,
            13.566,
            23.175,
            13.627,
            11.836,
            23.825,
            13.71,
            11.836,
            23.825,
            13.71,
            12.223,
            24.854,
            13.71,
            13.953,
            24.204,
            13.627
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4779",
        "polygon": [
            19.341,
            20.635,
            13.337,
            18.954,
            19.605,
            13.337,
            17.224,
            20.255,
            13.421,
            17.224,
            20.255,
            13.421,
            17.612,
            21.285,
            13.421,
            19.341,
            20.635,
            13.337
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4780",
        "polygon": [
            17.376,
            21.374,
            13.432,
            16.989,
            20.344,
            13.432,
            15.259,
            20.994,
            13.516,
            15.259,
            20.994,
            13.516,
            15.646,
            22.024,
            13.516,
            17.376,
            21.374,
            13.432
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4781",
        "polygon": [
            15.41,
            22.113,
            13.527,
            15.023,
            21.083,
            13.527,
            13.293,
            21.733,
            13.611,
            13.293,
            21.733,
            13.611,
            13.68,
            22.763,
            13.611,
            15.41,
            22.113,
            13.527
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4782",
        "polygon": [
            13.444,
            22.852,
            13.623,
            13.057,
            21.822,
            13.623,
            11.327,
            22.472,
            13.706,
            11.327,
            22.472,
            13.706,
            11.714,
            23.502,
            13.706,
            13.444,
            22.852,
            13.623
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4783",
        "polygon": [
            18.865,
            19.366,
            13.333,
            18.478,
            18.337,
            13.333,
            16.748,
            18.987,
            13.417,
            16.748,
            18.987,
            13.417,
            17.135,
            20.017,
            13.417,
            18.865,
            19.366,
            13.333
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4784",
        "polygon": [
            16.899,
            20.105,
            13.429,
            16.512,
            19.076,
            13.429,
            14.782,
            19.726,
            13.512,
            14.782,
            19.726,
            13.512,
            15.169,
            20.756,
            13.512,
            16.899,
            20.105,
            13.429
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4785",
        "polygon": [
            14.933,
            20.844,
            13.524,
            14.546,
            19.815,
            13.524,
            12.816,
            20.465,
            13.608,
            12.816,
            20.465,
            13.608,
            13.203,
            21.495,
            13.608,
            14.933,
            20.844,
            13.524
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4786",
        "polygon": [
            12.968,
            21.583,
            13.619,
            12.581,
            20.554,
            13.619,
            10.851,
            21.204,
            13.703,
            10.851,
            21.204,
            13.703,
            11.238,
            22.234,
            13.703,
            12.968,
            21.583,
            13.619
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4787",
        "polygon": [
            18.356,
            18.014,
            13.33,
            17.969,
            16.984,
            13.33,
            16.239,
            17.634,
            13.413,
            16.239,
            17.634,
            13.413,
            16.626,
            18.664,
            13.413,
            18.356,
            18.014,
            13.33
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4788",
        "polygon": [
            16.39,
            18.753,
            13.425,
            16.003,
            17.723,
            13.425,
            14.273,
            18.373,
            13.509,
            14.273,
            18.373,
            13.509,
            14.66,
            19.403,
            13.509,
            16.39,
            18.753,
            13.425
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4789",
        "polygon": [
            14.425,
            19.492,
            13.52,
            14.038,
            18.462,
            13.52,
            12.308,
            19.112,
            13.604,
            12.308,
            19.112,
            13.604,
            12.695,
            20.142,
            13.604,
            14.425,
            19.492,
            13.52
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4790",
        "polygon": [
            12.459,
            20.231,
            13.615,
            12.072,
            19.201,
            13.615,
            10.342,
            19.851,
            13.699,
            10.342,
            19.851,
            13.699,
            10.729,
            20.881,
            13.699,
            12.459,
            20.231,
            13.615
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4791",
        "polygon": [
            17.879,
            16.745,
            13.326,
            17.492,
            15.716,
            13.326,
            15.762,
            16.366,
            13.41,
            15.762,
            16.366,
            13.41,
            16.149,
            17.396,
            13.41,
            17.879,
            16.745,
            13.326
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4792",
        "polygon": [
            15.914,
            17.484,
            13.421,
            15.527,
            16.455,
            13.421,
            13.797,
            17.105,
            13.505,
            13.797,
            17.105,
            13.505,
            14.184,
            18.135,
            13.505,
            15.914,
            17.484,
            13.421
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4793",
        "polygon": [
            13.948,
            18.223,
            13.516,
            13.561,
            17.194,
            13.516,
            11.831,
            17.844,
            13.6,
            11.831,
            17.844,
            13.6,
            12.218,
            18.874,
            13.6,
            13.948,
            18.223,
            13.516
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4794",
        "polygon": [
            11.982,
            18.963,
            13.612,
            11.595,
            17.933,
            13.612,
            9.865,
            18.583,
            13.695,
            9.865,
            18.583,
            13.695,
            10.252,
            19.613,
            13.695,
            11.982,
            18.963,
            13.612
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4795",
        "polygon": [
            17.371,
            15.393,
            13.322,
            16.984,
            14.363,
            13.322,
            15.254,
            15.013,
            13.406,
            15.254,
            15.013,
            13.406,
            15.641,
            16.043,
            13.406,
            17.371,
            15.393,
            13.322
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4796",
        "polygon": [
            15.405,
            16.132,
            13.417,
            15.018,
            15.102,
            13.417,
            13.288,
            15.752,
            13.501,
            13.288,
            15.752,
            13.501,
            13.675,
            16.782,
            13.501,
            15.405,
            16.132,
            13.417
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4797",
        "polygon": [
            13.439,
            16.871,
            13.513,
            13.052,
            15.841,
            13.513,
            11.322,
            16.491,
            13.596,
            11.322,
            16.491,
            13.596,
            11.709,
            17.521,
            13.596,
            13.439,
            16.871,
            13.513
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4798",
        "polygon": [
            11.474,
            17.61,
            13.608,
            11.087,
            16.58,
            13.608,
            9.357,
            17.23,
            13.692,
            9.357,
            17.23,
            13.692,
            9.744,
            18.26,
            13.692,
            11.474,
            17.61,
            13.608
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4799",
        "polygon": [
            16.894,
            14.125,
            13.319,
            16.507,
            13.095,
            13.319,
            14.777,
            13.745,
            13.402,
            14.777,
            13.745,
            13.402,
            15.164,
            14.775,
            13.402,
            16.894,
            14.125,
            13.319
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4800",
        "polygon": [
            14.928,
            14.864,
            13.414,
            14.541,
            13.834,
            13.414,
            12.811,
            14.484,
            13.498,
            12.811,
            14.484,
            13.498,
            13.198,
            15.514,
            13.498,
            14.928,
            14.864,
            13.414
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4801",
        "polygon": [
            12.963,
            15.603,
            13.509,
            12.575,
            14.573,
            13.509,
            10.846,
            15.223,
            13.593,
            10.846,
            15.223,
            13.593,
            11.233,
            16.253,
            13.593,
            12.963,
            15.603,
            13.509
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4802",
        "polygon": [
            10.997,
            16.342,
            13.604,
            10.61,
            15.312,
            13.604,
            8.88,
            15.962,
            13.688,
            8.88,
            15.962,
            13.688,
            9.267,
            16.992,
            13.688,
            10.997,
            16.342,
            13.604
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4803",
        "polygon": [
            16.378,
            12.753,
            13.315,
            15.991,
            11.724,
            13.315,
            14.261,
            12.374,
            13.399,
            14.261,
            12.374,
            13.399,
            14.648,
            13.403,
            13.399,
            16.378,
            12.753,
            13.315
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4804",
        "polygon": [
            14.413,
            13.492,
            13.41,
            14.026,
            12.463,
            13.41,
            12.296,
            13.113,
            13.494,
            12.296,
            13.113,
            13.494,
            12.683,
            14.142,
            13.494,
            14.413,
            13.492,
            13.41
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4805",
        "polygon": [
            12.447,
            14.231,
            13.505,
            12.06,
            13.202,
            13.505,
            10.33,
            13.852,
            13.589,
            10.33,
            13.852,
            13.589,
            10.717,
            14.881,
            13.589,
            12.447,
            14.231,
            13.505
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4806",
        "polygon": [
            10.481,
            14.97,
            13.6,
            10.094,
            13.941,
            13.6,
            8.364,
            14.591,
            13.684,
            8.364,
            14.591,
            13.684,
            8.751,
            15.62,
            13.684,
            10.481,
            14.97,
            13.6
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4807",
        "polygon": [
            19.842,
            16.007,
            13.231,
            19.455,
            14.978,
            13.231,
            17.725,
            15.628,
            13.315,
            17.725,
            15.628,
            13.315,
            18.112,
            16.658,
            13.315,
            19.842,
            16.007,
            13.231
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4808",
        "polygon": [
            19.334,
            14.655,
            13.227,
            18.947,
            13.625,
            13.227,
            17.217,
            14.275,
            13.311,
            17.217,
            14.275,
            13.311,
            17.604,
            15.305,
            13.311,
            19.334,
            14.655,
            13.227
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4809",
        "polygon": [
            18.857,
            13.387,
            13.224,
            18.47,
            12.357,
            13.224,
            16.74,
            13.007,
            13.307,
            16.74,
            13.007,
            13.307,
            17.127,
            14.037,
            13.307,
            18.857,
            13.387,
            13.224
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4810",
        "polygon": [
            18.341,
            12.015,
            13.22,
            17.954,
            10.986,
            13.22,
            16.224,
            11.636,
            13.303,
            16.224,
            11.636,
            13.303,
            16.611,
            12.665,
            13.303,
            18.341,
            12.015,
            13.22
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4811",
        "polygon": [
            20.04,
            18.925,
            13.277,
            19.653,
            17.895,
            13.277,
            18.718,
            18.246,
            13.322,
            18.718,
            18.246,
            13.322,
            19.105,
            19.276,
            13.322,
            20.04,
            18.925,
            13.277
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4812",
        "polygon": [
            20.415,
            20.236,
            13.285,
            20.028,
            19.207,
            13.285,
            19.187,
            19.523,
            13.326,
            19.187,
            19.523,
            13.326,
            19.574,
            20.553,
            13.326,
            20.415,
            20.236,
            13.285
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4813",
        "polygon": [
            19.903,
            17.438,
            13.255,
            19.515,
            16.408,
            13.255,
            18.206,
            16.9,
            13.318,
            18.206,
            16.9,
            13.318,
            18.593,
            17.93,
            13.318,
            19.903,
            17.438,
            13.255
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4814",
        "polygon": [
            19.419,
            11.631,
            13.168,
            19.032,
            10.602,
            13.168,
            18.19,
            10.918,
            13.209,
            18.19,
            10.918,
            13.209,
            18.578,
            11.948,
            13.209,
            19.419,
            11.631,
            13.168
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4815",
        "polygon": [
            20.506,
            21.741,
            13.309,
            20.119,
            20.711,
            13.309,
            19.698,
            20.869,
            13.33,
            19.698,
            20.869,
            13.33,
            20.085,
            21.899,
            13.33,
            20.506,
            21.741,
            13.309
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4816",
        "polygon": [
            19.507,
            13.115,
            13.192,
            19.12,
            12.085,
            13.192,
            18.699,
            12.243,
            13.212,
            18.699,
            12.243,
            13.212,
            19.086,
            13.273,
            13.212,
            19.507,
            13.115,
            13.192
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4817",
        "polygon": [
            10.348,
            25.787,
            13.675,
            10.664,
            26.63,
            13.675,
            12.395,
            25.979,
            13.746,
            12.395,
            25.979,
            13.746,
            12.078,
            25.137,
            13.746,
            10.348,
            25.787,
            13.675
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4818",
        "polygon": [
            8.382,
            26.526,
            13.595,
            8.699,
            27.369,
            13.595,
            10.429,
            26.718,
            13.666,
            10.429,
            26.718,
            13.666,
            10.112,
            25.876,
            13.666,
            8.382,
            26.526,
            13.595
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4819",
        "polygon": [
            6.416,
            27.265,
            13.514,
            6.733,
            28.108,
            13.514,
            8.464,
            27.457,
            13.585,
            8.464,
            27.457,
            13.585,
            8.147,
            26.615,
            13.585,
            6.416,
            27.265,
            13.514
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4820",
        "polygon": [
            4.451,
            28.004,
            13.433,
            4.767,
            28.847,
            13.433,
            6.498,
            28.196,
            13.504,
            6.498,
            28.196,
            13.504,
            6.181,
            27.354,
            13.504,
            4.451,
            28.004,
            13.433
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4821",
        "polygon": [
            2.485,
            28.743,
            13.353,
            2.802,
            29.586,
            13.353,
            4.532,
            28.935,
            13.424,
            4.532,
            28.935,
            13.424,
            4.215,
            28.093,
            13.424,
            2.485,
            28.743,
            13.353
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4822",
        "polygon": [
            0.519,
            29.482,
            13.272,
            0.836,
            30.325,
            13.272,
            2.567,
            29.674,
            13.343,
            2.567,
            29.674,
            13.343,
            2.25,
            28.832,
            13.343,
            0.519,
            29.482,
            13.272
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4823",
        "polygon": [
            9.885,
            24.558,
            13.675,
            10.273,
            25.587,
            13.675,
            12.003,
            24.937,
            13.746,
            12.003,
            24.937,
            13.746,
            11.616,
            23.907,
            13.746,
            9.885,
            24.558,
            13.675
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4824",
        "polygon": [
            7.917,
            25.297,
            13.594,
            8.305,
            26.327,
            13.594,
            10.035,
            25.677,
            13.665,
            10.035,
            25.677,
            13.665,
            9.648,
            24.647,
            13.665,
            7.917,
            25.297,
            13.594
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4825",
        "polygon": [
            5.952,
            26.037,
            13.514,
            6.339,
            27.066,
            13.514,
            8.069,
            26.416,
            13.585,
            8.069,
            26.416,
            13.585,
            7.682,
            25.386,
            13.585,
            5.952,
            26.037,
            13.514
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4826",
        "polygon": [
            3.986,
            26.776,
            13.433,
            4.373,
            27.805,
            13.433,
            6.104,
            27.155,
            13.504,
            6.104,
            27.155,
            13.504,
            5.717,
            26.125,
            13.504,
            3.986,
            26.776,
            13.433
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4827",
        "polygon": [
            2.02,
            27.515,
            13.352,
            2.408,
            28.544,
            13.352,
            4.138,
            27.894,
            13.423,
            4.138,
            27.894,
            13.423,
            3.751,
            26.864,
            13.423,
            2.02,
            27.515,
            13.352
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4828",
        "polygon": [
            0.055,
            28.254,
            13.272,
            0.442,
            29.283,
            13.272,
            2.172,
            28.633,
            13.343,
            2.172,
            28.633,
            13.343,
            1.785,
            27.603,
            13.343,
            0.055,
            28.254,
            13.272
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4829",
        "polygon": [
            9.377,
            23.205,
            13.675,
            9.764,
            24.235,
            13.675,
            11.494,
            23.584,
            13.746,
            11.494,
            23.584,
            13.746,
            11.107,
            22.555,
            13.746,
            9.377,
            23.205,
            13.675
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4830",
        "polygon": [
            7.409,
            23.945,
            13.594,
            7.796,
            24.975,
            13.594,
            9.526,
            24.324,
            13.665,
            9.526,
            24.324,
            13.665,
            9.139,
            23.294,
            13.665,
            7.409,
            23.945,
            13.594
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4831",
        "polygon": [
            5.443,
            24.684,
            13.514,
            5.83,
            25.714,
            13.514,
            7.561,
            25.063,
            13.585,
            7.561,
            25.063,
            13.585,
            7.174,
            24.033,
            13.585,
            5.443,
            24.684,
            13.514
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4832",
        "polygon": [
            3.478,
            25.423,
            13.433,
            3.865,
            26.453,
            13.433,
            5.595,
            25.802,
            13.504,
            5.595,
            25.802,
            13.504,
            5.208,
            24.772,
            13.504,
            3.478,
            25.423,
            13.433
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4833",
        "polygon": [
            1.512,
            26.162,
            13.352,
            1.899,
            27.192,
            13.352,
            3.629,
            26.541,
            13.423,
            3.629,
            26.541,
            13.423,
            3.242,
            25.511,
            13.423,
            1.512,
            26.162,
            13.352
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4834",
        "polygon": [
            -0.454,
            26.901,
            13.272,
            -0.067,
            27.931,
            13.272,
            1.664,
            27.28,
            13.343,
            1.664,
            27.28,
            13.343,
            1.277,
            26.25,
            13.343,
            -0.454,
            26.901,
            13.272
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4835",
        "polygon": [
            8.9,
            21.937,
            13.675,
            9.287,
            22.966,
            13.675,
            11.018,
            22.316,
            13.746,
            11.018,
            22.316,
            13.746,
            10.63,
            21.286,
            13.746,
            8.9,
            21.937,
            13.675
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4836",
        "polygon": [
            6.932,
            22.677,
            13.594,
            7.319,
            23.706,
            13.594,
            9.05,
            23.056,
            13.665,
            9.05,
            23.056,
            13.665,
            8.663,
            22.026,
            13.665,
            6.932,
            22.677,
            13.594
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4837",
        "polygon": [
            4.966,
            23.416,
            13.514,
            5.354,
            24.445,
            13.514,
            7.084,
            23.795,
            13.585,
            7.084,
            23.795,
            13.585,
            6.697,
            22.765,
            13.585,
            4.966,
            23.416,
            13.514
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4838",
        "polygon": [
            3.001,
            24.155,
            13.433,
            3.388,
            25.184,
            13.433,
            5.118,
            24.534,
            13.504,
            5.118,
            24.534,
            13.504,
            4.731,
            23.504,
            13.504,
            3.001,
            24.155,
            13.433
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4839",
        "polygon": [
            1.035,
            24.894,
            13.352,
            1.422,
            25.923,
            13.352,
            3.153,
            25.273,
            13.423,
            3.153,
            25.273,
            13.423,
            2.766,
            24.243,
            13.423,
            1.035,
            24.894,
            13.352
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4840",
        "polygon": [
            -0.931,
            25.633,
            13.272,
            -0.543,
            26.662,
            13.272,
            1.187,
            26.012,
            13.343,
            1.187,
            26.012,
            13.343,
            0.8,
            24.982,
            13.343,
            -0.931,
            25.633,
            13.272
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4841",
        "polygon": [
            8.392,
            20.584,
            13.675,
            8.779,
            21.614,
            13.675,
            10.509,
            20.963,
            13.746,
            10.509,
            20.963,
            13.746,
            10.122,
            19.934,
            13.746,
            8.392,
            20.584,
            13.675
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4842",
        "polygon": [
            6.424,
            21.324,
            13.594,
            6.811,
            22.354,
            13.594,
            8.541,
            21.703,
            13.665,
            8.541,
            21.703,
            13.665,
            8.154,
            20.673,
            13.665,
            6.424,
            21.324,
            13.594
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4843",
        "polygon": [
            4.458,
            22.063,
            13.514,
            4.845,
            23.093,
            13.514,
            6.575,
            22.442,
            13.585,
            6.575,
            22.442,
            13.585,
            6.188,
            21.412,
            13.585,
            4.458,
            22.063,
            13.514
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4844",
        "polygon": [
            2.492,
            22.802,
            13.433,
            2.879,
            23.832,
            13.433,
            4.61,
            23.181,
            13.504,
            4.61,
            23.181,
            13.504,
            4.223,
            22.152,
            13.504,
            2.492,
            22.802,
            13.433
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4845",
        "polygon": [
            0.527,
            23.541,
            13.352,
            0.914,
            24.571,
            13.352,
            2.644,
            23.92,
            13.423,
            2.644,
            23.92,
            13.423,
            2.257,
            22.891,
            13.423,
            0.527,
            23.541,
            13.352
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4846",
        "polygon": [
            -1.439,
            24.28,
            13.272,
            -1.052,
            25.31,
            13.272,
            0.678,
            24.659,
            13.343,
            0.678,
            24.659,
            13.343,
            0.291,
            23.63,
            13.343,
            -1.439,
            24.28,
            13.272
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4847",
        "polygon": [
            7.915,
            19.316,
            13.675,
            8.302,
            20.345,
            13.675,
            10.032,
            19.695,
            13.746,
            10.032,
            19.695,
            13.746,
            9.645,
            18.665,
            13.746,
            7.915,
            19.316,
            13.675
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4848",
        "polygon": [
            5.947,
            20.056,
            13.594,
            6.334,
            21.085,
            13.594,
            8.064,
            20.435,
            13.665,
            8.064,
            20.435,
            13.665,
            7.677,
            19.405,
            13.665,
            5.947,
            20.056,
            13.594
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4849",
        "polygon": [
            3.981,
            20.795,
            13.514,
            4.368,
            21.824,
            13.514,
            6.099,
            21.174,
            13.585,
            6.099,
            21.174,
            13.585,
            5.712,
            20.144,
            13.585,
            3.981,
            20.795,
            13.514
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4850",
        "polygon": [
            2.015,
            21.534,
            13.433,
            2.403,
            22.563,
            13.433,
            4.133,
            21.913,
            13.504,
            4.133,
            21.913,
            13.504,
            3.746,
            20.883,
            13.504,
            2.015,
            21.534,
            13.433
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4851",
        "polygon": [
            0.05,
            22.273,
            13.352,
            0.437,
            23.302,
            13.352,
            2.167,
            22.652,
            13.423,
            2.167,
            22.652,
            13.423,
            1.78,
            21.622,
            13.423,
            0.05,
            22.273,
            13.352
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4852",
        "polygon": [
            -1.916,
            23.012,
            13.272,
            -1.529,
            24.041,
            13.272,
            0.202,
            23.391,
            13.343,
            0.202,
            23.391,
            13.343,
            -0.186,
            22.361,
            13.343,
            -1.916,
            23.012,
            13.272
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4853",
        "polygon": [
            7.406,
            17.963,
            13.675,
            7.793,
            18.993,
            13.675,
            9.524,
            18.342,
            13.746,
            9.524,
            18.342,
            13.746,
            9.137,
            17.313,
            13.746,
            7.406,
            17.963,
            13.675
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4854",
        "polygon": [
            5.438,
            18.703,
            13.594,
            5.825,
            19.733,
            13.594,
            7.556,
            19.082,
            13.665,
            7.556,
            19.082,
            13.665,
            7.169,
            18.053,
            13.665,
            5.438,
            18.703,
            13.594
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4855",
        "polygon": [
            3.473,
            19.442,
            13.514,
            3.86,
            20.472,
            13.514,
            5.59,
            19.821,
            13.585,
            5.59,
            19.821,
            13.585,
            5.203,
            18.792,
            13.585,
            3.473,
            19.442,
            13.514
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4856",
        "polygon": [
            1.507,
            20.181,
            13.433,
            1.894,
            21.211,
            13.433,
            3.624,
            20.56,
            13.504,
            3.624,
            20.56,
            13.504,
            3.237,
            19.531,
            13.504,
            1.507,
            20.181,
            13.433
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4857",
        "polygon": [
            -0.459,
            20.92,
            13.352,
            -0.072,
            21.95,
            13.352,
            1.659,
            21.299,
            13.423,
            1.659,
            21.299,
            13.423,
            1.272,
            20.27,
            13.423,
            -0.459,
            20.92,
            13.352
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4858",
        "polygon": [
            -2.424,
            21.659,
            13.272,
            -2.037,
            22.689,
            13.272,
            -0.307,
            22.038,
            13.343,
            -0.307,
            22.038,
            13.343,
            -0.694,
            21.009,
            13.343,
            -2.424,
            21.659,
            13.272
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4859",
        "polygon": [
            6.929,
            16.695,
            13.675,
            7.316,
            17.725,
            13.675,
            9.047,
            17.074,
            13.746,
            9.047,
            17.074,
            13.746,
            8.66,
            16.044,
            13.746,
            6.929,
            16.695,
            13.675
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4860",
        "polygon": [
            4.961,
            17.435,
            13.594,
            5.349,
            18.464,
            13.594,
            7.079,
            17.814,
            13.665,
            7.079,
            17.814,
            13.665,
            6.692,
            16.784,
            13.665,
            4.961,
            17.435,
            13.594
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4861",
        "polygon": [
            2.996,
            18.174,
            13.514,
            3.383,
            19.203,
            13.514,
            5.113,
            18.553,
            13.585,
            5.113,
            18.553,
            13.585,
            4.726,
            17.523,
            13.585,
            2.996,
            18.174,
            13.514
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4862",
        "polygon": [
            1.03,
            18.913,
            13.433,
            1.417,
            19.942,
            13.433,
            3.148,
            19.292,
            13.504,
            3.148,
            19.292,
            13.504,
            2.76,
            18.262,
            13.504,
            1.03,
            18.913,
            13.433
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4863",
        "polygon": [
            -0.936,
            19.652,
            13.352,
            -0.548,
            20.681,
            13.352,
            1.182,
            20.031,
            13.423,
            1.182,
            20.031,
            13.423,
            0.795,
            19.001,
            13.423,
            -0.936,
            19.652,
            13.352
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4864",
        "polygon": [
            -2.901,
            20.391,
            13.272,
            -2.514,
            21.421,
            13.272,
            -0.784,
            20.77,
            13.343,
            -0.784,
            20.77,
            13.343,
            -1.171,
            19.74,
            13.343,
            -2.901,
            20.391,
            13.272
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4865",
        "polygon": [
            6.414,
            15.324,
            13.675,
            6.801,
            16.353,
            13.675,
            8.531,
            15.703,
            13.746,
            8.531,
            15.703,
            13.746,
            8.144,
            14.673,
            13.746,
            6.414,
            15.324,
            13.675
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4866",
        "polygon": [
            4.446,
            16.064,
            13.594,
            4.833,
            17.093,
            13.594,
            6.563,
            16.443,
            13.665,
            6.563,
            16.443,
            13.665,
            6.176,
            15.413,
            13.665,
            4.446,
            16.064,
            13.594
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4867",
        "polygon": [
            2.48,
            16.803,
            13.514,
            2.867,
            17.832,
            13.514,
            4.598,
            17.182,
            13.585,
            4.598,
            17.182,
            13.585,
            4.211,
            16.152,
            13.585,
            2.48,
            16.803,
            13.514
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4868",
        "polygon": [
            0.515,
            17.542,
            13.433,
            0.902,
            18.571,
            13.433,
            2.632,
            17.921,
            13.504,
            2.632,
            17.921,
            13.504,
            2.245,
            16.891,
            13.504,
            0.515,
            17.542,
            13.433
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4869",
        "polygon": [
            -1.451,
            18.281,
            13.352,
            -1.064,
            19.31,
            13.352,
            0.666,
            18.66,
            13.423,
            0.666,
            18.66,
            13.423,
            0.279,
            17.63,
            13.423,
            -1.451,
            18.281,
            13.352
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_3OG_4870",
        "polygon": [
            -3.417,
            19.02,
            13.272,
            -3.03,
            20.049,
            13.272,
            -1.299,
            19.399,
            13.343,
            -1.299,
            19.399,
            13.343,
            -1.686,
            18.369,
            13.343,
            -3.417,
            19.02,
            13.272
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4871",
        "polygon": [
            -16.95,
            32.939,
            17.045,
            -16.563,
            33.969,
            17.045,
            -14.879,
            33.336,
            16.976,
            -14.879,
            33.336,
            16.976,
            -15.267,
            32.306,
            16.976,
            -16.95,
            32.939,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4872",
        "polygon": [
            -18.872,
            33.662,
            17.124,
            -18.485,
            34.692,
            17.124,
            -16.801,
            34.059,
            17.055,
            -16.801,
            34.059,
            17.055,
            -17.189,
            33.029,
            17.055,
            -18.872,
            33.662,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4873",
        "polygon": [
            -20.797,
            34.386,
            17.203,
            -20.41,
            35.415,
            17.203,
            -18.727,
            34.782,
            17.134,
            -18.727,
            34.782,
            17.134,
            -19.114,
            33.753,
            17.134,
            -20.797,
            34.386,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4874",
        "polygon": [
            -22.719,
            35.108,
            17.282,
            -22.332,
            36.138,
            17.282,
            -20.649,
            35.505,
            17.213,
            -20.649,
            35.505,
            17.213,
            -21.036,
            34.475,
            17.213,
            -22.719,
            35.108,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4875",
        "polygon": [
            -24.639,
            35.83,
            17.361,
            -24.252,
            36.86,
            17.361,
            -22.568,
            36.227,
            17.292,
            -22.568,
            36.227,
            17.292,
            -22.955,
            35.197,
            17.292,
            -24.639,
            35.83,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4876",
        "polygon": [
            -17.426,
            31.674,
            17.045,
            -17.039,
            32.703,
            17.045,
            -15.355,
            32.07,
            16.976,
            -15.355,
            32.07,
            16.976,
            -15.742,
            31.041,
            16.976,
            -17.426,
            31.674,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4877",
        "polygon": [
            -19.348,
            32.396,
            17.124,
            -18.961,
            33.426,
            17.124,
            -17.277,
            32.793,
            17.055,
            -17.277,
            32.793,
            17.055,
            -17.664,
            31.763,
            17.055,
            -19.348,
            32.396,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4878",
        "polygon": [
            -21.273,
            33.12,
            17.203,
            -20.886,
            34.15,
            17.203,
            -19.202,
            33.517,
            17.134,
            -19.202,
            33.517,
            17.134,
            -19.59,
            32.487,
            17.134,
            -21.273,
            33.12,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4879",
        "polygon": [
            -23.195,
            33.842,
            17.282,
            -22.808,
            34.872,
            17.282,
            -21.124,
            34.239,
            17.213,
            -21.124,
            34.239,
            17.213,
            -21.512,
            33.21,
            17.213,
            -23.195,
            33.842,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4880",
        "polygon": [
            -25.115,
            34.564,
            17.361,
            -24.728,
            35.594,
            17.361,
            -23.044,
            34.961,
            17.292,
            -23.044,
            34.961,
            17.292,
            -23.431,
            33.931,
            17.292,
            -25.115,
            34.564,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4881",
        "polygon": [
            -17.902,
            30.408,
            17.045,
            -17.515,
            31.437,
            17.045,
            -15.831,
            30.804,
            16.976,
            -15.831,
            30.804,
            16.976,
            -16.218,
            29.775,
            16.976,
            -17.902,
            30.408,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4882",
        "polygon": [
            -19.824,
            31.13,
            17.124,
            -19.437,
            32.16,
            17.124,
            -17.753,
            31.527,
            17.055,
            -17.753,
            31.527,
            17.055,
            -18.14,
            30.497,
            17.055,
            -19.824,
            31.13,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4883",
        "polygon": [
            -21.749,
            31.854,
            17.203,
            -21.362,
            32.884,
            17.203,
            -19.678,
            32.251,
            17.134,
            -19.678,
            32.251,
            17.134,
            -20.065,
            31.221,
            17.134,
            -21.749,
            31.854,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4884",
        "polygon": [
            -23.671,
            32.577,
            17.282,
            -23.284,
            33.606,
            17.282,
            -21.6,
            32.973,
            17.213,
            -21.6,
            32.973,
            17.213,
            -21.988,
            31.944,
            17.213,
            -23.671,
            32.577,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4885",
        "polygon": [
            -25.591,
            33.298,
            17.361,
            -25.204,
            34.328,
            17.361,
            -23.52,
            33.695,
            17.292,
            -23.52,
            33.695,
            17.292,
            -23.907,
            32.665,
            17.292,
            -25.591,
            33.298,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4886",
        "polygon": [
            -18.378,
            29.142,
            17.045,
            -17.991,
            30.171,
            17.045,
            -16.307,
            29.538,
            16.976,
            -16.307,
            29.538,
            16.976,
            -16.694,
            28.509,
            16.976,
            -18.378,
            29.142,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4887",
        "polygon": [
            -20.3,
            29.864,
            17.124,
            -19.913,
            30.894,
            17.124,
            -18.229,
            30.261,
            17.055,
            -18.229,
            30.261,
            17.055,
            -18.616,
            29.231,
            17.055,
            -20.3,
            29.864,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4888",
        "polygon": [
            -22.225,
            30.588,
            17.203,
            -21.838,
            31.618,
            17.203,
            -20.154,
            30.985,
            17.134,
            -20.154,
            30.985,
            17.134,
            -20.541,
            29.955,
            17.134,
            -22.225,
            30.588,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4889",
        "polygon": [
            -24.147,
            31.311,
            17.282,
            -23.76,
            32.34,
            17.282,
            -22.076,
            31.707,
            17.213,
            -22.076,
            31.707,
            17.213,
            -22.463,
            30.678,
            17.213,
            -24.147,
            31.311,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4890",
        "polygon": [
            -26.067,
            32.032,
            17.361,
            -25.68,
            33.062,
            17.361,
            -23.996,
            32.429,
            17.292,
            -23.996,
            32.429,
            17.292,
            -24.383,
            31.399,
            17.292,
            -26.067,
            32.032,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4891",
        "polygon": [
            -18.854,
            27.876,
            17.045,
            -18.467,
            28.905,
            17.045,
            -16.783,
            28.273,
            16.976,
            -16.783,
            28.273,
            16.976,
            -17.17,
            27.243,
            16.976,
            -18.854,
            27.876,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4892",
        "polygon": [
            -20.776,
            28.598,
            17.124,
            -20.389,
            29.628,
            17.124,
            -18.705,
            28.995,
            17.055,
            -18.705,
            28.995,
            17.055,
            -19.092,
            27.965,
            17.055,
            -20.776,
            28.598,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4893",
        "polygon": [
            -22.701,
            29.322,
            17.203,
            -22.314,
            30.352,
            17.203,
            -20.63,
            29.719,
            17.134,
            -20.63,
            29.719,
            17.134,
            -21.017,
            28.689,
            17.134,
            -22.701,
            29.322,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4894",
        "polygon": [
            -24.623,
            30.045,
            17.282,
            -24.236,
            31.074,
            17.282,
            -22.552,
            30.441,
            17.213,
            -22.552,
            30.441,
            17.213,
            -22.939,
            29.412,
            17.213,
            -24.623,
            30.045,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4895",
        "polygon": [
            -26.543,
            30.767,
            17.361,
            -26.156,
            31.796,
            17.361,
            -24.472,
            31.163,
            17.292,
            -24.472,
            31.163,
            17.292,
            -24.859,
            30.134,
            17.292,
            -26.543,
            30.767,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4896",
        "polygon": [
            -19.33,
            26.61,
            17.045,
            -18.943,
            27.64,
            17.045,
            -17.259,
            27.007,
            16.976,
            -17.259,
            27.007,
            16.976,
            -17.646,
            25.977,
            16.976,
            -19.33,
            26.61,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4897",
        "polygon": [
            -21.252,
            27.333,
            17.124,
            -20.865,
            28.362,
            17.124,
            -19.181,
            27.729,
            17.055,
            -19.181,
            27.729,
            17.055,
            -19.568,
            26.7,
            17.055,
            -21.252,
            27.333,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4898",
        "polygon": [
            -23.177,
            28.056,
            17.203,
            -22.79,
            29.086,
            17.203,
            -21.106,
            28.453,
            17.134,
            -21.106,
            28.453,
            17.134,
            -21.493,
            27.423,
            17.134,
            -23.177,
            28.056,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4899",
        "polygon": [
            -25.099,
            28.779,
            17.282,
            -24.712,
            29.809,
            17.282,
            -23.028,
            29.176,
            17.213,
            -23.028,
            29.176,
            17.213,
            -23.415,
            28.146,
            17.213,
            -25.099,
            28.779,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4900",
        "polygon": [
            -27.019,
            29.501,
            17.361,
            -26.632,
            30.53,
            17.361,
            -24.948,
            29.897,
            17.292,
            -24.948,
            29.897,
            17.292,
            -25.335,
            28.868,
            17.292,
            -27.019,
            29.501,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4901",
        "polygon": [
            -19.806,
            25.344,
            17.045,
            -19.419,
            26.374,
            17.045,
            -17.735,
            25.741,
            16.976,
            -17.735,
            25.741,
            16.976,
            -18.122,
            24.711,
            16.976,
            -19.806,
            25.344,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4902",
        "polygon": [
            -21.728,
            26.067,
            17.124,
            -21.341,
            27.096,
            17.124,
            -19.657,
            26.463,
            17.055,
            -19.657,
            26.463,
            17.055,
            -20.044,
            25.434,
            17.055,
            -21.728,
            26.067,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4903",
        "polygon": [
            -23.653,
            26.79,
            17.203,
            -23.266,
            27.82,
            17.203,
            -21.582,
            27.187,
            17.134,
            -21.582,
            27.187,
            17.134,
            -21.969,
            26.157,
            17.134,
            -23.653,
            26.79,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4904",
        "polygon": [
            -25.575,
            27.513,
            17.282,
            -25.188,
            28.543,
            17.282,
            -23.504,
            27.91,
            17.213,
            -23.504,
            27.91,
            17.213,
            -23.891,
            26.88,
            17.213,
            -25.575,
            27.513,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4905",
        "polygon": [
            -27.495,
            28.235,
            17.361,
            -27.108,
            29.264,
            17.361,
            -25.424,
            28.631,
            17.292,
            -25.424,
            28.631,
            17.292,
            -25.811,
            27.602,
            17.292,
            -27.495,
            28.235,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4906",
        "polygon": [
            -20.282,
            24.078,
            17.045,
            -19.895,
            25.108,
            17.045,
            -18.211,
            24.475,
            16.976,
            -18.211,
            24.475,
            16.976,
            -18.598,
            23.445,
            16.976,
            -20.282,
            24.078,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4907",
        "polygon": [
            -22.204,
            24.801,
            17.124,
            -21.817,
            25.83,
            17.124,
            -20.133,
            25.197,
            17.055,
            -20.133,
            25.197,
            17.055,
            -20.52,
            24.168,
            17.055,
            -22.204,
            24.801,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4908",
        "polygon": [
            -24.129,
            25.525,
            17.203,
            -23.742,
            26.554,
            17.203,
            -22.058,
            25.921,
            17.134,
            -22.058,
            25.921,
            17.134,
            -22.445,
            24.892,
            17.134,
            -24.129,
            25.525,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4909",
        "polygon": [
            -26.051,
            26.247,
            17.282,
            -25.664,
            27.277,
            17.282,
            -23.98,
            26.644,
            17.213,
            -23.98,
            26.644,
            17.213,
            -24.367,
            25.614,
            17.213,
            -26.051,
            26.247,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4910",
        "polygon": [
            -27.971,
            26.969,
            17.361,
            -27.583,
            27.999,
            17.361,
            -25.9,
            27.366,
            17.292,
            -25.9,
            27.366,
            17.292,
            -26.287,
            26.336,
            17.292,
            -27.971,
            26.969,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4911",
        "polygon": [
            -20.758,
            22.812,
            17.045,
            -20.37,
            23.842,
            17.045,
            -18.687,
            23.209,
            16.976,
            -18.687,
            23.209,
            16.976,
            -19.074,
            22.179,
            16.976,
            -20.758,
            22.812,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4912",
        "polygon": [
            -22.68,
            23.535,
            17.124,
            -22.292,
            24.565,
            17.124,
            -20.609,
            23.932,
            17.055,
            -20.609,
            23.932,
            17.055,
            -20.996,
            22.902,
            17.055,
            -22.68,
            23.535,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4913",
        "polygon": [
            -24.605,
            24.259,
            17.203,
            -24.218,
            25.288,
            17.203,
            -22.534,
            24.655,
            17.134,
            -22.534,
            24.655,
            17.134,
            -22.921,
            23.626,
            17.134,
            -24.605,
            24.259,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4914",
        "polygon": [
            -26.527,
            24.981,
            17.282,
            -26.14,
            26.011,
            17.282,
            -24.456,
            25.378,
            17.213,
            -24.456,
            25.378,
            17.213,
            -24.843,
            24.348,
            17.213,
            -26.527,
            24.981,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4915",
        "polygon": [
            -28.446,
            25.703,
            17.361,
            -28.059,
            26.733,
            17.361,
            -26.376,
            26.1,
            17.292,
            -26.376,
            26.1,
            17.292,
            -26.763,
            25.07,
            17.292,
            -28.446,
            25.703,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4916",
        "polygon": [
            -21.233,
            21.546,
            17.045,
            -20.846,
            22.576,
            17.045,
            -19.163,
            21.943,
            16.976,
            -19.163,
            21.943,
            16.976,
            -19.55,
            20.913,
            16.976,
            -21.233,
            21.546,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4917",
        "polygon": [
            -23.156,
            22.269,
            17.124,
            -22.768,
            23.299,
            17.124,
            -21.085,
            22.666,
            17.055,
            -21.085,
            22.666,
            17.055,
            -21.472,
            21.636,
            17.055,
            -23.156,
            22.269,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4918",
        "polygon": [
            -25.081,
            22.993,
            17.203,
            -24.693,
            24.022,
            17.203,
            -23.01,
            23.389,
            17.134,
            -23.01,
            23.389,
            17.134,
            -23.397,
            22.36,
            17.134,
            -25.081,
            22.993,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4919",
        "polygon": [
            -27.003,
            23.715,
            17.282,
            -26.616,
            24.745,
            17.282,
            -24.932,
            24.112,
            17.213,
            -24.932,
            24.112,
            17.213,
            -25.319,
            23.082,
            17.213,
            -27.003,
            23.715,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4920",
        "polygon": [
            -28.922,
            24.437,
            17.361,
            -28.535,
            25.467,
            17.361,
            -26.852,
            24.834,
            17.292,
            -26.852,
            24.834,
            17.292,
            -27.239,
            23.804,
            17.292,
            -28.922,
            24.437,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4921",
        "polygon": [
            -21.709,
            20.28,
            17.045,
            -21.322,
            21.31,
            17.045,
            -19.639,
            20.677,
            16.976,
            -19.639,
            20.677,
            16.976,
            -20.026,
            19.648,
            16.976,
            -21.709,
            20.28,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4922",
        "polygon": [
            -23.631,
            21.003,
            17.124,
            -23.244,
            22.033,
            17.124,
            -21.561,
            21.4,
            17.055,
            -21.561,
            21.4,
            17.055,
            -21.948,
            20.37,
            17.055,
            -23.631,
            21.003,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4923",
        "polygon": [
            -25.556,
            21.727,
            17.203,
            -25.169,
            22.756,
            17.203,
            -23.486,
            22.124,
            17.134,
            -23.486,
            22.124,
            17.134,
            -23.873,
            21.094,
            17.134,
            -25.556,
            21.727,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4924",
        "polygon": [
            -27.479,
            22.449,
            17.282,
            -27.091,
            23.479,
            17.282,
            -25.408,
            22.846,
            17.213,
            -25.408,
            22.846,
            17.213,
            -25.795,
            21.816,
            17.213,
            -27.479,
            22.449,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4925",
        "polygon": [
            -29.398,
            23.171,
            17.361,
            -29.011,
            24.201,
            17.361,
            -27.328,
            23.568,
            17.292,
            -27.328,
            23.568,
            17.292,
            -27.715,
            22.538,
            17.292,
            -29.398,
            23.171,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4926",
        "polygon": [
            -26.804,
            36.566,
            17.291,
            -26.417,
            37.596,
            17.291,
            -24.733,
            36.963,
            17.361,
            -24.733,
            36.963,
            17.361,
            -25.12,
            35.933,
            17.361,
            -26.804,
            36.566,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4927",
        "polygon": [
            -26.646,
            37.708,
            17.282,
            -27.033,
            36.678,
            17.282,
            -28.717,
            37.311,
            17.213,
            -28.717,
            37.311,
            17.213,
            -28.33,
            38.341,
            17.213,
            -26.646,
            37.708,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4928",
        "polygon": [
            -28.571,
            38.432,
            17.203,
            -28.959,
            37.402,
            17.203,
            -30.642,
            38.035,
            17.134,
            -30.642,
            38.035,
            17.134,
            -30.255,
            39.065,
            17.134,
            -28.571,
            38.432,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4929",
        "polygon": [
            -30.493,
            39.154,
            17.124,
            -30.881,
            38.125,
            17.124,
            -32.564,
            38.758,
            17.055,
            -32.564,
            38.758,
            17.055,
            -32.177,
            39.787,
            17.055,
            -30.493,
            39.154,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4930",
        "polygon": [
            -32.413,
            39.876,
            17.045,
            -32.8,
            38.847,
            17.045,
            -34.484,
            39.48,
            16.976,
            -34.484,
            39.48,
            16.976,
            -34.097,
            40.509,
            16.976,
            -32.413,
            39.876,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4931",
        "polygon": [
            -27.271,
            35.323,
            17.291,
            -26.884,
            36.353,
            17.291,
            -25.2,
            35.72,
            17.361,
            -25.2,
            35.72,
            17.361,
            -25.587,
            34.69,
            17.361,
            -27.271,
            35.323,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4932",
        "polygon": [
            -27.122,
            36.442,
            17.282,
            -27.509,
            35.413,
            17.282,
            -29.193,
            36.046,
            17.213,
            -29.193,
            36.046,
            17.213,
            -28.806,
            37.075,
            17.213,
            -27.122,
            36.442,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4933",
        "polygon": [
            -29.047,
            37.166,
            17.203,
            -29.434,
            36.136,
            17.203,
            -31.118,
            36.769,
            17.134,
            -31.118,
            36.769,
            17.134,
            -30.731,
            37.799,
            17.134,
            -29.047,
            37.166,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4934",
        "polygon": [
            -30.969,
            37.889,
            17.124,
            -31.357,
            36.859,
            17.124,
            -33.04,
            37.492,
            17.055,
            -33.04,
            37.492,
            17.055,
            -32.653,
            38.521,
            17.055,
            -30.969,
            37.889,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4935",
        "polygon": [
            -32.889,
            38.61,
            17.045,
            -33.276,
            37.581,
            17.045,
            -34.96,
            38.214,
            16.976,
            -34.96,
            38.214,
            16.976,
            -34.573,
            39.243,
            16.976,
            -32.889,
            38.61,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4936",
        "polygon": [
            -27.747,
            34.057,
            17.291,
            -27.36,
            35.087,
            17.291,
            -25.676,
            34.454,
            17.361,
            -25.676,
            34.454,
            17.361,
            -26.063,
            33.424,
            17.361,
            -27.747,
            34.057,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4937",
        "polygon": [
            -27.598,
            35.176,
            17.282,
            -27.985,
            34.147,
            17.282,
            -29.669,
            34.78,
            17.213,
            -29.669,
            34.78,
            17.213,
            -29.282,
            35.809,
            17.213,
            -27.598,
            35.176,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4938",
        "polygon": [
            -29.523,
            35.9,
            17.203,
            -29.91,
            34.87,
            17.203,
            -31.594,
            35.503,
            17.134,
            -31.594,
            35.503,
            17.134,
            -31.207,
            36.533,
            17.134,
            -29.523,
            35.9,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4939",
        "polygon": [
            -31.445,
            36.623,
            17.124,
            -31.832,
            35.593,
            17.124,
            -33.516,
            36.226,
            17.055,
            -33.516,
            36.226,
            17.055,
            -33.129,
            37.256,
            17.055,
            -31.445,
            36.623,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4940",
        "polygon": [
            -33.365,
            37.344,
            17.045,
            -33.752,
            36.315,
            17.045,
            -35.436,
            36.948,
            16.976,
            -35.436,
            36.948,
            16.976,
            -35.049,
            37.977,
            16.976,
            -33.365,
            37.344,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4941",
        "polygon": [
            -28.223,
            32.791,
            17.291,
            -27.836,
            33.821,
            17.291,
            -26.152,
            33.188,
            17.361,
            -26.152,
            33.188,
            17.361,
            -26.539,
            32.158,
            17.361,
            -28.223,
            32.791,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4942",
        "polygon": [
            -28.074,
            33.91,
            17.282,
            -28.461,
            32.881,
            17.282,
            -30.145,
            33.514,
            17.213,
            -30.145,
            33.514,
            17.213,
            -29.758,
            34.543,
            17.213,
            -28.074,
            33.91,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4943",
        "polygon": [
            -29.999,
            34.634,
            17.203,
            -30.386,
            33.604,
            17.203,
            -32.07,
            34.237,
            17.134,
            -32.07,
            34.237,
            17.134,
            -31.683,
            35.267,
            17.134,
            -29.999,
            34.634,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4944",
        "polygon": [
            -31.921,
            35.357,
            17.124,
            -32.308,
            34.327,
            17.124,
            -33.992,
            34.96,
            17.055,
            -33.992,
            34.96,
            17.055,
            -33.605,
            35.99,
            17.055,
            -31.921,
            35.357,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4945",
        "polygon": [
            -33.841,
            36.078,
            17.045,
            -34.228,
            35.049,
            17.045,
            -35.912,
            35.682,
            16.976,
            -35.912,
            35.682,
            16.976,
            -35.525,
            36.711,
            16.976,
            -33.841,
            36.078,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4946",
        "polygon": [
            -28.699,
            31.525,
            17.291,
            -28.312,
            32.555,
            17.291,
            -26.628,
            31.922,
            17.361,
            -26.628,
            31.922,
            17.361,
            -27.015,
            30.892,
            17.361,
            -28.699,
            31.525,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4947",
        "polygon": [
            -28.55,
            32.644,
            17.282,
            -28.937,
            31.615,
            17.282,
            -30.621,
            32.248,
            17.213,
            -30.621,
            32.248,
            17.213,
            -30.234,
            33.277,
            17.213,
            -28.55,
            32.644,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4948",
        "polygon": [
            -30.475,
            33.368,
            17.203,
            -30.862,
            32.339,
            17.203,
            -32.546,
            32.972,
            17.134,
            -32.546,
            32.972,
            17.134,
            -32.159,
            34.001,
            17.134,
            -30.475,
            33.368,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4949",
        "polygon": [
            -32.397,
            34.091,
            17.124,
            -32.784,
            33.061,
            17.124,
            -34.468,
            33.694,
            17.055,
            -34.468,
            33.694,
            17.055,
            -34.081,
            34.724,
            17.055,
            -32.397,
            34.091,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4950",
        "polygon": [
            -34.317,
            34.813,
            17.045,
            -34.704,
            33.783,
            17.045,
            -36.388,
            34.416,
            16.976,
            -36.388,
            34.416,
            16.976,
            -36.001,
            35.446,
            16.976,
            -34.317,
            34.813,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4951",
        "polygon": [
            -29.175,
            30.259,
            17.291,
            -28.788,
            31.289,
            17.291,
            -27.104,
            30.656,
            17.361,
            -27.104,
            30.656,
            17.361,
            -27.491,
            29.626,
            17.361,
            -29.175,
            30.259,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4952",
        "polygon": [
            -29.026,
            31.379,
            17.282,
            -29.413,
            30.349,
            17.282,
            -31.097,
            30.982,
            17.213,
            -31.097,
            30.982,
            17.213,
            -30.71,
            32.012,
            17.213,
            -29.026,
            31.379,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4953",
        "polygon": [
            -30.951,
            32.102,
            17.203,
            -31.338,
            31.073,
            17.203,
            -33.022,
            31.706,
            17.134,
            -33.022,
            31.706,
            17.134,
            -32.635,
            32.735,
            17.134,
            -30.951,
            32.102,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4954",
        "polygon": [
            -32.873,
            32.825,
            17.124,
            -33.26,
            31.795,
            17.124,
            -34.944,
            32.428,
            17.055,
            -34.944,
            32.428,
            17.055,
            -34.557,
            33.458,
            17.055,
            -32.873,
            32.825,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4955",
        "polygon": [
            -34.793,
            33.547,
            17.045,
            -35.18,
            32.517,
            17.045,
            -36.864,
            33.15,
            16.976,
            -36.864,
            33.15,
            16.976,
            -36.476,
            34.18,
            16.976,
            -34.793,
            33.547,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4956",
        "polygon": [
            -29.651,
            28.993,
            17.291,
            -29.264,
            30.023,
            17.291,
            -27.58,
            29.39,
            17.361,
            -27.58,
            29.39,
            17.361,
            -27.967,
            28.36,
            17.361,
            -29.651,
            28.993,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4957",
        "polygon": [
            -29.502,
            30.113,
            17.282,
            -29.889,
            29.083,
            17.282,
            -31.573,
            29.716,
            17.213,
            -31.573,
            29.716,
            17.213,
            -31.186,
            30.746,
            17.213,
            -29.502,
            30.113,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4958",
        "polygon": [
            -31.427,
            30.836,
            17.203,
            -31.814,
            29.807,
            17.203,
            -33.498,
            30.44,
            17.134,
            -33.498,
            30.44,
            17.134,
            -33.111,
            31.469,
            17.134,
            -31.427,
            30.836,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4959",
        "polygon": [
            -33.349,
            31.559,
            17.124,
            -33.736,
            30.529,
            17.124,
            -35.42,
            31.162,
            17.055,
            -35.42,
            31.162,
            17.055,
            -35.033,
            32.192,
            17.055,
            -33.349,
            31.559,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4960",
        "polygon": [
            -35.269,
            32.281,
            17.045,
            -35.656,
            31.251,
            17.045,
            -37.34,
            31.884,
            16.976,
            -37.34,
            31.884,
            16.976,
            -36.952,
            32.914,
            16.976,
            -35.269,
            32.281,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4961",
        "polygon": [
            -30.127,
            27.728,
            17.291,
            -29.739,
            28.757,
            17.291,
            -28.056,
            28.124,
            17.361,
            -28.056,
            28.124,
            17.361,
            -28.443,
            27.095,
            17.361,
            -30.127,
            27.728,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4962",
        "polygon": [
            -29.978,
            28.847,
            17.282,
            -30.365,
            27.817,
            17.282,
            -32.049,
            28.45,
            17.213,
            -32.049,
            28.45,
            17.213,
            -31.661,
            29.48,
            17.213,
            -29.978,
            28.847,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4963",
        "polygon": [
            -31.903,
            29.571,
            17.203,
            -32.29,
            28.541,
            17.203,
            -33.974,
            29.174,
            17.134,
            -33.974,
            29.174,
            17.134,
            -33.587,
            30.204,
            17.134,
            -31.903,
            29.571,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4964",
        "polygon": [
            -33.825,
            30.293,
            17.124,
            -34.212,
            29.264,
            17.124,
            -35.896,
            29.897,
            17.055,
            -35.896,
            29.897,
            17.055,
            -35.509,
            30.926,
            17.055,
            -33.825,
            30.293,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4965",
        "polygon": [
            -35.745,
            31.015,
            17.045,
            -36.132,
            29.985,
            17.045,
            -37.815,
            30.618,
            16.976,
            -37.815,
            30.618,
            16.976,
            -37.428,
            31.648,
            16.976,
            -35.745,
            31.015,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4966",
        "polygon": [
            -30.602,
            26.462,
            17.291,
            -30.215,
            27.491,
            17.291,
            -28.532,
            26.858,
            17.361,
            -28.532,
            26.858,
            17.361,
            -28.919,
            25.829,
            17.361,
            -30.602,
            26.462,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4967",
        "polygon": [
            -30.454,
            27.581,
            17.282,
            -30.841,
            26.551,
            17.282,
            -32.525,
            27.184,
            17.213,
            -32.525,
            27.184,
            17.213,
            -32.137,
            28.214,
            17.213,
            -30.454,
            27.581,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4968",
        "polygon": [
            -32.379,
            28.305,
            17.203,
            -32.766,
            27.275,
            17.203,
            -34.45,
            27.908,
            17.134,
            -34.45,
            27.908,
            17.134,
            -34.062,
            28.938,
            17.134,
            -32.379,
            28.305,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4969",
        "polygon": [
            -34.301,
            29.027,
            17.124,
            -34.688,
            27.998,
            17.124,
            -36.372,
            28.631,
            17.055,
            -36.372,
            28.631,
            17.055,
            -35.985,
            29.66,
            17.055,
            -34.301,
            29.027,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4970",
        "polygon": [
            -36.221,
            29.749,
            17.045,
            -36.608,
            28.719,
            17.045,
            -38.291,
            29.352,
            16.976,
            -38.291,
            29.352,
            16.976,
            -37.904,
            30.382,
            16.976,
            -36.221,
            29.749,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4971",
        "polygon": [
            -31.078,
            25.196,
            17.291,
            -30.691,
            26.225,
            17.291,
            -29.008,
            25.592,
            17.361,
            -29.008,
            25.592,
            17.361,
            -29.395,
            24.563,
            17.361,
            -31.078,
            25.196,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4972",
        "polygon": [
            -30.93,
            26.315,
            17.282,
            -31.317,
            25.285,
            17.282,
            -33.0,
            25.918,
            17.213,
            -33.0,
            25.918,
            17.213,
            -32.613,
            26.948,
            17.213,
            -30.93,
            26.315,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4973",
        "polygon": [
            -32.855,
            27.039,
            17.203,
            -33.242,
            26.009,
            17.203,
            -34.925,
            26.642,
            17.134,
            -34.925,
            26.642,
            17.134,
            -34.538,
            27.672,
            17.134,
            -32.855,
            27.039,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4974",
        "polygon": [
            -34.777,
            27.761,
            17.124,
            -35.164,
            26.732,
            17.124,
            -36.848,
            27.365,
            17.055,
            -36.848,
            27.365,
            17.055,
            -36.46,
            28.394,
            17.055,
            -34.777,
            27.761,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4975",
        "polygon": [
            -36.697,
            28.483,
            17.045,
            -37.084,
            27.454,
            17.045,
            -38.767,
            28.086,
            16.976,
            -38.767,
            28.086,
            16.976,
            -38.38,
            29.116,
            16.976,
            -36.697,
            28.483,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4976",
        "polygon": [
            -31.554,
            23.93,
            17.291,
            -31.167,
            24.96,
            17.291,
            -29.484,
            24.327,
            17.361,
            -29.484,
            24.327,
            17.361,
            -29.871,
            23.297,
            17.361,
            -31.554,
            23.93,
            17.291
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4977",
        "polygon": [
            -31.406,
            25.049,
            17.282,
            -31.793,
            24.02,
            17.282,
            -33.476,
            24.652,
            17.213,
            -33.476,
            24.652,
            17.213,
            -33.089,
            25.682,
            17.213,
            -31.406,
            25.049,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4978",
        "polygon": [
            -33.331,
            25.773,
            17.203,
            -33.718,
            24.743,
            17.203,
            -35.401,
            25.376,
            17.134,
            -35.401,
            25.376,
            17.134,
            -35.014,
            26.406,
            17.134,
            -33.331,
            25.773,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4979",
        "polygon": [
            -35.253,
            26.495,
            17.124,
            -35.64,
            25.466,
            17.124,
            -37.323,
            26.099,
            17.055,
            -37.323,
            26.099,
            17.055,
            -36.936,
            27.128,
            17.055,
            -35.253,
            26.495,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4980",
        "polygon": [
            -37.172,
            27.217,
            17.045,
            -37.56,
            26.188,
            17.045,
            -39.243,
            26.821,
            16.976,
            -39.243,
            26.821,
            16.976,
            -38.856,
            27.85,
            16.976,
            -37.172,
            27.217,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4981",
        "polygon": [
            -48.135,
            53.42,
            17.045,
            -47.748,
            54.45,
            17.045,
            -46.064,
            53.817,
            16.976,
            -46.064,
            53.817,
            16.976,
            -46.451,
            52.787,
            16.976,
            -48.135,
            53.42,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4982",
        "polygon": [
            -50.072,
            54.103,
            17.124,
            -49.685,
            55.133,
            17.124,
            -48.001,
            54.5,
            17.055,
            -48.001,
            54.5,
            17.055,
            -48.388,
            53.471,
            17.055,
            -50.072,
            54.103,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4983",
        "polygon": [
            -51.997,
            54.827,
            17.203,
            -51.61,
            55.857,
            17.203,
            -49.926,
            55.224,
            17.134,
            -49.926,
            55.224,
            17.134,
            -50.313,
            54.194,
            17.134,
            -51.997,
            54.827,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4984",
        "polygon": [
            -53.919,
            55.55,
            17.282,
            -53.532,
            56.579,
            17.282,
            -51.848,
            55.947,
            17.213,
            -51.848,
            55.947,
            17.213,
            -52.235,
            54.917,
            17.213,
            -53.919,
            55.55,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4985",
        "polygon": [
            -55.839,
            56.272,
            17.361,
            -55.452,
            57.301,
            17.361,
            -53.768,
            56.668,
            17.291,
            -53.768,
            56.668,
            17.291,
            -54.155,
            55.639,
            17.291,
            -55.839,
            56.272,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4986",
        "polygon": [
            -48.626,
            52.115,
            17.045,
            -48.239,
            53.145,
            17.045,
            -46.555,
            52.512,
            16.976,
            -46.555,
            52.512,
            16.976,
            -46.942,
            51.482,
            16.976,
            -48.626,
            52.115,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4987",
        "polygon": [
            -50.548,
            52.838,
            17.124,
            -50.161,
            53.867,
            17.124,
            -48.477,
            53.234,
            17.055,
            -48.477,
            53.234,
            17.055,
            -48.864,
            52.205,
            17.055,
            -50.548,
            52.838,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4988",
        "polygon": [
            -52.473,
            53.561,
            17.203,
            -52.086,
            54.591,
            17.203,
            -50.402,
            53.958,
            17.134,
            -50.402,
            53.958,
            17.134,
            -50.789,
            52.928,
            17.134,
            -52.473,
            53.561,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4989",
        "polygon": [
            -54.395,
            54.284,
            17.282,
            -54.008,
            55.314,
            17.282,
            -52.324,
            54.681,
            17.213,
            -52.324,
            54.681,
            17.213,
            -52.711,
            53.651,
            17.213,
            -54.395,
            54.284,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4990",
        "polygon": [
            -56.315,
            55.006,
            17.361,
            -55.928,
            56.035,
            17.361,
            -54.244,
            55.402,
            17.291,
            -54.244,
            55.402,
            17.291,
            -54.631,
            54.373,
            17.291,
            -56.315,
            55.006,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4991",
        "polygon": [
            -49.102,
            50.849,
            17.045,
            -48.715,
            51.879,
            17.045,
            -47.031,
            51.246,
            16.976,
            -47.031,
            51.246,
            16.976,
            -47.418,
            50.216,
            16.976,
            -49.102,
            50.849,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4992",
        "polygon": [
            -51.024,
            51.572,
            17.124,
            -50.637,
            52.601,
            17.124,
            -48.953,
            51.968,
            17.055,
            -48.953,
            51.968,
            17.055,
            -49.34,
            50.939,
            17.055,
            -51.024,
            51.572,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4993",
        "polygon": [
            -52.949,
            52.295,
            17.203,
            -52.562,
            53.325,
            17.203,
            -50.878,
            52.692,
            17.134,
            -50.878,
            52.692,
            17.134,
            -51.265,
            51.662,
            17.134,
            -52.949,
            52.295,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4994",
        "polygon": [
            -54.871,
            53.018,
            17.282,
            -54.484,
            54.048,
            17.282,
            -52.8,
            53.415,
            17.213,
            -52.8,
            53.415,
            17.213,
            -53.187,
            52.385,
            17.213,
            -54.871,
            53.018,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4995",
        "polygon": [
            -56.791,
            53.74,
            17.361,
            -56.404,
            54.769,
            17.361,
            -54.72,
            54.136,
            17.291,
            -54.72,
            54.136,
            17.291,
            -55.107,
            53.107,
            17.291,
            -56.791,
            53.74,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4996",
        "polygon": [
            -49.578,
            49.583,
            17.045,
            -49.191,
            50.613,
            17.045,
            -47.507,
            49.98,
            16.976,
            -47.507,
            49.98,
            16.976,
            -47.894,
            48.95,
            16.976,
            -49.578,
            49.583,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4997",
        "polygon": [
            -51.5,
            50.306,
            17.124,
            -51.113,
            51.335,
            17.124,
            -49.429,
            50.702,
            17.055,
            -49.429,
            50.702,
            17.055,
            -49.816,
            49.673,
            17.055,
            -51.5,
            50.306,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4998",
        "polygon": [
            -53.425,
            51.03,
            17.203,
            -53.038,
            52.059,
            17.203,
            -51.354,
            51.426,
            17.134,
            -51.354,
            51.426,
            17.134,
            -51.741,
            50.397,
            17.134,
            -53.425,
            51.03,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_4999",
        "polygon": [
            -55.347,
            51.752,
            17.282,
            -54.96,
            52.782,
            17.282,
            -53.276,
            52.149,
            17.213,
            -53.276,
            52.149,
            17.213,
            -53.663,
            51.119,
            17.213,
            -55.347,
            51.752,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5000",
        "polygon": [
            -57.267,
            52.474,
            17.361,
            -56.879,
            53.504,
            17.361,
            -55.196,
            52.871,
            17.291,
            -55.196,
            52.871,
            17.291,
            -55.583,
            51.841,
            17.291,
            -57.267,
            52.474,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5001",
        "polygon": [
            -50.054,
            48.317,
            17.045,
            -49.666,
            49.347,
            17.045,
            -47.983,
            48.714,
            16.976,
            -47.983,
            48.714,
            16.976,
            -48.37,
            47.684,
            16.976,
            -50.054,
            48.317,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5002",
        "polygon": [
            -51.976,
            49.04,
            17.124,
            -51.589,
            50.07,
            17.124,
            -49.905,
            49.437,
            17.055,
            -49.905,
            49.437,
            17.055,
            -50.292,
            48.407,
            17.055,
            -51.976,
            49.04,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5003",
        "polygon": [
            -53.901,
            49.764,
            17.203,
            -53.514,
            50.793,
            17.203,
            -51.83,
            50.16,
            17.134,
            -51.83,
            50.16,
            17.134,
            -52.217,
            49.131,
            17.134,
            -53.901,
            49.764,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5004",
        "polygon": [
            -55.823,
            50.486,
            17.282,
            -55.436,
            51.516,
            17.282,
            -53.752,
            50.883,
            17.213,
            -53.752,
            50.883,
            17.213,
            -54.139,
            49.853,
            17.213,
            -55.823,
            50.486,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5005",
        "polygon": [
            -57.742,
            51.208,
            17.361,
            -57.355,
            52.238,
            17.361,
            -55.672,
            51.605,
            17.291,
            -55.672,
            51.605,
            17.291,
            -56.059,
            50.575,
            17.291,
            -57.742,
            51.208,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5006",
        "polygon": [
            -50.529,
            47.051,
            17.045,
            -50.142,
            48.081,
            17.045,
            -48.459,
            47.448,
            16.976,
            -48.459,
            47.448,
            16.976,
            -48.846,
            46.418,
            16.976,
            -50.529,
            47.051,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5007",
        "polygon": [
            -52.452,
            47.774,
            17.124,
            -52.064,
            48.804,
            17.124,
            -50.381,
            48.171,
            17.055,
            -50.381,
            48.171,
            17.055,
            -50.768,
            47.141,
            17.055,
            -52.452,
            47.774,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5008",
        "polygon": [
            -54.377,
            48.498,
            17.203,
            -53.989,
            49.527,
            17.203,
            -52.306,
            48.894,
            17.134,
            -52.306,
            48.894,
            17.134,
            -52.693,
            47.865,
            17.134,
            -54.377,
            48.498,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5009",
        "polygon": [
            -56.299,
            49.22,
            17.282,
            -55.912,
            50.25,
            17.282,
            -54.228,
            49.617,
            17.213,
            -54.228,
            49.617,
            17.213,
            -54.615,
            48.587,
            17.213,
            -56.299,
            49.22,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5010",
        "polygon": [
            -58.218,
            49.942,
            17.361,
            -57.831,
            50.972,
            17.361,
            -56.148,
            50.339,
            17.291,
            -56.148,
            50.339,
            17.291,
            -56.535,
            49.309,
            17.291,
            -58.218,
            49.942,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5011",
        "polygon": [
            -51.005,
            45.786,
            17.045,
            -50.618,
            46.815,
            17.045,
            -48.935,
            46.182,
            16.976,
            -48.935,
            46.182,
            16.976,
            -49.322,
            45.153,
            16.976,
            -51.005,
            45.786,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5012",
        "polygon": [
            -52.927,
            46.508,
            17.124,
            -52.54,
            47.538,
            17.124,
            -50.857,
            46.905,
            17.055,
            -50.857,
            46.905,
            17.055,
            -51.244,
            45.875,
            17.055,
            -52.927,
            46.508,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5013",
        "polygon": [
            -54.853,
            47.232,
            17.203,
            -54.465,
            48.262,
            17.203,
            -52.782,
            47.629,
            17.134,
            -52.782,
            47.629,
            17.134,
            -53.169,
            46.599,
            17.134,
            -54.853,
            47.232,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5014",
        "polygon": [
            -56.775,
            47.955,
            17.282,
            -56.387,
            48.984,
            17.282,
            -54.704,
            48.351,
            17.213,
            -54.704,
            48.351,
            17.213,
            -55.091,
            47.322,
            17.213,
            -56.775,
            47.955,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5015",
        "polygon": [
            -58.694,
            48.676,
            17.361,
            -58.307,
            49.706,
            17.361,
            -56.624,
            49.073,
            17.291,
            -56.624,
            49.073,
            17.291,
            -57.011,
            48.043,
            17.291,
            -58.694,
            48.676,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5016",
        "polygon": [
            -51.481,
            44.52,
            17.045,
            -51.094,
            45.549,
            17.045,
            -49.411,
            44.916,
            16.976,
            -49.411,
            44.916,
            16.976,
            -49.798,
            43.887,
            16.976,
            -51.481,
            44.52,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5017",
        "polygon": [
            -53.403,
            45.242,
            17.124,
            -53.016,
            46.272,
            17.124,
            -51.333,
            45.639,
            17.055,
            -51.333,
            45.639,
            17.055,
            -51.72,
            44.609,
            17.055,
            -53.403,
            45.242,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5018",
        "polygon": [
            -55.328,
            45.966,
            17.203,
            -54.941,
            46.996,
            17.203,
            -53.258,
            46.363,
            17.134,
            -53.258,
            46.363,
            17.134,
            -53.645,
            45.333,
            17.134,
            -55.328,
            45.966,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5019",
        "polygon": [
            -57.25,
            46.689,
            17.282,
            -56.863,
            47.718,
            17.282,
            -55.18,
            47.085,
            17.213,
            -55.18,
            47.085,
            17.213,
            -55.567,
            46.056,
            17.213,
            -57.25,
            46.689,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5020",
        "polygon": [
            -59.17,
            47.41,
            17.361,
            -58.783,
            48.44,
            17.361,
            -57.1,
            47.807,
            17.291,
            -57.1,
            47.807,
            17.291,
            -57.487,
            46.777,
            17.291,
            -59.17,
            47.41,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5021",
        "polygon": [
            -51.957,
            43.254,
            17.045,
            -51.57,
            44.283,
            17.045,
            -49.887,
            43.65,
            16.976,
            -49.887,
            43.65,
            16.976,
            -50.274,
            42.621,
            16.976,
            -51.957,
            43.254,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5022",
        "polygon": [
            -53.879,
            43.976,
            17.124,
            -53.492,
            45.006,
            17.124,
            -51.809,
            44.373,
            17.055,
            -51.809,
            44.373,
            17.055,
            -52.196,
            43.343,
            17.055,
            -53.879,
            43.976,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5023",
        "polygon": [
            -55.804,
            44.7,
            17.203,
            -55.417,
            45.73,
            17.203,
            -53.734,
            45.097,
            17.134,
            -53.734,
            45.097,
            17.134,
            -54.121,
            44.067,
            17.134,
            -55.804,
            44.7,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5024",
        "polygon": [
            -57.726,
            45.423,
            17.282,
            -57.339,
            46.452,
            17.282,
            -55.656,
            45.819,
            17.213,
            -55.656,
            45.819,
            17.213,
            -56.043,
            44.79,
            17.213,
            -57.726,
            45.423,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5025",
        "polygon": [
            -59.646,
            46.144,
            17.361,
            -59.259,
            47.174,
            17.361,
            -57.575,
            46.541,
            17.291,
            -57.575,
            46.541,
            17.291,
            -57.963,
            45.511,
            17.291,
            -59.646,
            46.144,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5026",
        "polygon": [
            -52.433,
            41.988,
            17.045,
            -52.046,
            43.017,
            17.045,
            -50.362,
            42.385,
            16.976,
            -50.362,
            42.385,
            16.976,
            -50.75,
            41.355,
            16.976,
            -52.433,
            41.988,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5027",
        "polygon": [
            -54.355,
            42.71,
            17.124,
            -53.968,
            43.74,
            17.124,
            -52.285,
            43.107,
            17.055,
            -52.285,
            43.107,
            17.055,
            -52.672,
            42.077,
            17.055,
            -54.355,
            42.71,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5028",
        "polygon": [
            -56.28,
            43.434,
            17.203,
            -55.893,
            44.464,
            17.203,
            -54.21,
            43.831,
            17.134,
            -54.21,
            43.831,
            17.134,
            -54.597,
            42.801,
            17.134,
            -56.28,
            43.434,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5029",
        "polygon": [
            -58.202,
            44.157,
            17.282,
            -57.815,
            45.186,
            17.282,
            -56.132,
            44.553,
            17.213,
            -56.132,
            44.553,
            17.213,
            -56.519,
            43.524,
            17.213,
            -58.202,
            44.157,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5030",
        "polygon": [
            -60.122,
            44.879,
            17.361,
            -59.735,
            45.908,
            17.361,
            -58.051,
            45.275,
            17.291,
            -58.051,
            45.275,
            17.291,
            -58.438,
            44.246,
            17.291,
            -60.122,
            44.879,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5031",
        "polygon": [
            -52.909,
            40.722,
            17.045,
            -52.522,
            41.752,
            17.045,
            -50.838,
            41.119,
            16.976,
            -50.838,
            41.119,
            16.976,
            -51.225,
            40.089,
            16.976,
            -52.909,
            40.722,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5032",
        "polygon": [
            -54.831,
            41.445,
            17.124,
            -54.444,
            42.474,
            17.124,
            -52.76,
            41.841,
            17.055,
            -52.76,
            41.841,
            17.055,
            -53.148,
            40.812,
            17.055,
            -54.831,
            41.445,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5033",
        "polygon": [
            -56.756,
            42.168,
            17.203,
            -56.369,
            43.198,
            17.203,
            -54.685,
            42.565,
            17.134,
            -54.685,
            42.565,
            17.134,
            -55.073,
            41.535,
            17.134,
            -56.756,
            42.168,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5034",
        "polygon": [
            -58.678,
            42.891,
            17.282,
            -58.291,
            43.921,
            17.282,
            -56.608,
            43.288,
            17.213,
            -56.608,
            43.288,
            17.213,
            -56.995,
            42.258,
            17.213,
            -58.678,
            42.891,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5035",
        "polygon": [
            -60.598,
            43.613,
            17.361,
            -60.211,
            44.642,
            17.361,
            -58.527,
            44.009,
            17.291,
            -58.527,
            44.009,
            17.291,
            -58.914,
            42.98,
            17.291,
            -60.598,
            43.613,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5036",
        "polygon": [
            -57.969,
            57.098,
            17.292,
            -57.582,
            58.127,
            17.292,
            -55.899,
            57.494,
            17.361,
            -55.899,
            57.494,
            17.361,
            -56.286,
            56.465,
            17.361,
            -57.969,
            57.098,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5037",
        "polygon": [
            -57.814,
            58.234,
            17.282,
            -58.201,
            57.205,
            17.282,
            -59.885,
            57.838,
            17.213,
            -59.885,
            57.838,
            17.213,
            -59.498,
            58.867,
            17.213,
            -57.814,
            58.234,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5038",
        "polygon": [
            -59.739,
            58.958,
            17.203,
            -60.126,
            57.928,
            17.203,
            -61.81,
            58.561,
            17.134,
            -61.81,
            58.561,
            17.134,
            -61.423,
            59.591,
            17.134,
            -59.739,
            58.958,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5039",
        "polygon": [
            -61.661,
            59.681,
            17.124,
            -62.048,
            58.651,
            17.124,
            -63.732,
            59.284,
            17.055,
            -63.732,
            59.284,
            17.055,
            -63.345,
            60.314,
            17.055,
            -61.661,
            59.681,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5040",
        "polygon": [
            -63.581,
            60.402,
            17.045,
            -63.968,
            59.373,
            17.045,
            -65.652,
            60.006,
            16.976,
            -65.652,
            60.006,
            16.976,
            -65.265,
            61.035,
            16.976,
            -63.581,
            60.402,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5041",
        "polygon": [
            -58.439,
            55.849,
            17.292,
            -58.052,
            56.879,
            17.292,
            -56.368,
            56.246,
            17.361,
            -56.368,
            56.246,
            17.361,
            -56.755,
            55.216,
            17.361,
            -58.439,
            55.849,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5042",
        "polygon": [
            -58.29,
            56.968,
            17.282,
            -58.677,
            55.939,
            17.282,
            -60.361,
            56.572,
            17.213,
            -60.361,
            56.572,
            17.213,
            -59.974,
            57.601,
            17.213,
            -58.29,
            56.968,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5043",
        "polygon": [
            -60.215,
            57.692,
            17.203,
            -60.602,
            56.663,
            17.203,
            -62.286,
            57.296,
            17.134,
            -62.286,
            57.296,
            17.134,
            -61.899,
            58.325,
            17.134,
            -60.215,
            57.692,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5044",
        "polygon": [
            -62.137,
            58.415,
            17.124,
            -62.524,
            57.385,
            17.124,
            -64.208,
            58.018,
            17.055,
            -64.208,
            58.018,
            17.055,
            -63.821,
            59.048,
            17.055,
            -62.137,
            58.415,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5045",
        "polygon": [
            -64.057,
            59.137,
            17.045,
            -64.444,
            58.107,
            17.045,
            -66.128,
            58.74,
            16.976,
            -66.128,
            58.74,
            16.976,
            -65.741,
            59.77,
            16.976,
            -64.057,
            59.137,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5046",
        "polygon": [
            -58.915,
            54.583,
            17.292,
            -58.528,
            55.613,
            17.292,
            -56.844,
            54.98,
            17.361,
            -56.844,
            54.98,
            17.361,
            -57.231,
            53.95,
            17.361,
            -58.915,
            54.583,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5047",
        "polygon": [
            -58.766,
            55.703,
            17.282,
            -59.153,
            54.673,
            17.282,
            -60.837,
            55.306,
            17.213,
            -60.837,
            55.306,
            17.213,
            -60.45,
            56.336,
            17.213,
            -58.766,
            55.703,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5048",
        "polygon": [
            -60.691,
            56.426,
            17.203,
            -61.078,
            55.397,
            17.203,
            -62.762,
            56.03,
            17.134,
            -62.762,
            56.03,
            17.134,
            -62.375,
            57.059,
            17.134,
            -60.691,
            56.426,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5049",
        "polygon": [
            -62.613,
            57.149,
            17.124,
            -63.0,
            56.119,
            17.124,
            -64.684,
            56.752,
            17.055,
            -64.684,
            56.752,
            17.055,
            -64.297,
            57.782,
            17.055,
            -62.613,
            57.149,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5050",
        "polygon": [
            -64.533,
            57.871,
            17.045,
            -64.92,
            56.841,
            17.045,
            -66.604,
            57.474,
            16.976,
            -66.604,
            57.474,
            16.976,
            -66.217,
            58.504,
            16.976,
            -64.533,
            57.871,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5051",
        "polygon": [
            -59.391,
            53.317,
            17.292,
            -59.004,
            54.347,
            17.292,
            -57.32,
            53.714,
            17.361,
            -57.32,
            53.714,
            17.361,
            -57.707,
            52.684,
            17.361,
            -59.391,
            53.317,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5052",
        "polygon": [
            -59.242,
            54.437,
            17.282,
            -59.629,
            53.407,
            17.282,
            -61.313,
            54.04,
            17.213,
            -61.313,
            54.04,
            17.213,
            -60.926,
            55.07,
            17.213,
            -59.242,
            54.437,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5053",
        "polygon": [
            -61.167,
            55.16,
            17.203,
            -61.554,
            54.131,
            17.203,
            -63.238,
            54.764,
            17.134,
            -63.238,
            54.764,
            17.134,
            -62.851,
            55.793,
            17.134,
            -61.167,
            55.16,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5054",
        "polygon": [
            -63.089,
            55.883,
            17.124,
            -63.476,
            54.853,
            17.124,
            -65.16,
            55.486,
            17.055,
            -65.16,
            55.486,
            17.055,
            -64.773,
            56.516,
            17.055,
            -63.089,
            55.883,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5055",
        "polygon": [
            -65.009,
            56.605,
            17.045,
            -65.396,
            55.575,
            17.045,
            -67.08,
            56.208,
            16.976,
            -67.08,
            56.208,
            16.976,
            -66.692,
            57.238,
            16.976,
            -65.009,
            56.605,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5056",
        "polygon": [
            -59.867,
            52.052,
            17.292,
            -59.479,
            53.081,
            17.292,
            -57.796,
            52.448,
            17.361,
            -57.796,
            52.448,
            17.361,
            -58.183,
            51.419,
            17.361,
            -59.867,
            52.052,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5057",
        "polygon": [
            -59.718,
            53.171,
            17.282,
            -60.105,
            52.141,
            17.282,
            -61.789,
            52.774,
            17.213,
            -61.789,
            52.774,
            17.213,
            -61.402,
            53.804,
            17.213,
            -59.718,
            53.171,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5058",
        "polygon": [
            -61.643,
            53.895,
            17.203,
            -62.03,
            52.865,
            17.203,
            -63.714,
            53.498,
            17.134,
            -63.714,
            53.498,
            17.134,
            -63.327,
            54.527,
            17.134,
            -61.643,
            53.895,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5059",
        "polygon": [
            -63.565,
            54.617,
            17.124,
            -63.952,
            53.587,
            17.124,
            -65.636,
            54.22,
            17.055,
            -65.636,
            54.22,
            17.055,
            -65.249,
            55.25,
            17.055,
            -63.565,
            54.617,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5060",
        "polygon": [
            -65.485,
            55.339,
            17.045,
            -65.872,
            54.309,
            17.045,
            -67.556,
            54.942,
            16.976,
            -67.556,
            54.942,
            16.976,
            -67.168,
            55.972,
            16.976,
            -65.485,
            55.339,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5061",
        "polygon": [
            -60.343,
            50.786,
            17.292,
            -59.955,
            51.815,
            17.292,
            -58.272,
            51.182,
            17.361,
            -58.272,
            51.182,
            17.361,
            -58.659,
            50.153,
            17.361,
            -60.343,
            50.786,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5062",
        "polygon": [
            -60.194,
            51.905,
            17.282,
            -60.581,
            50.875,
            17.282,
            -62.265,
            51.508,
            17.213,
            -62.265,
            51.508,
            17.213,
            -61.877,
            52.538,
            17.213,
            -60.194,
            51.905,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5063",
        "polygon": [
            -62.119,
            52.629,
            17.203,
            -62.506,
            51.599,
            17.203,
            -64.19,
            52.232,
            17.134,
            -64.19,
            52.232,
            17.134,
            -63.803,
            53.262,
            17.134,
            -62.119,
            52.629,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5064",
        "polygon": [
            -64.041,
            53.351,
            17.124,
            -64.428,
            52.322,
            17.124,
            -66.112,
            52.955,
            17.055,
            -66.112,
            52.955,
            17.055,
            -65.725,
            53.984,
            17.055,
            -64.041,
            53.351,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5065",
        "polygon": [
            -65.961,
            54.073,
            17.045,
            -66.348,
            53.043,
            17.045,
            -68.031,
            53.676,
            16.976,
            -68.031,
            53.676,
            16.976,
            -67.644,
            54.706,
            16.976,
            -65.961,
            54.073,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5066",
        "polygon": [
            -60.818,
            49.52,
            17.292,
            -60.431,
            50.549,
            17.292,
            -58.748,
            49.916,
            17.361,
            -58.748,
            49.916,
            17.361,
            -59.135,
            48.887,
            17.361,
            -60.818,
            49.52,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5067",
        "polygon": [
            -60.67,
            50.639,
            17.282,
            -61.057,
            49.609,
            17.282,
            -62.74,
            50.242,
            17.213,
            -62.74,
            50.242,
            17.213,
            -62.353,
            51.272,
            17.213,
            -60.67,
            50.639,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5068",
        "polygon": [
            -62.595,
            51.363,
            17.203,
            -62.982,
            50.333,
            17.203,
            -64.666,
            50.966,
            17.134,
            -64.666,
            50.966,
            17.134,
            -64.278,
            51.996,
            17.134,
            -62.595,
            51.363,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5069",
        "polygon": [
            -64.517,
            52.085,
            17.124,
            -64.904,
            51.056,
            17.124,
            -66.588,
            51.689,
            17.055,
            -66.588,
            51.689,
            17.055,
            -66.2,
            52.718,
            17.055,
            -64.517,
            52.085,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5070",
        "polygon": [
            -66.437,
            52.807,
            17.045,
            -66.824,
            51.777,
            17.045,
            -68.507,
            52.41,
            16.976,
            -68.507,
            52.41,
            16.976,
            -68.12,
            53.44,
            16.976,
            -66.437,
            52.807,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5071",
        "polygon": [
            -61.294,
            48.254,
            17.292,
            -60.907,
            49.283,
            17.292,
            -59.224,
            48.65,
            17.361,
            -59.224,
            48.65,
            17.361,
            -59.611,
            47.621,
            17.361,
            -61.294,
            48.254,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5072",
        "polygon": [
            -61.146,
            49.373,
            17.282,
            -61.533,
            48.343,
            17.282,
            -63.216,
            48.976,
            17.213,
            -63.216,
            48.976,
            17.213,
            -62.829,
            50.006,
            17.213,
            -61.146,
            49.373,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5073",
        "polygon": [
            -63.071,
            50.097,
            17.203,
            -63.458,
            49.067,
            17.203,
            -65.141,
            49.7,
            17.134,
            -65.141,
            49.7,
            17.134,
            -64.754,
            50.73,
            17.134,
            -63.071,
            50.097,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5074",
        "polygon": [
            -64.993,
            50.819,
            17.124,
            -65.38,
            49.79,
            17.124,
            -67.064,
            50.423,
            17.055,
            -67.064,
            50.423,
            17.055,
            -66.676,
            51.452,
            17.055,
            -64.993,
            50.819,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5075",
        "polygon": [
            -66.913,
            51.541,
            17.045,
            -67.3,
            50.512,
            17.045,
            -68.983,
            51.145,
            16.976,
            -68.983,
            51.145,
            16.976,
            -68.596,
            52.174,
            16.976,
            -66.913,
            51.541,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5076",
        "polygon": [
            -61.77,
            46.988,
            17.292,
            -61.383,
            48.018,
            17.292,
            -59.7,
            47.385,
            17.361,
            -59.7,
            47.385,
            17.361,
            -60.087,
            46.355,
            17.361,
            -61.77,
            46.988,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5077",
        "polygon": [
            -61.622,
            48.107,
            17.282,
            -62.009,
            47.078,
            17.282,
            -63.692,
            47.711,
            17.213,
            -63.692,
            47.711,
            17.213,
            -63.305,
            48.74,
            17.213,
            -61.622,
            48.107,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5078",
        "polygon": [
            -63.547,
            48.831,
            17.203,
            -63.934,
            47.801,
            17.203,
            -65.617,
            48.434,
            17.134,
            -65.617,
            48.434,
            17.134,
            -65.23,
            49.464,
            17.134,
            -63.547,
            48.831,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5079",
        "polygon": [
            -65.469,
            49.554,
            17.124,
            -65.856,
            48.524,
            17.124,
            -67.539,
            49.157,
            17.055,
            -67.539,
            49.157,
            17.055,
            -67.152,
            50.187,
            17.055,
            -65.469,
            49.554,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5080",
        "polygon": [
            -67.388,
            50.275,
            17.045,
            -67.776,
            49.246,
            17.045,
            -69.459,
            49.879,
            16.976,
            -69.459,
            49.879,
            16.976,
            -69.072,
            50.908,
            16.976,
            -67.388,
            50.275,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5081",
        "polygon": [
            -62.246,
            45.722,
            17.292,
            -61.859,
            46.752,
            17.292,
            -60.175,
            46.119,
            17.361,
            -60.175,
            46.119,
            17.361,
            -60.563,
            45.089,
            17.361,
            -62.246,
            45.722,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5082",
        "polygon": [
            -62.098,
            46.841,
            17.282,
            -62.485,
            45.812,
            17.282,
            -64.168,
            46.445,
            17.213,
            -64.168,
            46.445,
            17.213,
            -63.781,
            47.474,
            17.213,
            -62.098,
            46.841,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5083",
        "polygon": [
            -64.023,
            47.565,
            17.203,
            -64.41,
            46.535,
            17.203,
            -66.093,
            47.168,
            17.134,
            -66.093,
            47.168,
            17.134,
            -65.706,
            48.198,
            17.134,
            -64.023,
            47.565,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5084",
        "polygon": [
            -65.945,
            48.288,
            17.124,
            -66.332,
            47.258,
            17.124,
            -68.015,
            47.891,
            17.055,
            -68.015,
            47.891,
            17.055,
            -67.628,
            48.921,
            17.055,
            -65.945,
            48.288,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5085",
        "polygon": [
            -67.864,
            49.009,
            17.045,
            -68.252,
            47.98,
            17.045,
            -69.935,
            48.613,
            16.976,
            -69.935,
            48.613,
            16.976,
            -69.548,
            49.642,
            16.976,
            -67.864,
            49.009,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5086",
        "polygon": [
            -62.722,
            44.456,
            17.292,
            -62.335,
            45.486,
            17.292,
            -60.651,
            44.853,
            17.361,
            -60.651,
            44.853,
            17.361,
            -61.039,
            43.823,
            17.361,
            -62.722,
            44.456,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5087",
        "polygon": [
            -62.573,
            45.575,
            17.282,
            -62.961,
            44.546,
            17.282,
            -64.644,
            45.179,
            17.213,
            -64.644,
            45.179,
            17.213,
            -64.257,
            46.208,
            17.213,
            -62.573,
            45.575,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5088",
        "polygon": [
            -64.499,
            46.299,
            17.203,
            -64.886,
            45.27,
            17.203,
            -66.569,
            45.903,
            17.134,
            -66.569,
            45.903,
            17.134,
            -66.182,
            46.932,
            17.134,
            -64.499,
            46.299,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5089",
        "polygon": [
            -66.421,
            47.022,
            17.124,
            -66.808,
            45.992,
            17.124,
            -68.491,
            46.625,
            17.055,
            -68.491,
            46.625,
            17.055,
            -68.104,
            47.655,
            17.055,
            -66.421,
            47.022,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5090",
        "polygon": [
            -68.34,
            47.744,
            17.045,
            -68.727,
            46.714,
            17.045,
            -70.411,
            47.347,
            16.976,
            -70.411,
            47.347,
            16.976,
            -70.024,
            48.377,
            16.976,
            -68.34,
            47.744,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5091",
        "polygon": [
            -85.107,
            58.522,
            17.045,
            -84.72,
            59.552,
            17.045,
            -83.036,
            58.919,
            16.976,
            -83.036,
            58.919,
            16.976,
            -83.423,
            57.889,
            16.976,
            -85.107,
            58.522,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5092",
        "polygon": [
            -87.037,
            59.224,
            17.124,
            -86.65,
            60.254,
            17.124,
            -84.966,
            59.621,
            17.055,
            -84.966,
            59.621,
            17.055,
            -85.353,
            58.591,
            17.055,
            -87.037,
            59.224,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5093",
        "polygon": [
            -88.962,
            59.948,
            17.203,
            -88.575,
            60.977,
            17.203,
            -86.891,
            60.344,
            17.134,
            -86.891,
            60.344,
            17.134,
            -87.278,
            59.315,
            17.134,
            -88.962,
            59.948,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5094",
        "polygon": [
            -90.884,
            60.67,
            17.282,
            -90.497,
            61.7,
            17.282,
            -88.813,
            61.067,
            17.213,
            -88.813,
            61.067,
            17.213,
            -89.2,
            60.037,
            17.213,
            -90.884,
            60.67,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5095",
        "polygon": [
            -92.804,
            61.392,
            17.361,
            -92.417,
            62.422,
            17.361,
            -90.733,
            61.789,
            17.292,
            -90.733,
            61.789,
            17.292,
            -91.12,
            60.759,
            17.292,
            -92.804,
            61.392,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5096",
        "polygon": [
            -85.591,
            57.235,
            17.045,
            -85.204,
            58.265,
            17.045,
            -83.52,
            57.632,
            16.976,
            -83.52,
            57.632,
            16.976,
            -83.907,
            56.602,
            16.976,
            -85.591,
            57.235,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5097",
        "polygon": [
            -87.513,
            57.958,
            17.124,
            -87.126,
            58.988,
            17.124,
            -85.442,
            58.355,
            17.055,
            -85.442,
            58.355,
            17.055,
            -85.829,
            57.325,
            17.055,
            -87.513,
            57.958,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5098",
        "polygon": [
            -89.438,
            58.682,
            17.203,
            -89.051,
            59.711,
            17.203,
            -87.367,
            59.078,
            17.134,
            -87.367,
            59.078,
            17.134,
            -87.754,
            58.049,
            17.134,
            -89.438,
            58.682,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5099",
        "polygon": [
            -91.36,
            59.404,
            17.282,
            -90.973,
            60.434,
            17.282,
            -89.289,
            59.801,
            17.213,
            -89.289,
            59.801,
            17.213,
            -89.676,
            58.771,
            17.213,
            -91.36,
            59.404,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5100",
        "polygon": [
            -93.28,
            60.126,
            17.361,
            -92.893,
            61.156,
            17.361,
            -91.209,
            60.523,
            17.292,
            -91.209,
            60.523,
            17.292,
            -91.596,
            59.493,
            17.292,
            -93.28,
            60.126,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5101",
        "polygon": [
            -86.067,
            55.97,
            17.045,
            -85.68,
            56.999,
            17.045,
            -83.996,
            56.366,
            16.976,
            -83.996,
            56.366,
            16.976,
            -84.383,
            55.337,
            16.976,
            -86.067,
            55.97,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5102",
        "polygon": [
            -87.989,
            56.692,
            17.124,
            -87.602,
            57.722,
            17.124,
            -85.918,
            57.089,
            17.055,
            -85.918,
            57.089,
            17.055,
            -86.305,
            56.059,
            17.055,
            -87.989,
            56.692,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5103",
        "polygon": [
            -89.914,
            57.416,
            17.203,
            -89.527,
            58.446,
            17.203,
            -87.843,
            57.813,
            17.134,
            -87.843,
            57.813,
            17.134,
            -88.23,
            56.783,
            17.134,
            -89.914,
            57.416,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5104",
        "polygon": [
            -91.836,
            58.139,
            17.282,
            -91.449,
            59.168,
            17.282,
            -89.765,
            58.535,
            17.213,
            -89.765,
            58.535,
            17.213,
            -90.152,
            57.506,
            17.213,
            -91.836,
            58.139,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5105",
        "polygon": [
            -93.756,
            58.86,
            17.361,
            -93.369,
            59.89,
            17.361,
            -91.685,
            59.257,
            17.292,
            -91.685,
            59.257,
            17.292,
            -92.072,
            58.227,
            17.292,
            -93.756,
            58.86,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5106",
        "polygon": [
            -86.543,
            54.704,
            17.045,
            -86.156,
            55.733,
            17.045,
            -84.472,
            55.1,
            16.976,
            -84.472,
            55.1,
            16.976,
            -84.859,
            54.071,
            16.976,
            -86.543,
            54.704,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5107",
        "polygon": [
            -88.465,
            55.426,
            17.124,
            -88.078,
            56.456,
            17.124,
            -86.394,
            55.823,
            17.055,
            -86.394,
            55.823,
            17.055,
            -86.781,
            54.793,
            17.055,
            -88.465,
            55.426,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5108",
        "polygon": [
            -90.39,
            56.15,
            17.203,
            -90.003,
            57.18,
            17.203,
            -88.319,
            56.547,
            17.134,
            -88.319,
            56.547,
            17.134,
            -88.706,
            55.517,
            17.134,
            -90.39,
            56.15,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5109",
        "polygon": [
            -92.312,
            56.873,
            17.282,
            -91.925,
            57.902,
            17.282,
            -90.241,
            57.269,
            17.213,
            -90.241,
            57.269,
            17.213,
            -90.628,
            56.24,
            17.213,
            -92.312,
            56.873,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5110",
        "polygon": [
            -94.232,
            57.594,
            17.361,
            -93.844,
            58.624,
            17.361,
            -92.161,
            57.991,
            17.292,
            -92.161,
            57.991,
            17.292,
            -92.548,
            56.961,
            17.292,
            -94.232,
            57.594,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5111",
        "polygon": [
            -87.019,
            53.438,
            17.045,
            -86.631,
            54.467,
            17.045,
            -84.948,
            53.834,
            16.976,
            -84.948,
            53.834,
            16.976,
            -85.335,
            52.805,
            16.976,
            -87.019,
            53.438,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5112",
        "polygon": [
            -88.941,
            54.16,
            17.124,
            -88.554,
            55.19,
            17.124,
            -86.87,
            54.557,
            17.055,
            -86.87,
            54.557,
            17.055,
            -87.257,
            53.527,
            17.055,
            -88.941,
            54.16,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5113",
        "polygon": [
            -90.866,
            54.884,
            17.203,
            -90.479,
            55.914,
            17.203,
            -88.795,
            55.281,
            17.134,
            -88.795,
            55.281,
            17.134,
            -89.182,
            54.251,
            17.134,
            -90.866,
            54.884,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5114",
        "polygon": [
            -92.788,
            55.607,
            17.282,
            -92.401,
            56.636,
            17.282,
            -90.717,
            56.003,
            17.213,
            -90.717,
            56.003,
            17.213,
            -91.104,
            54.974,
            17.213,
            -92.788,
            55.607,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5115",
        "polygon": [
            -94.707,
            56.328,
            17.361,
            -94.32,
            57.358,
            17.361,
            -92.637,
            56.725,
            17.292,
            -92.637,
            56.725,
            17.292,
            -93.024,
            55.696,
            17.292,
            -94.707,
            56.328,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5116",
        "polygon": [
            -87.495,
            52.172,
            17.045,
            -87.107,
            53.202,
            17.045,
            -85.424,
            52.569,
            16.976,
            -85.424,
            52.569,
            16.976,
            -85.811,
            51.539,
            16.976,
            -87.495,
            52.172,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5117",
        "polygon": [
            -89.417,
            52.894,
            17.124,
            -89.029,
            53.924,
            17.124,
            -87.346,
            53.291,
            17.055,
            -87.346,
            53.291,
            17.055,
            -87.733,
            52.262,
            17.055,
            -89.417,
            52.894,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5118",
        "polygon": [
            -91.342,
            53.618,
            17.203,
            -90.954,
            54.648,
            17.203,
            -89.271,
            54.015,
            17.134,
            -89.271,
            54.015,
            17.134,
            -89.658,
            52.985,
            17.134,
            -91.342,
            53.618,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5119",
        "polygon": [
            -93.264,
            54.341,
            17.282,
            -92.877,
            55.37,
            17.282,
            -91.193,
            54.738,
            17.213,
            -91.193,
            54.738,
            17.213,
            -91.58,
            53.708,
            17.213,
            -93.264,
            54.341,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5120",
        "polygon": [
            -95.183,
            55.063,
            17.361,
            -94.796,
            56.092,
            17.361,
            -93.113,
            55.459,
            17.292,
            -93.113,
            55.459,
            17.292,
            -93.5,
            54.43,
            17.292,
            -95.183,
            55.063,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5121",
        "polygon": [
            -87.97,
            50.906,
            17.045,
            -87.583,
            51.936,
            17.045,
            -85.9,
            51.303,
            16.976,
            -85.9,
            51.303,
            16.976,
            -86.287,
            50.273,
            16.976,
            -87.97,
            50.906,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5122",
        "polygon": [
            -89.892,
            51.629,
            17.124,
            -89.505,
            52.658,
            17.124,
            -87.822,
            52.025,
            17.055,
            -87.822,
            52.025,
            17.055,
            -88.209,
            50.996,
            17.055,
            -89.892,
            51.629,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5123",
        "polygon": [
            -91.818,
            52.352,
            17.203,
            -91.43,
            53.382,
            17.203,
            -89.747,
            52.749,
            17.134,
            -89.747,
            52.749,
            17.134,
            -90.134,
            51.719,
            17.134,
            -91.818,
            52.352,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5124",
        "polygon": [
            -93.74,
            53.075,
            17.282,
            -93.352,
            54.105,
            17.282,
            -91.669,
            53.472,
            17.213,
            -91.669,
            53.472,
            17.213,
            -92.056,
            52.442,
            17.213,
            -93.74,
            53.075,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5125",
        "polygon": [
            -95.659,
            53.797,
            17.361,
            -95.272,
            54.826,
            17.361,
            -93.589,
            54.193,
            17.292,
            -93.589,
            54.193,
            17.292,
            -93.976,
            53.164,
            17.292,
            -95.659,
            53.797,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5126",
        "polygon": [
            -88.446,
            49.64,
            17.045,
            -88.059,
            50.67,
            17.045,
            -86.376,
            50.037,
            16.976,
            -86.376,
            50.037,
            16.976,
            -86.763,
            49.007,
            16.976,
            -88.446,
            49.64,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5127",
        "polygon": [
            -90.368,
            50.363,
            17.124,
            -89.981,
            51.392,
            17.124,
            -88.298,
            50.759,
            17.055,
            -88.298,
            50.759,
            17.055,
            -88.685,
            49.73,
            17.055,
            -90.368,
            50.363,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5128",
        "polygon": [
            -92.293,
            51.086,
            17.203,
            -91.906,
            52.116,
            17.203,
            -90.223,
            51.483,
            17.134,
            -90.223,
            51.483,
            17.134,
            -90.61,
            50.453,
            17.134,
            -92.293,
            51.086,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5129",
        "polygon": [
            -94.215,
            51.809,
            17.282,
            -93.828,
            52.839,
            17.282,
            -92.145,
            52.206,
            17.213,
            -92.145,
            52.206,
            17.213,
            -92.532,
            51.176,
            17.213,
            -94.215,
            51.809,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5130",
        "polygon": [
            -96.135,
            52.531,
            17.361,
            -95.748,
            53.56,
            17.361,
            -94.065,
            52.927,
            17.292,
            -94.065,
            52.927,
            17.292,
            -94.452,
            51.898,
            17.292,
            -96.135,
            52.531,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5131",
        "polygon": [
            -88.922,
            48.374,
            17.045,
            -88.535,
            49.404,
            17.045,
            -86.852,
            48.771,
            16.976,
            -86.852,
            48.771,
            16.976,
            -87.239,
            47.741,
            16.976,
            -88.922,
            48.374,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5132",
        "polygon": [
            -90.844,
            49.097,
            17.124,
            -90.457,
            50.126,
            17.124,
            -88.774,
            49.493,
            17.055,
            -88.774,
            49.493,
            17.055,
            -89.161,
            48.464,
            17.055,
            -90.844,
            49.097,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5133",
        "polygon": [
            -92.769,
            49.821,
            17.203,
            -92.382,
            50.85,
            17.203,
            -90.699,
            50.217,
            17.134,
            -90.699,
            50.217,
            17.134,
            -91.086,
            49.188,
            17.134,
            -92.769,
            49.821,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5134",
        "polygon": [
            -94.691,
            50.543,
            17.282,
            -94.304,
            51.573,
            17.282,
            -92.621,
            50.94,
            17.213,
            -92.621,
            50.94,
            17.213,
            -93.008,
            49.91,
            17.213,
            -94.691,
            50.543,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5135",
        "polygon": [
            -96.611,
            51.265,
            17.361,
            -96.224,
            52.295,
            17.361,
            -94.54,
            51.662,
            17.292,
            -94.54,
            51.662,
            17.292,
            -94.928,
            50.632,
            17.292,
            -96.611,
            51.265,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5136",
        "polygon": [
            -89.398,
            47.108,
            17.045,
            -89.011,
            48.138,
            17.045,
            -87.327,
            47.505,
            16.976,
            -87.327,
            47.505,
            16.976,
            -87.715,
            46.475,
            16.976,
            -89.398,
            47.108,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5137",
        "polygon": [
            -91.32,
            47.831,
            17.124,
            -90.933,
            48.861,
            17.124,
            -89.25,
            48.228,
            17.055,
            -89.25,
            48.228,
            17.055,
            -89.637,
            47.198,
            17.055,
            -91.32,
            47.831,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5138",
        "polygon": [
            -93.245,
            48.555,
            17.203,
            -92.858,
            49.584,
            17.203,
            -91.175,
            48.951,
            17.134,
            -91.175,
            48.951,
            17.134,
            -91.562,
            47.922,
            17.134,
            -93.245,
            48.555,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5139",
        "polygon": [
            -95.167,
            49.277,
            17.282,
            -94.78,
            50.307,
            17.282,
            -93.097,
            49.674,
            17.213,
            -93.097,
            49.674,
            17.213,
            -93.484,
            48.644,
            17.213,
            -95.167,
            49.277,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5140",
        "polygon": [
            -97.087,
            49.999,
            17.361,
            -96.7,
            51.029,
            17.361,
            -95.016,
            50.396,
            17.292,
            -95.016,
            50.396,
            17.292,
            -95.403,
            49.366,
            17.292,
            -97.087,
            49.999,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5141",
        "polygon": [
            -89.874,
            45.842,
            17.045,
            -89.487,
            46.872,
            17.045,
            -87.803,
            46.239,
            16.976,
            -87.803,
            46.239,
            16.976,
            -88.191,
            45.209,
            16.976,
            -89.874,
            45.842,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5142",
        "polygon": [
            -91.796,
            46.565,
            17.124,
            -91.409,
            47.595,
            17.124,
            -89.725,
            46.962,
            17.055,
            -89.725,
            46.962,
            17.055,
            -90.113,
            45.932,
            17.055,
            -91.796,
            46.565,
            17.124
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5143",
        "polygon": [
            -93.721,
            47.289,
            17.203,
            -93.334,
            48.318,
            17.203,
            -91.651,
            47.685,
            17.134,
            -91.651,
            47.685,
            17.134,
            -92.038,
            46.656,
            17.134,
            -93.721,
            47.289,
            17.203
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5144",
        "polygon": [
            -95.643,
            48.011,
            17.282,
            -95.256,
            49.041,
            17.282,
            -93.573,
            48.408,
            17.213,
            -93.573,
            48.408,
            17.213,
            -93.96,
            47.378,
            17.213,
            -95.643,
            48.011,
            17.282
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5145",
        "polygon": [
            -97.563,
            48.733,
            17.361,
            -97.176,
            49.763,
            17.361,
            -95.492,
            49.13,
            17.292,
            -95.492,
            49.13,
            17.292,
            -95.879,
            48.1,
            17.292,
            -97.563,
            48.733,
            17.361
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5146",
        "polygon": [
            -94.931,
            62.221,
            17.292,
            -94.544,
            63.251,
            17.292,
            -92.86,
            62.618,
            17.361,
            -92.86,
            62.618,
            17.361,
            -93.247,
            61.588,
            17.361,
            -94.931,
            62.221,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5147",
        "polygon": [
            -94.784,
            63.336,
            17.282,
            -95.171,
            62.306,
            17.282,
            -96.855,
            62.939,
            17.213,
            -96.855,
            62.939,
            17.213,
            -96.467,
            63.969,
            17.213,
            -94.784,
            63.336,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5148",
        "polygon": [
            -96.709,
            64.059,
            17.203,
            -97.096,
            63.03,
            17.203,
            -98.78,
            63.663,
            17.134,
            -98.78,
            63.663,
            17.134,
            -98.393,
            64.692,
            17.134,
            -96.709,
            64.059,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5149",
        "polygon": [
            -98.631,
            64.782,
            17.124,
            -99.018,
            63.752,
            17.124,
            -100.702,
            64.385,
            17.055,
            -100.702,
            64.385,
            17.055,
            -100.315,
            65.415,
            17.055,
            -98.631,
            64.782,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5150",
        "polygon": [
            -100.551,
            65.504,
            17.045,
            -100.938,
            64.474,
            17.045,
            -102.621,
            65.107,
            16.976,
            -102.621,
            65.107,
            16.976,
            -102.234,
            66.137,
            16.976,
            -100.551,
            65.504,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5151",
        "polygon": [
            -95.408,
            60.95,
            17.292,
            -95.021,
            61.98,
            17.292,
            -93.338,
            61.347,
            17.361,
            -93.338,
            61.347,
            17.361,
            -93.725,
            60.318,
            17.361,
            -95.408,
            60.95,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5152",
        "polygon": [
            -95.26,
            62.07,
            17.282,
            -95.647,
            61.04,
            17.282,
            -97.33,
            61.673,
            17.213,
            -97.33,
            61.673,
            17.213,
            -96.943,
            62.703,
            17.213,
            -95.26,
            62.07,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5153",
        "polygon": [
            -97.185,
            62.794,
            17.203,
            -97.572,
            61.764,
            17.203,
            -99.256,
            62.397,
            17.134,
            -99.256,
            62.397,
            17.134,
            -98.868,
            63.426,
            17.134,
            -97.185,
            62.794,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5154",
        "polygon": [
            -99.107,
            63.516,
            17.124,
            -99.494,
            62.486,
            17.124,
            -101.178,
            63.119,
            17.055,
            -101.178,
            63.119,
            17.055,
            -100.79,
            64.149,
            17.055,
            -99.107,
            63.516,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5155",
        "polygon": [
            -101.027,
            64.238,
            17.045,
            -101.414,
            63.208,
            17.045,
            -103.097,
            63.841,
            16.976,
            -103.097,
            63.841,
            16.976,
            -102.71,
            64.871,
            16.976,
            -101.027,
            64.238,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5156",
        "polygon": [
            -95.884,
            59.685,
            17.292,
            -95.497,
            60.714,
            17.292,
            -93.814,
            60.081,
            17.361,
            -93.814,
            60.081,
            17.361,
            -94.201,
            59.052,
            17.361,
            -95.884,
            59.685,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5157",
        "polygon": [
            -95.736,
            60.804,
            17.282,
            -96.123,
            59.774,
            17.282,
            -97.806,
            60.407,
            17.213,
            -97.806,
            60.407,
            17.213,
            -97.419,
            61.437,
            17.213,
            -95.736,
            60.804,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5158",
        "polygon": [
            -97.661,
            61.528,
            17.203,
            -98.048,
            60.498,
            17.203,
            -99.731,
            61.131,
            17.134,
            -99.731,
            61.131,
            17.134,
            -99.344,
            62.161,
            17.134,
            -97.661,
            61.528,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5159",
        "polygon": [
            -99.583,
            62.25,
            17.124,
            -99.97,
            61.221,
            17.124,
            -101.654,
            61.854,
            17.055,
            -101.654,
            61.854,
            17.055,
            -101.266,
            62.883,
            17.055,
            -99.583,
            62.25,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5160",
        "polygon": [
            -101.503,
            62.972,
            17.045,
            -101.89,
            61.942,
            17.045,
            -103.573,
            62.575,
            16.976,
            -103.573,
            62.575,
            16.976,
            -103.186,
            63.605,
            16.976,
            -101.503,
            62.972,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5161",
        "polygon": [
            -96.36,
            58.419,
            17.292,
            -95.973,
            59.448,
            17.292,
            -94.29,
            58.815,
            17.361,
            -94.29,
            58.815,
            17.361,
            -94.677,
            57.786,
            17.361,
            -96.36,
            58.419,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5162",
        "polygon": [
            -96.212,
            59.538,
            17.282,
            -96.599,
            58.508,
            17.282,
            -98.282,
            59.141,
            17.213,
            -98.282,
            59.141,
            17.213,
            -97.895,
            60.171,
            17.213,
            -96.212,
            59.538,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5163",
        "polygon": [
            -98.137,
            60.262,
            17.203,
            -98.524,
            59.232,
            17.203,
            -100.207,
            59.865,
            17.134,
            -100.207,
            59.865,
            17.134,
            -99.82,
            60.895,
            17.134,
            -98.137,
            60.262,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5164",
        "polygon": [
            -100.059,
            60.984,
            17.124,
            -100.446,
            59.955,
            17.124,
            -102.129,
            60.588,
            17.055,
            -102.129,
            60.588,
            17.055,
            -101.742,
            61.617,
            17.055,
            -100.059,
            60.984,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5165",
        "polygon": [
            -101.978,
            61.706,
            17.045,
            -102.366,
            60.676,
            17.045,
            -104.049,
            61.309,
            16.976,
            -104.049,
            61.309,
            16.976,
            -103.662,
            62.339,
            16.976,
            -101.978,
            61.706,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5166",
        "polygon": [
            -96.836,
            57.153,
            17.292,
            -96.449,
            58.182,
            17.292,
            -94.765,
            57.549,
            17.361,
            -94.765,
            57.549,
            17.361,
            -95.153,
            56.52,
            17.361,
            -96.836,
            57.153,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5167",
        "polygon": [
            -96.688,
            58.272,
            17.282,
            -97.075,
            57.242,
            17.282,
            -98.758,
            57.875,
            17.213,
            -98.758,
            57.875,
            17.213,
            -98.371,
            58.905,
            17.213,
            -96.688,
            58.272,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5168",
        "polygon": [
            -98.613,
            58.996,
            17.203,
            -99.0,
            57.966,
            17.203,
            -100.683,
            58.599,
            17.134,
            -100.683,
            58.599,
            17.134,
            -100.296,
            59.629,
            17.134,
            -98.613,
            58.996,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5169",
        "polygon": [
            -100.535,
            59.718,
            17.124,
            -100.922,
            58.689,
            17.124,
            -102.605,
            59.322,
            17.055,
            -102.605,
            59.322,
            17.055,
            -102.218,
            60.351,
            17.055,
            -100.535,
            59.718,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5170",
        "polygon": [
            -102.454,
            60.44,
            17.045,
            -102.842,
            59.411,
            17.045,
            -104.525,
            60.044,
            16.976,
            -104.525,
            60.044,
            16.976,
            -104.138,
            61.073,
            16.976,
            -102.454,
            60.44,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5171",
        "polygon": [
            -97.312,
            55.887,
            17.292,
            -96.925,
            56.917,
            17.292,
            -95.241,
            56.284,
            17.361,
            -95.241,
            56.284,
            17.361,
            -95.629,
            55.254,
            17.361,
            -97.312,
            55.887,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5172",
        "polygon": [
            -97.163,
            57.006,
            17.282,
            -97.551,
            55.977,
            17.282,
            -99.234,
            56.61,
            17.213,
            -99.234,
            56.61,
            17.213,
            -98.847,
            57.639,
            17.213,
            -97.163,
            57.006,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5173",
        "polygon": [
            -99.089,
            57.73,
            17.203,
            -99.476,
            56.7,
            17.203,
            -101.159,
            57.333,
            17.134,
            -101.159,
            57.333,
            17.134,
            -100.772,
            58.363,
            17.134,
            -99.089,
            57.73,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5174",
        "polygon": [
            -101.011,
            58.453,
            17.124,
            -101.398,
            57.423,
            17.124,
            -103.081,
            58.056,
            17.055,
            -103.081,
            58.056,
            17.055,
            -102.694,
            59.086,
            17.055,
            -101.011,
            58.453,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5175",
        "polygon": [
            -102.93,
            59.174,
            17.045,
            -103.317,
            58.145,
            17.045,
            -105.001,
            58.778,
            16.976,
            -105.001,
            58.778,
            16.976,
            -104.614,
            59.807,
            16.976,
            -102.93,
            59.174,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5176",
        "polygon": [
            -97.788,
            54.621,
            17.292,
            -97.401,
            55.651,
            17.292,
            -95.717,
            55.018,
            17.361,
            -95.717,
            55.018,
            17.361,
            -96.104,
            53.988,
            17.361,
            -97.788,
            54.621,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5177",
        "polygon": [
            -97.639,
            55.74,
            17.282,
            -98.026,
            54.711,
            17.282,
            -99.71,
            55.344,
            17.213,
            -99.71,
            55.344,
            17.213,
            -99.323,
            56.373,
            17.213,
            -97.639,
            55.74,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5178",
        "polygon": [
            -99.564,
            56.464,
            17.203,
            -99.952,
            55.434,
            17.203,
            -101.635,
            56.067,
            17.134,
            -101.635,
            56.067,
            17.134,
            -101.248,
            57.097,
            17.134,
            -99.564,
            56.464,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5179",
        "polygon": [
            -101.486,
            57.187,
            17.124,
            -101.874,
            56.157,
            17.124,
            -103.557,
            56.79,
            17.055,
            -103.557,
            56.79,
            17.055,
            -103.17,
            57.82,
            17.055,
            -101.486,
            57.187,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5180",
        "polygon": [
            -103.406,
            57.908,
            17.045,
            -103.793,
            56.879,
            17.045,
            -105.477,
            57.512,
            16.976,
            -105.477,
            57.512,
            16.976,
            -105.09,
            58.541,
            16.976,
            -103.406,
            57.908,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5181",
        "polygon": [
            -98.264,
            53.355,
            17.292,
            -97.877,
            54.385,
            17.292,
            -96.193,
            53.752,
            17.361,
            -96.193,
            53.752,
            17.361,
            -96.58,
            52.722,
            17.361,
            -98.264,
            53.355,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5182",
        "polygon": [
            -98.115,
            54.474,
            17.282,
            -98.502,
            53.445,
            17.282,
            -100.186,
            54.078,
            17.213,
            -100.186,
            54.078,
            17.213,
            -99.799,
            55.107,
            17.213,
            -98.115,
            54.474,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5183",
        "polygon": [
            -100.04,
            55.198,
            17.203,
            -100.427,
            54.169,
            17.203,
            -102.111,
            54.801,
            17.134,
            -102.111,
            54.801,
            17.134,
            -101.724,
            55.831,
            17.134,
            -100.04,
            55.198,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5184",
        "polygon": [
            -101.962,
            55.921,
            17.124,
            -102.35,
            54.891,
            17.124,
            -104.033,
            55.524,
            17.055,
            -104.033,
            55.524,
            17.055,
            -103.646,
            56.554,
            17.055,
            -101.962,
            55.921,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5185",
        "polygon": [
            -103.882,
            56.643,
            17.045,
            -104.269,
            55.613,
            17.045,
            -105.953,
            56.246,
            16.976,
            -105.953,
            56.246,
            16.976,
            -105.566,
            57.276,
            16.976,
            -103.882,
            56.643,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5186",
        "polygon": [
            -98.74,
            52.089,
            17.292,
            -98.353,
            53.119,
            17.292,
            -96.669,
            52.486,
            17.361,
            -96.669,
            52.486,
            17.361,
            -97.056,
            51.456,
            17.361,
            -98.74,
            52.089,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5187",
        "polygon": [
            -98.591,
            53.209,
            17.282,
            -98.978,
            52.179,
            17.282,
            -100.662,
            52.812,
            17.213,
            -100.662,
            52.812,
            17.213,
            -100.275,
            53.842,
            17.213,
            -98.591,
            53.209,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5188",
        "polygon": [
            -100.516,
            53.932,
            17.203,
            -100.903,
            52.903,
            17.203,
            -102.587,
            53.536,
            17.134,
            -102.587,
            53.536,
            17.134,
            -102.2,
            54.565,
            17.134,
            -100.516,
            53.932,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5189",
        "polygon": [
            -102.438,
            54.655,
            17.124,
            -102.825,
            53.625,
            17.124,
            -104.509,
            54.258,
            17.055,
            -104.509,
            54.258,
            17.055,
            -104.122,
            55.288,
            17.055,
            -102.438,
            54.655,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5190",
        "polygon": [
            -104.358,
            55.377,
            17.045,
            -104.745,
            54.347,
            17.045,
            -106.429,
            54.98,
            16.976,
            -106.429,
            54.98,
            16.976,
            -106.042,
            56.01,
            16.976,
            -104.358,
            55.377,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5191",
        "polygon": [
            -99.216,
            50.823,
            17.292,
            -98.829,
            51.853,
            17.292,
            -97.145,
            51.22,
            17.361,
            -97.145,
            51.22,
            17.361,
            -97.532,
            50.19,
            17.361,
            -99.216,
            50.823,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5192",
        "polygon": [
            -99.067,
            51.943,
            17.282,
            -99.454,
            50.913,
            17.282,
            -101.138,
            51.546,
            17.213,
            -101.138,
            51.546,
            17.213,
            -100.751,
            52.576,
            17.213,
            -99.067,
            51.943,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5193",
        "polygon": [
            -100.992,
            52.666,
            17.203,
            -101.379,
            51.637,
            17.203,
            -103.063,
            52.27,
            17.134,
            -103.063,
            52.27,
            17.134,
            -102.676,
            53.299,
            17.134,
            -100.992,
            52.666,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5194",
        "polygon": [
            -102.914,
            53.389,
            17.124,
            -103.301,
            52.359,
            17.124,
            -104.985,
            52.992,
            17.055,
            -104.985,
            52.992,
            17.055,
            -104.598,
            54.022,
            17.055,
            -102.914,
            53.389,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5195",
        "polygon": [
            -104.834,
            54.111,
            17.045,
            -105.221,
            53.081,
            17.045,
            -106.905,
            53.714,
            16.976,
            -106.905,
            53.714,
            16.976,
            -106.518,
            54.744,
            16.976,
            -104.834,
            54.111,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5196",
        "polygon": [
            -99.692,
            49.557,
            17.292,
            -99.305,
            50.587,
            17.292,
            -97.621,
            49.954,
            17.361,
            -97.621,
            49.954,
            17.361,
            -98.008,
            48.924,
            17.361,
            -99.692,
            49.557,
            17.292
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5197",
        "polygon": [
            -99.543,
            50.677,
            17.282,
            -99.93,
            49.647,
            17.282,
            -101.614,
            50.28,
            17.213,
            -101.614,
            50.28,
            17.213,
            -101.227,
            51.31,
            17.213,
            -99.543,
            50.677,
            17.282
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5198",
        "polygon": [
            -101.468,
            51.4,
            17.203,
            -101.855,
            50.371,
            17.203,
            -103.539,
            51.004,
            17.134,
            -103.539,
            51.004,
            17.134,
            -103.152,
            52.033,
            17.134,
            -101.468,
            51.4,
            17.203
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5199",
        "polygon": [
            -103.39,
            52.123,
            17.124,
            -103.777,
            51.093,
            17.124,
            -105.461,
            51.726,
            17.055,
            -105.461,
            51.726,
            17.055,
            -105.074,
            52.756,
            17.055,
            -103.39,
            52.123,
            17.124
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_Roof_5200",
        "polygon": [
            -105.31,
            52.845,
            17.045,
            -105.697,
            51.815,
            17.045,
            -107.381,
            52.448,
            16.976,
            -107.381,
            52.448,
            16.976,
            -106.994,
            53.478,
            16.976,
            -105.31,
            52.845,
            17.045
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5201",
        "polygon": [
            -92.048,
            81.794,
            -4.388,
            -92.416,
            80.815,
            -4.388,
            -92.416,
            80.815,
            -0.832,
            -92.416,
            80.815,
            -0.832,
            -92.048,
            81.794,
            -0.832,
            -92.048,
            81.794,
            -4.388
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5202",
        "polygon": [
            -95.498,
            72.616,
            -4.388,
            -95.864,
            71.642,
            -4.388,
            -95.864,
            71.642,
            -0.832,
            -95.864,
            71.642,
            -0.832,
            -95.498,
            72.616,
            -0.832,
            -95.498,
            72.616,
            -4.388
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5203",
        "polygon": [
            -92.417,
            80.812,
            -4.388,
            -95.408,
            72.855,
            -4.388,
            -95.408,
            72.855,
            -0.832,
            -95.408,
            72.855,
            -0.832,
            -92.417,
            80.812,
            -0.832,
            -92.417,
            80.812,
            -4.388
        ],
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5204",
        "polygon": [
            -95.901,
            71.545,
            -4.388,
            -96.267,
            70.571,
            -4.388,
            -96.267,
            70.571,
            -0.832,
            -96.267,
            70.571,
            -0.832,
            -95.901,
            71.545,
            -0.832,
            -95.901,
            71.545,
            -4.388
        ],
        "05-24 09:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5205",
        "polygon": [
            -84.606,
            66.181,
            -4.388,
            -84.238,
            67.16,
            -4.388,
            -84.238,
            67.16,
            -0.832,
            -84.238,
            67.16,
            -0.832,
            -84.606,
            66.181,
            -0.832,
            -84.606,
            66.181,
            -4.388
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5206",
        "polygon": [
            -81.155,
            75.36,
            -4.388,
            -80.789,
            76.333,
            -4.388,
            -80.789,
            76.333,
            -0.832,
            -80.789,
            76.333,
            -0.832,
            -81.155,
            75.36,
            -0.832,
            -81.155,
            75.36,
            -4.388
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5207",
        "polygon": [
            -84.237,
            67.164,
            -4.388,
            -81.245,
            75.12,
            -4.388,
            -81.245,
            75.12,
            -0.832,
            -81.245,
            75.12,
            -0.832,
            -84.237,
            67.164,
            -0.832,
            -84.237,
            67.164,
            -4.388
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5208",
        "polygon": [
            -80.753,
            76.43,
            -4.388,
            -80.387,
            77.404,
            -4.388,
            -80.387,
            77.404,
            -0.832,
            -80.387,
            77.404,
            -0.832,
            -80.753,
            76.43,
            -0.832,
            -80.753,
            76.43,
            -4.388
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5209",
        "polygon": [
            -96.134,
            70.267,
            -4.388,
            -95.145,
            69.895,
            -4.388,
            -95.145,
            69.895,
            -0.832,
            -95.145,
            69.895,
            -0.832,
            -96.134,
            70.267,
            -0.832,
            -96.134,
            70.267,
            -4.388
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5210",
        "polygon": [
            -85.884,
            66.414,
            -4.388,
            -84.896,
            66.042,
            -4.388,
            -84.896,
            66.042,
            -0.832,
            -84.896,
            66.042,
            -0.832,
            -85.884,
            66.414,
            -0.832,
            -85.884,
            66.414,
            -4.388
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5211",
        "polygon": [
            -86.955,
            66.816,
            -4.388,
            -85.966,
            66.444,
            -4.388,
            -85.966,
            66.444,
            -0.832,
            -85.966,
            66.444,
            -0.832,
            -86.955,
            66.816,
            -0.832,
            -86.955,
            66.816,
            -4.388
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5212",
        "polygon": [
            -89.576,
            67.801,
            -4.388,
            -88.587,
            67.43,
            -4.388,
            -88.587,
            67.43,
            -0.832,
            -88.587,
            67.43,
            -0.832,
            -89.576,
            67.801,
            -0.832,
            -89.576,
            67.801,
            -4.388
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5213",
        "polygon": [
            -95.057,
            69.862,
            -4.388,
            -89.914,
            67.928,
            -4.388,
            -89.914,
            67.928,
            -0.832,
            -89.914,
            67.928,
            -0.832,
            -95.057,
            69.862,
            -0.832,
            -95.057,
            69.862,
            -4.388
        ]
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5214",
        "polygon": [
            -90.77,
            81.562,
            -4.388,
            -91.749,
            81.93,
            -4.388,
            -91.749,
            81.93,
            -0.832,
            -91.749,
            81.93,
            -0.832,
            -90.77,
            81.562,
            -0.832,
            -90.77,
            81.562,
            -4.388
        ],
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1
    },
    {
        "ID": "SantanderBank-HQ_MF_01_UG_5215",
        "polygon": [
            -80.52,
            77.708,
            -4.388,
            -81.499,
            78.076,
            -4.388,
            -81.499,
            78.076,
            -0.832,
            -81.499,
            78.076,
            -0.832,
            -80.52,
            77.708,
            -0.832,
            -80.52,
            77.708,
            -4.388
        ],
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_10_1",
        "polygon": [
            30.491,
            -16.376,
            0.142,
            29.697,
            -16.077,
            0.142,
            29.697,
            -16.077,
            3.698,
            29.697,
            -16.077,
            3.698,
            30.491,
            -16.376,
            3.698,
            30.491,
            -16.376,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_10_2",
        "polygon": [
            29.657,
            -16.062,
            0.08,
            29.278,
            -15.92,
            0.08,
            29.278,
            -15.92,
            2.928,
            29.278,
            -15.92,
            2.928,
            29.657,
            -16.062,
            2.928,
            29.657,
            -16.062,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_10_3",
        "polygon": [
            29.657,
            -16.062,
            2.928,
            29.278,
            -15.92,
            2.928,
            29.278,
            -15.92,
            3.698,
            29.278,
            -15.92,
            3.698,
            29.657,
            -16.062,
            3.698,
            29.657,
            -16.062,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_8_1",
        "polygon": [
            27.871,
            -15.391,
            0.142,
            27.076,
            -15.092,
            0.142,
            27.076,
            -15.092,
            3.698,
            27.076,
            -15.092,
            3.698,
            27.871,
            -15.391,
            3.698,
            27.871,
            -15.391,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_8_2",
        "polygon": [
            27.037,
            -15.077,
            0.08,
            26.658,
            -14.935,
            0.08,
            26.658,
            -14.935,
            2.928,
            26.658,
            -14.935,
            2.928,
            27.037,
            -15.077,
            2.928,
            27.037,
            -15.077,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_8_3",
        "polygon": [
            27.037,
            -15.077,
            2.928,
            26.658,
            -14.935,
            2.928,
            26.658,
            -14.935,
            3.698,
            26.658,
            -14.935,
            3.698,
            27.037,
            -15.077,
            3.698,
            27.037,
            -15.077,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_4_1",
        "polygon": [
            22.629,
            -13.42,
            0.142,
            21.834,
            -13.121,
            0.142,
            21.834,
            -13.121,
            3.698,
            21.834,
            -13.121,
            3.698,
            22.629,
            -13.42,
            3.698,
            22.629,
            -13.42,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_4_2",
        "polygon": [
            21.795,
            -13.106,
            0.08,
            21.416,
            -12.964,
            0.08,
            21.416,
            -12.964,
            2.928,
            21.416,
            -12.964,
            2.928,
            21.795,
            -13.106,
            2.928,
            21.795,
            -13.106,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_4_3",
        "polygon": [
            21.795,
            -13.106,
            2.928,
            21.416,
            -12.964,
            2.928,
            21.416,
            -12.964,
            3.698,
            21.416,
            -12.964,
            3.698,
            21.795,
            -13.106,
            3.698,
            21.795,
            -13.106,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_2_1",
        "polygon": [
            20.008,
            -12.435,
            0.142,
            19.213,
            -12.136,
            0.142,
            19.213,
            -12.136,
            3.698,
            19.213,
            -12.136,
            3.698,
            20.008,
            -12.435,
            3.698,
            20.008,
            -12.435,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_2_2",
        "polygon": [
            19.174,
            -12.121,
            0.08,
            18.795,
            -11.979,
            0.08,
            18.795,
            -11.979,
            2.928,
            18.795,
            -11.979,
            2.928,
            19.174,
            -12.121,
            2.928,
            19.174,
            -12.121,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_2_3",
        "polygon": [
            19.174,
            -12.121,
            2.928,
            18.795,
            -11.979,
            2.928,
            18.795,
            -11.979,
            3.698,
            18.795,
            -11.979,
            3.698,
            19.174,
            -12.121,
            3.698,
            19.174,
            -12.121,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_1_1",
        "polygon": [
            18.697,
            -11.942,
            0.142,
            17.484,
            -11.486,
            0.142,
            17.484,
            -11.486,
            3.698,
            17.484,
            -11.486,
            3.698,
            18.697,
            -11.942,
            3.698,
            18.697,
            -11.942,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_1_2",
        "polygon": [
            17.387,
            -11.449,
            0.142,
            16.716,
            -11.197,
            0.142,
            16.716,
            -11.197,
            3.698,
            16.716,
            -11.197,
            3.698,
            17.387,
            -11.449,
            3.698,
            17.387,
            -11.449,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_6_1",
        "polygon": [
            24.898,
            -14.273,
            0.08,
            24.037,
            -13.949,
            0.08,
            24.037,
            -13.949,
            2.928,
            24.037,
            -13.949,
            2.928,
            24.898,
            -14.273,
            2.928,
            24.898,
            -14.273,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_6_2",
        "polygon": [
            24.898,
            -14.273,
            2.928,
            24.037,
            -13.949,
            2.928,
            24.037,
            -13.949,
            3.698,
            24.037,
            -13.949,
            3.698,
            24.898,
            -14.273,
            3.698,
            24.898,
            -14.273,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_EG_6_3",
        "polygon": [
            25.25,
            -14.405,
            0.142,
            24.937,
            -14.288,
            0.142,
            24.937,
            -14.288,
            3.698,
            24.937,
            -14.288,
            3.698,
            25.25,
            -14.405,
            3.698,
            25.25,
            -14.405,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_36_1",
        "polygon": [
            -12.313,
            -0.283,
            0.142,
            -13.108,
            0.016,
            0.142,
            -13.108,
            0.016,
            3.698,
            -13.108,
            0.016,
            3.698,
            -12.313,
            -0.283,
            3.698,
            -12.313,
            -0.283,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_36_2",
        "polygon": [
            -13.147,
            0.03,
            0.08,
            -13.526,
            0.173,
            0.08,
            -13.526,
            0.173,
            2.928,
            -13.526,
            0.173,
            2.928,
            -13.147,
            0.03,
            2.928,
            -13.147,
            0.03,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_36_3",
        "polygon": [
            -13.147,
            0.03,
            2.928,
            -13.526,
            0.173,
            2.928,
            -13.526,
            0.173,
            3.698,
            -13.526,
            0.173,
            3.698,
            -13.147,
            0.03,
            3.698,
            -13.147,
            0.03,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_34_1",
        "polygon": [
            -14.934,
            0.702,
            0.142,
            -15.729,
            1.001,
            0.142,
            -15.729,
            1.001,
            3.698,
            -15.729,
            1.001,
            3.698,
            -14.934,
            0.702,
            3.698,
            -14.934,
            0.702,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_34_2",
        "polygon": [
            -15.768,
            1.016,
            0.08,
            -16.147,
            1.158,
            0.08,
            -16.147,
            1.158,
            2.928,
            -16.147,
            1.158,
            2.928,
            -15.768,
            1.016,
            2.928,
            -15.768,
            1.016,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_34_3",
        "polygon": [
            -15.768,
            1.016,
            2.928,
            -16.147,
            1.158,
            2.928,
            -16.147,
            1.158,
            3.698,
            -16.147,
            1.158,
            3.698,
            -15.768,
            1.016,
            3.698,
            -15.768,
            1.016,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_32_1",
        "polygon": [
            -17.555,
            1.688,
            0.142,
            -18.35,
            1.986,
            0.142,
            -18.35,
            1.986,
            3.698,
            -18.35,
            1.986,
            3.698,
            -17.555,
            1.688,
            3.698,
            -17.555,
            1.688,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_32_2",
        "polygon": [
            -18.389,
            2.001,
            0.08,
            -18.768,
            2.144,
            0.08,
            -18.768,
            2.144,
            2.928,
            -18.768,
            2.144,
            2.928,
            -18.389,
            2.001,
            2.928,
            -18.389,
            2.001,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_32_3",
        "polygon": [
            -18.389,
            2.001,
            2.928,
            -18.768,
            2.144,
            2.928,
            -18.768,
            2.144,
            3.698,
            -18.768,
            2.144,
            3.698,
            -18.389,
            2.001,
            3.698,
            -18.389,
            2.001,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_30_1",
        "polygon": [
            -20.176,
            2.673,
            0.142,
            -20.971,
            2.972,
            0.142,
            -20.971,
            2.972,
            3.698,
            -20.971,
            2.972,
            3.698,
            -20.176,
            2.673,
            3.698,
            -20.176,
            2.673,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_30_2",
        "polygon": [
            -21.01,
            2.986,
            0.08,
            -21.389,
            3.129,
            0.08,
            -21.389,
            3.129,
            2.928,
            -21.389,
            3.129,
            2.928,
            -21.01,
            2.986,
            2.928,
            -21.01,
            2.986,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_30_3",
        "polygon": [
            -21.01,
            2.986,
            2.928,
            -21.389,
            3.129,
            2.928,
            -21.389,
            3.129,
            3.698,
            -21.389,
            3.129,
            3.698,
            -21.01,
            2.986,
            3.698,
            -21.01,
            2.986,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_28_1",
        "polygon": [
            -22.797,
            3.658,
            0.142,
            -23.592,
            3.957,
            0.142,
            -23.592,
            3.957,
            3.698,
            -23.592,
            3.957,
            3.698,
            -22.797,
            3.658,
            3.698,
            -22.797,
            3.658,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_28_2",
        "polygon": [
            -23.631,
            3.972,
            0.08,
            -24.01,
            4.114,
            0.08,
            -24.01,
            4.114,
            2.928,
            -24.01,
            4.114,
            2.928,
            -23.631,
            3.972,
            2.928,
            -23.631,
            3.972,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_28_3",
        "polygon": [
            -23.631,
            3.972,
            2.928,
            -24.01,
            4.114,
            2.928,
            -24.01,
            4.114,
            3.698,
            -24.01,
            4.114,
            3.698,
            -23.631,
            3.972,
            3.698,
            -23.631,
            3.972,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_26_1",
        "polygon": [
            -25.418,
            4.644,
            0.142,
            -26.212,
            4.942,
            0.142,
            -26.212,
            4.942,
            3.698,
            -26.212,
            4.942,
            3.698,
            -25.418,
            4.644,
            3.698,
            -25.418,
            4.644,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_26_2",
        "polygon": [
            -26.252,
            4.957,
            0.08,
            -26.631,
            5.1,
            0.08,
            -26.631,
            5.1,
            2.928,
            -26.631,
            5.1,
            2.928,
            -26.252,
            4.957,
            2.928,
            -26.252,
            4.957,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_26_3",
        "polygon": [
            -26.252,
            4.957,
            2.928,
            -26.631,
            5.1,
            2.928,
            -26.631,
            5.1,
            3.698,
            -26.631,
            5.1,
            3.698,
            -26.252,
            4.957,
            3.698,
            -26.252,
            4.957,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_24_1",
        "polygon": [
            -28.039,
            5.629,
            0.142,
            -28.833,
            5.928,
            0.142,
            -28.833,
            5.928,
            3.698,
            -28.833,
            5.928,
            3.698,
            -28.039,
            5.629,
            3.698,
            -28.039,
            5.629,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_24_2",
        "polygon": [
            -28.873,
            5.942,
            0.08,
            -29.252,
            6.085,
            0.08,
            -29.252,
            6.085,
            2.928,
            -29.252,
            6.085,
            2.928,
            -28.873,
            5.942,
            2.928,
            -28.873,
            5.942,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_24_3",
        "polygon": [
            -28.873,
            5.942,
            2.928,
            -29.252,
            6.085,
            2.928,
            -29.252,
            6.085,
            3.698,
            -29.252,
            6.085,
            3.698,
            -28.873,
            5.942,
            3.698,
            -28.873,
            5.942,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_22_1",
        "polygon": [
            -30.66,
            6.614,
            0.142,
            -31.454,
            6.913,
            0.142,
            -31.454,
            6.913,
            3.698,
            -31.454,
            6.913,
            3.698,
            -30.66,
            6.614,
            3.698,
            -30.66,
            6.614,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_22_2",
        "polygon": [
            -31.494,
            6.928,
            0.08,
            -31.873,
            7.07,
            0.08,
            -31.873,
            7.07,
            2.928,
            -31.873,
            7.07,
            2.928,
            -31.494,
            6.928,
            2.928,
            -31.494,
            6.928,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_22_3",
        "polygon": [
            -31.494,
            6.928,
            2.928,
            -31.873,
            7.07,
            2.928,
            -31.873,
            7.07,
            3.698,
            -31.873,
            7.07,
            3.698,
            -31.494,
            6.928,
            3.698,
            -31.494,
            6.928,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_20_1",
        "polygon": [
            -33.28,
            7.6,
            0.142,
            -34.075,
            7.898,
            0.142,
            -34.075,
            7.898,
            3.698,
            -34.075,
            7.898,
            3.698,
            -33.28,
            7.6,
            3.698,
            -33.28,
            7.6,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_20_2",
        "polygon": [
            -34.114,
            7.913,
            0.08,
            -34.494,
            8.056,
            0.08,
            -34.494,
            8.056,
            2.928,
            -34.494,
            8.056,
            2.928,
            -34.114,
            7.913,
            2.928,
            -34.114,
            7.913,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_20_3",
        "polygon": [
            -34.114,
            7.913,
            2.928,
            -34.494,
            8.056,
            2.928,
            -34.494,
            8.056,
            3.698,
            -34.494,
            8.056,
            3.698,
            -34.114,
            7.913,
            3.698,
            -34.114,
            7.913,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_18_1",
        "polygon": [
            -35.901,
            8.585,
            0.142,
            -36.696,
            8.884,
            0.142,
            -36.696,
            8.884,
            3.698,
            -36.696,
            8.884,
            3.698,
            -35.901,
            8.585,
            3.698,
            -35.901,
            8.585,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_18_2",
        "polygon": [
            -36.735,
            8.899,
            0.08,
            -37.114,
            9.041,
            0.08,
            -37.114,
            9.041,
            2.928,
            -37.114,
            9.041,
            2.928,
            -36.735,
            8.899,
            2.928,
            -36.735,
            8.899,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_18_3",
        "polygon": [
            -36.735,
            8.899,
            2.928,
            -37.114,
            9.041,
            2.928,
            -37.114,
            9.041,
            3.698,
            -37.114,
            9.041,
            3.698,
            -36.735,
            8.899,
            3.698,
            -36.735,
            8.899,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_16_1",
        "polygon": [
            -38.522,
            9.57,
            0.142,
            -39.317,
            9.869,
            0.142,
            -39.317,
            9.869,
            3.698,
            -39.317,
            9.869,
            3.698,
            -38.522,
            9.57,
            3.698,
            -38.522,
            9.57,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_16_2",
        "polygon": [
            -39.356,
            9.884,
            0.08,
            -39.735,
            10.026,
            0.08,
            -39.735,
            10.026,
            2.928,
            -39.735,
            10.026,
            2.928,
            -39.356,
            9.884,
            2.928,
            -39.356,
            9.884,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_16_3",
        "polygon": [
            -39.356,
            9.884,
            2.928,
            -39.735,
            10.026,
            2.928,
            -39.735,
            10.026,
            3.698,
            -39.735,
            10.026,
            3.698,
            -39.356,
            9.884,
            3.698,
            -39.356,
            9.884,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_14_1",
        "polygon": [
            -41.143,
            10.556,
            0.142,
            -41.938,
            10.854,
            0.142,
            -41.938,
            10.854,
            3.698,
            -41.938,
            10.854,
            3.698,
            -41.143,
            10.556,
            3.698,
            -41.143,
            10.556,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_14_2",
        "polygon": [
            -41.977,
            10.869,
            0.08,
            -42.356,
            11.012,
            0.08,
            -42.356,
            11.012,
            2.928,
            -42.356,
            11.012,
            2.928,
            -41.977,
            10.869,
            2.928,
            -41.977,
            10.869,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_14_3",
        "polygon": [
            -41.977,
            10.869,
            2.928,
            -42.356,
            11.012,
            2.928,
            -42.356,
            11.012,
            3.698,
            -42.356,
            11.012,
            3.698,
            -41.977,
            10.869,
            3.698,
            -41.977,
            10.869,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_10_1",
        "polygon": [
            -46.385,
            12.526,
            0.142,
            -47.18,
            12.825,
            0.142,
            -47.18,
            12.825,
            3.698,
            -47.18,
            12.825,
            3.698,
            -46.385,
            12.526,
            3.698,
            -46.385,
            12.526,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_10_2",
        "polygon": [
            -47.219,
            12.84,
            0.08,
            -47.598,
            12.982,
            0.08,
            -47.598,
            12.982,
            2.928,
            -47.598,
            12.982,
            2.928,
            -47.219,
            12.84,
            2.928,
            -47.219,
            12.84,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_10_3",
        "polygon": [
            -47.219,
            12.84,
            2.928,
            -47.598,
            12.982,
            2.928,
            -47.598,
            12.982,
            3.698,
            -47.598,
            12.982,
            3.698,
            -47.219,
            12.84,
            3.698,
            -47.219,
            12.84,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_8_1",
        "polygon": [
            -49.006,
            13.512,
            0.142,
            -49.8,
            13.81,
            0.142,
            -49.8,
            13.81,
            3.698,
            -49.8,
            13.81,
            3.698,
            -49.006,
            13.512,
            3.698,
            -49.006,
            13.512,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_8_2",
        "polygon": [
            -49.84,
            13.825,
            0.08,
            -50.219,
            13.968,
            0.08,
            -50.219,
            13.968,
            2.928,
            -50.219,
            13.968,
            2.928,
            -49.84,
            13.825,
            2.928,
            -49.84,
            13.825,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_8_3",
        "polygon": [
            -49.84,
            13.825,
            2.928,
            -50.219,
            13.968,
            2.928,
            -50.219,
            13.968,
            3.698,
            -50.219,
            13.968,
            3.698,
            -49.84,
            13.825,
            3.698,
            -49.84,
            13.825,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_4_1",
        "polygon": [
            -54.248,
            15.482,
            0.142,
            -55.042,
            15.781,
            0.142,
            -55.042,
            15.781,
            3.698,
            -55.042,
            15.781,
            3.698,
            -54.248,
            15.482,
            3.698,
            -54.248,
            15.482,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_4_2",
        "polygon": [
            -55.082,
            15.796,
            0.08,
            -55.461,
            15.938,
            0.08,
            -55.461,
            15.938,
            2.928,
            -55.461,
            15.938,
            2.928,
            -55.082,
            15.796,
            2.928,
            -55.082,
            15.796,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_4_3",
        "polygon": [
            -55.082,
            15.796,
            2.928,
            -55.461,
            15.938,
            2.928,
            -55.461,
            15.938,
            3.698,
            -55.461,
            15.938,
            3.698,
            -55.082,
            15.796,
            3.698,
            -55.082,
            15.796,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_2_1",
        "polygon": [
            -56.868,
            16.468,
            0.142,
            -57.663,
            16.767,
            0.142,
            -57.663,
            16.767,
            3.698,
            -57.663,
            16.767,
            3.698,
            -56.868,
            16.468,
            3.698,
            -56.868,
            16.468,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_2_2",
        "polygon": [
            -57.702,
            16.781,
            0.08,
            -58.082,
            16.924,
            0.08,
            -58.082,
            16.924,
            2.928,
            -58.082,
            16.924,
            2.928,
            -57.702,
            16.781,
            2.928,
            -57.702,
            16.781,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_2_3",
        "polygon": [
            -57.702,
            16.781,
            2.928,
            -58.082,
            16.924,
            2.928,
            -58.082,
            16.924,
            3.698,
            -58.082,
            16.924,
            3.698,
            -57.702,
            16.781,
            3.698,
            -57.702,
            16.781,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_6_1",
        "polygon": [
            -51.627,
            14.497,
            0.08,
            -52.61,
            14.867,
            0.08,
            -52.61,
            14.867,
            2.928,
            -52.61,
            14.867,
            2.928,
            -51.627,
            14.497,
            2.928,
            -51.627,
            14.497,
            0.08
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_6_2",
        "polygon": [
            -51.627,
            14.497,
            2.928,
            -52.61,
            14.867,
            2.928,
            -52.61,
            14.867,
            3.698,
            -52.61,
            14.867,
            3.698,
            -51.627,
            14.497,
            3.698,
            -51.627,
            14.497,
            2.928
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_6_3",
        "polygon": [
            -52.649,
            14.881,
            0.142,
            -52.84,
            14.953,
            0.142,
            -52.84,
            14.953,
            3.698,
            -52.84,
            14.953,
            3.698,
            -52.649,
            14.881,
            3.698,
            -52.649,
            14.881,
            0.142
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_12_1",
        "polygon": [
            -43.764,
            11.541,
            0.08,
            -44.747,
            11.911,
            0.08,
            -44.747,
            11.911,
            2.928,
            -44.747,
            11.911,
            2.928,
            -43.764,
            11.541,
            2.928,
            -43.764,
            11.541,
            0.08
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_12_2",
        "polygon": [
            -43.764,
            11.541,
            2.928,
            -44.747,
            11.911,
            2.928,
            -44.747,
            11.911,
            3.698,
            -44.747,
            11.911,
            3.698,
            -43.764,
            11.541,
            3.698,
            -43.764,
            11.541,
            2.928
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_EG_12_3",
        "polygon": [
            -44.786,
            11.925,
            0.142,
            -44.977,
            11.997,
            0.142,
            -44.977,
            11.997,
            3.698,
            -44.977,
            11.997,
            3.698,
            -44.786,
            11.925,
            3.698,
            -44.786,
            11.925,
            0.142
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_2_1",
        "polygon": [
            -125.012,
            42.087,
            0.142,
            -125.806,
            42.386,
            0.142,
            -125.806,
            42.386,
            3.698,
            -125.806,
            42.386,
            3.698,
            -125.012,
            42.087,
            3.698,
            -125.012,
            42.087,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_2_2",
        "polygon": [
            -125.846,
            42.4,
            2.928,
            -126.225,
            42.543,
            2.928,
            -126.225,
            42.543,
            3.698,
            -126.225,
            42.543,
            3.698,
            -125.846,
            42.4,
            3.698,
            -125.846,
            42.4,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_2_3",
        "polygon": [
            -125.846,
            42.4,
            0.08,
            -126.225,
            42.543,
            0.08,
            -126.225,
            42.543,
            2.928,
            -126.225,
            42.543,
            2.928,
            -125.846,
            42.4,
            2.928,
            -125.846,
            42.4,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_4_1",
        "polygon": [
            -122.391,
            41.102,
            0.08,
            -123.374,
            41.471,
            0.08,
            -123.374,
            41.471,
            2.928,
            -123.374,
            41.471,
            2.928,
            -122.391,
            41.102,
            2.928,
            -122.391,
            41.102,
            0.08
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_4_2",
        "polygon": [
            -122.391,
            41.102,
            2.928,
            -123.374,
            41.471,
            2.928,
            -123.374,
            41.471,
            3.698,
            -123.374,
            41.471,
            3.698,
            -122.391,
            41.102,
            3.698,
            -122.391,
            41.102,
            2.928
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_4_3",
        "polygon": [
            -123.413,
            41.486,
            0.142,
            -123.604,
            41.558,
            0.142,
            -123.604,
            41.558,
            3.698,
            -123.604,
            41.558,
            3.698,
            -123.413,
            41.486,
            3.698,
            -123.413,
            41.486,
            0.142
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_6_1",
        "polygon": [
            -119.77,
            40.116,
            0.142,
            -120.565,
            40.415,
            0.142,
            -120.565,
            40.415,
            3.698,
            -120.565,
            40.415,
            3.698,
            -119.77,
            40.116,
            3.698,
            -119.77,
            40.116,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_6_2",
        "polygon": [
            -120.604,
            40.43,
            2.928,
            -120.983,
            40.572,
            2.928,
            -120.983,
            40.572,
            3.698,
            -120.983,
            40.572,
            3.698,
            -120.604,
            40.43,
            3.698,
            -120.604,
            40.43,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_6_3",
        "polygon": [
            -120.604,
            40.43,
            0.08,
            -120.983,
            40.572,
            0.08,
            -120.983,
            40.572,
            2.928,
            -120.983,
            40.572,
            2.928,
            -120.604,
            40.43,
            2.928,
            -120.604,
            40.43,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_8_1",
        "polygon": [
            -117.149,
            39.131,
            0.142,
            -117.944,
            39.43,
            0.142,
            -117.944,
            39.43,
            3.698,
            -117.944,
            39.43,
            3.698,
            -117.149,
            39.131,
            3.698,
            -117.149,
            39.131,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_8_2",
        "polygon": [
            -117.983,
            39.444,
            2.928,
            -118.362,
            39.587,
            2.928,
            -118.362,
            39.587,
            3.698,
            -118.362,
            39.587,
            3.698,
            -117.983,
            39.444,
            3.698,
            -117.983,
            39.444,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_8_3",
        "polygon": [
            -117.983,
            39.444,
            0.08,
            -118.362,
            39.587,
            0.08,
            -118.362,
            39.587,
            2.928,
            -118.362,
            39.587,
            2.928,
            -117.983,
            39.444,
            2.928,
            -117.983,
            39.444,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_10_1",
        "polygon": [
            -114.528,
            38.145,
            0.142,
            -115.323,
            38.444,
            0.142,
            -115.323,
            38.444,
            3.698,
            -115.323,
            38.444,
            3.698,
            -114.528,
            38.145,
            3.698,
            -114.528,
            38.145,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_10_2",
        "polygon": [
            -115.362,
            38.459,
            2.928,
            -115.741,
            38.602,
            2.928,
            -115.741,
            38.602,
            3.698,
            -115.741,
            38.602,
            3.698,
            -115.362,
            38.459,
            3.698,
            -115.362,
            38.459,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_10_3",
        "polygon": [
            -115.362,
            38.459,
            0.08,
            -115.741,
            38.602,
            0.08,
            -115.741,
            38.602,
            2.928,
            -115.741,
            38.602,
            2.928,
            -115.362,
            38.459,
            2.928,
            -115.362,
            38.459,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_14_1",
        "polygon": [
            -109.286,
            36.175,
            0.142,
            -110.081,
            36.474,
            0.142,
            -110.081,
            36.474,
            3.698,
            -110.081,
            36.474,
            3.698,
            -109.286,
            36.175,
            3.698,
            -109.286,
            36.175,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_14_2",
        "polygon": [
            -110.12,
            36.488,
            2.928,
            -110.499,
            36.631,
            2.928,
            -110.499,
            36.631,
            3.698,
            -110.499,
            36.631,
            3.698,
            -110.12,
            36.488,
            3.698,
            -110.12,
            36.488,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_14_3",
        "polygon": [
            -110.12,
            36.488,
            0.08,
            -110.499,
            36.631,
            0.08,
            -110.499,
            36.631,
            2.928,
            -110.499,
            36.631,
            2.928,
            -110.12,
            36.488,
            2.928,
            -110.12,
            36.488,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_18_1",
        "polygon": [
            -104.045,
            34.204,
            0.142,
            -104.839,
            34.503,
            0.142,
            -104.839,
            34.503,
            3.698,
            -104.839,
            34.503,
            3.698,
            -104.045,
            34.204,
            3.698,
            -104.045,
            34.204,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_18_2",
        "polygon": [
            -104.879,
            34.518,
            2.928,
            -105.258,
            34.66,
            2.928,
            -105.258,
            34.66,
            3.698,
            -105.258,
            34.66,
            3.698,
            -104.879,
            34.518,
            3.698,
            -104.879,
            34.518,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_18_3",
        "polygon": [
            -104.879,
            34.518,
            0.08,
            -105.258,
            34.66,
            0.08,
            -105.258,
            34.66,
            2.928,
            -105.258,
            34.66,
            2.928,
            -104.879,
            34.518,
            2.928,
            -104.879,
            34.518,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_20_1",
        "polygon": [
            -101.424,
            33.219,
            0.142,
            -102.218,
            33.517,
            0.142,
            -102.218,
            33.517,
            3.698,
            -102.218,
            33.517,
            3.698,
            -101.424,
            33.219,
            3.698,
            -101.424,
            33.219,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_20_2",
        "polygon": [
            -102.258,
            33.532,
            2.928,
            -102.637,
            33.675,
            2.928,
            -102.637,
            33.675,
            3.698,
            -102.637,
            33.675,
            3.698,
            -102.258,
            33.532,
            3.698,
            -102.258,
            33.532,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_20_3",
        "polygon": [
            -102.258,
            33.532,
            0.08,
            -102.637,
            33.675,
            0.08,
            -102.637,
            33.675,
            2.928,
            -102.637,
            33.675,
            2.928,
            -102.258,
            33.532,
            2.928,
            -102.258,
            33.532,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_22_1",
        "polygon": [
            -98.803,
            32.233,
            0.142,
            -99.597,
            32.532,
            0.142,
            -99.597,
            32.532,
            3.698,
            -99.597,
            32.532,
            3.698,
            -98.803,
            32.233,
            3.698,
            -98.803,
            32.233,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_22_2",
        "polygon": [
            -99.637,
            32.547,
            2.928,
            -100.016,
            32.689,
            2.928,
            -100.016,
            32.689,
            3.698,
            -100.016,
            32.689,
            3.698,
            -99.637,
            32.547,
            3.698,
            -99.637,
            32.547,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_22_3",
        "polygon": [
            -99.637,
            32.547,
            0.08,
            -100.016,
            32.689,
            0.08,
            -100.016,
            32.689,
            2.928,
            -100.016,
            32.689,
            2.928,
            -99.637,
            32.547,
            2.928,
            -99.637,
            32.547,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_26_1",
        "polygon": [
            -93.561,
            30.263,
            0.142,
            -94.356,
            30.561,
            0.142,
            -94.356,
            30.561,
            3.698,
            -94.356,
            30.561,
            3.698,
            -93.561,
            30.263,
            3.698,
            -93.561,
            30.263,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_26_2",
        "polygon": [
            -94.395,
            30.576,
            2.928,
            -94.774,
            30.719,
            2.928,
            -94.774,
            30.719,
            3.698,
            -94.774,
            30.719,
            3.698,
            -94.395,
            30.576,
            3.698,
            -94.395,
            30.576,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_26_3",
        "polygon": [
            -94.395,
            30.576,
            0.08,
            -94.774,
            30.719,
            0.08,
            -94.774,
            30.719,
            2.928,
            -94.774,
            30.719,
            2.928,
            -94.395,
            30.576,
            2.928,
            -94.395,
            30.576,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_28_1",
        "polygon": [
            -90.94,
            29.277,
            0.142,
            -91.735,
            29.576,
            0.142,
            -91.735,
            29.576,
            3.698,
            -91.735,
            29.576,
            3.698,
            -90.94,
            29.277,
            3.698,
            -90.94,
            29.277,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_28_2",
        "polygon": [
            -91.774,
            29.591,
            2.928,
            -92.153,
            29.733,
            2.928,
            -92.153,
            29.733,
            3.698,
            -92.153,
            29.733,
            3.698,
            -91.774,
            29.591,
            3.698,
            -91.774,
            29.591,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_28_3",
        "polygon": [
            -91.774,
            29.591,
            0.08,
            -92.153,
            29.733,
            0.08,
            -92.153,
            29.733,
            2.928,
            -92.153,
            29.733,
            2.928,
            -91.774,
            29.591,
            2.928,
            -91.774,
            29.591,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_30_1",
        "polygon": [
            -88.319,
            28.292,
            0.142,
            -89.114,
            28.591,
            0.142,
            -89.114,
            28.591,
            3.698,
            -89.114,
            28.591,
            3.698,
            -88.319,
            28.292,
            3.698,
            -88.319,
            28.292,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_30_2",
        "polygon": [
            -89.153,
            28.606,
            2.928,
            -89.532,
            28.748,
            2.928,
            -89.532,
            28.748,
            3.698,
            -89.532,
            28.748,
            3.698,
            -89.153,
            28.606,
            3.698,
            -89.153,
            28.606,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_30_3",
        "polygon": [
            -89.153,
            28.606,
            0.08,
            -89.532,
            28.748,
            0.08,
            -89.532,
            28.748,
            2.928,
            -89.532,
            28.748,
            2.928,
            -89.153,
            28.606,
            2.928,
            -89.153,
            28.606,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_34_1",
        "polygon": [
            -83.077,
            26.321,
            0.142,
            -83.872,
            26.62,
            0.142,
            -83.872,
            26.62,
            3.698,
            -83.872,
            26.62,
            3.698,
            -83.077,
            26.321,
            3.698,
            -83.077,
            26.321,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_34_2",
        "polygon": [
            -83.911,
            26.635,
            2.928,
            -84.291,
            26.777,
            2.928,
            -84.291,
            26.777,
            3.698,
            -84.291,
            26.777,
            3.698,
            -83.911,
            26.635,
            3.698,
            -83.911,
            26.635,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_34_3",
        "polygon": [
            -83.911,
            26.635,
            0.08,
            -84.291,
            26.777,
            0.08,
            -84.291,
            26.777,
            2.928,
            -84.291,
            26.777,
            2.928,
            -83.911,
            26.635,
            2.928,
            -83.911,
            26.635,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_36_1",
        "polygon": [
            -80.457,
            25.336,
            0.142,
            -81.251,
            25.635,
            0.142,
            -81.251,
            25.635,
            3.698,
            -81.251,
            25.635,
            3.698,
            -80.457,
            25.336,
            3.698,
            -80.457,
            25.336,
            0.142
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_36_2",
        "polygon": [
            -81.291,
            25.649,
            2.928,
            -81.67,
            25.792,
            2.928,
            -81.67,
            25.792,
            3.698,
            -81.67,
            25.792,
            3.698,
            -81.291,
            25.649,
            3.698,
            -81.291,
            25.649,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_36_3",
        "polygon": [
            -81.291,
            25.649,
            0.08,
            -81.67,
            25.792,
            0.08,
            -81.67,
            25.792,
            2.928,
            -81.67,
            25.792,
            2.928,
            -81.291,
            25.649,
            2.928,
            -81.291,
            25.649,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_12_1",
        "polygon": [
            -111.907,
            37.16,
            0.08,
            -112.89,
            37.53,
            0.08,
            -112.89,
            37.53,
            2.928,
            -112.89,
            37.53,
            2.928,
            -111.907,
            37.16,
            2.928,
            -111.907,
            37.16,
            0.08
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_12_2",
        "polygon": [
            -111.907,
            37.16,
            2.928,
            -112.89,
            37.53,
            2.928,
            -112.89,
            37.53,
            3.698,
            -112.89,
            37.53,
            3.698,
            -111.907,
            37.16,
            3.698,
            -111.907,
            37.16,
            2.928
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_12_3",
        "polygon": [
            -112.929,
            37.544,
            0.142,
            -113.12,
            37.616,
            0.142,
            -113.12,
            37.616,
            3.698,
            -113.12,
            37.616,
            3.698,
            -112.929,
            37.544,
            3.698,
            -112.929,
            37.544,
            0.142
        ],
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_32_1",
        "polygon": [
            -85.698,
            27.307,
            0.08,
            -86.911,
            27.763,
            0.08,
            -86.911,
            27.763,
            2.928,
            -86.911,
            27.763,
            2.928,
            -85.698,
            27.307,
            2.928,
            -85.698,
            27.307,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_32_2",
        "polygon": [
            -85.698,
            27.307,
            2.928,
            -86.911,
            27.763,
            2.928,
            -86.911,
            27.763,
            3.698,
            -86.911,
            27.763,
            3.698,
            -85.698,
            27.307,
            3.698,
            -85.698,
            27.307,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_24_1",
        "polygon": [
            -96.182,
            31.248,
            0.08,
            -97.395,
            31.704,
            0.08,
            -97.395,
            31.704,
            2.928,
            -97.395,
            31.704,
            2.928,
            -96.182,
            31.248,
            2.928,
            -96.182,
            31.248,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_24_2",
        "polygon": [
            -96.182,
            31.248,
            2.928,
            -97.395,
            31.704,
            2.928,
            -97.395,
            31.704,
            3.698,
            -97.395,
            31.704,
            3.698,
            -96.182,
            31.248,
            3.698,
            -96.182,
            31.248,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_16_1",
        "polygon": [
            -106.665,
            35.189,
            0.08,
            -107.879,
            35.645,
            0.08,
            -107.879,
            35.645,
            2.928,
            -107.879,
            35.645,
            2.928,
            -106.665,
            35.189,
            2.928,
            -106.665,
            35.189,
            0.08
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_EG_16_2",
        "polygon": [
            -106.665,
            35.189,
            2.928,
            -107.879,
            35.645,
            2.928,
            -107.879,
            35.645,
            3.698,
            -107.879,
            35.645,
            3.698,
            -106.665,
            35.189,
            3.698,
            -106.665,
            35.189,
            2.928
        ],
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_38_1",
        "polygon": [
            -127.165,
            44.48,
            0.142,
            -126.867,
            45.275,
            0.142,
            -126.867,
            45.275,
            3.698,
            -126.867,
            45.275,
            3.698,
            -127.165,
            44.48,
            3.698,
            -127.165,
            44.48,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_38_2",
        "polygon": [
            -126.852,
            45.314,
            2.928,
            -126.709,
            45.693,
            2.928,
            -126.709,
            45.693,
            3.698,
            -126.709,
            45.693,
            3.698,
            -126.852,
            45.314,
            3.698,
            -126.852,
            45.314,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_38_3",
        "polygon": [
            -126.852,
            45.314,
            0.08,
            -126.709,
            45.693,
            0.08,
            -126.709,
            45.693,
            2.928,
            -126.709,
            45.693,
            2.928,
            -126.852,
            45.314,
            2.928,
            -126.852,
            45.314,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_36_1",
        "polygon": [
            -126.18,
            47.101,
            0.142,
            -125.881,
            47.895,
            0.142,
            -125.881,
            47.895,
            3.698,
            -125.881,
            47.895,
            3.698,
            -126.18,
            47.101,
            3.698,
            -126.18,
            47.101,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_36_2",
        "polygon": [
            -125.866,
            47.935,
            2.928,
            -125.724,
            48.314,
            2.928,
            -125.724,
            48.314,
            3.698,
            -125.724,
            48.314,
            3.698,
            -125.866,
            47.935,
            3.698,
            -125.866,
            47.935,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_36_3",
        "polygon": [
            -125.866,
            47.935,
            0.08,
            -125.724,
            48.314,
            0.08,
            -125.724,
            48.314,
            2.928,
            -125.724,
            48.314,
            2.928,
            -125.866,
            47.935,
            2.928,
            -125.866,
            47.935,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_34_1",
        "polygon": [
            -125.195,
            49.722,
            0.142,
            -124.896,
            50.516,
            0.142,
            -124.896,
            50.516,
            3.698,
            -124.896,
            50.516,
            3.698,
            -125.195,
            49.722,
            3.698,
            -125.195,
            49.722,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_34_2",
        "polygon": [
            -124.881,
            50.556,
            2.928,
            -124.739,
            50.935,
            2.928,
            -124.739,
            50.935,
            3.698,
            -124.739,
            50.935,
            3.698,
            -124.881,
            50.556,
            3.698,
            -124.881,
            50.556,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_34_3",
        "polygon": [
            -124.881,
            50.556,
            0.08,
            -124.739,
            50.935,
            0.08,
            -124.739,
            50.935,
            2.928,
            -124.739,
            50.935,
            2.928,
            -124.881,
            50.556,
            2.928,
            -124.881,
            50.556,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_32_1",
        "polygon": [
            -124.209,
            52.343,
            0.142,
            -123.911,
            53.137,
            0.142,
            -123.911,
            53.137,
            3.698,
            -123.911,
            53.137,
            3.698,
            -124.209,
            52.343,
            3.698,
            -124.209,
            52.343,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_32_2",
        "polygon": [
            -123.896,
            53.176,
            2.928,
            -123.753,
            53.556,
            2.928,
            -123.753,
            53.556,
            3.698,
            -123.753,
            53.556,
            3.698,
            -123.896,
            53.176,
            3.698,
            -123.896,
            53.176,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_32_3",
        "polygon": [
            -123.896,
            53.176,
            0.08,
            -123.753,
            53.556,
            0.08,
            -123.753,
            53.556,
            2.928,
            -123.753,
            53.556,
            2.928,
            -123.896,
            53.176,
            2.928,
            -123.896,
            53.176,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_30_1",
        "polygon": [
            -123.224,
            54.963,
            0.142,
            -122.925,
            55.758,
            0.142,
            -122.925,
            55.758,
            3.698,
            -122.925,
            55.758,
            3.698,
            -123.224,
            54.963,
            3.698,
            -123.224,
            54.963,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_30_2",
        "polygon": [
            -122.91,
            55.797,
            2.928,
            -122.768,
            56.176,
            2.928,
            -122.768,
            56.176,
            3.698,
            -122.768,
            56.176,
            3.698,
            -122.91,
            55.797,
            3.698,
            -122.91,
            55.797,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_30_3",
        "polygon": [
            -122.91,
            55.797,
            0.08,
            -122.768,
            56.176,
            0.08,
            -122.768,
            56.176,
            2.928,
            -122.768,
            56.176,
            2.928,
            -122.91,
            55.797,
            2.928,
            -122.91,
            55.797,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_28_1",
        "polygon": [
            -122.239,
            57.584,
            0.142,
            -121.94,
            58.379,
            0.142,
            -121.94,
            58.379,
            3.698,
            -121.94,
            58.379,
            3.698,
            -122.239,
            57.584,
            3.698,
            -122.239,
            57.584,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_28_2",
        "polygon": [
            -121.925,
            58.418,
            2.928,
            -121.783,
            58.797,
            2.928,
            -121.783,
            58.797,
            3.698,
            -121.783,
            58.797,
            3.698,
            -121.925,
            58.418,
            3.698,
            -121.925,
            58.418,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_28_3",
        "polygon": [
            -121.925,
            58.418,
            0.08,
            -121.783,
            58.797,
            0.08,
            -121.783,
            58.797,
            2.928,
            -121.783,
            58.797,
            2.928,
            -121.925,
            58.418,
            2.928,
            -121.925,
            58.418,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_26_1",
        "polygon": [
            -121.253,
            60.205,
            0.142,
            -120.954,
            61.0,
            0.142,
            -120.954,
            61.0,
            3.698,
            -120.954,
            61.0,
            3.698,
            -121.253,
            60.205,
            3.698,
            -121.253,
            60.205,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_26_2",
        "polygon": [
            -120.94,
            61.039,
            2.928,
            -120.797,
            61.418,
            2.928,
            -120.797,
            61.418,
            3.698,
            -120.797,
            61.418,
            3.698,
            -120.94,
            61.039,
            3.698,
            -120.94,
            61.039,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_26_3",
        "polygon": [
            -120.94,
            61.039,
            0.08,
            -120.797,
            61.418,
            0.08,
            -120.797,
            61.418,
            2.928,
            -120.797,
            61.418,
            2.928,
            -120.94,
            61.039,
            2.928,
            -120.94,
            61.039,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_24_1",
        "polygon": [
            -120.268,
            62.826,
            0.142,
            -119.969,
            63.621,
            0.142,
            -119.969,
            63.621,
            3.698,
            -119.969,
            63.621,
            3.698,
            -120.268,
            62.826,
            3.698,
            -120.268,
            62.826,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_24_2",
        "polygon": [
            -119.954,
            63.66,
            2.928,
            -119.812,
            64.039,
            2.928,
            -119.812,
            64.039,
            3.698,
            -119.812,
            64.039,
            3.698,
            -119.954,
            63.66,
            3.698,
            -119.954,
            63.66,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_24_3",
        "polygon": [
            -119.954,
            63.66,
            0.08,
            -119.812,
            64.039,
            0.08,
            -119.812,
            64.039,
            2.928,
            -119.812,
            64.039,
            2.928,
            -119.954,
            63.66,
            2.928,
            -119.954,
            63.66,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_22_1",
        "polygon": [
            -119.283,
            65.447,
            0.142,
            -118.984,
            66.242,
            0.142,
            -118.984,
            66.242,
            3.698,
            -118.984,
            66.242,
            3.698,
            -119.283,
            65.447,
            3.698,
            -119.283,
            65.447,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_22_2",
        "polygon": [
            -118.969,
            66.281,
            2.928,
            -118.827,
            66.66,
            2.928,
            -118.827,
            66.66,
            3.698,
            -118.827,
            66.66,
            3.698,
            -118.969,
            66.281,
            3.698,
            -118.969,
            66.281,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_22_3",
        "polygon": [
            -118.969,
            66.281,
            0.08,
            -118.827,
            66.66,
            0.08,
            -118.827,
            66.66,
            2.928,
            -118.827,
            66.66,
            2.928,
            -118.969,
            66.281,
            2.928,
            -118.969,
            66.281,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_20_1",
        "polygon": [
            -118.297,
            68.068,
            0.142,
            -117.998,
            68.863,
            0.142,
            -117.998,
            68.863,
            3.698,
            -117.998,
            68.863,
            3.698,
            -118.297,
            68.068,
            3.698,
            -118.297,
            68.068,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_20_2",
        "polygon": [
            -117.984,
            68.902,
            2.928,
            -117.841,
            69.281,
            2.928,
            -117.841,
            69.281,
            3.698,
            -117.841,
            69.281,
            3.698,
            -117.984,
            68.902,
            3.698,
            -117.984,
            68.902,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_20_3",
        "polygon": [
            -117.984,
            68.902,
            0.08,
            -117.841,
            69.281,
            0.08,
            -117.841,
            69.281,
            2.928,
            -117.841,
            69.281,
            2.928,
            -117.984,
            68.902,
            2.928,
            -117.984,
            68.902,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_16_1",
        "polygon": [
            -116.327,
            73.31,
            0.142,
            -116.028,
            74.104,
            0.142,
            -116.028,
            74.104,
            3.698,
            -116.028,
            74.104,
            3.698,
            -116.327,
            73.31,
            3.698,
            -116.327,
            73.31,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_16_2",
        "polygon": [
            -116.013,
            74.144,
            2.928,
            -115.87,
            74.523,
            2.928,
            -115.87,
            74.523,
            3.698,
            -115.87,
            74.523,
            3.698,
            -116.013,
            74.144,
            3.698,
            -116.013,
            74.144,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_16_3",
        "polygon": [
            -116.013,
            74.144,
            0.08,
            -115.87,
            74.523,
            0.08,
            -115.87,
            74.523,
            2.928,
            -115.87,
            74.523,
            2.928,
            -116.013,
            74.144,
            2.928,
            -116.013,
            74.144,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_14_1",
        "polygon": [
            -115.341,
            75.931,
            0.142,
            -115.042,
            76.725,
            0.142,
            -115.042,
            76.725,
            3.698,
            -115.042,
            76.725,
            3.698,
            -115.341,
            75.931,
            3.698,
            -115.341,
            75.931,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_14_2",
        "polygon": [
            -115.028,
            76.765,
            2.928,
            -114.885,
            77.144,
            2.928,
            -114.885,
            77.144,
            3.698,
            -114.885,
            77.144,
            3.698,
            -115.028,
            76.765,
            3.698,
            -115.028,
            76.765,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_14_3",
        "polygon": [
            -115.028,
            76.765,
            0.08,
            -114.885,
            77.144,
            0.08,
            -114.885,
            77.144,
            2.928,
            -114.885,
            77.144,
            2.928,
            -115.028,
            76.765,
            2.928,
            -115.028,
            76.765,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_12_1",
        "polygon": [
            -114.356,
            78.551,
            0.142,
            -114.057,
            79.346,
            0.142,
            -114.057,
            79.346,
            3.698,
            -114.057,
            79.346,
            3.698,
            -114.356,
            78.551,
            3.698,
            -114.356,
            78.551,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_12_2",
        "polygon": [
            -114.042,
            79.385,
            2.928,
            -113.9,
            79.764,
            2.928,
            -113.9,
            79.764,
            3.698,
            -113.9,
            79.764,
            3.698,
            -114.042,
            79.385,
            3.698,
            -114.042,
            79.385,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_12_3",
        "polygon": [
            -114.042,
            79.385,
            0.08,
            -113.9,
            79.764,
            0.08,
            -113.9,
            79.764,
            2.928,
            -113.9,
            79.764,
            2.928,
            -114.042,
            79.385,
            2.928,
            -114.042,
            79.385,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_10_1",
        "polygon": [
            -113.37,
            81.172,
            0.142,
            -113.072,
            81.967,
            0.142,
            -113.072,
            81.967,
            3.698,
            -113.072,
            81.967,
            3.698,
            -113.37,
            81.172,
            3.698,
            -113.37,
            81.172,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_10_2",
        "polygon": [
            -113.057,
            82.006,
            2.928,
            -112.914,
            82.385,
            2.928,
            -112.914,
            82.385,
            3.698,
            -112.914,
            82.385,
            3.698,
            -113.057,
            82.006,
            3.698,
            -113.057,
            82.006,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_10_3",
        "polygon": [
            -113.057,
            82.006,
            0.08,
            -112.914,
            82.385,
            0.08,
            -112.914,
            82.385,
            2.928,
            -112.914,
            82.385,
            2.928,
            -113.057,
            82.006,
            2.928,
            -113.057,
            82.006,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_8_1",
        "polygon": [
            -112.385,
            83.793,
            0.142,
            -112.086,
            84.588,
            0.142,
            -112.086,
            84.588,
            3.698,
            -112.086,
            84.588,
            3.698,
            -112.385,
            83.793,
            3.698,
            -112.385,
            83.793,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_8_2",
        "polygon": [
            -112.072,
            84.627,
            2.928,
            -111.929,
            85.006,
            2.928,
            -111.929,
            85.006,
            3.698,
            -111.929,
            85.006,
            3.698,
            -112.072,
            84.627,
            3.698,
            -112.072,
            84.627,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_8_3",
        "polygon": [
            -112.072,
            84.627,
            0.08,
            -111.929,
            85.006,
            0.08,
            -111.929,
            85.006,
            2.928,
            -111.929,
            85.006,
            2.928,
            -112.072,
            84.627,
            2.928,
            -112.072,
            84.627,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_6_1",
        "polygon": [
            -111.4,
            86.414,
            0.142,
            -111.101,
            87.209,
            0.142,
            -111.101,
            87.209,
            3.698,
            -111.101,
            87.209,
            3.698,
            -111.4,
            86.414,
            3.698,
            -111.4,
            86.414,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_6_2",
        "polygon": [
            -111.086,
            87.248,
            2.928,
            -110.944,
            87.627,
            2.928,
            -110.944,
            87.627,
            3.698,
            -110.944,
            87.627,
            3.698,
            -111.086,
            87.248,
            3.698,
            -111.086,
            87.248,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_6_3",
        "polygon": [
            -111.086,
            87.248,
            0.08,
            -110.944,
            87.627,
            0.08,
            -110.944,
            87.627,
            2.928,
            -110.944,
            87.627,
            2.928,
            -111.086,
            87.248,
            2.928,
            -111.086,
            87.248,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_4_1",
        "polygon": [
            -110.414,
            89.035,
            0.142,
            -110.116,
            89.83,
            0.142,
            -110.116,
            89.83,
            3.698,
            -110.116,
            89.83,
            3.698,
            -110.414,
            89.035,
            3.698,
            -110.414,
            89.035,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_4_2",
        "polygon": [
            -110.101,
            89.869,
            2.928,
            -109.958,
            90.248,
            2.928,
            -109.958,
            90.248,
            3.698,
            -109.958,
            90.248,
            3.698,
            -110.101,
            89.869,
            3.698,
            -110.101,
            89.869,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_4_3",
        "polygon": [
            -110.101,
            89.869,
            0.08,
            -109.958,
            90.248,
            0.08,
            -109.958,
            90.248,
            2.928,
            -109.958,
            90.248,
            2.928,
            -110.101,
            89.869,
            2.928,
            -110.101,
            89.869,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_2_1",
        "polygon": [
            -109.429,
            91.656,
            2.928,
            -109.105,
            92.517,
            2.928,
            -109.105,
            92.517,
            3.698,
            -109.105,
            92.517,
            3.698,
            -109.429,
            91.656,
            3.698,
            -109.429,
            91.656,
            2.928
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_2_2",
        "polygon": [
            -109.429,
            91.656,
            0.08,
            -109.105,
            92.517,
            0.08,
            -109.105,
            92.517,
            2.928,
            -109.105,
            92.517,
            2.928,
            -109.429,
            91.656,
            2.928,
            -109.429,
            91.656,
            0.08
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_2_3",
        "polygon": [
            -109.091,
            92.556,
            0.142,
            -108.973,
            92.869,
            0.142,
            -108.973,
            92.869,
            3.698,
            -108.973,
            92.869,
            3.698,
            -109.091,
            92.556,
            3.698,
            -109.091,
            92.556,
            0.142
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_18_1",
        "polygon": [
            -117.312,
            70.689,
            2.928,
            -116.856,
            71.902,
            2.928,
            -116.856,
            71.902,
            3.698,
            -116.856,
            71.902,
            3.698,
            -117.312,
            70.689,
            3.698,
            -117.312,
            70.689,
            2.928
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_EG_18_2",
        "polygon": [
            -117.312,
            70.689,
            0.08,
            -116.856,
            71.902,
            0.08,
            -116.856,
            71.902,
            2.928,
            -116.856,
            71.902,
            2.928,
            -117.312,
            70.689,
            2.928,
            -117.312,
            70.689,
            0.08
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_35_1",
        "polygon": [
            37.669,
            31.845,
            0.142,
            37.567,
            31.002,
            0.142,
            37.567,
            31.002,
            3.698,
            37.567,
            31.002,
            3.698,
            37.669,
            31.845,
            3.698,
            37.669,
            31.845,
            0.142
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_35_2",
        "polygon": [
            37.562,
            30.96,
            2.928,
            37.513,
            30.558,
            2.928,
            37.513,
            30.558,
            3.698,
            37.513,
            30.558,
            3.698,
            37.562,
            30.96,
            3.698,
            37.562,
            30.96,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_35_3",
        "polygon": [
            37.562,
            30.96,
            0.08,
            37.513,
            30.558,
            0.08,
            37.513,
            30.558,
            2.928,
            37.513,
            30.558,
            2.928,
            37.562,
            30.96,
            2.928,
            37.562,
            30.96,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_33_1",
        "polygon": [
            37.33,
            29.066,
            0.142,
            37.228,
            28.223,
            0.142,
            37.228,
            28.223,
            3.698,
            37.228,
            28.223,
            3.698,
            37.33,
            29.066,
            3.698,
            37.33,
            29.066,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_33_2",
        "polygon": [
            37.223,
            28.181,
            2.928,
            37.174,
            27.779,
            2.928,
            37.174,
            27.779,
            3.698,
            37.174,
            27.779,
            3.698,
            37.223,
            28.181,
            3.698,
            37.223,
            28.181,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_33_3",
        "polygon": [
            37.223,
            28.181,
            0.08,
            37.174,
            27.779,
            0.08,
            37.174,
            27.779,
            2.928,
            37.174,
            27.779,
            2.928,
            37.223,
            28.181,
            2.928,
            37.223,
            28.181,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_31_1",
        "polygon": [
            36.991,
            26.286,
            0.142,
            36.889,
            25.443,
            0.142,
            36.889,
            25.443,
            3.698,
            36.889,
            25.443,
            3.698,
            36.991,
            26.286,
            3.698,
            36.991,
            26.286,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_31_2",
        "polygon": [
            36.884,
            25.402,
            2.928,
            36.835,
            25.0,
            2.928,
            36.835,
            25.0,
            3.698,
            36.835,
            25.0,
            3.698,
            36.884,
            25.402,
            3.698,
            36.884,
            25.402,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_31_3",
        "polygon": [
            36.884,
            25.402,
            0.08,
            36.835,
            25.0,
            0.08,
            36.835,
            25.0,
            2.928,
            36.835,
            25.0,
            2.928,
            36.884,
            25.402,
            2.928,
            36.884,
            25.402,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_29_1",
        "polygon": [
            36.652,
            23.507,
            0.142,
            36.55,
            22.664,
            0.142,
            36.55,
            22.664,
            3.698,
            36.55,
            22.664,
            3.698,
            36.652,
            23.507,
            3.698,
            36.652,
            23.507,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_29_2",
        "polygon": [
            36.545,
            22.622,
            2.928,
            36.496,
            22.22,
            2.928,
            36.496,
            22.22,
            3.698,
            36.496,
            22.22,
            3.698,
            36.545,
            22.622,
            3.698,
            36.545,
            22.622,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_29_3",
        "polygon": [
            36.545,
            22.622,
            0.08,
            36.496,
            22.22,
            0.08,
            36.496,
            22.22,
            2.928,
            36.496,
            22.22,
            2.928,
            36.545,
            22.622,
            2.928,
            36.545,
            22.622,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_27_1",
        "polygon": [
            36.313,
            20.727,
            0.142,
            36.211,
            19.885,
            0.142,
            36.211,
            19.885,
            3.698,
            36.211,
            19.885,
            3.698,
            36.313,
            20.727,
            3.698,
            36.313,
            20.727,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_27_2",
        "polygon": [
            36.206,
            19.843,
            2.928,
            36.157,
            19.441,
            2.928,
            36.157,
            19.441,
            3.698,
            36.157,
            19.441,
            3.698,
            36.206,
            19.843,
            3.698,
            36.206,
            19.843,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_27_3",
        "polygon": [
            36.206,
            19.843,
            0.08,
            36.157,
            19.441,
            0.08,
            36.157,
            19.441,
            2.928,
            36.157,
            19.441,
            2.928,
            36.206,
            19.843,
            2.928,
            36.206,
            19.843,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_25_1",
        "polygon": [
            35.974,
            17.948,
            0.142,
            35.872,
            17.105,
            0.142,
            35.872,
            17.105,
            3.698,
            35.872,
            17.105,
            3.698,
            35.974,
            17.948,
            3.698,
            35.974,
            17.948,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_25_2",
        "polygon": [
            35.867,
            17.063,
            2.928,
            35.817,
            16.661,
            2.928,
            35.817,
            16.661,
            3.698,
            35.817,
            16.661,
            3.698,
            35.867,
            17.063,
            3.698,
            35.867,
            17.063,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_25_3",
        "polygon": [
            35.867,
            17.063,
            0.08,
            35.817,
            16.661,
            0.08,
            35.817,
            16.661,
            2.928,
            35.817,
            16.661,
            2.928,
            35.867,
            17.063,
            2.928,
            35.867,
            17.063,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_23_1",
        "polygon": [
            35.635,
            15.169,
            0.142,
            35.533,
            14.326,
            0.142,
            35.533,
            14.326,
            3.698,
            35.533,
            14.326,
            3.698,
            35.635,
            15.169,
            3.698,
            35.635,
            15.169,
            0.142
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_23_2",
        "polygon": [
            35.528,
            14.284,
            2.928,
            35.478,
            13.882,
            2.928,
            35.478,
            13.882,
            3.698,
            35.478,
            13.882,
            3.698,
            35.528,
            14.284,
            3.698,
            35.528,
            14.284,
            2.928
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_23_3",
        "polygon": [
            35.528,
            14.284,
            0.08,
            35.478,
            13.882,
            0.08,
            35.478,
            13.882,
            2.928,
            35.478,
            13.882,
            2.928,
            35.528,
            14.284,
            2.928,
            35.528,
            14.284,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_21_1",
        "polygon": [
            35.296,
            12.389,
            0.142,
            35.194,
            11.546,
            0.142,
            35.194,
            11.546,
            3.698,
            35.194,
            11.546,
            3.698,
            35.296,
            12.389,
            3.698,
            35.296,
            12.389,
            0.142
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_21_2",
        "polygon": [
            35.189,
            11.505,
            2.928,
            35.139,
            11.103,
            2.928,
            35.139,
            11.103,
            3.698,
            35.139,
            11.103,
            3.698,
            35.189,
            11.505,
            3.698,
            35.189,
            11.505,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_21_3",
        "polygon": [
            35.189,
            11.505,
            0.08,
            35.139,
            11.103,
            0.08,
            35.139,
            11.103,
            2.928,
            35.139,
            11.103,
            2.928,
            35.189,
            11.505,
            2.928,
            35.189,
            11.505,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_19_1",
        "polygon": [
            34.957,
            9.61,
            0.142,
            34.855,
            8.767,
            0.142,
            34.855,
            8.767,
            3.698,
            34.855,
            8.767,
            3.698,
            34.957,
            9.61,
            3.698,
            34.957,
            9.61,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_19_2",
        "polygon": [
            34.849,
            8.725,
            2.928,
            34.8,
            8.323,
            2.928,
            34.8,
            8.323,
            3.698,
            34.8,
            8.323,
            3.698,
            34.849,
            8.725,
            3.698,
            34.849,
            8.725,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_19_3",
        "polygon": [
            34.849,
            8.725,
            0.08,
            34.8,
            8.323,
            0.08,
            34.8,
            8.323,
            2.928,
            34.8,
            8.323,
            2.928,
            34.849,
            8.725,
            2.928,
            34.849,
            8.725,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_17_1",
        "polygon": [
            34.618,
            6.83,
            0.142,
            34.516,
            5.988,
            0.142,
            34.516,
            5.988,
            3.698,
            34.516,
            5.988,
            3.698,
            34.618,
            6.83,
            3.698,
            34.618,
            6.83,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_17_2",
        "polygon": [
            34.51,
            5.946,
            0.09,
            34.461,
            5.544,
            0.09,
            34.461,
            5.544,
            3.13,
            34.461,
            5.544,
            3.13,
            34.51,
            5.946,
            3.13,
            34.51,
            5.946,
            0.09
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_17_3",
        "polygon": [
            34.51,
            5.946,
            3.13,
            34.461,
            5.544,
            3.13,
            34.461,
            5.544,
            3.736,
            34.461,
            5.544,
            3.736,
            34.51,
            5.946,
            3.736,
            34.51,
            5.946,
            3.13
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_15_1",
        "polygon": [
            34.279,
            4.051,
            0.142,
            34.177,
            3.208,
            0.142,
            34.177,
            3.208,
            3.698,
            34.177,
            3.208,
            3.698,
            34.279,
            4.051,
            3.698,
            34.279,
            4.051,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_15_2",
        "polygon": [
            34.171,
            3.166,
            2.928,
            34.122,
            2.764,
            2.928,
            34.122,
            2.764,
            3.698,
            34.122,
            2.764,
            3.698,
            34.171,
            3.166,
            3.698,
            34.171,
            3.166,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_15_3",
        "polygon": [
            34.171,
            3.166,
            0.08,
            34.122,
            2.764,
            0.08,
            34.122,
            2.764,
            2.928,
            34.122,
            2.764,
            2.928,
            34.171,
            3.166,
            2.928,
            34.171,
            3.166,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_13_1",
        "polygon": [
            33.94,
            1.272,
            0.142,
            33.838,
            0.429,
            0.142,
            33.838,
            0.429,
            3.698,
            33.838,
            0.429,
            3.698,
            33.94,
            1.272,
            3.698,
            33.94,
            1.272,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_13_2",
        "polygon": [
            33.832,
            0.387,
            2.928,
            33.783,
            -0.015,
            2.928,
            33.783,
            -0.015,
            3.698,
            33.783,
            -0.015,
            3.698,
            33.832,
            0.387,
            3.698,
            33.832,
            0.387,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_13_3",
        "polygon": [
            33.832,
            0.387,
            0.08,
            33.783,
            -0.015,
            0.08,
            33.783,
            -0.015,
            2.928,
            33.783,
            -0.015,
            2.928,
            33.832,
            0.387,
            2.928,
            33.832,
            0.387,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_11_1",
        "polygon": [
            33.601,
            -1.508,
            0.142,
            33.499,
            -2.351,
            0.142,
            33.499,
            -2.351,
            3.698,
            33.499,
            -2.351,
            3.698,
            33.601,
            -1.508,
            3.698,
            33.601,
            -1.508,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_11_2",
        "polygon": [
            33.493,
            -2.392,
            2.928,
            33.444,
            -2.794,
            2.928,
            33.444,
            -2.794,
            3.698,
            33.444,
            -2.794,
            3.698,
            33.493,
            -2.392,
            3.698,
            33.493,
            -2.392,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_11_3",
        "polygon": [
            33.493,
            -2.392,
            0.08,
            33.444,
            -2.794,
            0.08,
            33.444,
            -2.794,
            2.928,
            33.444,
            -2.794,
            2.928,
            33.493,
            -2.392,
            2.928,
            33.493,
            -2.392,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_9_1",
        "polygon": [
            33.262,
            -4.287,
            0.142,
            33.16,
            -5.13,
            0.142,
            33.16,
            -5.13,
            3.698,
            33.16,
            -5.13,
            3.698,
            33.262,
            -4.287,
            3.698,
            33.262,
            -4.287,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_9_2",
        "polygon": [
            33.154,
            -5.172,
            2.928,
            33.105,
            -5.574,
            2.928,
            33.105,
            -5.574,
            3.698,
            33.105,
            -5.574,
            3.698,
            33.154,
            -5.172,
            3.698,
            33.154,
            -5.172,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_9_3",
        "polygon": [
            33.154,
            -5.172,
            0.08,
            33.105,
            -5.574,
            0.08,
            33.105,
            -5.574,
            2.928,
            33.105,
            -5.574,
            2.928,
            33.154,
            -5.172,
            2.928,
            33.154,
            -5.172,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_7_1",
        "polygon": [
            32.923,
            -7.067,
            0.142,
            32.82,
            -7.909,
            0.142,
            32.82,
            -7.909,
            3.698,
            32.82,
            -7.909,
            3.698,
            32.923,
            -7.067,
            3.698,
            32.923,
            -7.067,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_7_2",
        "polygon": [
            32.815,
            -7.951,
            2.928,
            32.766,
            -8.353,
            2.928,
            32.766,
            -8.353,
            3.698,
            32.766,
            -8.353,
            3.698,
            32.815,
            -7.951,
            3.698,
            32.815,
            -7.951,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_7_3",
        "polygon": [
            32.815,
            -7.951,
            0.08,
            32.766,
            -8.353,
            0.08,
            32.766,
            -8.353,
            2.928,
            32.766,
            -8.353,
            2.928,
            32.815,
            -7.951,
            2.928,
            32.815,
            -7.951,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_5_1",
        "polygon": [
            32.584,
            -9.846,
            0.142,
            32.481,
            -10.689,
            0.142,
            32.481,
            -10.689,
            3.698,
            32.481,
            -10.689,
            3.698,
            32.584,
            -9.846,
            3.698,
            32.584,
            -9.846,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_5_2",
        "polygon": [
            32.476,
            -10.731,
            2.928,
            32.427,
            -11.133,
            2.928,
            32.427,
            -11.133,
            3.698,
            32.427,
            -11.133,
            3.698,
            32.476,
            -10.731,
            3.698,
            32.476,
            -10.731,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_5_3",
        "polygon": [
            32.476,
            -10.731,
            0.08,
            32.427,
            -11.133,
            0.08,
            32.427,
            -11.133,
            2.928,
            32.427,
            -11.133,
            2.928,
            32.476,
            -10.731,
            2.928,
            32.476,
            -10.731,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_1_1",
        "polygon": [
            31.906,
            -15.405,
            0.142,
            31.777,
            -16.467,
            0.142,
            31.777,
            -16.467,
            3.698,
            31.777,
            -16.467,
            3.698,
            31.906,
            -15.405,
            3.698,
            31.906,
            -15.405,
            0.142
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_1_2",
        "polygon": [
            31.772,
            -16.508,
            0.08,
            31.723,
            -16.91,
            0.08,
            31.723,
            -16.91,
            3.278,
            31.723,
            -16.91,
            3.278,
            31.772,
            -16.508,
            3.278,
            31.772,
            -16.508,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_1_3",
        "polygon": [
            31.772,
            -16.508,
            3.278,
            31.723,
            -16.91,
            3.278,
            31.723,
            -16.91,
            3.698,
            31.723,
            -16.91,
            3.698,
            31.772,
            -16.508,
            3.698,
            31.772,
            -16.508,
            3.278
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_3_1",
        "polygon": [
            32.245,
            -12.625,
            0.142,
            32.142,
            -13.468,
            0.142,
            32.142,
            -13.468,
            3.698,
            32.142,
            -13.468,
            3.698,
            32.245,
            -12.625,
            3.698,
            32.245,
            -12.625,
            0.142
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_3_2",
        "polygon": [
            32.137,
            -13.51,
            2.928,
            32.088,
            -13.912,
            2.928,
            32.088,
            -13.912,
            3.698,
            32.088,
            -13.912,
            3.698,
            32.137,
            -13.51,
            3.698,
            32.137,
            -13.51,
            2.928
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_3_3",
        "polygon": [
            32.137,
            -13.51,
            0.08,
            32.088,
            -13.912,
            0.08,
            32.088,
            -13.912,
            2.928,
            32.088,
            -13.912,
            2.928,
            32.137,
            -13.51,
            2.928,
            32.137,
            -13.51,
            0.08
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_4_1",
        "polygon": [
            32.415,
            -11.236,
            2.928,
            32.258,
            -12.522,
            2.928,
            32.258,
            -12.522,
            3.698,
            32.258,
            -12.522,
            3.698,
            32.415,
            -11.236,
            3.698,
            32.415,
            -11.236,
            2.928
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_EG_4_2",
        "polygon": [
            32.415,
            -11.236,
            0.08,
            32.258,
            -12.522,
            0.08,
            32.258,
            -12.522,
            2.928,
            32.258,
            -12.522,
            2.928,
            32.415,
            -11.236,
            2.928,
            32.415,
            -11.236,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_33_1",
        "polygon": [
            37.276,
            28.622,
            4.202,
            37.174,
            27.779,
            4.202,
            37.174,
            27.779,
            7.408,
            37.174,
            27.779,
            7.408,
            37.276,
            28.622,
            7.408,
            37.276,
            28.622,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_33_2",
        "polygon": [
            37.33,
            29.066,
            6.988,
            37.281,
            28.664,
            6.988,
            37.281,
            28.664,
            7.408,
            37.281,
            28.664,
            7.408,
            37.33,
            29.066,
            7.408,
            37.33,
            29.066,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_33_3",
        "polygon": [
            37.33,
            29.066,
            4.14,
            37.281,
            28.664,
            4.14,
            37.281,
            28.664,
            6.988,
            37.281,
            28.664,
            6.988,
            37.33,
            29.066,
            6.988,
            37.33,
            29.066,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_35_1",
        "polygon": [
            37.669,
            31.845,
            6.988,
            37.62,
            31.443,
            6.988,
            37.62,
            31.443,
            7.408,
            37.62,
            31.443,
            7.408,
            37.669,
            31.845,
            7.408,
            37.669,
            31.845,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_35_2",
        "polygon": [
            37.669,
            31.845,
            4.14,
            37.62,
            31.443,
            4.14,
            37.62,
            31.443,
            6.988,
            37.62,
            31.443,
            6.988,
            37.669,
            31.845,
            6.988,
            37.669,
            31.845,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_35_3",
        "polygon": [
            37.615,
            31.401,
            4.202,
            37.513,
            30.558,
            4.202,
            37.513,
            30.558,
            7.408,
            37.513,
            30.558,
            7.408,
            37.615,
            31.401,
            7.408,
            37.615,
            31.401,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_31_1",
        "polygon": [
            36.937,
            25.842,
            4.202,
            36.835,
            25.0,
            4.202,
            36.835,
            25.0,
            7.408,
            36.835,
            25.0,
            7.408,
            36.937,
            25.842,
            7.408,
            36.937,
            25.842,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_31_2",
        "polygon": [
            36.991,
            26.286,
            6.988,
            36.942,
            25.884,
            6.988,
            36.942,
            25.884,
            7.408,
            36.942,
            25.884,
            7.408,
            36.991,
            26.286,
            7.408,
            36.991,
            26.286,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_31_3",
        "polygon": [
            36.991,
            26.286,
            4.14,
            36.942,
            25.884,
            4.14,
            36.942,
            25.884,
            6.988,
            36.942,
            25.884,
            6.988,
            36.991,
            26.286,
            6.988,
            36.991,
            26.286,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_29_1",
        "polygon": [
            36.598,
            23.063,
            4.202,
            36.496,
            22.22,
            4.202,
            36.496,
            22.22,
            7.408,
            36.496,
            22.22,
            7.408,
            36.598,
            23.063,
            7.408,
            36.598,
            23.063,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_29_2",
        "polygon": [
            36.652,
            23.507,
            6.988,
            36.603,
            23.105,
            6.988,
            36.603,
            23.105,
            7.408,
            36.603,
            23.105,
            7.408,
            36.652,
            23.507,
            7.408,
            36.652,
            23.507,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_29_3",
        "polygon": [
            36.652,
            23.507,
            4.14,
            36.603,
            23.105,
            4.14,
            36.603,
            23.105,
            6.988,
            36.603,
            23.105,
            6.988,
            36.652,
            23.507,
            6.988,
            36.652,
            23.507,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_27_1",
        "polygon": [
            36.259,
            20.284,
            4.202,
            36.157,
            19.441,
            4.202,
            36.157,
            19.441,
            7.408,
            36.157,
            19.441,
            7.408,
            36.259,
            20.284,
            7.408,
            36.259,
            20.284,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_27_2",
        "polygon": [
            36.313,
            20.727,
            6.988,
            36.264,
            20.325,
            6.988,
            36.264,
            20.325,
            7.408,
            36.264,
            20.325,
            7.408,
            36.313,
            20.727,
            7.408,
            36.313,
            20.727,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_27_3",
        "polygon": [
            36.313,
            20.727,
            4.14,
            36.264,
            20.325,
            4.14,
            36.264,
            20.325,
            6.988,
            36.264,
            20.325,
            6.988,
            36.313,
            20.727,
            6.988,
            36.313,
            20.727,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_25_1",
        "polygon": [
            35.92,
            17.504,
            4.202,
            35.817,
            16.661,
            4.202,
            35.817,
            16.661,
            7.408,
            35.817,
            16.661,
            7.408,
            35.92,
            17.504,
            7.408,
            35.92,
            17.504,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_25_2",
        "polygon": [
            35.974,
            17.948,
            6.988,
            35.925,
            17.546,
            6.988,
            35.925,
            17.546,
            7.408,
            35.925,
            17.546,
            7.408,
            35.974,
            17.948,
            7.408,
            35.974,
            17.948,
            6.988
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_25_3",
        "polygon": [
            35.974,
            17.948,
            4.14,
            35.925,
            17.546,
            4.14,
            35.925,
            17.546,
            6.988,
            35.925,
            17.546,
            6.988,
            35.974,
            17.948,
            6.988,
            35.974,
            17.948,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_23_1",
        "polygon": [
            35.581,
            14.725,
            4.202,
            35.478,
            13.882,
            4.202,
            35.478,
            13.882,
            7.408,
            35.478,
            13.882,
            7.408,
            35.581,
            14.725,
            7.408,
            35.581,
            14.725,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_23_2",
        "polygon": [
            35.635,
            15.169,
            6.988,
            35.586,
            14.767,
            6.988,
            35.586,
            14.767,
            7.408,
            35.586,
            14.767,
            7.408,
            35.635,
            15.169,
            7.408,
            35.635,
            15.169,
            6.988
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_23_3",
        "polygon": [
            35.635,
            15.169,
            4.14,
            35.586,
            14.767,
            4.14,
            35.586,
            14.767,
            6.988,
            35.586,
            14.767,
            6.988,
            35.635,
            15.169,
            6.988,
            35.635,
            15.169,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_21_1",
        "polygon": [
            35.242,
            11.945,
            4.202,
            35.139,
            11.103,
            4.202,
            35.139,
            11.103,
            7.408,
            35.139,
            11.103,
            7.408,
            35.242,
            11.945,
            7.408,
            35.242,
            11.945,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_21_2",
        "polygon": [
            35.296,
            12.389,
            6.988,
            35.247,
            11.987,
            6.988,
            35.247,
            11.987,
            7.408,
            35.247,
            11.987,
            7.408,
            35.296,
            12.389,
            7.408,
            35.296,
            12.389,
            6.988
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_21_3",
        "polygon": [
            35.296,
            12.389,
            4.14,
            35.247,
            11.987,
            4.14,
            35.247,
            11.987,
            6.988,
            35.247,
            11.987,
            6.988,
            35.296,
            12.389,
            6.988,
            35.296,
            12.389,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_19_1",
        "polygon": [
            34.903,
            9.166,
            4.202,
            34.8,
            8.323,
            4.202,
            34.8,
            8.323,
            7.408,
            34.8,
            8.323,
            7.408,
            34.903,
            9.166,
            7.408,
            34.903,
            9.166,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_19_2",
        "polygon": [
            34.957,
            9.61,
            6.988,
            34.908,
            9.208,
            6.988,
            34.908,
            9.208,
            7.408,
            34.908,
            9.208,
            7.408,
            34.957,
            9.61,
            7.408,
            34.957,
            9.61,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_19_3",
        "polygon": [
            34.957,
            9.61,
            4.14,
            34.908,
            9.208,
            4.14,
            34.908,
            9.208,
            6.988,
            34.908,
            9.208,
            6.988,
            34.957,
            9.61,
            6.988,
            34.957,
            9.61,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_17_1",
        "polygon": [
            34.564,
            6.387,
            4.202,
            34.461,
            5.544,
            4.202,
            34.461,
            5.544,
            7.408,
            34.461,
            5.544,
            7.408,
            34.564,
            6.387,
            7.408,
            34.564,
            6.387,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_17_2",
        "polygon": [
            34.618,
            6.83,
            6.988,
            34.569,
            6.428,
            6.988,
            34.569,
            6.428,
            7.408,
            34.569,
            6.428,
            7.408,
            34.618,
            6.83,
            7.408,
            34.618,
            6.83,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_17_3",
        "polygon": [
            34.618,
            6.83,
            4.14,
            34.569,
            6.428,
            4.14,
            34.569,
            6.428,
            6.988,
            34.569,
            6.428,
            6.988,
            34.618,
            6.83,
            6.988,
            34.618,
            6.83,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_15_1",
        "polygon": [
            34.225,
            3.607,
            4.202,
            34.122,
            2.764,
            4.202,
            34.122,
            2.764,
            7.408,
            34.122,
            2.764,
            7.408,
            34.225,
            3.607,
            7.408,
            34.225,
            3.607,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_15_2",
        "polygon": [
            34.279,
            4.051,
            6.988,
            34.23,
            3.649,
            6.988,
            34.23,
            3.649,
            7.408,
            34.23,
            3.649,
            7.408,
            34.279,
            4.051,
            7.408,
            34.279,
            4.051,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_15_3",
        "polygon": [
            34.279,
            4.051,
            4.14,
            34.23,
            3.649,
            4.14,
            34.23,
            3.649,
            6.988,
            34.23,
            3.649,
            6.988,
            34.279,
            4.051,
            6.988,
            34.279,
            4.051,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_13_1",
        "polygon": [
            33.886,
            0.828,
            4.202,
            33.783,
            -0.015,
            4.202,
            33.783,
            -0.015,
            7.408,
            33.783,
            -0.015,
            7.408,
            33.886,
            0.828,
            7.408,
            33.886,
            0.828,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_13_2",
        "polygon": [
            33.94,
            1.272,
            6.988,
            33.891,
            0.87,
            6.988,
            33.891,
            0.87,
            7.408,
            33.891,
            0.87,
            7.408,
            33.94,
            1.272,
            7.408,
            33.94,
            1.272,
            6.988
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_13_3",
        "polygon": [
            33.94,
            1.272,
            4.14,
            33.891,
            0.87,
            4.14,
            33.891,
            0.87,
            6.988,
            33.891,
            0.87,
            6.988,
            33.94,
            1.272,
            6.988,
            33.94,
            1.272,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_11_1",
        "polygon": [
            33.547,
            -1.952,
            4.202,
            33.444,
            -2.794,
            4.202,
            33.444,
            -2.794,
            7.408,
            33.444,
            -2.794,
            7.408,
            33.547,
            -1.952,
            7.408,
            33.547,
            -1.952,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_11_2",
        "polygon": [
            33.601,
            -1.508,
            6.988,
            33.552,
            -1.91,
            6.988,
            33.552,
            -1.91,
            7.408,
            33.552,
            -1.91,
            7.408,
            33.601,
            -1.508,
            7.408,
            33.601,
            -1.508,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_11_3",
        "polygon": [
            33.601,
            -1.508,
            4.14,
            33.552,
            -1.91,
            4.14,
            33.552,
            -1.91,
            6.988,
            33.552,
            -1.91,
            6.988,
            33.601,
            -1.508,
            6.988,
            33.601,
            -1.508,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_9_1",
        "polygon": [
            33.208,
            -4.731,
            4.202,
            33.105,
            -5.574,
            4.202,
            33.105,
            -5.574,
            7.408,
            33.105,
            -5.574,
            7.408,
            33.208,
            -4.731,
            7.408,
            33.208,
            -4.731,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_9_2",
        "polygon": [
            33.262,
            -4.287,
            6.988,
            33.213,
            -4.689,
            6.988,
            33.213,
            -4.689,
            7.408,
            33.213,
            -4.689,
            7.408,
            33.262,
            -4.287,
            7.408,
            33.262,
            -4.287,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_9_3",
        "polygon": [
            33.262,
            -4.287,
            4.14,
            33.213,
            -4.689,
            4.14,
            33.213,
            -4.689,
            6.988,
            33.213,
            -4.689,
            6.988,
            33.262,
            -4.287,
            6.988,
            33.262,
            -4.287,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_7_1",
        "polygon": [
            32.869,
            -7.51,
            4.202,
            32.766,
            -8.353,
            4.202,
            32.766,
            -8.353,
            7.408,
            32.766,
            -8.353,
            7.408,
            32.869,
            -7.51,
            7.408,
            32.869,
            -7.51,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_7_2",
        "polygon": [
            32.923,
            -7.067,
            6.988,
            32.874,
            -7.469,
            6.988,
            32.874,
            -7.469,
            7.408,
            32.874,
            -7.469,
            7.408,
            32.923,
            -7.067,
            7.408,
            32.923,
            -7.067,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_7_3",
        "polygon": [
            32.923,
            -7.067,
            4.14,
            32.874,
            -7.469,
            4.14,
            32.874,
            -7.469,
            6.988,
            32.874,
            -7.469,
            6.988,
            32.923,
            -7.067,
            6.988,
            32.923,
            -7.067,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_5_1",
        "polygon": [
            32.53,
            -10.29,
            4.202,
            32.427,
            -11.133,
            4.202,
            32.427,
            -11.133,
            7.408,
            32.427,
            -11.133,
            7.408,
            32.53,
            -10.29,
            7.408,
            32.53,
            -10.29,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_5_2",
        "polygon": [
            32.584,
            -9.846,
            6.988,
            32.535,
            -10.248,
            6.988,
            32.535,
            -10.248,
            7.408,
            32.535,
            -10.248,
            7.408,
            32.584,
            -9.846,
            7.408,
            32.584,
            -9.846,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_5_3",
        "polygon": [
            32.584,
            -9.846,
            4.14,
            32.535,
            -10.248,
            4.14,
            32.535,
            -10.248,
            6.988,
            32.535,
            -10.248,
            6.988,
            32.584,
            -9.846,
            6.988,
            32.584,
            -9.846,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_3_1",
        "polygon": [
            32.191,
            -13.069,
            4.202,
            32.088,
            -13.912,
            4.202,
            32.088,
            -13.912,
            7.408,
            32.088,
            -13.912,
            7.408,
            32.191,
            -13.069,
            7.408,
            32.191,
            -13.069,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_3_2",
        "polygon": [
            32.245,
            -12.625,
            6.988,
            32.196,
            -13.027,
            6.988,
            32.196,
            -13.027,
            7.408,
            32.196,
            -13.027,
            7.408,
            32.245,
            -12.625,
            7.408,
            32.245,
            -12.625,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_3_3",
        "polygon": [
            32.245,
            -12.625,
            4.14,
            32.196,
            -13.027,
            4.14,
            32.196,
            -13.027,
            6.988,
            32.196,
            -13.027,
            6.988,
            32.245,
            -12.625,
            6.988,
            32.245,
            -12.625,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_1_1",
        "polygon": [
            31.852,
            -15.848,
            4.202,
            31.723,
            -16.91,
            4.202,
            31.723,
            -16.91,
            7.408,
            31.723,
            -16.91,
            7.408,
            31.852,
            -15.848,
            7.408,
            31.852,
            -15.848,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_1_2",
        "polygon": [
            31.906,
            -15.405,
            6.988,
            31.857,
            -15.807,
            6.988,
            31.857,
            -15.807,
            7.408,
            31.857,
            -15.807,
            7.408,
            31.906,
            -15.405,
            7.408,
            31.906,
            -15.405,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_1OG_1_3",
        "polygon": [
            31.906,
            -15.405,
            4.14,
            31.857,
            -15.807,
            4.14,
            31.857,
            -15.807,
            6.988,
            31.857,
            -15.807,
            6.988,
            31.906,
            -15.405,
            6.988,
            31.906,
            -15.405,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_35_1",
        "polygon": [
            37.669,
            31.845,
            7.912,
            37.567,
            31.002,
            7.912,
            37.567,
            31.002,
            11.118,
            37.567,
            31.002,
            11.118,
            37.669,
            31.845,
            11.118,
            37.669,
            31.845,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_35_2",
        "polygon": [
            37.562,
            30.96,
            10.698,
            37.513,
            30.558,
            10.698,
            37.513,
            30.558,
            11.118,
            37.513,
            30.558,
            11.118,
            37.562,
            30.96,
            11.118,
            37.562,
            30.96,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_35_3",
        "polygon": [
            37.562,
            30.96,
            7.85,
            37.513,
            30.558,
            7.85,
            37.513,
            30.558,
            10.698,
            37.513,
            30.558,
            10.698,
            37.562,
            30.96,
            10.698,
            37.562,
            30.96,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_33_1",
        "polygon": [
            37.33,
            29.066,
            7.912,
            37.228,
            28.223,
            7.912,
            37.228,
            28.223,
            11.118,
            37.228,
            28.223,
            11.118,
            37.33,
            29.066,
            11.118,
            37.33,
            29.066,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_33_2",
        "polygon": [
            37.223,
            28.181,
            10.698,
            37.174,
            27.779,
            10.698,
            37.174,
            27.779,
            11.118,
            37.174,
            27.779,
            11.118,
            37.223,
            28.181,
            11.118,
            37.223,
            28.181,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_33_3",
        "polygon": [
            37.223,
            28.181,
            7.85,
            37.174,
            27.779,
            7.85,
            37.174,
            27.779,
            10.698,
            37.174,
            27.779,
            10.698,
            37.223,
            28.181,
            10.698,
            37.223,
            28.181,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_31_1",
        "polygon": [
            36.991,
            26.286,
            7.912,
            36.889,
            25.443,
            7.912,
            36.889,
            25.443,
            11.118,
            36.889,
            25.443,
            11.118,
            36.991,
            26.286,
            11.118,
            36.991,
            26.286,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_31_2",
        "polygon": [
            36.884,
            25.402,
            10.698,
            36.835,
            25.0,
            10.698,
            36.835,
            25.0,
            11.118,
            36.835,
            25.0,
            11.118,
            36.884,
            25.402,
            11.118,
            36.884,
            25.402,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_31_3",
        "polygon": [
            36.884,
            25.402,
            7.85,
            36.835,
            25.0,
            7.85,
            36.835,
            25.0,
            10.698,
            36.835,
            25.0,
            10.698,
            36.884,
            25.402,
            10.698,
            36.884,
            25.402,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_29_1",
        "polygon": [
            36.652,
            23.507,
            7.912,
            36.55,
            22.664,
            7.912,
            36.55,
            22.664,
            11.118,
            36.55,
            22.664,
            11.118,
            36.652,
            23.507,
            11.118,
            36.652,
            23.507,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_29_2",
        "polygon": [
            36.545,
            22.622,
            10.698,
            36.496,
            22.22,
            10.698,
            36.496,
            22.22,
            11.118,
            36.496,
            22.22,
            11.118,
            36.545,
            22.622,
            11.118,
            36.545,
            22.622,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_29_3",
        "polygon": [
            36.545,
            22.622,
            7.85,
            36.496,
            22.22,
            7.85,
            36.496,
            22.22,
            10.698,
            36.496,
            22.22,
            10.698,
            36.545,
            22.622,
            10.698,
            36.545,
            22.622,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_27_1",
        "polygon": [
            36.313,
            20.727,
            7.912,
            36.211,
            19.885,
            7.912,
            36.211,
            19.885,
            11.118,
            36.211,
            19.885,
            11.118,
            36.313,
            20.727,
            11.118,
            36.313,
            20.727,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_27_2",
        "polygon": [
            36.206,
            19.843,
            10.698,
            36.157,
            19.441,
            10.698,
            36.157,
            19.441,
            11.118,
            36.157,
            19.441,
            11.118,
            36.206,
            19.843,
            11.118,
            36.206,
            19.843,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_27_3",
        "polygon": [
            36.206,
            19.843,
            7.85,
            36.157,
            19.441,
            7.85,
            36.157,
            19.441,
            10.698,
            36.157,
            19.441,
            10.698,
            36.206,
            19.843,
            10.698,
            36.206,
            19.843,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_25_1",
        "polygon": [
            35.974,
            17.948,
            7.912,
            35.872,
            17.105,
            7.912,
            35.872,
            17.105,
            11.118,
            35.872,
            17.105,
            11.118,
            35.974,
            17.948,
            11.118,
            35.974,
            17.948,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_25_2",
        "polygon": [
            35.867,
            17.063,
            10.698,
            35.817,
            16.661,
            10.698,
            35.817,
            16.661,
            11.118,
            35.817,
            16.661,
            11.118,
            35.867,
            17.063,
            11.118,
            35.867,
            17.063,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_25_3",
        "polygon": [
            35.867,
            17.063,
            7.85,
            35.817,
            16.661,
            7.85,
            35.817,
            16.661,
            10.698,
            35.817,
            16.661,
            10.698,
            35.867,
            17.063,
            10.698,
            35.867,
            17.063,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_23_1",
        "polygon": [
            35.635,
            15.169,
            7.912,
            35.533,
            14.326,
            7.912,
            35.533,
            14.326,
            11.118,
            35.533,
            14.326,
            11.118,
            35.635,
            15.169,
            11.118,
            35.635,
            15.169,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_23_2",
        "polygon": [
            35.528,
            14.284,
            10.698,
            35.478,
            13.882,
            10.698,
            35.478,
            13.882,
            11.118,
            35.478,
            13.882,
            11.118,
            35.528,
            14.284,
            11.118,
            35.528,
            14.284,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_23_3",
        "polygon": [
            35.528,
            14.284,
            7.85,
            35.478,
            13.882,
            7.85,
            35.478,
            13.882,
            10.698,
            35.478,
            13.882,
            10.698,
            35.528,
            14.284,
            10.698,
            35.528,
            14.284,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_21_1",
        "polygon": [
            35.296,
            12.389,
            7.912,
            35.194,
            11.546,
            7.912,
            35.194,
            11.546,
            11.118,
            35.194,
            11.546,
            11.118,
            35.296,
            12.389,
            11.118,
            35.296,
            12.389,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_21_2",
        "polygon": [
            35.189,
            11.505,
            10.698,
            35.139,
            11.103,
            10.698,
            35.139,
            11.103,
            11.118,
            35.139,
            11.103,
            11.118,
            35.189,
            11.505,
            11.118,
            35.189,
            11.505,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_21_3",
        "polygon": [
            35.189,
            11.505,
            7.85,
            35.139,
            11.103,
            7.85,
            35.139,
            11.103,
            10.698,
            35.139,
            11.103,
            10.698,
            35.189,
            11.505,
            10.698,
            35.189,
            11.505,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_19_1",
        "polygon": [
            34.957,
            9.61,
            7.912,
            34.855,
            8.767,
            7.912,
            34.855,
            8.767,
            11.118,
            34.855,
            8.767,
            11.118,
            34.957,
            9.61,
            11.118,
            34.957,
            9.61,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_19_2",
        "polygon": [
            34.849,
            8.725,
            10.698,
            34.8,
            8.323,
            10.698,
            34.8,
            8.323,
            11.118,
            34.8,
            8.323,
            11.118,
            34.849,
            8.725,
            11.118,
            34.849,
            8.725,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_19_3",
        "polygon": [
            34.849,
            8.725,
            7.85,
            34.8,
            8.323,
            7.85,
            34.8,
            8.323,
            10.698,
            34.8,
            8.323,
            10.698,
            34.849,
            8.725,
            10.698,
            34.849,
            8.725,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_17_1",
        "polygon": [
            34.618,
            6.83,
            7.912,
            34.516,
            5.988,
            7.912,
            34.516,
            5.988,
            11.118,
            34.516,
            5.988,
            11.118,
            34.618,
            6.83,
            11.118,
            34.618,
            6.83,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_17_2",
        "polygon": [
            34.51,
            5.946,
            10.698,
            34.461,
            5.544,
            10.698,
            34.461,
            5.544,
            11.118,
            34.461,
            5.544,
            11.118,
            34.51,
            5.946,
            11.118,
            34.51,
            5.946,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_17_3",
        "polygon": [
            34.51,
            5.946,
            7.85,
            34.461,
            5.544,
            7.85,
            34.461,
            5.544,
            10.698,
            34.461,
            5.544,
            10.698,
            34.51,
            5.946,
            10.698,
            34.51,
            5.946,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_15_1",
        "polygon": [
            34.279,
            4.051,
            7.912,
            34.177,
            3.208,
            7.912,
            34.177,
            3.208,
            11.118,
            34.177,
            3.208,
            11.118,
            34.279,
            4.051,
            11.118,
            34.279,
            4.051,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_15_2",
        "polygon": [
            34.171,
            3.166,
            10.698,
            34.122,
            2.764,
            10.698,
            34.122,
            2.764,
            11.118,
            34.122,
            2.764,
            11.118,
            34.171,
            3.166,
            11.118,
            34.171,
            3.166,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_15_3",
        "polygon": [
            34.171,
            3.166,
            7.85,
            34.122,
            2.764,
            7.85,
            34.122,
            2.764,
            10.698,
            34.122,
            2.764,
            10.698,
            34.171,
            3.166,
            10.698,
            34.171,
            3.166,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_13_1",
        "polygon": [
            33.94,
            1.272,
            7.912,
            33.838,
            0.429,
            7.912,
            33.838,
            0.429,
            11.118,
            33.838,
            0.429,
            11.118,
            33.94,
            1.272,
            11.118,
            33.94,
            1.272,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_13_2",
        "polygon": [
            33.832,
            0.387,
            10.698,
            33.783,
            -0.015,
            10.698,
            33.783,
            -0.015,
            11.118,
            33.783,
            -0.015,
            11.118,
            33.832,
            0.387,
            11.118,
            33.832,
            0.387,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_13_3",
        "polygon": [
            33.832,
            0.387,
            7.85,
            33.783,
            -0.015,
            7.85,
            33.783,
            -0.015,
            10.698,
            33.783,
            -0.015,
            10.698,
            33.832,
            0.387,
            10.698,
            33.832,
            0.387,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_11_1",
        "polygon": [
            33.601,
            -1.508,
            7.912,
            33.499,
            -2.351,
            7.912,
            33.499,
            -2.351,
            11.118,
            33.499,
            -2.351,
            11.118,
            33.601,
            -1.508,
            11.118,
            33.601,
            -1.508,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_11_2",
        "polygon": [
            33.493,
            -2.392,
            10.698,
            33.444,
            -2.794,
            10.698,
            33.444,
            -2.794,
            11.118,
            33.444,
            -2.794,
            11.118,
            33.493,
            -2.392,
            11.118,
            33.493,
            -2.392,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_11_3",
        "polygon": [
            33.493,
            -2.392,
            7.85,
            33.444,
            -2.794,
            7.85,
            33.444,
            -2.794,
            10.698,
            33.444,
            -2.794,
            10.698,
            33.493,
            -2.392,
            10.698,
            33.493,
            -2.392,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_9_1",
        "polygon": [
            33.262,
            -4.287,
            7.912,
            33.16,
            -5.13,
            7.912,
            33.16,
            -5.13,
            11.118,
            33.16,
            -5.13,
            11.118,
            33.262,
            -4.287,
            11.118,
            33.262,
            -4.287,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_9_2",
        "polygon": [
            33.154,
            -5.172,
            10.698,
            33.105,
            -5.574,
            10.698,
            33.105,
            -5.574,
            11.118,
            33.105,
            -5.574,
            11.118,
            33.154,
            -5.172,
            11.118,
            33.154,
            -5.172,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_9_3",
        "polygon": [
            33.154,
            -5.172,
            7.85,
            33.105,
            -5.574,
            7.85,
            33.105,
            -5.574,
            10.698,
            33.105,
            -5.574,
            10.698,
            33.154,
            -5.172,
            10.698,
            33.154,
            -5.172,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_7_1",
        "polygon": [
            32.923,
            -7.067,
            7.912,
            32.82,
            -7.909,
            7.912,
            32.82,
            -7.909,
            11.118,
            32.82,
            -7.909,
            11.118,
            32.923,
            -7.067,
            11.118,
            32.923,
            -7.067,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_7_2",
        "polygon": [
            32.815,
            -7.951,
            10.698,
            32.766,
            -8.353,
            10.698,
            32.766,
            -8.353,
            11.118,
            32.766,
            -8.353,
            11.118,
            32.815,
            -7.951,
            11.118,
            32.815,
            -7.951,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_7_3",
        "polygon": [
            32.815,
            -7.951,
            7.85,
            32.766,
            -8.353,
            7.85,
            32.766,
            -8.353,
            10.698,
            32.766,
            -8.353,
            10.698,
            32.815,
            -7.951,
            10.698,
            32.815,
            -7.951,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_5_1",
        "polygon": [
            32.584,
            -9.846,
            7.912,
            32.481,
            -10.689,
            7.912,
            32.481,
            -10.689,
            11.118,
            32.481,
            -10.689,
            11.118,
            32.584,
            -9.846,
            11.118,
            32.584,
            -9.846,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_5_2",
        "polygon": [
            32.476,
            -10.731,
            10.698,
            32.427,
            -11.133,
            10.698,
            32.427,
            -11.133,
            11.118,
            32.427,
            -11.133,
            11.118,
            32.476,
            -10.731,
            11.118,
            32.476,
            -10.731,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_5_3",
        "polygon": [
            32.476,
            -10.731,
            7.85,
            32.427,
            -11.133,
            7.85,
            32.427,
            -11.133,
            10.698,
            32.427,
            -11.133,
            10.698,
            32.476,
            -10.731,
            10.698,
            32.476,
            -10.731,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_3_1",
        "polygon": [
            32.245,
            -12.625,
            7.912,
            32.142,
            -13.468,
            7.912,
            32.142,
            -13.468,
            11.118,
            32.142,
            -13.468,
            11.118,
            32.245,
            -12.625,
            11.118,
            32.245,
            -12.625,
            7.912
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_3_2",
        "polygon": [
            32.137,
            -13.51,
            10.698,
            32.088,
            -13.912,
            10.698,
            32.088,
            -13.912,
            11.118,
            32.088,
            -13.912,
            11.118,
            32.137,
            -13.51,
            11.118,
            32.137,
            -13.51,
            10.698
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_3_3",
        "polygon": [
            32.137,
            -13.51,
            7.85,
            32.088,
            -13.912,
            7.85,
            32.088,
            -13.912,
            10.698,
            32.088,
            -13.912,
            10.698,
            32.137,
            -13.51,
            10.698,
            32.137,
            -13.51,
            7.85
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_1_1",
        "polygon": [
            31.906,
            -15.405,
            7.912,
            31.777,
            -16.467,
            7.912,
            31.777,
            -16.467,
            11.118,
            31.777,
            -16.467,
            11.118,
            31.906,
            -15.405,
            11.118,
            31.906,
            -15.405,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_1_2",
        "polygon": [
            31.772,
            -16.508,
            10.698,
            31.723,
            -16.91,
            10.698,
            31.723,
            -16.91,
            11.118,
            31.723,
            -16.91,
            11.118,
            31.772,
            -16.508,
            11.118,
            31.772,
            -16.508,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "Nr.9-Ax23 DS5_GS4_2OG_1_3",
        "polygon": [
            31.772,
            -16.508,
            7.85,
            31.723,
            -16.91,
            7.85,
            31.723,
            -16.91,
            10.698,
            31.723,
            -16.91,
            10.698,
            31.772,
            -16.508,
            10.698,
            31.772,
            -16.508,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 11:30": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_10_1",
        "polygon": [
            -111.433,
            82.001,
            11.622,
            -110.22,
            81.545,
            11.622,
            -110.22,
            81.545,
            14.349,
            -110.22,
            81.545,
            14.349,
            -111.433,
            82.001,
            14.349,
            -111.433,
            82.001,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_10_2",
        "polygon": [
            -111.433,
            82.001,
            14.408,
            -110.22,
            81.545,
            14.408,
            -110.22,
            81.545,
            14.828,
            -110.22,
            81.545,
            14.828,
            -111.433,
            82.001,
            14.828,
            -111.433,
            82.001,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_6_1",
        "polygon": [
            -106.191,
            80.031,
            11.622,
            -104.978,
            79.575,
            11.622,
            -104.978,
            79.575,
            14.349,
            -104.978,
            79.575,
            14.349,
            -106.191,
            80.031,
            14.349,
            -106.191,
            80.031,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_6_2",
        "polygon": [
            -106.191,
            80.031,
            14.408,
            -104.978,
            79.575,
            14.408,
            -104.978,
            79.575,
            14.828,
            -104.978,
            79.575,
            14.828,
            -106.191,
            80.031,
            14.828,
            -106.191,
            80.031,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_2_1",
        "polygon": [
            -100.949,
            78.06,
            11.622,
            -99.736,
            77.604,
            11.622,
            -99.736,
            77.604,
            14.349,
            -99.736,
            77.604,
            14.349,
            -100.949,
            78.06,
            14.349,
            -100.949,
            78.06,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_2_2",
        "polygon": [
            -100.949,
            78.06,
            14.408,
            -99.736,
            77.604,
            14.408,
            -99.736,
            77.604,
            14.828,
            -99.736,
            77.604,
            14.828,
            -100.949,
            78.06,
            14.828,
            -100.949,
            78.06,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_8_1",
        "polygon": [
            -108.393,
            80.859,
            11.622,
            -107.599,
            80.56,
            11.622,
            -107.599,
            80.56,
            14.828,
            -107.599,
            80.56,
            14.828,
            -108.393,
            80.859,
            14.828,
            -108.393,
            80.859,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_8_2",
        "polygon": [
            -108.812,
            81.016,
            11.622,
            -108.433,
            80.874,
            11.622,
            -108.433,
            80.874,
            14.408,
            -108.433,
            80.874,
            14.408,
            -108.812,
            81.016,
            14.408,
            -108.812,
            81.016,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_8_3",
        "polygon": [
            -108.812,
            81.016,
            14.408,
            -108.433,
            80.874,
            14.408,
            -108.433,
            80.874,
            14.828,
            -108.433,
            80.874,
            14.828,
            -108.812,
            81.016,
            14.828,
            -108.812,
            81.016,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_4_1",
        "polygon": [
            -103.152,
            78.888,
            11.622,
            -102.357,
            78.589,
            11.622,
            -102.357,
            78.589,
            14.828,
            -102.357,
            78.589,
            14.828,
            -103.152,
            78.888,
            14.828,
            -103.152,
            78.888,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_4_2",
        "polygon": [
            -103.57,
            79.045,
            11.622,
            -103.191,
            78.903,
            11.622,
            -103.191,
            78.903,
            14.408,
            -103.191,
            78.903,
            14.408,
            -103.57,
            79.045,
            14.408,
            -103.57,
            79.045,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "Nr.14 x000D_Ax B4_1_3B-3.OG_3OG_4_3",
        "polygon": [
            -103.57,
            79.045,
            14.408,
            -103.191,
            78.903,
            14.408,
            -103.191,
            78.903,
            14.828,
            -103.191,
            78.903,
            14.828,
            -103.57,
            79.045,
            14.828,
            -103.57,
            79.045,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_38_1",
        "polygon": [
            -127.008,
            44.898,
            4.202,
            -126.709,
            45.693,
            4.202,
            -126.709,
            45.693,
            7.408,
            -126.709,
            45.693,
            7.408,
            -127.008,
            44.898,
            7.408,
            -127.008,
            44.898,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_38_2",
        "polygon": [
            -127.165,
            44.48,
            6.988,
            -127.023,
            44.859,
            6.988,
            -127.023,
            44.859,
            7.408,
            -127.023,
            44.859,
            7.408,
            -127.165,
            44.48,
            7.408,
            -127.165,
            44.48,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_38_3",
        "polygon": [
            -127.165,
            44.48,
            4.14,
            -127.023,
            44.859,
            4.14,
            -127.023,
            44.859,
            6.988,
            -127.023,
            44.859,
            6.988,
            -127.165,
            44.48,
            6.988,
            -127.165,
            44.48,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_36_1",
        "polygon": [
            -126.023,
            47.519,
            4.202,
            -125.724,
            48.314,
            4.202,
            -125.724,
            48.314,
            7.408,
            -125.724,
            48.314,
            7.408,
            -126.023,
            47.519,
            7.408,
            -126.023,
            47.519,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_36_2",
        "polygon": [
            -126.18,
            47.101,
            6.988,
            -126.037,
            47.48,
            6.988,
            -126.037,
            47.48,
            7.408,
            -126.037,
            47.48,
            7.408,
            -126.18,
            47.101,
            7.408,
            -126.18,
            47.101,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_36_3",
        "polygon": [
            -126.18,
            47.101,
            4.14,
            -126.037,
            47.48,
            4.14,
            -126.037,
            47.48,
            6.988,
            -126.037,
            47.48,
            6.988,
            -126.18,
            47.101,
            6.988,
            -126.18,
            47.101,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_34_1",
        "polygon": [
            -125.037,
            50.14,
            4.202,
            -124.739,
            50.935,
            4.202,
            -124.739,
            50.935,
            7.408,
            -124.739,
            50.935,
            7.408,
            -125.037,
            50.14,
            7.408,
            -125.037,
            50.14,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_34_2",
        "polygon": [
            -125.195,
            49.722,
            6.988,
            -125.052,
            50.101,
            6.988,
            -125.052,
            50.101,
            7.408,
            -125.052,
            50.101,
            7.408,
            -125.195,
            49.722,
            7.408,
            -125.195,
            49.722,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_34_3",
        "polygon": [
            -125.195,
            49.722,
            4.14,
            -125.052,
            50.101,
            4.14,
            -125.052,
            50.101,
            6.988,
            -125.052,
            50.101,
            6.988,
            -125.195,
            49.722,
            6.988,
            -125.195,
            49.722,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_32_1",
        "polygon": [
            -124.052,
            52.761,
            4.202,
            -123.753,
            53.556,
            4.202,
            -123.753,
            53.556,
            7.408,
            -123.753,
            53.556,
            7.408,
            -124.052,
            52.761,
            7.408,
            -124.052,
            52.761,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_32_2",
        "polygon": [
            -124.209,
            52.343,
            6.988,
            -124.067,
            52.722,
            6.988,
            -124.067,
            52.722,
            7.408,
            -124.067,
            52.722,
            7.408,
            -124.209,
            52.343,
            7.408,
            -124.209,
            52.343,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_32_3",
        "polygon": [
            -124.209,
            52.343,
            4.14,
            -124.067,
            52.722,
            4.14,
            -124.067,
            52.722,
            6.988,
            -124.067,
            52.722,
            6.988,
            -124.209,
            52.343,
            6.988,
            -124.209,
            52.343,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_30_1",
        "polygon": [
            -123.067,
            55.382,
            4.202,
            -122.768,
            56.177,
            4.202,
            -122.768,
            56.177,
            7.408,
            -122.768,
            56.177,
            7.408,
            -123.067,
            55.382,
            7.408,
            -123.067,
            55.382,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_30_2",
        "polygon": [
            -123.224,
            54.963,
            6.988,
            -123.081,
            55.342,
            6.988,
            -123.081,
            55.342,
            7.408,
            -123.081,
            55.342,
            7.408,
            -123.224,
            54.963,
            7.408,
            -123.224,
            54.963,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_30_3",
        "polygon": [
            -123.224,
            54.963,
            4.14,
            -123.081,
            55.342,
            4.14,
            -123.081,
            55.342,
            6.988,
            -123.081,
            55.342,
            6.988,
            -123.224,
            54.963,
            6.988,
            -123.224,
            54.963,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_28_1",
        "polygon": [
            -122.081,
            58.003,
            4.202,
            -121.783,
            58.797,
            4.202,
            -121.783,
            58.797,
            7.408,
            -121.783,
            58.797,
            7.408,
            -122.081,
            58.003,
            7.408,
            -122.081,
            58.003,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_28_2",
        "polygon": [
            -122.239,
            57.584,
            6.988,
            -122.096,
            57.963,
            6.988,
            -122.096,
            57.963,
            7.408,
            -122.096,
            57.963,
            7.408,
            -122.239,
            57.584,
            7.408,
            -122.239,
            57.584,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_28_3",
        "polygon": [
            -122.239,
            57.584,
            4.14,
            -122.096,
            57.963,
            4.14,
            -122.096,
            57.963,
            6.988,
            -122.096,
            57.963,
            6.988,
            -122.239,
            57.584,
            6.988,
            -122.239,
            57.584,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_26_1",
        "polygon": [
            -121.096,
            60.624,
            4.202,
            -120.797,
            61.418,
            4.202,
            -120.797,
            61.418,
            7.408,
            -120.797,
            61.418,
            7.408,
            -121.096,
            60.624,
            7.408,
            -121.096,
            60.624,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_26_2",
        "polygon": [
            -121.253,
            60.205,
            6.988,
            -121.111,
            60.584,
            6.988,
            -121.111,
            60.584,
            7.408,
            -121.111,
            60.584,
            7.408,
            -121.253,
            60.205,
            7.408,
            -121.253,
            60.205,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_26_3",
        "polygon": [
            -121.253,
            60.205,
            4.14,
            -121.111,
            60.584,
            4.14,
            -121.111,
            60.584,
            6.988,
            -121.111,
            60.584,
            6.988,
            -121.253,
            60.205,
            6.988,
            -121.253,
            60.205,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_24_1",
        "polygon": [
            -120.111,
            63.244,
            4.202,
            -119.812,
            64.039,
            4.202,
            -119.812,
            64.039,
            7.408,
            -119.812,
            64.039,
            7.408,
            -120.111,
            63.244,
            7.408,
            -120.111,
            63.244,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_24_2",
        "polygon": [
            -120.268,
            62.826,
            6.988,
            -120.125,
            63.205,
            6.988,
            -120.125,
            63.205,
            7.408,
            -120.125,
            63.205,
            7.408,
            -120.268,
            62.826,
            7.408,
            -120.268,
            62.826,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_24_3",
        "polygon": [
            -120.268,
            62.826,
            4.14,
            -120.125,
            63.205,
            4.14,
            -120.125,
            63.205,
            6.988,
            -120.125,
            63.205,
            6.988,
            -120.268,
            62.826,
            6.988,
            -120.268,
            62.826,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_22_1",
        "polygon": [
            -119.125,
            65.865,
            4.202,
            -118.826,
            66.66,
            4.202,
            -118.826,
            66.66,
            7.408,
            -118.826,
            66.66,
            7.408,
            -119.125,
            65.865,
            7.408,
            -119.125,
            65.865,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_22_2",
        "polygon": [
            -119.283,
            65.447,
            6.988,
            -119.14,
            65.826,
            6.988,
            -119.14,
            65.826,
            7.408,
            -119.14,
            65.826,
            7.408,
            -119.283,
            65.447,
            7.408,
            -119.283,
            65.447,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_22_3",
        "polygon": [
            -119.283,
            65.447,
            4.14,
            -119.14,
            65.826,
            4.14,
            -119.14,
            65.826,
            6.988,
            -119.14,
            65.826,
            6.988,
            -119.283,
            65.447,
            6.988,
            -119.283,
            65.447,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_20_1",
        "polygon": [
            -118.14,
            68.486,
            4.202,
            -117.841,
            69.281,
            4.202,
            -117.841,
            69.281,
            7.408,
            -117.841,
            69.281,
            7.408,
            -118.14,
            68.486,
            7.408,
            -118.14,
            68.486,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_20_2",
        "polygon": [
            -118.297,
            68.068,
            6.988,
            -118.155,
            68.447,
            6.988,
            -118.155,
            68.447,
            7.408,
            -118.155,
            68.447,
            7.408,
            -118.297,
            68.068,
            7.408,
            -118.297,
            68.068,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_20_3",
        "polygon": [
            -118.297,
            68.068,
            4.14,
            -118.155,
            68.447,
            4.14,
            -118.155,
            68.447,
            6.988,
            -118.155,
            68.447,
            6.988,
            -118.297,
            68.068,
            6.988,
            -118.297,
            68.068,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_18_1",
        "polygon": [
            -117.155,
            71.107,
            4.202,
            -116.856,
            71.902,
            4.202,
            -116.856,
            71.902,
            7.408,
            -116.856,
            71.902,
            7.408,
            -117.155,
            71.107,
            7.408,
            -117.155,
            71.107,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_18_2",
        "polygon": [
            -117.312,
            70.689,
            6.988,
            -117.169,
            71.068,
            6.988,
            -117.169,
            71.068,
            7.408,
            -117.169,
            71.068,
            7.408,
            -117.312,
            70.689,
            7.408,
            -117.312,
            70.689,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_18_3",
        "polygon": [
            -117.312,
            70.689,
            4.14,
            -117.169,
            71.068,
            4.14,
            -117.169,
            71.068,
            6.988,
            -117.169,
            71.068,
            6.988,
            -117.312,
            70.689,
            6.988,
            -117.312,
            70.689,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_16_1",
        "polygon": [
            -116.169,
            73.728,
            4.202,
            -115.87,
            74.523,
            4.202,
            -115.87,
            74.523,
            7.408,
            -115.87,
            74.523,
            7.408,
            -116.169,
            73.728,
            7.408,
            -116.169,
            73.728,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_16_2",
        "polygon": [
            -116.327,
            73.31,
            6.988,
            -116.184,
            73.689,
            6.988,
            -116.184,
            73.689,
            7.408,
            -116.184,
            73.689,
            7.408,
            -116.327,
            73.31,
            7.408,
            -116.327,
            73.31,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_16_3",
        "polygon": [
            -116.327,
            73.31,
            4.14,
            -116.184,
            73.689,
            4.14,
            -116.184,
            73.689,
            6.988,
            -116.184,
            73.689,
            6.988,
            -116.327,
            73.31,
            6.988,
            -116.327,
            73.31,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_14_1",
        "polygon": [
            -115.184,
            76.349,
            4.202,
            -114.885,
            77.144,
            4.202,
            -114.885,
            77.144,
            7.408,
            -114.885,
            77.144,
            7.408,
            -115.184,
            76.349,
            7.408,
            -115.184,
            76.349,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_14_2",
        "polygon": [
            -115.341,
            75.931,
            6.988,
            -115.199,
            76.31,
            6.988,
            -115.199,
            76.31,
            7.408,
            -115.199,
            76.31,
            7.408,
            -115.341,
            75.931,
            7.408,
            -115.341,
            75.931,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_14_3",
        "polygon": [
            -115.341,
            75.931,
            4.14,
            -115.199,
            76.31,
            4.14,
            -115.199,
            76.31,
            6.988,
            -115.199,
            76.31,
            6.988,
            -115.341,
            75.931,
            6.988,
            -115.341,
            75.931,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_12_1",
        "polygon": [
            -114.199,
            78.97,
            4.202,
            -113.9,
            79.765,
            4.202,
            -113.9,
            79.765,
            7.408,
            -113.9,
            79.765,
            7.408,
            -114.199,
            78.97,
            7.408,
            -114.199,
            78.97,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_12_2",
        "polygon": [
            -114.356,
            78.551,
            6.988,
            -114.213,
            78.931,
            6.988,
            -114.213,
            78.931,
            7.408,
            -114.213,
            78.931,
            7.408,
            -114.356,
            78.551,
            7.408,
            -114.356,
            78.551,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_12_3",
        "polygon": [
            -114.356,
            78.551,
            4.14,
            -114.213,
            78.931,
            4.14,
            -114.213,
            78.931,
            6.988,
            -114.213,
            78.931,
            6.988,
            -114.356,
            78.551,
            6.988,
            -114.356,
            78.551,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_10_1",
        "polygon": [
            -113.213,
            81.591,
            4.202,
            -112.914,
            82.385,
            4.202,
            -112.914,
            82.385,
            7.408,
            -112.914,
            82.385,
            7.408,
            -113.213,
            81.591,
            7.408,
            -113.213,
            81.591,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_10_2",
        "polygon": [
            -113.37,
            81.172,
            6.988,
            -113.228,
            81.551,
            6.988,
            -113.228,
            81.551,
            7.408,
            -113.228,
            81.551,
            7.408,
            -113.37,
            81.172,
            7.408,
            -113.37,
            81.172,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_10_3",
        "polygon": [
            -113.37,
            81.172,
            4.14,
            -113.228,
            81.551,
            4.14,
            -113.228,
            81.551,
            6.988,
            -113.228,
            81.551,
            6.988,
            -113.37,
            81.172,
            6.988,
            -113.37,
            81.172,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_8_1",
        "polygon": [
            -112.228,
            84.212,
            4.202,
            -111.929,
            85.006,
            4.202,
            -111.929,
            85.006,
            7.408,
            -111.929,
            85.006,
            7.408,
            -112.228,
            84.212,
            7.408,
            -112.228,
            84.212,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_8_2",
        "polygon": [
            -112.385,
            83.793,
            6.988,
            -112.243,
            84.172,
            6.988,
            -112.243,
            84.172,
            7.408,
            -112.243,
            84.172,
            7.408,
            -112.385,
            83.793,
            7.408,
            -112.385,
            83.793,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_8_3",
        "polygon": [
            -112.385,
            83.793,
            4.14,
            -112.243,
            84.172,
            4.14,
            -112.243,
            84.172,
            6.988,
            -112.243,
            84.172,
            6.988,
            -112.385,
            83.793,
            6.988,
            -112.385,
            83.793,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_6_1",
        "polygon": [
            -111.242,
            86.833,
            4.202,
            -110.944,
            87.627,
            4.202,
            -110.944,
            87.627,
            7.408,
            -110.944,
            87.627,
            7.408,
            -111.242,
            86.833,
            7.408,
            -111.242,
            86.833,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_6_2",
        "polygon": [
            -111.4,
            86.414,
            6.988,
            -111.257,
            86.793,
            6.988,
            -111.257,
            86.793,
            7.408,
            -111.257,
            86.793,
            7.408,
            -111.4,
            86.414,
            7.408,
            -111.4,
            86.414,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_6_3",
        "polygon": [
            -111.4,
            86.414,
            4.14,
            -111.257,
            86.793,
            4.14,
            -111.257,
            86.793,
            6.988,
            -111.257,
            86.793,
            6.988,
            -111.4,
            86.414,
            6.988,
            -111.4,
            86.414,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_4_1",
        "polygon": [
            -110.257,
            89.453,
            4.202,
            -109.958,
            90.248,
            4.202,
            -109.958,
            90.248,
            7.408,
            -109.958,
            90.248,
            7.408,
            -110.257,
            89.453,
            7.408,
            -110.257,
            89.453,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_4_2",
        "polygon": [
            -110.414,
            89.035,
            6.988,
            -110.272,
            89.414,
            6.988,
            -110.272,
            89.414,
            7.408,
            -110.272,
            89.414,
            7.408,
            -110.414,
            89.035,
            7.408,
            -110.414,
            89.035,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_4_3",
        "polygon": [
            -110.414,
            89.035,
            4.14,
            -110.272,
            89.414,
            4.14,
            -110.272,
            89.414,
            6.988,
            -110.272,
            89.414,
            6.988,
            -110.414,
            89.035,
            6.988,
            -110.414,
            89.035,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_2_1",
        "polygon": [
            -109.297,
            92.008,
            6.948,
            -108.973,
            92.869,
            6.948,
            -108.973,
            92.869,
            7.408,
            -108.973,
            92.869,
            7.408,
            -109.297,
            92.008,
            7.408,
            -109.297,
            92.008,
            6.948
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_2_2",
        "polygon": [
            -109.297,
            92.008,
            4.12,
            -108.973,
            92.869,
            4.12,
            -108.973,
            92.869,
            6.889,
            -108.973,
            92.869,
            6.889,
            -109.297,
            92.008,
            6.889,
            -109.297,
            92.008,
            4.12
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_1OG_2_3",
        "polygon": [
            -109.429,
            91.656,
            4.202,
            -109.312,
            91.968,
            4.202,
            -109.312,
            91.968,
            7.408,
            -109.312,
            91.968,
            7.408,
            -109.429,
            91.656,
            7.408,
            -109.429,
            91.656,
            4.202
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_38_1",
        "polygon": [
            -127.165,
            44.48,
            7.912,
            -126.867,
            45.275,
            7.912,
            -126.867,
            45.275,
            11.118,
            -126.867,
            45.275,
            11.118,
            -127.165,
            44.48,
            11.118,
            -127.165,
            44.48,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_38_2",
        "polygon": [
            -126.852,
            45.314,
            10.698,
            -126.709,
            45.693,
            10.698,
            -126.709,
            45.693,
            11.118,
            -126.709,
            45.693,
            11.118,
            -126.852,
            45.314,
            11.118,
            -126.852,
            45.314,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_38_3",
        "polygon": [
            -126.852,
            45.314,
            7.85,
            -126.709,
            45.693,
            7.85,
            -126.709,
            45.693,
            10.698,
            -126.709,
            45.693,
            10.698,
            -126.852,
            45.314,
            10.698,
            -126.852,
            45.314,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_2_1",
        "polygon": [
            -109.429,
            91.656,
            10.658,
            -109.105,
            92.517,
            10.658,
            -109.105,
            92.517,
            11.118,
            -109.105,
            92.517,
            11.118,
            -109.429,
            91.656,
            11.118,
            -109.429,
            91.656,
            10.658
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_2_2",
        "polygon": [
            -109.429,
            91.656,
            7.83,
            -109.105,
            92.517,
            7.83,
            -109.105,
            92.517,
            10.599,
            -109.105,
            92.517,
            10.599,
            -109.429,
            91.656,
            10.599,
            -109.429,
            91.656,
            7.83
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_2_3",
        "polygon": [
            -109.091,
            92.556,
            7.912,
            -108.973,
            92.869,
            7.912,
            -108.973,
            92.869,
            11.118,
            -108.973,
            92.869,
            11.118,
            -109.091,
            92.556,
            11.118,
            -109.091,
            92.556,
            7.912
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_36_1",
        "polygon": [
            -126.18,
            47.101,
            7.912,
            -125.881,
            47.895,
            7.912,
            -125.881,
            47.895,
            11.118,
            -125.881,
            47.895,
            11.118,
            -126.18,
            47.101,
            11.118,
            -126.18,
            47.101,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_36_2",
        "polygon": [
            -125.866,
            47.935,
            10.698,
            -125.724,
            48.314,
            10.698,
            -125.724,
            48.314,
            11.118,
            -125.724,
            48.314,
            11.118,
            -125.866,
            47.935,
            11.118,
            -125.866,
            47.935,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_36_3",
        "polygon": [
            -125.866,
            47.935,
            7.85,
            -125.724,
            48.314,
            7.85,
            -125.724,
            48.314,
            10.698,
            -125.724,
            48.314,
            10.698,
            -125.866,
            47.935,
            10.698,
            -125.866,
            47.935,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_34_1",
        "polygon": [
            -125.195,
            49.722,
            7.912,
            -124.896,
            50.516,
            7.912,
            -124.896,
            50.516,
            11.118,
            -124.896,
            50.516,
            11.118,
            -125.195,
            49.722,
            11.118,
            -125.195,
            49.722,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_34_2",
        "polygon": [
            -124.881,
            50.556,
            10.698,
            -124.739,
            50.935,
            10.698,
            -124.739,
            50.935,
            11.118,
            -124.739,
            50.935,
            11.118,
            -124.881,
            50.556,
            11.118,
            -124.881,
            50.556,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_34_3",
        "polygon": [
            -124.881,
            50.556,
            7.85,
            -124.739,
            50.935,
            7.85,
            -124.739,
            50.935,
            10.698,
            -124.739,
            50.935,
            10.698,
            -124.881,
            50.556,
            10.698,
            -124.881,
            50.556,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_32_1",
        "polygon": [
            -124.209,
            52.343,
            7.912,
            -123.911,
            53.137,
            7.912,
            -123.911,
            53.137,
            11.118,
            -123.911,
            53.137,
            11.118,
            -124.209,
            52.343,
            11.118,
            -124.209,
            52.343,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_32_2",
        "polygon": [
            -123.896,
            53.177,
            10.698,
            -123.753,
            53.556,
            10.698,
            -123.753,
            53.556,
            11.118,
            -123.753,
            53.556,
            11.118,
            -123.896,
            53.177,
            11.118,
            -123.896,
            53.177,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_32_3",
        "polygon": [
            -123.896,
            53.177,
            7.85,
            -123.753,
            53.556,
            7.85,
            -123.753,
            53.556,
            10.698,
            -123.753,
            53.556,
            10.698,
            -123.896,
            53.177,
            10.698,
            -123.896,
            53.177,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_30_1",
        "polygon": [
            -123.224,
            54.963,
            7.912,
            -122.925,
            55.758,
            7.912,
            -122.925,
            55.758,
            11.118,
            -122.925,
            55.758,
            11.118,
            -123.224,
            54.963,
            11.118,
            -123.224,
            54.963,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_30_2",
        "polygon": [
            -122.91,
            55.797,
            10.698,
            -122.768,
            56.177,
            10.698,
            -122.768,
            56.177,
            11.118,
            -122.768,
            56.177,
            11.118,
            -122.91,
            55.797,
            11.118,
            -122.91,
            55.797,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_30_3",
        "polygon": [
            -122.91,
            55.797,
            7.85,
            -122.768,
            56.177,
            7.85,
            -122.768,
            56.177,
            10.698,
            -122.768,
            56.177,
            10.698,
            -122.91,
            55.797,
            10.698,
            -122.91,
            55.797,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_28_1",
        "polygon": [
            -122.239,
            57.584,
            7.912,
            -121.94,
            58.379,
            7.912,
            -121.94,
            58.379,
            11.118,
            -121.94,
            58.379,
            11.118,
            -122.239,
            57.584,
            11.118,
            -122.239,
            57.584,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_28_2",
        "polygon": [
            -121.925,
            58.418,
            10.698,
            -121.783,
            58.797,
            10.698,
            -121.783,
            58.797,
            11.118,
            -121.783,
            58.797,
            11.118,
            -121.925,
            58.418,
            11.118,
            -121.925,
            58.418,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_28_3",
        "polygon": [
            -121.925,
            58.418,
            7.85,
            -121.783,
            58.797,
            7.85,
            -121.783,
            58.797,
            10.698,
            -121.783,
            58.797,
            10.698,
            -121.925,
            58.418,
            10.698,
            -121.925,
            58.418,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_26_1",
        "polygon": [
            -121.253,
            60.205,
            7.912,
            -120.954,
            61.0,
            7.912,
            -120.954,
            61.0,
            11.118,
            -120.954,
            61.0,
            11.118,
            -121.253,
            60.205,
            11.118,
            -121.253,
            60.205,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_26_2",
        "polygon": [
            -120.94,
            61.039,
            10.698,
            -120.797,
            61.418,
            10.698,
            -120.797,
            61.418,
            11.118,
            -120.797,
            61.418,
            11.118,
            -120.94,
            61.039,
            11.118,
            -120.94,
            61.039,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_26_3",
        "polygon": [
            -120.94,
            61.039,
            7.85,
            -120.797,
            61.418,
            7.85,
            -120.797,
            61.418,
            10.698,
            -120.797,
            61.418,
            10.698,
            -120.94,
            61.039,
            10.698,
            -120.94,
            61.039,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_24_1",
        "polygon": [
            -120.268,
            62.826,
            7.912,
            -119.969,
            63.621,
            7.912,
            -119.969,
            63.621,
            11.118,
            -119.969,
            63.621,
            11.118,
            -120.268,
            62.826,
            11.118,
            -120.268,
            62.826,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_24_2",
        "polygon": [
            -119.954,
            63.66,
            10.698,
            -119.812,
            64.039,
            10.698,
            -119.812,
            64.039,
            11.118,
            -119.812,
            64.039,
            11.118,
            -119.954,
            63.66,
            11.118,
            -119.954,
            63.66,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_24_3",
        "polygon": [
            -119.954,
            63.66,
            7.85,
            -119.812,
            64.039,
            7.85,
            -119.812,
            64.039,
            10.698,
            -119.812,
            64.039,
            10.698,
            -119.954,
            63.66,
            10.698,
            -119.954,
            63.66,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_22_1",
        "polygon": [
            -119.283,
            65.447,
            7.912,
            -118.984,
            66.242,
            7.912,
            -118.984,
            66.242,
            11.118,
            -118.984,
            66.242,
            11.118,
            -119.283,
            65.447,
            11.118,
            -119.283,
            65.447,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_22_2",
        "polygon": [
            -118.969,
            66.281,
            10.698,
            -118.826,
            66.66,
            10.698,
            -118.826,
            66.66,
            11.118,
            -118.826,
            66.66,
            11.118,
            -118.969,
            66.281,
            11.118,
            -118.969,
            66.281,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_22_3",
        "polygon": [
            -118.969,
            66.281,
            7.85,
            -118.826,
            66.66,
            7.85,
            -118.826,
            66.66,
            10.698,
            -118.826,
            66.66,
            10.698,
            -118.969,
            66.281,
            10.698,
            -118.969,
            66.281,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_20_1",
        "polygon": [
            -118.297,
            68.068,
            7.912,
            -117.998,
            68.863,
            7.912,
            -117.998,
            68.863,
            11.118,
            -117.998,
            68.863,
            11.118,
            -118.297,
            68.068,
            11.118,
            -118.297,
            68.068,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_20_2",
        "polygon": [
            -117.984,
            68.902,
            10.698,
            -117.841,
            69.281,
            10.698,
            -117.841,
            69.281,
            11.118,
            -117.841,
            69.281,
            11.118,
            -117.984,
            68.902,
            11.118,
            -117.984,
            68.902,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_20_3",
        "polygon": [
            -117.984,
            68.902,
            7.85,
            -117.841,
            69.281,
            7.85,
            -117.841,
            69.281,
            10.698,
            -117.841,
            69.281,
            10.698,
            -117.984,
            68.902,
            10.698,
            -117.984,
            68.902,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_18_1",
        "polygon": [
            -117.312,
            70.689,
            7.912,
            -117.013,
            71.483,
            7.912,
            -117.013,
            71.483,
            11.118,
            -117.013,
            71.483,
            11.118,
            -117.312,
            70.689,
            11.118,
            -117.312,
            70.689,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_18_2",
        "polygon": [
            -116.998,
            71.523,
            10.698,
            -116.856,
            71.902,
            10.698,
            -116.856,
            71.902,
            11.118,
            -116.856,
            71.902,
            11.118,
            -116.998,
            71.523,
            11.118,
            -116.998,
            71.523,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_18_3",
        "polygon": [
            -116.998,
            71.523,
            7.85,
            -116.856,
            71.902,
            7.85,
            -116.856,
            71.902,
            10.698,
            -116.856,
            71.902,
            10.698,
            -116.998,
            71.523,
            10.698,
            -116.998,
            71.523,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_16_1",
        "polygon": [
            -116.327,
            73.31,
            7.912,
            -116.028,
            74.104,
            7.912,
            -116.028,
            74.104,
            11.118,
            -116.028,
            74.104,
            11.118,
            -116.327,
            73.31,
            11.118,
            -116.327,
            73.31,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_16_2",
        "polygon": [
            -116.013,
            74.144,
            10.698,
            -115.87,
            74.523,
            10.698,
            -115.87,
            74.523,
            11.118,
            -115.87,
            74.523,
            11.118,
            -116.013,
            74.144,
            11.118,
            -116.013,
            74.144,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_16_3",
        "polygon": [
            -116.013,
            74.144,
            7.85,
            -115.87,
            74.523,
            7.85,
            -115.87,
            74.523,
            10.698,
            -115.87,
            74.523,
            10.698,
            -116.013,
            74.144,
            10.698,
            -116.013,
            74.144,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_14_1",
        "polygon": [
            -115.341,
            75.931,
            7.912,
            -115.042,
            76.725,
            7.912,
            -115.042,
            76.725,
            11.118,
            -115.042,
            76.725,
            11.118,
            -115.341,
            75.931,
            11.118,
            -115.341,
            75.931,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_14_2",
        "polygon": [
            -115.028,
            76.765,
            10.698,
            -114.885,
            77.144,
            10.698,
            -114.885,
            77.144,
            11.118,
            -114.885,
            77.144,
            11.118,
            -115.028,
            76.765,
            11.118,
            -115.028,
            76.765,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_14_3",
        "polygon": [
            -115.028,
            76.765,
            7.85,
            -114.885,
            77.144,
            7.85,
            -114.885,
            77.144,
            10.698,
            -114.885,
            77.144,
            10.698,
            -115.028,
            76.765,
            10.698,
            -115.028,
            76.765,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_12_1",
        "polygon": [
            -114.356,
            78.551,
            7.912,
            -114.057,
            79.346,
            7.912,
            -114.057,
            79.346,
            11.118,
            -114.057,
            79.346,
            11.118,
            -114.356,
            78.551,
            11.118,
            -114.356,
            78.551,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_12_2",
        "polygon": [
            -114.042,
            79.385,
            10.698,
            -113.9,
            79.765,
            10.698,
            -113.9,
            79.765,
            11.118,
            -113.9,
            79.765,
            11.118,
            -114.042,
            79.385,
            11.118,
            -114.042,
            79.385,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_12_3",
        "polygon": [
            -114.042,
            79.385,
            7.85,
            -113.9,
            79.765,
            7.85,
            -113.9,
            79.765,
            10.698,
            -113.9,
            79.765,
            10.698,
            -114.042,
            79.385,
            10.698,
            -114.042,
            79.385,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_10_1",
        "polygon": [
            -113.37,
            81.172,
            7.912,
            -113.072,
            81.967,
            7.912,
            -113.072,
            81.967,
            11.118,
            -113.072,
            81.967,
            11.118,
            -113.37,
            81.172,
            11.118,
            -113.37,
            81.172,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_10_2",
        "polygon": [
            -113.057,
            82.006,
            10.698,
            -112.914,
            82.385,
            10.698,
            -112.914,
            82.385,
            11.118,
            -112.914,
            82.385,
            11.118,
            -113.057,
            82.006,
            11.118,
            -113.057,
            82.006,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_10_3",
        "polygon": [
            -113.057,
            82.006,
            7.85,
            -112.914,
            82.385,
            7.85,
            -112.914,
            82.385,
            10.698,
            -112.914,
            82.385,
            10.698,
            -113.057,
            82.006,
            10.698,
            -113.057,
            82.006,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_8_1",
        "polygon": [
            -112.385,
            83.793,
            7.912,
            -112.086,
            84.588,
            7.912,
            -112.086,
            84.588,
            11.118,
            -112.086,
            84.588,
            11.118,
            -112.385,
            83.793,
            11.118,
            -112.385,
            83.793,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_8_2",
        "polygon": [
            -112.072,
            84.627,
            10.698,
            -111.929,
            85.006,
            10.698,
            -111.929,
            85.006,
            11.118,
            -111.929,
            85.006,
            11.118,
            -112.072,
            84.627,
            11.118,
            -112.072,
            84.627,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_8_3",
        "polygon": [
            -112.072,
            84.627,
            7.85,
            -111.929,
            85.006,
            7.85,
            -111.929,
            85.006,
            10.698,
            -111.929,
            85.006,
            10.698,
            -112.072,
            84.627,
            10.698,
            -112.072,
            84.627,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_6_1",
        "polygon": [
            -111.4,
            86.414,
            7.912,
            -111.101,
            87.209,
            7.912,
            -111.101,
            87.209,
            11.118,
            -111.101,
            87.209,
            11.118,
            -111.4,
            86.414,
            11.118,
            -111.4,
            86.414,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_6_2",
        "polygon": [
            -111.086,
            87.248,
            10.698,
            -110.944,
            87.627,
            10.698,
            -110.944,
            87.627,
            11.118,
            -110.944,
            87.627,
            11.118,
            -111.086,
            87.248,
            11.118,
            -111.086,
            87.248,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_6_3",
        "polygon": [
            -111.086,
            87.248,
            7.85,
            -110.944,
            87.627,
            7.85,
            -110.944,
            87.627,
            10.698,
            -110.944,
            87.627,
            10.698,
            -111.086,
            87.248,
            10.698,
            -111.086,
            87.248,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_4_1",
        "polygon": [
            -110.414,
            89.035,
            7.912,
            -110.116,
            89.83,
            7.912,
            -110.116,
            89.83,
            11.118,
            -110.116,
            89.83,
            11.118,
            -110.414,
            89.035,
            11.118,
            -110.414,
            89.035,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_4_2",
        "polygon": [
            -110.101,
            89.869,
            10.698,
            -109.958,
            90.248,
            10.698,
            -109.958,
            90.248,
            11.118,
            -109.958,
            90.248,
            11.118,
            -110.101,
            89.869,
            11.118,
            -110.101,
            89.869,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_2OG_4_3",
        "polygon": [
            -110.101,
            89.869,
            7.85,
            -109.958,
            90.248,
            7.85,
            -109.958,
            90.248,
            10.698,
            -109.958,
            90.248,
            10.698,
            -110.101,
            89.869,
            10.698,
            -110.101,
            89.869,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_38_1",
        "polygon": [
            -127.008,
            44.898,
            11.622,
            -126.709,
            45.693,
            11.622,
            -126.709,
            45.693,
            14.828,
            -126.709,
            45.693,
            14.828,
            -127.008,
            44.898,
            14.828,
            -127.008,
            44.898,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_38_2",
        "polygon": [
            -127.165,
            44.48,
            14.408,
            -127.023,
            44.859,
            14.408,
            -127.023,
            44.859,
            14.828,
            -127.023,
            44.859,
            14.828,
            -127.165,
            44.48,
            14.828,
            -127.165,
            44.48,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_38_3",
        "polygon": [
            -127.165,
            44.48,
            11.56,
            -127.023,
            44.859,
            11.56,
            -127.023,
            44.859,
            14.408,
            -127.023,
            44.859,
            14.408,
            -127.165,
            44.48,
            14.408,
            -127.165,
            44.48,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_36_1",
        "polygon": [
            -126.023,
            47.519,
            11.622,
            -125.724,
            48.314,
            11.622,
            -125.724,
            48.314,
            14.828,
            -125.724,
            48.314,
            14.828,
            -126.023,
            47.519,
            14.828,
            -126.023,
            47.519,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_36_2",
        "polygon": [
            -126.18,
            47.101,
            14.408,
            -126.037,
            47.48,
            14.408,
            -126.037,
            47.48,
            14.828,
            -126.037,
            47.48,
            14.828,
            -126.18,
            47.101,
            14.828,
            -126.18,
            47.101,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_36_3",
        "polygon": [
            -126.18,
            47.101,
            11.56,
            -126.037,
            47.48,
            11.56,
            -126.037,
            47.48,
            14.408,
            -126.037,
            47.48,
            14.408,
            -126.18,
            47.101,
            14.408,
            -126.18,
            47.101,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_34_1",
        "polygon": [
            -125.037,
            50.14,
            11.622,
            -124.739,
            50.935,
            11.622,
            -124.739,
            50.935,
            14.828,
            -124.739,
            50.935,
            14.828,
            -125.037,
            50.14,
            14.828,
            -125.037,
            50.14,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_34_2",
        "polygon": [
            -125.195,
            49.722,
            14.408,
            -125.052,
            50.101,
            14.408,
            -125.052,
            50.101,
            14.828,
            -125.052,
            50.101,
            14.828,
            -125.195,
            49.722,
            14.828,
            -125.195,
            49.722,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_34_3",
        "polygon": [
            -125.195,
            49.722,
            11.56,
            -125.052,
            50.101,
            11.56,
            -125.052,
            50.101,
            14.408,
            -125.052,
            50.101,
            14.408,
            -125.195,
            49.722,
            14.408,
            -125.195,
            49.722,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_32_1",
        "polygon": [
            -124.052,
            52.761,
            11.622,
            -123.753,
            53.556,
            11.622,
            -123.753,
            53.556,
            14.828,
            -123.753,
            53.556,
            14.828,
            -124.052,
            52.761,
            14.828,
            -124.052,
            52.761,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_32_2",
        "polygon": [
            -124.209,
            52.343,
            14.408,
            -124.067,
            52.722,
            14.408,
            -124.067,
            52.722,
            14.828,
            -124.067,
            52.722,
            14.828,
            -124.209,
            52.343,
            14.828,
            -124.209,
            52.343,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_32_3",
        "polygon": [
            -124.209,
            52.343,
            11.56,
            -124.067,
            52.722,
            11.56,
            -124.067,
            52.722,
            14.408,
            -124.067,
            52.722,
            14.408,
            -124.209,
            52.343,
            14.408,
            -124.209,
            52.343,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_30_1",
        "polygon": [
            -123.067,
            55.382,
            11.622,
            -122.768,
            56.177,
            11.622,
            -122.768,
            56.177,
            14.828,
            -122.768,
            56.177,
            14.828,
            -123.067,
            55.382,
            14.828,
            -123.067,
            55.382,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_30_2",
        "polygon": [
            -123.224,
            54.963,
            14.408,
            -123.081,
            55.342,
            14.408,
            -123.081,
            55.342,
            14.828,
            -123.081,
            55.342,
            14.828,
            -123.224,
            54.963,
            14.828,
            -123.224,
            54.963,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_30_3",
        "polygon": [
            -123.224,
            54.963,
            11.56,
            -123.081,
            55.342,
            11.56,
            -123.081,
            55.342,
            14.408,
            -123.081,
            55.342,
            14.408,
            -123.224,
            54.963,
            14.408,
            -123.224,
            54.963,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_28_1",
        "polygon": [
            -122.081,
            58.003,
            11.622,
            -121.783,
            58.797,
            11.622,
            -121.783,
            58.797,
            14.828,
            -121.783,
            58.797,
            14.828,
            -122.081,
            58.003,
            14.828,
            -122.081,
            58.003,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_28_2",
        "polygon": [
            -122.239,
            57.584,
            14.408,
            -122.096,
            57.963,
            14.408,
            -122.096,
            57.963,
            14.828,
            -122.096,
            57.963,
            14.828,
            -122.239,
            57.584,
            14.828,
            -122.239,
            57.584,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_28_3",
        "polygon": [
            -122.239,
            57.584,
            11.56,
            -122.096,
            57.963,
            11.56,
            -122.096,
            57.963,
            14.408,
            -122.096,
            57.963,
            14.408,
            -122.239,
            57.584,
            14.408,
            -122.239,
            57.584,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_26_1",
        "polygon": [
            -121.096,
            60.624,
            11.622,
            -120.797,
            61.418,
            11.622,
            -120.797,
            61.418,
            14.828,
            -120.797,
            61.418,
            14.828,
            -121.096,
            60.624,
            14.828,
            -121.096,
            60.624,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_26_2",
        "polygon": [
            -121.253,
            60.205,
            14.408,
            -121.111,
            60.584,
            14.408,
            -121.111,
            60.584,
            14.828,
            -121.111,
            60.584,
            14.828,
            -121.253,
            60.205,
            14.828,
            -121.253,
            60.205,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_26_3",
        "polygon": [
            -121.253,
            60.205,
            11.56,
            -121.111,
            60.584,
            11.56,
            -121.111,
            60.584,
            14.408,
            -121.111,
            60.584,
            14.408,
            -121.253,
            60.205,
            14.408,
            -121.253,
            60.205,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_24_1",
        "polygon": [
            -120.111,
            63.244,
            11.622,
            -119.812,
            64.039,
            11.622,
            -119.812,
            64.039,
            14.828,
            -119.812,
            64.039,
            14.828,
            -120.111,
            63.244,
            14.828,
            -120.111,
            63.244,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_24_2",
        "polygon": [
            -120.268,
            62.826,
            14.408,
            -120.125,
            63.205,
            14.408,
            -120.125,
            63.205,
            14.828,
            -120.125,
            63.205,
            14.828,
            -120.268,
            62.826,
            14.828,
            -120.268,
            62.826,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_24_3",
        "polygon": [
            -120.268,
            62.826,
            11.56,
            -120.125,
            63.205,
            11.56,
            -120.125,
            63.205,
            14.408,
            -120.125,
            63.205,
            14.408,
            -120.268,
            62.826,
            14.408,
            -120.268,
            62.826,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_22_1",
        "polygon": [
            -119.125,
            65.865,
            11.622,
            -118.826,
            66.66,
            11.622,
            -118.826,
            66.66,
            14.828,
            -118.826,
            66.66,
            14.828,
            -119.125,
            65.865,
            14.828,
            -119.125,
            65.865,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_22_2",
        "polygon": [
            -119.283,
            65.447,
            14.408,
            -119.14,
            65.826,
            14.408,
            -119.14,
            65.826,
            14.828,
            -119.14,
            65.826,
            14.828,
            -119.283,
            65.447,
            14.828,
            -119.283,
            65.447,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_22_3",
        "polygon": [
            -119.283,
            65.447,
            11.56,
            -119.14,
            65.826,
            11.56,
            -119.14,
            65.826,
            14.408,
            -119.14,
            65.826,
            14.408,
            -119.283,
            65.447,
            14.408,
            -119.283,
            65.447,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_20_1",
        "polygon": [
            -118.14,
            68.486,
            11.622,
            -117.841,
            69.281,
            11.622,
            -117.841,
            69.281,
            14.828,
            -117.841,
            69.281,
            14.828,
            -118.14,
            68.486,
            14.828,
            -118.14,
            68.486,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_20_2",
        "polygon": [
            -118.297,
            68.068,
            14.408,
            -118.155,
            68.447,
            14.408,
            -118.155,
            68.447,
            14.828,
            -118.155,
            68.447,
            14.828,
            -118.297,
            68.068,
            14.828,
            -118.297,
            68.068,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_20_3",
        "polygon": [
            -118.297,
            68.068,
            11.56,
            -118.155,
            68.447,
            11.56,
            -118.155,
            68.447,
            14.408,
            -118.155,
            68.447,
            14.408,
            -118.297,
            68.068,
            14.408,
            -118.297,
            68.068,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_18_1",
        "polygon": [
            -117.155,
            71.107,
            11.622,
            -116.856,
            71.902,
            11.622,
            -116.856,
            71.902,
            14.828,
            -116.856,
            71.902,
            14.828,
            -117.155,
            71.107,
            14.828,
            -117.155,
            71.107,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_18_2",
        "polygon": [
            -117.312,
            70.689,
            14.408,
            -117.169,
            71.068,
            14.408,
            -117.169,
            71.068,
            14.828,
            -117.169,
            71.068,
            14.828,
            -117.312,
            70.689,
            14.828,
            -117.312,
            70.689,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_18_3",
        "polygon": [
            -117.312,
            70.689,
            11.56,
            -117.169,
            71.068,
            11.56,
            -117.169,
            71.068,
            14.408,
            -117.169,
            71.068,
            14.408,
            -117.312,
            70.689,
            14.408,
            -117.312,
            70.689,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_16_1",
        "polygon": [
            -116.169,
            73.728,
            11.622,
            -115.87,
            74.523,
            11.622,
            -115.87,
            74.523,
            14.828,
            -115.87,
            74.523,
            14.828,
            -116.169,
            73.728,
            14.828,
            -116.169,
            73.728,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_16_2",
        "polygon": [
            -116.327,
            73.31,
            14.408,
            -116.184,
            73.689,
            14.408,
            -116.184,
            73.689,
            14.828,
            -116.184,
            73.689,
            14.828,
            -116.327,
            73.31,
            14.828,
            -116.327,
            73.31,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_16_3",
        "polygon": [
            -116.327,
            73.31,
            11.56,
            -116.184,
            73.689,
            11.56,
            -116.184,
            73.689,
            14.408,
            -116.184,
            73.689,
            14.408,
            -116.327,
            73.31,
            14.408,
            -116.327,
            73.31,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_14_1",
        "polygon": [
            -115.184,
            76.349,
            11.622,
            -114.885,
            77.144,
            11.622,
            -114.885,
            77.144,
            14.828,
            -114.885,
            77.144,
            14.828,
            -115.184,
            76.349,
            14.828,
            -115.184,
            76.349,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_14_2",
        "polygon": [
            -115.341,
            75.931,
            14.408,
            -115.199,
            76.31,
            14.408,
            -115.199,
            76.31,
            14.828,
            -115.199,
            76.31,
            14.828,
            -115.341,
            75.931,
            14.828,
            -115.341,
            75.931,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_14_3",
        "polygon": [
            -115.341,
            75.931,
            11.56,
            -115.199,
            76.31,
            11.56,
            -115.199,
            76.31,
            14.408,
            -115.199,
            76.31,
            14.408,
            -115.341,
            75.931,
            14.408,
            -115.341,
            75.931,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_12_1",
        "polygon": [
            -114.199,
            78.97,
            11.622,
            -113.9,
            79.765,
            11.622,
            -113.9,
            79.765,
            14.828,
            -113.9,
            79.765,
            14.828,
            -114.199,
            78.97,
            14.828,
            -114.199,
            78.97,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_12_2",
        "polygon": [
            -114.356,
            78.551,
            14.408,
            -114.213,
            78.931,
            14.408,
            -114.213,
            78.931,
            14.828,
            -114.213,
            78.931,
            14.828,
            -114.356,
            78.551,
            14.828,
            -114.356,
            78.551,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_12_3",
        "polygon": [
            -114.356,
            78.551,
            11.56,
            -114.213,
            78.931,
            11.56,
            -114.213,
            78.931,
            14.408,
            -114.213,
            78.931,
            14.408,
            -114.356,
            78.551,
            14.408,
            -114.356,
            78.551,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_10_1",
        "polygon": [
            -113.213,
            81.591,
            11.622,
            -112.85,
            82.556,
            11.622,
            -112.85,
            82.556,
            14.828,
            -112.85,
            82.556,
            14.828,
            -113.213,
            81.591,
            14.828,
            -113.213,
            81.591,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_10_2",
        "polygon": [
            -113.37,
            81.172,
            14.408,
            -113.228,
            81.551,
            14.408,
            -113.228,
            81.551,
            14.828,
            -113.228,
            81.551,
            14.828,
            -113.37,
            81.172,
            14.828,
            -113.37,
            81.172,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.1-Ax1 A_J_3OG_10_3",
        "polygon": [
            -113.37,
            81.172,
            11.56,
            -113.228,
            81.551,
            11.56,
            -113.228,
            81.551,
            14.408,
            -113.228,
            81.551,
            14.408,
            -113.37,
            81.172,
            14.408,
            -113.37,
            81.172,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_36_1",
        "polygon": [
            -80.875,
            25.493,
            4.202,
            -81.67,
            25.792,
            4.202,
            -81.67,
            25.792,
            7.408,
            -81.67,
            25.792,
            7.408,
            -80.875,
            25.493,
            7.408,
            -80.875,
            25.493,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_36_2",
        "polygon": [
            -80.457,
            25.336,
            6.988,
            -80.836,
            25.478,
            6.988,
            -80.836,
            25.478,
            7.408,
            -80.836,
            25.478,
            7.408,
            -80.457,
            25.336,
            7.408,
            -80.457,
            25.336,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_36_3",
        "polygon": [
            -80.457,
            25.336,
            4.14,
            -80.836,
            25.478,
            4.14,
            -80.836,
            25.478,
            6.988,
            -80.836,
            25.478,
            6.988,
            -80.457,
            25.336,
            6.988,
            -80.457,
            25.336,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_34_1",
        "polygon": [
            -83.496,
            26.479,
            4.202,
            -84.291,
            26.777,
            4.202,
            -84.291,
            26.777,
            7.408,
            -84.291,
            26.777,
            7.408,
            -83.496,
            26.479,
            7.408,
            -83.496,
            26.479,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_34_2",
        "polygon": [
            -83.077,
            26.321,
            6.988,
            -83.456,
            26.464,
            6.988,
            -83.456,
            26.464,
            7.408,
            -83.456,
            26.464,
            7.408,
            -83.077,
            26.321,
            7.408,
            -83.077,
            26.321,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_34_3",
        "polygon": [
            -83.077,
            26.321,
            4.14,
            -83.456,
            26.464,
            4.14,
            -83.456,
            26.464,
            6.988,
            -83.456,
            26.464,
            6.988,
            -83.077,
            26.321,
            6.988,
            -83.077,
            26.321,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_32_1",
        "polygon": [
            -86.117,
            27.464,
            4.202,
            -86.911,
            27.763,
            4.202,
            -86.911,
            27.763,
            7.408,
            -86.911,
            27.763,
            7.408,
            -86.117,
            27.464,
            7.408,
            -86.117,
            27.464,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_32_2",
        "polygon": [
            -85.698,
            27.307,
            6.988,
            -86.077,
            27.449,
            6.988,
            -86.077,
            27.449,
            7.408,
            -86.077,
            27.449,
            7.408,
            -85.698,
            27.307,
            7.408,
            -85.698,
            27.307,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_32_3",
        "polygon": [
            -85.698,
            27.307,
            4.14,
            -86.077,
            27.449,
            4.14,
            -86.077,
            27.449,
            6.988,
            -86.077,
            27.449,
            6.988,
            -85.698,
            27.307,
            6.988,
            -85.698,
            27.307,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_30_1",
        "polygon": [
            -88.738,
            28.449,
            4.202,
            -89.532,
            28.748,
            4.202,
            -89.532,
            28.748,
            7.408,
            -89.532,
            28.748,
            7.408,
            -88.738,
            28.449,
            7.408,
            -88.738,
            28.449,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_30_2",
        "polygon": [
            -88.319,
            28.292,
            6.988,
            -88.698,
            28.434,
            6.988,
            -88.698,
            28.434,
            7.408,
            -88.698,
            28.434,
            7.408,
            -88.319,
            28.292,
            7.408,
            -88.319,
            28.292,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_30_3",
        "polygon": [
            -88.319,
            28.292,
            4.14,
            -88.698,
            28.434,
            4.14,
            -88.698,
            28.434,
            6.988,
            -88.698,
            28.434,
            6.988,
            -88.319,
            28.292,
            6.988,
            -88.319,
            28.292,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_28_1",
        "polygon": [
            -91.358,
            29.435,
            4.202,
            -92.153,
            29.733,
            4.202,
            -92.153,
            29.733,
            7.408,
            -92.153,
            29.733,
            7.408,
            -91.358,
            29.435,
            7.408,
            -91.358,
            29.435,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_28_2",
        "polygon": [
            -90.94,
            29.277,
            6.988,
            -91.319,
            29.42,
            6.988,
            -91.319,
            29.42,
            7.408,
            -91.319,
            29.42,
            7.408,
            -90.94,
            29.277,
            7.408,
            -90.94,
            29.277,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_28_3",
        "polygon": [
            -90.94,
            29.277,
            4.14,
            -91.319,
            29.42,
            4.14,
            -91.319,
            29.42,
            6.988,
            -91.319,
            29.42,
            6.988,
            -90.94,
            29.277,
            6.988,
            -90.94,
            29.277,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_26_1",
        "polygon": [
            -93.979,
            30.42,
            4.202,
            -94.774,
            30.719,
            4.202,
            -94.774,
            30.719,
            7.408,
            -94.774,
            30.719,
            7.408,
            -93.979,
            30.42,
            7.408,
            -93.979,
            30.42,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_26_2",
        "polygon": [
            -93.561,
            30.263,
            6.988,
            -93.94,
            30.405,
            6.988,
            -93.94,
            30.405,
            7.408,
            -93.94,
            30.405,
            7.408,
            -93.561,
            30.263,
            7.408,
            -93.561,
            30.263,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_26_3",
        "polygon": [
            -93.561,
            30.263,
            4.14,
            -93.94,
            30.405,
            4.14,
            -93.94,
            30.405,
            6.988,
            -93.94,
            30.405,
            6.988,
            -93.561,
            30.263,
            6.988,
            -93.561,
            30.263,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_24_1",
        "polygon": [
            -96.6,
            31.405,
            4.202,
            -97.395,
            31.704,
            4.202,
            -97.395,
            31.704,
            7.408,
            -97.395,
            31.704,
            7.408,
            -96.6,
            31.405,
            7.408,
            -96.6,
            31.405,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_24_2",
        "polygon": [
            -96.182,
            31.248,
            6.988,
            -96.561,
            31.391,
            6.988,
            -96.561,
            31.391,
            7.408,
            -96.561,
            31.391,
            7.408,
            -96.182,
            31.248,
            7.408,
            -96.182,
            31.248,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_24_3",
        "polygon": [
            -96.182,
            31.248,
            4.14,
            -96.561,
            31.391,
            4.14,
            -96.561,
            31.391,
            6.988,
            -96.561,
            31.391,
            6.988,
            -96.182,
            31.248,
            6.988,
            -96.182,
            31.248,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_22_1",
        "polygon": [
            -99.221,
            32.391,
            4.202,
            -100.016,
            32.689,
            4.202,
            -100.016,
            32.689,
            7.408,
            -100.016,
            32.689,
            7.408,
            -99.221,
            32.391,
            7.408,
            -99.221,
            32.391,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_22_2",
        "polygon": [
            -98.803,
            32.233,
            6.988,
            -99.182,
            32.376,
            6.988,
            -99.182,
            32.376,
            7.408,
            -99.182,
            32.376,
            7.408,
            -98.803,
            32.233,
            7.408,
            -98.803,
            32.233,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_22_3",
        "polygon": [
            -98.803,
            32.233,
            4.14,
            -99.182,
            32.376,
            4.14,
            -99.182,
            32.376,
            6.988,
            -99.182,
            32.376,
            6.988,
            -98.803,
            32.233,
            6.988,
            -98.803,
            32.233,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_20_1",
        "polygon": [
            -101.842,
            33.376,
            4.202,
            -102.637,
            33.675,
            4.202,
            -102.637,
            33.675,
            7.408,
            -102.637,
            33.675,
            7.408,
            -101.842,
            33.376,
            7.408,
            -101.842,
            33.376,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_20_2",
        "polygon": [
            -101.424,
            33.219,
            6.988,
            -101.803,
            33.361,
            6.988,
            -101.803,
            33.361,
            7.408,
            -101.803,
            33.361,
            7.408,
            -101.424,
            33.219,
            7.408,
            -101.424,
            33.219,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_20_3",
        "polygon": [
            -101.424,
            33.219,
            4.14,
            -101.803,
            33.361,
            4.14,
            -101.803,
            33.361,
            6.988,
            -101.803,
            33.361,
            6.988,
            -101.424,
            33.219,
            6.988,
            -101.424,
            33.219,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_18_1",
        "polygon": [
            -104.463,
            34.361,
            4.202,
            -105.258,
            34.66,
            4.202,
            -105.258,
            34.66,
            7.408,
            -105.258,
            34.66,
            7.408,
            -104.463,
            34.361,
            7.408,
            -104.463,
            34.361,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_18_2",
        "polygon": [
            -104.045,
            34.204,
            6.988,
            -104.424,
            34.347,
            6.988,
            -104.424,
            34.347,
            7.408,
            -104.424,
            34.347,
            7.408,
            -104.045,
            34.204,
            7.408,
            -104.045,
            34.204,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_18_3",
        "polygon": [
            -104.045,
            34.204,
            4.14,
            -104.424,
            34.347,
            4.14,
            -104.424,
            34.347,
            6.988,
            -104.424,
            34.347,
            6.988,
            -104.045,
            34.204,
            6.988,
            -104.045,
            34.204,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_16_1",
        "polygon": [
            -107.084,
            35.347,
            4.202,
            -107.879,
            35.645,
            4.202,
            -107.879,
            35.645,
            7.408,
            -107.879,
            35.645,
            7.408,
            -107.084,
            35.347,
            7.408,
            -107.084,
            35.347,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_16_2",
        "polygon": [
            -106.665,
            35.189,
            6.988,
            -107.045,
            35.332,
            6.988,
            -107.045,
            35.332,
            7.408,
            -107.045,
            35.332,
            7.408,
            -106.665,
            35.189,
            7.408,
            -106.665,
            35.189,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_16_3",
        "polygon": [
            -106.665,
            35.189,
            4.14,
            -107.045,
            35.332,
            4.14,
            -107.045,
            35.332,
            6.988,
            -107.045,
            35.332,
            6.988,
            -106.665,
            35.189,
            6.988,
            -106.665,
            35.189,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_14_1",
        "polygon": [
            -109.705,
            36.332,
            4.202,
            -110.499,
            36.631,
            4.202,
            -110.499,
            36.631,
            7.408,
            -110.499,
            36.631,
            7.408,
            -109.705,
            36.332,
            7.408,
            -109.705,
            36.332,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_14_2",
        "polygon": [
            -109.286,
            36.175,
            6.988,
            -109.665,
            36.317,
            6.988,
            -109.665,
            36.317,
            7.408,
            -109.665,
            36.317,
            7.408,
            -109.286,
            36.175,
            7.408,
            -109.286,
            36.175,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_14_3",
        "polygon": [
            -109.286,
            36.175,
            4.14,
            -109.665,
            36.317,
            4.14,
            -109.665,
            36.317,
            6.988,
            -109.665,
            36.317,
            6.988,
            -109.286,
            36.175,
            6.988,
            -109.286,
            36.175,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_12_1",
        "polygon": [
            -112.326,
            37.317,
            4.202,
            -113.12,
            37.616,
            4.202,
            -113.12,
            37.616,
            7.408,
            -113.12,
            37.616,
            7.408,
            -112.326,
            37.317,
            7.408,
            -112.326,
            37.317,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_12_2",
        "polygon": [
            -111.907,
            37.16,
            6.988,
            -112.286,
            37.303,
            6.988,
            -112.286,
            37.303,
            7.408,
            -112.286,
            37.303,
            7.408,
            -111.907,
            37.16,
            7.408,
            -111.907,
            37.16,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_12_3",
        "polygon": [
            -111.907,
            37.16,
            4.14,
            -112.286,
            37.303,
            4.14,
            -112.286,
            37.303,
            6.988,
            -112.286,
            37.303,
            6.988,
            -111.907,
            37.16,
            6.988,
            -111.907,
            37.16,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_10_1",
        "polygon": [
            -114.947,
            38.303,
            4.202,
            -115.741,
            38.602,
            4.202,
            -115.741,
            38.602,
            7.408,
            -115.741,
            38.602,
            7.408,
            -114.947,
            38.303,
            7.408,
            -114.947,
            38.303,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_10_2",
        "polygon": [
            -114.528,
            38.145,
            6.988,
            -114.907,
            38.288,
            6.988,
            -114.907,
            38.288,
            7.408,
            -114.907,
            38.288,
            7.408,
            -114.528,
            38.145,
            7.408,
            -114.528,
            38.145,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_10_3",
        "polygon": [
            -114.528,
            38.145,
            4.14,
            -114.907,
            38.288,
            4.14,
            -114.907,
            38.288,
            6.988,
            -114.907,
            38.288,
            6.988,
            -114.528,
            38.145,
            6.988,
            -114.528,
            38.145,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_8_1",
        "polygon": [
            -117.567,
            39.288,
            4.202,
            -118.362,
            39.587,
            4.202,
            -118.362,
            39.587,
            7.408,
            -118.362,
            39.587,
            7.408,
            -117.567,
            39.288,
            7.408,
            -117.567,
            39.288,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_8_2",
        "polygon": [
            -117.149,
            39.131,
            6.988,
            -117.528,
            39.273,
            6.988,
            -117.528,
            39.273,
            7.408,
            -117.528,
            39.273,
            7.408,
            -117.149,
            39.131,
            7.408,
            -117.149,
            39.131,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_8_3",
        "polygon": [
            -117.149,
            39.131,
            4.14,
            -117.528,
            39.273,
            4.14,
            -117.528,
            39.273,
            6.988,
            -117.528,
            39.273,
            6.988,
            -117.149,
            39.131,
            6.988,
            -117.149,
            39.131,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_6_1",
        "polygon": [
            -120.188,
            40.273,
            4.202,
            -120.983,
            40.572,
            4.202,
            -120.983,
            40.572,
            7.408,
            -120.983,
            40.572,
            7.408,
            -120.188,
            40.273,
            7.408,
            -120.188,
            40.273,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_6_2",
        "polygon": [
            -119.77,
            40.116,
            6.988,
            -120.149,
            40.259,
            6.988,
            -120.149,
            40.259,
            7.408,
            -120.149,
            40.259,
            7.408,
            -119.77,
            40.116,
            7.408,
            -119.77,
            40.116,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_6_3",
        "polygon": [
            -119.77,
            40.116,
            4.14,
            -120.149,
            40.259,
            4.14,
            -120.149,
            40.259,
            6.988,
            -120.149,
            40.259,
            6.988,
            -119.77,
            40.116,
            6.988,
            -119.77,
            40.116,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_4_1",
        "polygon": [
            -122.809,
            41.259,
            4.202,
            -123.604,
            41.558,
            4.202,
            -123.604,
            41.558,
            7.408,
            -123.604,
            41.558,
            7.408,
            -122.809,
            41.259,
            7.408,
            -122.809,
            41.259,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_4_2",
        "polygon": [
            -122.391,
            41.102,
            6.988,
            -122.77,
            41.244,
            6.988,
            -122.77,
            41.244,
            7.408,
            -122.77,
            41.244,
            7.408,
            -122.391,
            41.102,
            7.408,
            -122.391,
            41.102,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_4_3",
        "polygon": [
            -122.391,
            41.102,
            4.14,
            -122.77,
            41.244,
            4.14,
            -122.77,
            41.244,
            6.988,
            -122.77,
            41.244,
            6.988,
            -122.391,
            41.102,
            6.988,
            -122.391,
            41.102,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_2_1",
        "polygon": [
            -125.43,
            42.244,
            4.202,
            -126.225,
            42.543,
            4.202,
            -126.225,
            42.543,
            7.408,
            -126.225,
            42.543,
            7.408,
            -125.43,
            42.244,
            7.408,
            -125.43,
            42.244,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_2_2",
        "polygon": [
            -125.012,
            42.087,
            6.988,
            -125.391,
            42.229,
            6.988,
            -125.391,
            42.229,
            7.408,
            -125.391,
            42.229,
            7.408,
            -125.012,
            42.087,
            7.408,
            -125.012,
            42.087,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_1OG_2_3",
        "polygon": [
            -125.012,
            42.087,
            4.14,
            -125.391,
            42.229,
            4.14,
            -125.391,
            42.229,
            6.988,
            -125.391,
            42.229,
            6.988,
            -125.012,
            42.087,
            6.988,
            -125.012,
            42.087,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_36_1",
        "polygon": [
            -80.875,
            25.493,
            11.622,
            -81.67,
            25.792,
            11.622,
            -81.67,
            25.792,
            14.828,
            -81.67,
            25.792,
            14.828,
            -80.875,
            25.493,
            14.828,
            -80.875,
            25.493,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_36_2",
        "polygon": [
            -80.457,
            25.336,
            14.408,
            -80.836,
            25.478,
            14.408,
            -80.836,
            25.478,
            14.828,
            -80.836,
            25.478,
            14.828,
            -80.457,
            25.336,
            14.828,
            -80.457,
            25.336,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_36_3",
        "polygon": [
            -80.457,
            25.336,
            11.56,
            -80.836,
            25.478,
            11.56,
            -80.836,
            25.478,
            14.408,
            -80.836,
            25.478,
            14.408,
            -80.457,
            25.336,
            14.408,
            -80.457,
            25.336,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_34_1",
        "polygon": [
            -83.496,
            26.479,
            11.622,
            -84.291,
            26.777,
            11.622,
            -84.291,
            26.777,
            14.828,
            -84.291,
            26.777,
            14.828,
            -83.496,
            26.479,
            14.828,
            -83.496,
            26.479,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_34_2",
        "polygon": [
            -83.077,
            26.321,
            14.408,
            -83.456,
            26.464,
            14.408,
            -83.456,
            26.464,
            14.828,
            -83.456,
            26.464,
            14.828,
            -83.077,
            26.321,
            14.828,
            -83.077,
            26.321,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_34_3",
        "polygon": [
            -83.077,
            26.321,
            11.56,
            -83.456,
            26.464,
            11.56,
            -83.456,
            26.464,
            14.408,
            -83.456,
            26.464,
            14.408,
            -83.077,
            26.321,
            14.408,
            -83.077,
            26.321,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_32_1",
        "polygon": [
            -86.117,
            27.464,
            11.622,
            -86.911,
            27.763,
            11.622,
            -86.911,
            27.763,
            14.828,
            -86.911,
            27.763,
            14.828,
            -86.117,
            27.464,
            14.828,
            -86.117,
            27.464,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_32_2",
        "polygon": [
            -85.698,
            27.307,
            14.408,
            -86.077,
            27.449,
            14.408,
            -86.077,
            27.449,
            14.828,
            -86.077,
            27.449,
            14.828,
            -85.698,
            27.307,
            14.828,
            -85.698,
            27.307,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_32_3",
        "polygon": [
            -85.698,
            27.307,
            11.56,
            -86.077,
            27.449,
            11.56,
            -86.077,
            27.449,
            14.408,
            -86.077,
            27.449,
            14.408,
            -85.698,
            27.307,
            14.408,
            -85.698,
            27.307,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_30_1",
        "polygon": [
            -88.738,
            28.449,
            11.622,
            -89.532,
            28.748,
            11.622,
            -89.532,
            28.748,
            14.828,
            -89.532,
            28.748,
            14.828,
            -88.738,
            28.449,
            14.828,
            -88.738,
            28.449,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_30_2",
        "polygon": [
            -88.319,
            28.292,
            14.408,
            -88.698,
            28.434,
            14.408,
            -88.698,
            28.434,
            14.828,
            -88.698,
            28.434,
            14.828,
            -88.319,
            28.292,
            14.828,
            -88.319,
            28.292,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_30_3",
        "polygon": [
            -88.319,
            28.292,
            11.56,
            -88.698,
            28.434,
            11.56,
            -88.698,
            28.434,
            14.408,
            -88.698,
            28.434,
            14.408,
            -88.319,
            28.292,
            14.408,
            -88.319,
            28.292,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_28_1",
        "polygon": [
            -91.358,
            29.435,
            11.622,
            -92.153,
            29.733,
            11.622,
            -92.153,
            29.733,
            14.828,
            -92.153,
            29.733,
            14.828,
            -91.358,
            29.435,
            14.828,
            -91.358,
            29.435,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_28_2",
        "polygon": [
            -90.94,
            29.277,
            14.408,
            -91.319,
            29.42,
            14.408,
            -91.319,
            29.42,
            14.828,
            -91.319,
            29.42,
            14.828,
            -90.94,
            29.277,
            14.828,
            -90.94,
            29.277,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_28_3",
        "polygon": [
            -90.94,
            29.277,
            11.56,
            -91.319,
            29.42,
            11.56,
            -91.319,
            29.42,
            14.408,
            -91.319,
            29.42,
            14.408,
            -90.94,
            29.277,
            14.408,
            -90.94,
            29.277,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_26_1",
        "polygon": [
            -93.979,
            30.42,
            11.622,
            -94.774,
            30.719,
            11.622,
            -94.774,
            30.719,
            14.828,
            -94.774,
            30.719,
            14.828,
            -93.979,
            30.42,
            14.828,
            -93.979,
            30.42,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_26_2",
        "polygon": [
            -93.561,
            30.263,
            14.408,
            -93.94,
            30.405,
            14.408,
            -93.94,
            30.405,
            14.828,
            -93.94,
            30.405,
            14.828,
            -93.561,
            30.263,
            14.828,
            -93.561,
            30.263,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_26_3",
        "polygon": [
            -93.561,
            30.263,
            11.56,
            -93.94,
            30.405,
            11.56,
            -93.94,
            30.405,
            14.408,
            -93.94,
            30.405,
            14.408,
            -93.561,
            30.263,
            14.408,
            -93.561,
            30.263,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_24_1",
        "polygon": [
            -96.6,
            31.405,
            11.622,
            -97.395,
            31.704,
            11.622,
            -97.395,
            31.704,
            14.828,
            -97.395,
            31.704,
            14.828,
            -96.6,
            31.405,
            14.828,
            -96.6,
            31.405,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_24_2",
        "polygon": [
            -96.182,
            31.248,
            14.408,
            -96.561,
            31.391,
            14.408,
            -96.561,
            31.391,
            14.828,
            -96.561,
            31.391,
            14.828,
            -96.182,
            31.248,
            14.828,
            -96.182,
            31.248,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_24_3",
        "polygon": [
            -96.182,
            31.248,
            11.56,
            -96.561,
            31.391,
            11.56,
            -96.561,
            31.391,
            14.408,
            -96.561,
            31.391,
            14.408,
            -96.182,
            31.248,
            14.408,
            -96.182,
            31.248,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_22_1",
        "polygon": [
            -99.221,
            32.391,
            11.622,
            -100.016,
            32.689,
            11.622,
            -100.016,
            32.689,
            14.828,
            -100.016,
            32.689,
            14.828,
            -99.221,
            32.391,
            14.828,
            -99.221,
            32.391,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_22_2",
        "polygon": [
            -98.803,
            32.233,
            14.408,
            -99.182,
            32.376,
            14.408,
            -99.182,
            32.376,
            14.828,
            -99.182,
            32.376,
            14.828,
            -98.803,
            32.233,
            14.828,
            -98.803,
            32.233,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_22_3",
        "polygon": [
            -98.803,
            32.233,
            11.56,
            -99.182,
            32.376,
            11.56,
            -99.182,
            32.376,
            14.408,
            -99.182,
            32.376,
            14.408,
            -98.803,
            32.233,
            14.408,
            -98.803,
            32.233,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_20_1",
        "polygon": [
            -101.842,
            33.376,
            11.622,
            -102.637,
            33.675,
            11.622,
            -102.637,
            33.675,
            14.828,
            -102.637,
            33.675,
            14.828,
            -101.842,
            33.376,
            14.828,
            -101.842,
            33.376,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_20_2",
        "polygon": [
            -101.424,
            33.219,
            14.408,
            -101.803,
            33.361,
            14.408,
            -101.803,
            33.361,
            14.828,
            -101.803,
            33.361,
            14.828,
            -101.424,
            33.219,
            14.828,
            -101.424,
            33.219,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_20_3",
        "polygon": [
            -101.424,
            33.219,
            11.56,
            -101.803,
            33.361,
            11.56,
            -101.803,
            33.361,
            14.408,
            -101.803,
            33.361,
            14.408,
            -101.424,
            33.219,
            14.408,
            -101.424,
            33.219,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_18_1",
        "polygon": [
            -104.463,
            34.361,
            11.622,
            -105.258,
            34.66,
            11.622,
            -105.258,
            34.66,
            14.828,
            -105.258,
            34.66,
            14.828,
            -104.463,
            34.361,
            14.828,
            -104.463,
            34.361,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_18_2",
        "polygon": [
            -104.045,
            34.204,
            14.408,
            -104.424,
            34.347,
            14.408,
            -104.424,
            34.347,
            14.828,
            -104.424,
            34.347,
            14.828,
            -104.045,
            34.204,
            14.828,
            -104.045,
            34.204,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_18_3",
        "polygon": [
            -104.045,
            34.204,
            11.56,
            -104.424,
            34.347,
            11.56,
            -104.424,
            34.347,
            14.408,
            -104.424,
            34.347,
            14.408,
            -104.045,
            34.204,
            14.408,
            -104.045,
            34.204,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_16_1",
        "polygon": [
            -107.084,
            35.347,
            11.622,
            -107.879,
            35.645,
            11.622,
            -107.879,
            35.645,
            14.828,
            -107.879,
            35.645,
            14.828,
            -107.084,
            35.347,
            14.828,
            -107.084,
            35.347,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_16_2",
        "polygon": [
            -106.665,
            35.189,
            14.408,
            -107.045,
            35.332,
            14.408,
            -107.045,
            35.332,
            14.828,
            -107.045,
            35.332,
            14.828,
            -106.665,
            35.189,
            14.828,
            -106.665,
            35.189,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_16_3",
        "polygon": [
            -106.665,
            35.189,
            11.56,
            -107.045,
            35.332,
            11.56,
            -107.045,
            35.332,
            14.408,
            -107.045,
            35.332,
            14.408,
            -106.665,
            35.189,
            14.408,
            -106.665,
            35.189,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_14_1",
        "polygon": [
            -109.705,
            36.332,
            11.622,
            -110.499,
            36.631,
            11.622,
            -110.499,
            36.631,
            14.828,
            -110.499,
            36.631,
            14.828,
            -109.705,
            36.332,
            14.828,
            -109.705,
            36.332,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_14_2",
        "polygon": [
            -109.286,
            36.175,
            14.408,
            -109.665,
            36.317,
            14.408,
            -109.665,
            36.317,
            14.828,
            -109.665,
            36.317,
            14.828,
            -109.286,
            36.175,
            14.828,
            -109.286,
            36.175,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_14_3",
        "polygon": [
            -109.286,
            36.175,
            11.56,
            -109.665,
            36.317,
            11.56,
            -109.665,
            36.317,
            14.408,
            -109.665,
            36.317,
            14.408,
            -109.286,
            36.175,
            14.408,
            -109.286,
            36.175,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_12_1",
        "polygon": [
            -112.326,
            37.317,
            11.622,
            -113.12,
            37.616,
            11.622,
            -113.12,
            37.616,
            14.828,
            -113.12,
            37.616,
            14.828,
            -112.326,
            37.317,
            14.828,
            -112.326,
            37.317,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_12_2",
        "polygon": [
            -111.907,
            37.16,
            14.408,
            -112.286,
            37.303,
            14.408,
            -112.286,
            37.303,
            14.828,
            -112.286,
            37.303,
            14.828,
            -111.907,
            37.16,
            14.828,
            -111.907,
            37.16,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_12_3",
        "polygon": [
            -111.907,
            37.16,
            11.56,
            -112.286,
            37.303,
            11.56,
            -112.286,
            37.303,
            14.408,
            -112.286,
            37.303,
            14.408,
            -111.907,
            37.16,
            14.408,
            -111.907,
            37.16,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_10_1",
        "polygon": [
            -114.947,
            38.303,
            11.622,
            -115.741,
            38.602,
            11.622,
            -115.741,
            38.602,
            14.828,
            -115.741,
            38.602,
            14.828,
            -114.947,
            38.303,
            14.828,
            -114.947,
            38.303,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_10_2",
        "polygon": [
            -114.528,
            38.145,
            14.408,
            -114.907,
            38.288,
            14.408,
            -114.907,
            38.288,
            14.828,
            -114.907,
            38.288,
            14.828,
            -114.528,
            38.145,
            14.828,
            -114.528,
            38.145,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_10_3",
        "polygon": [
            -114.528,
            38.145,
            11.56,
            -114.907,
            38.288,
            11.56,
            -114.907,
            38.288,
            14.408,
            -114.907,
            38.288,
            14.408,
            -114.528,
            38.145,
            14.408,
            -114.528,
            38.145,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_8_1",
        "polygon": [
            -117.567,
            39.288,
            11.622,
            -118.362,
            39.587,
            11.622,
            -118.362,
            39.587,
            14.828,
            -118.362,
            39.587,
            14.828,
            -117.567,
            39.288,
            14.828,
            -117.567,
            39.288,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_8_2",
        "polygon": [
            -117.149,
            39.131,
            14.408,
            -117.528,
            39.273,
            14.408,
            -117.528,
            39.273,
            14.828,
            -117.528,
            39.273,
            14.828,
            -117.149,
            39.131,
            14.828,
            -117.149,
            39.131,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_8_3",
        "polygon": [
            -117.149,
            39.131,
            11.56,
            -117.528,
            39.273,
            11.56,
            -117.528,
            39.273,
            14.408,
            -117.528,
            39.273,
            14.408,
            -117.149,
            39.131,
            14.408,
            -117.149,
            39.131,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_6_1",
        "polygon": [
            -120.188,
            40.273,
            11.622,
            -120.983,
            40.572,
            11.622,
            -120.983,
            40.572,
            14.828,
            -120.983,
            40.572,
            14.828,
            -120.188,
            40.273,
            14.828,
            -120.188,
            40.273,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_6_2",
        "polygon": [
            -119.77,
            40.116,
            14.408,
            -120.149,
            40.259,
            14.408,
            -120.149,
            40.259,
            14.828,
            -120.149,
            40.259,
            14.828,
            -119.77,
            40.116,
            14.828,
            -119.77,
            40.116,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_6_3",
        "polygon": [
            -119.77,
            40.116,
            11.56,
            -120.149,
            40.259,
            11.56,
            -120.149,
            40.259,
            14.408,
            -120.149,
            40.259,
            14.408,
            -119.77,
            40.116,
            14.408,
            -119.77,
            40.116,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_4_1",
        "polygon": [
            -122.809,
            41.259,
            11.622,
            -123.604,
            41.558,
            11.622,
            -123.604,
            41.558,
            14.828,
            -123.604,
            41.558,
            14.828,
            -122.809,
            41.259,
            14.828,
            -122.809,
            41.259,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_4_2",
        "polygon": [
            -122.391,
            41.102,
            14.408,
            -122.77,
            41.244,
            14.408,
            -122.77,
            41.244,
            14.828,
            -122.77,
            41.244,
            14.828,
            -122.391,
            41.102,
            14.828,
            -122.391,
            41.102,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_4_3",
        "polygon": [
            -122.391,
            41.102,
            11.56,
            -122.77,
            41.244,
            11.56,
            -122.77,
            41.244,
            14.408,
            -122.77,
            41.244,
            14.408,
            -122.391,
            41.102,
            14.408,
            -122.391,
            41.102,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_2_1",
        "polygon": [
            -125.43,
            42.244,
            11.622,
            -126.225,
            42.543,
            11.622,
            -126.225,
            42.543,
            14.828,
            -126.225,
            42.543,
            14.828,
            -125.43,
            42.244,
            14.828,
            -125.43,
            42.244,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_2_2",
        "polygon": [
            -125.012,
            42.087,
            14.408,
            -125.391,
            42.229,
            14.408,
            -125.391,
            42.229,
            14.828,
            -125.391,
            42.229,
            14.828,
            -125.012,
            42.087,
            14.828,
            -125.012,
            42.087,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_3OG_2_3",
        "polygon": [
            -125.012,
            42.087,
            11.56,
            -125.391,
            42.229,
            11.56,
            -125.391,
            42.229,
            14.408,
            -125.391,
            42.229,
            14.408,
            -125.012,
            42.087,
            14.408,
            -125.012,
            42.087,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_36_1",
        "polygon": [
            -80.457,
            25.336,
            7.912,
            -81.251,
            25.635,
            7.912,
            -81.251,
            25.635,
            11.118,
            -81.251,
            25.635,
            11.118,
            -80.457,
            25.336,
            11.118,
            -80.457,
            25.336,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_36_2",
        "polygon": [
            -81.291,
            25.649,
            10.698,
            -81.67,
            25.792,
            10.698,
            -81.67,
            25.792,
            11.118,
            -81.67,
            25.792,
            11.118,
            -81.291,
            25.649,
            11.118,
            -81.291,
            25.649,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_36_3",
        "polygon": [
            -81.291,
            25.649,
            7.85,
            -81.67,
            25.792,
            7.85,
            -81.67,
            25.792,
            10.698,
            -81.67,
            25.792,
            10.698,
            -81.291,
            25.649,
            10.698,
            -81.291,
            25.649,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_34_1",
        "polygon": [
            -83.077,
            26.321,
            7.912,
            -83.872,
            26.62,
            7.912,
            -83.872,
            26.62,
            11.118,
            -83.872,
            26.62,
            11.118,
            -83.077,
            26.321,
            11.118,
            -83.077,
            26.321,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_34_2",
        "polygon": [
            -83.911,
            26.635,
            10.698,
            -84.291,
            26.777,
            10.698,
            -84.291,
            26.777,
            11.118,
            -84.291,
            26.777,
            11.118,
            -83.911,
            26.635,
            11.118,
            -83.911,
            26.635,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_34_3",
        "polygon": [
            -83.911,
            26.635,
            7.85,
            -84.291,
            26.777,
            7.85,
            -84.291,
            26.777,
            10.698,
            -84.291,
            26.777,
            10.698,
            -83.911,
            26.635,
            10.698,
            -83.911,
            26.635,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_32_1",
        "polygon": [
            -85.698,
            27.307,
            7.912,
            -86.493,
            27.605,
            7.912,
            -86.493,
            27.605,
            11.118,
            -86.493,
            27.605,
            11.118,
            -85.698,
            27.307,
            11.118,
            -85.698,
            27.307,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_32_2",
        "polygon": [
            -86.532,
            27.62,
            10.698,
            -86.911,
            27.763,
            10.698,
            -86.911,
            27.763,
            11.118,
            -86.911,
            27.763,
            11.118,
            -86.532,
            27.62,
            11.118,
            -86.532,
            27.62,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_32_3",
        "polygon": [
            -86.532,
            27.62,
            7.85,
            -86.911,
            27.763,
            7.85,
            -86.911,
            27.763,
            10.698,
            -86.911,
            27.763,
            10.698,
            -86.532,
            27.62,
            10.698,
            -86.532,
            27.62,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_30_1",
        "polygon": [
            -88.319,
            28.292,
            7.912,
            -89.114,
            28.591,
            7.912,
            -89.114,
            28.591,
            11.118,
            -89.114,
            28.591,
            11.118,
            -88.319,
            28.292,
            11.118,
            -88.319,
            28.292,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_30_2",
        "polygon": [
            -89.153,
            28.606,
            10.698,
            -89.532,
            28.748,
            10.698,
            -89.532,
            28.748,
            11.118,
            -89.532,
            28.748,
            11.118,
            -89.153,
            28.606,
            11.118,
            -89.153,
            28.606,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_30_3",
        "polygon": [
            -89.153,
            28.606,
            7.85,
            -89.532,
            28.748,
            7.85,
            -89.532,
            28.748,
            10.698,
            -89.532,
            28.748,
            10.698,
            -89.153,
            28.606,
            10.698,
            -89.153,
            28.606,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_28_1",
        "polygon": [
            -90.94,
            29.277,
            7.912,
            -91.735,
            29.576,
            7.912,
            -91.735,
            29.576,
            11.118,
            -91.735,
            29.576,
            11.118,
            -90.94,
            29.277,
            11.118,
            -90.94,
            29.277,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_28_2",
        "polygon": [
            -91.774,
            29.591,
            10.698,
            -92.153,
            29.733,
            10.698,
            -92.153,
            29.733,
            11.118,
            -92.153,
            29.733,
            11.118,
            -91.774,
            29.591,
            11.118,
            -91.774,
            29.591,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_28_3",
        "polygon": [
            -91.774,
            29.591,
            7.85,
            -92.153,
            29.733,
            7.85,
            -92.153,
            29.733,
            10.698,
            -92.153,
            29.733,
            10.698,
            -91.774,
            29.591,
            10.698,
            -91.774,
            29.591,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_26_1",
        "polygon": [
            -93.561,
            30.263,
            7.912,
            -94.356,
            30.561,
            7.912,
            -94.356,
            30.561,
            11.118,
            -94.356,
            30.561,
            11.118,
            -93.561,
            30.263,
            11.118,
            -93.561,
            30.263,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_26_2",
        "polygon": [
            -94.395,
            30.576,
            10.698,
            -94.774,
            30.719,
            10.698,
            -94.774,
            30.719,
            11.118,
            -94.774,
            30.719,
            11.118,
            -94.395,
            30.576,
            11.118,
            -94.395,
            30.576,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_26_3",
        "polygon": [
            -94.395,
            30.576,
            7.85,
            -94.774,
            30.719,
            7.85,
            -94.774,
            30.719,
            10.698,
            -94.774,
            30.719,
            10.698,
            -94.395,
            30.576,
            10.698,
            -94.395,
            30.576,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_24_1",
        "polygon": [
            -96.182,
            31.248,
            7.912,
            -96.977,
            31.547,
            7.912,
            -96.977,
            31.547,
            11.118,
            -96.977,
            31.547,
            11.118,
            -96.182,
            31.248,
            11.118,
            -96.182,
            31.248,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_24_2",
        "polygon": [
            -97.016,
            31.562,
            10.698,
            -97.395,
            31.704,
            10.698,
            -97.395,
            31.704,
            11.118,
            -97.395,
            31.704,
            11.118,
            -97.016,
            31.562,
            11.118,
            -97.016,
            31.562,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_24_3",
        "polygon": [
            -97.016,
            31.562,
            7.85,
            -97.395,
            31.704,
            7.85,
            -97.395,
            31.704,
            10.698,
            -97.395,
            31.704,
            10.698,
            -97.016,
            31.562,
            10.698,
            -97.016,
            31.562,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_22_1",
        "polygon": [
            -98.803,
            32.233,
            7.912,
            -99.597,
            32.532,
            7.912,
            -99.597,
            32.532,
            11.118,
            -99.597,
            32.532,
            11.118,
            -98.803,
            32.233,
            11.118,
            -98.803,
            32.233,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_22_2",
        "polygon": [
            -99.637,
            32.547,
            10.698,
            -100.016,
            32.689,
            10.698,
            -100.016,
            32.689,
            11.118,
            -100.016,
            32.689,
            11.118,
            -99.637,
            32.547,
            11.118,
            -99.637,
            32.547,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_22_3",
        "polygon": [
            -99.637,
            32.547,
            7.85,
            -100.016,
            32.689,
            7.85,
            -100.016,
            32.689,
            10.698,
            -100.016,
            32.689,
            10.698,
            -99.637,
            32.547,
            10.698,
            -99.637,
            32.547,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_20_1",
        "polygon": [
            -101.424,
            33.219,
            7.912,
            -102.218,
            33.517,
            7.912,
            -102.218,
            33.517,
            11.118,
            -102.218,
            33.517,
            11.118,
            -101.424,
            33.219,
            11.118,
            -101.424,
            33.219,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_20_2",
        "polygon": [
            -102.258,
            33.532,
            10.698,
            -102.637,
            33.675,
            10.698,
            -102.637,
            33.675,
            11.118,
            -102.637,
            33.675,
            11.118,
            -102.258,
            33.532,
            11.118,
            -102.258,
            33.532,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_20_3",
        "polygon": [
            -102.258,
            33.532,
            7.85,
            -102.637,
            33.675,
            7.85,
            -102.637,
            33.675,
            10.698,
            -102.637,
            33.675,
            10.698,
            -102.258,
            33.532,
            10.698,
            -102.258,
            33.532,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_18_1",
        "polygon": [
            -104.045,
            34.204,
            7.912,
            -104.839,
            34.503,
            7.912,
            -104.839,
            34.503,
            11.118,
            -104.839,
            34.503,
            11.118,
            -104.045,
            34.204,
            11.118,
            -104.045,
            34.204,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_18_2",
        "polygon": [
            -104.879,
            34.518,
            10.698,
            -105.258,
            34.66,
            10.698,
            -105.258,
            34.66,
            11.118,
            -105.258,
            34.66,
            11.118,
            -104.879,
            34.518,
            11.118,
            -104.879,
            34.518,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_18_3",
        "polygon": [
            -104.879,
            34.518,
            7.85,
            -105.258,
            34.66,
            7.85,
            -105.258,
            34.66,
            10.698,
            -105.258,
            34.66,
            10.698,
            -104.879,
            34.518,
            10.698,
            -104.879,
            34.518,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_16_1",
        "polygon": [
            -106.665,
            35.189,
            7.912,
            -107.46,
            35.488,
            7.912,
            -107.46,
            35.488,
            11.118,
            -107.46,
            35.488,
            11.118,
            -106.665,
            35.189,
            11.118,
            -106.665,
            35.189,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_16_2",
        "polygon": [
            -107.499,
            35.503,
            10.698,
            -107.879,
            35.645,
            10.698,
            -107.879,
            35.645,
            11.118,
            -107.879,
            35.645,
            11.118,
            -107.499,
            35.503,
            11.118,
            -107.499,
            35.503,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_16_3",
        "polygon": [
            -107.499,
            35.503,
            7.85,
            -107.879,
            35.645,
            7.85,
            -107.879,
            35.645,
            10.698,
            -107.879,
            35.645,
            10.698,
            -107.499,
            35.503,
            10.698,
            -107.499,
            35.503,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_14_1",
        "polygon": [
            -109.286,
            36.175,
            7.912,
            -110.081,
            36.474,
            7.912,
            -110.081,
            36.474,
            11.118,
            -110.081,
            36.474,
            11.118,
            -109.286,
            36.175,
            11.118,
            -109.286,
            36.175,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_14_2",
        "polygon": [
            -110.12,
            36.488,
            10.698,
            -110.499,
            36.631,
            10.698,
            -110.499,
            36.631,
            11.118,
            -110.499,
            36.631,
            11.118,
            -110.12,
            36.488,
            11.118,
            -110.12,
            36.488,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_14_3",
        "polygon": [
            -110.12,
            36.488,
            7.85,
            -110.499,
            36.631,
            7.85,
            -110.499,
            36.631,
            10.698,
            -110.499,
            36.631,
            10.698,
            -110.12,
            36.488,
            10.698,
            -110.12,
            36.488,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_12_1",
        "polygon": [
            -111.907,
            37.16,
            7.912,
            -112.702,
            37.459,
            7.912,
            -112.702,
            37.459,
            11.118,
            -112.702,
            37.459,
            11.118,
            -111.907,
            37.16,
            11.118,
            -111.907,
            37.16,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_12_2",
        "polygon": [
            -112.741,
            37.474,
            10.698,
            -113.12,
            37.616,
            10.698,
            -113.12,
            37.616,
            11.118,
            -113.12,
            37.616,
            11.118,
            -112.741,
            37.474,
            11.118,
            -112.741,
            37.474,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_12_3",
        "polygon": [
            -112.741,
            37.474,
            7.85,
            -113.12,
            37.616,
            7.85,
            -113.12,
            37.616,
            10.698,
            -113.12,
            37.616,
            10.698,
            -112.741,
            37.474,
            10.698,
            -112.741,
            37.474,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_10_1",
        "polygon": [
            -114.528,
            38.145,
            7.912,
            -115.323,
            38.444,
            7.912,
            -115.323,
            38.444,
            11.118,
            -115.323,
            38.444,
            11.118,
            -114.528,
            38.145,
            11.118,
            -114.528,
            38.145,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_10_2",
        "polygon": [
            -115.362,
            38.459,
            10.698,
            -115.741,
            38.602,
            10.698,
            -115.741,
            38.602,
            11.118,
            -115.741,
            38.602,
            11.118,
            -115.362,
            38.459,
            11.118,
            -115.362,
            38.459,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_10_3",
        "polygon": [
            -115.362,
            38.459,
            7.85,
            -115.741,
            38.602,
            7.85,
            -115.741,
            38.602,
            10.698,
            -115.741,
            38.602,
            10.698,
            -115.362,
            38.459,
            10.698,
            -115.362,
            38.459,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_8_1",
        "polygon": [
            -117.149,
            39.131,
            7.912,
            -117.944,
            39.43,
            7.912,
            -117.944,
            39.43,
            11.118,
            -117.944,
            39.43,
            11.118,
            -117.149,
            39.131,
            11.118,
            -117.149,
            39.131,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_8_2",
        "polygon": [
            -117.983,
            39.444,
            10.698,
            -118.362,
            39.587,
            10.698,
            -118.362,
            39.587,
            11.118,
            -118.362,
            39.587,
            11.118,
            -117.983,
            39.444,
            11.118,
            -117.983,
            39.444,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_8_3",
        "polygon": [
            -117.983,
            39.444,
            7.85,
            -118.362,
            39.587,
            7.85,
            -118.362,
            39.587,
            10.698,
            -118.362,
            39.587,
            10.698,
            -117.983,
            39.444,
            10.698,
            -117.983,
            39.444,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_6_1",
        "polygon": [
            -119.77,
            40.116,
            7.912,
            -120.565,
            40.415,
            7.912,
            -120.565,
            40.415,
            11.118,
            -120.565,
            40.415,
            11.118,
            -119.77,
            40.116,
            11.118,
            -119.77,
            40.116,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_6_2",
        "polygon": [
            -120.604,
            40.43,
            10.698,
            -120.983,
            40.572,
            10.698,
            -120.983,
            40.572,
            11.118,
            -120.983,
            40.572,
            11.118,
            -120.604,
            40.43,
            11.118,
            -120.604,
            40.43,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_6_3",
        "polygon": [
            -120.604,
            40.43,
            7.85,
            -120.983,
            40.572,
            7.85,
            -120.983,
            40.572,
            10.698,
            -120.983,
            40.572,
            10.698,
            -120.604,
            40.43,
            10.698,
            -120.604,
            40.43,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_4_1",
        "polygon": [
            -122.391,
            41.102,
            7.912,
            -123.186,
            41.4,
            7.912,
            -123.186,
            41.4,
            11.118,
            -123.186,
            41.4,
            11.118,
            -122.391,
            41.102,
            11.118,
            -122.391,
            41.102,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_4_2",
        "polygon": [
            -123.225,
            41.415,
            10.698,
            -123.604,
            41.558,
            10.698,
            -123.604,
            41.558,
            11.118,
            -123.604,
            41.558,
            11.118,
            -123.225,
            41.415,
            11.118,
            -123.225,
            41.415,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_4_3",
        "polygon": [
            -123.225,
            41.415,
            7.85,
            -123.604,
            41.558,
            7.85,
            -123.604,
            41.558,
            10.698,
            -123.604,
            41.558,
            10.698,
            -123.225,
            41.415,
            10.698,
            -123.225,
            41.415,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_2_1",
        "polygon": [
            -125.012,
            42.087,
            7.912,
            -125.806,
            42.386,
            7.912,
            -125.806,
            42.386,
            11.118,
            -125.806,
            42.386,
            11.118,
            -125.012,
            42.087,
            11.118,
            -125.012,
            42.087,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_2_2",
        "polygon": [
            -125.846,
            42.4,
            10.698,
            -126.225,
            42.543,
            10.698,
            -126.225,
            42.543,
            11.118,
            -126.225,
            42.543,
            11.118,
            -125.846,
            42.4,
            11.118,
            -125.846,
            42.4,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.2-Ax1 9_J_2OG_2_3",
        "polygon": [
            -125.846,
            42.4,
            7.85,
            -126.225,
            42.543,
            7.85,
            -126.225,
            42.543,
            10.698,
            -126.225,
            42.543,
            10.698,
            -125.846,
            42.4,
            10.698,
            -125.846,
            42.4,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_36_1",
        "polygon": [
            -12.732,
            -0.126,
            4.202,
            -13.526,
            0.173,
            4.202,
            -13.526,
            0.173,
            7.408,
            -13.526,
            0.173,
            7.408,
            -12.732,
            -0.126,
            7.408,
            -12.732,
            -0.126,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_36_2",
        "polygon": [
            -12.313,
            -0.283,
            4.14,
            -12.692,
            -0.141,
            4.14,
            -12.692,
            -0.141,
            6.988,
            -12.692,
            -0.141,
            6.988,
            -12.313,
            -0.283,
            6.988,
            -12.313,
            -0.283,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_36_3",
        "polygon": [
            -12.313,
            -0.283,
            6.988,
            -12.692,
            -0.141,
            6.988,
            -12.692,
            -0.141,
            7.408,
            -12.692,
            -0.141,
            7.408,
            -12.313,
            -0.283,
            7.408,
            -12.313,
            -0.283,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_34_1",
        "polygon": [
            -15.353,
            0.859,
            4.202,
            -16.147,
            1.158,
            4.202,
            -16.147,
            1.158,
            7.408,
            -16.147,
            1.158,
            7.408,
            -15.353,
            0.859,
            7.408,
            -15.353,
            0.859,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_34_2",
        "polygon": [
            -14.934,
            0.702,
            4.14,
            -15.313,
            0.845,
            4.14,
            -15.313,
            0.845,
            6.988,
            -15.313,
            0.845,
            6.988,
            -14.934,
            0.702,
            6.988,
            -14.934,
            0.702,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_34_3",
        "polygon": [
            -14.934,
            0.702,
            6.988,
            -15.313,
            0.845,
            6.988,
            -15.313,
            0.845,
            7.408,
            -15.313,
            0.845,
            7.408,
            -14.934,
            0.702,
            7.408,
            -14.934,
            0.702,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_32_1",
        "polygon": [
            -17.973,
            1.845,
            4.202,
            -18.768,
            2.144,
            4.202,
            -18.768,
            2.144,
            7.408,
            -18.768,
            2.144,
            7.408,
            -17.973,
            1.845,
            7.408,
            -17.973,
            1.845,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_32_2",
        "polygon": [
            -17.555,
            1.688,
            4.14,
            -17.934,
            1.83,
            4.14,
            -17.934,
            1.83,
            6.988,
            -17.934,
            1.83,
            6.988,
            -17.555,
            1.688,
            6.988,
            -17.555,
            1.688,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_32_3",
        "polygon": [
            -17.555,
            1.688,
            6.988,
            -17.934,
            1.83,
            6.988,
            -17.934,
            1.83,
            7.408,
            -17.934,
            1.83,
            7.408,
            -17.555,
            1.688,
            7.408,
            -17.555,
            1.688,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_30_1",
        "polygon": [
            -20.594,
            2.83,
            4.202,
            -21.389,
            3.129,
            4.202,
            -21.389,
            3.129,
            7.408,
            -21.389,
            3.129,
            7.408,
            -20.594,
            2.83,
            7.408,
            -20.594,
            2.83,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_30_2",
        "polygon": [
            -20.176,
            2.673,
            4.14,
            -20.555,
            2.815,
            4.14,
            -20.555,
            2.815,
            6.988,
            -20.555,
            2.815,
            6.988,
            -20.176,
            2.673,
            6.988,
            -20.176,
            2.673,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_30_3",
        "polygon": [
            -20.176,
            2.673,
            6.988,
            -20.555,
            2.815,
            6.988,
            -20.555,
            2.815,
            7.408,
            -20.555,
            2.815,
            7.408,
            -20.176,
            2.673,
            7.408,
            -20.176,
            2.673,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_28_1",
        "polygon": [
            -23.215,
            3.816,
            4.202,
            -24.01,
            4.114,
            4.202,
            -24.01,
            4.114,
            7.408,
            -24.01,
            4.114,
            7.408,
            -23.215,
            3.816,
            7.408,
            -23.215,
            3.816,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_28_2",
        "polygon": [
            -22.797,
            3.658,
            4.14,
            -23.176,
            3.801,
            4.14,
            -23.176,
            3.801,
            6.988,
            -23.176,
            3.801,
            6.988,
            -22.797,
            3.658,
            6.988,
            -22.797,
            3.658,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_28_3",
        "polygon": [
            -22.797,
            3.658,
            6.988,
            -23.176,
            3.801,
            6.988,
            -23.176,
            3.801,
            7.408,
            -23.176,
            3.801,
            7.408,
            -22.797,
            3.658,
            7.408,
            -22.797,
            3.658,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_26_1",
        "polygon": [
            -25.836,
            4.801,
            4.202,
            -26.631,
            5.1,
            4.202,
            -26.631,
            5.1,
            7.408,
            -26.631,
            5.1,
            7.408,
            -25.836,
            4.801,
            7.408,
            -25.836,
            4.801,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_26_2",
        "polygon": [
            -25.418,
            4.644,
            4.14,
            -25.797,
            4.786,
            4.14,
            -25.797,
            4.786,
            6.988,
            -25.797,
            4.786,
            6.988,
            -25.418,
            4.644,
            6.988,
            -25.418,
            4.644,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_26_3",
        "polygon": [
            -25.418,
            4.644,
            6.988,
            -25.797,
            4.786,
            6.988,
            -25.797,
            4.786,
            7.408,
            -25.797,
            4.786,
            7.408,
            -25.418,
            4.644,
            7.408,
            -25.418,
            4.644,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_24_1",
        "polygon": [
            -28.457,
            5.786,
            4.202,
            -29.252,
            6.085,
            4.202,
            -29.252,
            6.085,
            7.408,
            -29.252,
            6.085,
            7.408,
            -28.457,
            5.786,
            7.408,
            -28.457,
            5.786,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_24_2",
        "polygon": [
            -28.039,
            5.629,
            4.14,
            -28.418,
            5.771,
            4.14,
            -28.418,
            5.771,
            6.988,
            -28.418,
            5.771,
            6.988,
            -28.039,
            5.629,
            6.988,
            -28.039,
            5.629,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_24_3",
        "polygon": [
            -28.039,
            5.629,
            6.988,
            -28.418,
            5.771,
            6.988,
            -28.418,
            5.771,
            7.408,
            -28.418,
            5.771,
            7.408,
            -28.039,
            5.629,
            7.408,
            -28.039,
            5.629,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_22_1",
        "polygon": [
            -31.078,
            6.772,
            4.202,
            -31.873,
            7.07,
            4.202,
            -31.873,
            7.07,
            7.408,
            -31.873,
            7.07,
            7.408,
            -31.078,
            6.772,
            7.408,
            -31.078,
            6.772,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_22_2",
        "polygon": [
            -30.66,
            6.614,
            4.14,
            -31.039,
            6.757,
            4.14,
            -31.039,
            6.757,
            6.988,
            -31.039,
            6.757,
            6.988,
            -30.66,
            6.614,
            6.988,
            -30.66,
            6.614,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_22_3",
        "polygon": [
            -30.66,
            6.614,
            6.988,
            -31.039,
            6.757,
            6.988,
            -31.039,
            6.757,
            7.408,
            -31.039,
            6.757,
            7.408,
            -30.66,
            6.614,
            7.408,
            -30.66,
            6.614,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_20_1",
        "polygon": [
            -33.699,
            7.757,
            4.202,
            -34.494,
            8.056,
            4.202,
            -34.494,
            8.056,
            7.408,
            -34.494,
            8.056,
            7.408,
            -33.699,
            7.757,
            7.408,
            -33.699,
            7.757,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_20_2",
        "polygon": [
            -33.28,
            7.6,
            4.14,
            -33.66,
            7.742,
            4.14,
            -33.66,
            7.742,
            6.988,
            -33.66,
            7.742,
            6.988,
            -33.28,
            7.6,
            6.988,
            -33.28,
            7.6,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_20_3",
        "polygon": [
            -33.28,
            7.6,
            6.988,
            -33.66,
            7.742,
            6.988,
            -33.66,
            7.742,
            7.408,
            -33.66,
            7.742,
            7.408,
            -33.28,
            7.6,
            7.408,
            -33.28,
            7.6,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_18_1",
        "polygon": [
            -36.32,
            8.742,
            4.202,
            -37.114,
            9.041,
            4.202,
            -37.114,
            9.041,
            7.408,
            -37.114,
            9.041,
            7.408,
            -36.32,
            8.742,
            7.408,
            -36.32,
            8.742,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_18_2",
        "polygon": [
            -35.901,
            8.585,
            4.14,
            -36.28,
            8.727,
            4.14,
            -36.28,
            8.727,
            6.988,
            -36.28,
            8.727,
            6.988,
            -35.901,
            8.585,
            6.988,
            -35.901,
            8.585,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_18_3",
        "polygon": [
            -35.901,
            8.585,
            6.988,
            -36.28,
            8.727,
            6.988,
            -36.28,
            8.727,
            7.408,
            -36.28,
            8.727,
            7.408,
            -35.901,
            8.585,
            7.408,
            -35.901,
            8.585,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_16_1",
        "polygon": [
            -38.941,
            9.728,
            4.202,
            -39.735,
            10.026,
            4.202,
            -39.735,
            10.026,
            7.408,
            -39.735,
            10.026,
            7.408,
            -38.941,
            9.728,
            7.408,
            -38.941,
            9.728,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_16_2",
        "polygon": [
            -38.522,
            9.57,
            4.14,
            -38.901,
            9.713,
            4.14,
            -38.901,
            9.713,
            6.988,
            -38.901,
            9.713,
            6.988,
            -38.522,
            9.57,
            6.988,
            -38.522,
            9.57,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_16_3",
        "polygon": [
            -38.522,
            9.57,
            6.988,
            -38.901,
            9.713,
            6.988,
            -38.901,
            9.713,
            7.408,
            -38.901,
            9.713,
            7.408,
            -38.522,
            9.57,
            7.408,
            -38.522,
            9.57,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_14_1",
        "polygon": [
            -41.562,
            10.713,
            4.202,
            -42.356,
            11.012,
            4.202,
            -42.356,
            11.012,
            7.408,
            -42.356,
            11.012,
            7.408,
            -41.562,
            10.713,
            7.408,
            -41.562,
            10.713,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_14_2",
        "polygon": [
            -41.143,
            10.556,
            4.14,
            -41.522,
            10.698,
            4.14,
            -41.522,
            10.698,
            6.988,
            -41.522,
            10.698,
            6.988,
            -41.143,
            10.556,
            6.988,
            -41.143,
            10.556,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_14_3",
        "polygon": [
            -41.143,
            10.556,
            6.988,
            -41.522,
            10.698,
            6.988,
            -41.522,
            10.698,
            7.408,
            -41.522,
            10.698,
            7.408,
            -41.143,
            10.556,
            7.408,
            -41.143,
            10.556,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_12_1",
        "polygon": [
            -44.182,
            11.698,
            4.202,
            -44.977,
            11.997,
            4.202,
            -44.977,
            11.997,
            7.408,
            -44.977,
            11.997,
            7.408,
            -44.182,
            11.698,
            7.408,
            -44.182,
            11.698,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_12_2",
        "polygon": [
            -43.764,
            11.541,
            4.14,
            -44.143,
            11.684,
            4.14,
            -44.143,
            11.684,
            6.988,
            -44.143,
            11.684,
            6.988,
            -43.764,
            11.541,
            6.988,
            -43.764,
            11.541,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_12_3",
        "polygon": [
            -43.764,
            11.541,
            6.988,
            -44.143,
            11.684,
            6.988,
            -44.143,
            11.684,
            7.408,
            -44.143,
            11.684,
            7.408,
            -43.764,
            11.541,
            7.408,
            -43.764,
            11.541,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_10_1",
        "polygon": [
            -46.803,
            12.684,
            4.202,
            -47.598,
            12.982,
            4.202,
            -47.598,
            12.982,
            7.408,
            -47.598,
            12.982,
            7.408,
            -46.803,
            12.684,
            7.408,
            -46.803,
            12.684,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_10_2",
        "polygon": [
            -46.385,
            12.526,
            4.14,
            -46.764,
            12.669,
            4.14,
            -46.764,
            12.669,
            6.988,
            -46.764,
            12.669,
            6.988,
            -46.385,
            12.526,
            6.988,
            -46.385,
            12.526,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_10_3",
        "polygon": [
            -46.385,
            12.526,
            6.988,
            -46.764,
            12.669,
            6.988,
            -46.764,
            12.669,
            7.408,
            -46.764,
            12.669,
            7.408,
            -46.385,
            12.526,
            7.408,
            -46.385,
            12.526,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_8_1",
        "polygon": [
            -49.424,
            13.669,
            4.202,
            -50.219,
            13.968,
            4.202,
            -50.219,
            13.968,
            7.408,
            -50.219,
            13.968,
            7.408,
            -49.424,
            13.669,
            7.408,
            -49.424,
            13.669,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_8_2",
        "polygon": [
            -49.006,
            13.512,
            4.14,
            -49.385,
            13.654,
            4.14,
            -49.385,
            13.654,
            6.988,
            -49.385,
            13.654,
            6.988,
            -49.006,
            13.512,
            6.988,
            -49.006,
            13.512,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_8_3",
        "polygon": [
            -49.006,
            13.512,
            6.988,
            -49.385,
            13.654,
            6.988,
            -49.385,
            13.654,
            7.408,
            -49.385,
            13.654,
            7.408,
            -49.006,
            13.512,
            7.408,
            -49.006,
            13.512,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_6_1",
        "polygon": [
            -52.045,
            14.654,
            4.202,
            -52.84,
            14.953,
            4.202,
            -52.84,
            14.953,
            7.408,
            -52.84,
            14.953,
            7.408,
            -52.045,
            14.654,
            7.408,
            -52.045,
            14.654,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_6_2",
        "polygon": [
            -51.627,
            14.497,
            4.14,
            -52.006,
            14.64,
            4.14,
            -52.006,
            14.64,
            6.988,
            -52.006,
            14.64,
            6.988,
            -51.627,
            14.497,
            6.988,
            -51.627,
            14.497,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_6_3",
        "polygon": [
            -51.627,
            14.497,
            6.988,
            -52.006,
            14.64,
            6.988,
            -52.006,
            14.64,
            7.408,
            -52.006,
            14.64,
            7.408,
            -51.627,
            14.497,
            7.408,
            -51.627,
            14.497,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_4_1",
        "polygon": [
            -54.666,
            15.64,
            4.202,
            -55.461,
            15.938,
            4.202,
            -55.461,
            15.938,
            7.408,
            -55.461,
            15.938,
            7.408,
            -54.666,
            15.64,
            7.408,
            -54.666,
            15.64,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_4_2",
        "polygon": [
            -54.248,
            15.482,
            4.14,
            -54.627,
            15.625,
            4.14,
            -54.627,
            15.625,
            6.988,
            -54.627,
            15.625,
            6.988,
            -54.248,
            15.482,
            6.988,
            -54.248,
            15.482,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_4_3",
        "polygon": [
            -54.248,
            15.482,
            6.988,
            -54.627,
            15.625,
            6.988,
            -54.627,
            15.625,
            7.408,
            -54.627,
            15.625,
            7.408,
            -54.248,
            15.482,
            7.408,
            -54.248,
            15.482,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_2_1",
        "polygon": [
            -57.287,
            16.625,
            4.202,
            -58.082,
            16.924,
            4.202,
            -58.082,
            16.924,
            7.408,
            -58.082,
            16.924,
            7.408,
            -57.287,
            16.625,
            7.408,
            -57.287,
            16.625,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_2_2",
        "polygon": [
            -56.868,
            16.468,
            4.14,
            -57.248,
            16.61,
            4.14,
            -57.248,
            16.61,
            6.988,
            -57.248,
            16.61,
            6.988,
            -56.868,
            16.468,
            6.988,
            -56.868,
            16.468,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_1OG_2_3",
        "polygon": [
            -56.868,
            16.468,
            6.988,
            -57.248,
            16.61,
            6.988,
            -57.248,
            16.61,
            7.408,
            -57.248,
            16.61,
            7.408,
            -56.868,
            16.468,
            7.408,
            -56.868,
            16.468,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_36_1",
        "polygon": [
            -12.732,
            -0.126,
            11.622,
            -13.526,
            0.173,
            11.622,
            -13.526,
            0.173,
            14.828,
            -13.526,
            0.173,
            14.828,
            -12.732,
            -0.126,
            14.828,
            -12.732,
            -0.126,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_36_2",
        "polygon": [
            -12.313,
            -0.283,
            11.56,
            -12.692,
            -0.141,
            11.56,
            -12.692,
            -0.141,
            14.408,
            -12.692,
            -0.141,
            14.408,
            -12.313,
            -0.283,
            14.408,
            -12.313,
            -0.283,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_36_3",
        "polygon": [
            -12.313,
            -0.283,
            14.408,
            -12.692,
            -0.141,
            14.408,
            -12.692,
            -0.141,
            14.828,
            -12.692,
            -0.141,
            14.828,
            -12.313,
            -0.283,
            14.828,
            -12.313,
            -0.283,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_34_1",
        "polygon": [
            -15.353,
            0.859,
            11.622,
            -16.147,
            1.158,
            11.622,
            -16.147,
            1.158,
            14.828,
            -16.147,
            1.158,
            14.828,
            -15.353,
            0.859,
            14.828,
            -15.353,
            0.859,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_34_2",
        "polygon": [
            -14.934,
            0.702,
            11.56,
            -15.313,
            0.845,
            11.56,
            -15.313,
            0.845,
            14.408,
            -15.313,
            0.845,
            14.408,
            -14.934,
            0.702,
            14.408,
            -14.934,
            0.702,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_34_3",
        "polygon": [
            -14.934,
            0.702,
            14.408,
            -15.313,
            0.845,
            14.408,
            -15.313,
            0.845,
            14.828,
            -15.313,
            0.845,
            14.828,
            -14.934,
            0.702,
            14.828,
            -14.934,
            0.702,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_32_1",
        "polygon": [
            -17.973,
            1.845,
            11.622,
            -18.768,
            2.144,
            11.622,
            -18.768,
            2.144,
            14.828,
            -18.768,
            2.144,
            14.828,
            -17.973,
            1.845,
            14.828,
            -17.973,
            1.845,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_32_2",
        "polygon": [
            -17.555,
            1.688,
            11.56,
            -17.934,
            1.83,
            11.56,
            -17.934,
            1.83,
            14.408,
            -17.934,
            1.83,
            14.408,
            -17.555,
            1.688,
            14.408,
            -17.555,
            1.688,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_32_3",
        "polygon": [
            -17.555,
            1.688,
            14.408,
            -17.934,
            1.83,
            14.408,
            -17.934,
            1.83,
            14.828,
            -17.934,
            1.83,
            14.828,
            -17.555,
            1.688,
            14.828,
            -17.555,
            1.688,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_30_1",
        "polygon": [
            -20.594,
            2.83,
            11.622,
            -21.389,
            3.129,
            11.622,
            -21.389,
            3.129,
            14.828,
            -21.389,
            3.129,
            14.828,
            -20.594,
            2.83,
            14.828,
            -20.594,
            2.83,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_30_2",
        "polygon": [
            -20.176,
            2.673,
            11.56,
            -20.555,
            2.815,
            11.56,
            -20.555,
            2.815,
            14.408,
            -20.555,
            2.815,
            14.408,
            -20.176,
            2.673,
            14.408,
            -20.176,
            2.673,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_30_3",
        "polygon": [
            -20.176,
            2.673,
            14.408,
            -20.555,
            2.815,
            14.408,
            -20.555,
            2.815,
            14.828,
            -20.555,
            2.815,
            14.828,
            -20.176,
            2.673,
            14.828,
            -20.176,
            2.673,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_28_1",
        "polygon": [
            -23.215,
            3.816,
            11.622,
            -24.01,
            4.114,
            11.622,
            -24.01,
            4.114,
            14.828,
            -24.01,
            4.114,
            14.828,
            -23.215,
            3.816,
            14.828,
            -23.215,
            3.816,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_28_2",
        "polygon": [
            -22.797,
            3.658,
            11.56,
            -23.176,
            3.801,
            11.56,
            -23.176,
            3.801,
            14.408,
            -23.176,
            3.801,
            14.408,
            -22.797,
            3.658,
            14.408,
            -22.797,
            3.658,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_28_3",
        "polygon": [
            -22.797,
            3.658,
            14.408,
            -23.176,
            3.801,
            14.408,
            -23.176,
            3.801,
            14.828,
            -23.176,
            3.801,
            14.828,
            -22.797,
            3.658,
            14.828,
            -22.797,
            3.658,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_26_1",
        "polygon": [
            -25.836,
            4.801,
            11.622,
            -26.631,
            5.1,
            11.622,
            -26.631,
            5.1,
            14.828,
            -26.631,
            5.1,
            14.828,
            -25.836,
            4.801,
            14.828,
            -25.836,
            4.801,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_26_2",
        "polygon": [
            -25.418,
            4.644,
            11.56,
            -25.797,
            4.786,
            11.56,
            -25.797,
            4.786,
            14.408,
            -25.797,
            4.786,
            14.408,
            -25.418,
            4.644,
            14.408,
            -25.418,
            4.644,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_26_3",
        "polygon": [
            -25.418,
            4.644,
            14.408,
            -25.797,
            4.786,
            14.408,
            -25.797,
            4.786,
            14.828,
            -25.797,
            4.786,
            14.828,
            -25.418,
            4.644,
            14.828,
            -25.418,
            4.644,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_24_1",
        "polygon": [
            -28.457,
            5.786,
            11.622,
            -29.252,
            6.085,
            11.622,
            -29.252,
            6.085,
            14.828,
            -29.252,
            6.085,
            14.828,
            -28.457,
            5.786,
            14.828,
            -28.457,
            5.786,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_24_2",
        "polygon": [
            -28.039,
            5.629,
            11.56,
            -28.418,
            5.771,
            11.56,
            -28.418,
            5.771,
            14.408,
            -28.418,
            5.771,
            14.408,
            -28.039,
            5.629,
            14.408,
            -28.039,
            5.629,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_24_3",
        "polygon": [
            -28.039,
            5.629,
            14.408,
            -28.418,
            5.771,
            14.408,
            -28.418,
            5.771,
            14.828,
            -28.418,
            5.771,
            14.828,
            -28.039,
            5.629,
            14.828,
            -28.039,
            5.629,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_22_1",
        "polygon": [
            -31.078,
            6.772,
            11.622,
            -31.873,
            7.07,
            11.622,
            -31.873,
            7.07,
            14.828,
            -31.873,
            7.07,
            14.828,
            -31.078,
            6.772,
            14.828,
            -31.078,
            6.772,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_22_2",
        "polygon": [
            -30.66,
            6.614,
            11.56,
            -31.039,
            6.757,
            11.56,
            -31.039,
            6.757,
            14.408,
            -31.039,
            6.757,
            14.408,
            -30.66,
            6.614,
            14.408,
            -30.66,
            6.614,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_22_3",
        "polygon": [
            -30.66,
            6.614,
            14.408,
            -31.039,
            6.757,
            14.408,
            -31.039,
            6.757,
            14.828,
            -31.039,
            6.757,
            14.828,
            -30.66,
            6.614,
            14.828,
            -30.66,
            6.614,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_20_1",
        "polygon": [
            -33.699,
            7.757,
            11.622,
            -34.494,
            8.056,
            11.622,
            -34.494,
            8.056,
            14.828,
            -34.494,
            8.056,
            14.828,
            -33.699,
            7.757,
            14.828,
            -33.699,
            7.757,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_20_2",
        "polygon": [
            -33.28,
            7.6,
            11.56,
            -33.66,
            7.742,
            11.56,
            -33.66,
            7.742,
            14.408,
            -33.66,
            7.742,
            14.408,
            -33.28,
            7.6,
            14.408,
            -33.28,
            7.6,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_20_3",
        "polygon": [
            -33.28,
            7.6,
            14.408,
            -33.66,
            7.742,
            14.408,
            -33.66,
            7.742,
            14.828,
            -33.66,
            7.742,
            14.828,
            -33.28,
            7.6,
            14.828,
            -33.28,
            7.6,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_18_1",
        "polygon": [
            -36.32,
            8.742,
            11.622,
            -37.114,
            9.041,
            11.622,
            -37.114,
            9.041,
            14.828,
            -37.114,
            9.041,
            14.828,
            -36.32,
            8.742,
            14.828,
            -36.32,
            8.742,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_18_2",
        "polygon": [
            -35.901,
            8.585,
            11.56,
            -36.28,
            8.727,
            11.56,
            -36.28,
            8.727,
            14.408,
            -36.28,
            8.727,
            14.408,
            -35.901,
            8.585,
            14.408,
            -35.901,
            8.585,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_18_3",
        "polygon": [
            -35.901,
            8.585,
            14.408,
            -36.28,
            8.727,
            14.408,
            -36.28,
            8.727,
            14.828,
            -36.28,
            8.727,
            14.828,
            -35.901,
            8.585,
            14.828,
            -35.901,
            8.585,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_16_1",
        "polygon": [
            -38.941,
            9.728,
            11.622,
            -39.735,
            10.026,
            11.622,
            -39.735,
            10.026,
            14.828,
            -39.735,
            10.026,
            14.828,
            -38.941,
            9.728,
            14.828,
            -38.941,
            9.728,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_16_2",
        "polygon": [
            -38.522,
            9.57,
            11.56,
            -38.901,
            9.713,
            11.56,
            -38.901,
            9.713,
            14.408,
            -38.901,
            9.713,
            14.408,
            -38.522,
            9.57,
            14.408,
            -38.522,
            9.57,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_16_3",
        "polygon": [
            -38.522,
            9.57,
            14.408,
            -38.901,
            9.713,
            14.408,
            -38.901,
            9.713,
            14.828,
            -38.901,
            9.713,
            14.828,
            -38.522,
            9.57,
            14.828,
            -38.522,
            9.57,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_14_1",
        "polygon": [
            -41.562,
            10.713,
            11.622,
            -42.356,
            11.012,
            11.622,
            -42.356,
            11.012,
            14.828,
            -42.356,
            11.012,
            14.828,
            -41.562,
            10.713,
            14.828,
            -41.562,
            10.713,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_14_2",
        "polygon": [
            -41.143,
            10.556,
            11.56,
            -41.522,
            10.698,
            11.56,
            -41.522,
            10.698,
            14.408,
            -41.522,
            10.698,
            14.408,
            -41.143,
            10.556,
            14.408,
            -41.143,
            10.556,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_14_3",
        "polygon": [
            -41.143,
            10.556,
            14.408,
            -41.522,
            10.698,
            14.408,
            -41.522,
            10.698,
            14.828,
            -41.522,
            10.698,
            14.828,
            -41.143,
            10.556,
            14.828,
            -41.143,
            10.556,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_12_1",
        "polygon": [
            -44.182,
            11.698,
            11.622,
            -44.977,
            11.997,
            11.622,
            -44.977,
            11.997,
            14.828,
            -44.977,
            11.997,
            14.828,
            -44.182,
            11.698,
            14.828,
            -44.182,
            11.698,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_12_2",
        "polygon": [
            -43.764,
            11.541,
            11.56,
            -44.143,
            11.684,
            11.56,
            -44.143,
            11.684,
            14.408,
            -44.143,
            11.684,
            14.408,
            -43.764,
            11.541,
            14.408,
            -43.764,
            11.541,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_12_3",
        "polygon": [
            -43.764,
            11.541,
            14.408,
            -44.143,
            11.684,
            14.408,
            -44.143,
            11.684,
            14.828,
            -44.143,
            11.684,
            14.828,
            -43.764,
            11.541,
            14.828,
            -43.764,
            11.541,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_10_1",
        "polygon": [
            -46.803,
            12.684,
            11.622,
            -47.598,
            12.982,
            11.622,
            -47.598,
            12.982,
            14.828,
            -47.598,
            12.982,
            14.828,
            -46.803,
            12.684,
            14.828,
            -46.803,
            12.684,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_10_2",
        "polygon": [
            -46.385,
            12.526,
            11.56,
            -46.764,
            12.669,
            11.56,
            -46.764,
            12.669,
            14.408,
            -46.764,
            12.669,
            14.408,
            -46.385,
            12.526,
            14.408,
            -46.385,
            12.526,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_10_3",
        "polygon": [
            -46.385,
            12.526,
            14.408,
            -46.764,
            12.669,
            14.408,
            -46.764,
            12.669,
            14.828,
            -46.764,
            12.669,
            14.828,
            -46.385,
            12.526,
            14.828,
            -46.385,
            12.526,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_8_1",
        "polygon": [
            -49.424,
            13.669,
            11.622,
            -50.219,
            13.968,
            11.622,
            -50.219,
            13.968,
            14.828,
            -50.219,
            13.968,
            14.828,
            -49.424,
            13.669,
            14.828,
            -49.424,
            13.669,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_8_2",
        "polygon": [
            -49.006,
            13.512,
            11.56,
            -49.385,
            13.654,
            11.56,
            -49.385,
            13.654,
            14.408,
            -49.385,
            13.654,
            14.408,
            -49.006,
            13.512,
            14.408,
            -49.006,
            13.512,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_8_3",
        "polygon": [
            -49.006,
            13.512,
            14.408,
            -49.385,
            13.654,
            14.408,
            -49.385,
            13.654,
            14.828,
            -49.385,
            13.654,
            14.828,
            -49.006,
            13.512,
            14.828,
            -49.006,
            13.512,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_6_1",
        "polygon": [
            -52.045,
            14.654,
            11.622,
            -52.84,
            14.953,
            11.622,
            -52.84,
            14.953,
            14.828,
            -52.84,
            14.953,
            14.828,
            -52.045,
            14.654,
            14.828,
            -52.045,
            14.654,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_6_2",
        "polygon": [
            -51.627,
            14.497,
            11.56,
            -52.006,
            14.64,
            11.56,
            -52.006,
            14.64,
            14.408,
            -52.006,
            14.64,
            14.408,
            -51.627,
            14.497,
            14.408,
            -51.627,
            14.497,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_6_3",
        "polygon": [
            -51.627,
            14.497,
            14.408,
            -52.006,
            14.64,
            14.408,
            -52.006,
            14.64,
            14.828,
            -52.006,
            14.64,
            14.828,
            -51.627,
            14.497,
            14.828,
            -51.627,
            14.497,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_4_1",
        "polygon": [
            -54.666,
            15.64,
            11.622,
            -55.461,
            15.938,
            11.622,
            -55.461,
            15.938,
            14.828,
            -55.461,
            15.938,
            14.828,
            -54.666,
            15.64,
            14.828,
            -54.666,
            15.64,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_4_2",
        "polygon": [
            -54.248,
            15.482,
            11.56,
            -54.627,
            15.625,
            11.56,
            -54.627,
            15.625,
            14.408,
            -54.627,
            15.625,
            14.408,
            -54.248,
            15.482,
            14.408,
            -54.248,
            15.482,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_4_3",
        "polygon": [
            -54.248,
            15.482,
            14.408,
            -54.627,
            15.625,
            14.408,
            -54.627,
            15.625,
            14.828,
            -54.627,
            15.625,
            14.828,
            -54.248,
            15.482,
            14.828,
            -54.248,
            15.482,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_2_1",
        "polygon": [
            -57.287,
            16.625,
            11.622,
            -58.082,
            16.924,
            11.622,
            -58.082,
            16.924,
            14.828,
            -58.082,
            16.924,
            14.828,
            -57.287,
            16.625,
            14.828,
            -57.287,
            16.625,
            11.622
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_2_2",
        "polygon": [
            -56.868,
            16.468,
            11.56,
            -57.248,
            16.61,
            11.56,
            -57.248,
            16.61,
            14.408,
            -57.248,
            16.61,
            14.408,
            -56.868,
            16.468,
            14.408,
            -56.868,
            16.468,
            11.56
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_3OG_2_3",
        "polygon": [
            -56.868,
            16.468,
            14.408,
            -57.248,
            16.61,
            14.408,
            -57.248,
            16.61,
            14.828,
            -57.248,
            16.61,
            14.828,
            -56.868,
            16.468,
            14.828,
            -56.868,
            16.468,
            14.408
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_36_1",
        "polygon": [
            -12.313,
            -0.283,
            7.912,
            -13.108,
            0.016,
            7.912,
            -13.108,
            0.016,
            11.118,
            -13.108,
            0.016,
            11.118,
            -12.313,
            -0.283,
            11.118,
            -12.313,
            -0.283,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_36_2",
        "polygon": [
            -13.147,
            0.03,
            7.85,
            -13.526,
            0.173,
            7.85,
            -13.526,
            0.173,
            10.698,
            -13.526,
            0.173,
            10.698,
            -13.147,
            0.03,
            10.698,
            -13.147,
            0.03,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_36_3",
        "polygon": [
            -13.147,
            0.03,
            10.698,
            -13.526,
            0.173,
            10.698,
            -13.526,
            0.173,
            11.118,
            -13.526,
            0.173,
            11.118,
            -13.147,
            0.03,
            11.118,
            -13.147,
            0.03,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_34_1",
        "polygon": [
            -14.934,
            0.702,
            7.912,
            -15.729,
            1.001,
            7.912,
            -15.729,
            1.001,
            11.118,
            -15.729,
            1.001,
            11.118,
            -14.934,
            0.702,
            11.118,
            -14.934,
            0.702,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_34_2",
        "polygon": [
            -15.768,
            1.016,
            7.85,
            -16.147,
            1.158,
            7.85,
            -16.147,
            1.158,
            10.698,
            -16.147,
            1.158,
            10.698,
            -15.768,
            1.016,
            10.698,
            -15.768,
            1.016,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_34_3",
        "polygon": [
            -15.768,
            1.016,
            10.698,
            -16.147,
            1.158,
            10.698,
            -16.147,
            1.158,
            11.118,
            -16.147,
            1.158,
            11.118,
            -15.768,
            1.016,
            11.118,
            -15.768,
            1.016,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_32_1",
        "polygon": [
            -17.555,
            1.688,
            7.912,
            -18.35,
            1.986,
            7.912,
            -18.35,
            1.986,
            11.118,
            -18.35,
            1.986,
            11.118,
            -17.555,
            1.688,
            11.118,
            -17.555,
            1.688,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_32_2",
        "polygon": [
            -18.389,
            2.001,
            7.85,
            -18.768,
            2.144,
            7.85,
            -18.768,
            2.144,
            10.698,
            -18.768,
            2.144,
            10.698,
            -18.389,
            2.001,
            10.698,
            -18.389,
            2.001,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_32_3",
        "polygon": [
            -18.389,
            2.001,
            10.698,
            -18.768,
            2.144,
            10.698,
            -18.768,
            2.144,
            11.118,
            -18.768,
            2.144,
            11.118,
            -18.389,
            2.001,
            11.118,
            -18.389,
            2.001,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_30_1",
        "polygon": [
            -20.176,
            2.673,
            7.912,
            -20.971,
            2.972,
            7.912,
            -20.971,
            2.972,
            11.118,
            -20.971,
            2.972,
            11.118,
            -20.176,
            2.673,
            11.118,
            -20.176,
            2.673,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_30_2",
        "polygon": [
            -21.01,
            2.986,
            7.85,
            -21.389,
            3.129,
            7.85,
            -21.389,
            3.129,
            10.698,
            -21.389,
            3.129,
            10.698,
            -21.01,
            2.986,
            10.698,
            -21.01,
            2.986,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_30_3",
        "polygon": [
            -21.01,
            2.986,
            10.698,
            -21.389,
            3.129,
            10.698,
            -21.389,
            3.129,
            11.118,
            -21.389,
            3.129,
            11.118,
            -21.01,
            2.986,
            11.118,
            -21.01,
            2.986,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_28_1",
        "polygon": [
            -22.797,
            3.658,
            7.912,
            -23.592,
            3.957,
            7.912,
            -23.592,
            3.957,
            11.118,
            -23.592,
            3.957,
            11.118,
            -22.797,
            3.658,
            11.118,
            -22.797,
            3.658,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_28_2",
        "polygon": [
            -23.631,
            3.972,
            7.85,
            -24.01,
            4.114,
            7.85,
            -24.01,
            4.114,
            10.698,
            -24.01,
            4.114,
            10.698,
            -23.631,
            3.972,
            10.698,
            -23.631,
            3.972,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_28_3",
        "polygon": [
            -23.631,
            3.972,
            10.698,
            -24.01,
            4.114,
            10.698,
            -24.01,
            4.114,
            11.118,
            -24.01,
            4.114,
            11.118,
            -23.631,
            3.972,
            11.118,
            -23.631,
            3.972,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_26_1",
        "polygon": [
            -25.418,
            4.644,
            7.912,
            -26.212,
            4.942,
            7.912,
            -26.212,
            4.942,
            11.118,
            -26.212,
            4.942,
            11.118,
            -25.418,
            4.644,
            11.118,
            -25.418,
            4.644,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_26_2",
        "polygon": [
            -26.252,
            4.957,
            7.85,
            -26.631,
            5.1,
            7.85,
            -26.631,
            5.1,
            10.698,
            -26.631,
            5.1,
            10.698,
            -26.252,
            4.957,
            10.698,
            -26.252,
            4.957,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_26_3",
        "polygon": [
            -26.252,
            4.957,
            10.698,
            -26.631,
            5.1,
            10.698,
            -26.631,
            5.1,
            11.118,
            -26.631,
            5.1,
            11.118,
            -26.252,
            4.957,
            11.118,
            -26.252,
            4.957,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_24_1",
        "polygon": [
            -28.039,
            5.629,
            7.912,
            -28.833,
            5.928,
            7.912,
            -28.833,
            5.928,
            11.118,
            -28.833,
            5.928,
            11.118,
            -28.039,
            5.629,
            11.118,
            -28.039,
            5.629,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_24_2",
        "polygon": [
            -28.873,
            5.942,
            7.85,
            -29.252,
            6.085,
            7.85,
            -29.252,
            6.085,
            10.698,
            -29.252,
            6.085,
            10.698,
            -28.873,
            5.942,
            10.698,
            -28.873,
            5.942,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_24_3",
        "polygon": [
            -28.873,
            5.942,
            10.698,
            -29.252,
            6.085,
            10.698,
            -29.252,
            6.085,
            11.118,
            -29.252,
            6.085,
            11.118,
            -28.873,
            5.942,
            11.118,
            -28.873,
            5.942,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_22_1",
        "polygon": [
            -30.66,
            6.614,
            7.912,
            -31.454,
            6.913,
            7.912,
            -31.454,
            6.913,
            11.118,
            -31.454,
            6.913,
            11.118,
            -30.66,
            6.614,
            11.118,
            -30.66,
            6.614,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_22_2",
        "polygon": [
            -31.494,
            6.928,
            7.85,
            -31.873,
            7.07,
            7.85,
            -31.873,
            7.07,
            10.698,
            -31.873,
            7.07,
            10.698,
            -31.494,
            6.928,
            10.698,
            -31.494,
            6.928,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_22_3",
        "polygon": [
            -31.494,
            6.928,
            10.698,
            -31.873,
            7.07,
            10.698,
            -31.873,
            7.07,
            11.118,
            -31.873,
            7.07,
            11.118,
            -31.494,
            6.928,
            11.118,
            -31.494,
            6.928,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_20_1",
        "polygon": [
            -33.28,
            7.6,
            7.912,
            -34.075,
            7.898,
            7.912,
            -34.075,
            7.898,
            11.118,
            -34.075,
            7.898,
            11.118,
            -33.28,
            7.6,
            11.118,
            -33.28,
            7.6,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_20_2",
        "polygon": [
            -34.114,
            7.913,
            7.85,
            -34.494,
            8.056,
            7.85,
            -34.494,
            8.056,
            10.698,
            -34.494,
            8.056,
            10.698,
            -34.114,
            7.913,
            10.698,
            -34.114,
            7.913,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_20_3",
        "polygon": [
            -34.114,
            7.913,
            10.698,
            -34.494,
            8.056,
            10.698,
            -34.494,
            8.056,
            11.118,
            -34.494,
            8.056,
            11.118,
            -34.114,
            7.913,
            11.118,
            -34.114,
            7.913,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_18_1",
        "polygon": [
            -35.901,
            8.585,
            7.912,
            -36.696,
            8.884,
            7.912,
            -36.696,
            8.884,
            11.118,
            -36.696,
            8.884,
            11.118,
            -35.901,
            8.585,
            11.118,
            -35.901,
            8.585,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_18_2",
        "polygon": [
            -36.735,
            8.899,
            7.85,
            -37.114,
            9.041,
            7.85,
            -37.114,
            9.041,
            10.698,
            -37.114,
            9.041,
            10.698,
            -36.735,
            8.899,
            10.698,
            -36.735,
            8.899,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_18_3",
        "polygon": [
            -36.735,
            8.899,
            10.698,
            -37.114,
            9.041,
            10.698,
            -37.114,
            9.041,
            11.118,
            -37.114,
            9.041,
            11.118,
            -36.735,
            8.899,
            11.118,
            -36.735,
            8.899,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_16_1",
        "polygon": [
            -38.522,
            9.57,
            7.912,
            -39.317,
            9.869,
            7.912,
            -39.317,
            9.869,
            11.118,
            -39.317,
            9.869,
            11.118,
            -38.522,
            9.57,
            11.118,
            -38.522,
            9.57,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_16_2",
        "polygon": [
            -39.356,
            9.884,
            7.85,
            -39.735,
            10.026,
            7.85,
            -39.735,
            10.026,
            10.698,
            -39.735,
            10.026,
            10.698,
            -39.356,
            9.884,
            10.698,
            -39.356,
            9.884,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_16_3",
        "polygon": [
            -39.356,
            9.884,
            10.698,
            -39.735,
            10.026,
            10.698,
            -39.735,
            10.026,
            11.118,
            -39.735,
            10.026,
            11.118,
            -39.356,
            9.884,
            11.118,
            -39.356,
            9.884,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_14_1",
        "polygon": [
            -41.143,
            10.556,
            7.912,
            -41.938,
            10.854,
            7.912,
            -41.938,
            10.854,
            11.118,
            -41.938,
            10.854,
            11.118,
            -41.143,
            10.556,
            11.118,
            -41.143,
            10.556,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_14_2",
        "polygon": [
            -41.977,
            10.869,
            7.85,
            -42.356,
            11.012,
            7.85,
            -42.356,
            11.012,
            10.698,
            -42.356,
            11.012,
            10.698,
            -41.977,
            10.869,
            10.698,
            -41.977,
            10.869,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_14_3",
        "polygon": [
            -41.977,
            10.869,
            10.698,
            -42.356,
            11.012,
            10.698,
            -42.356,
            11.012,
            11.118,
            -42.356,
            11.012,
            11.118,
            -41.977,
            10.869,
            11.118,
            -41.977,
            10.869,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_12_1",
        "polygon": [
            -43.764,
            11.541,
            7.912,
            -44.559,
            11.84,
            7.912,
            -44.559,
            11.84,
            11.118,
            -44.559,
            11.84,
            11.118,
            -43.764,
            11.541,
            11.118,
            -43.764,
            11.541,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_12_2",
        "polygon": [
            -44.598,
            11.855,
            7.85,
            -44.977,
            11.997,
            7.85,
            -44.977,
            11.997,
            10.698,
            -44.977,
            11.997,
            10.698,
            -44.598,
            11.855,
            10.698,
            -44.598,
            11.855,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_12_3",
        "polygon": [
            -44.598,
            11.855,
            10.698,
            -44.977,
            11.997,
            10.698,
            -44.977,
            11.997,
            11.118,
            -44.977,
            11.997,
            11.118,
            -44.598,
            11.855,
            11.118,
            -44.598,
            11.855,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_10_1",
        "polygon": [
            -46.385,
            12.526,
            7.912,
            -47.18,
            12.825,
            7.912,
            -47.18,
            12.825,
            11.118,
            -47.18,
            12.825,
            11.118,
            -46.385,
            12.526,
            11.118,
            -46.385,
            12.526,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_10_2",
        "polygon": [
            -47.219,
            12.84,
            7.85,
            -47.598,
            12.982,
            7.85,
            -47.598,
            12.982,
            10.698,
            -47.598,
            12.982,
            10.698,
            -47.219,
            12.84,
            10.698,
            -47.219,
            12.84,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_10_3",
        "polygon": [
            -47.219,
            12.84,
            10.698,
            -47.598,
            12.982,
            10.698,
            -47.598,
            12.982,
            11.118,
            -47.598,
            12.982,
            11.118,
            -47.219,
            12.84,
            11.118,
            -47.219,
            12.84,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_8_1",
        "polygon": [
            -49.006,
            13.512,
            7.912,
            -49.8,
            13.81,
            7.912,
            -49.8,
            13.81,
            11.118,
            -49.8,
            13.81,
            11.118,
            -49.006,
            13.512,
            11.118,
            -49.006,
            13.512,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_8_2",
        "polygon": [
            -49.84,
            13.825,
            7.85,
            -50.219,
            13.968,
            7.85,
            -50.219,
            13.968,
            10.698,
            -50.219,
            13.968,
            10.698,
            -49.84,
            13.825,
            10.698,
            -49.84,
            13.825,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_8_3",
        "polygon": [
            -49.84,
            13.825,
            10.698,
            -50.219,
            13.968,
            10.698,
            -50.219,
            13.968,
            11.118,
            -50.219,
            13.968,
            11.118,
            -49.84,
            13.825,
            11.118,
            -49.84,
            13.825,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_6_1",
        "polygon": [
            -51.627,
            14.497,
            7.912,
            -52.421,
            14.796,
            7.912,
            -52.421,
            14.796,
            11.118,
            -52.421,
            14.796,
            11.118,
            -51.627,
            14.497,
            11.118,
            -51.627,
            14.497,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_6_2",
        "polygon": [
            -52.461,
            14.811,
            7.85,
            -52.84,
            14.953,
            7.85,
            -52.84,
            14.953,
            10.698,
            -52.84,
            14.953,
            10.698,
            -52.461,
            14.811,
            10.698,
            -52.461,
            14.811,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_6_3",
        "polygon": [
            -52.461,
            14.811,
            10.698,
            -52.84,
            14.953,
            10.698,
            -52.84,
            14.953,
            11.118,
            -52.84,
            14.953,
            11.118,
            -52.461,
            14.811,
            11.118,
            -52.461,
            14.811,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_4_1",
        "polygon": [
            -54.248,
            15.482,
            7.912,
            -55.042,
            15.781,
            7.912,
            -55.042,
            15.781,
            11.118,
            -55.042,
            15.781,
            11.118,
            -54.248,
            15.482,
            11.118,
            -54.248,
            15.482,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_4_2",
        "polygon": [
            -55.082,
            15.796,
            7.85,
            -55.461,
            15.938,
            7.85,
            -55.461,
            15.938,
            10.698,
            -55.461,
            15.938,
            10.698,
            -55.082,
            15.796,
            10.698,
            -55.082,
            15.796,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_4_3",
        "polygon": [
            -55.082,
            15.796,
            10.698,
            -55.461,
            15.938,
            10.698,
            -55.461,
            15.938,
            11.118,
            -55.461,
            15.938,
            11.118,
            -55.082,
            15.796,
            11.118,
            -55.082,
            15.796,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_2_1",
        "polygon": [
            -56.868,
            16.468,
            7.912,
            -57.663,
            16.767,
            7.912,
            -57.663,
            16.767,
            11.118,
            -57.663,
            16.767,
            11.118,
            -56.868,
            16.468,
            11.118,
            -56.868,
            16.468,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_2_2",
        "polygon": [
            -57.702,
            16.781,
            7.85,
            -58.082,
            16.924,
            7.85,
            -58.082,
            16.924,
            10.698,
            -58.082,
            16.924,
            10.698,
            -57.702,
            16.781,
            10.698,
            -57.702,
            16.781,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.5-AxJ 12_20_2OG_2_3",
        "polygon": [
            -57.702,
            16.781,
            10.698,
            -58.082,
            16.924,
            10.698,
            -58.082,
            16.924,
            11.118,
            -58.082,
            16.924,
            11.118,
            -57.702,
            16.781,
            11.118,
            -57.702,
            16.781,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_2_1",
        "polygon": [
            19.59,
            -12.277,
            4.202,
            18.795,
            -11.979,
            4.202,
            18.795,
            -11.979,
            7.408,
            18.795,
            -11.979,
            7.408,
            19.59,
            -12.277,
            7.408,
            19.59,
            -12.277,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_2_2",
        "polygon": [
            20.008,
            -12.435,
            4.14,
            19.629,
            -12.292,
            4.14,
            19.629,
            -12.292,
            6.988,
            19.629,
            -12.292,
            6.988,
            20.008,
            -12.435,
            6.988,
            20.008,
            -12.435,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_2_3",
        "polygon": [
            20.008,
            -12.435,
            6.988,
            19.629,
            -12.292,
            6.988,
            19.629,
            -12.292,
            7.408,
            19.629,
            -12.292,
            7.408,
            20.008,
            -12.435,
            7.408,
            20.008,
            -12.435,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_1_1",
        "polygon": [
            18.697,
            -11.942,
            4.202,
            17.484,
            -11.486,
            4.202,
            17.484,
            -11.486,
            7.408,
            17.484,
            -11.486,
            7.408,
            18.697,
            -11.942,
            7.408,
            18.697,
            -11.942,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_1_2",
        "polygon": [
            17.387,
            -11.449,
            4.202,
            16.716,
            -11.197,
            4.202,
            16.716,
            -11.197,
            7.408,
            16.716,
            -11.197,
            7.408,
            17.387,
            -11.449,
            7.408,
            17.387,
            -11.449,
            4.202
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_4_1",
        "polygon": [
            22.21,
            -13.263,
            4.202,
            21.416,
            -12.964,
            4.202,
            21.416,
            -12.964,
            7.408,
            21.416,
            -12.964,
            7.408,
            22.21,
            -13.263,
            7.408,
            22.21,
            -13.263,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_4_2",
        "polygon": [
            22.629,
            -13.42,
            4.14,
            22.25,
            -13.277,
            4.14,
            22.25,
            -13.277,
            6.988,
            22.25,
            -13.277,
            6.988,
            22.629,
            -13.42,
            6.988,
            22.629,
            -13.42,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_4_3",
        "polygon": [
            22.629,
            -13.42,
            6.988,
            22.25,
            -13.277,
            6.988,
            22.25,
            -13.277,
            7.408,
            22.25,
            -13.277,
            7.408,
            22.629,
            -13.42,
            7.408,
            22.629,
            -13.42,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_8_1",
        "polygon": [
            27.452,
            -15.233,
            4.202,
            26.658,
            -14.935,
            4.202,
            26.658,
            -14.935,
            7.408,
            26.658,
            -14.935,
            7.408,
            27.452,
            -15.233,
            7.408,
            27.452,
            -15.233,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_8_2",
        "polygon": [
            27.871,
            -15.391,
            4.14,
            27.492,
            -15.248,
            4.14,
            27.492,
            -15.248,
            6.988,
            27.492,
            -15.248,
            6.988,
            27.871,
            -15.391,
            6.988,
            27.871,
            -15.391,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_8_3",
        "polygon": [
            27.871,
            -15.391,
            6.988,
            27.492,
            -15.248,
            6.988,
            27.492,
            -15.248,
            7.408,
            27.492,
            -15.248,
            7.408,
            27.871,
            -15.391,
            7.408,
            27.871,
            -15.391,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_10_1",
        "polygon": [
            30.073,
            -16.219,
            4.202,
            29.278,
            -15.92,
            4.202,
            29.278,
            -15.92,
            7.408,
            29.278,
            -15.92,
            7.408,
            30.073,
            -16.219,
            7.408,
            30.073,
            -16.219,
            4.202
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_10_2",
        "polygon": [
            30.491,
            -16.376,
            4.14,
            30.112,
            -16.234,
            4.14,
            30.112,
            -16.234,
            6.988,
            30.112,
            -16.234,
            6.988,
            30.491,
            -16.376,
            6.988,
            30.491,
            -16.376,
            4.14
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_10_3",
        "polygon": [
            30.491,
            -16.376,
            6.988,
            30.112,
            -16.234,
            6.988,
            30.112,
            -16.234,
            7.408,
            30.112,
            -16.234,
            7.408,
            30.491,
            -16.376,
            7.408,
            30.491,
            -16.376,
            6.988
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_1_1",
        "polygon": [
            18.697,
            -11.942,
            7.912,
            17.484,
            -11.486,
            7.912,
            17.484,
            -11.486,
            11.118,
            17.484,
            -11.486,
            11.118,
            18.697,
            -11.942,
            11.118,
            18.697,
            -11.942,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_1_2",
        "polygon": [
            17.387,
            -11.449,
            7.912,
            16.716,
            -11.197,
            7.912,
            16.716,
            -11.197,
            11.118,
            16.716,
            -11.197,
            11.118,
            17.387,
            -11.449,
            11.118,
            17.387,
            -11.449,
            7.912
        ],
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_10_1",
        "polygon": [
            30.073,
            -16.219,
            7.912,
            29.278,
            -15.92,
            7.912,
            29.278,
            -15.92,
            11.118,
            29.278,
            -15.92,
            11.118,
            30.073,
            -16.219,
            11.118,
            30.073,
            -16.219,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_10_2",
        "polygon": [
            30.491,
            -16.376,
            7.85,
            30.112,
            -16.234,
            7.85,
            30.112,
            -16.234,
            10.698,
            30.112,
            -16.234,
            10.698,
            30.491,
            -16.376,
            10.698,
            30.491,
            -16.376,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_10_3",
        "polygon": [
            30.491,
            -16.376,
            10.698,
            30.112,
            -16.234,
            10.698,
            30.112,
            -16.234,
            11.118,
            30.112,
            -16.234,
            11.118,
            30.491,
            -16.376,
            11.118,
            30.491,
            -16.376,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_8_1",
        "polygon": [
            27.036,
            -15.077,
            7.85,
            26.657,
            -14.935,
            7.85,
            26.657,
            -14.935,
            10.698,
            26.657,
            -14.935,
            10.698,
            27.036,
            -15.077,
            10.698,
            27.036,
            -15.077,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_8_2",
        "polygon": [
            27.036,
            -15.077,
            10.698,
            26.657,
            -14.935,
            10.698,
            26.657,
            -14.935,
            11.118,
            26.657,
            -14.935,
            11.118,
            27.036,
            -15.077,
            11.118,
            27.036,
            -15.077,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_8_3",
        "polygon": [
            27.871,
            -15.391,
            7.912,
            27.076,
            -15.092,
            7.912,
            27.076,
            -15.092,
            11.118,
            27.076,
            -15.092,
            11.118,
            27.871,
            -15.391,
            11.118,
            27.871,
            -15.391,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_4_1",
        "polygon": [
            21.795,
            -13.106,
            7.85,
            21.415,
            -12.964,
            7.85,
            21.415,
            -12.964,
            10.698,
            21.415,
            -12.964,
            10.698,
            21.795,
            -13.106,
            10.698,
            21.795,
            -13.106,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_4_2",
        "polygon": [
            21.795,
            -13.106,
            10.698,
            21.415,
            -12.964,
            10.698,
            21.415,
            -12.964,
            11.118,
            21.415,
            -12.964,
            11.118,
            21.795,
            -13.106,
            11.118,
            21.795,
            -13.106,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_4_3",
        "polygon": [
            22.629,
            -13.42,
            7.912,
            21.834,
            -13.121,
            7.912,
            21.834,
            -13.121,
            11.118,
            21.834,
            -13.121,
            11.118,
            22.629,
            -13.42,
            11.118,
            22.629,
            -13.42,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_2_1",
        "polygon": [
            19.174,
            -12.121,
            7.85,
            18.795,
            -11.978,
            7.85,
            18.795,
            -11.978,
            10.698,
            18.795,
            -11.978,
            10.698,
            19.174,
            -12.121,
            10.698,
            19.174,
            -12.121,
            7.85
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_2_2",
        "polygon": [
            19.174,
            -12.121,
            10.698,
            18.795,
            -11.978,
            10.698,
            18.795,
            -11.978,
            11.118,
            18.795,
            -11.978,
            11.118,
            19.174,
            -12.121,
            11.118,
            19.174,
            -12.121,
            10.698
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_2_3",
        "polygon": [
            20.008,
            -12.435,
            7.912,
            19.213,
            -12.136,
            7.912,
            19.213,
            -12.136,
            11.118,
            19.213,
            -12.136,
            11.118,
            20.008,
            -12.435,
            11.118,
            20.008,
            -12.435,
            7.912
        ],
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_6_1",
        "polygon": [
            24.898,
            -14.273,
            7.83,
            24.037,
            -13.949,
            7.83,
            24.037,
            -13.949,
            10.599,
            24.037,
            -13.949,
            10.599,
            24.898,
            -14.273,
            10.599,
            24.898,
            -14.273,
            7.83
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_6_2",
        "polygon": [
            24.898,
            -14.273,
            10.658,
            24.037,
            -13.949,
            10.658,
            24.037,
            -13.949,
            11.118,
            24.037,
            -13.949,
            11.118,
            24.898,
            -14.273,
            11.118,
            24.898,
            -14.273,
            10.658
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_2OG_6_3",
        "polygon": [
            25.25,
            -14.405,
            7.912,
            24.937,
            -14.288,
            7.912,
            24.937,
            -14.288,
            11.118,
            24.937,
            -14.288,
            11.118,
            25.25,
            -14.405,
            11.118,
            25.25,
            -14.405,
            7.912
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_6_1",
        "polygon": [
            25.25,
            -14.405,
            4.12,
            24.389,
            -14.082,
            4.12,
            24.389,
            -14.082,
            6.889,
            24.389,
            -14.082,
            6.889,
            25.25,
            -14.405,
            6.889,
            25.25,
            -14.405,
            4.12
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_6_2",
        "polygon": [
            25.25,
            -14.405,
            6.948,
            24.389,
            -14.082,
            6.948,
            24.389,
            -14.082,
            7.408,
            24.389,
            -14.082,
            7.408,
            25.25,
            -14.405,
            7.408,
            25.25,
            -14.405,
            6.948
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.8-AxJ 23_25_1OG_6_3",
        "polygon": [
            24.349,
            -14.067,
            4.202,
            24.037,
            -13.949,
            4.202,
            24.037,
            -13.949,
            7.408,
            24.037,
            -13.949,
            7.408,
            24.349,
            -14.067,
            7.408,
            24.349,
            -14.067,
            4.202
        ],
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1,
        "05-24 11:15": 1,
        "05-24 11:30": 1,
        "05-24 11:45": 1,
        "05-24 12:00": 1,
        "05-24 12:15": 1,
        "05-24 12:30": 1,
        "05-24 12:45": 1,
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_2_1",
        "polygon": [
            -78.391,
            26.618,
            2.928,
            -78.534,
            26.239,
            2.928,
            -78.534,
            26.239,
            3.698,
            -78.534,
            26.239,
            3.698,
            -78.391,
            26.618,
            3.698,
            -78.391,
            26.618,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_2_2",
        "polygon": [
            -78.391,
            26.618,
            0.08,
            -78.534,
            26.239,
            0.08,
            -78.534,
            26.239,
            2.928,
            -78.534,
            26.239,
            2.928,
            -78.391,
            26.618,
            2.928,
            -78.391,
            26.618,
            0.08
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_2_3",
        "polygon": [
            -78.078,
            27.452,
            0.142,
            -78.376,
            26.657,
            0.142,
            -78.376,
            26.657,
            3.698,
            -78.376,
            26.657,
            3.698,
            -78.078,
            27.452,
            3.698,
            -78.078,
            27.452,
            0.142
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_3_1",
        "polygon": [
            -77.585,
            28.763,
            0.06,
            -78.041,
            27.549,
            0.06,
            -78.041,
            27.549,
            2.869,
            -78.041,
            27.549,
            2.869,
            -77.585,
            28.763,
            2.869,
            -77.585,
            28.763,
            0.06
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_3_2",
        "polygon": [
            -77.585,
            28.763,
            2.928,
            -78.041,
            27.549,
            2.928,
            -78.041,
            27.549,
            3.698,
            -78.041,
            27.549,
            3.698,
            -77.585,
            28.763,
            3.698,
            -77.585,
            28.763,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_4_1",
        "polygon": [
            -77.092,
            30.073,
            0.06,
            -77.548,
            28.86,
            0.06,
            -77.548,
            28.86,
            2.869,
            -77.548,
            28.86,
            2.869,
            -77.092,
            30.073,
            2.869,
            -77.092,
            30.073,
            0.06
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_4_2",
        "polygon": [
            -77.092,
            30.073,
            2.928,
            -77.548,
            28.86,
            2.928,
            -77.548,
            28.86,
            3.698,
            -77.548,
            28.86,
            3.698,
            -77.092,
            30.073,
            3.698,
            -77.092,
            30.073,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_11_1",
        "polygon": [
            -73.644,
            39.246,
            0.06,
            -74.1,
            38.033,
            0.06,
            -74.1,
            38.033,
            2.869,
            -74.1,
            38.033,
            2.869,
            -73.644,
            39.246,
            2.869,
            -73.644,
            39.246,
            0.06
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_11_2",
        "polygon": [
            -73.644,
            39.246,
            2.928,
            -74.1,
            38.033,
            2.928,
            -74.1,
            38.033,
            3.698,
            -74.1,
            38.033,
            3.698,
            -73.644,
            39.246,
            3.698,
            -73.644,
            39.246,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_12_1",
        "polygon": [
            -73.151,
            40.557,
            0.06,
            -73.607,
            39.343,
            0.06,
            -73.607,
            39.343,
            2.869,
            -73.607,
            39.343,
            2.869,
            -73.151,
            40.557,
            2.869,
            -73.151,
            40.557,
            0.06
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_12_2",
        "polygon": [
            -73.151,
            40.557,
            2.928,
            -73.607,
            39.343,
            2.928,
            -73.607,
            39.343,
            3.698,
            -73.607,
            39.343,
            3.698,
            -73.151,
            40.557,
            3.698,
            -73.151,
            40.557,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_6_1",
        "polygon": [
            -76.42,
            31.86,
            2.928,
            -76.563,
            31.481,
            2.928,
            -76.563,
            31.481,
            3.698,
            -76.563,
            31.481,
            3.698,
            -76.42,
            31.86,
            3.698,
            -76.42,
            31.86,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_6_2",
        "polygon": [
            -76.42,
            31.86,
            0.08,
            -76.563,
            31.481,
            0.08,
            -76.563,
            31.481,
            2.928,
            -76.563,
            31.481,
            2.928,
            -76.42,
            31.86,
            2.928,
            -76.42,
            31.86,
            0.08
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_6_3",
        "polygon": [
            -76.107,
            32.694,
            0.142,
            -76.406,
            31.899,
            0.142,
            -76.406,
            31.899,
            3.698,
            -76.406,
            31.899,
            3.698,
            -76.107,
            32.694,
            3.698,
            -76.107,
            32.694,
            0.142
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_8_1",
        "polygon": [
            -75.435,
            34.481,
            2.928,
            -75.578,
            34.102,
            2.928,
            -75.578,
            34.102,
            3.698,
            -75.578,
            34.102,
            3.698,
            -75.435,
            34.481,
            3.698,
            -75.435,
            34.481,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_8_2",
        "polygon": [
            -75.435,
            34.481,
            0.08,
            -75.578,
            34.102,
            0.08,
            -75.578,
            34.102,
            2.928,
            -75.578,
            34.102,
            2.928,
            -75.435,
            34.481,
            2.928,
            -75.435,
            34.481,
            0.08
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_8_3",
        "polygon": [
            -75.122,
            35.315,
            0.142,
            -75.42,
            34.52,
            0.142,
            -75.42,
            34.52,
            3.698,
            -75.42,
            34.52,
            3.698,
            -75.122,
            35.315,
            3.698,
            -75.122,
            35.315,
            0.142
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_10_1",
        "polygon": [
            -74.45,
            37.102,
            2.928,
            -74.592,
            36.723,
            2.928,
            -74.592,
            36.723,
            3.698,
            -74.592,
            36.723,
            3.698,
            -74.45,
            37.102,
            3.698,
            -74.45,
            37.102,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_10_2",
        "polygon": [
            -74.45,
            37.102,
            0.08,
            -74.592,
            36.723,
            0.08,
            -74.592,
            36.723,
            2.928,
            -74.592,
            36.723,
            2.928,
            -74.45,
            37.102,
            2.928,
            -74.45,
            37.102,
            0.08
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_10_3",
        "polygon": [
            -74.136,
            37.936,
            0.142,
            -74.435,
            37.141,
            0.142,
            -74.435,
            37.141,
            3.698,
            -74.435,
            37.141,
            3.698,
            -74.136,
            37.936,
            3.698,
            -74.136,
            37.936,
            0.142
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_14_1",
        "polygon": [
            -72.479,
            42.343,
            2.928,
            -72.622,
            41.964,
            2.928,
            -72.622,
            41.964,
            3.698,
            -72.622,
            41.964,
            3.698,
            -72.479,
            42.343,
            3.698,
            -72.479,
            42.343,
            2.928
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_14_2",
        "polygon": [
            -72.479,
            42.343,
            0.08,
            -72.622,
            41.964,
            0.08,
            -72.622,
            41.964,
            2.928,
            -72.622,
            41.964,
            2.928,
            -72.479,
            42.343,
            2.928,
            -72.479,
            42.343,
            0.08
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_14_3",
        "polygon": [
            -72.166,
            43.177,
            0.142,
            -72.464,
            42.383,
            0.142,
            -72.464,
            42.383,
            3.698,
            -72.464,
            42.383,
            3.698,
            -72.166,
            43.177,
            3.698,
            -72.166,
            43.177,
            0.142
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_16_1",
        "polygon": [
            -71.494,
            44.964,
            2.928,
            -71.636,
            44.585,
            2.928,
            -71.636,
            44.585,
            3.698,
            -71.636,
            44.585,
            3.698,
            -71.494,
            44.964,
            3.698,
            -71.494,
            44.964,
            2.928
        ],
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_16_2",
        "polygon": [
            -71.494,
            44.964,
            0.08,
            -71.636,
            44.585,
            0.08,
            -71.636,
            44.585,
            2.928,
            -71.636,
            44.585,
            2.928,
            -71.494,
            44.964,
            2.928,
            -71.494,
            44.964,
            0.08
        ],
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_EG_16_3",
        "polygon": [
            -71.18,
            45.798,
            0.142,
            -71.479,
            45.004,
            0.142,
            -71.479,
            45.004,
            3.698,
            -71.479,
            45.004,
            3.698,
            -71.18,
            45.798,
            3.698,
            -71.18,
            45.798,
            0.142
        ],
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_2_1",
        "polygon": [
            -78.078,
            27.452,
            6.988,
            -78.22,
            27.073,
            6.988,
            -78.22,
            27.073,
            7.408,
            -78.22,
            27.073,
            7.408,
            -78.078,
            27.452,
            7.408,
            -78.078,
            27.452,
            6.988
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_2_2",
        "polygon": [
            -78.078,
            27.452,
            4.14,
            -78.22,
            27.073,
            4.14,
            -78.22,
            27.073,
            6.988,
            -78.22,
            27.073,
            6.988,
            -78.078,
            27.452,
            6.988,
            -78.078,
            27.452,
            4.14
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_2_3",
        "polygon": [
            -78.235,
            27.034,
            4.202,
            -78.534,
            26.239,
            4.202,
            -78.534,
            26.239,
            7.408,
            -78.534,
            26.239,
            7.408,
            -78.235,
            27.034,
            7.408,
            -78.235,
            27.034,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_4_1",
        "polygon": [
            -77.25,
            29.655,
            4.202,
            -77.548,
            28.86,
            4.202,
            -77.548,
            28.86,
            7.408,
            -77.548,
            28.86,
            7.408,
            -77.25,
            29.655,
            7.408,
            -77.25,
            29.655,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_4_2",
        "polygon": [
            -77.092,
            30.073,
            6.988,
            -77.235,
            29.694,
            6.988,
            -77.235,
            29.694,
            7.408,
            -77.235,
            29.694,
            7.408,
            -77.092,
            30.073,
            7.408,
            -77.092,
            30.073,
            6.988
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_4_3",
        "polygon": [
            -77.092,
            30.073,
            4.14,
            -77.235,
            29.694,
            4.14,
            -77.235,
            29.694,
            6.988,
            -77.235,
            29.694,
            6.988,
            -77.092,
            30.073,
            6.988,
            -77.092,
            30.073,
            4.14
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_6_1",
        "polygon": [
            -76.264,
            32.276,
            4.202,
            -76.563,
            31.481,
            4.202,
            -76.563,
            31.481,
            7.408,
            -76.563,
            31.481,
            7.408,
            -76.264,
            32.276,
            7.408,
            -76.264,
            32.276,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_6_2",
        "polygon": [
            -76.107,
            32.694,
            6.988,
            -76.249,
            32.315,
            6.988,
            -76.249,
            32.315,
            7.408,
            -76.249,
            32.315,
            7.408,
            -76.107,
            32.694,
            7.408,
            -76.107,
            32.694,
            6.988
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_6_3",
        "polygon": [
            -76.107,
            32.694,
            4.14,
            -76.249,
            32.315,
            4.14,
            -76.249,
            32.315,
            6.988,
            -76.249,
            32.315,
            6.988,
            -76.107,
            32.694,
            6.988,
            -76.107,
            32.694,
            4.14
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_8_1",
        "polygon": [
            -75.279,
            34.896,
            4.202,
            -75.578,
            34.102,
            4.202,
            -75.578,
            34.102,
            7.408,
            -75.578,
            34.102,
            7.408,
            -75.279,
            34.896,
            7.408,
            -75.279,
            34.896,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_8_2",
        "polygon": [
            -75.122,
            35.315,
            6.988,
            -75.264,
            34.936,
            6.988,
            -75.264,
            34.936,
            7.408,
            -75.264,
            34.936,
            7.408,
            -75.122,
            35.315,
            7.408,
            -75.122,
            35.315,
            6.988
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_8_3",
        "polygon": [
            -75.122,
            35.315,
            4.14,
            -75.264,
            34.936,
            4.14,
            -75.264,
            34.936,
            6.988,
            -75.264,
            34.936,
            6.988,
            -75.122,
            35.315,
            6.988,
            -75.122,
            35.315,
            4.14
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_10_1",
        "polygon": [
            -74.294,
            37.517,
            4.202,
            -74.592,
            36.723,
            4.202,
            -74.592,
            36.723,
            7.408,
            -74.592,
            36.723,
            7.408,
            -74.294,
            37.517,
            7.408,
            -74.294,
            37.517,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_10_2",
        "polygon": [
            -74.136,
            37.936,
            6.988,
            -74.279,
            37.557,
            6.988,
            -74.279,
            37.557,
            7.408,
            -74.279,
            37.557,
            7.408,
            -74.136,
            37.936,
            7.408,
            -74.136,
            37.936,
            6.988
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_10_3",
        "polygon": [
            -74.136,
            37.936,
            4.14,
            -74.279,
            37.557,
            4.14,
            -74.279,
            37.557,
            6.988,
            -74.279,
            37.557,
            6.988,
            -74.136,
            37.936,
            6.988,
            -74.136,
            37.936,
            4.14
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_12_1",
        "polygon": [
            -73.308,
            40.138,
            4.202,
            -73.607,
            39.343,
            4.202,
            -73.607,
            39.343,
            7.408,
            -73.607,
            39.343,
            7.408,
            -73.308,
            40.138,
            7.408,
            -73.308,
            40.138,
            4.202
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_12_2",
        "polygon": [
            -73.151,
            40.557,
            6.988,
            -73.293,
            40.178,
            6.988,
            -73.293,
            40.178,
            7.408,
            -73.293,
            40.178,
            7.408,
            -73.151,
            40.557,
            7.408,
            -73.151,
            40.557,
            6.988
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_12_3",
        "polygon": [
            -73.151,
            40.557,
            4.14,
            -73.293,
            40.178,
            4.14,
            -73.293,
            40.178,
            6.988,
            -73.293,
            40.178,
            6.988,
            -73.151,
            40.557,
            6.988,
            -73.151,
            40.557,
            4.14
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_14_1",
        "polygon": [
            -72.323,
            42.759,
            4.202,
            -72.622,
            41.964,
            4.202,
            -72.622,
            41.964,
            7.408,
            -72.622,
            41.964,
            7.408,
            -72.323,
            42.759,
            7.408,
            -72.323,
            42.759,
            4.202
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_14_2",
        "polygon": [
            -72.166,
            43.177,
            6.988,
            -72.308,
            42.798,
            6.988,
            -72.308,
            42.798,
            7.408,
            -72.308,
            42.798,
            7.408,
            -72.166,
            43.177,
            7.408,
            -72.166,
            43.177,
            6.988
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_14_3",
        "polygon": [
            -72.166,
            43.177,
            4.14,
            -72.308,
            42.798,
            4.14,
            -72.308,
            42.798,
            6.988,
            -72.308,
            42.798,
            6.988,
            -72.166,
            43.177,
            6.988,
            -72.166,
            43.177,
            4.14
        ],
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_16_1",
        "polygon": [
            -71.337,
            45.38,
            4.202,
            -71.636,
            44.585,
            4.202,
            -71.636,
            44.585,
            7.408,
            -71.636,
            44.585,
            7.408,
            -71.337,
            45.38,
            7.408,
            -71.337,
            45.38,
            4.202
        ],
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_16_2",
        "polygon": [
            -71.18,
            45.798,
            6.988,
            -71.323,
            45.419,
            6.988,
            -71.323,
            45.419,
            7.408,
            -71.323,
            45.419,
            7.408,
            -71.18,
            45.798,
            7.408,
            -71.18,
            45.798,
            6.988
        ],
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_1OG_16_3",
        "polygon": [
            -71.18,
            45.798,
            4.14,
            -71.323,
            45.419,
            4.14,
            -71.323,
            45.419,
            6.988,
            -71.323,
            45.419,
            6.988,
            -71.18,
            45.798,
            6.988,
            -71.18,
            45.798,
            4.14
        ],
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_2_1",
        "polygon": [
            -78.078,
            27.452,
            14.408,
            -78.22,
            27.073,
            14.408,
            -78.22,
            27.073,
            14.828,
            -78.22,
            27.073,
            14.828,
            -78.078,
            27.452,
            14.828,
            -78.078,
            27.452,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_2_2",
        "polygon": [
            -78.078,
            27.452,
            11.56,
            -78.22,
            27.073,
            11.56,
            -78.22,
            27.073,
            14.408,
            -78.22,
            27.073,
            14.408,
            -78.078,
            27.452,
            14.408,
            -78.078,
            27.452,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_2_3",
        "polygon": [
            -78.235,
            27.034,
            11.622,
            -78.534,
            26.239,
            11.622,
            -78.534,
            26.239,
            14.828,
            -78.534,
            26.239,
            14.828,
            -78.235,
            27.034,
            14.828,
            -78.235,
            27.034,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_4_1",
        "polygon": [
            -77.25,
            29.655,
            11.622,
            -77.548,
            28.86,
            11.622,
            -77.548,
            28.86,
            14.828,
            -77.548,
            28.86,
            14.828,
            -77.25,
            29.655,
            14.828,
            -77.25,
            29.655,
            11.622
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_4_2",
        "polygon": [
            -77.092,
            30.073,
            14.408,
            -77.235,
            29.694,
            14.408,
            -77.235,
            29.694,
            14.828,
            -77.235,
            29.694,
            14.828,
            -77.092,
            30.073,
            14.828,
            -77.092,
            30.073,
            14.408
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_4_3",
        "polygon": [
            -77.092,
            30.073,
            11.56,
            -77.235,
            29.694,
            11.56,
            -77.235,
            29.694,
            14.408,
            -77.235,
            29.694,
            14.408,
            -77.092,
            30.073,
            14.408,
            -77.092,
            30.073,
            11.56
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_6_1",
        "polygon": [
            -76.264,
            32.276,
            11.622,
            -76.563,
            31.481,
            11.622,
            -76.563,
            31.481,
            14.828,
            -76.563,
            31.481,
            14.828,
            -76.264,
            32.276,
            14.828,
            -76.264,
            32.276,
            11.622
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_6_2",
        "polygon": [
            -76.107,
            32.694,
            14.408,
            -76.249,
            32.315,
            14.408,
            -76.249,
            32.315,
            14.828,
            -76.249,
            32.315,
            14.828,
            -76.107,
            32.694,
            14.828,
            -76.107,
            32.694,
            14.408
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_6_3",
        "polygon": [
            -76.107,
            32.694,
            11.56,
            -76.249,
            32.315,
            11.56,
            -76.249,
            32.315,
            14.408,
            -76.249,
            32.315,
            14.408,
            -76.107,
            32.694,
            14.408,
            -76.107,
            32.694,
            11.56
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_8_1",
        "polygon": [
            -75.279,
            34.896,
            11.622,
            -75.578,
            34.102,
            11.622,
            -75.578,
            34.102,
            14.828,
            -75.578,
            34.102,
            14.828,
            -75.279,
            34.896,
            14.828,
            -75.279,
            34.896,
            11.622
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_8_2",
        "polygon": [
            -75.122,
            35.315,
            14.408,
            -75.264,
            34.936,
            14.408,
            -75.264,
            34.936,
            14.828,
            -75.264,
            34.936,
            14.828,
            -75.122,
            35.315,
            14.828,
            -75.122,
            35.315,
            14.408
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_8_3",
        "polygon": [
            -75.122,
            35.315,
            11.56,
            -75.264,
            34.936,
            11.56,
            -75.264,
            34.936,
            14.408,
            -75.264,
            34.936,
            14.408,
            -75.122,
            35.315,
            14.408,
            -75.122,
            35.315,
            11.56
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_10_1",
        "polygon": [
            -74.294,
            37.517,
            11.622,
            -74.592,
            36.723,
            11.622,
            -74.592,
            36.723,
            14.828,
            -74.592,
            36.723,
            14.828,
            -74.294,
            37.517,
            14.828,
            -74.294,
            37.517,
            11.622
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_10_2",
        "polygon": [
            -74.136,
            37.936,
            14.408,
            -74.279,
            37.557,
            14.408,
            -74.279,
            37.557,
            14.828,
            -74.279,
            37.557,
            14.828,
            -74.136,
            37.936,
            14.828,
            -74.136,
            37.936,
            14.408
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_10_3",
        "polygon": [
            -74.136,
            37.936,
            11.56,
            -74.279,
            37.557,
            11.56,
            -74.279,
            37.557,
            14.408,
            -74.279,
            37.557,
            14.408,
            -74.136,
            37.936,
            14.408,
            -74.136,
            37.936,
            11.56
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_12_1",
        "polygon": [
            -73.308,
            40.138,
            11.622,
            -73.607,
            39.343,
            11.622,
            -73.607,
            39.343,
            14.828,
            -73.607,
            39.343,
            14.828,
            -73.308,
            40.138,
            14.828,
            -73.308,
            40.138,
            11.622
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_12_2",
        "polygon": [
            -73.151,
            40.557,
            14.408,
            -73.293,
            40.178,
            14.408,
            -73.293,
            40.178,
            14.828,
            -73.293,
            40.178,
            14.828,
            -73.151,
            40.557,
            14.828,
            -73.151,
            40.557,
            14.408
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_12_3",
        "polygon": [
            -73.151,
            40.557,
            11.56,
            -73.293,
            40.178,
            11.56,
            -73.293,
            40.178,
            14.408,
            -73.293,
            40.178,
            14.408,
            -73.151,
            40.557,
            14.408,
            -73.151,
            40.557,
            11.56
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_14_1",
        "polygon": [
            -72.323,
            42.759,
            11.622,
            -72.622,
            41.964,
            11.622,
            -72.622,
            41.964,
            14.828,
            -72.622,
            41.964,
            14.828,
            -72.323,
            42.759,
            14.828,
            -72.323,
            42.759,
            11.622
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_14_2",
        "polygon": [
            -72.166,
            43.177,
            14.408,
            -72.308,
            42.798,
            14.408,
            -72.308,
            42.798,
            14.828,
            -72.308,
            42.798,
            14.828,
            -72.166,
            43.177,
            14.828,
            -72.166,
            43.177,
            14.408
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_14_3",
        "polygon": [
            -72.166,
            43.177,
            11.56,
            -72.308,
            42.798,
            11.56,
            -72.308,
            42.798,
            14.408,
            -72.308,
            42.798,
            14.408,
            -72.166,
            43.177,
            14.408,
            -72.166,
            43.177,
            11.56
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_16_1",
        "polygon": [
            -71.337,
            45.38,
            11.622,
            -71.636,
            44.585,
            11.622,
            -71.636,
            44.585,
            14.828,
            -71.636,
            44.585,
            14.828,
            -71.337,
            45.38,
            14.828,
            -71.337,
            45.38,
            11.622
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_16_2",
        "polygon": [
            -71.18,
            45.798,
            14.408,
            -71.323,
            45.419,
            14.408,
            -71.323,
            45.419,
            14.828,
            -71.323,
            45.419,
            14.828,
            -71.18,
            45.798,
            14.828,
            -71.18,
            45.798,
            14.408
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_3OG_16_3",
        "polygon": [
            -71.18,
            45.798,
            11.56,
            -71.323,
            45.419,
            11.56,
            -71.323,
            45.419,
            14.408,
            -71.323,
            45.419,
            14.408,
            -71.18,
            45.798,
            14.408,
            -71.18,
            45.798,
            11.56
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_14_1",
        "polygon": [
            -72.166,
            43.177,
            7.912,
            -72.464,
            42.383,
            7.912,
            -72.464,
            42.383,
            11.118,
            -72.464,
            42.383,
            11.118,
            -72.166,
            43.177,
            11.118,
            -72.166,
            43.177,
            7.912
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_14_2",
        "polygon": [
            -72.479,
            42.343,
            10.698,
            -72.622,
            41.964,
            10.698,
            -72.622,
            41.964,
            11.118,
            -72.622,
            41.964,
            11.118,
            -72.479,
            42.343,
            11.118,
            -72.479,
            42.343,
            10.698
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_14_3",
        "polygon": [
            -72.479,
            42.343,
            7.85,
            -72.622,
            41.964,
            7.85,
            -72.622,
            41.964,
            10.698,
            -72.622,
            41.964,
            10.698,
            -72.479,
            42.343,
            10.698,
            -72.479,
            42.343,
            7.85
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_16_1",
        "polygon": [
            -71.494,
            44.964,
            10.698,
            -71.636,
            44.585,
            10.698,
            -71.636,
            44.585,
            11.118,
            -71.636,
            44.585,
            11.118,
            -71.494,
            44.964,
            11.118,
            -71.494,
            44.964,
            10.698
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_16_2",
        "polygon": [
            -71.494,
            44.964,
            7.85,
            -71.636,
            44.585,
            7.85,
            -71.636,
            44.585,
            10.698,
            -71.636,
            44.585,
            10.698,
            -71.494,
            44.964,
            10.698,
            -71.494,
            44.964,
            7.85
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_16_3",
        "polygon": [
            -71.18,
            45.798,
            7.912,
            -71.479,
            45.004,
            7.912,
            -71.479,
            45.004,
            11.118,
            -71.479,
            45.004,
            11.118,
            -71.18,
            45.798,
            11.118,
            -71.18,
            45.798,
            7.912
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_12_1",
        "polygon": [
            -73.151,
            40.557,
            7.912,
            -73.45,
            39.762,
            7.912,
            -73.45,
            39.762,
            11.118,
            -73.45,
            39.762,
            11.118,
            -73.151,
            40.557,
            11.118,
            -73.151,
            40.557,
            7.912
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_12_2",
        "polygon": [
            -73.464,
            39.723,
            10.698,
            -73.607,
            39.343,
            10.698,
            -73.607,
            39.343,
            11.118,
            -73.607,
            39.343,
            11.118,
            -73.464,
            39.723,
            11.118,
            -73.464,
            39.723,
            10.698
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_12_3",
        "polygon": [
            -73.464,
            39.723,
            7.85,
            -73.607,
            39.343,
            7.85,
            -73.607,
            39.343,
            10.698,
            -73.607,
            39.343,
            10.698,
            -73.464,
            39.723,
            10.698,
            -73.464,
            39.723,
            7.85
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_10_1",
        "polygon": [
            -74.136,
            37.936,
            7.912,
            -74.435,
            37.141,
            7.912,
            -74.435,
            37.141,
            11.118,
            -74.435,
            37.141,
            11.118,
            -74.136,
            37.936,
            11.118,
            -74.136,
            37.936,
            7.912
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_10_2",
        "polygon": [
            -74.45,
            37.102,
            10.698,
            -74.592,
            36.723,
            10.698,
            -74.592,
            36.723,
            11.118,
            -74.592,
            36.723,
            11.118,
            -74.45,
            37.102,
            11.118,
            -74.45,
            37.102,
            10.698
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_10_3",
        "polygon": [
            -74.45,
            37.102,
            7.85,
            -74.592,
            36.723,
            7.85,
            -74.592,
            36.723,
            10.698,
            -74.592,
            36.723,
            10.698,
            -74.45,
            37.102,
            10.698,
            -74.45,
            37.102,
            7.85
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_8_1",
        "polygon": [
            -75.122,
            35.315,
            7.912,
            -75.42,
            34.52,
            7.912,
            -75.42,
            34.52,
            11.118,
            -75.42,
            34.52,
            11.118,
            -75.122,
            35.315,
            11.118,
            -75.122,
            35.315,
            7.912
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_8_2",
        "polygon": [
            -75.435,
            34.481,
            10.698,
            -75.578,
            34.102,
            10.698,
            -75.578,
            34.102,
            11.118,
            -75.578,
            34.102,
            11.118,
            -75.435,
            34.481,
            11.118,
            -75.435,
            34.481,
            10.698
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_8_3",
        "polygon": [
            -75.435,
            34.481,
            7.85,
            -75.578,
            34.102,
            7.85,
            -75.578,
            34.102,
            10.698,
            -75.578,
            34.102,
            10.698,
            -75.435,
            34.481,
            10.698,
            -75.435,
            34.481,
            7.85
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_6_1",
        "polygon": [
            -76.107,
            32.694,
            7.912,
            -76.406,
            31.899,
            7.912,
            -76.406,
            31.899,
            11.118,
            -76.406,
            31.899,
            11.118,
            -76.107,
            32.694,
            11.118,
            -76.107,
            32.694,
            7.912
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_6_2",
        "polygon": [
            -76.42,
            31.86,
            10.698,
            -76.563,
            31.481,
            10.698,
            -76.563,
            31.481,
            11.118,
            -76.563,
            31.481,
            11.118,
            -76.42,
            31.86,
            11.118,
            -76.42,
            31.86,
            10.698
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_6_3",
        "polygon": [
            -76.42,
            31.86,
            7.85,
            -76.563,
            31.481,
            7.85,
            -76.563,
            31.481,
            10.698,
            -76.563,
            31.481,
            10.698,
            -76.42,
            31.86,
            10.698,
            -76.42,
            31.86,
            7.85
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_4_1",
        "polygon": [
            -77.092,
            30.073,
            7.912,
            -77.391,
            29.278,
            7.912,
            -77.391,
            29.278,
            11.118,
            -77.391,
            29.278,
            11.118,
            -77.092,
            30.073,
            11.118,
            -77.092,
            30.073,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_4_2",
        "polygon": [
            -77.406,
            29.239,
            10.698,
            -77.548,
            28.86,
            10.698,
            -77.548,
            28.86,
            11.118,
            -77.548,
            28.86,
            11.118,
            -77.406,
            29.239,
            11.118,
            -77.406,
            29.239,
            10.698
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_4_3",
        "polygon": [
            -77.406,
            29.239,
            7.85,
            -77.548,
            28.86,
            7.85,
            -77.548,
            28.86,
            10.698,
            -77.548,
            28.86,
            10.698,
            -77.406,
            29.239,
            10.698,
            -77.406,
            29.239,
            7.85
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_2_1",
        "polygon": [
            -78.078,
            27.452,
            7.912,
            -78.376,
            26.657,
            7.912,
            -78.376,
            26.657,
            11.118,
            -78.376,
            26.657,
            11.118,
            -78.078,
            27.452,
            11.118,
            -78.078,
            27.452,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_2_2",
        "polygon": [
            -78.391,
            26.618,
            10.698,
            -78.534,
            26.239,
            10.698,
            -78.534,
            26.239,
            11.118,
            -78.534,
            26.239,
            11.118,
            -78.391,
            26.618,
            11.118,
            -78.391,
            26.618,
            10.698
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.3-Ax9 E_J_2OG_2_3",
        "polygon": [
            -78.391,
            26.618,
            7.85,
            -78.534,
            26.239,
            7.85,
            -78.534,
            26.239,
            10.698,
            -78.534,
            26.239,
            10.698,
            -78.391,
            26.618,
            10.698,
            -78.391,
            26.618,
            7.85
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_16_1",
        "polygon": [
            -59.008,
            18.898,
            0.142,
            -58.709,
            19.693,
            0.142,
            -58.709,
            19.693,
            3.698,
            -58.709,
            19.693,
            3.698,
            -59.008,
            18.898,
            3.698,
            -59.008,
            18.898,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_16_2",
        "polygon": [
            -58.694,
            19.732,
            0.08,
            -58.552,
            20.111,
            0.08,
            -58.552,
            20.111,
            2.928,
            -58.552,
            20.111,
            2.928,
            -58.694,
            19.732,
            2.928,
            -58.694,
            19.732,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_16_3",
        "polygon": [
            -58.694,
            19.732,
            2.928,
            -58.552,
            20.111,
            2.928,
            -58.552,
            20.111,
            3.698,
            -58.552,
            20.111,
            3.698,
            -58.694,
            19.732,
            3.698,
            -58.694,
            19.732,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_14_1",
        "polygon": [
            -58.023,
            21.519,
            0.142,
            -57.724,
            22.314,
            0.142,
            -57.724,
            22.314,
            3.698,
            -57.724,
            22.314,
            3.698,
            -58.023,
            21.519,
            3.698,
            -58.023,
            21.519,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_14_2",
        "polygon": [
            -57.709,
            22.353,
            0.08,
            -57.567,
            22.732,
            0.08,
            -57.567,
            22.732,
            2.928,
            -57.567,
            22.732,
            2.928,
            -57.709,
            22.353,
            2.928,
            -57.709,
            22.353,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_14_3",
        "polygon": [
            -57.709,
            22.353,
            2.928,
            -57.567,
            22.732,
            2.928,
            -57.567,
            22.732,
            3.698,
            -57.567,
            22.732,
            3.698,
            -57.709,
            22.353,
            3.698,
            -57.709,
            22.353,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_12_1",
        "polygon": [
            -57.037,
            24.14,
            0.142,
            -56.739,
            24.935,
            0.142,
            -56.739,
            24.935,
            3.698,
            -56.739,
            24.935,
            3.698,
            -57.037,
            24.14,
            3.698,
            -57.037,
            24.14,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_12_2",
        "polygon": [
            -56.724,
            24.974,
            0.08,
            -56.581,
            25.353,
            0.08,
            -56.581,
            25.353,
            2.928,
            -56.581,
            25.353,
            2.928,
            -56.724,
            24.974,
            2.928,
            -56.724,
            24.974,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_12_3",
        "polygon": [
            -56.724,
            24.974,
            2.928,
            -56.581,
            25.353,
            2.928,
            -56.581,
            25.353,
            3.698,
            -56.581,
            25.353,
            3.698,
            -56.724,
            24.974,
            3.698,
            -56.724,
            24.974,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_10_1",
        "polygon": [
            -56.052,
            26.761,
            0.142,
            -55.753,
            27.556,
            0.142,
            -55.753,
            27.556,
            3.698,
            -55.753,
            27.556,
            3.698,
            -56.052,
            26.761,
            3.698,
            -56.052,
            26.761,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_10_2",
        "polygon": [
            -55.738,
            27.595,
            0.08,
            -55.596,
            27.974,
            0.08,
            -55.596,
            27.974,
            2.928,
            -55.596,
            27.974,
            2.928,
            -55.738,
            27.595,
            2.928,
            -55.738,
            27.595,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_10_3",
        "polygon": [
            -55.738,
            27.595,
            2.928,
            -55.596,
            27.974,
            2.928,
            -55.596,
            27.974,
            3.698,
            -55.596,
            27.974,
            3.698,
            -55.738,
            27.595,
            3.698,
            -55.738,
            27.595,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_8_1",
        "polygon": [
            -55.067,
            29.382,
            0.142,
            -54.768,
            30.176,
            0.142,
            -54.768,
            30.176,
            3.698,
            -54.768,
            30.176,
            3.698,
            -55.067,
            29.382,
            3.698,
            -55.067,
            29.382,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_8_2",
        "polygon": [
            -54.753,
            30.216,
            0.08,
            -54.611,
            30.595,
            0.08,
            -54.611,
            30.595,
            2.928,
            -54.611,
            30.595,
            2.928,
            -54.753,
            30.216,
            2.928,
            -54.753,
            30.216,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_8_3",
        "polygon": [
            -54.753,
            30.216,
            2.928,
            -54.611,
            30.595,
            2.928,
            -54.611,
            30.595,
            3.698,
            -54.611,
            30.595,
            3.698,
            -54.753,
            30.216,
            3.698,
            -54.753,
            30.216,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_6_1",
        "polygon": [
            -54.081,
            32.003,
            0.142,
            -53.783,
            32.797,
            0.142,
            -53.783,
            32.797,
            3.698,
            -53.783,
            32.797,
            3.698,
            -54.081,
            32.003,
            3.698,
            -54.081,
            32.003,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_6_2",
        "polygon": [
            -53.768,
            32.837,
            0.08,
            -53.625,
            33.216,
            0.08,
            -53.625,
            33.216,
            2.928,
            -53.625,
            33.216,
            2.928,
            -53.768,
            32.837,
            2.928,
            -53.768,
            32.837,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_6_3",
        "polygon": [
            -53.768,
            32.837,
            2.928,
            -53.625,
            33.216,
            2.928,
            -53.625,
            33.216,
            3.698,
            -53.625,
            33.216,
            3.698,
            -53.768,
            32.837,
            3.698,
            -53.768,
            32.837,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_4_1",
        "polygon": [
            -53.096,
            34.624,
            0.142,
            -52.797,
            35.418,
            0.142,
            -52.797,
            35.418,
            3.698,
            -52.797,
            35.418,
            3.698,
            -53.096,
            34.624,
            3.698,
            -53.096,
            34.624,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_4_2",
        "polygon": [
            -52.782,
            35.458,
            0.08,
            -52.64,
            35.837,
            0.08,
            -52.64,
            35.837,
            2.928,
            -52.64,
            35.837,
            2.928,
            -52.782,
            35.458,
            2.928,
            -52.782,
            35.458,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_4_3",
        "polygon": [
            -52.782,
            35.458,
            2.928,
            -52.64,
            35.837,
            2.928,
            -52.64,
            35.837,
            3.698,
            -52.64,
            35.837,
            3.698,
            -52.782,
            35.458,
            3.698,
            -52.782,
            35.458,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_2_1",
        "polygon": [
            -52.111,
            37.244,
            0.142,
            -51.812,
            38.039,
            0.142,
            -51.812,
            38.039,
            3.698,
            -51.812,
            38.039,
            3.698,
            -52.111,
            37.244,
            3.698,
            -52.111,
            37.244,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_2_2",
        "polygon": [
            -51.797,
            38.078,
            0.08,
            -51.655,
            38.458,
            0.08,
            -51.655,
            38.458,
            2.928,
            -51.655,
            38.458,
            2.928,
            -51.797,
            38.078,
            2.928,
            -51.797,
            38.078,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_EG_2_3",
        "polygon": [
            -51.797,
            38.078,
            2.928,
            -51.655,
            38.458,
            2.928,
            -51.655,
            38.458,
            3.698,
            -51.655,
            38.458,
            3.698,
            -51.797,
            38.078,
            3.698,
            -51.797,
            38.078,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_16_1",
        "polygon": [
            -58.851,
            19.317,
            4.202,
            -58.552,
            20.111,
            4.202,
            -58.552,
            20.111,
            7.408,
            -58.552,
            20.111,
            7.408,
            -58.851,
            19.317,
            7.408,
            -58.851,
            19.317,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_16_2",
        "polygon": [
            -59.008,
            18.898,
            4.14,
            -58.866,
            19.277,
            4.14,
            -58.866,
            19.277,
            6.988,
            -58.866,
            19.277,
            6.988,
            -59.008,
            18.898,
            6.988,
            -59.008,
            18.898,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_16_3",
        "polygon": [
            -59.008,
            18.898,
            6.988,
            -58.866,
            19.277,
            6.988,
            -58.866,
            19.277,
            7.408,
            -58.866,
            19.277,
            7.408,
            -59.008,
            18.898,
            7.408,
            -59.008,
            18.898,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_14_1",
        "polygon": [
            -57.865,
            21.937,
            4.202,
            -57.567,
            22.732,
            4.202,
            -57.567,
            22.732,
            7.408,
            -57.567,
            22.732,
            7.408,
            -57.865,
            21.937,
            7.408,
            -57.865,
            21.937,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_14_2",
        "polygon": [
            -58.023,
            21.519,
            4.14,
            -57.88,
            21.898,
            4.14,
            -57.88,
            21.898,
            6.988,
            -57.88,
            21.898,
            6.988,
            -58.023,
            21.519,
            6.988,
            -58.023,
            21.519,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_14_3",
        "polygon": [
            -58.023,
            21.519,
            6.988,
            -57.88,
            21.898,
            6.988,
            -57.88,
            21.898,
            7.408,
            -57.88,
            21.898,
            7.408,
            -58.023,
            21.519,
            7.408,
            -58.023,
            21.519,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_12_1",
        "polygon": [
            -56.88,
            24.558,
            4.202,
            -56.581,
            25.353,
            4.202,
            -56.581,
            25.353,
            7.408,
            -56.581,
            25.353,
            7.408,
            -56.88,
            24.558,
            7.408,
            -56.88,
            24.558,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_12_2",
        "polygon": [
            -57.037,
            24.14,
            4.14,
            -56.895,
            24.519,
            4.14,
            -56.895,
            24.519,
            6.988,
            -56.895,
            24.519,
            6.988,
            -57.037,
            24.14,
            6.988,
            -57.037,
            24.14,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_12_3",
        "polygon": [
            -57.037,
            24.14,
            6.988,
            -56.895,
            24.519,
            6.988,
            -56.895,
            24.519,
            7.408,
            -56.895,
            24.519,
            7.408,
            -57.037,
            24.14,
            7.408,
            -57.037,
            24.14,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_10_1",
        "polygon": [
            -55.895,
            27.179,
            4.202,
            -55.596,
            27.974,
            4.202,
            -55.596,
            27.974,
            7.408,
            -55.596,
            27.974,
            7.408,
            -55.895,
            27.179,
            7.408,
            -55.895,
            27.179,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_10_2",
        "polygon": [
            -56.052,
            26.761,
            4.14,
            -55.909,
            27.14,
            4.14,
            -55.909,
            27.14,
            6.988,
            -55.909,
            27.14,
            6.988,
            -56.052,
            26.761,
            6.988,
            -56.052,
            26.761,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_10_3",
        "polygon": [
            -56.052,
            26.761,
            6.988,
            -55.909,
            27.14,
            6.988,
            -55.909,
            27.14,
            7.408,
            -55.909,
            27.14,
            7.408,
            -56.052,
            26.761,
            7.408,
            -56.052,
            26.761,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_8_1",
        "polygon": [
            -54.909,
            29.8,
            4.202,
            -54.611,
            30.595,
            4.202,
            -54.611,
            30.595,
            7.408,
            -54.611,
            30.595,
            7.408,
            -54.909,
            29.8,
            7.408,
            -54.909,
            29.8,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_8_2",
        "polygon": [
            -55.067,
            29.382,
            4.14,
            -54.924,
            29.761,
            4.14,
            -54.924,
            29.761,
            6.988,
            -54.924,
            29.761,
            6.988,
            -55.067,
            29.382,
            6.988,
            -55.067,
            29.382,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_8_3",
        "polygon": [
            -55.067,
            29.382,
            6.988,
            -54.924,
            29.761,
            6.988,
            -54.924,
            29.761,
            7.408,
            -54.924,
            29.761,
            7.408,
            -55.067,
            29.382,
            7.408,
            -55.067,
            29.382,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_6_1",
        "polygon": [
            -53.924,
            32.421,
            4.202,
            -53.625,
            33.216,
            4.202,
            -53.625,
            33.216,
            7.408,
            -53.625,
            33.216,
            7.408,
            -53.924,
            32.421,
            7.408,
            -53.924,
            32.421,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_6_2",
        "polygon": [
            -54.081,
            32.003,
            4.14,
            -53.939,
            32.382,
            4.14,
            -53.939,
            32.382,
            6.988,
            -53.939,
            32.382,
            6.988,
            -54.081,
            32.003,
            6.988,
            -54.081,
            32.003,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_6_3",
        "polygon": [
            -54.081,
            32.003,
            6.988,
            -53.939,
            32.382,
            6.988,
            -53.939,
            32.382,
            7.408,
            -53.939,
            32.382,
            7.408,
            -54.081,
            32.003,
            7.408,
            -54.081,
            32.003,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_4_1",
        "polygon": [
            -52.939,
            35.042,
            4.202,
            -52.64,
            35.837,
            4.202,
            -52.64,
            35.837,
            7.408,
            -52.64,
            35.837,
            7.408,
            -52.939,
            35.042,
            7.408,
            -52.939,
            35.042,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_4_2",
        "polygon": [
            -53.096,
            34.624,
            4.14,
            -52.953,
            35.003,
            4.14,
            -52.953,
            35.003,
            6.988,
            -52.953,
            35.003,
            6.988,
            -53.096,
            34.624,
            6.988,
            -53.096,
            34.624,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_4_3",
        "polygon": [
            -53.096,
            34.624,
            6.988,
            -52.953,
            35.003,
            6.988,
            -52.953,
            35.003,
            7.408,
            -52.953,
            35.003,
            7.408,
            -53.096,
            34.624,
            7.408,
            -53.096,
            34.624,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_2_1",
        "polygon": [
            -51.953,
            37.663,
            4.202,
            -51.655,
            38.458,
            4.202,
            -51.655,
            38.458,
            7.408,
            -51.655,
            38.458,
            7.408,
            -51.953,
            37.663,
            7.408,
            -51.953,
            37.663,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_2_2",
        "polygon": [
            -52.111,
            37.244,
            4.14,
            -51.968,
            37.624,
            4.14,
            -51.968,
            37.624,
            6.988,
            -51.968,
            37.624,
            6.988,
            -52.111,
            37.244,
            6.988,
            -52.111,
            37.244,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_1OG_2_3",
        "polygon": [
            -52.111,
            37.244,
            6.988,
            -51.968,
            37.624,
            6.988,
            -51.968,
            37.624,
            7.408,
            -51.968,
            37.624,
            7.408,
            -52.111,
            37.244,
            7.408,
            -52.111,
            37.244,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_16_1",
        "polygon": [
            -58.851,
            19.317,
            11.622,
            -58.552,
            20.111,
            11.622,
            -58.552,
            20.111,
            14.828,
            -58.552,
            20.111,
            14.828,
            -58.851,
            19.317,
            14.828,
            -58.851,
            19.317,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_16_2",
        "polygon": [
            -59.008,
            18.898,
            11.56,
            -58.866,
            19.277,
            11.56,
            -58.866,
            19.277,
            14.408,
            -58.866,
            19.277,
            14.408,
            -59.008,
            18.898,
            14.408,
            -59.008,
            18.898,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_16_3",
        "polygon": [
            -59.008,
            18.898,
            14.408,
            -58.866,
            19.277,
            14.408,
            -58.866,
            19.277,
            14.828,
            -58.866,
            19.277,
            14.828,
            -59.008,
            18.898,
            14.828,
            -59.008,
            18.898,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_14_1",
        "polygon": [
            -57.865,
            21.937,
            11.622,
            -57.567,
            22.732,
            11.622,
            -57.567,
            22.732,
            14.828,
            -57.567,
            22.732,
            14.828,
            -57.865,
            21.937,
            14.828,
            -57.865,
            21.937,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_14_2",
        "polygon": [
            -58.023,
            21.519,
            11.56,
            -57.88,
            21.898,
            11.56,
            -57.88,
            21.898,
            14.408,
            -57.88,
            21.898,
            14.408,
            -58.023,
            21.519,
            14.408,
            -58.023,
            21.519,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_14_3",
        "polygon": [
            -58.023,
            21.519,
            14.408,
            -57.88,
            21.898,
            14.408,
            -57.88,
            21.898,
            14.828,
            -57.88,
            21.898,
            14.828,
            -58.023,
            21.519,
            14.828,
            -58.023,
            21.519,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_12_1",
        "polygon": [
            -56.88,
            24.558,
            11.622,
            -56.581,
            25.353,
            11.622,
            -56.581,
            25.353,
            14.828,
            -56.581,
            25.353,
            14.828,
            -56.88,
            24.558,
            14.828,
            -56.88,
            24.558,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_12_2",
        "polygon": [
            -57.037,
            24.14,
            11.56,
            -56.895,
            24.519,
            11.56,
            -56.895,
            24.519,
            14.408,
            -56.895,
            24.519,
            14.408,
            -57.037,
            24.14,
            14.408,
            -57.037,
            24.14,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_12_3",
        "polygon": [
            -57.037,
            24.14,
            14.408,
            -56.895,
            24.519,
            14.408,
            -56.895,
            24.519,
            14.828,
            -56.895,
            24.519,
            14.828,
            -57.037,
            24.14,
            14.828,
            -57.037,
            24.14,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_10_1",
        "polygon": [
            -55.895,
            27.179,
            11.622,
            -55.596,
            27.974,
            11.622,
            -55.596,
            27.974,
            14.828,
            -55.596,
            27.974,
            14.828,
            -55.895,
            27.179,
            14.828,
            -55.895,
            27.179,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_10_2",
        "polygon": [
            -56.052,
            26.761,
            11.56,
            -55.909,
            27.14,
            11.56,
            -55.909,
            27.14,
            14.408,
            -55.909,
            27.14,
            14.408,
            -56.052,
            26.761,
            14.408,
            -56.052,
            26.761,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_10_3",
        "polygon": [
            -56.052,
            26.761,
            14.408,
            -55.909,
            27.14,
            14.408,
            -55.909,
            27.14,
            14.828,
            -55.909,
            27.14,
            14.828,
            -56.052,
            26.761,
            14.828,
            -56.052,
            26.761,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_8_1",
        "polygon": [
            -54.909,
            29.8,
            11.622,
            -54.611,
            30.595,
            11.622,
            -54.611,
            30.595,
            14.828,
            -54.611,
            30.595,
            14.828,
            -54.909,
            29.8,
            14.828,
            -54.909,
            29.8,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_8_2",
        "polygon": [
            -55.067,
            29.382,
            11.56,
            -54.924,
            29.761,
            11.56,
            -54.924,
            29.761,
            14.408,
            -54.924,
            29.761,
            14.408,
            -55.067,
            29.382,
            14.408,
            -55.067,
            29.382,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_8_3",
        "polygon": [
            -55.067,
            29.382,
            14.408,
            -54.924,
            29.761,
            14.408,
            -54.924,
            29.761,
            14.828,
            -54.924,
            29.761,
            14.828,
            -55.067,
            29.382,
            14.828,
            -55.067,
            29.382,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_6_1",
        "polygon": [
            -53.924,
            32.421,
            11.622,
            -53.625,
            33.216,
            11.622,
            -53.625,
            33.216,
            14.828,
            -53.625,
            33.216,
            14.828,
            -53.924,
            32.421,
            14.828,
            -53.924,
            32.421,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_6_2",
        "polygon": [
            -54.081,
            32.003,
            11.56,
            -53.939,
            32.382,
            11.56,
            -53.939,
            32.382,
            14.408,
            -53.939,
            32.382,
            14.408,
            -54.081,
            32.003,
            14.408,
            -54.081,
            32.003,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_6_3",
        "polygon": [
            -54.081,
            32.003,
            14.408,
            -53.939,
            32.382,
            14.408,
            -53.939,
            32.382,
            14.828,
            -53.939,
            32.382,
            14.828,
            -54.081,
            32.003,
            14.828,
            -54.081,
            32.003,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_4_1",
        "polygon": [
            -52.939,
            35.042,
            11.622,
            -52.64,
            35.837,
            11.622,
            -52.64,
            35.837,
            14.828,
            -52.64,
            35.837,
            14.828,
            -52.939,
            35.042,
            14.828,
            -52.939,
            35.042,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_4_2",
        "polygon": [
            -53.096,
            34.624,
            11.56,
            -52.953,
            35.003,
            11.56,
            -52.953,
            35.003,
            14.408,
            -52.953,
            35.003,
            14.408,
            -53.096,
            34.624,
            14.408,
            -53.096,
            34.624,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_4_3",
        "polygon": [
            -53.096,
            34.624,
            14.408,
            -52.953,
            35.003,
            14.408,
            -52.953,
            35.003,
            14.828,
            -52.953,
            35.003,
            14.828,
            -53.096,
            34.624,
            14.828,
            -53.096,
            34.624,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_2_1",
        "polygon": [
            -51.953,
            37.663,
            11.622,
            -51.655,
            38.458,
            11.622,
            -51.655,
            38.458,
            14.828,
            -51.655,
            38.458,
            14.828,
            -51.953,
            37.663,
            14.828,
            -51.953,
            37.663,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_2_2",
        "polygon": [
            -52.111,
            37.244,
            11.56,
            -51.968,
            37.624,
            11.56,
            -51.968,
            37.624,
            14.408,
            -51.968,
            37.624,
            14.408,
            -52.111,
            37.244,
            14.408,
            -52.111,
            37.244,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_3OG_2_3",
        "polygon": [
            -52.111,
            37.244,
            14.408,
            -51.968,
            37.624,
            14.408,
            -51.968,
            37.624,
            14.828,
            -51.968,
            37.624,
            14.828,
            -52.111,
            37.244,
            14.828,
            -52.111,
            37.244,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_16_1",
        "polygon": [
            -59.008,
            18.898,
            7.912,
            -58.709,
            19.693,
            7.912,
            -58.709,
            19.693,
            11.118,
            -58.709,
            19.693,
            11.118,
            -59.008,
            18.898,
            11.118,
            -59.008,
            18.898,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_16_2",
        "polygon": [
            -58.694,
            19.732,
            7.85,
            -58.552,
            20.111,
            7.85,
            -58.552,
            20.111,
            10.698,
            -58.552,
            20.111,
            10.698,
            -58.694,
            19.732,
            10.698,
            -58.694,
            19.732,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_16_3",
        "polygon": [
            -58.694,
            19.732,
            10.698,
            -58.552,
            20.111,
            10.698,
            -58.552,
            20.111,
            11.118,
            -58.552,
            20.111,
            11.118,
            -58.694,
            19.732,
            11.118,
            -58.694,
            19.732,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_14_1",
        "polygon": [
            -58.023,
            21.519,
            7.912,
            -57.724,
            22.314,
            7.912,
            -57.724,
            22.314,
            11.118,
            -57.724,
            22.314,
            11.118,
            -58.023,
            21.519,
            11.118,
            -58.023,
            21.519,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_14_2",
        "polygon": [
            -57.709,
            22.353,
            7.85,
            -57.567,
            22.732,
            7.85,
            -57.567,
            22.732,
            10.698,
            -57.567,
            22.732,
            10.698,
            -57.709,
            22.353,
            10.698,
            -57.709,
            22.353,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_14_3",
        "polygon": [
            -57.709,
            22.353,
            10.698,
            -57.567,
            22.732,
            10.698,
            -57.567,
            22.732,
            11.118,
            -57.567,
            22.732,
            11.118,
            -57.709,
            22.353,
            11.118,
            -57.709,
            22.353,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_12_1",
        "polygon": [
            -57.037,
            24.14,
            7.912,
            -56.739,
            24.935,
            7.912,
            -56.739,
            24.935,
            11.118,
            -56.739,
            24.935,
            11.118,
            -57.037,
            24.14,
            11.118,
            -57.037,
            24.14,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_12_2",
        "polygon": [
            -56.724,
            24.974,
            7.85,
            -56.581,
            25.353,
            7.85,
            -56.581,
            25.353,
            10.698,
            -56.581,
            25.353,
            10.698,
            -56.724,
            24.974,
            10.698,
            -56.724,
            24.974,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_12_3",
        "polygon": [
            -56.724,
            24.974,
            10.698,
            -56.581,
            25.353,
            10.698,
            -56.581,
            25.353,
            11.118,
            -56.581,
            25.353,
            11.118,
            -56.724,
            24.974,
            11.118,
            -56.724,
            24.974,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_10_1",
        "polygon": [
            -56.052,
            26.761,
            7.912,
            -55.753,
            27.556,
            7.912,
            -55.753,
            27.556,
            11.118,
            -55.753,
            27.556,
            11.118,
            -56.052,
            26.761,
            11.118,
            -56.052,
            26.761,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_10_2",
        "polygon": [
            -55.738,
            27.595,
            7.85,
            -55.596,
            27.974,
            7.85,
            -55.596,
            27.974,
            10.698,
            -55.596,
            27.974,
            10.698,
            -55.738,
            27.595,
            10.698,
            -55.738,
            27.595,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_10_3",
        "polygon": [
            -55.738,
            27.595,
            10.698,
            -55.596,
            27.974,
            10.698,
            -55.596,
            27.974,
            11.118,
            -55.596,
            27.974,
            11.118,
            -55.738,
            27.595,
            11.118,
            -55.738,
            27.595,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_8_1",
        "polygon": [
            -55.067,
            29.382,
            7.912,
            -54.768,
            30.176,
            7.912,
            -54.768,
            30.176,
            11.118,
            -54.768,
            30.176,
            11.118,
            -55.067,
            29.382,
            11.118,
            -55.067,
            29.382,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_8_2",
        "polygon": [
            -54.753,
            30.216,
            7.85,
            -54.611,
            30.595,
            7.85,
            -54.611,
            30.595,
            10.698,
            -54.611,
            30.595,
            10.698,
            -54.753,
            30.216,
            10.698,
            -54.753,
            30.216,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_8_3",
        "polygon": [
            -54.753,
            30.216,
            10.698,
            -54.611,
            30.595,
            10.698,
            -54.611,
            30.595,
            11.118,
            -54.611,
            30.595,
            11.118,
            -54.753,
            30.216,
            11.118,
            -54.753,
            30.216,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_6_1",
        "polygon": [
            -54.081,
            32.003,
            7.912,
            -53.783,
            32.797,
            7.912,
            -53.783,
            32.797,
            11.118,
            -53.783,
            32.797,
            11.118,
            -54.081,
            32.003,
            11.118,
            -54.081,
            32.003,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_6_2",
        "polygon": [
            -53.768,
            32.837,
            7.85,
            -53.625,
            33.216,
            7.85,
            -53.625,
            33.216,
            10.698,
            -53.625,
            33.216,
            10.698,
            -53.768,
            32.837,
            10.698,
            -53.768,
            32.837,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_6_3",
        "polygon": [
            -53.768,
            32.837,
            10.698,
            -53.625,
            33.216,
            10.698,
            -53.625,
            33.216,
            11.118,
            -53.625,
            33.216,
            11.118,
            -53.768,
            32.837,
            11.118,
            -53.768,
            32.837,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_4_1",
        "polygon": [
            -53.096,
            34.624,
            7.912,
            -52.797,
            35.418,
            7.912,
            -52.797,
            35.418,
            11.118,
            -52.797,
            35.418,
            11.118,
            -53.096,
            34.624,
            11.118,
            -53.096,
            34.624,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_4_2",
        "polygon": [
            -52.782,
            35.458,
            7.85,
            -52.64,
            35.837,
            7.85,
            -52.64,
            35.837,
            10.698,
            -52.64,
            35.837,
            10.698,
            -52.782,
            35.458,
            10.698,
            -52.782,
            35.458,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_4_3",
        "polygon": [
            -52.782,
            35.458,
            10.698,
            -52.64,
            35.837,
            10.698,
            -52.64,
            35.837,
            11.118,
            -52.64,
            35.837,
            11.118,
            -52.782,
            35.458,
            11.118,
            -52.782,
            35.458,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_2_1",
        "polygon": [
            -52.111,
            37.244,
            7.912,
            -51.812,
            38.039,
            7.912,
            -51.812,
            38.039,
            11.118,
            -51.812,
            38.039,
            11.118,
            -52.111,
            37.244,
            11.118,
            -52.111,
            37.244,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_2_2",
        "polygon": [
            -51.797,
            38.078,
            7.85,
            -51.655,
            38.458,
            7.85,
            -51.655,
            38.458,
            10.698,
            -51.655,
            38.458,
            10.698,
            -51.797,
            38.078,
            10.698,
            -51.797,
            38.078,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.4-Ax12 E_J_2OG_2_3",
        "polygon": [
            -51.797,
            38.078,
            10.698,
            -51.655,
            38.458,
            10.698,
            -51.655,
            38.458,
            11.118,
            -51.655,
            38.458,
            11.118,
            -51.797,
            38.078,
            11.118,
            -51.797,
            38.078,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_16_1",
        "polygon": [
            -94.413,
            87.437,
            0.142,
            -94.712,
            86.642,
            0.142,
            -94.712,
            86.642,
            3.698,
            -94.712,
            86.642,
            3.698,
            -94.413,
            87.437,
            3.698,
            -94.413,
            87.437,
            0.142
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_16_2",
        "polygon": [
            -94.727,
            86.603,
            0.08,
            -94.869,
            86.224,
            0.08,
            -94.869,
            86.224,
            2.928,
            -94.869,
            86.224,
            2.928,
            -94.727,
            86.603,
            2.928,
            -94.727,
            86.603,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_16_3",
        "polygon": [
            -94.727,
            86.603,
            2.928,
            -94.869,
            86.224,
            2.928,
            -94.869,
            86.224,
            3.698,
            -94.869,
            86.224,
            3.698,
            -94.727,
            86.603,
            3.698,
            -94.727,
            86.603,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_14_1",
        "polygon": [
            -95.398,
            84.817,
            0.142,
            -95.697,
            84.022,
            0.142,
            -95.697,
            84.022,
            3.698,
            -95.697,
            84.022,
            3.698,
            -95.398,
            84.817,
            3.698,
            -95.398,
            84.817,
            0.142
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_14_2",
        "polygon": [
            -95.712,
            83.983,
            0.08,
            -95.854,
            83.604,
            0.08,
            -95.854,
            83.604,
            2.928,
            -95.854,
            83.604,
            2.928,
            -95.712,
            83.983,
            2.928,
            -95.712,
            83.983,
            0.08
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_14_3",
        "polygon": [
            -95.712,
            83.983,
            2.928,
            -95.854,
            83.604,
            2.928,
            -95.854,
            83.604,
            3.698,
            -95.854,
            83.604,
            3.698,
            -95.712,
            83.983,
            3.698,
            -95.712,
            83.983,
            2.928
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_12_1",
        "polygon": [
            -96.384,
            82.196,
            0.142,
            -96.682,
            81.401,
            0.142,
            -96.682,
            81.401,
            3.698,
            -96.682,
            81.401,
            3.698,
            -96.384,
            82.196,
            3.698,
            -96.384,
            82.196,
            0.142
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_12_2",
        "polygon": [
            -96.697,
            81.362,
            0.08,
            -96.84,
            80.983,
            0.08,
            -96.84,
            80.983,
            2.928,
            -96.84,
            80.983,
            2.928,
            -96.697,
            81.362,
            2.928,
            -96.697,
            81.362,
            0.08
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_12_3",
        "polygon": [
            -96.697,
            81.362,
            2.928,
            -96.84,
            80.983,
            2.928,
            -96.84,
            80.983,
            3.698,
            -96.84,
            80.983,
            3.698,
            -96.697,
            81.362,
            3.698,
            -96.697,
            81.362,
            2.928
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_10_1",
        "polygon": [
            -97.369,
            79.575,
            0.142,
            -97.668,
            78.78,
            0.142,
            -97.668,
            78.78,
            3.698,
            -97.668,
            78.78,
            3.698,
            -97.369,
            79.575,
            3.698,
            -97.369,
            79.575,
            0.142
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_10_2",
        "polygon": [
            -97.683,
            78.741,
            0.08,
            -97.825,
            78.362,
            0.08,
            -97.825,
            78.362,
            2.928,
            -97.825,
            78.362,
            2.928,
            -97.683,
            78.741,
            2.928,
            -97.683,
            78.741,
            0.08
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_10_3",
        "polygon": [
            -97.683,
            78.741,
            2.928,
            -97.825,
            78.362,
            2.928,
            -97.825,
            78.362,
            3.698,
            -97.825,
            78.362,
            3.698,
            -97.683,
            78.741,
            3.698,
            -97.683,
            78.741,
            2.928
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_8_1",
        "polygon": [
            -98.354,
            76.954,
            0.142,
            -98.653,
            76.159,
            0.142,
            -98.653,
            76.159,
            3.698,
            -98.653,
            76.159,
            3.698,
            -98.354,
            76.954,
            3.698,
            -98.354,
            76.954,
            0.142
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_8_2",
        "polygon": [
            -98.668,
            76.12,
            0.08,
            -98.81,
            75.741,
            0.08,
            -98.81,
            75.741,
            2.928,
            -98.81,
            75.741,
            2.928,
            -98.668,
            76.12,
            2.928,
            -98.668,
            76.12,
            0.08
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_8_3",
        "polygon": [
            -98.668,
            76.12,
            2.928,
            -98.81,
            75.741,
            2.928,
            -98.81,
            75.741,
            3.698,
            -98.81,
            75.741,
            3.698,
            -98.668,
            76.12,
            3.698,
            -98.668,
            76.12,
            2.928
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_6_1",
        "polygon": [
            -99.34,
            74.333,
            0.142,
            -99.638,
            73.539,
            0.142,
            -99.638,
            73.539,
            3.698,
            -99.638,
            73.539,
            3.698,
            -99.34,
            74.333,
            3.698,
            -99.34,
            74.333,
            0.142
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_6_2",
        "polygon": [
            -99.653,
            73.499,
            0.08,
            -99.796,
            73.12,
            0.08,
            -99.796,
            73.12,
            2.928,
            -99.796,
            73.12,
            2.928,
            -99.653,
            73.499,
            2.928,
            -99.653,
            73.499,
            0.08
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_6_3",
        "polygon": [
            -99.653,
            73.499,
            2.928,
            -99.796,
            73.12,
            2.928,
            -99.796,
            73.12,
            3.698,
            -99.796,
            73.12,
            3.698,
            -99.653,
            73.499,
            3.698,
            -99.653,
            73.499,
            2.928
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_4_1",
        "polygon": [
            -100.325,
            71.712,
            0.142,
            -100.624,
            70.918,
            0.142,
            -100.624,
            70.918,
            3.698,
            -100.624,
            70.918,
            3.698,
            -100.325,
            71.712,
            3.698,
            -100.325,
            71.712,
            0.142
        ],
        "05-24 04:15": 1,
        "05-24 04:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_4_2",
        "polygon": [
            -100.639,
            70.878,
            0.08,
            -100.781,
            70.499,
            0.08,
            -100.781,
            70.499,
            2.928,
            -100.781,
            70.499,
            2.928,
            -100.639,
            70.878,
            2.928,
            -100.639,
            70.878,
            0.08
        ],
        "05-24 04:15": 1,
        "05-24 04:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_4_3",
        "polygon": [
            -100.639,
            70.878,
            2.928,
            -100.781,
            70.499,
            2.928,
            -100.781,
            70.499,
            3.698,
            -100.781,
            70.499,
            3.698,
            -100.639,
            70.878,
            3.698,
            -100.639,
            70.878,
            2.928
        ],
        "05-24 04:15": 1,
        "05-24 04:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_2_1",
        "polygon": [
            -101.31,
            69.092,
            0.06,
            -101.766,
            67.878,
            0.06,
            -101.766,
            67.878,
            2.869,
            -101.766,
            67.878,
            2.869,
            -101.31,
            69.092,
            2.869,
            -101.31,
            69.092,
            0.06
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_EG_2_2",
        "polygon": [
            -101.311,
            69.091,
            2.928,
            -101.767,
            67.878,
            2.928,
            -101.767,
            67.878,
            3.698,
            -101.767,
            67.878,
            3.698,
            -101.311,
            69.091,
            3.698,
            -101.311,
            69.091,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_16_1",
        "polygon": [
            -94.413,
            87.437,
            7.912,
            -94.712,
            86.642,
            7.912,
            -94.712,
            86.642,
            11.118,
            -94.712,
            86.642,
            11.118,
            -94.413,
            87.437,
            11.118,
            -94.413,
            87.437,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_16_2",
        "polygon": [
            -94.727,
            86.603,
            7.85,
            -94.869,
            86.224,
            7.85,
            -94.869,
            86.224,
            10.698,
            -94.869,
            86.224,
            10.698,
            -94.727,
            86.603,
            10.698,
            -94.727,
            86.603,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_16_3",
        "polygon": [
            -94.727,
            86.603,
            10.698,
            -94.869,
            86.224,
            10.698,
            -94.869,
            86.224,
            11.118,
            -94.869,
            86.224,
            11.118,
            -94.727,
            86.603,
            11.118,
            -94.727,
            86.603,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_14_1",
        "polygon": [
            -95.398,
            84.817,
            7.912,
            -95.697,
            84.022,
            7.912,
            -95.697,
            84.022,
            11.118,
            -95.697,
            84.022,
            11.118,
            -95.398,
            84.817,
            11.118,
            -95.398,
            84.817,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_14_2",
        "polygon": [
            -95.712,
            83.983,
            7.85,
            -95.854,
            83.604,
            7.85,
            -95.854,
            83.604,
            10.698,
            -95.854,
            83.604,
            10.698,
            -95.712,
            83.983,
            10.698,
            -95.712,
            83.983,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_14_3",
        "polygon": [
            -95.712,
            83.983,
            10.698,
            -95.854,
            83.604,
            10.698,
            -95.854,
            83.604,
            11.118,
            -95.854,
            83.604,
            11.118,
            -95.712,
            83.983,
            11.118,
            -95.712,
            83.983,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_12_1",
        "polygon": [
            -96.384,
            82.196,
            7.912,
            -96.682,
            81.401,
            7.912,
            -96.682,
            81.401,
            11.118,
            -96.682,
            81.401,
            11.118,
            -96.384,
            82.196,
            11.118,
            -96.384,
            82.196,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_12_2",
        "polygon": [
            -96.697,
            81.362,
            7.85,
            -96.84,
            80.983,
            7.85,
            -96.84,
            80.983,
            10.698,
            -96.84,
            80.983,
            10.698,
            -96.697,
            81.362,
            10.698,
            -96.697,
            81.362,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_12_3",
        "polygon": [
            -96.697,
            81.362,
            10.698,
            -96.84,
            80.983,
            10.698,
            -96.84,
            80.983,
            11.118,
            -96.84,
            80.983,
            11.118,
            -96.697,
            81.362,
            11.118,
            -96.697,
            81.362,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_10_1",
        "polygon": [
            -97.369,
            79.575,
            7.912,
            -97.668,
            78.78,
            7.912,
            -97.668,
            78.78,
            11.118,
            -97.668,
            78.78,
            11.118,
            -97.369,
            79.575,
            11.118,
            -97.369,
            79.575,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_10_2",
        "polygon": [
            -97.683,
            78.741,
            7.85,
            -97.825,
            78.362,
            7.85,
            -97.825,
            78.362,
            10.698,
            -97.825,
            78.362,
            10.698,
            -97.683,
            78.741,
            10.698,
            -97.683,
            78.741,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_10_3",
        "polygon": [
            -97.683,
            78.741,
            10.698,
            -97.825,
            78.362,
            10.698,
            -97.825,
            78.362,
            11.118,
            -97.825,
            78.362,
            11.118,
            -97.683,
            78.741,
            11.118,
            -97.683,
            78.741,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_8_1",
        "polygon": [
            -98.354,
            76.954,
            7.912,
            -98.653,
            76.159,
            7.912,
            -98.653,
            76.159,
            11.118,
            -98.653,
            76.159,
            11.118,
            -98.354,
            76.954,
            11.118,
            -98.354,
            76.954,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_8_2",
        "polygon": [
            -98.668,
            76.12,
            7.85,
            -98.81,
            75.741,
            7.85,
            -98.81,
            75.741,
            10.698,
            -98.81,
            75.741,
            10.698,
            -98.668,
            76.12,
            10.698,
            -98.668,
            76.12,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_8_3",
        "polygon": [
            -98.668,
            76.12,
            10.698,
            -98.81,
            75.741,
            10.698,
            -98.81,
            75.741,
            11.118,
            -98.81,
            75.741,
            11.118,
            -98.668,
            76.12,
            11.118,
            -98.668,
            76.12,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_6_1",
        "polygon": [
            -99.34,
            74.333,
            7.912,
            -99.638,
            73.539,
            7.912,
            -99.638,
            73.539,
            11.118,
            -99.638,
            73.539,
            11.118,
            -99.34,
            74.333,
            11.118,
            -99.34,
            74.333,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_6_2",
        "polygon": [
            -99.653,
            73.499,
            7.85,
            -99.796,
            73.12,
            7.85,
            -99.796,
            73.12,
            10.698,
            -99.796,
            73.12,
            10.698,
            -99.653,
            73.499,
            10.698,
            -99.653,
            73.499,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_6_3",
        "polygon": [
            -99.653,
            73.499,
            10.698,
            -99.796,
            73.12,
            10.698,
            -99.796,
            73.12,
            11.118,
            -99.796,
            73.12,
            11.118,
            -99.653,
            73.499,
            11.118,
            -99.653,
            73.499,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_4_1",
        "polygon": [
            -100.325,
            71.712,
            7.912,
            -100.624,
            70.918,
            7.912,
            -100.624,
            70.918,
            11.118,
            -100.624,
            70.918,
            11.118,
            -100.325,
            71.712,
            11.118,
            -100.325,
            71.712,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_4_2",
        "polygon": [
            -100.639,
            70.878,
            7.85,
            -100.781,
            70.499,
            7.85,
            -100.781,
            70.499,
            10.698,
            -100.781,
            70.499,
            10.698,
            -100.639,
            70.878,
            10.698,
            -100.639,
            70.878,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_4_3",
        "polygon": [
            -100.639,
            70.878,
            10.698,
            -100.781,
            70.499,
            10.698,
            -100.781,
            70.499,
            11.118,
            -100.781,
            70.499,
            11.118,
            -100.639,
            70.878,
            11.118,
            -100.639,
            70.878,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_2_1",
        "polygon": [
            -101.31,
            69.092,
            7.912,
            -101.609,
            68.297,
            7.912,
            -101.609,
            68.297,
            11.118,
            -101.609,
            68.297,
            11.118,
            -101.31,
            69.092,
            11.118,
            -101.31,
            69.092,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_2_2",
        "polygon": [
            -101.624,
            68.258,
            7.85,
            -101.766,
            67.878,
            7.85,
            -101.766,
            67.878,
            10.698,
            -101.766,
            67.878,
            10.698,
            -101.624,
            68.258,
            10.698,
            -101.624,
            68.258,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_2OG_2_3",
        "polygon": [
            -101.624,
            68.258,
            10.698,
            -101.766,
            67.878,
            10.698,
            -101.766,
            67.878,
            11.118,
            -101.766,
            67.878,
            11.118,
            -101.624,
            68.258,
            11.118,
            -101.624,
            68.258,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_16_1",
        "polygon": [
            -94.57,
            87.019,
            4.202,
            -94.869,
            86.225,
            4.202,
            -94.869,
            86.225,
            7.408,
            -94.869,
            86.225,
            7.408,
            -94.57,
            87.019,
            7.408,
            -94.57,
            87.019,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_16_2",
        "polygon": [
            -94.413,
            87.438,
            4.14,
            -94.555,
            87.059,
            4.14,
            -94.555,
            87.059,
            6.988,
            -94.555,
            87.059,
            6.988,
            -94.413,
            87.438,
            6.988,
            -94.413,
            87.438,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_16_3",
        "polygon": [
            -94.413,
            87.438,
            6.988,
            -94.555,
            87.059,
            6.988,
            -94.555,
            87.059,
            7.408,
            -94.555,
            87.059,
            7.408,
            -94.413,
            87.438,
            7.408,
            -94.413,
            87.438,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_14_1",
        "polygon": [
            -95.556,
            84.398,
            4.202,
            -95.854,
            83.604,
            4.202,
            -95.854,
            83.604,
            7.408,
            -95.854,
            83.604,
            7.408,
            -95.556,
            84.398,
            7.408,
            -95.556,
            84.398,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_14_2",
        "polygon": [
            -95.398,
            84.817,
            4.14,
            -95.541,
            84.438,
            4.14,
            -95.541,
            84.438,
            6.988,
            -95.541,
            84.438,
            6.988,
            -95.398,
            84.817,
            6.988,
            -95.398,
            84.817,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_14_3",
        "polygon": [
            -95.398,
            84.817,
            6.988,
            -95.541,
            84.438,
            6.988,
            -95.541,
            84.438,
            7.408,
            -95.541,
            84.438,
            7.408,
            -95.398,
            84.817,
            7.408,
            -95.398,
            84.817,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_12_1",
        "polygon": [
            -96.541,
            81.778,
            4.202,
            -96.84,
            80.983,
            4.202,
            -96.84,
            80.983,
            7.408,
            -96.84,
            80.983,
            7.408,
            -96.541,
            81.778,
            7.408,
            -96.541,
            81.778,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_12_2",
        "polygon": [
            -96.384,
            82.196,
            4.14,
            -96.526,
            81.817,
            4.14,
            -96.526,
            81.817,
            6.988,
            -96.526,
            81.817,
            6.988,
            -96.384,
            82.196,
            6.988,
            -96.384,
            82.196,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_12_3",
        "polygon": [
            -96.384,
            82.196,
            6.988,
            -96.526,
            81.817,
            6.988,
            -96.526,
            81.817,
            7.408,
            -96.526,
            81.817,
            7.408,
            -96.384,
            82.196,
            7.408,
            -96.384,
            82.196,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_10_1",
        "polygon": [
            -97.526,
            79.157,
            4.202,
            -97.825,
            78.362,
            4.202,
            -97.825,
            78.362,
            7.408,
            -97.825,
            78.362,
            7.408,
            -97.526,
            79.157,
            7.408,
            -97.526,
            79.157,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_10_2",
        "polygon": [
            -97.369,
            79.575,
            4.14,
            -97.512,
            79.196,
            4.14,
            -97.512,
            79.196,
            6.988,
            -97.512,
            79.196,
            6.988,
            -97.369,
            79.575,
            6.988,
            -97.369,
            79.575,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_10_3",
        "polygon": [
            -97.369,
            79.575,
            6.988,
            -97.512,
            79.196,
            6.988,
            -97.512,
            79.196,
            7.408,
            -97.512,
            79.196,
            7.408,
            -97.369,
            79.575,
            7.408,
            -97.369,
            79.575,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_8_1",
        "polygon": [
            -98.512,
            76.536,
            4.202,
            -98.81,
            75.741,
            4.202,
            -98.81,
            75.741,
            7.408,
            -98.81,
            75.741,
            7.408,
            -98.512,
            76.536,
            7.408,
            -98.512,
            76.536,
            4.202
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_8_2",
        "polygon": [
            -98.354,
            76.954,
            4.14,
            -98.497,
            76.575,
            4.14,
            -98.497,
            76.575,
            6.988,
            -98.497,
            76.575,
            6.988,
            -98.354,
            76.954,
            6.988,
            -98.354,
            76.954,
            4.14
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_8_3",
        "polygon": [
            -98.354,
            76.954,
            6.988,
            -98.497,
            76.575,
            6.988,
            -98.497,
            76.575,
            7.408,
            -98.497,
            76.575,
            7.408,
            -98.354,
            76.954,
            7.408,
            -98.354,
            76.954,
            6.988
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_6_1",
        "polygon": [
            -99.497,
            73.915,
            4.202,
            -99.796,
            73.12,
            4.202,
            -99.796,
            73.12,
            7.408,
            -99.796,
            73.12,
            7.408,
            -99.497,
            73.915,
            7.408,
            -99.497,
            73.915,
            4.202
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_6_2",
        "polygon": [
            -99.34,
            74.333,
            4.14,
            -99.482,
            73.954,
            4.14,
            -99.482,
            73.954,
            6.988,
            -99.482,
            73.954,
            6.988,
            -99.34,
            74.333,
            6.988,
            -99.34,
            74.333,
            4.14
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_6_3",
        "polygon": [
            -99.34,
            74.333,
            6.988,
            -99.482,
            73.954,
            6.988,
            -99.482,
            73.954,
            7.408,
            -99.482,
            73.954,
            7.408,
            -99.34,
            74.333,
            7.408,
            -99.34,
            74.333,
            6.988
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_4_1",
        "polygon": [
            -100.482,
            71.294,
            4.202,
            -100.781,
            70.499,
            4.202,
            -100.781,
            70.499,
            7.408,
            -100.781,
            70.499,
            7.408,
            -100.482,
            71.294,
            7.408,
            -100.482,
            71.294,
            4.202
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_4_2",
        "polygon": [
            -100.325,
            71.712,
            4.14,
            -100.468,
            71.333,
            4.14,
            -100.468,
            71.333,
            6.988,
            -100.468,
            71.333,
            6.988,
            -100.325,
            71.712,
            6.988,
            -100.325,
            71.712,
            4.14
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_4_3",
        "polygon": [
            -100.325,
            71.712,
            6.988,
            -100.468,
            71.333,
            6.988,
            -100.468,
            71.333,
            7.408,
            -100.468,
            71.333,
            7.408,
            -100.325,
            71.712,
            7.408,
            -100.325,
            71.712,
            6.988
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_2_1",
        "polygon": [
            -101.31,
            69.092,
            4.202,
            -101.609,
            68.297,
            4.202,
            -101.609,
            68.297,
            7.408,
            -101.609,
            68.297,
            7.408,
            -101.31,
            69.092,
            7.408,
            -101.31,
            69.092,
            4.202
        ],
        "05-24 04:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_2_2",
        "polygon": [
            -101.624,
            68.258,
            4.14,
            -101.766,
            67.878,
            4.14,
            -101.766,
            67.878,
            6.988,
            -101.766,
            67.878,
            6.988,
            -101.624,
            68.258,
            6.988,
            -101.624,
            68.258,
            4.14
        ],
        "05-24 04:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_1OG_2_3",
        "polygon": [
            -101.624,
            68.258,
            6.988,
            -101.766,
            67.878,
            6.988,
            -101.766,
            67.878,
            7.408,
            -101.766,
            67.878,
            7.408,
            -101.624,
            68.258,
            7.408,
            -101.624,
            68.258,
            6.988
        ],
        "05-24 04:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_4_1",
        "polygon": [
            -100.482,
            71.294,
            11.622,
            -100.781,
            70.499,
            11.622,
            -100.781,
            70.499,
            14.828,
            -100.781,
            70.499,
            14.828,
            -100.482,
            71.294,
            14.828,
            -100.482,
            71.294,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_4_2",
        "polygon": [
            -100.325,
            71.712,
            11.56,
            -100.468,
            71.333,
            11.56,
            -100.468,
            71.333,
            14.408,
            -100.468,
            71.333,
            14.408,
            -100.325,
            71.712,
            14.408,
            -100.325,
            71.712,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_4_3",
        "polygon": [
            -100.325,
            71.712,
            14.408,
            -100.468,
            71.333,
            14.408,
            -100.468,
            71.333,
            14.828,
            -100.468,
            71.333,
            14.828,
            -100.325,
            71.712,
            14.828,
            -100.325,
            71.712,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_2_1",
        "polygon": [
            -101.31,
            69.092,
            11.622,
            -101.609,
            68.297,
            11.622,
            -101.609,
            68.297,
            14.828,
            -101.609,
            68.297,
            14.828,
            -101.31,
            69.092,
            14.828,
            -101.31,
            69.092,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_2_2",
        "polygon": [
            -101.624,
            68.258,
            11.56,
            -101.766,
            67.878,
            11.56,
            -101.766,
            67.878,
            14.408,
            -101.766,
            67.878,
            14.408,
            -101.624,
            68.258,
            14.408,
            -101.624,
            68.258,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_2_3",
        "polygon": [
            -101.624,
            68.258,
            14.408,
            -101.766,
            67.878,
            14.408,
            -101.766,
            67.878,
            14.828,
            -101.766,
            67.878,
            14.828,
            -101.624,
            68.258,
            14.828,
            -101.624,
            68.258,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_6_1",
        "polygon": [
            -99.497,
            73.915,
            11.622,
            -99.796,
            73.12,
            11.622,
            -99.796,
            73.12,
            14.828,
            -99.796,
            73.12,
            14.828,
            -99.497,
            73.915,
            14.828,
            -99.497,
            73.915,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_6_2",
        "polygon": [
            -99.34,
            74.333,
            11.56,
            -99.482,
            73.954,
            11.56,
            -99.482,
            73.954,
            14.408,
            -99.482,
            73.954,
            14.408,
            -99.34,
            74.333,
            14.408,
            -99.34,
            74.333,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.13-Ax3B A_D2_3OG_6_3",
        "polygon": [
            -99.34,
            74.333,
            14.408,
            -99.482,
            73.954,
            14.408,
            -99.482,
            73.954,
            14.828,
            -99.482,
            73.954,
            14.828,
            -99.34,
            74.333,
            14.828,
            -99.34,
            74.333,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_14_1",
        "polygon": [
            -81.098,
            63.577,
            4.202,
            -80.799,
            64.372,
            4.202,
            -80.799,
            64.372,
            7.408,
            -80.799,
            64.372,
            7.408,
            -81.098,
            63.577,
            7.408,
            -81.098,
            63.577,
            4.202
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_14_2",
        "polygon": [
            -81.255,
            63.158,
            6.988,
            -81.113,
            63.538,
            6.988,
            -81.113,
            63.538,
            7.408,
            -81.113,
            63.538,
            7.408,
            -81.255,
            63.158,
            7.408,
            -81.255,
            63.158,
            6.988
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_14_3",
        "polygon": [
            -81.255,
            63.158,
            4.14,
            -81.113,
            63.538,
            4.14,
            -81.113,
            63.538,
            6.988,
            -81.113,
            63.538,
            6.988,
            -81.255,
            63.158,
            6.988,
            -81.255,
            63.158,
            4.14
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_14_1",
        "polygon": [
            -81.255,
            63.158,
            7.912,
            -80.957,
            63.953,
            7.912,
            -80.957,
            63.953,
            11.118,
            -80.957,
            63.953,
            11.118,
            -81.255,
            63.158,
            11.118,
            -81.255,
            63.158,
            7.912
        ],
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_14_2",
        "polygon": [
            -80.942,
            63.992,
            10.698,
            -80.799,
            64.372,
            10.698,
            -80.799,
            64.372,
            11.118,
            -80.799,
            64.372,
            11.118,
            -80.942,
            63.992,
            11.118,
            -80.942,
            63.992,
            10.698
        ],
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_14_3",
        "polygon": [
            -80.942,
            63.992,
            7.85,
            -80.799,
            64.372,
            7.85,
            -80.799,
            64.372,
            10.698,
            -80.799,
            64.372,
            10.698,
            -80.942,
            63.992,
            10.698,
            -80.942,
            63.992,
            7.85
        ],
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_14_1",
        "polygon": [
            -81.098,
            63.577,
            11.622,
            -80.799,
            64.372,
            11.622,
            -80.799,
            64.372,
            14.828,
            -80.799,
            64.372,
            14.828,
            -81.098,
            63.577,
            14.828,
            -81.098,
            63.577,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_14_2",
        "polygon": [
            -81.255,
            63.158,
            14.408,
            -81.113,
            63.538,
            14.408,
            -81.113,
            63.538,
            14.828,
            -81.113,
            63.538,
            14.828,
            -81.255,
            63.158,
            14.828,
            -81.255,
            63.158,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_14_3",
        "polygon": [
            -81.255,
            63.158,
            11.56,
            -81.113,
            63.538,
            11.56,
            -81.113,
            63.538,
            14.408,
            -81.113,
            63.538,
            14.408,
            -81.255,
            63.158,
            14.408,
            -81.255,
            63.158,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_12_1",
        "polygon": [
            -80.113,
            66.198,
            4.202,
            -79.814,
            66.992,
            4.202,
            -79.814,
            66.992,
            7.408,
            -79.814,
            66.992,
            7.408,
            -80.113,
            66.198,
            7.408,
            -80.113,
            66.198,
            4.202
        ],
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_12_2",
        "polygon": [
            -80.27,
            65.779,
            6.988,
            -80.128,
            66.158,
            6.988,
            -80.128,
            66.158,
            7.408,
            -80.128,
            66.158,
            7.408,
            -80.27,
            65.779,
            7.408,
            -80.27,
            65.779,
            6.988
        ],
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_12_3",
        "polygon": [
            -80.27,
            65.779,
            4.14,
            -80.128,
            66.158,
            4.14,
            -80.128,
            66.158,
            6.988,
            -80.128,
            66.158,
            6.988,
            -80.27,
            65.779,
            6.988,
            -80.27,
            65.779,
            4.14
        ],
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_12_1",
        "polygon": [
            -80.27,
            65.779,
            7.912,
            -79.971,
            66.574,
            7.912,
            -79.971,
            66.574,
            11.118,
            -79.971,
            66.574,
            11.118,
            -80.27,
            65.779,
            11.118,
            -80.27,
            65.779,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_12_2",
        "polygon": [
            -79.957,
            66.613,
            10.698,
            -79.814,
            66.992,
            10.698,
            -79.814,
            66.992,
            11.118,
            -79.814,
            66.992,
            11.118,
            -79.957,
            66.613,
            11.118,
            -79.957,
            66.613,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_12_3",
        "polygon": [
            -79.957,
            66.613,
            7.85,
            -79.814,
            66.992,
            7.85,
            -79.814,
            66.992,
            10.698,
            -79.814,
            66.992,
            10.698,
            -79.957,
            66.613,
            10.698,
            -79.957,
            66.613,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_12_1",
        "polygon": [
            -80.113,
            66.198,
            11.622,
            -79.814,
            66.992,
            11.622,
            -79.814,
            66.992,
            14.828,
            -79.814,
            66.992,
            14.828,
            -80.113,
            66.198,
            14.828,
            -80.113,
            66.198,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_12_2",
        "polygon": [
            -80.27,
            65.779,
            14.408,
            -80.128,
            66.158,
            14.408,
            -80.128,
            66.158,
            14.828,
            -80.128,
            66.158,
            14.828,
            -80.27,
            65.779,
            14.828,
            -80.27,
            65.779,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_12_3",
        "polygon": [
            -80.27,
            65.779,
            11.56,
            -80.128,
            66.158,
            11.56,
            -80.128,
            66.158,
            14.408,
            -80.128,
            66.158,
            14.408,
            -80.27,
            65.779,
            14.408,
            -80.27,
            65.779,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_10_1",
        "polygon": [
            -79.127,
            68.819,
            4.202,
            -78.829,
            69.613,
            4.202,
            -78.829,
            69.613,
            7.408,
            -78.829,
            69.613,
            7.408,
            -79.127,
            68.819,
            7.408,
            -79.127,
            68.819,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_10_2",
        "polygon": [
            -79.285,
            68.4,
            6.988,
            -79.142,
            68.779,
            6.988,
            -79.142,
            68.779,
            7.408,
            -79.142,
            68.779,
            7.408,
            -79.285,
            68.4,
            7.408,
            -79.285,
            68.4,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_10_3",
        "polygon": [
            -79.285,
            68.4,
            4.14,
            -79.142,
            68.779,
            4.14,
            -79.142,
            68.779,
            6.988,
            -79.142,
            68.779,
            6.988,
            -79.285,
            68.4,
            6.988,
            -79.285,
            68.4,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_10_1",
        "polygon": [
            -79.285,
            68.4,
            7.912,
            -78.986,
            69.195,
            7.912,
            -78.986,
            69.195,
            11.118,
            -78.986,
            69.195,
            11.118,
            -79.285,
            68.4,
            11.118,
            -79.285,
            68.4,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_10_2",
        "polygon": [
            -78.971,
            69.234,
            10.698,
            -78.829,
            69.613,
            10.698,
            -78.829,
            69.613,
            11.118,
            -78.829,
            69.613,
            11.118,
            -78.971,
            69.234,
            11.118,
            -78.971,
            69.234,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_10_3",
        "polygon": [
            -78.971,
            69.234,
            7.85,
            -78.829,
            69.613,
            7.85,
            -78.829,
            69.613,
            10.698,
            -78.829,
            69.613,
            10.698,
            -78.971,
            69.234,
            10.698,
            -78.971,
            69.234,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_10_1",
        "polygon": [
            -79.127,
            68.819,
            11.622,
            -78.829,
            69.613,
            11.622,
            -78.829,
            69.613,
            14.828,
            -78.829,
            69.613,
            14.828,
            -79.127,
            68.819,
            14.828,
            -79.127,
            68.819,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_10_2",
        "polygon": [
            -79.285,
            68.4,
            14.408,
            -79.142,
            68.779,
            14.408,
            -79.142,
            68.779,
            14.828,
            -79.142,
            68.779,
            14.828,
            -79.285,
            68.4,
            14.828,
            -79.285,
            68.4,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_10_3",
        "polygon": [
            -79.285,
            68.4,
            11.56,
            -79.142,
            68.779,
            11.56,
            -79.142,
            68.779,
            14.408,
            -79.142,
            68.779,
            14.408,
            -79.285,
            68.4,
            14.408,
            -79.285,
            68.4,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_8_1",
        "polygon": [
            -78.142,
            71.44,
            4.202,
            -77.843,
            72.234,
            4.202,
            -77.843,
            72.234,
            7.408,
            -77.843,
            72.234,
            7.408,
            -78.142,
            71.44,
            7.408,
            -78.142,
            71.44,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_8_2",
        "polygon": [
            -78.299,
            71.021,
            6.988,
            -78.157,
            71.4,
            6.988,
            -78.157,
            71.4,
            7.408,
            -78.157,
            71.4,
            7.408,
            -78.299,
            71.021,
            7.408,
            -78.299,
            71.021,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_8_3",
        "polygon": [
            -78.299,
            71.021,
            4.14,
            -78.157,
            71.4,
            4.14,
            -78.157,
            71.4,
            6.988,
            -78.157,
            71.4,
            6.988,
            -78.299,
            71.021,
            6.988,
            -78.299,
            71.021,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_8_1",
        "polygon": [
            -78.299,
            71.021,
            7.912,
            -78.001,
            71.816,
            7.912,
            -78.001,
            71.816,
            11.118,
            -78.001,
            71.816,
            11.118,
            -78.299,
            71.021,
            11.118,
            -78.299,
            71.021,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_8_2",
        "polygon": [
            -77.986,
            71.855,
            10.698,
            -77.843,
            72.234,
            10.698,
            -77.843,
            72.234,
            11.118,
            -77.843,
            72.234,
            11.118,
            -77.986,
            71.855,
            11.118,
            -77.986,
            71.855,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_8_3",
        "polygon": [
            -77.986,
            71.855,
            7.85,
            -77.843,
            72.234,
            7.85,
            -77.843,
            72.234,
            10.698,
            -77.843,
            72.234,
            10.698,
            -77.986,
            71.855,
            10.698,
            -77.986,
            71.855,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_8_1",
        "polygon": [
            -78.142,
            71.44,
            11.622,
            -77.843,
            72.234,
            11.622,
            -77.843,
            72.234,
            14.828,
            -77.843,
            72.234,
            14.828,
            -78.142,
            71.44,
            14.828,
            -78.142,
            71.44,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_8_2",
        "polygon": [
            -78.299,
            71.021,
            14.408,
            -78.157,
            71.4,
            14.408,
            -78.157,
            71.4,
            14.828,
            -78.157,
            71.4,
            14.828,
            -78.299,
            71.021,
            14.828,
            -78.299,
            71.021,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_8_3",
        "polygon": [
            -78.299,
            71.021,
            11.56,
            -78.157,
            71.4,
            11.56,
            -78.157,
            71.4,
            14.408,
            -78.157,
            71.4,
            14.408,
            -78.299,
            71.021,
            14.408,
            -78.299,
            71.021,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_6_1",
        "polygon": [
            -77.157,
            74.06,
            4.202,
            -76.858,
            74.855,
            4.202,
            -76.858,
            74.855,
            7.408,
            -76.858,
            74.855,
            7.408,
            -77.157,
            74.06,
            7.408,
            -77.157,
            74.06,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_6_2",
        "polygon": [
            -77.314,
            73.642,
            6.988,
            -77.172,
            74.021,
            6.988,
            -77.172,
            74.021,
            7.408,
            -77.172,
            74.021,
            7.408,
            -77.314,
            73.642,
            7.408,
            -77.314,
            73.642,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_6_3",
        "polygon": [
            -77.314,
            73.642,
            4.14,
            -77.172,
            74.021,
            4.14,
            -77.172,
            74.021,
            6.988,
            -77.172,
            74.021,
            6.988,
            -77.314,
            73.642,
            6.988,
            -77.314,
            73.642,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_6_1",
        "polygon": [
            -77.314,
            73.642,
            7.912,
            -77.015,
            74.437,
            7.912,
            -77.015,
            74.437,
            11.118,
            -77.015,
            74.437,
            11.118,
            -77.314,
            73.642,
            11.118,
            -77.314,
            73.642,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_6_2",
        "polygon": [
            -77.001,
            74.476,
            10.698,
            -76.858,
            74.855,
            10.698,
            -76.858,
            74.855,
            11.118,
            -76.858,
            74.855,
            11.118,
            -77.001,
            74.476,
            11.118,
            -77.001,
            74.476,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_6_3",
        "polygon": [
            -77.001,
            74.476,
            7.85,
            -76.858,
            74.855,
            7.85,
            -76.858,
            74.855,
            10.698,
            -76.858,
            74.855,
            10.698,
            -77.001,
            74.476,
            10.698,
            -77.001,
            74.476,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_6_1",
        "polygon": [
            -77.157,
            74.06,
            11.622,
            -76.858,
            74.855,
            11.622,
            -76.858,
            74.855,
            14.828,
            -76.858,
            74.855,
            14.828,
            -77.157,
            74.06,
            14.828,
            -77.157,
            74.06,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_6_2",
        "polygon": [
            -77.314,
            73.642,
            14.408,
            -77.172,
            74.021,
            14.408,
            -77.172,
            74.021,
            14.828,
            -77.172,
            74.021,
            14.828,
            -77.314,
            73.642,
            14.828,
            -77.314,
            73.642,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_6_3",
        "polygon": [
            -77.314,
            73.642,
            11.56,
            -77.172,
            74.021,
            11.56,
            -77.172,
            74.021,
            14.408,
            -77.172,
            74.021,
            14.408,
            -77.314,
            73.642,
            14.408,
            -77.314,
            73.642,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_4_1",
        "polygon": [
            -76.171,
            76.681,
            4.202,
            -75.873,
            77.476,
            4.202,
            -75.873,
            77.476,
            7.408,
            -75.873,
            77.476,
            7.408,
            -76.171,
            76.681,
            7.408,
            -76.171,
            76.681,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_4_2",
        "polygon": [
            -76.329,
            76.263,
            6.988,
            -76.186,
            76.642,
            6.988,
            -76.186,
            76.642,
            7.408,
            -76.186,
            76.642,
            7.408,
            -76.329,
            76.263,
            7.408,
            -76.329,
            76.263,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_4_3",
        "polygon": [
            -76.329,
            76.263,
            4.14,
            -76.186,
            76.642,
            4.14,
            -76.186,
            76.642,
            6.988,
            -76.186,
            76.642,
            6.988,
            -76.329,
            76.263,
            6.988,
            -76.329,
            76.263,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_4_1",
        "polygon": [
            -76.329,
            76.263,
            7.912,
            -76.03,
            77.058,
            7.912,
            -76.03,
            77.058,
            11.118,
            -76.03,
            77.058,
            11.118,
            -76.329,
            76.263,
            11.118,
            -76.329,
            76.263,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_4_2",
        "polygon": [
            -76.015,
            77.097,
            10.698,
            -75.873,
            77.476,
            10.698,
            -75.873,
            77.476,
            11.118,
            -75.873,
            77.476,
            11.118,
            -76.015,
            77.097,
            11.118,
            -76.015,
            77.097,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_4_3",
        "polygon": [
            -76.015,
            77.097,
            7.85,
            -75.873,
            77.476,
            7.85,
            -75.873,
            77.476,
            10.698,
            -75.873,
            77.476,
            10.698,
            -76.015,
            77.097,
            10.698,
            -76.015,
            77.097,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_4_1",
        "polygon": [
            -76.171,
            76.681,
            11.622,
            -75.873,
            77.476,
            11.622,
            -75.873,
            77.476,
            14.828,
            -75.873,
            77.476,
            14.828,
            -76.171,
            76.681,
            14.828,
            -76.171,
            76.681,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_4_2",
        "polygon": [
            -76.329,
            76.263,
            14.408,
            -76.186,
            76.642,
            14.408,
            -76.186,
            76.642,
            14.828,
            -76.186,
            76.642,
            14.828,
            -76.329,
            76.263,
            14.828,
            -76.329,
            76.263,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_4_3",
        "polygon": [
            -76.329,
            76.263,
            11.56,
            -76.186,
            76.642,
            11.56,
            -76.186,
            76.642,
            14.408,
            -76.186,
            76.642,
            14.408,
            -76.329,
            76.263,
            14.408,
            -76.329,
            76.263,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_2_1",
        "polygon": [
            -75.186,
            79.302,
            4.202,
            -74.887,
            80.097,
            4.202,
            -74.887,
            80.097,
            7.408,
            -74.887,
            80.097,
            7.408,
            -75.186,
            79.302,
            7.408,
            -75.186,
            79.302,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_2_2",
        "polygon": [
            -75.343,
            78.884,
            6.988,
            -75.201,
            79.263,
            6.988,
            -75.201,
            79.263,
            7.408,
            -75.201,
            79.263,
            7.408,
            -75.343,
            78.884,
            7.408,
            -75.343,
            78.884,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_2_3",
        "polygon": [
            -75.343,
            78.884,
            4.14,
            -75.201,
            79.263,
            4.14,
            -75.201,
            79.263,
            6.988,
            -75.201,
            79.263,
            6.988,
            -75.343,
            78.884,
            6.988,
            -75.343,
            78.884,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_2_1",
        "polygon": [
            -75.343,
            78.884,
            7.912,
            -75.045,
            79.679,
            7.912,
            -75.045,
            79.679,
            11.118,
            -75.045,
            79.679,
            11.118,
            -75.343,
            78.884,
            11.118,
            -75.343,
            78.884,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_2_2",
        "polygon": [
            -75.03,
            79.718,
            10.698,
            -74.887,
            80.097,
            10.698,
            -74.887,
            80.097,
            11.118,
            -74.887,
            80.097,
            11.118,
            -75.03,
            79.718,
            11.118,
            -75.03,
            79.718,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_2_3",
        "polygon": [
            -75.03,
            79.718,
            7.85,
            -74.887,
            80.097,
            7.85,
            -74.887,
            80.097,
            10.698,
            -74.887,
            80.097,
            10.698,
            -75.03,
            79.718,
            10.698,
            -75.03,
            79.718,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_2_1",
        "polygon": [
            -75.186,
            79.302,
            11.622,
            -74.887,
            80.097,
            11.622,
            -74.887,
            80.097,
            14.828,
            -74.887,
            80.097,
            14.828,
            -75.186,
            79.302,
            14.828,
            -75.186,
            79.302,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_2_2",
        "polygon": [
            -75.343,
            78.884,
            14.408,
            -75.201,
            79.263,
            14.408,
            -75.201,
            79.263,
            14.828,
            -75.201,
            79.263,
            14.828,
            -75.343,
            78.884,
            14.828,
            -75.343,
            78.884,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_2_3",
        "polygon": [
            -75.343,
            78.884,
            11.56,
            -75.201,
            79.263,
            11.56,
            -75.201,
            79.263,
            14.408,
            -75.201,
            79.263,
            14.408,
            -75.343,
            78.884,
            14.408,
            -75.343,
            78.884,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1,
        "05-24 19:15": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_2_1",
        "polygon": [
            -75.343,
            78.884,
            0.142,
            -75.045,
            79.679,
            0.142,
            -75.045,
            79.679,
            3.698,
            -75.045,
            79.679,
            3.698,
            -75.343,
            78.884,
            3.698,
            -75.343,
            78.884,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_2_2",
        "polygon": [
            -75.03,
            79.718,
            2.928,
            -74.887,
            80.097,
            2.928,
            -74.887,
            80.097,
            3.698,
            -74.887,
            80.097,
            3.698,
            -75.03,
            79.718,
            3.698,
            -75.03,
            79.718,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_2_3",
        "polygon": [
            -75.03,
            79.718,
            0.08,
            -74.887,
            80.097,
            0.08,
            -74.887,
            80.097,
            2.928,
            -74.887,
            80.097,
            2.928,
            -75.03,
            79.718,
            2.928,
            -75.03,
            79.718,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_4_1",
        "polygon": [
            -76.329,
            76.263,
            0.142,
            -76.03,
            77.058,
            0.142,
            -76.03,
            77.058,
            3.698,
            -76.03,
            77.058,
            3.698,
            -76.329,
            76.263,
            3.698,
            -76.329,
            76.263,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_4_2",
        "polygon": [
            -76.015,
            77.097,
            2.928,
            -75.873,
            77.476,
            2.928,
            -75.873,
            77.476,
            3.698,
            -75.873,
            77.476,
            3.698,
            -76.015,
            77.097,
            3.698,
            -76.015,
            77.097,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_4_3",
        "polygon": [
            -76.015,
            77.097,
            0.08,
            -75.873,
            77.476,
            0.08,
            -75.873,
            77.476,
            2.928,
            -75.873,
            77.476,
            2.928,
            -76.015,
            77.097,
            2.928,
            -76.015,
            77.097,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_6_1",
        "polygon": [
            -77.314,
            73.642,
            0.142,
            -77.015,
            74.437,
            0.142,
            -77.015,
            74.437,
            3.698,
            -77.015,
            74.437,
            3.698,
            -77.314,
            73.642,
            3.698,
            -77.314,
            73.642,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_6_2",
        "polygon": [
            -77.001,
            74.476,
            2.928,
            -76.858,
            74.855,
            2.928,
            -76.858,
            74.855,
            3.698,
            -76.858,
            74.855,
            3.698,
            -77.001,
            74.476,
            3.698,
            -77.001,
            74.476,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_6_3",
        "polygon": [
            -77.001,
            74.476,
            0.08,
            -76.858,
            74.855,
            0.08,
            -76.858,
            74.855,
            2.928,
            -76.858,
            74.855,
            2.928,
            -77.001,
            74.476,
            2.928,
            -77.001,
            74.476,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_10_1",
        "polygon": [
            -79.285,
            68.4,
            0.142,
            -78.986,
            69.195,
            0.142,
            -78.986,
            69.195,
            3.698,
            -78.986,
            69.195,
            3.698,
            -79.285,
            68.4,
            3.698,
            -79.285,
            68.4,
            0.142
        ],
        "05-24 14:00": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_10_2",
        "polygon": [
            -78.971,
            69.234,
            2.928,
            -78.829,
            69.613,
            2.928,
            -78.829,
            69.613,
            3.698,
            -78.829,
            69.613,
            3.698,
            -78.971,
            69.234,
            3.698,
            -78.971,
            69.234,
            2.928
        ],
        "05-24 14:00": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_10_3",
        "polygon": [
            -78.971,
            69.234,
            0.08,
            -78.829,
            69.613,
            0.08,
            -78.829,
            69.613,
            2.928,
            -78.829,
            69.613,
            2.928,
            -78.971,
            69.234,
            2.928,
            -78.971,
            69.234,
            0.08
        ],
        "05-24 14:00": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_14_1",
        "polygon": [
            -81.255,
            63.158,
            0.142,
            -80.957,
            63.953,
            0.142,
            -80.957,
            63.953,
            3.698,
            -80.957,
            63.953,
            3.698,
            -81.255,
            63.158,
            3.698,
            -81.255,
            63.158,
            0.142
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_14_2",
        "polygon": [
            -80.942,
            63.992,
            2.928,
            -80.799,
            64.372,
            2.928,
            -80.799,
            64.372,
            3.698,
            -80.799,
            64.372,
            3.698,
            -80.942,
            63.992,
            3.698,
            -80.942,
            63.992,
            2.928
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_14_3",
        "polygon": [
            -80.942,
            63.992,
            0.08,
            -80.799,
            64.372,
            0.08,
            -80.799,
            64.372,
            2.928,
            -80.799,
            64.372,
            2.928,
            -80.942,
            63.992,
            2.928,
            -80.942,
            63.992,
            0.08
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_8_1",
        "polygon": [
            -78.299,
            71.021,
            0.06,
            -77.843,
            72.234,
            0.06,
            -77.843,
            72.234,
            2.869,
            -77.843,
            72.234,
            2.869,
            -78.299,
            71.021,
            2.869,
            -78.299,
            71.021,
            0.06
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_8_2",
        "polygon": [
            -78.299,
            71.021,
            2.928,
            -77.843,
            72.234,
            2.928,
            -77.843,
            72.234,
            3.698,
            -77.843,
            72.234,
            3.698,
            -78.299,
            71.021,
            3.698,
            -78.299,
            71.021,
            2.928
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_12_1",
        "polygon": [
            -80.278,
            65.758,
            0.06,
            -79.822,
            66.971,
            0.06,
            -79.822,
            66.971,
            2.869,
            -79.822,
            66.971,
            2.869,
            -80.278,
            65.758,
            2.869,
            -80.278,
            65.758,
            0.06
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_12_2",
        "polygon": [
            -80.278,
            65.758,
            2.928,
            -79.822,
            66.971,
            2.928,
            -79.822,
            66.971,
            3.698,
            -79.822,
            66.971,
            3.698,
            -80.278,
            65.758,
            3.698,
            -80.278,
            65.758,
            2.928
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_16_1",
        "polygon": [
            -82.241,
            60.538,
            0.06,
            -81.785,
            61.751,
            0.06,
            -81.785,
            61.751,
            2.869,
            -81.785,
            61.751,
            2.869,
            -82.241,
            60.538,
            2.869,
            -82.241,
            60.538,
            0.06
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_EG_16_2",
        "polygon": [
            -82.241,
            60.538,
            2.928,
            -81.785,
            61.751,
            2.928,
            -81.785,
            61.751,
            3.698,
            -81.785,
            61.751,
            3.698,
            -82.241,
            60.538,
            3.698,
            -82.241,
            60.538,
            2.928
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_16_1",
        "polygon": [
            -82.084,
            60.956,
            4.202,
            -81.785,
            61.751,
            4.202,
            -81.785,
            61.751,
            7.408,
            -81.785,
            61.751,
            7.408,
            -82.084,
            60.956,
            7.408,
            -82.084,
            60.956,
            4.202
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_16_2",
        "polygon": [
            -82.241,
            60.538,
            6.988,
            -82.098,
            60.917,
            6.988,
            -82.098,
            60.917,
            7.408,
            -82.098,
            60.917,
            7.408,
            -82.241,
            60.538,
            7.408,
            -82.241,
            60.538,
            6.988
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_1OG_16_3",
        "polygon": [
            -82.241,
            60.538,
            4.14,
            -82.098,
            60.917,
            4.14,
            -82.098,
            60.917,
            6.988,
            -82.098,
            60.917,
            6.988,
            -82.241,
            60.538,
            6.988,
            -82.241,
            60.538,
            4.14
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_16_1",
        "polygon": [
            -82.084,
            60.956,
            7.912,
            -81.785,
            61.751,
            7.912,
            -81.785,
            61.751,
            11.118,
            -81.785,
            61.751,
            11.118,
            -82.084,
            60.956,
            11.118,
            -82.084,
            60.956,
            7.912
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_16_2",
        "polygon": [
            -82.241,
            60.538,
            10.698,
            -82.098,
            60.917,
            10.698,
            -82.098,
            60.917,
            11.118,
            -82.098,
            60.917,
            11.118,
            -82.241,
            60.538,
            11.118,
            -82.241,
            60.538,
            10.698
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_2OG_16_3",
        "polygon": [
            -82.241,
            60.538,
            7.85,
            -82.098,
            60.917,
            7.85,
            -82.098,
            60.917,
            10.698,
            -82.098,
            60.917,
            10.698,
            -82.241,
            60.538,
            10.698,
            -82.241,
            60.538,
            7.85
        ]
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_16_1",
        "polygon": [
            -82.084,
            60.956,
            11.622,
            -81.785,
            61.751,
            11.622,
            -81.785,
            61.751,
            14.828,
            -81.785,
            61.751,
            14.828,
            -82.084,
            60.956,
            14.828,
            -82.084,
            60.956,
            11.622
        ],
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_16_2",
        "polygon": [
            -82.241,
            60.538,
            14.408,
            -82.098,
            60.917,
            14.408,
            -82.098,
            60.917,
            14.828,
            -82.098,
            60.917,
            14.828,
            -82.241,
            60.538,
            14.828,
            -82.241,
            60.538,
            14.408
        ],
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.12-Ax6C A_D2_3OG_16_3",
        "polygon": [
            -82.241,
            60.538,
            11.56,
            -82.098,
            60.917,
            11.56,
            -82.098,
            60.917,
            14.408,
            -82.098,
            60.917,
            14.408,
            -82.241,
            60.538,
            14.408,
            -82.241,
            60.538,
            11.56
        ],
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_2_1",
        "polygon": [
            -9.171,
            48.023,
            0.142,
            -8.872,
            48.818,
            0.142,
            -8.872,
            48.818,
            3.698,
            -8.872,
            48.818,
            3.698,
            -9.171,
            48.023,
            3.698,
            -9.171,
            48.023,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_2_2",
        "polygon": [
            -8.857,
            48.857,
            2.928,
            -8.715,
            49.236,
            2.928,
            -8.715,
            49.236,
            3.698,
            -8.715,
            49.236,
            3.698,
            -8.857,
            48.857,
            3.698,
            -8.857,
            48.857,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_2_3",
        "polygon": [
            -8.857,
            48.857,
            0.08,
            -8.715,
            49.236,
            0.08,
            -8.715,
            49.236,
            2.928,
            -8.715,
            49.236,
            2.928,
            -8.857,
            48.857,
            2.928,
            -8.857,
            48.857,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_4_1",
        "polygon": [
            -10.156,
            45.402,
            0.142,
            -9.857,
            46.197,
            0.142,
            -9.857,
            46.197,
            3.698,
            -9.857,
            46.197,
            3.698,
            -10.156,
            45.402,
            3.698,
            -10.156,
            45.402,
            0.142
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_4_2",
        "polygon": [
            -9.843,
            46.236,
            2.928,
            -9.7,
            46.615,
            2.928,
            -9.7,
            46.615,
            3.698,
            -9.7,
            46.615,
            3.698,
            -9.843,
            46.236,
            3.698,
            -9.843,
            46.236,
            2.928
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_4_3",
        "polygon": [
            -9.843,
            46.236,
            0.08,
            -9.7,
            46.615,
            0.08,
            -9.7,
            46.615,
            2.928,
            -9.7,
            46.615,
            2.928,
            -9.843,
            46.236,
            2.928,
            -9.843,
            46.236,
            0.08
        ],
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_6_1",
        "polygon": [
            -11.142,
            42.781,
            0.142,
            -10.843,
            43.576,
            0.142,
            -10.843,
            43.576,
            3.698,
            -10.843,
            43.576,
            3.698,
            -11.142,
            42.781,
            3.698,
            -11.142,
            42.781,
            0.142
        ],
        "05-24 14:00": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_6_2",
        "polygon": [
            -10.828,
            43.615,
            2.928,
            -10.685,
            43.994,
            2.928,
            -10.685,
            43.994,
            3.698,
            -10.685,
            43.994,
            3.698,
            -10.828,
            43.615,
            3.698,
            -10.828,
            43.615,
            2.928
        ],
        "05-24 14:00": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_6_3",
        "polygon": [
            -10.828,
            43.615,
            0.08,
            -10.685,
            43.994,
            0.08,
            -10.685,
            43.994,
            2.928,
            -10.685,
            43.994,
            2.928,
            -10.828,
            43.615,
            2.928,
            -10.828,
            43.615,
            0.08
        ],
        "05-24 14:00": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_8_1",
        "polygon": [
            -12.127,
            40.16,
            0.142,
            -11.828,
            40.955,
            0.142,
            -11.828,
            40.955,
            3.698,
            -11.828,
            40.955,
            3.698,
            -12.127,
            40.16,
            3.698,
            -12.127,
            40.16,
            0.142
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_8_2",
        "polygon": [
            -11.813,
            40.994,
            2.928,
            -11.671,
            41.373,
            2.928,
            -11.671,
            41.373,
            3.698,
            -11.671,
            41.373,
            3.698,
            -11.813,
            40.994,
            3.698,
            -11.813,
            40.994,
            2.928
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_8_3",
        "polygon": [
            -11.813,
            40.994,
            0.08,
            -11.671,
            41.373,
            0.08,
            -11.671,
            41.373,
            2.928,
            -11.671,
            41.373,
            2.928,
            -11.813,
            40.994,
            2.928,
            -11.813,
            40.994,
            0.08
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_10_1",
        "polygon": [
            -13.112,
            37.539,
            0.142,
            -12.813,
            38.334,
            0.142,
            -12.813,
            38.334,
            3.698,
            -12.813,
            38.334,
            3.698,
            -13.112,
            37.539,
            3.698,
            -13.112,
            37.539,
            0.142
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_10_2",
        "polygon": [
            -12.799,
            38.373,
            2.928,
            -12.656,
            38.752,
            2.928,
            -12.656,
            38.752,
            3.698,
            -12.656,
            38.752,
            3.698,
            -12.799,
            38.373,
            3.698,
            -12.799,
            38.373,
            2.928
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_10_3",
        "polygon": [
            -12.799,
            38.373,
            0.08,
            -12.656,
            38.752,
            0.08,
            -12.656,
            38.752,
            2.928,
            -12.656,
            38.752,
            2.928,
            -12.799,
            38.373,
            2.928,
            -12.799,
            38.373,
            0.08
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_12_1",
        "polygon": [
            -14.098,
            34.918,
            0.06,
            -13.641,
            36.132,
            0.06,
            -13.641,
            36.132,
            2.869,
            -13.641,
            36.132,
            2.869,
            -14.098,
            34.918,
            2.869,
            -14.098,
            34.918,
            0.06
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_EG_12_2",
        "polygon": [
            -14.098,
            34.918,
            2.928,
            -13.641,
            36.132,
            2.928,
            -13.641,
            36.132,
            3.698,
            -13.641,
            36.132,
            3.698,
            -14.098,
            34.918,
            3.698,
            -14.098,
            34.918,
            2.928
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_4_1",
        "polygon": [
            -9.998,
            45.821,
            4.202,
            -9.7,
            46.616,
            4.202,
            -9.7,
            46.616,
            7.408,
            -9.7,
            46.616,
            7.408,
            -9.998,
            45.821,
            7.408,
            -9.998,
            45.821,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_4_2",
        "polygon": [
            -10.156,
            45.403,
            6.988,
            -10.013,
            45.782,
            6.988,
            -10.013,
            45.782,
            7.408,
            -10.013,
            45.782,
            7.408,
            -10.156,
            45.403,
            7.408,
            -10.156,
            45.403,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_4_3",
        "polygon": [
            -10.156,
            45.403,
            4.14,
            -10.013,
            45.782,
            4.14,
            -10.013,
            45.782,
            6.988,
            -10.013,
            45.782,
            6.988,
            -10.156,
            45.403,
            6.988,
            -10.156,
            45.403,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_2_1",
        "polygon": [
            -9.013,
            48.442,
            4.202,
            -8.714,
            49.237,
            4.202,
            -8.714,
            49.237,
            7.408,
            -8.714,
            49.237,
            7.408,
            -9.013,
            48.442,
            7.408,
            -9.013,
            48.442,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_2_2",
        "polygon": [
            -9.17,
            48.024,
            6.988,
            -9.028,
            48.403,
            6.988,
            -9.028,
            48.403,
            7.408,
            -9.028,
            48.403,
            7.408,
            -9.17,
            48.024,
            7.408,
            -9.17,
            48.024,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_2_3",
        "polygon": [
            -9.17,
            48.024,
            4.14,
            -9.028,
            48.403,
            4.14,
            -9.028,
            48.403,
            6.988,
            -9.028,
            48.403,
            6.988,
            -9.17,
            48.024,
            6.988,
            -9.17,
            48.024,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_6_1",
        "polygon": [
            -10.984,
            43.2,
            4.202,
            -10.685,
            43.994,
            4.202,
            -10.685,
            43.994,
            7.408,
            -10.685,
            43.994,
            7.408,
            -10.984,
            43.2,
            7.408,
            -10.984,
            43.2,
            4.202
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_6_2",
        "polygon": [
            -11.142,
            42.781,
            6.988,
            -10.999,
            43.16,
            6.988,
            -10.999,
            43.16,
            7.408,
            -10.999,
            43.16,
            7.408,
            -11.142,
            42.781,
            7.408,
            -11.142,
            42.781,
            6.988
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_6_3",
        "polygon": [
            -11.142,
            42.781,
            4.14,
            -10.999,
            43.16,
            4.14,
            -10.999,
            43.16,
            6.988,
            -10.999,
            43.16,
            6.988,
            -11.142,
            42.781,
            6.988,
            -11.142,
            42.781,
            4.14
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_8_1",
        "polygon": [
            -11.97,
            40.579,
            4.202,
            -11.671,
            41.373,
            4.202,
            -11.671,
            41.373,
            7.408,
            -11.671,
            41.373,
            7.408,
            -11.97,
            40.579,
            7.408,
            -11.97,
            40.579,
            4.202
        ],
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_8_2",
        "polygon": [
            -12.127,
            40.16,
            6.988,
            -11.984,
            40.539,
            6.988,
            -11.984,
            40.539,
            7.408,
            -11.984,
            40.539,
            7.408,
            -12.127,
            40.16,
            7.408,
            -12.127,
            40.16,
            6.988
        ],
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_8_3",
        "polygon": [
            -12.127,
            40.16,
            4.14,
            -11.984,
            40.539,
            4.14,
            -11.984,
            40.539,
            6.988,
            -11.984,
            40.539,
            6.988,
            -12.127,
            40.16,
            6.988,
            -12.127,
            40.16,
            4.14
        ],
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_10_1",
        "polygon": [
            -12.955,
            37.958,
            4.202,
            -12.656,
            38.752,
            4.202,
            -12.656,
            38.752,
            7.408,
            -12.656,
            38.752,
            7.408,
            -12.955,
            37.958,
            7.408,
            -12.955,
            37.958,
            4.202
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_10_2",
        "polygon": [
            -13.112,
            37.539,
            6.988,
            -12.97,
            37.918,
            6.988,
            -12.97,
            37.918,
            7.408,
            -12.97,
            37.918,
            7.408,
            -13.112,
            37.539,
            7.408,
            -13.112,
            37.539,
            6.988
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_10_3",
        "polygon": [
            -13.112,
            37.539,
            4.14,
            -12.97,
            37.918,
            4.14,
            -12.97,
            37.918,
            6.988,
            -12.97,
            37.918,
            6.988,
            -13.112,
            37.539,
            6.988,
            -13.112,
            37.539,
            4.14
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_12_1",
        "polygon": [
            -13.94,
            35.337,
            4.202,
            -13.641,
            36.132,
            4.202,
            -13.641,
            36.132,
            7.408,
            -13.641,
            36.132,
            7.408,
            -13.94,
            35.337,
            7.408,
            -13.94,
            35.337,
            4.202
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_12_2",
        "polygon": [
            -14.098,
            34.918,
            6.988,
            -13.955,
            35.298,
            6.988,
            -13.955,
            35.298,
            7.408,
            -13.955,
            35.298,
            7.408,
            -14.098,
            34.918,
            7.408,
            -14.098,
            34.918,
            6.988
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_1OG_12_3",
        "polygon": [
            -14.098,
            34.918,
            4.14,
            -13.955,
            35.298,
            4.14,
            -13.955,
            35.298,
            6.988,
            -13.955,
            35.298,
            6.988,
            -14.098,
            34.918,
            6.988,
            -14.098,
            34.918,
            4.14
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_2_1",
        "polygon": [
            -9.171,
            48.023,
            7.912,
            -8.872,
            48.818,
            7.912,
            -8.872,
            48.818,
            11.118,
            -8.872,
            48.818,
            11.118,
            -9.171,
            48.023,
            11.118,
            -9.171,
            48.023,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_2_2",
        "polygon": [
            -8.857,
            48.857,
            10.698,
            -8.715,
            49.236,
            10.698,
            -8.715,
            49.236,
            11.118,
            -8.715,
            49.236,
            11.118,
            -8.857,
            48.857,
            11.118,
            -8.857,
            48.857,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_2_3",
        "polygon": [
            -8.857,
            48.857,
            7.85,
            -8.715,
            49.236,
            7.85,
            -8.715,
            49.236,
            10.698,
            -8.715,
            49.236,
            10.698,
            -8.857,
            48.857,
            10.698,
            -8.857,
            48.857,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_4_1",
        "polygon": [
            -10.156,
            45.402,
            7.912,
            -9.857,
            46.197,
            7.912,
            -9.857,
            46.197,
            11.118,
            -9.857,
            46.197,
            11.118,
            -10.156,
            45.402,
            11.118,
            -10.156,
            45.402,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_4_2",
        "polygon": [
            -9.843,
            46.236,
            10.698,
            -9.7,
            46.615,
            10.698,
            -9.7,
            46.615,
            11.118,
            -9.7,
            46.615,
            11.118,
            -9.843,
            46.236,
            11.118,
            -9.843,
            46.236,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_4_3",
        "polygon": [
            -9.843,
            46.236,
            7.85,
            -9.7,
            46.615,
            7.85,
            -9.7,
            46.615,
            10.698,
            -9.7,
            46.615,
            10.698,
            -9.843,
            46.236,
            10.698,
            -9.843,
            46.236,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_6_1",
        "polygon": [
            -11.142,
            42.781,
            7.912,
            -10.843,
            43.576,
            7.912,
            -10.843,
            43.576,
            11.118,
            -10.843,
            43.576,
            11.118,
            -11.142,
            42.781,
            11.118,
            -11.142,
            42.781,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_6_2",
        "polygon": [
            -10.828,
            43.615,
            10.698,
            -10.685,
            43.994,
            10.698,
            -10.685,
            43.994,
            11.118,
            -10.685,
            43.994,
            11.118,
            -10.828,
            43.615,
            11.118,
            -10.828,
            43.615,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_6_3",
        "polygon": [
            -10.828,
            43.615,
            7.85,
            -10.685,
            43.994,
            7.85,
            -10.685,
            43.994,
            10.698,
            -10.685,
            43.994,
            10.698,
            -10.828,
            43.615,
            10.698,
            -10.828,
            43.615,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_8_1",
        "polygon": [
            -12.126,
            40.161,
            7.912,
            -11.828,
            40.956,
            7.912,
            -11.828,
            40.956,
            11.118,
            -11.828,
            40.956,
            11.118,
            -12.126,
            40.161,
            11.118,
            -12.126,
            40.161,
            7.912
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_8_2",
        "polygon": [
            -11.813,
            40.995,
            10.698,
            -11.67,
            41.374,
            10.698,
            -11.67,
            41.374,
            11.118,
            -11.67,
            41.374,
            11.118,
            -11.813,
            40.995,
            11.118,
            -11.813,
            40.995,
            10.698
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_8_3",
        "polygon": [
            -11.813,
            40.995,
            7.85,
            -11.67,
            41.374,
            7.85,
            -11.67,
            41.374,
            10.698,
            -11.67,
            41.374,
            10.698,
            -11.813,
            40.995,
            10.698,
            -11.813,
            40.995,
            7.85
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_10_1",
        "polygon": [
            -13.112,
            37.54,
            7.912,
            -12.813,
            38.335,
            7.912,
            -12.813,
            38.335,
            11.118,
            -12.813,
            38.335,
            11.118,
            -13.112,
            37.54,
            11.118,
            -13.112,
            37.54,
            7.912
        ],
        "05-24 14:00": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_10_2",
        "polygon": [
            -12.798,
            38.374,
            10.698,
            -12.656,
            38.753,
            10.698,
            -12.656,
            38.753,
            11.118,
            -12.656,
            38.753,
            11.118,
            -12.798,
            38.374,
            11.118,
            -12.798,
            38.374,
            10.698
        ],
        "05-24 14:00": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_10_3",
        "polygon": [
            -12.798,
            38.374,
            7.85,
            -12.656,
            38.753,
            7.85,
            -12.656,
            38.753,
            10.698,
            -12.656,
            38.753,
            10.698,
            -12.798,
            38.374,
            10.698,
            -12.798,
            38.374,
            7.85
        ],
        "05-24 14:00": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_12_1",
        "polygon": [
            -13.94,
            35.337,
            7.912,
            -13.641,
            36.132,
            7.912,
            -13.641,
            36.132,
            11.118,
            -13.641,
            36.132,
            11.118,
            -13.94,
            35.337,
            11.118,
            -13.94,
            35.337,
            7.912
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_12_2",
        "polygon": [
            -14.098,
            34.918,
            10.698,
            -13.955,
            35.298,
            10.698,
            -13.955,
            35.298,
            11.118,
            -13.955,
            35.298,
            11.118,
            -14.098,
            34.918,
            11.118,
            -14.098,
            34.918,
            10.698
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_2OG_12_3",
        "polygon": [
            -14.098,
            34.918,
            7.85,
            -13.955,
            35.298,
            7.85,
            -13.955,
            35.298,
            10.698,
            -13.955,
            35.298,
            10.698,
            -14.098,
            34.918,
            10.698,
            -14.098,
            34.918,
            7.85
        ]
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_6_1",
        "polygon": [
            -10.984,
            43.2,
            11.622,
            -10.685,
            43.994,
            11.622,
            -10.685,
            43.994,
            14.828,
            -10.685,
            43.994,
            14.828,
            -10.984,
            43.2,
            14.828,
            -10.984,
            43.2,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_6_2",
        "polygon": [
            -11.142,
            42.781,
            14.408,
            -10.999,
            43.16,
            14.408,
            -10.999,
            43.16,
            14.828,
            -10.999,
            43.16,
            14.828,
            -11.142,
            42.781,
            14.828,
            -11.142,
            42.781,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_6_3",
        "polygon": [
            -11.142,
            42.781,
            11.56,
            -10.999,
            43.16,
            11.56,
            -10.999,
            43.16,
            14.408,
            -10.999,
            43.16,
            14.408,
            -11.142,
            42.781,
            14.408,
            -11.142,
            42.781,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_8_1",
        "polygon": [
            -11.97,
            40.579,
            11.622,
            -11.671,
            41.373,
            11.622,
            -11.671,
            41.373,
            14.828,
            -11.671,
            41.373,
            14.828,
            -11.97,
            40.579,
            14.828,
            -11.97,
            40.579,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_8_2",
        "polygon": [
            -12.127,
            40.16,
            14.408,
            -11.984,
            40.539,
            14.408,
            -11.984,
            40.539,
            14.828,
            -11.984,
            40.539,
            14.828,
            -12.127,
            40.16,
            14.828,
            -12.127,
            40.16,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_8_3",
        "polygon": [
            -12.127,
            40.16,
            11.56,
            -11.984,
            40.539,
            11.56,
            -11.984,
            40.539,
            14.408,
            -11.984,
            40.539,
            14.408,
            -12.127,
            40.16,
            14.408,
            -12.127,
            40.16,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_10_1",
        "polygon": [
            -12.955,
            37.958,
            11.622,
            -12.656,
            38.752,
            11.622,
            -12.656,
            38.752,
            14.828,
            -12.656,
            38.752,
            14.828,
            -12.955,
            37.958,
            14.828,
            -12.955,
            37.958,
            11.622
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_10_2",
        "polygon": [
            -13.112,
            37.539,
            14.408,
            -12.97,
            37.918,
            14.408,
            -12.97,
            37.918,
            14.828,
            -12.97,
            37.918,
            14.828,
            -13.112,
            37.539,
            14.828,
            -13.112,
            37.539,
            14.408
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_10_3",
        "polygon": [
            -13.112,
            37.539,
            11.56,
            -12.97,
            37.918,
            11.56,
            -12.97,
            37.918,
            14.408,
            -12.97,
            37.918,
            14.408,
            -13.112,
            37.539,
            14.408,
            -13.112,
            37.539,
            11.56
        ],
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_12_1",
        "polygon": [
            -13.94,
            35.337,
            11.622,
            -13.641,
            36.132,
            11.622,
            -13.641,
            36.132,
            14.828,
            -13.641,
            36.132,
            14.828,
            -13.94,
            35.337,
            14.828,
            -13.94,
            35.337,
            11.622
        ],
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_12_2",
        "polygon": [
            -14.098,
            34.918,
            14.408,
            -13.955,
            35.298,
            14.408,
            -13.955,
            35.298,
            14.828,
            -13.955,
            35.298,
            14.828,
            -14.098,
            34.918,
            14.828,
            -14.098,
            34.918,
            14.408
        ],
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.10-Ax17 A4_D2_3OG_12_3",
        "polygon": [
            -14.098,
            34.918,
            11.56,
            -13.955,
            35.298,
            11.56,
            -13.955,
            35.298,
            14.408,
            -13.955,
            35.298,
            14.408,
            -14.098,
            34.918,
            14.408,
            -14.098,
            34.918,
            11.56
        ],
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1,
        "05-24 18:30": 1,
        "05-24 18:45": 1,
        "05-24 19:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_16_1",
        "polygon": [
            -26.427,
            61.4,
            4.202,
            -26.726,
            60.606,
            4.202,
            -26.726,
            60.606,
            7.408,
            -26.726,
            60.606,
            7.408,
            -26.427,
            61.4,
            7.408,
            -26.427,
            61.4,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_16_2",
        "polygon": [
            -26.27,
            61.819,
            4.14,
            -26.412,
            61.44,
            4.14,
            -26.412,
            61.44,
            6.988,
            -26.412,
            61.44,
            6.988,
            -26.27,
            61.819,
            6.988,
            -26.27,
            61.819,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_16_3",
        "polygon": [
            -26.27,
            61.819,
            6.988,
            -26.412,
            61.44,
            6.988,
            -26.412,
            61.44,
            7.408,
            -26.412,
            61.44,
            7.408,
            -26.27,
            61.819,
            7.408,
            -26.27,
            61.819,
            6.988
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_14_1",
        "polygon": [
            -27.412,
            58.779,
            4.202,
            -27.711,
            57.985,
            4.202,
            -27.711,
            57.985,
            7.408,
            -27.711,
            57.985,
            7.408,
            -27.412,
            58.779,
            7.408,
            -27.412,
            58.779,
            4.202
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_14_2",
        "polygon": [
            -27.255,
            59.198,
            4.14,
            -27.398,
            58.819,
            4.14,
            -27.398,
            58.819,
            6.988,
            -27.398,
            58.819,
            6.988,
            -27.255,
            59.198,
            6.988,
            -27.255,
            59.198,
            4.14
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_14_3",
        "polygon": [
            -27.255,
            59.198,
            6.988,
            -27.398,
            58.819,
            6.988,
            -27.398,
            58.819,
            7.408,
            -27.398,
            58.819,
            7.408,
            -27.255,
            59.198,
            7.408,
            -27.255,
            59.198,
            6.988
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_12_1",
        "polygon": [
            -28.398,
            56.158,
            4.202,
            -28.696,
            55.364,
            4.202,
            -28.696,
            55.364,
            7.408,
            -28.696,
            55.364,
            7.408,
            -28.398,
            56.158,
            7.408,
            -28.398,
            56.158,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_12_2",
        "polygon": [
            -28.24,
            56.577,
            4.14,
            -28.383,
            56.198,
            4.14,
            -28.383,
            56.198,
            6.988,
            -28.383,
            56.198,
            6.988,
            -28.24,
            56.577,
            6.988,
            -28.24,
            56.577,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_12_3",
        "polygon": [
            -28.24,
            56.577,
            6.988,
            -28.383,
            56.198,
            6.988,
            -28.383,
            56.198,
            7.408,
            -28.383,
            56.198,
            7.408,
            -28.24,
            56.577,
            7.408,
            -28.24,
            56.577,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_10_1",
        "polygon": [
            -29.383,
            53.538,
            4.202,
            -29.682,
            52.743,
            4.202,
            -29.682,
            52.743,
            7.408,
            -29.682,
            52.743,
            7.408,
            -29.383,
            53.538,
            7.408,
            -29.383,
            53.538,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_10_2",
        "polygon": [
            -29.226,
            53.956,
            4.14,
            -29.368,
            53.577,
            4.14,
            -29.368,
            53.577,
            6.988,
            -29.368,
            53.577,
            6.988,
            -29.226,
            53.956,
            6.988,
            -29.226,
            53.956,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_10_3",
        "polygon": [
            -29.226,
            53.956,
            6.988,
            -29.368,
            53.577,
            6.988,
            -29.368,
            53.577,
            7.408,
            -29.368,
            53.577,
            7.408,
            -29.226,
            53.956,
            7.408,
            -29.226,
            53.956,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_8_1",
        "polygon": [
            -30.368,
            50.917,
            4.202,
            -30.667,
            50.122,
            4.202,
            -30.667,
            50.122,
            7.408,
            -30.667,
            50.122,
            7.408,
            -30.368,
            50.917,
            7.408,
            -30.368,
            50.917,
            4.202
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_8_2",
        "polygon": [
            -30.211,
            51.335,
            4.14,
            -30.354,
            50.956,
            4.14,
            -30.354,
            50.956,
            6.988,
            -30.354,
            50.956,
            6.988,
            -30.211,
            51.335,
            6.988,
            -30.211,
            51.335,
            4.14
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_8_3",
        "polygon": [
            -30.211,
            51.335,
            6.988,
            -30.354,
            50.956,
            6.988,
            -30.354,
            50.956,
            7.408,
            -30.354,
            50.956,
            7.408,
            -30.211,
            51.335,
            7.408,
            -30.211,
            51.335,
            6.988
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_6_1",
        "polygon": [
            -31.196,
            48.714,
            4.14,
            -31.339,
            48.335,
            4.14,
            -31.339,
            48.335,
            6.988,
            -31.339,
            48.335,
            6.988,
            -31.196,
            48.714,
            6.988,
            -31.196,
            48.714,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_6_2",
        "polygon": [
            -31.196,
            48.714,
            6.988,
            -31.339,
            48.335,
            6.988,
            -31.339,
            48.335,
            7.408,
            -31.339,
            48.335,
            7.408,
            -31.196,
            48.714,
            7.408,
            -31.196,
            48.714,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_6_3",
        "polygon": [
            -31.354,
            48.296,
            4.202,
            -31.653,
            47.501,
            4.202,
            -31.653,
            47.501,
            7.408,
            -31.653,
            47.501,
            7.408,
            -31.354,
            48.296,
            7.408,
            -31.354,
            48.296,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_4_1",
        "polygon": [
            -32.182,
            46.093,
            4.14,
            -32.324,
            45.714,
            4.14,
            -32.324,
            45.714,
            6.988,
            -32.324,
            45.714,
            6.988,
            -32.182,
            46.093,
            6.988,
            -32.182,
            46.093,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_4_2",
        "polygon": [
            -32.182,
            46.093,
            6.988,
            -32.324,
            45.714,
            6.988,
            -32.324,
            45.714,
            7.408,
            -32.324,
            45.714,
            7.408,
            -32.182,
            46.093,
            7.408,
            -32.182,
            46.093,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_4_3",
        "polygon": [
            -32.339,
            45.675,
            4.202,
            -32.638,
            44.88,
            4.202,
            -32.638,
            44.88,
            7.408,
            -32.638,
            44.88,
            7.408,
            -32.339,
            45.675,
            7.408,
            -32.339,
            45.675,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_2_1",
        "polygon": [
            -33.481,
            42.638,
            4.14,
            -33.623,
            42.259,
            4.14,
            -33.623,
            42.259,
            6.988,
            -33.623,
            42.259,
            6.988,
            -33.481,
            42.638,
            6.988,
            -33.481,
            42.638,
            4.14
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_2_2",
        "polygon": [
            -33.481,
            42.638,
            6.988,
            -33.623,
            42.259,
            6.988,
            -33.623,
            42.259,
            7.408,
            -33.623,
            42.259,
            7.408,
            -33.481,
            42.638,
            7.408,
            -33.481,
            42.638,
            6.988
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_1OG_2_3",
        "polygon": [
            -33.167,
            43.472,
            4.202,
            -33.466,
            42.678,
            4.202,
            -33.466,
            42.678,
            7.408,
            -33.466,
            42.678,
            7.408,
            -33.167,
            43.472,
            7.408,
            -33.167,
            43.472,
            4.202
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_16_1",
        "polygon": [
            -26.427,
            61.4,
            11.622,
            -26.726,
            60.606,
            11.622,
            -26.726,
            60.606,
            14.828,
            -26.726,
            60.606,
            14.828,
            -26.427,
            61.4,
            14.828,
            -26.427,
            61.4,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_16_2",
        "polygon": [
            -26.27,
            61.819,
            11.56,
            -26.412,
            61.44,
            11.56,
            -26.412,
            61.44,
            14.408,
            -26.412,
            61.44,
            14.408,
            -26.27,
            61.819,
            14.408,
            -26.27,
            61.819,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_16_3",
        "polygon": [
            -26.27,
            61.819,
            14.408,
            -26.412,
            61.44,
            14.408,
            -26.412,
            61.44,
            14.828,
            -26.412,
            61.44,
            14.828,
            -26.27,
            61.819,
            14.828,
            -26.27,
            61.819,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_14_1",
        "polygon": [
            -27.412,
            58.779,
            11.622,
            -27.711,
            57.985,
            11.622,
            -27.711,
            57.985,
            14.828,
            -27.711,
            57.985,
            14.828,
            -27.412,
            58.779,
            14.828,
            -27.412,
            58.779,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_14_2",
        "polygon": [
            -27.255,
            59.198,
            11.56,
            -27.398,
            58.819,
            11.56,
            -27.398,
            58.819,
            14.408,
            -27.398,
            58.819,
            14.408,
            -27.255,
            59.198,
            14.408,
            -27.255,
            59.198,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_14_3",
        "polygon": [
            -27.255,
            59.198,
            14.408,
            -27.398,
            58.819,
            14.408,
            -27.398,
            58.819,
            14.828,
            -27.398,
            58.819,
            14.828,
            -27.255,
            59.198,
            14.828,
            -27.255,
            59.198,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_12_1",
        "polygon": [
            -28.398,
            56.158,
            11.622,
            -28.696,
            55.364,
            11.622,
            -28.696,
            55.364,
            14.828,
            -28.696,
            55.364,
            14.828,
            -28.398,
            56.158,
            14.828,
            -28.398,
            56.158,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_12_2",
        "polygon": [
            -28.24,
            56.577,
            11.56,
            -28.383,
            56.198,
            11.56,
            -28.383,
            56.198,
            14.408,
            -28.383,
            56.198,
            14.408,
            -28.24,
            56.577,
            14.408,
            -28.24,
            56.577,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_12_3",
        "polygon": [
            -28.24,
            56.577,
            14.408,
            -28.383,
            56.198,
            14.408,
            -28.383,
            56.198,
            14.828,
            -28.383,
            56.198,
            14.828,
            -28.24,
            56.577,
            14.828,
            -28.24,
            56.577,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_10_1",
        "polygon": [
            -29.383,
            53.538,
            11.622,
            -29.682,
            52.743,
            11.622,
            -29.682,
            52.743,
            14.828,
            -29.682,
            52.743,
            14.828,
            -29.383,
            53.538,
            14.828,
            -29.383,
            53.538,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_10_2",
        "polygon": [
            -29.226,
            53.956,
            11.56,
            -29.368,
            53.577,
            11.56,
            -29.368,
            53.577,
            14.408,
            -29.368,
            53.577,
            14.408,
            -29.226,
            53.956,
            14.408,
            -29.226,
            53.956,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_10_3",
        "polygon": [
            -29.226,
            53.956,
            14.408,
            -29.368,
            53.577,
            14.408,
            -29.368,
            53.577,
            14.828,
            -29.368,
            53.577,
            14.828,
            -29.226,
            53.956,
            14.828,
            -29.226,
            53.956,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_8_1",
        "polygon": [
            -30.368,
            50.917,
            11.622,
            -30.667,
            50.122,
            11.622,
            -30.667,
            50.122,
            14.828,
            -30.667,
            50.122,
            14.828,
            -30.368,
            50.917,
            14.828,
            -30.368,
            50.917,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_8_2",
        "polygon": [
            -30.211,
            51.335,
            11.56,
            -30.354,
            50.956,
            11.56,
            -30.354,
            50.956,
            14.408,
            -30.354,
            50.956,
            14.408,
            -30.211,
            51.335,
            14.408,
            -30.211,
            51.335,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_8_3",
        "polygon": [
            -30.211,
            51.335,
            14.408,
            -30.354,
            50.956,
            14.408,
            -30.354,
            50.956,
            14.828,
            -30.354,
            50.956,
            14.828,
            -30.211,
            51.335,
            14.828,
            -30.211,
            51.335,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_6_1",
        "polygon": [
            -31.196,
            48.714,
            11.56,
            -31.339,
            48.335,
            11.56,
            -31.339,
            48.335,
            14.408,
            -31.339,
            48.335,
            14.408,
            -31.196,
            48.714,
            14.408,
            -31.196,
            48.714,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_6_2",
        "polygon": [
            -31.196,
            48.714,
            14.408,
            -31.339,
            48.335,
            14.408,
            -31.339,
            48.335,
            14.828,
            -31.339,
            48.335,
            14.828,
            -31.196,
            48.714,
            14.828,
            -31.196,
            48.714,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_6_3",
        "polygon": [
            -31.354,
            48.296,
            11.622,
            -31.653,
            47.501,
            11.622,
            -31.653,
            47.501,
            14.828,
            -31.653,
            47.501,
            14.828,
            -31.354,
            48.296,
            14.828,
            -31.354,
            48.296,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_4_1",
        "polygon": [
            -32.182,
            46.093,
            11.56,
            -32.324,
            45.714,
            11.56,
            -32.324,
            45.714,
            14.408,
            -32.324,
            45.714,
            14.408,
            -32.182,
            46.093,
            14.408,
            -32.182,
            46.093,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_4_2",
        "polygon": [
            -32.182,
            46.093,
            14.408,
            -32.324,
            45.714,
            14.408,
            -32.324,
            45.714,
            14.828,
            -32.324,
            45.714,
            14.828,
            -32.182,
            46.093,
            14.828,
            -32.182,
            46.093,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_4_3",
        "polygon": [
            -32.339,
            45.675,
            11.622,
            -32.638,
            44.88,
            11.622,
            -32.638,
            44.88,
            14.828,
            -32.638,
            44.88,
            14.828,
            -32.339,
            45.675,
            14.828,
            -32.339,
            45.675,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_2_1",
        "polygon": [
            -33.481,
            42.638,
            11.56,
            -33.623,
            42.259,
            11.56,
            -33.623,
            42.259,
            14.408,
            -33.623,
            42.259,
            14.408,
            -33.481,
            42.638,
            14.408,
            -33.481,
            42.638,
            11.56
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_2_2",
        "polygon": [
            -33.481,
            42.638,
            14.408,
            -33.623,
            42.259,
            14.408,
            -33.623,
            42.259,
            14.828,
            -33.623,
            42.259,
            14.828,
            -33.481,
            42.638,
            14.828,
            -33.481,
            42.638,
            14.408
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_3OG_2_3",
        "polygon": [
            -33.167,
            43.472,
            11.622,
            -33.466,
            42.678,
            11.622,
            -33.466,
            42.678,
            14.828,
            -33.466,
            42.678,
            14.828,
            -33.167,
            43.472,
            14.828,
            -33.167,
            43.472,
            11.622
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_16_1",
        "polygon": [
            -26.27,
            61.819,
            7.912,
            -26.568,
            61.024,
            7.912,
            -26.568,
            61.024,
            11.118,
            -26.568,
            61.024,
            11.118,
            -26.27,
            61.819,
            11.118,
            -26.27,
            61.819,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_16_2",
        "polygon": [
            -26.583,
            60.985,
            7.85,
            -26.726,
            60.606,
            7.85,
            -26.726,
            60.606,
            10.698,
            -26.726,
            60.606,
            10.698,
            -26.583,
            60.985,
            10.698,
            -26.583,
            60.985,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_16_3",
        "polygon": [
            -26.583,
            60.985,
            10.698,
            -26.726,
            60.606,
            10.698,
            -26.726,
            60.606,
            11.118,
            -26.726,
            60.606,
            11.118,
            -26.583,
            60.985,
            11.118,
            -26.583,
            60.985,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_14_1",
        "polygon": [
            -27.255,
            59.198,
            7.912,
            -27.554,
            58.403,
            7.912,
            -27.554,
            58.403,
            11.118,
            -27.554,
            58.403,
            11.118,
            -27.255,
            59.198,
            11.118,
            -27.255,
            59.198,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_14_2",
        "polygon": [
            -27.569,
            58.364,
            7.85,
            -27.711,
            57.985,
            7.85,
            -27.711,
            57.985,
            10.698,
            -27.711,
            57.985,
            10.698,
            -27.569,
            58.364,
            10.698,
            -27.569,
            58.364,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_14_3",
        "polygon": [
            -27.569,
            58.364,
            10.698,
            -27.711,
            57.985,
            10.698,
            -27.711,
            57.985,
            11.118,
            -27.711,
            57.985,
            11.118,
            -27.569,
            58.364,
            11.118,
            -27.569,
            58.364,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_12_1",
        "polygon": [
            -28.24,
            56.577,
            7.912,
            -28.539,
            55.782,
            7.912,
            -28.539,
            55.782,
            11.118,
            -28.539,
            55.782,
            11.118,
            -28.24,
            56.577,
            11.118,
            -28.24,
            56.577,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_12_2",
        "polygon": [
            -28.554,
            55.743,
            7.85,
            -28.696,
            55.364,
            7.85,
            -28.696,
            55.364,
            10.698,
            -28.696,
            55.364,
            10.698,
            -28.554,
            55.743,
            10.698,
            -28.554,
            55.743,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_12_3",
        "polygon": [
            -28.554,
            55.743,
            10.698,
            -28.696,
            55.364,
            10.698,
            -28.696,
            55.364,
            11.118,
            -28.696,
            55.364,
            11.118,
            -28.554,
            55.743,
            11.118,
            -28.554,
            55.743,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_10_1",
        "polygon": [
            -29.226,
            53.956,
            7.912,
            -29.525,
            53.161,
            7.912,
            -29.525,
            53.161,
            11.118,
            -29.525,
            53.161,
            11.118,
            -29.226,
            53.956,
            11.118,
            -29.226,
            53.956,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_10_2",
        "polygon": [
            -29.539,
            53.122,
            7.85,
            -29.682,
            52.743,
            7.85,
            -29.682,
            52.743,
            10.698,
            -29.682,
            52.743,
            10.698,
            -29.539,
            53.122,
            10.698,
            -29.539,
            53.122,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_10_3",
        "polygon": [
            -29.539,
            53.122,
            10.698,
            -29.682,
            52.743,
            10.698,
            -29.682,
            52.743,
            11.118,
            -29.682,
            52.743,
            11.118,
            -29.539,
            53.122,
            11.118,
            -29.539,
            53.122,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_8_1",
        "polygon": [
            -30.211,
            51.335,
            7.912,
            -30.51,
            50.54,
            7.912,
            -30.51,
            50.54,
            11.118,
            -30.51,
            50.54,
            11.118,
            -30.211,
            51.335,
            11.118,
            -30.211,
            51.335,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_8_2",
        "polygon": [
            -30.525,
            50.501,
            7.85,
            -30.667,
            50.122,
            7.85,
            -30.667,
            50.122,
            10.698,
            -30.667,
            50.122,
            10.698,
            -30.525,
            50.501,
            10.698,
            -30.525,
            50.501,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_8_3",
        "polygon": [
            -30.525,
            50.501,
            10.698,
            -30.667,
            50.122,
            10.698,
            -30.667,
            50.122,
            11.118,
            -30.667,
            50.122,
            11.118,
            -30.525,
            50.501,
            11.118,
            -30.525,
            50.501,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_6_1",
        "polygon": [
            -31.196,
            48.714,
            7.912,
            -31.495,
            47.92,
            7.912,
            -31.495,
            47.92,
            11.118,
            -31.495,
            47.92,
            11.118,
            -31.196,
            48.714,
            11.118,
            -31.196,
            48.714,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_6_2",
        "polygon": [
            -31.51,
            47.88,
            7.85,
            -31.653,
            47.501,
            7.85,
            -31.653,
            47.501,
            10.698,
            -31.653,
            47.501,
            10.698,
            -31.51,
            47.88,
            10.698,
            -31.51,
            47.88,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_6_3",
        "polygon": [
            -31.51,
            47.88,
            10.698,
            -31.653,
            47.501,
            10.698,
            -31.653,
            47.501,
            11.118,
            -31.653,
            47.501,
            11.118,
            -31.51,
            47.88,
            11.118,
            -31.51,
            47.88,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_4_1",
        "polygon": [
            -32.182,
            46.093,
            7.912,
            -32.481,
            45.299,
            7.912,
            -32.481,
            45.299,
            11.118,
            -32.481,
            45.299,
            11.118,
            -32.182,
            46.093,
            11.118,
            -32.182,
            46.093,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_4_2",
        "polygon": [
            -32.495,
            45.259,
            7.85,
            -32.638,
            44.88,
            7.85,
            -32.638,
            44.88,
            10.698,
            -32.638,
            44.88,
            10.698,
            -32.495,
            45.259,
            10.698,
            -32.495,
            45.259,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_4_3",
        "polygon": [
            -32.495,
            45.259,
            10.698,
            -32.638,
            44.88,
            10.698,
            -32.638,
            44.88,
            11.118,
            -32.638,
            44.88,
            11.118,
            -32.495,
            45.259,
            11.118,
            -32.495,
            45.259,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_2_1",
        "polygon": [
            -33.167,
            43.472,
            7.912,
            -33.466,
            42.678,
            7.912,
            -33.466,
            42.678,
            11.118,
            -33.466,
            42.678,
            11.118,
            -33.167,
            43.472,
            11.118,
            -33.167,
            43.472,
            7.912
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_2_2",
        "polygon": [
            -33.481,
            42.638,
            7.85,
            -33.623,
            42.259,
            7.85,
            -33.623,
            42.259,
            10.698,
            -33.623,
            42.259,
            10.698,
            -33.481,
            42.638,
            10.698,
            -33.481,
            42.638,
            7.85
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_2OG_2_3",
        "polygon": [
            -33.481,
            42.638,
            10.698,
            -33.623,
            42.259,
            10.698,
            -33.623,
            42.259,
            11.118,
            -33.623,
            42.259,
            11.118,
            -33.481,
            42.638,
            11.118,
            -33.481,
            42.638,
            10.698
        ],
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_8_1",
        "polygon": [
            -30.211,
            51.335,
            0.06,
            -30.667,
            50.122,
            0.06,
            -30.667,
            50.122,
            2.869,
            -30.667,
            50.122,
            2.869,
            -30.211,
            51.335,
            2.869,
            -30.211,
            51.335,
            0.06
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_8_2",
        "polygon": [
            -30.211,
            51.335,
            2.928,
            -30.667,
            50.121,
            2.928,
            -30.667,
            50.121,
            3.698,
            -30.667,
            50.121,
            3.698,
            -30.211,
            51.335,
            3.698,
            -30.211,
            51.335,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_6_1",
        "polygon": [
            -31.196,
            48.714,
            0.142,
            -31.495,
            47.92,
            0.142,
            -31.495,
            47.92,
            3.698,
            -31.495,
            47.92,
            3.698,
            -31.196,
            48.714,
            3.698,
            -31.196,
            48.714,
            0.142
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_6_2",
        "polygon": [
            -31.51,
            47.88,
            0.08,
            -31.653,
            47.501,
            0.08,
            -31.653,
            47.501,
            2.928,
            -31.653,
            47.501,
            2.928,
            -31.51,
            47.88,
            2.928,
            -31.51,
            47.88,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_6_3",
        "polygon": [
            -31.51,
            47.88,
            2.928,
            -31.653,
            47.501,
            2.928,
            -31.653,
            47.501,
            3.698,
            -31.653,
            47.501,
            3.698,
            -31.51,
            47.88,
            3.698,
            -31.51,
            47.88,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_4_1",
        "polygon": [
            -32.182,
            46.093,
            0.142,
            -32.481,
            45.299,
            0.142,
            -32.481,
            45.299,
            3.698,
            -32.481,
            45.299,
            3.698,
            -32.182,
            46.093,
            3.698,
            -32.182,
            46.093,
            0.142
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_4_2",
        "polygon": [
            -32.495,
            45.259,
            0.08,
            -32.638,
            44.88,
            0.08,
            -32.638,
            44.88,
            2.928,
            -32.638,
            44.88,
            2.928,
            -32.495,
            45.259,
            2.928,
            -32.495,
            45.259,
            0.08
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_4_3",
        "polygon": [
            -32.495,
            45.259,
            2.928,
            -32.638,
            44.88,
            2.928,
            -32.638,
            44.88,
            3.698,
            -32.638,
            44.88,
            3.698,
            -32.495,
            45.259,
            3.698,
            -32.495,
            45.259,
            2.928
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_2_1",
        "polygon": [
            -33.167,
            43.472,
            0.06,
            -33.623,
            42.259,
            0.06,
            -33.623,
            42.259,
            2.869,
            -33.623,
            42.259,
            2.869,
            -33.167,
            43.472,
            2.869,
            -33.167,
            43.472,
            0.06
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1
    },
    {
        "ID": "Nr.11-Ax14b D2_A4_EG_2_2",
        "polygon": [
            -33.167,
            43.472,
            2.928,
            -33.623,
            42.259,
            2.928,
            -33.623,
            42.259,
            3.698,
            -33.623,
            42.259,
            3.698,
            -33.167,
            43.472,
            3.698,
            -33.167,
            43.472,
            2.928
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 07:00": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_2_1",
        "polygon": [
            -10.248,
            0.998,
            2.928,
            -10.391,
            0.619,
            2.928,
            -10.391,
            0.619,
            3.698,
            -10.391,
            0.619,
            3.698,
            -10.248,
            0.998,
            3.698,
            -10.248,
            0.998,
            2.928
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_2_2",
        "polygon": [
            -10.248,
            0.998,
            0.08,
            -10.391,
            0.619,
            0.08,
            -10.391,
            0.619,
            2.928,
            -10.391,
            0.619,
            2.928,
            -10.248,
            0.998,
            2.928,
            -10.248,
            0.998,
            0.08
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_2_3",
        "polygon": [
            -9.935,
            1.832,
            0.142,
            -10.234,
            1.037,
            0.142,
            -10.234,
            1.037,
            3.698,
            -10.234,
            1.037,
            3.698,
            -9.935,
            1.832,
            3.698,
            -9.935,
            1.832,
            0.142
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_4_1",
        "polygon": [
            -9.263,
            3.619,
            2.928,
            -9.405,
            3.24,
            2.928,
            -9.405,
            3.24,
            3.698,
            -9.405,
            3.24,
            3.698,
            -9.263,
            3.619,
            3.698,
            -9.263,
            3.619,
            2.928
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_4_2",
        "polygon": [
            -9.263,
            3.619,
            0.08,
            -9.405,
            3.24,
            0.08,
            -9.405,
            3.24,
            2.928,
            -9.405,
            3.24,
            2.928,
            -9.263,
            3.619,
            2.928,
            -9.263,
            3.619,
            0.08
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_4_3",
        "polygon": [
            -8.949,
            4.453,
            0.142,
            -9.248,
            3.658,
            0.142,
            -9.248,
            3.658,
            3.698,
            -9.248,
            3.658,
            3.698,
            -8.949,
            4.453,
            3.698,
            -8.949,
            4.453,
            0.142
        ],
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_6_1",
        "polygon": [
            -8.278,
            6.24,
            2.928,
            -8.42,
            5.861,
            2.928,
            -8.42,
            5.861,
            3.698,
            -8.42,
            5.861,
            3.698,
            -8.278,
            6.24,
            3.698,
            -8.278,
            6.24,
            2.928
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_6_2",
        "polygon": [
            -8.278,
            6.24,
            0.08,
            -8.42,
            5.861,
            0.08,
            -8.42,
            5.861,
            2.928,
            -8.42,
            5.861,
            2.928,
            -8.278,
            6.24,
            2.928,
            -8.278,
            6.24,
            0.08
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_6_3",
        "polygon": [
            -7.964,
            7.074,
            0.142,
            -8.263,
            6.279,
            0.142,
            -8.263,
            6.279,
            3.698,
            -8.263,
            6.279,
            3.698,
            -7.964,
            7.074,
            3.698,
            -7.964,
            7.074,
            0.142
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_8_1",
        "polygon": [
            -7.292,
            8.861,
            2.928,
            -7.435,
            8.482,
            2.928,
            -7.435,
            8.482,
            3.698,
            -7.435,
            8.482,
            3.698,
            -7.292,
            8.861,
            3.698,
            -7.292,
            8.861,
            2.928
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_8_2",
        "polygon": [
            -7.292,
            8.861,
            0.08,
            -7.435,
            8.482,
            0.08,
            -7.435,
            8.482,
            2.928,
            -7.435,
            8.482,
            2.928,
            -7.292,
            8.861,
            2.928,
            -7.292,
            8.861,
            0.08
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_8_3",
        "polygon": [
            -6.979,
            9.695,
            0.142,
            -7.277,
            8.9,
            0.142,
            -7.277,
            8.9,
            3.698,
            -7.277,
            8.9,
            3.698,
            -6.979,
            9.695,
            3.698,
            -6.979,
            9.695,
            0.142
        ],
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_10_1",
        "polygon": [
            -6.307,
            11.482,
            2.928,
            -6.449,
            11.102,
            2.928,
            -6.449,
            11.102,
            3.698,
            -6.449,
            11.102,
            3.698,
            -6.307,
            11.482,
            3.698,
            -6.307,
            11.482,
            2.928
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_10_2",
        "polygon": [
            -6.307,
            11.482,
            0.08,
            -6.449,
            11.102,
            0.08,
            -6.449,
            11.102,
            2.928,
            -6.449,
            11.102,
            2.928,
            -6.307,
            11.482,
            2.928,
            -6.307,
            11.482,
            0.08
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_10_3",
        "polygon": [
            -5.993,
            12.316,
            0.142,
            -6.292,
            11.521,
            0.142,
            -6.292,
            11.521,
            3.698,
            -6.292,
            11.521,
            3.698,
            -5.993,
            12.316,
            3.698,
            -5.993,
            12.316,
            0.142
        ],
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_12_1",
        "polygon": [
            -5.322,
            14.102,
            2.928,
            -5.464,
            13.723,
            2.928,
            -5.464,
            13.723,
            3.698,
            -5.464,
            13.723,
            3.698,
            -5.322,
            14.102,
            3.698,
            -5.322,
            14.102,
            2.928
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_12_2",
        "polygon": [
            -5.322,
            14.102,
            0.08,
            -5.464,
            13.723,
            0.08,
            -5.464,
            13.723,
            2.928,
            -5.464,
            13.723,
            2.928,
            -5.322,
            14.102,
            2.928,
            -5.322,
            14.102,
            0.08
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_12_3",
        "polygon": [
            -5.008,
            14.936,
            0.142,
            -5.307,
            14.142,
            0.142,
            -5.307,
            14.142,
            3.698,
            -5.307,
            14.142,
            3.698,
            -5.008,
            14.936,
            3.698,
            -5.008,
            14.936,
            0.142
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_14_1",
        "polygon": [
            -4.022,
            17.558,
            2.928,
            -4.321,
            16.764,
            2.928,
            -4.321,
            16.764,
            3.698,
            -4.321,
            16.764,
            3.698,
            -4.022,
            17.558,
            3.698,
            -4.022,
            17.558,
            2.928
        ]
    },
    {
        "ID": "Nr.6-Ax20 F_J_EG_14_2",
        "polygon": [
            -4.336,
            16.724,
            2.928,
            -4.478,
            16.345,
            2.928,
            -4.478,
            16.345,
            3.698,
            -4.478,
            16.345,
            3.698,
            -4.336,
            16.724,
            3.698,
            -4.336,
            16.724,
            2.928
        ]
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_2_1",
        "polygon": [
            -9.934,
            1.833,
            6.988,
            -10.077,
            1.454,
            6.988,
            -10.077,
            1.454,
            7.408,
            -10.077,
            1.454,
            7.408,
            -9.934,
            1.833,
            7.408,
            -9.934,
            1.833,
            6.988
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_2_2",
        "polygon": [
            -9.934,
            1.833,
            4.14,
            -10.077,
            1.454,
            4.14,
            -10.077,
            1.454,
            6.988,
            -10.077,
            1.454,
            6.988,
            -9.934,
            1.833,
            6.988,
            -9.934,
            1.833,
            4.14
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_2_3",
        "polygon": [
            -10.092,
            1.414,
            4.202,
            -10.391,
            0.619,
            4.202,
            -10.391,
            0.619,
            7.408,
            -10.391,
            0.619,
            7.408,
            -10.092,
            1.414,
            7.408,
            -10.092,
            1.414,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_4_1",
        "polygon": [
            -8.949,
            4.454,
            6.988,
            -9.092,
            4.075,
            6.988,
            -9.092,
            4.075,
            7.408,
            -9.092,
            4.075,
            7.408,
            -8.949,
            4.454,
            7.408,
            -8.949,
            4.454,
            6.988
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_4_2",
        "polygon": [
            -8.949,
            4.454,
            4.14,
            -9.092,
            4.075,
            4.14,
            -9.092,
            4.075,
            6.988,
            -9.092,
            4.075,
            6.988,
            -8.949,
            4.454,
            6.988,
            -8.949,
            4.454,
            4.14
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_4_3",
        "polygon": [
            -9.107,
            4.034,
            4.202,
            -9.405,
            3.24,
            4.202,
            -9.405,
            3.24,
            7.408,
            -9.405,
            3.24,
            7.408,
            -9.107,
            4.034,
            7.408,
            -9.107,
            4.034,
            4.202
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_6_1",
        "polygon": [
            -7.964,
            7.075,
            6.988,
            -8.106,
            6.696,
            6.988,
            -8.106,
            6.696,
            7.408,
            -8.106,
            6.696,
            7.408,
            -7.964,
            7.075,
            7.408,
            -7.964,
            7.075,
            6.988
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_6_2",
        "polygon": [
            -7.964,
            7.075,
            4.14,
            -8.106,
            6.696,
            4.14,
            -8.106,
            6.696,
            6.988,
            -8.106,
            6.696,
            6.988,
            -7.964,
            7.075,
            6.988,
            -7.964,
            7.075,
            4.14
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_6_3",
        "polygon": [
            -8.121,
            6.655,
            4.202,
            -8.42,
            5.861,
            4.202,
            -8.42,
            5.861,
            7.408,
            -8.42,
            5.861,
            7.408,
            -8.121,
            6.655,
            7.408,
            -8.121,
            6.655,
            4.202
        ],
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_8_1",
        "polygon": [
            -6.978,
            9.696,
            6.988,
            -7.121,
            9.317,
            6.988,
            -7.121,
            9.317,
            7.408,
            -7.121,
            9.317,
            7.408,
            -6.978,
            9.696,
            7.408,
            -6.978,
            9.696,
            6.988
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_8_2",
        "polygon": [
            -6.978,
            9.696,
            4.14,
            -7.121,
            9.317,
            4.14,
            -7.121,
            9.317,
            6.988,
            -7.121,
            9.317,
            6.988,
            -6.978,
            9.696,
            6.988,
            -6.978,
            9.696,
            4.14
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_8_3",
        "polygon": [
            -7.136,
            9.276,
            4.202,
            -7.435,
            8.482,
            4.202,
            -7.435,
            8.482,
            7.408,
            -7.435,
            8.482,
            7.408,
            -7.136,
            9.276,
            7.408,
            -7.136,
            9.276,
            4.202
        ],
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_10_1",
        "polygon": [
            -5.993,
            12.317,
            6.988,
            -6.136,
            11.938,
            6.988,
            -6.136,
            11.938,
            7.408,
            -6.136,
            11.938,
            7.408,
            -5.993,
            12.317,
            7.408,
            -5.993,
            12.317,
            6.988
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_10_2",
        "polygon": [
            -5.993,
            12.317,
            4.14,
            -6.136,
            11.938,
            4.14,
            -6.136,
            11.938,
            6.988,
            -6.136,
            11.938,
            6.988,
            -5.993,
            12.317,
            6.988,
            -5.993,
            12.317,
            4.14
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_10_3",
        "polygon": [
            -6.151,
            11.897,
            4.202,
            -6.449,
            11.102,
            4.202,
            -6.449,
            11.102,
            7.408,
            -6.449,
            11.102,
            7.408,
            -6.151,
            11.897,
            7.408,
            -6.151,
            11.897,
            4.202
        ],
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_12_1",
        "polygon": [
            -5.008,
            14.937,
            6.988,
            -5.15,
            14.558,
            6.988,
            -5.15,
            14.558,
            7.408,
            -5.15,
            14.558,
            7.408,
            -5.008,
            14.937,
            7.408,
            -5.008,
            14.937,
            6.988
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_12_2",
        "polygon": [
            -5.008,
            14.937,
            4.14,
            -5.15,
            14.558,
            4.14,
            -5.15,
            14.558,
            6.988,
            -5.15,
            14.558,
            6.988,
            -5.008,
            14.937,
            6.988,
            -5.008,
            14.937,
            4.14
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_12_3",
        "polygon": [
            -5.165,
            14.518,
            4.202,
            -5.464,
            13.723,
            4.202,
            -5.464,
            13.723,
            7.408,
            -5.464,
            13.723,
            7.408,
            -5.165,
            14.518,
            7.408,
            -5.165,
            14.518,
            4.202
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_14_1",
        "polygon": [
            -4.022,
            17.558,
            6.988,
            -4.165,
            17.179,
            6.988,
            -4.165,
            17.179,
            7.408,
            -4.165,
            17.179,
            7.408,
            -4.022,
            17.558,
            7.408,
            -4.022,
            17.558,
            6.988
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_14_2",
        "polygon": [
            -4.022,
            17.558,
            4.14,
            -4.165,
            17.179,
            4.14,
            -4.165,
            17.179,
            6.988,
            -4.165,
            17.179,
            6.988,
            -4.022,
            17.558,
            6.988,
            -4.022,
            17.558,
            4.14
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_1OG_14_3",
        "polygon": [
            -4.18,
            17.139,
            4.202,
            -4.479,
            16.344,
            4.202,
            -4.479,
            16.344,
            7.408,
            -4.479,
            16.344,
            7.408,
            -4.18,
            17.139,
            7.408,
            -4.18,
            17.139,
            4.202
        ],
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_2_1",
        "polygon": [
            -9.934,
            1.833,
            14.408,
            -10.077,
            1.454,
            14.408,
            -10.077,
            1.454,
            14.828,
            -10.077,
            1.454,
            14.828,
            -9.934,
            1.833,
            14.828,
            -9.934,
            1.833,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_2_2",
        "polygon": [
            -9.934,
            1.833,
            11.56,
            -10.077,
            1.454,
            11.56,
            -10.077,
            1.454,
            14.408,
            -10.077,
            1.454,
            14.408,
            -9.934,
            1.833,
            14.408,
            -9.934,
            1.833,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_2_3",
        "polygon": [
            -10.092,
            1.414,
            11.622,
            -10.391,
            0.619,
            11.622,
            -10.391,
            0.619,
            14.828,
            -10.391,
            0.619,
            14.828,
            -10.092,
            1.414,
            14.828,
            -10.092,
            1.414,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_4_1",
        "polygon": [
            -8.949,
            4.454,
            14.408,
            -9.092,
            4.075,
            14.408,
            -9.092,
            4.075,
            14.828,
            -9.092,
            4.075,
            14.828,
            -8.949,
            4.454,
            14.828,
            -8.949,
            4.454,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_4_2",
        "polygon": [
            -8.949,
            4.454,
            11.56,
            -9.092,
            4.075,
            11.56,
            -9.092,
            4.075,
            14.408,
            -9.092,
            4.075,
            14.408,
            -8.949,
            4.454,
            14.408,
            -8.949,
            4.454,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_4_3",
        "polygon": [
            -9.107,
            4.034,
            11.622,
            -9.405,
            3.24,
            11.622,
            -9.405,
            3.24,
            14.828,
            -9.405,
            3.24,
            14.828,
            -9.107,
            4.034,
            14.828,
            -9.107,
            4.034,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_6_1",
        "polygon": [
            -7.964,
            7.075,
            14.408,
            -8.106,
            6.696,
            14.408,
            -8.106,
            6.696,
            14.828,
            -8.106,
            6.696,
            14.828,
            -7.964,
            7.075,
            14.828,
            -7.964,
            7.075,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_6_2",
        "polygon": [
            -7.964,
            7.075,
            11.56,
            -8.106,
            6.696,
            11.56,
            -8.106,
            6.696,
            14.408,
            -8.106,
            6.696,
            14.408,
            -7.964,
            7.075,
            14.408,
            -7.964,
            7.075,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_6_3",
        "polygon": [
            -8.121,
            6.655,
            11.622,
            -8.42,
            5.861,
            11.622,
            -8.42,
            5.861,
            14.828,
            -8.42,
            5.861,
            14.828,
            -8.121,
            6.655,
            14.828,
            -8.121,
            6.655,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_8_1",
        "polygon": [
            -6.978,
            9.696,
            14.408,
            -7.121,
            9.317,
            14.408,
            -7.121,
            9.317,
            14.828,
            -7.121,
            9.317,
            14.828,
            -6.978,
            9.696,
            14.828,
            -6.978,
            9.696,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_8_2",
        "polygon": [
            -6.978,
            9.696,
            11.56,
            -7.121,
            9.317,
            11.56,
            -7.121,
            9.317,
            14.408,
            -7.121,
            9.317,
            14.408,
            -6.978,
            9.696,
            14.408,
            -6.978,
            9.696,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_8_3",
        "polygon": [
            -7.136,
            9.276,
            11.622,
            -7.435,
            8.482,
            11.622,
            -7.435,
            8.482,
            14.828,
            -7.435,
            8.482,
            14.828,
            -7.136,
            9.276,
            14.828,
            -7.136,
            9.276,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_10_1",
        "polygon": [
            -5.993,
            12.317,
            14.408,
            -6.136,
            11.938,
            14.408,
            -6.136,
            11.938,
            14.828,
            -6.136,
            11.938,
            14.828,
            -5.993,
            12.317,
            14.828,
            -5.993,
            12.317,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_10_2",
        "polygon": [
            -5.993,
            12.317,
            11.56,
            -6.136,
            11.938,
            11.56,
            -6.136,
            11.938,
            14.408,
            -6.136,
            11.938,
            14.408,
            -5.993,
            12.317,
            14.408,
            -5.993,
            12.317,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_10_3",
        "polygon": [
            -6.151,
            11.897,
            11.622,
            -6.449,
            11.102,
            11.622,
            -6.449,
            11.102,
            14.828,
            -6.449,
            11.102,
            14.828,
            -6.151,
            11.897,
            14.828,
            -6.151,
            11.897,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_12_1",
        "polygon": [
            -5.008,
            14.937,
            14.408,
            -5.15,
            14.558,
            14.408,
            -5.15,
            14.558,
            14.828,
            -5.15,
            14.558,
            14.828,
            -5.008,
            14.937,
            14.828,
            -5.008,
            14.937,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_12_2",
        "polygon": [
            -5.008,
            14.937,
            11.56,
            -5.15,
            14.558,
            11.56,
            -5.15,
            14.558,
            14.408,
            -5.15,
            14.558,
            14.408,
            -5.008,
            14.937,
            14.408,
            -5.008,
            14.937,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_12_3",
        "polygon": [
            -5.165,
            14.518,
            11.622,
            -5.464,
            13.723,
            11.622,
            -5.464,
            13.723,
            14.828,
            -5.464,
            13.723,
            14.828,
            -5.165,
            14.518,
            14.828,
            -5.165,
            14.518,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_14_1",
        "polygon": [
            -4.022,
            17.558,
            14.408,
            -4.165,
            17.179,
            14.408,
            -4.165,
            17.179,
            14.828,
            -4.165,
            17.179,
            14.828,
            -4.022,
            17.558,
            14.828,
            -4.022,
            17.558,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_14_2",
        "polygon": [
            -4.022,
            17.558,
            11.56,
            -4.165,
            17.179,
            11.56,
            -4.165,
            17.179,
            14.408,
            -4.165,
            17.179,
            14.408,
            -4.022,
            17.558,
            14.408,
            -4.022,
            17.558,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_3OG_14_3",
        "polygon": [
            -4.18,
            17.139,
            11.622,
            -4.479,
            16.344,
            11.622,
            -4.479,
            16.344,
            14.828,
            -4.479,
            16.344,
            14.828,
            -4.18,
            17.139,
            14.828,
            -4.18,
            17.139,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_10_1",
        "polygon": [
            1.083,
            31.138,
            14.408,
            0.941,
            30.759,
            14.408,
            0.941,
            30.759,
            14.828,
            0.941,
            30.759,
            14.828,
            1.083,
            31.138,
            14.828,
            1.083,
            31.138,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_10_2",
        "polygon": [
            1.083,
            31.138,
            11.56,
            0.941,
            30.759,
            11.56,
            0.941,
            30.759,
            14.408,
            0.941,
            30.759,
            14.408,
            1.083,
            31.138,
            14.408,
            1.083,
            31.138,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_10_3",
        "polygon": [
            1.407,
            32.0,
            11.622,
            1.108,
            31.205,
            11.622,
            1.108,
            31.205,
            14.828,
            1.108,
            31.205,
            14.828,
            1.407,
            32.0,
            14.828,
            1.407,
            32.0,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_11_1",
        "polygon": [
            1.89,
            33.284,
            14.408,
            1.747,
            32.905,
            14.408,
            1.747,
            32.905,
            14.828,
            1.747,
            32.905,
            14.828,
            1.89,
            33.284,
            14.828,
            1.89,
            33.284,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_11_2",
        "polygon": [
            1.89,
            33.284,
            11.56,
            1.747,
            32.905,
            11.56,
            1.747,
            32.905,
            14.408,
            1.747,
            32.905,
            14.408,
            1.89,
            33.284,
            14.408,
            1.89,
            33.284,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_11_3",
        "polygon": [
            1.733,
            32.865,
            11.622,
            1.434,
            32.071,
            11.622,
            1.434,
            32.071,
            14.828,
            1.434,
            32.071,
            14.828,
            1.733,
            32.865,
            14.828,
            1.733,
            32.865,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_13_1",
        "polygon": [
            2.875,
            35.905,
            14.408,
            2.733,
            35.526,
            14.408,
            2.733,
            35.526,
            14.828,
            2.733,
            35.526,
            14.828,
            2.875,
            35.905,
            14.828,
            2.875,
            35.905,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_13_2",
        "polygon": [
            2.875,
            35.905,
            11.56,
            2.733,
            35.526,
            11.56,
            2.733,
            35.526,
            14.408,
            2.733,
            35.526,
            14.408,
            2.875,
            35.905,
            14.408,
            2.875,
            35.905,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_13_3",
        "polygon": [
            2.718,
            35.486,
            11.622,
            2.419,
            34.692,
            11.622,
            2.419,
            34.692,
            14.828,
            2.419,
            34.692,
            14.828,
            2.718,
            35.486,
            14.828,
            2.718,
            35.486,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_15_1",
        "polygon": [
            3.861,
            38.526,
            14.408,
            3.718,
            38.146,
            14.408,
            3.718,
            38.146,
            14.828,
            3.718,
            38.146,
            14.828,
            3.861,
            38.526,
            14.828,
            3.861,
            38.526,
            14.408
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_15_2",
        "polygon": [
            3.861,
            38.526,
            11.56,
            3.718,
            38.146,
            11.56,
            3.718,
            38.146,
            14.408,
            3.718,
            38.146,
            14.408,
            3.861,
            38.526,
            14.408,
            3.861,
            38.526,
            11.56
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.15-Ax20 B3_F-3.OG_3OG_15_3",
        "polygon": [
            3.703,
            38.107,
            11.622,
            3.404,
            37.312,
            11.622,
            3.404,
            37.312,
            14.828,
            3.404,
            37.312,
            14.828,
            3.703,
            38.107,
            14.828,
            3.703,
            38.107,
            11.622
        ],
        "05-24 04:00": 1,
        "05-24 04:15": 1,
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_2_1",
        "polygon": [
            -10.248,
            0.998,
            10.698,
            -10.391,
            0.619,
            10.698,
            -10.391,
            0.619,
            11.118,
            -10.391,
            0.619,
            11.118,
            -10.248,
            0.998,
            11.118,
            -10.248,
            0.998,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_2_2",
        "polygon": [
            -10.248,
            0.998,
            7.85,
            -10.391,
            0.619,
            7.85,
            -10.391,
            0.619,
            10.698,
            -10.391,
            0.619,
            10.698,
            -10.248,
            0.998,
            10.698,
            -10.248,
            0.998,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_2_3",
        "polygon": [
            -9.934,
            1.833,
            7.912,
            -10.233,
            1.038,
            7.912,
            -10.233,
            1.038,
            11.118,
            -10.233,
            1.038,
            11.118,
            -9.934,
            1.833,
            11.118,
            -9.934,
            1.833,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_4_1",
        "polygon": [
            -9.263,
            3.62,
            10.698,
            -9.405,
            3.241,
            10.698,
            -9.405,
            3.241,
            11.118,
            -9.405,
            3.241,
            11.118,
            -9.263,
            3.62,
            11.118,
            -9.263,
            3.62,
            10.698
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_4_2",
        "polygon": [
            -9.263,
            3.62,
            7.85,
            -9.405,
            3.241,
            7.85,
            -9.405,
            3.241,
            10.698,
            -9.405,
            3.241,
            10.698,
            -9.263,
            3.62,
            10.698,
            -9.263,
            3.62,
            7.85
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_4_3",
        "polygon": [
            -8.949,
            4.455,
            7.912,
            -9.247,
            3.66,
            7.912,
            -9.247,
            3.66,
            11.118,
            -9.247,
            3.66,
            11.118,
            -8.949,
            4.455,
            11.118,
            -8.949,
            4.455,
            7.912
        ],
        "05-24 04:30": 1,
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_6_1",
        "polygon": [
            -8.277,
            6.241,
            10.698,
            -8.42,
            5.862,
            10.698,
            -8.42,
            5.862,
            11.118,
            -8.42,
            5.862,
            11.118,
            -8.277,
            6.241,
            11.118,
            -8.277,
            6.241,
            10.698
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_6_2",
        "polygon": [
            -8.277,
            6.241,
            7.85,
            -8.42,
            5.862,
            7.85,
            -8.42,
            5.862,
            10.698,
            -8.42,
            5.862,
            10.698,
            -8.277,
            6.241,
            10.698,
            -8.277,
            6.241,
            7.85
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_6_3",
        "polygon": [
            -7.963,
            7.076,
            7.912,
            -8.262,
            6.281,
            7.912,
            -8.262,
            6.281,
            11.118,
            -8.262,
            6.281,
            11.118,
            -7.963,
            7.076,
            11.118,
            -7.963,
            7.076,
            7.912
        ],
        "05-24 04:45": 1,
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_8_1",
        "polygon": [
            -7.292,
            8.862,
            10.698,
            -7.434,
            8.483,
            10.698,
            -7.434,
            8.483,
            11.118,
            -7.434,
            8.483,
            11.118,
            -7.292,
            8.862,
            11.118,
            -7.292,
            8.862,
            10.698
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_8_2",
        "polygon": [
            -7.292,
            8.862,
            7.85,
            -7.434,
            8.483,
            7.85,
            -7.434,
            8.483,
            10.698,
            -7.434,
            8.483,
            10.698,
            -7.292,
            8.862,
            10.698,
            -7.292,
            8.862,
            7.85
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_8_3",
        "polygon": [
            -6.978,
            9.697,
            7.912,
            -7.277,
            8.902,
            7.912,
            -7.277,
            8.902,
            11.118,
            -7.277,
            8.902,
            11.118,
            -6.978,
            9.697,
            11.118,
            -6.978,
            9.697,
            7.912
        ],
        "05-24 05:00": 1,
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_10_1",
        "polygon": [
            -6.307,
            11.483,
            10.698,
            -6.449,
            11.104,
            10.698,
            -6.449,
            11.104,
            11.118,
            -6.449,
            11.104,
            11.118,
            -6.307,
            11.483,
            11.118,
            -6.307,
            11.483,
            10.698
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_10_2",
        "polygon": [
            -6.307,
            11.483,
            7.85,
            -6.449,
            11.104,
            7.85,
            -6.449,
            11.104,
            10.698,
            -6.449,
            11.104,
            10.698,
            -6.307,
            11.483,
            10.698,
            -6.307,
            11.483,
            7.85
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_10_3",
        "polygon": [
            -5.993,
            12.318,
            7.912,
            -6.291,
            11.523,
            7.912,
            -6.291,
            11.523,
            11.118,
            -6.291,
            11.523,
            11.118,
            -5.993,
            12.318,
            11.118,
            -5.993,
            12.318,
            7.912
        ],
        "05-24 05:15": 1,
        "05-24 05:30": 1,
        "05-24 05:45": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_12_1",
        "polygon": [
            -5.321,
            14.103,
            10.698,
            -5.464,
            13.724,
            10.698,
            -5.464,
            13.724,
            11.118,
            -5.464,
            13.724,
            11.118,
            -5.321,
            14.103,
            11.118,
            -5.321,
            14.103,
            10.698
        ],
        "05-24 05:30": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_12_2",
        "polygon": [
            -5.321,
            14.103,
            7.85,
            -5.464,
            13.724,
            7.85,
            -5.464,
            13.724,
            10.698,
            -5.464,
            13.724,
            10.698,
            -5.321,
            14.103,
            10.698,
            -5.321,
            14.103,
            7.85
        ],
        "05-24 05:30": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_12_3",
        "polygon": [
            -5.007,
            14.939,
            7.912,
            -5.306,
            14.144,
            7.912,
            -5.306,
            14.144,
            11.118,
            -5.306,
            14.144,
            11.118,
            -5.007,
            14.939,
            11.118,
            -5.007,
            14.939,
            7.912
        ],
        "05-24 05:30": 1,
        "05-24 06:00": 1,
        "05-24 06:15": 1,
        "05-24 06:30": 1,
        "05-24 06:45": 1,
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_14_1",
        "polygon": [
            -4.336,
            16.724,
            10.698,
            -4.478,
            16.345,
            10.698,
            -4.478,
            16.345,
            11.118,
            -4.478,
            16.345,
            11.118,
            -4.336,
            16.724,
            11.118,
            -4.336,
            16.724,
            10.698
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_14_2",
        "polygon": [
            -4.336,
            16.724,
            7.85,
            -4.478,
            16.345,
            7.85,
            -4.478,
            16.345,
            10.698,
            -4.478,
            16.345,
            10.698,
            -4.336,
            16.724,
            10.698,
            -4.336,
            16.724,
            7.85
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.6-Ax20 F_J_2OG_14_3",
        "polygon": [
            -4.022,
            17.559,
            7.912,
            -4.321,
            16.765,
            7.912,
            -4.321,
            16.765,
            11.118,
            -4.321,
            16.765,
            11.118,
            -4.022,
            17.559,
            11.118,
            -4.022,
            17.559,
            7.912
        ],
        "05-24 07:00": 1,
        "05-24 07:15": 1,
        "05-24 07:30": 1,
        "05-24 07:45": 1,
        "05-24 08:00": 1,
        "05-24 08:15": 1,
        "05-24 08:30": 1,
        "05-24 08:45": 1,
        "05-24 09:00": 1,
        "05-24 09:15": 1,
        "05-24 09:30": 1,
        "05-24 09:45": 1,
        "05-24 10:00": 1,
        "05-24 10:15": 1,
        "05-24 10:30": 1,
        "05-24 10:45": 1,
        "05-24 11:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_15_1",
        "polygon": [
            16.935,
            -9.795,
            2.928,
            16.984,
            -9.393,
            2.928,
            16.984,
            -9.393,
            3.698,
            16.984,
            -9.393,
            3.698,
            16.935,
            -9.795,
            3.698,
            16.935,
            -9.795,
            2.928
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_15_2",
        "polygon": [
            16.935,
            -9.795,
            0.08,
            16.984,
            -9.393,
            0.08,
            16.984,
            -9.393,
            2.928,
            16.984,
            -9.393,
            2.928,
            16.935,
            -9.795,
            2.928,
            16.935,
            -9.795,
            0.08
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_15_3",
        "polygon": [
            16.827,
            -10.679,
            0.142,
            16.93,
            -9.837,
            0.142,
            16.93,
            -9.837,
            3.698,
            16.93,
            -9.837,
            3.698,
            16.827,
            -10.679,
            3.698,
            16.827,
            -10.679,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_15_4",
        "polygon": [
            16.754,
            -11.28,
            0.142,
            16.815,
            -10.784,
            0.142,
            16.815,
            -10.784,
            3.698,
            16.815,
            -10.784,
            3.698,
            16.754,
            -11.28,
            3.698,
            16.754,
            -11.28,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_13_1",
        "polygon": [
            17.274,
            -7.017,
            2.928,
            17.323,
            -6.615,
            2.928,
            17.323,
            -6.615,
            3.698,
            17.323,
            -6.615,
            3.698,
            17.274,
            -7.017,
            3.698,
            17.274,
            -7.017,
            2.928
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_13_2",
        "polygon": [
            17.274,
            -7.017,
            0.08,
            17.323,
            -6.615,
            0.08,
            17.323,
            -6.615,
            2.928,
            17.323,
            -6.615,
            2.928,
            17.274,
            -7.017,
            2.928,
            17.274,
            -7.017,
            0.08
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_13_3",
        "polygon": [
            17.166,
            -7.901,
            0.142,
            17.269,
            -7.058,
            0.142,
            17.269,
            -7.058,
            3.698,
            17.269,
            -7.058,
            3.698,
            17.166,
            -7.901,
            3.698,
            17.166,
            -7.901,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_11_1",
        "polygon": [
            17.613,
            -4.237,
            2.928,
            17.662,
            -3.835,
            2.928,
            17.662,
            -3.835,
            3.698,
            17.662,
            -3.835,
            3.698,
            17.613,
            -4.237,
            3.698,
            17.613,
            -4.237,
            2.928
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_11_2",
        "polygon": [
            17.613,
            -4.237,
            0.08,
            17.662,
            -3.835,
            0.08,
            17.662,
            -3.835,
            2.928,
            17.662,
            -3.835,
            2.928,
            17.613,
            -4.237,
            2.928,
            17.613,
            -4.237,
            0.08
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_11_3",
        "polygon": [
            17.505,
            -5.122,
            0.142,
            17.608,
            -4.279,
            0.142,
            17.608,
            -4.279,
            3.698,
            17.608,
            -4.279,
            3.698,
            17.505,
            -5.122,
            3.698,
            17.505,
            -5.122,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_9_1",
        "polygon": [
            17.952,
            -1.458,
            2.928,
            18.001,
            -1.056,
            2.928,
            18.001,
            -1.056,
            3.698,
            18.001,
            -1.056,
            3.698,
            17.952,
            -1.458,
            3.698,
            17.952,
            -1.458,
            2.928
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_9_2",
        "polygon": [
            17.952,
            -1.458,
            0.08,
            18.001,
            -1.056,
            0.08,
            18.001,
            -1.056,
            2.928,
            18.001,
            -1.056,
            2.928,
            17.952,
            -1.458,
            2.928,
            17.952,
            -1.458,
            0.08
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_9_3",
        "polygon": [
            17.844,
            -2.342,
            0.142,
            17.947,
            -1.499,
            0.142,
            17.947,
            -1.499,
            3.698,
            17.947,
            -1.499,
            3.698,
            17.844,
            -2.342,
            3.698,
            17.844,
            -2.342,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_7_1",
        "polygon": [
            18.291,
            1.322,
            2.928,
            18.34,
            1.724,
            2.928,
            18.34,
            1.724,
            3.698,
            18.34,
            1.724,
            3.698,
            18.291,
            1.322,
            3.698,
            18.291,
            1.322,
            2.928
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_7_2",
        "polygon": [
            18.291,
            1.322,
            0.08,
            18.34,
            1.724,
            0.08,
            18.34,
            1.724,
            2.928,
            18.34,
            1.724,
            2.928,
            18.291,
            1.322,
            2.928,
            18.291,
            1.322,
            0.08
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_7_3",
        "polygon": [
            18.183,
            0.437,
            0.142,
            18.286,
            1.28,
            0.142,
            18.286,
            1.28,
            3.698,
            18.286,
            1.28,
            3.698,
            18.183,
            0.437,
            3.698,
            18.183,
            0.437,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_5_1",
        "polygon": [
            18.63,
            4.101,
            2.928,
            18.679,
            4.503,
            2.928,
            18.679,
            4.503,
            3.698,
            18.679,
            4.503,
            3.698,
            18.63,
            4.101,
            3.698,
            18.63,
            4.101,
            2.928
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_5_2",
        "polygon": [
            18.63,
            4.101,
            0.08,
            18.679,
            4.503,
            0.08,
            18.679,
            4.503,
            2.928,
            18.679,
            4.503,
            2.928,
            18.63,
            4.101,
            2.928,
            18.63,
            4.101,
            0.08
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_5_3",
        "polygon": [
            18.522,
            3.217,
            0.142,
            18.625,
            4.059,
            0.142,
            18.625,
            4.059,
            3.698,
            18.625,
            4.059,
            3.698,
            18.522,
            3.217,
            3.698,
            18.522,
            3.217,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_2_1",
        "polygon": [
            19.031,
            7.386,
            0.06,
            19.188,
            8.672,
            0.06,
            19.188,
            8.672,
            2.908,
            19.188,
            8.672,
            2.908,
            19.031,
            7.386,
            2.908,
            19.031,
            7.386,
            0.06
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_2_2",
        "polygon": [
            19.031,
            7.386,
            2.928,
            19.188,
            8.672,
            2.928,
            19.188,
            8.672,
            3.698,
            19.188,
            8.672,
            3.698,
            19.031,
            7.386,
            3.698,
            19.031,
            7.386,
            2.928
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_3_1",
        "polygon": [
            18.969,
            6.88,
            2.928,
            19.018,
            7.282,
            2.928,
            19.018,
            7.282,
            3.698,
            19.018,
            7.282,
            3.698,
            18.969,
            6.88,
            3.698,
            18.969,
            6.88,
            2.928
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_3_2",
        "polygon": [
            18.969,
            6.88,
            0.08,
            19.018,
            7.282,
            0.08,
            19.018,
            7.282,
            2.928,
            19.018,
            7.282,
            2.928,
            18.969,
            6.88,
            2.928,
            18.969,
            6.88,
            0.08
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_EG_3_3",
        "polygon": [
            18.861,
            5.996,
            0.142,
            18.964,
            6.839,
            0.142,
            18.964,
            6.839,
            3.698,
            18.964,
            6.839,
            3.698,
            18.861,
            5.996,
            3.698,
            18.861,
            5.996,
            0.142
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_15_1",
        "polygon": [
            16.935,
            -9.795,
            10.698,
            16.984,
            -9.393,
            10.698,
            16.984,
            -9.393,
            11.118,
            16.984,
            -9.393,
            11.118,
            16.935,
            -9.795,
            11.118,
            16.935,
            -9.795,
            10.698
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_15_2",
        "polygon": [
            16.935,
            -9.795,
            7.85,
            16.984,
            -9.393,
            7.85,
            16.984,
            -9.393,
            10.698,
            16.984,
            -9.393,
            10.698,
            16.935,
            -9.795,
            10.698,
            16.935,
            -9.795,
            7.85
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_15_3",
        "polygon": [
            16.827,
            -10.679,
            7.912,
            16.93,
            -9.837,
            7.912,
            16.93,
            -9.837,
            11.118,
            16.93,
            -9.837,
            11.118,
            16.827,
            -10.679,
            11.118,
            16.827,
            -10.679,
            7.912
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_15_4",
        "polygon": [
            16.754,
            -11.28,
            7.912,
            16.815,
            -10.784,
            7.912,
            16.815,
            -10.784,
            11.118,
            16.815,
            -10.784,
            11.118,
            16.754,
            -11.28,
            11.118,
            16.754,
            -11.28,
            7.912
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_13_1",
        "polygon": [
            17.274,
            -7.017,
            10.698,
            17.323,
            -6.615,
            10.698,
            17.323,
            -6.615,
            11.118,
            17.323,
            -6.615,
            11.118,
            17.274,
            -7.017,
            11.118,
            17.274,
            -7.017,
            10.698
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_13_2",
        "polygon": [
            17.274,
            -7.017,
            7.85,
            17.323,
            -6.615,
            7.85,
            17.323,
            -6.615,
            10.698,
            17.323,
            -6.615,
            10.698,
            17.274,
            -7.017,
            10.698,
            17.274,
            -7.017,
            7.85
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_13_3",
        "polygon": [
            17.166,
            -7.901,
            7.912,
            17.269,
            -7.058,
            7.912,
            17.269,
            -7.058,
            11.118,
            17.269,
            -7.058,
            11.118,
            17.166,
            -7.901,
            11.118,
            17.166,
            -7.901,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1,
        "05-24 18:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_11_1",
        "polygon": [
            17.613,
            -4.237,
            10.698,
            17.662,
            -3.835,
            10.698,
            17.662,
            -3.835,
            11.118,
            17.662,
            -3.835,
            11.118,
            17.613,
            -4.237,
            11.118,
            17.613,
            -4.237,
            10.698
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_11_2",
        "polygon": [
            17.613,
            -4.237,
            7.85,
            17.662,
            -3.835,
            7.85,
            17.662,
            -3.835,
            10.698,
            17.662,
            -3.835,
            10.698,
            17.613,
            -4.237,
            10.698,
            17.613,
            -4.237,
            7.85
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_11_3",
        "polygon": [
            17.505,
            -5.122,
            7.912,
            17.608,
            -4.279,
            7.912,
            17.608,
            -4.279,
            11.118,
            17.608,
            -4.279,
            11.118,
            17.505,
            -5.122,
            11.118,
            17.505,
            -5.122,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_9_1",
        "polygon": [
            17.952,
            -1.458,
            10.698,
            18.001,
            -1.056,
            10.698,
            18.001,
            -1.056,
            11.118,
            18.001,
            -1.056,
            11.118,
            17.952,
            -1.458,
            11.118,
            17.952,
            -1.458,
            10.698
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_9_2",
        "polygon": [
            17.952,
            -1.458,
            7.85,
            18.001,
            -1.056,
            7.85,
            18.001,
            -1.056,
            10.698,
            18.001,
            -1.056,
            10.698,
            17.952,
            -1.458,
            10.698,
            17.952,
            -1.458,
            7.85
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_9_3",
        "polygon": [
            17.844,
            -2.342,
            7.912,
            17.947,
            -1.499,
            7.912,
            17.947,
            -1.499,
            11.118,
            17.947,
            -1.499,
            11.118,
            17.844,
            -2.342,
            11.118,
            17.844,
            -2.342,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_7_1",
        "polygon": [
            18.291,
            1.322,
            10.698,
            18.34,
            1.724,
            10.698,
            18.34,
            1.724,
            11.118,
            18.34,
            1.724,
            11.118,
            18.291,
            1.322,
            11.118,
            18.291,
            1.322,
            10.698
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_7_2",
        "polygon": [
            18.291,
            1.322,
            7.85,
            18.34,
            1.724,
            7.85,
            18.34,
            1.724,
            10.698,
            18.34,
            1.724,
            10.698,
            18.291,
            1.322,
            10.698,
            18.291,
            1.322,
            7.85
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_7_3",
        "polygon": [
            18.183,
            0.437,
            7.912,
            18.286,
            1.28,
            7.912,
            18.286,
            1.28,
            11.118,
            18.286,
            1.28,
            11.118,
            18.183,
            0.437,
            11.118,
            18.183,
            0.437,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_5_1",
        "polygon": [
            18.63,
            4.101,
            10.698,
            18.679,
            4.503,
            10.698,
            18.679,
            4.503,
            11.118,
            18.679,
            4.503,
            11.118,
            18.63,
            4.101,
            11.118,
            18.63,
            4.101,
            10.698
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_5_2",
        "polygon": [
            18.63,
            4.101,
            7.85,
            18.679,
            4.503,
            7.85,
            18.679,
            4.503,
            10.698,
            18.679,
            4.503,
            10.698,
            18.63,
            4.101,
            10.698,
            18.63,
            4.101,
            7.85
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_5_3",
        "polygon": [
            18.522,
            3.217,
            7.912,
            18.625,
            4.059,
            7.912,
            18.625,
            4.059,
            11.118,
            18.625,
            4.059,
            11.118,
            18.522,
            3.217,
            11.118,
            18.522,
            3.217,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_3_1",
        "polygon": [
            18.969,
            6.88,
            10.698,
            19.018,
            7.282,
            10.698,
            19.018,
            7.282,
            11.118,
            19.018,
            7.282,
            11.118,
            18.969,
            6.88,
            11.118,
            18.969,
            6.88,
            10.698
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_3_2",
        "polygon": [
            18.969,
            6.88,
            7.85,
            19.018,
            7.282,
            7.85,
            19.018,
            7.282,
            10.698,
            19.018,
            7.282,
            10.698,
            18.969,
            6.88,
            10.698,
            18.969,
            6.88,
            7.85
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_3_3",
        "polygon": [
            18.861,
            5.996,
            7.912,
            18.964,
            6.839,
            7.912,
            18.964,
            6.839,
            11.118,
            18.964,
            6.839,
            11.118,
            18.861,
            5.996,
            11.118,
            18.861,
            5.996,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_15_1",
        "polygon": [
            16.827,
            -10.68,
            6.988,
            16.876,
            -10.278,
            6.988,
            16.876,
            -10.278,
            7.408,
            16.876,
            -10.278,
            7.408,
            16.827,
            -10.68,
            7.408,
            16.827,
            -10.68,
            6.988
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_15_2",
        "polygon": [
            16.827,
            -10.68,
            4.14,
            16.876,
            -10.278,
            4.14,
            16.876,
            -10.278,
            6.988,
            16.876,
            -10.278,
            6.988,
            16.827,
            -10.68,
            6.988,
            16.827,
            -10.68,
            4.14
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_15_3",
        "polygon": [
            16.881,
            -10.237,
            4.202,
            16.984,
            -9.394,
            4.202,
            16.984,
            -9.394,
            7.408,
            16.984,
            -9.394,
            7.408,
            16.881,
            -10.237,
            7.408,
            16.881,
            -10.237,
            4.202
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_15_4",
        "polygon": [
            16.754,
            -11.28,
            4.202,
            16.815,
            -10.784,
            4.202,
            16.815,
            -10.784,
            7.408,
            16.815,
            -10.784,
            7.408,
            16.754,
            -11.28,
            7.408,
            16.754,
            -11.28,
            4.202
        ],
        "05-24 13:00": 1,
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_13_1",
        "polygon": [
            17.166,
            -7.901,
            6.988,
            17.215,
            -7.499,
            6.988,
            17.215,
            -7.499,
            7.408,
            17.215,
            -7.499,
            7.408,
            17.166,
            -7.901,
            7.408,
            17.166,
            -7.901,
            6.988
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_13_2",
        "polygon": [
            17.166,
            -7.901,
            4.14,
            17.215,
            -7.499,
            4.14,
            17.215,
            -7.499,
            6.988,
            17.215,
            -7.499,
            6.988,
            17.166,
            -7.901,
            6.988,
            17.166,
            -7.901,
            4.14
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_13_3",
        "polygon": [
            17.22,
            -7.457,
            4.202,
            17.323,
            -6.615,
            4.202,
            17.323,
            -6.615,
            7.408,
            17.323,
            -6.615,
            7.408,
            17.22,
            -7.457,
            7.408,
            17.22,
            -7.457,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_11_1",
        "polygon": [
            17.505,
            -5.122,
            6.988,
            17.554,
            -4.72,
            6.988,
            17.554,
            -4.72,
            7.408,
            17.554,
            -4.72,
            7.408,
            17.505,
            -5.122,
            7.408,
            17.505,
            -5.122,
            6.988
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_11_2",
        "polygon": [
            17.505,
            -5.122,
            4.14,
            17.554,
            -4.72,
            4.14,
            17.554,
            -4.72,
            6.988,
            17.554,
            -4.72,
            6.988,
            17.505,
            -5.122,
            6.988,
            17.505,
            -5.122,
            4.14
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_11_3",
        "polygon": [
            17.559,
            -4.678,
            4.202,
            17.662,
            -3.835,
            4.202,
            17.662,
            -3.835,
            7.408,
            17.662,
            -3.835,
            7.408,
            17.559,
            -4.678,
            7.408,
            17.559,
            -4.678,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_9_1",
        "polygon": [
            17.844,
            -2.342,
            6.988,
            17.893,
            -1.94,
            6.988,
            17.893,
            -1.94,
            7.408,
            17.893,
            -1.94,
            7.408,
            17.844,
            -2.342,
            7.408,
            17.844,
            -2.342,
            6.988
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_9_2",
        "polygon": [
            17.844,
            -2.342,
            4.14,
            17.893,
            -1.94,
            4.14,
            17.893,
            -1.94,
            6.988,
            17.893,
            -1.94,
            6.988,
            17.844,
            -2.342,
            6.988,
            17.844,
            -2.342,
            4.14
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_9_3",
        "polygon": [
            17.898,
            -1.899,
            4.202,
            18.001,
            -1.056,
            4.202,
            18.001,
            -1.056,
            7.408,
            18.001,
            -1.056,
            7.408,
            17.898,
            -1.899,
            7.408,
            17.898,
            -1.899,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_7_1",
        "polygon": [
            18.183,
            0.437,
            6.988,
            18.232,
            0.839,
            6.988,
            18.232,
            0.839,
            7.408,
            18.232,
            0.839,
            7.408,
            18.183,
            0.437,
            7.408,
            18.183,
            0.437,
            6.988
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_7_2",
        "polygon": [
            18.183,
            0.437,
            4.14,
            18.232,
            0.839,
            4.14,
            18.232,
            0.839,
            6.988,
            18.232,
            0.839,
            6.988,
            18.183,
            0.437,
            6.988,
            18.183,
            0.437,
            4.14
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_7_3",
        "polygon": [
            18.238,
            0.881,
            4.202,
            18.34,
            1.724,
            4.202,
            18.34,
            1.724,
            7.408,
            18.34,
            1.724,
            7.408,
            18.238,
            0.881,
            7.408,
            18.238,
            0.881,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_5_1",
        "polygon": [
            18.522,
            3.217,
            6.988,
            18.571,
            3.619,
            6.988,
            18.571,
            3.619,
            7.408,
            18.571,
            3.619,
            7.408,
            18.522,
            3.217,
            7.408,
            18.522,
            3.217,
            6.988
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_5_2",
        "polygon": [
            18.522,
            3.217,
            4.14,
            18.571,
            3.619,
            4.14,
            18.571,
            3.619,
            6.988,
            18.571,
            3.619,
            6.988,
            18.522,
            3.217,
            6.988,
            18.522,
            3.217,
            4.14
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_5_3",
        "polygon": [
            18.577,
            3.66,
            4.202,
            18.679,
            4.503,
            4.202,
            18.679,
            4.503,
            7.408,
            18.679,
            4.503,
            7.408,
            18.577,
            3.66,
            7.408,
            18.577,
            3.66,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_3_1",
        "polygon": [
            18.861,
            5.996,
            6.988,
            18.91,
            6.398,
            6.988,
            18.91,
            6.398,
            7.408,
            18.91,
            6.398,
            7.408,
            18.861,
            5.996,
            7.408,
            18.861,
            5.996,
            6.988
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_3_2",
        "polygon": [
            18.861,
            5.996,
            4.14,
            18.91,
            6.398,
            4.14,
            18.91,
            6.398,
            6.988,
            18.91,
            6.398,
            6.988,
            18.861,
            5.996,
            6.988,
            18.861,
            5.996,
            4.14
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_3_3",
        "polygon": [
            18.916,
            6.44,
            4.202,
            19.018,
            7.282,
            4.202,
            19.018,
            7.282,
            7.408,
            19.018,
            7.282,
            7.408,
            18.916,
            6.44,
            7.408,
            18.916,
            6.44,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_1_1",
        "polygon": [
            19.2,
            8.775,
            6.988,
            19.249,
            9.177,
            6.988,
            19.249,
            9.177,
            7.408,
            19.249,
            9.177,
            7.408,
            19.2,
            8.775,
            7.408,
            19.2,
            8.775,
            6.988
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_1_2",
        "polygon": [
            19.2,
            8.775,
            4.14,
            19.249,
            9.177,
            4.14,
            19.249,
            9.177,
            6.988,
            19.249,
            9.177,
            6.988,
            19.2,
            8.775,
            6.988,
            19.2,
            8.775,
            4.14
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_1OG_1_3",
        "polygon": [
            19.255,
            9.219,
            4.202,
            19.357,
            10.062,
            4.202,
            19.357,
            10.062,
            7.408,
            19.357,
            10.062,
            7.408,
            19.255,
            9.219,
            7.408,
            19.255,
            9.219,
            4.202
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_1_1",
        "polygon": [
            19.2,
            8.775,
            10.698,
            19.249,
            9.177,
            10.698,
            19.249,
            9.177,
            11.118,
            19.249,
            9.177,
            11.118,
            19.2,
            8.775,
            11.118,
            19.2,
            8.775,
            10.698
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_1_2",
        "polygon": [
            19.2,
            8.775,
            7.85,
            19.249,
            9.177,
            7.85,
            19.249,
            9.177,
            10.698,
            19.249,
            9.177,
            10.698,
            19.2,
            8.775,
            10.698,
            19.2,
            8.775,
            7.85
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    },
    {
        "ID": "Nr.7-Ax23 DS5_GS4_2OG_1_3",
        "polygon": [
            19.255,
            9.219,
            7.912,
            19.357,
            10.062,
            7.912,
            19.357,
            10.062,
            11.118,
            19.357,
            10.062,
            11.118,
            19.255,
            9.219,
            11.118,
            19.255,
            9.219,
            7.912
        ],
        "05-24 13:15": 1,
        "05-24 13:30": 1,
        "05-24 13:45": 1,
        "05-24 14:00": 1,
        "05-24 14:15": 1,
        "05-24 14:30": 1,
        "05-24 14:45": 1,
        "05-24 15:00": 1,
        "05-24 15:15": 1,
        "05-24 15:30": 1,
        "05-24 15:45": 1,
        "05-24 16:00": 1,
        "05-24 16:15": 1,
        "05-24 16:30": 1,
        "05-24 16:45": 1,
        "05-24 17:00": 1,
        "05-24 17:15": 1,
        "05-24 17:30": 1,
        "05-24 17:45": 1,
        "05-24 18:00": 1
    }
]
export default Project56ShadingProgression