import { createSlice } from "@reduxjs/toolkit";

const createInitialState = () => {
  let floorsList;
  var energyValueData;
  let scenarioList;
  floorsList = localStorage.getItem("floor_list");
  scenarioList = localStorage.getItem("scenario_list");
  energyValueData = localStorage.getItem("value_list");
  if (floorsList & scenarioList & energyValueData) {
    floorsList = floorsList.split(",");

    scenarioList = scenarioList.split(",");

    energyValueData = energyValueData.split(",");

    var dict = {};
    for (let i = 0; i < floorsList.length; i++) {
      for (let j = 0; j < energyValueData.length; j++) {
        for (let k = 0; k < scenarioList.length; k++) {
          dict[
            `${floorsList[i]}${energyValueData[j]}${scenarioList[k]}`
          ] = false;
        }
      }
    }
    return dict;
  }
};
const initialState = {
  value: { parentName: "", childName: "" },
  api: { isFinished: false, data: null },
  roomEnergyDataStatus: null,
  // roomEnergyDataStatus: createInitialState(),
  rangeValues: {},
  haveRange: false,
  roomEnergyVisibility: false,
  currentValue: null,
  currentValueIndex: 0,
  roomEnergyScenarioList: null,
  roomName: null,
  roomPosition: { pagex: 0, pagey: 0 },
  roomArea: null,
  roomCoordinate: null,
  roomFirstIsShowing: false,
  modalScenarioIsShowing: false,
  lastChosenScenario: null,
  scenarioDescription: null,
  scenarioDescriptionList: null,
  roomIsShowing: null,
  minV: null,
  maxV: null,
  roomFunction: null,
  energyBoxView: false,
  roomConfigurationState: true,
};
const roomSlice = createSlice({
  name: "roomData",
  initialState,
  reducers: {
    roomEnergyToggle: (state, action) => {
      const clicked = action.payload;
      const isDeselected =
        state.value.parentName === clicked.parentName &&
        state.value.childName === clicked.childName;
      if (isDeselected) state.value = initialState.value;
      else state.value = clicked;
    },
    deselectEnergyData: (state) => {
      state.value = initialState.value;
    },
    setRoomEnergyDataScenarioList: (state, action) => {
      state.roomEnergyScenarioList = action.payload[0];
    },
    setModalScenarioIsShowing: (state, action) => {
      state.modalScenarioIsShowing = !state.modalScenarioIsShowing;
    },
    updateRoomEnergyConfigurationPosition: (state, action) => {
      state.roomFirstIsShowing = true;
      state.roomPosition.pagex = action.payload[0];
      state.roomPosition.pagey = action.payload[1];
      state.roomName = action.payload[2];
      state.roomArea = action.payload[3];
      state.roomCoordinate = action.payload[4];
      state.roomEnergyScenarioList = action.payload[5];
      // state.roomFunction = action.payload[6];
      state.minV = action.payload[6];
      state.maxV = action.payload[7];
      state.roomFunction = action.payload[6];
    },

    updateApiData: (state, action) => {
      state.api.data = action.payload[0];
      state.api.isFinished = true;
    },
    showEnergyData: (state, action) => {
      state.roomEnergyDataStatus[action.payload[0]] = !state
        .roomEnergyDataStatus[action.payload[0]];
    },
    hideEnergyData: (state, action) => {
      state.roomEnergyDataStatus = {};
    },
    setRange: (state, action) => {
      state.rangeValues = action.payload[0];
      state.haveRange = true;
    },
    toggleRoomVisibility: (state, action) => {
      state.roomEnergyVisibility = true;
    },
    disappearRoomEnergyColorBar: (state, action) => {
      state.roomEnergyVisibility = !state.roomEnergyVisibility;
    },
    setCurrentValue: (state, action) => {
      state.currentValue = action.payload[0];
    },
    setCurrentValueIndex: (state, action) => {
      state.currentValueIndex = action.payload;
    },

    setLastChosenScenario: (state, action) => {
      state.lastChosenScenario = action.payload[0];
    },
    setScenarioDescription: (state, action) => {
      state.scenarioDescription = action.payload[0];
    },
    setscenarioDescriptionList: (state, action) => {
      state.scenarioDescriptionList = action.payload[0];
    },
    resetRoomEnergyDataFilter: (state, action) => {
      // state.roomEnergyDataStatus = createInitialState();
      state.roomEnergyDataStatus = false;
    },
    setRoomEnergyDataFirstValue: (state, action) => {
      state.roomEnergyDataStatus = action.payload[0];
    },
    resetRoomEnergyColorBar: (state, action) => {
      state.roomEnergyVisibility = false;
    },
    setEnergyBoxView: (state, action) => {
      state.energyBoxView = action.payload;
    },
    roomConfigurationStateChanger: (state, action) => {
      state.roomConfigurationState = action.payload;
    },
  },
});

export const {
  roomEnergyToggle,
  updateApiData,
  showEnergyData,
  setRange,
  toggleRoomVisibility,
  setCurrentValue,
  setCurrentValueIndex,
  setRoomEnergyDataScenarioList,
  updateRoomEnergyConfigurationPosition,
  setModalScenarioIsShowing,
  setLastChosenScenario,
  setScenarioDescription,
  setscenarioDescriptionList,
  resetRoomEnergyDataFilter,
  disappearRoomEnergyColorBar,
  setRoomEnergyDataFirstValue,
  resetRoomEnergyColorBar,
  hideEnergyData,
  setEnergyBoxView,
  roomConfigurationStateChanger,
  deselectEnergyData,
} = roomSlice.actions;

export default roomSlice.reducer;
