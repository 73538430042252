import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: Array.from({ length: 2000 }, () => [true, true]),
  defaultValue: Array.from({ length: 2000 }, () => [true, true]),
  roomsData: Array(2000).fill(true),
  roomIsShowing: false,
  roomIndexIsShowing: null,
  roomDataLoaded: false,
  selectedFloors: Array(2000).fill(true),
  lastSelectedFloor: null,
};

const floorSlice = createSlice({
  name: "floorVisibility",
  initialState,
  reducers: {
    setCurrentFloorName: (state, action) => {
      state.lastSelectedFloor = action.payload[0];
    },
    updateSelectedFloor: (state, action) => {
      const floorIndex = action.payload[0];
      state.selectedFloors[floorIndex] = !state.selectedFloors[floorIndex];
    },
    toggleFloor: (state, action) => {
      const [floorIndex, subIndex] = action.payload;
      state.value[floorIndex][subIndex] = !state.value[floorIndex][subIndex];
    },
    showRoom: (state, action) => {
      if (action.payload[0] === "Room") {
        const roomIndex = action.payload[1];
        state.roomsData[roomIndex] = !state.roomsData[roomIndex];
      }
    },
    toggleRoomDataLoading: (state) => {
      state.roomDataLoaded = !state.roomDataLoaded;
    },
    hide: (state) => {
      state.value = Array.from({ length: 11 }, () => [false, false]);
    },
    setDefaultFloor: (state) => {
      state.selectedFloors = initialState.selectedFloors;
    },
    selectAllFloor: (state, action) => {
      state.selectedFloors[action.payload[0]] = true;
    },
    deselectAllFloor: (state, action) => {
      state.selectedFloors[action.payload[0]] = false;
    },
  },
});

export const {
  toggleFloor,
  hide,
  showRoom,
  toggleRoomDataLoading,
  setDefaultFloor,
  updateSelectedFloor,
  setCurrentFloorName,
  selectAllFloor,
  deselectAllFloor,
} = floorSlice.actions;

export default floorSlice.reducer;
