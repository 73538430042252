const arraysSum = (arr1, arr2) => {
    return arr1.map((a, i) => a + arr2[i]);
};

export const arraysSubtract = (arr1, arr2) => {
    return arr1.map((a, i) => a - arr2[i]);
};

export const arrayScale = (arr1, scaleFactor) => {
    return arr1.map((a, index) => scaleFactor[index] * a);
};


export const arePolygonsSimilar = (polygon1, polygon2) => {
    if (!polygon1?.length > 0 || !polygon2?.length > 0) return false;
    const v1 = polygon1.slice(0, 3);
    const v2 = polygon2.slice(0, 3);
    const translationVector = arraysSubtract(v2, v1);
    const mappedPolygon1 = polygon1.map((coordinate, index) => {
        const i = index % 3;
        return coordinate + translationVector[i];
    });
    const difference = arraysSubtract(mappedPolygon1, polygon2);
    return difference.every((e) => Math.abs(e) < 0.05);
};