const project50SunPosition = [
  {
    id: 5096672,
    customerId: 86,
    projectId: 294,
    time: "05-01 00:00",
    azimuth: 4,
    elevation: -21.23,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096673,
    customerId: 86,
    projectId: 294,
    time: "05-01 00:15",
    azimuth: 1,
    elevation: -21.33,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096674,
    customerId: 86,
    projectId: 294,
    time: "05-01 00:30",
    azimuth: 3,
    elevation: -21.27,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096675,
    customerId: 86,
    projectId: 294,
    time: "05-01 00:45",
    azimuth: 7,
    elevation: -21.07,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096676,
    customerId: 86,
    projectId: 294,
    time: "05-01 01:00",
    azimuth: 11,
    elevation: -20.72,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096677,
    customerId: 86,
    projectId: 294,
    time: "05-01 01:15",
    azimuth: 15,
    elevation: -20.22,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096678,
    customerId: 86,
    projectId: 294,
    time: "05-01 01:30",
    azimuth: 19,
    elevation: -19.57,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096679,
    customerId: 86,
    projectId: 294,
    time: "05-01 01:45",
    azimuth: 22,
    elevation: -18.79,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096680,
    customerId: 86,
    projectId: 294,
    time: "05-01 02:00",
    azimuth: 26,
    elevation: -17.88,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096681,
    customerId: 86,
    projectId: 294,
    time: "05-01 02:15",
    azimuth: 30,
    elevation: -16.84,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096682,
    customerId: 86,
    projectId: 294,
    time: "05-01 02:30",
    azimuth: 33,
    elevation: -15.67,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096683,
    customerId: 86,
    projectId: 294,
    time: "05-01 02:45",
    azimuth: 37,
    elevation: -14.39,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096684,
    customerId: 86,
    projectId: 294,
    time: "05-01 03:00",
    azimuth: 40,
    elevation: -13,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096685,
    customerId: 86,
    projectId: 294,
    time: "05-01 03:15",
    azimuth: 44,
    elevation: -11.52,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096686,
    customerId: 86,
    projectId: 294,
    time: "05-01 03:30",
    azimuth: 47,
    elevation: -9.93,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096687,
    customerId: 86,
    projectId: 294,
    time: "05-01 03:45",
    azimuth: 50,
    elevation: -8.26,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096688,
    customerId: 86,
    projectId: 294,
    time: "05-01 04:00",
    azimuth: 53,
    elevation: -6.51,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096689,
    customerId: 86,
    projectId: 294,
    time: "05-01 04:15",
    azimuth: 56,
    elevation: -4.69,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096690,
    customerId: 86,
    projectId: 294,
    time: "05-01 04:30",
    azimuth: 60,
    elevation: -2.8,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096691,
    customerId: 86,
    projectId: 294,
    time: "05-01 04:45",
    azimuth: 63,
    elevation: -0.85,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096692,
    customerId: 86,
    projectId: 294,
    time: "05-01 05:00",
    azimuth: 66,
    elevation: 1.16,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 5096693,
    customerId: 86,
    projectId: 294,
    time: "05-01 05:15",
    azimuth: 69,
    elevation: 3.21,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 5096694,
    customerId: 86,
    projectId: 294,
    time: "05-01 05:30",
    azimuth: 72,
    elevation: 5.31,
    maxRadiation: 196,
    cDirAmi: 6,
  },
  {
    id: 5096695,
    customerId: 86,
    projectId: 294,
    time: "05-01 05:45",
    azimuth: 75,
    elevation: 7.44,
    maxRadiation: 277,
    cDirAmi: 6,
  },
  {
    id: 5096696,
    customerId: 86,
    projectId: 294,
    time: "05-01 06:00",
    azimuth: 78,
    elevation: 9.6,
    maxRadiation: 360,
    cDirAmi: 6,
  },
  {
    id: 5096697,
    customerId: 86,
    projectId: 294,
    time: "05-01 06:15",
    azimuth: 80,
    elevation: 11.79,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 5096698,
    customerId: 86,
    projectId: 294,
    time: "05-01 06:30",
    azimuth: 83,
    elevation: 13.99,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 5096699,
    customerId: 86,
    projectId: 294,
    time: "05-01 06:45",
    azimuth: 86,
    elevation: 16.21,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 5096700,
    customerId: 86,
    projectId: 294,
    time: "05-01 07:00",
    azimuth: 89,
    elevation: 18.44,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 5096701,
    customerId: 86,
    projectId: 294,
    time: "05-01 07:15",
    azimuth: 92,
    elevation: 20.66,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 5096702,
    customerId: 86,
    projectId: 294,
    time: "05-01 07:30",
    azimuth: 96,
    elevation: 22.89,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 5096703,
    customerId: 86,
    projectId: 294,
    time: "05-01 07:45",
    azimuth: 99,
    elevation: 25.1,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096704,
    customerId: 86,
    projectId: 294,
    time: "05-01 08:00",
    azimuth: 102,
    elevation: 27.29,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096705,
    customerId: 86,
    projectId: 294,
    time: "05-01 08:15",
    azimuth: 105,
    elevation: 29.45,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096706,
    customerId: 86,
    projectId: 294,
    time: "05-01 08:30",
    azimuth: 108,
    elevation: 31.59,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096707,
    customerId: 86,
    projectId: 294,
    time: "05-01 08:45",
    azimuth: 112,
    elevation: 33.68,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096708,
    customerId: 86,
    projectId: 294,
    time: "05-01 09:00",
    azimuth: 116,
    elevation: 35.71,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096709,
    customerId: 86,
    projectId: 294,
    time: "05-01 09:15",
    azimuth: 119,
    elevation: 37.69,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096710,
    customerId: 86,
    projectId: 294,
    time: "05-01 09:30",
    azimuth: 123,
    elevation: 39.59,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096711,
    customerId: 86,
    projectId: 294,
    time: "05-01 09:45",
    azimuth: 128,
    elevation: 41.41,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096712,
    customerId: 86,
    projectId: 294,
    time: "05-01 10:00",
    azimuth: 132,
    elevation: 43.12,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096713,
    customerId: 86,
    projectId: 294,
    time: "05-01 10:15",
    azimuth: 136,
    elevation: 44.72,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096714,
    customerId: 86,
    projectId: 294,
    time: "05-01 10:30",
    azimuth: 141,
    elevation: 46.19,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096715,
    customerId: 86,
    projectId: 294,
    time: "05-01 10:45",
    azimuth: 146,
    elevation: 47.52,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096716,
    customerId: 86,
    projectId: 294,
    time: "05-01 11:00",
    azimuth: 151,
    elevation: 48.68,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096717,
    customerId: 86,
    projectId: 294,
    time: "05-01 11:15",
    azimuth: 156,
    elevation: 49.67,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096718,
    customerId: 86,
    projectId: 294,
    time: "05-01 11:30",
    azimuth: 162,
    elevation: 50.46,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096719,
    customerId: 86,
    projectId: 294,
    time: "05-01 11:45",
    azimuth: 168,
    elevation: 51.04,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096720,
    customerId: 86,
    projectId: 294,
    time: "05-01 12:00",
    azimuth: 173,
    elevation: 51.41,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096721,
    customerId: 86,
    projectId: 294,
    time: "05-01 12:15",
    azimuth: 179,
    elevation: 51.56,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096722,
    customerId: 86,
    projectId: 294,
    time: "05-01 12:30",
    azimuth: 185,
    elevation: 51.48,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096723,
    customerId: 86,
    projectId: 294,
    time: "05-01 12:45",
    azimuth: 191,
    elevation: 51.17,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096724,
    customerId: 86,
    projectId: 294,
    time: "05-01 13:00",
    azimuth: 196,
    elevation: 50.65,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096725,
    customerId: 86,
    projectId: 294,
    time: "05-01 13:15",
    azimuth: 202,
    elevation: 49.92,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096726,
    customerId: 86,
    projectId: 294,
    time: "05-01 13:30",
    azimuth: 207,
    elevation: 48.99,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096727,
    customerId: 86,
    projectId: 294,
    time: "05-01 13:45",
    azimuth: 212,
    elevation: 47.88,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096728,
    customerId: 86,
    projectId: 294,
    time: "05-01 14:00",
    azimuth: 218,
    elevation: 46.6,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096729,
    customerId: 86,
    projectId: 294,
    time: "05-01 14:15",
    azimuth: 222,
    elevation: 45.17,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096730,
    customerId: 86,
    projectId: 294,
    time: "05-01 14:30",
    azimuth: 227,
    elevation: 43.6,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096731,
    customerId: 86,
    projectId: 294,
    time: "05-01 14:45",
    azimuth: 231,
    elevation: 41.92,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 5096732,
    customerId: 86,
    projectId: 294,
    time: "05-01 15:00",
    azimuth: 235,
    elevation: 40.13,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096733,
    customerId: 86,
    projectId: 294,
    time: "05-01 15:15",
    azimuth: 239,
    elevation: 38.26,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096734,
    customerId: 86,
    projectId: 294,
    time: "05-01 15:30",
    azimuth: 243,
    elevation: 36.3,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096735,
    customerId: 86,
    projectId: 294,
    time: "05-01 15:45",
    azimuth: 247,
    elevation: 34.28,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096736,
    customerId: 86,
    projectId: 294,
    time: "05-01 16:00",
    azimuth: 250,
    elevation: 32.21,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096737,
    customerId: 86,
    projectId: 294,
    time: "05-01 16:15",
    azimuth: 254,
    elevation: 30.09,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096738,
    customerId: 86,
    projectId: 294,
    time: "05-01 16:30",
    azimuth: 257,
    elevation: 27.93,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096739,
    customerId: 86,
    projectId: 294,
    time: "05-01 16:45",
    azimuth: 260,
    elevation: 25.74,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 5096740,
    customerId: 86,
    projectId: 294,
    time: "05-01 17:00",
    azimuth: 264,
    elevation: 23.54,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 5096741,
    customerId: 86,
    projectId: 294,
    time: "05-01 17:15",
    azimuth: 267,
    elevation: 21.32,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 5096742,
    customerId: 86,
    projectId: 294,
    time: "05-01 17:30",
    azimuth: 270,
    elevation: 19.09,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 5096743,
    customerId: 86,
    projectId: 294,
    time: "05-01 17:45",
    azimuth: 273,
    elevation: 16.86,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 5096744,
    customerId: 86,
    projectId: 294,
    time: "05-01 18:00",
    azimuth: 276,
    elevation: 14.64,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 5096745,
    customerId: 86,
    projectId: 294,
    time: "05-01 18:15",
    azimuth: 279,
    elevation: 12.43,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 5096746,
    customerId: 86,
    projectId: 294,
    time: "05-01 18:30",
    azimuth: 282,
    elevation: 10.24,
    maxRadiation: 360,
    cDirAmi: 6,
  },
  {
    id: 5096747,
    customerId: 86,
    projectId: 294,
    time: "05-01 18:45",
    azimuth: 285,
    elevation: 8.07,
    maxRadiation: 314,
    cDirAmi: 6,
  },
  {
    id: 5096748,
    customerId: 86,
    projectId: 294,
    time: "05-01 19:00",
    azimuth: 288,
    elevation: 5.93,
    maxRadiation: 219,
    cDirAmi: 6,
  },
  {
    id: 5096749,
    customerId: 86,
    projectId: 294,
    time: "05-01 19:15",
    azimuth: 290,
    elevation: 3.82,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 5096750,
    customerId: 86,
    projectId: 294,
    time: "05-01 19:30",
    azimuth: 293,
    elevation: 1.76,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 5096751,
    customerId: 86,
    projectId: 294,
    time: "05-01 19:45",
    azimuth: 296,
    elevation: -0.26,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096752,
    customerId: 86,
    projectId: 294,
    time: "05-01 20:00",
    azimuth: 300,
    elevation: -2.23,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096753,
    customerId: 86,
    projectId: 294,
    time: "05-01 20:15",
    azimuth: 303,
    elevation: -4.14,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096754,
    customerId: 86,
    projectId: 294,
    time: "05-01 20:30",
    azimuth: 306,
    elevation: -5.98,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096755,
    customerId: 86,
    projectId: 294,
    time: "05-01 20:45",
    azimuth: 309,
    elevation: -7.76,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096756,
    customerId: 86,
    projectId: 294,
    time: "05-01 21:00",
    azimuth: 312,
    elevation: -9.45,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096757,
    customerId: 86,
    projectId: 294,
    time: "05-01 21:15",
    azimuth: 315,
    elevation: -11.06,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096758,
    customerId: 86,
    projectId: 294,
    time: "05-01 21:30",
    azimuth: 319,
    elevation: -12.58,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096759,
    customerId: 86,
    projectId: 294,
    time: "05-01 21:45",
    azimuth: 322,
    elevation: -13.99,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096760,
    customerId: 86,
    projectId: 294,
    time: "05-01 22:00",
    azimuth: 326,
    elevation: -15.31,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096761,
    customerId: 86,
    projectId: 294,
    time: "05-01 22:15",
    azimuth: 329,
    elevation: -16.51,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096762,
    customerId: 86,
    projectId: 294,
    time: "05-01 22:30",
    azimuth: 333,
    elevation: -17.58,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096763,
    customerId: 86,
    projectId: 294,
    time: "05-01 22:45",
    azimuth: 336,
    elevation: -18.54,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096764,
    customerId: 86,
    projectId: 294,
    time: "05-01 23:00",
    azimuth: 340,
    elevation: -19.36,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096765,
    customerId: 86,
    projectId: 294,
    time: "05-01 23:15",
    azimuth: 344,
    elevation: -20.04,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096766,
    customerId: 86,
    projectId: 294,
    time: "05-01 23:30",
    azimuth: 348,
    elevation: -20.59,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 5096767,
    customerId: 86,
    projectId: 294,
    time: "05-01 23:45",
    azimuth: 352,
    elevation: -20.98,
    maxRadiation: 0,
    cDirAmi: 0,
  },
];

export default project50SunPosition;
