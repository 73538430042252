import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  "Wind Sensors": {},
  "Shading System": {},
  "Energy Data": {},
  "Solar Sensors": {},
  "Sensor Placement": {},
  "Daylight Factor": {},
  "PV Panel": {},
  "Wind Turbine": {}
};
const colorbarsSlice = createSlice({
  name: "colorbars",
  initialState,
  reducers: {
    setColorbar: (state, action) => {
      const [category, subcategory, min, max, step] = action.payload;
      state[category][subcategory] = { min, max, step };
    },
  },
});

export const { setColorbar } = colorbarsSlice.actions;

export default colorbarsSlice.reducer;
