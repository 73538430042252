import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  TurbineData: {},
  value: {
    checkStatus: {},
  },
  infoBoxShow: false,
  valuesRanges: {},
  windTurbineIsLoading: false,
};

const infoWindTurbineSlice = createSlice({
  name: "infoWindTurbine",
  initialState,
  reducers: {
    updateTurbineData: (state, action) => {
      state.TurbineData = action.payload
    },
    setTurbineZoneShow: (state, action) => {
      state.value.checkStatus[action.payload[0]] = action.payload[1]
    },
    setTurbineInfoBoxShow: (state, action) => {
      state.infoBoxShow = action.payload
    },
    setWindTurbineIsLoading: (state, action) => {
      state.windTurbineIsLoading = action.payload
    },
    setWindTurbineValuesRanges: (state, action) => {
      state.valuesRanges = action.payload
    }
  },
});

export const { updateTurbineData,
  setTurbineZoneShow,
  setTurbineInfoBoxShow,
  setWindTurbineIsLoading,
  setWindTurbineValuesRanges
} = infoWindTurbineSlice.actions;

export default infoWindTurbineSlice.reducer;
