import { configureStore } from "@reduxjs/toolkit";
import sceneReducer from "./sceneSlice";
import sceneGlobalReducer from "./sceneGlobal";
import pvReducer from "./pvData";
import solarSensorsReducer from "./solarSensors";
import daylightFactorReducer from "./daylightFactor";
import shadingSystemReducer from "./shadingSystem";
import infoWindRiskSliceReducer from "./infoWindRisk";
import infoBoxReducer from "./infoBoxGeneral";
import windowConfigurationReducer from "./windowConfiguration";
import floorReducer from "./floorVisibility";
import uploadReducer from "./upload";
import roomReducer from "./roomData";
import virtualWindReducer from "./windDirection";
import colorbarsReducer from "./colorbars";
import userReducer from "./user";
import compassReducer from "./compass";
import WindTurbineReducer from './windTurbine'

export const store = configureStore({
  reducer: {
    sceneVisibility: sceneReducer,
    sceneVar: sceneGlobalReducer,
    sensorSolars: solarSensorsReducer,
    shadingSystem: shadingSystemReducer,
    infoWindRisk: infoWindRiskSliceReducer,
    infoBoxVisibility: infoBoxReducer,
    shadingConfigurationWindow: windowConfigurationReducer,
    floorVisibility: floorReducer,
    uploadData: uploadReducer,
    roomData: roomReducer,
    windDirection: virtualWindReducer,
    colorbars: colorbarsReducer,
    daylightFactor: daylightFactorReducer,
    pvData: pvReducer,
    user: userReducer,
    compass: compassReducer,
    windTurbine: WindTurbineReducer,
  },
});
