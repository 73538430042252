const project50ShadingProgression = [
  {
    ID: -1,
    polygon: "ANotExistingPolygon",
    "05-01 00:00": 0,
    "05-01 00:15": 0,
    "05-01 00:30": 0,
    "05-01 00:45": 0,
    "05-01 01:00": 0,
    "05-01 01:15": 0,
    "05-01 01:30": 0,
    "05-01 01:45": 0,
    "05-01 02:00": 0,
    "05-01 02:15": 0,
    "05-01 02:30": 0,
    "05-01 02:45": 0,
    "05-01 03:00": 0,
    "05-01 03:15": 0,
    "05-01 03:30": 0,
    "05-01 03:45": 0,
    "05-01 04:00": 0,
    "05-01 04:15": 0,
    "05-01 04:30": 0,
    "05-01 04:45": 0,
    "05-01 05:00": 0,
    "05-01 05:15": 0,
    "05-01 05:30": 0,
    "05-01 05:45": 0,
    "05-01 06:00": 0,
    "05-01 06:15": 0,
    "05-01 06:30": 0,
    "05-01 06:45": 0,
    "05-01 07:00": 0,
    "05-01 07:15": 0,
    "05-01 07:30": 0,
    "05-01 07:45": 0,
    "05-01 08:00": 0,
    "05-01 08:15": 0,
    "05-01 08:30": 0,
    "05-01 08:45": 0,
    "05-01 09:00": 0,
    "05-01 09:15": 0,
    "05-01 09:30": 0,
    "05-01 09:45": 0,
    "05-01 10:00": 0,
    "05-01 10:15": 0,
    "05-01 10:30": 0,
    "05-01 10:45": 0,
    "05-01 11:00": 0,
    "05-01 11:15": 0,
    "05-01 11:30": 0,
    "05-01 11:45": 0,
    "05-01 12:00": 0,
    "05-01 12:15": 0,
    "05-01 12:30": 0,
    "05-01 12:45": 0,
    "05-01 13:00": 0,
    "05-01 13:15": 0,
    "05-01 13:30": 0,
    "05-01 13:45": 0,
    "05-01 14:00": 0,
    "05-01 14:15": 0,
    "05-01 14:30": 0,
    "05-01 14:45": 0,
    "05-01 15:00": 0,
    "05-01 15:15": 0,
    "05-01 15:30": 0,
    "05-01 15:45": 0,
    "05-01 16:00": 0,
    "05-01 16:15": 0,
    "05-01 16:30": 0,
    "05-01 16:45": 0,
    "05-01 17:00": 0,
    "05-01 17:15": 0,
    "05-01 17:30": 0,
    "05-01 17:45": 0,
    "05-01 18:00": 0,
    "05-01 18:15": 0,
    "05-01 18:30": 0,
    "05-01 18:45": 0,
    "05-01 19:00": 0,
    "05-01 19:15": 0,
    "05-01 19:30": 0,
    "05-01 19:45": 0,
    "05-01 20:00": 0,
    "05-01 20:15": 0,
    "05-01 20:30": 0,
    "05-01 20:45": 0,
    "05-01 21:00": 0,
    "05-01 21:15": 0,
    "05-01 21:30": 0,
    "05-01 21:45": 0,
    "05-01 22:00": 0,
    "05-01 22:15": 0,
    "05-01 22:30": 0,
    "05-01 22:45": 0,
    "05-01 23:00": 0,
    "05-01 23:15": 0,
    "05-01 23:30": 0,
    "05-01 23:45": 0,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_490",
    polygon: [
      -20.693,
      5.116,
      9.326,
      -20.693,
      5.116,
      11.471,
      -19.466,
      5.358,
      11.471,
      -19.466,
      5.358,
      11.471,
      -19.466,
      5.358,
      9.326,
      -20.693,
      5.116,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_494",
    polygon: [
      -12.109,
      6.815,
      9.326,
      -12.109,
      6.815,
      11.471,
      -10.883,
      7.058,
      11.471,
      -10.883,
      7.058,
      11.471,
      -10.883,
      7.058,
      9.326,
      -12.109,
      6.815,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_495",
    polygon: [
      -7.205,
      7.786,
      9.326,
      -7.205,
      7.786,
      11.471,
      -5.978,
      8.029,
      11.471,
      -5.978,
      8.029,
      11.471,
      -5.978,
      8.029,
      9.326,
      -7.205,
      7.786,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_489",
    polygon: [
      -21.919,
      4.873,
      9.326,
      -21.919,
      4.873,
      11.471,
      -20.693,
      5.116,
      11.471,
      -20.693,
      5.116,
      11.471,
      -20.693,
      5.116,
      9.326,
      -21.919,
      4.873,
      9.326,
    ],
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_488",
    polygon: [
      -23.145,
      4.63,
      9.326,
      -23.145,
      4.63,
      11.471,
      -21.919,
      4.873,
      11.471,
      -21.919,
      4.873,
      11.471,
      -21.919,
      4.873,
      9.326,
      -23.145,
      4.63,
      9.326,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_482",
    polygon: [
      -14.562,
      6.33,
      9.326,
      -14.562,
      6.33,
      11.471,
      -13.336,
      6.572,
      11.471,
      -13.336,
      6.572,
      11.471,
      -13.336,
      6.572,
      9.326,
      -14.562,
      6.33,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_487",
    polygon: [
      -17.014,
      5.844,
      9.326,
      -17.014,
      5.844,
      11.471,
      -15.788,
      6.087,
      11.471,
      -15.788,
      6.087,
      11.471,
      -15.788,
      6.087,
      9.326,
      -17.014,
      5.844,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_125",
    polygon: [
      15.61,
      3.256,
      9.365,
      15.61,
      3.256,
      11.425,
      15.901,
      1.785,
      11.425,
      15.901,
      1.785,
      11.425,
      15.901,
      1.785,
      9.365,
      15.61,
      3.256,
      9.365,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_483",
    polygon: [
      -15.788,
      6.087,
      9.326,
      -15.788,
      6.087,
      11.471,
      -14.562,
      6.33,
      11.471,
      -14.562,
      6.33,
      11.471,
      -14.562,
      6.33,
      9.326,
      -15.788,
      6.087,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_343",
    polygon: [
      -1.984,
      -5.707,
      9.326,
      -1.984,
      -5.707,
      11.471,
      -3.21,
      -5.95,
      11.471,
      -3.21,
      -5.95,
      11.471,
      -3.21,
      -5.95,
      9.326,
      -1.984,
      -5.707,
      9.326,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_124",
    polygon: [
      13.06,
      16.131,
      9.365,
      13.06,
      16.131,
      11.425,
      13.352,
      14.66,
      11.425,
      13.352,
      14.66,
      11.425,
      13.352,
      14.66,
      9.365,
      13.06,
      16.131,
      9.365,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_342",
    polygon: [
      -3.21,
      -5.95,
      9.326,
      -3.21,
      -5.95,
      11.471,
      -4.437,
      -6.192,
      11.471,
      -4.437,
      -6.192,
      11.471,
      -4.437,
      -6.192,
      9.326,
      -3.21,
      -5.95,
      9.326,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_126",
    polygon: [
      15.003,
      6.322,
      9.365,
      15.003,
      6.322,
      11.425,
      15.294,
      4.85,
      11.425,
      15.294,
      4.85,
      11.425,
      15.294,
      4.85,
      9.365,
      15.003,
      6.322,
      9.365,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_127",
    polygon: [
      13.546,
      13.679,
      9.365,
      13.546,
      13.679,
      11.425,
      13.837,
      12.208,
      11.425,
      13.837,
      12.208,
      11.425,
      13.837,
      12.208,
      9.365,
      13.546,
      13.679,
      9.365,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_333",
    polygon: [
      -14.246,
      -8.135,
      9.326,
      -14.246,
      -8.135,
      11.471,
      -15.472,
      -8.377,
      11.471,
      -15.472,
      -8.377,
      11.471,
      -15.472,
      -8.377,
      9.326,
      -14.246,
      -8.135,
      9.326,
    ],
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_79",
    polygon: [
      -22.964,
      -2.98,
      8.65,
      -22.964,
      -2.98,
      11.65,
      -23.235,
      -1.606,
      11.65,
      -23.235,
      -1.606,
      11.65,
      -23.235,
      -1.606,
      8.65,
      -22.964,
      -2.98,
      8.65,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_481",
    polygon: [
      -1.074,
      9.0,
      9.326,
      -1.074,
      9.0,
      11.471,
      -0.421,
      9.129,
      11.471,
      -0.421,
      9.129,
      11.471,
      -0.421,
      9.129,
      9.326,
      -1.074,
      9.0,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_335",
    polygon: [
      -11.794,
      -7.649,
      9.326,
      -11.794,
      -7.649,
      11.471,
      -13.02,
      -7.892,
      11.471,
      -13.02,
      -7.892,
      11.471,
      -13.02,
      -7.892,
      9.326,
      -11.794,
      -7.649,
      9.326,
    ],
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_340",
    polygon: [
      -5.663,
      -6.435,
      9.326,
      -5.663,
      -6.435,
      11.471,
      -6.889,
      -6.678,
      11.471,
      -6.889,
      -6.678,
      11.471,
      -6.889,
      -6.678,
      9.326,
      -5.663,
      -6.435,
      9.326,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_332",
    polygon: [
      -15.472,
      -8.377,
      9.326,
      -15.472,
      -8.377,
      11.471,
      -16.699,
      -8.62,
      11.471,
      -16.699,
      -8.62,
      11.471,
      -16.699,
      -8.62,
      9.326,
      -15.472,
      -8.377,
      9.326,
    ],
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_305",
    polygon: [
      0.468,
      -5.221,
      5.566,
      0.468,
      -5.221,
      7.711,
      -0.758,
      -5.464,
      7.711,
      -0.758,
      -5.464,
      7.711,
      -0.758,
      -5.464,
      5.566,
      0.468,
      -5.221,
      5.566,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_149",
    polygon: [
      14.093,
      10.918,
      9.365,
      14.093,
      10.918,
      11.425,
      14.747,
      7.612,
      11.425,
      14.747,
      7.612,
      11.425,
      14.747,
      7.612,
      9.365,
      14.093,
      10.918,
      9.365,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_128",
    polygon: [
      16.217,
      0.19,
      9.365,
      16.217,
      0.19,
      11.425,
      16.508,
      -1.282,
      11.425,
      16.508,
      -1.282,
      11.425,
      16.508,
      -1.282,
      9.365,
      16.217,
      0.19,
      9.365,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_300",
    polygon: [
      -5.663,
      -6.435,
      5.566,
      -5.663,
      -6.435,
      7.711,
      -6.889,
      -6.678,
      7.711,
      -6.889,
      -6.678,
      7.711,
      -6.889,
      -6.678,
      5.566,
      -5.663,
      -6.435,
      5.566,
    ],
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_61",
    polygon: [
      6.171,
      -22.441,
      9.365,
      6.171,
      -22.441,
      11.425,
      5.88,
      -20.97,
      11.425,
      5.88,
      -20.97,
      11.425,
      5.88,
      -20.97,
      9.365,
      6.171,
      -22.441,
      9.365,
    ],
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_304",
    polygon: [
      -0.758,
      -5.464,
      5.566,
      -0.758,
      -5.464,
      7.711,
      -1.984,
      -5.707,
      7.711,
      -1.984,
      -5.707,
      7.711,
      -1.984,
      -5.707,
      5.566,
      -0.758,
      -5.464,
      5.566,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_334",
    polygon: [
      -13.02,
      -7.892,
      9.326,
      -13.02,
      -7.892,
      11.471,
      -14.246,
      -8.135,
      11.471,
      -14.246,
      -8.135,
      11.471,
      -14.246,
      -8.135,
      9.326,
      -13.02,
      -7.892,
      9.326,
    ],
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_331",
    polygon: [
      -16.699,
      -8.62,
      9.326,
      -16.699,
      -8.62,
      11.471,
      -17.925,
      -8.863,
      11.471,
      -17.925,
      -8.863,
      11.471,
      -17.925,
      -8.863,
      9.326,
      -16.699,
      -8.62,
      9.326,
    ],
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_341",
    polygon: [
      -4.437,
      -6.192,
      9.326,
      -4.437,
      -6.192,
      11.471,
      -5.663,
      -6.435,
      11.471,
      -5.663,
      -6.435,
      11.471,
      -5.663,
      -6.435,
      9.326,
      -4.437,
      -6.192,
      9.326,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_178",
    polygon: [
      5.96,
      -21.373,
      -0.12,
      5.96,
      -21.373,
      3.68,
      5.75,
      -20.314,
      3.68,
      5.75,
      -20.314,
      3.68,
      5.75,
      -20.314,
      -0.12,
      5.96,
      -21.373,
      -0.12,
    ],
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_45",
    polygon: [
      -1.598,
      16.797,
      9.365,
      -1.598,
      16.797,
      11.425,
      -1.889,
      18.268,
      11.425,
      -1.889,
      18.268,
      11.425,
      -1.889,
      18.268,
      9.365,
      -1.598,
      16.797,
      9.365,
    ],
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_347",
    polygon: [
      2.347,
      -4.849,
      9.326,
      2.347,
      -4.849,
      11.471,
      1.694,
      -4.978,
      11.471,
      1.694,
      -4.978,
      11.471,
      1.694,
      -4.978,
      9.326,
      2.347,
      -4.849,
      9.326,
    ],
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_46",
    polygon: [
      -1.112,
      14.345,
      9.365,
      -1.112,
      14.345,
      11.425,
      -1.404,
      15.816,
      11.425,
      -1.404,
      15.816,
      11.425,
      -1.404,
      15.816,
      9.365,
      -1.112,
      14.345,
      9.365,
    ],
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_47",
    polygon: [
      -0.627,
      11.892,
      9.365,
      -0.627,
      11.892,
      11.425,
      -0.918,
      13.364,
      11.425,
      -0.918,
      13.364,
      11.425,
      -0.918,
      13.364,
      9.365,
      -0.627,
      11.892,
      9.365,
    ],
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_56",
    polygon: [
      3.501,
      -8.953,
      9.365,
      3.501,
      -8.953,
      11.425,
      3.209,
      -7.482,
      11.425,
      3.209,
      -7.482,
      11.425,
      3.209,
      -7.482,
      9.365,
      3.501,
      -8.953,
      9.365,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_57",
    polygon: [
      3.986,
      -11.406,
      9.365,
      3.986,
      -11.406,
      11.425,
      3.695,
      -9.934,
      11.425,
      3.695,
      -9.934,
      11.425,
      3.695,
      -9.934,
      9.365,
      3.986,
      -11.406,
      9.365,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_58",
    polygon: [
      4.472,
      -13.858,
      9.365,
      4.472,
      -13.858,
      11.425,
      4.181,
      -12.386,
      11.425,
      4.181,
      -12.386,
      11.425,
      4.181,
      -12.386,
      9.365,
      4.472,
      -13.858,
      9.365,
    ],
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_59",
    polygon: [
      5.2,
      -17.537,
      9.365,
      5.2,
      -17.537,
      11.425,
      4.909,
      -16.065,
      11.425,
      4.909,
      -16.065,
      11.425,
      4.909,
      -16.065,
      9.365,
      5.2,
      -17.537,
      9.365,
    ],
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_122",
    polygon: [
      12.089,
      21.036,
      9.365,
      12.089,
      21.036,
      11.425,
      12.381,
      19.565,
      11.425,
      12.381,
      19.565,
      11.425,
      12.381,
      19.565,
      9.365,
      12.089,
      21.036,
      9.365,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_123",
    polygon: [
      12.575,
      18.584,
      9.365,
      12.575,
      18.584,
      11.425,
      12.866,
      17.112,
      11.425,
      12.866,
      17.112,
      11.425,
      12.866,
      17.112,
      9.365,
      12.575,
      18.584,
      9.365,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_60",
    polygon: [
      5.686,
      -19.989,
      9.365,
      5.686,
      -19.989,
      11.425,
      5.395,
      -18.517,
      11.425,
      5.395,
      -18.517,
      11.425,
      5.395,
      -18.517,
      9.365,
      5.686,
      -19.989,
      9.365,
    ],
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_175",
    polygon: [
      5.33,
      -18.191,
      -0.12,
      5.33,
      -18.191,
      3.68,
      5.12,
      -17.132,
      3.68,
      5.12,
      -17.132,
      3.68,
      5.12,
      -17.132,
      -0.12,
      5.33,
      -18.191,
      -0.12,
    ],
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_303",
    polygon: [
      -1.984,
      -5.707,
      5.566,
      -1.984,
      -5.707,
      7.711,
      -3.21,
      -5.95,
      7.711,
      -3.21,
      -5.95,
      7.711,
      -3.21,
      -5.95,
      5.566,
      -1.984,
      -5.707,
      5.566,
    ],
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_299",
    polygon: [
      -6.889,
      -6.678,
      5.566,
      -6.889,
      -6.678,
      7.711,
      -8.115,
      -6.921,
      7.711,
      -8.115,
      -6.921,
      7.711,
      -8.115,
      -6.921,
      5.566,
      -6.889,
      -6.678,
      5.566,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_336",
    polygon: [
      -10.568,
      -7.406,
      9.326,
      -10.568,
      -7.406,
      11.471,
      -11.794,
      -7.649,
      11.471,
      -11.794,
      -7.649,
      11.471,
      -11.794,
      -7.649,
      9.326,
      -10.568,
      -7.406,
      9.326,
    ],
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_177",
    polygon: [
      5.75,
      -20.313,
      -0.12,
      5.75,
      -20.313,
      3.68,
      5.54,
      -19.253,
      3.68,
      5.54,
      -19.253,
      3.68,
      5.54,
      -19.253,
      -0.12,
      5.75,
      -20.313,
      -0.12,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_292",
    polygon: [
      -15.472,
      -8.377,
      5.566,
      -15.472,
      -8.377,
      7.711,
      -16.699,
      -8.62,
      7.711,
      -16.699,
      -8.62,
      7.711,
      -16.699,
      -8.62,
      5.566,
      -15.472,
      -8.377,
      5.566,
    ],
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_330",
    polygon: [
      -17.925,
      -8.863,
      9.326,
      -17.925,
      -8.863,
      11.471,
      -19.151,
      -9.106,
      11.471,
      -19.151,
      -9.106,
      11.471,
      -19.151,
      -9.106,
      9.326,
      -17.925,
      -8.863,
      9.326,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_44",
    polygon: [
      -2.084,
      19.249,
      9.365,
      -2.084,
      19.249,
      11.425,
      -2.375,
      20.721,
      11.425,
      -2.375,
      20.721,
      11.425,
      -2.375,
      20.721,
      9.365,
      -2.084,
      19.249,
      9.365,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_473",
    polygon: [
      -9.657,
      7.301,
      5.566,
      -9.657,
      7.301,
      7.711,
      -8.431,
      7.544,
      7.711,
      -8.431,
      7.544,
      7.711,
      -8.431,
      7.544,
      5.566,
      -9.657,
      7.301,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_469",
    polygon: [
      -21.919,
      4.873,
      5.566,
      -21.919,
      4.873,
      7.711,
      -20.693,
      5.116,
      7.711,
      -20.693,
      5.116,
      7.711,
      -20.693,
      5.116,
      5.566,
      -21.919,
      4.873,
      5.566,
    ],
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_288",
    polygon: [
      -20.377,
      -9.349,
      5.566,
      -20.377,
      -9.349,
      7.711,
      -21.03,
      -9.478,
      7.711,
      -21.03,
      -9.478,
      7.711,
      -21.03,
      -9.478,
      5.566,
      -20.377,
      -9.349,
      5.566,
    ],
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_176",
    polygon: [
      5.54,
      -19.252,
      -0.12,
      5.54,
      -19.252,
      3.68,
      5.33,
      -18.193,
      3.68,
      5.33,
      -18.193,
      3.68,
      5.33,
      -18.193,
      -0.12,
      5.54,
      -19.252,
      -0.12,
    ],
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_468",
    polygon: [
      -23.145,
      4.63,
      5.566,
      -23.145,
      4.63,
      7.711,
      -21.919,
      4.873,
      7.711,
      -21.919,
      4.873,
      7.711,
      -21.919,
      4.873,
      5.566,
      -23.145,
      4.63,
      5.566,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_444",
    polygon: [
      -4.531,
      8.316,
      0.4,
      -4.531,
      8.316,
      2.876,
      -3.526,
      8.515,
      2.876,
      -3.526,
      8.515,
      2.876,
      -3.526,
      8.515,
      0.4,
      -4.531,
      8.316,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_302",
    polygon: [
      -3.21,
      -5.95,
      5.566,
      -3.21,
      -5.95,
      7.711,
      -4.437,
      -6.192,
      7.711,
      -4.437,
      -6.192,
      7.711,
      -4.437,
      -6.192,
      5.566,
      -3.21,
      -5.95,
      5.566,
    ],
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_480",
    polygon: [
      -4.752,
      8.272,
      5.566,
      -4.752,
      8.272,
      7.711,
      -3.526,
      8.515,
      7.711,
      -3.526,
      8.515,
      7.711,
      -3.526,
      8.515,
      5.566,
      -4.752,
      8.272,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_290",
    polygon: [
      -17.925,
      -8.863,
      5.566,
      -17.925,
      -8.863,
      7.711,
      -19.151,
      -9.106,
      7.711,
      -19.151,
      -9.106,
      7.711,
      -19.151,
      -9.106,
      5.566,
      -17.925,
      -8.863,
      5.566,
    ],
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_42",
    polygon: [
      6.171,
      -22.441,
      5.615,
      6.171,
      -22.441,
      7.675,
      5.88,
      -20.97,
      7.675,
      5.88,
      -20.97,
      7.675,
      5.88,
      -20.97,
      5.615,
      6.171,
      -22.441,
      5.615,
    ],
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_307",
    polygon: [
      2.347,
      -4.849,
      5.566,
      2.347,
      -4.849,
      7.711,
      1.694,
      -4.978,
      7.711,
      1.694,
      -4.978,
      7.711,
      1.694,
      -4.978,
      5.566,
      2.347,
      -4.849,
      5.566,
    ],
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_461",
    polygon: [
      -1.074,
      9.0,
      5.566,
      -1.074,
      9.0,
      7.711,
      -0.421,
      9.129,
      7.711,
      -0.421,
      9.129,
      7.711,
      -0.421,
      9.129,
      5.566,
      -1.074,
      9.0,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_291",
    polygon: [
      -16.699,
      -8.62,
      5.566,
      -16.699,
      -8.62,
      7.711,
      -17.925,
      -8.863,
      7.711,
      -17.925,
      -8.863,
      7.711,
      -17.925,
      -8.863,
      5.566,
      -16.699,
      -8.62,
      5.566,
    ],
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_298",
    polygon: [
      -8.115,
      -6.921,
      5.566,
      -8.115,
      -6.921,
      7.711,
      -9.341,
      -7.163,
      7.711,
      -9.341,
      -7.163,
      7.711,
      -9.341,
      -7.163,
      5.566,
      -8.115,
      -6.921,
      5.566,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_148",
    polygon: [
      14.093,
      10.918,
      5.615,
      14.093,
      10.918,
      7.675,
      14.747,
      7.612,
      7.675,
      14.747,
      7.612,
      7.675,
      14.747,
      7.612,
      5.615,
      14.093,
      10.918,
      5.615,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_43",
    polygon: [
      -2.569,
      21.702,
      9.365,
      -2.569,
      21.702,
      11.425,
      -2.861,
      23.173,
      11.425,
      -2.861,
      23.173,
      11.425,
      -2.861,
      23.173,
      9.365,
      -2.569,
      21.702,
      9.365,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_467",
    polygon: [
      -17.014,
      5.844,
      5.566,
      -17.014,
      5.844,
      7.711,
      -15.788,
      6.087,
      7.711,
      -15.788,
      6.087,
      7.711,
      -15.788,
      6.087,
      5.566,
      -17.014,
      5.844,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_41",
    polygon: [
      5.686,
      -19.989,
      5.615,
      5.686,
      -19.989,
      7.675,
      5.395,
      -18.517,
      7.675,
      5.395,
      -18.517,
      7.675,
      5.395,
      -18.517,
      5.615,
      5.686,
      -19.989,
      5.615,
    ],
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_443",
    polygon: [
      -3.526,
      8.515,
      2.876,
      -3.526,
      8.515,
      3.901,
      -2.52,
      8.714,
      3.901,
      -2.52,
      8.714,
      3.901,
      -2.52,
      8.714,
      2.876,
      -3.526,
      8.515,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_329",
    polygon: [
      -19.151,
      -9.106,
      9.326,
      -19.151,
      -9.106,
      11.471,
      -20.377,
      -9.349,
      11.471,
      -20.377,
      -9.349,
      11.471,
      -20.377,
      -9.349,
      9.326,
      -19.151,
      -9.106,
      9.326,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_434",
    polygon: [
      -7.205,
      7.786,
      2.876,
      -7.205,
      7.786,
      3.901,
      -5.978,
      8.029,
      3.901,
      -5.978,
      8.029,
      3.901,
      -5.978,
      8.029,
      2.876,
      -7.205,
      7.786,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_116",
    polygon: [
      13.546,
      13.679,
      5.615,
      13.546,
      13.679,
      7.675,
      13.837,
      12.208,
      7.675,
      13.837,
      12.208,
      7.675,
      13.837,
      12.208,
      5.615,
      13.546,
      13.679,
      5.615,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_388",
    polygon: [
      -19.151,
      -9.106,
      5.566,
      -19.151,
      -9.106,
      7.711,
      -20.377,
      -9.349,
      7.711,
      -20.377,
      -9.349,
      7.711,
      -20.377,
      -9.349,
      5.566,
      -19.151,
      -9.106,
      5.566,
    ],
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_147",
    polygon: [
      16.764,
      -2.57,
      5.615,
      16.764,
      -2.57,
      7.675,
      17.418,
      -5.876,
      7.675,
      17.418,
      -5.876,
      7.675,
      17.418,
      -5.876,
      5.615,
      16.764,
      -2.57,
      5.615,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_40",
    polygon: [
      5.2,
      -17.537,
      5.615,
      5.2,
      -17.537,
      7.675,
      4.909,
      -16.065,
      7.675,
      4.909,
      -16.065,
      7.675,
      4.909,
      -16.065,
      5.615,
      5.2,
      -17.537,
      5.615,
    ],
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_476",
    polygon: [
      -8.431,
      7.544,
      5.566,
      -8.431,
      7.544,
      7.711,
      -7.205,
      7.786,
      7.711,
      -7.205,
      7.786,
      7.711,
      -7.205,
      7.786,
      5.566,
      -8.431,
      7.544,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_442",
    polygon: [
      -3.526,
      8.515,
      0.4,
      -3.526,
      8.515,
      2.876,
      -2.52,
      8.714,
      2.876,
      -2.52,
      8.714,
      2.876,
      -2.52,
      8.714,
      0.4,
      -3.526,
      8.515,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_33",
    polygon: [
      -2.074,
      19.251,
      5.615,
      -2.074,
      19.251,
      7.675,
      -2.365,
      20.723,
      7.675,
      -2.365,
      20.723,
      7.675,
      -2.365,
      20.723,
      5.615,
      -2.074,
      19.251,
      5.615,
    ],
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_460",
    polygon: [
      -2.3,
      8.757,
      2.876,
      -2.3,
      8.757,
      3.901,
      -1.074,
      9.0,
      3.901,
      -1.074,
      9.0,
      3.901,
      -1.074,
      9.0,
      2.876,
      -2.3,
      8.757,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_39",
    polygon: [
      4.472,
      -13.858,
      5.615,
      4.472,
      -13.858,
      7.675,
      4.181,
      -12.386,
      7.675,
      4.181,
      -12.386,
      7.675,
      4.181,
      -12.386,
      5.615,
      4.472,
      -13.858,
      5.615,
    ],
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_297",
    polygon: [
      -9.341,
      -7.163,
      5.566,
      -9.341,
      -7.163,
      7.711,
      -10.568,
      -7.406,
      7.711,
      -10.568,
      -7.406,
      7.711,
      -10.568,
      -7.406,
      5.566,
      -9.341,
      -7.163,
      5.566,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_433",
    polygon: [
      -7.205,
      7.786,
      1.076,
      -7.205,
      7.786,
      2.876,
      -5.978,
      8.029,
      2.876,
      -5.978,
      8.029,
      2.876,
      -5.978,
      8.029,
      1.076,
      -7.205,
      7.786,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_115",
    polygon: [
      15.003,
      6.322,
      5.615,
      15.003,
      6.322,
      7.675,
      15.294,
      4.85,
      7.675,
      15.294,
      4.85,
      7.675,
      15.294,
      4.85,
      5.615,
      15.003,
      6.322,
      5.615,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_301",
    polygon: [
      -4.437,
      -6.192,
      5.566,
      -4.437,
      -6.192,
      7.711,
      -5.663,
      -6.435,
      7.711,
      -5.663,
      -6.435,
      7.711,
      -5.663,
      -6.435,
      5.566,
      -4.437,
      -6.192,
      5.566,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_328",
    polygon: [
      -20.377,
      -9.349,
      9.326,
      -20.377,
      -9.349,
      11.471,
      -21.03,
      -9.478,
      11.471,
      -21.03,
      -9.478,
      11.471,
      -21.03,
      -9.478,
      9.326,
      -20.377,
      -9.349,
      9.326,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_405",
    polygon: [
      -18.24,
      5.601,
      0.4,
      -18.24,
      5.601,
      1.076,
      -17.014,
      5.844,
      1.076,
      -17.014,
      5.844,
      1.076,
      -17.014,
      5.844,
      0.4,
      -18.24,
      5.601,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_406",
    polygon: [
      -18.24,
      5.601,
      1.076,
      -18.24,
      5.601,
      2.876,
      -17.014,
      5.844,
      2.876,
      -17.014,
      5.844,
      2.876,
      -17.014,
      5.844,
      1.076,
      -18.24,
      5.601,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_435",
    polygon: [
      -5.978,
      8.029,
      0.4,
      -5.978,
      8.029,
      1.076,
      -4.752,
      8.272,
      1.076,
      -4.752,
      8.272,
      1.076,
      -4.752,
      8.272,
      0.4,
      -5.978,
      8.029,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_466",
    polygon: [
      -18.24,
      5.601,
      5.566,
      -18.24,
      5.601,
      7.711,
      -17.014,
      5.844,
      7.711,
      -17.014,
      5.844,
      7.711,
      -17.014,
      5.844,
      5.566,
      -18.24,
      5.601,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_459",
    polygon: [
      -2.3,
      8.757,
      1.076,
      -2.3,
      8.757,
      2.876,
      -1.074,
      9.0,
      2.876,
      -1.074,
      9.0,
      2.876,
      -1.074,
      9.0,
      1.076,
      -2.3,
      8.757,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_432",
    polygon: [
      -7.205,
      7.786,
      0.4,
      -7.205,
      7.786,
      1.076,
      -5.978,
      8.029,
      1.076,
      -5.978,
      8.029,
      1.076,
      -5.978,
      8.029,
      0.4,
      -7.205,
      7.786,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_421",
    polygon: [
      -12.109,
      6.815,
      1.076,
      -12.109,
      6.815,
      2.876,
      -10.883,
      7.058,
      2.876,
      -10.883,
      7.058,
      2.876,
      -10.883,
      7.058,
      1.076,
      -12.109,
      6.815,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_420",
    polygon: [
      -12.109,
      6.815,
      0.4,
      -12.109,
      6.815,
      1.076,
      -10.883,
      7.058,
      1.076,
      -10.883,
      7.058,
      1.076,
      -10.883,
      7.058,
      0.4,
      -12.109,
      6.815,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_78",
    polygon: [
      -22.964,
      -2.98,
      4.73,
      -22.964,
      -2.98,
      7.73,
      -23.235,
      -1.606,
      7.73,
      -23.235,
      -1.606,
      7.73,
      -23.235,
      -1.606,
      4.73,
      -22.964,
      -2.98,
      4.73,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_110",
    polygon: [
      11.604,
      23.489,
      5.615,
      11.604,
      23.489,
      7.675,
      11.895,
      22.017,
      7.675,
      11.895,
      22.017,
      7.675,
      11.895,
      22.017,
      5.615,
      11.604,
      23.489,
      5.615,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_111",
    polygon: [
      12.089,
      21.036,
      5.615,
      12.089,
      21.036,
      7.675,
      12.381,
      19.565,
      7.675,
      12.381,
      19.565,
      7.675,
      12.381,
      19.565,
      5.615,
      12.089,
      21.036,
      5.615,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_112",
    polygon: [
      12.575,
      18.584,
      5.615,
      12.575,
      18.584,
      7.675,
      12.866,
      17.112,
      7.675,
      12.866,
      17.112,
      7.675,
      12.866,
      17.112,
      5.615,
      12.575,
      18.584,
      5.615,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_113",
    polygon: [
      13.06,
      16.131,
      5.615,
      13.06,
      16.131,
      7.675,
      13.352,
      14.66,
      7.675,
      13.352,
      14.66,
      7.675,
      13.352,
      14.66,
      5.615,
      13.06,
      16.131,
      5.615,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_114",
    polygon: [
      15.61,
      3.256,
      5.615,
      15.61,
      3.256,
      7.675,
      15.901,
      1.785,
      7.675,
      15.901,
      1.785,
      7.675,
      15.901,
      1.785,
      5.615,
      15.61,
      3.256,
      5.615,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_462",
    polygon: [
      -14.562,
      6.33,
      5.566,
      -14.562,
      6.33,
      7.711,
      -13.336,
      6.572,
      7.711,
      -13.336,
      6.572,
      7.711,
      -13.336,
      6.572,
      5.566,
      -14.562,
      6.33,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_32",
    polygon: [
      -1.588,
      16.799,
      5.615,
      -1.588,
      16.799,
      7.675,
      -1.88,
      18.27,
      7.675,
      -1.88,
      18.27,
      7.675,
      -1.88,
      18.27,
      5.615,
      -1.588,
      16.799,
      5.615,
    ],
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_419",
    polygon: [
      -13.336,
      6.572,
      2.876,
      -13.336,
      6.572,
      3.901,
      -12.109,
      6.815,
      3.901,
      -12.109,
      6.815,
      3.901,
      -12.109,
      6.815,
      2.876,
      -13.336,
      6.572,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_296",
    polygon: [
      -10.568,
      -7.406,
      5.566,
      -10.568,
      -7.406,
      7.711,
      -11.794,
      -7.649,
      7.711,
      -11.794,
      -7.649,
      7.711,
      -11.794,
      -7.649,
      5.566,
      -10.568,
      -7.406,
      5.566,
    ],
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_262",
    polygon: [
      1.694,
      -4.978,
      1.076,
      1.694,
      -4.978,
      2.876,
      0.468,
      -5.221,
      2.876,
      0.468,
      -5.221,
      2.876,
      0.468,
      -5.221,
      1.076,
      1.694,
      -4.978,
      1.076,
    ],
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_34",
    polygon: [
      -2.559,
      21.704,
      5.615,
      -2.559,
      21.704,
      7.675,
      -2.851,
      23.175,
      7.675,
      -2.851,
      23.175,
      7.675,
      -2.851,
      23.175,
      5.615,
      -2.559,
      21.704,
      5.615,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_35",
    polygon: [
      -3.045,
      24.156,
      5.615,
      -3.045,
      24.156,
      7.675,
      -3.336,
      25.627,
      7.675,
      -3.336,
      25.627,
      7.675,
      -3.336,
      25.627,
      5.615,
      -3.045,
      24.156,
      5.615,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_37",
    polygon: [
      3.501,
      -8.953,
      5.615,
      3.501,
      -8.953,
      7.675,
      3.209,
      -7.482,
      7.675,
      3.209,
      -7.482,
      7.675,
      3.209,
      -7.482,
      5.615,
      3.501,
      -8.953,
      5.615,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_38",
    polygon: [
      3.986,
      -11.406,
      5.615,
      3.986,
      -11.406,
      7.675,
      3.695,
      -9.934,
      7.675,
      3.695,
      -9.934,
      7.675,
      3.695,
      -9.934,
      5.615,
      3.986,
      -11.406,
      5.615,
    ],
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_404",
    polygon: [
      -19.466,
      5.358,
      2.876,
      -19.466,
      5.358,
      3.901,
      -18.24,
      5.601,
      3.901,
      -18.24,
      5.601,
      3.901,
      -18.24,
      5.601,
      2.876,
      -19.466,
      5.358,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_428",
    polygon: [
      -9.657,
      7.301,
      2.876,
      -9.657,
      7.301,
      3.901,
      -8.431,
      7.544,
      3.901,
      -8.431,
      7.544,
      3.901,
      -8.431,
      7.544,
      2.876,
      -9.657,
      7.301,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_455",
    polygon: [
      -1.074,
      9.0,
      0.4,
      -1.074,
      9.0,
      1.076,
      -0.421,
      9.129,
      1.076,
      -0.421,
      9.129,
      1.076,
      -0.421,
      9.129,
      0.4,
      -1.074,
      9.0,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_117",
    polygon: [
      16.217,
      0.19,
      5.615,
      16.217,
      0.19,
      7.675,
      16.508,
      -1.282,
      7.675,
      16.508,
      -1.282,
      7.675,
      16.508,
      -1.282,
      5.615,
      16.217,
      0.19,
      5.615,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_474",
    polygon: [
      -12.109,
      6.815,
      5.566,
      -12.109,
      6.815,
      7.711,
      -10.883,
      7.058,
      7.711,
      -10.883,
      7.058,
      7.711,
      -10.883,
      7.058,
      5.566,
      -12.109,
      6.815,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_475",
    polygon: [
      -7.205,
      7.786,
      5.566,
      -7.205,
      7.786,
      7.711,
      -5.978,
      8.029,
      7.711,
      -5.978,
      8.029,
      7.711,
      -5.978,
      8.029,
      5.566,
      -7.205,
      7.786,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_152",
    polygon: [
      16.764,
      -2.57,
      9.365,
      16.764,
      -2.57,
      11.425,
      17.418,
      -5.876,
      11.425,
      17.418,
      -5.876,
      11.425,
      17.418,
      -5.876,
      9.365,
      16.764,
      -2.57,
      9.365,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 10:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_261",
    polygon: [
      1.694,
      -4.978,
      0.4,
      1.694,
      -4.978,
      1.076,
      0.468,
      -5.221,
      1.076,
      0.468,
      -5.221,
      1.076,
      0.468,
      -5.221,
      0.4,
      1.694,
      -4.978,
      0.4,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_403",
    polygon: [
      -19.466,
      5.358,
      1.076,
      -19.466,
      5.358,
      2.876,
      -18.24,
      5.601,
      2.876,
      -18.24,
      5.601,
      2.876,
      -18.24,
      5.601,
      1.076,
      -19.466,
      5.358,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_239",
    polygon: [
      -5.663,
      -6.435,
      0.4,
      -5.663,
      -6.435,
      1.076,
      -6.889,
      -6.678,
      1.076,
      -6.889,
      -6.678,
      1.076,
      -6.889,
      -6.678,
      0.4,
      -5.663,
      -6.435,
      0.4,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_255",
    polygon: [
      -0.758,
      -5.464,
      2.876,
      -0.758,
      -5.464,
      3.901,
      -1.764,
      -5.663,
      3.901,
      -1.764,
      -5.663,
      3.901,
      -1.764,
      -5.663,
      2.876,
      -0.758,
      -5.464,
      2.876,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_306",
    polygon: [
      1.694,
      -4.978,
      5.566,
      1.694,
      -4.978,
      7.711,
      0.468,
      -5.221,
      7.711,
      0.468,
      -5.221,
      7.711,
      0.468,
      -5.221,
      5.566,
      1.694,
      -4.978,
      5.566,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_129",
    polygon: [
      17.674,
      -7.167,
      9.365,
      17.674,
      -7.167,
      11.425,
      17.965,
      -8.639,
      11.425,
      17.965,
      -8.639,
      11.425,
      17.965,
      -8.639,
      9.365,
      17.674,
      -7.167,
      9.365,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_179",
    polygon: [
      6.17,
      -22.434,
      -0.12,
      6.17,
      -22.434,
      3.68,
      5.96,
      -21.374,
      3.68,
      5.96,
      -21.374,
      3.68,
      5.96,
      -21.374,
      -0.12,
      6.17,
      -22.434,
      -0.12,
    ],
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_202",
    polygon: [
      -20.156,
      -9.305,
      0.4,
      -20.156,
      -9.305,
      1.076,
      -20.377,
      -9.349,
      1.076,
      -20.377,
      -9.349,
      1.076,
      -20.377,
      -9.349,
      0.4,
      -20.156,
      -9.305,
      0.4,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_31",
    polygon: [
      -1.103,
      14.346,
      5.615,
      -1.103,
      14.346,
      7.675,
      -1.394,
      15.818,
      7.675,
      -1.394,
      15.818,
      7.675,
      -1.394,
      15.818,
      5.615,
      -1.103,
      14.346,
      5.615,
    ],
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_295",
    polygon: [
      -11.794,
      -7.649,
      5.566,
      -11.794,
      -7.649,
      7.711,
      -13.02,
      -7.892,
      7.711,
      -13.02,
      -7.892,
      7.711,
      -13.02,
      -7.892,
      5.566,
      -11.794,
      -7.649,
      5.566,
    ],
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_427",
    polygon: [
      -9.657,
      7.301,
      1.076,
      -9.657,
      7.301,
      2.876,
      -8.431,
      7.544,
      2.876,
      -8.431,
      7.544,
      2.876,
      -8.431,
      7.544,
      1.076,
      -9.657,
      7.301,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_418",
    polygon: [
      -13.336,
      6.572,
      1.076,
      -13.336,
      6.572,
      2.876,
      -12.109,
      6.815,
      2.876,
      -12.109,
      6.815,
      2.876,
      -12.109,
      6.815,
      1.076,
      -13.336,
      6.572,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_454",
    polygon: [
      -4.752,
      8.272,
      2.876,
      -4.752,
      8.272,
      3.901,
      -4.531,
      8.316,
      3.901,
      -4.531,
      8.316,
      3.901,
      -4.531,
      8.316,
      2.876,
      -4.752,
      8.272,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_238",
    polygon: [
      -6.889,
      -6.678,
      2.876,
      -6.889,
      -6.678,
      3.901,
      -8.115,
      -6.921,
      3.901,
      -8.115,
      -6.921,
      3.901,
      -8.115,
      -6.921,
      2.876,
      -6.889,
      -6.678,
      2.876,
    ],
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_267",
    polygon: [
      -20.377,
      -9.349,
      2.876,
      -20.377,
      -9.349,
      3.901,
      -21.03,
      -9.478,
      3.901,
      -21.03,
      -9.478,
      3.901,
      -21.03,
      -9.478,
      2.876,
      -20.377,
      -9.349,
      2.876,
    ],
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_247",
    polygon: [
      -3.21,
      -5.95,
      2.876,
      -3.21,
      -5.95,
      3.901,
      -4.437,
      -6.192,
      3.901,
      -4.437,
      -6.192,
      3.901,
      -4.437,
      -6.192,
      2.876,
      -3.21,
      -5.95,
      2.876,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_231",
    polygon: [
      -9.341,
      -7.163,
      1.076,
      -9.341,
      -7.163,
      2.876,
      -10.568,
      -7.406,
      2.876,
      -10.568,
      -7.406,
      2.876,
      -10.568,
      -7.406,
      1.076,
      -9.341,
      -7.163,
      1.076,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_260",
    polygon: [
      0.468,
      -5.221,
      2.876,
      0.468,
      -5.221,
      3.901,
      0.247,
      -5.265,
      3.901,
      0.247,
      -5.265,
      3.901,
      0.247,
      -5.265,
      2.876,
      0.468,
      -5.221,
      2.876,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_266",
    polygon: [
      2.347,
      -4.849,
      2.876,
      2.347,
      -4.849,
      3.901,
      1.694,
      -4.978,
      3.901,
      1.694,
      -4.978,
      3.901,
      1.694,
      -4.978,
      2.876,
      2.347,
      -4.849,
      2.876,
    ],
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_399",
    polygon: [
      -20.693,
      5.116,
      0.4,
      -20.693,
      5.116,
      1.076,
      -19.466,
      5.358,
      1.076,
      -19.466,
      5.358,
      1.076,
      -19.466,
      5.358,
      0.4,
      -20.693,
      5.116,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_237",
    polygon: [
      -6.889,
      -6.678,
      1.076,
      -6.889,
      -6.678,
      2.876,
      -8.115,
      -6.921,
      2.876,
      -8.115,
      -6.921,
      2.876,
      -8.115,
      -6.921,
      1.076,
      -6.889,
      -6.678,
      1.076,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_230",
    polygon: [
      -9.341,
      -7.163,
      0.4,
      -9.341,
      -7.163,
      1.076,
      -10.568,
      -7.406,
      1.076,
      -10.568,
      -7.406,
      1.076,
      -10.568,
      -7.406,
      0.4,
      -9.341,
      -7.163,
      0.4,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_227",
    polygon: [
      -10.568,
      -7.406,
      0.4,
      -10.568,
      -7.406,
      1.076,
      -11.794,
      -7.649,
      1.076,
      -11.794,
      -7.649,
      1.076,
      -11.794,
      -7.649,
      0.4,
      -10.568,
      -7.406,
      0.4,
    ],
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_265",
    polygon: [
      2.347,
      -4.849,
      1.076,
      2.347,
      -4.849,
      2.876,
      1.694,
      -4.978,
      2.876,
      1.694,
      -4.978,
      2.876,
      1.694,
      -4.978,
      1.076,
      2.347,
      -4.849,
      1.076,
    ],
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_229",
    polygon: [
      -10.568,
      -7.406,
      2.876,
      -10.568,
      -7.406,
      3.901,
      -11.794,
      -7.649,
      3.901,
      -11.794,
      -7.649,
      3.901,
      -11.794,
      -7.649,
      2.876,
      -10.568,
      -7.406,
      2.876,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_426",
    polygon: [
      -9.657,
      7.301,
      0.4,
      -9.657,
      7.301,
      1.076,
      -8.431,
      7.544,
      1.076,
      -8.431,
      7.544,
      1.076,
      -8.431,
      7.544,
      0.4,
      -9.657,
      7.301,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_453",
    polygon: [
      -4.752,
      8.272,
      0.4,
      -4.752,
      8.272,
      1.076,
      -4.531,
      8.316,
      1.076,
      -4.531,
      8.316,
      1.076,
      -4.531,
      8.316,
      0.4,
      -4.752,
      8.272,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_236",
    polygon: [
      -6.889,
      -6.678,
      0.4,
      -6.889,
      -6.678,
      1.076,
      -8.115,
      -6.921,
      1.076,
      -8.115,
      -6.921,
      1.076,
      -8.115,
      -6.921,
      0.4,
      -6.889,
      -6.678,
      0.4,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_294",
    polygon: [
      -13.02,
      -7.892,
      5.566,
      -13.02,
      -7.892,
      7.711,
      -14.246,
      -8.135,
      7.711,
      -14.246,
      -8.135,
      7.711,
      -14.246,
      -8.135,
      5.566,
      -13.02,
      -7.892,
      5.566,
    ],
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_30",
    polygon: [
      -0.617,
      11.894,
      5.615,
      -0.617,
      11.894,
      7.675,
      -0.908,
      13.366,
      7.675,
      -0.908,
      13.366,
      7.675,
      -0.908,
      13.366,
      5.615,
      -0.617,
      11.894,
      5.615,
    ],
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_413",
    polygon: [
      -15.788,
      6.087,
      2.876,
      -15.788,
      6.087,
      3.901,
      -14.562,
      6.33,
      3.901,
      -14.562,
      6.33,
      3.901,
      -14.562,
      6.33,
      2.876,
      -15.788,
      6.087,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_246",
    polygon: [
      -3.21,
      -5.95,
      1.076,
      -3.21,
      -5.95,
      2.876,
      -4.437,
      -6.192,
      2.876,
      -4.437,
      -6.192,
      2.876,
      -4.437,
      -6.192,
      1.076,
      -3.21,
      -5.95,
      1.076,
    ],
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_254",
    polygon: [
      -0.758,
      -5.464,
      0.4,
      -0.758,
      -5.464,
      2.876,
      -1.764,
      -5.663,
      2.876,
      -1.764,
      -5.663,
      2.876,
      -1.764,
      -5.663,
      0.4,
      -0.758,
      -5.464,
      0.4,
    ],
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_259",
    polygon: [
      0.468,
      -5.221,
      0.4,
      0.468,
      -5.221,
      1.076,
      0.247,
      -5.265,
      1.076,
      0.247,
      -5.265,
      1.076,
      0.247,
      -5.265,
      0.4,
      0.468,
      -5.221,
      0.4,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_258",
    polygon: [
      0.468,
      -5.221,
      1.076,
      0.468,
      -5.221,
      2.876,
      0.247,
      -5.265,
      2.876,
      0.247,
      -5.265,
      2.876,
      0.247,
      -5.265,
      1.076,
      0.468,
      -5.221,
      1.076,
    ],
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_264",
    polygon: [
      2.347,
      -4.849,
      0.4,
      2.347,
      -4.849,
      1.076,
      1.694,
      -4.978,
      1.076,
      1.694,
      -4.978,
      1.076,
      1.694,
      -4.978,
      0.4,
      2.347,
      -4.849,
      0.4,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_235",
    polygon: [
      -8.115,
      -6.921,
      2.876,
      -8.115,
      -6.921,
      3.901,
      -9.341,
      -7.163,
      3.901,
      -9.341,
      -7.163,
      3.901,
      -9.341,
      -7.163,
      2.876,
      -8.115,
      -6.921,
      2.876,
    ],
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_398",
    polygon: [
      -21.919,
      4.873,
      2.876,
      -21.919,
      4.873,
      3.901,
      -20.693,
      5.116,
      3.901,
      -20.693,
      5.116,
      3.901,
      -20.693,
      5.116,
      2.876,
      -21.919,
      4.873,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_228",
    polygon: [
      -10.568,
      -7.406,
      1.076,
      -10.568,
      -7.406,
      2.876,
      -11.794,
      -7.649,
      2.876,
      -11.794,
      -7.649,
      2.876,
      -11.794,
      -7.649,
      1.076,
      -10.568,
      -7.406,
      1.076,
    ],
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_412",
    polygon: [
      -15.788,
      6.087,
      1.076,
      -15.788,
      6.087,
      2.876,
      -14.562,
      6.33,
      2.876,
      -14.562,
      6.33,
      2.876,
      -14.562,
      6.33,
      1.076,
      -15.788,
      6.087,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_245",
    polygon: [
      -3.21,
      -5.95,
      0.4,
      -3.21,
      -5.95,
      1.076,
      -4.437,
      -6.192,
      1.076,
      -4.437,
      -6.192,
      1.076,
      -4.437,
      -6.192,
      0.4,
      -3.21,
      -5.95,
      0.4,
    ],
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_263",
    polygon: [
      1.694,
      -4.978,
      2.876,
      1.694,
      -4.978,
      3.901,
      0.468,
      -5.221,
      3.901,
      0.468,
      -5.221,
      3.901,
      0.468,
      -5.221,
      2.876,
      1.694,
      -4.978,
      2.876,
    ],
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_452",
    polygon: [
      -4.752,
      8.272,
      1.076,
      -4.752,
      8.272,
      2.876,
      -4.531,
      8.316,
      2.876,
      -4.531,
      8.316,
      2.876,
      -4.531,
      8.316,
      1.076,
      -4.752,
      8.272,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_257",
    polygon: [
      0.247,
      -5.265,
      2.876,
      0.247,
      -5.265,
      3.901,
      -0.758,
      -5.464,
      3.901,
      -0.758,
      -5.464,
      3.901,
      -0.758,
      -5.464,
      2.876,
      0.247,
      -5.265,
      2.876,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_234",
    polygon: [
      -8.115,
      -6.921,
      1.076,
      -8.115,
      -6.921,
      2.876,
      -9.341,
      -7.163,
      2.876,
      -9.341,
      -7.163,
      2.876,
      -9.341,
      -7.163,
      1.076,
      -8.115,
      -6.921,
      1.076,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_425",
    polygon: [
      -10.883,
      7.058,
      2.876,
      -10.883,
      7.058,
      3.901,
      -9.657,
      7.301,
      3.901,
      -9.657,
      7.301,
      3.901,
      -9.657,
      7.301,
      2.876,
      -10.883,
      7.058,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_253",
    polygon: [
      -1.764,
      -5.663,
      2.876,
      -1.764,
      -5.663,
      3.901,
      -1.984,
      -5.707,
      3.901,
      -1.984,
      -5.707,
      3.901,
      -1.984,
      -5.707,
      2.876,
      -1.764,
      -5.663,
      2.876,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_293",
    polygon: [
      -14.246,
      -8.135,
      5.566,
      -14.246,
      -8.135,
      7.711,
      -15.472,
      -8.377,
      7.711,
      -15.472,
      -8.377,
      7.711,
      -15.472,
      -8.377,
      5.566,
      -14.246,
      -8.135,
      5.566,
    ],
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_232",
    polygon: [
      -9.341,
      -7.163,
      2.876,
      -9.341,
      -7.163,
      3.901,
      -10.568,
      -7.406,
      3.901,
      -10.568,
      -7.406,
      3.901,
      -10.568,
      -7.406,
      2.876,
      -9.341,
      -7.163,
      2.876,
    ],
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_226",
    polygon: [
      -11.794,
      -7.649,
      2.876,
      -11.794,
      -7.649,
      3.901,
      -13.02,
      -7.892,
      3.901,
      -13.02,
      -7.892,
      3.901,
      -13.02,
      -7.892,
      2.876,
      -11.794,
      -7.649,
      2.876,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_233",
    polygon: [
      -8.115,
      -6.921,
      0.4,
      -8.115,
      -6.921,
      1.076,
      -9.341,
      -7.163,
      1.076,
      -9.341,
      -7.163,
      1.076,
      -9.341,
      -7.163,
      0.4,
      -8.115,
      -6.921,
      0.4,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_256",
    polygon: [
      0.247,
      -5.265,
      0.4,
      0.247,
      -5.265,
      2.876,
      -0.758,
      -5.464,
      2.876,
      -0.758,
      -5.464,
      2.876,
      -0.758,
      -5.464,
      0.4,
      0.247,
      -5.265,
      0.4,
    ],
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_244",
    polygon: [
      -4.437,
      -6.192,
      2.876,
      -4.437,
      -6.192,
      3.901,
      -5.663,
      -6.435,
      3.901,
      -5.663,
      -6.435,
      3.901,
      -5.663,
      -6.435,
      2.876,
      -4.437,
      -6.192,
      2.876,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_411",
    polygon: [
      -15.788,
      6.087,
      0.4,
      -15.788,
      6.087,
      1.076,
      -14.562,
      6.33,
      1.076,
      -14.562,
      6.33,
      1.076,
      -14.562,
      6.33,
      0.4,
      -15.788,
      6.087,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_225",
    polygon: [
      -11.794,
      -7.649,
      1.076,
      -11.794,
      -7.649,
      2.876,
      -13.02,
      -7.892,
      2.876,
      -13.02,
      -7.892,
      2.876,
      -13.02,
      -7.892,
      1.076,
      -11.794,
      -7.649,
      1.076,
    ],
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_397",
    polygon: [
      -21.919,
      4.873,
      1.076,
      -21.919,
      4.873,
      2.876,
      -20.693,
      5.116,
      2.876,
      -20.693,
      5.116,
      2.876,
      -20.693,
      5.116,
      1.076,
      -21.919,
      4.873,
      1.076,
    ],
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_252",
    polygon: [
      -1.764,
      -5.663,
      1.076,
      -1.764,
      -5.663,
      2.876,
      -1.984,
      -5.707,
      2.876,
      -1.984,
      -5.707,
      2.876,
      -1.984,
      -5.707,
      1.076,
      -1.764,
      -5.663,
      1.076,
    ],
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_209",
    polygon: [
      -17.925,
      -8.863,
      1.076,
      -17.925,
      -8.863,
      2.876,
      -18.146,
      -8.907,
      2.876,
      -18.146,
      -8.907,
      2.876,
      -18.146,
      -8.907,
      1.076,
      -17.925,
      -8.863,
      1.076,
    ],
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_208",
    polygon: [
      -18.146,
      -8.907,
      2.876,
      -18.146,
      -8.907,
      3.901,
      -19.151,
      -9.106,
      3.901,
      -19.151,
      -9.106,
      3.901,
      -19.151,
      -9.106,
      2.876,
      -18.146,
      -8.907,
      2.876,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_445",
    polygon: [
      -4.531,
      8.316,
      2.876,
      -4.531,
      8.316,
      3.901,
      -3.526,
      8.515,
      3.901,
      -3.526,
      8.515,
      3.901,
      -3.526,
      8.515,
      2.876,
      -4.531,
      8.316,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_396",
    polygon: [
      -21.919,
      4.873,
      0.4,
      -21.919,
      4.873,
      1.076,
      -20.693,
      5.116,
      1.076,
      -20.693,
      5.116,
      1.076,
      -20.693,
      5.116,
      0.4,
      -21.919,
      4.873,
      0.4,
    ],
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_243",
    polygon: [
      -4.437,
      -6.192,
      1.076,
      -4.437,
      -6.192,
      2.876,
      -5.663,
      -6.435,
      2.876,
      -5.663,
      -6.435,
      2.876,
      -5.663,
      -6.435,
      1.076,
      -4.437,
      -6.192,
      1.076,
    ],
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_410",
    polygon: [
      -17.014,
      5.844,
      2.876,
      -17.014,
      5.844,
      3.901,
      -15.788,
      6.087,
      3.901,
      -15.788,
      6.087,
      3.901,
      -15.788,
      6.087,
      2.876,
      -17.014,
      5.844,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_251",
    polygon: [
      -1.764,
      -5.663,
      0.4,
      -1.764,
      -5.663,
      1.076,
      -1.984,
      -5.707,
      1.076,
      -1.984,
      -5.707,
      1.076,
      -1.984,
      -5.707,
      0.4,
      -1.764,
      -5.663,
      0.4,
    ],
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_224",
    polygon: [
      -11.794,
      -7.649,
      0.4,
      -11.794,
      -7.649,
      1.076,
      -13.02,
      -7.892,
      1.076,
      -13.02,
      -7.892,
      1.076,
      -13.02,
      -7.892,
      0.4,
      -11.794,
      -7.649,
      0.4,
    ],
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_207",
    polygon: [
      -18.146,
      -8.907,
      0.4,
      -18.146,
      -8.907,
      2.876,
      -19.151,
      -9.106,
      2.876,
      -19.151,
      -9.106,
      2.876,
      -19.151,
      -9.106,
      0.4,
      -18.146,
      -8.907,
      0.4,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_223",
    polygon: [
      -13.02,
      -7.892,
      2.876,
      -13.02,
      -7.892,
      3.901,
      -14.246,
      -8.135,
      3.901,
      -14.246,
      -8.135,
      3.901,
      -14.246,
      -8.135,
      2.876,
      -13.02,
      -7.892,
      2.876,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_250",
    polygon: [
      -1.984,
      -5.707,
      2.876,
      -1.984,
      -5.707,
      3.901,
      -3.21,
      -5.95,
      3.901,
      -3.21,
      -5.95,
      3.901,
      -3.21,
      -5.95,
      2.876,
      -1.984,
      -5.707,
      2.876,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_242",
    polygon: [
      -4.437,
      -6.192,
      0.4,
      -4.437,
      -6.192,
      1.076,
      -5.663,
      -6.435,
      1.076,
      -5.663,
      -6.435,
      1.076,
      -5.663,
      -6.435,
      0.4,
      -4.437,
      -6.192,
      0.4,
    ],
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_249",
    polygon: [
      -1.984,
      -5.707,
      1.076,
      -1.984,
      -5.707,
      2.876,
      -3.21,
      -5.95,
      2.876,
      -3.21,
      -5.95,
      2.876,
      -3.21,
      -5.95,
      1.076,
      -1.984,
      -5.707,
      1.076,
    ],
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_241",
    polygon: [
      -5.663,
      -6.435,
      2.876,
      -5.663,
      -6.435,
      3.901,
      -6.889,
      -6.678,
      3.901,
      -6.889,
      -6.678,
      3.901,
      -6.889,
      -6.678,
      2.876,
      -5.663,
      -6.435,
      2.876,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_240",
    polygon: [
      -5.663,
      -6.435,
      1.076,
      -5.663,
      -6.435,
      2.876,
      -6.889,
      -6.678,
      2.876,
      -6.889,
      -6.678,
      2.876,
      -6.889,
      -6.678,
      1.076,
      -5.663,
      -6.435,
      1.076,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_248",
    polygon: [
      -1.984,
      -5.707,
      0.4,
      -1.984,
      -5.707,
      1.076,
      -3.21,
      -5.95,
      1.076,
      -3.21,
      -5.95,
      1.076,
      -3.21,
      -5.95,
      0.4,
      -1.984,
      -5.707,
      0.4,
    ],
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_501",
    polygon: [
      -1.074,
      9.0,
      13.076,
      -1.074,
      9.0,
      15.221,
      -0.421,
      9.129,
      15.221,
      -0.421,
      9.129,
      15.221,
      -0.421,
      9.129,
      13.076,
      -1.074,
      9.0,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_532",
    polygon: [
      5.322,
      -5.28,
      20.62,
      5.322,
      -5.28,
      22.52,
      5.079,
      -4.053,
      22.52,
      5.079,
      -4.053,
      22.52,
      5.079,
      -4.053,
      20.62,
      5.322,
      -5.28,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_534",
    polygon: [
      5.565,
      -6.506,
      19.7,
      5.565,
      -6.506,
      22.52,
      5.322,
      -5.28,
      22.52,
      5.322,
      -5.28,
      22.52,
      5.322,
      -5.28,
      19.7,
      5.565,
      -6.506,
      19.7,
    ],
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_384",
    polygon: [
      -0.758,
      -5.464,
      13.076,
      -0.758,
      -5.464,
      15.221,
      -1.984,
      -5.707,
      15.221,
      -1.984,
      -5.707,
      15.221,
      -1.984,
      -5.707,
      13.076,
      -0.758,
      -5.464,
      13.076,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_502",
    polygon: [
      -14.562,
      6.33,
      13.076,
      -14.562,
      6.33,
      15.221,
      -13.336,
      6.572,
      15.221,
      -13.336,
      6.572,
      15.221,
      -13.336,
      6.572,
      13.076,
      -14.562,
      6.33,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_548",
    polygon: [
      7.264,
      -15.089,
      20.62,
      7.264,
      -15.089,
      22.52,
      7.021,
      -13.863,
      22.52,
      7.021,
      -13.863,
      22.52,
      7.021,
      -13.863,
      20.62,
      7.264,
      -15.089,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_503",
    polygon: [
      -15.788,
      6.087,
      13.076,
      -15.788,
      6.087,
      15.221,
      -14.562,
      6.33,
      15.221,
      -14.562,
      6.33,
      15.221,
      -14.562,
      6.33,
      13.076,
      -15.788,
      6.087,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_51",
    polygon: [
      -0.627,
      11.892,
      13.115,
      -0.627,
      11.892,
      15.175,
      -0.918,
      13.364,
      15.175,
      -0.918,
      13.364,
      15.175,
      -0.918,
      13.364,
      13.115,
      -0.627,
      11.892,
      13.115,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_376",
    polygon: [
      -10.568,
      -7.406,
      13.076,
      -10.568,
      -7.406,
      15.221,
      -11.794,
      -7.649,
      15.221,
      -11.794,
      -7.649,
      15.221,
      -11.794,
      -7.649,
      13.076,
      -10.568,
      -7.406,
      13.076,
    ],
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_535",
    polygon: [
      5.807,
      -7.732,
      19.7,
      5.807,
      -7.732,
      20.62,
      5.565,
      -6.506,
      20.62,
      5.565,
      -6.506,
      20.62,
      5.565,
      -6.506,
      19.7,
      5.807,
      -7.732,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_539",
    polygon: [
      6.293,
      -10.184,
      19.7,
      6.293,
      -10.184,
      20.62,
      6.05,
      -8.958,
      20.62,
      6.05,
      -8.958,
      20.62,
      6.05,
      -8.958,
      19.7,
      6.293,
      -10.184,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_62",
    polygon: [
      3.501,
      -8.953,
      13.115,
      3.501,
      -8.953,
      15.175,
      3.209,
      -7.482,
      15.175,
      3.209,
      -7.482,
      15.175,
      3.209,
      -7.482,
      13.115,
      3.501,
      -8.953,
      13.115,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_540",
    polygon: [
      6.293,
      -10.184,
      20.62,
      6.293,
      -10.184,
      22.52,
      6.05,
      -8.958,
      22.52,
      6.05,
      -8.958,
      22.52,
      6.05,
      -8.958,
      20.62,
      6.293,
      -10.184,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_511",
    polygon: [
      -19.466,
      5.358,
      13.076,
      -19.466,
      5.358,
      15.221,
      -18.24,
      5.601,
      15.221,
      -18.24,
      5.601,
      15.221,
      -18.24,
      5.601,
      13.076,
      -19.466,
      5.358,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_496",
    polygon: [
      -8.431,
      7.544,
      9.326,
      -8.431,
      7.544,
      11.471,
      -7.205,
      7.786,
      11.471,
      -7.205,
      7.786,
      11.471,
      -7.205,
      7.786,
      9.326,
      -8.431,
      7.544,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_68",
    polygon: [
      3.501,
      -8.953,
      16.865,
      3.501,
      -8.953,
      18.925,
      3.209,
      -7.482,
      18.925,
      3.209,
      -7.482,
      18.925,
      3.209,
      -7.482,
      16.865,
      3.501,
      -8.953,
      16.865,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_64",
    polygon: [
      4.472,
      -13.858,
      13.115,
      4.472,
      -13.858,
      15.175,
      4.181,
      -12.386,
      15.175,
      4.181,
      -12.386,
      15.175,
      4.181,
      -12.386,
      13.115,
      4.472,
      -13.858,
      13.115,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_549",
    polygon: [
      7.507,
      -16.315,
      19.7,
      7.507,
      -16.315,
      20.62,
      7.264,
      -15.089,
      20.62,
      7.264,
      -15.089,
      20.62,
      7.264,
      -15.089,
      19.7,
      7.507,
      -16.315,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_377",
    polygon: [
      -9.341,
      -7.163,
      13.076,
      -9.341,
      -7.163,
      15.221,
      -10.568,
      -7.406,
      15.221,
      -10.568,
      -7.406,
      15.221,
      -10.568,
      -7.406,
      13.076,
      -9.341,
      -7.163,
      13.076,
    ],
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_63",
    polygon: [
      3.986,
      -11.406,
      13.115,
      3.986,
      -11.406,
      15.175,
      3.695,
      -9.934,
      15.175,
      3.695,
      -9.934,
      15.175,
      3.695,
      -9.934,
      13.115,
      3.986,
      -11.406,
      13.115,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_133",
    polygon: [
      15.61,
      3.256,
      13.115,
      15.61,
      3.256,
      15.175,
      15.901,
      1.785,
      15.175,
      15.901,
      1.785,
      15.175,
      15.901,
      1.785,
      13.115,
      15.61,
      3.256,
      13.115,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_523",
    polygon: [
      4.351,
      -0.375,
      19.7,
      4.351,
      -0.375,
      22.52,
      4.098,
      0.903,
      22.52,
      4.098,
      0.903,
      22.52,
      4.098,
      0.903,
      19.7,
      4.351,
      -0.375,
      19.7,
    ],
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_138",
    polygon: [
      18.159,
      -9.62,
      13.115,
      18.159,
      -9.62,
      15.175,
      18.451,
      -11.091,
      15.175,
      18.451,
      -11.091,
      15.175,
      18.451,
      -11.091,
      13.115,
      18.159,
      -9.62,
      13.115,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_197",
    polygon: [
      -3.49,
      1.898,
      19.7,
      -3.49,
      1.898,
      22.5,
      -3.684,
      2.879,
      22.5,
      -3.684,
      2.879,
      22.5,
      -3.684,
      2.879,
      19.7,
      -3.49,
      1.898,
      19.7,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_69",
    polygon: [
      3.986,
      -11.406,
      16.865,
      3.986,
      -11.406,
      18.925,
      3.695,
      -9.934,
      18.925,
      3.695,
      -9.934,
      18.925,
      3.695,
      -9.934,
      16.865,
      3.986,
      -11.406,
      16.865,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_573",
    polygon: [
      0.952,
      16.792,
      19.7,
      0.952,
      16.792,
      20.62,
      0.819,
      17.462,
      20.62,
      0.819,
      17.462,
      20.62,
      0.819,
      17.462,
      19.7,
      0.952,
      16.792,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_189",
    polygon: [
      -3.684,
      2.877,
      15.95,
      -3.684,
      2.877,
      18.75,
      -3.878,
      3.858,
      18.75,
      -3.878,
      3.858,
      18.75,
      -3.878,
      3.858,
      15.95,
      -3.684,
      2.877,
      15.95,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_515",
    polygon: [
      -7.205,
      7.786,
      13.076,
      -7.205,
      7.786,
      15.221,
      -5.978,
      8.029,
      15.221,
      -5.978,
      8.029,
      15.221,
      -5.978,
      8.029,
      13.076,
      -7.205,
      7.786,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_65",
    polygon: [
      5.2,
      -17.537,
      13.115,
      5.2,
      -17.537,
      15.175,
      4.909,
      -16.065,
      15.175,
      4.909,
      -16.065,
      15.175,
      4.909,
      -16.065,
      13.115,
      5.2,
      -17.537,
      13.115,
    ],
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_497",
    polygon: [
      -5.978,
      8.029,
      9.326,
      -5.978,
      8.029,
      11.471,
      -4.752,
      8.272,
      11.471,
      -4.752,
      8.272,
      11.471,
      -4.752,
      8.272,
      9.326,
      -5.978,
      8.029,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_368",
    polygon: [
      -20.377,
      -9.349,
      13.076,
      -20.377,
      -9.349,
      15.221,
      -21.03,
      -9.478,
      15.221,
      -21.03,
      -9.478,
      15.221,
      -21.03,
      -9.478,
      13.076,
      -20.377,
      -9.349,
      13.076,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_198",
    polygon: [
      -3.296,
      0.918,
      19.7,
      -3.296,
      0.918,
      22.5,
      -3.49,
      1.899,
      22.5,
      -3.49,
      1.899,
      22.5,
      -3.49,
      1.899,
      19.7,
      -3.296,
      0.918,
      19.7,
    ],
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_70",
    polygon: [
      4.472,
      -13.858,
      16.865,
      4.472,
      -13.858,
      18.925,
      4.181,
      -12.386,
      18.925,
      4.181,
      -12.386,
      18.925,
      4.181,
      -12.386,
      16.865,
      4.472,
      -13.858,
      16.865,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_541",
    polygon: [
      6.536,
      -11.411,
      19.7,
      6.536,
      -11.411,
      20.62,
      6.293,
      -10.184,
      20.62,
      6.293,
      -10.184,
      20.62,
      6.293,
      -10.184,
      19.7,
      6.536,
      -11.411,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_574",
    polygon: [
      15.852,
      -10.84,
      19.7,
      15.852,
      -10.84,
      22.52,
      16.106,
      -12.118,
      22.52,
      16.106,
      -12.118,
      22.52,
      16.106,
      -12.118,
      19.7,
      15.852,
      -10.84,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_190",
    polygon: [
      -3.49,
      1.898,
      15.95,
      -3.49,
      1.898,
      18.75,
      -3.684,
      2.879,
      18.75,
      -3.684,
      2.879,
      18.75,
      -3.684,
      2.879,
      15.95,
      -3.49,
      1.898,
      15.95,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_134",
    polygon: [
      15.003,
      6.322,
      13.115,
      15.003,
      6.322,
      15.175,
      15.294,
      4.85,
      15.175,
      15.294,
      4.85,
      15.175,
      15.294,
      4.85,
      13.115,
      15.003,
      6.322,
      13.115,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_150",
    polygon: [
      14.093,
      10.918,
      13.115,
      14.093,
      10.918,
      15.175,
      14.747,
      7.612,
      15.175,
      14.747,
      7.612,
      15.175,
      14.747,
      7.612,
      13.115,
      14.093,
      10.918,
      13.115,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_381",
    polygon: [
      -4.437,
      -6.192,
      13.076,
      -4.437,
      -6.192,
      15.221,
      -5.663,
      -6.435,
      15.221,
      -5.663,
      -6.435,
      15.221,
      -5.663,
      -6.435,
      13.076,
      -4.437,
      -6.192,
      13.076,
    ],
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_524",
    polygon: [
      4.593,
      -1.601,
      19.7,
      4.593,
      -1.601,
      20.62,
      4.351,
      -0.375,
      20.62,
      4.351,
      -0.375,
      20.62,
      4.351,
      -0.375,
      19.7,
      4.593,
      -1.601,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_369",
    polygon: [
      -19.151,
      -9.106,
      13.076,
      -19.151,
      -9.106,
      15.221,
      -20.377,
      -9.349,
      15.221,
      -20.377,
      -9.349,
      15.221,
      -20.377,
      -9.349,
      13.076,
      -19.151,
      -9.106,
      13.076,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_164",
    polygon: [
      19.323,
      -15.495,
      16.865,
      19.323,
      -15.495,
      18.925,
      19.657,
      -17.182,
      18.925,
      19.657,
      -17.182,
      18.925,
      19.657,
      -17.182,
      16.865,
      19.323,
      -15.495,
      16.865,
    ],
    "05-01 05:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_48",
    polygon: [
      -2.084,
      19.249,
      13.115,
      -2.084,
      19.249,
      15.175,
      -2.375,
      20.721,
      15.175,
      -2.375,
      20.721,
      15.175,
      -2.375,
      20.721,
      13.115,
      -2.084,
      19.249,
      13.115,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_516",
    polygon: [
      -8.431,
      7.544,
      13.076,
      -8.431,
      7.544,
      15.221,
      -7.205,
      7.786,
      15.221,
      -7.205,
      7.786,
      15.221,
      -7.205,
      7.786,
      13.076,
      -8.431,
      7.544,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_73",
    polygon: [
      6.171,
      -22.441,
      16.865,
      6.171,
      -22.441,
      18.925,
      5.88,
      -20.97,
      18.925,
      5.88,
      -20.97,
      18.925,
      5.88,
      -20.97,
      16.865,
      6.171,
      -22.441,
      16.865,
    ],
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_153",
    polygon: [
      16.764,
      -2.57,
      13.115,
      16.764,
      -2.57,
      15.175,
      17.418,
      -5.876,
      15.175,
      17.418,
      -5.876,
      15.175,
      17.418,
      -5.876,
      13.115,
      16.764,
      -2.57,
      13.115,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_575",
    polygon: [
      15.61,
      -9.614,
      20.62,
      15.61,
      -9.614,
      22.52,
      15.852,
      -10.84,
      22.52,
      15.852,
      -10.84,
      22.52,
      15.852,
      -10.84,
      20.62,
      15.61,
      -9.614,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_191",
    polygon: [
      -3.296,
      0.918,
      15.95,
      -3.296,
      0.918,
      18.75,
      -3.49,
      1.899,
      18.75,
      -3.49,
      1.899,
      18.75,
      -3.49,
      1.899,
      15.95,
      -3.296,
      0.918,
      15.95,
    ],
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_370",
    polygon: [
      -17.925,
      -8.863,
      13.076,
      -17.925,
      -8.863,
      15.221,
      -19.151,
      -9.106,
      15.221,
      -19.151,
      -9.106,
      15.221,
      -19.151,
      -9.106,
      13.076,
      -17.925,
      -8.863,
      13.076,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_49",
    polygon: [
      -1.598,
      16.797,
      13.115,
      -1.598,
      16.797,
      15.175,
      -1.889,
      18.268,
      15.175,
      -1.889,
      18.268,
      15.175,
      -1.889,
      18.268,
      13.115,
      -1.598,
      16.797,
      13.115,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_526",
    polygon: [
      4.593,
      -1.601,
      20.62,
      4.593,
      -1.601,
      22.52,
      4.351,
      -0.375,
      22.52,
      4.351,
      -0.375,
      22.52,
      4.351,
      -0.375,
      20.62,
      4.593,
      -1.601,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_66",
    polygon: [
      5.686,
      -19.989,
      13.115,
      5.686,
      -19.989,
      15.175,
      5.395,
      -18.517,
      15.175,
      5.395,
      -18.517,
      15.175,
      5.395,
      -18.517,
      13.115,
      5.686,
      -19.989,
      13.115,
    ],
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_517",
    polygon: [
      -5.978,
      8.029,
      13.076,
      -5.978,
      8.029,
      15.221,
      -4.752,
      8.272,
      15.221,
      -4.752,
      8.272,
      15.221,
      -4.752,
      8.272,
      13.076,
      -5.978,
      8.029,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_185",
    polygon: [
      -4.46,
      6.796,
      15.95,
      -4.46,
      6.796,
      18.75,
      -4.654,
      7.777,
      18.75,
      -4.654,
      7.777,
      18.75,
      -4.654,
      7.777,
      15.95,
      -4.46,
      6.796,
      15.95,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_504",
    polygon: [
      -13.336,
      6.572,
      13.076,
      -13.336,
      6.572,
      15.221,
      -12.109,
      6.815,
      15.221,
      -12.109,
      6.815,
      15.221,
      -12.109,
      6.815,
      13.076,
      -13.336,
      6.572,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_382",
    polygon: [
      -3.21,
      -5.95,
      13.076,
      -3.21,
      -5.95,
      15.221,
      -4.437,
      -6.192,
      15.221,
      -4.437,
      -6.192,
      15.221,
      -4.437,
      -6.192,
      13.076,
      -3.21,
      -5.95,
      13.076,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_542",
    polygon: [
      6.536,
      -11.411,
      20.62,
      6.536,
      -11.411,
      22.52,
      6.293,
      -10.184,
      22.52,
      6.293,
      -10.184,
      22.52,
      6.293,
      -10.184,
      20.62,
      6.536,
      -11.411,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_135",
    polygon: [
      13.546,
      13.679,
      13.115,
      13.546,
      13.679,
      15.175,
      13.837,
      12.208,
      15.175,
      13.837,
      12.208,
      15.175,
      13.837,
      12.208,
      13.115,
      13.546,
      13.679,
      13.115,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_550",
    polygon: [
      7.507,
      -16.315,
      20.62,
      7.507,
      -16.315,
      22.52,
      7.264,
      -15.089,
      22.52,
      7.264,
      -15.089,
      22.52,
      7.264,
      -15.089,
      20.62,
      7.507,
      -16.315,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_192",
    polygon: [
      -4.46,
      6.796,
      19.7,
      -4.46,
      6.796,
      22.5,
      -4.654,
      7.777,
      22.5,
      -4.654,
      7.777,
      22.5,
      -4.654,
      7.777,
      19.7,
      -4.46,
      6.796,
      19.7,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_527",
    polygon: [
      4.836,
      -2.827,
      19.7,
      4.836,
      -2.827,
      20.62,
      4.593,
      -1.601,
      20.62,
      4.593,
      -1.601,
      20.62,
      4.593,
      -1.601,
      19.7,
      4.836,
      -2.827,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_576",
    polygon: [
      15.61,
      -9.614,
      19.7,
      15.61,
      -9.614,
      20.62,
      15.852,
      -10.84,
      20.62,
      15.852,
      -10.84,
      20.62,
      15.852,
      -10.84,
      19.7,
      15.61,
      -9.614,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_374",
    polygon: [
      -13.02,
      -7.892,
      13.076,
      -13.02,
      -7.892,
      15.221,
      -14.246,
      -8.135,
      15.221,
      -14.246,
      -8.135,
      15.221,
      -14.246,
      -8.135,
      13.076,
      -13.02,
      -7.892,
      13.076,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_50",
    polygon: [
      -1.112,
      14.345,
      13.115,
      -1.112,
      14.345,
      15.175,
      -1.404,
      15.816,
      15.175,
      -1.404,
      15.816,
      15.175,
      -1.404,
      15.816,
      13.115,
      -1.112,
      14.345,
      13.115,
    ],
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_518",
    polygon: [
      -3.526,
      8.515,
      13.076,
      -3.526,
      8.515,
      15.221,
      -2.3,
      8.757,
      15.221,
      -2.3,
      8.757,
      15.221,
      -2.3,
      8.757,
      13.076,
      -3.526,
      8.515,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_186",
    polygon: [
      -4.266,
      5.816,
      15.95,
      -4.266,
      5.816,
      18.75,
      -4.46,
      6.797,
      18.75,
      -4.46,
      6.797,
      18.75,
      -4.46,
      6.797,
      15.95,
      -4.266,
      5.816,
      15.95,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_582",
    polygon: [
      14.881,
      -5.935,
      19.7,
      14.881,
      -5.935,
      20.62,
      15.124,
      -7.161,
      20.62,
      15.124,
      -7.161,
      20.62,
      15.124,
      -7.161,
      19.7,
      14.881,
      -5.935,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_508",
    polygon: [
      -23.145,
      4.63,
      13.076,
      -23.145,
      4.63,
      15.221,
      -21.919,
      4.873,
      15.221,
      -21.919,
      4.873,
      15.221,
      -21.919,
      4.873,
      13.076,
      -23.145,
      4.63,
      13.076,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_554",
    polygon: [
      7.993,
      -18.768,
      20.62,
      7.993,
      -18.768,
      22.52,
      7.75,
      -17.541,
      22.52,
      7.75,
      -17.541,
      22.52,
      7.75,
      -17.541,
      20.62,
      7.993,
      -18.768,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_547",
    polygon: [
      7.264,
      -15.089,
      19.7,
      7.264,
      -15.089,
      20.62,
      7.021,
      -13.863,
      20.62,
      7.021,
      -13.863,
      20.62,
      7.021,
      -13.863,
      19.7,
      7.264,
      -15.089,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_193",
    polygon: [
      -4.266,
      5.816,
      19.7,
      -4.266,
      5.816,
      22.5,
      -4.46,
      6.797,
      22.5,
      -4.46,
      6.797,
      22.5,
      -4.46,
      6.797,
      19.7,
      -4.266,
      5.816,
      19.7,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_531",
    polygon: [
      5.322,
      -5.28,
      19.7,
      5.322,
      -5.28,
      20.62,
      5.079,
      -4.053,
      20.62,
      5.079,
      -4.053,
      20.62,
      5.079,
      -4.053,
      19.7,
      5.322,
      -5.28,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_53",
    polygon: [
      -1.598,
      16.797,
      16.865,
      -1.598,
      16.797,
      18.925,
      -1.889,
      18.268,
      18.925,
      -1.889,
      18.268,
      18.925,
      -1.889,
      18.268,
      16.865,
      -1.598,
      16.797,
      16.865,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_80",
    polygon: [
      -22.964,
      -2.98,
      12.57,
      -22.964,
      -2.98,
      15.57,
      -23.235,
      -1.606,
      15.57,
      -23.235,
      -1.606,
      15.57,
      -23.235,
      -1.606,
      12.57,
      -22.964,
      -2.98,
      12.57,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_562",
    polygon: [
      1.68,
      13.114,
      19.7,
      1.68,
      13.114,
      20.62,
      1.437,
      14.34,
      20.62,
      1.437,
      14.34,
      20.62,
      1.437,
      14.34,
      19.7,
      1.68,
      13.114,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_67",
    polygon: [
      6.171,
      -22.441,
      13.115,
      6.171,
      -22.441,
      15.175,
      5.88,
      -20.97,
      15.175,
      5.88,
      -20.97,
      15.175,
      5.88,
      -20.97,
      13.115,
      6.171,
      -22.441,
      13.115,
    ],
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_187",
    polygon: [
      -4.072,
      4.836,
      15.95,
      -4.072,
      4.836,
      18.75,
      -4.266,
      5.817,
      18.75,
      -4.266,
      5.817,
      18.75,
      -4.266,
      5.817,
      15.95,
      -4.072,
      4.836,
      15.95,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_580",
    polygon: [
      15.124,
      -7.161,
      19.7,
      15.124,
      -7.161,
      20.62,
      15.367,
      -8.387,
      20.62,
      15.367,
      -8.387,
      20.62,
      15.367,
      -8.387,
      19.7,
      15.124,
      -7.161,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_509",
    polygon: [
      -21.919,
      4.873,
      13.076,
      -21.919,
      4.873,
      15.221,
      -20.693,
      5.116,
      15.221,
      -20.693,
      5.116,
      15.221,
      -20.693,
      5.116,
      13.076,
      -21.919,
      4.873,
      13.076,
    ],
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_375",
    polygon: [
      -11.794,
      -7.649,
      13.076,
      -11.794,
      -7.649,
      15.221,
      -13.02,
      -7.892,
      15.221,
      -13.02,
      -7.892,
      15.221,
      -13.02,
      -7.892,
      13.076,
      -11.794,
      -7.649,
      13.076,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_383",
    polygon: [
      -1.984,
      -5.707,
      13.076,
      -1.984,
      -5.707,
      15.221,
      -3.21,
      -5.95,
      15.221,
      -3.21,
      -5.95,
      15.221,
      -3.21,
      -5.95,
      13.076,
      -1.984,
      -5.707,
      13.076,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_136",
    polygon: [
      16.217,
      0.19,
      13.115,
      16.217,
      0.19,
      15.175,
      16.508,
      -1.282,
      15.175,
      16.508,
      -1.282,
      15.175,
      16.508,
      -1.282,
      13.115,
      16.217,
      0.19,
      13.115,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_555",
    polygon: [
      8.235,
      -19.994,
      19.7,
      8.235,
      -19.994,
      20.62,
      7.993,
      -18.768,
      20.62,
      7.993,
      -18.768,
      20.62,
      7.993,
      -18.768,
      19.7,
      8.235,
      -19.994,
      19.7,
    ],
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_581",
    polygon: [
      14.881,
      -5.935,
      20.62,
      14.881,
      -5.935,
      22.52,
      15.124,
      -7.161,
      22.52,
      15.124,
      -7.161,
      22.52,
      15.124,
      -7.161,
      20.62,
      14.881,
      -5.935,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_194",
    polygon: [
      -4.072,
      4.836,
      19.7,
      -4.072,
      4.836,
      22.5,
      -4.266,
      5.817,
      22.5,
      -4.266,
      5.817,
      22.5,
      -4.266,
      5.817,
      19.7,
      -4.072,
      4.836,
      19.7,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_583",
    polygon: [
      14.638,
      -4.709,
      20.62,
      14.638,
      -4.709,
      22.52,
      14.881,
      -5.935,
      22.52,
      14.881,
      -5.935,
      22.52,
      14.881,
      -5.935,
      20.62,
      14.638,
      -4.709,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_141",
    polygon: [
      15.61,
      3.256,
      16.865,
      15.61,
      3.256,
      18.925,
      15.901,
      1.785,
      18.925,
      15.901,
      1.785,
      18.925,
      15.901,
      1.785,
      16.865,
      15.61,
      3.256,
      16.865,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_54",
    polygon: [
      -1.112,
      14.345,
      16.865,
      -1.112,
      14.345,
      18.925,
      -1.404,
      15.816,
      18.925,
      -1.404,
      15.816,
      18.925,
      -1.404,
      15.816,
      16.865,
      -1.112,
      14.345,
      16.865,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_131",
    polygon: [
      12.575,
      18.584,
      13.115,
      12.575,
      18.584,
      15.175,
      12.866,
      17.112,
      15.175,
      12.866,
      17.112,
      15.175,
      12.866,
      17.112,
      13.115,
      12.575,
      18.584,
      13.115,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_563",
    polygon: [
      1.68,
      13.114,
      20.62,
      1.68,
      13.114,
      22.52,
      1.437,
      14.34,
      22.52,
      1.437,
      14.34,
      22.52,
      1.437,
      14.34,
      20.62,
      1.68,
      13.114,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_589",
    polygon: [
      13.91,
      -1.03,
      20.62,
      13.91,
      -1.03,
      22.52,
      14.153,
      -2.256,
      22.52,
      14.153,
      -2.256,
      22.52,
      14.153,
      -2.256,
      20.62,
      13.91,
      -1.03,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_510",
    polygon: [
      -20.693,
      5.116,
      13.076,
      -20.693,
      5.116,
      15.221,
      -19.466,
      5.358,
      15.221,
      -19.466,
      5.358,
      15.221,
      -19.466,
      5.358,
      13.076,
      -20.693,
      5.116,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_195",
    polygon: [
      -3.878,
      3.857,
      19.7,
      -3.878,
      3.857,
      22.5,
      -4.072,
      4.838,
      22.5,
      -4.072,
      4.838,
      22.5,
      -4.072,
      4.838,
      19.7,
      -3.878,
      3.857,
      19.7,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_132",
    polygon: [
      13.06,
      16.131,
      13.115,
      13.06,
      16.131,
      15.175,
      13.352,
      14.66,
      15.175,
      13.352,
      14.66,
      15.175,
      13.352,
      14.66,
      13.115,
      13.06,
      16.131,
      13.115,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_137",
    polygon: [
      17.674,
      -7.167,
      13.115,
      17.674,
      -7.167,
      15.175,
      17.965,
      -8.639,
      15.175,
      17.965,
      -8.639,
      15.175,
      17.965,
      -8.639,
      13.115,
      17.674,
      -7.167,
      13.115,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_564",
    polygon: [
      1.437,
      14.34,
      19.7,
      1.437,
      14.34,
      20.62,
      1.194,
      15.566,
      20.62,
      1.194,
      15.566,
      20.62,
      1.194,
      15.566,
      19.7,
      1.437,
      14.34,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_188",
    polygon: [
      -3.878,
      3.857,
      15.95,
      -3.878,
      3.857,
      18.75,
      -4.072,
      4.838,
      18.75,
      -4.072,
      4.838,
      18.75,
      -4.072,
      4.838,
      15.95,
      -3.878,
      3.857,
      15.95,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_142",
    polygon: [
      15.003,
      6.322,
      16.865,
      15.003,
      6.322,
      18.925,
      15.294,
      4.85,
      18.925,
      15.294,
      4.85,
      18.925,
      15.294,
      4.85,
      16.865,
      15.003,
      6.322,
      16.865,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_556",
    polygon: [
      8.235,
      -19.994,
      20.62,
      8.235,
      -19.994,
      22.52,
      7.993,
      -18.768,
      22.52,
      7.993,
      -18.768,
      22.52,
      7.993,
      -18.768,
      20.62,
      8.235,
      -19.994,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_55",
    polygon: [
      -0.627,
      11.892,
      16.865,
      -0.627,
      11.892,
      18.925,
      -0.918,
      13.364,
      18.925,
      -0.918,
      13.364,
      18.925,
      -0.918,
      13.364,
      16.865,
      -0.627,
      11.892,
      16.865,
    ],
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_75",
    polygon: [
      2.53,
      -4.048,
      16.865,
      2.53,
      -4.048,
      18.925,
      2.238,
      -2.577,
      18.925,
      2.238,
      -2.577,
      18.925,
      2.238,
      -2.577,
      16.865,
      2.53,
      -4.048,
      16.865,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_74",
    polygon: [
      3.015,
      -6.501,
      16.865,
      3.015,
      -6.501,
      18.925,
      2.724,
      -5.029,
      18.925,
      2.724,
      -5.029,
      18.925,
      2.724,
      -5.029,
      16.865,
      3.015,
      -6.501,
      16.865,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_71",
    polygon: [
      5.2,
      -17.537,
      16.865,
      5.2,
      -17.537,
      18.925,
      4.909,
      -16.065,
      18.925,
      4.909,
      -16.065,
      18.925,
      4.909,
      -16.065,
      16.865,
      5.2,
      -17.537,
      16.865,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_590",
    polygon: [
      13.91,
      -1.03,
      19.7,
      13.91,
      -1.03,
      20.62,
      14.153,
      -2.256,
      20.62,
      14.153,
      -2.256,
      20.62,
      14.153,
      -2.256,
      19.7,
      13.91,
      -1.03,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_140",
    polygon: [
      13.06,
      16.131,
      16.865,
      13.06,
      16.131,
      18.925,
      13.352,
      14.66,
      18.925,
      13.352,
      14.66,
      18.925,
      13.352,
      14.66,
      16.865,
      13.06,
      16.131,
      16.865,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_196",
    polygon: [
      -3.684,
      2.877,
      19.7,
      -3.684,
      2.877,
      22.5,
      -3.878,
      3.858,
      22.5,
      -3.878,
      3.858,
      22.5,
      -3.878,
      3.858,
      19.7,
      -3.684,
      2.877,
      19.7,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_77",
    polygon: [
      2.044,
      -1.596,
      16.865,
      2.044,
      -1.596,
      18.925,
      1.753,
      -0.125,
      18.925,
      1.753,
      -0.125,
      18.925,
      1.753,
      -0.125,
      16.865,
      2.044,
      -1.596,
      16.865,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_557",
    polygon: [
      8.489,
      -21.272,
      19.7,
      8.489,
      -21.272,
      22.52,
      8.235,
      -19.994,
      22.52,
      8.235,
      -19.994,
      22.52,
      8.235,
      -19.994,
      19.7,
      8.489,
      -21.272,
      19.7,
    ],
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_146",
    polygon: [
      18.159,
      -9.62,
      16.865,
      18.159,
      -9.62,
      18.925,
      18.451,
      -11.091,
      18.925,
      18.451,
      -11.091,
      18.925,
      18.451,
      -11.091,
      16.865,
      18.159,
      -9.62,
      16.865,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_93",
    polygon: [
      5.12,
      -17.131,
      3.68,
      5.12,
      -17.131,
      4.7,
      4.91,
      -16.071,
      4.7,
      4.91,
      -16.071,
      4.7,
      4.91,
      -16.071,
      3.68,
      5.12,
      -17.131,
      3.68,
    ],
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_145",
    polygon: [
      17.674,
      -7.167,
      16.865,
      17.674,
      -7.167,
      18.925,
      17.965,
      -8.639,
      18.925,
      17.965,
      -8.639,
      18.925,
      17.965,
      -8.639,
      16.865,
      17.674,
      -7.167,
      16.865,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_143",
    polygon: [
      13.546,
      13.679,
      16.865,
      13.546,
      13.679,
      18.925,
      13.837,
      12.208,
      18.925,
      13.837,
      12.208,
      18.925,
      13.837,
      12.208,
      16.865,
      13.546,
      13.679,
      16.865,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_565",
    polygon: [
      1.437,
      14.34,
      20.62,
      1.437,
      14.34,
      22.52,
      1.194,
      15.566,
      22.52,
      1.194,
      15.566,
      22.52,
      1.194,
      15.566,
      20.62,
      1.437,
      14.34,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_72",
    polygon: [
      5.686,
      -19.989,
      16.865,
      5.686,
      -19.989,
      18.925,
      5.395,
      -18.517,
      18.925,
      5.395,
      -18.517,
      18.925,
      5.395,
      -18.517,
      16.865,
      5.686,
      -19.989,
      16.865,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_151",
    polygon: [
      14.093,
      10.918,
      16.865,
      14.093,
      10.918,
      18.925,
      14.747,
      7.612,
      18.925,
      14.747,
      7.612,
      18.925,
      14.747,
      7.612,
      16.865,
      14.093,
      10.918,
      16.865,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_82",
    polygon: [
      3.088,
      -6.996,
      0.4,
      3.088,
      -6.996,
      3.7,
      2.845,
      -5.77,
      3.7,
      2.845,
      -5.77,
      3.7,
      2.845,
      -5.77,
      0.4,
      3.088,
      -6.996,
      0.4,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_154",
    polygon: [
      16.764,
      -2.57,
      16.865,
      16.764,
      -2.57,
      18.925,
      17.418,
      -5.876,
      18.925,
      17.418,
      -5.876,
      18.925,
      17.418,
      -5.876,
      16.865,
      16.764,
      -2.57,
      16.865,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_591",
    polygon: [
      13.667,
      0.196,
      20.62,
      13.667,
      0.196,
      22.52,
      13.91,
      -1.03,
      22.52,
      13.91,
      -1.03,
      22.52,
      13.91,
      -1.03,
      20.62,
      13.667,
      0.196,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_105",
    polygon: [
      2.845,
      -5.77,
      3.7,
      2.845,
      -5.77,
      4.7,
      2.723,
      -5.152,
      4.7,
      2.723,
      -5.152,
      4.7,
      2.723,
      -5.152,
      3.7,
      2.845,
      -5.77,
      3.7,
    ],
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_588",
    polygon: [
      14.153,
      -2.256,
      19.7,
      14.153,
      -2.256,
      20.62,
      14.396,
      -3.483,
      20.62,
      14.396,
      -3.483,
      20.62,
      14.396,
      -3.483,
      19.7,
      14.153,
      -2.256,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_183",
    polygon: [
      5.96,
      -21.373,
      3.68,
      5.96,
      -21.373,
      4.7,
      5.75,
      -20.314,
      4.7,
      5.75,
      -20.314,
      4.7,
      5.75,
      -20.314,
      3.68,
      5.96,
      -21.373,
      3.68,
    ],
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_144",
    polygon: [
      16.217,
      0.19,
      16.865,
      16.217,
      0.19,
      18.925,
      16.508,
      -1.282,
      18.925,
      16.508,
      -1.282,
      18.925,
      16.508,
      -1.282,
      16.865,
      16.217,
      0.19,
      16.865,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_166",
    polygon: [
      3.573,
      -9.448,
      0.4,
      3.573,
      -9.448,
      3.7,
      3.331,
      -8.222,
      3.7,
      3.331,
      -8.222,
      3.7,
      3.331,
      -8.222,
      0.4,
      3.573,
      -9.448,
      0.4,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_165",
    polygon: [
      3.331,
      -8.222,
      0.4,
      3.331,
      -8.222,
      3.7,
      3.088,
      -6.996,
      3.7,
      3.088,
      -6.996,
      3.7,
      3.088,
      -6.996,
      0.4,
      3.331,
      -8.222,
      0.4,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_184",
    polygon: [
      6.17,
      -22.434,
      3.68,
      6.17,
      -22.434,
      4.7,
      5.96,
      -21.374,
      4.7,
      5.96,
      -21.374,
      4.7,
      5.96,
      -21.374,
      3.68,
      6.17,
      -22.434,
      3.68,
    ],
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_92",
    polygon: [
      5.12,
      -17.131,
      -0.12,
      5.12,
      -17.131,
      3.68,
      4.91,
      -16.071,
      3.68,
      4.91,
      -16.071,
      3.68,
      4.91,
      -16.071,
      -0.12,
      5.12,
      -17.131,
      -0.12,
    ],
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_206",
    polygon: [
      -19.151,
      -9.106,
      2.876,
      -19.151,
      -9.106,
      3.901,
      -20.156,
      -9.305,
      3.901,
      -20.156,
      -9.305,
      3.901,
      -20.156,
      -9.305,
      2.876,
      -19.151,
      -9.106,
      2.876,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_174",
    polygon: [
      4.059,
      -11.901,
      3.7,
      4.059,
      -11.901,
      4.7,
      3.816,
      -10.675,
      4.7,
      3.816,
      -10.675,
      4.7,
      3.816,
      -10.675,
      3.7,
      4.059,
      -11.901,
      3.7,
    ],
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_170",
    polygon: [
      3.088,
      -6.996,
      3.7,
      3.088,
      -6.996,
      4.7,
      2.845,
      -5.77,
      4.7,
      2.845,
      -5.77,
      4.7,
      2.845,
      -5.77,
      3.7,
      3.088,
      -6.996,
      3.7,
    ],
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_205",
    polygon: [
      -19.151,
      -9.106,
      0.4,
      -19.151,
      -9.106,
      2.876,
      -20.156,
      -9.305,
      2.876,
      -20.156,
      -9.305,
      2.876,
      -20.156,
      -9.305,
      0.4,
      -19.151,
      -9.106,
      0.4,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_199",
    polygon: [
      -20.377,
      -9.349,
      0.4,
      -20.377,
      -9.349,
      1.076,
      -21.03,
      -9.478,
      1.076,
      -21.03,
      -9.478,
      1.076,
      -21.03,
      -9.478,
      0.4,
      -20.377,
      -9.349,
      0.4,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_169",
    polygon: [
      2.845,
      -5.77,
      0.4,
      2.845,
      -5.77,
      3.7,
      2.723,
      -5.152,
      3.7,
      2.723,
      -5.152,
      3.7,
      2.723,
      -5.152,
      0.4,
      2.845,
      -5.77,
      0.4,
    ],
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_218",
    polygon: [
      -14.246,
      -8.135,
      0.4,
      -14.246,
      -8.135,
      1.076,
      -15.472,
      -8.377,
      1.076,
      -15.472,
      -8.377,
      1.076,
      -15.472,
      -8.377,
      0.4,
      -14.246,
      -8.135,
      0.4,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_220",
    polygon: [
      -14.246,
      -8.135,
      2.876,
      -14.246,
      -8.135,
      3.901,
      -15.472,
      -8.377,
      3.901,
      -15.472,
      -8.377,
      3.901,
      -15.472,
      -8.377,
      2.876,
      -14.246,
      -8.135,
      2.876,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_219",
    polygon: [
      -14.246,
      -8.135,
      1.076,
      -14.246,
      -8.135,
      2.876,
      -15.472,
      -8.377,
      2.876,
      -15.472,
      -8.377,
      2.876,
      -15.472,
      -8.377,
      1.076,
      -14.246,
      -8.135,
      1.076,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_204",
    polygon: [
      -20.156,
      -9.305,
      2.876,
      -20.156,
      -9.305,
      3.901,
      -20.377,
      -9.349,
      3.901,
      -20.377,
      -9.349,
      3.901,
      -20.377,
      -9.349,
      2.876,
      -20.156,
      -9.305,
      2.876,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_182",
    polygon: [
      5.75,
      -20.313,
      3.68,
      5.75,
      -20.313,
      4.7,
      5.54,
      -19.253,
      4.7,
      5.54,
      -19.253,
      4.7,
      5.54,
      -19.253,
      3.68,
      5.75,
      -20.313,
      3.68,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_200",
    polygon: [
      -20.377,
      -9.349,
      1.076,
      -20.377,
      -9.349,
      2.876,
      -21.03,
      -9.478,
      2.876,
      -21.03,
      -9.478,
      2.876,
      -21.03,
      -9.478,
      1.076,
      -20.377,
      -9.349,
      1.076,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_167",
    polygon: [
      3.816,
      -10.675,
      0.4,
      3.816,
      -10.675,
      3.7,
      3.573,
      -9.448,
      3.7,
      3.573,
      -9.448,
      3.7,
      3.573,
      -9.448,
      0.4,
      3.816,
      -10.675,
      0.4,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_180",
    polygon: [
      5.33,
      -18.191,
      3.68,
      5.33,
      -18.191,
      4.7,
      5.12,
      -17.132,
      4.7,
      5.12,
      -17.132,
      4.7,
      5.12,
      -17.132,
      3.68,
      5.33,
      -18.191,
      3.68,
    ],
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_173",
    polygon: [
      3.816,
      -10.675,
      3.7,
      3.816,
      -10.675,
      4.7,
      3.573,
      -9.448,
      4.7,
      3.573,
      -9.448,
      4.7,
      3.573,
      -9.448,
      3.7,
      3.816,
      -10.675,
      3.7,
    ],
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_215",
    polygon: [
      -15.472,
      -8.377,
      0.4,
      -15.472,
      -8.377,
      1.076,
      -16.699,
      -8.62,
      1.076,
      -16.699,
      -8.62,
      1.076,
      -16.699,
      -8.62,
      0.4,
      -15.472,
      -8.377,
      0.4,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_216",
    polygon: [
      -15.472,
      -8.377,
      1.076,
      -15.472,
      -8.377,
      2.876,
      -16.699,
      -8.62,
      2.876,
      -16.699,
      -8.62,
      2.876,
      -16.699,
      -8.62,
      1.076,
      -15.472,
      -8.377,
      1.076,
    ],
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_171",
    polygon: [
      3.331,
      -8.222,
      3.7,
      3.331,
      -8.222,
      4.7,
      3.088,
      -6.996,
      4.7,
      3.088,
      -6.996,
      4.7,
      3.088,
      -6.996,
      3.7,
      3.331,
      -8.222,
      3.7,
    ],
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_221",
    polygon: [
      -13.02,
      -7.892,
      0.4,
      -13.02,
      -7.892,
      1.076,
      -14.246,
      -8.135,
      1.076,
      -14.246,
      -8.135,
      1.076,
      -14.246,
      -8.135,
      0.4,
      -13.02,
      -7.892,
      0.4,
    ],
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_222",
    polygon: [
      -13.02,
      -7.892,
      1.076,
      -13.02,
      -7.892,
      2.876,
      -14.246,
      -8.135,
      2.876,
      -14.246,
      -8.135,
      2.876,
      -14.246,
      -8.135,
      1.076,
      -13.02,
      -7.892,
      1.076,
    ],
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_168",
    polygon: [
      4.059,
      -11.901,
      0.4,
      4.059,
      -11.901,
      3.7,
      3.816,
      -10.675,
      3.7,
      3.816,
      -10.675,
      3.7,
      3.816,
      -10.675,
      0.4,
      4.059,
      -11.901,
      0.4,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_210",
    polygon: [
      -17.925,
      -8.863,
      0.4,
      -17.925,
      -8.863,
      1.076,
      -18.146,
      -8.907,
      1.076,
      -18.146,
      -8.907,
      1.076,
      -18.146,
      -8.907,
      0.4,
      -17.925,
      -8.863,
      0.4,
    ],
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_181",
    polygon: [
      5.54,
      -19.252,
      3.68,
      5.54,
      -19.252,
      4.7,
      5.33,
      -18.193,
      4.7,
      5.33,
      -18.193,
      4.7,
      5.33,
      -18.193,
      3.68,
      5.54,
      -19.252,
      3.68,
    ],
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_217",
    polygon: [
      -15.472,
      -8.377,
      2.876,
      -15.472,
      -8.377,
      3.901,
      -16.699,
      -8.62,
      3.901,
      -16.699,
      -8.62,
      3.901,
      -16.699,
      -8.62,
      2.876,
      -15.472,
      -8.377,
      2.876,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_172",
    polygon: [
      3.573,
      -9.448,
      3.7,
      3.573,
      -9.448,
      4.7,
      3.331,
      -8.222,
      4.7,
      3.331,
      -8.222,
      4.7,
      3.331,
      -8.222,
      3.7,
      3.573,
      -9.448,
      3.7,
    ],
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_214",
    polygon: [
      -16.699,
      -8.62,
      2.876,
      -16.699,
      -8.62,
      3.901,
      -17.925,
      -8.863,
      3.901,
      -17.925,
      -8.863,
      3.901,
      -17.925,
      -8.863,
      2.876,
      -16.699,
      -8.62,
      2.876,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_212",
    polygon: [
      -16.699,
      -8.62,
      0.4,
      -16.699,
      -8.62,
      1.076,
      -17.925,
      -8.863,
      1.076,
      -17.925,
      -8.863,
      1.076,
      -17.925,
      -8.863,
      0.4,
      -16.699,
      -8.62,
      0.4,
    ],
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_213",
    polygon: [
      -16.699,
      -8.62,
      1.076,
      -16.699,
      -8.62,
      2.876,
      -17.925,
      -8.863,
      2.876,
      -17.925,
      -8.863,
      2.876,
      -17.925,
      -8.863,
      1.076,
      -16.699,
      -8.62,
      1.076,
    ],
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_401",
    polygon: [
      -20.693,
      5.116,
      2.876,
      -20.693,
      5.116,
      3.901,
      -19.466,
      5.358,
      3.901,
      -19.466,
      5.358,
      3.901,
      -19.466,
      5.358,
      2.876,
      -20.693,
      5.116,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_395",
    polygon: [
      -23.145,
      4.63,
      2.876,
      -23.145,
      4.63,
      3.901,
      -21.919,
      4.873,
      3.901,
      -21.919,
      4.873,
      3.901,
      -21.919,
      4.873,
      2.876,
      -23.145,
      4.63,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_211",
    polygon: [
      -17.925,
      -8.863,
      2.876,
      -17.925,
      -8.863,
      3.901,
      -18.146,
      -8.907,
      3.901,
      -18.146,
      -8.907,
      3.901,
      -18.146,
      -8.907,
      2.876,
      -17.925,
      -8.863,
      2.876,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_203",
    polygon: [
      -20.156,
      -9.305,
      1.076,
      -20.156,
      -9.305,
      2.876,
      -20.377,
      -9.349,
      2.876,
      -20.377,
      -9.349,
      2.876,
      -20.377,
      -9.349,
      1.076,
      -20.156,
      -9.305,
      1.076,
    ],
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_451",
    polygon: [
      -2.52,
      8.714,
      2.876,
      -2.52,
      8.714,
      3.901,
      -2.3,
      8.757,
      3.901,
      -2.3,
      8.757,
      3.901,
      -2.3,
      8.757,
      2.876,
      -2.52,
      8.714,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_417",
    polygon: [
      -13.336,
      6.572,
      0.4,
      -13.336,
      6.572,
      1.076,
      -12.109,
      6.815,
      1.076,
      -12.109,
      6.815,
      1.076,
      -12.109,
      6.815,
      0.4,
      -13.336,
      6.572,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_436",
    polygon: [
      -5.978,
      8.029,
      1.076,
      -5.978,
      8.029,
      2.876,
      -4.752,
      8.272,
      2.876,
      -4.752,
      8.272,
      2.876,
      -4.752,
      8.272,
      1.076,
      -5.978,
      8.029,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_400",
    polygon: [
      -20.693,
      5.116,
      1.076,
      -20.693,
      5.116,
      2.876,
      -19.466,
      5.358,
      2.876,
      -19.466,
      5.358,
      2.876,
      -19.466,
      5.358,
      1.076,
      -20.693,
      5.116,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_450",
    polygon: [
      -2.52,
      8.714,
      0.4,
      -2.52,
      8.714,
      1.076,
      -2.3,
      8.757,
      1.076,
      -2.3,
      8.757,
      1.076,
      -2.3,
      8.757,
      0.4,
      -2.52,
      8.714,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_449",
    polygon: [
      -2.52,
      8.714,
      1.076,
      -2.52,
      8.714,
      2.876,
      -2.3,
      8.757,
      2.876,
      -2.3,
      8.757,
      2.876,
      -2.3,
      8.757,
      1.076,
      -2.52,
      8.714,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_431",
    polygon: [
      -8.431,
      7.544,
      2.876,
      -8.431,
      7.544,
      3.901,
      -7.205,
      7.786,
      3.901,
      -7.205,
      7.786,
      3.901,
      -7.205,
      7.786,
      2.876,
      -8.431,
      7.544,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_402",
    polygon: [
      -19.466,
      5.358,
      0.4,
      -19.466,
      5.358,
      1.076,
      -18.24,
      5.601,
      1.076,
      -18.24,
      5.601,
      1.076,
      -18.24,
      5.601,
      0.4,
      -19.466,
      5.358,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_429",
    polygon: [
      -8.431,
      7.544,
      0.4,
      -8.431,
      7.544,
      1.076,
      -7.205,
      7.786,
      1.076,
      -7.205,
      7.786,
      1.076,
      -7.205,
      7.786,
      0.4,
      -8.431,
      7.544,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_441",
    polygon: [
      -14.562,
      6.33,
      1.076,
      -14.562,
      6.33,
      2.876,
      -13.336,
      6.572,
      2.876,
      -13.336,
      6.572,
      2.876,
      -13.336,
      6.572,
      1.076,
      -14.562,
      6.33,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_422",
    polygon: [
      -12.109,
      6.815,
      2.876,
      -12.109,
      6.815,
      3.901,
      -10.883,
      7.058,
      3.901,
      -10.883,
      7.058,
      3.901,
      -10.883,
      7.058,
      2.876,
      -12.109,
      6.815,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_423",
    polygon: [
      -10.883,
      7.058,
      0.4,
      -10.883,
      7.058,
      1.076,
      -9.657,
      7.301,
      1.076,
      -9.657,
      7.301,
      1.076,
      -9.657,
      7.301,
      0.4,
      -10.883,
      7.058,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_414",
    polygon: [
      -14.562,
      6.33,
      0.4,
      -14.562,
      6.33,
      1.076,
      -13.336,
      6.572,
      1.076,
      -13.336,
      6.572,
      1.076,
      -13.336,
      6.572,
      0.4,
      -14.562,
      6.33,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_408",
    polygon: [
      -17.014,
      5.844,
      0.4,
      -17.014,
      5.844,
      1.076,
      -15.788,
      6.087,
      1.076,
      -15.788,
      6.087,
      1.076,
      -15.788,
      6.087,
      0.4,
      -17.014,
      5.844,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_430",
    polygon: [
      -8.431,
      7.544,
      1.076,
      -8.431,
      7.544,
      2.876,
      -7.205,
      7.786,
      2.876,
      -7.205,
      7.786,
      2.876,
      -7.205,
      7.786,
      1.076,
      -8.431,
      7.544,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_456",
    polygon: [
      -1.074,
      9.0,
      1.076,
      -1.074,
      9.0,
      2.876,
      -0.421,
      9.129,
      2.876,
      -0.421,
      9.129,
      2.876,
      -0.421,
      9.129,
      1.076,
      -1.074,
      9.0,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_635",
    polygon: [
      9.731,
      -36.869,
      -0.25,
      9.731,
      -36.869,
      2.875,
      8.534,
      -37.246,
      2.875,
      8.534,
      -37.246,
      2.875,
      8.534,
      -37.246,
      -0.25,
      9.731,
      -36.869,
      -0.25,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_670",
    polygon: [
      8.538,
      -37.244,
      2.875,
      8.538,
      -37.244,
      3.875,
      7.34,
      -37.621,
      3.875,
      7.34,
      -37.621,
      3.875,
      7.34,
      -37.621,
      2.875,
      8.538,
      -37.244,
      2.875,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_667",
    polygon: [
      13.919,
      -34.359,
      -0.25,
      13.919,
      -34.359,
      2.875,
      13.477,
      -34.509,
      2.875,
      13.477,
      -34.509,
      2.875,
      13.477,
      -34.509,
      -0.25,
      13.919,
      -34.359,
      -0.25,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_634",
    polygon: [
      8.536,
      -37.245,
      -0.25,
      8.536,
      -37.245,
      2.875,
      7.338,
      -37.622,
      2.875,
      7.338,
      -37.622,
      2.875,
      7.338,
      -37.622,
      -0.25,
      8.536,
      -37.245,
      -0.25,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_663",
    polygon: [
      25.829,
      -30.289,
      -0.25,
      25.829,
      -30.289,
      2.875,
      26.214,
      -31.212,
      2.875,
      26.214,
      -31.212,
      2.875,
      26.214,
      -31.212,
      -0.25,
      25.829,
      -30.289,
      -0.25,
    ],
    "05-01 07:15": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_637",
    polygon: [
      12.112,
      -36.088,
      -0.25,
      12.112,
      -36.088,
      2.875,
      10.923,
      -36.492,
      2.875,
      10.923,
      -36.492,
      2.875,
      10.923,
      -36.492,
      -0.25,
      12.112,
      -36.088,
      -0.25,
    ],
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_394",
    polygon: [
      -23.145,
      4.63,
      1.076,
      -23.145,
      4.63,
      2.876,
      -21.919,
      4.873,
      2.876,
      -21.919,
      4.873,
      2.876,
      -21.919,
      4.873,
      1.076,
      -23.145,
      4.63,
      1.076,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_660",
    polygon: [
      27.875,
      -29.007,
      -0.25,
      27.875,
      -29.007,
      2.875,
      27.444,
      -29.187,
      2.875,
      27.444,
      -29.187,
      2.875,
      27.444,
      -29.187,
      -0.25,
      27.875,
      -29.007,
      -0.25,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_632",
    polygon: [
      7.341,
      -37.621,
      -0.25,
      7.341,
      -37.621,
      2.875,
      6.143,
      -37.997,
      2.875,
      6.143,
      -37.997,
      2.875,
      6.143,
      -37.997,
      -0.25,
      7.341,
      -37.621,
      -0.25,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_636",
    polygon: [
      10.928,
      -36.492,
      -0.25,
      10.928,
      -36.492,
      2.875,
      9.731,
      -36.869,
      2.875,
      9.731,
      -36.869,
      2.875,
      9.731,
      -36.869,
      -0.25,
      10.928,
      -36.492,
      -0.25,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_891",
    polygon: [
      16.845,
      -34.452,
      2.875,
      16.845,
      -34.452,
      3.875,
      15.666,
      -34.883,
      3.875,
      15.666,
      -34.883,
      3.875,
      15.666,
      -34.883,
      2.875,
      16.845,
      -34.452,
      2.875,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_674",
    polygon: [
      15.671,
      -34.881,
      2.875,
      15.671,
      -34.881,
      3.875,
      14.482,
      -35.285,
      3.875,
      14.482,
      -35.285,
      3.875,
      14.482,
      -35.285,
      2.875,
      15.671,
      -34.881,
      2.875,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_662",
    polygon: [
      28.741,
      -30.175,
      -0.25,
      28.741,
      -30.175,
      2.875,
      28.355,
      -29.253,
      2.875,
      28.355,
      -29.253,
      2.875,
      28.355,
      -29.253,
      -0.25,
      28.741,
      -30.175,
      -0.25,
    ],
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_669",
    polygon: [
      7.34,
      -37.621,
      2.875,
      7.34,
      -37.621,
      3.875,
      6.142,
      -37.998,
      3.875,
      6.142,
      -37.998,
      3.875,
      6.142,
      -37.998,
      2.875,
      7.34,
      -37.621,
      2.875,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_892",
    polygon: [
      18.02,
      -34.023,
      2.875,
      18.02,
      -34.023,
      3.875,
      16.841,
      -34.454,
      3.875,
      16.841,
      -34.454,
      3.875,
      16.841,
      -34.454,
      2.875,
      18.02,
      -34.023,
      2.875,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_409",
    polygon: [
      -17.014,
      5.844,
      1.076,
      -17.014,
      5.844,
      2.876,
      -15.788,
      6.087,
      2.876,
      -15.788,
      6.087,
      2.876,
      -15.788,
      6.087,
      1.076,
      -17.014,
      5.844,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_458",
    polygon: [
      -2.3,
      8.757,
      0.4,
      -2.3,
      8.757,
      1.076,
      -1.074,
      9.0,
      1.076,
      -1.074,
      9.0,
      1.076,
      -1.074,
      9.0,
      0.4,
      -2.3,
      8.757,
      0.4,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_895",
    polygon: [
      21.539,
      -32.707,
      2.875,
      21.539,
      -32.707,
      3.875,
      20.37,
      -33.165,
      3.875,
      20.37,
      -33.165,
      3.875,
      20.37,
      -33.165,
      2.875,
      21.539,
      -32.707,
      2.875,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_883",
    polygon: [
      11.777,
      -35.462,
      2.875,
      11.777,
      -35.462,
      3.875,
      12.098,
      -36.409,
      3.875,
      12.098,
      -36.409,
      3.875,
      12.098,
      -36.409,
      2.875,
      11.777,
      -35.462,
      2.875,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_665",
    polygon: [
      27.445,
      -29.186,
      -0.25,
      27.445,
      -29.186,
      2.875,
      26.43,
      -29.61,
      2.875,
      26.43,
      -29.61,
      2.875,
      26.43,
      -29.61,
      -0.25,
      27.445,
      -29.186,
      -0.25,
    ],
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_407",
    polygon: [
      -18.24,
      5.601,
      2.876,
      -18.24,
      5.601,
      3.901,
      -17.014,
      5.844,
      3.901,
      -17.014,
      5.844,
      3.901,
      -17.014,
      5.844,
      2.876,
      -18.24,
      5.601,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_943",
    polygon: [
      49.258,
      -19.785,
      0.6,
      49.258,
      -19.785,
      2.565,
      47.505,
      -20.705,
      2.565,
      47.505,
      -20.705,
      2.565,
      47.505,
      -20.705,
      0.6,
      49.258,
      -19.785,
      0.6,
    ],
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_638",
    polygon: [
      11.777,
      -35.462,
      -0.25,
      11.777,
      -35.462,
      2.875,
      12.098,
      -36.409,
      2.875,
      12.098,
      -36.409,
      2.875,
      12.098,
      -36.409,
      -0.25,
      11.777,
      -35.462,
      -0.25,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 11:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_666",
    polygon: [
      12.435,
      -34.863,
      -0.25,
      12.435,
      -34.863,
      2.875,
      11.993,
      -35.013,
      2.875,
      11.993,
      -35.013,
      2.875,
      11.993,
      -35.013,
      -0.25,
      12.435,
      -34.863,
      -0.25,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_668",
    polygon: [
      14.677,
      -35.512,
      -0.25,
      14.677,
      -35.512,
      2.875,
      14.356,
      -34.565,
      2.875,
      14.356,
      -34.565,
      2.875,
      14.356,
      -34.565,
      -0.25,
      14.677,
      -35.512,
      -0.25,
    ],
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_889",
    polygon: [
      13.477,
      -34.509,
      -0.25,
      13.477,
      -34.509,
      2.875,
      12.435,
      -34.863,
      2.875,
      12.435,
      -34.863,
      2.875,
      12.435,
      -34.863,
      -0.25,
      13.477,
      -34.509,
      -0.25,
    ],
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_893",
    polygon: [
      19.197,
      -33.593,
      2.875,
      19.197,
      -33.593,
      3.875,
      18.018,
      -34.024,
      3.875,
      18.018,
      -34.024,
      3.875,
      18.018,
      -34.024,
      2.875,
      19.197,
      -33.593,
      2.875,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_457",
    polygon: [
      -1.074,
      9.0,
      2.876,
      -1.074,
      9.0,
      3.901,
      -0.421,
      9.129,
      3.901,
      -0.421,
      9.129,
      3.901,
      -0.421,
      9.129,
      2.876,
      -1.074,
      9.0,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_671",
    polygon: [
      9.733,
      -36.868,
      2.875,
      9.733,
      -36.868,
      3.875,
      8.536,
      -37.245,
      3.875,
      8.536,
      -37.245,
      3.875,
      8.536,
      -37.245,
      2.875,
      9.733,
      -36.868,
      2.875,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_942",
    polygon: [
      46.465,
      -21.251,
      0.6,
      46.465,
      -21.251,
      2.565,
      44.712,
      -22.171,
      2.565,
      44.712,
      -22.171,
      2.565,
      44.712,
      -22.171,
      0.6,
      46.465,
      -21.251,
      0.6,
    ],
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_898",
    polygon: [
      25.038,
      -31.338,
      2.875,
      25.038,
      -31.338,
      3.875,
      23.869,
      -31.795,
      3.875,
      23.869,
      -31.795,
      3.875,
      23.869,
      -31.795,
      2.875,
      25.038,
      -31.338,
      2.875,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_897",
    polygon: [
      23.871,
      -31.795,
      2.875,
      23.871,
      -31.795,
      3.875,
      22.702,
      -32.252,
      3.875,
      22.702,
      -32.252,
      3.875,
      22.702,
      -32.252,
      2.875,
      23.871,
      -31.795,
      2.875,
    ],
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_672",
    polygon: [
      10.928,
      -36.492,
      2.875,
      10.928,
      -36.492,
      3.875,
      9.731,
      -36.869,
      3.875,
      9.731,
      -36.869,
      3.875,
      9.731,
      -36.869,
      2.875,
      10.928,
      -36.492,
      2.875,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_437",
    polygon: [
      -5.978,
      8.029,
      2.876,
      -5.978,
      8.029,
      3.901,
      -4.752,
      8.272,
      3.901,
      -4.752,
      8.272,
      3.901,
      -4.752,
      8.272,
      2.876,
      -5.978,
      8.029,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_908",
    polygon: [
      26.43,
      -29.61,
      -0.25,
      26.43,
      -29.61,
      2.875,
      25.999,
      -29.79,
      2.875,
      25.999,
      -29.79,
      2.875,
      25.999,
      -29.79,
      -0.25,
      26.43,
      -29.61,
      -0.25,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_896",
    polygon: [
      22.704,
      -32.252,
      2.875,
      22.704,
      -32.252,
      3.875,
      21.534,
      -32.709,
      3.875,
      21.534,
      -32.709,
      3.875,
      21.534,
      -32.709,
      2.875,
      22.704,
      -32.252,
      2.875,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_424",
    polygon: [
      -10.883,
      7.058,
      1.076,
      -10.883,
      7.058,
      2.876,
      -9.657,
      7.301,
      2.876,
      -9.657,
      7.301,
      2.876,
      -9.657,
      7.301,
      1.076,
      -10.883,
      7.058,
      1.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1038",
    polygon: [
      6.834,
      -11.632,
      0.4,
      6.834,
      -11.632,
      3.7,
      5.608,
      -11.874,
      3.7,
      5.608,
      -11.874,
      3.7,
      5.608,
      -11.874,
      0.4,
      6.834,
      -11.632,
      0.4,
    ],
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_904",
    polygon: [
      33.088,
      -27.883,
      2.875,
      33.088,
      -27.883,
      3.875,
      31.941,
      -28.393,
      3.875,
      31.941,
      -28.393,
      3.875,
      31.941,
      -28.393,
      2.875,
      33.088,
      -27.883,
      2.875,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_901",
    polygon: [
      29.659,
      -29.408,
      2.875,
      29.659,
      -29.408,
      3.875,
      28.501,
      -29.892,
      3.875,
      28.501,
      -29.892,
      3.875,
      28.501,
      -29.892,
      2.875,
      29.659,
      -29.408,
      2.875,
    ],
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_907",
    polygon: [
      25.822,
      -30.272,
      2.875,
      25.822,
      -30.272,
      3.875,
      26.207,
      -31.194,
      3.875,
      26.207,
      -31.194,
      3.875,
      26.207,
      -31.194,
      2.875,
      25.822,
      -30.272,
      2.875,
    ],
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_894",
    polygon: [
      20.375,
      -33.163,
      2.875,
      20.375,
      -33.163,
      3.875,
      19.195,
      -33.593,
      3.875,
      19.195,
      -33.593,
      3.875,
      19.195,
      -33.593,
      2.875,
      20.375,
      -33.163,
      2.875,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_393",
    polygon: [
      -23.145,
      4.63,
      0.4,
      -23.145,
      4.63,
      1.076,
      -21.919,
      4.873,
      1.076,
      -21.919,
      4.873,
      1.076,
      -21.919,
      4.873,
      0.4,
      -23.145,
      4.63,
      0.4,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_416",
    polygon: [
      -14.562,
      6.33,
      2.876,
      -14.562,
      6.33,
      3.901,
      -13.336,
      6.572,
      3.901,
      -13.336,
      6.572,
      3.901,
      -13.336,
      6.572,
      2.876,
      -14.562,
      6.33,
      2.876,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_905",
    polygon: [
      34.234,
      -27.374,
      2.875,
      34.234,
      -27.374,
      3.875,
      33.087,
      -27.884,
      3.875,
      33.087,
      -27.884,
      3.875,
      33.087,
      -27.884,
      2.875,
      34.234,
      -27.374,
      2.875,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_903",
    polygon: [
      31.943,
      -28.392,
      2.875,
      31.943,
      -28.392,
      3.875,
      30.796,
      -28.903,
      3.875,
      30.796,
      -28.903,
      3.875,
      30.796,
      -28.903,
      2.875,
      31.943,
      -28.392,
      2.875,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_902",
    polygon: [
      30.797,
      -28.902,
      2.875,
      30.797,
      -28.902,
      3.875,
      29.65,
      -29.412,
      3.875,
      29.65,
      -29.412,
      3.875,
      29.65,
      -29.412,
      2.875,
      30.797,
      -28.902,
      2.875,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1019",
    polygon: [
      13.959,
      11.722,
      2.855,
      13.959,
      11.722,
      3.555,
      14.202,
      10.496,
      3.555,
      14.202,
      10.496,
      3.555,
      14.202,
      10.496,
      2.855,
      13.959,
      11.722,
      2.855,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1024",
    polygon: [
      15.416,
      4.365,
      1.15,
      15.416,
      4.365,
      2.75,
      15.659,
      3.139,
      2.75,
      15.659,
      3.139,
      2.75,
      15.659,
      3.139,
      1.15,
      15.416,
      4.365,
      1.15,
    ],
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1021",
    polygon: [
      14.445,
      9.27,
      2.855,
      14.445,
      9.27,
      3.555,
      14.687,
      8.043,
      3.555,
      14.687,
      8.043,
      3.555,
      14.687,
      8.043,
      2.855,
      14.445,
      9.27,
      2.855,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_998",
    polygon: [
      34.266,
      -24.719,
      0.37,
      34.266,
      -24.719,
      4.0,
      34.814,
      -25.898,
      4.0,
      34.814,
      -25.898,
      4.0,
      34.814,
      -25.898,
      0.37,
      34.266,
      -24.719,
      0.37,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1022",
    polygon: [
      14.93,
      6.817,
      1.15,
      14.93,
      6.817,
      2.75,
      15.173,
      5.591,
      2.75,
      15.173,
      5.591,
      2.75,
      15.173,
      5.591,
      1.15,
      14.93,
      6.817,
      1.15,
    ],
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1029",
    polygon: [
      16.387,
      -0.54,
      2.855,
      16.387,
      -0.54,
      3.555,
      16.63,
      -1.766,
      3.555,
      16.63,
      -1.766,
      3.555,
      16.63,
      -1.766,
      2.855,
      16.387,
      -0.54,
      2.855,
    ],
    "05-01 09:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_944",
    polygon: [
      52.036,
      -18.328,
      0.6,
      52.036,
      -18.328,
      2.565,
      50.282,
      -19.248,
      2.565,
      50.282,
      -19.248,
      2.565,
      50.282,
      -19.248,
      0.6,
      52.036,
      -18.328,
      0.6,
    ],
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1027",
    polygon: [
      15.901,
      1.912,
      2.855,
      15.901,
      1.912,
      3.555,
      16.144,
      0.686,
      3.555,
      16.144,
      0.686,
      3.555,
      16.144,
      0.686,
      2.855,
      15.901,
      1.912,
      2.855,
    ],
    "05-01 07:45": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1023",
    polygon: [
      14.93,
      6.817,
      2.855,
      14.93,
      6.817,
      3.555,
      15.173,
      5.591,
      3.555,
      15.173,
      5.591,
      3.555,
      15.173,
      5.591,
      2.855,
      14.93,
      6.817,
      2.855,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1026",
    polygon: [
      15.901,
      1.912,
      1.15,
      15.901,
      1.912,
      2.75,
      16.144,
      0.686,
      2.75,
      16.144,
      0.686,
      2.75,
      16.144,
      0.686,
      1.15,
      15.901,
      1.912,
      1.15,
    ],
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1018",
    polygon: [
      13.959,
      11.722,
      1.15,
      13.959,
      11.722,
      2.75,
      14.202,
      10.496,
      2.75,
      14.202,
      10.496,
      2.75,
      14.202,
      10.496,
      1.15,
      13.959,
      11.722,
      1.15,
    ],
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1025",
    polygon: [
      15.416,
      4.365,
      2.855,
      15.416,
      4.365,
      3.555,
      15.659,
      3.139,
      3.555,
      15.659,
      3.139,
      3.555,
      15.659,
      3.139,
      2.855,
      15.416,
      4.365,
      2.855,
    ],
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1028",
    polygon: [
      16.387,
      -0.54,
      1.15,
      16.387,
      -0.54,
      2.75,
      16.63,
      -1.766,
      2.75,
      16.63,
      -1.766,
      2.75,
      16.63,
      -1.766,
      1.15,
      16.387,
      -0.54,
      1.15,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1020",
    polygon: [
      14.445,
      9.27,
      1.15,
      14.445,
      9.27,
      2.75,
      14.687,
      8.043,
      2.75,
      14.687,
      8.043,
      2.75,
      14.687,
      8.043,
      1.15,
      14.445,
      9.27,
      1.15,
    ],
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1050",
    polygon: [
      4.112,
      -12.171,
      3.7,
      4.112,
      -12.171,
      4.7,
      4.059,
      -11.901,
      4.7,
      4.059,
      -11.901,
      4.7,
      4.059,
      -11.901,
      3.7,
      4.112,
      -12.171,
      3.7,
    ],
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1049",
    polygon: [
      4.112,
      -12.171,
      0.4,
      4.112,
      -12.171,
      3.7,
      4.059,
      -11.901,
      3.7,
      4.059,
      -11.901,
      3.7,
      4.059,
      -11.901,
      0.4,
      4.112,
      -12.171,
      0.4,
    ],
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1051",
    polygon: [
      4.382,
      -12.117,
      0.4,
      4.382,
      -12.117,
      3.7,
      3.784,
      -12.236,
      3.7,
      3.784,
      -12.236,
      3.7,
      3.784,
      -12.236,
      0.4,
      4.382,
      -12.117,
      0.4,
    ],
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_0- EG_1039",
    polygon: [
      8.061,
      -11.389,
      0.4,
      8.061,
      -11.389,
      3.7,
      6.834,
      -11.632,
      3.7,
      6.834,
      -11.632,
      3.7,
      6.834,
      -11.632,
      0.4,
      8.061,
      -11.389,
      0.4,
    ],
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_680",
    polygon: [
      13.477,
      -36.228,
      5.695,
      13.477,
      -36.228,
      7.73,
      12.289,
      -36.631,
      7.73,
      12.289,
      -36.631,
      7.73,
      12.289,
      -36.631,
      5.695,
      13.477,
      -36.228,
      5.695,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_685",
    polygon: [
      19.393,
      -34.128,
      5.695,
      19.393,
      -34.128,
      7.73,
      18.213,
      -34.559,
      7.73,
      18.213,
      -34.559,
      7.73,
      18.213,
      -34.559,
      5.695,
      19.393,
      -34.128,
      5.695,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_688",
    polygon: [
      22.908,
      -32.783,
      5.695,
      22.908,
      -32.783,
      7.73,
      21.739,
      -33.24,
      7.73,
      21.739,
      -33.24,
      7.73,
      21.739,
      -33.24,
      5.695,
      22.908,
      -32.783,
      5.695,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_786",
    polygon: [
      4.661,
      25.603,
      4.7,
      4.661,
      25.603,
      7.7,
      5.884,
      25.343,
      7.7,
      5.884,
      25.343,
      7.7,
      5.884,
      25.343,
      4.7,
      4.661,
      25.603,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_792",
    polygon: [
      0.993,
      26.383,
      4.7,
      0.993,
      26.383,
      7.7,
      2.216,
      26.123,
      7.7,
      2.216,
      26.123,
      7.7,
      2.216,
      26.123,
      4.7,
      0.993,
      26.383,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_784",
    polygon: [
      5.884,
      25.343,
      4.7,
      5.884,
      25.343,
      7.7,
      7.106,
      25.083,
      7.7,
      7.106,
      25.083,
      7.7,
      7.106,
      25.083,
      4.7,
      5.884,
      25.343,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_478",
    polygon: [
      -3.526,
      8.515,
      5.566,
      -3.526,
      8.515,
      7.711,
      -2.3,
      8.757,
      7.711,
      -2.3,
      8.757,
      7.711,
      -2.3,
      8.757,
      5.566,
      -3.526,
      8.515,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_689",
    polygon: [
      24.079,
      -32.325,
      5.695,
      24.079,
      -32.325,
      7.73,
      22.909,
      -32.783,
      7.73,
      22.909,
      -32.783,
      7.73,
      22.909,
      -32.783,
      5.695,
      24.079,
      -32.325,
      5.695,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_472",
    polygon: [
      -10.883,
      7.058,
      5.566,
      -10.883,
      7.058,
      7.711,
      -9.657,
      7.301,
      7.711,
      -9.657,
      7.301,
      7.711,
      -9.657,
      7.301,
      5.566,
      -10.883,
      7.058,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_470",
    polygon: [
      -20.693,
      5.116,
      5.566,
      -20.693,
      5.116,
      7.711,
      -19.466,
      5.358,
      7.711,
      -19.466,
      5.358,
      7.711,
      -19.466,
      5.358,
      5.566,
      -20.693,
      5.116,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_678",
    polygon: [
      11.103,
      -37.034,
      5.695,
      11.103,
      -37.034,
      7.73,
      9.905,
      -37.411,
      7.73,
      9.905,
      -37.411,
      7.73,
      9.905,
      -37.411,
      5.695,
      11.103,
      -37.034,
      5.695,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_684",
    polygon: [
      18.213,
      -34.559,
      5.695,
      18.213,
      -34.559,
      7.73,
      17.033,
      -34.99,
      7.73,
      17.033,
      -34.99,
      7.73,
      17.033,
      -34.99,
      5.695,
      18.213,
      -34.559,
      5.695,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_471",
    polygon: [
      -19.466,
      5.358,
      5.566,
      -19.466,
      5.358,
      7.711,
      -18.24,
      5.601,
      7.711,
      -18.24,
      5.601,
      7.711,
      -18.24,
      5.601,
      5.566,
      -19.466,
      5.358,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_777",
    polygon: [
      9.552,
      24.564,
      4.7,
      9.552,
      24.564,
      7.7,
      10.826,
      24.293,
      7.7,
      10.826,
      24.293,
      7.7,
      10.826,
      24.293,
      4.7,
      9.552,
      24.564,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_779",
    polygon: [
      8.329,
      24.824,
      4.7,
      8.329,
      24.824,
      7.7,
      9.552,
      24.564,
      7.7,
      9.552,
      24.564,
      7.7,
      9.552,
      24.564,
      4.7,
      8.329,
      24.824,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_677",
    polygon: [
      9.904,
      -37.411,
      5.695,
      9.904,
      -37.411,
      7.73,
      8.706,
      -37.788,
      7.73,
      8.706,
      -37.788,
      7.73,
      8.706,
      -37.788,
      5.695,
      9.904,
      -37.411,
      5.695,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_702",
    polygon: [
      9.904,
      -37.411,
      9.604,
      9.904,
      -37.411,
      11.639,
      8.706,
      -37.788,
      11.639,
      8.706,
      -37.788,
      11.639,
      8.706,
      -37.788,
      9.604,
      9.904,
      -37.411,
      9.604,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_692",
    polygon: [
      27.563,
      -30.901,
      5.695,
      27.563,
      -30.901,
      7.73,
      26.404,
      -31.385,
      7.73,
      26.404,
      -31.385,
      7.73,
      26.404,
      -31.385,
      5.695,
      27.563,
      -30.901,
      5.695,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_479",
    polygon: [
      -2.3,
      8.757,
      5.566,
      -2.3,
      8.757,
      7.711,
      -1.074,
      9.0,
      7.711,
      -1.074,
      9.0,
      7.711,
      -1.074,
      9.0,
      5.566,
      -2.3,
      8.757,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_681",
    polygon: [
      14.667,
      -35.823,
      5.695,
      14.667,
      -35.823,
      7.73,
      13.478,
      -36.227,
      7.73,
      13.478,
      -36.227,
      7.73,
      13.478,
      -36.227,
      5.695,
      14.667,
      -35.823,
      5.695,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_463",
    polygon: [
      -15.788,
      6.087,
      5.566,
      -15.788,
      6.087,
      7.711,
      -14.562,
      6.33,
      7.711,
      -14.562,
      6.33,
      7.711,
      -14.562,
      6.33,
      5.566,
      -15.788,
      6.087,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_676",
    polygon: [
      8.706,
      -37.788,
      5.695,
      8.706,
      -37.788,
      7.73,
      7.508,
      -38.165,
      7.73,
      7.508,
      -38.165,
      7.73,
      7.508,
      -38.165,
      5.695,
      8.706,
      -37.788,
      5.695,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_477",
    polygon: [
      -5.978,
      8.029,
      5.566,
      -5.978,
      8.029,
      7.711,
      -4.752,
      8.272,
      7.711,
      -4.752,
      8.272,
      7.711,
      -4.752,
      8.272,
      5.566,
      -5.978,
      8.029,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_700",
    polygon: [
      7.508,
      -38.165,
      9.604,
      7.508,
      -38.165,
      11.639,
      6.31,
      -38.542,
      11.639,
      6.31,
      -38.542,
      11.639,
      6.31,
      -38.542,
      9.604,
      7.508,
      -38.165,
      9.604,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_686",
    polygon: [
      20.573,
      -33.697,
      5.695,
      20.573,
      -33.697,
      7.73,
      19.394,
      -34.127,
      7.73,
      19.394,
      -34.127,
      7.73,
      19.394,
      -34.127,
      5.695,
      20.573,
      -33.697,
      5.695,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_690",
    polygon: [
      25.249,
      -31.867,
      5.695,
      25.249,
      -31.867,
      7.73,
      24.079,
      -32.325,
      7.73,
      24.079,
      -32.325,
      7.73,
      24.079,
      -32.325,
      5.695,
      25.249,
      -31.867,
      5.695,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_721",
    polygon: [
      32.173,
      -28.913,
      9.604,
      32.173,
      -28.913,
      11.639,
      31.026,
      -29.423,
      11.639,
      31.026,
      -29.423,
      11.639,
      31.026,
      -29.423,
      9.604,
      32.173,
      -28.913,
      9.604,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_722",
    polygon: [
      33.321,
      -28.403,
      9.604,
      33.321,
      -28.403,
      11.639,
      32.174,
      -28.913,
      11.639,
      32.174,
      -28.913,
      11.639,
      32.174,
      -28.913,
      9.604,
      33.321,
      -28.403,
      9.604,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_705",
    polygon: [
      13.477,
      -36.228,
      9.604,
      13.477,
      -36.228,
      11.639,
      12.289,
      -36.631,
      11.639,
      12.289,
      -36.631,
      11.639,
      12.289,
      -36.631,
      9.604,
      13.477,
      -36.228,
      9.604,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_706",
    polygon: [
      14.667,
      -35.823,
      9.604,
      14.667,
      -35.823,
      11.639,
      13.478,
      -36.227,
      11.639,
      13.478,
      -36.227,
      11.639,
      13.478,
      -36.227,
      9.604,
      14.667,
      -35.823,
      9.604,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_782",
    polygon: [
      7.106,
      25.083,
      4.7,
      7.106,
      25.083,
      7.7,
      8.329,
      24.824,
      7.7,
      8.329,
      24.824,
      7.7,
      8.329,
      24.824,
      4.7,
      7.106,
      25.083,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_499",
    polygon: [
      -2.3,
      8.757,
      9.326,
      -2.3,
      8.757,
      11.471,
      -1.074,
      9.0,
      11.471,
      -1.074,
      9.0,
      11.471,
      -1.074,
      9.0,
      9.326,
      -2.3,
      8.757,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_710",
    polygon: [
      19.393,
      -34.128,
      9.604,
      19.393,
      -34.128,
      11.639,
      18.213,
      -34.559,
      11.639,
      18.213,
      -34.559,
      11.639,
      18.213,
      -34.559,
      9.604,
      19.393,
      -34.128,
      9.604,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_682",
    polygon: [
      15.857,
      -35.419,
      5.695,
      15.857,
      -35.419,
      7.73,
      14.668,
      -35.823,
      7.73,
      14.668,
      -35.823,
      7.73,
      14.668,
      -35.823,
      5.695,
      15.857,
      -35.419,
      5.695,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_709",
    polygon: [
      18.213,
      -34.559,
      9.604,
      18.213,
      -34.559,
      11.639,
      17.033,
      -34.99,
      11.639,
      17.033,
      -34.99,
      11.639,
      17.033,
      -34.99,
      9.604,
      18.213,
      -34.559,
      9.604,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_713",
    polygon: [
      22.908,
      -32.783,
      9.604,
      22.908,
      -32.783,
      11.639,
      21.739,
      -33.24,
      11.639,
      21.739,
      -33.24,
      11.639,
      21.739,
      -33.24,
      9.604,
      22.908,
      -32.783,
      9.604,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_810",
    polygon: [
      3.924,
      23.411,
      8.45,
      3.924,
      23.411,
      11.45,
      5.147,
      23.151,
      11.45,
      5.147,
      23.151,
      11.45,
      5.147,
      23.151,
      8.45,
      3.924,
      23.411,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_804",
    polygon: [
      7.592,
      22.631,
      8.45,
      7.592,
      22.631,
      11.45,
      8.815,
      22.371,
      11.45,
      8.815,
      22.371,
      11.45,
      8.815,
      22.371,
      8.45,
      7.592,
      22.631,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_717",
    polygon: [
      27.563,
      -30.901,
      9.604,
      27.563,
      -30.901,
      11.639,
      26.404,
      -31.385,
      11.639,
      26.404,
      -31.385,
      11.639,
      26.404,
      -31.385,
      9.604,
      27.563,
      -30.901,
      9.604,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_812",
    polygon: [
      2.701,
      23.67,
      8.45,
      2.701,
      23.67,
      11.45,
      3.924,
      23.411,
      11.45,
      3.924,
      23.411,
      11.45,
      3.924,
      23.411,
      8.45,
      2.701,
      23.67,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_814",
    polygon: [
      1.479,
      23.93,
      8.45,
      1.479,
      23.93,
      11.45,
      2.701,
      23.67,
      11.45,
      2.701,
      23.67,
      11.45,
      2.701,
      23.67,
      8.45,
      1.479,
      23.93,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_798",
    polygon: [
      10.037,
      22.111,
      8.45,
      10.037,
      22.111,
      11.45,
      11.312,
      21.84,
      11.45,
      11.312,
      21.84,
      11.45,
      11.312,
      21.84,
      8.45,
      10.037,
      22.111,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_724",
    polygon: [
      11.097,
      -37.036,
      9.604,
      11.097,
      -37.036,
      11.639,
      9.899,
      -37.413,
      11.639,
      9.899,
      -37.413,
      11.639,
      9.899,
      -37.413,
      9.604,
      11.097,
      -37.036,
      9.604,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_714",
    polygon: [
      24.079,
      -32.325,
      9.604,
      24.079,
      -32.325,
      11.639,
      22.909,
      -32.783,
      11.639,
      22.909,
      -32.783,
      11.639,
      22.909,
      -32.783,
      9.604,
      24.079,
      -32.325,
      9.604,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_949",
    polygon: [
      49.258,
      -19.785,
      7.55,
      49.258,
      -19.785,
      9.515,
      47.505,
      -20.705,
      9.515,
      47.505,
      -20.705,
      9.515,
      47.505,
      -20.705,
      7.55,
      49.258,
      -19.785,
      7.55,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_816",
    polygon: [
      0.256,
      24.19,
      8.45,
      0.256,
      24.19,
      11.45,
      1.479,
      23.93,
      11.45,
      1.479,
      23.93,
      11.45,
      1.479,
      23.93,
      8.45,
      0.256,
      24.19,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_808",
    polygon: [
      5.147,
      23.151,
      8.45,
      5.147,
      23.151,
      11.45,
      6.369,
      22.891,
      11.45,
      6.369,
      22.891,
      11.45,
      6.369,
      22.891,
      8.45,
      5.147,
      23.151,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_915",
    polygon: [
      38.176,
      -24.704,
      9.604,
      38.176,
      -24.704,
      11.48,
      37.232,
      -25.163,
      11.48,
      37.232,
      -25.163,
      11.48,
      37.232,
      -25.163,
      9.604,
      38.176,
      -24.704,
      9.604,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_718",
    polygon: [
      28.722,
      -30.416,
      9.604,
      28.722,
      -30.416,
      11.639,
      27.564,
      -30.9,
      11.639,
      27.564,
      -30.9,
      11.639,
      27.564,
      -30.9,
      9.604,
      28.722,
      -30.416,
      9.604,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_916",
    polygon: [
      39.121,
      -24.245,
      9.604,
      39.121,
      -24.245,
      11.48,
      38.176,
      -24.704,
      11.48,
      38.176,
      -24.704,
      11.48,
      38.176,
      -24.704,
      9.604,
      39.121,
      -24.245,
      9.604,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_918",
    polygon: [
      41.009,
      -23.327,
      9.604,
      41.009,
      -23.327,
      11.48,
      40.065,
      -23.786,
      11.48,
      40.065,
      -23.786,
      11.48,
      40.065,
      -23.786,
      9.604,
      41.009,
      -23.327,
      9.604,
    ],
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_806",
    polygon: [
      6.369,
      22.891,
      8.45,
      6.369,
      22.891,
      11.45,
      7.592,
      22.631,
      11.45,
      7.592,
      22.631,
      11.45,
      7.592,
      22.631,
      8.45,
      6.369,
      22.891,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_913",
    polygon: [
      36.288,
      -25.622,
      9.604,
      36.288,
      -25.622,
      11.48,
      35.343,
      -26.081,
      11.48,
      35.343,
      -26.081,
      11.48,
      35.343,
      -26.081,
      9.604,
      36.288,
      -25.622,
      9.604,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_643",
    polygon: [
      16.84,
      -34.458,
      -0.25,
      16.84,
      -34.458,
      2.875,
      15.661,
      -34.889,
      2.875,
      15.661,
      -34.889,
      2.875,
      15.661,
      -34.889,
      -0.25,
      16.84,
      -34.458,
      -0.25,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_345",
    polygon: [
      0.468,
      -5.221,
      9.326,
      0.468,
      -5.221,
      11.471,
      -0.758,
      -5.464,
      11.471,
      -0.758,
      -5.464,
      11.471,
      -0.758,
      -5.464,
      9.326,
      0.468,
      -5.221,
      9.326,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_820",
    polygon: [
      -2.241,
      24.721,
      8.45,
      -2.241,
      24.721,
      11.45,
      -0.967,
      24.45,
      11.45,
      -0.967,
      24.45,
      11.45,
      -0.967,
      24.45,
      8.45,
      -2.241,
      24.721,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_802",
    polygon: [
      8.815,
      22.371,
      8.45,
      8.815,
      22.371,
      11.45,
      10.037,
      22.111,
      11.45,
      10.037,
      22.111,
      11.45,
      10.037,
      22.111,
      8.45,
      8.815,
      22.371,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_464",
    polygon: [
      -13.336,
      6.572,
      5.566,
      -13.336,
      6.572,
      7.711,
      -12.109,
      6.815,
      7.711,
      -12.109,
      6.815,
      7.711,
      -12.109,
      6.815,
      5.566,
      -13.336,
      6.572,
      5.566,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_917",
    polygon: [
      40.065,
      -23.786,
      9.604,
      40.065,
      -23.786,
      11.48,
      39.121,
      -24.245,
      11.48,
      39.121,
      -24.245,
      11.48,
      39.121,
      -24.245,
      9.604,
      40.065,
      -23.786,
      9.604,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_642",
    polygon: [
      18.019,
      -34.027,
      -0.25,
      18.019,
      -34.027,
      2.875,
      16.84,
      -34.458,
      2.875,
      16.84,
      -34.458,
      2.875,
      16.84,
      -34.458,
      -0.25,
      18.019,
      -34.027,
      -0.25,
    ],
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_644",
    polygon: [
      15.672,
      -34.884,
      -0.25,
      15.672,
      -34.884,
      2.875,
      14.483,
      -35.288,
      2.875,
      14.483,
      -35.288,
      2.875,
      14.483,
      -35.288,
      -0.25,
      15.672,
      -34.884,
      -0.25,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_914",
    polygon: [
      37.232,
      -25.163,
      9.604,
      37.232,
      -25.163,
      11.48,
      36.288,
      -25.622,
      11.48,
      36.288,
      -25.622,
      11.48,
      36.288,
      -25.622,
      9.604,
      37.232,
      -25.163,
      9.604,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_818",
    polygon: [
      -0.967,
      24.45,
      8.45,
      -0.967,
      24.45,
      11.45,
      0.256,
      24.19,
      11.45,
      0.256,
      24.19,
      11.45,
      0.256,
      24.19,
      8.45,
      -0.967,
      24.45,
      8.45,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_693",
    polygon: [
      28.722,
      -30.416,
      5.695,
      28.722,
      -30.416,
      7.73,
      27.564,
      -30.9,
      7.73,
      27.564,
      -30.9,
      7.73,
      27.564,
      -30.9,
      5.695,
      28.722,
      -30.416,
      5.695,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_484",
    polygon: [
      -13.336,
      6.572,
      9.326,
      -13.336,
      6.572,
      11.471,
      -12.109,
      6.815,
      11.471,
      -12.109,
      6.815,
      11.471,
      -12.109,
      6.815,
      9.326,
      -13.336,
      6.572,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_648",
    polygon: [
      22.703,
      -32.256,
      -0.25,
      22.703,
      -32.256,
      2.875,
      21.534,
      -32.713,
      2.875,
      21.534,
      -32.713,
      2.875,
      21.534,
      -32.713,
      -0.25,
      22.703,
      -32.256,
      -0.25,
    ],
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_493",
    polygon: [
      -9.657,
      7.301,
      9.326,
      -9.657,
      7.301,
      11.471,
      -8.431,
      7.544,
      11.471,
      -8.431,
      7.544,
      11.471,
      -8.431,
      7.544,
      9.326,
      -9.657,
      7.301,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_646",
    polygon: [
      20.373,
      -33.167,
      -0.25,
      20.373,
      -33.167,
      2.875,
      19.194,
      -33.598,
      2.875,
      19.194,
      -33.598,
      2.875,
      19.194,
      -33.598,
      -0.25,
      20.373,
      -33.167,
      -0.25,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_492",
    polygon: [
      -10.883,
      7.058,
      9.326,
      -10.883,
      7.058,
      11.471,
      -9.657,
      7.301,
      11.471,
      -9.657,
      7.301,
      11.471,
      -9.657,
      7.301,
      9.326,
      -10.883,
      7.058,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_498",
    polygon: [
      -3.526,
      8.515,
      9.326,
      -3.526,
      8.515,
      11.471,
      -2.3,
      8.757,
      11.471,
      -2.3,
      8.757,
      11.471,
      -2.3,
      8.757,
      9.326,
      -3.526,
      8.515,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_500",
    polygon: [
      -4.752,
      8.272,
      9.326,
      -4.752,
      8.272,
      11.471,
      -3.526,
      8.515,
      11.471,
      -3.526,
      8.515,
      11.471,
      -3.526,
      8.515,
      9.326,
      -4.752,
      8.272,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_701",
    polygon: [
      8.706,
      -37.788,
      9.604,
      8.706,
      -37.788,
      11.639,
      7.508,
      -38.165,
      11.639,
      7.508,
      -38.165,
      11.639,
      7.508,
      -38.165,
      9.604,
      8.706,
      -37.788,
      9.604,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_645",
    polygon: [
      19.197,
      -33.597,
      -0.25,
      19.197,
      -33.597,
      2.875,
      18.017,
      -34.028,
      2.875,
      18.017,
      -34.028,
      2.875,
      18.017,
      -34.028,
      -0.25,
      19.197,
      -33.597,
      -0.25,
    ],
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_950",
    polygon: [
      52.036,
      -18.328,
      7.55,
      52.036,
      -18.328,
      9.515,
      50.282,
      -19.248,
      9.515,
      50.282,
      -19.248,
      9.515,
      50.282,
      -19.248,
      7.55,
      52.036,
      -18.328,
      7.55,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_657",
    polygon: [
      34.234,
      -27.378,
      -0.25,
      34.234,
      -27.378,
      2.875,
      33.087,
      -27.888,
      2.875,
      33.087,
      -27.888,
      2.875,
      33.087,
      -27.888,
      -0.25,
      34.234,
      -27.378,
      -0.25,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_1075",
    polygon: [
      26.191,
      -30.857,
      -0.25,
      26.191,
      -30.857,
      2.875,
      25.032,
      -31.341,
      2.875,
      25.032,
      -31.341,
      2.875,
      25.032,
      -31.341,
      -0.25,
      26.191,
      -30.857,
      -0.25,
    ],
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_337",
    polygon: [
      -9.341,
      -7.163,
      9.326,
      -9.341,
      -7.163,
      11.471,
      -10.568,
      -7.406,
      11.471,
      -10.568,
      -7.406,
      11.471,
      -10.568,
      -7.406,
      9.326,
      -9.341,
      -7.163,
      9.326,
    ],
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_882",
    polygon: [
      7.508,
      -38.165,
      5.695,
      7.508,
      -38.165,
      7.73,
      6.31,
      -38.542,
      7.73,
      6.31,
      -38.542,
      7.73,
      6.31,
      -38.542,
      5.695,
      7.508,
      -38.165,
      5.695,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_799",
    polygon: [
      -2.727,
      27.174,
      4.7,
      -2.727,
      27.174,
      7.7,
      -1.452,
      26.903,
      7.7,
      -1.452,
      26.903,
      7.7,
      -1.452,
      26.903,
      4.7,
      -2.727,
      27.174,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_945",
    polygon: [
      46.465,
      -21.251,
      4.32,
      46.465,
      -21.251,
      6.285,
      44.712,
      -22.171,
      6.285,
      44.712,
      -22.171,
      6.285,
      44.712,
      -22.171,
      4.32,
      46.465,
      -21.251,
      4.32,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_796",
    polygon: [
      -1.452,
      26.903,
      4.7,
      -1.452,
      26.903,
      7.7,
      -0.23,
      26.643,
      7.7,
      -0.23,
      26.643,
      7.7,
      -0.23,
      26.643,
      4.7,
      -1.452,
      26.903,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_699",
    polygon: [
      34.316,
      -27.722,
      5.695,
      34.316,
      -27.722,
      7.73,
      34.449,
      -28.023,
      7.73,
      34.449,
      -28.023,
      7.73,
      34.449,
      -28.023,
      5.695,
      34.316,
      -27.722,
      5.695,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_697",
    polygon: [
      33.321,
      -28.403,
      5.695,
      33.321,
      -28.403,
      7.73,
      32.174,
      -28.913,
      7.73,
      32.174,
      -28.913,
      7.73,
      32.174,
      -28.913,
      5.695,
      33.321,
      -28.403,
      5.695,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_371",
    polygon: [
      -16.699,
      -8.62,
      13.076,
      -16.699,
      -8.62,
      15.221,
      -17.925,
      -8.863,
      15.221,
      -17.925,
      -8.863,
      15.221,
      -17.925,
      -8.863,
      13.076,
      -16.699,
      -8.62,
      13.076,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_647",
    polygon: [
      21.538,
      -32.711,
      -0.25,
      21.538,
      -32.711,
      2.875,
      20.369,
      -33.169,
      2.875,
      20.369,
      -33.169,
      2.875,
      20.369,
      -33.169,
      -0.25,
      21.538,
      -32.711,
      -0.25,
    ],
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_649",
    polygon: [
      23.871,
      -31.799,
      -0.25,
      23.871,
      -31.799,
      2.875,
      22.701,
      -32.256,
      2.875,
      22.701,
      -32.256,
      2.875,
      22.701,
      -32.256,
      -0.25,
      23.871,
      -31.799,
      -0.25,
    ],
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_992",
    polygon: [
      1.582,
      -24.573,
      5.595,
      1.582,
      -24.573,
      7.695,
      3.008,
      -24.107,
      7.695,
      3.008,
      -24.107,
      7.695,
      3.008,
      -24.107,
      5.595,
      1.582,
      -24.573,
      5.595,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_991",
    polygon: [
      3.958,
      -23.796,
      5.595,
      3.958,
      -23.796,
      7.695,
      5.384,
      -23.329,
      7.695,
      5.384,
      -23.329,
      7.695,
      5.384,
      -23.329,
      5.595,
      3.958,
      -23.796,
      5.595,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_946",
    polygon: [
      49.258,
      -19.785,
      4.32,
      49.258,
      -19.785,
      6.285,
      47.505,
      -20.705,
      6.285,
      47.505,
      -20.705,
      6.285,
      47.505,
      -20.705,
      4.32,
      49.258,
      -19.785,
      4.32,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_947",
    polygon: [
      52.036,
      -18.328,
      4.32,
      52.036,
      -18.328,
      6.285,
      50.282,
      -19.248,
      6.285,
      50.282,
      -19.248,
      6.285,
      50.282,
      -19.248,
      4.32,
      52.036,
      -18.328,
      4.32,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_790",
    polygon: [
      2.216,
      26.123,
      4.7,
      2.216,
      26.123,
      7.7,
      3.438,
      25.863,
      7.7,
      3.438,
      25.863,
      7.7,
      3.438,
      25.863,
      4.7,
      2.216,
      26.123,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_788",
    polygon: [
      3.438,
      25.863,
      4.7,
      3.438,
      25.863,
      7.7,
      4.661,
      25.603,
      7.7,
      4.661,
      25.603,
      7.7,
      4.661,
      25.603,
      4.7,
      3.438,
      25.863,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_794",
    polygon: [
      -0.23,
      26.643,
      4.7,
      -0.23,
      26.643,
      7.7,
      0.993,
      26.383,
      7.7,
      0.993,
      26.383,
      7.7,
      0.993,
      26.383,
      4.7,
      -0.23,
      26.643,
      4.7,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_654",
    polygon: [
      30.801,
      -28.904,
      -0.25,
      30.801,
      -28.904,
      2.875,
      29.654,
      -29.414,
      2.875,
      29.654,
      -29.414,
      2.875,
      29.654,
      -29.414,
      -0.25,
      30.801,
      -28.904,
      -0.25,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_655",
    polygon: [
      31.944,
      -28.396,
      -0.25,
      31.944,
      -28.396,
      2.875,
      30.797,
      -28.906,
      2.875,
      30.797,
      -28.906,
      2.875,
      30.797,
      -28.906,
      -0.25,
      31.944,
      -28.396,
      -0.25,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_1036",
    polygon: [
      5.608,
      -11.874,
      0.4,
      5.608,
      -11.874,
      3.7,
      4.382,
      -12.117,
      3.7,
      4.382,
      -12.117,
      3.7,
      4.382,
      -12.117,
      0.4,
      5.608,
      -11.874,
      0.4,
    ],
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_656",
    polygon: [
      33.09,
      -27.887,
      -0.25,
      33.09,
      -27.887,
      2.875,
      31.942,
      -28.397,
      2.875,
      31.942,
      -28.397,
      2.875,
      31.942,
      -28.397,
      -0.25,
      33.09,
      -27.887,
      -0.25,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_990",
    polygon: [
      1.582,
      -24.573,
      9.345,
      1.582,
      -24.573,
      11.445,
      3.008,
      -24.107,
      11.445,
      3.008,
      -24.107,
      11.445,
      3.008,
      -24.107,
      9.345,
      1.582,
      -24.573,
      9.345,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_387",
    polygon: [
      2.347,
      -4.849,
      13.076,
      2.347,
      -4.849,
      15.221,
      1.694,
      -4.978,
      15.221,
      1.694,
      -4.978,
      15.221,
      1.694,
      -4.978,
      13.076,
      2.347,
      -4.849,
      13.076,
    ],
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_734",
    polygon: [
      14.667,
      -35.823,
      13.513,
      14.667,
      -35.823,
      15.548,
      13.478,
      -36.227,
      15.548,
      13.478,
      -36.227,
      15.548,
      13.478,
      -36.227,
      13.513,
      14.667,
      -35.823,
      13.513,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_730",
    polygon: [
      8.706,
      -37.788,
      13.513,
      8.706,
      -37.788,
      15.548,
      7.508,
      -38.165,
      15.548,
      7.508,
      -38.165,
      15.548,
      7.508,
      -38.165,
      13.513,
      8.706,
      -37.788,
      13.513,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_736",
    polygon: [
      18.213,
      -34.559,
      13.513,
      18.213,
      -34.559,
      15.548,
      17.033,
      -34.99,
      15.548,
      17.033,
      -34.99,
      15.548,
      17.033,
      -34.99,
      13.513,
      18.213,
      -34.559,
      13.513,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_731",
    polygon: [
      9.904,
      -37.411,
      13.513,
      9.904,
      -37.411,
      15.548,
      8.706,
      -37.788,
      15.548,
      8.706,
      -37.788,
      15.548,
      8.706,
      -37.788,
      13.513,
      9.904,
      -37.411,
      13.513,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_743",
    polygon: [
      28.722,
      -30.416,
      13.513,
      28.722,
      -30.416,
      15.548,
      27.564,
      -30.9,
      15.548,
      27.564,
      -30.9,
      15.548,
      27.564,
      -30.9,
      13.513,
      28.722,
      -30.416,
      13.513,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_737",
    polygon: [
      19.393,
      -34.128,
      13.513,
      19.393,
      -34.128,
      15.548,
      18.213,
      -34.559,
      15.548,
      18.213,
      -34.559,
      15.548,
      18.213,
      -34.559,
      13.513,
      19.393,
      -34.128,
      13.513,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_733",
    polygon: [
      13.477,
      -36.228,
      13.513,
      13.477,
      -36.228,
      15.548,
      12.289,
      -36.631,
      15.548,
      12.289,
      -36.631,
      15.548,
      12.289,
      -36.631,
      13.513,
      13.477,
      -36.228,
      13.513,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_729",
    polygon: [
      7.508,
      -38.165,
      13.513,
      7.508,
      -38.165,
      15.548,
      6.31,
      -38.542,
      15.548,
      6.31,
      -38.542,
      15.548,
      6.31,
      -38.542,
      13.513,
      7.508,
      -38.165,
      13.513,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_739",
    polygon: [
      22.908,
      -32.783,
      13.513,
      22.908,
      -32.783,
      15.548,
      21.739,
      -33.24,
      15.548,
      21.739,
      -33.24,
      15.548,
      21.739,
      -33.24,
      13.513,
      22.908,
      -32.783,
      13.513,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_742",
    polygon: [
      27.563,
      -30.901,
      13.513,
      27.563,
      -30.901,
      15.548,
      26.404,
      -31.385,
      15.548,
      26.404,
      -31.385,
      15.548,
      26.404,
      -31.385,
      13.513,
      27.563,
      -30.901,
      13.513,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_740",
    polygon: [
      24.079,
      -32.325,
      13.513,
      24.079,
      -32.325,
      15.548,
      22.909,
      -32.783,
      15.548,
      22.909,
      -32.783,
      15.548,
      22.909,
      -32.783,
      13.513,
      24.079,
      -32.325,
      13.513,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_822",
    polygon: [
      10.523,
      19.659,
      12.2,
      10.523,
      19.659,
      15.2,
      11.798,
      19.388,
      15.2,
      11.798,
      19.388,
      15.2,
      11.798,
      19.388,
      12.2,
      10.523,
      19.659,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_824",
    polygon: [
      9.3,
      19.919,
      12.2,
      9.3,
      19.919,
      15.2,
      10.523,
      19.659,
      15.2,
      10.523,
      19.659,
      15.2,
      10.523,
      19.659,
      12.2,
      9.3,
      19.919,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_745",
    polygon: [
      32.173,
      -28.913,
      13.513,
      32.173,
      -28.913,
      15.548,
      31.026,
      -29.423,
      15.548,
      31.026,
      -29.423,
      15.548,
      31.026,
      -29.423,
      13.513,
      32.173,
      -28.913,
      13.513,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_379",
    polygon: [
      -6.889,
      -6.678,
      13.076,
      -6.889,
      -6.678,
      15.221,
      -8.115,
      -6.921,
      15.221,
      -8.115,
      -6.921,
      15.221,
      -8.115,
      -6.921,
      13.076,
      -6.889,
      -6.678,
      13.076,
    ],
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_378",
    polygon: [
      -8.115,
      -6.921,
      13.076,
      -8.115,
      -6.921,
      15.221,
      -9.341,
      -7.163,
      15.221,
      -9.341,
      -7.163,
      15.221,
      -9.341,
      -7.163,
      13.076,
      -8.115,
      -6.921,
      13.076,
    ],
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_748",
    polygon: [
      11.097,
      -37.036,
      13.513,
      11.097,
      -37.036,
      15.548,
      9.899,
      -37.413,
      15.548,
      9.899,
      -37.413,
      15.548,
      9.899,
      -37.413,
      13.513,
      11.097,
      -37.036,
      13.513,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_746",
    polygon: [
      33.321,
      -28.403,
      13.513,
      33.321,
      -28.403,
      15.548,
      32.174,
      -28.913,
      15.548,
      32.174,
      -28.913,
      15.548,
      32.174,
      -28.913,
      13.513,
      33.321,
      -28.403,
      13.513,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_834",
    polygon: [
      3.187,
      21.218,
      12.2,
      3.187,
      21.218,
      15.2,
      4.41,
      20.958,
      15.2,
      4.41,
      20.958,
      15.2,
      4.41,
      20.958,
      12.2,
      3.187,
      21.218,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_934",
    polygon: [
      43.82,
      -22.639,
      13.115,
      43.82,
      -22.639,
      14.88,
      42.114,
      -23.534,
      14.88,
      42.114,
      -23.534,
      14.88,
      42.114,
      -23.534,
      13.115,
      43.82,
      -22.639,
      13.115,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_923",
    polygon: [
      40.065,
      -23.786,
      13.513,
      40.065,
      -23.786,
      15.389,
      39.121,
      -24.245,
      15.389,
      39.121,
      -24.245,
      15.389,
      39.121,
      -24.245,
      13.513,
      40.065,
      -23.786,
      13.513,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_513",
    polygon: [
      -9.657,
      7.301,
      13.076,
      -9.657,
      7.301,
      15.221,
      -8.431,
      7.544,
      15.221,
      -8.431,
      7.544,
      15.221,
      -8.431,
      7.544,
      13.076,
      -9.657,
      7.301,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_830",
    polygon: [
      5.632,
      20.698,
      12.2,
      5.632,
      20.698,
      15.2,
      6.855,
      20.438,
      15.2,
      6.855,
      20.438,
      15.2,
      6.855,
      20.438,
      12.2,
      5.632,
      20.698,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_386",
    polygon: [
      1.694,
      -4.978,
      13.076,
      1.694,
      -4.978,
      15.221,
      0.468,
      -5.221,
      15.221,
      0.468,
      -5.221,
      15.221,
      0.468,
      -5.221,
      13.076,
      1.694,
      -4.978,
      13.076,
    ],
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_828",
    polygon: [
      6.855,
      20.438,
      12.2,
      6.855,
      20.438,
      15.2,
      8.078,
      20.178,
      15.2,
      8.078,
      20.178,
      15.2,
      8.078,
      20.178,
      12.2,
      6.855,
      20.438,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_836",
    polygon: [
      1.964,
      21.478,
      12.2,
      1.964,
      21.478,
      15.2,
      3.187,
      21.218,
      15.2,
      3.187,
      21.218,
      15.2,
      3.187,
      21.218,
      12.2,
      1.964,
      21.478,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_937",
    polygon: [
      52.015,
      -18.339,
      13.115,
      52.015,
      -18.339,
      14.88,
      50.309,
      -19.234,
      14.88,
      50.309,
      -19.234,
      14.88,
      50.309,
      -19.234,
      13.115,
      52.015,
      -18.339,
      13.115,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_919",
    polygon: [
      36.288,
      -25.622,
      13.513,
      36.288,
      -25.622,
      15.389,
      35.343,
      -26.081,
      15.389,
      35.343,
      -26.081,
      15.389,
      35.343,
      -26.081,
      13.513,
      36.288,
      -25.622,
      13.513,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_920",
    polygon: [
      37.232,
      -25.163,
      13.513,
      37.232,
      -25.163,
      15.389,
      36.288,
      -25.622,
      15.389,
      36.288,
      -25.622,
      15.389,
      36.288,
      -25.622,
      13.513,
      37.232,
      -25.163,
      13.513,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_826",
    polygon: [
      8.078,
      20.178,
      12.2,
      8.078,
      20.178,
      15.2,
      9.3,
      19.919,
      15.2,
      9.3,
      19.919,
      15.2,
      9.3,
      19.919,
      12.2,
      8.078,
      20.178,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_832",
    polygon: [
      4.41,
      20.958,
      12.2,
      4.41,
      20.958,
      15.2,
      5.632,
      20.698,
      15.2,
      5.632,
      20.698,
      15.2,
      5.632,
      20.698,
      12.2,
      4.41,
      20.958,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_921",
    polygon: [
      38.176,
      -24.704,
      13.513,
      38.176,
      -24.704,
      15.389,
      37.232,
      -25.163,
      15.389,
      37.232,
      -25.163,
      15.389,
      37.232,
      -25.163,
      13.513,
      38.176,
      -24.704,
      13.513,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_924",
    polygon: [
      41.009,
      -23.327,
      13.513,
      41.009,
      -23.327,
      15.389,
      40.065,
      -23.786,
      15.389,
      40.065,
      -23.786,
      15.389,
      40.065,
      -23.786,
      13.513,
      41.009,
      -23.327,
      13.513,
    ],
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_840",
    polygon: [
      -0.481,
      21.998,
      12.2,
      -0.481,
      21.998,
      15.2,
      0.742,
      21.738,
      15.2,
      0.742,
      21.738,
      15.2,
      0.742,
      21.738,
      12.2,
      -0.481,
      21.998,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_936",
    polygon: [
      49.227,
      -19.801,
      13.115,
      49.227,
      -19.801,
      14.88,
      47.521,
      -20.697,
      14.88,
      47.521,
      -20.697,
      14.88,
      47.521,
      -20.697,
      13.115,
      49.227,
      -19.801,
      13.115,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_987",
    polygon: [
      3.958,
      -23.796,
      13.095,
      3.958,
      -23.796,
      15.195,
      5.384,
      -23.329,
      15.195,
      5.384,
      -23.329,
      15.195,
      5.384,
      -23.329,
      13.095,
      3.958,
      -23.796,
      13.095,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_988",
    polygon: [
      1.582,
      -24.573,
      13.095,
      1.582,
      -24.573,
      15.195,
      3.008,
      -24.107,
      15.195,
      3.008,
      -24.107,
      15.195,
      3.008,
      -24.107,
      13.095,
      1.582,
      -24.573,
      13.095,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_935",
    polygon: [
      46.436,
      -21.266,
      13.115,
      46.436,
      -21.266,
      14.88,
      44.729,
      -22.162,
      14.88,
      44.729,
      -22.162,
      14.88,
      44.729,
      -22.162,
      13.115,
      46.436,
      -21.266,
      13.115,
    ],
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_694",
    polygon: [
      29.882,
      -29.932,
      5.695,
      29.882,
      -29.932,
      7.73,
      28.723,
      -30.416,
      7.73,
      28.723,
      -30.416,
      7.73,
      28.723,
      -30.416,
      5.695,
      29.882,
      -29.932,
      5.695,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_842",
    polygon: [
      -1.756,
      22.269,
      12.2,
      -1.756,
      22.269,
      15.2,
      -0.481,
      21.998,
      15.2,
      -0.481,
      21.998,
      15.2,
      -0.481,
      21.998,
      12.2,
      -1.756,
      22.269,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_922",
    polygon: [
      39.121,
      -24.245,
      13.513,
      39.121,
      -24.245,
      15.389,
      38.176,
      -24.704,
      15.389,
      38.176,
      -24.704,
      15.389,
      38.176,
      -24.704,
      13.513,
      39.121,
      -24.245,
      13.513,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_1013",
    polygon: [
      41.217,
      -8.94,
      13.085,
      41.217,
      -8.94,
      14.835,
      42.616,
      -8.206,
      14.835,
      42.616,
      -8.206,
      14.835,
      42.616,
      -8.206,
      13.085,
      41.217,
      -8.94,
      13.085,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_948",
    polygon: [
      46.465,
      -21.251,
      7.55,
      46.465,
      -21.251,
      9.515,
      44.712,
      -22.171,
      9.515,
      44.712,
      -22.171,
      9.515,
      44.712,
      -22.171,
      7.55,
      46.465,
      -21.251,
      7.55,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_753",
    polygon: [
      7.508,
      -38.165,
      17.422,
      7.508,
      -38.165,
      19.457,
      6.31,
      -38.542,
      19.457,
      6.31,
      -38.542,
      19.457,
      6.31,
      -38.542,
      17.422,
      7.508,
      -38.165,
      17.422,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_1000",
    polygon: [
      34.316,
      -27.722,
      13.513,
      34.316,
      -27.722,
      15.548,
      34.449,
      -28.023,
      15.548,
      34.449,
      -28.023,
      15.548,
      34.449,
      -28.023,
      13.513,
      34.316,
      -27.722,
      13.513,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_838",
    polygon: [
      0.742,
      21.738,
      12.2,
      0.742,
      21.738,
      15.2,
      1.964,
      21.478,
      15.2,
      1.964,
      21.478,
      15.2,
      1.964,
      21.478,
      12.2,
      0.742,
      21.738,
      12.2,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_561",
    polygon: [
      1.923,
      11.887,
      19.7,
      1.923,
      11.887,
      22.52,
      1.68,
      13.114,
      22.52,
      1.68,
      13.114,
      22.52,
      1.68,
      13.114,
      19.7,
      1.923,
      11.887,
      19.7,
    ],
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_754",
    polygon: [
      8.706,
      -37.788,
      17.422,
      8.706,
      -37.788,
      19.457,
      7.508,
      -38.165,
      19.457,
      7.508,
      -38.165,
      19.457,
      7.508,
      -38.165,
      17.422,
      8.706,
      -37.788,
      17.422,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_989",
    polygon: [
      3.958,
      -23.796,
      9.345,
      3.958,
      -23.796,
      11.445,
      5.384,
      -23.329,
      11.445,
      5.384,
      -23.329,
      11.445,
      5.384,
      -23.329,
      9.345,
      3.958,
      -23.796,
      9.345,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_652",
    polygon: [
      29.658,
      -29.412,
      -0.25,
      29.658,
      -29.412,
      2.875,
      28.5,
      -29.896,
      2.875,
      28.5,
      -29.896,
      2.875,
      28.5,
      -29.896,
      -0.25,
      29.658,
      -29.412,
      -0.25,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_763",
    polygon: [
      22.908,
      -32.783,
      17.422,
      22.908,
      -32.783,
      19.457,
      21.739,
      -33.24,
      19.457,
      21.739,
      -33.24,
      19.457,
      21.739,
      -33.24,
      17.422,
      22.908,
      -32.783,
      17.422,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_757",
    polygon: [
      13.477,
      -36.228,
      17.422,
      13.477,
      -36.228,
      19.457,
      12.289,
      -36.631,
      19.457,
      12.289,
      -36.631,
      19.457,
      12.289,
      -36.631,
      17.422,
      13.477,
      -36.228,
      17.422,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_760",
    polygon: [
      18.213,
      -34.559,
      17.422,
      18.213,
      -34.559,
      19.457,
      17.033,
      -34.99,
      19.457,
      17.033,
      -34.99,
      19.457,
      17.033,
      -34.99,
      17.422,
      18.213,
      -34.559,
      17.422,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_761",
    polygon: [
      19.393,
      -34.128,
      17.422,
      19.393,
      -34.128,
      19.457,
      18.213,
      -34.559,
      19.457,
      18.213,
      -34.559,
      19.457,
      18.213,
      -34.559,
      17.422,
      19.393,
      -34.128,
      17.422,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_758",
    polygon: [
      14.667,
      -35.823,
      17.422,
      14.667,
      -35.823,
      19.457,
      13.478,
      -36.227,
      19.457,
      13.478,
      -36.227,
      19.457,
      13.478,
      -36.227,
      17.422,
      14.667,
      -35.823,
      17.422,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_755",
    polygon: [
      9.904,
      -37.411,
      17.422,
      9.904,
      -37.411,
      19.457,
      8.706,
      -37.788,
      19.457,
      8.706,
      -37.788,
      19.457,
      8.706,
      -37.788,
      17.422,
      9.904,
      -37.411,
      17.422,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_1014",
    polygon: [
      44.785,
      -7.067,
      13.085,
      44.785,
      -7.067,
      14.835,
      46.184,
      -6.333,
      14.835,
      46.184,
      -6.333,
      14.835,
      46.184,
      -6.333,
      13.085,
      44.785,
      -7.067,
      13.085,
    ],
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_848",
    polygon: [
      11.009,
      17.206,
      15.95,
      11.009,
      17.206,
      18.95,
      12.283,
      16.935,
      18.95,
      12.283,
      16.935,
      18.95,
      12.283,
      16.935,
      15.95,
      11.009,
      17.206,
      15.95,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_930",
    polygon: [
      41.009,
      -23.327,
      17.422,
      41.009,
      -23.327,
      19.298,
      40.065,
      -23.786,
      19.298,
      40.065,
      -23.786,
      19.298,
      40.065,
      -23.786,
      17.422,
      41.009,
      -23.327,
      17.422,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_764",
    polygon: [
      24.079,
      -32.325,
      17.422,
      24.079,
      -32.325,
      19.457,
      22.909,
      -32.783,
      19.457,
      22.909,
      -32.783,
      19.457,
      22.909,
      -32.783,
      17.422,
      24.079,
      -32.325,
      17.422,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_881",
    polygon: [
      10.61,
      14.914,
      19.7,
      10.61,
      14.914,
      22.535,
      10.992,
      14.833,
      22.535,
      10.992,
      14.833,
      22.535,
      10.992,
      14.833,
      19.7,
      10.61,
      14.914,
      19.7,
    ],
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_772",
    polygon: [
      11.097,
      -37.036,
      17.422,
      11.097,
      -37.036,
      19.457,
      9.899,
      -37.413,
      19.457,
      9.899,
      -37.413,
      19.457,
      9.899,
      -37.413,
      17.422,
      11.097,
      -37.036,
      17.422,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_770",
    polygon: [
      33.321,
      -28.403,
      17.422,
      33.321,
      -28.403,
      19.457,
      32.174,
      -28.913,
      19.457,
      32.174,
      -28.913,
      19.457,
      32.174,
      -28.913,
      17.422,
      33.321,
      -28.403,
      17.422,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_871",
    polygon: [
      -1.27,
      19.816,
      15.95,
      -1.27,
      19.816,
      18.95,
      0.004,
      19.545,
      18.95,
      0.004,
      19.545,
      18.95,
      0.004,
      19.545,
      15.95,
      -1.27,
      19.816,
      15.95,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_766",
    polygon: [
      27.563,
      -30.901,
      17.422,
      27.563,
      -30.901,
      19.457,
      26.404,
      -31.385,
      19.457,
      26.404,
      -31.385,
      19.457,
      26.404,
      -31.385,
      17.422,
      27.563,
      -30.901,
      17.422,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_925",
    polygon: [
      36.288,
      -25.622,
      17.422,
      36.288,
      -25.622,
      19.298,
      35.343,
      -26.081,
      19.298,
      35.343,
      -26.081,
      19.298,
      35.343,
      -26.081,
      17.422,
      36.288,
      -25.622,
      17.422,
    ],
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_769",
    polygon: [
      32.173,
      -28.913,
      17.422,
      32.173,
      -28.913,
      19.457,
      31.026,
      -29.423,
      19.457,
      31.026,
      -29.423,
      19.457,
      31.026,
      -29.423,
      17.422,
      32.173,
      -28.913,
      17.422,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_850",
    polygon: [
      9.786,
      17.466,
      15.95,
      9.786,
      17.466,
      18.95,
      11.009,
      17.206,
      18.95,
      11.009,
      17.206,
      18.95,
      11.009,
      17.206,
      15.95,
      9.786,
      17.466,
      15.95,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_938",
    polygon: [
      43.841,
      -22.679,
      16.865,
      43.841,
      -22.679,
      18.63,
      42.135,
      -23.574,
      18.63,
      42.135,
      -23.574,
      18.63,
      42.135,
      -23.574,
      16.865,
      43.841,
      -22.679,
      16.865,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_928",
    polygon: [
      39.121,
      -24.245,
      17.422,
      39.121,
      -24.245,
      19.298,
      38.176,
      -24.704,
      19.298,
      38.176,
      -24.704,
      19.298,
      38.176,
      -24.704,
      17.422,
      39.121,
      -24.245,
      17.422,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_767",
    polygon: [
      28.722,
      -30.416,
      17.422,
      28.722,
      -30.416,
      19.457,
      27.564,
      -30.9,
      19.457,
      27.564,
      -30.9,
      19.457,
      27.564,
      -30.9,
      17.422,
      28.722,
      -30.416,
      17.422,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_940",
    polygon: [
      49.248,
      -19.841,
      16.865,
      49.248,
      -19.841,
      18.63,
      47.542,
      -20.737,
      18.63,
      47.542,
      -20.737,
      18.63,
      47.542,
      -20.737,
      16.865,
      49.248,
      -19.841,
      16.865,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_867",
    polygon: [
      2.45,
      19.026,
      15.95,
      2.45,
      19.026,
      18.95,
      3.673,
      18.766,
      18.95,
      3.673,
      18.766,
      18.95,
      3.673,
      18.766,
      15.95,
      2.45,
      19.026,
      15.95,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_929",
    polygon: [
      40.065,
      -23.786,
      17.422,
      40.065,
      -23.786,
      19.298,
      39.121,
      -24.245,
      19.298,
      39.121,
      -24.245,
      19.298,
      39.121,
      -24.245,
      17.422,
      40.065,
      -23.786,
      17.422,
    ],
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_927",
    polygon: [
      38.176,
      -24.704,
      17.422,
      38.176,
      -24.704,
      19.298,
      37.232,
      -25.163,
      19.298,
      37.232,
      -25.163,
      19.298,
      37.232,
      -25.163,
      17.422,
      38.176,
      -24.704,
      17.422,
    ],
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_926",
    polygon: [
      37.232,
      -25.163,
      17.422,
      37.232,
      -25.163,
      19.298,
      36.288,
      -25.622,
      19.298,
      36.288,
      -25.622,
      19.298,
      36.288,
      -25.622,
      17.422,
      37.232,
      -25.163,
      17.422,
    ],
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_869",
    polygon: [
      3.673,
      18.766,
      15.95,
      3.673,
      18.766,
      18.95,
      4.895,
      18.506,
      18.95,
      4.895,
      18.506,
      18.95,
      4.895,
      18.506,
      15.95,
      3.673,
      18.766,
      15.95,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_855",
    polygon: [
      7.341,
      17.986,
      15.95,
      7.341,
      17.986,
      18.95,
      8.563,
      17.726,
      18.95,
      8.563,
      17.726,
      18.95,
      8.563,
      17.726,
      15.95,
      7.341,
      17.986,
      15.95,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_865",
    polygon: [
      1.227,
      19.285,
      15.95,
      1.227,
      19.285,
      18.95,
      2.45,
      19.026,
      18.95,
      2.45,
      19.026,
      18.95,
      2.45,
      19.026,
      15.95,
      1.227,
      19.285,
      15.95,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_939",
    polygon: [
      46.456,
      -21.306,
      16.865,
      46.456,
      -21.306,
      18.63,
      44.75,
      -22.201,
      18.63,
      44.75,
      -22.201,
      18.63,
      44.75,
      -22.201,
      16.865,
      46.456,
      -21.306,
      16.865,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_859",
    polygon: [
      4.895,
      18.506,
      15.95,
      4.895,
      18.506,
      18.95,
      6.118,
      18.246,
      18.95,
      6.118,
      18.246,
      18.95,
      6.118,
      18.246,
      15.95,
      4.895,
      18.506,
      15.95,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_966",
    polygon: [
      32.627,
      -13.517,
      16.835,
      32.627,
      -13.517,
      18.97,
      33.481,
      -13.102,
      18.97,
      33.481,
      -13.102,
      18.97,
      33.481,
      -13.102,
      16.835,
      32.627,
      -13.517,
      16.835,
    ],
    "05-01 17:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_857",
    polygon: [
      6.118,
      18.246,
      15.95,
      6.118,
      18.246,
      18.95,
      7.341,
      17.986,
      18.95,
      7.341,
      17.986,
      18.95,
      7.341,
      17.986,
      15.95,
      6.118,
      18.246,
      15.95,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_863",
    polygon: [
      0.004,
      19.545,
      15.95,
      0.004,
      19.545,
      18.95,
      1.227,
      19.285,
      18.95,
      1.227,
      19.285,
      18.95,
      1.227,
      19.285,
      15.95,
      0.004,
      19.545,
      15.95,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_853",
    polygon: [
      8.563,
      17.726,
      15.95,
      8.563,
      17.726,
      18.95,
      9.786,
      17.466,
      18.95,
      9.786,
      17.466,
      18.95,
      9.786,
      17.466,
      15.95,
      8.563,
      17.726,
      15.95,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_578",
    polygon: [
      15.367,
      -8.387,
      19.7,
      15.367,
      -8.387,
      20.62,
      15.61,
      -9.614,
      20.62,
      15.61,
      -9.614,
      20.62,
      15.61,
      -9.614,
      19.7,
      15.367,
      -8.387,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_986",
    polygon: [
      1.582,
      -24.573,
      16.845,
      1.582,
      -24.573,
      18.945,
      3.008,
      -24.107,
      18.945,
      3.008,
      -24.107,
      18.945,
      3.008,
      -24.107,
      16.845,
      1.582,
      -24.573,
      16.845,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_538",
    polygon: [
      6.05,
      -8.958,
      20.62,
      6.05,
      -8.958,
      22.52,
      5.807,
      -7.732,
      22.52,
      5.807,
      -7.732,
      22.52,
      5.807,
      -7.732,
      20.62,
      6.05,
      -8.958,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_985",
    polygon: [
      3.958,
      -23.796,
      16.845,
      3.958,
      -23.796,
      18.945,
      5.384,
      -23.329,
      18.945,
      5.384,
      -23.329,
      18.945,
      5.384,
      -23.329,
      16.845,
      3.958,
      -23.796,
      16.845,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_536",
    polygon: [
      5.807,
      -7.732,
      20.62,
      5.807,
      -7.732,
      22.52,
      5.565,
      -6.506,
      22.52,
      5.565,
      -6.506,
      22.52,
      5.565,
      -6.506,
      20.62,
      5.807,
      -7.732,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_1011",
    polygon: [
      35.675,
      -10.728,
      16.835,
      35.675,
      -10.728,
      18.585,
      37.446,
      -9.8,
      18.585,
      37.446,
      -9.8,
      18.585,
      37.446,
      -9.8,
      16.835,
      35.675,
      -10.728,
      16.835,
    ],
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_546",
    polygon: [
      7.021,
      -13.863,
      19.7,
      7.021,
      -13.863,
      22.52,
      6.779,
      -12.637,
      22.52,
      6.779,
      -12.637,
      22.52,
      6.779,
      -12.637,
      19.7,
      7.021,
      -13.863,
      19.7,
    ],
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_1001",
    polygon: [
      34.316,
      -27.722,
      17.422,
      34.316,
      -27.722,
      19.457,
      34.449,
      -28.023,
      19.457,
      34.449,
      -28.023,
      19.457,
      34.449,
      -28.023,
      17.422,
      34.316,
      -27.722,
      17.422,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_537",
    polygon: [
      6.05,
      -8.958,
      19.7,
      6.05,
      -8.958,
      20.62,
      5.807,
      -7.732,
      20.62,
      5.807,
      -7.732,
      20.62,
      5.807,
      -7.732,
      19.7,
      6.05,
      -8.958,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_941",
    polygon: [
      52.036,
      -18.378,
      16.865,
      52.036,
      -18.378,
      18.63,
      50.33,
      -19.274,
      18.63,
      50.33,
      -19.274,
      18.63,
      50.33,
      -19.274,
      16.865,
      52.036,
      -18.378,
      16.865,
    ],
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_967",
    polygon: [
      33.481,
      -13.102,
      16.835,
      33.481,
      -13.102,
      18.97,
      34.336,
      -12.687,
      18.97,
      34.336,
      -12.687,
      18.97,
      34.336,
      -12.687,
      16.835,
      33.481,
      -13.102,
      16.835,
    ],
    "05-01 16:45": 1,
    "05-01 17:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_1017",
    polygon: [
      44.764,
      -7.027,
      16.835,
      44.764,
      -7.027,
      18.585,
      46.163,
      -6.293,
      18.585,
      46.163,
      -6.293,
      18.585,
      46.163,
      -6.293,
      16.835,
      44.764,
      -7.027,
      16.835,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_522",
    polygon: [
      19.323,
      -15.495,
      20.615,
      19.323,
      -15.495,
      22.675,
      19.657,
      -17.182,
      22.675,
      19.657,
      -17.182,
      22.675,
      19.657,
      -17.182,
      20.615,
      19.323,
      -15.495,
      20.615,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_968",
    polygon: [
      34.336,
      -12.687,
      16.835,
      34.336,
      -12.687,
      18.97,
      35.235,
      -12.249,
      18.97,
      35.235,
      -12.249,
      18.97,
      35.235,
      -12.249,
      16.835,
      34.336,
      -12.687,
      16.835,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_4- OG_1016",
    polygon: [
      41.196,
      -8.9,
      16.835,
      41.196,
      -8.9,
      18.585,
      42.595,
      -8.166,
      18.585,
      42.595,
      -8.166,
      18.585,
      42.595,
      -8.166,
      16.835,
      41.196,
      -8.9,
      16.835,
    ],
    "05-01 18:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_529",
    polygon: [
      5.079,
      -4.053,
      19.7,
      5.079,
      -4.053,
      20.62,
      4.836,
      -2.827,
      20.62,
      4.836,
      -2.827,
      20.62,
      4.836,
      -2.827,
      19.7,
      5.079,
      -4.053,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_543",
    polygon: [
      6.779,
      -12.637,
      19.7,
      6.779,
      -12.637,
      20.62,
      6.536,
      -11.411,
      20.62,
      6.536,
      -11.411,
      20.62,
      6.536,
      -11.411,
      19.7,
      6.779,
      -12.637,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_553",
    polygon: [
      7.993,
      -18.768,
      19.7,
      7.993,
      -18.768,
      20.62,
      7.75,
      -17.541,
      20.62,
      7.75,
      -17.541,
      20.62,
      7.75,
      -17.541,
      19.7,
      7.993,
      -18.768,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_530",
    polygon: [
      5.079,
      -4.053,
      20.62,
      5.079,
      -4.053,
      22.52,
      4.836,
      -2.827,
      22.52,
      4.836,
      -2.827,
      22.52,
      4.836,
      -2.827,
      20.62,
      5.079,
      -4.053,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_570",
    polygon: [
      1.194,
      15.566,
      20.62,
      1.194,
      15.566,
      22.52,
      0.952,
      16.792,
      22.52,
      0.952,
      16.792,
      22.52,
      0.952,
      16.792,
      20.62,
      1.194,
      15.566,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_551",
    polygon: [
      7.75,
      -17.541,
      19.7,
      7.75,
      -17.541,
      20.62,
      7.507,
      -16.315,
      20.62,
      7.507,
      -16.315,
      20.62,
      7.507,
      -16.315,
      19.7,
      7.75,
      -17.541,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_587",
    polygon: [
      14.153,
      -2.256,
      20.62,
      14.153,
      -2.256,
      22.52,
      14.396,
      -3.483,
      22.52,
      14.396,
      -3.483,
      22.52,
      14.396,
      -3.483,
      20.62,
      14.153,
      -2.256,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_577",
    polygon: [
      15.367,
      -8.387,
      20.62,
      15.367,
      -8.387,
      22.52,
      15.61,
      -9.614,
      22.52,
      15.61,
      -9.614,
      22.52,
      15.61,
      -9.614,
      20.62,
      15.367,
      -8.387,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_552",
    polygon: [
      7.75,
      -17.541,
      20.62,
      7.75,
      -17.541,
      22.52,
      7.507,
      -16.315,
      22.52,
      7.507,
      -16.315,
      22.52,
      7.507,
      -16.315,
      20.62,
      7.75,
      -17.541,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_592",
    polygon: [
      13.667,
      0.196,
      19.7,
      13.667,
      0.196,
      20.62,
      13.91,
      -1.03,
      20.62,
      13.91,
      -1.03,
      20.62,
      13.91,
      -1.03,
      19.7,
      13.667,
      0.196,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_586",
    polygon: [
      14.396,
      -3.483,
      19.7,
      14.396,
      -3.483,
      22.52,
      14.638,
      -4.709,
      22.52,
      14.638,
      -4.709,
      22.52,
      14.638,
      -4.709,
      19.7,
      14.396,
      -3.483,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_558",
    polygon: [
      2.176,
      10.609,
      19.7,
      2.176,
      10.609,
      20.62,
      1.923,
      11.887,
      20.62,
      1.923,
      11.887,
      20.62,
      1.923,
      11.887,
      19.7,
      2.176,
      10.609,
      19.7,
    ],
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_584",
    polygon: [
      14.638,
      -4.709,
      19.7,
      14.638,
      -4.709,
      20.62,
      14.881,
      -5.935,
      20.62,
      14.881,
      -5.935,
      20.62,
      14.881,
      -5.935,
      19.7,
      14.638,
      -4.709,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_544",
    polygon: [
      6.779,
      -12.637,
      20.62,
      6.779,
      -12.637,
      22.52,
      6.536,
      -11.411,
      22.52,
      6.536,
      -11.411,
      22.52,
      6.536,
      -11.411,
      20.62,
      6.779,
      -12.637,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_528",
    polygon: [
      4.836,
      -2.827,
      20.62,
      4.836,
      -2.827,
      22.52,
      4.593,
      -1.601,
      22.52,
      4.593,
      -1.601,
      22.52,
      4.593,
      -1.601,
      20.62,
      4.836,
      -2.827,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_560",
    polygon: [
      2.176,
      10.609,
      20.62,
      2.176,
      10.609,
      22.52,
      1.923,
      11.887,
      22.52,
      1.923,
      11.887,
      22.52,
      1.923,
      11.887,
      20.62,
      2.176,
      10.609,
      20.62,
    ],
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_579",
    polygon: [
      15.124,
      -7.161,
      20.62,
      15.124,
      -7.161,
      22.52,
      15.367,
      -8.387,
      22.52,
      15.367,
      -8.387,
      22.52,
      15.367,
      -8.387,
      20.62,
      15.124,
      -7.161,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_593",
    polygon: [
      13.424,
      1.422,
      20.62,
      13.424,
      1.422,
      22.52,
      13.667,
      0.196,
      22.52,
      13.667,
      0.196,
      22.52,
      13.667,
      0.196,
      20.62,
      13.424,
      1.422,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_594",
    polygon: [
      13.424,
      1.422,
      19.7,
      13.424,
      1.422,
      20.62,
      13.667,
      0.196,
      20.62,
      13.667,
      0.196,
      20.62,
      13.667,
      0.196,
      19.7,
      13.424,
      1.422,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_603",
    polygon: [
      12.211,
      7.553,
      20.62,
      12.211,
      7.553,
      22.52,
      12.453,
      6.327,
      22.52,
      12.453,
      6.327,
      22.52,
      12.453,
      6.327,
      20.62,
      12.211,
      7.553,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_568",
    polygon: [
      0.952,
      16.792,
      20.62,
      0.952,
      16.792,
      22.52,
      0.819,
      17.462,
      22.52,
      0.819,
      17.462,
      22.52,
      0.819,
      17.462,
      20.62,
      0.952,
      16.792,
      20.62,
    ],
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_605",
    polygon: [
      11.968,
      8.779,
      20.62,
      11.968,
      8.779,
      22.52,
      12.211,
      7.553,
      22.52,
      12.211,
      7.553,
      22.52,
      12.211,
      7.553,
      20.62,
      11.968,
      8.779,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_613",
    polygon: [
      10.997,
      13.684,
      20.62,
      10.997,
      13.684,
      22.52,
      11.239,
      12.458,
      22.52,
      11.239,
      12.458,
      22.52,
      11.239,
      12.458,
      20.62,
      10.997,
      13.684,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_598",
    polygon: [
      12.939,
      3.875,
      19.7,
      12.939,
      3.875,
      22.52,
      13.182,
      2.648,
      22.52,
      13.182,
      2.648,
      22.52,
      13.182,
      2.648,
      19.7,
      12.939,
      3.875,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_595",
    polygon: [
      13.182,
      2.648,
      20.62,
      13.182,
      2.648,
      22.52,
      13.424,
      1.422,
      22.52,
      13.424,
      1.422,
      22.52,
      13.424,
      1.422,
      20.62,
      13.182,
      2.648,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_606",
    polygon: [
      11.968,
      8.779,
      19.7,
      11.968,
      8.779,
      20.62,
      12.211,
      7.553,
      20.62,
      12.211,
      7.553,
      20.62,
      12.211,
      7.553,
      19.7,
      11.968,
      8.779,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_873",
    polygon: [
      9.388,
      15.174,
      19.7,
      9.388,
      15.174,
      22.535,
      10.61,
      14.914,
      22.535,
      10.61,
      14.914,
      22.535,
      10.61,
      14.914,
      19.7,
      9.388,
      15.174,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_608",
    polygon: [
      11.725,
      10.005,
      19.7,
      11.725,
      10.005,
      20.62,
      11.968,
      8.779,
      20.62,
      11.968,
      8.779,
      20.62,
      11.968,
      8.779,
      19.7,
      11.725,
      10.005,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_600",
    polygon: [
      12.696,
      5.101,
      19.7,
      12.696,
      5.101,
      20.62,
      12.939,
      3.875,
      20.62,
      12.939,
      3.875,
      20.62,
      12.939,
      3.875,
      19.7,
      12.696,
      5.101,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_604",
    polygon: [
      12.211,
      7.553,
      19.7,
      12.211,
      7.553,
      20.62,
      12.453,
      6.327,
      20.62,
      12.453,
      6.327,
      20.62,
      12.453,
      6.327,
      19.7,
      12.211,
      7.553,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_607",
    polygon: [
      11.725,
      10.005,
      20.62,
      11.725,
      10.005,
      22.52,
      11.968,
      8.779,
      22.52,
      11.968,
      8.779,
      22.52,
      11.968,
      8.779,
      20.62,
      11.725,
      10.005,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_602",
    polygon: [
      12.453,
      6.327,
      19.7,
      12.453,
      6.327,
      20.62,
      12.696,
      5.101,
      20.62,
      12.696,
      5.101,
      20.62,
      12.696,
      5.101,
      19.7,
      12.453,
      6.327,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_879",
    polygon: [
      3.274,
      16.474,
      19.7,
      3.274,
      16.474,
      22.535,
      4.497,
      16.214,
      22.535,
      4.497,
      16.214,
      22.535,
      4.497,
      16.214,
      19.7,
      3.274,
      16.474,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_601",
    polygon: [
      12.453,
      6.327,
      20.62,
      12.453,
      6.327,
      22.52,
      12.696,
      5.101,
      22.52,
      12.696,
      5.101,
      22.52,
      12.696,
      5.101,
      20.62,
      12.453,
      6.327,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_596",
    polygon: [
      13.182,
      2.648,
      19.7,
      13.182,
      2.648,
      20.62,
      13.424,
      1.422,
      20.62,
      13.424,
      1.422,
      20.62,
      13.424,
      1.422,
      19.7,
      13.182,
      2.648,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_610",
    polygon: [
      11.482,
      11.232,
      19.7,
      11.482,
      11.232,
      22.52,
      11.725,
      10.005,
      22.52,
      11.725,
      10.005,
      22.52,
      11.725,
      10.005,
      19.7,
      11.482,
      11.232,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_611",
    polygon: [
      11.239,
      12.458,
      20.62,
      11.239,
      12.458,
      22.52,
      11.482,
      11.232,
      22.52,
      11.482,
      11.232,
      22.52,
      11.482,
      11.232,
      20.62,
      11.239,
      12.458,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_567",
    polygon: [
      1.194,
      15.566,
      19.7,
      1.194,
      15.566,
      20.62,
      0.952,
      16.792,
      20.62,
      0.952,
      16.792,
      20.62,
      0.952,
      16.792,
      19.7,
      1.194,
      15.566,
      19.7,
    ],
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
    "05-01 16:15": 1,
    "05-01 16:30": 1,
    "05-01 16:45": 1,
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_599",
    polygon: [
      12.696,
      5.101,
      20.62,
      12.696,
      5.101,
      22.52,
      12.939,
      3.875,
      22.52,
      12.939,
      3.875,
      22.52,
      12.939,
      3.875,
      20.62,
      12.696,
      5.101,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_878",
    polygon: [
      2.051,
      16.733,
      19.7,
      2.051,
      16.733,
      22.535,
      3.274,
      16.474,
      22.535,
      3.274,
      16.474,
      22.535,
      3.274,
      16.474,
      19.7,
      2.051,
      16.733,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_615",
    polygon: [
      10.754,
      14.91,
      20.62,
      10.754,
      14.91,
      22.52,
      10.997,
      13.684,
      22.52,
      10.997,
      13.684,
      22.52,
      10.997,
      13.684,
      20.62,
      10.754,
      14.91,
      20.62,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_982",
    polygon: [
      24.427,
      -16.31,
      21.465,
      24.427,
      -16.31,
      23.365,
      25.878,
      -15.685,
      23.365,
      25.878,
      -15.685,
      23.365,
      25.878,
      -15.685,
      21.465,
      24.427,
      -16.31,
      21.465,
    ],
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_612",
    polygon: [
      11.239,
      12.458,
      19.7,
      11.239,
      12.458,
      20.62,
      11.482,
      11.232,
      20.62,
      11.482,
      11.232,
      20.62,
      11.482,
      11.232,
      19.7,
      11.239,
      12.458,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_995",
    polygon: [
      3.946,
      -23.75,
      20.595,
      3.946,
      -23.75,
      22.695,
      5.364,
      -23.261,
      22.695,
      5.364,
      -23.261,
      22.695,
      5.364,
      -23.261,
      20.595,
      3.946,
      -23.75,
      20.595,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_875",
    polygon: [
      6.942,
      15.694,
      19.7,
      6.942,
      15.694,
      22.535,
      8.165,
      15.434,
      22.535,
      8.165,
      15.434,
      22.535,
      8.165,
      15.434,
      19.7,
      6.942,
      15.694,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_874",
    polygon: [
      8.165,
      15.434,
      19.7,
      8.165,
      15.434,
      22.535,
      9.388,
      15.174,
      22.535,
      9.388,
      15.174,
      22.535,
      9.388,
      15.174,
      19.7,
      8.165,
      15.434,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_614",
    polygon: [
      10.997,
      13.684,
      19.7,
      10.997,
      13.684,
      20.62,
      11.239,
      12.458,
      20.62,
      11.239,
      12.458,
      20.62,
      11.239,
      12.458,
      19.7,
      10.997,
      13.684,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_616",
    polygon: [
      10.754,
      14.91,
      19.7,
      10.754,
      14.91,
      20.62,
      10.997,
      13.684,
      20.62,
      10.997,
      13.684,
      20.62,
      10.997,
      13.684,
      19.7,
      10.754,
      14.91,
      19.7,
    ],
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_880",
    polygon: [
      4.497,
      16.214,
      19.7,
      4.497,
      16.214,
      22.535,
      5.719,
      15.954,
      22.535,
      5.719,
      15.954,
      22.535,
      5.719,
      15.954,
      19.7,
      4.497,
      16.214,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_876",
    polygon: [
      5.719,
      15.954,
      19.7,
      5.719,
      15.954,
      22.535,
      6.942,
      15.694,
      22.535,
      6.942,
      15.694,
      22.535,
      6.942,
      15.694,
      19.7,
      5.719,
      15.954,
      19.7,
    ],
    "05-01 05:00": 1,
    "05-01 05:15": 1,
    "05-01 05:30": 1,
    "05-01 05:45": 1,
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_996",
    polygon: [
      1.582,
      -24.565,
      20.595,
      1.582,
      -24.565,
      22.695,
      3.0,
      -24.076,
      22.695,
      3.0,
      -24.076,
      22.695,
      3.0,
      -24.076,
      20.595,
      1.582,
      -24.565,
      20.595,
    ],
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
    "05-01 19:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_5- OG_983",
    polygon: [
      26.723,
      -15.32,
      21.465,
      26.723,
      -15.32,
      23.365,
      28.174,
      -14.695,
      23.365,
      28.174,
      -14.695,
      23.365,
      28.174,
      -14.695,
      21.465,
      26.723,
      -15.32,
      21.465,
    ],
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_372",
    polygon: [
      -15.472,
      -8.377,
      13.076,
      -15.472,
      -8.377,
      15.221,
      -16.699,
      -8.62,
      15.221,
      -16.699,
      -8.62,
      15.221,
      -16.699,
      -8.62,
      13.076,
      -15.472,
      -8.377,
      13.076,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_385",
    polygon: [
      0.468,
      -5.221,
      13.076,
      0.468,
      -5.221,
      15.221,
      -0.758,
      -5.464,
      15.221,
      -0.758,
      -5.464,
      15.221,
      -0.758,
      -5.464,
      13.076,
      0.468,
      -5.221,
      13.076,
    ],
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_373",
    polygon: [
      -14.246,
      -8.135,
      13.076,
      -14.246,
      -8.135,
      15.221,
      -15.472,
      -8.377,
      15.221,
      -15.472,
      -8.377,
      15.221,
      -15.472,
      -8.377,
      13.076,
      -14.246,
      -8.135,
      13.076,
    ],
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_514",
    polygon: [
      -12.109,
      6.815,
      13.076,
      -12.109,
      6.815,
      15.221,
      -10.883,
      7.058,
      15.221,
      -10.883,
      7.058,
      15.221,
      -10.883,
      7.058,
      13.076,
      -12.109,
      6.815,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_512",
    polygon: [
      -10.883,
      7.058,
      13.076,
      -10.883,
      7.058,
      15.221,
      -9.657,
      7.301,
      15.221,
      -9.657,
      7.301,
      15.221,
      -9.657,
      7.301,
      13.076,
      -10.883,
      7.058,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_999",
    polygon: [
      34.316,
      -27.722,
      9.604,
      34.316,
      -27.722,
      11.639,
      34.449,
      -28.023,
      11.639,
      34.449,
      -28.023,
      11.639,
      34.449,
      -28.023,
      9.604,
      34.316,
      -27.722,
      9.604,
    ],
    "05-01 06:00": 1,
    "05-01 06:15": 1,
    "05-01 06:30": 1,
    "05-01 06:45": 1,
    "05-01 07:00": 1,
    "05-01 07:15": 1,
    "05-01 07:30": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- OG_696",
    polygon: [
      32.173,
      -28.913,
      5.695,
      32.173,
      -28.913,
      7.73,
      31.026,
      -29.423,
      7.73,
      31.026,
      -29.423,
      7.73,
      31.026,
      -29.423,
      5.695,
      32.173,
      -28.913,
      5.695,
    ],
    "05-01 07:15": 1,
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_380",
    polygon: [
      -5.663,
      -6.435,
      13.076,
      -5.663,
      -6.435,
      15.221,
      -6.889,
      -6.678,
      15.221,
      -6.889,
      -6.678,
      15.221,
      -6.889,
      -6.678,
      13.076,
      -5.663,
      -6.435,
      13.076,
    ],
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_338",
    polygon: [
      -8.115,
      -6.921,
      9.326,
      -8.115,
      -6.921,
      11.471,
      -9.341,
      -7.163,
      11.471,
      -9.341,
      -7.163,
      11.471,
      -9.341,
      -7.163,
      9.326,
      -8.115,
      -6.921,
      9.326,
    ],
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_507",
    polygon: [
      -17.014,
      5.844,
      13.076,
      -17.014,
      5.844,
      15.221,
      -15.788,
      6.087,
      15.221,
      -15.788,
      6.087,
      15.221,
      -15.788,
      6.087,
      13.076,
      -17.014,
      5.844,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_344",
    polygon: [
      -0.758,
      -5.464,
      9.326,
      -0.758,
      -5.464,
      11.471,
      -1.984,
      -5.707,
      11.471,
      -1.984,
      -5.707,
      11.471,
      -1.984,
      -5.707,
      9.326,
      -0.758,
      -5.464,
      9.326,
    ],
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_1- UG_650",
    polygon: [
      25.036,
      -31.343,
      -0.25,
      25.036,
      -31.343,
      2.875,
      23.867,
      -31.8,
      2.875,
      23.867,
      -31.8,
      2.875,
      23.867,
      -31.8,
      -0.25,
      25.036,
      -31.343,
      -0.25,
    ],
    "05-01 07:30": 1,
    "05-01 07:45": 1,
    "05-01 08:00": 1,
    "05-01 08:15": 1,
    "05-01 08:30": 1,
    "05-01 08:45": 1,
    "05-01 09:00": 1,
    "05-01 09:15": 1,
    "05-01 09:30": 1,
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_491",
    polygon: [
      -19.466,
      5.358,
      9.326,
      -19.466,
      5.358,
      11.471,
      -18.24,
      5.601,
      11.471,
      -18.24,
      5.601,
      11.471,
      -18.24,
      5.601,
      9.326,
      -19.466,
      5.358,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_339",
    polygon: [
      -6.889,
      -6.678,
      9.326,
      -6.889,
      -6.678,
      11.471,
      -8.115,
      -6.921,
      11.471,
      -8.115,
      -6.921,
      11.471,
      -8.115,
      -6.921,
      9.326,
      -6.889,
      -6.678,
      9.326,
    ],
    "05-01 09:45": 1,
    "05-01 10:00": 1,
    "05-01 10:15": 1,
    "05-01 10:30": 1,
    "05-01 10:45": 1,
    "05-01 11:00": 1,
    "05-01 11:15": 1,
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_346",
    polygon: [
      1.694,
      -4.978,
      9.326,
      1.694,
      -4.978,
      11.471,
      0.468,
      -5.221,
      11.471,
      0.468,
      -5.221,
      11.471,
      0.468,
      -5.221,
      9.326,
      1.694,
      -4.978,
      9.326,
    ],
    "05-01 11:30": 1,
    "05-01 11:45": 1,
    "05-01 12:00": 1,
    "05-01 12:15": 1,
    "05-01 12:30": 1,
    "05-01 12:45": 1,
    "05-01 13:00": 1,
    "05-01 13:15": 1,
    "05-01 13:30": 1,
    "05-01 13:45": 1,
    "05-01 14:00": 1,
    "05-01 14:15": 1,
    "05-01 14:30": 1,
    "05-01 14:45": 1,
    "05-01 15:00": 1,
    "05-01 15:15": 1,
    "05-01 15:30": 1,
    "05-01 15:45": 1,
    "05-01 16:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_2- OG_486",
    polygon: [
      -18.24,
      5.601,
      9.326,
      -18.24,
      5.601,
      11.471,
      -17.014,
      5.844,
      11.471,
      -17.014,
      5.844,
      11.471,
      -17.014,
      5.844,
      9.326,
      -18.24,
      5.601,
      9.326,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_520",
    polygon: [
      -4.752,
      8.272,
      13.076,
      -4.752,
      8.272,
      15.221,
      -3.526,
      8.515,
      15.221,
      -3.526,
      8.515,
      15.221,
      -3.526,
      8.515,
      13.076,
      -4.752,
      8.272,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_506",
    polygon: [
      -18.24,
      5.601,
      13.076,
      -18.24,
      5.601,
      15.221,
      -17.014,
      5.844,
      15.221,
      -17.014,
      5.844,
      15.221,
      -17.014,
      5.844,
      13.076,
      -18.24,
      5.601,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
  {
    ID: "Sprinkenhof_MF_00_3- OG_519",
    polygon: [
      -2.3,
      8.757,
      13.076,
      -2.3,
      8.757,
      15.221,
      -1.074,
      9.0,
      15.221,
      -1.074,
      9.0,
      15.221,
      -1.074,
      9.0,
      13.076,
      -2.3,
      8.757,
      13.076,
    ],
    "05-01 17:00": 1,
    "05-01 17:15": 1,
    "05-01 17:30": 1,
    "05-01 17:45": 1,
    "05-01 18:00": 1,
    "05-01 18:15": 1,
    "05-01 18:30": 1,
    "05-01 18:45": 1,
    "05-01 19:00": 1,
    "05-01 19:15": 1,
  },
];

export default project50ShadingProgression;
