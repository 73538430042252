import React from "react";
import { useDispatch } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { toggleInfoBpx } from "../../redux/infoBoxGeneral";
import { updateFilter } from "../../redux/sceneGlobal";
import {
  setZone,
  toggleWindRisk
} from "../../redux/shadingSystem";
import "../NavBar.css";


const DropDownShading = (props) => {
  const dispatch = useDispatch();
  var inputData = props.inputData;

  return (
    <Dropdown item simple text={props.title} className="drop-down">
      <Dropdown.Menu className="kkk">
        {inputData.map(({ id, title, zone }) => (
          <Dropdown.Item
            onClick={() => {
              dispatch(toggleWindRisk(id));
              dispatch(
                updateFilter([
                  `${props.parentTitle} >> ${props.title} >> ${title}`,
                ])
              );
              if (props.title === "Sensor position") {
                dispatch(setZone([zone]));
              }
            }}
          >
            <Checkbox
              checked={props.status.checkStatus[id]}
              label={title}
              key={id}
            />
            <button
              style={{ background: "none", border: "none" }}
              onClick={() => {
                dispatch(toggleInfoBpx([title]));
              }}
            >
              <i
                class="info circle icon"
                style={{ marginLeft: "5px", color: "blue" }}
              ></i>
            </button>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropDownShading;
