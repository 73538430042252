import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateAxisValues, generateNumbers } from "../utils";
import ColorBarComponent, { CustomColorBar } from "./ColorBarComponent";
import "./colorBar.css";
import { setColorbar } from "../../redux/colorbars";
import ColorbarSetRange from "./CustomizeColorbar";
import { useDaylightFactorColorBar } from "../CustomHooks";

const ColorBarDaylightFactorOld = () => {
  var daylightSelector = useSelector((state) => state.daylightFactor.value);

  const currentValue = daylightSelector?.parentName;
  var minVal = 0;

  const colorbars = useSelector((state) => state.colorbars["Daylight Factor"]);
  const { min, max, step } = colorbars[currentValue] || {
    min: undefined,
    max: undefined,
    step: undefined,
  };
  var maxVal = 100;
  let generatedNumbers;
  generatedNumbers = generateAxisValues(min || minVal, max || maxVal, step);

  const isVisible =
    (daylightSelector?.parentName !== "") &
    (daylightSelector?.childName !== "");
  if (!isVisible) {
    return null;
  }
  return (
    generatedNumbers?.length > 0 && (
      <>
        <ColorbarSetRange
          category="Daylight Factor"
          subcategory={currentValue}
          defaultMax={100}
          defaultMin={0}
          colorbarMin={min}
          colorbarMax={max}
        />
        <ColorBarComponent
          generatedNumbers={generatedNumbers}
          min={generatedNumbers[0]}
          max={generatedNumbers[generatedNumbers.length - 1]}
          shiftUp={2}
          label="%"
          className="color-bar-daylight color-bar-common"
        />
      </>
    )
  );
};

const ColorBarDaylightFactor = () => {
  const { isVisible, subcategory } = useDaylightFactorColorBar();
  return (
    <CustomColorBar
      colorbarClassName="color-bar-daylight"
      category="Daylight Factor"
      subcategory={subcategory}
      unit="%"
      dataMin={0}
      dataMax={100}
      isVisible={isVisible}
    />
  );
};

export default ColorBarDaylightFactor;
