import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  default: { checkStatus: [false, false, false, false, false] },
  value: { checkStatus: [false, false, false, false, false] },
  firstWindIsShowing: false,
  selectedZone: null,
  zoneGroup: [false, false, false],
  selectedHours: "07-12 00:00",
  shadingDate: null,
  flag: false,
  windRiskIsLoading: false,
};

const shadingSystemSlice = createSlice({
  name: "shadingSystem",
  initialState,
  reducers: {
    setZone: (state, action) => {
      state.selectedZone = action.payload[0];
      state.zoneGroup[action.payload[0]] = !state.zoneGroup[action.payload[0]];
    },
    setShadingDate: (state, action) => {
      state.shadingDate = action.payload;
    },
    toggleWindRisk: (state, action) => {
      state.value.checkStatus[action.payload] = !state.value.checkStatus[
        action.payload
      ];
    },
    setFirstWindIsShowing: (state) => {
      state.firstWindIsShowing = true;
    },
    hide: (state) => {
      state.value = false;
    },
    setDefaultShading: (state) => {
      state.value = state.default;
    },
    resetSensorPlacement: (state) => {
      state.zoneGroup = [false, false, false, false];
      state.default.checkStatus = [false, false, false, false, false];
      state.value.checkStatus = [false, false, false, false, false];
    },
    setSelectedHours: (state, action) => {
      state.selectedHours = action.payload;
    },
    toggleFlag: (state, action) => {
      state.flag = !state.flag;
    },
    deselectSensorPosition: (state) => {
      state.value.checkStatus[3] = false;
      state.value.checkStatus[4] = false;
      state.zoneGroup = [false, false, false, false];
    },
    deselectWindRisk: (state) => {
      state.value.checkStatus[0] = false;
    },
    deselectShadingProgression: (state) => {
      state.value.checkStatus[2] = false;
    },
    setWindRiskIsLoading: (state, action) => {
      state.windRiskIsLoading = action.payload;
    },
  },
});

export const {
  toggleWindRisk,
  setFirstWindIsShowing,
  setDefaultShading,
  setZone,
  resetSensorPlacement,
  setSelectedHours,
  toggleFlag,
  deselectSensorPosition,
  deselectWindRisk,
  deselectShadingProgression,
  setWindRiskIsLoading,
  setShadingDate
} = shadingSystemSlice.actions;

export default shadingSystemSlice.reducer;
