const project1289ShadingProgression = [
  {
    ID: -1,
    polygon: "ANotExistingPolygon",
    "11-07 00:00": 0,
    "11-07 00:15": 0,
    "11-07 00:30": 0,
    "11-07 00:45": 0,
    "11-07 01:00": 0,
    "11-07 01:15": 0,
    "11-07 01:30": 0,
    "11-07 01:45": 0,
    "11-07 02:00": 0,
    "11-07 02:15": 0,
    "11-07 02:30": 0,
    "11-07 02:45": 0,
    "11-07 03:00": 0,
    "11-07 03:15": 0,
    "11-07 03:30": 0,
    "11-07 03:45": 0,
    "11-07 04:00": 0,
    "11-07 04:15": 0,
    "11-07 04:30": 0,
    "11-07 04:45": 0,
    "11-07 05:00": 0,
    "11-07 05:15": 0,
    "11-07 05:30": 0,
    "11-07 05:45": 0,
    "11-07 06:00": 0,
    "11-07 06:15": 0,
    "11-07 06:30": 0,
    "11-07 06:45": 0,
    "11-07 07:00": 0,
    "11-07 07:15": 0,
    "11-07 07:30": 0,
    "11-07 07:45": 0,
    "11-07 08:00": 0,
    "11-07 08:15": 0,
    "11-07 08:30": 0,
    "11-07 08:45": 0,
    "11-07 09:00": 0,
    "11-07 09:15": 0,
    "11-07 09:30": 0,
    "11-07 09:45": 0,
    "11-07 10:00": 0,
    "11-07 10:15": 0,
    "11-07 10:30": 0,
    "11-07 10:45": 0,
    "11-07 11:00": 0,
    "11-07 11:15": 0,
    "11-07 11:30": 0,
    "11-07 11:45": 0,
    "11-07 12:00": 0,
    "11-07 12:15": 0,
    "11-07 12:30": 0,
    "11-07 12:45": 0,
    "11-07 13:00": 0,
    "11-07 13:15": 0,
    "11-07 13:30": 0,
    "11-07 13:45": 0,
    "11-07 14:00": 0,
    "11-07 14:15": 0,
    "11-07 14:30": 0,
    "11-07 14:45": 0,
    "11-07 15:00": 0,
    "11-07 15:15": 0,
    "11-07 15:30": 0,
    "11-07 15:45": 0,
    "11-07 16:00": 0,
    "11-07 16:15": 0,
    "11-07 16:30": 0,
    "11-07 16:45": 0,
    "11-07 17:00": 0,
    "11-07 17:15": 0,
    "11-07 17:30": 0,
    "11-07 17:45": 0,
    "11-07 18:00": 0,
    "11-07 18:15": 0,
    "11-07 18:30": 0,
    "11-07 18:45": 0,
    "11-07 19:00": 0,
    "11-07 19:15": 0,
    "11-07 19:30": 0,
    "11-07 19:45": 0,
    "11-07 20:00": 0,
    "11-07 20:15": 0,
    "11-07 20:30": 0,
    "11-07 20:45": 0,
    "11-07 21:00": 0,
    "11-07 21:15": 0,
    "11-07 21:30": 0,
    "11-07 21:45": 0,
    "11-07 22:00": 0,
    "11-07 22:15": 0,
    "11-07 22:30": 0,
    "11-07 22:45": 0,
    "11-07 23:00": 0,
    "11-07 23:15": 0,
    "11-07 23:30": 0,
    "11-07 23:45": 0,
  },
  {
    "ID": "DINHQ_4.OG_834",
    "polygon": [
      -2.567,
      -17.069,
      16.5,
      -3.499,
      -17.508,
      16.5,
      -3.499,
      -17.508,
      18.73,
      -3.499,
      -17.508,
      18.73,
      -2.567,
      -17.069,
      18.73,
      -2.567,
      -17.069,
      16.5
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 09:15": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1281",
    "polygon": [
      -13.624,
      -2.835,
      23.288,
      -12.695,
      -2.398,
      23.288,
      -12.695,
      -2.398,
      26.818,
      -12.695,
      -2.398,
      26.818,
      -13.624,
      -2.835,
      26.818,
      -13.624,
      -2.835,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_762",
    "polygon": [
      -79.123,
      -33.656,
      13.13,
      -78.227,
      -33.234,
      13.13,
      -78.227,
      -33.234,
      15.355,
      -78.227,
      -33.234,
      15.355,
      -79.123,
      -33.656,
      15.355,
      -79.123,
      -33.656,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_510",
    "polygon": [
      -54.823,
      -0.889,
      9.76,
      -54.412,
      -1.761,
      9.76,
      -54.412,
      -1.761,
      11.537,
      -54.412,
      -1.761,
      11.537,
      -54.823,
      -0.889,
      11.537,
      -54.823,
      -0.889,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_86",
    "polygon": [
      0.356,
      -15.614,
      0.882,
      -1.633,
      -16.549,
      0.882,
      -1.633,
      -16.549,
      1.832,
      -1.633,
      -16.549,
      1.832,
      0.356,
      -15.614,
      1.832,
      0.356,
      -15.614,
      0.882
    ],
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_1.OG_227",
    "polygon": [
      0.084,
      -15.822,
      6.39,
      -0.848,
      -16.26,
      6.39,
      -0.848,
      -16.26,
      8.62,
      -0.848,
      -16.26,
      8.62,
      0.084,
      -15.822,
      8.62,
      0.084,
      -15.822,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_3.OG_695",
    "polygon": [
      -41.195,
      -15.809,
      13.13,
      -40.266,
      -15.371,
      13.13,
      -40.266,
      -15.371,
      15.36,
      -40.266,
      -15.371,
      15.36,
      -41.195,
      -15.809,
      15.36,
      -41.195,
      -15.809,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_173",
    "polygon": [
      -74.342,
      -50.844,
      6.393,
      -75.238,
      -51.265,
      6.393,
      -75.238,
      -51.265,
      8.618,
      -75.238,
      -51.265,
      8.618,
      -74.342,
      -50.844,
      8.618,
      -74.342,
      -50.844,
      6.393
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_419",
    "polygon": [
      -14.238,
      -22.561,
      9.76,
      -15.17,
      -22.999,
      9.76,
      -15.17,
      -22.999,
      11.99,
      -15.17,
      -22.999,
      11.99,
      -14.238,
      -22.561,
      11.99,
      -14.238,
      -22.561,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_2.OG_418",
    "polygon": [
      -15.812,
      -23.301,
      9.76,
      -16.744,
      -23.74,
      9.76,
      -16.744,
      -23.74,
      11.99,
      -16.744,
      -23.74,
      11.99,
      -15.812,
      -23.301,
      11.99,
      -15.812,
      -23.301,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_4.OG_802",
    "polygon": [
      -49.1,
      -42.549,
      16.5,
      -51.389,
      -43.626,
      16.5,
      -51.389,
      -43.626,
      18.73,
      -51.389,
      -43.626,
      18.73,
      -49.1,
      -42.549,
      18.73,
      -49.1,
      -42.549,
      16.5
    ],
    "11-07 11:15": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_2.OG_570",
    "polygon": [
      -93.006,
      -40.189,
      9.76,
      -92.11,
      -39.767,
      9.76,
      -92.11,
      -39.767,
      11.985,
      -92.11,
      -39.767,
      11.985,
      -93.006,
      -40.189,
      11.985,
      -93.006,
      -40.189,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_844",
    "polygon": [
      10.911,
      -10.727,
      16.5,
      9.979,
      -11.165,
      16.5,
      9.979,
      -11.165,
      18.73,
      9.979,
      -11.165,
      18.73,
      10.911,
      -10.727,
      18.73,
      10.911,
      -10.727,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_379",
    "polygon": [
      -70.741,
      -49.149,
      9.763,
      -71.637,
      -49.571,
      9.763,
      -71.637,
      -49.571,
      11.988,
      -71.637,
      -49.571,
      11.988,
      -70.741,
      -49.149,
      11.988,
      -70.741,
      -49.149,
      9.763
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_529",
    "polygon": [
      -69.362,
      -5.802,
      9.76,
      -69.773,
      -4.93,
      9.76,
      -69.773,
      -4.93,
      11.537,
      -69.773,
      -4.93,
      11.537,
      -69.362,
      -5.802,
      11.537,
      -69.362,
      -5.802,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_181",
    "polygon": [
      -64.064,
      -46.007,
      6.393,
      -64.959,
      -46.429,
      6.393,
      -64.959,
      -46.429,
      8.618,
      -64.959,
      -46.429,
      8.618,
      -64.064,
      -46.007,
      8.618,
      -64.064,
      -46.007,
      6.393
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_689",
    "polygon": [
      -33.247,
      -12.069,
      13.13,
      -32.318,
      -11.631,
      13.13,
      -32.318,
      -11.631,
      15.36,
      -32.318,
      -11.631,
      15.36,
      -33.247,
      -12.069,
      15.36,
      -33.247,
      -12.069,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_381",
    "polygon": [
      -68.171,
      -47.94,
      9.763,
      -69.067,
      -48.362,
      9.763,
      -69.067,
      -48.362,
      11.988,
      -69.067,
      -48.362,
      11.988,
      -68.171,
      -47.94,
      11.988,
      -68.171,
      -47.94,
      9.763
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_458",
    "polygon": [
      4.076,
      5.494,
      9.76,
      5.005,
      5.932,
      9.76,
      5.005,
      5.932,
      11.99,
      5.005,
      5.932,
      11.99,
      4.076,
      5.494,
      11.99,
      4.076,
      5.494,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_259",
    "polygon": [
      -1.465,
      2.887,
      6.39,
      -0.536,
      3.324,
      6.39,
      -0.536,
      3.324,
      8.62,
      -0.536,
      3.324,
      8.62,
      -1.465,
      2.887,
      8.62,
      -1.465,
      2.887,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1134",
    "polygon": [
      -71.661,
      -0.918,
      19.87,
      -72.071,
      -0.045,
      19.87,
      -72.071,
      -0.045,
      21.647,
      -72.071,
      -0.045,
      21.647,
      -71.661,
      -0.918,
      21.647,
      -71.661,
      -0.918,
      19.87
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_EG_54",
    "polygon": [
      -55.672,
      -41.974,
      1.941,
      -59.535,
      -43.792,
      1.941,
      -59.535,
      -43.792,
      4.228,
      -59.535,
      -43.792,
      4.228,
      -55.672,
      -41.974,
      4.228,
      -55.672,
      -41.974,
      1.941
    ],
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_3.OG_745",
    "polygon": [
      -62.029,
      -21.387,
      13.13,
      -62.439,
      -20.515,
      13.13,
      -62.439,
      -20.515,
      14.907,
      -62.439,
      -20.515,
      14.907,
      -62.029,
      -21.387,
      14.907,
      -62.029,
      -21.387,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_407",
    "polygon": [
      -30.137,
      -30.043,
      9.76,
      -31.069,
      -30.481,
      9.76,
      -31.069,
      -30.481,
      11.99,
      -31.069,
      -30.481,
      11.99,
      -30.137,
      -30.043,
      11.99,
      -30.137,
      -30.043,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_490",
    "polygon": [
      -38.546,
      -14.562,
      9.76,
      -37.617,
      -14.125,
      9.76,
      -37.617,
      -14.125,
      11.99,
      -37.617,
      -14.125,
      11.99,
      -38.546,
      -14.562,
      11.99,
      -38.546,
      -14.562,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_223",
    "polygon": [
      -5.215,
      -18.315,
      6.39,
      -6.147,
      -18.754,
      6.39,
      -6.147,
      -18.754,
      8.62,
      -6.147,
      -18.754,
      8.62,
      -5.215,
      -18.315,
      8.62,
      -5.215,
      -18.315,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_4.OG_779",
    "polygon": [
      -78.45,
      -52.777,
      16.503,
      -79.346,
      -53.198,
      16.503,
      -79.346,
      -53.198,
      18.728,
      -79.346,
      -53.198,
      18.728,
      -78.45,
      -52.777,
      18.728,
      -78.45,
      -52.777,
      16.503
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1273",
    "polygon": [
      -3.027,
      2.152,
      23.288,
      -2.098,
      2.589,
      23.288,
      -2.098,
      2.589,
      26.818,
      -2.098,
      2.589,
      26.818,
      -3.027,
      2.152,
      26.818,
      -3.027,
      2.152,
      23.288
    ]
  },
  {
    "ID": "DINHQ_2.OG_481",
    "polygon": [
      -26.871,
      -9.068,
      9.76,
      -25.942,
      -8.631,
      9.76,
      -25.942,
      -8.631,
      11.99,
      -25.942,
      -8.631,
      11.99,
      -26.871,
      -9.068,
      11.99,
      -26.871,
      -9.068,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_436",
    "polygon": [
      8.153,
      -12.024,
      9.76,
      7.104,
      -12.518,
      9.76,
      7.104,
      -12.518,
      11.99,
      7.104,
      -12.518,
      11.99,
      8.153,
      -12.024,
      11.99,
      8.153,
      -12.024,
      9.76
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_940",
    "polygon": [
      -66.627,
      -11.615,
      16.5,
      -67.037,
      -10.743,
      16.5,
      -67.037,
      -10.743,
      18.277,
      -67.037,
      -10.743,
      18.277,
      -66.627,
      -11.615,
      18.277,
      -66.627,
      -11.615,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1109",
    "polygon": [
      -49.074,
      -13.105,
      19.87,
      -48.664,
      -13.977,
      19.87,
      -48.664,
      -13.977,
      21.647,
      -48.664,
      -13.977,
      21.647,
      -49.074,
      -13.105,
      21.647,
      -49.074,
      -13.105,
      19.87
    ]
  },
  {
    "ID": "DINHQ_1.OG_360",
    "polygon": [
      -84.262,
      -36.074,
      6.39,
      -83.366,
      -35.653,
      6.39,
      -83.366,
      -35.653,
      8.615,
      -83.366,
      -35.653,
      8.615,
      -84.262,
      -36.074,
      8.615,
      -84.262,
      -36.074,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_899",
    "polygon": [
      -42.777,
      -16.553,
      16.5,
      -41.848,
      -16.116,
      16.5,
      -41.848,
      -16.116,
      18.73,
      -41.848,
      -16.116,
      18.73,
      -42.777,
      -16.553,
      18.73,
      -42.777,
      -16.553,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_497",
    "polygon": [
      -47.489,
      -16.474,
      9.76,
      -47.079,
      -17.346,
      9.76,
      -47.079,
      -17.346,
      11.537,
      -47.079,
      -17.346,
      11.537,
      -47.489,
      -16.474,
      11.537,
      -47.489,
      -16.474,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_338",
    "polygon": [
      -62.464,
      -20.461,
      6.39,
      -62.875,
      -19.589,
      6.39,
      -62.875,
      -19.589,
      8.167,
      -62.875,
      -19.589,
      8.167,
      -62.464,
      -20.461,
      8.167,
      -62.464,
      -20.461,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_114",
    "polygon": [
      9.442,
      8.02,
      2.485,
      11.257,
      8.874,
      2.485,
      11.257,
      8.874,
      4.38,
      11.257,
      8.874,
      4.38,
      9.442,
      8.02,
      4.38,
      9.442,
      8.02,
      2.485
    ]
  },
  {
    "ID": "DINHQ_5.OG_1067",
    "polygon": [
      4.076,
      5.494,
      19.87,
      5.005,
      5.932,
      19.87,
      5.005,
      5.932,
      22.1,
      5.005,
      5.932,
      22.1,
      4.076,
      5.494,
      22.1,
      4.076,
      5.494,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_456",
    "polygon": [
      6.713,
      6.735,
      9.76,
      7.642,
      7.173,
      9.76,
      7.642,
      7.173,
      11.99,
      7.642,
      7.173,
      11.99,
      6.713,
      6.735,
      11.99,
      6.713,
      6.735,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_757",
    "polygon": [
      -72.449,
      -30.515,
      13.13,
      -71.553,
      -30.094,
      13.13,
      -71.553,
      -30.094,
      15.355,
      -71.553,
      -30.094,
      15.355,
      -72.449,
      -30.515,
      15.355,
      -72.449,
      -30.515,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_352",
    "polygon": [
      -73.983,
      -31.237,
      6.39,
      -73.087,
      -30.816,
      6.39,
      -73.087,
      -30.816,
      8.615,
      -73.087,
      -30.816,
      8.615,
      -73.983,
      -31.237,
      8.615,
      -73.983,
      -31.237,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_635",
    "polygon": [
      2.734,
      -14.575,
      13.13,
      1.802,
      -15.013,
      13.13,
      1.802,
      -15.013,
      15.36,
      1.802,
      -15.013,
      15.36,
      2.734,
      -14.575,
      15.36,
      2.734,
      -14.575,
      13.13
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_796",
    "polygon": [
      -56.354,
      -42.379,
      16.503,
      -57.25,
      -42.801,
      16.503,
      -57.25,
      -42.801,
      18.728,
      -57.25,
      -42.801,
      18.728,
      -56.354,
      -42.379,
      18.728,
      -56.354,
      -42.379,
      16.503
    ],
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_55",
    "polygon": [
      -57.626,
      -42.893,
      0.935,
      -59.535,
      -43.791,
      0.935,
      -59.535,
      -43.791,
      1.885,
      -59.535,
      -43.791,
      1.885,
      -57.626,
      -42.893,
      1.885,
      -57.626,
      -42.893,
      0.935
    ],
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_3.OG_666",
    "polygon": [
      -3.027,
      2.152,
      13.13,
      -2.098,
      2.589,
      13.13,
      -2.098,
      2.589,
      15.36,
      -2.098,
      2.589,
      15.36,
      -3.027,
      2.152,
      15.36,
      -3.027,
      2.152,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_555",
    "polygon": [
      -73.983,
      -31.237,
      9.76,
      -73.087,
      -30.816,
      9.76,
      -73.087,
      -30.816,
      11.985,
      -73.087,
      -30.816,
      11.985,
      -73.983,
      -31.237,
      11.985,
      -73.983,
      -31.237,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_962",
    "polygon": [
      -75.018,
      -31.724,
      16.5,
      -74.122,
      -31.303,
      16.5,
      -74.122,
      -31.303,
      18.725,
      -74.122,
      -31.303,
      18.725,
      -75.018,
      -31.724,
      18.725,
      -75.018,
      -31.724,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_476",
    "polygon": [
      -20.001,
      -5.835,
      9.76,
      -19.071,
      -5.398,
      9.76,
      -19.071,
      -5.398,
      11.99,
      -19.071,
      -5.398,
      11.99,
      -20.001,
      -5.835,
      11.99,
      -20.001,
      -5.835,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_673",
    "polygon": [
      -12.053,
      -2.095,
      13.13,
      -11.124,
      -1.658,
      13.13,
      -11.124,
      -1.658,
      15.36,
      -11.124,
      -1.658,
      15.36,
      -12.053,
      -2.095,
      15.36,
      -12.053,
      -2.095,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_954",
    "polygon": [
      -64.739,
      -26.887,
      16.5,
      -63.843,
      -26.466,
      16.5,
      -63.843,
      -26.466,
      18.725,
      -63.843,
      -26.466,
      18.725,
      -64.739,
      -26.887,
      18.725,
      -64.739,
      -26.887,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_699",
    "polygon": [
      -46.255,
      -18.19,
      13.13,
      -45.576,
      -17.87,
      13.13,
      -45.576,
      -17.87,
      15.36,
      -45.576,
      -17.87,
      15.36,
      -46.255,
      -18.19,
      15.36,
      -46.255,
      -18.19,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_242",
    "polygon": [
      20.29,
      -6.313,
      6.39,
      19.358,
      -6.752,
      6.39,
      19.358,
      -6.752,
      8.62,
      19.358,
      -6.752,
      8.62,
      20.29,
      -6.313,
      8.62,
      20.29,
      -6.313,
      6.39
    ],
    "11-07 11:00": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1269",
    "polygon": [
      2.504,
      4.755,
      23.288,
      3.434,
      5.192,
      23.288,
      3.434,
      5.192,
      26.818,
      3.434,
      5.192,
      26.818,
      2.504,
      4.755,
      26.818,
      2.504,
      4.755,
      23.288
    ]
  },
  {
    "ID": "DINHQ_2.OG_549",
    "polygon": [
      -66.273,
      -27.609,
      9.76,
      -65.378,
      -27.188,
      9.76,
      -65.378,
      -27.188,
      11.985,
      -65.378,
      -27.188,
      11.985,
      -66.273,
      -27.609,
      11.985,
      -66.273,
      -27.609,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_453",
    "polygon": [
      10.417,
      8.478,
      9.76,
      11.346,
      8.916,
      9.76,
      11.346,
      8.916,
      11.99,
      11.346,
      8.916,
      11.99,
      10.417,
      8.478,
      11.99,
      10.417,
      8.478,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_437",
    "polygon": [
      9.837,
      -11.232,
      9.76,
      8.787,
      -11.726,
      9.76,
      8.787,
      -11.726,
      11.99,
      8.787,
      -11.726,
      11.99,
      9.837,
      -11.232,
      11.99,
      9.837,
      -11.232,
      9.76
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1361",
    "polygon": [
      -68.843,
      -28.819,
      23.288,
      -67.947,
      -28.397,
      23.288,
      -67.947,
      -28.397,
      26.818,
      -67.947,
      -28.397,
      26.818,
      -68.843,
      -28.819,
      26.818,
      -68.843,
      -28.819,
      23.288
    ]
  },
  {
    "ID": "DINHQ_5.OG_1121",
    "polygon": [
      -55.973,
      1.554,
      19.87,
      -55.562,
      0.682,
      19.87,
      -55.562,
      0.682,
      21.647,
      -55.562,
      0.682,
      21.647,
      -55.973,
      1.554,
      21.647,
      -55.973,
      1.554,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_521",
    "polygon": [
      -70.299,
      4.163,
      9.76,
      -69.073,
      4.74,
      9.76,
      -69.073,
      4.74,
      11.54,
      -69.073,
      4.74,
      11.54,
      -70.299,
      4.163,
      11.54,
      -70.299,
      4.163,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_786",
    "polygon": [
      -69.203,
      -48.425,
      16.503,
      -70.099,
      -48.847,
      16.503,
      -70.099,
      -48.847,
      18.728,
      -70.099,
      -48.847,
      18.728,
      -69.203,
      -48.425,
      18.728,
      -69.203,
      -48.425,
      16.503
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 11:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1309",
    "polygon": [
      -48.639,
      -14.031,
      23.288,
      -48.228,
      -14.903,
      23.288,
      -48.228,
      -14.903,
      26.818,
      -48.228,
      -14.903,
      26.818,
      -48.639,
      -14.031,
      26.818,
      -48.639,
      -14.031,
      23.288
    ]
  },
  {
    "ID": "DINHQ_1.OG_245",
    "polygon": [
      23.989,
      -4.573,
      6.39,
      23.057,
      -5.011,
      6.39,
      23.057,
      -5.011,
      8.62,
      23.057,
      -5.011,
      8.62,
      23.989,
      -4.573,
      8.62,
      23.989,
      -4.573,
      6.39
    ],
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_EG_116",
    "polygon": [
      4.167,
      5.537,
      2.485,
      5.982,
      6.391,
      2.485,
      5.982,
      6.391,
      4.38,
      5.982,
      6.391,
      4.38,
      4.167,
      5.537,
      4.38,
      4.167,
      5.537,
      2.485
    ]
  },
  {
    "ID": "DINHQ_1.OG_165",
    "polygon": [
      -84.621,
      -55.681,
      6.393,
      -85.517,
      -56.102,
      6.393,
      -85.517,
      -56.102,
      8.618,
      -85.517,
      -56.102,
      8.618,
      -84.621,
      -55.681,
      8.618,
      -84.621,
      -55.681,
      6.393
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_4.OG_776",
    "polygon": [
      -82.051,
      -54.472,
      16.503,
      -82.947,
      -54.893,
      16.503,
      -82.947,
      -54.893,
      18.728,
      -82.947,
      -54.893,
      18.728,
      -82.051,
      -54.472,
      18.728,
      -82.051,
      -54.472,
      16.503
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_965",
    "polygon": [
      -79.123,
      -33.656,
      16.5,
      -78.227,
      -33.234,
      16.5,
      -78.227,
      -33.234,
      18.725,
      -78.227,
      -33.234,
      18.725,
      -79.123,
      -33.656,
      18.725,
      -79.123,
      -33.656,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_356",
    "polygon": [
      -79.123,
      -33.656,
      6.39,
      -78.227,
      -33.234,
      6.39,
      -78.227,
      -33.234,
      8.615,
      -78.227,
      -33.234,
      8.615,
      -79.123,
      -33.656,
      8.615,
      -79.123,
      -33.656,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_579",
    "polygon": [
      -74.342,
      -50.844,
      13.133,
      -75.238,
      -51.265,
      13.133,
      -75.238,
      -51.265,
      15.358,
      -75.238,
      -51.265,
      15.358,
      -74.342,
      -50.844,
      15.358,
      -74.342,
      -50.844,
      13.133
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_495",
    "polygon": [
      -45.429,
      -17.801,
      9.76,
      -44.5,
      -17.364,
      9.76,
      -44.5,
      -17.364,
      11.99,
      -44.5,
      -17.364,
      11.99,
      -45.429,
      -17.801,
      11.99,
      -45.429,
      -17.801,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_118",
    "polygon": [
      -6.675,
      0.439,
      2.11,
      -4.85,
      1.298,
      2.11,
      -4.85,
      1.298,
      4.38,
      -4.85,
      1.298,
      4.38,
      -6.675,
      0.439,
      4.38,
      -6.675,
      0.439,
      2.11
    ]
  },
  {
    "ID": "DINHQ_EG_72",
    "polygon": [
      -8.206,
      -19.643,
      1.885,
      -12.229,
      -21.536,
      1.885,
      -12.229,
      -21.536,
      4.172,
      -12.229,
      -21.536,
      4.172,
      -8.206,
      -19.643,
      4.172,
      -8.206,
      -19.643,
      1.885
    ],
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_1.OG_353",
    "polygon": [
      -75.018,
      -31.724,
      6.39,
      -74.122,
      -31.303,
      6.39,
      -74.122,
      -31.303,
      8.615,
      -74.122,
      -31.303,
      8.615,
      -75.018,
      -31.724,
      8.615,
      -75.018,
      -31.724,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_452",
    "polygon": [
      11.988,
      9.218,
      9.76,
      12.918,
      9.655,
      9.76,
      12.918,
      9.655,
      11.99,
      12.918,
      9.655,
      11.99,
      11.988,
      9.218,
      11.99,
      11.988,
      9.218,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_132",
    "polygon": [
      -46.416,
      -18.264,
      2.485,
      -44.591,
      -17.406,
      2.485,
      -44.591,
      -17.406,
      4.38,
      -44.591,
      -17.406,
      4.38,
      -46.416,
      -18.264,
      4.38,
      -46.416,
      -18.264,
      2.485
    ]
  },
  {
    "ID": "DINHQ_5.OG_1045",
    "polygon": [
      8.153,
      -12.024,
      19.87,
      7.104,
      -12.518,
      19.87,
      7.104,
      -12.518,
      22.1,
      7.104,
      -12.518,
      22.1,
      8.153,
      -12.024,
      22.1,
      8.153,
      -12.024,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_EG_56",
    "polygon": [
      -55.671,
      -41.973,
      0.935,
      -57.58,
      -42.872,
      0.935,
      -57.58,
      -42.872,
      1.885,
      -57.58,
      -42.872,
      1.885,
      -55.671,
      -41.973,
      1.885,
      -55.671,
      -41.973,
      0.935
    ],
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_4.OG_848",
    "polygon": [
      16.144,
      -8.264,
      16.5,
      15.212,
      -8.703,
      16.5,
      15.212,
      -8.703,
      18.73,
      15.212,
      -8.703,
      18.73,
      16.144,
      -8.264,
      18.73,
      16.144,
      -8.264,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1066",
    "polygon": [
      5.142,
      5.996,
      19.87,
      6.071,
      6.433,
      19.87,
      6.071,
      6.433,
      22.1,
      6.071,
      6.433,
      22.1,
      5.142,
      5.996,
      22.1,
      5.142,
      5.996,
      19.87
    ]
  },
  {
    "ID": "DINHQ_EG_51",
    "polygon": [
      -60.811,
      -44.392,
      1.941,
      -64.675,
      -46.21,
      1.941,
      -64.675,
      -46.21,
      4.228,
      -64.675,
      -46.21,
      4.228,
      -60.811,
      -44.392,
      4.228,
      -60.811,
      -44.392,
      1.941
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_2.OG_444",
    "polygon": [
      18.759,
      -7.034,
      9.76,
      17.827,
      -7.472,
      9.76,
      17.827,
      -7.472,
      11.99,
      17.827,
      -7.472,
      11.99,
      18.759,
      -7.034,
      11.99,
      18.759,
      -7.034,
      9.76
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_818",
    "polygon": [
      -23.764,
      -27.043,
      16.5,
      -24.696,
      -27.482,
      16.5,
      -24.696,
      -27.482,
      18.73,
      -24.696,
      -27.482,
      18.73,
      -23.764,
      -27.043,
      18.73,
      -23.764,
      -27.043,
      16.5
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1
  },
  {
    "ID": "DINHQ_1.OG_276",
    "polygon": [
      -24.222,
      -7.822,
      6.39,
      -23.292,
      -7.384,
      6.39,
      -23.292,
      -7.384,
      8.62,
      -23.292,
      -7.384,
      8.62,
      -24.222,
      -7.822,
      8.62,
      -24.222,
      -7.822,
      6.39
    ]
  },
  {
    "ID": "DINHQ_6.OG_1298",
    "polygon": [
      -35.897,
      -13.315,
      23.288,
      -34.967,
      -12.878,
      23.288,
      -34.967,
      -12.878,
      26.818,
      -34.967,
      -12.878,
      26.818,
      -35.897,
      -13.315,
      26.818,
      -35.897,
      -13.315,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_578",
    "polygon": [
      -75.881,
      -51.568,
      13.133,
      -76.776,
      -51.989,
      13.133,
      -76.776,
      -51.989,
      15.358,
      -76.776,
      -51.989,
      15.358,
      -75.881,
      -51.568,
      15.358,
      -75.881,
      -51.568,
      13.133
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_225",
    "polygon": [
      -2.567,
      -17.069,
      6.39,
      -3.499,
      -17.508,
      6.39,
      -3.499,
      -17.508,
      8.62,
      -3.499,
      -17.508,
      8.62,
      -2.567,
      -17.069,
      8.62,
      -2.567,
      -17.069,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_1.OG_291",
    "polygon": [
      -43.857,
      -17.061,
      6.39,
      -42.928,
      -16.624,
      6.39,
      -42.928,
      -16.624,
      8.62,
      -42.928,
      -16.624,
      8.62,
      -43.857,
      -17.061,
      8.62,
      -43.857,
      -17.061,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_568",
    "polygon": [
      -90.436,
      -38.979,
      9.76,
      -89.54,
      -38.558,
      9.76,
      -89.54,
      -38.558,
      11.985,
      -89.54,
      -38.558,
      11.985,
      -90.436,
      -38.979,
      11.985,
      -90.436,
      -38.979,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_795",
    "polygon": [
      -57.893,
      -43.103,
      16.503,
      -58.788,
      -43.525,
      16.503,
      -58.788,
      -43.525,
      18.728,
      -58.788,
      -43.525,
      18.728,
      -57.893,
      -43.103,
      18.728,
      -57.893,
      -43.103,
      16.503
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_EG_155",
    "polygon": [
      -54.855,
      -0.593,
      4.349,
      -54.325,
      -1.72,
      4.349,
      -54.325,
      -1.72,
      5.249,
      -54.325,
      -1.72,
      5.249,
      -54.855,
      -0.593,
      5.249,
      -54.855,
      -0.593,
      4.349
    ]
  },
  {
    "ID": "DINHQ_EG_122",
    "polygon": [
      -17.267,
      -4.549,
      2.11,
      -15.442,
      -3.69,
      2.11,
      -15.442,
      -3.69,
      4.38,
      -15.442,
      -3.69,
      4.38,
      -17.267,
      -4.549,
      4.38,
      -17.267,
      -4.549,
      2.11
    ]
  },
  {
    "ID": "DINHQ_4.OG_845",
    "polygon": [
      12.46,
      -9.998,
      16.5,
      11.528,
      -10.436,
      16.5,
      11.528,
      -10.436,
      18.73,
      11.528,
      -10.436,
      18.73,
      12.46,
      -9.998,
      18.73,
      12.46,
      -9.998,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_376",
    "polygon": [
      -74.342,
      -50.844,
      9.763,
      -75.238,
      -51.265,
      9.763,
      -75.238,
      -51.265,
      11.988,
      -75.238,
      -51.265,
      11.988,
      -74.342,
      -50.844,
      11.988,
      -74.342,
      -50.844,
      9.763
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_121",
    "polygon": [
      -14.619,
      -3.302,
      2.11,
      -12.794,
      -2.443,
      2.11,
      -12.794,
      -2.443,
      4.38,
      -12.794,
      -2.443,
      4.38,
      -14.619,
      -3.302,
      4.38,
      -14.619,
      -3.302,
      2.11
    ]
  },
  {
    "ID": "DINHQ_1.OG_256",
    "polygon": [
      2.504,
      4.755,
      6.39,
      3.434,
      5.192,
      6.39,
      3.434,
      5.192,
      8.62,
      3.434,
      5.192,
      8.62,
      2.504,
      4.755,
      8.62,
      2.504,
      4.755,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1080",
    "polygon": [
      -13.624,
      -2.835,
      19.87,
      -12.695,
      -2.398,
      19.87,
      -12.695,
      -2.398,
      22.1,
      -12.695,
      -2.398,
      22.1,
      -13.624,
      -2.835,
      22.1,
      -13.624,
      -2.835,
      19.87
    ]
  },
  {
    "ID": "DINHQ_1.OG_323",
    "polygon": [
      -71.225,
      -1.843,
      6.39,
      -71.635,
      -0.971,
      6.39,
      -71.635,
      -0.971,
      8.167,
      -71.635,
      -0.971,
      8.167,
      -71.225,
      -1.843,
      8.167,
      -71.225,
      -1.843,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_794",
    "polygon": [
      -58.924,
      -43.589,
      16.503,
      -59.82,
      -44.01,
      16.503,
      -59.82,
      -44.01,
      18.728,
      -59.82,
      -44.01,
      18.728,
      -58.924,
      -43.589,
      18.728,
      -58.924,
      -43.589,
      16.503
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_63",
    "polygon": [
      -24.102,
      -27.123,
      1.885,
      -28.125,
      -29.016,
      1.885,
      -28.125,
      -29.016,
      4.172,
      -28.125,
      -29.016,
      4.172,
      -24.102,
      -27.123,
      4.172,
      -24.102,
      -27.123,
      1.885
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_1.OG_200",
    "polygon": [
      -35.436,
      -32.536,
      6.39,
      -36.368,
      -32.974,
      6.39,
      -36.368,
      -32.974,
      8.62,
      -36.368,
      -32.974,
      8.62,
      -35.436,
      -32.536,
      8.62,
      -35.436,
      -32.536,
      6.39
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1
  },
  {
    "ID": "DINHQ_2.OG_372",
    "polygon": [
      -79.482,
      -53.262,
      9.763,
      -80.378,
      -53.684,
      9.763,
      -80.378,
      -53.684,
      11.988,
      -80.378,
      -53.684,
      11.988,
      -79.482,
      -53.262,
      11.988,
      -79.482,
      -53.262,
      9.763
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_977",
    "polygon": [
      -84.621,
      -55.681,
      19.873,
      -85.517,
      -56.102,
      19.873,
      -85.517,
      -56.102,
      22.098,
      -85.517,
      -56.102,
      22.098,
      -84.621,
      -55.681,
      22.098,
      -84.621,
      -55.681,
      19.873
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_2.OG_394",
    "polygon": [
      -51.565,
      -43.626,
      9.766,
      -52.543,
      -41.547,
      9.766,
      -52.543,
      -41.547,
      11.996,
      -52.543,
      -41.547,
      11.996,
      -51.565,
      -43.626,
      11.996,
      -51.565,
      -43.626,
      9.766
    ],
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1093",
    "polygon": [
      -30.598,
      -10.822,
      19.87,
      -29.669,
      -10.385,
      19.87,
      -29.669,
      -10.385,
      22.1,
      -29.669,
      -10.385,
      22.1,
      -30.598,
      -10.822,
      22.1,
      -30.598,
      -10.822,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1347",
    "polygon": [
      -64.763,
      -15.575,
      23.288,
      -65.174,
      -14.703,
      23.288,
      -65.174,
      -14.703,
      26.818,
      -65.174,
      -14.703,
      26.818,
      -64.763,
      -15.575,
      26.818,
      -64.763,
      -15.575,
      23.288
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_2.OG_477",
    "polygon": [
      -21.572,
      -6.575,
      9.76,
      -20.643,
      -6.138,
      9.76,
      -20.643,
      -6.138,
      11.99,
      -20.643,
      -6.138,
      11.99,
      -21.572,
      -6.575,
      11.99,
      -21.572,
      -6.575,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1016",
    "polygon": [
      -30.137,
      -30.043,
      19.87,
      -31.069,
      -30.481,
      19.87,
      -31.069,
      -30.481,
      22.1,
      -31.069,
      -30.481,
      22.1,
      -30.137,
      -30.043,
      22.1,
      -30.137,
      -30.043,
      19.87
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_545",
    "polygon": [
      -62.173,
      -25.68,
      9.76,
      -61.277,
      -25.258,
      9.76,
      -61.277,
      -25.258,
      11.985,
      -61.277,
      -25.258,
      11.985,
      -62.173,
      -25.68,
      11.985,
      -62.173,
      -25.68,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_611",
    "polygon": [
      -29.063,
      -29.537,
      13.13,
      -29.995,
      -29.976,
      13.13,
      -29.995,
      -29.976,
      15.36,
      -29.995,
      -29.976,
      15.36,
      -29.063,
      -29.537,
      15.36,
      -29.063,
      -29.537,
      13.13
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1
  },
  {
    "ID": "DINHQ_EG_123",
    "polygon": [
      -19.916,
      -5.795,
      2.11,
      -18.091,
      -4.936,
      2.11,
      -18.091,
      -4.936,
      4.38,
      -18.091,
      -4.936,
      4.38,
      -19.916,
      -5.795,
      4.38,
      -19.916,
      -5.795,
      2.11
    ]
  },
  {
    "ID": "DINHQ_4.OG_888",
    "polygon": [
      -27.949,
      -9.575,
      16.5,
      -27.019,
      -9.138,
      16.5,
      -27.019,
      -9.138,
      18.73,
      -27.019,
      -9.138,
      18.73,
      -27.949,
      -9.575,
      18.73,
      -27.949,
      -9.575,
      16.5
    ]
  },
  {
    "ID": "DINHQ_EG_139",
    "polygon": [
      -57.838,
      5.521,
      2.484,
      -57.428,
      4.649,
      2.484,
      -57.428,
      4.649,
      5.25,
      -57.428,
      4.649,
      5.25,
      -57.838,
      5.521,
      5.25,
      -57.838,
      5.521,
      2.484
    ]
  },
  {
    "ID": "DINHQ_2.OG_522",
    "polygon": [
      -72.959,
      2.912,
      9.76,
      -70.94,
      3.863,
      9.76,
      -70.94,
      3.863,
      11.54,
      -70.94,
      3.863,
      11.54,
      -72.959,
      2.912,
      11.54,
      -72.959,
      2.912,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1162",
    "polygon": [
      -71.413,
      -30.028,
      19.87,
      -70.518,
      -29.607,
      19.87,
      -70.518,
      -29.607,
      22.095,
      -70.518,
      -29.607,
      22.095,
      -71.413,
      -30.028,
      22.095,
      -71.413,
      -30.028,
      19.87
    ]
  },
  {
    "ID": "DINHQ_4.OG_783",
    "polygon": [
      -73.311,
      -50.358,
      16.503,
      -74.207,
      -50.78,
      16.503,
      -74.207,
      -50.78,
      18.728,
      -74.207,
      -50.78,
      18.728,
      -73.311,
      -50.358,
      18.728,
      -73.311,
      -50.358,
      16.503
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 11:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_403",
    "polygon": [
      -35.436,
      -32.536,
      9.76,
      -36.368,
      -32.974,
      9.76,
      -36.368,
      -32.974,
      11.99,
      -36.368,
      -32.974,
      11.99,
      -35.436,
      -32.536,
      11.99,
      -35.436,
      -32.536,
      9.76
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1
  },
  {
    "ID": "DINHQ_EG_68",
    "polygon": [
      -20.839,
      -25.587,
      0.881,
      -22.828,
      -26.523,
      0.881,
      -22.828,
      -26.523,
      1.831,
      -22.828,
      -26.523,
      1.831,
      -20.839,
      -25.587,
      1.831,
      -20.839,
      -25.587,
      0.881
    ],
    "11-07 11:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1322",
    "polygon": [
      -55.973,
      1.554,
      23.288,
      -55.562,
      0.682,
      23.288,
      -55.562,
      0.682,
      26.818,
      -55.562,
      0.682,
      26.818,
      -55.973,
      1.554,
      26.818,
      -55.973,
      1.554,
      23.288
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_EG_126",
    "polygon": [
      -27.86,
      -9.537,
      2.11,
      -26.035,
      -8.678,
      2.11,
      -26.035,
      -8.678,
      4.38,
      -26.035,
      -8.678,
      4.38,
      -27.86,
      -9.537,
      4.38,
      -27.86,
      -9.537,
      2.11
    ]
  },
  {
    "ID": "DINHQ_3.OG_664",
    "polygon": [
      -0.383,
      3.396,
      13.13,
      0.671,
      3.892,
      13.13,
      0.671,
      3.892,
      15.36,
      0.671,
      3.892,
      15.36,
      -0.383,
      3.396,
      15.36,
      -0.383,
      3.396,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_669",
    "polygon": [
      -6.754,
      0.398,
      13.13,
      -5.825,
      0.835,
      13.13,
      -5.825,
      0.835,
      15.36,
      -5.825,
      0.835,
      15.36,
      -6.754,
      0.398,
      15.36,
      -6.754,
      0.398,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_754",
    "polygon": [
      -68.843,
      -28.819,
      13.13,
      -67.947,
      -28.397,
      13.13,
      -67.947,
      -28.397,
      15.355,
      -67.947,
      -28.397,
      15.355,
      -68.843,
      -28.819,
      15.355,
      -68.843,
      -28.819,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_565",
    "polygon": [
      -86.832,
      -37.283,
      9.76,
      -85.936,
      -36.862,
      9.76,
      -85.936,
      -36.862,
      11.985,
      -85.936,
      -36.862,
      11.985,
      -86.832,
      -37.283,
      11.985,
      -86.832,
      -37.283,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_156",
    "polygon": [
      -54.303,
      -1.766,
      4.349,
      -53.772,
      -2.894,
      4.349,
      -53.772,
      -2.894,
      5.249,
      -53.772,
      -2.894,
      5.249,
      -54.303,
      -1.766,
      5.249,
      -54.303,
      -1.766,
      4.349
    ]
  },
  {
    "ID": "DINHQ_4.OG_860",
    "polygon": [
      9.351,
      7.977,
      16.5,
      10.28,
      8.414,
      16.5,
      10.28,
      8.414,
      18.73,
      10.28,
      8.414,
      18.73,
      9.351,
      7.977,
      18.73,
      9.351,
      7.977,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1031",
    "polygon": [
      -10.513,
      -20.808,
      19.87,
      -11.445,
      -21.247,
      19.87,
      -11.445,
      -21.247,
      22.1,
      -11.445,
      -21.247,
      22.1,
      -10.513,
      -20.808,
      22.1,
      -10.513,
      -20.808,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_241",
    "polygon": [
      18.759,
      -7.034,
      6.39,
      17.827,
      -7.472,
      6.39,
      17.827,
      -7.472,
      8.62,
      17.827,
      -7.472,
      8.62,
      18.759,
      -7.034,
      8.62,
      18.759,
      -7.034,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_4.OG_825",
    "polygon": [
      -14.238,
      -22.561,
      16.5,
      -15.17,
      -22.999,
      16.5,
      -15.17,
      -22.999,
      18.73,
      -15.17,
      -22.999,
      18.73,
      -14.238,
      -22.561,
      18.73,
      -14.238,
      -22.561,
      16.5
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_2.OG_530",
    "polygon": [
      -68.927,
      -6.728,
      9.76,
      -69.337,
      -5.855,
      9.76,
      -69.337,
      -5.855,
      11.537,
      -69.337,
      -5.855,
      11.537,
      -68.927,
      -6.728,
      11.537,
      -68.927,
      -6.728,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_226",
    "polygon": [
      -0.993,
      -16.328,
      6.39,
      -1.925,
      -16.767,
      6.39,
      -1.925,
      -16.767,
      8.62,
      -1.925,
      -16.767,
      8.62,
      -0.993,
      -16.328,
      8.62,
      -0.993,
      -16.328,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_3.OG_729",
    "polygon": [
      -71.225,
      -1.843,
      13.13,
      -71.635,
      -0.971,
      13.13,
      -71.635,
      -0.971,
      14.907,
      -71.635,
      -0.971,
      14.907,
      -71.225,
      -1.843,
      14.907,
      -71.225,
      -1.843,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_1159",
    "polygon": [
      -67.308,
      -28.096,
      19.87,
      -66.413,
      -27.675,
      19.87,
      -66.413,
      -27.675,
      22.095,
      -66.413,
      -27.675,
      22.095,
      -67.308,
      -28.096,
      22.095,
      -67.308,
      -28.096,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_603",
    "polygon": [
      -38.743,
      -35.061,
      13.13,
      -37.764,
      -37.141,
      13.13,
      -37.764,
      -37.141,
      15.36,
      -37.764,
      -37.141,
      15.36,
      -38.743,
      -35.061,
      15.36,
      -38.743,
      -35.061,
      13.13
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_1.OG_273",
    "polygon": [
      -20.001,
      -5.835,
      6.39,
      -19.071,
      -5.398,
      6.39,
      -19.071,
      -5.398,
      8.62,
      -19.071,
      -5.398,
      8.62,
      -20.001,
      -5.835,
      8.62,
      -20.001,
      -5.835,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1042",
    "polygon": [
      4.306,
      -13.835,
      19.87,
      3.374,
      -14.274,
      19.87,
      3.374,
      -14.274,
      22.1,
      3.374,
      -14.274,
      22.1,
      4.306,
      -13.835,
      22.1,
      4.306,
      -13.835,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1043",
    "polygon": [
      5.382,
      -13.328,
      19.87,
      4.45,
      -13.767,
      19.87,
      4.45,
      -13.767,
      22.1,
      4.45,
      -13.767,
      22.1,
      5.382,
      -13.328,
      22.1,
      5.382,
      -13.328,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_737",
    "polygon": [
      -66.627,
      -11.615,
      13.13,
      -67.037,
      -10.743,
      13.13,
      -67.037,
      -10.743,
      14.907,
      -67.037,
      -10.743,
      14.907,
      -66.627,
      -11.615,
      14.907,
      -66.627,
      -11.615,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_462",
    "polygon": [
      -1.465,
      2.887,
      9.76,
      -0.536,
      3.324,
      9.76,
      -0.536,
      3.324,
      11.99,
      -0.536,
      3.324,
      11.99,
      -1.465,
      2.887,
      11.99,
      -1.465,
      2.887,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_711",
    "polygon": [
      -53.673,
      -3.332,
      13.13,
      -53.263,
      -4.204,
      13.13,
      -53.263,
      -4.204,
      14.907,
      -53.263,
      -4.204,
      14.907,
      -53.673,
      -3.332,
      14.907,
      -53.673,
      -3.332,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_145",
    "polygon": [
      -53.239,
      -4.252,
      2.484,
      -52.829,
      -5.124,
      2.484,
      -52.829,
      -5.124,
      5.25,
      -52.829,
      -5.124,
      5.25,
      -53.239,
      -4.252,
      5.25,
      -53.239,
      -4.252,
      2.484
    ]
  },
  {
    "ID": "DINHQ_EG_95",
    "polygon": [
      23.522,
      -4.708,
      0.883,
      21.476,
      -5.671,
      0.883,
      21.476,
      -5.671,
      1.833,
      21.476,
      -5.671,
      1.833,
      23.522,
      -4.708,
      1.833,
      23.522,
      -4.708,
      0.883
    ]
  },
  {
    "ID": "DINHQ_3.OG_717",
    "polygon": [
      -57.122,
      3.998,
      13.13,
      -56.712,
      3.125,
      13.13,
      -56.712,
      3.125,
      14.907,
      -56.712,
      3.125,
      14.907,
      -57.122,
      3.998,
      14.907,
      -57.122,
      3.998,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_219",
    "polygon": [
      -10.513,
      -20.808,
      6.39,
      -11.445,
      -21.247,
      6.39,
      -11.445,
      -21.247,
      8.62,
      -11.445,
      -21.247,
      8.62,
      -10.513,
      -20.808,
      8.62,
      -10.513,
      -20.808,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_4.OG_835",
    "polygon": [
      -0.993,
      -16.328,
      16.5,
      -1.925,
      -16.767,
      16.5,
      -1.925,
      -16.767,
      18.73,
      -1.925,
      -16.767,
      18.73,
      -0.993,
      -16.328,
      18.73,
      -0.993,
      -16.328,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 09:15": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_2.OG_499",
    "polygon": [
      -48.639,
      -14.031,
      9.76,
      -48.228,
      -14.903,
      9.76,
      -48.228,
      -14.903,
      11.537,
      -48.228,
      -14.903,
      11.537,
      -48.639,
      -14.031,
      11.537,
      -48.639,
      -14.031,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_175",
    "polygon": [
      -71.773,
      -49.635,
      6.393,
      -72.668,
      -50.056,
      6.393,
      -72.668,
      -50.056,
      8.618,
      -72.668,
      -50.056,
      8.618,
      -71.773,
      -49.635,
      8.618,
      -71.773,
      -49.635,
      6.393
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_709",
    "polygon": [
      -52.523,
      -5.775,
      13.13,
      -52.113,
      -6.648,
      13.13,
      -52.113,
      -6.648,
      14.907,
      -52.113,
      -6.648,
      14.907,
      -52.523,
      -5.775,
      14.907,
      -52.523,
      -5.775,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_813",
    "polygon": [
      -30.137,
      -30.043,
      16.5,
      -31.069,
      -30.481,
      16.5,
      -31.069,
      -30.481,
      18.73,
      -31.069,
      -30.481,
      18.73,
      -30.137,
      -30.043,
      18.73,
      -30.137,
      -30.043,
      16.5
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_71",
    "polygon": [
      -15.54,
      -23.094,
      0.881,
      -17.529,
      -24.029,
      0.881,
      -17.529,
      -24.029,
      1.831,
      -17.529,
      -24.029,
      1.831,
      -15.54,
      -23.094,
      1.831,
      -15.54,
      -23.094,
      0.881
    ],
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_4.OG_820",
    "polygon": [
      -21.113,
      -25.796,
      16.5,
      -22.045,
      -26.235,
      16.5,
      -22.045,
      -26.235,
      18.73,
      -22.045,
      -26.235,
      18.73,
      -21.113,
      -25.796,
      18.73,
      -21.113,
      -25.796,
      16.5
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1336",
    "polygon": [
      -71.225,
      -1.843,
      23.288,
      -71.635,
      -0.971,
      23.288,
      -71.635,
      -0.971,
      26.818,
      -71.635,
      -0.971,
      26.818,
      -71.225,
      -1.843,
      26.818,
      -71.225,
      -1.843,
      23.288
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_3.OG_581",
    "polygon": [
      -71.773,
      -49.635,
      13.133,
      -72.668,
      -50.056,
      13.133,
      -72.668,
      -50.056,
      15.358,
      -72.668,
      -50.056,
      15.358,
      -71.773,
      -49.635,
      15.358,
      -71.773,
      -49.635,
      13.133
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_655",
    "polygon": [
      11.988,
      9.218,
      13.13,
      12.918,
      9.655,
      13.13,
      12.918,
      9.655,
      15.36,
      12.918,
      9.655,
      15.36,
      11.988,
      9.218,
      15.36,
      11.988,
      9.218,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_319",
    "polygon": [
      -72.959,
      2.912,
      6.39,
      -70.94,
      3.863,
      6.39,
      -70.94,
      3.863,
      8.17,
      -70.94,
      3.863,
      8.17,
      -72.959,
      2.912,
      8.17,
      -72.959,
      2.912,
      6.39
    ]
  },
  {
    "ID": "DINHQ_6.OG_1286",
    "polygon": [
      -20.001,
      -5.835,
      23.288,
      -19.071,
      -5.398,
      23.288,
      -19.071,
      -5.398,
      26.818,
      -19.071,
      -5.398,
      26.818,
      -20.001,
      -5.835,
      26.818,
      -20.001,
      -5.835,
      23.288
    ]
  },
  {
    "ID": "DINHQ_4.OG_968",
    "polygon": [
      -82.727,
      -35.352,
      16.5,
      -81.832,
      -34.931,
      16.5,
      -81.832,
      -34.931,
      18.725,
      -81.832,
      -34.931,
      18.725,
      -82.727,
      -35.352,
      18.725,
      -82.727,
      -35.352,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_866",
    "polygon": [
      1.313,
      4.195,
      16.5,
      2.367,
      4.691,
      16.5,
      2.367,
      4.691,
      18.73,
      2.367,
      4.691,
      18.73,
      1.313,
      4.195,
      18.73,
      1.313,
      4.195,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1006",
    "polygon": [
      -43.581,
      -39.952,
      19.87,
      -45.698,
      -40.948,
      19.87,
      -45.698,
      -40.948,
      22.1,
      -45.698,
      -40.948,
      22.1,
      -43.581,
      -39.952,
      22.1,
      -43.581,
      -39.952,
      19.87
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_4.OG_869",
    "polygon": [
      -3.027,
      2.152,
      16.5,
      -2.098,
      2.589,
      16.5,
      -2.098,
      2.589,
      18.73,
      -2.098,
      2.589,
      18.73,
      -3.027,
      2.152,
      18.73,
      -3.027,
      2.152,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_289",
    "polygon": [
      -41.195,
      -15.809,
      6.39,
      -40.266,
      -15.371,
      6.39,
      -40.266,
      -15.371,
      8.62,
      -40.266,
      -15.371,
      8.62,
      -41.195,
      -15.809,
      8.62,
      -41.195,
      -15.809,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_326",
    "polygon": [
      -69.362,
      -5.802,
      6.39,
      -69.773,
      -4.93,
      6.39,
      -69.773,
      -4.93,
      8.167,
      -69.773,
      -4.93,
      8.167,
      -69.362,
      -5.802,
      8.167,
      -69.362,
      -5.802,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_765",
    "polygon": [
      -82.727,
      -35.352,
      13.13,
      -81.832,
      -34.931,
      13.13,
      -81.832,
      -34.931,
      15.355,
      -81.832,
      -34.931,
      15.355,
      -82.727,
      -35.352,
      15.355,
      -82.727,
      -35.352,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_660",
    "polygon": [
      5.142,
      5.996,
      13.13,
      6.071,
      6.433,
      13.13,
      6.071,
      6.433,
      15.36,
      6.071,
      6.433,
      15.36,
      5.142,
      5.996,
      15.36,
      5.142,
      5.996,
      13.13
    ]
  },
  {
    "ID": "DINHQ_6.OG_1321",
    "polygon": [
      -55.537,
      0.628,
      23.288,
      -55.126,
      -0.244,
      23.288,
      -55.126,
      -0.244,
      26.818,
      -55.126,
      -0.244,
      26.818,
      -55.537,
      0.628,
      26.818,
      -55.537,
      0.628,
      23.288
    ]
  },
  {
    "ID": "DINHQ_2.OG_377",
    "polygon": [
      -73.311,
      -50.358,
      9.763,
      -74.207,
      -50.78,
      9.763,
      -74.207,
      -50.78,
      11.988,
      -74.207,
      -50.78,
      11.988,
      -73.311,
      -50.358,
      11.988,
      -73.311,
      -50.358,
      9.763
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_262",
    "polygon": [
      -5.676,
      0.905,
      6.39,
      -4.747,
      1.342,
      6.39,
      -4.747,
      1.342,
      8.62,
      -4.747,
      1.342,
      8.62,
      -5.676,
      0.905,
      8.62,
      -5.676,
      0.905,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_644",
    "polygon": [
      15.075,
      -8.767,
      13.13,
      14.143,
      -9.206,
      13.13,
      14.143,
      -9.206,
      15.36,
      14.143,
      -9.206,
      15.36,
      15.075,
      -8.767,
      15.36,
      15.075,
      -8.767,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_2.OG_410",
    "polygon": [
      -26.412,
      -28.289,
      9.76,
      -27.344,
      -28.728,
      9.76,
      -27.344,
      -28.728,
      11.99,
      -27.344,
      -28.728,
      11.99,
      -26.412,
      -28.289,
      11.99,
      -26.412,
      -28.289,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1
  },
  {
    "ID": "DINHQ_4.OG_975",
    "polygon": [
      -91.97,
      -39.701,
      16.5,
      -91.075,
      -39.28,
      16.5,
      -91.075,
      -39.28,
      18.725,
      -91.075,
      -39.28,
      18.725,
      -91.97,
      -39.701,
      18.725,
      -91.97,
      -39.701,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1284",
    "polygon": [
      -17.351,
      -4.589,
      23.288,
      -16.422,
      -4.151,
      23.288,
      -16.422,
      -4.151,
      26.818,
      -16.422,
      -4.151,
      26.818,
      -17.351,
      -4.589,
      26.818,
      -17.351,
      -4.589,
      23.288
    ]
  },
  {
    "ID": "DINHQ_2.OG_491",
    "polygon": [
      -40.118,
      -15.302,
      9.76,
      -39.188,
      -14.864,
      9.76,
      -39.188,
      -14.864,
      11.99,
      -39.188,
      -14.864,
      11.99,
      -40.118,
      -15.302,
      11.99,
      -40.118,
      -15.302,
      9.76
    ]
  },
  {
    "ID": "DINHQ_6.OG_1325",
    "polygon": [
      -57.836,
      5.515,
      23.288,
      -57.426,
      4.643,
      23.288,
      -57.426,
      4.643,
      26.818,
      -57.426,
      4.643,
      26.818,
      -57.836,
      5.515,
      26.818,
      -57.836,
      5.515,
      23.288
    ]
  },
  {
    "ID": "DINHQ_EG_140",
    "polygon": [
      -57.123,
      4.001,
      2.484,
      -56.712,
      3.128,
      2.484,
      -56.712,
      3.128,
      5.25,
      -56.712,
      3.128,
      5.25,
      -57.123,
      4.001,
      5.25,
      -57.123,
      4.001,
      2.484
    ]
  },
  {
    "ID": "DINHQ_2.OG_438",
    "polygon": [
      10.911,
      -10.727,
      9.76,
      9.979,
      -11.165,
      9.76,
      9.979,
      -11.165,
      11.99,
      9.979,
      -11.165,
      11.99,
      10.911,
      -10.727,
      11.99,
      10.911,
      -10.727,
      9.76
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_EG_44",
    "polygon": [
      -76.229,
      -51.647,
      0.935,
      -78.138,
      -52.545,
      0.935,
      -78.138,
      -52.545,
      1.885,
      -78.138,
      -52.545,
      1.885,
      -76.229,
      -51.647,
      1.885,
      -76.229,
      -51.647,
      0.935
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_EG_128",
    "polygon": [
      -35.806,
      -13.272,
      2.11,
      -33.981,
      -12.413,
      2.11,
      -33.981,
      -12.413,
      4.38,
      -33.981,
      -12.413,
      4.38,
      -35.806,
      -13.272,
      4.38,
      -35.806,
      -13.272,
      2.11
    ]
  },
  {
    "ID": "DINHQ_2.OG_566",
    "polygon": [
      -87.866,
      -37.77,
      9.76,
      -86.97,
      -37.349,
      9.76,
      -86.97,
      -37.349,
      11.985,
      -86.97,
      -37.349,
      11.985,
      -87.866,
      -37.77,
      11.985,
      -87.866,
      -37.77,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_991",
    "polygon": [
      -66.633,
      -47.216,
      19.873,
      -67.529,
      -47.638,
      19.873,
      -67.529,
      -47.638,
      22.098,
      -67.529,
      -47.638,
      22.098,
      -66.633,
      -47.216,
      22.098,
      -66.633,
      -47.216,
      19.873
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_457",
    "polygon": [
      5.142,
      5.996,
      9.76,
      6.071,
      6.433,
      9.76,
      6.071,
      6.433,
      11.99,
      6.071,
      6.433,
      11.99,
      5.142,
      5.996,
      11.99,
      5.142,
      5.996,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_807",
    "polygon": [
      -38.076,
      -33.778,
      16.5,
      -38.854,
      -34.144,
      16.5,
      -38.854,
      -34.144,
      18.73,
      -38.854,
      -34.144,
      18.73,
      -38.076,
      -33.778,
      18.73,
      -38.076,
      -33.778,
      16.5
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1
  },
  {
    "ID": "DINHQ_4.OG_877",
    "polygon": [
      -13.624,
      -2.835,
      16.5,
      -12.695,
      -2.398,
      16.5,
      -12.695,
      -2.398,
      18.73,
      -12.695,
      -2.398,
      18.73,
      -13.624,
      -2.835,
      18.73,
      -13.624,
      -2.835,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_963",
    "polygon": [
      -76.552,
      -32.446,
      16.5,
      -75.656,
      -32.025,
      16.5,
      -75.656,
      -32.025,
      18.725,
      -75.656,
      -32.025,
      18.725,
      -76.552,
      -32.446,
      18.725,
      -76.552,
      -32.446,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_254",
    "polygon": [
      5.142,
      5.996,
      6.39,
      6.071,
      6.433,
      6.39,
      6.071,
      6.433,
      8.62,
      6.071,
      6.433,
      8.62,
      5.142,
      5.996,
      8.62,
      5.142,
      5.996,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_750",
    "polygon": [
      -61.117,
      -25.183,
      13.13,
      -60.755,
      -25.013,
      13.13,
      -60.755,
      -25.013,
      15.355,
      -60.755,
      -25.013,
      15.355,
      -61.117,
      -25.183,
      15.355,
      -61.117,
      -25.183,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_824",
    "polygon": [
      -15.812,
      -23.301,
      16.5,
      -16.744,
      -23.74,
      16.5,
      -16.744,
      -23.74,
      18.73,
      -16.744,
      -23.74,
      18.73,
      -15.812,
      -23.301,
      18.73,
      -15.812,
      -23.301,
      16.5
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_2.OG_550",
    "polygon": [
      -67.308,
      -28.096,
      9.76,
      -66.413,
      -27.675,
      9.76,
      -66.413,
      -27.675,
      11.985,
      -66.413,
      -27.675,
      11.985,
      -67.308,
      -28.096,
      11.985,
      -67.308,
      -28.096,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_885",
    "polygon": [
      -24.222,
      -7.822,
      16.5,
      -23.292,
      -7.384,
      16.5,
      -23.292,
      -7.384,
      18.73,
      -23.292,
      -7.384,
      18.73,
      -24.222,
      -7.822,
      18.73,
      -24.222,
      -7.822,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_417",
    "polygon": [
      -16.891,
      -23.809,
      9.76,
      -17.823,
      -24.248,
      9.76,
      -17.823,
      -24.248,
      11.99,
      -17.823,
      -24.248,
      11.99,
      -16.891,
      -23.809,
      11.99,
      -16.891,
      -23.809,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1092",
    "polygon": [
      -29.52,
      -10.315,
      19.87,
      -28.591,
      -9.878,
      19.87,
      -28.591,
      -9.878,
      22.1,
      -28.591,
      -9.878,
      22.1,
      -29.52,
      -10.315,
      22.1,
      -29.52,
      -10.315,
      19.87
    ]
  },
  {
    "ID": "DINHQ_EG_92",
    "polygon": [
      18.622,
      -7.014,
      0.883,
      16.418,
      -8.051,
      0.883,
      16.418,
      -8.051,
      1.833,
      16.418,
      -8.051,
      1.833,
      18.622,
      -7.014,
      1.833,
      18.622,
      -7.014,
      0.883
    ],
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_3.OG_620",
    "polygon": [
      -16.891,
      -23.809,
      13.13,
      -17.823,
      -24.248,
      13.13,
      -17.823,
      -24.248,
      15.36,
      -17.823,
      -24.248,
      15.36,
      -16.891,
      -23.809,
      15.36,
      -16.891,
      -23.809,
      13.13
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_252",
    "polygon": [
      7.78,
      7.237,
      6.39,
      8.709,
      7.675,
      6.39,
      8.709,
      7.675,
      8.62,
      8.709,
      7.675,
      8.62,
      7.78,
      7.237,
      8.62,
      7.78,
      7.237,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_749",
    "polygon": [
      -63.707,
      -26.402,
      13.13,
      -62.811,
      -25.98,
      13.13,
      -62.811,
      -25.98,
      15.355,
      -62.811,
      -25.98,
      15.355,
      -63.707,
      -26.402,
      15.355,
      -63.707,
      -26.402,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_94",
    "polygon": [
      21.436,
      -5.69,
      0.883,
      19.39,
      -6.652,
      0.883,
      19.39,
      -6.652,
      1.833,
      19.39,
      -6.652,
      1.833,
      21.436,
      -5.69,
      1.833,
      21.436,
      -5.69,
      0.883
    ]
  },
  {
    "ID": "DINHQ_3.OG_650",
    "polygon": [
      22.901,
      -5.085,
      13.13,
      21.969,
      -5.523,
      13.13,
      21.969,
      -5.523,
      15.36,
      21.969,
      -5.523,
      15.36,
      22.901,
      -5.085,
      15.36,
      22.901,
      -5.085,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_787",
    "polygon": [
      -68.171,
      -47.94,
      16.503,
      -69.067,
      -48.362,
      16.503,
      -69.067,
      -48.362,
      18.728,
      -69.067,
      -48.362,
      18.728,
      -68.171,
      -47.94,
      18.728,
      -68.171,
      -47.94,
      16.503
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 11:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_298",
    "polygon": [
      -49.788,
      -11.588,
      6.39,
      -49.378,
      -12.46,
      6.39,
      -49.378,
      -12.46,
      8.167,
      -49.378,
      -12.46,
      8.167,
      -49.788,
      -11.588,
      8.167,
      -49.788,
      -11.588,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_823",
    "polygon": [
      -16.891,
      -23.809,
      16.5,
      -17.823,
      -24.248,
      16.5,
      -17.823,
      -24.248,
      18.73,
      -17.823,
      -24.248,
      18.73,
      -16.891,
      -23.809,
      18.73,
      -16.891,
      -23.809,
      16.5
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_552",
    "polygon": [
      -69.879,
      -29.306,
      9.76,
      -68.983,
      -28.884,
      9.76,
      -68.983,
      -28.884,
      11.985,
      -68.983,
      -28.884,
      11.985,
      -69.879,
      -29.306,
      11.985,
      -69.879,
      -29.306,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1095",
    "polygon": [
      -33.247,
      -12.069,
      19.87,
      -32.318,
      -11.631,
      19.87,
      -32.318,
      -11.631,
      22.1,
      -32.318,
      -11.631,
      22.1,
      -33.247,
      -12.069,
      22.1,
      -33.247,
      -12.069,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1071",
    "polygon": [
      -1.465,
      2.887,
      19.87,
      -0.536,
      3.324,
      19.87,
      -0.536,
      3.324,
      22.1,
      -0.536,
      3.324,
      22.1,
      -1.465,
      2.887,
      22.1,
      -1.465,
      2.887,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1272",
    "polygon": [
      -1.465,
      2.887,
      23.288,
      -0.536,
      3.324,
      23.288,
      -0.536,
      3.324,
      26.818,
      -0.536,
      3.324,
      26.818,
      -1.465,
      2.887,
      26.818,
      -1.465,
      2.887,
      23.288
    ]
  },
  {
    "ID": "DINHQ_EG_150",
    "polygon": [
      -50.225,
      -10.659,
      2.484,
      -49.814,
      -11.531,
      2.484,
      -49.814,
      -11.531,
      5.25,
      -49.814,
      -11.531,
      5.25,
      -50.225,
      -10.659,
      5.25,
      -50.225,
      -10.659,
      2.484
    ]
  },
  {
    "ID": "DINHQ_EG_103",
    "polygon": [
      -39.908,
      -34.614,
      0.94,
      -43.103,
      -35.621,
      0.94,
      -43.103,
      -35.621,
      3.54,
      -43.103,
      -35.621,
      3.54,
      -39.908,
      -34.614,
      3.54,
      -39.908,
      -34.614,
      0.94
    ],
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_4.OG_778",
    "polygon": [
      -79.482,
      -53.262,
      16.503,
      -80.378,
      -53.684,
      16.503,
      -80.378,
      -53.684,
      18.728,
      -80.378,
      -53.684,
      18.728,
      -79.482,
      -53.262,
      18.728,
      -79.482,
      -53.262,
      16.503
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_67",
    "polygon": [
      -18.804,
      -24.629,
      0.881,
      -20.792,
      -25.565,
      0.881,
      -20.792,
      -25.565,
      1.831,
      -20.792,
      -25.565,
      1.831,
      -18.804,
      -24.629,
      1.831,
      -18.804,
      -24.629,
      0.881
    ],
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_EG_70",
    "polygon": [
      -13.505,
      -22.136,
      0.881,
      -15.494,
      -23.072,
      0.881,
      -15.494,
      -23.072,
      1.831,
      -15.494,
      -23.072,
      1.831,
      -13.505,
      -22.136,
      1.831,
      -13.505,
      -22.136,
      0.881
    ],
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_4.OG_826",
    "polygon": [
      -13.164,
      -22.055,
      16.5,
      -14.096,
      -22.494,
      16.5,
      -14.096,
      -22.494,
      18.73,
      -14.096,
      -22.494,
      18.73,
      -13.164,
      -22.055,
      18.73,
      -13.164,
      -22.055,
      16.5
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_1.OG_342",
    "polygon": [
      -62.173,
      -25.68,
      6.39,
      -61.277,
      -25.258,
      6.39,
      -61.277,
      -25.258,
      8.615,
      -61.277,
      -25.258,
      8.615,
      -62.173,
      -25.68,
      8.615,
      -62.173,
      -25.68,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_810",
    "polygon": [
      -34.362,
      -32.031,
      16.5,
      -35.294,
      -32.469,
      16.5,
      -35.294,
      -32.469,
      18.73,
      -35.294,
      -32.469,
      18.73,
      -34.362,
      -32.031,
      18.73,
      -34.362,
      -32.031,
      16.5
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 13:00": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_868",
    "polygon": [
      -1.465,
      2.887,
      16.5,
      -0.536,
      3.324,
      16.5,
      -0.536,
      3.324,
      18.73,
      -0.536,
      3.324,
      18.73,
      -1.465,
      2.887,
      18.73,
      -1.465,
      2.887,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_472",
    "polygon": [
      -14.702,
      -3.342,
      9.76,
      -13.773,
      -2.905,
      9.76,
      -13.773,
      -2.905,
      11.99,
      -13.773,
      -2.905,
      11.99,
      -14.702,
      -3.342,
      11.99,
      -14.702,
      -3.342,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_261",
    "polygon": [
      -4.105,
      1.645,
      6.39,
      -3.176,
      2.082,
      6.39,
      -3.176,
      2.082,
      8.62,
      -3.176,
      2.082,
      8.62,
      -4.105,
      1.645,
      8.62,
      -4.105,
      1.645,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_928",
    "polygon": [
      -72.959,
      2.912,
      16.5,
      -70.94,
      3.863,
      16.5,
      -70.94,
      3.863,
      18.28,
      -70.94,
      3.863,
      18.28,
      -72.959,
      2.912,
      18.28,
      -72.959,
      2.912,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_799",
    "polygon": [
      -53.329,
      -40.956,
      16.503,
      -53.645,
      -41.105,
      16.503,
      -53.645,
      -41.105,
      18.728,
      -53.645,
      -41.105,
      18.728,
      -53.329,
      -40.956,
      18.728,
      -53.329,
      -40.956,
      16.503
    ],
    "11-07 11:00": 1,
    "11-07 11:15": 1
  },
  {
    "ID": "DINHQ_1.OG_248",
    "polygon": [
      13.055,
      9.72,
      6.39,
      13.984,
      10.157,
      6.39,
      13.984,
      10.157,
      8.62,
      13.984,
      10.157,
      8.62,
      13.055,
      9.72,
      8.62,
      13.055,
      9.72,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_265",
    "polygon": [
      -9.403,
      -0.849,
      6.39,
      -8.474,
      -0.411,
      6.39,
      -8.474,
      -0.411,
      8.62,
      -8.474,
      -0.411,
      8.62,
      -9.403,
      -0.849,
      8.62,
      -9.403,
      -0.849,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_486",
    "polygon": [
      -33.247,
      -12.069,
      9.76,
      -32.318,
      -11.631,
      9.76,
      -32.318,
      -11.631,
      11.99,
      -32.318,
      -11.631,
      11.99,
      -33.247,
      -12.069,
      11.99,
      -33.247,
      -12.069,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_401",
    "polygon": [
      -38.076,
      -33.778,
      9.76,
      -38.854,
      -34.144,
      9.76,
      -38.854,
      -34.144,
      11.99,
      -38.854,
      -34.144,
      11.99,
      -38.076,
      -33.778,
      11.99,
      -38.076,
      -33.778,
      9.76
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1
  },
  {
    "ID": "DINHQ_2.OG_435",
    "polygon": [
      6.954,
      -12.589,
      9.76,
      6.022,
      -13.027,
      9.76,
      6.022,
      -13.027,
      11.99,
      6.022,
      -13.027,
      11.99,
      6.954,
      -12.589,
      11.99,
      6.954,
      -12.589,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_683",
    "polygon": [
      -25.299,
      -8.329,
      13.13,
      -24.37,
      -7.891,
      13.13,
      -24.37,
      -7.891,
      15.36,
      -24.37,
      -7.891,
      15.36,
      -25.299,
      -8.329,
      15.36,
      -25.299,
      -8.329,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_543",
    "polygon": [
      -61.315,
      -22.904,
      9.76,
      -61.725,
      -22.032,
      9.76,
      -61.725,
      -22.032,
      11.537,
      -61.725,
      -22.032,
      11.537,
      -61.315,
      -22.904,
      11.537,
      -61.315,
      -22.904,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_838",
    "polygon": [
      2.734,
      -14.575,
      16.5,
      1.802,
      -15.013,
      16.5,
      1.802,
      -15.013,
      18.73,
      1.802,
      -15.013,
      18.73,
      2.734,
      -14.575,
      18.73,
      2.734,
      -14.575,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_479",
    "polygon": [
      -24.222,
      -7.822,
      9.76,
      -23.292,
      -7.384,
      9.76,
      -23.292,
      -7.384,
      11.99,
      -23.292,
      -7.384,
      11.99,
      -24.222,
      -7.822,
      11.99,
      -24.222,
      -7.822,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_674",
    "polygon": [
      -13.624,
      -2.835,
      13.13,
      -12.695,
      -2.398,
      13.13,
      -12.695,
      -2.398,
      15.36,
      -12.695,
      -2.398,
      15.36,
      -13.624,
      -2.835,
      15.36,
      -13.624,
      -2.835,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_979",
    "polygon": [
      -82.051,
      -54.472,
      19.873,
      -82.947,
      -54.893,
      19.873,
      -82.947,
      -54.893,
      22.098,
      -82.947,
      -54.893,
      22.098,
      -82.051,
      -54.472,
      22.098,
      -82.051,
      -54.472,
      19.873
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 10:00": 1,
    "11-07 10:45": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_467",
    "polygon": [
      -8.326,
      -0.341,
      9.76,
      -7.397,
      0.096,
      9.76,
      -7.397,
      0.096,
      11.99,
      -7.397,
      0.096,
      11.99,
      -8.326,
      -0.341,
      11.99,
      -8.326,
      -0.341,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1084",
    "polygon": [
      -18.923,
      -5.328,
      19.87,
      -17.994,
      -4.891,
      19.87,
      -17.994,
      -4.891,
      22.1,
      -17.994,
      -4.891,
      22.1,
      -18.923,
      -5.328,
      22.1,
      -18.923,
      -5.328,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_727",
    "polygon": [
      -72.808,
      1.52,
      13.13,
      -73.337,
      2.645,
      13.13,
      -73.337,
      2.645,
      14.907,
      -73.337,
      2.645,
      14.907,
      -72.808,
      1.52,
      14.907,
      -72.808,
      1.52,
      13.13
    ],
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_3.OG_610",
    "polygon": [
      -30.137,
      -30.043,
      13.13,
      -31.069,
      -30.481,
      13.13,
      -31.069,
      -30.481,
      15.36,
      -31.069,
      -30.481,
      15.36,
      -30.137,
      -30.043,
      15.36,
      -30.137,
      -30.043,
      13.13
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_852",
    "polygon": [
      21.379,
      -5.801,
      16.5,
      20.447,
      -6.24,
      16.5,
      20.447,
      -6.24,
      18.73,
      20.447,
      -6.24,
      18.73,
      21.379,
      -5.801,
      18.73,
      21.379,
      -5.801,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_324",
    "polygon": [
      -70.511,
      -3.361,
      6.39,
      -70.922,
      -2.488,
      6.39,
      -70.922,
      -2.488,
      8.167,
      -70.922,
      -2.488,
      8.167,
      -70.511,
      -3.361,
      8.167,
      -70.511,
      -3.361,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_679",
    "polygon": [
      -20.001,
      -5.835,
      13.13,
      -19.071,
      -5.398,
      13.13,
      -19.071,
      -5.398,
      15.36,
      -19.071,
      -5.398,
      15.36,
      -20.001,
      -5.835,
      15.36,
      -20.001,
      -5.835,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_632",
    "polygon": [
      -0.993,
      -16.328,
      13.13,
      -1.925,
      -16.767,
      13.13,
      -1.925,
      -16.767,
      15.36,
      -1.925,
      -16.767,
      15.36,
      -0.993,
      -16.328,
      15.36,
      -0.993,
      -16.328,
      13.13
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1132",
    "polygon": [
      -72.373,
      0.597,
      19.87,
      -72.784,
      1.469,
      19.87,
      -72.784,
      1.469,
      21.647,
      -72.784,
      1.469,
      21.647,
      -72.373,
      0.597,
      21.647,
      -72.373,
      0.597,
      19.87
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_3.OG_663",
    "polygon": [
      1.313,
      4.195,
      13.13,
      2.367,
      4.691,
      13.13,
      2.367,
      4.691,
      15.36,
      2.367,
      4.691,
      15.36,
      1.313,
      4.195,
      15.36,
      1.313,
      4.195,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_539",
    "polygon": [
      -63.614,
      -18.018,
      9.76,
      -64.024,
      -17.146,
      9.76,
      -64.024,
      -17.146,
      11.537,
      -64.024,
      -17.146,
      11.537,
      -63.614,
      -18.018,
      11.537,
      -63.614,
      -18.018,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_290",
    "polygon": [
      -42.777,
      -16.553,
      6.39,
      -41.848,
      -16.116,
      6.39,
      -41.848,
      -16.116,
      8.62,
      -41.848,
      -16.116,
      8.62,
      -42.777,
      -16.553,
      8.62,
      -42.777,
      -16.553,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1070",
    "polygon": [
      -0.383,
      3.396,
      19.87,
      0.671,
      3.892,
      19.87,
      0.671,
      3.892,
      22.1,
      0.671,
      3.892,
      22.1,
      -0.383,
      3.396,
      22.1,
      -0.383,
      3.396,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_464",
    "polygon": [
      -4.105,
      1.645,
      9.76,
      -3.176,
      2.082,
      9.76,
      -3.176,
      2.082,
      11.99,
      -3.176,
      2.082,
      11.99,
      -4.105,
      1.645,
      11.99,
      -4.105,
      1.645,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_609",
    "polygon": [
      -31.711,
      -30.783,
      13.13,
      -32.643,
      -31.222,
      13.13,
      -32.643,
      -31.222,
      15.36,
      -32.643,
      -31.222,
      15.36,
      -31.711,
      -30.783,
      15.36,
      -31.711,
      -30.783,
      13.13
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1360",
    "polygon": [
      -67.308,
      -28.096,
      23.288,
      -66.413,
      -27.675,
      23.288,
      -66.413,
      -27.675,
      26.818,
      -66.413,
      -27.675,
      26.818,
      -67.308,
      -28.096,
      26.818,
      -67.308,
      -28.096,
      23.288
    ]
  },
  {
    "ID": "DINHQ_4.OG_911",
    "polygon": [
      -52.088,
      -6.701,
      16.5,
      -51.677,
      -7.573,
      16.5,
      -51.677,
      -7.573,
      18.277,
      -51.677,
      -7.573,
      18.277,
      -52.088,
      -6.701,
      18.277,
      -52.088,
      -6.701,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_385",
    "polygon": [
      -63.032,
      -45.522,
      9.763,
      -63.928,
      -45.943,
      9.763,
      -63.928,
      -45.943,
      11.988,
      -63.928,
      -45.943,
      11.988,
      -63.032,
      -45.522,
      11.988,
      -63.032,
      -45.522,
      9.763
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1351",
    "polygon": [
      -62.464,
      -20.461,
      23.288,
      -62.875,
      -19.589,
      23.288,
      -62.875,
      -19.589,
      26.818,
      -62.875,
      -19.589,
      26.818,
      -62.464,
      -20.461,
      26.818,
      -62.464,
      -20.461,
      23.288
    ]
  },
  {
    "ID": "DINHQ_2.OG_483",
    "polygon": [
      -29.52,
      -10.315,
      9.76,
      -28.591,
      -9.878,
      9.76,
      -28.591,
      -9.878,
      11.99,
      -28.591,
      -9.878,
      11.99,
      -29.52,
      -10.315,
      11.99,
      -29.52,
      -10.315,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_414",
    "polygon": [
      -21.113,
      -25.796,
      9.76,
      -22.045,
      -26.235,
      9.76,
      -22.045,
      -26.235,
      11.99,
      -22.045,
      -26.235,
      11.99,
      -21.113,
      -25.796,
      11.99,
      -21.113,
      -25.796,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1
  },
  {
    "ID": "DINHQ_2.OG_564",
    "polygon": [
      -85.297,
      -36.561,
      9.76,
      -84.401,
      -36.14,
      9.76,
      -84.401,
      -36.14,
      11.985,
      -84.401,
      -36.14,
      11.985,
      -85.297,
      -36.561,
      11.985,
      -85.297,
      -36.561,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_488",
    "polygon": [
      -35.897,
      -13.315,
      9.76,
      -34.967,
      -12.878,
      9.76,
      -34.967,
      -12.878,
      11.99,
      -34.967,
      -12.878,
      11.99,
      -35.897,
      -13.315,
      11.99,
      -35.897,
      -13.315,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_764",
    "polygon": [
      -81.692,
      -34.865,
      13.13,
      -80.796,
      -34.443,
      13.13,
      -80.796,
      -34.443,
      15.355,
      -80.796,
      -34.443,
      15.355,
      -81.692,
      -34.865,
      15.355,
      -81.692,
      -34.865,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_791",
    "polygon": [
      -63.032,
      -45.522,
      16.503,
      -63.928,
      -45.943,
      16.503,
      -63.928,
      -45.943,
      18.728,
      -63.928,
      -45.943,
      18.728,
      -63.032,
      -45.522,
      18.728,
      -63.032,
      -45.522,
      16.503
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_189",
    "polygon": [
      -53.784,
      -41.17,
      6.393,
      -54.68,
      -41.591,
      6.393,
      -54.68,
      -41.591,
      8.618,
      -54.68,
      -41.591,
      8.618,
      -53.784,
      -41.17,
      8.618,
      -53.784,
      -41.17,
      6.393
    ],
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_224",
    "polygon": [
      -3.641,
      -17.574,
      6.39,
      -4.573,
      -18.013,
      6.39,
      -4.573,
      -18.013,
      8.62,
      -4.573,
      -18.013,
      8.62,
      -3.641,
      -17.574,
      8.62,
      -3.641,
      -17.574,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_4.OG_937",
    "polygon": [
      -68.212,
      -8.247,
      16.5,
      -68.622,
      -7.374,
      16.5,
      -68.622,
      -7.374,
      18.277,
      -68.622,
      -7.374,
      18.277,
      -68.212,
      -8.247,
      18.277,
      -68.212,
      -8.247,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1208",
    "polygon": [
      -40.822,
      -38.653,
      23.288,
      -42.939,
      -39.65,
      23.288,
      -42.939,
      -39.65,
      26.818,
      -42.939,
      -39.65,
      26.818,
      -40.822,
      -38.653,
      26.818,
      -40.822,
      -38.653,
      23.288
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_1.OG_284",
    "polygon": [
      -34.819,
      -12.808,
      6.39,
      -33.89,
      -12.371,
      6.39,
      -33.89,
      -12.371,
      8.62,
      -33.89,
      -12.371,
      8.62,
      -34.819,
      -12.808,
      8.62,
      -34.819,
      -12.808,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_75",
    "polygon": [
      -2.908,
      -17.149,
      1.885,
      -6.931,
      -19.042,
      1.885,
      -6.931,
      -19.042,
      4.172,
      -6.931,
      -19.042,
      4.172,
      -2.908,
      -17.149,
      4.172,
      -2.908,
      -17.149,
      1.885
    ],
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_3.OG_571",
    "polygon": [
      -84.621,
      -55.681,
      13.133,
      -85.517,
      -56.102,
      13.133,
      -85.517,
      -56.102,
      15.358,
      -85.517,
      -56.102,
      15.358,
      -84.621,
      -55.681,
      15.358,
      -84.621,
      -55.681,
      13.133
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_EG_73",
    "polygon": [
      -8.206,
      -19.643,
      0.881,
      -10.195,
      -20.578,
      0.881,
      -10.195,
      -20.578,
      1.831,
      -10.195,
      -20.578,
      1.831,
      -8.206,
      -19.643,
      1.831,
      -8.206,
      -19.643,
      0.881
    ],
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_1.OG_347",
    "polygon": [
      -67.308,
      -28.096,
      6.39,
      -66.413,
      -27.675,
      6.39,
      -66.413,
      -27.675,
      8.615,
      -66.413,
      -27.675,
      8.615,
      -67.308,
      -28.096,
      8.615,
      -67.308,
      -28.096,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_619",
    "polygon": [
      -18.465,
      -24.55,
      13.13,
      -19.397,
      -24.989,
      13.13,
      -19.397,
      -24.989,
      15.36,
      -19.397,
      -24.989,
      15.36,
      -18.465,
      -24.55,
      15.36,
      -18.465,
      -24.55,
      13.13
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_1.OG_297",
    "polygon": [
      -49.074,
      -13.105,
      6.39,
      -48.664,
      -13.977,
      6.39,
      -48.664,
      -13.977,
      8.167,
      -48.664,
      -13.977,
      8.167,
      -49.074,
      -13.105,
      8.167,
      -49.074,
      -13.105,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_447",
    "polygon": [
      22.901,
      -5.085,
      9.76,
      21.969,
      -5.523,
      9.76,
      21.969,
      -5.523,
      11.99,
      21.969,
      -5.523,
      11.99,
      22.901,
      -5.085,
      11.99,
      22.901,
      -5.085,
      9.76
    ],
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_2.OG_392",
    "polygon": [
      -53.784,
      -41.17,
      9.763,
      -54.68,
      -41.591,
      9.763,
      -54.68,
      -41.591,
      11.988,
      -54.68,
      -41.591,
      11.988,
      -53.784,
      -41.17,
      11.988,
      -53.784,
      -41.17,
      9.763
    ],
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_939",
    "polygon": [
      -67.062,
      -10.689,
      16.5,
      -67.473,
      -9.817,
      16.5,
      -67.473,
      -9.817,
      18.277,
      -67.473,
      -9.817,
      18.277,
      -67.062,
      -10.689,
      18.277,
      -67.062,
      -10.689,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_672",
    "polygon": [
      -10.975,
      -1.588,
      13.13,
      -10.046,
      -1.151,
      13.13,
      -10.046,
      -1.151,
      15.36,
      -10.046,
      -1.151,
      15.36,
      -10.975,
      -1.588,
      15.36,
      -10.975,
      -1.588,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_136",
    "polygon": [
      -63.889,
      7.181,
      2.485,
      -62.664,
      7.757,
      2.485,
      -62.664,
      7.757,
      5.25,
      -62.664,
      7.757,
      5.25,
      -63.889,
      7.181,
      5.25,
      -63.889,
      7.181,
      2.485
    ]
  },
  {
    "ID": "DINHQ_4.OG_806",
    "polygon": [
      -38.743,
      -35.061,
      16.5,
      -37.764,
      -37.141,
      16.5,
      -37.764,
      -37.141,
      18.73,
      -37.764,
      -37.141,
      18.73,
      -38.743,
      -35.061,
      18.73,
      -38.743,
      -35.061,
      16.5
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_3.OG_574",
    "polygon": [
      -81.02,
      -53.986,
      13.133,
      -81.916,
      -54.408,
      13.133,
      -81.916,
      -54.408,
      15.358,
      -81.916,
      -54.408,
      15.358,
      -81.02,
      -53.986,
      15.358,
      -81.02,
      -53.986,
      13.133
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_653",
    "polygon": [
      14.626,
      10.459,
      13.13,
      15.555,
      10.896,
      13.13,
      15.555,
      10.896,
      15.36,
      15.555,
      10.896,
      15.36,
      14.626,
      10.459,
      15.36,
      14.626,
      10.459,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_730",
    "polygon": [
      -70.511,
      -3.361,
      13.13,
      -70.922,
      -2.488,
      13.13,
      -70.922,
      -2.488,
      14.907,
      -70.922,
      -2.488,
      14.907,
      -70.511,
      -3.361,
      14.907,
      -70.511,
      -3.361,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_238",
    "polygon": [
      15.075,
      -8.767,
      6.39,
      14.143,
      -9.206,
      6.39,
      14.143,
      -9.206,
      8.62,
      14.143,
      -9.206,
      8.62,
      15.075,
      -8.767,
      8.62,
      15.075,
      -8.767,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_4.OG_923",
    "polygon": [
      -58.986,
      7.958,
      16.5,
      -58.576,
      7.086,
      16.5,
      -58.576,
      7.086,
      18.277,
      -58.576,
      7.086,
      18.277,
      -58.986,
      7.958,
      18.277,
      -58.986,
      7.958,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_167",
    "polygon": [
      -82.051,
      -54.472,
      6.393,
      -82.947,
      -54.893,
      6.393,
      -82.947,
      -54.893,
      8.618,
      -82.947,
      -54.893,
      8.618,
      -82.051,
      -54.472,
      8.618,
      -82.051,
      -54.472,
      6.393
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_1.OG_197",
    "polygon": [
      -38.743,
      -35.061,
      6.39,
      -37.764,
      -37.141,
      6.39,
      -37.764,
      -37.141,
      8.62,
      -37.764,
      -37.141,
      8.62,
      -38.743,
      -35.061,
      8.62,
      -38.743,
      -35.061,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_267",
    "polygon": [
      -12.053,
      -2.095,
      6.39,
      -11.124,
      -1.658,
      6.39,
      -11.124,
      -1.658,
      8.62,
      -11.124,
      -1.658,
      8.62,
      -12.053,
      -2.095,
      8.62,
      -12.053,
      -2.095,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_627",
    "polygon": [
      -7.865,
      -19.562,
      13.13,
      -8.797,
      -20.001,
      13.13,
      -8.797,
      -20.001,
      15.36,
      -8.797,
      -20.001,
      15.36,
      -7.865,
      -19.562,
      15.36,
      -7.865,
      -19.562,
      13.13
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_3.OG_753",
    "polygon": [
      -67.308,
      -28.096,
      13.13,
      -66.413,
      -27.675,
      13.13,
      -66.413,
      -27.675,
      15.355,
      -66.413,
      -27.675,
      15.355,
      -67.308,
      -28.096,
      15.355,
      -67.308,
      -28.096,
      13.13
    ]
  },
  {
    "ID": "DINHQ_6.OG_1324",
    "polygon": [
      -57.122,
      3.998,
      23.288,
      -56.712,
      3.125,
      23.288,
      -56.712,
      3.125,
      26.818,
      -56.712,
      3.125,
      26.818,
      -57.122,
      3.998,
      26.818,
      -57.122,
      3.998,
      23.288
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_3.OG_742",
    "polygon": [
      -63.614,
      -18.018,
      13.13,
      -64.024,
      -17.146,
      13.13,
      -64.024,
      -17.146,
      14.907,
      -64.024,
      -17.146,
      14.907,
      -63.614,
      -18.018,
      14.907,
      -63.614,
      -18.018,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_1044",
    "polygon": [
      6.954,
      -12.589,
      19.87,
      6.022,
      -13.027,
      19.87,
      6.022,
      -13.027,
      22.1,
      6.022,
      -13.027,
      22.1,
      6.954,
      -12.589,
      22.1,
      6.954,
      -12.589,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_166",
    "polygon": [
      -83.59,
      -55.195,
      6.393,
      -84.485,
      -55.617,
      6.393,
      -84.485,
      -55.617,
      8.618,
      -84.485,
      -55.617,
      8.618,
      -83.59,
      -55.195,
      8.618,
      -83.59,
      -55.195,
      6.393
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_774",
    "polygon": [
      -84.621,
      -55.681,
      16.503,
      -85.517,
      -56.102,
      16.503,
      -85.517,
      -56.102,
      18.728,
      -85.517,
      -56.102,
      18.728,
      -84.621,
      -55.681,
      18.728,
      -84.621,
      -55.681,
      16.503
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_4.OG_879",
    "polygon": [
      -16.274,
      -4.081,
      16.5,
      -15.344,
      -3.644,
      16.5,
      -15.344,
      -3.644,
      18.73,
      -15.344,
      -3.644,
      18.73,
      -16.274,
      -4.081,
      18.73,
      -16.274,
      -4.081,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_816",
    "polygon": [
      -26.412,
      -28.289,
      16.5,
      -27.344,
      -28.728,
      16.5,
      -27.344,
      -28.728,
      18.73,
      -27.344,
      -28.728,
      18.73,
      -26.412,
      -28.289,
      18.73,
      -26.412,
      -28.289,
      16.5
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1
  },
  {
    "ID": "DINHQ_EG_160",
    "polygon": [
      -47.491,
      -16.467,
      2.485,
      -46.959,
      -17.598,
      2.485,
      -46.959,
      -17.598,
      3.015,
      -46.959,
      -17.598,
      3.015,
      -47.491,
      -16.467,
      3.015,
      -47.491,
      -16.467,
      2.485
    ]
  },
  {
    "ID": "DINHQ_1.OG_192",
    "polygon": [
      -46.34,
      -41.25,
      6.39,
      -48.457,
      -42.247,
      6.39,
      -48.457,
      -42.247,
      8.62,
      -48.457,
      -42.247,
      8.62,
      -46.34,
      -41.25,
      8.62,
      -46.34,
      -41.25,
      6.39
    ],
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_EG_84",
    "polygon": [
      2.391,
      -14.656,
      1.885,
      -1.632,
      -16.549,
      1.885,
      -1.632,
      -16.549,
      4.172,
      -1.632,
      -16.549,
      4.172,
      2.391,
      -14.656,
      4.172,
      2.391,
      -14.656,
      1.885
    ],
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_EG_119",
    "polygon": [
      -9.323,
      -0.807,
      2.11,
      -7.498,
      0.052,
      2.11,
      -7.498,
      0.052,
      4.38,
      -7.498,
      0.052,
      4.38,
      -9.323,
      -0.807,
      4.38,
      -9.323,
      -0.807,
      2.11
    ]
  },
  {
    "ID": "DINHQ_2.OG_398",
    "polygon": [
      -40.822,
      -38.653,
      9.76,
      -42.939,
      -39.65,
      9.76,
      -42.939,
      -39.65,
      11.99,
      -42.939,
      -39.65,
      11.99,
      -40.822,
      -38.653,
      11.99,
      -40.822,
      -38.653,
      9.76
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1335",
    "polygon": [
      -71.661,
      -0.918,
      23.288,
      -72.071,
      -0.045,
      23.288,
      -72.071,
      -0.045,
      26.818,
      -72.071,
      -0.045,
      26.818,
      -71.661,
      -0.918,
      26.818,
      -71.661,
      -0.918,
      23.288
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_1.OG_187",
    "polygon": [
      -56.354,
      -42.379,
      6.393,
      -57.25,
      -42.801,
      6.393,
      -57.25,
      -42.801,
      8.618,
      -57.25,
      -42.801,
      8.618,
      -56.354,
      -42.379,
      8.618,
      -56.354,
      -42.379,
      6.393
    ],
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_642",
    "polygon": [
      12.46,
      -9.998,
      13.13,
      11.528,
      -10.436,
      13.13,
      11.528,
      -10.436,
      15.36,
      11.528,
      -10.436,
      15.36,
      12.46,
      -9.998,
      15.36,
      12.46,
      -9.998,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_EG_137",
    "polygon": [
      -58.986,
      7.96,
      2.484,
      -58.575,
      7.088,
      2.484,
      -58.575,
      7.088,
      5.25,
      -58.575,
      7.088,
      5.25,
      -58.986,
      7.96,
      5.25,
      -58.986,
      7.96,
      2.484
    ]
  },
  {
    "ID": "DINHQ_1.OG_329",
    "polygon": [
      -67.776,
      -9.172,
      6.39,
      -68.187,
      -8.3,
      6.39,
      -68.187,
      -8.3,
      8.167,
      -68.187,
      -8.3,
      8.167,
      -67.776,
      -9.172,
      8.167,
      -67.776,
      -9.172,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_591",
    "polygon": [
      -58.924,
      -43.589,
      13.133,
      -59.82,
      -44.01,
      13.133,
      -59.82,
      -44.01,
      15.358,
      -59.82,
      -44.01,
      15.358,
      -58.924,
      -43.589,
      15.358,
      -58.924,
      -43.589,
      13.133
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_718",
    "polygon": [
      -57.836,
      5.515,
      13.13,
      -57.426,
      4.643,
      13.13,
      -57.426,
      4.643,
      14.907,
      -57.426,
      4.643,
      14.907,
      -57.836,
      5.515,
      14.907,
      -57.836,
      5.515,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_926",
    "polygon": [
      -63.886,
      7.181,
      16.5,
      -62.661,
      7.757,
      16.5,
      -62.661,
      7.757,
      18.28,
      -62.661,
      7.757,
      18.28,
      -63.886,
      7.181,
      18.28,
      -63.886,
      7.181,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_808",
    "polygon": [
      -37.01,
      -33.277,
      16.5,
      -37.942,
      -33.715,
      16.5,
      -37.942,
      -33.715,
      18.73,
      -37.942,
      -33.715,
      18.73,
      -37.01,
      -33.277,
      18.73,
      -37.01,
      -33.277,
      16.5
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1
  },
  {
    "ID": "DINHQ_1.OG_207",
    "polygon": [
      -26.412,
      -28.289,
      6.39,
      -27.344,
      -28.728,
      6.39,
      -27.344,
      -28.728,
      8.62,
      -27.344,
      -28.728,
      8.62,
      -26.412,
      -28.289,
      8.62,
      -26.412,
      -28.289,
      6.39
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1
  },
  {
    "ID": "DINHQ_2.OG_406",
    "polygon": [
      -31.711,
      -30.783,
      9.76,
      -32.643,
      -31.222,
      9.76,
      -32.643,
      -31.222,
      11.99,
      -32.643,
      -31.222,
      11.99,
      -31.711,
      -30.783,
      11.99,
      -31.711,
      -30.783,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1
  },
  {
    "ID": "DINHQ_4.OG_800",
    "polygon": [
      -51.565,
      -43.626,
      16.506,
      -52.543,
      -41.547,
      16.506,
      -52.543,
      -41.547,
      18.736,
      -52.543,
      -41.547,
      18.736,
      -51.565,
      -43.626,
      18.736,
      -51.565,
      -43.626,
      16.506
    ],
    "11-07 11:15": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_1.OG_320",
    "polygon": [
      -72.373,
      0.597,
      6.39,
      -72.784,
      1.469,
      6.39,
      -72.784,
      1.469,
      8.167,
      -72.784,
      1.469,
      8.167,
      -72.373,
      0.597,
      8.167,
      -72.373,
      0.597,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_713",
    "polygon": [
      -54.823,
      -0.889,
      13.13,
      -54.412,
      -1.761,
      13.13,
      -54.412,
      -1.761,
      14.907,
      -54.412,
      -1.761,
      14.907,
      -54.823,
      -0.889,
      14.907,
      -54.823,
      -0.889,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_978",
    "polygon": [
      -83.59,
      -55.195,
      19.873,
      -84.485,
      -55.617,
      19.873,
      -84.485,
      -55.617,
      22.098,
      -84.485,
      -55.617,
      22.098,
      -83.59,
      -55.195,
      22.098,
      -83.59,
      -55.195,
      19.873
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 10:45": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1131",
    "polygon": [
      -72.959,
      2.912,
      19.87,
      -70.94,
      3.863,
      19.87,
      -70.94,
      3.863,
      21.65,
      -70.94,
      3.863,
      21.65,
      -72.959,
      2.912,
      21.65,
      -72.959,
      2.912,
      19.87
    ]
  },
  {
    "ID": "DINHQ_4.OG_913",
    "polygon": [
      -53.238,
      -4.258,
      16.5,
      -52.827,
      -5.13,
      16.5,
      -52.827,
      -5.13,
      18.277,
      -52.827,
      -5.13,
      18.277,
      -53.238,
      -4.258,
      18.277,
      -53.238,
      -4.258,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_952",
    "polygon": [
      -63.707,
      -26.402,
      16.5,
      -62.811,
      -25.98,
      16.5,
      -62.811,
      -25.98,
      18.725,
      -62.811,
      -25.98,
      18.725,
      -63.707,
      -26.402,
      18.725,
      -63.707,
      -26.402,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_191",
    "polygon": [
      -51.565,
      -43.626,
      6.396,
      -52.543,
      -41.547,
      6.396,
      -52.543,
      -41.547,
      8.626,
      -52.543,
      -41.547,
      8.626,
      -51.565,
      -43.626,
      8.626,
      -51.565,
      -43.626,
      6.396
    ],
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_2.OG_450",
    "polygon": [
      14.626,
      10.459,
      9.76,
      15.555,
      10.896,
      9.76,
      15.555,
      10.896,
      11.99,
      15.555,
      10.896,
      11.99,
      14.626,
      10.459,
      11.99,
      14.626,
      10.459,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_317",
    "polygon": [
      -63.886,
      7.181,
      6.39,
      -62.661,
      7.757,
      6.39,
      -62.661,
      7.757,
      8.17,
      -62.661,
      7.757,
      8.17,
      -63.886,
      7.181,
      8.17,
      -63.886,
      7.181,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_52",
    "polygon": [
      -62.765,
      -45.311,
      0.935,
      -64.674,
      -46.21,
      0.935,
      -64.674,
      -46.21,
      1.885,
      -64.674,
      -46.21,
      1.885,
      -62.765,
      -45.311,
      1.885,
      -62.765,
      -45.311,
      0.935
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_109",
    "polygon": [
      -47.358,
      -37.459,
      0.94,
      -48.503,
      -38.175,
      0.94,
      -48.503,
      -38.175,
      3.54,
      -48.503,
      -38.175,
      3.54,
      -47.358,
      -37.459,
      3.54,
      -47.358,
      -37.459,
      0.94
    ]
  },
  {
    "ID": "DINHQ_4.OG_812",
    "polygon": [
      -31.711,
      -30.783,
      16.5,
      -32.643,
      -31.222,
      16.5,
      -32.643,
      -31.222,
      18.73,
      -32.643,
      -31.222,
      18.73,
      -31.711,
      -30.783,
      18.73,
      -31.711,
      -30.783,
      16.5
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_832",
    "polygon": [
      -5.215,
      -18.315,
      16.5,
      -6.147,
      -18.754,
      16.5,
      -6.147,
      -18.754,
      18.73,
      -6.147,
      -18.754,
      18.73,
      -5.215,
      -18.315,
      18.73,
      -5.215,
      -18.315,
      16.5
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_690",
    "polygon": [
      -34.819,
      -12.808,
      13.13,
      -33.89,
      -12.371,
      13.13,
      -33.89,
      -12.371,
      15.36,
      -33.89,
      -12.371,
      15.36,
      -34.819,
      -12.808,
      15.36,
      -34.819,
      -12.808,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_243",
    "polygon": [
      21.379,
      -5.801,
      6.39,
      20.447,
      -6.24,
      6.39,
      20.447,
      -6.24,
      8.62,
      20.447,
      -6.24,
      8.62,
      21.379,
      -5.801,
      8.62,
      21.379,
      -5.801,
      6.39
    ],
    "11-07 11:00": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1097",
    "polygon": [
      -35.897,
      -13.315,
      19.87,
      -34.967,
      -12.878,
      19.87,
      -34.967,
      -12.878,
      22.1,
      -34.967,
      -12.878,
      22.1,
      -35.897,
      -13.315,
      22.1,
      -35.897,
      -13.315,
      19.87
    ]
  },
  {
    "ID": "DINHQ_EG_142",
    "polygon": [
      -55.973,
      1.557,
      2.484,
      -55.563,
      0.685,
      2.484,
      -55.563,
      0.685,
      5.25,
      -55.563,
      0.685,
      5.25,
      -55.973,
      1.557,
      5.25,
      -55.973,
      1.557,
      2.484
    ]
  },
  {
    "ID": "DINHQ_3.OG_626",
    "polygon": [
      -8.939,
      -20.067,
      13.13,
      -9.871,
      -20.506,
      13.13,
      -9.871,
      -20.506,
      15.36,
      -9.871,
      -20.506,
      15.36,
      -8.939,
      -20.067,
      15.36,
      -8.939,
      -20.067,
      13.13
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_1.OG_349",
    "polygon": [
      -69.879,
      -29.306,
      6.39,
      -68.983,
      -28.884,
      6.39,
      -68.983,
      -28.884,
      8.615,
      -68.983,
      -28.884,
      8.615,
      -69.879,
      -29.306,
      8.615,
      -69.879,
      -29.306,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1148",
    "polygon": [
      -63.614,
      -18.018,
      19.87,
      -64.024,
      -17.146,
      19.87,
      -64.024,
      -17.146,
      21.647,
      -64.024,
      -17.146,
      21.647,
      -63.614,
      -18.018,
      21.647,
      -63.614,
      -18.018,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_502",
    "polygon": [
      -50.224,
      -10.662,
      9.76,
      -49.814,
      -11.534,
      9.76,
      -49.814,
      -11.534,
      11.537,
      -49.814,
      -11.534,
      11.537,
      -50.224,
      -10.662,
      11.537,
      -50.224,
      -10.662,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_471",
    "polygon": [
      -13.624,
      -2.835,
      9.76,
      -12.695,
      -2.398,
      9.76,
      -12.695,
      -2.398,
      11.99,
      -12.695,
      -2.398,
      11.99,
      -13.624,
      -2.835,
      11.99,
      -13.624,
      -2.835,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_980",
    "polygon": [
      -81.02,
      -53.986,
      19.873,
      -81.916,
      -54.408,
      19.873,
      -81.916,
      -54.408,
      22.098,
      -81.916,
      -54.408,
      22.098,
      -81.02,
      -53.986,
      22.098,
      -81.02,
      -53.986,
      19.873
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1157",
    "polygon": [
      -64.739,
      -26.887,
      19.87,
      -63.843,
      -26.466,
      19.87,
      -63.843,
      -26.466,
      22.095,
      -63.843,
      -26.466,
      22.095,
      -64.739,
      -26.887,
      22.095,
      -64.739,
      -26.887,
      19.87
    ]
  },
  {
    "ID": "DINHQ_1.OG_306",
    "polygon": [
      -54.387,
      -1.815,
      6.39,
      -53.977,
      -2.687,
      6.39,
      -53.977,
      -2.687,
      8.167,
      -53.977,
      -2.687,
      8.167,
      -54.387,
      -1.815,
      8.167,
      -54.387,
      -1.815,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_299",
    "polygon": [
      -50.224,
      -10.662,
      6.39,
      -49.814,
      -11.534,
      6.39,
      -49.814,
      -11.534,
      8.167,
      -49.814,
      -11.534,
      8.167,
      -50.224,
      -10.662,
      8.167,
      -50.224,
      -10.662,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_600",
    "polygon": [
      -43.581,
      -39.952,
      13.13,
      -45.698,
      -40.948,
      13.13,
      -45.698,
      -40.948,
      15.36,
      -45.698,
      -40.948,
      15.36,
      -43.581,
      -39.952,
      15.36,
      -43.581,
      -39.952,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 12:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_1.OG_294",
    "polygon": [
      -47.489,
      -16.474,
      6.39,
      -47.079,
      -17.346,
      6.39,
      -47.079,
      -17.346,
      8.167,
      -47.079,
      -17.346,
      8.167,
      -47.489,
      -16.474,
      8.167,
      -47.489,
      -16.474,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_429",
    "polygon": [
      -0.993,
      -16.328,
      9.76,
      -1.925,
      -16.767,
      9.76,
      -1.925,
      -16.767,
      11.99,
      -1.925,
      -16.767,
      11.99,
      -0.993,
      -16.328,
      11.99,
      -0.993,
      -16.328,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_1.OG_215",
    "polygon": [
      -15.812,
      -23.301,
      6.39,
      -16.744,
      -23.74,
      6.39,
      -16.744,
      -23.74,
      8.62,
      -16.744,
      -23.74,
      8.62,
      -15.812,
      -23.301,
      8.62,
      -15.812,
      -23.301,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1
  },
  {
    "ID": "DINHQ_4.OG_880",
    "polygon": [
      -17.351,
      -4.589,
      16.5,
      -16.422,
      -4.151,
      16.5,
      -16.422,
      -4.151,
      18.73,
      -16.422,
      -4.151,
      18.73,
      -17.351,
      -4.589,
      18.73,
      -17.351,
      -4.589,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_657",
    "polygon": [
      9.351,
      7.977,
      13.13,
      10.28,
      8.414,
      13.13,
      10.28,
      8.414,
      15.36,
      10.28,
      8.414,
      15.36,
      9.351,
      7.977,
      15.36,
      9.351,
      7.977,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_902",
    "polygon": [
      -46.255,
      -18.19,
      16.5,
      -45.576,
      -17.87,
      16.5,
      -45.576,
      -17.87,
      18.73,
      -45.576,
      -17.87,
      18.73,
      -46.255,
      -18.19,
      18.73,
      -46.255,
      -18.19,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_927",
    "polygon": [
      -70.299,
      4.163,
      16.5,
      -69.073,
      4.74,
      16.5,
      -69.073,
      4.74,
      18.28,
      -69.073,
      4.74,
      18.28,
      -70.299,
      4.163,
      18.28,
      -70.299,
      4.163,
      16.5
    ]
  },
  {
    "ID": "DINHQ_EG_88",
    "polygon": [
      11.096,
      -10.555,
      0.881,
      8.817,
      -11.628,
      0.881,
      8.817,
      -11.628,
      1.831,
      8.817,
      -11.628,
      1.831,
      11.096,
      -10.555,
      1.831,
      11.096,
      -10.555,
      0.881
    ]
  },
  {
    "ID": "DINHQ_2.OG_384",
    "polygon": [
      -64.064,
      -46.007,
      9.763,
      -64.959,
      -46.429,
      9.763,
      -64.959,
      -46.429,
      11.988,
      -64.959,
      -46.429,
      11.988,
      -64.064,
      -46.007,
      11.988,
      -64.064,
      -46.007,
      9.763
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1017",
    "polygon": [
      -29.063,
      -29.537,
      19.87,
      -29.995,
      -29.976,
      19.87,
      -29.995,
      -29.976,
      22.1,
      -29.995,
      -29.976,
      22.1,
      -29.063,
      -29.537,
      22.1,
      -29.063,
      -29.537,
      19.87
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_354",
    "polygon": [
      -76.552,
      -32.446,
      6.39,
      -75.656,
      -32.025,
      6.39,
      -75.656,
      -32.025,
      8.615,
      -75.656,
      -32.025,
      8.615,
      -76.552,
      -32.446,
      8.615,
      -76.552,
      -32.446,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_782",
    "polygon": [
      -74.342,
      -50.844,
      16.503,
      -75.238,
      -51.265,
      16.503,
      -75.238,
      -51.265,
      18.728,
      -75.238,
      -51.265,
      18.728,
      -74.342,
      -50.844,
      18.728,
      -74.342,
      -50.844,
      16.503
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_541",
    "polygon": [
      -62.464,
      -20.461,
      9.76,
      -62.875,
      -19.589,
      9.76,
      -62.875,
      -19.589,
      11.537,
      -62.875,
      -19.589,
      11.537,
      -62.464,
      -20.461,
      11.537,
      -62.464,
      -20.461,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_76",
    "polygon": [
      -2.908,
      -17.149,
      0.881,
      -4.897,
      -18.085,
      0.881,
      -4.897,
      -18.085,
      1.831,
      -4.897,
      -18.085,
      1.831,
      -2.908,
      -17.149,
      1.831,
      -2.908,
      -17.149,
      0.881
    ],
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_3.OG_703",
    "polygon": [
      -49.074,
      -13.105,
      13.13,
      -48.664,
      -13.977,
      13.13,
      -48.664,
      -13.977,
      14.907,
      -48.664,
      -13.977,
      14.907,
      -49.074,
      -13.105,
      14.907,
      -49.074,
      -13.105,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_715",
    "polygon": [
      -55.973,
      1.554,
      13.13,
      -55.562,
      0.682,
      13.13,
      -55.562,
      0.682,
      14.907,
      -55.562,
      0.682,
      14.907,
      -55.973,
      1.554,
      14.907,
      -55.973,
      1.554,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_686",
    "polygon": [
      -29.52,
      -10.315,
      13.13,
      -28.591,
      -9.878,
      13.13,
      -28.591,
      -9.878,
      15.36,
      -28.591,
      -9.878,
      15.36,
      -29.52,
      -10.315,
      15.36,
      -29.52,
      -10.315,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_875",
    "polygon": [
      -10.975,
      -1.588,
      16.5,
      -10.046,
      -1.151,
      16.5,
      -10.046,
      -1.151,
      18.73,
      -10.046,
      -1.151,
      18.73,
      -10.975,
      -1.588,
      18.73,
      -10.975,
      -1.588,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1320",
    "polygon": [
      -54.823,
      -0.889,
      23.288,
      -54.412,
      -1.761,
      23.288,
      -54.412,
      -1.761,
      26.818,
      -54.412,
      -1.761,
      26.818,
      -54.823,
      -0.889,
      26.818,
      -54.823,
      -0.889,
      23.288
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_4.OG_847",
    "polygon": [
      15.075,
      -8.767,
      16.5,
      14.143,
      -9.206,
      16.5,
      14.143,
      -9.206,
      18.73,
      14.143,
      -9.206,
      18.73,
      15.075,
      -8.767,
      18.73,
      15.075,
      -8.767,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_478",
    "polygon": [
      -22.65,
      -7.082,
      9.76,
      -21.721,
      -6.645,
      9.76,
      -21.721,
      -6.645,
      11.99,
      -21.721,
      -6.645,
      11.99,
      -22.65,
      -7.082,
      11.99,
      -22.65,
      -7.082,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_295",
    "polygon": [
      -47.925,
      -15.548,
      6.39,
      -47.514,
      -16.421,
      6.39,
      -47.514,
      -16.421,
      8.167,
      -47.514,
      -16.421,
      8.167,
      -47.925,
      -15.548,
      8.167,
      -47.925,
      -15.548,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_430",
    "polygon": [
      0.084,
      -15.822,
      9.76,
      -0.848,
      -16.26,
      9.76,
      -0.848,
      -16.26,
      11.99,
      -0.848,
      -16.26,
      11.99,
      0.084,
      -15.822,
      11.99,
      0.084,
      -15.822,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_2.OG_507",
    "polygon": [
      -53.238,
      -4.258,
      9.76,
      -52.827,
      -5.13,
      9.76,
      -52.827,
      -5.13,
      11.537,
      -52.827,
      -5.13,
      11.537,
      -53.238,
      -4.258,
      11.537,
      -53.238,
      -4.258,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_163",
    "polygon": [
      -47.927,
      -15.541,
      3.062,
      -47.514,
      -16.419,
      3.062,
      -47.514,
      -16.419,
      5.252,
      -47.514,
      -16.419,
      5.252,
      -47.927,
      -15.541,
      5.252,
      -47.927,
      -15.541,
      3.062
    ]
  },
  {
    "ID": "DINHQ_6.OG_1297",
    "polygon": [
      -34.819,
      -12.808,
      23.288,
      -33.89,
      -12.371,
      23.288,
      -33.89,
      -12.371,
      26.818,
      -33.89,
      -12.371,
      26.818,
      -34.819,
      -12.808,
      26.818,
      -34.819,
      -12.808,
      23.288
    ]
  },
  {
    "ID": "DINHQ_1.OG_331",
    "polygon": [
      -66.627,
      -11.615,
      6.39,
      -67.037,
      -10.743,
      6.39,
      -67.037,
      -10.743,
      8.167,
      -67.037,
      -10.743,
      8.167,
      -66.627,
      -11.615,
      8.167,
      -66.627,
      -11.615,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_661",
    "polygon": [
      4.076,
      5.494,
      13.13,
      5.005,
      5.932,
      13.13,
      5.005,
      5.932,
      15.36,
      5.005,
      5.932,
      15.36,
      4.076,
      5.494,
      15.36,
      4.076,
      5.494,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_508",
    "polygon": [
      -53.673,
      -3.332,
      9.76,
      -53.263,
      -4.204,
      9.76,
      -53.263,
      -4.204,
      11.537,
      -53.263,
      -4.204,
      11.537,
      -53.673,
      -3.332,
      11.537,
      -53.673,
      -3.332,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_184",
    "polygon": [
      -60.462,
      -44.312,
      6.393,
      -61.358,
      -44.734,
      6.393,
      -61.358,
      -44.734,
      8.618,
      -61.358,
      -44.734,
      8.618,
      -60.462,
      -44.312,
      8.618,
      -60.462,
      -44.312,
      6.393
    ],
    "11-07 10:30": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_422",
    "polygon": [
      -10.513,
      -20.808,
      9.76,
      -11.445,
      -21.247,
      9.76,
      -11.445,
      -21.247,
      11.99,
      -11.445,
      -21.247,
      11.99,
      -10.513,
      -20.808,
      11.99,
      -10.513,
      -20.808,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_2.OG_446",
    "polygon": [
      21.379,
      -5.801,
      9.76,
      20.447,
      -6.24,
      9.76,
      20.447,
      -6.24,
      11.99,
      20.447,
      -6.24,
      11.99,
      21.379,
      -5.801,
      11.99,
      21.379,
      -5.801,
      9.76
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_333",
    "polygon": [
      -65.477,
      -14.058,
      6.39,
      -65.888,
      -13.186,
      6.39,
      -65.888,
      -13.186,
      8.167,
      -65.888,
      -13.186,
      8.167,
      -65.477,
      -14.058,
      8.167,
      -65.477,
      -14.058,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_772",
    "polygon": [
      -91.97,
      -39.701,
      13.13,
      -91.075,
      -39.28,
      13.13,
      -91.075,
      -39.28,
      15.355,
      -91.075,
      -39.28,
      15.355,
      -91.97,
      -39.701,
      15.355,
      -91.97,
      -39.701,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_135",
    "polygon": [
      -62.02,
      8.06,
      2.485,
      -59.667,
      9.167,
      2.485,
      -59.667,
      9.167,
      5.25,
      -59.667,
      9.167,
      5.25,
      -62.02,
      8.06,
      5.25,
      -62.02,
      8.06,
      2.485
    ]
  },
  {
    "ID": "DINHQ_1.OG_344",
    "polygon": [
      -61.117,
      -25.183,
      6.39,
      -60.755,
      -25.013,
      6.39,
      -60.755,
      -25.013,
      8.615,
      -60.755,
      -25.013,
      8.615,
      -61.117,
      -25.183,
      8.615,
      -61.117,
      -25.183,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_854",
    "polygon": [
      23.989,
      -4.573,
      16.5,
      23.057,
      -5.011,
      16.5,
      23.057,
      -5.011,
      18.73,
      23.057,
      -5.011,
      18.73,
      23.989,
      -4.573,
      18.73,
      23.989,
      -4.573,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_469",
    "polygon": [
      -10.975,
      -1.588,
      9.76,
      -10.046,
      -1.151,
      9.76,
      -10.046,
      -1.151,
      11.99,
      -10.046,
      -1.151,
      11.99,
      -10.975,
      -1.588,
      11.99,
      -10.975,
      -1.588,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_747",
    "polygon": [
      -60.879,
      -23.83,
      13.13,
      -61.289,
      -22.958,
      13.13,
      -61.289,
      -22.958,
      14.907,
      -61.289,
      -22.958,
      14.907,
      -60.879,
      -23.83,
      14.907,
      -60.879,
      -23.83,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_665",
    "polygon": [
      -1.465,
      2.887,
      13.13,
      -0.536,
      3.324,
      13.13,
      -0.536,
      3.324,
      15.36,
      -0.536,
      3.324,
      15.36,
      -1.465,
      2.887,
      15.36,
      -1.465,
      2.887,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_263",
    "polygon": [
      -6.754,
      0.398,
      6.39,
      -5.825,
      0.835,
      6.39,
      -5.825,
      0.835,
      8.62,
      -5.825,
      0.835,
      8.62,
      -6.754,
      0.398,
      8.62,
      -6.754,
      0.398,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_130",
    "polygon": [
      -41.103,
      -15.765,
      2.11,
      -39.278,
      -14.906,
      2.11,
      -39.278,
      -14.906,
      4.38,
      -39.278,
      -14.906,
      4.38,
      -41.103,
      -15.765,
      4.38,
      -41.103,
      -15.765,
      2.11
    ]
  },
  {
    "ID": "DINHQ_5.OG_1002",
    "polygon": [
      -53.329,
      -40.956,
      19.873,
      -53.645,
      -41.105,
      19.873,
      -53.645,
      -41.105,
      22.098,
      -53.645,
      -41.105,
      22.098,
      -53.329,
      -40.956,
      22.098,
      -53.329,
      -40.956,
      19.873
    ],
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1
  },
  {
    "ID": "DINHQ_2.OG_519",
    "polygon": [
      -62.02,
      8.06,
      9.76,
      -59.729,
      9.138,
      9.76,
      -59.729,
      9.138,
      11.54,
      -59.729,
      9.138,
      11.54,
      -62.02,
      8.06,
      11.54,
      -62.02,
      8.06,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_540",
    "polygon": [
      -63.178,
      -18.944,
      9.76,
      -63.589,
      -18.072,
      9.76,
      -63.589,
      -18.072,
      11.537,
      -63.589,
      -18.072,
      11.537,
      -63.178,
      -18.944,
      11.537,
      -63.178,
      -18.944,
      9.76
    ]
  },
  {
    "ID": "DINHQ_6.OG_1207",
    "polygon": [
      -43.581,
      -39.952,
      23.288,
      -45.698,
      -40.948,
      23.288,
      -45.698,
      -40.948,
      26.818,
      -45.698,
      -40.948,
      26.818,
      -43.581,
      -39.952,
      26.818,
      -43.581,
      -39.952,
      23.288
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_EG_49",
    "polygon": [
      -67.904,
      -47.73,
      0.935,
      -69.814,
      -48.628,
      0.935,
      -69.814,
      -48.628,
      1.885,
      -69.814,
      -48.628,
      1.885,
      -67.904,
      -47.73,
      1.885,
      -67.904,
      -47.73,
      0.935
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_843",
    "polygon": [
      9.837,
      -11.232,
      16.5,
      8.787,
      -11.726,
      16.5,
      8.787,
      -11.726,
      18.73,
      8.787,
      -11.726,
      18.73,
      9.837,
      -11.232,
      18.73,
      9.837,
      -11.232,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_170",
    "polygon": [
      -78.45,
      -52.777,
      6.393,
      -79.346,
      -53.198,
      6.393,
      -79.346,
      -53.198,
      8.618,
      -79.346,
      -53.198,
      8.618,
      -78.45,
      -52.777,
      8.618,
      -78.45,
      -52.777,
      6.393
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_278",
    "polygon": [
      -26.871,
      -9.068,
      6.39,
      -25.942,
      -8.631,
      6.39,
      -25.942,
      -8.631,
      8.62,
      -25.942,
      -8.631,
      8.62,
      -26.871,
      -9.068,
      8.62,
      -26.871,
      -9.068,
      6.39
    ]
  },
  {
    "ID": "DINHQ_6.OG_1348",
    "polygon": [
      -64.328,
      -16.501,
      23.288,
      -64.738,
      -15.629,
      23.288,
      -64.738,
      -15.629,
      26.818,
      -64.738,
      -15.629,
      26.818,
      -64.328,
      -16.501,
      26.818,
      -64.328,
      -16.501,
      23.288
    ],
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_2.OG_412",
    "polygon": [
      -23.764,
      -27.043,
      9.76,
      -24.696,
      -27.482,
      9.76,
      -24.696,
      -27.482,
      11.99,
      -24.696,
      -27.482,
      11.99,
      -23.764,
      -27.043,
      11.99,
      -23.764,
      -27.043,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1
  },
  {
    "ID": "DINHQ_4.OG_831",
    "polygon": [
      -6.291,
      -18.821,
      16.5,
      -7.223,
      -19.26,
      16.5,
      -7.223,
      -19.26,
      18.73,
      -7.223,
      -19.26,
      18.73,
      -6.291,
      -18.821,
      18.73,
      -6.291,
      -18.821,
      16.5
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_671",
    "polygon": [
      -9.403,
      -0.849,
      13.13,
      -8.474,
      -0.411,
      13.13,
      -8.474,
      -0.411,
      15.36,
      -8.474,
      -0.411,
      15.36,
      -9.403,
      -0.849,
      15.36,
      -9.403,
      -0.849,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_841",
    "polygon": [
      6.954,
      -12.589,
      16.5,
      6.022,
      -13.027,
      16.5,
      6.022,
      -13.027,
      18.73,
      6.022,
      -13.027,
      18.73,
      6.954,
      -12.589,
      18.73,
      6.954,
      -12.589,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_651",
    "polygon": [
      23.989,
      -4.573,
      13.13,
      23.057,
      -5.011,
      13.13,
      23.057,
      -5.011,
      15.36,
      23.057,
      -5.011,
      15.36,
      23.989,
      -4.573,
      15.36,
      23.989,
      -4.573,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_309",
    "polygon": [
      -55.973,
      1.554,
      6.39,
      -55.562,
      0.682,
      6.39,
      -55.562,
      0.682,
      8.167,
      -55.562,
      0.682,
      8.167,
      -55.973,
      1.554,
      8.167,
      -55.973,
      1.554,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_950",
    "polygon": [
      -60.879,
      -23.83,
      16.5,
      -61.289,
      -22.958,
      16.5,
      -61.289,
      -22.958,
      18.277,
      -61.289,
      -22.958,
      18.277,
      -60.879,
      -23.83,
      18.277,
      -60.879,
      -23.83,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_625",
    "polygon": [
      -10.513,
      -20.808,
      13.13,
      -11.445,
      -21.247,
      13.13,
      -11.445,
      -21.247,
      15.36,
      -11.445,
      -21.247,
      15.36,
      -10.513,
      -20.808,
      15.36,
      -10.513,
      -20.808,
      13.13
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_2.OG_473",
    "polygon": [
      -16.274,
      -4.081,
      9.76,
      -15.344,
      -3.644,
      9.76,
      -15.344,
      -3.644,
      11.99,
      -15.344,
      -3.644,
      11.99,
      -16.274,
      -4.081,
      11.99,
      -16.274,
      -4.081,
      9.76
    ]
  },
  {
    "ID": "DINHQ_6.OG_1282",
    "polygon": [
      -14.702,
      -3.342,
      23.288,
      -13.773,
      -2.905,
      23.288,
      -13.773,
      -2.905,
      26.818,
      -13.773,
      -2.905,
      26.818,
      -14.702,
      -3.342,
      26.818,
      -14.702,
      -3.342,
      23.288
    ]
  },
  {
    "ID": "DINHQ_2.OG_369",
    "polygon": [
      -83.59,
      -55.195,
      9.763,
      -84.485,
      -55.617,
      9.763,
      -84.485,
      -55.617,
      11.988,
      -84.485,
      -55.617,
      11.988,
      -83.59,
      -55.195,
      11.988,
      -83.59,
      -55.195,
      9.763
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_460",
    "polygon": [
      1.313,
      4.195,
      9.76,
      2.367,
      4.691,
      9.76,
      2.367,
      4.691,
      11.99,
      2.367,
      4.691,
      11.99,
      1.313,
      4.195,
      11.99,
      1.313,
      4.195,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_659",
    "polygon": [
      6.713,
      6.735,
      13.13,
      7.642,
      7.173,
      13.13,
      7.642,
      7.173,
      15.36,
      7.642,
      7.173,
      15.36,
      6.713,
      6.735,
      15.36,
      6.713,
      6.735,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_133",
    "polygon": [
      -29.499,
      -10.309,
      1.99,
      -28.459,
      -9.819,
      1.99,
      -28.459,
      -9.819,
      4.36,
      -28.459,
      -9.819,
      4.36,
      -29.499,
      -10.309,
      4.36,
      -29.499,
      -10.309,
      1.99
    ]
  },
  {
    "ID": "DINHQ_2.OG_421",
    "polygon": [
      -11.59,
      -21.315,
      9.76,
      -12.522,
      -21.753,
      9.76,
      -12.522,
      -21.753,
      11.99,
      -12.522,
      -21.753,
      11.99,
      -11.59,
      -21.315,
      11.99,
      -11.59,
      -21.315,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1056",
    "polygon": [
      22.901,
      -5.085,
      19.87,
      21.969,
      -5.523,
      19.87,
      21.969,
      -5.523,
      22.1,
      21.969,
      -5.523,
      22.1,
      22.901,
      -5.085,
      22.1,
      22.901,
      -5.085,
      19.87
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_199",
    "polygon": [
      -37.01,
      -33.277,
      6.39,
      -37.942,
      -33.715,
      6.39,
      -37.942,
      -33.715,
      8.62,
      -37.942,
      -33.715,
      8.62,
      -37.01,
      -33.277,
      8.62,
      -37.01,
      -33.277,
      6.39
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1041",
    "polygon": [
      2.734,
      -14.575,
      19.87,
      1.802,
      -15.013,
      19.87,
      1.802,
      -15.013,
      22.1,
      1.802,
      -15.013,
      22.1,
      2.734,
      -14.575,
      22.1,
      2.734,
      -14.575,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_386",
    "polygon": [
      -61.494,
      -44.798,
      9.763,
      -62.39,
      -45.219,
      9.763,
      -62.39,
      -45.219,
      11.988,
      -62.39,
      -45.219,
      11.988,
      -61.494,
      -44.798,
      11.988,
      -61.494,
      -44.798,
      9.763
    ],
    "11-07 10:30": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_649",
    "polygon": [
      21.379,
      -5.801,
      13.13,
      20.447,
      -6.24,
      13.13,
      20.447,
      -6.24,
      15.36,
      20.447,
      -6.24,
      15.36,
      21.379,
      -5.801,
      15.36,
      21.379,
      -5.801,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_558",
    "polygon": [
      -77.588,
      -32.934,
      9.76,
      -76.692,
      -32.512,
      9.76,
      -76.692,
      -32.512,
      11.985,
      -76.692,
      -32.512,
      11.985,
      -77.588,
      -32.934,
      11.985,
      -77.588,
      -32.934,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_115",
    "polygon": [
      6.804,
      6.778,
      2.485,
      8.619,
      7.633,
      2.485,
      8.619,
      7.633,
      4.38,
      8.619,
      7.633,
      4.38,
      6.804,
      6.778,
      4.38,
      6.804,
      6.778,
      2.485
    ]
  },
  {
    "ID": "DINHQ_2.OG_509",
    "polygon": [
      -54.387,
      -1.815,
      9.76,
      -53.977,
      -2.687,
      9.76,
      -53.977,
      -2.687,
      11.537,
      -53.977,
      -2.687,
      11.537,
      -54.387,
      -1.815,
      11.537,
      -54.387,
      -1.815,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1058",
    "polygon": [
      15.692,
      10.961,
      19.87,
      16.622,
      11.398,
      19.87,
      16.622,
      11.398,
      22.1,
      16.622,
      11.398,
      22.1,
      15.692,
      10.961,
      22.1,
      15.692,
      10.961,
      19.87
    ]
  },
  {
    "ID": "DINHQ_4.OG_942",
    "polygon": [
      -65.477,
      -14.058,
      16.5,
      -65.888,
      -13.186,
      16.5,
      -65.888,
      -13.186,
      18.277,
      -65.888,
      -13.186,
      18.277,
      -65.477,
      -14.058,
      18.277,
      -65.477,
      -14.058,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_440",
    "polygon": [
      13.526,
      -9.496,
      9.76,
      12.594,
      -9.935,
      9.76,
      12.594,
      -9.935,
      11.99,
      12.594,
      -9.935,
      11.99,
      13.526,
      -9.496,
      11.99,
      13.526,
      -9.496,
      9.76
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_409",
    "polygon": [
      -27.489,
      -28.797,
      9.76,
      -28.421,
      -29.235,
      9.76,
      -28.421,
      -29.235,
      11.99,
      -28.421,
      -29.235,
      11.99,
      -27.489,
      -28.797,
      11.99,
      -27.489,
      -28.797,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_190",
    "polygon": [
      -53.329,
      -40.956,
      6.393,
      -53.645,
      -41.105,
      6.393,
      -53.645,
      -41.105,
      8.618,
      -53.645,
      -41.105,
      8.618,
      -53.329,
      -40.956,
      8.618,
      -53.329,
      -40.956,
      6.393
    ],
    "11-07 11:00": 1
  },
  {
    "ID": "DINHQ_2.OG_395",
    "polygon": [
      -46.34,
      -41.25,
      9.76,
      -48.457,
      -42.247,
      9.76,
      -48.457,
      -42.247,
      11.99,
      -48.457,
      -42.247,
      11.99,
      -46.34,
      -41.25,
      11.99,
      -46.34,
      -41.25,
      9.76
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_3.OG_701",
    "polygon": [
      -47.925,
      -15.548,
      13.13,
      -47.514,
      -16.421,
      13.13,
      -47.514,
      -16.421,
      14.907,
      -47.514,
      -16.421,
      14.907,
      -47.925,
      -15.548,
      14.907,
      -47.925,
      -15.548,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_305",
    "polygon": [
      -53.673,
      -3.332,
      6.39,
      -53.263,
      -4.204,
      6.39,
      -53.263,
      -4.204,
      8.167,
      -53.263,
      -4.204,
      8.167,
      -53.673,
      -3.332,
      8.167,
      -53.673,
      -3.332,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1106",
    "polygon": [
      -47.489,
      -16.474,
      19.87,
      -47.079,
      -17.346,
      19.87,
      -47.079,
      -17.346,
      21.647,
      -47.079,
      -17.346,
      21.647,
      -47.489,
      -16.474,
      21.647,
      -47.489,
      -16.474,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1144",
    "polygon": [
      -65.913,
      -13.132,
      19.87,
      -66.323,
      -12.26,
      19.87,
      -66.323,
      -12.26,
      21.647,
      -66.323,
      -12.26,
      21.647,
      -65.913,
      -13.132,
      21.647,
      -65.913,
      -13.132,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1108",
    "polygon": [
      -48.639,
      -14.031,
      19.87,
      -48.228,
      -14.903,
      19.87,
      -48.228,
      -14.903,
      21.647,
      -48.228,
      -14.903,
      21.647,
      -48.639,
      -14.031,
      21.647,
      -48.639,
      -14.031,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_624",
    "polygon": [
      -11.59,
      -21.315,
      13.13,
      -12.522,
      -21.753,
      13.13,
      -12.522,
      -21.753,
      15.36,
      -12.522,
      -21.753,
      15.36,
      -11.59,
      -21.315,
      15.36,
      -11.59,
      -21.315,
      13.13
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_EG_110",
    "polygon": [
      -43.947,
      -36.032,
      3.59,
      -45.229,
      -36.453,
      3.59,
      -45.229,
      -36.453,
      4.64,
      -45.229,
      -36.453,
      4.64,
      -43.947,
      -36.032,
      4.64,
      -43.947,
      -36.032,
      3.59
    ]
  },
  {
    "ID": "DINHQ_5.OG_1055",
    "polygon": [
      21.379,
      -5.801,
      19.87,
      20.447,
      -6.24,
      19.87,
      20.447,
      -6.24,
      22.1,
      20.447,
      -6.24,
      22.1,
      21.379,
      -5.801,
      22.1,
      21.379,
      -5.801,
      19.87
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_524",
    "polygon": [
      -72.808,
      1.52,
      9.76,
      -73.337,
      2.645,
      9.76,
      -73.337,
      2.645,
      11.537,
      -73.337,
      2.645,
      11.537,
      -72.808,
      1.52,
      11.537,
      -72.808,
      1.52,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1069",
    "polygon": [
      1.313,
      4.195,
      19.87,
      2.367,
      4.691,
      19.87,
      2.367,
      4.691,
      22.1,
      2.367,
      4.691,
      22.1,
      1.313,
      4.195,
      22.1,
      1.313,
      4.195,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_670",
    "polygon": [
      -8.326,
      -0.341,
      13.13,
      -7.397,
      0.096,
      13.13,
      -7.397,
      0.096,
      15.36,
      -7.397,
      0.096,
      15.36,
      -8.326,
      -0.341,
      15.36,
      -8.326,
      -0.341,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_955",
    "polygon": [
      -66.273,
      -27.609,
      16.5,
      -65.378,
      -27.188,
      16.5,
      -65.378,
      -27.188,
      18.725,
      -65.378,
      -27.188,
      18.725,
      -66.273,
      -27.609,
      18.725,
      -66.273,
      -27.609,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_887",
    "polygon": [
      -26.871,
      -9.068,
      16.5,
      -25.942,
      -8.631,
      16.5,
      -25.942,
      -8.631,
      18.73,
      -25.942,
      -8.631,
      18.73,
      -26.871,
      -9.068,
      18.73,
      -26.871,
      -9.068,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_366",
    "polygon": [
      -91.97,
      -39.701,
      6.39,
      -91.075,
      -39.28,
      6.39,
      -91.075,
      -39.28,
      8.615,
      -91.075,
      -39.28,
      8.615,
      -91.97,
      -39.701,
      8.615,
      -91.97,
      -39.701,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_839",
    "polygon": [
      4.306,
      -13.835,
      16.5,
      3.374,
      -14.274,
      16.5,
      3.374,
      -14.274,
      18.73,
      3.374,
      -14.274,
      18.73,
      4.306,
      -13.835,
      18.73,
      4.306,
      -13.835,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_2.OG_503",
    "polygon": [
      -50.938,
      -9.144,
      9.76,
      -50.528,
      -10.017,
      9.76,
      -50.528,
      -10.017,
      11.537,
      -50.528,
      -10.017,
      11.537,
      -50.938,
      -9.144,
      11.537,
      -50.938,
      -9.144,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_361",
    "polygon": [
      -85.297,
      -36.561,
      6.39,
      -84.401,
      -36.14,
      6.39,
      -84.401,
      -36.14,
      8.615,
      -84.401,
      -36.14,
      8.615,
      -85.297,
      -36.561,
      8.615,
      -85.297,
      -36.561,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1081",
    "polygon": [
      -14.702,
      -3.342,
      19.87,
      -13.773,
      -2.905,
      19.87,
      -13.773,
      -2.905,
      22.1,
      -13.773,
      -2.905,
      22.1,
      -14.702,
      -3.342,
      22.1,
      -14.702,
      -3.342,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_693",
    "polygon": [
      -38.546,
      -14.562,
      13.13,
      -37.617,
      -14.125,
      13.13,
      -37.617,
      -14.125,
      15.36,
      -37.617,
      -14.125,
      15.36,
      -38.546,
      -14.562,
      15.36,
      -38.546,
      -14.562,
      13.13
    ]
  },
  {
    "ID": "DINHQ_6.OG_1294",
    "polygon": [
      -30.598,
      -10.822,
      23.288,
      -29.669,
      -10.385,
      23.288,
      -29.669,
      -10.385,
      26.818,
      -29.669,
      -10.385,
      26.818,
      -30.598,
      -10.822,
      26.818,
      -30.598,
      -10.822,
      23.288
    ]
  },
  {
    "ID": "DINHQ_1.OG_293",
    "polygon": [
      -46.255,
      -18.19,
      6.39,
      -45.576,
      -17.87,
      6.39,
      -45.576,
      -17.87,
      8.62,
      -45.576,
      -17.87,
      8.62,
      -46.255,
      -18.19,
      8.62,
      -46.255,
      -18.19,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_822",
    "polygon": [
      -18.465,
      -24.55,
      16.5,
      -19.397,
      -24.989,
      16.5,
      -19.397,
      -24.989,
      18.73,
      -19.397,
      -24.989,
      18.73,
      -18.465,
      -24.55,
      18.73,
      -18.465,
      -24.55,
      16.5
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_4.OG_780",
    "polygon": [
      -76.912,
      -52.053,
      16.503,
      -77.808,
      -52.475,
      16.503,
      -77.808,
      -52.475,
      18.728,
      -77.808,
      -52.475,
      18.728,
      -76.912,
      -52.053,
      18.728,
      -76.912,
      -52.053,
      16.503
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_99",
    "polygon": [
      -37.571,
      -33.46,
      1.064,
      -37.896,
      -33.614,
      1.064,
      -37.896,
      -33.614,
      3.364,
      -37.896,
      -33.614,
      3.364,
      -37.571,
      -33.46,
      3.364,
      -37.571,
      -33.46,
      1.064
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1
  },
  {
    "ID": "DINHQ_1.OG_171",
    "polygon": [
      -76.912,
      -52.053,
      6.393,
      -77.808,
      -52.475,
      6.393,
      -77.808,
      -52.475,
      8.618,
      -77.808,
      -52.475,
      8.618,
      -76.912,
      -52.053,
      8.618,
      -76.912,
      -52.053,
      6.393
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_194",
    "polygon": [
      -43.581,
      -39.952,
      6.39,
      -45.698,
      -40.948,
      6.39,
      -45.698,
      -40.948,
      8.62,
      -45.698,
      -40.948,
      8.62,
      -43.581,
      -39.952,
      8.62,
      -43.581,
      -39.952,
      6.39
    ],
    "11-07 09:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_2.OG_514",
    "polygon": [
      -57.122,
      3.998,
      9.76,
      -56.712,
      3.125,
      9.76,
      -56.712,
      3.125,
      11.537,
      -56.712,
      3.125,
      11.537,
      -57.122,
      3.998,
      11.537,
      -57.122,
      3.998,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_180",
    "polygon": [
      -65.602,
      -46.731,
      6.393,
      -66.498,
      -47.152,
      6.393,
      -66.498,
      -47.152,
      8.618,
      -66.498,
      -47.152,
      8.618,
      -65.602,
      -46.731,
      8.618,
      -65.602,
      -46.731,
      6.393
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_475",
    "polygon": [
      -18.923,
      -5.328,
      9.76,
      -17.994,
      -4.891,
      9.76,
      -17.994,
      -4.891,
      11.99,
      -17.994,
      -4.891,
      11.99,
      -18.923,
      -5.328,
      11.99,
      -18.923,
      -5.328,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_111",
    "polygon": [
      -43.947,
      -36.032,
      0.94,
      -45.229,
      -36.453,
      0.94,
      -45.229,
      -36.453,
      3.54,
      -45.229,
      -36.453,
      3.54,
      -43.947,
      -36.032,
      3.54,
      -43.947,
      -36.032,
      0.94
    ],
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_487",
    "polygon": [
      -34.819,
      -12.808,
      9.76,
      -33.89,
      -12.371,
      9.76,
      -33.89,
      -12.371,
      11.99,
      -33.89,
      -12.371,
      11.99,
      -34.819,
      -12.808,
      11.99,
      -34.819,
      -12.808,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1119",
    "polygon": [
      -54.823,
      -0.889,
      19.87,
      -54.412,
      -1.761,
      19.87,
      -54.412,
      -1.761,
      21.647,
      -54.412,
      -1.761,
      21.647,
      -54.823,
      -0.889,
      21.647,
      -54.823,
      -0.889,
      19.87
    ]
  },
  {
    "ID": "DINHQ_1.OG_217",
    "polygon": [
      -13.164,
      -22.055,
      6.39,
      -14.096,
      -22.494,
      6.39,
      -14.096,
      -22.494,
      8.62,
      -14.096,
      -22.494,
      8.62,
      -13.164,
      -22.055,
      8.62,
      -13.164,
      -22.055,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_1.OG_336",
    "polygon": [
      -63.614,
      -18.018,
      6.39,
      -64.024,
      -17.146,
      6.39,
      -64.024,
      -17.146,
      8.167,
      -64.024,
      -17.146,
      8.167,
      -63.614,
      -18.018,
      8.167,
      -63.614,
      -18.018,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1110",
    "polygon": [
      -49.788,
      -11.588,
      19.87,
      -49.378,
      -12.46,
      19.87,
      -49.378,
      -12.46,
      21.647,
      -49.378,
      -12.46,
      21.647,
      -49.788,
      -11.588,
      21.647,
      -49.788,
      -11.588,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1135",
    "polygon": [
      -71.225,
      -1.843,
      19.87,
      -71.635,
      -0.971,
      19.87,
      -71.635,
      -0.971,
      21.647,
      -71.635,
      -0.971,
      21.647,
      -71.225,
      -1.843,
      21.647,
      -71.225,
      -1.843,
      19.87
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_3.OG_697",
    "polygon": [
      -43.857,
      -17.061,
      13.13,
      -42.928,
      -16.624,
      13.13,
      -42.928,
      -16.624,
      15.36,
      -42.928,
      -16.624,
      15.36,
      -43.857,
      -17.061,
      15.36,
      -43.857,
      -17.061,
      13.13
    ]
  },
  {
    "ID": "DINHQ_6.OG_1364",
    "polygon": [
      -72.449,
      -30.515,
      23.288,
      -71.553,
      -30.094,
      23.288,
      -71.553,
      -30.094,
      26.818,
      -71.553,
      -30.094,
      26.818,
      -72.449,
      -30.515,
      26.818,
      -72.449,
      -30.515,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_598",
    "polygon": [
      -46.34,
      -41.25,
      13.13,
      -48.457,
      -42.247,
      13.13,
      -48.457,
      -42.247,
      15.36,
      -48.457,
      -42.247,
      15.36,
      -46.34,
      -41.25,
      15.36,
      -46.34,
      -41.25,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 11:30": 1,
    "11-07 12:00": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_1.OG_327",
    "polygon": [
      -68.927,
      -6.728,
      6.39,
      -69.337,
      -5.855,
      6.39,
      -69.337,
      -5.855,
      8.167,
      -69.337,
      -5.855,
      8.167,
      -68.927,
      -6.728,
      8.167,
      -68.927,
      -6.728,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_798",
    "polygon": [
      -53.784,
      -41.17,
      16.503,
      -54.68,
      -41.591,
      16.503,
      -54.68,
      -41.591,
      18.728,
      -54.68,
      -41.591,
      18.728,
      -53.784,
      -41.17,
      18.728,
      -53.784,
      -41.17,
      16.503
    ],
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_399",
    "polygon": [
      -37.891,
      -37.274,
      9.76,
      -40.18,
      -38.351,
      9.76,
      -40.18,
      -38.351,
      11.99,
      -40.18,
      -38.351,
      11.99,
      -37.891,
      -37.274,
      11.99,
      -37.891,
      -37.274,
      9.76
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_1.OG_288",
    "polygon": [
      -40.118,
      -15.302,
      6.39,
      -39.188,
      -14.864,
      6.39,
      -39.188,
      -14.864,
      8.62,
      -39.188,
      -14.864,
      8.62,
      -40.118,
      -15.302,
      8.62,
      -40.118,
      -15.302,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_365",
    "polygon": [
      -90.436,
      -38.979,
      6.39,
      -89.54,
      -38.558,
      6.39,
      -89.54,
      -38.558,
      8.615,
      -89.54,
      -38.558,
      8.615,
      -90.436,
      -38.979,
      8.615,
      -90.436,
      -38.979,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_704",
    "polygon": [
      -49.788,
      -11.588,
      13.13,
      -49.378,
      -12.46,
      13.13,
      -49.378,
      -12.46,
      14.907,
      -49.378,
      -12.46,
      14.907,
      -49.788,
      -11.588,
      14.907,
      -49.788,
      -11.588,
      13.13
    ]
  },
  {
    "ID": "DINHQ_6.OG_1299",
    "polygon": [
      -37.468,
      -14.055,
      23.288,
      -36.539,
      -13.618,
      23.288,
      -36.539,
      -13.618,
      26.818,
      -36.539,
      -13.618,
      26.818,
      -37.468,
      -14.055,
      26.818,
      -37.468,
      -14.055,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_641",
    "polygon": [
      10.911,
      -10.727,
      13.13,
      9.979,
      -11.165,
      13.13,
      9.979,
      -11.165,
      15.36,
      9.979,
      -11.165,
      15.36,
      10.911,
      -10.727,
      15.36,
      10.911,
      -10.727,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_195",
    "polygon": [
      -40.822,
      -38.653,
      6.39,
      -42.939,
      -39.65,
      6.39,
      -42.939,
      -39.65,
      8.62,
      -42.939,
      -39.65,
      8.62,
      -40.822,
      -38.653,
      8.62,
      -40.822,
      -38.653,
      6.39
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_4.OG_853",
    "polygon": [
      22.901,
      -5.085,
      16.5,
      21.969,
      -5.523,
      16.5,
      21.969,
      -5.523,
      18.73,
      21.969,
      -5.523,
      18.73,
      22.901,
      -5.085,
      18.73,
      22.901,
      -5.085,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_321",
    "polygon": [
      -72.808,
      1.52,
      6.39,
      -73.337,
      2.645,
      6.39,
      -73.337,
      2.645,
      8.167,
      -73.337,
      2.645,
      8.167,
      -72.808,
      1.52,
      8.167,
      -72.808,
      1.52,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_161",
    "polygon": [
      -48.603,
      -14.105,
      3.193,
      -48.17,
      -15.025,
      3.193,
      -48.17,
      -15.025,
      5.11,
      -48.17,
      -15.025,
      5.11,
      -48.603,
      -14.105,
      5.11,
      -48.603,
      -14.105,
      3.193
    ]
  },
  {
    "ID": "DINHQ_EG_89",
    "polygon": [
      13.419,
      -9.462,
      0.881,
      11.141,
      -10.534,
      0.881,
      11.141,
      -10.534,
      1.831,
      11.141,
      -10.534,
      1.831,
      13.419,
      -9.462,
      1.831,
      13.419,
      -9.462,
      0.881
    ],
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_2.OG_448",
    "polygon": [
      23.989,
      -4.573,
      9.76,
      23.057,
      -5.011,
      9.76,
      23.057,
      -5.011,
      11.99,
      23.057,
      -5.011,
      11.99,
      23.989,
      -4.573,
      11.99,
      23.989,
      -4.573,
      9.76
    ],
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_228",
    "polygon": [
      1.657,
      -15.081,
      6.39,
      0.726,
      -15.52,
      6.39,
      0.726,
      -15.52,
      8.62,
      0.726,
      -15.52,
      8.62,
      1.657,
      -15.081,
      8.62,
      1.657,
      -15.081,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_2.OG_547",
    "polygon": [
      -61.117,
      -25.183,
      9.76,
      -60.755,
      -25.013,
      9.76,
      -60.755,
      -25.013,
      11.985,
      -60.755,
      -25.013,
      11.985,
      -61.117,
      -25.183,
      11.985,
      -61.117,
      -25.183,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_322",
    "polygon": [
      -71.661,
      -0.918,
      6.39,
      -72.071,
      -0.045,
      6.39,
      -72.071,
      -0.045,
      8.167,
      -72.071,
      -0.045,
      8.167,
      -71.661,
      -0.918,
      8.167,
      -71.661,
      -0.918,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_872",
    "polygon": [
      -6.754,
      0.398,
      16.5,
      -5.825,
      0.835,
      16.5,
      -5.825,
      0.835,
      18.73,
      -5.825,
      0.835,
      18.73,
      -6.754,
      0.398,
      18.73,
      -6.754,
      0.398,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_330",
    "polygon": [
      -67.062,
      -10.689,
      6.39,
      -67.473,
      -9.817,
      6.39,
      -67.473,
      -9.817,
      8.167,
      -67.473,
      -9.817,
      8.167,
      -67.062,
      -10.689,
      8.167,
      -67.062,
      -10.689,
      6.39
    ]
  },
  {
    "ID": "DINHQ_6.OG_1285",
    "polygon": [
      -18.923,
      -5.328,
      23.288,
      -17.994,
      -4.891,
      23.288,
      -17.994,
      -4.891,
      26.818,
      -17.994,
      -4.891,
      26.818,
      -18.923,
      -5.328,
      26.818,
      -18.923,
      -5.328,
      23.288
    ]
  },
  {
    "ID": "DINHQ_2.OG_413",
    "polygon": [
      -22.19,
      -26.303,
      9.76,
      -23.122,
      -26.741,
      9.76,
      -23.122,
      -26.741,
      11.99,
      -23.122,
      -26.741,
      11.99,
      -22.19,
      -26.303,
      11.99,
      -22.19,
      -26.303,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_434",
    "polygon": [
      5.382,
      -13.328,
      9.76,
      4.45,
      -13.767,
      9.76,
      4.45,
      -13.767,
      11.99,
      4.45,
      -13.767,
      11.99,
      5.382,
      -13.328,
      11.99,
      5.382,
      -13.328,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_889",
    "polygon": [
      -29.52,
      -10.315,
      16.5,
      -28.591,
      -9.878,
      16.5,
      -28.591,
      -9.878,
      18.73,
      -28.591,
      -9.878,
      18.73,
      -29.52,
      -10.315,
      18.73,
      -29.52,
      -10.315,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_588",
    "polygon": [
      -63.032,
      -45.522,
      13.133,
      -63.928,
      -45.943,
      13.133,
      -63.928,
      -45.943,
      15.358,
      -63.928,
      -45.943,
      15.358,
      -63.032,
      -45.522,
      15.358,
      -63.032,
      -45.522,
      13.133
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_208",
    "polygon": [
      -24.838,
      -27.549,
      6.39,
      -25.77,
      -27.987,
      6.39,
      -25.77,
      -27.987,
      8.62,
      -25.77,
      -27.987,
      8.62,
      -24.838,
      -27.549,
      8.62,
      -24.838,
      -27.549,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_143",
    "polygon": [
      -55.539,
      0.634,
      2.484,
      -55.128,
      -0.238,
      2.484,
      -55.128,
      -0.238,
      5.25,
      -55.128,
      -0.238,
      5.25,
      -55.539,
      0.634,
      5.25,
      -55.539,
      0.634,
      2.484
    ]
  },
  {
    "ID": "DINHQ_6.OG_1362",
    "polygon": [
      -69.879,
      -29.306,
      23.288,
      -68.983,
      -28.884,
      23.288,
      -68.983,
      -28.884,
      26.818,
      -68.983,
      -28.884,
      26.818,
      -69.879,
      -29.306,
      26.818,
      -69.879,
      -29.306,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_720",
    "polygon": [
      -58.986,
      7.958,
      13.13,
      -58.576,
      7.086,
      13.13,
      -58.576,
      7.086,
      14.907,
      -58.576,
      7.086,
      14.907,
      -58.986,
      7.958,
      14.907,
      -58.986,
      7.958,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_1161",
    "polygon": [
      -69.879,
      -29.306,
      19.87,
      -68.983,
      -28.884,
      19.87,
      -68.983,
      -28.884,
      22.095,
      -68.983,
      -28.884,
      22.095,
      -69.879,
      -29.306,
      22.095,
      -69.879,
      -29.306,
      19.87
    ]
  },
  {
    "ID": "DINHQ_1.OG_355",
    "polygon": [
      -77.588,
      -32.934,
      6.39,
      -76.692,
      -32.512,
      6.39,
      -76.692,
      -32.512,
      8.615,
      -76.692,
      -32.512,
      8.615,
      -77.588,
      -32.934,
      8.615,
      -77.588,
      -32.934,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_855",
    "polygon": [
      15.692,
      10.961,
      16.5,
      16.622,
      11.398,
      16.5,
      16.622,
      11.398,
      18.73,
      16.622,
      11.398,
      18.73,
      15.692,
      10.961,
      18.73,
      15.692,
      10.961,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_251",
    "polygon": [
      9.351,
      7.977,
      6.39,
      10.28,
      8.414,
      6.39,
      10.28,
      8.414,
      8.62,
      10.28,
      8.414,
      8.62,
      9.351,
      7.977,
      8.62,
      9.351,
      7.977,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1014",
    "polygon": [
      -32.788,
      -31.29,
      19.87,
      -33.72,
      -31.728,
      19.87,
      -33.72,
      -31.728,
      22.1,
      -33.72,
      -31.728,
      22.1,
      -32.788,
      -31.29,
      22.1,
      -32.788,
      -31.29,
      19.87
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_4.OG_861",
    "polygon": [
      7.78,
      7.237,
      16.5,
      8.709,
      7.675,
      16.5,
      8.709,
      7.675,
      18.73,
      8.709,
      7.675,
      18.73,
      7.78,
      7.237,
      18.73,
      7.78,
      7.237,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_681",
    "polygon": [
      -22.65,
      -7.082,
      13.13,
      -21.721,
      -6.645,
      13.13,
      -21.721,
      -6.645,
      15.36,
      -21.721,
      -6.645,
      15.36,
      -22.65,
      -7.082,
      15.36,
      -22.65,
      -7.082,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_425",
    "polygon": [
      -6.291,
      -18.821,
      9.76,
      -7.223,
      -19.26,
      9.76,
      -7.223,
      -19.26,
      11.99,
      -7.223,
      -19.26,
      11.99,
      -6.291,
      -18.821,
      11.99,
      -6.291,
      -18.821,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1149",
    "polygon": [
      -63.178,
      -18.944,
      19.87,
      -63.589,
      -18.072,
      19.87,
      -63.589,
      -18.072,
      21.647,
      -63.589,
      -18.072,
      21.647,
      -63.178,
      -18.944,
      21.647,
      -63.178,
      -18.944,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_728",
    "polygon": [
      -71.661,
      -0.918,
      13.13,
      -72.071,
      -0.045,
      13.13,
      -72.071,
      -0.045,
      14.907,
      -72.071,
      -0.045,
      14.907,
      -71.661,
      -0.918,
      14.907,
      -71.661,
      -0.918,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_1172",
    "polygon": [
      -84.262,
      -36.074,
      19.87,
      -83.366,
      -35.653,
      19.87,
      -83.366,
      -35.653,
      22.095,
      -83.366,
      -35.653,
      22.095,
      -84.262,
      -36.074,
      22.095,
      -84.262,
      -36.074,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_391",
    "polygon": [
      -55.323,
      -41.894,
      9.763,
      -56.219,
      -42.316,
      9.763,
      -56.219,
      -42.316,
      11.988,
      -56.219,
      -42.316,
      11.988,
      -55.323,
      -41.894,
      11.988,
      -55.323,
      -41.894,
      9.763
    ],
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_325",
    "polygon": [
      -70.075,
      -4.286,
      6.39,
      -70.486,
      -3.414,
      6.39,
      -70.486,
      -3.414,
      8.167,
      -70.486,
      -3.414,
      8.167,
      -70.075,
      -4.286,
      8.167,
      -70.075,
      -4.286,
      6.39
    ]
  },
  {
    "ID": "DINHQ_6.OG_1337",
    "polygon": [
      -70.511,
      -3.361,
      23.288,
      -70.922,
      -2.488,
      23.288,
      -70.922,
      -2.488,
      26.818,
      -70.922,
      -2.488,
      26.818,
      -70.511,
      -3.361,
      26.818,
      -70.511,
      -3.361,
      23.288
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_1.OG_174",
    "polygon": [
      -73.311,
      -50.358,
      6.393,
      -74.207,
      -50.78,
      6.393,
      -74.207,
      -50.78,
      8.618,
      -74.207,
      -50.78,
      8.618,
      -73.311,
      -50.358,
      8.618,
      -73.311,
      -50.358,
      6.393
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_590",
    "polygon": [
      -60.462,
      -44.312,
      13.133,
      -61.358,
      -44.734,
      13.133,
      -61.358,
      -44.734,
      15.358,
      -61.358,
      -44.734,
      15.358,
      -60.462,
      -44.312,
      15.358,
      -60.462,
      -44.312,
      13.133
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_454",
    "polygon": [
      9.351,
      7.977,
      9.76,
      10.28,
      8.414,
      9.76,
      10.28,
      8.414,
      11.99,
      10.28,
      8.414,
      11.99,
      9.351,
      7.977,
      11.99,
      9.351,
      7.977,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_684",
    "polygon": [
      -26.871,
      -9.068,
      13.13,
      -25.942,
      -8.631,
      13.13,
      -25.942,
      -8.631,
      15.36,
      -25.942,
      -8.631,
      15.36,
      -26.871,
      -9.068,
      15.36,
      -26.871,
      -9.068,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_827",
    "polygon": [
      -11.59,
      -21.315,
      16.5,
      -12.522,
      -21.753,
      16.5,
      -12.522,
      -21.753,
      18.73,
      -12.522,
      -21.753,
      18.73,
      -11.59,
      -21.315,
      18.73,
      -11.59,
      -21.315,
      16.5
    ],
    "11-07 07:30": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_3.OG_607",
    "polygon": [
      -34.362,
      -32.031,
      13.13,
      -35.294,
      -32.469,
      13.13,
      -35.294,
      -32.469,
      15.36,
      -35.294,
      -32.469,
      15.36,
      -34.362,
      -32.031,
      15.36,
      -34.362,
      -32.031,
      13.13
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1
  },
  {
    "ID": "DINHQ_2.OG_551",
    "polygon": [
      -68.843,
      -28.819,
      9.76,
      -67.947,
      -28.397,
      9.76,
      -67.947,
      -28.397,
      11.985,
      -67.947,
      -28.397,
      11.985,
      -68.843,
      -28.819,
      11.985,
      -68.843,
      -28.819,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_846",
    "polygon": [
      13.526,
      -9.496,
      16.5,
      12.594,
      -9.935,
      16.5,
      12.594,
      -9.935,
      18.73,
      12.594,
      -9.935,
      18.73,
      13.526,
      -9.496,
      18.73,
      13.526,
      -9.496,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_886",
    "polygon": [
      -25.299,
      -8.329,
      16.5,
      -24.37,
      -7.891,
      16.5,
      -24.37,
      -7.891,
      18.73,
      -24.37,
      -7.891,
      18.73,
      -25.299,
      -8.329,
      18.73,
      -25.299,
      -8.329,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_836",
    "polygon": [
      0.084,
      -15.822,
      16.5,
      -0.848,
      -16.26,
      16.5,
      -0.848,
      -16.26,
      18.73,
      -0.848,
      -16.26,
      18.73,
      0.084,
      -15.822,
      18.73,
      0.084,
      -15.822,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 09:15": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_250",
    "polygon": [
      10.417,
      8.478,
      6.39,
      11.346,
      8.916,
      6.39,
      11.346,
      8.916,
      8.62,
      11.346,
      8.916,
      8.62,
      10.417,
      8.478,
      8.62,
      10.417,
      8.478,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_556",
    "polygon": [
      -75.018,
      -31.724,
      9.76,
      -74.122,
      -31.303,
      9.76,
      -74.122,
      -31.303,
      11.985,
      -74.122,
      -31.303,
      11.985,
      -75.018,
      -31.724,
      11.985,
      -75.018,
      -31.724,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_500",
    "polygon": [
      -49.074,
      -13.105,
      9.76,
      -48.664,
      -13.977,
      9.76,
      -48.664,
      -13.977,
      11.537,
      -48.664,
      -13.977,
      11.537,
      -49.074,
      -13.105,
      11.537,
      -49.074,
      -13.105,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1003",
    "polygon": [
      -51.565,
      -43.626,
      19.876,
      -52.543,
      -41.547,
      19.876,
      -52.543,
      -41.547,
      22.106,
      -52.543,
      -41.547,
      22.106,
      -51.565,
      -43.626,
      22.106,
      -51.565,
      -43.626,
      19.876
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_3.OG_617",
    "polygon": [
      -21.113,
      -25.796,
      13.13,
      -22.045,
      -26.235,
      13.13,
      -22.045,
      -26.235,
      15.36,
      -22.045,
      -26.235,
      15.36,
      -21.113,
      -25.796,
      15.36,
      -21.113,
      -25.796,
      13.13
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_5.OG_993",
    "polygon": [
      -64.064,
      -46.007,
      19.873,
      -64.959,
      -46.429,
      19.873,
      -64.959,
      -46.429,
      22.098,
      -64.959,
      -46.429,
      22.098,
      -64.064,
      -46.007,
      22.098,
      -64.064,
      -46.007,
      19.873
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_821",
    "polygon": [
      -19.539,
      -25.055,
      16.5,
      -20.471,
      -25.494,
      16.5,
      -20.471,
      -25.494,
      18.73,
      -20.471,
      -25.494,
      18.73,
      -19.539,
      -25.055,
      18.73,
      -19.539,
      -25.055,
      16.5
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1082",
    "polygon": [
      -16.274,
      -4.081,
      19.87,
      -15.344,
      -3.644,
      19.87,
      -15.344,
      -3.644,
      22.1,
      -15.344,
      -3.644,
      22.1,
      -16.274,
      -4.081,
      22.1,
      -16.274,
      -4.081,
      19.87
    ]
  },
  {
    "ID": "DINHQ_4.OG_929",
    "polygon": [
      -72.373,
      0.597,
      16.5,
      -72.784,
      1.469,
      16.5,
      -72.784,
      1.469,
      18.277,
      -72.784,
      1.469,
      18.277,
      -72.373,
      0.597,
      18.277,
      -72.373,
      0.597,
      16.5
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_EG_117",
    "polygon": [
      1.529,
      4.295,
      2.485,
      3.344,
      5.149,
      2.485,
      3.344,
      5.149,
      4.38,
      3.344,
      5.149,
      4.38,
      1.529,
      4.295,
      4.38,
      1.529,
      4.295,
      2.485
    ]
  },
  {
    "ID": "DINHQ_1.OG_280",
    "polygon": [
      -29.52,
      -10.315,
      6.39,
      -28.591,
      -9.878,
      6.39,
      -28.591,
      -9.878,
      8.62,
      -28.591,
      -9.878,
      8.62,
      -29.52,
      -10.315,
      8.62,
      -29.52,
      -10.315,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_455",
    "polygon": [
      7.78,
      7.237,
      9.76,
      8.709,
      7.675,
      9.76,
      8.709,
      7.675,
      11.99,
      8.709,
      7.675,
      11.99,
      7.78,
      7.237,
      11.99,
      7.78,
      7.237,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_771",
    "polygon": [
      -90.436,
      -38.979,
      13.13,
      -89.54,
      -38.558,
      13.13,
      -89.54,
      -38.558,
      15.355,
      -89.54,
      -38.558,
      15.355,
      -90.436,
      -38.979,
      15.355,
      -90.436,
      -38.979,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_1018",
    "polygon": [
      -27.489,
      -28.797,
      19.87,
      -28.421,
      -29.235,
      19.87,
      -28.421,
      -29.235,
      22.1,
      -28.421,
      -29.235,
      22.1,
      -27.489,
      -28.797,
      22.1,
      -27.489,
      -28.797,
      19.87
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_97",
    "polygon": [
      -34.704,
      -32.111,
      1.885,
      -36.434,
      -32.925,
      1.885,
      -36.434,
      -32.925,
      4.172,
      -36.434,
      -32.925,
      4.172,
      -34.704,
      -32.111,
      4.172,
      -34.704,
      -32.111,
      1.885
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1054",
    "polygon": [
      20.29,
      -6.313,
      19.87,
      19.358,
      -6.752,
      19.87,
      19.358,
      -6.752,
      22.1,
      19.358,
      -6.752,
      22.1,
      20.29,
      -6.313,
      22.1,
      20.29,
      -6.313,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1145",
    "polygon": [
      -65.477,
      -14.058,
      19.87,
      -65.888,
      -13.186,
      19.87,
      -65.888,
      -13.186,
      21.647,
      -65.888,
      -13.186,
      21.647,
      -65.477,
      -14.058,
      21.647,
      -65.477,
      -14.058,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1107",
    "polygon": [
      -47.925,
      -15.548,
      19.87,
      -47.514,
      -16.421,
      19.87,
      -47.514,
      -16.421,
      21.647,
      -47.514,
      -16.421,
      21.647,
      -47.925,
      -15.548,
      21.647,
      -47.925,
      -15.548,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1310",
    "polygon": [
      -49.074,
      -13.105,
      23.288,
      -48.664,
      -13.977,
      23.288,
      -48.664,
      -13.977,
      26.818,
      -48.664,
      -13.977,
      26.818,
      -49.074,
      -13.105,
      26.818,
      -49.074,
      -13.105,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_678",
    "polygon": [
      -18.923,
      -5.328,
      13.13,
      -17.994,
      -4.891,
      13.13,
      -17.994,
      -4.891,
      15.36,
      -17.994,
      -4.891,
      15.36,
      -18.923,
      -5.328,
      15.36,
      -18.923,
      -5.328,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_615",
    "polygon": [
      -23.764,
      -27.043,
      13.13,
      -24.696,
      -27.482,
      13.13,
      -24.696,
      -27.482,
      15.36,
      -24.696,
      -27.482,
      15.36,
      -23.764,
      -27.043,
      15.36,
      -23.764,
      -27.043,
      13.13
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1
  },
  {
    "ID": "DINHQ_1.OG_296",
    "polygon": [
      -48.639,
      -14.031,
      6.39,
      -48.228,
      -14.903,
      6.39,
      -48.228,
      -14.903,
      8.167,
      -48.228,
      -14.903,
      8.167,
      -48.639,
      -14.031,
      8.167,
      -48.639,
      -14.031,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_883",
    "polygon": [
      -21.572,
      -6.575,
      16.5,
      -20.643,
      -6.138,
      16.5,
      -20.643,
      -6.138,
      18.73,
      -20.643,
      -6.138,
      18.73,
      -21.572,
      -6.575,
      18.73,
      -21.572,
      -6.575,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1068",
    "polygon": [
      2.504,
      4.755,
      19.87,
      3.434,
      5.192,
      19.87,
      3.434,
      5.192,
      22.1,
      3.434,
      5.192,
      22.1,
      2.504,
      4.755,
      22.1,
      2.504,
      4.755,
      19.87
    ]
  },
  {
    "ID": "DINHQ_1.OG_235",
    "polygon": [
      10.911,
      -10.727,
      6.39,
      9.979,
      -11.165,
      6.39,
      9.979,
      -11.165,
      8.62,
      9.979,
      -11.165,
      8.62,
      10.911,
      -10.727,
      8.62,
      10.911,
      -10.727,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_1.OG_260",
    "polygon": [
      -3.027,
      2.152,
      6.39,
      -2.098,
      2.589,
      6.39,
      -2.098,
      2.589,
      8.62,
      -2.098,
      2.589,
      8.62,
      -3.027,
      2.152,
      8.62,
      -3.027,
      2.152,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_705",
    "polygon": [
      -50.224,
      -10.662,
      13.13,
      -49.814,
      -11.534,
      13.13,
      -49.814,
      -11.534,
      14.907,
      -49.814,
      -11.534,
      14.907,
      -50.224,
      -10.662,
      14.907,
      -50.224,
      -10.662,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_388",
    "polygon": [
      -58.924,
      -43.589,
      9.763,
      -59.82,
      -44.01,
      9.763,
      -59.82,
      -44.01,
      11.988,
      -59.82,
      -44.01,
      11.988,
      -58.924,
      -43.589,
      11.988,
      -58.924,
      -43.589,
      9.763
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_768",
    "polygon": [
      -86.832,
      -37.283,
      13.13,
      -85.936,
      -36.862,
      13.13,
      -85.936,
      -36.862,
      15.355,
      -85.936,
      -36.862,
      15.355,
      -86.832,
      -37.283,
      15.355,
      -86.832,
      -37.283,
      13.13
    ]
  },
  {
    "ID": "DINHQ_6.OG_1359",
    "polygon": [
      -66.273,
      -27.609,
      23.288,
      -65.378,
      -27.188,
      23.288,
      -65.378,
      -27.188,
      26.818,
      -65.378,
      -27.188,
      26.818,
      -66.273,
      -27.609,
      26.818,
      -66.273,
      -27.609,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_721",
    "polygon": [
      -59.538,
      9.131,
      13.13,
      -59.01,
      8.009,
      13.13,
      -59.01,
      8.009,
      14.907,
      -59.01,
      8.009,
      14.907,
      -59.538,
      9.131,
      14.907,
      -59.538,
      9.131,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_735",
    "polygon": [
      -67.776,
      -9.172,
      13.13,
      -68.187,
      -8.3,
      13.13,
      -68.187,
      -8.3,
      14.907,
      -68.187,
      -8.3,
      14.907,
      -67.776,
      -9.172,
      14.907,
      -67.776,
      -9.172,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_837",
    "polygon": [
      1.657,
      -15.081,
      16.5,
      0.726,
      -15.52,
      16.5,
      0.726,
      -15.52,
      18.73,
      0.726,
      -15.52,
      18.73,
      1.657,
      -15.081,
      18.73,
      1.657,
      -15.081,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 09:15": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_EG_159",
    "polygon": [
      -48.045,
      -15.291,
      2.485,
      -47.513,
      -16.422,
      2.485,
      -47.513,
      -16.422,
      3.015,
      -47.513,
      -16.422,
      3.015,
      -48.045,
      -15.291,
      3.015,
      -48.045,
      -15.291,
      2.485
    ]
  },
  {
    "ID": "DINHQ_4.OG_949",
    "polygon": [
      -61.315,
      -22.904,
      16.5,
      -61.725,
      -22.032,
      16.5,
      -61.725,
      -22.032,
      18.277,
      -61.725,
      -22.032,
      18.277,
      -61.315,
      -22.904,
      18.277,
      -61.315,
      -22.904,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_484",
    "polygon": [
      -30.598,
      -10.822,
      9.76,
      -29.669,
      -10.385,
      9.76,
      -29.669,
      -10.385,
      11.99,
      -29.669,
      -10.385,
      11.99,
      -30.598,
      -10.822,
      11.99,
      -30.598,
      -10.822,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_285",
    "polygon": [
      -35.897,
      -13.315,
      6.39,
      -34.967,
      -12.878,
      6.39,
      -34.967,
      -12.878,
      8.62,
      -34.967,
      -12.878,
      8.62,
      -35.897,
      -13.315,
      8.62,
      -35.897,
      -13.315,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_463",
    "polygon": [
      -3.027,
      2.152,
      9.76,
      -2.098,
      2.589,
      9.76,
      -2.098,
      2.589,
      11.99,
      -2.098,
      2.589,
      11.99,
      -3.027,
      2.152,
      11.99,
      -3.027,
      2.152,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_451",
    "polygon": [
      13.055,
      9.72,
      9.76,
      13.984,
      10.157,
      9.76,
      13.984,
      10.157,
      11.99,
      13.984,
      10.157,
      11.99,
      13.055,
      9.72,
      11.99,
      13.055,
      9.72,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_230",
    "polygon": [
      4.306,
      -13.835,
      6.39,
      3.374,
      -14.274,
      6.39,
      3.374,
      -14.274,
      8.62,
      3.374,
      -14.274,
      8.62,
      4.306,
      -13.835,
      8.62,
      4.306,
      -13.835,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_1.OG_172",
    "polygon": [
      -75.881,
      -51.568,
      6.393,
      -76.776,
      -51.989,
      6.393,
      -76.776,
      -51.989,
      8.618,
      -76.776,
      -51.989,
      8.618,
      -75.881,
      -51.568,
      8.618,
      -75.881,
      -51.568,
      6.393
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_439",
    "polygon": [
      12.46,
      -9.998,
      9.76,
      11.528,
      -10.436,
      9.76,
      11.528,
      -10.436,
      11.99,
      11.528,
      -10.436,
      11.99,
      12.46,
      -9.998,
      11.99,
      12.46,
      -9.998,
      9.76
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_3.OG_662",
    "polygon": [
      2.504,
      4.755,
      13.13,
      3.434,
      5.192,
      13.13,
      3.434,
      5.192,
      15.36,
      3.434,
      5.192,
      15.36,
      2.504,
      4.755,
      15.36,
      2.504,
      4.755,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_362",
    "polygon": [
      -86.832,
      -37.283,
      6.39,
      -85.936,
      -36.862,
      6.39,
      -85.936,
      -36.862,
      8.615,
      -85.936,
      -36.862,
      8.615,
      -86.832,
      -37.283,
      8.615,
      -86.832,
      -37.283,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_198",
    "polygon": [
      -38.076,
      -33.778,
      6.39,
      -38.854,
      -34.144,
      6.39,
      -38.854,
      -34.144,
      8.62,
      -38.854,
      -34.144,
      8.62,
      -38.076,
      -33.778,
      8.62,
      -38.076,
      -33.778,
      6.39
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1
  },
  {
    "ID": "DINHQ_1.OG_351",
    "polygon": [
      -72.449,
      -30.515,
      6.39,
      -71.553,
      -30.094,
      6.39,
      -71.553,
      -30.094,
      8.615,
      -71.553,
      -30.094,
      8.615,
      -72.449,
      -30.515,
      8.615,
      -72.449,
      -30.515,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_621",
    "polygon": [
      -15.812,
      -23.301,
      13.13,
      -16.744,
      -23.74,
      13.13,
      -16.744,
      -23.74,
      15.36,
      -16.744,
      -23.74,
      15.36,
      -15.812,
      -23.301,
      15.36,
      -15.812,
      -23.301,
      13.13
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1019",
    "polygon": [
      -26.412,
      -28.289,
      19.87,
      -27.344,
      -28.728,
      19.87,
      -27.344,
      -28.728,
      22.1,
      -27.344,
      -28.728,
      22.1,
      -26.412,
      -28.289,
      22.1,
      -26.412,
      -28.289,
      19.87
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1
  },
  {
    "ID": "DINHQ_1.OG_221",
    "polygon": [
      -7.865,
      -19.562,
      6.39,
      -8.797,
      -20.001,
      6.39,
      -8.797,
      -20.001,
      8.62,
      -8.797,
      -20.001,
      8.62,
      -7.865,
      -19.562,
      8.62,
      -7.865,
      -19.562,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_3.OG_692",
    "polygon": [
      -37.468,
      -14.055,
      13.13,
      -36.539,
      -13.618,
      13.13,
      -36.539,
      -13.618,
      15.36,
      -36.539,
      -13.618,
      15.36,
      -37.468,
      -14.055,
      15.36,
      -37.468,
      -14.055,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_74",
    "polygon": [
      -10.242,
      -20.6,
      0.881,
      -12.23,
      -21.536,
      0.881,
      -12.23,
      -21.536,
      1.831,
      -12.23,
      -21.536,
      1.831,
      -10.242,
      -20.6,
      1.831,
      -10.242,
      -20.6,
      0.881
    ],
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_2.OG_554",
    "polygon": [
      -72.449,
      -30.515,
      9.76,
      -71.553,
      -30.094,
      9.76,
      -71.553,
      -30.094,
      11.985,
      -71.553,
      -30.094,
      11.985,
      -72.449,
      -30.515,
      11.985,
      -72.449,
      -30.515,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_734",
    "polygon": [
      -68.212,
      -8.247,
      13.13,
      -68.622,
      -7.374,
      13.13,
      -68.622,
      -7.374,
      14.907,
      -68.622,
      -7.374,
      14.907,
      -68.212,
      -8.247,
      14.907,
      -68.212,
      -8.247,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_312",
    "polygon": [
      -57.836,
      5.515,
      6.39,
      -57.426,
      4.643,
      6.39,
      -57.426,
      4.643,
      8.167,
      -57.426,
      4.643,
      8.167,
      -57.836,
      5.515,
      8.167,
      -57.836,
      5.515,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_387",
    "polygon": [
      -60.462,
      -44.312,
      9.763,
      -61.358,
      -44.734,
      9.763,
      -61.358,
      -44.734,
      11.988,
      -61.358,
      -44.734,
      11.988,
      -60.462,
      -44.312,
      11.988,
      -60.462,
      -44.312,
      9.763
    ],
    "11-07 10:30": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_700",
    "polygon": [
      -47.489,
      -16.474,
      13.13,
      -47.079,
      -17.346,
      13.13,
      -47.079,
      -17.346,
      14.907,
      -47.079,
      -17.346,
      14.907,
      -47.489,
      -16.474,
      14.907,
      -47.489,
      -16.474,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_204",
    "polygon": [
      -30.137,
      -30.043,
      6.39,
      -31.069,
      -30.481,
      6.39,
      -31.069,
      -30.481,
      8.62,
      -31.069,
      -30.481,
      8.62,
      -30.137,
      -30.043,
      8.62,
      -30.137,
      -30.043,
      6.39
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_819",
    "polygon": [
      -22.19,
      -26.303,
      16.5,
      -23.122,
      -26.741,
      16.5,
      -23.122,
      -26.741,
      18.73,
      -23.122,
      -26.741,
      18.73,
      -22.19,
      -26.303,
      18.73,
      -22.19,
      -26.303,
      16.5
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_310",
    "polygon": [
      -56.687,
      3.072,
      6.39,
      -56.276,
      2.199,
      6.39,
      -56.276,
      2.199,
      8.167,
      -56.276,
      2.199,
      8.167,
      -56.687,
      3.072,
      8.167,
      -56.687,
      3.072,
      6.39
    ]
  },
  {
    "ID": "DINHQ_6.OG_1270",
    "polygon": [
      1.313,
      4.195,
      23.288,
      2.367,
      4.691,
      23.288,
      2.367,
      4.691,
      26.818,
      2.367,
      4.691,
      26.818,
      1.313,
      4.195,
      26.818,
      1.313,
      4.195,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_592",
    "polygon": [
      -57.893,
      -43.103,
      13.133,
      -58.788,
      -43.525,
      13.133,
      -58.788,
      -43.525,
      15.358,
      -58.788,
      -43.525,
      15.358,
      -57.893,
      -43.103,
      15.358,
      -57.893,
      -43.103,
      13.133
    ],
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_515",
    "polygon": [
      -57.836,
      5.515,
      9.76,
      -57.426,
      4.643,
      9.76,
      -57.426,
      4.643,
      11.537,
      -57.426,
      4.643,
      11.537,
      -57.836,
      5.515,
      11.537,
      -57.836,
      5.515,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_102",
    "polygon": [
      -53.171,
      -40.797,
      1.941,
      -54.395,
      -41.373,
      1.941,
      -54.395,
      -41.373,
      4.228,
      -54.395,
      -41.373,
      4.228,
      -53.171,
      -40.797,
      4.228,
      -53.171,
      -40.797,
      1.941
    ],
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_3.OG_676",
    "polygon": [
      -16.274,
      -4.081,
      13.13,
      -15.344,
      -3.644,
      13.13,
      -15.344,
      -3.644,
      15.36,
      -15.344,
      -3.644,
      15.36,
      -16.274,
      -4.081,
      15.36,
      -16.274,
      -4.081,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_389",
    "polygon": [
      -57.893,
      -43.103,
      9.763,
      -58.788,
      -43.525,
      9.763,
      -58.788,
      -43.525,
      11.988,
      -58.788,
      -43.525,
      11.988,
      -57.893,
      -43.103,
      11.988,
      -57.893,
      -43.103,
      9.763
    ],
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_668",
    "polygon": [
      -5.676,
      0.905,
      13.13,
      -4.747,
      1.342,
      13.13,
      -4.747,
      1.342,
      15.36,
      -4.747,
      1.342,
      15.36,
      -5.676,
      0.905,
      15.36,
      -5.676,
      0.905,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_493",
    "polygon": [
      -42.777,
      -16.553,
      9.76,
      -41.848,
      -16.116,
      9.76,
      -41.848,
      -16.116,
      11.99,
      -41.848,
      -16.116,
      11.99,
      -42.777,
      -16.553,
      11.99,
      -42.777,
      -16.553,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_871",
    "polygon": [
      -5.676,
      0.905,
      16.5,
      -4.747,
      1.342,
      16.5,
      -4.747,
      1.342,
      18.73,
      -4.747,
      1.342,
      18.73,
      -5.676,
      0.905,
      18.73,
      -5.676,
      0.905,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_232",
    "polygon": [
      6.954,
      -12.589,
      6.39,
      6.022,
      -13.027,
      6.39,
      6.022,
      -13.027,
      8.62,
      6.022,
      -13.027,
      8.62,
      6.954,
      -12.589,
      8.62,
      6.954,
      -12.589,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_4.OG_781",
    "polygon": [
      -75.881,
      -51.568,
      16.503,
      -76.776,
      -51.989,
      16.503,
      -76.776,
      -51.989,
      18.728,
      -76.776,
      -51.989,
      18.728,
      -75.881,
      -51.568,
      18.728,
      -75.881,
      -51.568,
      16.503
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_373",
    "polygon": [
      -78.45,
      -52.777,
      9.763,
      -79.346,
      -53.198,
      9.763,
      -79.346,
      -53.198,
      11.988,
      -79.346,
      -53.198,
      11.988,
      -78.45,
      -52.777,
      11.988,
      -78.45,
      -52.777,
      9.763
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_775",
    "polygon": [
      -83.59,
      -55.195,
      16.503,
      -84.485,
      -55.617,
      16.503,
      -84.485,
      -55.617,
      18.728,
      -84.485,
      -55.617,
      18.728,
      -83.59,
      -55.195,
      18.728,
      -83.59,
      -55.195,
      16.503
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_553",
    "polygon": [
      -71.413,
      -30.028,
      9.76,
      -70.518,
      -29.607,
      9.76,
      -70.518,
      -29.607,
      11.985,
      -70.518,
      -29.607,
      11.985,
      -71.413,
      -30.028,
      11.985,
      -71.413,
      -30.028,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_761",
    "polygon": [
      -77.588,
      -32.934,
      13.13,
      -76.692,
      -32.512,
      13.13,
      -76.692,
      -32.512,
      15.355,
      -76.692,
      -32.512,
      15.355,
      -77.588,
      -32.934,
      15.355,
      -77.588,
      -32.934,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_606",
    "polygon": [
      -35.436,
      -32.536,
      13.13,
      -36.368,
      -32.974,
      13.13,
      -36.368,
      -32.974,
      15.36,
      -36.368,
      -32.974,
      15.36,
      -35.436,
      -32.536,
      15.36,
      -35.436,
      -32.536,
      13.13
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_3.OG_760",
    "polygon": [
      -76.552,
      -32.446,
      13.13,
      -75.656,
      -32.025,
      13.13,
      -75.656,
      -32.025,
      15.355,
      -75.656,
      -32.025,
      15.355,
      -76.552,
      -32.446,
      15.355,
      -76.552,
      -32.446,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_736",
    "polygon": [
      -67.062,
      -10.689,
      13.13,
      -67.473,
      -9.817,
      13.13,
      -67.473,
      -9.817,
      14.907,
      -67.473,
      -9.817,
      14.907,
      -67.062,
      -10.689,
      14.907,
      -67.062,
      -10.689,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_397",
    "polygon": [
      -43.581,
      -39.952,
      9.76,
      -45.698,
      -40.948,
      9.76,
      -45.698,
      -40.948,
      11.99,
      -45.698,
      -40.948,
      11.99,
      -43.581,
      -39.952,
      11.99,
      -43.581,
      -39.952,
      9.76
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1136",
    "polygon": [
      -70.511,
      -3.361,
      19.87,
      -70.922,
      -2.488,
      19.87,
      -70.922,
      -2.488,
      21.647,
      -70.922,
      -2.488,
      21.647,
      -70.511,
      -3.361,
      21.647,
      -70.511,
      -3.361,
      19.87
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_EG_62",
    "polygon": [
      -31.436,
      -30.574,
      0.879,
      -33.425,
      -31.509,
      0.879,
      -33.425,
      -31.509,
      1.829,
      -33.425,
      -31.509,
      1.829,
      -31.436,
      -30.574,
      1.829,
      -31.436,
      -30.574,
      0.879
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_4.OG_811",
    "polygon": [
      -32.788,
      -31.29,
      16.5,
      -33.72,
      -31.728,
      16.5,
      -33.72,
      -31.728,
      18.73,
      -33.72,
      -31.728,
      18.73,
      -32.788,
      -31.29,
      18.73,
      -32.788,
      -31.29,
      16.5
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 13:00": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_2.OG_506",
    "polygon": [
      -52.523,
      -5.775,
      9.76,
      -52.113,
      -6.648,
      9.76,
      -52.113,
      -6.648,
      11.537,
      -52.113,
      -6.648,
      11.537,
      -52.523,
      -5.775,
      11.537,
      -52.523,
      -5.775,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_516",
    "polygon": [
      -58.272,
      6.441,
      9.76,
      -57.862,
      5.569,
      9.76,
      -57.862,
      5.569,
      11.537,
      -57.862,
      5.569,
      11.537,
      -58.272,
      6.441,
      11.537,
      -58.272,
      6.441,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_203",
    "polygon": [
      -31.711,
      -30.783,
      6.39,
      -32.643,
      -31.222,
      6.39,
      -32.643,
      -31.222,
      8.62,
      -32.643,
      -31.222,
      8.62,
      -31.711,
      -30.783,
      8.62,
      -31.711,
      -30.783,
      6.39
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1
  },
  {
    "ID": "DINHQ_3.OG_738",
    "polygon": [
      -65.913,
      -13.132,
      13.13,
      -66.323,
      -12.26,
      13.13,
      -66.323,
      -12.26,
      14.907,
      -66.323,
      -12.26,
      14.907,
      -65.913,
      -13.132,
      14.907,
      -65.913,
      -13.132,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_202",
    "polygon": [
      -32.788,
      -31.29,
      6.39,
      -33.72,
      -31.728,
      6.39,
      -33.72,
      -31.728,
      8.62,
      -33.72,
      -31.728,
      8.62,
      -32.788,
      -31.29,
      8.62,
      -32.788,
      -31.29,
      6.39
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_3.OG_706",
    "polygon": [
      -50.938,
      -9.144,
      13.13,
      -50.528,
      -10.017,
      13.13,
      -50.528,
      -10.017,
      14.907,
      -50.528,
      -10.017,
      14.907,
      -50.938,
      -9.144,
      14.907,
      -50.938,
      -9.144,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_1158",
    "polygon": [
      -66.273,
      -27.609,
      19.87,
      -65.378,
      -27.188,
      19.87,
      -65.378,
      -27.188,
      22.095,
      -65.378,
      -27.188,
      22.095,
      -66.273,
      -27.609,
      22.095,
      -66.273,
      -27.609,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_560",
    "polygon": [
      -80.157,
      -34.142,
      9.76,
      -79.261,
      -33.721,
      9.76,
      -79.261,
      -33.721,
      11.985,
      -79.261,
      -33.721,
      11.985,
      -80.157,
      -34.142,
      11.985,
      -80.157,
      -34.142,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_544",
    "polygon": [
      -60.879,
      -23.83,
      9.76,
      -61.289,
      -22.958,
      9.76,
      -61.289,
      -22.958,
      11.537,
      -61.289,
      -22.958,
      11.537,
      -60.879,
      -23.83,
      11.537,
      -60.879,
      -23.83,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_696",
    "polygon": [
      -42.777,
      -16.553,
      13.13,
      -41.848,
      -16.116,
      13.13,
      -41.848,
      -16.116,
      15.36,
      -41.848,
      -16.116,
      15.36,
      -42.777,
      -16.553,
      15.36,
      -42.777,
      -16.553,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_87",
    "polygon": [
      13.163,
      -9.583,
      1.886,
      9.073,
      -11.507,
      1.886,
      9.073,
      -11.507,
      4.173,
      9.073,
      -11.507,
      4.173,
      13.163,
      -9.583,
      4.173,
      13.163,
      -9.583,
      1.886
    ],
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_4.OG_914",
    "polygon": [
      -53.673,
      -3.332,
      16.5,
      -53.263,
      -4.204,
      16.5,
      -53.263,
      -4.204,
      18.277,
      -53.263,
      -4.204,
      18.277,
      -53.673,
      -3.332,
      18.277,
      -53.673,
      -3.332,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_212",
    "polygon": [
      -19.539,
      -25.055,
      6.39,
      -20.471,
      -25.494,
      6.39,
      -20.471,
      -25.494,
      8.62,
      -20.471,
      -25.494,
      8.62,
      -19.539,
      -25.055,
      8.62,
      -19.539,
      -25.055,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_466",
    "polygon": [
      -6.754,
      0.398,
      9.76,
      -5.825,
      0.835,
      9.76,
      -5.825,
      0.835,
      11.99,
      -5.825,
      0.835,
      11.99,
      -6.754,
      0.398,
      11.99,
      -6.754,
      0.398,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_842",
    "polygon": [
      8.153,
      -12.024,
      16.5,
      7.104,
      -12.518,
      16.5,
      7.104,
      -12.518,
      18.73,
      7.104,
      -12.518,
      18.73,
      8.153,
      -12.024,
      18.73,
      8.153,
      -12.024,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_4.OG_809",
    "polygon": [
      -35.436,
      -32.536,
      16.5,
      -36.368,
      -32.974,
      16.5,
      -36.368,
      -32.974,
      18.73,
      -36.368,
      -32.974,
      18.73,
      -35.436,
      -32.536,
      18.73,
      -35.436,
      -32.536,
      16.5
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_4.OG_964",
    "polygon": [
      -77.588,
      -32.934,
      16.5,
      -76.692,
      -32.512,
      16.5,
      -76.692,
      -32.512,
      18.725,
      -76.692,
      -32.512,
      18.725,
      -77.588,
      -32.934,
      18.725,
      -77.588,
      -32.934,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_186",
    "polygon": [
      -57.893,
      -43.103,
      6.393,
      -58.788,
      -43.525,
      6.393,
      -58.788,
      -43.525,
      8.618,
      -58.788,
      -43.525,
      8.618,
      -57.893,
      -43.103,
      8.618,
      -57.893,
      -43.103,
      6.393
    ],
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_311",
    "polygon": [
      -57.122,
      3.998,
      6.39,
      -56.712,
      3.125,
      6.39,
      -56.712,
      3.125,
      8.167,
      -56.712,
      3.125,
      8.167,
      -57.122,
      3.998,
      8.167,
      -57.122,
      3.998,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_275",
    "polygon": [
      -22.65,
      -7.082,
      6.39,
      -21.721,
      -6.645,
      6.39,
      -21.721,
      -6.645,
      8.62,
      -21.721,
      -6.645,
      8.62,
      -22.65,
      -7.082,
      8.62,
      -22.65,
      -7.082,
      6.39
    ]
  },
  {
    "ID": "DINHQ_6.OG_1209",
    "polygon": [
      -37.891,
      -37.274,
      23.288,
      -40.18,
      -38.351,
      23.288,
      -40.18,
      -38.351,
      26.818,
      -40.18,
      -38.351,
      26.818,
      -37.891,
      -37.274,
      26.818,
      -37.891,
      -37.274,
      23.288
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_3.OG_628",
    "polygon": [
      -6.291,
      -18.821,
      13.13,
      -7.223,
      -19.26,
      13.13,
      -7.223,
      -19.26,
      15.36,
      -7.223,
      -19.26,
      15.36,
      -6.291,
      -18.821,
      15.36,
      -6.291,
      -18.821,
      13.13
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_4.OG_915",
    "polygon": [
      -54.387,
      -1.815,
      16.5,
      -53.977,
      -2.687,
      16.5,
      -53.977,
      -2.687,
      18.277,
      -53.977,
      -2.687,
      18.277,
      -54.387,
      -1.815,
      18.277,
      -54.387,
      -1.815,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_378",
    "polygon": [
      -71.773,
      -49.635,
      9.763,
      -72.668,
      -50.056,
      9.763,
      -72.668,
      -50.056,
      11.988,
      -72.668,
      -50.056,
      11.988,
      -71.773,
      -49.635,
      11.988,
      -71.773,
      -49.635,
      9.763
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_988",
    "polygon": [
      -70.741,
      -49.149,
      19.873,
      -71.637,
      -49.571,
      19.873,
      -71.637,
      -49.571,
      22.098,
      -71.637,
      -49.571,
      22.098,
      -70.741,
      -49.149,
      22.098,
      -70.741,
      -49.149,
      19.873
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_712",
    "polygon": [
      -54.387,
      -1.815,
      13.13,
      -53.977,
      -2.687,
      13.13,
      -53.977,
      -2.687,
      14.907,
      -53.977,
      -2.687,
      14.907,
      -54.387,
      -1.815,
      14.907,
      -54.387,
      -1.815,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_253",
    "polygon": [
      6.713,
      6.735,
      6.39,
      7.642,
      7.173,
      6.39,
      7.642,
      7.173,
      8.62,
      7.642,
      7.173,
      8.62,
      6.713,
      6.735,
      8.62,
      6.713,
      6.735,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1173",
    "polygon": [
      -85.297,
      -36.561,
      19.87,
      -84.401,
      -36.14,
      19.87,
      -84.401,
      -36.14,
      22.095,
      -84.401,
      -36.14,
      22.095,
      -85.297,
      -36.561,
      22.095,
      -85.297,
      -36.561,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_623",
    "polygon": [
      -13.164,
      -22.055,
      13.13,
      -14.096,
      -22.494,
      13.13,
      -14.096,
      -22.494,
      15.36,
      -14.096,
      -22.494,
      15.36,
      -13.164,
      -22.055,
      15.36,
      -13.164,
      -22.055,
      13.13
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_1.OG_214",
    "polygon": [
      -16.891,
      -23.809,
      6.39,
      -17.823,
      -24.248,
      6.39,
      -17.823,
      -24.248,
      8.62,
      -17.823,
      -24.248,
      8.62,
      -16.891,
      -23.809,
      8.62,
      -16.891,
      -23.809,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_229",
    "polygon": [
      2.734,
      -14.575,
      6.39,
      1.802,
      -15.013,
      6.39,
      1.802,
      -15.013,
      8.62,
      1.802,
      -15.013,
      8.62,
      2.734,
      -14.575,
      8.62,
      2.734,
      -14.575,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_1.OG_340",
    "polygon": [
      -61.315,
      -22.904,
      6.39,
      -61.725,
      -22.032,
      6.39,
      -61.725,
      -22.032,
      8.167,
      -61.725,
      -22.032,
      8.167,
      -61.315,
      -22.904,
      8.167,
      -61.315,
      -22.904,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_441",
    "polygon": [
      15.075,
      -8.767,
      9.76,
      14.143,
      -9.206,
      9.76,
      14.143,
      -9.206,
      11.99,
      14.143,
      -9.206,
      11.99,
      15.075,
      -8.767,
      11.99,
      15.075,
      -8.767,
      9.76
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1105",
    "polygon": [
      -46.255,
      -18.19,
      19.87,
      -45.576,
      -17.87,
      19.87,
      -45.576,
      -17.87,
      22.1,
      -45.576,
      -17.87,
      22.1,
      -46.255,
      -18.19,
      22.1,
      -46.255,
      -18.19,
      19.87
    ]
  },
  {
    "ID": "DINHQ_EG_138",
    "polygon": [
      -58.272,
      6.444,
      2.484,
      -57.862,
      5.572,
      2.484,
      -57.862,
      5.572,
      5.25,
      -57.862,
      5.572,
      5.25,
      -58.272,
      6.444,
      5.25,
      -58.272,
      6.444,
      2.484
    ]
  },
  {
    "ID": "DINHQ_6.OG_1260",
    "polygon": [
      14.626,
      10.459,
      23.288,
      15.555,
      10.896,
      23.288,
      15.555,
      10.896,
      26.818,
      15.555,
      10.896,
      26.818,
      14.626,
      10.459,
      26.818,
      14.626,
      10.459,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_576",
    "polygon": [
      -78.45,
      -52.777,
      13.133,
      -79.346,
      -53.198,
      13.133,
      -79.346,
      -53.198,
      15.358,
      -79.346,
      -53.198,
      15.358,
      -78.45,
      -52.777,
      15.358,
      -78.45,
      -52.777,
      13.133
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_EG_104",
    "polygon": [
      -39.908,
      -34.614,
      3.59,
      -43.103,
      -35.621,
      3.59,
      -43.103,
      -35.621,
      4.64,
      -43.103,
      -35.621,
      4.64,
      -39.908,
      -34.614,
      4.64,
      -39.908,
      -34.614,
      3.59
    ]
  },
  {
    "ID": "DINHQ_EG_45",
    "polygon": [
      -71.09,
      -49.229,
      1.941,
      -74.953,
      -51.047,
      1.941,
      -74.953,
      -51.047,
      4.228,
      -74.953,
      -51.047,
      4.228,
      -71.09,
      -49.229,
      4.228,
      -71.09,
      -49.229,
      1.941
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_307",
    "polygon": [
      -54.823,
      -0.889,
      6.39,
      -54.412,
      -1.761,
      6.39,
      -54.412,
      -1.761,
      8.167,
      -54.412,
      -1.761,
      8.167,
      -54.823,
      -0.889,
      8.167,
      -54.823,
      -0.889,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_348",
    "polygon": [
      -68.843,
      -28.819,
      6.39,
      -67.947,
      -28.397,
      6.39,
      -67.947,
      -28.397,
      8.615,
      -67.947,
      -28.397,
      8.615,
      -68.843,
      -28.819,
      8.615,
      -68.843,
      -28.819,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1118",
    "polygon": [
      -54.387,
      -1.815,
      19.87,
      -53.977,
      -2.687,
      19.87,
      -53.977,
      -2.687,
      21.647,
      -53.977,
      -2.687,
      21.647,
      -54.387,
      -1.815,
      21.647,
      -54.387,
      -1.815,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_533",
    "polygon": [
      -67.062,
      -10.689,
      9.76,
      -67.473,
      -9.817,
      9.76,
      -67.473,
      -9.817,
      11.537,
      -67.473,
      -9.817,
      11.537,
      -67.062,
      -10.689,
      11.537,
      -67.062,
      -10.689,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_131",
    "polygon": [
      -43.765,
      -17.017,
      2.485,
      -41.939,
      -16.159,
      2.485,
      -41.939,
      -16.159,
      4.38,
      -41.939,
      -16.159,
      4.38,
      -43.765,
      -17.017,
      4.38,
      -43.765,
      -17.017,
      2.485
    ]
  },
  {
    "ID": "DINHQ_2.OG_535",
    "polygon": [
      -65.913,
      -13.132,
      9.76,
      -66.323,
      -12.26,
      9.76,
      -66.323,
      -12.26,
      11.537,
      -66.323,
      -12.26,
      11.537,
      -65.913,
      -13.132,
      11.537,
      -65.913,
      -13.132,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_755",
    "polygon": [
      -69.879,
      -29.306,
      13.13,
      -68.983,
      -28.884,
      13.13,
      -68.983,
      -28.884,
      15.355,
      -68.983,
      -28.884,
      15.355,
      -69.879,
      -29.306,
      15.355,
      -69.879,
      -29.306,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_359",
    "polygon": [
      -82.727,
      -35.352,
      6.39,
      -81.832,
      -34.931,
      6.39,
      -81.832,
      -34.931,
      8.615,
      -81.832,
      -34.931,
      8.615,
      -82.727,
      -35.352,
      8.615,
      -82.727,
      -35.352,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_731",
    "polygon": [
      -70.075,
      -4.286,
      13.13,
      -70.486,
      -3.414,
      13.13,
      -70.486,
      -3.414,
      14.907,
      -70.486,
      -3.414,
      14.907,
      -70.075,
      -4.286,
      14.907,
      -70.075,
      -4.286,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_402",
    "polygon": [
      -37.01,
      -33.277,
      9.76,
      -37.942,
      -33.715,
      9.76,
      -37.942,
      -33.715,
      11.99,
      -37.942,
      -33.715,
      11.99,
      -37.01,
      -33.277,
      11.99,
      -37.01,
      -33.277,
      9.76
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1
  },
  {
    "ID": "DINHQ_2.OG_374",
    "polygon": [
      -76.912,
      -52.053,
      9.763,
      -77.808,
      -52.475,
      9.763,
      -77.808,
      -52.475,
      11.988,
      -77.808,
      -52.475,
      11.988,
      -76.912,
      -52.053,
      11.988,
      -76.912,
      -52.053,
      9.763
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_480",
    "polygon": [
      -25.299,
      -8.329,
      9.76,
      -24.37,
      -7.891,
      9.76,
      -24.37,
      -7.891,
      11.99,
      -24.37,
      -7.891,
      11.99,
      -25.299,
      -8.329,
      11.99,
      -25.299,
      -8.329,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_346",
    "polygon": [
      -66.273,
      -27.609,
      6.39,
      -65.378,
      -27.188,
      6.39,
      -65.378,
      -27.188,
      8.615,
      -65.378,
      -27.188,
      8.615,
      -66.273,
      -27.609,
      8.615,
      -66.273,
      -27.609,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_675",
    "polygon": [
      -14.702,
      -3.342,
      13.13,
      -13.773,
      -2.905,
      13.13,
      -13.773,
      -2.905,
      15.36,
      -13.773,
      -2.905,
      15.36,
      -14.702,
      -3.342,
      15.36,
      -14.702,
      -3.342,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_584",
    "polygon": [
      -68.171,
      -47.94,
      13.133,
      -69.067,
      -48.362,
      13.133,
      -69.067,
      -48.362,
      15.358,
      -69.067,
      -48.362,
      15.358,
      -68.171,
      -47.94,
      15.358,
      -68.171,
      -47.94,
      13.133
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_586",
    "polygon": [
      -65.602,
      -46.731,
      13.133,
      -66.498,
      -47.152,
      13.133,
      -66.498,
      -47.152,
      15.358,
      -66.498,
      -47.152,
      15.358,
      -65.602,
      -46.731,
      15.358,
      -65.602,
      -46.731,
      13.133
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_622",
    "polygon": [
      -14.238,
      -22.561,
      13.13,
      -15.17,
      -22.999,
      13.13,
      -15.17,
      -22.999,
      15.36,
      -15.17,
      -22.999,
      15.36,
      -14.238,
      -22.561,
      15.36,
      -14.238,
      -22.561,
      13.13
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_EG_148",
    "polygon": [
      -51.374,
      -8.216,
      2.484,
      -50.964,
      -9.088,
      2.484,
      -50.964,
      -9.088,
      5.25,
      -50.964,
      -9.088,
      5.25,
      -51.374,
      -8.216,
      5.25,
      -51.374,
      -8.216,
      2.484
    ]
  },
  {
    "ID": "DINHQ_4.OG_815",
    "polygon": [
      -27.489,
      -28.797,
      16.5,
      -28.421,
      -29.235,
      16.5,
      -28.421,
      -29.235,
      18.73,
      -28.421,
      -29.235,
      18.73,
      -27.489,
      -28.797,
      18.73,
      -27.489,
      -28.797,
      16.5
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_593",
    "polygon": [
      -56.354,
      -42.379,
      13.133,
      -57.25,
      -42.801,
      13.133,
      -57.25,
      -42.801,
      15.358,
      -57.25,
      -42.801,
      15.358,
      -56.354,
      -42.379,
      15.358,
      -56.354,
      -42.379,
      13.133
    ],
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_777",
    "polygon": [
      -81.02,
      -53.986,
      16.503,
      -81.916,
      -54.408,
      16.503,
      -81.916,
      -54.408,
      18.728,
      -81.916,
      -54.408,
      18.728,
      -81.02,
      -53.986,
      18.728,
      -81.02,
      -53.986,
      16.503
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_179",
    "polygon": [
      -66.633,
      -47.216,
      6.393,
      -67.529,
      -47.638,
      6.393,
      -67.529,
      -47.638,
      8.618,
      -67.529,
      -47.638,
      8.618,
      -66.633,
      -47.216,
      8.618,
      -66.633,
      -47.216,
      6.393
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_873",
    "polygon": [
      -8.326,
      -0.341,
      16.5,
      -7.397,
      0.096,
      16.5,
      -7.397,
      0.096,
      18.73,
      -7.397,
      0.096,
      18.73,
      -8.326,
      -0.341,
      18.73,
      -8.326,
      -0.341,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_233",
    "polygon": [
      8.153,
      -12.024,
      6.39,
      7.104,
      -12.518,
      6.39,
      7.104,
      -12.518,
      8.62,
      7.104,
      -12.518,
      8.62,
      8.153,
      -12.024,
      8.62,
      8.153,
      -12.024,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_3.OG_646",
    "polygon": [
      17.675,
      -7.544,
      13.13,
      16.743,
      -7.982,
      13.13,
      16.743,
      -7.982,
      15.36,
      16.743,
      -7.982,
      15.36,
      17.675,
      -7.544,
      15.36,
      17.675,
      -7.544,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_4.OG_851",
    "polygon": [
      20.29,
      -6.313,
      16.5,
      19.358,
      -6.752,
      16.5,
      19.358,
      -6.752,
      18.73,
      19.358,
      -6.752,
      18.73,
      20.29,
      -6.313,
      18.73,
      20.29,
      -6.313,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_874",
    "polygon": [
      -9.403,
      -0.849,
      16.5,
      -8.474,
      -0.411,
      16.5,
      -8.474,
      -0.411,
      18.73,
      -8.474,
      -0.411,
      18.73,
      -9.403,
      -0.849,
      18.73,
      -9.403,
      -0.849,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_740",
    "polygon": [
      -64.763,
      -15.575,
      13.13,
      -65.174,
      -14.703,
      13.13,
      -65.174,
      -14.703,
      14.907,
      -65.174,
      -14.703,
      14.907,
      -64.763,
      -15.575,
      14.907,
      -64.763,
      -15.575,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_658",
    "polygon": [
      7.78,
      7.237,
      13.13,
      8.709,
      7.675,
      13.13,
      8.709,
      7.675,
      15.36,
      8.709,
      7.675,
      15.36,
      7.78,
      7.237,
      15.36,
      7.78,
      7.237,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_420",
    "polygon": [
      -13.164,
      -22.055,
      9.76,
      -14.096,
      -22.494,
      9.76,
      -14.096,
      -22.494,
      11.99,
      -14.096,
      -22.494,
      11.99,
      -13.164,
      -22.055,
      11.99,
      -13.164,
      -22.055,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_2.OG_536",
    "polygon": [
      -65.477,
      -14.058,
      9.76,
      -65.888,
      -13.186,
      9.76,
      -65.888,
      -13.186,
      11.537,
      -65.888,
      -13.186,
      11.537,
      -65.477,
      -14.058,
      11.537,
      -65.477,
      -14.058,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_557",
    "polygon": [
      -76.552,
      -32.446,
      9.76,
      -75.656,
      -32.025,
      9.76,
      -75.656,
      -32.025,
      11.985,
      -75.656,
      -32.025,
      11.985,
      -76.552,
      -32.446,
      11.985,
      -76.552,
      -32.446,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_286",
    "polygon": [
      -37.468,
      -14.055,
      6.39,
      -36.539,
      -13.618,
      6.39,
      -36.539,
      -13.618,
      8.62,
      -36.539,
      -13.618,
      8.62,
      -37.468,
      -14.055,
      8.62,
      -37.468,
      -14.055,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_304",
    "polygon": [
      -53.238,
      -4.258,
      6.39,
      -52.827,
      -5.13,
      6.39,
      -52.827,
      -5.13,
      8.167,
      -52.827,
      -5.13,
      8.167,
      -53.238,
      -4.258,
      8.167,
      -53.238,
      -4.258,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_66",
    "polygon": [
      -18.804,
      -24.629,
      1.885,
      -22.827,
      -26.522,
      1.885,
      -22.827,
      -26.522,
      4.172,
      -22.827,
      -26.522,
      4.172,
      -18.804,
      -24.629,
      4.172,
      -18.804,
      -24.629,
      1.885
    ],
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_3.OG_605",
    "polygon": [
      -37.01,
      -33.277,
      13.13,
      -37.942,
      -33.715,
      13.13,
      -37.942,
      -33.715,
      15.36,
      -37.942,
      -33.715,
      15.36,
      -37.01,
      -33.277,
      15.36,
      -37.01,
      -33.277,
      13.13
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1
  },
  {
    "ID": "DINHQ_1.OG_264",
    "polygon": [
      -8.326,
      -0.341,
      6.39,
      -7.397,
      0.096,
      6.39,
      -7.397,
      0.096,
      8.62,
      -7.397,
      0.096,
      8.62,
      -8.326,
      -0.341,
      8.62,
      -8.326,
      -0.341,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_744",
    "polygon": [
      -62.464,
      -20.461,
      13.13,
      -62.875,
      -19.589,
      13.13,
      -62.875,
      -19.589,
      14.907,
      -62.875,
      -19.589,
      14.907,
      -62.464,
      -20.461,
      14.907,
      -62.464,
      -20.461,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_638",
    "polygon": [
      6.954,
      -12.589,
      13.13,
      6.022,
      -13.027,
      13.13,
      6.022,
      -13.027,
      15.36,
      6.022,
      -13.027,
      15.36,
      6.954,
      -12.589,
      15.36,
      6.954,
      -12.589,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_EG_85",
    "polygon": [
      2.391,
      -14.656,
      0.882,
      0.402,
      -15.592,
      0.882,
      0.402,
      -15.592,
      1.832,
      0.402,
      -15.592,
      1.832,
      2.391,
      -14.656,
      1.832,
      2.391,
      -14.656,
      0.882
    ],
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_EG_152",
    "polygon": [
      -59.537,
      9.132,
      2.484,
      -59.007,
      8.005,
      2.484,
      -59.007,
      8.005,
      5.25,
      -59.007,
      8.005,
      5.25,
      -59.537,
      9.132,
      5.25,
      -59.537,
      9.132,
      2.484
    ]
  },
  {
    "ID": "DINHQ_3.OG_770",
    "polygon": [
      -89.401,
      -38.492,
      13.13,
      -88.505,
      -38.071,
      13.13,
      -88.505,
      -38.071,
      15.355,
      -88.505,
      -38.071,
      15.355,
      -89.401,
      -38.492,
      15.355,
      -89.401,
      -38.492,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_216",
    "polygon": [
      -14.238,
      -22.561,
      6.39,
      -15.17,
      -22.999,
      6.39,
      -15.17,
      -22.999,
      8.62,
      -15.17,
      -22.999,
      8.62,
      -14.238,
      -22.561,
      8.62,
      -14.238,
      -22.561,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1
  },
  {
    "ID": "DINHQ_4.OG_966",
    "polygon": [
      -80.157,
      -34.142,
      16.5,
      -79.261,
      -33.721,
      16.5,
      -79.261,
      -33.721,
      18.725,
      -79.261,
      -33.721,
      18.725,
      -80.157,
      -34.142,
      18.725,
      -80.157,
      -34.142,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_805",
    "polygon": [
      -37.891,
      -37.274,
      16.5,
      -40.18,
      -38.351,
      16.5,
      -40.18,
      -38.351,
      18.73,
      -40.18,
      -38.351,
      18.73,
      -37.891,
      -37.274,
      18.73,
      -37.891,
      -37.274,
      16.5
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_1.OG_274",
    "polygon": [
      -21.572,
      -6.575,
      6.39,
      -20.643,
      -6.138,
      6.39,
      -20.643,
      -6.138,
      8.62,
      -20.643,
      -6.138,
      8.62,
      -21.572,
      -6.575,
      8.62,
      -21.572,
      -6.575,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_176",
    "polygon": [
      -70.741,
      -49.149,
      6.393,
      -71.637,
      -49.571,
      6.393,
      -71.637,
      -49.571,
      8.618,
      -71.637,
      -49.571,
      8.618,
      -70.741,
      -49.149,
      8.618,
      -70.741,
      -49.149,
      6.393
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1363",
    "polygon": [
      -71.413,
      -30.028,
      23.288,
      -70.518,
      -29.607,
      23.288,
      -70.518,
      -29.607,
      26.818,
      -70.518,
      -29.607,
      26.818,
      -71.413,
      -30.028,
      26.818,
      -71.413,
      -30.028,
      23.288
    ]
  },
  {
    "ID": "DINHQ_2.OG_426",
    "polygon": [
      -5.215,
      -18.315,
      9.76,
      -6.147,
      -18.754,
      9.76,
      -6.147,
      -18.754,
      11.99,
      -6.147,
      -18.754,
      11.99,
      -5.215,
      -18.315,
      11.99,
      -5.215,
      -18.315,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_3.OG_580",
    "polygon": [
      -73.311,
      -50.358,
      13.133,
      -74.207,
      -50.78,
      13.133,
      -74.207,
      -50.78,
      15.358,
      -74.207,
      -50.78,
      15.358,
      -73.311,
      -50.358,
      15.358,
      -73.311,
      -50.358,
      13.133
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_364",
    "polygon": [
      -89.401,
      -38.492,
      6.39,
      -88.505,
      -38.071,
      6.39,
      -88.505,
      -38.071,
      8.615,
      -88.505,
      -38.071,
      8.615,
      -89.401,
      -38.492,
      8.615,
      -89.401,
      -38.492,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1170",
    "polygon": [
      -81.692,
      -34.865,
      19.87,
      -80.796,
      -34.443,
      19.87,
      -80.796,
      -34.443,
      22.095,
      -80.796,
      -34.443,
      22.095,
      -81.692,
      -34.865,
      22.095,
      -81.692,
      -34.865,
      19.87
    ]
  },
  {
    "ID": "DINHQ_1.OG_210",
    "polygon": [
      -22.19,
      -26.303,
      6.39,
      -23.122,
      -26.741,
      6.39,
      -23.122,
      -26.741,
      8.62,
      -23.122,
      -26.741,
      8.62,
      -22.19,
      -26.303,
      8.62,
      -22.19,
      -26.303,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_205",
    "polygon": [
      -29.063,
      -29.537,
      6.39,
      -29.995,
      -29.976,
      6.39,
      -29.995,
      -29.976,
      8.62,
      -29.995,
      -29.976,
      8.62,
      -29.063,
      -29.537,
      8.62,
      -29.063,
      -29.537,
      6.39
    ],
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1
  },
  {
    "ID": "DINHQ_3.OG_724",
    "polygon": [
      -70.299,
      4.163,
      13.13,
      -69.073,
      4.74,
      13.13,
      -69.073,
      4.74,
      14.91,
      -69.073,
      4.74,
      14.91,
      -70.299,
      4.163,
      14.91,
      -70.299,
      4.163,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_1120",
    "polygon": [
      -55.537,
      0.628,
      19.87,
      -55.126,
      -0.244,
      19.87,
      -55.126,
      -0.244,
      21.647,
      -55.126,
      -0.244,
      21.647,
      -55.537,
      0.628,
      21.647,
      -55.537,
      0.628,
      19.87
    ]
  },
  {
    "ID": "DINHQ_4.OG_924",
    "polygon": [
      -59.538,
      9.131,
      16.5,
      -59.01,
      8.009,
      16.5,
      -59.01,
      8.009,
      18.277,
      -59.01,
      8.009,
      18.277,
      -59.538,
      9.131,
      18.277,
      -59.538,
      9.131,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_375",
    "polygon": [
      -75.881,
      -51.568,
      9.763,
      -76.776,
      -51.989,
      9.763,
      -76.776,
      -51.989,
      11.988,
      -76.776,
      -51.989,
      11.988,
      -75.881,
      -51.568,
      11.988,
      -75.881,
      -51.568,
      9.763
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_739",
    "polygon": [
      -65.477,
      -14.058,
      13.13,
      -65.888,
      -13.186,
      13.13,
      -65.888,
      -13.186,
      14.907,
      -65.888,
      -13.186,
      14.907,
      -65.477,
      -14.058,
      14.907,
      -65.477,
      -14.058,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_941",
    "polygon": [
      -65.913,
      -13.132,
      16.5,
      -66.323,
      -12.26,
      16.5,
      -66.323,
      -12.26,
      18.277,
      -66.323,
      -12.26,
      18.277,
      -65.913,
      -13.132,
      18.277,
      -65.913,
      -13.132,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_589",
    "polygon": [
      -61.494,
      -44.798,
      13.133,
      -62.39,
      -45.219,
      13.133,
      -62.39,
      -45.219,
      15.358,
      -62.39,
      -45.219,
      15.358,
      -61.494,
      -44.798,
      15.358,
      -61.494,
      -44.798,
      13.133
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:30": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_667",
    "polygon": [
      -4.105,
      1.645,
      13.13,
      -3.176,
      2.082,
      13.13,
      -3.176,
      2.082,
      15.36,
      -3.176,
      2.082,
      15.36,
      -4.105,
      1.645,
      15.36,
      -4.105,
      1.645,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_990",
    "polygon": [
      -68.171,
      -47.94,
      19.873,
      -69.067,
      -48.362,
      19.873,
      -69.067,
      -48.362,
      22.098,
      -69.067,
      -48.362,
      22.098,
      -68.171,
      -47.94,
      22.098,
      -68.171,
      -47.94,
      19.873
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_341",
    "polygon": [
      -60.879,
      -23.83,
      6.39,
      -61.289,
      -22.958,
      6.39,
      -61.289,
      -22.958,
      8.167,
      -61.289,
      -22.958,
      8.167,
      -60.879,
      -23.83,
      8.167,
      -60.879,
      -23.83,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_967",
    "polygon": [
      -81.692,
      -34.865,
      16.5,
      -80.796,
      -34.443,
      16.5,
      -80.796,
      -34.443,
      18.725,
      -80.796,
      -34.443,
      18.725,
      -81.692,
      -34.865,
      18.725,
      -81.692,
      -34.865,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_272",
    "polygon": [
      -18.923,
      -5.328,
      6.39,
      -17.994,
      -4.891,
      6.39,
      -17.994,
      -4.891,
      8.62,
      -17.994,
      -4.891,
      8.62,
      -18.923,
      -5.328,
      8.62,
      -18.923,
      -5.328,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_108",
    "polygon": [
      -47.358,
      -37.459,
      3.59,
      -48.503,
      -38.175,
      3.59,
      -48.503,
      -38.175,
      4.64,
      -48.503,
      -38.175,
      4.64,
      -47.358,
      -37.459,
      4.64,
      -47.358,
      -37.459,
      3.59
    ]
  },
  {
    "ID": "DINHQ_3.OG_688",
    "polygon": [
      -32.17,
      -11.562,
      13.13,
      -31.24,
      -11.124,
      13.13,
      -31.24,
      -11.124,
      15.36,
      -31.24,
      -11.124,
      15.36,
      -32.17,
      -11.562,
      15.36,
      -32.17,
      -11.562,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_538",
    "polygon": [
      -64.328,
      -16.501,
      9.76,
      -64.738,
      -15.629,
      9.76,
      -64.738,
      -15.629,
      11.537,
      -64.738,
      -15.629,
      11.537,
      -64.328,
      -16.501,
      11.537,
      -64.328,
      -16.501,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1174",
    "polygon": [
      -86.832,
      -37.283,
      19.87,
      -85.936,
      -36.862,
      19.87,
      -85.936,
      -36.862,
      22.095,
      -85.936,
      -36.862,
      22.095,
      -86.832,
      -37.283,
      22.095,
      -86.832,
      -37.283,
      19.87
    ]
  },
  {
    "ID": "DINHQ_1.OG_367",
    "polygon": [
      -93.006,
      -40.189,
      6.39,
      -92.11,
      -39.767,
      6.39,
      -92.11,
      -39.767,
      8.615,
      -92.11,
      -39.767,
      8.615,
      -93.006,
      -40.189,
      8.615,
      -93.006,
      -40.189,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_125",
    "polygon": [
      -25.211,
      -8.29,
      2.11,
      -23.386,
      -7.431,
      2.11,
      -23.386,
      -7.431,
      4.38,
      -23.386,
      -7.431,
      4.38,
      -25.211,
      -8.29,
      4.38,
      -25.211,
      -8.29,
      2.11
    ]
  },
  {
    "ID": "DINHQ_2.OG_537",
    "polygon": [
      -64.763,
      -15.575,
      9.76,
      -65.174,
      -14.703,
      9.76,
      -65.174,
      -14.703,
      11.537,
      -65.174,
      -14.703,
      11.537,
      -64.763,
      -15.575,
      11.537,
      -64.763,
      -15.575,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_680",
    "polygon": [
      -21.572,
      -6.575,
      13.13,
      -20.643,
      -6.138,
      13.13,
      -20.643,
      -6.138,
      15.36,
      -20.643,
      -6.138,
      15.36,
      -21.572,
      -6.575,
      15.36,
      -21.572,
      -6.575,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_178",
    "polygon": [
      -68.171,
      -47.94,
      6.393,
      -69.067,
      -48.362,
      6.393,
      -69.067,
      -48.362,
      8.618,
      -69.067,
      -48.362,
      8.618,
      -68.171,
      -47.94,
      8.618,
      -68.171,
      -47.94,
      6.393
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_EG_50",
    "polygon": [
      -65.95,
      -46.81,
      0.935,
      -67.859,
      -47.709,
      0.935,
      -67.859,
      -47.709,
      1.885,
      -67.859,
      -47.709,
      1.885,
      -65.95,
      -46.81,
      1.885,
      -65.95,
      -46.81,
      0.935
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_773",
    "polygon": [
      -93.006,
      -40.189,
      13.13,
      -92.11,
      -39.767,
      13.13,
      -92.11,
      -39.767,
      15.355,
      -92.11,
      -39.767,
      15.355,
      -93.006,
      -40.189,
      15.355,
      -93.006,
      -40.189,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_77",
    "polygon": [
      -4.943,
      -18.107,
      0.881,
      -6.932,
      -19.043,
      0.881,
      -6.932,
      -19.043,
      1.831,
      -6.932,
      -19.043,
      1.831,
      -4.943,
      -18.107,
      1.831,
      -4.943,
      -18.107,
      0.881
    ],
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_EG_147",
    "polygon": [
      -52.09,
      -6.695,
      2.484,
      -51.679,
      -7.568,
      2.484,
      -51.679,
      -7.568,
      5.25,
      -51.679,
      -7.568,
      5.25,
      -52.09,
      -6.695,
      5.25,
      -52.09,
      -6.695,
      2.484
    ]
  },
  {
    "ID": "DINHQ_2.OG_393",
    "polygon": [
      -53.329,
      -40.956,
      9.763,
      -53.645,
      -41.105,
      9.763,
      -53.645,
      -41.105,
      11.988,
      -53.645,
      -41.105,
      11.988,
      -53.329,
      -40.956,
      11.988,
      -53.329,
      -40.956,
      9.763
    ],
    "11-07 11:00": 1
  },
  {
    "ID": "DINHQ_EG_96",
    "polygon": [
      20.314,
      -6.218,
      1.901,
      19.418,
      -6.639,
      1.901,
      19.418,
      -6.639,
      4.126,
      19.418,
      -6.639,
      4.126,
      20.314,
      -6.218,
      4.126,
      20.314,
      -6.218,
      1.901
    ]
  },
  {
    "ID": "DINHQ_2.OG_489",
    "polygon": [
      -37.468,
      -14.055,
      9.76,
      -36.539,
      -13.618,
      9.76,
      -36.539,
      -13.618,
      11.99,
      -36.539,
      -13.618,
      11.99,
      -37.468,
      -14.055,
      11.99,
      -37.468,
      -14.055,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_468",
    "polygon": [
      -9.403,
      -0.849,
      9.76,
      -8.474,
      -0.411,
      9.76,
      -8.474,
      -0.411,
      11.99,
      -8.474,
      -0.411,
      11.99,
      -9.403,
      -0.849,
      11.99,
      -9.403,
      -0.849,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_158",
    "polygon": [
      -48.641,
      -14.024,
      2.485,
      -48.109,
      -15.155,
      2.485,
      -48.109,
      -15.155,
      3.015,
      -48.109,
      -15.155,
      3.015,
      -48.641,
      -14.024,
      3.015,
      -48.641,
      -14.024,
      2.485
    ]
  },
  {
    "ID": "DINHQ_1.OG_282",
    "polygon": [
      -32.17,
      -11.562,
      6.39,
      -31.24,
      -11.124,
      6.39,
      -31.24,
      -11.124,
      8.62,
      -31.24,
      -11.124,
      8.62,
      -32.17,
      -11.562,
      8.62,
      -32.17,
      -11.562,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_149",
    "polygon": [
      -50.94,
      -9.139,
      2.484,
      -50.529,
      -10.011,
      2.484,
      -50.529,
      -10.011,
      5.25,
      -50.529,
      -10.011,
      5.25,
      -50.94,
      -9.139,
      5.25,
      -50.94,
      -9.139,
      2.484
    ]
  },
  {
    "ID": "DINHQ_5.OG_1004",
    "polygon": [
      -46.34,
      -41.25,
      19.87,
      -48.457,
      -42.247,
      19.87,
      -48.457,
      -42.247,
      22.1,
      -48.457,
      -42.247,
      22.1,
      -46.34,
      -41.25,
      22.1,
      -46.34,
      -41.25,
      19.87
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_2.OG_408",
    "polygon": [
      -29.063,
      -29.537,
      9.76,
      -29.995,
      -29.976,
      9.76,
      -29.995,
      -29.976,
      11.99,
      -29.995,
      -29.976,
      11.99,
      -29.063,
      -29.537,
      11.99,
      -29.063,
      -29.537,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1
  },
  {
    "ID": "DINHQ_2.OG_523",
    "polygon": [
      -72.373,
      0.597,
      9.76,
      -72.784,
      1.469,
      9.76,
      -72.784,
      1.469,
      11.537,
      -72.784,
      1.469,
      11.537,
      -72.373,
      0.597,
      11.537,
      -72.373,
      0.597,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_725",
    "polygon": [
      -72.959,
      2.912,
      13.13,
      -70.94,
      3.863,
      13.13,
      -70.94,
      3.863,
      14.91,
      -70.94,
      3.863,
      14.91,
      -72.959,
      2.912,
      14.91,
      -72.959,
      2.912,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_43",
    "polygon": [
      -78.183,
      -52.567,
      0.935,
      -80.092,
      -53.465,
      0.935,
      -80.092,
      -53.465,
      1.885,
      -80.092,
      -53.465,
      1.885,
      -78.183,
      -52.567,
      1.885,
      -78.183,
      -52.567,
      0.935
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_4.OG_936",
    "polygon": [
      -68.927,
      -6.728,
      16.5,
      -69.337,
      -5.855,
      16.5,
      -69.337,
      -5.855,
      18.277,
      -69.337,
      -5.855,
      18.277,
      -68.927,
      -6.728,
      18.277,
      -68.927,
      -6.728,
      16.5
    ],
    "11-07 14:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_1.OG_231",
    "polygon": [
      5.382,
      -13.328,
      6.39,
      4.45,
      -13.767,
      6.39,
      4.45,
      -13.767,
      8.62,
      4.45,
      -13.767,
      8.62,
      5.382,
      -13.328,
      8.62,
      5.382,
      -13.328,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_3.OG_631",
    "polygon": [
      -2.567,
      -17.069,
      13.13,
      -3.499,
      -17.508,
      13.13,
      -3.499,
      -17.508,
      15.36,
      -3.499,
      -17.508,
      15.36,
      -2.567,
      -17.069,
      15.36,
      -2.567,
      -17.069,
      13.13
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_EG_91",
    "polygon": [
      16.372,
      -8.072,
      0.883,
      14.187,
      -9.101,
      0.883,
      14.187,
      -9.101,
      1.833,
      14.187,
      -9.101,
      1.833,
      16.372,
      -8.072,
      1.833,
      16.372,
      -8.072,
      0.883
    ]
  },
  {
    "ID": "DINHQ_5.OG_1005",
    "polygon": [
      -49.1,
      -42.549,
      19.87,
      -51.389,
      -43.626,
      19.87,
      -51.389,
      -43.626,
      22.1,
      -51.389,
      -43.626,
      22.1,
      -49.1,
      -42.549,
      22.1,
      -49.1,
      -42.549,
      19.87
    ],
    "11-07 08:15": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_EG_134",
    "polygon": [
      -30.734,
      -10.89,
      1.99,
      -29.693,
      -10.4,
      1.99,
      -29.693,
      -10.4,
      4.36,
      -29.693,
      -10.4,
      4.36,
      -30.734,
      -10.89,
      4.36,
      -30.734,
      -10.89,
      1.99
    ]
  },
  {
    "ID": "DINHQ_3.OG_741",
    "polygon": [
      -64.328,
      -16.501,
      13.13,
      -64.738,
      -15.629,
      13.13,
      -64.738,
      -15.629,
      14.907,
      -64.738,
      -15.629,
      14.907,
      -64.328,
      -16.501,
      14.907,
      -64.328,
      -16.501,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_652",
    "polygon": [
      15.692,
      10.961,
      13.13,
      16.622,
      11.398,
      13.13,
      16.622,
      11.398,
      15.36,
      16.622,
      11.398,
      15.36,
      15.692,
      10.961,
      15.36,
      15.692,
      10.961,
      13.13
    ]
  },
  {
    "ID": "DINHQ_6.OG_1311",
    "polygon": [
      -49.788,
      -11.588,
      23.288,
      -49.378,
      -12.46,
      23.288,
      -49.378,
      -12.46,
      26.818,
      -49.378,
      -12.46,
      26.818,
      -49.788,
      -11.588,
      26.818,
      -49.788,
      -11.588,
      23.288
    ]
  },
  {
    "ID": "DINHQ_1.OG_240",
    "polygon": [
      17.675,
      -7.544,
      6.39,
      16.743,
      -7.982,
      6.39,
      16.743,
      -7.982,
      8.62,
      16.743,
      -7.982,
      8.62,
      17.675,
      -7.544,
      8.62,
      17.675,
      -7.544,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_1.OG_188",
    "polygon": [
      -55.323,
      -41.894,
      6.393,
      -56.219,
      -42.316,
      6.393,
      -56.219,
      -42.316,
      8.618,
      -56.219,
      -42.316,
      8.618,
      -55.323,
      -41.894,
      8.618,
      -55.323,
      -41.894,
      6.393
    ],
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_EG_129",
    "polygon": [
      -38.455,
      -14.519,
      2.11,
      -36.63,
      -13.66,
      2.11,
      -36.63,
      -13.66,
      4.38,
      -36.63,
      -13.66,
      4.38,
      -38.455,
      -14.519,
      4.38,
      -38.455,
      -14.519,
      2.11
    ]
  },
  {
    "ID": "DINHQ_2.OG_415",
    "polygon": [
      -19.539,
      -25.055,
      9.76,
      -20.471,
      -25.494,
      9.76,
      -20.471,
      -25.494,
      11.99,
      -20.471,
      -25.494,
      11.99,
      -19.539,
      -25.055,
      11.99,
      -19.539,
      -25.055,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_211",
    "polygon": [
      -21.113,
      -25.796,
      6.39,
      -22.045,
      -26.235,
      6.39,
      -22.045,
      -26.235,
      8.62,
      -22.045,
      -26.235,
      8.62,
      -21.113,
      -25.796,
      8.62,
      -21.113,
      -25.796,
      6.39
    ],
    "11-07 11:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1122",
    "polygon": [
      -56.687,
      3.072,
      19.87,
      -56.276,
      2.199,
      19.87,
      -56.276,
      2.199,
      21.647,
      -56.276,
      2.199,
      21.647,
      -56.687,
      3.072,
      21.647,
      -56.687,
      3.072,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1146",
    "polygon": [
      -64.763,
      -15.575,
      19.87,
      -65.174,
      -14.703,
      19.87,
      -65.174,
      -14.703,
      21.647,
      -65.174,
      -14.703,
      21.647,
      -64.763,
      -15.575,
      21.647,
      -64.763,
      -15.575,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_694",
    "polygon": [
      -40.118,
      -15.302,
      13.13,
      -39.188,
      -14.864,
      13.13,
      -39.188,
      -14.864,
      15.36,
      -39.188,
      -14.864,
      15.36,
      -40.118,
      -15.302,
      15.36,
      -40.118,
      -15.302,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_1133",
    "polygon": [
      -72.808,
      1.52,
      19.87,
      -73.337,
      2.645,
      19.87,
      -73.337,
      2.645,
      21.647,
      -73.337,
      2.645,
      21.647,
      -72.808,
      1.52,
      21.647,
      -72.808,
      1.52,
      19.87
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_3.OG_616",
    "polygon": [
      -22.19,
      -26.303,
      13.13,
      -23.122,
      -26.741,
      13.13,
      -23.122,
      -26.741,
      15.36,
      -23.122,
      -26.741,
      15.36,
      -22.19,
      -26.303,
      15.36,
      -22.19,
      -26.303,
      13.13
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_897",
    "polygon": [
      -40.118,
      -15.302,
      16.5,
      -39.188,
      -14.864,
      16.5,
      -39.188,
      -14.864,
      18.73,
      -39.188,
      -14.864,
      18.73,
      -40.118,
      -15.302,
      18.73,
      -40.118,
      -15.302,
      16.5
    ]
  },
  {
    "ID": "DINHQ_EG_144",
    "polygon": [
      -53.674,
      -3.329,
      2.484,
      -53.263,
      -4.201,
      2.484,
      -53.263,
      -4.201,
      5.25,
      -53.263,
      -4.201,
      5.25,
      -53.674,
      -3.329,
      5.25,
      -53.674,
      -3.329,
      2.484
    ]
  },
  {
    "ID": "DINHQ_3.OG_691",
    "polygon": [
      -35.897,
      -13.315,
      13.13,
      -34.967,
      -12.878,
      13.13,
      -34.967,
      -12.878,
      15.36,
      -34.967,
      -12.878,
      15.36,
      -35.897,
      -13.315,
      15.36,
      -35.897,
      -13.315,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_710",
    "polygon": [
      -53.238,
      -4.258,
      13.13,
      -52.827,
      -5.13,
      13.13,
      -52.827,
      -5.13,
      14.907,
      -52.827,
      -5.13,
      14.907,
      -53.238,
      -4.258,
      14.907,
      -53.238,
      -4.258,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_903",
    "polygon": [
      -47.489,
      -16.474,
      16.5,
      -47.079,
      -17.346,
      16.5,
      -47.079,
      -17.346,
      18.277,
      -47.079,
      -17.346,
      18.277,
      -47.489,
      -16.474,
      18.277,
      -47.489,
      -16.474,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_595",
    "polygon": [
      -53.784,
      -41.17,
      13.133,
      -54.68,
      -41.591,
      13.133,
      -54.68,
      -41.591,
      15.358,
      -54.68,
      -41.591,
      15.358,
      -53.784,
      -41.17,
      15.358,
      -53.784,
      -41.17,
      13.133
    ],
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_61",
    "polygon": [
      -29.401,
      -29.616,
      0.879,
      -31.389,
      -30.552,
      0.879,
      -31.389,
      -30.552,
      1.829,
      -31.389,
      -30.552,
      1.829,
      -29.401,
      -29.616,
      1.829,
      -29.401,
      -29.616,
      0.879
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_3.OG_759",
    "polygon": [
      -75.018,
      -31.724,
      13.13,
      -74.122,
      -31.303,
      13.13,
      -74.122,
      -31.303,
      15.355,
      -74.122,
      -31.303,
      15.355,
      -75.018,
      -31.724,
      15.355,
      -75.018,
      -31.724,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_953",
    "polygon": [
      -61.117,
      -25.183,
      16.5,
      -60.755,
      -25.013,
      16.5,
      -60.755,
      -25.013,
      18.725,
      -60.755,
      -25.013,
      18.725,
      -61.117,
      -25.183,
      18.725,
      -61.117,
      -25.183,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_301",
    "polygon": [
      -51.374,
      -8.218,
      6.39,
      -50.963,
      -9.091,
      6.39,
      -50.963,
      -9.091,
      8.167,
      -50.963,
      -9.091,
      8.167,
      -51.374,
      -8.218,
      8.167,
      -51.374,
      -8.218,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_494",
    "polygon": [
      -43.857,
      -17.061,
      9.76,
      -42.928,
      -16.624,
      9.76,
      -42.928,
      -16.624,
      11.99,
      -42.928,
      -16.624,
      11.99,
      -43.857,
      -17.061,
      11.99,
      -43.857,
      -17.061,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_48",
    "polygon": [
      -65.95,
      -46.81,
      1.941,
      -69.814,
      -48.628,
      1.941,
      -69.814,
      -48.628,
      4.228,
      -69.814,
      -48.628,
      4.228,
      -65.95,
      -46.81,
      4.228,
      -65.95,
      -46.81,
      1.941
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_239",
    "polygon": [
      16.144,
      -8.264,
      6.39,
      15.212,
      -8.703,
      6.39,
      15.212,
      -8.703,
      8.62,
      15.212,
      -8.703,
      8.62,
      16.144,
      -8.264,
      8.62,
      16.144,
      -8.264,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_3.OG_756",
    "polygon": [
      -71.413,
      -30.028,
      13.13,
      -70.518,
      -29.607,
      13.13,
      -70.518,
      -29.607,
      15.355,
      -70.518,
      -29.607,
      15.355,
      -71.413,
      -30.028,
      15.355,
      -71.413,
      -30.028,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_383",
    "polygon": [
      -65.602,
      -46.731,
      9.763,
      -66.498,
      -47.152,
      9.763,
      -66.498,
      -47.152,
      11.988,
      -66.498,
      -47.152,
      11.988,
      -65.602,
      -46.731,
      11.988,
      -65.602,
      -46.731,
      9.763
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_567",
    "polygon": [
      -89.401,
      -38.492,
      9.76,
      -88.505,
      -38.071,
      9.76,
      -88.505,
      -38.071,
      11.985,
      -88.505,
      -38.071,
      11.985,
      -89.401,
      -38.492,
      11.985,
      -89.401,
      -38.492,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_404",
    "polygon": [
      -34.362,
      -32.031,
      9.76,
      -35.294,
      -32.469,
      9.76,
      -35.294,
      -32.469,
      11.99,
      -35.294,
      -32.469,
      11.99,
      -34.362,
      -32.031,
      11.99,
      -34.362,
      -32.031,
      9.76
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1
  },
  {
    "ID": "DINHQ_2.OG_461",
    "polygon": [
      -0.383,
      3.396,
      9.76,
      0.671,
      3.892,
      9.76,
      0.671,
      3.892,
      11.99,
      0.671,
      3.892,
      11.99,
      -0.383,
      3.396,
      11.99,
      -0.383,
      3.396,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1040",
    "polygon": [
      1.657,
      -15.081,
      19.87,
      0.726,
      -15.52,
      19.87,
      0.726,
      -15.52,
      22.1,
      0.726,
      -15.52,
      22.1,
      1.657,
      -15.081,
      22.1,
      1.657,
      -15.081,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_337",
    "polygon": [
      -63.178,
      -18.944,
      6.39,
      -63.589,
      -18.072,
      6.39,
      -63.589,
      -18.072,
      8.167,
      -63.589,
      -18.072,
      8.167,
      -63.178,
      -18.944,
      8.167,
      -63.178,
      -18.944,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_634",
    "polygon": [
      1.657,
      -15.081,
      13.13,
      0.726,
      -15.52,
      13.13,
      0.726,
      -15.52,
      15.36,
      0.726,
      -15.52,
      15.36,
      1.657,
      -15.081,
      15.36,
      1.657,
      -15.081,
      13.13
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_629",
    "polygon": [
      -5.215,
      -18.315,
      13.13,
      -6.147,
      -18.754,
      13.13,
      -6.147,
      -18.754,
      15.36,
      -6.147,
      -18.754,
      15.36,
      -5.215,
      -18.315,
      15.36,
      -5.215,
      -18.315,
      13.13
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_EG_47",
    "polygon": [
      -71.089,
      -49.229,
      0.935,
      -72.999,
      -50.127,
      0.935,
      -72.999,
      -50.127,
      1.885,
      -72.999,
      -50.127,
      1.885,
      -71.089,
      -49.229,
      1.885,
      -71.089,
      -49.229,
      0.935
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_542",
    "polygon": [
      -62.029,
      -21.387,
      9.76,
      -62.439,
      -20.515,
      9.76,
      -62.439,
      -20.515,
      11.537,
      -62.439,
      -20.515,
      11.537,
      -62.029,
      -21.387,
      11.537,
      -62.029,
      -21.387,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_279",
    "polygon": [
      -27.949,
      -9.575,
      6.39,
      -27.019,
      -9.138,
      6.39,
      -27.019,
      -9.138,
      8.62,
      -27.019,
      -9.138,
      8.62,
      -27.949,
      -9.575,
      8.62,
      -27.949,
      -9.575,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_548",
    "polygon": [
      -64.739,
      -26.887,
      9.76,
      -63.843,
      -26.466,
      9.76,
      -63.843,
      -26.466,
      11.985,
      -63.843,
      -26.466,
      11.985,
      -64.739,
      -26.887,
      11.985,
      -64.739,
      -26.887,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_218",
    "polygon": [
      -11.59,
      -21.315,
      6.39,
      -12.522,
      -21.753,
      6.39,
      -12.522,
      -21.753,
      8.62,
      -12.522,
      -21.753,
      8.62,
      -11.59,
      -21.315,
      8.62,
      -11.59,
      -21.315,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_2.OG_433",
    "polygon": [
      4.306,
      -13.835,
      9.76,
      3.374,
      -14.274,
      9.76,
      3.374,
      -14.274,
      11.99,
      3.374,
      -14.274,
      11.99,
      4.306,
      -13.835,
      11.99,
      4.306,
      -13.835,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_716",
    "polygon": [
      -56.687,
      3.072,
      13.13,
      -56.276,
      2.199,
      13.13,
      -56.276,
      2.199,
      14.907,
      -56.276,
      2.199,
      14.907,
      -56.687,
      3.072,
      14.907,
      -56.687,
      3.072,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_639",
    "polygon": [
      8.153,
      -12.024,
      13.13,
      7.104,
      -12.518,
      13.13,
      7.104,
      -12.518,
      15.36,
      7.104,
      -12.518,
      15.36,
      8.153,
      -12.024,
      15.36,
      8.153,
      -12.024,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1027",
    "polygon": [
      -15.812,
      -23.301,
      19.87,
      -16.744,
      -23.74,
      19.87,
      -16.744,
      -23.74,
      22.1,
      -16.744,
      -23.74,
      22.1,
      -15.812,
      -23.301,
      22.1,
      -15.812,
      -23.301,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_318",
    "polygon": [
      -70.299,
      4.163,
      6.39,
      -69.073,
      4.74,
      6.39,
      -69.073,
      4.74,
      8.17,
      -69.073,
      4.74,
      8.17,
      -70.299,
      4.163,
      8.17,
      -70.299,
      4.163,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_901",
    "polygon": [
      -45.429,
      -17.801,
      16.5,
      -44.5,
      -17.364,
      16.5,
      -44.5,
      -17.364,
      18.73,
      -44.5,
      -17.364,
      18.73,
      -45.429,
      -17.801,
      18.73,
      -45.429,
      -17.801,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_236",
    "polygon": [
      12.46,
      -9.998,
      6.39,
      11.528,
      -10.436,
      6.39,
      11.528,
      -10.436,
      8.62,
      11.528,
      -10.436,
      8.62,
      12.46,
      -9.998,
      8.62,
      12.46,
      -9.998,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_4.OG_882",
    "polygon": [
      -20.001,
      -5.835,
      16.5,
      -19.071,
      -5.398,
      16.5,
      -19.071,
      -5.398,
      18.73,
      -19.071,
      -5.398,
      18.73,
      -20.001,
      -5.835,
      18.73,
      -20.001,
      -5.835,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_427",
    "polygon": [
      -3.641,
      -17.574,
      9.76,
      -4.573,
      -18.013,
      9.76,
      -4.573,
      -18.013,
      11.99,
      -4.573,
      -18.013,
      11.99,
      -3.641,
      -17.574,
      11.99,
      -3.641,
      -17.574,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_1.OG_268",
    "polygon": [
      -13.624,
      -2.835,
      6.39,
      -12.695,
      -2.398,
      6.39,
      -12.695,
      -2.398,
      8.62,
      -12.695,
      -2.398,
      8.62,
      -13.624,
      -2.835,
      8.62,
      -13.624,
      -2.835,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_801",
    "polygon": [
      -46.34,
      -41.25,
      16.5,
      -48.457,
      -42.247,
      16.5,
      -48.457,
      -42.247,
      18.73,
      -48.457,
      -42.247,
      18.73,
      -46.34,
      -41.25,
      18.73,
      -46.34,
      -41.25,
      16.5
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_1.OG_357",
    "polygon": [
      -80.157,
      -34.142,
      6.39,
      -79.261,
      -33.721,
      6.39,
      -79.261,
      -33.721,
      8.615,
      -79.261,
      -33.721,
      8.615,
      -80.157,
      -34.142,
      8.615,
      -80.157,
      -34.142,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_604",
    "polygon": [
      -38.076,
      -33.778,
      13.13,
      -38.854,
      -34.144,
      13.13,
      -38.854,
      -34.144,
      15.36,
      -38.854,
      -34.144,
      15.36,
      -38.076,
      -33.778,
      15.36,
      -38.076,
      -33.778,
      13.13
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1268",
    "polygon": [
      4.076,
      5.494,
      23.288,
      5.005,
      5.932,
      23.288,
      5.005,
      5.932,
      26.818,
      5.005,
      5.932,
      26.818,
      4.076,
      5.494,
      26.818,
      4.076,
      5.494,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_572",
    "polygon": [
      -83.59,
      -55.195,
      13.133,
      -84.485,
      -55.617,
      13.133,
      -84.485,
      -55.617,
      15.358,
      -84.485,
      -55.617,
      15.358,
      -83.59,
      -55.195,
      15.358,
      -83.59,
      -55.195,
      13.133
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_368",
    "polygon": [
      -84.621,
      -55.681,
      9.763,
      -85.517,
      -56.102,
      9.763,
      -85.517,
      -56.102,
      11.988,
      -85.517,
      -56.102,
      11.988,
      -84.621,
      -55.681,
      11.988,
      -84.621,
      -55.681,
      9.763
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_4.OG_785",
    "polygon": [
      -70.741,
      -49.149,
      16.503,
      -71.637,
      -49.571,
      16.503,
      -71.637,
      -49.571,
      18.728,
      -71.637,
      -49.571,
      18.728,
      -70.741,
      -49.149,
      18.728,
      -70.741,
      -49.149,
      16.503
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_857",
    "polygon": [
      13.055,
      9.72,
      16.5,
      13.984,
      10.157,
      16.5,
      13.984,
      10.157,
      18.73,
      13.984,
      10.157,
      18.73,
      13.055,
      9.72,
      18.73,
      13.055,
      9.72,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_656",
    "polygon": [
      10.417,
      8.478,
      13.13,
      11.346,
      8.916,
      13.13,
      11.346,
      8.916,
      15.36,
      11.346,
      8.916,
      15.36,
      10.417,
      8.478,
      15.36,
      10.417,
      8.478,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_234",
    "polygon": [
      9.837,
      -11.232,
      6.39,
      8.787,
      -11.726,
      6.39,
      8.787,
      -11.726,
      8.62,
      8.787,
      -11.726,
      8.62,
      9.837,
      -11.232,
      8.62,
      9.837,
      -11.232,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_EG_105",
    "polygon": [
      -49.326,
      -38.545,
      0.94,
      -52.148,
      -40.35,
      0.94,
      -52.148,
      -40.35,
      3.54,
      -52.148,
      -40.35,
      3.54,
      -49.326,
      -38.545,
      3.54,
      -49.326,
      -38.545,
      0.94
    ],
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_3.OG_687",
    "polygon": [
      -30.598,
      -10.822,
      13.13,
      -29.669,
      -10.385,
      13.13,
      -29.669,
      -10.385,
      15.36,
      -29.669,
      -10.385,
      15.36,
      -30.598,
      -10.822,
      15.36,
      -30.598,
      -10.822,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_237",
    "polygon": [
      13.526,
      -9.496,
      6.39,
      12.594,
      -9.935,
      6.39,
      12.594,
      -9.935,
      8.62,
      12.594,
      -9.935,
      8.62,
      13.526,
      -9.496,
      8.62,
      13.526,
      -9.496,
      6.39
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_4.OG_849",
    "polygon": [
      17.675,
      -7.544,
      16.5,
      16.743,
      -7.982,
      16.5,
      16.743,
      -7.982,
      18.73,
      16.743,
      -7.982,
      18.73,
      17.675,
      -7.544,
      18.73,
      17.675,
      -7.544,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_618",
    "polygon": [
      -19.539,
      -25.055,
      13.13,
      -20.471,
      -25.494,
      13.13,
      -20.471,
      -25.494,
      15.36,
      -20.471,
      -25.494,
      15.36,
      -19.539,
      -25.055,
      15.36,
      -19.539,
      -25.055,
      13.13
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_867",
    "polygon": [
      -0.383,
      3.396,
      16.5,
      0.671,
      3.892,
      16.5,
      0.671,
      3.892,
      18.73,
      0.671,
      3.892,
      18.73,
      -0.383,
      3.396,
      18.73,
      -0.383,
      3.396,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_864",
    "polygon": [
      4.076,
      5.494,
      16.5,
      5.005,
      5.932,
      16.5,
      5.005,
      5.932,
      18.73,
      5.005,
      5.932,
      18.73,
      4.076,
      5.494,
      18.73,
      4.076,
      5.494,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_898",
    "polygon": [
      -41.195,
      -15.809,
      16.5,
      -40.266,
      -15.371,
      16.5,
      -40.266,
      -15.371,
      18.73,
      -40.266,
      -15.371,
      18.73,
      -41.195,
      -15.809,
      18.73,
      -41.195,
      -15.809,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_528",
    "polygon": [
      -70.075,
      -4.286,
      9.76,
      -70.486,
      -3.414,
      9.76,
      -70.486,
      -3.414,
      11.537,
      -70.486,
      -3.414,
      11.537,
      -70.075,
      -4.286,
      11.537,
      -70.075,
      -4.286,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_492",
    "polygon": [
      -41.195,
      -15.809,
      9.76,
      -40.266,
      -15.371,
      9.76,
      -40.266,
      -15.371,
      11.99,
      -40.266,
      -15.371,
      11.99,
      -41.195,
      -15.809,
      11.99,
      -41.195,
      -15.809,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_343",
    "polygon": [
      -63.707,
      -26.402,
      6.39,
      -62.811,
      -25.98,
      6.39,
      -62.811,
      -25.98,
      8.615,
      -62.811,
      -25.98,
      8.615,
      -63.707,
      -26.402,
      8.615,
      -63.707,
      -26.402,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_862",
    "polygon": [
      6.713,
      6.735,
      16.5,
      7.642,
      7.173,
      16.5,
      7.642,
      7.173,
      18.73,
      7.642,
      7.173,
      18.73,
      6.713,
      6.735,
      18.73,
      6.713,
      6.735,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_334",
    "polygon": [
      -64.763,
      -15.575,
      6.39,
      -65.174,
      -14.703,
      6.39,
      -65.174,
      -14.703,
      8.167,
      -65.174,
      -14.703,
      8.167,
      -64.763,
      -15.575,
      8.167,
      -64.763,
      -15.575,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_470",
    "polygon": [
      -12.053,
      -2.095,
      9.76,
      -11.124,
      -1.658,
      9.76,
      -11.124,
      -1.658,
      11.99,
      -11.124,
      -1.658,
      11.99,
      -12.053,
      -2.095,
      11.99,
      -12.053,
      -2.095,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1053",
    "polygon": [
      18.759,
      -7.034,
      19.87,
      17.827,
      -7.472,
      19.87,
      17.827,
      -7.472,
      22.1,
      17.827,
      -7.472,
      22.1,
      18.759,
      -7.034,
      22.1,
      18.759,
      -7.034,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_313",
    "polygon": [
      -58.272,
      6.441,
      6.39,
      -57.862,
      5.569,
      6.39,
      -57.862,
      5.569,
      8.167,
      -57.862,
      5.569,
      8.167,
      -58.272,
      6.441,
      8.167,
      -58.272,
      6.441,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_531",
    "polygon": [
      -68.212,
      -8.247,
      9.76,
      -68.622,
      -7.374,
      9.76,
      -68.622,
      -7.374,
      11.537,
      -68.622,
      -7.374,
      11.537,
      -68.212,
      -8.247,
      11.537,
      -68.212,
      -8.247,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_587",
    "polygon": [
      -64.064,
      -46.007,
      13.133,
      -64.959,
      -46.429,
      13.133,
      -64.959,
      -46.429,
      15.358,
      -64.959,
      -46.429,
      15.358,
      -64.064,
      -46.007,
      15.358,
      -64.064,
      -46.007,
      13.133
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_702",
    "polygon": [
      -48.639,
      -14.031,
      13.13,
      -48.228,
      -14.903,
      13.13,
      -48.228,
      -14.903,
      14.907,
      -48.228,
      -14.903,
      14.907,
      -48.639,
      -14.031,
      14.907,
      -48.639,
      -14.031,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_643",
    "polygon": [
      13.526,
      -9.496,
      13.13,
      12.594,
      -9.935,
      13.13,
      12.594,
      -9.935,
      15.36,
      12.594,
      -9.935,
      15.36,
      13.526,
      -9.496,
      15.36,
      13.526,
      -9.496,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_206",
    "polygon": [
      -27.489,
      -28.797,
      6.39,
      -28.421,
      -29.235,
      6.39,
      -28.421,
      -29.235,
      8.62,
      -28.421,
      -29.235,
      8.62,
      -27.489,
      -28.797,
      8.62,
      -27.489,
      -28.797,
      6.39
    ],
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_127",
    "polygon": [
      -33.158,
      -12.026,
      2.11,
      -31.333,
      -11.167,
      2.11,
      -31.333,
      -11.167,
      4.38,
      -31.333,
      -11.167,
      4.38,
      -33.158,
      -12.026,
      4.38,
      -33.158,
      -12.026,
      2.11
    ]
  },
  {
    "ID": "DINHQ_1.OG_283",
    "polygon": [
      -33.247,
      -12.069,
      6.39,
      -32.318,
      -11.631,
      6.39,
      -32.318,
      -11.631,
      8.62,
      -32.318,
      -11.631,
      8.62,
      -33.247,
      -12.069,
      8.62,
      -33.247,
      -12.069,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_65",
    "polygon": [
      -26.137,
      -28.08,
      0.881,
      -28.126,
      -29.016,
      0.881,
      -28.126,
      -29.016,
      1.831,
      -28.126,
      -29.016,
      1.831,
      -26.137,
      -28.08,
      1.831,
      -26.137,
      -28.08,
      0.881
    ],
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_1.OG_271",
    "polygon": [
      -17.351,
      -4.589,
      6.39,
      -16.422,
      -4.151,
      6.39,
      -16.422,
      -4.151,
      8.62,
      -16.422,
      -4.151,
      8.62,
      -17.351,
      -4.589,
      8.62,
      -17.351,
      -4.589,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_562",
    "polygon": [
      -82.727,
      -35.352,
      9.76,
      -81.832,
      -34.931,
      9.76,
      -81.832,
      -34.931,
      11.985,
      -81.832,
      -34.931,
      11.985,
      -82.727,
      -35.352,
      11.985,
      -82.727,
      -35.352,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_594",
    "polygon": [
      -55.323,
      -41.894,
      13.133,
      -56.219,
      -42.316,
      13.133,
      -56.219,
      -42.316,
      15.358,
      -56.219,
      -42.316,
      15.358,
      -55.323,
      -41.894,
      15.358,
      -55.323,
      -41.894,
      13.133
    ],
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_183",
    "polygon": [
      -61.494,
      -44.798,
      6.393,
      -62.39,
      -45.219,
      6.393,
      -62.39,
      -45.219,
      8.618,
      -62.39,
      -45.219,
      8.618,
      -61.494,
      -44.798,
      8.618,
      -61.494,
      -44.798,
      6.393
    ],
    "11-07 10:30": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_416",
    "polygon": [
      -18.465,
      -24.55,
      9.76,
      -19.397,
      -24.989,
      9.76,
      -19.397,
      -24.989,
      11.99,
      -19.397,
      -24.989,
      11.99,
      -18.465,
      -24.55,
      11.99,
      -18.465,
      -24.55,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1
  },
  {
    "ID": "DINHQ_1.OG_269",
    "polygon": [
      -14.702,
      -3.342,
      6.39,
      -13.773,
      -2.905,
      6.39,
      -13.773,
      -2.905,
      8.62,
      -13.773,
      -2.905,
      8.62,
      -14.702,
      -3.342,
      8.62,
      -14.702,
      -3.342,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1015",
    "polygon": [
      -31.711,
      -30.783,
      19.87,
      -32.643,
      -31.222,
      19.87,
      -32.643,
      -31.222,
      22.1,
      -32.643,
      -31.222,
      22.1,
      -31.711,
      -30.783,
      22.1,
      -31.711,
      -30.783,
      19.87
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_698",
    "polygon": [
      -45.429,
      -17.801,
      13.13,
      -44.5,
      -17.364,
      13.13,
      -44.5,
      -17.364,
      15.36,
      -44.5,
      -17.364,
      15.36,
      -45.429,
      -17.801,
      15.36,
      -45.429,
      -17.801,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_42",
    "polygon": [
      -76.229,
      -51.647,
      1.941,
      -80.093,
      -53.465,
      1.941,
      -80.093,
      -53.465,
      4.228,
      -80.093,
      -53.465,
      4.228,
      -76.229,
      -51.647,
      4.228,
      -76.229,
      -51.647,
      1.941
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_EG_69",
    "polygon": [
      -13.505,
      -22.136,
      1.885,
      -17.528,
      -24.029,
      1.885,
      -17.528,
      -24.029,
      4.172,
      -17.528,
      -24.029,
      4.172,
      -13.505,
      -22.136,
      4.172,
      -13.505,
      -22.136,
      1.885
    ],
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_2.OG_400",
    "polygon": [
      -38.743,
      -35.061,
      9.76,
      -37.764,
      -37.141,
      9.76,
      -37.764,
      -37.141,
      11.99,
      -37.764,
      -37.141,
      11.99,
      -38.743,
      -35.061,
      11.99,
      -38.743,
      -35.061,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_517",
    "polygon": [
      -58.986,
      7.958,
      9.76,
      -58.576,
      7.086,
      9.76,
      -58.576,
      7.086,
      11.537,
      -58.576,
      7.086,
      11.537,
      -58.986,
      7.958,
      11.537,
      -58.986,
      7.958,
      9.76
    ]
  },
  {
    "ID": "DINHQ_6.OG_1346",
    "polygon": [
      -65.477,
      -14.058,
      23.288,
      -65.888,
      -13.186,
      23.288,
      -65.888,
      -13.186,
      26.818,
      -65.888,
      -13.186,
      26.818,
      -65.477,
      -14.058,
      26.818,
      -65.477,
      -14.058,
      23.288
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_2.OG_501",
    "polygon": [
      -49.788,
      -11.588,
      9.76,
      -49.378,
      -12.46,
      9.76,
      -49.378,
      -12.46,
      11.537,
      -49.378,
      -12.46,
      11.537,
      -49.788,
      -11.588,
      11.537,
      -49.788,
      -11.588,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_577",
    "polygon": [
      -76.912,
      -52.053,
      13.133,
      -77.808,
      -52.475,
      13.133,
      -77.808,
      -52.475,
      15.358,
      -77.808,
      -52.475,
      15.358,
      -76.912,
      -52.053,
      15.358,
      -76.912,
      -52.053,
      13.133
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_569",
    "polygon": [
      -91.97,
      -39.701,
      9.76,
      -91.075,
      -39.28,
      9.76,
      -91.075,
      -39.28,
      11.985,
      -91.075,
      -39.28,
      11.985,
      -91.97,
      -39.701,
      11.985,
      -91.97,
      -39.701,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_817",
    "polygon": [
      -24.838,
      -27.549,
      16.5,
      -25.77,
      -27.987,
      16.5,
      -25.77,
      -27.987,
      18.73,
      -25.77,
      -27.987,
      18.73,
      -24.838,
      -27.549,
      18.73,
      -24.838,
      -27.549,
      16.5
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1001",
    "polygon": [
      -53.784,
      -41.17,
      19.873,
      -54.68,
      -41.591,
      19.873,
      -54.68,
      -41.591,
      22.098,
      -54.68,
      -41.591,
      22.098,
      -53.784,
      -41.17,
      22.098,
      -53.784,
      -41.17,
      19.873
    ],
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_60",
    "polygon": [
      -29.401,
      -29.616,
      1.885,
      -33.424,
      -31.509,
      1.885,
      -33.424,
      -31.509,
      4.172,
      -33.424,
      -31.509,
      4.172,
      -29.401,
      -29.616,
      4.172,
      -29.401,
      -29.616,
      1.885
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_4.OG_863",
    "polygon": [
      5.142,
      5.996,
      16.5,
      6.071,
      6.433,
      16.5,
      6.071,
      6.433,
      18.73,
      6.071,
      6.433,
      18.73,
      5.142,
      5.996,
      18.73,
      5.142,
      5.996,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1349",
    "polygon": [
      -63.614,
      -18.018,
      23.288,
      -64.024,
      -17.146,
      23.288,
      -64.024,
      -17.146,
      26.818,
      -64.024,
      -17.146,
      26.818,
      -63.614,
      -18.018,
      26.818,
      -63.614,
      -18.018,
      23.288
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_3.OG_647",
    "polygon": [
      18.759,
      -7.034,
      13.13,
      17.827,
      -7.472,
      13.13,
      17.827,
      -7.472,
      15.36,
      17.827,
      -7.472,
      15.36,
      18.759,
      -7.034,
      15.36,
      18.759,
      -7.034,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_900",
    "polygon": [
      -43.857,
      -17.061,
      16.5,
      -42.928,
      -16.624,
      16.5,
      -42.928,
      -16.624,
      18.73,
      -42.928,
      -16.624,
      18.73,
      -43.857,
      -17.061,
      18.73,
      -43.857,
      -17.061,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_405",
    "polygon": [
      -32.788,
      -31.29,
      9.76,
      -33.72,
      -31.728,
      9.76,
      -33.72,
      -31.728,
      11.99,
      -33.72,
      -31.728,
      11.99,
      -32.788,
      -31.29,
      11.99,
      -32.788,
      -31.29,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_3.OG_636",
    "polygon": [
      4.306,
      -13.835,
      13.13,
      3.374,
      -14.274,
      13.13,
      3.374,
      -14.274,
      15.36,
      3.374,
      -14.274,
      15.36,
      4.306,
      -13.835,
      15.36,
      4.306,
      -13.835,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_2.OG_520",
    "polygon": [
      -63.886,
      7.181,
      9.76,
      -62.661,
      7.757,
      9.76,
      -62.661,
      7.757,
      11.54,
      -62.661,
      7.757,
      11.54,
      -63.886,
      7.181,
      11.54,
      -63.886,
      7.181,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_532",
    "polygon": [
      -67.776,
      -9.172,
      9.76,
      -68.187,
      -8.3,
      9.76,
      -68.187,
      -8.3,
      11.537,
      -68.187,
      -8.3,
      11.537,
      -67.776,
      -9.172,
      11.537,
      -67.776,
      -9.172,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_380",
    "polygon": [
      -69.203,
      -48.425,
      9.763,
      -70.099,
      -48.847,
      9.763,
      -70.099,
      -48.847,
      11.988,
      -70.099,
      -48.847,
      11.988,
      -69.203,
      -48.425,
      11.988,
      -69.203,
      -48.425,
      9.763
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_732",
    "polygon": [
      -69.362,
      -5.802,
      13.13,
      -69.773,
      -4.93,
      13.13,
      -69.773,
      -4.93,
      14.907,
      -69.773,
      -4.93,
      14.907,
      -69.362,
      -5.802,
      14.907,
      -69.362,
      -5.802,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_308",
    "polygon": [
      -55.537,
      0.628,
      6.39,
      -55.126,
      -0.244,
      6.39,
      -55.126,
      -0.244,
      8.167,
      -55.126,
      -0.244,
      8.167,
      -55.537,
      0.628,
      8.167,
      -55.537,
      0.628,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1079",
    "polygon": [
      -12.053,
      -2.095,
      19.87,
      -11.124,
      -1.658,
      19.87,
      -11.124,
      -1.658,
      22.1,
      -11.124,
      -1.658,
      22.1,
      -12.053,
      -2.095,
      22.1,
      -12.053,
      -2.095,
      19.87
    ]
  },
  {
    "ID": "DINHQ_1.OG_314",
    "polygon": [
      -58.986,
      7.958,
      6.39,
      -58.576,
      7.086,
      6.39,
      -58.576,
      7.086,
      8.167,
      -58.576,
      7.086,
      8.167,
      -58.986,
      7.958,
      8.167,
      -58.986,
      7.958,
      6.39
    ]
  },
  {
    "ID": "DINHQ_6.OG_1350",
    "polygon": [
      -63.178,
      -18.944,
      23.288,
      -63.589,
      -18.072,
      23.288,
      -63.589,
      -18.072,
      26.818,
      -63.589,
      -18.072,
      26.818,
      -63.178,
      -18.944,
      26.818,
      -63.178,
      -18.944,
      23.288
    ],
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_4.OG_878",
    "polygon": [
      -14.702,
      -3.342,
      16.5,
      -13.773,
      -2.905,
      16.5,
      -13.773,
      -2.905,
      18.73,
      -13.773,
      -2.905,
      18.73,
      -14.702,
      -3.342,
      18.73,
      -14.702,
      -3.342,
      16.5
    ]
  },
  {
    "ID": "DINHQ_EG_64",
    "polygon": [
      -24.102,
      -27.123,
      0.881,
      -26.091,
      -28.059,
      0.881,
      -26.091,
      -28.059,
      1.831,
      -26.091,
      -28.059,
      1.831,
      -24.102,
      -27.123,
      1.831,
      -24.102,
      -27.123,
      0.881
    ],
    "11-07 11:30": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_3.OG_766",
    "polygon": [
      -84.262,
      -36.074,
      13.13,
      -83.366,
      -35.653,
      13.13,
      -83.366,
      -35.653,
      15.355,
      -83.366,
      -35.653,
      15.355,
      -84.262,
      -36.074,
      15.355,
      -84.262,
      -36.074,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_573",
    "polygon": [
      -82.051,
      -54.472,
      13.133,
      -82.947,
      -54.893,
      13.133,
      -82.947,
      -54.893,
      15.358,
      -82.947,
      -54.893,
      15.358,
      -82.051,
      -54.472,
      15.358,
      -82.051,
      -54.472,
      13.133
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1057",
    "polygon": [
      23.989,
      -4.573,
      19.87,
      23.057,
      -5.011,
      19.87,
      23.057,
      -5.011,
      22.1,
      23.057,
      -5.011,
      22.1,
      23.989,
      -4.573,
      22.1,
      23.989,
      -4.573,
      19.87
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_213",
    "polygon": [
      -18.465,
      -24.55,
      6.39,
      -19.397,
      -24.989,
      6.39,
      -19.397,
      -24.989,
      8.62,
      -19.397,
      -24.989,
      8.62,
      -18.465,
      -24.55,
      8.62,
      -18.465,
      -24.55,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1
  },
  {
    "ID": "DINHQ_2.OG_485",
    "polygon": [
      -32.17,
      -11.562,
      9.76,
      -31.24,
      -11.124,
      9.76,
      -31.24,
      -11.124,
      11.99,
      -31.24,
      -11.124,
      11.99,
      -32.17,
      -11.562,
      11.99,
      -32.17,
      -11.562,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_512",
    "polygon": [
      -55.973,
      1.554,
      9.76,
      -55.562,
      0.682,
      9.76,
      -55.562,
      0.682,
      11.537,
      -55.562,
      0.682,
      11.537,
      -55.973,
      1.554,
      11.537,
      -55.973,
      1.554,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_876",
    "polygon": [
      -12.053,
      -2.095,
      16.5,
      -11.124,
      -1.658,
      16.5,
      -11.124,
      -1.658,
      18.73,
      -11.124,
      -1.658,
      18.73,
      -12.053,
      -2.095,
      18.73,
      -12.053,
      -2.095,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1296",
    "polygon": [
      -33.247,
      -12.069,
      23.288,
      -32.318,
      -11.631,
      23.288,
      -32.318,
      -11.631,
      26.818,
      -32.318,
      -11.631,
      26.818,
      -33.247,
      -12.069,
      26.818,
      -33.247,
      -12.069,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_685",
    "polygon": [
      -27.949,
      -9.575,
      13.13,
      -27.019,
      -9.138,
      13.13,
      -27.019,
      -9.138,
      15.36,
      -27.019,
      -9.138,
      15.36,
      -27.949,
      -9.575,
      15.36,
      -27.949,
      -9.575,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_98",
    "polygon": [
      -34.705,
      -32.112,
      0.879,
      -36.435,
      -32.926,
      0.879,
      -36.435,
      -32.926,
      1.829,
      -36.435,
      -32.926,
      1.829,
      -34.705,
      -32.112,
      1.829,
      -34.705,
      -32.112,
      0.879
    ],
    "11-07 10:15": 1,
    "11-07 10:45": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_2.OG_525",
    "polygon": [
      -71.661,
      -0.918,
      9.76,
      -72.071,
      -0.045,
      9.76,
      -72.071,
      -0.045,
      11.537,
      -72.071,
      -0.045,
      11.537,
      -71.661,
      -0.918,
      11.537,
      -71.661,
      -0.918,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_746",
    "polygon": [
      -61.315,
      -22.904,
      13.13,
      -61.725,
      -22.032,
      13.13,
      -61.725,
      -22.032,
      14.907,
      -61.725,
      -22.032,
      14.907,
      -61.315,
      -22.904,
      14.907,
      -61.315,
      -22.904,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_789",
    "polygon": [
      -65.602,
      -46.731,
      16.503,
      -66.498,
      -47.152,
      16.503,
      -66.498,
      -47.152,
      18.728,
      -66.498,
      -47.152,
      18.728,
      -65.602,
      -46.731,
      18.728,
      -65.602,
      -46.731,
      16.503
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_277",
    "polygon": [
      -25.299,
      -8.329,
      6.39,
      -24.37,
      -7.891,
      6.39,
      -24.37,
      -7.891,
      8.62,
      -24.37,
      -7.891,
      8.62,
      -25.299,
      -8.329,
      8.62,
      -25.299,
      -8.329,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_328",
    "polygon": [
      -68.212,
      -8.247,
      6.39,
      -68.622,
      -7.374,
      6.39,
      -68.622,
      -7.374,
      8.167,
      -68.622,
      -7.374,
      8.167,
      -68.212,
      -8.247,
      8.167,
      -68.212,
      -8.247,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_833",
    "polygon": [
      -3.641,
      -17.574,
      16.5,
      -4.573,
      -18.013,
      16.5,
      -4.573,
      -18.013,
      18.73,
      -4.573,
      -18.013,
      18.73,
      -3.641,
      -17.574,
      18.73,
      -3.641,
      -17.574,
      16.5
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_910",
    "polygon": [
      -51.374,
      -8.218,
      16.5,
      -50.963,
      -9.091,
      16.5,
      -50.963,
      -9.091,
      18.277,
      -50.963,
      -9.091,
      18.277,
      -51.374,
      -8.218,
      18.277,
      -51.374,
      -8.218,
      16.5
    ]
  },
  {
    "ID": "DINHQ_EG_124",
    "polygon": [
      -22.564,
      -7.041,
      2.11,
      -20.739,
      -6.182,
      2.11,
      -20.739,
      -6.182,
      4.38,
      -20.739,
      -6.182,
      4.38,
      -22.564,
      -7.041,
      4.38,
      -22.564,
      -7.041,
      2.11
    ]
  },
  {
    "ID": "DINHQ_1.OG_303",
    "polygon": [
      -52.523,
      -5.775,
      6.39,
      -52.113,
      -6.648,
      6.39,
      -52.113,
      -6.648,
      8.167,
      -52.113,
      -6.648,
      8.167,
      -52.523,
      -5.775,
      8.167,
      -52.523,
      -5.775,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_790",
    "polygon": [
      -64.064,
      -46.007,
      16.503,
      -64.959,
      -46.429,
      16.503,
      -64.959,
      -46.429,
      18.728,
      -64.959,
      -46.429,
      18.728,
      -64.064,
      -46.007,
      18.728,
      -64.064,
      -46.007,
      16.503
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_258",
    "polygon": [
      -0.383,
      3.396,
      6.39,
      0.671,
      3.892,
      6.39,
      0.671,
      3.892,
      8.62,
      0.671,
      3.892,
      8.62,
      -0.383,
      3.396,
      8.62,
      -0.383,
      3.396,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_682",
    "polygon": [
      -24.222,
      -7.822,
      13.13,
      -23.292,
      -7.384,
      13.13,
      -23.292,
      -7.384,
      15.36,
      -23.292,
      -7.384,
      15.36,
      -24.222,
      -7.822,
      15.36,
      -24.222,
      -7.822,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_788",
    "polygon": [
      -66.633,
      -47.216,
      16.503,
      -67.529,
      -47.638,
      16.503,
      -67.529,
      -47.638,
      18.728,
      -67.529,
      -47.638,
      18.728,
      -66.633,
      -47.216,
      18.728,
      -66.633,
      -47.216,
      16.503
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_370",
    "polygon": [
      -82.051,
      -54.472,
      9.763,
      -82.947,
      -54.893,
      9.763,
      -82.947,
      -54.893,
      11.988,
      -82.947,
      -54.893,
      11.988,
      -82.051,
      -54.472,
      11.988,
      -82.051,
      -54.472,
      9.763
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_350",
    "polygon": [
      -71.413,
      -30.028,
      6.39,
      -70.518,
      -29.607,
      6.39,
      -70.518,
      -29.607,
      8.615,
      -70.518,
      -29.607,
      8.615,
      -71.413,
      -30.028,
      8.615,
      -71.413,
      -30.028,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_443",
    "polygon": [
      17.675,
      -7.544,
      9.76,
      16.743,
      -7.982,
      9.76,
      16.743,
      -7.982,
      11.99,
      16.743,
      -7.982,
      11.99,
      17.675,
      -7.544,
      11.99,
      17.675,
      -7.544,
      9.76
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1175",
    "polygon": [
      -87.866,
      -37.77,
      19.87,
      -86.97,
      -37.349,
      19.87,
      -86.97,
      -37.349,
      22.095,
      -86.97,
      -37.349,
      22.095,
      -87.866,
      -37.77,
      22.095,
      -87.866,
      -37.77,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_474",
    "polygon": [
      -17.351,
      -4.589,
      9.76,
      -16.422,
      -4.151,
      9.76,
      -16.422,
      -4.151,
      11.99,
      -16.422,
      -4.151,
      11.99,
      -17.351,
      -4.589,
      11.99,
      -17.351,
      -4.589,
      9.76
    ]
  },
  {
    "ID": "DINHQ_5.OG_1123",
    "polygon": [
      -57.122,
      3.998,
      19.87,
      -56.712,
      3.125,
      19.87,
      -56.712,
      3.125,
      21.647,
      -56.712,
      3.125,
      21.647,
      -57.122,
      3.998,
      21.647,
      -57.122,
      3.998,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_563",
    "polygon": [
      -84.262,
      -36.074,
      9.76,
      -83.366,
      -35.653,
      9.76,
      -83.366,
      -35.653,
      11.985,
      -83.366,
      -35.653,
      11.985,
      -84.262,
      -36.074,
      11.985,
      -84.262,
      -36.074,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_890",
    "polygon": [
      -30.598,
      -10.822,
      16.5,
      -29.669,
      -10.385,
      16.5,
      -29.669,
      -10.385,
      18.73,
      -29.669,
      -10.385,
      18.73,
      -30.598,
      -10.822,
      18.73,
      -30.598,
      -10.822,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_803",
    "polygon": [
      -43.581,
      -39.952,
      16.5,
      -45.698,
      -40.948,
      16.5,
      -45.698,
      -40.948,
      18.73,
      -45.698,
      -40.948,
      18.73,
      -43.581,
      -39.952,
      18.73,
      -43.581,
      -39.952,
      16.5
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_EG_46",
    "polygon": [
      -73.044,
      -50.148,
      0.935,
      -74.953,
      -51.047,
      0.935,
      -74.953,
      -51.047,
      1.885,
      -74.953,
      -51.047,
      1.885,
      -73.044,
      -50.148,
      1.885,
      -73.044,
      -50.148,
      0.935
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_4.OG_881",
    "polygon": [
      -18.923,
      -5.328,
      16.5,
      -17.994,
      -4.891,
      16.5,
      -17.994,
      -4.891,
      18.73,
      -17.994,
      -4.891,
      18.73,
      -18.923,
      -5.328,
      18.73,
      -18.923,
      -5.328,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_748",
    "polygon": [
      -62.173,
      -25.68,
      13.13,
      -61.277,
      -25.258,
      13.13,
      -61.277,
      -25.258,
      15.355,
      -61.277,
      -25.258,
      15.355,
      -62.173,
      -25.68,
      15.355,
      -62.173,
      -25.68,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_157",
    "polygon": [
      -49.195,
      -12.847,
      2.485,
      -48.662,
      -13.978,
      2.485,
      -48.662,
      -13.978,
      3.015,
      -48.662,
      -13.978,
      3.015,
      -49.195,
      -12.847,
      3.015,
      -49.195,
      -12.847,
      2.485
    ]
  },
  {
    "ID": "DINHQ_6.OG_1338",
    "polygon": [
      -70.075,
      -4.286,
      23.288,
      -70.486,
      -3.414,
      23.288,
      -70.486,
      -3.414,
      26.818,
      -70.486,
      -3.414,
      26.818,
      -70.075,
      -4.286,
      26.818,
      -70.075,
      -4.286,
      23.288
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_4.OG_793",
    "polygon": [
      -60.462,
      -44.312,
      16.503,
      -61.358,
      -44.734,
      16.503,
      -61.358,
      -44.734,
      18.728,
      -61.358,
      -44.734,
      18.728,
      -60.462,
      -44.312,
      18.728,
      -60.462,
      -44.312,
      16.503
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_976",
    "polygon": [
      -93.006,
      -40.189,
      16.5,
      -92.11,
      -39.767,
      16.5,
      -92.11,
      -39.767,
      18.725,
      -92.11,
      -39.767,
      18.725,
      -93.006,
      -40.189,
      18.725,
      -93.006,
      -40.189,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_257",
    "polygon": [
      1.313,
      4.195,
      6.39,
      2.367,
      4.691,
      6.39,
      2.367,
      4.691,
      8.62,
      2.367,
      4.691,
      8.62,
      1.313,
      4.195,
      8.62,
      1.313,
      4.195,
      6.39
    ]
  },
  {
    "ID": "DINHQ_5.OG_1171",
    "polygon": [
      -82.727,
      -35.352,
      19.87,
      -81.832,
      -34.931,
      19.87,
      -81.832,
      -34.931,
      22.095,
      -81.832,
      -34.931,
      22.095,
      -82.727,
      -35.352,
      22.095,
      -82.727,
      -35.352,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_602",
    "polygon": [
      -37.891,
      -37.274,
      13.13,
      -40.18,
      -38.351,
      13.13,
      -40.18,
      -38.351,
      15.36,
      -40.18,
      -38.351,
      15.36,
      -37.891,
      -37.274,
      15.36,
      -37.891,
      -37.274,
      13.13
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_3.OG_601",
    "polygon": [
      -40.822,
      -38.653,
      13.13,
      -42.939,
      -39.65,
      13.13,
      -42.939,
      -39.65,
      15.36,
      -42.939,
      -39.65,
      15.36,
      -40.822,
      -38.653,
      15.36,
      -40.822,
      -38.653,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_2.OG_449",
    "polygon": [
      15.692,
      10.961,
      9.76,
      16.622,
      11.398,
      9.76,
      16.622,
      11.398,
      11.99,
      16.622,
      11.398,
      11.99,
      15.692,
      10.961,
      11.99,
      15.692,
      10.961,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_559",
    "polygon": [
      -79.123,
      -33.656,
      9.76,
      -78.227,
      -33.234,
      9.76,
      -78.227,
      -33.234,
      11.985,
      -78.227,
      -33.234,
      11.985,
      -79.123,
      -33.656,
      11.985,
      -79.123,
      -33.656,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_302",
    "polygon": [
      -52.088,
      -6.701,
      6.39,
      -51.677,
      -7.573,
      6.39,
      -51.677,
      -7.573,
      8.167,
      -51.677,
      -7.573,
      8.167,
      -52.088,
      -6.701,
      8.167,
      -52.088,
      -6.701,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_828",
    "polygon": [
      -10.513,
      -20.808,
      16.5,
      -11.445,
      -21.247,
      16.5,
      -11.445,
      -21.247,
      18.73,
      -11.445,
      -21.247,
      18.73,
      -10.513,
      -20.808,
      18.73,
      -10.513,
      -20.808,
      16.5
    ],
    "11-07 07:30": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_2.OG_428",
    "polygon": [
      -2.567,
      -17.069,
      9.76,
      -3.499,
      -17.508,
      9.76,
      -3.499,
      -17.508,
      11.99,
      -3.499,
      -17.508,
      11.99,
      -2.567,
      -17.069,
      11.99,
      -2.567,
      -17.069,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_4.OG_859",
    "polygon": [
      10.417,
      8.478,
      16.5,
      11.346,
      8.916,
      16.5,
      11.346,
      8.916,
      18.73,
      11.346,
      8.916,
      18.73,
      10.417,
      8.478,
      18.73,
      10.417,
      8.478,
      16.5
    ]
  },
  {
    "ID": "DINHQ_EG_90",
    "polygon": [
      18.367,
      -7.134,
      1.886,
      14.42,
      -8.991,
      1.886,
      14.42,
      -8.991,
      4.173,
      14.42,
      -8.991,
      4.173,
      18.367,
      -7.134,
      4.173,
      18.367,
      -7.134,
      1.886
    ],
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1028",
    "polygon": [
      -14.238,
      -22.561,
      19.87,
      -15.17,
      -22.999,
      19.87,
      -15.17,
      -22.999,
      22.1,
      -15.17,
      -22.999,
      22.1,
      -14.238,
      -22.561,
      22.1,
      -14.238,
      -22.561,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_1.OG_358",
    "polygon": [
      -81.692,
      -34.865,
      6.39,
      -80.796,
      -34.443,
      6.39,
      -80.796,
      -34.443,
      8.615,
      -80.796,
      -34.443,
      8.615,
      -81.692,
      -34.865,
      8.615,
      -81.692,
      -34.865,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_534",
    "polygon": [
      -66.627,
      -11.615,
      9.76,
      -67.037,
      -10.743,
      9.76,
      -67.037,
      -10.743,
      11.537,
      -67.037,
      -10.743,
      11.537,
      -66.627,
      -11.615,
      11.537,
      -66.627,
      -11.615,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_726",
    "polygon": [
      -72.373,
      0.597,
      13.13,
      -72.784,
      1.469,
      13.13,
      -72.784,
      1.469,
      14.907,
      -72.784,
      1.469,
      14.907,
      -72.373,
      0.597,
      14.907,
      -72.373,
      0.597,
      13.13
    ],
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1334",
    "polygon": [
      -72.808,
      1.52,
      23.288,
      -73.337,
      2.645,
      23.288,
      -73.337,
      2.645,
      26.818,
      -73.337,
      2.645,
      26.818,
      -72.808,
      1.52,
      26.818,
      -72.808,
      1.52,
      23.288
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_3.OG_752",
    "polygon": [
      -66.273,
      -27.609,
      13.13,
      -65.378,
      -27.188,
      13.13,
      -65.378,
      -27.188,
      15.355,
      -65.378,
      -27.188,
      15.355,
      -66.273,
      -27.609,
      15.355,
      -66.273,
      -27.609,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_870",
    "polygon": [
      -4.105,
      1.645,
      16.5,
      -3.176,
      2.082,
      16.5,
      -3.176,
      2.082,
      18.73,
      -3.176,
      2.082,
      18.73,
      -4.105,
      1.645,
      18.73,
      -4.105,
      1.645,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_582",
    "polygon": [
      -70.741,
      -49.149,
      13.133,
      -71.637,
      -49.571,
      13.133,
      -71.637,
      -49.571,
      15.358,
      -71.637,
      -49.571,
      15.358,
      -70.741,
      -49.149,
      15.358,
      -70.741,
      -49.149,
      13.133
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_300",
    "polygon": [
      -50.938,
      -9.144,
      6.39,
      -50.528,
      -10.017,
      6.39,
      -50.528,
      -10.017,
      8.167,
      -50.528,
      -10.017,
      8.167,
      -50.938,
      -9.144,
      8.167,
      -50.938,
      -9.144,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_599",
    "polygon": [
      -49.1,
      -42.549,
      13.13,
      -51.389,
      -43.626,
      13.13,
      -51.389,
      -43.626,
      15.36,
      -51.389,
      -43.626,
      15.36,
      -49.1,
      -42.549,
      15.36,
      -49.1,
      -42.549,
      13.13
    ],
    "11-07 11:15": 1,
    "11-07 12:00": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_3.OG_714",
    "polygon": [
      -55.537,
      0.628,
      13.13,
      -55.126,
      -0.244,
      13.13,
      -55.126,
      -0.244,
      14.907,
      -55.126,
      -0.244,
      14.907,
      -55.537,
      0.628,
      14.907,
      -55.537,
      0.628,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_100",
    "polygon": [
      -38.077,
      -33.699,
      1.064,
      -38.928,
      -34.099,
      1.064,
      -38.928,
      -34.099,
      3.364,
      -38.928,
      -34.099,
      3.364,
      -38.077,
      -33.699,
      3.364,
      -38.077,
      -33.699,
      1.064
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1
  },
  {
    "ID": "DINHQ_2.OG_526",
    "polygon": [
      -71.225,
      -1.843,
      9.76,
      -71.635,
      -0.971,
      9.76,
      -71.635,
      -0.971,
      11.537,
      -71.635,
      -0.971,
      11.537,
      -71.225,
      -1.843,
      11.537,
      -71.225,
      -1.843,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_654",
    "polygon": [
      13.055,
      9.72,
      13.13,
      13.984,
      10.157,
      13.13,
      13.984,
      10.157,
      15.36,
      13.984,
      10.157,
      15.36,
      13.055,
      9.72,
      15.36,
      13.055,
      9.72,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_193",
    "polygon": [
      -49.1,
      -42.549,
      6.39,
      -51.389,
      -43.626,
      6.39,
      -51.389,
      -43.626,
      8.62,
      -51.389,
      -43.626,
      8.62,
      -49.1,
      -42.549,
      8.62,
      -49.1,
      -42.549,
      6.39
    ],
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_2.OG_396",
    "polygon": [
      -49.1,
      -42.549,
      9.76,
      -51.389,
      -43.626,
      9.76,
      -51.389,
      -43.626,
      11.99,
      -51.389,
      -43.626,
      11.99,
      -49.1,
      -42.549,
      11.99,
      -49.1,
      -42.549,
      9.76
    ],
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1312",
    "polygon": [
      -50.224,
      -10.662,
      23.288,
      -49.814,
      -11.534,
      23.288,
      -49.814,
      -11.534,
      26.818,
      -49.814,
      -11.534,
      26.818,
      -50.224,
      -10.662,
      26.818,
      -50.224,
      -10.662,
      23.288
    ]
  },
  {
    "ID": "DINHQ_EG_120",
    "polygon": [
      -11.972,
      -2.053,
      2.11,
      -10.147,
      -1.195,
      2.11,
      -10.147,
      -1.195,
      4.38,
      -10.147,
      -1.195,
      4.38,
      -11.972,
      -2.053,
      4.38,
      -11.972,
      -2.053,
      2.11
    ]
  },
  {
    "ID": "DINHQ_2.OG_371",
    "polygon": [
      -81.02,
      -53.986,
      9.763,
      -81.916,
      -54.408,
      9.763,
      -81.916,
      -54.408,
      11.988,
      -81.916,
      -54.408,
      11.988,
      -81.02,
      -53.986,
      11.988,
      -81.02,
      -53.986,
      9.763
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_858",
    "polygon": [
      11.988,
      9.218,
      16.5,
      12.918,
      9.655,
      16.5,
      12.918,
      9.655,
      18.73,
      12.918,
      9.655,
      18.73,
      11.988,
      9.218,
      18.73,
      11.988,
      9.218,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_767",
    "polygon": [
      -85.297,
      -36.561,
      13.13,
      -84.401,
      -36.14,
      13.13,
      -84.401,
      -36.14,
      15.355,
      -84.401,
      -36.14,
      15.355,
      -85.297,
      -36.561,
      15.355,
      -85.297,
      -36.561,
      13.13
    ]
  },
  {
    "ID": "DINHQ_6.OG_1210",
    "polygon": [
      -38.743,
      -35.061,
      23.288,
      -37.764,
      -37.141,
      23.288,
      -37.764,
      -37.141,
      26.818,
      -37.764,
      -37.141,
      26.818,
      -38.743,
      -35.061,
      26.818,
      -38.743,
      -35.061,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_1.OG_316",
    "polygon": [
      -62.02,
      8.06,
      6.39,
      -59.729,
      9.138,
      6.39,
      -59.729,
      9.138,
      8.17,
      -59.729,
      9.138,
      8.17,
      -62.02,
      8.06,
      8.17,
      -62.02,
      8.06,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_938",
    "polygon": [
      -67.776,
      -9.172,
      16.5,
      -68.187,
      -8.3,
      16.5,
      -68.187,
      -8.3,
      18.277,
      -68.187,
      -8.3,
      18.277,
      -67.776,
      -9.172,
      18.277,
      -67.776,
      -9.172,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_287",
    "polygon": [
      -38.546,
      -14.562,
      6.39,
      -37.617,
      -14.125,
      6.39,
      -37.617,
      -14.125,
      8.62,
      -37.617,
      -14.125,
      8.62,
      -38.546,
      -14.562,
      8.62,
      -38.546,
      -14.562,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_266",
    "polygon": [
      -10.975,
      -1.588,
      6.39,
      -10.046,
      -1.151,
      6.39,
      -10.046,
      -1.151,
      8.62,
      -10.046,
      -1.151,
      8.62,
      -10.975,
      -1.588,
      8.62,
      -10.975,
      -1.588,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_884",
    "polygon": [
      -22.65,
      -7.082,
      16.5,
      -21.721,
      -6.645,
      16.5,
      -21.721,
      -6.645,
      18.73,
      -21.721,
      -6.645,
      18.73,
      -22.65,
      -7.082,
      18.73,
      -22.65,
      -7.082,
      16.5
    ]
  },
  {
    "ID": "DINHQ_EG_164",
    "polygon": [
      -47.489,
      -16.473,
      3.062,
      -47.076,
      -17.351,
      3.062,
      -47.076,
      -17.351,
      5.252,
      -47.076,
      -17.351,
      5.252,
      -47.489,
      -16.473,
      5.252,
      -47.489,
      -16.473,
      3.062
    ]
  },
  {
    "ID": "DINHQ_2.OG_390",
    "polygon": [
      -56.354,
      -42.379,
      9.763,
      -57.25,
      -42.801,
      9.763,
      -57.25,
      -42.801,
      11.988,
      -57.25,
      -42.801,
      11.988,
      -56.354,
      -42.379,
      11.988,
      -56.354,
      -42.379,
      9.763
    ],
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_612",
    "polygon": [
      -27.489,
      -28.797,
      13.13,
      -28.421,
      -29.235,
      13.13,
      -28.421,
      -29.235,
      15.36,
      -28.421,
      -29.235,
      15.36,
      -27.489,
      -28.797,
      15.36,
      -27.489,
      -28.797,
      13.13
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1259",
    "polygon": [
      15.692,
      10.961,
      23.288,
      16.622,
      11.398,
      23.288,
      16.622,
      11.398,
      26.818,
      16.622,
      11.398,
      26.818,
      15.692,
      10.961,
      26.818,
      15.692,
      10.961,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1271",
    "polygon": [
      -0.383,
      3.396,
      23.288,
      0.671,
      3.892,
      23.288,
      0.671,
      3.892,
      26.818,
      0.671,
      3.892,
      26.818,
      -0.383,
      3.396,
      26.818,
      -0.383,
      3.396,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_630",
    "polygon": [
      -3.641,
      -17.574,
      13.13,
      -4.573,
      -18.013,
      13.13,
      -4.573,
      -18.013,
      15.36,
      -4.573,
      -18.013,
      15.36,
      -3.641,
      -17.574,
      15.36,
      -3.641,
      -17.574,
      13.13
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_3.OG_751",
    "polygon": [
      -64.739,
      -26.887,
      13.13,
      -63.843,
      -26.466,
      13.13,
      -63.843,
      -26.466,
      15.355,
      -63.843,
      -26.466,
      15.355,
      -64.739,
      -26.887,
      15.355,
      -64.739,
      -26.887,
      13.13
    ]
  },
  {
    "ID": "DINHQ_2.OG_546",
    "polygon": [
      -63.707,
      -26.402,
      9.76,
      -62.811,
      -25.98,
      9.76,
      -62.811,
      -25.98,
      11.985,
      -62.811,
      -25.98,
      11.985,
      -63.707,
      -26.402,
      11.985,
      -63.707,
      -26.402,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_177",
    "polygon": [
      -69.203,
      -48.425,
      6.393,
      -70.099,
      -48.847,
      6.393,
      -70.099,
      -48.847,
      8.618,
      -70.099,
      -48.847,
      8.618,
      -69.203,
      -48.425,
      8.618,
      -69.203,
      -48.425,
      6.393
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_112",
    "polygon": [
      14.722,
      10.501,
      2.485,
      16.445,
      11.312,
      2.485,
      16.445,
      11.312,
      4.38,
      16.445,
      11.312,
      4.38,
      14.722,
      10.501,
      4.38,
      14.722,
      10.501,
      2.485
    ]
  },
  {
    "ID": "DINHQ_5.OG_1147",
    "polygon": [
      -64.328,
      -16.501,
      19.87,
      -64.738,
      -15.629,
      19.87,
      -64.738,
      -15.629,
      21.647,
      -64.738,
      -15.629,
      21.647,
      -64.328,
      -16.501,
      21.647,
      -64.328,
      -16.501,
      19.87
    ]
  },
  {
    "ID": "DINHQ_4.OG_830",
    "polygon": [
      -7.865,
      -19.562,
      16.5,
      -8.797,
      -20.001,
      16.5,
      -8.797,
      -20.001,
      18.73,
      -8.797,
      -20.001,
      18.73,
      -7.865,
      -19.562,
      18.73,
      -7.865,
      -19.562,
      16.5
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_4.OG_784",
    "polygon": [
      -71.773,
      -49.635,
      16.503,
      -72.668,
      -50.056,
      16.503,
      -72.668,
      -50.056,
      18.728,
      -72.668,
      -50.056,
      18.728,
      -71.773,
      -49.635,
      18.728,
      -71.773,
      -49.635,
      16.503
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_185",
    "polygon": [
      -58.924,
      -43.589,
      6.393,
      -59.82,
      -44.01,
      6.393,
      -59.82,
      -44.01,
      8.618,
      -59.82,
      -44.01,
      8.618,
      -58.924,
      -43.589,
      8.618,
      -58.924,
      -43.589,
      6.393
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_335",
    "polygon": [
      -64.328,
      -16.501,
      6.39,
      -64.738,
      -15.629,
      6.39,
      -64.738,
      -15.629,
      8.167,
      -64.738,
      -15.629,
      8.167,
      -64.328,
      -16.501,
      8.167,
      -64.328,
      -16.501,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_247",
    "polygon": [
      14.626,
      10.459,
      6.39,
      15.555,
      10.896,
      6.39,
      15.555,
      10.896,
      8.62,
      15.555,
      10.896,
      8.62,
      14.626,
      10.459,
      8.62,
      14.626,
      10.459,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_113",
    "polygon": [
      12.079,
      9.261,
      2.485,
      13.895,
      10.113,
      2.485,
      13.895,
      10.113,
      4.38,
      13.895,
      10.113,
      4.38,
      12.079,
      9.261,
      4.38,
      12.079,
      9.261,
      2.485
    ]
  },
  {
    "ID": "DINHQ_3.OG_719",
    "polygon": [
      -58.272,
      6.441,
      13.13,
      -57.862,
      5.569,
      13.13,
      -57.862,
      5.569,
      14.907,
      -57.862,
      5.569,
      14.907,
      -58.272,
      6.441,
      14.907,
      -58.272,
      6.441,
      13.13
    ]
  },
  {
    "ID": "DINHQ_EG_151",
    "polygon": [
      -49.79,
      -11.582,
      2.484,
      -49.38,
      -12.454,
      2.484,
      -49.38,
      -12.454,
      5.25,
      -49.38,
      -12.454,
      5.25,
      -49.79,
      -11.582,
      5.25,
      -49.79,
      -11.582,
      2.484
    ]
  },
  {
    "ID": "DINHQ_5.OG_1083",
    "polygon": [
      -17.351,
      -4.589,
      19.87,
      -16.422,
      -4.151,
      19.87,
      -16.422,
      -4.151,
      22.1,
      -16.422,
      -4.151,
      22.1,
      -17.351,
      -4.589,
      22.1,
      -17.351,
      -4.589,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_648",
    "polygon": [
      20.29,
      -6.313,
      13.13,
      19.358,
      -6.752,
      13.13,
      19.358,
      -6.752,
      15.36,
      19.358,
      -6.752,
      15.36,
      20.29,
      -6.313,
      15.36,
      20.29,
      -6.313,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_459",
    "polygon": [
      2.504,
      4.755,
      9.76,
      3.434,
      5.192,
      9.76,
      3.434,
      5.192,
      11.99,
      3.434,
      5.192,
      11.99,
      2.504,
      4.755,
      11.99,
      2.504,
      4.755,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_792",
    "polygon": [
      -61.494,
      -44.798,
      16.503,
      -62.39,
      -45.219,
      16.503,
      -62.39,
      -45.219,
      18.728,
      -62.39,
      -45.219,
      18.728,
      -61.494,
      -44.798,
      18.728,
      -61.494,
      -44.798,
      16.503
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_1.OG_255",
    "polygon": [
      4.076,
      5.494,
      6.39,
      5.005,
      5.932,
      6.39,
      5.005,
      5.932,
      8.62,
      5.005,
      5.932,
      8.62,
      4.076,
      5.494,
      8.62,
      4.076,
      5.494,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_723",
    "polygon": [
      -63.886,
      7.181,
      13.13,
      -62.661,
      7.757,
      13.13,
      -62.661,
      7.757,
      14.91,
      -62.661,
      7.757,
      14.91,
      -63.886,
      7.181,
      14.91,
      -63.886,
      7.181,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_246",
    "polygon": [
      15.692,
      10.961,
      6.39,
      16.622,
      11.398,
      6.39,
      16.622,
      11.398,
      8.62,
      16.622,
      11.398,
      8.62,
      15.692,
      10.961,
      8.62,
      15.692,
      10.961,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_270",
    "polygon": [
      -16.274,
      -4.081,
      6.39,
      -15.344,
      -3.644,
      6.39,
      -15.344,
      -3.644,
      8.62,
      -15.344,
      -3.644,
      8.62,
      -16.274,
      -4.081,
      8.62,
      -16.274,
      -4.081,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_382",
    "polygon": [
      -66.633,
      -47.216,
      9.763,
      -67.529,
      -47.638,
      9.763,
      -67.529,
      -47.638,
      11.988,
      -67.529,
      -47.638,
      11.988,
      -66.633,
      -47.216,
      11.988,
      -66.633,
      -47.216,
      9.763
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_637",
    "polygon": [
      5.382,
      -13.328,
      13.13,
      4.45,
      -13.767,
      13.13,
      4.45,
      -13.767,
      15.36,
      4.45,
      -13.767,
      15.36,
      5.382,
      -13.328,
      15.36,
      5.382,
      -13.328,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1323",
    "polygon": [
      -56.687,
      3.072,
      23.288,
      -56.276,
      2.199,
      23.288,
      -56.276,
      2.199,
      26.818,
      -56.276,
      2.199,
      26.818,
      -56.687,
      3.072,
      26.818,
      -56.687,
      3.072,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1295",
    "polygon": [
      -32.17,
      -11.562,
      23.288,
      -31.24,
      -11.124,
      23.288,
      -31.24,
      -11.124,
      26.818,
      -31.24,
      -11.124,
      26.818,
      -32.17,
      -11.562,
      26.818,
      -32.17,
      -11.562,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_645",
    "polygon": [
      16.144,
      -8.264,
      13.13,
      15.212,
      -8.703,
      13.13,
      15.212,
      -8.703,
      15.36,
      15.212,
      -8.703,
      15.36,
      16.144,
      -8.264,
      15.36,
      16.144,
      -8.264,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_EG_146",
    "polygon": [
      -52.524,
      -5.772,
      2.484,
      -52.113,
      -6.645,
      2.484,
      -52.113,
      -6.645,
      5.25,
      -52.113,
      -6.645,
      5.25,
      -52.524,
      -5.772,
      5.25,
      -52.524,
      -5.772,
      2.484
    ]
  },
  {
    "ID": "DINHQ_EG_162",
    "polygon": [
      -49.135,
      -12.975,
      3.287,
      -48.702,
      -13.895,
      3.287,
      -48.702,
      -13.895,
      5.115,
      -48.702,
      -13.895,
      5.115,
      -49.135,
      -12.975,
      5.115,
      -49.135,
      -12.975,
      3.287
    ]
  },
  {
    "ID": "DINHQ_3.OG_722",
    "polygon": [
      -62.02,
      8.06,
      13.13,
      -59.729,
      9.138,
      13.13,
      -59.729,
      9.138,
      14.91,
      -59.729,
      9.138,
      14.91,
      -62.02,
      8.06,
      14.91,
      -62.02,
      8.06,
      13.13
    ]
  },
  {
    "ID": "DINHQ_1.OG_168",
    "polygon": [
      -81.02,
      -53.986,
      6.393,
      -81.916,
      -54.408,
      6.393,
      -81.916,
      -54.408,
      8.618,
      -81.916,
      -54.408,
      8.618,
      -81.02,
      -53.986,
      8.618,
      -81.02,
      -53.986,
      6.393
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_196",
    "polygon": [
      -37.891,
      -37.274,
      6.39,
      -40.18,
      -38.351,
      6.39,
      -40.18,
      -38.351,
      8.62,
      -40.18,
      -38.351,
      8.62,
      -37.891,
      -37.274,
      8.62,
      -37.891,
      -37.274,
      6.39
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_2.OG_423",
    "polygon": [
      -8.939,
      -20.067,
      9.76,
      -9.871,
      -20.506,
      9.76,
      -9.871,
      -20.506,
      11.99,
      -9.871,
      -20.506,
      11.99,
      -8.939,
      -20.067,
      11.99,
      -8.939,
      -20.067,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_3.OG_733",
    "polygon": [
      -68.927,
      -6.728,
      13.13,
      -69.337,
      -5.855,
      13.13,
      -69.337,
      -5.855,
      14.907,
      -69.337,
      -5.855,
      14.907,
      -68.927,
      -6.728,
      14.907,
      -68.927,
      -6.728,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_763",
    "polygon": [
      -80.157,
      -34.142,
      13.13,
      -79.261,
      -33.721,
      13.13,
      -79.261,
      -33.721,
      15.355,
      -79.261,
      -33.721,
      15.355,
      -80.157,
      -34.142,
      15.355,
      -80.157,
      -34.142,
      13.13
    ]
  },
  {
    "ID": "DINHQ_5.OG_1096",
    "polygon": [
      -34.819,
      -12.808,
      19.87,
      -33.89,
      -12.371,
      19.87,
      -33.89,
      -12.371,
      22.1,
      -33.89,
      -12.371,
      22.1,
      -34.819,
      -12.808,
      22.1,
      -34.819,
      -12.808,
      19.87
    ]
  },
  {
    "ID": "DINHQ_3.OG_677",
    "polygon": [
      -17.351,
      -4.589,
      13.13,
      -16.422,
      -4.151,
      13.13,
      -16.422,
      -4.151,
      15.36,
      -16.422,
      -4.151,
      15.36,
      -17.351,
      -4.589,
      15.36,
      -17.351,
      -4.589,
      13.13
    ]
  },
  {
    "ID": "DINHQ_6.OG_1333",
    "polygon": [
      -72.373,
      0.597,
      23.288,
      -72.784,
      1.469,
      23.288,
      -72.784,
      1.469,
      26.818,
      -72.784,
      1.469,
      26.818,
      -72.373,
      0.597,
      26.818,
      -72.373,
      0.597,
      23.288
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_3.OG_708",
    "polygon": [
      -52.088,
      -6.701,
      13.13,
      -51.677,
      -7.573,
      13.13,
      -51.677,
      -7.573,
      14.907,
      -51.677,
      -7.573,
      14.907,
      -52.088,
      -6.701,
      14.907,
      -52.088,
      -6.701,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_608",
    "polygon": [
      -32.788,
      -31.29,
      13.13,
      -33.72,
      -31.728,
      13.13,
      -33.72,
      -31.728,
      15.36,
      -33.72,
      -31.728,
      15.36,
      -32.788,
      -31.29,
      15.36,
      -32.788,
      -31.29,
      13.13
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_3.OG_613",
    "polygon": [
      -26.412,
      -28.289,
      13.13,
      -27.344,
      -28.728,
      13.13,
      -27.344,
      -28.728,
      15.36,
      -27.344,
      -28.728,
      15.36,
      -26.412,
      -28.289,
      15.36,
      -26.412,
      -28.289,
      13.13
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1
  },
  {
    "ID": "DINHQ_1.OG_209",
    "polygon": [
      -23.764,
      -27.043,
      6.39,
      -24.696,
      -27.482,
      6.39,
      -24.696,
      -27.482,
      8.62,
      -24.696,
      -27.482,
      8.62,
      -23.764,
      -27.043,
      8.62,
      -23.764,
      -27.043,
      6.39
    ],
    "11-07 11:30": 1
  },
  {
    "ID": "DINHQ_2.OG_432",
    "polygon": [
      2.734,
      -14.575,
      9.76,
      1.802,
      -15.013,
      9.76,
      1.802,
      -15.013,
      11.99,
      1.802,
      -15.013,
      11.99,
      2.734,
      -14.575,
      11.99,
      2.734,
      -14.575,
      9.76
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_1.OG_220",
    "polygon": [
      -8.939,
      -20.067,
      6.39,
      -9.871,
      -20.506,
      6.39,
      -9.871,
      -20.506,
      8.62,
      -9.871,
      -20.506,
      8.62,
      -8.939,
      -20.067,
      8.62,
      -8.939,
      -20.067,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_3.OG_585",
    "polygon": [
      -66.633,
      -47.216,
      13.133,
      -67.529,
      -47.638,
      13.133,
      -67.529,
      -47.638,
      15.358,
      -67.529,
      -47.638,
      15.358,
      -66.633,
      -47.216,
      15.358,
      -66.633,
      -47.216,
      13.133
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1030",
    "polygon": [
      -11.59,
      -21.315,
      19.87,
      -12.522,
      -21.753,
      19.87,
      -12.522,
      -21.753,
      22.1,
      -12.522,
      -21.753,
      22.1,
      -11.59,
      -21.315,
      22.1,
      -11.59,
      -21.315,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1029",
    "polygon": [
      -13.164,
      -22.055,
      19.87,
      -14.096,
      -22.494,
      19.87,
      -14.096,
      -22.494,
      22.1,
      -14.096,
      -22.494,
      22.1,
      -13.164,
      -22.055,
      22.1,
      -13.164,
      -22.055,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_292",
    "polygon": [
      -45.429,
      -17.801,
      6.39,
      -44.5,
      -17.364,
      6.39,
      -44.5,
      -17.364,
      8.62,
      -44.5,
      -17.364,
      8.62,
      -45.429,
      -17.801,
      8.62,
      -45.429,
      -17.801,
      6.39
    ]
  },
  {
    "ID": "DINHQ_4.OG_912",
    "polygon": [
      -52.523,
      -5.775,
      16.5,
      -52.113,
      -6.648,
      16.5,
      -52.113,
      -6.648,
      18.277,
      -52.113,
      -6.648,
      18.277,
      -52.523,
      -5.775,
      18.277,
      -52.523,
      -5.775,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_992",
    "polygon": [
      -65.602,
      -46.731,
      19.873,
      -66.498,
      -47.152,
      19.873,
      -66.498,
      -47.152,
      22.098,
      -66.498,
      -47.152,
      22.098,
      -65.602,
      -46.731,
      22.098,
      -65.602,
      -46.731,
      19.873
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_769",
    "polygon": [
      -87.866,
      -37.77,
      13.13,
      -86.97,
      -37.349,
      13.13,
      -86.97,
      -37.349,
      15.355,
      -86.97,
      -37.349,
      15.355,
      -87.866,
      -37.77,
      15.355,
      -87.866,
      -37.77,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_865",
    "polygon": [
      2.504,
      4.755,
      16.5,
      3.434,
      5.192,
      16.5,
      3.434,
      5.192,
      18.73,
      3.434,
      5.192,
      18.73,
      2.504,
      4.755,
      18.73,
      2.504,
      4.755,
      16.5
    ]
  },
  {
    "ID": "DINHQ_EG_53",
    "polygon": [
      -60.811,
      -44.392,
      0.935,
      -62.72,
      -45.29,
      0.935,
      -62.72,
      -45.29,
      1.885,
      -62.72,
      -45.29,
      1.885,
      -60.811,
      -44.392,
      1.885,
      -60.811,
      -44.392,
      0.935
    ],
    "11-07 10:30": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1283",
    "polygon": [
      -16.274,
      -4.081,
      23.288,
      -15.344,
      -3.644,
      23.288,
      -15.344,
      -3.644,
      26.818,
      -15.344,
      -3.644,
      26.818,
      -16.274,
      -4.081,
      26.818,
      -16.274,
      -4.081,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_743",
    "polygon": [
      -63.178,
      -18.944,
      13.13,
      -63.589,
      -18.072,
      13.13,
      -63.589,
      -18.072,
      14.907,
      -63.589,
      -18.072,
      14.907,
      -63.178,
      -18.944,
      14.907,
      -63.178,
      -18.944,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_916",
    "polygon": [
      -54.823,
      -0.889,
      16.5,
      -54.412,
      -1.761,
      16.5,
      -54.412,
      -1.761,
      18.277,
      -54.412,
      -1.761,
      18.277,
      -54.823,
      -0.889,
      18.277,
      -54.823,
      -0.889,
      16.5
    ]
  },
  {
    "ID": "DINHQ_2.OG_482",
    "polygon": [
      -27.949,
      -9.575,
      9.76,
      -27.019,
      -9.138,
      9.76,
      -27.019,
      -9.138,
      11.99,
      -27.019,
      -9.138,
      11.99,
      -27.949,
      -9.575,
      11.99,
      -27.949,
      -9.575,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_633",
    "polygon": [
      0.084,
      -15.822,
      13.13,
      -0.848,
      -16.26,
      13.13,
      -0.848,
      -16.26,
      15.36,
      -0.848,
      -16.26,
      15.36,
      0.084,
      -15.822,
      15.36,
      0.084,
      -15.822,
      13.13
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_431",
    "polygon": [
      1.657,
      -15.081,
      9.76,
      0.726,
      -15.52,
      9.76,
      0.726,
      -15.52,
      11.99,
      0.726,
      -15.52,
      11.99,
      1.657,
      -15.081,
      11.99,
      1.657,
      -15.081,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_4.OG_856",
    "polygon": [
      14.626,
      10.459,
      16.5,
      15.555,
      10.896,
      16.5,
      15.555,
      10.896,
      18.73,
      15.555,
      10.896,
      18.73,
      14.626,
      10.459,
      18.73,
      14.626,
      10.459,
      16.5
    ]
  },
  {
    "ID": "DINHQ_3.OG_707",
    "polygon": [
      -51.374,
      -8.218,
      13.13,
      -50.963,
      -9.091,
      13.13,
      -50.963,
      -9.091,
      14.907,
      -50.963,
      -9.091,
      14.907,
      -51.374,
      -8.218,
      14.907,
      -51.374,
      -8.218,
      13.13
    ]
  },
  {
    "ID": "DINHQ_3.OG_597",
    "polygon": [
      -51.565,
      -43.626,
      13.136,
      -52.543,
      -41.547,
      13.136,
      -52.543,
      -41.547,
      15.366,
      -52.543,
      -41.547,
      15.366,
      -51.565,
      -43.626,
      15.366,
      -51.565,
      -43.626,
      13.136
    ],
    "11-07 11:15": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_2.OG_411",
    "polygon": [
      -24.838,
      -27.549,
      9.76,
      -25.77,
      -27.987,
      9.76,
      -25.77,
      -27.987,
      11.99,
      -25.77,
      -27.987,
      11.99,
      -24.838,
      -27.549,
      11.99,
      -24.838,
      -27.549,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_3.OG_575",
    "polygon": [
      -79.482,
      -53.262,
      13.133,
      -80.378,
      -53.684,
      13.133,
      -80.378,
      -53.684,
      15.358,
      -80.378,
      -53.684,
      15.358,
      -79.482,
      -53.262,
      15.358,
      -79.482,
      -53.262,
      13.133
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_106",
    "polygon": [
      -49.326,
      -38.545,
      3.59,
      -52.148,
      -40.35,
      3.59,
      -52.148,
      -40.35,
      4.64,
      -52.148,
      -40.35,
      4.64,
      -49.326,
      -38.545,
      4.64,
      -49.326,
      -38.545,
      3.59
    ]
  },
  {
    "ID": "DINHQ_2.OG_445",
    "polygon": [
      20.29,
      -6.313,
      9.76,
      19.358,
      -6.752,
      9.76,
      19.358,
      -6.752,
      11.99,
      19.358,
      -6.752,
      11.99,
      20.29,
      -6.313,
      11.99,
      20.29,
      -6.313,
      9.76
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_2.OG_496",
    "polygon": [
      -46.255,
      -18.19,
      9.76,
      -45.576,
      -17.87,
      9.76,
      -45.576,
      -17.87,
      11.99,
      -45.576,
      -17.87,
      11.99,
      -46.255,
      -18.19,
      11.99,
      -46.255,
      -18.19,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_201",
    "polygon": [
      -34.362,
      -32.031,
      6.39,
      -35.294,
      -32.469,
      6.39,
      -35.294,
      -32.469,
      8.62,
      -35.294,
      -32.469,
      8.62,
      -34.362,
      -32.031,
      8.62,
      -34.362,
      -32.031,
      6.39
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1
  },
  {
    "ID": "DINHQ_4.OG_829",
    "polygon": [
      -8.939,
      -20.067,
      16.5,
      -9.871,
      -20.506,
      16.5,
      -9.871,
      -20.506,
      18.73,
      -9.871,
      -20.506,
      18.73,
      -8.939,
      -20.067,
      18.73,
      -8.939,
      -20.067,
      16.5
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_4.OG_797",
    "polygon": [
      -55.323,
      -41.894,
      16.503,
      -56.219,
      -42.316,
      16.503,
      -56.219,
      -42.316,
      18.728,
      -56.219,
      -42.316,
      18.728,
      -55.323,
      -41.894,
      18.728,
      -55.323,
      -41.894,
      16.503
    ],
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1032",
    "polygon": [
      -8.939,
      -20.067,
      19.87,
      -9.871,
      -20.506,
      19.87,
      -9.871,
      -20.506,
      22.1,
      -9.871,
      -20.506,
      22.1,
      -8.939,
      -20.067,
      22.1,
      -8.939,
      -20.067,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_2.OG_465",
    "polygon": [
      -5.676,
      0.905,
      9.76,
      -4.747,
      1.342,
      9.76,
      -4.747,
      1.342,
      11.99,
      -4.747,
      1.342,
      11.99,
      -5.676,
      0.905,
      11.99,
      -5.676,
      0.905,
      9.76
    ]
  },
  {
    "ID": "DINHQ_2.OG_561",
    "polygon": [
      -81.692,
      -34.865,
      9.76,
      -80.796,
      -34.443,
      9.76,
      -80.796,
      -34.443,
      11.985,
      -80.796,
      -34.443,
      11.985,
      -81.692,
      -34.865,
      11.985,
      -81.692,
      -34.865,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_244",
    "polygon": [
      22.901,
      -5.085,
      6.39,
      21.969,
      -5.523,
      6.39,
      21.969,
      -5.523,
      8.62,
      21.969,
      -5.523,
      8.62,
      22.901,
      -5.085,
      8.62,
      22.901,
      -5.085,
      6.39
    ],
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_4.OG_814",
    "polygon": [
      -29.063,
      -29.537,
      16.5,
      -29.995,
      -29.976,
      16.5,
      -29.995,
      -29.976,
      18.73,
      -29.995,
      -29.976,
      18.73,
      -29.063,
      -29.537,
      18.73,
      -29.063,
      -29.537,
      16.5
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1
  },
  {
    "ID": "DINHQ_5.OG_989",
    "polygon": [
      -69.203,
      -48.425,
      19.873,
      -70.099,
      -48.847,
      19.873,
      -70.099,
      -48.847,
      22.098,
      -70.099,
      -48.847,
      22.098,
      -69.203,
      -48.425,
      22.098,
      -69.203,
      -48.425,
      19.873
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_153",
    "polygon": [
      -54.798,
      -0.715,
      2.017,
      -54.36,
      -1.645,
      2.017,
      -54.36,
      -1.645,
      4.161,
      -54.36,
      -1.645,
      4.161,
      -54.798,
      -0.715,
      4.161,
      -54.798,
      -0.715,
      2.017
    ]
  },
  {
    "ID": "DINHQ_2.OG_518",
    "polygon": [
      -59.538,
      9.131,
      9.76,
      -59.01,
      8.009,
      9.76,
      -59.01,
      8.009,
      11.537,
      -59.01,
      8.009,
      11.537,
      -59.538,
      9.131,
      11.537,
      -59.538,
      9.131,
      9.76
    ]
  },
  {
    "ID": "DINHQ_4.OG_804",
    "polygon": [
      -40.822,
      -38.653,
      16.5,
      -42.939,
      -39.65,
      16.5,
      -42.939,
      -39.65,
      18.73,
      -42.939,
      -39.65,
      18.73,
      -40.822,
      -38.653,
      18.73,
      -40.822,
      -38.653,
      16.5
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1094",
    "polygon": [
      -32.17,
      -11.562,
      19.87,
      -31.24,
      -11.124,
      19.87,
      -31.24,
      -11.124,
      22.1,
      -31.24,
      -11.124,
      22.1,
      -32.17,
      -11.562,
      22.1,
      -32.17,
      -11.562,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_527",
    "polygon": [
      -70.511,
      -3.361,
      9.76,
      -70.922,
      -2.488,
      9.76,
      -70.922,
      -2.488,
      11.537,
      -70.922,
      -2.488,
      11.537,
      -70.511,
      -3.361,
      11.537,
      -70.511,
      -3.361,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_182",
    "polygon": [
      -63.032,
      -45.522,
      6.393,
      -63.928,
      -45.943,
      6.393,
      -63.928,
      -45.943,
      8.618,
      -63.928,
      -45.943,
      8.618,
      -63.032,
      -45.522,
      8.618,
      -63.032,
      -45.522,
      6.393
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_3.OG_758",
    "polygon": [
      -73.983,
      -31.237,
      13.13,
      -73.087,
      -30.816,
      13.13,
      -73.087,
      -30.816,
      15.355,
      -73.087,
      -30.816,
      15.355,
      -73.983,
      -31.237,
      15.355,
      -73.983,
      -31.237,
      13.13
    ]
  },
  {
    "ID": "DINHQ_4.OG_925",
    "polygon": [
      -62.02,
      8.06,
      16.5,
      -59.729,
      9.138,
      16.5,
      -59.729,
      9.138,
      18.28,
      -59.729,
      9.138,
      18.28,
      -62.02,
      8.06,
      18.28,
      -62.02,
      8.06,
      16.5
    ]
  },
  {
    "ID": "DINHQ_EG_101",
    "polygon": [
      -53.171,
      -40.797,
      0.935,
      -54.395,
      -41.373,
      0.935,
      -54.395,
      -41.373,
      1.885,
      -54.395,
      -41.373,
      1.885,
      -53.171,
      -40.797,
      1.885,
      -53.171,
      -40.797,
      0.935
    ],
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_1.OG_332",
    "polygon": [
      -65.913,
      -13.132,
      6.39,
      -66.323,
      -12.26,
      6.39,
      -66.323,
      -12.26,
      8.167,
      -66.323,
      -12.26,
      8.167,
      -65.913,
      -13.132,
      8.167,
      -65.913,
      -13.132,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_504",
    "polygon": [
      -51.374,
      -8.218,
      9.76,
      -50.963,
      -9.091,
      9.76,
      -50.963,
      -9.091,
      11.537,
      -50.963,
      -9.091,
      11.537,
      -51.374,
      -8.218,
      11.537,
      -51.374,
      -8.218,
      9.76
    ]
  },
  {
    "ID": "DINHQ_EG_93",
    "polygon": [
      23.273,
      -4.825,
      1.886,
      20.377,
      -6.188,
      1.886,
      20.377,
      -6.188,
      4.173,
      20.377,
      -6.188,
      4.173,
      23.273,
      -4.825,
      4.173,
      23.273,
      -4.825,
      1.886
    ],
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_1.OG_169",
    "polygon": [
      -79.482,
      -53.262,
      6.393,
      -80.378,
      -53.684,
      6.393,
      -80.378,
      -53.684,
      8.618,
      -80.378,
      -53.684,
      8.618,
      -79.482,
      -53.262,
      8.618,
      -79.482,
      -53.262,
      6.393
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_951",
    "polygon": [
      -62.173,
      -25.68,
      16.5,
      -61.277,
      -25.258,
      16.5,
      -61.277,
      -25.258,
      18.725,
      -61.277,
      -25.258,
      18.725,
      -62.173,
      -25.68,
      18.725,
      -62.173,
      -25.68,
      16.5
    ]
  },
  {
    "ID": "DINHQ_1.OG_345",
    "polygon": [
      -64.739,
      -26.887,
      6.39,
      -63.843,
      -26.466,
      6.39,
      -63.843,
      -26.466,
      8.615,
      -63.843,
      -26.466,
      8.615,
      -64.739,
      -26.887,
      8.615,
      -64.739,
      -26.887,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_281",
    "polygon": [
      -30.598,
      -10.822,
      6.39,
      -29.669,
      -10.385,
      6.39,
      -29.669,
      -10.385,
      8.62,
      -29.669,
      -10.385,
      8.62,
      -30.598,
      -10.822,
      8.62,
      -30.598,
      -10.822,
      6.39
    ]
  },
  {
    "ID": "DINHQ_1.OG_363",
    "polygon": [
      -87.866,
      -37.77,
      6.39,
      -86.97,
      -37.349,
      6.39,
      -86.97,
      -37.349,
      8.615,
      -86.97,
      -37.349,
      8.615,
      -87.866,
      -37.77,
      8.615,
      -87.866,
      -37.77,
      6.39
    ]
  },
  {
    "ID": "DINHQ_2.OG_424",
    "polygon": [
      -7.865,
      -19.562,
      9.76,
      -8.797,
      -20.001,
      9.76,
      -8.797,
      -20.001,
      11.99,
      -8.797,
      -20.001,
      11.99,
      -7.865,
      -19.562,
      11.99,
      -7.865,
      -19.562,
      9.76
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1160",
    "polygon": [
      -68.843,
      -28.819,
      19.87,
      -67.947,
      -28.397,
      19.87,
      -67.947,
      -28.397,
      22.095,
      -67.947,
      -28.397,
      22.095,
      -68.843,
      -28.819,
      22.095,
      -68.843,
      -28.819,
      19.87
    ]
  },
  {
    "ID": "DINHQ_2.OG_513",
    "polygon": [
      -56.687,
      3.072,
      9.76,
      -56.276,
      2.199,
      9.76,
      -56.276,
      2.199,
      11.537,
      -56.276,
      2.199,
      11.537,
      -56.687,
      3.072,
      11.537,
      -56.687,
      3.072,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_614",
    "polygon": [
      -24.838,
      -27.549,
      13.13,
      -25.77,
      -27.987,
      13.13,
      -25.77,
      -27.987,
      15.36,
      -25.77,
      -27.987,
      15.36,
      -24.838,
      -27.549,
      15.36,
      -24.838,
      -27.549,
      13.13
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_840",
    "polygon": [
      5.382,
      -13.328,
      16.5,
      4.45,
      -13.767,
      16.5,
      4.45,
      -13.767,
      18.73,
      4.45,
      -13.767,
      18.73,
      5.382,
      -13.328,
      18.73,
      5.382,
      -13.328,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_511",
    "polygon": [
      -55.537,
      0.628,
      9.76,
      -55.126,
      -0.244,
      9.76,
      -55.126,
      -0.244,
      11.537,
      -55.126,
      -0.244,
      11.537,
      -55.537,
      0.628,
      11.537,
      -55.537,
      0.628,
      9.76
    ]
  },
  {
    "ID": "DINHQ_1.OG_315",
    "polygon": [
      -59.538,
      9.131,
      6.39,
      -59.01,
      8.009,
      6.39,
      -59.01,
      8.009,
      8.167,
      -59.01,
      8.009,
      8.167,
      -59.538,
      9.131,
      8.167,
      -59.538,
      9.131,
      6.39
    ]
  },
  {
    "ID": "DINHQ_EG_141",
    "polygon": [
      -56.688,
      3.078,
      2.484,
      -56.278,
      2.205,
      2.484,
      -56.278,
      2.205,
      5.25,
      -56.278,
      2.205,
      5.25,
      -56.688,
      3.078,
      5.25,
      -56.688,
      3.078,
      2.484
    ]
  },
  {
    "ID": "DINHQ_2.OG_442",
    "polygon": [
      16.144,
      -8.264,
      9.76,
      15.212,
      -8.703,
      9.76,
      15.212,
      -8.703,
      11.99,
      15.212,
      -8.703,
      11.99,
      16.144,
      -8.264,
      11.99,
      16.144,
      -8.264,
      9.76
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_2.OG_505",
    "polygon": [
      -52.088,
      -6.701,
      9.76,
      -51.677,
      -7.573,
      9.76,
      -51.677,
      -7.573,
      11.537,
      -51.677,
      -7.573,
      11.537,
      -52.088,
      -6.701,
      11.537,
      -52.088,
      -6.701,
      9.76
    ]
  },
  {
    "ID": "DINHQ_3.OG_596",
    "polygon": [
      -53.329,
      -40.956,
      13.133,
      -53.645,
      -41.105,
      13.133,
      -53.645,
      -41.105,
      15.358,
      -53.645,
      -41.105,
      15.358,
      -53.329,
      -40.956,
      15.358,
      -53.329,
      -40.956,
      13.133
    ],
    "11-07 11:00": 1,
    "11-07 11:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1308",
    "polygon": [
      -47.925,
      -15.548,
      23.288,
      -47.514,
      -16.421,
      23.288,
      -47.514,
      -16.421,
      26.818,
      -47.514,
      -16.421,
      26.818,
      -47.925,
      -15.548,
      26.818,
      -47.925,
      -15.548,
      23.288
    ]
  },
  {
    "ID": "DINHQ_4.OG_850",
    "polygon": [
      18.759,
      -7.034,
      16.5,
      17.827,
      -7.472,
      16.5,
      17.827,
      -7.472,
      18.73,
      17.827,
      -7.472,
      18.73,
      18.759,
      -7.034,
      18.73,
      18.759,
      -7.034,
      16.5
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_1.OG_222",
    "polygon": [
      -6.291,
      -18.821,
      6.39,
      -7.223,
      -19.26,
      6.39,
      -7.223,
      -19.26,
      8.62,
      -7.223,
      -19.26,
      8.62,
      -6.291,
      -18.821,
      8.62,
      -6.291,
      -18.821,
      6.39
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_1.OG_339",
    "polygon": [
      -62.029,
      -21.387,
      6.39,
      -62.439,
      -20.515,
      6.39,
      -62.439,
      -20.515,
      8.167,
      -62.439,
      -20.515,
      8.167,
      -62.029,
      -21.387,
      8.167,
      -62.029,
      -21.387,
      6.39
    ]
  },
  {
    "ID": "DINHQ_6.OG_1307",
    "polygon": [
      -47.489,
      -16.474,
      23.288,
      -47.079,
      -17.346,
      23.288,
      -47.079,
      -17.346,
      26.818,
      -47.079,
      -17.346,
      26.818,
      -47.489,
      -16.474,
      26.818,
      -47.489,
      -16.474,
      23.288
    ]
  },
  {
    "ID": "DINHQ_3.OG_583",
    "polygon": [
      -69.203,
      -48.425,
      13.133,
      -70.099,
      -48.847,
      13.133,
      -70.099,
      -48.847,
      15.358,
      -70.099,
      -48.847,
      15.358,
      -69.203,
      -48.425,
      15.358,
      -69.203,
      -48.425,
      13.133
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_EG_154",
    "polygon": [
      -54.265,
      -1.846,
      2.017,
      -53.828,
      -2.776,
      2.017,
      -53.828,
      -2.776,
      4.161,
      -53.828,
      -2.776,
      4.161,
      -54.265,
      -1.846,
      4.161,
      -54.265,
      -1.846,
      2.017
    ]
  },
  {
    "ID": "DINHQ_1.OG_249",
    "polygon": [
      11.988,
      9.218,
      6.39,
      12.918,
      9.655,
      6.39,
      12.918,
      9.655,
      8.62,
      12.918,
      9.655,
      8.62,
      11.988,
      9.218,
      8.62,
      11.988,
      9.218,
      6.39
    ]
  },
  {
    "ID": "DINHQ_3.OG_640",
    "polygon": [
      9.837,
      -11.232,
      13.13,
      8.787,
      -11.726,
      13.13,
      8.787,
      -11.726,
      15.36,
      8.787,
      -11.726,
      15.36,
      9.837,
      -11.232,
      15.36,
      9.837,
      -11.232,
      13.13
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_2.OG_498",
    "polygon": [
      -47.925,
      -15.548,
      9.76,
      -47.514,
      -16.421,
      9.76,
      -47.514,
      -16.421,
      11.537,
      -47.514,
      -16.421,
      11.537,
      -47.925,
      -15.548,
      11.537,
      -47.925,
      -15.548,
      9.76
    ]
  },
  {
    "ID": "DINHQ_6.OG_1521",
    "polygon": [
      18.122,
      -5.676,
      23.289,
      17.19,
      -6.115,
      23.289,
      17.19,
      -6.115,
      26.819,
      17.19,
      -6.115,
      26.819,
      18.122,
      -5.676,
      26.819,
      18.122,
      -5.676,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_7.OG_1612",
    "polygon": [
      -46.34,
      -18.917,
      28.03,
      -45.929,
      -19.789,
      28.03,
      -45.929,
      -19.789,
      30.848,
      -45.929,
      -19.789,
      30.848,
      -46.34,
      -18.917,
      30.848,
      -46.34,
      -18.917,
      28.03
    ]
  },
  {
    "ID": "DINHQ_5.OG_984",
    "polygon": [
      -75.881,
      -51.568,
      19.873,
      -76.776,
      -51.989,
      19.873,
      -76.776,
      -51.989,
      22.098,
      -76.776,
      -51.989,
      22.098,
      -75.881,
      -51.568,
      22.098,
      -75.881,
      -51.568,
      19.873
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1718",
    "polygon": [
      -55.973,
      1.554,
      35.445,
      -55.562,
      0.682,
      35.445,
      -55.562,
      0.682,
      39.445,
      -55.562,
      0.682,
      39.445,
      -55.973,
      1.554,
      39.445,
      -55.973,
      1.554,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_10.OG_1795",
    "polygon": [
      -58.272,
      6.441,
      40.445,
      -57.862,
      5.569,
      40.445,
      -57.862,
      5.569,
      44.113,
      -57.862,
      5.569,
      44.113,
      -58.272,
      6.441,
      44.113,
      -58.272,
      6.441,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1530",
    "polygon": [
      -86.345,
      -48.625,
      23.36,
      -86.89,
      -47.467,
      23.36,
      -86.89,
      -47.467,
      26.82,
      -86.89,
      -47.467,
      26.82,
      -86.345,
      -48.625,
      26.82,
      -86.345,
      -48.625,
      23.36
    ],
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_9.OG_1758",
    "polygon": [
      -58.581,
      -28.72,
      35.445,
      -58.992,
      -27.848,
      35.445,
      -58.992,
      -27.848,
      39.445,
      -58.992,
      -27.848,
      39.445,
      -58.581,
      -28.72,
      39.445,
      -58.581,
      -28.72,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1543",
    "polygon": [
      -51.565,
      -43.626,
      28.03,
      -52.543,
      -41.547,
      28.03,
      -52.543,
      -41.547,
      30.848,
      -52.543,
      -41.547,
      30.848,
      -51.565,
      -43.626,
      30.848,
      -51.565,
      -43.626,
      28.03
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1112",
    "polygon": [
      -50.938,
      -9.144,
      19.87,
      -50.528,
      -10.017,
      19.87,
      -50.528,
      -10.017,
      21.647,
      -50.528,
      -10.017,
      21.647,
      -50.938,
      -9.144,
      21.647,
      -50.938,
      -9.144,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1061",
    "polygon": [
      11.988,
      9.218,
      19.87,
      12.918,
      9.655,
      19.87,
      12.918,
      9.655,
      22.1,
      12.918,
      9.655,
      22.1,
      11.988,
      9.218,
      22.1,
      11.988,
      9.218,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1163",
    "polygon": [
      -72.449,
      -30.515,
      19.87,
      -71.553,
      -30.094,
      19.87,
      -71.553,
      -30.094,
      22.095,
      -71.553,
      -30.094,
      22.095,
      -72.449,
      -30.515,
      22.095,
      -72.449,
      -30.515,
      19.87
    ]
  },
  {
    "ID": "DINHQ_8.OG_1634",
    "polygon": [
      -49.788,
      -11.588,
      31.845,
      -49.378,
      -12.46,
      31.845,
      -49.378,
      -12.46,
      34.445,
      -49.378,
      -12.46,
      34.445,
      -49.788,
      -11.588,
      34.445,
      -49.788,
      -11.588,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1621",
    "polygon": [
      -44.593,
      -22.633,
      27.315,
      -44.064,
      -23.759,
      27.315,
      -44.064,
      -23.759,
      29.66,
      -44.064,
      -23.759,
      29.66,
      -44.593,
      -22.633,
      29.66,
      -44.593,
      -22.633,
      27.315
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1156",
    "polygon": [
      -61.117,
      -25.183,
      19.87,
      -60.755,
      -25.013,
      19.87,
      -60.755,
      -25.013,
      22.095,
      -60.755,
      -25.013,
      22.095,
      -61.117,
      -25.183,
      22.095,
      -61.117,
      -25.183,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1522",
    "polygon": [
      19.664,
      -4.95,
      23.289,
      18.732,
      -5.389,
      23.289,
      18.732,
      -5.389,
      26.819,
      18.732,
      -5.389,
      26.819,
      19.664,
      -4.95,
      26.819,
      19.664,
      -4.95,
      23.289
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1653",
    "polygon": [
      -63.886,
      7.181,
      31.845,
      -62.661,
      7.757,
      31.845,
      -62.661,
      7.757,
      34.445,
      -62.661,
      7.757,
      34.445,
      -63.886,
      7.181,
      34.445,
      -63.886,
      7.181,
      31.845
    ]
  },
  {
    "ID": "DINHQ_6.OG_1465",
    "polygon": [
      -69.841,
      -47.068,
      23.288,
      -70.736,
      -47.49,
      23.288,
      -70.736,
      -47.49,
      26.818,
      -70.736,
      -47.49,
      26.818,
      -69.841,
      -47.068,
      26.818,
      -69.841,
      -47.068,
      23.288
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1617",
    "polygon": [
      -41.031,
      -30.198,
      28.03,
      -40.184,
      -31.999,
      28.03,
      -40.184,
      -31.999,
      30.848,
      -40.184,
      -31.999,
      30.848,
      -41.031,
      -30.198,
      30.848,
      -41.031,
      -30.198,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1049",
    "polygon": [
      13.526,
      -9.496,
      19.87,
      12.594,
      -9.935,
      19.87,
      12.594,
      -9.935,
      22.1,
      12.594,
      -9.935,
      22.1,
      13.526,
      -9.496,
      22.1,
      13.526,
      -9.496,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_960",
    "polygon": [
      -72.449,
      -30.515,
      16.5,
      -71.553,
      -30.094,
      16.5,
      -71.553,
      -30.094,
      18.725,
      -71.553,
      -30.094,
      18.725,
      -72.449,
      -30.515,
      18.725,
      -72.449,
      -30.515,
      16.5
    ]
  },
  {
    "ID": "DINHQ_9.OG_1738",
    "polygon": [
      -67.776,
      -9.172,
      35.445,
      -68.187,
      -8.3,
      35.445,
      -68.187,
      -8.3,
      39.445,
      -68.187,
      -8.3,
      39.445,
      -67.776,
      -9.172,
      39.445,
      -67.776,
      -9.172,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_943",
    "polygon": [
      -64.763,
      -15.575,
      16.5,
      -65.174,
      -14.703,
      16.5,
      -65.174,
      -14.703,
      18.277,
      -65.174,
      -14.703,
      18.277,
      -64.763,
      -15.575,
      18.277,
      -64.763,
      -15.575,
      16.5
    ]
  },
  {
    "ID": "DINHQ_7.OG_1552",
    "polygon": [
      -48.639,
      -14.031,
      28.03,
      -48.228,
      -14.903,
      28.03,
      -48.228,
      -14.903,
      30.848,
      -48.228,
      -14.903,
      30.848,
      -48.639,
      -14.031,
      30.848,
      -48.639,
      -14.031,
      28.03
    ],
    "11-07 07:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1304",
    "polygon": [
      -43.857,
      -17.061,
      23.288,
      -42.928,
      -16.624,
      23.288,
      -42.928,
      -16.624,
      26.818,
      -42.928,
      -16.624,
      26.818,
      -43.857,
      -17.061,
      26.818,
      -43.857,
      -17.061,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1300",
    "polygon": [
      -38.546,
      -14.562,
      23.288,
      -37.617,
      -14.125,
      23.288,
      -37.617,
      -14.125,
      26.818,
      -37.617,
      -14.125,
      26.818,
      -38.546,
      -14.562,
      26.818,
      -38.546,
      -14.562,
      23.288
    ]
  },
  {
    "ID": "DINHQ_10.OG_1777",
    "polygon": [
      -47.925,
      -15.548,
      40.445,
      -47.514,
      -16.421,
      40.445,
      -47.514,
      -16.421,
      44.113,
      -47.514,
      -16.421,
      44.113,
      -47.925,
      -15.548,
      44.113,
      -47.925,
      -15.548,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1507",
    "polygon": [
      -0.55,
      -14.462,
      23.288,
      -1.482,
      -14.901,
      23.288,
      -1.482,
      -14.901,
      26.818,
      -1.482,
      -14.901,
      26.818,
      -0.55,
      -14.462,
      26.818,
      -0.55,
      -14.462,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1708",
    "polygon": [
      -50.224,
      -10.662,
      35.445,
      -49.814,
      -11.534,
      35.445,
      -49.814,
      -11.534,
      39.445,
      -49.814,
      -11.534,
      39.445,
      -50.224,
      -10.662,
      39.445,
      -50.224,
      -10.662,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1329",
    "polygon": [
      -62.02,
      8.06,
      23.288,
      -59.729,
      9.138,
      23.288,
      -59.729,
      9.138,
      26.818,
      -59.729,
      9.138,
      26.818,
      -62.02,
      8.06,
      26.818,
      -62.02,
      8.06,
      23.288
    ]
  },
  {
    "ID": "DINHQ_7.OG_1568",
    "polygon": [
      -57.836,
      5.515,
      28.03,
      -57.426,
      4.643,
      28.03,
      -57.426,
      4.643,
      30.848,
      -57.426,
      4.643,
      30.848,
      -57.836,
      5.515,
      30.848,
      -57.836,
      5.515,
      28.03
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1597",
    "polygon": [
      -60.879,
      -23.83,
      28.03,
      -61.289,
      -22.958,
      28.03,
      -61.289,
      -22.958,
      30.848,
      -61.289,
      -22.958,
      30.848,
      -60.879,
      -23.83,
      30.848,
      -60.879,
      -23.83,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1838",
    "polygon": [
      -42.183,
      -27.756,
      40.445,
      -41.335,
      -29.557,
      40.445,
      -41.335,
      -29.557,
      44.113,
      -41.335,
      -29.557,
      44.113,
      -42.183,
      -27.756,
      44.113,
      -42.183,
      -27.756,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1846",
    "polygon": [
      -44.171,
      -31.281,
      39.515,
      -44.901,
      -31.624,
      39.515,
      -44.901,
      -31.624,
      41.915,
      -44.901,
      -31.624,
      41.915,
      -44.171,
      -31.281,
      41.915,
      -44.171,
      -31.281,
      39.515
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:30": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1842",
    "polygon": [
      -48.082,
      -33.121,
      39.515,
      -49.071,
      -33.587,
      39.515,
      -49.071,
      -33.587,
      41.915,
      -49.071,
      -33.587,
      41.915,
      -48.082,
      -33.121,
      41.915,
      -48.082,
      -33.121,
      39.515
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1715",
    "polygon": [
      -54.387,
      -1.815,
      35.445,
      -53.977,
      -2.687,
      35.445,
      -53.977,
      -2.687,
      39.445,
      -53.977,
      -2.687,
      39.445,
      -54.387,
      -1.815,
      39.445,
      -54.387,
      -1.815,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1375",
    "polygon": [
      -86.832,
      -37.283,
      23.288,
      -85.936,
      -36.862,
      23.288,
      -85.936,
      -36.862,
      26.818,
      -85.936,
      -36.862,
      26.818,
      -86.832,
      -37.283,
      26.818,
      -86.832,
      -37.283,
      23.288
    ]
  },
  {
    "ID": "DINHQ_9.OG_1729",
    "polygon": [
      -72.373,
      0.597,
      35.445,
      -72.784,
      1.469,
      35.445,
      -72.784,
      1.469,
      39.445,
      -72.784,
      1.469,
      39.445,
      -72.373,
      0.597,
      39.445,
      -72.373,
      0.597,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1051",
    "polygon": [
      16.144,
      -8.264,
      19.87,
      15.212,
      -8.703,
      19.87,
      15.212,
      -8.703,
      22.1,
      15.212,
      -8.703,
      22.1,
      16.144,
      -8.264,
      22.1,
      16.144,
      -8.264,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1470",
    "polygon": [
      -63.67,
      -44.164,
      23.288,
      -64.565,
      -44.586,
      23.288,
      -64.565,
      -44.586,
      26.818,
      -64.565,
      -44.586,
      26.818,
      -63.67,
      -44.164,
      26.818,
      -63.67,
      -44.164,
      23.288
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_891",
    "polygon": [
      -32.17,
      -11.562,
      16.5,
      -31.24,
      -11.124,
      16.5,
      -31.24,
      -11.124,
      18.73,
      -31.24,
      -11.124,
      18.73,
      -32.17,
      -11.562,
      18.73,
      -32.17,
      -11.562,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1077",
    "polygon": [
      -9.403,
      -0.849,
      19.87,
      -8.474,
      -0.411,
      19.87,
      -8.474,
      -0.411,
      22.1,
      -8.474,
      -0.411,
      22.1,
      -9.403,
      -0.849,
      22.1,
      -9.403,
      -0.849,
      19.87
    ]
  },
  {
    "ID": "DINHQ_4.OG_956",
    "polygon": [
      -67.308,
      -28.096,
      16.5,
      -66.413,
      -27.675,
      16.5,
      -66.413,
      -27.675,
      18.725,
      -66.413,
      -27.675,
      18.725,
      -67.308,
      -28.096,
      18.725,
      -67.308,
      -28.096,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1155",
    "polygon": [
      -63.707,
      -26.402,
      19.87,
      -62.811,
      -25.98,
      19.87,
      -62.811,
      -25.98,
      22.095,
      -62.811,
      -25.98,
      22.095,
      -63.707,
      -26.402,
      22.095,
      -63.707,
      -26.402,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1205",
    "polygon": [
      -46.34,
      -41.25,
      23.288,
      -48.457,
      -42.247,
      23.288,
      -48.457,
      -42.247,
      26.818,
      -48.457,
      -42.247,
      26.818,
      -46.34,
      -41.25,
      26.818,
      -46.34,
      -41.25,
      23.288
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1730",
    "polygon": [
      -72.808,
      1.52,
      35.445,
      -73.337,
      2.645,
      35.445,
      -73.337,
      2.645,
      39.445,
      -73.337,
      2.645,
      39.445,
      -72.808,
      1.52,
      39.445,
      -72.808,
      1.52,
      35.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1728",
    "polygon": [
      -72.959,
      2.912,
      35.445,
      -70.94,
      3.863,
      35.445,
      -70.94,
      3.863,
      39.445,
      -70.94,
      3.863,
      39.445,
      -72.959,
      2.912,
      39.445,
      -72.959,
      2.912,
      35.445
    ]
  },
  {
    "ID": "DINHQ_4.OG_920",
    "polygon": [
      -57.122,
      3.998,
      16.5,
      -56.712,
      3.125,
      16.5,
      -56.712,
      3.125,
      18.277,
      -56.712,
      3.125,
      18.277,
      -57.122,
      3.998,
      18.277,
      -57.122,
      3.998,
      16.5
    ]
  },
  {
    "ID": "DINHQ_7.OG_1561",
    "polygon": [
      -53.673,
      -3.332,
      28.03,
      -53.263,
      -4.204,
      28.03,
      -53.263,
      -4.204,
      30.848,
      -53.263,
      -4.204,
      30.848,
      -53.673,
      -3.332,
      30.848,
      -53.673,
      -3.332,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1686",
    "polygon": [
      -46.34,
      -18.917,
      31.845,
      -45.929,
      -19.789,
      31.845,
      -45.929,
      -19.789,
      34.445,
      -45.929,
      -19.789,
      34.445,
      -46.34,
      -18.917,
      34.445,
      -46.34,
      -18.917,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1710",
    "polygon": [
      -51.374,
      -8.218,
      35.445,
      -50.963,
      -9.091,
      35.445,
      -50.963,
      -9.091,
      39.445,
      -50.963,
      -9.091,
      39.445,
      -51.374,
      -8.218,
      39.445,
      -51.374,
      -8.218,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1090",
    "polygon": [
      -26.871,
      -9.068,
      19.87,
      -25.942,
      -8.631,
      19.87,
      -25.942,
      -8.631,
      22.1,
      -25.942,
      -8.631,
      22.1,
      -26.871,
      -9.068,
      22.1,
      -26.871,
      -9.068,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1488",
    "polygon": [
      -25.469,
      -26.188,
      23.289,
      -26.401,
      -26.627,
      23.289,
      -26.401,
      -26.627,
      26.819,
      -26.401,
      -26.627,
      26.819,
      -25.469,
      -26.188,
      26.819,
      -25.469,
      -26.188,
      23.289
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1630",
    "polygon": [
      -47.489,
      -16.474,
      31.845,
      -47.079,
      -17.346,
      31.845,
      -47.079,
      -17.346,
      34.445,
      -47.079,
      -17.346,
      34.445,
      -47.489,
      -16.474,
      34.445,
      -47.489,
      -16.474,
      31.845
    ],
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1687",
    "polygon": [
      -46.775,
      -17.991,
      31.845,
      -46.365,
      -18.863,
      31.845,
      -46.365,
      -18.863,
      34.445,
      -46.365,
      -18.863,
      34.445,
      -46.775,
      -17.991,
      34.445,
      -46.775,
      -17.991,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1677",
    "polygon": [
      -60.879,
      -23.83,
      31.845,
      -61.289,
      -22.958,
      31.845,
      -61.289,
      -22.958,
      34.445,
      -61.289,
      -22.958,
      34.445,
      -60.879,
      -23.83,
      34.445,
      -60.879,
      -23.83,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1547",
    "polygon": [
      -40.822,
      -38.653,
      28.03,
      -42.939,
      -39.65,
      28.03,
      -42.939,
      -39.65,
      30.848,
      -42.939,
      -39.65,
      30.848,
      -40.822,
      -38.653,
      30.848,
      -40.822,
      -38.653,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1844",
    "polygon": [
      -51.996,
      -34.963,
      39.515,
      -52.726,
      -35.306,
      39.515,
      -52.726,
      -35.306,
      41.915,
      -52.726,
      -35.306,
      41.915,
      -51.996,
      -34.963,
      41.915,
      -51.996,
      -34.963,
      39.515
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1526",
    "polygon": [
      -38.719,
      -32.423,
      25.289,
      -39.497,
      -32.789,
      25.289,
      -39.497,
      -32.789,
      26.819,
      -39.497,
      -32.789,
      26.819,
      -38.719,
      -32.423,
      26.819,
      -38.719,
      -32.423,
      25.289
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1316",
    "polygon": [
      -52.523,
      -5.775,
      23.288,
      -52.113,
      -6.648,
      23.288,
      -52.113,
      -6.648,
      26.818,
      -52.113,
      -6.648,
      26.818,
      -52.523,
      -5.775,
      26.818,
      -52.523,
      -5.775,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1523",
    "polygon": [
      20.737,
      -4.445,
      23.289,
      19.805,
      -4.884,
      23.289,
      19.805,
      -4.884,
      26.819,
      19.805,
      -4.884,
      26.819,
      20.737,
      -4.445,
      26.819,
      20.737,
      -4.445,
      23.289
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1791",
    "polygon": [
      -55.973,
      1.554,
      40.445,
      -55.562,
      0.682,
      40.445,
      -55.562,
      0.682,
      44.113,
      -55.562,
      0.682,
      44.113,
      -55.973,
      1.554,
      44.113,
      -55.973,
      1.554,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_4.OG_944",
    "polygon": [
      -64.328,
      -16.501,
      16.5,
      -64.738,
      -15.629,
      16.5,
      -64.738,
      -15.629,
      18.277,
      -64.738,
      -15.629,
      18.277,
      -64.328,
      -16.501,
      18.277,
      -64.328,
      -16.501,
      16.5
    ]
  },
  {
    "ID": "DINHQ_8.OG_1691",
    "polygon": [
      -41.031,
      -30.198,
      31.845,
      -40.184,
      -31.999,
      31.845,
      -40.184,
      -31.999,
      34.445,
      -40.184,
      -31.999,
      34.445,
      -41.031,
      -30.198,
      34.445,
      -41.031,
      -30.198,
      31.845
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1486",
    "polygon": [
      -28.12,
      -27.436,
      23.289,
      -29.052,
      -27.875,
      23.289,
      -29.052,
      -27.875,
      26.819,
      -29.052,
      -27.875,
      26.819,
      -28.12,
      -27.436,
      26.819,
      -28.12,
      -27.436,
      23.289
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_9.OG_1739",
    "polygon": [
      -67.062,
      -10.689,
      35.445,
      -67.473,
      -9.817,
      35.445,
      -67.473,
      -9.817,
      39.445,
      -67.473,
      -9.817,
      39.445,
      -67.062,
      -10.689,
      39.445,
      -67.062,
      -10.689,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1536",
    "polygon": [
      -87.846,
      -45.436,
      23.33,
      -88.155,
      -44.78,
      23.33,
      -88.155,
      -44.78,
      25.19,
      -88.155,
      -44.78,
      25.19,
      -87.846,
      -45.436,
      25.19,
      -87.846,
      -45.436,
      23.33
    ]
  },
  {
    "ID": "DINHQ_10.OG_1783",
    "polygon": [
      -51.374,
      -8.218,
      40.445,
      -50.963,
      -9.091,
      40.445,
      -50.963,
      -9.091,
      44.113,
      -50.963,
      -9.091,
      44.113,
      -51.374,
      -8.218,
      44.113,
      -51.374,
      -8.218,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1654",
    "polygon": [
      -70.299,
      4.163,
      31.845,
      -69.073,
      4.74,
      31.845,
      -69.073,
      4.74,
      34.445,
      -69.073,
      4.74,
      34.445,
      -70.299,
      4.163,
      34.445,
      -70.299,
      4.163,
      31.845
    ]
  },
  {
    "ID": "DINHQ_6.OG_1484",
    "polygon": [
      -30.77,
      -28.683,
      23.289,
      -31.702,
      -29.121,
      23.289,
      -31.702,
      -29.121,
      26.819,
      -31.702,
      -29.121,
      26.819,
      -30.77,
      -28.683,
      26.819,
      -30.77,
      -28.683,
      23.289
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1713",
    "polygon": [
      -53.238,
      -4.258,
      35.445,
      -52.827,
      -5.13,
      35.445,
      -52.827,
      -5.13,
      39.445,
      -52.827,
      -5.13,
      39.445,
      -53.238,
      -4.258,
      39.445,
      -53.238,
      -4.258,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_4.OG_932",
    "polygon": [
      -71.225,
      -1.843,
      16.5,
      -71.635,
      -0.971,
      16.5,
      -71.635,
      -0.971,
      18.277,
      -71.635,
      -0.971,
      18.277,
      -71.225,
      -1.843,
      18.277,
      -71.225,
      -1.843,
      16.5
    ],
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1688",
    "polygon": [
      -45.192,
      -21.361,
      31.845,
      -44.782,
      -22.233,
      31.845,
      -44.782,
      -22.233,
      34.445,
      -44.782,
      -22.233,
      34.445,
      -45.192,
      -21.361,
      34.445,
      -45.192,
      -21.361,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1721",
    "polygon": [
      -57.836,
      5.515,
      35.445,
      -57.426,
      4.643,
      35.445,
      -57.426,
      4.643,
      39.445,
      -57.426,
      4.643,
      39.445,
      -57.836,
      5.515,
      39.445,
      -57.836,
      5.515,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1026",
    "polygon": [
      -16.891,
      -23.809,
      19.87,
      -17.823,
      -24.248,
      19.87,
      -17.823,
      -24.248,
      22.1,
      -17.823,
      -24.248,
      22.1,
      -16.891,
      -23.809,
      22.1,
      -16.891,
      -23.809,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1607",
    "polygon": [
      -59.017,
      -27.795,
      28.03,
      -59.427,
      -26.922,
      28.03,
      -59.427,
      -26.922,
      30.848,
      -59.427,
      -26.922,
      30.848,
      -59.017,
      -27.795,
      30.848,
      -59.017,
      -27.795,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_7.OG_1609",
    "polygon": [
      -55.019,
      -36.286,
      27.315,
      -55.549,
      -35.161,
      27.315,
      -55.549,
      -35.161,
      29.66,
      -55.549,
      -35.161,
      29.66,
      -55.019,
      -36.286,
      29.66,
      -55.019,
      -36.286,
      27.315
    ],
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1671",
    "polygon": [
      -64.328,
      -16.501,
      31.845,
      -64.738,
      -15.629,
      31.845,
      -64.738,
      -15.629,
      34.445,
      -64.738,
      -15.629,
      34.445,
      -64.328,
      -16.501,
      34.445,
      -64.328,
      -16.501,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1039",
    "polygon": [
      0.084,
      -15.822,
      19.87,
      -0.848,
      -16.26,
      19.87,
      -0.848,
      -16.26,
      22.1,
      -0.848,
      -16.26,
      22.1,
      0.084,
      -15.822,
      22.1,
      0.084,
      -15.822,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_7.OG_1588",
    "polygon": [
      -65.913,
      -13.132,
      28.03,
      -66.323,
      -12.26,
      28.03,
      -66.323,
      -12.26,
      30.848,
      -66.323,
      -12.26,
      30.848,
      -65.913,
      -13.132,
      30.848,
      -65.913,
      -13.132,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1102",
    "polygon": [
      -42.777,
      -16.553,
      19.87,
      -41.848,
      -16.116,
      19.87,
      -41.848,
      -16.116,
      22.1,
      -41.848,
      -16.116,
      22.1,
      -42.777,
      -16.553,
      22.1,
      -42.777,
      -16.553,
      19.87
    ]
  },
  {
    "ID": "DINHQ_10.OG_1797",
    "polygon": [
      -59.538,
      9.131,
      40.445,
      -59.01,
      8.009,
      40.445,
      -59.01,
      8.009,
      44.113,
      -59.01,
      8.009,
      44.113,
      -59.538,
      9.131,
      44.113,
      -59.538,
      9.131,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1760",
    "polygon": [
      -46.775,
      -17.991,
      35.445,
      -46.365,
      -18.863,
      35.445,
      -46.365,
      -18.863,
      39.445,
      -46.365,
      -18.863,
      39.445,
      -46.775,
      -17.991,
      39.445,
      -46.775,
      -17.991,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_9.OG_1759",
    "polygon": [
      -46.34,
      -18.917,
      35.445,
      -45.929,
      -19.789,
      35.445,
      -45.929,
      -19.789,
      39.445,
      -45.929,
      -19.789,
      39.445,
      -46.34,
      -18.917,
      39.445,
      -46.34,
      -18.917,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1706",
    "polygon": [
      -49.074,
      -13.105,
      35.445,
      -48.664,
      -13.977,
      35.445,
      -48.664,
      -13.977,
      39.445,
      -48.664,
      -13.977,
      39.445,
      -49.074,
      -13.105,
      39.445,
      -49.074,
      -13.105,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_7.OG_1550",
    "polygon": [
      -47.489,
      -16.474,
      28.03,
      -47.079,
      -17.346,
      28.03,
      -47.079,
      -17.346,
      30.848,
      -47.079,
      -17.346,
      30.848,
      -47.489,
      -16.474,
      30.848,
      -47.489,
      -16.474,
      28.03
    ]
  },
  {
    "ID": "DINHQ_5.OG_1025",
    "polygon": [
      -18.465,
      -24.55,
      19.87,
      -19.397,
      -24.989,
      19.87,
      -19.397,
      -24.989,
      22.1,
      -19.397,
      -24.989,
      22.1,
      -18.465,
      -24.55,
      22.1,
      -18.465,
      -24.55,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1719",
    "polygon": [
      -56.687,
      3.072,
      35.445,
      -56.276,
      2.199,
      35.445,
      -56.276,
      2.199,
      39.445,
      -56.276,
      2.199,
      39.445,
      -56.687,
      3.072,
      39.445,
      -56.687,
      3.072,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1370",
    "polygon": [
      -80.157,
      -34.142,
      23.288,
      -79.261,
      -33.721,
      23.288,
      -79.261,
      -33.721,
      26.818,
      -79.261,
      -33.721,
      26.818,
      -80.157,
      -34.142,
      26.818,
      -80.157,
      -34.142,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1469",
    "polygon": [
      -64.701,
      -44.65,
      23.288,
      -65.597,
      -45.071,
      23.288,
      -65.597,
      -45.071,
      26.818,
      -65.597,
      -45.071,
      26.818,
      -64.701,
      -44.65,
      26.818,
      -64.701,
      -44.65,
      23.288
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_9.OG_1734",
    "polygon": [
      -70.075,
      -4.286,
      35.445,
      -70.486,
      -3.414,
      35.445,
      -70.486,
      -3.414,
      39.445,
      -70.486,
      -3.414,
      39.445,
      -70.075,
      -4.286,
      39.445,
      -70.075,
      -4.286,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1644",
    "polygon": [
      -55.537,
      0.628,
      31.845,
      -55.126,
      -0.244,
      31.845,
      -55.126,
      -0.244,
      34.445,
      -55.126,
      -0.244,
      34.445,
      -55.537,
      0.628,
      34.445,
      -55.537,
      0.628,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_4.OG_948",
    "polygon": [
      -62.029,
      -21.387,
      16.5,
      -62.439,
      -20.515,
      16.5,
      -62.439,
      -20.515,
      18.277,
      -62.439,
      -20.515,
      18.277,
      -62.029,
      -21.387,
      18.277,
      -62.029,
      -21.387,
      16.5
    ]
  },
  {
    "ID": "DINHQ_9.OG_1716",
    "polygon": [
      -54.823,
      -0.889,
      35.445,
      -54.412,
      -1.761,
      35.445,
      -54.412,
      -1.761,
      39.445,
      -54.412,
      -1.761,
      39.445,
      -54.823,
      -0.889,
      39.445,
      -54.823,
      -0.889,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1468",
    "polygon": [
      -66.239,
      -45.374,
      23.288,
      -67.135,
      -45.795,
      23.288,
      -67.135,
      -45.795,
      26.818,
      -67.135,
      -45.795,
      26.818,
      -66.239,
      -45.374,
      26.818,
      -66.239,
      -45.374,
      23.288
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1376",
    "polygon": [
      -87.866,
      -37.77,
      23.288,
      -86.97,
      -37.349,
      23.288,
      -86.97,
      -37.349,
      26.818,
      -86.97,
      -37.349,
      26.818,
      -87.866,
      -37.77,
      26.818,
      -87.866,
      -37.77,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1515",
    "polygon": [
      10.277,
      -9.368,
      23.288,
      9.345,
      -9.806,
      23.288,
      9.345,
      -9.806,
      26.818,
      9.345,
      -9.806,
      26.818,
      10.277,
      -9.368,
      26.818,
      10.277,
      -9.368,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1532",
    "polygon": [
      -89.249,
      -42.454,
      23.36,
      -89.773,
      -41.341,
      23.36,
      -89.773,
      -41.341,
      26.82,
      -89.773,
      -41.341,
      26.82,
      -89.249,
      -42.454,
      26.82,
      -89.249,
      -42.454,
      23.36
    ]
  },
  {
    "ID": "DINHQ_9.OG_1746",
    "polygon": [
      -63.178,
      -18.944,
      35.445,
      -63.589,
      -18.072,
      35.445,
      -63.589,
      -18.072,
      39.445,
      -63.589,
      -18.072,
      39.445,
      -63.178,
      -18.944,
      39.445,
      -63.178,
      -18.944,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1602",
    "polygon": [
      -53.981,
      -38.491,
      28.03,
      -54.815,
      -36.721,
      28.03,
      -54.815,
      -36.721,
      30.848,
      -54.815,
      -36.721,
      30.848,
      -53.981,
      -38.491,
      30.848,
      -53.981,
      -38.491,
      28.03
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1374",
    "polygon": [
      -85.297,
      -36.561,
      23.288,
      -84.401,
      -36.14,
      23.288,
      -84.401,
      -36.14,
      26.818,
      -84.401,
      -36.14,
      26.818,
      -85.297,
      -36.561,
      26.818,
      -85.297,
      -36.561,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1494",
    "polygon": [
      -17.52,
      -22.448,
      23.289,
      -18.452,
      -22.887,
      23.289,
      -18.452,
      -22.887,
      26.819,
      -18.452,
      -22.887,
      26.819,
      -17.52,
      -22.448,
      26.819,
      -17.52,
      -22.448,
      23.289
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1684",
    "polygon": [
      -59.017,
      -27.795,
      31.845,
      -59.427,
      -26.922,
      31.845,
      -59.427,
      -26.922,
      34.445,
      -59.427,
      -26.922,
      34.445,
      -59.017,
      -27.795,
      34.445,
      -59.017,
      -27.795,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1514",
    "polygon": [
      9.203,
      -9.873,
      23.289,
      8.271,
      -10.311,
      23.289,
      8.271,
      -10.311,
      26.819,
      8.271,
      -10.311,
      26.819,
      9.203,
      -9.873,
      26.819,
      9.203,
      -9.873,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1492",
    "polygon": [
      -20.17,
      -23.695,
      23.289,
      -21.102,
      -24.133,
      23.289,
      -21.102,
      -24.133,
      26.819,
      -21.102,
      -24.133,
      26.819,
      -20.17,
      -23.695,
      26.819,
      -20.17,
      -23.695,
      23.289
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1610",
    "polygon": [
      -55.56,
      -35.137,
      27.315,
      -56.089,
      -34.011,
      27.315,
      -56.089,
      -34.011,
      29.66,
      -56.089,
      -34.011,
      29.66,
      -55.56,
      -35.137,
      29.66,
      -55.56,
      -35.137,
      27.315
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1784",
    "polygon": [
      -52.088,
      -6.701,
      40.445,
      -51.677,
      -7.573,
      40.445,
      -51.677,
      -7.573,
      44.113,
      -51.677,
      -7.573,
      44.113,
      -52.088,
      -6.701,
      44.113,
      -52.088,
      -6.701,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1206",
    "polygon": [
      -49.1,
      -42.549,
      23.288,
      -51.389,
      -43.626,
      23.288,
      -51.389,
      -43.626,
      26.818,
      -51.389,
      -43.626,
      26.818,
      -49.1,
      -42.549,
      26.818,
      -49.1,
      -42.549,
      23.288
    ],
    "11-07 08:15": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1659",
    "polygon": [
      -71.225,
      -1.843,
      31.845,
      -71.635,
      -0.971,
      31.845,
      -71.635,
      -0.971,
      34.445,
      -71.635,
      -0.971,
      34.445,
      -71.225,
      -1.843,
      34.445,
      -71.225,
      -1.843,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1047",
    "polygon": [
      10.911,
      -10.727,
      19.87,
      9.979,
      -11.165,
      19.87,
      9.979,
      -11.165,
      22.1,
      9.979,
      -11.165,
      22.1,
      10.911,
      -10.727,
      22.1,
      10.911,
      -10.727,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1456",
    "polygon": [
      -81.658,
      -52.629,
      23.288,
      -82.553,
      -53.05,
      23.288,
      -82.553,
      -53.05,
      26.818,
      -82.553,
      -53.05,
      26.818,
      -81.658,
      -52.629,
      26.818,
      -81.658,
      -52.629,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1765",
    "polygon": [
      -42.183,
      -27.756,
      35.445,
      -41.335,
      -29.557,
      35.445,
      -41.335,
      -29.557,
      39.445,
      -41.335,
      -29.557,
      39.445,
      -42.183,
      -27.756,
      39.445,
      -42.183,
      -27.756,
      35.445
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1089",
    "polygon": [
      -25.299,
      -8.329,
      19.87,
      -24.37,
      -7.891,
      19.87,
      -24.37,
      -7.891,
      22.1,
      -24.37,
      -7.891,
      22.1,
      -25.299,
      -8.329,
      22.1,
      -25.299,
      -8.329,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1518",
    "polygon": [
      14.434,
      -7.412,
      23.289,
      13.502,
      -7.85,
      23.289,
      13.502,
      -7.85,
      26.819,
      13.502,
      -7.85,
      26.819,
      14.434,
      -7.412,
      26.819,
      14.434,
      -7.412,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1204",
    "polygon": [
      -51.565,
      -43.626,
      23.288,
      -52.543,
      -41.547,
      23.288,
      -52.543,
      -41.547,
      26.818,
      -52.543,
      -41.547,
      26.818,
      -51.565,
      -43.626,
      26.818,
      -51.565,
      -43.626,
      23.288
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1126",
    "polygon": [
      -58.986,
      7.958,
      19.87,
      -58.576,
      7.086,
      19.87,
      -58.576,
      7.086,
      21.647,
      -58.576,
      7.086,
      21.647,
      -58.986,
      7.958,
      21.647,
      -58.986,
      7.958,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1482",
    "polygon": [
      -33.418,
      -29.929,
      23.289,
      -34.35,
      -30.367,
      23.289,
      -34.35,
      -30.367,
      26.819,
      -34.35,
      -30.367,
      26.819,
      -33.418,
      -29.929,
      26.819,
      -33.418,
      -29.929,
      23.289
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_8.OG_1685",
    "polygon": [
      -58.581,
      -28.72,
      31.845,
      -58.992,
      -27.848,
      31.845,
      -58.992,
      -27.848,
      34.445,
      -58.992,
      -27.848,
      34.445,
      -58.581,
      -28.72,
      34.445,
      -58.581,
      -28.72,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1757",
    "polygon": [
      -59.017,
      -27.795,
      35.445,
      -59.427,
      -26.922,
      35.445,
      -59.427,
      -26.922,
      39.445,
      -59.427,
      -26.922,
      39.445,
      -59.017,
      -27.795,
      39.445,
      -59.017,
      -27.795,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1619",
    "polygon": [
      -43.331,
      -25.313,
      28.03,
      -42.484,
      -27.114,
      28.03,
      -42.484,
      -27.114,
      30.848,
      -42.484,
      -27.114,
      30.848,
      -43.331,
      -25.313,
      30.848,
      -43.331,
      -25.313,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1737",
    "polygon": [
      -68.212,
      -8.247,
      35.445,
      -68.622,
      -7.374,
      35.445,
      -68.622,
      -7.374,
      39.445,
      -68.622,
      -7.374,
      39.445,
      -68.212,
      -8.247,
      39.445,
      -68.212,
      -8.247,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1314",
    "polygon": [
      -51.374,
      -8.218,
      23.288,
      -50.963,
      -9.091,
      23.288,
      -50.963,
      -9.091,
      26.818,
      -50.963,
      -9.091,
      26.818,
      -51.374,
      -8.218,
      26.818,
      -51.374,
      -8.218,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1524",
    "polygon": [
      22.279,
      -3.72,
      23.289,
      21.347,
      -4.158,
      23.289,
      21.347,
      -4.158,
      26.819,
      21.347,
      -4.158,
      26.819,
      22.279,
      -3.72,
      26.819,
      22.279,
      -3.72,
      23.289
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_930",
    "polygon": [
      -72.808,
      1.52,
      16.5,
      -73.337,
      2.645,
      16.5,
      -73.337,
      2.645,
      18.277,
      -73.337,
      2.645,
      18.277,
      -72.808,
      1.52,
      18.277,
      -72.808,
      1.52,
      16.5
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1267",
    "polygon": [
      5.142,
      5.996,
      23.288,
      6.071,
      6.433,
      23.288,
      6.071,
      6.433,
      26.818,
      6.071,
      6.433,
      26.818,
      5.142,
      5.996,
      26.818,
      5.142,
      5.996,
      23.288
    ]
  },
  {
    "ID": "DINHQ_5.OG_1113",
    "polygon": [
      -51.374,
      -8.218,
      19.87,
      -50.963,
      -9.091,
      19.87,
      -50.963,
      -9.091,
      21.647,
      -50.963,
      -9.091,
      21.647,
      -51.374,
      -8.218,
      21.647,
      -51.374,
      -8.218,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1088",
    "polygon": [
      -24.222,
      -7.822,
      19.87,
      -23.292,
      -7.384,
      19.87,
      -23.292,
      -7.384,
      22.1,
      -23.292,
      -7.384,
      22.1,
      -24.222,
      -7.822,
      22.1,
      -24.222,
      -7.822,
      19.87
    ]
  },
  {
    "ID": "DINHQ_8.OG_1694",
    "polygon": [
      -55.131,
      -36.048,
      31.845,
      -55.978,
      -34.247,
      31.845,
      -55.978,
      -34.247,
      34.445,
      -55.978,
      -34.247,
      34.445,
      -55.131,
      -36.048,
      34.445,
      -55.131,
      -36.048,
      31.845
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1745",
    "polygon": [
      -63.614,
      -18.018,
      35.445,
      -64.024,
      -17.146,
      35.445,
      -64.024,
      -17.146,
      39.445,
      -64.024,
      -17.146,
      39.445,
      -63.614,
      -18.018,
      39.445,
      -63.614,
      -18.018,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1264",
    "polygon": [
      9.351,
      7.977,
      23.288,
      10.28,
      8.414,
      23.288,
      10.28,
      8.414,
      26.818,
      10.28,
      8.414,
      26.818,
      9.351,
      7.977,
      26.818,
      9.351,
      7.977,
      23.288
    ]
  },
  {
    "ID": "DINHQ_8.OG_1628",
    "polygon": [
      -37.891,
      -37.274,
      31.845,
      -40.18,
      -38.351,
      31.845,
      -40.18,
      -38.351,
      34.445,
      -40.18,
      -38.351,
      34.445,
      -37.891,
      -37.274,
      34.445,
      -37.891,
      -37.274,
      31.845
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1288",
    "polygon": [
      -22.65,
      -7.082,
      23.288,
      -21.721,
      -6.645,
      23.288,
      -21.721,
      -6.645,
      26.818,
      -21.721,
      -6.645,
      26.818,
      -22.65,
      -7.082,
      26.818,
      -22.65,
      -7.082,
      23.288
    ]
  },
  {
    "ID": "DINHQ_10.OG_1831",
    "polygon": [
      -58.581,
      -28.72,
      40.445,
      -58.992,
      -27.848,
      40.445,
      -58.992,
      -27.848,
      44.113,
      -58.992,
      -27.848,
      44.113,
      -58.581,
      -28.72,
      44.113,
      -58.581,
      -28.72,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1499",
    "polygon": [
      -11.15,
      -19.45,
      23.288,
      -12.082,
      -19.889,
      23.288,
      -12.082,
      -19.889,
      26.818,
      -12.082,
      -19.889,
      26.818,
      -11.15,
      -19.45,
      26.818,
      -11.15,
      -19.45,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1802",
    "polygon": [
      -72.373,
      0.597,
      40.445,
      -72.784,
      1.469,
      40.445,
      -72.784,
      1.469,
      44.113,
      -72.784,
      1.469,
      44.113,
      -72.373,
      0.597,
      44.113,
      -72.373,
      0.597,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_933",
    "polygon": [
      -70.511,
      -3.361,
      16.5,
      -70.922,
      -2.488,
      16.5,
      -70.922,
      -2.488,
      18.277,
      -70.922,
      -2.488,
      18.277,
      -70.511,
      -3.361,
      18.277,
      -70.511,
      -3.361,
      16.5
    ],
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_4.OG_931",
    "polygon": [
      -71.661,
      -0.918,
      16.5,
      -72.071,
      -0.045,
      16.5,
      -72.071,
      -0.045,
      18.277,
      -72.071,
      -0.045,
      18.277,
      -71.661,
      -0.918,
      18.277,
      -71.661,
      -0.918,
      16.5
    ],
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1168",
    "polygon": [
      -79.123,
      -33.656,
      19.87,
      -78.227,
      -33.234,
      19.87,
      -78.227,
      -33.234,
      22.095,
      -78.227,
      -33.234,
      22.095,
      -79.123,
      -33.656,
      22.095,
      -79.123,
      -33.656,
      19.87
    ]
  },
  {
    "ID": "DINHQ_8.OG_1650",
    "polygon": [
      -58.986,
      7.958,
      31.845,
      -58.576,
      7.086,
      31.845,
      -58.576,
      7.086,
      34.445,
      -58.576,
      7.086,
      34.445,
      -58.986,
      7.958,
      34.445,
      -58.986,
      7.958,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1575",
    "polygon": [
      -72.959,
      2.912,
      28.03,
      -70.94,
      3.863,
      28.03,
      -70.94,
      3.863,
      30.848,
      -70.94,
      3.863,
      30.848,
      -72.959,
      2.912,
      30.848,
      -72.959,
      2.912,
      28.03
    ]
  },
  {
    "ID": "DINHQ_7.OG_1573",
    "polygon": [
      -63.886,
      7.181,
      28.03,
      -62.661,
      7.757,
      28.03,
      -62.661,
      7.757,
      30.848,
      -62.661,
      7.757,
      30.848,
      -63.886,
      7.181,
      30.848,
      -63.886,
      7.181,
      28.03
    ]
  },
  {
    "ID": "DINHQ_5.OG_1150",
    "polygon": [
      -62.464,
      -20.461,
      19.87,
      -62.875,
      -19.589,
      19.87,
      -62.875,
      -19.589,
      21.647,
      -62.875,
      -19.589,
      21.647,
      -62.464,
      -20.461,
      21.647,
      -62.464,
      -20.461,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1036",
    "polygon": [
      -3.641,
      -17.574,
      19.87,
      -4.573,
      -18.013,
      19.87,
      -4.573,
      -18.013,
      22.1,
      -4.573,
      -18.013,
      22.1,
      -3.641,
      -17.574,
      22.1,
      -3.641,
      -17.574,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1502",
    "polygon": [
      -6.924,
      -17.462,
      23.289,
      -7.856,
      -17.9,
      23.289,
      -7.856,
      -17.9,
      26.819,
      -7.856,
      -17.9,
      26.819,
      -6.924,
      -17.462,
      26.819,
      -6.924,
      -17.462,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1667",
    "polygon": [
      -66.627,
      -11.615,
      31.845,
      -67.037,
      -10.743,
      31.845,
      -67.037,
      -10.743,
      34.445,
      -67.037,
      -10.743,
      34.445,
      -66.627,
      -11.615,
      34.445,
      -66.627,
      -11.615,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1648",
    "polygon": [
      -57.836,
      5.515,
      31.845,
      -57.426,
      4.643,
      31.845,
      -57.426,
      4.643,
      34.445,
      -57.426,
      4.643,
      34.445,
      -57.836,
      5.515,
      34.445,
      -57.836,
      5.515,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1725",
    "polygon": [
      -62.02,
      8.06,
      35.445,
      -59.729,
      9.138,
      35.445,
      -59.729,
      9.138,
      39.445,
      -59.729,
      9.138,
      39.445,
      -62.02,
      8.06,
      39.445,
      -62.02,
      8.06,
      35.445
    ]
  },
  {
    "ID": "DINHQ_5.OG_1099",
    "polygon": [
      -38.546,
      -14.562,
      19.87,
      -37.617,
      -14.125,
      19.87,
      -37.617,
      -14.125,
      22.1,
      -37.617,
      -14.125,
      22.1,
      -38.546,
      -14.562,
      22.1,
      -38.546,
      -14.562,
      19.87
    ]
  },
  {
    "ID": "DINHQ_9.OG_1724",
    "polygon": [
      -59.538,
      9.131,
      35.445,
      -59.01,
      8.009,
      35.445,
      -59.01,
      8.009,
      39.445,
      -59.01,
      8.009,
      39.445,
      -59.538,
      9.131,
      39.445,
      -59.538,
      9.131,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1705",
    "polygon": [
      -48.639,
      -14.031,
      35.445,
      -48.228,
      -14.903,
      35.445,
      -48.228,
      -14.903,
      39.445,
      -48.228,
      -14.903,
      39.445,
      -48.639,
      -14.031,
      39.445,
      -48.639,
      -14.031,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1591",
    "polygon": [
      -64.328,
      -16.501,
      28.03,
      -64.738,
      -15.629,
      28.03,
      -64.738,
      -15.629,
      30.848,
      -64.738,
      -15.629,
      30.848,
      -64.328,
      -16.501,
      30.848,
      -64.328,
      -16.501,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1764",
    "polygon": [
      -41.031,
      -30.198,
      35.445,
      -40.184,
      -31.999,
      35.445,
      -40.184,
      -31.999,
      39.445,
      -40.184,
      -31.999,
      39.445,
      -41.031,
      -30.198,
      39.445,
      -41.031,
      -30.198,
      35.445
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1554",
    "polygon": [
      -49.788,
      -11.588,
      28.03,
      -49.378,
      -12.46,
      28.03,
      -49.378,
      -12.46,
      30.848,
      -49.378,
      -12.46,
      30.848,
      -49.788,
      -11.588,
      30.848,
      -49.788,
      -11.588,
      28.03
    ],
    "11-07 07:45": 1
  },
  {
    "ID": "DINHQ_10.OG_1798",
    "polygon": [
      -62.02,
      8.06,
      40.445,
      -59.729,
      9.138,
      40.445,
      -59.729,
      9.138,
      44.113,
      -59.729,
      9.138,
      44.113,
      -62.02,
      8.06,
      44.113,
      -62.02,
      8.06,
      40.445
    ]
  },
  {
    "ID": "DINHQ_6.OG_1368",
    "polygon": [
      -77.588,
      -32.934,
      23.288,
      -76.692,
      -32.512,
      23.288,
      -76.692,
      -32.512,
      26.818,
      -76.692,
      -32.512,
      26.818,
      -77.588,
      -32.934,
      26.818,
      -77.588,
      -32.934,
      23.288
    ]
  },
  {
    "ID": "DINHQ_7.OG_1579",
    "polygon": [
      -71.225,
      -1.843,
      28.03,
      -71.635,
      -0.971,
      28.03,
      -71.635,
      -0.971,
      30.848,
      -71.635,
      -0.971,
      30.848,
      -71.225,
      -1.843,
      30.848,
      -71.225,
      -1.843,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1717",
    "polygon": [
      -55.537,
      0.628,
      35.445,
      -55.126,
      -0.244,
      35.445,
      -55.126,
      -0.244,
      39.445,
      -55.126,
      -0.244,
      39.445,
      -55.537,
      0.628,
      39.445,
      -55.537,
      0.628,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1471",
    "polygon": [
      -62.131,
      -43.441,
      23.288,
      -63.027,
      -43.862,
      23.288,
      -63.027,
      -43.862,
      26.818,
      -63.027,
      -43.862,
      26.818,
      -62.131,
      -43.441,
      26.818,
      -62.131,
      -43.441,
      23.288
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1601",
    "polygon": [
      -52.846,
      -40.903,
      28.03,
      -53.679,
      -39.133,
      28.03,
      -53.679,
      -39.133,
      30.848,
      -53.679,
      -39.133,
      30.848,
      -52.846,
      -40.903,
      30.848,
      -52.846,
      -40.903,
      28.03
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_972",
    "polygon": [
      -87.866,
      -37.77,
      16.5,
      -86.97,
      -37.349,
      16.5,
      -86.97,
      -37.349,
      18.725,
      -86.97,
      -37.349,
      18.725,
      -87.866,
      -37.77,
      18.725,
      -87.866,
      -37.77,
      16.5
    ]
  },
  {
    "ID": "DINHQ_9.OG_1731",
    "polygon": [
      -71.661,
      -0.918,
      35.445,
      -72.071,
      -0.045,
      35.445,
      -72.071,
      -0.045,
      39.445,
      -72.071,
      -0.045,
      39.445,
      -71.661,
      -0.918,
      39.445,
      -71.661,
      -0.918,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1682",
    "polygon": [
      -60.164,
      -25.352,
      31.845,
      -60.575,
      -24.479,
      31.845,
      -60.575,
      -24.479,
      34.445,
      -60.575,
      -24.479,
      34.445,
      -60.164,
      -25.352,
      34.445,
      -60.164,
      -25.352,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1464",
    "polygon": [
      -71.379,
      -47.792,
      23.288,
      -72.275,
      -48.214,
      23.288,
      -72.275,
      -48.214,
      26.818,
      -72.275,
      -48.214,
      26.818,
      -71.379,
      -47.792,
      26.818,
      -71.379,
      -47.792,
      23.288
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_999",
    "polygon": [
      -56.354,
      -42.379,
      19.873,
      -57.25,
      -42.801,
      19.873,
      -57.25,
      -42.801,
      22.098,
      -57.25,
      -42.801,
      22.098,
      -56.354,
      -42.379,
      22.098,
      -56.354,
      -42.379,
      19.873
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_10.OG_1805",
    "polygon": [
      -71.225,
      -1.843,
      40.445,
      -71.635,
      -0.971,
      40.445,
      -71.635,
      -0.971,
      44.113,
      -71.635,
      -0.971,
      44.113,
      -71.225,
      -1.843,
      44.113,
      -71.225,
      -1.843,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_969",
    "polygon": [
      -84.262,
      -36.074,
      16.5,
      -83.366,
      -35.653,
      16.5,
      -83.366,
      -35.653,
      18.725,
      -83.366,
      -35.653,
      18.725,
      -84.262,
      -36.074,
      18.725,
      -84.262,
      -36.074,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1050",
    "polygon": [
      15.075,
      -8.767,
      19.87,
      14.143,
      -9.206,
      19.87,
      14.143,
      -9.206,
      22.1,
      14.143,
      -9.206,
      22.1,
      15.075,
      -8.767,
      22.1,
      15.075,
      -8.767,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_9.OG_1742",
    "polygon": [
      -65.477,
      -14.058,
      35.445,
      -65.888,
      -13.186,
      35.445,
      -65.888,
      -13.186,
      39.445,
      -65.888,
      -13.186,
      39.445,
      -65.477,
      -14.058,
      39.445,
      -65.477,
      -14.058,
      35.445
    ],
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1086",
    "polygon": [
      -21.572,
      -6.575,
      19.87,
      -20.643,
      -6.138,
      19.87,
      -20.643,
      -6.138,
      22.1,
      -20.643,
      -6.138,
      22.1,
      -21.572,
      -6.575,
      22.1,
      -21.572,
      -6.575,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1317",
    "polygon": [
      -53.238,
      -4.258,
      23.288,
      -52.827,
      -5.13,
      23.288,
      -52.827,
      -5.13,
      26.818,
      -52.827,
      -5.13,
      26.818,
      -53.238,
      -4.258,
      26.818,
      -53.238,
      -4.258,
      23.288
    ]
  },
  {
    "ID": "DINHQ_5.OG_1114",
    "polygon": [
      -52.088,
      -6.701,
      19.87,
      -51.677,
      -7.573,
      19.87,
      -51.677,
      -7.573,
      21.647,
      -51.677,
      -7.573,
      21.647,
      -52.088,
      -6.701,
      21.647,
      -52.088,
      -6.701,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1603",
    "polygon": [
      -56.28,
      -33.606,
      28.03,
      -57.128,
      -31.805,
      28.03,
      -57.128,
      -31.805,
      30.848,
      -57.128,
      -31.805,
      30.848,
      -56.28,
      -33.606,
      30.848,
      -56.28,
      -33.606,
      28.03
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1342",
    "polygon": [
      -67.776,
      -9.172,
      23.288,
      -68.187,
      -8.3,
      23.288,
      -68.187,
      -8.3,
      26.818,
      -68.187,
      -8.3,
      26.818,
      -67.776,
      -9.172,
      26.818,
      -67.776,
      -9.172,
      23.288
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_10.OG_1812",
    "polygon": [
      -67.062,
      -10.689,
      40.445,
      -67.473,
      -9.817,
      40.445,
      -67.473,
      -9.817,
      44.113,
      -67.473,
      -9.817,
      44.113,
      -67.062,
      -10.689,
      44.113,
      -67.062,
      -10.689,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1116",
    "polygon": [
      -53.238,
      -4.258,
      19.87,
      -52.827,
      -5.13,
      19.87,
      -52.827,
      -5.13,
      21.647,
      -52.827,
      -5.13,
      21.647,
      -53.238,
      -4.258,
      21.647,
      -53.238,
      -4.258,
      19.87
    ]
  },
  {
    "ID": "DINHQ_9.OG_1754",
    "polygon": [
      -57.429,
      -31.164,
      35.445,
      -58.277,
      -29.363,
      35.445,
      -58.277,
      -29.363,
      39.445,
      -58.277,
      -29.363,
      39.445,
      -57.429,
      -31.164,
      39.445,
      -57.429,
      -31.164,
      35.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_971",
    "polygon": [
      -86.832,
      -37.283,
      16.5,
      -85.936,
      -36.862,
      16.5,
      -85.936,
      -36.862,
      18.725,
      -85.936,
      -36.862,
      18.725,
      -86.832,
      -37.283,
      18.725,
      -86.832,
      -37.283,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1313",
    "polygon": [
      -50.938,
      -9.144,
      23.288,
      -50.528,
      -10.017,
      23.288,
      -50.528,
      -10.017,
      26.818,
      -50.528,
      -10.017,
      26.818,
      -50.938,
      -9.144,
      26.818,
      -50.938,
      -9.144,
      23.288
    ]
  },
  {
    "ID": "DINHQ_7.OG_1555",
    "polygon": [
      -50.224,
      -10.662,
      28.03,
      -49.814,
      -11.534,
      28.03,
      -49.814,
      -11.534,
      30.848,
      -49.814,
      -11.534,
      30.848,
      -50.224,
      -10.662,
      30.848,
      -50.224,
      -10.662,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_9.OG_1741",
    "polygon": [
      -65.913,
      -13.132,
      35.445,
      -66.323,
      -12.26,
      35.445,
      -66.323,
      -12.26,
      39.445,
      -66.323,
      -12.26,
      39.445,
      -65.913,
      -13.132,
      39.445,
      -65.913,
      -13.132,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1289",
    "polygon": [
      -24.222,
      -7.822,
      23.288,
      -23.292,
      -7.384,
      23.288,
      -23.292,
      -7.384,
      26.818,
      -23.292,
      -7.384,
      26.818,
      -24.222,
      -7.822,
      26.818,
      -24.222,
      -7.822,
      23.288
    ]
  },
  {
    "ID": "DINHQ_7.OG_1549",
    "polygon": [
      -38.743,
      -35.061,
      28.03,
      -37.764,
      -37.141,
      28.03,
      -37.764,
      -37.141,
      30.848,
      -37.764,
      -37.141,
      30.848,
      -38.743,
      -35.061,
      30.848,
      -38.743,
      -35.061,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1672",
    "polygon": [
      -63.614,
      -18.018,
      31.845,
      -64.024,
      -17.146,
      31.845,
      -64.024,
      -17.146,
      34.445,
      -64.024,
      -17.146,
      34.445,
      -63.614,
      -18.018,
      34.445,
      -63.614,
      -18.018,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1085",
    "polygon": [
      -20.001,
      -5.835,
      19.87,
      -19.071,
      -5.398,
      19.87,
      -19.071,
      -5.398,
      22.1,
      -19.071,
      -5.398,
      22.1,
      -20.001,
      -5.835,
      22.1,
      -20.001,
      -5.835,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1266",
    "polygon": [
      6.713,
      6.735,
      23.288,
      7.642,
      7.173,
      23.288,
      7.642,
      7.173,
      26.818,
      7.642,
      7.173,
      26.818,
      6.713,
      6.735,
      26.818,
      6.713,
      6.735,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1330",
    "polygon": [
      -63.886,
      7.181,
      23.288,
      -62.661,
      7.757,
      23.288,
      -62.661,
      7.757,
      26.818,
      -62.661,
      7.757,
      26.818,
      -63.886,
      7.181,
      26.818,
      -63.886,
      7.181,
      23.288
    ]
  },
  {
    "ID": "DINHQ_7.OG_1545",
    "polygon": [
      -49.1,
      -42.549,
      28.03,
      -51.389,
      -43.626,
      28.03,
      -51.389,
      -43.626,
      30.848,
      -51.389,
      -43.626,
      30.848,
      -49.1,
      -42.549,
      30.848,
      -49.1,
      -42.549,
      28.03
    ],
    "11-07 08:15": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1633",
    "polygon": [
      -49.074,
      -13.105,
      31.845,
      -48.664,
      -13.977,
      31.845,
      -48.664,
      -13.977,
      34.445,
      -48.664,
      -13.977,
      34.445,
      -49.074,
      -13.105,
      34.445,
      -49.074,
      -13.105,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_10.OG_1834",
    "polygon": [
      -45.192,
      -21.361,
      40.445,
      -44.782,
      -22.233,
      40.445,
      -44.782,
      -22.233,
      44.113,
      -44.782,
      -22.233,
      44.113,
      -45.192,
      -21.361,
      44.113,
      -45.192,
      -21.361,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1696",
    "polygon": [
      -51.565,
      -43.626,
      35.445,
      -52.543,
      -41.547,
      35.445,
      -52.543,
      -41.547,
      39.445,
      -52.543,
      -41.547,
      39.445,
      -51.565,
      -43.626,
      39.445,
      -51.565,
      -43.626,
      35.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1811",
    "polygon": [
      -67.776,
      -9.172,
      40.445,
      -68.187,
      -8.3,
      40.445,
      -68.187,
      -8.3,
      44.113,
      -68.187,
      -8.3,
      44.113,
      -67.776,
      -9.172,
      44.113,
      -67.776,
      -9.172,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1179",
    "polygon": [
      -93.006,
      -40.189,
      19.87,
      -92.11,
      -39.767,
      19.87,
      -92.11,
      -39.767,
      22.095,
      -92.11,
      -39.767,
      22.095,
      -93.006,
      -40.189,
      22.095,
      -93.006,
      -40.189,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1562",
    "polygon": [
      -54.387,
      -1.815,
      28.03,
      -53.977,
      -2.687,
      28.03,
      -53.977,
      -2.687,
      30.848,
      -53.977,
      -2.687,
      30.848,
      -54.387,
      -1.815,
      30.848,
      -54.387,
      -1.815,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1637",
    "polygon": [
      -51.374,
      -8.218,
      31.845,
      -50.963,
      -9.091,
      31.845,
      -50.963,
      -9.091,
      34.445,
      -50.963,
      -9.091,
      34.445,
      -51.374,
      -8.218,
      34.445,
      -51.374,
      -8.218,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_4.OG_909",
    "polygon": [
      -50.938,
      -9.144,
      16.5,
      -50.528,
      -10.017,
      16.5,
      -50.528,
      -10.017,
      18.277,
      -50.528,
      -10.017,
      18.277,
      -50.938,
      -9.144,
      18.277,
      -50.938,
      -9.144,
      16.5
    ]
  },
  {
    "ID": "DINHQ_8.OG_1676",
    "polygon": [
      -61.315,
      -22.904,
      31.845,
      -61.725,
      -22.032,
      31.845,
      -61.725,
      -22.032,
      34.445,
      -61.725,
      -22.032,
      34.445,
      -61.315,
      -22.904,
      34.445,
      -61.315,
      -22.904,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_907",
    "polygon": [
      -49.788,
      -11.588,
      16.5,
      -49.378,
      -12.46,
      16.5,
      -49.378,
      -12.46,
      18.277,
      -49.378,
      -12.46,
      18.277,
      -49.788,
      -11.588,
      18.277,
      -49.788,
      -11.588,
      16.5
    ]
  },
  {
    "ID": "DINHQ_9.OG_1753",
    "polygon": [
      -56.28,
      -33.606,
      35.445,
      -57.128,
      -31.805,
      35.445,
      -57.128,
      -31.805,
      39.445,
      -57.128,
      -31.805,
      39.445,
      -56.28,
      -33.606,
      39.445,
      -56.28,
      -33.606,
      35.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1010",
    "polygon": [
      -38.076,
      -33.778,
      19.87,
      -38.854,
      -34.144,
      19.87,
      -38.854,
      -34.144,
      22.1,
      -38.854,
      -34.144,
      22.1,
      -38.076,
      -33.778,
      22.1,
      -38.076,
      -33.778,
      19.87
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1608",
    "polygon": [
      -58.581,
      -28.72,
      28.03,
      -58.992,
      -27.848,
      28.03,
      -58.992,
      -27.848,
      30.848,
      -58.992,
      -27.848,
      30.848,
      -58.581,
      -28.72,
      30.848,
      -58.581,
      -28.72,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1457",
    "polygon": [
      -80.119,
      -51.905,
      23.288,
      -81.015,
      -52.327,
      23.288,
      -81.015,
      -52.327,
      26.818,
      -81.015,
      -52.327,
      26.818,
      -80.119,
      -51.905,
      26.818,
      -80.119,
      -51.905,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1009",
    "polygon": [
      -38.743,
      -35.061,
      19.87,
      -37.764,
      -37.141,
      19.87,
      -37.764,
      -37.141,
      22.1,
      -37.764,
      -37.141,
      22.1,
      -38.743,
      -35.061,
      22.1,
      -38.743,
      -35.061,
      19.87
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1663",
    "polygon": [
      -68.927,
      -6.728,
      31.845,
      -69.337,
      -5.855,
      31.845,
      -69.337,
      -5.855,
      34.445,
      -69.337,
      -5.855,
      34.445,
      -68.927,
      -6.728,
      34.445,
      -68.927,
      -6.728,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_947",
    "polygon": [
      -62.464,
      -20.461,
      16.5,
      -62.875,
      -19.589,
      16.5,
      -62.875,
      -19.589,
      18.277,
      -62.875,
      -19.589,
      18.277,
      -62.464,
      -20.461,
      18.277,
      -62.464,
      -20.461,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_935",
    "polygon": [
      -69.362,
      -5.802,
      16.5,
      -69.773,
      -4.93,
      16.5,
      -69.773,
      -4.93,
      18.277,
      -69.773,
      -4.93,
      18.277,
      -69.362,
      -5.802,
      18.277,
      -69.362,
      -5.802,
      16.5
    ],
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_10.OG_1828",
    "polygon": [
      -60.164,
      -25.352,
      40.445,
      -60.575,
      -24.479,
      40.445,
      -60.575,
      -24.479,
      44.113,
      -60.575,
      -24.479,
      44.113,
      -60.164,
      -25.352,
      44.113,
      -60.164,
      -25.352,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1645",
    "polygon": [
      -55.973,
      1.554,
      31.845,
      -55.562,
      0.682,
      31.845,
      -55.562,
      0.682,
      34.445,
      -55.562,
      0.682,
      34.445,
      -55.973,
      1.554,
      34.445,
      -55.973,
      1.554,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1008",
    "polygon": [
      -37.891,
      -37.274,
      19.87,
      -40.18,
      -38.351,
      19.87,
      -40.18,
      -38.351,
      22.1,
      -40.18,
      -38.351,
      22.1,
      -37.891,
      -37.274,
      22.1,
      -37.891,
      -37.274,
      19.87
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1328",
    "polygon": [
      -59.538,
      9.131,
      23.288,
      -59.01,
      8.009,
      23.288,
      -59.01,
      8.009,
      26.818,
      -59.01,
      8.009,
      26.818,
      -59.538,
      9.131,
      26.818,
      -59.538,
      9.131,
      23.288
    ],
    "11-07 08:00": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1340",
    "polygon": [
      -68.927,
      -6.728,
      23.288,
      -69.337,
      -5.855,
      23.288,
      -69.337,
      -5.855,
      26.818,
      -69.337,
      -5.855,
      26.818,
      -68.927,
      -6.728,
      26.818,
      -68.927,
      -6.728,
      23.288
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1660",
    "polygon": [
      -70.511,
      -3.361,
      31.845,
      -70.922,
      -2.488,
      31.845,
      -70.922,
      -2.488,
      34.445,
      -70.922,
      -2.488,
      34.445,
      -70.511,
      -3.361,
      34.445,
      -70.511,
      -3.361,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1508",
    "polygon": [
      1.025,
      -13.721,
      23.289,
      0.093,
      -14.16,
      23.289,
      0.093,
      -14.16,
      26.819,
      0.093,
      -14.16,
      26.819,
      1.025,
      -13.721,
      26.819,
      1.025,
      -13.721,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1341",
    "polygon": [
      -68.212,
      -8.247,
      23.288,
      -68.622,
      -7.374,
      23.288,
      -68.622,
      -7.374,
      26.818,
      -68.622,
      -7.374,
      26.818,
      -68.212,
      -8.247,
      26.818,
      -68.212,
      -8.247,
      23.288
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1073",
    "polygon": [
      -4.105,
      1.645,
      19.87,
      -3.176,
      2.082,
      19.87,
      -3.176,
      2.082,
      22.1,
      -3.176,
      2.082,
      22.1,
      -4.105,
      1.645,
      22.1,
      -4.105,
      1.645,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1024",
    "polygon": [
      -19.539,
      -25.055,
      19.87,
      -20.471,
      -25.494,
      19.87,
      -20.471,
      -25.494,
      22.1,
      -20.471,
      -25.494,
      22.1,
      -19.539,
      -25.055,
      22.1,
      -19.539,
      -25.055,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1130",
    "polygon": [
      -70.299,
      4.163,
      19.87,
      -69.073,
      4.74,
      19.87,
      -69.073,
      4.74,
      21.65,
      -69.073,
      4.74,
      21.65,
      -70.299,
      4.163,
      21.65,
      -70.299,
      4.163,
      19.87
    ]
  },
  {
    "ID": "DINHQ_10.OG_1832",
    "polygon": [
      -46.34,
      -18.917,
      40.445,
      -45.929,
      -19.789,
      40.445,
      -45.929,
      -19.789,
      44.113,
      -45.929,
      -19.789,
      44.113,
      -46.34,
      -18.917,
      44.113,
      -46.34,
      -18.917,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1485",
    "polygon": [
      -29.697,
      -28.178,
      23.288,
      -30.629,
      -28.616,
      23.288,
      -30.629,
      -28.616,
      26.818,
      -30.629,
      -28.616,
      26.818,
      -29.697,
      -28.178,
      26.818,
      -29.697,
      -28.178,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_8.OG_1674",
    "polygon": [
      -62.464,
      -20.461,
      31.845,
      -62.875,
      -19.589,
      31.845,
      -62.875,
      -19.589,
      34.445,
      -62.875,
      -19.589,
      34.445,
      -62.464,
      -20.461,
      34.445,
      -62.464,
      -20.461,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1702",
    "polygon": [
      -38.743,
      -35.061,
      35.445,
      -37.764,
      -37.141,
      35.445,
      -37.764,
      -37.141,
      39.445,
      -37.764,
      -37.141,
      39.445,
      -38.743,
      -35.061,
      39.445,
      -38.743,
      -35.061,
      35.445
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1678",
    "polygon": [
      -52.846,
      -40.903,
      31.845,
      -53.679,
      -39.133,
      31.845,
      -53.679,
      -39.133,
      34.445,
      -53.679,
      -39.133,
      34.445,
      -52.846,
      -40.903,
      34.445,
      -52.846,
      -40.903,
      31.845
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_983",
    "polygon": [
      -76.912,
      -52.053,
      19.873,
      -77.808,
      -52.475,
      19.873,
      -77.808,
      -52.475,
      22.098,
      -77.808,
      -52.475,
      22.098,
      -76.912,
      -52.053,
      22.098,
      -76.912,
      -52.053,
      19.873
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_959",
    "polygon": [
      -71.413,
      -30.028,
      16.5,
      -70.518,
      -29.607,
      16.5,
      -70.518,
      -29.607,
      18.725,
      -70.518,
      -29.607,
      18.725,
      -71.413,
      -30.028,
      18.725,
      -71.413,
      -30.028,
      16.5
    ]
  },
  {
    "ID": "DINHQ_10.OG_1821",
    "polygon": [
      -62.029,
      -21.387,
      40.445,
      -62.439,
      -20.515,
      40.445,
      -62.439,
      -20.515,
      44.113,
      -62.439,
      -20.515,
      44.113,
      -62.029,
      -21.387,
      44.113,
      -62.029,
      -21.387,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1596",
    "polygon": [
      -61.315,
      -22.904,
      28.03,
      -61.725,
      -22.032,
      28.03,
      -61.725,
      -22.032,
      30.848,
      -61.725,
      -22.032,
      30.848,
      -61.315,
      -22.904,
      30.848,
      -61.315,
      -22.904,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1794",
    "polygon": [
      -57.836,
      5.515,
      40.445,
      -57.426,
      4.643,
      40.445,
      -57.426,
      4.643,
      44.113,
      -57.426,
      4.643,
      44.113,
      -57.836,
      5.515,
      44.113,
      -57.836,
      5.515,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1604",
    "polygon": [
      -57.429,
      -31.164,
      28.03,
      -58.277,
      -29.363,
      28.03,
      -58.277,
      -29.363,
      30.848,
      -58.277,
      -29.363,
      30.848,
      -57.429,
      -31.164,
      30.848,
      -57.429,
      -31.164,
      28.03
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1462",
    "polygon": [
      -73.949,
      -49.001,
      23.288,
      -74.844,
      -49.423,
      23.288,
      -74.844,
      -49.423,
      26.818,
      -74.844,
      -49.423,
      26.818,
      -73.949,
      -49.001,
      26.818,
      -73.949,
      -49.001,
      23.288
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1011",
    "polygon": [
      -37.01,
      -33.277,
      19.87,
      -37.942,
      -33.715,
      19.87,
      -37.942,
      -33.715,
      22.1,
      -37.942,
      -33.715,
      22.1,
      -37.01,
      -33.277,
      22.1,
      -37.01,
      -33.277,
      19.87
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1
  },
  {
    "ID": "DINHQ_7.OG_1586",
    "polygon": [
      -67.062,
      -10.689,
      28.03,
      -67.473,
      -9.817,
      28.03,
      -67.473,
      -9.817,
      30.848,
      -67.473,
      -9.817,
      30.848,
      -67.062,
      -10.689,
      30.848,
      -67.062,
      -10.689,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1707",
    "polygon": [
      -49.788,
      -11.588,
      35.445,
      -49.378,
      -12.46,
      35.445,
      -49.378,
      -12.46,
      39.445,
      -49.378,
      -12.46,
      39.445,
      -49.788,
      -11.588,
      39.445,
      -49.788,
      -11.588,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1733",
    "polygon": [
      -70.511,
      -3.361,
      35.445,
      -70.922,
      -2.488,
      35.445,
      -70.922,
      -2.488,
      39.445,
      -70.922,
      -2.488,
      39.445,
      -70.511,
      -3.361,
      39.445,
      -70.511,
      -3.361,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1519",
    "polygon": [
      15.507,
      -6.907,
      23.289,
      14.575,
      -7.345,
      23.289,
      14.575,
      -7.345,
      26.819,
      14.575,
      -7.345,
      26.819,
      15.507,
      -6.907,
      26.819,
      15.507,
      -6.907,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1287",
    "polygon": [
      -21.572,
      -6.575,
      23.288,
      -20.643,
      -6.138,
      23.288,
      -20.643,
      -6.138,
      26.818,
      -20.643,
      -6.138,
      26.818,
      -21.572,
      -6.575,
      26.818,
      -21.572,
      -6.575,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1535",
    "polygon": [
      -88.157,
      -44.775,
      23.33,
      -88.466,
      -44.119,
      23.33,
      -88.466,
      -44.119,
      25.19,
      -88.466,
      -44.119,
      25.19,
      -88.157,
      -44.775,
      25.19,
      -88.157,
      -44.775,
      23.33
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1613",
    "polygon": [
      -46.775,
      -17.991,
      28.03,
      -46.365,
      -18.863,
      28.03,
      -46.365,
      -18.863,
      30.848,
      -46.365,
      -18.863,
      30.848,
      -46.775,
      -17.991,
      30.848,
      -46.775,
      -17.991,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_7.OG_1551",
    "polygon": [
      -47.925,
      -15.548,
      28.03,
      -47.514,
      -16.421,
      28.03,
      -47.514,
      -16.421,
      30.848,
      -47.514,
      -16.421,
      30.848,
      -47.925,
      -15.548,
      30.848,
      -47.925,
      -15.548,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1641",
    "polygon": [
      -53.673,
      -3.332,
      31.845,
      -53.263,
      -4.204,
      31.845,
      -53.263,
      -4.204,
      34.445,
      -53.263,
      -4.204,
      34.445,
      -53.673,
      -3.332,
      34.445,
      -53.673,
      -3.332,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1636",
    "polygon": [
      -50.938,
      -9.144,
      31.845,
      -50.528,
      -10.017,
      31.845,
      -50.528,
      -10.017,
      34.445,
      -50.528,
      -10.017,
      34.445,
      -50.938,
      -9.144,
      34.445,
      -50.938,
      -9.144,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_4.OG_946",
    "polygon": [
      -63.178,
      -18.944,
      16.5,
      -63.589,
      -18.072,
      16.5,
      -63.589,
      -18.072,
      18.277,
      -63.589,
      -18.072,
      18.277,
      -63.178,
      -18.944,
      18.277,
      -63.178,
      -18.944,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1460",
    "polygon": [
      -76.518,
      -50.21,
      23.288,
      -77.414,
      -50.632,
      23.288,
      -77.414,
      -50.632,
      26.818,
      -77.414,
      -50.632,
      26.818,
      -76.518,
      -50.21,
      26.818,
      -76.518,
      -50.21,
      23.288
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1841",
    "polygon": [
      -44.482,
      -22.871,
      40.445,
      -43.634,
      -24.671,
      40.445,
      -43.634,
      -24.671,
      44.113,
      -43.634,
      -24.671,
      44.113,
      -44.482,
      -22.871,
      44.113,
      -44.482,
      -22.871,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1544",
    "polygon": [
      -46.34,
      -41.25,
      28.03,
      -48.457,
      -42.247,
      28.03,
      -48.457,
      -42.247,
      30.848,
      -48.457,
      -42.247,
      30.848,
      -46.34,
      -41.25,
      30.848,
      -46.34,
      -41.25,
      28.03
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1169",
    "polygon": [
      -80.157,
      -34.142,
      19.87,
      -79.261,
      -33.721,
      19.87,
      -79.261,
      -33.721,
      22.095,
      -79.261,
      -33.721,
      22.095,
      -80.157,
      -34.142,
      22.095,
      -80.157,
      -34.142,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1458",
    "polygon": [
      -79.088,
      -51.42,
      23.288,
      -79.984,
      -51.841,
      23.288,
      -79.984,
      -51.841,
      26.818,
      -79.984,
      -51.841,
      26.818,
      -79.088,
      -51.42,
      26.818,
      -79.088,
      -51.42,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1761",
    "polygon": [
      -45.192,
      -21.361,
      35.445,
      -44.782,
      -22.233,
      35.445,
      -44.782,
      -22.233,
      39.445,
      -44.782,
      -22.233,
      39.445,
      -45.192,
      -21.361,
      39.445,
      -45.192,
      -21.361,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1833",
    "polygon": [
      -46.775,
      -17.991,
      40.445,
      -46.365,
      -18.863,
      40.445,
      -46.365,
      -18.863,
      44.113,
      -46.365,
      -18.863,
      44.113,
      -46.775,
      -17.991,
      44.113,
      -46.775,
      -17.991,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1124",
    "polygon": [
      -57.836,
      5.515,
      19.87,
      -57.426,
      4.643,
      19.87,
      -57.426,
      4.643,
      21.647,
      -57.426,
      4.643,
      21.647,
      -57.836,
      5.515,
      21.647,
      -57.836,
      5.515,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1583",
    "polygon": [
      -68.927,
      -6.728,
      28.03,
      -69.337,
      -5.855,
      28.03,
      -69.337,
      -5.855,
      30.848,
      -69.337,
      -5.855,
      30.848,
      -68.927,
      -6.728,
      30.848,
      -68.927,
      -6.728,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1104",
    "polygon": [
      -45.429,
      -17.801,
      19.87,
      -44.5,
      -17.364,
      19.87,
      -44.5,
      -17.364,
      22.1,
      -44.5,
      -17.364,
      22.1,
      -45.429,
      -17.801,
      22.1,
      -45.429,
      -17.801,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_998",
    "polygon": [
      -57.893,
      -43.103,
      19.873,
      -58.788,
      -43.525,
      19.873,
      -58.788,
      -43.525,
      22.098,
      -58.788,
      -43.525,
      22.098,
      -57.893,
      -43.103,
      22.098,
      -57.893,
      -43.103,
      19.873
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_917",
    "polygon": [
      -55.537,
      0.628,
      16.5,
      -55.126,
      -0.244,
      16.5,
      -55.126,
      -0.244,
      18.277,
      -55.126,
      -0.244,
      18.277,
      -55.537,
      0.628,
      18.277,
      -55.537,
      0.628,
      16.5
    ]
  },
  {
    "ID": "DINHQ_7.OG_1606",
    "polygon": [
      -59.729,
      -26.277,
      28.03,
      -60.139,
      -25.405,
      28.03,
      -60.139,
      -25.405,
      30.848,
      -60.139,
      -25.405,
      30.848,
      -59.729,
      -26.277,
      30.848,
      -59.729,
      -26.277,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1669",
    "polygon": [
      -65.477,
      -14.058,
      31.845,
      -65.888,
      -13.186,
      31.845,
      -65.888,
      -13.186,
      34.445,
      -65.888,
      -13.186,
      34.445,
      -65.477,
      -14.058,
      34.445,
      -65.477,
      -14.058,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1582",
    "polygon": [
      -69.362,
      -5.802,
      28.03,
      -69.773,
      -4.93,
      28.03,
      -69.773,
      -4.93,
      30.848,
      -69.773,
      -4.93,
      30.848,
      -69.362,
      -5.802,
      30.848,
      -69.362,
      -5.802,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1778",
    "polygon": [
      -48.639,
      -14.031,
      40.445,
      -48.228,
      -14.903,
      40.445,
      -48.228,
      -14.903,
      44.113,
      -48.228,
      -14.903,
      44.113,
      -48.639,
      -14.031,
      44.113,
      -48.639,
      -14.031,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1624",
    "polygon": [
      -46.34,
      -41.25,
      31.845,
      -48.457,
      -42.247,
      31.845,
      -48.457,
      -42.247,
      34.445,
      -48.457,
      -42.247,
      34.445,
      -46.34,
      -41.25,
      34.445,
      -46.34,
      -41.25,
      31.845
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1542",
    "polygon": [
      -39.561,
      -33.325,
      23.288,
      -39.05,
      -34.411,
      23.288,
      -39.05,
      -34.411,
      26.818,
      -39.05,
      -34.411,
      26.818,
      -39.561,
      -33.325,
      26.818,
      -39.561,
      -33.325,
      23.288
    ]
  },
  {
    "ID": "DINHQ_9.OG_1755",
    "polygon": [
      -60.164,
      -25.352,
      35.445,
      -60.575,
      -24.479,
      35.445,
      -60.575,
      -24.479,
      39.445,
      -60.575,
      -24.479,
      39.445,
      -60.164,
      -25.352,
      39.445,
      -60.164,
      -25.352,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1151",
    "polygon": [
      -62.029,
      -21.387,
      19.87,
      -62.439,
      -20.515,
      19.87,
      -62.439,
      -20.515,
      21.647,
      -62.439,
      -20.515,
      21.647,
      -62.029,
      -21.387,
      21.647,
      -62.029,
      -21.387,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1115",
    "polygon": [
      -52.523,
      -5.775,
      19.87,
      -52.113,
      -6.648,
      19.87,
      -52.113,
      -6.648,
      21.647,
      -52.113,
      -6.648,
      21.647,
      -52.523,
      -5.775,
      21.647,
      -52.523,
      -5.775,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1062",
    "polygon": [
      10.417,
      8.478,
      19.87,
      11.346,
      8.916,
      19.87,
      11.346,
      8.916,
      22.1,
      11.346,
      8.916,
      22.1,
      10.417,
      8.478,
      22.1,
      10.417,
      8.478,
      19.87
    ]
  },
  {
    "ID": "DINHQ_10.OG_1800",
    "polygon": [
      -70.299,
      4.163,
      40.445,
      -69.073,
      4.74,
      40.445,
      -69.073,
      4.74,
      44.113,
      -69.073,
      4.74,
      44.113,
      -70.299,
      4.163,
      44.113,
      -70.299,
      4.163,
      40.445
    ]
  },
  {
    "ID": "DINHQ_7.OG_1614",
    "polygon": [
      -45.192,
      -21.361,
      28.03,
      -44.782,
      -22.233,
      28.03,
      -44.782,
      -22.233,
      30.848,
      -44.782,
      -22.233,
      30.848,
      -45.192,
      -21.361,
      30.848,
      -45.192,
      -21.361,
      28.03
    ]
  },
  {
    "ID": "DINHQ_6.OG_1493",
    "polygon": [
      -19.097,
      -23.19,
      23.288,
      -20.029,
      -23.628,
      23.288,
      -20.029,
      -23.628,
      26.818,
      -20.029,
      -23.628,
      26.818,
      -19.097,
      -23.19,
      26.818,
      -19.097,
      -23.19,
      23.288
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1786",
    "polygon": [
      -53.238,
      -4.258,
      40.445,
      -52.827,
      -5.13,
      40.445,
      -52.827,
      -5.13,
      44.113,
      -52.827,
      -5.13,
      44.113,
      -53.238,
      -4.258,
      44.113,
      -53.238,
      -4.258,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1647",
    "polygon": [
      -57.122,
      3.998,
      31.845,
      -56.712,
      3.125,
      31.845,
      -56.712,
      3.125,
      34.445,
      -56.712,
      3.125,
      34.445,
      -57.122,
      3.998,
      34.445,
      -57.122,
      3.998,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1358",
    "polygon": [
      -64.739,
      -26.887,
      23.288,
      -63.843,
      -26.466,
      23.288,
      -63.843,
      -26.466,
      26.818,
      -63.843,
      -26.466,
      26.818,
      -64.739,
      -26.887,
      26.818,
      -64.739,
      -26.887,
      23.288
    ]
  },
  {
    "ID": "DINHQ_8.OG_1679",
    "polygon": [
      -53.981,
      -38.491,
      31.845,
      -54.815,
      -36.721,
      31.845,
      -54.815,
      -36.721,
      34.445,
      -54.815,
      -36.721,
      34.445,
      -53.981,
      -38.491,
      34.445,
      -53.981,
      -38.491,
      31.845
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1033",
    "polygon": [
      -7.865,
      -19.562,
      19.87,
      -8.797,
      -20.001,
      19.87,
      -8.797,
      -20.001,
      22.1,
      -8.797,
      -20.001,
      22.1,
      -7.865,
      -19.562,
      22.1,
      -7.865,
      -19.562,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1344",
    "polygon": [
      -66.627,
      -11.615,
      23.288,
      -67.037,
      -10.743,
      23.288,
      -67.037,
      -10.743,
      26.818,
      -67.037,
      -10.743,
      26.818,
      -66.627,
      -11.615,
      26.818,
      -66.627,
      -11.615,
      23.288
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_9.OG_1736",
    "polygon": [
      -68.927,
      -6.728,
      35.445,
      -69.337,
      -5.855,
      35.445,
      -69.337,
      -5.855,
      39.445,
      -69.337,
      -5.855,
      39.445,
      -68.927,
      -6.728,
      39.445,
      -68.927,
      -6.728,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1835",
    "polygon": [
      -45.628,
      -20.435,
      40.445,
      -45.218,
      -21.307,
      40.445,
      -45.218,
      -21.307,
      44.113,
      -45.218,
      -21.307,
      44.113,
      -45.628,
      -20.435,
      44.113,
      -45.628,
      -20.435,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_4.OG_906",
    "polygon": [
      -49.074,
      -13.105,
      16.5,
      -48.664,
      -13.977,
      16.5,
      -48.664,
      -13.977,
      18.277,
      -48.664,
      -13.977,
      18.277,
      -49.074,
      -13.105,
      18.277,
      -49.074,
      -13.105,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1128",
    "polygon": [
      -62.02,
      8.06,
      19.87,
      -59.729,
      9.138,
      19.87,
      -59.729,
      9.138,
      21.65,
      -59.729,
      9.138,
      21.65,
      -62.02,
      8.06,
      21.65,
      -62.02,
      8.06,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1510",
    "polygon": [
      3.674,
      -12.475,
      23.289,
      2.742,
      -12.913,
      23.289,
      2.742,
      -12.913,
      26.819,
      2.742,
      -12.913,
      26.819,
      3.674,
      -12.475,
      26.819,
      3.674,
      -12.475,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_9.OG_1712",
    "polygon": [
      -52.523,
      -5.775,
      35.445,
      -52.113,
      -6.648,
      35.445,
      -52.113,
      -6.648,
      39.445,
      -52.113,
      -6.648,
      39.445,
      -52.523,
      -5.775,
      39.445,
      -52.523,
      -5.775,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1657",
    "polygon": [
      -72.808,
      1.52,
      31.845,
      -73.337,
      2.645,
      31.845,
      -73.337,
      2.645,
      34.445,
      -73.337,
      2.645,
      34.445,
      -72.808,
      1.52,
      34.445,
      -72.808,
      1.52,
      31.845
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1766",
    "polygon": [
      -43.331,
      -25.313,
      35.445,
      -42.484,
      -27.114,
      35.445,
      -42.484,
      -27.114,
      39.445,
      -42.484,
      -27.114,
      39.445,
      -43.331,
      -25.313,
      39.445,
      -43.331,
      -25.313,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1277",
    "polygon": [
      -8.326,
      -0.341,
      23.288,
      -7.397,
      0.096,
      23.288,
      -7.397,
      0.096,
      26.818,
      -7.397,
      0.096,
      26.818,
      -8.326,
      -0.341,
      26.818,
      -8.326,
      -0.341,
      23.288
    ]
  },
  {
    "ID": "DINHQ_9.OG_1714",
    "polygon": [
      -53.673,
      -3.332,
      35.445,
      -53.263,
      -4.204,
      35.445,
      -53.263,
      -4.204,
      39.445,
      -53.263,
      -4.204,
      39.445,
      -53.673,
      -3.332,
      39.445,
      -53.673,
      -3.332,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_4.OG_973",
    "polygon": [
      -89.401,
      -38.492,
      16.5,
      -88.505,
      -38.071,
      16.5,
      -88.505,
      -38.071,
      18.725,
      -88.505,
      -38.071,
      18.725,
      -89.401,
      -38.492,
      18.725,
      -89.401,
      -38.492,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1292",
    "polygon": [
      -27.949,
      -9.575,
      23.288,
      -27.019,
      -9.138,
      23.288,
      -27.019,
      -9.138,
      26.818,
      -27.019,
      -9.138,
      26.818,
      -27.949,
      -9.575,
      26.818,
      -27.949,
      -9.575,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1367",
    "polygon": [
      -76.552,
      -32.446,
      23.288,
      -75.656,
      -32.025,
      23.288,
      -75.656,
      -32.025,
      26.818,
      -75.656,
      -32.025,
      26.818,
      -76.552,
      -32.446,
      26.818,
      -76.552,
      -32.446,
      23.288
    ]
  },
  {
    "ID": "DINHQ_8.OG_1680",
    "polygon": [
      -56.28,
      -33.606,
      31.845,
      -57.128,
      -31.805,
      31.845,
      -57.128,
      -31.805,
      34.445,
      -57.128,
      -31.805,
      34.445,
      -56.28,
      -33.606,
      34.445,
      -56.28,
      -33.606,
      31.845
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1796",
    "polygon": [
      -58.986,
      7.958,
      40.445,
      -58.576,
      7.086,
      40.445,
      -58.576,
      7.086,
      44.113,
      -58.576,
      7.086,
      44.113,
      -58.986,
      7.958,
      44.113,
      -58.986,
      7.958,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1629",
    "polygon": [
      -38.743,
      -35.061,
      31.845,
      -37.764,
      -37.141,
      31.845,
      -37.764,
      -37.141,
      34.445,
      -37.764,
      -37.141,
      34.445,
      -38.743,
      -35.061,
      34.445,
      -38.743,
      -35.061,
      31.845
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_4.OG_919",
    "polygon": [
      -56.687,
      3.072,
      16.5,
      -56.276,
      2.199,
      16.5,
      -56.276,
      2.199,
      18.277,
      -56.276,
      2.199,
      18.277,
      -56.687,
      3.072,
      18.277,
      -56.687,
      3.072,
      16.5
    ]
  },
  {
    "ID": "DINHQ_7.OG_1560",
    "polygon": [
      -53.238,
      -4.258,
      28.03,
      -52.827,
      -5.13,
      28.03,
      -52.827,
      -5.13,
      30.848,
      -52.827,
      -5.13,
      30.848,
      -53.238,
      -4.258,
      30.848,
      -53.238,
      -4.258,
      28.03
    ],
    "11-07 07:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1035",
    "polygon": [
      -5.215,
      -18.315,
      19.87,
      -6.147,
      -18.754,
      19.87,
      -6.147,
      -18.754,
      22.1,
      -6.147,
      -18.754,
      22.1,
      -5.215,
      -18.315,
      22.1,
      -5.215,
      -18.315,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1474",
    "polygon": [
      -58.53,
      -41.746,
      23.288,
      -59.426,
      -42.167,
      23.288,
      -59.426,
      -42.167,
      26.818,
      -59.426,
      -42.167,
      26.818,
      -58.53,
      -41.746,
      26.818,
      -58.53,
      -41.746,
      23.288
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_8.OG_1666",
    "polygon": [
      -67.062,
      -10.689,
      31.845,
      -67.473,
      -9.817,
      31.845,
      -67.473,
      -9.817,
      34.445,
      -67.473,
      -9.817,
      34.445,
      -67.062,
      -10.689,
      34.445,
      -67.062,
      -10.689,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1332",
    "polygon": [
      -72.959,
      2.912,
      23.288,
      -70.94,
      3.863,
      23.288,
      -70.94,
      3.863,
      26.818,
      -70.94,
      3.863,
      26.818,
      -72.959,
      2.912,
      26.818,
      -72.959,
      2.912,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1357",
    "polygon": [
      -61.117,
      -25.183,
      23.288,
      -60.755,
      -25.013,
      23.288,
      -60.755,
      -25.013,
      26.818,
      -60.755,
      -25.013,
      26.818,
      -61.117,
      -25.183,
      26.818,
      -61.117,
      -25.183,
      23.288
    ]
  },
  {
    "ID": "DINHQ_8.OG_1664",
    "polygon": [
      -68.212,
      -8.247,
      31.845,
      -68.622,
      -7.374,
      31.845,
      -68.622,
      -7.374,
      34.445,
      -68.622,
      -7.374,
      34.445,
      -68.212,
      -8.247,
      34.445,
      -68.212,
      -8.247,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1651",
    "polygon": [
      -59.538,
      9.131,
      31.845,
      -59.01,
      8.009,
      31.845,
      -59.01,
      8.009,
      34.445,
      -59.01,
      8.009,
      34.445,
      -59.538,
      9.131,
      34.445,
      -59.538,
      9.131,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1557",
    "polygon": [
      -51.374,
      -8.218,
      28.03,
      -50.963,
      -9.091,
      28.03,
      -50.963,
      -9.091,
      30.848,
      -50.963,
      -9.091,
      30.848,
      -51.374,
      -8.218,
      30.848,
      -51.374,
      -8.218,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_9.OG_1700",
    "polygon": [
      -40.822,
      -38.653,
      35.445,
      -42.939,
      -39.65,
      35.445,
      -42.939,
      -39.65,
      39.445,
      -42.939,
      -39.65,
      39.445,
      -40.822,
      -38.653,
      39.445,
      -40.822,
      -38.653,
      35.445
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1539",
    "polygon": [
      -85.637,
      -50.131,
      23.33,
      -85.946,
      -49.475,
      23.33,
      -85.946,
      -49.475,
      25.19,
      -85.946,
      -49.475,
      25.19,
      -85.637,
      -50.131,
      25.19,
      -85.637,
      -50.131,
      23.33
    ],
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1052",
    "polygon": [
      17.675,
      -7.544,
      19.87,
      16.743,
      -7.982,
      19.87,
      16.743,
      -7.982,
      22.1,
      16.743,
      -7.982,
      22.1,
      17.675,
      -7.544,
      22.1,
      17.675,
      -7.544,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1570",
    "polygon": [
      -58.986,
      7.958,
      28.03,
      -58.576,
      7.086,
      28.03,
      -58.576,
      7.086,
      30.848,
      -58.576,
      7.086,
      30.848,
      -58.986,
      7.958,
      30.848,
      -58.986,
      7.958,
      28.03
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1787",
    "polygon": [
      -53.673,
      -3.332,
      40.445,
      -53.263,
      -4.204,
      40.445,
      -53.263,
      -4.204,
      44.113,
      -53.263,
      -4.204,
      44.113,
      -53.673,
      -3.332,
      44.113,
      -53.673,
      -3.332,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_10.OG_1840",
    "polygon": [
      -55.131,
      -36.048,
      40.445,
      -55.978,
      -34.247,
      40.445,
      -55.978,
      -34.247,
      44.113,
      -55.978,
      -34.247,
      44.113,
      -55.131,
      -36.048,
      44.113,
      -55.131,
      -36.048,
      40.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1843",
    "polygon": [
      -47.093,
      -32.656,
      39.515,
      -48.082,
      -33.121,
      39.515,
      -48.082,
      -33.121,
      41.915,
      -48.082,
      -33.121,
      41.915,
      -47.093,
      -32.656,
      41.915,
      -47.093,
      -32.656,
      39.515
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_9.OG_1720",
    "polygon": [
      -57.122,
      3.998,
      35.445,
      -56.712,
      3.125,
      35.445,
      -56.712,
      3.125,
      39.445,
      -56.712,
      3.125,
      39.445,
      -57.122,
      3.998,
      39.445,
      -57.122,
      3.998,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1137",
    "polygon": [
      -70.075,
      -4.286,
      19.87,
      -70.486,
      -3.414,
      19.87,
      -70.486,
      -3.414,
      21.647,
      -70.486,
      -3.414,
      21.647,
      -70.075,
      -4.286,
      21.647,
      -70.075,
      -4.286,
      19.87
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1373",
    "polygon": [
      -84.262,
      -36.074,
      23.288,
      -83.366,
      -35.653,
      23.288,
      -83.366,
      -35.653,
      26.818,
      -83.366,
      -35.653,
      26.818,
      -84.262,
      -36.074,
      26.818,
      -84.262,
      -36.074,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1327",
    "polygon": [
      -58.986,
      7.958,
      23.288,
      -58.576,
      7.086,
      23.288,
      -58.576,
      7.086,
      26.818,
      -58.576,
      7.086,
      26.818,
      -58.986,
      7.958,
      26.818,
      -58.986,
      7.958,
      23.288
    ],
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1616",
    "polygon": [
      -39.882,
      -32.642,
      28.03,
      -39.049,
      -34.412,
      28.03,
      -39.049,
      -34.412,
      30.848,
      -39.049,
      -34.412,
      30.848,
      -39.882,
      -32.642,
      30.848,
      -39.882,
      -32.642,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1658",
    "polygon": [
      -71.661,
      -0.918,
      31.845,
      -72.071,
      -0.045,
      31.845,
      -72.071,
      -0.045,
      34.445,
      -72.071,
      -0.045,
      34.445,
      -71.661,
      -0.918,
      34.445,
      -71.661,
      -0.918,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_997",
    "polygon": [
      -58.924,
      -43.589,
      19.873,
      -59.82,
      -44.01,
      19.873,
      -59.82,
      -44.01,
      22.098,
      -59.82,
      -44.01,
      22.098,
      -58.924,
      -43.589,
      22.098,
      -58.924,
      -43.589,
      19.873
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1012",
    "polygon": [
      -35.436,
      -32.536,
      19.87,
      -36.368,
      -32.974,
      19.87,
      -36.368,
      -32.974,
      22.1,
      -36.368,
      -32.974,
      22.1,
      -35.436,
      -32.536,
      22.1,
      -35.436,
      -32.536,
      19.87
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1356",
    "polygon": [
      -63.707,
      -26.402,
      23.288,
      -62.811,
      -25.98,
      23.288,
      -62.811,
      -25.98,
      26.818,
      -62.811,
      -25.98,
      26.818,
      -63.707,
      -26.402,
      26.818,
      -63.707,
      -26.402,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1509",
    "polygon": [
      2.098,
      -13.216,
      23.288,
      1.166,
      -13.655,
      23.288,
      1.166,
      -13.655,
      26.818,
      1.166,
      -13.655,
      26.818,
      2.098,
      -13.216,
      26.818,
      2.098,
      -13.216,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1072",
    "polygon": [
      -3.027,
      2.152,
      19.87,
      -2.098,
      2.589,
      19.87,
      -2.098,
      2.589,
      22.1,
      -2.098,
      2.589,
      22.1,
      -3.027,
      2.152,
      22.1,
      -3.027,
      2.152,
      19.87
    ]
  },
  {
    "ID": "DINHQ_10.OG_1809",
    "polygon": [
      -68.927,
      -6.728,
      40.445,
      -69.337,
      -5.855,
      40.445,
      -69.337,
      -5.855,
      44.113,
      -69.337,
      -5.855,
      44.113,
      -68.927,
      -6.728,
      44.113,
      -68.927,
      -6.728,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_905",
    "polygon": [
      -48.639,
      -14.031,
      16.5,
      -48.228,
      -14.903,
      16.5,
      -48.228,
      -14.903,
      18.277,
      -48.228,
      -14.903,
      18.277,
      -48.639,
      -14.031,
      18.277,
      -48.639,
      -14.031,
      16.5
    ]
  },
  {
    "ID": "DINHQ_7.OG_1558",
    "polygon": [
      -52.088,
      -6.701,
      28.03,
      -51.677,
      -7.573,
      28.03,
      -51.677,
      -7.573,
      30.848,
      -51.677,
      -7.573,
      30.848,
      -52.088,
      -6.701,
      30.848,
      -52.088,
      -6.701,
      28.03
    ],
    "11-07 07:45": 1
  },
  {
    "ID": "DINHQ_10.OG_1785",
    "polygon": [
      -52.523,
      -5.775,
      40.445,
      -52.113,
      -6.648,
      40.445,
      -52.113,
      -6.648,
      44.113,
      -52.113,
      -6.648,
      44.113,
      -52.523,
      -5.775,
      44.113,
      -52.523,
      -5.775,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1167",
    "polygon": [
      -77.588,
      -32.934,
      19.87,
      -76.692,
      -32.512,
      19.87,
      -76.692,
      -32.512,
      22.095,
      -76.692,
      -32.512,
      22.095,
      -77.588,
      -32.934,
      22.095,
      -77.588,
      -32.934,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1569",
    "polygon": [
      -58.272,
      6.441,
      28.03,
      -57.862,
      5.569,
      28.03,
      -57.862,
      5.569,
      30.848,
      -57.862,
      5.569,
      30.848,
      -58.272,
      6.441,
      30.848,
      -58.272,
      6.441,
      28.03
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1111",
    "polygon": [
      -50.224,
      -10.662,
      19.87,
      -49.814,
      -11.534,
      19.87,
      -49.814,
      -11.534,
      21.647,
      -49.814,
      -11.534,
      21.647,
      -50.224,
      -10.662,
      21.647,
      -50.224,
      -10.662,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1506",
    "polygon": [
      -1.623,
      -14.967,
      23.289,
      -2.555,
      -15.406,
      23.289,
      -2.555,
      -15.406,
      26.819,
      -2.555,
      -15.406,
      26.819,
      -1.623,
      -14.967,
      26.819,
      -1.623,
      -14.967,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1476",
    "polygon": [
      -55.961,
      -40.537,
      23.288,
      -56.856,
      -40.958,
      23.288,
      -56.856,
      -40.958,
      26.818,
      -56.856,
      -40.958,
      26.818,
      -55.961,
      -40.537,
      26.818,
      -55.961,
      -40.537,
      23.288
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1748",
    "polygon": [
      -62.029,
      -21.387,
      35.445,
      -62.439,
      -20.515,
      35.445,
      -62.439,
      -20.515,
      39.445,
      -62.439,
      -20.515,
      39.445,
      -62.029,
      -21.387,
      39.445,
      -62.029,
      -21.387,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1091",
    "polygon": [
      -27.949,
      -9.575,
      19.87,
      -27.019,
      -9.138,
      19.87,
      -27.019,
      -9.138,
      22.1,
      -27.019,
      -9.138,
      22.1,
      -27.949,
      -9.575,
      22.1,
      -27.949,
      -9.575,
      19.87
    ]
  },
  {
    "ID": "DINHQ_8.OG_1670",
    "polygon": [
      -64.763,
      -15.575,
      31.845,
      -65.174,
      -14.703,
      31.845,
      -65.174,
      -14.703,
      34.445,
      -65.174,
      -14.703,
      34.445,
      -64.763,
      -15.575,
      34.445,
      -64.763,
      -15.575,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_908",
    "polygon": [
      -50.224,
      -10.662,
      16.5,
      -49.814,
      -11.534,
      16.5,
      -49.814,
      -11.534,
      18.277,
      -49.814,
      -11.534,
      18.277,
      -50.224,
      -10.662,
      18.277,
      -50.224,
      -10.662,
      16.5
    ]
  },
  {
    "ID": "DINHQ_8.OG_1693",
    "polygon": [
      -43.331,
      -25.313,
      31.845,
      -42.484,
      -27.114,
      31.845,
      -42.484,
      -27.114,
      34.445,
      -42.484,
      -27.114,
      34.445,
      -43.331,
      -25.313,
      34.445,
      -43.331,
      -25.313,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1490",
    "polygon": [
      -22.821,
      -24.943,
      23.289,
      -23.753,
      -25.381,
      23.289,
      -23.753,
      -25.381,
      26.819,
      -23.753,
      -25.381,
      26.819,
      -22.821,
      -24.943,
      26.819,
      -22.821,
      -24.943,
      23.289
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1639",
    "polygon": [
      -52.523,
      -5.775,
      31.845,
      -52.113,
      -6.648,
      31.845,
      -52.113,
      -6.648,
      34.445,
      -52.113,
      -6.648,
      34.445,
      -52.523,
      -5.775,
      34.445,
      -52.523,
      -5.775,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_7.OG_1572",
    "polygon": [
      -62.02,
      8.06,
      28.03,
      -59.729,
      9.138,
      28.03,
      -59.729,
      9.138,
      30.848,
      -59.729,
      9.138,
      30.848,
      -62.02,
      8.06,
      30.848,
      -62.02,
      8.06,
      28.03
    ]
  },
  {
    "ID": "DINHQ_6.OG_1537",
    "polygon": [
      -87.835,
      -45.46,
      25.22,
      -88.477,
      -44.095,
      25.22,
      -88.477,
      -44.095,
      26.82,
      -88.477,
      -44.095,
      26.82,
      -87.835,
      -45.46,
      26.82,
      -87.835,
      -45.46,
      25.22
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1839",
    "polygon": [
      -43.331,
      -25.313,
      40.445,
      -42.484,
      -27.114,
      40.445,
      -42.484,
      -27.114,
      44.113,
      -42.484,
      -27.114,
      44.113,
      -43.331,
      -25.313,
      44.113,
      -43.331,
      -25.313,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1483",
    "polygon": [
      -32.345,
      -29.424,
      23.288,
      -33.277,
      -29.862,
      23.288,
      -33.277,
      -29.862,
      26.818,
      -33.277,
      -29.862,
      26.818,
      -32.345,
      -29.424,
      26.818,
      -32.345,
      -29.424,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1306",
    "polygon": [
      -46.255,
      -18.19,
      23.288,
      -45.576,
      -17.87,
      23.288,
      -45.576,
      -17.87,
      26.818,
      -45.576,
      -17.87,
      26.818,
      -46.255,
      -18.19,
      26.818,
      -46.255,
      -18.19,
      23.288
    ]
  },
  {
    "ID": "DINHQ_10.OG_1807",
    "polygon": [
      -70.075,
      -4.286,
      40.445,
      -70.486,
      -3.414,
      40.445,
      -70.486,
      -3.414,
      44.113,
      -70.486,
      -3.414,
      44.113,
      -70.075,
      -4.286,
      44.113,
      -70.075,
      -4.286,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1459",
    "polygon": [
      -77.55,
      -50.696,
      23.288,
      -78.446,
      -51.117,
      23.288,
      -78.446,
      -51.117,
      26.818,
      -78.446,
      -51.117,
      26.818,
      -77.55,
      -50.696,
      26.818,
      -77.55,
      -50.696,
      23.288
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1143",
    "polygon": [
      -66.627,
      -11.615,
      19.87,
      -67.037,
      -10.743,
      19.87,
      -67.037,
      -10.743,
      21.647,
      -67.037,
      -10.743,
      21.647,
      -66.627,
      -11.615,
      21.647,
      -66.627,
      -11.615,
      19.87
    ],
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1675",
    "polygon": [
      -62.029,
      -21.387,
      31.845,
      -62.439,
      -20.515,
      31.845,
      -62.439,
      -20.515,
      34.445,
      -62.439,
      -20.515,
      34.445,
      -62.029,
      -21.387,
      34.445,
      -62.029,
      -21.387,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1810",
    "polygon": [
      -68.212,
      -8.247,
      40.445,
      -68.622,
      -7.374,
      40.445,
      -68.622,
      -7.374,
      44.113,
      -68.622,
      -7.374,
      44.113,
      -68.212,
      -8.247,
      44.113,
      -68.212,
      -8.247,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1632",
    "polygon": [
      -48.639,
      -14.031,
      31.845,
      -48.228,
      -14.903,
      31.845,
      -48.228,
      -14.903,
      34.445,
      -48.228,
      -14.903,
      34.445,
      -48.639,
      -14.031,
      34.445,
      -48.639,
      -14.031,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1577",
    "polygon": [
      -72.808,
      1.52,
      28.03,
      -73.337,
      2.645,
      28.03,
      -73.337,
      2.645,
      30.848,
      -73.337,
      2.645,
      30.848,
      -72.808,
      1.52,
      30.848,
      -72.808,
      1.52,
      28.03
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1806",
    "polygon": [
      -70.511,
      -3.361,
      40.445,
      -70.922,
      -2.488,
      40.445,
      -70.922,
      -2.488,
      44.113,
      -70.922,
      -2.488,
      44.113,
      -70.511,
      -3.361,
      44.113,
      -70.511,
      -3.361,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1829",
    "polygon": [
      -59.729,
      -26.277,
      40.445,
      -60.139,
      -25.405,
      40.445,
      -60.139,
      -25.405,
      44.113,
      -60.139,
      -25.405,
      44.113,
      -59.729,
      -26.277,
      44.113,
      -59.729,
      -26.277,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1541",
    "polygon": [
      -52.845,
      -40.904,
      23.288,
      -53.356,
      -39.818,
      23.288,
      -53.356,
      -39.818,
      26.818,
      -53.356,
      -39.818,
      26.818,
      -52.845,
      -40.904,
      26.818,
      -52.845,
      -40.904,
      23.288
    ],
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1643",
    "polygon": [
      -54.823,
      -0.889,
      31.845,
      -54.412,
      -1.761,
      31.845,
      -54.412,
      -1.761,
      34.445,
      -54.412,
      -1.761,
      34.445,
      -54.823,
      -0.889,
      34.445,
      -54.823,
      -0.889,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1154",
    "polygon": [
      -62.173,
      -25.68,
      19.87,
      -61.277,
      -25.258,
      19.87,
      -61.277,
      -25.258,
      22.095,
      -61.277,
      -25.258,
      22.095,
      -62.173,
      -25.68,
      22.095,
      -62.173,
      -25.68,
      19.87
    ]
  },
  {
    "ID": "DINHQ_9.OG_1735",
    "polygon": [
      -69.362,
      -5.802,
      35.445,
      -69.773,
      -4.93,
      35.445,
      -69.773,
      -4.93,
      39.445,
      -69.773,
      -4.93,
      39.445,
      -69.362,
      -5.802,
      39.445,
      -69.362,
      -5.802,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1750",
    "polygon": [
      -60.879,
      -23.83,
      35.445,
      -61.289,
      -22.958,
      35.445,
      -61.289,
      -22.958,
      39.445,
      -61.289,
      -22.958,
      39.445,
      -60.879,
      -23.83,
      39.445,
      -60.879,
      -23.83,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1527",
    "polygon": [
      -38.774,
      -32.449,
      23.347,
      -39.407,
      -32.747,
      23.347,
      -39.407,
      -32.747,
      25.177,
      -39.407,
      -32.747,
      25.177,
      -38.774,
      -32.449,
      25.177,
      -38.774,
      -32.449,
      23.347
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1531",
    "polygon": [
      -87.224,
      -46.757,
      23.36,
      -87.769,
      -45.599,
      23.36,
      -87.769,
      -45.599,
      26.82,
      -87.769,
      -45.599,
      26.82,
      -87.224,
      -46.757,
      26.82,
      -87.224,
      -46.757,
      23.36
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1103",
    "polygon": [
      -43.857,
      -17.061,
      19.87,
      -42.928,
      -16.624,
      19.87,
      -42.928,
      -16.624,
      22.1,
      -42.928,
      -16.624,
      22.1,
      -43.857,
      -17.061,
      22.1,
      -43.857,
      -17.061,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1467",
    "polygon": [
      -67.271,
      -45.859,
      23.288,
      -68.167,
      -46.28,
      23.288,
      -68.167,
      -46.28,
      26.818,
      -68.167,
      -46.28,
      26.818,
      -67.271,
      -45.859,
      26.818,
      -67.271,
      -45.859,
      23.288
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1129",
    "polygon": [
      -63.886,
      7.181,
      19.87,
      -62.661,
      7.757,
      19.87,
      -62.661,
      7.757,
      21.65,
      -62.661,
      7.757,
      21.65,
      -63.886,
      7.181,
      21.65,
      -63.886,
      7.181,
      19.87
    ]
  },
  {
    "ID": "DINHQ_10.OG_1790",
    "polygon": [
      -55.537,
      0.628,
      40.445,
      -55.126,
      -0.244,
      40.445,
      -55.126,
      -0.244,
      44.113,
      -55.126,
      -0.244,
      44.113,
      -55.537,
      0.628,
      44.113,
      -55.537,
      0.628,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1371",
    "polygon": [
      -81.692,
      -34.865,
      23.288,
      -80.796,
      -34.443,
      23.288,
      -80.796,
      -34.443,
      26.818,
      -80.796,
      -34.443,
      26.818,
      -81.692,
      -34.865,
      26.818,
      -81.692,
      -34.865,
      23.288
    ]
  },
  {
    "ID": "DINHQ_5.OG_1020",
    "polygon": [
      -24.838,
      -27.549,
      19.87,
      -25.77,
      -27.987,
      19.87,
      -25.77,
      -27.987,
      22.1,
      -25.77,
      -27.987,
      22.1,
      -24.838,
      -27.549,
      22.1,
      -24.838,
      -27.549,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1623",
    "polygon": [
      -51.565,
      -43.626,
      31.845,
      -52.543,
      -41.547,
      31.845,
      -52.543,
      -41.547,
      34.445,
      -52.543,
      -41.547,
      34.445,
      -51.565,
      -43.626,
      34.445,
      -51.565,
      -43.626,
      31.845
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_970",
    "polygon": [
      -85.297,
      -36.561,
      16.5,
      -84.401,
      -36.14,
      16.5,
      -84.401,
      -36.14,
      18.725,
      -84.401,
      -36.14,
      18.725,
      -85.297,
      -36.561,
      18.725,
      -85.297,
      -36.561,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1075",
    "polygon": [
      -6.754,
      0.398,
      19.87,
      -5.825,
      0.835,
      19.87,
      -5.825,
      0.835,
      22.1,
      -5.825,
      0.835,
      22.1,
      -6.754,
      0.398,
      22.1,
      -6.754,
      0.398,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1477",
    "polygon": [
      -54.422,
      -39.813,
      23.288,
      -55.318,
      -40.234,
      23.288,
      -55.318,
      -40.234,
      26.818,
      -55.318,
      -40.234,
      26.818,
      -54.422,
      -39.813,
      26.818,
      -54.422,
      -39.813,
      23.288
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1263",
    "polygon": [
      10.417,
      8.478,
      23.288,
      11.346,
      8.916,
      23.288,
      11.346,
      8.916,
      26.818,
      11.346,
      8.916,
      26.818,
      10.417,
      8.478,
      26.818,
      10.417,
      8.478,
      23.288
    ]
  },
  {
    "ID": "DINHQ_5.OG_1176",
    "polygon": [
      -89.401,
      -38.492,
      19.87,
      -88.505,
      -38.071,
      19.87,
      -88.505,
      -38.071,
      22.095,
      -88.505,
      -38.071,
      22.095,
      -89.401,
      -38.492,
      22.095,
      -89.401,
      -38.492,
      19.87
    ]
  },
  {
    "ID": "DINHQ_4.OG_918",
    "polygon": [
      -55.973,
      1.554,
      16.5,
      -55.562,
      0.682,
      16.5,
      -55.562,
      0.682,
      18.277,
      -55.562,
      0.682,
      18.277,
      -55.973,
      1.554,
      18.277,
      -55.973,
      1.554,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_895",
    "polygon": [
      -37.468,
      -14.055,
      16.5,
      -36.539,
      -13.618,
      16.5,
      -36.539,
      -13.618,
      18.73,
      -36.539,
      -13.618,
      18.73,
      -37.468,
      -14.055,
      18.73,
      -37.468,
      -14.055,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1138",
    "polygon": [
      -69.362,
      -5.802,
      19.87,
      -69.773,
      -4.93,
      19.87,
      -69.773,
      -4.93,
      21.647,
      -69.773,
      -4.93,
      21.647,
      -69.362,
      -5.802,
      21.647,
      -69.362,
      -5.802,
      19.87
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1505",
    "polygon": [
      -3.201,
      -15.71,
      23.288,
      -4.133,
      -16.149,
      23.288,
      -4.133,
      -16.149,
      26.818,
      -4.133,
      -16.149,
      26.818,
      -3.201,
      -15.71,
      26.818,
      -3.201,
      -15.71,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1343",
    "polygon": [
      -67.062,
      -10.689,
      23.288,
      -67.473,
      -9.817,
      23.288,
      -67.473,
      -9.817,
      26.818,
      -67.473,
      -9.817,
      26.818,
      -67.062,
      -10.689,
      26.818,
      -67.062,
      -10.689,
      23.288
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1301",
    "polygon": [
      -40.118,
      -15.302,
      23.288,
      -39.188,
      -14.864,
      23.288,
      -39.188,
      -14.864,
      26.818,
      -39.188,
      -14.864,
      26.818,
      -40.118,
      -15.302,
      26.818,
      -40.118,
      -15.302,
      23.288
    ]
  },
  {
    "ID": "DINHQ_10.OG_1808",
    "polygon": [
      -69.362,
      -5.802,
      40.445,
      -69.773,
      -4.93,
      40.445,
      -69.773,
      -4.93,
      44.113,
      -69.773,
      -4.93,
      44.113,
      -69.362,
      -5.802,
      44.113,
      -69.362,
      -5.802,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1615",
    "polygon": [
      -45.628,
      -20.435,
      28.03,
      -45.218,
      -21.307,
      28.03,
      -45.218,
      -21.307,
      30.848,
      -45.218,
      -21.307,
      30.848,
      -45.628,
      -20.435,
      30.848,
      -45.628,
      -20.435,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1352",
    "polygon": [
      -62.029,
      -21.387,
      23.288,
      -62.439,
      -20.515,
      23.288,
      -62.439,
      -20.515,
      26.818,
      -62.439,
      -20.515,
      26.818,
      -62.029,
      -21.387,
      26.818,
      -62.029,
      -21.387,
      23.288
    ]
  },
  {
    "ID": "DINHQ_10.OG_1826",
    "polygon": [
      -56.28,
      -33.606,
      40.445,
      -57.128,
      -31.805,
      40.445,
      -57.128,
      -31.805,
      44.113,
      -57.128,
      -31.805,
      44.113,
      -56.28,
      -33.606,
      44.113,
      -56.28,
      -33.606,
      40.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1528",
    "polygon": [
      -84.157,
      -53.276,
      23.36,
      -84.702,
      -52.118,
      23.36,
      -84.702,
      -52.118,
      26.82,
      -84.702,
      -52.118,
      26.82,
      -84.157,
      -53.276,
      26.82,
      -84.157,
      -53.276,
      23.36
    ],
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1
  },
  {
    "ID": "DINHQ_7.OG_1618",
    "polygon": [
      -42.183,
      -27.756,
      28.03,
      -41.335,
      -29.557,
      28.03,
      -41.335,
      -29.557,
      30.848,
      -41.335,
      -29.557,
      30.848,
      -42.183,
      -27.756,
      30.848,
      -42.183,
      -27.756,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_5.OG_985",
    "polygon": [
      -74.342,
      -50.844,
      19.873,
      -75.238,
      -51.265,
      19.873,
      -75.238,
      -51.265,
      22.098,
      -75.238,
      -51.265,
      22.098,
      -74.342,
      -50.844,
      22.098,
      -74.342,
      -50.844,
      19.873
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1548",
    "polygon": [
      -37.891,
      -37.274,
      28.03,
      -40.18,
      -38.351,
      28.03,
      -40.18,
      -38.351,
      30.848,
      -40.18,
      -38.351,
      30.848,
      -37.891,
      -37.274,
      30.848,
      -37.891,
      -37.274,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1847",
    "polygon": [
      -43.442,
      -30.938,
      39.515,
      -44.172,
      -31.281,
      39.515,
      -44.172,
      -31.281,
      41.915,
      -44.172,
      -31.281,
      41.915,
      -43.442,
      -30.938,
      41.915,
      -43.442,
      -30.938,
      39.515
    ],
    "11-07 07:45": 1,
    "11-07 08:15": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1819",
    "polygon": [
      -63.178,
      -18.944,
      40.445,
      -63.589,
      -18.072,
      40.445,
      -63.589,
      -18.072,
      44.113,
      -63.589,
      -18.072,
      44.113,
      -63.178,
      -18.944,
      44.113,
      -63.178,
      -18.944,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1279",
    "polygon": [
      -10.975,
      -1.588,
      23.288,
      -10.046,
      -1.151,
      23.288,
      -10.046,
      -1.151,
      26.818,
      -10.046,
      -1.151,
      26.818,
      -10.975,
      -1.588,
      26.818,
      -10.975,
      -1.588,
      23.288
    ]
  },
  {
    "ID": "DINHQ_8.OG_1692",
    "polygon": [
      -42.183,
      -27.756,
      31.845,
      -41.335,
      -29.557,
      31.845,
      -41.335,
      -29.557,
      34.445,
      -41.335,
      -29.557,
      34.445,
      -42.183,
      -27.756,
      34.445,
      -42.183,
      -27.756,
      31.845
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1037",
    "polygon": [
      -2.567,
      -17.069,
      19.87,
      -3.499,
      -17.508,
      19.87,
      -3.499,
      -17.508,
      22.1,
      -3.499,
      -17.508,
      22.1,
      -2.567,
      -17.069,
      22.1,
      -2.567,
      -17.069,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1529",
    "polygon": [
      -85.005,
      -51.475,
      23.36,
      -85.55,
      -50.317,
      23.36,
      -85.55,
      -50.317,
      26.82,
      -85.55,
      -50.317,
      26.82,
      -85.005,
      -51.475,
      26.82,
      -85.005,
      -51.475,
      23.36
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1
  },
  {
    "ID": "DINHQ_4.OG_921",
    "polygon": [
      -57.836,
      5.515,
      16.5,
      -57.426,
      4.643,
      16.5,
      -57.426,
      4.643,
      18.277,
      -57.426,
      4.643,
      18.277,
      -57.836,
      5.515,
      18.277,
      -57.836,
      5.515,
      16.5
    ]
  },
  {
    "ID": "DINHQ_10.OG_1814",
    "polygon": [
      -65.913,
      -13.132,
      40.445,
      -66.323,
      -12.26,
      40.445,
      -66.323,
      -12.26,
      44.113,
      -66.323,
      -12.26,
      44.113,
      -65.913,
      -13.132,
      44.113,
      -65.913,
      -13.132,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1727",
    "polygon": [
      -70.299,
      4.163,
      35.445,
      -69.073,
      4.74,
      35.445,
      -69.073,
      4.74,
      39.445,
      -69.073,
      4.74,
      39.445,
      -70.299,
      4.163,
      39.445,
      -70.299,
      4.163,
      35.445
    ]
  },
  {
    "ID": "DINHQ_5.OG_1038",
    "polygon": [
      -0.993,
      -16.328,
      19.87,
      -1.925,
      -16.767,
      19.87,
      -1.925,
      -16.767,
      22.1,
      -1.925,
      -16.767,
      22.1,
      -0.993,
      -16.328,
      22.1,
      -0.993,
      -16.328,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1262",
    "polygon": [
      11.988,
      9.218,
      23.288,
      12.918,
      9.655,
      23.288,
      12.918,
      9.655,
      26.818,
      12.918,
      9.655,
      26.818,
      11.988,
      9.218,
      26.818,
      11.988,
      9.218,
      23.288
    ]
  },
  {
    "ID": "DINHQ_10.OG_1815",
    "polygon": [
      -65.477,
      -14.058,
      40.445,
      -65.888,
      -13.186,
      40.445,
      -65.888,
      -13.186,
      44.113,
      -65.888,
      -13.186,
      44.113,
      -65.477,
      -14.058,
      44.113,
      -65.477,
      -14.058,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1491",
    "polygon": [
      -21.748,
      -24.438,
      23.288,
      -22.68,
      -24.876,
      23.288,
      -22.68,
      -24.876,
      26.818,
      -22.68,
      -24.876,
      26.818,
      -21.748,
      -24.438,
      26.818,
      -21.748,
      -24.438,
      23.288
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1480",
    "polygon": [
      -36.069,
      -31.176,
      23.289,
      -37.001,
      -31.615,
      23.289,
      -37.001,
      -31.615,
      26.819,
      -37.001,
      -31.615,
      26.819,
      -36.069,
      -31.176,
      26.819,
      -36.069,
      -31.176,
      23.289
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1101",
    "polygon": [
      -41.195,
      -15.809,
      19.87,
      -40.266,
      -15.371,
      19.87,
      -40.266,
      -15.371,
      22.1,
      -40.266,
      -15.371,
      22.1,
      -41.195,
      -15.809,
      22.1,
      -41.195,
      -15.809,
      19.87
    ]
  },
  {
    "ID": "DINHQ_10.OG_1780",
    "polygon": [
      -49.788,
      -11.588,
      40.445,
      -49.378,
      -12.46,
      40.445,
      -49.378,
      -12.46,
      44.113,
      -49.378,
      -12.46,
      44.113,
      -49.788,
      -11.588,
      44.113,
      -49.788,
      -11.588,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1007",
    "polygon": [
      -40.822,
      -38.653,
      19.87,
      -42.939,
      -39.65,
      19.87,
      -42.939,
      -39.65,
      22.1,
      -42.939,
      -39.65,
      22.1,
      -40.822,
      -38.653,
      22.1,
      -40.822,
      -38.653,
      19.87
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1365",
    "polygon": [
      -73.983,
      -31.237,
      23.288,
      -73.087,
      -30.816,
      23.288,
      -73.087,
      -30.816,
      26.818,
      -73.087,
      -30.816,
      26.818,
      -73.983,
      -31.237,
      26.818,
      -73.983,
      -31.237,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1533",
    "polygon": [
      -90.073,
      -40.704,
      23.36,
      -90.597,
      -39.591,
      23.36,
      -90.597,
      -39.591,
      26.82,
      -90.597,
      -39.591,
      26.82,
      -90.073,
      -40.704,
      26.82,
      -90.073,
      -40.704,
      23.36
    ],
    "11-07 12:00": 1,
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_9.OG_1740",
    "polygon": [
      -66.627,
      -11.615,
      35.445,
      -67.037,
      -10.743,
      35.445,
      -67.037,
      -10.743,
      39.445,
      -67.037,
      -10.743,
      39.445,
      -66.627,
      -11.615,
      39.445,
      -66.627,
      -11.615,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1525",
    "polygon": [
      23.352,
      -3.215,
      23.289,
      22.42,
      -3.653,
      23.289,
      22.42,
      -3.653,
      26.819,
      22.42,
      -3.653,
      26.819,
      23.352,
      -3.215,
      26.819,
      23.352,
      -3.215,
      23.289
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_7.OG_1581",
    "polygon": [
      -70.075,
      -4.286,
      28.03,
      -70.486,
      -3.414,
      28.03,
      -70.486,
      -3.414,
      30.848,
      -70.486,
      -3.414,
      30.848,
      -70.075,
      -4.286,
      30.848,
      -70.075,
      -4.286,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1565",
    "polygon": [
      -55.973,
      1.554,
      28.03,
      -55.562,
      0.682,
      28.03,
      -55.562,
      0.682,
      30.848,
      -55.562,
      0.682,
      30.848,
      -55.973,
      1.554,
      30.848,
      -55.973,
      1.554,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1140",
    "polygon": [
      -68.212,
      -8.247,
      19.87,
      -68.622,
      -7.374,
      19.87,
      -68.622,
      -7.374,
      21.647,
      -68.622,
      -7.374,
      21.647,
      -68.212,
      -8.247,
      21.647,
      -68.212,
      -8.247,
      19.87
    ],
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1074",
    "polygon": [
      -5.676,
      0.905,
      19.87,
      -4.747,
      1.342,
      19.87,
      -4.747,
      1.342,
      22.1,
      -4.747,
      1.342,
      22.1,
      -5.676,
      0.905,
      22.1,
      -5.676,
      0.905,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1164",
    "polygon": [
      -73.983,
      -31.237,
      19.87,
      -73.087,
      -30.816,
      19.87,
      -73.087,
      -30.816,
      22.095,
      -73.087,
      -30.816,
      22.095,
      -73.983,
      -31.237,
      22.095,
      -73.983,
      -31.237,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1013",
    "polygon": [
      -34.362,
      -32.031,
      19.87,
      -35.294,
      -32.469,
      19.87,
      -35.294,
      -32.469,
      22.1,
      -35.294,
      -32.469,
      22.1,
      -34.362,
      -32.031,
      22.1,
      -34.362,
      -32.031,
      19.87
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1139",
    "polygon": [
      -68.927,
      -6.728,
      19.87,
      -69.337,
      -5.855,
      19.87,
      -69.337,
      -5.855,
      21.647,
      -69.337,
      -5.855,
      21.647,
      -68.927,
      -6.728,
      21.647,
      -68.927,
      -6.728,
      19.87
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_4.OG_904",
    "polygon": [
      -47.925,
      -15.548,
      16.5,
      -47.514,
      -16.421,
      16.5,
      -47.514,
      -16.421,
      18.277,
      -47.514,
      -16.421,
      18.277,
      -47.925,
      -15.548,
      18.277,
      -47.925,
      -15.548,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1538",
    "polygon": [
      -85.954,
      -49.456,
      23.33,
      -86.263,
      -48.8,
      23.33,
      -86.263,
      -48.8,
      25.19,
      -86.263,
      -48.8,
      25.19,
      -85.954,
      -49.456,
      25.19,
      -85.954,
      -49.456,
      23.33
    ],
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1655",
    "polygon": [
      -72.959,
      2.912,
      31.845,
      -70.94,
      3.863,
      31.845,
      -70.94,
      3.863,
      34.445,
      -70.94,
      3.863,
      34.445,
      -72.959,
      2.912,
      34.445,
      -72.959,
      2.912,
      31.845
    ]
  },
  {
    "ID": "DINHQ_6.OG_1372",
    "polygon": [
      -82.727,
      -35.352,
      23.288,
      -81.832,
      -34.931,
      23.288,
      -81.832,
      -34.931,
      26.818,
      -81.832,
      -34.931,
      26.818,
      -82.727,
      -35.352,
      26.818,
      -82.727,
      -35.352,
      23.288
    ]
  },
  {
    "ID": "DINHQ_9.OG_1743",
    "polygon": [
      -64.763,
      -15.575,
      35.445,
      -65.174,
      -14.703,
      35.445,
      -65.174,
      -14.703,
      39.445,
      -65.174,
      -14.703,
      39.445,
      -64.763,
      -15.575,
      39.445,
      -64.763,
      -15.575,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1789",
    "polygon": [
      -54.823,
      -0.889,
      40.445,
      -54.412,
      -1.761,
      40.445,
      -54.412,
      -1.761,
      44.113,
      -54.412,
      -1.761,
      44.113,
      -54.823,
      -0.889,
      44.113,
      -54.823,
      -0.889,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1625",
    "polygon": [
      -49.1,
      -42.549,
      31.845,
      -51.389,
      -43.626,
      31.845,
      -51.389,
      -43.626,
      34.445,
      -51.389,
      -43.626,
      34.445,
      -49.1,
      -42.549,
      34.445,
      -49.1,
      -42.549,
      31.845
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1638",
    "polygon": [
      -52.088,
      -6.701,
      31.845,
      -51.677,
      -7.573,
      31.845,
      -51.677,
      -7.573,
      34.445,
      -51.677,
      -7.573,
      34.445,
      -52.088,
      -6.701,
      34.445,
      -52.088,
      -6.701,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1722",
    "polygon": [
      -58.272,
      6.441,
      35.445,
      -57.862,
      5.569,
      35.445,
      -57.862,
      5.569,
      39.445,
      -57.862,
      5.569,
      39.445,
      -58.272,
      6.441,
      39.445,
      -58.272,
      6.441,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_996",
    "polygon": [
      -60.462,
      -44.312,
      19.873,
      -61.358,
      -44.734,
      19.873,
      -61.358,
      -44.734,
      22.098,
      -61.358,
      -44.734,
      22.098,
      -60.462,
      -44.312,
      22.098,
      -60.462,
      -44.312,
      19.873
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1489",
    "polygon": [
      -24.396,
      -25.684,
      23.288,
      -25.328,
      -26.122,
      23.288,
      -25.328,
      -26.122,
      26.818,
      -25.328,
      -26.122,
      26.818,
      -24.396,
      -25.684,
      26.818,
      -24.396,
      -25.684,
      23.288
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1517",
    "polygon": [
      12.892,
      -8.137,
      23.288,
      11.96,
      -8.576,
      23.288,
      11.96,
      -8.576,
      26.818,
      11.96,
      -8.576,
      26.818,
      12.892,
      -8.137,
      26.818,
      12.892,
      -8.137,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_995",
    "polygon": [
      -61.494,
      -44.798,
      19.873,
      -62.39,
      -45.219,
      19.873,
      -62.39,
      -45.219,
      22.098,
      -62.39,
      -45.219,
      22.098,
      -61.494,
      -44.798,
      22.098,
      -61.494,
      -44.798,
      19.873
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1274",
    "polygon": [
      -4.105,
      1.645,
      23.288,
      -3.176,
      2.082,
      23.288,
      -3.176,
      2.082,
      26.818,
      -3.176,
      2.082,
      26.818,
      -4.105,
      1.645,
      26.818,
      -4.105,
      1.645,
      23.288
    ]
  },
  {
    "ID": "DINHQ_9.OG_1756",
    "polygon": [
      -59.729,
      -26.277,
      35.445,
      -60.139,
      -25.405,
      35.445,
      -60.139,
      -25.405,
      39.445,
      -60.139,
      -25.405,
      39.445,
      -59.729,
      -26.277,
      39.445,
      -59.729,
      -26.277,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1303",
    "polygon": [
      -42.777,
      -16.553,
      23.288,
      -41.848,
      -16.116,
      23.288,
      -41.848,
      -16.116,
      26.818,
      -41.848,
      -16.116,
      26.818,
      -42.777,
      -16.553,
      26.818,
      -42.777,
      -16.553,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1455",
    "polygon": [
      -82.689,
      -53.114,
      23.288,
      -83.585,
      -53.536,
      23.288,
      -83.585,
      -53.536,
      26.818,
      -83.585,
      -53.536,
      26.818,
      -82.689,
      -53.114,
      26.818,
      -82.689,
      -53.114,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1564",
    "polygon": [
      -55.537,
      0.628,
      28.03,
      -55.126,
      -0.244,
      28.03,
      -55.126,
      -0.244,
      30.848,
      -55.126,
      -0.244,
      30.848,
      -55.537,
      0.628,
      30.848,
      -55.537,
      0.628,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1302",
    "polygon": [
      -41.195,
      -15.809,
      23.288,
      -40.266,
      -15.371,
      23.288,
      -40.266,
      -15.371,
      26.818,
      -40.266,
      -15.371,
      26.818,
      -41.195,
      -15.809,
      26.818,
      -41.195,
      -15.809,
      23.288
    ]
  },
  {
    "ID": "DINHQ_9.OG_1732",
    "polygon": [
      -71.225,
      -1.843,
      35.445,
      -71.635,
      -0.971,
      35.445,
      -71.635,
      -0.971,
      39.445,
      -71.635,
      -0.971,
      39.445,
      -71.225,
      -1.843,
      39.445,
      -71.225,
      -1.843,
      35.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1315",
    "polygon": [
      -52.088,
      -6.701,
      23.288,
      -51.677,
      -7.573,
      23.288,
      -51.677,
      -7.573,
      26.818,
      -51.677,
      -7.573,
      26.818,
      -52.088,
      -6.701,
      26.818,
      -52.088,
      -6.701,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1512",
    "polygon": [
      6.326,
      -11.227,
      23.289,
      5.394,
      -11.666,
      23.289,
      5.394,
      -11.666,
      26.819,
      5.394,
      -11.666,
      26.819,
      6.326,
      -11.227,
      26.819,
      6.326,
      -11.227,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1546",
    "polygon": [
      -43.581,
      -39.952,
      28.03,
      -45.698,
      -40.948,
      28.03,
      -45.698,
      -40.948,
      30.848,
      -45.698,
      -40.948,
      30.848,
      -43.581,
      -39.952,
      30.848,
      -43.581,
      -39.952,
      28.03
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_7.OG_1578",
    "polygon": [
      -71.661,
      -0.918,
      28.03,
      -72.071,
      -0.045,
      28.03,
      -72.071,
      -0.045,
      30.848,
      -72.071,
      -0.045,
      30.848,
      -71.661,
      -0.918,
      30.848,
      -71.661,
      -0.918,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1827",
    "polygon": [
      -57.429,
      -31.164,
      40.445,
      -58.277,
      -29.363,
      40.445,
      -58.277,
      -29.363,
      44.113,
      -58.277,
      -29.363,
      44.113,
      -57.429,
      -31.164,
      44.113,
      -57.429,
      -31.164,
      40.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1781",
    "polygon": [
      -50.224,
      -10.662,
      40.445,
      -49.814,
      -11.534,
      40.445,
      -49.814,
      -11.534,
      44.113,
      -49.814,
      -11.534,
      44.113,
      -50.224,
      -10.662,
      44.113,
      -50.224,
      -10.662,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1076",
    "polygon": [
      -8.326,
      -0.341,
      19.87,
      -7.397,
      0.096,
      19.87,
      -7.397,
      0.096,
      22.1,
      -7.397,
      0.096,
      22.1,
      -8.326,
      -0.341,
      22.1,
      -8.326,
      -0.341,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1622",
    "polygon": [
      -44.601,
      -22.618,
      29.73,
      -43.516,
      -24.923,
      29.73,
      -43.516,
      -24.923,
      30.817,
      -43.516,
      -24.923,
      30.817,
      -44.601,
      -22.618,
      30.817,
      -44.601,
      -22.618,
      29.73
    ],
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1723",
    "polygon": [
      -58.986,
      7.958,
      35.445,
      -58.576,
      7.086,
      35.445,
      -58.576,
      7.086,
      39.445,
      -58.576,
      7.086,
      39.445,
      -58.986,
      7.958,
      39.445,
      -58.986,
      7.958,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1704",
    "polygon": [
      -47.925,
      -15.548,
      35.445,
      -47.514,
      -16.421,
      35.445,
      -47.514,
      -16.421,
      39.445,
      -47.514,
      -16.421,
      39.445,
      -47.925,
      -15.548,
      39.445,
      -47.925,
      -15.548,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1642",
    "polygon": [
      -54.387,
      -1.815,
      31.845,
      -53.977,
      -2.687,
      31.845,
      -53.977,
      -2.687,
      34.445,
      -53.977,
      -2.687,
      34.445,
      -54.387,
      -1.815,
      34.445,
      -54.387,
      -1.815,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1640",
    "polygon": [
      -53.238,
      -4.258,
      31.845,
      -52.827,
      -5.13,
      31.845,
      -52.827,
      -5.13,
      34.445,
      -52.827,
      -5.13,
      34.445,
      -53.238,
      -4.258,
      34.445,
      -53.238,
      -4.258,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1466",
    "polygon": [
      -68.809,
      -46.583,
      23.288,
      -69.705,
      -47.004,
      23.288,
      -69.705,
      -47.004,
      26.818,
      -69.705,
      -47.004,
      26.818,
      -68.809,
      -46.583,
      26.818,
      -68.809,
      -46.583,
      23.288
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_893",
    "polygon": [
      -34.819,
      -12.808,
      16.5,
      -33.89,
      -12.371,
      16.5,
      -33.89,
      -12.371,
      18.73,
      -33.89,
      -12.371,
      18.73,
      -34.819,
      -12.808,
      18.73,
      -34.819,
      -12.808,
      16.5
    ]
  },
  {
    "ID": "DINHQ_8.OG_1656",
    "polygon": [
      -72.373,
      0.597,
      31.845,
      -72.784,
      1.469,
      31.845,
      -72.784,
      1.469,
      34.445,
      -72.784,
      1.469,
      34.445,
      -72.373,
      0.597,
      34.445,
      -72.373,
      0.597,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1166",
    "polygon": [
      -76.552,
      -32.446,
      19.87,
      -75.656,
      -32.025,
      19.87,
      -75.656,
      -32.025,
      22.095,
      -75.656,
      -32.025,
      22.095,
      -76.552,
      -32.446,
      22.095,
      -76.552,
      -32.446,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1553",
    "polygon": [
      -49.074,
      -13.105,
      28.03,
      -48.664,
      -13.977,
      28.03,
      -48.664,
      -13.977,
      30.848,
      -48.664,
      -13.977,
      30.848,
      -49.074,
      -13.105,
      30.848,
      -49.074,
      -13.105,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_10.OG_1782",
    "polygon": [
      -50.938,
      -9.144,
      40.445,
      -50.528,
      -10.017,
      40.445,
      -50.528,
      -10.017,
      44.113,
      -50.528,
      -10.017,
      44.113,
      -50.938,
      -9.144,
      44.113,
      -50.938,
      -9.144,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1662",
    "polygon": [
      -69.362,
      -5.802,
      31.845,
      -69.773,
      -4.93,
      31.845,
      -69.773,
      -4.93,
      34.445,
      -69.773,
      -4.93,
      34.445,
      -69.362,
      -5.802,
      34.445,
      -69.362,
      -5.802,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1584",
    "polygon": [
      -68.212,
      -8.247,
      28.03,
      -68.622,
      -7.374,
      28.03,
      -68.622,
      -7.374,
      30.848,
      -68.622,
      -7.374,
      30.848,
      -68.212,
      -8.247,
      30.848,
      -68.212,
      -8.247,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_982",
    "polygon": [
      -78.45,
      -52.777,
      19.873,
      -79.346,
      -53.198,
      19.873,
      -79.346,
      -53.198,
      22.098,
      -79.346,
      -53.198,
      22.098,
      -78.45,
      -52.777,
      22.098,
      -78.45,
      -52.777,
      19.873
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1326",
    "polygon": [
      -58.272,
      6.441,
      23.288,
      -57.862,
      5.569,
      23.288,
      -57.862,
      5.569,
      26.818,
      -57.862,
      5.569,
      26.818,
      -58.272,
      6.441,
      26.818,
      -58.272,
      6.441,
      23.288
    ],
    "11-07 08:00": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_981",
    "polygon": [
      -79.482,
      -53.262,
      19.873,
      -80.378,
      -53.684,
      19.873,
      -80.378,
      -53.684,
      22.098,
      -80.378,
      -53.684,
      22.098,
      -79.482,
      -53.262,
      22.098,
      -79.482,
      -53.262,
      19.873
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1585",
    "polygon": [
      -67.776,
      -9.172,
      28.03,
      -68.187,
      -8.3,
      28.03,
      -68.187,
      -8.3,
      30.848,
      -68.187,
      -8.3,
      30.848,
      -67.776,
      -9.172,
      30.848,
      -67.776,
      -9.172,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1673",
    "polygon": [
      -63.178,
      -18.944,
      31.845,
      -63.589,
      -18.072,
      31.845,
      -63.589,
      -18.072,
      34.445,
      -63.589,
      -18.072,
      34.445,
      -63.178,
      -18.944,
      34.445,
      -63.178,
      -18.944,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1087",
    "polygon": [
      -22.65,
      -7.082,
      19.87,
      -21.721,
      -6.645,
      19.87,
      -21.721,
      -6.645,
      22.1,
      -21.721,
      -6.645,
      22.1,
      -22.65,
      -7.082,
      22.1,
      -22.65,
      -7.082,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1592",
    "polygon": [
      -63.614,
      -18.018,
      28.03,
      -64.024,
      -17.146,
      28.03,
      -64.024,
      -17.146,
      30.848,
      -64.024,
      -17.146,
      30.848,
      -63.614,
      -18.018,
      30.848,
      -63.614,
      -18.018,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1000",
    "polygon": [
      -55.323,
      -41.894,
      19.873,
      -56.219,
      -42.316,
      19.873,
      -56.219,
      -42.316,
      22.098,
      -56.219,
      -42.316,
      22.098,
      -55.323,
      -41.894,
      22.098,
      -55.323,
      -41.894,
      19.873
    ],
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_922",
    "polygon": [
      -58.272,
      6.441,
      16.5,
      -57.862,
      5.569,
      16.5,
      -57.862,
      5.569,
      18.277,
      -57.862,
      5.569,
      18.277,
      -58.272,
      6.441,
      18.277,
      -58.272,
      6.441,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1534",
    "polygon": [
      -88.542,
      -43.958,
      23.36,
      -89.184,
      -42.593,
      23.36,
      -89.184,
      -42.593,
      26.82,
      -89.184,
      -42.593,
      26.82,
      -88.542,
      -43.958,
      26.82,
      -88.542,
      -43.958,
      23.36
    ],
    "11-07 12:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1022",
    "polygon": [
      -22.19,
      -26.303,
      19.87,
      -23.122,
      -26.741,
      19.87,
      -23.122,
      -26.741,
      22.1,
      -23.122,
      -26.741,
      22.1,
      -22.19,
      -26.303,
      22.1,
      -22.19,
      -26.303,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_10.OG_1804",
    "polygon": [
      -71.661,
      -0.918,
      40.445,
      -72.071,
      -0.045,
      40.445,
      -72.071,
      -0.045,
      44.113,
      -72.071,
      -0.045,
      44.113,
      -71.661,
      -0.918,
      44.113,
      -71.661,
      -0.918,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1513",
    "polygon": [
      7.399,
      -10.722,
      23.288,
      6.467,
      -11.161,
      23.288,
      6.467,
      -11.161,
      26.818,
      6.467,
      -11.161,
      26.818,
      7.399,
      -10.722,
      26.818,
      7.399,
      -10.722,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1275",
    "polygon": [
      -5.676,
      0.905,
      23.288,
      -4.747,
      1.342,
      23.288,
      -4.747,
      1.342,
      26.818,
      -4.747,
      1.342,
      26.818,
      -5.676,
      0.905,
      26.818,
      -5.676,
      0.905,
      23.288
    ]
  },
  {
    "ID": "DINHQ_10.OG_1820",
    "polygon": [
      -62.464,
      -20.461,
      40.445,
      -62.875,
      -19.589,
      40.445,
      -62.875,
      -19.589,
      44.113,
      -62.875,
      -19.589,
      44.113,
      -62.464,
      -20.461,
      44.113,
      -62.464,
      -20.461,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_987",
    "polygon": [
      -71.773,
      -49.635,
      19.873,
      -72.668,
      -50.056,
      19.873,
      -72.668,
      -50.056,
      22.098,
      -72.668,
      -50.056,
      22.098,
      -71.773,
      -49.635,
      22.098,
      -71.773,
      -49.635,
      19.873
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1631",
    "polygon": [
      -47.925,
      -15.548,
      31.845,
      -47.514,
      -16.421,
      31.845,
      -47.514,
      -16.421,
      34.445,
      -47.514,
      -16.421,
      34.445,
      -47.925,
      -15.548,
      34.445,
      -47.925,
      -15.548,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1503",
    "polygon": [
      -5.851,
      -16.957,
      23.288,
      -6.783,
      -17.395,
      23.288,
      -6.783,
      -17.395,
      26.818,
      -6.783,
      -17.395,
      26.818,
      -5.851,
      -16.957,
      26.818,
      -5.851,
      -16.957,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1377",
    "polygon": [
      -89.401,
      -38.492,
      23.288,
      -88.505,
      -38.071,
      23.288,
      -88.505,
      -38.071,
      26.818,
      -88.505,
      -38.071,
      26.818,
      -89.401,
      -38.492,
      26.818,
      -89.401,
      -38.492,
      23.288
    ]
  },
  {
    "ID": "DINHQ_9.OG_1752",
    "polygon": [
      -53.981,
      -38.491,
      35.445,
      -54.815,
      -36.721,
      35.445,
      -54.815,
      -36.721,
      39.445,
      -54.815,
      -36.721,
      39.445,
      -53.981,
      -38.491,
      39.445,
      -53.981,
      -38.491,
      35.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1845",
    "polygon": [
      -51.268,
      -34.62,
      39.515,
      -51.997,
      -34.964,
      39.515,
      -51.997,
      -34.964,
      41.915,
      -51.997,
      -34.964,
      41.915,
      -51.268,
      -34.62,
      41.915,
      -51.268,
      -34.62,
      39.515
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 12:15": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1142",
    "polygon": [
      -67.062,
      -10.689,
      19.87,
      -67.473,
      -9.817,
      19.87,
      -67.473,
      -9.817,
      21.647,
      -67.473,
      -9.817,
      21.647,
      -67.062,
      -10.689,
      21.647,
      -67.062,
      -10.689,
      19.87
    ],
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1497",
    "polygon": [
      -13.799,
      -20.697,
      23.288,
      -14.731,
      -21.136,
      23.288,
      -14.731,
      -21.136,
      26.818,
      -14.731,
      -21.136,
      26.818,
      -13.799,
      -20.697,
      26.818,
      -13.799,
      -20.697,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1141",
    "polygon": [
      -67.776,
      -9.172,
      19.87,
      -68.187,
      -8.3,
      19.87,
      -68.187,
      -8.3,
      21.647,
      -68.187,
      -8.3,
      21.647,
      -67.776,
      -9.172,
      21.647,
      -67.776,
      -9.172,
      19.87
    ],
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1747",
    "polygon": [
      -62.464,
      -20.461,
      35.445,
      -62.875,
      -19.589,
      35.445,
      -62.875,
      -19.589,
      39.445,
      -62.875,
      -19.589,
      39.445,
      -62.464,
      -20.461,
      39.445,
      -62.464,
      -20.461,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1353",
    "polygon": [
      -61.315,
      -22.904,
      23.288,
      -61.725,
      -22.032,
      23.288,
      -61.725,
      -22.032,
      26.818,
      -61.725,
      -22.032,
      26.818,
      -61.315,
      -22.904,
      26.818,
      -61.315,
      -22.904,
      23.288
    ]
  },
  {
    "ID": "DINHQ_7.OG_1566",
    "polygon": [
      -56.687,
      3.072,
      28.03,
      -56.276,
      2.199,
      28.03,
      -56.276,
      2.199,
      30.848,
      -56.276,
      2.199,
      30.848,
      -56.687,
      3.072,
      30.848,
      -56.687,
      3.072,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1563",
    "polygon": [
      -54.823,
      -0.889,
      28.03,
      -54.412,
      -1.761,
      28.03,
      -54.412,
      -1.761,
      30.848,
      -54.412,
      -1.761,
      30.848,
      -54.823,
      -0.889,
      30.848,
      -54.823,
      -0.889,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1125",
    "polygon": [
      -58.272,
      6.441,
      19.87,
      -57.862,
      5.569,
      19.87,
      -57.862,
      5.569,
      21.647,
      -57.862,
      5.569,
      21.647,
      -58.272,
      6.441,
      21.647,
      -58.272,
      6.441,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1590",
    "polygon": [
      -64.763,
      -15.575,
      28.03,
      -65.174,
      -14.703,
      28.03,
      -65.174,
      -14.703,
      30.848,
      -65.174,
      -14.703,
      30.848,
      -64.763,
      -15.575,
      30.848,
      -64.763,
      -15.575,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1481",
    "polygon": [
      -34.996,
      -30.672,
      23.288,
      -35.928,
      -31.11,
      23.288,
      -35.928,
      -31.11,
      26.818,
      -35.928,
      -31.11,
      26.818,
      -34.996,
      -30.672,
      26.818,
      -34.996,
      -30.672,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1473",
    "polygon": [
      -59.562,
      -42.231,
      23.288,
      -60.458,
      -42.653,
      23.288,
      -60.458,
      -42.653,
      26.818,
      -60.458,
      -42.653,
      26.818,
      -59.562,
      -42.231,
      26.818,
      -59.562,
      -42.231,
      23.288
    ],
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1683",
    "polygon": [
      -59.729,
      -26.277,
      31.845,
      -60.139,
      -25.405,
      31.845,
      -60.139,
      -25.405,
      34.445,
      -60.139,
      -25.405,
      34.445,
      -59.729,
      -26.277,
      34.445,
      -59.729,
      -26.277,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1065",
    "polygon": [
      6.713,
      6.735,
      19.87,
      7.642,
      7.173,
      19.87,
      7.642,
      7.173,
      22.1,
      7.642,
      7.173,
      22.1,
      6.713,
      6.735,
      22.1,
      6.713,
      6.735,
      19.87
    ]
  },
  {
    "ID": "DINHQ_10.OG_1799",
    "polygon": [
      -63.886,
      7.181,
      40.445,
      -62.661,
      7.757,
      40.445,
      -62.661,
      7.757,
      44.113,
      -62.661,
      7.757,
      44.113,
      -63.886,
      7.181,
      44.113,
      -63.886,
      7.181,
      40.445
    ]
  },
  {
    "ID": "DINHQ_10.OG_1817",
    "polygon": [
      -64.328,
      -16.501,
      40.445,
      -64.738,
      -15.629,
      40.445,
      -64.738,
      -15.629,
      44.113,
      -64.738,
      -15.629,
      44.113,
      -64.328,
      -16.501,
      44.113,
      -64.328,
      -16.501,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1520",
    "polygon": [
      17.049,
      -6.181,
      23.289,
      16.117,
      -6.62,
      23.289,
      16.117,
      -6.62,
      26.819,
      16.117,
      -6.62,
      26.819,
      17.049,
      -6.181,
      26.819,
      17.049,
      -6.181,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_986",
    "polygon": [
      -73.311,
      -50.358,
      19.873,
      -74.207,
      -50.78,
      19.873,
      -74.207,
      -50.78,
      22.098,
      -74.207,
      -50.78,
      22.098,
      -73.311,
      -50.358,
      22.098,
      -73.311,
      -50.358,
      19.873
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1354",
    "polygon": [
      -60.879,
      -23.83,
      23.288,
      -61.289,
      -22.958,
      23.288,
      -61.289,
      -22.958,
      26.818,
      -61.289,
      -22.958,
      26.818,
      -60.879,
      -23.83,
      26.818,
      -60.879,
      -23.83,
      23.288
    ]
  },
  {
    "ID": "DINHQ_4.OG_934",
    "polygon": [
      -70.075,
      -4.286,
      16.5,
      -70.486,
      -3.414,
      16.5,
      -70.486,
      -3.414,
      18.277,
      -70.486,
      -3.414,
      18.277,
      -70.075,
      -4.286,
      18.277,
      -70.075,
      -4.286,
      16.5
    ],
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1280",
    "polygon": [
      -12.053,
      -2.095,
      23.288,
      -11.124,
      -1.658,
      23.288,
      -11.124,
      -1.658,
      26.818,
      -11.124,
      -1.658,
      26.818,
      -12.053,
      -2.095,
      26.818,
      -12.053,
      -2.095,
      23.288
    ]
  },
  {
    "ID": "DINHQ_10.OG_1822",
    "polygon": [
      -61.315,
      -22.904,
      40.445,
      -61.725,
      -22.032,
      40.445,
      -61.725,
      -22.032,
      44.113,
      -61.725,
      -22.032,
      44.113,
      -61.315,
      -22.904,
      44.113,
      -61.315,
      -22.904,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1779",
    "polygon": [
      -49.074,
      -13.105,
      40.445,
      -48.664,
      -13.977,
      40.445,
      -48.664,
      -13.977,
      44.113,
      -48.664,
      -13.977,
      44.113,
      -49.074,
      -13.105,
      44.113,
      -49.074,
      -13.105,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_10.OG_1830",
    "polygon": [
      -59.017,
      -27.795,
      40.445,
      -59.427,
      -26.922,
      40.445,
      -59.427,
      -26.922,
      44.113,
      -59.427,
      -26.922,
      44.113,
      -59.017,
      -27.795,
      44.113,
      -59.017,
      -27.795,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1034",
    "polygon": [
      -6.291,
      -18.821,
      19.87,
      -7.223,
      -19.26,
      19.87,
      -7.223,
      -19.26,
      22.1,
      -7.223,
      -19.26,
      22.1,
      -6.291,
      -18.821,
      22.1,
      -6.291,
      -18.821,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1369",
    "polygon": [
      -79.123,
      -33.656,
      23.288,
      -78.227,
      -33.234,
      23.288,
      -78.227,
      -33.234,
      26.818,
      -78.227,
      -33.234,
      26.818,
      -79.123,
      -33.656,
      26.818,
      -79.123,
      -33.656,
      23.288
    ]
  },
  {
    "ID": "DINHQ_7.OG_1587",
    "polygon": [
      -66.627,
      -11.615,
      28.03,
      -67.037,
      -10.743,
      28.03,
      -67.037,
      -10.743,
      30.848,
      -67.037,
      -10.743,
      30.848,
      -66.627,
      -11.615,
      30.848,
      -66.627,
      -11.615,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1580",
    "polygon": [
      -70.511,
      -3.361,
      28.03,
      -70.922,
      -2.488,
      28.03,
      -70.922,
      -2.488,
      30.848,
      -70.922,
      -2.488,
      30.848,
      -70.511,
      -3.361,
      30.848,
      -70.511,
      -3.361,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1701",
    "polygon": [
      -37.891,
      -37.274,
      35.445,
      -40.18,
      -38.351,
      35.445,
      -40.18,
      -38.351,
      39.445,
      -40.18,
      -38.351,
      39.445,
      -37.891,
      -37.274,
      39.445,
      -37.891,
      -37.274,
      35.445
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1165",
    "polygon": [
      -75.018,
      -31.724,
      19.87,
      -74.122,
      -31.303,
      19.87,
      -74.122,
      -31.303,
      22.095,
      -74.122,
      -31.303,
      22.095,
      -75.018,
      -31.724,
      22.095,
      -75.018,
      -31.724,
      19.87
    ]
  },
  {
    "ID": "DINHQ_9.OG_1744",
    "polygon": [
      -64.328,
      -16.501,
      35.445,
      -64.738,
      -15.629,
      35.445,
      -64.738,
      -15.629,
      39.445,
      -64.738,
      -15.629,
      39.445,
      -64.328,
      -16.501,
      39.445,
      -64.328,
      -16.501,
      35.445
    ],
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1574",
    "polygon": [
      -70.299,
      4.163,
      28.03,
      -69.073,
      4.74,
      28.03,
      -69.073,
      4.74,
      30.848,
      -69.073,
      4.74,
      30.848,
      -70.299,
      4.163,
      30.848,
      -70.299,
      4.163,
      28.03
    ]
  },
  {
    "ID": "DINHQ_10.OG_1788",
    "polygon": [
      -54.387,
      -1.815,
      40.445,
      -53.977,
      -2.687,
      40.445,
      -53.977,
      -2.687,
      44.113,
      -53.977,
      -2.687,
      44.113,
      -54.387,
      -1.815,
      44.113,
      -54.387,
      -1.815,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1021",
    "polygon": [
      -23.764,
      -27.043,
      19.87,
      -24.696,
      -27.482,
      19.87,
      -24.696,
      -27.482,
      22.1,
      -24.696,
      -27.482,
      22.1,
      -23.764,
      -27.043,
      22.1,
      -23.764,
      -27.043,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1762",
    "polygon": [
      -45.628,
      -20.435,
      35.445,
      -45.218,
      -21.307,
      35.445,
      -45.218,
      -21.307,
      39.445,
      -45.218,
      -21.307,
      39.445,
      -45.628,
      -20.435,
      39.445,
      -45.628,
      -20.435,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_10.OG_1803",
    "polygon": [
      -72.808,
      1.52,
      40.445,
      -73.337,
      2.645,
      40.445,
      -73.337,
      2.645,
      44.113,
      -73.337,
      2.645,
      44.113,
      -72.808,
      1.52,
      44.113,
      -72.808,
      1.52,
      40.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1661",
    "polygon": [
      -70.075,
      -4.286,
      31.845,
      -70.486,
      -3.414,
      31.845,
      -70.486,
      -3.414,
      34.445,
      -70.486,
      -3.414,
      34.445,
      -70.075,
      -4.286,
      34.445,
      -70.075,
      -4.286,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_957",
    "polygon": [
      -68.843,
      -28.819,
      16.5,
      -67.947,
      -28.397,
      16.5,
      -67.947,
      -28.397,
      18.725,
      -67.947,
      -28.397,
      18.725,
      -68.843,
      -28.819,
      18.725,
      -68.843,
      -28.819,
      16.5
    ]
  },
  {
    "ID": "DINHQ_10.OG_1776",
    "polygon": [
      -47.489,
      -16.474,
      40.445,
      -47.079,
      -17.346,
      40.445,
      -47.079,
      -17.346,
      44.113,
      -47.079,
      -17.346,
      44.113,
      -47.489,
      -16.474,
      44.113,
      -47.489,
      -16.474,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1516",
    "polygon": [
      11.819,
      -8.642,
      23.289,
      10.887,
      -9.081,
      23.289,
      10.887,
      -9.081,
      26.819,
      10.887,
      -9.081,
      26.819,
      11.819,
      -8.642,
      26.819,
      11.819,
      -8.642,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1290",
    "polygon": [
      -25.299,
      -8.329,
      23.288,
      -24.37,
      -7.891,
      23.288,
      -24.37,
      -7.891,
      26.818,
      -24.37,
      -7.891,
      26.818,
      -25.299,
      -8.329,
      26.818,
      -25.299,
      -8.329,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1339",
    "polygon": [
      -69.362,
      -5.802,
      23.288,
      -69.773,
      -4.93,
      23.288,
      -69.773,
      -4.93,
      26.818,
      -69.773,
      -4.93,
      26.818,
      -69.362,
      -5.802,
      26.818,
      -69.362,
      -5.802,
      23.288
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1319",
    "polygon": [
      -54.387,
      -1.815,
      23.288,
      -53.977,
      -2.687,
      23.288,
      -53.977,
      -2.687,
      26.818,
      -53.977,
      -2.687,
      26.818,
      -54.387,
      -1.815,
      26.818,
      -54.387,
      -1.815,
      23.288
    ]
  },
  {
    "ID": "DINHQ_5.OG_1060",
    "polygon": [
      13.055,
      9.72,
      19.87,
      13.984,
      10.157,
      19.87,
      13.984,
      10.157,
      22.1,
      13.984,
      10.157,
      22.1,
      13.055,
      9.72,
      22.1,
      13.055,
      9.72,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1463",
    "polygon": [
      -72.41,
      -48.277,
      23.288,
      -73.306,
      -48.699,
      23.288,
      -73.306,
      -48.699,
      26.818,
      -73.306,
      -48.699,
      26.818,
      -72.41,
      -48.277,
      26.818,
      -72.41,
      -48.277,
      23.288
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1646",
    "polygon": [
      -56.687,
      3.072,
      31.845,
      -56.276,
      2.199,
      31.845,
      -56.276,
      2.199,
      34.445,
      -56.276,
      2.199,
      34.445,
      -56.687,
      3.072,
      34.445,
      -56.687,
      3.072,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1500",
    "polygon": [
      -9.572,
      -18.708,
      23.289,
      -10.504,
      -19.146,
      23.289,
      -10.504,
      -19.146,
      26.819,
      -10.504,
      -19.146,
      26.819,
      -9.572,
      -18.708,
      26.819,
      -9.572,
      -18.708,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1293",
    "polygon": [
      -29.52,
      -10.315,
      23.288,
      -28.591,
      -9.878,
      23.288,
      -28.591,
      -9.878,
      26.818,
      -28.591,
      -9.878,
      26.818,
      -29.52,
      -10.315,
      26.818,
      -29.52,
      -10.315,
      23.288
    ]
  },
  {
    "ID": "DINHQ_9.OG_1699",
    "polygon": [
      -43.581,
      -39.952,
      35.445,
      -45.698,
      -40.948,
      35.445,
      -45.698,
      -40.948,
      39.445,
      -45.698,
      -40.948,
      39.445,
      -43.581,
      -39.952,
      39.445,
      -43.581,
      -39.952,
      35.445
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1801",
    "polygon": [
      -72.959,
      2.912,
      40.445,
      -70.94,
      3.863,
      40.445,
      -70.94,
      3.863,
      44.113,
      -70.94,
      3.863,
      44.113,
      -72.959,
      2.912,
      44.113,
      -72.959,
      2.912,
      40.445
    ]
  },
  {
    "ID": "DINHQ_6.OG_1475",
    "polygon": [
      -56.992,
      -41.022,
      23.288,
      -57.888,
      -41.444,
      23.288,
      -57.888,
      -41.444,
      26.818,
      -57.888,
      -41.444,
      26.818,
      -56.992,
      -41.022,
      26.818,
      -56.992,
      -41.022,
      23.288
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1472",
    "polygon": [
      -61.1,
      -42.955,
      23.288,
      -61.996,
      -43.377,
      23.288,
      -61.996,
      -43.377,
      26.818,
      -61.996,
      -43.377,
      26.818,
      -61.1,
      -42.955,
      26.818,
      -61.1,
      -42.955,
      23.288
    ],
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_7.OG_1605",
    "polygon": [
      -60.164,
      -25.352,
      28.03,
      -60.575,
      -24.479,
      28.03,
      -60.575,
      -24.479,
      30.848,
      -60.575,
      -24.479,
      30.848,
      -60.164,
      -25.352,
      30.848,
      -60.164,
      -25.352,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1
  },
  {
    "ID": "DINHQ_4.OG_892",
    "polygon": [
      -33.247,
      -12.069,
      16.5,
      -32.318,
      -11.631,
      16.5,
      -32.318,
      -11.631,
      18.73,
      -32.318,
      -11.631,
      18.73,
      -33.247,
      -12.069,
      18.73,
      -33.247,
      -12.069,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1291",
    "polygon": [
      -26.871,
      -9.068,
      23.288,
      -25.942,
      -8.631,
      23.288,
      -25.942,
      -8.631,
      26.818,
      -25.942,
      -8.631,
      26.818,
      -26.871,
      -9.068,
      26.818,
      -26.871,
      -9.068,
      23.288
    ]
  },
  {
    "ID": "DINHQ_8.OG_1626",
    "polygon": [
      -43.581,
      -39.952,
      31.845,
      -45.698,
      -40.948,
      31.845,
      -45.698,
      -40.948,
      34.445,
      -45.698,
      -40.948,
      34.445,
      -43.581,
      -39.952,
      34.445,
      -43.581,
      -39.952,
      31.845
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_5.OG_1063",
    "polygon": [
      9.351,
      7.977,
      19.87,
      10.28,
      8.414,
      19.87,
      10.28,
      8.414,
      22.1,
      10.28,
      8.414,
      22.1,
      9.351,
      7.977,
      22.1,
      9.351,
      7.977,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1064",
    "polygon": [
      7.78,
      7.237,
      19.87,
      8.709,
      7.675,
      19.87,
      8.709,
      7.675,
      22.1,
      8.709,
      7.675,
      22.1,
      7.78,
      7.237,
      22.1,
      7.78,
      7.237,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1378",
    "polygon": [
      -90.436,
      -38.979,
      23.288,
      -89.54,
      -38.558,
      23.288,
      -89.54,
      -38.558,
      26.818,
      -89.54,
      -38.558,
      26.818,
      -90.436,
      -38.979,
      26.818,
      -90.436,
      -38.979,
      23.288
    ]
  },
  {
    "ID": "DINHQ_5.OG_1023",
    "polygon": [
      -21.113,
      -25.796,
      19.87,
      -22.045,
      -26.235,
      19.87,
      -22.045,
      -26.235,
      22.1,
      -22.045,
      -26.235,
      22.1,
      -21.113,
      -25.796,
      22.1,
      -21.113,
      -25.796,
      19.87
    ],
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_6.OG_1265",
    "polygon": [
      7.78,
      7.237,
      23.288,
      8.709,
      7.675,
      23.288,
      8.709,
      7.675,
      26.818,
      8.709,
      7.675,
      26.818,
      7.78,
      7.237,
      26.818,
      7.78,
      7.237,
      23.288
    ]
  },
  {
    "ID": "DINHQ_6.OG_1355",
    "polygon": [
      -62.173,
      -25.68,
      23.288,
      -61.277,
      -25.258,
      23.288,
      -61.277,
      -25.258,
      26.818,
      -61.277,
      -25.258,
      26.818,
      -62.173,
      -25.68,
      26.818,
      -62.173,
      -25.68,
      23.288
    ]
  },
  {
    "ID": "DINHQ_8.OG_1681",
    "polygon": [
      -57.429,
      -31.164,
      31.845,
      -58.277,
      -29.363,
      31.845,
      -58.277,
      -29.363,
      34.445,
      -58.277,
      -29.363,
      34.445,
      -57.429,
      -31.164,
      34.445,
      -57.429,
      -31.164,
      31.845
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1117",
    "polygon": [
      -53.673,
      -3.332,
      19.87,
      -53.263,
      -4.204,
      19.87,
      -53.263,
      -4.204,
      21.647,
      -53.263,
      -4.204,
      21.647,
      -53.673,
      -3.332,
      21.647,
      -53.673,
      -3.332,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1100",
    "polygon": [
      -40.118,
      -15.302,
      19.87,
      -39.188,
      -14.864,
      19.87,
      -39.188,
      -14.864,
      22.1,
      -39.188,
      -14.864,
      22.1,
      -40.118,
      -15.302,
      22.1,
      -40.118,
      -15.302,
      19.87
    ]
  },
  {
    "ID": "DINHQ_10.OG_1818",
    "polygon": [
      -63.614,
      -18.018,
      40.445,
      -64.024,
      -17.146,
      40.445,
      -64.024,
      -17.146,
      44.113,
      -64.024,
      -17.146,
      44.113,
      -63.614,
      -18.018,
      44.113,
      -63.614,
      -18.018,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1576",
    "polygon": [
      -72.373,
      0.597,
      28.03,
      -72.784,
      1.469,
      28.03,
      -72.784,
      1.469,
      30.848,
      -72.784,
      1.469,
      30.848,
      -72.373,
      0.597,
      30.848,
      -72.373,
      0.597,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1567",
    "polygon": [
      -57.122,
      3.998,
      28.03,
      -56.712,
      3.125,
      28.03,
      -56.712,
      3.125,
      30.848,
      -56.712,
      3.125,
      30.848,
      -57.122,
      3.998,
      30.848,
      -57.122,
      3.998,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1305",
    "polygon": [
      -45.429,
      -17.801,
      23.288,
      -44.5,
      -17.364,
      23.288,
      -44.5,
      -17.364,
      26.818,
      -44.5,
      -17.364,
      26.818,
      -45.429,
      -17.801,
      26.818,
      -45.429,
      -17.801,
      23.288
    ]
  },
  {
    "ID": "DINHQ_9.OG_1703",
    "polygon": [
      -47.489,
      -16.474,
      35.445,
      -47.079,
      -17.346,
      35.445,
      -47.079,
      -17.346,
      39.445,
      -47.079,
      -17.346,
      39.445,
      -47.489,
      -16.474,
      39.445,
      -47.489,
      -16.474,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1048",
    "polygon": [
      12.46,
      -9.998,
      19.87,
      11.528,
      -10.436,
      19.87,
      11.528,
      -10.436,
      22.1,
      11.528,
      -10.436,
      22.1,
      12.46,
      -9.998,
      22.1,
      12.46,
      -9.998,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1594",
    "polygon": [
      -62.464,
      -20.461,
      28.03,
      -62.875,
      -19.589,
      28.03,
      -62.875,
      -19.589,
      30.848,
      -62.875,
      -19.589,
      30.848,
      -62.464,
      -20.461,
      30.848,
      -62.464,
      -20.461,
      28.03
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1495",
    "polygon": [
      -16.447,
      -21.943,
      23.288,
      -17.379,
      -22.382,
      23.288,
      -17.379,
      -22.382,
      26.818,
      -17.379,
      -22.382,
      26.818,
      -16.447,
      -21.943,
      26.818,
      -16.447,
      -21.943,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_7.OG_1593",
    "polygon": [
      -63.178,
      -18.944,
      28.03,
      -63.589,
      -18.072,
      28.03,
      -63.589,
      -18.072,
      30.848,
      -63.589,
      -18.072,
      30.848,
      -63.178,
      -18.944,
      30.848,
      -63.178,
      -18.944,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1366",
    "polygon": [
      -75.018,
      -31.724,
      23.288,
      -74.122,
      -31.303,
      23.288,
      -74.122,
      -31.303,
      26.818,
      -74.122,
      -31.303,
      26.818,
      -75.018,
      -31.724,
      26.818,
      -75.018,
      -31.724,
      23.288
    ]
  },
  {
    "ID": "DINHQ_8.OG_1690",
    "polygon": [
      -39.882,
      -32.642,
      31.845,
      -39.049,
      -34.412,
      31.845,
      -39.049,
      -34.412,
      34.445,
      -39.049,
      -34.412,
      34.445,
      -39.882,
      -32.642,
      34.445,
      -39.882,
      -32.642,
      31.845
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_7.OG_1571",
    "polygon": [
      -59.538,
      9.131,
      28.03,
      -59.01,
      8.009,
      28.03,
      -59.01,
      8.009,
      30.848,
      -59.01,
      8.009,
      30.848,
      -59.538,
      9.131,
      30.848,
      -59.538,
      9.131,
      28.03
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1668",
    "polygon": [
      -65.913,
      -13.132,
      31.845,
      -66.323,
      -12.26,
      31.845,
      -66.323,
      -12.26,
      34.445,
      -66.323,
      -12.26,
      34.445,
      -65.913,
      -13.132,
      34.445,
      -65.913,
      -13.132,
      31.845
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1046",
    "polygon": [
      9.837,
      -11.232,
      19.87,
      8.787,
      -11.726,
      19.87,
      8.787,
      -11.726,
      22.1,
      8.787,
      -11.726,
      22.1,
      9.837,
      -11.232,
      22.1,
      9.837,
      -11.232,
      19.87
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1278",
    "polygon": [
      -9.403,
      -0.849,
      23.288,
      -8.474,
      -0.411,
      23.288,
      -8.474,
      -0.411,
      26.818,
      -8.474,
      -0.411,
      26.818,
      -9.403,
      -0.849,
      26.818,
      -9.403,
      -0.849,
      23.288
    ]
  },
  {
    "ID": "DINHQ_5.OG_1152",
    "polygon": [
      -61.315,
      -22.904,
      19.87,
      -61.725,
      -22.032,
      19.87,
      -61.725,
      -22.032,
      21.647,
      -61.725,
      -22.032,
      21.647,
      -61.315,
      -22.904,
      21.647,
      -61.315,
      -22.904,
      19.87
    ]
  },
  {
    "ID": "DINHQ_8.OG_1627",
    "polygon": [
      -40.822,
      -38.653,
      31.845,
      -42.939,
      -39.65,
      31.845,
      -42.939,
      -39.65,
      34.445,
      -42.939,
      -39.65,
      34.445,
      -40.822,
      -38.653,
      34.445,
      -40.822,
      -38.653,
      31.845
    ],
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_8.OG_1695",
    "polygon": [
      -44.482,
      -22.871,
      31.845,
      -43.634,
      -24.671,
      31.845,
      -43.634,
      -24.671,
      34.445,
      -43.634,
      -24.671,
      34.445,
      -44.482,
      -22.871,
      34.445,
      -44.482,
      -22.871,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1689",
    "polygon": [
      -45.628,
      -20.435,
      31.845,
      -45.218,
      -21.307,
      31.845,
      -45.218,
      -21.307,
      34.445,
      -45.218,
      -21.307,
      34.445,
      -45.628,
      -20.435,
      34.445,
      -45.628,
      -20.435,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1276",
    "polygon": [
      -6.754,
      0.398,
      23.288,
      -5.825,
      0.835,
      23.288,
      -5.825,
      0.835,
      26.818,
      -5.825,
      0.835,
      26.818,
      -6.754,
      0.398,
      26.818,
      -6.754,
      0.398,
      23.288
    ]
  },
  {
    "ID": "DINHQ_7.OG_1595",
    "polygon": [
      -62.029,
      -21.387,
      28.03,
      -62.439,
      -20.515,
      28.03,
      -62.439,
      -20.515,
      30.848,
      -62.439,
      -20.515,
      30.848,
      -62.029,
      -21.387,
      30.848,
      -62.029,
      -21.387,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1177",
    "polygon": [
      -90.436,
      -38.979,
      19.87,
      -89.54,
      -38.558,
      19.87,
      -89.54,
      -38.558,
      22.095,
      -89.54,
      -38.558,
      22.095,
      -90.436,
      -38.979,
      22.095,
      -90.436,
      -38.979,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1611",
    "polygon": [
      -55.012,
      -36.302,
      29.73,
      -56.097,
      -33.996,
      29.73,
      -56.097,
      -33.996,
      30.817,
      -56.097,
      -33.996,
      30.817,
      -55.012,
      -36.302,
      30.817,
      -55.012,
      -36.302,
      29.73
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_974",
    "polygon": [
      -90.436,
      -38.979,
      16.5,
      -89.54,
      -38.558,
      16.5,
      -89.54,
      -38.558,
      18.725,
      -89.54,
      -38.558,
      18.725,
      -90.436,
      -38.979,
      18.725,
      -90.436,
      -38.979,
      16.5
    ]
  },
  {
    "ID": "DINHQ_6.OG_1345",
    "polygon": [
      -65.913,
      -13.132,
      23.288,
      -66.323,
      -12.26,
      23.288,
      -66.323,
      -12.26,
      26.818,
      -66.323,
      -12.26,
      26.818,
      -65.913,
      -13.132,
      26.818,
      -65.913,
      -13.132,
      23.288
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_10.OG_1813",
    "polygon": [
      -66.627,
      -11.615,
      40.445,
      -67.037,
      -10.743,
      40.445,
      -67.037,
      -10.743,
      44.113,
      -67.037,
      -10.743,
      44.113,
      -66.627,
      -11.615,
      44.113,
      -66.627,
      -11.615,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_4.OG_896",
    "polygon": [
      -38.546,
      -14.562,
      16.5,
      -37.617,
      -14.125,
      16.5,
      -37.617,
      -14.125,
      18.73,
      -37.617,
      -14.125,
      18.73,
      -38.546,
      -14.562,
      18.73,
      -38.546,
      -14.562,
      16.5
    ]
  },
  {
    "ID": "DINHQ_9.OG_1697",
    "polygon": [
      -46.34,
      -41.25,
      35.445,
      -48.457,
      -42.247,
      35.445,
      -48.457,
      -42.247,
      39.445,
      -48.457,
      -42.247,
      39.445,
      -46.34,
      -41.25,
      39.445,
      -46.34,
      -41.25,
      35.445
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1498",
    "polygon": [
      -12.223,
      -19.955,
      23.289,
      -13.155,
      -20.394,
      23.289,
      -13.155,
      -20.394,
      26.819,
      -13.155,
      -20.394,
      26.819,
      -12.223,
      -19.955,
      26.819,
      -12.223,
      -19.955,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_5.OG_1059",
    "polygon": [
      14.626,
      10.459,
      19.87,
      15.555,
      10.896,
      19.87,
      15.555,
      10.896,
      22.1,
      15.555,
      10.896,
      22.1,
      14.626,
      10.459,
      22.1,
      14.626,
      10.459,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1504",
    "polygon": [
      -4.274,
      -16.215,
      23.289,
      -5.206,
      -16.654,
      23.289,
      -5.206,
      -16.654,
      26.819,
      -5.206,
      -16.654,
      26.819,
      -4.274,
      -16.215,
      26.819,
      -4.274,
      -16.215,
      23.289
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_9.OG_1726",
    "polygon": [
      -63.886,
      7.181,
      35.445,
      -62.661,
      7.757,
      35.445,
      -62.661,
      7.757,
      39.445,
      -62.661,
      7.757,
      39.445,
      -63.886,
      7.181,
      39.445,
      -63.886,
      7.181,
      35.445
    ]
  },
  {
    "ID": "DINHQ_7.OG_1556",
    "polygon": [
      -50.938,
      -9.144,
      28.03,
      -50.528,
      -10.017,
      28.03,
      -50.528,
      -10.017,
      30.848,
      -50.528,
      -10.017,
      30.848,
      -50.938,
      -9.144,
      30.848,
      -50.938,
      -9.144,
      28.03
    ],
    "11-07 07:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1479",
    "polygon": [
      -37.645,
      -31.918,
      23.288,
      -38.577,
      -32.357,
      23.288,
      -38.577,
      -32.357,
      26.818,
      -38.577,
      -32.357,
      26.818,
      -37.645,
      -31.918,
      26.818,
      -37.645,
      -31.918,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1768",
    "polygon": [
      -44.482,
      -22.871,
      35.445,
      -43.634,
      -24.671,
      35.445,
      -43.634,
      -24.671,
      39.445,
      -43.634,
      -24.671,
      39.445,
      -44.482,
      -22.871,
      39.445,
      -44.482,
      -22.871,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1751",
    "polygon": [
      -52.846,
      -40.903,
      35.445,
      -53.679,
      -39.133,
      35.445,
      -53.679,
      -39.133,
      39.445,
      -53.679,
      -39.133,
      39.445,
      -52.846,
      -40.903,
      39.445,
      -52.846,
      -40.903,
      35.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_8.OG_1665",
    "polygon": [
      -67.776,
      -9.172,
      31.845,
      -68.187,
      -8.3,
      31.845,
      -68.187,
      -8.3,
      34.445,
      -68.187,
      -8.3,
      34.445,
      -67.776,
      -9.172,
      34.445,
      -67.776,
      -9.172,
      31.845
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1487",
    "polygon": [
      -27.047,
      -26.931,
      23.288,
      -27.979,
      -27.37,
      23.288,
      -27.979,
      -27.37,
      26.818,
      -27.979,
      -27.37,
      26.818,
      -27.047,
      -26.931,
      26.818,
      -27.047,
      -26.931,
      23.288
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_4.OG_958",
    "polygon": [
      -69.879,
      -29.306,
      16.5,
      -68.983,
      -28.884,
      16.5,
      -68.983,
      -28.884,
      18.725,
      -68.983,
      -28.884,
      18.725,
      -69.879,
      -29.306,
      18.725,
      -69.879,
      -29.306,
      16.5
    ]
  },
  {
    "ID": "DINHQ_10.OG_1816",
    "polygon": [
      -64.763,
      -15.575,
      40.445,
      -65.174,
      -14.703,
      40.445,
      -65.174,
      -14.703,
      44.113,
      -65.174,
      -14.703,
      44.113,
      -64.763,
      -15.575,
      44.113,
      -64.763,
      -15.575,
      40.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1501",
    "polygon": [
      -8.498,
      -18.203,
      23.288,
      -9.43,
      -18.641,
      23.288,
      -9.43,
      -18.641,
      26.818,
      -9.43,
      -18.641,
      26.818,
      -8.498,
      -18.203,
      26.818,
      -8.498,
      -18.203,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1749",
    "polygon": [
      -61.315,
      -22.904,
      35.445,
      -61.725,
      -22.032,
      35.445,
      -61.725,
      -22.032,
      39.445,
      -61.725,
      -22.032,
      39.445,
      -61.315,
      -22.904,
      39.445,
      -61.315,
      -22.904,
      35.445
    ],
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1331",
    "polygon": [
      -70.299,
      4.163,
      23.288,
      -69.073,
      4.74,
      23.288,
      -69.073,
      4.74,
      26.818,
      -69.073,
      4.74,
      26.818,
      -70.299,
      4.163,
      26.818,
      -70.299,
      4.163,
      23.288
    ]
  },
  {
    "ID": "DINHQ_5.OG_1127",
    "polygon": [
      -59.538,
      9.131,
      19.87,
      -59.01,
      8.009,
      19.87,
      -59.01,
      8.009,
      21.647,
      -59.01,
      8.009,
      21.647,
      -59.538,
      9.131,
      21.647,
      -59.538,
      9.131,
      19.87
    ]
  },
  {
    "ID": "DINHQ_5.OG_1178",
    "polygon": [
      -91.97,
      -39.701,
      19.87,
      -91.075,
      -39.28,
      19.87,
      -91.075,
      -39.28,
      22.095,
      -91.075,
      -39.28,
      22.095,
      -91.97,
      -39.701,
      22.095,
      -91.97,
      -39.701,
      19.87
    ]
  },
  {
    "ID": "DINHQ_10.OG_1792",
    "polygon": [
      -56.687,
      3.072,
      40.445,
      -56.276,
      2.199,
      40.445,
      -56.276,
      2.199,
      44.113,
      -56.276,
      2.199,
      44.113,
      -56.687,
      3.072,
      44.113,
      -56.687,
      3.072,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1698",
    "polygon": [
      -49.1,
      -42.549,
      35.445,
      -51.389,
      -43.626,
      35.445,
      -51.389,
      -43.626,
      39.445,
      -51.389,
      -43.626,
      39.445,
      -49.1,
      -42.549,
      39.445,
      -49.1,
      -42.549,
      35.445
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1
  },
  {
    "ID": "DINHQ_5.OG_1098",
    "polygon": [
      -37.468,
      -14.055,
      19.87,
      -36.539,
      -13.618,
      19.87,
      -36.539,
      -13.618,
      22.1,
      -36.539,
      -13.618,
      22.1,
      -37.468,
      -14.055,
      22.1,
      -37.468,
      -14.055,
      19.87
    ]
  },
  {
    "ID": "DINHQ_7.OG_1589",
    "polygon": [
      -65.477,
      -14.058,
      28.03,
      -65.888,
      -13.186,
      28.03,
      -65.888,
      -13.186,
      30.848,
      -65.888,
      -13.186,
      30.848,
      -65.477,
      -14.058,
      30.848,
      -65.477,
      -14.058,
      28.03
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1478",
    "polygon": [
      -53.97,
      -39.6,
      23.288,
      -54.287,
      -39.749,
      23.288,
      -54.287,
      -39.749,
      26.818,
      -54.287,
      -39.749,
      26.818,
      -53.97,
      -39.6,
      26.818,
      -53.97,
      -39.6,
      23.288
    ],
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1
  },
  {
    "ID": "DINHQ_8.OG_1635",
    "polygon": [
      -50.224,
      -10.662,
      31.845,
      -49.814,
      -11.534,
      31.845,
      -49.814,
      -11.534,
      34.445,
      -49.814,
      -11.534,
      34.445,
      -50.224,
      -10.662,
      34.445,
      -50.224,
      -10.662,
      31.845
    ],
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_1078",
    "polygon": [
      -10.975,
      -1.588,
      19.87,
      -10.046,
      -1.151,
      19.87,
      -10.046,
      -1.151,
      22.1,
      -10.046,
      -1.151,
      22.1,
      -10.975,
      -1.588,
      22.1,
      -10.975,
      -1.588,
      19.87
    ]
  },
  {
    "ID": "DINHQ_4.OG_945",
    "polygon": [
      -63.614,
      -18.018,
      16.5,
      -64.024,
      -17.146,
      16.5,
      -64.024,
      -17.146,
      18.277,
      -64.024,
      -17.146,
      18.277,
      -63.614,
      -18.018,
      18.277,
      -63.614,
      -18.018,
      16.5
    ]
  },
  {
    "ID": "DINHQ_4.OG_894",
    "polygon": [
      -35.897,
      -13.315,
      16.5,
      -34.967,
      -12.878,
      16.5,
      -34.967,
      -12.878,
      18.73,
      -34.967,
      -12.878,
      18.73,
      -35.897,
      -13.315,
      18.73,
      -35.897,
      -13.315,
      16.5
    ]
  },
  {
    "ID": "DINHQ_5.OG_1153",
    "polygon": [
      -60.879,
      -23.83,
      19.87,
      -61.289,
      -22.958,
      19.87,
      -61.289,
      -22.958,
      21.647,
      -61.289,
      -22.958,
      21.647,
      -60.879,
      -23.83,
      21.647,
      -60.879,
      -23.83,
      19.87
    ]
  },
  {
    "ID": "DINHQ_6.OG_1261",
    "polygon": [
      13.055,
      9.72,
      23.288,
      13.984,
      10.157,
      23.288,
      13.984,
      10.157,
      26.818,
      13.984,
      10.157,
      26.818,
      13.055,
      9.72,
      26.818,
      13.055,
      9.72,
      23.288
    ]
  },
  {
    "ID": "DINHQ_7.OG_1559",
    "polygon": [
      -52.523,
      -5.775,
      28.03,
      -52.113,
      -6.648,
      28.03,
      -52.113,
      -6.648,
      30.848,
      -52.113,
      -6.648,
      30.848,
      -52.523,
      -5.775,
      30.848,
      -52.523,
      -5.775,
      28.03
    ],
    "11-07 07:45": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_8.OG_1649",
    "polygon": [
      -58.272,
      6.441,
      31.845,
      -57.862,
      5.569,
      31.845,
      -57.862,
      5.569,
      34.445,
      -57.862,
      5.569,
      34.445,
      -58.272,
      6.441,
      34.445,
      -58.272,
      6.441,
      31.845
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_5.OG_994",
    "polygon": [
      -63.032,
      -45.522,
      19.873,
      -63.928,
      -45.943,
      19.873,
      -63.928,
      -45.943,
      22.098,
      -63.928,
      -45.943,
      22.098,
      -63.032,
      -45.522,
      22.098,
      -63.032,
      -45.522,
      19.873
    ],
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_6.OG_1461",
    "polygon": [
      -74.98,
      -49.487,
      23.288,
      -75.876,
      -49.908,
      23.288,
      -75.876,
      -49.908,
      26.818,
      -75.876,
      -49.908,
      26.818,
      -74.98,
      -49.487,
      26.818,
      -74.98,
      -49.487,
      23.288
    ],
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_8.OG_1652",
    "polygon": [
      -62.02,
      8.06,
      31.845,
      -59.729,
      9.138,
      31.845,
      -59.729,
      9.138,
      34.445,
      -59.729,
      9.138,
      34.445,
      -62.02,
      8.06,
      34.445,
      -62.02,
      8.06,
      31.845
    ]
  },
  {
    "ID": "DINHQ_6.OG_1511",
    "polygon": [
      4.748,
      -11.97,
      23.288,
      3.816,
      -12.408,
      23.288,
      3.816,
      -12.408,
      26.818,
      3.816,
      -12.408,
      26.818,
      4.748,
      -11.97,
      26.818,
      4.748,
      -11.97,
      23.288
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1
  },
  {
    "ID": "DINHQ_9.OG_1763",
    "polygon": [
      -39.882,
      -32.642,
      35.445,
      -39.049,
      -34.412,
      35.445,
      -39.049,
      -34.412,
      39.445,
      -39.049,
      -34.412,
      39.445,
      -39.882,
      -32.642,
      39.445,
      -39.882,
      -32.642,
      35.445
    ],
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1,
    "11-07 09:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1823",
    "polygon": [
      -60.879,
      -23.83,
      40.445,
      -61.289,
      -22.958,
      40.445,
      -61.289,
      -22.958,
      44.113,
      -61.289,
      -22.958,
      44.113,
      -60.879,
      -23.83,
      44.113,
      -60.879,
      -23.83,
      40.445
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1496",
    "polygon": [
      -14.873,
      -21.202,
      23.289,
      -15.804,
      -21.641,
      23.289,
      -15.804,
      -21.641,
      26.819,
      -15.804,
      -21.641,
      26.819,
      -14.873,
      -21.202,
      26.819,
      -14.873,
      -21.202,
      23.289
    ],
    "11-07 08:45": 1,
    "11-07 09:00": 1,
    "11-07 09:15": 1,
    "11-07 09:30": 1,
    "11-07 09:45": 1,
    "11-07 10:00": 1,
    "11-07 10:15": 1,
    "11-07 10:30": 1,
    "11-07 10:45": 1,
    "11-07 11:00": 1,
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1
  },
  {
    "ID": "DINHQ_4.OG_961",
    "polygon": [
      -73.983,
      -31.237,
      16.5,
      -73.087,
      -30.816,
      16.5,
      -73.087,
      -30.816,
      18.725,
      -73.087,
      -30.816,
      18.725,
      -73.983,
      -31.237,
      18.725,
      -73.983,
      -31.237,
      16.5
    ]
  },
  {
    "ID": "DINHQ_9.OG_1767",
    "polygon": [
      -55.131,
      -36.048,
      35.445,
      -55.978,
      -34.247,
      35.445,
      -55.978,
      -34.247,
      39.445,
      -55.978,
      -34.247,
      39.445,
      -55.131,
      -36.048,
      39.445,
      -55.131,
      -36.048,
      35.445
    ],
    "11-07 11:15": 1,
    "11-07 11:30": 1,
    "11-07 11:45": 1,
    "11-07 12:00": 1,
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1,
    "11-07 13:30": 1,
    "11-07 13:45": 1,
    "11-07 14:00": 1,
    "11-07 14:15": 1,
    "11-07 14:30": 1,
    "11-07 14:45": 1,
    "11-07 15:00": 1,
    "11-07 15:15": 1,
    "11-07 15:30": 1,
    "11-07 15:45": 1,
    "11-07 16:00": 1
  },
  {
    "ID": "DINHQ_10.OG_1793",
    "polygon": [
      -57.122,
      3.998,
      40.445,
      -56.712,
      3.125,
      40.445,
      -56.712,
      3.125,
      44.113,
      -56.712,
      3.125,
      44.113,
      -57.122,
      3.998,
      44.113,
      -57.122,
      3.998,
      40.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1,
    "11-07 08:15": 1,
    "11-07 08:30": 1,
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_6.OG_1318",
    "polygon": [
      -53.673,
      -3.332,
      23.288,
      -53.263,
      -4.204,
      23.288,
      -53.263,
      -4.204,
      26.818,
      -53.263,
      -4.204,
      26.818,
      -53.673,
      -3.332,
      26.818,
      -53.673,
      -3.332,
      23.288
    ],
    "11-07 08:45": 1
  },
  {
    "ID": "DINHQ_9.OG_1711",
    "polygon": [
      -52.088,
      -6.701,
      35.445,
      -51.677,
      -7.573,
      35.445,
      -51.677,
      -7.573,
      39.445,
      -51.677,
      -7.573,
      39.445,
      -52.088,
      -6.701,
      39.445,
      -52.088,
      -6.701,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_9.OG_1709",
    "polygon": [
      -50.938,
      -9.144,
      35.445,
      -50.528,
      -10.017,
      35.445,
      -50.528,
      -10.017,
      39.445,
      -50.528,
      -10.017,
      39.445,
      -50.938,
      -9.144,
      39.445,
      -50.938,
      -9.144,
      35.445
    ],
    "11-07 07:30": 1,
    "11-07 07:45": 1,
    "11-07 08:00": 1
  },
  {
    "ID": "DINHQ_6.OG_1540",
    "polygon": [
      -85.615,
      -50.177,
      25.22,
      -86.28,
      -48.765,
      25.22,
      -86.28,
      -48.765,
      26.82,
      -86.28,
      -48.765,
      26.82,
      -85.615,
      -50.177,
      26.82,
      -85.615,
      -50.177,
      25.22
    ],
    "11-07 12:15": 1,
    "11-07 12:30": 1,
    "11-07 12:45": 1,
    "11-07 13:00": 1,
    "11-07 13:15": 1
  },
  {
    "ID": "DINHQ_7.OG_1620",
    "polygon": [
      -44.053,
      -23.783,
      27.315,
      -43.523,
      -24.908,
      27.315,
      -43.523,
      -24.908,
      29.66,
      -43.523,
      -24.908,
      29.66,
      -44.053,
      -23.783,
      29.66,
      -44.053,
      -23.783,
      27.315
    ]
  }
]
export default project1289ShadingProgression;
