const project52SunPosition = [
  {
    id: 914756,
    customerId: 30,
    projectId: 72,
    time: "04-15 00:00",
    azimuth: 15,
    elevation: -26.57,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914757,
    customerId: 30,
    projectId: 72,
    time: "04-15 00:15",
    azimuth: 19,
    elevation: -25.92,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914758,
    customerId: 30,
    projectId: 72,
    time: "04-15 00:30",
    azimuth: 23,
    elevation: -25.11,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914759,
    customerId: 30,
    projectId: 72,
    time: "04-15 00:45",
    azimuth: 27,
    elevation: -24.15,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914760,
    customerId: 30,
    projectId: 72,
    time: "04-15 01:00",
    azimuth: 30,
    elevation: -23.06,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914761,
    customerId: 30,
    projectId: 72,
    time: "04-15 01:15",
    azimuth: 34,
    elevation: -21.84,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914762,
    customerId: 30,
    projectId: 72,
    time: "04-15 01:30",
    azimuth: 38,
    elevation: -20.49,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914763,
    customerId: 30,
    projectId: 72,
    time: "04-15 01:45",
    azimuth: 42,
    elevation: -19.03,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914764,
    customerId: 30,
    projectId: 72,
    time: "04-15 02:00",
    azimuth: 45,
    elevation: -17.46,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914765,
    customerId: 30,
    projectId: 72,
    time: "04-15 02:15",
    azimuth: 49,
    elevation: -15.8,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914766,
    customerId: 30,
    projectId: 72,
    time: "04-15 02:30",
    azimuth: 52,
    elevation: -14.04,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914767,
    customerId: 30,
    projectId: 72,
    time: "04-15 02:45",
    azimuth: 55,
    elevation: -12.2,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914768,
    customerId: 30,
    projectId: 72,
    time: "04-15 03:00",
    azimuth: 58,
    elevation: -10.29,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914769,
    customerId: 30,
    projectId: 72,
    time: "04-15 03:15",
    azimuth: 62,
    elevation: -8.32,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914770,
    customerId: 30,
    projectId: 72,
    time: "04-15 03:30",
    azimuth: 65,
    elevation: -6.28,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914771,
    customerId: 30,
    projectId: 72,
    time: "04-15 03:45",
    azimuth: 68,
    elevation: -4.19,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914772,
    customerId: 30,
    projectId: 72,
    time: "04-15 04:00",
    azimuth: 71,
    elevation: -2.06,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914773,
    customerId: 30,
    projectId: 72,
    time: "04-15 04:15",
    azimuth: 74,
    elevation: 0.12,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 914774,
    customerId: 30,
    projectId: 72,
    time: "04-15 04:30",
    azimuth: 77,
    elevation: 2.33,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 914775,
    customerId: 30,
    projectId: 72,
    time: "04-15 04:45",
    azimuth: 80,
    elevation: 4.56,
    maxRadiation: 158,
    cDirAmi: 6,
  },
  {
    id: 914776,
    customerId: 30,
    projectId: 72,
    time: "04-15 05:00",
    azimuth: 83,
    elevation: 6.82,
    maxRadiation: 277,
    cDirAmi: 6,
  },
  {
    id: 914777,
    customerId: 30,
    projectId: 72,
    time: "04-15 05:15",
    azimuth: 86,
    elevation: 9.09,
    maxRadiation: 360,
    cDirAmi: 6,
  },
  {
    id: 914778,
    customerId: 30,
    projectId: 72,
    time: "04-15 05:30",
    azimuth: 88,
    elevation: 11.37,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 914779,
    customerId: 30,
    projectId: 72,
    time: "04-15 05:45",
    azimuth: 92,
    elevation: 13.65,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 914780,
    customerId: 30,
    projectId: 72,
    time: "04-15 06:00",
    azimuth: 94,
    elevation: 15.93,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 914781,
    customerId: 30,
    projectId: 72,
    time: "04-15 06:15",
    azimuth: 98,
    elevation: 18.2,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 914782,
    customerId: 30,
    projectId: 72,
    time: "04-15 06:30",
    azimuth: 101,
    elevation: 20.45,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 914783,
    customerId: 30,
    projectId: 72,
    time: "04-15 06:45",
    azimuth: 104,
    elevation: 22.69,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 914784,
    customerId: 30,
    projectId: 72,
    time: "04-15 07:00",
    azimuth: 107,
    elevation: 24.89,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914785,
    customerId: 30,
    projectId: 72,
    time: "04-15 07:15",
    azimuth: 110,
    elevation: 27.05,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914786,
    customerId: 30,
    projectId: 72,
    time: "04-15 07:30",
    azimuth: 114,
    elevation: 29.16,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914787,
    customerId: 30,
    projectId: 72,
    time: "04-15 07:45",
    azimuth: 117,
    elevation: 31.22,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914788,
    customerId: 30,
    projectId: 72,
    time: "04-15 08:00",
    azimuth: 121,
    elevation: 33.22,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914789,
    customerId: 30,
    projectId: 72,
    time: "04-15 08:15",
    azimuth: 125,
    elevation: 35.13,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914790,
    customerId: 30,
    projectId: 72,
    time: "04-15 08:30",
    azimuth: 129,
    elevation: 36.96,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914791,
    customerId: 30,
    projectId: 72,
    time: "04-15 08:45",
    azimuth: 133,
    elevation: 38.68,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914792,
    customerId: 30,
    projectId: 72,
    time: "04-15 09:00",
    azimuth: 137,
    elevation: 40.29,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914793,
    customerId: 30,
    projectId: 72,
    time: "04-15 09:15",
    azimuth: 142,
    elevation: 41.78,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914794,
    customerId: 30,
    projectId: 72,
    time: "04-15 09:30",
    azimuth: 146,
    elevation: 43.11,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914795,
    customerId: 30,
    projectId: 72,
    time: "04-15 09:45",
    azimuth: 151,
    elevation: 44.29,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914796,
    customerId: 30,
    projectId: 72,
    time: "04-15 10:00",
    azimuth: 156,
    elevation: 45.3,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914797,
    customerId: 30,
    projectId: 72,
    time: "04-15 10:15",
    azimuth: 162,
    elevation: 46.13,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914798,
    customerId: 30,
    projectId: 72,
    time: "04-15 10:30",
    azimuth: 167,
    elevation: 46.75,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914799,
    customerId: 30,
    projectId: 72,
    time: "04-15 10:45",
    azimuth: 172,
    elevation: 47.17,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914800,
    customerId: 30,
    projectId: 72,
    time: "04-15 11:00",
    azimuth: 178,
    elevation: 47.38,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914801,
    customerId: 30,
    projectId: 72,
    time: "04-15 11:15",
    azimuth: 183,
    elevation: 47.37,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914802,
    customerId: 30,
    projectId: 72,
    time: "04-15 11:30",
    azimuth: 188,
    elevation: 47.14,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914803,
    customerId: 30,
    projectId: 72,
    time: "04-15 11:45",
    azimuth: 194,
    elevation: 46.7,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914804,
    customerId: 30,
    projectId: 72,
    time: "04-15 12:00",
    azimuth: 199,
    elevation: 46.05,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914805,
    customerId: 30,
    projectId: 72,
    time: "04-15 12:15",
    azimuth: 204,
    elevation: 45.21,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914806,
    customerId: 30,
    projectId: 72,
    time: "04-15 12:30",
    azimuth: 209,
    elevation: 44.18,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914807,
    customerId: 30,
    projectId: 72,
    time: "04-15 12:45",
    azimuth: 214,
    elevation: 42.98,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 914808,
    customerId: 30,
    projectId: 72,
    time: "04-15 13:00",
    azimuth: 219,
    elevation: 41.63,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914809,
    customerId: 30,
    projectId: 72,
    time: "04-15 13:15",
    azimuth: 223,
    elevation: 40.13,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914810,
    customerId: 30,
    projectId: 72,
    time: "04-15 13:30",
    azimuth: 228,
    elevation: 38.51,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914811,
    customerId: 30,
    projectId: 72,
    time: "04-15 13:45",
    azimuth: 232,
    elevation: 36.77,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914812,
    customerId: 30,
    projectId: 72,
    time: "04-15 14:00",
    azimuth: 236,
    elevation: 34.94,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914813,
    customerId: 30,
    projectId: 72,
    time: "04-15 14:15",
    azimuth: 239,
    elevation: 33.01,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914814,
    customerId: 30,
    projectId: 72,
    time: "04-15 14:30",
    azimuth: 243,
    elevation: 31.01,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914815,
    customerId: 30,
    projectId: 72,
    time: "04-15 14:45",
    azimuth: 246,
    elevation: 28.95,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914816,
    customerId: 30,
    projectId: 72,
    time: "04-15 15:00",
    azimuth: 250,
    elevation: 26.83,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914817,
    customerId: 30,
    projectId: 72,
    time: "04-15 15:15",
    azimuth: 253,
    elevation: 24.66,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 914818,
    customerId: 30,
    projectId: 72,
    time: "04-15 15:30",
    azimuth: 256,
    elevation: 22.46,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 914819,
    customerId: 30,
    projectId: 72,
    time: "04-15 15:45",
    azimuth: 260,
    elevation: 20.22,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 914820,
    customerId: 30,
    projectId: 72,
    time: "04-15 16:00",
    azimuth: 263,
    elevation: 17.97,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 914821,
    customerId: 30,
    projectId: 72,
    time: "04-15 16:15",
    azimuth: 266,
    elevation: 15.7,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 914822,
    customerId: 30,
    projectId: 72,
    time: "04-15 16:30",
    azimuth: 269,
    elevation: 13.42,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 914823,
    customerId: 30,
    projectId: 72,
    time: "04-15 16:45",
    azimuth: 272,
    elevation: 11.13,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 914824,
    customerId: 30,
    projectId: 72,
    time: "04-15 17:00",
    azimuth: 275,
    elevation: 8.85,
    maxRadiation: 360,
    cDirAmi: 6,
  },
  {
    id: 914825,
    customerId: 30,
    projectId: 72,
    time: "04-15 17:15",
    azimuth: 278,
    elevation: 6.58,
    maxRadiation: 245,
    cDirAmi: 6,
  },
  {
    id: 914826,
    customerId: 30,
    projectId: 72,
    time: "04-15 17:30",
    azimuth: 281,
    elevation: 4.33,
    maxRadiation: 158,
    cDirAmi: 6,
  },
  {
    id: 914827,
    customerId: 30,
    projectId: 72,
    time: "04-15 17:45",
    azimuth: 284,
    elevation: 2.1,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 914828,
    customerId: 30,
    projectId: 72,
    time: "04-15 18:00",
    azimuth: 286,
    elevation: -0.11,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914829,
    customerId: 30,
    projectId: 72,
    time: "04-15 18:15",
    azimuth: 290,
    elevation: -2.28,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914830,
    customerId: 30,
    projectId: 72,
    time: "04-15 18:30",
    azimuth: 293,
    elevation: -4.41,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914831,
    customerId: 30,
    projectId: 72,
    time: "04-15 18:45",
    azimuth: 296,
    elevation: -6.49,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914832,
    customerId: 30,
    projectId: 72,
    time: "04-15 19:00",
    azimuth: 299,
    elevation: -8.52,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914833,
    customerId: 30,
    projectId: 72,
    time: "04-15 19:15",
    azimuth: 302,
    elevation: -10.49,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914834,
    customerId: 30,
    projectId: 72,
    time: "04-15 19:30",
    azimuth: 305,
    elevation: -12.4,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914835,
    customerId: 30,
    projectId: 72,
    time: "04-15 19:45",
    azimuth: 308,
    elevation: -14.23,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914836,
    customerId: 30,
    projectId: 72,
    time: "04-15 20:00",
    azimuth: 312,
    elevation: -15.97,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914837,
    customerId: 30,
    projectId: 72,
    time: "04-15 20:15",
    azimuth: 315,
    elevation: -17.63,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914838,
    customerId: 30,
    projectId: 72,
    time: "04-15 20:30",
    azimuth: 319,
    elevation: -19.18,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914839,
    customerId: 30,
    projectId: 72,
    time: "04-15 20:45",
    azimuth: 322,
    elevation: -20.63,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914840,
    customerId: 30,
    projectId: 72,
    time: "04-15 21:00",
    azimuth: 326,
    elevation: -21.97,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914841,
    customerId: 30,
    projectId: 72,
    time: "04-15 21:15",
    azimuth: 330,
    elevation: -23.18,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914842,
    customerId: 30,
    projectId: 72,
    time: "04-15 21:30",
    azimuth: 334,
    elevation: -24.26,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914843,
    customerId: 30,
    projectId: 72,
    time: "04-15 21:45",
    azimuth: 338,
    elevation: -25.2,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914844,
    customerId: 30,
    projectId: 72,
    time: "04-15 22:00",
    azimuth: 342,
    elevation: -25.99,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914845,
    customerId: 30,
    projectId: 72,
    time: "04-15 22:15",
    azimuth: 346,
    elevation: -26.63,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914846,
    customerId: 30,
    projectId: 72,
    time: "04-15 22:30",
    azimuth: 350,
    elevation: -27.12,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914847,
    customerId: 30,
    projectId: 72,
    time: "04-15 22:45",
    azimuth: 354,
    elevation: -27.44,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914848,
    customerId: 30,
    projectId: 72,
    time: "04-15 23:00",
    azimuth: 2,
    elevation: -27.32,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914849,
    customerId: 30,
    projectId: 72,
    time: "04-15 23:15",
    azimuth: 2,
    elevation: -27.31,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914850,
    customerId: 30,
    projectId: 72,
    time: "04-15 23:30",
    azimuth: 6,
    elevation: -27.14,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 914851,
    customerId: 30,
    projectId: 72,
    time: "04-15 23:45",
    azimuth: 11,
    elevation: -26.8,
    maxRadiation: 0,
    cDirAmi: 0,
  },
];

export default project52SunPosition;
