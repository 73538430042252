import React from "react";
import * as THREE from "three";
import { useSelector, useDispatch } from "react-redux";
import {
  updatePosition,
  hideWindRisk,
  setMaxTimeVelMoreThan12,
} from "../../redux/infoWindRisk";
import axios from "axios";
import { useState, useEffect } from "react";
import { setFirstWindIsShowing, setWindRiskIsLoading } from "../../redux/shadingSystem";
import { normalize, generateColor, f } from "../utils";
import { setTargetElementShow } from "../../redux/sceneSlice";
import { useColorBar } from "../CustomHooks";

const WindRiskData = (props) => {
  const project_id = localStorage.getItem("project_id");
  const token = localStorage.getItem("token");
  const [resultArray, setResultArray] = useState();
  const [nodes, setNodes] = useState(false);
  const dispatch = useDispatch();
  var windRiskSelector = useSelector((state) => state.shadingSystem.value);
  let params = {
    project_id: project_id,
  };
  let query = Object.keys(params)
    .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
    .join("&");
  let url = `${process.env.REACT_APP_API_URL}api/wind_risk/?` + query;

  const { max_time_velocity_more_than_12: maxTimeVelMoreThan12 } = useSelector(
    (state) => state.infoWindRisk
  );

  var maxVal = maxTimeVelMoreThan12;
  const { max, min } = useColorBar("Shading System", "Wind Risk", 0, maxVal);

  useEffect(() => {
    if (windRiskSelector.checkStatus[0] && !nodes) {
      dispatch(setWindRiskIsLoading(true));
      axios
        .get(url, {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setNodes(true);
          setResultArray(response.data);
          const maxTimeVelMoreThan12 = response.data.reduce(
            (total, current) =>
              total < current.time_velocity_more_than_12
                ? current.time_velocity_more_than_12
                : total,
            0
          );
          dispatch(setMaxTimeVelMoreThan12(maxTimeVelMoreThan12));
        }).finally(() => {
          dispatch(setWindRiskIsLoading(false));
        })
    }
  }, [windRiskSelector.checkStatus[0]]);

  return (
    <>
      {nodes && (
        <group
          rotation-x={Math.PI * -0.5}
          visible={windRiskSelector.checkStatus[0]}
        >
          {resultArray.map((window) => {
            return (
              <>
                <mesh
                  dispose={null}
                  onClick={(config) => {
                    if (!windRiskSelector.checkStatus[0]) return;
                    dispatch(setFirstWindIsShowing());
                    dispatch(
                      updatePosition([
                        config.pageX,
                        config.pageY,
                        JSON.parse(window.avg),
                        JSON.parse(window.max),
                        window.time_velocity_more_than_12,
                        window.name,
                        window.x,
                        window.y,
                        window.z,
                      ])
                    );
                    config.stopPropagation();
                  }}
                >
                  <bufferGeometry>
                    <bufferAttribute
                      attach="attributes-position"
                      array={new Float32Array(JSON.parse(window.polygon))}
                      count={
                        new Float32Array(JSON.parse(window.polygon)).length / 3
                      }
                      itemSize={3}
                    />
                  </bufferGeometry>
                  <meshBasicMaterial
                    attach="material"
                    side={THREE.DoubleSide}
                    color={generateColor(
                      window.time_velocity_more_than_12,
                      min,
                      max,
                      f
                    )}
                  />
                </mesh>
              </>
            );
          })}
        </group>
      )}
    </>
  );
};

export default React.memo(WindRiskData);
