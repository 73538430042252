const project8SunPosition = [
  {
    id: 2626594,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:00",
    azimuth: 10,
    elevation: -30.85,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626595,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:05",
    azimuth: 12,
    elevation: -30.69,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626596,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:10",
    azimuth: 13,
    elevation: -30.51,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626597,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:15",
    azimuth: 15,
    elevation: -30.31,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626598,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:20",
    azimuth: 16,
    elevation: -30.09,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626599,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:25",
    azimuth: 18,
    elevation: -29.85,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626600,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:30",
    azimuth: 19,
    elevation: -29.59,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626601,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:35",
    azimuth: 20,
    elevation: -29.31,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626602,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:40",
    azimuth: 22,
    elevation: -29.02,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626603,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:45",
    azimuth: 23,
    elevation: -28.7,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626604,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:50",
    azimuth: 24,
    elevation: -28.37,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626605,
    customerId: 68,
    projectId: 175,
    time: "04-15 00:55",
    azimuth: 26,
    elevation: -28.02,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626606,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:00",
    azimuth: 27,
    elevation: -27.66,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626607,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:05",
    azimuth: 28,
    elevation: -27.27,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626608,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:10",
    azimuth: 30,
    elevation: -26.87,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626609,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:15",
    azimuth: 31,
    elevation: -26.46,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626610,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:20",
    azimuth: 32,
    elevation: -26.03,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626611,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:25",
    azimuth: 34,
    elevation: -25.58,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626612,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:30",
    azimuth: 35,
    elevation: -25.11,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626613,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:35",
    azimuth: 36,
    elevation: -24.64,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626614,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:40",
    azimuth: 37,
    elevation: -24.14,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626615,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:45",
    azimuth: 38,
    elevation: -23.64,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626616,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:50",
    azimuth: 40,
    elevation: -23.12,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626617,
    customerId: 68,
    projectId: 175,
    time: "04-15 01:55",
    azimuth: 41,
    elevation: -22.58,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626618,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:00",
    azimuth: 42,
    elevation: -22.04,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626619,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:05",
    azimuth: 43,
    elevation: -21.48,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626620,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:10",
    azimuth: 44,
    elevation: -20.9,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626621,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:15",
    azimuth: 46,
    elevation: -20.32,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626622,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:20",
    azimuth: 47,
    elevation: -19.72,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626623,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:25",
    azimuth: 48,
    elevation: -19.12,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626624,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:30",
    azimuth: 49,
    elevation: -18.5,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626625,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:35",
    azimuth: 50,
    elevation: -17.87,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626626,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:40",
    azimuth: 51,
    elevation: -17.23,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626627,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:45",
    azimuth: 52,
    elevation: -16.59,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626628,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:50",
    azimuth: 53,
    elevation: -15.93,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626629,
    customerId: 68,
    projectId: 175,
    time: "04-15 02:55",
    azimuth: 54,
    elevation: -15.26,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626630,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:00",
    azimuth: 56,
    elevation: -14.58,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626631,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:05",
    azimuth: 57,
    elevation: -13.9,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626632,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:10",
    azimuth: 58,
    elevation: -13.21,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626633,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:15",
    azimuth: 59,
    elevation: -12.51,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626634,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:20",
    azimuth: 60,
    elevation: -11.8,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626635,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:25",
    azimuth: 61,
    elevation: -11.08,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626636,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:30",
    azimuth: 62,
    elevation: -10.36,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626637,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:35",
    azimuth: 63,
    elevation: -9.63,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626638,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:40",
    azimuth: 64,
    elevation: -8.89,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626639,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:45",
    azimuth: 65,
    elevation: -8.15,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626640,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:50",
    azimuth: 66,
    elevation: -7.4,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626641,
    customerId: 68,
    projectId: 175,
    time: "04-15 03:55",
    azimuth: 67,
    elevation: -6.64,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626642,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:00",
    azimuth: 68,
    elevation: -5.88,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626643,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:05",
    azimuth: 69,
    elevation: -5.12,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626644,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:10",
    azimuth: 70,
    elevation: -4.35,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626645,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:15",
    azimuth: 71,
    elevation: -3.57,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626646,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:20",
    azimuth: 72,
    elevation: -2.79,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626647,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:25",
    azimuth: 72,
    elevation: -2,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626648,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:30",
    azimuth: 74,
    elevation: -1.22,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626649,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:35",
    azimuth: 74,
    elevation: -0.42,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626650,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:40",
    azimuth: 75,
    elevation: 0.37,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626651,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:45",
    azimuth: 76,
    elevation: 1.17,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626652,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:50",
    azimuth: 77,
    elevation: 1.98,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626653,
    customerId: 68,
    projectId: 175,
    time: "04-15 04:55",
    azimuth: 78,
    elevation: 2.78,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626654,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:00",
    azimuth: 79,
    elevation: 3.59,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626655,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:05",
    azimuth: 80,
    elevation: 4.4,
    maxRadiation: 158,
    cDirAmi: 6,
  },
  {
    id: 2626656,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:10",
    azimuth: 81,
    elevation: 5.22,
    maxRadiation: 196,
    cDirAmi: 6,
  },
  {
    id: 2626657,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:15",
    azimuth: 82,
    elevation: 6.03,
    maxRadiation: 219,
    cDirAmi: 6,
  },
  {
    id: 2626658,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:20",
    azimuth: 83,
    elevation: 6.85,
    maxRadiation: 277,
    cDirAmi: 6,
  },
  {
    id: 2626659,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:25",
    azimuth: 84,
    elevation: 7.67,
    maxRadiation: 314,
    cDirAmi: 6,
  },
  {
    id: 2626660,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:30",
    azimuth: 85,
    elevation: 8.49,
    maxRadiation: 314,
    cDirAmi: 6,
  },
  {
    id: 2626661,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:35",
    azimuth: 86,
    elevation: 9.31,
    maxRadiation: 360,
    cDirAmi: 6,
  },
  {
    id: 2626662,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:40",
    azimuth: 86,
    elevation: 10.13,
    maxRadiation: 360,
    cDirAmi: 6,
  },
  {
    id: 2626663,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:45",
    azimuth: 87,
    elevation: 10.96,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 2626664,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:50",
    azimuth: 88,
    elevation: 11.78,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 2626665,
    customerId: 68,
    projectId: 175,
    time: "04-15 05:55",
    azimuth: 89,
    elevation: 12.61,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 2626666,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:00",
    azimuth: 90,
    elevation: 13.43,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 2626667,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:05",
    azimuth: 91,
    elevation: 14.26,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 2626668,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:10",
    azimuth: 92,
    elevation: 15.08,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 2626669,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:15",
    azimuth: 93,
    elevation: 15.9,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 2626670,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:20",
    azimuth: 94,
    elevation: 16.73,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626671,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:25",
    azimuth: 95,
    elevation: 17.55,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626672,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:30",
    azimuth: 96,
    elevation: 18.37,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626673,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:35",
    azimuth: 97,
    elevation: 19.19,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626674,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:40",
    azimuth: 98,
    elevation: 20.01,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626675,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:45",
    azimuth: 99,
    elevation: 20.82,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626676,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:50",
    azimuth: 100,
    elevation: 21.64,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626677,
    customerId: 68,
    projectId: 175,
    time: "04-15 06:55",
    azimuth: 101,
    elevation: 22.45,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626678,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:00",
    azimuth: 102,
    elevation: 23.26,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626679,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:05",
    azimuth: 103,
    elevation: 24.06,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626680,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:10",
    azimuth: 104,
    elevation: 24.87,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626681,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:15",
    azimuth: 105,
    elevation: 25.67,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626682,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:20",
    azimuth: 106,
    elevation: 26.46,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626683,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:25",
    azimuth: 107,
    elevation: 27.25,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626684,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:30",
    azimuth: 108,
    elevation: 28.04,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626685,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:35",
    azimuth: 109,
    elevation: 28.82,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626686,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:40",
    azimuth: 110,
    elevation: 29.59,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626687,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:45",
    azimuth: 111,
    elevation: 30.37,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626688,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:50",
    azimuth: 112,
    elevation: 31.13,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626689,
    customerId: 68,
    projectId: 175,
    time: "04-15 07:55",
    azimuth: 114,
    elevation: 31.89,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626690,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:00",
    azimuth: 115,
    elevation: 32.64,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626691,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:05",
    azimuth: 116,
    elevation: 33.39,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626692,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:10",
    azimuth: 117,
    elevation: 34.12,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626693,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:15",
    azimuth: 118,
    elevation: 34.85,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626694,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:20",
    azimuth: 120,
    elevation: 35.57,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626695,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:25",
    azimuth: 121,
    elevation: 36.29,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626696,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:30",
    azimuth: 122,
    elevation: 36.99,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626697,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:35",
    azimuth: 123,
    elevation: 37.68,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626698,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:40",
    azimuth: 125,
    elevation: 38.37,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626699,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:45",
    azimuth: 126,
    elevation: 39.04,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626700,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:50",
    azimuth: 127,
    elevation: 39.7,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626701,
    customerId: 68,
    projectId: 175,
    time: "04-15 08:55",
    azimuth: 129,
    elevation: 40.35,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626702,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:00",
    azimuth: 130,
    elevation: 40.99,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626703,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:05",
    azimuth: 132,
    elevation: 41.61,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626704,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:10",
    azimuth: 133,
    elevation: 42.22,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626705,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:15",
    azimuth: 134,
    elevation: 42.82,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626706,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:20",
    azimuth: 136,
    elevation: 43.4,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626707,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:25",
    azimuth: 137,
    elevation: 43.97,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626708,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:30",
    azimuth: 139,
    elevation: 44.52,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626709,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:35",
    azimuth: 140,
    elevation: 45.05,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626710,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:40",
    azimuth: 142,
    elevation: 45.57,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626711,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:45",
    azimuth: 144,
    elevation: 46.06,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626712,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:50",
    azimuth: 145,
    elevation: 46.54,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626713,
    customerId: 68,
    projectId: 175,
    time: "04-15 09:55",
    azimuth: 147,
    elevation: 47,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626714,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:00",
    azimuth: 149,
    elevation: 47.44,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626715,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:05",
    azimuth: 150,
    elevation: 47.86,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626716,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:10",
    azimuth: 152,
    elevation: 48.25,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626717,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:15",
    azimuth: 154,
    elevation: 48.62,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626718,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:20",
    azimuth: 156,
    elevation: 48.97,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626719,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:25",
    azimuth: 158,
    elevation: 49.3,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626720,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:30",
    azimuth: 159,
    elevation: 49.6,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626721,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:35",
    azimuth: 161,
    elevation: 49.88,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626722,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:40",
    azimuth: 163,
    elevation: 50.13,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626723,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:45",
    azimuth: 165,
    elevation: 50.36,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626724,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:50",
    azimuth: 167,
    elevation: 50.56,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626725,
    customerId: 68,
    projectId: 175,
    time: "04-15 10:55",
    azimuth: 169,
    elevation: 50.73,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626726,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:00",
    azimuth: 171,
    elevation: 50.88,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626727,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:05",
    azimuth: 173,
    elevation: 50.99,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626728,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:10",
    azimuth: 175,
    elevation: 51.08,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626729,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:15",
    azimuth: 177,
    elevation: 51.15,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626730,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:20",
    azimuth: 179,
    elevation: 51.18,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626731,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:25",
    azimuth: 181,
    elevation: 51.19,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626732,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:30",
    azimuth: 183,
    elevation: 51.16,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626733,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:35",
    azimuth: 184,
    elevation: 51.11,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626734,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:40",
    azimuth: 186,
    elevation: 51.03,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626735,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:45",
    azimuth: 188,
    elevation: 50.93,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626736,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:50",
    azimuth: 190,
    elevation: 50.79,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626737,
    customerId: 68,
    projectId: 175,
    time: "04-15 11:55",
    azimuth: 192,
    elevation: 50.63,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626738,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:00",
    azimuth: 194,
    elevation: 50.44,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626739,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:05",
    azimuth: 196,
    elevation: 50.22,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626740,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:10",
    azimuth: 198,
    elevation: 49.98,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626741,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:15",
    azimuth: 200,
    elevation: 49.71,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626742,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:20",
    azimuth: 202,
    elevation: 49.42,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626743,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:25",
    azimuth: 204,
    elevation: 49.1,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626744,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:30",
    azimuth: 205,
    elevation: 48.76,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626745,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:35",
    azimuth: 207,
    elevation: 48.4,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626746,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:40",
    azimuth: 209,
    elevation: 48.01,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626747,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:45",
    azimuth: 211,
    elevation: 47.6,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626748,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:50",
    azimuth: 212,
    elevation: 47.17,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626749,
    customerId: 68,
    projectId: 175,
    time: "04-15 12:55",
    azimuth: 214,
    elevation: 46.72,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626750,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:00",
    azimuth: 216,
    elevation: 46.25,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626751,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:05",
    azimuth: 217,
    elevation: 45.76,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626752,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:10",
    azimuth: 219,
    elevation: 45.25,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626753,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:15",
    azimuth: 220,
    elevation: 44.73,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626754,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:20",
    azimuth: 222,
    elevation: 44.18,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626755,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:25",
    azimuth: 224,
    elevation: 43.62,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626756,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:30",
    azimuth: 225,
    elevation: 43.05,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626757,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:35",
    azimuth: 226,
    elevation: 42.46,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626758,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:40",
    azimuth: 228,
    elevation: 41.85,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 2626759,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:45",
    azimuth: 229,
    elevation: 41.23,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626760,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:50",
    azimuth: 231,
    elevation: 40.6,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626761,
    customerId: 68,
    projectId: 175,
    time: "04-15 13:55",
    azimuth: 232,
    elevation: 39.96,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626762,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:00",
    azimuth: 234,
    elevation: 39.3,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626763,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:05",
    azimuth: 235,
    elevation: 38.63,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626764,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:10",
    azimuth: 236,
    elevation: 37.95,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626765,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:15",
    azimuth: 237,
    elevation: 37.26,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626766,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:20",
    azimuth: 239,
    elevation: 36.56,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626767,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:25",
    azimuth: 240,
    elevation: 35.85,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626768,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:30",
    azimuth: 241,
    elevation: 35.14,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626769,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:35",
    azimuth: 242,
    elevation: 34.41,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626770,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:40",
    azimuth: 244,
    elevation: 33.67,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626771,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:45",
    azimuth: 245,
    elevation: 32.93,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626772,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:50",
    azimuth: 246,
    elevation: 32.18,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626773,
    customerId: 68,
    projectId: 175,
    time: "04-15 14:55",
    azimuth: 247,
    elevation: 31.43,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626774,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:00",
    azimuth: 248,
    elevation: 30.66,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626775,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:05",
    azimuth: 249,
    elevation: 29.9,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626776,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:10",
    azimuth: 250,
    elevation: 29.12,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626777,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:15",
    azimuth: 251,
    elevation: 28.34,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626778,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:20",
    azimuth: 252,
    elevation: 27.56,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626779,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:25",
    azimuth: 254,
    elevation: 26.77,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626780,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:30",
    azimuth: 255,
    elevation: 25.98,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626781,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:35",
    azimuth: 256,
    elevation: 25.18,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626782,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:40",
    azimuth: 257,
    elevation: 24.38,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 2626783,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:45",
    azimuth: 258,
    elevation: 23.57,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626784,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:50",
    azimuth: 259,
    elevation: 22.76,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626785,
    customerId: 68,
    projectId: 175,
    time: "04-15 15:55",
    azimuth: 260,
    elevation: 21.95,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626786,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:00",
    azimuth: 261,
    elevation: 21.14,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626787,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:05",
    azimuth: 262,
    elevation: 20.33,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626788,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:10",
    azimuth: 263,
    elevation: 19.51,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626789,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:15",
    azimuth: 264,
    elevation: 18.69,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626790,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:20",
    azimuth: 265,
    elevation: 17.87,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626791,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:25",
    azimuth: 266,
    elevation: 17.05,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 2626792,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:30",
    azimuth: 267,
    elevation: 16.22,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 2626793,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:35",
    azimuth: 268,
    elevation: 15.4,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 2626794,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:40",
    azimuth: 268,
    elevation: 14.58,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 2626795,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:45",
    azimuth: 269,
    elevation: 13.75,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 2626796,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:50",
    azimuth: 270,
    elevation: 12.93,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 2626797,
    customerId: 68,
    projectId: 175,
    time: "04-15 16:55",
    azimuth: 271,
    elevation: 12.1,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 2626798,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:00",
    azimuth: 272,
    elevation: 11.28,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 2626799,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:05",
    azimuth: 273,
    elevation: 10.45,
    maxRadiation: 360,
    cDirAmi: 6,
  },
  {
    id: 2626800,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:10",
    azimuth: 274,
    elevation: 9.63,
    maxRadiation: 360,
    cDirAmi: 6,
  },
  {
    id: 2626801,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:15",
    azimuth: 275,
    elevation: 8.81,
    maxRadiation: 314,
    cDirAmi: 6,
  },
  {
    id: 2626802,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:20",
    azimuth: 276,
    elevation: 7.99,
    maxRadiation: 314,
    cDirAmi: 6,
  },
  {
    id: 2626803,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:25",
    azimuth: 277,
    elevation: 7.17,
    maxRadiation: 277,
    cDirAmi: 6,
  },
  {
    id: 2626804,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:30",
    azimuth: 278,
    elevation: 6.35,
    maxRadiation: 245,
    cDirAmi: 6,
  },
  {
    id: 2626805,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:35",
    azimuth: 279,
    elevation: 5.53,
    maxRadiation: 219,
    cDirAmi: 6,
  },
  {
    id: 2626806,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:40",
    azimuth: 280,
    elevation: 4.72,
    maxRadiation: 176,
    cDirAmi: 6,
  },
  {
    id: 2626807,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:45",
    azimuth: 281,
    elevation: 3.91,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626808,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:50",
    azimuth: 282,
    elevation: 3.1,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626809,
    customerId: 68,
    projectId: 175,
    time: "04-15 17:55",
    azimuth: 282,
    elevation: 2.29,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626810,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:00",
    azimuth: 283,
    elevation: 1.49,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626811,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:05",
    azimuth: 284,
    elevation: 0.68,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 2626812,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:10",
    azimuth: 285,
    elevation: -0.11,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626813,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:15",
    azimuth: 286,
    elevation: -0.91,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626814,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:20",
    azimuth: 287,
    elevation: -1.7,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626815,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:25",
    azimuth: 288,
    elevation: -2.48,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626816,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:30",
    azimuth: 289,
    elevation: -3.27,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626817,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:35",
    azimuth: 290,
    elevation: -4.04,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626818,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:40",
    azimuth: 291,
    elevation: -4.82,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626819,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:45",
    azimuth: 292,
    elevation: -5.58,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626820,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:50",
    azimuth: 293,
    elevation: -6.35,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626821,
    customerId: 68,
    projectId: 175,
    time: "04-15 18:55",
    azimuth: 294,
    elevation: -7.1,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626822,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:00",
    azimuth: 295,
    elevation: -7.86,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626823,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:05",
    azimuth: 296,
    elevation: -8.6,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626824,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:10",
    azimuth: 297,
    elevation: -9.34,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626825,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:15",
    azimuth: 298,
    elevation: -10.07,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626826,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:20",
    azimuth: 299,
    elevation: -10.8,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626827,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:25",
    azimuth: 300,
    elevation: -11.52,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626828,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:30",
    azimuth: 301,
    elevation: -12.23,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626829,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:35",
    azimuth: 302,
    elevation: -12.93,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626830,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:40",
    azimuth: 303,
    elevation: -13.63,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626831,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:45",
    azimuth: 304,
    elevation: -14.32,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626832,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:50",
    azimuth: 305,
    elevation: -15,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626833,
    customerId: 68,
    projectId: 175,
    time: "04-15 19:55",
    azimuth: 306,
    elevation: -15.67,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626834,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:00",
    azimuth: 307,
    elevation: -16.33,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626835,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:05",
    azimuth: 308,
    elevation: -16.98,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626836,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:10",
    azimuth: 309,
    elevation: -17.62,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626837,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:15",
    azimuth: 310,
    elevation: -18.26,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626838,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:20",
    azimuth: 312,
    elevation: -18.88,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626839,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:25",
    azimuth: 313,
    elevation: -19.49,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626840,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:30",
    azimuth: 314,
    elevation: -20.09,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626841,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:35",
    azimuth: 315,
    elevation: -20.68,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626842,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:40",
    azimuth: 316,
    elevation: -21.25,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626843,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:45",
    azimuth: 317,
    elevation: -21.82,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626844,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:50",
    azimuth: 319,
    elevation: -22.37,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626845,
    customerId: 68,
    projectId: 175,
    time: "04-15 20:55",
    azimuth: 320,
    elevation: -22.91,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626846,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:00",
    azimuth: 321,
    elevation: -23.44,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626847,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:05",
    azimuth: 322,
    elevation: -23.95,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626848,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:10",
    azimuth: 324,
    elevation: -24.45,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626849,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:15",
    azimuth: 325,
    elevation: -24.93,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626850,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:20",
    azimuth: 326,
    elevation: -25.4,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626851,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:25",
    azimuth: 327,
    elevation: -25.85,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626852,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:30",
    azimuth: 328,
    elevation: -26.29,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626853,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:35",
    azimuth: 330,
    elevation: -26.71,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626854,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:40",
    azimuth: 331,
    elevation: -27.12,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626855,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:45",
    azimuth: 332,
    elevation: -27.51,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626856,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:50",
    azimuth: 334,
    elevation: -27.88,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626857,
    customerId: 68,
    projectId: 175,
    time: "04-15 21:55",
    azimuth: 335,
    elevation: -28.24,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626858,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:00",
    azimuth: 336,
    elevation: -28.58,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626859,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:05",
    azimuth: 338,
    elevation: -28.9,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626860,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:10",
    azimuth: 339,
    elevation: -29.2,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626861,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:15",
    azimuth: 340,
    elevation: -29.49,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626862,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:20",
    azimuth: 342,
    elevation: -29.75,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626863,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:25",
    azimuth: 343,
    elevation: -30,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626864,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:30",
    azimuth: 345,
    elevation: -30.22,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626865,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:35",
    azimuth: 346,
    elevation: -30.43,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626866,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:40",
    azimuth: 348,
    elevation: -30.62,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626867,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:45",
    azimuth: 349,
    elevation: -30.79,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626868,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:50",
    azimuth: 350,
    elevation: -30.94,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626869,
    customerId: 68,
    projectId: 175,
    time: "04-15 22:55",
    azimuth: 352,
    elevation: -31.06,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626870,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:00",
    azimuth: 7,
    elevation: -30.9,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626871,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:05",
    azimuth: 5,
    elevation: -30.99,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626872,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:10",
    azimuth: 4,
    elevation: -31.06,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626873,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:15",
    azimuth: 2,
    elevation: -31.1,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626874,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:20",
    azimuth: 1,
    elevation: -31.12,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626875,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:25",
    azimuth: 0,
    elevation: -31.13,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626876,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:30",
    azimuth: 2,
    elevation: -31.11,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626877,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:35",
    azimuth: 3,
    elevation: -31.07,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626878,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:40",
    azimuth: 5,
    elevation: -31.01,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 2626879,
    customerId: 68,
    projectId: 175,
    time: "04-15 23:45",
    azimuth: 6,
    elevation: -30.93,
    maxRadiation: 0,
    cDirAmi: 0,
  },
];

export default project8SunPosition;
