import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: { pagex: 0, pagey: 0 },
  name: null,
  isShowing: true,
  firstIsShowing: false,
  boxIsShowing: true,
  id: null,
  roomShowConfig: true,
  roomPosition: { pagex: 0, pagey: 0 },
  roomName: null,
  roomId: null,
  room: {},
  roomFirstIsShowing: false,
  roomIsShowing: false,
};

const windowConfigurationSlice = createSlice({
  name: "shadingConfigurationWindow",
  initialState,
  reducers: {
    updateWindowPosition: (state, action) => {
      state.firstIsShowing = true;
      state.value.pagex = action.payload[0];
      state.value.pagey = action.payload[1];
      state.name = action.payload[2];
      state.id = action.payload[3];
    },
    toggleWindowIsShowing: (state) => {
      state.isShowing = !state.isShowing;
    },
    toggleBoxForClickOutsideOfBox: (state) => {
      state.boxIsShowing = true;
    },
    setFalse: (state) => {
      state.boxIsShowing = false;
    },
    setTrue: (state) => {
      state.isShowing = true;
    },
    showRoomConfigBox: (state) => {
      state.roomShowConfig = !state.roomShowConfig;
    },
    updateRoomConfigurationPosition: (state, action) => {
      state.roomFirstIsShowing = true;
      state.roomPosition.pagex = action.payload[0];
      state.roomPosition.pagey = action.payload[1];
      state.roomName = action.payload[2];
      state.roomId = action.payload[3];
      state.room = action.payload[4];
    },
    roomGeneralSetTrue: (state) => {
      state.roomIsShowing = true;
    },
  },
});

export const {
  updateWindowPosition,
  toggleWindowIsShowing,
  setFalse,
  setTrue,
  toggleBoxForClickOutsideOfBox,
  showRoomConfigBox,
  updateRoomConfigurationPosition,
  roomGeneralSetTrue,
} = windowConfigurationSlice.actions;

export default windowConfigurationSlice.reducer;
