import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";
const initialState = {
  value: { pagex: 0, pagey: 0 },
  radarAvg: [],
  radarMax: [],
  firstWindIsShowing: false,
  barChart: [],
  time_velocity_more_than_12: 0,
  max_time_velocity_more_than_12: null,
  name: "",
  x: 0,
  y: 0,
  z: 0,
  sensorPlacementPoistion: { pagex: 0, pagey: 0 },
  sensorPlacementData: {},
  firstSensorPlacementIsShowing: false,
  sensorRange: { min: 0, max: 0 },
  sensorLevel: null,
};

const infoWindRiskSlice = createSlice({
  name: "infoWindRisk",
  initialState,
  reducers: {
    setMaxTimeVelMoreThan12: (state, action) => {
      state.max_time_velocity_more_than_12 = action.payload;
    },
    updatePosition: (state, action) => {
      state.value.pagex = action.payload[0];
      state.value.pagey = action.payload[1];
      state.radarAvg = action.payload[2];
      state.radarMax = action.payload[3];
      state.firstWindIsShowing = true;
      state.time_velocity_more_than_12 = action.payload[4];
      state.name = action.payload[5];
      state.x = action.payload[6];
      state.y = action.payload[7];
      state.z = action.payload[8];
    },

    updateSensorPlacementPosition: (state, action) => {
      state.sensorPlacementPoistion.pagex = action.payload[0];
      state.sensorPlacementPoistion.pagey = action.payload[1];
      state.sensorPlacementData = action.payload[2];
      state.sensorLevel = action.payload[3];
    },
    hideWindRisk: (state, action) => {
      state.firstWindIsShowing = false;
    },
    showFirstSensor: (state, action) => {
      state.firstSensorPlacementIsShowing = true;
    },
    hideFirstSensor: (state, action) => {
      state.firstSensorPlacementIsShowing = false;
    },
    hide: (state) => {
      state.value = false;
    },
    updateRangeSensorPlacement: (state, action) => {
      state.sensorRange.min = action.payload[0];
      state.sensorRange.max = action.payload[1];
    },
  },
});

export const {
  updatePosition,
  updateSensorPlacementPosition,
  showFirstSensor,
  hideFirstSensor,
  hideWindRisk,
  updateRangeSensorPlacement,
  setMaxTimeVelMoreThan12,
} = infoWindRiskSlice.actions;

export default infoWindRiskSlice.reducer;
// import { createSlice } from "@reduxjs/toolkit";

// const initialState = {
//   value: { pagex: 0, pagey: 0 },
//   radarAvg: [],
//   radarMax: [],
//   firstWindIsShowing: false,
//   barChart: [],
//   time_velocity_more_than_12: 0,
//   name: "",
//   x: 0,
//   y: 0,
//   z: 0,
//   sensorPlacementPoistion: { pagex: 0, pagey: 0 },
//   sensorPlacementData: {},
//   firstSensorPlacementIsShowing: false,
//   sensorRange: { min: 0, max: 0 },
//   sensorLevel: null,
// };

// const infoWindRiskSlice = createSlice({
//   name: "infoWindRisk",
//   initialState,
//   reducers: {
//     updatePosition: (state, { payload }) => {
//       const {
//         pagex,
//         pagey,
//         radarAvg,
//         radarMax,
//         time_velocity_more_than_12,
//         name,
//         x,
//         y,
//         z,
//       } = payload;
//       state.value = { pagex, pagey };
//       state.radarAvg = radarAvg;
//       state.radarMax = radarMax;
//       state.firstWindIsShowing = true;
//       state.time_velocity_more_than_12 = time_velocity_more_than_12;
//       state.name = name;
//       state.x = x;
//       state.y = y;
//       state.z = z;
//     },
//     updateSensorPlacementPosition: (state, { payload }) => {
//       const { pagex, pagey, sensorPlacementData, sensorLevel } = payload;
//       state.sensorPlacementPoistion = { pagex, pagey };
//       state.sensorPlacementData = sensorPlacementData;
//       state.sensorLevel = sensorLevel;
//     },
//     hideWindRisk: (state) => {
//       state.firstWindIsShowing = false;
//     },
//     showFirstSensor: (state) => {
//       state.firstSensorPlacementIsShowing = true;
//     },
//     hideFirstSensor: (state) => {
//       state.firstSensorPlacementIsShowing = false;
//     },
//     hide: (state) => {
//       state.value = { pagex: 0, pagey: 0 };
//     },
//     updateRangeSensorPlacement: (state, { payload }) => {
//       const { min, max } = payload;
//       state.sensorRange = { min, max };
//     },
//   },
// });

// export const {
//   updatePosition,
//   updateSensorPlacementPosition,
//   showFirstSensor,
//   hideFirstSensor,
//   hideWindRisk,
//   updateRangeSensorPlacement,
// } = infoWindRiskSlice.actions;

// export default infoWindRiskSlice.reducer;
