import React, { useLayoutEffect, useRef } from "react";
import * as THREE from "three";

import { arrayScale, arraysSubtract } from "../utility/dataManipulation";
import { FlatMeshGeometry } from "./Geometry";
import { FlatMeshMaterial } from "./Material";


const BasePolygonInstancedMesh = ({ Geometry, Material, objects,
    setColor, updateDependencyArray = [],
    isValid = () => true, getObjectPosition,
    groupProps }) => {
    const count = objects?.length;
    var instancedMeshRef = useRef();

    useLayoutEffect(() => {
        let temp = new THREE.Object3D();
        if (count) {
            objects.forEach((object, i) => {
                const scaleFactor = [1, 1, 1];
                temp.scale.set(scaleFactor[0], scaleFactor[1], scaleFactor[2]);
                if (!isValid(object)) {
                    temp.scale.set(0, 0, 0);
                }
                const position = getObjectPosition(object)
                temp.position.set(position[0], position[1], position[2]);
                temp.updateMatrix();
                instancedMeshRef.current.setMatrixAt(i, temp.matrix);

                const color = setColor(object)

                instancedMeshRef.current?.setColorAt(i, color);
            });
            instancedMeshRef.current.instanceMatrix.needsUpdate = true;
        }
    }, [...updateDependencyArray]);
    return (
        <group rotation-x={Math.PI * -0.5} dispose={null} {...groupProps}>
            <instancedMesh ref={instancedMeshRef} args={[null, null, count]}>
                <Geometry />
                <Material />
            </instancedMesh>
        </group>
    );
}

export const SimilarPolygonObjectsMesh = (props) => {
    const baseObject = props?.objects?.[0]
    const Material = FlatMeshMaterial
    const Geometry = () => FlatMeshGeometry(baseObject?.polygon)

    const getObjectPosition = (object) => {
        const scaleFactor = [1, 1, 1];
        const arr1 = new Float32Array(object?.polygon);
        const arr2 = new Float32Array(baseObject?.polygon);
        const position = arraysSubtract(arr1, arrayScale(arr2, scaleFactor));
        return position
    }
    return (
        <BasePolygonInstancedMesh
            baseObject={baseObject}
            Material={Material}
            Geometry={Geometry}
            getObjectPosition={getObjectPosition}
            {...props}
        />
    )
}


const BaseSphereInstancedMesh = ({ Geometry, Material, objects,
    setColor, getObjectPosition,
    groupProps }) => {
    const groupRef = useRef();
    const count = objects?.length;
    const instancedMesh = new THREE.InstancedMesh(Geometry, Material, count);
    if (count) {
        objects.forEach((object, i) => {
            const matrix = new THREE.Matrix4();
            const position = getObjectPosition(object)
            matrix.setPosition(position[0], position[1], position[2]);
            instancedMesh.setMatrixAt(i, matrix);
            const color = setColor(object)
            instancedMesh?.setColorAt(i, color);
        });
        instancedMesh.instanceMatrix.needsUpdate = true;
        groupRef.current = instancedMesh;
    }
    return (
        <group
            rotation-x={Math.PI * -0.5}
            dispose={null}
            ref={groupRef}
            {...groupProps}
        >
            <primitive object={instancedMesh} />
        </group>
    );
}


export const SphereInstancedMesh = (props) => {
    const Material = new THREE.MeshLambertMaterial({ color: "white" });
    const Geometry = new THREE.SphereGeometry(props?.radius ?? 0.26, 20, 10)

    const highlightMaterial = new THREE.MeshLambertMaterial({
        transparent: true,
        opacity: 0.6
    });
    const highlightGeometry = new THREE.SphereGeometry(props?.highlightProps?.radius ?? 0.5, 20, 10)

    const getObjectPosition = (object) => {
        const position = [object?.utm_x, object?.utm_y, object?.utm_z]
        return position
    }
    if (props?.objects?.length)
        return (
            <>
                <BaseSphereInstancedMesh
                    Material={Material}
                    Geometry={Geometry}
                    getObjectPosition={getObjectPosition}
                    {...props}
                />

                {props?.highlightProps?.showHighlight && props?.highlightProps?.highlightedObject &&
                    <BaseSphereInstancedMesh
                        Material={highlightMaterial}
                        Geometry={highlightGeometry}
                        getObjectPosition={getObjectPosition}
                        objects={props?.highlightProps?.highlightedObject}
                        setColor={() => new THREE.Color('yellow')}
                        {...props.highlightProps}
                    />
                }
            </>
        )
}

