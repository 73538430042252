import React from "react";
import "./colorBar.css";
import { useSelector, useDispatch } from "react-redux";
import { CustomColorBar } from "./ColorBarComponent";
import { useWindDirectionColorBar } from "../CustomHooks";

const VirtualWindColorBarOld = () => {
  const {
    rangeList: [minValue, maxValue],
    value: { checkStatus: windSelector },
  } = useSelector((state) => state.windDirection);
  const avgValue = (minValue + maxValue) / 2;
  const anyTrue = windSelector.includes(true);
  return (
    anyTrue && (
      <div className="background-div ">
        <div className="otherColor"></div>
        <div>
          <p className="zero">{Math.trunc(minValue)}</p>
        </div>
        <div>
          <p className="avg">{Math.trunc(avgValue)}</p>
        </div>
        <div>
          <p className="max">{Math.trunc(maxValue)}</p>
        </div>
        <div style={{ fontSize: "12px", fontWeight: "bold" }}> m/s</div>
      </div>
    )
  );
};

const VirtualWindColorBar = () => {
  const { minValue, maxValue, isVisible } = useWindDirectionColorBar();
  return (
    <CustomColorBar
      colorbarClassName="otherColor"
      category="Wind Sensors"
      subcategory={"0"}
      unit="m/s"
      dataMin={minValue}
      dataMax={maxValue}
      isVisible={isVisible}
    />
  );
};

export default VirtualWindColorBar;
