const zoneOneProject14100 = [
  {
    position: [-33.3855157, 3.3169522, 20.8740369],
    "overall score": 0.6928050806,
    "0": {
      velocity: [0.682995418, 0.8310171025, 0.9872178596],
      direction: [-4.4908221635, -0.6720205667, 7.6401310585],
      "speed factor": 1.376478195,
      "Uz factor": 0.996448603,
      "Directional variance factor": 0.9402648385,
      "Velocity stability factor": 0.6620684823,
      "Direction stability factor": 0.9663029077,
      score: 0.8837347841,
    },
    "22.5": {
      velocity: [0.225243913, 0.6625036712, 1.127887655],
      direction: [-23.7245851588, 17.6734953798, 53.3155396271],
      "speed factor": 0.8765610911,
      "Uz factor": 0.9999139103,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2373398376,
      "Direction stability factor": 0.7859996534,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3600880519, 0.6959284599, 1.023848504],
      direction: [-20.678844624, -4.1566107262, 35.1955476053],
      "speed factor": 1.1216905276,
      "Uz factor": 0.9999492274,
      "Directional variance factor": 0.630523491,
      "Velocity stability factor": 0.2845491289,
      "Direction stability factor": 0.8447933549,
      score: 0.6511648324,
    },
    "67.5": {
      velocity: [0.6796243372, 0.9030173249, 0.9813767235],
      direction: [-6.3112959904, -3.2422000251, 0.8810733253],
      "speed factor": 1.2272198456,
      "Uz factor": 0.9988358952,
      "Directional variance factor": 0.7118044422,
      "Velocity stability factor": 0.693394718,
      "Direction stability factor": 0.9800211963,
      score: 0.8413103882,
    },
    "90.0": {
      velocity: [0.3467059996, 0.714805621, 1.0116077958],
      direction: [-25.0551332914, 0.315598946, 29.1961491306],
      "speed factor": 1.0325975174,
      "Uz factor": 0.999479752,
      "Directional variance factor": 0.9719467604,
      "Velocity stability factor": 0.3241698214,
      "Direction stability factor": 0.8493019933,
      score: 0.7486801421,
    },
    "112.5": {
      velocity: [1.2065492859, 1.2359649495, 1.2762350731],
      direction: [2.1139014094, 3.6245023347, 5.0245506099],
      "speed factor": 1.1812270528,
      "Uz factor": 0.9948458577,
      "Directional variance factor": 0.6778220147,
      "Velocity stability factor": 0.9435662267,
      "Direction stability factor": 0.9919148633,
      score: 0.901304492,
    },
    "135.0": {
      velocity: [1.1325794084, 1.1413806958, 1.1529138707],
      direction: [-5.9237878143, -5.2628797417, -4.3535534049],
      "speed factor": 1.3202417181,
      "Uz factor": 0.9914794318,
      "Directional variance factor": 0.5321884674,
      "Velocity stability factor": 0.9792866034,
      "Direction stability factor": 0.9956382378,
      score: 0.8756878866,
    },
    "157.5": {
      velocity: [0.0959305024, 0.5304249304, 0.8848621904],
      direction: [-121.7783723907, -19.1355530059, 62.6829489606],
      "speed factor": 0.5087469043,
      "Uz factor": 0.9910281065,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4114142498,
      "Direction stability factor": 0.4876074407,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1968746119, 0.5848550021, 0.9639893487],
      direction: [-107.8676497424, -0.719219415, 144.4379977563],
      "speed factor": 0.8185813119,
      "Uz factor": 0.9960541921,
      "Directional variance factor": 0.9360693853,
      "Velocity stability factor": 0.2499906707,
      "Direction stability factor": 0.2991509792,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0740232731, 1.085767305, 1.0950302595],
      direction: [-8.7718325086, -8.040475233, -7.4486486849],
      "speed factor": 1.2912917489,
      "Uz factor": 0.9931078583,
      "Directional variance factor": 0.2852910904,
      "Velocity stability factor": 0.9832178999,
      "Direction stability factor": 0.9963244894,
      score: 0.8152894923,
    },
    "225.0": {
      velocity: [0.5562886491, 0.8358455297, 0.9406994134],
      direction: [-2.5995069679, 4.6090298777, 13.6482346379],
      "speed factor": 1.0121662532,
      "Uz factor": 0.9987240344,
      "Directional variance factor": 0.5903084553,
      "Velocity stability factor": 0.6389126243,
      "Direction stability factor": 0.9548673844,
      score: 0.7847389621,
    },
    "247.5": {
      velocity: [0.676030246, 0.8650827, 0.9879681],
      direction: [-13.6631634041, 2.4989139534, 12.942334565],
      "speed factor": 1.0838306272,
      "Uz factor": 0.9897884645,
      "Directional variance factor": 0.7778743152,
      "Velocity stability factor": 0.7526443089,
      "Direction stability factor": 0.926095839,
      score: 0.8456775755,
    },
    "270.0": {
      velocity: [0.9750742788, 1.020729862, 1.0823175599],
      direction: [-1.9653447052, 0.0460966212, 1.7929650056],
      "speed factor": 1.4315286638,
      "Uz factor": 0.9938093375,
      "Directional variance factor": 0.9959025226,
      "Velocity stability factor": 0.9085568558,
      "Direction stability factor": 0.9895602508,
      score: 0.97089497,
    },
    "292.5": {
      velocity: [1.1284165228, 1.1493189911, 1.1649185292],
      direction: [3.7751670672, 4.7532666391, 5.7642565953],
      "speed factor": 1.1487538443,
      "Uz factor": 0.9924694648,
      "Directional variance factor": 0.5774874099,
      "Velocity stability factor": 0.9627864343,
      "Direction stability factor": 0.9944747513,
      score: 0.8823058367,
    },
    "315.0": {
      velocity: [0.9362960032, 0.9434686593, 0.9528969487],
      direction: [-0.1024622313, 0.1150263279, 0.3281668151],
      "speed factor": 1.1724909888,
      "Uz factor": 0.9960936107,
      "Directional variance factor": 0.9897754375,
      "Velocity stability factor": 0.9732796231,
      "Direction stability factor": 0.9988038082,
      score: 0.9901656693,
    },
    "337.5": {
      velocity: [1.1239938033, 1.1576384262, 1.2093501649],
      direction: [-4.8819851522, -3.6676373795, -2.5035248583],
      "speed factor": 1.1573702283,
      "Uz factor": 0.9980662552,
      "Directional variance factor": 0.6739877885,
      "Velocity stability factor": 0.9149309163,
      "Direction stability factor": 0.9933931659,
      score: 0.8939262591,
    },
    id: "IDN:3638.0",
    zone: 1,
  },
  {
    position: [-31.64406464, 3.386945471, 20.8740369],
    "overall score": 0.6913024088,
    "0": {
      velocity: [0.6782677152, 0.7943247942, 0.9558798647],
      direction: [-2.7702640374, 1.4308263283, 9.7068310192],
      "speed factor": 1.3157018739,
      "Uz factor": 0.9961989671,
      "Directional variance factor": 0.8728154375,
      "Velocity stability factor": 0.6916273024,
      "Direction stability factor": 0.9653414026,
      score: 0.8737813863,
    },
    "22.5": {
      velocity: [0.2207775542, 0.6675225088, 1.1531424884],
      direction: [-42.1160832603, 15.5532792062, 45.0418855532],
      "speed factor": 0.883201534,
      "Uz factor": 0.9999796643,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2122278601,
      "Direction stability factor": 0.7578945311,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3363980407, 0.6692452637, 1.041004116],
      direction: [-24.7711942015, -4.4348285713, 39.7839050178],
      "speed factor": 1.078682819,
      "Uz factor": 0.9999857405,
      "Directional variance factor": 0.6057930159,
      "Velocity stability factor": 0.2405226482,
      "Direction stability factor": 0.8206802799,
      score: 0.621919056,
    },
    "67.5": {
      velocity: [0.6647580848, 0.9015042472, 0.9787482988],
      direction: [-5.8941916397, -3.1890647377, 3.3429589311],
      "speed factor": 1.2251635407,
      "Uz factor": 0.9988335921,
      "Directional variance factor": 0.7165275789,
      "Velocity stability factor": 0.6809600769,
      "Direction stability factor": 0.9743412484,
      score: 0.8365425382,
    },
    "90.0": {
      velocity: [0.3001331134, 0.7041535441, 0.996296445],
      direction: [-20.275186707, 0.6387225916, 44.2668328613],
      "speed factor": 1.0172096863,
      "Uz factor": 0.9996093612,
      "Directional variance factor": 0.9432246585,
      "Velocity stability factor": 0.2923944687,
      "Direction stability factor": 0.8207166123,
      score: 0.719263088,
    },
    "112.5": {
      velocity: [1.1893537768, 1.2211202602, 1.2667503012],
      direction: [2.6756842651, 4.535431757, 6.0669876812],
      "speed factor": 1.1670397989,
      "Uz factor": 0.9942438502,
      "Directional variance factor": 0.5968505105,
      "Velocity stability factor": 0.9373218257,
      "Direction stability factor": 0.9905797127,
      score: 0.8788329404,
    },
    "135.0": {
      velocity: [1.1334349966, 1.1425102354, 1.155393286],
      direction: [-4.999483241, -4.4788956505, -3.742036049],
      "speed factor": 1.3215482632,
      "Uz factor": 0.9877831188,
      "Directional variance factor": 0.6018759422,
      "Velocity stability factor": 0.9776325162,
      "Direction stability factor": 0.9965070911,
      score: 0.8931306602,
    },
    "157.5": {
      velocity: [0.1066913287, 0.5511936025, 0.931576535],
      direction: [-96.1954722317, -17.8872275977, 33.4927802674],
      "speed factor": 0.528666778,
      "Uz factor": 0.9958972501,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3845909787,
      "Direction stability factor": 0.6397548542,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1207022574, 0.568349055, 0.9242366526],
      direction: [-71.2844589475, 0.7660384465, 76.2858686489],
      "speed factor": 0.7954790733,
      "Uz factor": 0.9954895659,
      "Directional variance factor": 0.9319076936,
      "Velocity stability factor": 0.2143831112,
      "Direction stability factor": 0.5900824233,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0879062483, 1.1041201593, 1.1170437964],
      direction: [-9.1272088577, -8.466187567, -7.9261118357],
      "speed factor": 1.3131186074,
      "Uz factor": 0.9847893954,
      "Directional variance factor": 0.247449994,
      "Velocity stability factor": 0.9767225417,
      "Direction stability factor": 0.9966636194,
      score: 0.8043749436,
    },
    "225.0": {
      velocity: [0.7247865333, 0.8553765881, 0.9578177253],
      direction: [-11.0410994566, 0.0381024129, 5.0953580362],
      "speed factor": 1.0358173675,
      "Uz factor": 0.9981084848,
      "Directional variance factor": 0.9966131189,
      "Velocity stability factor": 0.7811075302,
      "Direction stability factor": 0.955176507,
      score: 0.9220184157,
    },
    "247.5": {
      velocity: [0.5377830586, 0.7996421181, 0.9813503794],
      direction: [-22.8946026485, 1.9219602324, 17.4230642117],
      "speed factor": 1.0018425041,
      "Uz factor": 0.9921048476,
      "Directional variance factor": 0.8291590905,
      "Velocity stability factor": 0.6482667949,
      "Direction stability factor": 0.8880064809,
      score: 0.8133597118,
    },
    "270.0": {
      velocity: [0.9355528909, 0.9941572187, 1.0714369822],
      direction: [-2.225703384, 0.2935256744, 2.4645633731],
      "speed factor": 1.3942617023,
      "Uz factor": 0.9949841455,
      "Directional variance factor": 0.9739088289,
      "Velocity stability factor": 0.884135692,
      "Direction stability factor": 0.9869714812,
      score: 0.9579968709,
    },
    "292.5": {
      velocity: [1.1641005525, 1.1872708321, 1.2063747242],
      direction: [2.2600425552, 3.3349174618, 4.2985394042],
      "speed factor": 1.1866870236,
      "Uz factor": 0.9913328972,
      "Directional variance factor": 0.7035628923,
      "Velocity stability factor": 0.9569017481,
      "Direction stability factor": 0.9943375088,
      score: 0.9122849145,
    },
    "315.0": {
      velocity: [0.9087948812, 0.9168436122, 0.9297891206],
      direction: [-1.3576728109, -1.0759507706, -0.8551957457],
      "speed factor": 1.1394028439,
      "Uz factor": 0.9975359274,
      "Directional variance factor": 0.9043599315,
      "Velocity stability factor": 0.966208311,
      "Direction stability factor": 0.9986042304,
      score: 0.9669441758,
    },
    "337.5": {
      velocity: [1.1272714971, 1.1634203804, 1.20813576],
      direction: [-6.4727820985, -5.1949988132, -3.5800262326],
      "speed factor": 1.163150843,
      "Uz factor": 0.9997453941,
      "Directional variance factor": 0.5382223277,
      "Velocity stability factor": 0.9194078963,
      "Direction stability factor": 0.991964567,
      score: 0.8603898395,
    },
    id: "IDN:3639.0",
    zone: 1,
  },
  {
    position: [-31.8936219, 5.3169522, 20.8740369],
    "overall score": 0.6848067079,
    "0": {
      velocity: [0.6723643488, 0.7925939568, 0.9473772487],
      direction: [-1.7600979207, 2.4660705174, 9.3636913456],
      "speed factor": 1.3128349534,
      "Uz factor": 0.9972789488,
      "Directional variance factor": 0.7807937318,
      "Velocity stability factor": 0.6945145592,
      "Direction stability factor": 0.9691005854,
      score: 0.8533773654,
    },
    "22.5": {
      velocity: [0.1354870675, 0.6558204862, 1.0858532625],
      direction: [-31.265381723, 16.5391318135, 58.3331909123],
      "speed factor": 0.867718544,
      "Uz factor": 0.9998633772,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1970182665,
      "Direction stability factor": 0.751115076,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.4166759491, 0.7234477044, 1.0418122843],
      direction: [-20.2336469948, -5.8388554953, 26.7843923279],
      "speed factor": 1.1660457706,
      "Uz factor": 0.9999993982,
      "Directional variance factor": 0.4809906226,
      "Velocity stability factor": 0.3261811031,
      "Direction stability factor": 0.8693943352,
      score: 0.636490099,
    },
    "67.5": {
      velocity: [0.5904510041, 0.86375962, 0.9545558747],
      direction: [-8.0584026649, -3.4709688324, 3.1635786021],
      "speed factor": 1.1738677855,
      "Uz factor": 0.9992315354,
      "Directional variance factor": 0.6914694371,
      "Velocity stability factor": 0.6300394575,
      "Direction stability factor": 0.9688278298,
      score: 0.8147911386,
    },
    "90.0": {
      velocity: [0.40992395, 0.7402159357, 1.03222842],
      direction: [-16.0590155126, 1.5993205853, 45.0098303242],
      "speed factor": 1.0693048783,
      "Uz factor": 0.9999961474,
      "Directional variance factor": 0.8578381702,
      "Velocity stability factor": 0.3674672809,
      "Direction stability factor": 0.8303643171,
      score: 0.7215085213,
    },
    "112.5": {
      velocity: [1.1499465582, 1.2034352541, 1.2814427644],
      direction: [3.5748998359, 7.1653440152, 9.9819533925],
      "speed factor": 1.1501380189,
      "Uz factor": 0.9963918168,
      "Directional variance factor": 0.363080532,
      "Velocity stability factor": 0.8935101777,
      "Direction stability factor": 0.982202629,
      score: 0.8052489919,
    },
    "135.0": {
      velocity: [1.1576328855, 1.1688990382, 1.1845608881],
      direction: [-2.4419704882, -1.8712037993, -1.0410813411],
      "speed factor": 1.3520723456,
      "Uz factor": 0.990904903,
      "Directional variance factor": 0.8336707734,
      "Velocity stability factor": 0.9725701919,
      "Direction stability factor": 0.9961086413,
      score: 0.949614562,
    },
    "157.5": {
      velocity: [0.0608397916, 0.5706624257, 0.9374843392],
      direction: [-100.1749180436, -18.0494813958, 39.6878222333],
      "speed factor": 0.5473399266,
      "Uz factor": 0.9960074759,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3459757079,
      "Direction stability factor": 0.6114923881,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0957367423, 0.568997274, 0.941137395],
      direction: [-59.4369476137, 0.4279617956, 97.1551226192],
      "speed factor": 0.7963863408,
      "Uz factor": 0.9964814958,
      "Directional variance factor": 0.9619589515,
      "Velocity stability factor": 0.1734504031,
      "Direction stability factor": 0.5650220271,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1494695011, 1.1663134465, 1.1796388933],
      direction: [-9.3119969069, -8.3520753383, -7.5350860094],
      "speed factor": 1.3870844362,
      "Uz factor": 0.9961038497,
      "Directional variance factor": 0.2575933033,
      "Velocity stability factor": 0.9758982202,
      "Direction stability factor": 0.9950641364,
      score: 0.8059049491,
    },
    "225.0": {
      velocity: [0.6515349389, 0.8261273681, 0.9838622027],
      direction: [-28.6126129172, -2.0514009393, 5.743028797],
      "speed factor": 1.0003980558,
      "Uz factor": 0.9999687697,
      "Directional variance factor": 0.8176532498,
      "Velocity stability factor": 0.687836058,
      "Direction stability factor": 0.9045676619,
      score: 0.8286561579,
    },
    "247.5": {
      velocity: [0.5760241738, 0.8405418649, 0.9987801583],
      direction: [-10.6954859513, 2.8822033975, 15.3666639945],
      "speed factor": 1.0530843082,
      "Uz factor": 0.9925608112,
      "Directional variance factor": 0.7438041424,
      "Velocity stability factor": 0.6647694488,
      "Direction stability factor": 0.927605139,
      score: 0.8159459673,
    },
    "270.0": {
      velocity: [1.0112529778, 1.0544249427, 1.1006825482],
      direction: [-2.4149796067, -1.0867306885, 0.6506076994],
      "speed factor": 1.4787845301,
      "Uz factor": 0.9933898773,
      "Directional variance factor": 0.9034017166,
      "Velocity stability factor": 0.9237460751,
      "Direction stability factor": 0.9914844797,
      score: 0.9525291878,
    },
    "292.5": {
      velocity: [1.1741613703, 1.1891128038, 1.20312673],
      direction: [-0.0885671716, 0.6519983663, 1.4111828513],
      "speed factor": 1.1885280895,
      "Uz factor": 0.9866208887,
      "Directional variance factor": 0.9420445897,
      "Velocity stability factor": 0.9704699982,
      "Direction stability factor": 0.9958340277,
      score: 0.9760456608,
    },
    "315.0": {
      velocity: [0.8789889906, 0.8881790214, 0.9009812852],
      direction: [-2.3310120247, -2.0290111926, -1.7977193336],
      "speed factor": 1.103780066,
      "Uz factor": 0.9971067618,
      "Directional variance factor": 0.8196434495,
      "Velocity stability factor": 0.9646018717,
      "Direction stability factor": 0.9985186314,
      score: 0.945320646,
    },
    "337.5": {
      velocity: [1.1092375159, 1.1512974548, 1.187545026],
      direction: [-6.9111796002, -5.6203961961, -3.9467674119],
      "speed factor": 1.151030726,
      "Uz factor": 0.9998181285,
      "Directional variance factor": 0.500409227,
      "Velocity stability factor": 0.9219560439,
      "Direction stability factor": 0.9917655217,
      score: 0.8514740786,
    },
    id: "IDN:2134.0",
    zone: 1,
  },
  {
    position: [-33.5306889, 6.9288932, 20.8740369],
    "overall score": 0.6430208296,
    "0": {
      velocity: [0.6924479514, 0.8348908266, 1.0070930128],
      direction: [-2.0094085363, 1.7327845925, 7.9838198855],
      "speed factor": 1.3828945453,
      "Uz factor": 0.998048849,
      "Directional variance factor": 0.8459747029,
      "Velocity stability factor": 0.6504909941,
      "Direction stability factor": 0.9722410322,
      score: 0.8602369403,
    },
    "22.5": {
      velocity: [0.1401851178, 0.6351637021, 1.0222826408],
      direction: [-48.6795076641, 17.5742644436, 86.0961852244],
      "speed factor": 0.8403874755,
      "Uz factor": 0.9998721967,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2546997127,
      "Direction stability factor": 0.6256230753,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.4003835835, 0.749296441, 1.033632479],
      direction: [-16.7594013008, -6.2075064027, 19.9187932171],
      "speed factor": 1.2077085056,
      "Uz factor": 0.9993909898,
      "Directional variance factor": 0.4482216531,
      "Velocity stability factor": 0.3174367764,
      "Direction stability factor": 0.8981161263,
      score: 0.6404726705,
    },
    "67.5": {
      velocity: [0.5903333537, 0.8530168169, 0.9701114278],
      direction: [-10.5538424311, -4.0386346853, 7.364237756],
      "speed factor": 1.1592680865,
      "Uz factor": 0.9997138749,
      "Directional variance factor": 0.6410102502,
      "Velocity stability factor": 0.6141141916,
      "Direction stability factor": 0.950227555,
      score: 0.788894888,
    },
    "90.0": {
      velocity: [0.5031000861, 0.8003814499, 1.0598873826],
      direction: [-25.29352273, 1.6821212346, 28.3721288459],
      "speed factor": 1.1562190809,
      "Uz factor": 0.9999147915,
      "Directional variance factor": 0.8504781125,
      "Velocity stability factor": 0.4340613002,
      "Direction stability factor": 0.8509287456,
      score: 0.746599226,
    },
    "112.5": {
      velocity: [1.0479383252, 1.1829938028, 1.2792371492],
      direction: [2.5306177826, 7.7432071733, 11.6690354466],
      "speed factor": 1.13060187,
      "Uz factor": 0.9978948719,
      "Directional variance factor": 0.3117149179,
      "Velocity stability factor": 0.8126868342,
      "Direction stability factor": 0.9746155065,
      score: 0.7684081913,
    },
    "135.0": {
      velocity: [1.1255514536, 1.142392646, 1.1637460595],
      direction: [-1.5940510363, -0.7138274189, 0.4880789586],
      "speed factor": 1.3214122469,
      "Uz factor": 0.9954128147,
      "Directional variance factor": 0.9365486739,
      "Velocity stability factor": 0.9610936346,
      "Direction stability factor": 0.9942163056,
      score: 0.9715187299,
    },
    "157.5": {
      velocity: [0.0910556644, 0.5874051968, 0.9142695636],
      direction: [-112.8307715959, -20.3001227436, 52.874099713],
      "speed factor": 0.5633984345,
      "Uz factor": 0.9950655279,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3858378642,
      "Direction stability factor": 0.5397086908,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.2239291178, 0.5830062176, 1.0212307661],
      direction: [-105.5356433561, -0.8175973061, 108.5128143726],
      "speed factor": 0.8159936954,
      "Uz factor": 0.9992155343,
      "Directional variance factor": 0.9273246839,
      "Velocity stability factor": 0.2204768781,
      "Direction stability factor": 0.4054209508,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1302418185, 1.1416338217, 1.148336513],
      direction: [-5.7723455299, -4.6204831872, -3.6508018147],
      "speed factor": 1.3577332154,
      "Uz factor": 0.9994104465,
      "Directional variance factor": 0.5892903834,
      "Velocity stability factor": 0.985544477,
      "Direction stability factor": 0.994106823,
      score: 0.8907621266,
    },
    "225.0": {
      velocity: [0.3355986996, 0.687641475, 0.8468102938],
      direction: [-43.9358403605, -0.7874328139, 11.9339595963],
      "speed factor": 0.8326987112,
      "Uz factor": 0.9966070111,
      "Directional variance factor": 0.9300059721,
      "Velocity stability factor": 0.5198051924,
      "Direction stability factor": 0.8448061112,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.720496067, 0.9245204272, 1.0099126517],
      direction: [-2.0405725214, 2.569367585, 8.6256204044],
      "speed factor": 1.1582979922,
      "Uz factor": 0.9896244086,
      "Directional variance factor": 0.7716117702,
      "Velocity stability factor": 0.7705028793,
      "Direction stability factor": 0.9703716863,
      score: 0.8707145055,
    },
    "270.0": {
      velocity: [1.0445615183, 1.0787506253, 1.1092839111],
      direction: [-3.5867184283, -2.4419098756, -1.0877820487],
      "speed factor": 1.5129002282,
      "Uz factor": 0.9931731802,
      "Directional variance factor": 0.7829413444,
      "Velocity stability factor": 0.9448131479,
      "Direction stability factor": 0.9930585101,
      score: 0.9284678781,
    },
    "292.5": {
      velocity: [1.1340141756, 1.1438900668, 1.1537665065],
      direction: [0.3874553087, 1.0487581775, 1.6148508652],
      "speed factor": 1.1433275896,
      "Uz factor": 0.9807427373,
      "Directional variance factor": 0.9067770509,
      "Velocity stability factor": 0.9798626231,
      "Direction stability factor": 0.9965905679,
      score: 0.9699552024,
    },
    "315.0": {
      velocity: [0.871803669, 0.8799031738, 0.891119521],
      direction: [-1.490746174, -1.2262091222, -1.0165228445],
      "speed factor": 1.0934952975,
      "Uz factor": 0.9950261766,
      "Directional variance factor": 0.8910036336,
      "Velocity stability factor": 0.9689097922,
      "Direction stability factor": 0.998682713,
      score: 0.9643197129,
    },
    "337.5": {
      velocity: [1.1081554748, 1.1460221322, 1.1702185908],
      direction: [-5.2213471998, -4.1960320129, -2.8395267905],
      "speed factor": 1.1457566255,
      "Uz factor": 0.9985455337,
      "Directional variance factor": 0.6270193766,
      "Velocity stability factor": 0.9381457655,
      "Direction stability factor": 0.9933838322,
      score: 0.8879832016,
    },
    id: "IDN:3656.0",
    zone: 1,
  },
  {
    position: [-33.4581023, 5.1229227, 20.8740369],
    "overall score": 0.6420802917,
    "0": {
      velocity: [0.6855975264, 0.8291626593, 0.9970116135],
      direction: [-2.842934399, 0.5263867999, 8.0018587406],
      "speed factor": 1.3734065367,
      "Uz factor": 0.9972062454,
      "Directional variance factor": 0.9532100622,
      "Velocity stability factor": 0.6540799735,
      "Direction stability factor": 0.9698755746,
      score: 0.8867602962,
    },
    "22.5": {
      velocity: [0.1076172767, 0.6500211155, 1.0455865607],
      direction: [-36.5099246231, 17.8441681949, 58.338883085],
      "speed factor": 0.8600453748,
      "Uz factor": 0.9998309032,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2074926428,
      "Direction stability factor": 0.7365310897,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.4204280544, 0.7267266136, 1.0439723465],
      direction: [-20.4978815218, -5.5981551411, 26.8338094457],
      "speed factor": 1.1713306836,
      "Uz factor": 0.9999876418,
      "Directional variance factor": 0.5023862097,
      "Velocity stability factor": 0.3278971268,
      "Direction stability factor": 0.8685230806,
      score: 0.6418323744,
    },
    "67.5": {
      velocity: [0.6076947994, 0.8726326539, 0.9638695232],
      direction: [-8.0568343478, -3.4119824222, 2.2484290757],
      "speed factor": 1.1859264282,
      "Uz factor": 0.9993561594,
      "Directional variance factor": 0.6967126736,
      "Velocity stability factor": 0.6380971399,
      "Direction stability factor": 0.9713742683,
      score: 0.8193895875,
    },
    "90.0": {
      velocity: [0.4568834206, 0.7524783419, 1.0497453527],
      direction: [-19.561587433, 1.170631504, 36.1949122266],
      "speed factor": 1.087018967,
      "Uz factor": 0.9999668107,
      "Directional variance factor": 0.8959438663,
      "Velocity stability factor": 0.3973937388,
      "Direction stability factor": 0.8451208343,
      score: 0.7458948184,
    },
    "112.5": {
      velocity: [1.183136835, 1.2220313504, 1.2812307283],
      direction: [3.7132724867, 6.3409375781, 8.4100664057],
      "speed factor": 1.1679105391,
      "Uz factor": 0.9959937801,
      "Directional variance factor": 0.4363611042,
      "Velocity stability factor": 0.9205604362,
      "Direction stability factor": 0.9869533502,
      score: 0.8327070602,
    },
    "135.0": {
      velocity: [1.1472575514, 1.1573106728, 1.171060106],
      direction: [-3.7206083348, -2.8868288341, -1.7209652153],
      "speed factor": 1.3386680155,
      "Uz factor": 0.9925785742,
      "Directional variance factor": 0.7433929925,
      "Velocity stability factor": 0.975753883,
      "Direction stability factor": 0.9944454358,
      score: 0.9270094368,
    },
    "157.5": {
      velocity: [0.1049180531, 0.5564054222, 0.9013891782],
      direction: [-100.6784034235, -20.0844850562, 60.5505076814],
      "speed factor": 0.5336655949,
      "Uz factor": 0.9941110373,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4057894215,
      "Direction stability factor": 0.5521419136,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.2009982085, 0.5862484825, 0.9650858657],
      direction: [-90.705141686, -0.797462184, 112.1332996737],
      "speed factor": 0.8205316706,
      "Uz factor": 0.997871604,
      "Directional variance factor": 0.9291144725,
      "Velocity stability factor": 0.2529502513,
      "Direction stability factor": 0.4365598851,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.120792217, 1.1327739799, 1.1422310277],
      direction: [-8.0819050647, -7.0787862943, -6.2521899942],
      "speed factor": 1.3471962978,
      "Uz factor": 0.9976007697,
      "Directional variance factor": 0.3707745516,
      "Velocity stability factor": 0.9828729233,
      "Direction stability factor": 0.9949174581,
      score: 0.8358705978,
    },
    "225.0": {
      velocity: [0.4040493805, 0.7736166806, 0.93673345],
      direction: [-27.4443329894, 1.3831024454, 8.3277051388],
      "speed factor": 0.9368102947,
      "Uz factor": 0.9982798979,
      "Directional variance factor": 0.8770575604,
      "Velocity stability factor": 0.4996355185,
      "Direction stability factor": 0.9006332274,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.6634385147, 0.8935083873, 1.020707093],
      direction: [-7.4121648777, 2.7947088993, 11.387379148],
      "speed factor": 1.1194441362,
      "Uz factor": 0.9898133015,
      "Directional variance factor": 0.7515814312,
      "Velocity stability factor": 0.7166986471,
      "Direction stability factor": 0.9477790444,
      score: 0.8409595417,
    },
    "270.0": {
      velocity: [1.0360081603, 1.0699426937, 1.1075468224],
      direction: [-2.2905118555, -1.1737928711, 0.2215084623],
      "speed factor": 1.5005474922,
      "Uz factor": 0.9923922383,
      "Directional variance factor": 0.8956628559,
      "Velocity stability factor": 0.9390011186,
      "Direction stability factor": 0.9930221658,
      score: 0.9551770765,
    },
    "292.5": {
      velocity: [1.1267958548, 1.1425128517, 1.1563735251],
      direction: [2.2922808832, 3.1075295302, 3.9089439915],
      "speed factor": 1.1419510517,
      "Uz factor": 0.9852062429,
      "Directional variance factor": 0.7237751529,
      "Velocity stability factor": 0.9698457514,
      "Direction stability factor": 0.9955092691,
      score: 0.9211598606,
    },
    "315.0": {
      velocity: [0.8942945726, 0.9015717126, 0.9123218088],
      direction: [-0.8920036248, -0.6474680866, -0.4533999574],
      "speed factor": 1.1204237665,
      "Uz factor": 0.994691849,
      "Directional variance factor": 0.9424472812,
      "Velocity stability factor": 0.970983909,
      "Direction stability factor": 0.9987816565,
      score: 0.9777486258,
    },
    "337.5": {
      velocity: [1.11065596, 1.147077643, 1.1833499045],
      direction: [-5.0686621781, -4.0444405521, -2.7388633997],
      "speed factor": 1.1468118918,
      "Uz factor": 0.9980738173,
      "Directional variance factor": 0.6404941731,
      "Velocity stability factor": 0.9275507165,
      "Direction stability factor": 0.9935283367,
      score: 0.8887753908,
    },
    id: "IDN:3657.0",
    zone: 1,
  },
  {
    position: [-29.90261359, 3.456938743, 20.8740369],
    "overall score": 0.6348339251,
    "0": {
      velocity: [0.6823997987, 0.7781589717, 0.9191179121],
      direction: [-2.6933307945, 3.2061177445, 11.1819690652],
      "speed factor": 1.288925166,
      "Uz factor": 0.9973387628,
      "Directional variance factor": 0.715011756,
      "Velocity stability factor": 0.737052563,
      "Direction stability factor": 0.9614575004,
      score: 0.8437448299,
    },
    "22.5": {
      velocity: [0.1746857495, 0.6803888398, 1.136709395],
      direction: [-31.3335263558, 14.1905860687, 61.7534672216],
      "speed factor": 0.9002250248,
      "Uz factor": 0.9998295081,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1871686739,
      "Direction stability factor": 0.7414250178,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.2542107036, 0.6377173094, 1.0169125857],
      direction: [-22.8440662183, -5.0587926051, 39.8524937699],
      "speed factor": 1.0278663777,
      "Uz factor": 0.9999809836,
      "Directional variance factor": 0.5503295462,
      "Velocity stability factor": 0.1779026241,
      "Direction stability factor": 0.8258428889,
      score: 0.594979487,
    },
    "67.5": {
      velocity: [0.7165937713, 0.903568332, 0.9799500103],
      direction: [-5.3221085619, -3.0412298749, 2.103832801],
      "speed factor": 1.2279686761,
      "Uz factor": 0.9986785666,
      "Directional variance factor": 0.7296684556,
      "Velocity stability factor": 0.7324083666,
      "Direction stability factor": 0.9793723851,
      score: 0.8552053981,
    },
    "90.0": {
      velocity: [0.2873794431, 0.699669788, 0.948852145],
      direction: [-21.0048530068, 0.9337609643, 39.0513376585],
      "speed factor": 1.0107325192,
      "Uz factor": 0.9999041551,
      "Directional variance factor": 0.9169990254,
      "Velocity stability factor": 0.3276552767,
      "Direction stability factor": 0.8331772482,
      score: 0.7277521996,
    },
    "112.5": {
      velocity: [1.1676640167, 1.2098209103, 1.2479317149],
      direction: [3.4372453467, 5.1958420128, 6.7201309616],
      "speed factor": 1.1562408698,
      "Uz factor": 0.9945866446,
      "Directional variance factor": 0.5381473766,
      "Velocity stability factor": 0.9349966576,
      "Direction stability factor": 0.9908808733,
      score: 0.8637264452,
    },
    "135.0": {
      velocity: [1.1321715456, 1.1401264989, 1.1515185119],
      direction: [-3.5831884041, -3.1973720278, -2.7281500113],
      "speed factor": 1.3187909813,
      "Uz factor": 0.9859508272,
      "Directional variance factor": 0.7157891531,
      "Velocity stability factor": 0.9802925014,
      "Direction stability factor": 0.9976248934,
      score: 0.9228328603,
    },
    "157.5": {
      velocity: [0.1062533201, 0.5619879909, 0.9166436165],
      direction: [-107.2037115796, -17.8987165062, 30.0266156872],
      "speed factor": 0.5390200087,
      "Uz factor": 0.9954754829,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3954049662,
      "Direction stability factor": 0.6188046465,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0620140795, 0.5497111585, 0.8417150663],
      direction: [-74.5990159344, 1.3884451531, 95.1581914316],
      "speed factor": 0.7693928917,
      "Uz factor": 0.9949010674,
      "Directional variance factor": 0.8765826531,
      "Velocity stability factor": 0.2376850735,
      "Direction stability factor": 0.5284522018,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.098638336, 1.1169072255, 1.132000742],
      direction: [-8.4342526755, -7.8190696805, -7.364404401],
      "speed factor": 1.3283261321,
      "Uz factor": 0.9777185032,
      "Directional variance factor": 0.304971584,
      "Velocity stability factor": 0.9733473794,
      "Direction stability factor": 0.9970281992,
      score: 0.8180938405,
    },
    "225.0": {
      velocity: [0.59614975, 0.8395747743, 1.0026600265],
      direction: [-18.1958272676, -2.8380267298, 3.2961203575],
      "speed factor": 1.016682178,
      "Uz factor": 0.9940275064,
      "Directional variance factor": 0.7477309574,
      "Velocity stability factor": 0.6181539577,
      "Direction stability factor": 0.9403001455,
      score: 0.8116213015,
    },
    "247.5": {
      velocity: [0.3947669541, 0.719433237, 0.9471372163],
      direction: [-27.1034552869, 1.9757305824, 29.6221784554],
      "speed factor": 0.9013517164,
      "Uz factor": 0.9927828851,
      "Directional variance factor": 0.8243795038,
      "Velocity stability factor": 0.5619899086,
      "Direction stability factor": 0.8424287952,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.8944983216, 0.96832417, 1.0623597812],
      direction: [-5.0097042625, 0.3476902315, 3.1437695612],
      "speed factor": 1.3580319895,
      "Uz factor": 0.9962959516,
      "Directional variance factor": 0.9690942016,
      "Velocity stability factor": 0.8568695448,
      "Direction stability factor": 0.9773514616,
      score: 0.9451666674,
    },
    "292.5": {
      velocity: [1.1484477669, 1.2064522547, 1.2546381635],
      direction: [0.3698386638, 1.7145187481, 3.3098158491],
      "speed factor": 1.2058590143,
      "Uz factor": 0.9925237516,
      "Directional variance factor": 0.8475983335,
      "Velocity stability factor": 0.8917395594,
      "Direction stability factor": 0.9918333967,
      score: 0.9307511716,
    },
    "315.0": {
      velocity: [0.8923328555, 0.9027231728, 0.917079312],
      direction: [-1.3317135328, -0.9714585941, -0.6844864313],
      "speed factor": 1.121854738,
      "Uz factor": 0.9994939329,
      "Directional variance factor": 0.913648125,
      "Velocity stability factor": 0.9601688564,
      "Direction stability factor": 0.9982021469,
      score: 0.9675553188,
    },
    "337.5": {
      velocity: [1.1180511615, 1.1599511569, 1.1992655448],
      direction: [-5.6859966052, -4.4865334516, -2.6974823175],
      "speed factor": 1.1596824232,
      "Uz factor": 0.9999998829,
      "Directional variance factor": 0.6011970265,
      "Velocity stability factor": 0.9190589542,
      "Direction stability factor": 0.9916985714,
      score: 0.8759132809,
    },
    id: "IDN:3640.0",
    zone: 1,
  },
  {
    position: [-31.8936219, 7.3169522, 20.8740369],
    "overall score": 0.6264250789,
    "0": {
      velocity: [0.6597003306, 0.8006247992, 0.951761424],
      direction: [-1.5815729756, 3.7989067231, 9.7153027608],
      "speed factor": 1.3261370616,
      "Uz factor": 0.9986105831,
      "Directional variance factor": 0.6623194024,
      "Velocity stability factor": 0.675577357,
      "Direction stability factor": 0.9686197896,
      score: 0.8187840847,
    },
    "22.5": {
      velocity: [0.1822240002, 0.6435266343, 1.070895811],
      direction: [-32.3729330986, 16.3095588617, 103.0496543906],
      "speed factor": 0.8514525025,
      "Uz factor": 0.9999250677,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2491449771,
      "Direction stability factor": 0.6238261459,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.4496474063, 0.7588981116, 1.0071976364],
      direction: [-18.2660861681, -6.762267478, 12.4502324872],
      "speed factor": 1.2231843822,
      "Uz factor": 0.9997300983,
      "Directional variance factor": 0.3989095575,
      "Velocity stability factor": 0.3990304837,
      "Direction stability factor": 0.9146768926,
      score: 0.6568234566,
    },
    "67.5": {
      velocity: [0.5315646848, 0.8394208072, 0.9577443121],
      direction: [-11.448545553, -4.1973170597, 3.608255508],
      "speed factor": 1.1407908188,
      "Uz factor": 0.9996548163,
      "Directional variance factor": 0.6269051502,
      "Velocity stability factor": 0.5669663911,
      "Direction stability factor": 0.9581755526,
      score: 0.7775556617,
    },
    "90.0": {
      velocity: [0.4538869439, 0.7932900877, 1.0353033479],
      direction: [-23.7060692521, 2.0412383117, 33.8462738188],
      "speed factor": 1.1459750051,
      "Uz factor": 0.9997938495,
      "Directional variance factor": 0.8185565945,
      "Velocity stability factor": 0.4090273866,
      "Direction stability factor": 0.8401323804,
      score: 0.7269621855,
    },
    "112.5": {
      velocity: [0.8560571703, 1.0851887485, 1.2520702225],
      direction: [2.660177965, 8.4917139384, 12.5463353156],
      "speed factor": 1.0371283649,
      "Uz factor": 0.9978415377,
      "Directional variance factor": 0.2451809833,
      "Velocity stability factor": 0.6792959981,
      "Direction stability factor": 0.9725384518,
      score: 0.7173884712,
    },
    "135.0": {
      velocity: [1.1106455518, 1.1446631933, 1.1712647236],
      direction: [-0.322550475, 0.3302118968, 1.1636909223],
      "speed factor": 1.3240386021,
      "Uz factor": 0.9949715912,
      "Directional variance factor": 0.9706478314,
      "Velocity stability factor": 0.9382511852,
      "Direction stability factor": 0.9958715517,
      score: 0.97516053,
    },
    "157.5": {
      velocity: [0.0347043962, 0.6013709647, 1.0214047045],
      direction: [-94.0017726297, -18.2314555417, 55.3790354515],
      "speed factor": 0.5767934331,
      "Uz factor": 0.9967217514,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2638681522,
      "Direction stability factor": 0.5850533109,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1256331461, 0.5670123508, 0.9366474849],
      direction: [-46.6668377896, 0.6533578667, 100.5445510735],
      "speed factor": 0.7936081803,
      "Uz factor": 0.9974602216,
      "Directional variance factor": 0.9419237452,
      "Velocity stability factor": 0.2070699583,
      "Direction stability factor": 0.5910794754,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1702202663, 1.1822727893, 1.1908476901],
      direction: [-7.3350208361, -5.9613427683, -4.7456109375],
      "speed factor": 1.4060647164,
      "Uz factor": 0.9999281216,
      "Directional variance factor": 0.470102865,
      "Velocity stability factor": 0.9835211256,
      "Direction stability factor": 0.9928071947,
      score: 0.859809595,
    },
    "225.0": {
      velocity: [0.4960399433, 0.753223575, 0.9288631691],
      direction: [-39.9142197224, -2.6656195583, 10.9250228461],
      "speed factor": 0.9121152853,
      "Uz factor": 0.9999770819,
      "Directional variance factor": 0.7630560393,
      "Velocity stability factor": 0.5934374964,
      "Direction stability factor": 0.8587798818,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.6071506362, 0.8719524116, 1.012699859],
      direction: [-7.0787802767, 3.1390402294, 13.7784791852],
      "speed factor": 1.0924374388,
      "Uz factor": 0.991216135,
      "Directional variance factor": 0.7209742018,
      "Velocity stability factor": 0.6784138026,
      "Direction stability factor": 0.9420631682,
      score: 0.8208785852,
    },
    "270.0": {
      velocity: [1.0097153837, 1.0566004234, 1.1009168714],
      direction: [-3.92102966, -2.4968596448, -0.9397182551],
      "speed factor": 1.481835546,
      "Uz factor": 0.9948715452,
      "Directional variance factor": 0.7780569205,
      "Velocity stability factor": 0.9222352142,
      "Direction stability factor": 0.9917185794,
      score: 0.9209323234,
    },
    "292.5": {
      velocity: [1.1976584929, 1.2073361867, 1.2173042481],
      direction: [-2.5011075981, -1.916010709, -1.2541420886],
      "speed factor": 1.2067425116,
      "Uz factor": 0.987965168,
      "Directional variance factor": 0.829687937,
      "Velocity stability factor": 0.9799712763,
      "Direction stability factor": 0.9965362069,
      score: 0.9506829068,
    },
    "315.0": {
      velocity: [0.8755287725, 0.8851942151, 0.8968318736],
      direction: [-2.4923568117, -2.1931486496, -1.9561929393],
      "speed factor": 1.1000707129,
      "Uz factor": 0.9985127136,
      "Directional variance factor": 0.8050534534,
      "Velocity stability factor": 0.9657111765,
      "Direction stability factor": 0.9985106559,
      score: 0.9419464854,
    },
    "337.5": {
      velocity: [1.1052550766, 1.1559299414, 1.1838180211],
      direction: [-6.6691411927, -5.4230607608, -3.7632724085],
      "speed factor": 1.1556621393,
      "Uz factor": 0.9999992342,
      "Directional variance factor": 0.5179501546,
      "Velocity stability factor": 0.9217014694,
      "Direction stability factor": 0.9919281423,
      score: 0.8558769771,
    },
    id: "IDN:2140.0",
    zone: 1,
  },
  {
    position: [-27.8936219, 15.3169522, 20.8740369],
    "overall score": 0.6250103832,
    "0": {
      velocity: [0.6993179178, 0.7735238236, 0.8778457858],
      direction: [-3.3025496586, 4.0128008729, 9.644664046],
      "speed factor": 1.2812476101,
      "Uz factor": 0.9835529428,
      "Directional variance factor": 0.6433065891,
      "Velocity stability factor": 0.8016905226,
      "Direction stability factor": 0.9640355175,
      score: 0.8432670367,
    },
    "22.5": {
      velocity: [0.3937491927, 0.7253245916, 1.0868332596],
      direction: [-25.1330179377, 15.690697815, 74.720543505],
      "speed factor": 0.9596796864,
      "Uz factor": 0.9906196443,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4144006296,
      "Direction stability factor": 0.722628996,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6463420933, 0.8662729522, 1.032086111],
      direction: [-15.5320385077, -4.1916019557, 6.1551761499],
      "speed factor": 1.3962500757,
      "Uz factor": 0.9892686011,
      "Directional variance factor": 0.6274131595,
      "Velocity stability factor": 0.584216124,
      "Direction stability factor": 0.9397577371,
      score: 0.7727861894,
    },
    "67.5": {
      velocity: [0.6006938521, 0.8909445781, 0.9707265189],
      direction: [-9.2001803931, -3.7962149504, 0.0981117285],
      "speed factor": 1.2108127246,
      "Uz factor": 0.9960229684,
      "Directional variance factor": 0.6625586711,
      "Velocity stability factor": 0.6240163282,
      "Direction stability factor": 0.9741714108,
      score: 0.8087294552,
    },
    "90.0": {
      velocity: [0.4250632412, 0.8102813357, 1.0430893235],
      direction: [-16.6730351281, 3.5425358426, 21.6068599012],
      "speed factor": 1.170520308,
      "Uz factor": 0.9998994022,
      "Directional variance factor": 0.6851079251,
      "Velocity stability factor": 0.3718159885,
      "Direction stability factor": 0.8936669583,
      score: 0.7110644575,
    },
    "112.5": {
      velocity: [0.6929369006, 1.1207757983, 1.2310834695],
      direction: [-13.5426740351, 8.4024277332, 12.1572304877],
      "speed factor": 1.0711393504,
      "Uz factor": 0.996842534,
      "Directional variance factor": 0.2531175348,
      "Velocity stability factor": 0.5641917424,
      "Direction stability factor": 0.9286113763,
      score: 0.6686330075,
    },
    "135.0": {
      velocity: [0.9453224202, 1.0017544802, 1.0606443927],
      direction: [2.5900725608, 5.1293903721, 7.2133884896],
      "speed factor": 1.158735259,
      "Uz factor": 0.9996639624,
      "Directional variance factor": 0.5440541891,
      "Velocity stability factor": 0.8825289936,
      "Direction stability factor": 0.9871574558,
      score: 0.8502245236,
    },
    "157.5": {
      velocity: [0.3668252215, 0.7320529835, 0.9917600426],
      direction: [-52.1255006549, -12.7791450164, 21.1473571044],
      "speed factor": 0.7021345863,
      "Uz factor": 0.9996356355,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5337647909,
      "Direction stability factor": 0.796464284,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1834763557, 0.5540794503, 0.8503366724],
      direction: [-98.399009675, -0.8144425866, 58.4861473114],
      "speed factor": 0.7755068892,
      "Uz factor": 0.999028964,
      "Directional variance factor": 0.9276051034,
      "Velocity stability factor": 0.3480095807,
      "Direction stability factor": 0.5642078973,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0895972474, 1.1124346468, 1.1229266535],
      direction: [-3.6102766787, -1.8879859661, -0.8582570954],
      "speed factor": 1.3230069407,
      "Uz factor": 0.9999808117,
      "Directional variance factor": 0.8321790252,
      "Velocity stability factor": 0.9733737425,
      "Direction stability factor": 0.9923555012,
      score: 0.9475659425,
    },
    "225.0": {
      velocity: [0.2670476089, 0.5910995169, 0.7919685238],
      direction: [-35.654402762, -1.0656923478, 36.051939667],
      "speed factor": 0.7157913299,
      "Uz factor": 0.9994278147,
      "Directional variance factor": 0.9052717913,
      "Velocity stability factor": 0.5069276586,
      "Direction stability factor": 0.8008157155,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.7590834505, 0.8434514135, 0.9502286669],
      direction: [-3.2557947363, 3.0379560724, 8.9749045843],
      "speed factor": 1.0567295758,
      "Uz factor": 0.9951345171,
      "Directional variance factor": 0.7299594602,
      "Velocity stability factor": 0.8484286005,
      "Direction stability factor": 0.9660258352,
      score: 0.8776099328,
    },
    "270.0": {
      velocity: [0.8715981282, 1.0532633567, 1.1530033265],
      direction: [-3.228627314, 0.3430040189, 13.5124847654],
      "speed factor": 1.477155457,
      "Uz factor": 0.9997380138,
      "Directional variance factor": 0.9695107539,
      "Velocity stability factor": 0.7600541884,
      "Direction stability factor": 0.9534969109,
      score: 0.909139691,
    },
    "292.5": {
      velocity: [1.1574508913, 1.1626480901, 1.167302221],
      direction: [2.9031831876, 3.3077029933, 3.8099153251],
      "speed factor": 1.1620763891,
      "Uz factor": 0.9995938566,
      "Directional variance factor": 0.7059819562,
      "Velocity stability factor": 0.9899566314,
      "Direction stability factor": 0.9974812996,
      score: 0.9227252967,
    },
    "315.0": {
      velocity: [0.802006463, 0.8117842797, 0.8175966996],
      direction: [9.12237235, 9.5273503003, 9.917368382],
      "speed factor": 1.0088408804,
      "Uz factor": 0.9940607821,
      "Directional variance factor": 0.1531244178,
      "Velocity stability factor": 0.9749064294,
      "Direction stability factor": 0.9977916777,
      score: 0.7809035506,
    },
    "337.5": {
      velocity: [0.8823962043, 1.0668315539, 1.1556117831],
      direction: [-2.9851101476, -0.7304577985, 2.9020039966],
      "speed factor": 1.0665843939,
      "Uz factor": 0.989712425,
      "Directional variance factor": 0.9350704179,
      "Velocity stability factor": 0.7277039636,
      "Direction stability factor": 0.9836469052,
      score: 0.9075170479,
    },
    id: "IDN:2166.0",
    zone: 1,
  },
  {
    position: [-29.8936219, 5.3169522, 20.8740369],
    "overall score": 0.6203706203,
    "0": {
      velocity: [0.6917459142, 0.7811247251, 0.9032229385],
      direction: [-1.2897427256, 4.2717757968, 10.617704278],
      "speed factor": 1.2938375738,
      "Uz factor": 0.9986465931,
      "Directional variance factor": 0.6202865958,
      "Velocity stability factor": 0.7650904667,
      "Direction stability factor": 0.9669237583,
      score: 0.8298061448,
    },
    "22.5": {
      velocity: [0.2119431684, 0.6709351125, 1.0837823154],
      direction: [-33.0877727112, 15.4248094347, 70.5114213656],
      "speed factor": 0.8877167628,
      "Uz factor": 0.9993840741,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2633672017,
      "Direction stability factor": 0.7122244609,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3883884527, 0.6968416148, 1.0015541152],
      direction: [-21.326654373, -6.1310369452, 26.9283968293],
      "speed factor": 1.1231623417,
      "Uz factor": 0.9999951448,
      "Directional variance factor": 0.4550189382,
      "Velocity stability factor": 0.3390839933,
      "Direction stability factor": 0.8659581911,
      score: 0.6315048284,
    },
    "67.5": {
      velocity: [0.58459784, 0.8698553998, 0.9575069888],
      direction: [-7.1415172325, -3.4417149982, 6.9188083099],
      "speed factor": 1.1821520806,
      "Uz factor": 0.998813435,
      "Directional variance factor": 0.6940697779,
      "Velocity stability factor": 0.6210935856,
      "Direction stability factor": 0.9609435402,
      score: 0.809262611,
    },
    "90.0": {
      velocity: [0.3444206643, 0.7312226117, 0.9716117011],
      direction: [-16.0178061033, 2.2661097837, 52.9502694807],
      "speed factor": 1.0563132568,
      "Uz factor": 0.9999459664,
      "Directional variance factor": 0.7985680192,
      "Velocity stability factor": 0.3625003981,
      "Direction stability factor": 0.8084220123,
      score: 0.6944781105,
    },
    "112.5": {
      velocity: [1.0862390773, 1.1773438892, 1.2364348441],
      direction: [3.1976940224, 7.2068734622, 10.135782198],
      "speed factor": 1.1252021774,
      "Uz factor": 0.9966886401,
      "Directional variance factor": 0.3593890256,
      "Velocity stability factor": 0.8783666771,
      "Direction stability factor": 0.9807275328,
      score: 0.7998026921,
    },
    "135.0": {
      velocity: [1.1546806866, 1.1664878405, 1.1798921997],
      direction: [-0.8492542033, -0.2753962619, 0.5555339725],
      "speed factor": 1.349283299,
      "Uz factor": 0.9903820928,
      "Directional variance factor": 0.9755203323,
      "Velocity stability factor": 0.9743186683,
      "Direction stability factor": 0.9960978106,
      score: 0.9855086555,
    },
    "157.5": {
      velocity: [0.1113580322, 0.5808516936, 1.0516459452],
      direction: [-104.3035276469, -17.9886742545, 25.9312319437],
      "speed factor": 0.5571127675,
      "Uz factor": 0.9971093925,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2984943118,
      "Direction stability factor": 0.6382367789,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0192628534, 0.5444911268, 0.8219682153],
      direction: [-50.5923037301, 0.1712323493, 81.8224760288],
      "speed factor": 0.7620867725,
      "Uz factor": 0.9947360418,
      "Directional variance factor": 0.9847793467,
      "Velocity stability factor": 0.2151936585,
      "Direction stability factor": 0.6321811673,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1705993988, 1.1922234917, 1.2091350896],
      direction: [-9.9784905691, -9.304507945, -8.746306445],
      "speed factor": 1.4178989831,
      "Uz factor": 0.9912129426,
      "Directional variance factor": 0.1729326271,
      "Velocity stability factor": 0.9692145361,
      "Direction stability factor": 0.9965772663,
      score: 0.783825424,
    },
    "225.0": {
      velocity: [0.6200255393, 0.849371091, 1.0532230145],
      direction: [-26.4203682176, -7.0918517347, 2.667310589],
      "speed factor": 1.0285450172,
      "Uz factor": 0.9997485728,
      "Directional variance factor": 0.3696131791,
      "Velocity stability factor": 0.5930859538,
      "Direction stability factor": 0.9192008922,
      score: 0.7002752293,
    },
    "247.5": {
      velocity: [0.4965297844, 0.7745192709, 1.0693122379],
      direction: [-17.9378271401, 2.0629644175, 24.7347925935],
      "speed factor": 0.9703670033,
      "Uz factor": 0.9954962408,
      "Directional variance factor": 0.8166253851,
      "Velocity stability factor": 0.5458037624,
      "Direction stability factor": 0.8814649452,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.9718960407, 1.0285635158, 1.0906764182],
      direction: [-3.5126397614, -0.9664572422, 1.3971524054],
      "speed factor": 1.4425150182,
      "Uz factor": 0.9954944866,
      "Directional variance factor": 0.9140926896,
      "Velocity stability factor": 0.8987195181,
      "Direction stability factor": 0.9863616884,
      score: 0.9463838961,
    },
    "292.5": {
      velocity: [1.1647105854, 1.1965747831, 1.2271821786],
      direction: [-4.2372571297, -2.6355555538, -1.3157696439],
      "speed factor": 1.1959863996,
      "Uz factor": 0.9935395976,
      "Directional variance factor": 0.7657283952,
      "Velocity stability factor": 0.9363106042,
      "Direction stability factor": 0.991884757,
      score: 0.9214521283,
    },
    "315.0": {
      velocity: [0.8757896131, 0.8864590994, 0.899238533],
      direction: [-1.6948063522, -1.3283923376, -1.0293773471],
      "speed factor": 1.1016426415,
      "Uz factor": 0.9997037187,
      "Directional variance factor": 0.8819206811,
      "Velocity stability factor": 0.9622573317,
      "Direction stability factor": 0.9981515861,
      score: 0.9601202963,
    },
    "337.5": {
      velocity: [1.0835162763, 1.1396468665, 1.1761565244],
      direction: [-6.060407719, -4.9103480885, -2.9722329143],
      "speed factor": 1.1393828369,
      "Uz factor": 0.9999122803,
      "Directional variance factor": 0.5635246144,
      "Velocity stability factor": 0.9076715446,
      "Direction stability factor": 0.9914217367,
      score: 0.8635099081,
    },
    id: "IDN:2135.0",
    zone: 1,
  },
  {
    position: [-29.8936219, 7.3169522, 20.8740369],
    "overall score": 0.6146106276,
    "0": {
      velocity: [0.7257124008, 0.7929186583, 0.9062231026],
      direction: [-1.4342270866, 5.0184601053, 10.8853872947],
      "speed factor": 1.3133727816,
      "Uz factor": 0.9996942493,
      "Directional variance factor": 0.5539146573,
      "Velocity stability factor": 0.7994879829,
      "Direction stability factor": 0.9657788489,
      score: 0.8212400845,
    },
    "22.5": {
      velocity: [0.1433408637, 0.6749746589, 1.2400653254],
      direction: [-30.3044493958, 15.4430279778, 91.2922591376],
      "speed factor": 0.8930615017,
      "Uz factor": 0.9994380199,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0733574972,
      "Direction stability factor": 0.6622313652,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.4954301978, 0.7685840386, 1.0274542491],
      direction: [-21.5556432181, -6.9350320385, 22.3283396839],
      "speed factor": 1.2387960624,
      "Uz factor": 0.9997698897,
      "Directional variance factor": 0.3835527077,
      "Velocity stability factor": 0.4265445165,
      "Direction stability factor": 0.8781000475,
      score: 0.6415743298,
    },
    "67.5": {
      velocity: [0.5178639577, 0.8431658401, 0.948891805],
      direction: [-11.2071220725, -4.2328637441, 10.1882468277],
      "speed factor": 1.1458803986,
      "Uz factor": 0.99940596,
      "Directional variance factor": 0.623745445,
      "Velocity stability factor": 0.5620402002,
      "Direction stability factor": 0.9405684197,
      score: 0.7667306212,
    },
    "90.0": {
      velocity: [0.4199676684, 0.7849210029, 0.9942850095],
      direction: [-11.1718107716, 2.8277949341, 38.5859029757],
      "speed factor": 1.1338851503,
      "Uz factor": 0.9990646564,
      "Directional variance factor": 0.7486404503,
      "Velocity stability factor": 0.4162431303,
      "Direction stability factor": 0.8617841285,
      score: 0.7221129594,
    },
    "112.5": {
      velocity: [0.8563829562, 1.0306432512, 1.1610667286],
      direction: [-2.2510530905, 8.8019783464, 13.9009249204],
      "speed factor": 0.9849985556,
      "Uz factor": 0.997837648,
      "Directional variance factor": 0.2176019248,
      "Velocity stability factor": 0.7532573622,
      "Direction stability factor": 0.9551333944,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.1425260349, 1.1636366786, 1.1771975924],
      direction: [0.8056594637, 1.9610921973, 3.8098278311],
      "speed factor": 1.3459853434,
      "Uz factor": 0.9951518253,
      "Directional variance factor": 0.8256806936,
      "Velocity stability factor": 0.9646823353,
      "Direction stability factor": 0.9916550879,
      score: 0.9434183011,
    },
    "157.5": {
      velocity: [0.1466441847, 0.5969064219, 1.0463558214],
      direction: [-95.3360806103, -17.9995226537, 24.0972347712],
      "speed factor": 0.5725113524,
      "Uz factor": 0.9974191824,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3287664106,
      "Direction stability factor": 0.6682407906,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0678527699, 0.554686487, 0.8460905017],
      direction: [-92.8442905302, -0.4788555764, 62.7351339547],
      "speed factor": 0.7763565167,
      "Uz factor": 0.994795948,
      "Directional variance factor": 0.9574350599,
      "Velocity stability factor": 0.2391157003,
      "Direction stability factor": 0.567834932,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1995309242, 1.2171335008, 1.2312186096],
      direction: [-10.0803854068, -9.1134506342, -8.2150650229],
      "speed factor": 1.4475241976,
      "Uz factor": 0.9994775844,
      "Directional variance factor": 0.1899154992,
      "Velocity stability factor": 0.9746852834,
      "Direction stability factor": 0.9948185545,
      score: 0.7885594729,
    },
    "225.0": {
      velocity: [0.5380737614, 0.8415601838, 1.0147574279],
      direction: [-33.0453863659, -7.1043721812, 5.0457694643],
      "speed factor": 1.0190864076,
      "Uz factor": 0.9986553382,
      "Directional variance factor": 0.3685002506,
      "Velocity stability factor": 0.5522382042,
      "Direction stability factor": 0.8941912338,
      score: 0.6772802306,
    },
    "247.5": {
      velocity: [0.5330653393, 0.8027894871, 0.9558349138],
      direction: [-8.6707047077, 3.5637444301, 19.4567320886],
      "speed factor": 1.0057857282,
      "Uz factor": 0.994496801,
      "Directional variance factor": 0.6832227173,
      "Velocity stability factor": 0.6647586725,
      "Direction stability factor": 0.9218682311,
      score: 0.797929463,
    },
    "270.0": {
      velocity: [0.9755442544, 1.0346529469, 1.0869457323],
      direction: [-4.1351772724, -2.2992880018, -0.1024704799],
      "speed factor": 1.451055177,
      "Uz factor": 0.9966754829,
      "Directional variance factor": 0.7956188443,
      "Velocity stability factor": 0.9050112856,
      "Direction stability factor": 0.9887980367,
      score: 0.9195565508,
    },
    "292.5": {
      velocity: [1.2223544459, 1.2377192763, 1.250704278],
      direction: [-4.7834814042, -3.9699293778, -3.1470517617],
      "speed factor": 1.2371106611,
      "Uz factor": 0.9970607927,
      "Directional variance factor": 0.6471173886,
      "Velocity stability factor": 0.9710975248,
      "Direction stability factor": 0.9954543621,
      score: 0.9022809094,
    },
    "315.0": {
      velocity: [0.8900310538, 0.8991673559, 0.9097147449],
      direction: [-0.7261994299, -0.3945953537, -0.1278961935],
      "speed factor": 1.1174357642,
      "Uz factor": 0.9999712263,
      "Directional variance factor": 0.9649248574,
      "Velocity stability factor": 0.9683177296,
      "Direction stability factor": 0.9983380466,
      score: 0.98247967,
    },
    "337.5": {
      velocity: [1.0691353655, 1.1455092901, 1.1780667741],
      direction: [-5.560916962, -4.404733264, -2.4156736154],
      "speed factor": 1.1452439023,
      "Uz factor": 0.99918641,
      "Directional variance factor": 0.6084681543,
      "Velocity stability factor": 0.8914352141,
      "Direction stability factor": 0.9912632129,
      score: 0.8706074486,
    },
    id: "IDN:2141.0",
    zone: 1,
  },
  {
    position: [-33.7484487, 12.3468047, 20.8740369],
    "overall score": 0.6023856861,
    "0": {
      velocity: [0.7214014679, 0.853877375, 0.9987816246],
      direction: [1.6408937828, 5.6639573908, 9.3462785809],
      "speed factor": 1.4143434405,
      "Uz factor": 0.9993882673,
      "Directional variance factor": 0.4965371208,
      "Velocity stability factor": 0.691885001,
      "Direction stability factor": 0.9785961533,
      score: 0.7864036071,
    },
    "22.5": {
      velocity: [0.2131127274, 0.6800997321, 1.0584306635],
      direction: [-31.9664663551, 16.045106462, 49.5641511239],
      "speed factor": 0.8998425053,
      "Uz factor": 0.9973402492,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2857754566,
      "Direction stability factor": 0.7735260626,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.5835692698, 0.8544630194, 1.0430444493],
      direction: [-13.2914221435, -3.5586901537, 8.1112607986],
      "speed factor": 1.3772149442,
      "Uz factor": 0.9943481144,
      "Directional variance factor": 0.6836719863,
      "Velocity stability factor": 0.5047431398,
      "Direction stability factor": 0.9405481029,
      score: 0.767377833,
    },
    "67.5": {
      velocity: [0.4533945886, 0.866293532, 0.9606299858],
      direction: [-10.9896019151, -4.5680348399, 2.4918697739],
      "speed factor": 1.1773114261,
      "Uz factor": 0.9986763553,
      "Directional variance factor": 0.5939524587,
      "Velocity stability factor": 0.4846070517,
      "Direction stability factor": 0.9625514675,
      score: 0.7509156114,
    },
    "90.0": {
      velocity: [0.5733523023, 0.8482179825, 0.9962965735],
      direction: [-10.6779269633, 2.9128954691, 23.1113385466],
      "speed factor": 1.225323021,
      "Uz factor": 0.9999408579,
      "Directional variance factor": 0.7410759583,
      "Velocity stability factor": 0.5701041809,
      "Direction stability factor": 0.9061409291,
      score: 0.7808654994,
    },
    "112.5": {
      velocity: [0.4511540292, 0.9341234411, 1.1301119133],
      direction: [-2.7095710001, 9.4964665445, 23.7548111283],
      "speed factor": 0.8927533744,
      "Uz factor": 0.9999984141,
      "Directional variance factor": 0.1558696405,
      "Velocity stability factor": 0.4501582477,
      "Direction stability factor": 0.9264878274,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.94116409, 1.0354043153, 1.1218861854],
      direction: [0.3993963311, 4.3267250881, 9.0445575337],
      "speed factor": 1.1976582198,
      "Uz factor": 0.9993349747,
      "Directional variance factor": 0.6154022144,
      "Velocity stability factor": 0.8159101342,
      "Direction stability factor": 0.9759856633,
      score: 0.8458209188,
    },
    "157.5": {
      velocity: [0.2115446933, 0.6517430804, 1.0787675835],
      direction: [-77.9982487419, -19.7823764601, 36.9317541132],
      "speed factor": 0.6251068823,
      "Uz factor": 0.9994823339,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3530047744,
      "Direction stability factor": 0.6807499921,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1568908896, 0.6030116196, 0.9738365736],
      direction: [-49.0801483409, -1.8092748646, 66.4115352745],
      "speed factor": 0.8439938804,
      "Uz factor": 0.9999363126,
      "Directional variance factor": 0.8391755676,
      "Velocity stability factor": 0.2012708723,
      "Direction stability factor": 0.6791897677,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1015769496, 1.1143082974, 1.1218372771],
      direction: [-1.7035626962, -0.9347561788, -0.2534749821],
      "speed factor": 1.3252352539,
      "Uz factor": 0.9992901001,
      "Directional variance factor": 0.9169105619,
      "Velocity stability factor": 0.9838143921,
      "Direction stability factor": 0.9959719786,
      score: 0.9731672278,
    },
    "225.0": {
      velocity: [0.5000260637, 0.7638181432, 0.9231969514],
      direction: [-10.8127087924, 0.0824403308, 7.0560793502],
      "speed factor": 0.9249447664,
      "Uz factor": 0.9974349975,
      "Directional variance factor": 0.9926719706,
      "Velocity stability factor": 0.6025041973,
      "Direction stability factor": 0.9503644774,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.9303807939, 0.9798163719, 1.0189561639],
      direction: [-0.5606332128, 1.4894708108, 3.2406397291],
      "speed factor": 1.2275762686,
      "Uz factor": 0.9898264053,
      "Directional variance factor": 0.8676025946,
      "Velocity stability factor": 0.9297628628,
      "Direction stability factor": 0.9894409085,
      score: 0.9440618186,
    },
    "270.0": {
      velocity: [1.0296328746, 1.076963247, 1.1226121667],
      direction: [-3.7513270671, -2.0938987409, 0.0016216097],
      "speed factor": 1.510393509,
      "Uz factor": 0.9968242418,
      "Directional variance factor": 0.8138756675,
      "Velocity stability factor": 0.9207193335,
      "Direction stability factor": 0.9895751426,
      score: 0.9284363215,
    },
    "292.5": {
      velocity: [1.1392938963, 1.1447645621, 1.1492362176],
      direction: [-3.4516086434, -3.0627741421, -2.6924950186],
      "speed factor": 1.1442016549,
      "Uz factor": 0.9806575968,
      "Directional variance factor": 0.7277534096,
      "Velocity stability factor": 0.989863866,
      "Direction stability factor": 0.997891351,
      score: 0.9283499944,
    },
    "315.0": {
      velocity: [0.889038391, 0.8962335813, 0.9054783702],
      direction: [0.2309644223, 0.4947402515, 0.6995342822],
      "speed factor": 1.1137898303,
      "Uz factor": 0.9979709701,
      "Directional variance factor": 0.9560230888,
      "Velocity stability factor": 0.9735387096,
      "Direction stability factor": 0.9986984171,
      score: 0.9817396581,
    },
    "337.5": {
      velocity: [1.1159188836, 1.1615718848, 1.1951719565],
      direction: [-2.4044559991, -1.1690046446, -0.0694615626],
      "speed factor": 1.1613027756,
      "Uz factor": 0.9986873715,
      "Directional variance factor": 0.896088476,
      "Velocity stability factor": 0.9210136636,
      "Direction stability factor": 0.9935139043,
      score: 0.9510324871,
    },
    id: "IDN:3653.0",
    zone: 1,
  },
  {
    position: [-33.6758621, 10.5408342, 20.8740369],
    "overall score": 0.6012140265,
    "0": {
      velocity: [0.7153100935, 0.8526058358, 1.0001779777],
      direction: [-0.188749198, 4.4435787502, 9.1537521074],
      "speed factor": 1.4122372913,
      "Uz factor": 0.9999796448,
      "Directional variance factor": 0.6050152222,
      "Velocity stability factor": 0.6835676029,
      "Direction stability factor": 0.9740486075,
      score: 0.80917001,
    },
    "22.5": {
      velocity: [0.2015610008, 0.6571310989, 1.0334608886],
      direction: [-81.9255025098, 15.5990109606, 94.1756588782],
      "speed factor": 0.8694526206,
      "Uz factor": 0.9998595908,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2971126104,
      "Direction stability factor": 0.5108301073,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.5306452712, 0.8110172779, 1.0399599485],
      direction: [-14.8945090195, -5.2436989537, 8.0371652653],
      "speed factor": 1.3071895329,
      "Uz factor": 0.9965003311,
      "Directional variance factor": 0.5338934263,
      "Velocity stability factor": 0.4510223855,
      "Direction stability factor": 0.9363009048,
      score: 0.7143794053,
    },
    "67.5": {
      velocity: [0.5035906676, 0.8596492451, 0.9564762783],
      direction: [-12.5894422933, -4.9576598609, 0.381670125],
      "speed factor": 1.1682816983,
      "Uz factor": 0.9997208188,
      "Directional variance factor": 0.5593191235,
      "Velocity stability factor": 0.539830912,
      "Direction stability factor": 0.9639691322,
      score: 0.756772075,
    },
    "90.0": {
      velocity: [0.6169119455, 0.8478678219, 0.9927512829],
      direction: [-17.0986339548, 2.5553245591, 28.2019332134],
      "speed factor": 1.2248171841,
      "Uz factor": 0.9998397056,
      "Directional variance factor": 0.7728600392,
      "Velocity stability factor": 0.6179833353,
      "Direction stability factor": 0.8741650912,
      score: 0.7847933892,
    },
    "112.5": {
      velocity: [0.5100825613, 0.9160887909, 1.1384073688],
      direction: [0.8394229884, 9.5731348177, 25.6122715401],
      "speed factor": 0.8755174352,
      "Uz factor": 0.9994924171,
      "Directional variance factor": 0.1490546829,
      "Velocity stability factor": 0.4911625283,
      "Direction stability factor": 0.9311865318,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.9738533515, 1.0812842216, 1.1380987246],
      direction: [0.5913934395, 2.6368950628, 6.004616435],
      "speed factor": 1.2507277754,
      "Uz factor": 0.9983979506,
      "Directional variance factor": 0.7656093277,
      "Velocity stability factor": 0.8326939017,
      "Direction stability factor": 0.9849632695,
      score: 0.8920574421,
    },
    "157.5": {
      velocity: [0.0937803848, 0.6363104521, 1.0976451718],
      direction: [-100.5086491236, -20.1776530791, 51.0964179826],
      "speed factor": 0.6103049726,
      "Uz factor": 0.9983806165,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2510625219,
      "Direction stability factor": 0.5788748136,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.136723149, 0.588532185, 0.98106969],
      direction: [-53.590437005, -1.3463042099, 94.7904628876],
      "speed factor": 0.8237280119,
      "Uz factor": 0.9999887535,
      "Directional variance factor": 0.8803285147,
      "Velocity stability factor": 0.1744810097,
      "Direction stability factor": 0.5878308336,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1072668156, 1.1217620666, 1.1286926975],
      direction: [-2.3365319175, -1.500952625, -0.7708515286],
      "speed factor": 1.334099944,
      "Uz factor": 0.9994892972,
      "Directional variance factor": 0.8665819889,
      "Velocity stability factor": 0.9828832518,
      "Direction stability factor": 0.9956508878,
      score: 0.9601917541,
    },
    "225.0": {
      velocity: [0.4458612367, 0.6925845535, 0.8703305417],
      direction: [-33.7633908042, -0.6789230474, 15.763978439],
      "speed factor": 0.8386845268,
      "Uz factor": 0.9968566035,
      "Directional variance factor": 0.9396512847,
      "Velocity stability factor": 0.601284559,
      "Direction stability factor": 0.8624239743,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.913919213, 0.9783616741, 1.0219466088],
      direction: [-0.7779995704, 1.8607917404, 4.3537149359],
      "speed factor": 1.2257537307,
      "Uz factor": 0.9896601991,
      "Directional variance factor": 0.8345962897,
      "Velocity stability factor": 0.914338094,
      "Direction stability factor": 0.9857452375,
      score: 0.9301062147,
    },
    "270.0": {
      velocity: [0.9860830685, 1.0395982986, 1.0836707706],
      direction: [-4.5368463057, -2.4760039618, -0.8934928683],
      "speed factor": 1.4579908149,
      "Uz factor": 0.9966572225,
      "Directional variance factor": 0.779910759,
      "Velocity stability factor": 0.9167898798,
      "Direction stability factor": 0.9898795738,
      score: 0.9191149466,
    },
    "292.5": {
      velocity: [1.1462912447, 1.1520826266, 1.1571260692],
      direction: [-2.1386132276, -1.6406146369, -1.1724657599],
      "speed factor": 1.151516121,
      "Uz factor": 0.9793578138,
      "Directional variance factor": 0.8541675878,
      "Velocity stability factor": 0.9889539646,
      "Direction stability factor": 0.997316257,
      score: 0.9594385166,
    },
    "315.0": {
      velocity: [0.878975492, 0.8874442963, 0.8974168806],
      direction: [-0.9627407944, -0.7018396558, -0.4892480363],
      "speed factor": 1.10286699,
      "Uz factor": 0.9969242217,
      "Directional variance factor": 0.9376142528,
      "Velocity stability factor": 0.9703173019,
      "Direction stability factor": 0.9986847423,
      score: 0.9763252598,
    },
    "337.5": {
      velocity: [1.1195216925, 1.1598874138, 1.1895060104],
      direction: [-3.9119416371, -2.799995274, -1.5609408996],
      "speed factor": 1.1596186949,
      "Uz factor": 0.9991273494,
      "Directional variance factor": 0.7511115312,
      "Velocity stability factor": 0.9302512234,
      "Direction stability factor": 0.9934694424,
      score: 0.9170754098,
    },
    id: "IDN:3654.0",
    zone: 1,
  },
  {
    position: [54.13706151, 10.6134944, 21.1598299],
    "overall score": 0.6008628684,
    "0": {
      velocity: [0.4823776752, 0.6815838629, 0.8241819924],
      direction: [-21.890645356, -10.2298943489, -0.7523141679],
      "speed factor": 1.1289603096,
      "Uz factor": 0.9974500455,
      "Directional variance factor": 0.0906760579,
      "Velocity stability factor": 0.6203223831,
      "Direction stability factor": 0.9412824134,
      score: 0.6483908169,
    },
    "22.5": {
      velocity: [0.5107287004, 0.7745291554, 1.019177705],
      direction: [-9.9022898331, 4.6286744436, 21.042179407],
      "speed factor": 1.0247824293,
      "Uz factor": 0.99676982,
      "Directional variance factor": 0.5885622717,
      "Velocity stability factor": 0.5704021615,
      "Direction stability factor": 0.914043141,
      score: 0.7467626788,
    },
    "45.0": {
      velocity: [0.4119559404, 0.6984342253, 0.8562774209],
      direction: [-15.3112386958, 2.3796343029, 12.5876658074],
      "speed factor": 1.1257292952,
      "Uz factor": 0.9894282876,
      "Directional variance factor": 0.7884769509,
      "Velocity stability factor": 0.5210769348,
      "Direction stability factor": 0.922503043,
      score: 0.7886399929,
    },
    "67.5": {
      velocity: [0.7070260827, 0.8493730938, 0.9927476523],
      direction: [-3.3435199587, 1.6273665022, 11.533918537],
      "speed factor": 1.154316189,
      "Uz factor": 0.9897748194,
      "Directional variance factor": 0.8553451998,
      "Velocity stability factor": 0.7096833483,
      "Direction stability factor": 0.958673782,
      score: 0.870594028,
    },
    "90.0": {
      velocity: [0.9826320462, 1.0057579528, 1.019066963],
      direction: [4.5536426309, 5.1272958195, 5.8463472073],
      "speed factor": 1.4529029076,
      "Uz factor": 0.9946117802,
      "Directional variance factor": 0.5442403716,
      "Velocity stability factor": 0.9629662358,
      "Direction stability factor": 0.996409154,
      score: 0.8750062288,
    },
    "112.5": {
      velocity: [0.9327581647, 1.0525703486, 1.1345266788],
      direction: [-1.7799411882, 4.0689490464, 10.7569878951],
      "speed factor": 1.0059545549,
      "Uz factor": 0.9979277177,
      "Directional variance factor": 0.6383156403,
      "Velocity stability factor": 0.8366014212,
      "Direction stability factor": 0.965175197,
      score: 0.8513168639,
    },
    "135.0": {
      velocity: [1.0776900054, 1.1243945189, 1.1673969918],
      direction: [-2.0975461962, -0.1673723543, 3.456550726],
      "speed factor": 1.3005937081,
      "Uz factor": 0.9998750619,
      "Directional variance factor": 0.9851224574,
      "Velocity stability factor": 0.908621317,
      "Direction stability factor": 0.984571953,
      score: 0.9657219201,
    },
    "157.5": {
      velocity: [1.1071032056, 1.1167247329, 1.125222464],
      direction: [-3.5533929051, -2.9921705689, -2.4430700077],
      "speed factor": 1.071085121,
      "Uz factor": 0.999571646,
      "Directional variance factor": 0.7340292828,
      "Velocity stability factor": 0.9864820523,
      "Direction stability factor": 0.9969157697,
      score: 0.9285857186,
    },
    "180.0": {
      velocity: [0.360581675, 0.8345381535, 1.0390409977],
      direction: [-40.6582911625, -7.0193395952, 10.7247802951],
      "speed factor": 1.1680456422,
      "Uz factor": 0.9998973049,
      "Directional variance factor": 0.3760587027,
      "Velocity stability factor": 0.3366692137,
      "Direction stability factor": 0.857269246,
      score: 0.6068166021,
    },
    "202.5": {
      velocity: [0.1153176965, 0.5068982244, 0.8621297486],
      direction: [-13.9787079399, 19.0335076425, 78.8684015708],
      "speed factor": 0.6028487795,
      "Uz factor": 0.9950995564,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.403385408,
      "Direction stability factor": 0.7420913625,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.0418070385, 1.1083882004, 1.1425190616],
      direction: [-1.9018620205, 0.8100662406, 3.8091338683],
      "speed factor": 1.3422015097,
      "Uz factor": 0.9962217493,
      "Directional variance factor": 0.9279941119,
      "Velocity stability factor": 0.9053984864,
      "Direction stability factor": 0.9841361225,
      score: 0.9504162109,
    },
    "247.5": {
      velocity: [0.5308996206, 0.9550745816, 1.2869555769],
      direction: [-17.6603046238, -6.8168691341, 29.46615527],
      "speed factor": 1.1965781802,
      "Uz factor": 0.9998107099,
      "Directional variance factor": 0.394056077,
      "Velocity stability factor": 0.4004743535,
      "Direction stability factor": 0.869093167,
      score: 0.6331791911,
    },
    "270.0": {
      velocity: [0.8110480983, 0.9873115863, 1.0518374605],
      direction: [-0.8072475866, 8.1513044922, 12.9849161677],
      "speed factor": 1.3846610043,
      "Uz factor": 0.9998455451,
      "Directional variance factor": 0.2754396007,
      "Velocity stability factor": 0.7946860994,
      "Direction stability factor": 0.961688434,
      score: 0.748375642,
    },
    "292.5": {
      velocity: [0.3532466556, 0.3915210257, 0.4390932832],
      direction: [0.675769767, 7.6053057884, 15.1876703929],
      "speed factor": 0.3913285058,
      "Uz factor": 0.985955424,
      "Directional variance factor": 0.3239728188,
      "Velocity stability factor": 0.9124799037,
      "Direction stability factor": 0.9596891649,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4194574289, 0.4410808627, 0.4608841746],
      direction: [-1.3052282575, 1.0793274252, 3.994611224],
      "speed factor": 0.5481510506,
      "Uz factor": 0.9997829994,
      "Directional variance factor": 0.9040597844,
      "Velocity stability factor": 0.9333207704,
      "Direction stability factor": 0.9852782237,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2022063464, 0.5383284438, 0.7246894862],
      direction: [-20.3610152433, -9.3415575442, 5.8222844701],
      "speed factor": 0.5382037256,
      "Uz factor": 0.9999701535,
      "Directional variance factor": 0.1696393294,
      "Velocity stability factor": 0.4792753449,
      "Direction stability factor": 0.9272686119,
      score: 0.0,
    },
    id: "IDN:3586.0",
    zone: 1,
  },
  {
    position: [-20.8883087, 2.2679947, 21.1598299],
    "overall score": 0.5992401275,
    "0": {
      velocity: [0.482717737, 0.8001322855, 0.9662656209],
      direction: [-22.5109933011, 2.3956747871, 17.6635998852],
      "speed factor": 1.325321273,
      "Uz factor": 0.9997145553,
      "Directional variance factor": 0.78705113,
      "Velocity stability factor": 0.4628730562,
      "Direction stability factor": 0.8884039078,
      score: 0.7566830005,
    },
    "22.5": {
      velocity: [0.2609184302, 0.807935894, 1.2027949935],
      direction: [-33.2997378682, 7.5527053511, 52.3631503389],
      "speed factor": 1.0689830103,
      "Uz factor": 0.9971321349,
      "Directional variance factor": 0.3286484132,
      "Velocity stability factor": 0.2041913112,
      "Direction stability factor": 0.7620475328,
      score: 0.5142336975,
    },
    "45.0": {
      velocity: [0.3074247424, 0.6917847348, 1.0257026756],
      direction: [-30.8897878141, -11.2426113296, 22.5816625274],
      "speed factor": 1.115011713,
      "Uz factor": 0.9981533663,
      "Directional variance factor": 0.0006567707,
      "Velocity stability factor": 0.2257860928,
      "Direction stability factor": 0.8514681935,
      score: 0.4823448126,
    },
    "67.5": {
      velocity: [0.672034079, 0.9322818761, 0.9918997943],
      direction: [-4.2449770081, -2.7557566088, 5.4241476777],
      "speed factor": 1.2669909961,
      "Uz factor": 0.9992546452,
      "Directional variance factor": 0.755043857,
      "Velocity stability factor": 0.6749900835,
      "Direction stability factor": 0.9731413203,
      score: 0.8440791453,
    },
    "90.0": {
      velocity: [0.3304540101, 0.6712314307, 0.9255443115],
      direction: [-32.9391345676, 1.2617252293, 55.8251241519],
      "speed factor": 0.9696508933,
      "Uz factor": 0.9993666029,
      "Directional variance factor": 0.8878466463,
      "Velocity stability factor": 0.3951287438,
      "Direction stability factor": 0.7534326147,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1870436542, 1.2075199676, 1.2424520784],
      direction: [2.9922676669, 4.8826038153, 6.1847222931],
      "speed factor": 1.1540418303,
      "Uz factor": 0.9922192521,
      "Directional variance factor": 0.565990772,
      "Velocity stability factor": 0.9551284907,
      "Direction stability factor": 0.9911320705,
      score: 0.8758458509,
    },
    "135.0": {
      velocity: [1.1397763997, 1.1451325376, 1.150654149],
      direction: [-2.5179841876, -2.2360657108, -1.9042615306],
      "speed factor": 1.3245814954,
      "Uz factor": 0.9838592134,
      "Directional variance factor": 0.8012386035,
      "Velocity stability factor": 0.988919543,
      "Direction stability factor": 0.9982952148,
      score: 0.946687144,
    },
    "157.5": {
      velocity: [0.2117023627, 0.6167083059, 0.9360382193],
      direction: [-115.3137166028, -15.7460346875, 27.5968686022],
      "speed factor": 0.5915039499,
      "Uz factor": 0.9934771423,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4596062371,
      "Direction stability factor": 0.6030261522,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0695872848, 0.3777451111, 0.7280041964],
      direction: [-178.08872877, -2.5818636372, 179.3826034319],
      "speed factor": 0.5287038454,
      "Uz factor": 0.9996101742,
      "Directional variance factor": 0.77050101,
      "Velocity stability factor": 0.3562647117,
      "Direction stability factor": 0.0070240772,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0787651981, 1.0917578723, 1.1083272013],
      direction: [-0.7018382184, 0.1292073407, 0.7975322856],
      "speed factor": 1.2984162682,
      "Uz factor": 0.9711589951,
      "Directional variance factor": 0.9885149031,
      "Velocity stability factor": 0.9763834523,
      "Direction stability factor": 0.9958350819,
      score: 0.9891421298,
    },
    "225.0": {
      velocity: [0.5513689548, 0.8786548981, 1.1057867821],
      direction: [-21.0069264617, 3.2389941979, 15.2702531575],
      "speed factor": 1.0640062122,
      "Uz factor": 0.9996522494,
      "Directional variance factor": 0.7120894046,
      "Velocity stability factor": 0.479220415,
      "Direction stability factor": 0.8992300566,
      score: 0.7474424832,
    },
    "247.5": {
      velocity: [0.1297644306, 0.524280353, 0.8933150597],
      direction: [-154.1003276461, 3.3166258481, 74.6391655803],
      "speed factor": 0.6568517714,
      "Uz factor": 0.9991884,
      "Directional variance factor": 0.7051888135,
      "Velocity stability factor": 0.3945313429,
      "Direction stability factor": 0.3646125188,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.4463519772, 0.8956447324, 1.0409883039],
      direction: [-5.3283509485, 1.4735032377, 9.5485891971],
      "speed factor": 1.2561022801,
      "Uz factor": 0.9999282113,
      "Directional variance factor": 0.8690219344,
      "Velocity stability factor": 0.4929713564,
      "Direction stability factor": 0.9586751663,
      score: 0.8198359058,
    },
    "292.5": {
      velocity: [0.8572901165, 1.1215058267, 1.2124725134],
      direction: [-13.4643715237, -0.4041592041, 5.6312335723],
      "speed factor": 1.1209543563,
      "Uz factor": 0.9960708074,
      "Directional variance factor": 0.9640747374,
      "Velocity stability factor": 0.637893783,
      "Direction stability factor": 0.9469566525,
      score: 0.8739704564,
    },
    "315.0": {
      velocity: [0.9469872085, 0.9571353096, 0.9703536207],
      direction: [3.3300423229, 3.7228627955, 4.0148723579],
      "speed factor": 1.1894751505,
      "Uz factor": 0.9997345192,
      "Directional variance factor": 0.6690788626,
      "Velocity stability factor": 0.9623901338,
      "Direction stability factor": 0.9980976943,
      score: 0.9069160963,
    },
    "337.5": {
      velocity: [0.7210240248, 1.0412873627, 1.1863414153],
      direction: [-4.4408133452, -1.795930306, 5.2729185051],
      "speed factor": 1.0410461206,
      "Uz factor": 0.9982718298,
      "Directional variance factor": 0.8403617506,
      "Velocity stability factor": 0.5362486954,
      "Direction stability factor": 0.9730174115,
      score: 0.8306613173,
    },
    id: "IDN:3544.0",
    zone: 1,
  },
  {
    position: [-33.6032755, 8.7348637, 20.8740369],
    "overall score": 0.5986396145,
    "0": {
      velocity: [0.7021193434, 0.8448665094, 0.9980966125],
      direction: [-1.2599558357, 3.0625854834, 8.6908852273],
      "speed factor": 1.3994180437,
      "Uz factor": 0.9992202808,
      "Directional variance factor": 0.7277701793,
      "Velocity stability factor": 0.6712272533,
      "Direction stability factor": 0.9723587748,
      score: 0.8359287456,
    },
    "22.5": {
      velocity: [0.1428880022, 0.6463474444, 1.0434450945],
      direction: [-121.8373875865, 16.6627908893, 118.7587846634],
      "speed factor": 0.8551847269,
      "Uz factor": 0.9998574399,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.239102886,
      "Direction stability factor": 0.3316772993,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.4830972636, 0.7767321386, 1.0374026388],
      direction: [-14.9284844498, -5.9859821757, 10.7145452264],
      "speed factor": 1.2519290884,
      "Uz factor": 0.9982261223,
      "Directional variance factor": 0.4679126955,
      "Velocity stability factor": 0.402528032,
      "Direction stability factor": 0.928769362,
      score: 0.6819948629,
    },
    "67.5": {
      velocity: [0.5479945601, 0.8515630113, 0.9680325483],
      direction: [-12.912939048, -4.7723995404, 3.8722872731],
      "speed factor": 1.1572923336,
      "Uz factor": 0.9999955233,
      "Directional variance factor": 0.5757867075,
      "Velocity stability factor": 0.5732068024,
      "Direction stability factor": 0.9533743713,
      score: 0.7639355632,
    },
    "90.0": {
      velocity: [0.5313449397, 0.8347025439, 1.0336274226],
      direction: [-24.4045623818, 2.2486687879, 29.5909526338],
      "speed factor": 1.2057988203,
      "Uz factor": 0.9996979659,
      "Directional variance factor": 0.80011833,
      "Velocity stability factor": 0.4894619596,
      "Direction stability factor": 0.8500124583,
      score: 0.7474013015,
    },
    "112.5": {
      velocity: [0.7011158039, 1.0168501642, 1.2405012462],
      direction: [0.1738154973, 8.6740831124, 11.9467001621],
      "speed factor": 0.9718163311,
      "Uz factor": 0.9983711614,
      "Directional variance factor": 0.22897039,
      "Velocity stability factor": 0.5631884633,
      "Direction stability factor": 0.9672975426,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.0090324417, 1.093637739, 1.1358351016],
      direction: [0.0813784453, 0.9292879184, 2.4914017006],
      "speed factor": 1.2650171612,
      "Uz factor": 0.9971404328,
      "Directional variance factor": 0.9173966295,
      "Velocity stability factor": 0.8708343627,
      "Direction stability factor": 0.993305491,
      score: 0.9437104935,
    },
    "157.5": {
      velocity: [0.0563179999, 0.613312696, 1.0282803841],
      direction: [-75.1601084801, -20.4454059325, 172.1350088414],
      "speed factor": 0.5882471158,
      "Uz factor": 0.9968697252,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2748634415,
      "Direction stability factor": 0.3130691186,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1320141612, 0.5794330362, 0.9945855096],
      direction: [-74.8641060843, -1.1862662437, 98.3413493102],
      "speed factor": 0.8109925593,
      "Uz factor": 0.9996784439,
      "Directional variance factor": 0.8945541117,
      "Velocity stability factor": 0.1566625859,
      "Direction stability factor": 0.518873735,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1170180315, 1.1315930455, 1.1377468801],
      direction: [-3.6268446122, -2.5940409287, -1.7285166828],
      "speed factor": 1.3457918248,
      "Uz factor": 0.999631796,
      "Directional variance factor": 0.7694185841,
      "Velocity stability factor": 0.9834400991,
      "Direction stability factor": 0.9947268669,
      score: 0.9355781042,
    },
    "225.0": {
      velocity: [0.4122392834, 0.6420883878, 0.8320836842],
      direction: [-51.065279805, -1.1499491797, 11.6816146867],
      "speed factor": 0.7775362487,
      "Uz factor": 0.9953581525,
      "Directional variance factor": 0.8977822951,
      "Velocity stability factor": 0.605628856,
      "Direction stability factor": 0.8257030709,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.8805399585, 0.9594227234, 1.0158467815],
      direction: [-1.5627097209, 2.1912138591, 6.1142420723],
      "speed factor": 1.2020258087,
      "Uz factor": 0.9899259803,
      "Directional variance factor": 0.8052254347,
      "Velocity stability factor": 0.8927064725,
      "Direction stability factor": 0.9786751339,
      score: 0.9138205438,
    },
    "270.0": {
      velocity: [0.9998069501, 1.0473720776, 1.0873435545],
      direction: [-4.8684863798, -2.9091510683, -1.3931625518],
      "speed factor": 1.4688931975,
      "Uz factor": 0.9954470903,
      "Directional variance factor": 0.7414087939,
      "Velocity stability factor": 0.9253601507,
      "Direction stability factor": 0.9903463227,
      score: 0.9118653975,
    },
    "292.5": {
      velocity: [1.1435757478, 1.1506753722, 1.1572456375],
      direction: [-1.0648607488, -0.4841487024, 0.0707420684],
      "speed factor": 1.1501095586,
      "Uz factor": 0.9800515292,
      "Directional variance factor": 0.9569645598,
      "Velocity stability factor": 0.9860636335,
      "Direction stability factor": 0.9968455477,
      score: 0.9841798222,
    },
    "315.0": {
      velocity: [0.8692947121, 0.8781353206, 0.8889025358],
      direction: [-1.4439516166, -1.1811225282, -0.9647224668],
      "speed factor": 1.0912983068,
      "Uz factor": 0.9958200645,
      "Directional variance factor": 0.8950113308,
      "Velocity stability factor": 0.9684398434,
      "Direction stability factor": 0.9986688079,
      score: 0.9651971975,
    },
    "337.5": {
      velocity: [1.1127085611, 1.1515814962, 1.176436102],
      direction: [-4.8556439597, -3.8828073144, -2.5406071511],
      "speed factor": 1.1513147015,
      "Uz factor": 0.9989836639,
      "Directional variance factor": 0.6548615721,
      "Velocity stability factor": 0.9364869424,
      "Direction stability factor": 0.9935693422,
      score: 0.8946217997,
    },
    id: "IDN:3655.0",
    zone: 1,
  },
  {
    position: [-31.8936219, 11.3169522, 20.8740369],
    "overall score": 0.5932411276,
    "0": {
      velocity: [0.6979155177, 0.8280304023, 0.9680843527],
      direction: [1.4501999607, 6.0130898301, 10.2460740912],
      "speed factor": 1.3715310915,
      "Uz factor": 0.9990781564,
      "Directional variance factor": 0.4655031262,
      "Velocity stability factor": 0.6998953662,
      "Direction stability factor": 0.9755670163,
      score: 0.7791331313,
    },
    "22.5": {
      velocity: [0.1954318228, 0.6764011567, 1.0411625849],
      direction: [-27.9158152206, 15.5334560254, 117.9135886415],
      "speed factor": 0.8949489063,
      "Uz factor": 0.9994889152,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2854266524,
      "Direction stability factor": 0.5949183226,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.5817214109, 0.8374832728, 1.1203591041],
      direction: [-15.6499402348, -5.7359893329, 9.4092860316],
      "speed factor": 1.3498471586,
      "Uz factor": 0.9958519746,
      "Directional variance factor": 0.4901342815,
      "Velocity stability factor": 0.4194158365,
      "Direction stability factor": 0.9303910381,
      score: 0.6925830486,
    },
    "67.5": {
      velocity: [0.5478919035, 0.8597740075, 0.9594304601],
      direction: [-11.758797946, -5.3244251985, -2.2848442612],
      "speed factor": 1.1684512529,
      "Uz factor": 0.9996016837,
      "Directional variance factor": 0.5267177601,
      "Velocity stability factor": 0.581842925,
      "Direction stability factor": 0.973683462,
      score: 0.7639819023,
    },
    "90.0": {
      velocity: [0.5566796749, 0.829085145, 1.0105970188],
      direction: [-10.7376063557, 2.9764981675, 24.6560392403],
      "speed factor": 1.1976840099,
      "Uz factor": 0.9997578931,
      "Directional variance factor": 0.7354223851,
      "Velocity stability factor": 0.5386220321,
      "Direction stability factor": 0.9016843178,
      score: 0.7693532632,
    },
    "112.5": {
      velocity: [0.5389054839, 0.9298259538, 1.1120353203],
      direction: [-10.7364289814, 10.3954939221, 25.4543949566],
      "speed factor": 0.8886462123,
      "Uz factor": 0.9999872349,
      "Directional variance factor": 0.0759560958,
      "Velocity stability factor": 0.5358611765,
      "Direction stability factor": 0.8994699335,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.8770668693, 0.9816833355, 1.0745987364],
      direction: [-0.1770112199, 3.8970196066, 7.1234624823],
      "speed factor": 1.1355188486,
      "Uz factor": 0.9992296215,
      "Directional variance factor": 0.6535982572,
      "Velocity stability factor": 0.7987871111,
      "Direction stability factor": 0.9797209064,
      score: 0.8529567953,
    },
    "157.5": {
      velocity: [0.1960001636, 0.633982651, 1.0530036309],
      direction: [-83.1066049084, -17.4544291524, 30.492918249],
      "speed factor": 0.6080723068,
      "Uz factor": 0.9987013946,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3606290172,
      "Direction stability factor": 0.684445769,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1306972177, 0.5943762415, 0.9387019566],
      direction: [-46.6677250615, -0.3295590867, 80.0018139892],
      "speed factor": 0.8319075357,
      "Uz factor": 0.998696876,
      "Directional variance factor": 0.970705859,
      "Velocity stability factor": 0.2100124489,
      "Direction stability factor": 0.6481401693,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.122719291, 1.1386595592, 1.1460550156],
      direction: [-1.8961838076, -0.8924910631, 0.0345545383],
      "speed factor": 1.354195956,
      "Uz factor": 0.9999727187,
      "Directional variance factor": 0.9206674611,
      "Velocity stability factor": 0.9813575133,
      "Direction stability factor": 0.9946368379,
      score: 0.9728246625,
    },
    "225.0": {
      velocity: [0.3149753611, 0.6020370634, 0.8209051484],
      direction: [-30.8029971164, 0.1320389737, 17.5763789387],
      "speed factor": 0.7290361402,
      "Uz factor": 0.9970947113,
      "Directional variance factor": 0.9882632023,
      "Velocity stability factor": 0.5247665358,
      "Direction stability factor": 0.8656128443,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.8607820808, 0.9370932318, 0.9918309947],
      direction: [-0.2253993639, 2.0134269346, 4.2625438839],
      "speed factor": 1.1740500015,
      "Uz factor": 0.9909209382,
      "Directional variance factor": 0.8210287169,
      "Velocity stability factor": 0.8960828439,
      "Direction stability factor": 0.987533491,
      score: 0.9230446357,
    },
    "270.0": {
      velocity: [0.9753492144, 1.0389958286, 1.0883433639],
      direction: [-4.2613485848, -2.0388551167, -0.0755927433],
      "speed factor": 1.4571458773,
      "Uz factor": 0.99808288,
      "Directional variance factor": 0.8187684341,
      "Velocity stability factor": 0.903653262,
      "Direction stability factor": 0.9883729004,
      score: 0.9247918742,
    },
    "292.5": {
      velocity: [1.2250132998, 1.23229858, 1.2384467943],
      direction: [-4.4401424024, -3.9960437402, -3.5969771049],
      "speed factor": 1.2316926303,
      "Uz factor": 0.9917759103,
      "Directional variance factor": 0.644796112,
      "Velocity stability factor": 0.9863046369,
      "Direction stability factor": 0.9976578742,
      score: 0.9066041243,
    },
    "315.0": {
      velocity: [0.9095158673, 0.9156832038, 0.9248824631],
      direction: [0.3830259733, 0.669467284, 0.8670879897],
      "speed factor": 1.1379607521,
      "Uz factor": 0.9997708301,
      "Directional variance factor": 0.940491797,
      "Velocity stability factor": 0.9752663948,
      "Direction stability factor": 0.9986553833,
      score: 0.9782672396,
    },
    "337.5": {
      velocity: [1.1180300276, 1.1859161982, 1.2285982562],
      direction: [-3.2741346359, -1.8110218985, -0.4742853921],
      "speed factor": 1.185641449,
      "Uz factor": 0.9999929668,
      "Directional variance factor": 0.8390202757,
      "Velocity stability factor": 0.889803903,
      "Direction stability factor": 0.992222641,
      score: 0.9283173652,
    },
    id: "IDN:2152.0",
    zone: 1,
  },
  {
    position: [-32.15217071, 16.02873893, 20.8740369],
    "overall score": 0.5887708215,
    "0": {
      velocity: [0.6639756596, 0.7855554405, 0.9256493884],
      direction: [2.6925001487, 6.885967511, 11.5665101838],
      "speed factor": 1.301176512,
      "Uz factor": 0.9857016209,
      "Directional variance factor": 0.387913999,
      "Velocity stability factor": 0.7093317644,
      "Direction stability factor": 0.9753499721,
      score: 0.7619864269,
    },
    "22.5": {
      velocity: [0.3458421583, 0.6803050091, 1.045812638],
      direction: [-23.2364094438, 17.9078494209, 76.4710766116],
      "speed factor": 0.9001141081,
      "Uz factor": 0.9865949246,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4085821737,
      "Direction stability factor": 0.723034761,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.652189361, 0.8597391763, 1.0377171822],
      direction: [-10.1174891888, -0.5206660959, 10.4628235933],
      "speed factor": 1.3857190011,
      "Uz factor": 0.990150391,
      "Directional variance factor": 0.9537185693,
      "Velocity stability factor": 0.5844491568,
      "Direction stability factor": 0.9428324645,
      score: 0.8559581638,
    },
    "67.5": {
      velocity: [0.6158544083, 0.8926764357, 0.9729025258],
      direction: [-6.7650514413, -1.7232687679, 4.7603478618],
      "speed factor": 1.2131663562,
      "Uz factor": 0.9950176119,
      "Directional variance factor": 0.846820554,
      "Velocity stability factor": 0.6372096999,
      "Direction stability factor": 0.9679850019,
      score: 0.8550000644,
    },
    "90.0": {
      velocity: [0.5060881211, 0.7963467923, 1.0034274577],
      direction: [-13.9546173164, 4.4825214932, 22.6278771708],
      "speed factor": 1.1503906748,
      "Uz factor": 0.9999999752,
      "Directional variance factor": 0.6015536451,
      "Velocity stability factor": 0.4944863517,
      "Direction stability factor": 0.8983819598,
      score: 0.7232009791,
    },
    "112.5": {
      velocity: [0.7217760186, 1.0986252838, 1.2096864787],
      direction: [-9.6971359093, 9.1394067032, 14.7510278934],
      "speed factor": 1.0499698285,
      "Uz factor": 0.998339818,
      "Directional variance factor": 0.187608293,
      "Velocity stability factor": 0.6048745458,
      "Direction stability factor": 0.9320884339,
      score: 0.6641649266,
    },
    "135.0": {
      velocity: [0.1473092884, 0.4090004865, 0.7395785434],
      direction: [-20.1138652339, 4.4958442543, 16.8932477049],
      "speed factor": 0.4730932519,
      "Uz factor": 0.9996420031,
      "Directional variance factor": 0.6003693996,
      "Velocity stability factor": 0.3966937615,
      "Direction stability factor": 0.8972024641,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3494816317, 0.6890069107, 1.0678998764],
      direction: [-57.6456715237, -13.6429426109, 17.9349152409],
      "speed factor": 0.6608477708,
      "Uz factor": 0.999995948,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4640210959,
      "Direction stability factor": 0.7900539257,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.227720087, 0.6251361857, 0.9853685145],
      direction: [-53.981129686, -2.2567152241, 60.1462369717],
      "speed factor": 0.8749601135,
      "Uz factor": 0.9999858963,
      "Directional variance factor": 0.7994030912,
      "Velocity stability factor": 0.2592458967,
      "Direction stability factor": 0.6829795371,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1063720682, 1.1176983547, 1.1284339236],
      direction: [-1.3170663745, -0.4668423051, 0.3727012472],
      "speed factor": 1.3292670137,
      "Uz factor": 0.9997825291,
      "Directional variance factor": 0.9585029062,
      "Velocity stability factor": 0.9823751842,
      "Direction stability factor": 0.9953062011,
      score: 0.9828726231,
    },
    "225.0": {
      velocity: [0.4143973861, 0.7297833995, 0.927709523],
      direction: [-17.69913954, -0.9433213827, 11.2386105666],
      "speed factor": 0.8837304297,
      "Uz factor": 0.9968670016,
      "Directional variance factor": 0.9161492104,
      "Velocity stability factor": 0.5178320962,
      "Direction stability factor": 0.9196173608,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.8675560675, 0.9358251632, 0.9954308108],
      direction: [-1.1453986956, 1.5875589278, 4.5684637923],
      "speed factor": 1.1724612844,
      "Uz factor": 0.9919861376,
      "Directional variance factor": 0.8588836509,
      "Velocity stability factor": 0.898599849,
      "Direction stability factor": 0.9841281598,
      score: 0.9314349548,
    },
    "270.0": {
      velocity: [1.0003656737, 1.1041755614, 1.166558348],
      direction: [-5.4097822839, -2.7777964414, 2.3685698726],
      "speed factor": 1.5485575811,
      "Uz factor": 0.9984773138,
      "Directional variance factor": 0.7530847608,
      "Velocity stability factor": 0.8582924681,
      "Direction stability factor": 0.9783934662,
      score: 0.8920410403,
    },
    "292.5": {
      velocity: [1.1413559188, 1.1458217535, 1.1512470063],
      direction: [-3.1172830459, -2.7582884135, -2.3366370187],
      "speed factor": 1.1452583264,
      "Uz factor": 0.9956929853,
      "Directional variance factor": 0.7548188077,
      "Velocity stability factor": 0.9899160985,
      "Direction stability factor": 0.9978315388,
      score: 0.935099496,
    },
    "315.0": {
      velocity: [0.8527410142, 0.8612559836, 0.8682269058],
      direction: [5.1639564505, 5.4770846719, 5.7376340839],
      "speed factor": 1.0703215945,
      "Uz factor": 0.996022177,
      "Directional variance factor": 0.5131480292,
      "Velocity stability factor": 0.97507438,
      "Direction stability factor": 0.998406451,
      score: 0.8712588278,
    },
    "337.5": {
      velocity: [1.0144602022, 1.0995051909, 1.1416785269],
      direction: [-0.8200578756, 0.7702360807, 1.966674969],
      "speed factor": 1.0992504612,
      "Uz factor": 0.9905636172,
      "Directional variance factor": 0.9315345706,
      "Velocity stability factor": 0.873209845,
      "Direction stability factor": 0.9922590754,
      score: 0.9473156416,
    },
    id: "IDN:3650.0",
    zone: 1,
  },
  {
    position: [-33.8936219, 15.9587457, 20.8740369],
    "overall score": 0.5883139501,
    "0": {
      velocity: [0.7002787694, 0.8215018966, 0.9408659382],
      direction: [3.7153615086, 7.3790311616, 11.3072990873],
      "speed factor": 1.3607174202,
      "Uz factor": 0.9938133567,
      "Directional variance factor": 0.344086119,
      "Velocity stability factor": 0.7327548005,
      "Direction stability factor": 0.9789112845,
      score: 0.7586658721,
    },
    "22.5": {
      velocity: [0.3305323861, 0.6668488696, 1.0278872662],
      direction: [-21.026703834, 18.1788715179, 60.529703766],
      "speed factor": 0.8823102394,
      "Uz factor": 0.9871401986,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4107921415,
      "Direction stability factor": 0.7734544233,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6478065595, 0.8629836232, 1.0350350717],
      direction: [-8.6752419045, 0.2880113318, 11.5310081625],
      "speed factor": 1.3909483682,
      "Uz factor": 0.9932833195,
      "Directional variance factor": 0.9743989927,
      "Velocity stability factor": 0.5826160244,
      "Direction stability factor": 0.9438715276,
      score: 0.8611895181,
    },
    "67.5": {
      velocity: [0.6389309656, 0.8965253676, 0.9863302263],
      direction: [-7.0103447095, -1.2793176555, 6.3270541788],
      "speed factor": 1.2183971369,
      "Uz factor": 0.9955709417,
      "Directional variance factor": 0.8862828751,
      "Velocity stability factor": 0.6470137332,
      "Direction stability factor": 0.9629516698,
      score: 0.8647999869,
    },
    "90.0": {
      velocity: [0.5157268591, 0.8115650747, 1.0483567978],
      direction: [-23.6620639002, 4.6131832279, 20.6632245838],
      "speed factor": 1.1723747782,
      "Uz factor": 0.9999999919,
      "Directional variance factor": 0.5899392686,
      "Velocity stability factor": 0.4586157104,
      "Direction stability factor": 0.8768741987,
      score: 0.7005758441,
    },
    "112.5": {
      velocity: [0.6642139628, 1.0744058892, 1.2023712224],
      direction: [-8.60575244, 9.1453112772, 16.1640071083],
      "speed factor": 1.0268230523,
      "Uz factor": 0.9986584573,
      "Directional variance factor": 0.187083442,
      "Velocity stability factor": 0.5641830848,
      "Direction stability factor": 0.9311951124,
      score: 0.6534141879,
    },
    "135.0": {
      velocity: [0.2424006731, 0.5374492006, 0.929704964],
      direction: [-12.0940289255, 4.809092762, 13.4465279529],
      "speed factor": 0.6216706298,
      "Uz factor": 0.999981821,
      "Directional variance factor": 0.5725250878,
      "Velocity stability factor": 0.2998877403,
      "Direction stability factor": 0.9290540087,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2687833776, 0.6909795235, 1.0666163089],
      direction: [-63.8960466581, -15.9477135479, 22.8279497241],
      "speed factor": 0.6627397646,
      "Uz factor": 0.9999865081,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4047734403,
      "Direction stability factor": 0.75910001,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.2000992079, 0.6397280316, 1.0135994157],
      direction: [-33.4295522876, -3.3772096276, 29.7287676436],
      "speed factor": 0.8953833164,
      "Uz factor": 0.9998076668,
      "Directional variance factor": 0.6998035887,
      "Velocity stability factor": 0.2046395201,
      "Direction stability factor": 0.8245602224,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.096508653, 1.1063181221, 1.1168049908],
      direction: [-1.728089405, -1.0363551913, -0.4019360074],
      "speed factor": 1.3157326217,
      "Uz factor": 0.998809994,
      "Directional variance factor": 0.9078795386,
      "Velocity stability factor": 0.9837856241,
      "Direction stability factor": 0.9963162406,
      score: 0.9710744109,
    },
    "225.0": {
      velocity: [0.4153759143, 0.7699412082, 0.8982235798],
      direction: [-9.838830045, 0.802593244, 8.0722890374],
      "speed factor": 0.9323594854,
      "Uz factor": 0.9970450308,
      "Directional variance factor": 0.9286583783,
      "Velocity stability factor": 0.5464481939,
      "Direction stability factor": 0.9502468914,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.9224244064, 0.9729810604, 1.0161282159],
      direction: [-0.7783673769, 1.1209910949, 3.3695173984],
      "speed factor": 1.2190125557,
      "Uz factor": 0.9909465484,
      "Directional variance factor": 0.9003563471,
      "Velocity stability factor": 0.9256961916,
      "Direction stability factor": 0.9884780978,
      score: 0.9507521836,
    },
    "270.0": {
      velocity: [1.0534682847, 1.1226090798, 1.1759240425],
      direction: [-5.1778827551, -3.392610198, -0.1719452377],
      "speed factor": 1.5744097786,
      "Uz factor": 0.9974622653,
      "Directional variance factor": 0.6984346491,
      "Velocity stability factor": 0.8955856311,
      "Direction stability factor": 0.986094618,
      score: 0.891552379,
    },
    "292.5": {
      velocity: [1.096031515, 1.0999337046, 1.1039946699],
      direction: [-4.5256625489, -4.2341707345, -3.9158536669],
      "speed factor": 1.0993928418,
      "Uz factor": 0.9911363799,
      "Directional variance factor": 0.623629268,
      "Velocity stability factor": 0.9918816138,
      "Direction stability factor": 0.9983060864,
      score: 0.9030307637,
    },
    "315.0": {
      velocity: [0.8699972008, 0.8754643653, 0.8830586751],
      direction: [3.4660116441, 3.7498095144, 3.9783712047],
      "speed factor": 1.0879789903,
      "Uz factor": 0.9966259676,
      "Directional variance factor": 0.6666835987,
      "Velocity stability factor": 0.9789766483,
      "Direction stability factor": 0.998576779,
      score: 0.9107034513,
    },
    "337.5": {
      velocity: [1.0524130711, 1.1046845632, 1.1373925984],
      direction: [-0.1704773557, 1.2742608351, 2.1660017539],
      "speed factor": 1.1044286334,
      "Uz factor": 0.9946987511,
      "Directional variance factor": 0.8867323702,
      "Velocity stability factor": 0.9153064823,
      "Direction stability factor": 0.9935097803,
      score: 0.9472646033,
    },
    id: "IDN:3651.0",
    zone: 1,
  },
  {
    position: [-31.8936219, 9.3169522, 20.8740369],
    "overall score": 0.5881904122,
    "0": {
      velocity: [0.6646020572, 0.8140327399, 0.948951559],
      direction: [-0.2636233617, 5.0375763725, 9.8539914843],
      "speed factor": 1.3483456756,
      "Uz factor": 0.9999290161,
      "Directional variance factor": 0.5522154336,
      "Velocity stability factor": 0.6841434243,
      "Direction stability factor": 0.9718955143,
      score: 0.7950374716,
    },
    "22.5": {
      velocity: [0.1998967042, 0.6600953465, 1.0277479612],
      direction: [-59.8094189662, 16.4304014043, 113.5964934012],
      "speed factor": 0.8733746277,
      "Uz factor": 0.9999849736,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3005333723,
      "Direction stability factor": 0.5183169101,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.4969901532, 0.7993305346, 1.0530071216],
      direction: [-16.547991002, -6.7293545736, 10.599915743],
      "speed factor": 1.2883529569,
      "Uz factor": 0.9983628001,
      "Directional variance factor": 0.401835149,
      "Velocity stability factor": 0.4006831484,
      "Direction stability factor": 0.9245891479,
      score: 0.6629241483,
    },
    "67.5": {
      velocity: [0.5664608593, 0.8418394349, 0.946048314],
      direction: [-13.2968089708, -4.9949433149, 2.2162908676],
      "speed factor": 1.1440777854,
      "Uz factor": 0.999996243,
      "Directional variance factor": 0.5560050387,
      "Velocity stability factor": 0.6143078767,
      "Direction stability factor": 0.956908056,
      score: 0.7710322568,
    },
    "90.0": {
      velocity: [0.5570809281, 0.8226186516, 1.0030830477],
      direction: [-18.470291731, 2.7731561105, 33.2381165511],
      "speed factor": 1.1883426101,
      "Uz factor": 0.9995549763,
      "Directional variance factor": 0.7534972346,
      "Velocity stability factor": 0.5466673517,
      "Direction stability factor": 0.8563655325,
      score: 0.7532239128,
    },
    "112.5": {
      velocity: [0.665220442, 0.9399535547, 1.111609673],
      direction: [-1.6766117871, 9.6889487329, 17.5770670708],
      "speed factor": 0.8983252864,
      "Uz factor": 0.9991243572,
      "Directional variance factor": 0.1387601126,
      "Velocity stability factor": 0.6384997616,
      "Direction stability factor": 0.9465175587,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.0862558795, 1.1285656997, 1.1582831366],
      direction: [0.1569243095, 2.0922615557, 3.9721293097],
      "speed factor": 1.3054185373,
      "Uz factor": 0.9974859655,
      "Directional variance factor": 0.814021195,
      "Velocity stability factor": 0.9266305094,
      "Direction stability factor": 0.9894022083,
      score: 0.9298640303,
    },
    "157.5": {
      velocity: [0.0903575117, 0.6204617519, 1.0964174389],
      direction: [-75.0610300759, -17.9178628515, 23.2445796086],
      "speed factor": 0.5951039956,
      "Uz factor": 0.9977690295,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2494248285,
      "Direction stability factor": 0.726928862,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1517483415, 0.5822868291, 0.957489701],
      direction: [-49.3970205849, -0.0823837033, 96.2622709568],
      "speed factor": 0.8149868168,
      "Uz factor": 0.9970718124,
      "Directional variance factor": 0.9926770042,
      "Velocity stability factor": 0.2122253587,
      "Direction stability factor": 0.5953908568,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1430796577, 1.1601719848, 1.16757171],
      direction: [-4.1000749092, -2.7672721906, -1.6023316727],
      "speed factor": 1.3797804598,
      "Uz factor": 0.9998983311,
      "Directional variance factor": 0.7540202497,
      "Velocity stability factor": 0.980433744,
      "Direction stability factor": 0.9930618243,
      score: 0.9301444106,
    },
    "225.0": {
      velocity: [0.4045334747, 0.6388925334, 0.838522166],
      direction: [-43.9011908231, -1.4719622603, 22.3156487267],
      "speed factor": 0.7736662323,
      "Uz factor": 0.9987479909,
      "Directional variance factor": 0.8691589102,
      "Velocity stability factor": 0.5923427433,
      "Direction stability factor": 0.8160643346,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.7964610573, 0.9150608746, 0.9976572559],
      direction: [-2.3975542239, 2.5930068925, 9.1931284601],
      "speed factor": 1.146446463,
      "Uz factor": 0.9909856848,
      "Directional variance factor": 0.7695104984,
      "Velocity stability factor": 0.8404585269,
      "Direction stability factor": 0.9678036592,
      score: 0.886394086,
    },
    "270.0": {
      velocity: [0.9613902729, 1.0226157456, 1.0767195162],
      direction: [-4.7598619288, -2.6953976661, -1.1190071497],
      "speed factor": 1.4341735324,
      "Uz factor": 0.9971681506,
      "Directional variance factor": 0.7604090963,
      "Velocity stability factor": 0.9016621972,
      "Direction stability factor": 0.9898865145,
      score: 0.9104610806,
    },
    "292.5": {
      velocity: [1.2161364689, 1.2235007501, 1.231308413],
      direction: [-3.6645088698, -3.0968441542, -2.5179972626],
      "speed factor": 1.2228991265,
      "Uz factor": 0.9899665427,
      "Directional variance factor": 0.7247249641,
      "Velocity stability factor": 0.9845322986,
      "Direction stability factor": 0.9968152455,
      score: 0.9257219384,
    },
    "315.0": {
      velocity: [0.8932160677, 0.9016747446, 0.9119571293],
      direction: [-1.5218305453, -1.2278438271, -1.0040920379],
      "speed factor": 1.1205518089,
      "Uz factor": 0.9994303046,
      "Directional variance factor": 0.8908583265,
      "Velocity stability factor": 0.9698349573,
      "Direction stability factor": 0.9985618375,
      score: 0.9644542397,
    },
    "337.5": {
      velocity: [1.112165902, 1.1716596084, 1.2026694067],
      direction: [-5.3523803633, -4.1317545621, -2.5843506734],
      "speed factor": 1.1713881621,
      "Uz factor": 0.9999547283,
      "Directional variance factor": 0.6327329278,
      "Velocity stability factor": 0.9098010966,
      "Direction stability factor": 0.9923110286,
      score: 0.8817890204,
    },
    id: "IDN:2146.0",
    zone: 1,
  },
  {
    position: [-30.41071953, 16.09873216, 20.8740369],
    "overall score": 0.587985514,
    "0": {
      velocity: [0.6540261265, 0.7611124157, 0.8988135421],
      direction: [0.3354731196, 5.3562777879, 10.7104577193],
      "speed factor": 1.2606896308,
      "Uz factor": 0.9798571924,
      "Directional variance factor": 0.5238864189,
      "Velocity stability factor": 0.7280891495,
      "Direction stability factor": 0.9711805983,
      score: 0.7985841913,
    },
    "22.5": {
      velocity: [0.321970276, 0.6888782716, 1.0489677686],
      direction: [-25.3173568472, 17.4185492499, 58.408779117],
      "speed factor": 0.9114574238,
      "Uz factor": 0.9878390094,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3857465576,
      "Direction stability factor": 0.7674274001,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6384089006, 0.8570773135, 1.0108033656],
      direction: [-12.211798135, -1.5891201271, 8.864454886],
      "speed factor": 1.3814286372,
      "Uz factor": 0.9879820203,
      "Directional variance factor": 0.8587448776,
      "Velocity stability factor": 0.5986052746,
      "Direction stability factor": 0.9414548527,
      score: 0.8350649644,
    },
    "67.5": {
      velocity: [0.6461506022, 0.8922685928, 0.975242923],
      direction: [-6.0656196648, -2.1685138018, 1.2946540693],
      "speed factor": 1.2126120889,
      "Uz factor": 0.9948501913,
      "Directional variance factor": 0.8072432176,
      "Velocity stability factor": 0.6656150923,
      "Direction stability factor": 0.9795547952,
      score: 0.8579919751,
    },
    "90.0": {
      velocity: [0.4498595547, 0.7922331329, 1.0414856756],
      direction: [-13.316472999, 4.1275811561, 23.3913014502],
      "speed factor": 1.144448144,
      "Uz factor": 0.9999952442,
      "Directional variance factor": 0.6331038972,
      "Velocity stability factor": 0.3986498619,
      "Direction stability factor": 0.8980339599,
      score: 0.7069554197,
    },
    "112.5": {
      velocity: [0.8073929115, 1.1243359913, 1.2277748098],
      direction: [-10.058351631, 8.9102016466, 13.7079402914],
      "speed factor": 1.074541871,
      "Uz factor": 0.9977017896,
      "Directional variance factor": 0.2079820759,
      "Velocity stability factor": 0.6595613292,
      "Direction stability factor": 0.9339825224,
      score: 0.6838771125,
    },
    "135.0": {
      velocity: [0.4717794946, 0.6657738157, 0.8377866939],
      direction: [-0.2655525607, 5.2384298839, 12.7896608439],
      "speed factor": 0.7701044617,
      "Uz factor": 0.9997057,
      "Directional variance factor": 0.5343617881,
      "Velocity stability factor": 0.6271722281,
      "Direction stability factor": 0.9637355183,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3783991815, 0.7109747336, 1.0433971568],
      direction: [-51.7433668706, -12.3166764978, 17.7616521851],
      "speed factor": 0.681917787,
      "Uz factor": 0.99999863,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5038755089,
      "Direction stability factor": 0.8069305026,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1506454604, 0.6121431205, 0.9117331075],
      direction: [-57.3296950647, -2.6709759366, 61.1137173933],
      "speed factor": 0.856774614,
      "Uz factor": 0.999400983,
      "Directional variance factor": 0.7625799167,
      "Velocity stability factor": 0.2558833661,
      "Direction stability factor": 0.6709905209,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1018535059, 1.1160990302, 1.1266689227],
      direction: [-2.3100198134, -1.2563779898, -0.3944238096],
      "speed factor": 1.3273649538,
      "Uz factor": 0.9999787481,
      "Directional variance factor": 0.8883219565,
      "Velocity stability factor": 0.9801754139,
      "Direction stability factor": 0.9946789,
      score: 0.9644637926,
    },
    "225.0": {
      velocity: [0.2488953383, 0.6343856316, 0.9264051709],
      direction: [-50.6267730087, -0.8340386834, 18.8102504885],
      "speed factor": 0.7682086044,
      "Uz factor": 0.996657968,
      "Directional variance factor": 0.9258632281,
      "Velocity stability factor": 0.3635967819,
      "Direction stability factor": 0.8071193792,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.813020187, 0.8978791546, 0.983844089],
      direction: [-1.6377716628, 2.1752801635, 5.8680174808],
      "speed factor": 1.1249201114,
      "Uz factor": 0.9928523012,
      "Directional variance factor": 0.8066417632,
      "Velocity stability factor": 0.8645426844,
      "Direction stability factor": 0.9791505857,
      score: 0.9073714048,
    },
    "270.0": {
      velocity: [0.8770831543, 1.0818341311, 1.1536982928],
      direction: [-4.6216384986, -1.7636199959, 6.2203353652],
      "speed factor": 1.5172247093,
      "Uz factor": 0.9991030296,
      "Directional variance factor": 0.8432337781,
      "Velocity stability factor": 0.7641385294,
      "Direction stability factor": 0.9698834059,
      score: 0.8867847799,
    },
    "292.5": {
      velocity: [1.1514817696, 1.1553511307, 1.1617672863],
      direction: [-0.553736694, -0.1582188331, 0.3122284588],
      "speed factor": 1.1547830178,
      "Uz factor": 0.9977543156,
      "Directional variance factor": 0.9859361037,
      "Velocity stability factor": 0.9895139805,
      "Direction stability factor": 0.9975945412,
      score: 0.9926597917,
    },
    "315.0": {
      velocity: [0.8186294007, 0.829147066, 0.8358394786],
      direction: [6.8237279471, 7.174989371, 7.4915094813],
      "speed factor": 1.0304183968,
      "Uz factor": 0.9938311533,
      "Directional variance factor": 0.362223167,
      "Velocity stability factor": 0.9722991821,
      "Direction stability factor": 0.9981450513,
      score: 0.8327031129,
    },
    "337.5": {
      velocity: [0.9489593504, 1.0802144443, 1.1365518641],
      direction: [-2.1511502382, -0.2960924706, 1.7139552346],
      "speed factor": 1.0799641837,
      "Uz factor": 0.9877444514,
      "Directional variance factor": 0.9736806693,
      "Velocity stability factor": 0.8130388532,
      "Direction stability factor": 0.9892635959,
      score: 0.9413116786,
    },
    id: "IDN:3649.0",
    zone: 1,
  },
  {
    position: [-31.8936219, 15.3169522, 20.8740369],
    "overall score": 0.5855388767,
    "0": {
      velocity: [0.6779059685, 0.7968941107, 0.9438728289],
      direction: [2.4743787137, 6.6382522543, 11.1640577795],
      "speed factor": 1.3199576324,
      "Uz factor": 0.9867499653,
      "Directional variance factor": 0.4099331329,
      "Velocity stability factor": 0.7045629365,
      "Direction stability factor": 0.9758620026,
      score: 0.7665550187,
    },
    "22.5": {
      velocity: [0.3549010452, 0.6923531876, 1.074646355],
      direction: [-24.4023751094, 17.3970662081, 71.2602478561],
      "speed factor": 0.9160550982,
      "Uz factor": 0.9894338906,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.391874059,
      "Direction stability factor": 0.7342704918,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6536737689, 0.8629183158, 1.0450663544],
      direction: [-11.6525475423, -1.602268771, 9.4999352343],
      "speed factor": 1.3908431064,
      "Uz factor": 0.9900001426,
      "Directional variance factor": 0.8575761092,
      "Velocity stability factor": 0.5781276734,
      "Direction stability factor": 0.9412431034,
      score: 0.8295474973,
    },
    "67.5": {
      velocity: [0.5691567719, 0.8886074724, 0.9690776349],
      direction: [-7.7331513498, -2.7612190501, 2.2253610501],
      "speed factor": 1.207636548,
      "Uz factor": 0.9955329743,
      "Directional variance factor": 0.7545583067,
      "Velocity stability factor": 0.5936474587,
      "Direction stability factor": 0.9723374656,
      score: 0.8232201741,
    },
    "90.0": {
      velocity: [0.5349806201, 0.8058154133, 1.0054529906],
      direction: [-19.1809730528, 3.837656563, 20.3245816899],
      "speed factor": 1.1640689032,
      "Uz factor": 0.9999891429,
      "Directional variance factor": 0.6588749722,
      "Velocity stability factor": 0.5217949057,
      "Direction stability factor": 0.8902623479,
      score: 0.7402986434,
    },
    "112.5": {
      velocity: [0.6724821862, 1.0606714294, 1.2021847418],
      direction: [-9.9522321232, 9.3169278254, 15.2747440136],
      "speed factor": 1.0136968585,
      "Uz factor": 0.9985137767,
      "Directional variance factor": 0.1718286377,
      "Velocity stability factor": 0.5710299738,
      "Direction stability factor": 0.9299250663,
      score: 0.650677186,
    },
    "135.0": {
      velocity: [0.1347417875, 0.3978739499, 0.7375558249],
      direction: [-19.4674529675, 4.9404369299, 16.6582854707],
      "speed factor": 0.460223122,
      "Uz factor": 0.999747478,
      "Directional variance factor": 0.5608500507,
      "Velocity stability factor": 0.3859524762,
      "Direction stability factor": 0.8996507266,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3622777857, 0.6773414153, 1.0650514667],
      direction: [-64.7877461152, -14.317879857, 17.8497490064],
      "speed factor": 0.6496590345,
      "Uz factor": 0.9999983209,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4756927874,
      "Direction stability factor": 0.7704514024,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1949645496, 0.6132205197, 0.9658425833],
      direction: [-49.3814419913, -1.8029216408, 60.7161741283],
      "speed factor": 0.8582825755,
      "Uz factor": 0.9999832954,
      "Directional variance factor": 0.8397402986,
      "Velocity stability factor": 0.2463112892,
      "Direction stability factor": 0.6941732886,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1045796401, 1.1165535492, 1.1261026857],
      direction: [-1.230538357, -0.3562979357, 0.4932560315],
      "speed factor": 1.3279055085,
      "Uz factor": 0.9999071478,
      "Directional variance factor": 0.9683290724,
      "Velocity stability factor": 0.9828056295,
      "Direction stability factor": 0.9952116823,
      score: 0.9853895166,
    },
    "225.0": {
      velocity: [0.3095736829, 0.7116635812, 0.9268268678],
      direction: [-21.9968697276, -0.3365931186, 13.6259763502],
      "speed factor": 0.8617882551,
      "Uz factor": 0.9966999242,
      "Directional variance factor": 0.9700806117,
      "Velocity stability factor": 0.4201974727,
      "Direction stability factor": 0.9010476498,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.8680504914, 0.9363875253, 1.0021474683],
      direction: [-0.8040307558, 1.6960414341, 4.0361993861],
      "speed factor": 1.1731658475,
      "Uz factor": 0.9922343955,
      "Directional variance factor": 0.8492407614,
      "Velocity stability factor": 0.8936658377,
      "Direction stability factor": 0.9865549163,
      score: 0.9290041079,
    },
    "270.0": {
      velocity: [1.020825743, 1.1048340956, 1.1618323798],
      direction: [-5.2655114821, -2.4180910818, 2.1119924803],
      "speed factor": 1.5494811462,
      "Uz factor": 0.9985050681,
      "Directional variance factor": 0.7850585705,
      "Velocity stability factor": 0.8797678504,
      "Direction stability factor": 0.9795069334,
      score: 0.9059600719,
    },
    "292.5": {
      velocity: [1.1657794567, 1.1707918304, 1.176352967],
      direction: [-3.1386421744, -2.7716302489, -2.3470116784],
      "speed factor": 1.170216125,
      "Uz factor": 0.9967230579,
      "Directional variance factor": 0.7536328668,
      "Velocity stability factor": 0.9892203728,
      "Direction stability factor": 0.9978010264,
      score: 0.9346138231,
    },
    "315.0": {
      velocity: [0.8695097707, 0.8779870582, 0.8853992135],
      direction: [5.4730951322, 5.7894686351, 6.0422491976],
      "speed factor": 1.0911140544,
      "Uz factor": 0.9975834207,
      "Directional variance factor": 0.4853805658,
      "Velocity stability factor": 0.9744248363,
      "Direction stability factor": 0.9984190165,
      score: 0.8641608588,
    },
    "337.5": {
      velocity: [1.0374370304, 1.1265688663, 1.1731886036],
      direction: [-0.6011068152, 1.0403183546, 2.1802637811],
      "speed factor": 1.1263078665,
      "Uz factor": 0.9922937603,
      "Directional variance factor": 0.9075272574,
      "Velocity stability factor": 0.8647053163,
      "Direction stability factor": 0.9922739706,
      score: 0.9391951287,
    },
    id: "IDN:2164.0",
    zone: 1,
  },
  {
    position: [-29.8936219, 15.3169522, 20.8740369],
    "overall score": 0.583263128,
    "0": {
      velocity: [0.6799137851, 0.7773361727, 0.9114622483],
      direction: [-1.0102502412, 4.8327124415, 10.1717301721],
      "speed factor": 1.2875623002,
      "Uz factor": 0.9822882165,
      "Directional variance factor": 0.5704255608,
      "Velocity stability factor": 0.7427950312,
      "Direction stability factor": 0.9689389433,
      score: 0.8127746196,
    },
    "22.5": {
      velocity: [0.3713535122, 0.7089917861, 1.0792541252],
      direction: [-25.6794740128, 16.6005362564, 55.4164807748],
      "speed factor": 0.9380696902,
      "Uz factor": 0.9905370689,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4018818595,
      "Direction stability factor": 0.7747334589,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.633340972, 0.8634375941, 1.0308549989],
      direction: [-14.2871580224, -3.1634451071, 7.3431186689],
      "speed factor": 1.391680074,
      "Uz factor": 0.9886494267,
      "Directional variance factor": 0.7188048794,
      "Velocity stability factor": 0.571529524,
      "Direction stability factor": 0.9399158981,
      score: 0.7925415499,
    },
    "67.5": {
      velocity: [0.6283234312, 0.8892400365, 0.9726191747],
      direction: [-8.2105231469, -3.4191737843, 0.1476656222],
      "speed factor": 1.2084962162,
      "Uz factor": 0.9957196846,
      "Directional variance factor": 0.6960734414,
      "Velocity stability factor": 0.6501671624,
      "Direction stability factor": 0.976782809,
      score: 0.8249515554,
    },
    "90.0": {
      velocity: [0.4328293048, 0.8050921298, 1.0382718935],
      direction: [-15.8341790332, 3.4793090276, 21.0200831047],
      "speed factor": 1.1630240587,
      "Uz factor": 0.999954902,
      "Directional variance factor": 0.6907280864,
      "Velocity stability factor": 0.3846063055,
      "Direction stability factor": 0.8976270496,
      score: 0.7176471228,
    },
    "112.5": {
      velocity: [0.7643450974, 1.100110591, 1.2085968236],
      direction: [-14.8755814043, 9.0502816566, 13.8299812467],
      "speed factor": 1.0513893551,
      "Uz factor": 0.9975386227,
      "Directional variance factor": 0.1955305194,
      "Velocity stability factor": 0.6402307811,
      "Direction stability factor": 0.920262326,
      score: 0.6690714881,
    },
    "135.0": {
      velocity: [0.4700916045, 0.6520557312, 0.8157107804],
      direction: [0.5950488307, 5.3311757482, 13.4933528955],
      "speed factor": 0.7542366733,
      "Uz factor": 0.9996150874,
      "Directional variance factor": 0.5261177113,
      "Velocity stability factor": 0.6479401839,
      "Direction stability factor": 0.9641713776,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.366693653, 0.7019532847, 1.0279251285],
      direction: [-50.4818212441, -13.2339185424, 19.6356510398],
      "speed factor": 0.6732650373,
      "Uz factor": 0.999989653,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5066855217,
      "Direction stability factor": 0.8052292437,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0694974505, 0.5876057029, 0.8776354235],
      direction: [-76.0368255603, -1.7038355011, 108.4836329271],
      "speed factor": 0.8224312786,
      "Uz factor": 0.9992075377,
      "Directional variance factor": 0.8485479555,
      "Velocity stability factor": 0.2098821857,
      "Direction stability factor": 0.4874431709,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0947510638, 1.110765204, 1.1203658754],
      direction: [-2.4262227307, -1.3197402498, -0.4578150746],
      "speed factor": 1.3210214899,
      "Uz factor": 0.9999972499,
      "Directional variance factor": 0.8826897556,
      "Velocity stability factor": 0.9795367919,
      "Direction stability factor": 0.994532201,
      score: 0.9628227374,
    },
    "225.0": {
      velocity: [0.2165073079, 0.5931740851, 0.8485285311],
      direction: [-34.2764650724, 0.540011853, 32.4091868002],
      "speed factor": 0.7183035261,
      "Uz factor": 0.9968803495,
      "Directional variance factor": 0.9519989464,
      "Velocity stability factor": 0.4063254568,
      "Direction stability factor": 0.8147620781,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.8049528316, 0.893072631, 0.9862243339],
      direction: [-1.2324331348, 2.4012852814, 5.9160198087],
      "speed factor": 1.118898193,
      "Uz factor": 0.9936605438,
      "Directional variance factor": 0.7865524194,
      "Velocity stability factor": 0.8562581067,
      "Direction stability factor": 0.9801431863,
      score: 0.9007742247,
    },
    "270.0": {
      velocity: [0.9791942785, 1.0819746268, 1.1599204218],
      direction: [-3.993393971, -1.1102883372, 8.6794905027],
      "speed factor": 1.5174217483,
      "Uz factor": 0.9994169034,
      "Directional variance factor": 0.9013077034,
      "Velocity stability factor": 0.8459002131,
      "Direction stability factor": 0.9647975431,
      score: 0.9192007507,
    },
    "292.5": {
      velocity: [1.1749533764, 1.1792521589, 1.1848533325],
      direction: [0.0741390583, 0.4979920088, 0.9967767684],
      "speed factor": 1.1786722934,
      "Uz factor": 0.9992961666,
      "Directional variance factor": 0.9557340437,
      "Velocity stability factor": 0.989907057,
      "Direction stability factor": 0.9974371175,
      score: 0.9851288339,
    },
    "315.0": {
      velocity: [0.8322825347, 0.8431066391, 0.8500582514],
      direction: [7.8713944489, 8.239850622, 8.5663052166],
      "speed factor": 1.0477665869,
      "Uz factor": 0.9960475719,
      "Directional variance factor": 0.2675688336,
      "Velocity stability factor": 0.9713887471,
      "Direction stability factor": 0.9980696923,
      score: 0.8087742413,
    },
    "337.5": {
      velocity: [0.9669999909, 1.1085672544, 1.1732847574],
      direction: [-1.6450580488, 0.2060067321, 2.3160404962],
      "speed factor": 1.1083104251,
      "Uz factor": 0.9899194717,
      "Directional variance factor": 0.9816882905,
      "Velocity stability factor": 0.7944095116,
      "Direction stability factor": 0.9889969485,
      score: 0.9385229248,
    },
    id: "IDN:2165.0",
    zone: 1,
  },
  {
    position: [-28.66926834, 16.16872539, 20.8740369],
    "overall score": 0.578740806,
    "0": {
      velocity: [0.6656212741, 0.7514116102, 0.8682402088],
      direction: [-2.1965384623, 4.1799862162, 9.6452307329],
      "speed factor": 1.2446214329,
      "Uz factor": 0.9788551499,
      "Directional variance factor": 0.6284456697,
      "Velocity stability factor": 0.7749300685,
      "Direction stability factor": 0.9671061967,
      score: 0.8343970329,
    },
    "22.5": {
      velocity: [0.328651406, 0.7004212203, 1.0496339031],
      direction: [-25.173750698, 16.8013143552, 54.9862156002],
      "speed factor": 0.9267299425,
      "Uz factor": 0.9878191756,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.390828737,
      "Direction stability factor": 0.7773334269,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6367619621, 0.8566764615, 1.011543543],
      direction: [-13.9070054326, -2.621781738, 7.4905185731],
      "speed factor": 1.3807825481,
      "Uz factor": 0.9876709743,
      "Directional variance factor": 0.7669527344,
      "Velocity stability factor": 0.5960322618,
      "Direction stability factor": 0.9405624333,
      score: 0.8110274657,
    },
    "67.5": {
      velocity: [0.678107483, 0.8934060789, 0.972469383],
      direction: [-7.0444947209, -2.4921193609, 0.8925090642],
      "speed factor": 1.2141579569,
      "Uz factor": 0.994780032,
      "Directional variance factor": 0.778478279,
      "Velocity stability factor": 0.7009040609,
      "Direction stability factor": 0.9779527673,
      score: 0.8588219686,
    },
    "90.0": {
      velocity: [0.430796425, 0.7974399388, 1.0548429145],
      direction: [-16.9396422335, 4.0685865021, 22.9191904826],
      "speed factor": 1.1519698179,
      "Uz factor": 0.9999857792,
      "Directional variance factor": 0.6383478665,
      "Velocity stability factor": 0.3656966295,
      "Direction stability factor": 0.8892810202,
      score: 0.6956516341,
    },
    "112.5": {
      velocity: [0.7502014737, 1.135459673, 1.2426042815],
      direction: [-14.384303731, 8.3283771194, 12.2715705336],
      "speed factor": 1.0851729118,
      "Uz factor": 0.9973123748,
      "Directional variance factor": 0.2596998116,
      "Velocity stability factor": 0.6012364994,
      "Direction stability factor": 0.9259559048,
      score: 0.6782120302,
    },
    "135.0": {
      velocity: [0.9140515906, 0.982622482, 1.051359614],
      direction: [1.5837846682, 5.0077338648, 7.8859267149],
      "speed factor": 1.1366051648,
      "Uz factor": 0.9997196772,
      "Directional variance factor": 0.5548681009,
      "Velocity stability factor": 0.8601332309,
      "Direction stability factor": 0.9824940499,
      score: 0.8449973579,
    },
    "157.5": {
      velocity: [0.385689144, 0.7451666332, 1.0048596297],
      direction: [-49.3907803964, -12.1029874893, 11.9665292232],
      "speed factor": 0.7147122921,
      "Uz factor": 0.9999069582,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5380652971,
      "Direction stability factor": 0.8295630288,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.189166327, 0.582385853, 0.9003277382],
      direction: [-130.5155475867, -2.0638102547, 47.3749383525],
      "speed factor": 0.8151254138,
      "Uz factor": 0.9989360212,
      "Directional variance factor": 0.8165501996,
      "Velocity stability factor": 0.3046963283,
      "Direction stability factor": 0.5058597613,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0997794353, 1.1189456291, 1.1297487112],
      direction: [-3.1604242412, -1.7398925388, -0.8636190626],
      "speed factor": 1.3307503843,
      "Uz factor": 0.9999554729,
      "Directional variance factor": 0.8453428854,
      "Velocity stability factor": 0.9760580895,
      "Direction stability factor": 0.9936199856,
      score: 0.9521602365,
    },
    "225.0": {
      velocity: [0.2493346193, 0.5685990005, 0.8159109674],
      direction: [-49.1609803477, -1.6298399552, 21.7856156974],
      "speed factor": 0.6885443535,
      "Uz factor": 0.9976683885,
      "Directional variance factor": 0.8551253373,
      "Velocity stability factor": 0.4677995892,
      "Direction stability factor": 0.8029261221,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.7607401602, 0.8604247053, 0.9813467618],
      direction: [-2.4730938574, 2.9626704647, 11.2422138976],
      "speed factor": 1.0779947952,
      "Uz factor": 0.9938196805,
      "Directional variance factor": 0.7366515142,
      "Velocity stability factor": 0.8250667634,
      "Direction stability factor": 0.9619019229,
      score: 0.8713805309,
    },
    "270.0": {
      velocity: [0.6922052527, 1.0509374975, 1.1504455666],
      direction: [-3.8463957613, -0.7734330373, 10.4116098202],
      "speed factor": 1.4738935418,
      "Uz factor": 0.9994289737,
      "Directional variance factor": 0.9312503967,
      "Velocity stability factor": 0.6092721647,
      "Direction stability factor": 0.9603944289,
      score: 0.8653278548,
    },
    "292.5": {
      velocity: [1.1395481321, 1.1440941069, 1.1499410971],
      direction: [1.6858360133, 2.0752469754, 2.540879697],
      "speed factor": 1.1435315294,
      "Uz factor": 0.9980472025,
      "Directional variance factor": 0.8155336022,
      "Velocity stability factor": 0.9894044376,
      "Direction stability factor": 0.9976248787,
      score: 0.9500469493,
    },
    "315.0": {
      velocity: [0.7907369364, 0.8006255626, 0.8064704275],
      direction: [7.6721668079, 8.052410542, 8.4216825105],
      "speed factor": 0.9949734402,
      "Uz factor": 0.9910780982,
      "Directional variance factor": 0.284230174,
      "Velocity stability factor": 0.9746758514,
      "Direction stability factor": 0.9979180119,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.8701477962, 1.0456531964, 1.1219556033],
      direction: [-3.5927908101, -1.4347520492, 1.8408385131],
      "speed factor": 1.0454109429,
      "Uz factor": 0.9867389018,
      "Directional variance factor": 0.8724664845,
      "Velocity stability factor": 0.7490396847,
      "Direction stability factor": 0.9849065852,
      score: 0.8978298349,
    },
    id: "IDN:3648.0",
    zone: 1,
  },
  {
    position: [-29.8936219, 9.3169522, 20.8740369],
    "overall score": 0.5732928593,
    "0": {
      velocity: [0.7348798988, 0.8043272085, 0.9007511747],
      direction: [-1.445423483, 5.1717911385, 11.0207931418],
      "speed factor": 1.3322696499,
      "Uz factor": 0.9998675936,
      "Directional variance factor": 0.5402852321,
      "Velocity stability factor": 0.8157495165,
      "Direction stability factor": 0.9653716205,
      score: 0.8216944974,
    },
    "22.5": {
      velocity: [0.1415192548, 0.6835863709, 1.1255546493],
      direction: [-32.3223362987, 15.68203575, 89.3498170408],
      "speed factor": 0.9044556902,
      "Uz factor": 0.9999134167,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1685705458,
      "Direction stability factor": 0.6620217963,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.5029968278, 0.812093934, 1.0496457039],
      direction: [-18.8201462252, -7.548507067, 13.5181978448],
      "speed factor": 1.3089248765,
      "Uz factor": 0.9987551095,
      "Directional variance factor": 0.329021594,
      "Velocity stability factor": 0.4107807819,
      "Direction stability factor": 0.9101712665,
      score: 0.6400362272,
    },
    "67.5": {
      velocity: [0.5032556394, 0.836257613, 0.9403590207],
      direction: [-13.261527255, -4.8619011645, 8.658361874],
      "speed factor": 1.1364919702,
      "Uz factor": 0.9999529455,
      "Directional variance factor": 0.5678310076,
      "Velocity stability factor": 0.5558669572,
      "Direction stability factor": 0.9391114191,
      score: 0.7504802007,
    },
    "90.0": {
      velocity: [0.5023389737, 0.8209436101, 1.0440354679],
      direction: [-9.6730259756, 3.0381339169, 35.2373791788],
      "speed factor": 1.1859228702,
      "Uz factor": 0.9987419553,
      "Directional variance factor": 0.7299436518,
      "Velocity stability factor": 0.4494001362,
      "Direction stability factor": 0.8752488746,
      score: 0.7324603843,
    },
    "112.5": {
      velocity: [0.5609768975, 0.9775231523, 1.1246050035],
      direction: [-10.9120823564, 10.4763370649, 17.2614915946],
      "speed factor": 0.9342310174,
      "Uz factor": 0.9994845038,
      "Directional variance factor": 0.0687700387,
      "Velocity stability factor": 0.5435559809,
      "Direction stability factor": 0.9217400724,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.9291962267, 1.0134208793, 1.0939643986],
      direction: [1.1959453347, 3.6324633218, 6.2205535332],
      "speed factor": 1.1722298509,
      "Uz factor": 0.997844199,
      "Directional variance factor": 0.6771143714,
      "Velocity stability factor": 0.8321613604,
      "Direction stability factor": 0.986042755,
      score: 0.8703403105,
    },
    "157.5": {
      velocity: [0.1266111541, 0.6118479589, 1.0098602456],
      direction: [-87.7323880558, -17.4171619215, 23.5561099956],
      "speed factor": 0.5868422413,
      "Uz factor": 0.997772829,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3410483606,
      "Direction stability factor": 0.6908652832,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1261470083, 0.5644663273, 0.8401699466],
      direction: [-69.4187577109, -0.8501354509, 132.6016583667],
      "speed factor": 0.7900446864,
      "Uz factor": 0.9938932633,
      "Directional variance factor": 0.9244324044,
      "Velocity stability factor": 0.3018986087,
      "Direction stability factor": 0.4388321776,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1802592147, 1.1919090974, 1.201125762],
      direction: [-7.8779817538, -6.4267819034, -5.0813120636],
      "speed factor": 1.4175250772,
      "Uz factor": 0.9990996559,
      "Directional variance factor": 0.4287304975,
      "Velocity stability factor": 0.9833300942,
      "Direction stability factor": 0.9922314731,
      score: 0.8491308844,
    },
    "225.0": {
      velocity: [0.4010172394, 0.7735350417, 0.9443459949],
      direction: [-31.5645386851, -4.7941822767, 10.8357717004],
      "speed factor": 0.9367114341,
      "Uz factor": 0.9983472395,
      "Directional variance factor": 0.5738504643,
      "Velocity stability factor": 0.4896366786,
      "Direction stability factor": 0.88222136,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.6536111007, 0.8393695003, 0.9951463519],
      direction: [-8.2938538458, 3.3173889176, 16.5148313577],
      "speed factor": 1.0516154952,
      "Uz factor": 0.9930662499,
      "Directional variance factor": 0.7051209851,
      "Velocity stability factor": 0.7291746193,
      "Direction stability factor": 0.9310869855,
      score: 0.8241173939,
    },
    "270.0": {
      velocity: [0.9341106108, 1.002293674, 1.0569081494],
      direction: [-4.6693992438, -2.5705514105, -0.5116940232],
      "speed factor": 1.4056727224,
      "Uz factor": 0.9985742898,
      "Directional variance factor": 0.7715065413,
      "Velocity stability factor": 0.8952942048,
      "Direction stability factor": 0.9884508188,
      score: 0.9109255959,
    },
    "292.5": {
      velocity: [1.2455754582, 1.2542478894, 1.2630477759],
      direction: [-4.799807741, -4.3023791386, -3.7394264679],
      "speed factor": 1.2536311467,
      "Uz factor": 0.9987918664,
      "Directional variance factor": 0.6175662988,
      "Velocity stability factor": 0.9821870821,
      "Direction stability factor": 0.9970544965,
      score: 0.8984655934,
    },
    "315.0": {
      velocity: [0.9095992362, 0.9159138581, 0.9252746254],
      direction: [0.8565776958, 1.1333222815, 1.3412573949],
      "speed factor": 1.1382473966,
      "Uz factor": 0.9999963441,
      "Directional variance factor": 0.8992602416,
      "Velocity stability factor": 0.9747693704,
      "Direction stability factor": 0.9986536675,
      score: 0.9678342368,
    },
    "337.5": {
      velocity: [1.0718046963, 1.1695370335, 1.2152597996],
      direction: [-3.888818531, -2.3524902735, -0.4479798226],
      "speed factor": 1.1692660789,
      "Uz factor": 0.9986667807,
      "Directional variance factor": 0.7908897535,
      "Velocity stability factor": 0.8570277134,
      "Direction stability factor": 0.9904421147,
      score: 0.9072004241,
    },
    id: "IDN:2147.0",
    zone: 1,
  },
  {
    position: [-27.8936219, 5.3169522, 20.8740369],
    "overall score": 0.5714381579,
    "0": {
      velocity: [0.6324179989, 0.785416861, 0.9020853368],
      direction: [-3.8881785905, 4.3003903976, 11.872810325],
      "speed factor": 1.3009469719,
      "Uz factor": 0.9991040956,
      "Directional variance factor": 0.6177430758,
      "Velocity stability factor": 0.7004524312,
      "Direction stability factor": 0.9562194752,
      score: 0.8076586144,
    },
    "22.5": {
      velocity: [0.1663028394, 0.6849281717, 1.1129779924],
      direction: [-29.2958346473, 14.7639573159, 113.8911628493],
      "speed factor": 0.9062310319,
      "Uz factor": 0.9991464739,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2001368952,
      "Direction stability factor": 0.6022583403,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3559997744, 0.6587995749, 1.0055190172],
      direction: [-19.8718764274, -6.1217325022, 33.2911893969],
      "speed factor": 1.0618465623,
      "Uz factor": 0.9999900828,
      "Directional variance factor": 0.4558459998,
      "Velocity stability factor": 0.2998993739,
      "Direction stability factor": 0.8523248172,
      score: 0.615098752,
    },
    "67.5": {
      velocity: [0.692738828, 0.8810080342, 0.9643549545],
      direction: [-6.9959129731, -3.3676704658, 1.1333956051],
      "speed factor": 1.1973087491,
      "Uz factor": 0.998545983,
      "Directional variance factor": 0.7006515142,
      "Velocity stability factor": 0.7240156405,
      "Direction stability factor": 0.9774185873,
      score: 0.8448760823,
    },
    "90.0": {
      velocity: [0.250286507, 0.7269271778, 0.936311774],
      direction: [-19.2642110118, 2.3646121333, 34.1272701766],
      "speed factor": 1.0501081372,
      "Uz factor": 0.9997640235,
      "Directional variance factor": 0.7898122548,
      "Velocity stability factor": 0.3026991634,
      "Direction stability factor": 0.85169033,
      score: 0.6989730196,
    },
    "112.5": {
      velocity: [1.0511593767, 1.1517585046, 1.2235353735],
      direction: [2.2048427743, 7.1238876267, 9.7002271644],
      "speed factor": 1.1007499076,
      "Uz factor": 0.99618614,
      "Directional variance factor": 0.3667655443,
      "Velocity stability factor": 0.8604044193,
      "Direction stability factor": 0.9791794878,
      score: 0.7963822348,
    },
    "135.0": {
      velocity: [1.1455006457, 1.1578653477, 1.1706788095],
      direction: [-0.6197523409, -0.0492690207, 1.0870062756],
      "speed factor": 1.3393096113,
      "Uz factor": 0.9922842464,
      "Directional variance factor": 0.9956205315,
      "Velocity stability factor": 0.9743526391,
      "Direction stability factor": 0.9952590038,
      score: 0.9901227946,
    },
    "157.5": {
      velocity: [0.0963296258, 0.5797551797, 1.0333842164],
      direction: [-99.5568134096, -18.0579754697, 28.828401994],
      "speed factor": 0.5560610673,
      "Uz factor": 0.9945736143,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3009065454,
      "Direction stability factor": 0.6433744017,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1263542769, 0.5018227129, 0.8051554417],
      direction: [-67.1110449937, -0.4998594114, 82.6595901358],
      "speed factor": 0.7023667289,
      "Uz factor": 0.9951360803,
      "Directional variance factor": 0.9555680523,
      "Velocity stability factor": 0.3363349941,
      "Direction stability factor": 0.583970458,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1796362484, 1.1987951545, 1.2182803911],
      direction: [-9.7169685436, -9.165300982, -8.6138934022],
      "speed factor": 1.4257145932,
      "Uz factor": 0.9850392758,
      "Directional variance factor": 0.1853065794,
      "Velocity stability factor": 0.9691278959,
      "Direction stability factor": 0.9969359024,
      score: 0.78707657,
    },
    "225.0": {
      velocity: [0.4446794233, 0.8066556077, 1.0888291701],
      direction: [-50.7323103496, -13.2355468702, 1.1299940125],
      "speed factor": 0.9768187482,
      "Uz factor": 0.9974784219,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3949328083,
      "Direction stability factor": 0.8559380434,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3791747779, 0.696679099, 1.0241510489],
      direction: [-33.3394403927, 2.8474418641, 37.4731011399],
      "speed factor": 0.8728438852,
      "Uz factor": 0.9963875259,
      "Directional variance factor": 0.7468940565,
      "Velocity stability factor": 0.4885566173,
      "Direction stability factor": 0.8032984957,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.9251901064, 1.0024530786, 1.0800922478],
      direction: [-4.2654698143, -0.7880174916, 2.1496392314],
      "speed factor": 1.4058962803,
      "Uz factor": 0.9972248008,
      "Directional variance factor": 0.9299540007,
      "Velocity stability factor": 0.8679195685,
      "Direction stability factor": 0.9821802527,
      score: 0.9405585186,
    },
    "292.5": {
      velocity: [1.0909728768, 1.1569048032, 1.2196440466],
      direction: [-9.4031169013, -5.4638828869, -2.9993507809],
      "speed factor": 1.1563359263,
      "Uz factor": 0.998944344,
      "Directional variance factor": 0.5143215212,
      "Velocity stability factor": 0.8688205526,
      "Direction stability factor": 0.9822117608,
      score: 0.8368913988,
    },
    "315.0": {
      velocity: [0.8905142474, 0.9003730138, 0.9116455447],
      direction: [1.2482628255, 1.5958998195, 1.8906616295],
      "speed factor": 1.1189340894,
      "Uz factor": 0.9999106336,
      "Directional variance factor": 0.8581422383,
      "Velocity stability factor": 0.9659877066,
      "Direction stability factor": 0.9982155589,
      score: 0.9551402657,
    },
    "337.5": {
      velocity: [1.0216333538, 1.1135615412, 1.1547604521],
      direction: [-5.4052300959, -4.0942113123, -1.3620766002],
      "speed factor": 1.1133035549,
      "Uz factor": 0.9996821136,
      "Directional variance factor": 0.6360701056,
      "Velocity stability factor": 0.8673209582,
      "Direction stability factor": 0.9887690181,
      score: 0.870232275,
    },
    id: "IDN:2136.0",
    zone: 1,
  },
  {
    position: [54.3509434, 5.2920371, 21.1598299],
    "overall score": 0.5713525641,
    "0": {
      velocity: [0.4946371804, 0.6766960257, 0.8434947757],
      direction: [-19.4914979034, -8.0576983158, 3.1417017068],
      "speed factor": 1.1208642051,
      "Uz factor": 0.9952766952,
      "Directional variance factor": 0.2837601497,
      "Velocity stability factor": 0.6124875732,
      "Direction stability factor": 0.9371300011,
      score: 0.6926269313,
    },
    "22.5": {
      velocity: [0.5298173553, 0.8487345713, 1.0689011776],
      direction: [-11.9178280112, 3.7418736259, 14.3381871334],
      "speed factor": 1.1229638933,
      "Uz factor": 0.9981133738,
      "Directional variance factor": 0.667389011,
      "Velocity stability factor": 0.5445182452,
      "Direction stability factor": 0.9270666246,
      score: 0.7665101264,
    },
    "45.0": {
      velocity: [0.4629327692, 0.7575035991, 0.8830362452],
      direction: [-17.3006697421, -2.0647957469, 5.6076314306],
      "speed factor": 1.2209367209,
      "Uz factor": 0.9942955343,
      "Directional variance factor": 0.8164626003,
      "Velocity stability factor": 0.547180919,
      "Direction stability factor": 0.9363658301,
      score: 0.8090937949,
    },
    "67.5": {
      velocity: [0.8208133353, 0.9288740563, 1.024143811],
      direction: [-3.1595812538, -0.7534951351, 4.1302621539],
      "speed factor": 1.2623596962,
      "Uz factor": 0.9994900307,
      "Directional variance factor": 0.9330226547,
      "Velocity stability factor": 0.7933994869,
      "Direction stability factor": 0.979750435,
      score: 0.9214807529,
    },
    "90.0": {
      velocity: [0.9838424658, 1.016842407, 1.0469535555],
      direction: [3.9431504093, 4.9600800125, 5.9876537105],
      "speed factor": 1.4689153445,
      "Uz factor": 0.9939158508,
      "Directional variance factor": 0.5591039989,
      "Velocity stability factor": 0.9358516111,
      "Direction stability factor": 0.9943208242,
      score: 0.8708993146,
    },
    "112.5": {
      velocity: [0.5698264103, 1.0203833688, 1.1324105939],
      direction: [-5.8576688084, 3.9818925493, 16.3542093479],
      "speed factor": 0.9751930586,
      "Uz factor": 0.9969459563,
      "Directional variance factor": 0.6460539956,
      "Velocity stability factor": 0.5444013825,
      "Direction stability factor": 0.9383003385,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.9293354268, 1.0219369421, 1.0800760075],
      direction: [-1.9640751623, 0.2591861646, 3.9721669172],
      "speed factor": 1.1820804305,
      "Uz factor": 0.9950725813,
      "Directional variance factor": 0.9769612298,
      "Velocity stability factor": 0.8464503569,
      "Direction stability factor": 0.9835104387,
      score: 0.947608116,
    },
    "157.5": {
      velocity: [1.0839321617, 1.092975714, 1.1026984712],
      direction: [0.364636163, 0.8333389188, 1.3549183815],
      "speed factor": 1.0483067048,
      "Uz factor": 0.9960407377,
      "Directional variance factor": 0.9259254294,
      "Velocity stability factor": 0.9859993171,
      "Direction stability factor": 0.9972492161,
      score: 0.9766057947,
    },
    "180.0": {
      velocity: [0.3897700213, 0.8299101345, 1.0233609632],
      direction: [-25.418989002, -6.1971849788, 6.4938537988],
      "speed factor": 1.1615681224,
      "Uz factor": 0.9991671471,
      "Directional variance factor": 0.449139113,
      "Velocity stability factor": 0.3805371028,
      "Direction stability factor": 0.9113532144,
      score: 0.6630956612,
    },
    "202.5": {
      velocity: [0.1699886081, 0.462644105, 0.8524318789],
      direction: [-45.2803063591, 18.5263887266, 92.4106527235],
      "speed factor": 0.5502178162,
      "Uz factor": 0.9938036787,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.454808459,
      "Direction stability factor": 0.6175251137,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1022672506, 1.1194984216, 1.1331594031],
      direction: [3.8814115806, 4.9531852167, 5.9819233949],
      "speed factor": 1.3556554203,
      "Uz factor": 0.991953386,
      "Directional variance factor": 0.5597168696,
      "Velocity stability factor": 0.9709821699,
      "Direction stability factor": 0.994165245,
      score: 0.8797573824,
    },
    "247.5": {
      velocity: [0.8905075809, 1.0236262793, 1.1365667064],
      direction: [-10.4693902015, -2.578544805, 6.4831454245],
      "speed factor": 1.2824641071,
      "Uz factor": 0.9979447845,
      "Directional variance factor": 0.7707960173,
      "Velocity stability factor": 0.8048838118,
      "Direction stability factor": 0.9529096233,
      score: 0.8703747689,
    },
    "270.0": {
      velocity: [0.7882340614, 0.9741362903, 1.0673794169],
      direction: [-4.0895213059, 7.6919416957, 14.6123719314],
      "speed factor": 1.3661832322,
      "Uz factor": 0.9998048799,
      "Directional variance factor": 0.3162718493,
      "Velocity stability factor": 0.7619810889,
      "Direction stability factor": 0.9480502966,
      score: 0.7435883828,
    },
    "292.5": {
      velocity: [0.4701106288, 0.5251590741, 0.5868826908],
      direction: [3.8617990335, 11.2665313783, 16.3556663752],
      "speed factor": 0.5249008413,
      "Uz factor": 0.9989534283,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8809516182,
      "Direction stability factor": 0.9652948129,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4156072882, 0.4453217278, 0.4701786122],
      direction: [6.1527301467, 10.1420089744, 13.5238806241],
      "speed factor": 0.5534213647,
      "Uz factor": 0.9953169089,
      "Directional variance factor": 0.0984880912,
      "Velocity stability factor": 0.9121636569,
      "Direction stability factor": 0.979524582,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3141544643, 0.5507844148, 0.7398494858],
      direction: [-66.8490230518, -10.6453925646, 18.7399024508],
      "speed factor": 0.5506568108,
      "Uz factor": 0.9976096215,
      "Directional variance factor": 0.0537428831,
      "Velocity stability factor": 0.5757377102,
      "Direction stability factor": 0.7622529847,
      score: 0.0,
    },
    id: "IDN:3583.0",
    zone: 1,
  },
  {
    position: [-20.95960261, 4.041812513, 21.1598299],
    "overall score": 0.5692643561,
    "0": {
      velocity: [0.552274704, 0.8249459719, 0.981666686],
      direction: [-13.8213903064, 2.9619194679, 10.9664454592],
      "speed factor": 1.3664221096,
      "Uz factor": 0.9998805553,
      "Directional variance factor": 0.7367182695,
      "Velocity stability factor": 0.5230296509,
      "Direction stability factor": 0.9311449007,
      score: 0.7805094304,
    },
    "22.5": {
      velocity: [0.2716252949, 0.8012896847, 1.1915318979],
      direction: [-33.6354416468, 8.125802143, 45.0599859999],
      "speed factor": 1.0601893859,
      "Uz factor": 0.9974643486,
      "Directional variance factor": 0.2777064762,
      "Velocity stability factor": 0.2227541313,
      "Direction stability factor": 0.7814015899,
      score: 0.5158159468,
    },
    "45.0": {
      velocity: [0.314292732, 0.688377733, 1.0588661506],
      direction: [-35.8686750259, -12.3761255009, 25.5982390189],
      "speed factor": 1.1095203416,
      "Uz factor": 0.9994863792,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1974428436,
      "Direction stability factor": 0.8292585721,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.6901442952, 0.9264570753, 0.9926097214],
      direction: [-5.0439953032, -2.9074018067, 7.9749247777],
      "speed factor": 1.2590749674,
      "Uz factor": 0.9994335409,
      "Directional variance factor": 0.7415642838,
      "Velocity stability factor": 0.6926702107,
      "Direction stability factor": 0.9638363331,
      score: 0.8404767902,
    },
    "90.0": {
      velocity: [0.2699900652, 0.6984933669, 0.9938114255],
      direction: [-27.5840605234, 1.5781074241, 45.8225180478],
      "speed factor": 1.0090330789,
      "Uz factor": 0.9999979385,
      "Directional variance factor": 0.8597237845,
      "Velocity stability factor": 0.2642818503,
      "Direction stability factor": 0.7960928373,
      score: 0.6790478274,
    },
    "112.5": {
      velocity: [1.1621676072, 1.1954738828, 1.2486911165],
      direction: [5.306397294, 8.0420108078, 9.5843817392],
      "speed factor": 1.1425292375,
      "Uz factor": 0.9930436504,
      "Directional variance factor": 0.2851545949,
      "Velocity stability factor": 0.9299305022,
      "Direction stability factor": 0.9881167099,
      score: 0.7978296292,
    },
    "135.0": {
      velocity: [1.1637977822, 1.1698059474, 1.1772849216],
      direction: [0.5742415471, 0.9736386506, 1.3931825431],
      "speed factor": 1.3531213728,
      "Uz factor": 0.9865811035,
      "Directional variance factor": 0.9134543422,
      "Velocity stability factor": 0.9862615267,
      "Direction stability factor": 0.9977251639,
      score: 0.9737915492,
    },
    "157.5": {
      velocity: [0.2181556425, 0.6445028126, 0.9926808852],
      direction: [-104.1310748805, -15.9892486327, 19.7792100385],
      "speed factor": 0.6181625182,
      "Uz factor": 0.9944037203,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4221622379,
      "Direction stability factor": 0.6558047641,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0495712936, 0.3913397258, 0.7679915526],
      direction: [-162.6149172532, -2.7870141195, 107.7514586381],
      "speed factor": 0.5477312923,
      "Uz factor": 0.999977478,
      "Directional variance factor": 0.7522654116,
      "Velocity stability factor": 0.297599341,
      "Direction stability factor": 0.2489822892,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.132397604, 1.1486073361, 1.1679744316],
      direction: [-1.4686313546, -0.6127632154, 0.0411957515],
      "speed factor": 1.3660267435,
      "Uz factor": 0.9683884356,
      "Directional variance factor": 0.9455321586,
      "Velocity stability factor": 0.9715783182,
      "Direction stability factor": 0.9958060358,
      score: 0.9771806371,
    },
    "225.0": {
      velocity: [0.5523278142, 0.8144234647, 1.0280972572],
      direction: [-42.6813272859, -8.1073039965, 7.5049723213],
      "speed factor": 0.9862252264,
      "Uz factor": 0.987415429,
      "Directional variance factor": 0.2793507559,
      "Velocity stability factor": 0.5530969588,
      "Direction stability factor": 0.8605936122,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2058053605, 0.5538388781, 0.8657020176],
      direction: [-55.5652843558, 5.5045932042, 66.1940526828],
      "speed factor": 0.6938845715,
      "Uz factor": 0.9998706356,
      "Directional variance factor": 0.5107028263,
      "Velocity stability factor": 0.4767252786,
      "Direction stability factor": 0.6617796193,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.7289042234, 0.9351635576, 1.0779402692],
      direction: [-8.2004775703, 0.1492409173, 5.7675489041],
      "speed factor": 1.3115256915,
      "Uz factor": 0.9999716404,
      "Directional variance factor": 0.9867341407,
      "Velocity stability factor": 0.7023873839,
      "Direction stability factor": 0.9611999265,
      score: 0.9028803444,
    },
    "292.5": {
      velocity: [1.1128394724, 1.1841841748, 1.237267119],
      direction: [-4.1345559513, 2.0111230557, 5.5862950002],
      "speed factor": 1.1836018841,
      "Uz factor": 0.9980434513,
      "Directional variance factor": 0.8212335062,
      "Velocity stability factor": 0.8731467978,
      "Direction stability factor": 0.9729976362,
      score: 0.9100938941,
    },
    "315.0": {
      velocity: [0.9117150881, 0.9232471839, 0.9344946162],
      direction: [2.2493399891, 2.6996317483, 3.0070897492],
      "speed factor": 1.1473608507,
      "Uz factor": 0.9996224925,
      "Directional variance factor": 0.7600327335,
      "Velocity stability factor": 0.9633347644,
      "Direction stability factor": 0.9978951396,
      score: 0.9297894442,
    },
    "337.5": {
      velocity: [0.5612230699, 1.0039525082, 1.1637675037],
      direction: [-5.5093254918, -1.3977380721, 7.4476688988],
      "speed factor": 1.0037199157,
      "Uz factor": 0.9981063522,
      "Directional variance factor": 0.8757566158,
      "Velocity stability factor": 0.3994835075,
      "Direction stability factor": 0.9640083489,
      score: 0.8008142053,
    },
    id: "IDN:3637.0",
    zone: 1,
  },
  {
    position: [54.27964944, 7.0658562, 21.1598299],
    "overall score": 0.5689085336,
    "0": {
      velocity: [0.4835641866, 0.6737600982, 0.8085620151],
      direction: [-20.6050354049, -8.758800425, 5.8121913682],
      "speed factor": 1.1160012003,
      "Uz factor": 0.996038945,
      "Directional variance factor": 0.2214399622,
      "Velocity stability factor": 0.6389910986,
      "Direction stability factor": 0.9266188145,
      score: 0.6784171725,
    },
    "22.5": {
      velocity: [0.5062046353, 0.8216188916, 1.0496072789],
      direction: [-12.1455924277, 4.1895987066, 17.2689693049],
      "speed factor": 1.0870870357,
      "Uz factor": 0.9970280981,
      "Directional variance factor": 0.6275912261,
      "Velocity stability factor": 0.5408691944,
      "Direction stability factor": 0.9182928841,
      score: 0.7512615472,
    },
    "45.0": {
      velocity: [0.4421141752, 0.7392163824, 0.8731295704],
      direction: [-14.9377360924, -0.1787833288, 8.5688064512],
      "speed factor": 1.191461568,
      "Uz factor": 0.9914305155,
      "Directional variance factor": 0.9841081485,
      "Velocity stability factor": 0.535419233,
      "Direction stability factor": 0.9347040485,
      score: 0.8472338696,
    },
    "67.5": {
      velocity: [0.7835423925, 0.9041823496, 1.005582282],
      direction: [-3.2058090544, -0.5223125649, 2.4233128215],
      "speed factor": 1.2288031391,
      "Uz factor": 0.9965568156,
      "Directional variance factor": 0.9535722165,
      "Velocity stability factor": 0.7743891812,
      "Direction stability factor": 0.9843635503,
      score: 0.9241721246,
    },
    "90.0": {
      velocity: [0.9878730988, 1.0138539075, 1.0339075811],
      direction: [4.4243496312, 5.0826355942, 6.2670780628],
      "speed factor": 1.4645982028,
      "Uz factor": 0.9943105315,
      "Directional variance factor": 0.5482101694,
      "Velocity stability factor": 0.9532088911,
      "Direction stability factor": 0.9948813099,
      score: 0.8727954201,
    },
    "112.5": {
      velocity: [0.614693663, 1.0276056061, 1.1476374384],
      direction: [-4.6249292357, 4.2824519153, 13.5981227668],
      "speed factor": 0.9820954405,
      "Uz factor": 0.9975540347,
      "Directional variance factor": 0.6193376075,
      "Velocity stability factor": 0.5684051306,
      "Direction stability factor": 0.9493804111,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.0019352513, 1.0721835345, 1.1301009933],
      direction: [-1.367317147, 1.1980228716, 5.9678958456],
      "speed factor": 1.2402009575,
      "Uz factor": 0.9980973498,
      "Directional variance factor": 0.8935090781,
      "Velocity stability factor": 0.8694458795,
      "Direction stability factor": 0.9796244084,
      score: 0.9305509436,
    },
    "157.5": {
      velocity: [1.111728544, 1.120488442, 1.1304266768],
      direction: [-0.590956515, -0.111046411, 0.4489527047],
      "speed factor": 1.0746950105,
      "Uz factor": 0.9982464571,
      "Directional variance factor": 0.9901292079,
      "Velocity stability factor": 0.9860501807,
      "Direction stability factor": 0.9971113633,
      score: 0.9926005288,
    },
    "180.0": {
      velocity: [0.4469027222, 0.8378480294, 1.0126666109],
      direction: [-33.0166125967, -6.5615830994, 3.7337851704],
      "speed factor": 1.1726782478,
      "Uz factor": 0.9995253835,
      "Directional variance factor": 0.4167481689,
      "Velocity stability factor": 0.4468517233,
      "Direction stability factor": 0.8979155618,
      score: 0.6648577539,
    },
    "202.5": {
      velocity: [0.1356420327, 0.4839674969, 0.9023448443],
      direction: [-32.2366743039, 19.3310555383, 84.6031976226],
      "speed factor": 0.5755775042,
      "Uz factor": 0.9939308281,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3874950414,
      "Direction stability factor": 0.6754448002,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1083920941, 1.1297398214, 1.1464006609],
      direction: [1.5294507459, 3.2434715962, 4.8086034565],
      "speed factor": 1.3680572325,
      "Uz factor": 0.992757359,
      "Directional variance factor": 0.7116914137,
      "Velocity stability factor": 0.9642975303,
      "Direction stability factor": 0.9908912425,
      score: 0.9144428572,
    },
    "247.5": {
      velocity: [0.7600562416, 1.0062250008, 1.1645216328],
      direction: [-16.0888863589, -4.4997670379, 9.6853969768],
      "speed factor": 1.2606626786,
      "Uz factor": 0.9982614304,
      "Directional variance factor": 0.6000207077,
      "Velocity stability factor": 0.6792732428,
      "Direction stability factor": 0.9284047685,
      score: 0.7840258719,
    },
    "270.0": {
      velocity: [0.7796030641, 0.973900482, 1.0633630565],
      direction: [0.3470846492, 8.0441527778, 13.7204962388],
      "speed factor": 1.3658525214,
      "Uz factor": 0.9999502773,
      "Directional variance factor": 0.2849641975,
      "Velocity stability factor": 0.7580463258,
      "Direction stability factor": 0.9628516345,
      score: 0.7421784481,
    },
    "292.5": {
      velocity: [0.4151855425, 0.4655317935, 0.535038968],
      direction: [3.6501335121, 10.9469507646, 17.1849519486],
      "speed factor": 0.4653028808,
      "Uz factor": 0.9998818595,
      "Directional variance factor": 0.0269377098,
      "Velocity stability factor": 0.8778101874,
      "Direction stability factor": 0.9624032821,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4209389335, 0.4425822187, 0.4619435064],
      direction: [3.4914265879, 6.9364094297, 10.2302981561],
      "speed factor": 0.5500168534,
      "Uz factor": 0.9982351437,
      "Directional variance factor": 0.3834302729,
      "Velocity stability factor": 0.9340002867,
      "Direction stability factor": 0.9812809123,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2469531726, 0.5340712844, 0.7500617814],
      direction: [-43.7474697944, -9.2745992699, 30.7497870593],
      "speed factor": 0.5339475525,
      "Uz factor": 0.9989134855,
      "Directional variance factor": 0.175591176,
      "Velocity stability factor": 0.4985846684,
      "Direction stability factor": 0.7930631754,
      score: 0.0,
    },
    id: "IDN:3584.0",
    zone: 1,
  },
  {
    position: [-25.8936219, 5.3169522, 20.8740369],
    "overall score": 0.5660690038,
    "0": {
      velocity: [0.4803867057, 0.754082456, 0.8983878957],
      direction: [-4.2588697495, 4.800154059, 15.5682862584],
      "speed factor": 1.2490453624,
      "Uz factor": 0.9986307634,
      "Directional variance factor": 0.5733196392,
      "Velocity stability factor": 0.5356825887,
      "Direction stability factor": 0.9449245666,
      score: 0.7497128403,
    },
    "22.5": {
      velocity: [0.2931878593, 0.7231202813, 1.1166373177],
      direction: [-29.6726831036, 13.1301086428, 67.393595321],
      "speed factor": 0.9567631552,
      "Uz factor": 0.9984251402,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3042525323,
      "Direction stability factor": 0.7303714488,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3320756934, 0.6376630557, 0.9952461779],
      direction: [-20.7973974309, -6.1118931275, 26.07648635],
      "speed factor": 1.0277789321,
      "Uz factor": 0.9999912385,
      "Directional variance factor": 0.4567206109,
      "Velocity stability factor": 0.2851850402,
      "Direction stability factor": 0.8697947673,
      score: 0.6203737964,
    },
    "67.5": {
      velocity: [0.7193876058, 0.8915452949, 0.9740140379],
      direction: [-5.5870186187, -3.2608446342, 0.7317729282],
      "speed factor": 1.2116291116,
      "Uz factor": 0.9985761562,
      "Directional variance factor": 0.7101471436,
      "Velocity stability factor": 0.7412785695,
      "Direction stability factor": 0.9824478013,
      score: 0.8540803289,
    },
    "90.0": {
      velocity: [0.3317826334, 0.7277167045, 0.9414384583],
      direction: [-19.7872097284, 1.9120687022, 30.4049943156],
      "speed factor": 1.0512486757,
      "Uz factor": 0.9997789778,
      "Directional variance factor": 0.8300383376,
      "Velocity stability factor": 0.3803238202,
      "Direction stability factor": 0.860577211,
      score: 0.7328791449,
    },
    "112.5": {
      velocity: [1.0785129314, 1.1587179061, 1.2304093434],
      direction: [4.472083921, 8.0513235559, 10.8421614082],
      "speed factor": 1.107401094,
      "Uz factor": 0.9949715539,
      "Directional variance factor": 0.284326795,
      "Velocity stability factor": 0.8769894403,
      "Direction stability factor": 0.9823053403,
      score: 0.781481729,
    },
    "135.0": {
      velocity: [1.0633115506, 1.0852987509, 1.1075466497],
      direction: [-2.2732709312, -1.5599117578, -0.6867626661],
      "speed factor": 1.2553714048,
      "Uz factor": 0.992219726,
      "Directional variance factor": 0.8613411771,
      "Velocity stability factor": 0.9549405763,
      "Direction stability factor": 0.9955930326,
      score: 0.9518669547,
    },
    "157.5": {
      velocity: [0.150194533, 0.5943450152, 0.9101393578],
      direction: [-73.0647427402, -17.4126221525, 31.6350405311],
      "speed factor": 0.5700546283,
      "Uz factor": 0.9936006552,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4330400189,
      "Direction stability factor": 0.7091672687,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0657177662, 0.4627554399, 0.8828836531],
      direction: [-100.4065874195, -0.8343907419, 177.4044953711],
      "speed factor": 0.6476869543,
      "Uz factor": 0.9964264854,
      "Directional variance factor": 0.9258319341,
      "Velocity stability factor": 0.2010555796,
      "Direction stability factor": 0.2283025478,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.180148304, 1.1954851304, 1.2146495183],
      direction: [-8.7015245439, -7.9896315988, -7.4131291667],
      "speed factor": 1.4217780161,
      "Uz factor": 0.9784028815,
      "Directional variance factor": 0.2898105245,
      "Velocity stability factor": 0.9724376062,
      "Direction stability factor": 0.996421124,
      score: 0.8137725947,
    },
    "225.0": {
      velocity: [0.3441208477, 0.7718084691, 0.9836468768],
      direction: [-73.9910750785, -22.8193430406, -0.3738606929],
      "speed factor": 0.9346206429,
      "Uz factor": 0.9911261591,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3992759908,
      "Direction stability factor": 0.7955077378,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2488000431, 0.6041762459, 0.9085298821],
      direction: [-37.441898429, 5.3702984276, 45.9259487099],
      "speed factor": 0.7569504274,
      "Uz factor": 0.9964036434,
      "Directional variance factor": 0.5226401398,
      "Velocity stability factor": 0.4768575594,
      "Direction stability factor": 0.7684226468,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.8644807019, 0.9702758085, 1.0738588593],
      direction: [-4.7122831886, -0.6113983866, 2.7407203151],
      "speed factor": 1.3607690766,
      "Uz factor": 0.9986412116,
      "Directional variance factor": 0.9456534767,
      "Velocity stability factor": 0.8214694959,
      "Direction stability factor": 0.9792972125,
      score: 0.9314293494,
    },
    "292.5": {
      velocity: [1.0608356228, 1.1553335617, 1.2271777446],
      direction: [-10.575311174, -4.8734487581, -1.6580866164],
      "speed factor": 1.1547654575,
      "Uz factor": 0.999530488,
      "Directional variance factor": 0.5668045548,
      "Velocity stability factor": 0.8304152543,
      "Direction stability factor": 0.9752299318,
      score: 0.8369199182,
    },
    "315.0": {
      velocity: [0.9045446813, 0.911369837, 0.9220425179],
      direction: [2.7213293769, 2.9841443233, 3.2300108179],
      "speed factor": 1.1326003368,
      "Uz factor": 0.9994480471,
      "Directional variance factor": 0.7347427268,
      "Velocity stability factor": 0.9718360144,
      "Direction stability factor": 0.998586996,
      score: 0.9259381833,
    },
    "337.5": {
      velocity: [0.9299849078, 1.0850862657, 1.1530210036],
      direction: [-5.2542078974, -3.5017593326, 0.4788244872],
      "speed factor": 1.0848348764,
      "Uz factor": 0.9992811432,
      "Directional variance factor": 0.6887325038,
      "Velocity stability factor": 0.777714561,
      "Direction stability factor": 0.98407491,
      score: 0.8586492212,
    },
    id: "IDN:2137.0",
    zone: 1,
  },
  {
    position: [-27.8936219, 13.3169522, 20.8740369],
    "overall score": 0.5657782458,
    "0": {
      velocity: [0.7410011164, 0.8091349862, 0.9092524355],
      direction: [-4.6082515676, 4.1752157934, 10.3404264651],
      "speed factor": 1.3402331457,
      "Uz factor": 0.9924814357,
      "Directional variance factor": 0.6288697073,
      "Velocity stability factor": 0.8131057549,
      "Direction stability factor": 0.9584758944,
      score: 0.8397318127,
    },
    "22.5": {
      velocity: [0.2669993953, 0.7449865639, 1.1473656295],
      direction: [-27.0079863938, 14.7392468914, 87.2154269456],
      "speed factor": 0.9856945156,
      "Uz factor": 0.9974225559,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2561625102,
      "Direction stability factor": 0.6827127407,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6468363064, 0.8711147034, 1.056616625],
      direction: [-16.7223015504, -6.3627376028, 4.0850924484],
      "speed factor": 1.4040539619,
      "Uz factor": 0.9936680938,
      "Directional variance factor": 0.4344233242,
      "Velocity stability factor": 0.5583079935,
      "Direction stability factor": 0.9422016833,
      score: 0.7192836711,
    },
    "67.5": {
      velocity: [0.5242004543, 0.8763649847, 0.963197855],
      direction: [-11.6675138427, -5.1384885051, 3.569509836],
      "speed factor": 1.1909987456,
      "Uz factor": 0.9987017251,
      "Directional variance factor": 0.5432454662,
      "Velocity stability factor": 0.5539424774,
      "Direction stability factor": 0.9576749342,
      score: 0.753134453,
    },
    "90.0": {
      velocity: [0.5392284746, 0.8297021736, 0.9903100005],
      direction: [-15.2275037948, 3.1423918692, 26.0339911176],
      "speed factor": 1.1985753602,
      "Uz factor": 0.9995420615,
      "Directional variance factor": 0.7206762783,
      "Velocity stability factor": 0.5415044599,
      "Direction stability factor": 0.8853847364,
      score: 0.7582375527,
    },
    "112.5": {
      velocity: [0.559903741, 1.0497648347, 1.1680685929],
      direction: [-17.9400326527, 10.5570490186, 18.4324805303],
      "speed factor": 1.0032732905,
      "Uz factor": 0.9967160374,
      "Directional variance factor": 0.0615956428,
      "Velocity stability factor": 0.5074887034,
      "Direction stability factor": 0.8989652412,
      score: 0.5917537071,
    },
    "135.0": {
      velocity: [0.677182027, 0.8073903738, 0.9008228303],
      direction: [2.9692607239, 5.9131372528, 9.5453409738],
      "speed factor": 0.9339131617,
      "Uz factor": 0.9990357602,
      "Directional variance factor": 0.4743877998,
      "Velocity stability factor": 0.7721916329,
      "Direction stability factor": 0.9817331104,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3562430299, 0.6901990859, 0.9508225758],
      direction: [-57.5078993473, -14.3569822891, 13.4758259465],
      "speed factor": 0.6619912228,
      "Uz factor": 0.9985170256,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5564114696,
      "Direction stability factor": 0.8028229853,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1774958821, 0.5321429308, 0.8187685293],
      direction: [-96.4078448966, -0.3894100224, 53.9466827076],
      "speed factor": 0.744803852,
      "Uz factor": 0.9976702065,
      "Directional variance factor": 0.9653857758,
      "Velocity stability factor": 0.3730266869,
      "Direction stability factor": 0.5823485344,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1060917632, 1.128257194, 1.1443441507],
      direction: [-4.801582739, -3.3080858644, -2.0328437695],
      "speed factor": 1.3418245314,
      "Uz factor": 0.9988120726,
      "Directional variance factor": 0.7059479232,
      "Velocity stability factor": 0.969440862,
      "Direction stability factor": 0.9923090584,
      score: 0.9150017255,
    },
    "225.0": {
      velocity: [0.3928438037, 0.6700601139, 0.9736587854],
      direction: [-23.9099165099, -0.6902174605, 34.179804751],
      "speed factor": 0.8114085806,
      "Uz factor": 0.9991803347,
      "Directional variance factor": 0.9386473368,
      "Velocity stability factor": 0.4544248575,
      "Direction stability factor": 0.8386396632,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.61635842, 0.8307794963, 0.9265661249],
      direction: [-4.8629082528, 1.9765422853, 7.5230621462],
      "speed factor": 1.0408533919,
      "Uz factor": 0.9948530768,
      "Directional variance factor": 0.8243073524,
      "Velocity stability factor": 0.754016256,
      "Direction stability factor": 0.9655945267,
      score: 0.8773781654,
    },
    "270.0": {
      velocity: [0.9469197294, 1.0511403424, 1.1418118291],
      direction: [-3.2363147273, -0.1933161191, 4.33592797],
      "speed factor": 1.4741780229,
      "Uz factor": 0.9999999108,
      "Directional variance factor": 0.982816345,
      "Velocity stability factor": 0.8338213249,
      "Direction stability factor": 0.9789659925,
      score: 0.9436424137,
    },
    "292.5": {
      velocity: [1.1999410858, 1.2065508302, 1.2115189552],
      direction: [1.0834997478, 1.5342365471, 2.0173077684],
      "speed factor": 1.2059575413,
      "Uz factor": 0.9992389114,
      "Directional variance factor": 0.863623418,
      "Velocity stability factor": 0.9881964351,
      "Direction stability factor": 0.9974060888,
      score: 0.9616580077,
    },
    "315.0": {
      velocity: [0.832314609, 0.8437537964, 0.8506448698],
      direction: [9.2760040458, 9.707240852, 10.0642407542],
      "speed factor": 1.0485708384,
      "Uz factor": 0.9997738321,
      "Directional variance factor": 0.1371341465,
      "Velocity stability factor": 0.9704961699,
      "Direction stability factor": 0.9978104536,
      score: 0.7758128059,
    },
    "337.5": {
      velocity: [0.9566080478, 1.1346781961, 1.2255274838],
      direction: [-1.6454407613, 0.4134047758, 3.388814557],
      "speed factor": 1.1344153175,
      "Uz factor": 0.9972113195,
      "Directional variance factor": 0.9632529088,
      "Velocity stability factor": 0.7319856472,
      "Direction stability factor": 0.9860159574,
      score: 0.9168176177,
    },
    id: "IDN:2160.0",
    zone: 1,
  },
  {
    position: [-19.45866, 4.2679947, 21.1598299],
    "overall score": 0.5637356672,
    "0": {
      velocity: [0.7377585243, 0.8692641816, 1.0016864924],
      direction: [-6.4940273282, 1.907694742, 10.559027866],
      "speed factor": 1.4398298038,
      "Uz factor": 0.999957272,
      "Directional variance factor": 0.830427134,
      "Velocity stability factor": 0.7068277463,
      "Direction stability factor": 0.9526304022,
      score: 0.8606289212,
    },
    "22.5": {
      velocity: [0.2869973963, 0.8073835492, 1.2032114633],
      direction: [-33.6625473076, 7.6070523947, 51.5498896409],
      "speed factor": 1.0682522009,
      "Uz factor": 0.9974833774,
      "Directional variance factor": 0.3238175649,
      "Velocity stability factor": 0.2258740224,
      "Direction stability factor": 0.7632987863,
      score: 0.51907229,
    },
    "45.0": {
      velocity: [0.3302680268, 0.6973133463, 1.0293129585],
      direction: [-33.6000466963, -12.7223497525, 44.1536225046],
      "speed factor": 1.1239226736,
      "Uz factor": 0.9992676214,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2465168665,
      "Direction stability factor": 0.7840175856,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.678864682, 0.9198106188, 0.98621958],
      direction: [-5.132784155, -2.9769990898, 5.5596373033],
      "speed factor": 1.250042291,
      "Uz factor": 0.9995111925,
      "Directional variance factor": 0.7353778587,
      "Velocity stability factor": 0.6877021046,
      "Direction stability factor": 0.9702988293,
      score: 0.8409194055,
    },
    "90.0": {
      velocity: [0.2753496515, 0.6933276044, 1.0031013985],
      direction: [-20.8771847974, 1.7050638352, 52.1288297209],
      "speed factor": 1.0015706955,
      "Uz factor": 0.9999725238,
      "Directional variance factor": 0.8484387702,
      "Velocity stability factor": 0.2602868634,
      "Direction stability factor": 0.7972055152,
      score: 0.675784166,
    },
    "112.5": {
      velocity: [1.1465641668, 1.1898651293, 1.2447888799],
      direction: [5.1165444422, 8.9482568715, 10.8007999315],
      "speed factor": 1.137168882,
      "Uz factor": 0.993100247,
      "Directional variance factor": 0.2045993892,
      "Velocity stability factor": 0.9204544941,
      "Direction stability factor": 0.9842104014,
      score: 0.7733686715,
    },
    "135.0": {
      velocity: [1.1752894616, 1.1824819828, 1.1893734664],
      direction: [0.9149547953, 1.3808284492, 1.7648706425],
      "speed factor": 1.3677838169,
      "Uz factor": 0.9896128934,
      "Directional variance factor": 0.8772596934,
      "Velocity stability factor": 0.9856535387,
      "Direction stability factor": 0.9976391226,
      score: 0.9645478693,
    },
    "157.5": {
      velocity: [0.231771411, 0.6610289243, 1.0400865202],
      direction: [-78.3144192155, -15.520398785, 18.8912575441],
      "speed factor": 0.6340132215,
      "Uz factor": 0.9949148083,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3969531682,
      "Direction stability factor": 0.7299842312,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0621486058, 0.3922349844, 0.7522587657],
      direction: [-152.219277647, -0.9144563068, 178.1087834548],
      "speed factor": 0.5489843243,
      "Uz factor": 0.9992200805,
      "Directional variance factor": 0.918714995,
      "Velocity stability factor": 0.3252781712,
      "Direction stability factor": 0.0824220525,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1384580565, 1.158728721, 1.1814356533],
      direction: [-1.2245125899, 0.2865431334, 1.2187541611],
      "speed factor": 1.3780640011,
      "Uz factor": 0.9796785961,
      "Directional variance factor": 0.9745294993,
      "Velocity stability factor": 0.965665978,
      "Direction stability factor": 0.9932131479,
      score: 0.9816554433,
    },
    "225.0": {
      velocity: [0.6272893192, 0.9171061105, 1.1328422816],
      direction: [-31.0536575693, -5.329116452, 5.2988991932],
      "speed factor": 1.1105686669,
      "Uz factor": 0.9999844388,
      "Directional variance factor": 0.5263007598,
      "Velocity stability factor": 0.5251204977,
      "Direction stability factor": 0.8990206757,
      score: 0.7123656522,
    },
    "247.5": {
      velocity: [0.2069128769, 0.5336610403, 0.8737443617],
      direction: [-79.5955208998, 3.2980165102, 49.6546742085],
      "speed factor": 0.668604493,
      "Uz factor": 0.9999814984,
      "Directional variance factor": 0.7068429769,
      "Velocity stability factor": 0.4712262053,
      "Direction stability factor": 0.6409716803,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.6855509075, 0.9344779164, 1.0713375192],
      direction: [-8.2235168351, 0.5353550404, 9.6071130202],
      "speed factor": 1.3105641099,
      "Uz factor": 0.9999416315,
      "Directional variance factor": 0.9524128853,
      "Velocity stability factor": 0.6710512734,
      "Direction stability factor": 0.9504704726,
      score: 0.881101276,
    },
    "292.5": {
      velocity: [1.1155798658, 1.1798878466, 1.2223569715],
      direction: [0.0113742988, 3.3821958904, 6.2191857526],
      "speed factor": 1.1793076685,
      "Uz factor": 0.9983704319,
      "Directional variance factor": 0.6993603653,
      "Velocity stability factor": 0.8911414131,
      "Direction stability factor": 0.9827560793,
      score: 0.8890034843,
    },
    "315.0": {
      velocity: [0.8682957177, 0.8791731223, 0.8898129936],
      direction: [2.4951559959, 3.0882455661, 3.4962704644],
      "speed factor": 1.0925880297,
      "Uz factor": 0.9999948677,
      "Directional variance factor": 0.725489283,
      "Velocity stability factor": 0.9653664472,
      "Direction stability factor": 0.9972191265,
      score: 0.9213234958,
    },
    "337.5": {
      velocity: [0.3455338584, 0.9345424852, 1.1165458338],
      direction: [-11.3163916606, -0.8018516427, 6.0901859432],
      "speed factor": 0.9343259734,
      "Uz factor": 0.9982309107,
      "Directional variance factor": 0.9287242984,
      "Velocity stability factor": 0.2315829653,
      "Direction stability factor": 0.9516483955,
      score: 0.0,
    },
    id: "IDN:1872.0",
    zone: 1,
  },
  {
    position: [-23.8936219, 5.3169522, 20.8740369],
    "overall score": 0.5629260416,
    "0": {
      velocity: [0.3011501912, 0.7124951089, 0.9004254625],
      direction: [-7.1545488155, 5.4103457371, 25.7374795913],
      "speed factor": 1.1801610082,
      "Uz factor": 0.9991736405,
      "Directional variance factor": 0.5190803789,
      "Velocity stability factor": 0.3343226066,
      "Direction stability factor": 0.9086332544,
      score: 0.6676673736,
    },
    "22.5": {
      velocity: [0.1785833063, 0.7464432512, 1.1276728417],
      direction: [-28.3742162165, 11.8235318078, 59.2560329063],
      "speed factor": 0.9876218641,
      "Uz factor": 0.9978239239,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1980969395,
      "Direction stability factor": 0.7565826413,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3082805401, 0.6332233933, 1.0408988831],
      direction: [-21.6089081585, -7.8937337125, 31.9371071328],
      "speed factor": 1.0206231288,
      "Uz factor": 0.9999788994,
      "Directional variance factor": 0.2983347811,
      "Velocity stability factor": 0.2103289221,
      "Direction stability factor": 0.8512610686,
      score: 0.5527964601,
    },
    "67.5": {
      velocity: [0.6668595315, 0.9019471551, 0.9822317743],
      direction: [-5.3822812183, -3.1918615844, 0.5854119921],
      "speed factor": 1.225765462,
      "Uz factor": 0.9988939406,
      "Directional variance factor": 0.7162789703,
      "Velocity stability factor": 0.6795558217,
      "Direction stability factor": 0.9834230744,
      score: 0.8406702352,
    },
    "90.0": {
      velocity: [0.3982785158, 0.7272118498, 0.9689852583],
      direction: [-24.9206003614, 1.3609223201, 33.886988355],
      "speed factor": 1.0505193701,
      "Uz factor": 0.9997349509,
      "Directional variance factor": 0.8790291271,
      "Velocity stability factor": 0.419913073,
      "Direction stability factor": 0.8366455869,
      score: 0.7430583435,
    },
    "112.5": {
      velocity: [0.9762401311, 1.1658490896, 1.2284694627],
      direction: [4.9513791371, 8.9871330092, 11.5053696207],
      "speed factor": 1.1142164546,
      "Uz factor": 0.9941336345,
      "Directional variance factor": 0.2011437325,
      "Velocity stability factor": 0.7957366415,
      "Direction stability factor": 0.9817944709,
      score: 0.7401173289,
    },
    "135.0": {
      velocity: [1.0582395287, 1.0776099331, 1.0892798135],
      direction: [0.1694152866, 0.451736876, 0.8838084496],
      "speed factor": 1.2464777044,
      "Uz factor": 0.9833411311,
      "Directional variance factor": 0.959845611,
      "Velocity stability factor": 0.9683812771,
      "Direction stability factor": 0.9980155745,
      score: 0.9810645093,
    },
    "157.5": {
      velocity: [0.1999346596, 0.6222565959, 1.005357709],
      direction: [-77.1822073863, -17.4670262794, 30.3784557469],
      "speed factor": 0.5968254859,
      "Uz factor": 0.9928651296,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3991108013,
      "Direction stability factor": 0.7012203802,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.064231599, 0.4153893893, 0.7916935212],
      direction: [-138.6578568476, -1.7196833998, 88.8242981876],
      "speed factor": 0.5813919518,
      "Uz factor": 0.9986021717,
      "Directional variance factor": 0.8471392534,
      "Velocity stability factor": 0.2887592922,
      "Direction stability factor": 0.3681051249,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.16466525, 1.1802658635, 1.201314897],
      direction: [-7.4812429186, -6.7060172937, -5.8032868787],
      "speed factor": 1.4036779004,
      "Uz factor": 0.9726025616,
      "Directional variance factor": 0.4039095739,
      "Velocity stability factor": 0.9707212623,
      "Direction stability factor": 0.995339011,
      score: 0.8413272145,
    },
    "225.0": {
      velocity: [0.3335106262, 0.7338546828, 0.90414657],
      direction: [-62.8532909276, -27.8635726172, -0.2331955086],
      "speed factor": 0.8886605459,
      "Uz factor": 0.9650452686,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4639863017,
      "Direction stability factor": 0.8260552905,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1856040707, 0.5436495064, 0.8825305758],
      direction: [-67.515768177, 6.9766945208, 76.2909148108],
      "speed factor": 0.6811186786,
      "Uz factor": 0.9979595116,
      "Directional variance factor": 0.3798493759,
      "Velocity stability factor": 0.447361918,
      "Direction stability factor": 0.6005369917,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.7666440382, 0.934441679, 1.0453234207],
      direction: [-5.7082462346, -0.1746110309, 4.3145192129],
      "speed factor": 1.3105132885,
      "Uz factor": 0.9995515933,
      "Directional variance factor": 0.9844790195,
      "Velocity stability factor": 0.7623784103,
      "Direction stability factor": 0.9721589849,
      score: 0.9227938499,
    },
    "292.5": {
      velocity: [1.0936202803, 1.1548766913, 1.2114933835],
      direction: [-7.1588732541, -0.812126227, 2.1680361901],
      "speed factor": 1.1543088117,
      "Uz factor": 0.9976509873,
      "Directional variance factor": 0.927811002,
      "Velocity stability factor": 0.8798291136,
      "Direction stability factor": 0.9740919182,
      score: 0.938955988,
    },
    "315.0": {
      velocity: [0.8953371832, 0.9040337014, 0.9144311324],
      direction: [2.723863848, 3.0164471585, 3.2614429459],
      "speed factor": 1.1234833908,
      "Uz factor": 0.9991322079,
      "Directional variance factor": 0.7318713637,
      "Velocity stability factor": 0.9692669602,
      "Direction stability factor": 0.9985067247,
      score: 0.9245379433,
    },
    "337.5": {
      velocity: [0.8274140469, 1.0507127558, 1.138386911],
      direction: [-5.1012800645, -2.6143045641, 2.5273806346],
      "speed factor": 1.0504693301,
      "Uz factor": 0.998650758,
      "Directional variance factor": 0.7676173721,
      "Velocity stability factor": 0.6900737554,
      "Direction stability factor": 0.9788092758,
      score: 0.8538274198,
    },
    id: "IDN:2138.0",
    zone: 1,
  },
  {
    position: [54.20835548, 8.8396753, 21.1598299],
    "overall score": 0.5595183084,
    "0": {
      velocity: [0.4743179984, 0.674824831, 0.801481493],
      direction: [-21.4192182413, -9.4424656481, 2.1935616062],
      "speed factor": 1.1177648,
      "Uz factor": 0.9964855862,
      "Directional variance factor": 0.1606697202,
      "Velocity stability factor": 0.6365854679,
      "Direction stability factor": 0.9344089449,
      score: 0.6665182694,
    },
    "22.5": {
      velocity: [0.5225827325, 0.7965974702, 1.0339230201],
      direction: [-10.3895674785, 4.5221696926, 19.6013247887],
      "speed factor": 1.0539810992,
      "Uz factor": 0.9966678272,
      "Directional variance factor": 0.5980293607,
      "Velocity stability factor": 0.5679592638,
      "Direction stability factor": 0.9166919659,
      score: 0.7498431391,
    },
    "45.0": {
      velocity: [0.4253588031, 0.7197186024, 0.8649363595],
      direction: [-12.6142948827, 1.3530681393, 10.6318699166],
      "speed factor": 1.1600352413,
      "Uz factor": 0.9902184276,
      "Directional variance factor": 0.8797272765,
      "Velocity stability factor": 0.5261902925,
      "Direction stability factor": 0.93542732,
      score: 0.8191930523,
    },
    "67.5": {
      velocity: [0.7462644135, 0.8763764575, 1.0007792734],
      direction: [-3.6404738493, 0.5567962942, 7.985574444],
      "speed factor": 1.1910143374,
      "Uz factor": 0.9928288466,
      "Directional variance factor": 0.9505069961,
      "Velocity stability factor": 0.7413919361,
      "Direction stability factor": 0.9677054214,
      score: 0.9068274438,
    },
    "90.0": {
      velocity: [0.9815860543, 1.0067912269, 1.0220424472],
      direction: [4.5633743452, 5.1257678698, 6.0729168238],
      "speed factor": 1.4543955599,
      "Uz factor": 0.9946336214,
      "Directional variance factor": 0.5443761894,
      "Velocity stability factor": 0.9588786624,
      "Direction stability factor": 0.9958068264,
      score: 0.8737171262,
    },
    "112.5": {
      velocity: [0.815989389, 1.0426323888, 1.14855577],
      direction: [-5.6504486626, 4.2139873115, 15.1238533533],
      "speed factor": 0.9964567234,
      "Uz factor": 0.9978859768,
      "Directional variance factor": 0.6254233501,
      "Velocity stability factor": 0.7306771363,
      "Direction stability factor": 0.9422936055,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.0507566036, 1.104994405, 1.1600576068],
      direction: [-1.5848327148, 0.6151212229, 4.8602046665],
      "speed factor": 1.278153483,
      "Uz factor": 0.9994985371,
      "Directional variance factor": 0.945322558,
      "Velocity stability factor": 0.8886621641,
      "Direction stability factor": 0.9820971184,
      score: 0.9495447397,
    },
    "157.5": {
      velocity: [1.1142119707, 1.1240599446, 1.133721684],
      direction: [-2.1799852396, -1.6626545441, -1.0854121656],
      "speed factor": 1.0781205487,
      "Uz factor": 0.9992846113,
      "Directional variance factor": 0.852208485,
      "Velocity stability factor": 0.9854446977,
      "Direction stability factor": 0.9969595192,
      score: 0.9578930553,
    },
    "180.0": {
      velocity: [0.4215834542, 0.8386674548, 1.0235980115],
      direction: [-42.0635712514, -6.8896399155, 6.3656686023],
      "speed factor": 1.1738251412,
      "Uz factor": 0.9997414008,
      "Directional variance factor": 0.3875875631,
      "Velocity stability factor": 0.4114093854,
      "Direction stability factor": 0.8654743337,
      score: 0.632486404,
    },
    "202.5": {
      velocity: [0.1507878941, 0.4952035935, 0.8465259797],
      direction: [-24.7738036135, 19.7173876508, 86.9612631221],
      "speed factor": 0.5889404768,
      "Uz factor": 0.994316437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4441874727,
      "Direction stability factor": 0.6896248146,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.0981325706, 1.1291268229, 1.1508120844],
      direction: [-0.5344894638, 1.7535178762, 3.5446970116],
      "speed factor": 1.3673149226,
      "Uz factor": 0.9943171945,
      "Directional variance factor": 0.8441317443,
      "Velocity stability factor": 0.9505167151,
      "Direction stability factor": 0.9886689265,
      score: 0.9429965781,
    },
    "247.5": {
      velocity: [0.7172999653, 0.9866479705, 1.2212550091],
      direction: [-18.2477073641, -6.0167767265, 18.912765019],
      "speed factor": 1.2361353298,
      "Uz factor": 0.9990234112,
      "Directional variance factor": 0.4651754021,
      "Velocity stability factor": 0.6003814653,
      "Direction stability factor": 0.8967764656,
      score: 0.7147774496,
    },
    "270.0": {
      velocity: [0.7793559417, 0.9779214767, 1.0578987212],
      direction: [1.2993654265, 8.368602441, 12.9339184663],
      "speed factor": 1.3714917893,
      "Uz factor": 0.9999943203,
      "Directional variance factor": 0.2561242275,
      "Velocity stability factor": 0.7624948875,
      "Direction stability factor": 0.9676817971,
      score: 0.7384956773,
    },
    "292.5": {
      velocity: [0.3741404885, 0.4179836088, 0.4705986655],
      direction: [1.6674582077, 9.6433370498, 17.0053798604],
      "speed factor": 0.4177780766,
      "Uz factor": 0.9931454685,
      "Directional variance factor": 0.1428144845,
      "Velocity stability factor": 0.901661496,
      "Direction stability factor": 0.9573946621,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.423927851, 0.4405635589, 0.4583146357],
      direction: [1.0102847231, 3.8704966228, 7.0072906129],
      "speed factor": 0.547508174,
      "Uz factor": 0.9998265427,
      "Directional variance factor": 0.6559558558,
      "Velocity stability factor": 0.9446520775,
      "Direction stability factor": 0.9833416503,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1837153336, 0.5279148987, 0.74481502],
      direction: [-26.2544011729, -8.6640973679, 44.8712428194],
      "speed factor": 0.5277925931,
      "Uz factor": 0.9998529181,
      "Directional variance factor": 0.2298580117,
      "Velocity stability factor": 0.4407887673,
      "Direction stability factor": 0.8024287667,
      score: 0.0,
    },
    id: "IDN:3585.0",
    zone: 1,
  },
  {
    position: [-18.95909711, 2.345534249, 21.1598299],
    "overall score": 0.5578325339,
    "0": {
      velocity: [0.5899235212, 0.8263427141, 1.0138692307],
      direction: [-10.5235212412, 1.7778779578, 20.1588872498],
      "speed factor": 1.3687356423,
      "Uz factor": 0.999615791,
      "Directional variance factor": 0.8419664037,
      "Velocity stability factor": 0.5290793925,
      "Direction stability factor": 0.9147710875,
      score: 0.8001469928,
    },
    "22.5": {
      velocity: [0.2890792727, 0.8139096274, 1.2106408715],
      direction: [-32.41896936, 6.8330235855, 39.1595174995],
      "speed factor": 1.0768868794,
      "Uz factor": 0.9971210579,
      "Directional variance factor": 0.3926201257,
      "Velocity stability factor": 0.2213557953,
      "Direction stability factor": 0.8011708698,
      score: 0.5540794152,
    },
    "45.0": {
      velocity: [0.3089331101, 0.6956222047, 1.0283750272],
      direction: [-24.9293620556, -11.0138130272, 36.7610593094],
      "speed factor": 1.1211969087,
      "Uz factor": 0.9981191101,
      "Directional variance factor": 0.0209943976,
      "Velocity stability factor": 0.2245314636,
      "Direction stability factor": 0.8286377184,
      score: 0.4757003245,
    },
    "67.5": {
      velocity: [0.7682090251, 0.9272535793, 0.9859910746],
      direction: [-4.2612978911, -2.8889982342, 0.1751854643],
      "speed factor": 1.2601574334,
      "Uz factor": 0.9993909928,
      "Directional variance factor": 0.743200157,
      "Velocity stability factor": 0.7787154974,
      "Direction stability factor": 0.9876764351,
      score: 0.8743171312,
    },
    "90.0": {
      velocity: [0.3393469557, 0.6617586863, 0.9328653806],
      direction: [-28.6364784317, 1.4135745378, 45.9938922953],
      "speed factor": 0.9559667083,
      "Uz factor": 0.9994479309,
      "Directional variance factor": 0.87434893,
      "Velocity stability factor": 0.3967264558,
      "Direction stability factor": 0.7926934146,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1741663669, 1.199406388, 1.2326822311],
      direction: [2.9031975587, 5.5712784194, 7.0284921957],
      "speed factor": 1.1462875815,
      "Uz factor": 0.9913032675,
      "Directional variance factor": 0.5047752516,
      "Velocity stability factor": 0.9526119866,
      "Direction stability factor": 0.9885408482,
      score: 0.8586172337,
    },
    "135.0": {
      velocity: [1.1536842552, 1.1603855032, 1.1653177848],
      direction: [-1.604869293, -1.282409154, -0.9770863582],
      "speed factor": 1.3422246899,
      "Uz factor": 0.9854935522,
      "Directional variance factor": 0.8860080752,
      "Velocity stability factor": 0.9881496786,
      "Direction stability factor": 0.9982561585,
      score: 0.9676675177,
    },
    "157.5": {
      velocity: [0.2195339047, 0.6322740196, 0.9780673354],
      direction: [-93.8462951288, -15.4875587669, 26.6525017652],
      "speed factor": 0.6064335059,
      "Uz factor": 0.9943858966,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4340929954,
      "Direction stability factor": 0.6652811197,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0798330886, 0.3919315701, 0.700500153],
      direction: [-123.1443816091, 1.2995805494, 176.5022068389],
      "speed factor": 0.548559656,
      "Uz factor": 0.9975335935,
      "Directional variance factor": 0.8844817289,
      "Velocity stability factor": 0.3931727988,
      "Direction stability factor": 0.1676483654,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.093847729, 1.1124176983, 1.1333356465],
      direction: [-0.9451514995, 0.1330724645, 0.9122890884],
      "speed factor": 1.3229867841,
      "Uz factor": 0.9765206681,
      "Directional variance factor": 0.9881713365,
      "Velocity stability factor": 0.9684538195,
      "Direction stability factor": 0.9948404428,
      score: 0.9865765104,
    },
    "225.0": {
      velocity: [0.6938219023, 0.9657756985, 1.1603961325],
      direction: [-15.2053790585, -1.7840861947, 7.8732150107],
      "speed factor": 1.1695050526,
      "Uz factor": 0.9998184823,
      "Directional variance factor": 0.8414145605,
      "Velocity stability factor": 0.5617342697,
      "Direction stability factor": 0.9358927943,
      score: 0.8187336047,
    },
    "247.5": {
      velocity: [0.117797537, 0.4834580913, 0.8701489084],
      direction: [-150.4477243129, 2.0176084334, 83.5520340823],
      "speed factor": 0.6057070454,
      "Uz factor": 0.9992938794,
      "Directional variance factor": 0.8206570281,
      "Velocity stability factor": 0.4034119584,
      "Direction stability factor": 0.3500006711,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.4445547394, 0.8753132952, 1.066552083],
      direction: [-23.0119337758, 0.9916273689, 12.0277651029],
      "speed factor": 1.2275883351,
      "Uz factor": 0.9999989413,
      "Directional variance factor": 0.911855345,
      "Velocity stability factor": 0.4696414342,
      "Direction stability factor": 0.9026675031,
      score: 0.7967079464,
    },
    "292.5": {
      velocity: [1.0948595541, 1.1740280194, 1.2392336928],
      direction: [-5.513084892, 2.715245078, 7.6155137615],
      "speed factor": 1.1734507227,
      "Uz factor": 0.9954290209,
      "Directional variance factor": 0.758644882,
      "Velocity stability factor": 0.8528114747,
      "Direction stability factor": 0.9635316704,
      score: 0.8846299244,
    },
    "315.0": {
      velocity: [0.9079648798, 0.9202705009, 0.9339628016],
      direction: [3.0258822895, 3.6009123634, 4.0154627449],
      "speed factor": 1.1436615928,
      "Uz factor": 0.9999903511,
      "Directional variance factor": 0.679918901,
      "Velocity stability factor": 0.9581545359,
      "Direction stability factor": 0.9972511654,
      score: 0.9081439419,
    },
    "337.5": {
      velocity: [0.3343744792, 0.9493236371, 1.1143890512],
      direction: [-10.8073751814, -0.6919722947, 6.2716982191],
      "speed factor": 0.9491037009,
      "Uz factor": 0.9980879842,
      "Directional variance factor": 0.9384913516,
      "Velocity stability factor": 0.2226106681,
      "Direction stability factor": 0.9525581294,
      score: 0.0,
    },
    id: "IDN:3545.0",
    zone: 1,
  },
  {
    position: [-27.8936219, 7.3169522, 20.8740369],
    "overall score": 0.5566605557,
    "0": {
      velocity: [0.677672682, 0.8055809736, 0.9227488581],
      direction: [-4.54838935, 5.2256163177, 12.2672310004],
      "speed factor": 1.3343463584,
      "Uz factor": 0.9999397276,
      "Directional variance factor": 0.5355007718,
      "Velocity stability factor": 0.727768393,
      "Direction stability factor": 0.9532899435,
      score: 0.7924622629,
    },
    "22.5": {
      velocity: [0.1273509108, 0.6860922948, 1.1320721941],
      direction: [-30.7652613678, 14.8543901084, 95.1443651516],
      "speed factor": 0.9077712874,
      "Uz factor": 0.9986015431,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1510926609,
      "Direction stability factor": 0.6502510374,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3997772268, 0.7384970066, 1.0534223557],
      direction: [-22.9894920373, -7.3046976806, 23.2804654276],
      "speed factor": 1.1903020853,
      "Uz factor": 0.9993507468,
      "Directional variance factor": 0.3506935395,
      "Velocity stability factor": 0.295452184,
      "Direction stability factor": 0.8714723404,
      score: 0.5972726011,
    },
    "67.5": {
      velocity: [0.5739968488, 0.8537447846, 0.9520338508],
      direction: [-9.7816773415, -4.3219884998, 6.3828219636],
      "speed factor": 1.1602574102,
      "Uz factor": 0.9991464323,
      "Directional variance factor": 0.6158232445,
      "Velocity stability factor": 0.6158832644,
      "Direction stability factor": 0.955098613,
      score: 0.7854759337,
    },
    "90.0": {
      velocity: [0.3969473552, 0.7816904512, 0.9843944039],
      direction: [-15.9686546918, 2.8459115546, 38.8561265457],
      "speed factor": 1.1292183436,
      "Uz factor": 0.9988651909,
      "Directional variance factor": 0.747030084,
      "Velocity stability factor": 0.4028976218,
      "Direction stability factor": 0.847708941,
      score: 0.711336397,
    },
    "112.5": {
      velocity: [0.8241707092, 1.0543342017, 1.2003725773],
      direction: [-3.3024896879, 9.7293536397, 14.5572169367],
      "speed factor": 1.0076402913,
      "Uz factor": 0.9976760239,
      "Directional variance factor": 0.1351685654,
      "Velocity stability factor": 0.6953397269,
      "Direction stability factor": 0.9503897038,
      score: 0.682821925,
    },
    "135.0": {
      velocity: [0.9642622181, 1.0199910791, 1.0645783611],
      direction: [0.3190095057, 1.8695565435, 3.4234150288],
      "speed factor": 1.1798296394,
      "Uz factor": 0.9962419616,
      "Directional variance factor": 0.8338171961,
      "Velocity stability factor": 0.8978144579,
      "Direction stability factor": 0.9913766513,
      score: 0.9285962392,
    },
    "157.5": {
      velocity: [0.0912633874, 0.6021677866, 0.9985796096],
      direction: [-84.1538158894, -18.0740497887, 30.854423661],
      "speed factor": 0.5775576895,
      "Uz factor": 0.9959047691,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3230929782,
      "Direction stability factor": 0.6805326679,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.180292284, 0.5092577457, 0.9085917691],
      direction: [-74.5122382227, -1.0087889924, 61.2308067162],
      "speed factor": 0.7127730328,
      "Uz factor": 0.9955893532,
      "Directional variance factor": 0.9103298673,
      "Velocity stability factor": 0.2879404055,
      "Direction stability factor": 0.6229359863,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.2203614835, 1.2378914797, 1.2557380424],
      direction: [-11.1610666267, -10.3497727515, -9.5781536901],
      "speed factor": 1.4722114457,
      "Uz factor": 0.9970049177,
      "Directional variance factor": 0.0800201999,
      "Velocity stability factor": 0.9717383092,
      "Direction stability factor": 0.9956030196,
      score: 0.7607411371,
    },
    "225.0": {
      velocity: [0.4719156434, 0.8373681142, 1.1104479497],
      direction: [-47.7800416184, -13.310716024, 1.78946572],
      "speed factor": 1.0140100253,
      "Uz factor": 0.9995131142,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4002094213,
      "Direction stability factor": 0.8623069241,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4255279671, 0.74946947, 0.9864261383],
      direction: [-15.5387135846, 4.0197931929, 31.6212679446],
      "speed factor": 0.9389830195,
      "Uz factor": 0.9973741652,
      "Directional variance factor": 0.6426850495,
      "Velocity stability factor": 0.5552275782,
      "Direction stability factor": 0.8690000513,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.9423914549, 1.0157454776, 1.0729073979],
      direction: [-4.6583084524, -1.9673912391, 0.7649082084],
      "speed factor": 1.4245382842,
      "Uz factor": 0.9980726158,
      "Directional variance factor": 0.8251207787,
      "Velocity stability factor": 0.8887129519,
      "Direction stability factor": 0.9849355093,
      score: 0.9209261873,
    },
    "292.5": {
      velocity: [1.2084621407, 1.2285799042, 1.2454855731],
      direction: [-5.4281690474, -4.3406414581, -3.5006127945],
      "speed factor": 1.2279757831,
      "Uz factor": 0.9999999892,
      "Directional variance factor": 0.6141652037,
      "Velocity stability factor": 0.9622548437,
      "Direction stability factor": 0.9946456771,
      score: 0.8914278504,
    },
    "315.0": {
      velocity: [0.9058101007, 0.9122226935, 0.9217945321],
      direction: [1.6526952495, 1.9199302547, 2.1435432681],
      "speed factor": 1.1336602201,
      "Uz factor": 0.9999570596,
      "Directional variance factor": 0.8293395329,
      "Velocity stability factor": 0.9742719456,
      "Direction stability factor": 0.9986365333,
      score: 0.9502211363,
    },
    "337.5": {
      velocity: [0.9896417495, 1.1172774448, 1.1639714334],
      direction: [-4.4712677332, -2.9316755676, -0.0586080896],
      "speed factor": 1.1170185976,
      "Uz factor": 0.9980231533,
      "Directional variance factor": 0.7394066162,
      "Velocity stability factor": 0.8262570452,
      "Direction stability factor": 0.9877426121,
      score: 0.8852872214,
    },
    id: "IDN:2142.0",
    zone: 1,
  },
  {
    position: [52.42173181, 5.214497551, 21.1598299],
    "overall score": 0.554835096,
    "0": {
      velocity: [0.4988600862, 0.6773745413, 0.7973998288],
      direction: [-19.6125808369, -9.0324681302, 3.0822374005],
      "speed factor": 1.1219880832,
      "Uz factor": 0.9964492867,
      "Directional variance factor": 0.197113944,
      "Velocity stability factor": 0.6683808473,
      "Direction stability factor": 0.9369588382,
      score: 0.6848531169,
    },
    "22.5": {
      velocity: [0.4886718034, 0.8365983367, 1.0775565807],
      direction: [-8.838397778, 6.4707781351, 22.1615210091],
      "speed factor": 1.1069063957,
      "Uz factor": 0.9999379809,
      "Directional variance factor": 0.4248197213,
      "Velocity stability factor": 0.50244051,
      "Direction stability factor": 0.9138891145,
      score: 0.6887596151,
    },
    "45.0": {
      velocity: [0.4638421551, 0.7670518339, 0.9038471567],
      direction: [-11.9092744513, 2.0261176757, 9.6605796387],
      "speed factor": 1.2363264702,
      "Uz factor": 0.9964437888,
      "Directional variance factor": 0.819900651,
      "Velocity stability factor": 0.5257295599,
      "Direction stability factor": 0.9400837386,
      score: 0.8064494221,
    },
    "67.5": {
      velocity: [0.8237737853, 0.9403826873, 1.0676544813],
      direction: [-2.9313610439, -0.5191575002, 4.3621485291],
      "speed factor": 1.2780001717,
      "Uz factor": 0.9993546837,
      "Directional variance factor": 0.9538526667,
      "Velocity stability factor": 0.7521971227,
      "Direction stability factor": 0.9797402512,
      score: 0.9163825729,
    },
    "90.0": {
      velocity: [0.9613439785, 1.0107829697, 1.0414730871],
      direction: [3.8742217957, 5.3267483665, 6.7160616925],
      "speed factor": 1.460161972,
      "Uz factor": 0.9955188531,
      "Directional variance factor": 0.5265112563,
      "Velocity stability factor": 0.9185538825,
      "Direction stability factor": 0.9921060003,
      score: 0.8573192848,
    },
    "112.5": {
      velocity: [0.4493056617, 0.9826584179, 1.1462226244],
      direction: [-17.4627906239, 3.9234757203, 14.4870744446],
      "speed factor": 0.9391388545,
      "Uz factor": 0.9978957153,
      "Directional variance factor": 0.6512466026,
      "Velocity stability factor": 0.435614413,
      "Direction stability factor": 0.9112503748,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.8028961887, 0.9575931681, 1.0436350503],
      direction: [-1.0364787334, 1.2630425911, 7.4335455888],
      "speed factor": 1.1076536112,
      "Uz factor": 0.9883868464,
      "Directional variance factor": 0.8877295475,
      "Velocity stability factor": 0.754774951,
      "Direction stability factor": 0.9764721547,
      score: 0.8988622019,
    },
    "157.5": {
      velocity: [1.1066605573, 1.1155385976, 1.1258832627],
      direction: [0.7880960868, 1.3335007926, 1.9454018283],
      "speed factor": 1.0699474621,
      "Uz factor": 0.9932098486,
      "Directional variance factor": 0.8814665962,
      "Velocity stability factor": 0.9856588211,
      "Direction stability factor": 0.9967852618,
      score: 0.9651739852,
    },
    "180.0": {
      velocity: [0.2963525395, 0.7989525033, 0.961858233],
      direction: [-39.4052558335, -7.6641496084, 13.8537680153],
      "speed factor": 1.1182388557,
      "Uz factor": 0.998456363,
      "Directional variance factor": 0.318742257,
      "Velocity stability factor": 0.349333998,
      "Direction stability factor": 0.8520582671,
      score: 0.5930481973,
    },
    "202.5": {
      velocity: [0.1008384789, 0.4663129535, 0.8024988522],
      direction: [-38.7256064991, 17.9111669728, 92.2980008393],
      "speed factor": 0.5545811395,
      "Uz factor": 0.9969832891,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4394562646,
      "Direction stability factor": 0.6360455352,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1056267591, 1.1239018271, 1.1407213021],
      direction: [3.4327728885, 4.4407440131, 5.4717849863],
      "speed factor": 1.3609877194,
      "Uz factor": 0.9904383955,
      "Directional variance factor": 0.6052671988,
      "Velocity stability factor": 0.9670347514,
      "Direction stability factor": 0.9943360775,
      score: 0.8902435263,
    },
    "247.5": {
      velocity: [0.8367686092, 1.0128971385, 1.1449385222],
      direction: [-12.633460966, -3.3156566192, 8.5747841923],
      "speed factor": 1.2690219571,
      "Uz factor": 0.9974230041,
      "Directional variance factor": 0.7052749672,
      "Velocity stability factor": 0.755632153,
      "Direction stability factor": 0.9410882079,
      score: 0.835770884,
    },
    "270.0": {
      velocity: [0.7845496522, 0.9604328921, 1.0573957019],
      direction: [-4.58926808, 7.8328521331, 15.0494297528],
      "speed factor": 1.3469648199,
      "Uz factor": 0.9998896467,
      "Directional variance factor": 0.3037464771,
      "Velocity stability factor": 0.7673523189,
      "Direction stability factor": 0.9454480616,
      score: 0.7404987298,
    },
    "292.5": {
      velocity: [0.4995696038, 0.5572920699, 0.6050393019],
      direction: [5.5105517304, 11.5947860865, 16.4172035087],
      "speed factor": 0.5570180365,
      "Uz factor": 0.9981530939,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8924743069,
      "Direction stability factor": 0.9697037451,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4089109784, 0.4402773801, 0.4648948753],
      direction: [9.8749675777, 13.3027568447, 15.6588646871],
      "speed factor": 0.5471525267,
      "Uz factor": 0.9941578114,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9098900226,
      "Direction stability factor": 0.9839336191,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3454287023, 0.5738429159, 0.7289986328],
      direction: [-55.8324599299, -11.0731460807, 4.6581561211],
      "speed factor": 0.5737099698,
      "Uz factor": 0.997785002,
      "Directional variance factor": 0.0157203484,
      "Velocity stability factor": 0.6177210238,
      "Direction stability factor": 0.831970511,
      score: 0.0,
    },
    id: "IDN:3582.0",
    zone: 1,
  },
  {
    position: [-23.8936219, 7.3169522, 20.8740369],
    "overall score": 0.5537258507,
    "0": {
      velocity: [0.4637946265, 0.7501079294, 0.9020232028],
      direction: [-3.7471465846, 5.7868428219, 14.3538550124],
      "speed factor": 1.2424620452,
      "Uz factor": 0.9998720984,
      "Directional variance factor": 0.4856139714,
      "Velocity stability factor": 0.5132139263,
      "Direction stability factor": 0.94971944,
      score: 0.7245666944,
    },
    "22.5": {
      velocity: [0.2911945798, 0.7528515858, 1.1560972652],
      direction: [-28.969325101, 12.6963078239, 52.288203357],
      "speed factor": 0.9961007557,
      "Uz factor": 0.9979036595,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2692279447,
      "Direction stability factor": 0.7742846432,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3804593468, 0.6805161782, 0.9948200607],
      direction: [-24.0878649843, -8.2815759391, 16.1812943809],
      "speed factor": 1.0968491663,
      "Uz factor": 0.9995573773,
      "Directional variance factor": 0.2638599165,
      "Velocity stability factor": 0.3377958771,
      "Direction stability factor": 0.888141224,
      score: 0.5944845604,
    },
    "67.5": {
      velocity: [0.6528019804, 0.8722679295, 0.9607725851],
      direction: [-7.8945388671, -4.2077993712, 1.5624362529],
      "speed factor": 1.18543076,
      "Uz factor": 0.9993940122,
      "Directional variance factor": 0.6259733892,
      "Velocity stability factor": 0.6870764959,
      "Direction stability factor": 0.9737306247,
      score: 0.8151277836,
    },
    "90.0": {
      velocity: [0.4535236174, 0.7844492362, 1.003804789],
      direction: [-27.4149451332, 1.8051770331, 33.7924569131],
      "speed factor": 1.1332036431,
      "Uz factor": 0.9992150684,
      "Directional variance factor": 0.8395398193,
      "Velocity stability factor": 0.4406743603,
      "Direction stability factor": 0.8299794388,
      score: 0.7350432643,
    },
    "112.5": {
      velocity: [0.8339283818, 1.0676513498, 1.1936227836],
      direction: [2.6872108132, 10.9789206237, 16.3955515961],
      "speed factor": 1.0203676551,
      "Uz factor": 0.9980049339,
      "Directional variance factor": 0.0240959446,
      "Velocity stability factor": 0.7087079997,
      "Direction stability factor": 0.9619212756,
      score: 0.6641616239,
    },
    "135.0": {
      velocity: [1.0119501756, 1.0365377289, 1.0561474219],
      direction: [4.0781626089, 4.8524925488, 5.3412162116],
      "speed factor": 1.1989692458,
      "Uz factor": 0.9910826788,
      "Directional variance factor": 0.568667329,
      "Velocity stability factor": 0.9549791345,
      "Direction stability factor": 0.9964915178,
      score: 0.8791573748,
    },
    "157.5": {
      velocity: [0.2671595997, 0.6564194807, 0.9916561897],
      direction: [-69.1044955234, -18.311521392, 22.1718724877],
      "speed factor": 0.629592162,
      "Uz factor": 0.9939280648,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4594863213,
      "Direction stability factor": 0.7464545333,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0805860821, 0.4133770426, 0.7805044623],
      direction: [-137.264001723, -1.2628835128, 83.5536558397],
      "speed factor": 0.5785754087,
      "Uz factor": 0.9978458603,
      "Directional variance factor": 0.8877436877,
      "Velocity stability factor": 0.3156886581,
      "Direction stability factor": 0.3866176179,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1479723036, 1.2018033715, 1.2429927141],
      direction: [-11.4661854675, -9.7656380681, -8.3629145504],
      "speed factor": 1.4292922344,
      "Uz factor": 0.985339439,
      "Directional variance factor": 0.1319432828,
      "Velocity stability factor": 0.9240899189,
      "Direction stability factor": 0.991379803,
      score: 0.7596982019,
    },
    "225.0": {
      velocity: [0.2242291873, 0.6764017912, 0.9056001771],
      direction: [-105.7025018156, -24.1392369774, 15.8222925306],
      "speed factor": 0.819088028,
      "Uz factor": 0.99997133,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.359969893,
      "Direction stability factor": 0.6624311268,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1797928413, 0.6037405225, 1.0205626515],
      direction: [-41.3436653606, 5.091726602, 56.5928558289],
      "speed factor": 0.7564045254,
      "Uz factor": 0.999576411,
      "Directional variance factor": 0.5474020798,
      "Velocity stability factor": 0.3332992621,
      "Direction stability factor": 0.7279541078,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.8295315718, 0.9674829275, 1.0352793077],
      direction: [-5.5899687627, -0.6852929792, 3.3789165641],
      "speed factor": 1.3568521839,
      "Uz factor": 0.9997061255,
      "Directional variance factor": 0.9390850685,
      "Velocity stability factor": 0.824565048,
      "Direction stability factor": 0.9750864296,
      score: 0.9284557439,
    },
    "292.5": {
      velocity: [1.1260738462, 1.1488688011, 1.1660999451],
      direction: [-0.5050043496, 0.9453409324, 2.2430713664],
      "speed factor": 1.1483038757,
      "Uz factor": 0.9986726038,
      "Directional variance factor": 0.9159696949,
      "Velocity stability factor": 0.9591936442,
      "Direction stability factor": 0.9923664563,
      score: 0.9649740629,
    },
    "315.0": {
      velocity: [0.8523969345, 0.8640594398, 0.8725982192],
      direction: [3.2661659094, 3.6522429444, 3.9269150755],
      "speed factor": 1.0738055758,
      "Uz factor": 0.9999628487,
      "Directional variance factor": 0.6753561827,
      "Velocity stability factor": 0.9674846267,
      "Direction stability factor": 0.9981645856,
      score: 0.9097924952,
    },
    "337.5": {
      velocity: [0.8091770151, 1.0370964321, 1.171337096],
      direction: [-4.0888355389, -0.4059266932, 7.8576240954],
      "speed factor": 1.036856161,
      "Uz factor": 0.9977958117,
      "Directional variance factor": 0.9639176273,
      "Velocity stability factor": 0.6390588158,
      "Direction stability factor": 0.9668153899,
      score: 0.8841518057,
    },
    id: "IDN:2144.0",
    zone: 1,
  },
  {
    position: [52.54134, 6.2679947, 21.1598299],
    "overall score": 0.5503884928,
    "0": {
      velocity: [0.4889066382, 0.674049506, 0.7990299636],
      direction: [-20.238550542, -9.4123347835, 2.3427951161],
      "speed factor": 1.1164805688,
      "Uz factor": 0.9967606239,
      "Directional variance factor": 0.1633480192,
      "Velocity stability factor": 0.6555137568,
      "Direction stability factor": 0.9372740398,
      score: 0.6733524639,
    },
    "22.5": {
      velocity: [0.48086841, 0.8243017466, 1.0668056309],
      direction: [-7.8105580994, 6.5293948936, 22.3360243804],
      "speed factor": 1.0906367313,
      "Uz factor": 0.9997740657,
      "Directional variance factor": 0.4196093428,
      "Velocity stability factor": 0.5049309542,
      "Direction stability factor": 0.9162594931,
      score: 0.6892648208,
    },
    "45.0": {
      velocity: [0.4571872285, 0.757832002, 0.9000509103],
      direction: [-11.2314246362, 2.8934916029, 11.1935248906],
      "speed factor": 1.2214660374,
      "Uz factor": 0.9958604786,
      "Directional variance factor": 0.7428007464,
      "Velocity stability factor": 0.5226482596,
      "Direction stability factor": 0.9377084735,
      score: 0.7852164883,
    },
    "67.5": {
      velocity: [0.8061350711, 0.9305188156, 1.0567586414],
      direction: [-2.6791324404, -0.0532783499, 3.2564816099],
      "speed factor": 1.2645949593,
      "Uz factor": 0.9979476721,
      "Directional variance factor": 0.9952641467,
      "Velocity stability factor": 0.7453458069,
      "Direction stability factor": 0.9835121832,
      score: 0.92690858,
    },
    "90.0": {
      velocity: [0.9737162897, 1.0137438062, 1.0416716549],
      direction: [4.0078747902, 5.2978252284, 6.7478962132],
      "speed factor": 1.4644391522,
      "Uz factor": 0.9958909593,
      "Directional variance factor": 0.5290822019,
      "Velocity stability factor": 0.9309277147,
      "Direction stability factor": 0.9923888294,
      score: 0.8611968938,
    },
    "112.5": {
      velocity: [0.4550200469, 0.9899501555, 1.1266489164],
      direction: [-30.1556633728, 4.1016859454, 13.2766613474],
      "speed factor": 0.9461076587,
      "Uz factor": 0.9981109416,
      "Directional variance factor": 0.6354056937,
      "Velocity stability factor": 0.4560935175,
      "Direction stability factor": 0.8793546536,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.85414894, 0.9971361962, 1.0802997993],
      direction: [-0.466677918, 3.1916347608, 14.2178369121],
      "speed factor": 1.1533932628,
      "Uz factor": 0.9933949292,
      "Directional variance factor": 0.7162991324,
      "Velocity stability factor": 0.7696348019,
      "Direction stability factor": 0.959209681,
      score: 0.8510883241,
    },
    "157.5": {
      velocity: [1.1309859272, 1.1395170788, 1.1502332061],
      direction: [0.7361490913, 1.2871373161, 1.9096873247],
      "speed factor": 1.0929459627,
      "Uz factor": 0.9964463184,
      "Directional variance factor": 0.8855877941,
      "Velocity stability factor": 0.985640488,
      "Direction stability factor": 0.9967401716,
      score: 0.9661771563,
    },
    "180.0": {
      velocity: [0.3144256703, 0.8101146918, 0.9880730269],
      direction: [-35.0412048629, -7.7030453268, 11.1430227708],
      "speed factor": 1.133861803,
      "Uz factor": 0.9988973586,
      "Directional variance factor": 0.3152848598,
      "Velocity stability factor": 0.3413738808,
      "Direction stability factor": 0.8717104788,
      score: 0.6000199246,
    },
    "202.5": {
      velocity: [0.0914272096, 0.4812028656, 0.8597738499],
      direction: [-37.0545095413, 18.6123076843, 85.1179378527],
      "speed factor": 0.5722895569,
      "Uz factor": 0.9970193678,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3861818164,
      "Direction stability factor": 0.6606320906,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1098448585, 1.1325026189, 1.1529777017],
      direction: [2.1871126575, 3.4477847924, 4.7297589608],
      "speed factor": 1.3714028392,
      "Uz factor": 0.9912727859,
      "Directional variance factor": 0.6935302407,
      "Velocity stability factor": 0.9594841597,
      "Direction stability factor": 0.9929370936,
      score: 0.9097221469,
    },
    "247.5": {
      velocity: [0.770985019, 1.0039958489, 1.1558531567],
      direction: [-16.521517401, -4.5866941251, 7.3861240521],
      "speed factor": 1.2578698553,
      "Uz factor": 0.9977125203,
      "Directional variance factor": 0.5922938555,
      "Velocity stability factor": 0.694813172,
      "Direction stability factor": 0.9335898849,
      score: 0.7885716993,
    },
    "270.0": {
      velocity: [0.8270183579, 0.959063051, 1.0540287329],
      direction: [-0.6594835173, 7.9343836189, 14.1665654496],
      "speed factor": 1.3450436781,
      "Uz factor": 0.9999563242,
      "Directional variance factor": 0.2947214561,
      "Velocity stability factor": 0.8064350304,
      "Direction stability factor": 0.9588165306,
      score: 0.7546973869,
    },
    "292.5": {
      velocity: [0.471692582, 0.5285423667, 0.5806448959],
      direction: [4.6990434401, 11.6603617833, 17.2220545607],
      "speed factor": 0.5282824702,
      "Uz factor": 0.998934224,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.888923802,
      "Direction stability factor": 0.965213858,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4113825087, 0.439725728, 0.4625504169],
      direction: [8.1362209907, 11.6164762986, 14.3964028308],
      "speed factor": 0.5464669637,
      "Uz factor": 0.9945774974,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9176416914,
      "Direction stability factor": 0.982610606,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2971382668, 0.5701841026, 0.7255678599],
      direction: [-43.098107769, -10.3707800551, 5.8483610228],
      "speed factor": 0.5700520042,
      "Uz factor": 0.9982075762,
      "Directional variance factor": 0.078152884,
      "Velocity stability factor": 0.5730123422,
      "Direction stability factor": 0.8640375867,
      score: 0.0,
    },
    id: "IDN:1933.0",
    zone: 1,
  },
  {
    position: [54.06576755, 12.3873135, 21.1598299],
    "overall score": 0.5501007287,
    "0": {
      velocity: [0.5058972089, 0.688181111, 0.8396991785],
      direction: [-22.4965537349, -11.0740491342, -1.8106315077],
      "speed factor": 1.1398878442,
      "Uz factor": 0.9988630058,
      "Directional variance factor": 0.015640077,
      "Velocity stability factor": 0.6292114231,
      "Direction stability factor": 0.9425391049,
      score: 0.6324824275,
    },
    "22.5": {
      velocity: [0.490880325, 0.7549746584, 1.004649529],
      direction: [-10.5460306369, 4.4227750511, 21.2068275297],
      "speed factor": 0.998909801,
      "Uz factor": 0.9972669004,
      "Directional variance factor": 0.6068644399,
      "Velocity stability factor": 0.565907028,
      "Direction stability factor": 0.9117976162,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3999613733, 0.6736489229, 0.8445421093],
      direction: [-17.760604716, 3.4233889827, 14.5621179253],
      "speed factor": 1.0857805929,
      "Uz factor": 0.9888180442,
      "Directional variance factor": 0.6956987571,
      "Velocity stability factor": 0.5207974897,
      "Direction stability factor": 0.9102146593,
      score: 0.7592313914,
    },
    "67.5": {
      velocity: [0.6746996095, 0.8211678068, 0.9701541023],
      direction: [-2.7322657285, 2.5240367679, 10.233165306],
      "speed factor": 1.115984601,
      "Uz factor": 0.9876690898,
      "Directional variance factor": 0.7756411762,
      "Velocity stability factor": 0.6997938966,
      "Direction stability factor": 0.9639849138,
      score: 0.8508512251,
    },
    "90.0": {
      velocity: [0.9930731967, 1.0082976642, 1.0195401562],
      direction: [4.70611124, 5.2944800346, 5.9836198794],
      "speed factor": 1.4565717368,
      "Uz factor": 0.9935593713,
      "Directional variance factor": 0.5293795525,
      "Velocity stability factor": 0.9730980273,
      "Direction stability factor": 0.9964513649,
      score: 0.8738450774,
    },
    "112.5": {
      velocity: [0.9767872922, 1.0527843372, 1.116955447],
      direction: [-0.0998288714, 3.8664875061, 8.7204072016],
      "speed factor": 1.0061590666,
      "Uz factor": 0.997817609,
      "Directional variance factor": 0.6563122217,
      "Velocity stability factor": 0.8864873571,
      "Direction stability factor": 0.9754993442,
      score: 0.8734495668,
    },
    "135.0": {
      velocity: [1.1048044107, 1.1400239825, 1.1726268214],
      direction: [-2.4651497055, -0.7980271707, 2.0880054224],
      "speed factor": 1.3186724,
      "Uz factor": 0.9999750164,
      "Directional variance factor": 0.9290642515,
      "Velocity stability factor": 0.9309137135,
      "Direction stability factor": 0.9873523469,
      score: 0.9586706647,
    },
    "157.5": {
      velocity: [1.0959985076, 1.1056179919, 1.1137356355],
      direction: [-4.5064927005, -3.912416, -3.3606024866],
      "speed factor": 1.0604323033,
      "Uz factor": 0.9995608956,
      "Directional variance factor": 0.6522296889,
      "Velocity stability factor": 0.9867671424,
      "Direction stability factor": 0.9968169716,
      score: 0.9081576936,
    },
    "180.0": {
      velocity: [0.334281895, 0.8342324948, 1.043110059],
      direction: [-26.8205106427, -7.1533466914, 5.8017042123],
      "speed factor": 1.1676178327,
      "Uz factor": 0.9999989706,
      "Directional variance factor": 0.3641469608,
      "Velocity stability factor": 0.306977548,
      "Direction stability factor": 0.9093827365,
      score: 0.6224724955,
    },
    "202.5": {
      velocity: [0.127688486, 0.5185746491, 0.8598571383],
      direction: [-18.9411413306, 16.9918984649, 68.3327700766],
      "speed factor": 0.6167354299,
      "Uz factor": 0.9971406163,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4150837543,
      "Direction stability factor": 0.7575724683,
      score: 0.0,
    },
    "225.0": {
      velocity: [0.9533601185, 1.079033278, 1.127836714],
      direction: [-3.4803576795, 0.771987381, 6.2222429458],
      "speed factor": 1.3066541978,
      "Uz factor": 0.9976894333,
      "Directional variance factor": 0.9313788995,
      "Velocity stability factor": 0.8361094385,
      "Direction stability factor": 0.9730483316,
      score: 0.9283962503,
    },
    "247.5": {
      velocity: [0.4207810025, 0.9221157519, 1.2675220789],
      direction: [-21.2416430234, -6.4995716065, 29.5796604696],
      "speed factor": 1.1552852621,
      "Uz factor": 0.9999768599,
      "Directional variance factor": 0.4222603016,
      "Velocity stability factor": 0.3285642591,
      "Direction stability factor": 0.8588297125,
      score: 0.6171209964,
    },
    "270.0": {
      velocity: [0.8787504874, 0.9980128241, 1.0445254069],
      direction: [-2.8196917984, 7.5209376968, 12.0096800486],
      "speed factor": 1.3996690189,
      "Uz factor": 0.9996159097,
      "Directional variance factor": 0.3314722047,
      "Velocity stability factor": 0.8586486752,
      "Direction stability factor": 0.9588073004,
      score: 0.7769338702,
    },
    "292.5": {
      velocity: [0.3599931879, 0.42200767, 0.4861251534],
      direction: [3.3484557934, 7.7399071371, 13.4157179919],
      "speed factor": 0.4218001591,
      "Uz factor": 0.9886933302,
      "Directional variance factor": 0.3120082545,
      "Velocity stability factor": 0.8714092555,
      "Direction stability factor": 0.9720353828,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4205166505, 0.4485954612, 0.4710186337],
      direction: [-3.2539463626, -1.0040705726, 1.9152304231],
      "speed factor": 0.5574897806,
      "Uz factor": 0.9982728242,
      "Directional variance factor": 0.9107492824,
      "Velocity stability factor": 0.9187135442,
      "Direction stability factor": 0.9856411756,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3701361805, 0.5610922854, 0.6934129633],
      direction: [-19.2198845949, -9.696830788, 1.7201133286],
      "speed factor": 0.5609622933,
      "Uz factor": 0.9993883442,
      "Directional variance factor": 0.1380594855,
      "Velocity stability factor": 0.6778112471,
      "Direction stability factor": 0.9418333391,
      score: 0.0,
    },
    id: "IDN:3587.0",
    zone: 1,
  },
  {
    position: [-17.02988552, 2.423073797, 21.1598299],
    "overall score": 0.5496798182,
    "0": {
      velocity: [0.2851495774, 0.784334273, 0.9491914645],
      direction: [-8.93837583, 1.5073599792, 29.4341286627],
      "speed factor": 1.2991537973,
      "Uz factor": 0.9995987884,
      "Directional variance factor": 0.8660124463,
      "Velocity stability factor": 0.2623795881,
      "Direction stability factor": 0.8934097097,
      score: 0.7288028635,
    },
    "22.5": {
      velocity: [0.2178250522, 0.8240160514, 1.2164062619],
      direction: [-27.4950672344, 5.7967031214, 37.6445418207],
      "speed factor": 1.0902587269,
      "Uz factor": 0.9968590407,
      "Directional variance factor": 0.4847375003,
      "Velocity stability factor": 0.1562805211,
      "Direction stability factor": 0.8190566415,
      score: 0.5697828261,
    },
    "45.0": {
      velocity: [0.32939563, 0.6899066525, 1.0492739899],
      direction: [-23.5515124233, -11.1075764798, 32.6557703479],
      "speed factor": 1.1119846387,
      "Uz factor": 0.9982356784,
      "Directional variance factor": 0.0126598685,
      "Velocity stability factor": 0.2240610328,
      "Direction stability factor": 0.843868659,
      score: 0.4811145548,
    },
    "67.5": {
      velocity: [0.7786745597, 0.9231522555, 0.9804073033],
      direction: [-8.1605907436, -2.9321086349, 1.1030137137],
      "speed factor": 1.2545836467,
      "Uz factor": 0.9994394369,
      "Directional variance factor": 0.7393681213,
      "Velocity stability factor": 0.7950229143,
      "Direction stability factor": 0.9742677654,
      score: 0.8707316416,
    },
    "90.0": {
      velocity: [0.3192313543, 0.6539823478, 0.9328315334],
      direction: [-20.9860426868, 2.0162858344, 42.2354066389],
      "speed factor": 0.944733126,
      "Uz factor": 0.9994157884,
      "Directional variance factor": 0.8207745925,
      "Velocity stability factor": 0.3763146363,
      "Direction stability factor": 0.824384863,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1565013439, 1.1927196244, 1.2166141776],
      direction: [2.614516093, 6.2231394449, 7.866690435],
      "speed factor": 1.1398969585,
      "Uz factor": 0.9907844129,
      "Directional variance factor": 0.4468320493,
      "Velocity stability factor": 0.9513187097,
      "Direction stability factor": 0.9854106268,
      score: 0.8422430032,
    },
    "135.0": {
      velocity: [1.166333785, 1.1742672307, 1.1788722033],
      direction: [-1.4272439908, -1.120906896, -0.8248756257],
      "speed factor": 1.3582817651,
      "Uz factor": 0.9859576015,
      "Directional variance factor": 0.9003638315,
      "Velocity stability factor": 0.9872279273,
      "Direction stability factor": 0.9983267545,
      score: 0.971061317,
    },
    "157.5": {
      velocity: [0.318636751, 0.6636617934, 0.9707682784],
      direction: [-66.3613157656, -15.0616551211, 34.6828227705],
      "speed factor": 0.6365384874,
      "Uz factor": 0.9963039024,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5134745756,
      "Direction stability factor": 0.7193218374,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0435725779, 0.3886184387, 0.7038012017],
      direction: [-111.7736654373, 1.8745532499, 160.4681610548],
      "speed factor": 0.543922494,
      "Uz factor": 0.997302112,
      "Directional variance factor": 0.8333730445,
      "Velocity stability factor": 0.3544933976,
      "Direction stability factor": 0.2437727042,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0909023443, 1.1149944483, 1.1394366031],
      direction: [-1.2122584317, -0.4957562167, 0.269926409],
      "speed factor": 1.326051286,
      "Uz factor": 0.9767808143,
      "Directional variance factor": 0.9559327807,
      "Velocity stability factor": 0.9612268616,
      "Direction stability factor": 0.9958828199,
      score: 0.9772313205,
    },
    "225.0": {
      velocity: [0.8719387754, 1.0040247311, 1.1395151931],
      direction: [-14.4706183632, -5.5379685686, -1.675436213],
      "speed factor": 1.2158226779,
      "Uz factor": 0.9997196545,
      "Directional variance factor": 0.5077361272,
      "Velocity stability factor": 0.7486582702,
      "Direction stability factor": 0.9644578274,
      score: 0.796327513,
    },
    "247.5": {
      velocity: [0.1099914001, 0.4462030092, 0.8910871898],
      direction: [-139.4828406984, 0.5227981741, 143.245243068],
      "speed factor": 0.5590315092,
      "Uz factor": 0.9990433473,
      "Directional variance factor": 0.9535290512,
      "Velocity stability factor": 0.3806186509,
      "Direction stability factor": 0.2146442118,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.5271230823, 0.8641777877, 1.0543124506],
      direction: [-16.9398343053, 1.141258313, 12.3669419709],
      "speed factor": 1.2119712764,
      "Uz factor": 0.9997293439,
      "Directional variance factor": 0.8985548166,
      "Velocity stability factor": 0.5504813643,
      "Direction stability factor": 0.9185922881,
      score: 0.8215551893,
    },
    "292.5": {
      velocity: [1.1047261578, 1.1740013745, 1.2406827715],
      direction: [-0.8879996926, 4.6749315108, 7.5298554432],
      "speed factor": 1.1734240909,
      "Uz factor": 0.9966118863,
      "Directional variance factor": 0.5844505324,
      "Velocity stability factor": 0.8613930884,
      "Direction stability factor": 0.9766170691,
      score: 0.8497694397,
    },
    "315.0": {
      velocity: [0.8665689217, 0.8767938463, 0.8896786333],
      direction: [3.8986170335, 4.6196158533, 5.1840149789],
      "speed factor": 1.0896311962,
      "Uz factor": 0.9997106827,
      "Directional variance factor": 0.5893674797,
      "Velocity stability factor": 0.9628033112,
      "Direction stability factor": 0.9964294502,
      score: 0.8862574228,
    },
    "337.5": {
      velocity: [0.4288891707, 0.8829199604, 1.0699020477],
      direction: [-15.0502252852, -0.3585897665, 12.6045453209],
      "speed factor": 0.8827154084,
      "Uz factor": 0.9988133407,
      "Directional variance factor": 0.9681253541,
      "Velocity stability factor": 0.3611445349,
      "Direction stability factor": 0.9231811928,
      score: 0.0,
    },
    id: "IDN:3546.0",
    zone: 1,
  },
  {
    position: [-25.8936219, 11.3169522, 20.8740369],
    "overall score": 0.5485420331,
    "0": {
      velocity: [0.7395152266, 0.8243254791, 0.9215737068],
      direction: [-3.0502385134, 6.0870460705, 12.9714028269],
      "speed factor": 1.3653943392,
      "Uz factor": 0.9969398778,
      "Directional variance factor": 0.4589292382,
      "Velocity stability factor": 0.7977687044,
      "Direction stability factor": 0.9554954407,
      score: 0.791922206,
    },
    "22.5": {
      velocity: [0.3263287767, 0.7443179291, 1.1666339943],
      direction: [-26.8252073733, 13.3674304264, 60.6479333188],
      "speed factor": 0.9848098425,
      "Uz factor": 0.9999995346,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2900107939,
      "Direction stability factor": 0.7570190536,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.612974351, 0.8525653533, 1.0553522531],
      direction: [-20.5411235399, -8.3528041819, 4.4947315222],
      "speed factor": 1.374156305,
      "Uz factor": 0.9967159408,
      "Directional variance factor": 0.2575285172,
      "Velocity stability factor": 0.5231718695,
      "Direction stability factor": 0.9304559582,
      score: 0.6604030757,
    },
    "67.5": {
      velocity: [0.5465127481, 0.8492847329, 0.9450423696],
      direction: [-12.0605749689, -5.1356039478, 4.8612551979],
      "speed factor": 1.1541961046,
      "Uz factor": 0.9999205212,
      "Directional variance factor": 0.5435018713,
      "Velocity stability factor": 0.5950610748,
      "Direction stability factor": 0.9529949162,
      score: 0.7611381946,
    },
    "90.0": {
      velocity: [0.5035006641, 0.8374829271, 1.0241342177],
      direction: [-14.7074414347, 2.9674427157, 28.9463503608],
      "speed factor": 1.2098153203,
      "Uz factor": 0.9993160599,
      "Directional variance factor": 0.7362273142,
      "Velocity stability factor": 0.4708092692,
      "Direction stability factor": 0.8787394672,
      score: 0.7411288794,
    },
    "112.5": {
      velocity: [0.6694900069, 1.0072080331, 1.1577815949],
      direction: [-13.9663597368, 12.131445451, 19.1868221234],
      "speed factor": 0.9626012266,
      "Uz factor": 0.9984614899,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6045658963,
      "Direction stability factor": 0.9079078282,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.8948572294, 0.9634749231, 1.0103097303],
      direction: [5.2800039737, 6.3114273959, 7.9080842779],
      "speed factor": 1.1144570716,
      "Uz factor": 0.9975260548,
      "Directional variance factor": 0.4389842315,
      "Velocity stability factor": 0.8823960328,
      "Direction stability factor": 0.9926997769,
      score: 0.8266949545,
    },
    "157.5": {
      velocity: [0.2949766658, 0.6874819549, 0.9830137607],
      direction: [-62.3151867927, -16.2358389679, 11.8636718187],
      "speed factor": 0.6593851387,
      "Uz factor": 0.9970612061,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4866870785,
      "Direction stability factor": 0.793947615,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1249609059, 0.4812518402, 0.8202867029],
      direction: [-61.8682782007, -0.5362640035, 68.7991110558],
      "speed factor": 0.6735750935,
      "Uz factor": 0.9967580429,
      "Directional variance factor": 0.9523320886,
      "Velocity stability factor": 0.3201788341,
      "Direction stability factor": 0.6370350298,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1155415178, 1.1602878575, 1.1979372327],
      direction: [-13.0952308941, -8.9768715862, -6.7276553436],
      "speed factor": 1.3799182659,
      "Uz factor": 0.9986358574,
      "Directional variance factor": 0.202055859,
      "Velocity stability factor": 0.934175559,
      "Direction stability factor": 0.9823122901,
      score: 0.7752139996,
    },
    "225.0": {
      velocity: [0.4339856335, 0.8496968403, 1.098310493],
      direction: [-34.6167701318, -5.9499050697, 12.5808880566],
      "speed factor": 1.028939483,
      "Uz factor": 0.9945386607,
      "Directional variance factor": 0.4711195494,
      "Velocity stability factor": 0.3759817819,
      "Direction stability factor": 0.8688953939,
      score: 0.6462230298,
    },
    "247.5": {
      velocity: [0.3210443377, 0.708801833, 0.9518592717],
      direction: [-13.9476324077, 3.5777262971, 21.5468691488],
      "speed factor": 0.8880320173,
      "Uz factor": 0.9952189211,
      "Directional variance factor": 0.6819798847,
      "Velocity stability factor": 0.499786057,
      "Direction stability factor": 0.9014041623,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.9018106334, 0.9955639771, 1.0824529388],
      direction: [-4.7696151767, -0.7282983882, 3.2213917736],
      "speed factor": 1.3962346189,
      "Uz factor": 0.9998158983,
      "Directional variance factor": 0.9352623655,
      "Velocity stability factor": 0.8459716991,
      "Direction stability factor": 0.9778027585,
      score: 0.9342098954,
    },
    "292.5": {
      velocity: [1.1650646493, 1.1733367529, 1.1793319305],
      direction: [1.1975124069, 1.6120847343, 2.1561719416],
      "speed factor": 1.1727597961,
      "Uz factor": 0.9972499966,
      "Directional variance factor": 0.8567035792,
      "Velocity stability factor": 0.9854545966,
      "Direction stability factor": 0.9973370568,
      score: 0.9592080724,
    },
    "315.0": {
      velocity: [0.81862603, 0.8296646814, 0.8362404178],
      direction: [8.7556341805, 9.2349204999, 9.5947166413],
      "speed factor": 1.0310616607,
      "Uz factor": 0.9997978785,
      "Directional variance factor": 0.1791181778,
      "Velocity stability factor": 0.9716484172,
      "Direction stability factor": 0.9976692154,
      score: 0.7865262564,
    },
    "337.5": {
      velocity: [0.9009152679, 1.1130325307, 1.2226599517],
      direction: [-2.3131315188, 0.7163396907, 4.8234519154],
      "speed factor": 1.112774667,
      "Uz factor": 0.9999123605,
      "Directional variance factor": 0.9363253608,
      "Velocity stability factor": 0.6793381897,
      "Direction stability factor": 0.9801761571,
      score: 0.8940039662,
    },
    id: "IDN:2155.0",
    zone: 1,
  },
  {
    position: [-33.8210353, 14.1527752, 20.8740369],
    "overall score": 0.5476049237,
    "0": {
      velocity: [0.7177145089, 0.8426101742, 0.9783401669],
      direction: [2.8778564956, 6.6982713624, 10.2510569181],
      "speed factor": 1.3956807004,
      "Uz factor": 0.9969283654,
      "Directional variance factor": 0.4045981011,
      "Velocity stability factor": 0.7104959657,
      "Direction stability factor": 0.9795188877,
      score: 0.7685329606,
    },
    "22.5": {
      velocity: [0.3080484012, 0.6854899984, 1.0608512428],
      direction: [-23.7915780962, 17.0851053298, 55.9597856884],
      "speed factor": 0.9069743868,
      "Uz factor": 0.9924626464,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3639431475,
      "Direction stability factor": 0.7784684339,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6098501497, 0.8654151425, 1.0535369865],
      direction: [-11.1134074319, -1.4918037512, 10.838255755],
      "speed factor": 1.3948674667,
      "Uz factor": 0.9930464003,
      "Directional variance factor": 0.8673952221,
      "Velocity stability factor": 0.5217610014,
      "Direction stability factor": 0.9390231578,
      score: 0.8168006348,
    },
    "67.5": {
      velocity: [0.4917440538, 0.884890997, 0.982011265],
      direction: [-8.5767729525, -3.2787548537, 4.7592955586],
      "speed factor": 1.2025857785,
      "Uz factor": 0.9968756144,
      "Directional variance factor": 0.7085551241,
      "Velocity stability factor": 0.5018481265,
      "Direction stability factor": 0.9629553652,
      score: 0.7840784953,
    },
    "90.0": {
      velocity: [0.5422881191, 0.8365735499, 1.0234543552],
      direction: [-19.6906868261, 3.4372862979, 25.4173808805],
      "speed factor": 1.2085016477,
      "Uz factor": 0.9999855017,
      "Directional variance factor": 0.6944634402,
      "Velocity stability factor": 0.5109252748,
      "Direction stability factor": 0.8746998119,
      score: 0.7386970847,
    },
    "112.5": {
      velocity: [0.6069971046, 0.9817366811, 1.2299965401],
      direction: [-5.7193092147, 9.1557854204, 18.889047793],
      "speed factor": 0.9382579392,
      "Uz factor": 0.9996655933,
      "Directional variance factor": 0.1861524071,
      "Velocity stability factor": 0.4954751844,
      "Direction stability factor": 0.9316434528,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.6211176666, 0.8315483934, 1.0587338584],
      direction: [-1.8731387956, 5.0308831308, 12.477506785],
      "speed factor": 0.9618568841,
      "Uz factor": 0.9998703303,
      "Directional variance factor": 0.5528103884,
      "Velocity stability factor": 0.5542287965,
      "Direction stability factor": 0.9601370956,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2927239101, 0.6666240316, 1.0867707518],
      direction: [-73.3071125537, -18.4430033709, 26.7694821471],
      "speed factor": 0.6393796613,
      "Uz factor": 0.999964737,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4075980682,
      "Direction stability factor": 0.7220094592,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.2025866208, 0.6180787387, 0.9989562542],
      direction: [-33.340489831, -2.7417760138, 41.6230918144],
      "speed factor": 0.8650822904,
      "Uz factor": 0.9997143827,
      "Directional variance factor": 0.7562865766,
      "Velocity stability factor": 0.2213881105,
      "Direction stability factor": 0.7917678288,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0964722746, 1.1073504325, 1.1156708113],
      direction: [-1.5136514564, -0.7765430196, -0.109171816],
      "speed factor": 1.3169603377,
      "Uz factor": 0.9990063338,
      "Directional variance factor": 0.9309739538,
      "Velocity stability factor": 0.9846626375,
      "Direction stability factor": 0.9960986677,
      score: 0.9769584817,
    },
    "225.0": {
      velocity: [0.486844438, 0.7801080026, 0.9220485077],
      direction: [-9.4010236745, 0.7017540002, 7.2656428221],
      "speed factor": 0.9446709542,
      "Uz factor": 0.9973018214,
      "Directional variance factor": 0.9376218667,
      "Velocity stability factor": 0.5912011056,
      "Direction stability factor": 0.9537037042,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.9313704864, 0.9807283695, 1.0235829977],
      direction: [-0.5599506575, 1.2628176476, 2.979992587],
      "speed factor": 1.2287188773,
      "Uz factor": 0.9904221462,
      "Directional variance factor": 0.8877495424,
      "Velocity stability factor": 0.9268787383,
      "Direction stability factor": 0.9901668243,
      score: 0.9487404824,
    },
    "270.0": {
      velocity: [1.0658442097, 1.1144511163, 1.154898423],
      direction: [-4.3298836813, -2.5954193518, -0.3032067891],
      "speed factor": 1.5629685941,
      "Uz factor": 0.9968145201,
      "Directional variance factor": 0.7692960576,
      "Velocity stability factor": 0.924066131,
      "Direction stability factor": 0.9888147864,
      score: 0.9177479403,
    },
    "292.5": {
      velocity: [1.1236764133, 1.1288563662, 1.1331464916],
      direction: [-4.3951285927, -4.081790808, -3.7324622758],
      "speed factor": 1.1283012814,
      "Uz factor": 0.9857573735,
      "Directional variance factor": 0.6371741504,
      "Velocity stability factor": 0.9903453148,
      "Direction stability factor": 0.9981592602,
      score: 0.9059594964,
    },
    "315.0": {
      velocity: [0.8929639154, 0.8982905593, 0.9066408779],
      direction: [2.0692849288, 2.3416919507, 2.547676931],
      "speed factor": 1.1163461296,
      "Uz factor": 0.9980505205,
      "Directional variance factor": 0.7918496044,
      "Velocity stability factor": 0.9779859771,
      "Direction stability factor": 0.9986711333,
      score: 0.941794462,
    },
    "337.5": {
      velocity: [1.0955304316, 1.1465827092, 1.1818530775],
      direction: [-0.8280980927, 0.5766007788, 1.5550147903],
      "speed factor": 1.1463170727,
      "Uz factor": 0.9971571129,
      "Directional variance factor": 0.9487465974,
      "Velocity stability factor": 0.9139678842,
      "Direction stability factor": 0.993380242,
      score: 0.9623687414,
    },
    id: "IDN:3652.0",
    zone: 1,
  },
  {
    position: [50.49252022, 5.136958003, 21.1598299],
    "overall score": 0.5468667287,
    "0": {
      velocity: [0.4731113432, 0.6865933025, 0.8086693505],
      direction: [-23.4756373397, -10.2254355734, 1.9006001096],
      "speed factor": 1.1372578336,
      "Uz factor": 0.9963907009,
      "Directional variance factor": 0.0910723935,
      "Velocity stability factor": 0.6272608093,
      "Direction stability factor": 0.9295104515,
      score: 0.6443385265,
    },
    "22.5": {
      velocity: [0.5364031528, 0.8305459204, 1.0819748648],
      direction: [-10.0745990564, 5.2530366641, 27.5073430407],
      "speed factor": 1.0988984211,
      "Uz factor": 0.9989251389,
      "Directional variance factor": 0.5330634076,
      "Velocity stability factor": 0.5390365089,
      "Direction stability factor": 0.8956057164,
      score: 0.7158278373,
    },
    "45.0": {
      velocity: [0.4145771019, 0.7675062928, 0.9142499672],
      direction: [-8.6846379729, 4.8801819766, 14.4532792178],
      "speed factor": 1.2370589624,
      "Uz factor": 0.9998024785,
      "Directional variance factor": 0.5662060465,
      "Velocity stability factor": 0.4614150547,
      "Direction stability factor": 0.9357280078,
      score: 0.7247692792,
    },
    "67.5": {
      velocity: [0.8179524633, 0.9408000672, 1.0798294291],
      direction: [-2.0307071004, 0.2853180817, 4.3532306309],
      "speed factor": 1.2785674,
      "Uz factor": 0.9996069516,
      "Directional variance factor": 0.9746383927,
      "Velocity stability factor": 0.73391143,
      "Direction stability factor": 0.9822668396,
      score: 0.9182708755,
    },
    "90.0": {
      velocity: [0.9328139672, 0.9985247639, 1.0436990955],
      direction: [3.825252354, 5.7724105531, 8.033319742],
      "speed factor": 1.442453951,
      "Uz factor": 0.9964903571,
      "Directional variance factor": 0.4868968397,
      "Velocity stability factor": 0.8872923543,
      "Direction stability factor": 0.9883109239,
      score: 0.8377027605,
    },
    "112.5": {
      velocity: [0.53874408, 0.9509829761, 1.1117529344],
      direction: [-14.3080186466, 4.5158200531, 21.374564546],
      "speed factor": 0.9088662414,
      "Uz factor": 0.9983882429,
      "Directional variance factor": 0.5985937731,
      "Velocity stability factor": 0.5359591516,
      "Direction stability factor": 0.9008817134,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.7885611677, 0.907082594, 0.9948195682],
      direction: [-0.0289951574, 1.5268129536, 8.9355363295],
      "speed factor": 1.0492277351,
      "Uz factor": 0.9815944239,
      "Directional variance factor": 0.864283293,
      "Velocity stability factor": 0.7898979582,
      "Direction stability factor": 0.9750985236,
      score: 0.9010945746,
    },
    "157.5": {
      velocity: [1.1175024764, 1.1290600656, 1.1428533606],
      direction: [0.5813928399, 1.2508995601, 1.9475996497],
      "speed factor": 1.0829163189,
      "Uz factor": 0.9904468675,
      "Directional variance factor": 0.888808928,
      "Velocity stability factor": 0.9810868679,
      "Direction stability factor": 0.9962049811,
      score: 0.9655764395,
    },
    "180.0": {
      velocity: [0.312790811, 0.7511846918, 0.9431665203],
      direction: [-40.5984179061, -8.7716755229, 11.3999261716],
      "speed factor": 1.0513815361,
      "Uz factor": 0.9967513247,
      "Directional variance factor": 0.2202955091,
      "Velocity stability factor": 0.3836806409,
      "Direction stability factor": 0.8555601553,
      score: 0.5787741152,
    },
    "202.5": {
      velocity: [0.1277691642, 0.4665874213, 0.7759947446],
      direction: [-30.0890574994, 17.7476382658, 105.6790717732],
      "speed factor": 0.5549075613,
      "Uz factor": 0.9970686563,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4821443507,
      "Direction stability factor": 0.6228663076,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1078365111, 1.1277316745, 1.1435336857],
      direction: [3.2420251822, 4.0588688258, 5.2194830454],
      "speed factor": 1.3656254691,
      "Uz factor": 0.9890164664,
      "Directional variance factor": 0.6392116599,
      "Velocity stability factor": 0.9664686833,
      "Direction stability factor": 0.9945070615,
      score: 0.8986736165,
    },
    "247.5": {
      velocity: [0.8339422472, 1.0053692711, 1.1473986725],
      direction: [-14.263869981, -4.00047517, 6.7951695491],
      "speed factor": 1.259590566,
      "Uz factor": 0.9969452839,
      "Directional variance factor": 0.6444022071,
      "Velocity stability factor": 0.7514401355,
      "Direction stability factor": 0.941502668,
      score: 0.8197119196,
    },
    "270.0": {
      velocity: [0.7878333142, 0.952073232, 1.0521481327],
      direction: [-1.0014276978, 7.9508051611, 14.7265315851],
      "speed factor": 1.3352407649,
      "Uz factor": 0.9998869437,
      "Directional variance factor": 0.2932617635,
      "Velocity stability factor": 0.7746266451,
      "Direction stability factor": 0.9563112242,
      score: 0.7451277142,
    },
    "292.5": {
      velocity: [0.5335758623, 0.5748507207, 0.6189777812],
      direction: [5.2660223717, 11.6104533803, 15.3412487633],
      "speed factor": 0.5745680533,
      "Uz factor": 0.9978031082,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9129332814,
      "Direction stability factor": 0.97201326,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4006512065, 0.4318788196, 0.4564061002],
      direction: [12.4905359629, 15.088336823, 16.6785907125],
      "speed factor": 0.5367152573,
      "Uz factor": 0.9964224263,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9102586191,
      "Direction stability factor": 0.9883665146,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2784909002, 0.5826489794, 0.7620015889],
      direction: [-68.3543722681, -10.976228816, 3.8060224059],
      "speed factor": 0.5825139932,
      "Uz factor": 0.9976615929,
      "Directional variance factor": 0.0243352164,
      "Velocity stability factor": 0.5181166292,
      "Direction stability factor": 0.7995544592,
      score: 0.0,
    },
    id: "IDN:3581.0",
    zone: 1,
  },
  {
    position: [-28.16116253, 3.526932014, 20.8740369],
    "overall score": 0.5460397273,
    "0": {
      velocity: [0.5932535451, 0.7709475647, 0.8884087253],
      direction: [-3.3840786448, 3.7050581124, 14.1491024647],
      "speed factor": 1.2769803523,
      "Uz factor": 0.9978216631,
      "Directional variance factor": 0.6706615011,
      "Velocity stability factor": 0.672140433,
      "Direction stability factor": 0.9512967191,
      score: 0.8113488431,
    },
    "22.5": {
      velocity: [0.2186318357, 0.6990091691, 1.2169183045],
      direction: [-141.4491801285, 13.5427427351, 57.1891833298],
      "speed factor": 0.9248616522,
      "Uz factor": 0.9994643778,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1565295531,
      "Direction stability factor": 0.4482267682,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.2665845055, 0.6145196986, 1.0096165037],
      direction: [-19.0681268348, -5.338124943, 41.43033101],
      "speed factor": 0.9904767008,
      "Uz factor": 0.999983981,
      "Directional variance factor": 0.5255000051,
      "Velocity stability factor": 0.1991043022,
      "Direction stability factor": 0.8319487282,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7654480254, 0.9072314822, 0.9843497816],
      direction: [-5.5441990146, -2.8878445729, -0.5532336982],
      "speed factor": 1.2329469755,
      "Uz factor": 0.998591631,
      "Directional variance factor": 0.7433027046,
      "Velocity stability factor": 0.7775777832,
      "Direction stability factor": 0.9861362075,
      score: 0.8732882257,
    },
    "90.0": {
      velocity: [0.3168802015, 0.6944777006, 0.9506122483],
      direction: [-25.9772428382, 1.3518373567, 33.216772785],
      "speed factor": 1.0032321074,
      "Uz factor": 0.999997835,
      "Directional variance factor": 0.8798366794,
      "Velocity stability factor": 0.3558518794,
      "Direction stability factor": 0.8355721788,
      score: 0.7267082291,
    },
    "112.5": {
      velocity: [1.1592863449, 1.2016674664, 1.2287034448],
      direction: [2.767347911, 5.3672775332, 6.908768853],
      "speed factor": 1.1484485223,
      "Uz factor": 0.9949904751,
      "Directional variance factor": 0.5229086637,
      "Velocity stability factor": 0.9437838181,
      "Direction stability factor": 0.9884960529,
      score: 0.8609211469,
    },
    "135.0": {
      velocity: [1.1328572011, 1.1402648217, 1.1494539382],
      direction: [-2.7795010188, -2.3643959656, -1.7710028925],
      "speed factor": 1.3189509801,
      "Uz factor": 0.9871090265,
      "Directional variance factor": 0.7898314697,
      "Velocity stability factor": 0.9830939814,
      "Direction stability factor": 0.9971986163,
      score: 0.9418306709,
    },
    "157.5": {
      velocity: [0.0900178472, 0.5614941479, 1.0180175924],
      direction: [-156.0918031503, -17.4536655801, 31.5373660562],
      "speed factor": 0.5385463486,
      "Uz factor": 0.9929300958,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3076619502,
      "Direction stability factor": 0.4788078633,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.119722215, 0.5068773363, 0.7951575869],
      direction: [-61.6159840446, 0.4326628127, 79.4381867732],
      "speed factor": 0.7094413375,
      "Uz factor": 0.9950938517,
      "Directional variance factor": 0.9615410833,
      "Velocity stability factor": 0.3396257353,
      "Direction stability factor": 0.6081828588,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.105154828, 1.1221479576, 1.1388844122],
      direction: [-7.6586859555, -7.1246143517, -6.6509572899],
      "speed factor": 1.3345588801,
      "Uz factor": 0.97246526,
      "Directional variance factor": 0.3667009465,
      "Velocity stability factor": 0.9730540475,
      "Direction stability factor": 0.9972007537,
      score: 0.8335391254,
    },
    "225.0": {
      velocity: [0.4463046039, 0.7771475617, 1.0399121253],
      direction: [-33.2722336067, -3.90283215, 4.9758166809],
      "speed factor": 0.9410860115,
      "Uz factor": 0.9877680833,
      "Directional variance factor": 0.6530815867,
      "Velocity stability factor": 0.4424084807,
      "Direction stability factor": 0.8937554159,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3278544018, 0.6410078053, 0.8867373952],
      direction: [-50.8656070248, 3.7563565154, 44.6367155251],
      "speed factor": 0.8030953477,
      "Uz factor": 0.9908866538,
      "Directional variance factor": 0.6661016431,
      "Velocity stability factor": 0.556825543,
      "Direction stability factor": 0.7347157707,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.8566423752, 0.9447106666, 1.0617175753],
      direction: [-4.3182315887, 0.4457275236, 3.7877591327],
      "speed factor": 1.3249150912,
      "Uz factor": 0.997462713,
      "Directional variance factor": 0.9603797757,
      "Velocity stability factor": 0.8251384992,
      "Direction stability factor": 0.9774833591,
      score: 0.9351212483,
    },
    "292.5": {
      velocity: [1.0228547941, 1.1364565708, 1.2284648436],
      direction: [-4.1787861773, -1.271624317, 1.7568978565],
      "speed factor": 1.1358977488,
      "Uz factor": 0.9945006643,
      "Directional variance factor": 0.8869667274,
      "Velocity stability factor": 0.7903818493,
      "Direction stability factor": 0.9835119888,
      score: 0.9110931386,
    },
    "315.0": {
      velocity: [0.8887665725, 0.9004313472, 0.9146501452],
      direction: [0.9599444493, 1.3697609631, 1.6927008925],
      "speed factor": 1.1190065831,
      "Uz factor": 0.9999790428,
      "Directional variance factor": 0.8782434699,
      "Velocity stability factor": 0.9583385887,
      "Direction stability factor": 0.9979645654,
      score: 0.9581277974,
    },
    "337.5": {
      velocity: [1.0858257208, 1.147627159, 1.1853250642],
      direction: [-5.0113135349, -3.866118569, -1.6720064036],
      "speed factor": 1.1473612805,
      "Uz factor": 0.9999997818,
      "Directional variance factor": 0.6563450161,
      "Velocity stability factor": 0.9008355345,
      "Direction stability factor": 0.9907241469,
      score: 0.8846572111,
    },
    id: "IDN:3641.0",
    zone: 1,
  },
  {
    position: [52.54134, 8.2679947, 21.1598299],
    "overall score": 0.5447744206,
    "0": {
      velocity: [0.4891872454, 0.6758265717, 0.7952120525],
      direction: [-21.3187914812, -10.1383807936, 0.8189259276],
      "speed factor": 1.1194240609,
      "Uz factor": 0.9968826332,
      "Directional variance factor": 0.0988105961,
      "Velocity stability factor": 0.6600664075,
      "Direction stability factor": 0.9385063405,
      score: 0.6589724212,
    },
    "22.5": {
      velocity: [0.5085579523, 0.8007055559, 1.0469171799],
      direction: [-7.5723773582, 6.4460174002, 24.3548335389],
      "speed factor": 1.0594165229,
      "Uz factor": 0.9996667062,
      "Directional variance factor": 0.4270206755,
      "Velocity stability factor": 0.5451304685,
      "Direction stability factor": 0.9113133031,
      score: 0.6986944375,
    },
    "45.0": {
      velocity: [0.4369704148, 0.7394236023, 0.8966226025],
      direction: [-9.1047991013, 4.3783985122, 13.8475151231],
      "speed factor": 1.191795563,
      "Uz factor": 0.9958012029,
      "Directional variance factor": 0.6108090211,
      "Velocity stability factor": 0.504552347,
      "Direction stability factor": 0.9362435716,
      score: 0.7469621278,
    },
    "67.5": {
      velocity: [0.7735108531, 0.90856277, 1.0453338587],
      direction: [-3.0757697282, 1.6111368698, 7.0524813201],
      "speed factor": 1.234756224,
      "Uz factor": 0.9950404002,
      "Directional variance factor": 0.8567878338,
      "Velocity stability factor": 0.7238054342,
      "Direction stability factor": 0.9718659693,
      score: 0.8810813017,
    },
    "90.0": {
      velocity: [0.9717370289, 1.0073215571, 1.0324588747],
      direction: [4.2755382505, 5.0477841568, 6.3091673616],
      "speed factor": 1.455161667,
      "Uz factor": 0.9962091141,
      "Directional variance factor": 0.5513080749,
      "Velocity stability factor": 0.9382801248,
      "Direction stability factor": 0.9943510302,
      score: 0.8695725651,
    },
    "112.5": {
      velocity: [0.5818015479, 1.0107666228, 1.1192998099],
      direction: [-19.888846382, 4.2947904003, 14.5812635512],
      "speed factor": 0.966002215,
      "Uz factor": 0.9985080312,
      "Directional variance factor": 0.6182408533,
      "Velocity stability factor": 0.5647167621,
      "Direction stability factor": 0.9042496946,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.9600948826, 1.0634045845, 1.1495634846],
      direction: [-0.7472437535, 2.4214444369, 13.4280201885],
      "speed factor": 1.2300462947,
      "Uz factor": 0.9985163721,
      "Directional variance factor": 0.7847604945,
      "Velocity stability factor": 0.8070006359,
      "Direction stability factor": 0.9606242668,
      score: 0.878252416,
    },
    "157.5": {
      velocity: [1.1406070407, 1.1510121568, 1.1626922526],
      direction: [-0.836243985, -0.2758705663, 0.3812701741],
      "speed factor": 1.1039712464,
      "Uz factor": 0.9995386149,
      "Directional variance factor": 0.9754781719,
      "Velocity stability factor": 0.9835232363,
      "Direction stability factor": 0.9966180162,
      score: 0.9880593601,
    },
    "180.0": {
      velocity: [0.4069902799, 0.8186452738, 0.98134833],
      direction: [-43.2930428796, -8.0477072471, 5.0620309889],
      "speed factor": 1.1458014718,
      "Uz factor": 0.9990978033,
      "Directional variance factor": 0.2846482447,
      "Velocity stability factor": 0.4384491976,
      "Direction stability factor": 0.8656803504,
      score: 0.6136145358,
    },
    "202.5": {
      velocity: [0.1165658939, 0.4978092721, 0.8408573085],
      direction: [-53.8680206704, 19.1619893043, 87.6552826336],
      "speed factor": 0.5920393832,
      "Uz factor": 0.9963239344,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4213767365,
      "Direction stability factor": 0.606879713,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1013821393, 1.1355848542, 1.1632398161],
      direction: [0.2079525668, 1.8325358324, 3.8441937858],
      "speed factor": 1.3751352688,
      "Uz factor": 0.9934584035,
      "Directional variance factor": 0.837107926,
      "Velocity stability factor": 0.9418954195,
      "Direction stability factor": 0.9898993299,
      score: 0.9397005014,
    },
    "247.5": {
      velocity: [0.7053744977, 0.9848748862, 1.2133747975],
      direction: [-21.2598153259, -6.6789966667, 10.5831828101],
      "speed factor": 1.233913897,
      "Uz factor": 0.9988069955,
      "Directional variance factor": 0.4063114074,
      "Velocity stability factor": 0.5971737203,
      "Direction stability factor": 0.9115472274,
      score: 0.7066448956,
    },
    "270.0": {
      velocity: [0.7542766359, 0.9582456614, 1.0471879882],
      direction: [-0.2306772568, 8.2690076694, 13.4269421529],
      "speed factor": 1.3438973252,
      "Uz factor": 0.9999999533,
      "Directional variance factor": 0.2649770961,
      "Velocity stability factor": 0.7502432344,
      "Direction stability factor": 0.9620621683,
      score: 0.7348361668,
    },
    "292.5": {
      velocity: [0.4107806978, 0.4635859479, 0.5390356923],
      direction: [4.4363209224, 12.1530205859, 18.8857511113],
      "speed factor": 0.463357992,
      "Uz factor": 0.9996013563,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8692448407,
      "Direction stability factor": 0.9598626939,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4139344784, 0.4351981739, 0.4540705114],
      direction: [5.2739708536, 8.45971619, 11.5966327198],
      "speed factor": 0.5408403684,
      "Uz factor": 0.9963811011,
      "Directional variance factor": 0.2480252276,
      "Velocity stability factor": 0.9353982621,
      "Direction stability factor": 0.9824370504,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2735044922, 0.5701887796, 0.7469363434],
      direction: [-31.6854705405, -9.2705539255, 11.6299051667],
      "speed factor": 0.5700566801,
      "Uz factor": 0.9993886213,
      "Directional variance factor": 0.1759507622,
      "Velocity stability factor": 0.528161545,
      "Direction stability factor": 0.8796795119,
      score: 0.0,
    },
    id: "IDN:1970.0",
    zone: 1,
  },
  {
    position: [52.54134, 12.2679947, 21.1598299],
    "overall score": 0.5438516872,
    "0": {
      velocity: [0.5247678641, 0.6889050767, 0.8156984521],
      direction: [-22.4081799084, -11.8384081155, -2.1278077146],
      "speed factor": 1.1410870049,
      "Uz factor": 0.9991599116,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6768331271,
      "Direction stability factor": 0.9436656328,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4963482261, 0.761448778, 1.0213008223],
      direction: [-9.7912394563, 5.3556478356, 24.1433071844],
      "speed factor": 1.0074757329,
      "Uz factor": 0.9998218119,
      "Directional variance factor": 0.5239424146,
      "Velocity stability factor": 0.556457976,
      "Direction stability factor": 0.9057373704,
      score: 0.7229687829,
    },
    "45.0": {
      velocity: [0.3986270878, 0.6907084348, 0.8814398311],
      direction: [-17.8668792616, 5.9506940664, 18.036213687],
      "speed factor": 1.1132769435,
      "Uz factor": 0.9968421743,
      "Directional variance factor": 0.4710494163,
      "Velocity stability factor": 0.4795881606,
      "Direction stability factor": 0.9002691863,
      score: 0.6877939874,
    },
    "67.5": {
      velocity: [0.7167332948, 0.8547676226, 1.0127383665],
      direction: [-0.534300811, 4.2358304392, 9.8413028665],
      "speed factor": 1.1616474689,
      "Uz factor": 0.9922912904,
      "Directional variance factor": 0.6234817387,
      "Velocity stability factor": 0.6992344631,
      "Direction stability factor": 0.9711788787,
      score: 0.8162684898,
    },
    "90.0": {
      velocity: [0.9903125201, 1.0122378668, 1.0281753347],
      direction: [4.1278551233, 4.9065477476, 5.6720931042],
      "speed factor": 1.4622636946,
      "Uz factor": 0.9948180045,
      "Directional variance factor": 0.5638624224,
      "Velocity stability factor": 0.9615148689,
      "Direction stability factor": 0.9957104501,
      score: 0.8791995479,
    },
    "112.5": {
      velocity: [0.9515834883, 1.04894179, 1.1472697099],
      direction: [-3.5494423906, 3.8882143334, 10.1656107737],
      "speed factor": 1.0024866964,
      "Uz factor": 0.9987691065,
      "Directional variance factor": 0.6543809481,
      "Velocity stability factor": 0.8415270556,
      "Direction stability factor": 0.9619026301,
      score: 0.854928316,
    },
    "135.0": {
      velocity: [1.0749834327, 1.1278659547, 1.1930506045],
      direction: [-1.8473126139, 0.0769725944, 4.7067218404],
      "speed factor": 1.3046091382,
      "Uz factor": 0.9998625581,
      "Directional variance factor": 0.9931579916,
      "Velocity stability factor": 0.8797326372,
      "Direction stability factor": 0.9817943487,
      score: 0.9591198316,
    },
    "157.5": {
      velocity: [1.1146215771, 1.1236807369, 1.1325784133],
      direction: [-4.3676198903, -3.6993996857, -3.0685270179],
      "speed factor": 1.0777568389,
      "Uz factor": 0.9999964939,
      "Directional variance factor": 0.6711644724,
      "Velocity stability factor": 0.9866032281,
      "Direction stability factor": 0.9963914087,
      score: 0.9126376295,
    },
    "180.0": {
      velocity: [0.4229312026, 0.8221484762, 1.0066163741],
      direction: [-42.044677143, -8.5973336989, 8.4340611799],
      "speed factor": 1.1507046631,
      "Uz factor": 0.9997970318,
      "Directional variance factor": 0.2357925601,
      "Velocity stability factor": 0.4293300559,
      "Direction stability factor": 0.8597812824,
      score: 0.5961712952,
    },
    "202.5": {
      velocity: [0.1783040942, 0.5192253822, 0.8489059947],
      direction: [-15.7117574811, 16.3274545613, 82.9078975757],
      "speed factor": 0.6175093399,
      "Uz factor": 0.9984701867,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4642683148,
      "Direction stability factor": 0.7260565137,
      score: 0.0,
    },
    "225.0": {
      velocity: [0.9585318416, 1.0846597057, 1.1433773623],
      direction: [-2.9117181625, 1.1472090258, 7.1048833424],
      "speed factor": 1.3134675145,
      "Uz factor": 0.997258982,
      "Directional variance factor": 0.8980258644,
      "Velocity stability factor": 0.826369628,
      "Direction stability factor": 0.9721761069,
      score: 0.9171869266,
    },
    "247.5": {
      velocity: [0.3892676033, 0.9094962064, 1.3007593768],
      direction: [-21.7862965718, -7.4160687998, 22.069940114],
      "speed factor": 1.1394746928,
      "Uz factor": 0.9998515419,
      "Directional variance factor": 0.3407938845,
      "Velocity stability factor": 0.2772192452,
      "Direction stability factor": 0.8781771203,
      score: 0.5935918426,
    },
    "270.0": {
      velocity: [0.8226235735, 0.9814842317, 1.033880378],
      direction: [-1.3663933365, 7.8465465779, 12.1975854264],
      "speed factor": 1.3764883962,
      "Uz factor": 0.9998035628,
      "Directional variance factor": 0.3025291931,
      "Velocity stability factor": 0.8198676297,
      "Direction stability factor": 0.9623222812,
      score: 0.7617603463,
    },
    "292.5": {
      velocity: [0.3727234816, 0.4293244747, 0.496934204],
      direction: [4.5819975838, 9.6040467787, 14.4965271783],
      "speed factor": 0.4291133659,
      "Uz factor": 0.9893173976,
      "Directional variance factor": 0.146306953,
      "Velocity stability factor": 0.8733679506,
      "Direction stability factor": 0.97245964,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4202438062, 0.4418671305, 0.4612916626],
      direction: [-0.0988077269, 2.1024609385, 5.0885687042],
      "speed factor": 0.549128181,
      "Uz factor": 0.9999870277,
      "Directional variance factor": 0.8131145832,
      "Velocity stability factor": 0.933930619,
      "Direction stability factor": 0.985590621,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4438566547, 0.584084452, 0.7166720089],
      direction: [-19.7181527666, -10.2235709345, -0.1427692175],
      "speed factor": 0.5839491332,
      "Uz factor": 0.9994120837,
      "Directional variance factor": 0.0912381392,
      "Velocity stability factor": 0.7281028412,
      "Direction stability factor": 0.9456239346,
      score: 0.0,
    },
    id: "IDN:2044.0",
    zone: 1,
  },
  {
    position: [-11.24225074, 2.655692444, 21.1598299],
    "overall score": 0.5427968618,
    "0": {
      velocity: [0.5225614889, 0.8352764666, 0.9524390524],
      direction: [-24.0439906675, -2.2570245448, 9.2650601284],
      "speed factor": 1.3835333107,
      "Uz factor": 0.999644428,
      "Directional variance factor": 0.799375596,
      "Velocity stability factor": 0.5224902651,
      "Direction stability factor": 0.9074748589,
      score: 0.7842038947,
    },
    "22.5": {
      velocity: [0.2023444823, 0.8142785616, 1.2144821699],
      direction: [-27.9813986364, 2.8933929262, 34.1900912349],
      "speed factor": 1.0773750177,
      "Uz factor": 0.9978981786,
      "Directional variance factor": 0.7428095177,
      "Velocity stability factor": 0.1448264056,
      "Direction stability factor": 0.827301417,
      score: 0.6355596893,
    },
    "45.0": {
      velocity: [0.2203682854, 0.6417555564, 0.9837027941],
      direction: [-30.0814506287, -11.0634975798, 26.2326829414],
      "speed factor": 1.034375184,
      "Uz factor": 0.9986830866,
      "Directional variance factor": 0.0165779929,
      "Velocity stability factor": 0.1772207315,
      "Direction stability factor": 0.8435718512,
      score: 0.4702356067,
    },
    "67.5": {
      velocity: [0.7986103354, 0.9128321518, 0.9733755441],
      direction: [-4.401906153, -2.8038272532, -0.0678373008],
      "speed factor": 1.2405584051,
      "Uz factor": 0.9995262674,
      "Directional variance factor": 0.7507709108,
      "Velocity stability factor": 0.8224241513,
      "Direction stability factor": 0.9879609199,
      score: 0.8872792255,
    },
    "90.0": {
      velocity: [0.2764643483, 0.6344260007, 0.9494359196],
      direction: [-33.1973908741, 2.0408102715, 42.3933908705],
      "speed factor": 0.9164823192,
      "Uz factor": 0.9995857121,
      "Directional variance factor": 0.8185946425,
      "Velocity stability factor": 0.3159674108,
      "Direction stability factor": 0.7900256063,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1078549818, 1.1764890503, 1.2492217568],
      direction: [5.2820930111, 7.8542801504, 9.3879726531],
      "speed factor": 1.1243851973,
      "Uz factor": 0.9897406547,
      "Directional variance factor": 0.3018417644,
      "Velocity stability factor": 0.8855166762,
      "Direction stability factor": 0.9885947788,
      score: 0.7911369995,
    },
    "135.0": {
      velocity: [1.1842456229, 1.1904493791, 1.1956349308],
      direction: [-1.0206501357, -0.683629278, -0.3760530212],
      "speed factor": 1.3769997508,
      "Uz factor": 0.9838528314,
      "Directional variance factor": 0.9392329531,
      "Velocity stability factor": 0.9883984514,
      "Direction stability factor": 0.9982094525,
      score: 0.9810125774,
    },
    "157.5": {
      velocity: [0.3078465912, 0.7884341146, 1.0231593316],
      direction: [-46.9619153291, -13.9885294291, 24.2018987392],
      "speed factor": 0.7562114675,
      "Uz factor": 0.998972851,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4663379702,
      "Direction stability factor": 0.8023227387,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0726068805, 0.3559123311, 0.7414353812],
      direction: [-147.9574121059, 8.9017668138, 169.9818428729],
      "speed factor": 0.4981460052,
      "Uz factor": 0.9999682433,
      "Directional variance factor": 0.2087318388,
      "Velocity stability factor": 0.3460852839,
      "Direction stability factor": 0.1168354028,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0566522742, 1.0894736438, 1.1116247587],
      direction: [0.2681248631, 0.901411879, 1.6430016451],
      "speed factor": 1.295699659,
      "Uz factor": 0.9737081839,
      "Directional variance factor": 0.9198744996,
      "Velocity stability factor": 0.95608348,
      "Direction stability factor": 0.9961808978,
      score: 0.9670799438,
    },
    "225.0": {
      velocity: [1.0569215846, 1.0901491215, 1.1371117665],
      direction: [-10.7482383556, -7.9026274108, -5.9543212471],
      "speed factor": 1.3201149166,
      "Uz factor": 0.9910361677,
      "Directional variance factor": 0.2975442302,
      "Velocity stability factor": 0.9246752042,
      "Direction stability factor": 0.9866835636,
      score: 0.7988966404,
    },
    "247.5": {
      velocity: [0.0474637846, 0.3650901123, 0.8853498018],
      direction: [-154.9150549049, -8.1146610851, 174.3101256755],
      "speed factor": 0.457408113,
      "Uz factor": 0.9860178666,
      "Directional variance factor": 0.2786967924,
      "Velocity stability factor": 0.3355860079,
      "Direction stability factor": 0.0854856095,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.4049601474, 0.9020912424, 1.0740416382],
      direction: [-7.4900518902, 3.8626992859, 14.2072939906],
      "speed factor": 1.2651432264,
      "Uz factor": 0.9978289082,
      "Directional variance factor": 0.6566489524,
      "Velocity stability factor": 0.4294941876,
      "Direction stability factor": 0.9397295948,
      score: 0.7414005824,
    },
    "292.5": {
      velocity: [1.1154304048, 1.1596428864, 1.195507674],
      direction: [4.1106102239, 6.4567420894, 7.7595812846],
      "speed factor": 1.1590726632,
      "Uz factor": 0.9984691453,
      "Directional variance factor": 0.4260673698,
      "Velocity stability factor": 0.9183617283,
      "Direction stability factor": 0.9898639693,
      score: 0.8310392592,
    },
    "315.0": {
      velocity: [0.8445444833, 0.857391568, 0.872245755],
      direction: [7.9880109126, 8.5720889849, 9.0370388287],
      "speed factor": 1.0655191113,
      "Uz factor": 0.9999299132,
      "Directional variance factor": 0.2380365347,
      "Velocity stability factor": 0.9554128756,
      "Direction stability factor": 0.9970860336,
      score: 0.7969053694,
    },
    "337.5": {
      velocity: [0.5636888271, 0.7544698391, 0.9060510637],
      direction: [-13.2436583528, 0.8821780232, 9.9422957096],
      "speed factor": 0.754295046,
      "Uz factor": 0.9990977211,
      "Directional variance factor": 0.9215841757,
      "Velocity stability factor": 0.6587900279,
      "Direction stability factor": 0.935594572,
      score: 0.0,
    },
    id: "IDN:3549.0",
    zone: 1,
  },
  {
    position: [-31.8936219, 13.3169522, 20.8740369],
    "overall score": 0.542258689,
    "0": {
      velocity: [0.7057465515, 0.8264867662, 0.9739571864],
      direction: [1.7751624112, 6.3846285932, 10.6975073738],
      "speed factor": 1.368974247,
      "Uz factor": 0.9946082064,
      "Directional variance factor": 0.4324774584,
      "Velocity stability factor": 0.7020705427,
      "Direction stability factor": 0.9752157084,
      score: 0.7712448545,
    },
    "22.5": {
      velocity: [0.3338965, 0.6954948991, 1.1125219072],
      direction: [-26.948174788, 16.2915849799, 67.1857938467],
      "speed factor": 0.9202119084,
      "Uz factor": 0.996705943,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3421251908,
      "Direction stability factor": 0.7385167538,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6413969935, 0.86498529, 1.1010484963],
      direction: [-14.2884598756, -3.9666900983, 7.6043299734],
      "speed factor": 1.3941746347,
      "Uz factor": 0.9925624483,
      "Directional variance factor": 0.6474053246,
      "Velocity stability factor": 0.5045530853,
      "Direction stability factor": 0.9391866949,
      score: 0.7575829499,
    },
    "67.5": {
      velocity: [0.5028736232, 0.8700734068, 0.9649709265],
      direction: [-10.2609457804, -4.7010916752, -1.7052324943],
      "speed factor": 1.1824483568,
      "Uz factor": 0.9979822596,
      "Directional variance factor": 0.5821251844,
      "Velocity stability factor": 0.5304710735,
      "Direction stability factor": 0.9762341298,
      score: 0.7662661294,
    },
    "90.0": {
      velocity: [0.4949940208, 0.8235616519, 0.9892772101],
      direction: [-14.3683326871, 3.0471081057, 20.2475246274],
      "speed factor": 1.1897048543,
      "Uz factor": 0.9998140651,
      "Directional variance factor": 0.7291459462,
      "Velocity stability factor": 0.4975927302,
      "Direction stability factor": 0.9038448408,
      score: 0.7586070895,
    },
    "112.5": {
      velocity: [0.5885656517, 0.9527940125, 1.1657954123],
      direction: [-12.9957844758, 9.9988351642, 18.6770025137],
      "speed factor": 0.9105970713,
      "Uz factor": 0.9995532167,
      "Directional variance factor": 0.1112146521,
      "Velocity stability factor": 0.5325409272,
      "Direction stability factor": 0.9120200361,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.3773195744, 0.5941040115, 0.9221672204],
      direction: [-4.829715828, 5.3603031849, 10.6721451297],
      "speed factor": 0.6872035805,
      "Uz factor": 0.9997853483,
      "Directional variance factor": 0.5235286058,
      "Velocity stability factor": 0.4449990758,
      "Direction stability factor": 0.9569392751,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3333010935, 0.6506983985, 1.0691578857],
      direction: [-80.4840722681, -16.4488569424, 22.5088360233],
      "speed factor": 0.6241048956,
      "Uz factor": 0.9996770774,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4510109953,
      "Direction stability factor": 0.7139085881,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1337599186, 0.6008321964, 0.9315087432],
      direction: [-45.0443356602, -0.8334599231, 75.3788367517],
      "speed factor": 0.8409434916,
      "Uz factor": 0.9996135696,
      "Directional variance factor": 0.9259146735,
      "Velocity stability factor": 0.2200396731,
      "Direction stability factor": 0.6654911877,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1108846172, 1.1242892246, 1.1318712842],
      direction: [-1.1323968412, -0.2278873989, 0.6098171387],
      "speed factor": 1.3371054667,
      "Uz factor": 0.9999999694,
      "Directional variance factor": 0.9797433423,
      "Velocity stability factor": 0.9832341327,
      "Direction stability factor": 0.9951605167,
      score: 0.9883246271,
    },
    "225.0": {
      velocity: [0.3098578909, 0.6572338325, 0.8804991793],
      direction: [-18.8539132986, 0.6012924335, 18.6235908911],
      "speed factor": 0.7958766089,
      "Uz factor": 0.9971483902,
      "Directional variance factor": 0.9465517837,
      "Velocity stability factor": 0.4639812813,
      "Direction stability factor": 0.8958958217,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.8683254366, 0.9399066596, 0.9929448944],
      direction: [-0.7269243309, 1.6027306724, 3.9001142605],
      "speed factor": 1.1775748427,
      "Uz factor": 0.991628938,
      "Directional variance factor": 0.8575350513,
      "Velocity stability factor": 0.9011811753,
      "Direction stability factor": 0.987147115,
      score: 0.9332526142,
    },
    "270.0": {
      velocity: [1.02860768, 1.0885395311, 1.1351835779],
      direction: [-4.3888821275, -1.9958478586, 0.9228698472],
      "speed factor": 1.5266287375,
      "Uz factor": 0.998205248,
      "Directional variance factor": 0.8225913015,
      "Velocity stability factor": 0.9091259136,
      "Direction stability factor": 0.9852451334,
      score: 0.9255518705,
    },
    "292.5": {
      velocity: [1.2081797141, 1.2144816157, 1.2203954027],
      direction: [-4.5584402753, -4.2631670757, -3.9170099499],
      "speed factor": 1.2138844271,
      "Uz factor": 0.9950614437,
      "Directional variance factor": 0.6210518155,
      "Velocity stability factor": 0.9875461824,
      "Direction stability factor": 0.9982182491,
      score: 0.901258624,
    },
    "315.0": {
      velocity: [0.905529006, 0.9114457498, 0.9197408246],
      direction: [3.1642661235, 3.4567636139, 3.660264266],
      "speed factor": 1.1326946772,
      "Uz factor": 0.9996793145,
      "Directional variance factor": 0.6927321232,
      "Velocity stability factor": 0.977125089,
      "Direction stability factor": 0.9986222274,
      score: 0.9167754167,
    },
    "337.5": {
      velocity: [1.0945148209, 1.1739443283, 1.2214292528],
      direction: [-1.3519112715, 0.323022207, 1.4741316927],
      "speed factor": 1.1736723527,
      "Uz factor": 0.9985880067,
      "Directional variance factor": 0.9712869149,
      "Velocity stability factor": 0.8735127151,
      "Direction stability factor": 0.9921498807,
      score: 0.9572748478,
    },
    id: "IDN:2158.0",
    zone: 1,
  },
  {
    position: [-29.8936219, 13.3169522, 20.8740369],
    "overall score": 0.5387163857,
    "0": {
      velocity: [0.7156798132, 0.8126265091, 0.9444965822],
      direction: [-1.4359970935, 4.5481439096, 10.2110209245],
      "speed factor": 1.3460164262,
      "Uz factor": 0.9919324142,
      "Directional variance factor": 0.5957205414,
      "Velocity stability factor": 0.7458294082,
      "Direction stability factor": 0.9676471722,
      score: 0.8192110735,
    },
    "22.5": {
      velocity: [0.2354910834, 0.7232181547, 1.1412614644],
      direction: [-27.3551428546, 15.4107795277, 38.573018014],
      "speed factor": 0.9568926519,
      "Uz factor": 0.9971151516,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2346980832,
      "Direction stability factor": 0.8168662198,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6229711526, 0.8701497892, 1.0654341651],
      direction: [-16.2883058212, -5.6250508899, 4.8269261735],
      "speed factor": 1.4024987228,
      "Uz factor": 0.9927008886,
      "Directional variance factor": 0.4999954765,
      "Velocity stability factor": 0.5230801311,
      "Direction stability factor": 0.9413465778,
      score: 0.7264421908,
    },
    "67.5": {
      velocity: [0.5915357343, 0.8764884076, 0.9587200052],
      direction: [-11.119503041, -5.1237512131, -0.6918002651],
      "speed factor": 1.19116648,
      "Uz factor": 0.9984149265,
      "Directional variance factor": 0.5445554477,
      "Velocity stability factor": 0.6269105331,
      "Direction stability factor": 0.971034159,
      score: 0.7783835747,
    },
    "90.0": {
      velocity: [0.5658537378, 0.8245543365, 1.0007413643],
      direction: [-9.4495235187, 3.0959432216, 24.2222160478],
      "speed factor": 1.1911388717,
      "Uz factor": 0.9995025754,
      "Directional variance factor": 0.724805047,
      "Velocity stability factor": 0.5579645235,
      "Direction stability factor": 0.9064673901,
      score: 0.7739260877,
    },
    "112.5": {
      velocity: [0.6039317748, 0.9929126806, 1.1495497646],
      direction: [-18.4464990381, 10.5880622375, 18.0260365769],
      "speed factor": 0.9489389808,
      "Uz factor": 0.9984003724,
      "Directional variance factor": 0.0588389122,
      "Velocity stability factor": 0.5581411475,
      "Direction stability factor": 0.8986874011,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.3016233623, 0.4401545466, 0.6488846945],
      direction: [0.199237164, 5.8746056224, 13.6550908586],
      "speed factor": 0.5091293352,
      "Uz factor": 0.9995771569,
      "Directional variance factor": 0.4778128336,
      "Velocity stability factor": 0.6462674259,
      "Direction stability factor": 0.9626226286,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.3756871044, 0.6677296493, 1.0137017414],
      direction: [-61.0401867345, -15.5097194375, 16.3848734527],
      "speed factor": 0.6404400934,
      "Uz factor": 0.9995723851,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5240065402,
      "Direction stability factor": 0.7849303884,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1275031279, 0.5783117271, 0.883881851],
      direction: [-119.7623676496, -0.6844690326, 52.9590233963],
      "speed factor": 0.80942314,
      "Uz factor": 0.9982229187,
      "Directional variance factor": 0.9391583082,
      "Velocity stability factor": 0.2604872888,
      "Direction stability factor": 0.5202183582,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1079411804, 1.1248868659, 1.1337836314],
      direction: [-2.6258758181, -1.4947811635, -0.5031490344],
      "speed factor": 1.3378162353,
      "Uz factor": 0.9994528797,
      "Directional variance factor": 0.8671305632,
      "Velocity stability factor": 0.9793549349,
      "Direction stability factor": 0.9941035367,
      score: 0.9586731429,
    },
    "225.0": {
      velocity: [0.2811843351, 0.5935025402, 0.8214579252],
      direction: [-30.8094232655, 1.4419300257, 38.1724182647],
      "speed factor": 0.7187012684,
      "Uz factor": 0.9985404064,
      "Directional variance factor": 0.8718284422,
      "Velocity stability factor": 0.4925064776,
      "Direction stability factor": 0.8083837735,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.7822979027, 0.8852687182, 0.9562489389],
      direction: [-1.3427279113, 1.9658816373, 5.7654865115],
      "speed factor": 1.1091209547,
      "Uz factor": 0.9934918883,
      "Directional variance factor": 0.8252549656,
      "Velocity stability factor": 0.8620629776,
      "Direction stability factor": 0.9802549599,
      score: 0.9119569658,
    },
    "270.0": {
      velocity: [0.9855346419, 1.0700054583, 1.1301257927],
      direction: [-4.1308878398, -1.3412684484, 2.3868429786],
      "speed factor": 1.5006355168,
      "Uz factor": 0.9996567451,
      "Directional variance factor": 0.8807761379,
      "Velocity stability factor": 0.8767114424,
      "Direction stability factor": 0.9818951922,
      score: 0.9303194912,
    },
    "292.5": {
      velocity: [1.2297811332, 1.2348829386, 1.2402241493],
      direction: [-2.6202283596, -2.2052495709, -1.7952477204],
      "speed factor": 1.2342757182,
      "Uz factor": 0.9999882005,
      "Directional variance factor": 0.8039778159,
      "Velocity stability factor": 0.9893534109,
      "Direction stability factor": 0.9977083871,
      score: 0.9471870003,
    },
    "315.0": {
      velocity: [0.876296067, 0.8862727301, 0.8941969388],
      direction: [6.2057867522, 6.5531396475, 6.8160089233],
      "speed factor": 1.101411032,
      "Uz factor": 0.9998887379,
      "Directional variance factor": 0.417498698,
      "Velocity stability factor": 0.9711873013,
      "Direction stability factor": 0.9983049384,
      score: 0.846323969,
    },
    "337.5": {
      velocity: [1.0418843136, 1.1692989758, 1.2361118927],
      direction: [-1.0357716799, 0.8831723522, 2.5223263239],
      "speed factor": 1.1690280764,
      "Uz factor": 0.9978488241,
      "Directional variance factor": 0.9214957909,
      "Velocity stability factor": 0.806426119,
      "Direction stability factor": 0.9901163944,
      score: 0.9270386747,
    },
    id: "IDN:2159.0",
    zone: 1,
  },
  {
    position: [-15.10067392, 2.500613346, 21.1598299],
    "overall score": 0.5383280808,
    "0": {
      velocity: [0.0993189545, 0.7339508489, 0.9411598102],
      direction: [-67.6673890176, 0.01331245, 34.7823986815],
      "speed factor": 1.2156998173,
      "Uz factor": 0.9997341305,
      "Directional variance factor": 0.9988166711,
      "Velocity stability factor": 0.0648797751,
      "Direction stability factor": 0.7154172564,
      score: 0.6236327398,
    },
    "22.5": {
      velocity: [0.1922447065, 0.8240196792, 1.206195513],
      direction: [-30.0799513363, 4.7106799047, 73.9891584851],
      "speed factor": 1.0902635268,
      "Uz factor": 0.9971143868,
      "Directional variance factor": 0.5812728974,
      "Velocity stability factor": 0.1432944685,
      "Direction stability factor": 0.7109191394,
      score: 0.5366014111,
    },
    "45.0": {
      velocity: [0.3072767762, 0.6791210445, 1.0483995843],
      direction: [-26.0415843402, -11.1692930758, 23.6159286379],
      "speed factor": 1.0946005037,
      "Uz factor": 0.9984101994,
      "Directional variance factor": 0.0071739488,
      "Velocity stability factor": 0.2011621708,
      "Direction stability factor": 0.8620624639,
      score: 0.4831152619,
    },
    "67.5": {
      velocity: [0.7785771006, 0.9195087658, 0.9757818848],
      direction: [-6.5124008532, -2.8809231501, 0.8102712687],
      "speed factor": 1.2496320663,
      "Uz factor": 0.9994633952,
      "Directional variance factor": 0.7439179422,
      "Velocity stability factor": 0.7996236941,
      "Direction stability factor": 0.9796592441,
      score: 0.8757150311,
    },
    "90.0": {
      velocity: [0.2928335418, 0.6461357204, 0.9260475154],
      direction: [-33.0510476576, 2.4273025809, 39.9280100141],
      "speed factor": 0.9333980053,
      "Uz factor": 0.999004754,
      "Directional variance factor": 0.7842397706,
      "Velocity stability factor": 0.3563784678,
      "Direction stability factor": 0.7972803954,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1424258574, 1.1872230641, 1.2149086503],
      direction: [3.0893723007, 6.8083321318, 8.6444005406],
      "speed factor": 1.1346438276,
      "Uz factor": 0.9903498554,
      "Directional variance factor": 0.3948149216,
      "Velocity stability factor": 0.9413011222,
      "Direction stability factor": 0.984569366,
      score: 0.826313694,
    },
    "135.0": {
      velocity: [1.1755941763, 1.1831935692, 1.1878522648],
      direction: [-1.2098794391, -0.9333418166, -0.6363558737],
      "speed factor": 1.368606913,
      "Uz factor": 0.9853224069,
      "Directional variance factor": 0.917036283,
      "Velocity stability factor": 0.9875134811,
      "Direction stability factor": 0.998406879,
      score: 0.9753408805,
    },
    "157.5": {
      velocity: [0.3137670951, 0.6965563919, 1.0018734802],
      direction: [-68.4212595222, -14.5910215681, 39.1689396132],
      "speed factor": 0.6680887109,
      "Uz factor": 0.9958348202,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4866353842,
      "Direction stability factor": 0.7011383357,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0461881631, 0.3764822947, 0.6948308446],
      direction: [-178.0544750561, 3.148000129, 146.6426841358],
      "speed factor": 0.5269363681,
      "Uz factor": 0.9993388296,
      "Directional variance factor": 0.7201777663,
      "Velocity stability factor": 0.3658209924,
      "Direction stability factor": 0.0980634467,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0818862028, 1.1076783767, 1.1314723859],
      direction: [-0.9578719697, -0.2887038266, 0.5658750256],
      "speed factor": 1.3173503583,
      "Uz factor": 0.97518037,
      "Directional variance factor": 0.9743374376,
      "Velocity stability factor": 0.9603864984,
      "Direction stability factor": 0.9957673695,
      score: 0.9815646687,
    },
    "225.0": {
      velocity: [0.9792673284, 1.0386651832, 1.1310254911],
      direction: [-12.9049486458, -7.1083602869, -3.7849016847],
      "speed factor": 1.2577704965,
      "Uz factor": 0.9975019977,
      "Directional variance factor": 0.3681457523,
      "Velocity stability factor": 0.8574494739,
      "Direction stability factor": 0.9746665362,
      score: 0.7937320746,
    },
    "247.5": {
      velocity: [0.1010310307, 0.4243837668, 0.8256924029],
      direction: [-162.9702802731, 0.0018912287, 166.337812809],
      "speed factor": 0.5316949745,
      "Uz factor": 0.9967887189,
      "Directional variance factor": 0.9998318908,
      "Velocity stability factor": 0.4253691489,
      "Direction stability factor": 0.085255297,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.6291411895, 0.8733042704, 1.0542061346],
      direction: [-10.3621682284, 1.7839844458, 9.0666650459],
      "speed factor": 1.2247707664,
      "Uz factor": 0.999097274,
      "Directional variance factor": 0.8414236048,
      "Velocity stability factor": 0.6375598112,
      "Direction stability factor": 0.9460310187,
      score: 0.8427613634,
    },
    "292.5": {
      velocity: [1.1021784845, 1.164641269, 1.2162269269],
      direction: [1.6544911385, 5.6335365023, 7.3373092232],
      "speed factor": 1.1640685879,
      "Uz factor": 0.9974543385,
      "Directional variance factor": 0.4992411998,
      "Velocity stability factor": 0.8837283312,
      "Direction stability factor": 0.9842143942,
      score: 0.8378495798,
    },
    "315.0": {
      velocity: [0.8528591423, 0.8613774631, 0.8719555198],
      direction: [6.0493512455, 6.9121940151, 7.553333277],
      "speed factor": 1.0704725626,
      "Uz factor": 0.9989394987,
      "Directional variance factor": 0.3855827542,
      "Velocity stability factor": 0.9692630518,
      "Direction stability factor": 0.9958222721,
      score: 0.8366225876,
    },
    "337.5": {
      velocity: [0.6112940123, 0.8354283934, 1.0188081163],
      direction: [-14.8551594367, -0.5319335802, 9.3819876822],
      "speed factor": 0.8352348441,
      "Uz factor": 0.9990205981,
      "Directional variance factor": 0.9527170151,
      "Velocity stability factor": 0.5938574375,
      "Direction stability factor": 0.9326745913,
      score: 0.0,
    },
    id: "IDN:3547.0",
    zone: 1,
  },
  {
    position: [52.54134, 10.2679947, 21.1598299],
    "overall score": 0.5353383616,
    "0": {
      velocity: [0.5118574207, 0.6824826523, 0.8121374617],
      direction: [-22.0456668434, -10.9551905588, -0.514646461],
      "speed factor": 1.1304490444,
      "Uz factor": 0.9977025398,
      "Directional variance factor": 0.0262052837,
      "Velocity stability factor": 0.6664477169,
      "Direction stability factor": 0.94019161,
      score: 0.6432590552,
    },
    "22.5": {
      velocity: [0.5174765621, 0.7793699297, 1.0312119657],
      direction: [-8.6054360066, 6.0785854638, 24.9894967284],
      "speed factor": 1.0311872759,
      "Uz factor": 0.9997285135,
      "Directional variance factor": 0.4596812921,
      "Velocity stability factor": 0.5659355866,
      "Direction stability factor": 0.9066807424,
      score: 0.7097445909,
    },
    "45.0": {
      velocity: [0.4144424171, 0.7161069507, 0.8901138991],
      direction: [-11.9019988718, 5.4170903386, 16.093420297],
      "speed factor": 1.1542140173,
      "Uz factor": 0.9959524953,
      "Directional variance factor": 0.5184808588,
      "Velocity stability factor": 0.4872855484,
      "Direction stability factor": 0.9222349468,
      score: 0.7125590752,
    },
    "67.5": {
      velocity: [0.7477294697, 0.8813044068, 1.0392917103],
      direction: [-1.5783796147, 3.1694550726, 10.1784833143],
      "speed factor": 1.1977115258,
      "Uz factor": 0.9932101695,
      "Directional variance factor": 0.7182706602,
      "Velocity stability factor": 0.7037487454,
      "Direction stability factor": 0.9673420474,
      score: 0.8391758751,
    },
    "90.0": {
      velocity: [0.9715019345, 1.0057303555, 1.0269900483],
      direction: [4.1947689181, 4.901377509, 5.8091722149],
      "speed factor": 1.452863041,
      "Uz factor": 0.9960768544,
      "Directional variance factor": 0.5643219992,
      "Velocity stability factor": 0.9435998789,
      "Direction stability factor": 0.9955155464,
      score: 0.8747382427,
    },
    "112.5": {
      velocity: [0.8775218982, 1.0367886867, 1.1212153443],
      direction: [-5.5211168153, 4.2145247929, 14.7311905473],
      "speed factor": 0.9908718247,
      "Uz factor": 0.9987561388,
      "Directional variance factor": 0.625375574,
      "Velocity stability factor": 0.8026492739,
      "Direction stability factor": 0.9437435907,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.0270298557, 1.103602005, 1.1871738557],
      direction: [-1.3035131098, 1.0491789147, 8.4672637418],
      "speed factor": 1.2765428858,
      "Uz factor": 0.9995254276,
      "Directional variance factor": 0.906739652,
      "Velocity stability factor": 0.8368717041,
      "Direction stability factor": 0.9728589532,
      score: 0.9223323156,
    },
    "157.5": {
      velocity: [1.1310176549, 1.1413617088, 1.1515780314],
      direction: [-2.7950637906, -2.1924661633, -1.5397836384],
      "speed factor": 1.0947152042,
      "Uz factor": 0.9999778282,
      "Directional variance factor": 0.8051141188,
      "Velocity stability factor": 0.9846608461,
      "Direction stability factor": 0.9965131107,
      score: 0.9457002966,
    },
    "180.0": {
      velocity: [0.3750314447, 0.8181192329, 0.9867323037],
      direction: [-38.7922105867, -8.3518395349, 1.772414854],
      "speed factor": 1.1450652085,
      "Uz factor": 0.9993609777,
      "Directional variance factor": 0.2576142636,
      "Velocity stability factor": 0.4019390725,
      "Direction stability factor": 0.8873204849,
      score: 0.6085485765,
    },
    "202.5": {
      velocity: [0.1684242092, 0.5089750505, 0.8795009103],
      direction: [-13.3668902013, 18.7485669995, 72.6626623225],
      "speed factor": 0.6053187273,
      "Uz factor": 0.9960070351,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4319337312,
      "Direction stability factor": 0.7610290208,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.0793261814, 1.1263445499, 1.1594425779],
      direction: [-1.7391884658, 1.0347018139, 4.4788054063],
      "speed factor": 1.3639457321,
      "Uz factor": 0.9958701588,
      "Directional variance factor": 0.9080265054,
      "Velocity stability factor": 0.9247445128,
      "Direction stability factor": 0.9827277948,
      score: 0.949556652,
    },
    "247.5": {
      velocity: [0.5092296042, 0.9487298526, 1.2911074473],
      direction: [-18.7245115305, -7.6846810815, 18.6556255618],
      "speed factor": 1.1886291,
      "Uz factor": 0.9998527901,
      "Directional variance factor": 0.3169172372,
      "Velocity stability factor": 0.3799985101,
      "Direction stability factor": 0.8961662859,
      score: 0.6223120798,
    },
    "270.0": {
      velocity: [0.7618315853, 0.9666235246, 1.0388403477],
      direction: [-0.450156201, 8.3016917267, 13.216639349],
      "speed factor": 1.3556469093,
      "Uz factor": 0.9999490409,
      "Directional variance factor": 0.2620718465,
      "Velocity stability factor": 0.7638028981,
      "Direction stability factor": 0.962036679,
      score: 0.7374870257,
    },
    "292.5": {
      velocity: [0.3700278599, 0.4177180045, 0.4930154631],
      direction: [3.7702007051, 10.8634457889, 17.1758462639],
      "speed factor": 0.4175126029,
      "Uz factor": 0.9919413496,
      "Directional variance factor": 0.0343603743,
      "Velocity stability factor": 0.874614913,
      "Direction stability factor": 0.9627620957,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4198806667, 0.4369280188, 0.4541962824],
      direction: [2.3777647773, 5.014647707, 8.2030619386],
      "speed factor": 0.5429901245,
      "Uz factor": 0.9987876807,
      "Directional variance factor": 0.5542535372,
      "Velocity stability factor": 0.9447666288,
      "Direction stability factor": 0.983818619,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3588147229, 0.5733841284, 0.7430369774],
      direction: [-25.0096817064, -9.5321103024, 4.6382616152],
      "speed factor": 0.5732512887,
      "Uz factor": 0.9999998877,
      "Directional variance factor": 0.1527013065,
      "Velocity stability factor": 0.6170708953,
      "Direction stability factor": 0.9176446019,
      score: 0.0,
    },
    id: "IDN:2007.0",
    zone: 1,
  },
  {
    position: [50.54134, 6.2679947, 21.1598299],
    "overall score": 0.5341581534,
    "0": {
      velocity: [0.502391048, 0.6851325497, 0.8102658852],
      direction: [-20.5213752487, -10.7247864746, 1.6562823851],
      "speed factor": 1.1348382753,
      "Uz factor": 0.9969425668,
      "Directional variance factor": 0.0466856467,
      "Velocity stability factor": 0.6580113866,
      "Direction stability factor": 0.9383953955,
      score: 0.6453719561,
    },
    "22.5": {
      velocity: [0.536124109, 0.8160321066, 1.0747670131],
      direction: [-9.8006914322, 5.1112709454, 25.8505028857],
      "speed factor": 1.0796951396,
      "Uz factor": 0.9993052292,
      "Directional variance factor": 0.5456648049,
      "Velocity stability factor": 0.5448907852,
      "Direction stability factor": 0.9009689047,
      score: 0.7231233498,
    },
    "45.0": {
      velocity: [0.4141492121, 0.7578414909, 0.9110018778],
      direction: [-7.1404214889, 5.5641613064, 15.8621794855],
      "speed factor": 1.2214813314,
      "Uz factor": 0.9998583047,
      "Directional variance factor": 0.5054078839,
      "Velocity stability factor": 0.4644548776,
      "Direction stability factor": 0.9361038862,
      score: 0.7105176335,
    },
    "67.5": {
      velocity: [0.7946428712, 0.930815738, 1.0766849496],
      direction: [-2.1535873372, 1.1907604125, 6.1060671129],
      "speed factor": 1.2649984832,
      "Uz factor": 0.9988146872,
      "Directional variance factor": 0.89415463,
      "Velocity stability factor": 0.7134220145,
      "Direction stability factor": 0.9770565154,
      score: 0.8904224188,
    },
    "90.0": {
      velocity: [0.948898072, 1.0024429702, 1.0423777626],
      direction: [4.1543077474, 5.8496502225, 8.169536345],
      "speed factor": 1.4481141333,
      "Uz factor": 0.9972601386,
      "Directional variance factor": 0.4800310913,
      "Velocity stability factor": 0.9049838693,
      "Direction stability factor": 0.9888465872,
      score: 0.8406770338,
    },
    "112.5": {
      velocity: [0.4608054714, 0.9525668427, 1.1224248197],
      direction: [-35.165586696, 4.717819563, 26.3553261597],
      "speed factor": 0.9103799623,
      "Uz factor": 0.9986394901,
      "Directional variance factor": 0.5806382611,
      "Velocity stability factor": 0.4641995471,
      "Direction stability factor": 0.8291085754,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.7847503316, 0.9334300596, 1.0221188023],
      direction: [0.5335513444, 6.7621211099, 26.2228084526],
      "speed factor": 1.0797040025,
      "Uz factor": 0.9871688883,
      "Directional variance factor": 0.398922568,
      "Velocity stability factor": 0.7582081494,
      "Direction stability factor": 0.9286409525,
      score: 0.7536031556,
    },
    "157.5": {
      velocity: [1.1468859283, 1.1588037141, 1.1708091825],
      direction: [1.1774228586, 1.8192176902, 2.4418876366],
      "speed factor": 1.1114443693,
      "Uz factor": 0.9945886575,
      "Directional variance factor": 0.8382917609,
      "Velocity stability factor": 0.9821519572,
      "Direction stability factor": 0.9964875978,
      score: 0.9533547284,
    },
    "180.0": {
      velocity: [0.3633811814, 0.7658979677, 0.9554621197],
      direction: [-43.6460044657, -8.8253033074, 7.7624393983],
      "speed factor": 1.0719746962,
      "Uz factor": 0.9977384017,
      "Directional variance factor": 0.2155285949,
      "Velocity stability factor": 0.421121501,
      "Direction stability factor": 0.857198767,
      score: 0.5877619075,
    },
    "202.5": {
      velocity: [0.1101291165, 0.4824813719, 0.8228408767],
      direction: [-28.8392287855, 18.0206644043, 98.9724712066],
      "speed factor": 0.5738100712,
      "Uz factor": 0.9971600594,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4306275122,
      "Direction stability factor": 0.6449675,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1139996274, 1.1385676299, 1.1593915553],
      direction: [1.966284239, 2.9395602213, 4.4074191779],
      "speed factor": 1.3787472578,
      "Uz factor": 0.9901350464,
      "Directional variance factor": 0.7387057581,
      "Velocity stability factor": 0.9573621406,
      "Direction stability factor": 0.9932190696,
      score: 0.9206265095,
    },
    "247.5": {
      velocity: [0.8168193524, 0.9979773521, 1.1664859538],
      direction: [-18.6897945637, -5.6226586605, 3.993905341],
      "speed factor": 1.2503294997,
      "Uz factor": 0.9973539238,
      "Directional variance factor": 0.5002081191,
      "Velocity stability factor": 0.722726746,
      "Direction stability factor": 0.9369897225,
      score: 0.7742285775,
    },
    "270.0": {
      velocity: [0.7858363234, 0.9471174444, 1.0454886577],
      direction: [-1.4777023939, 7.9263539228, 14.1219390641],
      "speed factor": 1.3282904911,
      "Uz factor": 0.9999435132,
      "Directional variance factor": 0.2954352069,
      "Velocity stability factor": 0.7786022062,
      "Direction stability factor": 0.9566676626,
      score: 0.7468431846,
    },
    "292.5": {
      velocity: [0.5083442332, 0.5581983619, 0.6058732028],
      direction: [5.3945386971, 11.6246534502, 15.7961093467],
      "speed factor": 0.5579238829,
      "Uz factor": 0.9980969929,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9005698296,
      "Direction stability factor": 0.9711067482,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.3997123616, 0.4292827973, 0.4513189574],
      direction: [11.2064785465, 13.990062368, 15.7640298868],
      "speed factor": 0.5334890635,
      "Uz factor": 0.9955454139,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9169355933,
      "Direction stability factor": 0.9873401352,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3365560518, 0.5872466535, 0.7549346086],
      direction: [-68.0716153955, -10.4496278404, 2.8855514994],
      "speed factor": 0.5871106021,
      "Uz factor": 0.9976895535,
      "Directional variance factor": 0.071144192,
      "Velocity stability factor": 0.5830295504,
      "Direction stability factor": 0.8028967586,
      score: 0.0,
    },
    id: "IDN:1932.0",
    zone: 1,
  },
  {
    position: [-21.8936219, 5.3169522, 20.8740369],
    "overall score": 0.5326546715,
    "0": {
      velocity: [0.3600860505, 0.7378546579, 0.92997515],
      direction: [-17.1512422198, 4.8120429284, 14.0253136283],
      "speed factor": 1.2221659995,
      "Uz factor": 0.9998686862,
      "Directional variance factor": 0.5722628508,
      "Velocity stability factor": 0.3669648851,
      "Direction stability factor": 0.913398456,
      score: 0.691506162,
    },
    "22.5": {
      velocity: [0.2803177831, 0.771217068, 1.1565464433],
      direction: [-30.689633112, 9.780314452, 53.7377794952],
      "speed factor": 1.020400194,
      "Uz factor": 0.9977804477,
      "Directional variance factor": 0.1306387154,
      "Velocity stability factor": 0.2596584219,
      "Direction stability factor": 0.7654794094,
      score: 0.480313989,
    },
    "45.0": {
      velocity: [0.3223368952, 0.6239663677, 1.0417526282],
      direction: [-25.9004079923, -10.5440390706, 31.9110903262],
      "speed factor": 1.0057027475,
      "Uz factor": 0.9999992248,
      "Directional variance factor": 0.0627520826,
      "Velocity stability factor": 0.2245596868,
      "Direction stability factor": 0.8394125047,
      score: 0.4915341947,
    },
    "67.5": {
      velocity: [0.6626371912, 0.9037342412, 0.9784765025],
      direction: [-5.9475097262, -3.1971691241, 4.7973312085],
      "speed factor": 1.2281941501,
      "Uz factor": 0.999375044,
      "Directional variance factor": 0.715807189,
      "Velocity stability factor": 0.6790812417,
      "Direction stability factor": 0.9701532196,
      score: 0.8337987175,
    },
    "90.0": {
      velocity: [0.3531667018, 0.7245177502, 0.9992655511],
      direction: [-23.790579916, 1.0058746617, 38.6506247556],
      "speed factor": 1.0466275141,
      "Uz factor": 0.9998231292,
      "Directional variance factor": 0.910588919,
      "Velocity stability factor": 0.3432818152,
      "Direction stability factor": 0.8265522092,
      score: 0.7267437882,
    },
    "112.5": {
      velocity: [1.0821807548, 1.1643283209, 1.2456436036],
      direction: [5.7187798219, 9.8694227071, 13.8123451391],
      "speed factor": 1.1127630371,
      "Uz factor": 0.9941571595,
      "Directional variance factor": 0.1227179816,
      "Velocity stability factor": 0.8676225708,
      "Direction stability factor": 0.9775178741,
      score: 0.7363440752,
    },
    "135.0": {
      velocity: [1.1502262602, 1.1601622641, 1.1704950876],
      direction: [2.9288920445, 3.4264648736, 3.9056305564],
      "speed factor": 1.3419664679,
      "Uz factor": 0.9864339663,
      "Directional variance factor": 0.6954253446,
      "Velocity stability factor": 0.9793534615,
      "Direction stability factor": 0.9972868375,
      score: 0.9173381202,
    },
    "157.5": {
      velocity: [0.2144467211, 0.6462626788, 1.0126688039],
      direction: [-87.7080567333, -18.2332186553, 16.0517919426],
      "speed factor": 0.6198504601,
      "Uz factor": 0.993853829,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4044831122,
      "Direction stability factor": 0.7117781981,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0734660973, 0.3775002976, 0.7519648958],
      direction: [-150.3366462985, -0.8708563642, 79.4939462182],
      "speed factor": 0.5283611968,
      "Uz factor": 0.9997791316,
      "Directional variance factor": 0.9225905454,
      "Velocity stability factor": 0.3366306181,
      "Direction stability factor": 0.3615816875,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1583304136, 1.1771781357, 1.2013026147],
      direction: [-4.6310588685, -4.1251604464, -3.5178099803],
      "speed factor": 1.4000056979,
      "Uz factor": 0.9649587825,
      "Directional variance factor": 0.6333190714,
      "Velocity stability factor": 0.9656702886,
      "Direction stability factor": 0.996907642,
      score: 0.898201161,
    },
    "225.0": {
      velocity: [0.3204222325, 0.6933242694, 0.9458880121],
      direction: [-60.5467686933, -23.0799269229, 2.3480665644],
      "speed factor": 0.8395802849,
      "Uz factor": 0.9663232856,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4124831614,
      "Direction stability factor": 0.8252921243,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1554411074, 0.5099263647, 0.8632164105],
      direction: [-60.0025368249, 6.2137844236, 92.9672029449],
      "speed factor": 0.6388681819,
      "Uz factor": 0.999208322,
      "Directional variance factor": 0.4476636068,
      "Velocity stability factor": 0.4387592048,
      "Direction stability factor": 0.5750840562,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.7116803781, 0.9080507949, 1.0267130622],
      direction: [-9.2142192649, 0.2764901041, 6.5478364077],
      "speed factor": 1.2735012362,
      "Uz factor": 0.9999583647,
      "Directional variance factor": 0.9754231019,
      "Velocity stability factor": 0.7313810352,
      "Direction stability factor": 0.956216512,
      score: 0.9048092903,
    },
    "292.5": {
      velocity: [1.1067016009, 1.1596259723, 1.1982627348],
      direction: [-0.790278633, 2.3315840926, 4.6612225863],
      "speed factor": 1.1590557574,
      "Uz factor": 0.998924833,
      "Directional variance factor": 0.7927480807,
      "Velocity stability factor": 0.9066540006,
      "Direction stability factor": 0.9848569411,
      score: 0.9172789908,
    },
    "315.0": {
      velocity: [0.8616705944, 0.8734253189, 0.8831037145],
      direction: [2.5145640915, 2.9577079489, 3.2650758899],
      "speed factor": 1.0854449755,
      "Uz factor": 0.9995290759,
      "Directional variance factor": 0.7370926268,
      "Velocity stability factor": 0.9655019019,
      "Direction stability factor": 0.997915245,
      score: 0.9246062547,
    },
    "337.5": {
      velocity: [0.564280552, 0.9844406852, 1.1532468409],
      direction: [-8.1622115033, -1.3337713111, 5.8590060972],
      "speed factor": 0.9842126131,
      "Uz factor": 0.9984066676,
      "Directional variance factor": 0.8814425501,
      "Velocity stability factor": 0.4130159534,
      "Direction stability factor": 0.9610521733,
      score: 0.0,
    },
    id: "IDN:2139.0",
    zone: 1,
  },
  {
    position: [-13.17146233, 2.578152895, 21.1598299],
    "overall score": 0.5310259983,
    "0": {
      velocity: [0.1225878464, 0.7572106419, 0.9298570713],
      direction: [-80.7803139162, -1.7461076781, 11.4190887317],
      "speed factor": 1.2542268197,
      "Uz factor": 0.9995570997,
      "Directional variance factor": 0.8447904286,
      "Velocity stability factor": 0.1032820824,
      "Direction stability factor": 0.7438905482,
      score: 0.6089634018,
    },
    "22.5": {
      velocity: [0.2647618688, 0.8195692747, 1.2034653775],
      direction: [-29.7485587856, 3.8446926291, 49.9484241966],
      "speed factor": 1.08437518,
      "Uz factor": 0.9977331653,
      "Directional variance factor": 0.6582495441,
      "Velocity stability factor": 0.2068722829,
      "Direction stability factor": 0.7786194917,
      score: 0.6055902026,
    },
    "45.0": {
      velocity: [0.2321874717, 0.6570130881, 1.0279435864],
      direction: [-29.1531812332, -10.9147342718, 53.7530785545],
      "speed factor": 1.0589671207,
      "Uz factor": 0.9987000659,
      "Directional variance factor": 0.0298013981,
      "Velocity stability factor": 0.1422742894,
      "Direction stability factor": 0.7697048339,
      score: 0.4278713388,
    },
    "67.5": {
      velocity: [0.7989843243, 0.9160549302, 0.9737986403],
      direction: [-4.9204929705, -2.8387593032, -0.5797161066],
      "speed factor": 1.2449382299,
      "Uz factor": 0.999492045,
      "Directional variance factor": 0.7476658397,
      "Velocity stability factor": 0.8223742543,
      "Direction stability factor": 0.9879422865,
      score: 0.8864811667,
    },
    "90.0": {
      velocity: [0.2964358781, 0.6364811152, 0.9320809414],
      direction: [-43.2284351692, 1.9655814114, 37.4799344933],
      "speed factor": 0.9194511069,
      "Uz factor": 0.9990446086,
      "Directional variance factor": 0.8252816523,
      "Velocity stability factor": 0.3539074204,
      "Direction stability factor": 0.7758100843,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1181068013, 1.1819680001, 1.2300522669],
      direction: [4.4619976067, 7.3508908858, 9.2079765558],
      "speed factor": 1.1296214976,
      "Uz factor": 0.9899314557,
      "Directional variance factor": 0.3465874768,
      "Velocity stability factor": 0.9093429911,
      "Direction stability factor": 0.9868167251,
      score: 0.8073909795,
    },
    "135.0": {
      velocity: [1.1820760553, 1.188120917, 1.1928803025],
      direction: [-1.0047133917, -0.7312750269, -0.4383571058],
      "speed factor": 1.3743064051,
      "Uz factor": 0.9846800136,
      "Directional variance factor": 0.9349977754,
      "Velocity stability factor": 0.9889944148,
      "Direction stability factor": 0.9984267881,
      score: 0.9802114416,
    },
    "157.5": {
      velocity: [0.2993606723, 0.7460535771, 1.0119221449],
      direction: [-60.722701712, -14.4968727963, 35.7515696873],
      "speed factor": 0.7155629874,
      "Uz factor": 0.997481871,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4683905649,
      "Direction stability factor": 0.7320159128,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0919533735, 0.3639525117, 0.6993152041],
      direction: [-111.5735868626, 6.3067037482, 141.0887717606],
      "speed factor": 0.5093992929,
      "Uz factor": 0.9999998116,
      "Directional variance factor": 0.4394041113,
      "Velocity stability factor": 0.4061813477,
      "Direction stability factor": 0.2981601149,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.069293626, 1.0987539529, 1.119836366],
      direction: [-0.5162738615, 0.245484236, 1.1375028291],
      "speed factor": 1.306736634,
      "Uz factor": 0.9744222781,
      "Directional variance factor": 0.978179179,
      "Velocity stability factor": 0.9596223225,
      "Direction stability factor": 0.9954061759,
      score: 0.9821534633,
    },
    "225.0": {
      velocity: [1.025606614, 1.0688632172, 1.1335613814],
      direction: [-11.9932163899, -7.7033376301, -5.0905282286],
      "speed factor": 1.2943387735,
      "Uz factor": 0.9943569563,
      "Directional variance factor": 0.3152588773,
      "Velocity stability factor": 0.8985951819,
      "Direction stability factor": 0.9808258662,
      score: 0.7938764479,
    },
    "247.5": {
      velocity: [0.059549093, 0.3886669525, 0.9061678726],
      direction: [-173.1043581156, -3.5301643287, 148.3267527757],
      "speed factor": 0.4869466779,
      "Uz factor": 0.9963167735,
      "Directional variance factor": 0.6862076152,
      "Velocity stability factor": 0.3286612361,
      "Direction stability factor": 0.1071358031,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.4582810546, 0.8883104871, 1.073435234],
      direction: [-9.1225364496, 2.7580819653, 11.5006236109],
      "speed factor": 1.2458163242,
      "Uz factor": 0.9984407282,
      "Directional variance factor": 0.7548371586,
      "Velocity stability factor": 0.4754763961,
      "Direction stability factor": 0.9427134443,
      score: 0.7789351108,
    },
    "292.5": {
      velocity: [1.1054886512, 1.1605927991, 1.2009731411],
      direction: [3.5136383535, 6.1123086169, 7.5876904182],
      "speed factor": 1.1600221088,
      "Uz factor": 0.9980547809,
      "Directional variance factor": 0.4566836785,
      "Velocity stability factor": 0.9026541638,
      "Direction stability factor": 0.9886831887,
      score: 0.8341760549,
    },
    "315.0": {
      velocity: [0.8405603185, 0.8501104217, 0.8628870954],
      direction: [8.1727654486, 8.9308891868, 9.4581854536],
      "speed factor": 1.0564705029,
      "Uz factor": 0.9996595711,
      "Directional variance factor": 0.2061431834,
      "Velocity stability factor": 0.964063499,
      "Direction stability factor": 0.9964293889,
      score: 0.790766365,
    },
    "337.5": {
      velocity: [0.5834333194, 0.7928188837, 0.9626507097],
      direction: [-19.2831436261, -0.297295536, 9.3729057378],
      "speed factor": 0.792635206,
      "Uz factor": 0.9991152152,
      "Directional variance factor": 0.9735737301,
      "Velocity stability factor": 0.6220589149,
      "Direction stability factor": 0.9203998629,
      score: 0.0,
    },
    id: "IDN:3548.0",
    zone: 1,
  },
  {
    position: [-29.8936219, 11.3169522, 20.8740369],
    "overall score": 0.5306723071,
    "0": {
      velocity: [0.7180872789, 0.8138522038, 0.9162706716],
      direction: [-1.4130422836, 4.8351368693, 10.4862179995],
      "speed factor": 1.3480466396,
      "Uz factor": 0.997694122,
      "Directional variance factor": 0.5702100561,
      "Velocity stability factor": 0.7798570864,
      "Direction stability factor": 0.9669464992,
      score: 0.8209900352,
    },
    "22.5": {
      velocity: [0.2389107289, 0.7012409931, 1.1390829967],
      direction: [-29.9892388921, 14.9574027721, 40.4301146927],
      "speed factor": 0.927814587,
      "Uz factor": 0.9997854135,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2394280312,
      "Direction stability factor": 0.8043906845,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6107717136, 0.8553712498, 1.0854507975],
      direction: [-17.4320288098, -7.1699189739, 7.2609573639],
      "speed factor": 1.3786788209,
      "Uz factor": 0.9960655556,
      "Directional variance factor": 0.362673869,
      "Velocity stability factor": 0.4883552296,
      "Direction stability factor": 0.9314083717,
      score: 0.6784614605,
    },
    "67.5": {
      velocity: [0.5354452197, 0.8581571623, 0.9455446208],
      direction: [-11.9263790191, -5.4101500774, 4.7983312512],
      "speed factor": 1.1662539258,
      "Uz factor": 0.9997693267,
      "Directional variance factor": 0.5190977709,
      "Velocity stability factor": 0.5833052256,
      "Direction stability factor": 0.9535424715,
      score: 0.7523719849,
    },
    "90.0": {
      velocity: [0.5264291932, 0.8269064252, 1.0378344764],
      direction: [-10.4773003223, 3.1003414232, 27.5092963653],
      "speed factor": 1.1945366637,
      "Uz factor": 0.9991384547,
      "Directional variance factor": 0.7244140957,
      "Velocity stability factor": 0.4801892161,
      "Direction stability factor": 0.8944816759,
      score: 0.7483916659,
    },
    "112.5": {
      velocity: [0.453720232, 0.9436424615, 1.1662846417],
      direction: [-10.9879808965, 10.9985471313, 20.3734692318],
      "speed factor": 0.9018508204,
      "Uz factor": 0.9999407689,
      "Directional variance factor": 0.0223513661,
      "Velocity stability factor": 0.4229426114,
      "Direction stability factor": 0.9128848608,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.4108769974, 0.5841357728, 0.8203393969],
      direction: [-0.4961889497, 5.7605190467, 9.6920146879],
      "speed factor": 0.6756732606,
      "Uz factor": 0.9981908475,
      "Directional variance factor": 0.4879538625,
      "Velocity stability factor": 0.5829072369,
      "Direction stability factor": 0.9716994343,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2008127787, 0.6387362956, 1.0011048464],
      direction: [-87.9921197824, -16.5154342265, 18.0715514052],
      "speed factor": 0.6126316739,
      "Uz factor": 0.9984230433,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4029387915,
      "Direction stability factor": 0.7053786911,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.146031742, 0.5790024859, 0.849655429],
      direction: [-56.4808997265, -0.7865637712, 166.0359991986],
      "speed factor": 0.8103899477,
      "Uz factor": 0.9959599537,
      "Directional variance factor": 0.9300832203,
      "Velocity stability factor": 0.3120659736,
      "Direction stability factor": 0.381897503,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1390092038, 1.1551469843, 1.1624953269],
      direction: [-4.5162596455, -3.1427061797, -1.8392156972],
      "speed factor": 1.3738042789,
      "Uz factor": 0.9985652054,
      "Directional variance factor": 0.7206483396,
      "Velocity stability factor": 0.9812373626,
      "Direction stability factor": 0.9925637668,
      score: 0.9217533089,
    },
    "225.0": {
      velocity: [0.3354547445, 0.6498762871, 0.8860163612],
      direction: [-28.4377425187, -1.7461283272, 20.447307729],
      "speed factor": 0.7869669971,
      "Uz factor": 0.9999755949,
      "Directional variance factor": 0.8447885931,
      "Velocity stability factor": 0.4828426574,
      "Direction stability factor": 0.8642081938,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.649415805, 0.8750254169, 0.9795348261],
      direction: [-2.9977727568, 2.3939635912, 6.8683706309],
      "speed factor": 1.0962874953,
      "Uz factor": 0.9928151901,
      "Directional variance factor": 0.7872032363,
      "Velocity stability factor": 0.7382272861,
      "Direction stability factor": 0.9725940461,
      score: 0.8676546537,
    },
    "270.0": {
      velocity: [0.9493022274, 1.0218019408, 1.0888089549],
      direction: [-3.8220882095, -1.9086680711, 0.9572150437],
      "speed factor": 1.4330322071,
      "Uz factor": 0.9994389964,
      "Directional variance factor": 0.8303406159,
      "Velocity stability factor": 0.8810467783,
      "Direction stability factor": 0.9867241576,
      score: 0.9212089274,
    },
    "292.5": {
      velocity: [1.2474978598, 1.2544724092, 1.2606519528],
      direction: [-4.3334088426, -3.9677148102, -3.6027244759],
      "speed factor": 1.2538555561,
      "Uz factor": 0.9998130776,
      "Directional variance factor": 0.6473142391,
      "Velocity stability factor": 0.986589485,
      "Direction stability factor": 0.9979703212,
      score: 0.9074610916,
    },
    "315.0": {
      velocity: [0.9080702104, 0.9149091576, 0.9234615118],
      direction: [2.9079965661, 3.1887947402, 3.3875075105],
      "speed factor": 1.1369988099,
      "Uz factor": 0.9999973939,
      "Directional variance factor": 0.7165515786,
      "Velocity stability factor": 0.9752266294,
      "Direction stability factor": 0.9986680252,
      score: 0.9222785646,
    },
    "337.5": {
      velocity: [1.0725314154, 1.185781721, 1.2470551536],
      direction: [-1.8111712048, 0.0635795796, 1.7296526286],
      "speed factor": 1.185507003,
      "Uz factor": 0.9999542992,
      "Directional variance factor": 0.9943484818,
      "Velocity stability factor": 0.826063644,
      "Direction stability factor": 0.9901643782,
      score: 0.9501852206,
    },
    id: "IDN:2153.0",
    zone: 1,
  },
  {
    position: [-7.383827554, 2.810771541, 21.1598299],
    "overall score": 0.5213678271,
    "0": {
      velocity: [0.1125279195, 0.7676855909, 0.9645356269],
      direction: [-26.8031359499, -5.1046229375, 43.6511596543],
      "speed factor": 1.2715772916,
      "Uz factor": 0.9995045737,
      "Directional variance factor": 0.5462557389,
      "Velocity stability factor": 0.0535863953,
      "Direction stability factor": 0.8042936233,
      score: 0.5521073452,
    },
    "22.5": {
      velocity: [0.2056648813, 0.7727212162, 1.2183070415],
      direction: [-32.8910619545, 2.1223472329, 49.2057499521],
      "speed factor": 1.0223903381,
      "Uz factor": 0.9980994089,
      "Directional variance factor": 0.8113469126,
      "Velocity stability factor": 0.1444001676,
      "Direction stability factor": 0.7719533003,
      score: 0.6249134202,
    },
    "45.0": {
      velocity: [0.1763016345, 0.6243617787, 0.9753432335],
      direction: [-30.0234508146, -11.1811649727, 36.1559397575],
      "speed factor": 1.0063400671,
      "Uz factor": 0.9984317551,
      "Directional variance factor": 0.0061186691,
      "Velocity stability factor": 0.1387329475,
      "Direction stability factor": 0.8161683595,
      score: 0.4442970839,
    },
    "67.5": {
      velocity: [0.8022091649, 0.9071750493, 0.9651964402],
      direction: [-5.4319393272, -2.8260409403, 0.4242261096],
      "speed factor": 1.232870282,
      "Uz factor": 0.9995838292,
      "Directional variance factor": 0.7487963609,
      "Velocity stability factor": 0.8343915018,
      "Direction stability factor": 0.9837328738,
      score: 0.8876634025,
    },
    "90.0": {
      velocity: [0.1950057051, 0.6336645104, 0.985988422],
      direction: [-31.8901258498, 2.6862234877, 45.8874502894],
      "speed factor": 0.9153822817,
      "Uz factor": 0.999401734,
      "Directional variance factor": 0.7612245789,
      "Velocity stability factor": 0.1960166239,
      "Direction stability factor": 0.7839511774,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.0431576156, 1.1639953649, 1.2611366319],
      direction: [4.6165184739, 8.7501589931, 11.0982383804],
      "speed factor": 1.1124448269,
      "Uz factor": 0.9898247599,
      "Directional variance factor": 0.2222080895,
      "Velocity stability factor": 0.8234736394,
      "Direction stability factor": 0.9819952225,
      score: 0.7524180435,
    },
    "135.0": {
      velocity: [1.1880556379, 1.1943936207, 1.2002971594],
      direction: [-0.6415124917, -0.2611966983, 0.1406985967],
      "speed factor": 1.381562078,
      "Uz factor": 0.9823782031,
      "Directional variance factor": 0.9767825157,
      "Velocity stability factor": 0.9875303568,
      "Direction stability factor": 0.9978271914,
      score: 0.9899918138,
    },
    "157.5": {
      velocity: [0.4552940866, 0.8728271457, 1.0958168823],
      direction: [-31.5003035118, -12.7089853076, 1.2281922276],
      "speed factor": 0.8371554255,
      "Uz factor": 0.9998866501,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5221353179,
      "Direction stability factor": 0.9090875118,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0291269712, 0.3407970876, 0.7851485722],
      direction: [-164.4018934219, 4.3094817417, 170.2062886344],
      "speed factor": 0.4769902387,
      "Uz factor": 0.9952006475,
      "Directional variance factor": 0.6169349563,
      "Velocity stability factor": 0.2608364477,
      "Direction stability factor": 0.0705328276,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0391485106, 1.0736403439, 1.0975926575],
      direction: [1.3675443898, 2.0821746691, 2.8939969616],
      "speed factor": 1.2768692804,
      "Uz factor": 0.9745465671,
      "Directional variance factor": 0.8149178072,
      "Velocity stability factor": 0.9533100319,
      "Direction stability factor": 0.995759854,
      score: 0.9399368868,
    },
    "225.0": {
      velocity: [1.1028144209, 1.1214786588, 1.1459356248],
      direction: [-9.0782696699, -7.6652921868, -6.3748012662],
      "speed factor": 1.3580533864,
      "Uz factor": 0.9862373429,
      "Directional variance factor": 0.3186406945,
      "Velocity stability factor": 0.9594950928,
      "Direction stability factor": 0.9924903655,
      score: 0.8157791296,
    },
    "247.5": {
      velocity: [0.0894818543, 0.3612914675, 0.8129743153],
      direction: [-139.7866431711, -18.1204370336, 165.9825556954],
      "speed factor": 0.4526489292,
      "Uz factor": 0.9753090432,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4262960542,
      "Direction stability factor": 0.1506411143,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.5362375348, 0.9311354875, 1.0463243815],
      direction: [-3.2764671223, 6.7181237451, 21.0144054122],
      "speed factor": 1.3058765007,
      "Uz factor": 0.9969729261,
      "Directional variance factor": 0.4028334449,
      "Velocity stability factor": 0.5650641739,
      "Direction stability factor": 0.9325253541,
      score: 0.7082370817,
    },
    "292.5": {
      velocity: [1.1262184825, 1.1572028623, 1.1829593736],
      direction: [4.8465092236, 6.9498149914, 8.0325193084],
      "speed factor": 1.1566338389,
      "Uz factor": 0.9989238197,
      "Directional variance factor": 0.3822386674,
      "Velocity stability factor": 0.9421530189,
      "Direction stability factor": 0.991149972,
      score: 0.8266729076,
    },
    "315.0": {
      velocity: [0.8485973934, 0.8619672775, 0.8766747063],
      direction: [7.5375971516, 8.3977889237, 9.1332984897],
      "speed factor": 1.0712055515,
      "Uz factor": 0.9996465553,
      "Directional variance factor": 0.2535298735,
      "Velocity stability factor": 0.9548076111,
      "Direction stability factor": 0.9955674963,
      score: 0.7998681193,
    },
    "337.5": {
      velocity: [0.4364736982, 0.6935289188, 0.7908941654],
      direction: [-4.4572479603, 2.7649843252, 9.7162236686],
      "speed factor": 0.6933682443,
      "Uz factor": 0.9996676087,
      "Directional variance factor": 0.7542236155,
      "Velocity stability factor": 0.6467723808,
      "Direction stability factor": 0.9606292455,
      score: 0.0,
    },
    id: "IDN:3551.0",
    zone: 1,
  },
  {
    position: [-27.8936219, 11.3169522, 20.8740369],
    "overall score": 0.5186282154,
    "0": {
      velocity: [0.7313091828, 0.8217802972, 0.9065353599],
      direction: [-4.1879078081, 5.1057825065, 11.961786765],
      "speed factor": 1.3611785567,
      "Uz factor": 0.9977462199,
      "Directional variance factor": 0.5461526661,
      "Velocity stability factor": 0.8053580542,
      "Direction stability factor": 0.9551397373,
      score: 0.8154475487,
    },
    "22.5": {
      velocity: [0.2384203347, 0.7260761394, 1.1786198347],
      direction: [-28.0493436352, 14.6600108057, 83.9973534445],
      "speed factor": 0.9606740621,
      "Uz factor": 0.9999881985,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2056082925,
      "Direction stability factor": 0.6887591748,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6037084662, 0.846411659, 1.0555034432],
      direction: [-18.7805476278, -7.8119979351, 3.1682146296],
      "speed factor": 1.3642378421,
      "Uz factor": 0.9968419602,
      "Directional variance factor": 0.3056001835,
      "Velocity stability factor": 0.5130214389,
      "Direction stability factor": 0.939031216,
      score: 0.6741710136,
    },
    "67.5": {
      velocity: [0.4858496414, 0.8510200111, 0.9419956231],
      direction: [-12.0186425531, -5.2196952761, 10.7234217517],
      "speed factor": 1.1565543847,
      "Uz factor": 0.9998917369,
      "Directional variance factor": 0.5360270866,
      "Velocity stability factor": 0.5365181065,
      "Direction stability factor": 0.9368275992,
      score: 0.7365500978,
    },
    "90.0": {
      velocity: [0.5161883918, 0.8356574955, 1.0041447296],
      direction: [-14.5774706414, 3.0635556953, 29.4879067769],
      "speed factor": 1.2071783292,
      "Uz factor": 0.9989934567,
      "Directional variance factor": 0.7276839382,
      "Velocity stability factor": 0.5040235704,
      "Direction stability factor": 0.8775961738,
      score: 0.7467249641,
    },
    "112.5": {
      velocity: [0.5372516089, 0.9621169004, 1.133094342],
      direction: [-7.301327094, 11.8107498242, 17.6459728197],
      "speed factor": 0.9195070711,
      "Uz factor": 0.999505549,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5174675483,
      "Direction stability factor": 0.9307019447,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.4565077737, 0.5673112503, 0.6870679169],
      direction: [2.3248350993, 5.6702748487, 9.0496021664],
      "speed factor": 0.6562122371,
      "Uz factor": 0.996848284,
      "Directional variance factor": 0.495975569,
      "Velocity stability factor": 0.7651433506,
      "Direction stability factor": 0.9813200915,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2784963718, 0.6565025406, 0.9363173139],
      direction: [-56.5519025838, -15.4609762529, 12.8968063357],
      "speed factor": 0.6296718273,
      "Uz factor": 0.9972488364,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5092299643,
      "Direction stability factor": 0.8070869197,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1776891037, 0.5304453937, 0.8396997858],
      direction: [-71.7734155514, -0.6788529644, 60.1119174519],
      "speed factor": 0.7424279261,
      "Uz factor": 0.995961939,
      "Directional variance factor": 0.9396575143,
      "Velocity stability factor": 0.3527510764,
      "Direction stability factor": 0.6336518528,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1569752187, 1.1707420936, 1.1869791322],
      direction: [-8.2828147578, -6.1452633207, -4.755893644],
      "speed factor": 1.39235138,
      "Uz factor": 0.9982827845,
      "Directional variance factor": 0.4537543715,
      "Velocity stability factor": 0.9760304182,
      "Direction stability factor": 0.9902029969,
      score: 0.8525476959,
    },
    "225.0": {
      velocity: [0.3911946461, 0.7779374773, 1.0117632417],
      direction: [-32.487486871, -4.2262245153, 23.5808090843],
      "speed factor": 0.9420425589,
      "Uz factor": 0.9958899798,
      "Directional variance factor": 0.6243355986,
      "Velocity stability factor": 0.4170832182,
      "Direction stability factor": 0.8442547335,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4501221743, 0.8021846956, 0.9477375247],
      direction: [-6.4386253335, 2.8979081042, 13.2691663045],
      "speed factor": 1.0050280069,
      "Uz factor": 0.9937744556,
      "Directional variance factor": 0.7424081685,
      "Velocity stability factor": 0.6054086181,
      "Direction stability factor": 0.9452561343,
      score: 0.8095822638,
    },
    "270.0": {
      velocity: [0.9241181962, 1.0084097145, 1.0933937977],
      direction: [-4.6743254391, -1.5454133295, 1.5456811589],
      "speed factor": 1.4142501996,
      "Uz factor": 0.9999771809,
      "Directional variance factor": 0.8626299263,
      "Velocity stability factor": 0.8556637481,
      "Direction stability factor": 0.9827222039,
      score: 0.9209345205,
    },
    "292.5": {
      velocity: [1.2126221276, 1.2203662515, 1.22559312],
      direction: [-1.8779500631, -1.4987869037, -0.9823417913],
      "speed factor": 1.2197661692,
      "Uz factor": 0.9981382779,
      "Directional variance factor": 0.8667744974,
      "Velocity stability factor": 0.986776155,
      "Direction stability factor": 0.9975121992,
      score: 0.9621437627,
    },
    "315.0": {
      velocity: [0.8716346465, 0.8825888789, 0.890521744],
      direction: [5.4705269228, 5.838910162, 6.1026995351],
      "speed factor": 1.0968329442,
      "Uz factor": 0.9998709239,
      "Directional variance factor": 0.4809857634,
      "Velocity stability factor": 0.9695999024,
      "Direction stability factor": 0.998243965,
      score: 0.8617683989,
    },
    "337.5": {
      velocity: [0.9898445351, 1.1599012774, 1.2416764315],
      direction: [-1.3586227492, 0.5756312001, 3.1636499784],
      "speed factor": 1.1596325552,
      "Uz factor": 0.9999996193,
      "Directional variance factor": 0.9488327822,
      "Velocity stability factor": 0.7490156765,
      "Direction stability factor": 0.9874381313,
      score: 0.9181811803,
    },
    id: "IDN:2154.0",
    zone: 1,
  },
  {
    position: [-17.45866, 4.2679947, 21.1598299],
    "overall score": 0.5182352133,
    "0": {
      velocity: [0.6212465803, 0.8484085406, 1.0091611892],
      direction: [-10.3107829931, 0.9657535127, 12.2351741858],
      "speed factor": 1.4052849851,
      "Uz factor": 0.999853325,
      "Directional variance factor": 0.9141552433,
      "Velocity stability factor": 0.5691028847,
      "Direction stability factor": 0.9373723412,
      score: 0.8395007026,
    },
    "22.5": {
      velocity: [0.2755616777, 0.8168846311, 1.2003094081],
      direction: [-30.0698590054, 6.3112503989, 45.1565700401],
      "speed factor": 1.0808231179,
      "Uz factor": 0.9971463351,
      "Directional variance factor": 0.4389999645,
      "Velocity stability factor": 0.2186637746,
      "Direction stability factor": 0.7910376971,
      score: 0.5599347833,
    },
    "45.0": {
      velocity: [0.3188277152, 0.703481134, 1.069716994],
      direction: [-29.1091124869, -13.1368287975, 41.0338081706],
      "speed factor": 1.1338638522,
      "Uz factor": 0.9989008594,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1906351351,
      "Direction stability factor": 0.8051585537,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7666935809, 0.915573268, 0.9798584988],
      direction: [-5.7331328394, -2.9483571034, 0.2929386469],
      "speed factor": 1.2442836407,
      "Uz factor": 0.9995007138,
      "Directional variance factor": 0.737923813,
      "Velocity stability factor": 0.7834068835,
      "Direction stability factor": 0.9832609125,
      score: 0.8719631304,
    },
    "90.0": {
      velocity: [0.2341842138, 0.6833454562, 0.9583274684],
      direction: [-22.0868895001, 2.575913431, 50.8166896282],
      "speed factor": 0.9871506336,
      "Uz factor": 0.9999993063,
      "Directional variance factor": 0.7710299172,
      "Velocity stability factor": 0.2639546654,
      "Direction stability factor": 0.797490058,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1287666553, 1.186889567, 1.2192442685],
      direction: [3.8946467163, 9.6767261378, 11.6961525588],
      "speed factor": 1.1343251002,
      "Uz factor": 0.9929297157,
      "Directional variance factor": 0.1398465655,
      "Velocity stability factor": 0.9267283427,
      "Direction stability factor": 0.9783291504,
      score: 0.7558083023,
    },
    "135.0": {
      velocity: [1.1898350201, 1.1994496456, 1.2058602178],
      direction: [0.8069726711, 1.2026286802, 1.600741839],
      "speed factor": 1.3874104116,
      "Uz factor": 0.9902696081,
      "Directional variance factor": 0.8930996729,
      "Velocity stability factor": 0.9836761715,
      "Direction stability factor": 0.9977950856,
      score: 0.9680915039,
    },
    "157.5": {
      velocity: [0.2785076561, 0.6950770497, 1.0010876965],
      direction: [-64.8790854338, -14.5819527221, 18.4013217407],
      "speed factor": 0.6666698283,
      "Uz factor": 0.996721391,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.460916171,
      "Direction stability factor": 0.7686655356,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0831311486, 0.3965628243, 0.7715325195],
      direction: [-173.8167228573, 1.7313823585, 160.8145372118],
      "speed factor": 0.555041704,
      "Uz factor": 0.9982331927,
      "Directional variance factor": 0.8460993459,
      "Velocity stability factor": 0.3269488569,
      "Direction stability factor": 0.070468722,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1298448984, 1.1580669954, 1.1857548162],
      direction: [-2.5910286995, -1.0616320883, 0.0957549492],
      "speed factor": 1.3772770177,
      "Uz factor": 0.9855929699,
      "Directional variance factor": 0.9056327033,
      "Velocity stability factor": 0.9553345815,
      "Direction stability factor": 0.9925367121,
      score: 0.9615101772,
    },
    "225.0": {
      velocity: [0.8134224817, 1.0259512393, 1.2220715044],
      direction: [-20.8413411448, -6.4398362417, 0.1777540971],
      "speed factor": 1.2423745596,
      "Uz factor": 0.9974354739,
      "Directional variance factor": 0.4275701119,
      "Velocity stability factor": 0.6161449759,
      "Direction stability factor": 0.9416136243,
      score: 0.7317355841,
    },
    "247.5": {
      velocity: [0.2075058946, 0.4930289063, 0.8656993513],
      direction: [-99.9579156558, 0.7039150893, 72.7618503686],
      "speed factor": 0.6176979712,
      "Uz factor": 0.9999999927,
      "Directional variance factor": 0.9374297698,
      "Velocity stability factor": 0.4780758562,
      "Direction stability factor": 0.5202228722,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.6461394307, 0.9213716285, 1.0539140935],
      direction: [-11.4625481971, 1.3353742288, 12.4741261865],
      "speed factor": 1.2921831185,
      "Uz factor": 0.9994015093,
      "Directional variance factor": 0.8813000686,
      "Velocity stability factor": 0.6523027187,
      "Direction stability factor": 0.9335092378,
      score: 0.8501553157,
    },
    "292.5": {
      velocity: [1.1272057061, 1.1700357396, 1.2031569462],
      direction: [1.4937280664, 4.2938313426, 6.1247301484],
      "speed factor": 1.169460406,
      "Uz factor": 0.9984662704,
      "Directional variance factor": 0.6183261029,
      "Velocity stability factor": 0.922568189,
      "Direction stability factor": 0.9871361053,
      score: 0.8787916256,
    },
    "315.0": {
      velocity: [0.8505408493, 0.8573184862, 0.8666318471],
      direction: [4.5643666654, 5.2876475697, 5.8240343331],
      "speed factor": 1.0654282892,
      "Uz factor": 0.9992256564,
      "Directional variance factor": 0.5299868827,
      "Velocity stability factor": 0.9741004194,
      "Direction stability factor": 0.9965009231,
      score: 0.8742722871,
    },
    "337.5": {
      velocity: [0.246215519, 0.8733000236, 1.0946749],
      direction: [-13.299721761, -0.9164805334, 14.8630425108],
      "speed factor": 0.8730977003,
      "Uz factor": 0.9986061363,
      "Directional variance factor": 0.9185350637,
      "Velocity stability factor": 0.154396219,
      "Direction stability factor": 0.9217700992,
      score: 0.0,
    },
    id: "IDN:1873.0",
    zone: 1,
  },
  {
    position: [-26.92781716, 16.23871861, 20.8740369],
    "overall score": 0.5180748495,
    "0": {
      velocity: [0.6822602609, 0.7525315856, 0.8403799268],
      direction: [-3.2636541594, 4.2022382764, 9.526134033],
      "speed factor": 1.2464765352,
      "Uz factor": 0.9793272719,
      "Directional variance factor": 0.6264677088,
      "Velocity stability factor": 0.8243600361,
      "Direction stability factor": 0.9644728106,
      score: 0.8449433415,
    },
    "22.5": {
      velocity: [0.372150304, 0.7147844905, 1.0542279459],
      direction: [-24.1140811723, 15.9571077789, 66.6043016646],
      "speed factor": 0.9457340391,
      "Uz factor": 0.9880126598,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4237001588,
      "Direction stability factor": 0.7480044921,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6425587228, 0.8594838702, 1.0366478815],
      direction: [-14.4765676199, -3.244562932, 7.1129244725],
      "speed factor": 1.3853075013,
      "Uz factor": 0.9877874612,
      "Directional variance factor": 0.711594406,
      "Velocity stability factor": 0.5752211043,
      "Direction stability factor": 0.9400291886,
      score: 0.7917184719,
    },
    "67.5": {
      velocity: [0.6195229298, 0.8948304835, 0.9726632993],
      direction: [-7.4463905647, -2.6567296203, 0.6543855056],
      "speed factor": 1.2160937532,
      "Uz factor": 0.9947701645,
      "Directional variance factor": 0.763846256,
      "Velocity stability factor": 0.6411802939,
      "Direction stability factor": 0.9774978442,
      score: 0.8400055596,
    },
    "90.0": {
      velocity: [0.4616164228, 0.8045518421, 1.0331678282],
      direction: [-18.2199674932, 4.2940342862, 22.5807245781],
      "speed factor": 1.1622435671,
      "Uz factor": 0.9999721006,
      "Directional variance factor": 0.6183080634,
      "Velocity stability factor": 0.4190545271,
      "Direction stability factor": 0.8866647442,
      score: 0.7026730198,
    },
    "112.5": {
      velocity: [0.8773694512, 1.1420296416, 1.2389366504],
      direction: [-3.2691576217, 7.8502952614, 11.9188982381],
      "speed factor": 1.0914519124,
      "Uz factor": 0.9972859393,
      "Directional variance factor": 0.3021959768,
      "Velocity stability factor": 0.7071913486,
      "Direction stability factor": 0.9578109559,
      score: 0.7312523093,
    },
    "135.0": {
      velocity: [0.9835463923, 1.0387204996, 1.0931854859],
      direction: [2.409366793, 5.1715580129, 7.0977295183],
      "speed factor": 1.2014940695,
      "Uz factor": 0.9998939445,
      "Directional variance factor": 0.5403059544,
      "Velocity stability factor": 0.8883177735,
      "Direction stability factor": 0.9869767702,
      score: 0.8506443171,
    },
    "157.5": {
      velocity: [0.4424393658, 0.7634363726, 1.0889542802],
      direction: [-44.7829645928, -11.6787065427, 17.0711825869],
      "speed factor": 0.7322353624,
      "Uz factor": 0.9995820111,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5176648729,
      "Direction stability factor": 0.8281829245,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1996990142, 0.5537591413, 0.8831128949],
      direction: [-81.8543928849, -0.9510876452, 59.3272876341],
      "speed factor": 0.7750585748,
      "Uz factor": 0.9995096715,
      "Directional variance factor": 0.915458876,
      "Velocity stability factor": 0.3318251343,
      "Direction stability factor": 0.6078286652,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0990170941, 1.1246771687, 1.1393797597],
      direction: [-4.1353490325, -1.7655016742, -0.5751026894],
      "speed factor": 1.3375668446,
      "Uz factor": 0.9999988517,
      "Directional variance factor": 0.8430665178,
      "Velocity stability factor": 0.9677549991,
      "Direction stability factor": 0.9901104268,
      score: 0.9477605927,
    },
    "225.0": {
      velocity: [0.3265074435, 0.5905392864, 0.8172400657],
      direction: [-33.6314284935, -3.3662824189, 21.6334472533],
      "speed factor": 0.7151129193,
      "Uz factor": 0.99956817,
      "Directional variance factor": 0.7007748961,
      "Velocity stability factor": 0.5390416419,
      "Direction stability factor": 0.8464864563,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.6879163576, 0.8196624825, 0.9315854534],
      direction: [-4.0853808401, 3.5091600575, 11.9458444155],
      "speed factor": 1.0269252899,
      "Uz factor": 0.9952793844,
      "Directional variance factor": 0.6880746616,
      "Velocity stability factor": 0.8067790208,
      "Direction stability factor": 0.9554688187,
      score: 0.85144783,
    },
    "270.0": {
      velocity: [0.5065970679, 1.0100154298, 1.1479153975],
      direction: [-4.6073892494, -0.0623275935, 33.0594562884],
      "speed factor": 1.4165021447,
      "Uz factor": 0.9994970121,
      "Directional variance factor": 0.9944597695,
      "Velocity stability factor": 0.4531670062,
      "Direction stability factor": 0.8953698735,
      score: 0.8095916307,
    },
    "292.5": {
      velocity: [1.1246703487, 1.1297667774, 1.1353835599],
      direction: [3.1059779954, 3.4645605714, 3.9114658765],
      "speed factor": 1.129211245,
      "Uz factor": 0.9974450541,
      "Directional variance factor": 0.6920390603,
      "Velocity stability factor": 0.9890779487,
      "Direction stability factor": 0.9977625337,
      score: 0.9191605191,
    },
    "315.0": {
      velocity: [0.7804173768, 0.7881568135, 0.7933531319],
      direction: [8.2388785412, 8.6432587922, 9.0494357483],
      "speed factor": 0.9794779641,
      "Uz factor": 0.9899525773,
      "Directional variance factor": 0.2317103296,
      "Velocity stability factor": 0.9791790019,
      "Direction stability factor": 0.9977484522,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.7994231736, 0.995481662, 1.0941841742],
      direction: [-4.8823907366, -2.3490747411, 2.4442152329],
      "speed factor": 0.9952510321,
      "Uz factor": 0.9861488568,
      "Directional variance factor": 0.7911933563,
      "Velocity stability factor": 0.7062310557,
      "Direction stability factor": 0.9796483168,
      score: 0.0,
    },
    id: "IDN:3647.0",
    zone: 1,
  },
  {
    position: [-25.8936219, 15.3169522, 20.8740369],
    "overall score": 0.513465696,
    "0": {
      velocity: [0.7124166455, 0.7812183583, 0.8725762987],
      direction: [-4.6870351528, 4.4003030749, 9.7889857198],
      "speed factor": 1.2939926657,
      "Uz factor": 0.9824182527,
      "Directional variance factor": 0.6088619489,
      "Velocity stability factor": 0.8220940099,
      "Direction stability factor": 0.9597888309,
      score: 0.8376334052,
    },
    "22.5": {
      velocity: [0.3273227615, 0.7454466841, 1.0967917175],
      direction: [-24.0414397031, 14.8097010425, 51.4113341367],
      "speed factor": 0.9863033025,
      "Uz factor": 0.9917505945,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3498616434,
      "Direction stability factor": 0.7904089616,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.642661257, 0.8692579046, 1.058861004],
      direction: [-15.7863410585, -4.9798849453, 6.1567524596],
      "speed factor": 1.4010611922,
      "Uz factor": 0.9889019759,
      "Directional variance factor": 0.5573435604,
      "Velocity stability factor": 0.5513886513,
      "Direction stability factor": 0.9390469624,
      score: 0.7467065342,
    },
    "67.5": {
      velocity: [0.5628577942, 0.8925321887, 0.9707386747],
      direction: [-9.2501036354, -3.8831983837, -0.1148781223],
      "speed factor": 1.2129703213,
      "Uz factor": 0.9961701579,
      "Directional variance factor": 0.6548268103,
      "Velocity stability factor": 0.5855594253,
      "Direction stability factor": 0.9746243736,
      score: 0.7974087457,
    },
    "90.0": {
      velocity: [0.5023233485, 0.8186029025, 1.015250862],
      direction: [-17.1432665186, 3.7396834462, 21.9521919412],
      "speed factor": 1.182541519,
      "Uz factor": 0.999895709,
      "Directional variance factor": 0.6675836937,
      "Velocity stability factor": 0.4786419663,
      "Direction stability factor": 0.8914015043,
      score: 0.7322571671,
    },
    "112.5": {
      velocity: [0.868717596, 1.1328499998, 1.2279257611],
      direction: [-0.9849490624, 7.6737064693, 11.6576600156],
      "speed factor": 1.0826788147,
      "Uz factor": 0.996893548,
      "Directional variance factor": 0.3178927583,
      "Velocity stability factor": 0.7091017696,
      "Direction stability factor": 0.9648816415,
      score: 0.7391894527,
    },
    "135.0": {
      velocity: [1.0022662669, 1.0508934243, 1.0985557928],
      direction: [2.9545220423, 5.2126887893, 6.9025927663],
      "speed factor": 1.2155745626,
      "Uz factor": 0.9998924156,
      "Directional variance factor": 0.5366498854,
      "Velocity stability factor": 0.9019161113,
      "Direction stability factor": 0.9890331369,
      score: 0.8541580676,
    },
    "157.5": {
      velocity: [0.4174906241, 0.7526509242, 1.0706004746],
      direction: [-50.1295945076, -12.6473423241, 11.7377201061],
      "speed factor": 0.7218907064,
      "Uz factor": 0.9992491131,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5127446935,
      "Direction stability factor": 0.8281463483,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.2137844966, 0.5190957637, 0.8894296655],
      direction: [-100.7120602794, -1.426328609, 49.1391662871],
      "speed factor": 0.7265426298,
      "Uz factor": 0.9996895076,
      "Directional variance factor": 0.8732152348,
      "Velocity stability factor": 0.3394206164,
      "Direction stability factor": 0.5837465929,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0732244864, 1.1162135119, 1.1417841862],
      direction: [-5.3135148572, -2.4126316355, -0.7614673576],
      "speed factor": 1.3275011056,
      "Uz factor": 0.9995461549,
      "Directional variance factor": 0.7855438546,
      "Velocity stability factor": 0.9452289004,
      "Direction stability factor": 0.9873554236,
      score: 0.9263709006,
    },
    "225.0": {
      velocity: [0.3717335479, 0.6698552071, 0.8742811707],
      direction: [-29.9967030367, -2.1916996647, 22.7765559078],
      "speed factor": 0.811160449,
      "Uz factor": 0.9993854368,
      "Directional variance factor": 0.805182252,
      "Velocity stability factor": 0.5279434939,
      "Direction stability factor": 0.853407614,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.6528746884, 0.782429182, 0.8926961907],
      direction: [-4.910762128, 3.1698567788, 18.1002538921],
      "speed factor": 0.980277043,
      "Uz factor": 0.9962713994,
      "Directional variance factor": 0.718234953,
      "Velocity stability factor": 0.8098300265,
      "Direction stability factor": 0.9360805111,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.6083146334, 1.0144087882, 1.1403109987],
      direction: [-2.2272810884, 1.7008898751, 18.1114755134],
      "speed factor": 1.4226636362,
      "Uz factor": 0.9998364184,
      "Directional variance factor": 0.8488097889,
      "Velocity stability factor": 0.5463825815,
      "Direction stability factor": 0.9435034539,
      score: 0.8205498195,
    },
    "292.5": {
      velocity: [1.1409362677, 1.1463051096, 1.1522687865],
      direction: [4.6713955759, 5.0247989865, 5.530087666],
      "speed factor": 1.1457414449,
      "Uz factor": 0.9993605111,
      "Directional variance factor": 0.5533512012,
      "Velocity stability factor": 0.9884465685,
      "Direction stability factor": 0.9976147442,
      score: 0.8842568145,
    },
    "315.0": {
      velocity: [0.7909376892, 0.7982935619, 0.8036160977],
      direction: [10.1332415025, 10.5621188489, 10.9818787338],
      "speed factor": 0.9920753579,
      "Uz factor": 0.9931850651,
      "Directional variance factor": 0.0611449912,
      "Velocity stability factor": 0.9795932193,
      "Direction stability factor": 0.9976426744,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.8042227467, 1.0045362889, 1.1177606692],
      direction: [-4.3222199218, -1.5249181676, 3.6496439177],
      "speed factor": 1.0043035612,
      "Uz factor": 0.9894080592,
      "Directional variance factor": 0.8644517184,
      "Velocity stability factor": 0.68751733,
      "Direction stability factor": 0.9778559338,
      score: 0.876920229,
    },
    id: "IDN:2167.0",
    zone: 1,
  },
  {
    position: [-22.93680936, 3.736911829, 20.8740369],
    "overall score": 0.5101866309,
    "0": {
      velocity: [0.1868030651, 0.6808059049, 0.8826711481],
      direction: [-35.602886251, 4.9283450218, 16.9469054539],
      "speed factor": 1.1276717174,
      "Uz factor": 0.9993225191,
      "Directional variance factor": 0.5619248869,
      "Velocity stability factor": 0.227026921,
      "Direction stability factor": 0.8540283564,
      score: 0.6242521302,
    },
    "22.5": {
      velocity: [0.2552690879, 0.762607747, 1.1460241309],
      direction: [-28.1531057924, 9.9248874109, 58.3470829804],
      "speed factor": 1.0090091691,
      "Uz factor": 0.998248567,
      "Directional variance factor": 0.1177877857,
      "Velocity stability factor": 0.2473848162,
      "Direction stability factor": 0.7597216979,
      score: 0.4711539994,
    },
    "45.0": {
      velocity: [0.2859350739, 0.6054155208, 1.034977613],
      direction: [-25.6555729326, -9.4592839581, 43.0621516653],
      "speed factor": 0.9758026781,
      "Uz factor": 0.9997539189,
      "Directional variance factor": 0.1591747593,
      "Velocity stability factor": 0.1926256898,
      "Direction stability factor": 0.8091174317,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7038747649, 0.9150347883, 0.9856840581],
      direction: [-4.7324983822, -2.7621962231, 1.912916677],
      "speed factor": 1.2435518352,
      "Uz factor": 0.999065145,
      "Directional variance factor": 0.7544714468,
      "Velocity stability factor": 0.7136585434,
      "Direction stability factor": 0.9815405137,
      score: 0.8578027544,
    },
    "90.0": {
      velocity: [0.2757295712, 0.6871145448, 0.9464173354],
      direction: [-25.4571486421, 1.1579269129, 36.0332536188],
      "speed factor": 0.9925954025,
      "Uz factor": 0.9999631833,
      "Directional variance factor": 0.8970731633,
      "Velocity stability factor": 0.3182887547,
      "Direction stability factor": 0.8291933271,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1499990559, 1.1902187048, 1.2360616993],
      direction: [4.2185377967, 6.9893663849, 8.3437799989],
      "speed factor": 1.1375067986,
      "Uz factor": 0.9919546888,
      "Directional variance factor": 0.378722988,
      "Velocity stability factor": 0.9303037261,
      "Direction stability factor": 0.9885409939,
      score: 0.8215271755,
    },
    "135.0": {
      velocity: [1.1501557583, 1.1588824629, 1.1676588537],
      direction: [-2.2017963076, -1.9278320422, -1.5178544616],
      "speed factor": 1.3404861144,
      "Uz factor": 0.9796728373,
      "Directional variance factor": 0.8286371518,
      "Velocity stability factor": 0.9821707331,
      "Direction stability factor": 0.9981001615,
      score: 0.951752052,
    },
    "157.5": {
      velocity: [0.2166151744, 0.6098561552, 0.9910900773],
      direction: [-97.2884552235, -17.6363291679, 24.6909232482],
      "speed factor": 0.5849318409,
      "Uz factor": 0.9934246159,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4221997941,
      "Direction stability factor": 0.6611683931,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.071798562, 0.3898238422, 0.7839235806],
      direction: [-153.2329766662, -3.374596405, 92.1316686383],
      "speed factor": 0.5456096143,
      "Uz factor": 0.9993642004,
      "Directional variance factor": 0.7000358751,
      "Velocity stability factor": 0.3037542078,
      "Direction stability factor": 0.3184315408,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1098743904, 1.1212695296, 1.1385856654],
      direction: [-4.0686663458, -3.5213938344, -2.9710302629],
      "speed factor": 1.3335141749,
      "Uz factor": 0.9606446202,
      "Directional variance factor": 0.6869872147,
      "Velocity stability factor": 0.9770630836,
      "Direction stability factor": 0.9969510109,
      score: 0.91448808,
    },
    "225.0": {
      velocity: [0.3658537506, 0.7181703918, 0.8940987032],
      direction: [-62.5485580458, -16.7708276652, 9.2681666342],
      "speed factor": 0.8696676704,
      "Uz factor": 0.9368840826,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5038053006,
      "Direction stability factor": 0.8005090981,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1479565739, 0.5082333551, 0.8328108999],
      direction: [-42.726954202, 6.5694476324, 136.0905093409],
      "speed factor": 0.6367470717,
      "Uz factor": 0.9980889303,
      "Directional variance factor": 0.4160490993,
      "Velocity stability factor": 0.4569347294,
      "Direction stability factor": 0.5032848235,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.6864003025, 0.8564271334, 1.036077039],
      direction: [-8.7342154823, 0.4076322611, 5.7912645618],
      "speed factor": 1.2011013252,
      "Uz factor": 0.9996092169,
      "Directional variance factor": 0.9637660212,
      "Velocity stability factor": 0.701841086,
      "Direction stability factor": 0.9596514443,
      score: 0.896227499,
    },
    "292.5": {
      velocity: [0.9723945027, 1.0967377898, 1.204333258],
      direction: [-11.1690923276, -1.7514717692, 3.525415021],
      "speed factor": 1.0961984984,
      "Uz factor": 0.9972769952,
      "Directional variance factor": 0.8443136205,
      "Velocity stability factor": 0.7635398995,
      "Direction stability factor": 0.959181924,
      score: 0.881554342,
    },
    "315.0": {
      velocity: [0.9190956664, 0.9277782463, 0.9402326907],
      direction: [3.6990318366, 4.0016417525, 4.2631274391],
      "speed factor": 1.1529918061,
      "Uz factor": 0.9987805203,
      "Directional variance factor": 0.6442985109,
      "Velocity stability factor": 0.9659784886,
      "Direction stability factor": 0.9984330678,
      score: 0.9017857838,
    },
    "337.5": {
      velocity: [0.8424510208, 1.0615524028, 1.1552797155],
      direction: [-5.4529522128, -3.0448606366, 3.1511876934],
      "speed factor": 1.0613064658,
      "Uz factor": 0.9990888389,
      "Directional variance factor": 0.7293457212,
      "Velocity stability factor": 0.6882241708,
      "Direction stability factor": 0.9760996114,
      score: 0.8424422787,
    },
    id: "IDN:3644.0",
    zone: 1,
  },
  {
    position: [-21.03089653, 5.815630325, 21.1598299],
    "overall score": 0.5098554895,
    "0": {
      velocity: [0.6400321577, 0.8584511388, 1.0212094815],
      direction: [-4.8159208965, 3.5423063087, 10.6056602875],
      "speed factor": 1.421919321,
      "Uz factor": 0.9999509905,
      "Directional variance factor": 0.6851283281,
      "Velocity stability factor": 0.5765866882,
      "Direction stability factor": 0.9571622745,
      score: 0.7940098913,
    },
    "22.5": {
      velocity: [0.2845546854, 0.8006046244, 1.2361150077],
      direction: [-32.3185288757, 8.9984764314, 54.735364094],
      "speed factor": 1.0592829801,
      "Uz factor": 0.9977701937,
      "Directional variance factor": 0.2001354283,
      "Velocity stability factor": 0.1960093265,
      "Direction stability factor": 0.7581836306,
      score: 0.478128004,
    },
    "45.0": {
      velocity: [0.3765251741, 0.7047773575, 1.0648162309],
      direction: [-31.2007616476, -12.6598649981, 22.4174493875],
      "speed factor": 1.1359530923,
      "Uz factor": 0.9999471046,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2581082005,
      "Direction stability factor": 0.8510605249,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7072253306, 0.917919026, 0.9887479839],
      direction: [-6.0209722103, -3.4851519658, 5.379262306],
      "speed factor": 1.2474715759,
      "Uz factor": 0.9995443989,
      "Directional variance factor": 0.6902087141,
      "Velocity stability factor": 0.7139497932,
      "Direction stability factor": 0.9683326819,
      score: 0.8352059678,
    },
    "90.0": {
      velocity: [0.3337998466, 0.7483667054, 1.0271756664],
      direction: [-19.7891570604, 1.3936347215, 45.3343189581],
      "speed factor": 1.0810793584,
      "Uz factor": 0.9997488431,
      "Directional variance factor": 0.8761213581,
      "Velocity stability factor": 0.2952277964,
      "Direction stability factor": 0.8191014555,
      score: 0.7023880164,
    },
    "112.5": {
      velocity: [1.1143890059, 1.1836717682, 1.2611384696],
      direction: [6.9549116979, 10.5815906231, 14.1099515646],
      "speed factor": 1.13124981,
      "Uz factor": 0.9955596645,
      "Directional variance factor": 0.0594141668,
      "Velocity stability factor": 0.8811576032,
      "Direction stability factor": 0.9801248893,
      score: 0.7252053871,
    },
    "135.0": {
      velocity: [1.1808997555, 1.1892192832, 1.198525686],
      direction: [2.7945481423, 3.3592115275, 3.8228942042],
      "speed factor": 1.3755768916,
      "Uz factor": 0.9915186459,
      "Directional variance factor": 0.7014034198,
      "Velocity stability factor": 0.9820456089,
      "Direction stability factor": 0.9971434832,
      score: 0.9194339987,
    },
    "157.5": {
      velocity: [0.2339175363, 0.6683550002, 1.0487087687],
      direction: [-86.370471115, -15.8436234428, 13.4132633296],
      "speed factor": 0.6410398867,
      "Uz factor": 0.9951532366,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3921216296,
      "Direction stability factor": 0.7228229599,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0987706684, 0.3945582807, 0.7691597149],
      direction: [-146.3723864658, -1.4791821232, 151.5862856135],
      "speed factor": 0.5522360822,
      "Uz factor": 0.9994980269,
      "Directional variance factor": 0.8685171446,
      "Velocity stability factor": 0.3445595358,
      "Direction stability factor": 0.172337022,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.167995972, 1.1960006377, 1.219603213],
      direction: [-3.2786169203, -2.5821638922, -2.0026508597],
      "speed factor": 1.4223911035,
      "Uz factor": 0.9724927859,
      "Directional variance factor": 0.7704743207,
      "Velocity stability factor": 0.958771912,
      "Direction stability factor": 0.9964556498,
      score: 0.9305393831,
    },
    "225.0": {
      velocity: [0.3635018061, 0.7207060114, 0.9598183637],
      direction: [-51.916895286, -15.6688343817, 3.0611387407],
      "speed factor": 0.8727381763,
      "Uz factor": 0.9847140392,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4398638101,
      "Direction stability factor": 0.8472832388,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2453262196, 0.5686085046, 0.9284080603],
      direction: [-50.0833332387, 6.7542985039, 66.3456593556],
      "speed factor": 0.712388899,
      "Uz factor": 0.9992090812,
      "Directional variance factor": 0.3996179108,
      "Velocity stability factor": 0.4583402475,
      "Direction stability factor": 0.6765861317,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.84234588, 0.9887131473, 1.0620216065],
      direction: [-7.8439251487, 0.4969698822, 4.9270440789],
      "speed factor": 1.3866266319,
      "Uz factor": 0.9999957767,
      "Directional variance factor": 0.9558248994,
      "Velocity stability factor": 0.8126890662,
      "Direction stability factor": 0.9645250855,
      score: 0.9243910341,
    },
    "292.5": {
      velocity: [1.1360431334, 1.1757707834, 1.1982493755],
      direction: [0.5456808516, 2.6705762089, 4.2773486401],
      "speed factor": 1.1751926297,
      "Uz factor": 0.9993963424,
      "Directional variance factor": 0.7626154481,
      "Velocity stability factor": 0.9365811279,
      "Direction stability factor": 0.9896342561,
      score: 0.9196162721,
    },
    "315.0": {
      velocity: [0.8627186389, 0.8740202519, 0.8830438096],
      direction: [2.2870567587, 2.7861661616, 3.105426306],
      "speed factor": 1.0861843255,
      "Uz factor": 0.9999973912,
      "Directional variance factor": 0.7523407856,
      "Velocity stability factor": 0.9672852236,
      "Direction stability factor": 0.9977267513,
      score: 0.9287698779,
    },
    "337.5": {
      velocity: [0.54045395, 0.9792987075, 1.1562900053],
      direction: [-7.136811623, -0.6327017336, 8.5748312887],
      "speed factor": 0.9790718268,
      "Uz factor": 0.999032972,
      "Directional variance factor": 0.9437598459,
      "Velocity stability factor": 0.3862366207,
      "Direction stability factor": 0.9563565475,
      score: 0.0,
    },
    id: "IDN:3636.0",
    zone: 1,
  },
  {
    position: [-9.313039146, 2.733231992, 21.1598299],
    "overall score": 0.5078009654,
    "0": {
      velocity: [0.3728033791, 0.8416615383, 1.0286507449],
      direction: [-24.6681488965, -3.4342660143, 14.4367127297],
      "speed factor": 1.394109401,
      "Uz factor": 0.9998637602,
      "Directional variance factor": 0.6947319098,
      "Velocity stability factor": 0.2714820954,
      "Direction stability factor": 0.8913753844,
      score: 0.6872411935,
    },
    "22.5": {
      velocity: [0.3114683592, 0.7996998308, 1.2134825207],
      direction: [-28.1993642859, 2.4134436333, 34.1056664195],
      "speed factor": 1.0580858443,
      "Uz factor": 0.9977628901,
      "Directional variance factor": 0.785471677,
      "Velocity stability factor": 0.2378717816,
      "Direction stability factor": 0.8269304703,
      score: 0.6693010998,
    },
    "45.0": {
      velocity: [0.1302560307, 0.6314571188, 0.9827584519],
      direction: [-28.0353239591, -11.3011406705, 36.0622083558],
      "speed factor": 1.0177762653,
      "Uz factor": 0.9984016432,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0811088579,
      "Direction stability factor": 0.8219512991,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7902755389, 0.9101165917, 0.970835386],
      direction: [-4.5412900887, -2.7984612277, 0.7896458016],
      "speed factor": 1.2368679009,
      "Uz factor": 0.9995559464,
      "Directional variance factor": 0.7512478909,
      "Velocity stability factor": 0.8165363212,
      "Direction stability factor": 0.9851918447,
      score: 0.8845419754,
    },
    "90.0": {
      velocity: [0.2633268948, 0.6335685202, 0.9642514932],
      direction: [-28.4716423959, 2.0837819658, 39.7279549144],
      "speed factor": 0.9152436156,
      "Uz factor": 0.999649867,
      "Directional variance factor": 0.8147749364,
      "Velocity stability factor": 0.2875549454,
      "Direction stability factor": 0.8105566741,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1104604618, 1.170361544, 1.2632432999],
      direction: [4.4966418249, 8.3077969854, 10.1876642997],
      "speed factor": 1.1185290633,
      "Uz factor": 0.9896813392,
      "Directional variance factor": 0.2615291569,
      "Velocity stability factor": 0.8762715841,
      "Direction stability factor": 0.9841916042,
      score: 0.7765459874,
    },
    "135.0": {
      velocity: [1.1874074158, 1.1930559224, 1.1981619991],
      direction: [-0.8310476387, -0.471971979, -0.1066865243],
      "speed factor": 1.3800147546,
      "Uz factor": 0.9829065301,
      "Directional variance factor": 0.9580469352,
      "Velocity stability factor": 0.9890450042,
      "Direction stability factor": 0.9979878858,
      score: 0.9857669277,
    },
    "157.5": {
      velocity: [0.3681707862, 0.8254376039, 1.0905025546],
      direction: [-46.7493556879, -13.4055367893, 7.0044200355],
      "speed factor": 0.791702655,
      "Uz factor": 0.9995241581,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4611013953,
      "Direction stability factor": 0.8506839563,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0840063075, 0.3531707713, 0.8074801373],
      direction: [-167.3839615069, 7.1461688435, 154.8081948203],
      "speed factor": 0.4943088325,
      "Uz factor": 0.9985924265,
      "Directional variance factor": 0.3647849917,
      "Velocity stability factor": 0.2926584566,
      "Direction stability factor": 0.105021788,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0482741729, 1.0813706765, 1.1033397439],
      direction: [0.7920558095, 1.5347664456, 2.3879020439],
      "speed factor": 1.2860628844,
      "Uz factor": 0.9739251973,
      "Directional variance factor": 0.863576316,
      "Velocity stability factor": 0.9560091149,
      "Direction stability factor": 0.9955670938,
      score: 0.9526799046,
    },
    "225.0": {
      velocity: [1.0810002661, 1.1071099063, 1.1423972468],
      direction: [-9.6711621969, -7.8255435561, -6.2274993803],
      "speed factor": 1.3406535609,
      "Uz factor": 0.9883388314,
      "Directional variance factor": 0.3043961284,
      "Velocity stability factor": 0.9423281638,
      "Direction stability factor": 0.99043427,
      score: 0.806898208,
    },
    "247.5": {
      velocity: [0.103167994, 0.3630116082, 0.9308703682],
      direction: [-163.5972896603, -14.3886383554, 176.4276826469],
      "speed factor": 0.4548040309,
      "Uz factor": 0.9810845468,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3436612768,
      "Direction stability factor": 0.055486188,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.5084783953, 0.9170306148, 1.060986769],
      direction: [-5.3692596134, 5.2221651938, 21.0367984595],
      "speed factor": 1.2860950381,
      "Uz factor": 0.9972964272,
      "Directional variance factor": 0.5358075383,
      "Velocity stability factor": 0.5288926044,
      "Direction stability factor": 0.9266498387,
      score: 0.729499955,
    },
    "292.5": {
      velocity: [1.1255153136, 1.158898435, 1.184132342],
      direction: [4.2799979397, 6.719641629, 7.9408935931],
      "speed factor": 1.1583285779,
      "Uz factor": 0.998753564,
      "Directional variance factor": 0.4026985219,
      "Velocity stability factor": 0.9402403086,
      "Direction stability factor": 0.9898308454,
      score: 0.8306501303,
    },
    "315.0": {
      velocity: [0.8499033121, 0.8643777798, 0.8800134558],
      direction: [7.5818667322, 8.2950479095, 8.9206664849],
      "speed factor": 1.0742011912,
      "Uz factor": 0.9997603268,
      "Directional variance factor": 0.262662408,
      "Velocity stability factor": 0.9515356286,
      "Direction stability factor": 0.9962811118,
      score: 0.801690065,
    },
    "337.5": {
      velocity: [0.5613876316, 0.7203043993, 0.8127922384],
      direction: [-5.916609326, 2.0929908252, 12.5947399266],
      "speed factor": 0.7201375215,
      "Uz factor": 0.9994646656,
      "Directional variance factor": 0.8139563711,
      "Velocity stability factor": 0.749441528,
      "Direction stability factor": 0.9485795854,
      score: 0.0,
    },
    id: "IDN:3550.0",
    zone: 1,
  },
  {
    position: [-25.8936219, 13.3169522, 20.8740369],
    "overall score": 0.50578805,
    "0": {
      velocity: [0.745795235, 0.8236901829, 0.9202815165],
      direction: [-4.4114189418, 5.5137096691, 11.8333423617],
      "speed factor": 1.3643420489,
      "Uz factor": 0.9914380564,
      "Directional variance factor": 0.5098924739,
      "Velocity stability factor": 0.8061799334,
      "Direction stability factor": 0.954875663,
      score: 0.8064559333,
    },
    "22.5": {
      velocity: [0.2996740523, 0.7643745741, 1.1718278148],
      direction: [-25.3161014833, 13.6159836858, 62.1410635599],
      "speed factor": 1.0113468646,
      "Uz factor": 0.9979197082,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2631013773,
      "Direction stability factor": 0.7570634304,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6475268273, 0.8739101558, 1.049631453],
      direction: [-17.6187044259, -7.2650183135, 4.7930611885],
      "speed factor": 1.4085596441,
      "Uz factor": 0.9937526339,
      "Directional variance factor": 0.3542205944,
      "Velocity stability factor": 0.5665814319,
      "Direction stability factor": 0.9377450955,
      score: 0.6990730543,
    },
    "67.5": {
      velocity: [0.5150233255, 0.8756352545, 0.9613258199],
      direction: [-11.6246908957, -5.1490163813, 1.6409772244],
      "speed factor": 1.1900070266,
      "Uz factor": 0.9988798038,
      "Directional variance factor": 0.542309655,
      "Velocity stability factor": 0.5465199005,
      "Direction stability factor": 0.9631509219,
      score: 0.7537828498,
    },
    "90.0": {
      velocity: [0.5795743448, 0.8318617745, 1.0137338694],
      direction: [-19.2275792756, 3.1199519642, 22.9584172981],
      "speed factor": 1.2016950874,
      "Uz factor": 0.9997600349,
      "Directional variance factor": 0.7226709365,
      "Velocity stability factor": 0.5587045926,
      "Direction stability factor": 0.8828166762,
      score: 0.7617522204,
    },
    "112.5": {
      velocity: [0.6825536127, 1.0909925361, 1.2173445195],
      direction: [-7.0191300151, 9.6640918816, 15.5883697913],
      "speed factor": 1.0426751167,
      "Uz factor": 0.9960542107,
      "Directional variance factor": 0.1409696105,
      "Velocity stability factor": 0.5669092646,
      "Direction stability factor": 0.9372013894,
      score: 0.6455704135,
    },
    "135.0": {
      velocity: [1.0273575765, 1.0580688877, 1.0958101345],
      direction: [4.0172088242, 5.792771361, 7.7433255464],
      "speed factor": 1.2238744631,
      "Uz factor": 0.9993489187,
      "Directional variance factor": 0.4850869901,
      "Velocity stability factor": 0.9302718232,
      "Direction stability factor": 0.9896496758,
      score: 0.8486645412,
    },
    "157.5": {
      velocity: [0.3557490236, 0.7181138821, 1.0252960669],
      direction: [-56.1369227302, -14.6123432171, 9.9750824357],
      "speed factor": 0.6887651645,
      "Uz factor": 0.9981798025,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5004816579,
      "Direction stability factor": 0.8163555412,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1534870589, 0.4959692152, 0.9092417555],
      direction: [-77.6302454986, -1.1228519973, 66.5328106991],
      "speed factor": 0.6941739908,
      "Uz factor": 0.9993453237,
      "Directional variance factor": 0.9001909336,
      "Velocity stability factor": 0.2610974006,
      "Direction stability factor": 0.5995470661,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0995995242, 1.1313553411, 1.1734486493],
      direction: [-9.5091473703, -5.3126901768, -3.5225207959],
      "speed factor": 1.3455091255,
      "Uz factor": 0.9977559785,
      "Directional variance factor": 0.5277608732,
      "Velocity stability factor": 0.9410032746,
      "Direction stability factor": 0.9833704817,
      score: 0.8588762778,
    },
    "225.0": {
      velocity: [0.3827843097, 0.762789923, 1.104693696],
      direction: [-25.8368194266, -1.9536394194, 22.2955632571],
      "speed factor": 0.9236996441,
      "Uz factor": 0.9964108326,
      "Directional variance factor": 0.8263431627,
      "Velocity stability factor": 0.3218910863,
      "Direction stability factor": 0.866298937,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.5317420471, 0.7542670462, 0.9023233552],
      direction: [-20.4926038568, 1.8707901267, 19.9794444655],
      "speed factor": 0.9449937281,
      "Uz factor": 0.9956827205,
      "Directional variance factor": 0.8337075443,
      "Velocity stability factor": 0.7061421229,
      "Direction stability factor": 0.8875776435,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.9205009351, 1.0360815085, 1.1189638697],
      direction: [-2.0385455232, 1.3016774123, 11.6744981422],
      "speed factor": 1.4530586717,
      "Uz factor": 0.9998791457,
      "Directional variance factor": 0.8842953411,
      "Velocity stability factor": 0.8307765806,
      "Direction stability factor": 0.961908212,
      score: 0.9097220864,
    },
    "292.5": {
      velocity: [1.1675484192, 1.1734384427, 1.1790636908],
      direction: [3.7820603605, 4.1382625893, 4.617098824],
      "speed factor": 1.1728614359,
      "Uz factor": 0.9992688935,
      "Directional variance factor": 0.6321544365,
      "Velocity stability factor": 0.9882602531,
      "Direction stability factor": 0.9976804487,
      score: 0.9039438968,
    },
    "315.0": {
      velocity: [0.8019073144, 0.8110542117, 0.8167833653],
      direction: [11.3160755434, 11.7700822185, 12.1712867803],
      "speed factor": 1.007933592,
      "Uz factor": 0.9990785403,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9760559609,
      "Direction stability factor": 0.9976244132,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.8719839663, 1.0789958091, 1.2082276411],
      direction: [-2.923989041, -0.0626115244, 4.3214466405],
      "speed factor": 1.0787458309,
      "Uz factor": 0.9965476815,
      "Directional variance factor": 0.9944345312,
      "Velocity stability factor": 0.6648879968,
      "Direction stability factor": 0.9798737898,
      score: 0.9047675269,
    },
    id: "IDN:2161.0",
    zone: 1,
  },
  {
    position: [-15.45866, 8.2679947, 21.1598299],
    "overall score": 0.5035897782,
    "0": {
      velocity: [0.7244093634, 0.894143099, 0.9848568343],
      direction: [-5.3889934337, 0.2401030568, 7.1618276054],
      "speed factor": 1.4810386878,
      "Uz factor": 0.997837064,
      "Directional variance factor": 0.9786575061,
      "Velocity stability factor": 0.7106938967,
      "Direction stability factor": 0.9651366082,
      score: 0.9049061548,
    },
    "22.5": {
      velocity: [0.2543487289, 0.8536982224, 1.2225636744],
      direction: [-24.6132874896, 6.59617968, 60.5055204268],
      "speed factor": 1.1295313186,
      "Uz factor": 0.997914358,
      "Directional variance factor": 0.4136729173,
      "Velocity stability factor": 0.1819375316,
      "Direction stability factor": 0.7635588669,
      score: 0.5306820457,
    },
    "45.0": {
      velocity: [0.3347232292, 0.7435222414, 1.0547829239],
      direction: [-32.4685372858, -15.7311312063, 11.0615498926],
      "speed factor": 1.1984017084,
      "Uz factor": 0.9995273842,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2238655764,
      "Direction stability factor": 0.8790830912,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.6967124644, 0.9001262288, 0.9749920076],
      direction: [-7.4283945889, -4.6719862516, -0.0131678478],
      "speed factor": 1.2232907842,
      "Uz factor": 0.9994313229,
      "Directional variance factor": 0.5847123332,
      "Velocity stability factor": 0.7172450602,
      "Direction stability factor": 0.9794021479,
      score: 0.8151904223,
    },
    "90.0": {
      velocity: [0.4516099558, 0.7863447474, 1.008626087],
      direction: [-19.913049118, 2.9893022674, 33.2232374257],
      "speed factor": 1.1359418702,
      "Uz factor": 0.9987211297,
      "Directional variance factor": 0.7342842429,
      "Velocity stability factor": 0.4338287044,
      "Direction stability factor": 0.852399204,
      score: 0.7182278388,
    },
    "112.5": {
      velocity: [0.886536392, 1.1536831098, 1.2496484655],
      direction: [-4.0808263579, 14.7484862592, 19.6954693302],
      "speed factor": 1.1025892767,
      "Uz factor": 0.999999975,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7059402601,
      "Direction stability factor": 0.9339547342,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.2000153086, 1.2192123826, 1.2339070314],
      direction: [2.4692167699, 3.2176963062, 3.8930271922],
      "speed factor": 1.410270085,
      "Uz factor": 0.9964926876,
      "Directional variance factor": 0.7139825506,
      "Velocity stability factor": 0.9654767023,
      "Direction stability factor": 0.996044971,
      score: 0.9178872987,
    },
    "157.5": {
      velocity: [0.3839168887, 0.7953143887, 1.074524106],
      direction: [-55.9765158939, -12.1442252489, 9.7433354052],
      "speed factor": 0.7628105505,
      "Uz factor": 0.9977739509,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.484769628,
      "Direction stability factor": 0.8174448575,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0835963836, 0.4135619199, 0.8158197289],
      direction: [-111.17053215, 5.5308067786, 141.8360301034],
      "speed factor": 0.5788341686,
      "Uz factor": 0.9989533112,
      "Directional variance factor": 0.5083727308,
      "Velocity stability factor": 0.284104041,
      "Direction stability factor": 0.2972039937,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1531730252, 1.1960924165, 1.2391596799],
      direction: [-4.3564294981, -2.6841930049, -0.6277684027],
      "speed factor": 1.4225002551,
      "Uz factor": 0.9956116952,
      "Directional variance factor": 0.7614050662,
      "Velocity stability factor": 0.9313068225,
      "Direction stability factor": 0.9896426081,
      score: 0.9179992762,
    },
    "225.0": {
      velocity: [0.6637903432, 0.9653386873, 1.196467866],
      direction: [-23.0250440284, -5.5913739908, 2.9878925547],
      "speed factor": 1.1689758543,
      "Uz factor": 0.9914629088,
      "Directional variance factor": 0.5029889786,
      "Velocity stability factor": 0.499641668,
      "Direction stability factor": 0.9277418428,
      score: 0.7145285831,
    },
    "247.5": {
      velocity: [0.2005567663, 0.5715550817, 0.9423321179],
      direction: [-60.0899465221, 6.6864382716, 95.4699706442],
      "speed factor": 0.7160805582,
      "Uz factor": 0.9999849652,
      "Directional variance factor": 0.4056499314,
      "Velocity stability factor": 0.4117983683,
      "Direction stability factor": 0.567889119,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.8690736598, 0.9709246956, 1.0164113218],
      direction: [-2.0966952798, 3.2775230098, 8.6199663903],
      "speed factor": 1.3616791121,
      "Uz factor": 0.9991573501,
      "Directional variance factor": 0.7086646214,
      "Velocity stability factor": 0.8743695743,
      "Direction stability factor": 0.9702314954,
      score: 0.8808742966,
    },
    "292.5": {
      velocity: [1.1299540454, 1.1449123, 1.1542863343],
      direction: [3.6981314278, 4.5247666769, 5.0534847509],
      "speed factor": 1.1443493201,
      "Uz factor": 0.9989261596,
      "Directional variance factor": 0.5977985176,
      "Velocity stability factor": 0.9751933846,
      "Direction stability factor": 0.9962351297,
      score: 0.8913655404,
    },
    "315.0": {
      velocity: [0.8580436433, 0.8634817306, 0.8714158558],
      direction: [9.7495375478, 10.2432162878, 10.6258497658],
      "speed factor": 1.0730876306,
      "Uz factor": 0.9999825265,
      "Directional variance factor": 0.0894918855,
      "Velocity stability factor": 0.9784764935,
      "Direction stability factor": 0.9975657994,
      score: 0.7657749945,
    },
    "337.5": {
      velocity: [0.590063912, 0.8224740064, 0.9915094135],
      direction: [-11.9737072669, -0.6701229007, 9.5099589754],
      "speed factor": 0.8222834583,
      "Uz factor": 0.9988100091,
      "Directional variance factor": 0.9404335199,
      "Velocity stability factor": 0.5999056153,
      "Direction stability factor": 0.9403231493,
      score: 0.0,
    },
    id: "IDN:1936.0",
    zone: 1,
  },
  {
    position: [53.99447359, 14.1611326, 21.1598299],
    "overall score": 0.5033324746,
    "0": {
      velocity: [0.5248987265, 0.6917434725, 0.8373735317],
      direction: [-23.6030183298, -11.9926051962, -2.6235505772],
      "speed factor": 1.1457884603,
      "Uz factor": 0.9998991992,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6529017235,
      "Direction stability factor": 0.9417237007,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.4832479121, 0.7385235252, 0.9974199103],
      direction: [-11.7601357441, 3.7167926035, 20.2106519543],
      "speed factor": 0.9771432449,
      "Uz factor": 0.997933022,
      "Directional variance factor": 0.6696184352,
      "Velocity stability factor": 0.5655666998,
      "Direction stability factor": 0.9111922564,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3785965199, 0.6470994753, 0.8363845463],
      direction: [-25.8233043903, 4.0168955426, 16.4323513061],
      "speed factor": 1.042988459,
      "Uz factor": 0.9898347268,
      "Directional variance factor": 0.6429426184,
      "Velocity stability factor": 0.5065616802,
      "Direction stability factor": 0.8826231786,
      score: 0.728687664,
    },
    "67.5": {
      velocity: [0.6588555292, 0.8045190208, 0.9420093245],
      direction: [-1.3757124326, 3.5540094435, 7.8394563512],
      "speed factor": 1.093358545,
      "Uz factor": 0.98695698,
      "Directional variance factor": 0.6840880495,
      "Velocity stability factor": 0.7122924186,
      "Direction stability factor": 0.9744023089,
      score: 0.8362962715,
    },
    "90.0": {
      velocity: [0.9959809584, 1.0075172117, 1.0181181552],
      direction: [5.5571055162, 6.161594657, 6.6235756211],
      "speed factor": 1.4554443068,
      "Uz factor": 0.9926887229,
      "Directional variance factor": 0.4523026972,
      "Velocity stability factor": 0.9774989543,
      "Direction stability factor": 0.997037583,
      score: 0.8559692044,
    },
    "112.5": {
      velocity: [0.9547001355, 1.0472650861, 1.0957529287],
      direction: [0.4831834367, 3.7115583179, 6.9548665474],
      "speed factor": 1.0008842497,
      "Uz factor": 0.9974174959,
      "Directional variance factor": 0.6700837051,
      "Velocity stability factor": 0.8857709487,
      "Direction stability factor": 0.9820231025,
      score: 0.8799752147,
    },
    "135.0": {
      velocity: [1.1285078249, 1.1541361327, 1.1789688875],
      direction: [-2.7484941885, -1.3157550482, 0.8693899898],
      "speed factor": 1.3349960065,
      "Uz factor": 0.9999999796,
      "Directional variance factor": 0.8830439957,
      "Velocity stability factor": 0.9485985916,
      "Direction stability factor": 0.9899503217,
      score: 0.9528858077,
    },
    "157.5": {
      velocity: [1.0851755676, 1.0940314596, 1.103977831],
      direction: [-4.9954849852, -4.3880864654, -3.8213073591],
      "speed factor": 1.0493193029,
      "Uz factor": 0.9993904133,
      "Directional variance factor": 0.6099478697,
      "Velocity stability factor": 0.9859724936,
      "Direction stability factor": 0.9967383955,
      score: 0.8973492886,
    },
    "180.0": {
      velocity: [0.4005904858, 0.8356247672, 1.0421515147],
      direction: [-32.2487800001, -7.1249566966, 4.1045658539],
      "speed factor": 1.1695665006,
      "Uz factor": 0.9999621428,
      "Directional variance factor": 0.3666705159,
      "Velocity stability factor": 0.3727447356,
      "Direction stability factor": 0.8990184837,
      score: 0.6343630547,
    },
    "202.5": {
      velocity: [0.1452657871, 0.5324826752, 0.9364615736],
      direction: [-24.9400858537, 15.380021774, 61.9692963514],
      "speed factor": 0.6332761004,
      "Uz factor": 0.9986393808,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3679280483,
      "Direction stability factor": 0.7585850494,
      score: 0.0,
    },
    "225.0": {
      velocity: [0.7584419203, 0.9996955277, 1.1381841907],
      direction: [-5.5078399091, 1.2979038572, 9.8301527327],
      "speed factor": 1.2105802337,
      "Uz factor": 0.9985934794,
      "Directional variance factor": 0.8846307683,
      "Velocity stability factor": 0.6432978663,
      "Direction stability factor": 0.9573944649,
      score: 0.8606793911,
    },
    "247.5": {
      velocity: [0.4476284422, 0.9042003094, 1.3730119004],
      direction: [-25.6740614556, -5.9549341592, 27.9335246228],
      "speed factor": 1.1328396562,
      "Uz factor": 0.9995376279,
      "Directional variance factor": 0.4706725192,
      "Velocity stability factor": 0.2662036303,
      "Direction stability factor": 0.8510900387,
      score: 0.6097640567,
    },
    "270.0": {
      velocity: [0.9048404698, 1.0087050768, 1.0473494821],
      direction: [-4.48316107, 6.8267689077, 10.3244226512],
      "speed factor": 1.4146644324,
      "Uz factor": 0.9994926836,
      "Directional variance factor": 0.3931760971,
      "Velocity stability factor": 0.8784868196,
      "Direction stability factor": 0.958867823,
      score: 0.7973496407,
    },
    "292.5": {
      velocity: [0.4229889395, 0.5028774997, 0.5598061794],
      direction: [6.8596120382, 10.0181095733, 12.9895996868],
      "speed factor": 0.5026302232,
      "Uz factor": 0.9908550627,
      "Directional variance factor": 0.1095013713,
      "Velocity stability factor": 0.8605156855,
      "Direction stability factor": 0.9829722565,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4269449998, 0.4582516205, 0.4821338179],
      direction: [-4.4081499826, -2.2945277464, 0.5549130889],
      "speed factor": 0.5694899246,
      "Uz factor": 0.9948469292,
      "Directional variance factor": 0.7960419781,
      "Velocity stability factor": 0.9111697571,
      "Direction stability factor": 0.9862137137,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.4662110775, 0.5749742387, 0.657783269],
      direction: [-17.0289451933, -9.2436247386, 2.5723165263],
      "speed factor": 0.5748410305,
      "Uz factor": 0.9970034451,
      "Directional variance factor": 0.1783444677,
      "Velocity stability factor": 0.8090725695,
      "Direction stability factor": 0.9455520508,
      score: 0.0,
    },
    id: "IDN:3588.0",
    zone: 1,
  },
  {
    position: [-17.45866, 6.2679947, 21.1598299],
    "overall score": 0.5023761048,
    "0": {
      velocity: [0.719175378, 0.8935440401, 1.0530992954],
      direction: [-5.2468362288, 1.0359328765, 7.6096890227],
      "speed factor": 1.48004642,
      "Uz factor": 0.9998167851,
      "Directional variance factor": 0.9079170776,
      "Velocity stability factor": 0.6290759631,
      "Direction stability factor": 0.9642874299,
      score: 0.8663919751,
    },
    "22.5": {
      velocity: [0.269623845, 0.8283661502, 1.2678299291],
      direction: [-29.9754319774, 7.1137248933, 47.9721615474],
      "speed factor": 1.0960143589,
      "Uz factor": 0.9973880194,
      "Directional variance factor": 0.3676688984,
      "Velocity stability factor": 0.1565974716,
      "Direction stability factor": 0.7834789069,
      score: 0.5228060459,
    },
    "45.0": {
      velocity: [0.3337199073, 0.7207886147, 1.0634448191],
      direction: [-34.9667016294, -14.9339714503, 32.9578257683],
      "speed factor": 1.1617598764,
      "Uz factor": 0.9999665472,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2134476795,
      "Direction stability factor": 0.8113207572,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7524868602, 0.9092061388, 0.9791987287],
      direction: [-5.5757340363, -3.645861616, 0.1737286018],
      "speed factor": 1.235630576,
      "Uz factor": 0.9994246355,
      "Directional variance factor": 0.6759234119,
      "Velocity stability factor": 0.7696420656,
      "Direction stability factor": 0.9840292704,
      score: 0.8534060046,
    },
    "90.0": {
      velocity: [0.3081452116, 0.7488834406, 1.0568316531],
      direction: [-18.6507164391, 2.5624202431, 40.9311919833],
      "speed factor": 1.0818258263,
      "Uz factor": 0.9992970622,
      "Directional variance factor": 0.7722293117,
      "Velocity stability factor": 0.2390080846,
      "Direction stability factor": 0.8344946988,
      score: 0.6700566985,
    },
    "112.5": {
      velocity: [1.0473378855, 1.1744762038, 1.2277488041],
      direction: [1.059678976, 12.7407763954, 16.3683214969],
      "speed factor": 1.1224614948,
      "Uz factor": 0.9968615941,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8538974833,
      "Direction stability factor": 0.957475993,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.1998809337, 1.2130519941, 1.2218869499],
      direction: [2.5942567116, 3.0796406339, 3.5211658989],
      "speed factor": 1.4031443276,
      "Uz factor": 0.9951145739,
      "Directional variance factor": 0.7262541659,
      "Velocity stability factor": 0.9775838999,
      "Direction stability factor": 0.9974252523,
      score: 0.9246721426,
    },
    "157.5": {
      velocity: [0.2999354169, 0.7298721806, 0.9988957556],
      direction: [-59.7362012818, -14.0400076928, 9.796585481],
      "speed factor": 0.700042911,
      "Uz factor": 0.9973586259,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4785377471,
      "Direction stability factor": 0.8068533701,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1014588068, 0.4037851083, 0.8427580286],
      direction: [-147.7357204248, 4.0315464898, 169.302224757],
      "speed factor": 0.5651502381,
      "Uz factor": 0.997605185,
      "Directional variance factor": 0.641640312,
      "Velocity stability factor": 0.2752305418,
      "Direction stability factor": 0.1193390412,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1468494873, 1.1935826119, 1.2217680594],
      direction: [-3.1301240324, -0.282941407, 2.3128949032],
      "speed factor": 1.4195153706,
      "Uz factor": 0.9906548207,
      "Directional variance factor": 0.9748496527,
      "Velocity stability factor": 0.9401489127,
      "Direction stability factor": 0.984880503,
      score: 0.9711898928,
    },
    "225.0": {
      velocity: [0.766572876, 0.9570627609, 1.2194848606],
      direction: [-29.3491919605, -6.4594451788, 4.2109353725],
      "speed factor": 1.1589541301,
      "Uz factor": 0.9952278919,
      "Directional variance factor": 0.4258270952,
      "Velocity stability factor": 0.5745675846,
      "Direction stability factor": 0.9067774241,
      score: 0.703487382,
    },
    "247.5": {
      velocity: [0.2645645045, 0.5391013764, 0.8711011473],
      direction: [-49.9464985864, 3.4447469096, 76.9640458135],
      "speed factor": 0.675420492,
      "Uz factor": 0.9998085191,
      "Directional variance factor": 0.6938002747,
      "Velocity stability factor": 0.5190378836,
      "Direction stability factor": 0.64747071,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.4926960617, 0.965848678, 1.0714790068],
      direction: [-5.4325553654, 1.9698589337, 6.5000714773],
      "speed factor": 1.3545602209,
      "Uz factor": 0.9995647771,
      "Directional variance factor": 0.8249014281,
      "Velocity stability factor": 0.5064890616,
      "Direction stability factor": 0.9668538143,
      score: 0.8162745296,
    },
    "292.5": {
      velocity: [1.1420700853, 1.1663765585, 1.1834071913],
      direction: [2.0997890833, 3.6612732309, 4.6399521853],
      "speed factor": 1.1658030242,
      "Uz factor": 0.9988846317,
      "Directional variance factor": 0.6745534906,
      "Velocity stability factor": 0.9578570807,
      "Direction stability factor": 0.9929439914,
      score: 0.9045746385,
    },
    "315.0": {
      velocity: [0.8344957467, 0.8390072771, 0.8462531493],
      direction: [7.743331233, 8.4788029053, 8.9620765436],
      "speed factor": 1.0426721251,
      "Uz factor": 0.9979739063,
      "Directional variance factor": 0.2463286306,
      "Velocity stability factor": 0.9810756426,
      "Direction stability factor": 0.9966145964,
      score: 0.8051583665,
    },
    "337.5": {
      velocity: [0.5540763063, 0.8772479414, 1.079014945],
      direction: [-29.3951665172, -1.6214706282, 7.7433696445],
      "speed factor": 0.8770447034,
      "Uz factor": 0.9983189576,
      "Directional variance factor": 0.8558692775,
      "Velocity stability factor": 0.4768281102,
      "Direction stability factor": 0.8968373996,
      score: 0.0,
    },
    id: "IDN:1898.0",
    zone: 1,
  },
  {
    position: [-27.8936219, 9.3169522, 20.8740369],
    "overall score": 0.501090706,
    "0": {
      velocity: [0.7117582648, 0.8189913781, 0.9218136694],
      direction: [-3.0608222745, 5.4562535496, 11.2334096373],
      "speed factor": 1.3565590532,
      "Uz factor": 0.9997225141,
      "Directional variance factor": 0.5149996845,
      "Velocity stability factor": 0.7666696076,
      "Direction stability factor": 0.9602938002,
      score: 0.8005642231,
    },
    "22.5": {
      velocity: [0.1985093715, 0.7023610112, 1.1382035023],
      direction: [-29.902755549, 14.8340317436, 88.9653520952],
      "speed factor": 0.9292964872,
      "Uz factor": 0.9992536906,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2060352882,
      "Direction stability factor": 0.6698108121,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.5518996005, 0.8101553787, 1.0715301884],
      direction: [-21.1773907347, -7.7516425555, 27.6727632133],
      "speed factor": 1.3058003323,
      "Uz factor": 0.9986341469,
      "Directional variance factor": 0.3109651062,
      "Velocity stability factor": 0.4399031223,
      "Direction stability factor": 0.8643051279,
      score: 0.6198696211,
    },
    "67.5": {
      velocity: [0.5348506742, 0.8384095426, 0.941772176],
      direction: [-11.8184362061, -4.761230432, 10.4024763436],
      "speed factor": 1.1394164886,
      "Uz factor": 0.9998814646,
      "Directional variance factor": 0.5767795172,
      "Velocity stability factor": 0.5865342331,
      "Direction stability factor": 0.9382752429,
      score: 0.759966059,
    },
    "90.0": {
      velocity: [0.5059777495, 0.8255124817, 1.0175870331],
      direction: [-13.3303480492, 3.0196400194, 35.3192932586],
      "speed factor": 1.1925229938,
      "Uz factor": 0.9981568514,
      "Directional variance factor": 0.7315875538,
      "Velocity stability factor": 0.4799818629,
      "Direction stability factor": 0.8648621075,
      score: 0.7353234079,
    },
    "112.5": {
      velocity: [0.6140031234, 0.9925593008, 1.1620062315],
      direction: [-10.1583700174, 10.8296976193, 15.7357578763],
      "speed factor": 0.9486012512,
      "Uz factor": 0.9998141621,
      "Directional variance factor": 0.0373602116,
      "Velocity stability factor": 0.5562096026,
      "Direction stability factor": 0.928071867,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.4705382181, 0.6155316429, 0.7229850773],
      direction: [0.9480932907, 4.1608329926, 7.1397837723],
      "speed factor": 0.7119890469,
      "Uz factor": 0.9957078501,
      "Directional variance factor": 0.6301481784,
      "Velocity stability factor": 0.7428487739,
      "Direction stability factor": 0.9828008598,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.2082285366, 0.6270246668, 0.9373508999],
      direction: [-66.1041806273, -17.0271731755, 24.5866277307],
      "speed factor": 0.6013986897,
      "Uz factor": 0.9966448313,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4560352439,
      "Direction stability factor": 0.7480810879,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.170121523, 0.5230419085, 0.8958108321],
      direction: [-84.5655239236, -0.9249068306, 55.4973663978],
      "speed factor": 0.7320657772,
      "Uz factor": 0.9949100347,
      "Directional variance factor": 0.9177860595,
      "Velocity stability factor": 0.290492379,
      "Direction stability factor": 0.6109364158,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.196452872, 1.2183019679, 1.2354437329],
      direction: [-10.6849522199, -9.0867565882, -7.9313632064],
      "speed factor": 1.4489138433,
      "Uz factor": 0.9999163973,
      "Directional variance factor": 0.1922883033,
      "Velocity stability factor": 0.968850909,
      "Direction stability factor": 0.9923511416,
      score: 0.7864603739,
    },
    "225.0": {
      velocity: [0.6144150257, 0.8431836007, 1.0897548565],
      direction: [-34.8775297715, -8.7597250119, 6.8897314919],
      "speed factor": 1.0210522824,
      "Uz factor": 0.9964182087,
      "Directional variance factor": 0.2213577767,
      "Velocity stability factor": 0.5535005051,
      "Direction stability factor": 0.8839798298,
      score: 0.6357044854,
    },
    "247.5": {
      velocity: [0.5626700623, 0.7645838346, 1.013889135],
      direction: [-9.0524393346, 4.5809769224, 24.1301187918],
      "speed factor": 0.9579192568,
      "Uz factor": 0.9952239425,
      "Directional variance factor": 0.5928020513,
      "Velocity stability factor": 0.6421992261,
      "Direction stability factor": 0.9078262274,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.9054779842, 0.9877484342, 1.0427200966],
      direction: [-4.485955466, -2.3157495156, 0.1949395866],
      "speed factor": 1.3852736643,
      "Uz factor": 0.9994487285,
      "Directional variance factor": 0.7941555986,
      "Velocity stability factor": 0.8829777479,
      "Direction stability factor": 0.9869975137,
      score: 0.9127820935,
    },
    "292.5": {
      velocity: [1.2151936349, 1.2272951124, 1.2341536125],
      direction: [-3.9410373732, -3.4304252403, -2.8350169506],
      "speed factor": 1.226691623,
      "Uz factor": 0.9991758039,
      "Directional variance factor": 0.695073312,
      "Velocity stability factor": 0.9806704222,
      "Direction stability factor": 0.996927721,
      score: 0.9173997941,
    },
    "315.0": {
      velocity: [0.9013526537, 0.9086885809, 0.9172351693],
      direction: [2.6535708426, 2.922678441, 3.1249122827],
      "speed factor": 1.1292682192,
      "Uz factor": 0.9999952857,
      "Directional variance factor": 0.7402063608,
      "Velocity stability factor": 0.9744359861,
      "Direction stability factor": 0.9986907182,
      score: 0.9280059458,
    },
    "337.5": {
      velocity: [0.992391288, 1.1481313035, 1.2152057063],
      direction: [-2.5234772126, -0.7625284082, 1.9142464736],
      "speed factor": 1.1478653081,
      "Uz factor": 0.9983361384,
      "Directional variance factor": 0.9322196971,
      "Velocity stability factor": 0.7779354925,
      "Direction stability factor": 0.9876729898,
      score: 0.9213752923,
    },
    id: "IDN:2148.0",
    zone: 1,
  },
  {
    position: [-15.45866, 4.2679947, 21.1598299],
    "overall score": 0.5004161063,
    "0": {
      velocity: [0.2846569319, 0.7902215454, 0.9761894317],
      direction: [-7.3257717927, -0.0587072439, 10.9395956792],
      "speed factor": 1.3089053441,
      "Uz factor": 0.9998121639,
      "Directional variance factor": 0.9947815783,
      "Velocity stability factor": 0.2318429043,
      "Direction stability factor": 0.9492628681,
      score: 0.7812875547,
    },
    "22.5": {
      velocity: [0.2363897733, 0.824267759, 1.1994084885],
      direction: [-31.9155396548, 5.1509896236, 96.3611473478],
      "speed factor": 1.0905917621,
      "Uz factor": 0.9970686995,
      "Directional variance factor": 0.5421342557,
      "Velocity stability factor": 0.1863279214,
      "Direction stability factor": 0.6436758694,
      score: 0.503953479,
    },
    "45.0": {
      velocity: [0.4048764236, 0.6998940854, 1.0643894493],
      direction: [-25.400509547, -12.8524507921, 26.3156699413],
      "speed factor": 1.1280822832,
      "Uz factor": 0.9986643474,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2891273241,
      "Direction stability factor": 0.8563439459,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7479028363, 0.9125859251, 0.9743032287],
      direction: [-6.3944900843, -2.8060275682, 1.3249019253],
      "speed factor": 1.2402237779,
      "Uz factor": 0.9994950567,
      "Directional variance factor": 0.7505753273,
      "Velocity stability factor": 0.7699585511,
      "Direction stability factor": 0.9785572444,
      score: 0.8694120918,
    },
    "90.0": {
      velocity: [0.1535583216, 0.6730805425, 0.9436312699],
      direction: [-21.3964321157, 3.22195287, 53.0152330062],
      "speed factor": 0.9723220926,
      "Uz factor": 0.999965833,
      "Directional variance factor": 0.7136041893,
      "Velocity stability factor": 0.1969413456,
      "Direction stability factor": 0.7933009302,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1150646231, 1.1810377346, 1.2168974769],
      direction: [4.5813590079, 10.2973098421, 12.9462519161],
      "speed factor": 1.1287324314,
      "Uz factor": 0.9927716835,
      "Directional variance factor": 0.0846835696,
      "Velocity stability factor": 0.9175325067,
      "Direction stability factor": 0.9767641864,
      score: 0.7389361123,
    },
    "135.0": {
      velocity: [1.199102053, 1.2088635687, 1.2160510956],
      direction: [0.7586483042, 1.1182235253, 1.5036879907],
      "speed factor": 1.3982995514,
      "Uz factor": 0.9902288115,
      "Directional variance factor": 0.9006023533,
      "Velocity stability factor": 0.9827351107,
      "Direction stability factor": 0.9979304453,
      score: 0.9697995887,
    },
    "157.5": {
      velocity: [0.3039413142, 0.7283217993, 1.0196225195],
      direction: [-68.4536757832, -14.0202600963, 17.6575845695],
      "speed factor": 0.6985558925,
      "Uz factor": 0.9965716468,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4660630755,
      "Direction stability factor": 0.7608020546,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0974068129, 0.3787292194, 0.8132744392],
      direction: [-169.0893739616, 3.420167429, 150.3498965412],
      "speed factor": 0.530081234,
      "Uz factor": 0.999678128,
      "Directional variance factor": 0.6959851174,
      "Velocity stability factor": 0.3000950543,
      "Direction stability factor": 0.112668693,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1226663533, 1.1510221714, 1.1783310469],
      direction: [-2.6793152396, -1.8564606692, -0.9923726727],
      "speed factor": 1.368898682,
      "Uz factor": 0.9835440185,
      "Directional variance factor": 0.8349812738,
      "Velocity stability factor": 0.9555304867,
      "Direction stability factor": 0.9953140484,
      score: 0.9452849643,
    },
    "225.0": {
      velocity: [0.9543044229, 1.0589024008, 1.20323434],
      direction: [-17.5982253306, -8.645416781, -3.4406056417],
      "speed factor": 1.2822767334,
      "Uz factor": 0.9997884867,
      "Directional variance factor": 0.2315185084,
      "Velocity stability factor": 0.76617343,
      "Direction stability factor": 0.9606732786,
      score: 0.7297596239,
    },
    "247.5": {
      velocity: [0.1394880728, 0.4568539966, 0.8029015845],
      direction: [-101.0747473036, -0.0104481962, 99.0958271954],
      "speed factor": 0.5723757436,
      "Uz factor": 0.9999941494,
      "Directional variance factor": 0.9990712714,
      "Velocity stability factor": 0.4739365372,
      "Direction stability factor": 0.4439706264,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.4699398796, 0.9207804447, 1.044965989],
      direction: [-4.3707773244, 2.6322429082, 12.5890847416],
      "speed factor": 1.2913540092,
      "Uz factor": 0.9989303218,
      "Directional variance factor": 0.7660228526,
      "Velocity stability factor": 0.5096924033,
      "Direction stability factor": 0.952889272,
      score: 0.79537345,
    },
    "292.5": {
      velocity: [1.119709846, 1.1603704821, 1.1903398893],
      direction: [2.4912708343, 4.9684520856, 6.1850499761],
      "speed factor": 1.1597999011,
      "Uz factor": 0.9986922457,
      "Directional variance factor": 0.5583598146,
      "Velocity stability factor": 0.9279931156,
      "Direction stability factor": 0.9897395024,
      score: 0.8664579837,
    },
    "315.0": {
      velocity: [0.8345553262, 0.8407120854, 0.849887199],
      direction: [7.5478285999, 8.3493453051, 8.9134611551],
      "speed factor": 1.0447907672,
      "Uz factor": 0.9988341961,
      "Directional variance factor": 0.2578359729,
      "Velocity stability factor": 0.9753222839,
      "Direction stability factor": 0.9962065762,
      score: 0.8063928523,
    },
    "337.5": {
      velocity: [0.5928643723, 0.8248855993, 1.0502662262],
      direction: [-10.7767159673, -0.2998219793, 7.5612618124],
      "speed factor": 0.8246944925,
      "Uz factor": 0.998427315,
      "Directional variance factor": 0.9733491574,
      "Velocity stability factor": 0.5441375913,
      "Direction stability factor": 0.9490611728,
      score: 0.0,
    },
    id: "IDN:1874.0",
    zone: 1,
  },
  {
    position: [-13.45866, 4.2679947, 21.1598299],
    "overall score": 0.4984478703,
    "0": {
      velocity: [0.2657590473, 0.7919595822, 0.9669303256],
      direction: [-23.2411851555, -1.771996162, 7.1440296698],
      "speed factor": 1.3117841895,
      "Uz factor": 0.9997650229,
      "Directional variance factor": 0.84248923,
      "Velocity stability factor": 0.2211361103,
      "Direction stability factor": 0.9155966255,
      score: 0.7237046478,
    },
    "22.5": {
      velocity: [0.2286773279, 0.8219438687, 1.1970151387],
      direction: [-37.8153315367, 4.2557718936, 44.4480157799],
      "speed factor": 1.0875170142,
      "Uz factor": 0.9974846348,
      "Directional variance factor": 0.621709165,
      "Velocity stability factor": 0.1818337205,
      "Direction stability factor": 0.7714907019,
      score: 0.5866310723,
    },
    "45.0": {
      velocity: [0.3493208582, 0.6856011352, 1.0274578341],
      direction: [-26.3929614035, -12.3134485616, 47.640902571],
      "speed factor": 1.1050450491,
      "Uz factor": 0.9987686768,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2690530316,
      "Direction stability factor": 0.7943503778,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7157813787, 0.9101781157, 0.9704207869],
      direction: [-4.7508812356, -2.7079878852, 0.6115416611],
      "speed factor": 1.2369515134,
      "Uz factor": 0.9995069532,
      "Directional variance factor": 0.7592899658,
      "Velocity stability factor": 0.7412653847,
      "Direction stability factor": 0.9851043808,
      score: 0.867691028,
    },
    "90.0": {
      velocity: [0.1280772006, 0.6612139327, 0.9451246761],
      direction: [-20.1867260293, 3.0214112974, 39.2441618965],
      "speed factor": 0.9551797654,
      "Uz factor": 0.9999760445,
      "Directional variance factor": 0.7314301069,
      "Velocity stability factor": 0.1695234628,
      "Direction stability factor": 0.8349142002,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.0814933521, 1.1769908042, 1.2396247242],
      direction: [6.7084294283, 10.8764105113, 14.1510210743],
      "speed factor": 1.1248647296,
      "Uz factor": 0.9927595736,
      "Directional variance factor": 0.0332079546,
      "Velocity stability factor": 0.8719401707,
      "Direction stability factor": 0.9793261343,
      score: 0.7159500985,
    },
    "135.0": {
      velocity: [1.2053823286, 1.2128246641, 1.2192794977],
      direction: [0.5130932622, 0.8932671786, 1.258349412],
      "speed factor": 1.4028813736,
      "Uz factor": 0.989332342,
      "Directional variance factor": 0.920598473,
      "Velocity stability factor": 0.985843856,
      "Direction stability factor": 0.997929844,
      score: 0.9755755043,
    },
    "157.5": {
      velocity: [0.2646789807, 0.7819551833, 1.0464421415],
      direction: [-59.2703018485, -13.3956306194, 21.3512535425],
      "speed factor": 0.7499973247,
      "Uz factor": 0.9976500536,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4167623591,
      "Direction stability factor": 0.776051235,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.02632859, 0.364664686, 0.7503144884],
      direction: [-177.5973751856, 5.8014113175, 177.6475056168],
      "speed factor": 0.5103960743,
      "Uz factor": 0.999967699,
      "Directional variance factor": 0.484318994,
      "Velocity stability factor": 0.2921578064,
      "Direction stability factor": 0.0132086644,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.10377449, 1.1393238626, 1.1618810731],
      direction: [-2.3553019711, -1.3682820767, -0.2573610539],
      "speed factor": 1.3549860051,
      "Uz factor": 0.9809810758,
      "Directional variance factor": 0.8783749265,
      "Velocity stability factor": 0.9535797055,
      "Direction stability factor": 0.9941723863,
      score: 0.9550748512,
    },
    "225.0": {
      velocity: [1.0342286336, 1.09627098, 1.192156744],
      direction: [-15.4450570722, -9.5745971636, -5.8145384746],
      "speed factor": 1.3275281747,
      "Uz factor": 0.9997758474,
      "Directional variance factor": 0.1489246966,
      "Velocity stability factor": 0.8516538759,
      "Direction stability factor": 0.9732485595,
      score: 0.7367689228,
    },
    "247.5": {
      velocity: [0.0606521072, 0.4257625542, 0.8498513533],
      direction: [-119.7846492915, -5.3265111033, 137.1516379841],
      "speed factor": 0.5334224071,
      "Uz factor": 0.9999518614,
      "Directional variance factor": 0.5265323464,
      "Velocity stability factor": 0.3741928964,
      "Direction stability factor": 0.2862880909,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.4704274491, 0.9291671451, 1.0456755175],
      direction: [-6.2580729733, 3.7198630878, 10.6068861689],
      "speed factor": 1.3031159871,
      "Uz factor": 0.9986678031,
      "Directional variance factor": 0.6693455033,
      "Velocity stability factor": 0.5095031455,
      "Direction stability factor": 0.9531528913,
      score: 0.7712886078,
    },
    "292.5": {
      velocity: [1.1170185875, 1.1551197816, 1.17901284],
      direction: [3.6509727428, 5.5535156959, 6.6832831253],
      "speed factor": 1.1545517825,
      "Uz factor": 0.9989470611,
      "Directional variance factor": 0.5063541604,
      "Velocity stability factor": 0.93679725,
      "Direction stability factor": 0.9915769156,
      score: 0.8565763104,
    },
    "315.0": {
      velocity: [0.845042639, 0.8533492894, 0.8650756252],
      direction: [8.6589056452, 9.2125586529, 9.6024453408],
      "speed factor": 1.0604955896,
      "Uz factor": 0.9999666464,
      "Directional variance factor": 0.1811058975,
      "Velocity stability factor": 0.9677555149,
      "Direction stability factor": 0.9973790564,
      score: 0.7859048813,
    },
    "337.5": {
      velocity: [0.5819778499, 0.7776106944, 0.9538705779],
      direction: [-22.4395049684, -0.0938650984, 9.0811597171],
      "speed factor": 0.7774305401,
      "Uz factor": 0.999051633,
      "Directional variance factor": 0.9916564357,
      "Velocity stability factor": 0.6293589251,
      "Direction stability factor": 0.9124425981,
      score: 0.0,
    },
    id: "IDN:1875.0",
    zone: 1,
  },
  {
    position: [-13.45866, 8.2679947, 21.1598299],
    "overall score": 0.4973386226,
    "0": {
      velocity: [0.6577030895, 0.8940132353, 0.9813469481],
      direction: [-6.6396764348, -1.8858761434, 7.8855655888],
      "speed factor": 1.4808235844,
      "Uz factor": 0.9979509983,
      "Directional variance factor": 0.832366565,
      "Velocity stability factor": 0.6404950939,
      "Direction stability factor": 0.9596521055,
      score: 0.8480414675,
    },
    "22.5": {
      velocity: [0.1818200019, 0.8530498888, 1.2571721265],
      direction: [-42.1620536879, 5.6818213209, 58.0406352632],
      "speed factor": 1.128673506,
      "Uz factor": 0.9985191801,
      "Directional variance factor": 0.4949492159,
      "Velocity stability factor": 0.0914153747,
      "Direction stability factor": 0.7216591974,
      score: 0.5074207463,
    },
    "45.0": {
      velocity: [0.3610565893, 0.7339043935, 1.0565675181],
      direction: [-31.9808270781, -15.9873997033, 25.6039292194],
      "speed factor": 1.1828997574,
      "Uz factor": 0.9998090944,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2503260803,
      "Direction stability factor": 0.8400423436,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.6594258765, 0.8993346143, 0.9732066733],
      direction: [-7.0207013981, -4.4610282443, -0.8066408643],
      "speed factor": 1.2222149632,
      "Uz factor": 0.9993259591,
      "Directional variance factor": 0.6034641561,
      "Velocity stability factor": 0.681172862,
      "Direction stability factor": 0.9827387207,
      score: 0.8125286149,
    },
    "90.0": {
      velocity: [0.4208163794, 0.7836641146, 0.9809767156],
      direction: [-21.2845762332, 2.9444831791, 30.5427001632],
      "speed factor": 1.1320694682,
      "Uz factor": 0.9988924569,
      "Directional variance factor": 0.7382681619,
      "Velocity stability factor": 0.4306328209,
      "Direction stability factor": 0.8560353433,
      score: 0.7202429174,
    },
    "112.5": {
      velocity: [0.930856575, 1.1556488527, 1.2870307486],
      direction: [1.0488315866, 15.0274943277, 21.1102085276],
      "speed factor": 1.1044679616,
      "Uz factor": 0.9999425696,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7115587928,
      "Direction stability factor": 0.9442739529,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.1946738424, 1.209855426, 1.2248086228],
      direction: [2.5995925898, 3.6716123459, 4.7219617623],
      "speed factor": 1.3994468386,
      "Uz factor": 0.9959731424,
      "Directional variance factor": 0.6736344581,
      "Velocity stability factor": 0.9693036556,
      "Direction stability factor": 0.9941045301,
      score: 0.9077867935,
    },
    "157.5": {
      velocity: [0.4639080868, 0.846165313, 1.0799575775],
      direction: [-47.5064988885, -11.080508857, 4.5174486238],
      "speed factor": 0.8115832398,
      "Uz factor": 0.9984435673,
      "Directional variance factor": 0.0150658794,
      "Velocity stability factor": 0.5403937284,
      "Direction stability factor": 0.8554890347,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0505353528, 0.3790060529, 0.8230299108],
      direction: [-118.3294099956, 4.02784699, 150.4497812646],
      "speed factor": 0.5304686989,
      "Uz factor": 0.9991587274,
      "Directional variance factor": 0.6419691564,
      "Velocity stability factor": 0.2447308108,
      "Direction stability factor": 0.2533911354,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1052717278, 1.1720169921, 1.2104579475],
      direction: [-4.966376875, -3.2416085392, -0.2728159293],
      "speed factor": 1.3938676036,
      "Uz factor": 0.9945539323,
      "Directional variance factor": 0.7118570187,
      "Velocity stability factor": 0.9159686385,
      "Direction stability factor": 0.9869623307,
      score: 0.9004375797,
    },
    "225.0": {
      velocity: [0.928500161, 1.0504731357, 1.1910268286],
      direction: [-16.7716195877, -5.9051567076, 1.0863300917],
      "speed factor": 1.2720693238,
      "Uz factor": 0.9949106757,
      "Directional variance factor": 0.4750971815,
      "Velocity stability factor": 0.7534016363,
      "Direction stability factor": 0.9503945842,
      score: 0.7823219966,
    },
    "247.5": {
      velocity: [0.1670911023, 0.5657009947, 1.0199502289],
      direction: [-62.198029841, 3.8106230335, 79.3104361035],
      "speed factor": 0.7087461857,
      "Uz factor": 0.9983186914,
      "Directional variance factor": 0.6612779526,
      "Velocity stability factor": 0.3237128615,
      "Direction stability factor": 0.6069209279,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.8029345544, 0.9691305292, 1.0076828875],
      direction: [-2.6341286961, 4.3350754199, 13.9101961543],
      "speed factor": 1.3591628728,
      "Uz factor": 0.9992576807,
      "Directional variance factor": 0.6146599627,
      "Velocity stability factor": 0.825417209,
      "Direction stability factor": 0.9540435421,
      score: 0.837041064,
    },
    "292.5": {
      velocity: [1.1164679103, 1.1320829909, 1.1414895777],
      direction: [4.2275217276, 5.0359944725, 5.5948093926],
      "speed factor": 1.1315263196,
      "Uz factor": 0.9991913418,
      "Directional variance factor": 0.5523560469,
      "Velocity stability factor": 0.9744905676,
      "Direction stability factor": 0.9962019787,
      score: 0.879812643,
    },
    "315.0": {
      velocity: [0.8694541863, 0.8750625649, 0.882877581],
      direction: [9.7519945706, 10.4053418395, 10.8928847918],
      "speed factor": 1.0874796548,
      "Uz factor": 0.9996459462,
      "Directional variance factor": 0.0750807254,
      "Velocity stability factor": 0.9783941121,
      "Direction stability factor": 0.9968308605,
      score: 0.7617841396,
    },
    "337.5": {
      velocity: [0.5837620627, 0.7693404353, 0.9249059748],
      direction: [-7.8665762204, 0.5727538357, 10.1996821738],
      "speed factor": 0.769162197,
      "Uz factor": 0.9989200369,
      "Directional variance factor": 0.9490885479,
      "Velocity stability factor": 0.6600042519,
      "Direction stability factor": 0.9498159489,
      score: 0.0,
    },
    id: "IDN:1937.0",
    zone: 1,
  },
  {
    position: [-9.45866, 6.2679947, 21.1598299],
    "overall score": 0.496385412,
    "0": {
      velocity: [0.692587013, 0.9401881934, 1.0516059105],
      direction: [-10.9519987999, -3.9215107466, 4.0879607515],
      "speed factor": 1.5573067553,
      "Uz factor": 0.9998260437,
      "Directional variance factor": 0.651421267,
      "Velocity stability factor": 0.6012003577,
      "Direction stability factor": 0.9582223346,
      score: 0.7922665734,
    },
    "22.5": {
      velocity: [0.3654465327, 0.8398297403, 1.3016974199],
      direction: [-36.3134364546, 2.4190660254, 68.7190249661],
      "speed factor": 1.111181878,
      "Uz factor": 0.9978575199,
      "Directional variance factor": 0.7849719089,
      "Velocity stability factor": 0.2089445476,
      "Direction stability factor": 0.7082431627,
      score: 0.6026006955,
    },
    "45.0": {
      velocity: [0.2178112076, 0.6797813918, 1.0632299524],
      direction: [-31.0419669983, -14.2038308994, 34.8613224009],
      "speed factor": 1.0956648449,
      "Uz factor": 0.9994672794,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.0887441764,
      "Direction stability factor": 0.8169353072,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.5911458843, 0.8994554856, 0.9722265659],
      direction: [-4.6602435512, -2.8444200546, 7.176826732],
      "speed factor": 1.2223792299,
      "Uz factor": 0.9993542165,
      "Directional variance factor": 0.7471626618,
      "Velocity stability factor": 0.612790635,
      "Direction stability factor": 0.9671192492,
      score: 0.8235479488,
    },
    "90.0": {
      velocity: [0.3697066012, 0.7367614565, 0.9963007756],
      direction: [-22.0352713652, 2.9318965818, 43.0063898764],
      "speed factor": 1.0643145892,
      "Uz factor": 0.9994196449,
      "Directional variance factor": 0.7393869705,
      "Velocity stability factor": 0.3631070705,
      "Direction stability factor": 0.8193287188,
      score: 0.6852878696,
    },
    "112.5": {
      velocity: [1.0135684508, 1.1655565227, 1.3469153283],
      direction: [8.832730228, 15.0704675625, 23.0948660063],
      "speed factor": 1.1139368448,
      "Uz factor": 0.9985108189,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7300450654,
      "Direction stability factor": 0.9603829562,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.2141275719, 1.226277022, 1.2388935548],
      direction: [1.8579324578, 2.6057377127, 3.2189784466],
      "speed factor": 1.4184417945,
      "Uz factor": 0.9916286318,
      "Directional variance factor": 0.76837887,
      "Velocity stability factor": 0.974772501,
      "Direction stability factor": 0.9962193167,
      score: 0.9338975011,
    },
    "157.5": {
      velocity: [0.4943899506, 0.9063302528, 1.1425063428],
      direction: [-35.6723215907, -10.8288219738, -0.4594087131],
      "speed factor": 0.8692892885,
      "Uz factor": 0.9992172334,
      "Directional variance factor": 0.0374380468,
      "Velocity stability factor": 0.5164700839,
      "Direction stability factor": 0.9021863531,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0574679142, 0.3646108084, 0.8366138729],
      direction: [-144.8077201721, 0.7830609556, 159.8018226412],
      "speed factor": 0.5103206655,
      "Uz factor": 0.994760793,
      "Directional variance factor": 0.9303945817,
      "Velocity stability factor": 0.2382277254,
      "Direction stability factor": 0.1538623811,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0985856994, 1.1402143099, 1.1733646991],
      direction: [-2.2817186228, -0.896676955, 0.5381775012],
      "speed factor": 1.3560450048,
      "Uz factor": 0.987344263,
      "Directional variance factor": 0.9202953818,
      "Velocity stability factor": 0.9402604145,
      "Direction stability factor": 0.9921669552,
      score: 0.9612224267,
    },
    "225.0": {
      velocity: [1.1089769063, 1.1480391935, 1.2068531378],
      direction: [-14.3440566007, -10.5743892544, -8.1486780972],
      "speed factor": 1.3902168376,
      "Uz factor": 0.9995509372,
      "Directional variance factor": 0.0600542885,
      "Velocity stability factor": 0.9080622218,
      "Direction stability factor": 0.9827906153,
      score: 0.7334244352,
    },
    "247.5": {
      velocity: [0.0684752491, 0.4218076081, 0.8736410639],
      direction: [-146.4034869751, -5.0670199342, 79.3548385796],
      "speed factor": 0.5284673991,
      "Uz factor": 0.9981075775,
      "Directional variance factor": 0.5495982281,
      "Velocity stability factor": 0.3615319718,
      "Direction stability factor": 0.3728935401,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.7004321448, 0.9590858189, 1.0311380675],
      direction: [0.2050131715, 5.9403729461, 11.2687944389],
      "speed factor": 1.345075609,
      "Uz factor": 0.999107846,
      "Directional variance factor": 0.4719668492,
      "Velocity stability factor": 0.7180169326,
      "Direction stability factor": 0.9692672743,
      score: 0.7821295826,
    },
    "292.5": {
      velocity: [1.1179260896, 1.1320855102, 1.1461044731],
      direction: [4.4682990888, 5.8293989311, 6.5152651108],
      "speed factor": 1.1315288376,
      "Uz factor": 0.9995282981,
      "Directional variance factor": 0.4818312061,
      "Velocity stability factor": 0.9712723155,
      "Direction stability factor": 0.9943139833,
      score: 0.860432872,
    },
    "315.0": {
      velocity: [0.8550232082, 0.8641893233, 0.8741426016],
      direction: [9.1936206929, 10.0304825533, 10.6697662422],
      "speed factor": 1.0739669879,
      "Uz factor": 0.9990920595,
      "Directional variance factor": 0.1084015508,
      "Velocity stability factor": 0.969226006,
      "Direction stability factor": 0.9958995957,
      score: 0.767356687,
    },
    "337.5": {
      velocity: [0.4198273327, 0.7000835273, 0.8314975844],
      direction: [-21.712940336, 2.0539710616, 10.8799336373],
      "speed factor": 0.6999213342,
      "Uz factor": 0.9993651443,
      "Directional variance factor": 0.8174247945,
      "Velocity stability factor": 0.5897152778,
      "Direction stability factor": 0.909464239,
      score: 0.0,
    },
    id: "IDN:1902.0",
    zone: 1,
  },
  {
    position: [-25.8936219, 7.3169522, 20.8740369],
    "overall score": 0.4963517423,
    "0": {
      velocity: [0.5999624345, 0.7776199232, 0.9267515715],
      direction: [-3.9787663302, 5.5949879217, 16.614538137],
      "speed factor": 1.2880322981,
      "Uz factor": 0.999983221,
      "Directional variance factor": 0.5026677403,
      "Velocity stability factor": 0.6370013059,
      "Direction stability factor": 0.9427963765,
      score: 0.7563154498,
    },
    "22.5": {
      velocity: [0.2491793825, 0.718182801, 1.1746474642],
      direction: [-53.1164839213, 13.409174983, 64.6185732675],
      "speed factor": 0.9502303565,
      "Uz factor": 0.9979537414,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2180551365,
      "Direction stability factor": 0.6729581745,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3726575663, 0.6977209979, 1.0210796398],
      direction: [-22.8824444005, -7.7058575839, 20.5107866009],
      "speed factor": 1.1245797226,
      "Uz factor": 0.9996582935,
      "Directional variance factor": 0.3150348814,
      "Velocity stability factor": 0.3010819854,
      "Direction stability factor": 0.8794632472,
      score: 0.5937608403,
    },
    "67.5": {
      velocity: [0.6396024543, 0.86401682, 0.9576824649],
      direction: [-8.1983934101, -4.3689772839, 0.5788660908],
      "speed factor": 1.1742173259,
      "Uz factor": 0.9991386848,
      "Directional variance factor": 0.6116464637,
      "Velocity stability factor": 0.6768045066,
      "Direction stability factor": 0.9756187236,
      score: 0.8099221044,
    },
    "90.0": {
      velocity: [0.4302121464, 0.7816524824, 0.9851513268],
      direction: [-21.8678985857, 2.3387681973, 43.9552774391],
      "speed factor": 1.1291634943,
      "Uz factor": 0.9987561214,
      "Directional variance factor": 0.7921094936,
      "Velocity stability factor": 0.4359397921,
      "Direction stability factor": 0.8171578444,
      score: 0.7155912436,
    },
    "112.5": {
      velocity: [0.6610730038, 1.0731099488, 1.18943072],
      direction: [-0.1452575616, 10.5864661834, 15.816739695],
      "speed factor": 1.025584506,
      "Uz factor": 0.9980269015,
      "Directional variance factor": 0.0589807837,
      "Velocity stability factor": 0.5721190676,
      "Direction stability factor": 0.9556611187,
      score: 0.6356055222,
    },
    "135.0": {
      velocity: [0.7221717987, 0.7757431924, 0.8130921077],
      direction: [0.5365472427, 1.913650536, 3.008998074],
      "speed factor": 0.8973066821,
      "Uz factor": 0.9903309239,
      "Directional variance factor": 0.8298977301,
      "Velocity stability factor": 0.9073853839,
      "Direction stability factor": 0.993132081,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.1494185804, 0.6278208717, 0.9769634835],
      direction: [-65.561833893, -18.0940867947, 20.9845868091],
      "speed factor": 0.6021623543,
      "Uz factor": 0.9949886433,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3826067008,
      "Direction stability factor": 0.7595932758,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0384081918, 0.464271336, 0.8402028225],
      direction: [-87.1978922266, -1.3515814333, 79.6804615711],
      "speed factor": 0.6498086499,
      "Uz factor": 0.997260831,
      "Directional variance factor": 0.8798594282,
      "Velocity stability factor": 0.2160840819,
      "Direction stability factor": 0.5364490172,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1878524499, 1.2178574071, 1.245632471],
      direction: [-11.2367237065, -10.3370567194, -9.5006965798],
      "speed factor": 1.4483851317,
      "Uz factor": 0.9928768229,
      "Directional variance factor": 0.0811505138,
      "Velocity stability factor": 0.95384059,
      "Direction stability factor": 0.9951777024,
      score: 0.7563366272,
    },
    "225.0": {
      velocity: [0.3852254159, 0.8113302639, 1.0804972857],
      direction: [-92.2000148404, -20.3752729692, -0.0465287592],
      "speed factor": 0.9824795183,
      "Uz factor": 0.9994408324,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3469124223,
      "Direction stability factor": 0.7440180942,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3285091428, 0.6834418945, 1.0126321053],
      direction: [-23.5332943177, 4.4985351324, 30.9106319062],
      "speed factor": 0.8562594734,
      "Uz factor": 0.9993230561,
      "Directional variance factor": 0.6001302105,
      "Velocity stability factor": 0.4575146748,
      "Direction stability factor": 0.8487668716,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.898775541, 0.9943183528, 1.0555011615],
      direction: [-4.2471604067, -1.3691159255, 1.7972150754],
      "speed factor": 1.3944876857,
      "Uz factor": 0.9991223279,
      "Directional variance factor": 0.8783008066,
      "Velocity stability factor": 0.8663647423,
      "Direction stability factor": 0.9832100681,
      score: 0.9277714213,
    },
    "292.5": {
      velocity: [1.1577944777, 1.1893455244, 1.2061286472],
      direction: [-3.3447790242, -2.2097454745, -1.0920468497],
      "speed factor": 1.1887606957,
      "Uz factor": 0.9982243879,
      "Directional variance factor": 0.80357818,
      "Velocity stability factor": 0.9507236186,
      "Direction stability factor": 0.9937424106,
      score: 0.935446655,
    },
    "315.0": {
      velocity: [0.8944345374, 0.902100697, 0.9110451702],
      direction: [2.5517303943, 2.8196409336, 3.0320500485],
      "speed factor": 1.1210811592,
      "Uz factor": 0.9999039244,
      "Directional variance factor": 0.7493652503,
      "Velocity stability factor": 0.9732640307,
      "Direction stability factor": 0.9986657787,
      score: 0.9299902096,
    },
    "337.5": {
      velocity: [0.8899174051, 1.0877524172, 1.1702663119],
      direction: [-3.8524848863, -1.8323865211, 2.2971477158],
      "speed factor": 1.0875004103,
      "Uz factor": 0.9975965711,
      "Directional variance factor": 0.8371211981,
      "Velocity stability factor": 0.7205946437,
      "Direction stability factor": 0.9829176872,
      score: 0.8808878041,
    },
    id: "IDN:2143.0",
    zone: 1,
  },
  {
    position: [-23.8936219, 13.3169522, 20.8740369],
    "overall score": 0.4950297807,
    "0": {
      velocity: [0.7507053672, 0.8344836851, 0.9356864308],
      direction: [-4.5693687584, 4.6043292949, 8.8584970096],
      "speed factor": 1.3822201651,
      "Uz factor": 0.9891895929,
      "Directional variance factor": 0.5907262849,
      "Velocity stability factor": 0.794522287,
      "Direction stability factor": 0.9627003729,
      score: 0.8276623294,
    },
    "22.5": {
      velocity: [0.2210589028, 0.7753430676, 1.1736930434],
      direction: [-25.327982378, 13.1316774535, 47.4455556996],
      "speed factor": 1.0258593195,
      "Uz factor": 0.9983795788,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.1951020379,
      "Direction stability factor": 0.7978512831,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6014749681, 0.8720254192, 1.0374511576],
      direction: [-18.6161851645, -7.963861093, 5.8944152091],
      "speed factor": 1.4055218445,
      "Uz factor": 0.9925991696,
      "Directional variance factor": 0.2921012362,
      "Velocity stability factor": 0.5300721162,
      "Direction stability factor": 0.931914999,
      score: 0.6715008376,
    },
    "67.5": {
      velocity: [0.5855712748, 0.8761823743, 0.9690038682],
      direction: [-10.9996793949, -4.9438082889, -1.8437370218],
      "speed factor": 1.1907505741,
      "Uz factor": 0.9987297985,
      "Directional variance factor": 0.5605503743,
      "Velocity stability factor": 0.6104008989,
      "Direction stability factor": 0.9745668267,
      score: 0.7800212317,
    },
    "90.0": {
      velocity: [0.5788664899, 0.8293725695, 0.9934499242],
      direction: [-14.0157289653, 3.137329592, 21.385392664],
      "speed factor": 1.1980992189,
      "Uz factor": 0.9999481403,
      "Directional variance factor": 0.7211262585,
      "Velocity stability factor": 0.5786024373,
      "Direction stability factor": 0.901663551,
      score: 0.7757639495,
    },
    "112.5": {
      velocity: [0.8379071205, 1.1130153809, 1.2873268372],
      direction: [-13.253435391, 8.5099883786, 13.8795495153],
      "speed factor": 1.0637226231,
      "Uz factor": 0.9968345956,
      "Directional variance factor": 0.2435565886,
      "Velocity stability factor": 0.6360455776,
      "Direction stability factor": 0.9246305975,
      score: 0.6822158403,
    },
    "135.0": {
      velocity: [1.0446577054, 1.0830808508, 1.1199113682],
      direction: [3.7537386843, 5.646608076, 7.5531467117],
      "speed factor": 1.2528059469,
      "Uz factor": 0.9997687362,
      "Directional variance factor": 0.4980792821,
      "Velocity stability factor": 0.9233439794,
      "Direction stability factor": 0.9894460888,
      score: 0.8500788598,
    },
    "157.5": {
      velocity: [0.4845399869, 0.7460891486, 0.9990586103],
      direction: [-58.3983398437, -15.0239791627, 7.3187770837],
      "speed factor": 0.7155971051,
      "Uz factor": 0.9994204481,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6161412521,
      "Direction stability factor": 0.817452453,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0930940922, 0.4593596146, 0.8426426141],
      direction: [-108.7002009834, -1.2217803718, 56.4440996759],
      "speed factor": 0.6429340513,
      "Uz factor": 0.999998882,
      "Directional variance factor": 0.8913973003,
      "Velocity stability factor": 0.2671651876,
      "Direction stability factor": 0.5412658315,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.9823040627, 1.0955505461, 1.1907537674],
      direction: [-18.8670414313, -10.1316099767, -4.5470206807],
      "speed factor": 1.3029268556,
      "Uz factor": 0.9971519711,
      "Directional variance factor": 0.0994124465,
      "Velocity stability factor": 0.8334733148,
      "Direction stability factor": 0.9602221646,
      score: 0.7133325226,
    },
    "225.0": {
      velocity: [0.3706800199, 0.8001749443, 1.0485531297],
      direction: [-39.8117626072, -4.092320077, 16.605772433],
      "speed factor": 0.9689709959,
      "Uz factor": 0.9957021719,
      "Directional variance factor": 0.6362382154,
      "Velocity stability factor": 0.3632555458,
      "Direction stability factor": 0.8432846249,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.3378852974, 0.6542584915, 0.9010134144],
      direction: [-27.7686047248, 1.3466342049, 35.3951785625],
      "speed factor": 0.8196966501,
      "Uz factor": 0.9968054833,
      "Directional variance factor": 0.8802991818,
      "Velocity stability factor": 0.55345931,
      "Direction stability factor": 0.8245450464,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.8973273014, 1.0169265207, 1.1238364858],
      direction: [-2.125741114, 2.3481541625, 18.3942156689],
      "speed factor": 1.4261946452,
      "Uz factor": 0.9997400306,
      "Directional variance factor": 0.7912751856,
      "Velocity stability factor": 0.8068623807,
      "Direction stability factor": 0.94300012,
      score: 0.8710344516,
    },
    "292.5": {
      velocity: [1.1443175728, 1.149909912, 1.1549213319],
      direction: [4.9148695244, 5.2826246616, 5.7807519936],
      "speed factor": 1.1493444747,
      "Uz factor": 0.9995924982,
      "Directional variance factor": 0.5304333634,
      "Velocity stability factor": 0.9891895343,
      "Direction stability factor": 0.9975947709,
      score: 0.8787031099,
    },
    "315.0": {
      velocity: [0.801133862, 0.8072086243, 0.8125452462],
      direction: [12.7340994281, 13.1796036329, 13.578053971],
      "speed factor": 1.0031545074,
      "Uz factor": 0.9980335295,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9816325832,
      "Direction stability factor": 0.9976556818,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.8034862064, 1.0143682487, 1.165751987],
      direction: [-4.3336387576, -1.211092496, 4.7828876711],
      "speed factor": 1.0141332431,
      "Uz factor": 0.9960718365,
      "Directional variance factor": 0.8923473337,
      "Velocity stability factor": 0.6389534718,
      "Direction stability factor": 0.9746763155,
      score: 0.8701633591,
    },
    id: "IDN:2162.0",
    zone: 1,
  },
  {
    position: [52.54134, 14.2679947, 21.1598299],
    "overall score": 0.4950132718,
    "0": {
      velocity: [0.5337327046, 0.6923013576, 0.8160070973],
      direction: [-23.2094083247, -12.7277217579, -2.7703093318],
      "speed factor": 1.1467125288,
      "Uz factor": 0.9999995686,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6864484638,
      "Direction stability factor": 0.943224725,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.485577228, 0.7466936775, 1.0199526526],
      direction: [-12.3023380178, 4.1014849083, 21.991303237],
      "speed factor": 0.9879532041,
      "Uz factor": 0.9999221524,
      "Directional variance factor": 0.6354235637,
      "Velocity stability factor": 0.5484964563,
      "Direction stability factor": 0.9047398854,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3992198346, 0.6622014151, 0.8659299707],
      direction: [-26.47687971, 5.6073571295, 18.8400408399],
      "speed factor": 1.0673296144,
      "Uz factor": 0.9979354485,
      "Directional variance factor": 0.5015682552,
      "Velocity stability factor": 0.4969447601,
      "Direction stability factor": 0.8741196651,
      score: 0.6866880864,
    },
    "67.5": {
      velocity: [0.6966649848, 0.8394355041, 0.9829112201],
      direction: [0.7720325689, 5.141079481, 9.9669715267],
      "speed factor": 1.1408107921,
      "Uz factor": 0.9929439032,
      "Directional variance factor": 0.5430151572,
      "Velocity stability factor": 0.7091502447,
      "Direction stability factor": 0.9744585029,
      score: 0.8002706019,
    },
    "90.0": {
      velocity: [0.9975545473, 1.0135079065, 1.0274036428],
      direction: [5.1748457065, 5.9516851013, 6.5400537713],
      "speed factor": 1.4640983749,
      "Uz factor": 0.9935764948,
      "Directional variance factor": 0.4709613243,
      "Velocity stability factor": 0.9696603022,
      "Direction stability factor": 0.9962077554,
      score: 0.8582592843,
    },
    "112.5": {
      velocity: [0.9201245872, 1.0467357212, 1.1317772811],
      direction: [0.5301008381, 3.5574263602, 7.3262848013],
      "speed factor": 1.0003783291,
      "Uz factor": 0.998376057,
      "Directional variance factor": 0.6837843235,
      "Velocity stability factor": 0.8285968971,
      "Direction stability factor": 0.9811217112,
      score: 0.8686561608,
    },
    "135.0": {
      velocity: [1.1116687568, 1.1478907344, 1.1910374052],
      direction: [-2.1081992379, -0.5701458675, 2.5969078261],
      "speed factor": 1.3277719178,
      "Uz factor": 0.9999811645,
      "Directional variance factor": 0.9493203673,
      "Velocity stability factor": 0.9191523106,
      "Direction stability factor": 0.9869302582,
      score: 0.9605832986,
    },
    "157.5": {
      velocity: [1.0970613569, 1.1050730971, 1.1146487137],
      direction: [-5.2965036458, -4.584202034, -3.8831592046],
      "speed factor": 1.0599096779,
      "Uz factor": 0.9999426065,
      "Directional variance factor": 0.5925153748,
      "Velocity stability factor": 0.9868788796,
      "Direction stability factor": 0.9960740432,
      score: 0.8928855852,
    },
    "180.0": {
      velocity: [0.4154602286, 0.8284545686, 1.0118359325],
      direction: [-42.588263238, -8.3056190118, 7.047192236],
      "speed factor": 1.1595308669,
      "Uz factor": 0.9999847531,
      "Directional variance factor": 0.2617227545,
      "Velocity stability factor": 0.4169225015,
      "Direction stability factor": 0.8621237348,
      score: 0.6007231814,
    },
    "202.5": {
      velocity: [0.1711193452, 0.5312686928, 0.9504834737],
      direction: [-20.0357537403, 14.3812406217, 57.3903163247],
      "speed factor": 0.6318323239,
      "Uz factor": 0.9990979793,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3773801451,
      "Direction stability factor": 0.7849275832,
      score: 0.0,
    },
    "225.0": {
      velocity: [0.7345429304, 0.9810945384, 1.1196126515],
      direction: [-4.5767899163, 1.5941758982, 10.9512600532],
      "speed factor": 1.1880553855,
      "Uz factor": 0.9981369984,
      "Directional variance factor": 0.8582954757,
      "Velocity stability factor": 0.6382936484,
      "Direction stability factor": 0.9568665279,
      score: 0.852580545,
    },
    "247.5": {
      velocity: [0.4315239629, 0.8935594285, 1.2239455241],
      direction: [-22.7825533219, -6.675865956, 37.9857087372],
      "speed factor": 1.1195080839,
      "Uz factor": 0.9991327749,
      "Directional variance factor": 0.4065896928,
      "Velocity stability factor": 0.3716377144,
      "Direction stability factor": 0.8311992721,
      score: 0.6101564878,
    },
    "270.0": {
      velocity: [0.8898789606, 0.9964033396, 1.0338545782],
      direction: [-2.0007134515, 7.2708820342, 11.1931419794],
      "speed factor": 1.3974117879,
      "Uz factor": 0.9996498891,
      "Directional variance factor": 0.3536993747,
      "Velocity stability factor": 0.8772362891,
      "Direction stability factor": 0.9633504016,
      score: 0.7894091168,
    },
    "292.5": {
      velocity: [0.4379096565, 0.5097101124, 0.5677510555],
      direction: [7.9533110421, 11.100951227, 13.8615486039],
      "speed factor": 0.5094594762,
      "Uz factor": 0.9903626599,
      "Directional variance factor": 0.0132487798,
      "Velocity stability factor": 0.8676275114,
      "Direction stability factor": 0.983588229,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4253005733, 0.4529969601, 0.4763885624],
      direction: [-2.2017494193, 0.0103191523, 2.8524848036],
      "speed factor": 0.5629597215,
      "Uz factor": 0.9987450751,
      "Directional variance factor": 0.999082742,
      "Velocity stability factor": 0.9177703268,
      "Direction stability factor": 0.9859604605,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5170858116, 0.5909568851, 0.6809569345],
      direction: [-17.7562234912, -10.0966364923, 0.2122223766],
      "speed factor": 0.5908199742,
      "Uz factor": 0.9967562797,
      "Directional variance factor": 0.1025212007,
      "Velocity stability factor": 0.8366804066,
      "Direction stability factor": 0.9500876504,
      score: 0.0,
    },
    id: "IDN:2081.0",
    zone: 1,
  },
  {
    position: [-15.45866, 6.2679947, 21.1598299],
    "overall score": 0.4943297914,
    "0": {
      velocity: [0.5760727583, 0.8519027079, 0.9880167719],
      direction: [-6.4825309405, -0.257661116, 6.9387607962],
      "speed factor": 1.4110726459,
      "Uz factor": 0.9997981242,
      "Directional variance factor": 0.9770967897,
      "Velocity stability factor": 0.5424109247,
      "Direction stability factor": 0.9627186341,
      score: 0.8612362456,
    },
    "22.5": {
      velocity: [0.2687181843, 0.8373524648, 1.2843029671],
      direction: [-24.3775003014, 5.8830044549, 82.0100900731],
      "speed factor": 1.1079041855,
      "Uz factor": 0.9972413373,
      "Directional variance factor": 0.4770662707,
      "Velocity stability factor": 0.1419138921,
      "Direction stability factor": 0.7044789156,
      score: 0.5069844985,
    },
    "45.0": {
      velocity: [0.3306836535, 0.7190828177, 1.0697946169],
      direction: [-31.2709990601, -14.8498093757, 26.855735213],
      "speed factor": 1.1590104899,
      "Uz factor": 0.9997825862,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2033306881,
      "Direction stability factor": 0.8385368492,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7197932626, 0.9069288686, 0.9754293499],
      direction: [-5.6029627294, -3.3969334638, 0.223794278],
      "speed factor": 1.232535717,
      "Uz factor": 0.9993273478,
      "Directional variance factor": 0.6980503588,
      "Velocity stability factor": 0.7402526766,
      "Direction stability factor": 0.9838145639,
      score: 0.8514830408,
    },
    "90.0": {
      velocity: [0.3497592449, 0.7349876289, 1.0318208762],
      direction: [-17.6985392193, 3.1355990239, 41.8988113438],
      "speed factor": 1.0617521443,
      "Uz factor": 0.9993342111,
      "Directional variance factor": 0.7212800868,
      "Velocity stability factor": 0.3067279458,
      "Direction stability factor": 0.834451804,
      score: 0.6742279101,
    },
    "112.5": {
      velocity: [1.0355740534, 1.1723731016, 1.2373747939],
      direction: [1.7563837742, 13.514467376, 16.9685160337],
      "speed factor": 1.120451534,
      "Uz factor": 0.997473132,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8365753232,
      "Direction stability factor": 0.9577440771,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.2096229342, 1.2220267244, 1.233925343],
      direction: [1.9763349026, 2.574070336, 3.006784689],
      "speed factor": 1.4135254505,
      "Uz factor": 0.9942653476,
      "Directional variance factor": 0.7711937479,
      "Velocity stability factor": 0.9752447139,
      "Direction stability factor": 0.9971376395,
      score: 0.9351784352,
    },
    "157.5": {
      velocity: [0.321840525, 0.7633044848, 1.0532022591],
      direction: [-62.6127013493, -13.2318477209, 5.1996491283],
      "speed factor": 0.7321088648,
      "Uz factor": 0.9970745181,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4543645521,
      "Direction stability factor": 0.8116323598,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0682396488, 0.3927286204, 0.8638752531],
      direction: [-149.1745517605, 5.2895433028, 146.3929413102],
      "speed factor": 0.5496752326,
      "Uz factor": 0.9990933625,
      "Directional variance factor": 0.5298183731,
      "Velocity stability factor": 0.2221057719,
      "Direction stability factor": 0.1789791859,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1514937693, 1.1825338476, 1.2072623269],
      direction: [-3.5119597732, -2.3767342109, -1.1658524119],
      "speed factor": 1.4063751903,
      "Uz factor": 0.9915887365,
      "Directional variance factor": 0.7887347368,
      "Velocity stability factor": 0.9554475117,
      "Direction stability factor": 0.9934830351,
      score: 0.9327870797,
    },
    "225.0": {
      velocity: [0.8805475199, 1.0481012839, 1.2405693417],
      direction: [-17.9399788402, -7.0600407892, 0.10083485],
      "speed factor": 1.2691971325,
      "Uz factor": 0.9947846502,
      "Directional variance factor": 0.3724408187,
      "Velocity stability factor": 0.6618218143,
      "Direction stability factor": 0.9498866286,
      score: 0.7335089726,
    },
    "247.5": {
      velocity: [0.2003934097, 0.5214550263, 0.9459145633],
      direction: [-56.1293642737, 3.8448976938, 72.3251641015],
      "speed factor": 0.6533120224,
      "Uz factor": 0.9999733654,
      "Directional variance factor": 0.6582313161,
      "Velocity stability factor": 0.4088280797,
      "Direction stability factor": 0.6431818656,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.3842909783, 0.9579751891, 1.0363641844],
      direction: [-7.7811405597, 3.1174508816, 12.1462693602],
      "speed factor": 1.3435179997,
      "Uz factor": 0.9993623114,
      "Directional variance factor": 0.722893255,
      "Velocity stability factor": 0.4439966442,
      "Direction stability factor": 0.9446460836,
      score: 0.7640455166,
    },
    "292.5": {
      velocity: [1.1300504772, 1.1539795993, 1.1704277737],
      direction: [3.0997064519, 4.4724038909, 5.2213249589],
      "speed factor": 1.1534121608,
      "Uz factor": 0.998932605,
      "Directional variance factor": 0.6024529875,
      "Velocity stability factor": 0.9588356006,
      "Direction stability factor": 0.9941066153,
      score: 0.8873754546,
    },
    "315.0": {
      velocity: [0.8424375999, 0.8492960761, 0.8584723044],
      direction: [9.8176450062, 10.342768844, 10.7240817585],
      "speed factor": 1.055458479,
      "Uz factor": 0.999844874,
      "Directional variance factor": 0.0806427694,
      "Velocity stability factor": 0.9741910275,
      "Direction stability factor": 0.9974821201,
      score: 0.7624495093,
    },
    "337.5": {
      velocity: [0.5793145057, 0.8214048619, 1.0831846468],
      direction: [-12.1772079773, -0.5194902884, 8.6744548233],
      "speed factor": 0.8212145615,
      "Uz factor": 0.9979015334,
      "Directional variance factor": 0.9538230855,
      "Velocity stability factor": 0.4978256991,
      "Direction stability factor": 0.9420787144,
      score: 0.0,
    },
    id: "IDN:1899.0",
    zone: 1,
  },
  {
    position: [54.3509434, 5.2920371, 20.1598299],
    "overall score": 0.4940626686,
    "0": {
      velocity: [0.4282898466, 0.6179320292, 0.755961209],
      direction: [-24.1566133393, -11.2059302977, 5.9248685357],
      "speed factor": 1.0235288319,
      "Uz factor": 0.993898049,
      "Directional variance factor": 0.0039173069,
      "Velocity stability factor": 0.6360213264,
      "Direction stability factor": 0.9164403281,
      score: 0.6182048224,
    },
    "22.5": {
      velocity: [0.4535219699, 0.826251261, 1.0685752899],
      direction: [-9.7372289271, 5.2484363303, 16.0284266155],
      "speed factor": 1.0932161411,
      "Uz factor": 0.9903191041,
      "Directional variance factor": 0.5334723262,
      "Velocity stability factor": 0.4803302309,
      "Direction stability factor": 0.9284287346,
      score: 0.7176650066,
    },
    "45.0": {
      velocity: [0.4510618733, 0.7408792571, 0.8694747066],
      direction: [-16.4419664952, -3.1595412972, 4.2523002488],
      "speed factor": 1.1941417728,
      "Uz factor": 0.9812917368,
      "Directional variance factor": 0.7191518847,
      "Velocity stability factor": 0.5490032208,
      "Direction stability factor": 0.9425159257,
      score: 0.7882967392,
    },
    "67.5": {
      velocity: [0.7742670462, 0.8897216303, 1.0085644366],
      direction: [-5.9636691277, -2.9235790724, 3.004715908],
      "speed factor": 1.2091507124,
      "Uz factor": 0.9987365779,
      "Directional variance factor": 0.7401263047,
      "Velocity stability factor": 0.7619345505,
      "Direction stability factor": 0.9750878193,
      score: 0.8630591235,
    },
    "90.0": {
      velocity: [0.9239982933, 0.9694954409, 1.0178686868],
      direction: [3.7779539427, 5.5563413245, 7.454678367],
      "speed factor": 1.4005186249,
      "Uz factor": 0.9928111169,
      "Directional variance factor": 0.5061029934,
      "Velocity stability factor": 0.9045867447,
      "Direction stability factor": 0.9897868766,
      score: 0.8475658728,
    },
    "112.5": {
      velocity: [0.2669283398, 0.8423066207, 1.0358206891],
      direction: [-25.2149204943, 4.4607174987, 20.6128427038],
      "speed factor": 0.8050028987,
      "Uz factor": 0.9967632555,
      "Directional variance factor": 0.6034917779,
      "Velocity stability factor": 0.377326449,
      "Direction stability factor": 0.8727006578,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.7231876058, 0.8838929158, 0.9610467237],
      direction: [0.3645475573, 5.4003307615, 23.4391606445],
      "speed factor": 1.022404099,
      "Uz factor": 0.9938076487,
      "Directional variance factor": 0.519970599,
      "Velocity stability factor": 0.7577083589,
      "Direction stability factor": 0.9359038525,
      score: 0.7873716657,
    },
    "157.5": {
      velocity: [1.0645439463, 1.0762283005, 1.0895609631],
      direction: [2.6966617088, 3.4255243594, 4.243165251],
      "speed factor": 1.0322437442,
      "Uz factor": 0.9930977935,
      "Directional variance factor": 0.6955089458,
      "Velocity stability factor": 0.9813359512,
      "Direction stability factor": 0.9957041568,
      score: 0.9170633027,
    },
    "180.0": {
      velocity: [0.3136952826, 0.7061371039, 0.94181383],
      direction: [-56.4249568071, -8.4032788812, 5.3937874166],
      "speed factor": 0.9883315262,
      "Uz factor": 0.9998786224,
      "Directional variance factor": 0.2530418772,
      "Velocity stability factor": 0.3858874718,
      "Direction stability factor": 0.828281266,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1487942306, 0.4551368596, 0.9012300105],
      direction: [-34.3253751341, 33.1912123614, 100.4706615735],
      "speed factor": 0.5412895275,
      "Uz factor": 0.996507202,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3988927139,
      "Direction stability factor": 0.6255665647,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.0748158679, 1.0943042328, 1.1091327387],
      direction: [5.1737230877, 6.4925349807, 8.0296812401],
      "speed factor": 1.3251465442,
      "Uz factor": 0.9865033165,
      "Directional variance factor": 0.4228857795,
      "Velocity stability factor": 0.9677652398,
      "Direction stability factor": 0.9920667829,
      score: 0.8436961463,
    },
    "247.5": {
      velocity: [0.660968812, 0.9805965865, 1.1215945429],
      direction: [-11.3539482423, -2.2576251286, 13.1115962311],
      "speed factor": 1.2285537711,
      "Uz factor": 0.9951490593,
      "Directional variance factor": 0.7993222108,
      "Velocity stability factor": 0.6347400787,
      "Direction stability factor": 0.9320401542,
      score: 0.8245356495,
    },
    "270.0": {
      velocity: [0.6436040318, 0.8978317377, 1.0082525364],
      direction: [0.8577390856, 9.0461839028, 17.920370147],
      "speed factor": 1.2591694588,
      "Uz factor": 0.999918767,
      "Directional variance factor": 0.1958947642,
      "Velocity stability factor": 0.6890751063,
      "Direction stability factor": 0.9526038026,
      score: 0.6975443689,
    },
    "292.5": {
      velocity: [0.4648724293, 0.5209328282, 0.5949670284],
      direction: [-14.4533449412, -3.8951412819, 5.1347072723],
      "speed factor": 0.5206766735,
      "Uz factor": 0.9998104112,
      "Directional variance factor": 0.6537652194,
      "Velocity stability factor": 0.8673693755,
      "Direction stability factor": 0.9455887439,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4169023835, 0.4422893844, 0.4706607697],
      direction: [-5.1167609765, 0.513625739, 4.2743490101],
      "speed factor": 0.549652935,
      "Uz factor": 0.9991157966,
      "Directional variance factor": 0.9543443788,
      "Velocity stability factor": 0.9134721369,
      "Direction stability factor": 0.9739135834,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1579873968, 0.4337724269, 0.7132037312],
      direction: [-87.3329197076, -21.6088723777, 13.5277749334],
      "speed factor": 0.4336719319,
      "Uz factor": 0.9954767527,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4466523181,
      "Direction stability factor": 0.7198314038,
      score: 0.0,
    },
    id: "IDN:3087.0",
    zone: 1,
  },
  {
    position: [-9.883390508, 16.92377608, 21.1598299],
    "overall score": 0.4909650434,
    "0": {
      velocity: [0.8612291843, 0.9018004123, 0.9461132924],
      direction: [-5.7371740749, -3.4015617499, -1.5057651102],
      "speed factor": 1.4937220907,
      "Uz factor": 0.9800062642,
      "Directional variance factor": 0.6976389556,
      "Velocity stability factor": 0.9057103896,
      "Direction stability factor": 0.9882460862,
      score: 0.8949603794,
    },
    "22.5": {
      velocity: [0.4376575924, 0.8372799123, 1.0955226021],
      direction: [-30.6190640218, 9.276290703, 37.9622974559],
      "speed factor": 1.107808191,
      "Uz factor": 0.9931593177,
      "Directional variance factor": 0.1754408264,
      "Velocity stability factor": 0.4441578535,
      "Direction stability factor": 0.8094962181,
      score: 0.559647779,
    },
    "45.0": {
      velocity: [0.3549697296, 0.7846865269, 0.9964028227],
      direction: [-21.247209004, -10.9624843329, -1.3027541024],
      "speed factor": 1.2647498918,
      "Uz factor": 0.9793726462,
      "Directional variance factor": 0.0255569482,
      "Velocity stability factor": 0.3086152334,
      "Direction stability factor": 0.9445987364,
      score: 0.5558424136,
    },
    "67.5": {
      velocity: [0.7327117438, 0.9077219518, 0.9905892547],
      direction: [-5.8363777945, -3.2252430131, -0.0606593016],
      "speed factor": 1.2336135341,
      "Uz factor": 0.9945302881,
      "Directional variance factor": 0.7133117322,
      "Velocity stability factor": 0.7379752056,
      "Direction stability factor": 0.9839563375,
      score: 0.8547999032,
    },
    "90.0": {
      velocity: [0.4792707453, 0.8292238118, 1.039277111],
      direction: [-11.6256718659, 4.12852711, 26.6239035646],
      "speed factor": 1.1978843258,
      "Uz factor": 0.9999499566,
      "Directional variance factor": 0.6330198124,
      "Velocity stability factor": 0.4307893221,
      "Direction stability factor": 0.8937511794,
      score: 0.7128278733,
    },
    "112.5": {
      velocity: [0.7788726895, 1.097141762, 1.2891639501],
      direction: [-13.1875853184, 10.9491955829, 20.205313756],
      "speed factor": 1.0485520083,
      "Uz factor": 0.9978411473,
      "Directional variance factor": 0.0267381704,
      "Velocity stability factor": 0.5867498597,
      "Direction stability factor": 0.907241947,
      score: 0.606992981,
    },
    "135.0": {
      velocity: [0.6277103205, 0.8752134288, 1.0850631813],
      direction: [-14.0810416244, 0.4143017075, 7.4440094603],
      "speed factor": 1.0123644856,
      "Uz factor": 0.9998685319,
      "Directional variance factor": 0.9631731816,
      "Velocity stability factor": 0.5341243331,
      "Direction stability factor": 0.9402081914,
      score: 0.8444284744,
    },
    "157.5": {
      velocity: [0.6726360263, 0.9835815259, 1.0945093975],
      direction: [-26.125457253, -5.4541067146, 3.0138698409],
      "speed factor": 0.9433833663,
      "Uz factor": 0.9998247339,
      "Directional variance factor": 0.5151905143,
      "Velocity stability factor": 0.6852596258,
      "Direction stability factor": 0.9190574247,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0741304792, 0.4303690484, 0.7692279677],
      direction: [-148.5320268307, -4.1695847762, 117.6137657704],
      "speed factor": 0.6023579501,
      "Uz factor": 0.9984361525,
      "Directional variance factor": 0.6293702421,
      "Velocity stability factor": 0.3204020518,
      "Direction stability factor": 0.2607061317,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4521962775, 0.9901678933, 1.1507652493],
      direction: [-14.7042531399, -2.089521966, 5.2430565602],
      "speed factor": 1.1775963641,
      "Uz factor": 0.9999735943,
      "Directional variance factor": 0.8142647141,
      "Velocity stability factor": 0.4419259291,
      "Direction stability factor": 0.9445908064,
      score: 0.786343064,
    },
    "225.0": {
      velocity: [0.4329851149, 0.8357171366, 1.0700368594],
      direction: [-22.3677257799, -5.9076180991, 21.0023967961],
      "speed factor": 1.0120107758,
      "Uz factor": 0.9984125124,
      "Directional variance factor": 0.4748783912,
      "Velocity stability factor": 0.4016001528,
      "Direction stability factor": 0.8795274373,
      score: 0.6588833546,
    },
    "247.5": {
      velocity: [0.08416101, 0.5482807339, 1.1998381909],
      direction: [-52.0743918263, -0.9808849914, 87.9476014774],
      "speed factor": 0.686920975,
      "Uz factor": 0.9998899154,
      "Directional variance factor": 0.912810223,
      "Velocity stability factor": 0.1153074352,
      "Direction stability factor": 0.6110500186,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.2097030633, 0.7571788832, 1.0124360317],
      direction: [-34.9183958795, 6.9264361184, 37.5588111052],
      "speed factor": 1.0619100267,
      "Uz factor": 0.9994282306,
      "Directional variance factor": 0.3843167895,
      "Velocity stability factor": 0.3155335626,
      "Direction stability factor": 0.798674425,
      score: 0.5742998005,
    },
    "292.5": {
      velocity: [0.7533467634, 0.8126573187, 0.8604184849],
      direction: [5.8830688695, 8.4965176171, 10.8876576868],
      "speed factor": 0.8122577163,
      "Uz factor": 0.9938140548,
      "Directional variance factor": 0.2447539896,
      "Velocity stability factor": 0.8908410542,
      "Direction stability factor": 0.9860983644,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.8886208073, 0.8958774055, 0.9030783664],
      direction: [8.2019216998, 8.4212681547, 8.654387305],
      "speed factor": 1.1133471946,
      "Uz factor": 0.9825172561,
      "Directional variance factor": 0.2514428307,
      "Velocity stability factor": 0.9767295527,
      "Direction stability factor": 0.9987431511,
      score: 0.8064146714,
    },
    "337.5": {
      velocity: [0.5701057808, 0.664138113, 0.7946306494],
      direction: [-1.9194247043, 3.4632089844, 11.4849802033],
      "speed factor": 0.6639842477,
      "Uz factor": 0.993502283,
      "Directional variance factor": 0.6921592014,
      "Velocity stability factor": 0.7762307989,
      "Direction stability factor": 0.9627655419,
      score: 0.0,
    },
    id: "IDN:3624.0",
    zone: 1,
  },
  {
    position: [-21.45866, 16.4585372, 21.1598299],
    "overall score": 0.4908159064,
    "0": {
      velocity: [0.7074386045, 0.8072104843, 0.8988613435],
      direction: [-3.9764218509, 1.3512588672, 3.5729045526],
      "speed factor": 1.3370454436,
      "Uz factor": 0.9781472188,
      "Directional variance factor": 0.8798881007,
      "Velocity stability factor": 0.7873668478,
      "Direction stability factor": 0.9790296489,
      score: 0.9063285616,
    },
    "22.5": {
      velocity: [0.3917086097, 0.7596036266, 1.0963920654],
      direction: [-28.1872025965, 14.0340833074, 46.7552087424],
      "speed factor": 1.0050344061,
      "Uz factor": 0.9922909296,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4046000943,
      "Direction stability factor": 0.7918266352,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.6345994833, 0.8603288907, 0.9928232233],
      direction: [-15.2769504321, -5.2735374953, 4.8555599916],
      "speed factor": 1.386669497,
      "Uz factor": 0.987366374,
      "Directional variance factor": 0.5312411115,
      "Velocity stability factor": 0.6138795463,
      "Direction stability factor": 0.9440763599,
      score: 0.7583183444,
    },
    "67.5": {
      velocity: [0.7012691642, 0.9092771148, 0.9877758701],
      direction: [-6.4028580135, -2.4365812058, 0.3065382454],
      "speed factor": 1.2357270339,
      "Uz factor": 0.9946153476,
      "Directional variance factor": 0.7834150039,
      "Velocity stability factor": 0.7088855851,
      "Direction stability factor": 0.9813627882,
      score: 0.8637565413,
    },
    "90.0": {
      velocity: [0.4933876823, 0.8271640706, 1.03502442],
      direction: [-15.678206474, 4.6112830538, 22.0465036433],
      "speed factor": 1.1949088545,
      "Uz factor": 0.9999819194,
      "Directional variance factor": 0.590108173,
      "Velocity stability factor": 0.4494608749,
      "Direction stability factor": 0.8952091386,
      score: 0.7074968313,
    },
    "112.5": {
      velocity: [0.9465433843, 1.1729934015, 1.277355519],
      direction: [-2.3691849205, 8.7533209177, 13.124303524],
      "speed factor": 1.1210443623,
      "Uz factor": 0.9992902422,
      "Directional variance factor": 0.2219270295,
      "Velocity stability factor": 0.7320977809,
      "Direction stability factor": 0.9569625321,
      score: 0.7169874687,
    },
    "135.0": {
      velocity: [0.9949717792, 1.1116516484, 1.1735675804],
      direction: [-3.6448740131, 2.8506341288, 6.8491896858],
      "speed factor": 1.2858539554,
      "Uz factor": 0.9999128027,
      "Directional variance factor": 0.7466102997,
      "Velocity stability factor": 0.8180760522,
      "Direction stability factor": 0.9708498231,
      score: 0.8765964995,
    },
    "157.5": {
      velocity: [0.4012871402, 0.8372542177, 1.0981962571],
      direction: [-40.6202089627, -10.1776085389, 10.774433711],
      "speed factor": 0.8030363335,
      "Uz factor": 0.9999999223,
      "Directional variance factor": 0.0953236854,
      "Velocity stability factor": 0.4800680697,
      "Direction stability factor": 0.8572371037,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0747124131, 0.4760397957, 0.9172965474],
      direction: [-98.7470455334, 0.1349538202, 108.0418538907],
      "speed factor": 0.6662801533,
      "Uz factor": 0.999991385,
      "Directional variance factor": 0.9880041049,
      "Velocity stability factor": 0.1762041176,
      "Direction stability factor": 0.4255863905,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.4405099029, 0.8697493221, 1.1104712316],
      direction: [-37.9306916686, -9.4832852432, 1.5142956942],
      "speed factor": 1.0343838114,
      "Uz factor": 0.9967442182,
      "Directional variance factor": 0.1570413117,
      "Velocity stability factor": 0.4647800558,
      "Direction stability factor": 0.8904305907,
      score: 0.6006706372,
    },
    "225.0": {
      velocity: [0.5064151607, 0.8243436381, 1.0897190187],
      direction: [-43.8357427806, -2.9476373108, 20.9380329599],
      "speed factor": 0.9982380498,
      "Uz factor": 0.9991826744,
      "Directional variance factor": 0.7379877946,
      "Velocity stability factor": 0.452086989,
      "Direction stability factor": 0.8200728452,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.246852099, 0.7316424635, 0.9109797566],
      direction: [-29.7295883992, 2.3361099932, 22.2146176974],
      "speed factor": 0.9166482119,
      "Uz factor": 0.9979110877,
      "Directional variance factor": 0.7923457784,
      "Velocity stability factor": 0.4733702448,
      "Direction stability factor": 0.8557105386,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.4720830481, 0.9498642912, 1.137274666],
      direction: [-6.1362428547, 2.3392367381, 53.3258970957],
      "speed factor": 1.3321428227,
      "Uz factor": 0.9999285774,
      "Directional variance factor": 0.7920678455,
      "Velocity stability factor": 0.432810966,
      "Direction stability factor": 0.834827389,
      score: 0.7236333974,
    },
    "292.5": {
      velocity: [1.0831047047, 1.0928944873, 1.1034202842],
      direction: [4.4217225482, 4.8193312074, 5.2484301534],
      "speed factor": 1.0923570858,
      "Uz factor": 0.9957780526,
      "Directional variance factor": 0.5716150038,
      "Velocity stability factor": 0.9792883946,
      "Direction stability factor": 0.99770359,
      score: 0.8865776446,
    },
    "315.0": {
      velocity: [0.8341067888, 0.8371718463, 0.8426819584],
      direction: [7.8598913407, 8.2297925997, 8.5630143834],
      "speed factor": 1.0403911526,
      "Uz factor": 0.9888326475,
      "Directional variance factor": 0.26846288,
      "Velocity stability factor": 0.9861976677,
      "Direction stability factor": 0.9980468804,
      score: 0.8126885772,
    },
    "337.5": {
      velocity: [0.6105938245, 0.8289564391, 0.9589371543],
      direction: [-7.1716901761, -3.6922491581, 5.7367376634],
      "speed factor": 0.8287643892,
      "Uz factor": 0.9893229056,
      "Directional variance factor": 0.6718000748,
      "Velocity stability factor": 0.6528290648,
      "Direction stability factor": 0.964143256,
      score: 0.0,
    },
    id: "IDN:3630.0",
    zone: 1,
  },
  {
    position: [50.54134, 8.2679947, 21.1598299],
    "overall score": 0.4881257357,
    "0": {
      velocity: [0.540487016, 0.6905969032, 0.8146565423],
      direction: [-21.3430416541, -11.5993672189, 0.1971818013],
      "speed factor": 1.1438893086,
      "Uz factor": 0.9975920768,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6954513822,
      "Direction stability factor": 0.940166046,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5314743882, 0.7933568579, 1.0613962527],
      direction: [-9.9423305511, 4.6284144646, 23.8558431075],
      "speed factor": 1.0496934331,
      "Uz factor": 0.9996048819,
      "Directional variance factor": 0.5885853809,
      "Velocity stability factor": 0.5522593505,
      "Direction stability factor": 0.9061161843,
      score: 0.738269275,
    },
    "45.0": {
      velocity: [0.4083091196, 0.7360260191, 0.905959373],
      direction: [-10.5117740465, 6.2430265498, 17.8460550037],
      "speed factor": 1.1863193724,
      "Uz factor": 0.9999858894,
      "Directional variance factor": 0.4450643067,
      "Velocity stability factor": 0.4635951778,
      "Direction stability factor": 0.9212282526,
      score: 0.6877789974,
    },
    "67.5": {
      velocity: [0.760441871, 0.917948225, 1.0734770773],
      direction: [-1.8747431571, 3.3414640316, 9.280514383],
      "speed factor": 1.247511258,
      "Uz factor": 0.9980726932,
      "Directional variance factor": 0.702980975,
      "Velocity stability factor": 0.6819304434,
      "Direction stability factor": 0.9690131735,
      score: 0.8307344413,
    },
    "90.0": {
      velocity: [0.9539625426, 0.9954723568, 1.0309238268],
      direction: [4.3131204637, 5.3517287552, 7.2739249634],
      "speed factor": 1.4380444894,
      "Uz factor": 0.9980101643,
      "Directional variance factor": 0.5242907773,
      "Velocity stability factor": 0.9217737736,
      "Direction stability factor": 0.9917755431,
      score: 0.8574039093,
    },
    "112.5": {
      velocity: [0.512650711, 0.972651194, 1.139051922],
      direction: [-36.8256259984, 4.8264910406, 22.5768761993],
      "speed factor": 0.9295748263,
      "Uz factor": 0.9990514776,
      "Directional variance factor": 0.5709785742,
      "Velocity stability factor": 0.492720318,
      "Direction stability factor": 0.8349930495,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.8090689167, 0.9970861617, 1.0552132677],
      direction: [-0.1380025621, 6.2696312888, 31.546523518],
      "speed factor": 1.1533353877,
      "Uz factor": 0.9986785246,
      "Directional variance factor": 0.442699441,
      "Velocity stability factor": 0.7492687299,
      "Direction stability factor": 0.9119874276,
      score: 0.7539857565,
    },
    "157.5": {
      velocity: [1.1617868481, 1.1732795795, 1.1868983392],
      direction: [0.6117493263, 1.1681783419, 1.7531452464],
      "speed factor": 1.125328618,
      "Uz factor": 0.9993579094,
      "Directional variance factor": 0.8961619252,
      "Velocity stability factor": 0.9812654682,
      "Direction stability factor": 0.9968294558,
      score: 0.9677715762,
    },
    "180.0": {
      velocity: [0.3701259594, 0.7791185259, 0.9887500458],
      direction: [-45.4829133757, -8.671456228, 3.7498796186],
      "speed factor": 1.0904786019,
      "Uz factor": 0.9979232332,
      "Directional variance factor": 0.2292038908,
      "Velocity stability factor": 0.3951702218,
      "Direction stability factor": 0.8632422417,
      score: 0.587714649,
    },
    "202.5": {
      velocity: [0.1140604718, 0.5071255647, 0.900372697],
      direction: [-36.0084713177, 18.1510202761, 72.6493967788],
      "speed factor": 0.603119153,
      "Uz factor": 0.9980330094,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3718294367,
      "Direction stability factor": 0.6981725886,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1156315878, 1.1467184813, 1.1777365444],
      direction: [0.0049965393, 1.3623287911, 3.4439027926],
      "speed factor": 1.3886175226,
      "Uz factor": 0.9930146282,
      "Directional variance factor": 0.8789041075,
      "Velocity stability factor": 0.9416631429,
      "Direction stability factor": 0.9904474826,
      score: 0.9503655539,
    },
    "247.5": {
      velocity: [0.7394046689, 0.9788607229, 1.2061690109],
      direction: [-24.28306037, -7.9056106411, 7.0482047858],
      "speed factor": 1.2263789708,
      "Uz factor": 0.9989598395,
      "Directional variance factor": 0.2972790541,
      "Velocity stability factor": 0.6298723771,
      "Direction stability factor": 0.9129687079,
      score: 0.6882722118,
    },
    "270.0": {
      velocity: [0.7820896821, 0.9451102284, 1.0338278538],
      direction: [-0.5820797734, 8.0698953753, 13.3075732977],
      "speed factor": 1.3254754591,
      "Uz factor": 0.9999701998,
      "Directional variance factor": 0.2826759666,
      "Velocity stability factor": 0.7853503764,
      "Direction stability factor": 0.9614176304,
      score: 0.7477154009,
    },
    "292.5": {
      velocity: [0.4509850812, 0.5095875503, 0.5626410547],
      direction: [5.0268003353, 12.922018709, 17.9274855902],
      "speed factor": 0.5093369743,
      "Uz factor": 0.9998390067,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8861674382,
      "Direction stability factor": 0.9641647632,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4042595216, 0.4298876864, 0.449084963],
      direction: [8.7101827267, 11.6647013097, 14.2079916733],
      "speed factor": 0.5342407864,
      "Uz factor": 0.9940808865,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9278503331,
      "Direction stability factor": 0.9847283085,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3198016979, 0.6015676703, 0.7442486346],
      direction: [-58.4071697817, -9.5000955022, 1.8445492325],
      "speed factor": 0.601428301,
      "Uz factor": 0.9987012111,
      "Directional variance factor": 0.1555470665,
      "Velocity stability factor": 0.5769815944,
      "Direction stability factor": 0.8326341138,
      score: 0.0,
    },
    id: "IDN:1969.0",
    zone: 1,
  },
  {
    position: [-13.45866, 6.2679947, 21.1598299],
    "overall score": 0.4880004656,
    "0": {
      velocity: [0.5003133739, 0.8491062092, 0.9914358061],
      direction: [-8.8924884259, -1.760228175, 7.3075082509],
      "speed factor": 1.4064405879,
      "Uz factor": 0.9998194556,
      "Directional variance factor": 0.8435352733,
      "Velocity stability factor": 0.4544592175,
      "Direction stability factor": 0.9550000092,
      score: 0.8019986273,
    },
    "22.5": {
      velocity: [0.1182777333, 0.8356261542, 1.2929668452],
      direction: [-101.1749117135, 5.0184433094, 53.2140944488],
      "speed factor": 1.1056200974,
      "Uz factor": 0.9977899046,
      "Directional variance factor": 0.5539161503,
      "Velocity stability factor": 0.0074837422,
      "Direction stability factor": 0.5711416495,
      score: 0.4259207979,
    },
    "45.0": {
      velocity: [0.3687867145, 0.7116401968, 1.052735241],
      direction: [-27.4592577815, -14.3417039057, 43.6858840713],
      "speed factor": 1.147014548,
      "Uz factor": 0.9996326234,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2627889059,
      "Direction stability factor": 0.802374606,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.6866247309, 0.9044472547, 0.9706676477],
      direction: [-5.2277443396, -3.2041013475, 0.8175265852],
      "speed factor": 1.2291631507,
      "Uz factor": 0.9993013529,
      "Directional variance factor": 0.7151909913,
      "Velocity stability factor": 0.7113889978,
      "Direction stability factor": 0.9832075808,
      score: 0.8482487877,
    },
    "90.0": {
      velocity: [0.3284630727, 0.7273348843, 0.9817508178],
      direction: [-19.3698521104, 2.9198287631, 35.7382610906],
      "speed factor": 1.0506971039,
      "Uz factor": 0.9992468564,
      "Directional variance factor": 0.7404596655,
      "Velocity stability factor": 0.3359747621,
      "Direction stability factor": 0.8469219078,
      score: 0.6925695608,
    },
    "112.5": {
      velocity: [1.0209358726, 1.1713900224, 1.2578100814],
      direction: [5.6583464612, 14.1770872446, 19.5816061883],
      "speed factor": 1.1195119929,
      "Uz factor": 0.9978319854,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8081717098,
      "Direction stability factor": 0.9613242785,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.2074610364, 1.21684624, 1.2278620198],
      direction: [1.9102631315, 2.4512214663, 2.9583170376],
      "speed factor": 1.4075331539,
      "Uz factor": 0.9931316155,
      "Directional variance factor": 0.7821136474,
      "Velocity stability factor": 0.9792188427,
      "Direction stability factor": 0.9970887391,
      score: 0.9388774921,
    },
    "157.5": {
      velocity: [0.364206706, 0.8162928655, 1.0738570247],
      direction: [-48.7265679739, -12.2979742111, 2.3330663629],
      "speed factor": 0.7829316544,
      "Uz factor": 0.997875105,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4705624434,
      "Direction stability factor": 0.8581676824,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0464262697, 0.3669243199, 0.747298514],
      direction: [-156.6108129673, 5.1212564046, 143.0016890692],
      "speed factor": 0.5135587284,
      "Uz factor": 0.9994446402,
      "Directional variance factor": 0.5447772085,
      "Velocity stability factor": 0.3147560636,
      "Direction stability factor": 0.1677430499,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1168981026, 1.1687819958, 1.1950804645],
      direction: [-3.7045951872, -2.3460016679, -0.3889965273],
      "speed factor": 1.3900202562,
      "Uz factor": 0.9893080419,
      "Directional variance factor": 0.7914665184,
      "Velocity stability factor": 0.9375415302,
      "Direction stability factor": 0.9907900037,
      score: 0.927647014,
    },
    "225.0": {
      velocity: [1.004083133, 1.085017393, 1.2060443585],
      direction: [-15.7762988451, -8.3080614473, -3.1062656916],
      "speed factor": 1.3139006555,
      "Uz factor": 0.9981012324,
      "Directional variance factor": 0.2615056491,
      "Velocity stability factor": 0.8102923859,
      "Direction stability factor": 0.9648054635,
      score: 0.7503522405,
    },
    "247.5": {
      velocity: [0.1215695485, 0.4877901862, 0.8825844032],
      direction: [-82.2487353479, -0.0729204408, 82.4425709496],
      "speed factor": 0.6111345695,
      "Uz factor": 0.9991068227,
      "Directional variance factor": 0.993518183,
      "Velocity stability factor": 0.3965421224,
      "Direction stability factor": 0.5425241492,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.4335384765, 0.9557129329, 1.0263112594],
      direction: [-3.122898116, 4.2202560094, 12.4888834962],
      "speed factor": 1.3403452851,
      "Uz factor": 0.9992696716,
      "Directional variance factor": 0.6248661325,
      "Velocity stability factor": 0.4945603446,
      "Direction stability factor": 0.95663394,
      score: 0.7581735893,
    },
    "292.5": {
      velocity: [1.1230796586, 1.1460644276, 1.1592230518],
      direction: [3.9272952394, 5.1146351583, 5.8819010611],
      "speed factor": 1.1455008812,
      "Uz factor": 0.9992216575,
      "Directional variance factor": 0.5453657637,
      "Velocity stability factor": 0.9631520382,
      "Direction stability factor": 0.9945705394,
      score: 0.8744147202,
    },
    "315.0": {
      velocity: [0.8540728137, 0.8605272929, 0.8702344396],
      direction: [8.5813034555, 9.1082939408, 9.5068865741],
      "speed factor": 1.0694160178,
      "Uz factor": 0.9998353249,
      "Directional variance factor": 0.1903738719,
      "Velocity stability factor": 0.9739867385,
      "Direction stability factor": 0.9974289358,
      score: 0.7898046205,
    },
    "337.5": {
      velocity: [0.5974814884, 0.7687897112, 0.9374966498],
      direction: [-12.5772079264, 0.3697046196, 8.6555176483],
      "speed factor": 0.7686116005,
      "Uz factor": 0.9986710832,
      "Directional variance factor": 0.9671373671,
      "Velocity stability factor": 0.6611292037,
      "Direction stability factor": 0.9410202067,
      score: 0.0,
    },
    id: "IDN:1900.0",
    zone: 1,
  },
  {
    position: [-26.41971147, 3.596925286, 20.8740369],
    "overall score": 0.4873528659,
    "0": {
      velocity: [0.3761980845, 0.7448015922, 0.8664322269],
      direction: [-4.206592798, 4.3249548442, 15.6186371997],
      "speed factor": 1.2336727466,
      "Uz factor": 0.9973747339,
      "Directional variance factor": 0.6155595694,
      "Velocity stability factor": 0.4554459334,
      "Direction stability factor": 0.9449299167,
      score: 0.740216334,
    },
    "22.5": {
      velocity: [0.2677124391, 0.7229337086, 1.1889020042],
      direction: [-29.8478645229, 12.4845160627, 57.4140201069],
      "speed factor": 0.9565163001,
      "Uz factor": 0.9992762042,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2216701333,
      "Direction stability factor": 0.757605876,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.287682391, 0.6040669842, 0.9839022737],
      direction: [-21.4611500171, -5.9991825318, 43.1241324556],
      "speed factor": 0.9736291203,
      "Uz factor": 0.9999870839,
      "Directional variance factor": 0.4667393305,
      "Velocity stability factor": 0.2495619165,
      "Direction stability factor": 0.8205964376,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7360984152, 0.9104643668, 0.9881015824],
      direction: [-4.7991441434, -2.7909277034, -0.0322788531],
      "speed factor": 1.2373405347,
      "Uz factor": 0.9986169515,
      "Directional variance factor": 0.7519175375,
      "Velocity stability factor": 0.7439440227,
      "Direction stability factor": 0.9867587075,
      score: 0.8673447438,
    },
    "90.0": {
      velocity: [0.257738292, 0.6864554152, 0.9622095873],
      direction: [-22.5389311326, 1.4186925017, 29.3397505777],
      "speed factor": 0.9916432337,
      "Uz factor": 0.9999734202,
      "Directional variance factor": 0.8738939998,
      "Velocity stability factor": 0.2839499546,
      "Direction stability factor": 0.8558925508,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.159501033, 1.1983030354, 1.2308748649],
      direction: [3.2160198852, 5.3322025246, 6.8987340486],
      "speed factor": 1.1452330937,
      "Uz factor": 0.9948020541,
      "Directional variance factor": 0.5260264423,
      "Velocity stability factor": 0.9421991941,
      "Direction stability factor": 0.9897702384,
      score: 0.8619415283,
    },
    "135.0": {
      velocity: [1.1399840179, 1.1462049548, 1.1542044993],
      direction: [-3.1318309454, -2.6452323221, -1.9994893243],
      "speed factor": 1.3258219667,
      "Uz factor": 0.988869992,
      "Directional variance factor": 0.764868238,
      "Velocity stability factor": 0.9855145188,
      "Direction stability factor": 0.9968546066,
      score: 0.9360229925,
    },
    "157.5": {
      velocity: [0.1456180363, 0.5680511816, 0.9049722543],
      direction: [-104.7609363255, -16.9242927764, 35.1975844868],
      "speed factor": 0.5448354018,
      "Uz factor": 0.9929451541,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4334806436,
      "Direction stability factor": 0.6112263311,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0902956576, 0.4690201759, 0.77216947],
      direction: [-103.835850142, -1.3471207496, 149.8644858905],
      "speed factor": 0.6564552745,
      "Uz factor": 0.9947036411,
      "Directional variance factor": 0.8802559334,
      "Velocity stability factor": 0.3333308616,
      "Direction stability factor": 0.2952768444,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1096887033, 1.123625625, 1.1406817084],
      direction: [-6.6105399675, -6.1310744435, -5.6626302369],
      "speed factor": 1.3363162546,
      "Uz factor": 0.9674728429,
      "Directional variance factor": 0.455015605,
      "Velocity stability factor": 0.9752402509,
      "Direction stability factor": 0.9973669174,
      score: 0.8562474227,
    },
    "225.0": {
      velocity: [0.462066042, 0.7121484178, 1.0092686422],
      direction: [-44.2860969546, -9.1784918377, 11.3461497019],
      "speed factor": 0.8623753674,
      "Uz factor": 0.9777409441,
      "Directional variance factor": 0.1841340589,
      "Velocity stability factor": 0.485997872,
      "Direction stability factor": 0.8454659815,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.2192243073, 0.5804631805, 0.8441591027],
      direction: [-64.4572806254, 4.9985136292, 60.3720119836],
      "speed factor": 0.727241191,
      "Uz factor": 0.9914429721,
      "Directional variance factor": 0.5556876774,
      "Velocity stability factor": 0.5044487989,
      "Direction stability factor": 0.653251965,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.819398624, 0.9203147455, 1.0511615292],
      direction: [-3.5829040173, 0.5770363559, 4.3506328708],
      "speed factor": 1.2907008867,
      "Uz factor": 0.9983731565,
      "Directional variance factor": 0.9487078795,
      "Velocity stability factor": 0.8023826898,
      "Direction stability factor": 0.9779623975,
      score: 0.9267538411,
    },
    "292.5": {
      velocity: [0.8472198938, 1.0518115654, 1.1880832607],
      direction: [-8.9879226909, -3.3858356294, -0.039467411],
      "speed factor": 1.0512943654,
      "Uz factor": 0.9985052756,
      "Directional variance factor": 0.6990368329,
      "Velocity stability factor": 0.6524919439,
      "Direction stability factor": 0.9751431798,
      score: 0.8254537841,
    },
    "315.0": {
      velocity: [0.9057110698, 0.9164595066, 0.9292389263],
      direction: [3.2462959065, 3.6021201233, 3.8940099644],
      "speed factor": 1.1389254986,
      "Uz factor": 0.9997320718,
      "Directional variance factor": 0.6798115446,
      "Velocity stability factor": 0.9621302779,
      "Direction stability factor": 0.9982007943,
      score: 0.9095858528,
    },
    "337.5": {
      velocity: [1.0234773836, 1.1272351525, 1.1701915401],
      direction: [-5.0374795257, -3.7453441281, -0.6197881092],
      "speed factor": 1.1269739983,
      "Uz factor": 0.9999656637,
      "Directional variance factor": 0.6670805219,
      "Velocity stability factor": 0.8537796289,
      "Direction stability factor": 0.987728635,
      score: 0.8740793552,
    },
    id: "IDN:3642.0",
    zone: 1,
  },
  {
    position: [50.54134, 10.2679947, 21.1598299],
    "overall score": 0.4842275392,
    "0": {
      velocity: [0.5397287226, 0.6969112941, 0.8322613479],
      direction: [-22.1057022481, -12.4636072949, -0.9466740191],
      "speed factor": 1.1543483249,
      "Uz factor": 0.9985431641,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6750535774,
      "Direction stability factor": 0.9412249216,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5293703333, 0.7714598618, 1.0497310691],
      direction: [-10.1160291111, 4.0509872276, 23.0743751836],
      "speed factor": 1.0207214354,
      "Uz factor": 0.9996414872,
      "Directional variance factor": 0.6399122464,
      "Velocity stability factor": 0.5603377225,
      "Direction stability factor": 0.9078044325,
      score: 0.7539647085,
    },
    "45.0": {
      velocity: [0.3937917088, 0.7112074027, 0.9008764728],
      direction: [-16.8108648716, 6.0506218754, 19.6809262276],
      "speed factor": 1.1463169748,
      "Uz factor": 0.9998620117,
      "Directional variance factor": 0.4621669444,
      "Velocity stability factor": 0.4534259536,
      "Direction stability factor": 0.8986339136,
      score: 0.6782151813,
    },
    "67.5": {
      velocity: [0.7587117637, 0.8943799731, 1.0638631388],
      direction: [-0.3932840562, 4.7853342558, 11.8750385832],
      "speed factor": 1.2154814999,
      "Uz factor": 0.9982928459,
      "Directional variance factor": 0.574636955,
      "Velocity stability factor": 0.6899410653,
      "Direction stability factor": 0.965921326,
      score: 0.7991051681,
    },
    "90.0": {
      velocity: [0.9538669348, 0.9953399164, 1.0245030244],
      direction: [3.9095670361, 4.8108502029, 6.3936804933],
      "speed factor": 1.4378531679,
      "Uz factor": 0.9979593342,
      "Directional variance factor": 0.5723688709,
      "Velocity stability factor": 0.9282029297,
      "Direction stability factor": 0.9930996848,
      score: 0.8716927926,
    },
    "112.5": {
      velocity: [0.707118233, 1.005383332, 1.1317998601],
      direction: [-21.6100281962, 4.6856752329, 20.7944699956],
      "speed factor": 0.9608573372,
      "Uz factor": 0.9995079002,
      "Directional variance factor": 0.5834955349,
      "Velocity stability factor": 0.6560792716,
      "Direction stability factor": 0.8822097272,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.8748475882, 1.0427643157, 1.109486842],
      direction: [-2.0925880239, 2.451899226, 22.5694811574],
      "speed factor": 1.2061715752,
      "Uz factor": 0.9996805219,
      "Directional variance factor": 0.7820534021,
      "Velocity stability factor": 0.7609882254,
      "Direction stability factor": 0.9314942523,
      score: 0.851507533,
    },
    "157.5": {
      velocity: [1.152122232, 1.1645124046, 1.1781748903],
      direction: [-1.250205751, -0.6581867932, 0.0059949707],
      "speed factor": 1.1169197502,
      "Uz factor": 0.9999724976,
      "Directional variance factor": 0.9414945073,
      "Velocity stability factor": 0.9805633065,
      "Direction stability factor": 0.9965105536,
      score: 0.9787697302,
    },
    "180.0": {
      velocity: [0.294859561, 0.7899153099, 1.0377192284],
      direction: [-48.3339931349, -8.5822461038, 6.4765026314],
      "speed factor": 1.1055901179,
      "Uz factor": 0.9981343192,
      "Directional variance factor": 0.2371336797,
      "Velocity stability factor": 0.2737048916,
      "Direction stability factor": 0.8477486229,
      score: 0.5515839543,
    },
    "202.5": {
      velocity: [0.1711290313, 0.5304135307, 0.8772314794],
      direction: [-28.8640296234, 18.4235344681, 73.4024184965],
      "speed factor": 0.6308152885,
      "Uz factor": 0.9989724642,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4359075717,
      "Direction stability factor": 0.715926533,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.0851110274, 1.1454120938, 1.1822574979],
      direction: [-1.2584094669, 0.8648371102, 3.3419263001],
      "speed factor": 1.387035554,
      "Uz factor": 0.9950494162,
      "Directional variance factor": 0.9231255902,
      "Velocity stability factor": 0.908747706,
      "Direction stability factor": 0.9872212895,
      score: 0.9515789688,
    },
    "247.5": {
      velocity: [0.4749758169, 0.9361840411, 1.3141219545],
      direction: [-20.4602656316, -8.6247803244, 30.4737408522],
      "speed factor": 1.1729109096,
      "Uz factor": 0.9999787561,
      "Directional variance factor": 0.2333528601,
      "Velocity stability factor": 0.334586777,
      "Direction stability factor": 0.8585166487,
      score: 0.5712432336,
    },
    "270.0": {
      velocity: [0.7443589351, 0.9553047838, 1.02986174],
      direction: [-1.5683237378, 8.0708657548, 12.6935380575],
      "speed factor": 1.3397728739,
      "Uz factor": 0.9999935605,
      "Directional variance factor": 0.2825897107,
      "Velocity stability factor": 0.7565602816,
      "Direction stability factor": 0.9603837172,
      score: 0.7399793567,
    },
    "292.5": {
      velocity: [0.4042105244, 0.4594864572, 0.5215293549],
      direction: [3.392011257, 12.8695538148, 18.7376596731],
      "speed factor": 0.4592605171,
      "Uz factor": 0.9969565646,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8803941911,
      "Direction stability factor": 0.9573731988,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4088852885, 0.4308356664, 0.4487651952],
      direction: [5.8501380883, 8.6327805555, 11.7204514486],
      "speed factor": 0.5354188839,
      "Uz factor": 0.9945737296,
      "Directional variance factor": 0.2326417284,
      "Velocity stability factor": 0.9358105153,
      "Direction stability factor": 0.983693574,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3641050991, 0.6097024231, 0.7508006084],
      direction: [-38.7866555828, -9.5030767305, 2.4885493668],
      "speed factor": 0.6095611692,
      "Uz factor": 0.9999567567,
      "Directional variance factor": 0.1552820684,
      "Velocity stability factor": 0.6146059645,
      "Direction stability factor": 0.8853466529,
      score: 0.0,
    },
    id: "IDN:2006.0",
    zone: 1,
  },
  {
    position: [-33.8210353, 14.1527752, 19.8740369],
    "overall score": 0.4824964913,
    "0": {
      velocity: [0.7039753141, 0.8338003553, 0.9904286567],
      direction: [5.7146723385, 11.3972387907, 16.4655393355],
      "speed factor": 1.3810883128,
      "Uz factor": 0.9966221701,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.6818064693,
      "Direction stability factor": 0.9701364806,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.1992709611, 0.6705692911, 1.0249352327],
      direction: [-53.7082916079, 18.4806507505, 83.7718527542],
      "speed factor": 0.8872327431,
      "Uz factor": 0.991574437,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3023811963,
      "Direction stability factor": 0.6181107101,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.3948413816, 0.8374661032, 1.0439597761],
      direction: [-12.0707660397, -0.8114741969, 20.7876900265],
      "speed factor": 1.3498194848,
      "Uz factor": 0.99016775,
      "Directional variance factor": 0.9278689603,
      "Velocity stability factor": 0.3003314382,
      "Direction stability factor": 0.9087265109,
      score: 0.7614133551,
    },
    "67.5": {
      velocity: [0.3199915124, 0.7658159628, 0.8900921409],
      direction: [-13.105265105, -2.9752464081, 31.281579679],
      "speed factor": 1.0407602619,
      "Uz factor": 0.9946829563,
      "Directional variance factor": 0.7355336526,
      "Velocity stability factor": 0.4207307981,
      "Direction stability factor": 0.8767032089,
      score: 0.7274177171,
    },
    "90.0": {
      velocity: [0.4985520453, 0.7448227339, 0.9600490504],
      direction: [-18.8013819122, 5.099726759, 31.4674763977],
      "speed factor": 1.0759597901,
      "Uz factor": 0.9998161066,
      "Directional variance factor": 0.5466909548,
      "Velocity stability factor": 0.5309177909,
      "Direction stability factor": 0.8603642825,
      score: 0.6995843277,
    },
    "112.5": {
      velocity: [0.3633216933, 0.6729675355, 1.0022908627],
      direction: [-18.3088569284, 5.1891664449, 31.1133448113],
      "speed factor": 0.6431634319,
      "Uz factor": 0.9997600751,
      "Directional variance factor": 0.5387407604,
      "Velocity stability factor": 0.4825423845,
      "Direction stability factor": 0.8627161063,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.115925058, 0.4333294707, 0.7049162034],
      direction: [-19.563801586, 14.2726737062, 61.8022157312],
      "speed factor": 0.50123473,
      "Uz factor": 0.9997463797,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4000329589,
      "Direction stability factor": 0.7739832852,
      score: 0.0,
    },
    "157.5": {
      velocity: [0.198647972, 0.6417159519, 1.0571602309],
      direction: [-71.9331871327, -23.9796584284, 44.5556746864],
      "speed factor": 0.6154895542,
      "Uz factor": 0.9975356512,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3595033769,
      "Direction stability factor": 0.6764198283,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1426001199, 0.5269920635, 0.9688855696],
      direction: [-51.5036760955, -1.40322355, 72.7260221568],
      "speed factor": 0.7375945374,
      "Uz factor": 0.9963977829,
      "Directional variance factor": 0.8752690178,
      "Velocity stability factor": 0.1921393681,
      "Direction stability factor": 0.6549175049,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.0687378831, 1.0819849101, 1.0911386327],
      direction: [-1.4754024123, -0.5544085946, 0.2006545182],
      "speed factor": 1.286793386,
      "Uz factor": 0.9973646543,
      "Directional variance factor": 0.950719236,
      "Velocity stability factor": 0.9821044478,
      "Direction stability factor": 0.9953442863,
      score: 0.9808780641,
    },
    "225.0": {
      velocity: [0.1223646908, 0.5784947168, 0.8284653112],
      direction: [-91.901782747, -8.4303500693, 7.5381201954],
      "speed factor": 0.7005275606,
      "Uz factor": 0.9994563182,
      "Directional variance factor": 0.2506355494,
      "Velocity stability factor": 0.3367406855,
      "Direction stability factor": 0.7237780474,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.7242919613, 0.8026854527, 0.8724464498],
      direction: [-1.5460243057, 1.5524693904, 5.0564869574],
      "speed factor": 1.0056553872,
      "Uz factor": 0.9899707165,
      "Directional variance factor": 0.8620027209,
      "Velocity stability factor": 0.882518728,
      "Direction stability factor": 0.9816596909,
      score: 0.9269602077,
    },
    "270.0": {
      velocity: [0.8918017436, 1.0010167389, 1.0650302988],
      direction: [-6.654131262, -2.7487827517, 3.584944148],
      "speed factor": 1.4038818771,
      "Uz factor": 0.995459883,
      "Directional variance factor": 0.7556637554,
      "Velocity stability factor": 0.8522931825,
      "Direction stability factor": 0.9715581239,
      score: 0.8877682964,
    },
    "292.5": {
      velocity: [1.1219376966, 1.1292173978, 1.1349020378],
      direction: [-7.1658509256, -6.7999428048, -6.4169474241],
      "speed factor": 1.1286621355,
      "Uz factor": 0.968137379,
      "Directional variance factor": 0.3955606396,
      "Velocity stability factor": 0.9867829358,
      "Direction stability factor": 0.9979197125,
      score: 0.8445457501,
    },
    "315.0": {
      velocity: [0.8157116974, 0.8212314914, 0.8304146262],
      direction: [1.7673649287, 2.166374453, 2.4633557506],
      "speed factor": 1.0205813557,
      "Uz factor": 0.9980849012,
      "Directional variance factor": 0.807433382,
      "Velocity stability factor": 0.9763346131,
      "Direction stability factor": 0.9980666922,
      score: 0.9449753448,
    },
    "337.5": {
      velocity: [1.0701442638, 1.141940766, 1.1888861963],
      direction: [-1.0016195141, 0.8800781901, 2.2069426875],
      "speed factor": 1.1416762049,
      "Uz factor": 0.9959670445,
      "Directional variance factor": 0.9217708275,
      "Velocity stability factor": 0.8816577089,
      "Direction stability factor": 0.9910873272,
      score: 0.9464007977,
    },
    id: "IDN:3156.0",
    zone: 1,
  },
  {
    position: [-11.45866, 6.2679947, 21.1598299],
    "overall score": 0.4812734947,
    "0": {
      velocity: [0.7005625777, 0.9141047765, 1.0439724348],
      direction: [-11.0836272766, -2.6402558749, 8.8430945906],
      "speed factor": 1.5141027651,
      "Uz factor": 0.999817299,
      "Directional variance factor": 0.7653105889,
      "Velocity stability factor": 0.6185389428,
      "Direction stability factor": 0.9446479948,
      score: 0.8182863803,
    },
    "22.5": {
      velocity: [0.1247268696, 0.8361802407, 1.3082733381],
      direction: [-174.2624224574, 3.8537683184, 66.4731145],
      "speed factor": 1.1063532114,
      "Uz factor": 0.9976321923,
      "Directional variance factor": 0.6574428161,
      "Velocity stability factor": 0.0,
      "Direction stability factor": 0.3312901751,
      score: 0.3300057916,
    },
    "45.0": {
      velocity: [0.3458311779, 0.6977929835, 1.0455545827],
      direction: [-28.3301965006, -14.1657678418, 34.8767975827],
      "speed factor": 1.1246957482,
      "Uz factor": 0.9995763744,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2457855572,
      "Direction stability factor": 0.8244250164,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.5742089778, 0.9014088356, 0.9689014165],
      direction: [-4.9646521156, -3.0263039228, 2.9474924542],
      "speed factor": 1.2250338742,
      "Uz factor": 0.9993150421,
      "Directional variance factor": 0.7309952069,
      "Velocity stability factor": 0.5989599685,
      "Direction stability factor": 0.9780218206,
      score: 0.8214997042,
    },
    "90.0": {
      velocity: [0.3695386426, 0.730946545, 0.9749674151],
      direction: [-18.9724911212, 2.8817968145, 42.5919464437],
      "speed factor": 1.0559144549,
      "Uz factor": 0.999381387,
      "Directional variance factor": 0.7438402832,
      "Velocity stability factor": 0.3846203488,
      "Direction stability factor": 0.8289876734,
      score: 0.6966089947,
    },
    "112.5": {
      velocity: [1.0395752399, 1.1677858721, 1.3000284559],
      direction: [8.9498497231, 14.7017015724, 22.0045315383],
      "speed factor": 1.1160674618,
      "Uz factor": 0.9982241429,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7890766776,
      "Direction stability factor": 0.963736995,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.2037157591, 1.2178793614, 1.2275819275],
      direction: [2.1017403171, 2.82359437, 3.446853153],
      "speed factor": 1.4087281715,
      "Uz factor": 0.992901821,
      "Directional variance factor": 0.7490138338,
      "Velocity stability factor": 0.9756890838,
      "Direction stability factor": 0.9962635755,
      score: 0.9293075171,
    },
    "157.5": {
      velocity: [0.4094886584, 0.8659854047, 1.0749868943],
      direction: [-39.956141717, -11.5672108998, 2.7859203011],
      "speed factor": 0.8305933008,
      "Uz factor": 0.998821726,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5035022874,
      "Direction stability factor": 0.8812720499,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0409361325, 0.3692744035, 0.8207029695],
      direction: [-128.9571084614, 3.9372291639, 164.532890666],
      "speed factor": 0.5168479788,
      "Uz factor": 0.9983881836,
      "Directional variance factor": 0.6500240743,
      "Velocity stability factor": 0.2376206917,
      "Direction stability factor": 0.1847500024,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1096353571, 1.1551887654, 1.1885193555],
      direction: [-3.1013158828, -1.8810438378, -0.0974735162],
      "speed factor": 1.3738539688,
      "Uz factor": 0.987494901,
      "Directional variance factor": 0.8327961033,
      "Velocity stability factor": 0.9369810056,
      "Direction stability factor": 0.9916559934,
      score: 0.9382722739,
    },
    "225.0": {
      velocity: [1.0689212656, 1.1214457674, 1.2052885876],
      direction: [-14.5205325987, -9.2067602074, -5.6994469013],
      "speed factor": 1.3580135566,
      "Uz factor": 0.9989803422,
      "Directional variance factor": 0.1816213149,
      "Velocity stability factor": 0.8719065047,
      "Direction stability factor": 0.9754969842,
      score: 0.751130447,
    },
    "247.5": {
      velocity: [0.0949242153, 0.4512736578, 0.9590911643],
      direction: [-139.0274697214, -1.9953688437, 82.1095483426],
      "speed factor": 0.5653843402,
      "Uz factor": 0.9982196895,
      "Directional variance factor": 0.8226338806,
      "Velocity stability factor": 0.3147461581,
      "Direction stability factor": 0.3857305054,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.5709227329, 0.9567916368, 1.0537453169],
      direction: [-3.3085011965, 5.1365505971, 9.5490367511],
      "speed factor": 1.3418581197,
      "Uz factor": 0.9991644244,
      "Directional variance factor": 0.5434177247,
      "Velocity stability factor": 0.5883115968,
      "Direction stability factor": 0.9642846168,
      score: 0.7650746388,
    },
    "292.5": {
      velocity: [1.1224877325, 1.1397896859, 1.1522932743],
      direction: [4.3379304934, 5.5156045683, 6.2871373954],
      "speed factor": 1.139229225,
      "Uz factor": 0.9994234363,
      "Directional variance factor": 0.5097240384,
      "Velocity stability factor": 0.9696134378,
      "Direction stability factor": 0.9945855364,
      score: 0.8671271372,
    },
    "315.0": {
      velocity: [0.8598637934, 0.8677342566, 0.8770925145],
      direction: [8.7155747012, 9.3745254968, 9.9262367612],
      "speed factor": 1.0783724361,
      "Uz factor": 0.9993930576,
      "Directional variance factor": 0.1667088447,
      "Velocity stability factor": 0.9722691746,
      "Direction stability factor": 0.9966370498,
      score: 0.7830630298,
    },
    "337.5": {
      velocity: [0.5964435304, 0.7311348136, 0.8450948358],
      direction: [-12.8060538328, 1.1372515831, 9.8217183114],
      "speed factor": 0.7309654267,
      "Uz factor": 0.9991732103,
      "Directional variance factor": 0.8989109704,
      "Velocity stability factor": 0.7521855628,
      "Direction stability factor": 0.9371450774,
      score: 0.0,
    },
    id: "IDN:1901.0",
    zone: 1,
  },
  {
    position: [75.45189457, -56.06059214, 16.6522295],
    "overall score": 0.4803076067,
    "0": {
      velocity: [0.1782250216, 0.4724458844, 0.8827031436],
      direction: [-30.3618091753, -8.9057984681, 22.0712566652],
      "speed factor": 0.7825488263,
      "Uz factor": 0.999999936,
      "Directional variance factor": 0.2083734695,
      "Velocity stability factor": 0.2174628549,
      "Direction stability factor": 0.8543525949,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.9607244734, 0.9986950139, 1.0186575114],
      direction: [-3.3374998744, -1.6968045822, -0.8666180663],
      "speed factor": 1.321377117,
      "Uz factor": 0.9939057885,
      "Directional variance factor": 0.849172926,
      "Velocity stability factor": 0.9510513195,
      "Direction stability factor": 0.9931364394,
      score: 0.9466242811,
    },
    "45.0": {
      velocity: [0.3722128337, 0.542236732, 0.7107681224],
      direction: [-28.3524309434, -4.1404242421, 7.8339435617],
      "speed factor": 0.8739717386,
      "Uz factor": 0.9899243079,
      "Directional variance factor": 0.6319622896,
      "Velocity stability factor": 0.6350796805,
      "Direction stability factor": 0.899482293,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.9154727045, 0.9233601057, 0.9319588867],
      direction: [-8.0746686511, -7.5863485487, -7.0460425865],
      "speed factor": 1.2548661196,
      "Uz factor": 0.9531312225,
      "Directional variance factor": 0.3256579068,
      "Velocity stability factor": 0.9832486808,
      "Direction stability factor": 0.9971427054,
      score: 0.8257979996,
    },
    "90.0": {
      velocity: [0.9468697079, 0.9562487016, 0.9687837539],
      direction: [-11.2755081498, -10.6744175786, -10.2519961202],
      "speed factor": 1.3813825833,
      "Uz factor": 0.9520301678,
      "Directional variance factor": 0.0511628819,
      "Velocity stability factor": 0.9777257729,
      "Direction stability factor": 0.997156911,
      score: 0.7558006192,
    },
    "112.5": {
      velocity: [1.1919079488, 1.1941087741, 1.196461378],
      direction: [-8.3077552577, -8.247742561, -8.1780752975],
      "speed factor": 1.1412245861,
      "Uz factor": 0.959099042,
      "Directional variance factor": 0.2668673279,
      "Velocity stability factor": 0.9963124879,
      "Direction stability factor": 0.9996397779,
      score: 0.8156148429,
    },
    "135.0": {
      velocity: [1.1335809005, 1.1346476067, 1.1355639491],
      direction: [-0.9754212918, -0.9249882103, -0.8816113417],
      "speed factor": 1.3124535146,
      "Uz factor": 0.9809913404,
      "Directional variance factor": 0.9177788258,
      "Velocity stability factor": 0.9979799971,
      "Direction stability factor": 0.9997394168,
      score: 0.9788094141,
    },
    "157.5": {
      velocity: [1.1214938364, 1.1269752492, 1.1326706389],
      direction: [3.8573349426, 4.7793466398, 5.3388090419],
      "speed factor": 1.0809167073,
      "Uz factor": 0.9868493726,
      "Directional variance factor": 0.5751691876,
      "Velocity stability factor": 0.9916615002,
      "Direction stability factor": 0.9958847942,
      score: 0.889650069,
    },
    "180.0": {
      velocity: [1.095177359, 1.0989898609, 1.1028803419],
      direction: [12.8431939488, 13.3760619917, 13.6775210854],
      "speed factor": 1.5381805043,
      "Uz factor": 0.9641659826,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9924687811,
      "Direction stability factor": 0.9976824246,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.9244137109, 0.9455872804, 0.9598708436],
      direction: [10.4800306216, 11.8635634384, 13.8022883143],
      "speed factor": 1.1245771054,
      "Uz factor": 0.951122326,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9716739403,
      "Direction stability factor": 0.9907715064,
      score: 0.0,
    },
    "225.0": {
      velocity: [0.83970248, 0.8564791094, 0.8772141691],
      direction: [7.2329900621, 8.4249863874, 9.3964056678],
      "speed factor": 1.0371524645,
      "Uz factor": 0.9641848151,
      "Directional variance factor": 0.2511123211,
      "Velocity stability factor": 0.964764261,
      "Direction stability factor": 0.9939905122,
      score: 0.8009644016,
    },
    "247.5": {
      velocity: [0.8237799368, 0.8389965608, 0.8486178575],
      direction: [-1.0575211138, -0.7262819467, -0.2475684553],
      "speed factor": 1.051148253,
      "Uz factor": 0.9887736544,
      "Directional variance factor": 0.9354416047,
      "Velocity stability factor": 0.9803044069,
      "Direction stability factor": 0.9977501315,
      score: 0.9778115687,
    },
    "270.0": {
      velocity: [0.4480646896, 0.8059697596, 1.1245859405],
      direction: [-25.933049056, 3.2308054269, 38.9702303333],
      "speed factor": 1.1303370813,
      "Uz factor": 0.9997556627,
      "Directional variance factor": 0.7128172954,
      "Velocity stability factor": 0.4231505262,
      "Direction stability factor": 0.8197131128,
      score: 0.6938485118,
    },
    "292.5": {
      velocity: [0.8592321645, 0.986006249, 1.0469561697],
      direction: [-0.0182799381, 6.5196368596, 9.7764488436],
      "speed factor": 0.9855214069,
      "Uz factor": 0.9999944442,
      "Directional variance factor": 0.4204767236,
      "Velocity stability factor": 0.8086165588,
      "Direction stability factor": 0.9727924201,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.3920028112, 0.6060612339, 0.8240945836],
      direction: [-19.9118674835, -5.7124947525, 16.5728839887],
      "speed factor": 0.7531795873,
      "Uz factor": 0.999883332,
      "Directional variance factor": 0.4922226887,
      "Velocity stability factor": 0.3045182271,
      "Direction stability factor": 0.8986534681,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.1781525981, 0.4131545106, 0.8479697413],
      direction: [-36.1841639268, -9.2141904516, 30.0856990503],
      "speed factor": 0.4130587923,
      "Uz factor": 0.9996978077,
      "Directional variance factor": 0.1809608487,
      "Velocity stability factor": 0.3324372131,
      "Direction stability factor": 0.8159170473,
      score: 0.0,
    },
    id: "IDN:3227.0",
    zone: 1,
  },
  {
    position: [40.84646225, 4.749260259, 21.1598299],
    "overall score": 0.4800136687,
    "0": {
      velocity: [0.551563633, 0.7511621362, 0.856130988],
      direction: [-18.2620550271, -10.5950402618, -2.9526296975],
      "speed factor": 1.2442082098,
      "Uz factor": 0.9987817575,
      "Directional variance factor": 0.0582186434,
      "Velocity stability factor": 0.6616853511,
      "Direction stability factor": 0.9574738185,
      score: 0.6587129079,
    },
    "22.5": {
      velocity: [0.453356476, 0.7611128467, 1.0599566989],
      direction: [-26.4466731792, -1.5270812286, 13.220171541],
      "speed factor": 1.0070312609,
      "Uz factor": 0.9986723569,
      "Directional variance factor": 0.8642594463,
      "Velocity stability factor": 0.4874724068,
      "Direction stability factor": 0.8898143202,
      score: 0.7828401234,
    },
    "45.0": {
      velocity: [0.4433635571, 0.6773373734, 0.872529022],
      direction: [-32.891691911, -5.3117518842, 8.2420911974],
      "speed factor": 1.0917256005,
      "Uz factor": 0.9977262656,
      "Directional variance factor": 0.527844277,
      "Velocity stability factor": 0.5374132268,
      "Direction stability factor": 0.8857394914,
      score: 0.7091841216,
    },
    "67.5": {
      velocity: [0.6069651108, 0.8662057133, 1.0345071326],
      direction: [-5.1897836724, 1.4076559654, 11.6373331418],
      "speed factor": 1.1771920786,
      "Uz factor": 0.9961276336,
      "Directional variance factor": 0.8748750253,
      "Velocity stability factor": 0.565582086,
      "Direction stability factor": 0.9532580088,
      score: 0.8367432823,
    },
    "90.0": {
      velocity: [0.261389286, 0.8223587475, 1.0060298204],
      direction: [-27.7025875111, 6.0224263411, 58.2136092619],
      "speed factor": 1.1879671566,
      "Uz factor": 0.9986579826,
      "Directional variance factor": 0.4646732141,
      "Velocity stability factor": 0.2431204905,
      "Direction stability factor": 0.7613438979,
      score: 0.5576203751,
    },
    "112.5": {
      velocity: [0.3528615476, 0.8266855275, 1.060992515],
      direction: [-18.3748688237, 9.3717425705, 64.3532402159],
      "speed factor": 0.7900736259,
      "Uz factor": 0.998635074,
      "Directional variance factor": 0.166956216,
      "Velocity stability factor": 0.4265329543,
      "Direction stability factor": 0.7701996971,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.9916231038, 1.0412550259, 1.0920794513],
      direction: [5.6881650369, 7.8773533079, 9.586084272],
      "speed factor": 1.2044257709,
      "Uz factor": 0.9866919879,
      "Directional variance factor": 0.2997908171,
      "Velocity stability factor": 0.8976716407,
      "Direction stability factor": 0.9891724466,
      score: 0.7939518377,
    },
    "157.5": {
      velocity: [1.1159189194, 1.1455847656, 1.1849889449],
      direction: [-1.073587096, -0.3001291728, 0.5712329013],
      "speed factor": 1.0987656681,
      "Uz factor": 0.982794677,
      "Directional variance factor": 0.9733218513,
      "Velocity stability factor": 0.9484700217,
      "Direction stability factor": 0.9954310556,
      score: 0.978163496,
    },
    "180.0": {
      velocity: [0.0796932274, 0.4883620879, 0.853013525],
      direction: [-141.4446347508, -7.1834280633, 152.6184175609],
      "speed factor": 0.6835268181,
      "Uz factor": 0.9838946,
      "Directional variance factor": 0.361473061,
      "Velocity stability factor": 0.2439234837,
      "Direction stability factor": 0.183158188,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.0721327397, 0.573732637, 0.8858581354],
      direction: [-31.7035411448, 15.3197098451, 111.4203821663],
      "speed factor": 0.6823342504,
      "Uz factor": 0.9995288279,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3499295524,
      "Direction stability factor": 0.6024335464,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1243994384, 1.1410982915, 1.1504988107],
      direction: [1.6386747909, 2.3961958298, 3.0083409677],
      "speed factor": 1.3818117597,
      "Uz factor": 0.9843344608,
      "Directional variance factor": 0.7870048151,
      "Velocity stability factor": 0.9754841572,
      "Direction stability factor": 0.9961953717,
      score: 0.9387199289,
    },
    "247.5": {
      velocity: [0.6855856867, 0.9536962351, 1.168817964],
      direction: [-20.9393765262, -7.0080023815, 4.5911809364],
      "speed factor": 1.1948513,
      "Uz factor": 0.9964228205,
      "Directional variance factor": 0.377066455,
      "Velocity stability factor": 0.6168138865,
      "Direction stability factor": 0.9290817848,
      score: 0.7130109778,
    },
    "270.0": {
      velocity: [0.6581092745, 0.9414288087, 1.0399746497],
      direction: [-1.884647968, 7.9770007231, 19.7588166313],
      "speed factor": 1.3203124301,
      "Uz factor": 0.9998933816,
      "Directional variance factor": 0.2909332691,
      "Velocity stability factor": 0.6743947947,
      "Direction stability factor": 0.939879265,
      score: 0.7112716485,
    },
    "292.5": {
      velocity: [0.5452939018, 0.5831708853, 0.6146043664],
      direction: [7.844456639, 11.4891706717, 15.1496596021],
      "speed factor": 0.5828841267,
      "Uz factor": 0.9985435745,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9293384177,
      "Direction stability factor": 0.9797077695,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4866038916, 0.5117595007, 0.5457270341],
      direction: [9.0795590391, 11.7494739415, 14.959226373],
      "speed factor": 0.6359865768,
      "Uz factor": 0.9997599609,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9048371883,
      "Direction stability factor": 0.9836675907,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3498316091, 0.6137180514, 0.8035215503],
      direction: [-53.286640739, -6.4897434214, 7.5037729906],
      "speed factor": 0.6135758671,
      "Uz factor": 0.9990665909,
      "Directional variance factor": 0.4231339181,
      "Velocity stability factor": 0.5478370111,
      "Direction stability factor": 0.8311377396,
      score: 0.0,
    },
    id: "IDN:3576.0",
    zone: 1,
  },
  {
    position: [48.54134, 8.2679947, 21.1598299],
    "overall score": 0.4799179184,
    "0": {
      velocity: [0.3832046347, 0.7184040436, 0.8360999083],
      direction: [-22.5625436984, -12.7090930617, -2.04230655],
      "speed factor": 1.1899484358,
      "Uz factor": 0.9979406841,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4969220997,
      "Direction stability factor": 0.9429993413,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5157087338, 0.773501281, 1.0659182019],
      direction: [-11.9480585194, 2.0004377465, 18.0768432072],
      "speed factor": 1.0234224447,
      "Uz factor": 0.9994168272,
      "Directional variance factor": 0.8221833114,
      "Velocity stability factor": 0.5351179842,
      "Direction stability factor": 0.9165974952,
      score: 0.7976240715,
    },
    "45.0": {
      velocity: [0.3971531427, 0.7132549357, 0.9006795747],
      direction: [-20.818558521, 3.4055560239, 19.4302221042],
      "speed factor": 1.149617168,
      "Uz factor": 0.9978127145,
      "Directional variance factor": 0.697283909,
      "Velocity stability factor": 0.4572613911,
      "Direction stability factor": 0.8881978316,
      score: 0.7327352408,
    },
    "67.5": {
      velocity: [0.6953152452, 0.9093821501, 1.045326991],
      direction: [-2.5175598584, 5.1064013062, 19.029372901],
      "speed factor": 1.2358697791,
      "Uz factor": 0.9996941976,
      "Directional variance factor": 0.5460976617,
      "Velocity stability factor": 0.6443592333,
      "Direction stability factor": 0.940147409,
      score: 0.7676879282,
    },
    "90.0": {
      velocity: [0.9251852531, 0.9842334941, 1.0208578252],
      direction: [4.3789456055, 5.8498312957, 9.0763349538],
      "speed factor": 1.4218089962,
      "Uz factor": 0.9988902134,
      "Directional variance factor": 0.4800149959,
      "Velocity stability factor": 0.9027549454,
      "Direction stability factor": 0.9869516963,
      score: 0.8391683335,
    },
    "112.5": {
      velocity: [0.4092690751, 0.9296575218, 1.1173783542],
      direction: [-19.9403367487, 5.6399831958, 26.8130524595],
      "speed factor": 0.8884852398,
      "Uz factor": 0.9995250862,
      "Directional variance factor": 0.4986681604,
      "Velocity stability factor": 0.4265505181,
      "Direction stability factor": 0.8701294744,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.7174146628, 0.9325768833, 1.0351789897],
      direction: [0.3829774116, 10.3058327265, 32.8706783301],
      "speed factor": 1.0787171285,
      "Uz factor": 0.9976666313,
      "Directional variance factor": 0.0839259799,
      "Velocity stability factor": 0.6763141102,
      "Direction stability factor": 0.9097563863,
      score: 0.6449382157,
    },
    "157.5": {
      velocity: [1.1779930771, 1.1911052651, 1.2060371184],
      direction: [1.2297749624, 1.8774489385, 2.7944838955],
      "speed factor": 1.1424257826,
      "Uz factor": 0.9984092055,
      "Directional variance factor": 0.8331156499,
      "Velocity stability factor": 0.979077627,
      "Direction stability factor": 0.9956535863,
      score: 0.9508751124,
    },
    "180.0": {
      velocity: [0.3006781789, 0.7258332667, 0.9290859811],
      direction: [-45.1706070641, -8.5598421402, 10.9462071545],
      "speed factor": 1.0158988904,
      "Uz factor": 0.9957732235,
      "Directional variance factor": 0.2391251431,
      "Velocity stability factor": 0.3856046668,
      "Direction stability factor": 0.8441199605,
      score: 0.5782424327,
    },
    "202.5": {
      velocity: [0.1151540562, 0.5238035604, 0.9221878185],
      direction: [-14.6556770331, 16.6080447823, 77.5801360357],
      "speed factor": 0.6229541196,
      "Uz factor": 0.9976619226,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3552753769,
      "Direction stability factor": 0.7437894081,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1232056355, 1.1504188435, 1.1774043448],
      direction: [-0.2450831737, 1.1189310841, 2.7812411418],
      "speed factor": 1.3930984722,
      "Uz factor": 0.9919873695,
      "Directional variance factor": 0.9005394592,
      "Velocity stability factor": 0.9490896938,
      "Direction stability factor": 0.9915935436,
      score: 0.95820406,
    },
    "247.5": {
      velocity: [0.7227842974, 0.9675092759, 1.2065738483],
      direction: [-23.0692591914, -8.8571631734, 11.0869138537],
      "speed factor": 1.212157156,
      "Uz factor": 0.9990767935,
      "Directional variance factor": 0.2126966068,
      "Velocity stability factor": 0.6163719882,
      "Direction stability factor": 0.9051217415,
      score: 0.6598280195,
    },
    "270.0": {
      velocity: [0.7564152231, 0.9369346541, 1.0281321384],
      direction: [0.6409553922, 7.8909065167, 13.1272067935],
      "speed factor": 1.3140095763,
      "Uz factor": 0.9999211461,
      "Directional variance factor": 0.2985860874,
      "Velocity stability factor": 0.7683150981,
      "Direction stability factor": 0.9653159683,
      score: 0.7493832805,
    },
    "292.5": {
      velocity: [0.5055032892, 0.547833524, 0.5960315835],
      direction: [5.3251056123, 12.6633298786, 16.6682762375],
      "speed factor": 0.5475641416,
      "Uz factor": 0.9990621031,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.907706974,
      "Direction stability factor": 0.9684911927,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.3945935493, 0.4194592673, 0.4372705876],
      direction: [10.8449376365, 13.3629142276, 15.305939205],
      "speed factor": 0.5212809204,
      "Uz factor": 0.9960963555,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9313083374,
      "Direction stability factor": 0.987608329,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.3343971566, 0.6189776597, 0.775202373],
      direction: [-52.1809641613, -9.3539147306, 0.9657071665],
      "speed factor": 0.618834257,
      "Uz factor": 0.9986965947,
      "Directional variance factor": 0.1685409128,
      "Velocity stability factor": 0.5606783706,
      "Direction stability factor": 0.8523703574,
      score: 0.0,
    },
    id: "IDN:1968.0",
    zone: 1,
  },
  {
    position: [-21.8936219, 7.3169522, 20.8740369],
    "overall score": 0.4797346845,
    "0": {
      velocity: [0.4717682943, 0.7721491971, 0.9513522473],
      direction: [-3.8282534277, 4.7693812447, 12.1911543511],
      "speed factor": 1.2789707093,
      "Uz factor": 0.9992724725,
      "Directional variance factor": 0.5760550005,
      "Velocity stability factor": 0.4672762068,
      "Direction stability factor": 0.9555016451,
      score: 0.7385836243,
    },
    "22.5": {
      velocity: [0.2968925795, 0.7803876846, 1.1742079722],
      direction: [-31.0548015244, 10.9666680281, 67.4338436195],
      "speed factor": 1.0325338713,
      "Uz factor": 0.9981515745,
      "Directional variance factor": 0.0251850642,
      "Velocity stability factor": 0.2587402219,
      "Direction stability factor": 0.7264204302,
      score: 0.4341915366,
    },
    "45.0": {
      velocity: [0.365107774, 0.6694925255, 1.0187262457],
      direction: [-21.6645287155, -10.3846954812, 21.6789044643],
      "speed factor": 1.0790813531,
      "Uz factor": 0.9991664266,
      "Directional variance factor": 0.0769159572,
      "Velocity stability factor": 0.2954809171,
      "Direction stability factor": 0.8796015745,
      score: 0.5329000058,
    },
    "67.5": {
      velocity: [0.5916032093, 0.8803529053, 0.9679289093],
      direction: [-7.5421385178, -4.2096404926, 3.8412728268],
      "speed factor": 1.196418415,
      "Uz factor": 0.9996834417,
      "Directional variance factor": 0.625809734,
      "Velocity stability factor": 0.617622088,
      "Direction stability factor": 0.9683794129,
      score: 0.795047662,
    },
    "90.0": {
      velocity: [0.4876060473, 0.781657618, 1.0123518874],
      direction: [-25.5761031731, 1.2022700492, 33.4212013444],
      "speed factor": 1.1291709131,
      "Uz factor": 0.9998733701,
      "Directional variance factor": 0.8931315512,
      "Velocity stability factor": 0.4666293927,
      "Direction stability factor": 0.8361185986,
      score: 0.7579995353,
    },
    "112.5": {
      velocity: [0.8606810242, 1.0768478756, 1.2181080385],
      direction: [4.1024559356, 11.9681832842, 17.0839541903],
      "speed factor": 1.029156889,
      "Uz factor": 0.9974003197,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7105442025,
      "Direction stability factor": 0.9639402826,
      score: 0.0,
    },
    "135.0": {
      velocity: [1.1472838449, 1.161620907, 1.1768363395],
      direction: [4.809194497, 5.7128617094, 6.4417721407],
      "speed factor": 1.3436536886,
      "Uz factor": 0.994096144,
      "Directional variance factor": 0.4921900703,
      "Velocity stability factor": 0.9698967924,
      "Direction stability factor": 0.9954650621,
      score: 0.8632542467,
    },
    "157.5": {
      velocity: [0.2720675481, 0.6762727269, 1.0340553574],
      direction: [-73.3052936128, -18.0557234104, 28.412639159],
      "speed factor": 0.6486340225,
      "Uz factor": 0.9953507948,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4315158419,
      "Direction stability factor": 0.7174501867,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0891162874, 0.3758312101, 0.7943414215],
      direction: [-110.641888748, 0.0962919929, 107.3874662735],
      "speed factor": 0.5260250898,
      "Uz factor": 0.9997613104,
      "Directional variance factor": 0.9914407117,
      "Velocity stability factor": 0.3105002362,
      "Direction stability factor": 0.3943629027,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1098932936, 1.172573336, 1.2553536442],
      direction: [-8.0338156731, -6.8604865744, -5.08132349],
      "speed factor": 1.3945292575,
      "Uz factor": 0.976808435,
      "Directional variance factor": 0.3901789712,
      "Velocity stability factor": 0.8837943663,
      "Direction stability factor": 0.9917986328,
      score: 0.8143926508,
    },
    "225.0": {
      velocity: [0.1323675564, 0.5703323864, 0.9041103958],
      direction: [-79.733694468, -22.1027569071, 21.8539865502],
      "speed factor": 0.6906433953,
      "Uz factor": 0.9968318993,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2750811827,
      "Direction stability factor": 0.7178119972,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.0981592473, 0.5448192264, 0.9955339791],
      direction: [-45.768509155, 5.8483992339, 67.6412216568],
      "speed factor": 0.6825841783,
      "Uz factor": 0.9983314639,
      "Directional variance factor": 0.4801422903,
      "Velocity stability factor": 0.2884135602,
      "Direction stability factor": 0.68497297,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.7212210594, 0.9387613276, 1.0114831238],
      direction: [-4.7065019606, 0.2838185124, 4.6969807481],
      "speed factor": 1.3165714054,
      "Uz factor": 0.9999910605,
      "Directional variance factor": 0.9747716878,
      "Velocity stability factor": 0.7525022032,
      "Direction stability factor": 0.9738792147,
      score: 0.9187580801,
    },
    "292.5": {
      velocity: [1.1275086084, 1.1516730693, 1.1672560651],
      direction: [1.2977654472, 2.4949546395, 3.4655740847],
      "speed factor": 1.1511067651,
      "Uz factor": 0.999723614,
      "Directional variance factor": 0.7782262543,
      "Velocity stability factor": 0.9594777182,
      "Direction stability factor": 0.9939783093,
      score: 0.9314151478,
    },
    "315.0": {
      velocity: [0.815982665, 0.8251232286, 0.8321608848],
      direction: [4.1088469927, 4.6381640653, 4.9780542645],
      "speed factor": 1.0254177928,
      "Uz factor": 0.9998764252,
      "Directional variance factor": 0.5877187497,
      "Velocity stability factor": 0.9739600296,
      "Direction stability factor": 0.9975855354,
      score: 0.8892124625,
    },
    "337.5": {
      velocity: [0.5078729567, 0.9542775949, 1.1288002486],
      direction: [-4.8671972118, 0.0152243835, 6.5054612074],
      "speed factor": 0.954056511,
      "Uz factor": 0.9995190646,
      "Directional variance factor": 0.9986467215,
      "Velocity stability factor": 0.3811625194,
      "Direction stability factor": 0.9684092822,
      score: 0.0,
    },
    id: "IDN:2145.0",
    zone: 1,
  },
  {
    position: [-17.45866, 12.2679947, 21.1598299],
    "overall score": 0.4794305966,
    "0": {
      velocity: [0.8107173527, 0.9360237691, 1.0201401442],
      direction: [-1.9551913639, 1.6491308446, 5.0134116983],
      "speed factor": 1.5504088957,
      "Uz factor": 0.9923353747,
      "Directional variance factor": 0.8534105916,
      "Velocity stability factor": 0.7673723168,
      "Direction stability factor": 0.9806427693,
      score: 0.8955171117,
    },
    "22.5": {
      velocity: [0.3518417556, 0.8459293986, 1.2251255137],
      direction: [-27.0785397287, 8.6507419356, 41.8087038337],
      "speed factor": 1.1192523588,
      "Uz factor": 0.9999977469,
      "Directional variance factor": 0.2310451613,
      "Velocity stability factor": 0.2621466235,
      "Direction stability factor": 0.8086465457,
      score: 0.527621219,
    },
    "45.0": {
      velocity: [0.492866688, 0.8339118361, 1.0609126146],
      direction: [-22.1136423639, -13.1424782372, -3.5474119734],
      "speed factor": 1.3440907526,
      "Uz factor": 0.9929877396,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3877174337,
      "Direction stability factor": 0.9484271378,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.602629612, 0.8924155993, 0.9687343469],
      direction: [-10.4361564629, -6.2952542571, -2.266004018],
      "speed factor": 1.2128118739,
      "Uz factor": 0.9994653846,
      "Directional variance factor": 0.4404218438,
      "Velocity stability factor": 0.6280074306,
      "Direction stability factor": 0.9773051321,
      score: 0.7557598847,
    },
    "90.0": {
      velocity: [0.4626941133, 0.8292141645, 1.0864702514],
      direction: [-13.0534418594, 2.7180231164, 32.7264136972],
      "speed factor": 1.1978703895,
      "Uz factor": 0.999962477,
      "Directional variance factor": 0.7583979452,
      "Velocity stability factor": 0.3659714243,
      "Direction stability factor": 0.8728337346,
      score: 0.7175092096,
    },
    "112.5": {
      velocity: [0.9494096068, 1.1334382151, 1.2056611238],
      direction: [-6.2663356198, 10.1521575591, 14.8362339828],
      "speed factor": 1.0832409794,
      "Uz factor": 0.9982598296,
      "Directional variance factor": 0.0975859947,
      "Velocity stability factor": 0.7924793476,
      "Direction stability factor": 0.9413817511,
      score: 0.6932072111,
    },
    "135.0": {
      velocity: [1.1131301179, 1.1612228396, 1.1990391465],
      direction: [-0.7604485553, 2.7197462975, 5.050371294],
      "speed factor": 1.3431932418,
      "Uz factor": 0.9996719305,
      "Directional variance factor": 0.7582447736,
      "Velocity stability factor": 0.91249005,
      "Direction stability factor": 0.9838588338,
      score: 0.9096131228,
    },
    "157.5": {
      velocity: [0.4653612603, 0.8142213587, 1.0867474438],
      direction: [-44.8122388514, -10.6426350701, 24.1321302108],
      "speed factor": 0.7809448083,
      "Uz factor": 0.999296098,
      "Directional variance factor": 0.0539879938,
      "Velocity stability factor": 0.5364122666,
      "Direction stability factor": 0.8084878637,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0858480693, 0.4310823038, 0.8474133586],
      direction: [-106.5406292989, 0.0249784209, 130.5577375577],
      "speed factor": 0.6033562448,
      "Uz factor": 0.9956954914,
      "Directional variance factor": 0.9977796959,
      "Velocity stability factor": 0.2554163745,
      "Direction stability factor": 0.3413934254,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.534149533, 0.852761788, 1.1529363754],
      direction: [-18.3010352875, -3.7924995968, 9.5511470842],
      "speed factor": 1.014180714,
      "Uz factor": 0.9998094067,
      "Directional variance factor": 0.6628889247,
      "Velocity stability factor": 0.5056624241,
      "Direction stability factor": 0.9226328267,
      score: 0.7534542506,
    },
    "225.0": {
      velocity: [0.0291299901, 0.6162577254, 0.9432851898],
      direction: [-79.0474805922, -9.8150347747, 57.8571794137],
      "speed factor": 0.7462566356,
      "Uz factor": 0.9987784373,
      "Directional variance factor": 0.1275524645,
      "Velocity stability factor": 0.1413094203,
      "Direction stability factor": 0.6197092778,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1684771799, 0.6121478412, 1.2113054559],
      direction: [-76.4402604135, 2.970996778, 47.0270414909],
      "speed factor": 0.7669377491,
      "Uz factor": 0.9999951805,
      "Directional variance factor": 0.7359113975,
      "Velocity stability factor": 0.1730740416,
      "Direction stability factor": 0.6570352725,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.8372553825, 1.0132000056, 1.2044403431],
      direction: [0.297129613, 4.6113039325, 19.2473383986],
      "speed factor": 1.4209683719,
      "Uz factor": 0.9993835452,
      "Directional variance factor": 0.5901063171,
      "Velocity stability factor": 0.686912346,
      "Direction stability factor": 0.9473605312,
      score: 0.7929349314,
    },
    "292.5": {
      velocity: [1.1293317866, 1.1368374523, 1.1433959641],
      direction: [5.2971890678, 5.8735244797, 6.3468687108],
      "speed factor": 1.136278443,
      "Uz factor": 0.9988623286,
      "Directional variance factor": 0.4779089351,
      "Velocity stability factor": 0.9856616596,
      "Direction stability factor": 0.9970842232,
      score: 0.8644347603,
    },
    "315.0": {
      velocity: [0.8790233523, 0.8825507911, 0.8884922929],
      direction: [10.094195258, 10.5402550863, 10.9034999324],
      "speed factor": 1.0967856107,
      "Uz factor": 0.9990274951,
      "Directional variance factor": 0.0630884368,
      "Velocity stability factor": 0.9847590812,
      "Direction stability factor": 0.9977519315,
      score: 0.7608378452,
    },
    "337.5": {
      velocity: [0.6346637518, 0.8417357185, 0.9805925236],
      direction: [-7.7003522124, -1.2525039164, 8.3438580632],
      "speed factor": 0.8415407079,
      "Uz factor": 0.9994503971,
      "Directional variance factor": 0.8886663185,
      "Velocity stability factor": 0.6552354963,
      "Direction stability factor": 0.9554327492,
      score: 0.0,
    },
    id: "IDN:2009.0",
    zone: 1,
  },
  {
    position: [-9.45866, 12.2679947, 21.1598299],
    "overall score": 0.478519408,
    "0": {
      velocity: [0.9178061315, 0.9797236704, 1.048577067],
      direction: [-8.4666636331, -3.748427039, -0.2721173121],
      "speed factor": 1.6227924376,
      "Uz factor": 0.9933054952,
      "Directional variance factor": 0.6668064854,
      "Velocity stability factor": 0.8547391164,
      "Direction stability factor": 0.9772373713,
      score: 0.8690050861,
    },
    "22.5": {
      velocity: [0.2686051519, 0.8798592704, 1.2128991876],
      direction: [-34.1034544426, 3.5484317233, 31.5085169467],
      "speed factor": 1.1641450993,
      "Uz factor": 0.9997173631,
      "Directional variance factor": 0.6845838468,
      "Velocity stability factor": 0.2021487447,
      "Direction stability factor": 0.8177445239,
      score: 0.6305554098,
    },
    "45.0": {
      velocity: [0.3994918463, 0.7764740307, 1.034094971],
      direction: [-29.2807900356, -17.0989160582, -4.6657084757],
      "speed factor": 1.2515130727,
      "Uz factor": 0.9945050497,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3159770864,
      "Direction stability factor": 0.9316247734,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.6108339346, 0.8953793908, 0.9682231757],
      direction: [-9.8478730746, -6.728852708, -3.2873398078],
      "speed factor": 1.2168397299,
      "Uz factor": 0.9999612674,
      "Directional variance factor": 0.4018797593,
      "Velocity stability factor": 0.6368630903,
      "Direction stability factor": 0.9817762965,
      score: 0.7505738606,
    },
    "90.0": {
      velocity: [0.4357103891, 0.8194600586, 1.1194434536],
      direction: [-10.0056893082, 2.6971909545, 15.6516519878],
      "speed factor": 1.1837797539,
      "Uz factor": 0.9999343311,
      "Directional variance factor": 0.7602496929,
      "Velocity stability factor": 0.3050290408,
      "Direction stability factor": 0.9287296075,
      score: 0.7306844872,
    },
    "112.5": {
      velocity: [0.7162751797, 1.1387284885, 1.2801016699],
      direction: [-8.2437906356, 10.7893330155, 21.5163015789],
      "speed factor": 1.0882969594,
      "Uz factor": 0.9975041214,
      "Directional variance factor": 0.0409481764,
      "Velocity stability factor": 0.543395323,
      "Direction stability factor": 0.9173330772,
      score: 0.6047524134,
    },
    "135.0": {
      velocity: [1.0487882581, 1.1388136666, 1.2171670391],
      direction: [0.1009654595, 3.1065335286, 6.6153815691],
      "speed factor": 1.3172724205,
      "Uz factor": 0.998026244,
      "Directional variance factor": 0.7238636864,
      "Velocity stability factor": 0.8284834673,
      "Direction stability factor": 0.9819043997,
      score: 0.8790389883,
    },
    "157.5": {
      velocity: [0.4375017231, 0.9595791376, 1.1245391264],
      direction: [-24.8725288559, -7.4622907401, 2.3946455462],
      "speed factor": 0.9203619356,
      "Uz factor": 0.9993249277,
      "Directional variance factor": 0.3366852675,
      "Velocity stability factor": 0.4874329025,
      "Direction stability factor": 0.9242578489,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.1050642724, 0.4113544716, 0.8002306498],
      direction: [-157.556496834, -1.577396263, 177.8450515759],
      "speed factor": 0.5757445551,
      "Uz factor": 0.9942891323,
      "Directional variance factor": 0.8597869988,
      "Velocity stability factor": 0.320334699,
      "Direction stability factor": 0.0683290322,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.6285910047, 1.0233004844, 1.1775179958],
      direction: [-8.3503780166, -3.2338595661, 3.172395137],
      "speed factor": 1.2170006097,
      "Uz factor": 0.9983344929,
      "Directional variance factor": 0.7125458163,
      "Velocity stability factor": 0.5614721911,
      "Direction stability factor": 0.9679922968,
      score: 0.8025006503,
    },
    "225.0": {
      velocity: [0.9430418987, 1.033528439, 1.1385909613],
      direction: [-9.997865628, -3.6009958734, 4.3753299192],
      "speed factor": 1.2515501615,
      "Uz factor": 0.9979880713,
      "Directional variance factor": 0.6799114779,
      "Velocity stability factor": 0.816315503,
      "Direction stability factor": 0.9600744568,
      score: 0.8540939736,
    },
    "247.5": {
      velocity: [0.0711688136, 0.6048501441, 1.0381816118],
      direction: [-51.5398027743, 3.0062152723, 46.8383800568],
      "speed factor": 0.75779473,
      "Uz factor": 0.9995182231,
      "Directional variance factor": 0.7327808647,
      "Velocity stability factor": 0.2331930353,
      "Direction stability factor": 0.7267272699,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.7020429069, 0.9767616797, 1.0731748642],
      direction: [-5.9605335019, 6.4099962466, 27.4709804418],
      "speed factor": 1.3698652252,
      "Uz factor": 0.9990936001,
      "Directional variance factor": 0.4302225559,
      "Velocity stability factor": 0.6835468597,
      "Direction stability factor": 0.9071346835,
      score: 0.7320096956,
    },
    "292.5": {
      velocity: [1.018278297, 1.0371830197, 1.0540657223],
      direction: [7.3704124832, 8.3096043915, 9.6203394329],
      "speed factor": 1.0366730128,
      "Uz factor": 0.9992816281,
      "Directional variance factor": 0.2613684985,
      "Velocity stability factor": 0.9635149453,
      "Direction stability factor": 0.9937502029,
      score: 0.8030959624,
    },
    "315.0": {
      velocity: [0.8902243508, 0.8985377833, 0.9054807967],
      direction: [15.424787331, 15.7601061771, 16.0145824368],
      "speed factor": 1.116653366,
      "Uz factor": 0.9982703393,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9754436887,
      "Direction stability factor": 0.9983616803,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.5970473456, 0.7099345402, 0.8459255698],
      direction: [-2.6252069211, 3.6170918209, 9.841316677],
      "speed factor": 0.7097700649,
      "Uz factor": 0.9999992493,
      "Directional variance factor": 0.678480727,
      "Velocity stability factor": 0.7519594077,
      "Direction stability factor": 0.9653707678,
      score: 0.0,
    },
    id: "IDN:2013.0",
    zone: 1,
  },
  {
    position: [-15.45866, 16.2679947, 21.1598299],
    "overall score": 0.4782544236,
    "0": {
      velocity: [0.8079956809, 0.8890070472, 0.9398904417],
      direction: [-2.7191030895, -0.6568456172, 1.9977217364],
      "speed factor": 1.472531446,
      "Uz factor": 0.9774237824,
      "Directional variance factor": 0.9416137229,
      "Velocity stability factor": 0.8534907666,
      "Direction stability factor": 0.9868977088,
      score: 0.9422249768,
    },
    "22.5": {
      velocity: [0.3994424154, 0.8143586724, 1.1006405915],
      direction: [-26.5225332248, 11.5055486243, 41.9302533747],
      "speed factor": 1.0774810125,
      "Uz factor": 0.9946441762,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4075448706,
      "Direction stability factor": 0.8098533706,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.5555827594, 0.8286790986, 1.0187818886],
      direction: [-19.2056932516, -8.951293735, 1.736389082],
      "speed factor": 1.3356566787,
      "Uz factor": 0.9837785501,
      "Directional variance factor": 0.2043294458,
      "Velocity stability factor": 0.5007291871,
      "Direction stability factor": 0.9418275491,
      score: 0.6471784327,
    },
    "67.5": {
      velocity: [0.7081655991, 0.9046873451, 0.974807545],
      direction: [-7.1735154784, -3.5792299086, -1.1038604298],
      "speed factor": 1.2294894387,
      "Uz factor": 0.994855647,
      "Directional variance factor": 0.6818462303,
      "Velocity stability factor": 0.7290698177,
      "Direction stability factor": 0.9831398471,
      score: 0.8442989355,
    },
    "90.0": {
      velocity: [0.5343164103, 0.8289994887, 0.9942736871],
      direction: [-18.5370572178, 3.5008911398, 17.8688952938],
      "speed factor": 1.1975602721,
      "Uz factor": 0.9999947265,
      "Directional variance factor": 0.6888096765,
      "Velocity stability factor": 0.5324828264,
      "Direction stability factor": 0.8988723541,
      score: 0.7547593028,
    },
    "112.5": {
      velocity: [0.8276559922, 1.1461430803, 1.2735053176],
      direction: [-3.8034076868, 9.7159007909, 15.1273250343],
      "speed factor": 1.0953831768,
      "Uz factor": 0.9986490827,
      "Directional variance factor": 0.1363643741,
      "Velocity stability factor": 0.6389369944,
      "Direction stability factor": 0.9474146313,
      score: 0.6675326578,
    },
    "135.0": {
      velocity: [0.8613161392, 0.9920905426, 1.1202552581],
      direction: [-7.4804819263, 1.3495071865, 7.0625168536],
      "speed factor": 1.1475569259,
      "Uz factor": 0.9999404884,
      "Directional variance factor": 0.8800438056,
      "Velocity stability factor": 0.7362355306,
      "Direction stability factor": 0.9596027812,
      score: 0.8838712246,
    },
    "157.5": {
      velocity: [0.5019446415, 0.9104464993, 1.0855096952],
      direction: [-29.3748918234, -7.1709739298, 14.1404189461],
      "speed factor": 0.8732373075,
      "Uz factor": 0.9999853733,
      "Directional variance factor": 0.3625800951,
      "Velocity stability factor": 0.564628877,
      "Direction stability factor": 0.8791241368,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0766419825, 0.4601898541, 0.8018445108],
      direction: [-177.2886346542, 0.0365622746, 127.4427270034],
      "speed factor": 0.6440960805,
      "Uz factor": 0.9993488858,
      "Directional variance factor": 0.99675002,
      "Velocity stability factor": 0.2909683053,
      "Direction stability factor": 0.1535239954,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.2752434068, 0.8458208487, 1.1582377515],
      direction: [-10.61485194, -0.9251798645, 34.0832451936],
      "speed factor": 1.0059259272,
      "Uz factor": 0.9999968157,
      "Directional variance factor": 0.9177617898,
      "Velocity stability factor": 0.2945918465,
      "Direction stability factor": 0.8758386191,
      score: 0.7410077186,
    },
    "225.0": {
      velocity: [0.2579409364, 0.7316239366, 1.0206046867],
      direction: [-52.3669852928, -3.3858715491, 31.5516520523],
      "speed factor": 0.8859592262,
      "Uz factor": 0.9976004595,
      "Directional variance factor": 0.6990336401,
      "Velocity stability factor": 0.2836094154,
      "Direction stability factor": 0.766892674,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1524624816, 0.5483173682, 0.8918313146],
      direction: [-47.0869527941, -0.5411138798, 46.7801198857],
      "speed factor": 0.6869668727,
      "Uz factor": 0.9990229912,
      "Directional variance factor": 0.9519009885,
      "Velocity stability factor": 0.4137066524,
      "Direction stability factor": 0.7392581314,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.2188099085, 0.7992328932, 1.0363509077],
      direction: [-31.4038627243, 6.3330522045, 44.7363485193],
      "speed factor": 1.120888923,
      "Uz factor": 0.9992533684,
      "Directional variance factor": 0.4370620263,
      "Velocity stability factor": 0.3029071968,
      "Direction stability factor": 0.7884994132,
      score: 0.5792420124,
    },
    "292.5": {
      velocity: [1.0037077671, 1.0231919566, 1.0391460667],
      direction: [6.5890945993, 7.2738481134, 8.0733107061],
      "speed factor": 1.0226888295,
      "Uz factor": 0.9963632033,
      "Directional variance factor": 0.3534357233,
      "Velocity stability factor": 0.9638708767,
      "Direction stability factor": 0.9958771775,
      score: 0.8272652387,
    },
    "315.0": {
      velocity: [0.8693798556, 0.8739354934, 0.8794119083],
      direction: [10.0407345037, 10.3706213732, 10.6272809027],
      "speed factor": 1.0860789924,
      "Uz factor": 0.9881323379,
      "Directional variance factor": 0.078166989,
      "Velocity stability factor": 0.9838527131,
      "Direction stability factor": 0.9983707044,
      score: 0.7646902778,
    },
    "337.5": {
      velocity: [0.5652806437, 0.7228889319, 0.8288451438],
      direction: [-6.5735401915, -1.4795797157, 10.2575815369],
      "speed factor": 0.7227214554,
      "Uz factor": 0.9922127168,
      "Directional variance factor": 0.868481803,
      "Velocity stability factor": 0.7373225603,
      "Direction stability factor": 0.9532468841,
      score: 0.0,
    },
    id: "IDN:2084.0",
    zone: 1,
  },
  {
    position: [-11.45866, 12.2679947, 21.1598299],
    "overall score": 0.4779774661,
    "0": {
      velocity: [0.9006824997, 0.9817632062, 1.0477726999],
      direction: [-7.2273731714, -3.7821706804, -0.0330317889],
      "speed factor": 1.6261706792,
      "Uz factor": 0.9925877029,
      "Directional variance factor": 0.6638070506,
      "Velocity stability factor": 0.8366116112,
      "Direction stability factor": 0.9800157184,
      score: 0.8651125247,
    },
    "22.5": {
      velocity: [0.3919649375, 0.8808035721, 1.2134492058],
      direction: [-27.14826977, 5.1703243078, 50.8426294914],
      "speed factor": 1.1653945084,
      "Uz factor": 0.9997906516,
      "Directional variance factor": 0.5404156171,
      "Velocity stability factor": 0.3059129573,
      "Direction stability factor": 0.7833586132,
      score: 0.6032614502,
    },
    "45.0": {
      velocity: [0.3412069471, 0.7888438992, 1.0339885444],
      direction: [-29.8920882014, -16.3928382366, -3.4765420046],
      "speed factor": 1.2714507029,
      "Uz factor": 0.9936384667,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2532679586,
      "Direction stability factor": 0.9266234828,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.5918209694, 0.8931614713, 0.9710198817],
      direction: [-9.5846923253, -6.6298428768, -2.6736115524],
      "speed factor": 1.2138255298,
      "Uz factor": 0.9998898098,
      "Directional variance factor": 0.4106806332,
      "Velocity stability factor": 0.6147026676,
      "Direction stability factor": 0.9808025534,
      score: 0.7467471019,
    },
    "90.0": {
      velocity: [0.4826862056, 0.8238631659, 1.0663291774],
      direction: [-9.1953722426, 3.0454167664, 17.3780529082],
      "speed factor": 1.1901404169,
      "Uz factor": 0.9999016995,
      "Directional variance factor": 0.7292962874,
      "Velocity stability factor": 0.4067642228,
      "Direction stability factor": 0.9261849301,
      score: 0.7471075926,
    },
    "112.5": {
      velocity: [0.8711480886, 1.1424717445, 1.2785724155],
      direction: [-7.3816729352, 10.5220508953, 19.6225883187],
      "speed factor": 1.0918744356,
      "Uz factor": 0.9978597016,
      "Directional variance factor": 0.0647065871,
      "Velocity stability factor": 0.6700547839,
      "Direction stability factor": 0.9249881632,
      score: 0.6461844243,
    },
    "135.0": {
      velocity: [1.0292749603, 1.0976113969, 1.1598725567],
      direction: [-1.0251234625, 3.006260143, 6.1276510542],
      "speed factor": 1.2696135144,
      "Uz factor": 0.9983184988,
      "Directional variance factor": 0.7327768762,
      "Velocity stability factor": 0.8669687073,
      "Direction stability factor": 0.9801311819,
      score: 0.8900019868,
    },
    "157.5": {
      velocity: [0.3711157776, 0.9285694125, 1.1100706159],
      direction: [-33.3981529198, -7.8295154306, 5.5391953053],
      "speed factor": 0.8906195522,
      "Uz factor": 0.9993145777,
      "Directional variance factor": 0.3040430728,
      "Velocity stability factor": 0.4486996853,
      "Direction stability factor": 0.8918406994,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0508942708, 0.404645881, 0.8778795676],
      direction: [-138.516319219, -0.108452633, 160.8498136319],
      "speed factor": 0.5663550023,
      "Uz factor": 0.9926257769,
      "Directional variance factor": 0.990359766,
      "Velocity stability factor": 0.1914551265,
      "Direction stability factor": 0.1684274087,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.9399123057, 1.0988169002, 1.1919952878],
      direction: [-8.7966115003, -3.7068251378, 0.6219830825],
      "speed factor": 1.3068114966,
      "Uz factor": 0.999051321,
      "Directional variance factor": 0.6705044322,
      "Velocity stability factor": 0.7986154816,
      "Direction stability factor": 0.9738372373,
      score: 0.8541985971,
    },
    "225.0": {
      velocity: [0.6617280952, 0.9560934836, 1.1543376941],
      direction: [-15.6029915389, -4.441665189, 5.7792714461],
      "speed factor": 1.1577803848,
      "Uz factor": 0.9967706919,
      "Directional variance factor": 0.6051853165,
      "Velocity stability factor": 0.5372785472,
      "Direction stability factor": 0.940604825,
      score: 0.7559183785,
    },
    "247.5": {
      velocity: [0.1526957961, 0.5887953987, 0.9715206702],
      direction: [-85.7419723276, 4.6600714358, 67.9424369924],
      "speed factor": 0.7376803239,
      "Uz factor": 0.9999993616,
      "Directional variance factor": 0.5857714279,
      "Velocity stability factor": 0.3507008206,
      "Direction stability factor": 0.573098863,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.6434184828, 0.9786877398, 1.1117864637],
      direction: [-7.1401713011, 5.7271576377, 30.2730838831],
      "speed factor": 1.3725664395,
      "Uz factor": 0.9994427643,
      "Directional variance factor": 0.4909193211,
      "Velocity stability factor": 0.6006366053,
      "Direction stability factor": 0.8960742912,
      score: 0.7209261272,
    },
    "292.5": {
      velocity: [1.0535081214, 1.0685299719, 1.0814877189],
      direction: [6.9941509031, 7.7503733934, 8.7631548119],
      "speed factor": 1.068004551,
      "Uz factor": 0.9993111674,
      "Directional variance factor": 0.3110779206,
      "Velocity stability factor": 0.9714749765,
      "Direction stability factor": 0.9950861003,
      score: 0.8181812744,
    },
    "315.0": {
      velocity: [0.8874219443, 0.8944434764, 0.9009187629],
      direction: [14.3127386974, 14.7845729662, 15.1118124155],
      "speed factor": 1.1115651863,
      "Uz factor": 0.9989583965,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9782759314,
      "Direction stability factor": 0.9977803508,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.6097917858, 0.7306728318, 0.8411581061],
      direction: [-11.8355513673, 2.1029278334, 10.5118397089],
      "speed factor": 0.7305035519,
      "Uz factor": 0.9999924632,
      "Directional variance factor": 0.8130730815,
      "Velocity stability factor": 0.7694123731,
      "Direction stability factor": 0.9379239137,
      score: 0.0,
    },
    id: "IDN:2012.0",
    zone: 1,
  },
  {
    position: [-24.67826041, 3.666918557, 20.8740369],
    "overall score": 0.4752738603,
    "0": {
      velocity: [0.2514524858, 0.7029022247, 0.8622436472],
      direction: [-17.8630510692, 5.4258876985, 19.561956789],
      "speed factor": 1.1642715687,
      "Uz factor": 0.9980591516,
      "Directional variance factor": 0.5176988712,
      "Velocity stability factor": 0.3215307094,
      "Direction stability factor": 0.8960416448,
      score: 0.6578282176,
    },
    "22.5": {
      velocity: [0.2829437574, 0.7419879937, 1.1476054802],
      direction: [-28.5475766186, 11.1766946718, 58.0644653302],
      "speed factor": 0.9817270963,
      "Uz factor": 0.9988179909,
      "Directional variance factor": 0.0065160292,
      "Velocity stability factor": 0.2694315384,
      "Direction stability factor": 0.7594109946,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.2841552509, 0.6042694717, 1.0015382973],
      direction: [-21.0379493499, -7.5918030767, 36.0787540321],
      "speed factor": 0.9739554876,
      "Uz factor": 0.9999484345,
      "Directional variance factor": 0.3251730598,
      "Velocity stability factor": 0.2267506691,
      "Direction stability factor": 0.8413424906,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.7038460229, 0.9135741975, 0.9890256734],
      direction: [-4.5636022659, -2.7411544913, 1.1682835006],
      "speed factor": 1.2415668611,
      "Uz factor": 0.9987576361,
      "Directional variance factor": 0.756341823,
      "Velocity stability factor": 0.7102339828,
      "Direction stability factor": 0.9840780951,
      score: 0.858682999,
    },
    "90.0": {
      velocity: [0.2950261058, 0.6872963469, 0.955973487],
      direction: [-22.7783343035, 1.4117160907, 27.9695509552],
      "speed factor": 0.9928580312,
      "Uz factor": 0.9999493595,
      "Directional variance factor": 0.8745141253,
      "Velocity stability factor": 0.3281892316,
      "Direction stability factor": 0.8590336521,
      score: 0.0,
    },
    "112.5": {
      velocity: [1.1568382, 1.1968554437, 1.2328032373],
      direction: [4.2104904608, 5.9134181581, 7.2140693627],
      "speed factor": 1.1438496124,
      "Uz factor": 0.9933139098,
      "Directional variance factor": 0.4743628304,
      "Velocity stability factor": 0.9384810896,
      "Direction stability factor": 0.9916567253,
      score: 0.8490393426,
    },
    "135.0": {
      velocity: [1.14996582, 1.1577714859, 1.1660245749],
      direction: [-4.2174108268, -3.7687362323, -3.1100403331],
      "speed factor": 1.3392010408,
      "Uz factor": 0.9866982213,
      "Directional variance factor": 0.6650012238,
      "Velocity stability factor": 0.9836419889,
      "Direction stability factor": 0.9969239709,
      score: 0.9106227886,
    },
    "157.5": {
      velocity: [0.0853438405, 0.5830318524, 0.9386474753],
      direction: [-85.322246473, -16.9449947426, 21.596631465],
      "speed factor": 0.5592038251,
      "Uz factor": 0.9943089606,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3633892925,
      "Direction stability factor": 0.7030031168,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0565502967, 0.4227763521, 0.8446795522],
      direction: [-173.6305351238, -2.621207626, 87.2255565259],
      "speed factor": 0.5917309755,
      "Uz factor": 0.9965380134,
      "Directional variance factor": 0.7670037666,
      "Velocity stability factor": 0.2294447415,
      "Direction stability factor": 0.2753997454,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1116767556, 1.1227124914, 1.1393160461],
      direction: [-5.54164468, -4.982906822, -4.4194871277],
      "speed factor": 1.3352302743,
      "Uz factor": 0.9648767906,
      "Directional variance factor": 0.5570749492,
      "Velocity stability factor": 0.9779194725,
      "Direction stability factor": 0.9968828957,
      score: 0.8821900533,
    },
    "225.0": {
      velocity: [0.4239823569, 0.7006851115, 0.9118014381],
      direction: [-61.0261579268, -14.4929258399, 8.1067994921],
      "speed factor": 0.8484938889,
      "Uz factor": 0.9385674391,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5417784094,
      "Direction stability factor": 0.8079640072,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1891109855, 0.5311843715, 0.8390316161],
      direction: [-57.9449736746, 5.8975819998, 98.4969013426],
      "speed factor": 0.6655015648,
      "Uz factor": 0.9950469922,
      "Directional variance factor": 0.4757704889,
      "Velocity stability factor": 0.4846359149,
      "Direction stability factor": 0.5654392361,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.7416865963, 0.8889037651, 1.0404117518],
      direction: [-5.5122294467, 0.5613960502, 5.939975215],
      "speed factor": 1.2466483704,
      "Uz factor": 0.9990848443,
      "Directional variance factor": 0.9500981289,
      "Velocity stability factor": 0.745285978,
      "Direction stability factor": 0.9681883204,
      score: 0.9079401869,
    },
    "292.5": {
      velocity: [0.7630615269, 1.0307279808, 1.1672107625],
      direction: [-15.9476967772, -3.9957820301, 0.9958589364],
      "speed factor": 1.030221148,
      "Uz factor": 0.9998552735,
      "Directional variance factor": 0.6448193751,
      "Velocity stability factor": 0.5879723993,
      "Direction stability factor": 0.9529345675,
      score: 0.7846652273,
    },
    "315.0": {
      velocity: [0.9227645746, 0.9317073121, 0.9445970423],
      direction: [3.9235232216, 4.1888433725, 4.4428097258],
      "speed factor": 1.157874633,
      "Uz factor": 0.9990553927,
      "Directional variance factor": 0.6276583669,
      "Velocity stability factor": 0.9648591241,
      "Direction stability factor": 0.9985575375,
      score: 0.8974081415,
    },
    "337.5": {
      velocity: [0.9366143278, 1.0988941142, 1.1637685148],
      direction: [-5.2574896649, -3.5505843063, 0.859558795],
      "speed factor": 1.0986395259,
      "Uz factor": 0.9997417722,
      "Directional variance factor": 0.6843925061,
      "Velocity stability factor": 0.7736103297,
      "Direction stability factor": 0.9830081987,
      score: 0.8560048083,
    },
    id: "IDN:3643.0",
    zone: 1,
  },
  {
    position: [2.54134, 14.2679947, 21.1598299],
    "overall score": 0.4729988326,
    "0": {
      velocity: [0.6770424755, 0.7827174003, 0.9175907529],
      direction: [-14.8446446883, -5.8090463346, 3.2469158403],
      "speed factor": 1.2964756455,
      "Uz factor": 0.9928795936,
      "Directional variance factor": 0.4836403258,
      "Velocity stability factor": 0.7327980013,
      "Direction stability factor": 0.9497456652,
      score: 0.7789824144,
    },
    "22.5": {
      velocity: [0.3473031462, 0.8134576355, 1.1379394376],
      direction: [-30.4524528626, -2.8752526206, 28.7021014876],
      "speed factor": 1.0762888473,
      "Uz factor": 0.9926642633,
      "Directional variance factor": 0.7444219893,
      "Velocity stability factor": 0.3319769757,
      "Direction stability factor": 0.8356817935,
      score: 0.686940638,
    },
    "45.0": {
      velocity: [0.3399815475, 0.7061568982, 0.9982668166],
      direction: [-31.6709836125, -18.3842213317, -1.1457159423],
      "speed factor": 1.1381766221,
      "Uz factor": 0.9908282598,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2904506923,
      "Direction stability factor": 0.9152075898,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.6868201003, 0.8896704326, 0.9691977859],
      direction: [-9.5348911671, -6.8521544679, -2.0341680572],
      "speed factor": 1.2090811337,
      "Uz factor": 0.9994895656,
      "Directional variance factor": 0.3909196029,
      "Velocity stability factor": 0.7130810099,
      "Direction stability factor": 0.979164658,
      score: 0.7655824822,
    },
    "90.0": {
      velocity: [0.4763516308, 0.8411524796, 0.9999642009],
      direction: [-6.8834712055, 3.8044266335, 21.4193237895],
      "speed factor": 1.215116301,
      "Uz factor": 0.9999421239,
      "Directional variance factor": 0.6618287437,
      "Velocity stability factor": 0.4677812892,
      "Direction stability factor": 0.921381125,
      score: 0.7430930707,
    },
    "112.5": {
      velocity: [0.4947118575, 0.896056156, 1.1826593873],
      direction: [-6.3020488649, 11.5363978894, 34.8710116454],
      "speed factor": 0.8563719972,
      "Uz factor": 0.9966309511,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4428781459,
      "Direction stability factor": 0.8856303875,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.8399943948, 1.087346527, 1.2685687154],
      direction: [-1.0109904809, 6.5956266362, 11.4391466507],
      "speed factor": 1.2577400794,
      "Uz factor": 0.9989172001,
      "Directional variance factor": 0.4137220768,
      "Velocity stability factor": 0.5634391637,
      "Direction stability factor": 0.9654162857,
      score: 0.726998453,
    },
    "157.5": {
      velocity: [0.7873152733, 1.0490369818, 1.2091702643],
      direction: [-16.9153377185, -6.5617670085, 1.4984678537],
      "speed factor": 1.006163712,
      "Uz factor": 0.9998713222,
      "Directional variance factor": 0.4167318215,
      "Velocity stability factor": 0.6852733384,
      "Direction stability factor": 0.9488505401,
      score: 0.74992656,
    },
    "180.0": {
      velocity: [0.0940972476, 0.4152869986, 0.7881385872],
      direction: [-111.8581124694, -7.9673388542, 153.8631703164],
      "speed factor": 0.5812486427,
      "Uz factor": 0.9954645391,
      "Directional variance factor": 0.2917921019,
      "Velocity stability factor": 0.3214346503,
      "Direction stability factor": 0.2618853256,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3503777646, 0.8756402974, 1.1316937595],
      direction: [-13.7186669965, 0.1044881268, 23.2236347184],
      "speed factor": 1.041389887,
      "Uz factor": 0.9991739736,
      "Directional variance factor": 0.9907121665,
      "Velocity stability factor": 0.3758208344,
      "Direction stability factor": 0.8973824952,
      score: 0.7903244978,
    },
    "225.0": {
      velocity: [1.0846164915, 1.1174332497, 1.139553646],
      direction: [-4.2589359498, -3.0006507124, -1.2545163853],
      "speed factor": 1.3531546025,
      "Uz factor": 0.9977802798,
      "Directional variance factor": 0.7332754922,
      "Velocity stability factor": 0.9483960524,
      "Direction stability factor": 0.9916543901,
      score: 0.9162450812,
    },
    "247.5": {
      velocity: [0.0887823033, 0.5578482566, 1.0542194138],
      direction: [-69.8084066088, -5.2315138364, 86.110017373],
      "speed factor": 0.6989077759,
      "Uz factor": 0.9956961538,
      "Directional variance factor": 0.5349765479,
      "Velocity stability factor": 0.2344425,
      "Direction stability factor": 0.5668932667,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.3554529076, 0.8151172874, 1.0099250515],
      direction: [-12.3305514344, 9.9553986502, 29.3963827943],
      "speed factor": 1.1431660861,
      "Uz factor": 0.9991939408,
      "Directional variance factor": 0.1150756755,
      "Velocity stability factor": 0.4419511415,
      "Direction stability factor": 0.8840918494,
      score: 0.5813026289,
    },
    "292.5": {
      velocity: [0.591195899, 0.6683224785, 0.7817276022],
      direction: [7.3115376089, 14.4535575208, 19.2293169252],
      "speed factor": 0.6679938489,
      "Uz factor": 0.9997166468,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.8057541284,
      "Direction stability factor": 0.9668950575,
      score: 0.0,
    },
    "315.0": {
      velocity: [1.0183462138, 1.0261388317, 1.0347468733],
      direction: [7.0393995953, 7.3663769115, 7.8441722005],
      "speed factor": 1.2752289348,
      "Uz factor": 0.9902682943,
      "Directional variance factor": 0.3452109412,
      "Velocity stability factor": 0.9736019972,
      "Direction stability factor": 0.9977645205,
      score: 0.8285854949,
    },
    "337.5": {
      velocity: [0.4373886183, 0.6481808368, 0.833868882],
      direction: [-5.8730013311, 7.3781448609, 16.8524770753],
      "speed factor": 0.6480306684,
      "Uz factor": 0.9952769294,
      "Directional variance factor": 0.3441649012,
      "Velocity stability factor": 0.604854142,
      "Direction stability factor": 0.9368736711,
      score: 0.0,
    },
    id: "IDN:2056.0",
    zone: 1,
  },
  {
    position: [-19.45866, 14.2679947, 21.1598299],
    "overall score": 0.4728427447,
    "0": {
      velocity: [0.7602875524, 0.8770118137, 0.9587060601],
      direction: [-2.7706467209, 0.8732755406, 3.1146627549],
      "speed factor": 1.4526628089,
      "Uz factor": 0.9869757262,
      "Directional variance factor": 0.9223755075,
      "Velocity stability factor": 0.7795959197,
      "Direction stability factor": 0.9836519181,
      score: 0.9173188159,
    },
    "22.5": {
      velocity: [0.4010605973, 0.8148757316, 1.1751727978],
      direction: [-28.9211416329, 10.6364146149, 43.3683800263],
      "speed factor": 1.0781651355,
      "Uz factor": 0.998069824,
      "Directional variance factor": 0.0545409231,
      "Velocity stability factor": 0.3459384815,
      "Direction stability factor": 0.7991957732,
      score: 0.4997177377,
    },
    "45.0": {
      velocity: [0.6115022395, 0.8585769117, 1.0466612297],
      direction: [-19.3856370283, -9.1671341592, 1.3286375632],
      "speed factor": 1.3838456747,
      "Uz factor": 0.9892981752,
      "Directional variance factor": 0.1851436303,
      "Velocity stability factor": 0.5309529551,
      "Direction stability factor": 0.9424603484,
      score: 0.6502543205,
    },
    "67.5": {
      velocity: [0.5962617998, 0.897929559, 0.9788976847],
      direction: [-9.4007312316, -4.8650836029, 0.4445594871],
      "speed factor": 1.220305463,
      "Uz factor": 0.9970552348,
      "Directional variance factor": 0.5675481242,
      "Velocity stability factor": 0.6112104204,
      "Direction stability factor": 0.9726519702,
      score: 0.7810156213,
    },
    "90.0": {
      velocity: [0.4589953289, 0.8292331748, 1.0980628662],
      direction: [-9.7546873915, 3.3819653247, 23.5293985287],
      "speed factor": 1.1978978516,
      "Uz factor": 0.9999346564,
      "Directional variance factor": 0.69938086,
      "Velocity stability factor": 0.3504286944,
      "Direction stability factor": 0.9075442058,
      score: 0.7162244915,
    },
    "112.5": {
      velocity: [0.9845992639, 1.1464676087, 1.2391721068],
      direction: [0.7119493231, 8.858214089, 14.2056103499],
      "speed factor": 1.0956933327,
      "Uz factor": 0.9992172396,
      "Directional variance factor": 0.2126031921,
      "Velocity stability factor": 0.7938387913,
      "Direction stability factor": 0.9625176083,
      score: 0.7328693,
    },
    "135.0": {
      velocity: [1.032146316, 1.1290506806, 1.1764805668],
      direction: [-1.522133679, 2.5065920002, 5.745632047],
      "speed factor": 1.3059795175,
      "Uz factor": 0.9999986568,
      "Directional variance factor": 0.7771918222,
      "Velocity stability factor": 0.8529760692,
      "Direction stability factor": 0.9798117619,
      score: 0.8974478538,
    },
    "157.5": {
      velocity: [0.5305248233, 0.8247623532, 1.0416556507],
      direction: [-44.3496394992, -10.6270358752, 16.8310653757],
      "speed factor": 0.7910550012,
      "Uz factor": 0.9999916864,
      "Directional variance factor": 0.0553745889,
      "Velocity stability factor": 0.6186687313,
      "Direction stability factor": 0.8300535976,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0964166746, 0.4383014533, 0.8598990004],
      direction: [-95.3815388959, 0.7199405942, 128.7144200634],
      "speed factor": 0.6134603917,
      "Uz factor": 0.9982678134,
      "Directional variance factor": 0.9360052805,
      "Velocity stability factor": 0.2535420848,
      "Direction stability factor": 0.3775112251,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3855515879, 0.6293811779, 0.9543492745],
      direction: [-31.5670839572, -5.1794347999, 18.1166827448],
      "speed factor": 0.7485164806,
      "Uz factor": 0.9963795229,
      "Directional variance factor": 0.5396057956,
      "Velocity stability factor": 0.5455978533,
      "Direction stability factor": 0.8619895369,
      score: 0.0,
    },
    "225.0": {
      velocity: [0.2597612634, 0.7452681147, 1.031772095],
      direction: [-70.6830466646, -3.0882908915, 38.3071017205],
      "speed factor": 0.9024816291,
      "Uz factor": 0.9999907639,
      "Directional variance factor": 0.7254852541,
      "Velocity stability factor": 0.2748294504,
      "Direction stability factor": 0.6972495878,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.1927819817, 0.6012396847, 1.005021296],
      direction: [-33.0037643411, 1.8158481692, 75.3738542502],
      "speed factor": 0.7532713168,
      "Uz factor": 0.9986036981,
      "Directional variance factor": 0.8385912738,
      "Velocity stability factor": 0.3559229368,
      "Direction stability factor": 0.6989510595,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.6829821796, 1.003306846, 1.1461204119],
      direction: [-7.0576846242, 4.5592914485, 23.989576191],
      "speed factor": 1.4070936515,
      "Uz factor": 0.9998884764,
      "Directional variance factor": 0.594729649,
      "Velocity stability factor": 0.6050958558,
      "Direction stability factor": 0.9137576088,
      score: 0.7568351806,
    },
    "292.5": {
      velocity: [1.1270590837, 1.1351024827, 1.1418617069],
      direction: [6.3306120157, 6.8629620146, 7.3391499332],
      "speed factor": 1.1345443266,
      "Uz factor": 0.9999933208,
      "Directional variance factor": 0.389958932,
      "Velocity stability factor": 0.9849088189,
      "Direction stability factor": 0.9971985058,
      score: 0.8423161906,
    },
    "315.0": {
      velocity: [0.8623088461, 0.8657448303, 0.8715665185],
      direction: [9.6829815158, 10.0716277828, 10.386010914],
      "speed factor": 1.0759000865,
      "Uz factor": 0.9950154559,
      "Directional variance factor": 0.1047441971,
      "Velocity stability factor": 0.985099132,
      "Direction stability factor": 0.9980471406,
      score: 0.7714844026,
    },
    "337.5": {
      velocity: [0.6303282738, 0.8488827273, 0.9884029284],
      direction: [-6.3000448372, -1.9525814124, 7.0947725682],
      "speed factor": 0.8486860609,
      "Uz factor": 0.9957799275,
      "Directional variance factor": 0.8264372078,
      "Velocity stability factor": 0.643130492,
      "Direction stability factor": 0.9627921739,
      score: 0.0,
    },
    id: "IDN:2045.0",
    zone: 1,
  },
  {
    position: [48.56330862, 5.059418454, 21.1598299],
    "overall score": 0.4727746141,
    "0": {
      velocity: [0.2471631181, 0.7009558526, 0.8256953715],
      direction: [-21.5856279176, -11.2580372691, -0.3678029832],
      "speed factor": 1.1610476414,
      "Uz factor": 0.9959761167,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3573640348,
      "Direction stability factor": 0.9410615974,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.5223624744, 0.8154057855, 1.0882596535],
      direction: [-11.9834364352, 2.2704791859, 23.1933737763],
      "speed factor": 1.0788664518,
      "Uz factor": 0.9982840844,
      "Directional variance factor": 0.7981796279,
      "Velocity stability factor": 0.521863151,
      "Direction stability factor": 0.9022866383,
      score: 0.7811540139,
    },
    "45.0": {
      velocity: [0.4046298699, 0.7515949864, 0.9054677451],
      direction: [-12.9747628699, 4.3189998077, 17.0446875341],
      "speed factor": 1.211413278,
      "Uz factor": 0.9987898398,
      "Directional variance factor": 0.616088906,
      "Velocity stability factor": 0.4601593196,
      "Direction stability factor": 0.9166126378,
      score: 0.7273683753,
    },
    "67.5": {
      velocity: [0.7681934292, 0.9289003271, 1.0668406457],
      direction: [-2.1334222269, 1.5790645681, 7.9803198589],
      "speed factor": 1.2623953988,
      "Uz factor": 0.9997737521,
      "Directional variance factor": 0.8596387051,
      "Velocity stability factor": 0.6965498262,
      "Direction stability factor": 0.971906272,
      score: 0.8750002688,
    },
    "90.0": {
      velocity: [0.899341579, 0.9839516644, 1.0380876502],
      direction: [3.5570447866, 6.0468626908, 11.1863189975],
      "speed factor": 1.4214018693,
      "Uz factor": 0.9969899498,
      "Directional variance factor": 0.4625010942,
      "Velocity stability factor": 0.8589734865,
      "Direction stability factor": 0.9788075716,
      score: 0.819772431,
    },
    "112.5": {
      velocity: [0.3752056954, 0.9216169157, 1.0804244168],
      direction: [-9.6882751797, 5.5753211801, 30.9249990604],
      "speed factor": 0.8808007327,
      "Uz factor": 0.9988466718,
      "Directional variance factor": 0.5044158951,
      "Velocity stability factor": 0.4288913841,
      "Direction stability factor": 0.8871853493,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.88675138, 0.943083704, 0.9792634126],
      direction: [-8.7221572254, 0.3420288583, 6.7813220908],
      "speed factor": 1.0908704294,
      "Uz factor": 0.9894100415,
      "Directional variance factor": 0.9695974348,
      "Velocity stability factor": 0.9057639985,
      "Direction stability factor": 0.9569347797,
      score: 0.9473077482,
    },
    "157.5": {
      velocity: [1.1243551268, 1.138564665, 1.1603806939],
      direction: [0.0525588378, 0.8443161987, 1.588328378],
      "speed factor": 1.0920324733,
      "Uz factor": 0.9883872476,
      "Directional variance factor": 0.9249496712,
      "Velocity stability factor": 0.9731229766,
      "Direction stability factor": 0.9957339735,
      score: 0.9723851487,
    },
    "180.0": {
      velocity: [0.2012219283, 0.686207584, 0.9279823019],
      direction: [-56.1252805332, -8.7051333727, 17.1884179129],
      "speed factor": 0.9604375484,
      "Uz factor": 0.9938363584,
      "Directional variance factor": 0.2262103669,
      "Velocity stability factor": 0.2894451974,
      "Direction stability factor": 0.7963508376,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.1207719359, 0.4780756255, 0.7682046221],
      direction: [-29.1987837784, 17.498395578, 134.7736095251],
      "speed factor": 0.5685703629,
      "Uz factor": 0.9978721449,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4827777794,
      "Direction stability factor": 0.5445211297,
      score: 0.0,
    },
    "225.0": {
      velocity: [1.1111811632, 1.1300572925, 1.1437804863],
      direction: [2.9552245795, 3.6403690629, 4.5842592241],
      "speed factor": 1.3684416738,
      "Uz factor": 0.987854679,
      "Directional variance factor": 0.6764116389,
      "Velocity stability factor": 0.9693785785,
      "Direction stability factor": 0.9954749038,
      score: 0.9091850062,
    },
    "247.5": {
      velocity: [0.7969437448, 0.9970401132, 1.167180391],
      direction: [-14.1250868827, -4.5301968104, 6.3034892309],
      "speed factor": 1.2491552672,
      "Uz factor": 0.9964956724,
      "Directional variance factor": 0.5973158391,
      "Velocity stability factor": 0.7064154275,
      "Direction stability factor": 0.9432539552,
      score: 0.7975597943,
    },
    "270.0": {
      velocity: [0.7508183305, 0.9460420091, 1.055315325],
      direction: [0.3170484654, 8.0525631126, 15.7857005936],
      "speed factor": 1.3267822405,
      "Uz factor": 0.9998838588,
      "Directional variance factor": 0.2842166122,
      "Velocity stability factor": 0.7403645032,
      "Direction stability factor": 0.9570315219,
      score: 0.7346610398,
    },
    "292.5": {
      velocity: [0.5383330283, 0.5819369379, 0.6158695773],
      direction: [6.2156650129, 11.3263959565, 15.2169184114],
      "speed factor": 0.581650786,
      "Uz factor": 0.9978289922,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9209519766,
      "Direction stability factor": 0.9749965183,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4029309971, 0.4269019612, 0.4534412182],
      direction: [12.9092773635, 15.2018591785, 17.3518803512],
      "speed factor": 0.5305302913,
      "Uz factor": 0.9986445707,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9187002846,
      "Direction stability factor": 0.9876594361,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2726650863, 0.5826492036, 0.7898976652],
      direction: [-58.0036033936, -10.364836525, 3.2356232241],
      "speed factor": 0.5825142174,
      "Uz factor": 0.9981256687,
      "Directional variance factor": 0.0786811978,
      "Velocity stability factor": 0.4845082344,
      "Direction stability factor": 0.8298910372,
      score: 0.0,
    },
    id: "IDN:3580.0",
    zone: 1,
  },
  {
    position: [73.57748783, -58.08753915, 16.6522295],
    "overall score": 0.47211976,
    "0": {
      velocity: [0.1881644274, 0.5112367451, 0.8318133711],
      direction: [-33.2665520103, -7.6928438135, 21.864545757],
      "speed factor": 0.8468011428,
      "Uz factor": 0.999965155,
      "Directional variance factor": 0.316191661,
      "Velocity stability factor": 0.285032152,
      "Direction stability factor": 0.8468580618,
      score: 0.0,
    },
    "22.5": {
      velocity: [0.9484288639, 0.9884967732, 1.0106833147],
      direction: [-2.1335843268, -0.27253925, 0.6443900763],
      "speed factor": 1.3078837866,
      "Uz factor": 0.9961295619,
      "Directional variance factor": 0.9757742889,
      "Velocity stability factor": 0.9474000789,
      "Direction stability factor": 0.9922834044,
      score: 0.9769352942,
    },
    "45.0": {
      velocity: [0.3369544817, 0.5513433437, 0.7202174791],
      direction: [-31.5273971438, -2.8385465793, 12.4769119971],
      "speed factor": 0.8886496841,
      "Uz factor": 0.9954863948,
      "Directional variance factor": 0.7476847485,
      "Velocity stability factor": 0.586890354,
      "Direction stability factor": 0.8777658079,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.9426437547, 0.9548702044, 0.9656544203],
      direction: [-8.7035028807, -7.8719180911, -7.0755938003],
      "speed factor": 1.2976890171,
      "Uz factor": 0.9689690857,
      "Directional variance factor": 0.3002739475,
      "Velocity stability factor": 0.9766192682,
      "Direction stability factor": 0.9954780303,
      score: 0.8169623191,
    },
    "90.0": {
      velocity: [0.9929702982, 1.0040721787, 1.0186007763],
      direction: [-11.4575855494, -10.7350844721, -10.2179262136],
      "speed factor": 1.4504676636,
      "Uz factor": 0.9788179215,
      "Directional variance factor": 0.0457702691,
      "Velocity stability factor": 0.9739482572,
      "Direction stability factor": 0.9965565018,
      score: 0.7532078825,
    },
    "112.5": {
      velocity: [1.2156824238, 1.2172463859, 1.2189364599],
      direction: [-4.5677832621, -4.4193954677, -4.274056241],
      "speed factor": 1.1633374891,
      "Uz factor": 0.9847308712,
      "Directional variance factor": 0.6071648473,
      "Velocity stability factor": 0.9973647777,
      "Direction stability factor": 0.9991840916,
      score: 0.9007244521,
    },
    "135.0": {
      velocity: [1.1479361641, 1.1491529143, 1.1501853188],
      direction: [5.8782220034, 5.9209988809, 5.9529981029],
      "speed factor": 1.3292318886,
      "Uz factor": 0.9765945625,
      "Directional variance factor": 0.4736889884,
      "Velocity stability factor": 0.9977089321,
      "Direction stability factor": 0.9997922886,
      score: 0.8677456244,
    },
    "157.5": {
      velocity: [1.1101925094, 1.1178475788, 1.1289983211],
      direction: [9.40301697, 9.8274449474, 10.0880796205],
      "speed factor": 1.0721620771,
      "Uz factor": 0.9571847445,
      "Directional variance factor": 0.126449338,
      "Velocity stability factor": 0.9859698464,
      "Direction stability factor": 0.9980970482,
      score: 0.7771533202,
    },
    "180.0": {
      velocity: [1.0417479527, 1.046011149, 1.0508054075],
      direction: [13.1522559063, 13.5240998079, 13.750878992],
      "speed factor": 1.4640298459,
      "Uz factor": 0.934789265,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9911445117,
      "Direction stability factor": 0.9983371581,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.9384939, 0.9504643322, 0.9592914649],
      direction: [11.7517964928, 12.4714065992, 13.3348171032],
      "speed factor": 1.1303773324,
      "Uz factor": 0.9295687294,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.9833852029,
      "Direction stability factor": 0.9956027205,
      score: 0.0,
    },
    "225.0": {
      velocity: [0.9271016168, 0.933683136, 0.9415621293],
      direction: [8.9571234891, 9.7029696904, 10.2861437441],
      "speed factor": 1.1306425983,
      "Uz factor": 0.9588817024,
      "Directional variance factor": 0.1375138053,
      "Velocity stability factor": 0.9864168514,
      "Direction stability factor": 0.9963082771,
      score: 0.7791368027,
    },
    "247.5": {
      velocity: [0.8897193034, 0.9037143019, 0.9101942969],
      direction: [0.4204878043, 0.6771847749, 1.0963427136],
      "speed factor": 1.1322307551,
      "Uz factor": 0.9866175356,
      "Directional variance factor": 0.9398057978,
      "Velocity stability factor": 0.9837640539,
      "Direction stability factor": 0.9981226253,
      score: 0.9799537756,
    },
    "270.0": {
      velocity: [0.470409864, 0.792816852, 1.1106841017],
      direction: [-21.6528485773, 4.2281818805, 26.9169343858],
      "speed factor": 1.1118907079,
      "Uz factor": 0.9998464982,
      "Directional variance factor": 0.6241616106,
      "Velocity stability factor": 0.4540572722,
      "Direction stability factor": 0.8650839362,
      score: 0.7020966888,
    },
    "292.5": {
      velocity: [0.8385172663, 0.98554726, 1.0513313854],
      direction: [-0.9080162758, 7.9556193472, 12.4391715432],
      "speed factor": 0.9850626436,
      "Uz factor": 0.9999944057,
      "Directional variance factor": 0.2928338358,
      "Velocity stability factor": 0.7830373456,
      "Direction stability factor": 0.9629244783,
      score: 0.0,
    },
    "315.0": {
      velocity: [0.4746063572, 0.7011055195, 0.9221355878],
      direction: [-17.3596357308, -2.3954709432, 15.5566107547],
      "speed factor": 0.871295401,
      "Uz factor": 0.9989975252,
      "Directional variance factor": 0.7870692495,
      "Velocity stability factor": 0.2796705638,
      "Direction stability factor": 0.908565982,
      score: 0.0,
    },
    "337.5": {
      velocity: [0.2112027128, 0.4310181678, 0.7325643846],
      direction: [-35.5239325226, -7.1093233882, 30.823640534],
      "speed factor": 0.430918311,
      "Uz factor": 0.9992590678,
      "Directional variance factor": 0.3680601433,
      "Velocity stability factor": 0.4803930384,
      "Direction stability factor": 0.815701186,
      score: 0.0,
    },
    id: "IDN:3225.0",
    zone: 1,
  },
  {
    position: [0.54134, 14.2679947, 21.1598299],
    "overall score": 0.4719779375,
    "0": {
      velocity: [0.6638441033, 0.8122353499, 0.9401276572],
      direction: [-12.6163217523, -5.8748414251, 2.2792612126],
      "speed factor": 1.3453685189,
      "Uz factor": 0.9938696753,
      "Directional variance factor": 0.4777918733,
      "Velocity stability factor": 0.6931031117,
      "Direction stability factor": 0.9586233807,
      score: 0.7720354366,
    },
    "22.5": {
      velocity: [0.3025008713, 0.8249092029, 1.1604793467],
      direction: [-36.2582950954, -1.857890622, 37.1999425915],
      "speed factor": 1.091440459,
      "Uz factor": 0.993919157,
      "Directional variance factor": 0.8348541669,
      "Velocity stability factor": 0.275078336,
      "Direction stability factor": 0.7959493398,
      score: 0.6754577956,
    },
    "45.0": {
      velocity: [0.3053814415, 0.7176096311, 1.0085187916],
      direction: [-29.343071456, -17.787968854, 6.5631869067],
      "speed factor": 1.1566360225,
      "Uz factor": 0.9898231301,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.2421057505,
      "Direction stability factor": 0.9002603934,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.6645366893, 0.8935217687, 0.9771196455],
      direction: [-10.1979323681, -6.8098871709, -2.7426382052],
      "speed factor": 1.2143151818,
      "Uz factor": 0.9993728874,
      "Directional variance factor": 0.3946766959,
      "Velocity stability factor": 0.6823899667,
      "Direction stability factor": 0.9792908495,
      score: 0.7589120904,
    },
    "90.0": {
      velocity: [0.5193696556, 0.8409269527, 1.0214880191],
      direction: [-9.365911272, 3.6040796875, 26.7414970494],
      "speed factor": 1.2147905083,
      "Uz factor": 0.9999366836,
      "Directional variance factor": 0.6796373611,
      "Velocity stability factor": 0.4896287765,
      "Direction stability factor": 0.8997016436,
      score: 0.7421673562,
    },
    "112.5": {
      velocity: [0.6066737335, 0.9444863776, 1.1996531573],
      direction: [-5.5918564132, 12.1802880236, 31.4961962015],
      "speed factor": 0.9026573615,
      "Uz factor": 0.9961625761,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5197863475,
      "Direction stability factor": 0.8969776316,
      score: 0.0,
    },
    "135.0": {
      velocity: [0.9674710754, 1.0533324784, 1.1494877115],
      direction: [-1.0517947964, 6.5410267774, 12.521249786],
      "speed factor": 1.2183958307,
      "Uz factor": 0.9995754566,
      "Directional variance factor": 0.4185753976,
      "Velocity stability factor": 0.8145914697,
      "Direction stability factor": 0.9622970984,
      score: 0.789440266,
    },
    "157.5": {
      velocity: [0.7932527847, 1.0477026865, 1.187029156],
      direction: [-20.5321188776, -6.9177312058, -1.3133701066],
      "speed factor": 1.0048839483,
      "Uz factor": 0.9996651569,
      "Directional variance factor": 0.3850905595,
      "Velocity stability factor": 0.7062215089,
      "Direction stability factor": 0.9466145867,
      score: 0.7461353105,
    },
    "180.0": {
      velocity: [0.0867117082, 0.4111993016, 0.958941094],
      direction: [-145.7020941084, -6.8874311525, 141.4317875892],
      "speed factor": 0.5755273744,
      "Uz factor": 0.9987622296,
      "Directional variance factor": 0.3877838976,
      "Velocity stability factor": 0.1472199069,
      "Direction stability factor": 0.2024058842,
      score: 0.0,
    },
    "202.5": {
      velocity: [0.3833229373, 0.9120407665, 1.1458861867],
      direction: [-13.457152584, -1.0366229618, 12.340252119],
      "speed factor": 1.0846805858,
      "Uz factor": 0.9988507143,
      "Directional variance factor": 0.9078557367,
      "Velocity stability factor": 0.3908020624,
      "Direction stability factor": 0.9283405425,
      score: 0.788834721,
    },
    "225.0": {
      velocity: [1.075198003, 1.1037647922, 1.132326027],
      direction: [-3.9462827415, -2.5386513384, -0.974958128],
      "speed factor": 1.3366027985,
      "Uz factor": 0.9986317254,
      "Directional variance factor": 0.7743421032,
      "Velocity stability factor": 0.9463381097,
      "Direction stability factor": 0.9917463205,
      score: 0.9260432135,
    },
    "247.5": {
      velocity: [0.1891975445, 0.5641836059, 0.8982403462],
      direction: [-71.4018787037, -4.2904363765, 36.1846628731],
      "speed factor": 0.7068451044,
      "Uz factor": 0.9969457845,
      "Directional variance factor": 0.6186278776,
      "Velocity stability factor": 0.4377541232,
      "Direction stability factor": 0.7011484956,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.3030311794, 0.8001230233, 1.0207290994],
      direction: [-17.5753504388, 9.7551509938, 35.001725356],
      "speed factor": 1.1221372912,
      "Uz factor": 0.99962597,
      "Directional variance factor": 0.1328754672,
      "Velocity stability factor": 0.3880404098,
      "Direction stability factor": 0.8539525672,
      score: 0.5572052529,
    },
    "292.5": {
      velocity: [0.5876024106, 0.6810819718, 0.8176169589],
      direction: [6.6837321016, 13.6851545253, 17.7917367536],
      "speed factor": 0.6807470681,
      "Uz factor": 0.9999507499,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.7655016164,
      "Direction stability factor": 0.9691444315,
      score: 0.0,
    },
    "315.0": {
      velocity: [1.003878561, 1.0132041063, 1.0236461569],
      direction: [8.5334109569, 8.8114867673, 9.1233041064],
      "speed factor": 1.2591543691,
      "Uz factor": 0.9906104405,
      "Directional variance factor": 0.2167567318,
      "Velocity stability factor": 0.968182679,
      "Direction stability factor": 0.9983614079,
      score: 0.7954155567,
    },
    "337.5": {
      velocity: [0.5139830455, 0.6521075145, 0.8355319341],
      direction: [-7.6967702904, 7.6779635369, 17.6113993334],
      "speed factor": 0.6519564363,
      "Uz factor": 0.9963695603,
      "Directional variance factor": 0.3175143523,
      "Velocity stability factor": 0.6795333258,
      "Direction stability factor": 0.9296995288,
      score: 0.0,
    },
    id: "IDN:2055.0",
    zone: 1,
  },
  {
    position: [-33.4581023, 5.1229227, 19.8740369],
    "overall score": 0.4702478571,
    "0": {
      velocity: [0.4630724039, 0.7323579145, 0.9520302947],
      direction: [-5.8435525494, 1.7805538656, 16.9954410042],
      "speed factor": 1.2130613164,
      "Uz factor": 0.9939074444,
      "Directional variance factor": 0.8417285453,
      "Velocity stability factor": 0.4568635988,
      "Direction stability factor": 0.9365583512,
      score: 0.7929272116,
    },
    "22.5": {
      velocity: [0.1803151506, 0.5789375455, 0.9961128727],
      direction: [-23.4901013877, 22.6139941847, 93.0958862519],
      "speed factor": 0.7659944369,
      "Uz factor": 0.999979992,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.3107176239,
      "Direction stability factor": 0.6761500343,
      score: 0.0,
    },
    "45.0": {
      velocity: [0.1103096246, 0.5170594102, 0.9495144288],
      direction: [-64.6225399757, -1.1498923156, 54.5955974423],
      "speed factor": 0.8333911833,
      "Uz factor": 0.9998411717,
      "Directional variance factor": 0.8977873497,
      "Velocity stability factor": 0.0954420283,
      "Direction stability factor": 0.6688385072,
      score: 0.0,
    },
    "67.5": {
      velocity: [0.4869231936, 0.7452569325, 0.8898359581],
      direction: [-8.1501961843, -1.6799888527, 7.3643456034],
      "speed factor": 1.0128201003,
      "Uz factor": 0.9997400888,
      "Directional variance factor": 0.8506676575,
      "Velocity stability factor": 0.5906074404,
      "Direction stability factor": 0.9569040506,
      score: 0.8387707998,
    },
    "90.0": {
      velocity: [0.404396823, 0.7023837992, 0.9988440951],
      direction: [-22.8947185009, -0.221087671, 32.8520806859],
      "speed factor": 1.0146531392,
      "Uz factor": 0.9992998156,
      "Directional variance factor": 0.9803477626,
      "Velocity stability factor": 0.395782342,
      "Direction stability factor": 0.84514778,
      score: 0.7666064162,
    },
    "112.5": {
      velocity: [1.0513788208, 1.1659294184, 1.2810592969],
      direction: [3.039354637, 8.238483789, 11.0310083498],
      "speed factor": 1.1142932259,
      "Uz factor": 0.9916050322,
      "Directional variance factor": 0.2676903299,
      "Velocity stability factor": 0.813997424,
      "Direction stability factor": 0.9778009619,
      score: 0.7593224194,
    },
    "135.0": {
      velocity: [1.0803157603, 1.1085824998, 1.1396185776],
      direction: [-5.2360683888, -3.8980416091, -1.6075165269],
      "speed factor": 1.2823038532,
      "Uz factor": 0.9901017339,
      "Directional variance factor": 0.6535074125,
      "Velocity stability factor": 0.9395920701,
      "Direction stability factor": 0.9899206893,
      score: 0.8932352153,
    },
    "157.5": {
      velocity: [0.1172914229, 0.5342055545, 0.8566551637],
      direction: [-105.7931638618, -31.9626866001, 50.4134722],
      "speed factor": 0.5123730174,
      "Uz factor": 0.9975493008,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.4483946219,
      "Direction stability factor": 0.5660926776,
      score: 0.0,
    },
    "180.0": {
      velocity: [0.0684305266, 0.4806042617, 0.9209919295],
      direction: [-140.0770212856, 3.8726150076, 127.7463300944],
      "speed factor": 0.6726687223,
      "Uz factor": 0.9992048677,
      "Directional variance factor": 0.6557675549,
      "Velocity stability factor": 0.1664493259,
      "Direction stability factor": 0.2560462462,
      score: 0.0,
    },
    "202.5": {
      velocity: [1.1256726686, 1.1442016523, 1.1566294902],
      direction: [-10.3508070581, -8.8249432875, -7.4717729335],
      "speed factor": 1.3607871096,
      "Uz factor": 0.9978762966,
      "Directional variance factor": 0.2155605967,
      "Velocity stability factor": 0.9752691571,
      "Direction stability factor": 0.992002683,
      score: 0.7937087799,
    },
    "225.0": {
      velocity: [0.2690522509, 0.5915585717, 0.7673745065],
      direction: [-63.2406598071, -23.7269153429, 1.0130476556],
      "speed factor": 0.7163472218,
      "Uz factor": 0.9945092723,
      "Directional variance factor": 0.0,
      "Velocity stability factor": 0.5319124951,
      "Direction stability factor": 0.8215174793,
      score: 0.0,
    },
    "247.5": {
      velocity: [0.4030379791, 0.6566227682, 0.9020168007],
      direction: [-14.856917744, 3.3711378385, 31.1573439121],
      "speed factor": 0.8226587663,
      "Uz factor": 0.9884524727,
      "Directional variance factor": 0.7003433032,
      "Velocity stability factor": 0.6043274336,
      "Direction stability factor": 0.8721826065,
      score: 0.0,
    },
    "270.0": {
      velocity: [0.864814422, 0.9307469794, 1.0091224688],
      direction: [-2.787181874, -0.8500653309, 1.0823693388],
      "speed factor": 1.3053316352,
      "Uz factor": 0.9891279347,
      "Directional variance factor": 0.9244386373,
      "Velocity stability factor": 0.8769528367,
      "Direction stability factor": 0.9892512466,
      score: 0.9449734918,
    },
    "292.5": {
      velocity: [1.0958337453, 1.1164443586, 1.1347663352],
      direction: [3.834971315, 4.8028697759, 5.7989594187],
      "speed factor": 1.1158953771,
      "Uz factor": 0.9682856027,
      "Directional variance factor": 0.5730782421,
      "Velocity stability factor": 0.9603084697,
      "Direction stability factor": 0.9945444775,
      score: 0.8806189167,
    },
    "315.0": {
      velocity: [0.7844912179, 0.7935802875, 0.8066736144],
      direction: [-1.836446286, -1.4497161276, -1.129145079],
      "speed factor": 0.9862179595,
      "Uz factor": 0.9873174743,
      "Directional variance factor": 0.8711363442,
      "Velocity stability factor": 0.9642958895,
      "Direction stability factor": 0.9980352744,
      score: 0.0,
    },
    "337.5": {
      velocity: [1.0728298903, 1.1244499397, 1.1719852955],
      direction: [-6.7801316912, -5.2768312397, -3.7351080725],
      "speed factor": 1.1241894308,
      "Uz factor": 0.9938267378,
      "Directional variance factor": 0.5309483342,
      "Velocity stability factor": 0.9011783151,
      "Direction stability factor": 0.9915416011,
      score: 0.8538024629,
    },
    id: "IDN:3161.0",
    zone: 1,
  },
];
export default zoneOneProject14100;
