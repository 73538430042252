import * as THREE from "three";


export const FlatMeshMaterial = () => {
    return (
        <meshBasicMaterial
            attach="material"
            side={THREE.DoubleSide}
            color="#fff"
        />
    );
};
