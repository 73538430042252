
export const windowEvents = {
    RESET_CAMERA: 'resetCamera'
}


export const subscribe = (eventName, action) => {
    document.addEventListener(eventName, action)
}

export const unsubscribe = (eventName, action) => {
    document.removeEventListener(eventName, action)
}

export const publish = (eventName, data) => {
    const event = new CustomEvent(eventName, { detail: data });
    document.dispatchEvent(event);
}