import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  value: { parentName: "", childName: "" },
  daylightScenarioList: null,
  roomName: null,
  roomPosition: { pagex: 0, pagey: 0 },
  roomArea: null,
  roomCoordinate: null,
  roomFirstIsShowing: false,
  minV: null,
  maxV: null,
  roomFunction: null,
  daylightBoxView: false,
  daylightIsLoading: false
};

const daylightFactorSlice = createSlice({
  name: "daylightFactor",
  initialState,
  reducers: {
    daylightToggleOld: (state, action) => {
      const selected = !state.value.checkStatus[action.payload];
      if (selected) state.value.checkStatus = [false, false, false];
      state.value.checkStatus[action.payload] = selected;
    },
    daylightToggle: (state, action) => {
      const clicked = action.payload;
      const isDeselected =
        state.value.parentName === clicked.parentName &&
        state.value.childName === clicked.childName;
      if (isDeselected) state.value = initialState.value;
      else state.value = clicked;
    },
    deselectDaylight: (state) => {
      state.value = initialState.value;
    },
    updateDaylightConfigurationPosition: (state, action) => {
      state.roomFirstIsShowing = true;
      state.roomPosition.pagex = action.payload[0];
      state.roomPosition.pagey = action.payload[1];
      state.roomName = action.payload[2];
      state.roomArea = action.payload[3];
      state.roomCoordinate = action.payload[4];
      state.daylightScenarioList = action.payload[5];
    },
    setDaylightBoxView: (state, action) => {
      state.daylightBoxView = action.payload;
    },
    setDaylightIsLoading: (state, action) => {
      state.daylightIsLoading = action.payload
    },
  },
});

export const {
  deselectDaylight,
  daylightToggleOld,
  daylightToggle,
  setDaylightBoxView,
  updateDaylightConfigurationPosition,
  setDaylightIsLoading
} = daylightFactorSlice.actions;

export default daylightFactorSlice.reducer;
