import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  defaultValue: {
    checkStatus: Array(24).fill(false),
  },
  value: {
    checkStatus: Array(24).fill(false),
  },
  rangeList: Array(2).fill(0),
  isLoading: false,
};

const windDirectionSlice = createSlice({
  name: "windDirection",
  initialState,
  reducers: {
    toggleWindDirection: (state, action) => {
      const index = action.payload;
      state.value.checkStatus[index] = !state.value.checkStatus[index];
    },
    deselectWindSensors: (state) => {
      state.value = initialState.value;
    },
    hide: (state) => {
      state.value = false;
    },
    updateRangeVirtualWind: (state, action) => {
      state.rangeList[0] = action.payload[0];
      state.rangeList[1] = action.payload[1];
    },
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  toggleWindDirection,
  hide,
  updateRangeVirtualWind,
  setIsLoading,
  deselectWindSensors,
} = windDirectionSlice.actions;

export default windDirectionSlice.reducer;
