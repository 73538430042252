const project15_29SunPosition = [
  {
    id: 3373223,
    customerId: 78,
    projectId: 223,
    time: "04-15 00:00",
    azimuth: 6,
    elevation: -26.41,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373224,
    customerId: 78,
    projectId: 223,
    time: "04-15 00:15",
    azimuth: 1,
    elevation: -26.55,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373225,
    customerId: 78,
    projectId: 223,
    time: "04-15 00:30",
    azimuth: 3,
    elevation: -26.52,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373226,
    customerId: 78,
    projectId: 223,
    time: "04-15 00:45",
    azimuth: 7,
    elevation: -26.34,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373227,
    customerId: 78,
    projectId: 223,
    time: "04-15 01:00",
    azimuth: 11,
    elevation: -25.99,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373228,
    customerId: 78,
    projectId: 223,
    time: "04-15 01:15",
    azimuth: 15,
    elevation: -25.49,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373229,
    customerId: 78,
    projectId: 223,
    time: "04-15 01:30",
    azimuth: 19,
    elevation: -24.84,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373230,
    customerId: 78,
    projectId: 223,
    time: "04-15 01:45",
    azimuth: 23,
    elevation: -24.04,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373231,
    customerId: 78,
    projectId: 223,
    time: "04-15 02:00",
    azimuth: 27,
    elevation: -23.11,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373232,
    customerId: 78,
    projectId: 223,
    time: "04-15 02:15",
    azimuth: 31,
    elevation: -22.03,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373233,
    customerId: 78,
    projectId: 223,
    time: "04-15 02:30",
    azimuth: 34,
    elevation: -20.83,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373234,
    customerId: 78,
    projectId: 223,
    time: "04-15 02:45",
    azimuth: 38,
    elevation: -19.52,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373235,
    customerId: 78,
    projectId: 223,
    time: "04-15 03:00",
    azimuth: 42,
    elevation: -18.09,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373236,
    customerId: 78,
    projectId: 223,
    time: "04-15 03:15",
    azimuth: 45,
    elevation: -16.55,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373237,
    customerId: 78,
    projectId: 223,
    time: "04-15 03:30",
    azimuth: 49,
    elevation: -14.92,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373238,
    customerId: 78,
    projectId: 223,
    time: "04-15 03:45",
    azimuth: 52,
    elevation: -13.21,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373239,
    customerId: 78,
    projectId: 223,
    time: "04-15 04:00",
    azimuth: 55,
    elevation: -11.41,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373240,
    customerId: 78,
    projectId: 223,
    time: "04-15 04:15",
    azimuth: 59,
    elevation: -9.54,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373241,
    customerId: 78,
    projectId: 223,
    time: "04-15 04:30",
    azimuth: 62,
    elevation: -7.61,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373242,
    customerId: 78,
    projectId: 223,
    time: "04-15 04:45",
    azimuth: 65,
    elevation: -5.62,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373243,
    customerId: 78,
    projectId: 223,
    time: "04-15 05:00",
    azimuth: 68,
    elevation: -3.58,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373244,
    customerId: 78,
    projectId: 223,
    time: "04-15 05:15",
    azimuth: 71,
    elevation: -1.49,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373245,
    customerId: 78,
    projectId: 223,
    time: "04-15 05:30",
    azimuth: 74,
    elevation: 0.64,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 3373246,
    customerId: 78,
    projectId: 223,
    time: "04-15 05:45",
    azimuth: 77,
    elevation: 2.79,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 3373247,
    customerId: 78,
    projectId: 223,
    time: "04-15 06:00",
    azimuth: 80,
    elevation: 4.98,
    maxRadiation: 176,
    cDirAmi: 6,
  },
  {
    id: 3373248,
    customerId: 78,
    projectId: 223,
    time: "04-15 06:15",
    azimuth: 83,
    elevation: 7.18,
    maxRadiation: 277,
    cDirAmi: 6,
  },
  {
    id: 3373249,
    customerId: 78,
    projectId: 223,
    time: "04-15 06:30",
    azimuth: 86,
    elevation: 9.4,
    maxRadiation: 360,
    cDirAmi: 6,
  },
  {
    id: 3373250,
    customerId: 78,
    projectId: 223,
    time: "04-15 06:45",
    azimuth: 89,
    elevation: 11.62,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 3373251,
    customerId: 78,
    projectId: 223,
    time: "04-15 07:00",
    azimuth: 92,
    elevation: 13.85,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 3373252,
    customerId: 78,
    projectId: 223,
    time: "04-15 07:15",
    azimuth: 95,
    elevation: 16.07,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 3373253,
    customerId: 78,
    projectId: 223,
    time: "04-15 07:30",
    azimuth: 98,
    elevation: 18.29,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 3373254,
    customerId: 78,
    projectId: 223,
    time: "04-15 07:45",
    azimuth: 101,
    elevation: 20.48,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 3373255,
    customerId: 78,
    projectId: 223,
    time: "04-15 08:00",
    azimuth: 105,
    elevation: 22.65,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 3373256,
    customerId: 78,
    projectId: 223,
    time: "04-15 08:15",
    azimuth: 108,
    elevation: 24.79,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373257,
    customerId: 78,
    projectId: 223,
    time: "04-15 08:30",
    azimuth: 111,
    elevation: 26.89,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373258,
    customerId: 78,
    projectId: 223,
    time: "04-15 08:45",
    azimuth: 115,
    elevation: 28.94,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373259,
    customerId: 78,
    projectId: 223,
    time: "04-15 09:00",
    azimuth: 118,
    elevation: 30.94,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373260,
    customerId: 78,
    projectId: 223,
    time: "04-15 09:15",
    azimuth: 122,
    elevation: 32.86,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373261,
    customerId: 78,
    projectId: 223,
    time: "04-15 09:30",
    azimuth: 126,
    elevation: 34.71,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373262,
    customerId: 78,
    projectId: 223,
    time: "04-15 09:45",
    azimuth: 130,
    elevation: 36.47,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373263,
    customerId: 78,
    projectId: 223,
    time: "04-15 10:00",
    azimuth: 134,
    elevation: 38.12,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373264,
    customerId: 78,
    projectId: 223,
    time: "04-15 10:15",
    azimuth: 138,
    elevation: 39.67,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373265,
    customerId: 78,
    projectId: 223,
    time: "04-15 10:30",
    azimuth: 143,
    elevation: 41.08,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373266,
    customerId: 78,
    projectId: 223,
    time: "04-15 10:45",
    azimuth: 148,
    elevation: 42.36,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373267,
    customerId: 78,
    projectId: 223,
    time: "04-15 11:00",
    azimuth: 152,
    elevation: 43.47,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373268,
    customerId: 78,
    projectId: 223,
    time: "04-15 11:15",
    azimuth: 157,
    elevation: 44.43,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373269,
    customerId: 78,
    projectId: 223,
    time: "04-15 11:30",
    azimuth: 162,
    elevation: 45.2,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373270,
    customerId: 78,
    projectId: 223,
    time: "04-15 11:45",
    azimuth: 168,
    elevation: 45.78,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373271,
    customerId: 78,
    projectId: 223,
    time: "04-15 12:00",
    azimuth: 173,
    elevation: 46.16,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373272,
    customerId: 78,
    projectId: 223,
    time: "04-15 12:15",
    azimuth: 178,
    elevation: 46.33,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373273,
    customerId: 78,
    projectId: 223,
    time: "04-15 12:30",
    azimuth: 184,
    elevation: 46.3,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373274,
    customerId: 78,
    projectId: 223,
    time: "04-15 12:45",
    azimuth: 189,
    elevation: 46.06,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373275,
    customerId: 78,
    projectId: 223,
    time: "04-15 13:00",
    azimuth: 194,
    elevation: 45.62,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373276,
    customerId: 78,
    projectId: 223,
    time: "04-15 13:15",
    azimuth: 199,
    elevation: 44.98,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373277,
    customerId: 78,
    projectId: 223,
    time: "04-15 13:30",
    azimuth: 204,
    elevation: 44.15,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373278,
    customerId: 78,
    projectId: 223,
    time: "04-15 13:45",
    azimuth: 209,
    elevation: 43.15,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373279,
    customerId: 78,
    projectId: 223,
    time: "04-15 14:00",
    azimuth: 214,
    elevation: 41.98,
    maxRadiation: 880,
    cDirAmi: 1,
  },
  {
    id: 3373280,
    customerId: 78,
    projectId: 223,
    time: "04-15 14:15",
    azimuth: 219,
    elevation: 40.66,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373281,
    customerId: 78,
    projectId: 223,
    time: "04-15 14:30",
    azimuth: 223,
    elevation: 39.2,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373282,
    customerId: 78,
    projectId: 223,
    time: "04-15 14:45",
    azimuth: 227,
    elevation: 37.62,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373283,
    customerId: 78,
    projectId: 223,
    time: "04-15 15:00",
    azimuth: 231,
    elevation: 35.93,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373284,
    customerId: 78,
    projectId: 223,
    time: "04-15 15:15",
    azimuth: 235,
    elevation: 34.15,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373285,
    customerId: 78,
    projectId: 223,
    time: "04-15 15:30",
    azimuth: 239,
    elevation: 32.27,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373286,
    customerId: 78,
    projectId: 223,
    time: "04-15 15:45",
    azimuth: 243,
    elevation: 30.33,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373287,
    customerId: 78,
    projectId: 223,
    time: "04-15 16:00",
    azimuth: 246,
    elevation: 28.31,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373288,
    customerId: 78,
    projectId: 223,
    time: "04-15 16:15",
    azimuth: 250,
    elevation: 26.25,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373289,
    customerId: 78,
    projectId: 223,
    time: "04-15 16:30",
    azimuth: 253,
    elevation: 24.13,
    maxRadiation: 700,
    cDirAmi: 2.01,
  },
  {
    id: 3373290,
    customerId: 78,
    projectId: 223,
    time: "04-15 16:45",
    azimuth: 256,
    elevation: 21.98,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 3373291,
    customerId: 78,
    projectId: 223,
    time: "04-15 17:00",
    azimuth: 260,
    elevation: 19.8,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 3373292,
    customerId: 78,
    projectId: 223,
    time: "04-15 17:15",
    azimuth: 263,
    elevation: 17.6,
    maxRadiation: 578,
    cDirAmi: 3.02,
  },
  {
    id: 3373293,
    customerId: 78,
    projectId: 223,
    time: "04-15 17:30",
    azimuth: 266,
    elevation: 15.39,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 3373294,
    customerId: 78,
    projectId: 223,
    time: "04-15 17:45",
    azimuth: 269,
    elevation: 13.16,
    maxRadiation: 487,
    cDirAmi: 4.06,
  },
  {
    id: 3373295,
    customerId: 78,
    projectId: 223,
    time: "04-15 18:00",
    azimuth: 272,
    elevation: 10.93,
    maxRadiation: 416,
    cDirAmi: 5.12,
  },
  {
    id: 3373296,
    customerId: 78,
    projectId: 223,
    time: "04-15 18:15",
    azimuth: 275,
    elevation: 8.71,
    maxRadiation: 314,
    cDirAmi: 6,
  },
  {
    id: 3373297,
    customerId: 78,
    projectId: 223,
    time: "04-15 18:30",
    azimuth: 278,
    elevation: 6.5,
    maxRadiation: 245,
    cDirAmi: 6,
  },
  {
    id: 3373298,
    customerId: 78,
    projectId: 223,
    time: "04-15 18:45",
    azimuth: 281,
    elevation: 4.3,
    maxRadiation: 158,
    cDirAmi: 6,
  },
  {
    id: 3373299,
    customerId: 78,
    projectId: 223,
    time: "04-15 19:00",
    azimuth: 284,
    elevation: 2.12,
    maxRadiation: 130,
    cDirAmi: 6,
  },
  {
    id: 3373300,
    customerId: 78,
    projectId: 223,
    time: "04-15 19:15",
    azimuth: 287,
    elevation: -0.03,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373301,
    customerId: 78,
    projectId: 223,
    time: "04-15 19:30",
    azimuth: 290,
    elevation: -2.14,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373302,
    customerId: 78,
    projectId: 223,
    time: "04-15 19:45",
    azimuth: 293,
    elevation: -4.21,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373303,
    customerId: 78,
    projectId: 223,
    time: "04-15 20:00",
    azimuth: 296,
    elevation: -6.24,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373304,
    customerId: 78,
    projectId: 223,
    time: "04-15 20:15",
    azimuth: 299,
    elevation: -8.22,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373305,
    customerId: 78,
    projectId: 223,
    time: "04-15 20:30",
    azimuth: 302,
    elevation: -10.13,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373306,
    customerId: 78,
    projectId: 223,
    time: "04-15 20:45",
    azimuth: 306,
    elevation: -11.98,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373307,
    customerId: 78,
    projectId: 223,
    time: "04-15 21:00",
    azimuth: 309,
    elevation: -13.75,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373308,
    customerId: 78,
    projectId: 223,
    time: "04-15 21:15",
    azimuth: 312,
    elevation: -15.44,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373309,
    customerId: 78,
    projectId: 223,
    time: "04-15 21:30",
    azimuth: 316,
    elevation: -17.04,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373310,
    customerId: 78,
    projectId: 223,
    time: "04-15 21:45",
    azimuth: 319,
    elevation: -18.54,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373311,
    customerId: 78,
    projectId: 223,
    time: "04-15 22:00",
    azimuth: 323,
    elevation: -19.94,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373312,
    customerId: 78,
    projectId: 223,
    time: "04-15 22:15",
    azimuth: 327,
    elevation: -21.22,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373313,
    customerId: 78,
    projectId: 223,
    time: "04-15 22:30",
    azimuth: 330,
    elevation: -22.38,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373314,
    customerId: 78,
    projectId: 223,
    time: "04-15 22:45",
    azimuth: 334,
    elevation: -23.41,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373315,
    customerId: 78,
    projectId: 223,
    time: "04-15 23:00",
    azimuth: 338,
    elevation: -24.31,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373316,
    customerId: 78,
    projectId: 223,
    time: "04-15 23:15",
    azimuth: 342,
    elevation: -25.06,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373317,
    customerId: 78,
    projectId: 223,
    time: "04-15 23:30",
    azimuth: 346,
    elevation: -25.66,
    maxRadiation: 0,
    cDirAmi: 0,
  },
  {
    id: 3373318,
    customerId: 78,
    projectId: 223,
    time: "04-15 23:45",
    azimuth: 350,
    elevation: -26.12,
    maxRadiation: 0,
    cDirAmi: 0,
  },
];

export default project15_29SunPosition;
