import React from "react";
import {
    PolarAngleAxis,
    PolarGrid,
    PolarRadiusAxis,
    Radar,
    RadarChart,
    Tooltip
} from "recharts";
import "../InformationBox/InfoBoxDashboard.css";

const RadarChartComponent = ({ title, data, dimentions, axisDataKey, valueDataKey, fill = '#39b32e', stroke = '#0b5e04',
    style
}) => (
    <div className="radar-section" style={style}>
        <p className="chart-title">{title}</p>
        <RadarChart {...dimentions} data={data}>
            <PolarGrid />
            <PolarAngleAxis dataKey={axisDataKey} tick={{ fontSize: 11 }} />
            <PolarRadiusAxis domain={[0, 1.4]} />
            <Radar
                dataKey={valueDataKey}
                stroke={stroke}
                fill={fill}
                fillOpacity={0.7}
            />
        </RadarChart>
    </div>
);

export default RadarChartComponent;
