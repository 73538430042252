import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "semantic-ui-css/semantic.min.css";
import { Checkbox, Dropdown } from "semantic-ui-react";
import { updatePVDataFilter } from "../../redux/pvData";
import "../NavBar.css";

const DropDownEnergyGeneration = ({ zone }) => {
  const dispatch = useDispatch()
  const { filter } = useSelector((state) => state.pvData);
  const checked = !!filter?.[zone]
  return (
    <Dropdown.Item
      onClick={() => {
        dispatch(
          updatePVDataFilter({ [zone]: !checked })
        );
      }}
    >
      <Checkbox
        label={zone}
        key={zone}
        checked={checked}
      />
    </Dropdown.Item>
  )
};

export default DropDownEnergyGeneration;
