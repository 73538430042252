import axios from "axios";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as THREE from "three";

import {
  setDaylightBoxView,
  setDaylightIsLoading,
  updateDaylightConfigurationPosition,
} from "../../redux/daylightFactor";
import {
  daylightFactorScale,
  generateColor
} from "../utils";
import { isOptionSelected } from "../utility/helpers";
function genratePolygon(data) {
  var output = [];
  data.forEach((k) => {
    output.push(new THREE.Vector3(k[0], k[1], k[2]));
  });
  return output;
}

const DaylightFactor = ({ }) => {
  const dispatch = useDispatch();
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  const [resultArray, setResultArray] = useState();
  const [nodes, setNodes] = useState(false);

  const project_id = localStorage.getItem("project_id");
  const token = localStorage.getItem("token");
  const getLocalStorageItem = (key) => {
    const item = localStorage.getItem(key);
    return item ? item.split(",") : [];
  };

  const floorsList = getLocalStorageItem("floor_list");
  const scenarioList = getLocalStorageItem("daylight_scenario_list");
  const valueList = getLocalStorageItem("daylight_value_list");

  var selector = useSelector((state) => state.daylightFactor.value);
  const isDaylightSelected = selector?.parentName !== ''
  let allURLs = [];

  floorsList.forEach((floorVal) => {
    allURLs.push(
      `${process.env.REACT_APP_API_URL}api/daylight/?project_id=${project_id}&floor=${floorVal}`
    );
  });
  var responses = [];
  useEffect(() => {
    if (isDaylightSelected && !nodes) {
      dispatch(setDaylightIsLoading(true));
      axios
        .all(
          allURLs.map((endpoint) =>
            axios.get(endpoint, {
              headers: {
                "Content-Type": "application/json; charset=UTF-8",
                Authorization: `Bearer ${token}`,
              },
            })
          )
        )
        .then((data) => {
          setNodes(true);
          data.forEach((val) => {
            responses.push(val.data);
          });
          setResultArray(responses);
        })
        .finally(() => {
          dispatch(setDaylightIsLoading(false));
        });
    }
  }, [isDaylightSelected]);

  const colorbars = useSelector((state) => state.colorbars["Daylight Factor"]);

  if (resultArray?.length > 0)
    return (
      <>
        {resultArray.map((eachFloor, floorIndex) => {
          return valueList.map((value, valueIndex) => {
            const { max, min } = colorbars[value] || { max: 100, min: 0 };
            return scenarioList.map((scenario, scenarioIndex) => {
              return (
                <group rotation-x={Math.PI * -0.5}>
                  {eachFloor.map((room, i) => {
                    const isVisible =
                      selectedFloor[floorIndex] &&
                      isOptionSelected({
                        selector,
                        parentName: value,
                        childName: scenario,
                      });
                    if (!room?.values || room?.values == '') room.values = '[]'

                    return (
                      <>
                        <mesh
                          position={[
                            0,
                            0,
                            JSON.parse(room?.room_polygon)[0][2],
                          ]}
                          visible={isVisible}
                          onClick={(config) => {
                            if (isVisible) {
                              dispatch(setDaylightBoxView(true));
                              dispatch(
                                updateDaylightConfigurationPosition([
                                  config.pageX,
                                  config.pageY,
                                  room.text,
                                  room.room_area,
                                  room.room_polygon,
                                  JSON.parse(room?.values)?.[valueIndex]?.[value],
                                ])
                              );
                              config.stopPropagation();
                            }
                          }}
                        >
                          <primitive
                            object={
                              new THREE.ShapeGeometry(
                                new THREE.Shape(
                                  genratePolygon(
                                    JSON.parse(room.room_polygon)
                                  )
                                )
                              )
                            }
                            attach={"geometry"}
                          />

                          <meshBasicMaterial
                            side={THREE.DoubleSide}
                            color={generateColor(
                              JSON.parse(room?.values)?.[valueIndex]?.[value]?.[
                              scenarioIndex
                              ],
                              // 79,
                              min,
                              // 100,
                              max,
                              daylightFactorScale
                            )}
                          />
                        </mesh>
                      </>
                    );
                  })}
                </group>
              );
            });
          });
        })}
      </>
    );
};

export default DaylightFactor;
