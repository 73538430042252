const infoData = {
  "External Walls":
    "here is some info about external walls, by choosing this one you are able to see the external walls",
  "Internal Walls":
    "by choosing this one, you are seeing the 3d modeling of internal walls, for the whole building, if you want to select specific floor,configure floor",
  Ceilings:
    "by choosing this one, you are seeing the 3d modeling of ceilings, for the whole building",
  SurroundingBuildings:
    "by choosing this one, you are seeing the 3d modeling of all of the building which surround your building, for the whole building",
  terrain: "by choosing this one, you are seeing the 3d modeling of terrain",
  vegetation:
    "by choosing this one, you are seeing the 3d modeling of vegetation which surround your building",
  "sun hoursbtn":
    "by choosing this one, you are seeing sun hours, which is infact provide for you the theoretical amount of sunshine hours available",
  "direct energybtn":
    "by choosing this one, you are seeing the the direct energy value which is direct solar intake based on TRY2015 data",
  "diffuse energybtn":
    "by choosing this one, you are seeing the the direct energy value which is diffuse solar intake based on TRY2015 data",
  "global energybtn":
    "by choosing this one, you are seeing the the direct energy value which is global solar intake based on TRY2015 data",
  vegetation:
    "by choosing this one, you are seeing the 3d modeling of vegetation which surround your building",
  "wind risk data":
    "The risk analysis of shading systems states how much risk there is for each individual component of the system to be exposed to winds with speeds exceeding the safe threshold. In this method, the wind speed at every point of the building façade is calculated by statistical analysis of historical meteorological data, in which we predict how many hours in a year each shade will experience a certain wind speed.",
  "internal walls":
    " you will be able to specificly see the walls for your choosen floor",
  "floors plan":
    " you will be able to specificly see the walls for your choosen floor",
  room: " you will be able to specificly see the walls for your choosen floor",
  "External Walls":
    " you will be able to specificly see the walls for your choosen floor",
  "sun hours":
    "  this value shows theoretical amount of sunshine hours available",
  "direct energy":
    " this value shows  direct solar intake based on TRY2015 data",
  "diffuse energy":
    " this value shows diffuse solar intake based on TRY2015 data",
  "global energy":
    " this value shows global solar intake based on TRY2015 data",
  "Wind Risk Data": " you will be able to see the distribution of window data",
  1.1: "This is status quo of building. The building has a sturdy external wall , internal walls and pitch roof  and floor is composed of three layers. The windows are single glazed with uncoated glass and an aluminium frame.",
  1.2: "In this scenario, the external wall of the building is insulated from the inner side with polyurethane rigid foam. This insulation layer can help to reduce heat loss through the walls, resulting in improved thermal efficiency and increased comfort for occupants. By keeping the indoor temperature consistent, this upgrade can also lead to energy savings in heating and cooling",
  1.3: "In Scenario 1.3 the external wall of the building is insulated from the inner side with polyurethane rigid foam and the windows are replaced with double glazed air-filled windows with UPVC frames. This upgrade can further reduce heat loss through the windows, improving the overall energy efficiency of the building. The double glazed windows can also provide better sound insulation.",
  2.1: "In this scenario, the external wall of the building is insulated from the inner side with polyurethane rigid foam, the windows are replaced with triple glazed air-filled windows with UPVC frames, and a smart shading system is installed on the inner side of the windows. The smart shading system can automatically adjust based on a solar radiation analysis results to optimise daylight and solar heat gain, further reducing energy consumption for heating and cooling. Triple glazed windows can provide even better thermal and sound insulation than double glazed windows.",
  2.2: "In Scenario 2.2, the external wall of the building is insulated from the inner side with polyurethane rigid foam, an air sealer is applied on windows and doors to eliminate thermal bridges, and a smart shading system is installed on the inner side of the windows. The air sealer can help to ensure that the insulation layer is effective by preventing air leakage. The smart shading system can further reduce energy consumption by optimising daylight and solar heat gain.",
  2.3: "In this scenario, the external wall of the building is insulated from the inner side with polyurethane rigid foam and a 185 kW PV system with 562 monocrystalline solar panels is installed on the pitch roof. This upgrade can generate renewable energy to power the building and reduce reliance on grid electricity. The excess energy generated by the system can also potentially be sold back to the grid, creating an additional revenue stream. The previous renovation upgrades, including the insulation and window upgrades, can help to reduce energy consumption and make the most of the renewable energy generated by the PV system.",
};
export default infoData;
