import React, { useState } from "react";
import "./faq.css";
import FAQ from "./FAQ";

export default function FAQData() {
  const [faqs, setFaqs] = useState([
    {
      question: "what types of features are available in scene selection?",
      answer:
        "By the scene selection setup, you will be able to select/deselect available buildings part in the representation,",

      open: false,
    },
    {
      question: "what is the meaning of sunshine hours?",
      answer:
        "By the scene selection setup, you will be able to select/deselect available buildings part in the representation,",

      open: false,
    },
    {
      question: "what is the meaning of global energy?",
      answer:
        "By the scene selection setup, you will be able to select/deselect available buildings part in the representation,",

      open: false,
    },
    {
      question: "what is the meaning of direct energy?",
      answer:
        "By the scene selection setup, you will be able to select/deselect available buildings part in the representation,",

      open: false,
    },
    {
      question: "what is the meaning of diffuse energy?",
      answer:
        "By the scene selection setup, you will be able to select/deselect available buildings part in the representation,",

      open: false,
    },
    {
      question:
        "How can I see the room plans and internal and external walls of building?",
      answer:
        "In the floor selection, you will be able to select each floor separately, at each floor level, you can also select/deselect the room plans, floors, internal walls, facades as well",
      open: false,
    },
    {
      question: "what does  mean sensors in virtual sensors part?",
      answer:
        "Virtual sensors represent a software layer that provides indirect measurements of a process variable or an abstract condition based on data gathered by physical (or other virtual) sensors leveraging a fusion function.",
      open: false,
    },
    {
      question: "what type of features are available from shading system?",
      answer: "each type of shading configuration is avaiable",
      open: false,
    },
    {
      question: "what is the functionality of upload/downalod?",
      answer:
        "you can upload your required files for the building, also you can downalod all of available parts for  your building",
      open: false,
    },
    {
      question: "what does  mean energy data?",
      answer:
        "we provide different energy consumption to the user based on different algorithm and also different scenarios",
      open: false,
    },
    {
      question: "what does that mean sceanrios in energy data?",
      answer: "different scenarios for genrating the results",
      open: false,
    },
    {
      question: "what does mean wind risk data?",
      answer: "different scenarios for genrating the results",
      open: false,
    },
    {
      question: "what does mean shading demand?",
      answer: "different scenarios for genrating the results",
      open: false,
    },
    {
      question: "what is the sensor positioning functionalities?",
      answer: "different scenarios for genrating the results",
      open: false,
    },
    {
      question: "what is the usage of turbine plancement ?",
      answer: "different scenarios for genrating the results",
      open: false,
    },
    {
      question:
        "are the virtual sensros are  for the whole years , or it is correlated to the specific period of time ?",
      answer: "thoese are for whole year",
      open: false,
    },
  ]);

  const toggleFAQ = (index) => {
    setFaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          faq.open = false;
        }

        return faq;
      })
    );
  };

  return (
    <div className="App">
      <div className="faqs">
        {faqs.map((faq, index) => (
          <FAQ faq={faq} index={index} key={index} toggleFAQ={toggleFAQ} />
        ))}
      </div>
    </div>
  );
}
