import React from "react";
import { useColorBar } from "../CustomHooks";
import { calculatePosition, generateAxisValues } from "../utils";
import ColorbarSetRange from "./CustomizeColorbar";
import "./colorBar.css";

export const CustomColorBar = ({
  colorbarClassName,
  category,
  subcategory,
  unit,
  dataMin,
  dataMax,
  isVisible,
}) => {
  const { max, min, step } = useColorBar(category, subcategory);
  let generatedNumbers;
  generatedNumbers = generateAxisValues(min || dataMin, max || dataMax, step);

  if (!isVisible) {
    return null;
  }
  return (
    generatedNumbers?.length > 0 && (
      <>
        <ColorbarSetRange
          category={category}
          subcategory={subcategory}
          defaultMax={parseFloat(dataMax?.toFixed(2))}
          defaultMin={parseFloat(dataMin?.toFixed(2))}
          colorbarMin={min}
          colorbarMax={max}
        />
        <ColorBarComponent
          generatedNumbers={generatedNumbers}
          min={generatedNumbers[0]}
          max={generatedNumbers[generatedNumbers.length - 1]}
          shiftUp={2}
          label={unit}
          className={`color-bar-common ${colorbarClassName}`}
        />
      </>
    )
  );
};

const ColorBarComponent = ({
  generatedNumbers,
  min,
  max,
  shiftUp,
  label,
  className,
}) => {
  const range = max - min;
  if (!generatedNumbers) return;
  return (
    <div className="background-div">
      <div className={`${className} color-bar-common`}>
        {generatedNumbers.map((num, index) => (
          <div
            key={index}
            style={{
              position: "absolute",
              top: `${100 - calculatePosition(num, min, max) - 1.5 * shiftUp}%`,
              marginLeft: "-60px",
            }}
          >
            <p
              style={{
                marginTop: "0.7vh",
                fontSize: "18px",
              }}
            >
              <b></b>
              {range < 0.1 && range != 0
                ? num.toFixed(3)
                : range < 1 && range != 0
                  ? num.toFixed(2)
                  : num.toFixed(1)}
            </p>
          </div>
        ))}
      </div>

      <div
        style={{
          fontSize: "16px",
          fontWeight: "bold",
          textAlign: "center",
          marginTop: "-1vh",
        }}
      >
        {typeof label === 'function' ? label() : label}
      </div>
    </div>
  );
};

export default ColorBarComponent;
