import * as THREE from "three";

export const FlatMeshGeometry = (polygon) => (
    <bufferGeometry>
        <bufferAttribute
            attach="attributes-position"
            array={new Float32Array(polygon)}
            count={new Float32Array(polygon).length / 3}
            itemSize={3}
        />
    </bufferGeometry>
)
