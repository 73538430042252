import React, { useEffect, useRef } from "react";
import "./InfoBoxDashboard.css";
import ClickOutsideHandler from "../utility/ClickOutsideHandler";
import { CaptureComponent } from "../utility/ScreenShotTaker";

const InfoBoxDashboardContainer = ({ children, handleClickOutside, needsCapture, captureProps, style }) => {
    return (
        <ClickOutsideHandler handleClickOutside={handleClickOutside}>
            <div style={{
                width: '900px',
                height: '600px',
                borderRadius: '10px',
                backgroundColor: '#ffffff',
                position: 'absolute',
                top: '150px',
                right: '135px',
                zIndex: 100,
                padding: '20px',
                border: '1px solid #ddd',
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gridTemplateRows: '1fr 1fr',
                gap: '20px',
                ...style
            }}>
                {needsCapture &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}  >
                        <CaptureComponent targetElement={captureProps?.targetElement} style={{
                            marginTop: -20,
                            marginLeft: 0,
                            color: 'var(--clr-primary)',
                            ...(captureProps?.style || {})
                        }} />
                    </div>
                }
                {children}
            </div>
        </ClickOutsideHandler>
    )
};

export default InfoBoxDashboardContainer;
