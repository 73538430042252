import React from "react";
import * as THREE from "three";
import { useSelector, useDispatch } from "react-redux";
import {
  updateRoomConfigurationPosition,
  roomGeneralSetTrue,
} from "../../redux/windowConfiguration";
import { getClickedRoomColor, getRoomTypeColor } from "../utils";
import { useUniqueRoomsFunctions } from "../CustomHooks";
import { Edges } from "@react-three/drei";
function genratePolygon(data) {
  var output = [];
  data.forEach((k) => {
    output.push(new THREE.Vector3(k[0], k[1], k[2]));
  });
  return output;
}

const SingleFloorRooms = ({
  data,
  roomIndex,
  roomConfigurationStateChanger,
  visible,
}) => {
  const selectedFloor = useSelector(
    (state) => state.floorVisibility.selectedFloors
  );
  var dispatch = useDispatch();
  var roomsFilter = useSelector((state) => state.sceneVisibility.roomsFilter);
  var clickedRoomId = useSelector(
    (state) => state.shadingConfigurationWindow.roomId
  );
  const roomIsClicked = useSelector(
    (store) => store.roomData.roomConfigurationState
  );
  var inputData = data;

  var { isByDetails, selectedRoomNames, selectedRoomDetails } = roomsFilter;

  var roomIsSelected = (roomName, roomDetail) => {
    if (!isByDetails) return true;
    return (
      (!selectedRoomNames?.length > 0 ||
        selectedRoomNames.includes(roomName)) &&
      (!selectedRoomDetails?.length > 0 ||
        selectedRoomDetails.includes(roomDetail ?? "Not specified"))
    );
  };
  var colorCoded = roomsFilter.isByDetails;
  var roomsFunctions = useUniqueRoomsFunctions();

  const getRoomColor = (room) => {
    if (roomIsClicked && clickedRoomId === room.id)
      return getClickedRoomColor();
    if (!colorCoded) return "#4675eb";
    return getRoomTypeColor(room.room_function_value, roomsFunctions);
  };

  if (Array.isArray(inputData))
    return (
      <group rotation-x={Math.PI * -0.5} visible={visible}>
        {inputData?.map((room) => {
          const roomIsVisible =
            selectedFloor[roomIndex] &&
            roomIsSelected(room.text, room.room_function_value);
          return (
            <>
              <mesh
                dispose={null}
                position={[0, 0, JSON.parse(room.coordinates)[2]]}
                visible={roomIsVisible}
                onClick={(config) => {
                  if (!roomIsVisible || !visible) return;
                  roomConfigurationStateChanger(true);
                  dispatch(
                    updateRoomConfigurationPosition([
                      config.pageX,
                      config.pageY,
                      room.text,
                      room.id,
                      room,
                    ])
                  );
                  dispatch(roomGeneralSetTrue());
                  config.stopPropagation();
                }}
              >
                <primitive
                  object={
                    new THREE.ShapeGeometry(
                      new THREE.Shape(
                        genratePolygon(JSON.parse(room.room_polygon))
                      )
                    )
                  }
                  attach={"geometry"}
                />

                <meshBasicMaterial
                  dispose={null}
                  // attach="material"
                  side={THREE.DoubleSide}
                  // color="green"
                  color={getRoomColor(room)}
                />
                <Edges color="#333" />
              </mesh>
            </>
          );
        })}
      </group>
    );
};

export default SingleFloorRooms;
