import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useSelector, useDispatch } from "react-redux";
import { setModalScenarioIsShowing } from "../redux/roomData";
import { toggleInfoBpx } from "../redux/infoBoxGeneral";
import { useNavigate } from "react-router-dom";

const ScenarioModal = () => {
  const navigate = useNavigate();
  const navigateFaq = () => {
    navigate("/faq");
  };
  var infoBoxValGeneral = useSelector((state) => state.infoBoxVisibility);
  // var scenarioDescription = useSelector(
  //   (state) => state.roomData.scenarioDescription
  // );
  const dispatch = useDispatch();
  // const status = useSelector((state) => state.roomData.modalScenarioIsShowing);
  // const lastChosenScenario = useSelector(
  //   (state) => state.roomData.lastChosenScenario
  // );
  return (
    infoBoxValGeneral.value && (
      <Modal show={infoBoxValGeneral.value} scrollable={true}>
        <Modal.Header
          closeButton
          onClick={() => {
            dispatch(toggleInfoBpx([""]));
          }}
        >
          {/* <Modal.Title>{infoBoxValGeneral.data}</Modal.Title> */}
        </Modal.Header>
        <Modal.Body>{infoBoxValGeneral.data}</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              dispatch(toggleInfoBpx([""]));
            }}
          >
            Close
          </Button>
          <Button variant="secondary" onClick={navigateFaq}>
            Read More
          </Button>
          {/* <Button variant="primary">Save Changes</Button> */}
        </Modal.Footer>
      </Modal>
    )
  );
};

export default ScenarioModal;
